import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import TextField from '../../../common/Ui/TextField/TextField';

function ResetPasswordForm({ isSameUser }: { isSameUser: boolean }) {
  const { control } = useFormContext();

  return (
    <Grid container justifyContent={'space-between'}>
      {isSameUser && (
        <TextField
          control={control}
          name="oldPassword"
          label="Current Password"
          sizes={{ xs: 12 }}
          type="password"
          required
          inputProps={{ autoComplete: 'new-password' }}
        />
      )}
      <TextField
        control={control}
        name="password"
        label="New Password"
        sizes={{ xs: 12 }}
        type="password"
        required
        inputProps={{ autoComplete: 'new-password' }}
      />
      <TextField
        control={control}
        name="confirmPassword"
        label="Re-Enter New Password"
        sizes={{ xs: 12 }}
        type="password"
        required
        inputProps={{ autoComplete: 'new-password' }}
      />
    </Grid>
  );
}

export default ResetPasswordForm;
