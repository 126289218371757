import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
  main: {
    display: 'flex',
    padding: '53px',
    border: '1px solid #669EEF',
    borderRadius: '14px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 294,
    height: 288,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  point: {
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.15px',
    color: '#001122',
  },
};
export default function UploadDocInstructions() {
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Box sx={styles.main}>
        <Box sx={styles.container}>
          <Typography sx={styles.point}>
            {t('loadsDocumentsUploadAFile')}
          </Typography>
          <Typography sx={styles.point}>
            {t('loadsDocumentsFileType')}
          </Typography>
          <Typography sx={styles.point}>
            {t('loadsDocumentsLinkFile')}
          </Typography>
          <Typography sx={styles.point}>{t('loadsDocumentsProfit')}</Typography>
        </Box>
      </Box>
    );
  }
}
