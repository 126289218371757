import TextField from '@mui/material/TextField';
import { DatePicker, PickersDay } from '@mui/x-date-pickers-v5';
import { AdapterMoment } from '@mui/x-date-pickers-v5/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-v5/LocalizationProvider';

import moment, { Moment } from 'moment';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { getFormattedDateForGrid } from '../../../utils';

const makeAsteriskRed = {
  //TODO refactoring moved to src/ui-kit/theme/components/MuiFormLabel.ts
  // '.MuiFormLabel-asterisk': {
  //   color: 'red',
  // },
};

const setUtcDate = (momentDate?: Moment, hour = 0) => {
  const date = momentDate ? momentDate : moment();
  return moment.utc()?.set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
    hour: hour,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
};

const Datepicker: FunctionComponent<Props> = ({
  label,
  variant = 'standard',
  onDateChange,
  required,
  disableFuture,
  error = false,
  disabled = false,
  inputFormat = 'MM/DD/YYYY',
  value = null,
  styleProps,
  helperText = `Please enter correct date in ${inputFormat} format`,
  id,
  customInputProps,
  customInputLabelProps,
  size,
  minDate = null,
  maxDate = null,
  icon,
  allowEmptyDate,
  disabledManualInput = false,
}: Props) => {
  const [datePickerError, setdatePickerError] = useState<boolean>(error);
  const [open, setOpen] = useState(false);

  const dateChange = (newDate: Moment | null) => {
    const date = newDate ? setUtcDate(newDate) : newDate;

    const formatValidation = moment(date, inputFormat).isValid();
    const requiredCheck = required && date === null;
    const futureDisableCheck =
      disableFuture && date && date.isAfter(setUtcDate());

    const emptyCase = !allowEmptyDate || date !== null;
    const dateValidationCheck =
      (requiredCheck || futureDisableCheck || !formatValidation) && emptyCase;
    dateValidationCheck ? setdatePickerError(true) : setdatePickerError(false);

    onDateChange?.(date, dateValidationCheck, id);
  };

  const today = setUtcDate();

  return (
    <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={label}
        value={value}
        renderDay={(date, selectedDates, pickersDayProps) => {
          const utcDate = setUtcDate(pickersDayProps.day);

          if (!value) {
            return <PickersDay {...pickersDayProps} />;
          }

          pickersDayProps.day = utcDate;

          return (
            <PickersDay
              {...pickersDayProps}
              today={pickersDayProps.day.isSame(today)}
              disabled={pickersDayProps.day.isAfter(today)}
            />
          );
        }}
        disabled={disabled}
        disableFuture={disableFuture}
        onChange={dateChange}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat={inputFormat}
        components={{
          OpenPickerIcon: icon,
        }}
        renderInput={(params: any) => {
          return (
            <TextField
              {...params}
              onClick={() => setOpen(true)}
              inputProps={{
                ...params.inputProps,
                value: getFormattedDateForGrid(value),
                disabled: disabledManualInput,
              }}
              id={id}
              variant={variant}
              error={datePickerError || error}
              required={required}
              sx={{
                ...makeAsteriskRed,
                ...styleProps,
              }}
              size={size}
              helperText={(datePickerError || error) && helperText}
              InputLabelProps={{
                style: customInputLabelProps,
              }}
              InputProps={{
                ...params.InputProps,
                style: customInputProps,
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;

export interface Props {
  id?: string;
  label?: string;
  variant?: any;
  onDateChange?: (
    newDate: Moment | null,
    error: boolean,
    id: string | undefined
  ) => void;
  error?: boolean;
  required?: boolean;
  disableFuture?: boolean;
  value?: string | null;
  styleProps?: any;
  helperText?: any;
  disabled?: boolean;
  inputFormat?: string;
  customInputProps?: CSSProperties;
  customInputLabelProps?: CSSProperties;
  size?: 'small' | 'medium';
  minDate?: any;
  maxDate?: any;
  icon?: React.ElementType<JSX.Element>;
  allowEmptyDate?: boolean;
  disabledManualInput?: boolean;
}
