// @TODO checked trimble maps is not returning only US states if API will be changed and when it can return US states
// then we can remove this constant values and use API instead of hard coding states.

export const states = [
  {
    name: 'Alabama',
    value: 'AL',
  },
  {
    name: 'Alaska',
    value: 'AK',
  },
  {
    name: 'Arizona',
    value: 'AZ',
  },
  {
    name: 'Arkansas',
    value: 'AR',
  },
  {
    name: 'California',
    value: 'CA',
  },
  {
    name: 'Colorado',
    value: 'CO',
  },
  {
    name: 'Connecticut',
    value: 'CT',
  },
  {
    name: 'District of Columbia',
    value: 'DC',
  },
  {
    name: 'Delaware',
    value: 'DE',
  },
  {
    name: 'Florida',
    value: 'FL',
  },
  {
    name: 'Georgia',
    value: 'GA',
  },
  {
    name: 'Hawaii',
    value: 'HI',
  },
  {
    name: 'Idaho',
    value: 'ID',
  },
  {
    name: 'Illinois',
    value: 'IL',
  },
  {
    name: 'Indiana',
    value: 'IN',
  },
  {
    name: 'Iowa',
    value: 'IA',
  },
  {
    name: 'Kansas',
    value: 'KS',
  },
  {
    name: 'Kentucky',
    value: 'KY',
  },
  {
    name: 'Louisiana',
    value: 'LA',
  },
  {
    name: 'Maine',
    value: 'ME',
  },
  {
    name: 'Maryland',
    value: 'MD',
  },
  {
    name: 'Massachusetts',
    value: 'MA',
  },
  {
    name: 'Michigan',
    value: 'MI',
  },
  {
    name: 'Minnesota',
    value: 'MN',
  },
  {
    name: 'Mississippi',
    value: 'MS',
  },
  {
    name: 'Missouri',
    value: 'MO',
  },
  {
    name: 'Montana',
    value: 'MT',
  },
  {
    name: 'Nebraska',
    value: 'NE',
  },
  {
    name: 'Nevada',
    value: 'NV',
  },
  {
    name: 'New Hampshire',
    value: 'NH',
  },
  {
    name: 'New Jersey',
    value: 'NJ',
  },
  {
    name: 'New Mexico',
    value: 'NM',
  },
  {
    name: 'New York',
    value: 'NY',
  },
  {
    name: 'North Carolina',
    value: 'NC',
  },
  {
    name: 'North Dakota',
    value: 'ND',
  },
  {
    name: 'Ohio',
    value: 'OH',
  },
  {
    name: 'Oklahoma',
    value: 'OK',
  },
  {
    name: 'Oregon',
    value: 'OR',
  },
  {
    name: 'Pennsylvania',
    value: 'PA',
  },
  {
    name: 'Rhode Island',
    value: 'RI',
  },
  {
    name: 'South Carolina',
    value: 'SC',
  },
  {
    name: 'South Dakota',
    value: 'SD',
  },
  {
    name: 'Tennessee',
    value: 'TN',
  },
  {
    name: 'Texas',
    value: 'TX',
  },
  {
    name: 'Utah',
    value: 'UT',
  },
  {
    name: 'Vermont',
    value: 'VT',
  },
  {
    name: 'Virginia',
    value: 'VA',
  },
  {
    name: 'Washington',
    value: 'WA',
  },
  {
    name: 'West Virginia',
    value: 'WV',
  },
  {
    name: 'Wisconsin',
    value: 'WI',
  },
  {
    name: 'Wyoming',
    value: 'WY',
  },
];

export const statesinCountry = [
  {
    value: 'AL',
    name: 'Alabama',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'AK',
    name: 'Alaska',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'AZ',
    name: 'Arizona',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'AR',
    name: 'Arkansas',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'CA',
    name: 'California',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'CO',
    name: 'Colorado',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'CT',
    name: 'Connecticut',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'DE',
    name: 'Delaware',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'DC',
    name: 'District of Columbia',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'FL',
    name: 'Florida',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'GA',
    name: 'Georgia',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'HI',
    name: 'Hawaii',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'ID',
    name: 'Idaho',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'IL',
    name: 'Illinois',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'IN',
    name: 'Indiana',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'IA',
    name: 'Iowa',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'KS',
    name: 'Kansas',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'KY',
    name: 'Kentucky',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'LA',
    name: 'Louisiana',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'ME',
    name: 'Maine',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'MD',
    name: 'Maryland',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'MA',
    name: 'Massachusetts',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'MI',
    name: 'Michigan',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'MN',
    name: 'Minnesota',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'MS',
    name: 'Mississippi',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'MO',
    name: 'Missouri',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'MT',
    name: 'Montana',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'NE',
    name: 'Nebraska',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'NV',
    name: 'Nevada',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'NH',
    name: 'New Hampshire',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'NJ',
    name: 'New Jersey',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'NM',
    name: 'New Mexico',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'NY',
    name: 'New York',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'NC',
    name: 'North Carolina',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'ND',
    name: 'North Dakota',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'OH',
    name: 'Ohio',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'OK',
    name: 'Oklahoma',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'OR',
    name: 'Oregon',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'PA',
    name: 'Pennsylvania',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'RI',
    name: 'Rhode Island',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'SC',
    name: 'South Carolina',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'SD',
    name: 'South Dakota',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'TN',
    name: 'Tennessee',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'TX',
    name: 'Texas',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'UT',
    name: 'Utah',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'VT',
    name: 'Vermont',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'VA',
    name: 'Virginia',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'WA',
    name: 'Washington',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'WV',
    name: 'West Virginia',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'WI',
    name: 'Wisconsin',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'WY',
    name: 'Wyoming',
    CountryAbbr: 'US',
    CountryName: 'United States',
  },
  {
    value: 'NU',
    name: 'Nunavut',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'YT',
    name: 'Yukon ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'NT',
    name: 'Northwest Territories ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'BC',
    name: 'British Columbia ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'AB',
    name: 'Alberta ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'SK',
    name: 'Saskatchewan ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'MB',
    name: 'Manitoba ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'ON',
    name: 'Ontario ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'QC',
    name: 'Quebec ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'NB',
    name: 'New Brunswick ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'PE',
    name: 'Prince Edward Island ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'NS',
    name: 'Nova Scotia ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'NL',
    name: 'Newfoundland and Labrador ',
    CountryAbbr: 'CA',
    CountryName: 'Canada',
  },
  {
    value: 'EM',
    name: 'Mexico',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },

  {
    value: 'AG',
    name: 'Aguascalientes',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },

  {
    value: 'BS',
    name: 'Baja California Sur',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'CP',
    name: 'Campeche',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'CH',
    name: 'Chiapas',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'CI',
    name: 'Chihuahua',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'CU',
    name: 'Coahuila',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'CL',
    name: 'Colima',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'DF',
    name: 'Ciudad de MÃ©xico',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'DG',
    name: 'Durango',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'GR',
    name: 'Guerrero',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'GJ',
    name: 'Guanajuato',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'HG',
    name: 'Hidalgo',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'JA',
    name: 'Jalisco',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'MH',
    name: 'MichoacÃ¡n',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'MR',
    name: 'Morelos',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'NA',
    name: 'Nayarit',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'NL-M',
    name: 'Nuevo LeÃ³n',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'OA',
    name: 'Oaxaca',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'PU',
    name: 'Puebla',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'QA',
    name: 'QuerÃ©taro Arteaga',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'QR',
    name: 'Quintana Roo',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'SI',
    name: 'Sinaloa',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'SL',
    name: 'San LuÃ­s PotosÃ­',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'SO',
    name: 'Sonora',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'TA',
    name: 'Tabasco',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'TM',
    name: 'Tamaulipas',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'TL',
    name: 'Tlaxcala',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'VZ',
    name: 'Veracruz',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'YC',
    name: 'Yucatan',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'ZT',
    name: 'Zacatecas',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
  {
    value: 'BJ',
    name: 'Baja California',
    CountryAbbr: 'MX',
    CountryName: 'Mexico',
  },
];
