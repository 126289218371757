import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import TextFieldForm from '../../../../../common/Ui/TextField/TextFieldFormDeprecated';
import { AxeleDriverMapping } from '../ManageFactoreIntegration/AxeleDriverMapping';
import { t } from 'i18next';
export const CreateDriverForm = ({ formData }: ICreateDriverProps) => {
  const { control, setValue, getValues } = useFormContext();
  const [driverName, fundingAccount, driverInvoiceCode, driverInvoiceNotes] =
    getValues([
      'driverName',
      'fundingAccount',
      'driverInvoiceCode',
      'driverInvoiceNotes',
    ]);
  const selectedId = (data: any) => {
    setValue(fundingAccount.data, data);
  };
  return (
    <Box>
      <Box sx={{}}>
        <TextFieldForm
          control={control}
          name="driverName"
          label={t('driverName')}
          defaultValue={driverName}
        />
      </Box>
      <Box sx={{ pt: '32px' }}>
        <AxeleDriverMapping
          rowDetails={formData}
          viewTable={'FormData'}
          selected={selectedId}
          defaultText={'Funding Account'}
        />
      </Box>
      <Box sx={{ pt: '32px' }}>
        <TextFieldForm
          control={control}
          name="driverInvoiceCode"
          label="Driver Invoice Code"
          defaultValue={driverInvoiceCode}
        />
      </Box>
      <Box sx={{ pt: '32px' }}>
        <TextFieldForm
          control={control}
          name="driverInvoiceNotes"
          label="Driver Invoice Notes"
          defaultValue={driverInvoiceNotes}
        />
      </Box>
    </Box>
  );
};

export interface ICreateDriverProps {
  formData: Array<IFormData>;
}

interface IFormData {
  axeleDriverEmail: string | null;
  axeleDriverId: string | null;
  axeleDriverName: string | null;
  axeleDriverSeqNumber: string | null;
  credentialId: string;
  eldId: string;
  email: string | null;
  fetchedTime: string;
  firstname: string;
  id: string;
  lastname: string | null;
  mobileno: string | null;
  organizationId: number;
  providerId: number;
  providerName: string;
  terminalId: string | null;
}
