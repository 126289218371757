export const NumberToCurrency = (number: string | number): string => {
  number = Number(number);
  const formatedNumber = number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const pairNumber = Array.from(formatedNumber);

  if (number < 0) pairNumber.splice(1, 0, '$');
  else pairNumber.splice(0, 0, '$');

  return pairNumber.join('');
};
