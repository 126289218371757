import { EquipmentType } from '../../../../views/maintenanceRecords/constants/constants';
import { dateFormOnlye } from '../../../../views/maintenanceRecords/utils';
import { QueryParams } from '../../commonMixed';
import { Payload } from '../../commonMixed/Payload';
import { MaintenanceFormData } from './Model';

export class PaginatedMaintenanceHistoryListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  [key: string]: any;

  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.terminalIds = dto.terminalIds;
    }
  }
}

export class GetMaintenanceHistoryListQueryParams extends QueryParams {}
export class GetMaintenanceHistoryListCalendarQueryParams extends QueryParams {
  startDate!: string;
  endDate!: string;
  constructor(dto?: any) {
    super();
    if (dto) {
      this.startDate = dto.startDate;
      this.endDate = dto.endDate;
      this.terminalIds = dto.terminalIds;
    }
  }
}

export class GetMaintHistoryListCalendarByEquipmentQueryParams extends QueryParams {
  assetId!: string;
  assetType!: string;

  constructor(dto?: any) {
    super();
    if (dto) {
      this.assetId = dto.assetId;
      this.assetType = dto.assetType;
    }
  }
}

export class CreateMaintenanceHistoryRequest extends Payload {
  completedDate!: string; // YYYY-MM-DD
  equipmentId!: number;
  equipmentType!: EquipmentType;
  equipmentName?: string;
  historyMaintenanceItems!: {
    id: string;
    itemCode: string;
    itemName: string;
    iconName: string;
  }[];
  terminalId!: string | null;
  terminalName!: string | null;

  odometer?: number;
  expense?: number;
  maintenanceVendor?: { id: string; name: string };
  notes?: string;
  createExpense?: boolean;
  expenseCategoryId?: string;
  expenseCategoryKey?: string;

  constructor(dto: MaintenanceFormData) {
    super(true);
    if (dto) {
      this.completedDate = dateFormOnlye(dto.completedDate);
      this.equipmentId = dto.equipmentItem.value;
      this.equipmentType = dto.equipmentType;
      this.equipmentName = dto.equipmentItem.label;
      this.historyMaintenanceItems = dto.maintenanceItems.map(
        ({ itemCode, value, label, iconName }) => ({
          id: value,
          itemCode: itemCode,
          itemName: label,
          iconName: iconName,
        })
      );
      this.terminalId = dto.equipmentItem.terminalId;
      this.terminalName = dto.equipmentItem.terminalName;

      this.odometer = dto.odometer ? +dto.odometer : undefined;
      this.expense = dto.expense ? +dto.expense : undefined;
      this.maintenanceVendor = dto.maintenanceVendor
        ? {
            id: dto.maintenanceVendor.value,
            name: dto.maintenanceVendor.label,
          }
        : undefined;

      this.createExpense = dto?.createExpense;
      this.expenseCategoryId = dto?.categoryObj?.id;

      this.expenseCategoryKey = dto?.categoryObj?.itemCode;
      this.notes = dto.notes;
    }
  }
}

export class UpdateMaintenanceHistoryRequest extends CreateMaintenanceHistoryRequest {
  id!: string;
  expenseId?: string;

  constructor(id: string, dto: MaintenanceFormData) {
    super(dto);
    this.id = id;
    this.expenseId = dto.expenseId;
  }
}

export class DeleteBulkMaintenanceHistoryRequest extends QueryParams {
  maintenanceHistoryIds: string[];

  constructor(dto: any) {
    super();
    this.organizationId = dto.organizationId;
    this.maintenanceHistoryIds = dto.maintenanceHistoryIds;
  }
}

export class ExportMaintenanceHistoryFilterQueryParams extends QueryParams {
  gridColumnMetadataList!: string[] | null;
  constructor({ gridColumnMetadataList }: any) {
    super();
    this.gridColumnMetadataList = gridColumnMetadataList;
  }
}

export class ExportMaintenanceHistoryFilterPayload extends QueryParams {
  equipmentTypesFilter!: string[];
  maintenanceItemsFilter!: string[];
  assetIdsFilter!: number[];
  vendorFilter!: string[];

  constructor({
    equipmentTypesFilter,
    maintenanceItemsFilter,
    assetIdsFilter,
    vendorFilter,
  }: any) {
    super();
    this.equipmentTypesFilter = equipmentTypesFilter || [];
    this.maintenanceItemsFilter = maintenanceItemsFilter || [];
    this.assetIdsFilter = assetIdsFilter || [];
    this.vendorFilter = vendorFilter || [];
  }
}

export class MaintenanceHistoryListTotalRequest extends QueryParams {
  equipmentTypes!: EquipmentType[];
  assetId!: number[];
  maintenanceItems!: string[];
  vendor!: string[];

  constructor(dto: {
    equipmentTypes?: EquipmentType[];
    assetId?: number[];
    maintenanceItems?: string[];
    vendor?: string[];
  }) {
    super();
    this.equipmentTypes = dto.equipmentTypes || [];
    this.assetId = dto.assetId || [];
    this.maintenanceItems = dto.maintenanceItems || [];
    this.vendor = dto.vendor || [];
  }
}
