import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { QueryParams } from '../../models';
import {
  EDIProviderDTO,
  EDITenderDTO,
  EEDITenderStatus,
  ExportEDITendersRequest,
  GetEDITenderResponseDTO,
  GetEDITendersRequest,
  GetFilterListRequest,
  GetProvidersResponse,
  LoadMappingResponse,
  UpdateShipmentStatusBody,
  UpdateShipmentStatusErrorResponse,
  UpdateShipmentStatusParams,
  UpdateShipmentStatusResponse,
} from '../../models/DTOs/EDI/Requests';
import { GetListFilterResponse } from '../../models/DTOs/EDI/Responses';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { docType } from '../../utils/Doc';
import { EEDITendersStatus } from '../../views/EDITenders/constants/types';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { IEDIService } from '../interfaces/IEDIService';

const EDI_PATH = '/web/lbhub/api/v2/edi';
const API_RESOURCE_URL = {
  tenders: {
    list: `${EDI_PATH}/tenders`,
    filterList: `${EDI_PATH}/filter-list`,
    updateShipmentStatus: `${EDI_PATH}/load/updateShipmentStatus`,
    deleteTender: `${EDI_PATH}/tender/delete`,
    loadMapping: `${EDI_PATH}/load-mapping`,
    exportToExcel: `${EDI_PATH}/tenders/export-to-excel`,
    getProviders: `${EDI_PATH}/providers`,
  },
};

export class EDIService extends IEDIService {
  @annotateNameAsync
  async getEDITenders(requestBody: GetEDITendersRequest) {
    try {
      const response = await httpClient.post<GetEDITenderResponseDTO>(
        API_RESOURCE_URL.tenders.list,
        new QueryParams(true),
        requestBody,
        EDITenderDTO,
        false,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getFilterList(
    request: GetFilterListRequest
  ): Promise<any | null | ServiceError> {
    try {
      const response = await httpClient.get<GetListFilterResponse>(
        API_RESOURCE_URL.tenders.filterList,
        request,
        GetListFilterResponse,
        false,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateShipmentStatus(
    requestParams: UpdateShipmentStatusParams,
    requestBody: Array<UpdateShipmentStatusBody>,
    action: EEDITendersStatus | string
  ): Promise<
    | UpdateShipmentStatusResponse
    | UpdateShipmentStatusErrorResponse
    | ServiceError
    | null
  > {
    try {
      const response = await httpClient.postRaw(
        API_RESOURCE_URL.tenders.updateShipmentStatus,
        requestParams,
        requestBody,
        false
      );
      if (response && response?.data?.failedTenders?.length <= 0) {
        switch (action) {
          case EEDITenderStatus.Accepted: {
            if (
              !response.data?.statusCodeValue ||
              response.data.statusCodeValue !== 400
            ) {
              RootStoreInstence.setNotificationType({
                type: 'SUCCESS',
                serviceName: 'tenderAccepted',
              });
            }
            break;
          }
          case EEDITenderStatus.Declined: {
            RootStoreInstence.setNotificationType({
              type: 'SUCCESS',
              serviceName: 'tenderDeclined',
            });
            break;
          }
        }
      }
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      switch (action) {
        case EEDITendersStatus.Accepted: {
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'tenderAccepted',
          });
          break;
        }
        case EEDITendersStatus.Declined: {
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'tenderDeclined',
          });
          break;
        }
      }
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteTender(tenderId: string): Promise<ServiceError | null> {
    try {
      const queryParams: any = new QueryParams();
      queryParams.tenderId = tenderId;
      const response = await httpClient.deleteRaw(
        API_RESOURCE_URL.tenders.deleteTender,
        queryParams,
        undefined
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async loadMapping(
    tenderId: string
  ): Promise<LoadMappingResponse | ServiceError | null> {
    try {
      const queryParams: any = new QueryParams();
      queryParams.tenderId = tenderId;
      const response = await httpClient.get<LoadMappingResponse>(
        API_RESOURCE_URL.tenders.loadMapping,
        queryParams,
        LoadMappingResponse,
        false
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async exportToExcel(
    payload: ExportEDITendersRequest
  ): Promise<docType | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        API_RESOURCE_URL.tenders.exportToExcel,
        new QueryParams(),
        payload
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getProviders(): Promise<GetProvidersResponse | ServiceError> {
    try {
      const queryParams: any = new QueryParams(true);
      const response = await httpClient.get<GetProvidersResponse>(
        API_RESOURCE_URL.tenders.getProviders,
        queryParams,
        EDIProviderDTO,
        false
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
