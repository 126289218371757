import React from 'react';
import Box from '@mui/material/Box';
import Features from './Features';
import FileInfo from './FileInfo';
import Guides from './Guides';
import Entities from './Entities';
import AboutImport from './AboutImport';

export default function ImportLandingPage() {
  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Entities />
      </Box>
      <Box sx={{ mt: 3, p: 4, display: 'flex', alignItems: 'flex-start' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: 5,
          }}
        >
          <AboutImport />
          <Guides />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            ml: 7,
          }}
        >
          <FileInfo />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mt: 3, p: 4, flexDirection: 'column' }}>
        <Features />
      </Box>
    </>
  );
}
