import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import FormDatePicker from '../../../common/Ui/FormDatePicker/FormDatePicker';
import Select from '../../../common/Ui/Select';
import { TrailerAlertSummaryDTO } from '../../../models';
import { findNextDueDateByFrequency } from '../../shared/equipment';

type props = {
  alertOptions: TrailerAlertSummaryDTO[];
};

const AddNewAlertForm = ({ alertOptions = [] }: props) => {
  const { control, setValue, watch } = useFormContext();

  const [maintenanceTypeId, lastConducted] = watch([
    'maintenanceTypeId',
    'lastConducted',
  ]);

  const onMaintenanceItemChanged = (typeId: string) => {
    onLastConductedDateChanged(lastConducted, typeId);
  };

  const onLastConductedDateChanged = (lastConductedDate: string) => {
    setValue(
      'nextDue',
      findNextDueDateByFrequency(
        lastConductedDate,
        maintenanceTypeId,
        'TRAILER'
      )
    );
  };

  return (
    <Grid spacing={2} container>
      <Select
        control={control}
        name="maintenanceTypeId"
        label="Maintenance Item"
        required
        options={alertOptions}
        fieldName="itemName"
        fieldValue="maintenanceTypeId"
        sizes={{ xs: 12 }}
        style={{ marginTop: 0 }}
        onChangeCallback={onMaintenanceItemChanged}
      />
      <FormDatePicker
        control={control}
        disabledText={false}
        name="lastConducted"
        required
        label="Last Done"
        sizes={{ xs: 6 }}
        disableFuture
        onDateChange={onLastConductedDateChanged}
      />
      <FormDatePicker
        control={control}
        disabledText={false}
        name="nextDue"
        required
        label="Next Due"
        sizes={{ xs: 6 }}
      />
    </Grid>
  );
};

AddNewAlertForm.defaultProps = {
  titleText: '',
};

export default AddNewAlertForm;
