import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { staticService } from '../../../../api';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import { GetStatesRequest } from '../../../../models';
import { TerminalShort } from '../../../../models/common/modelsShort';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { LineItem } from '../../../../types';
import {
  DocumentScannerOutlinedIcon,
  DriverIcon,
} from '../../../../ui-kit/components/Assets';
import { IconCheckbox } from '../../../../ui-kit/components/IconCheckbox';
import DocumentActions from '../DocumentActions';
import { addEditEntities } from '../constants';
import { getEndorsements, getRestrictions } from '../utils';
import CertificationForm from './CertificationForm';
import DriverLicenseForm from './DriverLicenseForm';

const COMMERCIAL_DRIVER_LICENSE = 'COMMERCIAL_DRIVER_LICENSE';

export default observer(function UploadForm({
  documentTypes,
  filesLength,
  fromEntity = false,
  entityId = null,
  entity = '',
  hideShareWithDriver = false,
}: PropTypes) {
  const { getAllTerminals, isTerminalEnabled } = useRootStore();
  const [latestDocumentInfo, setLatestDocumentInfo] = useState<any>(null);
  const allTerminalIds = getAllTerminals.map((t: TerminalShort) => t.id);
  const [staticData, setStaticData] = useState({
    states: [],
  });
  const { control, watch, setValue, reset, getValues } = useFormContext();

  const [latestFormState, setLatestFormState] = useState('none');

  const [entityTypes, documentType, entitiesForm, isLatest, terminal] = watch([
    'entityTypes',
    'documentType',
    'entities',
    'isLatest',
    'terminal',
  ]);

  const isLatestActive = !!documentType && !!entityTypes && !!entitiesForm;

  useEffect(() => {
    const getStates = async () => {
      const request = new GetStatesRequest();
      const result = await staticService.getStates(request);
      if (result) {
        setStaticData((data) => ({ ...data, states: result.states }));
      }
    };
    if (!staticData.states.length) {
      getStates();
    }
  }, []);

  useEffect(() => {
    const getItems = async () => {
      const items = await DocumentActions.getEntityAssociatedItems(entityId);
      if (items) {
        setLatestDocumentInfo(items[0]);
      }
    };
    if (fromEntity && entity === 'DRIVER') {
      getItems();
    }
  }, [fromEntity]);

  useEffect(() => {
    setValue('isLatest', isLatestActive);
  }, [isLatestActive]);

  useEffect(() => {
    setValue('filesLength', filesLength);
  }, [filesLength]);

  const getAssociatedItems = (
    searchText: string | undefined,
    pageNumber: number
  ) => {
    if (!documentType) {
      return [];
    }
    const terminalIds = terminal ? [terminal.id] : allTerminalIds;
    return DocumentActions.getAssociatedWith(
      pageNumber,
      searchText,
      entityTypes?.key,
      terminalIds,
      true
    );
  };

  const setLatestDocumentData = (
    data: {
      certifications: { documentTypeId: string; notes: string }[];
      driverLicense: any;
    },
    documentType: { id: string; itemCode: string }
  ) => {
    if (!data) {
      return;
    }
    const { certifications, driverLicense } = data;
    const currentCertification = certifications?.find(
      (c) => c.documentTypeId === documentType.id
    );
    if (currentCertification) {
      reset({
        ...getValues(),
        certification: currentCertification,
        notes: currentCertification.notes,
        state: 'certification',
      });
      setLatestFormState('certificationsForm');
    } else if (documentType.itemCode === COMMERCIAL_DRIVER_LICENSE) {
      const endorsements = getEndorsements(driverLicense);
      const restrictions = getRestrictions(driverLicense);
      reset({
        ...getValues(),
        driverLicense: {
          endorsements: endorsements,
          userId: driverLicense.userId,
          restrictions: restrictions,
          category: driverLicense.category,
          licenseState: driverLicense.licenseState,
          validTo: driverLicense.validTillDate,
        },
        currentLicense: driverLicense,
        state: 'driverLicense',
      });
      setLatestFormState('driverLicenseForm');
    } else {
      setLatestFormState('none');
      setValue('state', null);
    }
  };

  const handleAssociatedItemChange = (data: {
    certifications: { documentTypeId: string; notes: string }[];
    driverLicense: any;
  }) => {
    if (!data) {
      reset({
        ...getValues(),
        entityTypes: null,
      });
    }
    setLatestDocumentData(data, documentType);
  };

  const onDocumentTypeChange = (data: any) => {
    if (!fromEntity) {
      reset({
        ...getValues(),
        entities: null,
        entityTypes: null,
      });
      return;
    }
    setLatestDocumentData(latestDocumentInfo!, data);
  };

  const onEntityTypeChange = () => {
    reset({
      ...getValues(),
      entities: null,
    });
  };

  const onTerminalTypeChange = () => {
    reset({
      ...getValues(),
      entities: null,
    });
  };

  return (
    <Grid container spacing={2}>
      {filesLength === 1 && (
        <TextField control={control} name="fileName" label="Document Name*" />
      )}
      <SingleAutocompleteForm
        control={control}
        name="documentType"
        label="Document Type"
        options={documentTypes}
        fieldName="itemName"
        onChangeCb={onDocumentTypeChange}
        required
      />
      {!fromEntity && (
        <>
          {isTerminalEnabled && (
            <SingleAutocompleteForm
              control={control}
              name="terminal"
              label="Terminal"
              options={getAllTerminals}
              onChangeCb={onTerminalTypeChange}
              fieldName="companyName"
            />
          )}
          <SingleAutocompleteForm
            control={control}
            name="entityTypes"
            label="Associated Type"
            fieldName="value"
            required
            options={addEditEntities}
            sizes={{ xs: 6 }}
            onChangeCb={onEntityTypeChange}
          />
          <SingleAutocompleteForm
            control={control}
            name="entities"
            label="Associated Name"
            fieldName="name"
            getOptions={getAssociatedItems}
            sizes={{ xs: 6 }}
            onChangeCb={handleAssociatedItemChange}
            required={!!entityTypes}
          />
        </>
      )}
      <TextField control={control} name="notes" label="Notes" />
      {!hideShareWithDriver && (
        <IconCheckbox
          control={control}
          name="permission"
          icon={DriverIcon}
          label="Share With Driver"
          subLabel="Share the document with your driver"
        />
      )}
      {entityTypes?.key === 'DRIVER' && latestFormState !== 'none' && (
        <IconCheckbox
          control={control}
          name="isLatest"
          icon={DocumentScannerOutlinedIcon}
          label="Latest Document"
          subLabel="Is this the latest document?"
          disableChange={!isLatestActive}
        />
      )}
      {isLatest && latestFormState === 'certificationsForm' && (
        <CertificationForm parentKey={'certification.'} />
      )}
      {isLatest && latestFormState === 'driverLicenseForm' && (
        <DriverLicenseForm states={staticData.states} />
      )}
    </Grid>
  );
});

type PropTypes = {
  documentTypes: LineItem[];
  filesLength: number;
  fromEntity?: boolean;
  hideShareWithDriver?: boolean;
  entityId: string | null;
  entity: string;
};
