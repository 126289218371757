import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Chip,
  ChipProps,
  Fab,
  FabProps,
  IconButton,
  IconButtonProps,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/styles';

export const StyledChip = styled((props: ChipProps) => <Chip {...props} />)(
  ({ theme, sx = {} }: { theme: any; sx?: SxProps<Theme> }) => ({
    height: '26px',
    ...theme.typography?.chip,
    ...sx,
  })
);

export const StyledLeftTitle = styled((props: BoxProps) => (
  <Box component={'div'} {...props} />
))(
  ({
    theme,
    isActive = false,
    isDark = false,
    sx = {},
  }: {
    theme: any;
    isActive?: boolean;
    isDark?: boolean;
    sx?: SxProps<Theme>;
  }) => ({
    marginBottom: '5px',
    color: isActive
      ? isDark
        ? theme.palette.primary.dark
        : theme.palette.primary.main
      : theme.palette.text.primary,
    ...theme.typography.h7,
    whiteSpace: 'pre-wrap',
    ...sx,
  })
);

export const StyledRightTitle = styled(
  (props: BoxProps & { isHideCollapse?: boolean }) => (
    <Box component={'div'} {...props} />
  )
)(
  ({
    theme,
    isActive,
    sx = {},
    isHideCollapse = false,
  }: {
    theme: any;
    isActive?: boolean;
    sx?: SxProps<Theme>;
    isHideCollapse?: boolean;
  }) => ({
    color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
    ...theme.typography.kpiValue,
    fontWeight: 700,
    lineHeight: 'inherit',
    marginTop: '-5px',
    whiteSpace: 'pre-wrap',
    width: 'calc(100% + 22px)',
    marginRight: '-22px',
    minHeight: '18px',
    ...(isHideCollapse && {
      paddingLeft: '22px',
    }),
    ...sx,
  })
);

export const StyledRightSubTitle = styled((props: BoxProps) => (
  <Box component={'div'} {...props} />
))(
  ({
    theme,
    iSBold = false,
    sx = {},
  }: {
    theme: any;
    iSBold?: boolean;
    sx?: SxProps;
  }) => ({
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-wrap',
    ...theme.typography.body3,
    ...(iSBold && {
      fontWeight: 700,
    }),
    ...sx,
  })
);

export const StyledDetailedDataSectionContent = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }: any) => ({
  padding: '5px 0',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'inline-flex',
  width: '100%',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const StyledDetailedDataSectionContentRow = styled((props: BoxProps) => (
  <Box {...props} />
))(() => ({
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingRight: '10px',
}));

export const StyledDetailedDataSectionContentTitle = styled(
  (props: BoxProps) => <Box {...props} />
)(({ theme, sx = {} }: { theme: any; sx?: SxProps<Theme> }) => ({
  ...theme.typography.body2Bold,
  fontWeight: 600,
  color: theme.palette.text.primary,
  paddingLeft: '5px',
  whiteSpace: 'pre-wrap',
  ...sx,
}));

export const StyledDetailedDataSectionContentDescription = styled(
  (props: BoxProps) => <Box {...props} />
)(({ theme, sx = {} }: { theme: any; sx?: SxProps<Theme> }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.primary,
  paddingLeft: '5px',
  whiteSpace: 'pre-wrap',
  ...sx,
}));

export const StyleEditIconButton = styled((props: IconButtonProps) => (
  <IconButton size={'small'} color="primary" {...props} />
))(({ theme }: { theme: any }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.light,
  borderRadius: '8px',
  padding: '2px',
  marginLeft: '10px',
  width: '30px',
  height: '30px',
}));

export const StyleFloatingActionRoot = styled((props: BoxProps) => (
  <Box {...props} />
))(
  ({
    theme,
    expanded = false,
    showFloatingTruckIcon = false,
  }: {
    theme: any;
    expanded?: boolean;
    showFloatingTruckIcon?: boolean;
  }) => ({
    position: 'absolute',
    marginLeft: '-52px',
    ...(expanded
      ? {
          marginTop: '20%',
        }
      : {
          ...(showFloatingTruckIcon
            ? {
                marginTop: '20px',
              }
            : {
                marginTop: '10px',
              }),
        }),

    background: theme.palette.primary.contrast,
    boxShadow: '0px 1px 12px rgba(78, 101, 120, 0.15)',
    borderRadius: '24px',
  })
);

export const StyleFloatingActionButton = styled((props: FabProps) => (
  <Fab size="small" {...props} />
))(() => ({
  width: '36px',
  height: '36px',
}));

export const StyleFloatingActionAddButton = styled((props: ButtonProps) => (
  <Button variant="text" {...props} />
))(() => ({
  padding: '8px',
  opacity: 0.5,
  '&:hover': {
    opacity: 1,
  },
  '&:last-child': {
    paddingRight: '20px',
  },
}));

export const StyleFloatingActionAddButtonText = styled(
  (props: TypographyProps) => <Typography variant="badgeLabel" {...props} />
)(() => ({
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
}));

//HOS Event View
export const StyledHOSEventViewSectionContent = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }: any) => ({
  padding: '5px 0',
  background: theme.palette.uncategorized.accordion,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '10px',
  margin: '16px -10px',
  width: 'calc(100% + 20px)',
}));
//HOS Event View

export const StyleFloatingTruckIconRoot = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }: { theme: any }) => ({
  position: 'absolute',
  marginLeft: '-42px',
  marginTop: '10px',
  background: theme.palette.primary.contrast,
  boxShadow: '0px 1px 12px rgba(78, 101, 120, 0.15)',
  borderRadius: '24px',
}));
export const StyleFloatingTruckIcon = styled((props: BoxProps) => (
  <Box {...props} />
))(() => ({}));

export const StyleFloatingReSequenceStopIconRoot = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }: { theme: any }) => ({
  position: 'absolute',
  marginLeft: '-42px',
  background: theme.palette.primary.contrast,
  boxShadow: '0px 1px 12px rgba(78, 101, 120, 0.15)',
  borderRadius: '24px',
}));

export const getStylesTripV3ListWithAccordion = () => {
  return {
    renderComponentRoot: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: '8px',
    },
    renderComponentSwitcher: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      width: '100%',
    },
  };
};
