const AppointmentConfirmedIcon = ({ size = 32 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={32} height={32} rx="16" fill="#2E7D32" />
      <rect width={32} height={32} rx="16" fill="black" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.131 17.869C12.961 16.699 12.079 15.389 11.493 14.06C11.37 13.779 11.442 13.45 11.659 13.233L12.478 12.414C13.149 11.743 13.149 10.794 12.563 10.208L11.39 9.03497C10.609 8.25397 9.34302 8.25397 8.56202 9.03497L7.91002 9.68597C7.16902 10.427 6.86002 11.496 7.06002 12.556C7.55402 15.169 9.07202 18.03 11.521 20.479C13.97 22.928 16.831 24.446 19.444 24.94C20.504 25.14 21.573 24.831 22.314 24.09L22.965 23.439C23.746 22.658 23.746 21.392 22.965 20.611L21.792 19.438C21.206 18.852 20.256 18.852 19.671 19.438L18.768 20.342C18.551 20.559 18.222 20.632 17.941 20.508C16.612 19.921 15.301 19.038 14.131 17.869Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 8L20.6662 13L18 10.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AppointmentConfirmedIcon;
