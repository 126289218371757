import {
  PaymentDetails,
  PaymentList,
  SelectedPaymentsType,
} from '../../../models';

export const getPaymentsIds = ({
  fuelNonTripPaymentsList,
  otherNonTripPaymentsList,
  tripPaymentsList,
  excludedId,
}: {
  fuelNonTripPaymentsList: PaymentList;
  otherNonTripPaymentsList: PaymentList;
  tripPaymentsList: PaymentList;
  excludedId?: number;
}) => {
  const payments: PaymentDetails[] = [];
  if (!!fuelNonTripPaymentsList?.content?.length) {
    payments.push(...fuelNonTripPaymentsList.content);
  }
  if (!!otherNonTripPaymentsList?.content?.length) {
    payments.push(...otherNonTripPaymentsList.content);
  }
  if (!!tripPaymentsList?.content?.length) {
    payments.push(...tripPaymentsList.content);
  }

  const ids = payments.map((item) => item.id);
  if (!excludedId) {
    return ids;
  } else {
    return ids.filter((item) => item !== excludedId);
  }
};

export const calculatePaymentsTotalAmount = (content: PaymentDetails[]) => {
  return (
    content?.reduce((sum, value) => {
      return sum + value.amount;
    }, 0) || 0
  );
};

export const getPayStatmentTotalAmount = (
  selectedPayments: SelectedPaymentsType
): number => {
  if (!selectedPayments) return 0;
  let totalAmount = 0;
  if (!!selectedPayments?.tripPaymentsList?.length) {
    totalAmount =
      totalAmount +
      calculatePaymentsTotalAmount(selectedPayments.tripPaymentsList);
  }

  if (!!selectedPayments?.fuelNonTripPaymentsList?.length) {
    totalAmount =
      totalAmount +
      calculatePaymentsTotalAmount(selectedPayments.fuelNonTripPaymentsList);
  }
  if (!!selectedPayments?.otherNonTripPaymentsList?.length) {
    totalAmount =
      totalAmount +
      calculatePaymentsTotalAmount(selectedPayments.otherNonTripPaymentsList);
  }
  return totalAmount;
};

export const excludeItemFromListById = (
  list: PaymentDetails[],
  id: number | string
): PaymentDetails[] => {
  if (!list?.length) return [];
  if (!id) return list;
  return list.filter((item: PaymentDetails) => item.id !== id);
};

export const captilizeFirstChar = (textInCapital: string) => {
  if (!textInCapital?.length) return '';
  textInCapital = textInCapital?.toLocaleLowerCase?.();
  return textInCapital?.[0]?.toUpperCase?.() + textInCapital?.slice?.(1);
};
