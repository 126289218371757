import { QueryParams } from '../commonMixed/QueryParams';

export class CommodityTypeSummary {
  id!: string;
  itemCode!: string;
  itemName!: string;
  description!: string;
  custom!: boolean;
}
export class CommodityTypesListRequest extends QueryParams {
  type!: string;
  constructor({ type }: { type: string }) {
    super();
    this.type = type;
  }
}
