import * as xDataGridPro from '@mui/x-data-grid-pro';
import { action, computed, makeObservable, observable } from 'mobx';
import { paymentManagementService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  GetUnpaidPaymentsQueryParams,
  InvoicePaymentDetails,
} from '../../../models/DTOs/Payment/DTOs';
import { getSelectedInvoices } from '../../../subPages/invoices/utils';
import * as types from '../../../types';
import { invoicePaymentView } from '../../../views/finance/paymentManagement/components/Form/InvoicePaymentGrid/InvoicesPaymentGrid.constants';
import { TSetData, setData } from '../../utils';
const component = 'Invoices';
const dictionary = 'invoice';
class InvoicePaymentStore {
  @observable component: string = component;
  @observable dictionary: string = dictionary;
  @observable currentView: types.View = invoicePaymentView;
  @observable addNewClicked = false;
  @observable totalRows = 0;
  @observable showEmptyPage = false;
  @observable isInvoiceCreate = false;
  @observable filters: types.ViewMetaData = invoicePaymentView.metaData;
  @observable selectedRowId: string | null = null;
  @observable selectedItem: InvoicePaymentDetails | null = null;
  @observable pageIsLoading = false;
  @observable openSecondaryPanel: {
    id: string;
    open: boolean;
  } = {
    id: '',
    open: false,
  };

  @observable isLoading = false;
  @observable selectedInvoices: InvoicePaymentDetails[] = [];
  @observable selectionModel: xDataGridPro.GridSelectionModel = [];
  @observable tableData: InvoicePaymentDetails[] = []; //use for Table data
  @observable unpaidInvoices: InvoicePaymentDetails[] = []; //User for Invoice# dropdown only
  @observable currentPaidInvoices: InvoicePaymentDetails[] = []; //User for Invoice# dropdown only
  @observable amountToApply = 0;

  constructor() {
    makeObservable(this);
  }
  @action
  setAddNewClicked = (newValue: TSetData<boolean>) => {
    this.addNewClicked = setData(newValue, this.addNewClicked);
  };
  setAddInvoiceRecord = (newValue: boolean) => {
    if (newValue) {
      this.selectedItem = null;
    }
    this.isInvoiceCreate = newValue;
  };

  @action
  getUnpaidInvoices = async ({ customerId }: { customerId: string }) => {
    this.isLoading = true;
    const queryParams = new GetUnpaidPaymentsQueryParams({ customerId });
    const data: InvoicePaymentDetails[] | ServiceError =
      await paymentManagementService.getUnpaidInvoices(queryParams);
    if (!(data instanceof ServiceError)) {
      this.unpaidInvoices = data;
      this.totalRows = this.tableData.length;
    }
    this.isLoading = false;
  };

  @computed
  get getAvailableUnpaidInvoices(): InvoicePaymentDetails[] {
    const invoiceIds = this.tableData.reduce(
      (arr: string[], current: InvoicePaymentDetails) => {
        arr.push(current.invoiceId);
        return arr;
      },
      []
    );
    return (
      this.unpaidInvoices.filter(
        ({ invoiceId }) => !invoiceIds.includes(invoiceId)
      ) || []
    );
  }

  @action
  resetDataGrid = () => {
    this.unpaidInvoices = [];
    this.tableData = [];
    this.totalRows = 0;
    this.amountToApply = 0;
  };

  @action
  setCurrentView = (newValue: TSetData<types.View>) => {
    this.unpaidInvoices = [];
    this.tableData = [];
    this.totalRows = 0;
    this.currentView = setData(newValue, this.currentView);
  };

  @action
  setSelectedItem = (newValue: InvoicePaymentDetails | null) => {
    this.selectedItem = newValue;
  };
  @action
  removeRecord = async (invoiceId: string) => {
    const list = this.tableData.filter(
      (obj: InvoicePaymentDetails) => obj.invoiceId !== invoiceId
    );
    this.tableData = list;
    this.updateAmount();
  };
  @action
  changeSelectionModel = (newValue: xDataGridPro.GridSelectionModel) => {
    this.selectionModel = newValue;
    const selectedInvoices = getSelectedInvoices(this.tableData, newValue);
    this.selectedInvoices = selectedInvoices;
  };
  @action
  setSelectedInvoices = (newValue: InvoicePaymentDetails[]) => {
    this.selectedInvoices = newValue;
  };
  @action
  setTableData = (newValue: InvoicePaymentDetails[]) => {
    this.tableData = newValue;
    this.updateAmount();
  };
  @action
  setCurrentPaidInvoices = (newValue: InvoicePaymentDetails[]) => {
    this.currentPaidInvoices = newValue;
  };

  @action
  setFilters = (newValue: TSetData<types.ViewMetaData>) => {
    this.filters = setData(newValue, this.filters);
  };

  @action
  setOpenSecondaryPanel = (status: { id: string; open: boolean }) => {
    this.openSecondaryPanel = status;
  };

  @action
  addNewRowTable = (newValue: InvoicePaymentDetails) => {
    if (newValue?.invoiceId) {
      this.tableData.push({
        ...newValue,
      });
    }
  };

  @action
  updateEdittingRow = (
    newValue: InvoicePaymentDetails,
    defaultInvoiceId?: string,
    paidAmount?: number | null
  ) => {
    if (newValue?.invoiceId) {
      for (let i = 0; i < this.tableData.length; i++) {
        if (
          this.tableData[i].invoiceId === defaultInvoiceId ||
          this.tableData[i].invoiceId == newValue.invoiceId
        ) {
          if (defaultInvoiceId) {
            this.tableData[i] = newValue;
          } else {
            const temp = { ...this.tableData[i] };
            if (paidAmount || paidAmount === 0) {
              // (newAmount-amount) <= amountDue logic to update payment
              if (newValue.amount - paidAmount <= temp.amountDue) {
                this.tableData[i] = { ...temp, ...newValue, isInvalid: false };
              } else {
                this.tableData[i] = { ...temp, ...newValue, isInvalid: true };
              }
            } else {
              this.tableData[i] = { ...temp, advance: newValue.advance };
            }
          }
        }
      }
      this.updateAmount();
    }
  };

  @action
  updateAmount = (): void => {
    let amount = 0;
    this.tableData.forEach((d: InvoicePaymentDetails) => {
      if (d.amount) {
        amount =
          amount +
          (typeof d?.amount == 'string' ? parseFloat(d?.amount) : d?.amount);
      }
    });
    this.amountToApply = amount;
  };
}
export function createInvoicePaymentStore() {
  return new InvoicePaymentStore();
}
