import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiCheckbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ReactNode } from 'react';
import { Accordion as MuiAccordion } from './components/Accordion';
import AccordionSummary from './components/AccordionSummary';

const Accordion = ({
  name,
  children,
  summaryRenderer,
  summaryStyle = {},
  updateParentState,
  expanded = true,
  showCheckBox = false,
}: {
  name?: string;
  children: ReactNode;
  summaryStyle?: object;
  summaryRenderer?: (expanded: boolean) => ReactNode;
  updateParentState?: (expanded: boolean) => any;
  expanded?: boolean;
  showCheckBox?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(expanded);

  const handleChange = (event: React.SyntheticEvent, newExpanded: boolean) => {
    setIsExpanded(newExpanded);
    if (updateParentState) {
      updateParentState(newExpanded);
    }
  };

  React.useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const currentTheme = useTheme();

  const theme = createTheme(currentTheme, {
    components: {
      // Name of the component
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent !important',
            width: '100%',
          },
          // remove borders from root
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        {showCheckBox && <MuiCheckbox />}
        <MuiAccordion expanded={isExpanded} onChange={handleChange}>
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={summaryStyle}
          >
            {typeof summaryRenderer === 'function' ? (
              summaryRenderer(isExpanded)
            ) : (
              <Typography
                variant="h7"
                sx={{ color: isExpanded ? 'primary.main' : 'text.primary' }}
              >
                {name}
              </Typography>
            )}
          </AccordionSummary>
          <MuiAccordionDetails>
            <Grid item container spacing={1}>
              {children}
            </Grid>
          </MuiAccordionDetails>
        </MuiAccordion>
      </Box>
    </ThemeProvider>
  );
};

export default Accordion;
