import { Box, Paper, SxProps, Typography } from '@mui/material';
import React from 'react';

export default function CardWithIcon({
  cardAction,
  icon,
  cardSubtitleStyle,
  iconStyles,
  style = {},
}: IProps) {
  const Icon = icon;
  return (
    <Paper
      sx={{
        padding: '16px 34px 16px 18px',
        width: '48%',
        marginBottom: '32px',
        display: 'grid',
        placeItems: 'center',
        minHeight: '270px',
        ...style,
      }}
      onClick={cardAction}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        <Icon sx={iconStyles} />
        <Typography variant="subtitle1" sx={cardSubtitleStyle}>
          Add New Account
        </Typography>
      </Box>
    </Paper>
  );
}

export interface IProps {
  cardAction: any;
  icon: any;
  cardSubtitleStyle?: object;
  iconStyles?: object;
  style?: SxProps;
}
