import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission';
import DatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import Switch from '../../../../common/Ui/Switcher';
import TextField from '../../../../common/Ui/TextField/TextField';
import { DocumentItemType } from '../../../../models';
import TerminalShort from '../../../../models/common/TerminalShort';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { LineItem } from '../../../../types';
import { isHasPermission } from '../../../../utils';
import { docType } from '../../../../utils/Doc';
import DocumentActions from '../DocumentActions';
import { addEditEntities, disabledEntityKeys } from '../constants';
import FileViewer from './FileViewer';
const styles = { marginBottom: '20px' };
export default observer(function DocumentDetailsForm({
  documentLineItemsMap,
  file,
  editDocumentCallbacks,
  updateTable,
  data,
  setSelectedItem,
  inactive,
}: PropTypes) {
  const { id, attachedEntities: existingAttachedEntities } = data;
  const { getAllTerminals, isTerminalEnabled } = useRootStore();
  const {
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const [entityTypes, terminal, documentTypeId, attachedEntities, description] =
    watch([
      'entityTypes',
      'terminalId',
      'documentTypeId',
      'attachedEntities',
      'description',
    ]);

  useEffect(() => {
    if (!documentTypeId) {
      setError('documentTypeId', { message: 'Document Typ is required' });
    } else if (errors.documentTypeId) {
      clearErrors('documentTypeId');
    }
  }, [documentTypeId]);

  useEffect(() => {
    if (description?.length > 255) {
      setError('description', {
        message: 'Can not be more than 255 characters.',
      });
    } else if (errors.description) {
      clearErrors('description');
    }
  }, [description]);

  const getAssociatedItems = (
    searchText: string | undefined,
    pageNumber: number
  ) => {
    const terminalIds = terminal
      ? [terminal.id]
      : getAllTerminals.map((t: TerminalShort) => t.id);
    return DocumentActions.getAssociatedWith(
      pageNumber,
      searchText,
      entityTypes?.key,
      terminalIds
    );
  };

  const editDocument = async (id: string, value: string, name: string) => {
    if (name in errors) {
      return;
    }
    await DocumentActions.editDocument(id, value, name, data, setSelectedItem);
  };

  const editDocumentType = async (
    documentTypeId: string,
    documentTypeCode: string,
    oldDocumentTypeId: string,
    id: string
  ) => {
    await DocumentActions.editDocumentType(
      id,
      documentTypeId,
      documentTypeCode,
      oldDocumentTypeId,
      entityTypes,
      editDocumentCallbacks.editDocumentTypeCb,
      updateTable,
      setSelectedItem
    );
  };

  const editTerminal = async (terminalId: string | undefined, id: string) => {
    await DocumentActions.editTerminal(
      id,
      terminalId,
      updateTable,
      setSelectedItem,
      editDocumentCallbacks.editTerminalCb
    );
  };

  const editEntityType = async (data: { key: string }, id: string) => {
    await DocumentActions.editEntityType(
      id,
      data,
      entityTypes,
      editDocumentCallbacks.editAssociatedTypeCb,
      updateTable,
      setSelectedItem
    );
  };

  const editAssociatedItem = async (
    data: { id: string },
    attachedEntities: { id: string },
    entityTypes: { key: string },
    id: string
  ) => {
    await DocumentActions.editAssociatedItem(
      id,
      data,
      attachedEntities,
      entityTypes,
      editDocumentCallbacks.editAssociatedItemCb,
      updateTable,
      setSelectedItem,
      existingAttachedEntities?.[0]?.type
    );
  };

  const noPermissionForEditDocuments = !isHasPermission([
    AXELE_PERMISSION_TYPE.DOCUMENT_EDIT,
  ]);
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Box
        sx={{
          p: 2,
          width: '100%',
        }}
      >
        <Grid spacing={1} container width={'100%'} margin={0}>
          <Grid
            sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}
            sm={12}
          >
            <FileViewer allowPreview file={file} />
          </Grid>

          <TextField
            disabled
            control={control}
            name="ownerName"
            label={t('docUploadedBy')}
            sizes={{ xs: 6 }}
            style={styles}
          />

          <DatePicker
            control={control}
            disabled
            name="uploadDate"
            label={t('docUploadDate')}
            sizes={{ xs: 6 }}
          />
          <TextField
            disabled={noPermissionForEditDocuments || inactive}
            control={control}
            name="fileName"
            label={t('docDocumentName')}
            sizes={{ xs: 12 }}
            style={styles}
            onBlurCallback={(data, name) => {
              if (!data) {
                setError('fileName', {
                  message: 'file name is required',
                });
              } else {
                clearErrors('fileName');
                editDocument(id, data, name);
                updateTable(id, data, name);
              }
            }}
          />

          <SingleAutocompleteForm
            disabled={noPermissionForEditDocuments || inactive}
            control={control}
            name="documentTypeId"
            label={t('docDocumentType')}
            options={Object.values(documentLineItemsMap)}
            fieldName="itemName"
            fieldValue={'id'}
            customTextInputStyles={styles}
            disableClear
            onChangeCb={(data) =>
              editDocumentType(data.id, data.itemCode, documentTypeId, id)
            }
            required
          />

          {isTerminalEnabled && (
            <SingleAutocompleteForm
              disabled={noPermissionForEditDocuments || inactive}
              control={control}
              name="terminalId"
              label="Terminal"
              options={getAllTerminals}
              fieldName="companyName"
              customTextInputStyles={styles}
              onChangeCb={(data) => editTerminal(data?.id, id)}
            />
          )}

          <SingleAutocompleteForm
            disabled={
              noPermissionForEditDocuments ||
              disabledEntityKeys.includes(data.attachedEntities?.[0]?.type) ||
              inactive
            }
            control={control}
            name="entityTypes"
            label={t('docAssociatedType')}
            fieldName="value"
            customTextInputStyles={styles}
            getOptions={() => addEditEntities}
            sizes={{ xs: 6 }}
            onChangeCb={async (data) => {
              if (attachedEntities?.id) {
                await editEntityType(data, id);
              }
            }}
          />
          <SingleAutocompleteForm
            disabled={
              noPermissionForEditDocuments ||
              disabledEntityKeys.includes(data.attachedEntities?.[0]?.type) ||
              inactive
            }
            control={control}
            name="attachedEntities"
            label={t('docAssociatedName')}
            required={!!entityTypes}
            fieldName="title"
            getOptions={getAssociatedItems}
            sizes={{ xs: 6 }}
            onChangeCb={async (data) => {
              if (entityTypes) {
                await editAssociatedItem(
                  data,
                  attachedEntities,
                  entityTypes,
                  id
                );
              }
            }}
          />
          <TextField
            disabled={noPermissionForEditDocuments || inactive}
            control={control}
            name="description"
            label={t('docNotes')}
            sizes={{ xs: 12 }}
            onBlurCallback={(data, name) => {
              editDocument(id, data, name);
              updateTable(id, data, name);
            }}
          />
          <Switch
            control={control}
            disabled={noPermissionForEditDocuments || inactive}
            label={t('shareWithDriver')}
            subLabel={t('shareWithDriverSublabel')}
            name="permission"
            sizes={{ xs: 12 }}
            onChange={(event) => {
              const data = event.target.checked ? 'PUBLIC' : 'PRIVATE';
              editDocument(id, data, 'permission');
              updateTable(id, data, 'permission');
            }}
          />
        </Grid>
      </Box>
    );
  }
});

export type PropTypes = {
  documentLineItemsMap: { [key: string]: LineItem };
  file: docType | null;
  editDocumentCallbacks: {
    editDocumentTypeCb: (
      oldId: string,
      newId: string,
      oldEntityType: string
    ) => void;
    editAssociatedTypeCb: (oldType: string, newType: string) => void;
    editAssociatedItemCb: (
      oldValue: { id: string },
      newType: { id: string },
      id: string,
      entityTypeKey: string,
      existingTypeKey: boolean
    ) => void;
    editTerminalCb?: (rowId: string) => void;
  };
  updateTable: (id: string, value: string | object, name: string) => void;
  data: DocumentItemType;
  setSelectedItem: (data: DocumentItemType) => void;
  inactive?: boolean;
};
