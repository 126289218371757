import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { documentService } from '../../../api';
import { CreateDocument, CreateFileRequest } from '../../../models';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { FileType, LineItem } from '../../../types';
import { CloseIcon } from '../../../ui-kit/components/Assets';
import AxeleDialog from '../../../ui-kit/components/AxeleDialog/AxeleDialog';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved/ButtonImproved';
import DnD, { viewFileType } from './DnD';
import UploadDocInstructions from './Instructions';
import { useTranslation } from 'react-i18next';

export default function UploadFileDialog({
  open,
  onClose,
  renderContent,
  onFormSubmit,
  formDefaultValues,
  validationSchema,
  createDocumentCb,
}: PropTypes) {
  const [file, setFile] = useState<FileType[] | null>(null);
  const [viewFile, setViewFile] = useState<viewFileType[]>([]);

  const methods = useForm({
    defaultValues: formDefaultValues,
    reValidateMode: 'onSubmit',
    resolver: validationSchema && yupResolver(validationSchema),
  });

  const { handleSubmit, setValue } = methods;

  const handleUploadDialogClose = () => {
    setViewFile([]);
    setFile(null);
    onClose(false);
  };

  const setFileData = (file: FileType[]) => {
    setFile(file);
  };

  useEffect(() => {
    if (file) {
      let fileName = '';
      if (file.length === 1) {
        fileName = file[0].name || file[0].fileName || '';
      }
      setValue('fileName', fileName);
    }
  }, [file]);

  const onSubmit = async (data: any) => {
    onFormSubmit(data);
    handleUploadDialogClose();
    const request = new CreateFileRequest();
    if (file) {
      const createdDocument = await documentService.createDocument(
        request,
        file,
        data
      );
      if (createdDocument) {
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'documentCreate',
        });
        if (typeof createDocumentCb === 'function') {
          createDocumentCb(createdDocument, data);
        }
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'documentCreate',
        });
      }
    }
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <>
        {open && (
          <AxeleDialog
            open={open}
            handleClose={handleUploadDialogClose}
            isTabsPanel={false}
            renderComponent={
              <Box
                sx={{
                  display: 'flex',
                  background: 'rgba(3, 14, 24, 0.89)',
                  minHeight: '389px',
                }}
              >
                <Box sx={{ display: 1, p: 3, flex: 1 }}>
                  <DnD
                    setFile={setFileData}
                    viewFile={viewFile}
                    setViewFile={setViewFile}
                    file={file}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    background: 'white',
                    borderRadius: 3,
                    p: 4,
                    flex: 1,
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '32px',
                        fontSize: '20px',
                      }}
                    >
                      <span>Upload Document</span>
                      <IconButton
                        aria-label="close"
                        onClick={handleUploadDialogClose}
                      >
                        <CloseIcon
                          color="rgba(0, 17, 34, 0.75)"
                          width={14}
                          height={14}
                        />
                      </IconButton>
                    </Box>
                    {file && (
                      <Box>
                        <FormProvider {...methods}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            {renderContent(file)}
                          </form>
                        </FormProvider>
                      </Box>
                    )}
                    {!file && <UploadDocInstructions />}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 4,
                    }}
                  >
                    <ButtonImproved
                      onClick={handleUploadDialogClose}
                      variant="outlined"
                      label={t('cancel')}
                    ></ButtonImproved>
                    <ButtonImproved
                      disabled={!file}
                      type={'button'}
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                      label={'Upload Document'}
                    ></ButtonImproved>
                  </Box>
                </Box>
              </Box>
            }
            showTitle={false}
            height={'auto'}
            dialogStyles={{
              width: '65%',
              m: '0 auto',
              '.MuiDialogContent-root': {
                p: '0 !important',
                m: 0,
                overflow: 'scroll',
                overflowX: 'hidden',
              },
            }}
            dialogPaperStyles={{
              p: 0,
            }}
          />
        )}
      </>
    );
  }
}

export type PropTypes = {
  open: boolean;
  onClose: (state: boolean) => void;
  renderContent: (file: FileType[]) => JSX.Element;
  createDocumentCb?: (file: CreateDocument, docType: LineItem) => void;
  onFormSubmit: (data: any) => void;
  formDefaultValues: { [key: string]: null | string | boolean | object };
  validationSchema?: AnyObjectSchema;
};
