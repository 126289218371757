import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { getACHFields, getCreditCardFields } from './constant';
import { StyledCardInfoRow, StyledCardInfoRowItem } from './styles';

export const ChargifyCardInfo = ({
  setCardData,
  fields,
  isAuthPage = false,
}) => {
  const { themeLogin } = useThemeResponsive();
  const creditCardFields = getCreditCardFields({ theme: themeLogin });

  const [chargify, setChargify] = useState();
  const formRef = useRef();
  useEffect(() => {
    creditCardFields.country.value = 'US';
    const Chargify = new window.Chargify();
    Chargify.load({
      fields: fields ? fields : creditCardFields,
      type: 'card',
      publicKey: window.env.CHARGIFY_PUBLIC_KEY,
      serverHost: window.env.CHARGIFY_SERVER_HOST,
      hideCardImage: false,
      optionalLabel: '(optional field)',
      requiredLabel: '*',
      addressDropdowns: true,
    });
    setChargify(Chargify);
    setCardData({ chargify: Chargify, chargifyForm: formRef });
    return () => {
      if (chargify) chargify.unload();
    };
  }, []);
  return (
    <form
      ref={formRef}
      style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
    >
      {isAuthPage ? (
        <>
          <StyledCardInfoRow>
            <StyledCardInfoRowItem id="chargify-firstName" />
            <StyledCardInfoRowItem id="chargify-lastName" />
          </StyledCardInfoRow>
          <StyledCardInfoRow>
            <StyledCardInfoRowItem
              id="chargify-cardinfo"
              sx={{ width: '100%' }}
            />
          </StyledCardInfoRow>
          <StyledCardInfoRow alignItems="flex-start">
            <StyledCardInfoRowItem id="chargify-cardmonth" />
            <StyledCardInfoRowItem id="chargify-cardyear" />
            <StyledCardInfoRowItem id="chargify-cardcvv" />
          </StyledCardInfoRow>
          <StyledCardInfoRow>
            <StyledCardInfoRowItem id="chargify3" />
          </StyledCardInfoRow>
          <StyledCardInfoRow>
            <StyledCardInfoRowItem
              id="chargify-address"
              sx={{ width: '100%' }}
            />
          </StyledCardInfoRow>
          <StyledCardInfoRow alignItems="flex-start">
            <StyledCardInfoRowItem id="chargify-country" />
            <StyledCardInfoRowItem id="chargify-state" />
            <StyledCardInfoRowItem id="chargify-city" />
            <StyledCardInfoRowItem id="chargify-zip" />
          </StyledCardInfoRow>
        </>
      ) : (
        <>
          <Box
            id="chargify-firstName"
            sx={{ width: fields ? '227.5px' : '323px', marginRight: '24px' }}
          />
          <Box
            id="chargify-lastName"
            sx={{ width: fields ? '227.5px' : '323px' }}
          />
          <Box
            id="chargify-cardinfo"
            sx={{ width: fields ? '479px' : '670px' }}
          />
          <Box
            id="chargify-cardmonth"
            sx={
              fields
                ? { width: '143.6px', marginRight: '24px' }
                : { width: '200px' }
            }
          />
          <Box
            id="chargify-cardyear"
            sx={
              fields
                ? { width: '143.6px', marginRight: '24px' }
                : { marginLeft: '35px', width: '200px' }
            }
          />
          <Box
            id="chargify-cardcvv"
            sx={
              fields
                ? { width: '143.6px' }
                : { marginLeft: '35px', width: '200px' }
            }
          />
          <Box id="chargify3" sx={{ width: fields ? '479px' : '670px' }} />
        </>
      )}
    </form>
  );
};

export const ChargifyBankInfo = ({
  setCardData,
  fields,
  rejoin,
  isAuthPage = false,
}) => {
  const [chargify, setChargify] = useState();
  const formRef = useRef();
  const { themeLogin } = useThemeResponsive();
  const ACHFields = getACHFields({ theme: themeLogin });
  useEffect(() => {
    const Chargify = new window.Chargify();
    Chargify.load({
      fields: fields ? fields : ACHFields,
      type: 'bank',
      publicKey: window.env.CHARGIFY_PUBLIC_KEY,
      serverHost: window.env.CHARGIFY_SERVER_HOST,
      hideCardImage: false,
      optionalLabel: '(optional field)',
      requiredLabel: '*',
      addressDropdowns: true,
    });
    setChargify(Chargify);
    setCardData({ chargify: Chargify, chargifyForm: formRef });
    return () => {
      if (chargify) chargify.unload();
    };
  }, []);

  return (
    <>
      <form
        ref={formRef}
        style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
      >
        {isAuthPage ? (
          <>
            <StyledCardInfoRow>
              <StyledCardInfoRowItem id="chargify-firstName" />
              <StyledCardInfoRowItem id="chargify-lastName" />
            </StyledCardInfoRow>
            <StyledCardInfoRow>
              <StyledCardInfoRowItem id="chargify-bankName" />
              <StyledCardInfoRowItem id="chargify-routingNumber" />
            </StyledCardInfoRow>
            <StyledCardInfoRow>
              <StyledCardInfoRowItem id="chargify-accountNumber" />
              <StyledCardInfoRowItem id="chargify-accountHolderType" />
            </StyledCardInfoRow>
            <StyledCardInfoRow>
              <StyledCardInfoRowItem id="chargify3" />
            </StyledCardInfoRow>
          </>
        ) : (
          <>
            <Box
              id="chargify-firstName"
              sx={{ width: fields ? '227.5px' : '323px', marginRight: '24px' }}
            />
            <Box
              id="chargify-lastName"
              sx={{ width: fields ? '227.5px' : '323px' }}
            />
            <Box
              id="chargify-bankName"
              sx={{ width: fields ? '479px' : '670px' }}
            />
            <Box
              id="chargify-routingNumber"
              sx={{ width: fields ? '479px' : '670px' }}
            />
            <Box
              id="chargify-accountNumber"
              sx={{ width: fields ? '479px' : '670px' }}
            />
            <Box
              id="chargify-accountHolderType"
              sx={{ width: fields ? '479px' : '670px' }}
            />
            <Box id="chargify3" sx={{ width: fields ? '479px' : '670px' }} />
          </>
        )}
      </form>
      {fields || rejoin ? null : (
        <Box
          sx={(theme) => ({
            ...theme.typography.body3,
            color: 'text.primary',
            marginTop: '25px',
            fontSize: '12px',
          })}
        >
          Note: Your card info will be used for monthly charging of payment per
          your subscription plan
        </Box>
      )}
    </>
  );
};
