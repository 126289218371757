import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { locationService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import {
  GetTimezoneByCoordsRequest,
  GetTimezoneByCoordsResponse,
} from '../../../../models';
import TextInput from '../../../../ui-kit/components/TextField';
import fetchAddress from '../../../../utils/fetchAddress';
import { ILocationSearch, addressType } from '../constants';
import { propsType } from '../types/EditableAddressFieldPropType';

const textInputDisableSxProps = {
  '.Mui-disabled': {
    color: '#c5c8ca',
    '-webkit-text-fill-color': '#c5c8ca',
  },
};
function EditModeAddressField({
  data = null,
  onAddressChanged,
  errors,
  required,
  label,
  maxWidth,
  disableAddressLine,
  variant,
  disabled,
  includeTimezone,
  validateAddress,
  textInputSxProps,
  onlyState = false,
  autoFocus = true,
}: propsType) {
  const [addressData, setAddressData] = useState<addressType | null>(data);

  const [inputValue, setInputValue] = React.useState(data?.address);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (data && !Object.keys(data).length) {
      setAddressData(null);
    } else {
      setAddressData(data); //Important: Side effects
    }
  }, [data]);

  const debouncedSearch = useRef(
    debounce(
      async (queryString) => {
        const locations = await fetchAddress(queryString, onlyState);
        setLoading(false);
        return locations;
      },
      300,
      { leading: true }
    )
  ).current;

  const getAddresses = async (queryString: string | undefined) => {
    setLoading(true);
    const response = await debouncedSearch(queryString);
    return response;
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const selectAddress = async (
    event: any,
    newValue: ILocationSearch
  ): Promise<void> => {
    const newAddress: addressType = {
      fullAddress: addressData?.fullAddress || '',
      address: newValue?.ShortString,
      city: newValue?.Address?.City,
      state: newValue?.Address?.State,
      streetAddress: newValue?.Address?.StreetAddress,
      streetAddress2: '',
      center: { lat: newValue?.Coords.Lat, lng: newValue?.Coords.Lon },
      zipcode: newValue?.Address?.Zip,
    };

    if (includeTimezone) {
      const timezoneData: GetTimezoneByCoordsResponse | ServiceError | null =
        await locationService.getTimezoneByCoords(
          new GetTimezoneByCoordsRequest({
            latitude: newAddress.center?.lat,
            longitude: newAddress.center?.lng,
          })
        );
      if (!(!timezoneData || timezoneData instanceof ServiceError)) {
        newAddress.timezone = timezoneData.zoneName;
      }
    }

    onAddressChanged(newAddress);
    setAddressData(newAddress);
  };

  const getHelperText = () => {
    if (!validateAddress) {
      return errors?.address?.message || '';
    }
    if (required && !addressData?.address && errors) {
      return `${label} is required.`;
    }
    return '';
  };

  return (
    <Grid container sx={{ maxWidth: maxWidth || '-webkit-fill-available' }}>
      <Grid item xs={12}>
        <Autocomplete
          size="small"
          disabled={disabled}
          disableClearable={true}
          value={addressData?.address || ''}
          autoComplete={false}
          open={open}
          freeSolo
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            if (inputValue) {
              setInputValue(addressData?.address);
            }
            setOpen(false);
          }}
          onChange={selectAddress}
          inputValue={inputValue || ''}
          filterOptions={(options) => options}
          onInputChange={(event, newInputValue) => {
            if (!newInputValue) {
              const newAddress: addressType = {
                address: '',
                fullAddress: '',
                city: '',
                state: '',
                streetAddress: '',
                streetAddress2: '',
                zipcode: '',
                center: { lat: 0, lng: 0 },
              };
              setAddressData(newAddress);
              onAddressChanged(newAddress);
            }

            if (newInputValue !== 'undefined') {
              setInputValue(newInputValue);
            }
            if (newInputValue && newInputValue !== 'undefined') {
              (async () => {
                const locations = await getAddresses(newInputValue);
                if (onlyState) {
                  locations.forEach((location: any) => {
                    location.ShortString = `${location?.Address?.City}, ${location?.Address?.State}, ${location?.Address?.Country} `;
                  });
                }
                setOptions([...locations]);
              })();
            } else {
              setOptions([]);
              setAddressData({});
              onAddressChanged({});
            }
          }}
          getOptionLabel={(option: any) => option?.ShortString || option}
          options={options}
          loading={loading}
          renderInput={(params) => {
            return (
              <TextInput
                autoFocus={autoFocus}
                error={!!errors}
                {...params}
                helperText={getHelperText()}
                autoComplete="off"
                name="standard-read-only-input"
                label={label}
                variant={variant}
                required={required}
                styleProps={{ ...textInputSxProps }}
              />
            );
          }}
        />
      </Grid>
      {addressData?.address && !disableAddressLine && (
        <Grid container item xs={12} spacing={0}>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            xs={12}
            spacing={0}
            sx={{ margin: textInputSxProps ? '5px' : '20px 0px' }}
          >
            <Grid item xs={6}>
              <TextInput
                autoComplete="off"
                styleProps={{ width: '95%', ...textInputSxProps }}
                id="Address Line 1"
                label="Address Line 1"
                error={!!errors?.streetAddress?.message}
                helperText={errors?.streetAddress?.message}
                variant={variant}
                value={addressData.streetAddress}
                disabled={disabled}
                InputLabelProps={{
                  shrink: !!addressData.streetAddress,
                }}
                onChange={(event) => {
                  const updatedAddress = {
                    ...addressData,
                    streetAddress: event.target.value,
                  };
                  onAddressChanged(updatedAddress);
                  setAddressData(updatedAddress);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                autoComplete="off"
                styleProps={{ width: '100%', ...textInputSxProps }}
                id="Address Line 2"
                label="Address Line 2"
                error={!!errors?.streetAddress2?.message}
                helperText={errors?.streetAddress2?.message}
                variant={variant}
                value={addressData.streetAddress2}
                disabled={disabled}
                InputLabelProps={{
                  shrink: !!addressData.streetAddress2,
                }}
                onChange={(event) => {
                  const updatedAddress = {
                    ...addressData,
                    streetAddress2: event.target.value,
                  };
                  onAddressChanged(updatedAddress);
                  setAddressData(updatedAddress);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            xs={12}
            spacing={0}
          >
            <Grid item xs={5}>
              <TextInput
                autoComplete="off"
                disabled
                styleProps={{
                  width: '95%',
                  ...textInputDisableSxProps,
                  ...textInputSxProps,
                }}
                id="city"
                label="City"
                variant={variant}
                error={!!errors?.city}
                helperText={errors?.city?.message}
                value={addressData?.city}
                InputLabelProps={{
                  shrink: !!addressData?.city,
                }}
                required={required}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                autoComplete="off"
                disabled
                styleProps={{
                  width: '95%',
                  ...textInputDisableSxProps,
                  ...textInputSxProps,
                }}
                id="state"
                label="State"
                variant={variant}
                error={!!errors?.state}
                helperText={errors?.state?.message}
                value={addressData?.state}
                InputLabelProps={{
                  shrink: !!addressData?.state,
                }}
                required={required}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                autoComplete="off"
                disabled
                styleProps={{
                  width: '100%',
                  ...textInputDisableSxProps,
                  ...textInputSxProps,
                }}
                id="zip"
                label="Zip"
                variant={variant}
                value={addressData?.zipcode || addressData?.zip}
                InputLabelProps={{
                  shrink: !!addressData?.zipcode || !!addressData?.zip,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default EditModeAddressField;
