import { Box, Stack } from '@mui/material';
import moment from 'moment';
import { FC, useState } from 'react';
import { date, number, object } from 'yup';
import {
  loadService,
  locationService,
  tripService,
} from '../../../../../../api';
import { ServiceError } from '../../../../../../api/interfaces';
import {
  CreateLocationRequest,
  SplitLoadRequest,
  ValidateLocationsUniqueNamesRequest,
} from '../../../../../../models';
import {
  DeleteTripStopRequest,
  GetTripStopRequest,
  UpdateRelayStopPayload,
} from '../../../../../../models/DTOs/Trip/Requests';
import { RelayIcon } from '../../../../../../ui-kit/components/Assets';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';
import { TripStopStatusEnum } from '../../../../../../ui-kit/components/TripPlan';
import {
  nullableDateYup,
  nullableStringYup,
  validAddressYup,
} from '../../../../../../utils';
import FormDialog from '../../../../../Ui/FormDialog';
import { FormDialogMode } from '../../../../../Ui/FormDialog/constants';
import { useThemeResponsive } from '../../../../../hooks/useThemeResponsive';
import { relayStop2UpdatePayload, relayStopToSplitPayload } from '../../utils';
import { RelayStopForm, RelayStopFormData } from './RelayStopForm';

export const transformValidationDate = (value: any, rawValue: any) =>
  moment.isMoment(rawValue)
    ? rawValue.toDate()
    : value instanceof Date && !isNaN(value.getTime())
    ? value
    : undefined;

export interface LoadStopPopupProps {
  onClose: () => void;
  onCreated?: (data: any) => void;
  onUpdated?: (data: any) => void;
  onDeleted?: (data: Partial<RelayStopFormData>) => void;
  data: Partial<RelayStopFormData>;
  driverGroup?: string;
  isStatusAvailable: boolean;
}

export const appointmentValidationSchema = object().shape({
  startDate: date()
    .transform(transformValidationDate)
    .required('Start date and time is required.'),
  // .typeError('Start date and time is required.'),
  endDate: date()
    .transform(transformValidationDate)
    .when(
      ['startDate', 'fixedAppointment'],
      (startDate, fixedAppointment, schema) =>
        startDate && !fixedAppointment
          ? schema.min(
              startDate,
              'End date & time cannot be earlier than the Start date & time.'
            )
          : schema
    )
    .when(['fixedAppointment'], (fixedAppointment, schema) =>
      fixedAppointment
        ? schema
        : schema.required('End date and time is required.')
    ),
  handlingTime: number()
    .transform((value) => (isNaN(value) ? undefined : +value))
    .min(1, 'Value should be from 1m to 480m')
    .max(480, 'Value should be from 1m to 480m')
    .required('Handling Time is required.')
    .typeError('Only number is accepted.'),
  revenueShare: number()
    .transform((value) => (isNaN(value) ? undefined : +value))
    .min(0, 'Value should be from 0 to 100.')
    .max(100, 'Value should be from 0 to 100.')
    .required('Revenue share is required.'),
});

export const validationSchema = object().shape({
  address: object().shape({
    address: validAddressYup,
    city: nullableStringYup().required('City is required.'),
    state: nullableStringYup().required('State is required.'),
  }),
  dropOff: appointmentValidationSchema,
  pickup: appointmentValidationSchema,
});
export const relayAppointmentValidationSchema = (isDriverAssigned = true) =>
  object().shape({
    startDate: !isDriverAssigned
      ? nullableDateYup()
      : date()
          .transform(transformValidationDate)
          .required('Start date and time is required.'),
    // .typeError('Start date and time is required.'),
    endDate: !isDriverAssigned
      ? nullableDateYup()
      : date()
          .transform(transformValidationDate)
          .when(
            ['startDate', 'fixedAppointment'],
            (startDate, fixedAppointment, schema) =>
              startDate && !fixedAppointment
                ? schema.min(
                    startDate,
                    'End date & time cannot be earlier than the Start date & time.'
                  )
                : schema
          )
          .when(['fixedAppointment'], (fixedAppointment, schema) =>
            fixedAppointment
              ? schema
              : schema.required('End date and time is required.')
          ),
    handlingTime: number()
      .transform((value) => (isNaN(value) ? undefined : +value))
      .min(1, 'Value should be from 1m to 480m')
      .max(480, 'Value should be from 1m to 480m')
      .required('Handling Time is required.')
      .typeError('Only number is accepted.'),
    revenueShare: number()
      .transform((value) => (isNaN(value) ? undefined : +value))
      .min(0, 'Value should be from 0 to 100.')
      .max(100, 'Value should be from 0 to 100.')
      .required('Revenue share is required.'),
  });
export const relayValidationSchema = (isDriverAssigned = false) =>
  object().shape({
    address: object().shape({
      address: validAddressYup,
      city: nullableStringYup().required('City is required.'),
      state: nullableStringYup().required('State is required.'),
    }),
    dropOff: relayAppointmentValidationSchema(isDriverAssigned),
    pickup: relayAppointmentValidationSchema(isDriverAssigned),
  });

const NOT_ACCEPTABLE_STATUSES = [
  TripStopStatusEnum.EN_ROUTE,
  TripStopStatusEnum.ARRIVED,
  TripStopStatusEnum.CANCELLED,
  TripStopStatusEnum.COMPLETED,
];

const RelayStopPopup: FC<LoadStopPopupProps> = ({
  onClose,
  onCreated,
  onUpdated,
  onDeleted,
  data,
  driverGroup,
  isStatusAvailable = false,
}) => {
  const [aboutToDelete, setAboutToDelete] = useState<string>('...');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const contentRenderer = () => {
    return (
      <RelayStopForm
        isRevenueShareDisabled={!!data.id}
        isStartEndDateRequired={!(!driverGroup && isStatusAvailable)}
        onUpdateLoaderStatus={(val: boolean) => setIsLoading(val)}
      />
    );
  };
  const isAbleToDelete =
    data.id &&
    !NOT_ACCEPTABLE_STATUSES.includes(data[data.type]?.stopStatus) &&
    (data.type === 'pickup'
      ? !NOT_ACCEPTABLE_STATUSES.includes(data.dropOff?.stopStatus)
      : true);

  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const handleAction = async (formData: RelayStopFormData) => {
    const location = formData.businessName;
    if (location?.locationDisplayName && !location.id) {
      const locationName = location.locationDisplayName;
      const response = await locationService.validateLocationsUniqueNames(
        new ValidateLocationsUniqueNamesRequest({
          locations: [
            {
              id: 1,
              locationName,
            },
          ],
        })
      );
      if (
        !(response instanceof ServiceError) &&
        !!Object.values(response.locations).filter((value) => !value).length
      ) {
        const payload = new CreateLocationRequest();
        payload.getLocationOfLoadStop({
          addressDTO: formData.address,
          location: {
            locationName,
          },
          avgHandlingTime: formData.dropOff.handlingTime,
          contact: [],
        });
        const response = await locationService.createLocations([payload]);
        if (response && !(response instanceof ServiceError)) {
          formData.businessName = {
            ...response[0],
            locationDisplayName: response[0].locationName,
          };
        }
      }
    }
    if (!formData?.id) {
      const payload = relayStopToSplitPayload(formData);
      const response = await loadService.splitLoad(
        new SplitLoadRequest(payload)
      );
      // if (!(response instanceof ServiceError)) {
      onCreated?.(response);
      // }
    } else {
      const payload = relayStop2UpdatePayload(formData);
      const response = await tripService.updateRelayStop(
        new UpdateRelayStopPayload(payload)
      );
      if (!(response instanceof ServiceError)) {
        onUpdated?.(response);
      }
    }
  };

  const { isMobile, theme } = useThemeResponsive();
  const getTitleText = () => {
    const defaultTitleText = `${data.id ? 'Edit' : 'Add'} Relay`;
    if (!isMobile) return defaultTitleText;
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <RelayIcon color={theme?.palette?.primary?.main} />
        <Box>{defaultTitleText}</Box>
      </Stack>
    );
  };

  return (
    <>
      <FormDialog
        onDelete={
          isAbleToDelete
            ? async () => {
                setOpenDelete(true);
                if (data.type === 'dropOff') {
                  tripService
                    .getTripStop(
                      new GetTripStopRequest({
                        tripId: data.pickup?.tripId as string,
                      })
                    )
                    .then((response) => {
                      if (!(response instanceof ServiceError))
                        setAboutToDelete(response.seqNumber);
                    });
                } else setAboutToDelete(data.seqNumber as string);
              }
            : undefined
        }
        titleText={getTitleText()}
        mode={FormDialogMode.LIGHT}
        width={1062}
        open
        actionLabel={data?.id ? undefined : 'Add Relay'}
        disableActionBtnProp={isLoading}
        onAction={async (formData: RelayStopFormData) => {
          await handleAction(formData);
        }}
        data={data}
        handleClose={onClose}
        contentRenderer={contentRenderer}
        validationSchema={relayValidationSchema(
          !!driverGroup || !isStatusAvailable
        )}
      />
      <DeletePopup
        title="Delete relay stop ?"
        body={`Deleting the relay stop will delete the Trip ${aboutToDelete}`}
        open={openDelete}
        onAction={async () => {
          const response = await tripService.deleteTripStop(
            new DeleteTripStopRequest({
              tripId: data.loadId as string,
              stopId: data.legStopId as number,
            })
          );
          setOpenDelete(false);
          if (response && !(response instanceof ServiceError)) {
            onDeleted?.(data);
          }
        }}
        onClose={() => setOpenDelete(false)}
      />
    </>
  );
};

export default RelayStopPopup;
