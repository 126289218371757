import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Grid, Skeleton, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadSummary } from '../../../../subPages/loadsList/models/LoadSummary';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import IconsComponent from '../../../../ui-kit/components/IconsComponent';
import MultilineCellAxele from '../../../../ui-kit/components/MultilineCell';
import { emailValidation } from '../../../../views/settings/terminals/constants/constants';
import { Footer } from '../../../LoadSharedComponents/components/SaveChangesFooter';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../Permission';
import { AddTracker } from './AddTracker';
import { EditTracker } from './EditTracker';
import { VisibilityProvider } from './VisibilityPrivider';
import {
  deletePopOver,
  editCommunicationNotifications,
  editCommunicationNotificationsTurvo,
  phoneValidator,
} from './constants/constant';
import {
  CUDData,
  getAllAlerts,
  getContactList,
  getList,
  getTinyUrl,
  getTrackingURL,
  getVisibilityProviderIdsData,
  updateTracker,
} from './constants/helper';
import {
  AlertTypesSingleData,
  ContactListTypes,
  ListTypes,
  TrackingTypes,
} from './constants/interface';
import { styles } from './styles/styles';
import { t } from 'i18next';
export const Tracking = ({
  loadId = '',
  customerId = 0,
  isEdi,
}: TrackingTypes) => {
  const [alertTypes, setAlertTypes] = useState<AlertTypesSingleData[]>([]);
  const [list, setList] = useState<ListTypes[]>([]);
  const [contactList, setContactList] = useState<ContactListTypes[]>([]);
  const [bulkList, setBulkList] = useState<ListTypes[]>([]);
  const [deleteItem, setDeleteItem] = useState<ListTypes>({});
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [trackingURL, setTrackingURL] = useState<string>('');
  const [visibilityProviderIds, setVisibilityProviderIds] = useState({});
  const [panelItem, setPanelItem] = useState<LoadSummary | null>(null);
  const [notificationList, setNotificationList] = useState<
    { label: string }[] | []
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    trackingGet();
    getURL();
    getVisibilityProviderIds();
  }, [loadId]);

  const getURL = async () => {
    const haskKey: string = await getTrackingURL(loadId);
    const tempUrl = `${window.location.origin}/tnt?key=${haskKey}`;
    const tinuURLAPI = `${window.location.origin}/backendpublic/mediator/api/v2/notifications/sms/tinyurl?urlLongURL=${tempUrl}`;
    const res: string = await getTinyUrl(tinuURLAPI);
    setTrackingURL(res);
    setIsLoading(false);
  };

  const getVisibilityProviderIds = async () => {
    const res = await getVisibilityProviderIdsData(loadId);
    setPanelItem(res);
    if (res?.source === 'Turvo') {
      setNotificationList(editCommunicationNotificationsTurvo);
    } else {
      setNotificationList(editCommunicationNotifications);
    }
    setVisibilityProviderIds(
      res.visibilityProvidersIds ? res.visibilityProvidersIds : {}
    );
  };

  const trackingGet = async () => {
    const tempAlertsType: AlertTypesSingleData[] = await getAlertsTypeData();
    const tempContactList: ContactListTypes[] = await getContactNames();
    await getListData(tempAlertsType, tempContactList);
    setBulkList([]);
  };

  const getAlertsTypeData = async () => {
    const getAllAlertsRes = await getAllAlerts(loadId);
    setAlertTypes(getAllAlertsRes);
    return getAllAlertsRes;
  };

  const getContactNames = async () => {
    const getContactsRes = await getContactList(customerId);
    setContactList(getContactsRes);
    return getContactsRes;
  };

  const state = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: { list },
  });

  const getListData = async (
    tempAlertsType: AlertTypesSingleData[],
    tempContactList: ContactListTypes[]
  ) => {
    const getListRes = await getList(loadId, tempAlertsType, tempContactList);
    setList(getListRes);
  };

  const onDeleteClicked = async (item: ListTypes) => {
    setDeleteOpen(true);
    setDeleteItem(item);
  };

  const performDelete = async () => {
    setDeleteOpen(true);
    await CUDData(deleteItem, loadId, 'delete');
    setDeleteOpen(false);
    setDeleteItem({});
    await trackingGet();
  };
  const editTrackerValidation = () => {
    let isValid = true;
    const validationObject = bulkList.filter((data: ListTypes) => {
      if (data.name === '') {
        data.nameValidation = true;
        isValid = false;
      } else {
        data.nameValidation = false;
      }
      if (!data.email && !data.phone) {
        data.customValidation = true;
        isValid = false;
      } else {
        data.customValidation = false;
      }
      if (data?.phone && !phoneValidator.test(data.phone)) {
        data.phoneValidation = true;
        isValid = false;
      } else {
        data.phoneValidation = false;
      }
      if (data?.email && !emailValidation.test(data?.email || '')) {
        data.emailValidation = true;
        isValid = false;
      } else {
        data.emailValidation = false;
      }
      if (data.selectedList?.length === 0) {
        data.selecteValidation = true;
        isValid = false;
      } else {
        data.selecteValidation = false;
      }
      return data;
    });
    if (!isValid) {
      const updateValidation = list.filter((data: ListTypes) => {
        validationObject.map((record: ListTypes) => {
          if (data.id === record.id) return record;
        });
        return data;
      });
      setList(updateValidation);
    }
    return isValid;
  };
  const performSave = async () => {
    const isValidData = editTrackerValidation();
    if (!isValidData) return;
    let temp: any = [];
    for (const item of bulkList) {
      const singleUpdate = await CUDData(item, loadId, 'update');
      temp = [...temp, singleUpdate];
    }
    await updateTracker(temp);
    await trackingGet();
  };

  const renderAddTracker = () => (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <AddTracker
        alertTypes={alertTypes}
        contactList={contactList}
        loadId={loadId}
        trackingGet={trackingGet}
      />
      <MultilineCellAxele
        title={list.length.toString()}
        subtitle={t('loadsTrackingTotalNum')}
        titleStyle={styles.totalText}
        subtitleStyle={styles.totalSubText}
        alignTitle="right"
        alignSubtitle="right"
      />
    </Stack>
  );
  const renderURL = () => (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      mt={2}
    >
      <Stack direction="column" sx={{ maxWidth: 'calc(100% - 42px)' }}>
        <Typography align="left" sx={styles.urlSub}>
          {t('loadsTrackingURL')}
        </Typography>
        {isLoading ? (
          <Skeleton variant="text" height="2rem" />
        ) : (
          <Typography
            align="left"
            sx={styles.urlText}
            onClick={() => {
              window.open(trackingURL, '_blank').focus();
            }}
          >
            {trackingURL}
          </Typography>
        )}
      </Stack>
      <Box sx={styles.addButton}>
        <IconButton
          color="primary"
          onClick={() => {
            navigator.clipboard.writeText(trackingURL);
          }}
        >
          <IconsComponent iconName="ContentCopy" source="MUIcons" />
        </IconButton>
      </Box>
    </Stack>
  );

  const renderEdit = () => (
    <Box mb={10}>
      <EditTracker
        list={list}
        bulkList={bulkList}
        setBulkList={setBulkList}
        onDeleteClicked={onDeleteClicked}
      />
    </Box>
  );

  const renderVisibilityProvider = () => (
    <VisibilityProvider
      visibilityProviderIds={
        visibilityProviderIds && visibilityProviderIds.LOADSMART
          ? visibilityProviderIds.LOADSMART
          : ''
      }
    />
  );
  const saveContainerStyle = {
    position: 'absolute',
    right: '35px',
    width: 'inherit',
    textAlign: 'right',
    padding: '20px 0px',
    bottom: '0px',
  };

  const renderSaveButton = () => {
    const disabled = bulkList.length == 0;
    return (
      <>
        {!disabled && (
          <Grid container sx={{ pb: '25px', width: '100%' }}>
            <Grid item xs={12} md={12} sx={saveContainerStyle}>
              <Footer onSave={performSave} onCancel={trackingGet} />
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const renderDeletePopup = () => (
    <DeletePopup
      open={deleteOpen}
      onAction={() => performDelete()}
      onClose={() => {
        setDeleteOpen(false);
      }}
      title={deletePopOver.title}
      body={deletePopOver.body}
    />
  );

  // if (!trackingURL && !isLoading) {
  //   return <NoData />;
  // }

  return (
    <FormProvider {...state}>
      <Box id="TrackingContainer" sx={styles.trackingContainer}>
        {isEdi && (
          <Box
            className="edi-communication-wrapper"
            sx={styles.ediCommunicationWrapper}
          >
            <Box sx={{ p: '16px 24px' }}>
              <Typography sx={styles.labelStyles}>
                {panelItem?.source || 'EDI'} Communications is active
              </Typography>
              <Grid className="notifications" sx={{ pt: '8px' }}>
                {notificationList.map((data, index) => {
                  return (
                    <Grid key={index} display="flex" sx={{ p: '5px 0' }}>
                      <NotificationsActiveIcon
                        sx={{ width: '20px', color: 'success.main' }}
                      />
                      <Box sx={styles.notifiationLabel}>{data.label}</Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        )}
        {isLoading ? (
          <Skeleton
            height={'3rem'}
            sx={{
              borderRadius: '5px',
            }}
          />
        ) : (
          renderAddTracker()
        )}
        {isLoading ? (
          <Skeleton
            height={'3rem'}
            sx={{
              borderRadius: '5px',
            }}
          />
        ) : (
          renderURL()
        )}
        {isLoading ? (
          <Skeleton
            height={'3rem'}
            sx={{
              borderRadius: '5px',
            }}
          />
        ) : (
          renderEdit()
        )}
        {!isEmpty(visibilityProviderIds) && renderVisibilityProvider()}
        <Permission contains={[AXELE_PERMISSION_TYPE.LOAD_EDIT]}>
          {renderSaveButton()}
        </Permission>
        {renderDeletePopup()}
      </Box>
    </FormProvider>
  );
};
