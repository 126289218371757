import {
  Box,
  Link,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { loadService, tripService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { useRoutesLoad } from '../../../../contexts/LoadRoutesContext';
import {
  BrokeredUnAssignParams,
  ByLoadIdRequest,
  CreateLoadStopRequest,
  DeleteLoadStopRequest,
  GetAllStopsRequest,
  ResequenceLoadStopRequest,
  ResequenceLoadStopResponse,
  ResequenceTripStopResponse,
  TripLocation,
  TripStopResponse,
  UpdateLoadStopRequest,
} from '../../../../models';
import {
  CreateTripStopRequest,
  DeleteTripStopRequest,
  GetRelayStop,
  GetTripStopRequest,
  ResequenceTripStopRequest,
  UpdateTripStopRequest,
} from '../../../../models/DTOs/Trip/Requests';
import LoadController from '../../../../subPages/loadsList/LoadController';
import { Stop } from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { LoadSummary } from '../../../../subPages/loadsList/models/LoadSummary';
import { updateNoteDetails } from '../../../../utils/loads';
import { defaultStopData } from '../../../LoadSharedComponents/constants';
import NoData from '../../component/NoData';
import { IOpenSecondaryDetailsHandler } from '../../constants/interface';
import FinanceOverviewFormWithProvider from '../FinanceOverview/FinanceOverview';
import { RoutesInfo, Trip, TripStop } from './Models';
import DispatchSheet from './components/DispatchSheet';
import KPI from './components/KPI';
import { LoadStopPopup } from './components/LoadStopPopup';
import RelayStopPopup from './components/RelayStopPopup';
import { RelayStopFormData } from './components/RelayStopPopup/RelayStopForm';
import UpdateStopActivitiesDialog from './components/UpdateStopDialog';
import { UpdateActivityFormData } from './components/UpdateStopDialog/components/Form/UpdateStopForm';
import {
  convertToTripSpecificFormat,
  setLoadStopsResequenceRules,
} from './uiUtils';
import {
  initCreateRelayStopFormData,
  initUpdateActivitiesFormData,
  initUpdateRelayStopFormData,
  validateAndSaveStopLocation,
} from './utils';

import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import EventEmitter from '../../../../EventEmitter/EventEmitter';
import { EventTypes } from '../../../../EventEmitter/EventTypes';
import { LoadTripActionData } from '../../../../EventEmitter/Events/EventLoadTripAction';
import EventTripStopAction, {
  EEventTripStopAction,
  EventTripStopActionData,
} from '../../../../EventEmitter/Events/EventTripStopAction';
import EventUpdateProgress, {
  EventUpdateProgressData,
} from '../../../../EventEmitter/Events/EventUpdateProgress';
import { LoadConstants } from '../../../../locales/en/allLoads/loads';
import { filterDriverListByLocation } from '../../../../services/map/driverLocation.service';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import {
  RootStoreInstence,
  useRootStore,
} from '../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../store/root.context';
import { TripV3ListWithAccordion } from '../../../../ui-kit/TripPlan';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import {
  ExpandableTimelineV3PanelView,
  StopSolutionV3NormalStop,
  StopSolutionV3Prop,
  StopSolutionV3StopType,
  TripStopStatusEnum,
  TripV3DataProps,
} from '../../../../ui-kit/components/TripPlan';
import useEmit, {
  EventCallbackFunction,
} from '../../../../utils/hooks/useEmit';
import { ESecondaryDetailsPanelType } from '../../../../views/dispatch2/constants/types';
import { searchHelperLinkStyle } from '../../../Layouts/SearchModal/style';
import PopupMap from '../../../Ui/Maps/PopupMap/PopupMap';
import { MarkersData } from '../../../Ui/Maps/types';
import { useLoadPermission } from '../../../hooks/useLoadPermission';
import { getLoadMapData } from './RoutesController';
import { TripAssignmentEntityType } from './components/types';

interface IStopPopupState {
  open: boolean;
  data: Stop;
  tripId?: string | null;
  previousTripStopId: number | null;
  loadID: number | null;
  isFirstStop: boolean;
  isLastStop: boolean;
  deleteAction: boolean;
  isManifestLoad?: boolean;
  LoadType?: string;
  buttonText?: string;
  driverGroup?: string;
  tripStatus?: string;
}

interface IDeletePopupState {
  open: boolean;
  moveUp: boolean;
  loadStopId: number | null;
  targetTripId: number | null;
  initialTripId: number | null;
  title: string;
  onAction?: () => void;
  body: string;
  buttonText: string;
  bgColor?: string;
}

export const loadIdStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
};
function LoadRoutes({
  id,
  loadData,
  onUpdateActivitiesCompleted,
  openSecondaryDetailsHandler,
  loadType,
  onUpdateMainData,
}: {
  id: string;
  loadType?: string;
  loadData: LoadSummary & { lastUpdateTime?: number };
  onUpdateActivitiesCompleted?: () => void;
  onUpdateMainData?: () => void;
  onUpdated?: () => void;
} & IOpenSecondaryDetailsHandler) {
  const {
    selectedDriverId,
    setSelectedDriverId,
    driverAmountForAssign,
    setDriverAmountForAssign,
  } = useRoutesLoad();
  const {
    myLoadStore: { setUpdatedLoadId },
  } = useStores();
  const { hasLoadEditPermission } = useLoadPermission();
  const [routesInfo, setRoutesInfo] = useState<RoutesInfo | null>();
  const [relayErr, setRelayErr] = useState<any>(null);
  const [relayStop, setRelayStop] = useState<Partial<RelayStopFormData> | null>(
    null
  );
  const [isResequencing, setIsResequencing] = useState(false);
  const [isOpeningTripAssignment, setOpeningTripAssignment] =
    useState<boolean>(false);
  const { getTripPlanReadyEvent, resetTripPlanReadyEvent } = useRootStore();
  const [isLoading, setIsLoading] = useState(true);
  const { trackPromise } = useDetailsPanelStore;
  const [tripDataList, setTripDataList] = useState<TripV3DataProps[]>([]);
  const [cancelledStops, setCancelledStops] = useState<TripStop[]>([]);
  const [loadStopPopupState, setLoadStopPopupState] = useState<IStopPopupState>(
    {
      open: false,
      data: {} as Stop,
      tripId: null,
      previousTripStopId: null,
      isLastStop: false,
      isFirstStop: false,
      deleteAction: false,
      isManifestLoad: loadData.loadType === 'MANIFEST' ? true : false,
      LoadType: '',
      loadID: null,
      buttonText: '',
      driverGroup: '',
    }
  );
  const deletePopupDefaultState: IDeletePopupState = {
    open: false,
    moveUp: false,
    loadStopId: null,
    targetTripId: null,
    initialTripId: null,
    title: '',
    body: '',
    buttonText: '',
  };
  const [deletePopupState, setDeletePopupState] = useState<IDeletePopupState>(
    deletePopupDefaultState
  );
  const [activities, setActivities] = useState<UpdateActivityFormData | null>(
    null
  );
  const [openMapPopup, setOpenMapPopup] = useState<boolean>(false);
  const [mapData, setMapData] = useState<MarkersData>();
  const [editingStopProp, setEditingStopProp] =
    useState<StopSolutionV3NormalStop | null>(null);
  const onChangedTripStop = (
    action: EEventTripStopAction,
    overrideData: Partial<EventTripStopActionData> = {}
  ): void => {
    EventEmitter.send(
      new EventTripStopAction({
        toType: ESecondaryDetailsPanelType.TRIP,
        action,
        loadId: id,
        ...overrideData,
      })
    );
  };
  const [isAvailableStatus, setAvailableStatus] = useState<boolean>(false);

  const refreshExternalData = async () => {
    setUpdatedLoadId?.(id);
  };

  const handleAssignmentChange = (data) => {
    getTripData();
    // if (data.trip.loadId === id) {
    //   getTripData();
    // }
  };

  const handleLoadTripAction = (data: LoadTripActionData) => {
    getTripData();
    // Don't remove code to validate bussiness logic
    // if (
    //   // data.toStatus !== ELoadStatus.DELETED && //Delete TRIP on hyperlink need to refresh data too
    //   // example load have 2 trio -> open TRIP by hyperlink and delete -> refresh data
    //   (data.entity === 'Load' && data.id === loadData.id) ||
    //   (data.entity === 'Trip' && data.loadId === loadData.id)
    // ) {
    //   getTripData();
    // }
  };
  const handleTripStopAction = (data: EventTripStopActionData) => {
    getTripData();
    // Don't remove code to validate bussiness logic
    // if (data.loadId === loadData.id) {
    //   getTripData();
    // }
  };

  const handleUpdateProgress: EventCallbackFunction<EventUpdateProgressData> = (
    data
  ) => {
    getTripData();
    // Don't remove code to validate bussiness logic
    // if (data.toTypes.includes(ESecondaryDetailsPanelType.LOAD)) {
    //   getTripData();
    // }
  };

  useEmit({
    [EventTypes.TRIP_ASSIGNMENT_CHANGE]: handleAssignmentChange,
    [EventTypes.LOAD_TRIP_ACTION]: handleLoadTripAction,
    [EventTypes.TRIP_STOP_ACTION]: handleTripStopAction,
    [EventTypes.UPDATE_PROGRESS]: handleUpdateProgress,
  });

  const commonConvertTripParams: Pick<
    IOpenSecondaryDetailsHandler,
    'showProgressIcon' | 'disabled' | 'onHold' | 'disableAssignment'
  > = {
    showProgressIcon: hasLoadEditPermission && !loadData.onHold,
    disabled: !hasLoadEditPermission || loadData.onHold,
    onHold: loadData.onHold,
    disableAssignment: {
      [TripAssignmentEntityType.DRIVER]: loadData.onHold
        ? {
            disabled: !!loadData.onHold,
            reason: LoadConstants.loadIsCurrentlyOnHold,
          }
        : undefined,
    },
  };
  const getTripData = () => {
    const requestData = new ByLoadIdRequest();
    requestData[loadData.loadType != 'MANIFEST' ? 'loadId' : 'manifestId'] = id;
    trackPromise(
      LoadController.instance().getLoadRoutes(
        requestData,
        (routes: RoutesInfo) => {
          setRoutesInfo(routes);
          const tripDataList = convertToTripSpecificFormat(routes.trips, {
            openSecondaryDetailsHandler,
            isManifest: false,
            isAssignmentDisable: false,
            reasonAssignmentDisable: loadData.onHold
              ? LoadConstants.loadIsCurrentlyOnHold
              : '',
            isManifestDetailPanel:
              loadData.loadType === 'MANIFEST' ? true : false,
            isOpeningTripAssignment: false,
            setOpeningTripAssignment,
            ...commonConvertTripParams,
          });
          setCancelledStops(routes.cancelledStops);
          setLoadStopsResequenceRules(
            tripDataList,
            false,
            loadData.loadType === 'MANIFEST' ? true : false,
            true,
            true
          );
          setTripDataList(tripDataList);
          setIsLoading(false);
        }
      ),
      ESecondaryDetailsPanelType.LOAD
    );
  };

  useEffect(() => {
    if (routesInfo?.trips) {
      const tripDataList = convertToTripSpecificFormat(routesInfo.trips, {
        openSecondaryDetailsHandler,
        isManifest: false,
        isAssignmentDisable: false,
        reasonAssignmentDisable: loadData.onHold
          ? LoadConstants.loadIsCurrentlyOnHold
          : '',
        isManifestDetailPanel: loadData.loadType === 'MANIFEST' ? true : false,
        isOpeningTripAssignment,
        setOpeningTripAssignment,
        ...commonConvertTripParams,
      });
      setTripDataList(tripDataList);
    }
  }, [
    isOpeningTripAssignment,
    setOpeningTripAssignment,
    loadData.onHold,
    loadData.loadType,
    hasLoadEditPermission,
    JSON.stringify(commonConvertTripParams),
  ]);

  useEffect(() => {
    getTripData();
    //Do not change/Important: @Sinh
    //add loaddata?.status will trigger re-rendering and cause 2 times call this APIs
    //loaddata?.status dependency will be handled in side effect with DependencyList getTripPlanReadyEvent
  }, [id, loadData?.lastUpdateTime, loadData?.loadType]);

  useEffect(() => {
    if (
      getTripPlanReadyEvent &&
      !!loadData.driverGroups?.find(
        (driver) => driver.id === getTripPlanReadyEvent?.driverGroupId
      )
    ) {
      getMapData(id); // hot fix, map should not get if we have not opened it by clicking to marker
      getTripData();
      setUpdatedLoadId?.(id);
      resetTripPlanReadyEvent();
    }
  }, [getTripPlanReadyEvent]);

  const getMapData = async (id: string) => {
    const response = await trackPromise(
      getLoadMapData(id),
      ESecondaryDetailsPanelType.LOAD
    );
    const markersList: any = [];
    response?.markers?.map((marker: any) => {
      if (marker.driverToolTip) {
        const driverMarker = filterDriverListByLocation({
          drivers: marker?.driverToolTip?.drivers?.map?.((e: any) => ({
            ...e,
            routeFlag: marker?.routeFlag,
          })),
          currentWeek: marker?.driverToolTip?.currentWeek,
          hosConstants: marker?.driverToolTip?.hosConstants,
          isTooltipActionDisabled: true,
        });
        driverMarker?.locatedDrivers?.length
          ? markersList.push(...driverMarker?.locatedDrivers)
          : '';
      } else {
        markersList.push(marker);
      }
    });
    setMapData({ markers: markersList } as unknown as MarkersData);
  };

  const closeDeleteTripPopup = () => {
    setDeletePopupState(deletePopupDefaultState);
    if (relayErr) {
      setRelayErr(null);
    }
  };

  const closeLoadStopPopup = () => {
    setLoadStopPopupState({
      open: false,
      data: {} as Stop,
      tripId: null,
      previousTripStopId: null,
      isFirstStop: false,
      isLastStop: false,
      deleteAction: false,
      LoadType: '',
      loadID: null,
      isManifestLoad: loadData.loadType === 'MANIFEST' ? true : false,
      buttonText: '',
      tripStatus: '',
    });
  };

  const handleLoadStopPopupAction = async (stop: Stop, formState: any) => {
    const { savedLocationId } = await validateAndSaveStopLocation(stop);
    if (savedLocationId && stop.location?.id) {
      stop.location.id = savedLocationId;
    }
    updateNoteDetails(stop, 'notes', formState.dirtyFields);
    if (!loadStopPopupState.data?.id) {
      await createStop(stop);
    } else {
      await updateStop(stop);
    }
    await refreshExternalData();
    closeLoadStopPopup();
  };

  const onCreateStop = (prevStop: StopSolutionV3NormalStop) => {
    setEditingStopProp(prevStop);
    const stopData = new Stop();
    stopData.getStopDetailsFromLoadStopDTO({
      ...defaultStopData,
      activityType: 'PICKUP',
    });
    const trip = routesInfo?.trips.find(
      ({ id }) => id === prevStop.tripId
    ) as Trip;
    setLoadStopPopupState((prevState) => ({
      ...prevState,
      open: true,
      data: stopData,
      tripId: prevStop.tripId,
      previousTripStopId: prevStop.id,
      LoadType: '',
      loadID: null,
      isManifestLoad: loadData.loadType === 'MANIFEST' ? true : false,
      buttonText: '',
      tripStatus: trip.tripStatus,
    }));
  };

  const onUpdateStop = async (
    stopProp: StopSolutionV3NormalStop,
    tripProp: TripV3DataProps
  ) => {
    setEditingStopProp(stopProp);
    const { trips } = routesInfo || {};
    let isFirstOrLastTrip = false;
    const tripsCount = trips?.length || 0;
    const trip = trips?.find(({ id }, index: number) => {
      isFirstOrLastTrip = !index || index === tripsCount - 1;
      return id === tripProp.id;
    }) as Trip;
    const stopIndex = trip.stops.findIndex(({ id }) => +id === stopProp.id);
    const stop = trip.stops[stopIndex] as TripStop;
    if (stopProp.type === StopSolutionV3StopType.RELAY) {
      const response = await tripService.getRelayStop(
        new GetRelayStop({
          relayStopId: stopProp.id,
          tripId: tripProp.id,
        })
      );
      if (!(response instanceof ServiceError)) {
        const type = stopIndex === 0 ? 'pickup' : 'dropOff';
        setRelayStop(initUpdateRelayStopFormData(response, stop, trip, type));
      }
    } else {
      const stops = tripProp.stopList?.filter((stop) => stop.type === 'LOAD');
      const isFirstStop = stopProp.id === stops[0]?.id;
      const isLastStop = stopProp.id === stops[stops.length - 1].id;
      const loadStop = new Stop();
      const tripStop = trip?.stops.find(
        (tripStop) => +tripStop.id === stopProp.id
      );
      loadStop.getStopDetailsFromLoadStopDTO(tripStop);
      if (tripStop?.type === 'LOAD') {
        loadStop.loadStopId = tripStop.loadStopId;
      }
      setLoadStopPopupState((prevState) => ({
        ...prevState,
        open: true,
        data: loadStop,
        isFirstStop,
        isLastStop,
        isManifestLoad: loadData.loadType === 'MANIFEST' ? true : false,
        LoadType: tripStop?.type,
        deleteAction: loadStopPopupState.isManifestLoad
          ? tripStop?.type === 'TRIP'
            ? true
            : false
          : stopProp.type === 'LOAD' &&
            stopProp.status === TripStopStatusEnum.NONE &&
            ((tripsCount === 1 && stops.length > 2) ||
              (tripsCount > 1 && (!isFirstOrLastTrip || stops.length > 1))),
        tripId: tripProp.id,
        loadID:
          tripStop?.type != 'LOAD'
            ? tripStop?.loadStopId
            : tripStop?.loadDetails?.[0]?.loadId,
        driverGroup: trip?.driverGroup,
        tripStatus: trip.tripStatus,
      }));
    }
    getTripData();
  };
  const createStop = async (stop: Stop) => {
    if (loadType === 'MANIFEST') {
      const response = await tripService.createTripStop(
        new CreateTripStopRequest({
          stop,
          tripId: loadStopPopupState.tripId,
          previousTripStopId: loadStopPopupState.previousTripStopId,
        })
      );
      if (response) {
        getTripData();
      }
    } else {
      const requestData = new CreateLoadStopRequest({
        stop,
        loadId: id,
        tripId: loadStopPopupState.tripId,
        previousTripStopId: loadStopPopupState.previousTripStopId,
      });
      await LoadController.instance().createLoadStop(requestData, () => {
        getTripData();
      });

      onChangedTripStop(
        EEventTripStopAction.CREATED,
        editingStopProp && editingStopProp?.tripId
          ? {
              tripId: editingStopProp.tripId,
              updatedTripIds: [editingStopProp.tripId],
            }
          : {}
      );
    }
  };

  const updateStop = async (stop: Stop) => {
    if (
      loadStopPopupState.isManifestLoad &&
      loadStopPopupState.LoadType === 'TRIP'
    ) {
      const requestData = await tripService.updateTripStop(
        new UpdateTripStopRequest({
          stop,
          loadId: loadStopPopupState.tripId,
        })
      );
      if (requestData) {
        getTripData();
      }
    } else {
      const requestData = new UpdateLoadStopRequest({
        stop: {
          ...stop,
          id: stop.loadStopId || stop.id,
        },
        loadId: loadStopPopupState.isManifestLoad
          ? loadStopPopupState?.loadID
          : id,
      });
      requestData.stopDTO.noteDetails.note = undefined; // @TODO: Nvard, please have a look on this requestData obj, in the case of update it should not have note.
      await LoadController.instance().updateLoadStop(requestData, () => {
        getTripData();
      });
      onChangedTripStop(
        EEventTripStopAction.UPDATED,
        editingStopProp && editingStopProp?.tripId
          ? {
              tripId: editingStopProp.tripId,
              updatedTripIds: [editingStopProp.tripId],
            }
          : {}
      );
    }
    getTripData();
  };

  const deleteLoadStop = async () => {
    const { data, tripId, isFirstStop, isLastStop } = loadStopPopupState;
    const requestData = new DeleteLoadStopRequest({
      stopId: data.loadStopId || data.id,
      loadId:
        loadStopPopupState.isManifestLoad &&
        loadStopPopupState.LoadType === 'TRIP'
          ? tripId
          : id,
    });
    const tripDetails = await tripService.getTripStop(
      new GetTripStopRequest({
        tripId: tripId as string,
        fetchHosData: true,
      })
    );

    if (tripDetails instanceof TripStopResponse) {
      if (
        (isFirstStop && !tripDetails.stops[1].activityType) ||
        (isLastStop && !tripDetails.stops.at(-2)?.activityType)
      ) {
        const stop = isFirstStop ? 'next' : 'previous';
        setDeletePopupState((prevState) => ({
          ...prevState,
          open: true,
          body: `Deleting this load stop will also delete the ${stop} trip Stop`,
          title: 'Are you sure you want to delete this load stop?',
          onAction: async () => {
            closeDeleteTripPopup();

            await handleDeleteLoadStop(requestData);
            getTripData();
          },
        }));
      } else {
        await handleDeleteLoadStop(requestData);
        getTripData();
      }
    }
  };

  async function handleDeleteLoadStop(requestData: DeleteLoadStopRequest) {
    if (
      loadStopPopupState.isManifestLoad &&
      loadStopPopupState.LoadType === 'TRIP'
    ) {
      const response = await tripService.deleteTripStop(
        new DeleteTripStopRequest({
          tripId: requestData.loadId,
          stopId: requestData.stopId,
        })
      );
      if (response) {
        getTripData();
      }
    } else {
      await LoadController.instance().deleteLoadStop(requestData, () => {
        getTripData();
      });
    }

    onChangedTripStop(
      EEventTripStopAction.DELETED,
      editingStopProp && editingStopProp?.tripId
        ? {
            tripId: editingStopProp.tripId,
            updatedTripIds: [editingStopProp.tripId],
          }
        : {}
    );
    await refreshExternalData();
    getTripData();
    closeLoadStopPopup();
  }
  const deleteTrip = async (stopId: number, moveState: boolean) => {
    const request = new ResequenceLoadStopRequest({
      loadId: id,
      loadStopId: stopId,
      moveUp: moveState,
      applyDeletion: true,
    });
    const response = await loadService.resequenceLoadStop(request);
    if (response instanceof ResequenceLoadStopResponse && response.applied) {
      closeDeleteTripPopup();
      getTripData();
      await refreshExternalData();
    }
  };

  const onClickResequenceHandler = async (
    stop: StopSolutionV3Prop,
    moveUp: boolean,
    isDeleteMove = false
  ) => {
    setIsResequencing(true);
    if (loadStopPopupState.isManifestLoad) {
      const request = new ResequenceTripStopRequest({
        tripId: stop.tripId,
        tripStopId: stop.id,
        moveUp,
        applyMoveDeletion: isDeleteMove,
      });
      const response = await tripService.resequenceTripStop(request);
      if (response instanceof ResequenceTripStopResponse) {
        const { applied } = response;
        if (!applied) {
          const { removableTripIdToSeqNumber, updatedTripIdToSeqNumber } =
            response;
          if (
            removableTripIdToSeqNumber &&
            removableTripIdToSeqNumber[stop.tripId]
          ) {
            const { previousTrip, nextTrip } = stop;
            const targetTripId = moveUp
              ? previousTrip?.seqNumber || ''
              : nextTrip?.seqNumber || '';
            const body = `This action will move the stop to the trip ${targetTripId} and also delete the Trip(s) ${
              removableTripIdToSeqNumber[stop.tripId]
            }.`;
            setDeletePopupState({
              open: true,
              tripStopId: stop.id,
              moveUp,
              body,
              deleteCurrentTrip: true,
              buttonText: 'Continue',
              onAction: async () => {
                onClickResequenceHandler(stop, moveUp, true);
                closeDeleteTripPopup();
                closeLoadStopPopup();
                getTripData();
              },
            });
            setIsResequencing(false);
          } else if (!isEmpty(updatedTripIdToSeqNumber)) {
            const targetTripId = Object.values(updatedTripIdToSeqNumber)[0];
            if (targetTripId) {
              setDeletePopupState({
                open: true,
                tripStopId: stop.id,
                moveUp,
                title: 'Re-sequence',
                body: `Re-sequencing the stop will will move the stop to the trip ${targetTripId}.`,
                deleteCurrentTrip: false,
                buttonText: 'Continue',
                onAction: async () => {
                  onClickResequenceHandler(stop, moveUp, true);
                  closeDeleteTripPopup();
                  closeLoadStopPopup();
                  getTripData();
                },
              });
              setIsResequencing(false);
            } else {
              getTripData();
              onChangedTripStop(EEventTripStopAction.RESEQUENCED);
              refreshExternalData();
              setIsResequencing(false);
            }
          }
        } else {
          getTripData();
          onChangedTripStop(EEventTripStopAction.RESEQUENCED);
          refreshExternalData();
          setIsResequencing(false);
        }
      } else {
        setDeletePopupState({
          open: true,
          title: 'Info',
          body: response?.composedError?.message?.response?.data?.message,
          bgColor: theme.palette.primary.main,
        });
        setIsResequencing(false);
      }
    } else {
      const request = new ResequenceLoadStopRequest({
        loadId: id,
        loadStopId: stop.loadStopId,
        moveUp,
        applyDeletion: false,
      });
      const response = await loadService.resequenceLoadStop(request);
      if (response instanceof ResequenceLoadStopResponse) {
        const { applied } = response;
        if (!applied) {
          const { removableTripIdToSeqNumber } = response;
          const targetTripId = moveUp
            ? stop.prevTrip?.seqNumber
            : stop.nextTrip?.seqNumber;
          setDeletePopupState({
            open: true,
            loadStopId: stop.loadStopId,
            initialTripId: removableTripIdToSeqNumber[stop.tripId],
            targetTripId: targetTripId,
            moveUp,
            title: 'Re-sequence',
            onAction: () => {
              deleteTrip(stop.loadStopId, moveUp);
              getTripData();
            },
            body: `This action will move the stop to the trip ${targetTripId} and also delete the Trip(s) ${
              removableTripIdToSeqNumber[
                stop.tripId as keyof typeof removableTripIdToSeqNumber
              ]
            }.`,
          });
          setIsResequencing(false);
        } else {
          getTripData();
          const tripIndex = routesInfo?.trips?.findIndex(
            (trip) => trip?.id === stop.tripId
          );
          onChangedTripStop(
            EEventTripStopAction.RESEQUENCED,
            stop.tripId
              ? {
                  tripId: stop.tripId,
                  updatedTripIds: [
                    stop.tripId,
                    tripIndex &&
                      routesInfo?.trips?.[
                        moveUp ? tripIndex - 1 : tripIndex + 1
                      ]?.id,
                  ].filter(Boolean) as string[],
                }
              : {}
          );
          await refreshExternalData();
          getTripData();
          setIsResequencing(false);
        }
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'tripResequience',
        });
        setIsResequencing(false);
      }
    }
  };

  const onPaymentTmpUpdate = (amountSelected) => {
    setDriverAmountForAssign((prev) => ({
      ...prev,
      [amountSelected.driverId ||
      amountSelected.tractorId ||
      amountSelected.dispatcherId ||
      amountSelected.carrierId]: amountSelected,
    }));
  };
  const renderFinance = () => {
    if (selectedDriverId) {
      return (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 100,
            backgroundColor: 'primary.contrast',
            height: '100%',
            left: '-16px',
            right: '-16px',
            bottom: 0,
            overflow: 'scroll',
          }}
        >
          <FinanceOverviewFormWithProvider
            loadId={id}
            onClose={() => setSelectedDriverId(null)}
            openPayments={{
              type: selectedDriverId.type,
              driverAmountForAssign: driverAmountForAssign[selectedDriverId.id],
              onPaymentTmpUpdate,
            }}
            panelType={ESecondaryDetailsPanelType.LOAD}
          />
        </Box>
      );
    }
  };
  const theme = useTheme();
  const onMapClick = () => {
    setOpenMapPopup(true);
    getMapData(id);
  };

  const onClickBrokeredHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    item: TripV3DataProps,
    index: number
  ) => {
    const request = new BrokeredUnAssignParams({
      tripId: item.id,
      isBrokered: checked,
    });
    const response = await trackPromise(
      loadService.brokeredUnAssign(request),
      ESecondaryDetailsPanelType.LOAD
    );
    if (!(response instanceof ServiceError)) {
      getTripData();
      onUpdateMainData?.();
    }
  };

  useEffect(() => {
    if (relayStop || loadStopPopupState.open) {
      setAvailableStatus(
        relayStop?.tripStatus === 'AVAILABLE' ||
          loadStopPopupState?.tripStatus === 'AVAILABLE'
      );
      return;
    }
    setAvailableStatus(false);
  }, [loadStopPopupState.open, relayStop]);

  if (!routesInfo && !isLoading) return <NoData />;

  return (
    <Box
      sx={{
        height: '100%',
        padding: '16px 0px',
        position: 'relative',
        overflow: selectedDriverId ? 'hidden' : '',
      }}
    >
      {renderFinance()}
      {isLoading ? (
        <Stack direction="row" spacing={1}>
          <Skeleton
            variant="rectangular"
            height={'4rem'}
            width={'100%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectangular"
            height={'4rem'}
            width={'100%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectangular"
            height={'4rem'}
            width={'100%'}
            sx={{
              borderRadius: '5px',
            }}
          />
        </Stack>
      ) : (
        <>
          <KPI
            kpiInfo={{
              stopsNumber: routesInfo.stopsNumber,
              loadedMiles:
                loadType != 'MANIFEST'
                  ? routesInfo.loadedMiles
                  : routesInfo.tripMiles,
            }}
            loadType={loadType}
            appendNodeRender={() => {
              if (
                loadData.loadType === 'LTL' &&
                loadData.manifestBaseDetails?.seqNumber
              ) {
                return <></>;
              }
              return (
                <ButtonImproved
                  onClick={onMapClick}
                  label={t('TripPlanViewMap')}
                  variant={'text'}
                  styleProps={{
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                  }}
                ></ButtonImproved>
              );
            }}
          />
        </>
      )}
      {isLoading ? (
        <Skeleton
          variant={'rectangular'}
          width="100%"
          height={'5rem'}
          sx={{
            borderRadius: '5px',
            mt: 1,
          }}
        />
      ) : (
        <PopupMap
          openPopup={openMapPopup}
          handleClose={() => setOpenMapPopup(false)}
          markersList={mapData ? mapData.markers : []}
          generateRoute
        />
      )}

      {loadType != 'MANIFEST' && (
        <Box sx={{ pt: 1 }}>
          {isLoading ? (
            <Skeleton
              variant={'rectangular'}
              width="100%"
              sx={{
                borderRadius: '5px',
                height: '5rem',
              }}
            />
          ) : (
            <DispatchSheet
              loadId={id}
              seqNumber={routesInfo?.seqNumber}
              terminal={routesInfo?.terminalName}
              isDisabledAllAction={loadData.onHold}
            />
          )}
        </Box>
      )}

      {loadData.loadType === 'LTL' &&
        loadData.manifestBaseDetails?.seqNumber && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            sx={{ padding: '0px 0px 5px 0px' }}
          >
            {isLoading ? (
              <Skeleton variant="rectangular" height={'2rem'} />
            ) : (
              <Typography sx={loadIdStyle}>
                Manifest ID:
                <Link
                  sx={searchHelperLinkStyle}
                  href="#"
                  onClick={() => {
                    openSecondaryDetailsHandler?.(
                      loadData.manifestBaseDetails.manifestId,
                      'LOAD'
                    );
                  }}
                >
                  {loadData.manifestBaseDetails?.seqNumber}
                </Link>
              </Typography>
            )}
          </Stack>
        )}

      <Box sx={{ ml: -2, mr: -2 }} className={'TripV3ListWithAccordion'}>
        <TripV3ListWithAccordion
          isResequencing={isResequencing}
          onClickEditIconHandler={onUpdateStop}
          onClickAddStopHandler={onCreateStop}
          onClickResequenceUpHandler={(stop: StopSolutionV3Prop) => {
            onClickResequenceHandler(stop, true);
          }}
          onClickResequenceDownHandler={(stop: StopSolutionV3Prop) => {
            onClickResequenceHandler(stop, false);
          }}
          tripDataList={tripDataList}
          cancelledStops={cancelledStops}
          isManifest={false}
          idEditAddEnable={false}
          isManifestDetailPanel={
            loadData.loadType === 'MANIFEST' ? true : false
          }
          isMultipleExpanded
          panelView={ExpandableTimelineV3PanelView.LoadDetails}
          onClickAddRelayHandler={(leg, tripProp) => {
            setEditingStopProp(leg);
            const trip = routesInfo?.trips.find(
              ({ id }) => id === tripProp.id
            ) as Trip;
            const stop = trip.stops.find(
              ({ id }) => +id === leg.id
            ) as TripStop;
            setRelayStop(initCreateRelayStopFormData(stop, trip));
          }}
          onClickLoadIdHandler={(stopSolution: StopSolutionV3Prop) => {
            const loadId = stopSolution?.loadDetails[0]?.loadId;
            if (loadId) {
              openSecondaryDetailsHandler?.(loadId, 'LOAD');
            }
          }}
          onClickProgressIconHandler={async (a, tripProp) => {
            const tripIndex = routesInfo?.trips.findIndex(
              ({ id }) => id === tripProp.id
            );
            const trip = routesInfo.trips[tripIndex] as Trip;
            const tripStop = trip.stops[0];
            const prevTrip = routesInfo?.trips[tripIndex - 1];
            const response = await loadService.getAllActivities(
              new GetAllStopsRequest({
                tripOrLoadId: tripProp.id,
              })
            );
            if (!(response instanceof ServiceError)) {
              setActivities(
                initUpdateActivitiesFormData(
                  response,
                  trip.id,
                  trip.tripStatus,
                  tripStop.location as TripLocation,
                  'TRAILER',
                  prevTrip?.tripStatus,
                  trip.trailer,
                  trip.tractor
                )
              );
            }
          }}
          onClickBrokeredHandler={onClickBrokeredHandler}
        />
      </Box>
      {relayStop && (
        <RelayStopPopup
          onCreated={(response) => {
            if (Boolean(response?.composedError?.error?.response)) {
              setRelayStop(null);
              setRelayErr(response.composedError.error.response);
              return;
            }
            setRelayStop(null);
            getTripData();
            setUpdatedLoadId?.(id);
            onChangedTripStop(EEventTripStopAction.CREATED, {
              updatedTripIds: Array.from(
                new Set([
                  response?.pickupLoad?.id,
                  response?.dropoffLoad?.id,
                  relayStop?.loadId,
                ])
              )?.filter(Boolean),
            });
          }}
          onUpdated={() => {
            setRelayStop(null);
            getTripData();
            onChangedTripStop(EEventTripStopAction.UPDATED);
          }}
          onDeleted={() => {
            setRelayStop(null);
            getTripData();
            setUpdatedLoadId?.(id);
            const currentTripIndex = routesInfo?.trips.findIndex(
              ({ id }) => id === relayStop?.loadId
            );
            let updatedTripIds: string[] = [relayStop?.loadId as string];
            if (relayStop?.type === 'pickup') {
              updatedTripIds.push(routesInfo?.trips[currentTripIndex - 1]?.id);
            } else
              updatedTripIds.push(routesInfo?.trips[currentTripIndex + 1]?.id);
            updatedTripIds = updatedTripIds.filter(Boolean) as string[];
            onChangedTripStop(EEventTripStopAction.DELETED, {
              updatedTripIds: updatedTripIds,
            });
          }}
          data={relayStop}
          onClose={() => setRelayStop(null)}
          driverGroup={loadData?.driverGroups?.[0]?.id}
          isStatusAvailable={isAvailableStatus}
        />
      )}
      {loadStopPopupState.open && (
        <LoadStopPopup
          onClose={closeLoadStopPopup}
          onCreated={handleLoadStopPopupAction}
          data={loadStopPopupState.data}
          isLastStop={loadStopPopupState.isLastStop}
          isFirstStop={loadStopPopupState.isFirstStop}
          stopType={
            (loadStopPopupState?.isManifestLoad &&
              loadStopPopupState.LoadType === '') ||
            loadStopPopupState.LoadType === 'TRIP'
              ? 'TRIP'
              : 'LOAD'
          }
          driverGroup={loadStopPopupState?.driverGroup}
          {...(loadStopPopupState.deleteAction && { onDelete: deleteLoadStop })}
          isStatusAvailable={isAvailableStatus}
        />
      )}
      {(deletePopupState.open || Boolean(relayErr)) && (
        <>
          <DeletePopup
            title={deletePopupState.title || 'Resequence?'}
            body={deletePopupState.body || relayErr?.data}
            open={deletePopupState.open || Boolean(relayErr)}
            onAction={deletePopupState.onAction}
            onClose={closeDeleteTripPopup}
            backgroundColor={
              (Boolean(relayErr) && theme.palette.primary.main) ||
              deletePopupState?.bgColor
            }
            buttonText={deletePopupState.buttonText}
          />
        </>
      )}

      {activities && (
        <UpdateStopActivitiesDialog
          onClose={() => setActivities(null)}
          onAction={() => {
            setActivities(null);
            getTripData();
            onUpdateActivitiesCompleted?.();
            EventEmitter.send(
              new EventUpdateProgress({
                toTypes: [ESecondaryDetailsPanelType.TRIP],
              })
            );
          }}
          data={activities}
        />
      )}
    </Box>
  );
}

export default observer(LoadRoutes);
