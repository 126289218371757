import React from 'react';
import Box from '@mui/material/Box';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';

type PagePropTypes = {
  onFinish: () => void;
};

export default function FinishFooter({ onFinish }: PagePropTypes) {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Box>
          <ButtonImproved
            onClick={() => onFinish()}
            variant={'contained'}
            label={'Finish Import'}
          />
        </Box>
      </Box>
    </Box>
  );
}
