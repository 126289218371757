export interface ITerminalFilters {
  //
}

export enum ETerminalPanelTab {
  OVERVIEW = 'OVERVIEW',
}
export enum ETerminal3DotMenuType {
  DELETE = 'DELETE',
}
