export const activityStyles = {
  container: {
    m: '24px 0',
    paddingBottom: '90px',
  },
  activityBlock: {
    mt: '16px',
    background: '#F5F5F5',
    borderRadius: '5px',
    // '.MuiGrid-root':{
    //   marginTop:'0px',
    //   // marginBottom:'10px'
    // },
    '.MuiAccordionSummary-root': {
      padding: '0px !important',
      paddingLeft: '10px !important',
      paddingRight: '10px !important',
      margin: '0px !important',
      color: 'red',
    },
    '.MuiAccordionSummary-content': {
      // marginLeft:'0px !important',
      margin: '0px !important',
      marginBottom: '8px',
    },
  },
  activityInnerBlock: {
    background: '#F5F5F5',
    padding: '10px',
    borderRadius: '6px',
  },
  accordianActivityInnerBlock: {
    // padding: '10px',
    margin: '0px',
    borderRadius: '6px',
  },
  username: {
    color: '#2B64CB',
    fontWeight: 600,
    letterSpacing: '0.15px',
  },
  dateStyle: {
    color: 'rgba(4, 0, 34, 0.75)',
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.4px',
  },
  dataStyle: {
    fontSize: '14px',
    color: '#000',
    fontWeight: 400,
  },
};
export const hyperLinkStyle = {
  fontWeight: 600,
  fontSize: '14px',
  color: '#2B64CB',
};

export const activityGridStyle = {
  marginTop: '10px',
  marginBottom: '10px',
};

export const logMessageGridStyle = {
  pl: '16px',
  maxWidth: '85%',
};

export const updatedDetailStyle = {
  height: 250,
  width: '100%',
};

export const updatedDetailGridStyle = {
  border: 0,
  '.MuiDataGrid-cell.MuiDataGrid-cell--textLeft': {
    whiteSpace: 'normal !important',
    overflow: 'scroll !important',
    wordBreak: 'break-word !important',
  },
  '.MuiDataGrid-row.MuiDataGrid-row--dynamicHeight': {
    minHeight: '52px !important',
  },
};

export const noActivityText = {
  color: '#2B64CB',
  fontSize: '34px',
  fontStyle: 'medium',
  lineHeight: '40px',
  margin: '15px 0',
};
export const activityTextStyle = {
  ...noActivityText,
  lineHeight: '1',
  margin: 0,
  fontSize: '36px',
  marginLeft: '50px',
};
export const activityHeaderTextStyle = {
  ...noActivityText,
  lineHeight: '1',
  margin: 0,
  fontSize: '24px',
  marginLeft: '15px',
};
export const activityTextContainerStyle = {
  margin: '10px 0',
  color: '#2B64CB',
  display: 'flex',
  justifyContent: 'center',
};
export const noActivityImg = {
  display: 'inline-block',
};

export const linkStyle = {
  marginTop: '15px',
  marginBottom: '10px',
  color: 'primary',
  textTransform: 'capitalize',
  borderRadius: '10px',
  border: '1px solid',
};
