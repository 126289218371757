import Grid from '@mui/material/Grid';
import { Controller, ControllerProps } from 'react-hook-form';
import BaseMultipleAutocomplete from '../../../ui-kit/components/MultipleAutocomplete';

export const MultipleAutocompleteForm = ({
  id = '',
  name,
  fieldName,
  fieldValue,
  label,
  options,
  getOptions,
  onChangeCb,
  control,
  sizes,
  defaultValue,
  disabled = false,
  customStyles,
  freeSolo = false,
  variant,
  startAdornment = null,
  shouldUnregister = false,
  wrapperStyles = {},
  restProps = {},
  required,
  avoidLazyLoading = false,
}: {
  id?: string;
  name: string;
  fieldName: string;
  fieldValue?: string;
  label: string;
  options?: any[];
  disabled?: boolean;
  getOptions?: (value: string, pageNumber: number) => any;
  onChangeCb?: (data: any[], name: string, showError?: boolean) => void;
  control: any;
  sizes?: {
    xs: number;
  };
  defaultValue?: any;
  customStyles?: any;
  freeSolo?: boolean;
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  startAdornment?: JSX.Element | null;
  shouldUnregister?: ControllerProps['shouldUnregister'];
  wrapperStyles?: React.CSSProperties;
  restProps?: Record<string, any>;
  required?: boolean;
  avoidLazyLoading?: boolean;
}) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <Grid
          xs={sizes?.xs}
          item
          style={{ marginBottom: 20, ...wrapperStyles }}
        >
          <BaseMultipleAutocomplete
            id={id}
            required={required}
            variant={variant}
            disabled={disabled}
            freeSolo={freeSolo}
            {...field}
            label={label}
            name={name}
            getOptions={getOptions}
            fieldName={fieldName}
            fieldValue={fieldValue}
            onChangeCb={(data: any[], name: string) => {
              const newData =
                data && fieldValue
                  ? data.map((d) => (typeof d === 'object' ? d[fieldValue] : d))
                  : data;
              field.onChange(newData, name);
              // field.onChange(data, name);
              onChangeCb && onChangeCb(data, name);
            }}
            value={field.value || []}
            options={options}
            error={error ? { message: error?.message || '' } : undefined}
            defaultValue={defaultValue}
            customStyles={customStyles}
            startAdornment={startAdornment}
            avoidLazyLoading={avoidLazyLoading}
            {...restProps}
          />
        </Grid>
      )}
      shouldUnregister={shouldUnregister}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};
