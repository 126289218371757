import { t } from 'i18next';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { getTimeFromMinutes } from '../../../utils';

type UString = string | undefined;

function setupStopsDelay(stops: string[]): UString {
  let warningText;
  const ln = stops.length;
  if (ln) {
    warningText = `${t(
      'SetupStopsDelayPotentialdelayisdetectedfor'
    )} ${stops.join(ln === 2 ? ' and ' : ', ')}.`;
  }
  return warningText;
}

export class LoadListWarning {
  delay!: UString;
  detention!: UString;
  driver!: UString;
  invoice!: UString;

  constructor(warnings, entity: 'Load' | 'Trip' = 'Load') {
    this.delay = setupStopsDelay(warnings.delayedStopNames || []);
    this.driver =
      warnings.loadAssignmentDelayed &&
      `${
        entity === 'Load'
          ? t('SetupStopsDelayLoadpickup')
          : t('SetupStopsDelayTripstartdate')
      } ${t('SetupStopsDelayisgettingcloseanditisnotassignedtoadriver')}`;
    this.invoice =
      warnings.paymentDelayed &&
      `${entity} ${t('setupStopsDelayPaymentisnotreceived')}`;
  }

  setupDetentionTime(status: ELoadStatus, minutes?: number | null): void {
    if (minutes) {
      const time = getTimeFromMinutes(minutes);
      this.detention = `${t('SetupDetentionTimeDetentionTime')} ${time}`;
    }
  }
}
