import { Close, VerticalSplit } from '@mui/icons-material';
import { Box, DialogTitle, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { ReactNode } from 'react';
import { useCreateLoadPanel } from '../../../../contexts/CreateLoadPanelContext';

interface IProps {
  title?: ReactNode;
  onClose: () => void;
  toggleSidebar?: () => void;
  isCreate?: boolean;
}

const Header: React.FC<IProps> = ({
  onClose,
  toggleSidebar,
  title,
  isCreate,
}) => {
  const { mode } = useCreateLoadPanel();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  return (
    <Stack direction="row" alignItems="center" sx={{ m: 0, p: 2 }}>
      <DialogTitle sx={{ flexGrow: 1, p: 0, paddingRight: 2 }}>
        {title ? title : 'Add New Load'}
      </DialogTitle>
      <Box>
        {isCreate && (
          <IconButton
            onClick={toggleSidebar}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <VerticalSplit />
          </IconButton>
        )}

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Box>
    </Stack>
  );
};
export default Header;
