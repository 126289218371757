export const styles = {
  visiblityText: {
    color: 'primary.main',
    fontSize: 18,
  },
  visibilitySub: {
    color: 'text.primary',
    fontSize: 14,
    fontWeight: 600,
  },
};
