import { Box, Typography } from '@mui/material';
import { DetailsProps } from '@optym/gantt';
import React, { useEffect, useRef, useState } from 'react';
import { tripColumnTextStyles } from '../../styles';

interface ITripColumn {
  columnDetails?: Array<DetailsProps>;
  defaultWidth: number;
  containerStyle?: object;
}

const TripColumn: React.FC<ITripColumn> = (props) => {
  let prevWidth = 0;
  const getUpdatedColumnDetails = ({
    length,
  }: {
    length: number;
  }): Array<DetailsProps> => {
    if (props?.columnDetails?.length === 1) return props?.columnDetails;
    const arr = [
      ...((props?.columnDetails as Array<DetailsProps>) ?? []),
    ]?.splice?.(0, length);
    const remainingTripCount = props?.columnDetails?.length! - arr?.length;
    if (remainingTripCount > 0)
      return [
        ...arr,
        { text: `+${remainingTripCount}`, textStyles: { fontWeight: 500 } },
      ];
    return arr;
  };

  const getContentByWidth = (width: number): Array<DetailsProps> => {
    if (width <= 90) return getUpdatedColumnDetails({ length: 1 });
    else if (width > 90 && width < 120)
      return getUpdatedColumnDetails({ length: 2 });
    return [...(props?.columnDetails! ?? [])];
  };

  const [content, setContent] = useState<Array<DetailsProps> | undefined>(
    getContentByWidth(props?.defaultWidth)
  );

  const contentRef = useRef<any>(null);
  useEffect(() => {
    if (props?.columnDetails?.length > 1)
      observer.observe(contentRef?.current, { box: 'border-box' });
  }, []);

  function handleWidthChange(width: any) {
    debounce(updateColumn, 300, width);
  }
  const updateColumn = (width: number) => {
    setContent(getContentByWidth(width));
  };

  const debounce = (method: any, delay: number | undefined, params: any) => {
    clearTimeout(method._tId);
    method._tId = setTimeout(function () {
      method(params);
    }, delay);
  };

  const observer = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const width = entry.borderBoxSize?.[0].inlineSize;
      if (typeof width === 'number' && width !== prevWidth) {
        prevWidth = width;
        handleWidthChange(width);
      }
    }
  });
  return (
    <Box
      ref={contentRef}
      display={'flex'}
      height={'100%'}
      alignItems={'center'}
      sx={props?.containerStyle}
    >
      {content?.map((detail, i: number) => (
        <>
          <Typography
            key={detail.text}
            style={{
              ...tripColumnTextStyles,
              ...detail.textStyles,
              paddingLeft: i === 0 ? 0 : '4px',
            }}
          >
            {detail.text}
          </Typography>
          {i !== content?.length - 1 && `,`}
        </>
      ))}
    </Box>
  );
};

export default TripColumn;
