import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import {
  FooterContainer,
  KpiLabelStyles,
  KpiTextWrapperStyles,
  KpiValueStyles,
  RoundedBtnStyles,
} from '../styles';
import ConfirmationPopup from './generic/ConfirmationPopup';
import {
  restartOptimizationRequest,
  saveOptimization,
  validateOptimizationResult,
} from '../network/recommendTrips.request';
import { useStores } from '../../../store/root.context';
import {
  cancelBtnProps,
  conflictsPopupConfig,
} from '../config/conflictsModal.config';
import { observer } from 'mobx-react';
import { useLoadPermission } from '../../../common/hooks/useLoadPermission';

interface IKpiFooter {
  actionLayoutStyles?: object;
  sandboxId?: string;
  onConfirm?: (message?: string) => void;
  onRestart?: (data: object) => void;
  onConfirmPopupCancel?: () => void;
  onClosOptimization?: () => void;
}

type ConflictsDetailType = {
  failedDriverGroupCount: number;
  initialDriverGroupCount?: number;
  passedDriverGroupCount?: number;
};

const KPITemplate = ({
  label,
  value,
  valueStyles,
}: {
  label: string;
  value: string;
  valueStyles?: object;
}) => {
  return (
    <Box sx={KpiTextWrapperStyles}>
      <Typography sx={KpiLabelStyles}>{label}</Typography>
      <Typography color={'primary'} sx={{ ...KpiValueStyles, ...valueStyles }}>
        {value}
      </Typography>
    </Box>
  );
};

const ButtonTemplate = ({ children, sx, ...props }: ButtonProps) => {
  return (
    <Button sx={{ ...(RoundedBtnStyles as object), ...sx }} {...props}>
      {children}
    </Button>
  );
};

const KpiFooter: React.FC<IKpiFooter> = (props) => {
  const {
    bulkOptymizationStore: {
      showToast,
      cancelOptimization,
      lockedAssignmentMetrics,
    },
    dispatch2GlobalStore: { setPreviousSandboxId },
  } = useStores();
  const [showConflicts, setShowConflicts] = useState<boolean>(false);
  const [resultCounSet, setResultCountSet] =
    useState<ConflictsDetailType | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isRestarting, setIsRestarting] = useState<boolean>(false);
  const theme = useTheme();
  const startLoader = () => setShowLoader(true);
  const stopLoader = () => setShowLoader(false);

  const { hasLoadEditPermission } = useLoadPermission();

  const handleValidateResult = async () => {
    try {
      startLoader();
      const res = await validateOptimizationResult({
        data: { sandboxId: props?.sandboxId },
      });
      const resultCount = {
        failedDriverGroupCount: res?.failedDriverGroupCount ?? 0,
        initialDriverGroupCount: res?.initialDriverGroupCount ?? 0,
        passedDriverGroupCount: res?.passedDriverGroupCount ?? 0,
      };
      setResultCountSet(resultCount);
      stopLoader();
      if (res?.initialDriverGroupCount === 0)
        return handleSaveWithNoLockAssignment();
      if (res?.failedDriverGroupCount === 0)
        return await handleSaveAndExit(resultCount);
      setShowConflicts(true);
    } catch (error) {
      stopLoader();
      showToast({
        type: 'FAILURE',
        serviceName: 'engineValidateResult',
      });
    }
  };

  const closeConflictPopup = () => {
    setResultCountSet(null);
    setShowConflicts(false);
  };

  const handleSaveWithNoLockAssignment = () => {
    showToast({
      type: 'FAILURE',
      serviceName: 'engineSaveWithNoLocks',
    });
    closeConflictPopup();
  };

  const handleSaveAndExit = async (data?: ConflictsDetailType | null) => {
    let passedDriversCount = resultCounSet?.passedDriverGroupCount;
    let totalDriversCount = resultCounSet?.initialDriverGroupCount;
    if (data?.passedDriverGroupCount && data?.initialDriverGroupCount) {
      passedDriversCount = data?.passedDriverGroupCount;
      totalDriversCount = data?.initialDriverGroupCount;
    }
    try {
      startLoader();
      await saveOptimization?.({ data: { sandboxId: props?.sandboxId } });
      stopLoader();
      setPreviousSandboxId(null);
      props?.onConfirm?.(
        `Assignments successful: ${passedDriversCount} of ${totalDriversCount} assignment were completed successfully.`
      );
    } catch (error) {
      stopLoader();
      showToast({
        type: 'FAILURE',
        serviceName: 'engineSaveAndExitFailure',
      });
    }
  };

  const handleRestartOptimization = async () => {
    try {
      setIsRestarting(true);
      const res = await restartOptimizationRequest({
        params: {
          sandboxId: props?.sandboxId,
        },
      });
      const {
        drivers,
        trips,
        hosConstants,
        assignmentMetrics,
        lockedAssignmentMetrics,
        timeoffs,
        sandboxId,
      } = res;
      props?.onRestart?.({
        drivers,
        trips,
        hosConstants,
        assignmentMetrics,
        lockedAssignmentMetrics,
        timeoffs,
        sandboxId,
      });
      setIsRestarting(false);
      closeConflictPopup();
    } catch (error) {
      console.log('error: ', error);
      setIsRestarting(false);
      showToast({
        type: 'FAILURE',
        serviceName: 'engineOptimizationRestartFailed',
      });
    }
  };
  const lockedTextStylesCallback = useCallback(
    (theme: Theme) => {
      if (lockedAssignmentMetrics?.countLockedAssignments)
        return { color: theme?.palette?.primary?.main };
      return { color: 'inherit' };
    },
    [lockedAssignmentMetrics?.countLockedAssignments]
  );

  return (
    <>
      <Stack direction={'row'} sx={FooterContainer}>
        <Typography
          fontWeight={600}
          fontSize={14}
          sx={lockedTextStylesCallback}
        >
          {lockedAssignmentMetrics?.countLockedAssignments
            ? lockedAssignmentMetrics?.countLockedAssignments
            : ''}{' '}
          Locked Assignment(s):
        </Typography>
        <KPITemplate
          label="Total Trip Miles:"
          value={lockedAssignmentMetrics?.totalLoadedMiles as string}
        />
        <KPITemplate
          label="Total Deadhead Miles:"
          value={lockedAssignmentMetrics?.totalDeadheadMiles as string}
        />
        <KPITemplate
          label="Total Revenue:"
          value={lockedAssignmentMetrics?.totalRevenue as string}
          valueStyles={{ color: '#3B873E' }}
        />

        <ButtonTemplate
          sx={{ ml: 'auto' }}
          variant="outlined"
          color="primary"
          onClick={props?.onClosOptimization}
          disabled={showLoader}
        >
          Cancel
        </ButtonTemplate>
        {hasLoadEditPermission && (
          <ButtonTemplate
            sx={{ color: '#FFF', width: 115, ...props?.actionLayoutStyles }}
            variant="contained"
            onClick={handleValidateResult}
            disabled={showLoader}
          >
            {showLoader ? <CircularProgress size={20} /> : 'Save & Exit'}
          </ButtonTemplate>
        )}
      </Stack>
      {showConflicts && (
        <ConfirmationPopup
          config={{
            ...conflictsPopupConfig({
              failedDriverGroupCount: resultCounSet?.failedDriverGroupCount,
              passedDriverGroupCount: resultCounSet?.passedDriverGroupCount,
            }),
            deleteActionBtnStyles: {
              boxShadow: 'none',
              padding: '8px',
              color: theme?.palette?.primary?.main,
            },
            cancelBtnProps: {
              ...cancelBtnProps,
              disabled: isRestarting || showLoader,
              label: isRestarting ? <CircularProgress size={20} /> : 'Update',
            },
            onDelete: closeConflictPopup,
            disableActionBtnByDefault: showLoader || isRestarting,
            onCloseIconClick: () => {
              closeConflictPopup();
            },
          }}
          onCancel={handleRestartOptimization}
          onConfirm={() => handleSaveAndExit(null)}
        />
      )}
      {cancelOptimization && (
        <ConfirmationPopup
          onConfirm={props?.onClosOptimization}
          onCancel={props?.onConfirmPopupCancel}
        />
      )}
    </>
  );
};

export default observer(KpiFooter);
