import Box from '@mui/material/Box/Box';
import { useFormContext } from 'react-hook-form';
import Checkbox from '../../../../common/Ui/Checkbox';
import IconPicker from '../../../../common/Ui/IconPicker';
import TextField from '../../../../common/Ui/TextField/TextField';
import { useManageTypes } from '../../../../contexts/ManageTypesContext';
import { FINANCIAL_NAME } from '../constants';

export default function EditableManageTypeForm() {
  const { control } = useFormContext();
  const {
    entity: { name: entityName },
  } = useManageTypes();
  const isFinancial = entityName === FINANCIAL_NAME;

  return (
    <>
      {isFinancial && (
        <Box sx={{ display: 'flex', mb: 3 }}>
          <Checkbox
            name="invoiceItem"
            control={control}
            label="Invoice Line Item"
            disabled
          />
          <Checkbox
            name="settlementItem"
            control={control}
            label="Payment Line Item"
            disabled
          />
        </Box>
      )}
      <TextField
        name="description"
        control={control}
        label="Description"
        multiline
      />
      <Box sx={{ mb: 3 }}>
        <IconPicker name="iconName" control={control} width={'500'} />
      </Box>
    </>
  );
}
