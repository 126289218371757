import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import {
  GridCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IOpenPopupEventProps } from '../../../../../common/Appointment';
import CommonDataGrid from '../../../../../common/DataGridPro';
import { DirtyDetailsPanelManager } from '../../../../../common/DetailsPanel/utils';
import { terminalPage } from '../../../../../common/PendoClassIDs/constants';
import { getSortOption } from '../../../../../common/Sorting/sortingUtils';
import { useFilterDataGrid } from '../../../../../common/hooks/useFilterDataGrid';
import { useTerminalPermission } from '../../../../../common/hooks/useTerminalPermission';
import { persistUserState } from '../../../../../constants';
import { TerminalContentDTO } from '../../../../../models';
import useFilterStorage from '../../../../../services/storage';
import { useStores } from '../../../../../store/root.context';
import { GridColDefSelf } from '../../../../../types';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved/ButtonImproved';
import TableSettings from '../../../../../ui-kit/components/DataGridPro/settings/TableSettings';
import { onColumnOrderChange } from '../../../../../utils/gridColumnOrdering';
import { PANEL_FIXED_WIDTH } from '../../../../dispatch2/constants/dispatch';
import { debounceTime } from '../../../../trips/constants/constants';
import { ETerminal3DotMenuType, ITerminalFilters } from '../../constants/types';
import { TerminalDetailsPanel } from '../TerminalDetailsPanel';
import { NoData } from './NoData';
import TerminalGridConfig from './grid.config';
import { useRootStore } from '@/store/root-store/rootStateContext';
const TerminalDataGrid: FC = () => {
  const { terminalStore } = useStores();
  const [sortRequestData, setSortRequestData] = useState({});
  const [allColumns, setAllColumns] = useState<GridColDefSelf[]>([]);
  const [tableColumns, setTableColumns] = useState<GridColDefSelf[]>([]);

  const {
    currentView,
    addNewClicked,
    setAddNewClicked,
    tableList,
    filters,
    component,
    selectedItem,
    setSelectedItem,
    totalTableItems,
    gridIsLoading,
    updateLocalTableList,
  } = terminalStore;
  const { hasTerminalAddPermission } = useTerminalPermission();
  const { getIsTerminalEnabled } = useRootStore();
  const stripId = currentView?.id;
  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};

  const gridConfig = new TerminalGridConfig({});
  const defaultFilters =
    persistUserState.pages[component][stripId]?.filters || currentView.metaData;

  const {
    setDefaultColumns,
    setDefaultFilters,
    resetSettings,
    handleChangeColumns,
  } = useFilterDataGrid<ITerminalFilters>({
    component,
    allColumns: gridConfig.config.columns,
    currentView: currentView,
    setTableColumns,
    setAllColumns,
    defaultFilters: defaultFilters,
    currentViewPrimaryKey: 'id',
    setFilters: terminalStore.setFilters,
  });

  const debouncedChangeHandler = useCallback(
    debounce(
      ({
        nextPageNumber,
        nextFilters,
      }: {
        nextPageNumber: number;
        nextFilters: ITerminalFilters;
      }) => {
        terminalStore.fetchTableList({
          nextPageNumber,
          nextFilters,
        });
      },
      debounceTime
    ),
    []
  );

  const onClickHandler = (
    event: IOpenPopupEventProps<TerminalContentDTO>
  ): void => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    setSelectedItem(event.data);
    setAddNewClicked(false);
  };

  const handleSortChanged = (model: GridSortModel): void => {
    const sortOptions = getSortOption(model, '-createDate');

    const updatedData = {
      nextPageNumber: 1,
      nextFilters: {
        ...filters,
        sort: sortOptions,
      },
    };
    sendRequestHandler(updatedData);

    LStorageHook.updateStorage(stripId, {
      ...storageData[stripId],
      query: { ...storageData[stripId].query, sort: sortOptions },
    });
  };

  const handleSelectItem = (
    params: GridRowParams<TerminalContentDTO & { isPinnedRows?: boolean }>
  ) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    const data = params.row;
    if (!data?.isPinnedRows) {
      setSelectedItem(data);
      setAddNewClicked(false);
    }
  };

  const sendRequestHandler = (params: {
    nextPageNumber: number;
    nextFilters: ITerminalFilters;
  }) => {
    terminalStore.setSettingsPagination({
      ...terminalStore.settingsPagination,
      pageNumber: params.nextPageNumber,
    });

    terminalStore.setFilters(params.nextFilters);
    debouncedChangeHandler(params);
  };

  const onRowsScrollEnd = (): void => {
    const { pageNumber, isLoading, last }: any =
      terminalStore.settingsPagination;
    if (isLoading || last || !tableList.length) return;
    sendRequestHandler({
      nextPageNumber: pageNumber + 1,
      nextFilters: filters,
    });
  };

  const setDefaultSorting = () => {
    const sort = storageData[stripId]?.query?.sort;
    sort && setSortRequestData({ sort });
  };

  const onCellClick = async (params: GridCellParams): Promise<void> => {
    onClickHandler({
      data: params.row,
      params,
    });
  };

  //Side effect begin
  useEffect(() => {
    //Important: Call in the first time
    const defaultFilters = setDefaultFilters();
    setDefaultColumns();
    setDefaultSorting();

    sendRequestHandler({
      nextPageNumber: 1,
      nextFilters: { ...defaultFilters, ...sortRequestData },
    });
  }, [stripId, setDefaultFilters]);

  useEffect(() => {
    return () => {
      setSelectedItem(null);
    };
  }, []);

  useEffect(() => {
    if (addNewClicked) {
      setSelectedItem(null);
    }
  }, [addNewClicked]);
  //Side effect end

  const tableConfigurationProps = useMemo(
    () => ({
      TableSettings: {
        handleChangeForColumnModel: handleChangeColumns,
        tableColumnsData: allColumns,
        handleChangeColumns: handleChangeColumns,
        resetSettings: resetSettings,
      },
    }),
    [allColumns, handleChangeColumns]
  );

  const on3DotMenuActionCallback = ({
    type,
    data,
    response,
  }: {
    type: ETerminal3DotMenuType;
    data: TerminalContentDTO;
    response: any;
  }): void | Promise<void> => {
    if (type === ETerminal3DotMenuType.DELETE) {
      updateLocalTableList({
        deletedList: [data],
      });
    } else {
      updateLocalTableList({
        updatedList: [data],
      });
    }
  };

  useEffect(() => {
    if (!getIsTerminalEnabled) setSelectedItem(null);
  }, [getIsTerminalEnabled]);

  return (
    <Box
      className={'TerminalDataGrid-root'}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <Box
        className={'TerminalDataGrid-container'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width:
            selectedItem || addNewClicked
              ? `calc(100% - ${PANEL_FIXED_WIDTH}px)`
              : '100%',
        }}
      >
        <Box
          className={'TerminalDataGrid-top'}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {!!terminalStore.tableList.length &&
            !!allColumns.length &&
            getIsTerminalEnabled && (
              <Box sx={{ pr: 2 }}>
                <TableSettings {...tableConfigurationProps.TableSettings} />
              </Box>
            )}
          {hasTerminalAddPermission && getIsTerminalEnabled && (
            <ButtonImproved
              id={terminalPage + 'AddTerminal'}
              size="medium"
              variant="outlined"
              label="Add Terminal"
              startIcon={<AddIcon />}
              onClick={() => setAddNewClicked(true)}
            />
          )}
        </Box>

        <Box
          className={'CarrierDataGrid-content'}
          sx={() => ({
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          {!gridIsLoading && !getIsTerminalEnabled && <NoData />}
          {!!allColumns.length && getIsTerminalEnabled && (
            <CommonDataGrid
              disableColumnMenu
              onSortModelChange={handleSortChanged}
              loading={gridIsLoading}
              columns={allColumns}
              rows={terminalStore.tableList}
              onRowsScrollEnd={onRowsScrollEnd}
              getRowId={(row: TerminalContentDTO) => row.id}
              totalNumberOfRows={totalTableItems}
              totalDataInTable={totalTableItems}
              onCellClick={onCellClick}
              sortingMode="server"
              onColumnOrderChange={(params, event, details) =>
                onColumnOrderChange(
                  { params, event, details },
                  component,
                  stripId
                )
              }
              handleSelectItem={handleSelectItem}
            />
          )}
        </Box>
      </Box>
      {addNewClicked && getIsTerminalEnabled && (
        <TerminalDetailsPanel
          data={null}
          onClose={() => setAddNewClicked(false)}
          onCreated={(newTerminalDTO) => {
            setAddNewClicked(false);
            updateLocalTableList({
              createdList: [newTerminalDTO],
            });
            setSelectedItem(newTerminalDTO);
          }}
        />
      )}
      {!addNewClicked && selectedItem && (
        <TerminalDetailsPanel
          data={selectedItem}
          onClose={() => {
            setSelectedItem(null);
          }}
          onUpdated={(newTerminalDTO) => {
            setSelectedItem(null);
            updateLocalTableList({
              updatedList: [newTerminalDTO],
            });
          }}
          on3DotMenuActionCallback={on3DotMenuActionCallback}
        />
      )}
    </Box>
  );
};

export default observer(TerminalDataGrid);
