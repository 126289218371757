import { Box } from '@mui/material';

// @TODO: Anoush should be moved into UI-Kit
export default function KPIsInfo({
  title,
  value,
}: {
  title: string;
  value: string;
}) {
  return (
    <Box
      component="span"
      sx={{
        backgroundColor: 'uncategorized.oldHeaderSecondary',
        display: 'flex',
        p: 1,
        flexDirection: 'column',
        width: '40%',
        letterSpacing: '0.15px',
        borderRadius: '8px',
      }}
    >
      <Box
        component="span"
        sx={{
          lineHeight: '30px',
          color: 'primary.main',
          fontSize: '20px',
          fontWeight: 600,
        }}
      >
        {value}
      </Box>
      <Box
        component="span"
        sx={{
          lineHeight: '21px',
          color: 'text.secondary',
          fontSize: '14px',
          fontWeight: 500,
        }}
      >
        {title}
      </Box>
    </Box>
  );
}
