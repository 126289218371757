import React from 'react';
import { DialogTitle, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type DialogTitleProps = {
  titleText?: string;
  subTitleText?: string;
  onClose: () => void;
};

const Title = (props: DialogTitleProps) => {
  const { titleText, subTitleText, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 400,
        color: '#FFFFFF',
        padding: '0 24px 8px 24px',
      }}
      {...other}
    >
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Grid item sx={{ lineHeight: 1.2 }}>
            <span>{titleText}</span>
          </Grid>
          {subTitleText && (
            <Grid item sx={{ lineHeight: 1.2 }}>
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {subTitleText}
              </span>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: '#FFF' }} />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

Title.defaultProps = {
  titleText: '',
};

export default Title;
