export const apexMapping = {
  customers: [
    {
      field: 'axeleCustomer',
      headerName: 'LoadOps Customer',
      flex: 1,
    },

    { field: 'axeleCustomerMC', headerName: 'LoadOps Customer MC #', flex: 1 },
    {},
    {
      field: 'factoringCustomerMCs',
      headerName: 'Factoring Customer MC #',
      flex: 1,
      valueGetter: (params: { row: { factoringCustomerMCs: string } }) => {
        return params.row.factoringCustomerMCs;
      },
    },
  ],
  driver: [
    {
      field: 'axeleDriver',
      headerName: 'LoadOps Driver',
      flex: 1,
    },
    { field: 'code', headerName: 'Driver Invoice Code', flex: 1 },
    { field: 'mappingCode', headerName: 'Funding Account Code', flex: 1 },
    {
      field: 'fundingAccountDescription',
      headerName: 'Funding Account Description',
      flex: 1,
    },
    {
      field: 'driverInvoiceNotes',
      headerName: 'Driver Invoice Notes',
      flex: 1,
    },
    {},
  ],
};

export const tafsMapping = {
  customers: [
    {
      field: 'axeleCustomer',
      headerName: 'LoadOps Customer',
      flex: 1,
    },

    { field: 'axeleCustomerMC', headerName: 'LoadOps Customer MC #', flex: 1 },
    {
      field: 'factoringCustomer',
      headerName: 'Factoring Customer',
      flex: 1,
    },
    {
      field: 'factoringCustomerMCs',
      headerName: 'Factoring Customer MC #',
      flex: 1,
      valueGetter: (params: { row: { factoringCustomerMCs: string } }) => {
        return params.row.factoringCustomerMCs;
      },
    },
  ],
  driver: [
    {
      field: 'axeleDriver',
      headerName: 'LoadOps Driver',
      flex: 1,
    },
    {},
    { field: 'mappingId', headerName: 'Driver Security ID', flex: 1 },
  ],
};

export const OTRMapping = {
  customers: [
    {
      field: 'axeleCustomer',
      headerName: 'LoadOps Customer',
      flex: 1,
    },
    { field: 'axeleCustomerMC', headerName: 'LoadOps Customer MC #', flex: 1 },
    { field: 'customerName', headerName: 'Customer  Name', flex: 1 },
    {},
    {
      field: 'factoringCustomerMCs',
      headerName: 'Factoring Customer MC #',
      flex: 1,
      valueGetter: (params: { row: { factoringCustomerMCs: string } }) => {
        return params.row.factoringCustomerMCs;
      },
    },
  ],
  driver: [
    {
      field: 'axeleDriver',
      headerName: 'LoadOps Customer',
      flex: 1,
    },
    {
      field: 'fundingAccountCode',
      headerName: 'Funding Account Code',
      flex: 1,
    },
    { field: 'driverInvoiceCode', headerName: 'Driver Invoice Code', flex: 1 },
    {
      field: 'fundingAccountDescription',
      headerName: 'Funding Account Description',
      flex: 1,
    },
    {
      field: 'driverInvoiceNotes',
      headerName: 'Driver Invoice Notes',
      flex: 1,
    },
  ],
};

export const customerFilters = [
  {
    field: 'Customer',
    label: 'Customer',
    title: 'name',
    option: [],
  },
  {
    field: 'Status',
    label: 'Status',
    title: 'name',
    option: [
      {
        name: 'Linked',
      },
      {
        name: 'Unlinked',
      },
    ],
  },
];

export const driverFilters = [
  {
    field: 'Driver',
    label: 'Driver',
    title: 'driverName',
    option: [],
  },
  {
    field: 'Status',
    label: 'Status',
    title: 'name',
    option: [
      {
        name: 'Linked',
      },
      {
        name: 'Unlinked',
      },
    ],
  },
];

export const manageIntegrationTabs = [
  {
    iconName: 'PersonOutlined',
    source: 'MUIcons',
    label: 'Customers Mapping',
    key: 'customersMapping',
  },
  {
    label: 'Funding Accounts Mapping',
    key: 'fundingAccountsMapping',
    iconName: 'DriverSafety',
    source: 'AxeleIcon',
  },
];

export const OTRTabs = [
  {
    label: 'Customers Mapping',
    key: 'customersMapping',
    iconName: 'PersonOutlined',
    source: 'MUIcons',
  },
];

export const buttonTextDriver = {
  1: 'Update Funding Accounts',
};

export type TMappingObjectType = {
  mappingCode: string;
  mappingId: number;
  mappingName: string;
  mappingDescription: string;
};
export type TRowDetailsType = {
  dropDownValue: string | (() => string | null) | null;
  firstname: string | (() => string | null) | null;
  id: number;
  driverId: number | undefined;
  driverInvoiceNotes: string | undefined;
  factoringId: string;
  lastname: string | undefined;
  organizationId: number | undefined;
  code: any;
  customerId: string | undefined;
  axeleCustomerMC: string | undefined;
  axeleCustomer: string | undefined;
};
