import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridView';
import { Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { dispatchPage } from '../../../../../common/PendoClassIDs/constants';
import { EGanttTabPanel } from '../../../constants/types';
import { StyledButton, StyledButtonGroup } from './styles';
export interface GanttTabPanelProps {
  key: EGanttTabPanel;
  title: string;
  renderIcon: (color: string) => JSX.Element;
}

export const ganttTabsPanel: GanttTabPanelProps[] = [
  {
    key: EGanttTabPanel.Gantt,
    title: EGanttTabPanel.Gantt,
    renderIcon: (color: string) => {
      return (
        <TableRowsOutlinedIcon
          fontSize="small"
          sx={{
            color,
          }}
        />
      );
    },
  },
  {
    key: EGanttTabPanel.Map,
    title: EGanttTabPanel.Map,
    renderIcon: (color: string) => {
      return (
        <MapOutlinedIcon
          fontSize="small"
          sx={{
            color,
          }}
        />
      );
    },
  },
  {
    key: EGanttTabPanel.Grid,
    title: EGanttTabPanel.Grid,
    renderIcon: (color: string) => {
      return (
        <GridOnOutlinedIcon
          fontSize="small"
          sx={{
            color,
          }}
        />
      );
    },
  },
];

export interface GanttDisplayModeProps {
  defaultTab?: GanttTabPanelProps;
  onChange?: (tab: GanttTabPanelProps) => void;
}
export const GanttDisplayMode = ({
  defaultTab = ganttTabsPanel[0],
  onChange,
}: GanttDisplayModeProps) => {
  const theme: any = useTheme();
  const [selectedTab, setSelectedTab] =
    useState<GanttTabPanelProps>(defaultTab);

  useEffect(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);

  const handleChange = (newSelectedtab: GanttTabPanelProps) => {
    setSelectedTab(newSelectedtab);
    // let gantt = document.getElementById('ganttChartContainer');
    // let map = document.getElementById('ganttChartMapContainer');
    // console.log('newSelectedtab*************', newSelectedtab);
    // if (newSelectedtab?.key === EGanttTabPanel.Gantt) {
    //   map.style.visibility = 'hidden';
    //   map.style.width = '0';
    //   map.style.height = '0';
    //   gantt.style.visibility = 'visible';
    //   gantt.style.width = '100%';
    //   gantt.style.height = '100%';
    // } else {
    //   gantt.style.visibility = 'hidden';
    //   gantt.style.width = '0';
    //   gantt.style.height = '0';
    //   map.style.visibility = 'visible';
    // map.style.width = '100%';
    // map.style.height = '100%';
    // }
    onChange?.(newSelectedtab);
  };

  return (
    <StyledButtonGroup>
      {ganttTabsPanel.map((item: GanttTabPanelProps) => (
        <Tooltip key={item.key} title={item.title} arrow placement="top">
          <StyledButton
            size="small"
            id={dispatchPage + item.key}
            isSelected={item.key === selectedTab.key}
            onClick={() => {
              handleChange(item);
            }}
          >
            {item.renderIcon(
              item.key === selectedTab.key
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            )}
          </StyledButton>
        </Tooltip>
      ))}
    </StyledButtonGroup>
  );
};
