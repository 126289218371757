import React from 'react';
import {
  Step,
  StepLabel,
  Stepper as MUIStepper,
  useTheme,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  IMPORT_LOAD_NAME,
  useImport,
} from '../../../../contexts/ImportContext';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import ExtraStep from './ExtraStep';

export const steps = [
  'Upload File',
  'Map Data',
  'Change & Review Values',
  'Finish Import',
];

export const complexEntitySteps = [
  'Upload File',
  'Map Data',
  'Add Missing Entities',
  'Change & Review Values',
  'Finish Import',
];

const getSteps = (name: string): string[] => {
  if (name === IMPORT_LOAD_NAME && steps.length < 5) {
    return complexEntitySteps;
  }
  return steps;
};

export default function Stepper() {
  const {
    activeStep,
    currentEntity: { name, hasAdditionalStep },
  } = useImport();

  const currentTheme = useTheme();
  const theme = createTheme(currentTheme, {
    components: {
      MuiStep: {
        styleOverrides: {
          root: {
            paddingRight: 0,
            paddingLeft: 0,
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            '.Mui-completed': {
              color: '#4CAF50 !important',
            },
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            marginLeft: 8,
            marginRight: 8,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            paddingRight: '8px',
            fontSize: '14px',
          },
          label: {
            fontFamily: 'Poppins',
            fontSize: '14px',
            color: 'rgba(0, 17, 34, 0.6)',
          },
          iconContainer: {
            height: '24',
            width: '24',
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            height: '24px',
            width: '24px',
          },
        },
      },
    },
  });

  const renderSteps = () => {
    if (hasAdditionalStep) {
      return (
        <Box
          className="steps-wrapper"
          sx={{
            p: '50px 0 35px 0',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {activeStep === 0 && <Step1 />}
          {activeStep === 1 && <Step2 />}
          {activeStep === 2 && <ExtraStep />}
          {activeStep === 3 && <Step3 />}
          {activeStep === 4 && <Step4 />}
        </Box>
      );
    }
    return (
      <Box
        className="steps-wrapper"
        sx={{
          p: '50px 0 35px 0',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {activeStep === 0 && <Step1 />}
        {activeStep === 1 && <Step2 />}
        {activeStep === 2 && <Step3 />}
        {activeStep === 3 && <Step4 />}
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 2, height: '100%' }}>
        <MUIStepper activeStep={activeStep}>
          {getSteps(name).map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </MUIStepper>
        {renderSteps()}
      </Box>
    </ThemeProvider>
  );
}
