import * as yup from 'yup';
import { nullableStringYup } from '../../../../utils';

export const createDriverValidationSchema = yup.object().shape({
  firstname: nullableStringYup()
    .required('First name is required')
    .max(20, 'Name must be at most 20 characters.'),
  lastname: nullableStringYup().max(20, 'Name must be at most 20 characters.'),
  email: nullableStringYup().email('Email is not valid.'),
  terminalId: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .required('Terminal is required')
    .typeError('Terminal is required'),
});

export const createTractorValidationSchema = yup.object().shape({
  axeleTractorName: nullableStringYup()
    .required('Tractor Name is required')
    .max(20, 'Tractor Name must be at most 20 characters.'),
  axeleTractorVin: nullableStringYup()
    .required('Tractor VIN is required')
    .length(17, 'Tractor VIN must be exactly 17 characters.'),
  terminalId: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .required('Terminal is required')
    .typeError('Terminal is required'),
});
