/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventTypes } from '../EventEmitter/EventTypes';
import StorageManager from '../StorageManager/StorageManager';
import { DirtyDetailsPanelManager } from '../common/DetailsPanel/utils';
import { LS_HUBSPOT_TOKEN, useHubspot } from '../common/hooks/useHubspot';
import { persistUserState } from '../constants';
import { TokenWrapper, UserLoginInfo } from '../models';
import { RootStoreInstence } from '../store/root-store/rootStateContext';
import LoadController from '../subPages/loadsList/LoadController';
import TemplateController from '../subPages/templates/TemplatesController';
import TractorController from '../subPages/tractors/Controller';
import TrailerController from '../subPages/trailers/TrailerController';
import { b64EncodeUnicode } from '../utils';
import useEmit from '../utils/hooks/useEmit';
import TerminalController from '../views/settings/terminals/TerminalController';
import SecureLS from 'secure-ls';
import { datadogRum } from '@datadog/browser-rum';
import EncryptedStorageManager from '../StorageManager/EncryptedStorageManager';

type AuthContextType = {
  [propKey: string]: any;
};
const AuthContext = createContext({} as AuthContextType);

type Props = {
  children: any;
};

const userLoginInfo = new UserLoginInfo('', '', '');

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate();

  const authErroHandler = useCallback(() => {
    logoutUser();
  }, []);

  const logoutOnPermissionChanged = useCallback(() => {
    RootStoreInstence.setNotificationType({
      type: 'FAILURE',
      message: 'Permissions have changed, please login',
    });
    logoutUser();
  }, []);

  const logoutOnSessionExpired = useCallback(() => {
    RootStoreInstence.setNotificationType({
      type: 'FAILURE',
      message: 'Session has expired, please login',
    });
    logoutUser();
  }, []);

  useEmit({
    [EventTypes.AUTH_ERROR]: authErroHandler,
    [EventTypes.PERMISSION_CHANGED]: logoutOnPermissionChanged,
    [EventTypes.SESSION_EXPIRED]: logoutOnSessionExpired,
  });

  const setEnvs = () => {
    const data = StorageManager.getItem('login-data');
    const ls = new SecureLS({
      encodingType: 'aes',
      encryptionSecret: process.env.SECRET_KEY,
    });
    const keys = ls.get('keys');
    if (keys) {
      window.env = { ...window.env, ...JSON.parse(keys) };
    }
    if (data && !keys) {
      localStorage.removeItem('login-data');
      logoutUser();
      navigate('/login');
    }
  };

  const storeInitial = (data) => {
    // @TODO Anoush remove try catch after checking
    try {
      localStorage.setItem('user-settings', JSON.stringify(data));
    } catch (error) {
      /* Case if in localStorage is not enough memory to store initial settings */
      console.error(
        'Error while storing initial settings in local Storage: ',
        error
      );
    }
  };

  // @Anna todo versoning and userType
  const setInitial = (user: { id: any }) => {
    let storeData = { ...persistUserState };
    const initialExist = StorageManager.getItem('user-settings');
    // WE WILL REMOVE THIS LATER WHEN ALL CLIENTS HAVE THIS KEY REPLACED IN LOCAL
    const loadCols = initialExist?.pages?.MyLoads?.allLoads?.columnsOrdering;
    if (loadCols && loadCols?.includes('dispatcherName')) {
      const index = loadCols?.findIndex(
        (el: string) => el === 'dispatcherName'
      );
      initialExist.pages.MyLoads.allLoads.columnsOrdering[index] =
        'dispatchers';
    }
    // ---------------- //
    if (initialExist) {
      if (user.id === initialExist.userId) {
        storeData = initialExist;
      }
    }
    storeData.userId = user.id;
    storeInitial(storeData);
  };

  const loginUser = async (
    userLoginInfoParam: UserLoginInfo
  ): Promise<void> => {
    destroyUserSpecificData();
    const response = await fetch('/web/auth/api/v2/users/login/company', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dot: userLoginInfoParam.dot ?? userLoginInfo.dot,
        email: userLoginInfoParam.email ?? userLoginInfo.email,
        password: b64EncodeUnicode(
          (userLoginInfoParam.password as string) ??
            (userLoginInfo.password as string)
        ),
      }),
    });

    const data = await response.json();
    if (response.status === 200) {
      datadogRum.setUser({
        dot: data.dot,
        user: data.email,
      });
      const token = new TokenWrapper(data.token, data.refreshToken);
      EncryptedStorageManager.setItem('user', JSON.stringify(data));
      EncryptedStorageManager.setItem('token', JSON.stringify(token));
      EncryptedStorageManager.setItem('refreshToken', data.refreshToken);
      window.env = { ...window.env, ...data.frontEndConfig };
      if (Boolean(window.env)) {
        const ls = new SecureLS({
          encodingType: 'aes',
          encryptionSecret: process.env.SECRET_KEY,
        });
        ls.set('keys', JSON.stringify(window.env));
      }
      setInitial(data);
      // temp hotfix to understand, what the reason on #130 react error
      await new Promise((res) => setTimeout(res, 700));
      if (data.recreateFlag) {
        navigate('/reactivation');
        return Promise.resolve();
      }
      if (
        data?.subscriptionStatus !== 'ACTIVE' &&
        data?.subscriptionStatus !== 'PAST_DUE' &&
        data?.subscriptionStatus !== 'EVALUATION'
      ) {
        navigate('/rejoin-registration');
        return Promise.resolve();
      }
      navigate('/');
      return Promise.resolve();
    }
    if (response.status === 406) {
      navigate('/registration/verification', {
        state: { dot: userLoginInfoParam.dot, email: userLoginInfoParam.email },
      });
      return Promise.resolve();
    } else {
      RootStoreInstence.setNotificationType({
        message: data.message,
        type: 'FAILURE',
        serviceName: 'login',
      });
      return Promise.reject();
    }
  };

  const destroyUserSpecificData = () => {
    LoadController.destroy();
    TractorController.destroy();
    TrailerController.destroy();
    TerminalController.destroy();
    TemplateController.destroy();
  };

  const logoutUser = useCallback(() => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    destroyUserSpecificData();
    StorageManager.removeItems([
      'token',
      'user',
      'allTerminals',
      'terminalEnabled',
      // Temp Fix: re-enable this once the backend support is done
      // 'user-settings',
      'hubspottoken',
      'preferredTimeZone',
      'workWeekStartDay',
      'lazyWithRetry',
    ]);
    sessionStorage.removeItem('SelectedELDProvider');

    navigate('/login');
  }, [navigate]);

  const contextData = {
    user: EncryptedStorageManager.getUser(),
    loginUser: loginUser,
    logoutUser: logoutUser,
  };

  useEffect(() => {
    const onLocalStorageChange = (event: StorageEvent) => {
      if (event.isTrusted && event.key !== 'token') return;
      if (event.newValue) return (location.href = '/');
      return logoutUser();
    };

    if (contextData?.user) {
      datadogRum.setUser({
        dot: contextData?.user.dot,
        user: contextData?.user.email,
      });
    }
    onstorage = (event) => onLocalStorageChange(event);
    setEnvs();
    return () => {
      onstorage = null;
    };
  }, []);

  const { setIdentity } = useHubspot();

  if (contextData?.user && contextData.user?.token) {
    setIdentity();
  }

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
