/**
 * @CommonDataGridComponent
 * @Renders : A common data grid component that renders a datatable with various features according to the props passed.
 * @HasAPICall : False
 * @Author :  Mohammed Adil (mohammed.adil@optym.com), Faiz Sayed (c-faisy@optym.com)
 **/

import { Box, Card, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import {
  DataGridPro,
  GridCallbackDetails,
  GridCellParams,
  GridColumnVisibilityModel,
  GridRowModel,
  GridRowParams,
  GridRowScrollEndParams,
  GridSelectionModel,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import React from 'react';
import BulkAction from './BulkAction';
import { FiltersWrapper } from './filter/FiltersWrapper';
import SummaryColumn from './footer/SummaryColumn';
import { getCommonDataGridStyles } from './styles/CommonDataGrid.styles';
import {
  disableSelectionOnClickStyle,
  getcardViewStyles,
} from './styles/grid.style';
import { IDataGridProProps } from './types';
import { preProcessTableColumnsData } from './utils';

const LoadingSkeleton = () => (
  <Box
    sx={{
      height: 'max-content',
    }}
  >
    {[...Array(50)].map((_, key) => (
      <Skeleton key={key} variant="rectangular" sx={{ my: 4, mx: 1 }} />
    ))}
  </Box>
);

function DetailPanelContent({ row, columns }: any) {
  // subRows property for array of sub table rows
  const displayRows = row.subRows || [row];

  return (
    <DataGridPro
      density="compact"
      columns={columns}
      rows={displayRows}
      sx={{ flex: 1 }}
      hideFooter
    />
  );
}

const CommonDataGrid: React.FC<IDataGridProProps> = (props) => {
  const {
    tableDensity = 'standard',
    tableData,
    tableColumnsData,
    checkboxSelection = false,
    title = '',
    isCardView = false,
    rowHeight = 38,
    loading = false,
    onColumnHeaderClick,
    paginationMode = 'client',
    sortingMode,
    sortModel,
    onCellClick,
    filterColumns = [],
    selectRow,
    selectionModel,
    isRowSelectable,
    settingsPanel = true,
    displayFilters = true,
    customFooter = false,
    footerComponent,
    customeGridColumnMenu,
    getSelectedRowsCount,
    isColorCellGrid = false,
    editMode = 'row',
    rowEditing = false,
    updateRowsAfterEdit,
    hideNumericColumnsCount = false,
    customFilterComponent,
    isExpandable = false,
    getDetailPanelHeight = undefined,
    subTableColumnsData = [],
    onAllFilterSubmit,
    onAllFilterReset,
    filterPerCol,
    defaultFilterValue,
    handleSelectItem,
    handleScroll,
    onSortModelChange,
    componentsProps,
    getRowId,
    onPageFilterChange,
    dataGridContainerHeight = 'calc(100vh - 300px)',
    getExcel,
    excelDoc,
    resetSettings,
    downloadFile,
    reSetExcel,
    handleChangeColumns,
    pinnedColumns,
    columnTotalData,
    totalNumberOfRows,
    disableColumnReorder = false,
    isDisableColumnMenu = true,
    onColumnOrderChange,
    NoRowsOverlay,
    overBulkActionRender,
    bulkAction,
    disableSelectionOnClick = false,
    disableMultipleSelection = true,
    tableSettingIconProps,
    pinnedRows,
    totalDataInTable,
    primaryColumn3DotMenu = undefined,
    colVisibilityModel,
    columnVisibilityModelChange,
    settingsColumnData,
    gridBottomMenu,
    gridCustomeStyle,
    pageName = `pageName-${Math.random()}`,
    disableSelectionRowStyle = false,
    apiRef,
    lazyLoadingSettings = undefined,
  } = props;
  // ================|| STATES INITIALIZATION ||==================== //
  const [tableRows, setTableRows] = React.useState<any[]>(tableData);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<
    GridColumnVisibilityModel | undefined
  >();
  const [selectedCheckbox, setSelectedCheckbox] = React.useState<number>(0);
  const [selectionModelState, setSelectionModelState] =
    React.useState<GridSelectionModel>();
  const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);

  // ================|| SIDE EFFECTS||==================== //
  React.useEffect(() => {
    setTableRows(tableData);
  }, [tableData]);

  React.useEffect(() => {
    if (colVisibilityModel) {
      setColumnVisibilityModel(colVisibilityModel);
    }
    // getColumnVisibiltyModal();
  }, [colVisibilityModel]);

  React.useEffect(() => {
    if (checkboxSelection && selectionModel) {
      setSelectionModelState(selectionModel);
      setSelectedCheckbox(selectionModel.length);
    }
  }, [selectionModel]);

  // ================|| Theme , Grid Ref and Others ||==================== //
  const theme = useTheme();
  const cardViewStyles = isCardView ? getcardViewStyles(theme, isCardView) : {};
  const commonDataGridStyles = getCommonDataGridStyles(theme);
  // const apiRef = useGridApiRef();

  // ================|| State Handlers and Utility Functions @@ ||==================== //
  function fetchNumericColumns() {
    return tableColumnsData.filter((column: any) => column.type === 'number');
  }

  function getColumnVisibiltyModal() {
    const visibiltyModal: { [key: string]: boolean } = {};
    tableColumnsData &&
      tableColumnsData.forEach((column: any) => {
        visibiltyModal[column.field] = column.hide || false;
      });
    return visibiltyModal;
  }

  // @@ Table Configuration Props for Settings Popup @@ //
  const tableConfigurationProps = {
    TableSettings: {
      handleChangeForColumnModel: setColumnVisibilityModel,
      tableColumnsData: settingsColumnData || tableColumnsData,
      getExcel: getExcel,
      excelDoc: excelDoc,
      resetSettings: resetSettings,
      downloadFile: downloadFile,
      reSetExcel: reSetExcel,
      handleChangeColumns: handleChangeColumns,
      tableSettingIconProps: tableSettingIconProps,
    },
  };

  function Footer() {
    return (
      <>
        <div
          style={{
            padding: '2px 8px',
            boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            flexDirection: 'row',
            position: 'fixed',
            bottom: '0',
            width: '100%',
            zIndex: 1,
            backgroundColor: '#F7F7F7',
          }}
        >
          <Typography
            variant="caption"
            style={{
              fontSize: '12px',
              color: 'rgba(0, 17, 34, 0.75)',
              lineHeight: '20px',
              fontWeight: 400,
            }}
          >
            Total:
            {` ${
              totalDataInTable
                ? totalDataInTable
                : totalNumberOfRows || tableData?.length
            } `}
            Rows
          </Typography>
          {selectedCheckbox > 0 && (
            <Typography
              variant="caption"
              style={{
                fontSize: '12px',
                color: 'rgba(0, 17, 34, 0.75)',
                lineHeight: '20px',
                fontWeight: 400,
              }}
            >
              (Selected: {selectedCheckbox} Rows)
            </Typography>
          )}
        </div>
        {!hideNumericColumnsCount && (
          <div
            style={{
              padding: '2px 8px',
              // boxShadow: "inset 0px 1px 0px rgba(0, 0, 0, 0.25)",
              display: 'flex',
              flexDirection: 'row',
              marginRight: '2px',
            }}
          >
            {fetchNumericColumns().map((column: any) => (
              <SummaryColumn
                numericColumnField={column}
                tableData={tableData}
                columnTotalData={columnTotalData}
                key={column.field}
              />
            ))}
          </div>
        )}
      </>
    );
  }

  const cellName = (params: GridCellParams) => {
    if (params?.value?.color) {
      return params?.value?.color;
    }
  };

  const proccesRowUpdate = (newRow: GridRowModel) => {
    if (newRow?.length > 0) {
      updateRowsAfterEdit(newRow);
    }
    return newRow;
  };

  const handleCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if ('isHyperLink' in params.colDef && params.value && onCellClick) {
      event.stopPropagation();
      return onCellClick(params, event, details);
    }
    // move this string in to constants
    if (params.field === '__check__') {
      event.stopPropagation();
      // onShowPanel(false);
    } else {
      // onShowPanel(true);
    }
  };

  const handleRowClick = (params: GridRowParams) => {
    if (disableSelectionRowStyle) return;
    setSelectedRowId(params.row.id);
    if (handleSelectItem) {
      handleSelectItem(params.row as any);
    }
  };

  const handleRowScrollEnd = (
    params: GridRowScrollEndParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    if (!params.virtualRowsCount) return;
    handleScroll?.(params, event, details);
  };

  return (
    <Card
      elevation={8}
      sx={{
        backgroundColor: 'mainContainerBG.backgroundColor',
        backgroundImage: 'unset',
        boxShadow: 'none',
        // marginLeft: 4,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid
        className="FiltersWrapper"
        container
        sx={{ alignItems: 'center' }}
        flexDirection={'row'}
      >
        {!isColorCellGrid && (
          <FiltersWrapper
            customFilterComponent={customFilterComponent}
            filterColumns={filterColumns}
            onPageFilterChange={onPageFilterChange}
            defaultFilterValue={defaultFilterValue}
            displayFilters={displayFilters}
            onAllFilterSubmit={onAllFilterSubmit}
            onAllFilterReset={onAllFilterReset}
            filterPerCol={filterPerCol}
            tableConfigurationProps={tableConfigurationProps}
            settingsPanel={settingsPanel}
            gridBottomMenu={gridBottomMenu}
            pageName={pageName}
          />
        )}
      </Grid>
      <div
        className="CommonDataGridContainer"
        id="CommonDataGridContainer"
        style={{
          display: 'flex',
          minHeight: dataGridContainerHeight,
          height: '100%',
        }}
      >
        <Box sx={{ flexGrow: 1, mt: 1, pb: 2 }}>
          <DataGridPro
            {...props}
            headerHeight={45}
            getDetailPanelContent={
              isExpandable
                ? ({ row }) => (
                    <DetailPanelContent
                      row={row}
                      columns={subTableColumnsData}
                    />
                  )
                : undefined
            }
            initialState={{
              pinnedColumns: pinnedColumns,
            }}
            getDetailPanelHeight={getDetailPanelHeight}
            components={{
              Footer: customFooter ? footerComponent : Footer,
              NoRowsOverlay: NoRowsOverlay ? NoRowsOverlay : undefined,
              ColumnMenu: customeGridColumnMenu
                ? customeGridColumnMenu
                : undefined,
              LoadingOverlay: LoadingSkeleton,
            }}
            apiRef={apiRef}
            sx={() => ({
              ...cardViewStyles,
              ...commonDataGridStyles,
              ...(gridCustomeStyle as any),
              ...(disableSelectionRowStyle && disableSelectionOnClickStyle),
            })}
            density={tableDensity}
            disableColumnMenu={isDisableColumnMenu}
            disableColumnReorder={disableColumnReorder}
            onColumnOrderChange={onColumnOrderChange}
            rows={tableRows}
            columns={
              primaryColumn3DotMenu
                ? preProcessTableColumnsData({
                    primaryColumn3DotMenu,
                    tableColumnsData,
                  })
                : tableColumnsData
            }
            rowHeight={rowHeight}
            checkboxSelection={checkboxSelection}
            onSelectionModelChange={(newSelectedIds: any) => {
              if (checkboxSelection) {
                if (getSelectedRowsCount) {
                  getSelectedRowsCount(newSelectedIds.length);
                }
                setSelectedCheckbox(newSelectedIds.length);
                selectRow
                  ? selectRow(newSelectedIds)
                  : setSelectionModelState(newSelectedIds);
              }
            }}
            selectionModel={checkboxSelection ? selectionModelState : undefined}
            disableSelectionOnClick={disableSelectionOnClick}
            disableMultipleSelection={disableMultipleSelection}
            onRowClick={handleRowClick}
            onCellClick={handleCellClick}
            isCellEditable={() => rowEditing}
            showColumnRightBorder={false}
            showCellRightBorder={false}
            onRowsScrollEnd={handleRowScrollEnd}
            onSortModelChange={onSortModelChange}
            componentsProps={componentsProps}
            loading={loading}
            getRowId={getRowId}
            disableVirtualization={true}
            {...(!lazyLoadingSettings && {
              getRowClassName: (params) =>
                `paxton-table--row ${
                  params.row.id === selectedRowId ? 'Mui-selected2' : ''
                }, ${params.row.pinned ? 'Mui-pinned' : ''}`,
            })}
            onColumnHeaderClick={onColumnHeaderClick}
            paginationMode={paginationMode}
            sortingMode={sortingMode}
            sortModel={sortModel}
            isRowSelectable={(params: GridRowParams) =>
              isRowSelectable ? isRowSelectable(params) : true
            }
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel: any) => {
              setColumnVisibilityModel(newModel);
              columnVisibilityModelChange &&
                columnVisibilityModelChange(newModel);
            }}
            editMode={editMode}
            processRowUpdate={proccesRowUpdate}
            experimentalFeatures={{
              newEditingApi: true,
              rowPinning: true,
              ...(lazyLoadingSettings && {
                lazyLoading: true,
              }),
            }}
            getCellClassName={cellName}
            pinnedRows={pinnedRows}
            {...(lazyLoadingSettings && {
              sortingMode: 'server',
              rowsLoadingMode: 'server',
              filterMode: 'server',
              disableVirtualization: false,
              rowCount: lazyLoadingSettings.rowCount,
              onFetchRows: lazyLoadingSettings.onFetchRows,
            })}
          />
        </Box>
      </div>
      {Boolean(bulkAction) && selectedCheckbox > 0 && (
        <BulkAction
          bulkAction={bulkAction}
          overBulkActionRender={overBulkActionRender}
        />
      )}
    </Card>
  );
};

export default CommonDataGrid;
