const AppointmentRequiredIcon = ({ size = 32 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={32} height={32} rx="16" fill="#ED9D02" />
      <rect width={32} height={32} rx="16" fill="black" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.965 20.61L21.792 19.437C21.206 18.851 20.256 18.851 19.671 19.437L18.749 20.359C18.543 20.565 18.231 20.634 17.965 20.517C16.629 19.932 15.309 19.045 14.132 17.868C12.96 16.696 12.076 15.382 11.49 14.051C11.368 13.776 11.439 13.453 11.652 13.24L12.478 12.414C13.149 11.743 13.149 10.794 12.563 10.208L11.39 9.03497C10.609 8.25397 9.34302 8.25397 8.56202 9.03497L7.91002 9.68597C7.16902 10.427 6.86002 11.496 7.06002 12.556C7.55402 15.169 9.07202 18.03 11.521 20.479C13.97 22.928 16.831 24.446 19.444 24.94C20.504 25.14 21.573 24.831 22.314 24.09L22.965 23.439C23.746 22.658 23.746 21.392 22.965 20.61V20.61Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 10.9906C18.031 10.9766 19.067 11.3586 19.854 12.1456"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.682 9.31787C21.113 7.74887 19.056 6.96387 17 6.96387"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.009 14.9995C21.023 13.9685 20.641 12.9325 19.854 12.1455"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6821 9.31738C24.2511 10.8864 25.0361 12.9434 25.0361 14.9994"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AppointmentRequiredIcon;
