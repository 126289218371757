import { t } from 'i18next';
import { ReactNode } from 'react';
import * as yup from 'yup';
import {
  CreateManageFormDataType,
  UnitType,
} from '../../../models/DTOs/ManageTypes/Requests';
import { ITabV2 } from '../../../ui-kit/components/TabsV2';
import BaseManageTypeForm from './Forms/BaseManageTypeForm';
import FinanceManageTypeForm from './Forms/FinanceTypeForm';

export const DOCUMENT_NAME = 'Document';
export const MAINTENANCE_NAME = 'Maintenance';
export const FINANCIAL_NAME = 'Financial';
export const COMMODITY_NAME = 'Commodity';
export const EXPENSE_NAME = 'Expense';

export const documentType = {
  label: 'Document',
  key: 'document',
  iconName: 'FolderOpenOutlined',
  source: 'MUIcons',
  labelItemName: 'Document Type',
  errorType: 'Document Type',
  name: DOCUMENT_NAME,
  dictionary: 'document',
};

export const maintenanceType = {
  label: 'Maintenance',
  key: 'maintenance',
  iconName: 'HomeRepairServiceOutlined',
  source: 'MUIcons',
  labelItemName: t('maintenanceItem'),
  errorType: t('maintenanceItem'),
  name: MAINTENANCE_NAME,
  dictionary: 'maintenance',
};

export const payItemType = {
  label: 'Financial Pay Items',
  key: 'financial',
  iconName: 'RequestQuoteOutlined',
  source: 'MUIcons',
  labelItemName: 'Financial Pay Item',
  errorType: 'Financial Pay Item',
  inputLabel: 'Item Name',
  name: FINANCIAL_NAME,
  dictionary: 'financial',
};

export const commodityType = {
  label: 'Commodity',
  key: 'commodity',
  iconName: 'ViewInArOutlined',
  source: 'MUIcons',
  labelItemName: 'Commodity Type',
  errorType: 'Commodity Type',
  name: COMMODITY_NAME,
  dictionary: 'commodity',
};

export const expenseType = {
  label: 'Expense Category',
  key: 'expense',
  iconName: 'PaymentsOutlined',
  source: 'MUIcons',
  labelItemName: 'Expense Category',
  errorType: 'Expense Category',
  name: EXPENSE_NAME,
  dictionary: 'expense',
};

export const entities = [
  documentType,
  maintenanceType,
  payItemType,
  commodityType,
  expenseType,
];

export type ManageTypeEntityType = ITabV2 & {
  labelItemName: string;
  errorType: string;
  name: string;
  dictionary: string;
};

export const manageTypesMap: Record<string, ManageTypeEntityType> = {
  [DOCUMENT_NAME]: documentType,
  [MAINTENANCE_NAME]: maintenanceType,
  [FINANCIAL_NAME]: payItemType,
  [COMMODITY_NAME]: commodityType,
  [EXPENSE_NAME]: expenseType,
};

export const component = 'ManageTypes';

export const baseFormDefaultData: CreateManageFormDataType = {
  itemName: '',
  description: '',
  iconName: '',
};

export const financeFormDefaultData: CreateManageFormDataType = {
  itemName: '',
  description: '',
  iconName: '',
  unit: [],
  deduction: false,
  settlementItem: false,
  invoiceItem: false,
};

export const formDefaultDataMap: Record<string, CreateManageFormDataType> = {
  [DOCUMENT_NAME]: baseFormDefaultData,
  [MAINTENANCE_NAME]: baseFormDefaultData,
  [FINANCIAL_NAME]: financeFormDefaultData,
  [COMMODITY_NAME]: baseFormDefaultData,
  [EXPENSE_NAME]: baseFormDefaultData,
};

export const manageTypesFormsMap: Record<string, ReactNode> = {
  [DOCUMENT_NAME]: <BaseManageTypeForm />,
  [MAINTENANCE_NAME]: <BaseManageTypeForm />,
  [FINANCIAL_NAME]: <FinanceManageTypeForm />,
  [COMMODITY_NAME]: <BaseManageTypeForm />,
  [EXPENSE_NAME]: <BaseManageTypeForm />,
};

export const units: UnitType[] = [
  {
    value: 'PERCENT',
    label: '%',
  },
  {
    value: 'PER_STOP',
    label: 'Per Stop',
  },
  {
    value: 'PER_HOUR',
    label: 'Per Hour',
  },
  {
    value: 'PER_DUTY_HOUR',
    label: 'Per Duty Hour',
  },
  {
    value: 'PER_LOAD',
    label: 'Per Load',
  },
  {
    value: 'PER_LOADED_MILE',
    label: 'Per Loaded Mile',
  },
  {
    value: 'PER_EMPTY_MILE',
    label: 'Per Empty Mile',
  },
  {
    value: 'PER_TOTAL_MILE',
    label: 'Per Total Mile',
  },
  {
    value: 'PER_OCCURENCE',
    label: 'Per Occurrence',
  },
  {
    value: 'PER_DAY',
    label: 'Per Day',
  },
];

export const unitValueLabelMap: Record<string, string> = {
  PERCENT: '%',
  PER_OCCURENCE: 'Per Occurrence',
  PER_DAY: 'Per Day',
  PER_EMPTY_MILE: 'Per Empty Mile',
  PER_LOADED_MILE: 'Per Loaded Mile',
  PER_LOAD: 'Per Load',
  PER_HOUR: 'Per Hour',
  PER_DUTY_HOUR: 'Per Duty Hour',
  PER_STOP: 'Per Stop',
  PER_TOTAL_MILE: 'Per Total Mile',
  PER_WEIGHT: 'Per Loaded Weight',
  PRICE_PER_MILE_MATRIX: 'Price Per Mile (Matrix)',
  PERCENT_MATRIX: '% (Matrix)',
};

const baseTypeValidationSchema = yup.object().shape({
  itemName: yup
    .string()
    .nullable()
    .max(100, `Cannot be longer than 100 characters`)
    .required('Item name is required'),
  description: yup
    .string()
    .nullable()
    .max(255, `Cannot be longer than 255 characters`),
});

const financeTypeValidationSchema = baseTypeValidationSchema.shape({
  settlementItem: yup.boolean(),
  invoiceItem: yup.boolean().when('settlementItem', {
    is: false,
    then: (schema) =>
      schema.notOneOf([false], 'At least one checkbox should be selected'),
  }),

  unit: yup
    .array()
    .max(2, '1 or 2 units should be selected')
    .min(1, '1 or 2 units should be selected'),
});

export const manageTypesValidationSchemaMap: Record<string, object> = {
  [DOCUMENT_NAME]: baseTypeValidationSchema,
  [MAINTENANCE_NAME]: baseTypeValidationSchema,
  [FINANCIAL_NAME]: financeTypeValidationSchema,
  [COMMODITY_NAME]: baseTypeValidationSchema,
  [EXPENSE_NAME]: baseTypeValidationSchema,
};

export const MANAGE_TYPE_NAME_BACKEND_VALIDATION_MESSAGE =
  'item with given item name already exists. Please enter another name';

export const manageTypeNameUniqueValidationMessageNames: Record<
  string,
  string
> = {
  [DOCUMENT_NAME]: 'Document',
  [MAINTENANCE_NAME]: 'Maintenance',
  [FINANCIAL_NAME]: 'Financial Pay',
  [COMMODITY_NAME]: 'Commodity',
  [EXPENSE_NAME]: 'Expense',
};
