import * as yup from 'yup';
import { LoadPriorityEnum } from '../../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import {
  nullableNumberYup,
  nullableStringMaxValidation,
  nullableStringYup,
} from '../../../../utils';

export const loadOverviewDetailsDefaultData = {
  seqNumber: '',
  referenceId: '',
  customer: null,
  trips: [],
  loads: [],
  invoiceTerminal: null,
  loadPriority: LoadPriorityEnum.None,
  canBeBrokered: false,
};

export const loadReferenceDetailsValidationSchema = yup.object().shape({
  seqNumber: nullableStringYup()
    .required('Load ID is required.')
    .max(20, 'Cannot be longer than 20 characters.'),
  referenceId: nullableStringMaxValidation(50),
  dispatchRevenue: nullableNumberYup()
    .min(0, 'Value must be between 0 and 999,999.9999.')
    .max(9999999.999, 'Value must be between 0 and 999,999.9999.'),
  loadPriority: yup.string().required('Load Priority is required.'),
});
