import React, { useEffect, useState } from 'react';
import { toast, ToastContainer, ToastPromiseParams } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface NotificationToastProps {
  type: 'SUCCESS' | 'FAILURE' | 'PROMISE' | 'INFO' | null;
  message?: string;
  promiseFunction?: Promise<any>;
  promiseMessage?: ToastPromiseParams<any>;
  onToastOpen?: () => void;
}

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PROMISE = 'PROMISE',
  INFO = 'INFO',
}

const NotificationToast = ({
  type,
  message,
  promiseFunction,
  promiseMessage,
  onToastOpen,
}: NotificationToastProps) => {
  const [autoClose, setAutoClose] = useState<number>(2500);

  useEffect(() => {
    switch (type) {
      case NotificationType.SUCCESS:
        setAutoClose(1000);
        toast.success(message, {
          onOpen: onToastOpen,
        });
        break;
      case NotificationType.FAILURE:
        setAutoClose(2500);
        toast.error(message, {
          onOpen: onToastOpen,
        });
        break;
      case NotificationType.PROMISE:
        if (promiseFunction && promiseMessage) {
          setAutoClose(1000);
          toast.promise(promiseFunction, promiseMessage, {
            onOpen: onToastOpen,
          });
        }
        break;
      case NotificationType.INFO:
        setAutoClose(1000);
        toast.info(message, {
          onOpen: onToastOpen,
        });
        break;
    }
  }, [type]);

  return (
    <ToastContainer
      theme="colored"
      autoClose={autoClose}
      style={{
        width: 350,
        marginTop: 40,
        fontSize: '14px',
        p: 1,
      }}
    />
  );
};

export default NotificationToast;
