import { Autocomplete } from '@components/ui-kit/autocomplete';
import { IconButton } from '@components/ui-kit/icon-button';
import { MenuItem } from '@components/ui-kit/menu-item';
import { Select } from '@components/ui-kit/select';
import { TextField } from '@components/ui-kit/text-field';
import { Typography } from '@components/ui-kit/typography';
import styled from '@emotion/styled';
import { EditOutlined } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useInfiniteQuery, useQuery } from 'react-query';
import {
  ContactSummaryCustomer,
  CustomerSummaryDTO,
} from '../../../../../models';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { ITerminalActual } from '../../../../../types/ELDManageTypes';
import { getFactoringList } from '../../../../../views/operations/contacts/utils/customer';
import { urls } from '../../apis';
import {
  CustomerRequiredDocOptionLabel,
  CustomerRequiredDocOptions,
} from '../../constants/fieldOptions';
import { SECTION_LAST_ELEMENT_ID_LIST } from '../../constants/fieldValues';
import { loadPriorityOptions } from '../../schema';
import { fetchCustomDropdownData } from '../../services';
import { createLoadService } from '../../services/createLoad.service';
import { customerFormService } from '../../services/customerForm.service';
import { ErrorTextFieldStyles, LoadPriorityDropdown } from '../../style';
import { formatPhoneNumber } from '../../utils';
import { CustomerProps } from '../../utils/customer';
import { ErrorText } from '../ErrorText';
import { ContactDetailsForm } from './contactDetailsForm';

const CustomerDetails: React.FC<CustomerProps> = ({ isTemplate = false }) => {
  const {
    setValue,
    getValues,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext();
  const [isRequiredDocumentsExpanded, setIsRequiredDocumentsExpanded] =
    useState(false);
  const [isInvoiceExpanded, setIsInvoiceExpanded] = useState(false);
  const [customerNameInput, setCustomerNameInput] = useState('');
  const [factoringNameInput, setFactoringNameInput] = useState('');
  const [customerContactInput, setCustomerContactInput] = useState('');
  const toggleInvoiceFieldExpanded = () => {
    setIsInvoiceExpanded(!isInvoiceExpanded);
  };
  const [contactDetailsCurrAction, setContactDetailsCurrAction] = useState<
    'EDIT' | 'ADD' | null
  >(null);
  const { allTerminals, getCompanyPreferences } = useRootStore();
  const stateCustomerData = watch('customer');
  const isTerminalEnabled = watch('isTerminalEnabled');
  const customerHiddenFields = watch('hiddenFields.customer');
  const customerRequiredFields = watch('requiredFields.customer');
  const loadHiddenFields = watch('hiddenFields.customer');
  useEffect(() => {
    setValue('rate.invoiceTerm', getCompanyPreferences?.invoiceTerm);
    setValue('rate.invoiceTermValue', getCompanyPreferences?.invoiceTermValue);
  }, [
    getCompanyPreferences?.invoiceTerm,
    getCompanyPreferences?.invoiceTermValue,
    setValue,
  ]);

  const editContact = () => setContactDetailsCurrAction('EDIT');
  const addContact = () => setContactDetailsCurrAction('ADD');

  const toggleRequiredDocumentsExpanded = () => {
    setIsRequiredDocumentsExpanded(!isRequiredDocumentsExpanded);
  };

  const isLastCustomerPageRef = useRef(false);
  const fetchCustomers = async ({ pageParam = 1 }) => {
    const res: any = await fetchCustomDropdownData({
      endpoint: urls.getCustomer,
      query: { customerName: customerNameInput ?? '' },
      pageSize: 50,
      pageNumber: pageParam,
    });
    if (res?.last) isLastCustomerPageRef.current = true;
    return res?.content;
  };

  const { data, fetchNextPage } = useInfiniteQuery(
    ['customer1', { customerName: customerNameInput }],
    fetchCustomers,
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        return (pages?.length || 0) + 1;
      },
    }
  );
  const customerData = data?.pages;

  const handleScrollCustomer = (event: any) => {
    if (isLastCustomerPageRef?.current) return;
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      fetchNextPage();
    }
  };

  const factoringOptions = useQuery<any>(
    ['factoring', { keyword: factoringNameInput }],
    () => getFactoringList(factoringNameInput, 50),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  const handleOnChangeCustomer = async (
    _: any,
    value: {
      name: any;
      contacts: ContactSummaryCustomer[];
      id?: number;
      value?: string;
      factoringId?: string;
      factoringName?: string;
    }
  ) => {
    let customerName = value?.name;
    try {
      let newCustomer = null;
      // create new customer since no record exists
      if (value?.name && !value?.id) {
        customerName = value?.value;
        newCustomer = await customerFormService.createNewCustomer(
          value?.value!
        );
        setCustomerNameInput('');
      }
      const primaryContact = value?.contacts?.find?.(
        (e: ContactSummaryCustomer) => e?.isPrimary
      );
      const customer = {
        ...stateCustomerData,
        customerId: value?.id ?? newCustomer?.id ?? null,
        customerName: customerName ?? null,
        contacts: value?.contacts ?? [],
        customerContactId: primaryContact?.id ?? '',
        factoringId: value?.factoringId ?? '',
        factoringName: value?.factoringName ?? '',
        selectedPhoneData: primaryContact ?? null,
        selectedCustomer: newCustomer ? newCustomer : value,
      };
      setValue('customer', customer);
      setValue('customer.contacts', value?.contacts);
      setFactoringNameInput('');
    } catch (error) {
      setValue('customer.customerName', '');
    }
    if (errors?.customer?.customerName) trigger?.();
  };

  const selectedTerminalName = useMemo(() => {
    const terminal = allTerminals?.find?.(
      (e: { id: string }) => e?.id === stateCustomerData?.invoice
    );
    return terminal?.companyName ?? '';
  }, [stateCustomerData?.invoice]);

  const getInvoiceOptionLabel = useCallback(
    (value: object) =>
      createLoadService.getAutocompleteOptionLabel({
        list: allTerminals,
        value,
        fieldName: 'companyName',
      }),
    [allTerminals]
  );

  const getCustomerContactOptionLabel = useCallback(
    (value: object) => {
      const t = createLoadService.getAutocompleteOptionLabel({
        list: stateCustomerData?.contacts,
        value,
        fieldName: 'fullName',
        returnDefaultValue: false,
      });
      return t;
    },
    [stateCustomerData?.contacts]
  );

  const getFactoringOptionLabel = useCallback(
    (value: object) => {
      return createLoadService.getAutocompleteOptionLabel({
        list: factoringOptions?.data?.content,
        value,
        fieldName: 'displayName',
        returnDefaultValue: false,
      });
    },
    [factoringOptions?.data?.content]
  );

  const getCustomerOptions = useCallback(
    () =>
      customerFormService.getCustomerDropdownOptions({
        customersList: customerData as any,
        searchText: customerNameInput,
      }),
    [customerData, customerNameInput]
  );

  const handleCustomerContactUpdate = (
    customer: CustomerSummaryDTO,
    contact?: ContactSummaryCustomer
  ) => {
    setValue('customer.customerContactId', contact?.id);
    // setSelectedPhoneData(contact!);
    setValue('customer.selectedPhoneData', contact!);
    // setContacts(customer?.contacts ?? [contact]);
    setValue('customer.contacts', customer?.contacts ?? [contact]);
    setContactDetailsCurrAction(null);
  };

  const handleOnChangeCustomerContact = async (
    data: ContactSummaryCustomer
  ) => {
    if (data?.value && !data?.id) {
      if (!stateCustomerData?.selectedCustomer)
        setValue('customer.selectedCustomer', stateCustomerData);
      setValue('customer.selectedPhoneData', {
        ...data,
        fullName: (data as any)?.value,
      });
      // setSelectedPhoneData({ ...data, fullName: (data as any)?.value });
      addContact();
    } else {
      setValue('customer.selectedPhoneData', data);
      // setSelectedPhoneData(data);}
    }
  };
  const getCustomerContactOptions = useMemo(
    () =>
      customerFormService.getCustomerContactDropdownOptions({
        contacts: stateCustomerData?.contacts,
        searchText: customerContactInput,
      }),
    [stateCustomerData?.contacts, customerContactInput]
  );
  const notchedOutline = {
    borderWidth: '1px',
    borderColor: 'green !important',
  };

  return (
    <Stack direction="column" spacing={2}>
      <Box flexGrow={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack>
              <Controller
                name="customer.customerName"
                render={({ field, ...rest }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={getCustomerOptions()}
                      getOptionLabel={(option) => option?.name || option}
                      clearOnBlur
                      handleHomeEndKeys
                      id="createLoad-customer-name"
                      onInputChange={(_, v) => {
                        setCustomerNameInput(v);
                      }}
                      onChange={handleOnChangeCustomer}
                      ListboxProps={{
                        onScroll: (data: any) => {
                          handleScrollCustomer(data);
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={customerRequiredFields?.customerId}
                          autoFocus
                          label="Customer Name"
                          sx={
                            errors?.customer?.customerName?.message
                              ? ErrorTextFieldStyles
                              : {}
                          }
                        />
                      )}
                    />
                  );
                }}
              />
              <ErrorText text={errors?.customer?.customerName?.message} />
            </Stack>
          </Grid>
          {isTerminalEnabled && (
            <Grid item xs={12} md={6}>
              {isInvoiceExpanded || !stateCustomerData?.invoice?.length ? (
                <Stack>
                  <Controller
                    name="customer.invoice"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        id="createLoad-customer-invoice"
                        options={allTerminals ?? []}
                        value={stateCustomerData?.invoice}
                        getOptionLabel={getInvoiceOptionLabel}
                        onChange={(_, value) => {
                          setValue(
                            'customer.invoiceCompanyName',
                            (value as ITerminalActual)?.companyName ?? ''
                          );
                          field.onChange((value as any)?.id ?? '');
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Invoice Terminal"
                            sx={
                              errors?.customer?.invoice?.message
                                ? ErrorTextFieldStyles
                                : {}
                            }
                          />
                        )}
                      />
                    )}
                  />
                  <ErrorText text={errors?.customer?.invoice?.message} />
                </Stack>
              ) : (
                <Stack direction="row" justifyContent="flex-end">
                  <CondensedFormFieldWrapper
                    onClick={toggleInvoiceFieldExpanded}
                    style={{ alignSelf: 'center' }}
                  >
                    Invoice: <b>{selectedTerminalName}</b>
                  </CondensedFormFieldWrapper>
                </Stack>
              )}
            </Grid>
          )}
          {stateCustomerData?.customerName && (
            <Grid item xs={12} md={12}>
              <Stack
                direction="column"
                display={customerHiddenFields?.factoringId ? 'none' : undefined}
              >
                <Controller
                  name="customer.factoringId"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disabled={!stateCustomerData?.customerName}
                      id="createLoad-customer-factoring-company"
                      options={factoringOptions?.data?.content ?? []}
                      getOptionLabel={getFactoringOptionLabel}
                      onInputChange={(_, v) => {
                        setFactoringNameInput(v);
                      }}
                      onChange={(_, data) => {
                        setValue(
                          `customer.factoringName`,
                          (data as any)?.displayName
                        );
                        setValue(
                          `customer.factoringChargePercent`,
                          (data as any)?.factoringCharge
                        );
                        field.onChange((data as ContactSummaryCustomer)?.id);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Factoring Company" />
                      )}
                    />
                  )}
                />
              </Stack>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={6}
            display={
              customerHiddenFields?.customerContactId ? 'none' : undefined
            }
          >
            <Stack direction="column">
              <>
                <Controller
                  name="customer.customerContactId"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="createLoad-customer-contact"
                      handleHomeEndKeys
                      disabled={!Boolean(stateCustomerData?.customerName)}
                      options={getCustomerContactOptions}
                      getOptionLabel={getCustomerContactOptionLabel}
                      value={stateCustomerData.customerContactId}
                      onChange={(_, data) => {
                        handleOnChangeCustomerContact(
                          data as ContactSummaryCustomer
                        );
                        field.onChange((data as ContactSummaryCustomer)?.id);
                      }}
                      onInputChange={(_, v) => setCustomerContactInput(v)}
                      renderInput={(params) => (
                        <TextField {...params} label="Contact" />
                      )}
                    />
                  )}
                />
              </>
              {stateCustomerData?.selectedPhoneData && (
                <Stack
                  direction="row"
                  gap={1}
                  sx={{ mt: '3px' }}
                  alignItems={'center'}
                >
                  <Stack direction={'row'} sx={{ flexFlow: 'wrap' }}>
                    <Typography variant="body2" color="textSecondary">
                      {formatPhoneNumber(
                        stateCustomerData?.selectedPhoneData?.phoneData
                      )}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      {stateCustomerData?.selectedPhoneData?.email}
                    </Typography>
                    {!stateCustomerData?.selectedPhoneData?.phoneData?.phone &&
                      !stateCustomerData?.selectedPhoneData?.email && (
                        <Typography variant="body2" color="textSecondary">
                          Update contact
                        </Typography>
                      )}
                  </Stack>
                  <IconButton size="small">
                    <EditOutlined
                      fontSize={'small'}
                      onClick={() => editContact()}
                    />
                  </IconButton>
                </Stack>
              )}
              <ContactDetailsForm
                open={Boolean(contactDetailsCurrAction)}
                data={stateCustomerData?.selectedPhoneData}
                type={contactDetailsCurrAction}
                contacts={stateCustomerData?.contacts}
                customerDetails={stateCustomerData?.selectedCustomer!}
                onUpdateCustomer={handleCustomerContactUpdate}
                onClose={() => {
                  if (contactDetailsCurrAction !== 'EDIT')
                    setValue('customer.selectedPhoneData', null);
                  setContactDetailsCurrAction(null);
                }}
              />
            </Stack>
          </Grid>
          {!isTemplate && (
            <Grid
              item
              xs={12}
              md={6}
              display={
                customerHiddenFields?.referenceNumber ? 'none' : undefined
              }
            >
              <Controller
                name="customer.referenceNumber"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    id="createLoad-customer-referenceNumber"
                    {...field}
                    label="Reference Number"
                    sx={
                      errors?.customer?.referenceNumber
                        ? ErrorTextFieldStyles
                        : {}
                    }
                  />
                )}
              />
              <ErrorText text={errors?.customer?.referenceNumber?.message} />
            </Grid>
          )}
        </Grid>
      </Box>
      <Stack direction="row" spacing={1} alignItems="flex-end">
        <Box
          display={customerHiddenFields?.requiredDocuments ? 'none' : undefined}
          width={'100%'}
        >
          {isRequiredDocumentsExpanded ? (
            <Controller
              name="customer.requiredDocuments"
              render={({ field }) => (
                <Autocomplete
                  sx={{ flexGrow: 1 }}
                  id="createLoad-customer-requiredDocuments"
                  options={CustomerRequiredDocOptions}
                  multiple
                  isOptionEqualToValue={(option, val) => {
                    return val === option?.id;
                  }}
                  getOptionLabel={(option) =>
                    createLoadService.getAutocompleteOptionLabel({
                      list: CustomerRequiredDocOptions,
                      value: option,
                      fieldName: 'label',
                    })
                  }
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value?.map((e) => e?.id ?? e));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Required Documents" />
                  )}
                />
              )}
            />
          ) : (
            <Box sx={{ flexGrow: 1 }}>
              {requiredDocuments && (
                <CondensedFormFieldWrapper
                  onClick={toggleRequiredDocumentsExpanded}
                >
                  <span>Req Docs: </span>
                  {stateCustomerData?.requiredDocuments?.map?.(
                    (doc: string, i: number) => (
                      <span key={doc} style={{ fontWeight: 500 }}>
                        {i === 0
                          ? CustomerRequiredDocOptionLabel[doc]
                          : ', ' + CustomerRequiredDocOptionLabel[doc]}
                      </span>
                    )
                  )}
                </CondensedFormFieldWrapper>
              )}
            </Box>
          )}
        </Box>
        {!loadHiddenFields?.loadPriority && (
          <Stack
            direction={'row'}
            gap={0.5}
            alignItems={'center'}
            sx={{ minWidth: '150px' }}
          >
            <Box width={'145px'}>
              <Typography
                variant="body2"
                color={'textSecondary'}
                width={'max-content'}
              >
                Load Priority:{' '}
              </Typography>
            </Box>
            <Controller
              name="customer.loadPriority"
              render={({ field }) => (
                <Select
                  id={SECTION_LAST_ELEMENT_ID_LIST[1]}
                  {...field}
                  label=""
                  sx={LoadPriorityDropdown}
                >
                  {loadPriorityOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default React.memo(CustomerDetails);

const CondensedFormFieldWrapper = styled.div`
  cursor: pointer;
  padding: 3px;
  border-radius: 5px;
  &:hover {
    background-color: rgba(43, 100, 203, 0.15);
  }
`;

//////////////////////////////////////////////////////////////////////////////////////
// Stub Hard-coded values + other Temp stuff
const requiredDocuments = [
  { label: `Bill of Lading` },
  { label: 'Proof of Delivery' },
  { label: 'Custom Document' },
];
