import { EEDITendersStatus } from './types';

export enum EEDITenderPanelTabs {
  TRIP_PLAN = 'TRIP_PLAN',
  DETAILS = 'DETAILS',
}

export const FailedTenderText = {
  [EEDITendersStatus.Declined]:
    'Something went wrong while updating shipment IDs',
  [EEDITendersStatus.Accepted]:
    'Some information missing to create load for shipment IDs',
};
