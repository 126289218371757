import React, { useEffect, useState } from 'react';
import { contactsService } from '../api';
import { ContactsListDataTotal, QueryParams } from '../models';
import { View } from '../types';

export type ContactsContextType = {
  currentView: View;
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
  addNewClicked: boolean;
  setAddNewClicked: React.Dispatch<React.SetStateAction<boolean>>;
  component: string;
  listDataTotal: ContactsListDataTotal;
  setListDataTotal: React.Dispatch<React.SetStateAction<ContactsListDataTotal>>;
  dictionary: string;
};

export const ContactsContext = React.createContext<ContactsContextType>(
  {} as ContactsContextType
);
const component = 'Contacts';
const dictionary = 'location';

export const ContactsContextProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [currentView, setCurrentView] = useState<View>({} as View);

  const [addNewClicked, setAddNewClicked] = useState<boolean>(false);
  const [listDataTotal, setListDataTotal] = useState<ContactsListDataTotal>({
    customerTotal: 0,
    vendorTotal: 0,
    locationTotal: 0,
  });

  const getListDataTotal = async () => {
    try {
      const response = await contactsService.getListDataTotal(
        new QueryParams()
      );
      setListDataTotal(response);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    getListDataTotal();
  }, []);

  return (
    <ContactsContext.Provider
      value={{
        currentView,
        setCurrentView,
        addNewClicked,
        setAddNewClicked,
        component,
        listDataTotal,
        setListDataTotal,
        dictionary,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export const useContacts = () => React.useContext(ContactsContext);
