import { Box } from '@mui/material';
import { CarrierInvoiceReceivedPayment } from './CarrierInvoiceReceivedPayment';

export const CarrierFinanceFooter = () => {
  const financeFooterStyle = {
    backgroundColor: `primary.outlinedHoverBackground`,
    padding: '12px',
    margin: '12px 0px 50px',
    borderRadius: '8px',
    display: 'flex',
    width: '100%',
  };

  return (
    <Box className="CarrierFinanceFooter-root" sx={financeFooterStyle}>
      <CarrierInvoiceReceivedPayment />
    </Box>
  );
};
