import React, { useEffect, useState } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import {
  GetVendorFiltersRequest,
  PaginatedVendorsListRequest,
} from '../../../models';
import { VendorSummaryTableRow } from '../models/vendor';

import { vendorService } from '../../../api';
import { IHttpErrorType } from '../../../types';
import {
  vendorDefaultData,
  vendorDetailsFormValidationSchema,
} from '../constants';
import VendorDetailsForm from '../forms/VendorDetailsForm';
import { ActionsSection } from '../actionsSection';

interface IProps {
  data: any;
  panelTitle: string;
  isCreatePanelOpen: boolean;
  httpErrors: IHttpErrorType;
  onClose: () => void;
  onUpdated: (data: any) => void;
  onDeleted: (data: any) => void;
  onCreated: (data: any) => void;
  updateVendorSummary: (data: VendorSummaryTableRow) => void;
  isGlobal: boolean;
}

const VendorDetailsPanel: React.FC<IProps> = ({
  data,
  panelTitle,
  isCreatePanelOpen,
  onClose,
  onUpdated,
  onDeleted,
  onCreated,
  updateVendorSummary,
  httpErrors,
  isGlobal = false,
}) => {
  const handleUpdate = (vendor: VendorSummaryTableRow): void => {
    onUpdated(vendor);
    setVendorDetailsData(vendor);
  };

  const handleDelete = (id: string): void => {
    onDeleted(id);
  };

  const handleCreate = (vendor: VendorSummaryTableRow): void => {
    onCreated(vendor);
  };

  const [vendorDetailsData, setVendorDetailsData] =
    useState<VendorSummaryTableRow | null>(null);

  useEffect(() => {
    setVendorDetailsData(vendorDefaultData);
    if (typeof data === 'object' && data?.id && !isCreatePanelOpen) {
      getVendorDetails(data?.id);
    }
  }, [data, isCreatePanelOpen]);

  async function getVendorDetails(id: number) {
    try {
      const vendorDetails = await vendorService.getPaginatedVendorsList(
        new PaginatedVendorsListRequest({
          pageNumber: 1,
          pageSize: 1,
          sort: '+name',
        }),
        new GetVendorFiltersRequest({
          vendorNames: null,
          vendorAddress: null,
          vendorContactNames: null,
          preferredProhibitedEnum: null,
          vendorIds: [id.toString()],
        })
      );

      setVendorDetailsData(vendorDetails?.content[0]);
    } catch (error) {}
  }

  const actionsRenderer = () => {
    return (
      <>
        {vendorDetailsData?.id && (
          <ActionsSection
            onDeleted={onDeleted}
            vendorData={vendorDetailsData}
          />
        )}
      </>
    );
  };

  return (
    <DetailsPanel
      panelTitle={panelTitle}
      data={vendorDetailsData}
      actionButtonLabel={data.id ? undefined : 'Add Vendor'}
      entity="Vendor"
      onClose={onClose}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      onCreate={handleCreate}
      contentRenderer={() => (
        <VendorDetailsForm
          isCreatePanelOpen={isCreatePanelOpen}
          updateVendorSummary={updateVendorSummary}
        />
      )}
      validationSchema={vendorDetailsFormValidationSchema}
      isSaveIconVisible={!isCreatePanelOpen}
      httpErrors={httpErrors}
      isGlobal={isGlobal}
      actionsRenderer={actionsRenderer}
    />
  );
};

export default VendorDetailsPanel;
