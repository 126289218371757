import { MoreVertOutlined } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  Stack,
  ThemeProvider,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';
import useFiltersAutoResize, {
  mergeHiddenColumns,
} from '../../../../common/hooks/useFiltersAutoResize';
import { getThemeDispatchFilters } from '../../../../views/dispatch2/components/gantt/Filters/styles';
import {
  GridBottomMenu,
  GridBottomMenuItemClassName,
} from '../components/BottomMenu';
import TableSettings from '../settings/TableSettings';
import { IDataGridProProps } from '../types';
import { useThemeResponsive } from '../utils/useThemeResponsive';
import AllFilters from './AllFilters';
import Filters from './Filters';

export interface IFiltersWrapper
  extends Pick<
    IDataGridProProps,
    | 'onAllFilterSubmit'
    | 'onAllFilterReset'
    | 'filterPerCol'
    | 'settingsPanel'
    | 'defaultFilterValue'
    | 'onPageFilterChange'
    | 'displayFilters'
    | 'filterColumns'
    | 'customFilterComponent'
    | 'gridBottomMenu'
    | 'pageName'
  > {
  tableConfigurationProps: {
    TableSettings: Record<string, any>;
  };
  panelState?: boolean;
}

export const FiltersWrapper = ({
  customFilterComponent,
  filterColumns,
  displayFilters,
  onPageFilterChange,
  defaultFilterValue,
  onAllFilterSubmit,
  onAllFilterReset,
  filterPerCol,
  settingsPanel,
  tableConfigurationProps,
  gridBottomMenu,
  pageName,
  panelState,
}: IFiltersWrapper): JSX.Element => {
  const { isMobile } = useThemeResponsive();
  const currTheme = useTheme();
  const overrideTheme = getThemeDispatchFilters({ currTheme });

  const [showGridBottomMenu, setShowGridBottomMenu] =
    React.useState<boolean>(false);

  const onClick3DotMenuIconHandler = () => {
    setShowGridBottomMenu((prev) => !prev);
  };

  const {
    tableSettingsRef,
    hiddenColumns,
    filterRefs,
    allFilterRef,
    containerRef,
  } = useFiltersAutoResize({
    panelState,
  });

  const onClickAwayGridBottomMenu = (event: MouseEvent | TouchEvent) => {
    const isMenuItemAction =
      !!event?.target?.classList?.contains(GridBottomMenuItemClassName) ||
      !!event?.target?.parentElement?.classList?.contains(
        GridBottomMenuItemClassName
      );
    if (!isMenuItemAction) {
      setShowGridBottomMenu(false);
    }
  };

  const displayFilterColumns = useMemo(
    () => mergeHiddenColumns(filterColumns, hiddenColumns),
    [filterColumns, hiddenColumns]
  );
  return (
    <ThemeProvider theme={overrideTheme}>
      <Grid
        id="FilterGridRow"
        container
        flexDirection={'row'}
        ref={containerRef}
      >
        {customFilterComponent ? (
          customFilterComponent
        ) : filterColumns?.length == 0 || isMobile ? (
          ''
        ) : (
          <Filters
            columns={displayFilterColumns}
            onChangeCb={onPageFilterChange}
            defaultFilterValue={defaultFilterValue}
            pageName={pageName}
            setRef={(index: number, ref: HTMLDivElement, column: any) => {
              filterRefs.current[index] = {
                name: column.name,
                el: ref,
              };
            }}
          />
        )}

        {isMobile ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            margin={1}
            width={'100%'}
          >
            {displayFilters && (
              <AllFilters
                column={filterColumns?.map((filterCol) => ({
                  ...filterCol,
                  default: false,
                }))}
                handleOnSubimt={onAllFilterSubmit}
                handleOnReset={onAllFilterReset}
                filterPerCol={filterPerCol}
                defaultFilterValue={defaultFilterValue}
                pageName={pageName}
              />
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              {settingsPanel && (
                <TableSettings {...tableConfigurationProps.TableSettings} />
              )}
              {gridBottomMenu && (
                <ClickAwayListener onClickAway={onClickAwayGridBottomMenu}>
                  <Box
                    sx={{
                      pt: 2,
                    }}
                    onClick={onClick3DotMenuIconHandler}
                  >
                    <IconButton>
                      <MoreVertOutlined
                        sx={{
                          width: 22,
                          height: 22,
                          color: 'primary.main',
                        }}
                      />
                    </IconButton>
                  </Box>
                </ClickAwayListener>
              )}
            </Stack>
          </Stack>
        ) : (
          <>
            {displayFilters && (
              <AllFilters
                column={displayFilterColumns}
                handleOnSubimt={onAllFilterSubmit}
                handleOnReset={onAllFilterReset}
                filterPerCol={filterPerCol}
                defaultFilterValue={defaultFilterValue}
                pageName={pageName}
                ref={allFilterRef}
              />
            )}
            {settingsPanel && (
              <TableSettings
                ref={tableSettingsRef}
                {...tableConfigurationProps.TableSettings}
              />
            )}
          </>
        )}

        {gridBottomMenu && showGridBottomMenu ? (
          <GridBottomMenu
            {...gridBottomMenu}
            onClose={onClick3DotMenuIconHandler}
          />
        ) : (
          <></>
        )}
      </Grid>
    </ThemeProvider>
  );
};
