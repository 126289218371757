import Grid from '@mui/material/Grid';
import { PreferencesTabStripType } from '../../../../../models';
import SaveChanges from './SaveChanges';
import TabStripPreferences from './TabStripPreferences';

export default function HeaderPreferences({
  handleSubmit,
  entity,
  setEntity,
}: {
  handleSubmit: () => void;
  entity: PreferencesTabStripType;
  setEntity: (data: PreferencesTabStripType) => void;
}) {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems="flex-end"
      spacing={2}
      direction="row"
    >
      <Grid item xs={12} lg={10}>
        <TabStripPreferences entity={entity} setEntity={setEntity} />
      </Grid>
      <Grid
        item
        xs={12}
        lg={2}
        display="flex"
        sx={(theme) => ({
          justifyContent: 'flex-start',
          [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end',
          },
        })}
      >
        <SaveChanges handleSubmit={handleSubmit} />
      </Grid>
    </Grid>
  );
}
