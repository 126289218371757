import { Typography } from '@mui/material';
import { customerTypeOptions } from '../../../../constants/contacts';
import { GridColDefSelf } from '../../../../types';
import {
  formatPhoneNumber,
  numberWithThousandSeparator,
} from '../../../../utils';
import { getCustomerVendorLocationName } from '../utils/sharedMethods';

const getCustomerType = ({ value }: { value: string }) =>
  customerTypeOptions.find((option) => option.id === value)?.name || '';

export const customerColumns: GridColDefSelf[] = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 250,
    editable: false,
    sortable: true,
    permanent: true,
    renderCell: (params) => {
      return getCustomerVendorLocationName(params);
    },
    flex: 1,
  },
  {
    field: 'mc',
    headerName: 'MC#',
    sortable: true,
  },
  {
    field: 'type',
    headerName: 'Type',
    sortable: true,
    renderCell: (params) => {
      return getCustomerType(params);
    },
  },
  {
    field: 'factoringName',
    headerName: 'Factoring',
    sortable: false,
  },
  {
    field: 'openCreditAmount',
    headerName: 'Open Credit',
    sortable: false,
    renderCell: (params) => {
      return (
        <Typography
          variant="body2"
          sx={[
            {
              color: 'text.primary',
              fontSize: '14px',
              lineHeight: '21px',
              fontWeight: 700,
            },
          ]}
        >
          ${numberWithThousandSeparator(params.value || 0, false, 2)}
        </Typography>
      );
    },
  },
  {
    field: 'contact',
    headerName: 'Contact',
    sortable: false,
  },

  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 250,
    sortable: false,
    renderCell: (params: any) => {
      const { row } = params;
      const phone = formatPhoneNumber(row?.phoneData);
      return phone ? (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <a href={`tel:${phone}`}>{phone}</a>
        </span>
      ) : (
        ''
      );
    },
  },
  {
    field: 'fullAddress',
    headerName: 'Address',
    sortable: false,
    minWidth: 250,
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: false,
    minWidth: 250,
  },

  {
    field: 'notes',
    headerName: 'Notes',
    sortable: false,
    minWidth: 250,
  },
];
