import {
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import StorageManager from '../StorageManager/StorageManager';
import { GridColDefSelf } from '../types';

export const onColumnOrderChange = (
  params: {
    params: GridColumnOrderChangeParams;
    event: MuiEvent<{}>;
    details: GridCallbackDetails;
  },
  page: string,
  entity: string,
  isSelectable = false
) => {
  const settings = StorageManager.getItem('user-settings');
  if (!settings?.pages?.[page]?.[entity]?.columnsOrdering) return;
  const columnsOrdering = params.details?.api
    .getVisibleColumns()
    .filter(({ type }: { type: string }) => {
      return type !== 'checkboxSelection';
    })
    .map(({ field }: { field: string }) => field);

  settings.pages[page][entity].columnsOrdering = columnsOrdering;
  StorageManager.setItem('user-settings', settings);
};

export const prepareColumnOrdering = (
  columns: GridColDefSelf[] = [],
  page: string,
  entity: string
): GridColDefSelf[] => {
  const settings = StorageManager.getItem('user-settings');

  if (settings.pages?.[page]?.[entity]?.columnsOrdering) {
    const columnsOrdering = settings.pages[page][entity]?.columnsOrdering;
    return columns.sort(
      (a, b) =>
        columnsOrdering.indexOf(a.field) - columnsOrdering.indexOf(b.field)
    );
  }

  const newSettings = {
    ...settings,
    pages: {
      ...settings.pages,
      [page]: {
        ...(settings.pages[page] || {}),
        [entity]: {
          ...(settings.pages?.[page]?.[entity] || {}),
          columnsOrdering: columns.map((item) => item.field),
        },
      },
    },
  };

  StorageManager.setItem('user-settings', newSettings);

  return columns;
};
