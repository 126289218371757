import { accountingService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import {
  ChangeClassMappingTypeRequest,
  ChangeMappingRequest,
  ClassMappingDropdownItemType,
  ClassMappingTableType,
  GetClassMappingListRequest,
  GetIsClassMappingActiveRequest,
  RefreshClassMappingTypeRequest,
} from '../../../../../models';
import { getDriverNamesList } from '../../../users/utils';
import TractorController from '../../../../../subPages/tractors/Controller';

class ClassMappingActions {
  static async getIsClassMappingActive(qbAccountId: string) {
    const request = new GetIsClassMappingActiveRequest();
    request.qbAccountId = qbAccountId;
    const response = await accountingService.getIsClassMappingActive(request);
    if (response instanceof ServiceError) {
      return false;
    } else {
      return response;
    }
  }

  static async getClassMappingList(qbAccountId: string) {
    const request = new GetClassMappingListRequest();
    request.qbAccountId = qbAccountId;
    const response = await accountingService.getClassMappingList(request);
    if (response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  static async changeClassMappingType(qbAccountId: string, type: string) {
    const request = new ChangeClassMappingTypeRequest();
    request.qbAccountId = qbAccountId;
    request.mappingEntityType = type;
    const response = await accountingService.changeClassMappingType(request);
    if (response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  static async refreshClassMappingType(qbAccountId: string) {
    const request = new RefreshClassMappingTypeRequest();
    request.qbAccountId = qbAccountId;
    const response = await accountingService.refreshClassMappingType(request);
    if (response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  static async mappingChange(
    qbAccountId: string,
    value: ClassMappingDropdownItemType,
    mappingType: string,
    mappingEntityId: string
  ) {
    const request = new ChangeMappingRequest();
    request.qbAccountId = qbAccountId;
    request.qbClassId = value.id;
    request.qbClass = value.name;
    request.mappingEntityType = mappingType;
    request.mappingEntityId = mappingEntityId;
    const response = await accountingService.mappingChange(request);
    if (response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  static async getClassMappingDropdown(qbAccountId: string) {
    const request = new ChangeMappingRequest();
    request.qbAccountId = qbAccountId;
    const response = await accountingService.getClassMappingDropdown(request);
    if (response instanceof ServiceError) {
      return [];
    } else {
      return response;
    }
  }

  static async getDrivers(
    terminalIds: string[],
    driverClassCategoriesMapping: Record<string, ClassMappingDropdownItemType>
  ) {
    const data = await getDriverNamesList('', 1, terminalIds, false, 5000);
    if (data instanceof Array) {
      return [];
    }
    const result: ClassMappingTableType[] = data.content.map((driver) => ({
      id: driver.id,
      name: driver.name,
      mappingName: driverClassCategoriesMapping[driver.id],
    }));
    return result;
  }

  static async getTractors(
    terminalIds: string[],
    tractorClassCategoriesMapping: Record<string, ClassMappingDropdownItemType>
  ) {
    const data =
      await TractorController.instance().getTractorLightListByNameSearch(
        '',
        1,
        terminalIds,
        ['INACTIVE'],
        5000
      );
    if (data instanceof ServiceError || !data) {
      return [];
    }
    const result: ClassMappingTableType[] = data.content.map((tractor) => ({
      id: tractor.id,
      name: tractor.name,
      mappingName: tractorClassCategoriesMapping[tractor.id],
    }));
    return result;
  }

  static generateTableData = (
    mapping: Record<string, ClassMappingDropdownItemType>,
    list: { name: string; id: string }[]
  ) => {
    return list.map((item) => ({
      id: item.id,
      name: item.name,
      mappingName: mapping[item.id],
    }));
  };
}

export default ClassMappingActions;
