import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import StorageManager from '../../../../../StorageManager/StorageManager';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../../common/Permission';
import { IconBtnWrapper } from '../../../../../common/Ui/IconBtnWrapper/IconBtnWrapper';
import { AddIcon } from '../../../../../ui-kit/components/Assets';

const addAlertPermissions: { [key: string]: AXELE_PERMISSION_TYPE[] } = {
  AD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_ADD],
  DI: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_ADD],
  NFD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_ADD],
  PD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_ADD],
};
export default function SafetyAlertsHeader({
  alertsCount,
  openNewAlertModal,
  inactive,
}: {
  alertsCount: number;
  openNewAlertModal: () => void;
  inactive?: boolean;
}) {
  const userStorageData = StorageManager.getUser();
  return (
    <>
      <Grid container>
        <Grid
          container
          alignItems={'center'}
          justifyContent={'space-between'}
          spacing={0}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant={'h7'} color={'primary.main'}>
              Alert
            </Typography>
            {!inactive && (
              <Permission
                includes={addAlertPermissions[userStorageData?.roleCode]}
              >
                <IconBtnWrapper sx={{ ml: 2 }} onClick={openNewAlertModal}>
                  <AddIcon />
                </IconBtnWrapper>
              </Permission>
            )}
          </Grid>
          <Grid item>
            <Typography variant={'h7'} color={'primary.main'}>
              {alertsCount}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-end'}>
        <Typography variant="body3" sx={{ color: 'text.secondary' }}>
          {t('totalAlerts')}
        </Typography>
      </Grid>
    </>
  );
}
