import Checkbox from '@/common/Ui/Checkbox';
import DatePicker from '@/common/Ui/FormDateTimePicker/FormDateTimePicker';
import RadioGroup from '@/common/Ui/RadioGroup';
import TextFieldForm from '@/common/Ui/TextField/TextFieldFormDeprecated';
import { FuelCardAccount } from '@/models/DTOs/FuelCards/FuelCards';
import ButtonImproved from '@/ui-kit/components/ButtonImproved';
import { CommonDataGrid } from '@/ui-kit/components/DataGridPro';
import { getGridCompanyTimezoneSettings } from '@/utils/Timezone.utils';
import { TransactionRecordsColumns } from '@/views/settings/integrations/FuelCard/constants';
import { filtersConfig } from '@/views/settings/integrations/FuelCard/constants/TransactionRecords.constants';
import { useTransactionRecords } from '@/views/settings/integrations/FuelCard/hooks';
import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment/moment';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  account: FuelCardAccount;
  handleImportKeyChange: (value: string) => void;
  rePullData: boolean;
  handleRePullDataChange: (data: boolean) => void;
}

export const TransactionRecords: FC<Props> = ({
  account,
  handleImportKeyChange,
  rePullData,
  handleRePullDataChange,
}) => {
  const { control } = useFormContext();
  const timezone = getGridCompanyTimezoneSettings().timezone || '';

  const {
    pullData,
    transactionRecords,
    handleCheckboxSelectionChange,
    isDataPulled,
    isGetRecordsDisabled,
    loading,
    filters,
    deductionType,
    handleFilterChangeCb,
  } = useTransactionRecords(
    account,
    handleImportKeyChange,
    rePullData,
    handleRePullDataChange
  );

  return (
    <Box padding={2}>
      <Stack justifyContent="space-between" flexDirection="row">
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              color: 'primary.main',
              wordBreak: 'break-all',
            }}
          >
            Transaction Records{' '}
          </Typography>
          {account.data.END_DATE && account.data.BEG_DATE && (
            <Typography variant="subtitle1" fontSize={12} color="primary.main">
              (Last Synced Period:{' '}
              {moment(account.data.BEG_DATE)
                .tz(timezone)
                .format('MMM DD, YYYY, hh:mm A')}{' '}
              -{' '}
              {moment(account.data.END_DATE)
                .tz(timezone)
                .format('MMM DD, YYYY, hh:mm A')}
              )
            </Typography>
          )}
        </Stack>
        <ButtonImproved
          disabled={isGetRecordsDisabled || loading}
          label="Get Records"
          variant="contained"
          onClick={pullData}
        />
      </Stack>
      <Stack
        pl={0.5}
        pr={0.5}
        display="flex"
        gap={3}
        flexDirection="row"
        marginTop={2}
      >
        <DatePicker
          control={control}
          label="Start Date + Time"
          name={'begDate'}
          required
        />
        <DatePicker
          control={control}
          label="End Date + Time"
          name={'endDate'}
          required
        />
        <Checkbox
          control={control}
          label="Hide Synced Record"
          name="hideSyncedReports"
          defaultChecked
        />
      </Stack>
      <Stack direction="column">
        <Box sx={{ width: '100%' }}>
          <CommonDataGrid
            dataGridContainerHeight={'50vh'}
            loading={loading}
            customFooter
            footerComponent={() => null}
            tableData={transactionRecords}
            tableColumnsData={TransactionRecordsColumns}
            onPageFilterChange={handleFilterChangeCb}
            sortingMode="server"
            displayFilters={false}
            settingsPanel={false}
            defaultFilterValue={filters}
            checkboxSelection={true}
            filterColumns={filtersConfig}
            isRowSelectable={(params) => {
              return !params.row.isSynced;
            }}
            selectRow={handleCheckboxSelectionChange}
            NoRowsOverlay={() => (
              <Box
                sx={{
                  textAlign: 'center',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Stack>
                  <b>No Data</b>
                </Stack>
                <Typography
                  sx={{ maxWidth: 381 }}
                  variant="caption"
                  fontFamily={'oxanium'}
                >
                  To search for EFS transactions, ensure your EFS integration is
                  active and all the search parameters are added
                </Typography>
              </Box>
            )}
          />
        </Box>
      </Stack>
      <Stack
        pl={0.5}
        pr={0.5}
        justifyContent="space-between"
        flexDirection="row"
        marginTop={4}
      >
        <Stack justifyContent="space-between">
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              color: 'primary.main',
              wordBreak: 'break-all',
            }}
          >
            Deduction options
          </Typography>
        </Stack>
        <Stack>
          <RadioGroup
            row
            control={control}
            name={`deductionType`}
            defaultValue="noDeduction"
            disabled={!isDataPulled}
            data={[
              {
                value: 'driversDeduction',
                label: "Add to Driver's Deduction",
              },
              {
                value: 'tractorsDeduction',
                label: "Add to Tractor's Deduction",
              },
              {
                value: 'noDeduction',
                label: 'No Deduction',
              },
            ]}
          />
          {['driversDeduction', 'tractorsDeduction'].includes(
            deductionType
          ) && (
              <Stack flexDirection="row" justifyContent="space-between">
                <Stack flex={0.8}>
                  <TextFieldForm
                    control={control}
                    name={`percentageToReimburse`}
                    label="Discount to Reimburse (%)"
                    onlyNumbers
                    required
                    defaultValue={100}
                  />
                </Stack>
                <Stack flex={1}>
                  <Checkbox
                    name="addDiscountInDescription"
                    label="Add Discount Amount to Settlement Description"
                    control={control}
                  />
                </Stack>
              </Stack>
            )}
        </Stack>
      </Stack>
    </Box>
  );
};
