import React, { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import {
  RadioGroup as MuiRadioGroup,
  Radio as MuiRadio,
  FormLabel,
  RadioGroupProps,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';

const RadioGroup: React.FC<{
  control?: any;
  name: string;
  data: any[];
  defaultValue?: string;
  label?: ReactNode;
  row?: RadioGroupProps['row'];
  onChangeCb?: (value: string) => void;
  disabled?: boolean;
  sizes?: {
    xs: number;
  };
}> = ({
  control,
  name,
  data,
  defaultValue,
  label,
  onChangeCb,
  row = false,
  disabled = false,
  sizes = { xs: 12 },
}) => {
  return (
    <Controller
      render={({ field }) => {
        const { onChange, value, ...rest } = field;

        return (
          <Grid spacing={3} container alignItems="center" xs={sizes.xs}>
            {label && (
              <Grid item>
                <FormLabel>{label}</FormLabel>
              </Grid>
            )}
            <Grid item>
              <MuiRadioGroup row={row} value={value} {...rest}>
                {data.map((d, i) => (
                  <FormControlLabel
                    disabled={disabled}
                    key={i}
                    style={d.style}
                    value={d.value}
                    control={
                      <MuiRadio
                        disabled={d.disabled}
                        id={d.value ? d.value : ''}
                      />
                    }
                    label={d.label}
                    onChange={(e) => {
                      onChange(e);
                      onChangeCb && onChangeCb(value);
                    }}
                  />
                ))}
              </MuiRadioGroup>
            </Grid>
          </Grid>
        );
      }}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default RadioGroup;
