import {
  DriversIcon,
  ExpensesIcon,
  LoadsIcon,
  LocationsTabStripIcon,
  TractorsIcon,
  TrailersIcon,
  VendorsTabStripIcon,
  WebUserIcon,
} from '../ui-kit/components/Assets';
import React, { ReactNode, useEffect, useState } from 'react';
import { GridColDefSelf } from '../types';
import ImportActions from '../views/settings/import/Import';
import { importDefaultDateType } from '../views/settings/import/components/Step1/constants';
import { fileTypeCodesMap } from '../views/settings/import/components/Landing/constants';

export type ImportableEntityType = {
  key: string;
  label: string;
  name: string;
  routeName: string;
  dictionary: string;
  displayName: string;
  icon: ReactNode;
  hasAdditionalStep: boolean;
  hideFromImportPage?: boolean;
};

export const IMPORT_CUSTOMER_NAME = 'Customer';
export const IMPORT_VENDOR_NAME = 'Vendor';
export const IMPORT_LOCATION_NAME = 'Locations';
export const IMPORT_DRIVER_NAME = 'Driver';
export const IMPORT_TRACTOR_NAME = 'Tractors';
export const IMPORT_TRAILER_NAME = 'Trailers';
export const IMPORT_LOAD_NAME = 'Loads';
export const IMPORT_EXPENSE_NAME = 'Expenses';
export const IMPORT_CONTRACT_RATE_LINE_ITEM_NAME = 'Contract Line Items';

export const loadAndExpenseAndLineItemsSampleFiles: {
  [key: string]: { [key: string]: string };
} = {
  [IMPORT_LOAD_NAME]: {
    FUTURE: 'Future_Load_LoadOps_Data_Template.xlsx',
    PAST: 'Past_Load_LoadOps_Data_Template.xlsx',
  },
  [IMPORT_CONTRACT_RATE_LINE_ITEM_NAME]: {
    CITY_BASED: 'Lane_Information_City_Based_Import_LoadOps_Template.xlsx',
    ZIP_BASED: 'Lane_Information_Zip_Based_Import_LoadOps_Template.xlsx',
    MILEAGE_BASED:
      'Mileage_Information_Mileage_Based_Import_LoadOps_Template.xlsx',
  },
  [IMPORT_EXPENSE_NAME]: {
    APEX: 'Apex_TCS_Sample.xlsx',
    EFS: 'EFS_Sample.xlsx',
    PILOT_FLYING_J: 'Pilot_Flying_J_Sample.xlsx',
    MSFC: 'MSFC_Sample.xlsx',
    BESTPASS: 'Bestpass_Toll_Card_Sample.xlsx',
    EZPASS: 'E-ZPass_Toll_Card_Sample.xlsx',
    PREPASS: 'PrePass_Toll_Card_Sample.xlsx',
    IPASS: 'I-PASS_Toll_Card_Sample.xlsx',
    AXELE_FUEL: 'LoadOps_Fuel_Purchase_Sample.xlsx',
    AXELE_OTHER: 'LoadOps_Other_Purchase_Sample.xlsx',
    COMDATA: 'Comdata_Sample.xlsx',
  },
};

export const sampleFilesMap: { [key: string]: string } = {
  [IMPORT_CUSTOMER_NAME]: 'Customer_Import_LoadOps_Template.xlsx',
  [IMPORT_DRIVER_NAME]: 'Driver_Import_LoadOps_Template.xlsx',
  [IMPORT_LOCATION_NAME]: 'Location_Import_LoadOps_Template.xlsx',
  [IMPORT_TRACTOR_NAME]: 'Tractor_Import_LoadOps_Template.xlsx',
  [IMPORT_TRAILER_NAME]: 'Trailer_Import_LoadOps_Template.xlsx',
  [IMPORT_VENDOR_NAME]: 'Vendor_Import_LoadOps_Template.xlsx',
};

export const importableEntities = [
  {
    key: 'customer',
    label: 'Customer',
    name: IMPORT_CUSTOMER_NAME,
    routeName: 'customer',
    dictionary: 'customer',
    displayName: 'Customers',
    icon: <WebUserIcon />,
    hasAdditionalStep: false,
  },
  {
    key: 'vendor',
    label: 'Vendor',
    name: IMPORT_VENDOR_NAME,
    routeName: 'vendor',
    dictionary: 'expense',
    displayName: 'Vendors',
    icon: <VendorsTabStripIcon />,
    hasAdditionalStep: false,
  },
  {
    key: 'location',
    label: 'Location',
    name: IMPORT_LOCATION_NAME,
    routeName: 'location',
    dictionary: 'location',
    displayName: 'Locations',
    icon: <LocationsTabStripIcon />,
    hasAdditionalStep: false,
  },
  {
    key: 'driver',
    label: 'Driver',
    name: IMPORT_DRIVER_NAME,
    routeName: 'driver',
    dictionary: 'people',
    displayName: 'Drivers',
    icon: <DriversIcon />,
    hasAdditionalStep: false,
  },
  {
    key: 'tractor',
    label: 'Tractor',
    name: IMPORT_TRACTOR_NAME,
    routeName: 'tractor',
    dictionary: 'asset',
    displayName: 'Tractors',
    icon: <TractorsIcon />,
    hasAdditionalStep: false,
  },
  {
    key: 'trailer',
    label: 'Trailer',
    name: IMPORT_TRAILER_NAME,
    routeName: 'trailer',
    dictionary: 'asset',
    displayName: 'Trailers',
    icon: <TrailersIcon />,
    hasAdditionalStep: false,
  },
  {
    key: 'load',
    label: 'Load',
    name: IMPORT_LOAD_NAME,
    routeName: 'load',
    dictionary: 'interload',
    displayName: 'Loads',
    icon: <LoadsIcon />,
    hasAdditionalStep: true,
  },
  {
    key: 'expense',
    label: 'Expense',
    name: IMPORT_EXPENSE_NAME,
    routeName: 'expense',
    dictionary: 'expense',
    displayName: 'Expenses',
    icon: <ExpensesIcon />,
    hasAdditionalStep: false,
  },
  {
    key: 'contractRateLineItem',
    label: 'Contract Rate Line Item',
    name: IMPORT_CONTRACT_RATE_LINE_ITEM_NAME,
    routeName: 'contract',
    dictionary: 'customer',
    displayName: 'Line Items',
    icon: <ExpensesIcon />,
    hasAdditionalStep: false,
    hideFromImportPage: true,
  },
];

export const defaultFileTypes: { [key: string]: string } = {
  load: 'future',
  expense: 'Fuel Expense',
};

/* @TODO davits change all any types */
export type ImportContextType = {
  currentEntity: ImportableEntityType;
  setCurrentEntity: React.Dispatch<React.SetStateAction<ImportableEntityType>>;
  showImportModal: boolean;
  setShowImportModal: React.Dispatch<React.SetStateAction<boolean>>;
  file: any;
  setFile: React.Dispatch<React.SetStateAction<any>>;
  uploadingFile: boolean;
  setUploadingFile: React.Dispatch<React.SetStateAction<boolean>>;
  handleImportModalClose: () => void;
  handleImportModalOpen: () => void;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  tableColumns: GridColDefSelf[];
  setTableColumns: React.Dispatch<React.SetStateAction<GridColDefSelf[]>>;
  tableData: any;
  setTableData: React.Dispatch<React.SetStateAction<any>>;
  warnings: { key: string; message: string }[];
  setWarnings: React.Dispatch<
    React.SetStateAction<{ key: string; message: string }[]>
  >;
  sessionId: string;
  setSessionId: React.Dispatch<React.SetStateAction<string>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  kpiInfo: { [key: string]: number };
  setKpiInfo: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>;
  selectedFileType: string;
  setSelectedFileType: React.Dispatch<React.SetStateAction<string>>;
  handleBack: () => void;
  handleNext: () => void;
  handleReset: () => void;
  handleSkip: (numberOfSteps?: number) => void;
  finishImportCb?: (data: unknown) => void;
  handleFinishImport: () => void;
  handleDownloadSampleFile: (name: string, hasDownloadOption: boolean) => void;
};

export const ImportContext = React.createContext<ImportContextType>(
  {} as ImportContextType
);

export const ImportContextProvider = ({
  importableEntityName,
  finishImportCb,
  children,
  defaultUploadType,
}: {
  importableEntityName?: string;
  finishImportCb?: (data: unknown) => void;
  children: JSX.Element;
  defaultUploadType?: string;
}) => {
  // if import modal opened from entity page then should pass importableEntityName, otherwise it will be Customer
  const currentSelectedEntity: ImportableEntityType = importableEntityName
    ? importableEntities.find((ent) => ent.name === importableEntityName)!
    : importableEntities[0];
  // detects which entity is imported, customer, load ...etc.
  const [currentEntity, setCurrentEntity] = useState<ImportableEntityType>(
    currentSelectedEntity
  );
  // skipped step
  const [skipped, setSkipped] = React.useState(new Set<number>());
  // detects either modal is open or not
  const [showImportModal, setShowImportModal] = useState(false);
  // this comes from backend. Current session id
  const [sessionId, setSessionId] = useState('');
  // selected file
  const [file, setFile] = React.useState<any>();
  // detects uploading state in first step. (After dropping or choosing file)
  const [uploadingFile, setUploadingFile] = useState(false);
  // file related frontend errors. (Max size, file type support)
  const [errors, setErrors] = useState(null);
  // after importing file shows file info in First step
  const [tableData, setTableData] = useState<any>(null);
  // after importing file shows file columns info in First step
  const [tableColumns, setTableColumns] = useState<GridColDefSelf[]>([]);
  // detects either file has warnings or not. (Comes from backend)
  const [warnings, setWarnings] = useState<{ key: string; message: string }[]>(
    []
  );
  // Shows imported/warnings/errors/ignored columns info
  const [kpiInfo, setKpiInfo] = useState<{ [key: string]: number }>({});
  // Selected file type. Only for expense and load
  const [selectedFileType, setSelectedFileType] = useState(
    defaultUploadType || ''
  );

  /*Stepper state*/
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    setSelectedFileType(defaultUploadType);
  }, [defaultUploadType]);

  const handleImportModalClose = () => {
    setShowImportModal(false);
    setTableData(null);
    setUploadingFile(false);
    setFile(null);
    setErrors(null);
    setWarnings([]);
    setActiveStep(0);
    setSessionId('');
    setSelectedFileType(defaultFileTypes[currentEntity.key] || '');
    ImportActions.deductionType = '';
    ImportActions.selectedDateFormat = importDefaultDateType;
  };

  const handleImportModalOpen = () => setShowImportModal(true);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleSkip = (numbersOfSteps = 1) => {
    setActiveStep((prevActiveStep) => prevActiveStep + numbersOfSteps + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleFinishImport = async () => {
    handleImportModalClose();
    await ImportActions.finishImport(
      sessionId,
      currentEntity.routeName,
      currentEntity.dictionary
    );
    finishImportCb?.(true);
  };

  const handleDownloadSampleFile = (
    name: string,
    hasDownloadOption: boolean
  ) => {
    if (hasDownloadOption) {
      if (
        name === IMPORT_LOAD_NAME ||
        name === IMPORT_EXPENSE_NAME ||
        name === IMPORT_CONTRACT_RATE_LINE_ITEM_NAME
      ) {
        window.location.href = `/public/${
          loadAndExpenseAndLineItemsSampleFiles[name][
            fileTypeCodesMap[selectedFileType]
          ]
        }`;
      } else {
        window.location.href = `/public/${sampleFilesMap[name]}`;
      }
    }
  };

  return (
    <ImportContext.Provider
      value={{
        currentEntity,
        setCurrentEntity,
        showImportModal,
        setShowImportModal,
        file,
        setFile,
        uploadingFile,
        setUploadingFile,
        handleImportModalClose,
        handleImportModalOpen,
        errors,
        setErrors,
        tableData,
        setTableData,
        tableColumns,
        setTableColumns,
        warnings,
        setWarnings,
        sessionId,
        setSessionId,
        activeStep,
        setActiveStep,
        kpiInfo,
        setKpiInfo,
        selectedFileType,
        setSelectedFileType,
        handleBack,
        handleNext,
        handleReset,
        handleSkip,
        finishImportCb,
        handleFinishImport,
        handleDownloadSampleFile,
      }}
    >
      {children}
    </ImportContext.Provider>
  );
};

export const useImport = () => React.useContext(ImportContext);
