import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  GetFactoringStaticRequest,
  GetInvoiceTermsRequest,
  GetStatesRequest,
} from '../../models';
import { IStaticService, ServiceError, annotateNameAsync } from '../interfaces';

export class StaticService extends IStaticService {
  @annotateNameAsync
  async getStates(requestData: GetStatesRequest) {
    try {
      const response = await httpClient.getRaw(
        '/web/location/api/v2/data/countryandstate/static',
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getInvoiceTerms(requestData: GetInvoiceTermsRequest) {
    try {
      const response = await httpClient.getRaw(
        '/web/preference/api/v2/preference/company/static',
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getFactoringStatic(
    requestData: GetFactoringStaticRequest
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/factoring/api/v2/integrations/organization/summary`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
