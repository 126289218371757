import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { paymentManagementService } from '../../../../../../api';
import { ServiceError } from '../../../../../../api/interfaces';
import CurrencyFieldControl from '../../../../../../common/Ui/CurrencyField/CurrencyFieldControl';
import FormDatePicker from '../../../../../../common/Ui/FormDatePicker/FormDatePicker';
import { SingleAutocompleteForm } from '../../../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../../../common/Ui/TextField/TextField';
import { GetPaymentOpenCreditRequest } from '../../../../../../models/DTOs/Payment/DTOs';
import { useRootStore } from '../../../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../../../store/root.context';
import { CarrierNumberFormatProps } from '../../../../../../subPages/carriers/constants/constants';
import { getCustomerList } from '../../../../../../subPages/loadsList/LoadDetailsPanel/utils/customer';
import { currencyDisplay } from '../../../../../../utils/grid.utils';
import { paymentMethods } from '../../../constants';
import { PaymentDetailsFormProps } from '../../PaymentDetailsPanel/PaymentDetailsForm';
import { getCreatePaymentPanelStyles } from '../styles';
export const GeneralDetailsPaymentForm = ({
  isCreatePanel = false,
  isCustomerDisabled = false,
  isDisabled = false,
}: Pick<
  PaymentDetailsFormProps,
  'isCreatePanel' | 'isCustomerDisabled' | 'isDisabled'
>) => {
  const { control, watch } = useFormContext();
  const styles = getCreatePaymentPanelStyles({ theme: useTheme() });
  const watchCustomerId = watch('customerId');
  const defaultTextFieldProps = {
    style: styles.defaultTextFieldProps,
  };
  const [openCredits, setOpenCredits] = useState<number>(0);
  const sizes = isCreatePanel ? { xs: 4 } : { xs: 6 };
  const {
    invoiceStore: { paymentPanel },
  } = useStores();
  const { getCompanyPreferences } = useRootStore();

  useEffect(() => {
    if (watchCustomerId) {
      getOpenCredits();
    } else {
      setOpenCredits(0);
    }
  }, [watchCustomerId]);

  const getOpenCredits = async () => {
    const data = await paymentManagementService.getPaymentOpenCredit(
      new GetPaymentOpenCreditRequest()
    );
    if (!(data instanceof ServiceError)) {
      setOpenCredits(data[watchCustomerId?.id]);
    } else {
      setOpenCredits(0);
    }
  };
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
        width={'100%'}
      >
        <Typography
          variant="h7"
          color={'primary.main'}
          sx={{ width: '100%', marginBottom: '24px' }}
        >
          General Details
        </Typography>
      </Stack>
      <Grid sx={{ mt: 3 }}>
        <Grid spacing={2} container mt={-2}>
          <SingleAutocompleteForm
            wrapperStyles={{
              ...styles.colStyle,
            }}
            fieldName="name"
            id={'customerId'}
            control={control}
            name={'customerId'}
            label="Customer"
            freeSolo
            getOptions={(name, pageNumber) => {
              return getCustomerList(name, pageNumber);
            }}
            disabled={isCustomerDisabled || isDisabled || paymentPanel?.open}
            sizes={sizes}
            required
          />
          <FormDatePicker
            size="small"
            control={control}
            styleProps={{ width: '100%' }}
            name={`paymentDate`}
            label="Payment Date"
            wrapperStyles={{ paddingTop: '0', marginBottom: 0 }}
            sizes={sizes}
            disableFuture
            disabled={isDisabled}
          />
          <SingleAutocompleteForm
            fieldName="label"
            id={'paymentMethod'}
            control={control}
            name={'paymentMethod'}
            label="Payment Method"
            freeSolo
            options={paymentMethods}
            sizes={sizes}
            disabled={isDisabled}
            wrapperStyles={
              isCreatePanel ? { ...styles.colStyle } : { paddingTop: '16px' }
            }
          />

          <TextField
            control={control}
            label={'Payment Reference Number'}
            name={'paymentReferenceId'}
            sizes={sizes}
            disabled={isDisabled}
            {...defaultTextFieldProps}
            style={{
              marginTop: isCreatePanel ? '0' : '-2px !important',
            }}
          />
          <CurrencyFieldControl
            control={control}
            label={'Amount Received'}
            name={'amountReceived'}
            sizes={sizes}
            required
            disabled={isDisabled}
            numberFormatProps={CarrierNumberFormatProps}
            {...defaultTextFieldProps}
          />
          <Grid
            xs={sizes.xs}
            item
            sx={{
              paddingTop: '18px !important',
            }}
          >
            <Box>
              <Typography sx={styles.cardLabel}>Open Credits</Typography>
              <Typography sx={styles.cardValue}>
                {currencyDisplay(openCredits ? openCredits : 0, '0', 2)}
              </Typography>
            </Box>
          </Grid>
          <TextField
            control={control}
            label={'Memo'}
            name={'memo'}
            sizes={{ xs: 12 }}
            disabled={isDisabled}
            {...defaultTextFieldProps}
            style={{
              paddingTop: '13px !important',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
