import { Skeleton, Stack, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import StorageManager from '../../../../StorageManager/StorageManager';
import NoData from '../../../../common/LoadTabPanel/component/NoData';
import {
  getVisibilityProviderIdsData,
  sendDocumentTurvoData,
} from '../../../../common/LoadTabPanel/tabs/Tracking/constants/helper';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { IconBtnWrapper } from '../../../../common/Ui/IconBtnWrapper/IconBtnWrapper';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import {
  CreateDocument,
  DocumentItemType,
  NotesListRes,
} from '../../../../models';
import { TerminalShort } from '../../../../models/common/modelsShort';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { CARRIER_DOCUMENT_TYPE } from '../../../../subPages/carriers/constants/constants';
import { LineItem } from '../../../../types';
import { AddIcon } from '../../../../ui-kit/components/Assets';
import AxeleDialog from '../../../../ui-kit/components/AxeleDialog';
import { CommonDataGrid } from '../../../../ui-kit/components/DataGridPro';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import { isHasPermission } from '../../../../utils';
import { downloadFile } from '../../../../utils/Doc';
import {
  onColumnOrderChange,
  prepareColumnOrdering,
} from '../../../../utils/gridColumnOrdering';
import DocumentActions from '../DocumentActions';
import UploadFileDialog from '../UploadFileDialog';
import DocumentDetailPanel from '../components/DocumentDetailsPanel';
import EmailDocument from '../components/EmailDocument';
import {
  entityKeyValueMap,
  latestDocumentCertificationFormSchema,
  panelColumns,
} from '../constants';
import UploadForm from '../forms/UploadForm';
import { getDocumentTypeById, getUpdatedTableData } from '../utils';

interface IDocumentType {
  documentId: string;
  documentType: string;
}

export interface ISendDocumentTurvoType {
  documents: Array<IDocumentType>;
  tenderId: string;
  loadId: string;
}

export default observer(function EntityDocumentsSection({
  data: { entity, entityObject, terminal = null, id: entityId },
  createDocumentCallback,
  inactive,
  hideShareWithDriver,
}: PropTypes) {
  const { getGlobalTerminalsIds } = useRootStore();
  const { hasLoadEditPermission } = useLoadPermission();
  const [documentLineItemsMap, setDocumentLineItemsMap] = useState<{
    [key: string]: LineItem;
  }>({});
  const [tableData, setTableData] = useState<DocumentItemType[] | null>(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [allColumns, setAllColumns] = useState([]);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [totalDocumentsCount, setTotalDocumentsCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState<DocumentItemType | null>(
    null
  );
  const [selectedLoad, setSelectedLoad] = useState<NotesListRes | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<
    DocumentItemType[] | null
  >(null);
  const [isConformation, setConformation] = useState<boolean>(false);
  const pageNumber = useRef(1);
  const isLast = useRef(true);
  const userStorageData = StorageManager.getUser();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDocumentLineItems = async () => {
      const lineItems = await DocumentActions.getDocumentLineItems();
      if (lineItems) {
        const obj: { [key: string]: LineItem } = {};
        lineItems.forEach((lineItem) => {
          obj[lineItem.id] = lineItem;
        });
        setDocumentLineItemsMap(obj);
        DocumentActions.documentsLineItemsMap = obj;
      }
    };
    const getLoadItems = async () => {
      const providerIdsData = await getVisibilityProviderIdsData(
        entityObject?.id
      );
      if (providerIdsData) {
        setSelectedLoad(providerIdsData);
        setLoading(false);
      }
    };
    getDocumentLineItems();
    if (entity !== CARRIER_DOCUMENT_TYPE) {
      getLoadItems();
    }
  }, []);

  const getDocuments = async (scrolling = false, pageNumber = 1) => {
    if (!scrolling) {
      setLoading(true);
    }
    const params = {
      objectTypesAndIds: `${entity}:${entityObject.id}`,
      terminalIds: getGlobalTerminalsIds,
    };
    const result = await DocumentActions.getDocuments({
      ...params,
      pageNumber,
    });
    if (result) {
      setTotalDocumentsCount(result.totalElements!);
      isLast.current = result.last;
      if (scrolling) {
        setTableData((oldData) => [...oldData!, ...result.content]);
      } else {
        setTableData(result.content);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getDocuments();

    const orderedColumns = prepareColumnOrdering(
      panelColumns,
      'Documents',
      'tablesection'
    );
    setAllColumns(orderedColumns);
  }, [entityId]);

  const handleDelete = async () => {
    const result = await DocumentActions.deleteDocuments(selectedDocumentIds);
    if (!result) {
      return;
    }
    const updatedDocuments = tableData.filter(
      (doc) => !selectedDocumentIds.includes(doc.documentId)
    );
    setSelectedItem(null);
    setTableData(updatedDocuments);
    setSelectedDocumentIds([]);
    setTotalDocumentsCount((oldCount) => oldCount - selectedDocumentIds.length);
  };

  const handleSelectRow = (newModel: string[]) => {
    const selectedItems = tableData.filter((doc) => newModel.includes(doc.id));
    setSelectedDocument(selectedItems);
    setSelectedDocumentIds(selectedItems.map((item) => item.documentId));
  };

  const handleSelectItem = (document: DocumentItemType) => {
    setSelectedItem(document);
  };

  const updateTable = (id: string, value: string | object, name: string) => {
    if (tableData) {
      const updatedTableData = getUpdatedTableData(tableData, id, name, value);
      setTableData(updatedTableData);
    }
  };

  const removeRowFromTable = (rowId: string) => {
    const updatedTable = tableData.filter((row) => row.id !== rowId);
    setTableData(updatedTable);
  };

  const handleDownload = async () => {
    const result = await DocumentActions.downloadDocuments(selectedDocumentIds);
    if (!result) {
      return;
    }
    result.forEach((doc) => downloadFile(doc));
  };

  const handleScroll = async () => {
    if (!isLast.current) {
      pageNumber.current++;
      await getDocuments(true, pageNumber.current);
    }
  };

  const hasDocumentEditPermission = isHasPermission([
    AXELE_PERMISSION_TYPE.DOCUMENT_EDIT,
  ]);
  const sendDocumentTurvo = async () => {
    if (!selectedDocument) return;
    const documentList: { documentId: string; documentType: string }[] = [];
    selectedDocument.map((item) => {
      documentList.push({
        documentId: item.documentId,
        documentType: item.documentType,
      });
    });
    const payLoad: ISendDocumentTurvoType = {
      documents: documentList,
      tenderId: selectedLoad?.ediDetails?.tenderId,
      loadId: selectedLoad?.id,
    };
    const result = await sendDocumentTurvoData(payLoad);
    if (result) {
      setTableData(tableData);
      setConformation(false);
    } else {
      setTableData(tableData);
      setConformation(false);
    }
  };

  if (loading) {
    return (
      <Stack direction={'column'} mt={'1rem'} spacing={3}>
        {[...new Array(18)].map((index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={'100%'}
            height={'1rem'}
          />
        ))}
      </Stack>
    );
  }

  if (!tableData) {
    return <NoData />;
  }

  return (
    <Grid container padding={2}>
      <Grid container>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={0}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant={'h7'} color={'primary.main'}>
              Documents
            </Typography>
            {!inactive && (
              <Permission contains={[AXELE_PERMISSION_TYPE.DOCUMENT_ADD]}>
                <IconBtnWrapper
                  sx={{ ml: 2 }}
                  onClick={() => setUploadDialogOpen(true)}
                >
                  <AddIcon />
                </IconBtnWrapper>
              </Permission>
            )}
          </Grid>
          <Grid item>
            <Typography variant={'h7'} color={'primary.main'}>
              {totalDocumentsCount}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-end'}>
        <Typography variant="body3" sx={{ color: 'text.secondary' }}>
          Total Documents
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          width: '100%',
          '> div': {
            width: '100%',
          },
        }}
      >
        <CommonDataGrid
          dataGridContainerHeight="65vh"
          handleSelectItem={handleSelectItem}
          selectRow={handleSelectRow}
          tableData={tableData}
          tableColumnsData={allColumns}
          filterColumns={[]}
          settingsPanel={false}
          displayFilters={false}
          customFooter
          cardBackgroundColor={''}
          getSelectedRowsCount={''}
          handleScroll={handleScroll}
          checkboxSelection={hasDocumentEditPermission && !inactive}
          onColumnOrderChange={(params, event, details) => {
            onColumnOrderChange(
              { params, event, details },
              'Documents',
              'entities',
              true
            );
          }}
          bulkAction={[
            {
              iconName: 'DeleteOutlined',
              iconSource: 'MUIcons',
              label: 'Delete',
              onClick: () => {
                if (selectedDocumentIds.length) {
                  setShowDeleteDialog(true);
                }
              },
            },
            {
              iconName: 'ShareOutlined',
              iconSource: 'MUIcons',
              label: 'Share',
              onClick: () => setEmailDialogOpen(true),
            },
            {
              iconName: 'DownloadOutlined',
              iconSource: 'MUIcons',
              label: 'Download',
              onClick: () => {
                handleDownload();
              },
            },
            ...(selectedLoad?.source === 'Turvo'
              ? [
                  {
                    iconName: 'ShareOutlined',
                    iconSource: 'MUIcons',
                    label: 'Send to Turvo',
                    onClick: () => {
                      setConformation(true);
                    },
                  },
                ]
              : []),
          ]}
        />
      </Grid>
      {!!selectedItem && (
        <DocumentDetailPanel
          isGlobal
          inactive={inactive}
          panelTitle={selectedItem.fileName}
          documentLineItemsMap={documentLineItemsMap}
          data={selectedItem}
          setSelectedItem={setSelectedItem}
          onClose={() => setSelectedItem(null)}
          onDelete={handleDelete}
          onDownload={async () =>
            await DocumentActions.bulkDownload([selectedItem.documentId])
          }
          updateTable={updateTable}
          editDocumentCallbacks={{
            editDocumentTypeCb: () => {
              return;
            },
            editAssociatedTypeCb: () => {
              removeRowFromTable(selectedItem.id);
            },
            editAssociatedItemCb: () => {
              removeRowFromTable(selectedItem.id);
            },
            editTerminalCb: () => {
              removeRowFromTable(selectedItem.id);
            },
          }}
          onShare={() => setEmailDialogOpen(true)}
          isCreatePanelOpen
        />
      )}

      {uploadDialogOpen && (
        <UploadFileDialog
          open={uploadDialogOpen}
          onClose={() => setUploadDialogOpen(false)}
          createDocumentCb={(files: CreateDocument[], docType) => {
            const newTableData = files.map((f) => ({
              uploadDate: f.uploadDate,
              id: f.id,
              fileName: f.fileName,
              documentType: getDocumentTypeById(f.documentTypeId),
              documentId: f.documentId,
              documentTypeId: f.documentTypeId,
              attachedEntities: f.attachedEntities,
              ownerName: f.ownerName,
              notes: f.notes,
              terminalId: f.terminalId,
              permission: f.permission,
            }));
            setTableData((oldData) => [...newTableData, ...oldData]);
            createDocumentCallback?.(files, docType);
            setTotalDocumentsCount((oldCount) => oldCount + files.length);
          }}
          renderContent={(files) => (
            <UploadForm
              documentTypes={Object.values(documentLineItemsMap)}
              filesLength={files.length}
              fromEntity
              entityId={entityId}
              entity={entity}
              hideShareWithDriver={hideShareWithDriver}
            />
          )}
          onFormSubmit={DocumentActions.createDocument}
          formDefaultValues={{
            fileName: '',
            documentType: null,
            terminal: terminal || null,
            entityTypes: { key: entity, value: entityKeyValueMap[entity] },
            entities: { id: entityObject.id, name: entityObject.name },
            notes: '',
            permission: false,
            isLatest: false,
          }}
          validationSchema={latestDocumentCertificationFormSchema}
        />
      )}
      {showDeleteDialog && (
        <DeletePopup
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          onAction={async () => {
            setShowDeleteDialog(false);
            if (selectedDocumentIds.length) {
              await handleDelete();
            }
          }}
          title={`Delete Document?`}
          subtitle={`Are you sure you want to delete this Document?`}
        />
      )}
      {emailDialogOpen && (
        <AxeleDialog
          open={emailDialogOpen}
          handleClose={() => setEmailDialogOpen(false)}
          renderComponent={
            <EmailDocument
              handleEmailDialogClose={() => setEmailDialogOpen(false)}
              ids={selectedDocumentIds}
            />
          }
          showTitle={false}
          dialogPaperStyles={{
            backgroundColor: 'rgba(3, 14, 24, 0.89)',
            p: 0,
            '.MuiDialogContent-root': {
              m: 0,
              backgroundColor: 'rgba(3, 14, 24, 0.89)',
              height: 'unset',
            },
          }}
          dialogStyles={{ width: 1028, margin: '0 auto' }}
        />
      )}

      {isConformation && (
        <DeletePopup
          open={isConformation}
          onClose={() => setConformation(false)}
          onAction={() => sendDocumentTurvo()}
          title={``}
          subtitle={`Are you sure you want to send documents to Turvo?`}
          buttonText={'Okay'}
          backgroundColor={theme.palette.primary.main}
          width={'350px'}
        />
      )}
    </Grid>
  );
});

export type PropTypes = {
  data: {
    entity: string;
    terminal: TerminalShort | null;
    entityObject: {
      id: string;
      name: string;
    };
    id: string;
  };
  createDocumentCallback?: (files: CreateDocument[], docType: LineItem) => void;
  inactive?: boolean;
  hideShareWithDriver?: boolean;
};
