import { Box, Divider, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStores } from '../../../../../store/root.context';
import { currencyDisplay } from '../../../../../utils/grid.utils';
import { hasPaymentEdit } from '../../utils/payment.permission';
import { PaymentDetailsFormProps } from '../PaymentDetailsPanel/PaymentDetailsForm';
import { PaymentDocumentUpload } from '../PaymentDetailsPanel/PaymentDocumentUpload';
import { GeneralDetailsPaymentForm } from './GeneralDetails/GeneralDetailsPaymentForm';
import { InvoicePaymentForm } from './InvoicePaymentGrid/InvoicePaymentForm';
import { getCreatePaymentPanelStyles } from './styles';

export const CreatePaymentForm = observer(
  ({
    isCreatePanel,
    paymentDetailsDTO,
  }: Pick<PaymentDetailsFormProps, 'isCreatePanel' | 'paymentDetailsDTO'>) => {
    const styles = getCreatePaymentPanelStyles({ theme: useTheme() });
    const { watch, control } = useFormContext();
    const watchAmountReceived = watch('amountReceived');

    const {
      invoicePaymentStore: { amountToApply, tableData, getOpenCredits },
    } = useStores();
    const amountToCredit = useMemo(() => {
      if (watchAmountReceived || amountToApply) {
        return watchAmountReceived - amountToApply;
      }
      return 0;
    }, [watchAmountReceived, amountToApply]);

    return (
      <Box sx={{ ...styles.root }} className="CreatePaymentForm-root">
        <Box sx={styles.container}>
          <Box sx={{ mt: 1, mb: 1 }}>
            <GeneralDetailsPaymentForm
              isCreatePanel={isCreatePanel}
              isCustomerDisabled={tableData?.length ? true : false}
              isDisabled={!hasPaymentEdit()}
            />
          </Box>
          <Box sx={{ mt: 1, mb: 1 }}>
            <Divider />
          </Box>
          <Box sx={{ mt: 2 }}>
            <InvoicePaymentForm isCreatePanel={isCreatePanel} />
          </Box>
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Typography variant="body2" sx={{ display: 'inline-block' }}>
              {' '}
              Amount to Apply{' '}
            </Typography>
            <Typography
              variant="body2"
              sx={{ minWidth: 150, display: 'inline-block' }}
            >
              {' '}
              {currencyDisplay(amountToApply)}{' '}
            </Typography>
            <br />
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
              }}
            >
              Amount to Credit
            </Typography>
            <Typography
              variant="body2"
              sx={{ minWidth: 148, display: 'inline-block' }}
            >
              {' '}
              {currencyDisplay(amountToCredit)}
            </Typography>
            {amountToCredit < 0 && (
              <>
                <br />
                <Typography
                  variant="body2"
                  sx={{ color: 'red', marginRight: 3.5 }}
                >
                  Open balance cannot be negative
                </Typography>
              </>
            )}
          </Box>
          {hasPaymentEdit() && (
            <>
              <Box sx={{ mt: 1, mb: 1 }}>
                <Divider />
              </Box>
              <Box sx={{ mt: 1, mb: 1, width: '100%' }}>
                <PaymentDocumentUpload />
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  }
);
