import { Stack } from '@mui/material';
import { FC } from 'react';
import { ELoadStatus } from '../../../../../common/LoadTabPanel/constants/constants';
import {
  EDITenderDTO,
  EEDITenderStatus,
  UpdateShipmentStatusDTO,
} from '../../../../../models/DTOs/EDI/Requests';
import { useEDITendersStore } from '../../../../../store/EDITenders/EDITenders';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { EEDITendersGridAction } from '../../../constants/types';

interface ActionProps {
  data: EDITenderDTO;
}

const Actions: FC<ActionProps> = ({ data }: { data: EDITenderDTO }) => {
  const { setUpdateShipmentStatusData } = useEDITendersStore();
  return (
    <Stack direction="row" spacing={1} width="100%" justifyContent="center">
      {[EEDITenderStatus.New, EEDITenderStatus.Declined].includes(
        data.action
      ) && (
        <ButtonImproved
          variant="contained"
          label="Accept"
          onClick={async (e) => {
            e.stopPropagation();
            const requestBody: UpdateShipmentStatusDTO = {
              loadAction: EEDITenderStatus.Accepted,
              rejectReason: '',
              shipmentId: data.shipmentId,
              tenderId: data.id,
            };
            setUpdateShipmentStatusData({
              action: EEDITendersGridAction.Accepted,
              data: requestBody,
            });
          }}
        />
      )}
      {(data.action === EEDITenderStatus.New ||
        (data.action === EEDITenderStatus.Accepted &&
          (!data.loadStatus ||
            ![
              ELoadStatus.LOAD_COMPLETED,
              ELoadStatus.INVOICED,
              ELoadStatus.PAID,
            ].includes(data.loadStatus)))) && (
        <ButtonImproved
          variant="outlined"
          label="Decline"
          onClick={(e) => {
            e.stopPropagation();
            const requestBody: UpdateShipmentStatusDTO = {
              loadAction: EEDITenderStatus.Declined,
              rejectReason: '',
              shipmentId: data.shipmentId,
              tenderId: data.id,
            };
            setUpdateShipmentStatusData({
              action: EEDITendersGridAction.Rejected,
              data: requestBody,
            });
          }}
        />
      )}
      {EEDITenderStatus.Declined === data.action && (
        <ButtonImproved
          variant="contained"
          label="Remove"
          styleProps={{
            background: '#F44336',
            '&:hover': {
              background: '#F44336',
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            const requestBody: UpdateShipmentStatusDTO = {
              loadAction: data.action,
              rejectReason: '',
              shipmentId: data.shipmentId,
              tenderId: data.id,
            };
            setUpdateShipmentStatusData({
              action: EEDITendersGridAction.Deleted,
              data: requestBody,
            });
          }}
        />
      )}
    </Stack>
  );
};

export default Actions;
