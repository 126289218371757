import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  paymentLineItemAmountText,
  paymentTermsLineItemText,
  paymentLineItemAmoutConfigText,
} from '../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewConstant';
import RadioGroup from '../../../common/Ui/RadioGroup';
import Select from '../../../common/Ui/Select';
import TextField from '../../../common/Ui/TextField/TextField';
import { EquipmentType } from '../../../views/maintenanceRecords/constants/constants';
import FormContext from './TractorPaymentTermFormContext';
import { t } from 'i18next';
const AddNewTractorPaymentTermForm = ({}) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const { availablePayTypes, expenseCategories, userType } =
    useContext(FormContext);
  const [selectedUnitText, SetselectedUnit] = useState<string>('Rate');
  const [selectedPayItem, SetselectedPayItem] = useState<any>({});

  const [selectedPaymentUnitList, SetSelectedPaymentUnitList] = useState<any[]>(
    []
  );
  const [selectedPaymentRecordList, SetSelectedPaymentRecordList] = useState<
    any[]
  >([]);
  const selectedUnitType = watch('unit');
  const selectedPaymentTypeId = watch('payTypeId');

  useEffect(() => {
    SetSelectedPaymentRecordList(availablePayTypes);
    let foundPaymentType = findRecord(availablePayTypes, 'PER_LOADED_MILE');
    foundPaymentType = foundPaymentType
      ? foundPaymentType
      : availablePayTypes[0];
    SetselectedPayItem(foundPaymentType);
    setValue('description', foundPaymentType?.description);
    setValue('payTypeId', foundPaymentType?.id);
    setValue('payType', foundPaymentType?.itemCode);
    setValue('unit', foundPaymentType?.unit);
    if (userType === EquipmentType.Tractor) {
      setValue(
        'expenseCategoryId',
        findRecord(expenseCategories, 'DRIVER_PAYMENT').id
      );
    } else {
      setValue(
        'expenseCategoryId',
        findRecord(expenseCategories, 'DISPATCHER_PAYMENT').id
      );
    }
  }, [expenseCategories, availablePayTypes]);
  const findRecord = (list: any[], key: string) => {
    const result = list.find(
      (item: { itemCode: string }) => item?.itemCode === key
    );
    return result;
  };

  useEffect(() => {
    if (!selectedPaymentTypeId) {
      return;
    }

    const foundPaymentType = availablePayTypes.find(
      (payType) => payType.id === selectedPaymentTypeId
    );
    SetselectedPayItem(foundPaymentType);
    setValue('description', foundPaymentType?.description);
    setValue('payType', foundPaymentType?.itemCode);
    setValue('unit', foundPaymentType?.unit);
    SetselectedUnit(
      'Rate ' + paymentLineItemAmountText[foundPaymentType?.unit[0]]
    );
    setValue('unit', foundPaymentType?.unit[0]);
    SetSelectedPaymentUnitList(foundPaymentType?.unit);
  }, [selectedPaymentTypeId]);

  useEffect(() => {
    SetselectedUnit('Rate ' + paymentLineItemAmountText[selectedUnitType]);
  }, [selectedUnitType]);

  const getRateLabel = () => {
    if (selectedUnitType === 'PERCENT') return selectedUnitText;
    if (
      selectedPaymentUnitList?.length > 1 ||
      (selectedUnitType?.[0] &&
        selectedPayItem.itemCode === 'USERDEFINED_PAYMENT')
    ) {
      return paymentLineItemAmountText[selectedUnitType];
    }
    return paymentLineItemAmoutConfigText[selectedUnitType];
  };

  return (
    <Grid container>
      <Grid container item spacing={2} xs={12}>
        <Select
          name={`expenseCategoryId`}
          label={t('expenseCategory')}
          fieldName={'itemName'}
          sizes={{ xs: 12 }}
          options={expenseCategories}
          control={control}
          fieldValue={'id'}
          error={errors.expenseCategory}
          required
        />
      </Grid>
      {selectedPaymentRecordList.length > 0 && (
        <Select
          name={`payTypeId`}
          label={'Pay Type'}
          fieldName={'itemName'}
          sizes={{ xs: 12 }}
          options={selectedPaymentRecordList}
          control={control}
          fieldValue={'id'}
          error={errors.paymentType}
          required
        />
      )}
      {selectedPaymentUnitList.length > 1 && (
        <Grid container>
          <Grid item sx={{ pt: '9px', pb: '10px', pr: '20px' }}>
            <Typography component={'span'}>Unit </Typography>
          </Grid>
          <Grid item sx={{ pb: '10px', pl: '15px' }}>
            <RadioGroup
              row
              name="unit"
              data={selectedPaymentUnitList.map((item) => {
                return {
                  value: item,
                  label:
                    paymentTermsLineItemText[
                      item as keyof typeof paymentTermsLineItemText
                    ],
                };
              })}
            />
          </Grid>
        </Grid>
      )}
      <TextField
        defaultValue="0"
        control={control}
        name={`rate`}
        label={getRateLabel()}
        required
      />
      <TextField
        control={control}
        name={`description`}
        label="Description"
        sizes={{ xs: 12 }}
      />
    </Grid>
  );
};

AddNewTractorPaymentTermForm.defaultProps = {
  titleText: '',
};

export default AddNewTractorPaymentTermForm;
