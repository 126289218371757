import React, { useEffect, useMemo, useState } from 'react';
import {
  EQUIPMENT_PANEL_TABS,
  equipmentGeneralTab,
  equipmentPanelTab,
  equipmentPanelTabs,
} from '../../../constants/contacts';
import { getAccountTypeWithRoleCode, isHasPermission } from '../../../utils';
import { DirtyDetailsPanelManager } from '../../../common/DetailsPanel/utils';
import TabsV2 from '../../../ui-kit/components/TabsV2';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';
const getTabItems = (tractorId: number): equipmentPanelTab[] => {
  if (tractorId) {
    const newTabs = [...equipmentPanelTabs];
    newTabs.unshift(equipmentGeneralTab);
    const noPermissionForViewMainetenance = !isHasPermission([
      AXELE_PERMISSION_TYPE.MAINTENANCE_HISTORY_VIEW,
    ]);
    const filtredTabs = [
      ...(noPermissionForViewMainetenance
        ? newTabs?.filter((item) => item.label !== 'Maintenance')
        : newTabs),
    ];
    return filtredTabs;
  }
  return [equipmentGeneralTab];
};

const DetailsTabStrips: React.FC<{
  defaultTab?: EQUIPMENT_PANEL_TABS;
  onChange: (selected: EQUIPMENT_PANEL_TABS) => void;
  tractorId: number;
  keys: EQUIPMENT_PANEL_TABS[];
}> = ({ onChange, tractorId, defaultTab, keys }) => {
  const tabItems = useMemo(() => {
    return getTabItems(tractorId).filter((item: equipmentPanelTab) =>
      keys.includes(item.key)
    );
  }, [keys, tractorId]);

  const getTab = (tabKey?: EQUIPMENT_PANEL_TABS) =>
    tabItems.find(({ key }) => key === tabKey) || tabItems[0];

  const [selected, setSelected] = useState(getTab(defaultTab));

  useEffect(() => {
    setSelected(getTab(defaultTab));
  }, [defaultTab]);

  const handleStripChange = (selected: equipmentPanelTab) => {
    setSelected(selected);
    onChange(selected.key);
  };

  return (
    <TabsV2<'key', equipmentPanelTab>
      keyName="key"
      tabs={tabItems}
      setSelectedTab={(selected: equipmentPanelTab) => {
        if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
        handleStripChange(selected);
      }}
      selectedTab={selected.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

const getTabByKey = (
  key: EQUIPMENT_PANEL_TABS
): equipmentPanelTab | undefined => {
  return [equipmentGeneralTab, ...equipmentPanelTabs].find(
    (tab) => tab.key === key
  );
};

export const tabStripRendererByKeyFS = (key: EQUIPMENT_PANEL_TABS) => {
  return (
    <TabsV2<'key', equipmentPanelTab>
      keyName="key"
      tabs={[getTabByKey(key)].filter(Boolean) as equipmentPanelTab[]}
      selectedTab={getTabByKey(key)?.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

export default DetailsTabStrips;
