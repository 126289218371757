import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved/ButtonImproved';
import {
  activityTextContainerStyle,
  activityTextStyle,
  linkStyle,
} from '../styles';
export const BetaLogText = () => {
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Box textAlign={'center'}>
        <Box sx={{ ...activityTextContainerStyle }}>
          <sup>
            <Typography sx={{ ...activityTextStyle }}>
              {t('loadsLoadActivity')}
            </Typography>
          </sup>
        </Box>
        <Typography fontSize={'16px'}>{t('loadsActivityMsg')}</Typography>
        <ButtonImproved
          variant={'outlined'}
          size="small"
          href="https://help.loadops.com/load-audit-logs"
          target="blank"
          sx={{ ...linkStyle }}
          label={'Learn more'}
        ></ButtonImproved>
      </Box>
    );
  }
};
