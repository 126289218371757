import { templateActionURL } from '../../../../api/impl/requestConstants';
import { ServiceError } from '../../../../api/interfaces';
import { httpClient } from '../../../../axios/axiosInstance';
import { QueryParams, TemplateDetailsResponse } from '../../../../models';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import { CreateLoadRequestDTO } from '../../CreateLoad/dto/create-load-request.dto';
import { ILoadDetails } from '../../CreateLoad/types/types';

interface ICreateTemplate extends ILoadDetails {
  templateName?: string;
  assignedDocument?: File;
}

export class CreateTemplateRequest extends QueryParams {
  templateName?: string;
  assignedDocument?: File;

  constructor(data: { templateName?: string; assignedDocument?: File }) {
    super();
    this.templateName = data.templateName;
    this.assignedDocument = data.assignedDocument;
  }

  async createTemplate(load: ILoadDetails) {
    const createLoadPayload = new CreateLoadRequestDTO(load);
    const payload = { ...createLoadPayload?.data?.[0], ...this };
    return await httpClient.post<TemplateDetailsResponse>(
      `${templateActionURL}create`,
      new QueryParams(true),
      payload,
      TemplateDetailsResponse,
      false
    );
  }
}

export class UpdateTemplateRequest extends QueryParams {
  id?: string;
  templateName?: string;
  constructor({
    templateId,
    templateName,
  }: {
    templateId: string;
    templateName: string;
  }) {
    super();
    this.id = templateId;
    this.templateName = templateName;
  }

  async updateTemplate(templateData: ILoadDetails) {
    try {
      const createLoadPayload = new CreateLoadRequestDTO(templateData);
      const payloadData = { ...createLoadPayload?.data?.[0], ...this };
      const res = await httpClient.put<TemplateDetailsResponse>(
        `${templateActionURL}update`,
        undefined,
        payloadData,
        TemplateDetailsResponse,
        false
      );
      if (res instanceof ServiceError)
        throw new Error('Failed to update template.');
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'templateEdit',
      });
      return res;
    } catch (error: any) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        ...(error?.response?.data?.message
          ? { message: error?.response?.data?.message }
          : { serviceName: 'templateEdit' }),
      });
      return null;
    }
  }
}
