import { useDispatchPage } from '../../../contexts/DispatchPageContext';
import useFilterStorage from '../../../services/storage';
import { IDispatchFilters } from '../constants/types';

//centralize dispatch storage
export const useDispatchStorage = () => {
  const { component, entity } = useDispatchPage();
  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};

  const updateStorage = (newFilters: IDispatchFilters): void => {
    LStorageHook.updateStorage(entity, {
      ...storageData[entity],
      ...newFilters,
    });
  };

  const getCurrentStorage = (): IDispatchFilters => {
    return storageData[entity];
  };

  return {
    updateStorage: updateStorage,
    getCurrentStorage: getCurrentStorage,
  };
};
