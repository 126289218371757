import { DistanceInfo } from '../../models/DTOs/GeoBus/Requests';
import { DistanceResponse } from '../../models/DTOs/GeoBus/GeoBus';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IGeoBusService extends Service {
  abstract getDistance(
    requestData: DistanceInfo[]
  ): Promise<DistanceResponse[] | null | ServiceError>;
}
