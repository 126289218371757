import { Button, Grid, TextareaAutosize } from '@mui/material';
import TextInput from '../../../../../ui-kit/components/TextField';
import SingleAutocomplete from '../../../../../ui-kit/components/SingleAutocomplete';
import { useState } from 'react';
import { financeIntegrationService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import {
  DriverSummaryResponseModel,
  GetDriverSummaryFactoreRequest,
} from '../../../../../models/DTOs/ELD/Requests';
import { gridPageSize } from '../../../../../utils';
import { RowActualData } from '../ManageFactoreIntegration';
import { FactorePaginatedRoleList } from './Refresh';

export const FactoringApexModel = ({
  successCallBack,
  handleClose,
  selectData,
}: factoringApexModelType) => {
  const factoringID = sessionStorage.getItem('FactoringCustomerID');
  const [invoiceCode, setInvoiceCode] = useState<number>(selectData?.code);
  const [invoiceNote, setInvoiceNote] = useState<string>(
    selectData?.driverInvoiceNotes || ''
  );
  const [mappingObject, setMappingObject] =
    useState<DriverSummaryResponseModel>({});

  const getOptions = async (name: string, pageNumber: number) => {
    const request = new GetDriverSummaryFactoreRequest();
    request.pageNumber = pageNumber;
    request.pageSize = gridPageSize;
    request.credentialId = factoringID || undefined;
    request.nameFilter = name;
    const response = await financeIntegrationService.drivermappingsOptionList(
      request
    );
    if (!(response instanceof ServiceError)) {
      if (response?.content.length) {
        const customField = response?.content.filter((data) => {
          data.optionName = `${data.mappingCode} - ${data.mappingDescription} `;
          return data;
        });
        response.content = customField;
      }
      return response;
    }
  };
  const saveDriverMapping = async () => {
    const payLoad = {
      code: invoiceCode,
      driverInvoiceNotes: invoiceNote,
      factoringId: selectData.factoringId,
      firstname: selectData.firstname,
      lastname: selectData.lastname,
      mappingCode: mappingObject?.mappingCode || selectData.mappingCode,
      mappingDescription:
        mappingObject.mappingDescription || selectData.mappingDescription,
      mappingId: mappingObject.mappingId || selectData.mappingId,
      mappingName: mappingObject.mappingName || selectData.mappingName,
      organizationId: selectData.organizationId,
      driverId: selectData.driverId,
      id: selectData.id,
    };
    const response =
      await financeIntegrationService.driverFactoringMapingRecord(payLoad);
    if (!(response instanceof ServiceError)) {
      successCallBack(response);
    }
  };
  return (
    <Grid
      container
      xs={12}
      spacing={4}
      sx={{ alignItems: 'center', p: '20px 30px' }}
    >
      <Grid item xs={12}>
        <TextInput
          label={'LoadOps Driver'}
          variant="outlined"
          defaultValue={selectData?.axeleDriver || ''}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label={'Driver Invoice Code'}
          variant="outlined"
          defaultValue={selectData?.code || ''}
          onChange={(event: Event) => {
            setInvoiceCode(event?.target?.value || '');
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SingleAutocomplete
          name={'optionName'}
          fieldName={'optionName'}
          label={'Funding Account'}
          getOptions={getOptions}
          variant="outlined"
          defaultValue={
            selectData?.mappingCode &&
            `${selectData?.mappingCode || ''} - ${
              selectData?.mappingDescription || ''
            } `
          }
          onChangeCb={(data) => {
            setMappingObject(data);
          }}
          disableClear={true}
        />
      </Grid>

      <Grid item xs={12}>
        <TextareaAutosize
          maxRows={4}
          minRows={3}
          aria-label="maximum height"
          placeholder={'Driver Invoice Note'}
          style={{
            width: '100%',
            maxWidth: '100%',
            backgroundColor: 'transparent',
            border: '1px solid #c5c8ca',
            padding: 8,
          }}
          defaultValue={selectData?.driverInvoiceNotes || ''}
          onChange={(event: Event) => {
            setInvoiceNote(event?.target?.value || '');
          }}
        />
      </Grid>
      <Grid xs={12} sx={{ textAlign: 'right', mt: 4, mb: 2 }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={saveDriverMapping}
          sx={{
            '& .MuiInput-input': {
              color: 'black',
            },
            '& input[type="search"]::-webkit-search-cancel-button': {
              '-webkit-appearance': 'none',
            },
          }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export interface factoringApexModelType {
  successCallBack: (data: FactorePaginatedRoleList) => void;
  handleClose: () => void;
  selectData: RowActualData;
}
