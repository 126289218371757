import { Theme } from '@mui/material';

export const getCreateLoadPanelStyles = (theme: Theme) => ({
  dialog: {
    '.MuiPaper-root': {},
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
  },
  container: {
    display: 'flex',
    backgroundColor: '#fff',
    overflowY: 'hidden',
  },
  form: {
    width: '840px',
    overflowY: 'auto',
    padding: '0 15px',
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
    '.MuiAccordion-root': {
      margin: 0,
    },
    '.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    p: '16px',
    backgroundColor: '#35446B',
    color: theme.palette.primary.contrastText,
    overflowY: 'auto',
  },
  pdfViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    p: '16px',
    pt: 0,
    overflowY: 'scroll',
    width: '100%',
    maxWidth: '640px',
  },
});
