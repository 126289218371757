import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { AuthSuccessIcon } from '../../ui-kit/components/Assets';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import TextInput from '../../ui-kit/components/TextField';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { subscriptionService } from '../../api';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { ForgotPasswordRequest } from '../../models';
import { useRootStore } from '../../store/root-store/rootStateContext';
import { emailValidation } from '../settings/terminals/constants/constants';
import { EPartner, forgotPasswordConfig, formDefaultValues } from './constant';
import {
  StyledFormTitle,
  StyledLoginAuthFromWrapper,
  SxPropAuthButton,
  getRegistrationStyles,
} from './styles';
import { ThemeProvider } from '@emotion/react';
import { Button, CircularProgress, Stack } from '@mui/material';
import { regitrationPageStyle } from './RegistrationPageUtils';
import { AuthIntroduction } from './components/AuthIntroduction';

const ForgotPasswordForm = (props: any) => {
  const { formValues, setFormValues } = props;
  const [forgotPassWordFormValues, setForgotPassWordFormValues] = useState({
    ...formDefaultValues.forgotPassword,
  });
  const navigate = useNavigate();
  const { themeLogin } = useThemeResponsive();
  const [form, setForm] = useState({ ...forgotPassWordFormValues });
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);
  const config = { ...forgotPasswordConfig };
  const { setLoadingUrl, setLoadingFinishedUrl } = useRootStore();
  const conditionalUrl = 'forgotpassword';
  const [isLoading, setLoading] = useState<boolean>(false);

  const callApi = async () => {
    if (validate()) {
      setLoading(true);
      const query = new ForgotPasswordRequest();
      query.dot = form.dot;
      query.email = form.email;
      // setLoadingUrl(conditionalUrl);
      const response = await subscriptionService.forgotPassword(query);
      // setLoadingFinishedUrl(conditionalUrl);
      setForgotPassWordFormValues(form);
      if (response) {
        setSuccess(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const validate = () => {
    const toValid = config.form;
    const err = {};
    toValid.forEach((ele) => {
      ele.config.forEach((element) => {
        if (element.required && !form[element.name]) err[element.name] = true;
        else if (
          element.name === 'email' &&
          !emailValidation.test(form[element.name])
        )
          err[element.name] = element.label + ' is not valid.';
      });
    });
    setError(err);
    return isEmpty(err);
  };
  const [searchParams] = useSearchParams();

  const isExistPartnerUser: boolean = Object.values(EPartner).includes(
    searchParams.get('partner')
  );

  const styles = getRegistrationStyles({
    theme: themeLogin,
    isExistPartnerUser: isExistPartnerUser,
  });
  return (
    <ThemeProvider theme={themeLogin}>
      <>
        <Box className="Auth-root" sx={styles.authRoot}>
          <Box className="Auth-backgroundImage" sx={styles.authBackgroundImage}>
            <Stack className="Auth-content" spacing={0} sx={styles.authContent}>
              <AuthIntroduction />

              <Box className="formWrapper" sx={styles.formWrapper}>
                <Box
                  sx={{
                    width: '100%',
                    height: 'max-content',
                    overflowX: 'auto',
                  }}
                >
                  <Box
                    className="formWrapperContent"
                    sx={styles.formWrapperContent}
                  >
                    <StyledLoginAuthFromWrapper>
                      <Box sx={regitrationPageStyle.verificationStyle}>
                        <Box
                          sx={{
                            height: '100%',
                          }}
                        >
                          <Box>
                            {success ? (
                              <Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: '20%',
                                    mb: '10%',
                                  }}
                                >
                                  <AuthSuccessIcon />
                                </Box>
                                <Box
                                  sx={{
                                    fontFamily: 'Oxanium',
                                    fontSize: '42px',
                                    fontWeight: '600',
                                    lineHeight: '59px',
                                    textAlign: 'center',
                                    color: 'primary.main',
                                    mb: '15%',
                                  }}
                                >
                                  Email Sent!
                                </Box>
                                <Box
                                  sx={(theme) => ({
                                    ...theme.typography.body1,
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '22px',
                                    textAlign: 'center',
                                    color: 'text.secondary',
                                  })}
                                >
                                  We’ve sent you an email at {form.email} with
                                  instructions on how to reset your password!
                                </Box>
                              </Box>
                            ) : (
                              <Box>
                                <Box
                                  sx={{
                                    textAlign: 'center',
                                    marginBottom: '15px',
                                    position: 'relative',
                                  }}
                                >
                                  <StyledFormTitle>
                                    {config.title}
                                  </StyledFormTitle>
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                  {config.form.map(
                                    (block: any, index: number) => {
                                      return (
                                        <Box
                                          key={index}
                                          sx={{
                                            marginBottom: '20px',
                                            width: '100%',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flexWrap: 'wrap',
                                              width: '100%',
                                              justifyContent: 'space-between',
                                            }}
                                          >
                                            {block.config.map(
                                              (
                                                field: {
                                                  name: string;
                                                  label: string;
                                                  inputType?: string;
                                                  width?: string;
                                                  options?: any;
                                                  type: string;
                                                  required: boolean;
                                                },
                                                i: number
                                              ) => {
                                                return (
                                                  <Box
                                                    key={i}
                                                    sx={{
                                                      width:
                                                        field.width || '100%',
                                                      marginBottom: '15px',
                                                    }}
                                                  >
                                                    {field.type ===
                                                    'checkbox' ? (
                                                      <Box
                                                        sx={() => ({
                                                          ...themeLogin
                                                            .typography.body1,
                                                          color: 'text.primary',
                                                        })}
                                                      >
                                                        <Checkbox
                                                          checked={
                                                            form[field.name]
                                                          }
                                                          onChange={(e) =>
                                                            setForm({
                                                              ...form,
                                                              [field.name]:
                                                                e.target
                                                                  .checked,
                                                            })
                                                          }
                                                        />
                                                        {field.label}
                                                      </Box>
                                                    ) : (
                                                      <TextInput
                                                        type={
                                                          field.inputType ||
                                                          undefined
                                                        }
                                                        label={field.label}
                                                        error={Boolean(
                                                          error[field.name]
                                                        )}
                                                        helperText={
                                                          error[field.name]
                                                        }
                                                        onChange={(e: any) => {
                                                          const newForm = {
                                                            ...form,
                                                            [field.name]:
                                                              e.target.value,
                                                          };
                                                          setForm(newForm);
                                                          setError({
                                                            ...error,
                                                            [field.name]: '',
                                                          });
                                                        }}
                                                        styleProps={{
                                                          ...themeLogin
                                                            .typography
                                                            .inputText,
                                                          borderRadius: '14px',
                                                          color:
                                                            'text.secondary',
                                                          width: '100%',
                                                        }}
                                                        value={form[field.name]}
                                                        variant="outlined"
                                                        required={
                                                          field.required
                                                        }
                                                      />
                                                    )}
                                                  </Box>
                                                );
                                              }
                                            )}
                                          </Box>
                                        </Box>
                                      );
                                    }
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Box>

                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ButtonImproved
                              onClick={() =>
                                navigate({
                                  pathname: '/login',
                                  search: window?.location?.search,
                                })
                              }
                              label="Back to Log In"
                              size="large"
                              styleProps={{
                                ...SxPropAuthButton,
                                color: success
                                  ? 'primary.contrast'
                                  : 'primary.main',
                                ...themeLogin.typography.h6,
                                marginRight: '20px',
                              }}
                              variant={success ? 'contained' : 'outlined'}
                            />
                            {!success && (
                              <Button
                                variant="contained"
                                disabled={isLoading}
                                onClick={callApi}
                                sx={{
                                  color: 'primary.contrast',
                                  ...themeLogin.typography.h6,
                                  ...(isLoading && {
                                    background: '#3a4755 !important',
                                    color: '#757e88 !important',
                                  }),
                                }}
                                size="large"
                              >
                                {isLoading && (
                                  <CircularProgress
                                    size={22}
                                    sx={{ ml: '-16px', mr: '22px' }}
                                  />
                                )}{' '}
                                {isLoading ? 'Reseting' : 'Reset'}
                              </Button>
                              // {/* <ButtonImproved
                              //   onClick={callApi}
                              //   label="Reset"
                              //   size="large"
                              //   styleProps={{
                              //     ...SxPropAuthButton,
                              //     ...themeLogin.typography.h6,
                              //   }}
                              //   variant="contained"
                              // /> */}
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </StyledLoginAuthFromWrapper>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </>
    </ThemeProvider>
  );
};

export default ForgotPasswordForm;
