import { Box, Link, Typography } from '@mui/material';
import { AuthRibbonBannerSvg } from '../../../ui-kit/components/Assets';
import { useThemeResponsive } from '../../../common/hooks/useThemeResponsive';
import { message } from '../../../components/Subscription/constant';

export const AuthRibbonBanner = () => {
  const { isMobile } = useThemeResponsive();
  return (
    <Box width={'100%'} sx={{ display: isMobile ? 'contents' : '' }}>
      {isMobile ? (
        <>
          {message.registers.map((msg) => (
            <Typography
              key={msg}
              variant="body2"
              sx={{
                color: '#78EAEE',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {msg}
            </Typography>
          ))}
          <Link
            sx={{ color: '#78EAEE' }}
            href="https://help.loadops.com/what-is-loadops-pricing-model"
            underline="always"
            rel="noopener noreferrer"
            target="_blank"
          >
            {' '}
            Learn more
          </Link>
        </>
      ) : (
        <Box>
          <Box ml={-8} height={0}>
            <AuthRibbonBannerSvg />
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: 'primary.contrast',
              height: '62px',
              display: 'flex',
              alignItems: 'center',
              p: 2,
              ml: -8,
            }}
          >
            {message.register}{' '}
            <Link
              sx={{ color: 'primary.contrast', ml: '5px' }}
              href="https://help.loadops.com/what-is-loadops-pricing-model"
              underline="always"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
