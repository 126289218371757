import { ExportExcelQueryExcel } from '../../models';
import {
  GetExpenseCategorySummaryResponse,
  GetExpenseDetailsGlobalTotalResponse,
  GetExpenseDetailsResponse,
  GetIncomeCategorySummaryResponse,
  GetIncomeDetailsResponse,
  GetInvoiceCategoriesResponse,
  IFASExpenseDetailsCarrierPayment,
  IFASExpenseDetailsDispatcherPayment,
  IFASExpenseDetailsDriverPayment,
  IFASExpenseDetailsExpensePayment,
  IFASExpenseDetailsTractorPayment,
} from '../../models/DTOs/FinanceAccountSummary/FinanceAccountSummary';
import {
  GetExpenseCategorySummaryRequest,
  GetExpenseDetailRequest,
  GetIncomeCategorySummaryRequest,
  GetSummaryGridExportRequest,
  GetIncomeDetailsRequest,
  GetInvoiceCategoriesRequest,
} from '../../models/DTOs/FinanceAccountSummary/FinanceAccountSummaryRequests';

import { Service } from './Service';

export abstract class IFinanceAccountSummaryService extends Service {
  abstract getIncomeCategorySummary(
    queryParams: GetIncomeCategorySummaryRequest
  ): Promise<GetIncomeCategorySummaryResponse | undefined>;

  abstract getIncomeDetails(
    queryParams: GetIncomeDetailsRequest
  ): Promise<GetIncomeDetailsResponse | undefined>;

  abstract getExpenseCategorySummary(
    queryParams: GetExpenseCategorySummaryRequest
  ): Promise<GetExpenseCategorySummaryResponse | undefined>;

  abstract getExpenseDetailsDriverPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsDriverPayment> | undefined
  >;

  abstract exportSummaryGridData(
    queryParams: GetSummaryGridExportRequest,
    url: string
  ): Promise<ExportExcelQueryExcel | undefined>;

  abstract getExpenseDetailsTractorPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsTractorPayment> | undefined
  >;

  abstract getExpenseDetailsDispatcherPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsDispatcherPayment> | undefined
  >;

  abstract getExpenseDetailsExpensesPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsExpensePayment> | undefined
  >;
  abstract getCarrierDetailsExpensesPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsCarrierPayment> | undefined
  >;

  abstract getExpenseDetailsGlobalTotal(
    queryParams: GetExpenseDetailRequest
  ): Promise<GetExpenseDetailsGlobalTotalResponse | undefined>;

  abstract getInvoiceCategories(
    queryParams: GetInvoiceCategoriesRequest
  ): Promise<GetInvoiceCategoriesResponse | undefined>;
}
