import { IRelativeDateRangeOption } from '../../../../ui-kit/components/RelativeDateRange';
import { RelativeDateRangeUtils } from '../../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import {
  carrierDetailsExpensePaymentFilterDefault,
  expenseDetailsExpensePaymentFilterDefault,
  expenseDetailsFilterDefault,
  IExpenseDetailsFilter,
} from './expenseDetails';
import { incomeDetailsFilters } from './incomeDetailsGrid';
import {
  ACCOUNT_SUMMARY_FILTERS,
  EFinanceAccountSummaryCategory,
  EFinanceAccountSummaryExpense,
} from './types';

export interface IAccountSummaryFilter {
  query: {
    sort?: string;
    terminalIds?: string[];
  };
  filters: {
    [ACCOUNT_SUMMARY_FILTERS.period]?: IRelativeDateRangeOption;
    [ACCOUNT_SUMMARY_FILTERS.categories]: string[];
  };
}

export interface IFASFilters {
  [EFinanceAccountSummaryCategory.INCOME]: IAccountSummaryFilter;
  [EFinanceAccountSummaryCategory.EXPENSE]: IAccountSummaryFilter;
  incomeDetails: IExpenseDetailsFilter;
  [EFinanceAccountSummaryExpense.DRIVER_PAYMENT]: IExpenseDetailsFilter;
  [EFinanceAccountSummaryExpense.TRACTOR_PAYMENT]: IExpenseDetailsFilter;
  [EFinanceAccountSummaryExpense.DISPATCHER_PAYMENT]: IExpenseDetailsFilter;
  [EFinanceAccountSummaryExpense.EXPENSE_PAYMENT]: IExpenseDetailsFilter;
  [EFinanceAccountSummaryExpense.CARRIER_PAYMENT]: IExpenseDetailsFilter;
}

export const accountSummaryFilterDefault: IAccountSummaryFilter = {
  filters: {
    [ACCOUNT_SUMMARY_FILTERS.period]:
      RelativeDateRangeUtils.getSettingsAccoutSummary().defaultOption || null,
    [ACCOUNT_SUMMARY_FILTERS.categories]: [],
  },
  query: {
    sort: '-subTotal',
  },
};

export const accountSummaryFilters: IFASFilters = {
  [EFinanceAccountSummaryCategory.EXPENSE]: accountSummaryFilterDefault,
  [EFinanceAccountSummaryCategory.INCOME]: accountSummaryFilterDefault,
  incomeDetails: incomeDetailsFilters,
  [EFinanceAccountSummaryExpense.DRIVER_PAYMENT]: expenseDetailsFilterDefault,
  [EFinanceAccountSummaryExpense.TRACTOR_PAYMENT]: expenseDetailsFilterDefault,
  [EFinanceAccountSummaryExpense.DISPATCHER_PAYMENT]:
    expenseDetailsFilterDefault,
  [EFinanceAccountSummaryExpense.EXPENSE_PAYMENT]:
    expenseDetailsExpensePaymentFilterDefault,
  [EFinanceAccountSummaryExpense.CARRIER_PAYMENT]:
    carrierDetailsExpensePaymentFilterDefault,
};

export const debounceTime = 300;
