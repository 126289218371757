import { PageviewOutlined, PictureAsPdfOutlined } from '@mui/icons-material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ELoadStatus } from '../../../../common/LoadTabPanel/constants/constants';
import LoadActionsController from '../../../../common/LoadTabPanel/utils/LoadActionsController';
import { mappingAction } from '../../../../common/LoadTabPanel/utils/helper';
import { invoiceModule } from '../../../../common/PendoClassIDs/constants';
import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import { UpdateStatusPayload } from '../../../../models';
import { isHasPermission } from '../../../../utils';
import { previewFile } from '../../../../utils/Doc';
import { EEDITenderSource } from '../../../../views/EDITenders/constants/types';
import {
  ActionDialogFormData,
  LoadDetailDialog,
} from '../../../../views/myLoads/components/LoadDetailDialog';
import { E3DotMenuType } from '../../../../views/myLoads/components/LoadDetailDialog/types';
import { IEdiDetails } from '../../../loadsList/models/LoadSummary';
import InvoiceActions from '../../InvoiceActions';
import {
  CANCELLED_LOAD_STATUS_NAME,
  COMPLETED_LOAD_STATUS_NAME,
  INVOICED_LOAD_STATUS_NAME,
  PAID_LOAD_STATUS_NAME,
  READY_TO_INVOICE,
} from '../../constants';
import { InvoiceModel } from '../../models/InvoiceModel';
import Actions from '../BulkActions/Actions';
export const previewInvoiceDownload = async (invoice: InvoiceModel) => {
  const result = await InvoiceActions.previewInvoiceDownload(invoice);
  if (result) {
    previewFile(result);
  }
};

export function BasicActions({
  invoice,
  isStatusUpdated,
  editDetails,
  onHold,
}: {
  invoice: InvoiceModel;
  isStatusUpdated?: () => void;
  editDetails: IEdiDetails;
  onHold?: boolean;
}) {
  const { hasInvoiceAddPermission } = useLoadPermission();
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [current3DotMenu, setCurrent3DotMenu] = useState<{
    type: E3DotMenuType;
    data?: any;
  } | null>(null);

  /*-------------------------------------------------------------STATUS CHANGE ACTIONS SECTION START-------------------------------------------------------*/
  const [selectDateDialogOpen, setSelectDateDialogOpen] = useState(false);
  const [currentAction] = useState('');
  const [paidToInvoicedModal, setPaidToInvoicedModal] = useState(false);
  const [invoicedToCompletedModal, setInvoicedToCompletedModal] =
    useState(false);

  const actionButtonStyle = {
    padding: '0px',
    height: '28px',
    minWidth: '38px',
    m: '0px 5px',
    cursor: 'pointer',
  };
  const noPermissionForEditInvoice = !isHasPermission([
    AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICE_EDIT,
  ]);
  const {
    formState: { isDirty },
    watch,
  } = useFormContext();

  const invoiceCanceledStatus =
    invoice.status === CANCELLED_LOAD_STATUS_NAME &&
    !invoice.lineItems.length &&
    invoice.nonInvoiceable;

  const lineItems = watch('lineItems');

  const hasLineItems =
    lineItems?.length > 0 || invoice?.childInvoices.length > 0;

  const sendInvoiceToProvider = async (
    data: InvoiceModel,
    tenderId: string
  ) => {
    const response = await InvoiceActions.sendInvoiceToProviderViaEdi(
      data,
      tenderId
    );
    if (response) {
      setCurrent3DotMenu({ type: E3DotMenuType.MARK_AS_INVOICE });
    }
  };
  const invoiceToTurvo = async (loadId: string) => {
    const response = await InvoiceActions.sendInvoiceToTurvo(loadId);
  };
  const invoiceToBitFreighter = async (loadId: string) => {
    const response = await InvoiceActions.sendInvoiceToBitFreighter(loadId);
  };
  const onActionLoadDetailDialog = async (
    data: ActionDialogFormData
  ): Promise<void> => {
    if (current3DotMenu && mappingAction[current3DotMenu?.type]) {
      const requestBody: UpdateStatusPayload = {
        completionDate: data.completionDate?.toISOString(),
        invoicedDate: data.invoicedDate?.toISOString(),
        paidDate: data.paidDate?.toISOString(),
        invoiceNotes: undefined,
        noteDetails: undefined,
      };
      await LoadActionsController.instance().handleActionOnLoad({
        loadId: invoice.loadId,
        status: ELoadStatus.INVOICED,
        updateData: requestBody,
      });
      isStatusUpdated?.();
      setCurrent3DotMenu(null);
    }
  };

  const MarkAsInvoicedDialog = () => {
    const currentDate = moment();
    return (
      <LoadDetailDialog
        entity={'Load'}
        data={{
          seqNumber: invoice.seqNumber,
          completionDate: null,
          invoiceNotes: undefined,
          invoicedDate: currentDate,
          paidDate: null,
        }}
        type={E3DotMenuType.MARK_AS_INVOICE}
        onClose={() => {
          setCurrent3DotMenu(null);
        }}
        onAction={(data) => {
          onActionLoadDetailDialog(data);
        }}
      />
    );
  };
  const InvoiceSActionContainertyle = {
    padding: '5px 0px',
    background: '#EEF3FB',
    height: '40px',
    borderRadius: '8px',
    display: 'flex',
  };

  return (
    <>
      <Grid container sx={{ pt: '15px' }}>
        <Grid item sm={12} sx={InvoiceSActionContainertyle}>
          <Grid
            sx={{
              display: 'flex',
              padding: ' 4px 10px',
              color: 'text.primary',
            }}
          >
            <PictureAsPdfOutlined
              htmlColor="rgba(0, 17, 34, 0.6)"
              sx={{ width: '20px' }}
            />
            <Typography variant="subtitle2" sx={{ pl: '8px' }}>
              Invoice Actions
            </Typography>
          </Grid>
          {invoiceCanceledStatus}
          {!invoiceCanceledStatus && (
            <Grid display="flex" justifyContent="flex-end" sm={8}>
              {invoice?.source == EEDITenderSource.turvo ? (
                <Button
                  id={invoiceModule + 'turvo'}
                  variant="outlined"
                  sx={{ textTransform: 'none', height: '28px' }}
                  onClick={() => invoiceToTurvo(invoice?.loadId)}
                >
                  Send to Turvo
                </Button>
              ) : invoice?.source == EEDITenderSource.bitFreighter &&
                [
                  COMPLETED_LOAD_STATUS_NAME,
                  PAID_LOAD_STATUS_NAME,
                  INVOICED_LOAD_STATUS_NAME,
                  READY_TO_INVOICE,
                ].includes(invoice?.status) ? (
                <Button
                  id={invoiceModule + 'bitFreighter'}
                  variant="outlined"
                  sx={{ textTransform: 'none', height: '28px' }}
                  onClick={() => invoiceToBitFreighter(invoice?.loadId)}
                >
                  Send to BitFrighter
                </Button>
              ) : (
                invoice.status === COMPLETED_LOAD_STATUS_NAME &&
                editDetails?.tenderId && (
                  <Button
                    id={invoiceModule + 'ediInvoice'}
                    disabled={
                      noPermissionForEditInvoice ||
                      isDirty ||
                      !hasLineItems ||
                      onHold
                    }
                    variant="outlined"
                    sx={{ textTransform: 'none', height: '28px' }}
                    onClick={() =>
                      sendInvoiceToProvider(invoice, editDetails?.tenderId)
                    }
                  >
                    Send to EDI
                  </Button>
                )
              )}
              <Tooltip title="Send Email" placement="left-end">
                <Button
                  id={invoiceModule + 'EmailButton'}
                  disabled={
                    noPermissionForEditInvoice ||
                    isDirty ||
                    !hasLineItems ||
                    onHold
                  }
                  variant="outlined"
                  sx={actionButtonStyle}
                  onClick={() => setShowEmailPopup(true)}
                >
                  <EmailOutlinedIcon sx={{ width: '20px' }} />
                </Button>
              </Tooltip>
              <Tooltip title="Download" placement="top">
                <Button
                  disabled={isDirty || !hasLineItems}
                  id={invoiceModule + 'DownloadButton'}
                  variant="outlined"
                  sx={actionButtonStyle}
                  onClick={() => setDownloadDialogOpen(true)}
                >
                  <DownloadOutlinedIcon sx={{ width: '20px' }} />
                </Button>
              </Tooltip>
              <Tooltip title="Click to get Preview" placement="left-end">
                <Button
                  disabled={isDirty || !hasLineItems}
                  id={invoiceModule + 'ViewButton'}
                  variant="outlined"
                  sx={actionButtonStyle}
                  onClick={() => previewInvoiceDownload(invoice)}
                >
                  <PageviewOutlined sx={{ width: '20px' }} />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Actions
        showEmailPopup={showEmailPopup}
        setShowEmailPopup={setShowEmailPopup}
        selectDateDialogOpen={selectDateDialogOpen}
        setSelectDateDialogOpen={setSelectDateDialogOpen}
        currentAction={currentAction}
        downloadDialogOpen={downloadDialogOpen}
        setDownloadDialogOpen={setDownloadDialogOpen}
        invoicedToCompletedModal={invoicedToCompletedModal}
        setInvoicedToCompletedModal={setInvoicedToCompletedModal}
        paidToInvoicedModal={paidToInvoicedModal}
        setPaidToInvoicedModal={setPaidToInvoicedModal}
        invoices={[invoice]}
        isStatusUpdated={isStatusUpdated}
        canAddInvoice={hasInvoiceAddPermission}
      />
      {current3DotMenu && <MarkAsInvoicedDialog />}
    </>
  );
}
