/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PaginatedVendorsList,
  PaginatedVendorsListRequest,
  VendorSummaryDTO,
  CreateVendorRequest,
  UpdateVendorRequest,
  DeleteVendorRequest,
  ContactSummary,
  CreateVendorContactRequest,
  CreateVendorContactRequestParams,
  GetVendorFiltersRequest,
  GetVendorListExcelRequest,
} from '../../models';
import { annotateNameAsync, IVendorService } from '../interfaces';
import { httpClient, ComposedError } from '../../axios/axiosInstance';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

const getPaginatedVendorsListURL = '/web/expense/api/v2/vendors/list';
const getPaginatedVendorsListExcelURL =
  '/web/expense/api/v2/vendors/vendor/export-to-excel';
const createContactURL = '/web/expense/api/v2/vendors/vendor-contact';
const createVendorURL = '/web/expense/api/v2/vendors/vendor';
const updateVendorURL = '/web/expense/api/v2/vendors/vendor';
const deleteVendorURL = '/web/expense/api/v2/vendors/vendor';

export class VendorService extends IVendorService {
  @annotateNameAsync
  async getPaginatedVendorsList(
    requestData: PaginatedVendorsListRequest,
    filtersData: GetVendorFiltersRequest
  ) {
    try {
      return await httpClient.put<PaginatedVendorsList>(
        getPaginatedVendorsListURL,
        requestData,
        filtersData,
        PaginatedVendorsList,
        true,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async getPaginatedVendorsListExcel(
    requestData: GetVendorListExcelRequest,
    filtersData: GetVendorFiltersRequest
  ) {
    try {
      return await httpClient.put<PaginatedVendorsList>(
        getPaginatedVendorsListExcelURL,
        requestData,
        filtersData,
        PaginatedVendorsList,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async createVendor(requestData: CreateVendorRequest) {
    try {
      const response = await httpClient.post<VendorSummaryDTO>(
        createVendorURL,
        undefined,
        requestData,
        VendorSummaryDTO,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'vendorCreate',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'vendorCreate',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      throw error;
    }
  }

  @annotateNameAsync
  async createContact(
    requestData: CreateVendorContactRequest,
    params: CreateVendorContactRequestParams
  ) {
    try {
      const response = await httpClient.post<ContactSummary>(
        createContactURL,
        params,
        requestData,
        ContactSummary,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createContact',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'createContact',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      throw error;
    }
  }

  @annotateNameAsync
  async updateVendor(requestData: UpdateVendorRequest) {
    try {
      const response = await httpClient.put<VendorSummaryDTO>(
        updateVendorURL,
        undefined,
        requestData,
        VendorSummaryDTO,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'vendorUpdate',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'vendorUpdate',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      throw error;
    }
  }

  //@TODO: Nvard - discuss with Nitin to refactor delete api impl
  @annotateNameAsync
  async deleteVendor(requestData: DeleteVendorRequest) {
    try {
      await httpClient.deleteRaw(deleteVendorURL, requestData, null);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'vendorDelete',
      });
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'vendorDelete',
      });
      throw error;
    }
  }
}
