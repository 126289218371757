import React, { useCallback, useEffect, useState } from 'react';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import { FormProvider, useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Footer from '../Common/Footer';
import ImportActions from '../../Import';
import { fontFamily } from '../../constants';
import TabStripLarge from '../../../../../common/TabStripLarge';
import { useImport } from '../../../../../contexts/ImportContext';
import {
  allSections,
  BACK_ACTION_NAME,
  formsMap,
  NEXT_ACTION_NAME,
  sectionType,
} from './constants';
import EmptyPage from './EmptyPage';

export type FormDataType = { [key: string]: { [key: string]: string | null } };

// from all possible sections removes unnecessaries
const generateCurrentSections = (result: any) => {
  const sections = { ...allSections };
  for (const entityName in result) {
    if (!sections[entityName] || !Object.keys(result[entityName]).length) {
      delete sections[entityName];
    }
  }
  return Object.values(sections);
};

// generates form default values
// from { Customer: { a: 'b' } }
// to { Customer: { a: { name: 'b' } } }
const generateFormState = (result: any) => {
  const data = { ...result };
  for (const key in data) {
    for (const formKey in data[key]) {
      if (data[key][formKey]) {
        data[key][formKey] = { name: data[key][formKey] };
      } else {
        data[key][formKey] = null;
      }
    }
  }
  return data;
};

//
const getNeedShowWarningModal = (data: {
  [key: string]: { [key: string]: string };
}) => {
  let needShowModal = false;
  if (data['CUSTOMER']) {
    for (const i in data['CUSTOMER']) {
      if (!data['CUSTOMER'][i]) {
        needShowModal = true;
      }
    }
  }
  return needShowModal;
};

const prepareDataForSave = (data: any) => {
  const tempData = { ...data };
  const finalArray = [];
  for (const key in tempData) {
    for (const formKey in tempData[key]) {
      if (tempData[key][formKey]) {
        tempData[key][formKey] = tempData[key][formKey].name;
      } else {
        tempData[key][formKey] = null;
      }
    }
    finalArray.push({ entityName: key, entityMapping: tempData[key] });
  }
  return finalArray;
};

export default function ExtraStep() {
  const {
    handleBack,
    handleNext,
    currentEntity: { dictionary, routeName },
    sessionId,
  } = useImport();
  const state = useForm();

  const [shouldShowWarningPopup, setShouldShowWarningPopup] = useState(false);
  const [actionName, setActionName] = useState('');
  const [sections, setSections] = useState<sectionType[] | null>(null);
  const [currentSection, setCurrentSection] = useState<sectionType | null>(
    null
  );
  const [missingEntities, setMissingEntities] = useState<{
    [key: string]: any;
  }>({});

  useEffect(() => {
    const getMissingEntities = async () => {
      const result = await ImportActions.getMissingEntities(
        routeName,
        sessionId,
        dictionary
      );
      if (result) {
        setMissingEntities(result);
        const formState = generateFormState(result);
        state.reset(formState);
        const currentSections = generateCurrentSections(result);
        setSections(currentSections);
      }
    };
    getMissingEntities();
  }, []);

  useEffect(() => {
    if (sections && sections.length) {
      setCurrentSection(sections[0]);
    }
  }, [sections]);

  const onSubmit = async (data: FormDataType, action: string) => {
    if (!sections?.length) {
      if (action === NEXT_ACTION_NAME) {
        handleNext();
        return;
      } else {
        handleBack();
        return;
      }
    }
    const requestData = prepareDataForSave(data);
    if (action === NEXT_ACTION_NAME) {
      const result = await ImportActions.applyMissingEntities(
        routeName,
        dictionary,
        sessionId,
        requestData
      );
      if (result) {
        handleNext();
      }
    } else {
      await ImportActions.createMissingEntities(
        routeName,
        dictionary,
        sessionId,
        requestData
      );
      handleBack();
    }
  };

  const handleActionClicked = useCallback(
    (actionName: string) => {
      setActionName(actionName);
      state.handleSubmit((data) => {
        const needShowWarningModal = getNeedShowWarningModal(data);
        if (needShowWarningModal) {
          setShouldShowWarningPopup(true);
        } else {
          onSubmit(data, actionName);
        }
      })();
    },
    [sections]
  );

  const handleAction = useCallback(() => {
    state.handleSubmit((data) => onSubmit(data, actionName))();
  }, [actionName, sections]);

  const renderContent = () => {
    if (!sections) {
      return null;
    }
    if (sections.length === 0) {
      return <EmptyPage />;
    }
    if (currentSection) {
      const CurrentForm = formsMap[currentSection.name];
      const missingEntitiesCount = missingEntities[currentSection.name]
        ? Object.keys(missingEntities[currentSection.name]).length
        : '';
      return (
        <>
          <Box sx={{ display: 'flex' }}>
            {sections.map((section) => (
              <TabStripLarge
                key={section.key}
                title={section.label}
                icon={section.icon}
                onClick={() => setCurrentSection(section)}
                selected={section.key === currentSection.key}
              />
            ))}
          </Box>
          <Box sx={{ mt: 6, overflow: 'hidden' }}>
            <Box sx={{ mt: 3 }}>
              <Typography
                variant={'h6'}
                sx={{
                  fontFamily,
                  fontSize: 20,
                  color: 'rgba(0, 17, 34, 0.75)',
                }}
              >
                {missingEntitiesCount} missing{' '}
                {currentSection.label.toLowerCase()}(s) needed to complete Load
                Import. All unmapped {currentSection.label.toLowerCase()}s will
                be added to the system automatically with its load.
              </Typography>
            </Box>
            <Box sx={{ mt: 3, overflow: 'auto' }}>
              <FormProvider {...state}>
                <CurrentForm
                  axeleValues={missingEntities[currentSection.name]}
                  currentSection={currentSection}
                />
              </FormProvider>
            </Box>
          </Box>
        </>
      );
    }
  };

  return (
    <>
      {shouldShowWarningPopup && (
        <DeletePopup
          open={shouldShowWarningPopup}
          onClose={() => setShouldShowWarningPopup(false)}
          onAction={handleAction}
          title={
            actionName === NEXT_ACTION_NAME
              ? `There is unmapped Customer(s). We will create them as a new Customer(s). `
              : 'If you go one step back you will lose all the changes done on this page. Still, want to go back? '
          }
          buttonText={actionName === NEXT_ACTION_NAME ? 'OK' : 'Yes'}
          cancelText={actionName === NEXT_ACTION_NAME ? 'CANCEL' : 'No'}
        />
      )}
      {renderContent()}
      {sections && (
        <Footer
          onBack={() => handleActionClicked(BACK_ACTION_NAME)}
          onNext={() => handleActionClicked(NEXT_ACTION_NAME)}
          nextButtonTitle={'Next'}
          showNextIcon={true}
        />
      )}
    </>
  );
}
