import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { IconButton, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { debounce } from 'lodash';
import { ChangeEvent, forwardRef, memo, useCallback, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { TimeDurationDialog } from './TimeDurationDialog';
import {
  fromMinutesToTextMask,
  fromTextMaskToMinutes,
  getTimeDurationSettings,
} from './utils';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

export interface TimeDurationProps {
  name: string;
  label?: string;
  value?: number; //in minutes value
  variant?: 'standard' | 'outlined' | 'filled';
  required?: boolean;
  disabled?: boolean;
  styleProps?: React.CSSProperties;
  maxValue?: number; //in minutes value
  minuteStep?: number;
  onChange?: (newValue: number | null) => void;
  onError?: (event: any) => void;
  placeholder?: string;
  error?: boolean | undefined;
  helperText?: any;
  is24HFormat?: boolean;
}
export const TimeDuration = memo(
  ({
    name,
    label = '',
    variant = 'standard',
    required = false,
    disabled = false,
    styleProps = {},
    value = 0,
    maxValue = 24 * 60,
    minuteStep = 10,
    onChange,
    onError,
    placeholder = '',
    error = false,
    helperText,
    is24HFormat = false,
  }: TimeDurationProps) => {
    const [isError, setIsError] = useState<boolean>(error);
    const textmask = fromMinutesToTextMask(value, { is24HFormat });
    const [defaultTextmaskValue, setDefaultTextmaskValue] =
      useState<string>(textmask);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const handleChangeTextmask = useCallback(
      (_textmask: string): void => {
        if (_textmask) {
          const currValue = fromTextMaskToMinutes(_textmask, { is24HFormat });
          if (isNaN(currValue)) {
            setDefaultTextmaskValue(_textmask);
            onChange?.(null);
          } else {
            if (currValue > maxValue) {
              setDefaultTextmaskValue(
                fromMinutesToTextMask(maxValue, { is24HFormat })
              );
              onChange?.(maxValue);
            } else {
              setDefaultTextmaskValue(_textmask);
              onChange?.(currValue);
            }
          }
        } else {
          setIsError(required);
        }
      },
      [maxValue, onChange, required, is24HFormat]
    );

    const onChangeHandler = useCallback(
      debounce((event: ChangeEvent<HTMLInputElement>) => {
        const _textmask = event.target.value;
        handleChangeTextmask(_textmask);
      }, 1000),
      [handleChangeTextmask]
    );

    const onClickShowDialog = () => {
      setOpenDialog(true);
    };

    const handleClose = useCallback(
      (_textmask?: string) => {
        setOpenDialog(false);

        if (_textmask) {
          handleChangeTextmask(_textmask);
        }
      },
      [handleChangeTextmask]
    );

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TextField
          variant={variant}
          label={label}
          required={required}
          disabled={disabled}
          error={isError || error}
          helperText={(isError || error) && helperText}
          placeholder={placeholder}
          value={defaultTextmaskValue}
          onChange={onChangeHandler}
          onError={onError}
          name={name}
          InputProps={{
            inputProps: {
              ...getTimeDurationSettings({ maxValue, is24HFormat }),
            },
            inputComponent: TextMaskCustom as any,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  disabled={disabled}
                  onClick={onClickShowDialog}
                >
                  <ScheduleOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            ...styleProps,
          }}
        />
        <TimeDurationDialog
          minuteStep={minuteStep}
          maxValue={maxValue}
          open={openDialog}
          onClose={handleClose}
          textmaskValue={defaultTextmaskValue}
          is24HFormat={is24HFormat}
        />
      </LocalizationProvider>
    );
  }
);
TimeDuration.displayName = 'TimeDuration';
