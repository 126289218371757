import { Link } from '@/components_v2/ui-kit/link';
import { Autocomplete } from '@components/ui-kit/autocomplete';
import { Switch } from '@components/ui-kit/switch';
import styled from '@emotion/styled';
import { CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Controller, useFormContext } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { loadService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { httpClient } from '../../../../../axios/axiosInstance';
import { QueryParams } from '../../../../../models';
import useFilterStorage from '../../../../../services/storage';
import { RootStoreInstence } from '../../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../../store/root.context';
import { LineItem } from '../../../../../types';
import { urls } from '../../apis';
import { SECTION_LAST_ELEMENT_ID_LIST } from '../../constants/fieldValues';
import { QUERY_KEYS } from '../../constants/queryKeys';
import { initialState } from '../../data.config';
import {
  CreateLoadStopRequestStopDTO,
  RelayStopRequestDTO,
  StopDropRequestDTO,
} from '../../dto/create-load-request.dto';
import {
  LoadCommodityToCreateLoadDTO,
  TemplateResToLoadDTO,
  TemplateResToLoadRateDTO,
} from '../../dto/template-res.dto';
import { fetchDropdownData } from '../../services';
import { stopFormService } from '../../services/stopForm.service';
import { RateConFileNameViewerContainer } from '../../style';
import { LoadTemplateDetailsDTO } from '../../types/requests';
import { SupportedVendorsDialog } from './supported-vendors';

export const OrDividerStyle = {
  alignSelf: 'stretch',
  justifyContent: 'center',
  backgroundColor: '#f5f5f5',
  borderRadius: '10px',
  alignItems: 'center',
  padding: ' 5px 10px',
};

const customFileUploadStyle: React.CSSProperties = {
  borderRadius: '8px',
  textAlign: 'center',
  cursor: 'pointer',
};

const customFileUploadStylesContainer = {
  border: '2px dashed #ccc',
  borderRadius: '8px',
  fontStyle: 'italic',
  width: '100%',
};

const QuickStartContentWrapper = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const QuickStartOrDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 800px) {
    flex-direction: row;
  }
`;
interface IProps {
  isTerminalEnabled?: boolean;
  allTerminals?: Array<any>;
}

const QuickStartDetails: React.FC<IProps> = observer(
  ({ isTerminalEnabled, allTerminals }) => {
    // const [selectedTemplate, setSelectedTemplate] = useState<string>('');
    const [vendorsDialogOpen, setVendorsDialogOpen] = useState(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const { setValue, reset, watch } = useFormContext();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [templates, setTemplates] = useState<any>([]);
    const isLoadingRef = useRef(false);
    const selectedTemplate = watch('templateId');
    const selectedTemplateName = watch('templateName');
    const [templateInput, setTemplateInput] = useState<string>('');
    const inputFileRef = useRef();
    const {
      myLoadStore: {
        setCreateLoadRateConFile,
        createLoadRateConFile,
        setShowRateConPdf,
        showRateConPdf,
      },
    } = useStores();
    const queryClient = useQueryClient();

    const LStorageHook = useFilterStorage({ page: 'MyLoads' });
    const storageData = LStorageHook.getStorage() || {};
    useEffect(() => {
      setTemplateInput(selectedTemplateName);
      if (selectedTemplateName) {
        fetchTemplates(1, selectedTemplateName);
      }
    }, [selectedTemplateName]);

    const lineItemsList = queryClient.getQueryData<{
      base: LineItem[];
      fees: LineItem[];
    }>(QUERY_KEYS.lineItems);

    useEffect(() => {
      const fetchSingleTemplate = async () => {
        try {
          setShowLoader(true);
          const queryParams = new QueryParams(true);
          const response = await httpClient.get<LoadTemplateDetailsDTO>(
            urls.getTemplateData + selectedTemplate,
            queryParams,
            undefined,
            true,
            true
          );
          setCreateLoadFormValue(response);
          setShowLoader(false);
        } catch (error) {
          setShowLoader(false);
        }
      };
      if (selectedTemplate) {
        fetchSingleTemplate();
      }
    }, [selectedTemplate]);

    const setCreateLoadFormValue = async (load: LoadTemplateDetailsDTO) => {
      try {
        validateStopMissingCoordinates(load);
        const createLoadSchema = new TemplateResToLoadDTO(
          { ...load, templateId: selectedTemplate },
          isTerminalEnabled!,
          allTerminals
        );
        const templateToRateDTOInstance = new TemplateResToLoadRateDTO(load);
        const templateCommodityResDTO = new LoadCommodityToCreateLoadDTO();
        const isContractExist = load?.invoice?.lineItems?.find((e: any) =>
          Boolean(e?.contractId)
        );
        if (!isContractExist) {
          queryClient.refetchQueries(QUERY_KEYS.lineItems);
        } else {
          const feeWithoutContract: LineItem[] = [];
          lineItemsList?.fees?.forEach?.((item) => {
            if (
              !item?.contractId ||
              item?.contractId === isContractExist?.contractId
            ) {
              feeWithoutContract?.push(item);
            }
          });
          queryClient.setQueryData(QUERY_KEYS.lineItems, {
            base: lineItemsList?.base,
            fees: feeWithoutContract,
          });
        }
        const [rateFormDetails, totalMiles, formattedCommodity] =
          await Promise.all([
            templateToRateDTOInstance.fetchAndUpdateLineItemValuesById(
              load.invoice?.lineItems as LineItem[]
            ),
            stopFormService.getTotalMiles(createLoadSchema?.stops),
            templateCommodityResDTO.fetchAndMapCommodityTypes(
              load?.loadDetails?.commodityData
            ),
          ]);
        createLoadSchema.rate = rateFormDetails;
        createLoadSchema.totalMiles = createLoadSchema.totalMiles ?? totalMiles;
        if (createLoadSchema?.load)
          createLoadSchema.load.commodities = formattedCommodity;
        reset({
          ...createLoadSchema,
          requiredFields: initialState.requiredFields,
          hiddenFields: storageData?.createLoad?.['hiddenFields'] ?? {},
        });
      } catch (error) {
        console.log(error);
      }
    };

    const validateStopMissingCoordinates = (load: LoadTemplateDetailsDTO) => {
      const missingCoordsExist = load?.stops?.some?.((stop) => {
        if (stop?.loadStop) {
          return (
            !stop.loadStop?.location?.lat &&
            !stop.loadStop?.location?.lng &&
            !stop.loadStop?.location?.center?.lat &&
            !stop.loadStop?.location?.center?.lng
          );
        } else {
          return (
            !stop.relayStop?.connectionPoint?.lat &&
            !stop.relayStop?.connectionPoint?.lng &&
            !stop.relayStop?.connectionPoint?.center?.lat &&
            !stop.relayStop?.connectionPoint?.center?.lng
          );
        }
      });
      if (missingCoordsExist) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'missingCoordinates',
        });
      }
    };

    useEffect(() => {
      fetchTemplates(1);
    }, []);

    const fetchTemplates = async (pageNumber: number, templateName = '') => {
      try {
        isLoadingRef.current = true;
        const templatesRes: any = await fetchDropdownData({
          endpoint: urls.getAllTemplates,
          pageNumber,
          pageSize: 20,
          query: { templateName },
        });
        if (pageNumber === 1) setTemplates(templatesRes?.content);
        else {
          if (templatesRes?.content?.length)
            setTemplates([...templates, ...templatesRes?.content]);
        }
        if (templatesRes?.last) return;
        isLoadingRef.current = false;
      } catch (error) {
        isLoadingRef.current = false;
        console.log(error);
      }
    };

    const handleScrollTemplate = (event: any) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
        if (isLoadingRef?.current) return;
        fetchTemplates(pageNumber + 1, templateInput);
        setPageNumber(pageNumber + 1);
        isLoadingRef.current = true;
      }
    };

    const handleFileChange = async (event: any) => {
      try {
        if (event?.target?.files?.[0]?.type !== 'application/pdf') {
          return RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            message: 'Only PDF file format is accepted',
          });
        }
        setShowLoader(true);
        const file: File = event?.target?.files?.[0];
        setCreateLoadRateConFile(file);
        const parsedLoadData = await loadService.getParsedLoadDetailsFromFile(
          new QueryParams(),
          file,
          true
        );
        if (parsedLoadData instanceof ServiceError) {
          throw new Error('File upload failed');
        }
        const stops: Array<CreateLoadStopRequestStopDTO> = [];
        if (parsedLoadData?.stops) {
          parsedLoadData?.stops?.forEach?.((stop, index) => {
            if ((stop as StopDropRequestDTO)?.activityType === 'RELAY')
              stops?.push({ relayStop: stop as RelayStopRequestDTO });
            else stops?.push({ loadStop: stop as StopDropRequestDTO });
          });
          parsedLoadData.stops = stops as StopDropRequestDTO[];
        }
        if (parsedLoadData) parsedLoadData.customer = {};
        setCreateLoadFormValue(parsedLoadData as LoadTemplateDetailsDTO);
        setShowLoader(false);
      } catch (error) {
        console.log('error: ', error);
        setShowLoader(false);
      }
    };

    const containerStyles = useMemo(() => {
      if (showLoader)
        return { opacity: '0.5', pointerEvents: 'none', cursor: 'wait' };
      return { opacity: 1 };
    }, [showLoader]);
    const closeSelectedRateconFile = () => {
      setCreateLoadRateConFile(null);
    };

    const handleChange = (file: any) => {
      handleFileChange({ target: { files: [file] } });
    };

    const handleTemplateSearch = debounce((t: string) => {
      if (t === templateInput) return;
      setTemplateInput(t);
      setPageNumber(1);
      fetchTemplates(1, t);
    }, 300);

    return (
      <Stack direction="column" spacing={3} sx={containerStyles}>
        <QuickStartContentWrapper>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              flexGrow: 1,
            }}
          >
            <div>
              Have a Rate Confirmation PDF? If its from one of our{' '}
              <Link
                href="javascript:void(0)"
                onClick={() => setVendorsDialogOpen(true)}
              >
                supported vendors
              </Link>{' '}
              we&apos;ll scrape the data.
            </div>
            <SupportedVendorsDialog
              open={vendorsDialogOpen}
              setOpen={setVendorsDialogOpen}
            />
            {createLoadRateConFile ? (
              <>
                <Box sx={RateConFileNameViewerContainer}>
                  {createLoadRateConFile?.name}
                  <IconButton
                    size="small"
                    sx={{ ml: 'auto' }}
                    onClick={closeSelectedRateconFile}
                  >
                    <CloseOutlined />
                  </IconButton>
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  sx={{ mt: '0!important' }}
                >
                  <Switch
                    checked={showRateConPdf}
                    onChange={() => setShowRateConPdf(!showRateConPdf)}
                  />
                  <Typography fontWeight={500}>View PDF</Typography>
                </Box>
              </>
            ) : (
              <div style={customFileUploadStylesContainer}>
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  style={customFileUploadStyle}
                  hoverTitle={"'"}
                >
                  <Box
                    sx={{ padding: '20px', cursor: 'pointer', width: '100%' }}
                  >
                    <Typography sx={{ textAlign: 'center' }}>
                      Drag/Drop Ratecon PDF or <br />
                      <Link sx={{ textDecoration: 'none' }}>
                        click to browse files
                      </Link>
                    </Typography>
                    <input
                      type="file"
                      id="file-upload"
                      accept=".pdf"
                      onChange={handleFileChange}
                      style={{ display: 'none', width: '100%' }}
                      autoFocus
                    />
                  </Box>
                </FileUploader>
              </div>
            )}
          </Stack>
          <QuickStartOrDivider>
            <Box
              sx={{ flexGrow: 1, backgroundColor: '#DDD', padding: '1px' }}
            ></Box>
            <Box sx={OrDividerStyle}>OR</Box>
            <Box
              sx={{ flexGrow: 1, backgroundColor: '#DDD', padding: '1px' }}
            ></Box>
          </QuickStartOrDivider>

          <Stack
            direction="column"
            spacing={2}
            sx={{
              flexGrow: 1,
            }}
          >
            <Box>
              Do you often run the same lanes? Save any load as a template and
              then use it to prefill a new load.
            </Box>
            <Controller
              name="templateId"
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={templates}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      const temp = templates?.find(
                        (e: any) => e?.id === option
                      );
                      return temp?.templateName ?? '';
                    }
                    return option?.templateName ?? '';
                  }}
                  onBlur={() => {
                    if (selectedTemplateName?.length) {
                      const temp = templates?.find(
                        (e: any) => e?.id === selectedTemplate
                      );
                      if (!temp) handleTemplateSearch(selectedTemplateName);
                    }
                  }}
                  handleHomeEndKeys
                  id={SECTION_LAST_ELEMENT_ID_LIST[0]}
                  onInputChange={(_, v) => {
                    handleTemplateSearch?.(v);
                  }}
                  onChange={(_: any, event: any) => {
                    field.onChange(event?.id ?? '');
                  }}
                  ListboxProps={{
                    onScroll: (data: any) => {
                      handleScrollTemplate(data);
                    },
                  }}
                  sx={{
                    '.MuiInputBase-input': { height: '15px' },
                    '& .MuiFormLabel-root': {
                      marginTop: '-3px',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={!selectedTemplateName}
                      label="Choose a template"
                    />
                  )}
                />
              )}
            />
          </Stack>
        </QuickStartContentWrapper>
        <Box>
          <small>
            Tip: <b>Tab</b> off last field to move to first field in next
            section; <b>Shift+Tab</b> on any field to skip to next section
          </small>
        </Box>
      </Stack>
    );
  }
);

export { QuickStartDetails };
