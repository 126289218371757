export const SET_DRIVER_SCHEDULE = 'SET_DRIVER_SCHEDULE';
export const SET_DRIVERS = 'SET_DRIVERS';
export const SET_LOADS = 'SET_LOADS';
export const SET_BREAKS = 'SET_BREAKS';
export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE';
export const ADD_DRIVER_SCHEDULE = 'ADD_DRIVER_SCHEDULE';
export const SET_LOADER = 'SET_LOADER';
export const SET_SCHEDULE_WARNINGS = 'SET_SCHEDULE_WARNINGS';
export const UPDATE_DRIVER_SCHEDULE_WARNINGS =
  'UPDATE_DRIVER_SCHEDULE_WARNINGS';
export const MAP_SCHEDULE_WARNINGS = 'MAP_SCHEDULE_WARNINGS';
export const STOP_LOADER = 'STOP_LOADER';
export const UPDATE_DRIVER_SCHEDULE = 'UPDATE_DRIVER_SCHEDULE';
export const UPDATE_TRIP = 'UPDATE_TRIP';
export const EMPTY_TRIP_ASSIGNMENT = 'EMPTY_TRIP_ASSIGNMENT';
export const CLEAR_EMPTY_TRIP_ASSIGNMENT = 'CLEAR_EMPTY_TRIP_ASSIGNMENT';
export const STOP_INITIAL_LOADER = 'STOP_INITIAL_LOADER';
