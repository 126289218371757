import { Box, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Accordion from '../../../common/Accordion';
import FormSectionTitle from '../../../common/FormSectionTitle';
import { statesinCountry } from '../../../common/Ui/AddressField/constants/states';
import UploadUpdated from '../../../common/Ui/FileUpload/UploadUpdated';
import FormDatePicker from '../../../common/Ui/FormDatePicker/FormDatePicker';
import Select from '../../../common/Ui/Select';
import { SingleAutocompleteForm } from '../../../common/Ui/SingleAutocomplete';
import Switch from '../../../common/Ui/Switcher';
import TextField from '../../../common/Ui/TextField/TextField';
import { AssignedDocuments, FileType } from '../../../types';
import { getEldIntegrationsDetailsList } from '../../drivers/utils';
import Controller from '../../tractors/Controller';
import { invoiceManagementOption, timezonesMap } from '../constants';
import {
  deleteFile,
  getEldDrivers,
  noPermissionForAddRemoveEditUser,
  saveFile,
} from '../utils';
export default function DriverDetailsForm({
  data: { status },
}: {
  data: { status: number };
}) {
  const { control, setValue, watch, getValues } = useFormContext();

  const [eldProvider, assignedDocuments, id] = watch([
    'eldProvider',
    'assignedDocuments',
    'id',
  ]);

  const driver = watch();
  const licenseState = getValues('driverLicense.licenseState');
  useEffect(() => {
    if (!licenseState) {
      setValue('driverLicense.licenseState', null);
    }
  }, [licenseState]);

  const [assignedDocumentList, setAssignedDocumentList] =
    useState(assignedDocuments);

  useEffect(() => {
    setAssignedDocumentList(assignedDocuments);
  }, [assignedDocuments]);

  const { fuelProviderOptions } = Controller.instance();

  const onProviderChange = (data: any) => {
    setValue('eldDriver', null);
    if (!data) {
      setValue('trackingSource.eld', false);
    }
  };

  const onEldDriverIdChange = (data: any[]) => {
    setValue('trackingSource.eld', !!data);
  };

  const handleAssignedDocumentsChange = async (file?: File[] | null) => {
    if (!file) return;

    const document = await saveFile(driver, file);

    if (document) setAssignedDocumentList([new AssignedDocuments(document)]);
  };

  const handleRemoveAssignedDocuments = async () => {
    if (!assignedDocumentList.length) return;

    const ids = assignedDocumentList.map((item: FileType) => item.documentId);

    const response = await deleteFile(ids, id);

    if (!response?.length) setAssignedDocumentList(null);
    setAssignedDocumentList(response);
  };

  const noPermissionForInactiveStatus = status === 0;

  return (
    <Box sx={{ marginTop: 2, pr: 2, pl: 2 }}>
      <Grid container justifyContent={'space-between'}>
        <TextField
          control={control}
          name="driverRefId"
          label="Driver ID"
          required
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
        />
        <SingleAutocompleteForm
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          control={control}
          name="fuelCardProvider"
          label="Fuel Card Provider"
          options={fuelProviderOptions}
          fieldName="value"
          fieldValue="key"
          sizes={{ xs: 5.7 }}
        />
        <TextField
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          control={control}
          name="fuelCardNumber"
          label={t('fuelCard') + ' #'}
          sizes={{ xs: 5.7 }}
        />
      </Grid>
      <Accordion name="Tracking info">
        <Grid container justifyContent={'space-between'}>
          <SingleAutocompleteForm
            disabled={
              noPermissionForAddRemoveEditUser() ||
              noPermissionForInactiveStatus
            }
            name={'eldProvider'}
            label={'ELD Provider'}
            fieldName={'name'}
            getOptions={() => getEldIntegrationsDetailsList()}
            onChangeCb={onProviderChange}
            control={control}
            sizes={{ xs: 5.7 }}
            defaultValue={null}
          />
          <SingleAutocompleteForm
            name={'eldDriver'}
            label={'ELD ID'}
            fieldName={'nameToShow'}
            getOptions={(patern) => getEldDrivers(patern, eldProvider.id)}
            control={control}
            onChangeCb={onEldDriverIdChange}
            disabled={
              !eldProvider ||
              noPermissionForAddRemoveEditUser() ||
              noPermissionForInactiveStatus
            }
            required={!!eldProvider}
            disableClear
            sizes={{ xs: 5.7 }}
            defaultValue={null}
          />
          <Switch
            name={'trackingSource.eld'}
            label={'ELD'}
            disabled={
              !eldProvider ||
              noPermissionForAddRemoveEditUser() ||
              noPermissionForInactiveStatus
            }
          />
          <Switch
            name={'trackingSource.gps'}
            label="GPS"
            disabled={
              noPermissionForAddRemoveEditUser() ||
              noPermissionForInactiveStatus
            }
          />
          <Typography
            sx={{
              fontSize: '12px',
              margin: '-25px 0px 20px 0px',
            }}
          >
            Ask driver to enable location by turning on GPS.
          </Typography>
          <Select
            disabled={
              noPermissionForAddRemoveEditUser() ||
              noPermissionForInactiveStatus
            }
            name={'timezone'}
            label={'Timezone'}
            fieldName={'value'}
            fieldValue={'key'}
            options={timezonesMap}
            control={control}
          />
        </Grid>
      </Accordion>
      <Accordion
        summaryRenderer={(expanded) => (
          <Grid container direction="row" alignItems={'center'}>
            <Grid xs={4}>
              <Typography
                variant="h7"
                sx={{ color: expanded ? 'primary.main' : 'text.primary' }}
              >
                License Details
              </Typography>
            </Grid>
          </Grid>
        )}
      >
        <Grid
          container
          justifyContent="space-around"
          sx={{
            marginTop: '20px',
          }}
        >
          <FormDatePicker
            disabled={
              noPermissionForAddRemoveEditUser() ||
              noPermissionForInactiveStatus
            }
            control={control}
            name="driverLicense.validTillDate"
            label="Expiry Date"
            sizes={{ xs: 4.5 }}
            startOfDate
            iso
            allowEmptyDate
          />
          <SingleAutocompleteForm
            disabled={
              noPermissionForAddRemoveEditUser() ||
              noPermissionForInactiveStatus
            }
            sizes={{ xs: 4.5 }}
            name="driverLicense.licenseState"
            label="License State"
            fieldName="name"
            fieldValue="value"
            options={statesinCountry}
            control={control}
            defaultValue={null}
          />
          <SingleAutocompleteForm
            disabled={
              noPermissionForAddRemoveEditUser() ||
              noPermissionForInactiveStatus
            }
            sizes={{ xs: 2 }}
            name="driverLicense.category"
            label="Category"
            fieldName="value"
            options={[{ value: 'A' }, { value: 'B' }, { value: 'C' }]}
            control={control}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <UploadUpdated
            onChange={handleAssignedDocumentsChange}
            fileId={
              assignedDocumentList && assignedDocumentList?.[0]?.documentId
            }
            onRemoveCB={handleRemoveAssignedDocuments}
            disabled={
              noPermissionForAddRemoveEditUser() ||
              noPermissionForInactiveStatus
            }
            value={assignedDocumentList?.[0]}
          />
        </Grid>

        <FormSectionTitle title={'Endorsements'} />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.h_endorsement'}
          label={'Hazmat certification - H'}
          convertToNumber
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.n_endorsement'}
          label={'Tanker certification - N'}
          convertToNumber
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.t_endorsement'}
          label={'Double or Triple trailer certification - T'}
          convertToNumber
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.x_endorsement'}
          label={'Hazardous tanker - X'}
          convertToNumber
        />
        <FormSectionTitle title={'Restrictions'} />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.e_restriction'}
          label={'Manual Transmission Restriction - E'}
          convertToNumber
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.l_restriction'}
          label={'Air Break Restriction - L'}
          convertToNumber
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.z_restriction'}
          label={'Restriction - Z'}
          convertToNumber
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.m_restriction'}
          label={'Restriction - M'}
          convertToNumber
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.n_restriction'}
          label={'Restriction - N'}
          convertToNumber
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.o_restriction'}
          label={'Fifth Wheel Connection Restriction - O'}
          convertToNumber
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'driverLicense.v_restriction'}
          label={'Restriction - V'}
          convertToNumber
        />
      </Accordion>
      <Accordion name="Automation Settings">
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'allowDriverAcceptRejectLoad'}
          label={'Allow Driver to Accept/Reject Load'}
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'hidePendingPaymentsFromMobile'}
          label={
            'Do not show pending payments/In review/reviewed settlements on Driver App'
          }
        />
        <Switch
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          name={'canUpdateCommodity'}
          label={'Allow driver to update Commodities on Driver app'}
        />
        <Select
          disabled={
            noPermissionForAddRemoveEditUser() || noPermissionForInactiveStatus
          }
          control={control}
          name="invoiceAccessType"
          label="Invoice Management"
          options={invoiceManagementOption}
          fieldName="value"
          fieldValue="key"
          sizes={{ xs: 12 }}
        />
      </Accordion>
    </Box>
  );
}
