import React, { useState, useEffect } from 'react';
import { View } from '../types';
import { ExpensesListDataCount, QueryParams } from '../models';
import { expenceService } from '../api';

export type ExpensesContextType = {
  currentView: View;
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
  addNewClicked: boolean;
  setAddNewClicked: React.Dispatch<React.SetStateAction<boolean>>;
  component: string;
  listDataCount: ExpensesListDataCount;
  setListDataCount: React.Dispatch<React.SetStateAction<ExpensesListDataCount>>;
  dictionary: string;
  isFuelExpensePanel?: boolean;
  setIsFuelExpense: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

export const ExpensesContext = React.createContext<ExpensesContextType>(
  {} as ExpensesContextType
);
const component = 'Expenses';
const dictionary = 'expense';

export const ExpensesContextProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [currentView, setCurrentView] = useState<View>({} as View);

  const [addNewClicked, setAddNewClicked] = useState<boolean>(false);
  const [listDataCount, setListDataCount] = useState<ExpensesListDataCount>({
    allCount: null,
    fuelCount: null,
    otherCount: null,
  });
  const [isFuelExpensePanel, setIsFuelExpense] = useState<
    boolean | undefined
  >();

  const getExpenseListCount = async () => {
    const response = await expenceService.getExpenseListCount(
      new QueryParams()
    );
    if (response) {
      setListDataCount(response);
    }
  };

  useEffect(() => {
    getExpenseListCount();
  }, []);

  return (
    <ExpensesContext.Provider
      value={{
        currentView,
        setCurrentView,
        addNewClicked,
        setAddNewClicked,
        component,
        listDataCount,
        setListDataCount,
        dictionary,
        isFuelExpensePanel,
        setIsFuelExpense,
      }}
    >
      {children}
    </ExpensesContext.Provider>
  );
};

export const useExpensesSettings = () => React.useContext(ExpensesContext);
