export const wrapperStyles = {
  background:
    'radial-gradient(54.19% 37.9% at 48.7% 17.25%, rgba(45, 115, 230, 0.51) 0%, rgba(8, 34, 77, 0) 100%), rgba(3, 14, 24, 0.89)',
  width: '380px',
  // position: 'absolute',
  // right: '0',
  // top: '0',
  boxShadow: '0px -11px 25px rgb(78 101 120 / 25%)',
  backdropFilter: 'blur(12px)',
  borderRadius: '18px',
  alignItems: 'center',
  zIndex: 99,
};
export const contentBoxStyles = {
  bgcolor: '#fff',
  height: '400px',
  width: '100%',
  borderRadius: '16px',
  p: '16px',
};

export const inputStyles = {
  background: 'transparent',
  border: 'none',
  fontSize: '34px',
  fontWeight: '400',
  color: '#95E7EA',
  letterSpacing: '0.25px',
  width: '100%',
  outline: 'none',
  textAlign: 'center',
};

export const inputStylesVar2 = {
  background: 'transparent',
  border: 'none',
  fontSize: '34px',
  fontWeight: '400',
  color: '#95E7EA',
  letterSpacing: '0.25px',
  width: '50%',
  outline: 'none',
  textAlign: 'center',
};

export const inputStylesVar3 = {
  background: 'transparent',
  border: 'none',
  width: '50%',
  outline: 'none',
  color: '#fff',
  fontWeight: 400,
  fontSize: '1rem',
  letterSpacing: '0.15px',
};

export const gridContainerWrapper = {
  padding: '32px 0',
  alignItems: 'center',
  width: '60%',
  margin: '0 auto',
  justifyContent: 'space-between',
};

export const gridInnerWraaperStyle = {
  display: 'flex',
  borderBottom: '2px solid #95E7EA',
  alignItems: 'center',
};

export const constantSymbolStyles = {
  fontSize: '34px',
  fontWeight: '400',
  color: '#95E7EA !important',
  letterSpacing: '0.25px',
  textAlign: 'center',
};

export const gridBottomStyles = {
  display: 'flex',
  m: '0 auto',
  width: '50%',
  pt: '8px',
};

export const constantSymbolStylesVar2 = {
  color: 'rgb(255, 255, 255)',
  fontWeight: 400,
  fontSize: '1rem',
  letterSpacing: '0.15px',
};

export const swapWrapperStyles = {
  background: '#95E7EA',
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  display: 'grid',
  placeContent: 'center',
  cursor: 'pointer',
};

export const selectStyle = {
  border: 'none',
  outline: 'none',
  color: 'rgba(0, 17, 34, 0.75)',
  fontSize: '1rem',
  cursor: 'pointer',
  fontWeight: 600,
  letterSpacing: '0.15px',
};
