import { Fragment } from 'react';
import {
  CarrierDTO,
  LookupExternalCarrierItem,
} from '../../../../models/DTOs/Carrier/Requests';
import Documents from '../../../../views/reports/documents/sections/EntityDocumentsSection';
import { CARRIER_DOCUMENT_TYPE } from '../../constants/constants';
import { ECarrierPanelTab } from '../../constants/types';
import { AddNewCarrierForm } from './Forms/AddNewCarrierForm/AddNewCarrierForm';
import { CarrierOverview } from './Overview/Overview';

export interface CarrierDetailsFormProps {
  isCreatePanel?: boolean;
  selectedTab: ECarrierPanelTab;
  carrierDTO: CarrierDTO | null;
  setExternalCarrier: React.Dispatch<
    React.SetStateAction<LookupExternalCarrierItem | null>
  >;
}
export default function CarrierDetailsForm({
  isCreatePanel = false,
  selectedTab,
  carrierDTO,
  setExternalCarrier,
}: CarrierDetailsFormProps) {
  if (isCreatePanel) {
    return <AddNewCarrierForm setExternalCarrier={setExternalCarrier} />;
  }

  return (
    <Fragment>
      {selectedTab === ECarrierPanelTab.OVERVIEW && carrierDTO && (
        <CarrierOverview carrierDTO={carrierDTO} />
      )}
      {selectedTab === ECarrierPanelTab.CONTRACT && carrierDTO && <>CONTRACT</>}
      {selectedTab === ECarrierPanelTab.DOCUMENT && carrierDTO && (
        <Documents
          data={{
            id: carrierDTO.id,
            entity: CARRIER_DOCUMENT_TYPE,
            entityObject: {
              id: carrierDTO.id,
              name: `${carrierDTO.name}`,
            },
            terminal: null,
          }}
          // createDocumentCallback={updateMissingDocuments}
        />
      )}
    </Fragment>
  );
}
