import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import Checkbox from '../../../../../../common/Ui/Checkbox';
import CurrencyFieldControl from '../../../../../../common/Ui/CurrencyField/CurrencyFieldControl';
import FileUploadUpdated from '../../../../../../common/Ui/FileUpload/indexUpdated';
import FormDatePickerWithoutTimeZone from '../../../../../../common/Ui/FormDatePickerWithoutTimeZone/FormDatePickerWithoutTimeZone';
import { SingleAutocompleteForm } from '../../../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../../../common/Ui/TextField/TextField';
import { CarrierNumberFormatProps } from '../../../../constants/constants';
import {
  getStateOptions,
  getStaticListOptions,
} from '../../../../utils/filters.utils';
const defaultTextFieldProps = {
  style: { marginBottom: 0 },
};

export default function InsuranceForm({
  index,
  onChangeCarrierInsuranceDocument,
  canEdit,
}: {
  index: number;
  onChangeCarrierInsuranceDocument: (files: File[] | null) => void;
  canEdit: boolean;
}) {
  const { control, watch } = useFormContext();
  const defaultDocument = watch(`insurances.${index}.defaultDocument`);
  const insuranceDocument = watch(`insurances.${index}.assignedDocuments`)?.[0];
  const insuranceDocumentId = insuranceDocument?.documentId;

  return (
    <>
      <SingleAutocompleteForm
        name={`insurances.${index}.type`}
        label="Insurance Type"
        fieldName="value"
        getOptions={async (searchString: string, pageNumber: number) => {
          return getStaticListOptions(
            searchString,
            pageNumber,
            'insuranceType'
          );
        }}
        control={control}
        sizes={{ xs: 6 }}
        required
        disabled={!canEdit}
      />
      <CurrencyFieldControl
        control={control}
        name={`insurances.${index}.amount`}
        label="Insurance Amount"
        sizes={{
          xs: 6,
        }}
        required
        {...defaultTextFieldProps}
        disabled={!canEdit}
        numberFormatProps={CarrierNumberFormatProps}
      />
      <FormDatePickerWithoutTimeZone
        control={control}
        disabledText
        name={`insurances.${index}.expDate`}
        label="Expiration"
        sizes={{ xs: 6 }}
        wrapperStyles={{ marginBottom: 0 }}
        disabled={!canEdit}
      />
      <TextField
        control={control}
        name={`insurances.${index}.provider`}
        label="Provider"
        sizes={{
          xs: 6,
        }}
        required
        {...defaultTextFieldProps}
        disabled={!canEdit}
      />
      <TextField
        control={control}
        name={`insurances.${index}.producer`}
        label="Producer"
        sizes={{
          xs: 12,
        }}
        {...defaultTextFieldProps}
        disabled={!canEdit}
      />
      <SingleAutocompleteForm
        name={`insurances.${index}.state`}
        label="State"
        fieldName="value"
        options={getStateOptions()}
        control={control}
        sizes={{ xs: 6 }}
        disabled={!canEdit}
      />
      <TextField
        control={control}
        name={`insurances.${index}.policyNumber`}
        label="Policy #"
        sizes={{
          xs: 6,
        }}
        onlyNumbers={true}
        {...defaultTextFieldProps}
        disabled={!canEdit}
      />
      <Grid xs={12} container>
        <Checkbox
          control={control}
          label={'Refrigeration Breakdown Coverage'}
          name={`insurances.${index}.fridgeBreakCoverage`}
          {...defaultTextFieldProps}
          disabled={!canEdit}
        />
      </Grid>
      <TextField
        sizes={{ xs: 12 }}
        control={control}
        label={'Notes'}
        name={`insurances.${index}.notes`}
        {...defaultTextFieldProps}
        disabled={!canEdit}
      />
      <FileUploadUpdated
        name={`insurances.${index}.assignedDocuments`}
        label="Upload"
        fileId={insuranceDocumentId}
        control={control}
        sizes={{ xs: 12 }}
        onChangeCB={onChangeCarrierInsuranceDocument}
        defaultFile={defaultDocument}
        disabled={!canEdit}
      />
    </>
  );
}
