import { ELoadStatus } from '../../common/LoadTabPanel/constants/constants';
import BaseEvent from '../BaseEvent';
import { EventTypes } from '../EventTypes';

export interface LoadTripActionData {
  toStatus: ELoadStatus | 'UPDATE_PROGRESS';
  fromStatus: ELoadStatus;
  entity: 'Load' | 'Trip';
  loadId?: string;
  tripIds?: string[];
  id: string;
  skipOpenDetailsPanel?: boolean;
}

export default class EventLoadTripAction extends BaseEvent {
  data: LoadTripActionData;
  constructor(data: LoadTripActionData) {
    super(EventTypes.LOAD_TRIP_ACTION, data);
    this.data = data;
  }
}
