import { DeleteButtonIcon } from '../../ui-kit/components/Assets';
import DeletePopup from '../../ui-kit/components/DeletePopup';
import { useState } from 'react';
import { locationService } from '../../api';
import LongMenu from '../../common/LongMenu';
import { AXELE_PERMISSION_TYPE, Permission } from '../../common/Permission';
import { DeleteLocationByIdRequest } from '../../models';
import StorageManager from '../../StorageManager/StorageManager';

interface ActionsSectionProps {
  onDeleted: (data: number) => void;
  id?: number | null;
}

interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
  customStyles?: {};
}

export function ActionsSection({ onDeleted, id }: ActionsSectionProps) {
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const userStorageData = StorageManager.getUser();
  const options: Action[] = [
    {
      customStyles: { paddingRight: '5px' },
      name: 'Delete Location',
      action: () => {
        handleDeleteClick();
      },
      startIcon: DeleteButtonIcon({}),
    },
  ];

  function handleDeleteClick() {
    setIsDeletePopupOpen(true);
  }

  async function handleDelete() {
    if (!id) return;
    const request = new DeleteLocationByIdRequest();
    request.id = id;

    try {
      const response = await locationService.deleteLocationById(request);
      if (response === null) {
        throw new Error();
      }
      onDeleted(id);
      setIsDeletePopupOpen(false);
    } catch (error) {
      return;
    }
  }

  return (
    <>
      <Permission includes={[AXELE_PERMISSION_TYPE.LOCATION_REMOVE]}>
        <LongMenu color={'primary.contrast'} options={options} />
      </Permission>
      {isDeletePopupOpen && (
        <DeletePopup
          open={isDeletePopupOpen}
          title="Delete Location"
          body="Are you sure you want to delete this location?"
          onAction={handleDelete}
          onClose={() => setIsDeletePopupOpen(false)}
        />
      )}
    </>
  );
}
