import {
  GetFactoringStaticRequest,
  GetInvoiceTermsRequest,
  GetStatesRequest,
} from '../models';
import { staticService } from '../api';
import { ServiceError } from '../api/interfaces';

class StaticActions {
  // keep all states static data
  static statesResponse = null;

  // invoice terms here
  static invoiceTerms = null;

  // factoring static data here
  static factoringStatic = null;

  // getting invoices list for grid view
  static async getStates() {
    if (!this.statesResponse) {
      const request = new GetStatesRequest();
      const response = await staticService.getStates(request);
      if (response instanceof ServiceError) {
        return;
      } else {
        this.statesResponse = response;
        return response;
      }
    }
    return this.statesResponse;
  }

  // getting invoices list for grid view
  static async getInvoiceTerms() {
    if (!this.invoiceTerms) {
      const request = new GetInvoiceTermsRequest();
      const response = await staticService.getInvoiceTerms(request);
      if (response instanceof ServiceError) {
        return;
      } else {
        this.invoiceTerms = response.invoiceTerm;
        return response;
      }
    }
    return this.invoiceTerms;
  }

  // getting factoring statics
  static async getFactoringStatic() {
    if (!this.factoringStatic) {
      const request = new GetFactoringStaticRequest();
      const response = await staticService.getFactoringStatic(request);
      if (response instanceof ServiceError) {
        return;
      } else {
        this.factoringStatic = response;
        return response;
      }
    }
    return this.factoringStatic;
  }
}

export default StaticActions;
