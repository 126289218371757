import IconButton from '@mui/material/IconButton';
import React, { useCallback } from 'react';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import { DirtyDetailsPanelManager } from '../utils';
import { DetailsPanelClosePopover } from './DetailsPanelClosePopover';
import { CloseIcon, ExpandIcon } from './icons';
import { t } from 'i18next';

export enum ECloseOrCancelButton {
  CLOSE_ICON = 'CLOSE_ICON',
  CANCEL_BUTTON = 'CANCEL_BUTTON',
  EXPAND_ICON = 'EXPAND_ICON',
}
interface CloseOrCancelButtonProps {
  onClose: () => void;
  isDirty: boolean;
  type: ECloseOrCancelButton;
}

export const CloseOrCancelButton = ({
  onClose,
  isDirty,
  type = ECloseOrCancelButton.CLOSE_ICON,
}: CloseOrCancelButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handlePanelClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      //@TODO : Sargis.  DirtyDetailsPanelManager.panelName check Remove after load panels refactoring
      if (isDirty || DirtyDetailsPanelManager.panelName) {
        setAnchorEl(event.currentTarget);
      } else {
        onClose();
      }
    },
    [isDirty, onClose]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const contentRender = () => {
    switch (type) {
      case ECloseOrCancelButton.CLOSE_ICON:
        return (
          <IconButton
            role="button"
            onClick={handlePanelClose}
            id="closeIconButton"
          >
            <CloseIcon />
          </IconButton>
        );

      case ECloseOrCancelButton.CANCEL_BUTTON:
        return (
          <ButtonImproved
            id="Cancel"
            variant="outlined"
            onClick={handlePanelClose}
            label={t('cancel')}
            size="small"
            styleProps={{
              marginRight: '10px',
              width: '150px',
            }}
          />
        );

      case ECloseOrCancelButton.EXPAND_ICON:
        return (
          <IconButton
            style={{ paddingRight: 18 }}
            onClick={handlePanelClose}
            id="expandIconButton"
          >
            <ExpandIcon />
          </IconButton>
        );
    }
  };

  return (
    <>
      {contentRender()}
      <DetailsPanelClosePopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onPopoverClose={handlePopoverClose}
        onExit={onClose}
        onSubmit={handlePopoverClose}
        {...(type === ECloseOrCancelButton.EXPAND_ICON && {
          title: t('pleasesaveyourchanges'),
          exitButtonTitle: t('yes'),
          onExit: handlePopoverClose,
          submitButtonTitle: t('no'),
          onSubmit: () => {
            onClose();
            handlePopoverClose();
          },
        })}
      />
    </>
  );
};
