import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { eldService } from '../../api';
import {
  CreateDriverRequest,
  CreateMultipleDriversRequest,
  CreateMultipleTractorRequest,
  CreateTractorRequest,
  LinkDriverValidateRequest,
  LinkTractorValidateRequest,
} from '../../models/DTOs/ELD/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { AxeleDriverMapping } from '../../subPages/integrations/eld/SelectedCustomer/AxeleDriverMapping';
import { AxeleTractorMapping } from '../../subPages/integrations/eld/SelectedCustomer/AxeleTractorMapping';
import { manageIntegrationTabs } from '../../subPages/integrations/eld/SelectedCustomer/manageIntegrationTabs';
import { CreateDriverForm } from '../../subPages/integrations/eld/forms/CreateDriverForm';
import { CreateTractorForm } from '../../subPages/integrations/eld/forms/CreateTractorForm';
import {
  createDriverValidationSchema,
  createTractorValidationSchema,
} from '../../subPages/integrations/eld/forms/eldFormValidationConstants';
import {
  IDriverCredentials,
  IManageDataProps,
  IRowActualData,
  IRowData,
  IRowDetails,
  ITerminals,
} from '../../types/ELDManageTypes';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import { CommonDataGrid } from '../../ui-kit/components/DataGridPro';
import DeletePopup from '../../ui-kit/components/DeletePopup';
import TabsV2 from '../../ui-kit/components/TabsV2/TabsV2';
import TerminalController from '../../views/settings/terminals/TerminalController';
import FormDialog from '../Ui/FormDialog';

export const ManageEldIntegration = ({
  onManageTabChanged,
  selectedTab,
  unlinkSelectedDriver,
  linkDriver,
  updateDriverList,
  linkTractor,
  updateTractorList,
  unlinkSelectedTractor,
  allTractorData,
  allDriverData,
  setCheckedTractors,
  checkedTractors,
  isMappedTractor,
  setIsMappedTractor,
  setCheckedDrivers,
  checkedDrivers,
  isMappedDriver,
  setIsMappedDriver,
  getDriverDetails,
  cardData,
  getTractorDetails,
  tractorsFiltersFormConfig,
  tractorDefaultFilterValue,
  handleOnChangeTractorCb,
  driverFiltersFormConfig,
  driverDefaultFilterValue,
  handleOnChangeDriverCb,
  handleTractorScroll,
  handleDriverScroll,
  totalDataInTable,
  setDriverData,
  setTractorData,
  renderDriver,
  setRenderDriver,
  renderTractor,
  setRenderTractor,
  axelePermissions,
  getProviderDetails,
}: IManageDataProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [titleText, setTitleText] = useState<string>('');
  const [actionLabel, setActionLabel] = useState<string>('');
  const [isUnlinkPopupOpen, setUnlinkPopup] = useState<boolean>(false);
  const [isLinkedValidation, setLinkedValidation] = useState<boolean>(false);
  const [linkValidationTitleMsg, setLinkValidationTitleMsg] =
    useState<string>('');
  const [linkValidationSubTitleMsg, setLinkValidationSubTitleMsg] =
    useState<string>('');
  const [selectedRowData, setSelectedRowData] = useState<Array<IRowData>>([]);
  const [driverToUnlink, selectDriverToUnlink] = useState<Array<IRowData>>([]);
  const [driverIdLink, selectDriverIdLink] = useState<string>('');
  const [eldDriverIdLink, selectEldDriverIdLink] = useState<string>('');
  const [tractorIdLink, selectTractorIdLink] = useState<string>('');
  const [eldTractorIdLink, selectEldTractorIdLink] = useState<string>('');
  const [tractorToUnlink, selectTractorToUnlink] = useState<Array<IRowData>>(
    []
  );
  const [rowActualData, setRowActualData] = useState<Array<IRowActualData>>([]);
  const [terminalObject, setTerminalObject] = useState<ITerminals | null>(null);
  const [rowValues, setRowValues] = useState<IRowDetails | null>(null);
  const [tableDriverData, setTableDriverData] = useState<Array<IRowData>>([]);
  const [tableTractorData, setTableTractorData] = useState<Array<IRowData>>([]);

  const { terminals } = TerminalController.instance();

  useEffect(() => {
    frameDriverData();
    if (selectedTab === 'Driver' && checkedDrivers.length <= 0) {
      setRowActualData([]);
    }
  }, [allDriverData || selectedTab, renderDriver]);
  useEffect(() => {
    (checkedTractors.length <= 0 || checkedDrivers.length <= 0) &&
      setSelectedRowData([]);
    if (selectedTab === 'Tractor' && checkedTractors.length <= 0) {
      setRowActualData([]);
    }
    frameTractorData();
  }, [allTractorData || selectedTab, renderTractor]);

  useEffect(() => {
    setCheckedDrivers([]);
    setCheckedTractors([]);
    setSelectedRowData([]);
  }, []);

  /**
   * Validates to check if the multi-select create Tractor has VIN
   * If VIN is empty for the selected Tractors, error message is shown
   * @param {Boolean} isTriggerCall If true, triggers API call to create tractor for the selected rows only if the selected rows have VIN specified
   */
  const validateVinAndOpenDialog = (isTriggerCall: boolean) => {
    const checkIfVINPresent = rowActualData.every(
      (el) => el.eldTractorVin != null && el.eldTractorVin.trim() != ''
    );
    if (checkIfVINPresent && isTriggerCall) {
      setOpenDialog(false);
      createTractor({});
      return;
    }
    if (checkIfVINPresent) {
      setOpenDialog(true);
      return;
    }
    RootStoreInstence.setNotificationType({
      type: 'FAILURE',
      serviceName: 'tractorVINMissing',
    });
  };
  const callFormDialog = () => {
    const isTerminalEnabled = terminals && terminals?.length > 0 ? true : false;
    const selectedEntityCount = rowActualData?.length;
    if (isTerminalEnabled) {
      if (selectedTab === 'Tractor' && selectedEntityCount > 1) {
        validateVinAndOpenDialog(false);
        return;
      }
      setOpenDialog(true);
      return;
    }
    if (selectedTab === 'Driver' && selectedEntityCount > 1) {
      setOpenDialog(false);
      createDriver({});
      return;
    }
    if (selectedTab === 'Tractor' && selectedEntityCount > 1) {
      validateVinAndOpenDialog(true);
      return;
    }
    setOpenDialog(true);
  };

  const openCreatePopup = () => {
    callFormDialog();
    setActionLabel('Create');
    selectedTab === 'Driver'
      ? setTitleText('Create New Driver')
      : setTitleText('Create New Tractor');
    getTerminalObject(rowActualData[0]?.terminalId);
    setRowValues(null);
  };
  const closeDialog = (fromClose = true) => {
    if (fromClose) {
      selectedTab === 'Driver'
        ? getDriverDetails(cardData)
        : getTractorDetails(cardData);
    }
    setOpenDialog(false);
    setRowValues(null);
  };
  const createDriver = async (driverDetails: IDriverCredentials) => {
    if (rowValues || selectedRowData.length === 1) {
      const request = new CreateDriverRequest();
      request.firstname = driverDetails.firstname;
      request.lastname = driverDetails.lastname;
      request.eldId = driverDetails.id;
      request.email = driverDetails.email;
      request.axeleDriverName = driverDetails.lastname
        ? driverDetails.firstname + ' ' + driverDetails.lastname
        : driverDetails.firstname;
      request.axeleDriverEmail = driverDetails.email;
      request.providerId = rowValues
        ? rowValues.providerId
        : rowActualData[0]?.providerId;
      request.providerName = rowValues
        ? rowValues.providerName
        : rowActualData[0]?.providerName;
      request.terminalId = driverDetails.terminalId?.id;
      request.credentialId = rowValues
        ? rowValues.credentialId
        : rowActualData[0]?.credentialId;
      const allDriverDataCopy = allDriverData;
      try {
        const res = await eldService.createDriver(request);
        closeDialog(false);
        setCheckedDrivers([]);
        allDriverData.map((el: any, index: number) => {
          res?.drivers.map((item: any) => {
            if (el.id === item.id) {
              allDriverDataCopy[index] = item;
            }
          });
        });
        setDriverData(allDriverDataCopy);
        setRenderDriver((prev: number) => prev + 1);
        getProviderDetails();
      } catch {
        closeDialog(false);
      }
    } else if (selectedRowData.length > 1 && !rowValues) {
      const request = new CreateMultipleDriversRequest();
      request.terminalId = driverDetails.terminalId?.id;
      request.credentialId =
        selectedRowData && selectedRowData[0]?.credentialId;
      const driverIds = selectedRowData.map((el) => el.id);
      request.eldDriverIds = driverIds;
      const allDriverDataCopy = allDriverData;
      try {
        const res = await eldService.createMultipleDrivers(request);
        closeDialog(false);
        setCheckedDrivers([]);
        allDriverData.map((el: any, index: number) => {
          res?.drivers.map((item: any) => {
            if (el.id === item.id) {
              allDriverDataCopy[index] = item;
            }
          });
        });
        setDriverData(allDriverDataCopy);
        setRenderDriver((prev: number) => prev + 1);
        getProviderDetails();
      } catch {
        closeDialog(false);
      }
    }
  };
  const createTractor = async (tractorDetails: any) => {
    if (rowValues || selectedRowData.length === 1) {
      const request = new CreateTractorRequest();
      request.axeleTractorName = tractorDetails?.axeleTractorName!;
      request.axeleTractorVin = tractorDetails?.axeleTractorVin!;
      if (selectedRowData.length === 0) {
        request.organizationId = rowValues?.organizationId!;
        request.credentialId = rowValues?.credentialId!;
        request.id = rowValues?.id!;
        request.terminalId = tractorDetails?.terminalId?.id;
      } else {
        request.organizationId = selectedRowData?.[0]?.organizationId!;
        request.credentialId = selectedRowData?.[0]?.credentialId!;
        const eldTractorRowDataIds = selectedRowData.map(
          (eldTractorRowData: any) => eldTractorRowData.id
        );
        request.id = eldTractorRowDataIds[0];
        request.terminalId = tractorDetails?.terminalId?.id;
      }
      const allTractorDataCopy = allTractorData;
      try {
        const res = await eldService.createTractor(request);
        closeDialog(false);
        setCheckedTractors([]);
        allTractorDataCopy.map((el: any, index: number) => {
          res?.tractors.map((item: any) => {
            if (el.id === item.id) {
              allTractorDataCopy[index] = item;
            }
          });
        });
        setTractorData(allTractorDataCopy);
        setRenderTractor((prev) => prev + 1);
        getProviderDetails();
      } catch {
        closeDialog(false);
      }
    } else if (selectedRowData.length > 1 && !rowValues) {
      const request = new CreateMultipleTractorRequest();
      (request.organizationId = selectedRowData?.[0]?.organizationId),
        (request.credentialId = selectedRowData?.[0]?.credentialId);
      const ids = selectedRowData.map((el: any) => el.id);
      request.eldTractorIds = ids;
      request.terminalId = tractorDetails?.terminalId?.id;
      const allTractorDataCopy = allTractorData;
      try {
        const res = await eldService.createMultipleTractor(request);
        closeDialog(false);
        setCheckedTractors([]);
        allTractorData.map((el: any, index: number) => {
          res?.tractors.map((item: any) => {
            if (el.id === item.id) {
              allTractorDataCopy[index] = item;
            }
          });
        });
        setTractorData(allTractorDataCopy);
        setRenderTractor((prev) => prev + 1);
        getProviderDetails();
      } catch {
        closeDialog(false);
      }
    }
  };
  const createNewDriverPopup = (rowDetails: IRowDetails) => {
    openCreatePopup();
    setRowValues(rowDetails);
  };
  const createNewTractor = (rowDetails: IRowDetails) => {
    openCreatePopup();
    setRowValues(rowDetails);
  };

  const tractorTableColumns = [
    { field: 'ELDTractorName', headerName: 'ELDTractor #', flex: 1 },
    {
      field: 'AxeleTractorName',
      headerName: 'LoadOps Tractor #',
      flex: 1,
      renderCell: (params: {
        row: { AxeleTractorName: string; ELDTractorName: string };
      }) => {
        return (
          <>
            <AxeleTractorMapping
              rowDetails={params.row}
              defaultValue={
                params.row.AxeleTractorName
                  ? { tractorName: params.row.AxeleTractorName }
                  : { tractorName: 'Select to Map' }
              }
              linkTractor={linkSelectedTractorValidate}
              createNewTractor={createNewTractor}
              showCreateTractorBtn={axelePermissions?.showAddTractors}
            />
          </>
        );
      },
    },
    { field: 'ELDTractorVIN', headerName: 'ELD Tractor VIN', flex: 1 },
    { field: 'AxeleVIN', headerName: 'LoadOps VIN', flex: 1 },
    { field: 'Terminal', headerName: 'Terminal', flex: 1 },
  ];

  const driverTableColumns = [
    {
      field: 'EldDriverName',
      headerName: 'ELD Driver Name',
      flex: 1,
    },
    {
      field: 'AxeleDriverName',
      headerName: 'LoadOps Driver Name',
      flex: 1,
      renderCell: (params: {
        row: { AxeleDriverName: string; AxeleId: number };
      }) => {
        return (
          <>
            <AxeleDriverMapping
              rowDetails={params.row}
              defaultValue={
                params.row.AxeleDriverName
                  ? { driverName: params.row.AxeleDriverName }
                  : { driverName: 'Select to Map' }
              }
              linkDriver={linkSelectedDriverValidate}
              createNewDriverPopup={createNewDriverPopup}
              showCreateDriverBtn={axelePermissions?.showAddDrivers}
            />
          </>
        );
      },
    },
    { field: 'EldId', headerName: 'ELD ID', flex: 1 },
    { field: 'AxeleId', headerName: 'LoadOps ID', flex: 1 },
    { field: 'EldEmail', headerName: 'ELD Email', flex: 1 },
    { field: 'AxeleEmail', headerName: 'LoadOps Email', flex: 1 },
    { field: 'Terminal', headerName: 'Terminal', flex: 1 },
  ];

  const contentRenderer = () => {
    if (selectedTab === 'Driver') {
      return <CreateDriverForm formData={rowActualData} />;
    } else {
      return <CreateTractorForm formData={rowActualData} />;
    }
  };

  const openUnlinkPopup = (): void => {
    selectedTab === 'Driver'
      ? selectDriverToUnlink(selectedRowData)
      : selectTractorToUnlink(selectedRowData);
    setUnlinkPopup(true);
  };

  const closeLinkedValidationPopup = (): void => {
    setLinkedValidation(false);
  };

  const closeUnlinkPopup = (): void => {
    setUnlinkPopup(false);
  };

  const linkSelectedTractorValidate = async (
    eldId: string,
    tractorId: number
  ) => {
    //Validate Link
    const request = new LinkTractorValidateRequest();
    request.axeleTractorId = tractorId;
    try {
      const res = await eldService.linkTractorValidate(request);
      if (res?.linked) {
        const localTitleMessage = res?.titleMessage || '';
        const localSubTitleMessage = res?.subTitleMessage || '';
        setLinkValidationTitleMsg(localTitleMessage);
        setLinkValidationSubTitleMsg(localSubTitleMessage);
        selectTractorIdLink(tractorId.toString());
        selectEldTractorIdLink(eldId);
        setLinkedValidation(true);
        return;
      }
      setLinkedValidation(false);
      linkTractor(eldId, tractorId);
    } catch {
      setLinkedValidation(false);
    }
  };

  const linkSelectedDriverValidate = async (
    eldId: string,
    driverId: number
  ) => {
    //Validate Link
    const request = new LinkDriverValidateRequest();
    request.axeleDriverId = driverId;
    try {
      const res = await eldService.linkDriverValidate(request);
      if (res?.linked) {
        const localTitleMessage = res?.titleMessage || '';
        const localSubTitleMessage = res?.subTitleMessage || '';
        setLinkValidationTitleMsg(localTitleMessage);
        setLinkValidationSubTitleMsg(localSubTitleMessage);
        selectDriverIdLink(driverId.toString());
        selectEldDriverIdLink(eldId);
        setLinkedValidation(true);
        return;
      }
      setLinkedValidation(false);
      linkDriver(eldId, driverId);
    } catch {
      setLinkedValidation(false);
    }
  };

  const linkSelectedDriver = (): void => {
    linkDriver(eldDriverIdLink, driverIdLink);
    setLinkedValidation(false);
  };

  const linkSelectedTractor = (): void => {
    linkTractor(eldTractorIdLink, tractorIdLink);
    setLinkedValidation(false);
  };

  const unlinkDriver = (): void => {
    unlinkSelectedDriver(driverToUnlink);
    setUnlinkPopup(false);
  };

  const unlinkTractor = (): void => {
    unlinkSelectedTractor(tractorToUnlink);
    setUnlinkPopup(false);
  };

  const frameDriverData = () => {
    const td: Array<IRowData> = [];
    if (allDriverData?.length > 0) {
      allDriverData?.forEach((el: IRowActualData) => {
        const mappedData = mapDriver(el);
        td.push(mappedData);
      });
    }
    setTableDriverData(td);
    setTableTractorData([]);
  };

  const frameTractorData = () => {
    const td: Array<IRowData> = [];
    if (allTractorData?.length > 0) {
      allTractorData?.forEach((el: IRowActualData) => {
        const mappedData = mapTractor(el);
        td.push(mappedData);
      });
    }
    setTableTractorData(td);
    setTableDriverData([]);
  };

  const mapDriver = (el: IRowActualData) => {
    const fName = el.firstname;
    const lName = el.lastname;
    return {
      id: el.id,
      AxeleDriverName: el.axeleDriverName,
      EldDriverName: fName && lName ? fName + ' ' + lName : fName,
      EldDriverFirstName: el.firstname,
      EldDriverLastName: el.lastname ? el.lastname : '',
      EldId: el.eldId,
      AxeleId: el.axeleDriverId,
      EldEmail: el.email ? el.email : '-',
      EldActualEmail: el.email,
      AxeleEmail: el.axeleDriverEmail ? el.axeleDriverEmail : '-',
      AxeleActualEmail: el.axeleDriverEmail,
      Terminal: el.terminalId
        ? terminals &&
          terminals?.length > 0 &&
          terminals.filter((it) => it.id === el.terminalId)[0].name
        : '-',
      credentialId: el.credentialId,
      providerId: el.providerId,
      providerName: el.providerName,
    };
  };

  const mapTractor = (el: IRowActualData) => {
    return {
      id: el.id,
      ELDTractorName: el.tractorName,
      AxeleTractorName: el.axeleTractorName,
      ELDTractorVIN: el.eldTractorVin ? el.eldTractorVin : '-',
      AxeleVIN: el.axeleTractorVin ? el.axeleTractorVin : '-',
      Terminal: el.terminalId
        ? terminals &&
          terminals?.length > 0 &&
          terminals.filter((it) => it.id === el.terminalId)[0]?.name
        : '-',
      credentialId: el.credentialId,
      organizationId: el.organizationId,
    };
  };

  const selectRowData = (ids: string[]) => {
    setCheckedTractors(ids);
    setCheckedDrivers(ids);
    const rowData: Array<IRowData> = [],
      rowFullData: Array<IRowActualData> = [];
    ids.forEach((data: string) => {
      if (selectedTab === 'Driver') {
        tableDriverData.forEach((item: IRowData) => {
          if (data === item.id) {
            rowData.push(item);
          }
        });
        allDriverData?.forEach((item: IRowActualData) => {
          if (data === item.id) {
            rowFullData.push(item);
          }
        });
      } else {
        tableTractorData.forEach((item: IRowData) => {
          if (data === item.id) {
            rowData.push(item);
          }
        });
        allTractorData?.forEach((item: IRowActualData) => {
          if (data === item.id) {
            rowFullData.push(item);
          }
        });
      }
    });
    setSelectedRowData(rowData);
    setRowActualData(rowFullData);
    checkIfSelectionIsMapped(rowFullData);
  };

  const checkIfSelectionIsMapped = (data: any) => {
    const isSelectionMapped = data.every(
      (val: any) => val.axeleTractorName || val.axeleDriverName
    );
    setIsMappedTractor(isSelectionMapped);
    setIsMappedDriver(isSelectionMapped);
  };

  const getTerminalObject = (terminalId: string | null | Array<string>) => {
    const allTerminals = Promise.resolve(terminals);
    allTerminals.then((terminalObj: any) => {
      terminalObj &&
        terminalObj.map((el: any) => {
          if (terminalId === el.id) {
            setTerminalObject(el);
          }
        });
    });
  };

  let showCreateDriver = selectedRowData.every((val) => val.AxeleId === null);
  let showCreateTractor = selectedRowData.every(
    (val) => val.AxeleTractorName === null
  );
  if (selectedRowData && selectedRowData.length <= 0) {
    showCreateDriver = false;
    showCreateTractor = false;
  }

  let deletePopupTitle = '',
    deletePopupSubtitle = '';
  if (selectedTab === 'Driver') {
    deletePopupTitle =
      'Are you sure you want to un-link the selected driver(s)?';
    deletePopupSubtitle =
      "Please note that once un-linked, HOS and location info won't be populated from ELD.";
  } else {
    deletePopupTitle =
      'Are you sure you want to un-link the selected tractor(s)?';
    deletePopupSubtitle =
      "Please note that once un-linked, location info won't be populated from ELD.";
  }

  let DialogData: any = {},
    validationSchema: any = {};
  if (selectedTab === 'Driver') {
    DialogData = {
      firstname: rowValues
        ? rowValues?.EldDriverFirstName
        : rowActualData[0]?.firstname,
      lastname: rowValues
        ? rowValues?.EldDriverLastName
        : rowActualData[0]?.lastname,
      email: rowValues ? rowValues?.EldActualEmail : rowActualData[0]?.email,
      id: rowValues ? rowValues?.id : rowActualData[0]?.id,
      terminalId: terminals?.[0],
      credentialId: rowValues?.credentialId,
      providerId: rowValues?.providerId,
      providerName: rowValues?.providerName,
    };
    validationSchema = createDriverValidationSchema;
  } else {
    DialogData = {
      axeleTractorName: rowValues
        ? rowValues?.ELDTractorName
        : rowActualData[0]?.tractorName,
      axeleTractorVin: rowValues
        ? rowValues?.ELDTractorVIN
        : rowActualData[0]?.eldTractorVin,
      terminalId: terminals?.[0],
    };
    validationSchema = createTractorValidationSchema;
  }

  const tractorBulkActionConfig = [
    {
      iconName: 'LinkOff',
      iconSource: 'MUIcons',
      label: 'Unlink',
      onClick: openUnlinkPopup,
      disabled:
        (axelePermissions?.showRemoveTractors && !isMappedTractor) ||
        (!axelePermissions?.showRemoveTractors && isMappedTractor) ||
        (!axelePermissions?.showRemoveTractors && !isMappedTractor)
          ? true
          : false,
    },
    {
      iconName: 'AddCircleOutlined',
      iconSource: 'MUIcons',
      label: 'Create Tractor',
      onClick: openCreatePopup,
      disabled:
        (axelePermissions?.showAddTractors && !showCreateTractor) ||
        (!axelePermissions?.showAddTractors && showCreateTractor) ||
        (!axelePermissions?.showAddTractors && !showCreateTractor)
          ? true
          : false,
    },
  ];
  const driverBulkActionConfig = [
    {
      iconName: 'LinkOff',
      iconSource: 'MUIcons',
      label: 'Unlink',
      onClick: openUnlinkPopup,
      disabled:
        (axelePermissions?.showRemoveDrivers && !isMappedDriver) ||
        (!axelePermissions?.showRemoveDrivers && isMappedDriver) ||
        (!axelePermissions?.showRemoveDrivers && !isMappedDriver)
          ? true
          : false,
    },
    {
      iconName: 'AddCircleOutlined',
      iconSource: 'MUIcons',
      label: 'Create Driver',
      onClick: openCreatePopup,
      disabled:
        (axelePermissions?.showAddDrivers && !showCreateDriver) ||
        (!axelePermissions?.showAddDrivers && showCreateDriver) ||
        (!axelePermissions?.showAddDrivers && !showCreateDriver)
          ? true
          : false,
    },
  ];

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <Grid className="top-section">
        <Grid className="tabs-section">
          <TabsV2
            keyName="key"
            isDarkMode
            labelCentered
            tabs={manageIntegrationTabs}
            selectedTab={selectedTab}
            setSelectedTab={(tab) => {
              onManageTabChanged(tab.key as string);
            }}
          />
        </Grid>
      </Grid>

      <Box
        className="buttons-group"
        sx={{ position: 'absolute', right: '5px', top: 0, zIndex: 1 }}
      >
        {selectedTab === 'Driver' ? (
          <Grid container>
            <ButtonImproved
              label="Update Driver List"
              variant="outlined"
              size="small"
              onClick={updateDriverList}
              styleProps={{
                color: 'primary.contrast',
                borderColor: 'primary.contrast',
              }}
            />
          </Grid>
        ) : (
          <Grid container>
            <ButtonImproved
              label="Update Tractor List"
              variant="outlined"
              size="small"
              onClick={updateTractorList}
              styleProps={{
                color: 'primary.contrast',
                borderColor: 'primary.contrast',
              }}
            />
          </Grid>
        )}
      </Box>

      {selectedTab === 'Driver' && (
        <CommonDataGrid
          gridCustomeStyle={{ height: '85%' }}
          tableData={tableDriverData}
          tableColumnsData={driverTableColumns}
          checkboxSelection={true}
          selectionModel={checkedDrivers}
          rowHeight={43}
          filterColumns={driverFiltersFormConfig}
          defaultFilterValue={driverDefaultFilterValue}
          selectRow={selectRowData}
          settingsPanel={false}
          displayFilters={false}
          disableSelectionOnClick={true}
          bulkAction={
            checkedDrivers?.length > 0 ? driverBulkActionConfig : undefined
          }
          onPageFilterChange={handleOnChangeDriverCb}
          handleScroll={handleDriverScroll}
          totalDataInTable={totalDataInTable}
        />
      )}
      {selectedTab === 'Tractor' && (
        <CommonDataGrid
          gridCustomeStyle={{ height: '85%' }}
          tableData={tableTractorData}
          tableColumnsData={tractorTableColumns}
          checkboxSelection={true}
          selectionModel={checkedTractors}
          rowHeight={43}
          filterColumns={tractorsFiltersFormConfig}
          defaultFilterValue={tractorDefaultFilterValue}
          selectRow={selectRowData}
          settingsPanel={false}
          displayFilters={false}
          disableSelectionOnClick={true}
          bulkAction={
            checkedTractors?.length > 0 ? tractorBulkActionConfig : undefined
          }
          onPageFilterChange={handleOnChangeTractorCb}
          handleScroll={handleTractorScroll}
          totalDataInTable={totalDataInTable}
        />
      )}

      {openDialog && (
        <FormDialog
          data={DialogData}
          titleText={titleText}
          actionLabel={actionLabel}
          open={openDialog}
          onAction={selectedTab === 'Driver' ? createDriver : createTractor}
          handleClose={closeDialog}
          contentRenderer={contentRenderer}
          validationSchema={validationSchema}
          disableActionBtnByDefault={false}
        />
      )}

      <DeletePopup
        open={isUnlinkPopupOpen}
        onClose={closeUnlinkPopup}
        onAction={selectedTab === 'Driver' ? unlinkDriver : unlinkTractor}
        title={deletePopupTitle}
        subtitle={deletePopupSubtitle}
        buttonText={'Unlink'}
        width="20%"
      />

      <DeletePopup
        open={isLinkedValidation}
        onClose={closeLinkedValidationPopup}
        onAction={
          selectedTab === 'Driver' ? linkSelectedDriver : linkSelectedTractor
        }
        title={linkValidationTitleMsg}
        subtitle={linkValidationSubTitleMsg}
        buttonText={'Link'}
        width="20%"
      />
    </Box>
  );
};
