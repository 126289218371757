import {
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { Moment } from 'moment';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { geoBusService } from '../../../../../../api';
import { ServiceError } from '../../../../../../api/interfaces';
import AppointmentConfirmation from '../../../../../../components_v2/generic/appointment-confirmation';
import {
  AddressDTO,
  ContactSummaryDTO,
  PreferenceType,
} from '../../../../../../models';
import { DistanceResponse } from '../../../../../../models/DTOs/GeoBus/GeoBus';
import { useRootStore } from '../../../../../../store/root-store/rootStateContext';
import { AppointmentConfirmationOptions } from '../../../../../../subPages/loadsList/CreateLoad/constants/fieldOptions';
import { AppointmentConfirmationType } from '../../../../../../subPages/loadsList/CreateLoad/types/requests';
import {
  StopSolutionV3StopType,
  TripStopStatusEnum,
} from '../../../../../../ui-kit/components/TripPlan';
import { getLocationListByNameAddressSearch } from '../../../../../../views/operations/contacts/utils/location';
import { ErrorMessage } from '../../../../../ErrorMessage';
import GeoCodingModelPopup from '../../../../../GeoCoding/GeoCodingModel';
import { isSamePoint } from '../../../../../LoadSharedComponents/utils';
import { FormAddress } from '../../../../../Ui/AddressField';
import { addressType } from '../../../../../Ui/AddressField/constants';
import FormDateTimePicker from '../../../../../Ui/FormDateTimePicker/FormDateTimePicker';
import { SingleAutocompleteForm } from '../../../../../Ui/SingleAutocomplete';
import Switch from '../../../../../Ui/Switcher';
import TextField from '../../../../../Ui/TextField/TextField';
import { useThemeResponsive } from '../../../../../hooks/useThemeResponsive';
import { getRoundedRevenueShare, routeInfo2DistancePayload } from '../../utils';

const GroupName: FC<PropsWithChildren<{}>> = ({ children }) => (
  <Typography component="div" sx={{ color: 'primary.main', fontSize: 16 }}>
    {children}
  </Typography>
);

const Divider: FC<{ horizontal?: boolean }> = ({ horizontal }) => {
  const horizontalStyle: SxProps = horizontal
    ? {
        width: '100%',
        height: '2px',
      }
    : {};
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        height: '100%',
        width: '2px',
        ...horizontalStyle,
      }}
    />
  );
};

export interface Appointment {
  id?: number;
  fixedAppointment?: boolean;
  startDate?: Moment | null;
  endDate?: Moment | null;
  handlingTime?: string;
  revenueShare?: string;
  driverAssist?: boolean;
  stopStatus?: TripStopStatusEnum;
  tripId?: string;
  appointmentConfirmation?: AppointmentConfirmationType;
}

export interface Business {
  contacts: ContactSummaryDTO[];
  addressDTO: AddressDTO;
  id: number;
  locationName: string;
  notes: string;
  avgHandlingTime: number;
  timezone: string;
  locationPreference: PreferenceType;
  locationDisplayName: string;
}

export interface TripStopFormData {
  appointmentStartDate: Moment | null;
  appointmentEndDate: Moment | null;
  id: string;
  location: {
    lat: number;
    lng: number;
  };
  activityType: string;
}

export interface RelayStopFormData {
  type: 'pickup' | 'dropOff';
  stopType?: string;
  id?: number;
  businessName:
    | (Omit<Business, 'id'> & {
        id: number | null;
      })
    | null;
  address: AddressDTO;
  dropOff: Appointment;
  pickup: Appointment;
  legStopId: number;
  driverGroupId?: string;
  terminalId?: string;
  loadId: string;
  stops: TripStopFormData[];
  driverId?: string;
  contact: {
    id?: string;
    countryCode?: string;
    email?: string;
    extension?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
  };
  refNumber?: string | number | null;
  noteDetails?: {
    note: string;
    userId: string;
    updateTime: Moment;
  } | null;
  seqNumber?: string;
  tripStatus?: string;
  stopStatus?: TripStopStatusEnum;
  stopCategory?: StopSolutionV3StopType;
  appointmentConfirmation?: 'YES' | 'NO' | 'CONFIRMED';
}

export const RelayStopForm: FC<{
  nameStart?: string;
  datesDependencyError?: string;
  isViewMode?: boolean;
  isRevenueShareDisabled?: boolean;
  showRequiredSign?: boolean;
  onStopAddressChange?: () => Promise<void>;
  onRevenueShareChange?: (name: string, value: number) => void;
  isStartEndDateRequired?: boolean;
  onUpdateLoaderStatus?: (flag: boolean) => void;
}> = ({
  nameStart = '',
  datesDependencyError = '',
  isViewMode = false,
  isRevenueShareDisabled = false,
  showRequiredSign = true,
  isStartEndDateRequired = true,
  onStopAddressChange,
  onRevenueShareChange,
  onUpdateLoaderStatus,
}) => {
  const { isMobile } = useThemeResponsive();
  const {
    control,
    watch,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [
    businessName,
    address,
    dropOffFixedAppointment,
    pickupFixedAppointment,
    pickupAppointmentConfirmation,
    dropOffAppointmentConfirmation,
  ] = watch([
    `${nameStart}businessName`,
    `${nameStart}address`,
    `${nameStart}dropOff.fixedAppointment`,
    `${nameStart}pickup.fixedAppointment`,
    `${nameStart}.pickup.appointmentConfirmation`,
    `${nameStart}.dropOff.appointmentConfirmation`,
  ]);

  const { getCompanyPreferences } = useRootStore();
  const defaultTimezone =
    address?.timezone || getCompanyPreferences?.timezone || '';

  const selectedBusiness = useRef<Business | null>(null);
  const isTyped = useRef(false);

  const handleBusinessNameChange = (newBusinessName: any) => {
    if (newBusinessName) {
      if (newBusinessName?.id) {
        isTyped.current = false;
        selectedBusiness.current = newBusinessName;
        setValue(`${nameStart}address`, newBusinessName.addressDTO);
        setValue(
          `${nameStart}dropOff.handlingTime`,
          newBusinessName.avgHandlingTime || 120
        );
        setValue(
          `${nameStart}pickup.handlingTime`,
          newBusinessName.avgHandlingTime || 120
        );
        if (
          onStopAddressChange &&
          newBusinessName.addressDTO?.address &&
          !isSamePoint(newBusinessName.addressDTO.center, address?.center)
        ) {
          onStopAddressChange();
        }
      } else if (
        newBusinessName?.locationDisplayName ===
          selectedBusiness.current?.locationDisplayName &&
        selectedBusiness.current
      ) {
        // Typed
        isTyped.current = true;
        setValue(`${nameStart}businessName`, selectedBusiness.current);
        setValue(`${nameStart}address`, selectedBusiness.current.addressDTO);
      }
    }
  };

  // NOTE: In case of any questions related to selectedBusiness,isTyped refer to Anh Tran
  const handleAddressChange = (newAddress: any) => {
    if (newAddress?.address !== address?.address) {
      if (businessName?.id) {
        if (isTyped.current) {
          setError('businessName', {
            message: 'Business Name is duplicated',
          });
        } else {
          setValue(`${nameStart}businessName`, null);
        }
      }
      if (
        onStopAddressChange &&
        newAddress?.address &&
        !isSamePoint(newAddress.center, address?.center)
      ) {
        onStopAddressChange();
      }
    }
  };

  useEffect(() => {
    if (address && !getValues(`${nameStart}id`)) {
      const getDistances = async () => {
        try {
          onUpdateLoaderStatus?.(true);
          const payload = routeInfo2DistancePayload(
            getValues(`${nameStart}stops`),
            getValues(`${nameStart}legStopId`),
            address
          );

          const calculate = (distances: DistanceResponse[]): number =>
            distances.reduce<number>(
              (result, data) => (result += data.distance),
              0
            );
          const response = await geoBusService.getDistance(payload);
          onUpdateLoaderStatus?.(false);
          if (!(response instanceof ServiceError) && response) {
            const total = calculate(response);
            const [{ distance: revenue1 }] = response;
            const revenue1Percent =
              total === 0
                ? 50
                : getRoundedRevenueShare((revenue1 / total) * 100);
            setValue(
              `${nameStart}dropOff.revenueShare`,
              revenue1Percent.toString()
            );
            setValue(
              `${nameStart}pickup.revenueShare`,
              getRoundedRevenueShare(100 - revenue1Percent).toString()
            );
          }
        } catch (error) {
          onUpdateLoaderStatus?.(false);
        }
      };
      getDistances();
    }
  }, [address?.center]);

  const isDisabledDropOff =
    isViewMode ||
    getValues(`${nameStart}dropOff.stopStatus`) ===
      TripStopStatusEnum.COMPLETED;

  const confirmationCallPopupState = usePopupState({
    variant: 'popover',
    popupId: 'pickupConfirmationCallPopup',
  });

  const renderDropOffConfirmationIcon = () => {
    const confirmationCallStateIcon = (
      <AppointmentConfirmation
        status={dropOffAppointmentConfirmation ?? 'NO'}
      />
    );
    return (
      <Box>
        <IconButton
          size="small"
          sx={{ flexShrink: 0 }}
          {...bindTrigger(confirmationCallPopupState)}
        >
          {confirmationCallStateIcon}
        </IconButton>
      </Box>
    );
  };
  const confirmationPickupCallPopupState = usePopupState({
    variant: 'popover',
    popupId: 'pickupConfirmationCallPopup',
  });

  const renderPickupConfirmationIcon = () => {
    const confirmationCallStateIcon = (
      <AppointmentConfirmation status={pickupAppointmentConfirmation ?? 'NO'} />
    );
    return (
      <Box>
        <IconButton
          size="small"
          sx={{ flexShrink: 0 }}
          {...bindTrigger(confirmationPickupCallPopupState)}
        >
          {confirmationCallStateIcon}
        </IconButton>
      </Box>
    );
  };

  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Box sx={{ mb: 10 }}>
        <GroupName>{t('RelayStopFormConnectionPoint')}</GroupName>
        <Grid sx={{ mt: 0 }} container spacing={4}>
          <Grid item xs={isMobile ? 12 : 6}>
            <SingleAutocompleteForm
              defaultValue={null}
              control={control}
              onChangeCb={handleBusinessNameChange}
              name={`${nameStart}businessName`}
              fieldName="locationDisplayName"
              typedTextName="locationDisplayName"
              getOptions={(name, pageNumber) =>
                getLocationListByNameAddressSearch(name, pageNumber)
              }
              renderOption={{
                enableOptionSubtitle: true,
                renderOptionSubTitle: (option: any): JSX.Element => {
                  return (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '0.625rem',
                        fontWeight: 400,
                        lineHeight: 1.167,
                        color: 'text.primary',
                        padding: '5px 5px',
                      }}
                    >
                      {option.addressDTO?.fullAddress || ''}
                    </Typography>
                  );
                },
              }}
              label={t('RelayStopFormBusinessName')}
              sizes={{ xs: 12 }}
              disabled={isDisabledDropOff}
            />
          </Grid>

          <FormAddress
            locationData={address}
            label={t('RelayStopFormAddress')}
            control={control}
            name={`${nameStart}address`}
            sizes={{ xs: 12 }}
            onChangeCallback={handleAddressChange}
            required={showRequiredSign}
            includeTimezone={true}
            disabled={isDisabledDropOff}
            validateAddress={false}
          />
        </Grid>
        <GeoCodingModelPopup
          address={address}
          valueName={`${nameStart}address`}
          callBackAddress={(data: addressType) => {
            setValue(`${nameStart}address`, data);
          }}
        />
        <Divider horizontal />
        <Grid
          flexWrap="nowrap"
          container
          sx={{ mt: 3 }}
          spacing={2}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Grid flexGrow={1} item xs={12}>
            <GroupName>{t('RelayStopFormTrailerDropOffAppointment')}</GroupName>
            <Grid
              container
              sx={{ mt: 2 }}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                width="100%"
              >
                <Switch
                  control={control}
                  label={t('RelayStopFormFixedAppointment')}
                  subLabel={t('RelayStopFormFixedAppointmentSubLabel')}
                  name={`${nameStart}dropOff.fixedAppointment`}
                  sizes={{ xs: 12 }}
                  onChange={(checked) => {
                    if (checked) {
                      setValue(
                        `${nameStart}dropOff.endDate`,
                        getValues(`${nameStart}dropOff.startDate`)
                      );
                    }
                  }}
                  disabled={isDisabledDropOff}
                />
                {renderDropOffConfirmationIcon()}
                <Menu {...bindMenu(confirmationCallPopupState)}>
                  {AppointmentConfirmationOptions?.map((e) => (
                    <MenuItem
                      key={e?.value}
                      selected={dropOffAppointmentConfirmation == e.value}
                      onClick={() => {
                        setValue(`dropOff.appointmentConfirmation`, e.value, {
                          shouldDirty: true,
                        });
                        confirmationCallPopupState.close?.();
                      }}
                    >
                      <ListItemIcon>
                        <AppointmentConfirmation status={e.value} />
                      </ListItemIcon>
                      {e.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
              <FormDateTimePicker
                required={showRequiredSign && isStartEndDateRequired}
                control={control}
                name={`${nameStart}dropOff.startDate`}
                label={t('RelayStopFormStartDateTime')}
                sizes={{ xs: isMobile ? 12 : 6 }}
                onChangeCallback={(data) => {
                  if (getValues(`${nameStart}dropOff.fixedAppointment`)) {
                    setValue(`${nameStart}dropOff.endDate`, data);
                  }
                }}
                timezone={defaultTimezone}
                disabled={isDisabledDropOff}
                styleProps={{
                  ...(isMobile && {
                    width: '100%',
                  }),
                }}
              />
              <FormDateTimePicker
                required={showRequiredSign && isStartEndDateRequired}
                control={control}
                name={`${nameStart}dropOff.endDate`}
                label={t('RelayStopFormEndDateTime')}
                sizes={{ xs: isMobile ? 12 : 6 }}
                disabled={dropOffFixedAppointment || isDisabledDropOff}
                timezone={defaultTimezone}
                styleProps={{
                  ...(isMobile && {
                    width: '100%',
                  }),
                }}
              />
              {datesDependencyError ? (
                <Grid item>
                  <ErrorMessage
                    message={datesDependencyError}
                    styles={{ paddingLeft: '8px', marginTop: '-15px' }}
                  />
                </Grid>
              ) : null}
              <Grid
                container
                spacing={3}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Grid item xs={isMobile ? 12 : 6}>
                  <TextField
                    control={control}
                    name={`${nameStart}dropOff.handlingTime`}
                    label={t('RelayStopFormHandlingTime')}
                    required={showRequiredSign}
                    disabled={isDisabledDropOff}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <TextField
                    disabled={isRevenueShareDisabled || isDisabledDropOff}
                    control={control}
                    name={`${nameStart}dropOff.revenueShare`}
                    label={t('RelayStopFormRevenueShare')}
                    required={showRequiredSign}
                    onChangeCallback={(value: string) => {
                      if (!isNaN(+value)) {
                        if (+value <= 100)
                          if (typeof onRevenueShareChange === 'function') {
                            onRevenueShareChange('dropOff', +value);
                          } else {
                            setValue(
                              `${nameStart}pickup.revenueShare`,
                              (100 - +value).toString()
                            );
                          }
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Switch
                control={control}
                label={t('RelayStopFormDriverAssist')}
                subLabel={t('RelayStopFormDriverAssistSubLabel')}
                name={`${nameStart}dropOff.driverAssist`}
                sizes={{ xs: 12 }}
                style={{ marginBottom: 0 }}
                disabled={isDisabledDropOff}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid flexGrow={1} item xs={12}>
            <GroupName>{t('RelayStopFormTrailerPickupAppointment')}</GroupName>
            <Grid
              container
              sx={{
                mt: 2,
                width: '100%',
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                width="100%"
              >
                <Switch
                  control={control}
                  label={t('RelayStopFormFixedAppointment')}
                  subLabel={t('RelayStopFormFixedAppointmentSubLabel')}
                  name={`${nameStart}pickup.fixedAppointment`}
                  onChange={(checked) => {
                    if (checked) {
                      setValue(
                        `${nameStart}pickup.endDate`,
                        getValues(`${nameStart}pickup.startDate`)
                      );
                    }
                  }}
                  sizes={{ xs: 12 }}
                  disabled={isViewMode}
                />
                {renderPickupConfirmationIcon()}
                <Menu {...bindMenu(confirmationPickupCallPopupState)}>
                  {AppointmentConfirmationOptions?.map((e) => (
                    <MenuItem
                      key={e?.value}
                      selected={pickupAppointmentConfirmation == e.value}
                      onClick={() => {
                        setValue(`pickup.appointmentConfirmation`, e.value, {
                          shouldDirty: true,
                        });
                        confirmationPickupCallPopupState?.close();
                      }}
                    >
                      <ListItemIcon>
                        <AppointmentConfirmation status={e.value} />
                      </ListItemIcon>
                      {e.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
              <FormDateTimePicker
                control={control}
                name={`${nameStart}pickup.startDate`}
                label={t('RelayStopFormStartDateTime')}
                sizes={{ xs: isMobile ? 12 : 6 }}
                onChangeCallback={(data) => {
                  if (getValues(`${nameStart}pickup.fixedAppointment`)) {
                    setValue(`${nameStart}pickup.endDate`, data);
                  }
                }}
                timezone={defaultTimezone}
                required={showRequiredSign && isStartEndDateRequired}
                disabled={isViewMode}
                styleProps={{
                  ...(isMobile && {
                    width: '100%',
                  }),
                }}
              />
              <FormDateTimePicker
                control={control}
                name={`${nameStart}pickup.endDate`}
                label={t('RelayStopFormEndDateTime')}
                sizes={{ xs: isMobile ? 12 : 6 }}
                disabled={isViewMode || pickupFixedAppointment}
                timezone={defaultTimezone}
                required={showRequiredSign && isStartEndDateRequired}
                styleProps={{
                  ...(isMobile && {
                    width: '100%',
                  }),
                }}
              />
              {datesDependencyError ? (
                <Grid item>
                  <ErrorMessage
                    message={datesDependencyError}
                    styles={{ paddingLeft: '8px', marginTop: '-15px' }}
                  />
                </Grid>
              ) : null}
              <Grid container spacing={3}>
                <Grid item xs={isMobile ? 12 : 6}>
                  <TextField
                    control={control}
                    name={`${nameStart}pickup.handlingTime`}
                    required={showRequiredSign}
                    disabled={isViewMode}
                    label={t('RelayStopFormHandlingTime')}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                  <TextField
                    disabled={isRevenueShareDisabled || isViewMode}
                    control={control}
                    name={`${nameStart}pickup.revenueShare`}
                    label={t('RelayStopFormRevenueShare')}
                    required={showRequiredSign}
                    onChangeCallback={(value: string) => {
                      if (!isNaN(+value)) {
                        if (+value <= 100)
                          if (typeof onRevenueShareChange === 'function') {
                            onRevenueShareChange('pickup', +value);
                          } else {
                            setValue(
                              `${nameStart}dropOff.revenueShare`,
                              (100 - +value).toString()
                            );
                          }
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Switch
                control={control}
                label={t('RelayStopFormDriverAssist')}
                subLabel={t('RelayStopFormDriverAssistSubLabel')}
                name={`${nameStart}pickup.driverAssist`}
                disabled={isViewMode}
                sizes={{ xs: 12 }}
                style={{ marginBottom: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
};
