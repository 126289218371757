import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  CreatePaymentDTO,
  ExportPaymentPayload,
  GetPaginatedPaymentListParams,
  GetPaginatedPaymentListPayload,
  GetPaginatedPaymentListResponse,
  GetPaymentCountTotalResponse,
  GetPaymentOneRequest,
  GetPaymentOpenCreditRequest,
  GetPaymentOpenCreditResponse,
  GetUnpaidPaymentsQueryParams,
  InvoicePaymentDetails,
  PaginatedPaymentInvoiceList,
  PaginatedPaymentInvoiceListParams,
  PaginatedPaymentReferenceList,
  PaginatedPaymentReferenceListParams,
  PaginatedPaymentSeqNumberList,
  PaginatedPaymentSeqNumberListParams,
  PaymentDetailsDTO,
  PaymentMessageResponse,
  UpdatePaymentDTO,
  updatePaymentToInvoiceMappingtParams,
} from '../../models/DTOs/Payment/DTOs';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { docType } from '../../utils/Doc';
import { ServiceError } from '../interfaces';
import { IPaymentService } from '../interfaces/IPaymentService';
import { isAxiosErrorCancel } from './DispatchService';

const paymentApiPath = '/web/invoice/api/v1/payment';
const quickBookApiPath = '/web/quickBook/api/v2/invoice';
const invoiceApiPath = '/web/invoice/api/v2/invoice';

export const API_RESOURCE_URL = {
  payment: {
    createPayment: `${paymentApiPath}`,
    updatePayment: `${paymentApiPath}`,
    getPaymentOne: `${paymentApiPath}`,
    getInvoiceRemove: `${paymentApiPath}/remove-invoice-payment`,
    getPaginatedPaymentList: `${paymentApiPath}/get/list`,
    getPaginatedPaymentSeqNumberList: `${paymentApiPath}/seqnumber/list`,
    getPaginatedPaymentReferenceList: `${paymentApiPath}/reference/list`,
    getPaginatedPaymentInvoiceList: `${paymentApiPath}/invoice/list`, //@TODO : update real API
    deletePayment: `${paymentApiPath}`,
    exportToExcel: `${paymentApiPath}/export-to-excel`,
    getPaymentsTotal: `${paymentApiPath}/get/total`,
    getPaymentOpenCredit: `${paymentApiPath}/get/open-credit`,
  },
  quickBook: {
    syncBackPayments: `${quickBookApiPath}/sync/back`,
  },
  invoice: {
    getUnpaidInvoices: `${invoiceApiPath}/unpaid-invoices`,
  },
};

export class PaymentService extends IPaymentService {
  async getUnpaidInvoices(
    queryParams: GetUnpaidPaymentsQueryParams
  ): Promise<Array<InvoicePaymentDetails> | ServiceError> {
    try {
      const data = await httpClient.get<Array<InvoicePaymentDetails>>(
        API_RESOURCE_URL.invoice.getUnpaidInvoices,
        queryParams,
        InvoicePaymentDetails,
        true,
        true
      );
      return data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async createPayment(
    payload: CreatePaymentDTO
  ): Promise<PaymentDetailsDTO | ServiceError> {
    try {
      const data = await httpClient.post<PaymentDetailsDTO>(
        API_RESOURCE_URL.payment.createPayment,
        undefined,
        payload,
        PaymentDetailsDTO,
        true,
        undefined,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createPaymentManagement',
      });
      return data;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message:
            error?.message?.response?.data?.message ||
            error?.message?.response?.data,
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async updatePayment(
    payload: UpdatePaymentDTO
  ): Promise<PaymentDetailsDTO | ServiceError> {
    try {
      const data = await httpClient.put<PaymentDetailsDTO>(
        API_RESOURCE_URL.payment.updatePayment,
        undefined,
        payload,
        PaymentDetailsDTO,
        true,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updatePaymentManagement',
      });
      return data;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message:
            error?.message?.response?.data?.message ||
            error?.message?.response?.data,
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getPaginatedPaymentList(
    queryParams: GetPaginatedPaymentListParams,
    payload: GetPaginatedPaymentListPayload
  ): Promise<GetPaginatedPaymentListResponse | ServiceError> {
    try {
      const result = await httpClient.put<GetPaginatedPaymentListResponse>(
        API_RESOURCE_URL.payment.getPaginatedPaymentList,
        queryParams,
        payload,
        GetPaginatedPaymentListResponse,
        true,
        true
      );

      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getCountTotal(
    queryParams: GetPaginatedPaymentListParams,
    payload: GetPaginatedPaymentListPayload
  ): Promise<GetPaymentCountTotalResponse | ServiceError> {
    try {
      const result = await httpClient.put<GetPaymentCountTotalResponse>(
        API_RESOURCE_URL.payment.getPaymentsTotal,
        queryParams,
        payload,
        GetPaymentCountTotalResponse,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getPaymentOne(
    queryParams: GetPaymentOneRequest
  ): Promise<PaymentDetailsDTO | ServiceError | null> {
    try {
      const result = await httpClient.get<PaymentDetailsDTO>(
        API_RESOURCE_URL.payment.getPaymentOne,
        queryParams,
        PaymentDetailsDTO,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async exportToExcel(
    payload: ExportPaymentPayload
  ): Promise<docType | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        API_RESOURCE_URL.payment.exportToExcel,
        undefined,
        payload,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async deletePayment(
    queryParams: GetPaymentOneRequest
  ): Promise<PaymentMessageResponse | ServiceError> {
    try {
      const result = await httpClient.delete<PaymentMessageResponse>(
        API_RESOURCE_URL.payment.deletePayment,
        queryParams,
        undefined,
        PaymentMessageResponse,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deletePaymentManagement',
      });
      return result;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message:
            error?.message?.response?.data?.message ||
            error?.message?.response?.data,
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getPaymentOpenCredit(
    queryParams: GetPaymentOpenCreditRequest
  ): Promise<GetPaymentOpenCreditResponse | ServiceError> {
    try {
      const result = await httpClient.get<GetPaymentOpenCreditResponse>(
        API_RESOURCE_URL.payment.getPaymentOpenCredit,
        queryParams,
        GetPaymentOpenCreditResponse,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getPaginatedPaymentSeqNumberList(
    queryParams: PaginatedPaymentSeqNumberListParams
  ): Promise<PaginatedPaymentSeqNumberList | ServiceError> {
    try {
      const result = await httpClient.get<PaginatedPaymentSeqNumberList>(
        API_RESOURCE_URL.payment.getPaginatedPaymentSeqNumberList,
        queryParams,
        PaginatedPaymentSeqNumberList,
        undefined,
        true
      );
      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getPaginatedPaymentReferenceList(
    queryParams: PaginatedPaymentReferenceListParams
  ): Promise<PaginatedPaymentReferenceList | ServiceError> {
    try {
      const result = await httpClient.get<PaginatedPaymentReferenceList>(
        API_RESOURCE_URL.payment.getPaginatedPaymentReferenceList,
        queryParams,
        PaginatedPaymentReferenceList,
        undefined,
        true
      );
      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getPaginatedPaymentInvoiceList(
    queryParams: PaginatedPaymentInvoiceListParams
  ): Promise<PaginatedPaymentInvoiceList | ServiceError> {
    try {
      const result = await httpClient.get<PaginatedPaymentInvoiceList>(
        API_RESOURCE_URL.payment.getPaginatedPaymentInvoiceList,
        queryParams,
        PaginatedPaymentInvoiceList,
        undefined,
        true
      );
      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async updatePaymentToInvoiceMapping(
    queryParams: updatePaymentToInvoiceMappingtParams
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        API_RESOURCE_URL.payment.getInvoiceRemove,
        queryParams,
        undefined,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
