import { QueryParams } from '@/models';
import { Moment } from 'moment';

export class GetFuelCardProvidersRequest extends QueryParams {}

export class CreateEfsAccountRequest extends QueryParams {
  providerId!: number;
  data!: {
    DISPLAY_NAME: string;
    USERNAME: string;
    PASSWORD: string;
  };
}

export class UpdateEfsAccountRequest extends QueryParams {
  providerId!: number;
  id!: number;
  data!: {
    DISPLAY_NAME: string;
    USERNAME: string;
    PASSWORD: string;
  };
}

export class GetEfsAccountsRequest extends QueryParams {
  providerId!: number;
}

export class PullDataRequest extends QueryParams {
  credentialId!: number;
  hideSyncedReports?: boolean;
  begDate?: string;
  endDate?: string;
  driverRoleFilter?: string;
  driverIdsFilter?: string[];
  tractorIdsFilter?: string[];
}

export class CreateExpensesRequest extends QueryParams {
  key!: string;
  credentialId!: number;
  deductionType!: string;
  percentageToReimburse?: number;
  addDiscountInDescription?: boolean;
}

export class DeleteEfsAccountRequest extends QueryParams {
  id!: number;
}

export class FinishImportRequest extends QueryParams {
  key!: string;
}
