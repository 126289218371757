import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { annotateNameAsync, IGeoBusService } from '../interfaces';
import { DistanceInfo } from '../../models/DTOs/GeoBus/Requests';
import { DistanceResponse } from '../../models/DTOs/GeoBus/GeoBus';

const geoBusBaseApiPath = '/web/geobus/api/v2/map';

const resources = {
  getDistance: `${geoBusBaseApiPath}/distance/query/bulk`,
};

export class GeoBusService extends IGeoBusService {
  @annotateNameAsync
  async getDistance(requestData: DistanceInfo[]) {
    try {
      return httpClient.post<DistanceResponse[]>(
        resources.getDistance,
        undefined,
        requestData,
        DistanceResponse,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
