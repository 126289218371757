import { QueryParams } from '../commonMixed';
import { PreferencesType } from './Preferences';

export class GetPreferencesRequest extends QueryParams {}
export class GenerateApiKeyRequest extends QueryParams {}
export class DeleteApiKeyRequest extends QueryParams {}
export class DeactivateApiKeyRequest extends QueryParams {}
export class ActivateApiKeyRequest extends QueryParams {}
export class GetCompanyLogoRequest extends QueryParams {}
export class UploadCompanyLogoRequest extends QueryParams {}
export class UpdatePreferencesRequest extends QueryParams {
  constructor(dto: PreferencesType) {
    super();
    Object.assign(this, dto);
  }
}
