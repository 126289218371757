import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import uniqWith from 'lodash/uniqWith';
import moment from 'moment';
import StorageManager from '../../../../StorageManager/StorageManager';
import {
  customerService,
  documentService,
  importService,
  loadService,
  locationService,
  manageTypesService,
  templateService,
} from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { httpClient } from '../../../../axios/axiosInstance';
import { defaultStopData } from '../../../../common/LoadSharedComponents/constants';
import { getTrailerTypes } from '../../../../common/LoadSharedComponents/utils';
import {
  Appointment,
  RelayStopFormData,
} from '../../../../common/LoadTabPanel/tabs/Routes/components/RelayStopPopup/RelayStopForm';
import {
  getRoundedRevenueShare,
  location2BusinessAndAddress,
} from '../../../../common/LoadTabPanel/tabs/Routes/utils';
import { getSuccessMsg } from '../../../../constants/globalNotificationMessages';
import {
  CenterDTO,
  CommodityTypeSummary,
  ConnectionDetail,
  CreateCustomerRequest,
  CreateFileRequest,
  CreateLoadStop,
  CreateLocationRequest,
  CreateTemplateRequest,
  CustomerSummaryDTO,
  EditTemplateRequest,
  GetCommodityTypesRequest,
  GetLocationListDetailsByCoordsRequest,
  GetLocationListDetailsByCoordsResponse,
  PaginatedTemplateListRequest,
  Points,
  RelayStop,
  StopLocation,
  TemplateDetailsRequest,
  TemplateDetailsResponse,
  TripStopListCoords,
  ValidateLoadReferenceIdRequest,
  ValidateLocationsUniqueNamesRequest,
} from '../../../../models';
import { CreateManageTypeRequest } from '../../../../models/DTOs/ManageTypes/Requests';
import { QueryParams } from '../../../../models/DTOs/commonMixed/QueryParams';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import uuid from '../../../../utils/uuid';
import TerminalController from '../../../../views/settings/terminals/TerminalController';
import { getDocumentTypeList } from '../../../expenses/utils/utils';
import InvoiceActions from '../../../invoices/InvoiceActions';
import { LineItem } from '../../../invoices/models/InvoiceModel';
import { fixUserDefinedItems } from '../../../invoices/utils';
import {
  duplicatedFieldsValidationMsgs,
  loadDetaislDefaultData,
  pageSize,
} from '../constants/constants';
import { LoadDetailsSummary, Stop } from '../models/LoadDetails';

// Nvard - this api doesn't have usage
const getUsersTerminalsUrl =
  '/web/preference/api/v2/preferences/terminals/users/primary-terminals';

export const createTemplate = async (
  requestData: CreateTemplateRequest,
  callback: (data: TemplateDetailsResponse, validationErr?: any) => void
): Promise<void> => {
  const response = await templateService.createTemplate(requestData);
  const serviceName = 'templateCreation';
  if (response instanceof ServiceError) {
    const errorStatusCode = response.composedError.getStatusCode();
    if (errorStatusCode !== 409) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName,
      });
    }
    callback({} as TemplateDetailsResponse, {
      fieldName: 'newTemplateName',
      message: response.composedError.error.response?.data.message || '',
    });
  } else {
    RootStoreInstence.setNotificationType({
      type: 'SUCCESS',
      serviceName,
      message: getSuccessMsg[serviceName](response.templateName),
    });
    callback(response, null);
  }
};

export const editTemplate = async (
  requestData: EditTemplateRequest,
  callback: (data: TemplateDetailsResponse, validationErr?: any) => void
): Promise<void> => {
  const response = await templateService.editTemplate(requestData);
  const serviceName = 'templateEdit';
  if (response instanceof ServiceError) {
    const errorStatusCode = response.composedError.getStatusCode();
    if (errorStatusCode !== 409) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName,
      });
    }
    callback({} as TemplateDetailsResponse, {
      fieldName: 'newTemplateName',
      message: response.composedError.error.response?.data.message || '',
    });
  } else {
    RootStoreInstence.setNotificationType({
      type: 'SUCCESS',
      serviceName,
      message: getSuccessMsg[serviceName](requestData.templateName),
    });
    callback(response, null);
  }
};
class GetUsersDefaultTerminalsRequest extends QueryParams {
  userIds: number[];
  constructor(data: any) {
    super();
    this.userIds = data.userIds;
  }
}

export const getUsersDefaultTerminals = async () => {
  try {
    const { id } = StorageManager.getUser() || {};
    const requestData = new GetUsersDefaultTerminalsRequest({ userIds: [id] });
    const response = await httpClient.putRaw(
      getUsersTerminalsUrl,
      undefined,
      requestData
    );
    const { userToPrimaryTerminal } = response.data || {};
    const userDefaultTerminal = userToPrimaryTerminal[id];
    if (userDefaultTerminal) {
      return {
        id: userDefaultTerminal.id,
        name: userDefaultTerminal.companyName,
      };
    }
    return null;
  } catch (error) {
    return null;
  }
};

// @TODO: Nvard - later importService will be replaced with static data service

export const getCommodityTypes = async () => {
  const request = new GetCommodityTypesRequest();
  const response = await importService.getCommodityTypes(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const addNewCommodityType = async (itemName: string) => {
  try {
    const user = StorageManager.getUser();
    const createdBy = `${user?.firstname} ${user?.lastname}`;
    const request = new CreateManageTypeRequest();
    request.itemName = itemName;
    request.iconName = null;
    request.deduction = false;
    request.description = null;
    request.unit = [];
    request.createdBy = createdBy;

    return await manageTypesService.createManageType(request, 'commodity');
  } catch (error) {
    return null;
  }
};

export const getTemplateList = async (text: string, pageNumber: number) => {
  const requestData = new PaginatedTemplateListRequest(
    pageSize,
    pageNumber,
    text
  );

  const response = await templateService.getPaginatedTemplateList(requestData);
  if (response instanceof ServiceError) {
    return { content: [] };
  } else {
    return response || { content: [] };
  }
};

const getLocationsDetailsByCoords = async (locationCoords: CenterDTO[]) => {
  const requestData = new GetLocationListDetailsByCoordsRequest();

  requestData.data = locationCoords.map((coords) => {
    const { lat, lng } = coords || {};
    const locationCoords = new CenterDTO();
    locationCoords.lat = lat;
    locationCoords.lng = lng;
    return locationCoords;
  });

  const response = await locationService.getLocationListDetailsByCoords(
    requestData
  );
  if (response instanceof GetLocationListDetailsByCoordsResponse) {
    return response.data || [];
  }
  return [];
};

export const getStopFormsData = (stops: CreateLoadStop[]) => {
  return (
    stops?.map((stop, index: number) => {
      if (stop.relayStop) {
        const relayStop = convertRelayStopDataToRelayStopFormData({
          ...stop.relayStop,
          id: index + 1,
        });
        relayStop.key = uuid();
        return relayStop;
      } else {
        const loadStop = new Stop();
        loadStop.getStopDetailsFromLoadStopDTO({
          ...stop.loadStop,
          id: index + 1,
        });
        loadStop.setUniqueKey();
        return loadStop;
      }
    }) || []
  );
};

const getStopsDetails = async (data) => {
  const {
    id: refNumber,
    stops,
    phoneNumber: phone,
    comments,
    email,
    contactName,
    loadDetails,
  } = data;
  const { notes } = loadDetails || {};
  const locationsCoords = stops.map((stop: any) => ({
    ...stop.location.center,
  }));

  const locationsDetails = await getLocationsDetailsByCoords(locationsCoords);
  return stops?.map((stop: any, index: number) => {
    const locationDetails = locationsDetails[index];
    if (locationDetails) {
      const { city, state, zip, timezone, street, latitude, longitude } =
        locationDetails;
      stop.location = {
        ...stop.location,
        timezone,
        city,
        state,
        address: street,
        zipcode: zip,
        lat: latitude,
        lng: longitude,
      };
    }

    const loadStop = new Stop();
    loadStop.getStopDetailsFromLoadStopDTO({
      ...stop,
      notes: notes || comments,
      refNumber,
      contact: {
        firstname: contactName || '',
        email,
        phone,
      },
      id: index + 1,
    });
    loadStop.setUniqueKey();
    return loadStop;
  });
};

const createCustomerForLSLoad = async (
  data
): Promise<{
  customer: { id: number; name: string } | null;
  contact: { id: number; fullName: string } | null;
}> => {
  const { customerId, company, customerContactId, contactName, mcNumber } =
    data;
  let customer = null,
    contact = null;

  if (company) {
    if (customerId) {
      customer = { id: customerId, name: company };
      if (customerContactId && contactName) {
        contact = { id: customerContactId, fullName: contactName };
      }
    } else {
      const requestData: CreateCustomerRequest = new CreateCustomerRequest({
        name: company,
        contacts: [
          {
            fullName: contactName,
            isPrimary: true,
            description: null,
            phoneData: {},
            email: null,
          },
        ],
        ...(mcNumber && { mc: mcNumber }),
      });
      try {
        const response = await customerService.createCustomer(requestData);
        if (response instanceof CustomerSummaryDTO) {
          const { id, name, contacts } = response;
          customer = { id, name };
          const primaryContact = contacts?.[0];
          if (primaryContact) {
            contact = {
              id: primaryContact.id,
              fullName: primaryContact.fullName,
            };
          }
        }
      } catch (error) {
        return {
          customer,
          contact,
        };
      }
    }
  }

  return {
    customer,
    contact,
  };
};

export const getLoadDetailsFromLSLoad = async (
  data,
  commodityTypes: CommodityTypeSummary[]
) => {
  const { loadDetails, terminalId, terminal, revenue, comments } = data;
  const {
    commodityData = [],
    equipmentAdditionalDetails,
    referenceId,
    factoringId,
    factoringNotes,
    trailerTypes = [],
    notes,
    noteDetails,
    totalWeight,
  } = loadDetails || {};
  const {
    sealNumber,
    containerNumber,
    chassisNumber,
    bookingNumber,
    appointmentNumber,
    loadDirection,
    lowerLimitTemperature,
    upperLimitTemperature,
    reeferMode,
  } = equipmentAdditionalDetails || {};
  const miscellaneousCommodityType = commodityTypes.find(
    (item) => item.itemCode === 'MISCELLANEOUS'
  );
  const defaultCommodityType = miscellaneousCommodityType
    ? {
        commodity: {
          id: miscellaneousCommodityType.id,
          itemName: miscellaneousCommodityType.itemName,
        },
        commodityTypeDisplayValue: miscellaneousCommodityType.itemName,
        commodityType: miscellaneousCommodityType.itemCode,
        commodityTypeId: miscellaneousCommodityType.id,
        description: miscellaneousCommodityType.description,
      }
    : {};

  const mapCommodityType = (item) => {
    if (!item.commodityType) {
      return {
        ...item,
        ...defaultCommodityType,
      };
    }
    return {
      ...item,
      commodityTypeDisplayValue: item.itemName,
      commodity: { id: item.commodityTypeId, itemName: item.itemName },
    };
  };
  const newStops = (await getStopsDetails(data)) as Array<any>;
  const { customer, contact } = await createCustomerForLSLoad(data);
  return new LoadDetailsSummary({
    commodityData: commodityData?.map(mapCommodityType) || [
      {
        ...defaultCommodityType,
        floorLoad: false,
        height: null,
        length: null,
        packageType: null,
        qty: 1,
        stackable: false,
        volume: null,
        weight: null,
        width: null,
      },
    ],
    sealNumber,
    containerNumber,
    chassisNumber,
    bookingNumber,
    appointmentNumber,
    loadDirection,
    reeferMode,
    lowerLimitTemperature,
    upperLimitTemperature,
    referenceId,
    factoringNotes,
    factoring: (factoringId && { id: factoringId, displayName: '' }) || null, // AXL2-2738: set factoring name after backend fix
    terminal: (terminalId && { id: terminalId, name: terminal }) || null,
    customer,
    contact,
    equipmentTypes: trailerTypes?.map((item: string) => ({
      key: item,
      value: item,
    })),
    notes: notes || comments,
    noteDetails,
    totalWeight,
    revenue,
    stops: newStops,
  });
};

export const getLoadDetailsFromTemplate = (templateDetails) => {
  const {
    loadDetails,
    invoice,
    terminal,
    customer,
    stops,
    id,
    templateName,
    billOfLadingMandatory,
    proofOfDeliveryMandatory,
  } = templateDetails;
  const { customerId, customerName, customerContactId, contacts } =
    customer || {};
  const { id: terminalId, companyName } = terminal || {};
  const customerContact = contacts?.find(
    (contact) => contact.id === customerContactId
  );
  const {
    commodityData = [],
    equipmentAdditionalDetails,
    referenceId,
    factoringChargePercent,
    factoringId,
    factoringName,
    factoringNotes,
    trailerTypes = [],
    notes,
    totalWeight,
  } = loadDetails || {};
  const {
    sealNumber,
    containerNumber,
    chassisNumber,
    bookingNumber,
    appointmentNumber,
    loadDirection,
    lowerLimitTemperature,
    upperLimitTemperature,
  } = equipmentAdditionalDetails || {};
  const {
    notes: invoiceNotes,
    noteDetails: invoiceNoteDetails,
    lineItems = [],
    receivedPayments = [],
  } = invoice || {};

  return new LoadDetailsSummary({
    id: templateDetails.id || null,
    commodityData:
      commodityData?.map((item) => ({
        ...item,
        commodityTypeDisplayValue: item.itemName,
        commodity: { id: item.commodityTypeId, itemName: item.itemName },
      })) || [],
    sealNumber,
    containerNumber,
    chassisNumber,
    bookingNumber,
    appointmentNumber,
    loadDirection,
    lowerLimitTemperature,
    upperLimitTemperature,
    billOfLadingMandatory,
    proofOfDeliveryMandatory,
    referenceId,
    factoringChargePercent,
    factoring:
      (factoringId && { id: factoringId, displayName: factoringName || '' }) ||
      null,
    terminal: {
      id: terminalId || null,
      name: (terminalId && companyName) || '',
    },
    customer: {
      id: customerId || null,
      name: (customerId && customerName) || '',
      contacts:
        (customerId &&
          contacts?.map((contact) => ({
            id: contact.id,
            fullName: `${contact.firstname} ${contact.lastname}`,
          }))) ||
        [],
    },
    template: (id && { id, templateName }) || null,
    contact: {
      id: customerContact?.id || null,
      fullName: (customerContact?.id && customerContact?.firstname) || '',
    },
    equipmentTypes: trailerTypes,
    notes,
    factoringNotes,
    invoiceNotes,
    invoiceNoteDetails,
    lineItems,
    receivedPayments,
    totalWeight,
    stops: getStopFormsData(stops),
  });
};

// @Alok what data can I expect from file
export const getLoadDetailsFromFile = (parsedFileData) => {
  const { loadDetails, stops: parsedStops, customer } = parsedFileData;
  const {
    commodityData,
    referenceId,
    factoringNotes,
    totalWeight = null,
    trailerTypes = [],
    equipmentAdditionalDetails,
  } = loadDetails || {};
  const {
    sealNumber,
    containerNumber,
    chassisNumber,
    bookingNumber,
    appointmentNumber,
    loadDirection,
    lowerLimitTemperature,
    upperLimitTemperature,
  } = equipmentAdditionalDetails || {};
  const {
    id: customerId = null,
    primaryContact,
    name: customerName = '',
    contactSummaries,
  } = customer || {};

  let stops = parsedStops;
  if (!stops?.length) {
    stops = [
      {
        ...defaultStopData,
        id: 1,
        activityType: 'PICKUP',
      },
      {
        ...defaultStopData,
        id: 2,
        activityType: 'DROPOFF',
      },
    ];
  }
  return new LoadDetailsSummary({
    commodityData:
      commodityData?.map((item) => ({
        ...item,
        commodityTypeDisplayValue: item.itemName,
        commodity: { id: item.commodityTypeId, itemName: item.itemName },
      })) || [],
    factoringNotes,
    sealNumber,
    containerNumber,
    chassisNumber,
    bookingNumber,
    appointmentNumber,
    loadDirection,
    lowerLimitTemperature,
    upperLimitTemperature,
    referenceId,
    customer: {
      id: customerId || null,
      name: (customerId && customerName) || '',
      contacts:
        (customerId &&
          contactSummaries?.map((contact) => ({
            id: contact.id,
            fullName: `${contact.firstname} ${contact.lastname}`,
          }))) ||
        [],
    },
    contact: {
      id: primaryContact?.id || null,
      fullName:
        (primaryContact?.id &&
          `${primaryContact.fullName} ${primaryContact.lastname}`) ||
        '',
    },
    equipmentTypes: trailerTypes,
    totalWeight,
    stops: stops?.map((stop) => {
      const loadStop = new Stop();
      loadStop.getStopDetailsFromLoadStopDTO(stop);
      loadStop.setUniqueKey();
      return loadStop;
    }),
  });
};

export const updateLoadStopsLocationsIds = ({
  stops = [],
  locations = [],
}: {
  stops: { location: { id: number | null; locationName: string } }[];
  locations: { id: number | null; locationName: string }[];
}) => {
  if (stops?.length && locations?.length) {
    stops.forEach((stop) => {
      const location = locations.find(
        (loc) => loc.locationName === stop.location?.locationName
      );
      if (location) {
        stop.location.id = location.id;
      }
    });
  }
  return stops;
};

// groups locations that have the same bussines name but different adresses
export const getDuplicateLocationsByBussinesName = (
  locations: StopLocation[]
) => {
  const duplicateLocationNames: string[] = [];
  if (locations?.length) {
    const groupedLocs = groupBy(locations, 'locationName');
    Object.keys(groupedLocs).forEach((key) => {
      if (
        uniqWith(groupedLocs[key], (l1, l2) => l1.address === l2.address)
          .length > 1
      ) {
        duplicateLocationNames.push(key);
      }
    });
  }

  return duplicateLocationNames;
};

export const validateLoadReferenceId = async ({
  customerId,
  referenceId,
}: {
  customerId: number;
  referenceId: string;
}): Promise<{ isValid: boolean; errors: {} }> => {
  let isValid = true;
  if (referenceId) {
    const requestData = new ValidateLoadReferenceIdRequest({
      loadCategory: 'LOAD',
      customerId,
      referenceId,
    });
    const response = await loadService.validateLoadReferenceId(requestData);

    if (response instanceof ServiceError) {
      isValid = false;
    } else {
      isValid = response?.valid;
    }
  }
  return {
    isValid,
    errors: isValid
      ? {}
      : {
          message: duplicatedFieldsValidationMsgs.referenceId,
          type: 'customHttpError',
        },
  };
};

export const validateLoadStopsLocations = async (
  stopsData: Stop | RelayStopFormData[]
): Promise<{ isValid: boolean; errors: {} }> => {
  const stops = cloneDeep(stopsData);
  const stopsErrors = [];
  let isValid = true;
  let stopsLocationsToBeSaved =
    stops?.filter((stop) => {
      if (stop.stopType === 'RELAY' && stop.businessName?.locationDisplayName) {
        stop.location = stop.businessName;
        stop.location.locationName = stop.businessName?.locationDisplayName;
        stop.addressDTO = stop.address;
      }
      return stop.location?.locationName?.trim() && !stop.location.id;
    }) || [];
  if (stopsLocationsToBeSaved.length) {
    const locations = stopsLocationsToBeSaved.map((stop, index) => ({
      ...stop.location,
      stopId: stop.id,
      stopIndex: index,
      address: `${stop.addressDTO.address}${stop.addressDTO.streetAddress}${stop.addressDTO.streetAddress2}`,
    }));
    const duplicateLocations = getDuplicateLocationsByBussinesName(locations);
    stops.forEach((stop) => {
      let locationNameErrorMsg = '';

      if (duplicateLocations.includes(stop.location?.locationName?.trim())) {
        locationNameErrorMsg = duplicatedFieldsValidationMsgs.locationName;
        isValid = false;
      }
      const locationNameError =
        stop.stopType !== 'RELAY'
          ? {
              location: {
                type: 'customHttpError',
                message: locationNameErrorMsg,
              },
            }
          : {
              businessName: {
                type: 'customHttpError',
                message: locationNameErrorMsg,
              },
            };
      stopsErrors.push(locationNameError);
    });

    // Validate locations with not duplicated Business Names
    const uniqueLocations = locations.filter(
      (location) => !duplicateLocations.includes(location.locationName)
    );
    let validLocationIds = [];
    if (uniqueLocations.length) {
      const requestData = new ValidateLocationsUniqueNamesRequest({
        locations: uniqueLocations.map((location) => ({
          id: location.stopId,
          locationName: location.locationName,
        })),
      });
      const response = await locationService.validateLocationsUniqueNames(
        requestData
      );
      const validLocations = response?.locations || null;
      validLocationIds = Object.keys(validLocations).filter(
        (key) => !validLocations[key]
      );
      validLocationIds = validLocationIds.map((id) => parseInt(id, 10));

      stopsLocationsToBeSaved = stopsLocationsToBeSaved.filter((stop) =>
        validLocationIds.includes(stop.id)
      );
    }
  }
  return {
    isValid,
    errors: stopsErrors,
    stopsLocationsToBeSaved,
  };
};

export const validateStopAppointmentDates = (stops) => {
  const stopsErrors = [];
  let isValid = true;
  stops.forEach((stop, index) => {
    let areDatesValid = true;
    if (index !== stops.length - 1) {
      const startDate =
        stop.stopType !== 'RELAY'
          ? stop.appointmentStartDate
          : stop.pickup.startDate;
      const endDate =
        stops[index + 1].stopType !== 'RELAY'
          ? stops[index + 1].appointmentEndDate
          : stops[index + 1].dropOff.endDate;
      areDatesValid = moment(startDate).isSameOrBefore(endDate);
    } else {
      const startDate =
        stops[index - 1].stopType !== 'RELAY'
          ? stops[index - 1].appointmentStartDate
          : stops[index - 1].pickup.startDate;
      areDatesValid = moment(startDate).isSameOrBefore(stop.appointmentEndDate);
    }

    if (!areDatesValid) {
      isValid = areDatesValid;
    }
    stopsErrors.push(!areDatesValid);
  });

  const errors = [];
  stopsErrors.forEach((stopError, index) => {
    if (!index) {
      errors.push({
        datesDependencyError: stopError
          ? {
              message: duplicatedFieldsValidationMsgs.appointmentDates,
              type: 'custom',
              ref: { name: `stops.${index}.datesDependencyError` },
            }
          : {},
      });
    } else if (stopError || stopsErrors[index - 1]) {
      errors.push({
        datesDependencyError: {
          message: duplicatedFieldsValidationMsgs.appointmentDates,
          type: 'custom',
          ref: { name: `stops.${index}.datesDependencyError` },
        },
      });
    } else {
      errors.push({});
    }
  });
  return {
    isValid,
    errors,
  };
};

export const validateLoadDetails = async (load: LoadDetailsSummary) => {
  const { stops, customer, referenceId } = load;
  const { isValid: stopsDatesAreValid, errors: dateTimeDependencyErrors } =
    validateStopAppointmentDates(stops);
  const { isValid: refIdIsValid, errors: referenceIdErrors } =
    await validateLoadReferenceId({ customerId: customer?.id, referenceId });
  const {
    isValid: stopsLocationsAreValid,
    errors: stopsLocationsErrors,
    stopsLocationsToBeSaved,
  } = await validateLoadStopsLocations(stops);

  const stopsErrors = [];
  stops.forEach((stop, index) => {
    stopsErrors.push({
      ...dateTimeDependencyErrors[index],
      ...stopsLocationsErrors[index],
    });
  });
  return {
    isValid: refIdIsValid && stopsLocationsAreValid && stopsDatesAreValid,
    stopsLocationsToBeSaved,
    referenceIdErrors,
    stopsErrors,
  };
};

export const updateLoadStopsWithSavedLocations = async (
  stops: Stop[],
  stopsLocationsToBeSaved: CreateLocationRequest[] | undefined
) => {
  let locations;
  if (stopsLocationsToBeSaved?.length) {
    locations = stopsLocationsToBeSaved.map((stop) => {
      const stopLocation = new CreateLocationRequest();
      stopLocation.getLocationOfLoadStop(stop);
      return stopLocation;
    });
  }
  let updatedStops = stops;
  if (locations) {
    const savedLocations = await locationService.createLocations(locations);
    if (!(savedLocations instanceof ServiceError) && savedLocations?.length) {
      updatedStops = updateLoadStopsLocationsIds({
        stops,
        locations: savedLocations,
      });
    }
  }
  return updatedStops;
};

export const getInvoiceDefaultLineItem = async () => {
  const invoiceBaseLineItems = await InvoiceActions.getInvoiceBaseLineItems();
  const defaultLineItem = invoiceBaseLineItems?.find(
    (item) => item.itemCode === 'FLAT_RATE'
  );
  return {
    itemId: defaultLineItem?.id,
    description: defaultLineItem?.description,
    type: defaultLineItem?.itemCode,
    rate: null,
    amount: null,
    quantity: 1,
  };
};

export const getNonEmptyBaseLineItems = async (lineItems: LineItem[]) => {
  if (lineItems?.length) {
    const invoiceBaseLineItems = await InvoiceActions.getInvoiceBaseLineItems();
    return lineItems.filter(
      (item) =>
        !invoiceBaseLineItems?.find(
          (baseLineItem) => baseLineItem.id === item.itemId
        ) ||
        item.rate ||
        item.rate === 0
    );
  }
  return lineItems;
};

export const assignDocumentToLoad = async (
  load: { id: string; seqNumber: string; terminalId: string },
  document: File
) => {
  const documentTypes = await getDocumentTypeList();
  const rateConDocType = documentTypes.find(
    (docType) => docType.itemCode === 'RATE_CONFIRMATION'
  );
  const data = {
    documentType: rateConDocType,
    entityTypes: {
      key: 'LOAD',
      value: 'Load',
    },
    entities: {
      id: load.id,
      name: load.seqNumber,
    },
    fileName: document.name,
    notes: '',
    permission: true,
    terminal: { id: load.terminalId },
  };
  const request = new CreateFileRequest();
  await documentService.createDocument(request, [document], data);
};

// @TODO: AXL2--5205: fix ts errors
export const getCreateLoadPanelFormDefaultData = () => {
  const { terminals = [] } = TerminalController.instance();
  const primaryTerminal =
    terminals?.find((terminal) => terminal.isPrimary) || null;
  const loadDetails = cloneDeep(loadDetaislDefaultData);
  loadDetails.stops.forEach((stop) => (stop.key = uuid()));
  loadDetails.terminal = primaryTerminal
    ? { id: primaryTerminal.id, name: primaryTerminal.name }
    : null;
  return new LoadDetailsSummary(loadDetails);
};

export const getTemplateDetails = async (
  requestData: TemplateDetailsRequest
) => {
  const response = await templateService.getTemplateDetails(requestData);
  if (response instanceof TemplateDetailsResponse) {
    if (response.loadDetails) {
      response.loadDetails.trailerTypes = await getTrailerTypes(
        response.loadDetails.trailerTypes
      );
    }
    const templateDetails = getLoadDetailsFromTemplate(response);
    if (!templateDetails.lineItems?.length) {
      const invoiceDefaultLineItem = await getInvoiceDefaultLineItem();
      // @TODO: Nvard/Davit S - review invoice LineItem model
      templateDetails.lineItems = [invoiceDefaultLineItem];
    } else {
      templateDetails.lineItems = fixUserDefinedItems(
        templateDetails.lineItems
      );
    }
    if (!templateDetails.terminal) {
      const primaryTerminal =
        await TerminalController.instance().getPrimaryTerminal();
      if (primaryTerminal) {
        templateDetails.terminal = {
          id: primaryTerminal.id,
          name: primaryTerminal.name,
        };
      }
    }
    return templateDetails;
  }
  return response;
};

const getRelayStopConnectionDetails = (data: ConnectionDetail): Appointment => {
  const {
    fixedAppointment = true,
    driverAssistRequired = false,
    estimatedActivityDuration,
    revenueShare,
    appointmentStartDate,
    appointmentEndDate,
  } = data || {};

  return {
    driverAssist: driverAssistRequired,
    startDate: appointmentStartDate ? moment(appointmentStartDate) : null,
    endDate: appointmentEndDate ? moment(appointmentEndDate) : null,
    handlingTime: estimatedActivityDuration,
    fixedAppointment,
    revenueShare,
  };
};

export const convertRelayStopDataToRelayStopFormData = (
  relayStop: RelayStop
): RelayStopFormData | null => {
  if (relayStop) {
    const {
      id,
      pickupConnectionDetails,
      dropOffConnectionDetails,
      connectionPoint,
    } = relayStop;
    const { address, business } = location2BusinessAndAddress(connectionPoint);
    return {
      id,
      stopType: 'RELAY',
      pickup: getRelayStopConnectionDetails(pickupConnectionDetails),
      dropOff: getRelayStopConnectionDetails(dropOffConnectionDetails),
      businessName: business,
      address,
    };
  }
  return relayStop;
};

const getStopAddressCoordinates = (stop) => {
  const { lat, lng } =
    stop.stopType === 'RELAY'
      ? stop.address?.center || {}
      : stop.addressDTO?.center || {};
  if (lat && lng) {
    return {
      lat,
      lon: lng,
    };
  }

  return null;
};

export const getStopsAddressCoords = (stops) => {
  const tripListCoords: TripStopListCoords[] = [];
  let tripStopListCoords: Points[] = [];
  let recalculateRevenue = true;
  let prevRelayStop = null;
  let tripId = 1;
  for (let i = 0; i <= stops.length - 1; i++) {
    const stop = stops[i];
    const coords = getStopAddressCoordinates(stop);
    if (!coords) {
      recalculateRevenue = false;
      break;
    } else {
      tripStopListCoords.push(coords);

      if (prevRelayStop) {
        tripStopListCoords.unshift(getStopAddressCoordinates(prevRelayStop));
        prevRelayStop = null;
      }
      if (i === stops.length - 1) {
        // last stop can't be RELAY stop
        tripListCoords.push({
          id: tripId,
          points: tripStopListCoords,
        });
      } else if (stop.stopType === 'RELAY') {
        stop.tripId = tripId;
        prevRelayStop = stop;
        tripListCoords.push({
          id: tripId,
          points: tripStopListCoords,
        });
        tripStopListCoords = [];
        tripId++;
      }
    }
  }

  if (recalculateRevenue) {
    return tripListCoords;
  }
  return null;
};

export const calculateTripListRevenueShare = async (stops) => {
  const requestData = getStopsAddressCoords(stops);
  if (requestData) {
    const response = await loadService.getTripListRevenueShare(requestData);

    if (!(response instanceof ServiceError)) {
      return stops.map((stop) => {
        if (stop.stopType === 'RELAY') {
          const tripIndex = response.findIndex(
            (trip) => trip.id == stop.tripId
          );
          return {
            ...stop,
            key: uuid(),
            dropOff: {
              ...stop.dropOff,
              revenueShare: `${getRoundedRevenueShare(
                response[tripIndex].revenueShare
              )}`,
            },
            pickup: {
              ...stop.pickup,
              revenueShare: `${getRoundedRevenueShare(
                response[tripIndex + 1].revenueShare
              )}`,
            },
          };
        }
        return stop;
      });
    }
    return stops;
  }
  return stops;
};

export const calculateTotalRevenueShare = (stops) => {
  const relayStops = stops?.filter((stop) => stop.stopType === 'RELAY');
  let totalRevenueShare = 0;

  if (relayStops?.length) {
    totalRevenueShare =
      relayStops?.reduce((total, row) => {
        return total + (+row.pickup?.revenueShare || 0);
      }, 0) + +relayStops[0].dropOff.revenueShare;
  }
  return totalRevenueShare;
};

export const getNextExpandedSection = (
  currSection: string,
  allSectionsList: Array<string>
) => {
  const index = allSectionsList.findIndex((e) => e === currSection);
  if (index >= 0) {
    return allSectionsList[index + 1 >= allSectionsList.length ? 0 : index + 1];
  }
  return null;
};

export const getNextExpandedSectionOfLastElement = (
  currElId: string,
  allSectionsList: Array<string>,
  allElementIds: Array<string>
) => {
  const currentActiveSectionIndex = allElementIds.findIndex(
    (e) => e === currElId
  );
  if (currentActiveSectionIndex >= 0) {
    if (currentActiveSectionIndex >= allElementIds.length - 1) {
      return allSectionsList[0];
    } else {
      return allSectionsList[currentActiveSectionIndex + 1];
    }
  }
  return null;
};
