import React from 'react';
import { sectionType } from '../constants';
import MissingEntitiesMatchForm from './MissingEntitiesMatchForm';

type pagePropTypes = {
  axeleValues: string[];
  currentSection: sectionType;
};

export default function DispatcherForm({
  axeleValues,
  currentSection,
}: pagePropTypes) {
  return (
    <MissingEntitiesMatchForm
      axeleValues={axeleValues}
      currentSection={currentSection}
      fieldName={'name'}
    />
  );
}
