import { formatTimeoffsToMap } from '../../services/recommendTrips.service';
import {
  AssignmentPreferenceType,
  LOCK_RESOURCE_KEYS,
  REJECT_RESOURCE_KEYS,
} from '../../constants/optymization.const';
import {
  convertPossibleDriversListToMap,
  convertPossibleTripsListToMap,
  setDriverAsRejected,
  setDriverAsUnRejected,
} from '../../services/possibleResources.service';
import {
  IPossibleResourceStore,
  PossibleDriverResourceType,
  PossibleResourceProps,
} from '../../types/possibleResource.types';
import { POSSIBLE_RESOURCE_ACTIONS } from '../optymization.actions';
import { mapTripWarnings } from '../../../../services/gantt';
import { EngineSandboxWarningsType } from '../../../../types/DispatchTypes';

export const possibleResourcesInitialState: IPossibleResourceStore = {
  isLoading: true,
  possibleResourceDrivers: new Map(),
  possibleResourceTrips: new Map(),
  possibleLockedDrivers: new Map(),
  possibleRejectedDrivers: new Map(),
  possibleLockedTrips: new Map(),
};

export const possibleResourcesReducer = (
  state: IPossibleResourceStore,
  action: { type: string; payload?: PossibleResourceProps }
): IPossibleResourceStore => {
  const lockPossibleDriver = (driverId: string, lockedDriverId: string) =>
    state?.possibleLockedDrivers?.set(driverId, lockedDriverId);
  const unlockPossibleDriver = (driverId: string) =>
    state?.possibleLockedDrivers?.delete(driverId);
  const lockPossibleTrip = (resourceId: string, lockedTripId: string) =>
    state?.possibleLockedTrips?.set(resourceId, lockedTripId);
  const unlockPossibleTrip = (resourceId: string) =>
    state?.possibleLockedTrips?.delete(resourceId);
  const handleLockPossibleDriver = (
    isActionToLock: boolean,
    lockForResourceId: string,
    lockedResourceId: string
  ) => {
    const existingLockedResourceId = state?.possibleLockedDrivers?.get(
      lockForResourceId
    ) as string;
    const newPossibleLockedDriver = state?.possibleResourceDrivers?.get?.(
      lockedResourceId
    ) as PossibleDriverResourceType;
    const existingLockedPossibleResource = state.possibleResourceDrivers?.get(
      existingLockedResourceId
    ) as PossibleDriverResourceType;
    if (existingLockedPossibleResource?.engineMultiTripOutput) {
      if (isActionToLock) {
        if (existingLockedPossibleResource) {
          existingLockedPossibleResource.engineMultiTripOutput.isLocked = false;
        }
        lockPossibleDriver(lockForResourceId, lockedResourceId);
      } else {
        existingLockedPossibleResource.engineMultiTripOutput.isLocked = false;
        unlockPossibleDriver(lockForResourceId);
      }
    }
    if (newPossibleLockedDriver.engineMultiTripOutput)
      newPossibleLockedDriver.engineMultiTripOutput.isLocked = isActionToLock
        ? true
        : false;
  };

  const handleLockPossibleTrip = (
    isActionToLock: boolean,
    lockForResourceId: string,
    lockedResourceId: string
  ) => {
    const existingLockedResourceId =
      state?.possibleLockedTrips?.get(lockForResourceId);
    const existingLockedPossibleTrip = state.possibleResourceDrivers?.get(
      existingLockedResourceId as string
    ) as unknown as PossibleDriverResourceType;
    const newPossibleTrip = state?.possibleResourceDrivers?.get?.(
      lockedResourceId
    ) as PossibleDriverResourceType;
    if (isActionToLock) {
      if (existingLockedPossibleTrip) {
        existingLockedPossibleTrip.isLocked = false;
      }
      lockPossibleTrip(lockForResourceId, lockedResourceId);
    } else {
      existingLockedPossibleTrip.isLocked = false;
      unlockPossibleTrip(lockForResourceId);
    }
    newPossibleTrip.isLocked = isActionToLock ? true : false;
  };
  const { type, payload = {} } = action;
  switch (type) {
    case POSSIBLE_RESOURCE_ACTIONS.START_LOADER:
      return { ...state, isLoading: true };
    case POSSIBLE_RESOURCE_ACTIONS.STOP_LOADER:
      return { ...state, isLoading: false };
    case POSSIBLE_RESOURCE_ACTIONS.SET_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case POSSIBLE_RESOURCE_ACTIONS.SET_POSSIBLE_RESOURCES:
      const { drivers = [], trips = [], timeoffs = [] } = payload;
      const tripsMap = convertPossibleTripsListToMap(trips);
      return {
        ...state,
        isLoading: false,
        timeoffs: formatTimeoffsToMap(timeoffs),
        possibleResourceDrivers: convertPossibleDriversListToMap(
          drivers,
          tripsMap
        ),
        possibleResourceTrips: tripsMap,
      };
    case POSSIBLE_RESOURCE_ACTIONS.LOCK_POSSIBLE_RESOURCE: {
      const { lockedResource } = payload;
      const lockForResourceId = lockedResource?.lockForResourceId as string;
      const isActionToLock = lockedResource?.type === LOCK_RESOURCE_KEYS.LOCK;
      handleLockPossibleDriver(
        isActionToLock,
        lockForResourceId,
        lockedResource?.id as string
      );
      return { ...state, isLoading: false };
    }
    case POSSIBLE_RESOURCE_ACTIONS.REJECT_POSSIBLE_RESOURCE: {
      const { rejectedResource } = payload;
      const { possibleResourceDrivers } = state;
      const driver = possibleResourceDrivers?.get(
        rejectedResource?.id as string
      );
      if (!driver) return state;
      driver.isRejected =
        rejectedResource?.type === REJECT_RESOURCE_KEYS.REJECT ? true : false;
      return { ...state };
    }
    case POSSIBLE_RESOURCE_ACTIONS.REJECT_DRIVER: {
      const { rejectedResource } = payload;
      const driverToReject = state?.possibleResourceDrivers?.get(
        rejectedResource?.id!
      );
      state?.possibleResourceDrivers?.set(
        driverToReject?.id as string,
        setDriverAsRejected(driverToReject!)
      );

      return {
        ...state,
        isLoading: false,
      };
    }
    case POSSIBLE_RESOURCE_ACTIONS.UN_REJECT_DRIVER: {
      const { rejectedResource } = payload;
      const driverToReject = state?.possibleResourceDrivers?.get(
        rejectedResource?.id as string
      );
      state?.possibleResourceDrivers?.set(
        driverToReject?.id as string,
        setDriverAsUnRejected(driverToReject as PossibleDriverResourceType)
      );
      return {
        ...state,
        isLoading: false,
      };
    }
    case POSSIBLE_RESOURCE_ACTIONS.CLEAR_POSSIBLE_STORE: {
      return {
        ...state,
        possibleResourceDrivers: new Map(),
        possibleResourceTrips: new Map(),
        possibleLockedDrivers: new Map(),
        possibleLockedTrips: new Map(),
      };
    }
    case POSSIBLE_RESOURCE_ACTIONS.MAP_POSSIBLE_RESOURCE_WARNINGS: {
      const { warnings } = payload;
      const { possibleResourceTrips: trips } = state;
      // let driversWithWarnings = drivers;
      // if (warnings?.drivers)
      //   driversWithWarnings = mapDriverWarnings({
      //     drivers,
      //     warnings: warnings?.drivers as unknown as {
      //       [key: string]: DriverWarningsType;
      //     },
      //   });
      const tripsWithWarnings = mapTripWarnings({
        trips,
        warnings: warnings?.trips as EngineSandboxWarningsType,
        isWarningByDriverId: true,
      });
      return {
        ...state,
        possibleResourceTrips: tripsWithWarnings,
        warnings,
      };
    }
    default:
      return { ...state, isLoading: false };
  }
};
