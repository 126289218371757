import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';

export const StyledDialog = styled('div')(
  ({ theme, uncategorized = false }: any) => ({
    borderRadius: 16,
    background: uncategorized
      ? theme.palette.uncategorized.popup
      : 'transparent',
    textAlign: 'left',
    width: '100%',
    maxWidth: '514px',

    //Update css
    height: uncategorized ? 'auto' : '100%',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  })
);

export const StyledDialogHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.uncategorized.popup,
  paddingBottom: '10px',
  marginBottom: '-10px',
  borderRadius: '16px 16px 0 0',
}));

export const StyledDialogTitle = styled(DialogTitle)(
  ({ theme, isError }: any) => ({
    margin: 0,
    fontSize: '16px',
    fontWeight: '28px',
    color: theme.palette.primary.contrast,
    position: 'relative',
    transition: 'all 0.3s ease',
    marginBottom: isError ?? true ? 0 : 14,
    //Update css
    display: 'flex',
    alignItems: 'center',
  })
);

export const StyledDialogTitleError = styled(DialogTitle)(
  ({ theme, isError }: any) => ({
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
    position: 'relative',
    backgroundColor:
      theme.palette.uncategorized.oldHeaderSecondary || '#EEF3FB',
    borderRadius: '16px 16px 0px 0px',
    textAlign: 'center',
    zIndex: 0,
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    paddingTop: isError ? 30 : 0,
    paddingBottom: isError ? 40 : 0,
    marginBottom: -14,
    height: isError ? 94 : 0,
  })
);

export const StyledDialogCloseIcon = styled('div')(() => ({
  position: 'absolute',
  right: 16,
  top: '50%',
  transform: 'translateY(-50%)',
}));

export const StyledAssetTypeField = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiTypography-root': {
    paddingRight: '20px',
  },
}));

export const StyledDialogContent = styled(DialogContent)(
  ({ theme, isError }: any) => ({
    backgroundColor: theme.palette.primary.contrast,
    textAlign: 'left',
    color: theme.palette.leftMenuCollapsed.inactive,
    borderRadius: '16px 16px 0px 0px',
    zIndex: 1,
    '& .MuiTextField-root': { marginBottom: 2 },

    //Update css
    padding: '0 !important',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    height: `calc(100% - 220px)`,
    maxHeight: 'calc(100% - 220px)',
    ...(isError && {
      height: 'calc(100% - 220px - 94px)',
      maxHeight: 'calc(100% - 220px - 94px)',
    }),
  })
);

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrast,
  position: 'relative',
  height: '63px',
  width: '100%',
  flex: '0 0 auto',
  display: 'unset',
  borderBottomRightRadius: '16px',
  borderBottomLeftRadius: '16px',
}));

export const StyledVendorRequired = styled(DialogTitle)(({ theme }) => ({
  margin: 0,
  fontSize: 14,
  fontWeight: 400,
  position: 'relative',
  transition: 'all 0.3s ease',
  backgroundColor: theme.palette.primary.contrast,
  borderRadius: '16px 16px 0px 0px',
  marginBottom: -14,
  padding: '18px 24px 90px',
  // zIndex: 0,
  overflow: 'hidden',
}));

export const StyledVendorRequiredHighlight = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 14,
  })
);

export const StyledVendorRequiredBackground = styled('img')(() => ({
  position: 'absolute',
  zIndex: -1,
  top: 0,
  left: 0,
  objectFit: 'cover',
  width: '100%',
  height: '100%',
}));

export const StyledVendorRequiredOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: -1,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `linear-gradient(180deg, ${theme.palette.primary.contrast}85 14.61%, ${theme.palette.primary.contrast}00 34.7%)`,
}));

export const StyledVendorContentContainer = styled(DialogContent)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.contrast,
    textAlign: 'left',
    color: theme.palette.leftMenuCollapsed.inactive,
    borderRadius: '16px 16px 0px 0px',
    zIndex: 1,
    filter: `drop-shadow(0px -35px 25px ${theme.palette.leftMenuCollapsed.axeleIcon}25)`,
    backdropFilter: 'blur(12px)',

    //Update css
    padding: '0 !important',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    position: 'relative',
    flexGrow: 1,
    // height: `calc(100% - 350px)`,
    // maxHeight: 'calc(100% - 350px)',
  })
);

export const StyledVendorAddContactContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16,
  marginTop: 16,
  marginBottom: 16,
}));

export const StyledTypography = styled(Typography)(
  ({ theme, color, fontSize, fontWeight, ...rest }: any) => {
    const getColor = () => {
      switch (color) {
        case 'primary':
          return theme.palette.primary.main;
        default:
          return null;
      }
    };

    return {
      color: getColor(),
      fontSize,
      fontWeight,
      ...rest,
    };
  }
);
