import { useFormContext } from 'react-hook-form';
import { dispatchService } from '../../../../../../../api';
import { ITrailerInfoForDriverAssign } from '../../../../../../../models/DTOs/Dispatch/Dispatch';
import { GetTrailerInfoForDriverAssignRequest } from '../../../../../../../models/DTOs/Dispatch/Requests';
import { gridPageSize } from '../../../../../../../utils';
import { SingleAutocompleteForm } from '../../../../../../Ui/SingleAutocomplete';
import { Trip } from '../../../Models';
import AssignmentFormWrapper from '../../AssignmentFormWrapper';

export default function TrailerAssignmentToDriver({
  trip,
  onClose,
  onAssign,
  apiAssignHandler,
}: {
  trip: Trip;
  onClose: () => void;
  onAssign?: (trailer: ITrailerInfoForDriverAssign) => void;
  apiAssignHandler: (trailer: ITrailerInfoForDriverAssign) => Promise<void>;
}): JSX.Element {
  const handleTrailerAssign = async ({
    trailer,
  }: {
    trailer: ITrailerInfoForDriverAssign;
  }) => {
    if (trailer?.id) {
      if (apiAssignHandler) {
        await apiAssignHandler(trailer);
      }
      onAssign?.(trailer);
    }
    onClose();
  };

  return (
    <AssignmentFormWrapper
      onAction={handleTrailerAssign}
      onClose={onClose}
      data={trip.trailer}
      title="Assign Trailer"
      actionLabel="Assign"
    >
      <FormComponent />
    </AssignmentFormWrapper>
  );
}

const FormComponent = () => {
  const { control } = useFormContext();
  return (
    <SingleAutocompleteForm
      control={control}
      disableClear
      name="trailer"
      fieldName="name"
      label="Trailer"
      getOptions={(searchText: string | undefined, pageNumber: number) =>
        dispatchService.getTrailerInfoForDriverAssign(
          new GetTrailerInfoForDriverAssignRequest({
            axeleIdFilter: searchText,
            pageNumber,
            pageSize: gridPageSize,
            statusList: ['AVAILABLE'],
          })
        )
      }
    />
  );
};
