import {
  DriverTeamWithTrips,
  FetchDriverGroupDataWithTripsRequest,
  GetDriverTeamRequest,
  ImplicitAssignUpdateRequest,
  PaginatedAllTeamDriverListRequest,
  TeamDriver,
  TeamDriverFilterObject,
  TeamDriverPaginatedRequest,
  TeamDriverPaginatedRespones,
} from '../../models';
import { PaginatedResult } from '../../types';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class ITeamDriverService extends Service {
  abstract getTeamDriverList(
    requestData: PaginatedAllTeamDriverListRequest,
    isDetailsUrl?: boolean
  ): Promise<PaginatedResult<TeamDriver> | ServiceError>;
  abstract fetchDriverGroupDataWithTrips(
    requestData: FetchDriverGroupDataWithTripsRequest
  ): Promise<DriverTeamWithTrips | ServiceError>;
  abstract getDriverTeamFilterList(
    requestData: GetDriverTeamRequest,
    skipTrackPromise?: boolean
  ): Promise<PaginatedResult<TeamDriverFilterObject> | ServiceError>;
  abstract getDriverTeamTractorList(
    requestData: GetDriverTeamRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract getDriverTeamTrailerList(
    requestData: GetDriverTeamRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract creatTeamDriver(
    requestData: TeamDriverPaginatedRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract deleteTeamDriver(
    requestData: string
  ): Promise<string | null | ServiceError>;
  abstract teamDriverActive(
    requestData: TeamDriverPaginatedRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract assignTractor(
    requestData: TeamDriverPaginatedRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract unassignTractor(
    requestData: TeamDriverPaginatedRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract assignTrailer(
    requestData: TeamDriverPaginatedRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract unassignTrailer(
    requestData: TeamDriverPaginatedRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract getValidateWarning(
    requestData: TeamDriverPaginatedRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract getValidateWarningsRecord(
    requestData: TeamDriverPaginatedRequest
  ): Promise<TeamDriverPaginatedRespones | null | ServiceError>;
  abstract implicitAssignUpdate(
    requestData: ImplicitAssignUpdateRequest
  ): Promise<string | ServiceError>;
}
