import { Theme } from '@mui/material';
import { BREAKPOINT_TABLET } from '../../hooks/useThemeResponsive';

export const layoutMenuStyles = (theme: Theme) => ({
  height: '100%',
  p: 1,
  display: 'flex',
  flexDirection: 'column',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  border: '1px solid transparent',
  backgroundColor: theme.palette.mainContainerBG.backgroundColor,
  overflow: 'auto',
  transform: 'none!important',
  top: 'auto!important',
  'backface-visibility': 'hidden',
  '-webkit-backface-visibility': 'hidden',

  [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
    marginLeft: 0,
    width: '100%',
  },
});
