import { LoadDetailsResponse } from '../../models';
import {
  DeleteTenderResponse,
  ExportEDITendersRequest,
  GetEDITenderResponseDTO,
  GetEDITendersRequest,
  GetFilterListRequest,
  GetProvidersResponse,
  LoadMappingResponse,
  UpdateShipmentStatusBody,
  UpdateShipmentStatusErrorResponse,
  UpdateShipmentStatusParams,
  UpdateShipmentStatusResponse,
} from '../../models/DTOs/EDI/Requests';
import { GetListFilterResponse } from '../../models/DTOs/EDI/Responses';
import { docType } from '../../utils/Doc';
import { EEDITendersStatus } from '../../views/EDITenders/constants/types';
import { Service } from './Service';
import { ServiceError } from './helperTypes';

export abstract class IEDIService extends Service {
  abstract getEDITenders(
    requestBody: GetEDITendersRequest
  ): Promise<GetEDITenderResponseDTO | null | ServiceError>;

  abstract getFilterList(
    request: GetFilterListRequest
  ): Promise<GetListFilterResponse | null | ServiceError>;

  abstract updateShipmentStatus(
    requestParams: UpdateShipmentStatusParams,
    requestBody: Array<UpdateShipmentStatusBody>,
    action: EEDITendersStatus | string
  ): Promise<
    | UpdateShipmentStatusResponse
    | UpdateShipmentStatusErrorResponse
    | LoadDetailsResponse
    | null
    | ServiceError
  >;

  abstract deleteTender(
    tenderId: string
  ): Promise<DeleteTenderResponse | null | ServiceError>;

  abstract loadMapping(
    tenderId: string
  ): Promise<LoadMappingResponse | null | ServiceError>;

  abstract bulkLoadMapping(
    tenderIds: Array<string>
  ): Promise<LoadMappingResponse | null | ServiceError>;

  abstract exportToExcel(
    payload: ExportEDITendersRequest
  ): Promise<docType | ServiceError>;

  abstract getProviders(): Promise<GetProvidersResponse | ServiceError>;
}
