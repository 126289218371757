import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

export const StyledAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `unset`,
}));

export const StyledAccordionSummary = styled(
  ({
    isCancelled = false,
    isManifest = false,
    ...props
  }: AccordionSummaryProps & {
    isCancelled?: boolean;
    isManifest?: boolean;
  }) => (
    <MuiAccordionSummary
      expandIcon={
        !isManifest && <KeyboardArrowRightOutlinedIcon fontSize="small" />
      }
      {...props}
      {...(isCancelled && {
        expandIcon: null,
      })}
    />
  )
)(({ theme }: { theme: any }) => ({
  backgroundColor: theme.palette.uncategorized.accordion,
  corlor: theme.palette.text.primary,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
    color: theme.palette.primary.main,
    // display :'none'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
    color: theme.palette.primary.main,
    // display :'none'
  },

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const StyledAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 'unset',
  borderTop: 'unset',
}));

export const StyleAccordionSummaryTitle = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }: { theme?: any }) => ({
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap',
  ...theme.typography.body3,
  fontWeight: 700,
  display: 'flex',
  direction: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const StyleAccordionSummaryRightAction = styled((props: BoxProps) => (
  <Box {...props} />
))(() => ({
  position: 'absolute',
  right: '15px',
  display: 'inline-flex',
  marginTop: '-5px',
}));
export const StyleAccordionSummaryRightActionBroker = styled(
  ({
    isProgressVisible = false,
    ...props
  }: AccordionSummaryProps & {
    isProgressVisible?: boolean;
  }) => <Box {...props} />
)((isProgressVisible) => ({
  position: 'absolute',
  // right: isProgressVisible ? '50px' : '0px',
  display: 'inline-flex',
  marginTop: '5px',
}));

export const StyleIconButton = styled((props: IconButtonProps) => (
  <IconButton size={'small'} color="primary" {...props} />
))(({ theme }: { theme: any }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.light,
  borderRadius: '8px',
  padding: '2px',
  marginLeft: '5px',
  marginBottom: '5px',
}));

export const StyleAccordionSummarySwitcher = styled(
  (props: TypographyProps) => <Typography {...props} />
)(({ theme, checked }: { theme?: any; checked: boolean }) => ({
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  ...theme.typography.body1,
  ...(checked && {
    color: theme.palette.primary.main,
  }),
}));
