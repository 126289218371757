import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Theme } from '@mui/material';
import clsx from 'clsx';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { FormProvider, FormState, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { EventTypes } from '../../EventEmitter/EventTypes';
import useEmit from '../../utils/hooks/useEmit';
import { AXELE_PERMISSION_TYPE } from '../Permission';
import { useThemeResponsive } from '../hooks/useThemeResponsive';
import Footer from './components/Footer';
import { IDetailsPanelThreeDotMenuOption } from './components/ThreeDotMenu';
import Title from './components/Title';
import { DirtyDetailsPanelManager, isAsyncFunction } from './utils';

// @TODO: Nvard - interface for data prop should be handled

export interface Panel3DotMenuHeaderProps {
  showThreeDotMenuIcon?: boolean;
  showDeleteButton?: boolean;
  menuOptions?: IDetailsPanelThreeDotMenuOption[];
  entity?: string;
  onDelete?: (id: string) => void;
  onOpen3DotMenu?: (event: React.MouseEvent<HTMLElement>) => void;
}

type FormInputs = Record<string, unknown>;
export interface PanelDetailsUpdateEventProps {
  //TODO: formContext can be use from external DetailPanels
  onUpdate?: (
    data: FormInputs,
    formState: FormState<any>
  ) => void | Promise<void>;
}
export interface PanelCommonProps
  extends Panel3DotMenuHeaderProps,
    PanelDetailsUpdateEventProps {
  data: any;
  panelTitle: ReactNode;
  showTitle?: boolean;
  darkHeader?: boolean;
  onClose: () => void;
  onDelete?: (id: string) => void | Promise<void>;
  onCreate?: (
    data: FormInputs,
    formState: FormState<any>
  ) => void | Promise<void>;
  subHeaderRenderer?: () => ReactNode;
  tabStripRenderer?: () => ReactNode;
  contentRenderer: (expanded: boolean) => ReactNode;
  footerRenderer?: () => ReactNode;
  titleRenderer?: () => ReactNode;
  validationSchema?: AnyObjectSchema;
  httpErrors?: any;
  actionButtonLabel?: string;
  isSaveIconVisible?: boolean;
  disableDelete?: boolean;
  autoExpanded?: boolean;
  contentCustomStyles?: {};
  mainContentCustomStyles?: {};
  contentWrapperCustomStyles?: {};
  fullScreenCustomStyles?: {};
  formCustomStyles?: {};
  panelWrapperCustomStyles?: {};
  renderCustomTitle?: (data: any) => JSX.Element | undefined;
  actionsRenderer?: (data: any) => JSX.Element;
  showDeleteButton?: boolean;
  isFullscreen?: boolean;
  setIsFullscreen?: React.Dispatch<React.SetStateAction<boolean>>;
  onFullscreen?: (isFullscreen: boolean) => void;
  handleExpand?: () => void;
  showExpandIcon?: boolean;
  showCloseIcon?: boolean;
  isPanelTitleVisible?: boolean;
  style?: Record<string, any>;
  animationDuration?: number;
  secondaryActionButtonLabel?: string;
  secondaryActionButtonIconName?: string;
  onSecondaryAction?: () => void;
  secondaryActionButtonIsDisabled?: boolean;
  permissions?: { [key: string]: AXELE_PERMISSION_TYPE[] };
  isEmbedded?: boolean; //DetailsPanel is embedded in DetailsPanel
  onReset?: () => void;
  secondaryActions?: {
    secondaryActionButtonLabel?: string;
    secondaryActionButtonIconName?: string;
    onSecondaryAction?: () => void;
    secondaryActionButtonIsDisabled?: boolean;
  }[];
}
export interface PanelProps extends PanelCommonProps {
  onClose: () => void;
  permissions: { [key: string]: AXELE_PERMISSION_TYPE[] } | undefined;
  isLastIndex?: boolean;
  isFullScreen?: boolean;
  height?: string;
  isCreateForm?: boolean;
  alwaysShowActionButtons?: boolean;
}

export type PanelFSProps = Pick<
  PanelProps,
  | 'data'
  | 'entity'
  | 'panelTitle'
  | 'renderCustomTitle'
  | 'validationSchema' //each panel has a specific validation schema
  | 'onUpdate' //each panel has a specific onUpdate
  | 'contentRenderer' //each panel has a specific contentRenderer
  | 'panelWrapperCustomStyles'
  | 'tabStripRenderer' //each panel has a specific tabStripRenderer
  | 'subHeaderRenderer' //each panel has a specific subHeaderRenderer
  | 'footerRenderer'
  | 'contentCustomStyles'
>;

export const Panel: React.FC<PanelProps> = ({
  data,
  panelTitle,
  showTitle = true,
  validationSchema,
  onClose,
  onUpdate,
  onCreate,
  contentRenderer,
  subHeaderRenderer,
  tabStripRenderer,
  titleRenderer,
  footerRenderer,
  httpErrors,
  actionButtonLabel = 'Save Changes',
  isSaveIconVisible = false,
  contentCustomStyles = {},
  mainContentCustomStyles = {},
  contentWrapperCustomStyles = {},
  formCustomStyles = {},
  panelWrapperCustomStyles = {},
  renderCustomTitle,
  actionsRenderer,
  handleExpand,
  showExpandIcon = true,
  showCloseIcon = true,
  style,
  isPanelTitleVisible = true,
  secondaryActionButtonLabel,
  secondaryActionButtonIconName,
  onSecondaryAction,
  secondaryActionButtonIsDisabled,
  permissions,
  isLastIndex,
  isFullScreen,
  isCreateForm,
  height,
  onReset,
  secondaryActions = [],
  alwaysShowActionButtons = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const state = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: data,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });
  const { isMobile } = useThemeResponsive();
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    setError,
    formState,
  } = state;

  useEffect(() => {
    DirtyDetailsPanelManager.isDirty = isDirty;
  }, [isDirty]);

  useEffect(() => {
    if (data) {
      reset(data, { keepDefaultValues: false });
    }
  }, [data]);

  useEffect(() => {
    if (Array.isArray(httpErrors)) {
      httpErrors?.forEach((error) => {
        setError(error.fieldName, {
          type: 'httpError',
          message: error.message,
        });
      });
    }
  }, [httpErrors]);

  const handleSave = (data: FormInputs) => {
    if (data.id) {
      if (onUpdate) {
        if (isAsyncFunction(onUpdate)) {
          setIsSubmitting(true);
        }
        onUpdate(data, formState)?.finally(() => {
          setIsSubmitting(false);
        });
      }
    } else {
      if (onCreate) {
        if (isAsyncFunction(onCreate)) {
          setIsSubmitting(true);
        }
        onCreate({ ...data }, formState)?.finally(() => {
          setIsSubmitting(false);
        });
      }
    }
  };

  const shouldShowSaveButton = useMemo((): boolean => {
    return alwaysShowActionButtons || !data?.id || isDirty;
  }, [data?.id, isDirty, alwaysShowActionButtons]);

  const onCancel = (): void => {
    if (Boolean(data?.id)) {
      reset(); //reset current form
      onReset?.();
      return;
    }
    onClose?.();
  };

  const onCloseHandler = (): void => {
    onCancel();
    onClose?.();
  };
  const onResetChangesHandler = (): void => {
    onCancel();
  };

  //#region Shake behovier

  const [shouldShake, setShouldShake] = useState(false);

  const animationClassName = useMemo(() => {
    setTimeout(() => {
      setShouldShake(false);
    }, 1000);
    //https://animate.style/
    return clsx({
      animate__animated: shouldShake,
      animate__shakeX: shouldShake,
      'animate__repeat-1': shouldShake,
    });
  }, [shouldShake]);

  useEmit({
    [EventTypes.PANEL_SHOULD_SHAKE]: () => {
      //@TODO : Sargis. Remove after load panels refactoring
      if (DirtyDetailsPanelManager.panelName) {
        setShouldShake(true);
        return;
      }
      //@TODO

      if (isDirty) setShouldShake(true);
    },
  });

  useEffect(() => {
    return () => {
      DirtyDetailsPanelManager.isDirty = false;
    };
  }, []);

  //#endregion

  const panelTitleRenderer = () => {
    if (typeof titleRenderer === 'function') {
      return titleRenderer();
    } else if (showTitle) {
      return (
        <Title
          title={panelTitle}
          renderCustomTitle={renderCustomTitle}
          data={data}
          actionsRenderer={actionsRenderer}
          onClose={onCloseHandler}
          onResetChangesHandler={onResetChangesHandler}
          showExpandIcon={showExpandIcon}
          showCloseIcon={showCloseIcon}
          onExpand={handleExpand}
          isDirty={isDirty}
          isLastIndex={isLastIndex}
          isFullScreen={isFullScreen}
        />
      );
    }
  };

  return (
    <Box
      sx={(theme: Theme) => ({
        backgroundColor: `#35446B!important`,
        height: '100%',
        ...(isMobile
          ? {
              position: 'absolute!important',
              zIndex: '100000!important',
            }
          : {}),
        ...style?.wrapper,
        ...panelWrapperCustomStyles,
        ...mainContentCustomStyles,
      })}
      className={animationClassName}
    >
      {isPanelTitleVisible && panelTitleRenderer()}
      <Box
        sx={(theme) => ({
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'relative',
          background: 'rgb(53, 68, 107)',
          ...contentWrapperCustomStyles,
          backgroundColor: '#ffffff',
        })}
      >
        {tabStripRenderer && (
          <div>
            {/* this div is workaround, when tabs will be corrected from the ui-kit, we can remove it @toto Nand*/}
            {tabStripRenderer && tabStripRenderer()}
          </div>
        )}

        {subHeaderRenderer && subHeaderRenderer()}
        <div
          style={{
            padding: '0 0px',
            maxHeight: '100%',
            overflowY: 'auto',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            height: '100%',
            ...contentCustomStyles,
          }}
        >
          <FormProvider {...state}>
            <form
              onSubmit={handleSubmit(handleSave)}
              style={{
                height: '100%',
                ...formCustomStyles,
                // backgroundColor: '#ffffff',
                // paddingLeft: '15px',
                // paddingRight: '15px',
                overflowY: 'auto',
              }}
            >
              {contentRenderer(!!style?.shake)}
            </form>
          </FormProvider>
        </div>
      </Box>

      <Box
        id="footerRenderer"
        sx={{
          width: '100%',
        }}
      >
        {footerRenderer ? (
          footerRenderer()
        ) : (
          <Footer
            onSave={handleSubmit(handleSave)}
            onCancel={onCancel}
            shouldShake={shouldShake}
            actionButtonLabel={actionButtonLabel}
            isSaveIconVisible={isSaveIconVisible}
            showSaveButton={shouldShowSaveButton}
            showCancelButton={shouldShowSaveButton}
            secondaryActionButtonLabel={secondaryActionButtonLabel}
            secondaryActionButtonIconName={secondaryActionButtonIconName}
            onSecondaryAction={onSecondaryAction}
            secondaryActionButtonIsDisabled={secondaryActionButtonIsDisabled}
            permissions={permissions}
            // isSubmitting={isSubmitting}
            secondaryActions={secondaryActions}
          />
        )}
      </Box>
    </Box>
  );
};
