import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const lightThemeObject: Theme = {
  palette: {
    mainContainerBG: {
      backgroundColor: '#F7F7F7',
    },
    primary: {
      main: '#2B64CB',
      light: '#699EFF',
      dark: '#114199',
      contrast: '#FFFFFF',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #2B64CB',
      outlinedHoverBackground: alpha('#2B64CB', 0.08),
      outlinedRestingBorder: alpha('#2B64CB', 0.5),
      sideTab: alpha('#2B64CB', 0.4),
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
      contrast: '#FFFFFF',
      contrastText: '#F77B72',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #F44336',
      outlinedHoverBackground: alpha('#F44336', 0.08),
      outlinedRestingBorder: alpha('#F44336', 0.5),
      content:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #F44336',
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336',
    },
    warning: {
      main: '#ED6C02',
      dark: '#C77700',
      light: '#FFB547',
      contrast: '#FFFFFF',
      contrastText: '#ED9D02',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #ED6C02',
      outlinedRestingBorder: alpha('#ED6C02', 0.5),
      outlinedHoverBackground: alpha('#ED6C02', 0.08),
      content:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #ED6C02',
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02',
    },
    secondary: {
      main: '#008280',
      light: '#19AFAF',
      dark: '#0C5654',
      contrast: '#FFFFFF',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #008280',
      outlinedHoverBackground: alpha('#008280', 0.08),
      outlinedRestingBorder: alpha('#008280', 0.5),
    },
    info: {
      main: '#2196F3',
      dark: '#0B79D0',
      light: '#64B6F7',
      contrast: '#FFFFFF',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #2196F3',
      outlinedRestingBorder: alpha('#2196F3', 0.5),
      outlinedHoverBackground: alpha('#2196F3', 0.08),
      content:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #2196F3',
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
      contrast: '#FFFFFF',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #4CAF50',
      outlinedRestingBorder: alpha('#4CAF50', 0.5),
      outlinedHoverBackground: alpha('#4CAF50', 0.08),
      content:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4CAF50',
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50',
    },
    text: {
      primary: alpha('#001122', 0.75),
      secondary: alpha('#001122', 0.6),
      disabled: alpha('#001122', 0.35),
    },
    leftMenuCollapsed: {
      axeleIcon: '#4E6578',
      inactive: '#4E6578',
      active: '#2B64CB',
      activeBackground:
        'linear-gradient(270deg, rgba(43, 100, 203, 0.27) 0%, rgba(43, 100, 203, 0) 75%)',
      canvas: '#35446B',
    },
    leftMenuExpanded: {
      inactive: '#669EFF',
      active: '#FFFFFF',
      primaryInactiveHover: alpha('#FFFFFF', 0.15),
      primaryInactivePressed: alpha('#000000', 0.4),
      primaryActiveNormalGradient:
        'linear-gradient(270deg, rgba(255, 255, 255, 0.27) 0%, rgba(255, 255, 255, 0) 100%)',
      primaryActiveHoverGradient:
        'linear-gradient(270deg, rgba(255, 255, 255, 0.6) 0%, rgba(76, 97, 120, 0.4) 100%)',
      primaryActivePressedGradient:
        'linear-gradient(270deg, rgba(217, 217, 217, 0.27) 0%, rgba(0, 0, 0, 0.2) 100%)',
      secondaryInactiveNormal: alpha('#000000', 0.25),
      secondaryInactiveHover: alpha('#FFFFFF', 0.1),
      secondaryInactivePressed: alpha('#000000', 0.5),
      secondaryActiveNormal:
        'linear-gradient(270deg, rgba(121, 132, 150, 0.5) 0%, rgba(8, 13, 18, 0.4) 100%)',
      secondaryActiveHover:
        'linear-gradient(270deg, rgba(240, 240, 240, 0.4) 0%, rgba(48, 59, 77, 0.4) 100%)',
      secondaryActivePressed:
        'linear-gradient(270deg, rgba(90, 98, 112, 0.5) 0%, rgba(0, 0, 0, 0.4) 100%)',
      panelBorder: alpha('#1C2C3D', 0.89),
      panel: alpha('#1C2C3D', 0.89),
      sidePanel: alpha('#18212B', 0.93),
      selectedTabPanelTab: alpha('#669EFF', 0.12),
    },
    action: {
      active: alpha('#001122', 0.6),
      hover: alpha('#001122', 0.04),
      selected: alpha('#001122', 0.08),
      disabled: alpha('#001122', 0.26),
      disabledBackground: alpha('#001122', 0.12),
      focus: alpha('#001122', 0.12),
    },
    uncategorized: {
      break: '#C81800',
      netProfit: alpha('#669EFF', 0.1),
      canvas: '#E8E9EE',
      canvas_02: '#35446B',
      panel: '#F7F7F7',
      panelTopGradient:
        'linear-gradient(180deg, rgba(250, 250, 250, 0.6) 0%, rgba(255, 255, 255, 0) 61.72%)',
      card: '#FFFFFF',
      tooltip:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D',
      popup: alpha('#030E18', 0.89),
      accordion: '#F5F5F5',
      oldHeaderSecondary: '#EEF3FB',
      headerSecondary: '#D9E5F8',
      headerSidePanel: alpha('#35446B', 0.89),
    },
    divider: '#E5E5E5',
    other: {
      outlineBorder: '#FFFFFF',
      standartInputLine: '#FFFFFF',
      backdropOverlay: alpha('#001122', 0.5),
      ratingActive: '#FFB400',
      snackbarBackground: '#323232',
      default: '#FAFAFA',
      dataGridCellActive:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D', // ? no value in light
      liquidNitrogen: '#f1f1f2',
      gridColumnHeaderColor: '#1F2E3D',
      gridScrollbarColor: 'rgba(0, 0, 0, 0.09)',
      gridScrollbarActiveColor: '#878787',
      gridErrorColor: '#f4433614',
      gridWarningColor: '#ed6c0214',
      gridBulkActionDisabled: 'rgba(255, 255, 255, 0.35)',
      warningBorderColor: '#ED9D02',
      warningBackgroundColor: '#FEF7EB',
    },
    tabStrip: {
      tabText: '#FFFFFF',
      actionIcon: alpha('#FFFFFF', 0.6),
      actionPressed:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #669EFF',
      inactiveHover: '#FFFFFF',
      inactivePressed: alpha('#669EFF', 0.26),
      addHover: alpha('#2B64CB', 0.04),
      addPressed: alpha('#2B64CB', 0.24),
      sidePanelTabText: '#000000',
      sidePanelTabIcon: alpha('#FFFFFF', 0.75),
    },
    grey: {
      a100: '#D5D5D5',
      a200: '#AAAAAA',
      a300: '#66707A',
      a400: '#616161',
      a700: '#303030',
      a900: '#00112299',
    },
    chip: {
      availableBg: '#F2984E',
      plannedColor: '#f1f1f2',
      plannedBg: '#5C667D',
      assignedColor: '#f1f1f2',
      assignedBg: '#2F3B58',
      assigned2Bg: '#78EAEE',
      inTransitColor: '#f1f1f2',
      inTransitBg: '#0C6BB9',
      completedBg: '#B6BDC7',
      canceledBg: '#F77B72',
      invoicedBg: '#82C784',
      closedStatementBg: '#9E9E9E',
      reviewedStatementBg: '#4CAF50',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    buttonLarge: {
      fontSize: '0.9375rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    buttonMedium: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    buttonSmall: {
      fontSize: '0.8125rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    avatar: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    inputLabel: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    helperText: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    inputText: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    tooltip: {
      fontSize: '0.625rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    chip: {
      fontSize: '0.8125rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    alert: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    tableHeader: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    badgeLabel: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    kpiValue: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.167,
    },
    smallTab: {
      fontSize: '0.6875rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    h7: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.167,
    },
    h8: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    body3: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    tableFooterTotals: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: 1.167,
    },
    body4: {
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    body2Bold: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.167,
    },
  },
};
