import { Box, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomerRequiredDocOptions } from '../../constants/fieldOptions';
import { LoadPriorityEnumVals } from '../../constants/fieldValues';
import { VerticalDividerStyle } from '../../style';
import { formatPhoneNumber } from '../../utils';

const CustomerSummary = () => {
  const { getValues } = useFormContext();
  const customer = getValues('customer');
  const primaryContact = customer?.selectedPhoneData;
  // customer?.contacts?.find(
  //   (contact: ContactSummary) => contact?.id === customer?.customerContactId
  //   // contact.isPrimary && contact
  // );
  const getCustomerRequiredDocumentsText = useMemo(() => {
    const docs = CustomerRequiredDocOptions?.filter?.((doc) =>
      customer?.requiredDocuments?.includes?.(doc.id)
    )?.map?.((e) => e?.label);
    return docs?.join?.(', ');
  }, [customer?.requiredDocuments]);

  if (!customer?.customerId) return <></>;
  return (
    <>
      <Stack direction="row" gap={1} alignItems={'center'} width={'93%'}>
        {customer.customerName && (
          <>
            <Typography variant="body1">
              <b>{customer.customerName}</b>
            </Typography>
            {Boolean(
              customer.invoiceCompanyName || customer?.factoringName
            ) && <Divider orientation="vertical" sx={VerticalDividerStyle} />}
          </>
        )}
        {Boolean(customer?.customerId && customer.invoiceCompanyName) && (
          <>
            <Typography variant="body1" color="textSecondary" fontWeight={600}>
              Invoice: {customer.invoiceCompanyName}
            </Typography>
            {/* {Boolean(customer.factoringName) && (
              <Divider orientation="vertical" sx={VerticalDividerStyle} />
            )} */}
          </>
        )}
      </Stack>
      {Boolean(customer?.customerId && customer.factoringName) && (
        <Box width="100%" ml={4}>
          <Typography variant="body1" color="textSecondary" fontWeight={600}>
            {customer.factoringName}
          </Typography>
        </Box>
      )}
      <Stack direction="column" gap={'2px'} marginLeft={'34px'}>
        {primaryContact && (
          <Stack direction="row" gap={1} alignItems={'center'}>
            {primaryContact?.fullName && (
              <Typography variant="body2" color="textSecondary">
                <b>{primaryContact.fullName}</b>
              </Typography>
            )}
            {primaryContact?.phoneData?.phone && (
              <Typography variant="body2" color="textSecondary">
                {formatPhoneNumber(primaryContact.phoneData)}
              </Typography>
            )}
            {!!primaryContact?.email && (
              <>
                {primaryContact?.phoneData?.phone && (
                  <Divider orientation="vertical" sx={VerticalDividerStyle} />
                )}
                <Typography variant="body2" color="textSecondary">
                  {primaryContact?.email}
                </Typography>
              </>
            )}
          </Stack>
        )}

        <Stack direction="row" gap={1} alignItems={'center'}>
          {customer.referenceNumber && (
            <>
              <Typography variant="body2" color="textSecondary">
                {customer.referenceNumber}
              </Typography>
              <Divider orientation="vertical" sx={VerticalDividerStyle} />
            </>
          )}
          {!!customer.requiredDocuments?.length ? (
            <Typography variant="body2" color="textSecondary">
              {getCustomerRequiredDocumentsText}
            </Typography>
          ) : (
            ''
          )}
          {Boolean(customer?.customerId && customer.loadPriority) && (
            <>
              {!!customer.requiredDocuments?.length && (
                <Divider orientation="vertical" sx={VerticalDividerStyle} />
              )}
              <Typography variant="body2" color="textSecondary">
                Priority: {LoadPriorityEnumVals[customer.loadPriority] ?? ''}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
export default CustomerSummary;
