import {
  FactoringSummary,
  FactoringListRequest,
  ActivateFactoryRequest,
  ActivateFactoringCustomerRequest,
} from '../../models';
import { annotateNameAsync, IFactoringService } from '../interfaces';
import { ComposedError, httpClient } from '../../axios/axiosInstance';

const getFactoringListURL =
  '/web/factoring/api/v2/integrations/organization/summary';
const integrationsURL = '/web/factoring/api/v2/integrations';
const factoringDeactiveURL = '/web/factoring/api/v2/integrations/alter';
export class FactoringService extends IFactoringService {
  @annotateNameAsync
  async getFactoringList(requestData: FactoringListRequest) {
    try {
      return await httpClient.get<FactoringSummary[]>(
        getFactoringListURL,
        requestData,
        FactoringSummary,
        false,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async activateFactoringProvider(payloadData: ActivateFactoryRequest) {
    try {
      return await httpClient.post<any>(
        integrationsURL,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      } else {
        // something else
        throw this.getServiceError(error as Error);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async activateFactoringProviderUpdate(payloadData: ActivateFactoryRequest) {
    try {
      return await httpClient.post<ActivateFactoringCustomerRequest>(
        integrationsURL,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      } else {
        // something else
        throw this.getServiceError(error as Error);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async factoringDeactive(payloadData: ActivateFactoryRequest) {
    try {
      return await httpClient.put<ActivateFactoringCustomerRequest>(
        factoringDeactiveURL,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      } else {
        // something else
        throw this.getServiceError(error as Error);
      }
      return Promise.resolve(null);
    }
  }
}
