export const CommonScrollBarStyles = {
  height: '100%',
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    backgroundColor: 'uncategorized.panel',
    width: '10px',
    height: '10px',
  },
  '&::-webkit-scrollbar:hover, & *::-webkit-scrollbar:hover': {
    // width: '13px',
    // height: '13px',
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    borderRadius: '27px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    minHeight: 12,
    width: 2,
  },
  '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
  },
  '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
  },
  '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
    background: '#555',
    // width: '13px',
    // height: '13px',
  },
  '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent',
  },
};
