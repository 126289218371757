import { yupResolver } from '@hookform/resolvers/yup';
import { Skeleton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { cloneDeep, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission';
import { getHasPermission } from '../../../../common/Permission/utils/helperUtils';
import { CommodityTypeProvider } from '../../../../contexts/CommodityTypeContext';
import { OptionType } from '../../../../models';
import { useStores } from '../../../../store/root.context';
import LoadController from '../../../../subPages/loadsList/LoadController';
import { Commodity as CommodityModel } from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { LoadSummary } from '../../../../subPages/loadsList/models/LoadSummary';
import { updateNoteDetails } from '../../../../utils/loads';
import { DirtyDetailsPanelManager } from '../../../DetailsPanel/utils';
import { CommodityList } from '../../../LoadSharedComponents/components/Commodity/CommodityList';
import { getTotalWeight } from '../../../LoadSharedComponents/components/Commodity/utils';
import { Footer } from '../../../LoadSharedComponents/components/SaveChangesFooter';
import NoData from '../../component/NoData';
import { Equipment } from './components/Equipment';
import {
  loadAdditionalDetailsDefaultData,
  loadDetailsValidationSchema,
} from './constants';
import { LoadAdditionalDetails } from './models';
import {
  getLoadAdditionalDetails,
  updateLoadAdditionalDetails,
  updateLoadByEquipmentDetails,
} from './utils';

interface IProps {
  load: LoadSummary;
  onUpDated?: any;
}

export const AdditionalDetails = observer(({ load, onUpdated }: IProps) => {
  const isViewMode = !getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_EDIT],
  });
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialLoadData, setInitialLoadData] = useState({
    ...loadAdditionalDetailsDefaultData,
  });
  const [isSaving, setIsSaving] = useState(false);
  const equipmentTypeOptions = useRef<OptionType[]>([]);
  const state = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: { ...loadAdditionalDetailsDefaultData },
    resolver: yupResolver(loadDetailsValidationSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = state;

  const {
    dispatch2TripsStore: { updateMainData },
    myLoadStore: { setUpdatedLoadId },
  } = useStores();

  const emitGridUpdate = (loadId: string) => {
    updateMainData?.({ updatedIds: [loadId] });
    setUpdatedLoadId?.(loadId);
    onUpdated?.();
  };

  useEffect(() => {
    DirtyDetailsPanelManager.panelName = isDirty ? 'AdditionalDetails' : null;

    DirtyDetailsPanelManager.isDirty = isDirty;
  }, [isDirty]);

  useEffect(() => {
    return () => {
      DirtyDetailsPanelManager.panelName = null;
      DirtyDetailsPanelManager.isDirty = false;
    };
  }, []);

  const fetchData = async () => {
    if (load?.id) {
      if (!equipmentTypeOptions.current?.length) {
        const loadStaticData = await LoadController.instance().getLoadStatic();
        equipmentTypeOptions.current = loadStaticData?.content || [];
      }
      const data = await getLoadAdditionalDetails(
        load?.id,
        equipmentTypeOptions.current
      );
      setNoData(!data);
      const loadAdditionalDetailsData = cloneDeep(
        new LoadAdditionalDetails({ ...data, id: load?.id })
      );
      reset(loadAdditionalDetailsData);
      setInitialLoadData(loadAdditionalDetailsData);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [load?.id]);

  const deleteCommodity = async (commodityId) => {
    const { commodityData: initialCommodityData = [] } = initialLoadData;
    initialCommodityData.splice(commodityId, 1);
    const totalWeight = getTotalWeight(initialCommodityData);
    initialLoadData.totalWeight = totalWeight;
    const data = { ...initialLoadData };
    await updateLoadAdditionalDetails(data);
    const { commodityData: dirtyCommodityData = [], ...restDirtyFields } =
      dirtyFields || {};
    const isCommodityDataDirty =
      dirtyCommodityData?.length &&
      dirtyCommodityData.filter(
        (_item, index: number) => index !== commodityId?.length
      );
    if (isEmpty(restDirtyFields) && !isCommodityDataDirty) {
      reset(data, { keepDefaultValues: false, keepDirty: false });
    }
    setInitialLoadData(data);
    updateLoadByEquipmentDetails(load, data);
    emitGridUpdate(load.id);
  };

  const handleSave = async (data: LoadAdditionalDetails) => {
    setIsSaving(true);
    updateNoteDetails(data, 'notes', dirtyFields);
    await updateLoadAdditionalDetails(data);
    reset(data, { keepDefaultValues: false, keepDirty: false });
    setInitialLoadData(data);
    updateLoadByEquipmentDetails(load, data);
    emitGridUpdate(load.id);
    setIsSaving(false);
  };

  const handleCancel = () => {
    reset();
  };

  const handleCommodityDataUpdate = async (
    newCommodity: CommodityModel,
    totalWeight: number | null
  ) => {
    const { commodityData: initialCommodityData = [] } = initialLoadData;
    if (initialCommodityData?.length) {
      initialCommodityData.push(newCommodity);
    } else {
      initialLoadData.commodityData = [newCommodity];
    }
    initialLoadData.totalWeight = totalWeight;
    const data = { ...initialLoadData };
    const updatedData = await updateLoadAdditionalDetails(data);
    if (updatedData) {
      if (!newCommodity?.id) {
        fetchData();
      }
      updateLoadByEquipmentDetails(load, data);
      emitGridUpdate(load.id);
    }
  };

  if (noData && !isLoading) {
    return <NoData />;
  }

  return (
    <CommodityTypeProvider>
      <Box
        sx={{
          height: '100%',
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: (theme) => {
            return `${theme.palette.uncategorized?.card}`;
          },
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            mb: 4,
          }}
        >
          {isLoading ? (
            <Stack spacing={2} direction={'column'}>
              <Skeleton height={'3rem'} width={'100%'} variant="rectangular" />
              <Skeleton height={'3rem'} width={'100%'} variant="rectangular" />
              <Skeleton height={'3rem'} width={'100%'} variant="rectangular" />
              <Skeleton height={'3rem'} width={'100%'} variant="rectangular" />
              <Skeleton height={'3rem'} width={'100%'} variant="rectangular" />
              <Skeleton height={'3rem'} width={'100%'} variant="rectangular" />
              <Skeleton height={'3rem'} width={'100%'} variant="rectangular" />
            </Stack>
          ) : (
            <FormProvider {...state}>
              <Equipment
                isViewMode={isViewMode}
                equipmentTypeOptions={equipmentTypeOptions.current}
                load={load}
              />
              <Divider />
              <Box sx={{ mt: 2 }}>
                <CommodityList
                  handleCommodityDataUpdate={handleCommodityDataUpdate}
                  deleteCommodity={deleteCommodity}
                  isViewMode={isViewMode}
                  shouldUpdateCommodityTypes={false}
                />
              </Box>
            </FormProvider>
          )}
        </Box>
        {isDirty && (
          <Footer
            sx={{ position: 'sticky', bottom: '8px', background: '#FFF' }}
            isSaving={isSaving}
            onSave={handleSubmit(handleSave)}
            onCancel={handleCancel}
          />
        )}
      </Box>
    </CommodityTypeProvider>
  );
});
