import { Box, Button, Grid, Stack, Theme, Typography } from '@mui/material';
import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import {
  EvaluationKpiViewButton,
  KpiLabelStyles,
  kpiLabelContainerStyles,
  KpiUnitStyles,
  IssuesTitleContainerStyles,
} from '../styles/evaluation.style';
import {
  DEFAULT_KPIS_DISPLAY_COUNT,
  KPI_BORDER_COLOR_BY_FACTOR,
  KPI_COLOR_BY_FACTOR,
} from '../config/evaluationKpis.config';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { KpiPropsType } from '../types/evaluation.type';

interface IEvaluateKpis {
  kpis: Array<KpiPropsType>;
  onFullViewChange?: () => void;
  type?: 'KPI' | 'ISSUES';
  hideIssuesTitle?: boolean;
}

const EvaluateKpis: React.FC<IEvaluateKpis> = (props) => {
  const [viewAllKpis, setViewAllKpis] = useState<boolean>(false);
  const LabelContainerStylesByIndex = (index: number) => {
    if (index === 0 || (index === 1 && props?.type !== 'ISSUES'))
      return { borderRadius: '4px 0px 0px 0px' };
    return {};
  };

  const ValueContainerStylesByIndex = (
    index: number,
    factor: keyof typeof KPI_COLOR_BY_FACTOR
  ) => {
    const backgroundColor = KPI_COLOR_BY_FACTOR[factor ?? 0];
    const border = `1px solid ${KPI_BORDER_COLOR_BY_FACTOR[factor ?? 0]}`;
    if (index === 0 || (index === 1 && props?.type !== 'ISSUES'))
      return { borderRadius: '0px 4px 0px 0px', backgroundColor, border };
    return { backgroundColor, border };
  };

  const switchKpisViews = () => setViewAllKpis((prev) => !prev);

  const getStackContainerStyles = (i: number) => {
    if (props?.type === 'ISSUES') return {};
    if (i % 2 === 0) return { paddingRight: '4px' };
    return { paddingLeft: '4px' };
  };

  const renderViewButton = () => {
    if (props?.kpis?.length <= DEFAULT_KPIS_DISPLAY_COUNT) return <></>;
    if (viewAllKpis)
      return (
        <Button
          fullWidth
          sx={EvaluationKpiViewButton}
          onClick={switchKpisViews}
        >
          View Less
          <KeyboardArrowUpOutlinedIcon />
        </Button>
      );
    return (
      <Button fullWidth sx={EvaluationKpiViewButton} onClick={switchKpisViews}>
        View More
        <KeyboardArrowDownOutlinedIcon />
      </Button>
    );
  };

  const getLastUnitGapStyles = useCallback((postUnit: string) => {
    if (postUnit && postUnit?.[0] === '/') return { gap: 0 };
    return { gap: '3px' };
  }, []);

  const renderIssuesTitle = () => {
    if (props?.type !== 'ISSUES' || props?.hideIssuesTitle) return <></>;
    return (
      <Box sx={IssuesTitleContainerStyles}>
        <Typography fontSize={14}>
          No pairings could be made due to the reasons below
        </Typography>
      </Box>
    );
  };

  const getGridLevel = (): number => {
    if (props?.kpis?.length === 1 || props?.type === 'ISSUES') return 12;
    return 6;
  };
  const kpiLabelStyles = (): string => {
    if (props?.kpis?.length > 1 && props?.type !== 'ISSUES') return '130px';
    return '100%';
  };
  const kpiValueStyles = (): string => {
    if (props?.kpis?.length > 1 || props?.type !== 'ISSUES') return '100px';
    return '100px';
  };
  const kpiTextFontStyles = useMemo(() => {
    if (props?.type === 'ISSUES') return { fontSize: '14px' };
    return { fontSize: '12px' };
  }, [props?.type]);

  if (!props?.kpis?.length) return <></>;
  return (
    <>
      {renderIssuesTitle()}
      <Grid container>
        {props?.kpis?.map((kpi, i: number) => (
          <Grid key={kpi?.name} item xs={getGridLevel()}>
            <Stack
              direction={'row'}
              height={'100%'}
              sx={getStackContainerStyles(i)}
            >
              <Box
                sx={{
                  ...kpiLabelContainerStyles,
                  textAlign: 'start',
                  ...LabelContainerStylesByIndex(i),
                }}
                width={kpiLabelStyles()}
              >
                <Typography
                  sx={(theme: Theme) => ({
                    ...KpiLabelStyles(theme),
                    borderRadius: '0px 4px 0px 0px',
                    width: '100%',
                    ...kpiTextFontStyles,
                  })}
                >
                  {kpi?.name}
                </Typography>
              </Box>
              <Box
                width={kpiValueStyles()}
                sx={{
                  ...kpiLabelContainerStyles,
                  ...ValueContainerStylesByIndex(
                    i,
                    `${kpi?.goodnessFactor}` as keyof typeof KPI_COLOR_BY_FACTOR
                  ),
                }}
                justifyContent={'space-between'}
                alignItems={'baseline'}
              >
                <Typography sx={KpiUnitStyles}>{kpi?.preUnit ?? ''}</Typography>
                <Box
                  ml="auto"
                  display={'flex'}
                  alignItems="baseline"
                  gap={getLastUnitGapStyles(kpi?.postUnit!)}
                >
                  <Typography sx={{ ...KpiLabelStyles, ...kpiTextFontStyles }}>
                    {kpi?.value}
                  </Typography>
                  {Boolean(kpi?.postUnit?.length) && (
                    <Typography sx={KpiUnitStyles}>
                      {kpi?.postUnit ?? ''}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default EvaluateKpis;
