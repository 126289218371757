import Box from '@mui/material/Box';
import { AddIcon } from '../../ui-kit/components/Assets';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import { useState } from 'react';
import { AXELE_PERMISSION_TYPE, Permission } from '../../common/Permission';
import {
  BottomRightCircle,
  BottomRightCornerCircle,
  TopLeftCircle,
} from './constant';
import ManagePayment from './ManagePayment';
import PaymentInfoPopup from './PaymentInfoPopup';
import { styles } from './styles';

type PaymentCardProps = { data: any; getSubscription: () => void };

const PaymentCard = ({
  data: subscriptionData,
  getSubscription,
}: PaymentCardProps) => {
  const data =
    subscriptionData?.credit_card || subscriptionData?.bank_account || {};
  const [showManage, setShowManage] = useState<boolean>(false);
  const [showPaymentScreen, setShowPaymentScreen] = useState<boolean>(false);

  function cardNumber(text: string) {
    return (
      <Box
        sx={(theme) => ({
          ...theme.typography.body1,
          ...styles.text,
        })}
      >
        {text}
      </Box>
    );
  }

  function details(name: string, label: string, verified: boolean) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={(theme) => ({
            ...theme.typography.body2Bold,
            color: 'tabStrip.sidePanelTabText',
            marginBottom: '15px',
          })}
        >
          {name + ':'}
        </Box>
        {name === 'Account Number' ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={(theme) => ({
                ...theme.typography.body2,
                ...styles.details,
                marginBottom: '0px',
              })}
            >
              {label}
            </Box>
            <Box
              sx={(theme) => ({
                ...theme.typography.body2,
                ...styles.details,
                color: verified ? 'success.main' : 'error.main',
                marginBottom: '15px',
                whiteSpace: 'nowrap',
              })}
            >
              {verified ? 'verified' : 'verification pending'}
            </Box>
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              ...theme.typography.body2,
              ...styles.details,
            })}
          >
            {label}
          </Box>
        )}
      </Box>
    );
  }

  return subscriptionData?.id ? (
    <Box sx={styles.container}>
      <Box sx={styles.flex}>
        <Box sx={styles.title}>Payment Info</Box>
        <Box sx={{ display: 'flex' }}>
          <Permission includes={[AXELE_PERMISSION_TYPE.SUBSCRIPTION_EDIT]}>
            <ButtonImproved
              styleProps={{ marginRight: '5px' }}
              label="Manage Payment"
              variant="contained"
              onClick={() => setShowManage(true)}
            />
          </Permission>
          {showManage && (
            <ManagePayment
              getSubscription={getSubscription}
              defaultId={data.id}
              setShowManage={setShowManage}
              id={subscriptionData?.customer?.id}
              subscriptionId={subscriptionData?.id}
            />
          )}
          <Permission includes={[AXELE_PERMISSION_TYPE.SUBSCRIPTION_ADD]}>
            <ButtonImproved
              onClick={() => setShowPaymentScreen(true)}
              variant="contained"
              startIcon={<AddIcon color="#fff" />}
              styleProps={styles.addicon}
            />
          </Permission>
          {showPaymentScreen && (
            <PaymentInfoPopup
              getSubscription={getSubscription}
              data={subscriptionData}
              setShowPaymentScreen={setShowPaymentScreen}
            />
          )}
        </Box>
      </Box>
      <Box sx={styles.cardContainer}>
        {subscriptionData?.bank_account ? (
          <Box sx={styles.cardRight}>
            {details('Payment Type', `ACH`)}
            {details(
              'Account Number',
              data?.masked_bank_account_number
                ? '****' + data.masked_bank_account_number.slice(-4)
                : '****',
              data?.verified
            )}
            {details(
              'Routing Number',
              data?.masked_bank_routing_number
                ? '****' + data.masked_bank_routing_number.slice(-4)
                : '****'
            )}
          </Box>
        ) : (
          <Box sx={styles.cardLeft}>
            <Box sx={styles.cardRelative}>
              {cardNumber('****')}
              {cardNumber('****')}
              {cardNumber('****')}
              {cardNumber(
                data?.masked_card_number
                  ? data.masked_card_number.slice(-4)
                  : '****'
              )}
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={(theme) => ({
                  ...styles.font,
                  ...theme.typography.body3,
                })}
              >
                <font style={{ fontWeight: 700 }}>Expiration Date</font>{' '}
                {`${data?.expiration_month || ''}/${
                  data?.expiration_year || ''
                }`}
              </Box>
              <Box
                sx={(theme) => ({
                  ...styles.bankName,
                  ...theme.typography.body3,
                })}
              >
                <font style={{ fontWeight: 700 }}>Zip Code</font>{' '}
                {data?.billing_zip || ''}
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                height: '33px',
                ...theme.typography.body3,
                color: 'primary.contrast',
                opacity: 0.7,
              })}
            >
              <font style={{ fontWeight: 700 }}>Name</font>
              <br /> {data?.first_name || ''} {data?.last_name || ''}
            </Box>
            <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
              <TopLeftCircle />
            </Box>
            <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
              <BottomRightCornerCircle />
            </Box>
            <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
              <BottomRightCircle />
            </Box>
          </Box>
        )}
        <Box sx={styles.paymentRight}>
          {details(
            'Name',
            `${subscriptionData?.customer?.first_name || ''} ${
              subscriptionData?.customer?.last_name || ''
            }`
          )}
          {data?.billing_address
            ? details(
                'Address',
                `${data?.billing_address || ''}, ${data?.billing_city || ''}, ${
                  data?.billing_state || ''
                } ${data?.billing_zip || ''}`
              )
            : null}
          {details('Email', subscriptionData?.customer?.email || '')}
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default PaymentCard;
