import React, { FunctionComponent } from 'react';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import IconsComponent from '../IconsComponent';

export interface PopoverProps {
  actions?: Array<{
    label: string;
    onClick: Function;
    iconName: string;
    source: string;
  }>;
  width?: any;
  open?: any;
  onclose?: any;
}

const PopOver: FunctionComponent<PopoverProps> = ({
  actions,
  width = 125,
  open,
  onclose,
}: PopoverProps) => {
  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={onclose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: { width },
      }}
    >
      <List className="list">
        {actions && actions.length
          ? actions.map((action, index) => (
              <ListItem
                button
                onClick={(e) => {
                  e.stopPropagation();
                  action.onClick();
                  onclose();
                }}
                key={`listitem-${action.label}-${index}`}
              >
                {action.iconName ? (
                  <IconsComponent
                    iconName={action.iconName}
                    source={action.source}
                    styleProps={{ marginRight: '5px' }}
                  />
                ) : null}
                {action.label}
              </ListItem>
            ))
          : null}
      </List>
    </Popover>
  );
};

export default PopOver;
