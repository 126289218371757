import { t } from 'i18next';
import { ToolTips } from '../../locales/en/dispatch/tooltips';

export const DRIVER_COLUMN_HEADER_TOOLTIP: { [key: string]: Array<string> } = {
  DRIVER: [ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPDriverName],
  LOCATION: [ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPDriverLocation],
  EQUIPMENT: [
    ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPTractorID,
    ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPTrailerID,
  ],
  REVENUE: [
    ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPProjectedRevenue,
    ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPRevenueGoal,
  ],
  DRIVERPAY: [
    ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPProjectedDriverEarning,
    ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPTargetedDriverEarning,
  ],
  MILEAGE: [
    ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPProjectedMileage,
    ToolTips.DRIVER_COLUMN_HEADER_TOOLTIPTargetedMileage,
  ],
};

export const LOADAI_COLUMN_HEADER_TOOLTIP: { [key: string]: Array<string> } = {
  DRIVER: ['Driver Name'],
  TRIP: ['Trip ID'],
  DHMILES: ['Deadhead Miles'],
  DELAYS: ['Expected Delays'],
  DOLLARPERHOUR: ['Dollar per hour'],
  DOLLARPERMILE: ['Dollar per mile'],
  TRIPPICKUPLOC: ['Trip Pickup Location'],
  DRIVERAVAILABLELOC: ['Driver Availability Location'],
  DRIVERAVAILABLETIME: ['Driver Availability Time'],
  TIMETOPICKUP: ['Time to Pickup'],
};

export enum DRIVER_TOOLTIP_ACTION_KEYS {
  TIMELINE = 'TIMELINE',
  RECOMMEND_TRIPS = 'RECOMMEND_TRIPS',
}

export enum TRIP_TOOLTOP_ACTION_KEYS {
  VIEW_DETAILS = 'VIEW_DETAILS',
  UNASSIGN = 'UNASSIGN',
}
