import { httpClient } from '../../axios/axiosInstance';

import { PreferencesDTO } from '../../models';
import { QueryParams } from '../../models/DTOs/commonMixed/QueryParams';
import StorageManager from '../../StorageManager/StorageManager';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { annotateNameAsync, IPreferenceService } from '../interfaces';

const getPreferencesData = '/web/preference/api/v2/preference/company';
const postPreferencesData = '/web/preference/api/v2/preference/company';
const getCompanyLogo = '/web/afs/api/v2/companyLogo/get';
const postCompanyLogo = '/web/afs/api/v2/companyLogo/create';
const generateAPIRoute = '/web/preference/api/v2/organization/apikey';
const saveWhitelistRoute = '/web/preference/api/v2/organization/whitelist';

const updatePreferenceLocalStorate = (prefData: PreferencesDTO) => {
  if (prefData) {
    StorageManager.setItem('preferredTimeZone', prefData?.timezone);
    StorageManager.setItem('workWeekStartDay', prefData?.workWeekStartDay);
  }
};

export class PreferenceSevice extends IPreferenceService {
  requestData: QueryParams;
  constructor() {
    super();
    this.requestData = new QueryParams();
  }
  @annotateNameAsync
  async getPreferencesData(cancelable = false) {
    try {
      const requestData = new QueryParams();
      const prefData = await httpClient.get<PreferencesDTO>(
        getPreferencesData,
        requestData,
        QueryParams,
        cancelable,
        true
      );
      if (prefData) {
        updatePreferenceLocalStorate(prefData);
      }
      return prefData;
    } catch (error) {
      console.error(error);
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getCompanyLogo() {
    try {
      const requestData = new QueryParams();
      const logoData = await httpClient.get<any>(
        getCompanyLogo,
        requestData,
        QueryParams,
        true,
        true
      );
      return logoData;
    } catch (error) {
      console.error(error);
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async postPreferencesData(queryData: any) {
    try {
      const requestData = new QueryParams();
      const postResponse = await httpClient.patch<PreferencesDTO>(
        postPreferencesData,
        requestData,
        queryData,
        QueryParams,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'savePayItemCategory',
      });
      if (postResponse) {
        updatePreferenceLocalStorate(postResponse);
      }
      return postResponse;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'savePayItemCategory',
      });
      console.error(error);
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async postCompanyLogo(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    try {
      const requestData = new QueryParams();
      const postResponse = await httpClient.post<any>(
        postCompanyLogo,
        requestData,
        formData,
        QueryParams,
        false,
        headers
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'postCompanyLogo',
      });
      return postResponse;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'postCompanyLogo',
      });
      console.error(error);
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async generateAPI(query: any) {
    try {
      const response = await httpClient[
        query.delete ? 'delete' : query.toggle ? 'put' : 'post'
      ]<any>(generateAPIRoute, query);
      return Promise.resolve(response);
    } catch (error) {
      console.error(error);
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async saveWhitelist(query: any) {
    try {
      const response = await httpClient['post']<any>(saveWhitelistRoute, query);
      return Promise.resolve(response);
    } catch (error) {
      console.error(error);
      return Promise.resolve(null);
    }
  }
}
