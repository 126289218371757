import { useFormContext } from 'react-hook-form';
import TextField from '../../../../common/Ui/TextField/TextField';
import { useManageTypes } from '../../../../contexts/ManageTypesContext';
import Box from '@mui/material/Box';
import { units } from '../constants';
import Checkbox from '../../../../common/Ui/Checkbox';
import { MultipleAutocompleteForm } from '../../../../common/Ui/MultipleAutocomplete';
import Typography from '@mui/material/Typography';
import IconPicker from '../../../../common/Ui/IconPicker';

export default function FinancialManageTypeForm() {
  const {
    entity: { name },
  } = useManageTypes();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [custom, id] = getValues(['custom', 'id']);

  const isFieldDisabled = !custom && id;

  return (
    <>
      <TextField
        name="itemName"
        control={control}
        label={`${name} Type Name`}
        disabled={isFieldDisabled}
        required
      />
      <Box sx={{ display: 'flex', mt: 3 }}>
        <Checkbox
          name="invoiceItem"
          control={control}
          label="Invoice Line Item"
          disabled={isFieldDisabled}
        />
        <Checkbox
          name="settlementItem"
          control={control}
          label="Payment Line Item"
          disabled={isFieldDisabled}
        />
      </Box>
      {errors.invoiceItem && (
        <Box>
          <Typography
            variant={'subtitle2'}
            sx={(theme) => ({
              color: `${theme.palette.error.main} !important`,
            })}
          >
            {errors.invoiceItem.message}
          </Typography>
        </Box>
      )}
      <TextField
        name="description"
        control={control}
        label="Description"
        multiline
      />
      <IconPicker name="iconName" control={control} width={'500'} />
      <Box sx={{ mt: 3 }}>
        <MultipleAutocompleteForm
          control={control}
          name="unit"
          label="Unit"
          options={units}
          fieldName="label"
          variant="standard"
          disabled={isFieldDisabled}
          required
        />
      </Box>
      <Checkbox
        name="deduction"
        control={control}
        label="This item is a deduction"
        disabled={!custom && id}
      />
    </>
  );
}
