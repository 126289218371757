export const ROW_GAP = 2;
export const COLUMN_GAP = 2;
export const TAB_HEIGHT = 33;
export const ICON_SIZE = 14;
export const ICON_WIDTH = 16;
export const THREE_DOTS_ICON_WIDTH = 20;
export const MIN_TAB_WIDTH = 76;
export const GAP = 3;
export const TAB_PADDING_X = 5;
export const MAX_TAB_WIDTH = 360;
export const ADD_ICON_WIDTH = 16;
export const FONT = '600 12px Poppins';
export const CHAR_OFFSET = 2;
