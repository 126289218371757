import { ArrowBackOutlined } from '@mui/icons-material';
import { Box, CircularProgress, useTheme } from '@mui/material';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved/ButtonImproved';
import SingleAutocomplete from '../../../ui-kit/components/SingleAutocomplete';
import {
  debounce,
  generateMapForRouteMode,
  generateMapRoute,
  getDispatchDetailsPanelMapData,
  initMarkerCluster,
  isCursorOutsideElement,
  removeMapLayers,
  removeMapSources,
} from './Maps.Cluster.utils';
import MapToolTip from './MapsToolTip';
import Markers from './Markers/Markers';
import constants, { allUnclusteredMarkerlayers } from './constants';
import mapStyle from './light-custom.json';
import { MapsProps, MarkersData } from './types';
import {
  AutoCompleteStyles,
  LoaderContainer,
  MapExternalComponentContainer,
  UnlocatedDropdownContainer,
  mapContainer,
} from './styles';
import { observer } from 'mobx-react';
import { render } from 'react-dom';
import MarkerLoader from './Markers/MarkerLoader';
TrimbleMaps.APIKey = constants.trimbleMapsApiKey as string;

const callIndex = 0;
let popupRef: TrimbleMaps.Popup | null = null;
let currMarkerLoader: TrimbleMaps.Marker | null = null;

const Maps = ({
  center = { lat: 40.78, lng: -84.31 },
  zoom = 3,
  onMapLoaded,
  onMapClick,
  theme = 'light',
  markersList = [],
  generateRoute = false,
  addFilter = false,
  missingMarkers = [],
  handleUnlocatedDrivers,
  onMapInstanceCb,
  height,
  cluster = false,
  onFixDriverHosLocation,
  isLoading = false,
  onViewPanel,
}: MapsProps) => {
  const mapRef = useRef<any>(null);
  const [markersData, setMarkersData] = useState<MarkersData[]>();
  const [isRouteMode, setIsRouteMode] = useState<boolean>(false);
  const [currentZoom, setCurrentZoom] = useState<number>(zoom);
  const [markersInstance, setMarkersInstance] = useState<TrimbleMaps.Marker[]>(
    []
  );
  const [routesInstance, setRoutesInstance] = useState<TrimbleMaps.Route[]>([]);
  const [map, setMap] = useState<any>();
  const showRoute = useRef<any>({ current: false });
  let trimbelMap: TrimbleMaps.Map;
  const currentTheme = useTheme();
  const currentMapInstance = useRef<any>();

  const isRouteViewOpen = useMemo(
    () => isRouteMode || generateRoute,
    [isRouteMode, generateRoute]
  );

  useEffect(() => {
    showRoute.current = isRouteViewOpen;
  }, [isRouteViewOpen]);

  useEffect(() => {
    trimbelMap = new TrimbleMaps.Map({
      container: mapRef?.current,
      center: center,
      zoom: currentZoom,
      doubleClickZoom: false,
      style: JSON.parse(
        JSON.stringify(mapStyle).replaceAll('customKey', TrimbleMaps.APIKey)
      ),
    });

    trimbelMap?.addControl(
      new TrimbleMaps.NavigationControl({ showCompass: false }),
      'top-right'
    );

    trimbelMap.on('load', handleOnMapLoaded);
    trimbelMap.on('click', handleOnMapClick);

    allUnclusteredMarkerlayers?.forEach((layerId: string) => {
      if (showRoute?.current) return;
      trimbelMap.on('click', layerId, function (evt) {
        const marker = JSON.parse(evt.features?.[0]?.properties?.marker);
        onClickMarkerHandler(marker);
        popupRef?.remove?.();
      });
      trimbelMap.on('mouseenter', layerId, function (evt) {
        trimbelMap.getCanvas().style.cursor = 'pointer';
        openMarkerTooltip(evt);
      });

      // Change cursor back
      trimbelMap.on('mouseleave', layerId, function (evt) {
        trimbelMap.getCanvas().style.cursor = '';
        if (isCursorOutsideElement(popupRef, evt)) {
          popupRef?.remove?.();
        }
      });
    });
    onMapInstanceCb?.(trimbelMap);
  }, []);

  useEffect(() => {
    if (!map) return;
    if (generateRoute) {
      initMarkerData();
      generateMapRoute({
        map: map,
        stopsList: generateMarkersData(markersList),
        theme: currentTheme,
      });
    }
    removeMapLayers(map);
    removeMapSources(map);
    if (isRouteViewOpen) {
      removeRoutes();
      removePrevMarkers();
      setIsRouteMode(false);
    }
    if (cluster && !generateRoute) {
      initMarkerCluster({
        trimbelMap: map,
        markersData: generateMarkersData(markersList),
        theme: currentTheme,
      });
    }
  }, [JSON.stringify(markersList), map]);

  const initMarkerData = () => {
    removePrevMarkers();
    const markers = generateMarkersData(markersList);
    setMarkersData(markers);
  };

  const openMarkerTooltip = (evt: any) => {
    popupRef?.remove?.();
    const popupLocation = evt.features[0].geometry.coordinates.slice();
    const marker = JSON.parse(evt.features?.[0]?.properties?.marker);
    const popupContent = (
      <MapToolTip
        {...marker}
        content={marker?.tooltip}
        onFixDriverHosLocation={onFixDriverHosLocation}
        onAction={onViewPanel}
      />
    );
    const el = document.createElement('div');
    render(popupContent, el);
    el.addEventListener('mouseleave', function (evnt) {
      popupRef?.remove?.();
    });
    popupRef = new TrimbleMaps.Popup({ closeOnMove: false })
      .setLngLat(popupLocation)
      .setDOMContent(el)
      .addTo(trimbelMap);
  };

  const generateMarkersData = (markers?: MarkersData[]) => {
    if (!markers) return;
    const markersInfo = markers.map((marker: MarkersData) => {
      return {
        ...marker,
        toolTip: {
          tooltipComponent: (
            <MapToolTip
              type={marker?.type}
              tooltip={marker?.tooltip}
              onFixDriverHosLocation={onFixDriverHosLocation}
            />
          ),
        },
      };
    });
    return markersInfo;
  };
  const handleOnMapClick = (event: MouseEvent) => onMapClick?.(event);

  const initRoutes = (markers = markersData) => {
    const routes = generateMapForRouteMode({
      stopsList: markers,
      trimbelMap: currentMapInstance?.current,
      theme: currentTheme,
    });
    currentMapInstance.current?.setZoom?.(currentZoom);
    if (routes?.length) setRoutesInstance((prev) => [...prev, ...routes]);
  };

  const handleOnMapLoaded = (map: any) => {
    currentMapInstance.current = map?.target;
    setMap(map?.target);
    map?.target?.resize?.();
    if (onMapLoaded) {
      onMapLoaded(map);
    }
  };

  const renderMarkerLoader = ({ lat, lng }: { lat: number; lng: number }) => {
    const element = document.createElement('div');
    render(<MarkerLoader />, element);
    currMarkerLoader = new TrimbleMaps.Marker({
      element,
    })
      .setLngLat([lng, lat])
      .addTo(currentMapInstance?.current);
  };

  const removeMarkerLoader = () => currMarkerLoader?.remove?.();

  const fetchDriverRouteMapData = async (marker: MarkersData) => {
    if (!marker?.driverGroupID) return;
    renderMarkerLoader({ lat: marker?.lat, lng: marker?.lng });
    const response = await getDispatchDetailsPanelMapData(
      marker?.driverGroupID
    );
    if (response?.markers) {
      const markers = generateMarkersData(
        response?.markers as unknown as MarkersData[]
      );
      resetMap();
      showRoute.current = true;
      setMarkersData(markers);
      setIsRouteMode(true);
      initRoutes(markers);
    }
    popupRef?.remove?.();
    removeMarkerLoader();
  };

  const removePrevMarkers = (clearInstances = true) => {
    markersInstance?.forEach?.((marker) => marker?.remove?.());
    if (clearInstances) setMarkersInstance([]);
  };

  const removeRoutes = () =>
    routesInstance?.forEach?.((route) => route?.remove?.());

  const resetMap = () => {
    removeMapLayers(currentMapInstance?.current);
    removeMapSources(currentMapInstance?.current);
  };

  const onClickMarkerHandler = (marker: MarkersData) => {
    if (showRoute?.current) return;
    if (!cluster || isRouteViewOpen || !marker?.driverGroupID) return;
    fetchDriverRouteMapData(marker);
  };
  const onClickBackToDriversHandler = () => {
    removeRoutes();
    removePrevMarkers();
    initMarkerCluster({
      trimbelMap: map,
      markersData: generateMarkersData(markersList),
      theme: currentTheme,
    });
    setRoutesInstance([]);
    setIsRouteMode(false);
    showRoute.current = false;
  };

  return (
    <div id="mapContainer" style={mapContainer} ref={mapRef}>
      {isRouteViewOpen &&
        markersData?.map?.((marker) => {
          return (
            <Markers
              key={`${callIndex}${JSON.stringify(marker)}`}
              map={currentMapInstance.current}
              lat={marker.lat}
              lng={marker.lng}
              type={marker.type}
              status={marker.status}
              issues={marker.issue}
              toolTip={marker.toolTip}
              markerInfo={marker}
              isUnclusteredPoint={true}
              onClick={() => {
                onClickMarkerHandler(marker);
              }}
              onMarkerInitialize={(marker) => {
                setMarkersInstance((prev) => [...prev, marker]);
              }}
            />
          );
        })}
      {addFilter && (
        <Box id="driverFilter" sx={UnlocatedDropdownContainer}>
          <SingleAutocomplete
            key={missingMarkers?.length}
            label={`${t('unlocatedDrivers')} (${missingMarkers.length})`}
            name="unlocatedDrivers"
            fieldName="groupName"
            variant="outlined"
            options={missingMarkers}
            size="small"
            onChangeCb={handleUnlocatedDrivers}
            customTextInputStyles={AutoCompleteStyles}
            customInputProps={{
              borderRadius: '10px',
            }}
            customInputLabelProps={{
              color: 'rgba(43, 100, 203, 1)',
            }}
          />
        </Box>
      )}
      {isRouteMode && (
        <Box sx={MapExternalComponentContainer}>
          <ButtonImproved
            variant="contained"
            startIcon={<ArrowBackOutlined />}
            label={'Back to Drivers'}
            sx={{
              color: 'primary.main',
              backgroundColor: 'common.white',
              '&:hover': {
                color: 'primary.main',
                backgroundColor: 'common.white',
              },
            }}
            onClick={onClickBackToDriversHandler}
          ></ButtonImproved>
        </Box>
      )}
      {isLoading && (
        <Box sx={LoaderContainer}>
          <CircularProgress size={18} />
          {markersData?.length
            ? 'Updating locations...'
            : 'Fetching locations...'}
        </Box>
      )}
    </div>
  );
};

export default observer(Maps);
