import { AxiosConfigType, http } from '../../../axios/axios.service';
import { BULK_OPTYMIZATION_ENDPOINTS } from '../config/apiEndpoints.config';

export const getOptmizedResult = async (payload?: Partial<AxiosConfigType>) => {
  return await http.put({
    apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.optimize,
    ...payload,
  });
};

export const rejectAssignmentRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return await http.put({
    apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.rejectAssignment,
    ...payload,
  });
};

export const lockAssignmentRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return (
    await http.put({
      apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.lockAssignment,
      ...payload,
    })
  )?.data;
};

export const saveOptimization = async (payload?: Partial<AxiosConfigType>) => {
  return (
    await http.put({
      apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.saveOptimization,
      ...payload,
    })
  )?.data;
};

export const validateOptimizationResult = async (
  payload?: Partial<AxiosConfigType>
) => {
  return (
    await http.put({
      apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.validateOptimizationResult,
      ...payload,
    })
  )?.data;
};

export const savePreferenceRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return (
    await http.put({
      apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.saveEnginePreference,
      ...payload,
    })
  )?.data;
};
export const getEnginePreferenceRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return (
    await http.get({
      apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.getEnginePreferences,
      ...payload,
    })
  )?.data;
};

export const restartOptimizationRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return (
    await http.put({
      apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.restartOptimization,
      ...payload,
    })
  )?.data;
};

export const cancelBulkOptimizationRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return (
    await http.put({
      apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.cancelOptimization,
      ...payload,
    })
  )?.data;
};

export const fetchSandboxWarningsRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return (
    await http.put({
      apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.sandboxWarnings,
      ...payload,
    })
  )?.data;
};

export const fetchPossibleDriverTripWarningsRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return (
    await http.put({
      apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.possibleDriverTripsWarnings,
      ...payload,
    })
  )?.data;
};
