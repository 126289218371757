import { Box } from '@mui/material';
import { useTerminalPermission } from '../../../../../common/hooks/useTerminalPermission';
import { TerminalContentDTO } from '../../../../../models';
import { ETerminalPanelTab } from '../../constants/types';
import { Overview } from '../Overview';

export interface TerminalDetailsFormProps {
  isCreatePanel?: boolean;
  selectedTab: ETerminalPanelTab;
  terminalDTO: TerminalContentDTO | null;
}
export default function TerminalDetailsForm({
  isCreatePanel = false,
  selectedTab,
  terminalDTO,
}: TerminalDetailsFormProps) {
  const { hasTerminalEditPermission } = useTerminalPermission();

  return (
    <Box sx={{ p: 2 }}>
      {isCreatePanel && <Overview canEdit={true} isCreatePanel={true} />}
      {!isCreatePanel &&
        selectedTab === ETerminalPanelTab.OVERVIEW &&
        terminalDTO && (
          <Overview
            canEdit={hasTerminalEditPermission}
            isCreatePanel={false}
            terminalDTO={terminalDTO}
          />
        )}
    </Box>
  );
}
