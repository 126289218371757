import { Popover } from '@mui/material';
import { ProfitCalculator } from '.';
import { IProfitCalculatorProps } from '../../loadTypes';

export interface ProfitCalculatorPopoverProps extends IProfitCalculatorProps {
  open: boolean;
  onClose: () => void;
  anchorEl: Element | null;
}
export const ProfitCalculatorPopover = ({
  open,
  onClose,
  anchorEl,
  selectedRowData,
  closeLoadAttributePopup,
  profitCalculatorUpdatedValue,
}: ProfitCalculatorPopoverProps) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{
        sx: {
          '&.MuiPaper-root': {
            backgroundColor: 'unset',
          },
        },
      }}
    >
      <ProfitCalculator
        selectedRowData={selectedRowData}
        closeLoadAttributePopup={closeLoadAttributePopup}
        profitCalculatorUpdatedValue={profitCalculatorUpdatedValue}
      />
    </Popover>
  );
};
