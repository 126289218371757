import omit from 'lodash/omit';
import { loadService, tripService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import {
  ExportTripFilterPayload,
  ExportTripFilterQueryParams,
  GetTripDetailsActionsRequest,
} from '../../../models/DTOs/Trip/Requests';
import { downloadFile } from '../../../utils/Doc';
import { tripFieldsConfig } from '../constants/constants';
import { ITripFilters } from '../constants/types';
import { filterToQuery } from './queryParams.utils';

export const exportToExcel = async ({
  filters,
  gridColumnMetadataList,
  terminalIds,
}: {
  filters: ITripFilters;
  gridColumnMetadataList: string[];
  terminalIds: string[];
}) => {
  const payload: ExportTripFilterPayload = new ExportTripFilterPayload({
    ...filterToQuery(omit(filters, [tripFieldsConfig.warning.fieldName])),
    terminalIds,
  });
  gridColumnMetadataList.push('onHoldState');
  const queryParams = new ExportTripFilterQueryParams({
    gridColumnMetadataList,
  });

  const response = await tripService.exportToExcel(queryParams, payload);
  return response instanceof ServiceError ? null : downloadFile(response);
};

export const getTripDetailsActions = async (
  tripId: string
): Promise<ELoadStatus[]> => {
  const response = await tripService.getTripDetailsActions(
    new GetTripDetailsActionsRequest({ tripId })
  );
  return response instanceof ServiceError ? null : response.data;
};

export const getCarrierNamesList = async (
  searchText: string,
  pageNumber: number
) => {
  const response = await loadService.getCarrierList(searchText, pageNumber);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};
