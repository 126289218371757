import React from 'react';
import { Controller } from 'react-hook-form';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const TextArea: React.FC<{
  control?: any;
  name: string;
  value?: string;
  placeholder?: string;
  customStyles?: { [key: string]: string | number };
}> = ({ control, name, placeholder, customStyles }) => {
  return (
    <Controller
      render={({ field }) => (
        <TextareaAutosize
          {...field}
          maxRows={4}
          minRows={3}
          aria-label="maximum height"
          placeholder={placeholder}
          style={{ width: '100%', maxWidth: '100%', ...customStyles }}
        />
      )}
      name={name}
      control={control}
      defaultValue=""
    />
  );
};

export default TextArea;
