import { CloseOutlined } from '@mui/icons-material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Popover, Stack, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import { t } from 'i18next';
import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import { getTableSettingsStyles } from '../styles/TableSettings.styles';
import { GridColDefSelf, IDataGridSettingsProps } from '../types';
import ExportToExcel from './ExportToExcel';
const TableSettings: ForwardRefRenderFunction<
  HTMLButtonElement,
  IDataGridSettingsProps
> = (
  {
    handleChangeForColumnModel,
    tableColumnsData,
    getExcel,
    excelDoc,
    resetSettings,
    downloadFile,
    reSetExcel,
    handleChangeColumns,
    tableSettingIconProps,
  },
  ref
) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);

  const styles = getTableSettingsStyles({ theme });
  React.useEffect(() => {
    if (!open && reSetExcel) {
      reSetExcel();
    }
  }, [open]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
    setAnchorEl(null);
    // backend request here
  };

  const handleToggle = (colItem: GridColDefSelf) => () => {
    if (colItem.permanent) return;
    const columnsData: { [key: string]: boolean } = {};
    tableColumnsData.forEach((column) => {
      if (column.field === colItem.field) {
        column.hide = !column.hide;
      }
      columnsData[column.field] = true;
      if (column.hide) {
        columnsData[column.field] = false;
      }
    });
    handleChangeForColumnModel(columnsData);
    handleChangeColumns && handleChangeColumns(columnsData);
  };

  const handleResetSettings = () => {
    resetSettings && resetSettings();
    // handleClose();
  };
  const TableSettingsBox = (
    <Box id="TableSettingsBox" sx={styles.TableSettingsBoxRoot}>
      <Box sx={styles.TableSettingsBoxContainer}>
        <Box className="headerContainerLeft" sx={styles.headerContainerLeft}>
          <Box sx={{ mr: 1, display: 'flex' }}>
            <SettingsOutlinedIcon sx={styles.SettingsOutlinedIcon} />
          </Box>
          <Typography component={'div'} variant="subtitle1" sx={styles.title}>
            {t('financeSummaryTableSettings')}
          </Typography>
        </Box>
        <Box
          className="headerContainerRight"
          sx={styles.headerContainerRight}
          onClick={handleClose}
        >
          <CloseOutlined sx={{ color: 'primary.main', cursor: 'pointer' }} />
        </Box>
      </Box>
      <Box id="TableSettingsContent" sx={styles.TableSettingsContent}>
        <Box id="paneHeader">
          <Typography
            variant="h6"
            sx={{
              color: 'text.primary',
              pl: 2,
            }}
          >
            {t('financeSummaryShowHideColumns')}
          </Typography>
        </Box>
        <Box id="paneContent" sx={{ height: '100%', overflow: 'auto' }}>
          <List
            className="List-root"
            sx={{
              [theme?.breakpoints?.down('tablet')]: {
                maxHeight: `calc(100vh - 200px)`,
                overflowY: 'auto',
              },
            }}
          >
            {tableColumnsData.map((item, index) => {
              if (item.headerName && !item.hidden) {
                return (
                  <ListItem
                    sx={{ pl: 1 }}
                    onClick={handleToggle(item)}
                    key={`switch-list-item${item.field}-index${index}`}
                  >
                    <Switch
                      id={`switch${item.field}-index${index}`}
                      edge="end"
                      disabled={item.permanent}
                      checked={!item.hide}
                      inputProps={{
                        'aria-labelledby': 'switch-list-label-wifi',
                      }}
                    />
                    <ListItemText
                      sx={styles.ListItemText}
                      id={`switch-list-label${item.field}-index${index}`}
                      primary={
                        <Typography variant="h7">{item.headerName}</Typography>
                      }
                    />
                  </ListItem>
                );
              }
            })}
          </List>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            margin={2}
          >
            {getExcel && (
              <ExportToExcel
                getExcel={getExcel}
                excelDoc={excelDoc}
                downloadFile={downloadFile}
              />
            )}

            <Button
              onClick={handleResetSettings}
              variant="outlined"
              fullWidth
              size="medium"
              sx={styles.resetButton}
            >
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  ...theme.typography?.buttonMedium,
                }}
              >
                {t('financeSummaryTableReset')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <IconButton
        ref={ref}
        className="TableSettings-root"
        onClick={handleClick}
        color="primary"
        sx={{ mt: 1, p: 0, ...tableSettingIconProps }}
      >
        <SettingsOutlinedIcon sx={{ p: 0 }} />
      </IconButton>
      {open && (
        <>
          <Popover
            anchorEl={anchorEl}
            open={open}
            title="Grid Settings"
            onClose={handleClose}
            sx={styles.TableSettingsBoxPopover}
          >
            {TableSettingsBox}
          </Popover>
        </>
      )}
    </>
  );
};

export default forwardRef(TableSettings);
