import { Box, SxProps } from '@mui/material';
import { helperLinkColor } from '../../../views/settings/import/constants';
import { MouseEventHandler } from 'react';

// @TODO: should be moved into UI-Kit
export default function HyperLink({
  onClick,
  value,
  sx = {},
  id = '',
}: {
  onClick?: MouseEventHandler<HTMLSpanElement>;
  value: string;
  sx?: SxProps;
  id?: string;
}) {
  return (
    <Box
      onClick={onClick}
      component="span"
      id={id}
      sx={{
        cursor: 'pointer',
        color: helperLinkColor,
        textDecoration: 'underline',
        ...sx,
      }}
    >
      {value}
    </Box>
  );
}
