import Box from '@mui/material/Box';
import { AuthReJoinIcon } from '../../ui-kit/components/Assets';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { letter15 } from '../../components/Subscription/constant';
import AuthContext from '../../globalContexts/AuthContext';
import { currencyDisplay } from '../../utils/grid.utils';
import { headerCss } from './constant';
import RegistrationForm from './RegistrationForm';
import { StyledAuthFromWrapper, SxPropAuthButton } from './styles';
import StorageManager from '../../StorageManager/StorageManager';
import { useNavigate } from 'react-router-dom';

const ReJoin = () => {
  const { themeLogin } = useThemeResponsive();
  const { logoutUser } = useContext(AuthContext);
  const user = StorageManager.getUser() || {};
  const outstandingAmount = user?.balanceAmount || 0;

  const [showPaymentScreen, setShowPaymentScreen] = useState<boolean>(false);
  const navigate = useNavigate();
  return showPaymentScreen ? (
    <RegistrationForm
      onClickBack={() => setShowPaymentScreen(false)}
      rejoin={true}
    />
  ) : (
    <StyledAuthFromWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AuthReJoinIcon />
        <Box sx={headerCss}>Welcome Back!</Box>
        <Box sx={headerCss}>{user?.organizationName}</Box>
        <Box
          sx={(theme) => ({
            ...theme.typography.body1,
            ...letter15,
            fontSize: '20px',
            textAlign: 'center',
            pt: 4,
            pl: 3,
            pr: 3,
          })}
        >
          {`Your account was terminated on ${moment(
            user.effectiveTerminationDate || undefined
          ).format('ll')} ${outstandingAmount > 0 ? `with an ` : ''}`}
          {outstandingAmount > 0 && (
            <font style={{ color: '#F76E64', fontWeight: 500 }}>
              outstanding balance of {currencyDisplay(outstandingAmount / 100)}
            </font>
          )}
          <br></br>
          {outstandingAmount > 0 &&
            `You will charged with ${currencyDisplay(
              outstandingAmount / 100
            )} `}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <ButtonImproved
          onClick={logoutUser}
          label="Logout"
          size="large"
          styleProps={{
            ...SxPropAuthButton,
            ...themeLogin.typography.h6,
            color: 'primary.main',
          }}
          variant="outlined"
        />
        <ButtonImproved
          onClick={() => {
            setShowPaymentScreen(true);
            navigate('/rejoin-registration');
          }}
          label={outstandingAmount > 0 ? 'Pay and Rejoin' : 'Rejoin'}
          size="large"
          styleProps={{
            ...SxPropAuthButton,
            ...themeLogin.typography.h6,
          }}
          variant="contained"
        />
      </Box>
    </StyledAuthFromWrapper>
  );
};

export default ReJoin;
