import { Box } from '@mui/material';
import { CSSProperties, FC, PropsWithChildren } from 'react';
import { CLASS_NAMES } from './components/ManualDriverSelection/styles';

interface StyledCommonDataGridProps extends PropsWithChildren<{}> {
  footerWidth?: CSSProperties['width'];
}

export const StyledCommonDataGrid: FC<
  PropsWithChildren<StyledCommonDataGridProps>
> = ({ footerWidth, ...props }) => {
  return (
    <Box
      display="flex"
      height="100%"
      sx={{
        '> .MuiPaper-root': {
          ml: 0,
          width: '100%',
        },
        '#CommonDataGridContainer': {
          // height: 'unset!important',
          width: '100%!important',
        },
        '& .MuiDataGrid-main + div > div': {
          width: footerWidth,
        },
        [`& .${CLASS_NAMES.driverSelectionCellClassName}.MuiDataGrid-cell:focus-within`]:
          {
            outline: 'none !important',
          },
      }}
      {...props}
    />
  );
};
