import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import Tab, { TabProps } from '@mui/material/Tab';
import { ICON_SIZE, MIN_TAB_WIDTH, TAB_HEIGHT } from './constants';

const StyledTab: ForwardRefRenderFunction<
  HTMLDivElement,
  TabProps & {
    isDarkMode?: boolean;
  }
> = ({ sx = {}, isDarkMode, ...props }, ref) => {
  const light = !isDarkMode;
  const defaultColor = light ? '#2352A6' : '#fff';
  const selectedColor = light ? '#f7f7f7' : '#35446B';
  const hoveredColor = '#fff';
  return (
    <Tab
      ref={ref}
      sx={{
        justifyContent: 'flex-start',
        opacity: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: light ? '1px solid rgba(132, 160, 210, 0.17)' : undefined,
        borderRadius: '4px 4px 0px 0px',
        backgroundColor: light ? '#E5EAF3' : '#425B91',
        p: '6px 5px 6px 6px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: defaultColor,
        textTransform: 'none',
        fontWeight: 600,
        minWidth: MIN_TAB_WIDTH,
        minHeight: TAB_HEIGHT,
        transition: '200ms',
        '& .MuiTab-root': {
          minHeight: TAB_HEIGHT,
          minWidth: MIN_TAB_WIDTH,
          textAlign: 'left',
        },
        '.tab-three-dots-icon': { color: defaultColor },
        '.tab-icon': {
          svg: {
            width: ICON_SIZE,
            height: ICON_SIZE,
            path: {
              fill: defaultColor,
              fillOpacity: 1,
            },
          },
        },
        '&:hover': {
          backgroundColor: '#5C84CB',
          color: hoveredColor,
          '.tab-three-dots-icon': { color: hoveredColor },
          '.tab-icon': {
            svg: {
              path: {
                fill: hoveredColor,
                fillOpacity: 1,
              },
            },
          },
        },
        '&.Mui-selected, &.selected': {
          backgroundColor: light ? '#35446B' : '#fff',
          color: selectedColor,
          '.tab-three-dots-icon': { color: selectedColor },
          '.tab-icon': {
            svg: {
              path: {
                fill: selectedColor,
                fillOpacity: 1,
              },
            },
          },
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export default forwardRef(StyledTab);
