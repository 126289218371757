import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomersList from '../../../../ui-kit/components/CustomersList';

import { accountingService, financeIntegrationService } from '../../../../api';
import SelectedCustomerAccounting from './SelectedCustomerAccounting/SelectecCustomerAccounting';
import { providersMap } from './constants';
import TerminalController from '../../terminals/TerminalController';
import {
  AccountingDTO,
  ActivateProviderDTO,
  FactoringListRequest,
  providerListDTO,
  ProvidersStatusMapDTO,
  UpdateQBAuthDTO,
} from '../../../../models';

import SelectedCustomerFactoring from './FactoreSelectedCustomer';
import { IcustomerListWithLogo } from '../../../../types/ELDTypes';
import { factorLeftPanelList } from '../../../../utils/factoringViewData';
import { IeldProviderData } from './FactoreSelectedCustomer/Refresh';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { observer } from 'mobx-react';
import StorageManager from '../../../../StorageManager/StorageManager';

const Finance = () => {
  const [providersList, setProvidersList] = useState<providerListDTO[]>([]);
  const [accountsApiRes, setAccountsApiRes] = useState<AccountingDTO[]>([]);
  const [isFactoringView, setFactoringView] = useState<boolean>(false);
  const [selectedProviderDetails, setSelectedProviderDetails] = useState<
    providerListDTO[]
  >([]);
  const [allTerminals, setAllTerminals] = useState<TerminalController[]>([]);
  const [terminalIdsList, setTerminalIdsList] = useState<string[]>([]);
  const [factoringprovidersList, setFactoringProvidersList] = useState<
    IeldProviderData[]
  >([]);
  const [factoreSelectedProviderDetails, setFactoreSelectedProviderDetails] =
    useState<providerListDTO>();
  const [factoreCustomerListWithLogo, setFactoreCustomerListWithLogo] =
    useState<IcustomerListWithLogo[]>([]);

  useEffect(() => {
    initialSelectedProvider(providersList);
    getSearchParams();
  }, [providersList]);

  const { terminals } = TerminalController.instance();
  const { getGlobalTerminalsIds } = useRootStore();

  useEffect(() => {
    getTerminals(terminals);
  }, [terminals, getGlobalTerminalsIds]);

  useEffect(() => {
    sessionStorage.setItem(
      'SelectedAccountingProvider',
      JSON.stringify(selectedProviderDetails)
    );
  }, [selectedProviderDetails]);

  useEffect(() => {
    // getTerminals();
    setFactoringView(getSelectedProviderView());
    getFactoringData();
  }, []);

  const getSelectedProviderView = () => {
    const isFactoreViewSelect = getItemSessionStore('FactoringViewSelected');
    if (isFactoreViewSelect === 'true') {
      setFactoringView(true);
      return true;
    }
    setFactoringView(false);
    return false;
  };

  const getProvidersFullList = (accountsResponse: AccountingDTO): void => {
    const allCustomers: providerListDTO[] = [];
    allCustomers.push({ headerName: 'Accounting', providerId: 1000 });
    accountsResponse?.providersStatusMap?.forEach(
      (data: ProvidersStatusMapDTO) => {
        const { providerId, status } = data;
        if (providerId && providerId > 0) {
          providersMap?.forEach((item) => {
            if (data.providerId === item.providerId) {
              const customer: providerListDTO = {
                providerId: item.providerId,
                providerName: item.providerName,
                logo: item.logo,
                logoWhite: item.logoWhite,
                status: status,
                description: item.description,
                gradient: item.gradient,
              };
              allCustomers.push(customer);
            }
          });
        }
      }
    );
    setProvidersList(allCustomers);
  };
  const getItemSessionStore = (name: string) => {
    if (name === 'SelectedAccountingProvider') return null;
    else return sessionStorage.getItem(name);
  };

  const redirectToExistingProvider = (): void => {
    const val = JSON.parse(
      getItemSessionStore(
        isFactoringView
          ? 'SelectedFactoreProvider'
          : 'SelectedAccountingProvider'
      ) || '{}'
    );
    providersMap?.forEach((item) => {
      if (val.providerId === item.providerId) {
        val.logo = item.logo;
      }
    });
    if (getSelectedProviderView()) {
      setFactoreSelectedProviderDetails(val);
      setSelectedProviderDetails([]);
    } else {
      setSelectedProviderDetails(val);
      setFactoreSelectedProviderDetails({});
    }
  };

  const initialSelectedProvider = (allCustomers: any) => {
    if (
      getItemSessionStore(
        isFactoringView
          ? 'SelectedFactoreProvider'
          : 'SelectedAccountingProvider'
      )
    ) {
      redirectToExistingProvider();
    }

    if (getSelectedProviderView()) {
      setFactoreSelectedProviderDetails(
        factoreCustomerListWithLogo?.length > 0 &&
          factoreCustomerListWithLogo[1]
      );
    } else {
      setSelectedProviderDetails(
        allCustomers && allCustomers.length > 0 && allCustomers[1]
      );
    }
  };

  const selectProvider = (data: providerListDTO[]): void => {
    setFactoringView(false);
    setSelectedProviderDetails(data);
    setFactoreSelectedProviderDetails({});
    sessionStorage.setItem('FactoringViewSelected', 'false');
    sessionStorage.setItem(
      'SelectedAccountingProvider',
      JSON.stringify(selectedProviderDetails)
    );
  };

  const getProvidersList = async (terminalIds: string[]) => {
    const userData = StorageManager.getUser() || {};
    const organizationId = userData?.organizationId;
    const accountsResponse = await accountingService.getAccountingData(
      terminalIds,
      organizationId
    );
    getProvidersFullList(accountsResponse as AccountingDTO);
    setAccountsApiRes(accountsResponse as AccountingDTO[]);
  };

  const getTerminals = (terminals: any): void => {
    setAllTerminals(terminals);
    if (getGlobalTerminalsIds && getGlobalTerminalsIds.length <= 0) {
      const terminalIds =
        terminals && terminals.length > 0 && terminals.map((el: any) => el.id);
      setTerminalIdsList(terminalIds);
      getProvidersList(terminalIds);
    } else if (
      terminalIdsList &&
      terminalIdsList?.length == getGlobalTerminalsIds?.length
    ) {
      const idDifference = terminalIdsList?.filter(
        (terminalId) => !getGlobalTerminalsIds.includes(terminalId)
      );
      if (!idDifference) {
        setTerminalIdsList(getGlobalTerminalsIds);
        getProvidersList(getGlobalTerminalsIds);
      }
    } else {
      setTerminalIdsList(getGlobalTerminalsIds);
      getProvidersList(getGlobalTerminalsIds);
    }
  };

  const activateProvider = async (
    payload: ActivateProviderDTO
  ): Promise<void> => {
    sessionStorage.removeItem('QBOnline');
    const activateResponse = await accountingService.postIntegrationData(
      payload
    );
    if (activateResponse) {
      window.location.href = '/settings/integrations';
    }
  };

  const udateQBAuth = async (payload: UpdateQBAuthDTO): Promise<void> => {
    sessionStorage.removeItem('QBOnline');
    const updateResponse = await accountingService.updateQBAuth(payload);
    if (updateResponse) {
      window.location.href = '/settings/integrations';
    }
  };

  const getSearchParams = async (): Promise<void> => {
    const url = new URL(window.location.href);
    if (url.search) {
      const params = new URLSearchParams(url.search);
      const QBOnline = getItemSessionStore(
        isFactoringView ? 'ApexAuthCreds' : 'QBOnline'
      );
      if (QBOnline) {
        const creds = JSON.parse(QBOnline || '');
        if (creds !== '') {
          if (isFactoringView) {
            const codeValue = {
              name: 'code',
              value: params.get('code'),
            };
            creds.data.push(codeValue);
            await activateFactoreProvider(creds);
          } else {
            creds.code = params.get('code');
            creds.realmId = params.get('realmId');
            if (creds.operation && creds.operation === 'updateQBAuth') {
              await udateQBAuth(creds);
            } else {
              await activateProvider(creds);
            }
          }
          params.delete('code');
        }
      }
    }
  };

  const getFactoringCustomerList = (providerData: AccountingDTO): void => {
    const allCustomers: IcustomerListWithLogo[] = [];
    const val = JSON.parse(getItemSessionStore('SelectedFactoreProvider'));
    let selectValue = {};
    allCustomers.push({ headerName: 'Factoring', providerId: 1001 });
    providerData?.providerStatusMap?.forEach(
      (data: { providerId: number; status: string }) => {
        if (data.providerId > 0) {
          factorLeftPanelList?.forEach((item) => {
            if (data.providerId === item.providerId) {
              const customer: any = {
                providerId: item.providerId,
                providerName: item.providerName,
                logo: item.logo,
                logoWhite: item.logoWhite,
                gradient: item.gradient,
                status: data.status,
                description: item.description,
              };
              if (val?.providerId === data.providerId) {
                selectValue = customer;
              }
              allCustomers.push(customer);
            }
          });
        }
      }
    );
    setFactoreCustomerListWithLogo(allCustomers);

    if (getSelectedProviderView()) {
      setFactoreSelectedProviderDetails(
        selectValue ? selectValue : allCustomers[1]
      );
    } else {
      setFactoreSelectedProviderDetails({});
    }
  };
  const activateFactoreProvider = async (
    payload: ActivateProviderDTO
  ): Promise<void> => {
    await financeIntegrationService.activateProvider(payload).then(() => {
      getFactoringData();
      // window.location.href = '/settings/integrations';
    });
  };
  const selectFactoringProvider = (data: providerListDTO[]): void => {
    setFactoringView(true);
    setFactoreSelectedProviderDetails(data);
    setSelectedProviderDetails({});
    sessionStorage.setItem('FactoringViewSelected', 'true');
    sessionStorage.setItem('SelectedFactoreProvider', JSON.stringify(data));
  };

  const getRefreshData = (): void => {
    getFactoringData();
  };
  const getFactoringData = async (): Promise<void> => {
    const request = new FactoringListRequest();
    await financeIntegrationService.getFactoringList(request).then((res) => {
      setFactoringProvidersList(res);
      getFactoringCustomerList(res);
    });
  };
  return (
    <Grid
      id="finance"
      container
      sx={{
        backgroundColor: 'white',
        borderRadius: '16px 0px 0px 16px',
      }}
    >
      <Grid item xs={2} px={2} sx={{ height: '76vh', overflow: 'auto' }}>
        <CustomersList
          allCustomers={providersList}
          selectedProviderDetails={selectedProviderDetails}
          selectProvider={selectProvider}
        />

        {factoreCustomerListWithLogo?.length > 0 && (
          <CustomersList
            allCustomers={factoreCustomerListWithLogo}
            selectedProviderDetails={factoreSelectedProviderDetails}
            selectProvider={selectFactoringProvider}
          />
        )}
      </Grid>
      <Grid item xs={10} sx={{ height: '76vh', overflow: 'auto' }}>
        {!isFactoringView &&
          selectedProviderDetails?.providerId &&
          accountsApiRes && (
            <SelectedCustomerAccounting
              currentCustomerData={selectedProviderDetails}
              providerData={accountsApiRes}
              getProviderDetails={getProvidersList}
              allTerminals={allTerminals}
              terminalIdsList={terminalIdsList}
            />
          )}
        {isFactoringView &&
          factoringprovidersList &&
          factoreCustomerListWithLogo?.length > 0 &&
          factoreSelectedProviderDetails?.providerId && (
            <SelectedCustomerFactoring
              currentCustomerData={factoreSelectedProviderDetails}
              eldProviderData={factoringprovidersList}
              refreshFactoringData={getRefreshData}
            />
          )}
      </Grid>
      <Grid item xs={10}></Grid>
    </Grid>
  );
};

export default observer(Finance);
