import { findLastIndex } from 'lodash';
import { TripV3DataProps } from '../TripPlanV3';
import { StopSolutionV3Prop } from '../TripPlanV3/types';
import { TripStopStatusEnum } from '../types';

export const restrictedStopStatus: TripStopStatusEnum[] = [
  TripStopStatusEnum.COMPLETED,
  TripStopStatusEnum.CANCELLED,
];
export const restrictedStopStatus2: TripStopStatusEnum[] = [
  TripStopStatusEnum.ARRIVED,
  TripStopStatusEnum.COMPLETED,
  TripStopStatusEnum.CANCELLED,
  TripStopStatusEnum.ACTIVITY_STARTED,
  TripStopStatusEnum.ACTIVITY_ENDED,
];

export const generateStopSolution = (
  stopList: StopSolutionV3Prop[],
  stopSolution: StopSolutionV3Prop,
  idx: number
): StopSolutionV3Prop => {
  if (idx === 0) {
    return stopSolution;
  }
  const nextStopSolution: StopSolutionV3Prop | undefined = stopList[idx + 1];
  if (nextStopSolution) {
    return {
      ...stopSolution,
      HOSList: nextStopSolution?.HOSList,
    };
  } else {
    return {
      ...stopSolution,
      HOSList: [],
    };
  }
};

export const canShowFloatingActionButtonCase1 = ({
  preStopSolution = undefined,
  nextStopSolution = undefined,
}: {
  preStopSolution: StopSolutionV3Prop | undefined;
  nextStopSolution: StopSolutionV3Prop | undefined;
}): boolean => {
  //Case 1: User cannot add a stop between two completed and cancelled stop
  if (!preStopSolution || !nextStopSolution) {
    return true;
  }
  if (
    preStopSolution?.status &&
    restrictedStopStatus.indexOf(preStopSolution?.status) >= 0 &&
    nextStopSolution?.status &&
    restrictedStopStatus.indexOf(nextStopSolution?.status) >= 0
  ) {
    return false;
  }
  return true;
};

export const canShowFloatingActionButtonCase2 = ({
  nextStopSolution = undefined,
}: {
  nextStopSolution: StopSolutionV3Prop | undefined;
}): boolean => {
  //Case 2: Do not show Add Stop and Add Relay option when the next stop status = Arrived/Completed/Cancelled/ACTIVITY_STARTED/ACTIVITY_ENDED
  if (!nextStopSolution) {
    return true;
  }
  if (
    nextStopSolution?.status &&
    restrictedStopStatus2.indexOf(nextStopSolution?.status) >= 0
  ) {
    return false;
  }
  return true;
};

export const canShowFloatingActionButton = ({
  preStopSolution = undefined,
  nextStopSolution = undefined,
}: {
  preStopSolution: StopSolutionV3Prop | undefined;
  nextStopSolution: StopSolutionV3Prop | undefined;
}): boolean => {
  const isValidCase1 = canShowFloatingActionButtonCase1({
    preStopSolution,
    nextStopSolution,
  });
  const isValidCase2 = canShowFloatingActionButtonCase2({ nextStopSolution });
  return isValidCase1 && isValidCase2;
};

export const getLastIdxNotCompletedBeforeEnRoute = (
  stopList: StopSolutionV3Prop[],
  isCompleted: boolean
): number => {
  if (isCompleted) {
    return -1;
  }
  return findLastIndex(
    stopList,
    (stopSolution: StopSolutionV3Prop, idx: number) => {
      return (
        canShowFloatingActionButton({
          preStopSolution: stopList[idx - 1],
          nextStopSolution: stopList[idx + 1],
        }) && stopSolution.status === TripStopStatusEnum.EN_ROUTE
      );
    }
  );
};

const canEnablePreAndNext = (newValue: any): boolean => {
  return newValue !== null && newValue !== undefined && newValue !== '';
};

export const shouldDisableActionOnPreAndNext = (
  enablePreAndNext: boolean,
  idx: number,
  data: TripV3DataProps
): boolean => {
  if (enablePreAndNext) {
    if (canEnablePreAndNext(data?.prevLoadId) && idx === 0) {
      return true;
    }
    if (
      canEnablePreAndNext(data?.nextLoadId) &&
      idx === data.stopList.length - 1
    ) {
      return true;
    }
  }
  return false;
};
