import { Box, Stack, Typography } from '@mui/material';
import SubscriptionCard from './subscriptionCard';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import ForwardIcon from '@mui/icons-material/Forward';
import { styles } from './styles';
import StorageManager from '../../StorageManager/StorageManager';
import moment from 'moment/moment';

const TrailCard = ({ trialDays }: any) => {
  const cardItems = (label: string, data: string | ReactJSXElement) => (
    <Box display="flex" justifyContent={'space-between'}>
      <Typography>{label}</Typography>
      {data}
    </Box>
  );
  const getDate = (date: string) => {
    return date ? moment(date).format('MM/DD/YY') : '-';
  };
  const userStorage = StorageManager.getUser();
  return (
    <SubscriptionCard
      containerStyle={{ border: 'none', width: '100%' }}
      title={'Your Trial'}
      content={
        <Stack spacing={1}>
          {cardItems(
            'Account status',
            <Typography sx={{ color: 'warning.main' }}>
              Trial In Progress
            </Typography>
          )}
          {cardItems(
            'Trial duration',
            `${getDate(userStorage.effectiveStartDate)} - ${getDate(
              userStorage.effectiveTerminationDate
            )}`
          )}
          {cardItems(
            'Days remaining',
            <Typography fontWeight={500}>{trialDays} days</Typography>
          )}
          {cardItems(
            '',
            <Box fontWeight={500}>
              Activate Now! <ForwardIcon sx={{ ...styles.activeArrowStyle }} />
            </Box>
          )}
        </Stack>
      }
    />
  );
};
export default TrailCard;
