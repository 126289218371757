import { ThemeOptions } from '@mui/material';
import { CancelButtonColorSxProps } from '../../components/ButtonImproved/ButtonImproved.styles';
export const MuiButtonSizeSxProps = {
  large: {
    padding: '8px 22px',
    borderRadius: '14px',
    fontSize: '15px',
    height: '42px',
  },
  medium: {
    padding: '6px 16px',
    borderRadius: '10px',
    fontSize: '14px',
    height: '36px',
  },
  small: {
    padding: '4px 10px',
    borderRadius: '6px',
    fontSize: '13px',
    height: '30px',
    lineHeight: 'unset',
  },
};
export const MuiButton: ThemeOptions['components'] = {
  MuiButton: {
    styleOverrides: {
      root: ({ theme, ownerState }) =>
        theme.unstable_sx({
          textTransform: 'none',
          fontWeight: 500,
          '&.Mui-disabled': {
            'svg > path': {
              color: ownerState?.bulkaction
                ? 'other.gridBulkActionDisabled'
                : 'action.disabled',
              fill: 'currentcolor',
            },
          },
          ...(ownerState?.variant === 'outlined' && {
            ...CancelButtonColorSxProps,
          }),
        }),
      sizeLarge: ({ theme }) => theme.unstable_sx(MuiButtonSizeSxProps.large),
      sizeMedium: ({ theme }) => theme.unstable_sx(MuiButtonSizeSxProps.medium),
      sizeSmall: ({ theme }) => theme.unstable_sx(MuiButtonSizeSxProps.small),
      containedPrimary: ({ theme }) =>
        theme.unstable_sx({
          color: 'primary.contrast',
          backgroundColor: 'primary.main',
        }),
    },
  },
};
