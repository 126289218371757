import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector'; // This is for testing
import { EnTranslations } from './locales/en';
import { HiTranslations } from './locales/hi';

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: EnTranslations },
      hi: { translation: HiTranslations },
    },
    lng: 'en',
    fallbackLng: 'en',
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
    // detection: {
    //   order: ['cookie', 'htmlTag', 'localStorage', 'sessionStorage', 'path', 'subdomain'],
    //   caches: ['cookie']
    // },
    react: { useSuspense: false },
  });

export default i18n;
