import moment from 'moment';
import { InvoiceModel } from 'subPages/invoices/models/InvoiceModel';
import { paymentManagementService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { getOrganizationId } from '../../../../common/TimeoffDialog/utils';
import {
  PaginatedPaymentInvoiceListParams,
  PaginatedPaymentReferenceListParams,
  PaginatedPaymentSeqNumberListParams,
} from '../../../../models/DTOs/Payment/DTOs';
import { gridPageSize } from '../../../../utils';
import { getCustomerList } from '../../../settings/import/components/ExtraStep/constants';

export const getPaymentFilterSeqNumbers = async (
  searchString: string,
  pageNumber: number
) => {
  const response =
    await paymentManagementService.getPaginatedPaymentSeqNumberList(
      new PaginatedPaymentSeqNumberListParams({
        seqNumber: searchString,
        pageNumber,
        pageSize: gridPageSize,
      })
    );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: response?.content.map(({ id, seqNumber }) => ({
      label: seqNumber,
      value: id,
    })),
  };
};

export const getPaymentFilterCustomers = async (
  searchString: string,
  pageNumber: number
) => {
  const response = await getCustomerList(searchString, pageNumber);
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: response?.content.map(({ id, name }) => ({
      label: name,
      value: id,
    })),
  };
};

export const getPaymentFilterInvoices = async (
  searchString: string,
  pageNumber: number
) => {
  const response =
    await paymentManagementService.getPaginatedPaymentInvoiceList(
      new PaginatedPaymentInvoiceListParams({
        invoiceId: searchString,
        pageNumber,
        pageSize: 20,
      })
    );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: response?.content.map(({ id, invoiceId }) => ({
      label: invoiceId,
      value: id,
    })),
  };
};

export const getPaymentFilterReferenceIds = async (
  searchString: string,
  pageNumber: number
) => {
  const response =
    await paymentManagementService.getPaginatedPaymentReferenceList(
      new PaginatedPaymentReferenceListParams({
        referenceId: searchString,
        pageNumber,
        pageSize: 20,
      })
    );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: response?.content.map(({ id, referenceId }) => ({
      label: referenceId || id,
      value: id,
    })),
  };
};

export const getPaymentFilterDTO = (data: InvoiceModel) => {
  return {
    generalDetail: {
      organizationId: getOrganizationId(),
      amountReceived: data.amountDue,
      customer: data.customer,
      paymentDate: moment(),
    },
    tableData: {
      invoiceId: data.invoiceId,
      invoiceSeqNumber: data.seqNumber,
      invoiceReferenceId: data.invoiceReferenceId,
      total: data.total,
      amountDue: data.amountDue,
      amount: data.amountDue,
      invoiceDueDate: data.invoicedOn,
    },
  };
};
