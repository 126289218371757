import React from 'react';
import {
  StopSolutionV3Prop,
  StopSolutionV3StopType,
} from '../../TripPlanV3/types';
import { TripStopStatusEnum } from '../../types';
import { TimeLineIconMapping } from '../timelineIconsMapping';
import { TruckIconSection } from './TruckIconSection';

export const TimelineSeparatorIconSection = ({
  isCompleted,
  isCancelled,
  isActive,
  stopSolution,
}: {
  isCompleted?: boolean;
  isCancelled?: boolean;
  isActive: boolean;
  stopSolution: StopSolutionV3Prop;
}): JSX.Element => {
  if (isCompleted) {
    return TimeLineIconMapping.PickupOrDropOff({ isActive: true });
  }
  if (isCancelled) {
    return TimeLineIconMapping.HighlightOffOutlinedIcon({
      isActive,
      sx: {
        color: 'error.main',
      },
    });
  }

  if (stopSolution?.type === StopSolutionV3StopType.RELAY) {
    return TimeLineIconMapping.Relay({ isActive });
  }

  switch (stopSolution?.status) {
    case TripStopStatusEnum.NONE:
      return TimeLineIconMapping.PickupOrDropOff({ isActive });

    case TripStopStatusEnum.ACTIVITY_STARTED:
    case TripStopStatusEnum.ACTIVITY_ENDED:
    case TripStopStatusEnum.ARRIVED:
      return <TruckIconSection stopSolution={stopSolution} />;

    case TripStopStatusEnum.CANCELLED:
      return TimeLineIconMapping.HighlightOffOutlinedIcon({ isActive });

    case TripStopStatusEnum.COMPLETED:
      return TimeLineIconMapping.PickupOrDropOff({ isActive });

    case TripStopStatusEnum.EN_ROUTE:
      return TimeLineIconMapping.PickupOrDropOff({ isActive });

    default:
      return TimeLineIconMapping.PickupOrDropOff({ isActive });
  }
};
