import { Box, SxProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import {
  MapMarkerStyles,
  getMapMarkerStyles,
  markerCompletedColor,
} from './styles';
import HomeIcon from '@mui/icons-material/Home';
import NavigationIcon from '@mui/icons-material/Navigation';
export enum EMapMarkerType {
  Stop = 'Stop',
  Pickup = 'Pickup',
  Dropoff = 'Dropoff',
  PickupDropoff = 'Pickup & Dropoff',
  TripStop = 'Trip Stop',
  RelayStop = 'Relay Stop',
  HOSStop = 'HOS Stop',
  TimeOff = 'Time Off',
  StartTrip = 'Start of Trip',
  EndTrip = 'End of Trip',
}

export interface MapMarkerProps {
  variant: 'outlined' | 'filled';
  children?: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  completed?: boolean;
  delayed?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  SxProps?: Record<string, SxProps>;
}
export const MapMarker = ({
  variant,
  children,
  size,
  title,
  subTitle,
  completed,
  delayed,
  backgroundColor,
  borderColor,
  SxProps,
}: MapMarkerProps) => {
  const theme = useTheme();
  const styles = getMapMarkerStyles({
    theme,
    completed,
    delayed,
    variant,
    size,
    title,
    subTitle,
    backgroundColor,
    borderColor,
    SxProps,
  });
  return (
    <Box sx={(theme) => theme.unstable_sx(styles.MapMarkerRoot)}>
      <Box sx={(theme) => theme.unstable_sx(styles.MapMarkerMarker)}>
        {children}
      </Box>
      {title && (
        <Box sx={(theme) => theme.unstable_sx(styles.MapMarkerTooltipRoot)}>
          <Box sx={(theme) => theme.unstable_sx(styles.MapMarkerTooltip)}>
            <Typography
              sx={(theme) => theme.unstable_sx(styles.MapMarkerTooltipTitle)}
            >
              {title}
            </Typography>
            {subTitle && (
              <Typography
                sx={(theme) =>
                  theme.unstable_sx(styles.MapMarkerTooltipSubTitle)
                }
              >
                {subTitle}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const MapMarkerStop = ({
  subTitle,
  completed,
}: Pick<MapMarkerProps, 'subTitle' | 'completed'>) => {
  return (
    <MapMarker
      size="small"
      variant="outlined"
      title={EMapMarkerType.Stop}
      subTitle={subTitle}
      completed={completed}
    ></MapMarker>
  );
};

export const StartEndTripMarker = ({
  title,
  subTitle,
  completed,
  delayed,
}: Pick<MapMarkerProps, 'title' | 'subTitle' | 'completed' | 'delayed'>) => {
  return (
    <MapMarker
      size="small"
      variant="outlined"
      title={title ? title : ''}
      subTitle={subTitle}
      completed={completed}
      delayed={delayed}
    ></MapMarker>
  );
};
export const MapMarkerPickup = ({
  title,
  subTitle,
  completed,
  delayed,
}: Pick<MapMarkerProps, 'title' | 'subTitle' | 'completed' | 'delayed'>) => {
  return (
    <MapMarker
      size="small"
      variant="outlined"
      // title={title ? title : EMapMarkerType.Pickup}
      title={title}
      subTitle={subTitle}
      completed={completed}
      delayed={delayed}
    >
      <Typography sx={(theme) => theme.unstable_sx(MapMarkerStyles.content)}>
        P
      </Typography>
    </MapMarker>
  );
};
export const MapMarkerDropoff = ({
  title,
  subTitle,
  completed,
  delayed,
}: Pick<MapMarkerProps, 'title' | 'subTitle' | 'completed' | 'delayed'>) => {
  return (
    <MapMarker
      size="small"
      variant="outlined"
      title={title}
      subTitle={subTitle}
      completed={completed}
      delayed={delayed}
    >
      <Typography sx={(theme) => theme.unstable_sx(MapMarkerStyles.content)}>
        D
      </Typography>
    </MapMarker>
  );
};

export const MapMarkerTripStop = ({
  title,
  subTitle,
  completed,
  delayed,
}: Pick<MapMarkerProps, 'title' | 'subTitle' | 'completed' | 'delayed'>) => {
  return (
    <MapMarker
      size="small"
      variant="outlined"
      title={title}
      subTitle={subTitle}
      completed={completed}
      delayed={delayed}
    >
      <Typography sx={(theme) => theme.unstable_sx(MapMarkerStyles.content)}>
        T
      </Typography>
    </MapMarker>
  );
};

export const MapMarkerRelayStop = ({
  title,
  subTitle,
  completed,
  delayed,
}: Pick<MapMarkerProps, 'title' | 'subTitle' | 'completed' | 'delayed'>) => {
  return (
    <MapMarker
      size="small"
      variant="outlined"
      title={title}
      subTitle={subTitle}
      completed={completed}
      delayed={delayed}
    >
      <Typography sx={(theme) => theme.unstable_sx(MapMarkerStyles.content)}>
        R
      </Typography>
    </MapMarker>
  );
};

export const MapMarkerHOSStop = ({
  title,
  subTitle,
  completed,
  delayed,
}: Pick<MapMarkerProps, 'title' | 'subTitle' | 'completed' | 'delayed'>) => {
  return (
    <MapMarker
      size="small"
      variant="outlined"
      title={title}
      subTitle={subTitle}
      completed={completed}
      delayed={delayed}
    >
      <Typography
        sx={(theme) => theme.unstable_sx(MapMarkerStyles.smallContent)}
      >
        HoS
      </Typography>
    </MapMarker>
  );
};

export const MapMarkerPickupDropoff = ({
  title,
  subTitle,
  completed,
  delayed,
}: Pick<MapMarkerProps, 'title' | 'subTitle' | 'completed' | 'delayed'>) => {
  return (
    <MapMarker
      size="small"
      variant="outlined"
      title={title}
      subTitle={subTitle}
      completed={completed}
      delayed={delayed}
    >
      <Typography
        sx={(theme) => theme.unstable_sx(MapMarkerStyles.smallContent)}
      >
        P&D
      </Typography>
    </MapMarker>
  );
};

export const MapMarkerTimeOff = ({
  variant = 'outlined',
  title,
  subTitle,
  completed,
  delayed,
}: Partial<
  Pick<
    MapMarkerProps,
    'variant' | 'title' | 'subTitle' | 'completed' | 'delayed'
  >
>) => {
  return (
    <MapMarker
      size="small"
      variant={variant}
      title={title ? title : EMapMarkerType.TimeOff}
      subTitle={subTitle ? subTitle : EMapMarkerType.TimeOff}
      completed={completed}
      delayed={delayed}
    ></MapMarker>
  );
};

export const HomeLocationMarker = ({
  title,
  subTitle,
  completed,
  delayed,
}: Pick<MapMarkerProps, 'title' | 'subTitle' | 'completed' | 'delayed'>) => {
  return (
    <MapMarker size="medium" variant={'filled'} borderColor={'primary.main'}>
      <Typography
        sx={(theme) =>
          theme.unstable_sx({
            ...MapMarkerStyles.largeContent,
          })
        }
      >
        <HomeIcon fontSize="medium" sx={{ color: 'common.white' }} />
      </Typography>
    </MapMarker>
  );
};

export const EnrouteMarker = ({
  title,
  subTitle,
  completed,
  delayed,
}: Pick<MapMarkerProps, 'title' | 'subTitle' | 'completed' | 'delayed'>) => {
  return (
    <MapMarker
      size="small"
      variant={'filled'}
      title={title}
      subTitle={'Enroute'}
      backgroundColor={'common.white'}
    >
      <NavigationIcon color="primary" fontSize="small" />
    </MapMarker>
  );
};
