import StorageManager from '../../../../StorageManager/StorageManager';
import { rolesService } from '../../../../api';
import { GetRoles, AddUpdateRoleDTO } from '../../../../models//DTOs';
export const getRolesListData = async () => {
  const userStorage = StorageManager.getUser();
  const request = new GetRoles();
  request.organizationId = userStorage.organizationId;
  return await rolesService.getRolesListData(request);
};

export const getActiveRoles = async (custom?: boolean) => {
  const userStorage = StorageManager.getUser();
  const request = new GetRoles();
  request.organizationId = userStorage.organizationId;
  if (custom) {
    request.custom = custom;
  }
  return await rolesService.getActiveRoles(request);
};

export const getRoleDetailsData = async (
  isIncludePrimaryTerminal: boolean,
  terminalIds: string[]
) => {
  const userStorage = StorageManager.getUser();
  const request = new GetRoles();
  request.organizationId = userStorage.organizationId;
  return await rolesService.getRoleDetailsData(request);
};
export const addNewRole = async (payload: AddUpdateRoleDTO) => {
  const userStorage = StorageManager.getUser();
  payload.organizationId = userStorage.organizationId;
  return await rolesService.addNewRole(payload);
};
export const updateRole = async (payload: AddUpdateRoleDTO) => {
  const userStorage = StorageManager.getUser();
  payload.organizationId = userStorage.organizationId;
  return await rolesService.updateRole(payload);
};

export const updateRoleMeteData = async (payload: AddUpdateRoleDTO) => {
  const userStorage = StorageManager.getUser();
  payload.organizationId = userStorage.organizationId;
  return await rolesService.updateRoleMeteData(payload);
};
export const deleteRole = async (payload: {
  code: string;
  organizationId?: number;
}) => {
  const userStorage = StorageManager.getUser();
  payload.organizationId = userStorage.organizationId;
  return await rolesService.deleteRole(payload);
};
