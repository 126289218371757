import moment from 'moment';
import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import {
  ERelativeDateRangeKey,
  ERelativeDateRangeUnit,
} from '../../../ui-kit/components/RelativeDateRange';
import { getGridCompanyTimezoneSettings } from '../../../utils/Timezone.utils';
import { SCHEDULE_TYPE, getDateType } from '../basicReports/constant';
import {
  doSearchCustomersName,
  doSearchDriversName,
  doSearchTractorsName,
  doSearchTrailersName,
} from '../basicReports/utils';
import { DefaultLoadFilter, DefaultTrendType } from './constant';
import { prepareQuarterList } from './utils';

export const AdvancedReportList = {
  LOAD: { id: 'LOAD', label: 'Load Report' },
  LOAD_TREND: { id: 'LOAD_TREND', label: 'Load Trend Report' },
  DRIVER_TIMEOFF: { id: 'DRIVER_TIMEOFF', label: 'Driver Time Off Report' },
  // detentionReport: { id: 'detentionReport', label: 'Detention Report' },
  IFTA: { id: 'IFTA', label: 'IFTA Report' },
  LOAD_AND_INVOICE: { id: 'LOAD_AND_INVOICE', label: 'Load and Invoice' },
  TRIPS_AND_STOPS: { id: 'TRIPS_AND_STOPS', label: 'Trips and Stops' },
  DRIVER_TRIP_PAYMENTS: {
    id: 'DRIVER_TRIP_PAYMENTS',
    label: 'Driver Trip Payments',
  },
  TRACTOR_TRIP_PAYMENTS: {
    id: 'TRACTOR_TRIP_PAYMENTS',
    label: 'Tractor Trip Payments',
  },
};
export const DefaultAdvanceReportsConfig = [
  {
    id: AdvancedReportList.LOAD.id,
    title: AdvancedReportList.LOAD.label,
    description:
      'Comprehensive load report all loads, trips, driver and tractor settlements across multiple tabs',
    subCatagory: [
      {
        id: AdvancedReportList.LOAD_AND_INVOICE.id,
        title: AdvancedReportList.LOAD_AND_INVOICE.label,
      },
      {
        id: AdvancedReportList.TRIPS_AND_STOPS.id,
        title: AdvancedReportList.TRIPS_AND_STOPS.label,
      },
      {
        id: AdvancedReportList.DRIVER_TRIP_PAYMENTS.id,
        title: AdvancedReportList.DRIVER_TRIP_PAYMENTS.label,
      },
      {
        id: AdvancedReportList.TRACTOR_TRIP_PAYMENTS.id,
        title: AdvancedReportList.TRACTOR_TRIP_PAYMENTS.label,
      },
    ],
    // visibleFields: reportColumns[AdvancedReportList.LOAD.id].visibleFields,
    // hiddenFields: reportColumns[AdvancedReportList.LOAD.id].hiddenFields
  },
  {
    id: AdvancedReportList.LOAD_TREND.id,
    title: AdvancedReportList.LOAD_TREND.label,
    description:
      'Graphical load trend report, showcasing key revenue and KPI metrics',
  },
  {
    id: AdvancedReportList.IFTA.id,
    title: AdvancedReportList.IFTA.label,
    description:
      'IFTA (International Fuel Tax Agreement) Report where data related to fuel usage and mileage covered by commercial vehicles across different jurisdictions is compiled and calculated to comply with IFTA requirements.',
  },
  {
    id: AdvancedReportList.DRIVER_TIMEOFF.id,
    title: AdvancedReportList.DRIVER_TIMEOFF.label,
    description: 'Report of all scheduled and actual time off used by driver',
  },
  // {
  //   id: AdvancedReportList.detentionReport.id,
  //   title: AdvancedReportList.detentionReport.label,
  //   description: 'Report of detention time accumulated by load and location',
  // },
];
export const entity = [
  { label: 'Driver', value: 'DRIVER' },
  { label: 'Customer', value: 'CUSTOMER' },
  { label: 'Tractor', value: 'TRACTOR' },
  { label: 'Trailer', value: 'TRAILER' },
];

export const period = [
  { label: 'Last 1 Year', value: 'one' },
  { label: 'Last 2 Years', value: 'two' },
  { label: 'Whole Period', value: 'whole' },
];

const timeOff_statue = [
  { label: 'Not Started', value: 'OFFERED_TO_DRIVER' },
  { label: 'Active', value: 'DISPATCHED,IN_TRANSIT' },
  { label: 'Completed', value: 'LOAD_COMPLETED' },
];
const sheduleAndDateTypeFilters = [
  {
    key: 5,
    name: 'scheduleType',
    options: [...SCHEDULE_TYPE].map((s) => ({
      label: s.name,
      value: s.id,
    })),
    label: 'Schedule Type',
    type: 'singleSelect',
    style: {
      width: '100%',
    },
  },
  {
    key: 6,
    name: 'dateType',
    options: getDateType('driver').map((s) => ({
      label: s.name,
      value: s.id,
    })),
    label: 'Date Type',
    type: 'singleSelect',
    style: {
      width: '100%',
    },
  },
];
export const AdvancedReportFilterConfig = {
  [AdvancedReportList.LOAD.id]: {
    defaultAppliedFilters: [
      {
        name: 'pickupRange',
        label: 'Period *',
        style: {
          width: '100%',
        },
        isDefault: 'true',
        variant: 'outlined',
        require: true,
        ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
      },
    ],
    allFilters: [
      {
        name: 'statusList',
        label: 'Status',
        style: {
          width: '100%',
        },
        variant: 'outlined',
        fieldName: 'name',
        options: [
          {
            name: 'Available',
            value: 'AVAILABLE',
          },
          {
            name: 'Planned',
            value: 'ASSIGNMENT_PLANNED',
          },
          {
            name: 'Assigned',
            value: 'OFFERED_TO_DRIVER',
          },
          {
            name: 'Dispatched',
            value: 'DISPATCHED',
          },
          {
            name: 'In Transit',
            value: 'IN_TRANSIT',
          },
          {
            name: 'Completed',
            value: 'LOAD_COMPLETED',
          },
          {
            name: 'Canceled',
            value: 'CANCELLED',
          },
          {
            name: 'Ready to Invoice',
            value: 'READY_TO_INVOICE',
          },
          {
            name: 'Invoiced',
            value: 'INVOICED',
          },
          {
            name: 'Paid',
            value: 'PAID',
          },
        ],
        type: 'multipleAutoComplete',
      },
      {
        name: 'driverIds',
        fieldName: 'driverName',
        getData: doSearchDriversName,
        label: 'Driver',
        type: 'multipleAutoComplete',
      },
      {
        name: 'customerIds',
        fieldName: 'name',
        getData: doSearchCustomersName,
        label: 'Customer',
        type: 'multipleAutoComplete',
      },
      {
        name: 'tractorIds',
        fieldName: 'name',
        getData: doSearchTractorsName,
        label: 'Tractor',
        type: 'multipleAutoComplete',
      },
      {
        name: 'trailerIds',
        fieldName: 'name',
        getData: doSearchTrailersName,
        label: 'Trailer',
        type: 'multipleAutoComplete',
      },
      ...sheduleAndDateTypeFilters,
    ],
  },
  [AdvancedReportList.LOAD_TREND.id]: {
    defaultAppliedFilters: [
      {
        key: 1,
        name: 'type',
        isDefault: 'true',
        options: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Weekly', value: 'weekly' },
        ],
        label: 'Trend Type',
        type: 'singleSelect',
        style: {
          width: '100%',
        },
      },
      {
        key: 1,
        name: 'entity',
        isDefault: 'true',
        options: [...entity],
        label: 'Entity',
        type: 'singleSelect',
        style: {
          width: '100%',
        },
      },
      {
        key: 2,
        name: 'period',
        isDefault: 'true',
        options: [...period],
        label: 'Period',
        type: 'singleSelect',
        style: {
          width: '100%',
        },
      },
    ],
    allFilters: [...sheduleAndDateTypeFilters],
  },
  [AdvancedReportList.DRIVER_TIMEOFF.id]: {
    defaultAppliedFilters: [
      {
        key: 1,
        name: 'pickupRange',
        isDefault: 'true',
        label: 'Time-off Date',
        style: {
          width: '100%',
        },
        variant: 'outlined',
        ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
      },
    ],
    allFilters: [
      {
        name: 'driverIds',
        fieldName: 'driverName',
        getData: doSearchDriversName,
        label: 'Driver',
        type: 'multipleAutoComplete',
      },
      {
        key: 3,
        name: 'Status',
        fieldName: 'label',
        options: timeOff_statue,
        label: 'Status',
        type: 'multipleAutoComplete',
        style: {
          width: '100%',
        },
      },
    ],
  },
  [AdvancedReportList.IFTA.id]: {
    defaultAppliedFilters: [
      {
        name: 'quarter',
        label: 'Period *',
        style: {
          width: '100%',
        },
        variant: 'outlined',
        require: true,
        fieldName: 'name',
        isDefault: 'true',
        type: 'singleSelect',
        options: [...prepareQuarterList(3)].map((s) => ({
          label: s.name,
          value: s,
        })),
      },
    ],
    allFilters: [
      {
        // name: 'tractor',
        name: 'tractorIds',
        fieldName: 'name',
        getData: doSearchTractorsName,
        label: 'Tractor',
        type: 'multipleAutoComplete',
      },
    ],
  },
};
const timezone = getGridCompanyTimezoneSettings().timezone;
const zonedMomentDate = timezone ? moment().tz(timezone) : moment();

const defaultSelect: DefaultLoadFilter = {
  statusList: [],
  driverIds: [],
  customerIds: [],
  tractorIds: [],
  trailerIds: [],
  scheduleType: '',
  dateType: '',
  Status: [],
  pickupRange: {
    type: ERelativeDateRangeUnit.CUSTOMIZE,
    key: ERelativeDateRangeKey.PeriodTimeCustom,
    name: 'Custom',
    dateRange: [
      zonedMomentDate?.clone()?.startOf('day')?.subtract(1, 'month')?.toDate(),
      zonedMomentDate?.clone()?.endOf('day')?.toDate(),
    ],
  },
};

const defaultTrend: DefaultTrendType = {
  period: 'one',
  type: 'monthly',
  entity: 'DRIVER',
};
export const DefaultFilterValuesReport = {
  [AdvancedReportList.LOAD.id]: defaultSelect,
  [AdvancedReportList.LOAD_TREND.id]: {
    ...defaultTrend,
  },
  [AdvancedReportList.DRIVER_TIMEOFF.id]: {
    ...defaultSelect,
  },
  [AdvancedReportList.IFTA.id]: {
    quarter: '',
    tractorIds: [],
  },
};
