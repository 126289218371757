import Box from '@mui/material/Box';
import { LoadSummary } from '../../../../subPages/loadsList/models/LoadSummary';

interface IProps {
  load: LoadSummary;
}
// @TODO: Anna & Anush Review value-Load part
export const Warnings = ({ load }: IProps) => {
  const { warning } = load || {};

  if (!warning) {
    return null;
  }
  const warnings = Object.values(warning)?.filter(
    (value) => value && value !== 'Load'
  );

  if (!warnings.length) {
    return null;
  }

  return (
    <Box
      sx={{
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FFA7267F',
        padding: 2,
        borderRadius: '7px',
        color: 'text.primary',
      }}
    >
      {warnings.map((warning, index) => (
        <li key={index}>{warning}</li>
      ))}
    </Box>
  );
};
