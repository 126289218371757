export default function RightArrow() {
  return (
    <svg
      width="131"
      height="8"
      viewBox="0 0 131 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M130.354 4.35355C130.549 4.15829 130.549 3.84171 130.354 3.64645L127.172 0.464466C126.976 0.269204 126.66 0.269204 126.464 0.464466C126.269 0.659728 126.269 0.976311 126.464 1.17157L129.293 4L126.464 6.82843C126.269 7.02369 126.269 7.34027 126.464 7.53553C126.66 7.7308 126.976 7.7308 127.172 7.53553L130.354 4.35355ZM0 4.5H130V3.5H0V4.5Z"
        fill="#001122"
        fillOpacity="0.6"
      />
    </svg>
  );
}
