import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { StyleFloatingReSequenceStopIconRoot } from '../../TripPlanV3/styles';
import { StopSolutionV3Prop } from '../../TripPlanV3/types';
import { TimeLineIconMapping } from '../timelineIconsMapping';
import { classNamesTimeline } from './constants';

export interface FloatingReSequenceStopIconProps {
  isSeparatorTruckIcon: boolean;
  stopSolution: StopSolutionV3Prop;
  onClickResequenceUpHandler?: (stopSolution: StopSolutionV3Prop) => void;
  onClickResequenceDownHandler?: (stopSolution: StopSolutionV3Prop) => void;
}
export const FloatingReSequenceStopIcon = ({
  isSeparatorTruckIcon,
  stopSolution,
  onClickResequenceUpHandler,
  onClickResequenceDownHandler,
}: FloatingReSequenceStopIconProps): JSX.Element => {
  const { resequenceActions } = stopSolution;
  return (
    <StyleFloatingReSequenceStopIconRoot
      className={classNamesTimeline.StyledFloatingReSequenceStopIconRoot}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '-30px',
          marginTop: '-8px',
        }}
      >
        {resequenceActions?.resequenceUp && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              if (typeof onClickResequenceUpHandler === 'function') {
                onClickResequenceUpHandler(stopSolution);
              }
            }}
            sx={{
              width: '24px',
              height: '24px',
              marginTop: '-33px',
              ...(isSeparatorTruckIcon && {
                marginTop: '-30px',
              }),
            }}
          >
            {TimeLineIconMapping.ReSequenceStopIcon({
              sx: {
                height: '20px',
              },
            })}
          </IconButton>
        )}
        {resequenceActions?.resequenceDown && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              if (typeof onClickResequenceDownHandler === 'function') {
                onClickResequenceDownHandler(stopSolution);
              }
            }}
            sx={{
              width: '24px',
              height: '24px',
              marginTop: '-8px',
              ...(isSeparatorTruckIcon && {
                marginTop: '-8px',
              }),
            }}
          >
            {TimeLineIconMapping.ReSequenceStopIcon({
              sx: {
                height: '20px',
                transform: 'rotate(180deg)',
              },
            })}
          </IconButton>
        )}
      </Box>
    </StyleFloatingReSequenceStopIconRoot>
  );
};
