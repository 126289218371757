import { ContentCopy, DeleteOutline } from '@mui/icons-material';
import { useCallback, useMemo, useState } from 'react';
import { httpClient } from '../../../axios/axiosInstance';
import { DetailsPanel } from '../../../common/DetailsPanel/DetailsPanel';
import { QueryParams } from '../../../models';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { useStores } from '../../../store/root.context';
import { updateDocument } from '../../../utils/contract_payload_formatter';
import { CreateFormContent } from '../add_contract';

import { Skeleton, Stack, Typography } from '@mui/material';
import LongMenu from '../../../common/LongMenu';
import { BaseStatusChip } from '../../../common/Ui/StatusComponent/StatusComponent';
import { useContractPermission } from '../../../common/hooks/useContractPermission';
import { CONTRACT_STATUS_FILTERS } from '../../../constants/contracts';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
const ContractDetailsPanel = (props: any) => {
  const { data, onUpdate, onClose, handleContractDuplication, matrixData } =
    props;
  const { contractStore } = useStores();
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState(false);
  const [status, setStatus] = useState(false);
  const { hasContractDeletePermission, hasContractCreatePermission } =
    useContractPermission();
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  }>({
    type: '',
    message: '',
  });
  const [laneErrIndex, setLaneErrIndex] = useState([]);
  const [laneErr, setLaneErr] = useState({
    type: '',
    message: '',
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const handleOnSubmit = useCallback(
    async (values: any, newAttachedDocument: any, oldDocument: any) => {
      setIsSaving(true);
      setErrorMessage({
        type: '',
        message: '',
      });
      try {
        const queryParams = new QueryParams();
        const response = await httpClient.putRaw(
          'web/customer/api/v2/contracts/update',
          queryParams,
          { ...values, id: data?.id },
          true,
          true
        );
        if (response && response?.data) {
          await updateDocument(
            response?.data,
            newAttachedDocument,
            oldDocument
          );
        }
        contractStore.fetchContractList(1);
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          message: `Successfully updated contract ${response?.data?.name}`,
        });
        setIsSaving(false);
        onUpdate?.(response?.data);
        return true;
      } catch (e) {
        const err = e?.getError?.()?.response?.data;
        if (err && err.validationType === 'NON_UNIQUE_NAME') {
          setErrorMessage({
            type: err.validationType,
            message: err.message,
          });
          setIsSaving(false);
          return;
        }
        if (err && err.validationType && err.message) {
          setLaneErr({
            type: err.validationType,
            message: err.message,
          });
          setLaneErrIndex(err?.errors);
          setIsSaving(false);
          return;
        }
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'createContractErr',
        });
        setIsSaving(false);
      }
    },
    [contractStore, data?.id, onUpdate]
  );

  const deleteContract = async () => {
    try {
      const queryParams = new QueryParams();
      queryParams.id = data?.id;
      const response = await httpClient.deleteRaw(
        'web/customer/api/v2/contracts/delete',
        queryParams,
        { id: data?.id }
      );
      if (response) {
        onUpdate?.();
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          message: 'Contract deleted !',
        });
      }
    } catch (e) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Failed to delete contract',
      });
      console.log(e);
    }
  };

  const handleThreeDotAction = async (name) => {
    if (name === 'Delete Contract') setShowDeletePopup(true);
    if (name === 'Duplicate') handleContractDuplication();
  };

  const ActionRenderer = () => {
    return (
      <>
        <LongMenu
          color="#ffffff"
          options={[
            {
              name: 'Delete Contract',
              action: handleThreeDotAction,
              startIcon: <DeleteOutline />,
              disabled: !hasContractDeletePermission,
            },
            {
              name: 'Duplicate',
              action: handleThreeDotAction,
              disabled: !hasContractCreatePermission,
              startIcon: <ContentCopy />,
            },
          ]}
          canPerformClick={(event: React.MouseEvent<HTMLElement>) => {
            return true;
          }}
        />
      </>
    );
  };

  const setPanelTitle = (value) => setTitle(value);
  const setPanelStatus = (value) => setStatus(value);

  const Title = useMemo(
    () => (
      <Stack direction={'row'} spacing={1}>
        <Typography variant="subtitle1">{title}</Typography>
        {Boolean(status) && (
          <BaseStatusChip
            config={{
              color: '#ffffff',
              backgroundColor:
                status == CONTRACT_STATUS_FILTERS.ACTIVE
                  ? '#0C6BB9'
                  : '#5C667D',
              label: <Typography variant="caption">{status}</Typography>,
            }}
          />
        )}
      </Stack>
    ),
    [status, title]
  );
  return (
    <>
      <DetailsPanel
        actionsRenderer={() => <ActionRenderer />}
        data={data}
        panelTitle={
          data.id && !title ? (
            <Skeleton variant="text" height={'3rem'} width={'3rem'} />
          ) : (
            Title
          )
        }
        tabStripRenderer={() => null}
        {...props}
        footerRenderer={() => null}
        showThreeDotMenuIcon
        contentRenderer={() => (
          <CreateFormContent
            isContractDetails={true}
            setTitle={setPanelTitle}
            setStatus={setPanelStatus}
            isSaving={isSaving}
            onClose={onClose}
            onSubmit={handleOnSubmit}
            id={data?.id}
            error={laneErr}
            errorIndex={laneErrIndex}
            matrixData={matrixData}
          />
        )}
      />
      <DeletePopup
        open={showDeletePopup}
        body={`
          Are you sure you want to delete the contract?
          `}
        title="Delete Contract"
        onAction={async () => await deleteContract()}
        onClose={() => setShowDeletePopup(false)}
      />
    </>
  );
};

export default ContractDetailsPanel;
