import { number } from 'yup';
import StorageManager from '../../StorageManager/StorageManager';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  CreateSafetyAlertRequest,
  CreateUserRequest,
  DeleteSafetyAlertRequest,
  DeleteUserRequest,
  GetDocumentTypesRequest,
  GetDriverAlertListRequest,
  GetDriverIdListRequest,
  GetEldIntegrationDriversRequest,
  GetEldIntegrationsRequest,
  GetEmailsRequest,
  GetInactiveUserDriveURLRequest,
  GetInactiveUserDriverMetadataRequest,
  GetNamesRequest,
  GetPrimaryDispatcherRequest,
  GetRolesRequest,
  GetSafetyAlertListRequest,
  GetStatusesRequest,
  GetTerminalsRequest,
  GetUserByIdRequest,
  PaginatedAllUserListRequest,
  PaginatedUserListRequest,
  ValidateUserRequest,
  invitationsUserDeactivateRequest,
  invitationsUserStatusRequest,
  resetOtherUserPasswordRequest,
  resetPasswordRequest,
} from '../../models';
import {
  EldIntegrationDetails,
  EldIntegrationDrivers,
  NameDTO,
  PaginatedEmailList,
  PaginatedRoleList,
  PaginatedTerminalsList,
  PrimaryDispatcher,
  User,
  UserDetails,
  UserDriverActivation,
} from '../../models/DTOs/user/User';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { LineItem } from '../../subPages/invoices/models/InvoiceModel';
import {
  DriverAlertType,
  SafetyAlertModel,
} from '../../subPages/users/components/SafetyAlerts/models';
import { PaginatedResult } from '../../types';
import { removeExtraSpacesFromString } from '../../utils';
import { IUserService, ServiceError, annotateNameAsync } from '../interfaces';

const getUserListURL = '/web/people/api/v2/organization/users/list';
const exportUserListURL =
  '/web/people/api/v2/organization/users/webUserDriver/export';
const getAllUserListURL =
  '/web/people/api/v2/organization/users/allUsers/getList';
const exportAllUserListURL =
  '/web/people/api/v2/organization/users/allUsers/export?';
const geUserByIdURL = '/web/people/api/v2/organization/people/person';
const getUserRolesUrl = '/web/people/api/v2/info/user/role-code/dropdown';
const getEmailsUrl = '/web/people/api/v2/info/user/email/dropdown';
const getTerminalsUrl = '/web/preference/api/v2/preferences/terminals/list';
const getNamesUrl = '/web/people/api/v2/info/user/name/dropdown';
const getOwnerOperatorNamesUrl = 'web/people/api/v2/organization/dropdown/lite';
const getStatusesUrl = '/web/people/api/v2/organization/get/user/status';
const createUserUrl = '/web/people/api/v2/organization/users/create';
const updateUserUrl = '/web/people/api/v2/organization/users/edit';
const deleteUserUrl = '/web/people/api/v2/organization/users/delete';
const validateUserDeletionUrl =
  '/web/people/api/v2/organization/users/delete/validate';
const getDriverIdsUrl = '/web/people/api/v2/info/user/driver-ref-id/dropdown';
const validateUserUrl = '/web/people/api/v2/organization/validate/user';
const getDriverListDataTotalURL = '/web/people/api/v2/info/count/drivers';
const getInactiveUserMetadataURL =
  '/web/organization/api/v2/registration/activation/find/user/metadata/short';
const getInactiveDriverMetadataURL =
  '/web/organization/api/v2/registration/activation/people/person/short';
const activateUserURL =
  '/web/organization/api/v2/registration/activation/activate/user';
const getEldIntegrationDriversURL = '/web/omnibus/api/v2/drivers';
const getEldIntegrationsListURL =
  '/web/omnibus/api/v2/integrations/organization/eld/short';
const getSafetyAlertListURL = '/web/people/api/v2/driver/alert/list';
const getDriverAlertListURL = '/web/preference/api/v2/preference/alert/driver';
const getDocumentTypesURL =
  '/web/dictionary/api/v2/document/items/getByOrganizationId';
const createSafetyAlertURL = '/web/people/api/v2/driver/alert';
const deleteSafetyAlertURL = '/web/people/api/v2/driver/alert';
const safetyAlertBulkUpdate = '/web/people/api/v2/driver/alert/bulk';
const invitationsUserStatusURL = '/web/people/api/v2/invitations/invitation';
const invitationsUserValidationURL =
  '/web/people/api/v2/invitations/invitation/validation';

const validateOldPasswordUrl =
  '/web/organization/api/v2/registration/validate/oldPassword';

const validateNewPasswordUrl =
  '/web/organization/api/v2/registration/validate/newPassword';

const resetPasswordUrl = '/web/organization/api/v2/registration/reset/password';
const resetOtherUserPasswordUrl = '/web/people/api/v2/owner/manage/account';
const loginActivationURL =
  '/web/organization/api/v2/registration/activation/check/activate/web/account';

export class UserService extends IUserService {
  @annotateNameAsync
  async getPaginatedUserList(
    requestData: PaginatedUserListRequest,
    fetchingType: string
  ): Promise<PaginatedResult<User> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        fetchingType === 'GET_DATA' ? getUserListURL : exportUserListURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPaginatedAllUserList(
    requestData: PaginatedAllUserListRequest,
    fetchingType: string
  ): Promise<PaginatedResult<User> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        fetchingType === 'GET_DATA' ? getAllUserListURL : exportAllUserListURL,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getUserById(
    requestData: GetUserByIdRequest
  ): Promise<UserDetails | ServiceError> {
    try {
      const user = await httpClient.getRaw(geUserByIdURL, requestData);
      return user.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getUserRoles(requestData: GetRolesRequest) {
    try {
      const response = await httpClient.get<PaginatedRoleList>(
        getUserRolesUrl,
        requestData,
        PaginatedRoleList,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getUserEmails(requestData: GetEmailsRequest) {
    try {
      const response = await httpClient.get<PaginatedEmailList>(
        getEmailsUrl,
        requestData,
        PaginatedEmailList,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTerminals(requestData: GetTerminalsRequest) {
    try {
      const response = await httpClient.get<PaginatedTerminalsList>(
        getTerminalsUrl,
        requestData,
        PaginatedTerminalsList,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverIdList(requestData: GetDriverIdListRequest) {
    try {
      const response = await httpClient.getRaw(getDriverIdsUrl, requestData);
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getNames(requestData: GetNamesRequest) {
    try {
      const response = await httpClient.getRaw(
        getNamesUrl,
        requestData,
        undefined,
        true
      );

      const content = response?.data?.content?.map((item: NameDTO) => {
        return {
          id: item.id,
          nameToFilter: item.name,
          name: removeExtraSpacesFromString(item.name),
        };
      });

      return { ...response?.data, content };
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getOwnerOperatorNames(requestData: GetNamesRequest) {
    try {
      const response = await httpClient.getRaw(
        getOwnerOperatorNamesUrl,
        requestData,
        undefined,
        true
      );

      const content = response?.data?.content?.map((item: NameDTO) => {
        return {
          id: item.id,
          nameToFilter: item.name,
          name: removeExtraSpacesFromString(item.name),
        };
      });

      return { ...response?.data, content };
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getStatuses(requestData: GetStatusesRequest) {
    try {
      const response = await httpClient.getRaw(
        getStatusesUrl,
        requestData,
        false,
        true
      );
      return response.data[0];
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createUser(
    requestData: CreateUserRequest,
    data: any
  ): Promise<{ [key: string]: UserDetails | null } | ServiceError> {
    try {
      const dot = StorageManager.getUser().dot;
      const response = await httpClient.postRaw(createUserUrl, undefined, {
        ...requestData,
        ...data,
        dot,
      });
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'userCreate',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userCreate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateUser(requestData: CreateUserRequest, data: any) {
    try {
      const response = await httpClient.putRaw(updateUserUrl, undefined, {
        ...requestData,
        ...data,
      });
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'userUpdate',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userUpdate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteUser(
    requestData: DeleteUserRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        deleteUserUrl,
        requestData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'userDelete',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userDelete',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async validateUserDeletion(
    requestData: DeleteUserRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        validateUserDeletionUrl,
        requestData,
        true
      );

      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userDelete',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async validateResetPassword(
    payloadData: resetPasswordRequest,
    passwordType: string
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        passwordType === 'OLD_PASSWORD'
          ? validateOldPasswordUrl
          : validateNewPasswordUrl,
        undefined,
        payloadData,
        true
      );

      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;

      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async resetPassword(
    payloadData: resetPasswordRequest
  ): Promise<string | undefined> {
    try {
      const response = await httpClient.postRaw(
        resetPasswordUrl,
        undefined,
        payloadData,
        true
      );

      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'userUpdate',
      });

      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userUpdate',
      });
      return;
    }
  }

  @annotateNameAsync
  async resetOtherUserPassword(
    payloadData: resetOtherUserPasswordRequest
  ): Promise<string | undefined> {
    try {
      const response = await httpClient.putRaw(
        resetOtherUserPasswordUrl,
        undefined,
        payloadData,
        true
      );

      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'userUpdate',
      });

      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userUpdate',
      });
      return;
    }
  }

  /* @TODO davits fix return type */
  @annotateNameAsync
  async validateUser(
    requestData: ValidateUserRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        validateUserUrl,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;

      const errorObj = this.getServiceError(composedError);
      if (errorObj) {
        if (
          errorObj?.error?.response?.data?.apierror?.message ==
          'Unique Constraint Exception'
        )
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'duplicateEmail',
          });
        else
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'updateUser',
          });
      }
      return errorObj;
    }
  }

  @annotateNameAsync
  async getPrimaryDispatchers(
    requestData: GetPrimaryDispatcherRequest,
    cancelable = false,
    skipLoader = false
  ): Promise<PaginatedResult<PrimaryDispatcher> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getUserListURL,
        requestData,
        cancelable,
        skipLoader
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverListDataTotal(
    requestData: GetEldIntegrationsRequest
  ): Promise<number | ServiceError> {
    try {
      return await httpClient.get<number>(
        getDriverListDataTotalURL,
        requestData,
        number,
        false,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async getInactiveDriverUserMetadata(
    requestData: GetInactiveUserDriverMetadataRequest,
    type: string
  ): Promise<UserDriverActivation | null> {
    try {
      return await httpClient.get<UserDriverActivation>(
        type === 'USER'
          ? getInactiveUserMetadataURL
          : getInactiveDriverMetadataURL,
        requestData,
        UserDriverActivation,
        false,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error;

        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async activateUserDriver(
    payloadData: GetInactiveUserDriverMetadataRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        loginActivationURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Account activated successfully',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userStatusChange',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async activateUserURL(
    payloadData: GetInactiveUserDriveURLRequest,
    type: string
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        activateUserURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Account activated successfully',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userStatusChange',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getEldIntegrationsList(
    requestData: GetEldIntegrationsRequest
  ): Promise<EldIntegrationDetails[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getEldIntegrationsListURL,
        requestData,
        false
      );
      return response.data.eldIntegrationsDTO;
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async getEldIntegrationDriversByCredentialId(
    requestData: GetEldIntegrationDriversRequest
  ): Promise<EldIntegrationDrivers | ServiceError> {
    try {
      return await httpClient.get<EldIntegrationDrivers>(
        getEldIntegrationDriversURL,
        requestData,
        EldIntegrationDrivers,
        false
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async getSafetyAlerts(
    requestData: GetSafetyAlertListRequest
  ): Promise<SafetyAlertModel[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getSafetyAlertListURL,
        requestData,
        undefined
      );
      return response.data.map((alert: object) => new SafetyAlertModel(alert));
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverAlerts(
    requestData: GetDriverAlertListRequest
  ): Promise<DriverAlertType | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getDriverAlertListURL,
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDocumentTypes(
    requestData: GetDocumentTypesRequest
  ): Promise<LineItem[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getDocumentTypesURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createSafetyAlert(
    requestData: CreateSafetyAlertRequest
  ): Promise<LineItem[] | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        createSafetyAlertURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'userAlertCreate',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userAlertCreate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteSafetyAlert(
    requestData: DeleteSafetyAlertRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        deleteSafetyAlertURL,
        requestData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'userAlertDelete',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userAlertDelete',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async safetyAlertBulkUpdate(
    requestData: DeleteSafetyAlertRequest,
    alertsList: SafetyAlertModel[]
  ): Promise<SafetyAlertModel[] | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        safetyAlertBulkUpdate,
        requestData,
        alertsList
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'userAlertUpdate',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userAlertUpdate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async invitationsUserStatus(
    requestData: invitationsUserStatusRequest
  ): Promise<UserDetails | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        invitationsUserStatusURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'userInvitationStatus',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userInvitationStatus',
      });
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async invitationsUserDeactivateValidation(
    requestData: invitationsUserStatusRequest
  ): Promise<invitationsUserDeactivateRequest | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        invitationsUserValidationURL,
        undefined,
        requestData
      );
      if (response.data.valid) {
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'userStatusChange',
        });
      }
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'userStatusChange',
      });
      return this.getServiceError(composedError);
    }
  }
}
