import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@components/ui-kit/accordion';
import { yupResolver } from '@hookform/resolvers/yup';
import { LocationOnOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { tripService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import {
  RootStoreInstence,
  useRootStore,
} from '../../../../../store/root-store/rootStateContext';
import { ErrorText } from '../../../../../subPages/loadsList/CreateLoad/components/ErrorText';
import Header from '../../../../../subPages/loadsList/CreateLoad/components/header';
import {
  StopsDetails,
  StopsSummary,
} from '../../../../../subPages/loadsList/CreateLoad/components/stops';
import { getLoadStopRequestDTO } from '../../../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import { transformEmptyStringToNull } from '../../../../../subPages/loadsList/CreateLoad/schema';
import { stopSchema } from '../../../../../subPages/loadsList/CreateLoad/schema/stops';
import { createLoadService } from '../../../../../subPages/loadsList/CreateLoad/services/createLoad.service';
import { ErrorTextFieldStyles } from '../../../../../subPages/loadsList/CreateLoad/style';
import { getCreateLoadPanelStyles } from '../../../../../subPages/loadsList/CreateLoad/styles';
import { ILoadStop } from '../../../../../subPages/loadsList/CreateLoad/types/requests';
import { CreateEmptyTripDTO } from './dto/createEmptyTripDto';
import { initialEmptyTripData } from './lib/data.';

interface ICreateEmptyTrip {
  open: boolean;
  onClose?: () => void;
  onAfterSubmit?: (id?: string) => void;
}

const CreateEmptyTrip: React.FC<ICreateEmptyTrip> = ({
  open,
  onClose,
  onAfterSubmit,
}) => {
  const theme = useTheme();
  const styles = getCreateLoadPanelStyles(theme as Theme);
  const [loader, setLoader] = useState<boolean>(false);

  const [expandedSection, setExpandedSection] = React.useState<string | false>(
    'stops'
  );
  const { isTerminalEnabled, allTerminals } = useRootStore();
  const primaryTerminal = allTerminals?.find?.((e: any) => e?.isPrimary);

  const handleClose = () => {
    form.reset();
    onClose?.();
  };

  const handleExpandedSectionChange =
    (section: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedSection(isExpanded ? section : '');
    };

  const form = useForm({
    resolver: yupResolver(
      yup.object().shape({
        isEmptyTrip: yup.boolean(),
        stops: yup.array().of(yup.object().shape(stopSchema)).min(2).required(),
        terminalId: isTerminalEnabled
          ? yup
              .string()
              .typeError('Dispatch Terminal is required.')
              .required('Dispatch Terminal is required.')
              .transform(transformEmptyStringToNull)
          : yup.string().optional().nullable(),
      })
    ),
    defaultValues: initialEmptyTripData({
      terminalId: isTerminalEnabled ? primaryTerminal?.id : null,
    }),
  });
  const validateFormCustomErrors = (): boolean => {
    const { stopApptDatesValidation } = createLoadService.validateForm(
      form.getValues() as any
    );
    if (stopApptDatesValidation?.errorCount > 0) {
      for (let i = 0; i < stopApptDatesValidation?.errors?.length; i++) {
        const errorList = stopApptDatesValidation.errors?.[i];
        errorList?.forEach((err) => {
          form?.setError(
            (err?.fieldName as any) ?? `stops.${i}.appointmentStartDate`,
            err
          );
        });
      }
    }
    return !Boolean(stopApptDatesValidation?.errorCount);
  };

  const handleCreateTrip = async () => {
    try {
      setLoader(true);
      const stops = form
        .getValues('stops')
        ?.map((e) => getLoadStopRequestDTO(e as ILoadStop));
      const organizationId = StorageManager.getUser()?.organizationId;
      const createEmptyTripDTO = new CreateEmptyTripDTO({
        stops,
        organizationId,
        terminalId: isTerminalEnabled
          ? form.getValues('terminalId')!
          : undefined,
      });
      const res = await tripService.createEmptyTrip(createEmptyTripDTO);
      if (res instanceof ServiceError)
        throw new Error('Failed to create empty trip.');
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createEmptyTrip',
      });
      setLoader(false);
      form.reset();
      onAfterSubmit?.(res);
    } catch (error) {
      console.log(error);
      setLoader(false);
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'createEmptyTrip',
      });
    }
  };

  const handleOnSave = async () => {
    await form.trigger();
    form.handleSubmit(handleCreateTrip)?.();
    setTimeout(() => {
      validateFormCustomErrors();
    });
  };

  const getInvoiceOptionLabel = useCallback(
    (value: object) =>
      createLoadService.getAutocompleteOptionLabel({
        list: allTerminals,
        value,
        fieldName: 'companyName',
      }),
    [allTerminals]
  );

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      <Header
        title={'Create Empty Trip'}
        onClose={handleClose}
        // toggleSidebar={toggleSidebar}
      />
      <DialogContent sx={styles.root}>
        <Box sx={styles.container} className="CreateLoadPanel-root">
          <Box sx={styles.form}>
            <FormProvider {...form}>
              <form>
                {isTerminalEnabled && (
                  <Stack sx={{ pt: 1, maxWidth: '320px' }}>
                    <Controller
                      name="terminalId"
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          id="terminal-id"
                          options={allTerminals ?? []}
                          getOptionLabel={getInvoiceOptionLabel}
                          onChange={(_, value) => {
                            field.onChange((value as any)?.id ?? '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Dispatch Terminal"
                              sx={
                                form.formState?.errors?.terminalId?.message
                                  ? ErrorTextFieldStyles
                                  : {}
                              }
                            />
                          )}
                        />
                      )}
                    />
                    <ErrorText
                      text={form.formState?.errors?.terminalId?.message}
                    />
                  </Stack>
                )}
                <Accordion
                  expanded={expandedSection === 'stops'}
                  onChange={handleExpandedSectionChange('stops')}
                >
                  <AccordionSummary
                    id="stops"
                    prefixIcon={<LocationOnOutlined />}
                  >
                    {expandedSection !== 'stops' &&
                    form.getValues('stops')?.[0]?.location?.locationName
                      ?.length ? (
                      <></>
                    ) : (
                      'Stops'
                    )}
                    {expandedSection !== 'stops' && <StopsSummary />}
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <StopsDetails />
                  </AccordionDetails>
                </Accordion>
              </form>
            </FormProvider>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ gap: '10px', padding: '15px 20px' }}>
        <Button onClick={handleClose} size="large">
          Cancel
        </Button>
        <ButtonGroup
          variant="contained"
          size="large"
          sx={{ boxShadow: 'none' }}
        >
          <Button
            onClick={handleOnSave}
            disabled={loader}
            sx={{ eidth: '240px' }}
          >
            {loader ? (
              <CircularProgress sx={{ maxWidth: '20px', maxHeight: '20px' }} />
            ) : (
              'Save'
            )}
          </Button>
          {/* <Button>
            <ArrowDropDown />
          </Button> */}
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export { CreateEmptyTrip };
