import React, { useContext, useMemo, useState } from 'react';

import Accordion from '../../../common/Accordion';
import DatePicker from '../../../common/Ui/FormDatePicker/FormDatePicker';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import ThreeDotMenuIcon from '../../../ui-kit/components/ThreeDotMenuIcon';
import { TrailerAlertSummaryDTO } from '../../../models';
import { SafetyAlertModel } from '../../../subPages/users/components/SafetyAlerts/models';
import {
  addNameToAlertFromDictionary,
  defaultMarkDoneAlertData,
  getAlertStatusType,
  MenuOptions,
} from '../../shared/equipment';

import { useFormContext } from 'react-hook-form';
import { Box, Dialog, Grid, Typography } from '@mui/material';

import { Theme } from '@mui/system';
import MaintenanceDetailPanelWrapper from '../../../views/maintenanceRecords/Popups/component/MaintenanceDetailsForm/MaintenanceDetailPanelWrapper';
import { EquipmentType } from '../../../views/maintenanceRecords/constants/constants';
import { MaintenanceHistoryContext } from '../../equipment/maintenance/context/MaintenanceHistoryContext';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../common/Permission';
import { getAccountTypeWithRoleCode, isHasPermission } from '../../../utils';
import StorageManager from '../../../StorageManager/StorageManager';
import { t } from 'i18next';
import { getPaginatedMaintHistoryListRequest } from '@/subPages/equipment/maintenance/controllers/Controller';
import { pageSize } from '@/subPages/loadsList/LoadDetailsPanel/constants/constants';

type propsType = {
  index: number;
  handleDelete: (alert: TrailerAlertSummaryDTO) => void;
  dirtyFields?: SafetyAlertModel[];
  onUpdateWarningsCount?: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
};

function AlertsTabCard({
  index,
  handleDelete,
  dirtyFields,
  onUpdateWarningsCount,
}: propsType) {
  const [expanded, setExpanded] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenMaintenance, setIsOpenMaintenance] = useState<boolean>(false);

  const { watch, control, getValues, setValue } = useFormContext();
  const data = getValues();

  const { getGlobalTerminals } = useRootStore();

  const { getPaginatedMaintenanceHistoryList, filters } = useContext(
    MaintenanceHistoryContext
  );

  const alert = watch(`alertsList.${index}`);

  const storageUserData = StorageManager.getUser();
  const noPermissionForEditAllerts = !isHasPermission([
    AXELE_PERMISSION_TYPE.ALERTS_EDIT,
  ]);

  const alertTabData = addNameToAlertFromDictionary(
    alert,
    EquipmentType.Trailer
  );

  const defaultMaintenanceData = useMemo(() => {
    return defaultMarkDoneAlertData(
      alertTabData,
      EquipmentType.Trailer,
      data,
      getGlobalTerminals
    );
  }, []);

  const handleCreateMaintenance = async (): Promise<void> => {
    setIsOpenMaintenance(false);
    getPaginatedMaintenanceHistoryList &&
      (await getPaginatedMaintenanceHistoryList(
        getPaginatedMaintHistoryListRequest({
          ...filters,
          pageSize: pageSize,
        })
      ));
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    action: { icon: string; label: string },
    accountData: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (action?.label !== 'Delete') return;
    handleDelete(alert);
  };

  const titleRenderer = (field: TrailerAlertSummaryDTO) => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'grid' }}>
          <Typography
            variant="h7"
            sx={{ color: expanded ? 'primary.main' : 'text.primary' }}
          >
            {addNameToAlertFromDictionary(field, 'TRAILER').itemName}
          </Typography>
          <Typography variant="body3" sx={{ color: 'text.secondary' }}>
            {getAlertStatusType(field)?.message}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {getAlertStatusType(field)?.icon}
          <Permission contains={[AXELE_PERMISSION_TYPE.EQUIPMENT_ALERT_REMOVE]}>
            <ThreeDotMenuIcon
              menuOptions={MenuOptions}
              open={Boolean(anchorEl)}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              anchorEl={anchorEl}
              handleMenuItemClick={handleMenuItemClick}
            />
          </Permission>
        </Box>
      </Box>
    );
  };
  return (
    <Grid
      item
      sx={{
        border: (theme: Theme) => {
          const {
            palette: {
              primary: { main },
            },
          } = theme;
          return expanded ? `1px solid ${main}` : '';
        },
        borderRadius: '8px',
        padding: '5px',
        marginTop: '14px',
        backgroundColor: expanded
          ? 'uncategorized.card'
          : 'uncategorized.accordion',
        width: '100%',
      }}
    >
      <Accordion
        name={alert.maintenanceType}
        summaryRenderer={() => titleRenderer(alert)}
        updateParentState={setExpanded}
        expanded={expanded}
      >
        <Grid container spacing={3}>
          <DatePicker
            disabled={noPermissionForEditAllerts}
            control={control}
            disabledText={false}
            name={`alertsList.${index}.lastConducted`}
            label="Last Done"
            sizes={{ xs: 6 }}
            disableFuture
            startOfDate
            iso
          />
          <DatePicker
            disabled={noPermissionForEditAllerts}
            control={control}
            disabledText={false}
            name={`alertsList.${index}.nextDue`}
            label="Next Due"
            sizes={{ xs: 6 }}
            startOfDate
            iso
          />
          {!dirtyFields?.[index] && (
            <Grid container justifyContent={'flex-end'}>
              <Grid item sx={{ marginTop: '12px' }}>
                <ButtonImproved
                  disabled={noPermissionForEditAllerts}
                  size="medium"
                  variant="contained"
                  label={t('markDone')}
                  onClick={() => {
                    setIsOpenMaintenance(true);
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Accordion>
      {isOpenMaintenance && (
        <Dialog
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
          open
        >
          <MaintenanceDetailPanelWrapper
            data={defaultMaintenanceData}
            onUpdateWarningsCount={onUpdateWarningsCount}
            index={index}
            equipmentId={data.id}
            setValue={setValue}
            onCloseMaintenance={() => setIsOpenMaintenance(false)}
            onCreateSuccess={() => {
              handleCreateMaintenance();
            }}
            lockEquipment
            disableAutocomplate
          />
        </Dialog>
      )}
    </Grid>
  );
}

export default AlertsTabCard;
