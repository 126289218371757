import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../StorageManager/StorageManager';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../common/Permission';
import FormDialog from '../../../common/Ui/FormDialog/index';
import { TractorAlertSummaryDTO } from '../../../models';
import { AddIcon } from '../../../ui-kit/components/Assets';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
import { useSafetyAlert } from '../../../views/safety/alerts/context/SafetyAlertContext';
import {
  addNameToAlertFromDictionary,
  tractorAlertValidationSchema,
} from '../../shared/equipment';
import Controller from '../Controller';
import { isInactive } from '../utils';
import AddNewAlertForm from './AddNewAlertForm';
import AlertsTabCard from './AlertsTabCard';

function AlertsTabDetails({
  onUpdateWarningsCount,
  disableAllFields,
}: {
  onUpdateWarningsCount: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
  disableAllFields?: boolean;
}) {
  const [isCreateAlertFormDialogOpen, setIsCreateAlertFormDialogOpen] =
    useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deletedField, setDeletedField] = useState<TractorAlertSummaryDTO>();

  const {
    createTractorAlert,
    defaultTractorsTypesList,
    deleteTractorAlertById,
  } = Controller.instance();

  const { watch, setValue, formState } = useFormContext();

  const alertList = watch('alertsList') || [];

  const tractorId = watch('id');
  const status = watch('status');

  const dirtyFields = formState.dirtyFields['alertsList'];
  const storageUserData = StorageManager.getUser();

  const handleOpenAddAlertModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setIsCreateAlertFormDialogOpen(true);
  };

  const onAddAlert = (data: TractorAlertSummaryDTO) => {
    const defaultTractorsType = defaultTractorsTypesList?.find(
      (item) => item.maintenanceTypeId === data.maintenanceTypeId
    );
    if (!defaultTractorsType) return;

    const updatedData = {
      ...data,
      maintenanceType: defaultTractorsType.maintenanceType,
    };
    createTractorAlert(updatedData, (alert: TractorAlertSummaryDTO) => {
      fetchMainData?.();
      handleClose();
      setValue('alertsList', [...alertList, alert]);
      if (alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'WARNING') {
        onUpdateWarningsCount(tractorId, 1, 0, true);
      }
      if (alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'CRITICAL') {
        onUpdateWarningsCount(tractorId, 0, 1, true);
      }
    });
  };
  const handleClose = () => {
    setIsCreateAlertFormDialogOpen(false);
  };

  const confirmAlertDeletion = (alert: TractorAlertSummaryDTO) => {
    setDeletedField(alert);
    setDeleteDialogOpen(true);
  };

  const { fetchMainData } = useSafetyAlert();

  const handleDelete = () => {
    setDeleteDialogOpen(false);

    if (!deletedField || !deletedField.id) return;
    const updatedAlerts = alertList.filter(
      (item: TractorAlertSummaryDTO) => item.id !== deletedField.id
    );

    deleteTractorAlertById(deletedField.id, () => {
      setValue('alertsList', updatedAlerts);
      fetchMainData?.();
    });
    if (
      deletedField?.safetyIssueTypeWithDaysAndMiles?.issueType === 'WARNING'
    ) {
      onUpdateWarningsCount(tractorId, -1, 0, true);
    }
    if (
      deletedField?.safetyIssueTypeWithDaysAndMiles?.issueType === 'CRITICAL'
    ) {
      onUpdateWarningsCount(tractorId, 0, -1, true);
    }
  };

  const getFilteredTypesList = () => {
    const defaultTractorsTypes = defaultTractorsTypesList.filter(
      (item) =>
        !alertList?.find(
          (element: any) => element.maintenanceTypeId === item.maintenanceTypeId
        )
    );

    return defaultTractorsTypes.map((item) => {
      return addNameToAlertFromDictionary(
        item,
        'TRACTOR'
      ) as TractorAlertSummaryDTO;
    });
  };

  const equipmentAddAlertsPermission: {
    [key: string]: AXELE_PERMISSION_TYPE[];
  } = {
    AD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_ADD],
    DI: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_ADD],
    NFD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_ADD],
    PD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_ADD],
  };

  const equipmentViewAlertsPermission: {
    [key: string]: AXELE_PERMISSION_TYPE[];
  } = {
    AD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_VIEW],
    DI: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_VIEW],
    NFD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_VIEW],
    PD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_VIEW],
  };

  return (
    <Grid container sx={{ p: 2 }}>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={0}
      >
        <Grid item>
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            Alerts
          </Typography>
          {defaultTractorsTypesList.length !== alertList?.length &&
            !isInactive(status) &&
            !disableAllFields && (
              <Permission
                includes={
                  equipmentAddAlertsPermission[storageUserData?.roleCode]
                }
              >
                <Button
                  onClick={handleOpenAddAlertModal}
                  variant="outlined"
                  style={{
                    minWidth: 38,
                    minHeight: 38,
                    marginLeft: 10,
                    borderColor: 'primary.main',
                    marginBottom: 8,
                  }}
                >
                  <AddIcon />
                </Button>
              </Permission>
            )}
        </Grid>
        <Grid item>
          <Grid item container direction={'column'}>
            <Grid container item justifyContent="flex-end">
              <Typography variant="h7" sx={{ color: 'primary.main' }}>
                {alertList?.length || 0}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="tooltip" sx={{ color: 'text.secondary' }}>
                {t('totalAlerts')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Permission
        includes={equipmentViewAlertsPermission[storageUserData?.roleCode]}
      >
        {alertList && !isInactive(status)
          ? alertList.map((field: TractorAlertSummaryDTO, index: number) => (
              <AlertsTabCard
                key={field.id}
                index={index}
                handleDelete={confirmAlertDeletion}
                dirtyFields={dirtyFields}
                onUpdateWarningsCount={onUpdateWarningsCount}
                disableAllFields={disableAllFields}
              />
            ))
          : null}
      </Permission>
      {isCreateAlertFormDialogOpen && (
        <FormDialog
          data={{
            tractorId,
            maintenanceTypeId: getFilteredTypesList()[0]?.maintenanceTypeId,
          }}
          titleText={t('addNewAlert')}
          actionLabel={t('addAlert')}
          open={isCreateAlertFormDialogOpen}
          onAction={onAddAlert}
          handleClose={handleClose}
          contentRenderer={() => (
            <AddNewAlertForm alertOptions={getFilteredTypesList()} />
          )}
          validationSchema={tractorAlertValidationSchema}
        />
      )}
      {deleteDialogOpen && (
        <DeletePopup
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onAction={handleDelete}
          title={t('deleteAlert')}
          body="Are you sure you want to delete the alert?"
        />
      )}
    </Grid>
  );
}

export default AlertsTabDetails;
