export const SettlementConstants = {
  createSettlementView: 'Create a Settlement View',
  createSettlement: 'Create Settlement',
  markAsReviewed: 'Mark As Reviewed',
  markAsInReview: 'Mark As In Review',
  revertToInReview: 'Mark as In Review',
  closeSettlement: 'Close Settlement',
  closeSettlements: 'Close Settlements',
  emailSettlement: 'Email Settlement',
  removePayment: 'Remove Payment',
  removeItemFromSettlement: 'This only removes items from this settlement',
  addedToPendingPayment: 'It will be added to the pending payments',
  deleteSettlement: 'Delete Settlement',
  deleteSettlementConfirmation:
    'Are you sure you want to permanently delete this settlement?',
  deleteSettlementSubtitle:
    'Please note, that after deletion the line items will be added to the pending payments',
  reopenSettlement: 'Reopen Settlement',
  revertToInReviewTitle: 'Mark as In Review',
  revertToReviewedTitle: 'Mark as Reviewed',
  totalTripIncome: 'Total Trip Income',
  totalReimbursement: 'Total Reimbursement',
  totalDeduction: 'Total Deduction',
  pendingAmount: 'Pending Amount',
  totalAmount: 'Total Amount',
  settlementPeriod: 'Create Settlement',
  closeSettlementConfirmation:
    'Are you sure you want to close this settlement?',
  markAsClosed: 'Mark as Closed',
  loadStaus: 'Load Status',
  trip: 'Trip',
  resetToDefault: 'Reset to Default',
  createMultipleSettlementDateRange:
    'This settlement will include all completed trips, along with their associated payments, deductions, and reimbursements, within the selected period.',
  bulkSettlementEmailNote:
    'Note: For each settlement, an email will be sent to the primary email address associated with the user. For tractor settlement, the email will be sent to the owner of the tractor.',
  closeSettlementBulkNote:
    'Add the date this settlement was paid. This date can’t be in the future.',
  settlementPaymentDate: 'Payment Date',
  closeSettlementFormHelperText:
    'Date in which all parties involved in the settlement received payment.',
  noPayItemToCreateSettlement:
    'Settlement(s) cannot be created as the payments are not found in the selected settlement period.',
};
