/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import { plainToInstance } from 'class-transformer';
import Events from '../EventEmitter/EventEmitter';
import { QueryParams, TokenWrapper } from '../models';

import { EventTypes } from '../EventEmitter/EventTypes';
import EncryptedStorageManager from '../StorageManager/EncryptedStorageManager';
import { RootStoreInstence } from '../store/root-store/rootStateContext';
import { getPermisssionHashFromToken, refetchTokens } from './helper';
const baseURL = '/';

const axiosInstance = axios.create({
  baseURL,
  headers: {
    mp_front: 'aaa',
  },
});

const currentExecutingRequests: {
  [key: string]: CancelTokenSource;
} = {};

const getUniqueKeyForRequest = (
  req: AxiosRequestConfig<any>
): string | undefined => {
  if (req && req.method && req.url) {
    return req?.method + '_' + req?.url;
  }
  return '';
};

const cancelIfNeeded = (req: AxiosRequestConfig<any>) => {
  const originalRequest = req;

  if (!originalRequest.data?.cancelable) {
    return;
  }
  const key = getUniqueKeyForRequest(req);

  if (key) {
    if (currentExecutingRequests[key]) {
      const source = currentExecutingRequests[key];
      delete currentExecutingRequests[key];
      // @ts-expect-error There is the no way pass req object, without extending axios type
      source.cancel({ originalRequest });
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    originalRequest.cancelToken = source.token;
    currentExecutingRequests[key] = source;
  }
};

axiosInstance.interceptors.request.use(async (req) => {
  const authTokens = EncryptedStorageManager.getItem('token') || null;
  if (req.headers) {
    req.headers['mp-auth-token'] = `${authTokens?.access}`;
  }
  cancelIfNeeded(req);
  return req;
});

export abstract class ComposedError {
  readonly message!: string | AxiosError;
  readonly error!: AxiosError;
  abstract handleGlobally(): void;
  abstract getError(): AxiosError;
  abstract getStatusCode(): number | null | undefined;
}

export class FormatError extends Error {
  message: string;
  constructor(message: string) {
    super();
    this.message = message;
  }
}

export class ComposedRequestError extends ComposedError {
  name = 'ComposedRequestError';
  public readonly message: string | AxiosError;
  public readonly error: AxiosError;

  private _globallyHandled = false;

  constructor(error: AxiosError) {
    super();
    this.error = error;
    const statusCode = error.response ? error.response.status : null;

    switch (statusCode) {
      case 401:
        this.message = 'Please login to access this page';
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'unauthorized',
        });
        Events.emit(EventTypes.AUTH_ERROR);
        break;
      case 404:
        this.message =
          'The requested resource does not exist or has been deleted';
        break;
      default:
        this.message = error;
    }
  }

  public getError(): AxiosError {
    return this.error;
  }

  public handleGlobally(): void {
    if (this._globallyHandled) return;
    this._globallyHandled = true;
  }

  getStatusCode(): number | null | undefined {
    const statusCode = this.error.response ? this.error.response.status : null;
    return statusCode;
  }
}

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      currentExecutingRequests[getUniqueKeyForRequest(response.config) || '']
    ) {
      delete currentExecutingRequests[
        getUniqueKeyForRequest(response.config) || ''
      ];
    }
    return response;
  },
  async function (error) {
    let originalRequest = error.config;
    if (axios.isCancel(error)) {
      if (error.message && error.message.originalRequest) {
        originalRequest = error.message.originalRequest;
      }
      if (
        originalRequest?.url &&
        currentExecutingRequests[getUniqueKeyForRequest(originalRequest) || '']
      ) {
        delete currentExecutingRequests[
          getUniqueKeyForRequest(originalRequest) || ''
        ];
      }
    }
    if (error?.response?.status === 403) {
      Events.emit(EventTypes.SESSION_EXPIRED);
    }
    if (error?.response?.status === 401 && !originalRequest._retry) {
      const tokens = await refetchTokens();
      const localPermHash = await getPermisssionHashFromToken();
      if (tokens) {
        const remotePermHash = await getPermisssionHashFromToken(tokens?.token);
        if (localPermHash === remotePermHash) {
          originalRequest._retry = true;
          originalRequest.headers['mp-auth-token'] = tokens?.token;
          const newTokens = new TokenWrapper(tokens.token, tokens.refreshToken);
          EncryptedStorageManager.setItem('token', JSON.stringify(newTokens));
          return axiosInstance(originalRequest);
        } else {
          Events.emit(EventTypes.PERMISSION_CHANGED);
        }
      }
    }
    return Promise.reject(error);
  }
);

class HttpClient {
  private static instance: HttpClient;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): HttpClient {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient();
    }

    return HttpClient.instance;
  }

  public async get<T>(
    url: string,
    queryParams: QueryParams | undefined,
    Model: any | null,
    cancelable = false,
    skipLoader = false
  ): Promise<T> {
    let response;
    !skipLoader && RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.get(url, {
        params: queryParams?.getParamsFromRequest(),
        data: {
          cancelable,
        },
      });
    } catch (error) {
      throw error;
    } finally {
      !skipLoader && RootStoreInstence.setLoadingFinishedUrl(url);
    }
    const data = await response.data;
    const dto = plainToInstance<T, AxiosResponse<T>>(Model, data);

    return dto as unknown as T;
  }

  public async getRaw(
    url: string,
    queryParams: QueryParams | undefined,
    cancelable = false,
    skipLoader = false
  ): Promise<AxiosResponse['data']> {
    let response;
    !skipLoader && RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.get(url, {
        params: queryParams?.getParamsFromRequest(),
        data: {
          cancelable,
        },
      });
    } catch (error) {
      throw error;
    } finally {
      !skipLoader && RootStoreInstence.setLoadingFinishedUrl(url);
    }
    return response;
  }

  public async post<T>(
    url: string,
    queryParams: QueryParams | undefined,
    payload: any,
    Model: any,
    cancelable = false,
    headers: AxiosRequestHeaders = { 'Content-Type': 'application/json' },
    skipLoader = false
  ): Promise<T> {
    let response;
    !skipLoader && RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.post(url, payload, {
        params: queryParams?.getParamsFromRequest(),
        data: {
          cancelable,
        },
        headers: headers,
      });
    } catch (error) {
      throw error;
    } finally {
      !skipLoader && RootStoreInstence.setLoadingFinishedUrl(url);
    }
    const data = await response.data;
    const dto = plainToInstance<T, AxiosResponse<T>>(Model, data);

    return dto as unknown as T;
  }

  public async postRaw(
    url: string,
    queryParams: QueryParams | undefined,
    payload: any,
    cancelable = false,
    headers = { 'Content-Type': 'application/json' },
    skipLoader = false
  ): Promise<AxiosResponse['data']> {
    let response;
    !skipLoader && RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.post(url, payload, {
        params: queryParams?.getParamsFromRequest(),
        data: { cancelable },
        headers,
      });
    } catch (error) {
      throw error;
    } finally {
      !skipLoader && RootStoreInstence.setLoadingFinishedUrl(url);
    }
    return response;
  }

  public async put<T>(
    url: string,
    queryParams: QueryParams | undefined,
    payload: any,
    Model: any,
    cancelable = false,
    skipLoader = false
  ): Promise<T> {
    let response;
    !skipLoader && RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.put(url, payload, {
        params: queryParams?.getParamsFromRequest() || queryParams,
        data: {
          cancelable,
        },
      });
    } catch (error) {
      throw error;
    } finally {
      !skipLoader && RootStoreInstence.setLoadingFinishedUrl(url);
    }
    const data = await response.data;
    const dto = plainToInstance<T, AxiosResponse<T>>(Model, data);

    return dto as unknown as T;
  }

  public async putRaw(
    url: string,
    queryParams: QueryParams | undefined,
    payload: any,
    cancelable = false,
    /**
     * Won't show loading on UI
     */
    skipLoader = false
  ): Promise<AxiosResponse['data']> {
    let response;
    !skipLoader && RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.put(url, payload, {
        params: queryParams?.getParamsFromRequest(),
        data: {
          cancelable,
        },
      });
    } catch (error) {
      throw error;
    } finally {
      !skipLoader && RootStoreInstence.setLoadingFinishedUrl(url);
    }
    return response;
  }

  public async delete<T>(
    url: string,
    queryParams: QueryParams | undefined,
    payload: any,
    Model: any,
    skipLoader?: boolean
  ): Promise<T> {
    let response;
    !skipLoader && RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.delete(url, {
        params: queryParams?.getParamsFromRequest(),
        data: payload,
      });
    } catch (error) {
      throw error;
    } finally {
      !skipLoader && RootStoreInstence.setLoadingFinishedUrl(url);
    }
    const data = await response.data;
    const dto = plainToInstance<T, AxiosResponse<T>>(Model, data);

    return dto as unknown as T;
  }

  public async deleteRaw(
    url: string,
    queryParams: QueryParams | undefined,
    payload: any
  ): Promise<AxiosResponse['data']> {
    let response;
    RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.delete(url, {
        params: queryParams?.getParamsFromRequest(),
        data: payload,
      });
    } catch (error) {
      throw error;
    } finally {
      RootStoreInstence.setLoadingFinishedUrl(url);
    }
    return response;
  }

  public async patch<T>(
    url: string,
    queryParams: QueryParams | undefined,
    payload: any,
    Model: any,
    cancelable = false
  ): Promise<T> {
    let response;
    RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.patch(url, payload, {
        params: queryParams?.getParamsFromRequest(),
        data: { cancelable },
      });
    } catch (error) {
      throw error;
    } finally {
      RootStoreInstence.setLoadingFinishedUrl(url);
    }
    const data = await response.data;
    const dto = plainToInstance<T, AxiosResponse<T>>(Model, data);

    return dto as unknown as T;
  }

  public async patchRaw(
    url: string,
    queryParams: QueryParams | undefined,
    payload: any,
    cancelable = false
  ): Promise<AxiosResponse['data']> {
    let response;
    RootStoreInstence.setLoadingUrl(url);
    try {
      response = await axiosInstance.patch(url, payload, {
        params: queryParams?.getParamsFromRequest(),
        data: { cancelable },
      });
    } catch (error) {
      throw error;
    } finally {
      RootStoreInstence.setLoadingFinishedUrl(url);
    }
    return response;
  }
}

export default axiosInstance;
export const httpClient = HttpClient.getInstance();
