import { Box } from '@mui/material';
import { RoutesLoadContextProvider } from '../../../contexts/LoadRoutesContext';
import { CreateDocument, GetRemoveLoadManifestDataResp } from '../../../models';
import { useStores } from '../../../store/root.context';
import {
  FactoringActionReturnType,
  InvoiceModel,
} from '../../../subPages/invoices/models/InvoiceModel';
import { LoadSummary } from '../../../subPages/loadsList/models/LoadSummary';
import { ESecondaryDetailsPanelType } from '../../../views/dispatch2/constants/types';
import Documents from '../../../views/reports/documents/sections/EntityDocumentsSection';
import { ELoadStatus, EMyLoadDetailsPanelTabs } from '../constants/constants';
import { IOpenSecondaryDetailsHandler } from '../constants/interface';
import { Activity } from '../tabs/Activity';
import { AdditionalDetails } from '../tabs/AdditionalDetails';
import FinanceOverviewFormWithProvider from '../tabs/FinanceOverview/FinanceOverview';
import FinanceOverviewInvoice from '../tabs/FinanceOverview/FinanceOverviewInvoice/FinanceOverviewInvoice';
import { Notes } from '../tabs/Notes';
import { Overview } from '../tabs/Overview';
import LoadRoutes from '../tabs/Routes';
import { Tracking } from '../tabs/Tracking';
import { OnHoldLoadWarning } from './OnHoldLoadWarning';

export interface MyLoadDetailsFormProps extends IOpenSecondaryDetailsHandler {
  selectedTab: EMyLoadDetailsPanelTabs;
  loadId: LoadSummary;
  setLoadData?: (data: LoadSummary) => void;
  customerId?: number;
  onClose?: () => void;
  onUpdateActivitiesCompleted?: () => void;
  handleFactoringResponse: (
    response: FactoringActionReturnType,
    selectedItem: InvoiceModel | null
  ) => void;
  triggerAlert: (actionName: string, message: string) => void;
  handleUpdatedInvoice: (data: InvoiceModel) => void;
  isStatusUpdated?: () => void | Promise<void>;
  onAction?: (status?: ELoadStatus) => void;
  onUpdated?: () => void;
  onUpdateMainData?: () => Promise<void>;
  manifestDeleteForLoad?: (
    id: GetRemoveLoadManifestDataResp,
    status: boolean
  ) => void;
  readonly?: boolean;
}
export default function MyLoadDetailsForm({
  selectedTab,
  loadId,
  customerId,
  onUpdateActivitiesCompleted,
  openSecondaryDetailsHandler,
  handleFactoringResponse,
  triggerAlert,
  handleUpdatedInvoice,
  isStatusUpdated,
  onAction,
  setLoadData,
  onUpdated,
  onUpdateMainData,
  manifestDeleteForLoad,
  readonly = false,
}: MyLoadDetailsFormProps) {
  const {
    myLoadStore: { setUpdatedLoadId },
  } = useStores();
  function updateMissingDocuments(files: CreateDocument[]) {
    const warningDocumentTypes = [
      'BILL_OF_LADING',
      'RATE_CONFIRMATION',
      'PROOF_OF_DELIVERY',
    ];
    if (warningDocumentTypes.includes(files[0].documentType)) {
      setUpdatedLoadId && setUpdatedLoadId(loadId.id);
    }
  }

  let associatedLoadId: string = loadId.id;
  if (loadId?.manifestBaseDetails?.manifestId) {
    associatedLoadId = loadId.manifestBaseDetails.manifestId;
  }

  return (
    <Box
      sx={(theme) => ({
        pointerEvents: readonly ? 'none' : '',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        overflowY: 'auto',
        background: theme.palette.primary?.contrast,
        pb: '1rem',
        ...(selectedTab === EMyLoadDetailsPanelTabs.FINANCE && {
          position: 'static',
        }),
        pl: 2,
        pr: 2,
      })}
    >
      <Box
        sx={{
          height: '100%',
        }}
      >
        {selectedTab !== EMyLoadDetailsPanelTabs.NOTES && loadId.onHold && (
          <Box
            sx={{
              ml: -2,
              mr: -2,
              mt: 0,
              mb: 0,
            }}
          >
            <OnHoldLoadWarning loadId={associatedLoadId} />
          </Box>
        )}
        {selectedTab === EMyLoadDetailsPanelTabs.OVERVIEW && (
          <Overview
            load={loadId}
            onUpdated={onUpdated}
            manifestDeleteForLoad={manifestDeleteForLoad}
          />
        )}
        {selectedTab === EMyLoadDetailsPanelTabs.ROUTES && (
          <RoutesLoadContextProvider>
            <LoadRoutes
              id={loadId.id}
              loadData={loadId}
              onUpdated={onUpdated}
              onUpdateActivitiesCompleted={onUpdateActivitiesCompleted}
              openSecondaryDetailsHandler={openSecondaryDetailsHandler}
              loadType={loadId?.loadType || ''}
              onUpdateMainData={onUpdateMainData}
            />
          </RoutesLoadContextProvider>
        )}
        {selectedTab === EMyLoadDetailsPanelTabs.FINANCE && (
          <FinanceOverviewFormWithProvider
            loadId={loadId.id}
            ModuleType={'FinanceOverView'}
            setLoadData={setLoadData}
            panelType={ESecondaryDetailsPanelType.LOAD}
            loadType={loadId?.loadType || ''}
          />
        )}

        {selectedTab === EMyLoadDetailsPanelTabs.TRACKING && (
          <Tracking
            loadId={loadId.id}
            customerId={customerId}
            isEdi={loadId?.ediDetails?.tenderId ? true : false}
          />
        )}
        {selectedTab === EMyLoadDetailsPanelTabs.NOTES && (
          <Notes loadId={loadId.id} onHold={loadId.onHold} />
        )}
        {selectedTab === EMyLoadDetailsPanelTabs.DOCUMENTS && (
          <Box
            sx={{
              '> div': {
                p: 0,
                mt: 1,
              },
            }}
          >
            <Documents
              data={{
                id: loadId.id,
                entity: 'LOAD',
                entityObject: {
                  id: loadId.id,
                  name: `${loadId?.seqNumber}`,
                },
                terminal: loadId.terminal,
              }}
              createDocumentCallback={updateMissingDocuments}
            />
          </Box>
        )}
        {selectedTab === EMyLoadDetailsPanelTabs.ADDITIONAL_DETAILS && (
          <AdditionalDetails load={loadId} onUpdated={onUpdated} />
        )}
        {selectedTab === EMyLoadDetailsPanelTabs.INVOICE && (
          <Box
            sx={{
              '> div': {
                height: 'unset',
              },
            }}
          >
            <FinanceOverviewInvoice
              id={loadId.id}
              handleFactoringResponse={handleFactoringResponse}
              triggerAlert={triggerAlert}
              handleUpdatedInvoice={handleUpdatedInvoice}
              isStatusUpdated={isStatusUpdated}
              onAction={onAction}
              setLoadData={setLoadData}
              ediDetails={loadId?.ediDetails}
              panelType={ESecondaryDetailsPanelType.LOAD}
              onUpdated={onUpdated}
              onHold={loadId.onHold}
              status={loadId.loadStatus}
            />
          </Box>
        )}

        {selectedTab === EMyLoadDetailsPanelTabs.ACTIVITY && (
          <Activity load={loadId} />
        )}
      </Box>
    </Box>
  );
}
