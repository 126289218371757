import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Map from '../../../../common/Ui/Maps/Maps';
import { MarkersData } from '../../../../common/Ui/Maps/types';
import {
  AuthAppLogo,
  DynamicOptymLogo,
} from '../../../../ui-kit/components/Assets';
import IconsComponent from '../../../../ui-kit/components/IconsComponent';
import MultilineCellAxele from '../../../../ui-kit/components/MultilineCell';
import { TripV3ListWithAccordion } from '../../../../ui-kit/components/TripPlan';
import { RoutesInfo } from '../../../LoadTabPanel/tabs/Routes/Models';
import { convertToTripSpecificFormat } from '../../../LoadTabPanel/tabs/Routes/uiUtils';
import { getTinyUrl } from '../../../LoadTabPanel/tabs/Tracking/constants/helper';
import axeleRoutes from '../../../auxComponents/axeleRoutes';
import { useLoadPermission } from '../../../hooks/useLoadPermission';
import { styles } from './styles';

const COMPLETED_IN_TRANSIT_LOAD_STATUSES = new Set([
  'IN_TRANSIT',
  'LOAD_COMPLETED',
  'CANCELLED',
  'INVOICED',
  'READY_TO_INVOICE',
  'PAID',
]);

const COMPLETED_LOAD_STATUSES = new Set([
  'LOAD_COMPLETED',
  'CANCELLED',
  'READY_TO_INVOICE',
  'INVOICED',
  'PAID',
]);

const TrackingMap = () => {
  const [routesInfo, setRoutesInfo] = useState<RoutesInfo | null>();
  const [overviewData, setOverviewData] = useState({});
  const [loadDetailsData, setLoadDetailsData] = useState('-');
  const [loadData, setLoadData] = useState({});
  const [mapData, setMapData] = useState<MarkersData>();
  const [tripDataList, setTripDataList] = useState({});
  const { hasLoadEditPermission } = useLoadPermission();

  useEffect(() => {
    getData();
  }, []);

  const determineLoadStatusFromConnectedTrips = (
    connectedTrips: any,
    stops: any
  ) => {
    if (connectedTrips[0].tripStatus === 'DISPATCHED') {
      return 'DISPATCHED';
    }
    if (
      stops?.[0].status === 'NONE' &&
      ['IN_TRANSIT' && 'DISPATCHED'].includes(connectedTrips[0].tripStatus)
    ) {
      return 'DISPATCHED';
    }
    if (
      COMPLETED_IN_TRANSIT_LOAD_STATUSES.has(connectedTrips[0].tripStatus) &&
      connectedTrips[connectedTrips.length - 1].tripStatus !== 'LOAD_COMPLETED'
    ) {
      return 'IN_TRANSIT';
    }
    if (
      COMPLETED_LOAD_STATUSES.has(
        connectedTrips[connectedTrips.length - 1].tripStatus
      ) ||
      stops?.[stops.length - 1].status === 'COMPLETED'
    ) {
      return 'COMPLETED';
    }
    return connectedTrips[0].tripStatus;
  };

  const getData = async () => {
    const key = window.location.search.replace('?key=', '');
    const locationURL = window.location.origin;

    const overviewURL = `${locationURL}/backendpublic/load/api/v2/details/load/overview/public?key=${key}`;
    fetch(overviewURL)
      .then((res) => res.json())
      .then((json) => setOverviewData(json));

    const tripDataURL = `${locationURL}/backendpublic/load/api/v2/details/trip-routes/public?key=${key}`;
    fetch(tripDataURL)
      .then((res) => res.json())
      .then((getTrip) => {
        let tempTripData: any = [];
        if (getTrip && getTrip.trips.length > 1)
          getTrip.trips.map((item: any) => {
            tempTripData = [...tempTripData, ...item.stops];
          });
        else
          tempTripData = getTrip && getTrip.trips ? getTrip.trips[0].stops : [];

        const tempTripStops = getTrip.trips[0];
        tempTripStops.stops = tempTripData.filter(
          (item: any) => item.type != 'RELAY'
        );

        const tripStatus = tempTripStops.tripStatus; // here
        getTrip.trips = [tempTripStops];
        setRoutesInfo(getTrip);

        const tempList = convertToTripSpecificFormat(
          getTrip.trips,
          {
            openSecondaryDetailsHandler,
            hasLoadEditPermission,
          },
          'TRACKING_MAP',
          getTrip.seqNumber
        );
        const status = determineLoadStatusFromConnectedTrips(
          getTrip.trips,
          tempTripStops.stops
        );
        tempList[0].isCompleted = status == 'LOAD_COMPLETED' ? true : false;
        setTripDataList(tempList);
      });

    const loadDetailsURL = `${locationURL}/backendpublic/load/api/v2/details/load/details/request/public?key=${key}`;
    fetch(loadDetailsURL)
      .then((res) => res.json())
      .then((json) => {
        setLoadData(json);
        setLoadDetailsData(
          json &&
            json.tpSequentialOutputDTO &&
            json.tpSequentialOutputDTO.invocationTime
            ? moment(json.tpSequentialOutputDTO.invocationTime).fromNow()
            : '-'
        );
      });

    const mapUrl = `${locationURL}/backendpublic/load/api/v2/details/tnt/map?key=${key}`;
    fetch(mapUrl)
      .then((res) => res.json())
      .then((json) => setMapData(json));
  };

  const makeShort = (str: string, no: number) =>
    str.length >= no ? str.slice(0, no) + '...' : str;

  const noOfStops = (input: any) => {
    let count = 0;
    input.map((item: any) => (count = count + item.stops.length));
    return count;
  };

  const openSecondaryDetailsHandler = (id: string, type: any): void => {
    // This will be needed in Future
  };

  const statusDelivered = 'Your shipment is delivered!';
  const statusCancelled = 'This shipment is canceled!';
  const statusProcess = 'Your shipment is on the way!';

  const shipmentText = {
    AVAILABLE: statusProcess,
    ASSIGNMENT_PLANNED: statusProcess,
    OFFERED_TO_DRIVER: statusProcess,
    DISPATCHED: statusProcess,
    IN_TRANSIT: statusProcess,
    LOAD_COMPLETED: statusDelivered,
    CANCELLED: statusCancelled,
    INVOICED: statusDelivered,
    PAID: statusDelivered,
    DELETED: statusCancelled,
  };

  const onShareClicked = async () => {
    const key = window.location.search.replace('?key=', '');
    const locationURL = window.location.origin;
    const tempUrl = `${locationURL}/tnt?key=${key}`;
    const tinuURLAPI = `${locationURL}/backendpublic/mediator/api/v2/notifications/sms/tinyurl?urlLongURL=${tempUrl}`;
    const res: string = await getTinyUrl(tinuURLAPI);
    navigator.clipboard.writeText(res);
  };

  return (
    <Stack direction="row" width={'100%'} height={'100%'}>
      <Box sx={{ width: 500, borderRightWidth: 1, borderRightColor: 'gray' }}>
        <Stack
          direction="column"
          justifyContent={'space-between'}
          width={'100%'}
          height={'100%'}
        >
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={'space-around'}
              width={'100%'}
              spacing={1}
              mt={1}
              padding={2}
            >
              <Typography
                color={'primary.main'}
                align="left"
                fontSize={26}
                fontWeight={600}
              >
                {loadData && loadData.loadStatus
                  ? shipmentText[loadData.loadStatus]
                  : statusProcess}
              </Typography>
              <Tooltip title="Copy tracking link">
                <Box
                  sx={{
                    borderRadius: 2,
                    borderWidth: 1,
                    width: 'auto',
                    borderColor: 'primary.main',
                  }}
                >
                  <IconButton color="primary" onClick={() => onShareClicked()}>
                    <IconsComponent iconName="ShareRounded" source="MUIcons" />
                  </IconButton>
                </Box>
              </Tooltip>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent={'center'}
              width={'100%'}
              spacing={1}
              mt={1}
              ml={2}
              mr={2}
            >
              <MultilineCellAxele
                title={
                  overviewData.referenceId ? overviewData.referenceId : '-'
                }
                subtitle={'Reference #'}
                titleStyle={styles.visiblityText}
                subtitleStyle={styles.visibilitySub}
                alignTitle="left"
                alignSubtitle="left"
              />
              <MultilineCellAxele
                title={loadDetailsData ? loadDetailsData : '-'}
                subtitle={'Last Update'}
                titleStyle={styles.visiblityText}
                subtitleStyle={styles.visibilitySub}
                alignTitle="left"
                alignSubtitle="left"
              />
              <MultilineCellAxele
                title={
                  routesInfo && routesInfo.trips
                    ? noOfStops(routesInfo.trips)
                    : '-'
                }
                subtitle={'# of Stops'}
                titleStyle={styles.visiblityText}
                subtitleStyle={styles.visibilitySub}
                alignTitle="left"
                alignSubtitle="left"
              />
            </Stack>

            <Stack
              direction="row"
              width={'100%'}
              spacing={0}
              mt={3}
              alignItems="center"
              justifyContent={'center'}
              sx={{ paddingRight: 2, paddingLeft: 2 }}
            >
              <MultilineCellAxele
                title={makeShort(
                  `${overviewData.pickupCity ? overviewData.pickupCity : ''},${
                    overviewData.pickupState ? overviewData.pickupState : ''
                  }`,
                  16
                )}
                subtitle={'From'}
                titleStyle={styles.visiblityText}
                subtitleStyle={styles.visibilitySub}
                alignTitle="left"
                alignSubtitle="left"
              />
              <IconButton sx={{ color: 'text.secondary', marginRight: 6 }}>
                <IconsComponent iconName="ArrowForward" source="MUIcons" />
              </IconButton>
              <MultilineCellAxele
                title={makeShort(
                  `${
                    overviewData.dropOffCity ? overviewData.dropOffCity : ''
                  },${
                    overviewData.dropOffState ? overviewData.dropOffState : ''
                  }`,
                  16
                )}
                subtitle={'To'}
                titleStyle={styles.visiblityText}
                subtitleStyle={styles.visibilitySub}
                alignTitle="left"
                alignSubtitle="left"
              />
            </Stack>

            <Box mt={2}>
              <Divider />
            </Box>

            {routesInfo && routesInfo.trips && (
              <TripV3ListWithAccordion
                tripDataList={tripDataList}
                isMultipleExpanded
                panelView="LoadDetails"
                readOnly
              />
            )}
          </Box>
          <Box ml={2} mb={2}>
            <AuthAppLogo />
          </Box>
        </Stack>
      </Box>
      <Box id="TnTMapBox" sx={{ height: '100vh', width: '100%' }}>
        <Map generateRoute markersList={mapData ? mapData.markers : []} />
      </Box>
    </Stack>
  );
};

axeleRoutes.addRoute({
  path: '/tnt',
  pathId: 'tnttnt',
  element: <TrackingMap />,
});
