import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { memo } from 'react';
import { MyLoadHoS } from '../../types';

export const SourceAndTime = memo(
  ({ myloadHoS }: { myloadHoS: MyLoadHoS | null }): JSX.Element => {
    const hosSource = myloadHoS?.location?.locationSource || '--';
    const lastUpdateTime = myloadHoS?.location?.time;

    const formatTime = (): string => {
      return lastUpdateTime
        ? moment
            .tz(lastUpdateTime, 'America/New_York')
            .format('MM/DD/YY, hh:mm z')
        : '--';
    };
    return (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Typography variant="body4">{`Source: ${hosSource}`}</Typography>
        <Typography variant="body4">{`Updated: ${formatTime()}`}</Typography>
      </Stack>
    );
  }
);
SourceAndTime.displayName = 'SourceAndTime';
