import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoadSmart } from '../../../../../ui-kit/components/Assets';
import MultilineCellAxele from '../../../../../ui-kit/components/MultilineCell';
import { visibilityText } from '../constants/constant';
import { styles } from '../styles/styles';

interface VisibilityProviderTypes {
  visibilityProviderIds?: string;
}

export const VisibilityProvider = ({
  visibilityProviderIds = '',
}: VisibilityProviderTypes) => {
  const rednerNameProvider = () => (
    <Box display="flex" flexDirection={'row'} justifyContent={'flex-start'}>
      <LoadSmart width={160} height={80} />
      <Stack direction="column" ml={9} mt={2}>
        <Typography align="left" sx={styles.visiblityText}>
          {'Provider Load ID'}
        </Typography>
        <Typography align="left" sx={styles.visibilitySub}>
          {visibilityProviderIds ? visibilityProviderIds : ''}
        </Typography>
      </Stack>
    </Box>
  );

  const rednerEventLocation = () => (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <MultilineCellAxele
        title={'Event Tracking'}
        subtitle={'Yes'}
        titleStyle={styles.visiblityText}
        subtitleStyle={styles.visibilitySub}
        alignTitle="left"
        alignSubtitle="left"
      />
      <MultilineCellAxele
        title={'Location Tracking'}
        subtitle={'Yes'}
        titleStyle={styles.visiblityText}
        subtitleStyle={styles.visibilitySub}
        alignTitle="left"
        alignSubtitle="left"
      />
    </Stack>
  );

  return (
    <Box mt={4}>
      <Typography
        color={'primary.main'}
        align="left"
        fontSize={16}
        fontWeight={600}
      >
        {visibilityText}
      </Typography>
      {rednerNameProvider()}
      {rednerEventLocation()}
    </Box>
  );
};
