import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import TabStripLarge from '../../../../../common/TabStripLarge';
import {
  importableEntities,
  ImportableEntityType,
  useImport,
} from '../../../../../contexts/ImportContext';
import TabsV2 from '../../../../../ui-kit/components/TabsV2';
import { Box } from '@mui/system';

export interface ILocation {
  state: {
    subPage: string;
    selectedValue?: string;
  };
}

export default function Entities() {
  const { setCurrentEntity, currentEntity } = useImport();
  const location = useLocation() as ILocation;
  const importedEntity = importableEntities.find(
    (elem) => location?.state?.subPage === elem.name
  );

  useEffect(() => {
    if (importedEntity) {
      setCurrentEntity(importedEntity);
      history.replaceState({}, document.title);
    }
  }, []);

  return (
    <>
      <Box ml={4} pt={2} width="100%">
        <TabsV2
          keyName="key"
          tabs={importableEntities.filter(
            (entity) => !entity.hideFromImportPage
          )}
          setSelectedTab={(entity: ImportableEntityType) =>
            setCurrentEntity(entity)
          }
          selectedTab={currentEntity.key}
        />
      </Box>
      {/*{importableEntities.map((entity) => (*/}
      {/*  <TabStripLarge*/}
      {/*    key={entity.key}*/}
      {/*    title={entity.name}*/}
      {/*    icon={entity.icon}*/}
      {/*    onClick={() => setCurrentEntity(entity)}*/}
      {/*    selected={entity.name === currentEntity.name}*/}
      {/*  />*/}
      {/*))}*/}
    </>
  );
}
