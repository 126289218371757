import { Type } from 'class-transformer';
// import { SortResponse } from '../commonMixed';

import { PaginatedList, PhoneType } from '../../../types/index';

export class LocationsListDTO {
  id!: number;
  locationName!: string;
  fullAddress!: string;
  avgHandlingTime!: number;
  @Type(() => ContactSummaryDTO)
  primaryContact!: ContactSummaryDTO | null;
  notes!: string;
  locationPreference!: PreferenceType;
  timezone!: string;
}

export class LocationAddressListDTO {
  id!: number;
  locationName!: string;
  @Type(() => AddressDTO)
  addressDTO!: AddressDTO | null;
  avgHandlingTime!: number;
  @Type(() => ContactSummaryDTO)
  contacts!: ContactSummaryDTO | null;
  notes!: string;
  locationPreference!: PreferenceType;
  timezone!: string;
}
export class ContactSummaryDTO {
  id!: number;
  fullName!: string;
  phoneData!: PhoneType;
  email!: string;
  isPrimary!: boolean;
  description!: string;
}

export enum PreferenceType {
  PREFERRED,
  PROHIBITED,
  NONE,
}

export class CenterDTO {
  lat!: number | null;
  lng!: number | null;
}
interface ICoordinates {
  coordinates: Array<Array<number>>;
}
export class AddressDTO {
  fullAddress!: string;
  address!: string;
  city!: string;
  state!: string;
  country?: string;
  streetAddress!: string;
  streetAddress2!: string;
  zipcode!: string;
  @Type(() => CenterDTO)
  center!: CenterDTO;
  timezone?: string;
  geoCoordinates?: ICoordinates;
}

export class LocationSummaryDTO {
  id!: number | null;
  locationName!: string;
  country!: string;
  notes!: string;
  avgHandlingTime!: number;
  @Type(() => ContactSummaryDTO)
  contacts!: ContactSummaryDTO[];
  locationPreference!: PreferenceType;
  @Type(() => AddressDTO)
  addressDTO!: AddressDTO;
  timezone!: string;
  locationDisplayName?: string;
  Coords?: { Lat?: number; Lon?: number };
}

export class PaginatedLocationsList extends PaginatedList {
  @Type(() => LocationsListDTO)
  content!: LocationsListDTO[];
}

export class PaginatedLocationAddressList extends PaginatedList {
  @Type(() => LocationSummaryDTO)
  content!: LocationSummaryDTO[];
}
export type locationListRequestType = {
  sort: string;
  pageNumber: number;
  pageSize: number;
};
export type locationFiltersRequestType = {
  locationNamesFilter: string[] | null;
  locationContactsFilter: string[] | null;
  locationAddressFilter: string[] | null;
  locationPreferenceFilter: string[] | null;
  timezoneFilter: string[] | null;
  gridColumnMetadataList?: string[];
};

export type locationListExcelRequestType = {
  sort: string;
  pageNumber: number;
  pageSize: number;
  gridColumnMetadataList: string[];
};

export class LocationsFilterDTO {
  fullAddress!: string;
  locationId!: number;
  locationName!: string;
}

export class PaginatedLocationsFilterList extends PaginatedList {
  @Type(() => AddressFilterDTO)
  content!: AddressFilterDTO[];
}
export class AddressFilterDTO {
  fullAddress!: string;
  locationId!: number;
  locationName!: string;
  addressToShow?: string;
}

export class PaginatedAddressFilterList extends PaginatedList {
  @Type(() => AddressFilterDTO)
  content!: AddressFilterDTO[];
}

export class ContactFilterDTO {
  fullName!: string;
  locationId!: number;
}

export class PaginatedContactsFilterList extends PaginatedList {
  @Type(() => ContactFilterDTO)
  content!: ContactFilterDTO[];
}

export class ValidateLocationsUniqueNamesResponse {
  locations!: {};
}
export class LocationDetails {
  latitude!: string;
  longitude!: string;
  street!: string;
  city!: string;
  state!: string;
  country!: string;
  zip!: string;
  timezone!: string;
}
export class GetLocationListDetailsByCoordsResponse {
  data!: LocationDetails[];
}
export class GetTimezoneByCoordsResponse {
  zoneName?: string;
  abbreviation: any;
  nextAbbreviation: any;
}
