import LoadTabPanel from '../../common/LoadTabPanel/component/LoadTabPanel';
import { LoadDetailsResponse } from '../../models';
import CarrierDetailsPanel from '../../subPages/carriers/components/CarrierDetailsPanel/CarrierDetailsPanel';
import CustomerDetailsPanel from '../../subPages/customers/components/CustomerDetailsPanel';
import ExpenseDetailsPanel from '../../subPages/expenses/components/ExpenseDetailsPanel';
import InvoiceCustomDetailPanel from '../../subPages/invoices/InvoiceCustomDetailPanel';
import PaymentsDetailsPanel from '../../subPages/payStatement/components/PayStatementDetailsPanel';
import TractorDetailsPanel from '../../subPages/tractors/components/TractorDetailsPanel';
import TrailerDetailsPanel from '../../subPages/trailers/components/TrailerDetailsPanel';
import UserDetailsPanel from '../../subPages/users/components/UserDetailsPanel';
import VendorDetailsPanel from '../../subPages/vendors/components/VendorDetailsPanel';
import { ComponentType } from '../../types';
import { StopSolutionV3Prop } from '../../ui-kit/components/TripPlan';
import { EDITenderDetailsPanel } from '../../views/EDITenders/components/EDITenderDetailsPanel';
import DispatchDetailsPanelWithProvider from '../../views/dispatch/components/DispatchDetailsPanel/DispatchDetailsPanelWithProvider';
import { ESecondaryDetailsPanelType } from '../../views/dispatch2/constants/types';
import TripDetailsPanelWrapper from '../../views/trips/components/TripDetailsPanel/TripDetailsPanelWrapper';
import PaymentDetailsPanel from '../../views/finance/paymentManagement/components/PaymentDetailsPanel/PaymentDetailsPanel';

const panels: ComponentType = {
  [ESecondaryDetailsPanelType.TRACTOR]: TractorDetailsPanel,
  [ESecondaryDetailsPanelType.TRAILER]: TrailerDetailsPanel,
  [ESecondaryDetailsPanelType.CUSTOMER]: CustomerDetailsPanel,
  [ESecondaryDetailsPanelType.USER]: UserDetailsPanel,
  [ESecondaryDetailsPanelType.LOAD]: LoadTabPanel,
  [ESecondaryDetailsPanelType.TRIP]: TripDetailsPanelWrapper,
  [ESecondaryDetailsPanelType.DISPATCH]: DispatchDetailsPanelWithProvider,
  [ESecondaryDetailsPanelType.PAY_STATMENT]: PaymentsDetailsPanel,
  [ESecondaryDetailsPanelType.EXPENSE]: ExpenseDetailsPanel,
  [ESecondaryDetailsPanelType.VENDOR]: VendorDetailsPanel,
  [ESecondaryDetailsPanelType.EDI_TENDER]: EDITenderDetailsPanel,
  [ESecondaryDetailsPanelType.CARRIER]: CarrierDetailsPanel,
  [ESecondaryDetailsPanelType.INVOICE]: InvoiceCustomDetailPanel,
  [ESecondaryDetailsPanelType.PAYMENT]: PaymentDetailsPanel,
};

export interface SecondaryDetailsPanelProps {
  type: ESecondaryDetailsPanelType;
  id: string | number;
  data?: any;
  onCreated?: () => void;
  onUpdated?: (data?: any) => void;
  onDeleted?: () => void;
  onClose?: () => void;
  defaultTab?: string;
  isGlobal?: boolean;
  onCreatedLoadHandler?: (load: LoadDetailsResponse) => Promise<void>;
  onClickLoadIdHandler?: (stopSolution: StopSolutionV3Prop) => Promise<void>;
  onUpdateMainData?: (updatedIds?: string[], deletedIds?: string[]) => void;
  readonly?: boolean;
}

export function SecondaryDetailsPanel({
  type,
  id,
  data,
  readonly = false,
  onCreated,
  onUpdated,
  onDeleted,
  onClose,
  defaultTab,
  onUpdateMainData,
  ...restProps
}: SecondaryDetailsPanelProps) {
  const DetailsPanel = panels[type] as React.FC<any>;
  return (
    <DetailsPanel
      key={id}
      data={{ id, ...data }}
      onCreated={onCreated}
      onUpdated={onUpdated}
      onDeleted={onDeleted}
      onClose={onClose}
      defaultTab={defaultTab}
      readonly={readonly}
      onUpdateMainData={onUpdateMainData}
      {...restProps}
    />
  );
}

export default SecondaryDetailsPanel;
