import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { OptionType } from '../../../../../models';
import EquipmentForm from '../../../../LoadSharedComponents/forms/EquipmentForm';
import { LoadSummary } from '../../../../../subPages/loadsList/models/LoadSummary';

export const Equipment = ({
  isViewMode = false,
  equipmentTypeOptions,
  load,
}: {
  isViewMode?: boolean;
  equipmentTypeOptions: OptionType[];
  load: LoadSummary;
}) => {
  return (
    <Box>
      <Box sx={{ mb: 1 }}>
        <Typography
          variant="h7"
          sx={{
            color: 'primary.main',
          }}
        >
          Equipment
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <EquipmentForm
          isViewMode={isViewMode}
          equipmentTypeOptions={equipmentTypeOptions}
          load={load}
        />
      </Grid>
    </Box>
  );
};
