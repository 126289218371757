import { Moment } from 'moment';
import { TractorAlertSummaryDTO } from '../../../models';
import { Model } from '../../../models/common/Model';
import { DriverShort, TerminalShort } from '../../../models/common/modelsShort';
import { addressType } from '../../../types';
import { fromIsoToMoment, getNullableModel } from '../../../utils';

export class TractorSummary extends Model {
  tractorName: string;
  activeLoad!: { loadId: string; seqNumber: string } | null;
  status: string;
  groupDetails: object | undefined;
  driver: DriverShort | null;
  terminal: TerminalShort | null;
  vin: string;
  licensePlateNumber: string;
  licenseValidTill: Moment | null;
  locationShort: string | null;
  lastLocation: string;
  lastUpdated: Moment | null;
  locationSource: string;
  ownershipType: string;
  ownedCompany: string;
  warning: number;
  critical: number;
  gridColumnMetadataList?: string[];
  alertsList?: TractorAlertSummaryDTO[] | undefined;
  fuelType: { value: string } | null; //@TODO Slavik - will be changed when the autoComplete component is healthy
  constructor(dto: any) {
    super(dto);
    this.tractorName = dto.tractorName;
    this.status = dto.status;
    this.groupDetails = dto.groupDetails;
    this.driver = getNullableModel(DriverShort, dto.groupDetails);
    this.terminal = getNullableModel(TerminalShort, dto.terminal);
    this.vin = dto.vin;
    this.fuelType = null;
    this.licensePlateNumber = dto.licensePlateNumber;
    this.licenseValidTill = fromIsoToMoment(dto.licenseValidTill);
    this.lastLocation = dto.assetLocationDTO?.addressDTO?.fullAddress;
    this.locationShort = this.getShortLocation(
      dto.assetLocationDTO?.addressDTO
    );
    this.lastUpdated = fromIsoToMoment(dto.assetLocationDTO?.time);
    this.locationSource = dto.assetLocationDTO?.locationSource;
    this.ownershipType = dto.ownershipType;
    this.ownedCompany = dto.ownedCompany;
    this.warning = dto.warningSafetyIssues;
    this.activeLoad = dto.activeLoad;
    this.critical = dto.criticalSafetyIssues;
    this.gridColumnMetadataList = dto.gridColumnMetadataList;
  }

  getShortLocation(address: addressType) {
    if (address && address.city && address.state) {
      return `${address.city}, ${address.state}`;
    }
    return null;
  }
}
