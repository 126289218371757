import { FuelMatrixItem, QueryParams } from '@/models';

export class GetFuelMatrixRequest extends QueryParams {
  statusFilter?: Array<string> | undefined;
}
export class CreateUpdateFuelMatrixRequest extends QueryParams {
  id?: string;
  matrixName!: string;
  status!: string;
  description!: string;
  fuelSurchargeItems!: Array<FuelMatrixItem>;
}

export interface FuelMatrixValidationError {
  validationType: string;
  errors: number[];
  hasErrors: boolean;
}
