import React from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from '../../../../common/Ui/TextField/TextField';
import Switch from '../../../../common/Ui/Switcher';
import Grid from '@mui/material/Grid';
import FormPhoneField from '../../../../common/Ui/FormPhoneField/index';
import { contactFormModule } from '../../../../common/PendoClassIDs/constants';

const AddNewContactForm = ({
  numberOfContacts,
}: {
  numberOfContacts: number;
}) => {
  const { control } = useFormContext();

  return (
    <div
      style={{
        width: '100%',
        color: '#999999',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 600,
        letterSpacing: '0.15px',
        fontFamily: 'Poppins',
      }}
    >
      <Grid spacing={1} container>
        <TextField
          id={contactFormModule + 'Name'}
          control={control}
          name="fullName"
          label="Name"
          sizes={{
            xs: 12,
          }}
          required
        />
        <FormPhoneField
          control={control}
          name="phoneData"
          label="Phone #"
          sizes={{ xs: 12 }}
        />
        <TextField
          id={contactFormModule + 'Email'}
          control={control}
          name="email"
          label="Email"
          sizes={{ xs: 12 }}
        />
        <TextField
          id={contactFormModule + 'Description'}
          control={control}
          name="description"
          label="Description"
          sizes={{ xs: 12 }}
        />
        <span style={{ marginLeft: 10 }}>
          <Switch
            id={contactFormModule + 'Primary'}
            control={control}
            name="isPrimary"
            label="Primary"
            sizes={{ xs: 12 }}
            disabled={numberOfContacts === 0}
          />
        </span>
      </Grid>
    </div>
  );
};

AddNewContactForm.defaultProps = {
  titleText: '',
};

export default AddNewContactForm;
