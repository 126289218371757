import { t } from 'i18next';
import { Label } from '../../locales/en/dispatch/label';

export enum TRIP_STATUSES {
  NEED_LOAD = 'NEED_LOAD',
  DISPATCHED = 'DISPATCHED',
  COMPLETED = 'COMPLETED',
  ASSIGNED = 'ASSIGNED',
  'IN-PROGRESS' = 'IN-PROGRESS',
  PLANNED = 'PLANNED',
  DEADHEAD = 'DEADHEAD',
  EMPTY = 'EMPTY',
  'TRIP' = 'TRIP',
  RECOMMENDED = 'RECOMMENDED',
}
export const ganttPageSize = 50;
export const OPTYMIZATION_GANTT_PAGE_SIZE = 5000;

export const GANTT_WINDOW_SIZE = {
  0: 7,
  1: 5,
  2: 3,
} as any;

export const ZOOM_LEVELS = {
  MIN: 0,
  MAX: 2,
};

export const VIEW_PRESET_BY_WINDOW = {
  0: 'hourAndDay3',
  1: 'hourAndDay2',
  2: 'hourAndDay',
} as any;

export const ganttDefaultControlsConfig = {
  allowDefaultNavigationControl: false,
  hideNavigationControl: true,
  showControlActions: true,
  allowDefaultZoomControl: false,
};

export const GANTT_LOADER_CONFIG = {
  text: '',
  mode: 'dark',
  autoClose: 30000,
  icon: 'loader',
} as any;

export const EMPTY_TRIP_ACTION_KEYS = {
  ASSIGN: 'ASSIGN',
  SEARCH: 'SEARCH',
  OPTIMIZE: 'OPTIMIZE',
};

export enum ESecondaryDetailsPanelType {
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  LOAD = 'LOAD',
  TRIP = 'TRIP',
  USER = 'USER',
  CUSTOMER = 'CUSTOMER',
  DISPATCH = 'DISPATCH',
  PAY_STATMENT = 'PAY_STATMENT',
  EXPENSE = 'EXPENSE',
  VENDOR = 'VENDOR',
}

export enum DispatchFiltersName {
  sort = 'sortFilter',
  sortDirection = 'sortDirectionFilter',
  driver = 'driverFilter',
  startDate = 'dateFilter',
  needLoad = 'needLoad',
  timeOff = 'timeOff',
  operationMode = 'operationMode',
  primaryDispatcher = 'primaryDispatcher',
  employmentType = 'employmentType',
  operationType = 'operationType',
  hasViolation = 'hasViolation',
  displayMode = 'displayMode',
  zoomLevel = 'zoomLevel',
  ganttColumnWidth = 'ganttColumnWidth',
}

export enum EGanttTabPanel {
  Gantt = 'Gantt',
  Map = 'Map',
  Grid = 'Grid',
}

export const TIMEOFF_TOOLTIP_TYPE = 'BREAK';

export const textStyles = { fontFamily: 'Poppins' };

export const ganttLegend = {
  content: [
    {
      color: '#B6BDC7',
      text: Label.ganttLegendCompleted,
      textStyles,
    },
    {
      color: '#5C667D',
      text: Label.ganttLegendPlanned,
      textStyles,
    },
    {
      color: '#2F3B58',
      text: Label.ganttLegendAssigned,
      textStyles,
    },
    {
      color: '#008280',
      text: Label.ganttLegendDispatched,
      textStyles,
    },
    {
      color: '#0C6BB9',
      text: Label.ganttLegendInTransit,
      textStyles,
    },
    {
      color: '#fff',
      text: Label.ganttLegendDeadhead,
      textStyles,
      colorBoxStyles: {
        border: '2px solid #0C6BB9',
      },
    },
    {
      color: '#fff',
      text: Label.ganttLegendNeedLoad,
      textStyles,
      colorBoxStyles: {
        border: '1px solid red',
      },
    },
  ],
  containerStyles: {
    height: '300px',
    overflowY: 'auto',
  },
  itemContainerStyles: {
    padding: '9px 16px',
  },
};

export const RECOMMEND_SORT_ENUMS = {
  groupName: 'groupName',
  driverGroupName: 'driverGroupName',
  driverAvailability: 'driverAvailability',
  timeToPickup: 'timeToPickup',
  revenuePerMile: 'revenuePerMile',
  revenuePerHour: 'revenuePerHour',
  deadheadMiles: 'deadheadMiles',
  delays: 'delays',
};

export const timelineDateFormat = 'YYYY-MM-DD HH:mm:ss';

export const GANTT_DEFAULT_ZOOM = 1;

export const RESOURCES_TYPE = {
  DRIVER: 'DRIVER',
  TRIP: 'TRIP',
};

export enum OPTIMIZATION_SOURCES {
  EVALUATION = 'EVALUATION',
  DISPATCH = 'DISPATCH',
}
