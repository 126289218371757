import { Stack } from '@mui/material';
import Filters from '../../../../ui-kit/components/DataGridPro/filter/Filters';
import { FC } from 'react';

type FiltersProps = Parameters<typeof Filters>[0];

const StyledFilters: FC<FiltersProps> = (props) => {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      pb={1}
      sx={{
        '> .MuiBox-root': {
          pt: '6px',
        },
        '.MuiFormControl-root': {
          minHeight: 'unset',
          height: 'unset',
        },
      }}
    >
      <Filters {...props} />
    </Stack>
  );
};

export default StyledFilters;
