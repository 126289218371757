import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { PieChart, PieGraph, ZingChartData } from './PieChart';
import { ZingGuiSettings } from './zingchart.settings';

interface ProgressChartProps {
  percentComplete: number;
  subTitle: string;
}
export const ProgressChart = ({
  percentComplete,
  subTitle,
}: ProgressChartProps): JSX.Element => {
  const theme: any = useTheme();

  const chartData: ZingChartData = useMemo(() => {
    const g1 = PieGraph({
      color: theme.palette.primary.main,
      title: `${percentComplete}%`,
      subTitle: subTitle,
      plotOptions: {
        slice: 45, // Ring width
      },
      plotarea: {
        margin: 0,
      },
      series: [
        {
          values: [percentComplete],
          backgroundColor: theme.palette.primary.main,
        },
        {
          values: [100 - percentComplete],
          backgroundColor: theme.palette.info.main,
        },
      ],
    });
    return {
      layout: 'auto',
      graphset: [g1],
      gui: ZingGuiSettings,
    };
  }, [
    percentComplete,
    subTitle,
    theme.palette.info.main,
    theme.palette.primary.light,
    theme.palette.primary.main,
  ]);

  return <PieChart height={90} width={90} chartData={chartData} />;
};
