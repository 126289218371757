import { Typography, useTheme } from '@mui/material';
import { CustomDashboardLoader } from '../../views/home/CustomDashboardLoader';
import {
  StyledFinanceKPICard,
  StyledFinanceKPICardLeft,
  StyledFinanceKPICardRight,
} from './styles/financeKPI.styles';

export interface FinanceKPICardProps {
  renderLeftContent: () => React.ReactNode | null;
  title: React.ReactNode;
  valueDisplay: React.ReactNode;
  subTitle: React.ReactNode;
  isMileage?: boolean;
  financeLoader?: boolean;
}
export const FinanceKPICard = ({
  renderLeftContent,
  title,
  valueDisplay,
  subTitle,
  isMileage = false,
  financeLoader,
}: FinanceKPICardProps): JSX.Element => {
  const theme: any = useTheme();
  return (
    <StyledFinanceKPICard isMileage={isMileage}>
      <CustomDashboardLoader loading={!!financeLoader} cardName="financeCard" />
      {!financeLoader && (
        <>
          <StyledFinanceKPICardLeft
            sx={{
              ...(renderLeftContent() === null && {
                minWidth: 0,
                width: 0,
              }),
            }}
          >
            {renderLeftContent() || <></>}
          </StyledFinanceKPICardLeft>

          <StyledFinanceKPICardRight
            {...(isMileage ? { mileage: 'true' } : {})}
          >
            <Typography
              component={'div'}
              variant="h8"
              color={'primary.main'}
              sx={{
                ...theme.typography.buttonMedium,
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '150%',
                ...(isMileage && {
                  color: 'text.primary',
                }),
              }}
            >
              {title}
            </Typography>
            <Typography
              component={'div'}
              variant={isMileage ? 'h6' : 'KPIValue'}
              color={isMileage ? 'warning.main' : 'info.main'}
              sx={{
                ...(!isMileage && {
                  fontSize: '34px',
                }),
                fontWeight: 500,
                lineHeight: '130%',
              }}
            >
              {valueDisplay}
            </Typography>
            <Typography
              component={'div'}
              variant={isMileage ? 'h6' : 'body2Bold'}
              color={'primary.main'}
              sx={{
                fontSize: '14px',
                lineHeight: '150%',
                fontWeight: 500,
                ...(isMileage && {
                  fontSize: '20px',
                }),
              }}
            >
              {subTitle}
            </Typography>
          </StyledFinanceKPICardRight>
        </>
      )}
    </StyledFinanceKPICard>
  );
};
