import EmailFormContainer from './index';
import Albom from '../Albom';

import { Box } from '@mui/material';
import { docType } from '../../../../utils/Doc';

/* @TODO this file should be rewritten */

const styles = {
  main: {
    display: 'flex',
    height: '100%',
    background: 'rgba(3, 14, 24, 0.89)',
  },
  container: {
    width: '50%',
    padding: '24px',
  },
  formWraapper: {
    width: '50%',
    background: '#ffffff',
    borderRadius: '24px',
    padding: '32px',
  },
};

interface IProps {
  handleUploadDialogClose: () => void;
  emailDocument: (data: {
    to: string;
    message: string;
    subject: string;
    cc: string;
  }) => void;
  files: docType[];
  setFiles: (files: docType[]) => void;
}

const Email = ({
  handleUploadDialogClose,
  emailDocument,
  files,
  setFiles,
}: IProps) => {
  return (
    <Box sx={styles.main}>
      <Box sx={styles.container}>
        <Albom viewFile={files} setFiles={setFiles} />
      </Box>
      {files.length && (
        <Box sx={styles.formWraapper}>
          <EmailFormContainer
            onClose={handleUploadDialogClose}
            sendDocument={emailDocument}
            files={files}
          />
        </Box>
      )}
    </Box>
  );
};

export default Email;
