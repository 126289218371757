import Grid from '@mui/material/Grid';
import TextInput from '../../../../../ui-kit/components/TextField';
import AutocompleteField from '../../../../../ui-kit/components/Autocomplete';
import { ArrowRightAlt, SwapHorizOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { AxeleMapPayItemsCategory } from '../../../../../models';

export const MapInvoicePayItems = ({
  axeleMapPayItems,
  QBOIncomeCategory,
  mapIncomeDropdownItems,
  handleAutocompleteChnage,
  connectionStatus,
}: any) => {
  return (
    <Grid
      container
      id="MapInvoicePayItems-container"
      sx={{ height: '100%', p: '40px 32px' }}
    >
      <Grid
        id="MapInvoicePayItems-header"
        justifyContent={'center'}
        container
        sx={{ color: '#000' }}
      >
        <Grid
          id="AxeleIncomeCategory"
          item
          xs={6}
          display={'flex'}
          justifyContent={'center'}
        >
          <Typography>LoadOps Income Category</Typography>
        </Grid>
        <Grid
          id="QBItemCategory"
          item
          xs={6}
          display={'flex'}
          justifyContent={'center'}
        >
          <Typography>QuickBooks Item Category</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        id="MapInvoicePayItems-body"
        sx={{ height: '95%', overflowY: 'auto', p: '0 0 25px 0' }}
      >
        {axeleMapPayItems.map((item: AxeleMapPayItemsCategory) => {
          const tempValue = QBOIncomeCategory[item.id];
          return (
            <Grid
              container
              id="MapInvoicePayItems-row-container"
              sx={{ pt: 3 }}
              key={item.id}
            >
              <Grid item id="Axele-side" xs={5}>
                <TextInput
                  label={'LoadOps Income Category'}
                  variant="standard"
                  defaultValue={item.itemName}
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                id="divider-Logo"
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                xs={2}
              >
                {connectionStatus && !!tempValue ? (
                  <SwapHorizOutlined sx={{ color: '#4CAF50' }} />
                ) : (
                  <ArrowRightAlt sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                )}
              </Grid>
              <Grid item id="QBO-side" xs={5} zIndex={100}>
                <AutocompleteField
                  id={item.id}
                  label="QuickBooks Item Category"
                  variant="standard"
                  options={mapIncomeDropdownItems}
                  defaultValue={{ label: tempValue }}
                  onChange={handleAutocompleteChnage}
                  disabled={!connectionStatus}
                  disableClearable={true}
                  styleProps={{
                    ...(connectionStatus && {
                      input: { color: '#000' },
                      label: { color: '#000' },
                    }),
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
