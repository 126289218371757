import { CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React from 'react';
import {
  bookingConfirmTitleStyle,
  bookingContentHeaderStyle,
  bookingContentTitleStyle,
  bookingDividerStyle,
  bookingMilesStyle,
  bookingSubTitleStyle,
  bookingTitleStyle,
  textPrimaryColor,
} from '../style/style';
import { BasicTimeline } from './locationTimeline';

export interface BookingConfirmationPopoverProps {
  open: boolean;
  data: any;
  handleCloseConfirmation: () => void;
  handleInstantBooking: (data: any) => void;
}

export const BookingConfirmationPopover: React.FC<
  BookingConfirmationPopoverProps
> = ({ open, data, handleCloseConfirmation, handleInstantBooking }) => {
  const theme = useTheme();

  const StyledDialog = styled(Dialog)(({ theme }) => {
    return theme.unstable_sx({
      '& .MuiDialog-paper': {
        borderRadius: '19.3689px',
        fontFamily: 'Poppins',
        width: 344,
      },
      '& .MuiDialog-paper .title-wrapper': {
        fontFamily: 'Poppins',
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.background.default,
      },
    });
  });
  return (
    <StyledDialog disableEnforceFocus open={open}>
      <Box>
        <Box sx={{ ...bookingConfirmTitleStyle }}>
          <Typography sx={{ ...bookingTitleStyle }}>
            Confirm load booking
          </Typography>

          <CloseOutlined
            onClick={handleCloseConfirmation}
            sx={{ cursor: 'pointer' }}
          />
        </Box>

        <Box sx={{ ...bookingSubTitleStyle }}>
          <Typography fontSize={'12px'}>
            You are agreeing to book with the offer rate:
          </Typography>
          <Typography sx={{ ...bookingMilesStyle }}>
            $
            {data?.revenue || data?.revenue == 0
              ? Number(data.revenue)?.toFixed(2)
              : data?.revenue}
          </Typography>
          <Divider sx={{ ...bookingDividerStyle }} />
        </Box>
        <Box padding={'8px'} sx={{ ...textPrimaryColor }}>
          <Box sx={{ ...bookingContentTitleStyle }}>
            <Box marginBottom={2} fontWeight={500}>
              <Typography sx={{ ...bookingContentHeaderStyle }}>
                {data?.loadBoard == 'DAT_V3' ? 'DAT' : data?.loadBoard}
              </Typography>
              <Typography
                sx={{
                  ...bookingContentHeaderStyle,
                }}
              >
                {`${
                  data?.loadMiles || data?.loadMiles == 0
                    ? data?.loadMiles?.toFixed(2)
                    : data?.loadMiles
                } loaded miles`}
              </Typography>
            </Box>
            <Box>
              <BasicTimeline data={data} />
            </Box>
          </Box>

          <Box textAlign={'end'}>
            <Button
              variant="contained"
              sx={{ borderRadius: '10px' }}
              onClick={() => handleInstantBooking(data)}
            >
              Confirm Booking
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledDialog>
  );
};
