import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { gridPageSize } from '../../../utils';
import { getDateRangeOptions, getSortOptions } from '../utils/filters.utils';
import { DispatchFiltersName, IDispatchFilters } from './types';

export const ganttHeaderAndFilterHeightPx = '160px';
export const fixedDetailsPanelWidth = '540px';
export const ganttTooltipWidth = '467px';
export const ganttPageSize = 50;

export const debounceTime = 300;

export const chunkProcessorMaxChunkSize = gridPageSize;
export const chunkProcessorDebounce = 300;

export const SCROLL_OFFSET = 500;

export const defaultDispatchFilters: IDispatchFilters = {
  filters: {
    [DispatchFiltersName.sort]: getSortOptions()[0],
    [DispatchFiltersName.sortDirection]: true,
    [DispatchFiltersName.driver]: [],
    [DispatchFiltersName.startDate]:
      RelativeDateRangeUtils.getSettingsDispatch().options[1],
    [DispatchFiltersName.window]: getDateRangeOptions()[0],
    [DispatchFiltersName.needLoad]: null,
    [DispatchFiltersName.timeOff]: null,
    [DispatchFiltersName.hasViolation]: null,
    [DispatchFiltersName.operationMode]: null,
    [DispatchFiltersName.primaryDispatcher]: [],
    [DispatchFiltersName.employmentType]: null,
    [DispatchFiltersName.operationType]: null,
  },
  query: {},
};

export const defaultNoValue = '--';

export const groupModeTeamDriver = 'TEAM';
