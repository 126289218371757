import { CloseOutlined, Download, EditOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import update from 'immutability-helper';
import { useCallback, useEffect, useState } from 'react';
import StorageManager from '../StorageManager/StorageManager';
import { basicReportsService } from '../api';
import { ServiceError } from '../api/interfaces';
import {
  CreateAdvanceLoadReportRequest,
  GetDriverTimeOffRequest,
  GetQuaInfoForReportsRequest,
  PreferencesDTO,
} from '../models';
import {
  RootStoreInstence,
  useRootStore,
} from '../store/root-store/rootStateContext';
import { downloadFile } from '../utils/Doc';
import { getGridCompanyTimezoneSettings } from '../utils/Timezone.utils';
import { FieldsSection } from '../views/reports/advancedReports/components/fieldsSection';
import { FilterSection } from '../views/reports/advancedReports/components/filterSection';
import { AdvanceReportSideBar } from '../views/reports/advancedReports/components/sidebar';
import {
  DefaultTrendFilter,
  defaultSelect,
  reportColumns,
} from '../views/reports/advancedReports/constant';
import {
  AdvancedReportFilterConfig,
  AdvancedReportList,
  DefaultAdvanceReportsConfig,
  DefaultFilterValuesReport,
} from '../views/reports/advancedReports/report.config';
import {
  fieldsListStyle,
  getSelectedReportStyle,
  reportFilterContainerStyle,
  reportHeadingStyle,
  reportListStyle,
  visibleFieldsContainer,
} from '../views/reports/advancedReports/style';
import {
  filterDisplayText,
  getQueryForDriverTimeOff,
  getQueryForLoad,
  getQueryForTrend,
} from '../views/reports/advancedReports/utils';

export const AdvancedReport = () => {
  const [selectedReport, setSelectedReport] = useState<{
    id: string;
    title: string;
    description: string;
    subCatagory?: Array<{
      id: string;
      title: string;
    }>;
  }>(DefaultAdvanceReportsConfig[0]);
  const [selectedSubReport, setSelectedSubReport] = useState<any | null>(
    selectedReport?.subCatagory?.[0]
  );
  const [selectedSubReportsFields, setSelectedSubReportsFields] =
    useState<any>();
  // { reportColumns[selectedReport.id] }
  const [initialVisibleFields, setInitialVisibleFields] = useState<
    Array<string>
  >(reportColumns.LOAD.LOAD_AND_INVOICE.visibleFields);
  const [initialhiddenFields, setinitialHiddenFields] = useState<Array<string>>(
    reportColumns.LOAD.LOAD_AND_INVOICE.hiddenFields
  );
  const [updatedVisibleFields, setUpdatedVisibleFields] = useState<
    Array<string>
  >(reportColumns.LOAD.LOAD_AND_INVOICE.visibleFields);
  const [updatedhiddenFields, setUpdatedHiddenFields] = useState<Array<string>>(
    reportColumns.LOAD.LOAD_AND_INVOICE.hiddenFields
  );

  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: any;
  }>({
    ...DefaultFilterValuesReport.LOAD,
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedFiltersConfig, setSelectedFilterConfig] = useState<Array<any>>(
    AdvancedReportFilterConfig.LOAD.defaultAppliedFilters
  );

  const timezone = getGridCompanyTimezoneSettings().timezone;
  const [allFilters, setAllFilters] = useState<any>(
    AdvancedReportFilterConfig.LOAD.allFilters
  );
  const { getGlobalTerminalsIds, getCompanyPreferences } = useRootStore();

  const theme = useTheme();
  const setInitialSubReportFields = () => {
    if (selectedReport?.id == AdvancedReportList.LOAD.id) {
      setSelectedSubReportsFields({
        [AdvancedReportList.LOAD_AND_INVOICE.id]: {
          ...reportColumns[selectedReport.id][
            AdvancedReportList.LOAD_AND_INVOICE.id
          ],
        },
        [AdvancedReportList.TRIPS_AND_STOPS.id]: {
          ...reportColumns[selectedReport.id][
            AdvancedReportList.TRIPS_AND_STOPS.id
          ],
        },
        [AdvancedReportList.DRIVER_TRIP_PAYMENTS.id]: {
          ...reportColumns[selectedReport.id][
            AdvancedReportList.DRIVER_TRIP_PAYMENTS.id
          ],
        },
        [AdvancedReportList.TRACTOR_TRIP_PAYMENTS.id]: {
          ...reportColumns[selectedReport.id][
            AdvancedReportList.TRACTOR_TRIP_PAYMENTS.id
          ],
        },
      });
    }
  };
  useEffect(() => {
    setInitialSubReportFields();
  }, []);
  const handleIftaFiltersValue = (data: any) => {
    const DefaultValues = { ...DefaultFilterValuesReport[data.id] } as any;
    const filteredData = AdvancedReportFilterConfig[
      data.id
    ].defaultAppliedFilters?.filter(
      (filterData) => filterData?.name == 'quarter'
    )?.[0];
    DefaultValues.quarter = filteredData?.options?.[0]?.value;
    setSelectedFilters(DefaultValues);
  };

  const handleSetInitialValues = () => {
    if (selectedSubReport?.id) {
      setInitialSubReportFields();
      setUpdatedVisibleFields([...initialVisibleFields]);
      setUpdatedHiddenFields([...initialhiddenFields]);
    } else {
      setUpdatedVisibleFields([...initialVisibleFields]);
      setUpdatedHiddenFields([...initialhiddenFields]);
    }
    setSelectedFilterConfig(
      AdvancedReportFilterConfig[selectedReport.id]?.defaultAppliedFilters
    );
    setAllFilters(AdvancedReportFilterConfig[selectedReport.id]?.allFilters);
    if (
      selectedReport.id === AdvancedReportList.IFTA.id &&
      AdvancedReportFilterConfig[selectedReport.id]?.defaultAppliedFilters
        ?.length > 0
    ) {
      handleIftaFiltersValue(selectedReport);
    } else {
      setSelectedFilters(DefaultFilterValuesReport[selectedReport.id]);
    }
  };

  const handleReportOnClick = (data: any) => {
    setSelectedReport(data);
    if (data?.subCatagory && data?.subCatagory?.length > 0) {
      const subCatagory = data?.subCatagory[0].id;
      setSelectedSubReport(data?.subCatagory[0]);
      setInitialSubReportFields();
      setInitialVisibleFields([
        ...reportColumns[data?.id][subCatagory]?.visibleFields,
      ]);
      setinitialHiddenFields([
        ...reportColumns[data?.id][subCatagory]?.hiddenFields,
      ]);
      setUpdatedVisibleFields([
        ...reportColumns[data?.id][subCatagory]?.visibleFields,
      ]);
      setUpdatedHiddenFields([
        ...reportColumns[data?.id][subCatagory]?.hiddenFields,
      ]);
    } else {
      setSelectedSubReport(null);
      setSelectedSubReportsFields({});
      setInitialVisibleFields([...reportColumns[data?.id]?.visibleFields]);
      setinitialHiddenFields([...reportColumns[data?.id]?.hiddenFields]);
      setUpdatedVisibleFields([...reportColumns[data?.id]?.visibleFields]);
      setUpdatedHiddenFields([...reportColumns[data?.id]?.hiddenFields]);
    }
    if (
      data.id === AdvancedReportList.IFTA.id &&
      AdvancedReportFilterConfig[data.id]?.defaultAppliedFilters?.length > 0
    ) {
      handleIftaFiltersValue(data);
    } else if (data.id in DefaultFilterValuesReport) {
      setSelectedFilters(DefaultFilterValuesReport[data.id]);
    }
    if (data.id in AdvancedReportFilterConfig) {
      setSelectedFilterConfig(
        AdvancedReportFilterConfig[data.id].defaultAppliedFilters
      );
      setAllFilters(AdvancedReportFilterConfig[data.id].allFilters);
    }
  };
  const createIFTAReport = async () => {
    if (
      !(selectedFilters?.quarter?.year || selectedFilters?.quarter?.quarter)
    ) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Period id required',
      });
      return;
    }
    const query = {
      year: selectedFilters?.quarter?.year,
      quarter: selectedFilters?.quarter?.quarter,
      tractorId:
        selectedFilters?.tractorIds?.map((tractorData) => tractorData?.id) ||
        [],
    };
    const requestQuery = new GetQuaInfoForReportsRequest(query);
    RootStoreInstence.setNotificationType({
      type: 'SUCCESS',
      message:
        'Report Generation is in progress. Report will be downloaded automatically.',
    });
    const response = await basicReportsService.createIFTAReport(requestQuery);
    if (response instanceof ServiceError) {
      return;
    } else {
      response && downloadFile(response);
    }
  };

  const createTimeOffReport = async () => {
    const queryData = getQueryForDriverTimeOff(selectedFilters);
    queryData.terminalIds = getGlobalTerminalsIds;
    queryData.selectedFields = {
      DRIVER_TIMEOFF: updatedVisibleFields,
    };
    const requestQuery = new GetDriverTimeOffRequest(queryData);
    RootStoreInstence.setNotificationType({
      type: 'SUCCESS',
      message:
        'Report Generation is in progress. Report will be downloaded automatically.',
    });
    const response = await basicReportsService.createDriverTimeOffReport(
      requestQuery
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      response && downloadFile(response);
    }
  };
  const handleSubReportClick = (data: { id: any }) => {
    const selectedSubReportField = { ...selectedSubReportsFields } as {
      [key: string]: any;
    };
    if (
      data?.id in selectedSubReportsFields &&
      selectedSubReportsFields[data.id]
    ) {
      const defaultVisibleFields =
        selectedSubReportsFields[data.id].visibleFields;
      const defautHiddenFields = selectedSubReportsFields[data.id].hiddenFields;
      selectedSubReportField[selectedSubReport.id].visibleFields = [
        ...updatedVisibleFields,
      ];
      selectedSubReportField[selectedSubReport.id].hiddenFields = [
        ...updatedhiddenFields,
      ];
      setSelectedSubReportsFields({ ...selectedSubReportField });
      setInitialVisibleFields([...defaultVisibleFields]);
      setinitialHiddenFields([...defautHiddenFields]);
      setUpdatedVisibleFields([...defaultVisibleFields]);
      setUpdatedHiddenFields([...defautHiddenFields]);
    }
    setSelectedSubReport(data);
  };

  const handleAddFilter = () => {
    const selectedFilterData = [...selectedFiltersConfig];
    const allFilterData = [...allFilters];
    selectedFilterData.push(allFilterData[0]);
    allFilterData.splice(0, 1);
    setSelectedFilterConfig([...selectedFilterData]);
    setAllFilters([...allFilterData]);
  };

  const HandleDefaultFilterValues = (filterName: string) => {
    const selectedFilterValues = { ...selectedFilters };
    if (filterName && filterName in defaultSelect) {
      selectedFilterValues[filterName] = defaultSelect[filterName];
      setSelectedFilters({ ...selectedFilterValues });
    }
  };
  const handleRemoveFilters = (data: any, index: number) => {
    const selectedFilterData = [...selectedFiltersConfig];
    const allFilterData = [...allFilters];
    allFilterData.push(selectedFilterData[index]);
    HandleDefaultFilterValues(selectedFilterData[index]?.name);
    if (index > -1) {
      selectedFilterData.splice(index, 1);
    }
    setAllFilters([...allFilterData]);
    setSelectedFilterConfig([...selectedFilterData]);
  };
  const hanldeChangeFieldSequence = useCallback(
    (dragIndex: number, hoverIndex: number, isVisible: boolean) => {
      isVisible
        ? setUpdatedVisibleFields((prevData: Array<string>) =>
            update(prevData, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, prevData[dragIndex]],
              ],
            })
          )
        : setUpdatedHiddenFields((prevData: Array<string>) =>
            update(prevData, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, prevData[dragIndex]],
              ],
            })
          );
    },
    []
  );

  const handleVisibilityChange = (
    data: string,
    index: number,
    isVisible: boolean
  ) => {
    const visibleFields = [...updatedVisibleFields];
    const hiddenFields = [...updatedhiddenFields];
    if (isVisible) {
      data == visibleFields[index] && visibleFields.splice(index, 1);
      hiddenFields.push(data);
    } else {
      data == hiddenFields[index] && hiddenFields.splice(index, 1);
      visibleFields.push(data);
    }
    setUpdatedVisibleFields(visibleFields);
    setUpdatedHiddenFields(hiddenFields);
  };
  const handleFilterSelection = (
    newData: any,
    index: number,
    oldFilterData: any
  ) => {
    const selectedFilterData = [...selectedFiltersConfig];
    const allFilterData = [...allFilters];
    let dataIndex = -1;
    const data = allFilters?.filter((data: any, index: number) => {
      if (data.name == newData.value) {
        dataIndex = index;
        return data;
      }
    })?.[0];
    if (oldFilterData) {
      allFilterData.push(oldFilterData);
    }
    allFilterData.splice(dataIndex, 1);
    selectedFilterData[index] = data;
    HandleDefaultFilterValues(oldFilterData?.name);
    setAllFilters([...allFilterData]);
    setSelectedFilterConfig([...selectedFilterData]);
  };

  const onResponse = async (queryData: CreateAdvanceLoadReportRequest) => {
    queryData.terminalId = getGlobalTerminalsIds || [];
    if (!queryData.terminalId!.length) {
      const allTerminals = StorageManager.getItem('allTerminals');
      if (allTerminals?.length)
        queryData.terminalId = allTerminals.map(
          (terminal: { id: string }) => terminal.id
        );
    }
    if (queryData.isTrend)
      queryData.terminalId = queryData.terminalId!.join(
        ','
      ) as unknown as string[];
    RootStoreInstence.setNotificationType({
      type: 'SUCCESS',
      message:
        'Report Generation is in progress. Report will be downloaded automatically.',
    });
    const response = await basicReportsService.createAdvanceLoadReport(
      queryData
    );
    if (response?.file) {
      downloadFile(response);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Report Generated Successfully.',
      });
    } else if (response) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: response,
      });
    }
  };

  const handleDownloadReport = () => {
    let queryData = new CreateAdvanceLoadReportRequest();
    if (selectedReport?.id === AdvancedReportList.LOAD.id) {
      queryData = getQueryForLoad(selectedFilters as any);
      const selectedReportFields = { ...selectedSubReportsFields };
      selectedReportFields[selectedSubReport.id].visibleFields = [
        ...updatedVisibleFields,
      ];
      selectedReportFields[selectedSubReport.id].hiddenFields = [
        ...updatedhiddenFields,
      ];
      const selectedFields = {
        LOAD_AND_INVOICE:
          selectedReportFields?.LOAD_AND_INVOICE?.visibleFields || [],
        TRIPS_AND_STOPS:
          selectedReportFields?.TRIPS_AND_STOPS?.visibleFields || [],
        DRIVER_TRIP_PAYMENTS:
          selectedReportFields?.DRIVER_TRIP_PAYMENTS?.visibleFields || [],
        TRACTOR_TRIP_PAYMENTS:
          selectedReportFields?.TRACTOR_TRIP_PAYMENTS?.visibleFields || [],
      };
      setSelectedSubReportsFields({ ...selectedReportFields });
      queryData.selectedFields = { ...selectedFields };
    }
    if (selectedReport?.id === AdvancedReportList.LOAD_TREND.id) {
      const selectedFields = {
        TREND_REPORT_MONTHLY: [...updatedVisibleFields],
        TREND_REPORT_WEEKLY: [...updatedVisibleFields],
      };
      queryData = getQueryForTrend(
        selectedFilters as DefaultTrendFilter,
        selectedFields,
        getCompanyPreferences as PreferencesDTO
      );
    } else if (selectedReport?.id === AdvancedReportList.DRIVER_TIMEOFF.id) {
      createTimeOffReport();
      return;
    } else if (selectedReport?.id === AdvancedReportList.IFTA.id) {
      createIFTAReport();
      return;
    }
    onResponse(queryData);
  };
  return (
    <Box display={'flex'} height={'100%'}>
      <Box
        sx={{
          width: '346px',
          height: '100%',
          bgcolor: 'background.paper',
          borderRadius: '16px 16px 0px 0px',
        }}
      >
        <AdvanceReportSideBar
          data={DefaultAdvanceReportsConfig}
          listStyle={{ width: '346px' }}
          listItemStyle={{
            '.MuiListItemText-secondary': {
              fontSize: '12px',
            },
          }}
          selectedReport={selectedReport}
          selectedReportStyle={getSelectedReportStyle({ theme })}
          handleReportOnClick={handleReportOnClick}
        />
      </Box>
      <Box sx={{ ...reportListStyle }}>
        <Box>
          <Typography
            variant={'h4'}
            sx={(theme) => ({ color: theme.palette.text.primary })}
          >
            {AdvancedReportList[selectedReport?.id].label}
          </Typography>
          <Box sx={{ ...reportHeadingStyle }}>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({ color: theme.palette.text.disabled })}
            >
              {/* Last Downloaded by Betty Boop on 1/4/23 @ 14:30 */}
            </Typography>
            <Box>
              {isEditMode ? (
                <Button
                  variant="outlined"
                  startIcon={<CloseOutlined />}
                  onClick={() => {
                    setIsEditMode(!isEditMode);
                    handleSetInitialValues();
                  }}
                  color={'error'}
                  sx={{
                    marginRight: '5px',
                    color: theme.palette.error.main,
                    borderColor: theme.palette.error.main,
                  }}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<EditOutlined />}
                  onClick={() => {
                    setIsEditMode(!isEditMode);
                  }}
                  sx={{
                    marginRight: '5px',
                  }}
                >
                  Edit
                </Button>
              )}
              <Button
                variant="contained"
                startIcon={<Download />}
                onClick={handleDownloadReport}
                // disabled={selectedReport?.id != 'LOAD'}
              >
                Download
              </Button>
            </Box>
          </Box>
        </Box>
        <Box display="flex" height={'90%'}>
          {((updatedVisibleFields && updatedVisibleFields?.length > 0) ||
            (updatedhiddenFields && updatedhiddenFields?.length > 0)) && (
            <Box sx={{ ...visibleFieldsContainer }}>
              {selectedReport?.subCatagory?.length > 0 && (
                <>
                  <AdvanceReportSideBar
                    data={selectedReport.subCatagory}
                    listStyle={{ width: '200px', marginRight: '10px' }}
                    listItemStyle={{ ...fieldsListStyle }}
                    handleReportOnClick={handleSubReportClick}
                    selectedReport={selectedSubReport}
                    selectedReportStyle={{ background: '#EEF3FB' }}
                  />
                  <Divider orientation="vertical" variant="middle" flexItem />
                </>
              )}
              <FieldsSection
                visibleFields={updatedVisibleFields}
                changeFieldSequence={hanldeChangeFieldSequence}
                hiddenFields={updatedhiddenFields}
                handleVisibilityChange={handleVisibilityChange}
                isEditMode={isEditMode}
                width={selectedSubReport?.id ? '248px' : '100%'}
              />
            </Box>
          )}
          <Box sx={{ ...reportFilterContainerStyle }}>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.text.secondary, fontSize: '20px' }}
            >
              Filters
            </Typography>
            {isEditMode ? (
              <>
                <Typography
                  variant="subtitle2"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Select what information you would like to see in the final
                  report
                </Typography>
                <FilterSection
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  allFilters={allFilters}
                  selectedFiltersConfig={selectedFiltersConfig}
                  handleFilterSelection={handleFilterSelection}
                  handleAddFilter={handleAddFilter}
                  handleRemoveFilters={handleRemoveFilters}
                />
              </>
            ) : (
              <Box sx={{ padding: '10px', marginTop: '8px' }}>
                {selectedFiltersConfig?.map((data) => {
                  return (
                    <>
                      <Typography variant="body1" sx={{}}>
                        {filterDisplayText(data, selectedFilters, timezone)}
                      </Typography>
                      <Typography
                        variant="body3"
                        sx={{ color: theme.palette.text.disabled }}
                      >
                        {data?.label}
                      </Typography>
                    </>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
