import { Box } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import TrailerController from '../../../../../../subPages/trailers/TrailerController';
import { SingleAutocompleteForm } from '../../../../../Ui/SingleAutocomplete';
import { Trip } from '../../Models';
import RoutesController from '../../RoutesController';
import AssignmentFormWrapper from '../AssignmentFormWrapper';
import WarningWindow from '../DriverAssignment/WarningWindow';
import { useTranslation } from 'react-i18next';

export default function TrailerAssignmentToLoad({
  trip,
  onClose,
  onAssign,
  apiAssignHandler,
}: {
  trip: Trip;
  onClose: () => void;
  onAssign: (trailer) => void;
  apiAssignHandler: (data: any) => Promise<void>;
}): JSX.Element {
  const { trailer: assignedTrailer } = trip;
  const [warnings, setWarnings] = useState<string[] | undefined>();
  const [data] = useState({ trailer: assignedTrailer });

  const handleTrailerAssign = async (data) => {
    if (data.trailer?.id) {
      if (apiAssignHandler) {
        await apiAssignHandler(data);
      } else {
        const response = await RoutesController.assignTrailerToLoad(
          data.trailer?.id,
          trip.id
        );
        if (!response) return;
      }
      onAssign(data.trailer);
    }
    onClose();
  };

  const handleTrailerChange = async (trailer) => {
    const warningsList = await RoutesController.getTrailerWarningsForLoadAssign(
      trailer.id,
      trip.id,
      trip.driverGroup?.id
    );
    setWarnings(warningsList);
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <AssignmentFormWrapper
        onAction={handleTrailerAssign}
        onClose={onClose}
        data={data}
        title={t('TrailerAssignmentToLoadAssignTrailer')}
        actionLabel={t('TrailerAssignmentToLoadAssign')}
      >
        <Box sx={{ marginBottom: '16px' }}>
          <WarningWindow warningList={warnings} />
        </Box>
        <FormComponent onTrailerChange={handleTrailerChange} trip={trip} />
      </AssignmentFormWrapper>
    );
  }
}

const FormComponent = ({
  trip,
  onTrailerChange,
}: {
  trip: Trip;
  onTrailerChange: (trailer) => void;
}) => {
  const { control } = useFormContext();

  const prepareStatusList = (status: string) => {
    if (
      [
        'AVAILABLE',
        'ASSIGNMENT_PLANNED',
        'OFFERED_TO_DRIVER',
        'DISPATCHED',
      ].includes(status)
    ) {
      return ['AVAILABLE', 'ASSIGNED'];
    }

    if (status === 'IN_TRANSIT') {
      return ['AVAILABLE'];
    }

    if (status === 'LOAD_COMPLETED') {
      return ['AVAILABLE', 'ASSIGNED', 'UNAVAILABLE'];
    }

    return [];
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <SingleAutocompleteForm
        control={control}
        disableClear
        name="trailer"
        fieldName="name"
        label={t('TrailerAssignmentToLoadTrailer')}
        onChangeCb={onTrailerChange}
        getOptions={(searchText: string | undefined, pageNumber: number) =>
          TrailerController.instance().getTrailerByNameExtendedSearch(
            searchText,
            pageNumber,
            prepareStatusList(trip.tripStatus),
            trip.terminal?.id ? [trip.terminal?.id] : undefined
          )
        }
      />
    );
  }
};
