import moment from 'moment';
import StorageManager from '../../../StorageManager/StorageManager';
import { UI_RELATIVE_DR_FORMAT } from '../../../constants/date.constants';
import {
  CreateAdvanceLoadReportRequest,
  PreferencesDTO,
  TrendReportSelectedFields,
} from '../../../models';
import { RelativeDateRangeUiKitUtils } from '../../../ui-kit/components/RelativeDateRange/RelativeDateRange.uikit.utils';
import { copyToClipboard } from '../../../utils';
import { DateTimezoneUtils } from '../../../utils/Timezone.utils';
import { SCHEDULE_TYPE, convertToUTC } from '../basicReports/constant';
import { DefaultLoadFilter, DefaultTrendFilter } from './constant';

function onQueryType(params: any, selectedFilters: any) {
  const { scheduleType, dateType } = selectedFilters;
  const request = {
    ...params,
    scheduleType: scheduleType
      ? SCHEDULE_TYPE.find((el) => el.id === scheduleType)?.name
      : '',
    dispatchType: dateType === 1 ? 'PickUp' : dateType === 2 ? 'Dropoff' : '',
  };
  const queryData = new CreateAdvanceLoadReportRequest();
  Object.keys(request).map((x) => {
    queryData[x] = request[x];
  });
  return queryData;
}

export const getQueryForTrend = (
  selectedFilters: DefaultTrendFilter,
  selectedFields: TrendReportSelectedFields,
  preferences: PreferencesDTO
) => {
  const { entity, type, period } = selectedFilters;
  const toDate = convertToUTC(
    new Date() as unknown as string,
    true,
    preferences.timezone
  );
  let fromDate = null;
  if (period && period !== 'whole') {
    fromDate = moment(toDate).add(period === 'one' ? -1 : -2, 'years');
  }
  const request = {
    fromDate: DateTimezoneUtils.toStartDateISOString(fromDate, null),
    toDate: DateTimezoneUtils.toEndDateISOString(toDate),
    selectedFields,
    entity,
    type,
    isTrend: true,
  };
  return onQueryType(request, selectedFilters);
};

export const getQueryForLoad = (selectedFilters: DefaultLoadFilter) => {
  const { driverIds, customerIds, tractorIds, trailerIds, Status, statusList } =
    selectedFilters;
  const request = {
    ...selectedFilters,
    driverIds: driverIds.map((x) => x.driverId),
    customerIds: customerIds.map((x) => x.id),
    tractorIds: tractorIds.map((x) => x.id),
    trailerIds: trailerIds.map((x) => x.id),
    loadStatusList: Status.map((x) => x.value),
    statusList: statusList?.map((x) => x.value),
    startDate: DateTimezoneUtils.toStartDateISOString(
      selectedFilters?.pickupRange?.dateRange?.[0]
    ),
    endDate: DateTimezoneUtils.toEndDateISOString(
      selectedFilters?.pickupRange?.dateRange?.[1]
    ),
  };
  return onQueryType(request, selectedFilters);
};

export const getQueryForDriverTimeOff = (
  selectedFilters: DefaultLoadFilter
) => {
  const { driverIds, Status } = selectedFilters;
  console.log(
    'selectedFilters?.startDate ? DateTimezoneUtils.toStartDateISOString(selectedFilters?.startDate) : DateTimezoneUtils.toStartDateISOString(selectedFilters?.pickupRange?.dateRange?.[0])',
    selectedFilters?.startDate
      ? DateTimezoneUtils.toStartDateISOString(selectedFilters?.startDate)
      : DateTimezoneUtils.toStartDateISOString(
          selectedFilters?.pickupRange?.dateRange?.[0]
        )
  );
  const request = {
    driverIds: driverIds?.map((x) => x.driverId),
    statusList: Status?.map((x) => x.value),
    fromDate: selectedFilters?.startDate
      ? DateTimezoneUtils.toStartDateISOString(selectedFilters?.startDate)
      : DateTimezoneUtils.toStartDateISOString(
          selectedFilters?.pickupRange?.dateRange?.[0]
        ),
    toDate: selectedFilters?.startDate
      ? DateTimezoneUtils.toEndDateISOString(selectedFilters?.endDate)
      : DateTimezoneUtils.toStartDateISOString(
          selectedFilters?.pickupRange?.dateRange?.[1]
        ),
    terminalIds: [],
    selectedFields: {},
  };
  return request;
};

export function onCopy(
  selectedFilters: DefaultLoadFilter & DefaultTrendFilter,
  selectedReportType: string,
  preferences: PreferencesDTO
) {
  const {
    statusList,
    driverIds,
    customerIds,
    tractorIds,
    trailerIds,
    startDate,
    endDate,
    dateType,
    scheduleType,
    entity,
    type,
    period,
  } = selectedFilters;
  let apiCopy =
    window.location.origin +
    '/backendpublic/tnt/api/v2/public/export/reports/' +
    (selectedReportType === 'load' ? 'advance' : 'trend');
  const userStorage = StorageManager.getUser();
  apiCopy += '?organizationId=' + userStorage.organizationId;
  apiCopy += '&userId=' + userStorage.id;
  if (scheduleType)
    apiCopy +=
      '&scheduleType=' +
      SCHEDULE_TYPE.find((el) => el.id === scheduleType)?.name;
  if (dateType)
    apiCopy += '&dateType=' + (dateType === 1 ? 'PickUp' : 'Dropoff');
  if (selectedReportType === 'load') {
    if (statusList.length) apiCopy += '&statusList=' + statusList.join(',');
    if (driverIds.length)
      apiCopy += '&driverIds=' + driverIds.map((x) => x.driverId).join(',');
    if (customerIds.length)
      apiCopy += '&customerIds=' + customerIds.map((x) => x.id).join(',');
    if (tractorIds.length)
      apiCopy += '&tractorIds=' + tractorIds.map((x) => x.id).join(',');
    if (trailerIds.length)
      apiCopy += '&trailerIds=' + trailerIds.map((x) => x.id).join(',');
    if (startDate) apiCopy += '&startDate=' + startDate;
    if (endDate) apiCopy += '&endDate=' + endDate;
  } else {
    const toDate = convertToUTC(
      new Date() as unknown as string,
      true,
      preferences.timezone
    );
    const fromDate =
      !period || period === 'whole'
        ? userStorage.registerDate
        : moment(toDate)
            .add(period === 'one' ? -1 : -2, 'years')
            .utc()
            .toISOString();
    apiCopy += '&entity=' + entity;
    apiCopy += '&type=' + type;
    apiCopy += '&toDate=' + toDate;
    if (period && period !== 'whole') apiCopy += '&fromDate=' + fromDate;
  }
  copyToClipboard(apiCopy);
}
export const prepareQuarterList = (noYears: number) => {
  const currentYear = moment().format('YYYY');
  const yearQuarterList = [];
  for (let years = 0; years <= noYears; years += 1) {
    const instanceYear = moment().subtract(years, 'year').format('YYYY');
    const quarterNumber =
      currentYear === instanceYear ? moment().utc().quarter() : 4;
    for (let quarter = quarterNumber; quarter--; ) {
      yearQuarterList.push({
        name: `${instanceYear} - Q${quarter + 1}`,
        quarter: quarter + 1,
        year: instanceYear,
      });
    }
  }
  return yearQuarterList;
};

export const filterDisplayText = (
  data: { name: string; type: string; label: string; options?: Array<any> },
  selectedFilters: { [key: string]: any },
  timezone: string
) => {
  if (data?.name in selectedFilters) {
    if (data?.type == 'RelativeDateRange') {
      return `${RelativeDateRangeUiKitUtils.fromNativeDateToFormat({
        inputDate: selectedFilters[data.name]?.dateRange?.[0],
        timezone: timezone,
        dateFormat: UI_RELATIVE_DR_FORMAT,
      })} - ${RelativeDateRangeUiKitUtils.fromNativeDateToFormat({
        inputDate: selectedFilters[data.name]?.dateRange?.[1],
        timezone: timezone,
        dateFormat: UI_RELATIVE_DR_FORMAT,
      })}`;
    } else if (
      data?.type == 'singleSelect' &&
      data?.options &&
      data?.options?.length > 0
    ) {
      let selectedOption = selectedFilters[data.name];
      data?.options?.forEach((option) => {
        option?.value == selectedFilters[data.name]
          ? (selectedOption = option?.label)
          : '';
      });
      return selectedOption;
    } else return selectedFilters[data.name];
  } else return '-';
};
