import React from 'react';
import { DefaultDataTypes, ListTypes } from './interface';

export const NotesContext = React.createContext({});

export const defaultData: DefaultDataTypes = {
  notesText: '',
  shareDriver: false,
};

export const insertEditMode = (list: ListTypes[]) =>
  list.map((item: ListTypes) => {
    return { ...item, editMode: false };
  });

export const makeIndexEdit = (list: ListTypes[], index: number) =>
  list.map((item: ListTypes, i: number) => {
    return { ...item, editMode: index === i };
  });

export const titleCase = (str = '') =>
  str
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b(\w)/g, (s: string) => s.toUpperCase());

export const deletePopOver = {
  title: `Delete Note?`,
  body: `Are you sure you want to delete note?`,
};
