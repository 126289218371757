export class VisitorUpdateRequest {
  firstname: string;
  lastname: string;
  email: string;
  constructor(dto: any) {
    this.firstname = dto.firstname;
    this.lastname = dto.lastname;
    this.email = dto.email;
  }
}

export class VisitorUpdateResponse {
  token!: string;
}
