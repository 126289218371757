export enum AXELE_PERMISSION_TYPE {
  CONTRACT_ADD = 'CONTRACT_ADD',
  CONTRACT_REMOVE = 'CONTRACT_REMOVE',
  CONTRACT_VIEW = 'CONTRACT_VIEW',
  CONTRACT_EDIT = 'CONTRACT_EDIT',
  DASHBOARD_VIEW = 'DASHBOARD_VIEW',
  LOADS_VIEW = 'LOADS_VIEW',
  LOAD_ADD = 'LOAD_ADD',
  LOAD_REMOVE = 'LOAD_REMOVE',
  ALERTS_VIEW = 'ALERTS_VIEW',
  ALERTS_ADD = 'ALERTS_ADD',
  ALERTS_REMOVE = 'ALERTS_REMOVE',
  ALERTS_EDIT = 'ALERTS_EDIT',
  TERMINALS_VIEW = 'TERMINALS_VIEW',
  TERMINALS_ADD = 'TERMINALS_ADD',
  TERMINALS_REMOVE = 'TERMINALS_REMOVE',
  TERMINALS_EDIT = 'TERMINALS_EDIT',
  DEFAULTS_VIEW = 'DEFAULTS_VIEW',
  DEFAULTS_ADD = 'DEFAULTS_ADD',
  DEFAULTS_REMOVE = 'DEFAULTS_REMOVE',
  DEFAULTS_EDIT = 'DEFAULTS_EDIT',
  SUBSCRIPTION_VIEW = 'SUBSCRIPTION_VIEW',
  SUBSCRIPTION_ADD = 'SUBSCRIPTION_ADD',
  SUBSCRIPTION_REMOVE = 'SUBSCRIPTION_REMOVE',
  SUBSCRIPTION_EDIT = 'SUBSCRIPTION_EDIT',
  LOAD_EDIT = 'LOAD_EDIT',
  LOAD_STATUS_CHANGE_TO_COMPLETED = 'LOAD_STATUS_CHANGE_TO_COMPLETED',
  LOAD_STATUS_CHANGE_COMPLETED_TO_AVAILABLE = 'LOAD_STATUS_CHANGE_COMPLETED_TO_AVAILABLE',
  LOAD_STATUS_CHANGE_COMPLETED_TO_INVOICED = 'LOAD_STATUS_CHANGE_COMPLETED_TO_INVOICED',
  LOAD_STATUS_CHANGE_INVOICED_TO_COMPLETED = 'LOAD_STATUS_CHANGE_INVOICED_TO_COMPLETED',
  LOAD_STATUS_CHANGE_INVOICED_TO_PAID = 'LOAD_STATUS_CHANGE_INVOICED_TO_PAID',
  LOAD_STATUS_CHANGE_PAID_TO_INVOICED = 'LOAD_STATUS_CHANGE_PAID_TO_INVOICED',
  LOAD_STATUS_CHANGE_TO_CANCELLED = 'LOAD_STATUS_CHANGE_TO_CANCELLED',
  LOAD_FINANCIAL_INVOICES_VIEW = 'LOAD_FINANCIAL_INVOICES_VIEW',
  LOAD_FINANCIAL_INVOICE_ADD = 'LOAD_FINANCIAL_INVOICE_ADD',
  LOAD_FINANCIAL_INVOICE_REMOVE = 'LOAD_FINANCIAL_INVOICE_REMOVE',
  LOAD_FINANCIAL_INVOICE_EDIT = 'LOAD_FINANCIAL_INVOICE_EDIT',
  LOAD_FINANCIAL_PAYMENT_VIEW = 'LOAD_FINANCIAL_PAYMENT_VIEW',
  LOAD_FINANCIAL_PAYMENT_ADD = 'LOAD_FINANCIAL_PAYMENT_ADD',
  LOAD_FINANCIAL_PAYMENT_REMOVE = 'LOAD_FINANCIAL_PAYMENT_REMOVE',
  LOAD_FINANCIAL_PAYMENT_EDIT = 'LOAD_FINANCIAL_PAYMENT_EDIT',
  FINANCIAL_PAYMENT_CHANGE_STATUS_INREVIEW_CLOSE = 'FINANCIAL_PAYMENT_CHANGE_STATUS_INREVIEW_CLOSE',
  FINANCIAL_PAYMENT_CHANGE_STATUS_CLOSE_INREVIEW = 'FINANCIAL_PAYMENT_CHANGE_STATUS_CLOSE_INREVIEW',
  DISPATCHES_VIEW = 'DISPATCHES_VIEW',
  DISPATCH_ADD_LOAD = 'DISPATCH_ADD_LOAD',
  DISPATCH_EDIT = 'DISPATCH_EDIT',
  DISPATCH_PREFERENCE_EDIT = 'DISPATCH_PREFERENCE_EDIT',
  TRUCK_POSTING_VIEW = 'TRUCK_POSTING_VIEW',
  TRUCK_POSTING_ADD = 'TRUCK_POSTING_ADD',
  TRUCK_POSTING_REMOVE = 'TRUCK_POSTING_REMOVE',
  TRUCK_POSTING_EDIT = 'TRUCK_POSTING_EDIT',
  CUSTOMERS_VIEW = 'CUSTOMERS_VIEW',
  CUSTOMER_ADD = 'CUSTOMER_ADD',
  CUSTOMER_REMOVE = 'CUSTOMER_REMOVE',
  CUSTOMER_EDIT = 'CUSTOMER_EDIT',
  EQUIPMENT_PAYMENT_TERMS_VIEW = 'EQUIPMENT_PAYMENT_TERMS_VIEW',
  EQUIPMENT_PAYMENT_TERM_ADD = 'EQUIPMENT_PAYMENT_TERM_ADD',
  EQUIPMENT_PAYMENT_TERM_REMOVE = 'EQUIPMENT_PAYMENT_TERM_REMOVE',
  EQUIPMENT_PAYMENT_TERM_EDIT = 'EQUIPMENT_PAYMENT_TERM_EDIT',
  EQUIPMENT_ALERT_VIEW = 'EQUIPMENT_ALERT_VIEW',
  EQUIPMENT_ALERT_ADD = 'EQUIPMENT_ALERT_ADD',
  EQUIPMENT_ALERT_REMOVE = 'EQUIPMENT_ALERT_REMOVE',
  EQUIPMENT_ALERT_EDIT = 'EQUIPMENT_ALERT_EDIT',
  LOCATIONS_VIEW = 'LOCATIONS_VIEW',
  LOCATION_ADD = 'LOCATION_ADD',
  LOCATION_REMOVE = 'LOCATION_REMOVE',
  LOCATION_EDIT = 'LOCATION_EDIT',
  EQUIPMENT_VIEW = 'EQUIPMENT_VIEW',
  EQUIPMENT_ADD = 'EQUIPMENT_ADD',
  EQUIPMENT_REMOVE = 'EQUIPMENT_REMOVE',
  EQUIPMENT_EDIT = 'EQUIPMENT_EDIT',
  TRACTOR_STATUS_CHANGE_AVAILABLE_TO_UNAVAILABLE = 'TRACTOR_STATUS_CHANGE_AVAILABLE_TO_UNAVAILABLE',
  TRACTOR_STATUS_CHANGE_UNAVAILABLE_TO_AVAILABLE = 'TRACTOR_STATUS_CHANGE_UNAVAILABLE_TO_AVAILABLE',
  TRACTOR_STATUS_CHANGE_ASSIGNED_TO_AVAILABLE = 'TRACTOR_STATUS_CHANGE_ASSIGNED_TO_AVAILABLE',
  TRACTOR_STATUS_CHANGE_AVAILABLE_TO_ASSIGNED = 'TRACTOR_STATUS_CHANGE_AVAILABLE_TO_ASSIGNED',
  TRAILER_STATUS_CHANGE_AVAILABLE_TO_UNAVAILABLE = 'TRAILER_STATUS_CHANGE_AVAILABLE_TO_UNAVAILABLE',
  TRAILER_STATUS_CHANGE_UNAVAILABLE_TO_AVAILABLE = 'TRAILER_STATUS_CHANGE_UNAVAILABLE_TO_AVAILABLE',
  TRAILER_STATUS_CHANGE_ASSIGNED_TO_AVAILABLE = 'TRAILER_STATUS_CHANGE_ASSIGNED_TO_AVAILABLE',
  TRAILER_STATUS_CHANGE_AVAILABLE_TO_ASSIGNED = 'TRAILER_STATUS_CHANGE_AVAILABLE_TO_ASSIGNED',
  USER_PAYMENT_TERMS_VIEW = 'USER_PAYMENT_TERMS_VIEW',
  USER_PAYMENT_TERM_ADD = 'USER_PAYMENT_TERM_ADD',
  USER_PAYMENT_TERM_REMOVE = 'USER_PAYMENT_TERM_REMOVE',
  USER_PAYMENT_TERM_EDIT = 'USER_PAYMENT_TERM_EDIT',
  INTEGRATIONS_ELD_VIEW = 'INTEGRATIONS_ELD_VIEW',
  INTEGRATIONS_ELD_ADD = 'INTEGRATIONS_ELD_ADD',
  INTEGRATIONS_ELD_REMOVE = 'INTEGRATIONS_ELD_REMOVE',
  INTEGRATIONS_ELD_EDIT = 'INTEGRATIONS_ELD_EDIT',
  INTEGRATIONS_LOADBOARD_VIEW = 'INTEGRATIONS_LOADBOARD_VIEW',
  INTEGRATIONS_LOADBOARD_ADD = 'INTEGRATIONS_LOADBOARD_ADD',
  INTEGRATIONS_LOADBOARD_REMOVE = 'INTEGRATIONS_LOADBOARD_REMOVE',
  INTEGRATIONS_LOADBOARD_EDIT = 'INTEGRATIONS_LOADBOARD_EDIT',
  INTEGRATIONS_ACCOUNTING_VIEW = 'INTEGRATIONS_ACCOUNTING_VIEW',
  INTEGRATIONS_ACCOUNTING_ADD = 'INTEGRATIONS_ACCOUNTING_ADD',
  INTEGRATIONS_ACCOUNTING_REMOVE = 'INTEGRATIONS_ACCOUNTING_REMOVE',
  INTEGRATIONS_ACCOUNTING_EDIT = 'INTEGRATIONS_ACCOUNTING_EDIT',
  INTEGRATIONS_FACTORING_VIEW = 'INTEGRATIONS_FACTORING_VIEW',
  INTEGRATIONS_FACTORING_ADD = 'INTEGRATIONS_FACTORING_ADD',
  INTEGRATIONS_FACTORING_REMOVE = 'INTEGRATIONS_FACTORING_REMOVE',
  INTEGRATIONS_FACTORING_EDIT = 'INTEGRATIONS_FACTORING_EDIT',
  PREFERENCES_VIEW = 'PREFERENCES_VIEW',
  PREFERENCE_ADD = 'PREFERENCE_ADD',
  PREFERENCE_REMOVE = 'PREFERENCE_REMOVE',
  PREFERENCE_EDIT = 'PREFERENCE_EDIT',
  DRIVER_GROUP_VIEW = 'DRIVER_GROUP_VIEW',
  ACCOUNT_SUMMARY_EXPENSE_VIEW = 'ACCOUNT_SUMMARY_EXPENSE_VIEW',
  QUICKBOOKS_VIEW = 'QUICKBOOKS_VIEW',
  IFTA_VIEW = 'IFTA_VIEW',
  BASIC_REPORTS_VIEW = 'BASIC_REPORTS_VIEW',
  TREND_ANALYSIS_REPORTS_VIEW = 'TREND_ANALYSIS_REPORTS_VIEW',
  ADVANCE_REPORTS_CREATE = 'ADVANCE_REPORTS_CREATE',
  DOCUMENTS_VIEW = 'DOCUMENTS_VIEW',
  DOCUMENT_ADD = 'DOCUMENT_ADD',
  DOCUMENT_REMOVE = 'DOCUMENT_REMOVE',
  DOCUMENT_EDIT = 'DOCUMENT_EDIT',
  IMPORT_DATA = 'IMPORT_DATA',
  DRIVER_GROUP_REMOVE = 'DRIVER_GROUP_REMOVE',
  DRIVER_GROUP_EDIT = 'DRIVER_GROUP_EDIT',
  DRIVER_GROUP_ADD = 'DRIVER_GROUP_ADD',
  USER_PROFILES_VIEW = 'USER_PROFILES_VIEW',
  USER_PROFILE_ADD = 'USER_PROFILE_ADD',
  USER_PROFILE_REMOVE = 'USER_PROFILE_REMOVE',
  USER_PROFILE_EDIT = 'USER_PROFILE_EDIT',
  LOAD_ID_EDIT = 'LOAD_ID_EDIT',
  QUICKBOOKS_EDIT = 'QUICKBOOKS_EDIT',
  IFTA_EDIT = 'IFTA_EDIT',
  EXPENSES_VIEW = 'EXPENSES_VIEW',
  EXPENSE_ADD = 'EXPENSE_ADD',
  EXPENSE_REMOVE = 'EXPENSE_REMOVE',
  EXPENSE_EDIT = 'EXPENSE_EDIT',
  EXPENSE_STATUS_CHANGE_INREVIEW_REVIEWED = 'EXPENSE_STATUS_CHANGE_INREVIEW_REVIEWED',
  EXPENSE_STATUS_CHANGE_REVIEWED_INREVIEW = 'EXPENSE_STATUS_CHANGE_REVIEWED_INREVIEW',
  LOADSEARCH_VIEW = 'LOADSEARCH_VIEW',
  MAINTENANCE_HISTORY_VIEW = 'MAINTENANCE_HISTORY_VIEW',
  MAINTENANCE_HISTORY_ADD = 'MAINTENANCE_HISTORY_ADD',
  MAINTENANCE_HISTORY_REMOVE = 'MAINTENANCE_HISTORY_REMOVE',
  MAINTENANCE_HISTORY_EDIT = 'MAINTENANCE_HISTORY_EDIT',
  VENDORS_VIEW = 'VENDORS_VIEW',
  VENDOR_ADD = 'VENDOR_ADD',
  VENDOR_REMOVE = 'VENDOR_REMOVE',
  VENDOR_EDIT = 'VENDOR_EDIT',
  COMMON_UPCOMING_EVENTS_VIEW = 'COMMON_UPCOMING_EVENTS_VIEW',
  COMMON_UPCOMING_EVENTS_ADD = 'COMMON_UPCOMING_EVENTS_ADD',
  COMMON_UPCOMING_EVENTS_REMOVE = 'COMMON_UPCOMING_EVENTS_REMOVE',
  COMMON_UPCOMING_EVENTS_EDIT = 'COMMON_UPCOMING_EVENTS_EDIT',
  CARGO_CHIEF_LIVE_LANE_RATES = 'CARGO_CHIEF_LIVE_LANE_RATES',
  OPTIMIZATION_EDIT = 'OPTIMIZATION_EDIT',
  OPTIMIZATION_EVALUATION_EDIT = 'OPTIMIZATION_EVALUATION_EDIT',
  CARRIER_VIEW = 'CARRIER_VIEW',
  CARRIER_ADD = 'CARRIER_ADD',
  CARRIER_REMOVE = 'CARRIER_REMOVE',
  CARRIER_EDIT = 'CARRIER_EDIT',
  EDI_TENDER = 'EDI_TENDER',
  LOAD_NOTES_EDIT = 'LOAD_NOTES_EDIT',
  LOAD_NOTES_REMOVE = 'LOAD_NOTES_REMOVE',
  ROLE_VIEW = 'ROLE_VIEW',
  ROLE_ADD = 'ROLE_ADD',
  ROLE_EDIT = 'ROLE_EDIT',
  ROLE_REMOVE = 'ROLE_REMOVE',
  LOADTENDER_EDI_VIEW = 'LOADTENDER_EDI_VIEW',
  LOADTENDER_TURVO_VIEW = 'LOADTENDER_TURVO_VIEW',
  LOADTENDER_TURVO_EDIT = 'LOADTENDER_TURVO_EDIT',
  LOADTENDER_TURVO_REMOVE = 'LOADTENDER_TURVO_REMOVE',
  INVOICE_ADD = 'INVOICE_ADD',
  INVOICE_EDIT = 'INVOICE_EDIT',
  INVOICE_DELETE = 'INVOICE_DELETE',
  PAYMENT_VIEW = 'PAYMENT_VIEW',
  PAYMENT_ADD = 'PAYMENT_ADD',
  PAYMENT_EDIT = 'PAYMENT_EDIT',
  PAYMENT_DELETE = 'PAYMENT_DELETE',
  TRACTOR_PAYMENT_PREFERENCE_EDIT = 'TRACTOR_PAYMENT_PREFERENCE_EDIT',

  TAGS_VIEW = 'TAGS_VIEW',
  TAGS_ADD = 'TAGS_ADD',
  TAGS_REMOVE = 'TAGS_REMOVE',
  TAGS_EDIT = 'TAGS_EDIT',

  LOAD_STATUS_CHANGE_COMPLETED_TO_READYTOINVOICE = 'LOAD_STATUS_CHANGE_COMPLETED_TO_READYTOINVOICE',
  LOAD_STATUS_CHANGE_READYTOINVOICE_TO_INVOICE = 'LOAD_STATUS_CHANGE_READYTOINVOICE_TO_INVOICE',
  LOAD_STATUS_CHANGE_INVOICED_TO_READYTOINVOICE = 'LOAD_STATUS_CHANGE_INVOICED_TO_READYTOINVOICE',
  LOAD_STATUS_CHANGE_READYTOINVOICE_TO_COMPLETED = 'LOAD_STATUS_CHANGE_READYTOINVOICE_TO_COMPLETED',
}

export enum AXELE_WEB_FEATURE_TYPE {
  CSID_PRIMARY_USER = 'CSID_PRIMARY_USER',
  CSID_DATA_RETENTION = 'CSID_DATA_RETENTION',
  CSID_LOADS = 'CSID_LOADS',
  CSID_QUOTES = 'CSID_QUOTES',
  CSID_DRIVERS = 'CSID_DRIVERS',
  CSID_DRIVERS_MAP_VIEW = 'CSID_DRIVERS_MAP_VIEW',
  CSID_TP_GENERATION = 'CSID_TP_GENERATION',
  CSID_MANUAL_HOS = 'CSID_MANUAL_HOS',
  CSID_CUSTOMERS = 'CSID_CUSTOMERS',
  CSID_LOCATIONS = 'CSID_LOCATIONS',
  CSID_ASSET_USERS = 'CSID_ASSET_USERS',
  CSID_ASSET_DRIVERS = 'CSID_ASSET_DRIVERS',
  CSID_ASSET_TRACTORS = 'CSID_ASSET_TRACTORS',
  CSID_ASSET_TRAILERS = 'CSID_ASSET_TRAILERS',
  CSID_INVOICES = 'CSID_INVOICES',
  CSID_DRIVER_SETTLEMENTS = 'CSID_DRIVER_SETTLEMENTS',
  CSID_DASHBOARD = 'CSID_DASHBOARD',
  CSID_LOAD_BOARDS = 'CSID_LOAD_BOARDS',
  CSID_LOAD_SELECTION = 'CSID_LOAD_SELECTION',
  CSID_TRUCK_POSTING = 'CSID_TRUCK_POSTING',
  CSID_QUOTE_DRIVER_EVALUATION = 'CSID_QUOTE_DRIVER_EVALUATION',
  CSID_LOAD_DRIVER_EVALUATION = 'CSID_LOAD_DRIVER_EVALUATION',
  CSID_ELDS = 'CSID_ELDS',
  CSID_WARNINGS = 'CSID_WARNINGS',
  CSID_DRIVER_PREFERENCES = 'CSID_DRIVER_PREFERENCES',
  CSID_DRIVER_TIMEOFF = 'CSID_DRIVER_TIMEOFF',
  CSID_BASIC_REPORTS = 'CSID_BASIC_REPORTS',
  CSID_TREND_ANALYSIS = 'CSID_TREND_ANALYSIS',
  CSID_TNT = 'CSID_TNT',
  CSID_DOCUMENTS = 'CSID_DOCUMENTS',
  CSID_IFTA = 'CSID_IFTA',
  CSID_SAFETY = 'CSID_SAFETY',
  CSID_PREVENTIVE_MAINTENANCE = 'CSID_PREVENTIVE_MAINTENANCE',
  CSID_QUICKBOOKS = 'CSID_QUICKBOOKS',
  CSID_EXPENSES = 'CSID_EXPENSES',
  CSID_LOAD_NOTE = 'CSID_LOAD_NOTE',
  CSID_MAINTENANCE_HISTORY = 'CSID_MAINTENANCE_HISTORY',
  CSID_FACTORING = 'CSID_FACTORING',
  CSID_VENDORS = 'CSID_VENDORS',
  TEST = 'TEST', //TODO: use for testing only,
}

export enum AXELE_ROLE_TYPE {
  ADMIN = 'AD',
  FINANCIAL_DISPATCHER = 'DI',
  DISPATCHER = 'NFD',
  DRIVER = 'DR',
  ACCOUNTANT = 'AC',
  POWER_DISPATCHER = 'PD',
  SUPPORT = 'SP',
}

export enum AXELE_USER_TYPES {
  ADMIN = 'ADMIN',
  OWNER_ADMIN = 'OWNER_ADMIN',
}
