import { QueryParams } from '../commonMixed';

export class GetManageTypesRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  itemIds?: number[];
  itemName?: string;
  type?: string;
}

export class DeleteManageTypeRequest extends QueryParams {
  id!: string;
}

export class CreateManageTypeRequest extends QueryParams {
  itemName!: string;
  description!: string | null;
  iconName!: string | null;
  createdBy!: string;
  deduction?: boolean;
  settlementItem?: boolean;
  invoiceItem?: boolean;
  unit?: string[] | null;
}

export class UpdateManageTypeRequest extends QueryParams {
  itemName!: string;
  description!: string | null;
  iconName!: string | null;
  createdBy!: string;
  deduction?: boolean;
  settlementItem?: boolean;
  invoiceItem?: boolean;
  unit?: string[] | null;
  id!: string;
}

export type ManageTypesItem = {
  id: string;
  itemName: string;
  itemCode?: string;
  itemType?: string;
  description: string;
  custom: boolean;
  iconName: string | null;
  createdBy: string | null;
  createDate: string | null;
  deduction: boolean;
  invoiceItem?: boolean;
  settlementItem?: boolean;
  unit?: string[] | null;
};

export type CreateManageFormDataType = {
  itemName: string;
  description: string;
  iconName: string;
  unit?: string[] | UnitType[] | null;
  deduction?: boolean;
  settlementItem?: boolean;
  invoiceItem?: boolean;
};

export type UpdateManageFormDataType = {
  itemName: string;
  description: string;
  iconName: string;
  unit?: string[] | UnitType[] | null;
  deduction?: boolean;
  settlementItem?: boolean;
  invoiceItem?: boolean;
  id: string;
};

export type UnitType = {
  value: string;
  label: string;
};
