import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { TSetData, setData } from '../../utils';

// Role helper
import {
  getRoleDetailsData,
  getRolesListData,
} from '../../../views/settings/roles/constants/helper';
// End Helper

import { RolesResponseDTO, AddUpdateRoleDTO } from '../../../models/DTOs';

class RolesStore {
  @observable component = 'Roles';
  @observable RoleList: RolesResponseDTO[] = [];
  @observable SelectedRole: any = {};

  constructor() {
    makeObservable(this);
  }

  @action
  getRoles = async () => {
    try {
      const getRes = await getRolesListData();
      runInAction(() => {
        if (getRes) {
          this.RoleList = getRes?.data as RolesResponseDTO[];
        }
      });
    } catch (e) {}
  };

  @action
  addNewRole = (data: RolesResponseDTO) => {
    if (data) {
      const newRoleList = [...this.RoleList, data];
      this.RoleList = newRoleList;
    }
  };

  @action
  updateMetaData = (data: RolesResponseDTO) => {
    const newRoleList = [...this.RoleList];
    const idx = newRoleList.findIndex(
      (d: RolesResponseDTO) => d.code == data.code
    );
    if (idx) {
      const newRole = {
        ...this.RoleList[idx],
        name: data.name,
        description: data.description,
      };
      newRoleList[idx] = newRole;
      this.RoleList = newRoleList;
    }
  };

  @action
  updateRole = (data: RolesResponseDTO) => {
    const newRoleList = [...this.RoleList];
    const idx = newRoleList.findIndex(
      (d: RolesResponseDTO) => d.code == data.code
    );
    if (idx) {
      newRoleList[idx] = data;
      this.RoleList = newRoleList;
    }
  };
  @action
  deleteRole = (code: string) => {
    const newRoleList = [...this.RoleList];
    const idx = newRoleList.findIndex((d: RolesResponseDTO) => d.code == code);
    if (idx) {
      newRoleList.splice(idx, 1);
      this.RoleList = newRoleList;
    }
  };

  @action
  getRoleDetailById = async (
    isIncludePrimaryTerminal = true,
    terminalIds: string[] = []
  ) => {
    try {
      const getRes = await getRoleDetailsData(
        isIncludePrimaryTerminal,
        terminalIds
      );
      runInAction(() => {});
    } catch (e) {}
  };

  @computed
  get getRoleList(): RolesResponseDTO[] {
    return toJS(this.RoleList);
  }

  @action
  setSelectedItem = (newValue: any) => {
    this.SelectedRole = setData(newValue, this.SelectedRole);
  };
}

export function createRolesStore() {
  return new RolesStore();
}
