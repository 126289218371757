import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  IconButton,
  List,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React from 'react';
import { paymentDetailsType } from './FinanceOverviewModel';
import { AddFinanceExpenseLoader } from './FinanceOverviewStyle';

interface IAddTripPayment {
  options?: Array<paymentDetailsType>;
  onSelect?: (data?: paymentDetailsType) => void;
  showLoader?: boolean;
}

const AddTripPaymentPopOver: React.FC<IAddTripPayment> = ({
  options,
  onSelect,
  showLoader,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!options?.length) return <></>;

  return (
    <Box ml="auto">
      <IconButton
        aria-describedby={'trip-payment-popover-btn'}
        onClick={handleClick}
        sx={{
          ':hover *': { textDecoration: 'underline' },
          ':hover': { background: 'none' },
        }}
      >
        <Typography variant="caption">Add Payment</Typography>
        <ArrowDropDownIcon color="primary" />
      </IconButton>
      <Menu
        id="trip-payment-popover"
        aria-labelledby="trip-payment-popover"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ '.MuiPaper-root': { borderRadius: '8px' } }}
      >
        <List>
          {options?.map((item) => (
            <MenuItem
              key={item?.id}
              onClick={() => onSelect?.(item)}
              sx={showLoader ? AddFinanceExpenseLoader : {}}
            >
              {item?.type + ' ' + item?.name}
            </MenuItem>
          ))}
        </List>
      </Menu>
    </Box>
  );
};

export default AddTripPaymentPopOver;
