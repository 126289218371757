import { Service } from './Service';
import {
  CancelImportRequest,
  ChangeCellValueRequest,
  DownloadImportedFileRequest,
  GenerateFinalDataRequest,
  GetColumnsMappingRequest,
  GetImportedDataRequest,
  ExtractColumnsMappingRequest,
  SaveColumnsMappingRequest,
  GetMissingEntitiesRequest,
  CreateMissingEntitiesRequest,
  GetCustomerStaticRequest,
  FinishImportRequest,
  GetFactoringStaticRequest,
  GetCommodityTypesRequest,
  ApplyMissingEntitiesRequest,
  GetDriverStaticRequest,
  ChangeAddressCellValueRequest,
  ExtractAndCreateRequest,
} from '../../models';
import { FileType } from '../../types';
import { ServiceError } from './helperTypes';
import { docType } from '../../utils/Doc';

export abstract class IImportService extends Service {
  abstract importFile(
    file: FileType,
    entityName: string,
    dictionary: string,
    dateFormat: string,
    fileType: string,
    deductionType: string
  ): Promise<
    | {
        content: any;
        warnings: { key: string; message: string }[];
        key: string;
        totalRows: number;
      }
    | ServiceError
  >;

  abstract cancelImport(
    requestData: CancelImportRequest,
    entityName: string,
    dictionary: string
  ): Promise<{ message: string } | ServiceError>;

  abstract extractAndCreate(
    requestData: ExtractAndCreateRequest,
    entityName: string,
    dictionary: string
  ): Promise<
    | {
        extractedRowsCount: number;
        warningsCount: number;
        errorsCount: number;
        ignoredColumnsCount: number;
        totalRowsCount: number;
      }
    | ServiceError
  >;

  abstract getColumnsMapping(
    requestData: GetColumnsMappingRequest,
    entityName: string,
    dictionary: string
  ): Promise<
    | {
        importColumnMapping: {
          destinationColumn: string;
          mandatory: boolean;
          sourceColumn: string;
          sourceColumnCode: string;
        }[];
      }
    | ServiceError
  >;

  abstract extractColumnsMapping(
    requestData: ExtractColumnsMappingRequest,
    entityName: string,
    dictionary: string,
    bodyParams: {
      importColumnMapping: Partial<{ [p: string]: string | null }>;
      key: string;
    }
  ): Promise<{ message: string } | ServiceError>;

  abstract saveColumnsMapping(
    requestData: SaveColumnsMappingRequest,
    entityName: string,
    dictionary: string,
    bodyParams: {
      importColumnMapping: Partial<{ [key: string]: string | null }>;
      key: string;
    }
  ): Promise<{ message: string } | ServiceError>;

  abstract getImportedData(
    requestData: GetImportedDataRequest,
    entityName: string,
    dictionary: string
  ): Promise<any | ServiceError>;

  abstract downloadImportedFile(
    requestData: DownloadImportedFileRequest,
    entityName: string,
    dictionary: string
  ): Promise<docType | ServiceError>;

  abstract changeCellValue(
    requestData: ChangeCellValueRequest,
    entityName: string,
    dictionary: string,
    cellData: Partial<{ [key: string]: any }>
  ): Promise<any | ServiceError>;

  abstract changeAddressCellValue(
    requestData: ChangeAddressCellValueRequest,
    entityName: string,
    dictionary: string,
    cellData: Partial<{ [key: string]: any }>
  ): Promise<any | ServiceError>;

  abstract generateFinalData(
    requestData: GenerateFinalDataRequest,
    entityName: string,
    dictionary: string
  ): Promise<
    | {
        extractedRowsCount: number;
        warningsCount: number;
        errorsCount: number;
        ignoredColumnsCount: number;
        totalRowsCount: number;
      }
    | ServiceError
  >;

  abstract getMissingEntities(
    requestData: GetMissingEntitiesRequest,
    entityName: string,
    dictionary: string
  ): Promise<
    { [key: string]: { [key: string]: string | number } } | ServiceError
  >;

  abstract createMissingEntities(
    requestData: CreateMissingEntitiesRequest,
    entityName: string,
    dictionary: string,
    bodyParams: any
  ): Promise<boolean | ServiceError>;

  abstract applyMissingEntities(
    requestData: ApplyMissingEntitiesRequest,
    entityName: string,
    dictionary: string,
    bodyParams: any
  ): Promise<boolean | ServiceError>;

  abstract getCustomerStatic(
    requestData: GetCustomerStaticRequest
  ): Promise<any | ServiceError>;

  abstract getDriverStatic(
    requestData: GetDriverStaticRequest
  ): Promise<any | ServiceError>;

  abstract getFactoringStatic(
    requestData: GetFactoringStaticRequest
  ): Promise<any | ServiceError>;

  abstract getCommodityTypes(
    requestData: GetCommodityTypesRequest
  ): Promise<any | ServiceError>;

  abstract finishImport(
    requestData: FinishImportRequest,
    entityName: string,
    dictionary: string
  ): Promise<{ message: string } | ServiceError>;
}
