import { GridColDefSelf } from '../../types';
import { getLoadOriginDestinations } from '../invoices/utils';
import LoadController from '../loadsList/LoadController';
import { ECreateLoadPanelModes } from '../loadsList/LoadDetailsPanel/constants/constants';
import TemplateController from './TemplatesController';
import MenuActions from '../../common/MenuActions';
import { IMenuActions } from '../../common/MenuActions/types';
import { AXELE_PERMISSION_TYPE } from '../../common/Permission';
import { TemplatesConstants } from '../../locales/en/allLoads/templates';

export const templateColumnsInfo = {
  templateName: true,
  customerName: true,
  fromLocationName: true,
  toLocationName: true,
  equipmentTypes: true,
  terminal: true,
  action: true,
};

export const deleteTemplateText = {
  singleDelete: {
    title: TemplatesConstants.deleteTemplate + '?',
    subTitle: TemplatesConstants.deleteTemplateSubTitle + '?',
  },
  bulkDelete: {
    title: TemplatesConstants.deleteTemplates + '?',
    subTitle: TemplatesConstants.deleteTemplatesSubTitle + '?',
  },
};

export const templateActions = [
  {
    id: ECreateLoadPanelModes.EDIT_TEMPLATE,
    label: TemplatesConstants.viewEdit,
  },
  {
    id: ECreateLoadPanelModes.CREATE_LOAD,
    label: TemplatesConstants.createLoad,
    permissions: [AXELE_PERMISSION_TYPE.LOAD_ADD],
  },
  {
    id: ECreateLoadPanelModes.CREATE_TEMPLATE,
    label: TemplatesConstants.duplicate,
    permissions: [AXELE_PERMISSION_TYPE.LOAD_ADD],
  },
  {
    id: 'DELETE',
    label: TemplatesConstants.delete,
    permissions: [AXELE_PERMISSION_TYPE.LOAD_REMOVE],
  },
];

export const columns = (actionsMenuProps: IMenuActions): GridColDefSelf[] => [
  {
    flex: 1,
    minWidth: 135,
    field: 'templateName',
    permanent: true,
    headerName: TemplatesConstants.templateName,
    sortable: false,
  },
  {
    flex: 1,
    minWidth: 150,
    field: 'customerName',
    headerName: TemplatesConstants.customer,
    isHyperLink: false,
    sortable: false,
    // renderCell: (params) => <HyperLink value={params.value} />, should be uncommented
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'fromLocationName',
    headerName: TemplatesConstants.origin,
    sortable: false,
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'toLocationName',
    headerName: TemplatesConstants.destination,
    sortable: false,
  },
  {
    flex: 1,
    minWidth: 100,
    field: 'equipmentTypes',
    headerName: TemplatesConstants.requiredEquipmentType,
    sortable: false,
  },
  {
    flex: 1,
    field: 'terminal',
    minWidth: 130,
    headerName: TemplatesConstants.invoicingTerminal,
    sortable: false,
    valueGetter: (params) => params.value?.name,
  },
  {
    flex: 1,
    field: 'action',
    headerName: TemplatesConstants.actions,
    sortable: false,
    permanent: true,
    renderCell: (params: { row: any }) => (
      <MenuActions {...actionsMenuProps} entityData={params.row} />
    ),
  },
];

export const templateFiltersFormConfig = [
  {
    key: 1,
    name: 'templateNameFilter',
    fieldName: 'templateName',
    getData: TemplateController.instance().templateNameFilter,
    label: TemplatesConstants.templateName,
    type: 'MultipleAutocomplete',
    default: true,
  },
  {
    key: 2,
    name: 'customerFilter',
    fieldName: 'value',
    getData: LoadController.instance().getCustomerForFilter,
    label: TemplatesConstants.customer,
    type: 'MultipleAutocomplete',
    default: true,
  },
  {
    key: 3,
    name: 'originFilter',
    fieldName: 'name',
    getData: getLoadOriginDestinations,
    label: TemplatesConstants.origin,
    type: 'MultipleAutocomplete',
    default: true,
  },
  {
    key: 4,
    name: 'destinationFilter',
    fieldName: 'name',
    getData: getLoadOriginDestinations,
    label: TemplatesConstants.destination,
    type: 'MultipleAutocomplete',
    default: true,
  },
];

export const templateFilters = {
  templateNameFilter: [],
  customerFilter: [],
  originFilter: [],
  destinationFilter: [],
};
