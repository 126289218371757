import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { Box, Grid, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import ThreeDotMenuIcon from '@/ui-kit/components/ThreeDotMenuIcon';
import MultilineCellWithIcon from '@/ui-kit/components/MultilineCellWithIcon';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { AccountInfoItem } from '@/views/settings/integrations/FuelCard/components/AccountInfoItem';
import { useCardItem } from '@/views/settings/integrations/FuelCard/hooks';
import DeletePopup from '@/ui-kit/components/DeletePopup';
import { FuelCardAccount, Provider } from '@/models/DTOs/FuelCards/FuelCards';
import DetailsPanel from '@/common/DetailsPanel';
import { TransactionRecords } from '@/views/settings/integrations/FuelCard/components/TransactionRecords';
import { transactionRecordsFormValidationSchema } from '@/views/settings/integrations/FuelCard/constants';

const titleStyle = { color: 'primary.main', fontSize: 20, fontWeight: 500 };
const subtitleStyle = {
  color: 'text.secondary',
  letterSpacing: '0.15px',
  lineHeight: '10px',
};
const successIconStyle = { color: 'success.main', marginRight: '9px' };
const warningIconStyle = { color: 'error.main', marginRight: '9px' };

interface Props {
  account: FuelCardAccount;
  onAccountDelete: (id: number) => void;
  selectedProvider: Provider;
  onDrawerOpen: () => void;
  getAccounts: () => void;
  onSelectedProviderAccountChange: (account: FuelCardAccount) => void;
}

export const CardItem: FC<Props> = ({
  account,
  onAccountDelete,
  selectedProvider,
  onDrawerOpen,
  onSelectedProviderAccountChange,
  getAccounts,
}) => {
  const {
    showDeletePopup,
    toggleShowDeletePopup,
    anchorElement,
    handleMenuItemClick,
    handleMenuClose,
    handleMenuClick,
    handleDeleteAccount,
    showSyncModal,
    createExpenses,
    handleImportKeyChange,
    syncModalClose,
    rePullData,
    handleRePullDataChange,
  } = useCardItem(
    onAccountDelete,
    account,
    selectedProvider,
    onDrawerOpen,
    onSelectedProviderAccountChange,
    getAccounts
  );

  return (
    <>
      <Paper
        className="account"
        sx={{
          padding: '16px 34px 16px 18px',
          width: '48%',
          marginBottom: '32px',
          minHeight: '270px',
        }}
      >
        <Box
          className="top-panel"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Box className="left">
            <MultilineCellWithIcon
              title={account.data.DISPLAY_NAME}
              subtitle={
                account.lastUpdatedDate
                  ? `Last Updated On ${moment(account.lastUpdatedDate).format(
                      'MMM DD, YYYY, hh:mm A'
                    )}`
                  : ''
              }
              titleStyle={titleStyle}
              subtitleStyle={subtitleStyle}
              icon={CheckCircleOutlineOutlinedIcon}
              iconStyle={account.isValid ? successIconStyle : warningIconStyle}
            />
          </Box>
          <Box className="right">
            <ThreeDotMenuIcon
              menuOptions={[
                {
                  label: 'Sync Transaction',
                  iconName: 'CurrencyExchangeOutlined',
                  source: 'MUIcons',
                },
                {
                  label: 'Edit',
                  iconName: 'EditOutlined',
                  source: 'MUIcons',
                },
                {
                  label: 'Delete',
                  iconName: 'DeleteOutlined',
                  source: 'MUIcons',
                },
              ]}
              open={!!anchorElement}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              anchorEl={anchorElement}
              handleMenuItemClick={handleMenuItemClick}
            />
          </Box>
        </Box>
        <Box
          className="bottom-panel"
          sx={{ marginTop: '16px', paddingTop: '17px' }}
        >
          <Grid container sx={{ flexDirection: 'column' }}>
            <Box className="account-card-info">
              <AccountInfoItem
                value={account.data.USERNAME}
                key="Username"
                icon={AccountBoxOutlinedIcon}
                title="Username"
              />
              {account.data.BEG_DATE && account.data.END_DATE && (
                <AccountInfoItem
                  value={`${moment(account.data.BEG_DATE).format(
                    'MM/DD/YYYY'
                  )} - ${moment(account.data.END_DATE).format('MM/DD/YYYY')}`}
                  key="Last Sync Period"
                  title="Last Sync Period"
                  icon={DateRangeOutlinedIcon}
                />
              )}
            </Box>
          </Grid>
        </Box>
      </Paper>
      <DeletePopup
        open={showDeletePopup}
        onClose={toggleShowDeletePopup}
        onAction={handleDeleteAccount}
        title={'Delete Account ?'}
        body={'Are you sure you want to delete the provider?'}
        subtitle={
          'Please note that all related information will be permanently lost.'
        }
        buttonText={'Delete'}
        width="20%"
      />
      {showSyncModal && (
        <DetailsPanel
          hideExpandIcon
          autoExpanded
          actionButtonLabel="Create As Expense"
          panelTitle="Sync fuel transactions"
          onClose={syncModalClose}
          validationSchema={transactionRecordsFormValidationSchema}
          onCreate={(data) => createExpenses(data)}
          contentRenderer={() => (
            <TransactionRecords
              account={account}
              handleImportKeyChange={handleImportKeyChange}
              rePullData={rePullData}
              handleRePullDataChange={handleRePullDataChange}
            />
          )}
        />
      )}
    </>
  );
};
