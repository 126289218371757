import { Label } from './label';
import { ToolTips } from './tooltips';
import { Warning } from './warning';

export const DispatchConstants = {
  hosInformation: 'HoS Information',
  hosClock: 'HoS Clock at Gap Start',
  driverHosLocationRequired: 'Driver HOS or Location is not provided',
  sleeperBearthProvision: 'Use Sleeper Berth Provision',
  ignoreHosRestrictions: 'Ignore HOS Restrictions',
  dvirPreference: 'DVIR Preference',
  workWeekType: 'Work Week Type',
  preferredTruckstops: 'Preferred Truck stops',
  restoreCompanyDefaults: 'Restore Company Defaults',
  driveTime: 'Drive Time',
  addLoad: 'Add Load',

  LoadStatusesConstantsAvailable: 'Available',
  cancelTripAssignment: 'Cancel Trip Assignment',
  Areyousureyouwanttocanceltripassignment:
    'Are you sure you want to cancel trip assignment?',

  ...ToolTips,
  ...Warning,
  ...Label,
};
