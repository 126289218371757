import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import IntegrationFormContent from '../../../../../ui-kit/components/IntegrationFormContent';

import { sideDrawerDisplayData } from '../constants';
import { CustomerFeaturesDTO } from '../../../../../models';
import TerminalController from '../../../terminals/TerminalController';

export default function SidedrawerContent({
  currentCustomerData,
  open,
  toggleDrawer,
  providerData,
  validateFormCreds,
  allTerminals,
  dataToUpdate,
  activationErrMessage,
  updateAccountCard,
  formErr,
  authenticateOAuth,
}: any) {
  const [integrationCredentials, setIntegrationCredentials] = useState([]);
  const { providerId } = currentCustomerData;

  const { terminals } = TerminalController.instance();

  useEffect(() => {
    getCustomerIntegrationData();
  }, [providerId]);

  const getCustomerIntegrationData = (): void => {
    const currentCustomerRequiredCredentials =
      providerData?.integrationRequiredCredentials?.filter(
        (item: CustomerFeaturesDTO) =>
          item.id === currentCustomerData.providerId
      );
    currentCustomerRequiredCredentials &&
      currentCustomerRequiredCredentials.length > 0 &&
      setIntegrationCredentials(currentCustomerRequiredCredentials[0]?.fields);
  };

  sideDrawerDisplayData.fields = integrationCredentials;

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(true)}
      onOpen={toggleDrawer(true)}
      disableSwipeToOpen={false}
      sx={{
        '.MuiPaper-root': {
          width: '462px',
          height: '90%',
          bottom: '5px',
          top: 'unset',
          padding: '25px 40px 10px 32px',
          boxShadow:
            '0px 5px 6px -3px rgb(0 0 0 / 20%), 0px 9px 12px 1px rgb(0 0 0 / 14%), 0px 3px 16px 2px rgb(0 0 0 / 12%)',
          borderRadius: '16px 0px 0px 16px',
        },
      }}
    >
      <Box className="drawer-content">
        <Box
          onClick={toggleDrawer(false)}
          sx={{ float: 'right', cursor: 'pointer' }}
        >
          <CloseOutlinedIcon />
        </Box>
        <Box className="provider-logo" sx={{ pt: '25px' }}>
          {currentCustomerData && currentCustomerData.logo}
        </Box>
        {open && (
          <IntegrationFormContent
            sideDrawerDisplayData={sideDrawerDisplayData}
            currentCustomerData={currentCustomerData}
            validateFormCreds={validateFormCreds}
            closeDrawer={toggleDrawer(false)}
            dataToUpdate={dataToUpdate}
            activationErrMessage={activationErrMessage}
            updateAccountCard={updateAccountCard}
            formErr={formErr}
            authenticateOAuth={authenticateOAuth}
            getTerminalOptions={terminals}
            integrationType={'ACCOUNTING'}
          />
        )}
      </Box>
    </SwipeableDrawer>
  );
}
