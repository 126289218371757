import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import { ISelectedCustomerTypes } from '../../../../../models/DTOs/Factoring/Factoring';
import FactorAddForm from './FactorAddForm';
import {
  integrationFormFieldLabelText,
  prepareFormDataFactor,
} from './FactoreUtil';
const SwipeableDrawerStyle = {
  '.MuiPaper-root': {
    width: '462px',
    height: '90%',
    bottom: '5px',
    top: 'unset',
    padding: '25px 40px 10px 32px',
    boxShadow:
      '0px 5px 6px -3px rgb(0 0 0 / 20%), 0px 9px 12px 1px rgb(0 0 0 / 14%), 0px 3px 16px 2px rgb(0 0 0 / 12%)',
    borderRadius: '16px 0px 0px 16px',
  },
};
export default function SidedrawerContent({
  currentCustomerData,
  open,
  toggleDrawer,
  isEditForm,
  selectedCardData,
  connectAuth,
}: ISelectedCustomerTypes) {
  let formData = {};
  const closeSideModel = (value: boolean) => {
    toggleDrawer(value);
  };
  if (isEditForm) {
    formData = prepareFormDataFactor(selectedCardData?.data);
  }
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={() => closeSideModel(false)}
      onOpen={() => closeSideModel(true)}
      disableSwipeToOpen={false}
      sx={SwipeableDrawerStyle}
    >
      <Box className="drawer-content">
        <Box
          onClick={() => closeSideModel(false)}
          sx={{ float: 'right', cursor: 'pointer' }}
        >
          <CloseOutlinedIcon />
        </Box>
        <Box className="provider-logo" sx={{ pt: '25px' }}>
          {currentCustomerData && currentCustomerData.logo}
        </Box>

        {open && (
          <FactorAddForm
            selectType={
              integrationFormFieldLabelText[currentCustomerData?.providerId]
            }
            isEditForm={isEditForm}
            formData={formData}
            selectedCardData={selectedCardData}
            connectAuth={connectAuth}
            closeDrawer={toggleDrawer}
          />
        )}
      </Box>
    </SwipeableDrawer>
  );
}
