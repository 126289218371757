import { SxProps, Theme } from '@mui/material';

export const getLoadboardPickerStyles = ({
  isFormControl,
  theme,
  width,
}: {
  theme: Theme;
  isFormControl?: boolean;
  width: string;
}): { [key: string]: SxProps } => {
  return {
    Button: {
      width,
      padding: '5px 8px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textTransform: 'none',
      color: isFormControl ? 'text.primary' : 'primary.main',
      borderColor: isFormControl ? 'text.primary' : 'primary.main',
      borderRadius: isFormControl ? 0 : '6px',
      borderWidth: isFormControl ? '0 0 1px 0px' : '1px',
      '& .MuiOutlinedInput-input': {
        color: !isFormControl && 'rgba(43, 100, 203, 1)',
      },
      '&:hover': {
        borderWidth: isFormControl ? '0 0 1px 0px' : '1px',
      },
      ...(isFormControl && {
        paddingLeft: 0,
        width: '100%',
      }),
      position: 'relative',
      height: '40px',
    },
    DisplayName: {
      width: '100%',
      maxWidth: '270px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      ...theme.typography?.buttonSmall,
      ...(isFormControl && {
        textAlign: 'left',
        maxWidth: 'unset',
        position: 'absolute',
        paddingLeft: '8px',
      }),
    },
    Menu: {
      '& .MuiPaper-root': {
        minWidth: 250,
        width,
        ...(isFormControl && {
          // width: '100%',
          width: 270,
        }),
        maxHeight: '100vh',
        overflowY: 'auto',
      },
    },
  };
};
