import { useGridApiContext } from '@mui/x-data-grid-pro';
import { FormAddress } from '../../common/Ui/AddressField';
import { ContractValidators } from '../contract/validators';
import { useState } from 'react';

const EditableAutocompleteCell = (props: any) => {
  const apiRef = useGridApiContext();
  const { params } = props;
  const [err, setErr] = useState('');
  return (
    <>
      <FormAddress
        showOnlyState={true}
        {...props}
        focused={(params.cellMode = 'Edit')}
        style={{ fontSize: '12px' }}
        label=""
        textInputSxProps={{
          error: Boolean(err),
          helperText: { err },
        }}
        onChangeCallback={(value: any) => {
          apiRef.current.setEditCellValue({
            id: params.row.id,
            value: value,
            field: params.field,
            debounceMs: 300,
          });
          apiRef.current.commitCellChange({
            id: params.row.id,
            field: params.field,
          });
          apiRef.current.setCellMode(params?.row?.id, params?.field, 'view');
        }}
      />
    </>
  );
};

export default EditableAutocompleteCell;
