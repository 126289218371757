import { Grid, Typography } from '@mui/material';
import React from 'react';
import { NumberToCurrency } from '../../../../constants/numberToCurrency';
import { ICalculatorBreakdownProps } from '../../loadTypes';

export const costToCurrency = (cost: number): string => {
  if (cost > 0) {
    return `$${cost}`;
  } else {
    return `-$${Math.abs(cost)}`;
  }
};

export const CalculatorBreakdown = ({ data }: ICalculatorBreakdownProps) => {
  return (
    <Grid
      container
      sx={{
        justifyContent: 'space-between',
        pb: '8px',
      }}
    >
      <Grid item>
        <Typography
          variant={'subtitle1'}
          sx={{
            color: 'text.primary',
          }}
        >
          {data.label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant={'subtitle1'}
          sx={{
            fontWeight: '600',
            ...(Number(data.value) > 0
              ? { color: 'tabStrip.sidePanelTabText' }
              : { color: 'error.main' }),
          }}
        >
          {NumberToCurrency(Number(data.value))}
        </Typography>
      </Grid>
    </Grid>
  );
};
