import { MaintenanceHistory } from '../../../models/DTOs/maintenance/maintenanceHistory/Model';
import {
  CreateMaintenanceHistoryRequest,
  DeleteBulkMaintenanceHistoryRequest,
  ExportMaintenanceHistoryFilterPayload,
  ExportMaintenanceHistoryFilterQueryParams,
  GetMaintenanceHistoryListCalendarQueryParams,
  MaintenanceHistoryListTotalRequest,
  PaginatedMaintenanceHistoryListRequest,
  UpdateMaintenanceHistoryRequest,
} from '../../../models/DTOs/maintenance/maintenanceHistory/Requests';
import { PaginatedResult } from '../../../types';
import { docType } from '../../../utils/Doc';
import { ServiceError } from '../helperTypes';
import { Service } from '../Service';

export abstract class IMaintenanceHistoryService extends Service {
  abstract getPaginatedList(
    requestData: PaginatedMaintenanceHistoryListRequest
  ): Promise<PaginatedResult<MaintenanceHistory> | ServiceError>;

  abstract getMaintenanceHistoryListTotal(
    requestData: MaintenanceHistoryListTotalRequest
  ): Promise<number | ServiceError>;

  abstract getMaintenanceHistoryListCalendar(
    requestData: GetMaintenanceHistoryListCalendarQueryParams
  ): Promise<MaintenanceHistory[] | ServiceError>;

  abstract create(
    requestData: CreateMaintenanceHistoryRequest
  ): Promise<MaintenanceHistory | ServiceError>;

  abstract update(
    requestData: UpdateMaintenanceHistoryRequest
  ): Promise<MaintenanceHistory | ServiceError>;

  abstract buckDelete(
    requestData: DeleteBulkMaintenanceHistoryRequest
  ): Promise<void | ServiceError>;

  abstract exportToExcel(
    queryParams: ExportMaintenanceHistoryFilterQueryParams,
    payload: ExportMaintenanceHistoryFilterPayload
  ): Promise<docType | ServiceError>;
}
