import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import TerminalController from '../../../views/settings/terminals/TerminalController';
import TractorController from '../../tractors/Controller';
import { Trailer } from '../models/Trailer';
import { TrailerSummary } from '../models/TrailerSummary';
import TrailerController from '../TrailerController';
import AlertsTabDetails from './AlertsTabDetails';
import GeneralTabDetails from './GeneralTabDetails';
import { WrapperMaintenanceTabDetails } from './WrapperMaintenanceTabDetails';
import Documents from '@/views/reports/documents/sections/EntityDocumentsSection';
import { EntityNotes } from '@/common/EntityNotes';

export async function getStaticDataTrailerDetails() {
  const controller = TractorController.instance();
  const trailerController = TrailerController.instance();

  await Promise.all([
    controller.getYearOptions(),
    controller.getStateOptions(),
    controller.getOwnershipTypeOptions(),
    TerminalController.instance().getTerminals(),
    trailerController.getTrailerTypeOptions(),
    trailerController.getDefaultTrailersTypesList(),
    trailerController.getDefaultTrailersTypesDictionary(),
  ]);
}
export interface TrailerDetailsFormProps {
  expanded: boolean;
  selectedTab: string;
  onAssign: (driverGroupId: string) => void;
  data: TrailerSummary | Trailer;
  setOpenDialog: React.Dispatch<
    React.SetStateAction<'' | 'markAvailable' | 'markUnavailable' | 'delete'>
  >;
  setShowPopup: React.Dispatch<
    React.SetStateAction<
      | {
          allow?: boolean | undefined;
          body?: string | undefined;
        }
      | null
      | undefined
    >
  >;
  onUpdateWarningsCount: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
}

export default function TrailerDetailsForm({
  expanded,
  selectedTab,
  onUpdateWarningsCount,
  onAssign,
  data,
  setOpenDialog,
  setShowPopup,
}: TrailerDetailsFormProps) {
  const { control } = useFormContext();

  return (
    <Fragment>
      {selectedTab === 'GENERAL' && (
        <GeneralTabDetails
          expanded={expanded}
          control={control}
          onAssign={onAssign}
          data={data}
          setOpenDialog={setOpenDialog}
          setShowPopup={setShowPopup}
        />
      )}
      {selectedTab === 'ALERTS' && (
        <AlertsTabDetails onUpdateWarningsCount={onUpdateWarningsCount} />
      )}
      {selectedTab === 'NOTES' && (
        <EntityNotes entityId={data.id} entityType="TRAILER" />
      )}
      {selectedTab === 'MAINTENANCE' && <WrapperMaintenanceTabDetails />}
      {selectedTab === 'DOCUMENT' && (
        <Documents
          hideShareWithDriver
          data={{
            id: data.id,
            entity: 'TRAILER',
            entityObject: {
              id: data.id,
              name: `${data.trailerName}`,
            },
            terminal: null,
          }}
        />
      )}
    </Fragment>
  );
}
