import { AutocompleteRenderInputParams, SxProps, Theme } from '@mui/material';
import Autocomplete from '../../../ui-kit/components/Autocomplete';
import React from 'react';

export interface AutoCompleteFieldProps {
  id?: string;
  multiple?: boolean;
  options: Array<Record<string, unknown>>;
  label?: React.ReactNode;
  variant?: 'standard' | 'filled' | 'outlined';
  disabled?: boolean;
  error?: boolean;
  styleProps?: SxProps<Theme>;
  addCustom?: boolean;
  startAdornment?: boolean;
  defaultValue?: unknown;
  required?: boolean;
  onChange: (data: unknown) => void;
  helperText?: React.ReactNode;
  disableClearable?: boolean;
  onOpen?: unknown;
  onInputChange?: unknown;
  limitTags?: number;
  size?: 'small' | 'medium';
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

export const AutoCompleteField = ({
  // label,
  defaultValue,
  id,
  multiple = false,
  options,
  styleProps = {},
  limitTags = -1,
  variant,
  size = 'small',
  onChange,
  disabled = false,
  ...restProps
}: AutoCompleteFieldProps) => {
  return (
    <Autocomplete
      {...restProps}
      id={id}
      disabled={disabled}
      multiple={multiple}
      options={options}
      defaultValue={defaultValue}
      styleProps={styleProps}
      variant={variant}
      limitTags={limitTags}
      size={size}
      isOptionEqualToValue={(option: any, value: any) =>
        value && option['label'] === value['label']
      }
      onChange={(_event: unknown, data: unknown) => {
        onChange(data);
      }}
    />
  );
};
