/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  PaginatedVendorsList,
  PaginatedVendorsListRequest,
  CreateVendorRequest,
  UpdateVendorRequest,
  DeleteVendorRequest,
  VendorSummaryDTO,
  ContactSummary,
  CreateVendorContactRequest,
  CreateVendorContactRequestParams,
  GetVendorFiltersRequest,
  GetVendorListExcelRequest,
} from '../../models';
import { Service } from './Service';

export abstract class IVendorService extends Service {
  abstract getPaginatedVendorsList(
    requestData: PaginatedVendorsListRequest,
    filtersData: GetVendorFiltersRequest
  ): Promise<PaginatedVendorsList>;

  abstract getPaginatedVendorsListExcel(
    requestData: GetVendorListExcelRequest,
    filtersData: GetVendorFiltersRequest
  ): Promise<PaginatedVendorsList>;

  abstract createVendor(
    requestData: CreateVendorRequest
  ): Promise<VendorSummaryDTO>;

  abstract createContact(
    requestData: CreateVendorContactRequest,
    params: CreateVendorContactRequestParams
  ): Promise<ContactSummary>;

  abstract updateVendor(
    requestData: UpdateVendorRequest
  ): Promise<VendorSummaryDTO>;

  abstract deleteVendor(requestData: DeleteVendorRequest): Promise<void>;
}
