import { Rating, RatingProps, SxProps } from '@mui/material';
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

const RatingField: React.FC<
  {
    id?: string;
    control?: any;
    onChangeCallback?: any;
    styleGrid?: SxProps;
    styleRating?: SxProps;
    defaultValue?: number;
    shouldUnregister?: ControllerProps['shouldUnregister'];
  } & RatingProps
> = ({
  control,
  name,
  onChangeCallback,
  styleGrid,
  styleRating,
  sizes = { xs: 12 },
  defaultValue = 0,
  shouldUnregister,
  precision,
  size,
  disabled,
}) => {
  return (
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Rating
          name={name}
          precision={precision}
          size={size}
          sx={styleRating}
          onChange={(event, newValue) => {
            onChange(newValue);
            onChangeCallback?.(newValue);
          }}
          value={value}
          disabled={disabled}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
    />
  );
};

export default React.memo(RatingField);
