import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '../../../Ui/Checkbox';
import Switch from '../../../Ui/Switcher';
import FormTimeDuration from '../../../Ui/TimeDuration/FormTimeDuration';
import { UpdateHOSFormProps } from '../../types';

import { SourceAndTime } from './SourceAndTime';

export const UpdateOnlyHOSForm = memo(
  (props: UpdateHOSFormProps & { foundIndex: number }): JSX.Element => {
    const { control, watch } = useFormContext();
    const { foundIndex, canUpdateHos } = props;

    const fieldPath = `driversHosDataList.${foundIndex}`;
    const enableHoS = watch(`${fieldPath}.enableHoS`);
    const isRest = watch(`${fieldPath}.isRest`);

    return (
      <Grid xs={12} item>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingLeft={'8px'}
        >
          <Switch
            control={control}
            label="Hours of service"
            name={`${fieldPath}.enableHoS`}
            sizes={{ xs: 6 }}
            disabled={!canUpdateHos}
          />
          {enableHoS && <SourceAndTime myloadHoS={props.myloadHoS} />}
        </Stack>
        {canUpdateHos && enableHoS && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              ml={0}
            >
              <Checkbox
                control={control}
                name={`${fieldPath}.isRest`}
                label={'Most recent activity is “Rest”'}
              />
              {isRest && (
                <FormTimeDuration
                  maxValue={34 * 60}
                  required={true}
                  control={control}
                  name={`${fieldPath}.restDuration`}
                  label="Rest Duration"
                  sizes={{ xs: 4 }}
                />
              )}
            </Stack>

            <Grid columnSpacing={2} container>
              <FormTimeDuration
                maxValue={14 * 60}
                required={true}
                control={control}
                name={`${fieldPath}.dutyTimeRemaining`}
                label="Duty Time Left"
                sizes={{ xs: 6 }}
                styleProps={{
                  marginLeft: 2,
                }}
              />

              <FormTimeDuration
                maxValue={11 * 60}
                required={true}
                control={control}
                name={`${fieldPath}.shiftDriveRemaining`}
                label="Shift Drive Left"
                sizes={{ xs: 6 }}
                styleProps={{
                  float: 'right',
                }}
              />
            </Grid>
            <Grid columnSpacing={2} container>
              <FormTimeDuration
                maxValue={70 * 60}
                required={true}
                control={control}
                name={`${fieldPath}.weeklyHoursRemaining`}
                label="Weekly Hours Left"
                sizes={{ xs: 6 }}
                styleProps={{
                  marginLeft: 2,
                }}
              />

              <FormTimeDuration
                maxValue={8 * 60}
                required={true}
                control={control}
                name={`${fieldPath}.breakTimeRemaining`}
                label="Break Time Left"
                sizes={{ xs: 6 }}
                styleProps={{
                  float: 'right',
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
);
UpdateOnlyHOSForm.displayName = 'UpdateOnlyHOSForm';
