import {
  AssignDocumentRequest,
  AttachedEntitiesRequest,
  CreateDocument,
  CreateDocumentOnlyRequest,
  CreateFileRequest,
  CreateLatestCertificationRequest,
  CreateLatestDriverLicenseRequest,
  DeleteDocumentRequest,
  DeleteDocumentsRequest,
  DocumentByEntity,
  DocumentItemType,
  DocumentShareRequest,
  DocumentSummaryResponse,
  DownloadDocumentsRequest,
  EditDocument,
  EmailDocumentsRequest,
  EntityTypes,
  EntityTypesRequest,
  FolderTypeResponse,
  GetAssociatedDriversRequest,
  GetDocumentFoldersRequest,
  GetDocumentsByEntityRequest,
  GetDocumentsListByDocIdRequest,
  GetDocumentsListRequest,
  GetDocumentTypeListRequest,
  GetDocumentTypesRequest,
  PaginatedDocumentsList,
  PaginatedDocumentsListRequest,
} from '../../models';
import { FileType, LineItem, PaginatedResult } from '../../types';
import { docType } from '../../utils/Doc';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IDocumentService extends Service {
  abstract getDocumentAssigments(
    requestData: PaginatedDocumentsListRequest
  ): Promise<PaginatedDocumentsList>;

  abstract getEntityTypes(
    requestData: EntityTypesRequest
  ): Promise<EntityTypes>;

  abstract assignDocument(
    requestData: AssignDocumentRequest
  ): Promise<AssignDocumentRequest>;

  abstract deleteDocument(
    requestData: DeleteDocumentRequest
  ): Promise<EntityTypes>;

  abstract createDocument(
    requestData: CreateFileRequest,
    file: FileType[],
    data: any
  ): Promise<CreateDocument>;

  abstract createDocumentOnly(
    requestData: CreateDocumentOnlyRequest
  ): Promise<DocumentSummaryResponse>;

  abstract editDocument(requestData: any): Promise<EditDocument>;

  abstract getAssociatedWith(
    requestData: AttachedEntitiesRequest,
    entity: string
  ): Promise<PaginatedResult<{ id: string; name: string }> | ServiceError>;

  abstract shareDocument(requestData: DocumentShareRequest): Promise<any>;

  abstract getDocumentTypeList(
    requestData: GetDocumentTypeListRequest
  ): Promise<LineItem[]>;

  abstract getDocumentFolders(
    requestData: GetDocumentFoldersRequest
  ): Promise<FolderTypeResponse | ServiceError>;

  abstract getDocumentTypes(
    requestData: GetDocumentTypesRequest
  ): Promise<LineItem[] | ServiceError>;

  abstract getDocumentsByEntity(
    requestData: GetDocumentsByEntityRequest
  ): Promise<DocumentByEntity[] | ServiceError>;

  abstract getDocumentListByTypeId(
    requestData: GetDocumentsListByDocIdRequest
  ): Promise<DocumentItemType[] | ServiceError>;

  abstract downloadDocuments(
    requestData: DownloadDocumentsRequest,
    skipLoader?: boolean,
    isSharing?: boolean
  ): Promise<docType[] | ServiceError>;

  abstract emailDocuments(
    requestData: EmailDocumentsRequest
  ): Promise<string | ServiceError>;

  abstract createLatestCertification(
    requestData: CreateLatestCertificationRequest
  ): Promise<any>;

  abstract createLatestDriverLicense(
    requestData: CreateLatestDriverLicenseRequest
  ): Promise<any | ServiceError>;

  abstract getAssociatedDrivers(
    requestData: GetAssociatedDriversRequest
  ): Promise<any | ServiceError>;

  abstract deleteDocuments(
    requestData: DeleteDocumentsRequest
  ): Promise<string | ServiceError>;

  abstract getDocumentsList(
    requestData: GetDocumentsListRequest
  ): Promise<PaginatedResult<DocumentItemType> | ServiceError>;
}
