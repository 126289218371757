import { t } from 'i18next';
import { useMemo, useState } from 'react';
import StorageManager from '../../StorageManager/StorageManager';
import { customerService } from '../../api';
import LongMenu from '../../common/LongMenu';
import { AXELE_PERMISSION_TYPE, Permission } from '../../common/Permission';
import {
  CustomerAssignedToLoadRequest,
  CustomerSummaryDTO,
  DeleteCustomerRequest,
  customerAssignedToLoadType,
} from '../../models';
import { DeleteButtonIcon } from '../../ui-kit/components/Assets';
import DeletePopup from '../../ui-kit/components/DeletePopup';
import { deletePopupConfig } from './constants';

interface ActionsSectionProps {
  onDeleted: (id: string) => void;
  customerData: CustomerSummaryDTO;
}

interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}

export function ActionsSection({
  onDeleted,
  customerData,
}: ActionsSectionProps) {
  const [deletePopupState, setDeletePopupState] = useState<{
    open: boolean;
    isCustomerAssignedToLoad: boolean;
    popupMessage?: string | false;
    customerId: string | undefined;
  }>({ open: false, isCustomerAssignedToLoad: false, customerId: '' });

  const id = customerData?.id;
  const userStorageData = StorageManager.getUser();
  const options = useMemo(() => {
    const mainActions: Action[] = [];

    const deleteAction = {
      customStyles: { paddingRight: '5px' },
      name: t('deleteCustomer'),
      action: () => {
        handleDeleteClick(id);
      },
      startIcon: DeleteButtonIcon({}),
    };

    mainActions.push(deleteAction);

    return mainActions;
  }, [id]);

  const getDeleteMessage = (option: customerAssignedToLoadType) => {
    if (option?.customerAssignedToLoad) {
      return deletePopupConfig.bodyText.isCustomerAssignedToLoad;
    } else if (option?.customerAssignedToInvoice) {
      return deletePopupConfig.bodyText.isCustomerAssignedToInvoice;
    } else if (option?.customerAssignedToPayment) {
      return deletePopupConfig.bodyText.isCustomerAssignedToPayment;
    } else {
      return false;
    }
  };

  const handleDeleteClick = async (id: string | undefined) => {
    if (id) {
      try {
        const customerAssignedToLoadRequest = new CustomerAssignedToLoadRequest(
          {
            customerId: id,
          }
        );
        const isCustomerAssignedToLoad: customerAssignedToLoadType =
          await customerService.customerAssignedToLoad(
            customerAssignedToLoadRequest
          );
        const message = getDeleteMessage(isCustomerAssignedToLoad);
        setDeletePopupState({
          open: true,
          isCustomerAssignedToLoad: message ? true : false,
          popupMessage: message,
          customerId: id,
        });
      } catch (error) {
        return;
      }
    }
  };

  const handleDelete = async () => {
    const { customerId, isCustomerAssignedToLoad } = deletePopupState;

    if (!isCustomerAssignedToLoad) {
      const requestData = new DeleteCustomerRequest({ id: customerId });
      try {
        await customerService.deleteCustomer(requestData);
      } catch (error) {
        return;
      }
      onDeleted(id);
    }
    setDeletePopupState({
      open: false,
      isCustomerAssignedToLoad: false,
      customerId: '',
    });
  };

  const customerRemovePermissions: {
    [key: string]: AXELE_PERMISSION_TYPE[];
  } = {
    DR: [AXELE_PERMISSION_TYPE.CUSTOMER_REMOVE],
    SP: [AXELE_PERMISSION_TYPE.CUSTOMER_REMOVE],
  };

  return (
    <>
      <Permission
        includes={customerRemovePermissions[userStorageData?.roleCode]}
      >
        <LongMenu color={'primary.contrast'} options={options} />
      </Permission>
      {deletePopupState.open && (
        <DeletePopup
          open
          onClose={() =>
            setDeletePopupState({
              open: false,
              isCustomerAssignedToLoad: false,
              customerId: '',
            })
          }
          onAction={
            deletePopupState.isCustomerAssignedToLoad ? undefined : handleDelete
          }
          title={t('deleteCustomer')}
          body={
            deletePopupState.isCustomerAssignedToLoad &&
            deletePopupState.popupMessage
              ? deletePopupState.popupMessage
              : deletePopupConfig.bodyText.default
          }
          cancelText={
            deletePopupState.isCustomerAssignedToLoad ? 'Ok' : 'Cancel'
          }
        />
      )}
    </>
  );
}
