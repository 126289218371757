import { UpdateOutlined } from '@mui/icons-material';
import {
  ChangeEvent,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TripViewV3Mode } from '../../../types';
import {
  CustomizeAccordion,
  CustomizeAccordionItem,
} from '../CustomizeAccordion';
import {
  StyleAccordionSummaryRightAction,
  StyleAccordionSummarySwitcher,
  StyleIconButton,
} from '../CustomizeAccordion/styles';

import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import { useLoadPermission } from '../../../../../../common/hooks/useLoadPermission';
import Switcher from '../../../../Switcher';
import { getStylesTripV3ListWithAccordion } from '../../styles';
import {
  ExpandableTimelineV3AddStopEvents,
  ExpandableTimelineV3BrokeredEvents,
  ExpandableTimelineV3EditIconEvents,
  ExpandableTimelineV3LoadIdEvents,
  ExpandableTimelineV3PanelView,
  ExpandableTimelineV3ProgressIconEvents,
  ExpandableTimelineV3ResequenceStopEvents,
  StopSolutionV3NormalStop,
} from '../../types';
import { TripStatus } from './TripStatus';
import { TripV3, TripV3DataProps } from './TripV3';

interface IExpanded {
  [key: string]: boolean;
}

export interface TripV3ListWithAccordionProps
  extends ExpandableTimelineV3EditIconEvents,
    ExpandableTimelineV3ProgressIconEvents,
    ExpandableTimelineV3AddStopEvents,
    ExpandableTimelineV3ResequenceStopEvents,
    ExpandableTimelineV3LoadIdEvents,
    ExpandableTimelineV3BrokeredEvents {
  tripDataList: TripV3DataProps[];
  isMultipleExpanded?: boolean;
  panelView: ExpandableTimelineV3PanelView;
  readOnly?: boolean;
  cancelledStops?: StopSolutionV3NormalStop[];
}

export const TripV3ListWithAccordion = memo(
  ({
    tripDataList,
    isMultipleExpanded,
    readOnly = false,
    panelView,
    onClickProgressIconHandler,
    onClickEditIconHandler,
    onClickAddStopHandler,
    onClickAddRelayHandler,
    onClickResequenceUpHandler,
    onClickResequenceDownHandler,
    onClickLoadIdHandler,
    onClickBrokeredHandler,
    cancelledStops = [],
  }: TripV3ListWithAccordionProps): JSX.Element => {
    const [expandedAccordion, setExpandedAccordion] = useState<IExpanded>({});
    const [expandedSwitcherHOS, setExpandedSwitcherHOS] = useState<IExpanded>(
      {}
    );
    const styles = getStylesTripV3ListWithAccordion();
    const { hasLoadEditPermission, hasCarrierViewPermission } =
      useLoadPermission();
    const hasCancelledStops: boolean =
      !!cancelledStops && cancelledStops.length > 0;

    const cancelledStopsDataProp: TripV3DataProps = {
      id: 'cancelledStops',
      expanded: false,
      showHOSPlan: true,
      showProgressIcon: false,
      expandedHOSPLan: false,
      isCompleted: false,
      isCancelled: true,
      disabled: true,
      hideImageComplete: true,
      headerData: {
        title: <Box>Canceled Stops</Box>,
      },
      stopList: cancelledStops,
    };

    const finalTripDataList = useMemo(() => {
      if (Boolean(tripDataList.length)) {
        return [
          ...tripDataList,
          ...(hasCancelledStops ? [cancelledStopsDataProp] : []),
        ];
      }
      return [];
    }, [hasCancelledStops, tripDataList]);

    const init = (): void => {
      const newState: IExpanded = {};
      finalTripDataList.forEach((item: TripV3DataProps) => {
        newState[item.id] = !!item.expandedHOSPLan;
      });
      setExpandedSwitcherHOS(newState);
    };

    useEffect(() => {
      init();
    }, [finalTripDataList]);

    const onChangeSwitcherHandler = useCallback(
      (id: string, checked: boolean): void => {
        setExpandedSwitcherHOS((preState) => {
          return {
            ...preState,
            [id]: checked,
          };
        });
      },
      [setExpandedSwitcherHOS]
    );

    const isExpanedAccordion = (item: TripV3DataProps): boolean => {
      if (expandedAccordion.hasOwnProperty(item.id)) {
        return expandedAccordion[item.id];
      }
      return item.expanded;
    };

    const acordionItemList: CustomizeAccordionItem[] = useMemo(() => {
      return finalTripDataList.map((item: TripV3DataProps, idx: number) => {
        return {
          id: item.id,
          isCancelled: !!item?.isCancelled,
          expanded: isExpanedAccordion(item),
          summaryTitle: item.headerData.title,
          renderTripStatus: () => {
            return (
              <>
                {panelView === ExpandableTimelineV3PanelView.LoadDetails &&
                !!item.headerData?.tripStatus ? (
                  <TripStatus {...item.headerData?.tripStatus} />
                ) : (
                  <></>
                )}
              </>
            );
          },
          renderSummaryAction: ({ expanded }): JSX.Element => {
            if (
              !readOnly &&
              !item?.isCancelled &&
              (item?.showProgressIcon || item?.showBrokered)
            ) {
              return (
                <StyleAccordionSummaryRightAction
                  onClick={(event: SyntheticEvent<Element, Event>) => {
                    event.stopPropagation();
                  }}
                >
                  {item?.showBrokered && hasCarrierViewPermission && (
                    <Tooltip title={t('brokered')}>
                      <Switcher
                        onChange={(event, checked) => {
                          event.stopPropagation();
                          onClickBrokeredHandler?.(event, checked, item, idx);
                        }}
                        disabled={hasLoadEditPermission}
                        label={
                          <StyleAccordionSummarySwitcher checked={false}>
                            {t('brokered')}
                          </StyleAccordionSummarySwitcher>
                        }
                      />
                    </Tooltip>
                  )}
                  {item?.showProgressIcon && (
                    <Tooltip title="Update Progress">
                      <StyleIconButton
                        onClick={(event) =>
                          onClickProgressIconHandler?.(event, item)
                        }
                      >
                        <UpdateOutlined fontSize="small" />
                      </StyleIconButton>
                    </Tooltip>
                  )}
                </StyleAccordionSummaryRightAction>
              );
            } else {
              return <></>;
            }
          },
          renderHeaderContents: (): JSX.Element => {
            return readOnly ? (
              <></>
            ) : (
              item.headerData?.renderHeaderContents?.() || <></>
            );
          },
          renderComponent: (): JSX.Element => {
            return (
              <Box sx={styles.renderComponentRoot}>
                {item?.showHOSPlan && !item.isCancelled && (
                  <Box sx={styles.renderComponentSwitcher}>
                    <Switcher
                      name={item.id}
                      defaultChecked={item.expandedHOSPLan}
                      checked={expandedSwitcherHOS[item.id]}
                      onChange={(
                        event: ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        onChangeSwitcherHandler(item.id, checked);
                        event.stopPropagation();
                      }}
                      label={
                        <StyleAccordionSummarySwitcher
                          checked={expandedSwitcherHOS[item.id]}
                        >
                          View HoS Plan
                        </StyleAccordionSummarySwitcher>
                      }
                    />
                  </Box>
                )}
                <TripV3
                  data={{
                    ...item,
                    hideImageComplete:
                      idx < finalTripDataList.length - 1 ||
                      item.hideImageComplete ||
                      !!item?.isCancelled,
                  }}
                  viewMode={
                    expandedSwitcherHOS[item.id]
                      ? TripViewV3Mode.HOSEventView
                      : TripViewV3Mode.DefaultView
                  }
                  isMultipleExpanded={isMultipleExpanded}
                  readOnly={readOnly}
                  panelView={panelView}
                  onClickEditIconHandler={onClickEditIconHandler}
                  onClickAddStopHandler={onClickAddStopHandler}
                  onClickResequenceDownHandler={onClickResequenceDownHandler}
                  onClickResequenceUpHandler={onClickResequenceUpHandler}
                  onClickAddRelayHandler={onClickAddRelayHandler}
                  onClickLoadIdHandler={onClickLoadIdHandler}
                />
              </Box>
            );
          },
        };
      });
    }, [
      hasCancelledStops,
      finalTripDataList,
      expandedSwitcherHOS,
      onChangeSwitcherHandler,
      panelView,
      isMultipleExpanded,
      readOnly,
    ]);

    return (
      <CustomizeAccordion
        list={acordionItemList}
        expandedAccordion={expandedAccordion}
        setExpandedAccordion={setExpandedAccordion}
      />
    );
  }
);
