import { Button, Grid, InputLabel, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Accordion from '../../../../common/Accordion';
import { expensesModule } from '../../../../common/PendoClassIDs/constants';
import Checkbox from '../../../../common/Ui/Checkbox/index';
import FileUploadUpdated from '../../../../common/Ui/FileUpload/indexUpdated';
import DatePicker from '../../../../common/Ui/FormDatePickerWithoutTimeZone/FormDatePickerWithoutTimeZone';
import FormDialog from '../../../../common/Ui/FormDialog/index';
import { MultipleAutocompleteForm } from '../../../../common/Ui/MultipleAutocomplete';
import RadioGroup from '../../../../common/Ui/RadioGroup';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import {
  Expense,
  ExpenseDetails,
  PayItemDTO,
  Payment,
  SetSecondaryPanelDataType,
} from '../../../../models/DTOs';
import { AddIcon } from '../../../../ui-kit/components/Assets';
import AddNewPaymentForm from './AddNewPaymentForm';
import PaymentsListForm from './PaymentsListForm';

import {
  createPaymentsBulk,
  createVendor,
  getDriverNamesList,
  getIsOpenAddPaymentsModalButtonDesabled,
  getMaintenanceList,
  getUsersNamesList,
  getVendorNamesList,
  updateExpense,
} from '../../utils/utils';

import { t } from 'i18next';
import { observer } from 'mobx-react';
import StorageManager from '../../../../StorageManager/StorageManager';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { statesinCountry } from '../../../../common/Ui/AddressField/constants/states';
import { Link } from '../../../../components_v2/ui-kit/link';
import { useStaticData } from '../../../../contexts/StaticDataContext';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { amountFormat, isHasPermission } from '../../../../utils';
import {
  calculateNetExpense,
  calculateSettlementAmount,
} from '../../../../utils/expense';
import {
  getExpenseCategoriesList,
  getFuelTypesList,
  getLoadList,
  tractorList,
  trailerList,
} from '../../../../views/finance/expenses/utils';
import { paymentValidationSchema } from '../../constants';

type propsType = {
  isFuelExpense: boolean;
  onUpdated: (data?: Expense) => void;
  setSecondaryPanelData: React.Dispatch<
    React.SetStateAction<SetSecondaryPanelDataType | null>
  >;
  expenseDetailsData?: ExpenseDetails;
  handleMaintenanceOnClick?: (
    expenseId: string,
    isGlobal?: boolean,
    expenseDetailsData?: ExpenseDetails
  ) => void;
};

function ExpensesDetailsForm({
  isFuelExpense,
  onUpdated,
  setSecondaryPanelData,
  expenseDetailsData,
  handleMaintenanceOnClick,
}: propsType) {
  const { control, watch, setValue } = useFormContext();
  const fileId = watch('assignedDocuments')?.[0]?.documentId;

  const { getAllTerminals, getIsTerminalEnabled } = useRootStore();

  const { staticData } = useStaticData();

  const userStorageData = StorageManager.getUser();

  const [isCreatePaymentsFormDialogOpen, setIsCreatePaymentsFormDialogOpen] =
    useState<boolean>(false);

  const [maintenanceList, setMaintenanceList] = useState<Array<string>>([]);

  const [
    equipmentType,
    grossAmount,
    discountAmount,
    payCategoryObj,
    id,
    finalAmount,
    amount,
    companyExpenseName,
    payments,
    state,
  ] = watch([
    'equipmentType',
    'grossAmount',
    'discountAmount',
    'payCategoryObj',
    'id',
    'finalAmount',
    'amount',
    'companyExpenseName',
    'payments',
    'state',
  ]);
  const expense = watch();
  const noPermissionForEdit = !isHasPermission([
    AXELE_PERMISSION_TYPE.EXPENSE_EDIT,
  ]);
  useEffect(() => {
    if (state?.CountryName) return;
    if (state?.value) {
      const selectedOption = cloneDeep(statesinCountry).find((x) => {
        if (x.value === state.value) {
          return x;
        }
      });
      setValue('state', cloneDeep(selectedOption));
    }
  }, [state]);

  useEffect(() => {
    if (!grossAmount) {
      setValue('amount', '0');
      return;
    }
    setValue('amount', grossAmount - discountAmount);
  }, [grossAmount, discountAmount]);

  useEffect(() => {
    const maintenanceData = getMaintenanceList(
      expenseDetailsData?.maintenanceDetails
    );
    setMaintenanceList(maintenanceData);
  }, [expenseDetailsData?.maintenanceDetails]);
  const recalculateNetExpense = (paymentsList: Payment[]) => {
    const settlementAmount = paymentsList.length
      ? calculateSettlementAmount(paymentsList)
      : 0;

    const netExpense = calculateNetExpense(
      companyExpenseName === 'companyExpense',
      amount,
      settlementAmount
    );

    setValue('finalAmount', netExpense);
    return netExpense;
  };

  useEffect(() => {
    recalculateNetExpense(payments);
  }, [amount, companyExpenseName]);

  useEffect(() => {
    const updatedCompanyExpense = companyExpenseName === 'companyExpense';
    const updatedPayCategoryObj =
      companyExpenseName === 'companyExpense'
        ? { payCategoryName: 'Deduction', payCategoryKey: 'DEDUCTION' }
        : {
            payCategoryName: 'Reimbursement',
            payCategoryKey: 'REIMBURSEMENT',
          };

    setValue('companyExpense', updatedCompanyExpense);
    setValue('payCategoryObj', updatedPayCategoryObj);
  }, [companyExpenseName]);

  const onEquipmentTypeChange = () => setValue('equipmentObj', undefined);

  const createNewVendor = async (name: string) => {
    const vendor = await createVendor(name);

    if (vendor?.id) {
      setValue('paidToObj', vendor);
    }
  };

  const handleOpenAddPaymentsModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setIsCreatePaymentsFormDialogOpen(true);
  };

  const handleCloseCreatePayments = () => {
    setIsCreatePaymentsFormDialogOpen(false);
  };

  const handleCreatePayment = async (payment: Payment) => {
    if (id) {
      const response = await createPaymentsBulk([payment], payment?.expenseId);

      if (response) {
        payments.push(
          new PayItemDTO({ ...response[0], isAmountRequiredInAbs: true })
        );

        if (!expenseDetailsData) return;

        const settlementAmount = payments?.length
          ? calculateSettlementAmount(payments)
          : 0;

        const finalAmount = calculateNetExpense(
          companyExpenseName === 'companyExpense',
          expenseDetailsData.amount,
          settlementAmount
        );

        const updatedExpense = await updateExpense(
          { ...expenseDetailsData, finalAmount },
          getAllTerminals,
          staticData
        );

        if (updatedExpense) {
          onUpdated(updatedExpense);
        }
      }
    } else {
      setValue('payments', [...payments, payment]);
    }
    setIsCreatePaymentsFormDialogOpen(false);
  };
  const handlePaymentDeleted = async (updatedPayments: Payment[]) => {
    const finalAmount = recalculateNetExpense(updatedPayments);
    if (!expenseDetailsData || !id) return;

    const updatedExpense = await updateExpense(
      { ...expenseDetailsData, finalAmount },
      getAllTerminals,
      staticData
    );

    if (updatedExpense) {
      onUpdated(updatedExpense);
    }
  };

  // TODO_Remove - Kishor
  // const financeExpensesEditPermissions: {
  //   [key: string]: AXELE_PERMISSION_TYPE[];
  // } = {
  //   NFD: [AXELE_PERMISSION_TYPE.EXPENSE_EDIT],
  //   DI: [AXELE_PERMISSION_TYPE.EXPENSE_EDIT],
  //   DR: [AXELE_PERMISSION_TYPE.EXPENSE_EDIT],
  //   SP: [AXELE_PERMISSION_TYPE.EXPENSE_EDIT],
  // };

  const paymentsSummaryRenderer = () => {
    const isOpenAddPaymentsModalButtonDesabled =
      getIsOpenAddPaymentsModalButtonDesabled(
        expense as ExpenseDetails,
        isFuelExpense
      );
    return (
      <Grid container alignItems={'center'} spacing={0}>
        <Grid item>
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            {t('settlements')}
          </Typography>
        </Grid>
        <Grid item>
          <Permission contains={[AXELE_PERMISSION_TYPE.EXPENSE_EDIT]}>
            <Button
              onClick={handleOpenAddPaymentsModal}
              variant="outlined"
              disabled={isOpenAddPaymentsModalButtonDesabled}
              style={{
                minWidth: 35,
                minHeight: 35,
                marginLeft: 10,
              }}
            >
              <AddIcon
                color={
                  isOpenAddPaymentsModalButtonDesabled ? '#e0e0e0' : '#2B64CB'
                }
              />
            </Button>
          </Permission>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container justifyContent={'space-between'} padding={2}>
      <Grid item sx={{ mt: 3 }}>
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          {t('transactionDetails')}
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        <RadioGroup
          disabled={noPermissionForEdit && !!id}
          row
          control={control}
          name={'companyExpenseName'}
          defaultValue="companyExpense"
          data={[
            { value: 'companyExpense', label: t('companyCardCash') },
            { value: 'nonCompanyExpense', label: t('nonCompanyCardCash') },
          ]}
        />
      </Grid>
      <TextField
        id={expensesModule + 'GrossAmount'}
        disabled={noPermissionForEdit && !!id}
        control={control}
        name="grossAmount"
        label={t('grossAmount') + ' ($)'}
        required
        sizes={{
          xs: 5.7,
        }}
      />
      <TextField
        id={expensesModule + 'DiscountAmount'}
        disabled={noPermissionForEdit && !!id}
        control={control}
        name="discountAmount"
        label={t('discountAmount') + ' ($)'}
        sizes={{
          xs: 5.7,
        }}
      />
      <DatePicker
        disabled={noPermissionForEdit && !!id}
        control={control}
        disabledText
        name="paidDate"
        label={t('date')}
        sizes={{ xs: 5.7 }}
        disableFuture
        required
      />
      <Grid container item xs={5.7} direction={'column'}>
        <Typography
          variant="h8"
          sx={{ color: 'text.secondary', marginBottom: '10px' }}
        >
          {t('expenseAmount') + ' ($)'}
        </Typography>
        <Typography variant="inputText" sx={{ color: 'text.secondary' }}>
          {amountFormat(amount)}
        </Typography>
      </Grid>

      <SingleAutocompleteForm
        id={expensesModule + 'PaidTo'}
        disabled={noPermissionForEdit && !!id}
        name={'paidToObj'}
        label={t('paidTo')}
        fieldName={'name'}
        getOptions={getVendorNamesList}
        control={control}
        sizes={{ xs: 5.7 }}
        freeSolo
        addNewOption={createNewVendor}
      />
      <SingleAutocompleteForm
        id={expensesModule + 'PaidBy'}
        disabled={noPermissionForEdit && !!id}
        name="paidByObj"
        label={t('paidBy')}
        fieldName="name"
        getOptions={getUsersNamesList}
        control={control}
        sizes={{ xs: 5.7 }}
      />
      <TextField
        id={expensesModule + 'Reference'}
        disabled={noPermissionForEdit && !!id}
        control={control}
        name="referenceNumber"
        label={t('reference')}
        sizes={{
          xs: 5.7,
        }}
      />
      {!isFuelExpense && (
        <SingleAutocompleteForm
          id={expensesModule + 'ExpenseCategory'}
          disabled={noPermissionForEdit && !!id}
          name="categoryObj"
          label={t('expenseCategory')}
          fieldName="itemName"
          required
          getOptions={(name: string, pageNumber: number) =>
            getExpenseCategoriesList(name, pageNumber, 'FUEL')
          }
          control={control}
          sizes={{ xs: 5.7 }}
        />
      )}
      {isFuelExpense && (
        <>
          <TextField
            id={expensesModule + 'FuelCard'}
            disabled={noPermissionForEdit && !!id}
            control={control}
            name="cardNumber"
            label={t('fuelCard')}
            sizes={{
              xs: 5.7,
            }}
          />
          <Grid item xs={12} sx={{ marginBottom: '20px' }}>
            <Typography variant="h7" sx={{ color: 'primary.main' }}>
              {t('fuelDetails')}
            </Typography>
          </Grid>

          <SingleAutocompleteForm
            id={expensesModule + 'FuelType'}
            disabled={noPermissionForEdit && !!id}
            name="fuelType"
            label={t('fuelType')}
            fieldName="value"
            getOptions={getFuelTypesList}
            control={control}
            groupBy="categoryName"
            sizes={{ xs: 5.7 }}
            required
          />

          <TextField
            id={expensesModule + 'FuelQuantity'}
            disabled={noPermissionForEdit && !!id}
            control={control}
            name="fuelQuantity"
            label={t('fuelQuantity')}
            sizes={{
              xs: 5.7,
            }}
            required
          />

          <TextField
            id={expensesModule + 'TruckStop'}
            disabled={noPermissionForEdit && !!id}
            control={control}
            name="truckstopAddress"
            label={t('truckStop')}
            sizes={{
              xs: 5.7,
            }}
          />

          <SingleAutocompleteForm
            id={expensesModule + 'State'}
            disabled={noPermissionForEdit && !!id}
            name="state"
            label={t('state')}
            fieldName="name"
            options={statesinCountry}
            control={control}
            required
            sizes={{ xs: 5.7 }}
          />
        </>
      )}
      <TextField
        id={expensesModule + 'Description'}
        disabled={noPermissionForEdit && !!id}
        control={control}
        name="description"
        label={t('description')}
        sizes={{
          xs: 12,
        }}
      />
      <Grid item xs={12} sx={{ marginBottom: '20px' }}>
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          {t('associations')}
        </Typography>
      </Grid>
      {getIsTerminalEnabled && (
        <SingleAutocompleteForm
          id={expensesModule + 'Terminal'}
          disabled={noPermissionForEdit && !!id}
          name="terminal"
          label={t('terminal')}
          required
          fieldName="companyName"
          getOptions={() => getAllTerminals}
          control={control}
          sizes={{ xs: maintenanceList?.length > 0 ? 6 : 12 }}
        />
      )}
      {maintenanceList?.length > 0 && (
        <Grid
          item
          xs={getIsTerminalEnabled ? 5.7 : 12}
          sx={{ marginBottom: '20px' }}
        >
          <InputLabel
            style={{
              color: 'rgba(0, 17, 34, 0.6)',
              fontFamily: 'Poppins',
              fontWeight: 400,
              marginBottom: '7px',
              fontSize: '0.71rem !important',
              lineHeight: '1.4375em !important',
            }}
          >
            Maintenance
          </InputLabel>
          <Link
            variant="h7"
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              whiteSpace: 'pre-wrap',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginLeft: '5px',
            }}
            onClick={(event) => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              event.stopPropagation();
              handleMaintenanceOnClick &&
                handleMaintenanceOnClick(
                  expenseDetailsData?.id as string,
                  true,
                  expenseDetailsData
                );
            }}
          >
            {maintenanceList?.join(', ')}
          </Link>
        </Grid>
      )}
      <SingleAutocompleteForm
        id={expensesModule + 'Driver'}
        disabled={noPermissionForEdit && !!id}
        name="driverObj"
        label={t('driver')}
        fieldName="name"
        getOptions={(name: string, pageNumber: number) =>
          getDriverNamesList(name, pageNumber, getAllTerminals)
        }
        control={control}
        sizes={{ xs: 5.7 }}
      />
      <MultipleAutocompleteForm
        id={expensesModule + 'LoadID'}
        disabled={noPermissionForEdit && !!id}
        control={control}
        name="loadsIdAndName"
        fieldName="seqNumber"
        defaultValue={[{ seqNumber: '' }]}
        getOptions={(value: string, pageNumber: number) =>
          getLoadList(
            value,
            pageNumber,
            getAllTerminals.map((item: any) => item.id)
          )
        }
        label={t('loadId')}
        variant="standard"
        sizes={{ xs: 5.7 }}
      />
      <Grid item xs={5.7} sx={{ marginTop: '16px' }}>
        <RadioGroup
          disabled={noPermissionForEdit && !!id}
          row
          control={control}
          name={'equipmentType'}
          defaultValue={'TRACTOR'}
          onChangeCb={onEquipmentTypeChange}
          data={[
            { value: 'TRACTOR', label: t('tractor') },
            { value: 'TRAILER', label: t('trailer') },
          ]}
        />
      </Grid>
      {equipmentType === 'TRACTOR' ? (
        <SingleAutocompleteForm
          id={expensesModule + 'EquipmentTractor'}
          disabled={noPermissionForEdit && !!id}
          name="equipmentObj"
          label="Tractor"
          required={isFuelExpense}
          fieldName="name"
          getOptions={(name: string, pageNumber: number) =>
            tractorList(name, pageNumber, null, ['UNAVAILABLE', 'INACTIVE'])
          }
          control={control}
          sizes={{ xs: 5.7 }}
        />
      ) : (
        <SingleAutocompleteForm
          id={expensesModule + 'EquipmentTrailer'}
          disabled={noPermissionForEdit && !!id}
          name="equipmentObj"
          label="Trailer"
          required={isFuelExpense}
          fieldName="name"
          getOptions={(name, pageNumber) =>
            trailerList(name, pageNumber, null, ['UNAVAILABLE', 'INACTIVE'])
          }
          control={control}
          sizes={{ xs: 5.7 }}
        />
      )}
      <Permission contains={[AXELE_PERMISSION_TYPE.EXPENSE_EDIT]}>
        <FileUploadUpdated
          name={`assignedDocuments.${name}`}
          label="Upload"
          fileId={fileId}
          control={control}
        />
      </Permission>
      <Accordion summaryRenderer={paymentsSummaryRenderer}>
        {!!payments?.length
          ? payments.map((item: any, index: number) => (
              <PaymentsListForm
                key={`${item?.id ?? item?.payingEntityObj?.id}`}
                id={index}
                isFuelExpense={isFuelExpense}
                onPaymentDeleted={handlePaymentDeleted}
                setSecondaryPanelData={setSecondaryPanelData}
              />
            ))
          : null}
      </Accordion>
      <Grid
        container
        sx={{ marginTop: '30px' }}
        justifyContent={'space-between'}
      >
        <Grid item>
          <Typography variant="kpiValue" sx={{ color: 'text.primary' }}>
            {t('netExpense')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="kpiValue"
            sx={{ color: 'text.primary', fontWeight: 700 }}
          >
            {amountFormat(finalAmount)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ marginTop: '20px', marginLeft: '-10px' }}>
        <Checkbox
          id={expensesModule + 'NeedsReveiw'}
          control={control}
          label={t('needsReview')}
          name="statusValue"
          disabled={noPermissionForEdit && !!id}
        />
      </Grid>
      {isCreatePaymentsFormDialogOpen && (
        <FormDialog
          data={{
            entityType: 'DRIVER',
            amount: null,
            description: '',
            expenseCategoryId: expense.categoryObj?.id,
            expenseCategoryKey: expense.categoryObj?.itemCode,
            expenseId: id,
            expenseSeqNumber: expense.seqNumber,
            fuelQuantity: expense.fuelQuantity,
            fuelType: expense.fuelType?.key,
            payCategory: payCategoryObj?.payCategoryKey,
            payCategoryName: payCategoryObj?.payCategoryName,
            payDate: expense.paidDate,
            settlementStatus: expense.status,
            state: expense.state?.value,
            truckStop: expense.truckstopAddress,
            finalAmount: expense.finalAmount,
            expenseAmount: expense.amount,
            settlementAmount: expense.settlementAmount,
            companyExpense: expense.companyExpense,
          }}
          titleText={payCategoryObj?.payCategoryName}
          actionLabel="Add"
          open={isCreatePaymentsFormDialogOpen}
          onAction={handleCreatePayment}
          handleClose={handleCloseCreatePayments}
          contentRenderer={() => (
            <AddNewPaymentForm isFuelExpense={isFuelExpense} />
          )}
          validationSchema={paymentValidationSchema}
        />
      )}
    </Grid>
  );
}

export default observer(ExpensesDetailsForm);
