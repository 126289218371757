import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission/index';
import { isHasPermission } from '../../../../utils';

export const hasPaymentAdd = (): boolean => {
  return isHasPermission([AXELE_PERMISSION_TYPE.PAYMENT_ADD]);
};
export const hasPaymentEdit = (): boolean => {
  return isHasPermission([AXELE_PERMISSION_TYPE.PAYMENT_ADD]);
};
export const hasPaymentRemove = (): boolean => {
  return isHasPermission([AXELE_PERMISSION_TYPE.PAYMENT_ADD]);
};
export const hasPaymentView = (): boolean => {
  return isHasPermission([AXELE_PERMISSION_TYPE.PAYMENT_ADD]);
};
export const hasDocumentUplad = (): boolean => {
  return isHasPermission([AXELE_PERMISSION_TYPE.DOCUMENT_ADD]);
};
