import { FC } from 'react';
import Grid from '@mui/material/Grid';
import { Providers } from './Providers';
import { ProviderDetails } from '@/views/settings/integrations/FuelCard/components/ProviderDetails';
import { useFuelCards } from '@/views/settings/integrations/FuelCard/hooks';

export const FuelCards: FC = () => {
  const {
    providers,
    selectedProvider,
    handleSelectedProviderChange,
    getProviders,
  } = useFuelCards();
  return (
    <Grid container>
      <Grid item xs={2} px={2} sx={{ height: '76vh', overflow: 'auto' }}>
        <Providers
          providers={providers}
          onProviderChange={handleSelectedProviderChange}
        />
      </Grid>
      <Grid
        item
        xs={10}
        className="selected-transporter-block"
        sx={{ height: '76vh', overflow: 'auto' }}
      >
        {selectedProvider && (
          <ProviderDetails
            selectedProvider={selectedProvider}
            getProviders={getProviders}
          />
        )}
      </Grid>
    </Grid>
  );
};
