import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import StorageManager from '../../../../StorageManager/StorageManager';
import { CloseIcon } from '../../../../ui-kit/components/Assets';
import { emailArrayValidationYup } from '../../../../utils';
import { docType } from '../../../../utils/Doc';
import EmailForm from '../forms/EmailForm';

/* @TODO this file should be rewritten */

const styles = {
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  uploadDocMain: {
    color: 'primary.text',
    letterSpacing: '0.15px',
    fontWeight: 500,
    fontSize: 20,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '32px',
    fontSize: '20px',
  },
  point: {
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.15px',
    color: '#001122',
  },
};
interface IProps {
  onClose: () => void;
  sendDocument: (data: any) => void;
  files: docType[];
}

const validationSchema = yup.object({
  to: emailArrayValidationYup('to').nullable().min(1, 'Email is required'),
  cc: emailArrayValidationYup('to').nullable(),
});

const getFileNamesString = (files: docType[]) => {
  if (!files?.length) {
    return '';
  }
  return files.reduce((finalString, currentFile) => {
    finalString += `<p>${currentFile.fileName}</p>`;
    return finalString;
  }, '');
};

export default function EmailFormContainer({
  onClose,
  sendDocument,
  files,
}: IProps) {
  const user = StorageManager.getUser();
  const state = useForm({
    defaultValues: {
      to: [],
      cc: [],
      subject: `${files.length} Document(s) from ${user.organizationName}`,
      message: `<p>Dear Sir/Madam,</p><p>Please find attached relevant document(s) for processing your requirements:</p>${getFileNamesString(
        files
      )}<p>Regards,</p><p>${user.organizationName}</p>`,
      permission: false,
    },
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const handleShare = (data: any) => {
    sendDocument(data);
  };

  const checkKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <Box sx={styles.title}>
        <span>Send Document</span>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon color="rgba(0, 17, 34, 0.75)" width={14} height={14} />
        </IconButton>
      </Box>
      <FormProvider {...state}>
        <form
          onSubmit={state.handleSubmit(handleShare)}
          onKeyDown={(event) => checkKeyDown(event)}
        >
          <EmailForm onClose={onClose} />
          <Box style={styles.footer}>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button disabled={false} type={'submit'} variant="contained">
              Send
            </Button>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
