import {
  CreateManageTypeRequest,
  DeleteManageTypeRequest,
  GetManageTypesRequest,
  ManageTypesItem,
  UpdateManageTypeRequest,
} from '../../models/DTOs/ManageTypes/Requests';
import { PaginatedResult } from '../../types';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IManageTypesService extends Service {
  abstract getManageTypesList(
    requestData: GetManageTypesRequest,
    routeName: string
  ): Promise<PaginatedResult<ManageTypesItem> | ServiceError>;

  abstract deleteManageType(
    requestData: DeleteManageTypeRequest,
    routeName: string
  ): Promise<string | ServiceError>;

  abstract createManageType(
    requestData: CreateManageTypeRequest,
    routeName: string
  ): Promise<ManageTypesItem | ServiceError>;

  abstract updateManageType(
    requestData: UpdateManageTypeRequest,
    routeName: string
  ): Promise<ManageTypesItem | ServiceError>;
}
