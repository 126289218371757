import React, { useState } from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  useTheme,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ImportActions from '../../Import';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { mainColor } from '../../constants';
import { importDefaultDateType, possibleDateFormats } from './constants';

export const theme = (currentTheme: Theme) =>
  createTheme(currentTheme, {
    components: {
      // Name of the component
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(43, 100, 203, 0.5)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '1px solid rgba(43, 100, 203, 0.5)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(43, 100, 203, 0.5)',
            },
            color: mainColor,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: mainColor,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: `${mainColor} !important`,
          },
        },
      },
    },
  });

export default function DateFormatSelect() {
  const [dateFormat, setDateFormat] = useState(importDefaultDateType);
  const handleChange = (event: SelectChangeEvent) => {
    event.stopPropagation();
    setDateFormat(event.target.value);
    ImportActions.selectedDateFormat = event.target.value;
  };

  const currentTheme = useTheme();

  return (
    <ThemeProvider theme={theme(currentTheme)}>
      <FormControl
        sx={{ minWidth: 250, mr: 4, borderRadius: '10px' }}
        size="small"
      >
        <InputLabel>Select The Date Format</InputLabel>

        <Select
          sx={{ borderRadius: '10' }}
          value={dateFormat}
          label="Select The Date Format"
          onChange={handleChange}
        >
          {possibleDateFormats.map((format, i) => (
            <MenuItem
              key={i}
              onClick={(e) => e.stopPropagation()}
              value={format}
            >
              {format}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
