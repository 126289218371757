import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  PreferenceButtonOptions,
  PreferenceOptionsEnum,
  ResumePreferenceOption,
} from '../../constants/preferences';
import { getEnginePreferenceRequest } from '../../../../views/optymization/network/recommendTrips.request';
import { useStores } from '../../../../store/root.context';
import { observer } from 'mobx-react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  OptymizeAlertStyles,
  OptymizeAlertTextStyles,
  OptymizeBtnGroupStyles,
  OptymizeBtnStyles,
} from '../../styles';
import { OptymizeIcon } from '../../../../static';
import { LOADAI_ELEMENTS } from '../../../../constants/elementIds';
import { useTranslation } from 'react-i18next';

interface PreferenceButtonProps {
  onClick?: () => void;
  onOpenDriverPreferences?: () => void;
  onResumePreviousOptimization?: (sandboxId: string) => void;
  driversCount: number;
  tripsCount: number;
  onStartOptimization?: () => void;
}

const PreferenceButton: React.FC<PreferenceButtonProps> = (props) => {
  const {
    dispatch2GlobalStore: { getBulkOptymizationInputs },
  } = useStores();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [preferenceOptions, setPreferenceOptions] = useState<
    { id: string; label: string; elId?: string }[]
  >([...PreferenceButtonOptions]);
  const [previousSandboxId, setPreviousSandboxId] = useState<string>();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setPreviousSandboxId(
      getBulkOptymizationInputs?.previousSandboxId as string
    );
    updatePreferenceMenuOptions(
      Boolean(getBulkOptymizationInputs?.previousSandboxId)
    );
  }, [getBulkOptymizationInputs?.previousSandboxId]);

  const handlePreferenceSelect = (pref: { id: string; label: string }) => {
    handleClose();
    if (pref.id === PreferenceOptionsEnum.CONFIGURE)
      return props?.onOpenDriverPreferences?.();
    if (pref.id === PreferenceOptionsEnum.OPTIMIZE)
      return props?.onStartOptimization?.();
    props?.onResumePreviousOptimization?.(previousSandboxId as string);
  };

  useEffect(() => {
    async function getPreferences() {
      try {
        const preferenceRes = await getEnginePreferenceRequest({});
        if (preferenceRes?.currentSandboxId?.length) {
          setPreviousSandboxId(preferenceRes?.currentSandboxId);
          updatePreferenceMenuOptions(Boolean(preferenceRes?.currentSandboxId));
        }
      } catch (error) {}
    }
    getPreferences();
  }, []);

  const updatePreferenceMenuOptions = (
    isPreviousSandboxAvailable?: boolean
  ) => {
    const options = [...PreferenceButtonOptions];
    if (isPreviousSandboxAvailable)
      options.splice(1, 0, ResumePreferenceOption);
    setPreferenceOptions(options);
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <>
        <ButtonGroup
          disableElevation
          variant="contained"
          aria-label="optymize-btn-container"
          sx={OptymizeBtnGroupStyles}
        >
          <Box sx={OptymizeAlertStyles}>
            {props?.driversCount >= 0 && (
              <Typography sx={OptymizeAlertTextStyles}>
                <b>{props?.driversCount}</b> {t('PreferenceButtonDrivers')}
              </Typography>
            )}
            {props?.tripsCount >= 0 && (
              <Typography sx={OptymizeAlertTextStyles}>
                <b>{props?.tripsCount}</b> {t('PreferenceButtonTrips')}
              </Typography>
            )}
          </Box>
          <Button
            id={LOADAI_ELEMENTS.OPTIMIZE_BUTTON}
            sx={OptymizeBtnStyles}
            onClick={props?.onStartOptimization}
          >
            <OptymizeIcon />
          </Button>
          <Button
            size="small"
            aria-controls={open ? 'optimization-group' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{ borderRadius: '10px', height: '100%' }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Menu
          id="preference-settings-menu"
          aria-labelledby="preference-settings-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ '.MuiPaper-root': { borderRadius: '14px' } }}
        >
          {preferenceOptions?.map((pref) => (
            <MenuItem
              key={pref?.id}
              onClick={() => handlePreferenceSelect(pref)}
              id={pref?.elId}
            >
              {pref?.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
};

export default observer(PreferenceButton);
