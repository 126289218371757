import React, { useEffect, useRef, useState } from 'react';
import { useObserver } from 'mobx-react';
import { Box, Grid } from '@mui/material';
import TabsV2 from '../../../../../ui-kit/components/TabsV2';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import {
  AccountingPopupEnum,
  accountingTabs,
  buttonsArray,
  getCustomerDefaultRequestData,
} from './constants';
import { MapInvoicePayItems } from './MapInvoicePayItems';
import { CustomerMapping } from './CustomerMapping';
import Invoices from './QuickbookInvoices';
import { accountingService } from '../../../../../api';
import {
  AccountingPopDTO,
  AccoutingDropDownDTO,
  GetAxeleMapPayItemsDTO,
  GetCustomerName,
  GetMapIncomeItemsDTO,
  GetMapPayItemsDTO,
  GetQBCustomersDTO,
  GetQBOCustomersContentDTO,
  GetQuickbookInvoicesRequest,
  QBInvoice,
  SaveIncomeCategoryDTO,
} from '../../../../../models';
import { ServiceError } from '../../../../../api/interfaces';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { gridPageSize } from '../../../../../utils';
import ClassMappingEntities from './ClassMappingEntities';
import ClassMappingActions from '../ClassMappingActions';

export const AccountingPopup = ({
  AccountCardId,
  entityType,
  terminalIds = [],
}: AccountingPopDTO) => {
  const { getGlobalTerminalsIds = [], getIsTerminalEnabled } = useRootStore();
  const [selectedTab, setSelectedTab] = useState<string>(accountingTabs[0].key);
  const [connectionStatus, setConnectionStatus] = useState<boolean | undefined>(
    false
  );
  const [customerData, setCustomerData] = useState<GetQBOCustomersContentDTO[]>(
    []
  );
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [sendToQBDisabled, setSendToQBDisabled] = useState<boolean>(true);
  const [axeleMapPayItems, setAxeleMapPayItems] = useState<
    GetAxeleMapPayItemsDTO[]
  >([]);
  const [QBOIncomeCategory, setQBOIncomeCategory] = useState<
    GetMapPayItemsDTO[]
  >([]);
  const [mapIncomeDropdownItems, setMapIncomeDropdownItems] = useState<
    AccoutingDropDownDTO[]
  >([]);
  const [customerFilterData, setCustomerFilterData] = useState<string[]>([]);
  const [statusFilterData, setStatusFilterData] = useState<{} | null>(null);
  const [invoices, setInvoices] = useState<QBInvoice[] | null>(null);
  const [totalDataInTable, setTableTotalData] = useState<number | undefined>(0);
  const [totalInvoices, setTotalInvoices] = useState<number | undefined>(0);
  const [customerRequestData, setCustomerRequestData] = useState(
    getCustomerDefaultRequestData
  );
  const [areFiltersApplied, setFiltersApplied] = useState(false);
  const [isClassMappingActive, setIsClassMappingActive] = useState(false);
  const [classMappingRefreshData, setClassMappingRefreshData] =
    useState<any>(null);

  const isInvoiceLast = useRef(true);

  const defaultFilterValue = {
    customerFilter: customerFilterData,
    statusFilter: statusFilterData,
  };

  useEffect(() => {
    getPopUpData();
    resetFilters();
  }, [selectedTab]);

  useEffect(() => {
    setCustomerRequestData({
      pageNumber: 1,
      pageSize: 25,
      sort: `+name`,
      synchFilter: null,
      axeleCustomerIds: [],
      qbAccountId: AccountCardId,
    });
  }, [AccountCardId]);

  const resetFilters = () => {
    setCustomerFilterData(customerFilterData);
    setStatusFilterData({ label: 'All', value: null });
  };

  useEffect(() => {
    getQBCustomersData(true);
  }, [customerRequestData]);

  useEffect(() => {
    if (connectionStatus) {
      const getIsClassMappingActive = async () => {
        const isActive = await ClassMappingActions.getIsClassMappingActive(
          AccountCardId
        );
        setIsClassMappingActive(isActive);
      };
      getIsClassMappingActive();
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (entityType === AccountingPopupEnum.CUSTOMER) {
      setSelectedTab(accountingTabs[1].key);
    } else if (entityType === AccountingPopupEnum.INVOICES) {
      setSelectedTab(accountingTabs[2].key);
    } else if (entityType === AccountingPopupEnum.CLASS_MAPPING) {
      setSelectedTab(accountingTabs[3].key);
    } else {
      setSelectedTab(accountingTabs[0].key);
    }
  }, []);

  const getInvoices = async () => {
    const request = new GetQuickbookInvoicesRequest();
    request.pageNumber = 1;
    request.pageSize = gridPageSize;
    request.qbAccountId = AccountCardId;
    let currentTerminalIds = terminalIds;
    if (getGlobalTerminalsIds && getGlobalTerminalsIds.length) {
      currentTerminalIds = terminalIds.filter(function (n) {
        return getGlobalTerminalsIds.indexOf(n) !== -1;
      });
    }
    request.terminalIds = currentTerminalIds;
    if (
      !currentTerminalIds.length &&
      getIsTerminalEnabled &&
      terminalIds?.length
    ) {
      setInvoices([]);
      return;
    }
    const response = await accountingService.getQBInvoices(request);
    if (!(response instanceof ServiceError)) {
      const {
        content,
        last,
        totalElements,
        connectionDTO: { connected },
      } = response;
      setTotalInvoices(totalElements);
      isInvoiceLast.current = last;
      setInvoices(content);
      setConnectionStatus(connected);
    }
  };

  const getPopUpData = async () => {
    switch (selectedTab) {
      case AccountingPopupEnum.MAPINVOICEITEMS:
        await getMapPayItemsCategory();
        await getMapIncomeItems();
        await getAxeleMapPayItemsCategory();
        break;
      case AccountingPopupEnum.CUSTOMERMAPPING:
        await getQBCustomersData();
        break;
      case AccountingPopupEnum.INVOICES:
        await getInvoices();
      case AccountingPopupEnum.CLASS_MAPPING:
      // class
    }
  };

  const getMapPayItemsCategory = async () => {
    const mapPayItemsRes = await accountingService.getMapPayItems(
      AccountCardId
    );
    if (mapPayItemsRes) {
      setQBOIncomeCategory(mapPayItemsRes.incomeCategories);
      setConnectionStatus(mapPayItemsRes?.connectionDTO?.connected);
    }
  };

  const getAxeleMapPayItemsCategory = async () => {
    const axeleMapPayItemsRes = await accountingService.getAxeleMapPayItems();
    if (axeleMapPayItemsRes) {
      setAxeleMapPayItems(axeleMapPayItemsRes as GetAxeleMapPayItemsDTO[]);
    }
  };

  const getMapIncomeItems = async () => {
    const queryData = {
      qbAccountId: AccountCardId,
    };
    const queryParams = new GetMapIncomeItemsDTO(queryData);
    const mapIncomeItems = await accountingService.getMapIncomeItems(
      queryParams
    );
    if (mapIncomeItems) {
      createDropDownOptions(mapIncomeItems);
    }
  };

  const createDropDownOptions = (dropDownOptions: string[] | null) => {
    const options: AccoutingDropDownDTO[] = [];
    if (dropDownOptions !== null) {
      dropDownOptions.map((item: string) => {
        options.push({ label: item });
      });
    }
    setMapIncomeDropdownItems(options);
  };

  const getQBCustomersData = async (scrolling = false) => {
    const queryData = {
      pageNumber: customerRequestData.pageNumber,
      pageSize: customerRequestData.pageSize,
      sort: customerRequestData.sort,
      synchFilter: customerRequestData.synchFilter,
      axeleCustomerIds: customerRequestData.axeleCustomerIds,
      qbAccountId: AccountCardId,
    };
    areFiltersApplied && setCustomerData([]);
    const queryParams = new GetQBCustomersDTO(queryData);
    const customerData = await accountingService.getQBOCustomers(queryParams);
    if (customerData) {
      setTableTotalData(customerData?.totalElements);
      setConnectionStatus(customerData?.connectionDTO?.connected);
      if (scrolling) {
        setFiltersApplied(false);
        setCustomerData((oldData) => [...oldData, ...customerData.content]);
      } else {
        setCustomerData(customerData.content);
      }
    }
  };

  const getCustomerName = async (customerName: string, pageNumber: number) => {
    const request = new GetCustomerName();
    request.pageNumber = pageNumber;
    request.pageSize = 25;
    request.customerName = customerName;
    request.qbAccountId = AccountCardId;
    const response = accountingService.getCustomerName(request);
    if (response instanceof ServiceError) {
      return [];
    } else {
      return response;
    }
  };

  const handleRefreshClassMapping = async () => {
    const result = await ClassMappingActions.refreshClassMappingType(
      AccountCardId
    );
    if (result) {
      setClassMappingRefreshData(result);
    }
  };

  const handleOnChangeCb = (newData: any, name: string) => {
    if (name === AccountingPopupEnum.CUSTOMERFILTER) {
      const searchParam = newData.map((item: any) => item.customerId);
      setCustomerFilterData(newData);
      setFiltersApplied(true);
      setCustomerRequestData({
        pageNumber: 1,
        pageSize: 25,
        sort: `+name`,
        synchFilter: statusFilterData?.value,
        axeleCustomerIds: searchParam,
        qbAccountId: AccountCardId,
      });
    }
    if (name === AccountingPopupEnum.STATUSFILTER) {
      const searchParam = newData !== null ? newData.value : null;
      setStatusFilterData(newData);
      const customerIds = customerFilterData.map(
        (item: any) => item.customerId
      );
      setFiltersApplied(true);
      setCustomerRequestData({
        pageNumber: 1,
        pageSize: 25,
        sort: `+name`,
        synchFilter: searchParam,
        axeleCustomerIds: customerIds,
        qbAccountId: AccountCardId,
      });
    }
  };

  const customerFilterColumnConfig = [
    {
      key: 1,
      name: 'customerFilter',
      fieldName: 'name',
      getData: getCustomerName,
      label: 'Customer',
      default: true,
      type: 'MultipleAutocomplete',
      disable: !connectionStatus,
    },
    {
      key: 2,
      name: 'statusFilter',
      fieldName: 'label',
      options: [
        { label: 'All', value: null },
        { label: 'Synced', value: 'true' },
        { label: 'Not Synced', value: 'false' },
      ],
      label: 'Status',
      default: true,
      type: 'SingleAutocomplete',
      disable: !connectionStatus,
    },
  ];

  const handleClick = (methodName: string) => {
    switch (methodName) {
      case AccountingPopupEnum.HANDLEQBOCONNECT:
        handleQBOConnect();
        break;
      case AccountingPopupEnum.HANDLEQBODISCONNECT:
        handleQBODisconnect();
        break;
      case AccountingPopupEnum.HANDLEUPDATEQBOCATEGORY:
        handleUpdateQBOCategory();
        break;
      case AccountingPopupEnum.HANDLESENDTOQBO:
        handleSendToQBO();
        break;
      case AccountingPopupEnum.HANDLEFETCHCUSTOMER:
        handleFetchCustomer();
        break;
      case AccountingPopupEnum.HANDLEREFRESHCLASSMAPPING:
        handleRefreshClassMapping();
        break;
    }
  };

  const handleQBOConnect = async () => {
    const connectionRes = await accountingService.connectToQBOnline(
      AccountCardId
    );
    if (connectionRes) {
      setConnectionStatus(connectionRes.connected);
      getPopUpData();
    }
  };

  const handleUpdateQBOCategory = async () => {
    const updateQBOCategoryRes = await accountingService.updateQBOItemsCategory(
      AccountCardId
    );
    if (updateQBOCategoryRes) {
      setQBOIncomeCategory(updateQBOCategoryRes.incomeCategories);
      setConnectionStatus(updateQBOCategoryRes?.connectionDTO?.connected);
    }
  };

  const handleSendToQBO = async () => {
    const payload = {
      ids: selectedCustomers,
      qbAccountId: AccountCardId,
    };
    const sendDataRes = await accountingService.sendCustomersToQB(payload);
    if (sendDataRes) {
      setSelectedCustomers([]);
      await getQBCustomersData();
    }
  };

  const handleFetchCustomer = async () => {
    const queryData = {
      pageNumber: 1,
      pageSize: 100,
      sort: `+name`,
      synchFilter: null,
      axeleCustomerIds: [],
      qbAccountId: AccountCardId,
    };
    const queryParams = new GetQBCustomersDTO(queryData);
    const customerData = await accountingService.fetchQBOCustomers(queryParams);
    if (customerData) {
      setCustomerData(customerData.content);
      setConnectionStatus(customerData?.connectionDTO?.connected);
    }
  };

  const handleCheckBoxSelect = (ids: string[]) => {
    setSelectedCustomers(ids);
    if (ids.length > 0) {
      setSendToQBDisabled(false);
    } else {
      setSendToQBDisabled(true);
    }
  };

  const handleAutocompleteChnage = (
    event: Event,
    newValue: any,
    error: boolean,
    id: string
  ) => {
    if (newValue !== null) {
      const payload = {
        axeleIncomeCategoryId: id,
        qbIncomeCategory: newValue.label,
        qbAccountId: AccountCardId,
      };
      saveIncomeCategory(payload);
    }
  };

  const saveIncomeCategory = async (payload: SaveIncomeCategoryDTO) => {
    await accountingService.savePayItemMapping(payload);
    await getMapPayItemsCategory();
  };

  const handleCustomerScroll = (event: any) => {
    const updatedRequestData = {
      ...customerRequestData,
      pageNumber: customerRequestData.pageNumber + 1,
    };
    setCustomerRequestData(updatedRequestData);
  };

  const visibleTabs =
    isClassMappingActive && connectionStatus
      ? accountingTabs
      : accountingTabs.filter(
          (tab) => tab.key !== AccountingPopupEnum.CLASS_MAPPING
        );

  return useObserver(() => (
    <Box sx={{ height: '100%' }}>
      <Grid
        id="header-container"
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        position={'relative'}
      >
        <Grid id="tab-panel" item sx={{ width: '100%', height: '100%' }}>
          <TabsV2
            keyName="key"
            isDarkMode
            labelCentered
            tabs={visibleTabs}
            selectedTab={selectedTab}
            setSelectedTab={(tab) => setSelectedTab(tab.key)}
          />
        </Grid>
        <Grid
          id="button-panel"
          item
          sx={{ position: 'absolute', top: 0, right: '10px', zIndex: 1 }}
        >
          {buttonsArray.map(
            ({
              label,
              variant,
              icon,
              onClick,
              styles,
              section,
              visibiltyType,
              disabled,
            }) => {
              if (
                visibiltyType === AccountingPopupEnum.CONNECTED &&
                !connectionStatus
              )
                return;
              if (
                visibiltyType === AccountingPopupEnum.DISCONNECTED &&
                connectionStatus
              )
                return;
              return (
                section.includes(selectedTab) && (
                  <ButtonImproved
                    key={label}
                    label={label}
                    variant={
                      variant as 'contained' | 'outlined' | 'text' | undefined
                    }
                    onClick={() => handleClick(onClick)}
                    startIcon={icon}
                    styleProps={{ height: '36px', fontSize: '14px', ...styles }}
                    disabled={
                      (!connectionStatus && disabled) ||
                      (label === AccountingPopupEnum.SENDTOQUICKBOOKS &&
                        sendToQBDisabled)
                    }
                  />
                )
              );
            }
          )}
        </Grid>
      </Grid>
      <Grid id="body-container" container sx={{ height: '100%' }}>
        {selectedTab === AccountingPopupEnum.MAPINVOICEITEMS && (
          <MapInvoicePayItems
            axeleMapPayItems={axeleMapPayItems}
            QBOIncomeCategory={QBOIncomeCategory}
            mapIncomeDropdownItems={mapIncomeDropdownItems}
            handleAutocompleteChnage={handleAutocompleteChnage}
            connectionStatus={connectionStatus}
          />
        )}
        {selectedTab === AccountingPopupEnum.CUSTOMERMAPPING && (
          <CustomerMapping
            customerData={customerData}
            filterColumns={customerFilterColumnConfig}
            handleSelect={handleCheckBoxSelect}
            selectedCustomers={selectedCustomers}
            defaultFilterValue={defaultFilterValue}
            handleOnChangeCb={handleOnChangeCb}
            totalDataInTable={totalDataInTable}
            handleCustomerScroll={handleCustomerScroll}
          />
        )}
        {selectedTab === AccountingPopupEnum.INVOICES && invoices && (
          <Invoices
            qbAccountId={AccountCardId}
            invoices={invoices}
            terminalIds={terminalIds}
            last={isInvoiceLast.current}
            totalDataInTable={totalInvoices}
            isClassMappingActive={isClassMappingActive}
          />
        )}
        {selectedTab === AccountingPopupEnum.CLASS_MAPPING && (
          <ClassMappingEntities
            terminalIds={terminalIds}
            qbAccountId={AccountCardId}
            refreshData={classMappingRefreshData}
            setRefreshData={setClassMappingRefreshData}
          />
        )}
      </Grid>
    </Box>
  ));
};
