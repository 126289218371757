import { GridColDefSelf } from '@/types';
import { currencyDisplay } from '@/utils/grid.utils';
import { getDriverNamesList } from '@/views/settings/users/utils';
import TractorController from '@/subPages/tractors/Controller';
import { nullableNumberYup } from '@/utils';
import * as yup from 'yup';
import { array } from 'yup';

export const TransactionRecordsColumns: GridColDefSelf[] = [
  {
    flex: 1,
    field: 'invoice',
    headerName: 'Invoice #',
    minWidth: 210,
    sortable: false,
  },
  {
    flex: 1,
    field: 'item',
    headerName: 'Item',
    minWidth: 210,
    sortable: false,
  },
  {
    flex: 1,
    field: 'amount',
    headerName: 'Net Amount',
    minWidth: 210,
    sortable: false,
    renderCell: (params) => currencyDisplay(params.value),
  },
  {
    flex: 1,
    field: 'discAmount',
    headerName: 'Discount Amount',
    minWidth: 210,
    sortable: false,
    renderCell: (params) => currencyDisplay(params.value),
  },
  {
    flex: 1,
    field: 'grossAmount',
    headerName: 'Gross Amount',
    minWidth: 210,
    sortable: false,
    renderCell: (params) => currencyDisplay(params.value),
  },
  {
    flex: 1,
    field: 'driverName',
    headerName: 'Driver',
    minWidth: 210,
    sortable: false,
  },
  {
    flex: 1,
    field: 'unit',
    headerName: 'Tractor',
    minWidth: 210,
    sortable: false,
  },
  {
    flex: 1,
    field: 'expenseId',
    headerName: 'Expense ID',
    minWidth: 210,
    sortable: false,
  },
  {
    flex: 1,
    field: 'isSynced',
    headerName: 'Sync Status',
    minWidth: 210,
    sortable: false,
    renderCell: (params) => (params.value ? 'Synced' : 'Not Synced'),
  },
];

export const filtersConfig = [
  {
    key: 1,
    name: 'driverRoleFilter',
    fieldName: 'value',
    fieldVale: 'value',
    options: [
      { key: 'OWNER_OPERATOR', value: 'Owner Operator' },
      { key: 'EMPLOYEE', value: 'Employee' },
    ],
    label: 'Employment Type',
    type: 'SingleAutocomplete',
    default: true,
  },
  {
    key: 2,
    name: 'driverIdsFilter',
    fieldName: 'name',
    getData: getDriverNamesList,
    label: 'Driver',
    default: true,
    type: 'MultipleAutocomplete',
  },
  {
    key: 3,
    name: 'tractorIdsFilter',
    fieldName: 'name',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getTractorLightListByNameSearch(
        searchText,
        pageNumber,
        [],
        ['INACTIVE']
      ),
    label: 'Tractor',
    default: true,
    type: 'MultipleAutocomplete',
  },
];

export const transactionRecordsFormValidationSchema = yup.object().shape({
  percentageToReimburse: nullableNumberYup().when(
    ['deductionType'],
    (deductionType, schema) => {
      if (['driversDeduction', 'tractorsDeduction'].includes(deductionType)) {
        return schema
          .required('Field is mandatory')
          .min(0, 'Value must be between 0 and 100')
          .max(100, 'Value must be between 0 and 100');
      }
      return schema;
    }
  ),
});
