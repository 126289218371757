import { ServiceError } from './helperTypes';
import { Service } from './Service';
import {
  DeactivateApiKeyRequest,
  DeleteApiKeyRequest,
  GenerateApiKeyRequest,
  GetCompanyLogoRequest,
  GetPreferencesRequest,
  UpdatePreferencesRequest,
} from '../../models/DTOs/Preferences/Requests';
import { PreferencesType } from '../../models';
import { docType } from '../../utils/Doc';

export abstract class IPreferencesService extends Service {
  abstract getPreferences(
    requestData: GetPreferencesRequest
  ): Promise<PreferencesType | ServiceError>;

  abstract generateApiKey(
    requestData: GenerateApiKeyRequest
  ): Promise<PreferencesType | ServiceError>;

  abstract deleteApiKey(
    requestData: DeleteApiKeyRequest
  ): Promise<PreferencesType | ServiceError>;

  abstract deactivateApiKey(
    requestData: DeactivateApiKeyRequest
  ): Promise<PreferencesType | ServiceError>;

  abstract activateApiKey(
    requestData: DeactivateApiKeyRequest
  ): Promise<PreferencesType | ServiceError>;

  abstract getCompanyLogo(
    requestData: GetCompanyLogoRequest
  ): Promise<docType | ServiceError>;

  abstract updatePreferences(
    requestData: UpdatePreferencesRequest
  ): Promise<PreferencesType | ServiceError>;
  abstract updateLogo(file: docType): Promise<docType | ServiceError>;
}
