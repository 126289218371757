import { ComposedError, httpClient } from '../../axios/axiosInstance';
import * as FinanceOverviewModel from '../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewModel';
import { QueryParams, UpdateStatusResponse } from '../../models';
import { ByLoadIdRequest } from '../../models/DTOs';
import {
  FinanceLoadListRequest,
  FinanceLoadType,
  GetFinanceLoadByIDRequest,
  GetFinanceLoadByLoadIDRequest,
  GetFinanceLoadItemDriverIntialRequest,
  GetFinanceLoadItemTractorIntialRequest,
  GetFinanceLoadRequest,
  GetFinancialItemRequest,
  LoadCarrierExpenseDTO,
  LoadCarrierExpenseDTORequest,
  LoadCarrierExpenseShortDTO,
} from '../../models/DTOs/FinanceLoadService';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { fixUserDefinedItems } from '../../subPages/invoices/utils';
import {
  IFinanceLoadService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';
import { isAxiosErrorCancel } from './DispatchService';
import {
  CARRIER_PAYMENT_RESOURCES,
  GetAutopopulateDispatcherURL,
  GetAutopopulateDriverURL,
  GetAutopopulateTractorURL,
  createDispatcherExpenseManualURL,
  createDriverExpenseManualURL,
  createTractorExpenseManualURL,
  deleteDispatcherPaymentExpenseURL,
  deleteDriverPaymentExpenseURL,
  deleteTractorPaymentExpenseURL,
  dispatcherStatementInfoURL,
  driverAccessURL,
  driverStatementInfoURL,
  financeLoadCreateLineItemURL,
  financeLoadDeleteLineItemURL,
  financeLoadDispatcherCreateLineItemURL,
  financeLoadDispatcherDeleteLineItemURL,
  financeLoadDispatcherInitialCreateLineItemURL,
  financeLoadDispatcherURL,
  financeLoadDownloadURL,
  financeLoadDriverURL,
  financeLoadInitialCreateLineItemURL,
  financeLoadTractorCreateLineItemURL,
  financeLoadTractorDeleteLineItemURL,
  financeLoadTractorInitialCreateLineItemURL,
  financeLoadTractorURL,
  financeLoadUpdateLineItemURL,
  financeLoaddDispatcherUpdateLineItemURL,
  financeLoaddTractorUpdateLineItemURL,
  financialItemURL,
  getExpenseCategoriesURLFinance,
  getFinanceRevenueShareURL,
  invoiceURl,
  tractorStatementInfoURL,
} from './requestConstants';

export class FinanceLoadService extends IFinanceLoadService {
  @annotateNameAsync
  async GetLoadFinanceInitial(loadId: ByLoadIdRequest) {
    try {
      return await httpClient.get<FinanceLoadType>(
        getExpenseCategoriesURLFinance,
        loadId,
        FinanceLoadType,
        false, //Important: Need set to false because there are 2 tabs of TripDetailsPanel in Fullscreen mode use this API (Finance/Carrier Payment)
        true
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async GetFinancialItem(requestData: GetFinancialItemRequest) {
    try {
      return await httpClient.get<FinanceLoadListRequest[]>(
        financialItemURL,
        requestData,
        FinanceLoadListRequest,
        false,
        true
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async GetinvoiceDetail(loadId: string) {
    const requestData = new GetFinanceLoadRequest(loadId);
    try {
      const result = await httpClient.get<FinanceLoadType>(
        invoiceURl,
        requestData,
        null,
        undefined,
        true
      );
      result.lineItems = fixUserDefinedItems(result.lineItems);
      return result;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async GetFinanceLoadDriverList(id: string) {
    const requestData = new GetFinanceLoadByIDRequest(id);
    try {
      return await httpClient.get<FinanceOverviewModel.ListDataType>(
        financeLoadDriverURL,
        requestData,
        null,
        undefined,
        true
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async GetFinanceLoadTractorList(id: string) {
    const requestData = new GetFinanceLoadByIDRequest(id);
    try {
      return await httpClient.get<FinanceOverviewModel.ListDataType>(
        financeLoadTractorURL,
        requestData,
        null,
        undefined,
        true
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async GetFinanceLoadDispatcherList(id: string) {
    const requestData = new GetFinanceLoadByLoadIDRequest(id);
    try {
      return await httpClient.get<FinanceOverviewModel.ListDataType>(
        financeLoadDispatcherURL,
        requestData,
        null,
        undefined,
        true
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async CreateFinanceLoadLineItem(requestData: any) {
    try {
      const response = await httpClient.postRaw(
        financeLoadCreateLineItemURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadAdd',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async UpdateFinanceLoadLineItem(requestData: any) {
    try {
      const response = await httpClient.put<any>(
        financeLoadUpdateLineItemURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'financeOverviewUpdate',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async DeleteFinanceLoadLineItem(requestData: any) {
    try {
      const response = await httpClient.delete<any>(
        financeLoadDeleteLineItemURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'financeOverviewDelete',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async CreateFinanceLoadDispatcherLineItem(requestData: any) {
    try {
      const response = await httpClient.postRaw(
        financeLoadDispatcherCreateLineItemURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadAdd',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async UpdateFinanceLoadDispatcherLineItem(requestData: any) {
    try {
      const response = await httpClient.put<any>(
        financeLoaddDispatcherUpdateLineItemURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'financeOverviewUpdate',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createNewLoadFinanceExpense(
    type: string,
    requestData: FinanceOverviewModel.CreateLoadFinanceExpenseDTO
  ) {
    try {
      let url = createDispatcherExpenseManualURL;
      switch (type) {
        case FinanceOverviewModel.EPaymentDetailType.Driver:
          url = createDriverExpenseManualURL;
          break;
        case FinanceOverviewModel.EPaymentDetailType.Tractor:
          url = createTractorExpenseManualURL;
          break;
      }
      const response = await httpClient.post<any>(
        url,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'financeExpenseCreated',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeExpenseCreated',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteLoadFinanceExpense(
    type: string,
    reqParams: FinanceOverviewModel.DeleteFinanceExpense
  ) {
    try {
      let url = deleteDispatcherPaymentExpenseURL;
      switch (type) {
        case FinanceOverviewModel.EPaymentDetailType.Driver:
          url = deleteDriverPaymentExpenseURL;
          break;
        case FinanceOverviewModel.EPaymentDetailType.Tractor:
          url = deleteTractorPaymentExpenseURL;
          break;
      }
      const response = await httpClient.delete<any>(
        url,
        reqParams,
        undefined,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteFinanceExpense',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'deleteFinanceExpense',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async DeleteFinanceLoadDispatcherLineItem(requestData: any) {
    try {
      const response = await httpClient.delete<any>(
        financeLoadDispatcherDeleteLineItemURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'financeOverviewDelete',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async CreateFinanceLoadTractorLineItem(requestData: any) {
    try {
      const response = await httpClient.postRaw(
        financeLoadTractorCreateLineItemURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadAdd',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async UpdateFinanceLoadTractorLineItem(requestData: any) {
    try {
      const response = await httpClient.put<any>(
        financeLoaddTractorUpdateLineItemURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'financeOverviewUpdate',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async DeleteFinanceLoadTractorLineItem(requestData: any) {
    try {
      const response = await httpClient.delete<any>(
        financeLoadTractorDeleteLineItemURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'financeOverviewDelete',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async CreateFinanceLoadDriverInitialLineItem(data: any) {
    const requestData = new GetFinanceLoadItemDriverIntialRequest();
    try {
      const response = await httpClient.post<any>(
        financeLoadInitialCreateLineItemURL +
          `?organizationId=${requestData.organizationId}&loadId=${data.loadId}&driverId=${data.driverId}`,
        undefined,
        requestData,
        false
      );
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async CreateFinanceLoadTractorInitialLineItem(data: any) {
    const requestData = new GetFinanceLoadItemTractorIntialRequest();
    try {
      const response = await httpClient.post<any>(
        financeLoadTractorInitialCreateLineItemURL +
          `?organizationId=${requestData.organizationId}&loadId=${data.loadId}&tractorId=${data.tractorId}`,
        undefined,
        requestData,
        false
      );
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async CreateFinanceLoadDispatcherInitialLineItem(data: string) {
    const requestData = new GetFinanceLoadByLoadIDRequest(data);
    try {
      return await httpClient.post<any>(
        financeLoadDispatcherInitialCreateLineItemURL +
          `/?organizationId=${requestData.organizationId}&loadId=${data}`,
        undefined,
        requestData,
        false
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async GetfinanceLoadDownload(data: any) {
    const requestData = new GetFinanceLoadByLoadIDRequest(data);
    try {
      return await httpClient.get<any>(
        financeLoadDownloadURL +
          `?${data.driverId}&loadId=${data.loadId}&${data.type}&organizationId=${requestData.organizationId}`,
        undefined,
        null,
        undefined,
        true
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async GetAutopopulateDriver(requestData: string) {
    try {
      return await httpClient.put<any>(
        GetAutopopulateDriverURL + requestData,
        undefined,
        requestData,
        false
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async GetAutopopulateTractor(requestData: string) {
    try {
      return await httpClient.put<any>(
        GetAutopopulateTractorURL + requestData,
        undefined,
        requestData,
        false
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async GetAutopopulateDispatcher(requestData: string) {
    try {
      return await httpClient.put<any>(
        GetAutopopulateDispatcherURL + requestData,
        undefined,
        requestData,
        false
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async GetDriverStatuesStatementInfo(
    requestData: FinanceOverviewModel.requestParamsFinanceOverview
  ) {
    try {
      const response = await httpClient.getRaw(
        driverStatementInfoURL +
          `?entityId=${requestData?.entityId}&loadId=${requestData.loadId}&organizationId=${requestData.organizationId}&entityType=${requestData?.entityType}`,
        undefined
      );
      return response.data;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async GetTractorStatuesStatementInfo(
    requestData: FinanceOverviewModel.requestParamsFinanceOverview
  ) {
    try {
      const response = await httpClient.getRaw(
        tractorStatementInfoURL +
          `?entityId=${requestData?.entityId}&loadId=${requestData.loadId}&organizationId=${requestData.organizationId}&entityType=${requestData?.entityType}`,
        undefined
      );
      return response.data;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async GetDispatcherStatuesStatementInfo(
    requestData: FinanceOverviewModel.requestParamsFinanceOverview
  ) {
    try {
      const response = await httpClient.getRaw(
        dispatcherStatementInfoURL +
          `?entityId=${requestData.entityId}&loadId=${requestData.loadId}&organizationId=${requestData.organizationId}&entityType=${requestData?.entityType}`,
        undefined
      );
      return response.data;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async UpdateTractorDriverAccess(
    payloadData: FinanceOverviewModel.UpdateTractorDriverAccessQuery
  ) {
    try {
      return await httpClient.put<any>(
        driverAccessURL +
          `?tractorExpenseId=${payloadData.tractorExpenseId}&shareWithDriver=${payloadData.shareWithDriver}&organizationId=${payloadData.organizationId}`,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'paymentPerLoadGeneral',
        });
      }
      return Promise.resolve(null);
    }
  }

  //Carrier module begin
  async GetFinanceLoadCarrierList(
    tripId: string
  ): Promise<LoadCarrierExpenseDTO | ServiceError | null> {
    const queryParams: any = new QueryParams();
    queryParams.tripId = tripId;
    try {
      const response = await httpClient.get<LoadCarrierExpenseDTO>(
        CARRIER_PAYMENT_RESOURCES.getCarrierPaymentByTripId,
        queryParams,
        LoadCarrierExpenseDTO,
        true,
        true
      );
      return new LoadCarrierExpenseDTO(response);
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }

      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getTripCarrierAssignmentPreview(
    tripId: string,
    carrierId: string
  ): Promise<LoadCarrierExpenseDTO | ServiceError | null> {
    const queryParams: any = new QueryParams();
    queryParams.tripId = tripId;
    queryParams.carrierId = carrierId;
    try {
      const response = await httpClient.get<LoadCarrierExpenseDTO>(
        CARRIER_PAYMENT_RESOURCES.getTripCarrierAssignmentPreview,
        queryParams,
        LoadCarrierExpenseDTO,
        true,
        true
      );
      return new LoadCarrierExpenseDTO(response);
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }

      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  async CreateFinanceLoadCarrierInitialLineItem(
    tripId: string
  ): Promise<LoadCarrierExpenseDTO | ServiceError | null> {
    const requestData: any = new QueryParams(true);
    requestData.tripId = tripId;
    try {
      const queryParams: any = new QueryParams(true);
      queryParams.tripId = tripId;
      const response = await httpClient.post<LoadCarrierExpenseDTO>(
        CARRIER_PAYMENT_RESOURCES.createCarrierPayment,
        queryParams,
        requestData,
        LoadCarrierExpenseDTO,
        false,
        undefined,
        true
      );
      return new LoadCarrierExpenseDTO(response);
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'financeOverviewGeneral',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async updateLoadCarrierExpense(
    payload: LoadCarrierExpenseDTORequest
  ): Promise<LoadCarrierExpenseDTO | ServiceError | null> {
    try {
      const response = await httpClient.put<LoadCarrierExpenseDTO>(
        CARRIER_PAYMENT_RESOURCES.updateCarrierPayment,
        undefined,
        payload,
        LoadCarrierExpenseDTO,
        false,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateLoadCarrierExpense',
      });
      return new LoadCarrierExpenseDTO(response);
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'updateLoadCarrierExpense',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async updateCarrierPaymentStatus(
    payload: LoadCarrierExpenseShortDTO
  ): Promise<LoadCarrierExpenseDTO | ServiceError | null> {
    try {
      const response = await httpClient.put<LoadCarrierExpenseDTO>(
        CARRIER_PAYMENT_RESOURCES.updateCarrierPaymentStatus,
        undefined,
        payload,
        LoadCarrierExpenseDTO,
        false,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateLoadCarrierExpense',
      });
      return new LoadCarrierExpenseDTO(response);
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'updateLoadCarrierExpense',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  async deleteCarrierPaymentById(
    carrierPaymentId: string
  ): Promise<UpdateStatusResponse | ServiceError | null> {
    try {
      const queryParams: any = new QueryParams(true);
      queryParams.id = carrierPaymentId;
      const response = await httpClient.delete<UpdateStatusResponse>(
        CARRIER_PAYMENT_RESOURCES.deleteCarrierPaymentById,
        queryParams,
        undefined,
        UpdateStatusResponse,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteCarrierPayment',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'deleteCarrierPayment',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  async deleteCarrierPaymentByTripId(
    tripId: string
  ): Promise<UpdateStatusResponse | ServiceError | null> {
    try {
      const queryParams: any = new QueryParams(true);
      queryParams.tripId = tripId;
      const response = await httpClient.delete<UpdateStatusResponse>(
        CARRIER_PAYMENT_RESOURCES.deleteCarrierPaymentTripId,
        queryParams,
        undefined,
        UpdateStatusResponse,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteCarrierPayment',
      });
      return response;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'deleteCarrierPayment',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  //Carrier module end
  @annotateNameAsync
  async updateFinanceRevenueShare(
    requestData: FinanceOverviewModel.IFinanceRevenueSharePayLoadType
  ) {
    try {
      const response = await httpClient.put<{ message: string }>(
        getFinanceRevenueShareURL,
        undefined,
        requestData,
        false
      );
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'financeOverviewGeneral',
      });

      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
