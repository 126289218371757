import { createContext, FC, useEffect, useState } from 'react';
import {
  IMaintenanceHistory,
  MaintenanceHistory,
} from '../../../../models/DTOs/maintenance/maintenanceHistory/Model';
import {
  IMaintenanceItem,
  MaintenanceItem,
} from '../../../../models/DTOs/maintenance/maintenanceItem/Model';
import { PaginatedResult } from '../../../../types';
import {
  IMaintenanceHistoryListPagination,
  IMaintenanceRecordFilters,
} from '../../../../views/maintenanceRecords/constants/types';
import MaintenanceController from '../controllers/Controller';
import { groupedMaitHistoriesByTimeline } from '../utils';
import { PaginatedMaintenanceHistoryListRequest } from '../../../../models/DTOs/maintenance/maintenanceHistory/Requests';
export interface IGroupedMaitHistoriesByTimeline {
  thisMonth: IMaintenanceHistory[];
  thisYear: IMaintenanceHistory[];
  theRest: IMaintenanceHistory[];
}

interface MaintenanceHistoryContextInterface {
  filters: IMaintenanceRecordFilters;

  maintenanceItems: IMaintenanceItem[];
  // editingMaintenanceHistory: IMaintenanceItem | undefined;
  maintenanceHistoryListPagination: IMaintenanceHistoryListPagination;
  maintenanceHistoryList: IGroupedMaitHistoriesByTimeline;

  totalAmountMaintCostYTD: number;

  setFilters: React.Dispatch<React.SetStateAction<IMaintenanceRecordFilters>>;
  resetFilters: () => void;
  // setEditingMaintenanceHistory: React.Dispatch<
  //   React.SetStateAction<IMaintenanceHistory | null>
  // >;
  setMaintenanceHistoryListPagination: React.Dispatch<
    React.SetStateAction<IMaintenanceHistoryListPagination>
  >;

  getMaintenanceItems: () => Promise<void>;
  getPaginatedMaintenanceHistoryList: (
    queryParams: PaginatedMaintenanceHistoryListRequest
  ) => Promise<void>;
}
const defaultFilters: IMaintenanceRecordFilters = {
  maintenanceItems: [],
  equipmentTypes: [],
  assetId: [],
  vendor: [],
  sort: '-completedDate',
};

const defaultMaintenanceHistoryListPagination: IMaintenanceHistoryListPagination =
  {
    isLoading: false,
    first: false,
    last: false,
    pageNumber: 1,
    pageSize: 40,
  };

const defaultGroupedMaintenanceHistoryList: IGroupedMaitHistoriesByTimeline = {
  thisMonth: [],
  thisYear: [],
  theRest: [],
};

export const MaintenanceHistoryContext =
  createContext<MaintenanceHistoryContextInterface>({
    filters: defaultFilters,
    maintenanceItems: [],
    maintenanceHistoryList: defaultGroupedMaintenanceHistoryList,
    maintenanceHistoryListPagination: defaultMaintenanceHistoryListPagination,
    totalAmountMaintCostYTD: 0,
  } as unknown as MaintenanceHistoryContextInterface);

export const MaintenanceHistoryContextWrapper: FC = (props) => {
  const [maintenanceItems, setMaintenanceItems] = useState<IMaintenanceItem[]>(
    []
  );

  const [
    maintenanceHistoryListPagination,
    setMaintenanceHistoryListPagination,
  ] = useState<IMaintenanceHistoryListPagination>(
    defaultMaintenanceHistoryListPagination
  );
  const [totalAmountMaintCostYTD, setTotalAmountMaintCostYTD] = useState(0);
  const [maintenanceHistoryList, setMaintenanceHistoryList] =
    useState<IGroupedMaitHistoriesByTimeline>(
      defaultGroupedMaintenanceHistoryList
    );
  const [filters, setFilters] =
    useState<IMaintenanceRecordFilters>(defaultFilters);

  const resetFilters = (): void => {
    setFilters(defaultFilters);
  };
  const getMaintenanceItemList = async () => {
    const response: MaintenanceItem[] | null =
      await MaintenanceController.instance().fetchMaintenanceItems();
    setMaintenanceItems(response);
  };

  const fetchMaintenanceHistoryListTotal = async (
    queryParams: PaginatedMaintenanceHistoryListRequest
  ) => {
    try {
      const response: number =
        await MaintenanceController.instance().fetchMaintenanceHistoryListTotal(
          queryParams
        );
      setTotalAmountMaintCostYTD(response);
    } catch (e) {
      setTotalAmountMaintCostYTD(0);
    }
  };
  const getPaginatedMaintenanceHistoryList = async (
    queryParams: PaginatedMaintenanceHistoryListRequest
  ) => {
    if (!maintenanceHistoryListPagination.isLoading) {
      setMaintenanceHistoryListPagination((oldState) => {
        return {
          ...oldState,
          isLoading: true,
        };
      });

      try {
        if (queryParams.pageNumber === 1) {
          fetchMaintenanceHistoryListTotal(queryParams);
        }
        const response: PaginatedResult<IMaintenanceHistory> | null =
          await MaintenanceController.instance().fetchMaintenanceHistoriesByEquipment(
            queryParams
          );
        if (response) {
          setMaintenanceHistoryListPagination((oldState) => {
            return {
              ...oldState,
              isLoading: false,
              first: response?.first,
              last:
                response?.last ||
                response.content === null ||
                response.content?.length === 0,
              pageNumber: response?.number + 1,
            };
          });
          const items = (response.content || []).map(
            (item: IMaintenanceHistory) => new MaintenanceHistory(item)
          );
          const groupedData: IGroupedMaitHistoriesByTimeline =
            groupedMaitHistoriesByTimeline(items);

          if (response?.number === 0) {
            //First page
            setMaintenanceHistoryList(groupedData);
          } else {
            setMaintenanceHistoryList((oldState) => {
              const newData = { ...oldState };

              if (groupedData.thisMonth) {
                newData.thisMonth = groupedData.thisMonth;
              }
              if (groupedData.thisYear) {
                newData.thisYear = groupedData.thisYear;
              }
              if (groupedData.theRest) {
                newData.theRest = groupedData.theRest;
              }

              return newData;
            });
          }
        }
      } catch (e) {
        setMaintenanceHistoryListPagination((oldState) => {
          return {
            ...oldState,
            isLoading: false,
          };
        });
      }
    }
  };

  useEffect(() => {
    getMaintenanceItemList();
  }, []);

  return (
    <MaintenanceHistoryContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,

        maintenanceItems,
        getMaintenanceItems: getMaintenanceItemList,

        totalAmountMaintCostYTD,

        maintenanceHistoryList,
        maintenanceHistoryListPagination,
        getPaginatedMaintenanceHistoryList,
        setMaintenanceHistoryListPagination,
      }}
    >
      {props.children}
    </MaintenanceHistoryContext.Provider>
  );
};
