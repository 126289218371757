import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC, useState } from 'react';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import SearchItemsGroup from './SearchItemsGroup';
import { entityTypes, groupCategories } from './constants';
import {
  groupTitleStyle,
  searchContainerStyle,
  showMoreLessStyle,
} from './style';

function onShowHideClick(
  key: string,
  showAllKeys: string[],
  setShowAllKeys: any
) {
  if (showAllKeys.find((x) => x === key)) {
    const newKeys: string[] = showAllKeys.filter((x) => x !== key);
    setShowAllKeys(newKeys);
  } else {
    const newKeys: string[] = showAllKeys.map((x) => x);
    newKeys.push(key);
    setShowAllKeys(newKeys);
  }
}

function getButtonText(key: string, showAllKeys: string[]) {
  return showAllKeys.find((x) => x === key) ? 'Show Less' : 'Show More';
}

function getButtonIcon(key: string, showAllKeys: string[]) {
  return showAllKeys.find((x) => x === key) ? (
    <KeyboardArrowUpIcon />
  ) : (
    <KeyboardArrowDownIcon />
  );
}

function getGrouppedData(searchData: any) {
  const grouppedData = new Map();
  if (searchData && searchData.resultList && searchData.resultList.length > 0) {
    searchData.resultList.forEach((item: any) => {
      let entityType = item.entityType;
      if (
        item.entityType === entityTypes.SOLO ||
        item.entityType === entityTypes.TEAM
      ) {
        entityType = entityTypes.DRIVER;
      } else if (
        item.entityType === entityTypes.TRACTOR ||
        item.entityType === entityTypes.TRAILER
      ) {
        entityType = entityTypes.EQUIPMENT;
      }

      if (grouppedData.has(entityType)) {
        grouppedData.get(entityType).push(item);
      } else {
        grouppedData.set(entityType, [item]);
      }
    });
  }
  return grouppedData;
}

interface SearchContainerProps {
  searchData: any;
  onClose: () => void;
  onSearchItemClicked: any;
}

const SearchContainer: FC<SearchContainerProps> = ({
  searchData,
  onClose,
  onSearchItemClicked,
}) => {
  const [showAllKeys, setShowAllKeys] = useState<string[]>([]);
  const grouppedData = getGrouppedData(searchData);

  return (
    <Box sx={searchContainerStyle}>
      {Array.from(grouppedData, ([key, value], i) => {
        return (
          <Box key={i}>
            <Typography
              sx={groupTitleStyle}
            >{`${groupCategories[key]} (${value.length} results found)`}</Typography>
            <SearchItemsGroup
              grouppedValue={value}
              showAll={showAllKeys.find((x) => x === key)}
              onClose={onClose}
              onSearchItemClicked={onSearchItemClicked}
            ></SearchItemsGroup>
            {value?.length > 3 && (
              <ButtonImproved
                endIcon={getButtonIcon(key, showAllKeys)}
                variant="text"
                onClick={() =>
                  onShowHideClick(key, showAllKeys, setShowAllKeys)
                }
                styleProps={showMoreLessStyle}
                label={getButtonText(key, showAllKeys)}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default SearchContainer;
