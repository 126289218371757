import Grid from '@mui/material/Grid';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRootStore } from '../../../../../../store/root-store/rootStateContext';
import FormDatePicker from '../../../../../../common/Ui/FormDatePicker/FormDatePicker';

export const MarkPaidForm = memo((): JSX.Element => {
  const { control } = useFormContext();
  const { getCompanyPreferences } = useRootStore();

  return (
    <Grid container spacing={1}>
      <FormDatePicker
        styleProps={{
          width: '100%',
        }}
        sizes={{
          xs: 12,
        }}
        disableFuture
        required
        control={control}
        label="Date"
        name={`paidDate`}
        timezone={getCompanyPreferences?.timezone}
      />
    </Grid>
  );
});
MarkPaidForm.displayName = 'MarkPaidForm';
