import { ServiceError } from './helperTypes';
import { Service } from './Service';
import {
  GetGlobalTerminalDTO,
  TerminalListResponse,
  UpdateReceivedDTO,
  GetNotificationCountDTO,
  GetNotificationListDTO,
  GetGlobalSerarchDTO,
  GetLoadByIDDTO,
  NotesListRes,
} from '../../models';

export abstract class ILayoutService extends Service {
  abstract getGlobalSearchResponseData(
    requestData: GetGlobalSerarchDTO
  ): Promise<any | null | ServiceError>;

  abstract getGlobalTerminals(): Promise<any | null | ServiceError>;

  abstract updateReceivedData(
    payloadData: UpdateReceivedDTO
  ): Promise<any | null | ServiceError>;

  abstract updateSingleRead(
    payloadData: UpdateReceivedDTO
  ): Promise<any | null | ServiceError>;

  abstract getNotificationCountData(
    requestData: GetNotificationCountDTO
  ): Promise<any | null | ServiceError>;

  abstract getNotificationListData(
    requestData: GetNotificationListDTO
  ): Promise<any | null | ServiceError>;

  abstract getLoadByIDData(
    requestData: GetLoadByIDDTO
  ): Promise<NotesListRes | null | ServiceError>;
}
