export const Errors = {
  DISPLAY_NAME: 'Display Name is required',
  USERNAME: 'Username is required',
  PASSWORD: 'Password is required',
  CARRIER_NAME: 'Carrier Name is required',
  DOT_NUMBER: 'DOT is required',
  CARRIER_USERNAME: 'Carrier User Name/Email is required',
  CONTACT_NAME: 'Contact Name is required',
  CONTACT_PHONE: 'Contact Phone is required',
  TERMINAL_IDS: 'Terminal Id is required',
  INTEGRATION_ID: 'Integration Id is required',
  CARRIER_CODE: 'Carrer code is required',
  COMPANY_NAME: 'Company name is required',
  MC: 'MC number is required',
  DOT: 'DOT number is required',
};

export const IntegrationTooltipText = `To set this up, we'll need an email that is not associated with an existing user, that you'll use to log in on behalf of your organization. Most companies will do this by either generating a new email (Ex: datserviceaccount@abclogistics.com), using a group email (Ex: admin@abclogistics.com), or using a + to make a unique email if your email service allows that (example: johndoe+1@abclogistics.com or johndoe+dat@abclogistics.com).`;
