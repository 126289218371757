import axios, { CancelTokenSource } from 'axios';
import { toJS } from 'mobx';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { ganttPageSize } from '../constants/dispatch';
import { getDriverScheduleRequest, getDriverScheduleWarnings } from '../http';
import { getDayStartHours, getWindowSizeDateRange } from '../services';
import {
  ADD_DRIVER_SCHEDULE,
  driverScheduleInitialState,
  driverScheduleReducer,
  IDriverReducerPayload,
  MAP_SCHEDULE_WARNINGS,
  SET_DRIVER_SCHEDULE,
  SET_LOADER,
  SET_SCHEDULE_WARNINGS,
  STOP_INITIAL_LOADER,
  STOP_LOADER,
  UPDATE_DRIVER_SCHEDULE,
} from '../../../store/pageStores/dispatch';
import { GanttAvailableDataRangeType, IDriverScheduleWarnings } from '../types';
import { useStores } from '../../../store/root.context';
import {
  IDriverSceduleRequestPayload,
  IDriverScheduleRequestParams,
} from '../../../types/DispatchTypes';

interface IDriverScheduleProps {
  requestPayload: IDriverSceduleRequestPayload;
  requestParams: IDriverScheduleRequestParams;
  ganttEndDate?: Date | string;
  onScheduleRequestFailure?: () => void;
  onDriversCountUpdate?: (count: number) => void;
}

interface IDriverRequestProps {
  requestPayload: IDriverSceduleRequestPayload;
  requestParams: IDriverScheduleRequestParams;
  scheduleType?: string;
}

const useDriverScheduler = (props: IDriverScheduleProps) => {
  const {
    requestPayload,
    requestParams,
    ganttEndDate,
    onScheduleRequestFailure,
    onDriversCountUpdate,
  } = props;
  const [driverSchedule, dispatchDriverSchedule] = useReducer(
    driverScheduleReducer,
    driverScheduleInitialState
  );
  const [ganttAvailableDataRange, setGanttAvailableDataRange] =
    useState<GanttAvailableDataRangeType>();
  const [allFilters, setAllFilters] = useState<string>('');
  const [prevReqParams, setPrevReqParams] = useState<string>('');
  const {
    dispatch2GlobalStore: {
      getDriverIdsListToRefresh,
      clearRefreshDriverIdsList,
      setTripAssignmentForGap,
    },
  } = useStores();
  const {
    getPendingDispatchDriverGroupIds,
    pushPendingDispatchDriverGroupIds,
  } = useRootStore();
  let scheduleController: AbortController;
  let scheduleSource: CancelTokenSource;
  const initAxiosReqController = () => {
    scheduleController = new AbortController();
    scheduleSource = axios.CancelToken.source();
  };
  const abortAxiosRequest = () => scheduleController?.abort();

  const { startDate, ...payload } = requestPayload;
  const [ganttVisibleDate, setGanttVisibleDate] = useState<Date | string>(
    getDayStartHours(startDate)
  );
  const isStartDateInRange = useMemo(() => {
    if (
      new Date(startDate as Date)?.getTime() >=
        new Date(ganttAvailableDataRange?.startDate as Date)?.getTime() &&
      new Date(ganttEndDate as Date | string)?.getTime() <=
        new Date(ganttAvailableDataRange?.endDate as Date)?.getTime()
    ) {
      return true;
    }
    return false;
  }, [
    JSON.stringify(startDate),
    JSON.stringify(ganttAvailableDataRange),
    JSON.stringify(ganttEndDate),
  ]);

  useEffect(() => {
    let driverIds = [] as Array<string | number>;
    if (getPendingDispatchDriverGroupIds.length > 0) {
      const { drivers } = driverSchedule;
      drivers?.forEach((driverData) => {
        if (getPendingDispatchDriverGroupIds.includes(driverData.id))
          driverIds.push(driverData?.driverGroupDetails?.driverId as number);
      });
      pushPendingDispatchDriverGroupIds([]);
    } else {
      driverIds = toJS(getDriverIdsListToRefresh);
    }
    if (!driverIds?.length) return;
    updateDriverSchedule({
      requestParams: { pageSize: ganttPageSize, ...requestParams },
      requestPayload: { ...requestPayload, driverIds },
      scheduleType: UPDATE_DRIVER_SCHEDULE,
    });
    clearRefreshDriverIdsList();
  }, [
    JSON.stringify(toJS(getDriverIdsListToRefresh)),
    JSON.stringify(getPendingDispatchDriverGroupIds),
  ]);

  useEffect(() => {
    if (!startDate) return;
    if (allFilters !== JSON.stringify(payload))
      setAllFilters(JSON.stringify(payload));
    else if (prevReqParams !== JSON.stringify(requestParams))
      setPrevReqParams(JSON.stringify(requestParams));
    initAxiosReqController();
    updateDriverSchedule({
      requestPayload,
      requestParams: { pageSize: ganttPageSize, ...requestParams },
    });
    return () => abortAxiosRequest();
  }, [
    JSON.stringify(payload),
    JSON.stringify(requestParams),
    JSON.stringify(ganttEndDate),
  ]);

  const updateDriverSchedule = (payload: IDriverRequestProps) => {
    fetchDriverSchedule(payload);
  };
  async function fetchDriverSchedule({
    requestPayload,
    requestParams,
    scheduleType,
  }: IDriverRequestProps) {
    try {
      dispatchDriverSchedule({
        type: SET_LOADER,
        payload: { isLoading: true },
      });
      setTripAssignmentForGap(null);
      setGanttAvailableDataRange(getWindowSizeDateRange(startDate!));
      setGanttVisibleDate(getDayStartHours(startDate));
      const scheduleRes = await getDriverScheduleRequest({
        data: requestPayload,
        params: requestParams,
        signal: scheduleController?.signal,
        cancelToken: scheduleSource?.token,
      });
      const {
        drivers,
        trips,
        timeoffs,
        hosConstants,
        currentWeek,
        numberOfDrivers,
      } = scheduleRes?.data;
      const { pageNumber } = requestParams;
      fetchWarningsInSchedule(scheduleRes);
      dispatchDriverSchedule({
        type: scheduleType
          ? scheduleType
          : pageNumber === 1
          ? SET_DRIVER_SCHEDULE
          : ADD_DRIVER_SCHEDULE,
        payload: {
          drivers: drivers?.map((driver: any) => ({
            ...driver,
            hosConstants,
            currentWeek,
          })),
          trips,
          timeoffs,
          hosConstants,
          numberOfDrivers:
            scheduleType === UPDATE_DRIVER_SCHEDULE
              ? driverSchedule?.numberOfDrivers
              : numberOfDrivers,
          currentWeek,
        },
      });
      dispatchDriverSchedule({ type: STOP_LOADER });
      mapScheduleWarnings();
      if (scheduleType !== UPDATE_DRIVER_SCHEDULE)
        onDriversCountUpdate?.(numberOfDrivers);
      if (!drivers?.length) onScheduleRequestFailure?.();
    } catch (error) {
      dispatchDriverSchedule({ type: STOP_LOADER });
      onScheduleRequestFailure?.();
    }
  }

  const getTotalWarnings = (warnings: any) => {
    let count = 0;
    if (warnings) {
      Object.keys(warnings || {}).forEach((warningKey) => {
        count += warnings[warningKey].length;
      });
    }
    return count;
  };

  const fetchWarningsInSchedule = async ({ data }: any) => {
    try {
      const trips = data.trips || [];
      const tripWarnings: any = {};
      trips.forEach((trip: any) => {
        if (trip.id) {
          tripWarnings[trip.id] = {
            warningsCount: trip.warningsCount,
            ...trip.warnings,
          };
        }
      });
      dispatchDriverSchedule({
        type: SET_SCHEDULE_WARNINGS,
        payload: {
          warnings: {
            ...driverSchedule?.warnings,
            trips: {
              ...driverSchedule?.warnings?.trips,
              ...tripWarnings,
            },
          },
        },
      });
      mapScheduleWarnings();
    } catch (error: any) {
      console.log('error:', error);
      if (error?.message === 'canceled') return;
      dispatchDriverSchedule({ type: STOP_LOADER });
    }
  };

  const mapScheduleWarnings = () =>
    dispatchDriverSchedule({
      type: MAP_SCHEDULE_WARNINGS,
    });

  const updateTripsByActionType = (
    type: string,
    payload: IDriverReducerPayload
  ) =>
    dispatchDriverSchedule({
      type,
      payload,
    });
  return {
    driverSchedule,
    updateDriverSchedule,
    updateTripsByActionType,
    ganttAvailableDataRange,
    ganttVisibleDate,
  };
};

export default useDriverScheduler;
