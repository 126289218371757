import {
  Avatar,
  Box,
  Chip,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { fromMomentToIso } from '../../../../../src/utils';
import { loadService } from '../../../../api';
import Accordion from '../../../../common/Accordion';
import {
  UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE,
  UI_DATE_FORMAT,
} from '../../../../constants/date.constants';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import SecondaryDetailsPanel from '../../../SecondaryDetailsPanel/SecondaryDetailsPanel';
import { BetaLogText } from './components/betaLoadText';
import { OldLogMessage } from './components/oldLog';
import { DateType, LogMessage, ActionType } from './config';
import { IActivities, IEvents, IProps } from './models';
import {
  activityGridStyle,
  activityStyles,
  logMessageGridStyle,
  updatedDetailGridStyle,
  updatedDetailStyle,
} from './styles';
import { logEntityType, secondaryPanelType } from './type';
import EncryptedStorageManager from '../../../../StorageManager/EncryptedStorageManager';

export const Activity = ({ load }: IProps) => {
  const [allActivities, setAllActivities] = useState<IActivities | any>();
  const [cardExpanded, setCardExpanded] = useState<any>({});
  const [secondaryPanelData, setSecondaryPanelData] =
    useState<secondaryPanelType | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (load?.id) {
        const data = await loadService.getAuditLogs(load.id);
        setAllActivities(data);
        setLoading(false);
      }
    };
    fetchData();
  }, [load]);
  const { getCompanyPreferences } = useRootStore();
  const theme: any = useTheme();

  const handleSecondaryPanelOpen = (data: secondaryPanelType) => {
    setSecondaryPanelData({ ...data });
  };

  const getFormatedDate = (date: string, timezone?: string) => {
    const timeZoneVal = timezone || getCompanyPreferences?.timezone;
    const zoneDate = timeZoneVal ? moment(date).tz(timeZoneVal) : moment(date);
    return zoneDate.format(UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE);
  };
  const renderAccordian = (data: IEvents, formatedDate: string) => {
    return (
      <Grid className="activity" sx={{ ...activityGridStyle }}>
        <Box
          className="activity-inner-block"
          sx={activityStyles.accordianActivityInnerBlock}
        >
          <Grid container margin={'0px'}>
            <Grid item xs={1}>
              <Avatar>{fName[0]}</Avatar>
            </Grid>
            <Grid item paddingLeft={'20px'} xs={11}>
              <Box sx={{ ...activityStyles.dataStyle }}>
                {LogMessage({
                  ...data.field,
                  logKey: data.messageKey,
                  entity: logEntityType.LOAD,
                  handleSecondaryPanelOpen,
                })}
              </Box>
              <Typography style={activityStyles.dateStyle}>
                {formatedDate}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  const user = EncryptedStorageManager.getItem('user');
  const fName = user ? user?.firstname : null;

  const columns: GridColDef[] = [
    { field: 'field', headerName: 'Field', flex: 1 },
    {
      field: 'oldValue',
      renderCell: (params: any) => {
        if (params.row.type === DateType.DATE) {
          return fromMomentToIso(
            moment(params.row.oldValue),
            UI_DATE_FORMAT
          ) as string;
        } else if (params.row.type === DateType.DATETIME) {
          return getFormatedDate(params.row.oldValue, params?.row?.timezone);
        }
        {
          return params.row.oldValue;
        }
      },
      headerName: 'Old Value',
      flex: 1,
    },
    {
      field: 'newValue',
      headerName: 'New Value',
      renderCell: (params: any) => {
        if (params.row.type === DateType.DATE) {
          return fromMomentToIso(
            moment(params.row.newValue),
            UI_DATE_FORMAT
          ) as string;
        } else if (params.row.type === DateType.DATETIME) {
          return getFormatedDate(params.row.newValue, params?.row?.timezone);
        } else {
          return params.row.newValue;
        }
      },
      flex: 1,
    },
  ];

  if (loading) {
    return (
      <Stack direction={'column'} mt={'1rem'} spacing={3}>
        {[...new Array(18)].map((index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={'100%'}
            height={'1rem'}
          />
        ))}
      </Stack>
    );
  }

  return (
    <Grid className="activity-block-wrapper" sx={activityStyles.container}>
      <Box display="flex"></Box>
      {allActivities &&
        (allActivities?.serviceName || allActivities?.method ? (
          <OldLogMessage />
        ) : allActivities?.events?.length > 0 ? (
          <Box>
            <BetaLogText />
            {allActivities?.events?.map((data: IEvents, index: number) => {
              const key = `log+${index}`;
              const style =
                key in cardExpanded && cardExpanded[key]
                  ? {
                      background: '#fff !important',
                      border: '1px solid #2B64CB',
                    }
                  : {};
              const formatedDate = getFormatedDate(data?.timestamp);
              return (
                <Fragment key={index}>
                  {data.action != ActionType.UPDATED ? (
                    <Grid
                      className="activity"
                      sx={activityStyles.activityBlock}
                    >
                      <Box
                        className="activity-inner-block"
                        sx={activityStyles.activityInnerBlock}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={data?.loadSource ? 10 : 12}
                            sx={{
                              ...logMessageGridStyle,
                              paddingLeft: '0px !important',
                            }}
                            display={'flex'}
                          >
                            <Avatar sx={{ marginRight: '15px' }}>
                              {fName[0]}
                            </Avatar>
                            <Box>
                              <Box sx={{ ...activityStyles.dataStyle }}>
                                {LogMessage({
                                  ...data.field,
                                  logKey: data.messageKey,
                                  entity: logEntityType.LOAD,
                                  handleSecondaryPanelOpen,
                                })}
                              </Box>
                              <Typography style={activityStyles.dateStyle}>
                                {formatedDate}
                              </Typography>
                            </Box>
                          </Grid>
                          {data?.action === ActionType.CREATED &&
                            data?.loadSource && (
                              <Grid item textAlign={'end'} xs={2}>
                                <Chip
                                  label={data?.loadSource}
                                  color="success"
                                  sx={{
                                    background: theme.palette.secondary.main,
                                    color: theme.palette.secondary.contrast,
                                  }}
                                />
                              </Grid>
                            )}
                        </Grid>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      className="activity"
                      sx={{ ...activityStyles.activityBlock, ...style }}
                    >
                      {data?.changes?.length > 0 ? (
                        <Box>
                          <Accordion
                            key={key}
                            name={key}
                            summaryRenderer={() =>
                              renderAccordian(data, formatedDate)
                            }
                            updateParentState={(data: any) => {
                              setCardExpanded({
                                ...cardExpanded,
                                [`${key}`]: data,
                              });
                            }}
                            expanded={
                              key in cardExpanded ? cardExpanded[key] : false
                            }
                            summaryStyle={{
                              flexDirection: 'row',
                              marginLeft: '0px',
                            }}
                          >
                            <Box sx={{ ...updatedDetailStyle }}>
                              <DataGrid
                                hideFooter
                                rows={data.changes}
                                getRowHeight={() => 'auto'}
                                columns={columns}
                                getRowId={() => Math.random()}
                                sx={{ ...updatedDetailGridStyle }}
                              />
                            </Box>
                          </Accordion>
                        </Box>
                      ) : (
                        <Box
                          className="activity-inner-block"
                          sx={activityStyles.activityInnerBlock}
                        >
                          <Grid container>
                            <Grid item>
                              <Avatar>{fName[0]}</Avatar>
                            </Grid>
                            <Grid item sx={{ ...logMessageGridStyle }}>
                              <Box sx={{ ...activityStyles.dataStyle }}>
                                {LogMessage({
                                  ...data.field,
                                  logKey: data.messageKey,
                                  entity: logEntityType.LOAD,
                                  handleSecondaryPanelOpen,
                                })}
                              </Box>
                              <Typography style={activityStyles.dateStyle}>
                                {formatedDate}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </Box>
        ) : (
          <Box textAlign={'center'} fontSize={'22px'}>
            No logs found.
          </Box>
        ))}
      {secondaryPanelData && (
        <SecondaryDetailsPanel
          type={secondaryPanelData.type}
          id={secondaryPanelData.id}
          onClose={() => {
            setSecondaryPanelData(null);
          }}
          isGlobal={true}
        />
      )}
    </Grid>
  );
};
