import { Type } from 'class-transformer';
import { getOrganizationId } from '../../../common/TimeoffDialog/utils';
import { SortResponse } from '../commonMixed';
import { QueryParams } from '../commonMixed/QueryParams';

export class PaginatedBasicReportsRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  reportKey?: string;
  startDate?: string;
  endDate?: string;
  driverIds?: Array<string>;
  customerIds?: Array<string>;
  tractorIds?: Array<string>;
  trailerIds?: Array<string>;
  dispatcherIds?: Array<string>;
  terminalId?: string;
  sort?: any;
  scheduleType!: string;
  dispatchType!: string;
}

export class GetBasicReportsListTotalRequest extends QueryParams {
  reportKey?: string;
  startDate?: string;
  endDate?: string;
  driverIds?: Array<string>;
  customerIds?: Array<string>;
  tractorIds?: Array<string>;
  trailerIds?: Array<string>;
  dispatcherIds?: Array<string>;
  terminalId?: string;
  sort?: any;
  scheduleType!: string;
  dispatchType!: string;
}

export class TotalReportResult {
  driverId?: string;
  driverName?: string;
  customerId?: string;
  customerName?: string;
  tractorId?: string;
  tractorName?: string;
  trailerId?: string;
  trailerName?: string;
  dispatcherId?: string;
  dispatcherName?: string;
  driverPayment?: number;
  emptyMiles?: number;
  grossRevenue?: number;
  loadCount?: number;
  loadedMiles?: number;
  netIncome?: number;
  netIncomeLoadedMile?: number;
  netIncomeTotalMile?: number;
  organizationId?: number;
  revenueLoadedMile?: number;
  revenueTotalMile?: number;
  totalExpense?: number;
  dispatcherPayment?: number;
}

export class BasicReportsListDTO {
  driverId?: string;
  driverName?: string;
  driverPayment?: number;
  emptyMiles?: number;
  grossRevenue?: number;
  loadCount?: number;
  loadedMiles?: number;
  netIncome?: number;
  netIncomeLoadedMile?: number;
  netIncomeTotalMile?: number;
  organizationId?: number;
  revenueLoadedMile?: number;
  revenueTotalMile?: number;
  totalExpense?: number;
  id!: string;
}

export class PaginatedBasicReportsList {
  @Type(() => BasicReportsListDTO)
  content?: BasicReportsListDTO[];
  first?: boolean;
  last?: boolean;
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  size?: number;
  number?: number;
  @Type(() => SortResponse)
  order?: SortResponse[];
  totalEmptyMiles?: number;
  totalGrossRevenue?: number;
  totalLoadedMiles?: number;
  totalNetIncome?: number;
  totalTotalExpense?: number;
  totalLoadCount?: number;
  totalDriverPayment?: number;
  totalDispatcherPayment?: number;
}

export class DoSearchDriverNameRequest extends QueryParams {
  nameFilter?: string;
  pageSize?: number;
  pageNumber?: number;
  sort?: string;
  terminalIds?: Array<string>;
  excludeStatuses?: Array<number>;
}

class DriverObj {
  driverId?: number;
  driverName?: string;
}

export type DoSearchDriverList = DriverObj[];

export class DoSearchCustomerNameRequest extends QueryParams {
  nameKeyword?: string;
  pageSize?: number;
  sort?: string;
  pageNumber?: number;
}

class CustomerObj {
  id!: number;
  name!: string;
  organizationId!: number;
}
export type DoSearchCustomerList = CustomerObj[];

export class DoSearchTractorNameRequest extends QueryParams {
  axeleIdFilter?: string;
  pageSize?: number;
  sort?: string;
  pageNumber?: number;
  terminalIds?: Array<string>;
  excludeStatusList?: Array<string>;
}

class TractorObj {
  id!: number;
  name!: string;
}
export type DoSearchTractorList = TractorObj[];

export class DoSearchTrailerNameRequest extends QueryParams {
  axeleIdFilter?: string;
  pageSize?: number;
  sort?: string;
  pageNumber?: number;
  terminalIds?: Array<string>;
  excludeStatusList?: Array<string>;
}

export type DoSearchTrailerList = TractorObj[];

export class DoSearchDispatcherNameRequest extends QueryParams {
  name?: string;
  pageSize?: number;
  pageNumber?: number;
  excludeRoleCodeList?: string[];
  terminalIds?: Array<string>;
}

class Dispatcher {
  id!: number;
  fullName!: string;
}
export type DoSearchDispatcherList = Dispatcher[];

export class ExportExcelQuery extends QueryParams {
  reportKey?: string;
  startDate?: string;
  endDate?: string;
  driverIds?: Array<string>;
  customerIds?: Array<string>;
  tractorIds?: Array<string>;
  trailerIds?: Array<string>;
  dispatcherIds?: Array<string>;
  terminalId?: string;
  sort?: any;
  scheduleType!: string;
  dispatchType!: string;
}

export class ExportExcelQueryExcel {
  contentType?: string;
  file?: string;
  fileName?: string;
  key?: string;
  organizationId?: number;
}

export class LoadReportSelectedFields {
  LOAD_AND_INVOICE!: Array<string>;
  TRIPS_AND_STOPS!: Array<string>;
  DRIVER_TRIP_PAYMENTS!: Array<string>;
  TRACTOR_TRIP_PAYMENTS!: Array<string>;
}
export class TrendReportSelectedFields {
  TREND_REPORT_MONTHLY!: Array<string>;
  TREND_REPORT_WEEKLY!: Array<string>;
}
export class CreateAdvanceLoadReportRequest extends QueryParams {
  isTrend!: boolean;
  fromDate?: string;
  toDate?: string;
  entity?: string;
  type?: string;
  driverIds?: Array<string>;
  customerIds?: Array<string>;
  tractorIds?: Array<string>;
  trailerIds?: Array<string>;
  dispatcherIds?: Array<string>;
  scheduleType?: string;
  dateType?: string;
  terminalId?: Array<string>;
  selectedFields?: LoadReportSelectedFields | TrendReportSelectedFields;
  // selectedFields?: {
  //   LOAD_AND_INVOICE: Array<string>;
  //   TRIPS_AND_STOPS: Array<string>;
  //   DRIVER_TRIP_PAYMENTS: Array<string>;
  //   TRACTOR_TRIP_PAYMENTS: Array<string>;
  // };
}

export class CreateAdvanceLoadResult {
  contentType!: string;
  file!: string;
  fileName!: string;
  key?: string;
  organizationId!: number;
}
export class GetQuaInfoForReportsRequest extends QueryParams {
  year!: string;
  quarter!: string;
  tractorId!: Array<string>;
  organizationId!: number;
  constructor(dto: any) {
    super();
    if (dto) {
      this.year = dto.year;
      this.quarter = dto.quarter;
      this.tractorId = dto.tractorId;
      this.organizationId = getOrganizationId();
    }
  }
}

export class GetDriverTimeOffRequest extends QueryParams {
  driverIds!: Array<string>;
  organizationId!: number;
  terminalIds?: Array<string>;
  statusList?: Array<string>;
  fromDate?: string;
  toDate?: string;
  selectedFields?: {
    DRIVER_TIMEOFF: Array<string>;
  };
  constructor(dto: any) {
    super();
    if (dto) {
      this.driverIds = dto.driverIds;
      this.terminalIds = dto.terminalIds;
      this.statusList = dto.statusList;
      this.fromDate = dto.fromDate;
      this.toDate = dto.toDate;
      this.organizationId = getOrganizationId();
      this.selectedFields = dto.selectedFields;
    }
  }
}
