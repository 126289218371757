import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { columns } from './constant';
import { GetInvoiceListSubscriptionRequest } from '../../models';
import { subscriptionService } from '../../api';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { currencyDisplay } from '../../utils/grid.utils';
import Chip from '@mui/material/Chip';
import IconsComponent from '../../ui-kit/components/IconsComponent';
import { styles } from './styles';
import { capitalize } from '../../utils';

type InvoiceTableProps = { setUrl: (x: string) => void; uid: string };

const InvoiceTable = ({ setUrl, uid }: InvoiceTableProps) => {
  const [height, setHeight] = useState<number>(0);
  const [list, setList] = useState([]);

  const getDate = (date: string) =>
    date ? moment(date).startOf('day').format('ll') : '';

  const getList = async () => {
    const query = new GetInvoiceListSubscriptionRequest();
    const response = await subscriptionService.getInvoiceList(query);
    const newList = response.map((x) => ({
      ...x,
      id: x.uid,
      invoice_date: getDate(x.issue_date),
      due_date: getDate(x.due_date),
      paid_date: getDate(x.paid_date),
      total_amount: currencyDisplay(x.total_amount),
    }));
    setList(newList);
  };

  useEffect(() => {
    setUrl(list.find((el) => el.subscription_id === uid)?.public_url || '');
  }, [list, uid]);

  useEffect(() => {
    let heightCalculated = 0;
    const topHeight = document.getElementById('upper-grid')?.offsetHeight;
    const contactHeight =
      document.getElementById('contact-block')?.offsetHeight;
    heightCalculated = topHeight + contactHeight + 27;
    setHeight(`calc(100% - ${heightCalculated}px)`);
    getList();
  }, []);

  return list.length === 0 ? (
    <Box
      sx={{
        marginTop: '10px',
        marginBottom: '20px',
      }}
    />
  ) : (
    <Box
      sx={(theme) => ({
        ...styles.tableContainer,
        borderColor: theme.palette.info.outlinedRestingBorder,
        height,
      })}
    >
      <Box sx={styles.title}>Invoice History</Box>
      <Box sx={styles.flexcontainer}>
        <Grid container xs={12}>
          {columns.map((col, index) => {
            return (
              <Grid
                item
                xs={col.size}
                key={index}
                sx={(theme) => ({
                  ...(index < columns.length - 1
                    ? {
                        textAlign: 'center',
                        borderRightWidth: 1,
                        borderRightColor: 'grey.a100',
                      }
                    : {}),
                  ...theme.typography.tableHeader,
                  color: 'text.primary',
                })}
              >
                {col.headerName}
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box sx={styles.overflowcontainer}>
        {list.map((row, rowIndex) => {
          return (
            <Box key={rowIndex + 'row'} sx={styles.tableoverflow}>
              <Grid container xs={12}>
                {columns.map((col, colIndex) => {
                  return (
                    <Grid
                      item
                      xs={col.size}
                      key={rowIndex + 'row' + colIndex + 'col'}
                      sx={(theme) => ({
                        textAlign:
                          col.type === 'icon'
                            ? 'center'
                            : col.type === 'number'
                            ? 'right'
                            : 'left',
                        ...theme.typography.body1,
                        color: 'text.primary',
                      })}
                    >
                      {col.field === 'share' ? (
                        <Box
                          onClick={() =>
                            window.open(row.public_url, '_blank').focus()
                          }
                        >
                          <IconsComponent
                            source="MUIcons"
                            iconName="Launch"
                            styleProps={{
                              width: '18px',
                              height: '18px',
                              color: 'primary.main',
                              cursor: 'pointer',
                            }}
                          />
                        </Box>
                      ) : col.field === 'status' ? (
                        <Chip
                          label={'$ ' + capitalize(row[col.field])}
                          color="primary"
                          size="small"
                          sx={{ width: '67px', height: '26px' }}
                        />
                      ) : (
                        row[col.field]
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default InvoiceTable;
