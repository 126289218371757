import { ThemeOptions } from '@mui/material';

export const MuiCheckbox: ThemeOptions['components'] = {
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          color: 'text.primary',
        }),
    },
  },
};
