import {
  action,
  autorun,
  isAction,
  isObservableArray,
  runInAction,
} from 'mobx';
import { gridPageSize } from './';

export type IDisposer = () => void;

export const debounceTime = 300;

export const chunkProcessorMaxChunkSize = gridPageSize;
export const chunkProcessorDebounce = 300;

export function chunkProcessor<T>(
  observableArray: T[],
  processor: (item: T[]) => void,
  debounce = 0,
  maxChunkSize = 0
): IDisposer {
  if (!isObservableArray(observableArray))
    throw new Error('Expected observable array as first argument');
  if (!isAction(processor)) processor = action('chunkProcessor', processor);

  const runner = () => {
    while (observableArray.length > 0) {
      const chunkSize =
        maxChunkSize === 0
          ? observableArray.length
          : Math.min(observableArray.length, maxChunkSize);
      // construct a final set
      const items = observableArray.slice(0, chunkSize);
      // clear the slice for next iteration
      runInAction(() => observableArray.splice(0, chunkSize));
      // fire processor
      processor(items);
    }
  };
  if (debounce > 0) return autorun(runner, { delay: debounce });
  return autorun(runner);
}
