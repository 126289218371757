import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { XlxIcon } from '../../../../../ui-kit/components/Assets';
import { useImport } from '../../../../../contexts/ImportContext';
import { colorsMap, textsMap } from './constants';
import { formatBytes } from '../../../../../utils';

type PagePropTypes = {
  value?: number;
  uploading?: boolean;
  state?: 'success' | 'warning' | 'error';
  text?: string;
  infos?: { message: string }[];
};

export default function UploadProgress({
  uploading,
  state = 'success',
  infos = [],
}: PagePropTypes) {
  const { file } = useImport();
  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ marginRight: '13px' }}>
          <XlxIcon color={colorsMap[state]} />
        </Box>
        <Box flex={1}>
          <Typography
            sx={{
              color: textsMap[state],
              fontWeight: 400,
              fontFamily: 'Poppins',
              letterSpacing: '0,15px',
            }}
          >
            {file?.name}
          </Typography>
          {uploading ? (
            <LinearProgress color={'success'} />
          ) : (
            <LinearProgress variant="determinate" value={100} color={state} />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              {!!infos.length &&
                infos.map((info, i) => (
                  <Typography
                    key={i}
                    sx={{
                      color: colorsMap[state],
                      fontWeight: 400,
                      fontFamily: 'Poppins',
                      fontSize: 12,
                      mt: 0.5,
                    }}
                  >
                    {info.message}
                  </Typography>
                ))}
            </Box>
            <Box>
              <Typography
                sx={{
                  color: colorsMap[state],
                  fontWeight: 400,
                  fontFamily: 'Poppins',
                  fontSize: 12,
                  mt: 0.5,
                }}
              >
                {formatBytes(file?.size)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
