import * as yup from 'yup';
import { EQUIPMENT_PANEL_TABS } from '../../../constants/contacts';
import {
  lastLocationValidationSchema,
  nullableEmailYup,
  nullableNumberYup,
  nullableStringMaxValidation,
  nullableStringYup,
  phoneValidationSchema,
  vinValidationSchema,
} from '../../../utils';
import { tractorAlertValidationSchema } from '../../shared/equipment';
import { tractorPaymentTermsTabValidationSchema } from '../../webUsers/constants';
const textMaxSize = 100;
const maxAlert = `must be at most ${textMaxSize} characters`;
const tractorTabValidationSchema = yup
  .object()
  .shape(
    {
      tractorName: nullableStringMaxValidation(50).required(
        'Tractor name is a required.'
      ),
      vin: vinValidationSchema(),
      licensePlateNumber: nullableStringYup().max(
        textMaxSize,
        `License plate number ${maxAlert}`
      ),
      currentOdometer: nullableNumberYup().max(
        3000000,
        'Value must be between 0 and 3,000,000'
      ),
      ownedCompany: nullableStringYup().max(
        textMaxSize,
        `Owned company ${maxAlert}`
      ),
      phone: phoneValidationSchema,
      ownerEmail: nullableEmailYup(),
      ownerAddress: lastLocationValidationSchema,
      make: nullableStringYup().max(textMaxSize, `Make ${maxAlert}`),
      model: nullableStringYup().max(textMaxSize, `Model ${maxAlert}`),
      fuelCardNumber: nullableStringYup().max(
        textMaxSize,
        `Fuel card Number ${maxAlert}`
      ),
      tollTagNumber: nullableStringYup().max(
        textMaxSize,
        'Cannot be longer than 100 characters'
      ),
      lastLocation: lastLocationValidationSchema,
      fuelCapacity: nullableNumberYup().max(
        1000,
        'Value must be between 0 and 1,000.'
      ),
      gvw: nullableNumberYup().max(99999, 'Value must be between 0 and 99,999'),
      insuranceCompany: nullableStringYup().max(
        textMaxSize,
        `Insurance company ${maxAlert}`
      ),
    },
    [
      ['vin', 'vin'],
      ['mobileno', 'mobileno'],
    ]
  )
  .required();

const alertTabValidationSchema = yup
  .object()
  .shape(
    {
      alertsList: yup.array().of(tractorAlertValidationSchema).compact(),
    },
    []
  )
  .required();

export const EQUIPMENT_TRACTOR_VALIDATION_SCHEMA: {
  [property: string]: yup.AnyObjectSchema;
} = {
  [EQUIPMENT_PANEL_TABS.GENERAL]: tractorTabValidationSchema,
  [EQUIPMENT_PANEL_TABS.ALERTS]: alertTabValidationSchema,
  [EQUIPMENT_PANEL_TABS.PAYMENT_TERMS]: tractorPaymentTermsTabValidationSchema,
};
