import { Divider } from '@mui/material';

export const VerticalDivider = () => {
  return (
    <Divider
      orientation="vertical"
      variant="middle"
      flexItem
      sx={{
        borderColor: 'rgba(0, 17, 34, 0.26)',
      }}
    />
  );
};
