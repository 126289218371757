import React from 'react';

const OptymizedLogo = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1980_91867)">
        <path
          d="M7.83565 16.5031C5.718 16.5031 4.00159 14.7881 4.00117 12.6726V7.17676H0.0866997C0.0293188 7.56253 0 7.95249 0 8.34245V12.6726C0 16.9956 3.50736 20.4998 7.83481 20.5002H12.1702C12.5606 20.5002 12.9509 20.4709 13.3371 20.4136V16.5031H7.83565Z"
          fill="#2B64CB"
        />
        <path
          d="M12.1711 0.500001H7.84027C5.00891 0.498746 2.39745 2.02636 1.01318 4.49372H12.1711C14.2866 4.49372 16.0018 6.20711 16.0022 8.3205V19.4661C18.4721 18.0828 20.0013 15.4745 20 12.646V8.3205C20 4.00084 16.4952 0.500001 12.1711 0.500001Z"
          fill="#47A9F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_1980_91867">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OptymizedLogo;
