import React from 'react';
import { Button, IconButton, SxProps, Theme } from '@mui/material';

interface IActionIconButton {
  children: JSX.Element;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  style?: SxProps;
  variant?: 'contained' | 'outlined';
  disabled?: boolean;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  id?: string;
  className?: string;
}

const IconButtonStyles = {
  width: '22px',
  minWidth: '22px',
  height: '22px',
  border: '1px solid rgba(43, 100, 203, 0.5)',
  padding: 0,
};

const ActionIconButton = React.forwardRef(
  (
    {
      id,
      children,
      onClick,
      style,
      variant = 'outlined',
      disabled = false,
      color = 'primary',
      className = '',
    }: IActionIconButton,
    ref
  ) => {
    return (
      <Button
        id={id}
        ref={ref as any}
        color={color}
        variant={variant}
        sx={(theme: Theme) => ({
          ...IconButtonStyles,
          borderRadius: '4px',
          ':hover': {
            color: (theme?.palette?.primary as any)?.contrast,
            background: theme.palette.primary.main,
            '*': {
              color: (theme?.palette?.primary as any)?.contrast,
            },
          },
          ...style,
        })}
        onClick={onClick}
        disabled={disabled}
        className={className}
      >
        {children}
      </Button>
    );
  }
);

export default ActionIconButton;
