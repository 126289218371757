import { AxiosConfigType, http } from '../../../axios/axios.service';
import { BULK_OPTYMIZATION_ENDPOINTS } from '../config/apiEndpoints.config';

export const validateLockActionRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return await http.put({
    apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.validateLockAssignment,
    ...payload,
  });
};

export const possibleDriverTripsRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return await http.put({
    apiEndpoint: BULK_OPTYMIZATION_ENDPOINTS.getPossibleDriverTrips,
    defaultUrl: false,
    ...payload,
  });
};
