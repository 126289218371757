import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { getLocationListByNameAddressSearch } from '../../../../../../views/operations/contacts/utils/location';
import { FormAddress } from '../../../../../Ui/AddressField';
import RadioGroup from '../../../../../Ui/RadioGroup';
import { SingleAutocompleteForm } from '../../../../../Ui/SingleAutocomplete';
import { AssetAssignmentFormData } from './index';

const AssetAssignmentPopupForm = () => {
  const { control, setValue, watch } = useFormContext();
  const [driverAsset, assetType, trailer, tractor]: [
    AssetAssignmentFormData['driverAsset'],
    AssetAssignmentFormData['assetType'],
    AssetAssignmentFormData['trailer'],
    AssetAssignmentFormData['tractor']
  ] = watch(['driverAsset', 'assetType', 'trailer', 'tractor']);

  const assetName = `${
    assetType === 'TRAILER' ? trailer?.name : tractor?.name
  }`;
  const assetTypeName = `${assetType === 'TRAILER' ? 'trailer' : 'tractor'}`;
  return (
    <div>
      <RadioGroup
        defaultValue="DRIVER"
        row
        label={`Select a ${assetTypeName} to drop`}
        name="userChoice"
        data={[
          {
            value: 'DRIVER',
            label: `Current ${assetTypeName} of driver (${driverAsset?.name})`,
          },
          {
            value: 'LOAD',
            label: `Current ${assetTypeName} of trip (${assetName})`,
          },
        ]}
      />
      <Typography
        sx={{
          mt: 4,
          fontSize: 16,
          color: 'text.primary',
        }}
      >
        Choose a location to drop
      </Typography>
      <SingleAutocompleteForm
        customStyles={{
          marginTop: '32px',
        }}
        defaultValue={null}
        control={control}
        name="business"
        fieldName="locationDisplayName"
        typedTextName="locationDisplayName"
        getOptions={(name, pageNumber) =>
          getLocationListByNameAddressSearch(name, pageNumber)
        }
        renderOption={{
          enableOptionSubtitle: true,
          renderOptionSubTitle: (option: any): JSX.Element => {
            return (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '0.625rem',
                  fontWeight: 400,
                  lineHeight: 1.167,
                  color: 'text.primary',
                  padding: '5px 5px',
                }}
              >
                {option.addressDTO?.fullAddress || ''}
              </Typography>
            );
          },
        }}
        label="Business Name"
        sizes={{ xs: 12 }}
        onChangeCb={(value) => {
          setValue('address', value.addressDTO);
        }}
      />
      <FormAddress
        label="Address *"
        control={control}
        name="address"
        sizes={{ xs: 12 }}
        onChangeCallback={() => {
          setValue('business', null);
        }}
      />
    </div>
  );
};

export default AssetAssignmentPopupForm;
