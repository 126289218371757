export const SuccessNotificationConstants = {
  homeService: 'Application Successfully updated',
  generalSuccessMsg: 'operation was successfull',
  loginSuccess: 'Logged In Successfully',
  activateQBProvider: 'Account Added Successfully',
  updateQBAuth: 'Authenticated Successfully',
  deleteQBProvider: 'Account Deleted Successfully',
  updateQBProvider: 'Account Updated Successfully',
  updateQBItemCategory: 'Item Category is updated',
  savePayItemCategory: 'Item saved successfully',
  fetchQBOCustomers: 'Customers Fetched Successfully',
  sendQBOCustomers: 'Customers sent to QuickBooks',
  connectToQBO: 'Session established with QuickBooks',
  suppressInvoices: 'Invoice(s) suppressed successfully',
  releaseInvoices: 'Invoice(s) released successfully',
  syncBackQBInvoices: 'Invoice synced Back Successfully',
  syncQBInvoices: 'Invoice synced Successfully',
  postCompanyLogo: 'Logo uploaded Successfully',
  datCreation: 'Credentials has been requested successfully',
  activationLogin: 'Integration activation successfull',
  editIntegration: 'Integration changes has been successfully saved',
  deactivate: 'Integration deactivated successfully',
  deactivateFactoring: 'Account has been deleted successfully',
  deleteItem: 'Item deleted successfully',
  addMaintenanceHistory: 'Record Added Successfully',
  updateMaintenanceHistory: 'Record Updated Successfully',
  deleteMaintenanceHistory: 'Record(s) Deleted Successfully',
  deleteTerminal: 'Terminal Deleted Successfully',
  createTerminal: 'Terminal Created Successfully',
  updateTerminal: 'Terminal Updated Successfully',
  reset: 'Your password reset successfully',
  deleteCancellation: 'Cancellation deleted successfully',
  deletePayment: 'Payment method deleted successfully',
  primaryPayment: 'Primary payment method changed successfully',
  tractorLink: 'Tractor(s) linked successfully',
  driverLink: 'Driver(s) linked successfully',
  tractorUnLink: 'Tractor(s) unlinked successfully',
  driverUnLink: 'Driver(s) unlinked successfully',
  tractorCreation: 'Tractor was created successfully',
  tractorUpdate: 'Tractor was updated successfully',
  tractorDelete: 'Tractor was deleted successfully',
  tractorAlertCreate: 'Tractor was created alert successfully',
  tractorAlertUpdate: 'Tractor alert was updated successfully',
  tractorAlertDelete: 'Tractor alert  was deleted successfully',
  tractorDriverAssign: 'Tractor was assigned successfully',
  tractorDriverUnAssign: 'Tractor was unassigned successfully',
  tractorStatusChange: 'Tractor status change successfully',
  trailerCreation: 'Trailer was created successfully',
  trailerUpdate: 'Trailer was updated successfully',
  trailerDelete: 'Trailer was deleted successfully',
  trailerAlertCreate: 'Trailer was created alert successfully',
  trailerAlertUpdate: 'Trailer alert was updated successfully',
  trailerAlertDelete: 'Trailer alert  was deleted successfully',
  trailerDriverAssign: 'Trailer was assigned successfully',
  trailerDriverUnAssign: 'Trailer was unassigned successfully',
  trailerStatusChange: 'Trailer status change successfully',
  multipleTractorCreation: 'Tractors created successfully',
  driverCreation: 'Driver created successfully',
  multipleDriverCreation: 'Drivers created successfully',
  accountDelete: 'Account deleted successfully',
  dataRefresh: 'Data refreshed successfully',
  financeOverviewAdd: 'Line Item Added Successfully',
  financeOverviewUpdate: 'Line Item Updated Successfully',
  financeOverviewDelete: 'Line Item deleted successfully',
  paymentPerLoadAdd: 'Payment Per Load Added Successfully',
  paymentPerLoadUpdate: 'Payment Per Load Updated Successfully',
  paymentPerLoadDelete: 'Payment Per Load deleted successfully',
  scheduledPaymentAdd: 'Scheduled Payment Added Successfully',
  scheduledPaymentUpdate: 'Scheduled Payment Updated Successfully',
  scheduledPaymentDelete: 'Scheduled Payment deleted successfully',
  deleteNotesData: 'Note Deleted Successfully',
  createNotesData: 'Note Created Successfully',
  editNotesData: 'Note Updated Successfully',
  createTrackerData: 'Record Created Successfully',
  deleteTrackerData: 'Record Deleted Successfully',
  updateTrackerData: 'Record Updated Successfully',
  deleteTerminalLogo: 'Logo Deleted Successfully',
  editLoadAlert: 'Alert Updated Successfully',
  createLoadAlert: 'Alert Created Successfully',
  settingAlertDelete: 'Alert Deleted Successfully',
  customerListRefresh: 'Customer list has been refreshed',
  updateMapCustomer: 'List has been updated',
  archived: 'Account has been successfully archived',
  driverTractorAssign: 'Assign tractor successfully',
  driverTrailerAssign: 'Assign trailer successfully',
  driverTractorUnAssign: 'Unassgin tractor successfully',
  driverTrailerUnAssign: 'Unassgin trailer successfully',
  updatePreferenceDriver: 'Preference updated successfully',
  SavedFactoring: 'Data saved successfully',
  unArchived: 'Account has been successfully restored',
  updateManualHosLocation: 'Location/HoS Updated Successfully',
  teamGroupCreation: 'Team Created Successfully',
  teamGroupDelete: 'Record Deleted Successfully',
  teamGroupUpdate: 'Record Updated Successfully',
  createContact: 'Contact was created successfully',
  customerCreate: 'Customer was created successfully',
  customerUpdate: 'Customer was updated successfully',
  customerDelete: 'Customer was deleted successfully',
  vendorCreate: 'Vendor was created successfully',
  vendorUpdate: 'Vendor was updated successfully',
  vendorDelete: 'Vendor was deleted successfully',
  locationCreate: 'Location was created successfully',
  locationUpdate: 'Location was updated successfully',
  locationDelete: 'Location was deleted successfully',
  userCreate: 'User was created successfully',
  userUpdate: 'User was updated successfully',
  userDelete: 'User was deleted successfully',
  userAlertCreate: 'User was created alert successfully',
  userAlertUpdate: 'User alert was updated successfully',
  userAlertDelete: 'User alert  was deleted successfully',
  userStatusChange: 'User status was change successfully',
  userInvitationStatus: 'User invitation sent successfully',
  payStatementNonTripCreate: 'Payment was created successfully',
  payStatementNonTripUpdate: 'Payment was updated successfully',
  payStatementNonTripDelete: 'Payment was deleted successfully',
  payStatementInReviewCreate: 'Settlement was moved in review successfully',
  payStatementInReviewClose: 'Settlement was closed successfully',
  payStatementReopen: 'Settlement was reopened successfully.',
  payStatementInReviewDelete: 'Settlement was deleted successfully',
  payStatementEmailSend: 'Email was sent successfully',
  templateDelete: 'Load template was deleted successfully',
  expenseCreate: 'Expense was created successfully',
  expenseUpdate: 'Expense was updated successfully',
  expenseDelete: 'Expense was deleted successfully',
  documentCreate: 'Document was created successfully',
  documentDelete: 'Document was deleted successfully',
  documentShare: 'You have successfully shared a document',
  importFile: 'File was imported successfully',
  invoiceUpdate: 'Invoice was updated successfully',
  invoiceEmailSend: 'Email was sent successfully',
  invoiceExport: 'Data was exported successfully',
  invoiceStatusChange: 'Invoice status was changed successfully',
  invoiceDownload: 'Invoice was downloaded successfully',
  tripAssignDriver: 'Successfully assigned driver',
  tripUnAssignDriver: 'Successfully unassigned driver',
  tripAssignTractor: 'Successfully assigned tractor',
  tripUnAssignTractor: 'Successfully unassigned tractor',
  tripAssignTrailer: 'Successfully assigned trailer',
  tripUnAssignTrailer: 'Successfully unassigned trailer',
  tripAssignDispatcher: 'Successfully assigned dispatcher',
  tripUnAssignDispatcher: 'Successfully unassigned dispatcher',
  tripAssignTerminal: 'Successfully assigned new terminal',
  regenerateTimeline: 'Timeline was regenerated successfully',
  tripResequience: 'Stop is being successfully re-sequenced',
  updateLoadReferenceDetails: 'Load was updated successfully',
  updateTripDispatchRevenue: 'Trip was updated successfully',
  reportGeneration: 'Report Generated Successfully.',
  reportGenerationInProgress:
    'Report Generation is in progress. Report will be downloaded automatically.',
  linkCopiedToClipboard: 'Link Copied To Clipboard!',
};
