import { QueryParams } from '../commonMixed/QueryParams';
import { Type } from 'class-transformer';

export class GetRecurrenceDTO extends QueryParams {
  templateSchedulerIds!: string;
  organizationId!: number;
  constructor() {
    super();
  }
}

export class GetLoadAlacacertsTypeDTO extends QueryParams {
  organizationId!: number;
}

export class GetRecurrenceRes {
  first!: boolean;
  last!: boolean;
}

export class CreateRecurrenceDTO extends QueryParams {
  @Type(() => axeleEntityObjDTO)
  axeleEntityObj!: axeleEntityObjDTO;
  currentRecurrencePeriod!: string;
  id!: string;
  @Type(() => monthlyRecurrenceDTO)
  monthlyRecurrence!: monthlyRecurrenceDTO;
  organizationId!: number;
  @Type(() => rangeOfRecurrenceDTO)
  rangeOfRecurrence!: rangeOfRecurrenceDTO;
  @Type(() => weeklyRecurrenceDTO)
  weeklyRecurrence!: weeklyRecurrenceDTO;
}

export class weeklyRecurrenceDTO {
  recurrenceWeekFrequency!: number;
  weekDaysEnum!: string[];
}

export class rangeOfRecurrenceDTO {
  startOfRecurrence!: string;
  @Type(() => endByRecurrenceDTO)
  endByRecurrence!: endByRecurrenceDTO;
}

export class endByRecurrenceDTO {
  endAfterNumberOfOccurrence!: number;
  endByDate!: string;
  noEndDate!: boolean;
}

export class axeleEntityObjDTO {
  recurrenceEntityType!: string;
  templateTypeId!: string;
}

export class monthlyRecurrenceDTO {
  dayOfMonth!: number;
}

export class DeleteRecurrenceDTO extends QueryParams {
  organizationId!: number;
}
