import { TLocationSelectValue } from '../../../common/Ui/LocationSelect';
import { OptionType } from '../../../models';

export interface IEDITendersSelectOption {
  value: string;
}

export interface IEDITendersFilters {
  customers?: OptionType[] | null;
  pickup?: TLocationSelectValue | null;
  dropoff?: TLocationSelectValue | null;
  shipmentId?: IEDITendersSelectOption[] | null;
  respondBy?: Date;
}

export enum EEDITendersStatus {
  New = 'New',
  Accepted = 'Accepted',
  Canceled = 'Canceled',
  Declined = 'Declined',
  All = '',
}

export enum EEDITendersGridAction {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
}

export enum EEDITenderSource {
  turvo = 'Turvo',
  edi = 'EDI',
  bitFreighter = 'BITFREIGHTER',
}
