import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { body2Color, fontFamily } from '../../constants';

const FeatureItem = ({
  text,
  subText,
  icon,
}: {
  text: string;
  subText: string;
  icon: ReactElement;
}) => {
  return (
    <Box sx={{ mb: 2, display: 'flex', flex: '1 1 50%' }}>
      <Box display="flex" alignItems="center" sx={{ mr: 3 }}>
        {icon}
      </Box>
      <Box>
        <Typography
          variant="h6"
          display="flex"
          alignItems="center"
          sx={{
            color: 'rgba(0, 17, 34, 0.75)',
            fontSize: 20,
            fontWeight: 500,
            fontFamily,
          }}
        >
          {text}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontFamily, fontSize: 14, color: body2Color, mt: 0.5 }}
        >
          {subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default FeatureItem;
