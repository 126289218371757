export default function OptymLogo() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2410_13868)">
        <path
          d="M7.05208 15.1535C5.1462 15.1535 3.60143 13.6099 3.60106 11.706V6.75977H0.0780298C0.0263869 7.10696 0 7.45792 0 7.80889V11.706C0 15.5967 3.15663 18.7505 7.05133 18.7509H10.9532C11.3045 18.7509 11.6558 18.7245 12.0034 18.6729V15.1535H7.05208Z"
          fill="#2B64CB"
        />
        <path
          d="M10.954 0.750001H7.05624C4.50802 0.748871 2.1577 2.12372 0.911865 4.34435H10.954C12.858 4.34435 14.4016 5.8864 14.402 7.78845V17.8195C16.6249 16.5746 18.0011 14.227 18 11.6814V7.78845C18 3.90075 14.8456 0.750001 10.954 0.750001Z"
          fill="#47A9F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_2410_13868">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
