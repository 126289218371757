import { useState, useEffect } from 'react';
import { setEntityData } from './constants/helper';
import { CustomerDetailsPanelWrapper } from '../../subPages/customers/components/CustomerDetailPanelWrapper';
import LocationDetailsPanel from '../../subPages/locations/components/LocationDetailsPanel';
import { entityTypes } from '../SearchContainer/constants';
import { GlobalPanelTypes } from './constants/interface';
import VendorDetailsPanelContainer from '../../subPages/vendors/containers/VendorDetailsPanelContainer';
import SecondaryDetailsPanel from '../SecondaryDetailsPanel/SecondaryDetailsPanel';
import { ESecondaryDetailsPanelType } from '../../views/dispatch/constants/types';
import { IHttpErrorType } from '../../types';

export const OpenGlobalPanel = ({
  selectedSearchItem,
  source = 'other',
  setShowSearchModal,
  setShowOpenGlobalPanel,
  deleteItemCB,
}: GlobalPanelTypes) => {
  const [httpErrors, setHttpErrors] = useState<IHttpErrorType>(
    {} as IHttpErrorType
  );
  const [data, setData] = useState({});
  const [showPanel, setShowPanel] = useState<boolean>(false);

  useEffect(() => {
    performIntialOperations();
  }, []);

  const performIntialOperations = async () => {
    if (selectedSearchItem) {
      const res = await setEntityData(selectedSearchItem);
      setData(res);
      setShowPanel(true);
      if (source == 'globalSearch') setShowSearchModal(false);
    }
  };

  const handleClose = () => {
    setShowPanel(false);
    setShowOpenGlobalPanel(false);
    if (selectedSearchItem.hasOwnProperty('searchKey'))
      setShowSearchModal(true);
  };

  const updateCustomerSummary = (customerSummary: any) => {};
  const handleCreated = (createValue?: any): void => {};
  const handleUpdated = (updateValue?: any): void => {};
  const handleDelete = (deleteValue?: any): void => {
    deleteItemCB?.(deleteValue);
    setShowSearchModal(true);
    setShowOpenGlobalPanel(false);
    setShowPanel(false);
  };

  const renderLoad = () => (
    <SecondaryDetailsPanel
      type={ESecondaryDetailsPanelType.LOAD}
      id={selectedSearchItem.entityId}
      onCreated={handleCreated}
      onUpdated={handleUpdated}
      onDeleted={handleDelete}
      onClose={handleClose}
      isGlobal
    />
  );

  const openCustomerDetailsPanel = () =>
    selectedSearchItem.entityType == entityTypes.CUSTOMER && showPanel;

  const renderCustomer = () => (
    <CustomerDetailsPanelWrapper
      isGlobal={true}
      handleClose={() => handleClose()}
      updateHttpErrors={() => {}}
      openDetailsPanel={openCustomerDetailsPanel}
      data={data}
      onCreated={handleCreated}
      onUpdated={() => handleClose()}
      onClose={() => handleClose()}
      onDeleted={handleDelete}
      isCreatePanelOpen={false}
      httpErrors={httpErrors}
      updateCustomerSummary={updateCustomerSummary}
      deleteCustomer={() => {}}
    />
  );

  const renderTractor = () => (
    <SecondaryDetailsPanel
      type={ESecondaryDetailsPanelType.TRACTOR}
      id={selectedSearchItem.entityId}
      onCreated={handleCreated}
      onUpdated={handleUpdated}
      onDeleted={handleDelete}
      onClose={handleClose}
      isGlobal
    />
  );

  const renderTrailer = () => (
    <SecondaryDetailsPanel
      type={ESecondaryDetailsPanelType.TRAILER}
      id={selectedSearchItem.entityId}
      onCreated={handleCreated}
      onUpdated={handleUpdated}
      onDeleted={handleDelete}
      onClose={handleClose}
      isGlobal
    />
  );

  const renderLocation = () => (
    <LocationDetailsPanel
      isGlobal={true}
      data={data}
      isCreatePanelOpen={false}
      onCreated={handleCreated}
      onUpdated={handleUpdated}
      onDeleted={handleDelete}
      onClose={() => handleClose()}
    />
  );

  const renderVendor = () => (
    <VendorDetailsPanelContainer
      isGlobal={true}
      selectedItem={data}
      onClose={() => handleClose()}
      onCreated={() => handleClose()}
      onUpdated={handleUpdated}
      onDeleted={handleDelete}
    />
  );

  const renderWebUser = () => (
    <SecondaryDetailsPanel
      type={ESecondaryDetailsPanelType.USER}
      id={selectedSearchItem.entityId}
      onCreated={handleCreated}
      onUpdated={handleUpdated}
      onDeleted={handleDelete}
      onClose={handleClose}
      isGlobal
    />
  );

  const renderTeamSoloDriver = () => (
    <SecondaryDetailsPanel
      type={ESecondaryDetailsPanelType.DISPATCH}
      id={selectedSearchItem.entityId}
      onCreated={handleCreated}
      onUpdated={handleUpdated}
      onDeleted={handleDelete}
      onClose={handleClose}
      isGlobal
    />
  );
  const renderCarrier = () => (
    <SecondaryDetailsPanel
      type={ESecondaryDetailsPanelType.DISPATCH}
      id={selectedSearchItem.entityId}
      onCreated={handleCreated}
      onUpdated={handleUpdated}
      onDeleted={handleDelete}
      onClose={handleClose}
      isGlobal
    />
  );

  return (
    <>
      {selectedSearchItem && (
        <>
          {selectedSearchItem.entityType == entityTypes.LOAD &&
            showPanel &&
            data &&
            renderLoad()}
          {selectedSearchItem.entityType == entityTypes.CUSTOMER &&
            showPanel &&
            renderCustomer()}
          {selectedSearchItem.entityType == entityTypes.TRACTOR &&
            showPanel &&
            renderTractor()}
          {selectedSearchItem.entityType == entityTypes.TRAILER &&
            showPanel &&
            renderTrailer()}
          {selectedSearchItem.entityType == entityTypes.LOCATION &&
            showPanel &&
            renderLocation()}
          {selectedSearchItem.entityType == entityTypes.VENDOR &&
            showPanel &&
            renderVendor()}
          {selectedSearchItem.entityType == entityTypes.USER &&
            showPanel &&
            renderWebUser()}
          {selectedSearchItem.entityType == entityTypes.SOLO &&
            showPanel &&
            renderTeamSoloDriver()}
          {selectedSearchItem.entityType == entityTypes.TEAM &&
            showPanel &&
            renderTeamSoloDriver()}
          {selectedSearchItem.entityType == entityTypes.CARRIER &&
            showPanel &&
            renderCarrier()}
        </>
      )}
    </>
  );
};
