import {
  CommonExpandableTimelineContentProps,
  TimelineVariantType,
} from '../../AxeleTimeline';
import { TripV3DataProps } from '../TripPlanV3';
import {
  ExpandableTimelineV3AddStopEvents,
  ExpandableTimelineV3EditIconEvents,
  ExpandableTimelineV3LoadIdEvents,
  ExpandableTimelineV3PanelView,
  ExpandableTimelineV3ResequenceStopEvents,
  StopSolutionV3Prop,
} from '../TripPlanV3/types';
import { TripStopStatusEnum, TripViewV3Mode } from '../types';
import {
  convertToHOSEventOnlyTimelineContentV3,
  convertToTimelineContentV3,
} from './timelineV3';
import {
  canShowFloatingActionButton,
  generateStopSolution,
  getLastIdxNotCompletedBeforeEnRoute,
  shouldDisableActionOnPreAndNext,
} from './timelineViewModeV3.utils';

export interface GetTimelineContentByViewModeV3Props
  extends ExpandableTimelineV3EditIconEvents,
    ExpandableTimelineV3AddStopEvents,
    ExpandableTimelineV3ResequenceStopEvents,
    ExpandableTimelineV3LoadIdEvents {
  stopList: StopSolutionV3Prop[];
  variant?: TimelineVariantType;
  viewMode: TripViewV3Mode;
  isMultipleExpanded?: boolean;
  isCompleted?: boolean;
  isCancelled?: boolean;
  disabled?: boolean;
  enablePreAndNext?: boolean;
  readOnly?: boolean;
  panelView: ExpandableTimelineV3PanelView;
  options?: Partial<CommonExpandableTimelineContentProps<StopSolutionV3Prop>>;
  data: TripV3DataProps;
}

export const getTimelineContentByViewModeV3 = ({
  stopList,
  variant,
  viewMode,
  panelView,
  onClickEditIconHandler,
  onClickAddStopHandler,
  onClickAddRelayHandler,
  onClickResequenceUpHandler,
  onClickResequenceDownHandler,
  onClickLoadIdHandler,
  isCompleted = false,
  isCancelled = false,
  disabled = false,
  enablePreAndNext = false,
  readOnly = false,
  options = {},
  data,
}: GetTimelineContentByViewModeV3Props): CommonExpandableTimelineContentProps<StopSolutionV3Prop>[] => {
  const timelineContentArr: CommonExpandableTimelineContentProps<StopSolutionV3Prop>[] =
    [];

  const lastIdxOfNotCompletedEnRoute: number =
    getLastIdxNotCompletedBeforeEnRoute(stopList, isCompleted);
  const isNotCompletedBeforeEnRoute = (idx: number): boolean => {
    return idx < lastIdxOfNotCompletedEnRoute;
  };
  const isNotCompletedBeforeClosetEnRoute = (idx: number): boolean => {
    return idx === lastIdxOfNotCompletedEnRoute - 1;
  };

  stopList.forEach((stopSolution: StopSolutionV3Prop, idx: number) => {
    const isStopCancelled =
      stopSolution?.status === TripStopStatusEnum.CANCELLED || isCancelled;
    if (idx === 0 && stopSolution?.HOSList && stopSolution.HOSList.length > 0) {
      timelineContentArr.push(
        convertToHOSEventOnlyTimelineContentV3({
          idx,
          id: `HOS-Event-${stopSolution.id.toString()}`, //temp unique id
          stopSolution: generateStopSolution(stopList, stopSolution, idx),
          variant: variant,
          isHideCollapse: true,
          isHideRightSubContent: false,
          hide: viewMode !== TripViewV3Mode.HOSEventView,
          isOpenCollapseDefault: true,
          panelView: panelView,
          viewMode: viewMode,
          showFloatingActionButton: false,
          isCompleted: isCompleted,
          isCancelled: isStopCancelled,
          disabled: disabled,
          data,
          shouldDisableActionOnPreAndNext: () => false, //always false
        })
      );
    }

    timelineContentArr.push(
      convertToTimelineContentV3({
        idx,
        id: stopSolution.id.toString(),
        stopSolution: generateStopSolution(stopList, stopSolution, idx),
        variant: variant,
        isHideCollapse: !!options?.isHideCollapse,
        isHideRightSubContent: false,
        isOpenCollapseDefault: false,
        panelView: panelView,
        viewMode: viewMode,
        onClickEditIconHandler,
        onClickAddStopHandler,
        onClickAddRelayHandler,
        onClickResequenceUpHandler,
        onClickResequenceDownHandler,
        onClickLoadIdHandler,
        showFloatingActionButton: canShowFloatingActionButton({
          preStopSolution: stopList[idx - 1],
          nextStopSolution: stopList[idx + 1],
        }),
        isCompleted: isCompleted,
        isCancelled: isStopCancelled,
        disabled: disabled,
        enablePreAndNext: enablePreAndNext,
        readOnly: readOnly,
        isNotCompletedBeforeEnRoute: isNotCompletedBeforeEnRoute(idx),
        isNotCompletedBeforeClosetEnRoute:
          isNotCompletedBeforeClosetEnRoute(idx),
        isShowFloatingTruckIconSection:
          !isCompleted &&
          !readOnly &&
          !disabled &&
          isNotCompletedBeforeClosetEnRoute(idx),
        options,
        data,
        shouldDisableActionOnPreAndNext: () =>
          shouldDisableActionOnPreAndNext(enablePreAndNext, idx, data),
      })
    );
  });

  return timelineContentArr;
};
