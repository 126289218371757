import {
  Box,
  BoxProps,
  Stack,
  StackProps,
  styled,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import AuthBackground from '../../_assets/images/login/loadopsBackground.jpg';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_TABLET,
} from '../../common/hooks/useThemeResponsive';

export const StyledFormTitle = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  fontFamily: 'Oxanium',
  fontSize: '32px',
  fontWeight: '600',
  lineHeight: '45px',
  width: '100%',
  marginBottom: '16px',
  justifyContent: 'center',
  [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
  },
}));
export const StyledAuthIntro = styled((props: TypographyProps) => (
  <Typography {...props} />
))(() => ({
  color: '#FFFFFF',
  fontFamily: 'Oxanium',
  fontSize: '52px',
  fontWeight: '600',
  lineHeight: '73px',
  textAlign: 'left',
}));
export const StyledAuthIntroForPartner = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  textAlign: 'left',
  color: theme.palette.tabStrip.actionIcon,
  textIndent: '-24px',
  marginLeft: 0,
}));

export const StyledAuthFromWrapper = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '48px 80px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'auto',
  [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
    padding: '16px',
  },
}));

export const StyledLoginAuthFromWrapper = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '48px 80px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
    padding: '16px',
  },
}));

export const StyledLoginAuthFromReactivateWrapper = styled(
  (props: BoxProps) => <Box {...props} />
)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '60px 20px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
    padding: '16px',
  },
}));

export const SxPropAuthButton = {
  width: '238px',
  color: 'primary.contrast',
};

export const StyledBlockTitle = styled((props: TypographyProps) => (
  <Typography variant="h5" {...props} />
))(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: '20px',
  height: '36px',
  [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '26px',
    textAlign: 'left',
  },
}));

export const StyledCardInfoRow = styled((props: StackProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    spacing={2}
    width={'100%'}
    {...props}
  />
))(({ theme }) => ({
  iframe: {
    width: '100% !important',
  },
  [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
    flexDirection: 'column',
  },
}));

export const StyledCardInfoRowItem = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
    width: '100%',
    marginLeft: '0 !important',
  },
}));

export const getSxPropCardInfoInput = ({
  theme,
  hasStartIcon = false,
}: {
  theme: Theme;
  hasStartIcon?: boolean;
}) => {
  return {
    field: {
      ...(hasStartIcon && {
        paddingTop: '8px',
      }),
    },
    input: {
      maxHeight: '56px',
      minHeight: '56px !important',
      paddingTop: '15px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingBottom: '14px',
      borderColor: theme.palette.text.secondary,
      background: 'none',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.167,
      borderRadius: '14px',
      color: theme.palette.text.secondary,
      ...(hasStartIcon && {
        paddingLeft: '66px',
        marginTop: '-8px',
      }),
    },
    label: {
      display: 'none',
    },
    message: {
      color: '#CF383C',
      margin: 0,
      fontWeight: '400px',
      lineHeight: '12px',
      paddingTop: '5px',
      paddingLeft: '22px',
    },
  };
};

export const getPhoneFieldStylePropsAuthForm = (isMobile: boolean) => {
  return {
    countryCodestyleProps: {
      width: isMobile ? undefined : 'auto',
    },
    phoneFieldStyleProps: {
      width: '100%',
    },
    extnStyleProps: {
      width: isMobile ? '100%' : 'auto',
    },
  };
};

export const getRegistrationStyles = ({
  isExistPartnerUser,
  theme,
}: {
  theme: Theme;
  isExistPartnerUser?: boolean;
}) => {
  return {
    authRoot: {
      [theme.breakpoints.down(BREAKPOINT_LAPTOP)]: {
        width: '100%',
        height: '100vh',
      },
      [theme.breakpoints.between(BREAKPOINT_TABLET, BREAKPOINT_LAPTOP)]: {
        width: '100%',
        height: '100vh',
      },
      [theme.breakpoints.between(BREAKPOINT_LAPTOP, BREAKPOINT_DESKTOP)]: {
        width: '100%',
        height: '100vh',
      },
      [theme.breakpoints.up(BREAKPOINT_DESKTOP)]: {
        width: '100%',
        height: '100vh',
      },
    },
    authBackgroundImage: {
      width: '100%',
      // borderRadius: '13px',

      [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
        padding: 0,
        // marginTop: '-16px',
        height: '100vh',
        background: `url(${AuthBackground})`,
      },
      [theme.breakpoints.between(BREAKPOINT_TABLET, BREAKPOINT_LAPTOP)]: {
        padding: 0,
        // marginTop: '-16px',
        height: '100%',
        background: `url(${AuthBackground})`,
      },
      [theme.breakpoints.up(BREAKPOINT_LAPTOP)]: {
        ...(isExistPartnerUser
          ? {
              background: '#12171F',
              maxWidth: 'unset',
              height: '100%',
            }
          : {
              background: `url(${AuthBackground})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              maxWidth: 'unset',
              height: '100%',
            }),
      },
    },
    authContent: {
      alignItems: 'flex-start',
      height: '100%',
      width: '100%',
      maxWidth: 'unset',
      [theme.breakpoints.down(BREAKPOINT_LAPTOP)]: {
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      [theme.breakpoints.up(BREAKPOINT_LAPTOP)]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      [theme.breakpoints.between(BREAKPOINT_LAPTOP, BREAKPOINT_DESKTOP)]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    formWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '32px',
      maxWidth: '700px',
      minWidth: '700px',

      justifyContent: 'center',
      [theme.breakpoints.down(BREAKPOINT_LAPTOP)]: {
        borderRadius: '0px',
        width: '100%',
        maxWidth: '100%',
        minWidth: '100%',
      },
      [theme.breakpoints.up(BREAKPOINT_LAPTOP)]: {
        minWidth: '700px',
        maxWidth: '700px',
      },
    },
    formWrapperReactivate: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '32px',
      maxWidth: '800px',
      minWidth: '800px',

      justifyContent: 'center',
      [theme.breakpoints.down(BREAKPOINT_LAPTOP)]: {
        borderRadius: '0px',
        width: '100%',
        maxWidth: '100%',
        minWidth: '100%',
      },
      [theme.breakpoints.up(BREAKPOINT_LAPTOP)]: {
        minWidth: '800px',
        maxWidth: '800px',
      },
    },
    formWrapperContent: {
      width: '100%',
      background: '#1F2E3D',
      boxShadow:
        '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
      borderRadius: '13px',
      height: '100%',
    },
    authFooter: {
      [theme.breakpoints.down(BREAKPOINT_LAPTOP)]: {
        display: 'none',
      },
    },
  };
};
