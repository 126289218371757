/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import axiosInstance, {
  ComposedError,
  httpClient,
} from '../../axios/axiosInstance';
import {
  ForgotPasswordRequest,
  GetInvoiceListSubscriptionRequest,
  QueryParams,
  Register,
  RegisterResponse,
  ResetPasswordRequest,
} from '../../models';

import { annotateNameAsync, ISubscriptionService } from '../interfaces';
import { ActivateAccount } from '../../models/DTOs/Subscription/Subscription';
import * as Subscription from '../../models/DTOs/Subscription/Subscription';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import * as RegistrationPageUtils from '../../views/register/RegistrationPageUtils';
import { FailureNotificationConstants } from '../../locales/en/notifications/failureNotifications';

const routes = {
  getProforma: '/web/organization/api/v2/chargify/proforma/invoice/list',
  validate: '/web/organization/api/v2/registration/register/validation',
  company: '/web/organization/api/v2/registration/register/company',
  forgot: '/web/organization/api/v2/registration/forgot',
  reset: '/web/organization/api/v2/registration/change/password',
  getSubscription: '/web/organization/api/v2/chargify/subscription',
  activateAccount: '/web/organization/api/v2/chargify/updateComponent',
  subscriptionPlan: '/web/organization/api/v2/registration/activate',
  invoiceList: '/web/organization/api/v2/chargify/invoice/list',
  reasonCode: '/web/organization/api/v2/chargify/reasonCode/list',
  cancelSubscription: '/web/organization/api/v2/chargify/subscription/cancel',
  payAndCancel:
    '/web/organization/api/v2/chargify/subscription/delayedCancellation',
  upsertPayment: '/web/organization/api/v2/chargify/creditcard',
  paymentProfiles: '/web/organization/api/v2/chargify/getPaymentProfiles',
  setDefaultPaymentProfile:
    '/web/organization/api/v2/chargify/setDefaultPaymentProfile',
  reJoin: '/web/organization/api/v2/registration/rejoin',
  dotValidation:
    '/web/organization/api/v2/registration/register/validation/dot',
  verificationEmail:
    '/web/organization/api/v2/registration/register/resend/email',
  reCreate: '/web/organization/api/v2/registration/recreate/subscription',
};

export class SubscriptionService extends ISubscriptionService {
  @annotateNameAsync
  async validateRegisterData(requestData: Register): Promise<any> {
    try {
      const response = await axiosInstance.post(routes.validate, requestData);
      return Promise.resolve(response?.data);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'duplicateCompany',
      });
      return Promise.resolve(false);
    }
  }
  @annotateNameAsync
  async userRegistration(
    requestData: Subscription.RegisterPage
  ): Promise<boolean> {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await httpClient.postRaw(
        routes.company,
        undefined,
        requestData,
        false,
        headers,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Registration Successfully',
      });
      return Promise.resolve(response?.data);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message:
          composedError?.error?.response?.data?.message ||
          FailureNotificationConstants.duplicateCompany,
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async registerData(requestData: Register): Promise<RegisterResponse | null> {
    try {
      const response = await axiosInstance.post(routes.company, requestData);
      return Promise.resolve(response?.data);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'registration',
      });
      return Promise.resolve(errMsg);
    }
  }

  @annotateNameAsync
  async forgotPassword(requestData: ForgotPasswordRequest): Promise<boolean> {
    try {
      const response = await axiosInstance.post(routes.forgot, requestData);
      return Promise.resolve(response?.data ? true : false);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'forgotPassword',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async resetPassword(requestData: ResetPasswordRequest): Promise<boolean> {
    try {
      const response = await axiosInstance.post(routes.reset, requestData);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'reset',
      });
      return Promise.resolve(response?.data ? true : false);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'reset',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async getSubscription(
    requestData: Subscription.GetSubscriptionRequest
  ): Promise<any> {
    try {
      const response = await axiosInstance.get(routes.getSubscription, {
        params: requestData,
      });
      return Promise.resolve(response?.data || {});
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'getSubscription',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async activateAccount(requestData: ActivateAccount): Promise<boolean> {
    try {
      const response = await axiosInstance.post(
        `${routes.activateAccount}?organizationId=${requestData.organizationId}&subscriptionId=${requestData.subscriptionId}&componentId=${requestData.componentId}&enabled=${requestData.enabled}`
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'activateAccount',
      });
      return Promise.resolve(response?.data);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'activateAccount',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async subscriptionPlan(
    requestData: Subscription.SubscriptionPlanRequest
  ): Promise<boolean> {
    try {
      const response = await axiosInstance.post(
        routes.subscriptionPlan,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Account activated successfully',
      });
      return Promise.resolve(response?.data);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'generalFailureMsg',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async getInvoiceList(
    requestData: GetInvoiceListSubscriptionRequest
  ): Promise<Subscription.GetInvoiceListResponse> {
    try {
      const response = await axiosInstance.get(routes.invoiceList, {
        params: { ...requestData, pageNumber: 1, pageSize: 999 },
      });
      return Promise.resolve(response?.data?.content || []);
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async getReasons(): Promise<Subscription.GetInvoiceListResponse> {
    try {
      const response = await axiosInstance.get(routes.reasonCode);
      return Promise.resolve(response?.data?.output || []);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'failedData',
      });
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async cancelSubscription(
    requestData: Subscription.CancelSubscriptionRequest
  ): Promise<boolean> {
    try {
      const { organizationId } = requestData;
      const response = await axiosInstance.post(
        routes.cancelSubscription,
        requestData,
        { params: { organizationId } }
      );
      return Promise.resolve(!!response);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'cancelSubscription',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async payAndCancel(
    requestData: Subscription.CancelSubscriptionRequest
  ): Promise<boolean> {
    try {
      const { organizationId } = requestData;
      let response;
      if (requestData.delete) {
        response = await axiosInstance.delete(routes.payAndCancel, {
          params: { organizationId },
        });
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'deleteCancellation',
        });
        return Promise.resolve(
          response.status === 200 || response.status === 204
        );
      } else {
        response = await axiosInstance.post(routes.payAndCancel, requestData, {
          params: { organizationId },
        });
        return Promise.resolve(response?.data ? true : false);
      }
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'generalFailureMsg',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async upsertPayment(
    requestData: Subscription.UpsertPaymentRequest
  ): Promise<boolean> {
    try {
      const response = await axiosInstance.post(
        routes.upsertPayment,
        requestData
      );
      return Promise.resolve(response?.data ? true : false);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'generalFailureMsg',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async deletePayment(
    requestData: Subscription.UpsertPaymentRequest
  ): Promise<boolean> {
    try {
      const response = await axiosInstance.delete(routes.upsertPayment, {
        params: requestData,
      });
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deletePayment',
      });
      return Promise.resolve(response.status === 204);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'deletePayment',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async setDefaultPayment(
    requestData: Subscription.UpsertPaymentRequest
  ): Promise<boolean> {
    try {
      const response = await axiosInstance.put(
        routes.setDefaultPaymentProfile,
        {},
        {
          params: requestData,
        }
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'primaryPayment',
      });
      return Promise.resolve(response?.data ? true : false);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'generalFailureMsg',
      });
      return Promise.resolve(false);
    }
  }

  @annotateNameAsync
  async getPaymentProfiles(
    requestData: Subscription.GetPaymentProfileRequest
  ): Promise<Record<string, string>> {
    try {
      const response = await axiosInstance.get(routes.paymentProfiles, {
        params: requestData,
      });
      return Promise.resolve(response?.data || []);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'failedData',
      });
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async getProforma(requestData: QueryParams): Promise<Record<string, string>> {
    try {
      const response = await axiosInstance.get(routes.getProforma, {
        params: requestData,
      });
      return Promise.resolve(response?.data?.length ? response.data[0] : {});
    } catch (error) {
      return Promise.resolve({});
    }
  }
  @annotateNameAsync
  async upsertPaymentRejoin(
    requestData: Subscription.UpsertPaymentRejoinRequest
  ): Promise<boolean> {
    try {
      const response = await axiosInstance.post(routes.reJoin, requestData);
      return Promise.resolve(response?.data ? true : false);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'generalFailureMsg',
      });
      return Promise.resolve(false);
    }
  }
  @annotateNameAsync
  async getDotValidation(
    requestData: RegistrationPageUtils.RegistrationDotValidationModel
  ): Promise<string> {
    try {
      await httpClient.getRaw(
        routes.dotValidation,
        requestData,
        undefined,
        true
      );
      return 'Success';
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError)?.error?.response?.data
        ?.message;
    }
  }
  @annotateNameAsync
  async getVerificationEmail(
    requestData: RegistrationPageUtils.RegistrationVerificationModel
  ): Promise<string> {
    try {
      await httpClient.getRaw(routes.verificationEmail, requestData);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Email sent Successfully',
      });
      return 'Success';
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message:
          error?.error?.response?.data?.message || 'Failed to fetch data',
      });
      return 'failed';
    }
  }
  @annotateNameAsync
  async ReactivationFlow(
    requestData: RegistrationPageUtils.RegisterReCreateModel
  ): Promise<boolean> {
    try {
      const response = await axiosInstance.post(routes.reCreate, requestData);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Account reactivated successfully. Please login to proceed.',
      });
      return true;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message:
          error?.error?.response?.data?.message || 'Failed to reactivated',
      });
      return false;
    }
  }
}
