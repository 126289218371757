import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { invoiceModuleSetting } from '../../../../common/PendoClassIDs/constants';
import DatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import {
  RootStoreInstence,
  useRootStore,
} from '../../../../store/root-store/rootStateContext';
import StaticActions from '../../../../utils/StaticActions';
import { getCustomerList } from '../../../loadsList/LoadDetailsPanel/utils/customer';
import {
  CANCELLED_LOAD_STATUS_NAME,
  INVOICED_LOAD_STATUS_NAME,
  PAID_LOAD_STATUS_NAME,
  WarningIconWithTooltip,
} from '../../constants';
import { CustomerContactType, InvoiceModel } from '../../models/InvoiceModel';
import {
  MultiInvoiceIcon,
  addFullNameIfNecessaryToContact,
  notesMsg,
} from '../../utils';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { SingleFieldInfoCard } from '../../../../common/SingleFieldInfoCard';
import { Grid, Typography } from '@mui/material';
import TerminalController from '../../../../views/settings/terminals/TerminalController';
import { useStores } from '../../../../store/root.context';
import InvoiceCustomDetailPanel from '../../../../subPages/invoices/InvoiceCustomDetailPanel';
import { SingleAutoCompleteNetTerms } from '../loadGroupInvoiceItem';
import moment from 'moment';
export function InvoiceSettings({
  invoice,
  canEditInvoice,
}: {
  invoice: InvoiceModel;
  canEditInvoice: boolean;
}) {
  const [secondaryPanelData, setSecondaryPanelData] = useState<{
    isOpen: boolean;
    selectedMutliInvoice: string;
  }>({
    isOpen: false,
    selectedMutliInvoice: '',
  });
  const { control, setValue, watch } = useFormContext();
  const [tempContacts, setTempContacts] = useState<CustomerContactType[]>([]);
  const [terms, setTerms] = useState([]);
  const [customTermValue, setCustomTermValue] = useState<string | null>(null);
  const {
    invoiceStore: { setAddInvoiceRecord, selectedInvoices, isInvoiceCreate },
  } = useStores();
  const [
    customer,
    invoiceTerm,
    factoringCompany,
    terminal,
    seqNumber,
    notes,
    childInvoices,
    childInvoice,
    masterInvoiceSeqNumber,
    messageOnInvoice,
    invoiceTermValue,
    invoicedOn,
  ] = watch([
    'customer',
    'invoiceTerm',
    'factoringCompany',
    'terminal',
    'seqNumber',
    'notes',
    'childInvoices',
    'childInvoice',
    'masterInvoiceSeqNumber',
    'messageOnInvoice',
    'invoiceTermValue',
    'invoicedOn',
  ]);
  TerminalController.instance().getTerminals();
  const { terminals } = TerminalController.instance();
  const { getIsTerminalEnabled } = useRootStore();

  const isInvoiceTermCustom = invoiceTerm === 'CUSTOM';
  // set primary contact as default contact
  const handleCustomerChange = (customer: {
    contacts: CustomerContactType[];
    id: string;
  }) => {
    if (customer) {
      const contacts = addFullNameIfNecessaryToContact(customer.contacts);
      setTempContacts(contacts);
      setValue('customerId', customer.id);

      const primaryContact = contacts.find((contact) => contact.isPrimary);
      setValue('customerContactId', primaryContact?.id || null);
    } else {
      setValue('customerContactId', null);
      setValue('customerId', null);
    }
  };

  useEffect(() => {
    if (customer) {
      const contacts = addFullNameIfNecessaryToContact(customer.contacts);
      setTempContacts(contacts);
    }
  }, [customer?.id]);
  useEffect(() => {
    if (isInvoiceCreate) {
      setValue('invoicedOn', moment());
    }
    const getInvoiceTerms = async () => {
      const result = await StaticActions.getInvoiceTerms();
      setTerms(result.invoiceTerm || result);
    };
    getInvoiceTerms();
  }, []);
  useEffect(() => {
    if (terminal) return;
    // if (!isInvoiceCreate) return;
    async function primaryTerminal() {
      const terminalsList = await TerminalController.instance().getTerminals();
      if (terminalsList?.length) {
        const terminalValue =
          await TerminalController.instance().getPrimaryTerminal();
        if (terminalValue) {
          setValue('terminal', terminalValue);
        }
      }
    }
    primaryTerminal();
  }, []);

  useEffect(() => {
    const preferences = RootStoreInstence.getCompanyPreferences;
    if (preferences) {
      setCustomTermValue(preferences.invoiceTerm as string);
    }
  }, []);
  const paidOrInvoicedStatus =
    invoice?.status === PAID_LOAD_STATUS_NAME ||
    invoice?.status === INVOICED_LOAD_STATUS_NAME;

  const { hasFinancialInvoiceEdit } = useLoadPermission();

  const disableInvoiceEditing = !hasFinancialInvoiceEdit || !canEditInvoice;
  const invoiceCanceledStatus =
    invoice?.status === CANCELLED_LOAD_STATUS_NAME &&
    !invoice?.lineItems?.length &&
    invoice?.nonInvoiceable;
  const handleInvoiceTermChange = (data: { key: string }) => {
    if (data.key !== 'CUSTOM') {
      setValue('invoiceTermValue', null);
      return;
    }
    setValue('invoiceTermValue', invoice?.invoiceTermValue || customTermValue);
  };
  const getInvoiceLabel = () => {
    const isMutliInvoiceLoad =
      window.location.pathname === '/' && invoice.childInvoice;

    return (
      <Box sx={{ color: 'primary.main', display: 'flex' }}>
        {isMutliInvoiceLoad ? (
          <MultiInvoiceIcon />
        ) : (
          <RequestQuoteOutlinedIcon />
        )}

        <Typography
          sx={{
            pl: '5px',
            pt: '3px',
            textDecoration: isMutliInvoiceLoad ? 'underline' : '',
            cursor: isMutliInvoiceLoad ? 'pointer' : '',
          }}
          onClick={() => {
            if (isMutliInvoiceLoad) {
              setSecondaryPanelData({
                isOpen: true,
                selectedMutliInvoice: invoice.masterInvoiceId,
              });
            }
          }}
        >
          {childInvoice ? masterInvoiceSeqNumber : seqNumber}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {!(isInvoiceCreate || invoice?.status === 'INVOICE') && (
        <SingleFieldInfoCard
          label="Invoice ID"
          value={getInvoiceLabel()}
          styles={{ width: '50%', pt: '8px', pl: '8px', m: 0 }}
          sizes={{ xs: 6 }}
        />
      )}
      {(isInvoiceCreate || paidOrInvoicedStatus) && (
        <DatePicker
          iso
          format
          // disabled
          sizes={{ xs: 6 }}
          wrapperStyles={{ marginBottom: '0px', paddingTop: '5px' }}
          styleProps={{ width: '100%' }}
          control={control}
          name={'invoicedOn'}
          label={'Invoice Date'}
          disabled={
            !(
              disableInvoiceEditing ||
              invoiceCanceledStatus ||
              selectedInvoices?.length > 0 ||
              !invoice.childInvoice ||
              isInvoiceCreate
            )
          }
        />
      )}
      {isInvoiceCreate && getIsTerminalEnabled && (
        <SingleAutocompleteForm
          control={control}
          name={'terminal'}
          fieldName={'companyName'}
          getOptions={() => new Promise((res) => res(terminals))}
          label={'Invoice Terminal'}
          customStyles={{ pt: 0, m: 0 }}
          sizes={{
            xs: 6,
          }}
          required
        />
      )}

      <SingleAutocompleteForm
        id={invoiceModuleSetting + 'CustomerName'}
        sizes={{ xs: 6 }}
        customStyles={{ pt: 0, m: 0 }}
        startAdornment={
          !invoice.customerMapped &&
          typeof invoice.customerMapped === 'boolean' &&
          customer &&
          factoringCompany ? (
            <Box sx={{ mr: 1 }}>
              <WarningIconWithTooltip
                stripId={'customer'}
                externalId={invoice.customer?.id}
                text={'Customer is not Mapped with factoring company.'}
              />
            </Box>
          ) : null
        }
        disabled={
          isInvoiceCreate
            ? false
            : invoice.invoiceType === 'MASTER' ||
              invoice?.status === PAID_LOAD_STATUS_NAME
        }
        control={control}
        name={'customer'}
        fieldName={'name'}
        label={'Customer Name'}
        getOptions={getCustomerList}
        onChangeCb={handleCustomerChange}
        required
      />
      <SingleAutocompleteForm
        id={invoiceModuleSetting + 'ContactInfo'}
        control={control}
        name={`customerContactId`}
        sizes={{ xs: 6 }}
        fieldName={`fullName`}
        customStyles={{ pt: 0, m: 0 }}
        label={'Contact Info'}
        options={tempContacts}
        fieldValue={'id'}
        disabled={
          isInvoiceCreate
            ? false
            : invoice.invoiceType === 'MASTER' ||
              invoice?.status === PAID_LOAD_STATUS_NAME
        }
        shouldUnregister
      />
      {!isInvoiceCreate && getIsTerminalEnabled && (
        <SingleAutocompleteForm
          control={control}
          name={'terminal'}
          fieldName={'companyName'}
          getOptions={() => new Promise((res) => res(terminals))}
          label={'Invoice Terminal'}
          customStyles={{ pt: 0, m: 0 }}
          sizes={{
            xs: paidOrInvoicedStatus ? 12 : 6,
          }}
          disabled={
            (paidOrInvoicedStatus ||
              disableInvoiceEditing ||
              invoiceCanceledStatus ||
              selectedInvoices?.length > 0 ||
              invoice.childInvoice ||
              childInvoices.length > 0) &&
            !(invoice.invoiceType === 'CUSTOM')
          }
          required
        />
      )}

      {!isInvoiceCreate && invoice?.status === 'PAID' && (
        <DatePicker
          iso
          format
          // disabled
          control={control}
          sizes={{ xs: 6 }}
          wrapperStyles={{ marginBottom: '0px', marginTop: '-3px' }}
          name={'paidOn'}
          label={'Payment Date'}
          styleProps={{ width: '100%' }}
          disabled={
            !(
              paidOrInvoicedStatus ||
              disableInvoiceEditing ||
              invoiceCanceledStatus ||
              invoice.childInvoice
            )
          }
        />
      )}

      {SingleAutoCompleteNetTerms(
        terms,
        control,
        disableInvoiceEditing || invoiceCanceledStatus || invoice.childInvoice,
        isInvoiceCreate
      )}

      {/* <SingleAutoCompleteNetTerms terms={terms} control={control} isDisable={disableInvoiceEditing || invoiceCanceledStatus || invoice.childInvoice}/> */}
      {/* {(invoice?.status === 'LOAD_COMPLETED' || invoice?.status === 'CANCELLED' ) && ) */}

      {/* <SingleAutocompleteForm
        id={invoiceModuleSetting + 'InvoiceTerm'}
        control={control}
        name={'invoiceTerm'}
        fieldName={'value'}
        fieldValue={'key'}
        label={'Net Term'}
        customStyles={{ p: 0, m: 0 }}
        options={terms}
        sizes={{ xs: isInvoiceTermCustom ? 6 : 12 }}
        disabled={
          disableInvoiceEditing || invoiceCanceledStatus || invoice.childInvoice
        }
        onChangeCb={handleInvoiceTermChange}
        required
      />
      {isInvoiceTermCustom && (
        <TextField
          id={invoiceModuleSetting + 'NumberOfdays'}
          control={control}
          name={'invoiceTermValue'}
          sizes={{ xs: 6 }}
          label={'Number of Days'}
          style={{ p: 0, m: 0 }}
          type={'number'}
          disabled={!canEditInvoice}
          required={isInvoiceTermCustom}
        />
      )} */}
      <TextField
        id={invoiceModuleSetting + 'LoadedMiles'}
        control={control}
        sizes={{
          xs: !getIsTerminalEnabled
            ? !getIsTerminalEnabled &&
              invoice?.status === INVOICED_LOAD_STATUS_NAME
              ? 6
              : 12
            : getIsTerminalEnabled && invoice?.status === PAID_LOAD_STATUS_NAME
            ? 12
            : 6,
        }}
        style={{ p: 0, m: 0, mt: '-0px' }}
        name={childInvoice ? 'masterInvoiceReferenceId' : 'invoiceReferenceId'}
        label={'Invoice Reference Number'}
        disabled={
          disableInvoiceEditing ||
          invoiceCanceledStatus ||
          invoice.childInvoice ||
          invoice.invoiceType === 'LOAD'
        }
      />

      {/* <TextField
        id={invoiceModuleSetting + 'LoadedMiles'}
        control={control}
        sizes={{ xs: 6 }}
        name={'loadedMiles'}
        label={'Loaded Miles'}
        onlyNumbers
        disabled={!hasFinancialInvoiceEdit || invoiceCanceledStatus}
      />
      <TextField
        id={invoiceModuleSetting + 'EmptyMiles'}
        control={control}
        name={'emptyMiles'}
        sizes={{ xs: 6 }}
        label={'Empty Miles'}
        onlyNumbers
        disabled={!hasFinancialInvoiceEdit || invoiceCanceledStatus}
      /> */}
      <TextField
        id={invoiceModuleSetting + 'InternalInvoiceNotes'}
        control={control}
        sizes={{ xs: 12 }}
        style={{ p: 0, m: 0, pt: '0px', mt: '-7px' }}
        styleProps={{ width: '100%', pt: '0px' }}
        name={'notes'}
        label={'Internal Notes for Invoicing'}
        disabled={
          disableInvoiceEditing ||
          invoiceCanceledStatus ||
          invoice.childInvoice ||
          !canEditInvoice
        }
      />
      {notesMsg(
        disableInvoiceEditing || invoiceCanceledStatus || invoice.childInvoice,
        control,
        messageOnInvoice
      )}
      {/* <TextField
        // id={invoiceModuleSetting + 'InternalInvoiceNotes'}
        control={control}
        sizes={{ xs: 12 }}
        name={'messageOnInvoice'}
        style={{ p: 0, m: 0 }}
        // placeholder={'Message On Invoicing'}
        label={'Message On Invoicing'}
        multiline
        // maxRows={4}
        disabled={
          !hasFinancialInvoiceEdit ||
          invoiceCanceledStatus ||
          invoice.childInvoice
        }
      /> */}

      {secondaryPanelData.isOpen && (
        <InvoiceCustomDetailPanel
          onClose={() =>
            setSecondaryPanelData({ isOpen: false, selectedMutliInvoice: '' })
          }
          data={secondaryPanelData?.selectedMutliInvoice}
          isGlobal={true}
        />
      )}
    </>
  );
}
