import { TractorAlertSummaryDTO } from '../../../models';
import { fromMomentToIso, ParseToDate } from '../../../utils';
import { Payload } from '../commonMixed/Payload';
import { QueryParams } from '../commonMixed/QueryParams';

export class PaginatedTractorsListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;
  safetyIssueType!: string[];
  gridColumnMetadataList?: string[] | null;
  driverGroupFilter?: string[] | null;
  vinFilter?: string[];
  nameFilter?: string[];
  licensePlateFilter?: string[];
  safetyIssueFilter?: string[];
  statusFilter?: string[];
  inTransitFilter?: string[];
  regExpiryFrom?: string;
  regExpiryTo?: string;
  terminalIds?: string[];
}

export class DriverGroupSearchListParams extends QueryParams {
  pageNumber?: number;
  pageSize: number;
  nameFilter: string | undefined;
  terminalIds?: string[];
  sort: string;
  activeForOperation?: boolean;
  typeFilter?: string;
  constructor(nameFilter: string | undefined) {
    super();
    this.pageSize = 25;
    this.nameFilter = nameFilter;
    this.sort = '+groupName';
  }
}

export class DriverWarningParams extends QueryParams {
  driverGroupId: string;
  tractorId?: number;
  trailerId?: number;
  warningTypes: string[];
  constructor(dto: {
    driverGroupId: string;
    tractorId?: number;
    trailerId?: number;
    warningTypes: string[];
  }) {
    super();
    this.driverGroupId = dto.driverGroupId;
    this.tractorId = dto.tractorId;
    this.trailerId = dto.trailerId;
    this.warningTypes = dto.warningTypes;
  }
}

export class DriverMaintenanceWarningParams extends QueryParams {
  driverGroupId: string;
  scenarioType: string;
  constructor(dto: { driverGroupId: string; scenarioType: string }) {
    super();
    this.driverGroupId = dto.driverGroupId;
    this.scenarioType = dto.scenarioType;
  }
}

export class EntitySearchListParams extends QueryParams {
  pageNumber?: number;
  pageSize: number;
  keyword: string | undefined;
  terminalIds?: string[];
  excludeStatusList: string[] = [];
  constructor(keyword: string | undefined) {
    super();
    this.pageSize = 25;
    this.keyword = keyword;
  }
}

export class VerifyTractorByIdQueryParams extends QueryParams {
  tractorId: string;
  constructor(id: string) {
    super();
    this.tractorId = id;
  }
}

export class TractorCreateQueryParams extends Payload {
  tractorName: string;
  terminalId: number | null;
  id?: string;
  vin?: string;
  status?: string; // should be deleted
  licensePlateNumber?: string;
  licenseValidTill?: string | null;
  assetLocationDTO: any;
  ownershipType?: string;
  ownedCompany?: string;
  licenseState?: string;
  currentOdometer: number;
  extension?: number;
  mobileno?: number;
  ownerEmail?: string;
  ownerAddress?: any;
  make?: string;
  model?: string;
  year?: number;
  tollProvider?: string;
  tollTagNumber?: string;
  fuelCardProvider?: string;
  fuelCardNumber?: string;
  fuelCapacity?: number;
  fuelType?: string;
  gvw?: number;
  braking?: string;
  isSleeperBerthAvailable?: boolean;
  transmission: string;
  insuranceCompany?: string;
  insuranceStartDate?: string | null;
  insuranceEndDate?: string | null;
  fieldsToBeSetNull?: string[];
  constructor(tractor: any) {
    super(true);
    if (tractor.id) {
      this.id = tractor.id;
    }
    this.status = tractor.status;
    this.tractorName = tractor.tractorName;
    this.terminalId = tractor.terminalId || null;
    this.vin = tractor.vin || null;
    this.licensePlateNumber = tractor.licensePlateNumber || null;
    this.licenseValidTill = fromMomentToIso(tractor.licenseValidTill);
    this.assetLocationDTO = this.setupAssetLocationDTO(tractor);
    this.ownershipType = tractor.ownershipType || null;
    this.ownedCompany = tractor.ownedCompany || null;
    this.licenseState = tractor.licenseState || null;
    this.currentOdometer = tractor.currentOdometer;
    this.extension = tractor.phone.extension || null;
    this.mobileno = tractor.phone.phone || null;
    this.ownerEmail = tractor.ownerEmail || null;
    this.ownerAddress = tractor.ownerAddress || null;
    this.make = tractor.make || null;
    this.model = tractor.model || null;
    this.year = tractor.year || null;
    this.tollProvider = tractor.tollProvider || null;
    this.tollTagNumber = tractor.tollTagNumber || null;
    this.fuelCapacity = tractor.fuelCapacity || null;
    this.fuelType = tractor.fuelType?.value || null;
    this.gvw = tractor.gvw || null;
    this.braking = tractor.braking || null;
    this.isSleeperBerthAvailable = tractor.isSleeperBerthAvailable || false;
    this.transmission = this.setManualTransmission(tractor.transmission);
    this.insuranceCompany = tractor.insuranceCompany || null;
    this.insuranceStartDate = tractor.insuranceDate[0];
    this.insuranceEndDate = tractor.insuranceDate[1];
    if (tractor.id) {
      this.setFieldsToBeSetNull();
    }
  }

  setFieldsToBeSetNull() {
    // to be sure that boolean values are not set to null
    const fields: string[] = [];
    Object.keys(this).forEach((k) => {
      const key = k as keyof TractorCreateQueryParams;
      const item = this[key];
      if (!item && item !== false && item !== 0) {
        fields.push(key);
      }
    });
    this.fieldsToBeSetNull = fields;
    if (!this.assetLocationDTO.addressDTO?.address) {
      this.fieldsToBeSetNull.push('assetLocation');
      this.assetLocationDTO.addressDTO = null;
    }
    if (!this.ownerAddress?.address) {
      this.fieldsToBeSetNull.push('ownerAddress');
      this.ownerAddress = null;
    }
  }

  setManualTransmission(transmission: string) {
    if (transmission) return 'MANUAL';
    return 'AUTOMATIC';
  }

  setupAssetLocationDTO(tractor: any) {
    return {
      addressDTO: tractor.lastLocation || null,
      locationSource: tractor.locationSource || null,
    };
  }
}

export class TractorAlertQueryParamsRequest extends QueryParams {
  constructor(private tractorId: number) {
    super();
    this.tractorId = tractorId;
  }
}
export class CreateTractorAlertQueryParamsRequest extends Payload {
  private tractorId: number;
  private maintenanceTypeId: string;
  private maintenanceType: string;
  private lastConducted: string;
  private nextDue: string;
  private lastServiceDueOdometer: number;
  private nextServiceDueOdometer: number;
  constructor(alert: TractorAlertSummaryDTO) {
    super(true);
    this.tractorId = alert.tractorId;
    this.maintenanceTypeId = alert.maintenanceTypeId;
    this.maintenanceType = alert.maintenanceType;
    this.lastConducted = ParseToDate(alert.lastConducted);
    this.nextDue = ParseToDate(alert.nextDue);
    this.lastServiceDueOdometer = alert.lastServiceDueOdometer;
    this.nextServiceDueOdometer = alert.nextServiceDueOdometer;
  }
}

export class UpdateTractorAlertListRequest extends QueryParams {
  private id: string;
  private tractorId: number;
  private maintenanceTypeId: string;
  private maintenanceType: string;
  private lastConducted: string;
  private nextDue: string;
  private lastServiceDueOdometer: number;
  private nextServiceDueOdometer: number;

  constructor(alert: TractorAlertSummaryDTO) {
    super();
    this.id = alert.id;
    this.lastConducted = ParseToDate(alert.lastConducted);
    this.lastServiceDueOdometer = alert.lastServiceDueOdometer;
    this.maintenanceType = alert.maintenanceType;
    this.maintenanceTypeId = alert.maintenanceTypeId;
    this.nextDue = ParseToDate(alert.nextDue);
    this.nextServiceDueOdometer = alert.nextServiceDueOdometer;
    this.tractorId = alert.tractorId;
  }
}

export class TractorAlertByIdQueryParams extends QueryParams {
  constructor(private id: string) {
    super();
    this.id = id;
  }
}

export class DriverTractorAssignRequest extends QueryParams {
  driverGroupId!: string;
  tractorId?: number;
  constructor(dto: { driverGroupId: string; tractorId?: number }) {
    super();
    this.driverGroupId = dto.driverGroupId;
    this.tractorId = dto.tractorId;
  }
}

export class ChangeTractorStatusRequest extends QueryParams {
  id!: number;
  status!: string;
  constructor(dto: { tractorId: number; status: string }) {
    super(false);
    this.id = dto.tractorId;
    this.status = dto.status;
  }
}

export class MarkTractorAsInactiveRequest extends QueryParams {
  tractorId!: string;
}

export class CarrierSearchListParams extends QueryParams {
  pageNumber?: number;
  pageSize?: number;
  name: string | undefined;
  statusFilter: string | undefined;
  sort: string | undefined;
  constructor(dot) {
    super();
    this.pageNumber = dot.pageNumber;
    this.name = dot.name;
    this.statusFilter = dot.statusFilter;
    this.pageSize = dot.pageSize;
    this.sort = dot.sort;
  }
}
export class BrokeredUnAssignParams extends QueryParams {
  tripId: string;
  isBrokered: boolean;
  constructor(dot) {
    super();
    this.isBrokered = dot.isBrokered;
    this.tripId = dot.tripId;
  }
}
export class assignCarrierToLoadParams extends QueryParams {
  tripId: string;
  carrierId: string;
  tripAssignmentStatus: string;
  constructor(dot) {
    super();
    this.carrierId = dot.carrierId;
    this.tripId = dot.tripId;
    this.tripAssignmentStatus = dot.tripAssignmentStatus;
  }
}
export class OnHoldStateLoadRequest extends QueryParams {
  id: string;
  onHold: boolean;
  addedNote: boolean;
  constructor(data: { id: string; onHold: boolean; addedNote: boolean }) {
    super();
    this.onHold = data.onHold;
    this.id = data.id;
    this.addedNote = data.addedNote;
  }
}
export class OnHoldStateLoadResponse {
  message: string;
}
