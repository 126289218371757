import Box from '@mui/material/Box';
import dashboardLoader from '../../_assets/images/LoadingGif.gif';
import { dashboardLoaderWrapper } from './constants/constant';
import { DashboardLoaderTypes } from './constants/interface';

export const DashboardLoader = ({
  size = '60px',
  cardName = 'other',
}: DashboardLoaderTypes) => (
  <Box
    className="DashboardLoader"
    sx={
      cardName == 'other'
        ? dashboardLoaderWrapper.defaultWrapper
        : dashboardLoaderWrapper.financeWrapper
    }
  >
    <img
      src={dashboardLoader}
      alt="loading..."
      style={{ height: size, alignItems: 'center', justifyContent: 'center' }}
    />
  </Box>
);
