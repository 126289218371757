import { TerminalShort } from '../../common/modelsShort';

export class PaginatedTemplateListResponse {
  content!: [];
  first!: boolean;
  last!: boolean;
  totalElements!: number;
  numberOfElements!: number;
  totalPages!: number;
  size!: number;
}

// @TODO: AXL2-1936 Nvard - will be impl later
export class TemplateDetailsResponse {
  id!: string;
  templateName!: string;
}

export class TemplateSummaryResponse {
  id!: string;
  customerContactId!: string;
  customerId!: number;
  customerName!: string;
  equipmentTypes!: string;
  fromLocationName!: string;
  templateName!: string;
  toLocationName!: string;
  terminal!: TerminalShort | null;
}

export class DeleteTemplateResponse {}
