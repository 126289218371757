import { Box, List, Typography } from '@mui/material';
import { useState } from 'react';
import CustomizedTooltips from '../AxeleTooltip';
import ButtonImproved from '../ButtonImproved';
import IconsComponent from '../IconsComponent';
import {
  BulkactionContainerStyle,
  BulkactionItemStyle,
  BulkactionLabelContainerStyle,
  BulkactionLabelTruncateStyle,
  bulkActionChildItemStyle,
  bulkActionStyle,
  bulkActionTooltipStyle,
  childOptionContainerStyle,
  customeTooltipContainerStyles,
  customeTooltipExpandStyles,
  customeTooltipStyles,
  customeTooltipTextStyles,
} from './styles/grid.style';
import { IDataGridProProps } from './types';

type BulkActionProps = Pick<
  IDataGridProProps,
  'bulkAction' | 'overBulkActionRender'
>;

const BulkAction = ({ bulkAction, overBulkActionRender }: BulkActionProps) => {
  return (
    <Box
      id="BulkActionContainer"
      sx={bulkActionStyle}
      className={'BulkactionContainer-1'}
    >
      {overBulkActionRender?.()}
      {bulkAction?.map(
        (
          item: {
            iconName: string;
            iconSource: string;
            label: string;
            childOptions?: any;
            onClick?: () => void;
            disabled?: boolean;
            iconStyle?: any;
            customeTootTip?: any;
            id?: string;
          },
          key: any
        ) => {
          return (
            <CustomizedTooltips
              key={key}
              placement="top"
              arrow={true}
              isCustomizationTitle={true}
              hide={!item.childOptions && !(item.customeTootTip?.length > 0)}
              backgroundColor={'#1F2E3D'}
              toolTipStyleProps={bulkActionTooltipStyle}
              component={
                <>
                  {item.customeTootTip?.length > 0 && (
                    <Box sx={customeTooltipContainerStyles}>
                      <CustomTooltipContainer item={item?.customeTootTip} />
                    </Box>
                  )}
                  {item?.childOptions && (
                    <Box id="childOptionItem" sx={childOptionContainerStyle}>
                      {item?.childOptions &&
                        item?.childOptions?.map((subItem: any, key: any) => {
                          return (
                            <ButtonImproved
                              key={key}
                              variant="text"
                              disabled={subItem.disabled}
                              onClick={subItem.onChildclick}
                              label={subItem.label}
                              styleProps={bulkActionChildItemStyle}
                            />
                          );
                        })}
                    </Box>
                  )}
                </>
              }
            >
              <Box
                id="BulkactionContainer"
                className={'BulkactionContainer-2'}
                sx={BulkactionContainerStyle}
              >
                <ButtonImproved
                  variant="text"
                  id={item?.id}
                  disabled={item.disabled}
                  bulkaction={true}
                  onClick={item.onClick}
                  styleProps={{
                    ...BulkactionItemStyle,
                    height: 'auto',
                  }}
                  label={
                    <Box id="labelContainer" sx={BulkactionLabelContainerStyle}>
                      <Box id="icon" sx={{ height: 24, width: 24 }}>
                        <IconsComponent
                          iconName={item.iconName}
                          source={item.iconSource}
                          styleProps={{
                            color: item?.disabled
                              ? 'other.gridBulkActionDisabled'
                              : 'rgba(102, 158, 255, 1)',
                            height: 24,
                            width: 24,
                            ...item.iconStyle,
                          }}
                        />
                      </Box>
                      <Box id="text">
                        <Typography
                          sx={{
                            color: item?.disabled
                              ? 'rgba(255, 255, 255, 0.35)'
                              : 'rgba(102, 158, 255, 1)',
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                            fontSize: 12,
                            height: 20,
                            p: '5px',
                            letterSpacing: '0.4px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </Box>
            </CustomizedTooltips>
          );
        }
      )}
    </Box>
  );
};
const CustomTooltipContainer = ({ item }: any): JSX.Element => {
  const [isExpandView, setExpandView] = useState(
    item.length <= 2 ? true : false
  );
  return (
    <>
      {!isExpandView && item.length > 2 && item[0] && (
        <Box sx={customeTooltipStyles}>
          <List sx={BulkactionLabelTruncateStyle}>{item[0]} </List>
          <Box
            sx={{ display: 'inline-flex', pt: '5px' }}
            onClick={() => setExpandView(true)}
          >
            <Typography sx={customeTooltipTextStyles}>
              {' '}
              +{item.length}
            </Typography>
            <IconsComponent
              iconName="KeyboardArrowUpOutlined"
              source="MUIcons"
              styleProps={{
                color: item?.disabled
                  ? 'rgba(255, 255, 255, 0.35)'
                  : 'rgba(102, 158, 255, 1)',
                height: 24,
                width: 24,
                ...item.iconStyle,
              }}
            />
          </Box>
        </Box>
      )}
      {isExpandView && (
        <Box sx={{ width: '100%' }}>
          {!(item.length <= 2) && (
            <Box
              sx={customeTooltipExpandStyles}
              onClick={() => setExpandView(false)}
            >
              {' '}
              <IconsComponent
                iconName="KeyboardArrowDownOutlined"
                source="MUIcons"
                styleProps={{
                  color: item?.disabled
                    ? 'rgba(255, 255, 255, 0.35)'
                    : 'rgba(102, 158, 255, 1)',
                  height: 24,
                  width: 24,
                  ...item.iconStyle,
                }}
              />
            </Box>
          )}
          {item?.map((subItem: any) => (
            <List
              sx={{ pr: item.length <= 2 ? '0px' : '5px', color: '#BDBDBD' }}
              key={subItem}
            >
              {subItem}
            </List>
          ))}
        </Box>
      )}
    </>
  );
};

export default BulkAction;
