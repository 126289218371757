import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import {
  CardHeader,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ELoadStatus } from '../../../../../common/LoadTabPanel/constants/constants';
import ActionsSection from '../../../../../common/LoadTabPanel/hoc/actionsSection';
import { LoadStatus } from '../../../../../common/Ui/StatusComponent/StatusComponent';
import { useDispatchPermission } from '../../../../../common/hooks/useDispatchPermission';
import { LoadDetailsResponse } from '../../../../../models';
import {
  DispatchDriverTimelineTripStop,
  IDispatchDriverTimelineTrip,
} from '../../../../../models/DTOs/Dispatch/Timeline';
import { WarningLoadListDelayIcon } from '../../../../../ui-kit/components/Assets';
import { numberToMiles } from '../../../../../utils/formater.utils';
import TimeoffDateTime from '../../../../../utils/timeoffDateTime';
import { debounceTime } from '../../../constants/dispatch';
import {
  ESecondaryDetailsPanelType,
  SecondaryDetailsOpenProps,
} from '../../../constants/types';
import {
  StyledCardTripRoot,
  StyledSectionDelay,
  StyledSectionDelayTitle,
  StyledSectionETA,
  StyledSectionHeader,
  StyledSectionItem,
  StyledSectionKPIValue,
  StyledSectionLoadIds,
} from '../styles';

export interface GanttCardTripProps extends Partial<SecondaryDetailsOpenProps> {
  trip: IDispatchDriverTimelineTrip;
  isReOrderActive?: boolean;

  onAfterUpdateTripAction?(action: ELoadStatus): void;
}

export const GanttCardTrip = ({
  trip,
  handleSecondaryDetailsOpen,
  isReOrderActive = false,
  onAfterUpdateTripAction,
}: GanttCardTripProps): JSX.Element => {
  const { hasDispatchEditPermission } = useDispatchPermission();
  const theme: any = useTheme();

  const onCreatedLoadHandler = async (load: LoadDetailsResponse) => {
    if (load instanceof LoadDetailsResponse) {
      handleSecondaryDetailsOpen?.(load.id, ESecondaryDetailsPanelType.LOAD, {
        isGlobal: true,
      });
    }
  };

  const debouncedOnClickTripIdHandler = useCallback(
    debounce((tripId) => {
      if (tripId) {
        handleSecondaryDetailsOpen?.(tripId, ESecondaryDetailsPanelType.TRIP, {
          isGlobal: true,
        });
      }
    }, debounceTime),
    []
  );
  const { t, ready } = useTranslation();
  const entity = 'Trip';

  if (ready) {
    return (
      <StyledCardTripRoot>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          width={'100%'}
        >
          <CardHeader
            title={
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                component={'div'}
              >
                <Typography
                  variant="h6"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    debouncedOnClickTripIdHandler(trip.id);
                  }}
                >
                  {t('GanttCardTripTripID')}:{' '}
                  <Box
                    component={'span'}
                    sx={{
                      textDecoration: 'underline',
                      color: 'primary.main',
                    }}
                  >
                    {trip.seqNumber}
                  </Box>
                </Typography>
                <LoadStatus status={trip.status} onHold={trip?.onHold} />
              </Stack>
            }
            sx={{ width: '100%', paddingBottom: 0 }}
            action={
              isReOrderActive ? (
                <MenuOutlinedIcon />
              ) : (
                hasDispatchEditPermission && (
                  <ActionsSection
                    tripData={trip}
                    entity={entity}
                    isDetailsPanel={false}
                    onCreatedLoadHandler={onCreatedLoadHandler}
                    onStatusAction={onAfterUpdateTripAction}
                    skipOpenDetailsPanel={true}
                  />
                )
              )
            }
          />
        </Stack>
        {trip?.stops?.map(
          (stop: DispatchDriverTimelineTripStop, idx: number) => {
            return (
              <StyledSectionItem key={stop.id}>
                <StyledSectionHeader>
                  {[stop.location?.city, stop.location?.state]
                    .filter((str) => !!str)
                    .join(', ')}
                </StyledSectionHeader>
                <StyledSectionETA>
                  {idx === trip.stops.length - 1 ? 'ETD' : 'ETA'}:{' '}
                  {TimeoffDateTime.getDateTimeDisplayTooltip({
                    dateTime:
                      idx === 0
                        ? trip.startTime
                        : idx === trip?.stops.length - 1
                        ? trip.endTime
                        : stop.appointmentStartDate,
                    timezone: stop?.location?.timezone,
                  })}
                </StyledSectionETA>
              </StyledSectionItem>
            );
          }
        )}
        <Divider variant="middle" sx={{ marginTop: '16px' }} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          width={'100%'}
        >
          <CardHeader
            title={
              <StyledSectionETA whiteSpace={'nowrap'}>
                {t('GanttCardTripDriveTime')}
              </StyledSectionETA>
            }
            subheader={
              <StyledSectionKPIValue>
                {TimeoffDateTime.minutesToDurationFormat(trip?.driveTimeMins)}
              </StyledSectionKPIValue>
            }
          />
          <CardHeader
            title={
              <StyledSectionETA>{t('GanttCardTripTripMiles')}</StyledSectionETA>
            }
            subheader={
              <StyledSectionKPIValue>
                {numberToMiles(trip?.loadedMiles)}
              </StyledSectionKPIValue>
            }
          />
          <CardHeader
            title={
              <StyledSectionETA>
                {t('GanttCardTripEmptyMiles')}
              </StyledSectionETA>
            }
            subheader={
              <StyledSectionKPIValue>
                {numberToMiles(trip?.emptyMiles)}
              </StyledSectionKPIValue>
            }
          />
        </Stack>
        {trip?.loadDetails?.length && trip?.loadDetails?.length > 0 ? (
          <StyledSectionItem
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: 0,
              paddingBottom: '8px',
            }}
          >
            <StyledSectionETA>{t('AssociatedLoads')}: </StyledSectionETA>
            <Box sx={{ paddingLeft: '10px' }}>
              {trip?.loadDetails?.map((item, idx) => (
                <StyledSectionLoadIds
                  key={item.loadId}
                  component={'span'}
                  sx={{ cursor: 'pointer', paddingLeft: '2px' }}
                  onClick={() => {
                    handleSecondaryDetailsOpen?.(
                      item.loadId,
                      ESecondaryDetailsPanelType.LOAD
                    );
                  }}
                >
                  {item.seqNumber}
                  {trip.loadDetails.length > 1 &&
                  idx < trip.loadDetails.length - 1
                    ? ','
                    : ''}
                </StyledSectionLoadIds>
              ))}
            </Box>
          </StyledSectionItem>
        ) : (
          <></>
        )}

        {!isReOrderActive && trip.violations && trip.violations > 0 ? (
          <StyledSectionDelay>
            <CardHeader
              avatar={
                <WarningLoadListDelayIcon color={theme.palette.error.dark} />
              }
              title={
                <StyledSectionDelayTitle>
                  {t('GanttCardTripDelay')}: {trip.violations}{' '}
                  {t('GanttCardTripStop')}
                </StyledSectionDelayTitle>
              }
            />
          </StyledSectionDelay>
        ) : (
          <></>
        )}
      </StyledCardTripRoot>
    );
  }
};
