import { SxProps, Theme } from '@mui/material';

export function getAllFiltersStyles({
  theme,
  isFilterActive,
}: {
  theme: Theme;
  isFilterActive: boolean;
}): {
  [key: string]: SxProps;
} {
  return {
    AllFilterBoxRoot: {
      display: 'flex',
      backgroundColor: 'uncategorized.popup',
      flexDirection: 'column',
      borderRadius: '16px 16px 0 0',
      [theme?.breakpoints?.down('tablet')]: {
        width: '100%',
        backgroundColor: 'primary.contrast',
        borderRadius: 0,
      },
    },
    AllFilterBoxContainer: {
      [theme?.breakpoints?.down('tablet')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        pt: 0,
      },
    },
    AllFilterBoxContainerLeft: {
      display: 'flex',
      justifyContent: 'center',
      height: 44,
      color: 'primary.contrast',
      alignItems: 'center',
      [theme?.breakpoints?.down('tablet')]: {
        pl: 2,
      },
    },
    AllFilterBoxContainerRight: {
      display: 'none',
      pr: 2,
      [theme?.breakpoints?.down('tablet')]: {
        display: 'flex',
      },
    },
    AllFilterContent: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme?.palette?.uncategorized?.card,
      borderRadius: '16px',
      pt: 2,
      overflow: 'auto',
      [theme?.breakpoints?.down('tablet')]: {
        pt: 0,
      },
    },
    AllFiltersButtonRoot: {
      borderRadius: '6px',
      height: '40px',
      borderColor: 'primary.main',
      backgroundColor: isFilterActive ? 'primary.main' : undefined,
      color: isFilterActive ? 'primary.contrast' : undefined,
      ': hover': {
        backgroundColor: isFilterActive ? 'primary.main' : undefined,
      },
    },
    AllFilterPopover: {
      '& .MuiPaper-root': {
        overflow: 'hidden',
        minWidth: 300,
        borderRadius: '16px',
        [theme?.breakpoints?.down('tablet')]: {
          borderRadius: 0,
          minWidth: '100%',
          minHeight: '100%',
          top: `0 !important`,
          left: `0 !important`,
        },
      },
    },
    FilterListOutlinedIcon: {
      width: 22,
      height: 22,
      color: isFilterActive ? 'primary.contrast' : 'primary.main',
      // [theme?.breakpoints?.down('tablet')]: {
      //   color: 'primary.main',
      // },
    },
    FilterListOutlinedIconPopover: {
      width: 22,
      height: 22,
      [theme?.breakpoints?.down('tablet')]: {
        color: 'primary.main',
      },
    },
    submitButton: {
      borderRadius: '10px',
      height: '36px',
      pl: 3,
      pr: 3,
      [theme?.breakpoints?.down('tablet')]: {
        mt: 2,
        mb: 2,
      },
    },
    resetButton: {
      height: '36px',
      pl: 3,
      pr: 3,
      borderColor: 'primary.main',
      [theme?.breakpoints?.down('tablet')]: {
        mb: 2,
      },
    },
  };
}
