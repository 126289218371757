import { InvoicePay } from '../../../../models/DTOs/Payment/DTOs';
import { ITabV2 } from '../../../../ui-kit/components/TabsV2';
import { IAutocompleteOption } from '../../../trips/constants/types';

export enum EPaymentMethod {
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  OTHER = 'OTHER',
  CHECK = 'CHECK',
}

export enum EPaymentStatus {
  PAID = 'PAID',
  CLOSED = 'CLOSED',
}

export enum EPayment3DotMenuType {
  DELETE = 'DELETE',
}

export enum EPaymentPanelTab {
  OVERVIEW = 'OVERVIEW',
}

export interface IPaymentDataGridFilters {
  paymentIds: IAutocompleteOption[];
  paymentSeqNumberList: IAutocompleteOption[];
  paymentStatusList: IAutocompleteOption[] | null;
  paymentMethodList: IAutocompleteOption[];
  invoiceSeqNumberList: IAutocompleteOption[];
  referenceIds: IAutocompleteOption[];
  customerNameFilter: IAutocompleteOption[];
  paymentFromDate: Date | null;
  paymentToDate: Date | null;
  remainCredit: boolean;
  paymentDateFilter: Date | null;
  assignedDocuments: any;
}

export interface ICreatePaymentForm {
  assignedDocuments: any;
  id?: string;
  paymentDate: Date | null;
  paymentMethod: IAutocompleteOption | null;
  paymentReferenceId: string | null;
  amountReceived: string | null;
  memo: string | null;
  customerId: IAutocompleteOption | null;
  isAdvanced: boolean | null;
  invoicePayDTO: InvoicePay[];
}

export interface IPaymentPanelTab extends ITabV2 {
  key: EPaymentPanelTab;
}
