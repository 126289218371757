import { LOADAI_ELEMENTS } from '../../../constants/elementIds';
import { IDispatchOption } from '../../../types/DispatchTypes';
import {
  DVIRPreferenceOptions,
  PrefferedTruckStopsOptions,
  WorkWeekTypeOptions,
} from '../../settings/preferences/constants';
import { IDispatchPreferencesFormData } from './types';
import { t } from 'i18next';
import { Label } from '../../../locales/en/dispatch/label';

export const getWorkWeekType = (): IDispatchOption[] => {
  return WorkWeekTypeOptions.map((option) => ({
    value: option.label,
    key: option.key,
  }));
};

export const getDvirPreferences = (): IDispatchOption[] => {
  return DVIRPreferenceOptions.map((option) => ({
    value: option.label,
    key: option.key,
  }));
};

export const getPreferredTruckstopsType = (): IDispatchOption[] => {
  return PrefferedTruckStopsOptions.map((option) => ({
    value: option.label,
    key: option.key,
  }));
};

export const defaultDispatchPreferencesFormData: IDispatchPreferencesFormData =
  {
    useSleeperBerthProvision: false,
    ignoreHOSRestrictions: false,
    dvirPreferences: null,
    workWeekType: null,
    preferredTruckstops: [],
  };

export enum PreferenceOptionsEnum {
  RESUME = 'resume',
  CONFIGURE = 'configure',
  OPTIMIZE = 'optimize',
}

export const PreferenceButtonOptions = [
  {
    id: PreferenceOptionsEnum.OPTIMIZE,
    label: 'Start New Optymization',
    elId: LOADAI_ELEMENTS.NEW_OPTIMIZATION,
  },
  {
    id: PreferenceOptionsEnum.CONFIGURE,
    label: 'Configure Parameters',
    elId: LOADAI_ELEMENTS.DRIVER_PREFERENCES,
  },
];

export const ResumePreferenceOption = {
  id: PreferenceOptionsEnum.RESUME,
  label: 'Resume Previous Optymization',
  elId: LOADAI_ELEMENTS.RESUME_OPTIMIZATION,
};
