import React from 'react';
import { Permission } from './Permission';
import { AXELE_PERMISSION_TYPE } from '../types/AxelePermission';

export default function PermissionWrapper({
  permissions,
  children,
}: {
  permissions?: AXELE_PERMISSION_TYPE[] | undefined;
  children: JSX.Element;
}) {
  if (permissions) {
    return <Permission includes={permissions}>{children}</Permission>;
  }
  return children;
}
