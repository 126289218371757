import { SingleSelectFilter } from '../../../common/Ui/SingleSelect';
import { SortIconFilter } from '../../../common/Ui/SortIcon/SortIconFilter';
import {
  IAutocompleteOption,
  IRecommendedTripFilters,
  IRecommendedTripStatusesFilters,
} from '../types/recommendTrips.types';
import { RECOMMEND_SORT_ENUMS } from '../../../constants/gantt/gantt.const';
import { GridButtonGroupFilter } from '../components/generic/GridButtonGroupFilter';

export enum ERecommendedTripStatus {
  Assigned = 'Assigned',
  UnassignedDrivers = 'UnassignedDrivers',
  UnassignedTrips = 'UnassignedTrips',
  brokeredTrips = 'brokeredTrips',
}

const defaultAutocompleteConfig = {
  fieldName: 'value',
  fieldValue: 'key',
  immutableModel: true,
};

export const recommendadtionSortFilters = [
  {
    value: 'Name',
    key: RECOMMEND_SORT_ENUMS.groupName,
  },
  {
    value: `Driver's Availability`,
    key: RECOMMEND_SORT_ENUMS.driverAvailability,
  },
  {
    value: 'Time to Pickup',
    key: RECOMMEND_SORT_ENUMS.timeToPickup,
  },
  {
    value: '$/mi',
    key: RECOMMEND_SORT_ENUMS.revenuePerMile,
  },
  { value: '$/hr', key: RECOMMEND_SORT_ENUMS.revenuePerHour },
  { value: 'Deadhead Miles', key: RECOMMEND_SORT_ENUMS.deadheadMiles },
  { value: 'Delays', key: RECOMMEND_SORT_ENUMS.delays },
];

export const RecommendedTripSortFilters = [
  {
    default: true,
    label: 'Sort',
    labelId: 'SortId',
    name: 'sort',
    options: recommendadtionSortFilters,
    type: 'Custom',
    customComponent: SingleSelectFilter,
    ...defaultAutocompleteConfig,
    customStyles: { '.MuiInputBase-root': { height: '28px' } },
    renderValueSxProps: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '13px',
    },
  },
  {
    name: 'sortDirection',
    type: 'Custom',
    default: true,
    customComponent: SortIconFilter,
  },
];
const getDisplayRecommendedTripStatus = (type: ERecommendedTripStatus) => {
  switch (type) {
    case ERecommendedTripStatus.Assigned:
      return 'Assigned';
    case ERecommendedTripStatus.UnassignedDrivers:
      return 'Unassigned Drivers';
    case ERecommendedTripStatus.UnassignedTrips:
      return 'Unassigned Trips';
    case ERecommendedTripStatus.brokeredTrips:
      return 'Brokered Trips';
    default:
      throw Error('Invalid type');
  }
};
const renderOption = (
  total: number,
  type: ERecommendedTripStatus
): IAutocompleteOption => {
  return {
    value: type,
    label: (
      <>
        {total >= 0 && <b style={{ paddingRight: '8px' }}>{total}</b>}
        {getDisplayRecommendedTripStatus(type)}
      </>
    ),
  };
};

export const getRecommendedTripStatusesFilters = ({
  assignedDrivers = 0,
  unAssignedDrivers = 0,
  unAssignedTrips = 0,
  brokeredTrips = 0,
}: IRecommendedTripStatusesFilters) => {
  return [
    {
      default: true,
      label: '',
      name: 'statuses',
      type: 'Custom',
      options: [
        renderOption(assignedDrivers, ERecommendedTripStatus.Assigned),
        renderOption(brokeredTrips, ERecommendedTripStatus.brokeredTrips),
        renderOption(
          unAssignedDrivers,
          ERecommendedTripStatus.UnassignedDrivers
        ),
        renderOption(unAssignedTrips, ERecommendedTripStatus.UnassignedTrips),
      ],
      customComponent: GridButtonGroupFilter,
    },
  ];
};

export const defaultRecommendedTripFilters: IRecommendedTripFilters = {
  sort: {
    key: RECOMMEND_SORT_ENUMS.groupName,
    value: `Name`,
  },
  sortDirection: true,
  statuses: [
    renderOption(0, ERecommendedTripStatus.Assigned),
    renderOption(0, ERecommendedTripStatus.UnassignedDrivers),
    // renderOption(0, ERecommendedTripStatus.UnassignedTrips),
  ],
};

export const RECOMMEND_ONLY_TRIPS_FILTER_STATUS = [
  renderOption(0, ERecommendedTripStatus.Assigned),
  renderOption(0, ERecommendedTripStatus.UnassignedTrips),
];
