import { CloseOutlined, KeyboardArrowDownOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  CardHeader,
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import {
  forwardRef,
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import dashboardLoader from '../../../_assets/static/images/favicon.svg';

import Link from '@mui/material/Link';
import AuthContext from '../../../globalContexts/AuthContext';
import { AxeleLogo, SelectedIcon } from '../../../ui-kit/components/Assets';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import { getAccountTypeWithRoleCode } from '../../../utils';
import { getAvatarStringByName } from '../../../utils/avatarName';
import { DirtyDetailsPanelManager } from '../../DetailsPanel/utils';
import {
  BREAKPOINT_TABLET,
  useThemeResponsive,
} from '../../hooks/useThemeResponsive';
import {
  AXELE_PERMISSION_TYPE,
  AXELE_ROLE_TYPE,
  AXELE_WEB_FEATURE_TYPE,
  Permission,
} from '../../Permission';
import { leftMenuContants, subMenuContants } from './constants';
import { leftMenuStyles, StyledLeftMenuDivider } from './styles';

export class IItemDTOPermission {
  permission?: AXELE_PERMISSION_TYPE[];
  permit?: AXELE_WEB_FEATURE_TYPE[] | undefined;
  roleCodes?: AXELE_ROLE_TYPE[] | undefined;
}

export class selectedItemDTO extends IItemDTOPermission {
  id?: string;
  icon?: any;
  iconName?: string;
  text?: string;
  hasChildren?: boolean;
  redirectionPath!: string;
  fullheight?: boolean;
}

export class selectedSubItemDTO extends IItemDTOPermission {
  id?: string;
  iconName?: string;
  parent?: string;
  text?: string;
  redirectionPath!: string;
  fullheight?: boolean;
}

export class LeftMenuDTO {
  handleClick!: (redirectionURL: string) => void;
  mainMenu!: string;
  subMenu!: string;
  getIsTerminalEnabled!: boolean;
  menuTerminalRender!: () => ReactNode;
  onClose!: (event: MouseEvent) => void;
  open!: boolean;
  userStorage!: any;
}

const LeftMenu = ({
  handleClick,
  mainMenu,
  subMenu,
  getIsTerminalEnabled,
  menuTerminalRender,
  open = false,
  onClose,
  userStorage,
}: LeftMenuDTO) => {
  const [selectedItem, setSelectedItem] = useState<any>(leftMenuContants[0]);
  const [subMenuItems, setSubMenuItems] = useState<any>([]);
  const [selectedSubItem, setSelectedSubItem] = useState<any>([]);
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
  const [clickedItem, setClickedItem] = useState<string | undefined>(
    leftMenuContants[0].text
  );
  const { logoutUser } = useContext(AuthContext);
  const theme = useTheme();
  const { isMobile } = useThemeResponsive();
  const init = useCallback((): void => {
    const item: selectedItemDTO | undefined = getselectedMenuItem(mainMenu);
    setSelectedItem(item);
    if (isMobile && item?.hasChildren) {
      setClickedItem(item.text);
      setSubMenuItems(subMenuContants[item.id as keyof typeof subMenuContants]);
      setCollapseOpen(true);
    }
    if (subMenu && subMenu.length > 0) {
      setSelectedSubItem(getselectedSubMenuItem(mainMenu, subMenu));
    }
  }, [isMobile, mainMenu, subMenu]);

  useEffect(() => {
    const noPermissionForViewSubItems = getAccountTypeWithRoleCode([
      'NFD',
      'DR',
    ]);
    const filtredSubItems = subMenuContants?.finance?.filter(
      (item) =>
        !(
          noPermissionForViewSubItems &&
          ['summary', 'settlement', 'invoices'].includes(item.id!)
        )
    );
    if (filtredSubItems) {
      subMenuContants.finance = filtredSubItems;
    }
  }, [init]);

  useEffect(() => {
    init();
  }, [init, mainMenu, subMenu]);

  const getselectedMenuItem = (menuId: string) => {
    if (menuId.length > 0) {
      return leftMenuContants.find((item) => item.id === menuId);
    } else {
      return leftMenuContants[0];
    }
  };

  const getselectedSubMenuItem = (mainMenu: string, subMenuId: string) => {
    return subMenuContants[mainMenu as keyof typeof subMenuContants].find(
      (subItem) => subItem.id === subMenuId
    );
  };

  const handleMainMenuClick = (e: MouseEvent, item: selectedItemDTO): void => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;

    if (item.id === selectedItem?.id && collapseOpen) {
      setCollapseOpen(false);
      return;
    }
    setSelectedItem(item);
    if (item.hasChildren) {
      setClickedItem(item.text);
      setSubMenuItems(subMenuContants[item.id as keyof typeof subMenuContants]);
      setCollapseOpen(true);
    } else {
      setSelectedItem(item);
      handleClick(item.redirectionPath);
      setSelectedSubItem([]);
      setCollapseOpen(false);
      onClose(e);
    }
  };

  const handleSubMenuClick = (e: MouseEvent, subItem: selectedSubItemDTO) => {
    e.preventDefault();
    setSelectedItem(
      leftMenuContants.find((item) => item.id === subItem.parent)
    );
    setSelectedSubItem(subItem);
    handleClick(subItem.redirectionPath);
    setCollapseOpen(false);
    onClose(e);
  };

  const handleCollapseClose = (e: globalThis.MouseEvent | TouchEvent) => {
    if (collapseOpen) {
      e.preventDefault();
      setSelectedItem(getselectedMenuItem(mainMenu));
      setCollapseOpen(false);
    }
  };

  const leftMenuStylesWithRS = leftMenuStyles(theme);

  const LeftMenuCollapse = forwardRef((props, ref) => {
    return (
      <Box
        id="LeftMenuCollapse"
        ref={ref}
        {...props}
        sx={{
          ...leftMenuStylesWithRS.leftMenuCollapseRoot,
        }}
      >
        <Box sx={leftMenuStylesWithRS.leftMenuCollapseContainer}>
          <Stack id="LeftMenuCollapseContent">
            <Grid
              container
              id="CollapseHeader"
              sx={{
                flexDirection: 'row',
                p: '16px',
                mb: '8px',
                ...leftMenuStylesWithRS.notForMobile,
              }}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Grid item id="HeaderText">
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 400,
                    color: theme.palette.leftMenuExpanded.active,
                    fontFamily: 'Poppins',
                    lineHeight: '36px',
                  }}
                >
                  {clickedItem}
                </Typography>
              </Grid>
              <Grid
                item
                id="closeIcon"
                alignItems={'center'}
                sx={{ mt: '10px', cursor: 'pointer' }}
                onClick={(e: any) => {
                  handleCollapseClose(e);
                }}
              >
                <CloseIcon
                  style={{
                    color: theme.palette.leftMenuExpanded.active,
                  }}
                />
              </Grid>
            </Grid>
            {subMenuItems.map((subItem: selectedSubItemDTO) => {
              return (
                <Permission
                  key={subItem.id}
                  contains={subItem.permission}
                  containsPermit={subItem.permit}
                  roleCodes={subItem.roleCodes}
                >
                  <Link
                    sx={{
                      textDecoration: 'none',
                    }}
                    href={subItem.redirectionPath}
                    id="LeftSubMenu"
                    key={subItem.id}
                    onClick={(e: MouseEvent) => {
                      handleSubMenuClick(e, subItem);
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        textDecoration: 'none',
                        backgroundColor:
                          selectedSubItem.id === subItem.id
                            ? 'leftMenuExpanded.selectedTabPanelTab'
                            : '',
                        ...leftMenuStylesWithRS.leftMenuCollapseSubMenuItem,
                      }}
                    >
                      <Stack alignItems={'center'} spacing={3} direction="row">
                        <Stack id="SubMenuText">
                          <Typography
                            sx={{
                              color:
                                selectedSubItem.id === subItem.id
                                  ? 'leftMenuExpanded.active'
                                  : 'leftMenuExpanded.inactive',
                              ...leftMenuStylesWithRS.leftMenuCollapseSubMenuItemName,
                            }}
                          >
                            {subItem.text}
                          </Typography>
                        </Stack>
                        {selectedSubItem.id === subItem.id && (
                          <Stack
                            id="selectedDot"
                            sx={
                              leftMenuStylesWithRS.leftMenuCollapseSubMenuItemSelectedDot
                            }
                          >
                            {SelectedIcon}
                          </Stack>
                        )}
                      </Stack>
                    </Box>
                  </Link>

                  <StyledLeftMenuDivider className="StyledLeftMenuDivider" />
                </Permission>
              );
            })}
          </Stack>
        </Box>
      </Box>
    );
  });
  LeftMenuCollapse.displayName = 'LeftMenuCollapse';

  return (
    <ClickAwayListener
      onClickAway={(e: globalThis.MouseEvent | TouchEvent) => {
        if (isMobile) {
          setCollapseOpen(true);
        } else {
          handleCollapseClose(e);
        }
      }}
    >
      <Box
        id="LeftMenuComponent"
        sx={{
          ...leftMenuStylesWithRS.root,
          [theme.breakpoints.down('tablet')]: {
            ...(open
              ? {
                  width: '100%',
                }
              : {
                  display: 'none',
                }),
          },
        }}
      >
        <Box id="LeftFixedMenu" sx={leftMenuStylesWithRS.leftFixedMenu}>
          <Box id="MenuItemsLogo" sx={leftMenuStylesWithRS.menuItemsLogo}>
            <img
              style={{ display: 'visible', width: '100%' }}
              src={dashboardLoader}
              alt="logo"
            />
          </Box>
          <Stack
            id="navigationItems"
            sx={{
              ...leftMenuStylesWithRS.navigationItems,
              ...(isMobile &&
                collapseOpen && {
                  height: '130vh !important',
                }),
            }}
          >
            <Stack
              sx={{
                ...leftMenuStylesWithRS.leftMenuTop,
                ...(!getIsTerminalEnabled && {
                  mb: -3,
                  justifyContent: 'flex-end',
                }),
              }}
            >
              <Stack sx={leftMenuStylesWithRS.leftMenuTerminal}>
                {getIsTerminalEnabled ? menuTerminalRender() : <></>}
              </Stack>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseOutlined
                  sx={{
                    color: 'primary.contrast',
                  }}
                />
              </IconButton>
            </Stack>

            {leftMenuContants.map((item: selectedItemDTO) => {
              const ItemIcon = item.icon;
              return (
                <Permission
                  key={item.id}
                  contains={item.permission}
                  containsPermit={item.permit}
                  roleCodes={item.roleCodes}
                >
                  <Box sx={leftMenuStylesWithRS.menuListItemContainer}>
                    <Link
                      component={item.hasChildren ? 'div' : 'a'}
                      href={item.hasChildren ? '' : item.redirectionPath}
                      key={item.id}
                      id={`MenuItemsLogo-${item.id}`}
                      onClick={(e: MouseEvent) => {
                        handleMainMenuClick(e, item);
                      }}
                    >
                      <Stack
                        id={item.id}
                        sx={{
                          ...leftMenuStylesWithRS.menuListItem,
                          [theme.breakpoints.up(BREAKPOINT_TABLET)]: {
                            background:
                              selectedItem?.id === item.id
                                ? theme.palette.leftMenuExpanded
                                    .primaryActiveNormalGradient
                                : '',
                            borderRight:
                              selectedItem?.id === item.id
                                ? `4px solid ${theme.palette.leftMenuExpanded.active}`
                                : '',
                          },
                        }}
                      >
                        <Stack id="svgIcon">
                          <ItemIcon
                            sx={{
                              color:
                                selectedItem?.id === item.id
                                  ? 'leftMenuExpanded.active'
                                  : 'leftMenuExpanded.inactive',
                              [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
                                color: 'primary.contrast',
                              },
                            }}
                          />
                        </Stack>
                        <Stack id="SectionName">
                          <Typography
                            sx={{
                              color:
                                selectedItem?.id === item.id
                                  ? 'leftMenuExpanded.active'
                                  : 'leftMenuExpanded.inactive',
                              ...leftMenuStylesWithRS.menuListItemName,
                            }}
                          >
                            {item.text?.toLowerCase()}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack sx={leftMenuStylesWithRS.menuListItemCollapseIcon}>
                        {item.hasChildren && (
                          <KeyboardArrowDownOutlined
                            sx={{
                              color: 'primary.contrast',
                              cursor: 'pointer',
                              ...(selectedItem?.id === item.id && {
                                transform: 'rotate(-180deg)',
                              }),
                            }}
                          />
                        )}
                      </Stack>
                    </Link>
                  </Box>

                  <StyledLeftMenuDivider className="StyledLeftMenuDivider" />

                  <Stack sx={leftMenuStylesWithRS.menuListItemCollapseMenuList}>
                    <Collapse
                      in={
                        item.hasChildren &&
                        selectedItem?.id === item.id &&
                        collapseOpen
                      }
                      mountOnEnter
                      unmountOnExit
                    >
                      <LeftMenuCollapse />
                    </Collapse>
                  </Stack>
                </Permission>
              );
            })}

            <CardHeader
              sx={{
                [theme.breakpoints.up('tablet')]: {
                  display: 'none',
                },
              }}
              avatar={
                <Avatar>
                  {getAvatarStringByName(
                    [userStorage?.firstname, userStorage?.lastname].join(' ')
                  )}
                </Avatar>
              }
              title={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography sx={{ color: 'primary.contrast' }}>
                    {`Hi ${userStorage?.firstname}`}
                  </Typography>
                  {isMobile && (
                    <ButtonImproved
                      styleProps={{
                        ml: 1,
                      }}
                      size="small"
                      label="Logout"
                      variant="contained"
                      onClick={logoutUser}
                    />
                  )}
                </Box>
              }
            />
          </Stack>
        </Box>
        <Box
          sx={{
            width: '50%',
            [theme.breakpoints.down('tablet')]: {
              display: 'none',
            },
          }}
        >
          <Collapse
            in={collapseOpen}
            style={{
              zIndex: 1200,
            }}
            orientation="horizontal"
            mountOnEnter
            unmountOnExit
          >
            <LeftMenuCollapse />
          </Collapse>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default LeftMenu;
