import React, { lazy, Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';

import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import { EmailFormType } from '../../../../../subPages/invoices/models/InvoiceModel';
import TextField from '../../../../Ui/TextField/TextField';
import Checkbox from '../../../../Ui/Checkbox';
import FormDialog from '../../../../Ui/FormDialog';
import EmailReceivers from '../../../../Email/EmailReceivers';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { t } from 'i18next';
const Wisiwig = lazy(() => import('../../../../../ui-kit/components/Wisiwig'));

export function InvoiceSendEmailForm({
  invoice,
  seqNumber,
}: {
  invoice: string;
  seqNumber: string;
}) {
  const { control } = useFormContext();
  return (
    <>
      <EmailReceivers />
      <Box>
        <TextField control={control} name="subject" label="Subject" />
      </Box>
      <Box sx={{ mb: 4 }}>
        <Suspense fallback={<></>}>
          <Wisiwig
            customStyles={{
              contentBackground: {
                backgroundColor: 'transparent',
              },
              headerBackgroundColor: {
                backgroundColor: 'transparent',
              },
            }}
            control={control}
            name="message"
          />
        </Suspense>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant={'subtitle2'}>Attachments:</Typography>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'rateConfirmation'}
          label={`${invoice}_Rate Confirmation_${seqNumber}.pdf`}
          disabled={true}
        />
      </Box>
    </>
  );
}

export function FinanceLoadEmail({
  open,
  onAction,
  onClose,
  invoice,
  customerEmail,
  seqNumber,
  emailName,
}: {
  open: boolean;
  onAction: (data: EmailFormType) => void;
  onClose: () => void;
  invoice: string;
  seqNumber: string;
  customerEmail: string;
  emailName: string;
}) {
  const user = StorageManager.getUser();
  const to = customerEmail;
  const organizationName = user.organizationName;
  return (
    <FormDialog
      data={{
        to: to ? [to] : [],
        cc: [],
        subject: getInvoiceEmailDefaultSubjectCustome(
          invoice,
          organizationName,
          seqNumber
        ),
        message: getInvoiceEmailDefaultMessageCustome(
          invoice,
          organizationName,
          seqNumber,
          emailName
        ),
        invoice: true,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
        markAsInvoiced: true,
        documentIds: [],
        invoiceId: invoice,
      }}
      validationSchema={yup.object().shape({
        to: yup.array().of(yup.string()).nullable().min(1, 'To is required'),
      })}
      titleText="Confirmation Email"
      actionLabel="Email"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => (
        <InvoiceSendEmailForm invoice={invoice} seqNumber={seqNumber} />
      )}
    />
  );
}

export function MultipleSendEmailDialog({
  open,
  onAction,
  onClose,
}: {
  open: boolean;
  onAction: (data: EmailFormType) => void;
  onClose: () => void;
}) {
  return (
    <FormDialog
      data={{
        cc: [],
        invoice: true,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
      }}
      titleText={t('emailInvoices')}
      actionLabel="Email"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <MultipleSendEmailForm />}
    />
  );
}

export function MultipleSendEmailForm() {
  const { control } = useFormContext();
  return (
    <>
      <EmailReceivers hideTo />
      <Box sx={{ mb: 4 }}>
        <Typography variant={'subtitle2'}>Attachments:</Typography>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'rateConfirmation'}
          label={t('rateConfirmation')}
        />
      </Box>
    </>
  );
}

export const getInvoiceEmailDefaultMessageCustome = (
  invoice: string,
  organizationName: string,
  seqNumber: string,
  emailName: string
) => {
  return `<p> Dear ${emailName} </p><br /></p>Please find attached the Rate Confirmation of <b> Load ${seqNumber} </b>  for processing your requirements: <br /> <br /> <b>${invoice}_Rate Confirmation_${seqNumber}.pdf </b></p><br /></p><br />Regards, <br /> <b>${organizationName} </b></p>`;
};

export const getInvoiceEmailDefaultSubjectCustome = (
  invoice: string,
  organizationName: string,
  seqNumber: string
) => {
  return ` ${invoice} Rate Confirmation for Load ${seqNumber}  from ${organizationName} `;
};
