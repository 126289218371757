import { ThemeOptions } from '@mui/material';

export const MuiFormLabel: ThemeOptions['components'] = {
  MuiFormLabel: {
    styleOverrides: {
      asterisk: ({ theme }) =>
        theme.unstable_sx({
          color: 'error.main',
        }),
      error: ({ theme }) =>
        theme.unstable_sx({
          color: 'error.main',
        }),
      focused: ({ theme }) =>
        theme.unstable_sx({
          color: 'primary.main',
        }),
      disabled: ({ theme }) =>
        theme.unstable_sx({
          color: 'text.disabled',
        }),
    },
  },
};
