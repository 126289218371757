import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
  style?: SxProps;
}

const PageContainer: React.FC<IProps> = ({ children, style }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default PageContainer;
