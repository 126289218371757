export const GanttChartStyles = {
  flexGrow: 1,
  height: 'calc(100% - 40px)',
};

export const DriverColumnStyle = {
  width: '100%',
  textAlign: 'center',
};

export const FilterContainerDisabledStyles = {
  opacity: '0.5',
  pointerEvents: 'none',
};
