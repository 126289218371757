import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import React, { FC } from 'react';
import CustomizedTooltips from '../../../../ui-kit/components/AxeleTooltip';
import { ILoadsearchTableItem } from '../../loadTypes';
import { loadboardsMap } from './logoMap';
import { ToolTipLoadboard } from './tooltip';

type AgeProps = ILoadsearchTableItem['Age'];

const Age: FC<AgeProps> = (Age) => {
  return (
    <CustomizedTooltips
      placement={'bottom'}
      titleFirstLine={'firstline'}
      titleSecondLine={'secondline'}
      arrow={true}
      backgroundColor={'transparent'}
      enterDelay={20}
      leaveDelay={10}
      titleFirstIcon={<SettingsOutlinedIcon />}
      titleSecondIcon={<DnsOutlinedIcon />}
      isCustomizationTitle={true}
      component={<ToolTipLoadboard logo={Age.logo} />}
    >
      <Box
        style={{
          display: 'flex',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ListItemIcon
            aria-label="provider-name"
            className="provider-logo"
            sx={{ alignItems: 'center' }}
          >
            {loadboardsMap[Age.logo]}
          </ListItemIcon>

          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '150%',
              marginBottom: '0',
              margin: 'auto',
            }}
            gutterBottom
          >
            {`${Age.age}m`}
          </Typography>
        </Box>
      </Box>
    </CustomizedTooltips>
  );
};

export default React.memo(Age);
