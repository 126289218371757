import StorageManager from '../../../../../StorageManager/StorageManager';
import { documentService, notesService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import {
  AssignDocumentRequest,
  CreateDocumentOnlyRequest,
  DeleteNotesDTO,
  DocumentType,
  EditNotesDTO,
  GetNotesDTO,
  NOTE_TYPE,
  NOTE_TYPE_DISPLAY,
} from '../../../../../models';
import { deleteFile } from '../../../../../subPages/expenses/utils/utils';
import { titleCase } from './constant';
import { DefaultDataTypes, ListTypes } from './interface';

export const getNotesListByLoadId = async (loadId: string) => {
  const userStorage = StorageManager.getUser();
  const request = new GetNotesDTO();
  request.organizationId = userStorage.organizationId;
  request.loadId = loadId;
  return await notesService.getNotesList(request);
};

export const deleteNotes = async (item: ListTypes) => {
  const userStorage = StorageManager.getUser();
  const request = new DeleteNotesDTO();
  request.organizationId = userStorage.organizationId;
  request.noteId = item.noteId || '';
  await notesService.deleteNotesData(request);
};

export const createNotes = async (
  mode: string,
  data: DefaultDataTypes,
  loadId: string,
  item: ListTypes = {},
  isAttachmentIncluded,
  previousData
) => {
  const userStorage = StorageManager.getUser();
  const payload = new EditNotesDTO();
  payload.loadId = loadId;
  payload.noteDesc = data.notesText || '';
  payload.organizationId = userStorage.organizationId;

  let modeRes;
  if (mode == 'createOnHoldLoad') {
    payload.notesType = data.notesType;
    (payload.shareWithDriver = !!data.shareDriver),
      (modeRes = await notesService.createNotesData(payload));
  } else if (mode == 'create') {
    payload.notesType = 'INTERNAL';
    (payload.shareWithDriver = !!data.shareDriver),
      (modeRes = await notesService.createNotesData(payload));
  } else {
    payload.notesType = item.notesType || '';
    payload.noteId = item.noteId || '';
    payload.userId = item.userId || 0;
    payload.editable = item.editable || false;
    payload.deletable = item.deletable || false;
    payload.stopId = item.stopId || null;
    payload.deleteDocument = data.deleteDocument || false;
    payload.shareWithDriver =
      item.notesType == 'INTERNAL' ? data.shareDriver : true;
    modeRes = await notesService.editNotesData(payload);
  }
  if (data.attachment && data.attachment.length) {
    const documentId = previousData?.attachment?.[0]?.documentId;
    let performAttachment = true;
    if (!isAttachmentIncluded) performAttachment = false;

    if (performAttachment) {
      if (documentId) {
        await deleteFile([documentId]);
      }
      const requestBody = new CreateDocumentOnlyRequest({
        data: data.attachment[0],
        organizationId: userStorage.organizationId,
        ownerId: userStorage.id,
      });

      const fileRes = await uploadDocument(requestBody);
      if (fileRes) {
        const assignDocumentRequest = new AssignDocumentRequest({
          description: '',
          ownerName: userStorage.firstname + ' ' + userStorage.lastname,
          permission: 'PRIVATE',
          organizationId: fileRes.organizationId || userStorage.organizationId,
          documentType: 'RECEIPT' as DocumentType,
          documentId: fileRes.id,
          fileName: fileRes.fileName,
          fileSize: fileRes.fileSize,
          uploadDate: fileRes.uploadDate,
          ownerId: fileRes.ownerId || Number.parseInt(userStorage.id),
          documentTypeId: '',
          attachedEntities: [
            {
              properties: [
                {
                  id:
                    mode == 'create' || mode == 'createOnHoldLoad'
                      ? modeRes.noteId
                      : item.noteId,
                  title: '',
                },
              ],
              type: 'LOAD_NOTE',
            },
          ],
        });
        await assignDocument(assignDocumentRequest);
      }
    }
  }
};

export const uploadDocument = async (
  requestBody: CreateDocumentOnlyRequest
) => {
  const response = await documentService.createDocumentOnly(requestBody);
  return response instanceof ServiceError ? null : response;
};

export const assignDocument = async (requestBody: AssignDocumentRequest) => {
  const response = documentService.assignDocument(requestBody);

  return response instanceof ServiceError ? null : response;
};

export const getNoteTypeDisplay = (notesType: NOTE_TYPE): string => {
  const str = NOTE_TYPE_DISPLAY[notesType];
  if (str) return str;
  return titleCase(notesType || '');
};
