import { useEffect, useMemo, useState } from 'react';
import { getaccessorialItemStaticData } from '../contract';
import { Autocomplete, TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { httpClient } from '../../axios/axiosInstance';
import { financialItemURL } from '../../api/impl/requestConstants';
import { GetInvoiceLineItemsRequest, QueryParams } from '../../models';
import { invoiceService } from '../../api';
const DropDownCellEdit = (prop: any) => {
  const { params } = prop;
  const [options, setOptions] = useState<Array<any>>([]);
  const fetchFinanceItems = async () => {
    try {
      const request = new GetInvoiceLineItemsRequest();
      request.isInvoiceItem = true;
      const response = (await invoiceService.getInvoiceLineItems(
        request
      )) as Array<any>;

      // const response = (await httpClient.get(
      //   financialItemURL,
      //   new QueryParams(),
      //   null,
      //   true,
      //   true
      // )) as Array<any>;
      if (response && response?.length > 0) {
        const data = [] as Array<any>;
        response?.forEach((item: any) => {
          if (item?.itemType === 'ACCESSORIAL') {
            data.push({ value: item?.itemCode, label: item?.itemName });
          }
        });
        setOptions(data);
      }
      return response;
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    fetchFinanceItems();
  }, []);

  const apiRef = useGridApiContext();

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="" />}
      onchange={(value: any) => {
        apiRef.current.setEditCellValue({
          id: params.row.id,
          value: value,
          field: params.field,
          debounceMs: 300,
        });
        apiRef.current.commitCellChange({
          id: params.row.id,
          field: params.field,
        });
      }}
    />
  );
};

export default DropDownCellEdit;
