import { Type } from 'class-transformer';
import { QueryParams } from '../commonMixed/QueryParams';
import {
  ContactSummary,
  vendorListRequestType,
  vendorFiltersRequestType,
  vendorListExcelRequestType,
} from './Vendor';
import { Address } from '../../../subPages/customers/models/customer';
import { Contact } from '../Contacts/Contacts';

export class PaginatedVendorsListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;

  constructor({ pageSize, pageNumber, sort }: vendorListRequestType) {
    super();
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.sort = sort;
  }
}

export class GetVendorFiltersRequest {
  vendorNames!: string[] | null;
  vendorAddress!: string[] | null;
  vendorContactNames!: string[] | null;
  preferredProhibitedEnum!: string[] | null;
  gridColumnMetadataList?: string[];
  vendorIds?: string[];
  constructor({
    vendorNames,
    vendorAddress,
    vendorContactNames,
    preferredProhibitedEnum,
    gridColumnMetadataList,
    vendorIds,
  }: vendorFiltersRequestType) {
    this.vendorNames = vendorNames;
    this.vendorAddress = vendorAddress;
    this.vendorContactNames = vendorContactNames;
    this.preferredProhibitedEnum = preferredProhibitedEnum;
    this.gridColumnMetadataList = gridColumnMetadataList;
    this.vendorIds = vendorIds;
  }
}

export class GetVendorListExcelRequest extends QueryParams {
  sort!: string;
  pageNumber!: number;
  pageSize!: number;
  gridColumnMetadataList: string[];
  constructor({
    sort,
    pageNumber,
    pageSize,
    gridColumnMetadataList,
  }: vendorListExcelRequestType) {
    super(true);
    this.sort = sort;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.gridColumnMetadataList = gridColumnMetadataList;
  }
}
export class CreateVendorContactRequestParams extends QueryParams {
  vendorId: number;
  constructor(data: any) {
    super(data);
    this.vendorId = data.vendorId;
  }
}
export class CreateVendorContactRequest extends Contact {
  seqNumber: number; //@TODO: Nvard - seqNumber will be removed in the future fter backend side refactoring of Vendor contact
  name: string; // @TODO: Nvard - name will be renamed to fullName after backend refactorings of vendor
  constructor(data: any) {
    super(data);
    this.seqNumber = data.seqNumber;
    this.name = data.name;
  }
}
export class CreateVendorRequest extends QueryParams {
  isActive?: boolean;
  seqNumber?: number;
  name!: string;
  notes?: string;
  preferredProhibitedEnum?: string;
  addressDTO?: Address;
  @Type(() => ContactSummary)
  contacts?: ContactSummary[];

  constructor(data: any) {
    // @TODO: Nvard - use class transformer api
    super();
    this.isActive = data.isActive;
    this.seqNumber = data.seqNumber;
    this.name = data.name;
    this.notes = data.notes;
    this.preferredProhibitedEnum = data.preferredProhibitedEnum;
    this.addressDTO = data.addressDTO;
    this.contacts = data.contacts;
  }
}

export class UpdateVendorRequest extends QueryParams {
  id!: string;
  isActive!: boolean;
  seqNumber!: number;
  name!: string;
  notes!: string;
  preferredProhibitedEnum!: string;
  addressDTO!: Address;
  @Type(() => ContactSummary)
  contacts!: ContactSummary[];

  constructor(data: any) {
    // @TODO: Nvard - use class transformer api
    super();
    this.id = data.id;
    this.isActive = data.isActive;
    this.seqNumber = data.seqNumber;
    this.name = data.name;
    this.notes = data.notes;
    this.preferredProhibitedEnum = data.preferredProhibitedEnum;
    this.addressDTO = data.addressDTO;
    this.contacts = data.contacts;
  }
}

export class DeleteVendorRequest extends QueryParams {
  id!: string;
  constructor(data: { id: string }) {
    super();
    this.id = data.id;
  }
}
export class PaginatedVendorNamesListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  vendorSearchNames!: string;

  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.vendorSearchNames = dto.vendorSearchNames;
    }
  }
}

export class PaginatedVendorContactsListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  vendorSearchContactNames!: string;

  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.vendorSearchContactNames = dto.vendorSearchContactNames;
    }
  }
}

export class PaginatedVendorAddressesListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  vendorSearchAddress!: string;

  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.vendorSearchAddress = dto.vendorSearchAddress;
    }
  }
}
