import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import StorageManager from '../../../../StorageManager/StorageManager';
import LongMenu from '../../../../common/LongMenu';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import FormDialog from '../../../../common/Ui/FormDialog';
import { UserDetails } from '../../../../models/DTOs/user/User';
import AxeleAccordion from '../../../../ui-kit/components/Accordion';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import { useSafetyAlert } from '../../../../views/safety/alerts/context/SafetyAlertContext';
import { getAlertStatusType } from '../../../shared/equipment';
import SafetyAlertActions from './SafetyAlertActions';
import { safetyAlertValidationSchema } from './constants';
import { LineItem, SafetyFormType, SafetyType } from './models';
import AlertLineItem from './sections/AlertLineItem';
import SafetyAlertsHeader from './sections/SafetyAlertsHeader';

const deleteAlertPermissions: { [key: string]: AXELE_PERMISSION_TYPE[] } = {
  AD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_REMOVE],
  DI: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_REMOVE],
  NFD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_REMOVE],
  PD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_REMOVE],
};

const viewAlertsPermission: {
  [key: string]: AXELE_PERMISSION_TYPE[];
} = {
  AD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_VIEW],
  DI: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_VIEW],
  NFD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_VIEW],
  PD: [AXELE_PERMISSION_TYPE.COMMON_UPCOMING_EVENTS_VIEW],
};

export default function SafetyAlerts({
  data,
  onMarkDoneCompleteCallback,
  createAlertCallback,
}: {
  data: UserDetails;
  createAlertCallback?: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
  onMarkDoneCompleteCallback?: (
    result: any,
    formData?: { validFrom: string; validTo: string; notes: string }
  ) => void;
}) {
  const { id: driverId, firstName, lastName } = data;
  const [safetyTypes, setSafetyTypes] = useState<SafetyType[]>([]);
  const [documentLineItemsMap, setDocumentLineItemsMap] = useState<{
    [key: string]: LineItem;
  }>({});
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletedAlertInfo, setDeletedAlertInfo] = useState<{
    index: number;
    id: string;
    safetyIssueTypeWithDaysAndMiles: {
      days: number;
      issueType: string;
      miles: number;
    };
  } | null>(null);

  const { control, watch, getValues, reset, setValue } = useFormContext();
  const { fetchMainData } = useSafetyAlert();

  const { fields } = useFieldArray({
    control,
    name: 'alertsList',
  });
  const watchFieldArray = watch('alertsList');
  const userStorageData = StorageManager.getUser();
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const openNewAlertModal = () => setAlertDialogOpen(true);

  useEffect(() => {
    const getPageData = async () => {
      const [alerts, driverAlerts, documentTypes] = await Promise.all([
        SafetyAlertActions.getSafetyAlerts(data.id),
        SafetyAlertActions.getDriverAlerts(),
        SafetyAlertActions.getDocumentTypes(),
      ]);

      if (alerts) {
        reset(
          {
            ...getValues(),
            alertsList: alerts,
          },
          { keepDefaultValues: false }
        );
      }
      const organizationSafetyMap: { [key: string]: SafetyType } = {};
      if (driverAlerts && documentTypes) {
        const organizationDocumentIds = driverAlerts.safety.map((doc) => {
          organizationSafetyMap[doc.documentTypeId] = doc;
          return doc.documentTypeId;
        });
        const documentLineItemsMapObj: { [key: string]: any } = {};
        for (const doc of documentTypes) {
          if (organizationDocumentIds.includes(doc.id)) {
            const currentSafety = organizationSafetyMap[doc.id];
            documentLineItemsMapObj[doc.id] = {
              ...doc,
              frequency: currentSafety.frequency,
              frequencyValue: currentSafety.frequencyValue,
            };
          }
        }
        setDocumentLineItemsMap(documentLineItemsMapObj);
        setSafetyTypes(driverAlerts.safety);
      }
    };
    getPageData();
  }, [data.id]);

  const existingItemIds = controlledFields.map((field) => field.documentTypeId);

  const noPermissionForInactiveStatus = data.status === 0;

  return (
    <Grid container sx={{ p: 2 }}>
      <SafetyAlertsHeader
        alertsCount={controlledFields.length}
        openNewAlertModal={openNewAlertModal}
        inactive={noPermissionForInactiveStatus}
      />
      {controlledFields?.map((alert, index) => {
        const status = getAlertStatusType(alert);
        const currentLineItem =
          documentLineItemsMap[alert.documentTypeId] || {};
        return (
          <Permission
            key={alert.id}
            includes={viewAlertsPermission[userStorageData?.roleCode]}
          >
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <AxeleAccordion
                  titleRenderer={() => currentLineItem.itemName || ''}
                  subTitleRenderer={() => status?.message || ''}
                  renderLabel={() => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        sx={(theme) => ({ color: theme.palette.primary.main })}
                      >
                        {getAlertStatusType(alert)?.icon}
                      </Typography>
                      <Box sx={{ ml: 1 }} onClick={(e) => e.stopPropagation()}>
                        {!noPermissionForInactiveStatus && (
                          <Permission
                            includes={
                              deleteAlertPermissions[userStorageData?.roleCode]
                            }
                          >
                            <LongMenu
                              color={'primary.main'}
                              options={[
                                {
                                  name: 'Delete',
                                  action: () => {
                                    setDeleteDialogOpen(true);
                                    setDeletedAlertInfo({
                                      index,
                                      id: alert.id,
                                      safetyIssueTypeWithDaysAndMiles:
                                        alert.safetyIssueTypeWithDaysAndMiles,
                                    });
                                  },
                                },
                              ]}
                            />
                          </Permission>
                        )}
                      </Box>
                    </Box>
                  )}
                >
                  <AlertLineItem
                    nameStart={`alertsList.${index}.`}
                    documentTypeOptions={[]}
                    documentLineItem={currentLineItem}
                    driverId={driverId}
                    driverName={`${firstName} ${lastName}`}
                    alert={alert}
                    index={index}
                    inactive={noPermissionForInactiveStatus}
                    onMarkDoneCompleteCallback={onMarkDoneCompleteCallback}
                  />
                </AxeleAccordion>
              </Grid>
            </Grid>
          </Permission>
        );
      })}
      {alertDialogOpen && (
        <FormDialog
          data={{
            documentTypeId: null,
            validFrom: moment().startOf('day'),
            validTo: null,
            notes: '',
            documentType: '',
          }}
          titleText={t('createAlert')}
          actionLabel={t('addAlert')}
          open={alertDialogOpen}
          handleClose={() => setAlertDialogOpen(false)}
          onAction={async (data: SafetyFormType) => {
            const result = await SafetyAlertActions.createAlert(data, driverId);
            if (result) {
              fetchMainData?.();
              setAlertDialogOpen(false);
              setValue(
                'alertsList',
                [...getValues('alertsList'), ...[result]],
                {
                  shouldDirty: false,
                }
              );
            }
            if (
              result?.safetyIssueTypeWithDaysAndMiles?.issueType === 'WARNING'
            ) {
              createAlertCallback?.(driverId, 1, 0, true);
            }
            if (
              result?.safetyIssueTypeWithDaysAndMiles?.issueType === 'CRITICAL'
            ) {
              createAlertCallback?.(driverId, 0, 1, true);
            }
          }}
          validationSchema={safetyAlertValidationSchema}
          contentRenderer={() => (
            <AlertLineItem
              nameStart={''}
              documentLineItemsMap={documentLineItemsMap}
              onMarkDoneCompleteCallback={onMarkDoneCompleteCallback}
              documentTypeOptions={Object.values(documentLineItemsMap).filter(
                (lineItem) => !existingItemIds.includes(lineItem.id)
              )}
            />
          )}
        />
      )}
      {deleteDialogOpen && (
        <DeletePopup
          title={'Are you sure you want to delete the alert?'}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onAction={async () => {
            if (deletedAlertInfo) {
              const result = await SafetyAlertActions.deleteSafetyAlert(
                deletedAlertInfo.id
              );
              if (result) {
                fetchMainData?.();
                setDeleteDialogOpen(false);
                const filteredAlerts = getValues('alertsList').filter(
                  (alert: SafetyType) => alert.id !== deletedAlertInfo.id
                );
                setValue('alertsList', filteredAlerts, {
                  shouldDirty: false,
                });
              }
              if (
                deletedAlertInfo?.safetyIssueTypeWithDaysAndMiles?.issueType ===
                'WARNING'
              ) {
                createAlertCallback?.(driverId, -1, 0, true);
              }
              if (
                deletedAlertInfo?.safetyIssueTypeWithDaysAndMiles?.issueType ===
                'CRITICAL'
              ) {
                createAlertCallback?.(driverId, 0, -1, true);
              }
            }
          }}
        />
      )}
    </Grid>
  );
}
