import { CircularProgress, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, CSSProperties } from 'react';
import {
  IntersectionObserverHookRefCallback,
  IntersectionObserverHookRootRefCallback,
} from 'react-intersection-observer-hook';

interface Props {
  infiniteRef: IntersectionObserverHookRefCallback;
  rootRef: IntersectionObserverHookRootRefCallback;
  hasNextPage: boolean;
  sx: CSSProperties;
  children: ReactNode;
}
export const InfiniteScrollWrapper = ({
  infiniteRef,
  rootRef,
  hasNextPage,
  sx,
  children,
}: Props) => {
  return (
    <Box sx={sx} ref={rootRef}>
      {children}

      <Grid container>
        <Grid item xs={12}>
          {hasNextPage && (
            <Box ref={infiniteRef} sx={{ textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
