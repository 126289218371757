import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { IeldCustomerFeatures } from '../../../../types/ELDTypes';
import {
  credentialsInfo,
  sideDrawerDisplayData,
  userGuides,
} from '../../../../utils/customerCredentialsStepperLabel';
import IntegrationFormContent from '../../../../ui-kit/components/IntegrationFormContent';
import TerminalController from '../../../../views/settings/terminals/TerminalController';

export default function SidedrawerContent({
  currentCustomerData,
  open,
  toggleDrawer,
  providerData,
  validateFormCreds,
  dataToUpdate,
  activationErrMessage,
  updateAccountCard,
  formErr,
  authenticateOAuth,
  integrationType,
}: ISelectedCustomerTypes) {
  const [integrationCredentials, setIntegrationCredentials] = useState([]);

  const { terminals } = TerminalController.instance();

  useEffect(() => {
    getCustomerIntegrationData();
  }, [currentCustomerData.providerId]);

  const getCustomerIntegrationData = (): void => {
    const currentCustomerRequiredCredentials =
      providerData?.integrationRequiredCredentials?.filter(
        (item: IeldCustomerFeatures) =>
          item.id === currentCustomerData.providerId
      );
    currentCustomerRequiredCredentials &&
      currentCustomerRequiredCredentials.length > 0 &&
      setIntegrationCredentials(currentCustomerRequiredCredentials[0]?.fields);
  };

  let desc = '';
  sideDrawerDisplayData.fields = integrationCredentials;
  sideDrawerDisplayData.userGuide = userGuides[currentCustomerData.providerId];
  switch (currentCustomerData.providerId) {
    case 12:
      desc = credentialsInfo.content2.replace(
        '{providerName}',
        `${currentCustomerData.providerName}`
      );
      break;
    case 17:
    case 15:
    case 11:
      desc = credentialsInfo.content8.replace(
        '{providerName}',
        `${currentCustomerData.providerName}`
      );
      break;
    case 6:
      desc = credentialsInfo.content3.replace(
        '{providerName}',
        `${currentCustomerData.providerName}`
      );
      break;
    case 2:
      desc = credentialsInfo.content9.replace(
        '{providerName}',
        `${currentCustomerData.providerName}`
      );
      break;
    case 1:
    case 19:
    case 9:
    case 16:
    case 13:
    case 14:
      desc = credentialsInfo.content1.replace(
        '{providerName}',
        `${currentCustomerData.providerName}`
      );
      break;
    case 4:
      desc = credentialsInfo.content10.replace(
        '{providerName}',
        `${currentCustomerData.providerName}`
      );
      break;
    case 10:
      desc = credentialsInfo.content11.replaceAll(
        '{providerName}',
        `${currentCustomerData.providerName}`
      );
      break;
    case 8:
      desc = credentialsInfo.content6.replace(
        '{providerName}',
        `${currentCustomerData.providerName}`
      );
      break;
    case 3:
      desc = credentialsInfo.content12.replaceAll(
        '{providerName}',
        `${currentCustomerData.providerName}`
      );
      break;
  }
  sideDrawerDisplayData.desc = desc;

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(true)}
      onOpen={toggleDrawer(true)}
      disableSwipeToOpen={false}
      sx={{
        '.MuiPaper-root': {
          width: '462px',
          height: '90%',
          bottom: '5px',
          top: 'unset',
          padding: '25px 40px 10px 32px',
          borderRadius: '16px 0px 0px 16px',
        },
      }}
    >
      <Box className="drawer-content">
        <Box
          onClick={toggleDrawer(false)}
          sx={{ float: 'right', cursor: 'pointer' }}
        >
          <CloseOutlinedIcon />
        </Box>
        <Box className="provider-logo" sx={{ pt: '25px' }}>
          {currentCustomerData && currentCustomerData.logo}
        </Box>
        {open && (
          <IntegrationFormContent
            sideDrawerDisplayData={sideDrawerDisplayData}
            currentCustomerData={currentCustomerData}
            validateFormCreds={validateFormCreds}
            closeDrawer={toggleDrawer(false)}
            dataToUpdate={dataToUpdate}
            activationErrMessage={activationErrMessage}
            updateAccountCard={updateAccountCard}
            formErr={formErr}
            authenticateOAuth={authenticateOAuth}
            getTerminalOptions={terminals}
            integrationType={integrationType}
          />
        )}
      </Box>
    </SwipeableDrawer>
  );
}

export interface ISelectedCustomerTypes {
  currentCustomerData?: any;
  open: boolean;
  toggleDrawer: Function;
  providerData: any;
  validateFormCreds?: any;
  dataToUpdate?: any;
  activationErrMessage?: string;
  updateAccountCard?: any;
  formErr?: any;
  authenticateOAuth?: any;
  integrationType?: string;
}
