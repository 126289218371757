import {
  DriverMapMarkersRequestParams,
  GanttDriverMapMarkersResponse,
} from '../../models/DTOs/Driver/Requests';
import {
  ChangeStatusBulkSelectUnassignedTripsResponse,
  CreateTimeoffResponse,
  DispatchGridDataItem,
  DriverTractorAssignResponse,
  DriverTrailerAssignResponse,
  GanttDriverLocationMarkersResponse,
  GanttWarningsResponse,
  GetDispatchDetailsPanelResponse,
  GetDriverLoadsGroupAssignmentResponse,
  GetPreferenceDriverResponse,
  GetTractorInfoForDriverAssignResponse,
  GetTrailerInfoForDriverAssignResponse,
  PaginatedDispatchDriverListResponse,
  PaginatedGetPreviousLoadResponse,
  RegenerateTimelineResponse,
  RevertOrActivateTimeoffResponse,
  UpdateGroupAssgnmentResponse,
  UpdatePreferenceDriverResponse,
} from '../../models/DTOs/Dispatch/Dispatch';
import {
  ChangeStatusBulkSelectUnassignedTripsRequest,
  CreateTimeoffRequest,
  DeleteTimeoffRequest,
  DriverTractorAssignRequest,
  DriverTractorUnAssignRequest,
  DriverTrailerAssignRequest,
  DriverTrailerUnAssignRequest,
  GanttDriverLocationMarkersRequest,
  GanttWarningsRequest,
  GetDispatchDetailsPanelMapDataRequest,
  GetDispatchDetailsPanelRequest,
  GetDispatchGridDataRequest,
  GetDriverLoadsGroupAssignmentRequest,
  GetPreferenceDriverRequest,
  GetPreviousLoadRequest,
  GetTractorInfoForDriverAssignRequest,
  GetTrailerInfoForDriverAssignRequest,
  PaginatedDispatchDriverRequest,
  PaginatedDispatchDriverRequestQuery,
  RegenerateTimelineRequest,
  RevertOrActivateTimeoffRequest,
  UpdateGroupAssgnmentRequest,
  UpdatePreferenceDriverRequest,
  UpdateTimeoffRequest,
} from '../../models/DTOs/Dispatch/Requests';
import { ServiceError } from './helperTypes';
import { Service } from './Service';
import { QueryParams } from '../../models';
export abstract class IDispatchService extends Service {
  abstract getPaginatedDriverList(
    requestData: PaginatedDispatchDriverRequest,
    queryParams?: PaginatedDispatchDriverRequestQuery,
    skipTrackPromise?: boolean
  ): Promise<PaginatedDispatchDriverListResponse | null | ServiceError>;

  abstract getGanttWarnings(
    requestData: GanttWarningsRequest,
    skipTrackPromise?: boolean
  ): Promise<GanttWarningsResponse | null | ServiceError>;

  abstract getGanttDriverLocationMarkers(
    requestData: GanttDriverLocationMarkersRequest,
    skipTrackPromise?: boolean
  ): Promise<GanttDriverLocationMarkersResponse | null | ServiceError>;

  abstract getDriverMapMarkers(
    requestData: DriverMapMarkersRequestParams,
    requestParams: QueryParams,
    skipTrackPromise?: boolean
  ): Promise<GanttDriverMapMarkersResponse | null | ServiceError>;

  abstract getDispatchDetailsPanel(
    requestData: GetDispatchDetailsPanelRequest,
    skipTrackPromise?: boolean
  ): Promise<GetDispatchDetailsPanelResponse | null | ServiceError>;

  abstract getDispatchDetailsPanelMapData(
    requestData: GetDispatchDetailsPanelMapDataRequest,
    skipTrackPromise?: boolean
  ): Promise<GanttDriverLocationMarkersResponse | null | ServiceError>;

  abstract driverTractorAssign(
    requestData: DriverTractorAssignRequest
  ): Promise<DriverTractorAssignResponse | null | ServiceError>;

  abstract driverTractorUnAssign(
    requestData: DriverTractorUnAssignRequest
  ): Promise<DriverTractorAssignResponse | null | ServiceError>;

  abstract driverTrailerAssign(
    requestData: DriverTrailerAssignRequest
  ): Promise<DriverTrailerAssignResponse | null | ServiceError>;

  abstract driverTrailerUnAssign(
    requestData: DriverTrailerUnAssignRequest
  ): Promise<DriverTrailerAssignResponse | null | ServiceError>;

  abstract getPreviousLoad(
    requestData: GetPreviousLoadRequest
  ): Promise<PaginatedGetPreviousLoadResponse | null | ServiceError>;

  abstract createTimeoff(
    requestData: CreateTimeoffRequest
  ): Promise<CreateTimeoffResponse | null | ServiceError>;

  abstract updateTimeoff(
    requestData: UpdateTimeoffRequest
  ): Promise<CreateTimeoffResponse | null | ServiceError>;

  abstract deleteTimeoff(
    requestData: DeleteTimeoffRequest
  ): Promise<CreateTimeoffResponse | null | ServiceError>;

  abstract revertOrActivateTimeoff(
    requestData: RevertOrActivateTimeoffRequest
  ): Promise<RevertOrActivateTimeoffResponse | null | ServiceError>;

  abstract updateGroupAssgnment(
    requestData: UpdateGroupAssgnmentRequest
  ): Promise<UpdateGroupAssgnmentResponse | null | ServiceError>;

  abstract updatePreferenceDriver(
    requestData: UpdatePreferenceDriverRequest
  ): Promise<UpdatePreferenceDriverResponse | null | ServiceError>;

  abstract addPreferenceDriver(
    requestData: UpdatePreferenceDriverRequest
  ): Promise<UpdatePreferenceDriverResponse | null | ServiceError>;

  abstract getPreferenceDriver(
    requestData: GetPreferenceDriverRequest,
    skipTrackPromise?: boolean
  ): Promise<GetPreferenceDriverResponse | null | ServiceError>;

  abstract getDriverLoadsGroupAssignment(
    requestData: GetDriverLoadsGroupAssignmentRequest,
    skipTrackPromise?: boolean
  ): Promise<GetDriverLoadsGroupAssignmentResponse | null | ServiceError>;

  abstract regenerateTimeline(
    requestData: RegenerateTimelineRequest
  ): Promise<RegenerateTimelineResponse | null | ServiceError>;

  abstract getTractorInfoForDriverAssign(
    requestData: GetTractorInfoForDriverAssignRequest
  ): Promise<GetTractorInfoForDriverAssignResponse | ServiceError>;

  abstract getTrailerInfoForDriverAssign(
    requestData: GetTrailerInfoForDriverAssignRequest
  ): Promise<GetTrailerInfoForDriverAssignResponse | ServiceError>;

  abstract changeStatusBulkSelectUnassignedTrips(
    requestData: ChangeStatusBulkSelectUnassignedTripsRequest
  ): Promise<ChangeStatusBulkSelectUnassignedTripsResponse | ServiceError>;

  abstract getDispatchGridData(
    requestData: GetDispatchGridDataRequest
  ): Promise<DispatchGridDataItem[] | ServiceError>;
}
