import { numberWithThousandSeparator } from '.';

const isNullOrEmpty = (value: any): boolean => {
  return value === null || value === '' || value === undefined;
};

export const numberToMiles = (value: number, defaultValue = ''): string => {
  return !isNullOrEmpty(value)
    ? `${numberWithThousandSeparator(value)} mi`
    : defaultValue;
};
