import { Box, SxProps } from '@mui/material';
import React from 'react';
import SingleLineColumn from './SingleLineColumns';

interface columnProps {
  icon?: JSX.Element;
  text?: string;
  textStyles?: object;
  containerStyles?: SxProps;
}

interface IMultipleLineColumn {
  data: columnProps[];
  containerStyle?: object;
}

const MultiLineColumn: React.FC<IMultipleLineColumn> = (props) => {
  const { data, containerStyle } = props;
  return (
    <Box sx={{ width: '100%', ...containerStyle }}>
      {data?.map((detail: columnProps) => (
        <SingleLineColumn
          key={detail?.text}
          icon={detail.icon && detail.icon}
          text={detail?.text}
          textStyles={detail.textStyles}
          containerStyles={detail?.containerStyles}
        />
      ))}
    </Box>
  );
};

export default MultiLineColumn;
