import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import FormDatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';
import Select from '../../../../common/Ui/Select';
import { MultipleAutocompleteForm } from '../../../../common/Ui/MultipleAutocomplete';
import {
  driverLicenseEndorsements,
  driverLicenseRestrictions,
} from '../constants';

export default function DriverLicenseForm({
  states,
}: {
  states: { name: string }[];
}) {
  const { control } = useFormContext();
  return (
    <Grid container rowSpacing={2} columnSpacing={4} sx={{ mt: 2 }}>
      <FormDatePicker
        control={control}
        name={`driverLicense.validTo`}
        label={'Expiry Date'}
        sizes={{ xs: 4 }}
        startOfDate
        iso
        // onDateChange={(date: string) => handleValidFromChange(date)}
      />
      <Select
        sizes={{ xs: 4 }}
        options={states}
        fieldName={'name'}
        fieldValue={'name'}
        label={'License State'}
        control={control}
        name={'driverLicense.licenseState'}
      />
      <Select
        sizes={{ xs: 4 }}
        options={['A', 'B']}
        disabled
        label={'Category'}
        control={control}
        name={'driverLicense.category'}
      />
      <Grid item xs={12} sx={{ pt: 2 }}>
        <MultipleAutocompleteForm
          control={control}
          variant={'standard'}
          options={driverLicenseEndorsements}
          name={'driverLicense.endorsements'}
          fieldName={'value'}
          label={'endorsements'}
        />
      </Grid>
      <Grid item xs={12} sx={{ pt: 4 }}>
        <MultipleAutocompleteForm
          control={control}
          variant={'standard'}
          options={driverLicenseRestrictions}
          name={'driverLicense.restrictions'}
          fieldName={'value'}
          label={'Restrictions'}
        />
      </Grid>
    </Grid>
  );
}
