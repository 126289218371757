import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Measure from 'react-measure';
import { AXELE_PERMISSION_TYPE } from '../common/Permission';
import { getHasPermission } from '../common/Permission/utils/helperUtils';
import { useRootStore } from '../store/root-store/rootStateContext';
import { useStores } from '../store/root.context';
import { dashboardContainerStyles } from '../styles/dashboard.styles';
import { AvailabilityKPI } from '../views/dashboard/components/AvailabilityKPI';
import Drivers from '../views/dashboard/components/Drivers';
import { FinanceKPI } from '../views/dashboard/components/FinanceKPI';
import LoadInvoices from '../views/dashboard/components/LoadInvoices';
import { LoadOperationalSummary } from '../views/dashboard/components/LoadOperationalSummary';
import { SafetyStanding } from '../views/dashboard/components/SafetyStanding';
import DriversMap from '../common/Map';

const Dashboard = () => {
  const { dashboardStore } = useStores();
  const { getGlobalTerminalsIds } = useRootStore();
  const [dimensions, setDimensions] = useState({
    dimensions: {
      width: -1,
      height: -1,
    },
  });

  const hasFinanceKPIPermission = getHasPermission({
    includes: [
      AXELE_PERMISSION_TYPE.DASHBOARD_VIEW,
      AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICES_VIEW,
    ],
  });

  useEffect(() => {
    dashboardStore.getInvoice(getGlobalTerminalsIds, 'delay');
    dashboardStore.getInvoice(getGlobalTerminalsIds, 'pending');
    dashboardStore.getInvoice(getGlobalTerminalsIds, 'missing');
    dashboardStore.getInvoice(getGlobalTerminalsIds, 'ready');
    dashboardStore.getSafety(getGlobalTerminalsIds);

    dashboardStore.getFinance(
      true,
      dashboardStore.revenue,
      dashboardStore.plan,
      getGlobalTerminalsIds
    );

    dashboardStore.getDriverAvailability(getGlobalTerminalsIds);
    dashboardStore.getTractorAvailability(getGlobalTerminalsIds);
    dashboardStore.getTripsSummary(getGlobalTerminalsIds);
  }, [getGlobalTerminalsIds, dashboardStore.revenue]);

  return (
    <Measure
      bounds
      onResize={(contentRect: { bounds: any }) => {
        setDimensions({ dimensions: contentRect.bounds });
      }}
    >
      {({ measureRef }) => {
        return (
          <div ref={measureRef}>
            <Box sx={dashboardContainerStyles(dimensions.dimensions.width)}>
              <Grid container spacing={1}>
                {hasFinanceKPIPermission && (
                  <Grid item xs={12}>
                    <FinanceKPI />
                  </Grid>
                )}
                <Grid item md={hasFinanceKPIPermission ? 4 : 6} xs={12}>
                  <LoadOperationalSummary />
                </Grid>
                <Grid item md={hasFinanceKPIPermission ? 4 : 6} xs={12}>
                  <AvailabilityKPI />
                </Grid>
                {hasFinanceKPIPermission && (
                  <Grid item md={4} xs={12}>
                    <LoadInvoices />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <SafetyStanding />
                </Grid>
                <Grid item xs={12}>
                  {/* <Drivers /> */}
                  <Box
                    sx={{
                      mt: 1,
                      height: '50vh',
                      width: '100%',
                    }}
                  >
                    <DriversMap
                      isMarkerActionDisabled={true}
                      allowNavigationForUnlocatedDrivers={true}
                      disableFilter={true}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
        );
      }}
    </Measure>
  );
};

export default observer(Dashboard);
