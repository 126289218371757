import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import uuid from '../../../../../../utils/uuid';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import {
  ArrowRightIcon,
  MappedIcon,
} from '../../../../../../ui-kit/components/Assets';
import { SingleAutocompleteForm } from '../../../../../../common/Ui/SingleAutocomplete';
import { sectionType } from '../constants';
import {
  IMPORT_CUSTOMER_NAME,
  useImport,
} from '../../../../../../contexts/ImportContext';

type pagePropTypes = {
  axeleValues: string[];
  currentSection: sectionType;
  fieldName: string;
  from?: string;
};

const FORM_DATA_SLICE_NUMBER = 20;

export default function MissingEntitiesMatchForm({
  axeleValues,
  currentSection,
  fieldName,
  from = '',
}: pagePropTypes) {
  const {
    currentEntity: { displayName: entityDisplayName },
  } = useImport();
  const { control, watch, setValue } = useFormContext();
  const scrollRef = useRef<HTMLDivElement>(null);
  const formValues = watch();

  const [newOption] = useState(null);
  const [formData, setFormData] = useState<string[]>(
    Object.keys(axeleValues).slice(0, FORM_DATA_SLICE_NUMBER)
  );
  const [allData] = useState<string[]>(Object.keys(axeleValues));
  const scrollNumber = useRef(1);

  const addNewOption = async (itemName: string, name: string) => {
    setValue(`${name}`, itemName);
  };

  useEffect(() => {
    const element = scrollRef.current;
    if (!element) {
      return;
    }
    const handleScroll = () => {
      if (
        element.scrollTop + element.clientHeight >= element.scrollHeight &&
        formData.length < allData.length
      ) {
        setFormData((data) => [
          ...data,
          ...allData.slice(
            scrollNumber.current * FORM_DATA_SLICE_NUMBER,
            scrollNumber.current * FORM_DATA_SLICE_NUMBER +
              FORM_DATA_SLICE_NUMBER
          ),
        ]);
        scrollNumber.current++;
      }
    };
    element.addEventListener('scroll', handleScroll);
    return () => {
      scrollNumber.current = 1;
      element.removeEventListener('scroll', handleScroll);
    };
  }, [formData.length]);

  const renderSelect = (axeleValue: string) => {
    if (from === IMPORT_CUSTOMER_NAME) {
      return (
        <SingleAutocompleteForm
          fieldName={fieldName}
          control={control}
          label={`LoadOps ${entityDisplayName}`}
          name={`${currentSection.name}.${axeleValue}`}
          getOptions={currentSection.getList}
          wrapperStyles={{ margin: 0 }}
          newOption={newOption}
          addNewOption={() =>
            addNewOption(axeleValue, `${currentSection.name}.${axeleValue}`)
          }
          freeSolo
          customValue={{ id: uuid(), name: axeleValue }}
        />
      );
    }
    return (
      <SingleAutocompleteForm
        fieldName={fieldName}
        control={control}
        label={`LoadOps ${entityDisplayName}`}
        name={`${currentSection.name}.${axeleValue}`}
        getOptions={currentSection.getList}
        wrapperStyles={{ margin: 0 }}
      />
    );
  };

  return (
    <Box
      sx={{ height: 400, overflowY: 'auto', overflowX: 'hidden' }}
      ref={scrollRef}
    >
      {formData.map((axeleValue, i) => (
        <Box
          key={i}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 3,
            p: 1,
          }}
        >
          <Box sx={{ flex: 4 }}>
            <TextField
              disabled
              value={axeleValue}
              label={`Found In File`}
              variant={'standard'}
              sx={{ width: '100%' }}
            />
          </Box>
          <Box sx={{ mr: 3, ml: 3 }}>
            {formValues?.[currentSection.name]?.[axeleValue] ? (
              <MappedIcon />
            ) : (
              <ArrowRightIcon opacity={'0.6'} />
            )}
          </Box>
          <Box sx={{ flex: 4 }}>{renderSelect(axeleValue)}</Box>
        </Box>
      ))}
    </Box>
  );
}
