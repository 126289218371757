import { Grid, SxProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import ButtonGroup from '../../../ui-kit/components/ButtonGroup';

const optionsExample = [
  {
    key: 'one',
    value: 'One',
  },
];

export default function ButtonGroupRadio({
  name,
  control,
  sizes = { xs: 12 },
  options = optionsExample,
  disabled,
  switcher,
  fullwidth,
  marginBottom,
  buttonStyle,
  fieldName,
  fieldValue,
}: {
  name: string;
  control?: any;
  sizes?: { xs: number };
  options?: { key: string; value: string }[];
  disabled?: boolean;
  switcher?: boolean;
  fullwidth?: boolean;
  marginBottom?: number;
  buttonStyle?: SxProps;
  fieldName?: string;
  fieldValue?: string;
}) {
  return (
    <Grid xs={sizes.xs} item style={{ marginBottom: marginBottom || 20 }}>
      <Controller
        render={({ field }) => {
          return (
            <ButtonGroup
              switcher={switcher}
              fullWidth={fullwidth}
              disabled={disabled}
              options={options}
              selected={field.value}
              onChange={field.onChange}
              buttonStyle={buttonStyle}
              fieldName={fieldName}
              fieldValue={fieldValue}
            />
          );
        }}
        name={name}
        control={control}
      />
    </Grid>
  );
}
