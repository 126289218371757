import { Type } from 'class-transformer';
import { ContactPerson } from './userHelperTypes';

export class Terminal {
  id!: string;
  organizationId!: number;
  companyName!: string;
  dot!: string;
  mc!: string;
  address!: string;
  @Type(() => ContactPerson)
  contactPerson!: ContactPerson;
}
