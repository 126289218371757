import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  getFailureMsg,
  getSuccessMsg,
} from '../../constants/globalNotificationMessages';
import {
  ContactSummaryDTO,
  CreateLocationContactRequest,
  CreateLocationRequest,
  DeleteLocationByIdRequest,
  EditLocationDetailsByIdRequest,
  GetAddressNamesListRequest,
  GetContactNamesListRequest,
  GetLocationDetailsByIdRequest,
  GetLocationFiltersRequest,
  GetLocationListByNameAddressSearchRequest,
  GetLocationListDetailsByCoordsRequest,
  GetLocationListDetailsByCoordsResponse,
  GetLocationListRequest,
  GetLocationNamesListRequest,
  GetTimezoneByCoordsRequest,
  GetTimezoneByCoordsResponse,
  LocationSummaryDTO,
  PaginatedAddressFilterList,
  PaginatedContactsFilterList,
  PaginatedLocationAddressList,
  PaginatedLocationsFilterList,
  PaginatedLocationsList,
  QueryParams,
  ValidateLocationsUniqueNamesRequest,
  ValidateLocationsUniqueNamesResponse,
} from '../../models';
import {
  ILocationService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';
import {
  createLocationsURL,
  getLocationListDetailsByCoordsURL,
  validateLocationsUniqueNamesURL,
} from './requestConstants';

import { RootStoreInstence } from '../../store/root-store/rootStateContext';

const getCreateLocationURL = '/web/location/api/v2/locations/create/location';
const getCreateLocationContactURL =
  '/web/location/api/v2/contacts/location/create';
const getLocationListURL = '/web/location/api/v2/locations/get/list/fe';
const getLocationListByNameAddressSearchURL =
  '/web/location/api/v2/locations/search/name/address';
const getLocationListExportURL =
  '/web/location/api/v2/locations/location/export-to-excel';
const getDeleteLocationByIdURL = '/web/location/api/v2/locations/location';
const getLocationDetailsByIdURL = '/web/location/api/v2/locations/get/location';
const editLocationDetailsByIdURL = '/web/location/api/v2/locations/update';
const getLocationNamesListURL = '/web/location/api/v2/locations/name/dropdown';
const getAddressNamesListURL =
  '/web/location/api/v2/locations/address/dropdown';
const getContactNamesListURL =
  '/web/location/api/v2/contacts/get/contact/dropdown';
const getTimezoneByCoordsURL = '/web/geobus/api/v2/locations/timezone';

export class LocationService extends ILocationService {
  @annotateNameAsync
  async createLocation(payloadData: CreateLocationRequest) {
    try {
      const response = await httpClient.post<LocationSummaryDTO>(
        getCreateLocationURL,
        new QueryParams(true),
        payloadData,
        LocationSummaryDTO,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'locationCreate',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'locationCreate',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async createLocations(payloadData: CreateLocationRequest[]) {
    const serviceName = 'locationsCreate';
    const locationNames = payloadData
      ?.map((location) => location.locationName)
      ?.join(', ');
    try {
      const response = await httpClient.postRaw(
        createLocationsURL,
        new QueryParams(true),
        payloadData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName,
        message: getSuccessMsg[serviceName](locationNames),
      });
      return response.data || [];
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName,
        message: getFailureMsg[serviceName](locationNames) || '',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async validateLocationsUniqueNames(
    payloadData: ValidateLocationsUniqueNamesRequest
  ) {
    try {
      return await httpClient.put<ValidateLocationsUniqueNamesResponse>(
        validateLocationsUniqueNamesURL,
        undefined,
        payloadData,
        ValidateLocationsUniqueNamesResponse,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getLocationListExport(
    requestData: GetLocationListRequest,
    filtersData: GetLocationFiltersRequest
  ) {
    try {
      return await httpClient.put<PaginatedLocationsList>(
        getLocationListExportURL,
        requestData,
        filtersData,
        PaginatedLocationsList,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getLocationList(
    requestData: GetLocationListRequest,
    filtersData: GetLocationFiltersRequest
  ) {
    try {
      return await httpClient.put<PaginatedLocationsList>(
        getLocationListURL,
        requestData,
        filtersData,
        PaginatedLocationsList,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getLocationListByNameAddressSearch(
    requestData: GetLocationListByNameAddressSearchRequest
  ) {
    try {
      return await httpClient.get<PaginatedLocationAddressList>(
        getLocationListByNameAddressSearchURL,
        requestData,
        PaginatedLocationAddressList,
        false,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async deleteLocationById(payloadData: DeleteLocationByIdRequest) {
    try {
      await httpClient.deleteRaw(getDeleteLocationByIdURL, payloadData, null);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'locationDelete',
      });
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'locationDelete',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getLocationDetailsById(requestData: GetLocationDetailsByIdRequest) {
    try {
      return await httpClient.get<LocationSummaryDTO>(
        getLocationDetailsByIdURL,
        requestData,
        LocationSummaryDTO,
        false,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async editLocationDetailsById(payloadData: EditLocationDetailsByIdRequest) {
    try {
      const response = await httpClient.put<LocationSummaryDTO>(
        editLocationDetailsByIdURL,
        new QueryParams(true),
        payloadData,
        LocationSummaryDTO,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'locationUpdate',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'locationUpdate',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;

        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getLocationsNamesList(requestData: GetLocationNamesListRequest) {
    try {
      return await httpClient.get<PaginatedLocationsFilterList>(
        getLocationNamesListURL,
        requestData,
        PaginatedLocationsFilterList,
        false,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getAddressNamesList(requestData: GetAddressNamesListRequest) {
    try {
      return await httpClient.get<PaginatedAddressFilterList>(
        getAddressNamesListURL,
        requestData,
        PaginatedAddressFilterList,
        false,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
  async getContactNamesList(requestData: GetContactNamesListRequest) {
    try {
      return await httpClient.get<PaginatedContactsFilterList>(
        getContactNamesListURL,
        requestData,
        PaginatedContactsFilterList,
        false,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async createLocationContact(payloadData: CreateLocationContactRequest) {
    try {
      const response = await httpClient.post<ContactSummaryDTO>(
        getCreateLocationContactURL,
        new QueryParams(true),
        payloadData,
        ContactSummaryDTO,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createContact',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'createContact',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getLocationListDetailsByCoords(
    requestData: GetLocationListDetailsByCoordsRequest
  ): Promise<GetLocationListDetailsByCoordsResponse | ServiceError> {
    try {
      return await httpClient.post<GetLocationListDetailsByCoordsResponse>(
        getLocationListDetailsByCoordsURL,
        new QueryParams(true),
        requestData,
        GetLocationListDetailsByCoordsResponse,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTimezoneByCoords(
    requestData: GetTimezoneByCoordsRequest
  ): Promise<GetTimezoneByCoordsResponse | ServiceError | null> {
    try {
      return await httpClient.get<GetTimezoneByCoordsResponse>(
        getTimezoneByCoordsURL,
        requestData,
        GetTimezoneByCoordsResponse,
        false,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
}
