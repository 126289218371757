// import TerminalComponent from '../views/settings/terminals';
import { AXELE_PERMISSION_TYPE, Permission } from '../common/Permission';
import TerminalDataGrid from '../views/settings/terminalsV2/components/TerminalDataGrid/TerminalDataGrid';

export const Terminal = () => {
  return (
    <Permission includes={[AXELE_PERMISSION_TYPE.TERMINALS_VIEW]}>
      <TerminalDataGrid />
    </Permission>
  );
};

export default Terminal;
