type EntityTypesType = {
  [key: string]: string;
};

export const entityTypes: EntityTypesType = {
  LOAD: 'LOAD',
  SOLO: 'SOLO',
  TEAM: 'TEAM',
  DRIVER: 'DRIVER',
  TRACTOR: 'TRACTOR',
  TRAILER: 'TRAILER',
  EQUIPMENT: 'EQUIPMENT',
  CUSTOMER: 'CUSTOMER',
  LOCATION: 'LOCATION',
  VENDOR: 'VENDOR',
  DOCUMENT: 'DOCUMENT',
  INVOICE: 'INVOICE',
  TRIPS: 'TRIPS',
  DISPATCH: 'DISPATCH',
  DISPATCHER: 'DISPATCHER',
};
