import { ArrowBackOutlined } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { subscriptionService } from '../../api';
import { getOrganizationId } from '../../common/TimeoffDialog/utils';
import { statesinCountry } from '../../common/Ui/AddressField/constants/states';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { countryCodeOptions } from '../../constants/contacts';
import AuthContext from '../../globalContexts/AuthContext';
import {
  GetSubscriptionRequest,
  Register,
  UpsertPaymentRejoinRequest,
  UpsertPaymentRequest,
  UserLoginInfo,
} from '../../models';
import { useRootStore } from '../../store/root-store/rootStateContext';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import PhoneField from '../../ui-kit/components/PhoneFieldWithContryandExtn/PhoneField';
import TextInput from '../../ui-kit/components/TextField';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import StorageManager from '../../StorageManager/StorageManager';
import { emailValidation } from '../settings/terminals/constants/constants';
import { ChargifyBankInfo, ChargifyCardInfo } from './Chargify';
import { AuthRibbonBanner } from './components/AuthRibbonBanner';
import { registerConfig, rejoinConfig } from './constant';
import {
  StyledAuthFromWrapper,
  StyledBlockTitle,
  StyledFormTitle,
  SxPropAuthButton,
  getPhoneFieldStylePropsAuthForm,
} from './styles';
import {
  PASSWORD_SCORE,
  checkPassScore,
  containsOnlyNumbers,
  getCustomerIp,
  keyCompareValidation,
} from './utils';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const RegistrationForm = ({ rejoin = false, onClickBack }) => {
  const [searchParams] = useSearchParams();
  const source: string = searchParams.get('partner');
  const { isMobile } = useThemeResponsive();
  const [step, setStep] = useState<number>(rejoin ? 1 : 0);
  const [ipAddress, setIpAddress] = useState<string>('');

  const [form, setForm] = useState({
    same_billing_address: !rejoin,
    paymentTab: 'card',

    billing_address: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    privacy: false,
    dot: '',
    email: '',
    fleetSize: '',
    mobileno: '',
    organizationName: '',
    password: '',
    firstname: '',
    countryCode: 'USA',
    extension: '',
    lastname: '',
  });

  const [error, setError] = useState({});
  const [cardData, setCardData] = useState({});
  const [tokenGenerated, setToken] = useState('');
  const [policyError, setPolicyError] = useState<boolean>(false);
  const { loginUser } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const config = rejoin ? rejoinConfig : { ...registerConfig[step] };

  if (form.paymentTab !== 'card') config.form = [config.form[0]];
  const navigate = useNavigate();
  const { setLoadingUrl, setLoadingFinishedUrl } = useRootStore();
  const [open, setOpen] = useState(false);
  const getQuery = () => {
    const token = tokenGenerated;
    const query = new Register();
    const {
      address,
      city,
      state,
      zipcode,
      dot,
      email,
      fleetSize,
      mobileno,
      extension,
      organizationName,
      password,
      firstname,
      lastname,
      same_billing_address,
      billing_address,
      paymentTab,
    } = form;
    query.address = address.trim();
    query.country = 'USA';
    query.city = city.trim();
    query.state = state.trim();
    query.zipcode = zipcode.trim();
    query.dot = dot.trim();
    query.email = email.trim();
    query.fleetSize = fleetSize.trim();
    query.mobileno = mobileno.trim();
    query.countryCode = 'USA';
    query.extension = extension;
    query.organizationName = organizationName.trim();
    query.password = window.btoa(password);
    query.paymentToken = token;
    query.firstname = firstname.trim();
    query.lastname = lastname.trim();
    query.source = source;
    query.paymentProfileDTO = {
      billing_address: same_billing_address ? form.address : billing_address,
      billing_city: same_billing_address ? form.city : city,
      billing_state: same_billing_address ? form.state : state,
      billing_zip: same_billing_address ? form.zipcode : zipcode,
      billing_country: 'USA',
      chargify_token: token,
      payment_method: paymentTab === 'card' ? 'credit_card' : 'bank_account',
    };
    return query;
  };

  useEffect(() => {
    if (tokenGenerated) {
      callApi();
    }
  }, [tokenGenerated]);

  const callApi = async () => {
    const conditionalUrl = 'registrationform';
    setLoadingUrl(conditionalUrl);
    if (rejoin) {
      const { address, city, state, zipcode, countryCode } = form;
      const query = new UpsertPaymentRequest();
      query.chargify_token = tokenGenerated;
      query.billing_address = address;
      query.billing_city = city;
      query.billing_state = state;
      query.billing_zip = zipcode;
      query.billing_country = countryCode;
      const subscription = await subscriptionService.getSubscription(
        new GetSubscriptionRequest()
      );
      if (!subscription) {
        setOpen(true);
        setErrorMessage('Failed to fetch subscription dat');
        setTimeout(() => {
          setOpen(false);
          setErrorMessage('');
        }, 2000);
      }
      query.subscription_id = subscription?.subscription?.id;
      query.customer_id = subscription?.subscription?.customer?.id;
      query.subscription_state = subscription?.subscription?.state;
      UpsertPaymentRejoinRequest;
      const reJoinPayLoad = new UpsertPaymentRejoinRequest();
      reJoinPayLoad.organizationId = getOrganizationId();
      reJoinPayLoad.paymentProfile = query;
      let ip = ipAddress;
      if (!ipAddress) {
        ip = await getCustomerIp();
        setIpAddress(ip);
      }
      reJoinPayLoad.ipAddress = ip;

      const res = await subscriptionService.upsertPaymentRejoin(reJoinPayLoad);
      if (res) {
        const loginData = StorageManager.getUser() || {};
        loginData.subscriptionStatus = 'ACTIVE';
        localStorage.setItem('user', JSON.stringify(loginData));
        navigate('/');
      }
    } else {
      const query = getQuery();
      const res = await subscriptionService.registerData({
        ...query,
        ipAddress: ipAddress,
      });
      if (res) {
        loginUser(new UserLoginInfo(res.dot, res.email, form.password));
      }
    }
    setLoadingFinishedUrl(conditionalUrl);
  };

  const onSaveCardChargifyHandler = async (): Promise<void> => {
    const conditionalUrl = 'onsavecard';
    setLoadingUrl(conditionalUrl);
    return new Promise((resolve, reject) => {
      cardData.chargify.token(
        cardData.chargifyForm.current,
        (token: string) => {
          setToken(token);
          resolve(token);
          setLoadingFinishedUrl(conditionalUrl);
        },
        (error) => {
          setOpen(true);
          const errorMsg = Array.isArray(error?.errors)
            ? error?.errors[0]
            : error?.errors;
          setErrorMessage(errorMsg ? errorMsg : 'Your card data is invalid.');
          setTimeout(() => {
            setOpen(false);
            setErrorMessage('');
          }, 2000);
          reject(error);
          setLoadingFinishedUrl(conditionalUrl);
        }
      );
    });
  };
  const handleSave = async () => {
    const conditionalUrl = 'onsavecard';
    setLoadingUrl(conditionalUrl);
    await onSaveCardChargifyHandler();
    setLoadingFinishedUrl(conditionalUrl);
  };

  const validate = () => {
    const toValid = config.form;
    const err: keyCompareValidation = {};
    const passwordScore = checkPassScore(form.password);

    toValid.forEach((ele) => {
      ele.config.forEach((element) => {
        if (element.required && !form[element.name]) err[element.name] = true;
        else if (element.name === 'address' && !form.address)
          err[element.name] = true;
        else if (element.name === 'city' && !form.city)
          err[element.name] = true;
        else if (element.name === 'zipcode' && !form.zipcode)
          err[element.name] = true;
        else if (
          element.name === 'email' &&
          !emailValidation.test(form[element.name])
        )
          err[element.name] = element.label + ' is not valid.';
        else if (
          element.name === 'mobileno' &&
          !(containsOnlyNumbers(form.mobileno) && form.mobileno.length === 10)
        )
          err[element.name] = element.label + ' is not valid.';
        else if (
          element.name === 'mobileno' &&
          form.extension.length > 0 &&
          !(containsOnlyNumbers(form.extension) && form.extension.length <= 5)
        )
          err['mobileno'] = 'Extension is not valid.';
        else if (element.name === 'dot' && form.dot.length > 20)
          err[element.name] = 'Cannot be longer than 20 characters.';
        else if (element.name === 'password' && passwordScore < PASSWORD_SCORE)
          err[element.name] = 'Password is not strong enough';
        else if (
          element.name === 'confirmPassword' &&
          form.confirmPassword !== form.password
        )
          err[element.name] = 'Confirm password does not match.';
      });
    });
    setError(err);
    return isEmpty(err);
  };

  const onNextStep = async () => {
    if (!ipAddress) {
      const ip = await getCustomerIp();
      setIpAddress(ip);
    }
    if (validate() && step === 0) {
      const conditionalUrl = 'onnextstep';
      setLoadingUrl(conditionalUrl);
      const response = await subscriptionService.validateRegisterData(
        getQuery()
      );
      setLoadingFinishedUrl(conditionalUrl);
      if (response) {
        const globalData = window?.env || {};
        globalData.CHARGIFY_PUBLIC_KEY =
          response.frontEndConfig.CHARGIFY_PUBLIC_KEY;
        globalData.CHARGIFY_SERVER_HOST =
          response.frontEndConfig.CHARGIFY_SERVER_HOST;
        window.env = globalData;
        setStep(1);
      }
    } else if (step === 1 && validate()) {
      if (!form.privacy) {
        setPolicyError(true);
        return;
      }
      handleSave();
    }
  };

  const onBack = () => {
    if (rejoin) {
      onClickBack();
      return;
    }
    const newForm = form;
    const toValid = config.form;
    toValid.forEach((ele) => {
      ele.config.forEach((element) => {
        newForm[element.name] = undefined;
      });
    });
    setForm({
      ...newForm,
      paymentTab: 'card',
      billing_address: {},
      same_billing_address: true,
    });
    setError({});
    setStep(step - 1);
  };

  useEffect(() => {
    if (form?.same_billing_address) {
      setForm((prev) => ({
        ...prev,
        billing_address: prev.address,
      }));
    }
  }, [form?.same_billing_address, form?.address, step]);
  return (
    <StyledAuthFromWrapper>
      <Box>
        <Box
          sx={{
            marginBottom: '15px',
            position: 'relative',
          }}
        >
          {step ? (
            <Box
              sx={{
                position: 'absolute',
                pl: isMobile ? 0 : 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={onBack}
            >
              <ArrowBackOutlined
                fontSize={isMobile ? 'medium' : 'large'}
                sx={{ color: 'text.primary' }}
              />
            </Box>
          ) : null}
          <StyledFormTitle>{config.title}</StyledFormTitle>
        </Box>
        <Box>
          {config.form.map((block: any, index: number) => {
            return (
              <Box key={index} sx={{ marginBottom: '20px', width: '100%' }}>
                <StyledBlockTitle>{block.title}</StyledBlockTitle>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  {block.config.map(
                    (
                      field: {
                        name: string;
                        label: string;
                        inputType?: string;
                        width?: string;
                        options?: any;
                        type: string;
                        required: boolean;
                      },
                      i: number
                    ) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            width: isMobile ? '100%' : field.width || '100%',
                            marginBottom: '15px',
                          }}
                        >
                          {field.name === 'state' ? (
                            <Autocomplete
                              id="combo-box-demo"
                              options={statesinCountry}
                              getOptionLabel={(option) => option.name}
                              value={
                                form.state
                                  ? statesinCountry.find(
                                      ({ value }) => value === form.state
                                    )
                                  : undefined
                              }
                              onChange={(
                                event: any,
                                newValue: string | null
                              ) => {
                                setForm({
                                  ...form,
                                  [field.name]: newValue.value,
                                  ['countryCode']: newValue.CountryAbbr,
                                });
                              }}
                              renderInput={(params) => (
                                <TextInput
                                  {...params}
                                  label="State"
                                  variant="outlined"
                                  required={field.required}
                                  error={Boolean(error[field.name])}
                                  helperText={error[field.name]}
                                />
                              )}
                            />
                          ) : field.name === 'cardName' ? (
                            <ChargifyCardInfo
                              setCardData={setCardData}
                              isAuthPage
                              userProfileData={form}
                            />
                          ) : field.name === 'mobileno' ? (
                            <PhoneField
                              label={field.label}
                              required={field.required}
                              countryCodeOptions={countryCodeOptions}
                              error={error[field.name]}
                              helperText={error[field.name]}
                              countryCode={form.countryCode}
                              number={form.mobileno}
                              extn={form.extension}
                              onChange={(data: any) => {
                                setForm({
                                  ...form,
                                  countryCode: data.countryCode,
                                  extension: data.extn,
                                  mobileno: data.number,
                                });
                              }}
                              variant="outlined"
                              {...getPhoneFieldStylePropsAuthForm(isMobile)}
                            />
                          ) : field.type === 'tab' ? (
                            <ButtonGroup fullWidth size="large">
                              {field.options.map(
                                (x: { key: string; value: string }) => {
                                  return (
                                    <ButtonImproved
                                      key={x.key}
                                      variant={
                                        x.key === form[field.name]
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                      onClick={() =>
                                        setForm({
                                          ...form,
                                          [field.name]: x.key,
                                        })
                                      }
                                      label={x.value}
                                      styleProps={{ marginBottom: '15px' }}
                                    />
                                  );
                                }
                              )}
                            </ButtonGroup>
                          ) : field.type === 'checkbox' ? (
                            <Box
                              sx={(theme) => ({
                                ...theme.typography.body1,
                                color: 'text.primary',
                              })}
                            >
                              <Checkbox
                                checked={form[field.name]}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    [field.name]: e.target.checked,
                                  })
                                }
                              />
                              {field.label}
                            </Box>
                          ) : (
                            <TextInput
                              type={field.inputType || undefined}
                              label={field.label}
                              error={Boolean(error[field.name])}
                              helperText={error[field.name]}
                              onChange={(e: any) => {
                                const newForm = {
                                  ...form,
                                  [field.name]: e.target.value,
                                };
                                setForm(newForm);
                                setError({ ...error, [field.name]: '' });
                              }}
                              styleProps={{ width: '100%' }}
                              value={form[field.name]}
                              variant="outlined"
                              required={field.required}
                            />
                          )}
                          {form.paymentTab !== 'card' && (
                            <ChargifyBankInfo
                              setCardData={setCardData}
                              rejoin={true}
                            />
                          )}
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          alignItems: 'center',
        }}
      >
        {step === 0 && (
          <>
            <ButtonImproved
              onClick={onNextStep}
              label="Next"
              size="large"
              endIcon={<ArrowRightAltIcon />}
              styleProps={{
                ...SxPropAuthButton,
                borderRadius: '14px',
              }}
              variant="contained"
            />
            <Box
              sx={(theme) => ({
                ...theme.typography.body2,
                color: 'text.primary',
                mt: 2,
                mb: 1,
                textAlign: 'center',
                display: 'flex',
              })}
            >
              Already have an account?
              <Box sx={{ color: 'primary.main' }}>
                <Link
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                  to={{
                    pathname: '/login',
                    search: window?.location?.search,
                  }}
                >
                  Sign In
                </Link>
              </Box>
            </Box>
          </>
        )}
        {step === 1 && (
          <>
            {!rejoin && <AuthRibbonBanner />}

            <Box
              sx={(theme) => ({
                ...theme.typography.body1,
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '22px',
                color: 'text.primary',
                marginBottom: policyError ? '5px' : '25px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
                mt: 2,
              })}
            >
              <Checkbox
                checked={form.privacy}
                onChange={(e) => {
                  if (e.target.checked) setPolicyError(false);
                  setForm({
                    ...form,
                    privacy: e.target.checked,
                  });
                }}
              />
              <Box
                sx={{
                  display: 'inline-table',
                }}
              >
                I agree with LoadOps’
                <Box
                  component={'span'}
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  <a
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://axele.com/privacy-policy/"
                  >
                    Privacy Policy
                  </a>
                </Box>
                and
                <Box
                  component={'span'}
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  <a
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      marginLeft: '5px',
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://axele.com/terms/"
                  >
                    Terms of Conditions
                  </a>
                </Box>
              </Box>
            </Box>
            {policyError && (
              <Box
                sx={(theme) => ({
                  ...theme.typography.body1,
                  color: 'error.main',
                  marginBottom: '25px',
                  fontSize: '12px',
                  paddingLeft: '40px',
                  width: '100%',
                })}
              >
                Please agree to Privacy Policy.
              </Box>
            )}
            <ButtonImproved
              onClick={onNextStep}
              label="Join"
              size="large"
              styleProps={{
                ...SxPropAuthButton,
                borderRadius: '14px',
              }}
              variant="contained"
            />
          </>
        )}
        {!rejoin && (
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Chip
              label=""
              color="primary"
              size="small"
              sx={{ width: '66px', height: '20px', marginRight: '30px' }}
            />
            <Chip
              label=""
              color="primary"
              size="small"
              variant={step ? undefined : 'outlined'}
              sx={{ width: '66px', height: '20px' }}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={2500}
      >
        <Alert severity="error"> {errorMessage}</Alert>
      </Snackbar>
    </StyledAuthFromWrapper>
  );
};

export default RegistrationForm;
