import { Box, SxProps, Theme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { ITripV3BrokeredOption } from '../../../../../TripPlan';
import {
  CommonExpandableTimelineContentProps,
  TimelineVariantType,
} from '../../../../AxeleTimeline';
import { StyledImageComplete } from '../../../styles';
import { TripViewV3Mode } from '../../../types';
import { getTimelineContentByViewModeV3 } from '../../../utils/timelineViewModeV3';
import {
  ExpandableTimelineV3AddStopEvents,
  ExpandableTimelineV3EditIconEvents,
  ExpandableTimelineV3LoadIdEvents,
  ExpandableTimelineV3PanelView,
  ExpandableTimelineV3ResequenceStopEvents,
  StopSolutionV3Prop,
  TripHeaderProps,
} from '../../types';
import { TripContent } from './TripContent';

export interface TripV3DataProps {
  id: string;
  expanded: boolean;
  showHOSPlan?: boolean;
  showProgressIcon?: boolean;
  brokered?: ITripV3BrokeredOption | null;
  expandedHOSPLan?: boolean;
  isCompleted?: boolean;
  isCancelled?: boolean;
  disabled?: boolean;
  hideImageComplete?: boolean;
  headerData: TripHeaderProps;
  stopList: StopSolutionV3Prop[];
  options?: Record<string, any>;
  //combine together with enablePreAndNext (prevLoadId/nextLoadId)
  prevLoadId?: any;
  nextLoadId?: any;
}

export interface TripV3Props
  extends ExpandableTimelineV3EditIconEvents,
    ExpandableTimelineV3AddStopEvents,
    ExpandableTimelineV3ResequenceStopEvents,
    ExpandableTimelineV3LoadIdEvents {
  data: TripV3DataProps;
  variant?: TimelineVariantType;
  viewMode: TripViewV3Mode;
  isMultipleExpanded?: boolean;
  panelView: ExpandableTimelineV3PanelView;
  sx?: SxProps<Theme>;
  enablePreAndNext?: boolean;
  readOnly?: boolean;
}

export const TripV3 = memo(
  ({
    data,
    variant = 'outlined' as TimelineVariantType,
    viewMode,
    isMultipleExpanded,
    panelView,
    sx = {},
    enablePreAndNext = false,
    readOnly = false,
    onClickEditIconHandler,
    onClickAddStopHandler,
    onClickAddRelayHandler,
    onClickResequenceDownHandler,
    onClickResequenceUpHandler,
    onClickLoadIdHandler,
  }: TripV3Props): JSX.Element => {
    const [timelineContent, setTimelineContent] = useState<
      CommonExpandableTimelineContentProps<StopSolutionV3Prop>[]
    >([]);

    const init = (): void => {
      const content: CommonExpandableTimelineContentProps<StopSolutionV3Prop>[] =
        getTimelineContentByViewModeV3({
          stopList: data.stopList,
          variant,
          panelView,
          onClickEditIconHandler,
          onClickAddStopHandler,
          onClickAddRelayHandler,
          onClickResequenceDownHandler,
          onClickResequenceUpHandler,
          onClickLoadIdHandler,
          viewMode,
          isCompleted: data.isCompleted,
          isCancelled: data.isCancelled,
          disabled: data.disabled,
          enablePreAndNext: enablePreAndNext,
          readOnly: readOnly,
          data,
        });

      setTimelineContent(content);
    };

    useEffect(() => {
      init();
    }, [
      data,
      variant,
      panelView,
      onClickEditIconHandler,
      onClickAddStopHandler,
      onClickAddRelayHandler,
      onClickResequenceDownHandler,
      onClickResequenceUpHandler,
      viewMode,
      enablePreAndNext,
      readOnly,
    ]);

    return (
      <Box
        sx={{
          width: '100%',
          ...sx,
        }}
      >
        <TripContent
          timelineContent={timelineContent}
          isMultipleExpanded={isMultipleExpanded}
        />
        {!data?.hideImageComplete && (
          <StyledImageComplete
            isCompleted={data.isCompleted}
          ></StyledImageComplete>
        )}
      </Box>
    );
  }
);
