import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  IMPORT_CONTRACT_RATE_LINE_ITEM_NAME,
  IMPORT_CUSTOMER_NAME,
  IMPORT_EXPENSE_NAME,
  IMPORT_LOCATION_NAME,
  IMPORT_VENDOR_NAME,
  useImport,
} from '../../../../../contexts/ImportContext';
import { FileType, GridColDefSelf } from '../../../../../types';
import { UploadFileIcon } from '../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import ImportActions from '../../Import';
import { body2Color, fontFamily, typographyStyles } from '../../constants';
import { fileTypeCodesMap } from '../Landing/constants';
import DateFormatSelect from './DateFormatSelect';
import DeductionTypeSelect from './DeductionTypeSelect';
import LineItemTypeSelect from './LineItemTypeSelect';
import {
  SUPPORTED_FILE_SIZE,
  UPLOAD_BACKEND_ERROR_MESSAGE,
  possibleDeductionTypesMap,
  rowsDataMap,
} from './constants';
type PagePropTypes = {
  setBackendErrors: (error: string) => void;
};

const hideDateSelectEntitiesNames = [
  IMPORT_CUSTOMER_NAME,
  IMPORT_VENDOR_NAME,
  IMPORT_LOCATION_NAME,
  IMPORT_CONTRACT_RATE_LINE_ITEM_NAME,
];

export const BrowseFile = ({ setBackendErrors }: PagePropTypes) => {
  const {
    setFile,
    setUploadingFile,
    errors,
    setErrors,
    currentEntity,
    setTableData,
    setTableColumns,
    setWarnings,
    setSessionId,
    selectedFileType,
    handleDownloadSampleFile,
  } = useImport();
  const { key, name: entityName } = currentEntity;

  const onDrop = useCallback(
    async (acceptedFiles: FileType[]) => {
      if (!acceptedFiles.length) {
        setErrors('The file type is not supported');
      } else if (acceptedFiles[0].size > SUPPORTED_FILE_SIZE) {
        setErrors('File Size can not be greater than 5 MB');
      } else {
        const currentFile = acceptedFiles[0];
        setUploadingFile(true);
        // request here
        const result = await ImportActions.importFile(
          currentFile,
          currentEntity.routeName,
          currentEntity.dictionary,
          ImportActions.selectedDateFormat,
          fileTypeCodesMap[selectedFileType],
          possibleDeductionTypesMap[ImportActions.deductionType]
        );
        if (result) {
          const { content, warnings, key } = result;
          setFile(currentFile);
          setSessionId(key);
          let columns: GridColDefSelf[] = [];
          if (content.length) {
            content.forEach(
              (c: { [key: string]: string | number }, i: number) => (c.id = i)
            );
            columns = ImportActions.generateColumns(
              Object.keys(content[0])
            ) as GridColDefSelf[];
            setUploadingFile(false);
            setTableColumns(columns);
            setTableData(content);
            setWarnings(warnings);
          } else {
            if (warnings.length) {
              setBackendErrors(warnings[0].message);
              return;
            }
            setBackendErrors(UPLOAD_BACKEND_ERROR_MESSAGE);
          }
        } else {
          setUploadingFile(false);
          setBackendErrors(UPLOAD_BACKEND_ERROR_MESSAGE);
        }
      }
    },
    [selectedFileType]
  );

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xlsx'],
    },
    noKeyboard: true,
  });
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        m: 1,
        minHeight: 500,
        height: '100%',
      }}
      {...getRootProps()}
      onClick={(e) => e.stopPropagation()}
    >
      <Box sx={{ mb: 5 }}>
        <UploadFileIcon />
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant={'h5'}
          sx={{
            ...typographyStyles,
            fontSize: 24,
            fontWeight: 400,
          }}
        >
          Drag and Drop anywhere
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        {!hideDateSelectEntitiesNames.includes(entityName) && (
          <DateFormatSelect />
        )}
        {entityName === IMPORT_EXPENSE_NAME && <DeductionTypeSelect />}
        {entityName === IMPORT_CONTRACT_RATE_LINE_ITEM_NAME && (
          <LineItemTypeSelect />
        )}
        <ButtonImproved
          variant={'outlined'}
          label={t('loadsDocumentsBrowseToUpload')}
          {...getRootProps()}
        />
        {entityName === IMPORT_CONTRACT_RATE_LINE_ITEM_NAME && (
          <ButtonImproved
            onClick={() => handleDownloadSampleFile(entityName, true)}
            style={{ marginLeft: 32 }}
            variant={'outlined'}
            label={'Download Sample File'}
          />
        )}
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography
          variant={'body2'}
          sx={{
            color: body2Color,
            fontSize: 14,
            fontWeight: 400,
            fontFamily: fontFamily,
            letterSpacing: '0.15px',
          }}
        >
          {`Supported file types for import are XLS and XLSX. You can preview the
          data after you finish uploading. We support import of only ${rowsDataMap[key]} rows. `}
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={(e) => e.stopPropagation()}
            variant={'body2'}
          >
            Learn More
          </Link>
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        {errors && (
          <Typography
            variant={'body1'}
            sx={{ color: '#E31B0C', fontSize: 16, fontFamily }}
          >
            {errors}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
