import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';

export const NoRowsOverlay = () => (
  <Stack height="100%" alignItems="center" justifyContent="center">
    <TableRowsOutlinedIcon fontSize={'large'} />
    <Box>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '150%',
          alignItems: 'center',
          textAlign: 'center',
          letterSpacing: '0.15px',
        }}
      >
        {t('NoRowsOverlayNodatatodisplay')}
      </Typography>
    </Box>
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '150%',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.15px',
      }}
    ></Typography>
  </Stack>
);
