export const UI_GRID_DATE_FORMAT = 'MMM DD,YYYY';

export const UI_MAINTENANCE_HISTORY_DATE_RANGE_PICKER_FORMAT = 'MM/dd/yyyy';

export const API_MAINTENANCE_HISTORY_COMPLETED_DATE_FORMAT = 'YYYY-MM-DD'; //use for moment fornmat
export const UI_MAINTENANCE_HISTORY_COMPLETED_DATE_FORMAT = 'MM-DD-YYYY'; //use for moment fornmat

export const UI_DATE_FORMAT = 'MMM DD, YYYY';

export const UI_DATETIME_MILITARY_FORMAT = 'MMM DD, YYYY HH:mm';

export const UI_DATETIME_MILITARY_FORMAT_WITH_AT = 'MM/DD/YYYY @ HH:mm';

export const UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE = 'MMM DD, YYYY, HH:mm z';
export const UI_DATE_DISPATCH_CARD_TIMEOFF_ETA = 'MMM Do [@] HH:mm z';
export const UI_DATE_DISPATCH_GANTT_ENTITY_TOOLTIP = 'MMM Do[,] HH:mm z';
export const UI_TIME_24HOURS = 'HH:mm';
export const UI_TIME_24HOURS_TZ_FORMAT = 'HH:mm z';

export const FORM_DATEPICKER_FORMAT = 'MM/DD/YYYY';
export const FORM_DATETIMEPICKER_FORMAT = 'MM/DD/YYYY HH:mm';

export const UI_RELATIVE_DR_FORMAT = 'MM/DD/YYYY';
export const UI_RELATIVE_DR_SELECTION_FORMAT = 'MM/DD';
