import { Box, Stack, Typography } from '@mui/material';
import { MomentInput } from 'moment';
import { useNavigate } from 'react-router-dom';
import { performNaivgation } from '../../utils/commonNavigation';
import { DashboardLoader } from '../../views/home/DhaboardLoader';
import {
  StyledLoadInvoiceKPICard,
  subTitleStyles,
  timeStampStyles,
  titleStyle,
  totalStyles,
} from './styles/loadInvoices.styles';
import { dashboardPage } from '../../common/PendoClassIDs/constants';
import { CustomDashboardLoader } from '../../views/home/CustomDashboardLoader';

export interface LoadInvoiceKPICardProps {
  time: MomentInput;
  total: number;
  titles: string[];
  subTitle: string;
  id: string;
  loader: boolean;
}
export const LoadInvoiceKPICard = ({
  time,
  total,
  titles,
  subTitle,
  id,
  loader,
}: LoadInvoiceKPICardProps): JSX.Element => {
  const navigate = useNavigate();
  return (
    <StyledLoadInvoiceKPICard
      direction="column"
      justifyContent="center"
      alignItems="center"
      onClick={() => performNaivgation(id, navigate)}
    >
      <CustomDashboardLoader loading={!!loader} />
      {!loader && (
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          width={'12rem'}
          height={'100%'}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            width={'100%'}
          >
            <Stack
              id={id + dashboardPage}
              width={'50%'}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              {titles.map((title) => (
                <Typography
                  key={title}
                  component={'div'}
                  color={'primary.main'}
                  sx={titleStyle}
                >
                  {title}
                </Typography>
              ))}
              <Typography
                component={'span'}
                color={'text.primary'}
                sx={subTitleStyles}
              >
                {subTitle}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              width={'50%'}
            >
              <Typography color={'primary.main'} sx={totalStyles}>
                {total}
              </Typography>

              <Typography
                component={'span'}
                color={'text.primary'}
                sx={timeStampStyles}
              >
                {time}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </StyledLoadInvoiceKPICard>
  );
};
