import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import { LoadDetailsSummary } from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { EGanttTabPanel, IDispatchFilters } from '../../constants/types';
import {
  FilterContainerDisabledStyles,
  getStyledGanttHeader,
} from '../../styles';
import { VerticalDivider } from '../Generic/VerticalDivider';
import { DispatchFilters } from './Filters/DispatchFilters';
import PreferenceButton from '../Generic/PreferenceButton';
import { GanttTabPanelProps } from './Filters/DisplayMode';
import { useStores } from '../../../../store/root.context';
import { useTranslation } from 'react-i18next';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { OPTIMIZATION_SOURCES } from '../../../../constants/gantt/gantt.const';
import { AutorenewOutlined } from '@mui/icons-material';
import { http } from '../../../../axios/axios.service';

interface IGanttHeaderLayout {
  onFilterChange?: (filters: IDispatchFilters) => void;
  onTabChange?: (key: EGanttTabPanel) => void;
}

const GanttLayoutHeader: React.FC<IGanttHeaderLayout> = ({
  onTabChange,
  onFilterChange,
}: IGanttHeaderLayout) => {
  const { isMobile } = useThemeResponsive();
  const theme = useTheme();
  const {
    dispatch2GlobalStore: {
      gapForTripAssignment,
      displayMode,
      setDisplayMode,
      startBulkOptymization,
      updatePreferenceSettings,
      resumePreviousOptimization,
      bulkOptymizationInputs: { driverIds, tripIds },
      currentDriversCountToOptimize,
      currentTripsCountToOptimize,
    },
    bulkOptymizationStore: {
      setOptimizationSource,
      setOptimizationRequestResources,
      resetOptimizationResources,
      showToast,
    },
  } = useStores();

  const [newLoadData, setNewLoadData] = useState<LoadDetailsSummary | null>(
    null
  );
  const [timelineLoader, setTimelineLoader] = useState<boolean>(false);
  const onChangeDisplayMode = (tab: GanttTabPanelProps): void => {
    setDisplayMode(tab.key);
    onTabChange?.(tab.key);
  };

  const onChangeFilters = (newFilters?: IDispatchFilters): void => {
    if (!newFilters) return;
    onFilterChange?.(newFilters);
  };

  const styles = getStyledGanttHeader({ theme });
  const { t, ready } = useTranslation();

  const runScriptToUpdateTimeline = async () => {
    try {
      setTimelineLoader(true);
      const res = await http.put({
        apiEndpoint: '/web/interload/api/v2/data-migration/demo-script',
        params: { minutesAhead: '60' },
      });
      showToast({ type: 'SUCCESS', serviceName: 'regenerateTimeline' });
      setTimelineLoader(false);
    } catch (error) {
      showToast({ type: 'FAILURE', serviceName: 'regenerateTimeline' });
      setTimelineLoader(false);
    }
  };
  const renderTimelineRefresh = () => {
    if (window?.location?.host !== 'demo.loadops.com') return <></>;
    return timelineLoader ? (
      <CircularProgress size={22} color="primary" sx={{ margin: 'auto 6px' }} />
    ) : (
      <IconButton onClick={runScriptToUpdateTimeline}>
        <AutorenewOutlined width={18} color="primary" />
      </IconButton>
    );
  };

  if (ready) {
    return (
      <Stack
        direction={'row'}
        sx={styles.GanttFilterContainer}
        spacing={isMobile ? 0 : 1}
      >
        <Typography sx={styles.LayoutTitleStyles}>
          {t('DispatchTitleDriver')}
        </Typography>

        <VerticalDivider />

        <Grid container sx={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            sx={gapForTripAssignment?.id ? FilterContainerDisabledStyles : {}}
            display={'flex'}
          >
            <DispatchFilters
              onChangeDisplayMode={onChangeDisplayMode}
              onChange={onChangeFilters}
              isMapView={displayMode === EGanttTabPanel.Map}
              isGridView={displayMode === EGanttTabPanel.Grid}
              newLoadData={newLoadData}
              setNewLoadData={setNewLoadData}
            />
            {renderTimelineRefresh()}
            <Permission contains={[AXELE_PERMISSION_TYPE.OPTIMIZATION_EDIT]}>
              <Box
                alignItems="flex-start"
                display="flex"
                id="optimization-actions"
              >
                <PreferenceButton
                  driversCount={currentDriversCountToOptimize}
                  tripsCount={currentTripsCountToOptimize}
                  onOpenDriverPreferences={() => updatePreferenceSettings(true)}
                  onStartOptimization={() => {
                    setOptimizationRequestResources({ driverIds, tripIds });
                    setOptimizationSource(OPTIMIZATION_SOURCES.DISPATCH);
                    startBulkOptymization();
                  }}
                  onResumePreviousOptimization={(id: string) => {
                    resetOptimizationResources();
                    setOptimizationSource(OPTIMIZATION_SOURCES.DISPATCH);
                    resumePreviousOptimization(id);
                  }}
                />
              </Box>
            </Permission>
          </Grid>
        </Grid>
      </Stack>
    );
  }
};

export default observer(GanttLayoutHeader);
