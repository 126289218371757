export const itemContainerBoxStyle = {
  padding: '0px 13px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '&:hover': {
    borderRadius: '12px',
    border: '1px solid rgba(43, 100, 203, 0.5)',
    backgroundColor: 'rgba(43, 100, 203, 0.08)',
  },
  textDecoration: 'none',
  width: '100%',
};

export const lineItemContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

export const lineInfoContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: '10px',
};

export const labelStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'Regular',
  fontSize: '14px',
  lineHeight: '21px',
  letterSpacing: '0.15px',
  fontWeight: 400,
  color: 'rgba(0, 17, 34, 0.75)',
};

export const additionalInfoStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'Medium',
  fontSize: '10px',
  lineHeight: '15px',
  letterSpacing: '0.15px',
  fontWeight: 500,
  color: 'rgba(0, 17, 34, 0.6)',
};

export const groupTitleStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'Medium',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.4px',
  fontWeight: 500,
  color: 'rgba(0, 17, 34, 0.6)',
  marginTop: '10px',
};

export const showMoreLessStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'Medium',
  fontSize: '13px',
  lineHeight: '20px',
  letterSpacing: '0.4px',
  fontWeight: 500,
  color: '#2B64CB',
  marginTop: '5px',
};

export const groupContainer = {
  marginTop: '1%',
  marginRight: '1%',
};

export const searchContainerStyle = {
  overflow: 'scroll',
  height: '100%',
};

export const keyboardReturnIconStyle = {
  fontSize: 32,
  color: '#2B64CB',
};

export const entityTypeStyle = {
  fontWeight: 'bold',
};
