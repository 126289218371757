import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  DEFAULT_ZOOM_LEVEL,
  GANTT_ACTION_COLUMN_WIDTH,
  GANTT_DEFAULT_START_DATE,
  GANTT_WINDOW_SIZE,
  OPTIMIZE_GANTT_ALL_LOCK_ID,
  VIEW_PRESET_BY_WINDOW,
  ZOOM_LEVELS,
  additionalDriverColumns,
  driverNameColumn,
  optymizationGanttDefaultConfig,
  optymizeGanttDefaultControlsConfig,
} from '../../config/gantt.config';
import {
  DRIVER_ACTION_KEYS,
  GANTT_LOADER_CLASSNAME,
  OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS,
  RESOURCES_TYPE,
} from '../../constants/gantt.const';
import {
  IGanttTimelineRange,
  IRecommendedResource,
  IRecommendedTrip,
  OnAfterZoomChangeProps,
} from '../../types/gantt.types';
import ActionColumn from '../gantt/ActionColumn';
import ActionColumnHeader from '../gantt/ActionColumnHeader';
import OptymizationGanttChart from '../gantt/GanttChart';
import {
  formatGanttStartDate,
  generateGanttTimelineRange,
  isDriverActionDisabled,
  isResourceTypeAssignedDriver,
} from '../../services/recommendTrips.service';
import { ScheduleTimeoffsType } from '../../../../types/DispatchTypes';
import { IPuckTooltipPropsOnClick, NavigateToTypes } from '@optym/gantt';
import { getTimelinePuckTooltip } from '../../services/tooltip.service';
import { useStores } from '../../../../store/root.context';
import { getGanttColumnSize } from '../../services/operations.service';
import { observer } from 'mobx-react';
import {
  addDaysToDate,
  getPreferredGanttStartDate,
  isDateSame,
  subtractDaysFromDate,
} from '../../../../services/gantt';
import { getResourceReassignOptions } from '../../services/ganttColumns.service';
import { PossibleDriverResourceType } from '../../types/possibleResource.types';
import { OPTIMIZATION_TYPES } from '../../../../store/EvaluationMode/Evaluation.store';
import { useTheme } from '@mui/material';

interface IRecommendedTrips {
  drivers?: Array<IRecommendedResource>;
  trips?: Array<IRecommendedTrip>;
  timeoffs?: Array<ScheduleTimeoffsType>;
  onResourceLock?: (
    driver: IRecommendedResource,
    isAlreadyLocked?: boolean
  ) => void;
  onResourceReject?: (
    driver: IRecommendedResource,
    resourceCurrentEl?: { cls?: string },
    rejectedDriverIndex?: number
  ) => void;
  onAllResourceLock?: (lock: boolean) => void;
  viewPreset?: string;
  layoutStyle?: object;
  isLoading?: boolean;
  allRecordsLocked?: boolean;
  disableAllResourceLock?: boolean;
}
let resetGanttTimeline = true;
const RecommendTripsGantt: React.FC<IRecommendedTrips> = (props) => {
  const { drivers = [], trips = [], timeoffs = [], ...rest } = props;
  const {
    bulkOptymizationStore: {
      hosConstants,
      setGanttColumnSize,
      conflictingResource,
      setResourceToReassign,
      ganttStartDate,
      setGanttStartDate,
      zoomLevel,
      setZoomLevel,
      optimizationStartDate,
      setRecommendationGanttRef,
      resourceToReassign,
      ganttColumnSize,
    },
    evaluationStore: {
      setEvaluationDetails,
      driverDetailsToEvaluate,
      setOnLockCallback,
      setOnRejectCallback,
      setOnSwapCallback,
      stopEvaluation,
      optimizationType,
    },
  } = useStores();
  const ganttRef = useRef<any>(null);
  const allAssignmentsLocked = useRef<any>(false);

  useEffect(() => {
    setRecommendationGanttRef(ganttRef?.current?.instance);
  }, []);

  const [ganttTimelineRange, setGanttTimelineRange] =
    useState<IGanttTimelineRange>(
      generateGanttTimelineRange(GANTT_DEFAULT_START_DATE)
    );

  useEffect(() => {
    if (optimizationStartDate)
      setGanttTimelineRange(generateGanttTimelineRange(optimizationStartDate));
  }, [optimizationStartDate]);

  useEffect(() => {
    if (
      !driverDetailsToEvaluate ||
      optimizationType !== OPTIMIZATION_TYPES.RECOMMENDED_RESOURCE
    )
      return;
    setOnLockCallback(() => {
      props?.onResourceLock?.(
        driverDetailsToEvaluate,
        driverDetailsToEvaluate?.engineMultiTripOutput?.isLocked
      );
    });
    setOnRejectCallback(() => {
      handleRejectCandidate(driverDetailsToEvaluate);
    });
    setOnSwapCallback(() => {
      setResourceToReassign({
        type: RESOURCES_TYPE.DRIVER,
        id: driverDetailsToEvaluate?.id,
        label: driverDetailsToEvaluate?.driverGroupDetails?.groupName,
      });
      stopEvaluation();
    });
  }, [driverDetailsToEvaluate, optimizationType]);

  const selectGanttRow = (record: any) =>
    ganttRef?.current?.instance?.selectRow?.({ record });

  const handleRejectCandidate = (data: PossibleDriverResourceType) => {
    const rejectedResourceIndex =
      ganttRef?.current?.instance?.resourceStore?.indexOf(data?.id);
    const resourceCurrentEl =
      ganttRef?.current?.instance?.resourceStore?.getById?.(data?.id);
    if (resourceCurrentEl) resourceCurrentEl.cls = GANTT_LOADER_CLASSNAME;
    rest?.onResourceReject?.(data, resourceCurrentEl, rejectedResourceIndex);
  };
  const theme = useTheme();
  const driverColumnsMemo = useMemo(() => {
    return [
      {
        id: '1',
        draggable: false,
        width: GANTT_ACTION_COLUMN_WIDTH,
        minWidth: GANTT_ACTION_COLUMN_WIDTH,
        headerRenderer: ({
          headerElement,
        }: {
          headerElement: HTMLElement;
        }): string => {
          headerElement?.classList?.add?.(
            `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
          );
          return renderToStaticMarkup(
            <ActionColumnHeader
              lockButtonId={OPTIMIZE_GANTT_ALL_LOCK_ID}
              isLocked={rest?.allRecordsLocked}
              className={'header-lock'}
              btnStyles={{
                background: '#FFF',
                color: theme?.palette?.primary?.main,
              }}
            />
          );
        },
        renderer: (event: any) => {
          const data = event?.record?.data as IRecommendedResource;
          return (
            <ActionColumn
              isLocked={data?.engineMultiTripOutput?.isLocked}
              isRejectDisabled={isDriverActionDisabled(data)}
              isLockDisabled={isDriverActionDisabled(data)}
              isRejected={data?.engineMultiTripOutput?.isRejected}
              onLock={() => {
                selectGanttRow(event?.record);
                rest?.onResourceLock?.(
                  data,
                  data?.engineMultiTripOutput?.isLocked
                );
              }}
              driver={data}
              evaluationResult={data?.evaluateResponseDTO}
              onReject={() => handleRejectCandidate(data)}
              isOptymal={data?.engineMultiTripOutput?.optimalFlag}
              reassignOptions={getResourceReassignOptions(data)}
              allowReassign={true}
              onReassignClick={() => selectGanttRow(event?.record)}
              onReassign={(data) => {
                setResourceToReassign(data);
              }}
              isSourceRecommendation={true}
            />
          );
        },
      },
      {
        ...driverNameColumn({
          onActionClick: (data: any) => handleDriverAction(data),
          onResetClick: (data: IRecommendedResource) =>
            handleDriverAction({
              action: { key: DRIVER_ACTION_KEYS.CHANGE_TRIP },
              data,
            }),
        }),
      },
      ...additionalDriverColumns({}),
    ];
  }, []);

  useEffect(() => {
    if (!ganttRef?.current?.instance) return;
    const allResourceLockBtn =
      ganttRef?.current?.instance?.columns?.allRecords[0]?.element?.querySelector?.(
        'button'
      );
    if (rest?.isLoading || rest?.disableAllResourceLock)
      return allResourceLockBtn?.classList?.add?.('all-resource-lock-disabled');
    if (!rest?.disableAllResourceLock)
      allResourceLockBtn?.classList?.remove?.('all-resource-lock-disabled');
  }, [rest?.isLoading, rest?.disableAllResourceLock]);

  useEffect(() => {
    const handleDriverConflict = () => {
      if (!conflictingResource?.id || !ganttRef?.current) return;
      const driverRowElement =
        ganttRef.current.instance?.resourceStore?.getById?.(
          conflictingResource?.id
        );
      if (!driverRowElement) return;
      if (conflictingResource?.type === 'CLEAR')
        return (driverRowElement.cls = '');
      driverRowElement.cls = 'conflicted-driver-row';
    };
    handleDriverConflict();
  }, [conflictingResource]);

  const handleDriverAction = (data: {
    action: any;
    data: IRecommendedResource;
  }) => {
    if (data?.action?.key === DRIVER_ACTION_KEYS.CHANGE_DRIVER)
      return setResourceToReassign({
        type: RESOURCES_TYPE.TRIP,
        id: data?.data?.selectedTripData?.[0]?.tripPrimaryId as string,
        label: data?.data?.selectedTripData?.[0]?.tripSeqNumber,
      });
    setResourceToReassign({
      type: RESOURCES_TYPE.DRIVER,
      id: data?.data?.id,
      label: data?.data?.driverGroupDetails?.groupName,
    });
  };

  const handleAllLockResource = (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => {
    {
      if (event) event.stopPropagation();
      if (!ganttRef?.current?.instance) return false;
      rest?.onAllResourceLock?.(!allAssignmentsLocked.current);
    }
  };

  useEffect(() => {
    if (rest?.allRecordsLocked === undefined) return;
    const switchAllLockAction = (lock: boolean): boolean => {
      if (!ganttRef?.current?.instance) return false;
      const allLocked = lock;
      allAssignmentsLocked.current = lock;
      ganttRef.current.instance.columns.allRecords[0].data.allLocked = lock;
      const lockButton =
        ganttRef?.current?.instance?.columns?.allRecords[0]?.element?.querySelector?.(
          'button'
        );
      if (allLocked) lockButton?.classList?.add?.('resource-locked');
      else lockButton?.classList?.remove?.('resource-locked');
      return allLocked;
    };
    switchAllLockAction(rest.allRecordsLocked);
  }, [rest?.allRecordsLocked]);

  const handleChangeZoomLevel = (props: OnAfterZoomChangeProps) =>
    setZoomLevel(props?.zoomLevel);

  const puckTooltipCallback = useCallback(
    (tripData: any): IPuckTooltipPropsOnClick | boolean =>
      getTimelinePuckTooltip({
        tripData,
        hosConstants,
        isTooltipForRecommendation: true,
        onActionClick: () => {
          resetGanttTimeline = false;
          setResourceToReassign({
            id: tripData?.eventRecord?.data?.tripPrimaryId,
            type: RESOURCES_TYPE.TRIP,
            label: tripData?.eventRecord?.data?.tripSeqNumber,
          });
        },
      }),
    [hosConstants]
  );
  const handleSetGanttColumnSize = () => {
    setGanttColumnSize(getGanttColumnSize(ganttRef?.current?.instance));
  };

  useEffect(() => {
    const addColumnResizeListener = () => {
      ganttRef?.current?.instance?.columns?.grid.on('horizontalscroll', () => {
        debounce(handleSetGanttColumnSize, 300);
      });
    };
    addColumnResizeListener();
  }, []);

  const debounce = (method: any, delay: number) => {
    clearTimeout(method._tId);
    method._tId = setTimeout(function () {
      method();
    }, delay);
  };
  useEffect(() => {
    if (!ganttRef?.current?.instance) return;
    ganttColumnSize?.columns?.forEach?.(
      (column: { width: number }, index: number) => {
        if (ganttRef?.current?.instance?.columns?.allRecords?.[index]?.width)
          ganttRef.current.instance.columns.allRecords[index].width =
            column.width;
      }
    );
  }, [JSON.stringify(ganttColumnSize.columns), resourceToReassign?.id]);

  const handleGanttNavigation = (navTo: NavigateToTypes) => {
    if (navTo === 'LEFT')
      return setGanttStartDate(
        subtractDaysFromDate(ganttStartDate, GANTT_WINDOW_SIZE[zoomLevel])
      );
    setGanttStartDate(
      addDaysToDate(ganttStartDate, GANTT_WINDOW_SIZE[zoomLevel])
    );
  };

  const disableGanttResetMemo = useMemo(() => {
    if (
      isDateSame(ganttStartDate, optimizationStartDate) &&
      zoomLevel == DEFAULT_ZOOM_LEVEL
    )
      return true;
    return false;
  }, [ganttStartDate, zoomLevel]);

  const handleReset = () => {
    setGanttStartDate(formatGanttStartDate(optimizationStartDate));
    setZoomLevel(DEFAULT_ZOOM_LEVEL);
  };

  const handleCellClick = (data: {
    event: { clientX: any; clientY: any };
    column?: { data: { type: string; id: string } };
    record: { data: IRecommendedResource };
  }) => {
    if (
      !data?.event?.clientX ||
      !data?.event.clientY ||
      data?.column?.data?.type === 'resourceInfo' ||
      data?.column?.data?.id === '1'
    )
      return;
    const driver: IRecommendedResource = data?.record?.data;
    if (!isResourceTypeAssignedDriver(driver)) return;
    if (!data?.record?.data?.evaluateResponseDTO) return;
    setEvaluationDetails({
      source: 'OPTIMIZATION',
      targetPosition: {
        left: data?.event.clientX,
        top: data?.event.clientY,
      },
      resource: driver,
      optimizationType: OPTIMIZATION_TYPES.RECOMMENDED_RESOURCE,
      evaluationResult: data?.record?.data?.evaluateResponseDTO,
    });
  };

  useEffect(() => {
    if (resourceToReassign && ganttRef?.current?.instance) {
      if (resetGanttTimeline)
        ganttRef?.current?.instance?.scrollToDate(
          ganttRef?.current?.instance?.visibleDateRange?.startDate,
          {
            block: 'start',
          }
        );
    }
    resetGanttTimeline = true;
  }, [resourceToReassign]);

  return (
    <OptymizationGanttChart
      {...optymizationGanttDefaultConfig}
      onCellClick={handleCellClick}
      ref={ganttRef}
      columns={driverColumnsMemo}
      resources={drivers}
      resourceTimeRanges={timeoffs}
      events={trips}
      ganttVisibleDate={ganttStartDate as Date}
      startDate={ganttTimelineRange.startDate}
      endDate={ganttTimelineRange.endDate}
      puckTooltipPropsOnClick={puckTooltipCallback}
      listeners={{
        paint({ firstPaint }: any) {
          if (firstPaint) {
            const allLockBtnEl = document?.getElementById?.(
              OPTIMIZE_GANTT_ALL_LOCK_ID
            ) as any;
            if (allLockBtnEl) allLockBtnEl.onclick = handleAllLockResource;
          }
        },
      }}
      onNavigation={handleGanttNavigation}
      onAfterZoomChange={handleChangeZoomLevel}
      onReset={handleReset}
      timeRanges={getPreferredGanttStartDate()}
      layoutStyle={rest?.layoutStyle}
      viewPreset={VIEW_PRESET_BY_WINDOW[zoomLevel]}
      isLoading={rest?.isLoading}
      ganttControlsConfig={{
        ...optymizeGanttDefaultControlsConfig,
        disableReset: disableGanttResetMemo,
        disableZoomIn: zoomLevel === ZOOM_LEVELS.MAX,
        disableZoomOut: zoomLevel === ZOOM_LEVELS.MIN,
        hideNavigationControl: true,
      }}
    />
  );
};

export default observer(RecommendTripsGantt);
