import { annotateNameAsync } from '../interfaces';
import {
  GetRecurrenceDTO,
  GetRecurrenceRes,
  CreateRecurrenceDTO,
  QueryParams,
} from '../../models';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { IRecurrenceService } from '../interfaces/IRecurrenceSerivice';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

export class RecurrenceService extends IRecurrenceService {
  @annotateNameAsync
  async getRecurrenceData(requestData: GetRecurrenceDTO, endURL: string) {
    try {
      return await httpClient.get<GetRecurrenceRes>(
        endURL,
        requestData,
        GetRecurrenceRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'scheduledPaymentGeneral',
      });
      return Promise.resolve(null);
    }
  }

  async createRecurrenceData(payloadData: CreateRecurrenceDTO, endURL: string) {
    try {
      const response = await httpClient.post(
        endURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'scheduledPaymentAdd',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'scheduledPaymentGeneral',
      });
      return Promise.resolve(null);
    }
  }

  async updateRecurrenceData(payloadData: CreateRecurrenceDTO, endURL: string) {
    try {
      const response = await httpClient.put(
        endURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'scheduledPaymentUpdate',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'scheduledPaymentGeneral',
      });
      return Promise.resolve(null);
    }
  }

  async deleteRecurrenceData(ids: number[], endURL: string) {
    try {
      const response = await httpClient.deleteRaw(
        endURL,
        new QueryParams(),
        ids
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'scheduledPaymentDelete',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'scheduledPaymentGeneral',
      });
      return Promise.resolve(null);
    }
  }
}
