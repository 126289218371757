import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { FC } from 'react';

const AxeleLoadingButton: FC<LoadingButtonProps> = ({ sx, ...props }) => {
  return (
    <LoadingButton
      {...props}
      sx={{
        textTransform: 'unset',
        ...(sx || {}),
      }}
    />
  );
};

export default AxeleLoadingButton;
