import * as yup from 'yup';
import { nullableNumberYup } from '../../../utils';
const errorMsg = {
  required: 'Dispatch revenue is required',
  dispatchRevenue: 'Value must be between 0 and 999,999.9999.',
};

export const TripRouteValidationSchema = yup.object().shape({
  dispatchRevenue: nullableNumberYup()
    .required(errorMsg.required)
    .min(0, errorMsg.dispatchRevenue)
    .max(999999.9999, errorMsg.dispatchRevenue),
});
