import { ComposedError, httpClient } from '../../../axios/axiosInstance';
import { MaintenanceItem } from '../../../models/DTOs/maintenance/maintenanceItem/Model';
import {
  GetAlertTypesRequest,
  GetMaintenanceItemsByOrganizationIdRequest,
} from '../../../models/DTOs/maintenance/maintenanceItem/Requests';
import {
  annotateNameAsync,
  IMaintenanceItemService,
  ServiceError,
} from '../../interfaces';

const basePathApi = '/web/dictionary/api/v2/maintenance/items';
const resources = {
  getByOrganizationId: `${basePathApi}/getByOrganizationId`,
  getAlerts: `${basePathApi}/alerts`,
};

export class MaintenanceItemService extends IMaintenanceItemService {
  @annotateNameAsync
  async getByOrganizationId(): Promise<MaintenanceItem[] | ServiceError> {
    try {
      return httpClient.get<MaintenanceItem[]>(
        resources.getByOrganizationId,
        new GetMaintenanceItemsByOrganizationIdRequest(),
        MaintenanceItem,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async getAlertTypes(): Promise<MaintenanceItem[] | ServiceError> {
    try {
      return httpClient.get<MaintenanceItem[]>(
        resources.getAlerts,
        new GetAlertTypesRequest(),
        MaintenanceItem,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
