import { alpha } from '@mui/material';

export function getGridStyles(isCardView: boolean) {
  return {
    components: {
      // Name of the component
      MuiDataGrid: {
        styleOverrides: {
          // remove borders from root
          root: {
            border: 'none',
            '& .MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer':
              {
                display: 'inherit',
              },
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor: '#F7F7F7',
              width: '16px',
              height: '16px',
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: '27px',
              backgroundColor: 'rgba(0, 0, 0, 0.09)',
              minHeight: 12,
              width: 2,
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
              {
                backgroundColor: '#878787', // ' rgba(0, 0, 0, 0.09);',
              },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
              {
                backgroundColor: '#878787', //  'rgba(0, 0, 0, 0.09);',
              },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
              {
                backgroundColor: '#878787', //  'rgba(0, 0, 0, 0.09);',
              },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: 'transparent',
            },
            '.Mui-selected2': {
              backgroundColor: 'rgba(0, 17, 34, 0.04)',
            },
          },
          row: {
            border: isCardView ? 'none' : '',
            borderStyle: isCardView ? 'none' : 'none none solid none',
            borderWidth: '2px',
            borderColor: '#E0E0E0',
            boxShadow: isCardView
              ? 'inset 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)'
              : '',
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: 'rgba(0, 17, 34, 0.04);',
              boxShadow: 'inset 0px 1px 12px rgba(78, 101, 120, 0.15)',
            },
          },
          cell: {
            border: isCardView ? 'none' : '',
            fontSize: '14px',
            backgroundColor: isCardView ? '#FFFFFF' : '',
            color: 'rgba(0, 17, 34, 0.75)',
            fontWeight: 400,
            '&:focus': {
              border: 'none',
              outline: 'none',
            },
          },
          columnHeader: {
            '&:focus': {
              outline: 'none',
              weight: 400,
            },
            '&:hover': {
              cursor: 'pointer',
              color: '#2B64CB',
            },
          },
          columnHeaders: {
            color: '#1F2E3D !important',
            border: 'none',
            fontSize: '12px',
            fontWeight: 500,
          },
          'pinnedColumns--right': {
            backgroundColor: '#fff',
          },
          'pinnedColumnHeaders--right': {
            backgroundColor: '#F7F7F7',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#2B64CB',
        light: '#699EFF',
        dark: '#114199',
        contrast: '#FFFFFF',
        containedHoverBackground:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #2B64CB',
        outlinedHoverBackground: alpha('#2B64CB', 0.08),
        outlinedRestingBackground: alpha('#2B64CB', 0.5),
        sideTab: alpha('#2B64CB', 0.4),
      },
      error: {
        main: '#F44336',
        dark: '#E31B0C',
        light: '#F88078',
        contrast: '#FFFFFF',
        containedHoverBackground:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #F44336',
        outlinedHoverBackground: alpha('#F44336', 0.08),
        outlinedRestingBorder: alpha('#F44336', 0.5),
        content:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #F44336',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336',
      },
      warning: {
        main: '#ED6C02',
        dark: '#C77700',
        light: '#FFB547',
        contrast: '#FFFFFF',
        containedHoverBackground:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #ED6C02',
        outlinedRestingBorder: alpha('#ED6C02', 0.5),
        outlinedHoverBackground: alpha('#ED6C02', 0.08),
        content:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #ED6C02',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02',
      },
      secondary: {
        main: '#008280',
        light: '#19AFAF',
        dark: '#0C5654',
        contrast: '#FFFFFF',
        containedHoverBackground:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #008280',
        outlinedHoverBackground: alpha('#008280', 0.08),
        outlinedRestingBorder: alpha('#008280', 0.5),
      },
      info: {
        main: '#2196F3',
        dark: '#0B79D0',
        light: '#64B6F7',
        contrast: '#FFFFFF',
        containedHoverBackground:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #2196F3',
        outlinedRestingBorder: alpha('#2196F3', 0.5),
        outlinedHoverBackground: alpha('#2196F3', 0.08),
        content:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #2196F3',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
      },
      success: {
        main: '#4CAF50',
        dark: '#3B873E',
        light: '#7BC67E',
        contrast: '#FFFFFF',
        containedHoverBackground:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #4CAF50',
        outlinedRestingBorder: alpha('#4CAF50', 0.5),
        outlinedHoverBackground: alpha('#4CAF50', 0.08),
        content:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4CAF50',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50',
      },
      text: {
        primary: alpha('#001122', 0.75),
        secondary: alpha('#001122', 0.6),
        disabled: alpha('#001122', 0.35),
      },
      leftMenuCollapsed: {
        axeleIcon: '#4E6578',
        inactive: '#4E6578',
        active: '#2B64CB',
        activeBackground:
          'linear-gradient(270deg, rgba(43, 100, 203, 0.27) 0%, rgba(43, 100, 203, 0) 75%)',
      },
      leftMenuExpanded: {
        inactive: '#669EFF',
        active: '#FFFFFF',
        primaryInactiveHover: alpha('#FFFFFF', 0.15),
        primaryInactivePressed: alpha('#000000', 0.4),
        primaryActiveNormalGradient:
          'linear-gradient(270deg, rgba(255, 255, 255, 0.27) 0%, rgba(255, 255, 255, 0) 100%)',
        primaryActiveHoverGradient:
          'linear-gradient(270deg, rgba(255, 255, 255, 0.6) 0%, rgba(76, 97, 120, 0.4) 100%)',
        primaryActivePressedGradient:
          'linear-gradient(270deg, rgba(217, 217, 217, 0.27) 0%, rgba(0, 0, 0, 0.2) 100%)',
        secondaryInactiveNormal: alpha('#000000', 0.25),
        secondaryInactiveHover: alpha('#FFFFFF', 0.1),
        secondaryInactivePressed: alpha('#000000', 0.5),
        secondaryActiveNormal:
          'linear-gradient(270deg, rgba(121, 132, 150, 0.5) 0%, rgba(8, 13, 18, 0.4) 100%)',
        secondaryActiveHover:
          'linear-gradient(270deg, rgba(240, 240, 240, 0.4) 0%, rgba(48, 59, 77, 0.4) 100%)',
        secondaryActivePressed:
          'linear-gradient(270deg, rgba(90, 98, 112, 0.5) 0%, rgba(0, 0, 0, 0.4) 100%)',
        panelBorder: alpha('#1C2C3D', 0.89),
        panel: alpha('#1C2C3D', 0.89),
      },
      action: {
        active: alpha('#001122', 0.6),
        hover: alpha('#001122', 0.04),
        selected: alpha('#001122', 0.08),
        disabled: alpha('#001122', 0.26),
        disabledBackground: alpha('#001122', 0.12),
        focus: alpha('#001122', 0.12),
      },
      uncategorized: {
        break: '#C81800',
        netProfit: alpha('#669EFF', 0.1),
        canvas: '#E8E9EE',
        panel: '#F7F7F7',
        panelTopGradient:
          'linear-gradient(180deg, rgba(250, 250, 250, 0.6) 0%, rgba(255, 255, 255, 0) 61.72%)',
        card: '#FFFFFF',
        tooltip:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D',
        headerSecondary: '#EEF3FB',
        popup: alpha('#030E18', 0.89), //? no value in light
        accordion: alpha('#FFFFF', 0.08), //? no value in light
      },
      divider: '#E5E5E5',
      other: {
        outlineBorder: '#FFFFFF',
        standartInputLine: '#FFFFFF',
        backdropOverlay: alpha('#001122', 0.5),
        ratingActive: '#FFB400',
        snackbarBackground: '#323232',
        default: '#FAFAFA',
        dataGridCellActive:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D', // ? no value in light
      },
    },
    typography: {
      fontFamily: 'Poppins',
      avatar: {
        fontFamily: 'Poppins',
        fontSize: '1.25rem',
        fontWeight: 400,
        lineHeight: 1.167,
      },
      inputLabel: {
        fontFamily: 'Poppins',
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.167,
      },
      helperText: {
        fontFamily: 'Poppins',
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.167,
      },
      inputText: {
        fontFamily: 'Poppins',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.167,
      },
      tooltip: {
        fontFamily: 'Poppins',
        fontSize: '0.625rem',
        fontWeight: 500,
        lineHeight: 1.167,
      },
      chip: {
        fontFamily: 'Poppins',
        fontSize: '0.8125rem',
        fontWeight: 400,
        lineHeight: 1.167,
      },
      tableHeader: {
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.167,
      },
      alertTitle: {
        fontFamily: 'Poppins',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.167,
      },
      badgeLabel: {
        fontFamily: 'Poppins',
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1.167,
      },
      h1: {
        fontSize: '3rem',
        fontWeight: 'bold',
        lineHeight: 1.5,
        letterSpacing: '0.03em',
      },
      h2: {
        fontSize: '2.8rem',
        fontWeight: 'bold',
        lineHeight: 1.11,
      },
      h3: {
        fontSize: '2.3rem',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: '1.8rem',
        fontWeight: 600,
        lineHeight: 1.1,
      },
      h5: {
        fontSize: '1.4rem',
        fontWeight: 500,
        lineHeight: 1.03,
      },
      h6: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '160%',
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 600,
      },
      subtitle2: {
        fontWeight: 500,
        lineHeight: '143%',
      },
      body1: {
        lineHeight: 1.2,
        fontWeight: 400,
      },
    },
    shape: {
      borderRadius: 6,
    },
  };
}

export function getcardViewStyles(theme: any, isCardView: boolean) {
  return {
    '& .super-app-theme--header': {
      opacity: 0,
    },
    // remove padding at beginning of column header container so that text is inline with column text
    '& .paxton-table--header': {
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
      // set header font styling
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: '16px',
      },
    },
    // Datagrid Row Styling
    '& .paxton-table--row': {
      borderRadius: '12px',
      marginBottom: theme.spacing(2),
      boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.18)',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
      },
    },
    // remove borders and separators
    '& .paxton-table--cell': {
      border: isCardView ? 'none' : '',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnsContainer': {
      border: isCardView ? 'none' : '',
    },
    // '& .MuiDataGrid-viewport': {
    //   maxHeight: 'unset !important',
    // },
    // '& .MuiDataGrid-renderingZone': {
    //   maxHeight: '670px !important', // <= manually setting the max height, but this is not responsive
    // },
  };
}

export const bulkActionStyle = {
  position: 'fixed',
  bottom: 10,
  display: 'flex',
  left: '50%',
  zIndex: 4,
  maxWidth: '900px',
  background: '#1F2E3D',
  boxShadow: '0px -11px 25px rgba(78, 101, 120, 0.25)',
  backdropFilter: 'blur(6px)',
  height: 70,
  borderRadius: '16px',
  '-webkit-transform': 'translate(-50%, -50%)',
  '-moz-transform': 'translate(-50%, -50%)' /* Older Gecko browser */,
  '-ms-transform': 'translate(-50%, -50%)' /* IE9+ */,
  '-o-transform': 'translate(-50%, -50%)',
  transform: 'translate(-50%, -50%)',
};

export const bulkActionTooltipStyle = {
  '& .MuiTooltip-tooltip': {
    borderRadius: '10px',
    backgroundColor: '#1F2E3D',
    marginBottom: '20px !important',
    p: '4px 0 !important',
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  },
};

export const childOptionContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  p: '8px 0px',
};

export const bulkActionChildItemStyle = {
  p: '6px 16px',
  color: '#669EFF',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: 0,
  m: '0 !important',
  ':hover': {
    background: 'rgba(102, 158, 255, 0.26)',
  },
  ':clicked': {
    background: 'rgba(102, 158, 255, 0.5)',
  },
  ':disabled': {
    color: 'rgba(255, 255, 255, 0.35)',
    cursur: 'not-allowed',
  },
};

export const BulkactionContainerStyle = {
  m: 1,
  display: 'flex',
  height: '54px',
  justifyContent: 'center',
  alignItems: 'center',
};

export const BulkactionItemStyle = {
  p: '8px 12px',
  ':hover': {
    border: '1px solid rgba(102, 158, 255, 0.5)',
    background: 'rgba(102, 158, 255, 0.26)',
    borderRadius: '8px',
  },
  ':clicked': {
    background: 'rgba(102, 158, 255, 0.5)',
    borderRadius: '8px',
  },
};

export const BulkactionLabelContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
export const disableSelectionOnClickStyle = {
  '& .MuiDataGrid-row:hover': {
    cursor: 'initial',
    backgroundColor: '#f7f7f7',
    boxShadow: 'inset 0px 0px 0px rgb(78 101 120 / 15%)',
  },
};

export const BulkactionLabelTruncateStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '85%',
};

export const customeTooltipStyles = {
  width: '250px',
  display: 'inline-flex',
  cursor: 'pointer',
  color: '#BDBDBD',
};
export const customeTooltipContainerStyles = {
  fontSize: '14px',
  padding: '5px 20px !important',
  fontWeight: '500',
  color: 'primary.main',
  lineHeight: '140%',
};
export const customeTooltipTextStyles = {
  textDecoration: 'underline',
  fontSize: '14px',
  pt: '4px',
  fontWeight: '500',
};
export const customeTooltipExpandStyles = {
  position: 'absolute',
  right: '6px',
  top: '16px',
  cursor: 'pointer',
};
