export const PossibleResourcesLayout = {
  height: '48%',
  // marginTop: '13px',
  flex: '1 1 0%',
  minHeight: '8em',
  // display: 'block',
};

export const PossibleResourcesWrapper = {
  border: '1px solid rgba(0, 17, 34, 0.26)',
  borderRadius: '8px',
  height: '100%',
};

export const PossibleResourceTextStyles = {
  fontFamily: "'Oxanium'",
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '140%',
  color: 'rgba(4, 0, 34, 0.75)',
};
