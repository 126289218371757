import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function WarningWindow({
  warningList = [],
}: {
  warningList: string[] | { label: string; action: () => {} }[] | undefined;
}) {
  if (!warningList.length) return null;
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <ul
        style={{
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),#FFA7267F',
          borderRadius: '7px',
          padding: '16px',
          listStyle: 'initial',
          paddingLeft: '40px',
          maxHeight: '200px',
          overflowY: 'auto',
        }}
      >
        {warningList?.map((warning, index) => (
          <li key={index}>
            {typeof warning === 'string' ? (
              <Typography variant="subtitle2">{warning}</Typography>
            ) : (
              <>
                <Typography variant="subtitle2">
                  {warning.label}
                  <Button
                    sx={{ textTransform: 'none' }}
                    variant="text"
                    onClick={() => warning.action()}
                  >
                    {t('DriverAssignmentWarningWindowFixNow')}
                  </Button>
                </Typography>
              </>
            )}
          </li>
        ))}
      </ul>
    );
  }
}
