import { Moment } from 'moment';
import { DocumentType } from '../../../subPages/invoices/models/InvoiceModel';
import { QueryParams } from '../commonMixed';

export class GetInvoiceListRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  sort!: string;
}

export class GetInvoiceWarningsRequest extends QueryParams {
  ids!: string[];
}

export class GetInvoiceLineItemsRequest extends QueryParams {
  isInvoiceItem!: boolean;
}

export class UpdateInvoiceRequest extends QueryParams {
  tenderId!: string;
}
export class InvoiceToTurvoRequest extends QueryParams {
  loadId!: string;
}
export class UpdateInvoiceResponse {
  message: string;
  status: string;
}

export class DownloadInvoiceRequest extends QueryParams {
  invoiceDocumentsDTO!: {
    id: string;
    documentIds: string[];
    invoicePdfComplexity: string;
  };
}
export class DownloadInvoicesAsZipRequest extends QueryParams {
  invoiceDocumentsDTO!: {
    id: string;
    documentIds: string[];
    invoicePdfComplexity: string;
  }[];
}

export class PreviewInvoiceRequest extends QueryParams {
  id!: string;
}

export class StatusChangeRequest {
  invoiceIds!: string[];
  paidOn!: Moment;
  invoicedOn!: Moment;
  organizationId!: string;
  status!: string;
}

export class StatusChangeSingleRequest {
  organizationId!: string;
  invoiceId!: string;
  paidOn!: Moment;
  status!: string;
}
export class SendEmailRequest extends QueryParams {
  combinePdfDocuments!: boolean;
  html!: boolean;
  emailSendingRequestDTO!: {
    cc: string[];
    documentList: { id: string }[];
    invoiceList: { id: string; pdfComplexity: string }[];
    message: string;
    subject: string;
    type: string;
    to: string[];
  }[];
}

export class InvoiceListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;
  fetchBySeqNumbers?: string[];
  emailIsSent?: boolean | null;
  loadSeqNumbers?: string[];
  customerNameFilter?: string[];
  driverNameFilter?: string[];
  factoringNameFilter?: string[];
  loadStatusList?: string[];
  referenceIds?: string[];
  originCityStateList?: string[];
  destinationCityStateList?: string[];
  originStateList?: string[];
  destinationStateList?: string[];
  completedFromDate?: string | null;
  completedToDate?: string | null;
  invoicedOnFromDate?: string | null;
  tagIds!: Array<string> | null;
  invoicedOnToDate?: string | null;
  paidOnFromDate?: string | null;
  paidOnToDate?: string | null;
  updatedFromDate?: string | null;
  updatedToDate?: string | null;
  documentNeeded?: boolean | null;
  calculateDetention?: boolean | null;
  isOverdue?: boolean | null;
  terminalIds?: string[] | null;
  invoiceTypeList?: string[];
  invoiceSeqNumbers?: string | null;
  onHoldState?: boolean | null;
  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.sort = dto.sort;
      this.fetchBySeqNumbers = dto.fetchBySeqNumbers;
      this.loadSeqNumbers = dto.loadSeqNumbers;
      this.customerNameFilter = dto.customerNameFilter;
      this.driverNameFilter = dto.driverNameFilter;
      this.factoringNameFilter = dto.factoringNameFilter;
      this.destinationCityStateList = dto.destinationCityStateList;
      this.originStateList = dto.originStateList;
      this.destinationStateList = dto.destinationStateList;
      this.completedFromDate = dto.completedFromDate;
      this.completedToDate = dto.completedToDate;
      this.invoicedOnFromDate = dto.invoicedOnFromDate;
      this.paidOnToDate = dto.paidOnToDate;
      this.updatedFromDate = dto.updatedFromDate;
      this.updatedToDate = dto.updatedToDate;
      this.documentNeeded = dto.documentNeeded;
      this.calculateDetention = dto.calculateDetention;
      this.isOverdue = dto.isOverdue;
      this.terminalIds = dto.terminalIds;
      this.invoiceSeqNumbers = dto.invoiceSeqNumbers;
      this.invoiceTypeList = dto.invoiceTypeList;
      this.onHoldState = dto.onHoldState;
      this.tagIds = dto.tagIds;
    }
  }
}

export class ExportInvoiceListRequest extends QueryParams {
  gridColumnMetadataList!: string[];
  fetchBySeqNumbers?: string[];
  loadSeqNumbers?: string[];
  customerNameFilter?: string[];
  driverNameFilter?: string[];
  factoringNameFilter?: string[];
  loadStatusList?: string[];
  referenceIds?: string[];
  originCityStateList?: string[];
  destinationCityStateList?: string[];
  completedFromDate?: string | null;
  completedToDate?: string | null;
  invoicedOnFromDate?: string | null;
  invoicedOnToDate?: string | null;
  paidOnFromDate?: string | null;
  paidOnToDate?: string | null;
  updatedFromDate?: string | null;
  updatedToDate?: string | null;
  documentNeeded?: boolean;
  calculateDetention?: boolean;
  invoiceTypeList?: string[];
  tagIds?: string[];
  invoiceSeqNumbers?: string | null;
  emailIsSent?: string | null;
}

export class GetInvoiceTotalsRequest extends ExportInvoiceListRequest {}

export class SendFactoringRequest extends QueryParams {}

export class SendFactoringBulkRequest extends QueryParams {
  factoringId!: string;
  factoringNote!: string;
  invoicesCreate!: {
    documentIds: string[];
    factoringInvoiceId: string;
    id: string;
    loadId: string;
  }[];
  sendInvoice!: boolean;
}

export class SendDocumentRequest extends QueryParams {}

export class SendFactoringRequestType extends QueryParams {
  id!: string;
  documentIds!: string[];
  note!: string;
  sendInvoice!: boolean;
  constructor(dto?: any) {
    super();
    if (dto) {
      this.id = dto.invoiceId;
      this.documentIds = dto.documentIds;
      this.note = dto.note;
      this.sendInvoice = dto.sendInvoice;
    }
  }
}

export type FactoringFormType = {
  [key: string]: string | number | null | string[];
};

export class GetFundingAccountsRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  search!: string;
  credentialId!: string;
  excludeFundingIds!: string[];
}

export class RefreshLoadAndTripsDriversRequest extends QueryParams {
  loadId!: string;
}

export class RefreshFactoringStatusRequest extends QueryParams {
  factoringId!: string;
  invoiceFactoringDTO!: { factoringInvoiceId: string; invoiceId: string }[];
}
export class BulkSubmitRequest extends QueryParams {
  factoringId!: string;
  carrierId!: string;
  axeleInvoiceIds!: string[];
  invoiceIds!: string[];
  invoiceNumbers!: string[];
  sameDay!: boolean;
  instructions!: string;
  fundings!: { fundingId: string; amount: string }[];
}

export class DisconnectFromFactoringRequest extends QueryParams {
  loadIds!: string[];
}

export class GetIntegrationsRequest extends QueryParams {
  terminalIds!: string[];
}

export class GetDriverMappingDetailsRequest extends QueryParams {
  factoringId!: string;
  driverIds!: string[];
}

export class SendMultipleEmailRequest extends QueryParams {
  combinePdfDocuments!: boolean;
  html!: boolean;
  emailSendingRequestDTO!: {
    cc: string[];
    documentList: { id: string }[];
    invoiceList: { id: string; pdfComplexity: string }[];
    message: string;
    subject: string;
    type: string;
    to: string[];
  }[];
}

export type IntegrationType = {
  id: string;
  organisationId: number;
  integrationType: string;
  providerId: number;
  isValid: boolean;
  status: string | null;
  lastIntegrationDate: string;
  lastConnectionDate: string;
  terminalIds: string[];
  data: { name: string; value: string }[];
};

export type IntegrationTypeDTO = {
  [key: number]: IntegrationType[];
};

export type IntegrationShortType = {
  terminalIds: string[];
  accountId: string;
  accountName: string;
  lastConnectionDate: string;
};

export class GetInvoiceNotesRequest extends QueryParams {
  loadId!: string;
  constructor(dto: { loadId: string }) {
    super();
    Object.assign(this, dto);
  }
}

export type InvoiceWarningType = {
  [key: string]: {
    loadId: string;
    assignedDocuments: DocumentType[];
    warnings: { name: string; time: number }[];
    missingDocuments: string[];
  };
};

export type DriverMappingType = {
  amount: number;
  mappingCode: string;
  mappingDescription: string;
  mappingId: string;
  mappingName: string;
  driverInvoiceNotes: string | null;
};
export class GetInvoiceSearchLoadsRequest extends QueryParams {
  customerId!: string;
  terminalId!: string;
  pageNumber!: number;
  pageSize!: number;
  sort!: string;
  search!: string;

  constructor(dto) {
    super(true);
    Object.assign(this, dto);
  }
}
export class DeleteInvoiceRequest extends QueryParams {
  id!: string;
}

export class GetloadIdListRequest {
  ids!: Array<string>;
}

export class GetInvoiceActionDataRequest extends QueryParams {
  invoiceId!: string;
}
export class DownloadInvoicePreviewRequest extends QueryParams {
  invoiceId!: string;
  loadId!: string;
  pdfComplexity!: string;
}
export class DownloadInvoicesRequest {
  id!: string;
  documentIds!: string[];
  invoicePdfComplexity!: string;
  organizationId!: string;
}

export type InvoiceSearchLoadsType = {
  calculateDetention: boolean;
  dataExists: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  order: Array<string>;
  content: InvoiceSearchLoadsListType[];
};
export type InvoiceSearchLoadsListType = {
  destinationCity: string;
  destinationState: string;
  invoiceId: string;
  loadId: string;
  loadReferenceId: string;
  loadSeqNumber: string;
  originState: string;
  originCity: string;
  emptyMiles: number;
  loadedMiles: number;
  total: number;
};

export class OnHoldStateInvoiceRequest extends QueryParams {
  id: string;
  onHold: boolean;
  addedNote: boolean;
  constructor(data: { id: string; onHold: boolean; addedNote: boolean }) {
    super();
    this.onHold = data.onHold;
    this.id = data.id;
    this.addedNote = data.addedNote;
  }
}
export class OnHoldStateInvoiceResponse {
  message: string;
}
