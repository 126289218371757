import { SxProps, Theme, createTheme } from '@mui/material';

export const CLASS_NAMES = {
  editIcon: 'ManualDriverSelection-editIcon',
  arrowForwardIcon: 'ManualDriverSelection-arrowForwardIcon',
  driverSelectionCellClassName: 'ManualDriverSelection-cellClassName',
};
export const getStyledManualDriverSelection = (): {
  [key: string]: SxProps;
} => {
  return {
    root: {
      width: '100%',
      height: '100%',
    },
    cell: {
      width: '100%',
      height: '100%',
      [`.${CLASS_NAMES.editIcon}`]: {
        visibility: 'hidden',
      },
      ':hover': {
        [`.${CLASS_NAMES.editIcon}`]: {
          visibility: 'visible',
        },
      },
    },
    editIcon: {
      width: '16px',
      height: '16px',
      color: 'text.disabled',
    },
    SingleAutocomplete: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    SACustomInputProps: {
      height: '30px',
    },
    CustomOption: {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      [`.${CLASS_NAMES.arrowForwardIcon}`]: {
        visibility: 'hidden',
      },
      ':hover': {
        [`.${CLASS_NAMES.arrowForwardIcon}`]: {
          visibility: 'visible',
        },
      },
    },
    CustomOptionContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      direction: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    CustomOptionActions: {
      padding: '16px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    CustomOptionActionButton: {
      fontFamily: "'Poppins'",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '160%',
      textTransform: 'capitalize',
      width: '100%',
      height: '28px',
      borderRadius: '6px',
    },
    arrowForwardIcon: {
      width: '16px',
      height: '16px',
      color: 'text.primary',
    },
  };
};

export const getThemeManualDriverSelection = (currTheme: Theme) => {
  const height = '28px !imporant';
  const MuiInputLabelStyles: SxProps = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
  };
  const MuiInputLabelShrinkStyles: SxProps = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '12px',
  };
  const BorderStyles = {
    '.MuiOutlinedInput-notchedOutline': {},
  };
  const theme = createTheme(currTheme, {
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            ...MuiInputLabelStyles,
            '&.MuiInputLabel-shrink': {
              top: '8px !important',
              ...MuiInputLabelShrinkStyles,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: height,
            ...BorderStyles,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiInputLabel-root': {
              top: '-3px',
              ...MuiInputLabelStyles,
            },
            '.MuiAutocomplete-tag': {
              margin: 0,
            },
            '.MuiChip-label': {
              fontSize: '10px',
            },
            ...BorderStyles,
          },
          inputRoot: {
            height: height,
          },
          input: {
            lineHeight: height,
          },
          hasClearIcon: {
            '.MuiAutocomplete-inputRoot': {
              paddingTop: '4px !important',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '.MuiOutlinedInput-root': {
              height: height,
              ...MuiInputLabelStyles,
              '.MuiTypography-root': {
                ...MuiInputLabelStyles,
              },
            },
            '.MuiInput-input': {
              ...MuiInputLabelStyles,
            },
            ...BorderStyles,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-root': {
              height: height,
            },
          },
        },
      },
    },
  });
  return theme;
};
