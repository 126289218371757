// Anna: this component was already written , I've just made it from HOC to ordinary component
import { Dispatch, SetStateAction, useMemo } from 'react';
import { ESecondaryDetailsPanelType } from '../../../views/dispatch/constants/types';
import SecondaryDetailsPanel, {
  SecondaryDetailsPanelProps,
} from '../../SecondaryDetailsPanel/SecondaryDetailsPanel';

export interface IWithSecondaryDetailsPanelData {
  id?: string;
  data?: Record<string, any>;
  type?: ESecondaryDetailsPanelType;
  options?: Partial<SecondaryDetailsPanelProps>;
}
export interface WithSecondaryDetailsPanelProps {
  secondaryDetailsPanelData: IWithSecondaryDetailsPanelData | null;
  setSecondaryDetailsPanelData: Dispatch<
    SetStateAction<IWithSecondaryDetailsPanelData | null>
  >;
}

const WithSecondaryDetailsPanel = (props: WithSecondaryDetailsPanelProps) => {
  const { secondaryDetailsPanelData, setSecondaryDetailsPanelData } = props;

  const resetSecondaryDetailsPanel = (): void => {
    setSecondaryDetailsPanelData(null);
  };

  const secondaryDetailsPanelSettings = useMemo(() => {
    return {
      type: secondaryDetailsPanelData?.type,
      id: secondaryDetailsPanelData?.id,
      data: secondaryDetailsPanelData?.data,
      onClose: resetSecondaryDetailsPanel,

      //@TODO: set global in secondaryDetailsPanelData.options
      ...secondaryDetailsPanelData?.options,
    };
  }, [
    resetSecondaryDetailsPanel,
    secondaryDetailsPanelData,
    setSecondaryDetailsPanelData,
  ]);

  return (
    <>
      {!!secondaryDetailsPanelData ? (
        <SecondaryDetailsPanel {...secondaryDetailsPanelSettings} />
      ) : (
        <></>
      )}
    </>
  );
  // };
};

export default WithSecondaryDetailsPanel;
