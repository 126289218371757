import { PossibleResourcesLayout } from './possibleResources.style';
import {
  RecommendGanttWithResources,
  RecommendGanttContainerStyles,
} from './recommendTrips.style';

export * from './optymizationModal.style';
export * from './recommendTrips.style';
export * from './footer.styles';

export const RoundedBtnStyles = {
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px -2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: '14px',
  height: '40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '500',
  fontSize: '15px',
  textTransform: 'capitalize',
  lineHeight: '160%',
};

export const GANTT_WITH_RESOURCES_STYLES = {
  RECOMMENDTRIPS_STYLES: RecommendGanttWithResources,
  POSSIBLERESOURCE_STYLES: PossibleResourcesLayout,
};
export const GANTT_WITHOUT_RESOURCES_STYLES = {
  RECOMMENDTRIPS_STYLES: RecommendGanttContainerStyles,
  POSSIBLERESOURCE_STYLES: { display: 'none' },
};
