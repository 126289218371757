import { ComposedError, httpClient } from '../../../axios/axiosInstance';
import { GetMaintenanceEquipmentByOrganizationIdRequest } from '../../../models/DTOs/maintenance/maintenanceEquipment/Requests';
import { MaintenanceVendor } from '../../../models/DTOs/maintenance/maintenanceVendor/Model';
import { PaginatedMaintenanceVendorListRequest } from '../../../models/DTOs/maintenance/maintenanceVendor/Requests';
import { PaginatedResult } from '../../../types';
import {
  annotateNameAsync,
  ServiceError,
  IMaintenanceVendorService,
} from '../../interfaces';

const basePathApi = '/web/expense/api/v2/vendors';
const resources = {
  list: `${basePathApi}/list`,
};

export class MaintenanceVendorService extends IMaintenanceVendorService {
  @annotateNameAsync
  async getPaginatedList(
    requestData: PaginatedMaintenanceVendorListRequest
  ): Promise<PaginatedResult<MaintenanceVendor> | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        resources.list,
        requestData,
        {},
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getByOrganizationId(): Promise<MaintenanceVendor[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        resources.list,
        new GetMaintenanceEquipmentByOrganizationIdRequest()
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
