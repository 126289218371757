import { ServiceError } from './helperTypes';
import { Service } from './Service';
import {
  AccountingDTO,
  ActivateProviderDTO,
  UpdateQBAuthDTO,
  GetQBCustomersDTO,
  GetMapIncomeItemsDTO,
  GetMapPayItemsDTO,
  GetAxeleMapPayItemsDTO,
  GetMapIncomeItemsResDTO,
  SaveIncomeCategoryDTO,
  GetQBOCustomersDTO,
  SendCustomersToQBDTO,
  SendCustomersToQBResDTO,
  ConnectionDTO,
  GetCustomerNameRes,
  GetCustomerName,
  GetQuickbookInvoicesRequest,
  QBInvoiceResult,
  SuppressInvoicesRequest,
  SyncBackQBInvoicesRequest,
  SyncQBInvoicesRequest,
  QBActionResponseType,
  CredentialValidRequestDTO,
  CredentialValidDTO,
  GetIsClassMappingActiveRequest,
  ClassMappingListResponseType,
  ChangeClassMappingTypeRequest,
  RefreshClassMappingTypeRequest,
  ChangeMappingRequest,
  GetClassMappingDropdownRequest,
  ClassMappingDropdownItemType,
} from '../../models';

export abstract class IAccountingService extends Service {
  abstract getAccountingData(
    terminalIds: string[],
    organizationId: number
  ): Promise<AccountingDTO | null | ServiceError>;

  abstract postIntegrationData(
    payload: ActivateProviderDTO
  ): Promise<AccountingDTO | null | ServiceError>;

  abstract updateQBAuth(
    payload: UpdateQBAuthDTO
  ): Promise<AccountingDTO | null | ServiceError>;

  abstract deleteIntegration(
    accoutId?: string,
    organizationId?: number
  ): Promise<any | null | ServiceError>;

  abstract updateIntegration(
    payload: ActivateProviderDTO
  ): Promise<AccountingDTO | null | ServiceError>;

  abstract getMapPayItems(
    AccountCardId: string
  ): Promise<GetMapPayItemsDTO | null>;

  abstract getMapIncomeItems(
    queryData: GetMapIncomeItemsDTO
  ): Promise<GetMapIncomeItemsResDTO | null>;

  abstract getAxeleMapPayItems(): Promise<GetAxeleMapPayItemsDTO | null>;

  abstract updateQBOItemsCategory(
    AccountCardId: string
  ): Promise<GetMapPayItemsDTO | null>;

  abstract savePayItemMapping(
    payload: SaveIncomeCategoryDTO
  ): Promise<GetMapPayItemsDTO | null | ServiceError>;

  abstract getQBOCustomers(
    queryData: GetQBCustomersDTO
  ): Promise<GetQBOCustomersDTO | null>;

  abstract fetchQBOCustomers(
    queryData: GetQBCustomersDTO
  ): Promise<GetQBOCustomersDTO | null>;

  abstract sendCustomersToQB(
    payload: SendCustomersToQBDTO
  ): Promise<SendCustomersToQBResDTO | null | ServiceError>;

  abstract connectToQBOnline(
    qbAccountId: string
  ): Promise<ConnectionDTO | null>;

  abstract getCustomerName(
    requestData: GetCustomerName
  ): Promise<GetCustomerNameRes | ServiceError>;

  abstract getQBInvoices(
    requestData: GetQuickbookInvoicesRequest
  ): Promise<QBInvoiceResult | ServiceError>;

  abstract suppressInvoices(
    requestData: SuppressInvoicesRequest
  ): Promise<QBActionResponseType | ServiceError>;

  abstract syncBackQBInvoices(
    requestData: SyncBackQBInvoicesRequest
  ): Promise<QBActionResponseType | ServiceError>;

  abstract syncQBInvoices(
    requestData: SyncQBInvoicesRequest
  ): Promise<QBActionResponseType | ServiceError>;

  abstract validateQBCredentials(
    requestData: CredentialValidRequestDTO
  ): Promise<CredentialValidDTO | ServiceError>;

  abstract getIsClassMappingActive(
    requestData: GetIsClassMappingActiveRequest
  ): Promise<boolean | ServiceError>;

  abstract getClassMappingList(
    requestData: GetIsClassMappingActiveRequest
  ): Promise<ClassMappingListResponseType | ServiceError>;

  abstract changeClassMappingType(
    requestData: ChangeClassMappingTypeRequest
  ): Promise<ClassMappingListResponseType | ServiceError>;

  abstract refreshClassMappingType(
    requestData: RefreshClassMappingTypeRequest
  ): Promise<ClassMappingListResponseType | ServiceError>;

  abstract mappingChange(
    requestData: ChangeMappingRequest
  ): Promise<boolean | ServiceError>;

  abstract getClassMappingDropdown(
    requestData: GetClassMappingDropdownRequest
  ): Promise<ClassMappingDropdownItemType | ServiceError>;
}
