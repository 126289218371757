import { SummaryConstants } from './summary';
import { ExpensesConstant } from './expenses';
import { InvoicesConstants } from './invoices';
import { SettlementConstants } from './settlements';

export const FinanceConstants = {
  ...SummaryConstants,
  ...ExpensesConstant,
  ...InvoicesConstants,
  ...SettlementConstants,
};
