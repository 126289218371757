import moment, { Moment, MomentInput } from 'moment';
import { ITimeoffDateWithTz } from '../common/TimeoffDialog/types';
import {
  FORM_DATEPICKER_FORMAT,
  UI_DATE_DISPATCH_GANTT_ENTITY_TOOLTIP,
  UI_TIME_24HOURS_TZ_FORMAT,
} from '../constants/date.constants';
const isNullOrEmpty = (value: any): boolean => {
  return value === null || value === '' || value === undefined;
};
export default class TimeoffDateTime {
  /*
   * Duration from start to stop dates
   */
  static duration = ({
    startDate,
    endDate,
  }: {
    startDate: Moment;
    endDate: Moment;
  }): string => {
    const duration = moment(endDate) - moment(startDate);
    return TimeoffDateTime.durationFormat(duration);
  };

  /*
   * duration is timestamp (milliseconds)
   */
  static durationFormat = (timestamp: number): string => {
    const formatedDuration = [];
    const durationObject = moment.duration(timestamp);
    const years = durationObject.years();
    const months = durationObject.months();
    const days = durationObject.days();
    const hours = durationObject.hours();
    const minutes = durationObject.minutes();

    if (years > 0) {
      formatedDuration.push(`${years}y`);
    }
    if (months > 0) {
      formatedDuration.push(`${months}M`);
    }
    if (days > 0) {
      formatedDuration.push(`${days}d`);
    }
    if (hours > 0) {
      formatedDuration.push(`${hours}h`);
    }
    if (minutes > 0) {
      formatedDuration.push(`${minutes}m`);
    }

    return formatedDuration.join(' ');
  };

  static calculateDuration = ({
    startDate,
    endDate,
  }: {
    startDate: Moment;
    endDate: Moment;
  }): {
    duration: string;
    durationInMinutes: number;
  } => {
    let duration = '0',
      durationInMinutes = 0;

    if (startDate && endDate) {
      duration = TimeoffDateTime.duration({ startDate, endDate });
      durationInMinutes = moment(endDate).diff(startDate, 'minutes');
      if (!durationInMinutes) {
        duration = '0';
      }
    }
    return { duration, durationInMinutes };
  };

  static calculateAppointmentDates = ({
    timezone,
    startDatePart = moment(),
    startTimePart,
    endDatePart = moment(),
    endTimePart,
  }: ITimeoffDateWithTz): {
    timezone: string;
    appointmentStartDate: Moment;
    appointmentEndDate: Moment;
  } => {
    //From UI -> to request API
    //Need to convert given datetime to timezone date
    return {
      appointmentStartDate: moment
        .tz(startDatePart, timezone)
        .startOf('days')
        .set('minutes', startTimePart),
      appointmentEndDate: moment
        .tz(endDatePart, timezone)
        .startOf('days')
        .set('minutes', endTimePart),
    };
  };

  static fromAppointmentDateToTimeoffDate = ({
    appointmentStartDate,
    appointmentEndDate,
    timezone,
  }: {
    appointmentStartDate: MomentInput;
    appointmentEndDate: MomentInput;
    timezone: string;
  }): ITimeoffDateWithTz => {
    //From api response -> display on UI
    //Need to convert given datetime to timezone date
    let startDatePart = null;
    let endDatePart = null;
    let startTimePart = 0;
    let endTimePart = 0;
    if (appointmentStartDate) {
      const startDate = moment.tz(appointmentStartDate, timezone);
      startDatePart = startDate.clone().startOf('day').toDate();
      startTimePart = startDate.hours() * 60 + startDate.minutes();
    }

    if (appointmentEndDate) {
      const endDate = moment.tz(appointmentEndDate, timezone);
      endDatePart = endDate.clone().startOf('day').toDate();
      endTimePart = endDate.hours() * 60 + endDate.minutes();
    }

    return {
      startDatePart: startDatePart,
      startTimePart: startTimePart,
      endDatePart: endDatePart,
      endTimePart: endTimePart,
      timezone,
    };
  };

  static dateToISOString = (inputDate: MomentInput): string => {
    if (moment.isMoment(inputDate) || inputDate instanceof Date) {
      return inputDate.toISOString();
    }
    return moment(inputDate).toISOString();
  };

  static startOfDayToUTCString = (inputDate: MomentInput): string => {
    return moment.utc(inputDate).startOf('day').toISOString();
  };

  static hoursToDurationFormat = (hours: number, defaultValue = ''): string => {
    if (isNullOrEmpty(hours)) {
      return defaultValue;
    }
    const timestamp = hours * 60 * 60 * 1000; //milliseconds
    return TimeoffDateTime.durationFormat(timestamp);
  };

  static minutesToDurationFormat = (
    minutes: string | number,
    defaultValue = ''
  ): string => {
    if (isNullOrEmpty(minutes)) {
      return defaultValue;
    }
    const timestamp = Number(minutes) * 60 * 1000; //milliseconds
    return TimeoffDateTime.durationFormat(timestamp);
  };

  static rangeDateToTime = (
    startDate: MomentInput,
    endDate: MomentInput
  ): string => {
    return TimeoffDateTime.duration({
      startDate: moment(startDate),
      endDate: moment(endDate),
    });
  };

  static dateToFormat({
    value,
    format = FORM_DATEPICKER_FORMAT,
    timezone = '',
  }: {
    value: MomentInput;
    format: string;
    timezone?: string;
  }): string {
    if (!isNullOrEmpty(value)) {
      if (timezone) {
        return moment(value).tz(timezone).format(format);
      }
      return moment(value).format(format);
    }
    return '';
  }

  static daysDiff = ({
    endDate,
    startDate,
    timezone,
  }: {
    endDate: MomentInput;
    startDate: MomentInput;
    timezone?: string;
  }): number => {
    const endDateWithTz = timezone
      ? moment(endDate).tz(timezone, true).startOf('day')
      : moment(endDate).startOf('day');
    const startDateWithTz = timezone
      ? moment(startDate).tz(timezone, true).startOf('day')
      : moment(startDate).startOf('day');

    return endDateWithTz.diff(startDateWithTz, 'days');
  };

  static getDateTimeDisplayHumanReadable = ({
    dateTime,
    timezone,
    dateFormat,
    timeFormat,
  }: {
    dateTime: MomentInput;
    timezone?: string;
    dateFormat: string;
    timeFormat: string;
  }): string => {
    const daysDiff = TimeoffDateTime.daysDiff({
      endDate: dateTime,
      startDate: moment(),
      timezone,
    });

    const dateText =
      {
        '-1': 'Yesterday',
        '0': 'Today',
        '1': 'Tomorrow',
      }[daysDiff] || '';

    if (dateText) {
      return `${dateText}, ${TimeoffDateTime.dateToFormat({
        value: dateTime,
        format: timeFormat,
        timezone,
      })}`;
    }

    return TimeoffDateTime.dateToFormat({
      value: dateTime,
      format: dateFormat,
      timezone,
    });
  };

  static getDateTimeDisplayTooltip = ({
    dateTime,
    timezone,
    defaultValue = '',
  }: {
    dateTime: MomentInput;
    timezone?: string;
    defaultValue?: string;
  }): string => {
    if (dateTime === null || dateTime === '') {
      return defaultValue;
    }
    return TimeoffDateTime.getDateTimeDisplayHumanReadable({
      dateTime,
      timezone,
      dateFormat: UI_DATE_DISPATCH_GANTT_ENTITY_TOOLTIP,
      timeFormat: UI_TIME_24HOURS_TZ_FORMAT,
    });
  };
}
