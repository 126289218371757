import axiosInstance, {
  ComposedError,
  httpClient,
} from '../../axios/axiosInstance';
import {
  CreateAdvanceLoadReportRequest,
  CreateAdvanceLoadResult,
  DoSearchCustomerList,
  DoSearchCustomerNameRequest,
  DoSearchDispatcherList,
  DoSearchDispatcherNameRequest,
  DoSearchDriverList,
  DoSearchDriverNameRequest,
  DoSearchTractorList,
  DoSearchTractorNameRequest,
  DoSearchTrailerList,
  DoSearchTrailerNameRequest,
  ExportExcelQuery,
  ExportExcelQueryExcel,
  GetDriverTimeOffRequest,
  GetQuaInfoForReportsRequest,
  PaginatedBasicReportsList,
  PaginatedBasicReportsRequest,
  QueryParams,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { docType } from '../../utils/Doc';
import {
  IBasicReportsService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';

const routes = {
  getBasicReports: {
    driver: 'web/interload/api/v2/drivers/list',
    customer: 'web/interload/api/v2/customers/list',
    tractor: 'web/interload/api/v2/tractors/list',
    trailer: 'web/interload/api/v2/trailers/list',
    dispatcher: 'web/interload/api/v2/dispatchers/list',
  },
  doSearchDriversName: '/web/people/api/v2/organization/summary/list',
  doSearchCustomersName: '/web/customer/api/v2/customers/name/dropdown',
  doSearchTractorsName: '/web/asset/api/v2/assets/tractors/base-details',
  doSearchTrailersName: '/web/asset/api/v2/assets/trailers/base-details',
  doSearchDispatchersName: '/web/people/api/v2/info/user/name/dropdown',
  exportBasicReportFilterSettings: {
    driver: '/web/interload/api/v2/drivers/export-to-excel',
    customer: '/web/interload/api/v2/customers/export-to-excel',
    tractor: '/web/interload/api/v2/tractors/export-to-excel',
    trailer: '/web/interload/api/v2/trailers/export-to-excel',
    dispatcher: '/web/interload/api/v2/dispatchers/export-to-excel',
  },
  advancedReport: {
    loadReport: '/web/interload/api/v2/advanced/reports/load/comprehensive',
    reportLogs: '/web/interload/api/v2/reports/logs',
    monthly: '/web/interload/api/v2/advanced/reports/trend/monthly',
    weekly: '/web/interload/api/v2/advanced/reports/trend/weekly',
    iftaURL: '/web/expense/api/v2/iftaReport/download',
  },
};

export class BasicReportsService extends IBasicReportsService {
  @annotateNameAsync
  async getPaginatedBasicReportsList(
    requestData: PaginatedBasicReportsRequest
  ) {
    const route = routes.getBasicReports[requestData.reportKey];
    const params = {
      organizationId: requestData.organizationId,
      startDate: requestData.startDate || '',
      endDate: requestData.endDate || '',
      sort: requestData.sort || undefined,
      scheduleType: requestData.scheduleType,
      dispatchType: requestData.dispatchType,
      terminalId: requestData.terminalId || undefined,
      ids: requestData[requestData.reportKey + 'Ids'] || [],
    };
    try {
      const response = await httpClient.post<PaginatedBasicReportsList>(
        route,
        undefined,
        params,
        false,
        false,
        undefined,
        true
      );
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'failedData',
      });
      return Promise.resolve({});
    }
  }

  @annotateNameAsync
  async doSearchDriverName(
    requestData: DoSearchDriverNameRequest
  ): Promise<DoSearchDriverList | null | ServiceError> {
    const params = requestData.getParamsFromRequest();
    const route = routes.doSearchDriversName;
    try {
      const response = await axiosInstance.get(route, { params });
      return Promise.resolve(response?.data?.content || []);
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async doSearchCustomerName(
    requestData: DoSearchCustomerNameRequest
  ): Promise<DoSearchCustomerList | null | ServiceError> {
    const params = requestData.getParamsFromRequest();
    const route = routes.doSearchCustomersName;
    try {
      const response = await axiosInstance.get(route, { params });
      return Promise.resolve(response?.data?.content || []);
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async doSearchTractorName(
    requestData: DoSearchTractorNameRequest
  ): Promise<DoSearchTractorList | null | ServiceError> {
    const params = requestData.getParamsFromRequest();
    const route = routes.doSearchTractorsName;
    try {
      const response = await axiosInstance.get(route, { params });
      return Promise.resolve(response?.data?.content || []);
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async doSearchTrailerName(
    requestData: DoSearchTrailerNameRequest
  ): Promise<DoSearchTrailerList | null | ServiceError> {
    const params = requestData.getParamsFromRequest();
    const route = routes.doSearchTrailersName;
    try {
      const response = await axiosInstance.get(route, { params });
      return Promise.resolve(response?.data?.content || []);
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async doSearchDispatcherName(
    requestData: DoSearchDispatcherNameRequest
  ): Promise<DoSearchDispatcherList | null | ServiceError> {
    const params = requestData.getParamsFromRequest();
    const route = routes.doSearchDispatchersName;
    try {
      const response = await axiosInstance.get(route, { params });
      return Promise.resolve(
        response?.data?.content?.map((d) => ({
          ...d,
          fullName: d.firstname + ' ' + d.lastname,
        })) || []
      );
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async getExportExcel(
    requestData: ExportExcelQuery
  ): Promise<ExportExcelQueryExcel | null | ServiceError> {
    const route = routes.exportBasicReportFilterSettings[requestData.reportKey];
    const params = {
      organizationId: requestData.organizationId,
      startDate: requestData.startDate,
      endDate: requestData.endDate,
      sort: requestData.sort || undefined,
      terminalId: requestData.terminalId || undefined,
      scheduleType: requestData.scheduleType,
      dispatchType: requestData.dispatchType,
      ids: requestData[requestData.reportKey + 'Ids'],
    };
    try {
      const response = await axiosInstance.post(route, params);
      return Promise.resolve(response?.data || {});
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'download',
      });
      return Promise.resolve({});
    }
  }

  @annotateNameAsync
  async createAdvanceLoadReport(
    requestData: CreateAdvanceLoadReportRequest
  ): Promise<CreateAdvanceLoadResult> {
    try {
      const queryParams: any = new QueryParams(true);
      if (requestData.isTrend) {
        const route = routes.advancedReport[requestData.type];
        return await httpClient.post<CreateAdvanceLoadResult>(
          route,
          queryParams,
          requestData,
          CreateAdvanceLoadReportRequest,
          undefined,
          // requestData.type !== routes.advancedReport.monthly
          undefined,
          true
        );
      } else {
        const route = routes.advancedReport.loadReport;
        return await httpClient.post(
          route,
          undefined,
          requestData,
          CreateAdvanceLoadResult,
          undefined,
          undefined,
          true
        );
      }
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'download',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async createIFTAReport(
    requestData: GetQuaInfoForReportsRequest
  ): Promise<docType | ServiceError> {
    try {
      return await httpClient.get<docType>(
        routes.advancedReport.iftaURL,
        requestData,
        CreateAdvanceLoadReportRequest,
        true,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'download',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async createDriverTimeOffReport(
    requestData: GetDriverTimeOffRequest
  ): Promise<docType | ServiceError> {
    try {
      const queryParams: any = new QueryParams(true);

      return await httpClient.post<docType>(
        '/web/interload/api/v2/advanced/reports/driver/timeoff',
        queryParams,
        requestData,
        CreateAdvanceLoadReportRequest,
        undefined,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      const errMessage = this.getServiceError(composedError);
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: errMessage?.error?.response?.data,
      });
      return errMessage;
    }
  }
}
