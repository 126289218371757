import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ActionIconButton from './ActionIconButton';
import { Theme, useTheme } from '@mui/material';
import { RejectButtonStyles } from '../../styles';

interface IRejectButton {
  onClick?: () => void;
  isRejected?: boolean;
  disabled?: boolean;
  variant?: 'contained' | 'outlined';
  style?: object;
  label?: string;
  iconStyles?: object;
  id?: string;
}

const RejectButton: React.FC<IRejectButton> = (props) => {
  const theme = useTheme();
  return (
    <ActionIconButton
      id={props?.id}
      variant={props?.variant ?? props.isRejected ? 'contained' : 'outlined'}
      color={props.isRejected || props.disabled ? 'error' : 'inherit'}
      style={{
        ...RejectButtonStyles(
          theme,
          Boolean(props?.variant) || props.isRejected
        ),
        ...props?.style,
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <>
        <CloseOutlinedIcon
          sx={{
            width: '14px',
            height: '14px',
            ...props?.iconStyles,
            color:
              props.isRejected || props.disabled
                ? 'inherit'
                : theme?.palette?.error?.contrastText,
          }}
        />
        {props?.label}
      </>
    </ActionIconButton>
  );
};

export default RejectButton;
