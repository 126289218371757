import { Grid, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { locationService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import {
  GetTimezoneByCoordsRequest,
  GetTimezoneByCoordsResponse,
} from '../../models';
import { CloseIcon } from '../../ui-kit/components/Assets';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../ui-kit/components/IconsComponent';
import fetchAddress from '../../utils/fetchAddress';
import { addressType } from '../Ui/AddressField/constants';
import {
  StyledDialog,
  center,
  geoCodingDialogBox,
  geoCodingDialogContent,
  geoCodingDiv,
  geoCodingPrevue,
  geoCodingPrevueSave,
  geoCodingText,
  geofenceType,
  propsType,
  textInputSxPropsGeocoding,
} from './GeoCodingCustomStyle';
import Trimblemaps from './Trimblemaps';
import './geoCodingStyle.css';
import { t } from 'i18next';

const GeoCodingModelPopup = ({
  address,
  callBackAddress,
  valueName,
}: propsType) => {
  const [isDialogView, setDialogView] = useState<boolean>(false);
  const [addressData, setAddressData] = useState<addressType>();
  const [geofenceData, setGeofenceData] = useState<geofenceType>();

  useEffect(() => {
    const addressData = address;
    const data = {
      center: addressData?.center,
      geoCoordinates: addressData?.geoCoordinates,
    };
    data && setGeofenceData(data);
    setAddressData(addressData);
  }, [address]);

  const getAddressDetails = async (data: center) => {
    const queryString = `${data.lat},${data.lng}`;
    const locations = await fetchAddress(queryString);
    const timezoneData: GetTimezoneByCoordsResponse | ServiceError | null =
      await locationService.getTimezoneByCoords(
        new GetTimezoneByCoordsRequest({
          latitude: data.lat,
          longitude: data.lng,
        })
      );

    if (locations && timezoneData) {
      const newAddress: addressType = {
        fullAddress: locations[0]?.ShortString || '',
        address: locations[0]?.ShortString,
        city: locations[0]?.Address?.City,
        state: locations[0]?.Address?.State,
        streetAddress: locations[0]?.Address?.StreetAddress,
        streetAddress2: '',
        geoCoordinates: geofenceData?.geoCoordinates,
        center: {
          lat: locations[0]?.Coords.Lat,
          lng: locations[0]?.Coords.Lon,
        },
        zipcode: locations[0]?.Address?.Zip,
        timezone: timezoneData?.zoneName,
      };
      callBackAddress(cloneDeep(newAddress));
      newAddress.geoCoordinates = geofenceData?.geoCoordinates || [];
      setAddressData(cloneDeep(newAddress));
      setDialogView(false);
    }
  };
  const renderAddressMap = (data?: addressType, readOnly?: boolean) => {
    return (
      <Trimblemaps
        addressData={data}
        readOnly={readOnly}
        valueName={valueName}
        getGeoFenceData={(geoCoordinates: Array<Array<number>>) => {
          if (!geofenceData) return;
          const geoData = geofenceData;
          geoData.geoCoordinates = geoCoordinates;
          setGeofenceData(geoData);
        }}
        getGeoCodingData={(center) => {
          if (!geofenceData) return;
          const geoData = geofenceData;
          geoData.center = center;
          setGeofenceData(geoData);
        }}
      />
    );
  };
  return (
    <>
      <Grid
        container
        sx={geoCodingDiv}
        spacing={2}
        onClick={() => {
          setDialogView(true);
        }}
      >
        <Grid item xs={12} sx={{ pointeEevents: 'none' }}>
          {renderAddressMap(addressData, true)}
        </Grid>
        <Box sx={geoCodingText}>
          <ButtonImproved
            variant="outlined"
            disabled
            label={
              addressData?.address
                ? t('TripAddNewLoadOption3Editlocationgeofence')
                : t('TripAddNewLoadOption3Addlocationgeofence')
            }
            startIcon={
              <IconsComponent
                iconName="EditLocationOutlined"
                source="MUIcons"
              />
            }
            styleProps={textInputSxPropsGeocoding}
          />
        </Box>
      </Grid>

      <StyledDialog open={isDialogView}>
        <Dialog open={isDialogView} maxWidth={'md'} fullWidth={true}>
          <DialogContent sx={{ padding: '0px 0px 20px 0px!important' }}>
            <Box sx={geoCodingDialogBox}>
              <Box style={geoCodingDialogContent}>
                <Typography sx={{ color: '#ffffff !important' }}>
                  {t('TripAddNewLoadOption3SetLocationBoundary')}
                </Typography>
                <Box style={{ display: 'flex' }}>
                  <IconButton
                    role="button"
                    onClick={() => {
                      setDialogView(false);
                    }}
                  >
                    <CloseIcon color="#fff" width={14} height={14} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Grid container sx={geoCodingPrevue} spacing={2}>
              <Grid item xs={12}>
                {renderAddressMap(addressData, false)}
              </Grid>
            </Grid>

            <Grid item xs={12} px={2} display="flex" justifyContent="end">
              <ButtonImproved
                variant="outlined"
                onClick={() => {
                  setDialogView(false);
                }}
                label={t('TripAddNewLoadOption3Cancel')}
                styleProps={geoCodingPrevueSave}
              />
              <ButtonImproved
                onClick={() => {
                  geofenceData && getAddressDetails(geofenceData.center);
                }}
                variant="contained"
                label={t('TripAddNewLoadOption3Save')}
                styleProps={{
                  padding: '7px 25px',
                }}
              />
            </Grid>
          </DialogContent>
        </Dialog>
      </StyledDialog>
    </>
  );
};
export default GeoCodingModelPopup;
