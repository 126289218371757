import AlertComponent from '../views/settings/alerts';
import { AXELE_PERMISSION_TYPE, Permission } from '../common/Permission';

export const Alert = () => {
  return (
    <Permission includes={[AXELE_PERMISSION_TYPE.ALERTS_VIEW]}>
      <AlertComponent />
    </Permission>
  );
};

export default Alert;
