import { Stack } from '@mui/material';
import { numberWithThousandSeparator } from '../../../../../utils';
import KPIsInfo from '../../../../Ui/KPIsInfo/KPIsInfo';
import React from 'react';
import { t } from 'i18next';
export default function KPI({
  kpiInfo,
  loadType,
  appendNodeRender,
}: {
  kpiInfo: {
    stopsNumber: number;
    loadedMiles: number;
  };
  loadType?: string;
  appendNodeRender: () => JSX.Element;
}) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <KPIsInfo
        title={`${
          loadType && loadType != 'MANIFEST'
            ? t('loadsRoutesLoadedMiles')
            : t('loadsRoutesTripMiles')
        }`}
        value={numberWithThousandSeparator(kpiInfo.loadedMiles)}
      />
      <KPIsInfo
        title={t('loadsRoutesNumberOfStops')}
        value={numberWithThousandSeparator(kpiInfo.stopsNumber)}
      />
      {appendNodeRender?.()}
    </Stack>
  );
}
