import React, { FC } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import { ToolTipReloadScore } from './tooltip';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedTooltips from '../../../../ui-kit/components/AxeleTooltip';
import { ILoadsearchTableItem } from '../../loadTypes';

type ReloadScoreProps = ILoadsearchTableItem['ReloadScore'];

const ReloadScore: FC<ReloadScoreProps> = (ReloadScore) => {
  return (
    <CustomizedTooltips
      placement={'bottom'}
      titleFirstLine={'firstline'}
      titleSecondLine={'secondline'}
      arrow={true}
      color={'primary'}
      backgroundColor={'transparent'}
      enterDelay={20}
      leaveDelay={10}
      titleFirstIcon={<SettingsOutlinedIcon />}
      titleSecondIcon={<DnsOutlinedIcon />}
      isCustomizationTitle={true}
      component={<ToolTipReloadScore {...ReloadScore} />}
    >
      <Box sx={{ margin: 3 }}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            color={
              ReloadScore.value > 3
                ? 'primary'
                : ReloadScore.value > 2
                ? 'warning'
                : 'error'
            }
            variant="determinate"
            value={ReloadScore.subValue}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontSize: '12px' }}
            >{`${ReloadScore.value ? ReloadScore.value : 0}`}</Typography>
          </Box>
        </Box>
      </Box>
    </CustomizedTooltips>
  );
};

export default React.memo(ReloadScore);
