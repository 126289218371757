import Box from '@mui/material/Box';

interface IProps {
  mainKpi: string;
  secondaryKpi: string;
  styles?: {};
}

// @TODO: Nvard - remove in case in develop branch some component like this will be pushed earlier
export const KPIInfoCard = ({ mainKpi, secondaryKpi, styles }: IProps) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => {
          return `${theme.palette.uncategorized?.oldHeaderSecondary}`;
        },
        borderRadius: '8px',
        opacity: '0.5px',
        padding: '8px 16px',
        minWidth: '225px',
        marginright: '16px',
        ...styles,
      }}
    >
      <Box
        sx={{
          color: 'primary.main',
          fontSize: '20px',
          fontWeight: 600,
        }}
      >
        {mainKpi}
      </Box>
      <Box sx={{ color: 'text.secondary', fontSize: '14px', fontWeight: 500 }}>
        {secondaryKpi}
      </Box>
    </Box>
  );
};
