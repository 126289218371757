import { TagListComponent } from '@/views/customTags/customTagChip';
import { Box, Stack, Typography } from '@mui/material';
import { TooltipActionType } from '@optym/gantt';
import { t } from 'i18next';
import OptymLogo from '../../_assets/static/svgs/OptymLogo';
import { DISPATCH_ELEMENTS, LOADAI_ELEMENTS } from '../../constants/elementIds';
import { TRIP_TOOLTOP_ACTION_KEYS } from '../../constants/gantt/columnTooltip.config';
import {
  EMPTY_TRIP_ACTION_KEYS,
  ESecondaryDetailsPanelType,
  TIMEOFF_TOOLTIP_TYPE,
  TRIP_STATUSES,
} from '../../constants/gantt/gantt.const';
import {
  HosConstantsType,
  ScheduleDriverType,
  ScheduleLocationType,
  ScheduleTimeoffsType,
  ScheduleTripType,
} from '../../types/DispatchTypes';
import { isTripRevertToAvailableAllowed } from './gantt.service';
import { getTripWarnings } from './ganttWarnings.service';
import {
  appendTextToDigit,
  getDifference,
  getHourFromMinutes,
  getTimeDetailsFromMinutes,
  toFixDigit,
} from './utility.service';

export const getPuckTooltipProps = (tripSchedule: {
  tripData: {
    eventRecord: { data: ScheduleTripType };
    elementType?: string;
    resourceTimeRangeRecord?: any;
    resourceRecord?: any;
  };
  hosConstants: HosConstantsType;
  hasLoadCRUDPermission?: boolean;
  hasBulkOptimizationPermission?: boolean;
  allowActions?: boolean;
}) => {
  const { tripData, hosConstants } = tripSchedule;
  if (tripData?.elementType === TIMEOFF_TOOLTIP_TYPE) {
    return getTimeOffTooltipProps(tripData?.resourceTimeRangeRecord?.data);
  }
  const data = tripData?.eventRecord?.data;
  if (isTripTypeGap(data)) {
    return getEmptyTripTooltipProps({
      data,
      hosConstants,
      hasLoadCRUDPermission: tripSchedule?.hasLoadCRUDPermission,
      hasBulkOptimizationPermission:
        tripSchedule?.hasBulkOptimizationPermission,
      allowActions: tripSchedule?.allowActions,
    });
  }
  return getTripTooltip(data, tripData?.resourceRecord?.data);
};

export const getTimeOffTooltipProps = (data: ScheduleTimeoffsType) => {
  return {
    title: `Time Off: ${data?.destination?.city},  ${data?.destination?.state}`,
    displayStatus: data?.status?.toLowerCase?.(),
    statusContainerStyles: { width: 'max-content' },
    tooltipTitleContainerStyles: { alignItems: 'flex-start' },
    subTitles: generateTimoffSubtitles(data),
    status: data?.status?.toUpperCase(),
    footer: {
      element: (
        <Box
          style={{ padding: '16px', background: 'rgba(43, 100, 203, 0.08)' }}
        >
          {data?.miles! >= 0 && (
            <Typography style={{ fontFamily: 'Poppins' }}>
              <span style={{ fontSize: '10px', color: 'rgba(4, 0, 34, 0.75)' }}>
                {t('milestoLocation')}:
              </span>
              <span
                style={{
                  fontSize: '12px',
                  color: 'rgba(4, 0, 34, 0.75)',
                  fontWeight: 700,
                  marginLeft: '3px',
                }}
              >
                {appendTextToDigit({
                  val: data?.miles!,
                  postfixLabel: ' mi',
                })}
              </span>
            </Typography>
          )}

          {data?.driveTimeDisplay && (
            <Typography style={{ fontFamily: 'Poppins' }}>
              <span style={{ fontSize: '10px', color: 'rgba(4, 0, 34, 0.75)' }}>
                {t('driveTimetoLocation')}:
              </span>
              <span
                style={{
                  fontSize: '12px',
                  color: 'rgba(4, 0, 34, 0.75)',
                  fontWeight: 700,
                  marginLeft: '3px',
                }}
              >
                {data?.driveTimeDisplay}
              </span>
            </Typography>
          )}
        </Box>
      ),
    },
  };
};

const getEmptyTripTooltipProps = ({
  data,
  hosConstants,
  hasLoadCRUDPermission = false,
  hasBulkOptimizationPermission,
  allowActions = true,
}: {
  data: ScheduleTripType;
  hosConstants: HosConstantsType;
  hasLoadCRUDPermission?: boolean;
  hasBulkOptimizationPermission?: boolean;
  allowActions?: boolean;
}) => {
  const gapTooltipProps = {
    title: data?.destination
      ? `${data?.travelTimeDisplay} ${t('gapTooltipPropsGap')}`
      : t('gapTooltipPropsAvailabilityStart'),
    subTitles: [
      {
        key: t('gapTooltipPropsGapStart'),
        additionalValue: `${data?.origin?.city ?? ''}${
          data?.origin?.state ? ', ' + data?.origin?.state : ''
        }`,
        subValue: (data?.origin as any)?.[data?.origin?.dates?.[0]?.key as any],
      },
    ] as any,
    footer: {},
    hosDetails: data?.ignoreHOSRestrictions
      ? null
      : {
          title: t('gapTooltipPropsHOSatGapStart'),
          isOutDated: data?.isHOSOutdated,
          progress: generateHOSProgress(data, hosConstants),
          caption: `${data?.lastUpdatedHOSStatus ?? ''}`,
        },
    actions: allowActions
      ? getEmptyTripActions({
          hasLoadCRUDPermission,
          hasBulkOptimizationPermission,
        })
      : [],
  };
  if (!isEndGap(data)) {
    gapTooltipProps.subTitles = [
      ...(gapTooltipProps?.subTitles ?? []),
      ...generateGapAdditionalSubtitles(data),
    ];
  }
  return gapTooltipProps;
};

const getEmptyTripActions = ({
  hasLoadCRUDPermission,
  hasBulkOptimizationPermission,
}: {
  hasLoadCRUDPermission?: boolean;
  hasBulkOptimizationPermission?: boolean;
}) => {
  const actions = [];
  const assignTrip = {
    id: '1',
    key: EMPTY_TRIP_ACTION_KEYS.ASSIGN,
    label: t('getEmptyTripActionsAssignTrip'),
    btnId: DISPATCH_ELEMENTS.GAP_TOOLTIP_ASSIGN_TRIP,
  };
  const optimizationAction = {
    id: '2',
    key: EMPTY_TRIP_ACTION_KEYS.OPTIMIZE,
    btnId: LOADAI_ELEMENTS.GAP_RECOMMEND_TRIPS,
    label: t('getEmptyTripActionsRecommendTrips'),
    variant: 'outlined',
    buttonProps: { startIcon: <OptymLogo /> },
  };
  const searchLoad = {
    id: '3',
    key: EMPTY_TRIP_ACTION_KEYS.SEARCH,
    label: t('getEmptyTripActionsSearchLoadboards'),
    variant: 'outlined',
  };
  if (hasLoadCRUDPermission) actions.push(assignTrip);
  if (hasBulkOptimizationPermission) actions.push(optimizationAction);
  actions.push(searchLoad);
  return actions;
};

export const isEndGap = (trip: ScheduleTripType): boolean => {
  if (trip?.destination && trip?.endDate) return false;
  return true;
};

export const tooltiptag = (option: any) => {
  if (option?.loads?.length === 0) {
    return null;
  }
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      gap={1}
      sx={{ mt: '5px', mb: '5px', padding: '0 16px' }}
    >
      <TagListComponent tagList={option?.tags || []} />
    </Stack>
  );
};

export const getTripTooltip = (data: ScheduleTripType, resourceRecord = {}) => {
  const actions: Array<TooltipActionType> = [
    {
      id: '1',
      label: t('getTripTooltipViewDetails'),
      key: TRIP_TOOLTOP_ACTION_KEYS.VIEW_DETAILS,
    },
  ];
  if (isTripRevertToAvailableAllowed(data))
    actions.push({
      id: '2',
      label: t('getTripTooltipReverttoAvailable'),
      key: TRIP_TOOLTOP_ACTION_KEYS.UNASSIGN,
      variant: 'outlined',
      btnTextStyles: { textTransform: 'initial!important' },
    });
  return {
    title: `${t('getTripTooltipTrip')}: ${data?.tripSeqNumber ?? ''}`,
    tags: tooltiptag(data),
    displayStatus: data?.ganttTooltipStatus?.length
      ? data?.ganttTooltipStatus
      : data?.status?.toLowerCase?.(),
    statusContainerStyles: { maxWidth: '160px' },
    statusStyle: {
      textTransform: data?.ganttTooltipStatus?.length
        ? 'inherit'
        : 'capitalize',
    },
    status: data?.status,
    subTitles: generateTripSubtitles(data),
    trips: {
      tripData: generateTripData(data),
    },
    kpis: {
      content: generateKpiContent(data, resourceRecord),
    },
    actions,
    footer: getTripWarnings(data),
    onHold: data?.onHold,
  };
};

const getHOSValue = (total: string | number, completed: number | string) => {
  if (isNaN(Number(total)) || isNaN(Number(completed))) return 0;
  const value = Math.round((Number(completed) / Number(total)) * 100);
  return value > 0 ? (value <= 100 ? value : 100) : 0;
};

export const getHOSLabelTime = (mins: string | number) => {
  if (Number(mins) < 0) return '0m';
  const { days, hours, minutes } = getTimeDetailsFromMinutes(Number(mins));
  if (days <= 0) {
    if (hours <= 0) return `${minutes}m`;
    return `${hours}h`;
  }
  if (hours <= 0) return `${days}d`;
  return `${days}d`;
};

export const mapTripTimes = (
  keys: ScheduleLocationType['dates'],
  data: any
) => {
  const mappedDates: Array<{
    key?: string;
    text: string;
  }> = [];
  if (!keys?.length) return mappedDates;
  for (let i = 0; i < keys.length; i += 2) {
    if (!keys?.[i]) break;
    let startTime = data[keys?.[i]?.key];
    if (i === 0 && startTime?.length) {
      let charsToTrim = 0;
      for (let i = startTime.length; i >= 0; i--) {
        if (startTime[i] === ' ') break;
        charsToTrim++;
      }
      startTime = startTime.slice(0, startTime.length - charsToTrim + 1);
    }
    const endTime = data[keys?.[i + 1]?.key];
    mappedDates.push({
      key: `${keys[i]?.title}:`,
      text: `${startTime || ''}${endTime ? '- ' + endTime : ''}`,
    });
  }
  return mappedDates;
};

export const convertMinsToDaysAndHours = (mins: number): string => {
  if (!mins) return '';
  let { hours, days, minutes } = getTimeDetailsFromMinutes(mins) as any;
  days = days <= 0 ? '' : `${days}d `;
  hours = hours <= 0 ? '' : `${hours}h `;
  minutes = minutes <= 0 ? '' : `${minutes}m`;
  return `${days}${hours}${minutes}`?.trim?.();
};

export const convertMinsToDaysAndHoursDigit = (mins: number): string => {
  if (!mins) return '';
  if (mins < 60) return `${Math.ceil(mins)} m`;
  const hrs = mins / 60;
  if (hrs < 24) return `${get1DecimalPlacesIfDouble(hrs)} h`;
  const days = hrs / 24;
  return `${get1DecimalPlacesIfDouble(days)} d`;
};

const get1DecimalPlacesIfDouble = (num: number) => {
  if (!isNaN(num) && num % 1 === 0) return num;
  return Number(num)?.toFixed?.(1);
};

export const getTripTimeDifferenceInMin = (
  total: string | number,
  completed: number | string
): string => {
  const { hours, days, minutes } = getTimeDetailsFromMinutes(
    Number(getDifference({ total, completed }))
  );
  if (days <= 0) {
    if (hours <= 0) return `${minutes}m`;
    if (minutes <= 0) return `${hours}h`;
    return `${hours}h ${minutes}m`;
  }
  if (hours <= 0) return `${days}d`;
  return `${days}d ${hours}h`;
};

export const getCustomerNamesHyperlink = (
  customer: Array<{
    name: string;
    customer_id?: string;
  }>,
  maxHyperLinkLength = 25
): Array<{
  name: string;
  customer_id?: string;
}> => {
  const firstTwoCustomers = customer?.slice(0, 2);
  if (!firstTwoCustomers?.length) return [];
  if (firstTwoCustomers[0]?.name?.length > maxHyperLinkLength) {
    firstTwoCustomers[0].name =
      firstTwoCustomers[0]?.name?.slice(0, maxHyperLinkLength) + '...';
    return [firstTwoCustomers[0]];
  }
  const firstTwoCustomersNameLength = firstTwoCustomers?.reduce?.(
    (p, c) => p + c?.name?.length,
    0
  );
  if (firstTwoCustomersNameLength > maxHyperLinkLength) {
    firstTwoCustomers[1].name = firstTwoCustomers[1]?.name?.slice(
      0,
      maxHyperLinkLength - firstTwoCustomers[0].name?.length
    );
    if (!firstTwoCustomers[1]?.name?.length) return [firstTwoCustomers[0]];
    firstTwoCustomers[1].name += '...';
  }
  return firstTwoCustomers;
};

export const generateTripSubtitles = (data: ScheduleTripType) => {
  const associatedLoads = data?.loads?.slice(0, 2);
  const referenceNumbers: Array<any> = [];
  data?.loads?.forEach?.((load: any) => {
    if (load?.loadRefNum)
      referenceNumbers?.push({
        loadId: load?.loadId,
        loadRefNum: load?.loadRefNum,
      });
  });
  const splitRefNumbers = referenceNumbers?.slice(0, 2);
  const formattedCustomersList = getCustomerNamesHyperlink(
    data?.customer as Array<{
      name: string;
      customer_id?: string;
    }>
  );
  return [
    {
      key: t('generateTripSubtitlesLoads'),
      type: ESecondaryDetailsPanelType.LOAD,
      values: associatedLoads?.length
        ? associatedLoads?.map((e: any, i: number) => {
            return {
              id: e?.loadId,
              text: e?.loadNo,
            };
          })
        : '',
      additionalValue:
        data?.loads?.length > 2 ? `, +${data?.loads?.length - 2}` : '',
    },
    {
      key: t('generateTripSubtitlesReference'),
      type: ESecondaryDetailsPanelType.LOAD,
      values: splitRefNumbers?.length
        ? splitRefNumbers?.map((e: any, i: number) => {
            return {
              id: e?.loadId,
              text: e?.loadRefNum,
            };
          })
        : '',
      additionalValue:
        referenceNumbers?.length > 2
          ? `, +${referenceNumbers?.length - 2}`
          : '',
    },
    {
      key: t('generateTripSubtitlesCustomer'),
      type: ESecondaryDetailsPanelType.CUSTOMER,
      values: data?.customer?.length
        ? formattedCustomersList?.map((e: any) => ({
            id: e?.customer_id,
            text: e?.name,
          }))
        : '-',
      additionalValue:
        data?.customer?.length - formattedCustomersList?.length > 0
          ? `, +${data?.customer?.length - formattedCustomersList?.length}`
          : '',
    },
    {
      key: t('generateTripSubtitlesDispatcher'),
      type: ESecondaryDetailsPanelType.USER,
      values: [{ id: data?.dispatcherId, text: data?.dispatcherName ?? '' }],
    },
  ];
};

export const generateTripData = (data: ScheduleTripType) => {
  return [
    {
      trip: [
        {
          text: data?.destination?.city
            ? `${data?.origin?.city}, ${data?.origin?.state}`
            : '-',
        },
        {
          key: t('generateTripDataBusinessName'),
          text: data?.origin?.businessName || '-',
        },
        ...mapTripTimes(
          data?.origin?.dates as ScheduleLocationType['dates'],
          data?.origin
        ),
      ],
    },
    {
      trip: [
        {
          text: data?.destination?.city
            ? `${data?.destination?.city}, ${data?.destination?.state}`
            : '-',
        },
        {
          key: t('generateTripDataBusinessName'),
          text: data?.destination?.businessName || '-',
        },
        ...mapTripTimes(
          data?.destination?.dates as ScheduleLocationType['dates'],
          data?.destination
        ),
      ],
    },
  ];
};

export const isResourceTypeUnassignedDriver = (driver: ScheduleDriverType) => {
  if ((driver as any)?.resourceType === 'UNASSIGNED_TRIP') return true;
  return false;
};

export const generateKpiContent = (
  data: ScheduleTripType,
  resourceRecord = {}
) => {
  return [
    {
      primaryText: appendTextToDigit({
        val: data?.revenue,
        prefixLabel: '$',
      }),
      secondaryText: t('generateKpiContentRevenue'),
      primaryTextStyles: { color: '#3B873E', fontSize: '14px' },
    },
    {
      primaryText: appendTextToDigit({
        val: data?.driverPay,
        prefixLabel: '$',
      }),
      secondaryText: t('generateKpiContentDriverPay'),
      primaryTextStyles: { color: '#3B873E', fontSize: '14px' },
    },
    {
      primaryText: appendTextToDigit({
        val: data?.miles ?? 0,
        postfixLabel: ' mi',
        requiredValidation: false,
      }),
      secondaryText: isResourceTypeUnassignedDriver(resourceRecord as any)
        ? t('generateKpiContentLoadedMiles')
        : t('generateKpiContentTripMiles'),
      primaryTextStyles: { fontSize: '14px' },
    },
    {
      primaryText: `${data?.travelTimeDisplay ?? '-'}`,
      secondaryText: t('generateKpiContentTripTime'),
      primaryTextStyles: { fontSize: '14px' },
    },
  ];
};

export const generateGapAdditionalSubtitles = (data: ScheduleTripType) => {
  return [
    {
      key: t('generateGapAdditionalSubtitlesGapEnd'),
      additionalValue: `${data?.destination?.city ?? ''}${
        data?.destination?.state ? ', ' + data?.destination?.state : ''
      }`,
      subValue: (data?.destination as any)?.[
        data?.destination?.dates?.[0]?.key as any
      ],
      additionalValueStyles: { marginLeft: '5px' },
      subValueStyles: { marginLeft: '5px' },
    },
    {
      key: t('generateGapAdditionalSubtitlesDeadheadtoNext'),
      additionalValue: `${
        data?.deadheadMileage ? toFixDigit(data?.deadheadMileage) + ' mi' : ''
      }`,
    },
    {
      key: t('generateGapAdditionalSubtitlesEstAvailableDriveTime'),
      additionalValue: data?.totalUsableDriveTime,
    },
    {
      key: t('generateGapAdditionalSubtitlesEstAvailableDutyTime'),
      additionalValue: data?.totalUsableDutyTime,
    },
  ];
};

export const generateHOSProgress = (
  data: ScheduleTripType,
  hosConstants: HosConstantsType
) => {
  return [
    {
      label: getHourFromMinutes(data?.timeToBreak as number),
      value: getHOSValue(
        hosConstants.timeToBreakConst as number,
        data?.timeToBreak as number
      ),
      caption: t('generateHOSProgressNextBreak'),
    },
    {
      label: getHourFromMinutes(data?.driveTimeRemaining as number),
      value: getHOSValue(
        hosConstants.driveTimeConst as number,
        data?.driveTimeRemaining as number
      ),
      caption: t('generateHOSProgressDrive'),
    },
    {
      label: getHourFromMinutes(data?.dutyTimeRemaining as number),
      value: getHOSValue(
        hosConstants.dutyTimeConst as number,
        data?.dutyTimeRemaining as number
      ),
      caption: t('generateHOSProgressDuty'),
    },
    {
      label: getHourFromMinutes(data?.cycleTimeRemaining as number),
      value: getHOSValue(
        data.cycleTimeConst as number,
        data?.cycleTimeRemaining as number
      ),
      caption: t('generateHOSProgressCycle'),
    },
  ];
};

export const generateTimoffSubtitles = (data: ScheduleTimeoffsType) => {
  return [
    {
      key: 'Duration',
      additionalValue: data?.durationToDisplay ?? '-',
    },
    {
      key: 'Est. Start Time',
      additionalValue: data?.displayStartDate ?? '-',
      subValue: `Appt Time: ${data?.destination?.appointmentStartTime ?? '-'}`,
    },
    {
      key: 'Est. End Time',
      additionalValue: data?.displayEndDate ?? '-',
      subValue: `Appt Time: ${data?.destination?.appointmentEndTime ?? '-'}`,
    },
  ];
};

export const isTripTypeGap = (trip: ScheduleTripType): boolean => {
  if (
    trip?.type === TRIP_STATUSES.EMPTY ||
    trip?.status === TRIP_STATUSES.NEED_LOAD ||
    trip?.status === TRIP_STATUSES.DEADHEAD
  )
    return true;
  return false;
};
