import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import TerminalController from '../../../../../../views/settings/terminals/TerminalController';
import { SingleAutocompleteForm } from '../../../../../Ui/SingleAutocomplete';
import { TerminalShort } from '../../../../../../models/common/modelsShort';
import AssignmentFormWrapper from '../AssignmentFormWrapper';
import RoutesController from '../../RoutesController';

export default function TerminalAssignmentToLoad({
  tripId,
  terminal: terminalAssigned,
  onClose,
  onAssign,
}: {
  tripId: string;
  terminal: TerminalShort | null;
  onClose: () => void;
  onAssign: (terminal: TerminalShort) => void;
}): JSX.Element {
  const [data] = useState({ terminal: terminalAssigned });

  const handleTerminalAssign = async (data) => {
    if (data.terminal?.id) {
      const result = await RoutesController.assignDispatchTerminalToLoad(
        tripId,
        data.terminal?.id
      );
      if (!result) return;
      onAssign(data.terminal);
    }
    onClose();
  };

  return (
    <AssignmentFormWrapper
      onAction={handleTerminalAssign}
      onClose={onClose}
      data={data}
      title="Assign Terminal"
      actionLabel="Assign"
    >
      <FormComponent />
    </AssignmentFormWrapper>
  );
}

const FormComponent = () => {
  const { control } = useFormContext();
  return (
    <SingleAutocompleteForm
      control={control}
      disableClear
      name="terminal"
      fieldName="companyName"
      label="Terminal"
      getOptions={() => TerminalController.instance().getTerminals()}
    />
  );
};
