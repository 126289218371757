import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  CreateManageTypeRequest,
  DeleteManageTypeRequest,
  GetManageTypesRequest,
  ManageTypesItem,
  UpdateManageTypeRequest,
} from '../../models/DTOs/ManageTypes/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { PaginatedResult } from '../../types';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { IManageTypesService } from '../interfaces/IManageTypesService';
export class ManageTypesService extends IManageTypesService {
  @annotateNameAsync
  async getManageTypesList(
    requestData: GetManageTypesRequest,
    routeName: string
  ): Promise<PaginatedResult<ManageTypesItem> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/dictionary/api/v2/${routeName}/items/list`,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async deleteManageType(
    requestData: DeleteManageTypeRequest,
    routeName: string
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        `/web/dictionary/api/v2/${routeName}/items`,
        requestData,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteItem',
      });
      return response.data;
    } catch (error: any) {
      const errorObj = this.getServiceError(error as ComposedError);
      if (errorObj?.error?.response?.status === 405) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'generalFailureMsg',
          message: 'Custom type is currently in use',
        });
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'generalFailureMsg',
        });
      }
      return errorObj;
    }
  }

  @annotateNameAsync
  async createManageType(
    requestData: CreateManageTypeRequest,
    routeName: string
  ): Promise<ManageTypesItem | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `/web/dictionary/api/v2/${routeName}/items`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'savePayItemCategory',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'generalFailureMsg',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async updateManageType(
    requestData: UpdateManageTypeRequest,
    routeName: string
  ): Promise<ManageTypesItem | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/dictionary/api/v2/${routeName}/items`,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'savePayItemCategory',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'generalFailureMsg',
      });
      return this.getServiceError(error as ComposedError);
    }
  }
}
