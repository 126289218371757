import { Moment } from 'moment';
import { Model } from '../../../models/common/Model';
import { IAssignedDocuments, PhoneType } from '../../../types';
import {
  fromIsoToMoment,
  getAssignedDocumentsFromDto,
  getFuelTypeFromDTO,
  getPhoneFromDTO,
} from '../../../utils';

export class Tractor extends Model {
  tractorName: string;
  vin: string;
  phone: PhoneType;
  status!: string; // should be deleted
  licensePlateNumber: string;
  licenseState: string | null;
  licenseValidTill: Moment | null;
  lastUpdated: Moment | null;
  assignedDocuments: IAssignedDocuments;
  currentOdometer: number;
  extension: number;
  mobileno: number;
  ownedCompany: string;
  ownerEmail: string;
  ownerAddress: string;
  lastLocation: string;
  ownershipType: string;
  make: string;
  model: string;
  year: number;
  tollProvider: string;
  tollTagNumber: string;
  fuelCardProvider: string;
  fuelCardNumber: string;
  fuelCapacity: number;
  fuelType: { value: string } | null;
  gvw: number;
  terminalId?: any;
  braking: string;
  locationSource: string;
  isSleeperBerthAvailable: boolean;
  transmission: boolean;
  insuranceCompany: string;
  insuranceStartDate: Moment | null;
  insuranceEndDate: Moment | null;
  insuranceDate: [Moment, Moment];
  driverGroup: { name: string; id: string } | null;

  constructor(dto: any) {
    super(dto);
    this.phone = getPhoneFromDTO(dto);
    this.status = dto.status;
    this.tractorName = dto.tractorName;
    this.vin = dto.vin;
    this.terminalId = dto.terminalId;
    this.licensePlateNumber = dto.licensePlateNumber;
    this.licenseState = dto.licenseState;
    this.lastLocation = dto.assetLocationDTO?.addressDTO;
    this.lastUpdated = fromIsoToMoment(dto.assetLocationDTO?.time);
    this.locationSource = dto.assetLocationDTO?.locationSource;
    this.licenseValidTill = fromIsoToMoment(dto.licenseValidTill);
    this.assignedDocuments = getAssignedDocumentsFromDto(dto, 'TRACTOR');
    this.currentOdometer = dto.currentOdometer;
    this.extension = dto.extension;
    this.mobileno = dto.mobileno;
    this.ownedCompany = dto.ownedCompany;
    this.ownerEmail = dto.ownerEmail;
    this.ownerAddress = dto.ownerAddress;
    this.ownershipType = dto.ownershipType;
    this.make = dto.make;
    this.model = dto.model;
    this.year = dto.year;
    this.tollProvider = dto.tollProvider;
    this.tollTagNumber = dto.tollTagNumber;
    this.fuelCardProvider = dto.fuelCardProvider;
    this.fuelCardNumber = dto.fuelCardNumber;
    this.fuelCapacity = dto.fuelCapacity;
    this.fuelType = getFuelTypeFromDTO(dto);
    this.gvw = dto.gvw;
    this.braking = dto.braking;
    this.isSleeperBerthAvailable = dto.isSleeperBerthAvailable;
    this.transmission = dto.transmission === 'MANUAL';
    this.insuranceCompany = dto.insuranceCompany;
    this.insuranceStartDate = fromIsoToMoment(dto.insuranceStartDate);
    this.insuranceEndDate = fromIsoToMoment(dto.insuranceEndDate);
    this.insuranceDate = [
      dto.insuranceStartDate || null,
      dto.insuranceEndDate || null,
    ];
    this.driverGroup = dto.groupDetails
      ? {
          name: dto.groupDetails.groupName,
          id: dto.groupDetails.id,
        }
      : null;
  }
}
