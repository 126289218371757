import { EquipmentType } from '../../../../views/maintenanceRecords/constants/constants';
import { PaginatedList } from '../../../../types';
import { Type } from 'class-transformer';

export interface IMaintenanceEquipment {
  equipmentId: number;
  equipmentName: string;
  currentOdometer: number;
  lastUpdatedDate: string;
  equipmentType: EquipmentType;
  terminalId: string | null;
}

export class MaintenanceEquipment implements IMaintenanceEquipment {
  equipmentId!: number;
  equipmentName!: string;
  currentOdometer!: number;
  lastUpdatedDate!: string;
  equipmentType!: EquipmentType;
  terminalId!: string | null;
  terminalName!: string | null;
}

export class PaginatedMaintenanceEquipmentList extends PaginatedList {
  @Type(() => MaintenanceEquipment)
  content!: MaintenanceEquipment[];
}
