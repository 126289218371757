import { Box, Stack } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SxProps, styled } from '@mui/material/styles';
import React, { FunctionComponent, MutableRefObject } from 'react';

const AxeleTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '350',
    fonFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '14px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    // padding: "4px 8px",
    borderRadius: '4px',
  },
}));

export interface CustomTooltipProps {
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
  titleFirstLine?: string;
  titleFirstIcon?: any;
  titleSecondLine?: string;
  titleSecondIcon?: any;
  arrow?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'info';
  children?: any;
  enterDelay?: number;
  leaveDelay?: number;
  followCursor?: boolean;
  typographyProps?: any;
  hide?: boolean;
  isCustomizationTitle?: boolean;
  component?: any;
  backgroundColor?: string;
  toolTipStyleProps?: SxProps;
  reference?: MutableRefObject<undefined>;
}

export const CustomizedTooltips: FunctionComponent<CustomTooltipProps> = ({
  placement,
  titleFirstLine,
  titleFirstIcon,
  titleSecondIcon,
  titleSecondLine,
  arrow,
  color = 'primary',
  children,
  enterDelay,
  leaveDelay,
  followCursor,
  typographyProps,
  hide = false,
  isCustomizationTitle = false,
  component,
  backgroundColor,
  toolTipStyleProps,
  reference,
}: CustomTooltipProps) => {
  return hide ? (
    children
  ) : (
    <div>
      <AxeleTooltip
        ref={reference}
        sx={{
          '& .MuiTooltip-arrow': {
            color: backgroundColor,
          },
          '& .MuiTooltip-tooltip': {
            backgroundColor: backgroundColor,
          },
          ...toolTipStyleProps,
        }}
        title={
          !isCustomizationTitle ? (
            <React.Fragment>
              <Stack direction="row" alignItems="center" gap={1}>
                {titleFirstIcon}
                {typographyProps ? (
                  <Box sx={typographyProps}>{titleFirstLine}</Box>
                ) : (
                  <Typography variant="body1">{titleFirstLine}</Typography>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                {titleSecondIcon}
                <Typography variant="body1">{titleSecondLine}</Typography>
              </Stack>
            </React.Fragment>
          ) : (
            <React.Fragment>{component}</React.Fragment>
          )
        }
        placement={placement}
        arrow={arrow}
        color={color}
        enterDelay={enterDelay}
        leaveDelay={leaveDelay}
        followCursor={followCursor}
      >
        {children}
      </AxeleTooltip>
    </div>
  );
};

export default CustomizedTooltips;
