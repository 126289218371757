import { useNavigate } from 'react-router-dom';
import { useLoadPermission } from '../../../common/hooks/useLoadPermission';
import { useStores } from '../../../store/root.context';
import { getGridBottomMenuSettings } from '../../../utils/gridBottomMenuSettings';

export const useLoadListGridActions = () => {
  const { myLoadStore } = useStores();
  const { setAddNewClicked, currentView, loadCount } = myLoadStore;
  const { hasLoadCRUDPermission } = useLoadPermission();
  const navigate = useNavigate();

  const canUploadPermission = !!loadCount && hasLoadCRUDPermission;

  const canAddPermission = hasLoadCRUDPermission;

  const onAddHandler = () => {
    setAddNewClicked(true);
  };

  const onUploadHandler = () => {
    navigate('/settings/import', { state: { subPage: 'Loads' } });
  };

  const gridBottomMenu = getGridBottomMenuSettings({
    onAddHandler: onAddHandler,
    onUploadHandler: onUploadHandler,
    canUploadPermission: canUploadPermission && canAddPermission,
    canAddPermission: canAddPermission,
    currentView: currentView,
  });

  return {
    canUploadPermission,
    canAddPermission,
    onAddHandler: onAddHandler,
    onUploadHandler: onUploadHandler,
    gridBottomMenu: gridBottomMenu,
  };
};
