import React, { useState } from 'react';
import { MenuItemData } from '@/common/NestedDropDown/definitions';
import { fuelCardService } from '@/api';
import { FuelCardAccount, Provider } from '@/models/DTOs/FuelCards/FuelCards';
import {
  CreateExpensesRequest,
  DeleteEfsAccountRequest,
  FinishImportRequest,
} from '@/models/DTOs/FuelCards/Requests';
import { ServiceError } from '@/api/interfaces';
import { RootStoreInstence } from '@/store/root-store/rootStateContext';
import { Providers } from '@/views/settings/integrations/FuelCard/enums';

export const useCardItem = (
  onAccountDelete: (id: number) => void,
  account: FuelCardAccount,
  selectedProvider: Provider,
  onDrawerOpen: () => void,
  onSelectedProviderAccountChange: (account: FuelCardAccount) => void,
  getAccounts: () => void
) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [importKey, setImportKey] = useState('');
  const [rePullData, setRePullData] = useState(false);

  const handleRePullDataChange = (rePull: boolean) => setRePullData(rePull);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorElement(event.currentTarget);
  };

  const handleImportKeyChange = (key: string) => setImportKey(key);

  const handleMenuClose = (): void => {
    setAnchorElement(null);
  };

  const handleMenuItemClick = (option: MenuItemData) => {
    switch (option.label) {
      case 'Delete':
        setShowDeletePopup(true);
        break;
      case 'Edit':
        onSelectedProviderAccountChange(account);
        onDrawerOpen();
        break;
      case 'Sync Transaction':
        setShowSyncModal(true);
        break;
    }
    setAnchorElement(null);
  };

  const toggleShowDeletePopup = () => {
    setShowDeletePopup(!showDeletePopup);
  };

  const deleteEfsAccount = async (id: number) => {
    const request = new DeleteEfsAccountRequest();
    request.id = account.id;
    const response = await fuelCardService.deleteEfsAccount(request);
    if (response instanceof ServiceError) {
      setShowDeletePopup(false);
      return;
    }
  };

  const handleDeleteAccount = async () => {
    switch (selectedProvider.providerName) {
      case Providers.EFS: {
        await deleteEfsAccount(account.id);
        break;
      }
    }
    onAccountDelete(account.id);
    setShowDeletePopup(false);
  };

  const syncModalClose = async () => {
    setShowSyncModal(false);
    if (importKey) {
      await finishImport();
    }
  };

  const createExpenses = async (data: any) => {
    if (!(data?.expenseIdsToCreate ?? []).length) {
      return;
    }
    const request = new CreateExpensesRequest();
    request.key = data.key;
    request.credentialId = account.id;
    request.deductionType = data.deductionType;
    request.percentageToReimburse = data.percentageToReimburse;
    request.addDiscountInDescription = data.addDiscountInDescription;
    const params = data.expenseIdsToCreate;
    const response = await fuelCardService.createExpenses(request, params);
    if (response instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'expenseFromEFSCreate',
      });
      return;
    }
    RootStoreInstence.setNotificationType({
      type: 'SUCCESS',
      serviceName: 'expenseFromEFSCreate',
    });
    getAccounts();
    setRePullData(true);
  };

  const finishImport = async () => {
    const request = new FinishImportRequest();
    request.key = importKey;
    await fuelCardService.finishImport(request);
  };

  return {
    showDeletePopup,
    toggleShowDeletePopup,
    anchorElement,
    handleMenuItemClick,
    handleMenuClose,
    handleMenuClick,
    handleDeleteAccount,
    showSyncModal,
    createExpenses,
    handleImportKeyChange,
    syncModalClose,
    rePullData,
    handleRePullDataChange,
  };
};
