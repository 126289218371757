import React, { useEffect, useMemo, useState } from 'react';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import TabsV2, { ITabV2 } from '../../../../ui-kit/components/TabsV2';
import { ETripPanelTabs } from '../../constants/constants';
import { t } from 'i18next';

export interface TripPanelTab extends ITabV2 {
  key: ETripPanelTabs;
}

const getTabItems = (): TripPanelTab[] => {
  return [
    {
      key: ETripPanelTabs.ROUTE,
      label: t('TripPanelTabRoute'),
      iconName: 'MapOutlined',
      source: 'MUIcons',
    },
    {
      key: ETripPanelTabs.FINANCE,
      label: t('TripPanelTabFinance'),
      iconName: 'AttachMoneyOutlined',
      source: 'MUIcons',
    },
    {
      key: ETripPanelTabs.CARRIER_PAYMENT,
      label: t('TripPanelTabCarrierPayment'),
      iconName: 'ReceiptOutlined',
      source: 'MUIcons',
    },
  ];
};

const DetailsTabStrips: React.FC<{
  defaultTab?: ETripPanelTabs;
  onChange: (selected: ETripPanelTabs) => void;
  tripId: string;
  keys: ETripPanelTabs[];
}> = ({ onChange, defaultTab, keys }) => {
  const { hasFinancialPaymentView } = useLoadPermission();
  const tabItems = useMemo(() => {
    return getTabItems()
      .filter((item) => keys.includes(item.key))
      .filter(
        (item) => item.key !== ETripPanelTabs.FINANCE || hasFinancialPaymentView
      );
  }, [hasFinancialPaymentView, keys]);

  const getTab = (tabKey?: ETripPanelTabs) =>
    tabItems.find(({ key }) => key === tabKey) || tabItems[0];

  const [selected, setSelected] = useState(getTab(defaultTab));

  useEffect(() => {
    setSelected(getTab(defaultTab));
  }, [defaultTab]);

  const handleStripChange = (selected: TripPanelTab) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    setSelected(selected);
    onChange(selected.key);
  };

  return (
    <TabsV2<'key', TripPanelTab>
      keyName="key"
      tabs={tabItems}
      setSelectedTab={(tab) => {
        handleStripChange(tab);
      }}
      selectedTab={selected.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

const getTabByKey = (key: ETripPanelTabs): TripPanelTab | undefined => {
  return getTabItems().find((tab) => tab.key === key);
};

export const tabStripRendererByKeyFS = (key: ETripPanelTabs) => {
  return (
    <TabsV2<'key', TripPanelTab>
      keyName="key"
      tabs={[getTabByKey(key)].filter(Boolean) as TripPanelTab[]}
      selectedTab={getTabByKey(key)?.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

export default DetailsTabStrips;
