import { documentService, userService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import {
  CreateLatestCertificationRequest,
  CreateSafetyAlertRequest,
  DeleteSafetyAlertRequest,
  GetDocumentTypesRequest,
  GetDriverAlertListRequest,
  GetSafetyAlertListRequest,
  SafetyAlertBulkUpdateRequest,
} from '../../../../models';
import { ParseToDate } from '../../../../utils';
import { SafetyAlertModel, SafetyFormType } from './models';

class SafetyAlertActions {
  static async getSafetyAlerts(driverId: string) {
    const request = new GetSafetyAlertListRequest();
    request.driverId = driverId;
    const response = await userService.getSafetyAlerts(request);
    if (response instanceof ServiceError) {
      return;
    }
    return response;
  }

  static async getDriverAlerts() {
    const request = new GetDriverAlertListRequest();
    const response = await userService.getDriverAlerts(request);
    if (response instanceof ServiceError) {
      return;
    }
    return response;
  }

  static async createLatestCertification(
    data: { validFrom: string; validTo: string; notes: string },
    alert: SafetyAlertModel
  ) {
    const request = new CreateLatestCertificationRequest();
    alert.validFrom = data.validFrom;
    alert.validTo = data.validTo;
    alert.notes = data.notes;
    request.certification = alert;
    const response = await documentService.createLatestCertification(request);
    if (response instanceof ServiceError) {
      return;
    }
    return response;
  }

  static async getDocumentTypes() {
    const request = new GetDocumentTypesRequest();
    const response = await userService.getDocumentTypes(request);
    if (response instanceof ServiceError) {
      return;
    }
    return response;
  }

  static async createAlert(data: SafetyFormType, driverId: string) {
    const request = new CreateSafetyAlertRequest();
    request.driverId = driverId;
    request.documentType = data.documentType;
    request.documentTypeId = data.documentTypeId;
    request.notes = data.notes;

    request.validFrom = ParseToDate(data.validFrom);
    request.validTo = ParseToDate(data.validTo);
    const response = await userService.createSafetyAlert(request);
    if (response instanceof ServiceError) {
      return;
    }
    return response;
  }

  static async deleteSafetyAlert(id: string) {
    const request = new DeleteSafetyAlertRequest();
    request.ids = [id];
    const response = await userService.deleteSafetyAlert(request);
    if (response instanceof ServiceError) {
      return;
    }
    return response;
  }

  static async safetyAlertBulkUpdate(alertsList: SafetyAlertModel[]) {
    const request = new SafetyAlertBulkUpdateRequest();
    const response = await userService.safetyAlertBulkUpdate(
      request,
      alertsList.map((alert) => {
        return {
          ...alert,
          validFrom: ParseToDate(alert.validFrom),
          validTo: ParseToDate(alert.validTo),
        };
      })
    );
    if (response instanceof ServiceError) {
      return;
    }
    return response;
  }
}

export default SafetyAlertActions;
