import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useImport } from '../../../../../contexts/ImportContext';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import ImportActions from '../../Import';
import Footer from '../Common/Footer';
import DataPreview from './DataPreview';
import Mapping from './Mapping';
import SampleData from './SampleData';
import { ColumnMappingInfoType } from './constants';

type FormDataType = { [key: string]: string | null };

const generateFormData = (data: FormDataType) => {
  const formData: Partial<FormDataType> = {};
  for (const prop in data) {
    if (data[prop]) {
      formData[prop] = data[prop];
    }
  }
  return formData;
};

export default function Step2() {
  const state = useForm();
  const {
    currentEntity: { routeName, dictionary },
    sessionId,
    tableColumns,
    tableData,
    handleNext,
    handleBack,
  } = useImport();

  const [hasUnmappedColumns, setHasUnmappedColumns] = useState(false);
  const [shouldShowWarningPopup, setShouldShowWarningPopup] = useState(false);
  const [columnsMappingInfo, setColumnsMappingInfo] = useState<
    ColumnMappingInfoType[] | null
  >(null);
  const [sampleData, setSampleData] = useState<any>(null);
  const [currentColumnName, setCurrentColumnName] = useState<string>('');
  const [submitting, setSubmitting] = useState(false);

  const headerList = tableColumns.map((column) => column.field);

  const handleSetUnmappedColumns = (hasUnmappedColumns: boolean) =>
    setHasUnmappedColumns(hasUnmappedColumns);

  useEffect(() => {
    const getColumnsMapping = async () => {
      const result = await ImportActions.getColumnsMapping(
        routeName,
        dictionary,
        sessionId,
        headerList
      );
      if (result) {
        const { importColumnMapping } = result;
        const formDefaultValues: Partial<{ [key: string]: string | null }> = {};
        importColumnMapping.forEach((v: any) => {
          formDefaultValues[v.sourceColumnCode] = v.destinationColumn;
        });
        setColumnsMappingInfo(importColumnMapping);
        state.reset(formDefaultValues);
      }
    };
    getColumnsMapping();
  }, []);

  const handleOnGoBack = useCallback(async () => {
    if (!submitting) {
      setSubmitting(true);
      const formData = generateFormData(state.getValues());
      const res = await ImportActions.saveMapping(
        routeName,
        dictionary,
        sessionId,
        formData
      );
      if (res) {
        handleBack();
      }
      setSubmitting(false);
    }
  }, [submitting]);

  const onSubmit = async (data: FormDataType) => {
    if (!submitting) {
      setSubmitting(true);
      const formData = generateFormData(data);
      const res = await ImportActions.extractMapping(
        routeName,
        dictionary,
        sessionId,
        formData
      );
      if (res) {
        handleNext();
      }
      setSubmitting(false);
    }
  };

  const handleSetSampleData = (columnName: string) => {
    setCurrentColumnName(columnName);
    const simpleData = tableData
      .filter((t: any) => !!t[columnName])
      .map((t: any) => t[columnName])
      .slice(0, 12);
    setSampleData(simpleData);
  };

  const handleOnNextClick = useCallback(() => {
    if (hasUnmappedColumns) {
      setShouldShowWarningPopup(true);
    } else {
      state.handleSubmit((data) => onSubmit(data))();
    }
  }, [submitting, hasUnmappedColumns]);

  const handleIgnoreWarningAndNext = () => {
    setShouldShowWarningPopup(false);
    state.handleSubmit((data) => onSubmit(data))();
  };

  return (
    <>
      {shouldShowWarningPopup && (
        <DeletePopup
          open={shouldShowWarningPopup}
          onClose={() => setShouldShowWarningPopup(false)}
          onAction={handleIgnoreWarningAndNext}
          title={'There are unmapped field(s)'}
          buttonText={'Ignore'}
          cancelText={'Cancel'}
          width={'20%'}
        />
      )}
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          alignItems: 'flex-start',
          overflow: 'auto',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <FormProvider {...state}>
            <form>
              <Mapping
                columnsMappingInfo={columnsMappingInfo}
                handleSetSampleData={handleSetSampleData}
                handleSetUnmappedColumns={handleSetUnmappedColumns}
              />
            </form>
          </FormProvider>
        </Box>
        <Box
          sx={{
            display: 'flex',
            ml: 2,
            flex: 1,
            p: 4,
          }}
        >
          {sampleData ? (
            <SampleData columnName={currentColumnName} data={sampleData} />
          ) : (
            <DataPreview />
          )}
        </Box>
      </Box>
      <Footer onBack={handleOnGoBack} onNext={handleOnNextClick} />
    </>
  );
}
