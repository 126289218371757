import * as yup from 'yup';
import { transformEmptyStringToNull } from './';

export const loadEquipmentOptions = ['Flatbed', 'Reefer'];

export enum ReeferModeEnum {
  CONTINUOUS = 'CONTINUOUS',
  START_STOP = 'START_STOP',
  OFF = 'OFF',
}

export const ReeferModeVals = {
  CONTINUOUS: 'Continuous',
  START_STOP: 'Start/Stop',
  OFF: 'Off',
};

export const ReeferMode: ReeferModeEnum[] = [
  ReeferModeEnum.CONTINUOUS,
  ReeferModeEnum.START_STOP,
  ReeferModeEnum.OFF,
];

export const loadCommodity = yup.object().shape({
  commodity: yup
    .string()
    .required('Commodity Type is required.')
    .test('empty-check', 'Commodity Type is required.', (c) => !c?.length),
  quantity: yup
    .number()
    .required('Quantity is required.')
    .min(1, 'Value must be between 1 and 999,999.')
    .max(999999, 'Value must be between 1 and 999,999.')
    .typeError('Please enter a number')
    .test('empty-check', 'Quantity is required.', (v) => !Boolean(v)),
  package: yup.string().nullable(),
  weight: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  description: yup
    .string()
    .nullable()
    .max(255, 'Cannot be longer than 255 characters.'),
  pickup: yup.string().nullable(),
  dropoff: yup.string().nullable(),
  length: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  width: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  height: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  volume: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  isDimensionalWeight: yup.boolean().default(false),
});

export const loadCommoditySchema = {
  commodity: yup
    .string()
    .required('Commodity Type is required.')
    .test('empty-check', 'Commodity Type is required.', (c) => !c?.length),
  quantity: yup
    .number()
    .required('Quantity is required.')
    .min(1, 'Value must be between 1 and 999,999.')
    .max(999999, 'Value must be between 1 and 999,999.')
    .typeError('Please enter a number')
    .test('empty-check', 'Quantity is required.', (v) => !Boolean(v)),
  package: yup.string().nullable(),
  weight: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  description: yup
    .string()
    .nullable()
    .max(255, 'Cannot be longer than 255 characters.'),
  pickup: yup.string().nullable(),
  dropoff: yup.string().nullable(),
  length: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  width: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  height: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  volume: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull),
  isDimensionalWeight: yup.boolean().default(false),
};

export const equipmentAdditionalDetailsSchema = {
  chassisNumber: yup
    .string()
    .nullable()
    .max(50, 'Cannot be longer than 50 characters.')
    .transform(transformEmptyStringToNull),
  containerNumber: yup
    .string()
    .nullable()
    .max(50, 'Cannot be longer than 50 characters.')
    .transform(transformEmptyStringToNull),
  bookingNumber: yup.string().nullable().transform(transformEmptyStringToNull),
  appointmentNumber: yup
    .string()
    .nullable()
    .max(50, 'Cannot be longer than 50 characters.')
    .transform(transformEmptyStringToNull),
  reeferMode: yup.string().default('Continuous'),
};

export const loadSchema = {
  equipment: yup
    .array()
    .min(1, 'Equipment type is required.')
    .required('Equipment type is required.'),
  // .oneOf(loadEquipmentOptions).required(),
  temp: yup
    .number()
    .min(-500, 'Value must be between -500 and 500.')
    .max(500, 'Value must be between -500 and 500.')
    .nullable()
    .transform(transformEmptyStringToNull)
    .typeError('Please enter a number.'),
  // .when(['temp'], {
  // is: (temp) => temp === ''
  // },
  // then:yup.num
  // ),
  type: yup.string().nullable(),
  commodities: yup.array().of(yup.object().shape(loadCommoditySchema)),
  weight: yup.string().nullable(),
  notes: yup.string().max(1024, 'Cannot be longer than 1024 characters.'),
  seal: yup.string().nullable().max(50, 'Cannot be longer than 50 characters.'),
  loadType: yup.string().oneOf(['FTL', 'LTL']),
  isBrokered: yup.boolean().default(false),
  equipmentAdditionalDetails: yup
    .object()
    .shape(equipmentAdditionalDetailsSchema),
};
