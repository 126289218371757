import { ComponentType } from '../../../../../types';

export interface hyperLinkProps {
  displayName: string | number;
  id: string | number;
  type: keyof ComponentType;
  isNothyperLink?: boolean;
}

export enum logEntityType {
  LOAD = 'LOAD',
}

export interface secondaryPanelType {
  type: keyof ComponentType;
  // subType?: keyof ComponentType;
  // subId?: string | number;
  id: string | number;
}
