import { Box, useTheme } from '@mui/material';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import EventEmitter from '../../EventEmitter/EventEmitter';
import EventHoldLoadAction from '../../EventEmitter/Events/EventHoldLoadAction';
import DeletePopup from '../../common/DeletePopup';
import DetailsPanel from '../../common/DetailsPanel';
import { OnHoldInvoiceWarning } from '../../common/LoadTabPanel/component/OnHoldLoadWarning';
import {
  ELoadStatus,
  ELoadTextStatus,
} from '../../common/LoadTabPanel/constants/constants';
import FinanceOverviewInvoice from '../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewInvoice/FinanceOverviewInvoice';
import { HoldLoadPopup } from '../../common/LoadTabPanel/tabs/Routes/components/HoldLoadPopup';
import { EHoldLoadEntityType } from '../../common/LoadTabPanel/tabs/Routes/components/HoldLoadPopup/utils';
import LongMenu from '../../common/LongMenu';
import { getOrganizationId } from '../../common/TimeoffDialog/utils';
import { InvoiceStatus } from '../../common/Ui/StatusComponent/StatusComponent';
import TableDataContainer from '../../common/Wrappers/TableDataContainer';
import { useLoadPermission } from '../../common/hooks/useLoadPermission';
import { ESecondaryDetailsPanelType } from '../../constants/gantt/gantt.const';
import { StatusChangeSingleRequest } from '../../models';
import { useStores } from '../../store/root.context';
import {
  IEdiDetails,
  LoadSummary,
} from '../../subPages/loadsList/models/LoadSummary';
import { E3DotMenuType } from '../../views/myLoads/components/LoadDetailDialog/types';
import InvoiceActions from './InvoiceActions';
import { SelectDateFormDialog } from './components/BulkActions/Actions';
import { FactoringActionReturnType, InvoiceModel } from './models/InvoiceModel';

const InvoiceCustomDetailPanel = ({
  data,
  handleFactoringResponse,
  triggerAlert,
  handleUpdatedInvoice,
  isStatusUpdated,
  onUpdated,
  onAction,
  setLoadData,
  isGlobal = false,
  onClose,
  canEditInvoice,
  canDeleteInvoice,
  readonly = false,
}: {
  data: string | undefined;
  UpdatedCloseVisible?: () => void;
  handleFactoringResponse?: (
    response: FactoringActionReturnType,
    selectedItem: InvoiceModel | null
  ) => void;
  handleUpdatedInvoice?: (data: InvoiceModel) => void;
  isStatusUpdated?: () => void;
  triggerAlert?: (actionName: string, message: string) => void;
  isFinanceOverview?: boolean;
  onAction?: (status?: ELoadStatus) => void;
  setLoadData: (data: LoadSummary) => void;
  ediDetails?: IEdiDetails;
  panelType: ESecondaryDetailsPanelType;
  onUpdated?: () => void;
  onClose?: () => void;
  isGlobal: boolean;
  canEditInvoice: boolean;
  canDeleteInvoice: boolean;
  readonly: boolean;
}) => {
  const [invoiceData, setInvoiceData] = useState<InvoiceModel | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [invoiceAction, setInvoiceAction] = useState([]);
  const [selectDateDialogOpen, setSelectDateDialogOpen] =
    useState<boolean>(false);
  const [current3DotMenu, setCurrent3DotMenu] = useState<{
    type: E3DotMenuType;
    data?: any;
  } | null>(null);
  const { hasInvoiceEditPermission } = useLoadPermission();

  const theme = useTheme();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<{
    open: boolean;
    hideBtn: boolean;
    type: string;
    title: string;
    subMsg: string;
    okay?: () => void;
    cancel: string;
  }>({
    open: false,
    type: '',
    title: '',
    subMsg: '',
    cancel: '',
    hideBtn: false,
  });
  const {
    invoiceStore: { tableData, setTableData, selectedItem },
  } = useStores();
  let statusValue: string | null;

  const getDataInvoiceData = async () => {
    // if (!data) return;
    const result = await InvoiceActions.previewInvoice(data?.id || data);
    if (result) {
      result.id = result.invoiceId;
      setInvoiceData(new InvoiceModel(result));
    }
  };

  const fetchData = async () => {
    // if (!data) return;
    await getDataInvoiceData();
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  useEffect(() => {
    getActionList();
  }, [invoiceData?.status, invoiceData?.onHold]);

  const onClickAction = async (type: string) => {
    const option = getKeyByValue(ELoadTextStatus, type);
    setSelectedOption(option);
    statusValue = option;
    if (option === ELoadStatus['DELETED']) {
      if (invoiceData?.onHold) {
        setDeleteDialogOpen({
          open: true,
          title: '',
          subMsg: `Invoice cannot be deleted as it is on hold`,
          cancel: 'Okay',
          hideBtn: true,
          type: '',
        });
        return;
      }
      setDeleteDialogOpen({
        open: true,
        type: 'delete',
        title: 'Delete Invoice',
        subMsg: `Are you sure, you want to delete the invoice ${selectedItem?.seqNumber} ?`,
        cancel: 'Cancel',
        hideBtn: false,
        okay: deleteInvoice,
      });
      return;
    } else {
      if (option === 'PAID') {
        setSelectDateDialogOpen(true);
      } else {
        changeStatusInvoice(moment());
      }
    }
  };
  useEffect(() => {
    if (isStatusUpdated) getDataInvoiceData();
  }, [isStatusUpdated]);

  const changeStatusInvoice = async (data: Moment) => {
    const payLoad = new StatusChangeSingleRequest();
    payLoad.organizationId = getOrganizationId();
    payLoad.invoiceId = selectedItem?.id;
    payLoad.status = statusValue || selectedOption;
    payLoad.paidOn = data;
    const result = await InvoiceActions.statusChangeSingle(payLoad);
    if (result) {
      setInvoiceData({
        ...invoiceData,
        status: statusValue || selectedOption,
      });
      getDataInvoiceData();
      removeInvoice();
    }
  };
  const deleteInvoice = async () => {
    const result = await InvoiceActions.deleteInvoice(selectedItem?.id || '');
    setDeleteDialogOpen((prevState) => ({ ...prevState, open: false }));
    if (result) {
      removeInvoice();
      onClose?.();
    }
  };

  const removeInvoice = () => {
    const updatedList = tableData.filter((obj) => obj.id !== selectedItem?.id);
    setTableData(updatedList);
    // onClose?.();
  };

  const getActionList = async () => {
    if (!data && !canEditInvoice) return;
    const result = await InvoiceActions.getInvoiceActionData(data?.id || data);
    if (result) {
      const option = canDeleteInvoice
        ? [
            {
              name: 'Delete',
              action: onClickAction,
            },
          ]
        : [];
      const getIsExceptedActionHoldingInvoice = (loadStatus: ELoadStatus) => {
        //Hide all the following 3-dot action on Hold Invoice
        return (
          !!invoiceData?.onHold &&
          [
            ELoadStatus.PAID,
            ELoadStatus.INVOICED,
            ELoadStatus.LOAD_COMPLETED,
            ELoadStatus.CANCELLED,
          ].includes(loadStatus)
        );
      };
      result?.data?.forEach((datAccount: string) => {
        if (!getIsExceptedActionHoldingInvoice(datAccount)) {
          option.push({
            name: ELoadTextStatus[datAccount],
            action: onClickAction,
          });
        }
      });
      if (
        [ELoadStatus.PAID, ELoadStatus.INVOICED].includes(
          invoiceData?.status
        ) &&
        hasInvoiceEditPermission
      ) {
        if (invoiceData?.onHold) {
          option.push({
            name: `Release`,
            action: (): void => {
              setCurrent3DotMenu({ type: E3DotMenuType.RELEASE_LOAD });
            },
          });
        } else {
          option.push({
            name: `Mark as Hold`,
            action: (): void => {
              setCurrent3DotMenu({ type: E3DotMenuType.MARK_AS_HOLD });
            },
          });
        }
      }
      setInvoiceAction(option);
    }
  };

  const panelTitle = {
    panelTitle: `Invoice Id: ${invoiceData?.seqNumber || ''}`,
    renderCustomTitle: () => (
      <Box sx={{ pl: 1 }}>
        <InvoiceStatus
          status={
            invoiceData?.status === 'INVOICED' &&
            invoiceData?.receivedPayments.length > 0
              ? 'PARTIALLY_PAID'
              : invoiceData?.status || ''
          }
          type="LOAD"
          onHold={invoiceData?.onHold}
          onHoldNote={invoiceData?.onHoldNote || undefined}
        />
      </Box>
    ),
  };

  const ActionsRenderer = () => {
    return canEditInvoice ? (
      <>
        <LongMenu
          color="#ffffff"
          options={invoiceAction}
          canPerformClick={(event: React.MouseEvent<HTMLElement>) => {
            return true;
          }}
        />
      </>
    ) : null;
  };

  const sendEventHoldLoadActionData = ({
    actionType,
    entityType,
  }: {
    actionType: E3DotMenuType;
    entityType: EHoldLoadEntityType;
  }) => {
    setCurrent3DotMenu(null);
    if (!invoiceData?.id) return;
    setInvoiceData(
      (prev) =>
        ({
          ...prev,
          onHold: actionType === E3DotMenuType.MARK_AS_HOLD,
        } as unknown as InvoiceModel)
    );
    fetchData();
    EventEmitter.send(
      new EventHoldLoadAction({
        loadIds: [],
        tripIds: [],
        invoiceIds: invoiceData?.id ? [invoiceData.id] : [],
        type: actionType,
      })
    );
  };

  const contentCustomStyles = {
    ...(invoiceData?.onHold && {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }),
  };

  return (
    <>
      <TableDataContainer>
        <DetailsPanel
          darkHeader
          data={invoiceData}
          {...panelTitle}
          entity="Invoice"
          isGlobal={isGlobal}
          onClose={() => onClose?.()}
          showThreeDotMenuIcon
          actionsRenderer={() => <ActionsRenderer />}
          contentRenderer={() => (
            <Box sx={{ pointerEvents: readonly ? 'none' : '' }}>
              {invoiceData?.onHold && (
                <OnHoldInvoiceWarning invoiceId={invoiceData?.invoiceId} />
              )}
              <Box sx={{ p: 2, pt: 0 }}>
                {invoiceData && (
                  <FinanceOverviewInvoice
                    id={invoiceData?.invoiceId || data}
                    handleFactoringResponse={handleFactoringResponse}
                    triggerAlert={triggerAlert}
                    handleUpdatedInvoice={handleUpdatedInvoice}
                    isStatusUpdated={isStatusUpdated}
                    onAction={onAction}
                    setLoadData={setLoadData}
                    ediDetails={data}
                    onUpdated={onUpdated}
                    invoiceData={'MASTER'}
                    removeInvoice={removeInvoice}
                    onHold={invoiceData?.onHold}
                    status={invoiceData?.status}
                  />
                )}
              </Box>
            </Box>
          )}
          contentCustomStyles={contentCustomStyles}
        />
      </TableDataContainer>

      {selectDateDialogOpen && (
        <SelectDateFormDialog
          titleName={'Mark as Paid'}
          open={selectDateDialogOpen}
          onAction={async (dataValue) => {
            changeStatusInvoice(dataValue.date);
            setSelectDateDialogOpen(false);
          }}
          onClose={() => setSelectDateDialogOpen(false)}
        />
      )}

      <DeletePopup
        title={deleteDialogOpen.title}
        body={deleteDialogOpen.subMsg}
        open={deleteDialogOpen?.open}
        cancelText={deleteDialogOpen?.cancel}
        backgroundColor={
          deleteDialogOpen.hideBtn ? theme.palette.primary.main : ''
        }
        hideDelete={deleteDialogOpen.hideBtn}
        onClose={() =>
          setDeleteDialogOpen((prevState) => ({ ...prevState, open: false }))
        }
        onAction={deleteDialogOpen?.okay}
        okText="Delete"
        okTextStyles={{ color: 'white' }}
      />

      {current3DotMenu && (
        <HoldLoadPopup
          actionType={current3DotMenu.type}
          entityType={EHoldLoadEntityType.INVOICE}
          invoiceData={{
            id: invoiceData?.id,
            invoiceType: invoiceData?.invoiceType,
          }}
          onClose={() => setCurrent3DotMenu(null)}
          onActionCompleted={() => {
            sendEventHoldLoadActionData({
              actionType: current3DotMenu.type,
              entityType: EHoldLoadEntityType.INVOICE,
            });
          }}
        />
      )}
    </>
  );
};
export default InvoiceCustomDetailPanel;
export const getKeyByValue = (object, value) => {
  for (const key in object) {
    if (object[key] === value) {
      return key;
    }
  }
  return null; // Return null if the value is not found
};
