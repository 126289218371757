import { t } from 'i18next';
import { ReactNode } from 'react';
import DeletePopup from '../../../ui-kit/components/DeletePopup';

type PropTypes = {
  open: boolean;
  data?: any;
  actionName?: string;
  actions?: { [key: string]: (data: any) => void };
  onClose: () => void;
  title: string;
  body?: string;
  subTitle?: string;
  cancelText?: string;
  buttonText?: string;
  backgroundColor?: string;
  buttonTextColor?: string;
  width?: string;
  contentRenderer?: () => ReactNode;
};

export default function WarningPopover({
  open,
  data,
  actionName,
  actions,
  onClose,
  title,
  body = '',
  subTitle = '',
  cancelText = title.at(-1) === '?' ? t('cancel') : t('ok'),
  buttonText = t('yes'),
  backgroundColor,
  buttonTextColor,
  width,
  contentRenderer,
}: PropTypes) {
  function hanldleAction() {
    if (!actions || !actionName) return;
    actions[actionName](data);
    onClose();
  }

  return (
    <DeletePopup
      open={open}
      onAction={!actionName || !actions ? undefined : hanldleAction}
      onClose={onClose}
      title={title}
      body={body}
      contentRenderer={contentRenderer}
      subtitle={subTitle}
      cancelText={cancelText}
      buttonText={buttonText}
      backgroundColor={backgroundColor}
      buttonTextColor={buttonTextColor}
      width={width}
    />
  );
}
