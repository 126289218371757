import {
  TimelineOppositeContent,
  TimelineOppositeContentProps,
  TimelineSeparator,
  TimelineSeparatorProps,
} from '@mui/lab';
import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

export const StyledTimelineSeparator = styled(
  (props: TimelineSeparatorProps) => <TimelineSeparator {...props} />
)(({ theme, isActive, sx = {} }: any) => ({
  padding: '6px',
  '& .MuiTimelineDot-root': {
    borderWidth: '4px',
  },
  '& .MuiTimelineConnector-root': {
    backgroundColor: isActive ? theme.palette.primary.main : '',
  },
  ...sx,
}));

export const StyledTimelineOppositeContent = styled(
  (props: TimelineOppositeContentProps) => (
    <TimelineOppositeContent {...props} />
  )
)(() => ({
  padding: '6px',
  flex: 'unset',
  width: '35%',
  zIndex: 1,
}));

export const StyledArrowForwardIos = styled(
  ({ CollapseIcon, ...props }: any) => (
    <CollapseIcon fontSize="medium" {...props} />
  )
)(({ theme, isActive, isHideCollapse }: any) => ({
  cursor: 'pointer',
  color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
  transform: isActive ? 'rotate(90deg)' : '',
  marginBottom: '5px',
  ...(isHideCollapse
    ? {
        visibility: 'hidden',
      }
    : {}),
}));

export const StyledLeftSubTitle = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }: any) => ({
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap',
  ...theme.typography.body3,
}));
