import React, { useEffect, useState } from 'react';
import TabsV2 from '../../../ui-kit/components/TabsV2';
import { UserDetailsTabs } from '../../shared/constants';
import { DetailsTabStripsMenuListInterface } from '../constants';
import { DirtyDetailsPanelManager } from '../../../common/DetailsPanel/utils';
import {
  DriversDetailsIcon,
  GeneralIcon,
  MaintenanceIcon,
  PaymentTermsIcon,
  SafetyAlertsIcon,
} from '../../../ui-kit/components/Assets';

const themeMode = true;
const DetailsTabStrips: React.FC<{
  selected?: UserDetailsTabs;
  handleStripChange: (stripName: string) => void;
  showDriverDetailsStrip: boolean;
  showDocumentsStrip: boolean;
  showTabStrips: boolean;
  keys: UserDetailsTabs[];
  entity?: string;
  showNotes?: boolean;
}> = ({
  handleStripChange,
  selected,
  showDriverDetailsStrip,
  showDocumentsStrip,
  showTabStrips,
  keys,
  entity,
  showNotes,
}) => {
  const [selectedTab, setSelectedTab] =
    useState<DetailsTabStripsMenuListInterface>();
  const [tabList, setTabList] = useState<
    Array<DetailsTabStripsMenuListInterface>
  >([]);
  const isSelected = (strip: string) => {
    return strip === selected;
  };

  const tabListPrepare = (selectedKey = UserDetailsTabs.General) => {
    const DetailsTabStripsMenuList = [
      getTabByKey(UserDetailsTabs.General, selectedKey),
      getTabByKey(UserDetailsTabs.DriverDetails, selectedKey),
      getTabByKey(UserDetailsTabs.Documents, selectedKey, entity),
      getTabByKey(UserDetailsTabs.PaymentTerms, selectedKey),
      getTabByKey(UserDetailsTabs.Notes, selectedKey),
      getTabByKey(UserDetailsTabs.SafetyAlerts, selectedKey),
      getTabByKey(UserDetailsTabs.Preferences, selectedKey),
    ].filter(Boolean) as DetailsTabStripsMenuListInterface[];
    const tabData: DetailsTabStripsMenuListInterface[] = [];

    DetailsTabStripsMenuList.filter((item) => keys.includes(item.key)).forEach(
      (item) => {
        if (
          (!showDocumentsStrip && item.key === UserDetailsTabs.Documents) ||
          (!showDriverDetailsStrip &&
            item.key == UserDetailsTabs.DriverDetails) ||
          (!showDriverDetailsStrip &&
            item.key === UserDetailsTabs.SafetyAlerts) ||
          (!showDriverDetailsStrip && item.key === UserDetailsTabs.Preferences)
        ) {
          return true;
        }

        if (!showTabStrips) {
          if (
            item.key !== UserDetailsTabs.Documents &&
            item.key !== UserDetailsTabs.PaymentTerms &&
            item.key !== UserDetailsTabs.SafetyAlerts &&
            item.key !== UserDetailsTabs.Preferences
          ) {
            tabData.push(item);
          }
        } else {
          tabData.push(item);
        }
      }
    );
    const finalTabsData = showNotes
      ? tabData
      : tabData.filter((item) => item.key !== UserDetailsTabs.Notes);
    setTabList(finalTabsData);

    const tmpSelectedTab = finalTabsData.find((item) => item.key === selected);
    setSelectedTab(tmpSelectedTab ? tmpSelectedTab : finalTabsData[0]);
  };

  useEffect(() => {
    tabListPrepare(selected);
  }, [showDriverDetailsStrip, showTabStrips, selected, showDocumentsStrip]);

  const onManageTabChanged = (data: DetailsTabStripsMenuListInterface) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    handleStripChange(data.key);
    setSelectedTab(data);
    isSelected(data.key);
    tabListPrepare(data.key);
  };

  return (
    <TabsV2<'key', DetailsTabStripsMenuListInterface>
      keyName="key"
      isDarkMode
      autoFill
      labelCentered
      tabs={tabList}
      selectedTab={selectedTab?.key}
      setSelectedTab={onManageTabChanged}
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

const getTabByKey = (
  key: UserDetailsTabs,
  selectedKey?: UserDetailsTabs,
  entity?: string
): DetailsTabStripsMenuListInterface | undefined => {
  switch (key) {
    case UserDetailsTabs.General:
      return {
        label: 'General',
        key: UserDetailsTabs.General,
        icon: (
          <GeneralIcon
            color={UserDetailsTabs.General === selectedKey ? 'Black' : 'white'}
          />
        ),
        labelItemName: 'General',
      };

    case UserDetailsTabs.DriverDetails:
      return {
        label: 'Driver Details',
        key: UserDetailsTabs.DriverDetails,
        icon: (
          <DriversDetailsIcon
            color={
              UserDetailsTabs.DriverDetails === selectedKey ? 'Black' : 'white'
            }
          />
        ),
        labelItemName: 'Driver Details',
      };
    case entity === 'Driver' && UserDetailsTabs.Documents:
      return {
        label: 'Documents',
        key: UserDetailsTabs.Documents,
        icon: (
          <MaintenanceIcon
            color={
              UserDetailsTabs.Documents === selectedKey ? 'Black' : 'white'
            }
          />
        ),
        labelItemName: 'Documents',
      };
    case UserDetailsTabs.PaymentTerms:
      return {
        label: 'Payment Terms',
        key: UserDetailsTabs.PaymentTerms,
        icon: (
          <PaymentTermsIcon
            color={
              UserDetailsTabs.PaymentTerms === selectedKey ? 'Black' : 'white'
            }
          />
        ),
        labelItemName: 'Payment Terms',
      };
    case UserDetailsTabs.SafetyAlerts:
      return {
        label: 'Safety Alerts',
        key: UserDetailsTabs.SafetyAlerts,
        icon: (
          <SafetyAlertsIcon
            color={
              UserDetailsTabs.SafetyAlerts === selectedKey ? 'Black' : 'white'
            }
          />
        ),
        labelItemName: 'Safety Alerts',
      };
    case UserDetailsTabs.Preferences:
      return {
        label: 'Preferences',
        key: UserDetailsTabs.Preferences,
        iconName: 'ManageAccountsOutlined',
        source: 'MUIcons',
      };
    case UserDetailsTabs.Notes:
      return {
        label: 'Notes',
        key: UserDetailsTabs.Notes,
        iconName: 'SpeakerNotesOutlined',
        source: 'MUIcons',
        labelItemName: 'Notes',
      };

    default:
      return undefined;
  }
};

export const tabStripRendererByKeyFS = (key: UserDetailsTabs) => {
  return (
    <TabsV2<'key', DetailsTabStripsMenuListInterface>
      keyName="key"
      isDarkMode
      autoFill
      labelCentered
      tabs={
        [getTabByKey(key, key)].filter(
          Boolean
        ) as DetailsTabStripsMenuListInterface[]
      }
      selectedTab={getTabByKey(key, key)?.key}
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

export default DetailsTabStrips;
