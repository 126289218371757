import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { LoadingComponent } from './common/Loader';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: window.env.DD_APPLICATION_ID || '',
  clientToken: window.env.DD_CLIENT_TOKEN || '',
  site: window.env.DD_SITE,
  service: window.env.DD_SERVICE,
  env: window.env.DD_ENV?.toLowerCase(), //env value always needs to be in lowercase
  version: window.env.DD_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  // allowedTracingOrigins: [window.location.origin],
});
datadogLogs.init({
  clientToken: window.env.DD_CLIENT_TOKEN || '',
  site: window.env.DD_SITE,
  service: window.env.DD_SERVICE,
  env: window.env.DD_ENV?.toLowerCase(), //env value always needs to be in lowercase
  version: window.env.DD_VERSION,
  forwardErrorsToLogs: true,
  sampleRate: 100,
});
datadogRum.getUser();
datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <BrowserRouter>
    <App />
    <LoadingComponent />
  </BrowserRouter>,
  document.getElementById('root')
);
