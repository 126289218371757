import { useRootStore } from '@/store/root-store/rootStateContext';
import { RefreshOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { loadService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { invoiceModuleSetting } from '../../../../../common/PendoClassIDs/constants';
import DatePicker from '../../../../../common/Ui/FormDatePicker/FormDatePicker';
import TextField from '../../../../../common/Ui/TextField/TextField';
import { useLoadPermission } from '../../../../../common/hooks/useLoadPermission';
import {
  CustomerRequiredDocOptions,
  FINANCE_TYPES,
  PER_LOADED_MILE_KEY,
} from '../../../../loadsList/CreateLoad/constants/fieldOptions';
import { createLoadService } from '../../../../loadsList/CreateLoad/services/createLoad.service';
import { rateFormService } from '../../../../loadsList/CreateLoad/services/rate.service';
import { CANCELLED_LOAD_STATUS_NAME } from '../../../constants';
import { InvoiceModel } from '../../../models/InvoiceModel';
export function LoadGroupInvoiceSection2({
  invoice,
  foundIndex,
  type,
  canEditInvoice,
  invoiceData,
}: {
  invoice: InvoiceModel;
  foundIndex: number;
  type: string;
  canEditInvoice: boolean;
  invoiceData: InvoiceModel;
}) {
  const { getCompanyPreferences } = useRootStore();

  const { control, setValue, watch, getValues } = useFormContext();
  const [
    customer,
    invoiceTerm,
    feeDetail,
    loadedMiles,
    milesManuallyUpdated,
    mileageUpdatedBy,
    mileageUpdatedDate,
  ] = watch([
    'customer',
    'invoiceTerm',
    'rate.feeDetail',
    'loadedMiles',
    'milesManuallyUpdated',
    'mileageUpdatedBy',
    'mileageUpdatedDate',
  ]);
  const [isMilesLoading, setIsMilesLoading] = useState<boolean>(false);

  const { hasFinancialInvoiceEdit } = useLoadPermission();

  const disableInvoiceEditing = !hasFinancialInvoiceEdit;

  const invoiceCanceledStatus =
    invoice.status === CANCELLED_LOAD_STATUS_NAME &&
    !invoice.lineItems.length &&
    invoice.nonInvoiceable;

  useEffect(() => {
    setValue('totalMiles', loadedMiles);
  }, [loadedMiles]);

  const handleOnLoadedMilesChange = (totalMiles: any) => {
    const additionalFees = getValues('rate.additionalFees');
    let baseQuantity = getValues('rate.quantity');
    baseQuantity = rateFormService.getBaseQuantityByType({
      type: feeDetail?.itemCode,
      totalMiles,
      quantity: baseQuantity,
    });
    const baseRate = getValues('rate.rate');
    if (feeDetail?.itemCode === FINANCE_TYPES.PER_LOADED_MILE)
      setValue('rate.quantity', baseQuantity);
    const updatedFees = additionalFees?.map((e: any, i: number) => {
      const isPerLoadedType =
        e?.unit === PER_LOADED_MILE_KEY ||
        e?.feeDetail?.unit === PER_LOADED_MILE_KEY;
      const quantity = isPerLoadedType
        ? rateFormService.calculateQuantityByItemUnit({
            unit: e?.unit ?? e?.feeDetail?.unit,
            baseQuantity,
            baseRate,
            totalMiles,
          })
        : e?.quantity;
      const total = isPerLoadedType
        ? rateFormService.calculateLineItemRateByUnit({
            quantity,
            rate: Number(e?.rate),
            unit: e?.unit,
          })
        : e?.total;
      return {
        ...e,
        quantity:
          i !== additionalFees?.length - 1
            ? quantity
            : additionalFees?.[i]?.quantity,
        total:
          i !== additionalFees?.length - 1 ? total : additionalFees?.[i]?.total,
      };
    });
    setValue('rate.additionalFees', updatedFees);
  };

  const handleResetMiles = async () => {
    try {
      setIsMilesLoading(true);
      const res = await loadService.getLoadTotalMilesById(invoiceData?.loadId);
      if (res instanceof ServiceError)
        throw new Error('Failed to fetch load miles.');
      setValue('loadedMiles', res?.loadedMiles);
      setValue('emptyMiles', res?.emptyMiles);
      setValue('milesManuallyUpdated', false);
      setValue('isMilesManuallyUpdated', false);
      setIsMilesLoading(false);
    } catch (error) {
      setIsMilesLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ m: 0, pr: '18px' }}>
        <Grid item xs={6} sx={{ m: 0, p: 0 }}>
          <TextField
            id={invoiceModuleSetting + 'LoadedMiles'}
            control={control}
            sizes={{ xs: 12 }}
            name={'loadedMiles'}
            label={'Loaded (mi)'}
            onChangeCallback={() => {
              setValue('isMilesManuallyUpdated', true);
            }}
            onBlurCallback={handleOnLoadedMilesChange}
            onlyNumbers
            disabled={disableInvoiceEditing || invoiceCanceledStatus}
            style={{ m: 0, p: 0 }}
          />

          {milesManuallyUpdated ? (
            <Typography
              fontSize="x-small"
              variant="caption"
              color="text.secondary"
            >
              {mileageUpdatedBy},{' '}
              {moment(mileageUpdatedDate).format('MM/DD/YYYY')}
            </Typography>
          ) : (
            <Typography
              fontSize="x-small"
              variant="caption"
              color="text.secondary"
            >
              Estimated by PC Miler
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} sx={{ m: 0, p: 0 }}>
          <Stack direction={'row'} gap={1} alignItems={'end'}>
            <TextField
              id={invoiceModuleSetting + 'InternalInvoiceNotes'}
              onChangeCallback={() => {
                setValue('isMilesManuallyUpdated', true);
              }}
              control={control}
              sizes={{ xs: 12 }}
              name={'emptyMiles'}
              label={'Empty  (mi)'}
              disabled={disableInvoiceEditing || invoiceCanceledStatus}
              style={{ m: 0, p: 0 }}
            />

            {isMilesLoading ? (
              <CircularProgress sx={{ maxWidth: '18px', maxHeight: '18px' }} />
            ) : (
              <Tooltip title="Reset miles">
                <IconButton onClick={handleResetMiles}>
                  <RefreshOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            iso
            format
            disabled
            timezone={getCompanyPreferences?.timezone}
            control={control}
            name={'completeDate'}
            label={'Completed Date'}
            styleProps={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="requiredDocuments"
            render={({ field }) => (
              <Autocomplete
                sx={{
                  flexGrow: 1,
                  '& .MuiInputBase-root': {
                    paddingLeft: '0!important',
                    paddingRight: '0!important',
                  },
                  '& .MuiChip-root': { height: '26px' },
                }}
                id="createLoad-invoice-requiredDocuments"
                options={CustomerRequiredDocOptions}
                multiple
                isOptionEqualToValue={(option, val) => {
                  return val === option?.id;
                }}
                getOptionLabel={(option) =>
                  createLoadService.getAutocompleteOptionLabel({
                    list: CustomerRequiredDocOptions,
                    value: option,
                    fieldName: 'label',
                  })
                }
                {...field}
                onChange={(_, value) => {
                  field.onChange(value?.map((e) => e?.id ?? e));
                }}
                renderInput={(params) => (
                  <TextField name={''} {...params} label="Required Documents" />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
