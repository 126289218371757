import React, { useEffect } from 'react';
import TextField from '../../Ui/TextField/TextField';
import Switch from '../../Ui/Switcher';
import { useFormContext } from 'react-hook-form';
import IconPicker from '../../Ui/IconPicker';
import { t } from 'i18next';
type PagePropTypes = {
  customErrors: any;
};

export default function ViewBaseInfoForm({ customErrors }: PagePropTypes) {
  const { control, setError } = useFormContext();

  useEffect(() => {
    if (customErrors) {
      setError('name', {
        type: 'custom',
        message: customErrors.name,
      });
    }
  }, [customErrors]);

  return (
    <>
      <TextField
        control={control}
        name={'name'}
        label={t('modalEnterViewName')}
        required
        helperText={'0/20 Chair limit'}
        sizes={{ xs: 12 }}
      />

      <IconPicker control={control} name={'iconName'} />
      <Switch
        control={control}
        label={t('shareWithTeam')}
        name={'shared'}
        defaultChecked
        style={{ paddingTop: '10px' }}
      />
    </>
  );
}
