import React, { FC } from 'react';
import IconsComponent, { Props } from '../IconsComponent';
import { ICON_SIZE } from './constants';

const StyledIconsComponent: FC<Props> = (props) => {
  return (
    <IconsComponent
      styleProps={{
        maxWidth: '1em',
        width: 'unset',
        height: ICON_SIZE,
      }}
      {...props}
    />
  );
};

export default StyledIconsComponent;
