import { useState } from 'react';
import Box from '@mui/material/Box';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../ui-kit/components/DeletePopup';
import IconsComponent from '../../ui-kit/components/IconsComponent';
import CancelSubscription from './CancelSubscription';
import { AXELE_PERMISSION_TYPE, Permission } from '../../common/Permission';
import { styles } from './styles';
import { subscriptionService } from '../../api';
import { CancelSubscriptionRequest } from '../../models';

type ContactUsProps = {
  subscriptionData: any;
  url: string;
  getSubscription: () => void;
  showCancelSubscription?: boolean;
};

const ContactUs = ({
  subscriptionData,
  url,
  getSubscription,
  showCancelSubscription = true,
}: ContactUsProps) => {
  const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  return (
    <Box
      id="contact-block"
      sx={(theme) => ({
        ...styles.contactContainer,
        background: theme.palette.info.background,
      })}
    >
      <Box sx={styles.flexContact}>
        <Box sx={styles.title}>Contact Support</Box>
        <Box
          sx={(theme) => ({
            ...theme.typography.body2,
            textAlign: 'center',
            color: 'text.primary',
          })}
        >
          Our support team is here to help with any questions you may have.
        </Box>
      </Box>
      <Box sx={styles.contactSupport}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <ButtonImproved
            label="Call Us"
            styleProps={{
              marginRight: '7px',
              marginBottom: '10px',
              width: '166px',
            }}
            variant="contained"
            startIcon={
              <IconsComponent
                styleProps={{ height: '15px', width: '15px' }}
                iconName="CallOutlined"
                source="MUIcons"
              />
            }
            onClick={() => {
              setPhoneNumber(true);
              setEmail(false);
            }}
          />
          <ButtonImproved
            onClick={() => {
              setPhoneNumber(false);
              setEmail(true);
            }}
            styleProps={{ marginBottom: '10px', width: '166px' }}
            label="Email Us"
            variant="contained"
            startIcon={
              <IconsComponent
                styleProps={{ height: '15px', width: '15px' }}
                iconName="EmailOutlined"
                source="MUIcons"
              />
            }
          />
        </Box>
        {phoneNumber && (
          <Box
            sx={(theme) => ({
              ...theme.typography.body2,
              ...styles.contactPhone,
            })}
          >
            Phone Number:{' '}
            <Box
              sx={(theme) => ({
                ...theme.typography.body2Bold,
                marginLeft: '5px',
              })}
            >
              1-833-293-5301
            </Box>
          </Box>
        )}
        {email && (
          <Box
            sx={(theme) => ({
              ...theme.typography.body2,
              ...styles.contactPhone,
            })}
          >
            Email:{' '}
            <Box
              sx={(theme) => ({
                ...theme.typography.body2Bold,
                marginLeft: '5px',
              })}
            >
              loadops.support@optym.com
            </Box>
          </Box>
        )}
        {subscriptionData?.state === 'past_due' ? null : (
          <>
            {subscriptionData?.cancel_at_end_of_period ? (
              <>
                <DeletePopup
                  open={isDeletePopupOpen}
                  title="Delete Cancellation"
                  body="Are you sure you want to delete delayed cancellation of account?"
                  onAction={async () => {
                    const query = new CancelSubscriptionRequest();
                    query.delete = true;
                    const response = await subscriptionService.payAndCancel(
                      query
                    );
                    if (response) getSubscription();
                    setIsDeletePopupOpen(false);
                  }}
                  onClose={() => setIsDeletePopupOpen(false)}
                />
                {/* <ButtonImproved
                  styleProps={{ width: '100%' }}
                  onClick={() => setIsDeletePopupOpen(true)}
                  label="Delete Cancellation"
                  variant="outlined"
                  color="error"
                /> */}
              </>
            ) : (
              showCancelSubscription && (
                <Permission
                  includes={[AXELE_PERMISSION_TYPE.SUBSCRIPTION_REMOVE]}
                >
                  <ButtonImproved
                    onClick={() => setShowCancelPopup(true)}
                    styleProps={{ width: '100%' }}
                    label="Cancel Subscription"
                    variant="outlined"
                    startIcon={
                      <IconsComponent
                        styleProps={{ height: '15px', width: '15px' }}
                        iconName="ExitToAppOutlined"
                        source="MUIcons"
                      />
                    }
                  />
                </Permission>
              )
            )}
          </>
        )}
        {showCancelPopup && subscriptionData?.id ? (
          <CancelSubscription
            subscriptionData={subscriptionData}
            onclose={() => setShowCancelPopup(false)}
            url={url}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default ContactUs;
