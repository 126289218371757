import { observer } from 'mobx-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { financeLoadService, loadService } from '../../../../../../api';
import { ServiceError } from '../../../../../../api/interfaces';
import { useRoutesLoad } from '../../../../../../contexts/LoadRoutesContext';
import { assignCarrierToLoadParams } from '../../../../../../models';
import {
  LoadCarrierExpenseDTO,
  LoadCarrierExpenseDTORequest,
} from '../../../../../../models/DTOs/FinanceLoadService';
import { useDetailsPanelStore } from '../../../../../../store/DetailsPanel';
import { ESecondaryDetailsPanelType } from '../../../../../../views/dispatch2/constants/types';
import ButtonGroupRadio from '../../../../../Ui/ButtonGroup';
import { SingleAutocompleteForm } from '../../../../../Ui/SingleAutocomplete';
import { ELoadStatus } from '../../../../constants/constants';
import { Trip } from '../../Models';
import RoutesController from '../../RoutesController';
import AssignmentFormWrapper from '../AssignmentFormWrapper';
import PaymentAmountAssignComponent from '../DriverAssignment/DriverAmountComponent';

const DisabledPlanActions = [
  ELoadStatus.LOAD_COMPLETED,
  ELoadStatus.INVOICED,
  ELoadStatus.PAID,
  ELoadStatus.CANCELLED,
];

const CarrierAssignmentToLoad = ({
  trip,
  onClose,
  onAssign,
  panelType,
}: {
  trip: Trip;
  onClose: () => void;
  onAssign: (carrierGroup: any, assets: any) => void;
  panelType?: ESecondaryDetailsPanelType;
}): JSX.Element => {
  const { setDriverAmountForAssign, driverAmountForAssign } = useRoutesLoad();
  const { trackPromise } = useDetailsPanelStore;
  const [data] = useState({
    driverGroup: null,
    loadStatus: 'OFFERED_TO_DRIVER',
  });

  const [selectedCarrierExpenseDTO, setSelectedCarrierExpenseDTO] =
    useState<LoadCarrierExpenseDTO | null>(null);

  const handleCarrierSelection = (carrierExpenseDTO: LoadCarrierExpenseDTO) => {
    setSelectedCarrierExpenseDTO({
      ...carrierExpenseDTO,
    });
  };

  const handleAssign = async (data: any) => {
    const carrierId = data?.carrierGroup?.id;
    if (data.carrierGroup?.id) {
      const request = new assignCarrierToLoadParams({
        carrierId: carrierId,
        tripId: trip.id,
        tripAssignmentStatus: trip.completed
          ? trip.tripStatus
          : data.loadStatus,
      });
      const response = await trackPromise(
        loadService.assignCarrierToLoad(request),
        panelType
      );
      if (response instanceof ServiceError) return;

      const payload = driverAmountForAssign?.[carrierId];
      if (payload && response?.carrierExpenseDTO?.id) {
        await trackPromise(
          financeLoadService.updateLoadCarrierExpense(
            new LoadCarrierExpenseDTORequest({
              ...payload,
              tripId: response?.carrierExpenseDTO?.tripId,
              seqNumber: response?.carrierExpenseDTO?.seqNumber,
              carrierId: response?.carrierExpenseDTO?.carrierId,
              status: response?.carrierExpenseDTO?.status,
              id: response?.carrierExpenseDTO?.id,
            })
          ),
          panelType
        );
      }
      onAssign(data.carrierGroup, response);
      setDriverAmountForAssign(null);
      onClose();
    }
  };

  return (
    <AssignmentFormWrapper
      onAction={handleAssign}
      onClose={onClose}
      data={data}
      title="Assign Carrier"
      actionLabel="Save"
    >
      <>
        <FormComponent trip={trip} onCarrierSelect={handleCarrierSelection} />
        {selectedCarrierExpenseDTO && (
          <PaymentAmountAssignComponent items={[selectedCarrierExpenseDTO]} />
        )}
      </>
    </AssignmentFormWrapper>
  );
};

export const FormComponent = ({
  trip,
  onCarrierSelect,
}: {
  trip: Trip;
  onCarrierSelect: (newData: LoadCarrierExpenseDTO) => void;
}) => {
  const { setDriverAmountForAssign } = useRoutesLoad();
  const { control } = useFormContext();

  const onCarrierChange = async (data) => {
    setDriverAmountForAssign(null);

    const paymentDetails =
      await financeLoadService.getTripCarrierAssignmentPreview(
        trip.id,
        data.id
      );
    if (paymentDetails instanceof LoadCarrierExpenseDTO) {
      onCarrierSelect({
        ...data,
        paymentDetails: paymentDetails,
      });
    } else {
      onCarrierSelect({
        ...data,
        paymentDetails: {
          items: [],
          carrierId: data.id,
          total: 0,
        },
      });
    }
  };

  const disabledPlanAction = DisabledPlanActions.includes(trip.tripStatus);

  return (
    <>
      <SingleAutocompleteForm
        control={control}
        disableClear
        name="carrierGroup"
        fieldName="name"
        label="Carrier"
        onChangeCb={(data) => onCarrierChange(data)}
        getOptions={(name, pageNumber) =>
          RoutesController.getCarrierList(name, pageNumber)
        }
      />
      <ButtonGroupRadio
        switcher
        fullwidth
        name="loadStatus"
        disabled={disabledPlanAction}
        options={[
          { key: 'ASSIGNMENT_PLANNED', value: 'Plan' },
          { key: 'OFFERED_TO_DRIVER', value: 'Assign' },
        ]}
      />
    </>
  );
};

export default observer(CarrierAssignmentToLoad);
