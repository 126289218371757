import * as yup from 'yup';
import {
  nullableStringMaxValidation,
  requiredPhoneValidationSchema,
} from '../../../../utils';
import { defaultContactValidationSchema } from '../../../../validator/validationSchemas';
const errorMsgConfig = {
  companyName: {
    required: 'Terminal Name is required.',
    validation: 'Terminal Name cannot be longer than 255 characters',
  },
  dot: {
    required: 'DOT Number is required.',
  },
  mc: {
    validation: 'MC Number cannot be longer than 8 characters',
    typeError: 'MC Number can contain only digits',
  },
  contactName: {
    required: 'Contact is required.',
  },
  phoneData: {
    required: 'Phone is required.',
  },
  email: {
    required: 'Email is required.',
  },
  addressData: {
    required: 'Address is required.',
  },
};

export const TerminalValidationSchema = yup.object().shape({
  companyName: yup
    .string()
    .required(errorMsgConfig.companyName.required)
    .max(255, errorMsgConfig.companyName.validation)
    .typeError(errorMsgConfig.companyName.required),
  dot: yup.string().required(errorMsgConfig.dot.required),
  mc: yup
    .number()
    .nullable()
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .typeError(errorMsgConfig.mc.typeError)
    .max(9999999, errorMsgConfig.mc.validation),
  contactName: nullableStringMaxValidation(50).required(
    errorMsgConfig.contactName.required
  ),
  email: defaultContactValidationSchema.email.required(
    errorMsgConfig.email.required
  ),
  phoneData: requiredPhoneValidationSchema.required(
    errorMsgConfig.phoneData.required
  ),
  addressData: yup
    .object()
    .shape({
      streetAddress: nullableStringMaxValidation(50).required(
        'Address is required.'
      ),
      city: yup.string().required('City is required.'),
      state: yup.string().required('State is required.'),
    })
    .required(errorMsgConfig.addressData.required),
});
