import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { contactFormModule } from '../../../../../../common/PendoClassIDs/constants';
import FormPhoneField from '../../../../../../common/Ui/FormPhoneField';
import Switch from '../../../../../../common/Ui/Switcher';
import TextField from '../../../../../../common/Ui/TextField/TextField';

const defaultTextFieldProps = {
  style: { marginBottom: 0 },
};
export default function AddContactForm() {
  const { control, getValues } = useFormContext();
  const isPrimary: boolean = useMemo(() => getValues('isPrimary'), []);

  return (
    <Grid spacing={1} container>
      <TextField
        id={contactFormModule + 'Name'}
        control={control}
        name="name"
        label="Name"
        sizes={{
          xs: 12,
        }}
        required
        {...defaultTextFieldProps}
      />
      <FormPhoneField
        control={control}
        name="phoneData"
        label="Phone #"
        sizes={{ xs: 12 }}
        gridSxProp={{
          mt: 1,
          mb: 0,
        }}
      />
      <TextField
        id={contactFormModule + 'Email'}
        control={control}
        name="email"
        label="Email"
        sizes={{ xs: 12 }}
        {...defaultTextFieldProps}
      />
      <TextField
        id={contactFormModule + 'Description'}
        control={control}
        name="description"
        label="Description"
        sizes={{ xs: 12 }}
        {...defaultTextFieldProps}
      />
      <Box sx={{ mt: 3, ml: 1 }}>
        <Switch
          id={contactFormModule + 'Primary'}
          control={control}
          name="isPrimary"
          label="Primary"
          sizes={{ xs: 12 }}
          disabled={isPrimary}
        />
      </Box>
    </Grid>
  );
}
