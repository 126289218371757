import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { loadboardService } from '../../../api';
import {
  LoadboardRequest,
  LoginAccountIntegration,
} from '../../../models/DTOs/Loadboard/Requests';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import SelectedCustomerLoadboard from '../../../subPages/integrations/loadboard/SelectedCustomer/SelectedCustomerLoadboard';
import {
  IAllCustomers,
  ICustomerCredentials,
  IcustomerListWithLogo,
  IloadboardProviderData,
} from '../../../types/LoadboardTypes';
import CustomersList from '../../../ui-kit/components/CustomersList';
import { LoadboardCustomerMap } from '../../../utils/customerMap';
import TerminalController from '../terminals/TerminalController';

const LoadboardIntegration = () => {
  const [loadboardProviderData, setLoadboardProviderData] = useState();
  const [customerListWithLogo, setCustomerListWithLogo] = useState();
  const [selectedProviderDetails, setSelectedProviderDetails] = useState();
  const [allCustomerCredentials, setAllCustomerCredentials] = useState([]);
  const [selectedCustomerCredentials, setSelectedCustomerCredentials] =
    useState({});
  const [selectCustomerFeatures, setSelectCustomerFeatures] = useState([]);
  const [credentialSteps, setCredentialSteps] = useState([]);
  const [integrationDTO, setIntegrationDTO] = useState({});
  const [allTerminals, setAllTerminals] = useState<any[] | null>([]);
  const [userTerminalIds, setUserTerminalIds] = useState<string[]>([]);
  const [cbCounterCustomerList, setCbCounterCustomerList] = useState<number>(0);
  const [cbCounterCustomerCredentials, setCbCounterCustomerCredentials] =
    useState<number>(0);
  const [cbCounterLoadboardProvider, setCbCounterLoadboardProvider] =
    useState<number>(0);

  const { terminals } = TerminalController.instance();
  const { getGlobalTerminalsIds } = useRootStore();
  const didMount = useRef(false);

  useEffect(() => {
    const renderOnMount = async () => {
      try {
        await getProviderDetails(userTerminalIds);
        // await getTerminals(terminals);
        getSearchParams();
      } catch (err) {}
    };
    renderOnMount();
  }, []);

  /* Use State CB */

  useEffect(() => {
    if (!didMount.current) {
      initialSelectedProvider(customerListWithLogo);
      getIntegrationCredentials(
        loadboardProviderData,
        customerListWithLogo && customerListWithLogo.length > 0
          ? customerListWithLogo[0]['providerId']
          : null
      );
      getIntegrationDTO(loadboardProviderData);
    } else {
      didMount.current = true;
    }
  }, [cbCounterCustomerList]);

  useEffect(() => {
    if (!didMount.current) {
      createCredentialsSteps(selectedCustomerCredentials);
    } else {
      didMount.current = true;
    }
  }, [cbCounterCustomerCredentials]);

  useEffect(() => {
    if (!didMount.current) {
      getCustomerList(loadboardProviderData);
      getIntegrationDTO(loadboardProviderData);
    } else {
      didMount.current = true;
    }
  }, [cbCounterLoadboardProvider]);

  const getSearchParams = () => {
    const url = new URL(window.location.href);
    if (url.search) {
      const oAuthloadboardCred = sessionStorage.getItem('123Loadboard');
      const creds = JSON.parse(oAuthloadboardCred || '{}');
      const params = new URLSearchParams(url.search);
      if (creds?.data && creds?.data.length > 0) {
        creds.data.push(
          { name: 'CODE', value: params.get('code') },
          {
            name: 'REDIRECTURI',
            value: `${window.location.origin}/settings/integrations`,
          }
        );
        const isReauthenticateCred = sessionStorage.getItem(
          '123LoadboardIsReauthenticate'
        );
        const isReauthenticate = JSON.parse(isReauthenticateCred || 'false');
        if (isReauthenticate) {
          reauthenticate(creds);
        } else {
          activateProvider(creds);
        }
      }
    }
  };

  const activateProvider = async (payload: any) => {
    try {
      const request = new LoginAccountIntegration(payload);
      await loadboardService.createLoginCard(request);
      sessionStorage.removeItem('123Loadboard');
      window.location.replace('/settings/integrations');
    } catch (error: any) {}
  };

  const reauthenticate = async (payload: any) => {
    const request = new LoginAccountIntegration(payload);
    try {
      await loadboardService.editDATCredential(request);
      sessionStorage.removeItem('123Loadboard');
      sessionStorage.removeItem('123LoadboardIsReauthenticate');
      window.location.replace('/settings/integrations');
    } catch (error) {}
  };

  const revalidateLoadboard = (cardData: {
    id: string;
    data: [];
    organizationId: number;
    providerId: number;
    terminalIds: string[];
  }) => {
    if (cardData['providerId'] === 4) {
      const terminals = cardData.terminalIds;
      const name = (cardData?.data?.find(
        (el: { name: string; value: string }) => el.name
      )).value;
      const payload = {
        data: [{ name: 'DISPLAY_NAME', value: name }],
        integrationType: 'LOADBOARD',
        organizationId: cardData.organizationId,
        providerId: cardData.providerId,
        terminalIds: terminals,
        id: cardData.id,
      };

      sessionStorage.setItem('123Loadboard', JSON.stringify(payload));
      sessionStorage.setItem(
        '123LoadboardIsReauthenticate',
        JSON.stringify(true)
      );
      const url = `${window.env['123LOADBOARD_BASE_URL']}/authorize?response_type=code&client_id=${window.env.ONE_TWO_THREE_LOADBOARD_CLIENT_ID}&redirect_uri=${window.location.origin}/${process.env['123LOADBOARD_REDIRECT_URI']}`;
      window.location = url;
    }
  };

  const onRefresh = () => {
    getProviderDetails(userTerminalIds);
  };

  const getIntegrationDTO = (response: IloadboardProviderData) => {
    if (
      response &&
      response['loadBoardIntegrations'] &&
      Object.keys(response['loadBoardIntegrations']).length > 0
    ) {
      if (
        response &&
        response?.['loadBoardIntegrations'] &&
        response?.['loadBoardIntegrations']?.['4'] &&
        response?.['loadBoardIntegrations']?.['4'].length > 0
      ) {
        response?.['loadBoardIntegrations']?.['4'].map((data: any) => {
          data &&
            data.data &&
            data.data.length > 0 &&
            data.data.map((dataItem: any) => {
              if (dataItem['name'] === 'ACCESS_TOKEN') {
                dataItem['name'] = 'accessToken';
              }
            });
        });
      }

      setIntegrationDTO(response);
    }
  };

  const getCustomerList = (
    loadboardProviderData: IloadboardProviderData
  ): void => {
    const allCustomers: IcustomerListWithLogo[] = [];
    loadboardProviderData?.providerStatusMap?.forEach(
      (data: { providerId: number; status: string }) => {
        if (data.providerId > 0) {
          LoadboardCustomerMap.forEach((item) => {
            if (data.providerId === item.providerId) {
              const customer: IAllCustomers = {
                providerId: item.providerId,
                providerName: item.providerName,
                logo: item.logo,
                status: data.status,
                description: item.description,
                logoWhite: item.logoWhite,
                gradient: item.gradient,
                logoWidth: item?.logoWidth,
              };
              allCustomers.push(customer);
            }
          });
        }
      }
    );
    setCustomerListWithLogo(allCustomers);
    setCbCounterCustomerList((prev) => prev + 1);
  };

  const initialSelectedProvider = (allCustomers: Array<IAllCustomers>) => {
    const providerDetailsId = Number(
      sessionStorage.getItem('selectedProviderDetails')
    );
    if (allCustomers && allCustomers.length > 0 && providerDetailsId) {
      const item = allCustomers.filter(
        (allCustomersItem: any) =>
          providerDetailsId === allCustomersItem['providerId']
      );
      if (item && item.length > 0) {
        setSelectedProviderDetails(item[0]);
      }
    } else {
      if (allCustomers && allCustomers.length > 0 && allCustomers[0]) {
        setSelectedProviderDetails(allCustomers[0]);
      }
    }
  };

  const selectProvider = (data: IcustomerListWithLogo[]) => {
    sessionStorage.setItem('selectedProviderDetails', data['providerId']);
    setSelectedProviderDetails(data);
    selectCredentials(allCustomerCredentials, data['providerId']);
  };

  const selectCredentials = (
    allCustomerCredentials: Array<ICustomerCredentials>,
    providerID: null
  ) => {
    if (
      !(allCustomerCredentials && allCustomerCredentials.length > 0) ||
      !providerID
    ) {
      return;
    }
    allCustomerCredentials.forEach((item: ICustomerCredentials) => {
      const providerDetailsId = Number(
        sessionStorage.getItem('selectedProviderDetails')
      );
      if (providerDetailsId) {
        providerID = providerDetailsId;
      }
      if (item['id'] === providerID) {
        setSelectCustomerFeatures(item['features']);
        setSelectedCustomerCredentials(item);
        setCbCounterCustomerCredentials((prev) => prev + 1);
        return;
      }
    });
  };

  const createCredentialsSteps = (data: ICustomerCredentials) => {
    try {
      const stepsData: any = [];
      const groupedFields = groupByStep(data['fields'], 'stepNumber');
      for (const item in groupedFields) {
        const steps: any = {};
        if (data && data['stepDescriptions']) {
          steps['displayStepName'] = data['stepDescriptions'][1]['stepName'];
          steps['description'] = data['stepDescriptions'][1]['text'];
          steps['hasReadmeGuide'] = data['stepDescriptions'][1]['url']
            ? true
            : false;
          steps['readmeURL'] = data['stepDescriptions'][1]['url']
            ? data['stepDescriptions'][1]['url']
            : null;
        }
        steps['hasFields'] = true;
        if (data && ![1, 7].includes(data['id'])) {
          groupedFields[item].map((item: any) => {
            const displayName = item['displayText'].split(' ');
            // Astrick Notes For Field
            item['displayText'] =
              displayName[displayName.length - 1] === '*'
                ? item['displayText']
                : `${item['displayText']} *`;
          });
        }
        steps['fields'] = groupedFields[item];

        stepsData.push(steps);
      }
      setCredentialSteps(stepsData);
    } catch (error) {
      throw error;
    }
  };

  const groupByStep = (
    integrationCredentials: Array<any>,
    groupByKey: string
  ) => {
    try {
      const stepFields: any = {};
      if (integrationCredentials) {
        for (const itemCredentials of integrationCredentials) {
          if (!stepFields[itemCredentials[groupByKey]])
            stepFields[itemCredentials[groupByKey]] = [];
          stepFields[itemCredentials[groupByKey]].push(itemCredentials);
        }
        return stepFields;
      }
    } catch (error) {
      throw error;
    }
  };

  const getIntegrationCredentials = (
    loadboardIntegration: IloadboardProviderData,
    selectedProviderID: number | null
  ) => {
    setAllCustomerCredentials(
      loadboardIntegration &&
        loadboardIntegration['integrationRequiredCredentials']
        ? loadboardIntegration['integrationRequiredCredentials']
        : []
    );

    selectCredentials(
      loadboardIntegration &&
        loadboardIntegration['integrationRequiredCredentials']
        ? loadboardIntegration['integrationRequiredCredentials']
        : [],
      selectedProviderID
    );
  };

  const getTerminals = async () => {
    await Promise.all([TerminalController.instance().getTerminals()]).then(
      (res) => {
        const terminalIds: Array<string> = [];
        res &&
          res.length > 0 &&
          res.map((data: any) => {
            data &&
              data.length > 0 &&
              data.map((el: any) => {
                terminalIds.push(el.id);
              });
          });
        setAllTerminals(res[0]);
      }
    );
    globalTerminals();
  };

  const globalTerminals = (): void => {
    if (getGlobalTerminalsIds && getGlobalTerminalsIds.length <= 0) {
      const terminalIds =
        terminals && terminals.length > 0 && terminals.map((el: any) => el.id);
      setUserTerminalIds(terminalIds);
      getProviderDetails(terminalIds);
    } else if (
      userTerminalIds &&
      userTerminalIds?.length == getGlobalTerminalsIds?.length
    ) {
      const idDifference = userTerminalIds?.filter(
        (terminalId) => !getGlobalTerminalsIds.includes(terminalId)
      );
      if (!idDifference) {
        setUserTerminalIds(getGlobalTerminalsIds);
        getProviderDetails(getGlobalTerminalsIds);
        getGlobalTerminalsIds;
      }
    } else {
      setUserTerminalIds(getGlobalTerminalsIds);
      getProviderDetails(getGlobalTerminalsIds);
    }
  };

  useEffect(() => {
    getTerminals();
  }, [terminals, getGlobalTerminalsIds]);

  const getProviderDetails = async (terminalIds?: string[] | null) => {
    const request = new LoadboardRequest();
    request.terminalIds = terminalIds ? terminalIds : [];
    const response = await loadboardService.getLoadboardProvidersList(request);
    setLoadboardProviderData(response);
    setCbCounterLoadboardProvider((prev) => prev + 1);
  };
  return (
    <Grid container>
      <Grid item xs={2} px={2} sx={{ height: '76vh', overflow: 'auto' }}>
        {selectedProviderDetails && (
          <CustomersList
            allCustomers={customerListWithLogo}
            selectedProviderDetails={selectedProviderDetails}
            selectProvider={selectProvider}
          />
        )}
      </Grid>
      <Grid
        item
        xs={10}
        className="selected-transporter-block"
        sx={{ height: '76vh', overflow: 'auto' }}
      >
        {selectedProviderDetails && (
          <SelectedCustomerLoadboard
            currentCustomerData={selectedProviderDetails}
            loadboardProviderData={loadboardProviderData}
            activateProvider={activateProvider}
            activationStatus={''}
            getProviderDetails={getProviderDetails}
            allTerminals={allTerminals}
            credentialSteps={credentialSteps}
            selectCustomerFeatures={Object.keys(selectCustomerFeatures)}
            onRefresh={onRefresh}
            revalidateLoadboard={revalidateLoadboard}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default observer(LoadboardIntegration);
