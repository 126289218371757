import { leftMenuContants, subMenuContants } from './constants';

import { flatten } from 'lodash';
import { PermissionRouteProps } from '../../auxComponents/PermissionRoute';

export const getPermissionRouteByPathId = (
  pathId: string
): PermissionRouteProps | null => {
  const menuConfig = leftMenuContants.find((item) => item.id === pathId);
  if (menuConfig) {
    return {
      permission: menuConfig.permission || undefined,
      permit: menuConfig.permit || undefined,
      roleCodes: menuConfig.roleCodes,
    };
  }
  const subMenuConfig = flatten(Object.values(subMenuContants)).find(
    (item) => item.id === pathId
  );
  if (subMenuConfig) {
    return {
      permission: subMenuConfig.permission || undefined,
      permit: subMenuConfig.permit || undefined,
      roleCodes: subMenuConfig.roleCodes,
    };
  }
  //return null when route do not have any permission setting
  return null;
};
