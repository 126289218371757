import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
  Box,
  IconButton,
  InputAdornment,
  Popover,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro-v5';
import {
  StaticDateRangePicker,
  StaticDateRangePickerProps,
} from '@mui/x-date-pickers-pro-v5/StaticDateRangePicker';
import moment, { Moment, MomentInput } from 'moment';
import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import InputLabel from '../../InputLabel';
import {
  isTimezoneValid,
  toTimezoneMoment,
  useTimezoneAdapterMoment,
} from '../Utils';
import {
  getFormatedDate,
  isValidDateRangeMomentObject,
} from './utils/date.utils';

export interface SingleDateRangePickerProps {
  id?: string;
  format?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  startDate?: MomentInput;
  endDate?: MomentInput;
  handleChange?: (startDate: Date | null, endDate: Date | null) => void;
  styleProps?: SxProps<Theme>;
  styleTextFieldProps?: SxProps<Theme>;
  label?: React.ReactNode;
  disabled?: boolean;
  clearable?: boolean;
  rest?: Partial<StaticDateRangePickerProps<Moment | null, Moment>>;
  dateRangeIcon?: React.ReactNode;
  isShowDateRangeIcon?: boolean;
  size?: 'small' | 'medium';
  customInputLabelProps?: CSSProperties;
  timezone?: string;
  withTimeTz?: boolean;
  fixedLabel?: boolean;
  placeholder?: string;
}

export const SingleDateRangePicker = ({
  id = '',
  format = 'MMM Do YY',
  variant = 'standard',
  startDate: startDateInput = null,
  endDate: endDateInput = null,
  handleChange,
  styleProps = {},
  styleTextFieldProps = {},
  label = '',
  disabled = false,
  clearable = true,
  rest = {},
  dateRangeIcon,
  isShowDateRangeIcon = true,
  size,
  customInputLabelProps,
  timezone,
  withTimeTz = false,
  fixedLabel,
  placeholder,
}: SingleDateRangePickerProps): JSX.Element => {
  const [momentDateRanges, setMomentDateRanges] = useState<
    DateRange<Moment | null>
  >([
    startDateInput ? moment(startDateInput) : null,
    endDateInput ? moment(endDateInput) : null,
  ]);

  useEffect(() => {
    const newDateRanges: DateRange<Moment | null> = [
      startDateInput ? moment(startDateInput) : null,
      endDateInput ? moment(endDateInput) : null,
    ];
    setMomentDateRanges(newDateRanges);
  }, [startDateInput, endDateInput]);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onClickHandler = useCallback(
    (event: SyntheticEvent<Element, Event>): void => {
      if (disabled) return;
      setAnchorEl(event?.target);
    },
    [setAnchorEl, disabled]
  );

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);

  const displayValue = useMemo(() => {
    if (momentDateRanges[0] || momentDateRanges[1]) {
      const getDateStringFormat = (inputMoment: Moment | null) => {
        if (inputMoment) {
          const zonedMoment = isTimezoneValid(timezone)
            ? inputMoment.clone().tz(timezone as string)
            : inputMoment.clone();
          return zonedMoment.format(format);
        }
        return 'N/A';
      };
      const tmpStr: string[] = [
        getDateStringFormat(momentDateRanges[0]),
        getDateStringFormat(momentDateRanges[1]),
      ];

      return tmpStr.join(' - ');
    }
    return '';
  }, [format, momentDateRanges, timezone]);

  const onDateChangeHandler = useCallback(
    (newValue: DateRange<Moment>): void => {
      if (isValidDateRangeMomentObject(newValue)) {
        setMomentDateRanges(newValue);
        const parsedValue = getFormatedDate(newValue);
        if (withTimeTz) {
          const startOfDate = newValue[0]
            ? newValue[0].clone().startOf('day').toDate()
            : null;
          const endOfDate = newValue[1]
            ? newValue[1].clone().endOf('day').toDate()
            : null;
          handleChange?.(startOfDate, endOfDate);
        } else {
          handleChange?.(parsedValue[0], parsedValue[1]);
        }
      }
    },
    [handleChange, withTimeTz]
  );

  const onClearHandler = useCallback(
    (event: SyntheticEvent<Element, Event>): void => {
      event.stopPropagation();
      onDateChangeHandler([null, null]);
    },
    [onDateChangeHandler]
  );

  const adapter = useTimezoneAdapterMoment(timezone);

  return (
    <Box
      sx={{
        width: '100%',
        ...styleProps,
      }}
    >
      <TextField
        id={`${id}-InputField`}
        disabled={disabled}
        label={fixedLabel ? <InputLabel>{label}</InputLabel> : label}
        value={displayValue}
        size={size}
        sx={{
          width: '100%',
          ...styleTextFieldProps,
        }}
        InputLabelProps={{
          style: customInputLabelProps,
          fixedLabel,
        }}
        variant={variant}
        {...((clearable || isShowDateRangeIcon) && {
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                {!disabled &&
                  clearable &&
                  (momentDateRanges[0] || momentDateRanges[1]) && (
                    <IconButton
                      onClick={onClearHandler}
                      sx={{
                        padding: '8px 0',
                      }}
                    >
                      <CloseOutlinedIcon />
                    </IconButton>
                  )}
                {isShowDateRangeIcon && (
                  <IconButton edge="end" disabled={disabled}>
                    {dateRangeIcon ? dateRangeIcon : <DateRangeIcon />}
                  </IconButton>
                )}
              </InputAdornment>
            ),
          },
        })}
        aria-describedby={open ? 'single-daterangepicker-popover' : undefined}
        onClick={onClickHandler}
        helperText={
          <>
            {timezone && (
              <Typography
                fontSize="xx-small"
                color="text.secondary"
                variant="subtitle1"
              >
                {timezone}
              </Typography>
            )}
          </>
        }
        placeholder={placeholder}
      />

      <Popover
        id={`${id}-singleDatePicker-Popover`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <LocalizationProvider dateLibInstance={moment} dateAdapter={adapter}>
          <StaticDateRangePicker<Moment | null, Moment>
            {...rest}
            disabled={disabled}
            displayStaticWrapperAs="desktop"
            value={[
              toTimezoneMoment(momentDateRanges[0], timezone),
              toTimezoneMoment(momentDateRanges[1], timezone),
            ]}
            onChange={onDateChangeHandler}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </LocalizationProvider>
      </Popover>
    </Box>
  );
};
