export const stepCountStyle = {
  '.Mui-completed': {
    color: 'primary.main',
  },
  '.Mui-active': {
    color: 'primary.main',
  },
  '.Mui-disabled': {
    color: 'text.disabled',
  },
};

export const apiKeyStyle = {
  fontFamily: 'Poppins',
  fontSize: '20.57px',
};

export const gridTenderStyle = { height: '76vh', overflow: 'auto' };

export const connectBtnStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  height: '100%',
  marginBottom: '10px',
};

export const tendetStackStyle = {
  p: '50px 20px 0 30px',
  '.MuiBackdrop-root.MuiModal-backdrop.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop':
    {
      backgroundColor: 'transparent !important',
    },
};

export const ediStackStyle = {
  width: '571px',
  pb: 4,
};
