import { observer } from 'mobx-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  GANTT_ACTION_COLUMN_WIDTH,
  additionalDriverColumns,
  driverNameColumn,
  possibleResourcesGanttConfig,
} from '../../config/gantt.config';
import {
  GANTT_LOADER_CLASSNAME,
  RESOURCES_TYPE,
} from '../../constants/gantt.const';
import ActionColumn from '../gantt/ActionColumn';
import ActionColumnHeader from '../gantt/ActionColumnHeader';
import GanttChart from '../gantt/GanttChart';
import { ConflictingConfirmPopup } from '../recommendedTrips/ConflictingConfirmPopup';
import {
  AssignmentUpdateParamsType,
  IConflictingConfirmProps,
  IPossibleResource,
  PossibleDriverResourceType,
} from '../../types/possibleResource.types';
import {
  possibleResourcesInitialState,
  possibleResourcesReducer,
} from '../../store/reducers';
import {
  convertPossibleDriversMapToList,
  getConflictWarningType,
  getConflictsTooltip,
  getOptimalFlagTooltip,
  isResourceConflict,
} from '../../services/possibleResources.service';
import { POSSIBLE_RESOURCE_ACTIONS } from '../../store';
import {
  LOCK_RESOURCE_KEYS,
  REJECT_RESOURCE_KEYS,
} from '../../constants/optymization.const';
import { useStores } from '../../../../store/root.context';
import {
  possibleDriverTripsRequest,
  validateLockActionRequest,
} from '../../network/possibleResource.request';
import {
  fetchPossibleDriverTripWarningsRequest,
  lockAssignmentRequest,
  rejectAssignmentRequest,
} from '../../network/recommendTrips.request';
import {
  generateLockOperationPayload,
  getGanttColumnSize,
  isResourceOptimal,
} from '../../services/operations.service';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import { IPuckTooltipPropsOnClick } from '@optym/gantt';
import { HosConstantsType } from '../../../../types/DispatchTypes';
import { getTimelinePuckTooltip } from '../../services/tooltip.service';
import { IRecommendedResource, ResourceType } from '../../types/gantt.types';
import {
  getFilteredPossibleResources,
  getFilteredPossibleTrips,
} from '../../services/filters.service';
import {
  formatSortFilterParam,
  getPreferredGanttStartDate,
} from '../../../../services/gantt';
import { toJS } from 'mobx';
import {
  convertTimeoffsMapToList,
  convertTripsMapToList,
  isResourceTypeAssignedDriver,
  isResourceTypeBrokeredTrip,
} from '../../services/recommendTrips.service';
import axios, { CancelTokenSource } from 'axios';
import { WarningReqPayloadType } from '../../types/requestPayload.types';
import { OPTIMIZATION_TYPES } from '../../../../store/EvaluationMode/Evaluation.store';
import { PopoverReference, useTheme } from '@mui/material';

interface PossibleResourceProps {
  organizationId?: string;
  onAssignmentUpdate?: (data: AssignmentUpdateParamsType) => void;
  sandboxId?: string;
}

const PossibleResoure: React.FC<PossibleResourceProps> = (props) => {
  const {
    bulkOptymizationStore: {
      resourceToReassign,
      setLockedPossibleTrips,
      setLockedPossibleDrivers,
      ganttColumnSize,
      hosConstants,
      setConflictingResource,
      conflictingResource,
      possibleResourceFilters,
      ganttStartDate,
      zoomLevel,
      showToast,
      recommnendationGanttRef,
      setGanttColumnSize,
    },
    evaluationStore: {
      setEvaluationDetails,
      driverDetailsToEvaluate,
      setOnLockCallback,
      setOnRejectCallback,
      stopEvaluation,
      actionTargetEl,
      setActionDisabled,
      optimizationType,
      setPossibleOptionsEvaluation,
    },
  } = useStores();
  let abortController: AbortController;
  let cancelTokenSource: CancelTokenSource;
  const ganttRef = useRef<any>(null);
  const [possibleResourceState, dispatchPossibleResource] = useReducer(
    possibleResourcesReducer,
    possibleResourcesInitialState
  );
  const theme = useTheme();
  const abortAxiosRequest = () => abortController?.abort();
  const initAxiosReqController = () => {
    abortController = new AbortController();
    cancelTokenSource = axios.CancelToken.source();
  };
  const resourceReassignCurrentVal = useRef<any>(null);
  const [conflictingConfirmPopup, setConflictingConfirmPopup] =
    useState<IConflictingConfirmProps | null>(null);

  useEffect(() => {
    resourceReassignCurrentVal.current = resourceToReassign;
  }, [resourceToReassign?.id]);

  const handleSetGanttColumnSize = () => {
    setGanttColumnSize(getGanttColumnSize(ganttRef?.current?.instance));
  };

  useEffect(() => {
    const addColumnResizeListener = () => {
      ganttRef?.current?.instance?.columns?.grid.on('horizontalscroll', () => {
        debounce(handleSetGanttColumnSize, 300);
      });
    };
    addColumnResizeListener();
  }, []);

  const debounce = (method: any, delay: number) => {
    clearTimeout(method._tId);
    method._tId = setTimeout(function () {
      method();
    }, delay);
  };

  useEffect(() => {
    if (!ganttRef?.current?.instance) return;
    ganttColumnSize?.columns?.forEach?.(
      (column: { width: number }, index: number) => {
        if (ganttRef?.current?.instance?.columns?.allRecords?.[index]?.width)
          ganttRef.current.instance.columns.allRecords[index].width =
            column.width;
      }
    );
  }, [JSON.stringify(ganttColumnSize.columns), resourceToReassign?.id]);

  useEffect(() => {
    if (!ganttRef?.current) return;
    if (
      possibleResourceState?.isLoading &&
      possibleResourceState?.possibleResourceDrivers?.size
    )
      return ganttRef?.current?.instance?.columns?.grid?.subGrids?.locked?.currentElement?.classList?.add?.(
        GANTT_LOADER_CLASSNAME
      );
    ganttRef?.current?.instance?.columns?.grid?.subGrids?.locked?.currentElement?.classList?.remove?.(
      GANTT_LOADER_CLASSNAME
    );
  }, [
    possibleResourceState?.isLoading,
    possibleResourceState?.possibleResourceDrivers?.size,
  ]);

  useEffect(() => {
    initAxiosReqController();
    dispatchPossibleResource({
      type: POSSIBLE_RESOURCE_ACTIONS.CLEAR_POSSIBLE_STORE,
    });
    fetchPossibleResources();
    return () => abortAxiosRequest();
  }, [
    JSON.stringify(resourceToReassign),
    possibleResourceFilters?.sort,
    possibleResourceFilters?.sortDirection,
  ]);

  useEffect(() => {
    if (
      !driverDetailsToEvaluate ||
      optimizationType !== OPTIMIZATION_TYPES.POSSIBLE_RESOURCES
    )
      return;
    setOnLockCallback(() => {
      handleResourceLock?.(
        driverDetailsToEvaluate,
        actionTargetEl as EventTarget
      );
    });
    setOnRejectCallback(() => {
      stopEvaluation();
      handleResourceReject(driverDetailsToEvaluate!);
    });
  }, [driverDetailsToEvaluate, actionTargetEl, optimizationType]);

  async function fetchPossibleResources(
    currentResourceType = resourceToReassign
  ) {
    try {
      if (!currentResourceType) return;
      showLoader();
      const possibleResourceRes = await possibleDriverTripsRequest({
        data: {
          sandboxId: props?.sandboxId,
          resourceType: currentResourceType?.type,
          resourceId: currentResourceType?.id,
        },
        params: {
          organizationId: props?.organizationId,
          sort: formatSortFilterParam(
            possibleResourceFilters?.sort,
            possibleResourceFilters?.sortDirection as boolean
          ),
        },
        signal: abortController?.signal,
        cancelToken: cancelTokenSource?.token,
      });
      const { drivers, trips, timeoffs, evaluateResponseDTO } =
        possibleResourceRes?.data;
      setPossibleOptionsEvaluation(evaluateResponseDTO);
      dispatchPossibleResource({
        type: POSSIBLE_RESOURCE_ACTIONS.SET_POSSIBLE_RESOURCES,
        payload: { drivers, trips, timeoffs },
      });
      stopLoader();
      fetchPossibleResourceWarnings({
        sandboxId: props?.sandboxId as string,
        driverGroupId:
          resourceToReassign?.type === RESOURCES_TYPE.DRIVER
            ? resourceToReassign?.id
            : null,
        tripId:
          resourceToReassign?.type === RESOURCES_TYPE.TRIP
            ? resourceToReassign?.id
            : null,
      });
    } catch (error: any) {
      console.log('error: ', error);
      stopLoader();
      showToast({
        type: 'FAILURE',
        serviceName: 'engineFetchPossibleResourceFailure',
      });
    }
  }

  const fetchPossibleResourceWarnings = async (
    payload: WarningReqPayloadType
  ) => {
    try {
      const warningsRes = await fetchPossibleDriverTripWarningsRequest({
        data: payload,
      });
      const warnings = {
        trips: warningsRes?.possibleDriverTripsWarnings,
        drivers: warningsRes?.driverWarnings,
      };
      dispatchPossibleResource({
        type: POSSIBLE_RESOURCE_ACTIONS.MAP_POSSIBLE_RESOURCE_WARNINGS,
        payload: { warnings },
      });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleResourceLock = (
    data: PossibleDriverResourceType,
    event?: any
  ) => {
    try {
      showLoader();
      if (!data?.engineMultiTripOutput?.isLocked) {
        const conflictDto =
          data?.validatePossibleConflictsDTO?.validateConflictsMap;
        const isConflict = isResourceConflict(
          data,
          resourceReassignCurrentVal?.current?.type
        );
        if (isConflict) {
          stopLoader();
          setActionDisabled(false);
          const conflictResourceRecord =
            resourceReassignCurrentVal?.current?.type === RESOURCES_TYPE.DRIVER
              ? conflictDto?.DRIVER
              : conflictDto?.TRIP;

          let target: any = null,
            position: any = null;
          if (event?.actionReference === 'anchorElement') target = event.target;
          else {
            position = { left: event?.clientX, top: event?.clientY };
            event.actionReference = 'anchorPosition';
          }
          return showAlreadyAssignedWarning({
            conflictingDriverData: {
              ...data,
              conflictingData: conflictResourceRecord,
            } as any,
            targetEl: target,
            position: position,
            actionReference: event?.actionReference,
          });
        }
      }
      handleLockPossibleResource(data);
    } catch (error) {
      stopLoader();
    }
  };

  const handleLockPossibleResource = async (
    data: PossibleDriverResourceType
  ) => {
    try {
      showLoader();
      const type = data?.engineMultiTripOutput?.isLocked
        ? LOCK_RESOURCE_KEYS.UNLOCK
        : LOCK_RESOURCE_KEYS.LOCK;
      const payload = {
        sandboxId: props?.sandboxId,
        ...generateLockOperationPayload(type, data, {
          //need id from inner driverGroupDetails object as outer id is customized as per gantt usage for unique ids
          driverGroupId: isResourceTypeAssignedDriver(data)
            ? data?.driverGroupDetails?.id
            : data?.id,
        }),
      };
      const resData = await lockAssignmentRequest({
        data: payload,
        params: {
          organizationId: props?.organizationId,
        },
      });
      props?.onAssignmentUpdate?.({
        ...resData,
        driverIdToDelete: data?.engineMultiTripOutput?.tripIdSelected,
      });
      const handler =
        resourceToReassign?.type === RESOURCES_TYPE.DRIVER
          ? setLockedPossibleDrivers
          : setLockedPossibleTrips;
      handler(resourceToReassign?.id as string, [data?.id]);
      setConflictingResource({
        id: conflictingResource?.id as string,
        type: 'CLEAR',
      });
      setConflictingConfirmPopup(null);
      stopEvaluation();
      initAxiosReqController();
      await fetchPossibleResources(toJS(resourceReassignCurrentVal.current));
      stopLoader();
    } catch (error) {
      stopLoader();
      setActionDisabled(false);
      showToast({
        type: 'FAILURE',
        serviceName: data?.engineMultiTripOutput?.isLocked
          ? 'engineAssignmnetUnlockFailure'
          : 'engineAssignmentLockFailure',
      });
    }
  };

  const showAlreadyAssignedWarning = ({
    conflictingDriverData,
    targetEl,
    position,
    actionReference,
  }: {
    conflictingDriverData: PossibleDriverResourceType;
    targetEl?: EventTarget;
    position?: IConflictingConfirmProps['position'];
    actionReference?: IConflictingConfirmProps['actionReference'];
  }) =>
    setConflictingConfirmPopup({
      ...conflictingDriverData,
      targetEl,
      position,
      actionReference,
    });

  const showLoader = () =>
    dispatchPossibleResource({ type: POSSIBLE_RESOURCE_ACTIONS.START_LOADER });
  const stopLoader = () =>
    dispatchPossibleResource({ type: POSSIBLE_RESOURCE_ACTIONS.STOP_LOADER });

  const handleResourceReject = async (data: PossibleDriverResourceType) => {
    try {
      showLoader();
      const isAlreadyRejected = data?.engineMultiTripOutput?.isRejected;
      const type = isAlreadyRejected
        ? REJECT_RESOURCE_KEYS.UNREJECT
        : REJECT_RESOURCE_KEYS.REJECT;
      const payload = {
        sandboxId: props?.sandboxId,
        userSandboxOperationsType: type,
        //need id from inner driverGroupDetails object as outer id is customized as per gantt usage for unique ids and outer for brokered trips
        driverGroupId: isResourceTypeAssignedDriver(data)
          ? data?.driverGroupDetails?.id
          : data?.id,
        tripId: data?.engineMultiTripOutput?.tripIdSelected,
        currentAssignmentPreference:
          data?.engineMultiTripOutput?.assignmentPreferenceType,
      };
      const rejectRes = await rejectAssignmentRequest({
        data: payload,
        params: { organizationId: props?.organizationId },
      });
      const actionType = isAlreadyRejected
        ? POSSIBLE_RESOURCE_ACTIONS.UN_REJECT_DRIVER
        : POSSIBLE_RESOURCE_ACTIONS.REJECT_DRIVER;
      props?.onAssignmentUpdate?.({
        ...rejectRes?.data,
        driverIdToDelete:
          isResourceTypeBrokeredTrip(data) && !isAlreadyRejected
            ? [data?.driverGroupDetails?.id]
            : [],
        actionType: 'REJECT',
      });
      dispatchPossibleResource({
        type: actionType,
        payload: { rejectedResource: { id: data?.id, type } },
      });
      stopLoader();
    } catch (error) {
      stopLoader();
      showToast({
        type: 'FAILURE',
        serviceName: data?.engineMultiTripOutput?.isRejected
          ? 'engineAssignmentUnrejectFailue'
          : 'engineAssignmentRejectFailure',
      });
    }
  };

  const selectGanttRow = (record: any) =>
    ganttRef?.current?.instance?.selectRow?.({ record });

  const driverColumnsMemo = useMemo(() => {
    return [
      {
        id: '1',
        draggable: false,
        width: GANTT_ACTION_COLUMN_WIDTH,
        minWidth: GANTT_ACTION_COLUMN_WIDTH,
        headerRenderer: (data: any) => {
          if (data?.headerElement?.style)
            data.headerElement.style.background = (
              theme?.palette?.primary as any
            )?.contrast;
          return renderToStaticMarkup(
            <ActionColumnHeader allowLockAll={false} />
          );
        },
        renderer: (event: any) => {
          const data = event?.record?.data as IPossibleResource;
          return (
            <ActionColumn
              isLocked={data?.engineMultiTripOutput?.isLocked}
              isRejected={data?.engineMultiTripOutput?.isRejected}
              isOptymal={isResourceOptimal(data)}
              onLock={(e) => {
                selectGanttRow(event?.record);
                handleResourceLock(data, e);
              }}
              onReject={() => {
                selectGanttRow(event?.record);
                handleResourceReject(data);
              }}
              optymalTooltip={getOptimalFlagTooltip(data)}
              conflictsTooltip={getConflictsTooltip(
                data,
                resourceReassignCurrentVal.current?.type
              )}
              showTooltip={true}
              driver={data}
              evaluationResult={data?.evaluateResponseDTO}
              optimizationType={OPTIMIZATION_TYPES.POSSIBLE_RESOURCES}
            />
          );
        },
      },
      driverNameColumn({ allowReset: false, allowActions: false }),
      ...additionalDriverColumns({}),
    ];
  }, []);

  const puckTooltipCallback = useCallback(
    (tripData: any): IPuckTooltipPropsOnClick | boolean =>
      getTimelinePuckTooltip({ tripData, hosConstants }),
    [hosConstants]
  );
  const filteredTrips = useMemo(() => {
    return getFilteredPossibleTrips(
      possibleResourceState.possibleResourceDrivers,
      possibleResourceState.possibleResourceTrips,
      possibleResourceFilters
    );
  }, [
    possibleResourceState.possibleResourceDrivers,
    possibleResourceState.possibleResourceTrips,
    possibleResourceFilters?.noOfResult,
    possibleResourceFilters?.statuses,
  ]);

  const handleCellClick = (data: any) => {
    if (
      !data?.event?.clientX ||
      !data?.event.clientY ||
      data?.column?.data?.type === 'resourceInfo' ||
      data?.column?.data?.id === '1'
    )
      return;
    const driver: IRecommendedResource = data?.record?.data;
    if (!isResourceTypeAssignedDriver(driver)) return;
    if (!data?.record?.data?.evaluateResponseDTO) return;
    setEvaluationDetails({
      source: 'OPTIMIZATION',
      targetPosition: {
        left: data?.event.clientX,
        top: data?.event.clientY,
      },
      resource: driver,
      optimizationType: OPTIMIZATION_TYPES.POSSIBLE_RESOURCES,
      evaluationResult: data?.record?.data?.evaluateResponseDTO,
    });
  };

  return (
    <>
      <GanttChart
        emptyText={
          possibleResourceState.isLoading ? 'Loading...' : 'No options found!'
        }
        {...possibleResourcesGanttConfig}
        ref={ganttRef}
        onCellClick={handleCellClick}
        partner={recommnendationGanttRef}
        columns={driverColumnsMemo}
        resources={getFilteredPossibleResources(
          convertPossibleDriversMapToList(
            possibleResourceState.possibleResourceDrivers
          ),
          possibleResourceFilters
        )}
        events={filteredTrips}
        resourceTimeRanges={convertTimeoffsMapToList(
          possibleResourceState?.timeoffs
        )}
        puckTooltipPropsOnClick={puckTooltipCallback}
        timeRanges={getPreferredGanttStartDate()}
      />
      {conflictingConfirmPopup && (
        <ConflictingConfirmPopup
          popover={{
            open: true,
            anchorReference:
              (conflictingConfirmPopup?.actionReference as PopoverReference) ??
              'anchorElement',
            anchorPosition: conflictingConfirmPopup?.position,
            anchorEl: conflictingConfirmPopup?.targetEl as Element,
          }}
          conflictType={resourceToReassign?.type}
          data={getConflictWarningType(
            resourceToReassign?.type!,
            conflictingConfirmPopup
          )}
          onConfirm={() => handleLockPossibleResource(conflictingConfirmPopup)}
          onClose={() => {
            setConflictingResource({
              id: conflictingResource?.id as string,
              type: 'CLEAR',
            });
            setConflictingConfirmPopup(null);
          }}
          isLoading={possibleResourceState?.isLoading}
        />
      )}
    </>
  );
};

export default observer(PossibleResoure);
