import { locationService, userService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import {
  GetAddressNamesListRequest,
  GetContactNamesListRequest,
  GetEmailsRequest,
  GetLocationListByNameAddressSearchRequest,
  GetLocationNamesListRequest,
  GetNamesRequest,
  GetRolesRequest,
  GetStatusesRequest,
  GetTerminalsRequest,
} from '../../../../models';

export const getTrackingSource = (
  trackingSource: {
    [key: string]: boolean;
  } = {}
): string => {
  let source = '';
  if (trackingSource.eld) {
    source += 'ELD, ';
  }
  if (trackingSource.gps) {
    source += 'GPS';
  }
  return source;
};

export const getUserRoles = async (name: string) => {
  const request = new GetRolesRequest();
  request.pageNumber = 1;
  request.pageSize = 25;
  if (name) {
    request.roleCode = name;
  }
  const response = await userService.getUserRoles(request);
  if (response instanceof ServiceError) {
  } else {
    return response.content;
  }
};

export const getUserEmails = async (name: string) => {
  const request = new GetEmailsRequest();
  request.pageNumber = 1;
  request.pageSize = 25;
  if (name) {
    request.email = name;
  }
  const response = await userService.getUserEmails(request);
  if (response instanceof ServiceError) {
  } else {
    return response.content;
  }
};

export const getTerminalsList = async (name: string) => {
  const request = new GetTerminalsRequest();
  request.pageNumber = 1;
  request.pageSize = 25;
  request.companyName = name;
  const response = await userService.getTerminals(request);
  if (response instanceof ServiceError) {
  } else {
    return response.content;
  }
};

export const getNamesList = async (name: string) => {
  const request = new GetNamesRequest();
  request.pageNumber = 1;
  request.pageSize = 25;
  request.name = name;
  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
  } else {
    return response.content;
  }
};

export const getStatusesList = async (name: string) => {
  const request = new GetStatusesRequest();
  request.pageNumber = 1;
  request.pageSize = 25;
  request.status = name;
  const response = await userService.getStatuses(request);
  if (response instanceof ServiceError) {
  } else {
    return response;
  }
};

export const getLocationsNamesList = async (
  key: string,
  pageNumber: number
) => {
  const request = new GetLocationNamesListRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.nameKeyword = key;
  const response = await locationService.getLocationsNamesList(request);
  if (response instanceof ServiceError) {
  } else {
    return { content: response?.content, last: response?.last };
  }
};

export const getLocationListByNameAddressSearch = async (
  key: string,
  pageNumber: number,
  pageSize?: number
) => {
  const request = new GetLocationListByNameAddressSearchRequest();
  request.pageNumber = pageNumber;
  request.pageSize = pageSize ?? 25;
  request.nameAddressKeyword = key;
  const response = await locationService.getLocationListByNameAddressSearch(
    request
  );
  if (response instanceof ServiceError) {
    return [];
  } else {
    return {
      content: response?.content.map((item) => {
        return {
          ...item,
          locationDisplayName: item.locationName,
          //item.locationName + ' ' + item.addressDTO?.fullAddress,
        };
      }),
    };
  }
};

export const getAddressNamesList = async (key: string, pageNumber: number) => {
  const request = new GetAddressNamesListRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.addressKeyword = key;
  const response = await locationService.getAddressNamesList(request);

  if (response instanceof ServiceError) {
  } else {
    const content = response?.content.map((item) => {
      return {
        ...item,
        addressToShow: item?.locationName + ' ' + item?.fullAddress,
      };
    });
    return { content: content, last: response?.last };
  }
};

export const getContactsNamesList = async (key: string) => {
  const request = new GetContactNamesListRequest();
  request.pageNumber = 1;
  request.pageSize = 25;
  request.locationContactNameKeyword = key;
  const response = await locationService.getContactNamesList(request);
  if (response instanceof ServiceError) {
  } else {
    return response?.content;
  }
};
