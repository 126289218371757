import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { carrierService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { ISettingsPagination } from '../../../contexts/TeamDriverContext';
import { PreferencesDTO } from '../../../models';
import {
  CarrierDTO,
  CarrierStaticDataDTO,
  GetPaginatedCarrierListResponse,
} from '../../../models/DTOs/Carrier/Requests';
import {
  defaultCarrierFilters,
  defaultCarrierStaticData,
} from '../../../subPages/carriers/constants/constants';
import * as types from '../../../subPages/carriers/constants/types';
import {
  fetchCarrierCountTotal,
  fetchCarrierOne,
  fetchStaticData,
  fromCarrierFilterToQuery,
} from '../../../subPages/carriers/utils/api.utils';
import { loadAndTripPageSize } from '../../../utils';
import { TSetData, setData } from '../../utils';

const defaultSettingsPagination: Partial<ISettingsPagination> = {
  isLoading: true,
  first: false,
  last: false,
  pageNumber: 0,
  pageSize: loadAndTripPageSize,
};

class CarrierStore {
  @observable component = 'Contacts';
  @observable dictionary = 'asset';
  @observable companyPreferences: PreferencesDTO | null = null;
  @observable addNewClicked = false;
  @observable tableList: CarrierDTO[] = [];
  @observable settingsPagination: Partial<ISettingsPagination> =
    defaultSettingsPagination;
  @observable totalTableItems = 0;
  @observable filters: types.ICarrierFilters = defaultCarrierFilters;
  @observable selectedItem: CarrierDTO | null = null;
  @observable staticDataDTO: CarrierStaticDataDTO = defaultCarrierStaticData;
  @observable carrierCountTotal: number | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setAddNewClicked(value: boolean) {
    this.addNewClicked = value;
  }

  @action
  updateLocalTableList = async (
    {
      createdList = [],
      deletedList = [],
      updatedList = [],
    }: {
      createdList?: CarrierDTO[];
      deletedList?: CarrierDTO[];
      updatedList?: CarrierDTO[];
    },
    callback?: (carriers: CarrierDTO[]) => void
  ) => {
    const newDeletedList: CarrierDTO[] = deletedList || [];
    if (createdList?.length || newDeletedList?.length) {
      this.fetchCarrierCountTotal();
    }

    let newTableList: CarrierDTO[] = this.tableList.concat();
    if (createdList?.length) {
      newTableList.unshift(...createdList);
    }

    if (updatedList?.length) {
      const updatedItemList = await updatedList?.reduce<Promise<CarrierDTO[]>>(
        async (listP, carrierDTO): Promise<CarrierDTO[]> => {
          const carrierResponse = await fetchCarrierOne(carrierDTO.id);
          const list = await listP;
          if (carrierResponse instanceof ServiceError || !carrierResponse?.id) {
            newDeletedList.push(carrierDTO);
            return list;
          }
          return [...list, carrierResponse];
        },
        Promise.resolve([])
      );
      if (updatedItemList) {
        updatedItemList.forEach((item: CarrierDTO) => {
          const index = newTableList.findIndex(
            ({ id }: CarrierDTO) => id === item.id
          );
          if (index < 0) {
            newTableList.unshift(item);
          } else {
            newTableList.splice(index, 1, item);
          }
        });
      }
    }

    if (newDeletedList?.length) {
      const newDeletedListIds = newDeletedList.map(({ id }) => id);
      newTableList = newTableList.filter(
        ({ id }) => !newDeletedListIds.includes(id)
      );
    }

    this.tableList = newTableList;
    callback?.(newTableList);
  };

  @action
  fetchTableList = async ({
    nextPageNumber,
    nextFilters,
  }: {
    nextPageNumber: number;
    nextFilters: types.ICarrierFilters;
  }): Promise<void> => {
    this.settingsPagination = {
      ...this.settingsPagination,
      pageNumber: nextPageNumber,
      isLoading: true,
    };

    try {
      const { queryParams, payload } = fromCarrierFilterToQuery(
        this.settingsPagination,
        this.filters
      );
      const response = await carrierService.getPaginatedCarrierList(
        queryParams,
        payload
      );

      runInAction(() => {
        if (response instanceof GetPaginatedCarrierListResponse) {
          this.settingsPagination = {
            ...this.settingsPagination,
            isLoading: false,
            first: response.first,
            last: response.last,
            pageNumber: response.number + 1,
          };
          const items = response.content || [];
          const newTableList: any = !response.number
            ? items
            : [...this.tableList, ...items];
          this.tableList = newTableList;
          this.totalTableItems = response.totalElements;
        } else {
          this.tableList = [];
        }
      });
    } catch (e) {
      this.settingsPagination = {
        ...this.settingsPagination,
        isLoading: false,
      };
    }
  };
  @action
  fetchStaticData = async (): Promise<void> => {
    try {
      const response = await fetchStaticData();
      runInAction(() => {
        this.staticDataDTO = response;
      });
    } catch (e) {
      //
    }
  };

  @action
  fetchCarrierCountTotal = async (): Promise<void> => {
    try {
      const response = await fetchCarrierCountTotal();
      runInAction(() => {
        this.carrierCountTotal = response;
      });
    } catch (e) {
      //
    }
  };

  @computed
  get getTableList(): CarrierDTO[] {
    return toJS(this.tableList);
  }

  @action
  setSettingsPagination = (newValue: TSetData<any>) => {
    this.settingsPagination = setData(newValue, this.settingsPagination);
  };

  @action
  setFilters = (newValue: TSetData<types.ICarrierFilters>) => {
    this.filters = setData(newValue, this.filters);
  };

  @action
  setSelectedItem = (newValue: TSetData<CarrierDTO | null>) => {
    this.selectedItem = setData(newValue, this.selectedItem);
  };
}

export function createCarrierStore() {
  return new CarrierStore();
}
