export const EldIntegrationConstants = {
  threeMDDescription:
    '3MD ELD is an electronic logging device and tracking solutions provider with a complete FMCSA and IFTA compliance.',
  eRoadDescription:
    'EROAD Limited provides electronic on-board units and software as a service to the transport industry globally.',
  verizonDescription:
    'Verizon Connect is a modern electronic logging device (ELD) based tracking solution provider that gives a near real-time view of a fleet’s daily operations, to help improve driver safety, increase productivity and fleet management.',
  motiveDescription:
    'The Motive ELD is a simple electronic logging device (ELD) that helps managing records and ensures that truck drivers are in compliance with safety laws.',
  samsaraDescription:
    'Samsara offers a complete Hours of Service solution for the ELD mandate to keep fleet compliance and simplify fleet management with integrated GPS tracking, fuel usage analysis, safety reports, and maintenance alerts.',
  omnitracsDescription:
    'Omnitracs offers comprehensive set of solutions to ensure fleets get the most out of the Electronic Logging Device mandate.',
  geoTabDescription:
    'Geotab provides ELD compliance software, for both U.S. and Canada, that simplifies Hours of Service (HOS), Driver Vehicle Inspection Reporting (DVIR), Driver Identification and Messaging.',
  jjKellerDescription:
    'J. J. Keller provides a wide range of electronic logbook solutions, from ELogs to fleet technology to data management helping business comply with hours-of-service regulations and navigate complex topics, like personal conveyance and unassigned driving events.',
  trackEnsureDescription:
    'TrackEnsure provides an easy to use ELD/HOS solution, compliant with FMCSA helping Owner Operators and Fleet Owners staying compliant and on track.',
  bigRoadDescription:
    'BigRoad provides mandate compliance solutions such as hours-of-service (HOS), and electronic logging device (ELD) to the North American transportation industry.',
  teletracDescription:
    'Teletrac ELD is a modern system that provides the data required to make informed hours of service decisions. And, offers compliance centric features and services like Hours of Service (HOS), DOT roadside inspections, pre- and post-trip inspections and more.',
  m2mDescription:
    'M2M in Motion technology platform combines hours of service with GPS and sensor data, ensuring compliance, expand safety, and daily streamlining operations.',
  mondoTrackingDescription:
    'MondoTracking offers solutions pertaining to GPS tracking and ELD for the Trucking Industry solving variety of purposes including fleet, vehicle, and asset management as well as personal/pet tracking.',
  vistracksDescription:
    'VisTracks ELD is FMCSA certified and widely used by several GPS Fleet Tracking companies in the US and Canada.',
  masterEldDescription:
    'The MasterELD electronic logging device (ELD) is loaded with an array of fleet management and compliance features, for DOT, FMCSA, and HOS compliance.',
  linxupDescription:
    'Linxup offers fleet management tools and ELD solutions that help drivers with compliances like managing accurate logs of their Hours of service (HOS), in accordance to mandate requirements.',
  eLDTractor: 'ELDTractor #',
  loadOpsTractor: 'LoadOps Tractor #',
  eLDTractorVIN: 'ELD Tractor VIN',
  loadOpsVIN: 'LoadOps VIN',
  eldDriverName: 'ELD Driver Name',
  loadOpsDriverName: 'LoadOps Driver Name',
  eldId: 'ELD ID',
  loadOpsId: 'LoadOps ID',
  eldEmail: 'ELD Email',
  loadOpsEmail: 'LoadOps Email',
  driverDeleteConfirmation:
    'Are you sure you want to de-link the selected driver(s)?',
  driverDeleteConfirmationSubtitle:
    "Please note that once de-linked, HOS and location info won't be populated from ELD",
  tractorUnlinkConfirmation:
    'Are you sure you want to de-link the selected tractor(s)?',
  tractorUnlinkConfirmationSubtitle:
    "Please note that once de-linked, location info won't be populated from ELD",
  tractorVIN: 'Tractor VIN',
};
