import { Box } from '@mui/material';
import { Fragment } from 'react';
import FinanceOverviewFormWithProvider from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverview';
import { RoutesLoadContextProvider } from '../../../../contexts/LoadRoutesContext';
import { PreferencesDTO } from '../../../../models';
import { BrokeredHandlerTimelineV3EditIconEvents } from '../../../../ui-kit/TripPlan';
import { ExpandableTimelineV3LoadIdEvents } from '../../../../ui-kit/components/TripPlan';
import { ESecondaryDetailsPanelType } from '../../../dispatch2/constants/types';
import { ETripPanelTabs } from '../../constants/constants';
import { CarrierPayment } from '../CarrierPayment';
import { TripPlan, TripPlanProps } from '../TripPlan/TripPlan';

export interface TripDetailsFormProps
  extends TripPlanProps,
    ExpandableTimelineV3LoadIdEvents,
    BrokeredHandlerTimelineV3EditIconEvents {
  selectedTab: ETripPanelTabs;
  closeDetailsPanel?: () => void;
  preferences: PreferencesDTO | null;
}

export default function TripDetailsForm({
  selectedTab,
  trip,
  closeDetailsPanel,
  onRelayDeleted,
  onUpdatedTrips,
  onClickLoadIdHandler,
  onClickBrokeredHandler,
  onUpdateActivitiesCompleted,
  preferences,
}: TripDetailsFormProps) {
  return (
    <Fragment>
      {selectedTab === ETripPanelTabs.ROUTE && (
        <RoutesLoadContextProvider>
          <TripPlan
            onRelayDeleted={onRelayDeleted}
            trip={trip}
            closeDetailsPanel={closeDetailsPanel}
            onUpdatedTrips={onUpdatedTrips}
            onClickLoadIdHandler={onClickLoadIdHandler}
            onClickBrokeredHandler={onClickBrokeredHandler}
            onUpdateActivitiesCompleted={onUpdateActivitiesCompleted}
            preferences={preferences}
          />
        </RoutesLoadContextProvider>
      )}
      {selectedTab === ETripPanelTabs.FINANCE && (
        <Box sx={{ p: 2, pt: 0 }}>
          <FinanceOverviewFormWithProvider
            loadId={trip.id}
            ModuleType={'FinanceTrip'}
            isBrokered={trip?.brokerageTrip}
            panelType={ESecondaryDetailsPanelType.TRIP}
            isEmptyTrip={trip?.isEmptyTrip}
          />
        </Box>
      )}
      {selectedTab === ETripPanelTabs.CARRIER_PAYMENT &&
        trip &&
        trip.brokerageTrip && (
          <Box sx={{ p: 2, pt: 0 }}>
            <CarrierPayment trip={trip} isBrokered={trip?.brokerageTrip} />
          </Box>
        )}
    </Fragment>
  );
}
