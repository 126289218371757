export const getAvatarStringByName = (fullName: string): string => {
  if (fullName === null || fullName === undefined || fullName === '') {
    return '';
  }
  const fullNameArr = fullName.split(' ');
  return fullNameArr
    .filter((str: string) => !!str)
    .map((str: string) => str[0])
    .join('');
};
