import * as yup from 'yup';
import { rateSchema } from './rate';
import { stopSchema } from './stops';

export enum LoadPriorityEnum {
  None = 'NONE',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Critical = 'CRITICAL',
}

export const loadPriorityOptions: { label: string; value: LoadPriorityEnum }[] =
  [
    { label: 'None', value: LoadPriorityEnum.None },
    { label: 'Low', value: LoadPriorityEnum.Low },
    { label: 'Medium', value: LoadPriorityEnum.Medium },
    { label: 'High', value: LoadPriorityEnum.High },
    { label: 'Critical', value: LoadPriorityEnum.Critical },
  ];

export const MAX_DECIMAL_DIGITS_ALLOWED = 4;
export const DecimalDigitErrorMsg = 'Please enter upto 4 decimal digits.';
export const MAX_COMMODITY_NAME_LENGTH = 100;
const DECIMAL_DIGIT_VALIDATION_REGEX = /^(?!0\d|$)\d*(\.\d{1,4})?$/;

const loadCommoditySchema = {
  commodity: yup
    .string()
    .required('Commodity Type is required.')
    .max(MAX_COMMODITY_NAME_LENGTH, 'Cannot be longer than 100 characters.')
    .typeError('Commodity Type is required.'),
  quantity: yup
    .number()
    .required('Quantity is required.')
    .min(1, 'Value must be between 1 and 999,999.')
    .max(999999, 'Value must be between 1 and 999,999.')
    .typeError('Please enter a number.')
    .transform(transformEmptyStringToNumber),
  package: yup.string().nullable(),
  weight: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull)
    .test('maxDigitsAfterDecimal', DecimalDigitErrorMsg, (number) => {
      if (!number) return true;
      return DECIMAL_DIGIT_VALIDATION_REGEX.test(`${number}`);
    }),
  description: yup
    .string()
    .nullable()
    .max(255, 'Cannot be longer than 255 characters.'),
  pickup: yup.string().nullable(),
  dropoff: yup.string().nullable(),
  length: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull)
    .test('maxDigitsAfterDecimal', DecimalDigitErrorMsg, (number) => {
      if (!number) return true;
      return DECIMAL_DIGIT_VALIDATION_REGEX.test(`${number}`);
    }),
  width: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull)
    .test('maxDigitsAfterDecimal', DecimalDigitErrorMsg, (number) => {
      if (!number) return true;
      return DECIMAL_DIGIT_VALIDATION_REGEX.test(`${number}`);
    }),
  height: yup
    .number()
    .nullable()
    .min(0.0001, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull)
    .test('maxDigitsAfterDecimal', DecimalDigitErrorMsg, (number) => {
      if (!number) return true;
      return DECIMAL_DIGIT_VALIDATION_REGEX.test(`${number}`);
    }),
  volume: yup
    .number()
    .nullable()
    .min(0, 'Value must be between 0.0001 and 999,999.')
    .max(999999, 'Value must be between 0.0001 and 999,999.')
    .typeError('Please enter a number')
    .transform(transformEmptyStringToNull)
    .test('maxDigitsAfterDecimal', DecimalDigitErrorMsg, (number) => {
      if (!number) return true;
      return DECIMAL_DIGIT_VALIDATION_REGEX.test(`${number}`);
    }),
  isDimensionalWeight: yup.boolean().default(false),
};

export const createLoadFormSchema = yup.object().shape({
  customer: yup.object().shape({
    customerName: yup
      .string()
      .required('Customer Name is required.')
      .transform(transformEmptyStringToNull)
      .typeError('Customer Name is required.'),
    invoice: yup.string().nullable(),
    factoringId: yup.string().nullable().optional(),
    customerContactId: yup.string().nullable(),
    referenceNumber: yup
      .string()
      .nullable()
      .max(50, 'Cannot be longer than 50 characters.'),
    requiredDocuments: yup.array().of(yup.string()),
    loadPriority: yup.string().default('None'),
    // .oneOf(loadPriorityOptions)
  }),
  stops: yup.array().of(yup.object().shape(stopSchema)).min(2).required(),
  load: yup.object().shape({
    equipment: yup
      .array()
      .min(1, 'Equipment type is required.')
      .required('Equipment type is required.'),
    temp: yup
      .number()
      .min(-500, 'Value must be between -500 and 500.')
      .max(500, 'Value must be between -500 and 500.')
      .nullable()
      .transform(transformEmptyStringToNull)
      .typeError('Please enter a number.'),
    type: yup.string().nullable(),
    commodities: yup.array().of(yup.object().shape(loadCommoditySchema)),
    weight: yup.string().nullable(),
    notes: yup
      .string()
      .nullable()
      .max(1024, 'Cannot be longer than 1024 characters.'),
    seal: yup
      .string()
      .nullable()
      .max(50, 'Cannot be longer than 50 characters.'),
    loadType: yup.string().oneOf(['FTL', 'LTL']),
    isBrokered: yup.boolean().default(false),
    equipmentAdditionalDetails: yup.object().shape({
      chassisNumber: yup
        .string()
        .nullable()
        .max(50, 'Cannot be longer than 50 characters.')
        .transform(transformEmptyStringToNull),
      containerNumber: yup
        .string()
        .nullable()
        .max(50, 'Cannot be longer than 50 characters.')
        .transform(transformEmptyStringToNull),
      bookingNumber: yup
        .string()
        .nullable()
        .max(50, 'Cannot be longer than 50 characters.')
        .transform(transformEmptyStringToNull),
      appointmentNumber: yup
        .string()
        .nullable()
        .max(50, 'Cannot be longer than 50 characters.')
        .transform(transformEmptyStringToNull),
      reeferMode: yup
        .string()
        .nullable()
        .default('CONTINUOUS')
        .transform(transformEmptyStringToNull),
    }),
  }),
  rate: yup.object().shape(rateSchema, [['baseRateType', 'rate']]),
});

// convert empty string to null for yup validation
export function transformEmptyStringToNull(value: any, originalValue: any) {
  if (originalValue?.trim?.() === '') {
    return null;
  }
  return value;
}

// convert empty string to number for yup validation
export function transformEmptyStringToNumber(value: any, originalValue: any) {
  if (originalValue?.trim?.() === '') {
    return null;
  }
  return isNaN(Number(value)) ? value : Number(value);
}
