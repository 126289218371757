import { Box } from '@mui/system';
import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
} from 'react';
import {
  StyleAccordionSummaryTitle,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './styles';

export interface CustomizeAccordionItem {
  id: string;
  expanded: boolean;
  isCancelled?: boolean;
  summaryTitle: React.ReactNode;
  renderTripStatus: () => React.ReactNode;
  renderSummaryAction: (params: { expanded: boolean }) => JSX.Element;
  renderHeaderContents: () => JSX.Element;
  renderComponent: (isResequencing?: boolean) => JSX.Element;
}

interface IExpanded {
  [key: string]: boolean;
}

export interface CustomizeAccordionProps {
  list: CustomizeAccordionItem[];
  expandedAccordion: IExpanded;
  setExpandedAccordion: Dispatch<SetStateAction<IExpanded>>;
  isManifest?: boolean;
  idEditAddEnable?: boolean;
  isResequencing?: boolean;
}

export const CustomizeAccordion = ({
  list,
  expandedAccordion,
  setExpandedAccordion,
  isManifest,
  idEditAddEnable,
  isResequencing = false,
}: CustomizeAccordionProps): JSX.Element => {
  const init = (): void => {
    const newState: IExpanded = {};
    list.forEach((crrAccordion: CustomizeAccordionItem) => {
      newState[crrAccordion.id] = !!crrAccordion.expanded;
    });
    setExpandedAccordion(newState);
  };

  useEffect(() => {
    init();
  }, [list]);

  const onChangeHandler = useCallback(
    (crrAccordion: CustomizeAccordionItem, expanded: boolean) => {
      setExpandedAccordion((preState) => {
        return {
          ...preState,
          [crrAccordion.id]: expanded,
        };
      });
    },
    [expandedAccordion, setExpandedAccordion]
  );

  return (
    <Box>
      {list.map((crrAccordion: CustomizeAccordionItem) => (
        <Box key={crrAccordion.id}>
          <StyledAccordion
            key={crrAccordion.id}
            defaultExpanded={crrAccordion.expanded}
            expanded={expandedAccordion[crrAccordion.id]}
            onChange={(
              event: SyntheticEvent<Element, Event>,
              expanded: boolean
            ) => {
              onChangeHandler(crrAccordion, expanded);
              event.stopPropagation();
            }}
          >
            <StyledAccordionSummary
              id={crrAccordion.id}
              isCancelled={!!crrAccordion?.isCancelled}
              isManifest={isManifest}
              sx={{
                alignItems: 'center',
              }}
            >
              <StyleAccordionSummaryTitle
                className="StyleAccordionSummaryTitle"
                // expanded={expandedAccordion[crrAccordion.id]}
                component="div"
              >
                {crrAccordion.summaryTitle}
                {crrAccordion?.renderTripStatus?.()}
              </StyleAccordionSummaryTitle>

              {crrAccordion.renderSummaryAction({
                expanded: expandedAccordion[crrAccordion.id],
              })}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {crrAccordion.renderHeaderContents()}
            </StyledAccordionDetails>
          </StyledAccordion>

          <StyledAccordionDetails>
            {crrAccordion.renderComponent(isResequencing)}
          </StyledAccordionDetails>
        </Box>
      ))}
    </Box>
  );
};
