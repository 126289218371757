import { useFormContext } from 'react-hook-form';
import TextField from '../../../../../common/Ui/TextField/TextField';
import { FUEL_PRICE_TYPE, MatrixSectionInfo } from '../../constants';
import SettingBlock from '../Blocks/SettingBlock';
import { SettingBlockItem } from '../Blocks/SettingBlockItem';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Matrix as MatrixModel } from '@/models';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Typography } from '@components/ui-kit/typography';

interface Props {
  matrix: keyof typeof MatrixModel;
  title: string;
  subTitle: string;
}

function Matrix({ matrix, title, subTitle }: Props) {
  const { control, watch, setValue: setFormValue } = useFormContext();
  const fuelMatrix = watch('fuelMatrix');
  const [value, setValue] = useState(
    fuelMatrix[matrix]?.useNationalAverage ? 'default' : 'custom'
  );

  useEffect(() => {
    if (fuelMatrix[matrix]?.useNationalAverage) {
      setValue(fuelMatrix[matrix].useNationalAverage ? 'default' : 'custom');
    }
  }, [fuelMatrix[matrix]?.useNationalAverage]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setValue(value);
    setFormValue(
      `fuelMatrix.${matrix}.useNationalAverage`,
      value === 'default',
      { shouldDirty: true }
    );
  };

  return (
    <SettingBlockItem hasExceptions title={title} subTitle={subTitle}>
      <Grid
        justifyContent="space-between"
        container
        sx={{ xs: 12 }}
        alignItems="center"
        spacing={1}
      >
        <RadioGroup
          onChange={handleChange}
          row
          aria-labelledby="buttons-group-label"
          name="row-radio-buttons-group"
          value={value}
        >
          <FormControlLabel
            value="custom"
            control={<Radio />}
            label="Custom Price"
            sx={{ xs: 3, mt: 2, mr: 2 }}
          />
          <TextField
            control={control}
            name={`fuelMatrix.${matrix}.price`}
            label="Price"
            required={value === 'custom'}
            disabled={value === 'default'}
            onlyNumbers
            sizes={{ xs: 3 }}
            style={{ mr: 2 }}
          />
          <FormControlLabel
            value="default"
            control={<Radio />}
            label="EIA Price"
            sx={{ xs: 3, mt: 2 }}
          />
          <Typography sx={{ mt: 3 }}>
            $ {fuelMatrix[matrix].defaultPrice}
          </Typography>
        </RadioGroup>
      </Grid>
    </SettingBlockItem>
  );
}

export default function FuelPrice() {
  return (
    <section id={FUEL_PRICE_TYPE} className="section">
      <SettingBlock
        title="Fuel Price "
        subTitle="Enter the fuel price for different regions."
      >
        {MatrixSectionInfo.map((matrix) => {
          return (
            <Matrix
              key={matrix.matrix}
              matrix={matrix.matrix as keyof typeof MatrixModel}
              title={matrix.title}
              subTitle={matrix.subTitle}
            />
          );
        })}
      </SettingBlock>
    </section>
  );
}
