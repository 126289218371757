import { annotateNameAsync } from '../interfaces';
import {
  GetAllAlerts,
  GetAllAlertsRes,
  CreateTrackerDTO,
  GetTrackerListRes,
  GetContactListDTO,
  GetTrackingURLRes,
} from '../../models';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { IVisibilityService } from '../interfaces/IVisibilityService';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import * as EntityDocumentsSection from '../../views/reports/documents/sections/EntityDocumentsSection';
import { getOrganizationId } from '../../common/TimeoffDialog/utils';

const getAllAlertsURL = '/web/load/api/v2/load/alert/get/alert-data';
const getTrackingURLDataURL = '/web/load/api/v2/links/generate';
const getTrackerListURL = '/web/alert/api/v2/customer/alert/list';
const createURL = '/web/alert/api/v2/customer/alert/create';
const deleteURL = '/web/alert/api/v2/customer/alert/delete/bulk';
const updateURL = '/web/alert/api/v2/customer/alert/bulk/update';
const getContactListURL = '/web/customer/api/v2/customers/get/contacts/by-name';
const sendDocumentTurvoDataURL = '/web/lbhub/api/v2/turvo/send-documents';

export class VisibilityService extends IVisibilityService {
  @annotateNameAsync
  async getAllAlerts(requestData: GetAllAlerts) {
    try {
      return await httpClient.get<GetAllAlertsRes>(
        getAllAlertsURL,
        requestData,
        GetAllAlertsRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getTrackingURLData(requestData: GetAllAlerts) {
    try {
      return await httpClient.get<GetTrackingURLRes>(
        getTrackingURLDataURL,
        requestData,
        GetTrackingURLRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getContactList(requestData: GetContactListDTO) {
    try {
      return await httpClient.get<GetAllAlertsRes>(
        getContactListURL,
        requestData,
        GetAllAlertsRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getTrackerList(loadId: string) {
    try {
      return await httpClient.get<GetTrackerListRes[]>(
        getTrackerListURL + `?loadId=${loadId}`,
        undefined,
        GetTrackerListRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async createTrackerData(payloadData: CreateTrackerDTO) {
    try {
      const response = await httpClient.post(
        createURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createTrackerData',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'createTrackerData',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async deleteTrackerData(payloadData: CreateTrackerDTO[]) {
    try {
      const response = await httpClient.deleteRaw(
        deleteURL,
        undefined,
        payloadData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteTrackerData',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'deleteTrackerData',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async updateTrackerData(payloadData: CreateTrackerDTO[]) {
    try {
      const response = await httpClient.put(
        updateURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateTrackerData',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'updateTrackerData',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
  @annotateNameAsync
  async sendDocumentTurvoData(
    requestData: EntityDocumentsSection.ISendDocumentTurvoType
  ) {
    try {
      const response = await httpClient.post<any>(
        sendDocumentTurvoDataURL + `?organizationId=${getOrganizationId()}`,
        undefined,
        requestData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'Documents sent successfully to Turvo.',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Failed to Send Document, Please try again',
      });
      throw error;
    }
  }
}
