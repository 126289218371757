import React, { useState, useEffect } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import LocationsDetailsForm from './form/LocationsDetailsForm';
import { locationService } from '../../../api';
import {
  CreateLocationRequest,
  EditLocationDetailsByIdRequest,
  GetLocationDetailsByIdRequest,
} from '../../../models';
import { LocationSummaryDTO, LocationsListDTO } from '../../../models/DTOs';
import {
  locationDetailsDefaultData,
  locationDetailsFormValidationSchema,
} from '../constants';
import { plainToClassFromExist, plainToInstance } from 'class-transformer';
import { ServiceError } from '../../../api/interfaces';
import {
  getHttpErrorsFromResponse,
  getTimeZoneAbbreviation,
} from '../../../utils/index';
import { ActionsSection } from '../actionsSection';

type propsType = {
  data: LocationsListDTO | null;
  isCreatePanelOpen: boolean;
  onClose: () => void;
  onUpdated: (data: LocationSummaryDTO) => void;
  onDeleted: (data: number) => void;
  onCreated: (data: LocationSummaryDTO) => void;
  autoExpanded: boolean;
  isGlobal: boolean;
};

function LocationDetailsPanel({
  data,
  isCreatePanelOpen,
  onClose,
  onUpdated,
  onDeleted,
  onCreated,
  autoExpanded = false,
  isGlobal = false,
}: propsType): JSX.Element | null {
  const [locationDetailsData, setLocationDetailsData] =
    useState<LocationSummaryDTO | null>(null);

  const [httpErrors, setHttpErrors] = useState<any>();

  async function getLocationDetails(id: number) {
    const request = new GetLocationDetailsByIdRequest();
    request.id = id;

    try {
      const locationDetails = await locationService.getLocationDetailsById(
        request
      );

      if (locationDetails instanceof LocationSummaryDTO) {
        locationDetails.timezone = getTimeZoneAbbreviation(
          locationDetails.timezone
        );
        setLocationDetailsData(locationDetails);
      }
    } catch (error) {}
  }

  useEffect(() => {
    setLocationDetailsData(locationDetailsDefaultData);
    if (typeof data === 'object' && data?.id && !isCreatePanelOpen) {
      getLocationDetails(data?.id);
    }
  }, [data, isCreatePanelOpen]);

  async function handleUpdate(editedLocation: LocationSummaryDTO) {
    const request = new EditLocationDetailsByIdRequest();

    const locationForSave = plainToClassFromExist(request, editedLocation);
    delete locationForSave?.timezone; //@TODO solution so as not to spoil contacts in other places, and not to touch the model
    try {
      const location = await locationService.editLocationDetailsById(
        locationForSave
      );
      const updatedLocation = plainToInstance(LocationSummaryDTO, location);
      onUpdated(updatedLocation);
      setLocationDetailsData(updatedLocation);
    } catch (error) {
      const serviceError = error as ServiceError; // @TODO: Anoush check this type actually it is a serviceError instance

      setHttpErrors(getHttpErrorsFromResponse(serviceError));
    }
  }

  async function handleCreateLocation(newLocation: LocationSummaryDTO) {
    const request = new CreateLocationRequest();

    const locationForSave = plainToClassFromExist(request, newLocation);

    try {
      const response = await locationService.createLocation(locationForSave);

      if (response instanceof LocationSummaryDTO) {
        onCreated(response);
      }
    } catch (error) {
      const serviceError = error as ServiceError;

      setHttpErrors(getHttpErrorsFromResponse(serviceError));
    }
  }

  const handleClose = () => {
    setLocationDetailsData(null);
    onClose();
  };

  const actionsRenderer = () => {
    return (
      <>
        {locationDetailsData?.id && (
          <ActionsSection onDeleted={onDeleted} id={locationDetailsData.id} />
        )}
      </>
    );
  };

  return locationDetailsData ? (
    <React.Fragment>
      <DetailsPanel
        data={locationDetailsData}
        panelTitle={
          isCreatePanelOpen
            ? 'Add New Location'
            : `Location: ${locationDetailsData?.locationName}`
        }
        onClose={handleClose}
        onUpdate={handleUpdate}
        onCreate={handleCreateLocation}
        contentRenderer={() => (
          <LocationsDetailsForm
            isCreatePanelOpen={isCreatePanelOpen}
            onUpdated={onUpdated}
          />
        )}
        validationSchema={locationDetailsFormValidationSchema}
        httpErrors={httpErrors}
        actionButtonLabel={isCreatePanelOpen ? 'Add Location' : 'Save Changes'}
        isSaveIconVisible={!isCreatePanelOpen}
        autoExpanded={autoExpanded}
        isGlobal={isGlobal}
        actionsRenderer={actionsRenderer}
      />
    </React.Fragment>
  ) : null;
}

export default LocationDetailsPanel;
