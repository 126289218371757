import { AXELE_PERMISSION_TYPE, Permission } from '@/common/Permission';
import Box from '@mui/material/Box';
import ButtonImproved from '@/ui-kit/components/ButtonImproved';
import { t } from 'i18next';
import IconsComponent from '@/ui-kit/components/IconsComponent';
import { FC } from 'react';
import NoData from '@/common/EntityNotes/components/NoData';
import { useNotesContent } from '@/common/EntityNotes/hooks';
import { NoteForm } from '@/common/EntityNotes/components/NoteForm';
import { NoteItem } from '@/common/EntityNotes/components/NoteItem';
import { NoteEntityType } from '@/common/EntityNotes/utils/types';

interface Props {
  entityNotes: any[];
  getEntityNotes: () => void;
  entityType: NoteEntityType;
  entityId: string;
}

export const NotesContent: FC<Props> = ({
  entityNotes,
  getEntityNotes,
  entityType,
  entityId,
}) => {
  const { showAddNewForm, handleShowAddNewFormChange } = useNotesContent();
  return (
    <>
      <Permission contains={[AXELE_PERMISSION_TYPE.LOAD_EDIT]}>
        <Box display="flex" justifyContent="end">
          <ButtonImproved
            size="medium"
            variant="contained"
            disabled={false}
            label={t('newNote')}
            styleProps={{ mt: 2 }}
            startIcon={
              <IconsComponent iconName="AddOutlined" source="MUIcons" />
            }
            onClick={handleShowAddNewFormChange(true)}
          />
        </Box>
      </Permission>
      {showAddNewForm && (
        <NoteForm
          getEntityNotes={getEntityNotes}
          entityId={entityId}
          entityType={entityType}
          onCancel={handleShowAddNewFormChange(false)}
        />
      )}
      {entityNotes.length ? (
        entityNotes.map((note) => (
          <NoteItem
            note={note}
            key={note.id}
            getEntityNotes={getEntityNotes}
            entityId={entityId}
            entityType={entityType}
          />
        ))
      ) : !showAddNewForm ? (
        <NoData />
      ) : null}
    </>
  );
};
