import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Slider as MuiSlider } from '@mui/material';

type PropTypes = {
  control?: any;
  name: string;
  defaultValue?: number;
  shouldUnregister?: ControllerProps['shouldUnregister'];
  onChangeCb?: (event: Event, newValue: number | number[]) => void;
  valueLabelDisplay?: string;
  sizes?: { [key: string]: number };
  valueLabel?: React.ReactNode;
  min?: number;
  max?: number;
};

const SliderForm = ({
  control,
  name,
  defaultValue,
  shouldUnregister = false,
  onChangeCb,
  sizes = {
    xs: 12,
  },
  min,
  max,
}: PropTypes) => {
  return (
    <Grid xs={sizes.xs} item>
      <Controller
        render={({ field }) => (
          <MuiSlider
            {...field}
            defaultValue={defaultValue}
            aria-label="Default"
            min={min}
            max={max}
            // valueLabelDisplay="auto"
            // valueLabelFormat={(value: number) => {
            //   return valueLabel || value;
            // }}
            onChange={(event: Event, newValue: number | number[]) => {
              field.onChange(event, newValue);
              onChangeCb && onChangeCb(event, newValue);
            }}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
        shouldUnregister={shouldUnregister}
      />
    </Grid>
  );
};

export default SliderForm;
