export interface IGroup {
  label: string;
  toggleable: boolean;
  fieldSection?: string;
  fieldName?: string;
}

interface IFieldGroup {
  label: string;
  groups: Record<string, IGroup>;
}

export type IFieldGroupsConfig = Record<string, IFieldGroup>;

export const FieldGroupsConfig: IFieldGroupsConfig = {
  Customer: {
    label: 'Customer',
    groups: {
      CustomerName: { label: 'Customer Name', toggleable: false },
      InvoiceTerminal: { label: 'Invoice Terminal', toggleable: false },
      Contact: {
        label: 'Contact',
        toggleable: true,
        fieldSection: 'customer',
        fieldName: 'customerContactId',
      },
      ReferenceNumber: {
        label: 'Reference Number',
        toggleable: true,
        fieldSection: 'customer',
        fieldName: 'referenceNumber',
      },
      FactoringCompany: {
        label: 'Factoring Company',
        toggleable: true,
        fieldSection: 'customer',
        fieldName: 'factoringId',
      },
      RequiredDocuments: {
        label: 'Required Documents',
        toggleable: true,
        fieldSection: 'customer',
        fieldName: 'requiredDocuments',
      },
      LoadPriority: {
        label: 'Load Priority',
        toggleable: true,
        fieldSection: 'customer',
        fieldName: 'loadPriority',
      },
    },
  },
  Stops: {
    label: 'Stops',
    groups: {
      Activity: { label: 'Activity', toggleable: false },
      HandlingTime: {
        label: 'Handling Time',
        toggleable: true,
        fieldSection: 'stops',
        fieldName: 'estimatedActivityDuration',
      },
      DriverAssist: {
        label: 'Driver Assist',
        toggleable: true,
        fieldSection: 'stops',
        fieldName: 'isDriverAssistRequired',
      },
      LocationAddress: { label: 'Location / Address', toggleable: false },
      // BusinessName: {
      //   label: 'Business Name',
      //   toggleable: true,
      //   fieldSection: 'stops',
      //   fieldName: 'businessName',
      // },
      // EditAddress: {
      //   label: 'Edit Address',
      //   toggleable: true,
      //   fieldSection: 'stops',
      //   fieldName: 'editAddress',
      // },
      EditContact: {
        label: 'Contact',
        toggleable: true,
        fieldSection: 'stops',
        fieldName: 'editContact',
      },
      EditReferenceNumber: {
        label: 'Reference Number',
        toggleable: true,
        fieldSection: 'stops',
        fieldName: 'editReferenceNumber',
      },
      FromDateTime: { label: 'From Date / Time', toggleable: false },
      ToDateTime: {
        label: 'To Date / Time',
        toggleable: true,
        fieldSection: 'stops',
        fieldName: 'appointmentEndDate',
      },
      // Contact: {
      //   label: 'Contact',
      //   toggleable: true,
      //   fieldSection: 'stops',
      //   fieldName: 'contact',
      // },
      Notes: {
        label: 'Notes',
        toggleable: true,
        fieldSection: 'stops',
        fieldName: 'notes',
      },
    },
  },
  Load: {
    label: 'Load',
    groups: {
      EquipmentType: { label: 'Equipment Type', toggleable: false },
      LoadType: { label: 'Load Type', toggleable: false },
      SealNumber: {
        label: 'Seal Number',
        toggleable: true,
        fieldSection: 'load',
        fieldName: 'seal',
      },
      CanBeBrokered: {
        label: 'Can be brokered',
        toggleable: true,
        fieldSection: 'load',
        fieldName: 'canBeBrokered',
      },
      CommodityDetails: {
        label: 'Commodity Details',
        toggleable: true,
        fieldSection: 'load',
        fieldName: 'commodities',
      },
      DispatchingNotes: {
        label: 'Notes for Dispatching',
        toggleable: true,
        fieldSection: 'load',
        fieldName: 'notes',
      },
    },
  },
  Rate: {
    label: 'Rate',
    groups: {
      MessageOnInvoice: {
        label: 'Message On Invoice',
        toggleable: true,
        fieldSection: 'rate',
        fieldName: 'messageOnInvoice',
      },
      InternalNotes: {
        label: 'Internal Notes',
        toggleable: true,
        fieldSection: 'rate',
        fieldName: 'internalNotes',
      },
    },
  },
} as const;
