import React, { FC } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';

interface RespondByProps {
  value?: string;
}

const RespondBy: FC<RespondByProps> = ({ value }) => {
  const momentValue = moment(value);
  if (!momentValue.isValid()) return <></>;
  return (
    <Box>
      <Typography fontSize={14}>{momentValue.format('H:mm A')}</Typography>
      <Typography fontSize={11}>{momentValue.format('dddd M/D/YY')}</Typography>
    </Box>
  );
};

export default RespondBy;
