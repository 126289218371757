import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  NotesOutlinedIcon,
  WarningLoadListDetentionIcon,
  WarningLoadListInvoiceIcon,
} from '../../../../ui-kit/components/Assets';
import { getTimeFromMinutes } from '../../../../utils';
import { t } from 'i18next';
import { InvoicesConstants } from '../../../../locales/en/finance/invoices/index';
const warningsMap: { [key: string]: { text: string; icon: JSX.Element } } = {
  BOL: {
    text: InvoicesConstants.missingLadingBill,
    icon: <NotesOutlinedIcon />,
  },
  RC: {
    text: InvoicesConstants.missingRateConfirmation,
    icon: <NotesOutlinedIcon />,
  },
  POD: {
    text: InvoicesConstants.missingDeliveryProof,
    icon: <NotesOutlinedIcon />,
  },
  Overdue: {
    text: InvoicesConstants.invoiceOverDue,
    icon: <WarningLoadListInvoiceIcon />,
  },
  Detention: {
    text: InvoicesConstants.detentionTime,
    icon: <WarningLoadListDetentionIcon />,
  },
};

const getTime = (w: { time: number; name: string }) => {
  if (w.name === 'Overdue') {
    return `${w.time}d`;
  }
  return getTimeFromMinutes(w.time);
};

export function Warnings({
  warnings = [],
  missingDocuments = [],
}: {
  warnings: { name: string; time: number }[];
  missingDocuments: string[];
}) {
  return (
    <Box
      sx={{
        mt: 2,
        backgroundColor: '#ed9d0214',
        padding: '16px 16px',
        borderRadius: '7px',
      }}
    >
      {missingDocuments.map((w, i) => (
        <Box key={i} sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mr: 3 }}>{warningsMap[w].icon}</Box>
          <Box>
            <Typography color={'text.primary'}>
              {warningsMap[w].text}
            </Typography>
          </Box>
        </Box>
      ))}
      {warnings.map((w, i) => (
        <Box key={i} sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mr: 3 }}>{warningsMap[w.name].icon}</Box>
          <Box>
            <Typography color={'text.primary'}>
              {`${warningsMap[w.name].text}: ${getTime(w)}`}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
