import React, { ReactElement } from 'react';
import {
  AxeleTimeline as DefaultAxeleTimeline,
  TimelineProps as DefaultAxeleTimelineProps,
} from './AxeleTimeline';
import ExpandableTimeline, {
  ExpandableTimelineProps,
} from './ExpandableTimeline';

export type AxeleTimelineProps<TRecord> = (
  | ExpandableTimelineProps
  | DefaultAxeleTimelineProps<TRecord>
) & {
  isExpandable?: boolean;
  useDarkMode?: boolean;
};

const AxeleTimeline = <TRecord,>({
  isExpandable = false,
  useDarkMode = false,
  ...restProps
}: AxeleTimelineProps<TRecord>): ReactElement | null => {
  return (
    <>
      {isExpandable ? (
        <ExpandableTimeline {...(restProps as ExpandableTimelineProps)} />
      ) : (
        <DefaultAxeleTimeline
          {...(restProps as DefaultAxeleTimelineProps<TRecord>)}
        />
      )}
    </>
  );
};

export default AxeleTimeline;
