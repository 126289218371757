import { createContext, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../StorageManager/StorageManager';
import { paymentService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  CreateDriverPaymentTerm,
  DriverPaymentTermsGrouped,
  ExpenseCategory,
  OwnerOperatorPaymentRecipient,
  PaymentPerLoad,
  PaymentType,
  PaymentUnitType,
} from '../../../models';
import { UserDetails } from '../../../models/DTOs/PaymentTerms/TractorPaymentTermConstant';
import { EquipmentType } from '../../../views/maintenanceRecords/constants/constants';
import { ITractorPaymentDataProps } from '../../tractors/components/WrapperTractorPayment';
import { IPaymentTermDataDependency } from '../../users/components/PaymentTermForm/PaymentTermTabs';

type FormTypeContextType = {
  availablePayTypes: PaymentType[];
  expenseCategories: ExpenseCategory[];
  savedLoadPaymentTerms: PaymentPerLoad[];
  allPaymentTypes: PaymentType[];
  createNewPaymentTermForLoad: (paymentTerm: CreateDriverPaymentTerm) => void;
  setUserDetails: (value: React.SetStateAction<UserDetails>) => void;
  deletePaymentForLoad: (id: number) => void;
  filterOperationsModes: (payTypeId: string) => void;
  resetOperationModes: () => void;
  scheduledPayment: PaymentPerLoad[];
  scheduledDeduction: PaymentPerLoad[];
  payToDetails: OwnerOperatorPaymentRecipient;
};

export async function getTractorPaymentTerms(
  tractorId: number
): Promise<DriverPaymentTermsGrouped | null> {
  const tractorPaymentTerms = await paymentService.getTractorPaymentTerms(
    tractorId
  );
  if (tractorPaymentTerms instanceof ServiceError) {
    // should be decided what to do
    return null;
  } else if (tractorPaymentTerms != null) {
    const savedPaymentTerms = tractorPaymentTerms as DriverPaymentTermsGrouped;
    return savedPaymentTerms;
  }

  return null;
}

async function getWebUserPaymentTerms(
  dispatcherId: number
): Promise<DriverPaymentTermsGrouped | null> {
  const tractorPaymentTerms = await paymentService.getWebUserPaymentTerms(
    dispatcherId
  );

  if (tractorPaymentTerms instanceof ServiceError) {
    // should be decided what to do
    return null;
  } else if (tractorPaymentTerms != null) {
    const savedPaymentTerms = tractorPaymentTerms as DriverPaymentTermsGrouped;
    return savedPaymentTerms;
  }

  return null;
}

const FormContext = createContext({} as FormTypeContextType);

type Props = {
  setUserDetails?: (value: React.SetStateAction<UserDetails>) => void;
  data: UserDetails | ITractorPaymentDataProps;
  children?: any;
  userType?: string;
  dataDependency: IPaymentTermDataDependency;
};

export const TractorPaymentTermFormContext = ({
  children,
  setUserDetails,
  data,
  userType,
  dataDependency,
}: Props): JSX.Element => {
  const { setValue } = useFormContext();
  const { allPaymentTypes, expenseCategories } = dataDependency;
  const loading = false;
  const [availablePayTypes, setAvailablePayTypes] = useState<PaymentType[]>([]);
  const [paymentUnit] = useState<PaymentUnitType[]>([]);
  const [operationModes] = useState([]);
  const [savedLoadPaymentTerms, setSavedLoadPaymentTerms] = useState<
    PaymentPerLoad[]
  >([]);
  const [payToDetails, setPayToDetails] =
    useState<OwnerOperatorPaymentRecipient>();
  const [scheduledPayment, setScheduledPayment] = useState<PaymentPerLoad[]>(
    []
  );
  const [scheduledDeduction, setScheduledDeduction] = useState<
    PaymentPerLoad[]
  >([]);

  const createNewPaymentTermForLoad = useCallback(
    (paymentTerm: CreateDriverPaymentTerm) => {
      const doSave = async () => {
        const userStorage = StorageManager.getUser();

        if (userType === EquipmentType.Tractor) {
          paymentTerm.tractorId = data.id;
        } else {
          paymentTerm.dispatcherId = data.id;
        }

        paymentTerm.organizationId = userStorage.organizationId;

        const savedPaymentPerLoadResponse =
          userType === EquipmentType.Tractor
            ? await paymentService.createTractorPaymentTerms(paymentTerm)
            : await paymentService.createWebUserPaymentTerms(paymentTerm);

        if (savedPaymentPerLoadResponse instanceof ServiceError) {
          // should be decided what to do
          return null;
        } else if (savedPaymentPerLoadResponse != null) {
          prepareDataForForm();
          // const tractorPaymentTerms = await getTractorPaymentTerms(
          //   data.id,
          //   allPaymentTypes
          // );
          // const filteredPayTypes = filterAvailablePaymentTypes(
          //   tractorPaymentTerms,
          //   allPaymentTypes
          // );

          // const copiedPaymentTerms = [...savedLoadPaymentTerms];
          // // setUserDetails({
          // //   ...data,
          // //   paymentTerms: {
          // //     ...data.paymentTerms,
          // //     loadRateDTO: copiedPaymentTerms,
          // //   },
          // // });

          // setAvailablePayTypes(filteredPayTypes);
          // setSavedLoadPaymentTerms(tractorPaymentTerms?.loadRateDTO || []);
          // setValue(
          //   'paymentTerms.loadRateDTO',
          //   tractorPaymentTerms?.loadRateDTO
          // );
        }
      };

      doSave();
    },
    [data, allPaymentTypes, savedLoadPaymentTerms]
  );

  const deletePaymentForLoad = useCallback(
    (id: number) => {
      const doDelete = async () => {
        const deleteResponse =
          userType === EquipmentType.Tractor
            ? await paymentService.deleteTractorLoadRateByIDs([id])
            : await paymentService.deleteWebUserLoadRateByIDs([id]);

        if (deleteResponse instanceof ServiceError) {
          // should be decided what to do
          return null;
        } else {
          prepareDataForForm();
          // setUserDetails({
          //   ...data,
          //   paymentTerms: {
          //     ...data.paymentTerms,
          //     loadRateDTO: filteredItems,
          //   },
          // });
        }
      };

      doDelete();
    },
    [savedLoadPaymentTerms, setUserDetails, data]
  );

  const filterAvailablePaymentTypes = (
    driverPaymentTermsArg: DriverPaymentTermsGrouped | null,
    availablePayTypesArg: PaymentType[]
  ): PaymentType[] => {
    let loadPaymentTerms: PaymentPerLoad[] = [];
    let filteredPayTypes: PaymentType[] = [];

    if (driverPaymentTermsArg?.loadRateDTO?.length) {
      loadPaymentTerms = driverPaymentTermsArg.loadRateDTO;

      availablePayTypesArg.map((eachPaymentType) => {
        let paymentTypeUsageCounter = 0;
        let isAdd = true;
        for (let index = 0; index < loadPaymentTerms.length; index += 1) {
          if (loadPaymentTerms[index].payTypeId === eachPaymentType.id) {
            paymentTypeUsageCounter += 1;
            isAdd = false;
          }

          if (paymentTypeUsageCounter === 2) {
            break;
          }
        }
        if (isAdd) {
          filteredPayTypes.push(eachPaymentType);
        }
      });
    } else {
      filteredPayTypes = availablePayTypesArg;
    }

    if (userType != EquipmentType.Tractor) {
      const filteredPayTypeWebuser: PaymentType[] = [];
      filteredPayTypes.map((eachPaymentType) => {
        if (eachPaymentType.itemCode === 'REVENUE_SHARE') {
          filteredPayTypeWebuser.push(eachPaymentType);
        }
        if (eachPaymentType.itemCode === 'FLAT_RATE') {
          filteredPayTypeWebuser.push(eachPaymentType);
        }
      });
      filteredPayTypes = filteredPayTypeWebuser;
    }

    return filteredPayTypes;
  };
  async function prepareDataForForm() {
    const tractorPaymentTerms =
      userType === EquipmentType.Tractor
        ? await getTractorPaymentTerms(data.id)
        : await getWebUserPaymentTerms(data.id);
    const filteredPayTypes = filterAvailablePaymentTypes(
      tractorPaymentTerms,
      allPaymentTypes
    );
    setAvailablePayTypes(filteredPayTypes);
    setSavedLoadPaymentTerms(tractorPaymentTerms?.loadRateDTO || []);
    setScheduledPayment(tractorPaymentTerms?.scheduledPay || []);
    setScheduledDeduction(
      tractorPaymentTerms?.scheduledDeductionTractorDTO || []
    );
    setPayToDetails(tractorPaymentTerms?.payToDetails);
    setValue('paymentTerms', tractorPaymentTerms);
    setUserDetails?.({
      ...data,
      paymentTerms: tractorPaymentTerms,
    });
  }
  useEffect(() => {
    prepareDataForForm();
  }, []);

  const contextData = {
    availablePayTypes,
    expenseCategories,
    paymentUnit,
    savedLoadPaymentTerms,
    allPaymentTypes,
    setAvailablePayTypes,
    operationModes,
    createNewPaymentTermForLoad,
    deletePaymentForLoad,
    userType,
    scheduledPayment,
    scheduledDeduction,
    payToDetails,
  };
  return (
    <FormContext.Provider value={contextData}>
      {loading ? null : children}
    </FormContext.Provider>
  );
};

export default FormContext;
