export const BookingListStyle = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
};
export const BookingListItemStyle = {
  fontSize: '12px',
  flexDirection: 'column',
};
export const BookingButtonStyle = {
  fontSize: '16px',
};

export const textPrimaryColor = {
  color: '#040022',
};

export const textSecondary = {
  color: '#001122',
};

export const timelineContentStyle = {
  marginTop: '-10px',
};

export const bookingConfirmTitleStyle = {
  padding: '12px 16px 12px 16px',
  display: 'flex',
  justifyContent: 'space-between',
};

export const bookingTitleStyle = {
  fonrSize: '16px',
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#040022',
};

export const bookingSubTitleStyle = {
  padding: '10px 17px',
  ...textPrimaryColor,
};
export const bookingDividerStyle = {
  marginTop: '10px',
};

export const bookingContentTitleStyle = {
  padding: '0px 16px 0px 16px',
};

export const bookingContentHeaderStyle = {
  fontSize: '14px',
  lineHeight: '23px',
  ...textPrimaryColor,
  fontWeight: 500,
};
export const bookingMilesStyle = {
  color: '#2B64CB',
  fontWeight: 600,
  lineHeight: '32px',
};
