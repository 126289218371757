import { AXELE_ROLE_TYPE, AXELE_PERMISSION_TYPE } from '../../Permission';
import { getHasPermission } from '../../Permission/utils/helperUtils';

export const useContractPermission = () => {
  const hasContractViewPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CONTRACT_VIEW],
  });
  const hasContractCreatePermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CONTRACT_ADD],
  });
  const hasContractDeletePermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CONTRACT_REMOVE],
  });
  const hasContractEditPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CONTRACT_EDIT],
  });

  return {
    hasContractViewPermission,
    hasContractCreatePermission,
    hasContractDeletePermission,
    hasContractEditPermission,
  };
};
