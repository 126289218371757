export const FooterContainer = {
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px',
  gap: '10px',
  marginTop: '13px',
};

export const KpiTextWrapperStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2px 16px 2px 8px',
  gap: '16px',
  height: '34px',
  background: '#EDF3FF',
  borderRadius: '8px',
};
const commonTextStyles = {
  lineHeight: '150%',
  letterSpacing: '0.15px',
};
export const KpiLabelStyles = {
  ...commonTextStyles,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  color: 'rgba(0, 17, 34, 0.6)',
};

export const KpiValueStyles = {
  ...commonTextStyles,
  fontWeight: '600',
  fontSize: '20px',
};
