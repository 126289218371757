import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import { getStyledGanttHeader } from '../../styles';
import { VerticalDivider } from '../Generic/VerticalDivider';
import { GridFilters } from './Filters/GridFilters';
import { useTranslation } from 'react-i18next';

interface ITripsLayoutHeader {
  tableConfigurationProps?: any;
  setDefaultFilters?: any;
  onPageFilterChange?: any;
  onAllFilterSubmit?: any;
  onAllFilterReset?: any;
}

const TripsLayoutHeader: React.FC<ITripsLayoutHeader> = ({
  tableConfigurationProps,
  onPageFilterChange,
  onAllFilterSubmit,
  onAllFilterReset,
}) => {
  const { isMobile } = useThemeResponsive();
  const theme = useTheme();
  const styles = getStyledGanttHeader({ theme });
  const { t, ready } = useTranslation();
  return (
    <Stack
      direction={'row'}
      sx={styles.GanttFilterContainer}
      spacing={isMobile ? 0 : 1}
    >
      <Typography sx={styles.LayoutTitleStyles}>
        {isMobile ? t('TitleTrips') : t('TitleUnassignedTrips')}
      </Typography>
      <VerticalDivider />
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <GridFilters
            onPageFilterChange={onPageFilterChange}
            onAllFilterSubmit={onAllFilterSubmit}
            onAllFilterReset={onAllFilterReset}
            tableConfigurationProps={tableConfigurationProps}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default observer(TripsLayoutHeader);
