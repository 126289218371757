import { QueryParams } from '../../../../../../models';
import { CreateLoadStopRequestStopDTO } from '../../../../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';

interface IEmptyTrip {
  organizationId: number;
  stops: Array<CreateLoadStopRequestStopDTO>;
  terminalId?: string;
  loadStatus?: string;
}

export class CreateEmptyTripDTO extends QueryParams implements IEmptyTrip {
  organizationId!: number;
  stops: Array<CreateLoadStopRequestStopDTO>;
  terminalId?: string;
  loadStatus?: string;

  constructor(data: IEmptyTrip) {
    super();
    this.organizationId = data?.organizationId;
    this.stops = data?.stops;
    this.terminalId = data?.terminalId;
    this.loadStatus = 'AVAILABLE';
  }
}
