import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import FormDateTimePicker from '../../../Ui/FormDateTimePicker/FormDateTimePicker';
import Switch from '../../../Ui/Switcher';

export const MarkProgressTimeoffForm = () => {
  const { control, watch } = useFormContext();
  const { getCompanyPreferences } = useRootStore();
  const [actualTimeOfArrivalChecked, actualTimeOfCompletionChecked] = watch([
    'actualTimeOfArrivalChecked',
    'actualTimeOfCompletionChecked',
  ]);

  return (
    <Grid spacing={1} container>
      <Grid xs={12} item>
        <Grid container>
          <Switch
            control={control}
            label={'Start'}
            name={`actualTimeOfArrivalChecked`}
            sizes={{ xs: 4 }}
          />
          <FormDateTimePicker
            disabled={!actualTimeOfArrivalChecked}
            disableFuture
            control={control}
            name={`actualTimeOfArrival`}
            sizes={{ xs: 8 }}
            timezone={getCompanyPreferences?.timezone || ''}
            styleProps={{
              float: 'right',
            }}
          />
        </Grid>
        <Grid container>
          <Switch
            disabled={!actualTimeOfArrivalChecked}
            control={control}
            label={'Complete'}
            name={`actualTimeOfCompletionChecked`}
            sizes={{ xs: 4 }}
          />
          <FormDateTimePicker
            disabled={!actualTimeOfCompletionChecked}
            disableFuture
            control={control}
            name={`actualTimeOfCompletion`}
            sizes={{ xs: 8 }}
            timezone={getCompanyPreferences?.timezone || ''}
            styleProps={{
              float: 'right',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
