import { Fragment } from 'react';
import { ELoadboardsPanelTab } from '../../constants/detailsPanel';
import { DetailsTab, DetailsTabProps } from '../DetailsTab';
import { Profitability, ProfitabilityProps } from '../Profitability';
import { TripPlan, TripPlanProps } from '../TripPlan';

export interface LoadBoardsDetailsFormProps {
  selectedTab: ELoadboardsPanelTab;
  tripPlanProps?: TripPlanProps;
  profitabilityProps?: ProfitabilityProps;
  detailsTabProps?: DetailsTabProps;
}

export const LoadBoardsDetailsForm = ({
  selectedTab,
  tripPlanProps,
  profitabilityProps,
  detailsTabProps,
}: LoadBoardsDetailsFormProps) => {
  return (
    <Fragment>
      {tripPlanProps && selectedTab === ELoadboardsPanelTab.TRIP_PLAN && (
        <TripPlan {...tripPlanProps} />
      )}

      {profitabilityProps &&
        selectedTab === ELoadboardsPanelTab.PROFITABILITY && (
          <Profitability {...profitabilityProps} />
        )}

      {detailsTabProps && selectedTab === ELoadboardsPanelTab.DETAILS && (
        <DetailsTab {...detailsTabProps} />
      )}
    </Fragment>
  );
};
