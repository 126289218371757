import Box from '@mui/material/Box';
import { DashboardLoader } from './DhaboardLoader';
import { DashboardLoaderTypes } from './constants/interface';

export const CustomDashboardLoader = ({
  loading,
  ...restProps
}: DashboardLoaderTypes & { loading: boolean }) => {
  return (
    <Box
      className={'CustomDashboardLoader'}
      sx={{
        ...(!loading && {
          display: 'none',
        }),
      }}
    >
      <DashboardLoader {...restProps} />
    </Box>
  );
};
