export const LoadboardsIntegrationConstants = {
  truckStopDescription:
    'TruckStop loadboard enables efficient planning, rates discovery, and identifying the best paying loads and get paid quickly, essential for a smooth trucking operation.',
  datDescription:
    'The DAT Network hosts millions of freight loads and trucks per year in the US and Canada. The network consists of several load board subscription services for small to midsize carriers, freight brokers, and shippers.',
  loadsmartDescription:
    'Loadsmart offers an easy way to find the right freight to keep trucks full and quickly sorts through thousands of available loads by pickup location, destination, rate, and other factors.',
  chRobinsonDescription:
    'With C.H. Robinson, avoid empty miles, compare rates, and instantly book loads with the largest carrier load board in North America.',
  loadboard123Description:
    '123Loadboard provides a freight matching web and mobile app marketplace for carriers, owner­-operators, brokers and shippers to engage with trucking tools to help professional truck drivers find loads and increase their loaded miles.',
};
