import { Expense } from '..';
import { DateTimezoneUtils } from '../../../utils/Timezone.utils';
import {
  CreatePayItemDTO,
  PayItemDTO,
  UpdatePayItemDTO,
} from '../Payments/Payments';
import { QueryParams } from '../commonMixed/QueryParams';
import { ExpenseEntityType, Payment } from './Expenses';

export class GetExpenseListRequest extends QueryParams {
  sort!: string;
  pageNumber!: number;
  pageSize!: number;
  gridColumnMetadataList?: string[];
  expenseIds?: string[];
  categoryKey?: string[];
  excludeCategoryKey?: string[];
  expenseStatuses?: string;
  companyExpense?: boolean;
  dateFrom?: string;
  dateTo?: string;
  categoryId?: string;
  fuelTypes?: string;
  loadIds?: string;
  tractorIds?: string;
  trailerIds?: string;
  driverIds?: string;
  terminalIds?: string[];
  cardNumbers?: string[];
  maintenanceItems?: string[];
  constructor({
    sort,
    pageNumber,
    pageSize,
    gridColumnMetadataList,
    expenseIds,
    categoryKey,
    excludeCategoryKey,
    expenseStatuses,
    maintenanceItems,
    companyExpense,
    dateFrom,
    dateTo,
    categoryId,
    fuelTypes,
    loadIds,
    tractorIds,
    trailerIds,
    driverIds,
    terminalIds,
    cardNumbers,
  }: any) {
    super();
    this.sort = sort;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.gridColumnMetadataList = gridColumnMetadataList;
    this.expenseIds = expenseIds;
    this.categoryKey = categoryKey;
    this.excludeCategoryKey = excludeCategoryKey;
    this.expenseStatuses = expenseStatuses;
    this.companyExpense = companyExpense;
    this.dateFrom = DateTimezoneUtils.toNoTimeZoneDateString(dateFrom);
    this.maintenanceItems = maintenanceItems;
    this.dateTo = DateTimezoneUtils.toNoTimeZoneDateString(
      dateTo,
      undefined,
      false
    );
    this.categoryId = categoryId;
    this.fuelTypes = fuelTypes;
    this.loadIds = loadIds;
    this.tractorIds = tractorIds;
    this.trailerIds = trailerIds;
    this.driverIds = driverIds;
    this.terminalIds = terminalIds;
    this.cardNumbers = cardNumbers;
  }
}

export class GetExpenseShortListRequest extends QueryParams {
  name?: string;
  pageNumber?: number;
  terminalIds?: string[];
  pageSize?: number;
  categoryKey?: string[];
  excludeCategoryKey?: string[];
  hasLinkedMaintenance?: boolean;
  constructor(
    name?: string,
    pageNumber?: number,
    pageSize?: number,
    terminalIds?: string[],
    categoryKey?: string[],
    excludeCategoryKey?: string[],
    hasLinkedMaintenance?: boolean
  ) {
    super();
    this.name = name;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.terminalIds = terminalIds;
    this.categoryKey = categoryKey;
    this.excludeCategoryKey = excludeCategoryKey;
    this.hasLinkedMaintenance = hasLinkedMaintenance || false;
  }
}

export class GetExpenseShortListByCardNumberRequest extends QueryParams {
  cardNumber?: string;
  pageNumber?: number;
  terminalIds?: string[];
  pageSize?: number;
  categoryKey?: string[];
  excludeCategoryKey?: string[];
  constructor(
    cardNumber?: string,
    pageNumber?: number,
    pageSize?: number,
    terminalIds?: string[],
    categoryKey?: string[],
    excludeCategoryKey?: string[]
  ) {
    super();
    this.cardNumber = cardNumber;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.terminalIds = terminalIds;
    this.categoryKey = categoryKey;
    this.excludeCategoryKey = excludeCategoryKey;
  }
}

export class GetExpenseCategoriesListRequest extends QueryParams {
  constructor(
    private itemName?: string,
    private excludeCategoryKey?: string,
    private pageNumber?: number
  ) {
    super();
    this.itemName = itemName;
    this.excludeCategoryKey = excludeCategoryKey;
    this.pageNumber = pageNumber;
  }
}
export class GetExpenseDetailsByIdRequest extends QueryParams {
  constructor(private id: string) {
    super();
    this.id = id;
  }
}

export type ExpensesListRequestType = {
  sort: string;
  pageNumber: number;
  pageSize: number;
  gridColumnMetadataList?: string[];
};

export class VendorNamesListRequest extends QueryParams {
  constructor(
    private pageNumber: number,
    private pageSize: number,
    private vendorSearchNames: string
  ) {
    super();
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.vendorSearchNames = vendorSearchNames;
  }
}
export class FuelTypesListRequest extends QueryParams {
  constructor(private fuelTypeFilter: string) {
    super();
    this.fuelTypeFilter = fuelTypeFilter;
  }
}

export class EntityListRequestDTO {
  payToEntityId?: number;
  payToEntityType?: string;
  nameFilter?: string;
  pageNumber?: number;
  pageSize?: number;
}

export class EntityListResDTO {
  content?: {
    entityId?: number;
    entity?: ExpenseEntityType;
    entityName?: string;
  }[];
}

export class TractorEntityListRequest extends QueryParams {
  payToEntityId?: number;
  payToEntityType?: string;
  nameFilter?: string;
  pageNumber?: number;
  pageSize?: number;
  constructor({ payToEntityId, nameFilter }: any) {
    super();
    this.payToEntityId = payToEntityId;
    this.payToEntityType = 'DRIVER';
    this.nameFilter = nameFilter;
    this.pageNumber = 1;
    this.pageSize = 25;
  }
}

export const prepareFromList = <T>(
  list: T[] | null,
  fieldName: keyof T
): any[] | undefined => {
  if (!list?.length) return;
  return list.map((item: T) => {
    const field = item[fieldName];
    return Array.isArray(field) ? field[0] : field;
  });
};

export class SaveExpenseRequest extends QueryParams {
  id?: string;
  seqNumber?: string;
  paidDate?: string;
  status?: string;
  category?: string;
  expenseCategoryId?: string;
  equipmentId?: string;
  companyExpense?: boolean;
  finalAmount?: number | string;
  grossAmount?: number | string;
  discountAmount?: number | string;
  amount?: number | string;
  paidBy?: any;
  paidTo?: any;
  paidById?: number;
  paidToId?: string;
  createdBy?: string;
  referenceNumber?: string;
  description!: string;
  companyExpenseName?: string;
  cardNumber?: string;
  fuelType?: any;
  fuelQuantity?: number | string;
  equipmentType?: string;
  loadIds?: string[];
  terminalId?: string;
  terminal?: string;
  truckstopAddress?: string;
  state?: any;
  loadSeqNumber?: any;
  driverId?: number;
  constructor(data: any) {
    super();
    this.id = data.id;
    this.seqNumber = data.seqNumber;
    this.paidDate = data.paidDate;
    this.status = data.statusValue ? 'IN_REVIEW' : 'REVIEWED';
    this.category = data.categoryObj?.itemCode;
    this.expenseCategoryId = data.categoryObj?.id;
    this.companyExpense = data.companyExpenseName === 'companyExpense';
    this.grossAmount = data.grossAmount;
    this.discountAmount = data.discountAmount;
    this.finalAmount = data.finalAmount;
    this.amount = data.amount;
    this.paidBy = data.paidByObj?.name;
    this.paidTo = data.paidToObj?.name;
    this.paidById = data.paidByObj?.id;
    this.paidToId = data.paidToObj?.id;
    this.createdBy = data.createdBy;
    this.referenceNumber = data.referenceNumber;
    this.description = data.description;
    this.cardNumber = data.cardNumber;
    this.fuelType = data.fuelType?.key;
    this.fuelQuantity = data.fuelQuantity;
    this.equipmentType = data.equipmentType;
    this.equipmentId = data.equipmentObj?.id;
    this.loadIds = prepareFromList(data['loadsIdAndName'], 'loadIds');

    this.terminalId = data.terminal?.id;
    this.truckstopAddress = data.truckstopAddress;
    this.state = data.state?.value;
    this.driverId = data.driverObj?.id;
  }
}
export class DeleteExpenseRequest extends QueryParams {
  ids?: string[];
  constructor(expenses: Expense[]) {
    super();
    this.ids = prepareFromList(expenses, 'id');
  }
}

export class PaymentRequest extends QueryParams {
  id?: number;
  amount?: number;
  description: string;
  expenseCategoryId: string;
  expenseCategoryKey: string;
  expenseId: string;
  expenseSeqNumber: number;
  fuelQuantity: number;
  fuelType: string;
  isManuallyAdded: boolean;
  loadId: string;
  payCategory: string;
  payCategoryName: string;
  payDate: string;
  settlementId: number;
  state: string;
  truckStop: string;
  constructor(data: Payment, expenseId?: string) {
    super();
    this.id = data.id;
    this.amount =
      data?.amount && data.payCategory === 'DEDUCTION'
        ? -Math.abs(data?.amount)
        : data?.amount;
    this.description = data.description;
    this.expenseCategoryId = data.expenseCategoryId;
    this.expenseCategoryKey = data.expenseCategoryKey;
    this.expenseId = expenseId || data.expenseId;
    this.expenseSeqNumber = data.expenseSeqNumber;
    this.fuelQuantity = data.fuelQuantity;
    this.fuelType = data.fuelType;
    this.isManuallyAdded = true;
    this.loadId = data.loadId;
    this.payCategory = data.payCategory;
    this.payCategoryName = data.payCategoryName;
    this.payDate = data.payDate;
    this.settlementId = data.settlementId;
    this.state = data.state;
    this.truckStop = data.truckStop;
  }
}

export class SavePaymentRequest extends QueryParams {
  payItemDTOs?: Array<PayItemDTO>;
  constructor(data: Payment[], expenseId?: string) {
    super();
    const organizationId = new QueryParams().organizationId;
    this.payItemDTOs = data?.map(
      (d) =>
        new CreatePayItemDTO(
          { ...d, expenseId: d?.expenseId ?? expenseId },
          organizationId
        )
    );
  }
}

export class SavePayItemRequest extends QueryParams {
  payItemDTOs?: Array<PayItemDTO>;
  constructor(data: Payment[], expenseId?: string) {
    super();
    const organizationId = new QueryParams().organizationId;
    this.payItemDTOs = data?.map(
      (d) => new UpdatePayItemDTO(d, organizationId)
    );
  }
}

export class DeletePaymentsRequest extends QueryParams {
  ids?: string[];
  constructor(data: PayItemDTO[], paymentType: string) {
    super();
    this.ids = prepareFromList(
      data.filter((item) => item.entityType === paymentType),
      'entityId'
    );
  }
}

export class DeletePayItemDTO extends QueryParams {
  ids?: number[];
  constructor(data: PayItemDTO[]) {
    super();
    this.ids = data?.map((e) => e?.id!);
  }
}
export class ChangeStatusBulkRequest extends QueryParams {
  ids!: string[];
  status!: string;
  constructor(dto: any) {
    super();
    this.ids = dto.ids;
    this.status = dto.status;
  }
}
