import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Box, Button, Link, Typography } from '@mui/material';
import React, { ChangeEvent, Component } from 'react';
import EncryptedStorageManager from '../../../StorageManager/EncryptedStorageManager';
import { ErrorsMsg } from '../../constants/IntegrationFormErrorText';
import { AutocompleteAsync } from '../AutocompleteAsync/AutocompleteAsync';
import { SimpleTextField } from '../TextField/SimpleTextField';
import { Switch } from '@components/ui-kit/switch';
import FormControlLabel from '@mui/material/FormControlLabel';

export default class IntegrationFormContent extends Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orgId: '',
      name: '',
      password: '',
      API_KEY: '',
      displayName: '',
      ACCESS_TOKEN: '',
      AUTO_SYNC: 'false',
      TOKEN: '',
      GROUP_ID: '',
      terminals: [],
      terminalIds: [],
      USER_ID: '',
      COMPANY_ID: '',
      PASSWORD: '',
      USERNAME: '',
      DATABASE: '',
      code: '',
      DISPLAY_NAME: '',
      INTEGRATION_ID: '',
      CARRIER_CODE: '',
      COMPANY_NAME: '',
      MC: '',
      DOT: '',
      CODE: '',
      REDIRECTURI: '',
      formErrors: {},
      touchedField: [],
      INTEGRATION_EMAIL: '',
      INTEGRATION_PASSWORD: '',
      CARRIER_USERNAME: '',
      isUpdatedTerminals: false,
    };
  }

  componentDidMount() {
    let organizationId;
    const userStorage = EncryptedStorageManager.getItem('user');
    if (userStorage) {
      const { organizationId: orgId } = userStorage;
      organizationId = orgId;
      this.setState({ orgId: organizationId });
    }
  }

  onAutoAsyncChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked ? 'true' : 'false';
    const name = event.target.name;
    const editedFields = this.state.touchedField;
    !editedFields.includes(name) && editedFields.push(name);
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      touchedField: editedFields,
    }));
  };

  onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const editedFields = this.state.touchedField;
    !editedFields.includes(name) && editedFields.push(name);
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      touchedField: editedFields,
    }));
  };

  submitForm = () => {
    const formData: Array<IFormData> = [];
    this.props?.sideDrawerDisplayData?.fields?.map((data: IFormFieldType) => {
      if (data.type !== 'dropdown') {
        const formF = {
          name: data.name,
          value: this.state[data.name],
        };
        formData.push(formF);
      }
    });
    const payload = {
      data: formData,
      integrationType: 'ELD',
      isValid: true,
      organizationId: this.state.orgId,
      providerId: this.props.currentCustomerData?.providerId,
      terminalIds: this.state.terminalIds,
    };

    if (this.props.currentCustomerData.providerId === 4) {
      sessionStorage.setItem('KeepTruckingCreds', JSON.stringify(payload));
    }
    this.props.validateFormCreds(payload);
  };

  updateForm = (
    displayValues: any,
    terminal_id: Array<string>,
    id: string,
    isReauthenticate: boolean
  ) => {
    const formData: Array<IFormData> = [];
    this.props?.sideDrawerDisplayData?.fields?.map((data: IFormFieldType) => {
      if (data.type !== 'dropdown') {
        const formF = {
          name: data.name,
          value: displayValues[data.name]
            ? displayValues[data.name]
            : this.state[data.name],
        };
        formData.push(formF);
      }
    });
    const tIds = terminal_id.map((el: any) => el.id);

    const payload = {
      id: id,
      data: formData,
      integrationType: 'ELD',
      isValid: true,
      organizationId: this.state.orgId,
      providerId: this.props.currentCustomerData?.providerId,
      terminalIds:
        this.state.terminalIds && this.state.terminalIds.length > 0
          ? this.state.terminalIds
          : tIds,
    };

    if (this.props.currentCustomerData.providerId === 4) {
      sessionStorage.setItem('KeepTruckingCreds', JSON.stringify(payload));
    }
    this.props.integrationType === 'ELD'
      ? this.props.validateFormCreds(payload)
      : this.props.updateAccountCard(payload, isReauthenticate);
  };

  terminalOnChange = (args: any) => {
    const ids: Array<string> = [];
    args.forEach((item: any) => {
      ids.push(item.id);
    });
    this.setState({
      terminals: args,
      terminalIds: ids,
      isUpdatedTerminals: true,
    });
  };

  checkRequiredField = () => {
    const fieldValidationErrors = this.state.formErrors;
    this.props?.sideDrawerDisplayData?.fields.forEach(
      (item: IFormFieldType) => {
        switch (item.name) {
          case 'displayName':
          case 'DISPLAY_NAME':
          case 'INTEGRATION_EMAIL':
          case 'GROUP_ID':
          case 'USERNAME':
          case 'API_KEY':
          case 'COMPANY_ID':
          case 'USER_ID':
          case 'PASSWORD':
          case 'INTEGRATION_ID':
          case 'CARRIER_CODE':
          case 'COMPANY_NAME':
          case 'INTEGRATION_PASSWORD':
          case 'CARRIER_USERNAME':
            if (this.state[item.name].length <= 0) {
              fieldValidationErrors[item.name] = ErrorsMsg['mandatoryFields'];
            } else if (this.state[item.name].length > 50) {
              fieldValidationErrors[item.name] = ErrorsMsg['maxLength50'];
            } else if (this.state[item.name].length > 0) {
              delete fieldValidationErrors[item.name];
            }
            break;
          case 'ACCESS_TOKEN':
          case 'TOKEN':
            if (this.state[item.name].length <= 0) {
              fieldValidationErrors[item.name] = ErrorsMsg['mandatoryFields'];
            } else if (this.state[item.name].length > 255) {
              fieldValidationErrors[item.name] = ErrorsMsg['maxLength255'];
            } else if (this.state[item.name].length > 0) {
              delete fieldValidationErrors[item.name];
            }
            break;
          case 'terminalIds':
          case 'TERMINAL_IDS':
            if (this.props.getTerminalOptions?.length > 0) {
              if (this.state.terminalIds.length <= 0) {
                fieldValidationErrors.message = ErrorsMsg['mandatoryFields'];
              } else if (this.state.terminalIds.length > 0) {
                delete fieldValidationErrors.message;
              }
            }

            break;
        }
      }
    );
    this.setState({ formErrors: fieldValidationErrors }, () => {
      Object.keys(fieldValidationErrors).length <= 0 && this.submitForm();
    });
  };

  checkEditRequiredField = (
    displayValues?: IDisplayValues,
    terminal_id?: Array<string>,
    id?: string,
    isReauthenticate?: boolean,
    selectedTerminalIds?: Array<string>
  ) => {
    const fieldValidationErrors = this.state.formErrors;
    this.props?.sideDrawerDisplayData?.fields.forEach(
      (item: IFormFieldType) => {
        switch (item.name) {
          case 'displayName':
          case 'DISPLAY_NAME':
          case 'INTEGRATION_EMAIL':
          case 'GROUP_ID':
          case 'USERNAME':
          case 'API_KEY':
          case 'COMPANY_ID':
          case 'USER_ID':
          case 'PASSWORD':
          case 'INTEGRATION_ID':
          case 'CARRIER_CODE':
          case 'COMPANY_NAME':
          case 'CARRIER_USERNAME':
            if (displayValues[item.name]?.length <= 0) {
              fieldValidationErrors[item.name] = ErrorsMsg['mandatoryFields'];
            } else if (displayValues[item.name]?.length > 50) {
              fieldValidationErrors[item.name] = ErrorsMsg['maxLength50'];
            } else if (displayValues[item.name]?.length > 0) {
              delete fieldValidationErrors[item.name];
            }
            break;
          case 'INTEGRATION_PASSWORD':
            if (
              displayValues[item.name]?.length <= 0 &&
              this.state[item.name]?.length <= 0
            ) {
              fieldValidationErrors[item.name] = ErrorsMsg['mandatoryFields'];
            } else if (
              displayValues[item.name]?.length > 50 &&
              this.state[item.name]?.length
            ) {
              fieldValidationErrors[item.name] = ErrorsMsg['maxLength50'];
            } else if (
              displayValues[item.name]?.length > 0 ||
              this.state[item.name]?.length > 0
            ) {
              delete fieldValidationErrors[item.name];
            }
            break;
          case 'ACCESS_TOKEN':
          case 'TOKEN':
            if (displayValues[item.name].length <= 0) {
              fieldValidationErrors[item.name] = ErrorsMsg['mandatoryFields'];
            } else if (displayValues[item.name].length > 255) {
              fieldValidationErrors[item.name] = ErrorsMsg['maxLength255'];
            } else if (displayValues[item.name].length > 0) {
              delete fieldValidationErrors[item.name];
            }
            break;
          case 'terminalIds':
          case 'TERMINAL_IDS':
            if (this.props.getTerminalOptions?.length > 0) {
              if (
                this.state.terminalIds.length <= 0 &&
                selectedTerminalIds &&
                selectedTerminalIds?.length <= 0
              ) {
                fieldValidationErrors.message = ErrorsMsg['mandatoryFields'];
              } else if (this.state.terminalIds.length > 0) {
                delete fieldValidationErrors.message;
              }
            }
            break;
        }
      }
    );
    this.setState({ formErrors: fieldValidationErrors }, () => {
      Object.keys(fieldValidationErrors).length <= 0 &&
        this.updateForm(displayValues, terminal_id, id, isReauthenticate);
    });
  };

  render() {
    const fields = this.props.dataToUpdate;
    let terminal_id: any = [],
      terminal_list_ids: any = [],
      id = '';
    id = this.props.dataToUpdate?.id;

    if (Object.keys(fields).length > 0) {
      terminal_id = [];
      terminal_list_ids = [];
      this.props.getTerminalOptions &&
        this.props.getTerminalOptions.length > 0 &&
        this.props.getTerminalOptions.map((data: ITerminalType) => {
          if (fields && fields.terminalIds && fields.terminalIds.length > 0) {
            fields.terminalIds.map((el: any) => {
              if (data.id === el) {
                const tData = {
                  name: data.name,
                  id: data.id,
                };
                terminal_id.push(tData);
                terminal_list_ids.push(tData);
              }
            });
          }
        });
    }

    const terminal_list =
      this.state.terminals.length > 0 || this.state.isUpdatedTerminals
        ? this.state.terminals
        : terminal_list_ids;

    let displayName = '',
      DISPLAY_NAME = '',
      AUTO_SYNC = 'false',
      ACCESS_TOKEN = '',
      GROUP_ID = '',
      API_KEY = '',
      USER_ID = '',
      COMPANY_ID = '',
      PASSWORD = '',
      USERNAME = '',
      DATABASE = '',
      CARRIER_CODE = '',
      COMPANY_NAME = '',
      MC = '',
      DOT = '',
      INTEGRATION_ID = '',
      code = '',
      CODE = '',
      REDIRECTURI = '',
      TOKEN = '',
      CARRIER_USERNAME = '',
      INTEGRATION_PASSWORD = '',
      INTEGRATION_EMAIL = '';

    fields?.data?.map((data: any) => {
      switch (data.name) {
        case 'displayName':
          displayName = this.state.touchedField.includes('displayName')
            ? this.state.displayName
            : data.value;
          break;
        case 'AUTO_SYNC':
          AUTO_SYNC = this.state.touchedField.includes('AUTO_SYNC')
            ? this.state.AUTO_SYNC
            : data.value;
          break;
        case 'DISPLAY_NAME':
          DISPLAY_NAME = this.state.touchedField.includes('DISPLAY_NAME')
            ? this.state.DISPLAY_NAME
            : data.value;
          break;
        case 'ACCESS_TOKEN':
          ACCESS_TOKEN = this.state.touchedField.includes('ACCESS_TOKEN')
            ? this.state.ACCESS_TOKEN
            : data.value;
          break;
        case 'TOKEN':
          TOKEN = this.state.touchedField.includes('TOKEN')
            ? this.state.TOKEN
            : data.value;
          break;
        case 'GROUP_ID':
          GROUP_ID = this.state.touchedField.includes('GROUP_ID')
            ? this.state.GROUP_ID
            : data.value;
          break;
        case 'API_KEY':
          API_KEY = this.state.touchedField.includes('API_KEY')
            ? this.state.API_KEY
            : data.value;
          break;
        case 'USER_ID':
          USER_ID = this.state.touchedField.includes('USER_ID')
            ? this.state.USER_ID
            : data.value;
          break;
        case 'COMPANY_ID':
          COMPANY_ID = this.state.touchedField.includes('COMPANY_ID')
            ? this.state.COMPANY_ID
            : data.value;
          break;
        case 'PASSWORD':
          PASSWORD = this.state.touchedField.includes('PASSWORD')
            ? this.state.PASSWORD
            : data.value;
          break;
        case 'USERNAME':
          USERNAME = this.state.touchedField.includes('USERNAME')
            ? this.state.USERNAME
            : data.value;
          break;
        case 'DATABASE':
          DATABASE = this.state.touchedField.includes('DATABASE')
            ? this.state.DATABASE
            : data.value;
          break;
        case 'code':
          code = this.state.code ? this.state.code : data.value;
          break;
        case 'INTEGRATION_ID':
          INTEGRATION_ID = this.state.touchedField.includes('INTEGRATION_ID')
            ? this.state.INTEGRATION_ID
            : data.value;
          break;
        case 'CARRIER_CODE':
          CARRIER_CODE = this.state.touchedField.includes('CARRIER_CODE')
            ? this.state.CARRIER_CODE
            : data.value;
          break;
        case 'COMPANY_NAME':
          COMPANY_NAME = this.state.touchedField.includes('COMPANY_NAME')
            ? this.state.COMPANY_NAME
            : data.value;
          break;
        case 'MC':
          MC = this.state.touchedField.includes('MC')
            ? this.state.MC
            : data.value;
          break;
        case 'DOT':
          DOT = this.state.touchedField.includes('DOT')
            ? this.state.DOT
            : data.value;
          break;
        case 'CODE':
          CODE = this.state.touchedField.includes('CODE')
            ? this.state.CODE
            : data.value;
          break;
        case 'REDIRECTURI':
          REDIRECTURI = this.state.touchedField.includes('REDIRECTURI')
            ? this.state.REDIRECTURI
            : data.value;
          break;
        case 'INTEGRATION_EMAIL':
          INTEGRATION_EMAIL = this.state.touchedField.includes(
            'INTEGRATION_EMAIL'
          )
            ? this.state.INTEGRATION_EMAIL
            : data.value;
          break;
        case 'INTEGRATION_PASSWORD':
          INTEGRATION_PASSWORD = this.state.touchedField.includes(
            'INTEGRATION_PASSWORD'
          )
            ? this.state.INTEGRATION_PASSWORD
            : data.value;
          break;
        case 'CARRIER_USERNAME':
          CARRIER_USERNAME = this.state.touchedField.includes(
            'CARRIER_USERNAME'
          )
            ? this.state.CARRIER_USERNAME
            : data.value;
          break;
      }
    });
    const displayValues: IDisplayValues = {
      displayName: displayName,
      DISPLAY_NAME: DISPLAY_NAME,
      ACCESS_TOKEN: ACCESS_TOKEN,
      AUTO_SYNC: AUTO_SYNC,
      TOKEN: TOKEN,
      GROUP_ID: GROUP_ID,
      API_KEY: API_KEY,
      USER_ID: USER_ID,
      COMPANY_ID: COMPANY_ID,
      PASSWORD: PASSWORD,
      USERNAME: USERNAME,
      DATABASE: DATABASE,
      code: code,
      CARRIER_CODE: CARRIER_CODE,
      COMPANY_NAME: COMPANY_NAME,
      MC: MC,
      DOT: DOT,
      INTEGRATION_ID: INTEGRATION_ID,
      CODE: CODE,
      REDIRECTURI: REDIRECTURI,
      INTEGRATION_EMAIL: INTEGRATION_EMAIL,
      INTEGRATION_PASSWORD: INTEGRATION_PASSWORD,
      CARRIER_USERNAME: CARRIER_USERNAME,
    };
    const helperTexts: { [key: string]: string } = {},
      terminalErrMessage: any = {};

    if (Object.keys(this.props?.formErr)?.length > 0) {
      if (this.props?.formErr?.integrationType === 'LOADBOARD') {
        this.props?.sideDrawerDisplayData?.fields?.forEach(
          (element: IFormFieldType) => {
            if (this.props?.formErr[element.name])
              helperTexts[element.name] = this.props?.formErr[element.name][0];
          }
        );
      } else if (this.props?.integrationType === 'ACCOUNTING') {
        if (!this.props.formErr?.isDisplayNameUnique) {
          helperTexts['displayName'] = ErrorsMsg['displayName'];
        }
        if (this.props.formErr?.isTerminalIdsInUse) {
          terminalErrMessage.message =
            (this.props.formErr?.terminalsInUse).join(', ') +
            ' ' +
            ErrorsMsg['terminalInUse'];
        }
      } else if (
        this.props.formErr?.displayNameUnique &&
        !this.props.formErr?.credentialsUnique
      ) {
        this.props?.sideDrawerDisplayData?.fields?.forEach(
          (element: IFormFieldType) => {
            if (element.name !== 'displayName') {
              helperTexts[element.name] = ErrorsMsg[element.name];
            }
          }
        );
      } else if (
        !this.props?.formErr?.displayNameUnique &&
        this.props?.formErr?.credentialsUnique
      ) {
        helperTexts['displayName'] = ErrorsMsg.displayName;
      } else if (
        !this.props?.formErr?.credentialsUnique &&
        !this.props?.formErr?.displayNameUnique &&
        !this.props?.formErr?.integrationType
      ) {
        this.props?.sideDrawerDisplayData?.fields?.forEach(
          (element: IFormFieldType) => {
            helperTexts[element.name] = ErrorsMsg[element.name];
          }
        );
      }
    }

    let dropdownErrMessage: any = {};
    if (this.state.formErrors?.message) {
      dropdownErrMessage = this.state.formErrors;
    } else if (terminalErrMessage?.message) {
      dropdownErrMessage = terminalErrMessage;
    } else dropdownErrMessage = undefined;
    return (
      <Box sx={{ width: '100%' }}>
        <Box className="info-block">
          <Typography
            className="main-heading"
            variant="h5"
            color={'primary.main'}
          >
            {this.props.sideDrawerDisplayData.label}
          </Typography>
          <Typography
            variant="subtitle1"
            className="description"
            color={'text.primary'}
            pt={1}
          >
            {this.props.sideDrawerDisplayData.desc}
          </Typography>
          {this.props.sideDrawerDisplayData.userGuide && (
            <Link
              href={this.props.sideDrawerDisplayData.userGuide}
              underline="always"
              target="_blank"
              rel="noopener"
              sx={{ pt: '12px', display: 'block' }}
            >
              Read User Guide
              <ArticleOutlinedIcon
                sx={{ ml: '11px', width: '18px', height: '18px' }}
              />
            </Link>
          )}
        </Box>
        <Box className="form-block" sx={{ pt: '2' }}>
          <form autoComplete="off">
            {this.props?.sideDrawerDisplayData?.fields?.map(
              (item: IForm, key: number) => {
                let inputType = '';
                if (
                  this.props.currentCustomerData.providerId === 4 &&
                  item.name === 'code'
                ) {
                  inputType = 'disabled';
                } else if (
                  helperTexts[item.name] !== undefined ||
                  this.state.formErrors[item.name] !== undefined
                ) {
                  inputType = 'error';
                }
                return (
                  <Box key={key} sx={{ pt: 1 }}>
                    {item.type === 'dropdown' ? (
                      <>
                        {this.props.getTerminalOptions?.length > 0 && (
                          <AutocompleteAsync
                            name={'terminals'}
                            getOptions={() =>
                              new Promise((res) =>
                                res(this.props.getTerminalOptions)
                              )
                            }
                            fieldName={'name'}
                            label={item.displayText}
                            value={terminal_list}
                            onChangeCb={(args) => this.terminalOnChange(args)}
                            error={dropdownErrMessage}
                          />
                        )}
                      </>
                    ) : item.type === 'boolean' ? (
                      <>
                        <FormControlLabel
                          style={{ marginLeft: 0 }}
                          value="bottom"
                          control={
                            <Switch
                              onChange={this.onAutoAsyncChange}
                              defaultChecked={
                                displayValues[item.name] === 'true'
                              }
                              name={item.name}
                            />
                          }
                          label={`${
                            displayValues[item.name] === 'true'
                              ? 'Turn-off auto sync'
                              : 'Turn-on auto sync'
                          }`}
                          labelPlacement="start"
                        />
                      </>
                    ) : (
                      <>
                        {this.props.currentCustomerData['providerId'] === 3 &&
                          this.props.currentCustomerData['providerName'] ===
                            'Load Smart' &&
                          key === 3 && (
                            <Typography sx={{ textAlign: 'center', mt: 1 }}>
                              OR
                            </Typography>
                          )}
                        <SimpleTextField
                          label={item.displayText}
                          type={inputType}
                          isPassword={item.type === 'text' ? false : true}
                          size={'small'}
                          variant={'standard'}
                          name={item.name}
                          onChangeHandler={this.onChangeHandler}
                          value={
                            Object.keys(fields).length > 0
                              ? displayValues[item.name]
                              : this.state[item.name]
                          }
                          helperText={
                            helperTexts[item.name] ||
                            this.state.formErrors[item.name]
                          }
                        />
                      </>
                    )}
                  </Box>
                );
              }
            )}
            <div className="save-btn">
              {this.props?.dataToUpdate &&
              Object.keys(this.props?.dataToUpdate).length === 0 ? (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: '10px',
                    textTransform: 'capitalize',
                    marginTop: '12px',
                  }}
                  onClick={
                    this.props.integrationType === 'ELD' ||
                    this.props.integrationType === 'ACCOUNTING' ||
                    this.props.integrationType === 'LOADBOARD'
                      ? this.checkRequiredField
                      : this.submitForm
                  }
                >
                  {`${
                    this.props.currentCustomerData.providerId === 100 ||
                    this.props.currentCustomerData.providerId === 4
                      ? 'Connect'
                      : 'Save'
                  }`}
                </Button>
              ) : (
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'capitalize',
                      marginTop: '12px',
                    }}
                    onClick={() =>
                      this.checkEditRequiredField(
                        displayValues,
                        terminal_id,
                        id,
                        false,
                        terminal_list
                      )
                    }
                  >
                    Save
                  </Button>

                  {this.props.currentCustomerData.providerId === 4 && (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        borderRadius: '10px',
                        textTransform: 'capitalize',
                        marginTop: '12px',
                        marginLeft: '5px',
                      }}
                      onClick={() =>
                        this.checkEditRequiredField(
                          displayValues,
                          terminal_id,
                          id,
                          true
                        )
                      }
                    >
                      Reauthenticate
                    </Button>
                  )}
                </Box>
              )}
            </div>
          </form>
        </Box>
      </Box>
    );
  }
}

export interface Props {
  sideDrawerDisplayData: any;
  currentCustomerData: any;
  validateFormCreds?: any;
  closeDrawer?: any;
  dataToUpdate?: any;
  activationErrMessage?: string;
  updateAccountCard?: any;
  formErr?: any;
  authenticateOAuth?: any;
  getTerminalOptions?: any;
  integrationType?: string;
}

export interface IState extends IKeyObj {
  isBtnActivated: boolean;
  formFields: any;
  name: string;
  password: string;
  API_KEY: string;
  displayName: string;
  ACCESS_TOKEN: string;
  GROUP_ID: string;
  USER_ID: string;
  COMPANY_ID: string;
  PASSWORD: string;
  USERNAME: string;
  DATABASE: string;
}

export interface IForm {
  name: string;
  type: string;
  displayText: string;
}

export interface IKeyObj {
  [key: string]: any;
}

export interface IFormData {
  name: string;
  value: string;
}

export interface IDisplayValues extends IKeyObj {
  displayName: string;
  ACCESS_TOKEN: string;
  GROUP_ID: string;
  API_KEY: string;
}

export interface IFormFieldType {
  name: string;
  type: string;
  displayText: string;
}

export interface ITerminalType {
  companyName: string;
  id: string;
  isPrimary: boolean;
  name: string;
}
