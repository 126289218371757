import { Box, InputAdornment, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import InputLabel from '../../../ui-kit/InputLabel';
import TextInput from '../../../ui-kit/components/TextField';

const TextField: React.FC<{
  id?: string;
  control?: any;
  name: string;
  label: string;
  onChangeCallback?: any;
  sizes?: {
    xs: number;
  };
  style?: {};
  styleProps?: {};
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
  type?: string;
  defaultValue?: string;
  shouldUnregister?: ControllerProps['shouldUnregister'];
  InputProps?: { [key: string]: boolean | any };
  inputProps?: {};
  onlyNumbers?: boolean;
  onBlurCallback?: (value: string, name: string) => void;
  multiline?: boolean;
  variant?: any;
  size?: any;
  fixedLabel?: boolean;
  placeholder?: string;
  startAdornmentText?: string;
  endAdornmentText?: string;
  rows?: number;
}> = ({
  id = '',
  control,
  name,
  helperText,
  label,
  onChangeCallback,
  sizes = { xs: 12 },
  style = { mb: 2 },
  styleProps = { width: '100%' },
  disabled = false,
  type,
  required,
  defaultValue = '',
  shouldUnregister,
  InputProps = {},
  inputProps,
  onlyNumbers = false,
  onBlurCallback,
  multiline = false,
  variant = 'standard',
  size,
  fixedLabel = false,
  placeholder,
  startAdornmentText = '',
  endAdornmentText = '',
  rows,
}) => {
  return (
    <Grid xs={sizes.xs} item sx={style}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextInput
            multiline={multiline}
            id={id}
            fullWidth
            error={!!error?.message}
            helperText={error ? error.message : helperText}
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              onBlurCallback && onBlurCallback(event.target.value, name);
            }}
            autoComplete="off"
            onChange={(e) => {
              if (onlyNumbers && isNaN(+e.target.value)) {
                return;
              }
              onChangeCallback && onChangeCallback(e.target.value, name);
              onChange(e);
            }}
            type={type}
            className="materialUIInput"
            variant={variant}
            size={size}
            style={{ width: '100%' }}
            label={
              fixedLabel ? (
                <InputLabel>
                  {required ? (
                    <>
                      {label}{' '}
                      <Typography
                        component="span"
                        sx={(theme) => ({
                          color: `${theme.palette.error.main} !important`,
                        })}
                      >
                        *
                      </Typography>
                    </>
                  ) : (
                    label
                  )}
                </InputLabel>
              ) : (
                <>
                  {label}{' '}
                  {required && (
                    <Typography
                      component="span"
                      sx={(theme) => ({
                        color: `${theme.palette.error.main} !important`,
                      })}
                    >
                      *
                    </Typography>
                  )}
                </>
              )
            }
            value={value ?? ''}
            disabled={disabled}
            inputProps={inputProps}
            styleProps={styleProps}
            InputProps={
              startAdornmentText || endAdornmentText
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={(theme) => ({
                            color: error?.message
                              ? `${theme.palette.error.main} !important`
                              : '',
                          })}
                        >
                          {startAdornmentText}
                        </Box>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={(theme) => ({
                            color: error?.message
                              ? `${theme.palette.error.main} !important`
                              : '',
                          })}
                        >
                          {endAdornmentText}
                        </Box>
                      </InputAdornment>
                    ),
                  }
                : InputProps
            }
            placeholder={placeholder}
            InputLabelProps={{
              fixedLabel,
            }}
            rows={rows}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
        shouldUnregister={shouldUnregister}
      />
    </Grid>
  );
};

export default React.memo(TextField);
