import { getOrganizationId } from '../../../common/TimeoffDialog/utils';
import { QueryParams, SortResponse } from '../commonMixed';
import {IDispatchDriverTimelineTrip} from "@/models/DTOs/Dispatch/Timeline";

export class TeamDriver {
  actions: Array<string>;
  activeForOperation: boolean;
  assetAssignment: assetAssignment;
  dispatcherId: number;
  displayName: string;
  drivers: Array<TeamDriversList>;
  employmentType: string;
  groupMode: string;
  groupName: string;
  id: string;
  operationType: string;
  organizationId: number;
  assignImplicitTractor: boolean;
  assignImplicitTrailer: boolean;
  constructor(dto: any) {
    this.actions = dto.actions;
    this.activeForOperation = dto.activeForOperation;
    this.assetAssignment = dto.assetAssignment;
    this.dispatcherId = dto.dispatcherId;
    this.displayName = dto.displayName;
    this.drivers = dto.drivers;
    this.groupMode = dto.groupMode;
    this.groupName = dto.groupName;
    this.id = dto.id;
    this.operationType = dto.operationType;
    this.organizationId = dto.organizationId;
    this.employmentType = dto.employmentType;
    this.assignImplicitTractor = dto.assignImplicitTractor;
    this.assignImplicitTrailer = dto.assignImplicitTrailer;
  }
}

export interface DriverTeamWithTrips {
  id: string;
  displayName: string;
  drivers: TeamDriversList[];
  assetAssignment: assetAssignment;
  groupName: string;
  driverGroupId: string;
  driverGroupName: string;
  dispatcherId: string;
  dispatcherName: string;
  organizationId: number;
  groupMode: number;
  currentTrip: IDispatchDriverTimelineTrip[];
  assignedTrips: IDispatchDriverTimelineTrip[];
  futureTrips: IDispatchDriverTimelineTrip[];
}

export class PaginatedDriverTeamListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  organizationId!: number;
  sort!: string;
  terminalIds?: Array<string>;
  groupMode!: string;
  activeForOperation!: boolean;
  driverGroupIds?: Array<string>;
  nameFilterList?: Array<string>;
  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.sort = dto.sort;
      this.terminalIds = dto.terminalIds;
      this.organizationId = getOrganizationId();
      this.groupMode = dto.groupMode;
      this.activeForOperation = dto.activeForOperation;
      this.driverGroupIds = dto.driverGroupIds;
      this.nameFilterList = dto.nameFilterList;
    }
  }
}

export class PaginatedAllTeamDriverListRequest extends PaginatedDriverTeamListRequest {}

export class FetchDriverGroupDataWithTripsRequest extends QueryParams {
  groupId!: string;
}

export class TeamDriverPaginatedRespones {
  content?: any;
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  order!: SortResponse[];
  error!: any;
}
export class TeamDriverPaginatedResponesData {
  data?: TeamDriverPaginatedRespones;
}
export interface assetAssignment {
  tractorId: number;
  tractorModel: string;
  tractorName: string;
  trailerId: number;
  trailerModel: string;
  trailerName: string;
}
export interface TeamDriversList {
  email: string;
  id: number;
  mobileno: string;
  name: string;
  playerId: number;
  shareTractorExpenses: boolean;
  soloGroupId: number;
  terminalId: number;
  timezone: number;
}

export class TeamDriverPaginatedRequest {
  driverIds?: Array<string>;
  tractorId?: number;
  id?: string;
  trailerId?: number;
  driverGroupId?: string;
  activeForOperation?: boolean;
  groupMode?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
  terminalIds?: number;
  organizationId!: number;
  scenarioType?: string;
  warningTypes?: any;
}

export class ImplicitAssignUpdateRequest {
  assignImplicitTractor?: boolean;
  assignImplicitTrailer?: boolean;
  driverGroupId?: string;
}

export class TeamDriverType {
  driverIds?: Array<string>;
  tractorId?: number;
  id?: number;
  trailerId?: number;
  driverGroupId?: number;
  activeForOperation?: boolean;
  groupMode?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
  terminalIds?: number;
  organizationId!: number;
  scenarioType?: string;
  warningTypes?: any;
}

export class TeamDriverFilterObject {
  name: string;
  driverAxeleId: number;
  driverEldId: number;
  driverId: number;
  driverName: string;
  eldProviderId: number;
  eldProviderName: string;
  seqNumber: string;
  terminalId: number;
  constructor(dto: any) {
    this.name = dto.name;
    this.driverAxeleId = dto.driverAxeleId;
    this.driverEldId = dto.driverEldId;
    this.driverName = dto.driverName;
    this.driverId = dto.driverId;
    this.eldProviderId = dto.eldProviderId;
    this.eldProviderName = dto.eldProviderName;
    this.seqNumber = dto.seqNumber;
    this.terminalId = dto.terminalId;
  }
}

export class GetDriverTeamRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  organizationId!: number;
  driverFilter!: boolean;
  excludeStatuses!: number;
  terminalIds!: Array<string>;
  nameFilter!: string;
  axeleIdFilter!: string;
  statusList!: string;
  name!: string;
  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.driverFilter = dto.driverFilter;
      this.terminalIds = dto.terminalIds;
      this.excludeStatuses = dto.excludeStatuses;
      this.nameFilter = dto.nameFilter;
      this.organizationId = getOrganizationId();
    }
  }
}
