import { number } from 'yup';

const percentageValidation = number().positive().min(0).max(100).required();
const numberValidation = number().positive().integer().required();
const decimalValidation = number().min(0).required();

export const allowOnlyNumbers = (text: string) => {
  if (text?.includes?.('.')) return false;
  if (!text || numberValidation.isValidSync(text)) return true;
  return false;
};

export const allowDecimalNumbers = (text: string) => {
  if (!text || decimalValidation.isValidSync(text)) return true;
  return false;
};

export const allowPercentageOnly = (text: string) => {
  if (!text || percentageValidation.isValidSync(text)) return true;
  return false;
};
