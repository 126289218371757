import { lazy } from 'react';
const lsLazyWithRetry = 'lazyWithRetry';
export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(lsLazyWithRetry) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(lsLazyWithRetry, 'false');

      return component;
    } catch (error) {
      if (
        error?.name === 'ChunkLoadError' &&
        !pageHasAlreadyBeenForceRefreshed
      ) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(lsLazyWithRetry, 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
