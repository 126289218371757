import { ComposedError, httpClient } from '../../../axios/axiosInstance';
import { IMaintenanceAssetTractor } from '../../../models/DTOs/maintenance/maintenanceAssetTractor/Model';
import { GetMaintenanceAssetTractorOneParams } from '../../../models/DTOs/maintenance/maintenanceAssetTractor/Requests';
import {
  annotateNameAsync,
  IMaintenanceAssetTractorService,
  ServiceError,
} from '../../interfaces';
const basePathApi = '/web/asset/api/v2/assets/tractor';
const resources = {
  one: `${basePathApi}/one`,
};

export class MaintenanceAssetTractorService extends IMaintenanceAssetTractorService {
  @annotateNameAsync
  async getMaintenanceAssetTractorOne(
    requestData: GetMaintenanceAssetTractorOneParams
  ): Promise<IMaintenanceAssetTractor | ServiceError> {
    try {
      const response = await httpClient.getRaw(resources.one, requestData);
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
