import { Box } from '@mui/material';
import { ReactNode } from 'react';
import * as yup from 'yup';
import { CurrentSelectedItem, CurrentSelectedType } from '../../../models';
import { TerminalShort } from '../../../models/common/modelsShort';
import {
  CARRIER_DOCUMENT_TYPE,
  CARRIER_INSURANCE_DOCUMENT_TYPE,
} from '../../../subPages/carriers/constants/constants';
import { getUsersNamesList } from '../../../subPages/expenses/utils/utils';
import { safetyAlertValidationSchema } from '../../../subPages/users/components/SafetyAlerts/constants';
import { GridColDefSelf, LineItem, ViewMetaData } from '../../../types';
import {
  DriverIcon,
  EquipmentIcon,
  LoadsIcon as LoadIcon,
  LockerCloseIcon,
  LockerOpenIcon,
  TractorsIcon as TractorIcon,
  TrailersIcon as TrailerIcon,
} from '../../../ui-kit/components/Assets';
import { fromIsoToMoment, nullableStringYup } from '../../../utils';
import { getGridCompanyTimezoneSettings } from '../../../utils/Timezone.utils';
import DocumentActions from './DocumentActions';
import { LoadConstants } from '../../../locales/en/allLoads/loads/index';
export const icons: { [key: string]: ReactNode } = {
  DriverIcon,
  TrailerIcon,
  EquipmentIcon,
  LoadIcon,
  TractorIcon,
};

export const folderIconsMap: { [key: string]: ReactNode } = {
  DRIVER: DriverIcon,
  EQUIPMENT: EquipmentIcon,
  LOAD: LoadIcon,
  TRACTOR: TractorIcon,
  TRAILER: TrailerIcon,
};

import { EquipmentConstants } from '../../../locales/en/operations/equipment/index';
import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';

export const documentTypeColumns: GridColDefSelf[] = [
  {
    flex: 1,
    field: 'fileName',
    headerName: 'Name',
    sortable: false,
    renderCell: (params) => {
      return (
        <Box sx={{ display: 'flex' }}>
          {params.row.permission === 'PRIVATE' ? (
            <LockerCloseIcon />
          ) : (
            <LockerOpenIcon />
          )}
          <span style={{ paddingLeft: 8 }}>{params.row.fileName}</span>
        </Box>
      );
    },
  },
  {
    flex: 1,
    field: 'uploadDate',
    headerName: 'Upload Date',
    sortable: false,
    renderCell: (params) => {
      const date = fromIsoToMoment(params.row.uploadDate);
      if (date) {
        return (
          <span style={{ paddingLeft: 8 }}>{date.format('M/D/YYYY')}</span>
        );
      }
      return '';
    },
  },
  {
    flex: 1,
    field: 'companyName',
    headerName: 'Terminal',
    sortable: false,
  },
  {
    flex: 1,
    field: 'ownerName',
    headerName: 'Uploaded By',
    sortable: false,
  },
];

export const panelColumns: GridColDefSelf[] = [
  {
    flex: 1,
    field: 'documentTypeName',
    headerName: LoadConstants.loadsDocumentsDocType,
    sortable: false,
    minWidth: 150,
    renderCell: (params) => {
      const { documentTypeId } = params.row;
      return (
        DocumentActions.documentsLineItemsMap[documentTypeId]?.itemName || ''
      );
    },
  },
  {
    flex: 1,
    field: 'fileName',
    headerName: LoadConstants.loadsDocumentsDocName,
    sortable: false,
    minWidth: 100,
    renderCell: (params) => {
      return (
        <Box sx={{ display: 'flex' }}>
          {params.row.permission === 'PRIVATE' ? (
            <LockerCloseIcon />
          ) : (
            <LockerOpenIcon />
          )}
          <span style={{ paddingLeft: 8 }}>{params.row.fileName}</span>
        </Box>
      );
    },
  },
  {
    flex: 1,
    field: 'uploadDate',
    headerName: LoadConstants.loadsDocumentsDocUploadDate,
    sortable: false,
    minWidth: 100,
    renderCell: (params) => {
      const date = fromIsoToMoment(params.row.uploadDate);
      if (date) {
        return (
          <span style={{ paddingLeft: 8 }}>{date.format('M/D/YYYY')}</span>
        );
      }
      return '';
    },
  },
];

export const entityTypeColumns: GridColDefSelf[] = [
  {
    flex: 1,
    field: 'fileName',
    headerName: 'Name',
    sortable: false,
    renderCell: (params) => {
      return (
        <Box sx={{ display: 'flex' }}>
          {params.row.permission === 'PRIVATE' ? (
            <LockerCloseIcon />
          ) : (
            <LockerOpenIcon />
          )}
          <span style={{ paddingLeft: 8 }}>{params.row.fileName}</span>
        </Box>
      );
    },
  },
  {
    flex: 1,
    field: 'uploadDate',
    headerName: 'Upload Date',
    sortable: false,
    renderCell: (params) => {
      const date = fromIsoToMoment(params.row.uploadDate);
      if (date) {
        return (
          <span style={{ paddingLeft: 8 }}>{date.format('M/D/YYYY')}</span>
        );
      }
      return '';
    },
  },
  {
    flex: 1,
    field: 'companyName',
    headerName: 'Terminal',
    sortable: false,
  },
  {
    flex: 1,
    field: 'ownerName',
    headerName: 'Uploaded By',
    sortable: false,
  },
  {
    flex: 1,
    field: 'attachedEntities',
    headerName: 'Associated With',
    sortable: true,
    renderCell: (params) => {
      const { attachedEntities } = params.row;
      const name = attachedEntities[0]?.properties[0]
        ? attachedEntities[0].properties[0].name
        : '';
      return <span style={{ paddingLeft: 8 }}>{name}</span>;
    },
  },
];

export const TABLE_COLUMNS_MAP: { [key: string]: GridColDefSelf[] } = {
  [CurrentSelectedType.ENTITY]: entityTypeColumns,
  [CurrentSelectedType.FOLDER]: documentTypeColumns,
  [CurrentSelectedType.MID_ITEM]: documentTypeColumns,
  [CurrentSelectedType.PANEL]: panelColumns,
};

const getEntityTypeColumns = (
  terminals: TerminalShort[],
  documentLineItemsMap: { [key: string]: LineItem }
) => {
  const columns = [
    {
      flex: 1,
      field: 'fileName',
      headerName: 'Name',
      sortable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            {params.row.permission === 'PRIVATE' ? (
              <LockerCloseIcon />
            ) : (
              <LockerOpenIcon />
            )}
            <span style={{ paddingLeft: 8 }}>{params.row.fileName}</span>
          </Box>
        );
      },
    },
    {
      flex: 1,
      field: 'uploadDate',
      headerName: 'Upload Date',
      sortable: false,
      renderCell: (params) => {
        const date = fromIsoToMoment(params.row.uploadDate);
        if (date) {
          return (
            <span style={{ paddingLeft: 8 }}>{date.format('M/D/YYYY')}</span>
          );
        }
        return '';
      },
    },
    {
      flex: 1,
      field: 'terminal',
      headerName: 'Terminal',
      sortable: false,
      renderCell: (params) => {
        return (
          terminals.find((terminal) => terminal.id === params.row.terminalId)
            ?.companyName || ''
        );
      },
    },
    {
      flex: 1,
      field: 'ownerName',
      headerName: 'Uploaded By',
      sortable: false,
    },
    {
      flex: 1,
      field: 'type',
      headerName: 'Associated Type',
      sortable: true,
      renderCell: (params) => {
        const { attachedEntities } = params.row;
        const type = attachedEntities?.[0]?.type || '';
        return (
          <span style={{ paddingLeft: 8 }}>
            {entityKeyValueMap[type] || type}
          </span>
        );
      },
    },
    {
      flex: 1,
      field: 'attachedEntities',
      headerName: 'Associated Name',
      sortable: true,
      renderCell: (params) => {
        const { attachedEntities } = params.row;
        const title = attachedEntities[0]?.properties[0]
          ? attachedEntities[0].properties[0].title
          : '';
        return <span style={{ paddingLeft: 8 }}>{title}</span>;
      },
    },
    {
      flex: 1,
      field: 'documentTypeId',
      headerName: 'Document Type',
      sortable: false,
      renderCell: (params) =>
        documentLineItemsMap[params.value]?.itemName || '',
    },
  ];
  return [...columns];
};

const getDocumentTypeColumns = (terminals: TerminalShort[]) => {
  return [
    {
      flex: 1,
      field: 'fileName',
      headerName: 'Name',
      sortable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            {params.row.permission === 'PRIVATE' ? (
              <LockerCloseIcon />
            ) : (
              <LockerOpenIcon />
            )}
            <span style={{ paddingLeft: 8 }}>{params.row.fileName}</span>
          </Box>
        );
      },
    },
    {
      flex: 1,
      field: 'uploadDate',
      headerName: 'Upload Date',
      sortable: false,
      renderCell: (params) => {
        const date = fromIsoToMoment(params.row.uploadDate);
        if (date) {
          return (
            <span style={{ paddingLeft: 8 }}>{date.format('M/D/YYYY')}</span>
          );
        }
        return '';
      },
    },
    {
      flex: 1,
      field: 'terminal',
      headerName: 'Terminal',
      sortable: false,
      renderCell: (params) => {
        return (
          terminals.find((terminal) => terminal.id === params.row.terminalId)
            ?.companyName || ''
        );
      },
    },
    {
      flex: 1,
      field: 'ownerName',
      headerName: 'Uploaded By',
      sortable: false,
    },
  ];
};

const getDocumentMidTypeColumns = (
  terminals: TerminalShort[],
  documentLineItemsMap: { [key: string]: LineItem }
) => {
  return [
    {
      flex: 1,
      field: 'fileName',
      headerName: 'Name',
      sortable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            {params.row.permission === 'PRIVATE' ? (
              <LockerCloseIcon />
            ) : (
              <LockerOpenIcon />
            )}
            <span style={{ paddingLeft: 8 }}>{params.row.fileName}</span>
          </Box>
        );
      },
    },
    {
      flex: 1,
      field: 'uploadDate',
      headerName: 'Upload Date',
      sortable: false,
      renderCell: (params) => {
        const date = fromIsoToMoment(params.row.uploadDate);
        if (date) {
          return (
            <span style={{ paddingLeft: 8 }}>{date.format('M/D/YYYY')}</span>
          );
        }
        return '';
      },
    },
    {
      flex: 1,
      field: 'terminal',
      headerName: 'Terminal',
      sortable: false,
      renderCell: (params) => {
        return (
          terminals.find((terminal) => terminal.id === params.row.terminalId)
            ?.companyName || ''
        );
      },
    },
    {
      flex: 1,
      field: 'ownerName',
      headerName: 'Uploaded By',
      sortable: false,
    },
    {
      flex: 1,
      field: 'documentTypeId',
      headerName: 'Document Type',
      sortable: false,
      renderCell: (params) =>
        documentLineItemsMap[params.value]?.itemName || '',
    },
  ];
};

export const getColumns = (
  terminals: TerminalShort[],
  type: string,
  documentLineItemsMap: { [key: string]: LineItem }
) => {
  const TABLE_COLUMNS_MAP: { [key: string]: GridColDefSelf[] } = {
    [CurrentSelectedType.ENTITY]: getEntityTypeColumns(
      terminals,
      documentLineItemsMap
    ),
    [CurrentSelectedType.FOLDER]: getDocumentTypeColumns(terminals),
    [CurrentSelectedType.MID_ITEM]: getDocumentMidTypeColumns(
      terminals,
      documentLineItemsMap
    ),
    [CurrentSelectedType.PANEL]: panelColumns,
  };
  return TABLE_COLUMNS_MAP[type];
};

const getFolderTypeFilterFormConfig = (documentLineItems: LineItem[]) => {
  return [
    {
      key: 1,
      name: 'documentTypeIds',
      fieldName: 'itemName',
      options: documentLineItems.map((item) => ({
        itemName: item.itemName,
        id: item.id,
      })),
      label: 'Document Type',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: 'ownerIds',
      fieldName: 'name',
      getData: getUsersNamesList,
      label: 'Uploaded By',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 3,
      name: 'range',
      fieldName: 'name',
      label: 'Upload Range',
      default: true,
      ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
    },
    {
      key: 4,
      name: 'permissionType',
      fieldName: 'value',
      fieldValue: 'name',
      options: [
        { name: true, value: 'PUBLIC' },
        { name: false, value: 'PRIVATE' },
      ],
      label: 'Shared With Driver',
      default: false,
      type: 'GridSwitchFilter',
    },
  ];
};

const getEntityTypeFilterFormConfig = (
  terminals: TerminalShort[],
  entity: { key: string } | undefined
) => {
  const terminalIds = terminals.map((t: TerminalShort) => t.id);
  return [
    {
      key: 1,
      name: 'entityTypes',
      fieldName: 'value',
      fieldValue: 'key',
      options: filterEntities,
      label: 'Associated Type',
      default: true,
      type: 'ButtonGroup',
      multiple: false,
    },
    {
      key: 2,
      name: 'entities',
      fieldName: 'title',
      label: 'Associated Name',
      default: true,
      type: 'MultipleAutocomplete',
      getData: (name: string, pageNumber: number) =>
        DocumentActions.getAssociatedWith(
          pageNumber,
          name,
          entity?.key || '',
          terminalIds
        ),
    },
    {
      key: 3,
      name: 'range',
      fieldName: 'name',
      label: 'Upload Range',
      default: true,
      ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
    },
    {
      key: 4,
      name: 'ownerIds',
      fieldName: 'name',
      getData: getUsersNamesList,
      label: 'Uploaded By',
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 5,
      name: 'permissionType',
      fieldName: 'value',
      fieldValue: 'name',
      options: [
        { name: true, value: 'PUBLIC' },
        { name: false, value: 'PRIVATE' },
      ],
      label: 'Shared With Driver',
      default: false,
      type: 'GridSwitchFilter',
    },
  ];
};

export const getFilterColumns = (
  currentSelectedItem: CurrentSelectedItem,
  documentLineItems: LineItem[],
  terminals: TerminalShort[],
  entity: { key: string } | undefined
) => {
  const map: { [key: string]: ViewMetaData[] } = {
    [CurrentSelectedType.ENTITY]: getEntityTypeFilterFormConfig(
      terminals,
      entity
    ),
    [CurrentSelectedType.FOLDER]:
      getFolderTypeFilterFormConfig(documentLineItems),
    [CurrentSelectedType.MID_ITEM]:
      getFolderTypeFilterFormConfig(documentLineItems),
  };
  return map[currentSelectedItem.type];
};

export const defaultFilters = {
  documentTypeIds: null,
  objectTypesAndIds: null,
  entityTypes: null,
  entities: null,
  ownerIds: null,
  permissionType: null,
  range: null,
};

export const defAttachedEntities = [
  { properties: [{ id: '', title: '' }], type: '' },
];

export const entities = [
  { key: 'LOAD', value: 'Load' },
  { key: 'DRIVER', value: 'Driver' },
  { key: 'TRACTOR', value: 'Tractor' },
  { key: 'TRAILER', value: 'Trailer' },
  { key: 'EXPENSE', value: 'Expense' },
  { key: 'SETTLEMENT', value: 'Settlement' },
  { key: 'MAINTENANCE_HISTORY', value: 'Maintenance' },
  { key: CARRIER_DOCUMENT_TYPE, value: 'Carrier' },
  { key: CARRIER_INSURANCE_DOCUMENT_TYPE, value: 'Carrier Insurance' },
];

export const filterEntities = [
  { key: 'LOAD', value: 'Load' },
  { key: 'DRIVER', value: 'Driver' },
  { key: 'TRACTOR', value: 'Tractor' },
  { key: 'TRAILER', value: 'Trailer' },
  { key: CARRIER_DOCUMENT_TYPE, value: 'Carrier' },
];

export const entityKeyValueMap: { [key: string]: string } = {
  LOAD: 'Load',
  DRIVER: 'Driver',
  TRACTOR: 'Tractor',
  TRAILER: 'Trailer',
  EXPENSE: 'Expense',
  SETTLEMENT: 'Settlement',
  PAYMENT: 'Payment',
  MAINTENANCE_HISTORY: 'Maintenance',
  [CARRIER_DOCUMENT_TYPE]: 'Carrier',
  [CARRIER_INSURANCE_DOCUMENT_TYPE]: 'Carrier Insurance',
};

export const disabledEntityKeys = [
  'EXPENSE',
  'SETTLEMENT',
  'MAINTENANCE_HISTORY',
  CARRIER_INSURANCE_DOCUMENT_TYPE,
];

export const addEditEntities = [
  { key: 'LOAD', value: 'Load' },
  { key: 'DRIVER', value: 'Driver' },
  { key: 'TRACTOR', value: 'Tractor' },
  { key: 'TRAILER', value: 'Trailer' },
  { key: CARRIER_DOCUMENT_TYPE, value: 'Carrier' },
];

export const driverLicenseEndorsements = [
  { key: 'h_endorsement', value: 'Hazmat certification' },
  { key: 'n_endorsement', value: 'Tanker certification' },
  { key: 't_endorsement', value: 'Double or Triple trailer certification' },
  { key: 'x_endorsement', value: 'Hazardous tanker' },
];

export const driverLicenseRestrictions = [
  { key: 'e_restriction', value: EquipmentConstants.manualTransmission },
  { key: 'l_restriction', value: 'Air Break' },
  { key: 'z_restriction', value: 'Restriction - Z' },
  { key: 'm_restriction', value: 'Restriction - M' },
  { key: 'n_restriction', value: 'Restriction - N' },
  { key: 'o_restriction', value: 'Fifth Wheel Connection' },
  { key: 'v_restriction', value: 'Restriction - V' },
];

export const latestDocumentCertificationFormSchema = yup
  .object({
    documentType: yup.object().nullable().required('document type is required'),
    fileName: nullableStringYup().when(
      ['filesLength'],
      (filesLength, schema) => {
        if (filesLength === 1) {
          return nullableStringYup().required('file name is required');
        }
        return schema;
      }
    ),
    entityTypes: yup
      .object()
      .nullable()
      .required('Associated Type  is required'),
    entities: yup
      .object()
      .nullable()
      .when(['entityTypes'], (entityTypes, schema) => {
        return !entityTypes
          ? schema
          : schema.required('associated name is required');
      }),
    certification: yup
      .object()
      .when(['state', 'isLatest'], (state, isLatest, schema) => {
        if (state === 'certification' && isLatest) {
          return safetyAlertValidationSchema;
        }
        return schema;
      }),
    notes: nullableStringYup().max(255, 'Can not be more than 255 characters.'),
  })
  .required();

export const restrictionsMap = {
  e_restriction: EquipmentConstants.manualTransmission,
  l_restriction: 'Air Break',
  z_restriction: 'Restriction - Z',
  m_restriction: 'Restriction - M',
  o_restriction: 'Fifth Wheel Connection',
  v_restriction: 'Restriction - V',
  n_restriction: 'Restriction - N',
};

export const endorsementsMap = {
  h_endorsement: 'Hazmat certification',
  n_endorsement: 'Tanker certification',
  t_endorsement: 'Double or Triple trailer certification',
  x_endorsement: 'Hazardous tanker',
};
