import { Stack, Typography } from '@mui/material';
import { LoadOperationalChartProps } from './Chart/LoadOperationalChart';
import { LoadKPIChart } from './LoadKPIChart';
import { StyledLoadKPICard } from './styles/loadOperationalSummary.styles';

interface LoadKPICardProps {
  isAvailabilityKPI?: boolean;
  groupTitle?: string;
  chartList: LoadOperationalChartProps[];
  id?: string;
}
export const LoadKPICard = ({
  isAvailabilityKPI = false,
  groupTitle,
  chartList,
  id,
}: LoadKPICardProps): JSX.Element => {
  return (
    <StyledLoadKPICard {...(isAvailabilityKPI ? { availability: 'true' } : {})}>
      {groupTitle && (
        <Typography
          sx={{
            color: 'rgba(4, 0, 34, 0.75)',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '21px',
            textAlign: 'center',
          }}
        >
          {groupTitle}
        </Typography>
      )}

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        marginTop={'10px'}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {chartList &&
            chartList.map(
              (chartProp: LoadOperationalChartProps, idx: number) => (
                <LoadKPIChart
                  key={idx}
                  loadOperationalChart={chartProp}
                  chartTitle={chartProp.chartTitle}
                />
              )
            )}
        </Stack>
      </Stack>
    </StyledLoadKPICard>
  );
};
