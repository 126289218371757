import { Box } from '@mui/material';
import { StyleType } from './StatusComponent';

/**
 * Component takes label and icon(component) props. Icon will appear in the left side
 * */

const style = {
  wrap: {
    fontFamily: 'Poppins',
    width: 'min-content',
    height: '24px',
    display: 'flex',
    padding: '3px 10px 3px 6.5px',
    alignItems: 'center',
    background: 'rgba(0, 17, 34, 0.08)',
    borderRadius: '16px',
    fontWeight: 400,
  },
  label: {
    color: 'rgba(0, 17, 34, 0.75)',
    paddingLeft: '5px',
    whiteSpace: 'nowrap',
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: 400,
  },
};

export default function StatusBaseComponent({
  label,
  icon,
  customStyles = { wrap: {}, label: {} },
}: {
  label: string;
  icon?: JSX.Element;
  customStyles?: { wrap?: StyleType; label?: StyleType };
}) {
  return (
    <Box component="span" sx={{ ...style.wrap, ...customStyles.wrap }}>
      {icon}
      <Box component="span" sx={{ ...style.label, ...customStyles.label }}>
        {label}
      </Box>
    </Box>
  );
}
