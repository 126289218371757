export const dashboardContainerStyles =
  (width: number | string | any) => () => {
    if (width >= 2650) {
      return {
        width: '50%',
        display: 'block',
        ml: 'auto',
        mr: 'auto',
      };
    }
    return {
      width: '100%',
    };
  };
