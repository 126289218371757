import { SyntheticEvent } from 'react';
import {
  StyleFloatingTruckIcon,
  StyleFloatingTruckIconRoot,
} from '../../TripPlanV3/styles';
import { StopSolutionV3Prop } from '../../TripPlanV3/types';
import { TruckIconSection } from './TruckIconSection';

export interface FloatingTruckIconSectionSectionProps {
  stopSolution: StopSolutionV3Prop;
}
export const FloatingTruckIconSection = ({
  stopSolution,
}: FloatingTruckIconSectionSectionProps): JSX.Element => {
  return (
    <StyleFloatingTruckIconRoot>
      <StyleFloatingTruckIcon
        onClick={(event: SyntheticEvent<Element, Event>) => {
          event.stopPropagation();
        }}
      >
        <TruckIconSection stopSolution={stopSolution} />
      </StyleFloatingTruckIcon>
    </StyleFloatingTruckIconRoot>
  );
};
