import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useFilterStorage from '../../../services/storage';
import { View, ViewMap } from '../../../types';
import { CloseIcon, SaveIcon } from '../../../ui-kit/components/Assets';
import DeletePopup from '../../DeletePopup';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    '& .MuiDialog-paper': {
      height: 950,
      borderRadius: 14,
      background: '#35446B',
      width: '80%',
      transform: 'translate(50px, 30px)',
      maxWidth: 'none',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
      borderTop: '1.49777px solid #EEEEEE',
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
});

export const customViewFormValidator = yup
  .object({
    name: yup
      .string()
      .required('Name is required.')
      .max(20, 'Name must be at most 20 characters.'),
  })
  .required();

type PageProps = {
  handleClose: () => void;
  open: boolean;
  title: string;
  defaultEntity?: string;
  contentRenderer: () => JSX.Element;
  onSave: (data: View, cb: () => void) => void;
  formDefaultValuesMap?: ViewMap;
  defaultValues?: View;
  onViewDelete?: () => void;
  component: any;
};

const ViewDataModalV2 = ({
  handleClose,
  open,
  title,
  defaultEntity,
  contentRenderer,
  onSave,
  defaultValues,
  formDefaultValuesMap,
  onViewDelete,
  component,
}: PageProps): JSX.Element => {
  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const state = useForm({
    resolver: yupResolver(customViewFormValidator),
  });
  const { reset, watch, getValues } = state;
  const entity = watch('entity') || defaultEntity;

  useEffect(() => {
    if (formDefaultValuesMap) {
      const formDefaultValues = formDefaultValuesMap[entity];
      reset({
        ...getValues(),
        entity: formDefaultValues?.entity,
        metaData: formDefaultValues?.metaData,
        columnFilters: formDefaultValues?.columnFilters,
      });
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, formDefaultValuesMap, entity]);

  const { handleSubmit } = state;

  const updateOrCreateCallback = (data: View) => {
    handleClose();
    if (data.default) {
      const newFilters = data.metaData;
      LStorageHook.updateStorage(data.entity, {
        ...storageData[data.entity],
        filters: newFilters,
      });
    }
  };

  const onSubmit = (data: View) => {
    onSave(data, () => updateOrCreateCallback(data));
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <>
        <StyledDialog open={open}>
          <DialogTitle
            sx={{
              margin: 0,
              fontSize: 16,
              color: '#ffffff',
              padding: '10px 16px',
              fontFamily: 'Poppins',
            }}
          >
            <Grid container justifyContent={'space-between'}>
              <span>{title}</span>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>

          <DialogContent
            sx={{
              borderTopRightRadius: 14,
              borderTopLeftRadius: 14,
              backgroundColor: 'white',
            }}
          >
            <FormProvider {...state}>
              <form
                style={{ height: '100%' }}
                onSubmit={handleSubmit(onSubmit)}
              >
                {contentRenderer()}
              </form>
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ padding: 0, backgroundColor: 'white' }}>
            <Grid>
              {defaultValues && (
                <Button
                  style={{ marginLeft: 20 }}
                  onClick={() => setDeleteDialogOpen(true)}
                  color={'error'}
                >
                  Delete View
                </Button>
              )}
            </Grid>
            <Grid>
              <Button
                variant={'outlined'}
                onClick={handleClose}
                style={{
                  borderRadius: 10,
                  border: '1px solid rgba(43, 100, 203, 0.5)',
                }}
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                startIcon={<SaveIcon />}
                style={{
                  borderRadius: 10,
                  marginLeft: 8,
                  marginRight: 20,
                  fontFamily: 'Poppins',
                  fontSize: 14,
                  textTransform: 'capitalize',
                }}
              >
                {t('saveView')}
              </Button>
            </Grid>
          </DialogActions>
        </StyledDialog>
        {deleteDialogOpen && (
          <DeletePopup
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onAction={() => {
              setDeleteDialogOpen(false);
              onViewDelete && onViewDelete();
            }}
            title={'Delete View?'}
          />
        )}
      </>
    );
  }
};

export default ViewDataModalV2;
