import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Grid, Stack } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useEffect, useState } from 'react';
import { loadboardService } from '../../../../api';
import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission';
import { getHasPermission } from '../../../../common/Permission/utils/helperUtils';
import { DeactivateAccountCard } from '../../../../models/DTOs/Loadboard/Requests';
import {
  IAllCustomers,
  ITerminals,
  IcurrentCustomerIntegrationFormField,
  IloadboardProviderData,
} from '../../../../types/LoadboardTypes';
import CustomerFeatures from '../../../../ui-kit/components/CustomerFeatures';
import AccountsSection from '../../../../ui-kit/components/IntegrationAccountCards/AccountsSection';
import IntegrationActivationPanel from '../../../../ui-kit/components/IntegrationActivationPanel';
import ProviderDescription from '../../../../ui-kit/components/ProviderDescription';
import ProviderHero from '../../../../ui-kit/components/ProviderHero';
import SideDrawerFields from '../SideDrawer';

export default function SelectedCustomerLoadboard({
  currentCustomerData,
  loadboardProviderData,
  credentialSteps,
  getProviderDetails,
  allTerminals,
  selectCustomerFeatures,
  onRefresh,
  revalidateLoadboard,
}: IProps) {
  const [open, setOpen] = useState(false);
  const [features, setFeatures] = useState([]);
  const [isDeletePopupOpen, openDeletePopup] = useState(false);
  const [isEditTab, setIsEditTab] = useState(false);
  const [cardData, setCardData] = useState({});
  const [selectedCardForDeletion, setCardForDeletion] = useState<{
    id: number;
    integrationType: string;
    providerId: number;
    organizationId: number;
  }>();
  const swipeableDrawerStyle = {
    '.MuiPaper-root': {
      width: '462px',
      height: '90%',
      bottom: '5px',
      top: 'unset',
      padding: '25px 40px 10px 32px',
      borderRadius: '16px 0px 0px 16px',
    },
  };
  useEffect(() => {
    getCustomerFeatures();
  }, [currentCustomerData.providerId]);

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
    if (isEditTab) {
      setIsEditTab(false);
      setCardData({});
    }
  };

  const getCustomerFeatures = (): void => {
    setFeatures(selectCustomerFeatures);
  };

  const openConfirmationPopup = (accountCard: any) => {
    openDeletePopup(true);
    setCardForDeletion(accountCard);
  };
  const closeConfirmationPopup = () => {
    openDeletePopup(false);
  };

  const deactivateAccount = async () => {
    if (selectedCardForDeletion) {
      const data = {
        id: selectedCardForDeletion['id'],
        integrationType: 'LOADBOARD',
        providerId: selectedCardForDeletion['providerId'],
        organizationId: selectedCardForDeletion['organizationId'],
      };
      const request = new DeactivateAccountCard(data);
      try {
        const response: any = await loadboardService.deactivatedAccountCard(
          request
        );
        if (response) {
          closeConfirmationPopup();
          onRefresh();
        }
      } catch (err) {
        return;
      }
    }
  };

  const editAccount = (cardData: any) => {
    setIsEditTab(true);
    setCardData(cardData);
    setOpen(true);
  };

  const axelePermissions = {
    showAddBtn: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_LOADBOARD_ADD],
    }),
    showEdit: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_LOADBOARD_EDIT],
    }),
    showRemove: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_LOADBOARD_REMOVE],
    }),
  };
  return (
    <Box className="selected-customer-block">
      <ProviderHero currentCustomerData={currentCustomerData} />
      <Stack sx={{ p: '50px 20px 0 30px' }}>
        <Box>
          <Box>
            <Grid container>
              <ProviderDescription currentCustomerData={currentCustomerData} />
              <Grid item xs={2}>
                <IntegrationActivationPanel
                  status={
                    currentCustomerData?.providerId === 1
                      ? ''
                      : currentCustomerData.status
                  }
                  toggleDrawer={() => toggleDrawer(true)}
                  axelePermissions={axelePermissions}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        {currentCustomerData.status != 'UNAVAILABLE' && (
          <Stack sx={{ pt: '48px' }}>
            <AccountsSection
              openDrawer={() => toggleDrawer(true)}
              currentCustomerData={currentCustomerData}
              providerData={loadboardProviderData}
              allTerminals={allTerminals}
              getProviderDetails={getProviderDetails}
              isDeletePopupOpen={isDeletePopupOpen}
              closeConfirmationPopup={closeConfirmationPopup}
              deactivateAccount={deactivateAccount}
              openConfirmationPopup={openConfirmationPopup}
              editAccount={editAccount}
              integrationType={'LOADBOARD'}
              selectedCardForDeletion={cardData}
              axelePermissions={axelePermissions}
              reauthenticate={revalidateLoadboard}
              isArchivedSection={
                currentCustomerData?.providerName === 'DAT LEGACY'
                  ? true
                  : false
              }
            />
          </Stack>
        )}
        <Stack sx={{ pt: '48px' }}>
          <CustomerFeatures features={features} />
        </Stack>
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          disableSwipeToOpen={false}
          sx={swipeableDrawerStyle}
        >
          <Box className="drawer-content">
            <Box
              onClick={() => toggleDrawer(false)}
              sx={{ float: 'right', cursor: 'pointer' }}
            >
              <CloseOutlinedIcon />
            </Box>
            <Box className="provider-logo" sx={{ pt: '25px', pb: '33px' }}>
              {currentCustomerData && currentCustomerData.logo}
            </Box>
            {open && (
              <SideDrawerFields
                currentCustomerIntegrationFormField={credentialSteps}
                refreshOnCard={onRefresh}
                isDAT={
                  [1, 7].includes(currentCustomerData['providerId'])
                    ? true
                    : false
                }
                currentCustomerData={currentCustomerData}
                isEditTab={isEditTab}
                cardData={cardData}
                allTerminals={allTerminals}
                toggleDrawer={toggleDrawer}
              />
            )}
          </Box>
        </SwipeableDrawer>
      </Stack>
    </Box>
  );
}

interface IProps {
  currentCustomerData: Array<IAllCustomers>;
  loadboardProviderData: Array<IloadboardProviderData>;
  credentialSteps: Array<IcurrentCustomerIntegrationFormField>;
  getProviderDetails: any;
  allTerminals: Array<ITerminals> | null;
  selectCustomerFeatures: any;
  onRefresh: () => void;
  revalidateLoadboard: (payload: {
    data: [];
    organizationId: number;
    providerId: number;
    terminalIds: string[];
  }) => void;
}
