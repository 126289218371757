import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import * as MUIcon from '@mui/icons-material';
import Select from '../Select';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import './pickerIcon.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { t } from 'i18next';
export interface PickIconInputProps {
  icon?: any;
  onChange?: Function;
  width?: any;
  error?: boolean | undefined;
  helperText?: any;
  modalHeight?: number;
}

const PickIconInput: FunctionComponent<PickIconInputProps> = ({
  icon = '',
  onChange = () => {},
  width = 400,
  helperText,
  error,
  modalHeight = 400,
}: PickIconInputProps) => {
  const [options, setOptions] = useState<(object | null)[]>([]);
  const [range, setRange] = useState(200);
  const [iconName, setIconName] = useState(icon);
  const iconQuantity = 50;
  const scrollHeightDifference = 10;
  const component: any = MUIcon;
  const [widthSize, setWidthSize] = useState<number>();

  const list = useMemo(
    () =>
      Object.keys(MUIcon).reduce((iconArray: object[], key: string) => {
        if (key.includes('Outlined')) {
          iconArray.push({
            label: <SvgIcon component={component[key]} inheritViewBox />,
            value: key,
          });
        }
        return iconArray;
      }, []),
    []
  );

  const handleModalSize = (size: number) => {
    setWidthSize(size);
  };

  useEffect(() => {
    setOptions(list.slice(0, range));
  }, [range]);

  function showDeleteIcon() {
    return (
      <IconButton
        size="small"
        onClick={() => {
          setIconName('');
          onChange('');
        }}
      >
        <ClearIcon fontSize="small" />
      </IconButton>
    );
  }

  function handleScroll(event: React.UIEvent<HTMLElement>) {
    if (
      event.currentTarget.scrollTop + modalHeight + scrollHeightDifference >=
      event.currentTarget.scrollHeight
    ) {
      setRange((range) => range + iconQuantity);
    }
  }

  function handleIconChange(value: string) {
    setIconName(value);
    onChange(value);
  }

  return (
    <Select
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          style: {
            height: modalHeight,
            width: widthSize,
          },
          onScroll: (event: React.UIEvent<HTMLElement>) => handleScroll(event),
        },
      }}
      onOpen={() => setRange(200)}
      options={options as object[]}
      value={iconName}
      label={t('modalPickIcon')}
      styleProps={{ width }}
      fullWidth
      helperText={helperText}
      onChange={(value: string) => handleIconChange(value)}
      IconComponent={iconName ? showDeleteIcon : ArrowDropDownIcon}
      error={error}
      handleModalSize={handleModalSize}
      inputLabelId="pick-icon-input"
      selectLabelId="pick-icon-label"
      selectId="pick-icon"
    />
  );
};

export default PickIconInput;
