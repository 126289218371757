import {
  createTheme,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_LAPTOP = 1440;
export const BREAKPOINT_DESKTOP = 1680;

export const useThemeResponsive = (): {
  theme: Theme;
  themeLogin: Theme;
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
} => {
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(
    theme.breakpoints.down(BREAKPOINT_TABLET),
    {
      noSsr: true,
    }
  );

  const isIpadAir: boolean = useMediaQuery(
    theme.breakpoints.between(768, 960),
    {
      noSsr: true,
    }
  );
  const isTablet: boolean =
    useMediaQuery(
      theme.breakpoints.between(BREAKPOINT_TABLET, BREAKPOINT_LAPTOP),
      {
        noSsr: true,
      }
    ) || isIpadAir;

  const isLaptop: boolean = useMediaQuery(
    theme.breakpoints.between(BREAKPOINT_LAPTOP, BREAKPOINT_DESKTOP),
    {
      noSsr: true,
    }
  );

  const isDesktop = useMediaQuery(theme.breakpoints.up(BREAKPOINT_DESKTOP), {
    noSsr: true,
  });

  const loginTextInputDisableSxProps: SxProps = {
    borderColor: `${theme.palette?.tabStrip?.actionIcon} !important`,
    color: `${theme.palette?.tabStrip?.actionIcon} !important`,
    '-webkit-text-fill-color': `${theme.palette?.tabStrip?.actionIcon} !important`,
    fontStyle: 'italic !important',
    opacity: '0.3 !important',
  };
  const themeLogin = createTheme(theme, {
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              ...loginTextInputDisableSxProps,
              paddingRight: '8px',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              ...loginTextInputDisableSxProps,
              backgroundColor: 'rgba(0, 17, 34, 0.42)',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette?.tabStrip?.actionIcon,
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .Mui-disabled': {
              ...loginTextInputDisableSxProps,
              backgroundColor: 'rgba(0, 17, 34, 0.42)',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette?.tabStrip?.actionIcon,
              },
            },
          },
          notchedOutline: {
            borderColor: theme.palette?.tabStrip?.actionIcon,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popupIndicator: {
            color: theme.palette?.tabStrip?.actionIcon,
          },
          option: {
            color: '#1F2E3D',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#669EFF',
        dark: theme.palette?.primary?.dark,
        contrast: theme.palette?.tabStrip?.sidePanelTabText,
      },
      text: {
        primary: theme.palette?.tabStrip?.sidePanelTabIcon,
        secondary: theme.palette?.tabStrip?.actionIcon,
      },
    },
    typography: {
      h6: {
        ...theme.typography.h6,
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
      },
      h5: {
        ...theme.typography.h5,
      },
      inputText: {
        ...theme.typography.inputText,
        fontSize: '16px',
        fontWeight: '400',
      },
    },
  });

  return {
    theme,
    themeLogin,
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
  };
};
