import React, { useEffect, useMemo, useState } from 'react';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import TabsV2 from '../../../../ui-kit/components/TabsV2/TabsV2';
import { ECarrierPanelTab, ICarrierPanelTab } from '../../constants/types';
const getTabItems = (): ICarrierPanelTab[] => {
  return [
    {
      key: ECarrierPanelTab.OVERVIEW,
      label: 'Overview',
      iconName: 'SpeakerNotes',
      source: 'MUIcons',
    },
    // {
    //   key: ECarrierPanelTab.CONTRACT,
    //   label: 'Contract',
    //   iconName: 'ManageAccountsOutlined',
    //   source: 'MUIcons',
    // },
    {
      key: ECarrierPanelTab.DOCUMENT,
      label: 'Document',
      iconName: 'SnippetFolderOutlined',
      source: 'MUIcons',
    },
  ];
};

const DetailsTabStrips: React.FC<{
  defaultTab?: ECarrierPanelTab;
  onChange: (selected: ECarrierPanelTab) => void;
  keys: ECarrierPanelTab[];
}> = ({ onChange, defaultTab, keys }) => {
  const tabItems = useMemo(() => {
    return getTabItems().filter((item: ICarrierPanelTab) =>
      keys.includes(item.key)
    );
  }, [keys]);

  const getTab = (tabKey?: ECarrierPanelTab) =>
    tabItems.find(({ key }) => key === tabKey) || tabItems[0];

  const [selected, setSelected] = useState(getTab(defaultTab));

  useEffect(() => {
    setSelected(getTab(defaultTab));
  }, [defaultTab]);

  const handleStripChange = (selected: ICarrierPanelTab) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    setSelected(selected);
    onChange(selected.key);
  };

  return (
    <TabsV2
      keyName="key"
      tabs={tabItems}
      setSelectedTab={(tab: ICarrierPanelTab) => {
        handleStripChange(tab);
      }}
      selectedTab={selected.key}
      isDarkMode
      autoFill
      labelCentered
    />
  );
};

const getTabByKey = (key: ECarrierPanelTab): ICarrierPanelTab | undefined => {
  return getTabItems().find((tab) => tab.key === key);
};

export const tabStripRendererByKeyFS = (key: ECarrierPanelTab) => {
  return (
    <TabsV2
      keyName="key"
      tabs={[getTabByKey(key)].filter(Boolean) as ICarrierPanelTab[]}
      selectedTab={getTabByKey(key)?.key}
      isDarkMode
      autoFill
      labelCentered
    />
  );
};

export default DetailsTabStrips;
