import { Box, SxProps } from '@mui/material';
import { FunctionComponent } from 'react';
import IconsComponent from '../IconsComponent';

interface BreadCrumbsProps {
  iconName?: string;
  source?: string;
  sectionName?: string | boolean;
  pageName?: string;
  pageNameAppend?: () => JSX.Element;
  partition?: string;
}

export const BreadCrumbs: FunctionComponent<BreadCrumbsProps> = ({
  iconName,
  source = 'MUIcons',
  sectionName,
  pageName = '',
  pageNameAppend,
  partition = '>',
}: BreadCrumbsProps) => {
  const sxProps: SxProps = {
    color: 'tabStrip.tabText',
    fontWeight: 'fontWeightRegular',
    fontSize: 'fontSize',
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
    width: 'fit-content',
    textTransform: 'capitalize',
  };

  return (
    <Box sx={sxProps} className="BreadCrumbs-root">
      <IconsComponent
        styleProps={{
          height: '20px',
          width: '20px',
          marginRight: '5px',
          color: 'tabStrip.sidePanelTabIcon',
        }}
        iconName={iconName}
        source={source}
      />
      <Box
        sx={{
          ...sxProps,
          color: 'tabStrip.sidePanelTabIcon',
        }}
        className="BreadCrumbs-sectionName"
      >
        {sectionName && sectionName.toLowerCase()}
      </Box>
      {pageName ? (
        <Box sx={sxProps} className="BreadCrumbs-partition">
          {partition}
        </Box>
      ) : null}
      {pageName ? (
        <Box
          sx={{
            ...sxProps,
            flexDirection: 'row',
          }}
          className="BreadCrumbs-pageName"
        >
          {pageName && pageName.toLowerCase()}
          {pageNameAppend?.()}
        </Box>
      ) : null}
    </Box>
  );
};

export default BreadCrumbs;
