export interface IMaintenanceItem {
  id: string;
  organizationId: number;
  itemName: string;
  itemCode: string;
  description: string;
  iconName: string;
  createdBy: string;
  createDate: string;
  custom: boolean;
  deduction: boolean;
}

export class MaintenanceItem implements IMaintenanceItem {
  id!: string;
  organizationId!: number;
  itemName!: string;
  itemCode!: string;
  description!: string;
  iconName!: string;
  createdBy!: string;
  createDate!: string;
  custom!: boolean;
  deduction!: boolean;
}
