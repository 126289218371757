export const ImportConstants = {
  finishImport: 'Finish Import',
  goBack: 'Go Back',
  clickWilCancel: 'Clicking here will cancel all steps',
  totalExtractedRows: 'Total Extracted Rows',
  foundInFIle: 'Found In File',
  assignedTractors: 'Assigned Tractors',
  assignedTrailers: 'Assigned Trailers',
  noMissingEntitiesFound: 'No Missing Entities Found',
  everythingMapped:
    'All the entites such as customers, drivers, tractors, trailers were found and mapped automatically. Proceed to next step',
  unmappedCustomerSub:
    'There are unmapped Customer(s). We will create them as a new Customer(s).',
  wantToGoBack:
    'If you go one step back you will lose all the changes done on this page. Still, want to go back? ',
  import: 'Import',
  importAll:
    'Please import Customer, Locations, Drivers, Tractors and Trailers before you start Loads Import to ensure those entities are mapped with your loads.',
  columnMapping: 'Column Mapping',
  mapFieldsWithLoadops: 'Map the file fields with LoadOps fields',
  bulkEditing: 'Bulk Editing',
  doBulkActions: 'Do bulk actions and make the changes as required',
  smartErrDetection: 'Smart Error Detection',
  resolveImportErr:
    'Resolve all import errors and warnings right during the import process',
  mappingPersistency: 'Mapping Persistency',
  mappingSub: 'Map the field once and do future imports easily',
  readUserGuide: 'Read User Guide',
  watchVideoTutorial: 'Watch Video Tutorial',
  downloadSampleFile: 'Download  Sample File',
  duration: 'Duration',
  limit: 'Limit',
  fileSize: 'File Size',
  supportedFiles: 'Supported Files',
  upload: 'Upload',
  selectType: 'Select Type',
  learnMore: 'Learn More',
  dragDropAnywhere: 'Drag and Drop anywhere',
  uploadFailed:
    'Upload Failed. The file cannot be uploaded at the moment. Please try again',
  selectDateFormat: 'Select The Date Format',
  selectDeductionType: 'Select Deduction Type',
  none: 'None',
  uploadCompleted: 'Upload Completed',
  uploadFileResultHeading:
    'The first row has been selected as the header by default. This is a sample from the file, go next to do final extraction.',
  dataPreview: ' Data Preview',
  clickToViewSampleFiles:
    'Map values and click on eye icon to view sample values',
  unmappedFiledsMessage: 'There are unmapped field(s)',
  loadOpsColumn: 'LoadOps Column',
  columnsInFile: 'Columns in File',
  warningCells: 'Warning Cells',
  importCompletedWithErrors:
    'Import has been successfully completed with a few errors.',
  importCompletedSuccessfully: 'Import has been successfully completed.',
  importCompleted: 'Import Completed!',
};
