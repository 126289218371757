import { EventsType, ResourceTimeRangeType, ResourceType } from '@optym/gantt';
import { SecondaryDetailsPanelProps } from '../../../common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import { OptionType, Trip } from '../../../models';
import {
  DispatchEntitySummary,
  IDispatchDriver,
} from '../../../models/DTOs/Dispatch/Dispatch';
import { IRelativeDateRangeOption } from '../../../ui-kit/components/RelativeDateRange';
import { ITabV2 } from '../../../ui-kit/components/TabsV2';

export enum EGanttTabPanel {
  Gantt = 'Gantt',
  Map = 'Map',
  Grid = 'Grid',
}

export enum EDispatchPanelTab {
  TIMELINE = 'TIMELINE',
  PREFERENCES = 'PREFERENCES',
}

export interface IDispatchPanelTab extends ITabV2 {
  key: EDispatchPanelTab;
}

export enum EDispatchDriverWorkDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum EDispatchOperationType {
  LOCAL = 'LOCAL',
  REGIONAL = 'REGIONAL',
  OTR = 'OTR',
  CASUAL = 'CASUAL',
}

export enum EOperationMode {
  TEAM = 'TEAM',
  SOLO = 'SOLO',
}

export enum EEmploymentType {
  OWNER_OPERATOR = 'OWNER_OPERATOR',
  EMPLOYEE = 'EMPLOYEE',
}

export interface IDispatchOption {
  key: string | number | boolean | null;
  value: string;
}

export interface IDriverDetailOption {
  key: EDriverDetails;
  value: string;
}

export enum EDriverDetails {
  needLoad = 'needLoad',
  timeOff = 'timeOff',
  hasViolation = 'hasViolation',
}

export enum DispatchFiltersName {
  sort = 'sortFilter',
  sortDirection = 'sortDirectionFilter',
  driver = 'driverFilter',
  startDate = 'dateFilter',
  operationMode = 'operationMode',
  primaryDispatcher = 'primaryDispatcher',
  employmentType = 'employmentType',
  operationType = 'operationType',
  displayMode = 'displayMode',
  zoomLevel = 'zoomLevel',
  ganttColumnWidth = 'ganttColumnWidth',
  driverDetails = 'driverDetails',
  trips = 'tripIdsFilter',
  hasViolation = 'hasViolation',
  originRadius = 'originRadius',
  destinationRadius = 'destinationRadius',
  origin = 'originFilter',
  destination = 'destinationFilter',
}

export interface IDispatchFilters {
  filters: {
    [DispatchFiltersName.sort]?: IDispatchOption;
    [DispatchFiltersName.sortDirection]?: boolean;
    [DispatchFiltersName.driver]?: OptionType[];
    [DispatchFiltersName.startDate]?: IRelativeDateRangeOption;
    [DispatchFiltersName.operationMode]?: IDispatchOption | null;
    [DispatchFiltersName.primaryDispatcher]?: OptionType[];
    [DispatchFiltersName.employmentType]?: IDispatchOption | null;
    [DispatchFiltersName.operationType]?: IDispatchOption | null;
    [DispatchFiltersName.displayMode]?: EGanttTabPanel | null;
    [DispatchFiltersName.zoomLevel]: number;
    [DispatchFiltersName.ganttColumnWidth]: number | null;
    [DispatchFiltersName.driverDetails]: IDriverDetailOption[];
    [DispatchFiltersName.trips]?: Trip[];
    [DispatchFiltersName.hasViolation]?: boolean;
    [DispatchFiltersName.originRadius]?: number | null;
    [DispatchFiltersName.destinationRadius]?: number | null;
    [DispatchFiltersName.origin]?: string[] | null;
    [DispatchFiltersName.destination]?: string[] | null;
  };
  gantt?: {
    columns?: { [key: string]: boolean };
  };
  query: {};
}

export enum EDriverGroupWarningCatetory {
  driverSafetyWarnings = 'driverSafetyWarnings',
  driverPlanningWarnings = 'driverPlanningWarnings',
  tractorSafetyWarnings = 'tractorSafetyWarnings',
  trailerSafetyWarnings = 'trailerSafetyWarnings',
  driverTractorIncompatibilityWarnings = 'driverTractorIncompatibilityWarnings',
  driverTrailerIncompatibilityWarnings = 'driverTrailerIncompatibilityWarnings',
  loadWarnings = 'loadWarnings',
  driverHOSWarning = 'driverHOSWarning',
}

export enum EDriverGroupWarningType {
  LOAD_DELAYS = 'LOAD_DELAYS',
  LOAD_DETENTION = 'LOAD_DETENTION',
  LOAD_UNASSIGNED = 'LOAD_UNASSIGNED',
  SAFETY_ISSUE_WARNING = 'SAFETY_ISSUE_WARNING',
  INCOMP_LOAD_TRAILER_WARNING = 'INCOMP_LOAD_TRAILER_WARNING',
  INCOMP_LOAD_DRIVER_WARNING = 'INCOMP_LOAD_DRIVER_WARNING',
  SAFETY_DRIVER_ISSUE_CRITICAL = 'SAFETY_DRIVER_ISSUE_CRITICAL',
  INCOMP_DRIVER_TRACTOR_WARNING = 'INCOMP_DRIVER_TRACTOR_WARNING',
  INCOMP_DRIVER_TRAILER_WARNING = 'INCOMP_DRIVER_TRAILER_WARNING',
  SAFETY_TRACTOR_MAINTENANCE_CRITICAL = 'SAFETY_TRACTOR_MAINTENANCE_CRITICAL',
  SAFETY_TRAILER_MAINTENANCE_CRITICAL = 'SAFETY_TRAILER_MAINTENANCE_CRITICAL',
  SAFETY_TRAILER_MAINTENANCE_WARNING = 'SAFETY_TRAILER_MAINTENANCE_WARNING',
  SAFETY_TRACTOR_MAINTENANCE_WARNING = 'SAFETY_TRACTOR_MAINTENANCE_WARNING',
  SAFETY_HOS = 'SAFETY_HOS',
  PLANNING_NEED_LOAD = 'PLANNING_NEED_LOAD',
}

export enum EGanttTripStatus {
  DEFAULT = 'DEFAULT',
  DELAY = 'DELAY',
  WARNING = 'WARNING',
  GAP = 'GAP',
  TIMEOFF = 'TIMEOFF',
}

export interface IDispatchPreferencesData {
  useSleeperBerthProvision: boolean;
  ignoreHOSRestrictions: boolean;
  dvirPreferences: string;
  workWeekType: string;
  preferredTruckstops: string[];
}

export interface IDispatchPreferencesFormData {
  id?: string;
  driverId?: number;
  useSleeperBerthProvision: boolean;
  ignoreHOSRestrictions: boolean;
  dvirPreferences: IDispatchOption | null;
  workWeekType: IDispatchOption | null;
  preferredTruckstops: IDispatchOption[];
}

export enum EDispatch3DotMenuType {
  DELETE = 'DELETE',
  REQUEST_TIMEOFF = 'REQUEST_TIMEOFF',
  ACTIVATE_TIMEOFF = 'ACTIVATE_TIMEOFF',
  REVERT_TIMEOFF = 'REVERT_TIMEOFF',
  EDIT_TIMEOFF = 'EDIT_TIMEOFF',
  DELETE_TIMEOFF = 'DELETE_TIMEOFF',
  MARK_PROGRESS_TIMEOFF = 'MARK_PROGRESS_TIMEOFF',
}

export interface IActionTimeOffBaseProps {
  hocOnAction?: (status: EDispatch3DotMenuType) => Promise<void>;
}

export enum EVENT_STATUS {
  AVAILABLE = 'AVAILABLE',
  ASSIGNED = 'ASSIGNED',
  PLANNED = 'PLANNED',
  PLANNED_DISAPPROVE = 'PLANNED_DISAPPROVE',
  DISPATCHED = 'DISPATCHED',
  TRANSIT = 'TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  DETAINED = 'DETAINED',
  GAP = 'GAP',
  COMPLETED = 'COMPLETED',
  EMPTY = 'EMPTY',
  RELAY_START = 'RELAY_START',
  RELAY_END = 'RELAY_END',
  EMPTY_HIDDEN = 'EMPTY_HIDDEN',
  NEED_LOAD = 'NEED_LOAD',
}

export enum ESecondaryDetailsPanelType {
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  LOAD = 'LOAD',
  TRIP = 'TRIP',
  USER = 'USER',
  CUSTOMER = 'CUSTOMER',
  DISPATCH = 'DISPATCH',
  PAY_STATMENT = 'PAY_STATMENT',
  EXPENSE = 'EXPENSE',
  VENDOR = 'VENDOR',
  LOAD_BOARD = 'LOAD_BOARD',
  CARRIER = 'CARRIER',
  ADD_NEW_LOAD = 'ADD_NEW_LOAD',
  EDI_TENDER = 'EDI_TENDER',
  TERMINAL = 'TERMINAL',
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
}

export interface SecondaryDetailsOpenProps {
  handleSecondaryDetailsOpen: (
    id: string,
    type: ESecondaryDetailsPanelType,
    options?: Partial<SecondaryDetailsPanelProps>
  ) => void;
}

export interface GanttResourceType extends ResourceType {
  id: string;
  driver: IDispatchDriver;
}

export interface GanttEventsType extends EventsType {
  id: string;
  loadId?: string;
  driverId?: string;
  entitySummary: DispatchEntitySummary;
  resourceId: string;
}

export interface GanttResourceTimeRangeType extends ResourceTimeRangeType {
  id: string;
  entitySummary: DispatchEntitySummary;
}

export interface GanttDataResponse {
  ganttResources: GanttResourceType[];
  ganttEvents: GanttEventsType[];
  ganttResourceTimeRanges: GanttResourceTimeRangeType[];
}

export enum EGanttTimelineReOrder {
  Assigned = 'Assigned',
  Planned = 'Planned',
}
