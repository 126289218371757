export const RegistrationConstants = {
  whatsNew: 'What’s New',
  alreadyHaveAccount: 'Already have an account?',
  signIn: 'Sign In',
  agree: 'I agree with LoadOps’',
  privacyPolicy: 'Privacy Policy',
  and: 'and',
  termsConditions: 'Terms of Conditions',
  agreeToPrivacyPolicy: 'Please agree to Privacy Policy.',
  welcomeBack: 'Welcome Back!',
  somethingWrongWithActivation: 'Something went wrong with your activation!',
  accountLinkNotValid:
    'The account activation link is not valid. Please contact your company admin.',
  payRejoin: 'Pay and Rejoin',
  rejoin: 'Rejoin',
  accountFullyActivated: 'Account is successfully activated!',
  downloadAppFromPlaystore:
    ' Download the LoadOps mobile app for drivers via App Store or Google Play Store',
  cardInfoUsed:
    'Your card info will be used for monthly charging of payment per your subscription plan',
  runAllTrucking: 'Run all your trucking',
  operationsInOnePlace: 'operations in one place',
};
