import { isEmpty } from 'lodash';
import {
  StopSolutionV3Contact2,
  StopSolutionV3Location,
  StopSolutionV3Prop,
} from '../../TripPlanV3/types';
import { TripStopStatusEnum } from '../../types';

//https://confluence.optym.net/display/OMP/Trip+Plan#TripPlan-ShowingStopStatus
interface GetTimelineeActiveProps {
  isCompleted: boolean;
  isCancelled: boolean;
  disabled: boolean;
  isNotCompletedBeforeEnRoute: boolean;
  stopSolution: StopSolutionV3Prop;
}
export const getTimelineActive = ({
  isCompleted,
  isCancelled,
  disabled,
  stopSolution,
  isNotCompletedBeforeEnRoute,
}: GetTimelineeActiveProps): {
  icon: boolean;
  header: boolean;
  separator: boolean;
} => {
  if (isCompleted) {
    return {
      icon: true,
      header: true,
      separator: true,
    };
  }
  if (disabled || isCancelled) {
    return {
      icon: false,
      header: false,
      separator: false,
    };
  }

  if (isNotCompletedBeforeEnRoute) {
    return {
      icon: true,
      header: false,
      separator: true,
    };
  }

  if (stopSolution.status === TripStopStatusEnum.EN_ROUTE) {
    return {
      icon: false,
      header: false,
      separator: false,
    };
  }

  if (stopSolution.status === TripStopStatusEnum.ARRIVED) {
    return {
      icon: true,
      header: true,
      separator: false,
    };
  }
  if (stopSolution.status === TripStopStatusEnum.CANCELLED) {
    return {
      icon: false,
      header: false,
      separator: false,
    };
  }
  if (stopSolution.status === TripStopStatusEnum.COMPLETED) {
    return {
      icon: true,
      header: false,
      separator: false,
    };
  }

  return {
    icon: false,
    header: false,
    separator: false,
  };
};

export const isContactEmpty = (
  contact: StopSolutionV3Contact2 | null
): boolean => {
  if (isEmpty(contact)) {
    return true;
  }
  if (
    [contact?.name, contact?.email, contact?.phone].filter((str) => !!str)
      .length === 0
  ) {
    return true;
  }
  return false;
};

export const getDisplayArrivalTimeTitle = ({
  isCompleted,
  isCancelled,
  stopSolution,
}: {
  isCompleted?: boolean;
  isCancelled?: boolean;
  stopSolution: StopSolutionV3Prop;
}): string => {
  if (isCancelled) {
    return 'Cancelation Date Time';
  }
  if (
    isCompleted &&
    stopSolution.actualTimeOfArrival === null &&
    stopSolution.actualTimeOfCompletion === null
  ) {
    return 'Actual Arrival Time';
  }
  if (stopSolution.actualTimeOfArrival) {
    return 'Actual Arrival Time';
  }
  return 'Estimated Arrival Time';
};

export const getDisplayDepartureTimeTitle = ({
  isCompleted,
  stopSolution,
}: {
  isCompleted?: boolean;
  stopSolution: StopSolutionV3Prop;
}): string => {
  if (
    isCompleted &&
    stopSolution.actualTimeOfArrival === null &&
    stopSolution.actualTimeOfCompletion === null
  ) {
    return 'Actual Departure Time';
  }
  if (stopSolution.actualTimeOfCompletion) {
    return 'Actual Departure Time';
  }
  return 'Estimated Departure Time';
};

export const getDislayLocation = ({
  withLocationName = false,
  location,
}: {
  withLocationName?: boolean;
  location: StopSolutionV3Location;
}): string => {
  const address: string = [
    location?.streetAddress,
    location?.streetAddress2,
    location?.city,
    location?.state,
  ]
    .filter((str) => !!str)
    .join(', ');
  const finalAddress = [address, location?.zipcode]
    .filter((str) => !!str)
    .join(' ');
  if (withLocationName) {
    return [location?.locationName, finalAddress]
      .filter((str) => !!str)
      .join(': ');
  }
  return finalAddress;
};
