import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import PageContainer from '../../common/Wrappers/PageContainer';
import Grid from '@mui/material/Grid';
import InvoiceCard from './InvoiceCard';
import PaymentCard from './PaymentCard';
import ContactUs from './ContactUs';
import InvoiceTable from './InvoiceTable';
import { subscriptionService } from '../../api';
import { ActivateAccount, GetSubscriptionRequest } from '../../models';
import AdditionalModulesCard from './AdditionalModulesCard';
import { Button, Stack, Typography } from '@mui/material';
import StorageManager from '../../StorageManager/StorageManager';

import SubscriptionCard from './subscriptionCard';
import {
  PLAN_CARD_CONTENT,
  PLAN_CARD_KEYS,
  SUBSCRIPTION_CARD_INFO,
} from '../../views/subscription/config/subscription.config';
import TrailCard from './trialCard';
import { styles } from './styles';
import ActivatePlanForm from './activatePlanForm';
import AdditionalModulePopup from './AdditionalModulPopup';
import moment from 'moment/moment';
import { getCustomerIp } from '../../views/register/utils';
import { useNavigate } from 'react-router-dom';
import { AXELE_USER_TYPES } from '../../common/Permission';

const Subscription = () => {
  const [subscriptionData, setSubscriptionData] = useState<any>({});
  const [componentData, setComponentData] = useState({});
  const [url, setUrl] = useState('');
  const [planType, setPlanType] = useState<string | null>(null);
  const [isLoadingSubscriptionAPI, setIsLoadingSubscriptionAPI] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const [trialDays, setTrialDays] = useState<string | number>('');
  const [isOpenAgreementPopup, setIsOpenAgreementPopup] =
    useState<boolean>(false);
  const userStorage = StorageManager.getUser();

  const getSubscription = async () => {
    const query = new GetSubscriptionRequest();
    const response = await subscriptionService.getSubscription(query);
    if (response?.subscription) setSubscriptionData(response.subscription);
    if (response?.component) setComponentData(response.component);
  };

  const handleSubscriptionClick = () => {
    setIsOpenAgreementPopup(true);
  };

  const handleActivatePlanClick = (planType: string) => {
    setPlanType(planType);
  };

  const handleCloseActivatePlan = () => {
    setPlanType(null);
  };

  const handlePopupClose = () => {
    setIsOpenAgreementPopup(false);
  };

  const handleActivateAccount = async (data: ActivateAccount) => {
    const response = await subscriptionService.activateAccount(data);
    if (response) {
      getSubscription();
    }
  };

  useEffect(() => {
    getSubscription();
    const current_period_ends_at = userStorage.effectiveTerminationDate;
    if (
      current_period_ends_at &&
      userStorage?.subscriptionStatus === 'EVALUATION' &&
      userStorage.userType === AXELE_USER_TYPES.OWNER_ADMIN
    ) {
      const suspensionDate = moment(current_period_ends_at).startOf('day');
      const daysRemaining = suspensionDate.diff(
        moment().startOf('day'),
        'days'
      );
      if (daysRemaining) {
        setTrialDays(daysRemaining);
      }
    }
  }, []);

  const handleSubscriptionPlan = async (token: any) => {
    setIsLoadingSubscriptionAPI(true);
    const ip = await getCustomerIp();
    const payload = {
      organizationId: userStorage.organizationId,
      paymentProfile: {
        subscription_id: subscriptionData?.id,
        customer_id: subscriptionData?.customer?.id,
        subscription_state: subscriptionData?.state,
        chargify_token: token,
      },

      ipAddress: ip,
      pricePoint: planType,
    };
    const response = await subscriptionService.subscriptionPlan(payload);

    if (response) {
      setIsLoadingSubscriptionAPI(false);
      getSubscription();
      handleCloseActivatePlan();
      setTrialDays(0);
      handlePopupClose();
      userStorage.effectiveTerminationDate = null;
      StorageManager.setItem('user', userStorage);
      navigate('/subscription');
    }
  };

  const onActivateClick = () => {
    handleActivatePlanClick(PLAN_CARD_KEYS.ANNUAL);
  };
  return (
    <PageContainer>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: 'mainContainerBG.backgroundColor',
          padding: '32px',
          overflow: 'auto',
        }}
      >
        {planType && (
          <ActivatePlanForm
            onClose={handleCloseActivatePlan}
            handleActivatePlan={handleSubscriptionPlan}
            planType={planType}
            isLoadingSubscriptionAPI={isLoadingSubscriptionAPI}
          />
        )}
        {isOpenAgreementPopup && (
          <AdditionalModulePopup
            handleClose={() => handlePopupClose()}
            onActionClick={onActivateClick}
            title={SUBSCRIPTION_CARD_INFO.TITLE}
            content={SUBSCRIPTION_CARD_INFO.CONTENT}
            isCloseBtn={false}
            isCancelBtn={true}
            isHideCancelIcon={true}
            popUpStyle={styles.agreementPopupStyle}
            titleContainerStyle={styles.agreementPopupTitleStyle}
            titleTextStyle={styles.agreementPopupTitleStyle}
            actionBtnText={SUBSCRIPTION_CARD_INFO.ACTION_BUTTON_TEXT}
          />
        )}

        {trialDays ? (
          <Grid id="upper-grid" container xs={12} spacing={2} mb={3}>
            <Grid item xs={12} md={4}>
              <Stack spacing={2}>
                <TrailCard trialDays={trialDays} />
                <AdditionalModulesCard
                  data={componentData}
                  handleActivateAccount={handleActivateAccount}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={8}>
              <SubscriptionCard
                title={'Activate your account now!'}
                subTitle={
                  'Activating account now will extend your first billing by the remaining days of the free trial.'
                }
                containerStyle={{
                  ...styles.trialCardStyle,
                  paddingRight: '0px !important',
                }}
                content={
                  <Grid container xs={12} spacing={4} mt={1}>
                    <Grid item xs={12} md={4} sx={styles.planCardGridStyle}>
                      <SubscriptionCard
                        title={'Monthly Plan'}
                        containerStyle={{ ...styles.planCardStyle }}
                        content={PLAN_CARD_CONTENT(
                          PLAN_CARD_KEYS.MONTHLY,
                          '75'
                        )}
                        footer={
                          <Box>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                handleActivatePlanClick(PLAN_CARD_KEYS.MONTHLY)
                              }
                            >
                              Subscribe
                            </Button>
                          </Box>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={styles.planCardGridStyle}>
                      <SubscriptionCard
                        title={'Annual Plan'}
                        titleStyle={{ textAlign: 'center' }}
                        containerStyle={styles.planCardStyle}
                        subTitle={'Save 25%'}
                        subTitleStyle={{
                          color: 'success.dark',
                          fontWeight: 600,
                          fontSize: '20px',
                        }}
                        content={PLAN_CARD_CONTENT(PLAN_CARD_KEYS.ANNUAL, '55')}
                        footer={
                          <Box>
                            <Button
                              variant="contained"
                              onClick={handleSubscriptionClick}
                            >
                              Subscribe
                            </Button>
                          </Box>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={styles.planCardGridStyle}>
                      <SubscriptionCard
                        title={'50+ Trucks'}
                        titleStyle={{ textAlign: 'center' }}
                        content={PLAN_CARD_CONTENT(PLAN_CARD_KEYS.TRUCKS, '75')}
                        footer={
                          <Box sx={{ wordWrap: 'break-word' }}>
                            <Typography sx={{ color: 'primary.main' }}>
                              Contact us
                            </Typography>
                            truckload.sales@optym.com
                          </Box>
                        }
                        containerStyle={styles.planCardStyle}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          </Grid>
        ) : (
          <Box>
            <Grid id="upper-grid" container xs={12} spacing={2}>
              <Grid item xs={12} md={3}>
                <InvoiceCard
                  data={subscriptionData}
                  getSubscription={getSubscription}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AdditionalModulesCard
                  data={componentData}
                  handleActivateAccount={handleActivateAccount}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <PaymentCard
                  data={subscriptionData}
                  getSubscription={getSubscription}
                />
              </Grid>
            </Grid>
            <InvoiceTable setUrl={setUrl} uid={subscriptionData?.id} />
          </Box>
        )}
        <ContactUs
          getSubscription={getSubscription}
          subscriptionData={subscriptionData}
          url={url}
          showCancelSubscription={trialDays ? false : true}
        />
      </Box>
    </PageContainer>
  );
};

export default Subscription;
