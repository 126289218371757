export const sideDrawerDisplayData = {
  desc: '',
  fields: [],
  label: 'Enter Integration Credentials:',
  userGuide: '',
};

export const credentialsInfo = {
  content1:
    'Please contact {providerName} support team to get integration credentials.',
  content2:
    'Login into your {providerName} account. Navigate to Fleet > Apps > Generate API key to generate the API key',
  content3:
    'Before you can integrate, you will need an API Token from within your {providerName} account. ',
  content4:
    'Ensure you are entering the admin login credentials to complete the integration. You will redirected to the vendor’s website to complete the integration.',
  content5:
    'The integration credentials may vary from the application credentials for {providerName}. Please contact {providerName} support team to get integration credentials.',
  content6:
    'Before you integrate, you’ll need the following IDs from {providerName} account: Company ID, User ID',
  content7:
    'Before you can access this integration, a set of API credentials will be needed from {providerName}.​ Please contact the {providerName} support team and request API credentials so that your ELD can be integrated with Axele. Additional charges from {providerName} may apply. ',
  content8:
    'Before you integrate, please connect with a {providerName} representative to request an Access Token. You need an "API Key" to integrate with Axele',
  content9:
    'Login into your {providerName} account. Navigate to Administration > My Organization > Partner Gateway to generate the API key',
  content10:
    'Ensure you are entering the admin login credentials to complete the integration. You will redirected to the vendor’s website to complete the integration.',
  content11:
    'The integration credentials may vary from the application credentials for {providerName}. Please contact {providerName} support team to get integration credentials.',
  content12:
    'Before you can access this integration, a set of API credentials will be needed from {providerName}.​ Please contact the {providerName} support team and request API credentials so that your ELD can be integrated with Axele. Additional charges from {providerName} may apply. ',
};

export const userGuides: { [key: number]: any } = {
  1: 'https://help.axele.com/loadops-3md',
  2: 'https://help.axele.com/loadops-eroad',
  3: 'https://help.axele.com/loadops-verizon-connect',
  4: 'https://help.axele.com/loadops-keeptruckin',
  6: 'https://help.axele.com/loadops-samsara',
  8: 'https://help.axele.com/eld-omnitracs',
  9: 'https://help.axele.com/loadops-geotab',
  10: 'https://help.axele.com/loadops-',
  11: 'https://help.axele.com/eld-trackensure',
  12: 'https://help.axele.com/loadops-big-road',
  13: 'https://help.axele.com/loadops-teletrac',
  14: 'https://help.axele.com/loadops-m2m-in-motion',
  15: 'https://help.axele.com/eld-mondo-tracking',
  16: 'https://help.axele.com/loadops-vistracks',
  17: 'https://help.axele.com/loadops-master-eld',
  19: 'https://help.axele.com/loadops-linxup',
};
