import React, { useState } from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  useTheme,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ImportActions from '../../Import';
import { ThemeProvider } from '@mui/material/styles';
import { possibleDeductionTypes } from './constants';
import { theme } from './DateFormatSelect';

export default function DeductionTypeSelect() {
  const [deductionType, setDeductionType] = useState('');
  const handleChange = (event: SelectChangeEvent) => {
    event.stopPropagation();
    setDeductionType(event.target.value);
    ImportActions.deductionType = event.target.value;
  };

  const currentTheme = useTheme();

  return (
    <ThemeProvider theme={theme(currentTheme)}>
      <FormControl
        sx={{ minWidth: 250, mr: 4, borderRadius: '10px' }}
        size="small"
      >
        <InputLabel>Select Deduction Type</InputLabel>

        <Select
          sx={{ borderRadius: '10' }}
          value={deductionType}
          label="Select Deduction Type"
          onChange={handleChange}
        >
          <MenuItem key="empty" value="">
            <em>None</em>
          </MenuItem>
          {possibleDeductionTypes.map((type, i) => (
            <MenuItem key={i} onClick={(e) => e.stopPropagation()} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
