import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import Address from '../AddressField/Address';
import { addressType } from '../AddressField/constants';
import { state, zone } from './stateZone';
import StateZoneSelect, { IStateValue, IZoneValue } from './StateZoneSelect';
import { ediService } from '../../../api';
import { GetFilterListRequest } from '../../../models/DTOs/EDI/Requests';
import { ServiceError } from '../../../api/interfaces';

export enum ELocationLevel {
  City = 'City',
  State = 'State',
  Zone = 'Zone',
}

export enum EChangedType {
  LocationLevel = 'LOCATION_LEVEL',
  LocationValue = 'LOCATION_VALUE',
}

export type TLocationSelectValue = {
  locationLevel?: ELocationLevel;
  data?: IZoneValue[] | IStateValue[] | addressType;
};

export interface ILocationSelect {
  label?: string;
  value?: TLocationSelectValue;
  onChange?: (value: TLocationSelectValue, type: EChangedType) => void;
  error?: any;
  isZoneDisabled?: boolean;
  /**
   * On EDI Tender, this is Zip instead of Zone
   */
  isSwapZoneToZip?: boolean;
}

const LocationSelect: FC<ILocationSelect> = ({
  label,
  value,
  onChange,
  error,
  isZoneDisabled,
  isSwapZoneToZip,
}) => {
  const [locationLevel, setLocationLevel] = useState<
    ELocationLevel | undefined
  >(value?.locationLevel);
  const [zoneOptions, setZoneOptions] = useState<IZoneValue[]>(
    isSwapZoneToZip ? [] : zone
  );
  useEffect(() => {
    if (isSwapZoneToZip) {
      ediService.getFilterList(new GetFilterListRequest()).then((res) => {
        if (!(res instanceof ServiceError)) {
          setZoneOptions(
            res?.zip.map<IZoneValue>((value) => ({
              value,
              title: value,
            })) || []
          );
        }
      });
    }
  }, [isSwapZoneToZip]);
  return (
    <div>
      <FormControl>
        <RadioGroup
          row
          value={locationLevel}
          onChange={(e, newValue) => {
            if (value?.data) {
              onChange?.(
                {
                  locationLevel: newValue as ELocationLevel,
                  data: undefined,
                },
                EChangedType.LocationLevel
              );
            }
            setLocationLevel(newValue as ELocationLevel);
          }}
          sx={{
            flexWrap: 'nowrap',
          }}
        >
          {Object.values(ELocationLevel).map((value: ELocationLevel) => (
            <FormControlLabel
              key={value}
              value={value}
              control={
                <Radio
                  disabled={value === ELocationLevel.Zone && isZoneDisabled}
                />
              }
              label={
                value === ELocationLevel.Zone
                  ? isSwapZoneToZip
                    ? 'Zip'
                    : value
                  : value
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
      {locationLevel === ELocationLevel.City && (
        <Address
          onChange={(data: addressType) => {
            onChange?.(
              {
                locationLevel: locationLevel,
                data: data,
              },
              EChangedType.LocationValue
            );
          }}
          data={value?.data as addressType}
          errors={error}
          label={label}
          disableAddressLine
        />
      )}
      {locationLevel === ELocationLevel.State && (
        <StateZoneSelect<IStateValue>
          value={value?.data as IStateValue[] | undefined}
          onChange={(event, newValue) => {
            onChange?.(
              {
                locationLevel: locationLevel,
                data: newValue as IStateValue[],
              },
              EChangedType.LocationValue
            );
          }}
          options={state}
          label={`Select ${label} ${locationLevel}`}
          keyName="title"
        />
      )}
      {locationLevel === ELocationLevel.Zone && (
        <StateZoneSelect<IZoneValue>
          value={value?.data as IZoneValue[] | undefined}
          onChange={(event, newValue) => {
            onChange?.(
              {
                locationLevel: locationLevel,
                data: newValue as IZoneValue[],
              },
              EChangedType.LocationValue
            );
          }}
          options={zoneOptions}
          label={`Select ${label} ${isSwapZoneToZip ? 'Zip' : locationLevel}`}
          keyName="value"
        />
      )}
    </div>
  );
};

export default observer(LocationSelect);
