import { Type } from 'class-transformer';
import { ExpenseEntityType } from '../Expenses/Expenses';
import { QueryParams } from '../commonMixed/QueryParams';

export class GetExpenseCategoriesRequest extends QueryParams {}

export class ExpenseCategory {
  id?: string;
  custom?: boolean;
  description?: string;
  itemCode?: string;
  itemName?: string;
  organizationId?: number;
  deduction?: number;
}

export class GetPaymentTypesRequest extends QueryParams {}

export class PaymentType {
  id?: string;
  itemCode?: string;
  itemName?: string;
  expenseCategoryId?: string;
  expenseCategoryKey?: string;
  custom?: boolean;
  deduction?: boolean;
  description?: string;
  organizationId?: number;
  unit?: PaymentUnitType[] | undefined;
  PaymentType?: string;
  dispatcherId?: number;
}

export class GetDriverPaymentTermsRequest extends QueryParams {
  driverId?: number;
  tractorId?: number;

  constructor(id: number) {
    super(true);
    this.driverId = id;
    this.tractorId = id;
    this.dispatcherId = id;
  }
}

export class ExistsPayTypeItemByIdRequest extends QueryParams {
  itemId?: string;

  constructor(id: string) {
    super(true);
    this.itemId = id;
  }
}

export class DriverPaymentTermsGrouped {
  @Type(() => PaymentPerLoad)
  loadRateDTO?: PaymentPerLoad[] | null;
  scheduledPayDTO?: [];
  scheduledDeductionDTO?: [];
  payToDetails?: OwnerOperatorPaymentRecipient;
  scheduledDeductionTractorDTO?: PaymentPerLoad[];
  scheduledPay?: PaymentPerLoad[];
}

export enum OperationMode {
  SOLO = 'SOLO',
  TEAM = 'TEAM',
}

export class CreateDriverPaymentTerm {
  description?: string;
  driverId?: string;
  expenseCategoryId?: string;
  expenseCategoryKey?: string;
  operationMode?: OperationMode;
  organizationId?: number;
  payType?: string;
  payTypeId?: string;
  rate?: number;
  parent: number | undefined;
  unit: PaymentUnitType | undefined;
  availablePayTypes?: PaymentType;
}
export class PaymentUnitType {
  name?: string;
}
export class PaymentPerLoad {
  id?: number;
  driverId?: number;
  organizationId?: number;
  description?: string;
  expenseCategoryKey?: string;
  expenseCategoryId?: string;
  rate?: number;
  payTypeId?: string;
  payType?: string;
  operationMode?: OperationMode;
  unit?: string;
  PaymentUnit!: PaymentUnitType[] | null;
}

export class CreateTractorPaymentTerm {
  description?: string;
  tractorId?: string;
  expenseCategoryId?: string;
  expenseCategoryKey?: string;
  operationMode?: OperationMode;
  organizationId?: number;
  payType?: string;
  payTypeId?: string;
  rate?: number;
  parent: number | undefined;
  unit: PaymentUnitType | undefined;
}

export class OwnerOperatorPaymentRecipient extends QueryParams {
  entityType?: ExpenseEntityType;
  entityId?: string;
  payToEnabled?: boolean;
  payToEntityType?: ExpenseEntityType | null;
  payToEntityId?: number | null;
  payToEntityName?: string;
  id?: number;
  name?: string;
  constructor(data: Partial<OwnerOperatorPaymentRecipient>) {
    super();
    this.entityId = data?.entityId;
    this.entityType = data?.entityType ?? 'TRACTOR';
    this.payToEnabled = Boolean(data?.payToEntityId);
    this.payToEntityId = data?.payToEntityId;
    this.payToEntityType =
      data?.payToEntityType ?? Boolean(data?.payToEntityId) ? 'DRIVER' : null;
    this.payToEntityName = data?.payToEntityName;
  }
}
