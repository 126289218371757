import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface DisabledMarketRateProps {
  onClose: () => void;
}

const DisabledMarketRate: FC<DisabledMarketRateProps> = ({ onClose }) => {
  return (
    <Box
      p={2}
      borderRadius={2}
      boxShadow="0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)"
      onMouseLeave={onClose}
    >
      <Box py={1.5} px={2} borderRadius={0.5} bgcolor="#FFEED3">
        <Typography fontSize={14} fontWeight={400}>
          Contact{' '}
          <Typography
            fontSize={14}
            component="a"
            fontWeight={600}
            href="mailto:loadops.support@optym.com"
          >
            loadops.support@optym.com
          </Typography>{' '}
          to access Cargo Chief C4 real-time accurate full truckload lane rates
        </Typography>
      </Box>
    </Box>
  );
};

export default DisabledMarketRate;
