import { Type } from 'class-transformer';
import { LoadCommodity } from '../../../../models';
import { ReeferModeEnum } from '../../../../subPages/loadsList/CreateLoad/schema/load';
import {
  Commodity,
  EquipmentType,
} from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';

export class LoadAdditionalDetails {
  id: string | null;
  @Type(() => EquipmentType)
  equipmentTypes: EquipmentType[] | null;
  sealNumber: string;
  chassisNumber: string;
  containerNumber: string;
  bookingNumber?: string;
  appointmentNumber?: string;
  loadDirection?: string;
  lowerLimitTemperature: string;
  upperLimitTemperature: string;
  totalWeight: string;
  notes: string;
  loadType: string;
  @Type(() => Commodity)
  commodityData: Commodity[] | null;
  temperature?: number | null;
  variance?: number | null;
  reeferMode?: ReeferModeEnum;
  mode?: string;
  serviceType?: string;
  steamShipLine?: string;
  yardLocation?: string;
  containerTerminal?: string;
  holdCategory?: string;
  holdType?: string;
  perdiemStartDate?: string;
  fullOutgateDate?: string;
  lastFreeDay?: string;
  inPin?: string;
  outPin?: string;
  constructor(data: any) {
    const {
      equipmentAdditionalDetails,
      metaDataDetails,
      totalWeight,
      notes,
      trailerTypes,
      commodityData,
    } = data?.loadDetails || {};
    const {
      chassisNumber,
      containerNumber,
      lowerLimitTemperature,
      upperLimitTemperature,
      sealNumber,
      bookingNumber,
      appointmentNumber,
      loadDirection,
    } = equipmentAdditionalDetails || {};
    const {
      mode,
      serviceType,
      steamShipLine,
      yardLocation,
      containerTerminal,
      holdCategory,
      holdType,
      perdiemStartDate,
      fullOutgateDate,
      lastFreeDay,
      inPin,
      outPin,
    } = metaDataDetails || {};
    this.id = data.id;
    this.sealNumber = sealNumber;
    this.containerNumber = containerNumber;
    this.chassisNumber = chassisNumber;
    this.bookingNumber = bookingNumber;
    this.appointmentNumber = appointmentNumber;
    this.loadDirection = loadDirection;
    this.lowerLimitTemperature = lowerLimitTemperature;
    this.upperLimitTemperature = upperLimitTemperature;
    this.totalWeight = totalWeight;
    (this.mode = mode),
      (this.serviceType = serviceType),
      (this.steamShipLine = steamShipLine),
      (this.yardLocation = yardLocation),
      (this.containerTerminal = containerTerminal),
      (this.holdCategory = holdCategory),
      (this.holdType = holdType),
      (this.perdiemStartDate = perdiemStartDate),
      (this.fullOutgateDate = fullOutgateDate),
      (this.lastFreeDay = lastFreeDay),
      (this.inPin = inPin),
      (this.outPin = outPin),
      (this.notes = notes);
    this.equipmentTypes = trailerTypes;
    this.loadType = data.loadType;
    this.commodityData =
      commodityData?.map((item: LoadCommodity) => ({
        ...item,
        commodity: { id: item.commodityTypeId, itemName: item.itemName },
        commodityTypeDisplayValue: item.itemName,
      })) || null;
    this.temperature =
      data?.loadDetails?.equipmentAdditionalDetails?.temperature;
    this.variance = data?.loadDetails?.equipmentAdditionalDetails?.variance;
    this.reeferMode = data?.loadDetails?.equipmentAdditionalDetails?.reeferMode;
  }
}
