import { observer } from 'mobx-react';
import { loadService } from '../../../../api';
import {
  ELoadStatus,
  EMyLoadDetailsPanelTabs,
} from '../../../../common/LoadTabPanel/constants/constants';
import { RelayStopFormData } from '../../../../common/LoadTabPanel/tabs/Routes/components/RelayStopPopup/RelayStopForm';
import SecondaryDetailsPanel from '../../../../common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import { ByLoadIdRequest, LoadDetailsResponse } from '../../../../models';
import { useStores } from '../../../../store/root.context';
import { ESecondaryDetailsPanelType } from '../../../dispatch/constants/types';

interface IDispatchChildrenPanel {
  onUpdate?: (props?: any) => void;
}

const DispatchChildrenPanel = (props: IDispatchChildrenPanel) => {
  const {
    dispatch2TripsStore: {
      secondaryPanelData,
      setSecondaryPanelData,
      updateMainData,
      fetchMainData,
      filters,
    },
    dispatch2GlobalStore: { setRefreshDriverIdsList },
  } = useStores();

  if (!secondaryPanelData) return null;

  const onCreatedLoadHandler = async (load: LoadDetailsResponse) => {
    if (load instanceof LoadDetailsResponse) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.LOAD,
        id: load.id,
        isGlobal: false,
        defaultTab: EMyLoadDetailsPanelTabs.ROUTES,
      });
      const requestData = new ByLoadIdRequest();
      requestData['loadId'] = load.id;
      const loadRoutes = await loadService.getLoadRoutes(requestData);
      if (loadRoutes?.data?.trips) {
        const updatedIds = loadRoutes.data.trips.map(({ id }) => id);
        if (updatedIds) {
          updateMainData({ updatedIds });
        }
      }
    }
  };

  const handleClose = () => {
    setSecondaryPanelData(null);
  };

  const updateTripList = ({
    deletedIds,
    updatedIds,
  }: {
    deletedIds?: string[];
    updatedIds?: string[];
  }) => {
    updateMainData(
      {
        deletedIds,
        updatedIds,
      },
      (trips) => {
        const panelId =
          secondaryPanelData?.id || deletedIds?.[0] === updatedIds?.[0]
            ? updatedIds?.[1]
            : updatedIds?.[0];

        if (trips.some(({ id }) => id === panelId)) {
          setSecondaryPanelData({
            type: ESecondaryDetailsPanelType.TRIP,
            id: panelId as string,
            isGlobal: false,
          });
        }
        //else setSecondaryPanelData(null)
      }
    );
  };

  const handleDelete = (): void => {
    setSecondaryPanelData(null);
  };

  const getOptions = () => {
    switch (secondaryPanelData.type) {
      case ESecondaryDetailsPanelType.LOAD:
        return {
          onClose: handleClose,
          isGlobal: secondaryPanelData.isGlobal,
          onAction: (status: ELoadStatus) => {
            if (status === ELoadStatus.DELETED) {
              handleClose();
            }
          },
          onCreatedLoadHandler,
        };
      case ESecondaryDetailsPanelType.TRIP:
        return {
          onAction: (status: ELoadStatus) => {
            if (status === ELoadStatus.DELETED) {
              updateTripList({
                deletedIds: [secondaryPanelData.id as string],
              });
            } else
              updateTripList({
                updatedIds: [secondaryPanelData.id as string],
              });
          },
          onDeleted: handleDelete,
          onClose: handleClose,
          onRelayDeleted: (data: Partial<RelayStopFormData>) => {
            fetchMainData({
              nextFilters: filters,
              nextPageNumber: 1,
            });
            if (data.type === 'pickup') setSecondaryPanelData(null);
          },
          onUpdatedTrips: updateTripList,
          onUpdateActivitiesCompleted: () => {
            if (secondaryPanelData?.id)
              updateTripList({
                updatedIds: [secondaryPanelData.id as string],
              });
          },
          onCreatedLoadHandler: onCreatedLoadHandler,
        };
      default:
        return {};
    }
  };

  const handlePanelActions = (data?: any) => {
    if (!data) return;
    switch (secondaryPanelData?.type) {
      case ESecondaryDetailsPanelType.TRAILER:
      case ESecondaryDetailsPanelType.TRACTOR:
        if (!secondaryPanelData?.driverId) return;
        setRefreshDriverIdsList([secondaryPanelData?.driverId]);
        break;
      case ESecondaryDetailsPanelType.USER:
        const driverId = data?.currentUser?.isDriver
          ? data?.currentId
          : secondaryPanelData?.driverId;
        if (!driverId) return;
        setRefreshDriverIdsList([driverId]);
        break;
      case ESecondaryDetailsPanelType.TRIP:
        if (data?.brokerageTrip) {
          //Dont show Brokered trip at Dispatch page
          updateTripList({
            deletedIds: [data.id],
          });
        } else {
          //Bring Un-Brokered trip to the Grid again
          updateTripList({
            updatedIds: [data.id],
          });
        }
        break;
    }
  };
  return (
    <SecondaryDetailsPanel
      isGlobal={secondaryPanelData.isGlobal}
      autoExpanded={secondaryPanelData.autoExpanded}
      type={secondaryPanelData.type}
      id={secondaryPanelData.id}
      onClose={handleClose}
      defaultTab={secondaryPanelData.defaultTab}
      onUpdated={handlePanelActions}
      {...getOptions()}
    />
  );
};

export default observer(DispatchChildrenPanel);
