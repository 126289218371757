import {
  DatePicker as BaseDatePicker,
  DatePickerProps as BaseDatePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

const CustomizedDatePicker: React.FC<BaseDatePickerProps<any>> = ({
  slotProps,
  ...props
}) => {
  const modifiedSlotProps = {
    ...slotProps,
    textField: {
      ...(slotProps?.textField || {}),
      size: (slotProps?.textField as any)?.size || ('small' as const),
    },
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BaseDatePicker {...props} slotProps={modifiedSlotProps} />
    </LocalizationProvider>
  );
};

export { CustomizedDatePicker as DatePicker };
