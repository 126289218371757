import { Divider, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import IconsComponent from '../../../IconsComponent';
import { IGridBottomMenu, IGridBottomMenuOption } from '../../types';

export const GridBottomMenuItemClassName = 'gridBottomMenuActionClass';
export const GridBottomMenu = React.memo(
  ({
    menuOptions = [],
    sxProps = {},
    onClose,
  }: IGridBottomMenu & { onClose: () => void }) => {
    if (!menuOptions.length) return <></>;

    return (
      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          boxSizing: 'border-box',
          flexShrink: 0,
          position: 'fixed',
          zIndex: 1100,
          top: 'auto',
          left: 'auto',
          right: 0,
          bottom: 0,
          p: 1,
          borderRadius: 2,
          boxShadow:
            'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
          ...sxProps,
        }}
      >
        <MenuList
          sx={{
            pt: 2,
            pb: 2,
          }}
        >
          {menuOptions.map((option: IGridBottomMenuOption, idx: number) => {
            const Icon = option?.icon;
            return (
              <>
                <MenuItem
                  onClick={(event: React.MouseEvent) => {
                    option.onClickHandler?.({ option, event });
                    onClose();
                  }}
                  className={GridBottomMenuItemClassName}
                >
                  <ListItemIcon className={GridBottomMenuItemClassName}>
                    {Icon ? <Icon /> : null}
                    {option?.iconName ? (
                      <IconsComponent
                        iconName={option?.iconName}
                        source={option?.source}
                      />
                    ) : null}
                  </ListItemIcon>
                  <Typography
                    variant="inherit"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                    }}
                  >
                    {option.label}
                  </Typography>
                </MenuItem>
                {idx < menuOptions.length - 1 && (
                  <Divider orientation={'horizontal'} />
                )}
              </>
            );
          })}
        </MenuList>
      </Paper>
    );
  }
);
GridBottomMenu.displayName = 'GridBottomMenu';
