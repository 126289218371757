import { Box, BoxProps, SxProps } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledFinanceKPICard = styled(
  (props: BoxProps & { isMileage: boolean }) => <Box {...props} />
)(({ isMileage, theme }: { theme: any; isMileage: boolean }) => ({
  background: theme.palette.info.background,
  borderRadius: '8px',
  height: '122px',
  display: 'flex',
  flexDirection: 'row',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledFinanceKPICardLeft = styled((props: BoxProps) => (
  <Box {...props} />
))(({ sx }: { sx: SxProps }) => ({
  height: '100%',
  minWidth: '90px',
  justifyContent: 'center',
  alignItems: 'center',
  ...sx,
}));

export const StyledFinanceKPICardRight = styled(
  (props: BoxProps & { mileage?: string }) => <Box {...props} />
)(({ mileage }: { mileage?: string }) => ({
  paddingLeft: mileage ? '-8px' : '16px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
}));
