import { Moment } from 'moment';
import { EquipmentType } from '../../../../views/maintenanceRecords/constants/constants';

export interface IHistoryMaintenanceItem {
  id: string;
  itemCode: string;
  iconName: string;
  itemName: string;
}

interface IMaintenanceVendor {
  id: string;
  name: string;
}
export interface IAttachment {
  [key: string]: any;
}
export interface IMaintenanceHistory {
  id: string;
  organizationId: number;
  equipmentId: number;
  equipmentName: string;
  equipmentType: EquipmentType;
  terminalId?: string | null;
  terminalName?: string | null;
  historyMaintenanceItems: IHistoryMaintenanceItem[];
  maintenanceVendor: IMaintenanceVendor | null;
  completedDate: string;
  notes: string;
  attachments: IAttachment[] | null[]; // TODO: backend returns wrong format
  expense: number;
  odometer: number;
  status: string;
  categoryObj?: any;
  createExpense?: boolean;
  expenseCategoryId?: string;
  expenseCategoryKey?: string;
  expenseCategoryName?: string;
  expenseSeqNumber?: string;
  expenseId?: string;
}

export class MaintenanceHistory implements IMaintenanceHistory {
  id: string;
  organizationId: number;
  equipmentId: number;
  equipmentName: string;
  equipmentType: EquipmentType;
  terminalId: string;
  terminalName: string;
  historyMaintenanceItems: IHistoryMaintenanceItem[];
  maintenanceVendor: IMaintenanceVendor;
  completedDate: string;
  notes: string;
  attachments: IAttachment[];
  expense: number;
  odometer: number;
  createExpense?: boolean | undefined;
  categoryObj?: any;
  expenseId?: string;
  expenseCategoryId?: string;
  expenseCategoryKey?: string;
  expenseSeqNumber?: string;
  constructor(dto: any) {
    this.id = dto.id;
    this.organizationId = dto.organizationId;
    this.equipmentId = dto.equipmentId;
    this.equipmentName = dto.equipmentName;
    this.equipmentType = dto.equipmentType;
    this.terminalId = dto.terminalId;
    this.historyMaintenanceItems = dto.historyMaintenanceItems;
    this.maintenanceVendor = dto.maintenanceVendor;
    this.completedDate = dto.completedDate;
    this.notes = dto.notes;
    this.attachments = dto.attachments;
    this.expense = dto.expense;
    this.odometer = dto.odometer;
    this.terminalName = dto.terminalName;
    this.expenseId = dto?.expenseId;
    this.createExpense = dto?.createExpense;
    this.categoryObj = dto?.expenseCategoryId
      ? {
          id: dto?.expenseCategoryId,
          itemCode: dto?.expenseCategoryKey,
          itemName: dto?.expenseCategoryName,
        }
      : {};
    this.expenseCategoryId = dto?.expenseCategoryId;
    this.expenseCategoryKey = dto?.expenseCategoryKey;
    this.expenseSeqNumber = dto?.expenseSeqNumber;
  }
  status: string;
}

export interface MaintenanceFormData {
  id?: string;
  equipmentType: EquipmentType;
  maintenanceItems: IMaintenanceItemOption[];
  equipmentItem: IMaintenanceEquipmentOption;
  completedDate: Moment;
  terminalId: string;
  terminalName: string;

  // required on type === tractor
  odometer?: string;
  maintenanceVendor?: IMaintenanceVendorOption | null;
  schedule?: boolean;
  expense?: string;
  notes?: string;
  schedules?: {
    [itemCode: string]: {
      nextDate: Moment;
      nextOdo: string;
    };
  };
  createExpense?: boolean | undefined;
  categoryObj?: any;
  expenseId?: string;
  expenseCategoryId?: string;
  expenseCategoryKey?: string;
  expenseSeqNumber?: string;
  receipt: FileList | null;
}

export interface IMaintenanceVendorOption {
  label: string;
  value: string;
}

export interface IMaintenanceEquipmentOption {
  label: string;
  value: number;
  terminalId: string | null;
  terminalName: string | null;
}

export interface IMaintenanceItemOption {
  label: string;
  value: string;
  itemCode: string;
  iconName: string;
}

export class MaintenanceHistoryListTotal {
  totalAmount!: number;
}
