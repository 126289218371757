import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import TextInput from '../../ui-kit/components/TextField';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';

import AuthContext from '../../globalContexts/AuthContext';
import { UserLoginInfo } from '../../models';
import { decryptData, encryptData } from '../../utils';
import { emailValidation } from '../settings/terminals/constants/constants';

import { EPartner, formDefaultValues, loginConfig } from './constant';
import {
  StyledAuthFromWrapper,
  StyledFormTitle,
  SxPropAuthButton,
  getRegistrationStyles,
} from './styles';
import StorageManager from '../../StorageManager/StorageManager';
import { useRootStore } from '../../store/root-store/rootStateContext';
import { activateUserDriver } from '../../common/Layouts/util';
import {
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  ThemeProvider,
} from '@mui/material';
import { AuthIntroduction } from './components/AuthIntroduction';
import { regitrationPageStyle } from '@/views/register/RegistrationPageUtils';
import { AuthAppLogo } from '@/ui-kit/components/Assets';

const LoginForm = () => {
  const [loginFormValues, setLoginFormValues] = useState({
    ...formDefaultValues.login,
  });
  const location = useLocation();
  const { isMobile, themeLogin } = useThemeResponsive();
  const [form, setForm] = useState({ ...loginFormValues });
  const [error, setError] = useState({});
  const { loginUser } = useContext(AuthContext);
  const config = { ...loginConfig };
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    preventAttributeMutation();
    const data = StorageManager.getItem('login-data');
    if (data && typeof data === 'string') {
      const decryptedData = decryptData(data);
      if (decryptedData.rememberPassword) setForm(decryptedData);
      return;
    }
    if (typeof data !== 'string' && data) {
      localStorage.removeItem('login-data');
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlParams = {
      id: params.get('uid'),
      key: params.get('key'),
      organizationId: params.get('organizationId'),
    };
    const { id, key, organizationId } = urlParams;
    if (!id || !key || !organizationId) return;
    userActivation(id, key, organizationId);
  }, []);

  const userActivation = async (
    id: number,
    key: string,
    organizationId: number
  ) => {
    const response = await activateUserDriver(id, key, organizationId);
    if (response) {
      navigate('/login');
    }
  };

  const callApi = async () => {
    if (validate()) {
      setLoading(true);
      setLoginFormValues({ ...form });
      if (form.rememberPassword)
        encryptData({
          dot: form.dot,
          email: form.email,
          password: form.password,
          rememberPassword: form.rememberPassword,
        });
      else {
        localStorage.removeItem('login-data');
      }
      loginUser(
        new UserLoginInfo(form.dot.trim(), form.email.trim(), form.password)
      )
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const preventAttributeMutation = async () => {
    const inputPasswordElements = document.querySelectorAll(
      'input[type=password]'
    );
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'type'
        ) {
          mutation.target.value = '';
          setForm({
            dot: '',
            email: '',
            password: '',
            rememberPassword: false,
          });
        }
      });
    });
    Array.from(inputPasswordElements).forEach(function (input) {
      observer.observe(input, { attributes: true });
    });
  };

  const validate = () => {
    const toValid = config.form;
    const err = {};
    toValid.forEach((ele) => {
      ele.config.forEach((element) => {
        if (element.required && !form[element.name]) err[element.name] = true;
        else if (
          element.name === 'email' &&
          !emailValidation.test(form[element.name])
        )
          err[element.name] = element.label + ' is not valid.';
      });
    });
    setError(err);
    return isEmpty(err);
  };
  const [searchParams] = useSearchParams();

  const isExistPartnerUser: boolean = Object.values(EPartner).includes(
    searchParams.get('partner')
  );
  const styles = getRegistrationStyles({
    theme: themeLogin,
    isExistPartnerUser: isExistPartnerUser,
  });

  return (
    <ThemeProvider theme={themeLogin}>
      <Box className="Auth-root" sx={styles.authRoot}>
        {/* {location?.state?.changedPermission && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            message="Permission has expired, please login again"
            key={'topright'}
          />
        )} */}
        <Box className="Auth-backgroundImage" sx={styles.authBackgroundImage}>
          <Stack className="Auth-content" spacing={0} sx={styles.authContent}>
            <AuthIntroduction />

            <Box className="formWrapper" sx={styles.formWrapper}>
              <Box>
                <Box
                  className="formWrapperContent"
                  sx={styles.formWrapperContent}
                >
                  <StyledAuthFromWrapper>
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Box sx={regitrationPageStyle.logoStyle}>
                          <AuthAppLogo />
                        </Box>
                        <StyledFormTitle>{config.title}</StyledFormTitle>
                        <Box>
                          {config.form.map((block: any, index: number) => {
                            return (
                              <Box key={index} id="login-form-wrapper">
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  {block.config.map(
                                    (
                                      field: {
                                        name: string;
                                        label: string;
                                        inputType?: string;
                                        width?: string;
                                        options?: any;
                                        type: string;
                                        required: boolean;
                                      },
                                      i: number
                                    ) => {
                                      return (
                                        <Box
                                          key={i}
                                          sx={{
                                            width: field.width || '100%',
                                            marginBottom: '15px',
                                          }}
                                        >
                                          {field.type === 'checkbox' ? (
                                            <Box
                                              sx={() => ({
                                                ...themeLogin.typography.body1,
                                                color: 'text.primary',
                                              })}
                                            >
                                              <Checkbox
                                                checked={form[field.name]}
                                                onChange={(e) =>
                                                  setForm({
                                                    ...form,
                                                    [field.name]:
                                                      e.target.checked,
                                                  })
                                                }
                                              />
                                              {field.label}
                                            </Box>
                                          ) : (
                                            <TextInput
                                              fullWidth
                                              type={
                                                field.inputType || undefined
                                              }
                                              label={field.label}
                                              error={Boolean(error[field.name])}
                                              helperText={error[field.name]}
                                              onChange={(e: any) => {
                                                const newForm = {
                                                  ...form,
                                                  [field.name]:
                                                    e.target.value.trim(),
                                                };
                                                setForm(newForm);
                                                setError({
                                                  ...error,
                                                  [field.name]: '',
                                                });
                                              }}
                                              defaultValue={form[field.name]}
                                              variant="outlined"
                                              required={field.required}
                                              styleProps={{
                                                ...themeLogin.typography
                                                  .inputText,
                                                borderRadius: '14px',
                                                color: 'text.secondary',
                                              }}
                                              inputProps={{
                                                autoComplete: 'off',
                                              }}
                                              InputProps={{
                                                autoComplete: 'off',
                                              }}
                                            />
                                          )}
                                        </Box>
                                      );
                                    }
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={() => ({
                            ...themeLogin.typography.h6,
                            color: 'primary.main',
                            pb: '15px',
                            textAlign: 'center',
                            display: 'flex',
                            textAlign: 'center',
                          })}
                        >
                          <Link
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              marginLeft: '5px',
                            }}
                            to={{
                              pathname: '/forgot-password',
                              search: window?.location?.search,
                            }}
                          >
                            Forgot Password?
                          </Link>
                        </Box>
                        <Box
                          sx={() => ({
                            ...themeLogin.typography.h6,
                            color: 'text.primary',
                            pb: '15px',
                            textAlign: 'center',
                            display: 'flex',
                          })}
                        >
                          Don&apos; t have an account?
                          <Box
                            sx={{
                              color: 'primary.main',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                          >
                            <Link
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                marginLeft: '5px',
                              }}
                              to={{
                                pathname: '/registration',
                                search: window?.location?.search,
                              }}
                            >
                              Sign up!
                            </Link>
                          </Box>
                        </Box>

                        <Button
                          variant="contained"
                          disabled={isLoading}
                          onClick={callApi}
                          sx={{
                            ...SxPropAuthButton,
                            ...themeLogin.typography.h6,
                            ...(isMobile && {
                              width: '100%',
                            }),
                            ...(isLoading && {
                              background: '#3a4755 !important',
                              color: '#757e88 !important',
                            }),
                          }}
                          id="login-btn"
                          size="large"
                        >
                          {isLoading && (
                            <CircularProgress
                              size={22}
                              sx={{ ml: '-16px', mr: '22px' }}
                            />
                          )}{' '}
                          {isLoading ? 'Signing In' : 'Sign In'}
                        </Button>
                      </Box>
                    </>
                  </StyledAuthFromWrapper>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LoginForm;
