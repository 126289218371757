import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { StyledDivider } from '../../../styles';

export interface TripHeaderCardItemProps {
  title: React.ReactNode;
  subTitle: React.ReactNode;
}

export const TripHeaderCardItem = ({
  title,
  subTitle,
}: TripHeaderCardItemProps): JSX.Element => {
  const theme: any = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '150px',
        borderRadius: '8px',
        padding: '10px',
      }}
    >
      <Box>
        <Typography
          sx={{
            ...theme.typography.body2Bold,
            color: 'text.primary',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle2"
          sx={{
            color: 'text.secondary',
            ...theme.typography.smallTab,
          }}
        >
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export const TripHeaderCardRow = ({
  data,
}: {
  data: TripHeaderCardItemProps[];
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        padding: '10px',
      }}
    >
      {data.map(
        ({ title, subTitle }: TripHeaderCardItemProps, index: number) => (
          <Box key={index}>
            <TripHeaderCardItem title={title} subTitle={subTitle} />
          </Box>
        )
      )}
    </Box>
  );
};

interface TripHeaderContentProps {
  driver: React.ReactNode;
  tractor: React.ReactNode;
  trailer: React.ReactNode;
  dispatcher: React.ReactNode;
  terminal: React.ReactNode;
  contact: React.ReactNode;
}
export interface TripHeaderProps {
  title: React.ReactNode;
  renderHeaderContents: () => JSX.Element;
}

export const TripHeader = ({
  title,
  renderHeaderContents,
}: TripHeaderProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <StyledDivider textAlign="left" variant={'fullWidth'}>
        <Typography variant="body2">{title}</Typography>
      </StyledDivider>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          backgroundColor: 'uncategorized.accordion',
        }}
      >
        {renderHeaderContents()}
      </Box>
    </Box>
  );
};
