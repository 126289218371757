import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Header from './components/Header';
import KpiFooter from './components/KpiFooter';
import OptytmizationLoader from './components/OptymizationLoader';
import PossibleResourceFilter from './components/possibleResources/PossibleResourceFilter';
import PossibleResoure from './components/possibleResources/PossibleResourceGantt';
import RecommendTripFilters from './components/recommendedTrips/RecommendTripFilters';
import RecommendTripsGantt from './components/recommendedTrips/RecommendTripGantt';
import {
  BROKERED_TRIP_ID_PREFIX,
  LOCK_RESOURCE_KEYS,
  RECOMMENDED_RESOURCE_TYPE,
  REJECT_RESOURCE_KEYS,
  UNASSIGNED_TRIP_ID_PREFIX,
} from './constants/optymization.const';
import { useRecommendTrips } from './hooks';
import {
  convertTimeoffsMapToList,
  convertTripsMapToList,
  getRecommendationRequestParams,
  getRecommendationRequestPayload,
  getDefaultRequestParams,
  getFilteredRecommendedDrivers,
  isResourceTypeBrokeredTrip,
  getRecommendationLockAllSection,
} from './services/recommendTrips.service';
import { OPTYMIZATION_ACTIONS } from './store';
import {
  ActionBodyStyles,
  BodyContainerStyles,
  GANTT_WITHOUT_RESOURCES_STYLES,
  GANTT_WITH_RESOURCES_STYLES,
  RecommendTripsContainerStyles,
} from './styles';
import { PossibleResourcesWrapper } from './styles/possibleResources.style';
import BulkOptymizationModal from './wrappers/OptymizationModal';
import { IRecommendedResource } from './types/gantt.types';
import {
  filterRecommendationsByType,
  filterTripsByDriver,
} from './services/filters.service';
import { IGanttFilters } from '../../types/DispatchTypes';
import { useStores } from '../../store/root.context';
import {
  cancelBulkOptimizationRequest,
  lockAssignmentRequest,
  rejectAssignmentRequest,
} from './network/recommendTrips.request';
import {
  IBulkOptymization,
  LockOperationPayloadType,
} from './types/requestPayload.types';
import { AssignmentUpdateParamsType } from './types/possibleResource.types';
import {
  generateLockOperationPayload,
  getCurrentResourceLockActionType,
} from './services/operations.service';
import ErrorTemplate from './components/ErrorTemplate';
import SplitResizer, { Resizer } from './wrappers/SplitResizer';
import { GlobalNotificationType } from '../../constants/globalNotificationMessages';
import { toJS } from 'mobx';
import { ERecommendedTripStatus } from './constants/recommendedTrips.filters';

interface BulkOptymizationProps extends IBulkOptymization {
  onClose?: () => void;
}
const BulkOptymization: React.FC<BulkOptymizationProps> = ({
  driverIds,
  tripIds,
  driverFilters,
  tripFilters,
  onClose,
  previousSandboxId,
  resumePreviousOptimization,
}) => {
  const {
    bulkOptymizationStore: {
      recommendationFilters,
      showToast,
      resourceToReassign,
      setResourceToReassign,
      clearGlobalStore,
      setOptimizationStartDate,
      setCancelOptimization,
      setCurrentSandboxId,
      setKpiMetrics,
      setEnableReoptimization,
    },
    dispatch2GlobalStore: { setOnPreferenceUpdateCallback },
    evaluationStore: { stopEvaluation, setActionDisabled },
  } = useStores();
  const {
    recommendedTripsStore,
    updateRecommendTripsByAction,
    runOptimization,
  } = useRecommendTrips({
    requestPayload: getRecommendationRequestPayload({
      driverFilters,
      tripFilters,
      driverIds,
      tripIds,
    }),
    requestParams: getRecommendationRequestParams(
      driverFilters as IGanttFilters
    ),
    previousSandboxId,
    resumePreviousOptimization,
  });
  const sandboxRefId = useRef<any>(null);
  const resourceToReassignIdRef = useRef<any>(null);
  const statusFilterRef = useRef<any>(null);

  const getOptimizationParams = useCallback(
    (driverFilters: IBulkOptymization['driverFilters']) => {
      const { sort, sortDirection } = recommendationFilters;
      const filteredSort = sortDirection ? sort?.key : `-${sort?.key}`;
      return getDefaultRequestParams({
        ...driverFilters,
        sort: filteredSort as string,
      }) as IGanttFilters;
    },
    [recommendationFilters?.sort?.key, recommendationFilters?.sortDirection]
  );

  useEffect(() => {
    setOnPreferenceUpdateCallback(() => {
      allowReoptimization();
    });
  }, []);

  useEffect(() => {
    sandboxRefId.current = recommendedTripsStore?.sandboxId;
    setCurrentSandboxId(recommendedTripsStore?.sandboxId as string);
  }, [recommendedTripsStore?.sandboxId]);

  useEffect(() => {
    setOptimizationStartDate(driverFilters?.startDate as Date | string);
  }, [driverFilters?.startDate]);

  useEffect(() => {
    if (!sandboxRefId?.current) return;
    try {
      const fetchUpdatedResults = async () => {
        showGanttLoader();
        await runOptimization({
          params: getOptimizationParams(driverFilters),
          payload: {
            isRefreshOnlyFlag: true,
            sandboxId: sandboxRefId?.current,
          },
        });
        stopGanttLoader();
      };
      fetchUpdatedResults();
    } catch (error) {
      stopGanttLoader();
    }
  }, [recommendationFilters.sort?.key, recommendationFilters?.sortDirection]);

  useEffect(() => {
    if (recommendedTripsStore?.currentRejectedResource?.rejectedResources?.size)
      updateRecommendTripsByAction(
        OPTYMIZATION_ACTIONS.CLEAR_CURRENT_REJECTED_RESOURCE
      );
    statusFilterRef.current = recommendationFilters?.statuses?.map(
      (e) => e?.value
    );
  }, [recommendationFilters]);

  useEffect(() => {
    resourceToReassignIdRef.current = resourceToReassign?.id ?? null;
  }, [resourceToReassign?.id]);

  const allowReoptimization = () => setEnableReoptimization(true);

  const disableReoptimization = () => {
    setEnableReoptimization(false);
  };

  const showGanttLoader = () =>
    updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.START_LOADER);
  const stopGanttLoader = () => {
    // if (recommendedTripsStore?.isLoading)
    updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.STOP_LOADER);
  };
  const showAlert = ({ type, serviceName, message }: GlobalNotificationType) =>
    showToast({
      type,
      serviceName,
      message,
    });

  const handleRejectResource = async (
    driver: IRecommendedResource,
    resourceCurrentEl?: { cls?: string },
    rejectedResourceIndex?: number
  ) => {
    try {
      closePossibleResourcesGantt();
      const payload = {
        sandboxId: sandboxRefId?.current,
        userSandboxOperationsType: REJECT_RESOURCE_KEYS.REJECT,
        driverGroupId: driver?.id,
        tripId: driver?.engineMultiTripOutput?.tripIdSelected,
        currentAssignmentPreference:
          driver?.engineMultiTripOutput?.assignmentPreferenceType,
      };
      const {
        drivers,
        trips,
        timeoffs,
        lockedAssignmentMetrics,
        assignmentMetrics,
      } = (
        await rejectAssignmentRequest({
          data: payload,
          params: {
            organizationId: driverFilters?.organizationId,
          },
        })
      )?.data;
      updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.UPDATE_ASSIGNMENTS, {
        newDriverResources: drivers,
        newTrips: trips,
        timeoffs,
        actionType: 'REJECT',
        rejectedResourceIndex,
        rejectedResource: {
          ids: [driver?.id],
          resourceType: driver?.resourceType,
        },
      });
      setKpiMetrics({ assignmentMetrics, lockedAssignmentMetrics });
      stopEvaluation();
      allowReoptimization();
    } catch (error) {
      console.log('error: ', error);
      stopGanttLoader();
      if (resourceCurrentEl?.cls) resourceCurrentEl.cls = '';
      setActionDisabled(false);
      showAlert({
        type: 'FAILURE',
        serviceName: 'engineAssignmentRejectFailure',
      });
    }
  };
  const ganttLayoutStyles = useMemo(() => {
    if (resourceToReassign) return GANTT_WITH_RESOURCES_STYLES;
    return GANTT_WITHOUT_RESOURCES_STYLES;
  }, [JSON.stringify(resourceToReassign)]);

  const handleClosePossibleResources = async () => {
    try {
      closePossibleResourcesGantt();
    } catch (error) {
      stopGanttLoader();
    }
  };

  const getHeaderTitle = useMemo(
    () =>
      recommendedTripsStore?.isInitialLoad ? 'Beginning LoadAi' : 'Load Ai',
    [recommendedTripsStore?.isInitialLoad]
  );

  const handleLockResource = async (
    driver: IRecommendedResource,
    isAlreadyLocked?: boolean
  ) => {
    handleLockAssignment(!isAlreadyLocked, JSON.parse(JSON.stringify(driver)));
  };

  const lockAssignedResource = (
    resource: IRecommendedResource,
    updateAll = false
  ) => {
    updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.LOCK_ASSIGNED_CANDIDATE, {
      lockedResource: {
        lockForResourceId: resource?.id,
        type: LOCK_RESOURCE_KEYS.LOCK,
        updateAll,
        resourceType: resource?.resourceType,
        // ...rest,
      },
    });
  };
  const unlockAssignedResource = (resource: IRecommendedResource) => {
    updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.LOCK_ASSIGNED_CANDIDATE, {
      lockedResource: {
        lockForResourceId: resource?.id,
        type: LOCK_RESOURCE_KEYS.UNLOCK,
        updateAll: false,
        resourceType: resource?.resourceType,
      },
    });
  };

  const handleLockAssignment = async (
    isActionTypeLock: boolean,
    resource: IRecommendedResource | null,
    isBulkOperation = false
  ) => {
    closePossibleResourcesGantt();
    try {
      if (!isBulkOperation) {
        if (isActionTypeLock) lockAssignedResource(resource!);
        else unlockAssignedResource(resource!);
      }
      stopEvaluation();
      const {
        drivers = [],
        assignmentMetrics = {},
        lockedAssignmentMetrics = {},
      } = await lockAssignmentRequest({
        data: {
          sandboxId: sandboxRefId?.current,
          ...generateLockOperationPayload(
            // getCurrentResourceLockActionType(resource!),
            isActionTypeLock
              ? LOCK_RESOURCE_KEYS.LOCK
              : LOCK_RESOURCE_KEYS.UNLOCK,
            resource!,
            {
              isBulkOperation,
              ...getRecommendationLockAllSection(
                statusFilterRef.current,
                isBulkOperation
              ),
            }
          ),
        },
      });
      if (isBulkOperation)
        updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.LOCK_ASSIGNMENTS, {
          drivers,
        });
      setKpiMetrics({ assignmentMetrics, lockedAssignmentMetrics });
      allowReoptimization();
      stopGanttLoader();
    } catch (error: any) {
      console.log(error);
      stopGanttLoader();
      if (!isBulkOperation) {
        if (isActionTypeLock) unlockAssignedResource(resource!);
        else lockAssignedResource(resource!);
      }
      setActionDisabled(false);
      showAlert({
        type: 'FAILURE',
        serviceName: isActionTypeLock
          ? 'engineAssignmentLockFailure'
          : 'engineAssignmnetUnlockFailure',
      });
    }
  };

  const handleAllResourceLock = async (lock: boolean) => {
    showGanttLoader();
    closePossibleResourcesGantt();
    await handleLockAssignment(lock, null, true);
  };

  const handleCancelOptimization = () => {
    try {
      handleOptimizationClose();
      if (sandboxRefId?.current)
        cancelBulkOptimizationRequest({
          params: { sandboxId: sandboxRefId?.current },
        });
    } catch (error) {
      handleOptimizationClose();
    }
  };

  const allAssignmentsLocked = useMemo(() => {
    const assignedLockedResources =
      recommendedTripsStore?.assignedCandidatesLockedCount?.size ?? 0;
    const brokeredTripsLocked =
      recommendedTripsStore?.brokeredTripLockedCount?.size ?? 0;
    const filters = recommendationFilters?.statuses?.map(
      (filter) => filter.value
    );
    let currLockedResourceCount =
      recommendedTripsStore?.lockedRecommendations?.size ?? 0;
    const assignedCount =
      recommendedTripsStore?.drivers?.get(
        RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER
      )?.size ?? 0;
    const brokeredCount =
      recommendedTripsStore?.drivers?.get(
        RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP
      )?.size ?? 0;
    let totalActionableResources = assignedCount + brokeredCount;
    if (
      filters?.includes(ERecommendedTripStatus.Assigned) &&
      !filters?.includes(ERecommendedTripStatus.brokeredTrips)
    ) {
      currLockedResourceCount =
        currLockedResourceCount > assignedLockedResources
          ? currLockedResourceCount - brokeredTripsLocked
          : currLockedResourceCount;
      totalActionableResources -= brokeredCount;
    }
    if (
      !filters?.includes(ERecommendedTripStatus.Assigned) &&
      filters?.includes(ERecommendedTripStatus.brokeredTrips)
    ) {
      currLockedResourceCount =
        currLockedResourceCount > brokeredTripsLocked
          ? currLockedResourceCount - assignedLockedResources
          : currLockedResourceCount;
      totalActionableResources -= assignedCount;
    }
    if (
      currLockedResourceCount === totalActionableResources &&
      totalActionableResources !== 0
    )
      return true;
    return false;
  }, [
    recommendedTripsStore?.drivers?.get(
      RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER
    )?.size,
    recommendedTripsStore?.drivers?.get(RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP)
      ?.size,
    recommendedTripsStore?.assignedCandidatesLockedCount?.size,
    recommendedTripsStore?.brokeredTripLockedCount?.size,
    recommendedTripsStore?.lockedRecommendations?.size,
    recommendationFilters?.statuses?.length,
  ]);

  const handlePossibleAssignmentUpdate = (data: AssignmentUpdateParamsType) => {
    const {
      drivers,
      trips,
      timeoffs,
      assignmentMetrics,
      lockedAssignmentMetrics,
      actionType,
    } = data;
    if (data?.driverIdToDelete?.length)
      updateRecommendTripsByAction(
        OPTYMIZATION_ACTIONS.REMOVE_DRIVER_ASSIGNMENT,
        {
          rejectedResource: {
            ids: [
              ...data?.driverIdToDelete?.map(
                (id) => `${UNASSIGNED_TRIP_ID_PREFIX}${id}`
              ),
              ...data?.driverIdToDelete?.map(
                (id) => `${BROKERED_TRIP_ID_PREFIX}${id}`
              ),
            ],
          },
        }
      );
    updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.UPDATE_ASSIGNMENTS, {
      newDriverResources: drivers,
      newTrips: trips,
      timeoffs,
      actionType,
    });
    setKpiMetrics({
      assignmentMetrics: assignmentMetrics!,
      lockedAssignmentMetrics: lockedAssignmentMetrics!,
    });
    allowReoptimization();
  };

  const handleReOptymization = async () => {
    try {
      closePossibleResourcesGantt();
      disableReoptimization();
      updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.REOPTIMIZATION_START);
      await runOptimization({
        params: getOptimizationParams(driverFilters),
        payload: {
          isRefreshOnlyFlag: false,
          sandboxId: sandboxRefId?.current,
        },
      });
      updateRecommendTripsByAction(
        OPTYMIZATION_ACTIONS.REOPTIMIZATION_COMPLETED
      );
      showAlert({
        type: 'SUCCESS',
        serviceName: 'engineReoptimizationSuccess',
      });
    } catch (error) {
      console.log(error);
      stopGanttLoader();
      updateRecommendTripsByAction(
        OPTYMIZATION_ACTIONS.REOPTIMIZATION_COMPLETED
      );
      showAlert({
        type: 'FAILURE',
        serviceName: 'engineReoptimizationFailure',
      });
    }
  };
  const handleOptimizationClose = () => {
    onClose?.();
    clearGlobalStore();
    setCancelOptimization(false);
  };
  const handleRefreshOptimization = async () => {
    updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.RERUN_OPTIMIZATION);
    await runOptimization({
      payload: getRecommendationRequestPayload({
        driverFilters,
        driverIds,
        tripIds,
      }),
      params: getRecommendationRequestParams(driverFilters as IGanttFilters),
      isInitialLoad: true,
      previousSandboxId,
      resumePreviousOptimization,
    });
  };

  const disableAllResourceLock: boolean = useMemo(() => {
    const filteredResouces = filterRecommendationsByType(
      recommendedTripsStore?.drivers,
      recommendationFilters
    );
    return (
      filteredResouces?.get(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER)
        ?.size! > 0 ||
      filteredResouces?.get(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP)?.size! >
        0
    );
  }, [
    recommendedTripsStore?.drivers?.get(
      RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER
    )?.size,
    recommendedTripsStore?.drivers?.get(RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP)
      ?.size,
    recommendationFilters,
  ]);
  const handleSaveOptimization = (message?: string) => {
    showAlert({ type: 'SUCCESS', message });
    handleOptimizationClose();
  };

  const handleCloseConfirmPopup = () => {
    setCancelOptimization(false);
  };

  const closePossibleResourcesGantt = () => {
    if (resourceToReassignIdRef?.current) setResourceToReassign(null);
  };
  const handleRefreshStore = (data: object) => {
    updateRecommendTripsByAction(OPTYMIZATION_ACTIONS.CLEAR_OPTIMIZED_STORE);
    cancelSaveAndExit();
    showAlert({ type: 'SUCCESS', serviceName: 'engineOptimizationRestart' });
    updateRecommendTripsByAction(
      OPTYMIZATION_ACTIONS.SET_RECOMMENDED_TRIPS,
      data
    );
    setEnableReoptimization(false);
  };

  const cancelSaveAndExit = () => {
    closePossibleResourcesGantt();
  };
  const renderBody = () => {
    if (recommendedTripsStore?.optimizationFailed)
      return (
        <ErrorTemplate
          onCancel={() => {
            updateRecommendTripsByAction(
              OPTYMIZATION_ACTIONS.CLEAR_OPTIMIZATION_FAILURE
            );
            handleOptimizationClose();
          }}
          onRerun={handleRefreshOptimization}
        />
      );
    return (
      <Box sx={BodyContainerStyles}>
        <Box sx={ActionBodyStyles}>
          <SplitResizer enableResize={Boolean(resourceToReassign)}>
            <Box
              sx={{
                ...RecommendTripsContainerStyles,
                ...ganttLayoutStyles.RECOMMENDTRIPS_STYLES,
              }}
            >
              <RecommendTripFilters
                onReOptymize={handleReOptymization}
                isReOptymizationRunning={
                  recommendedTripsStore?.isReOptimizationRunning
                }
              />
              <RecommendTripsGantt
                drivers={getFilteredRecommendedDrivers(
                  recommendedTripsStore?.drivers,
                  recommendationFilters,
                  recommendedTripsStore?.currentRejectedResource
                )}
                trips={convertTripsMapToList(
                  filterTripsByDriver(
                    recommendedTripsStore?.trips,
                    recommendedTripsStore?.drivers,
                    recommendationFilters,
                    recommendedTripsStore?.currentRejectedResource
                  )
                )}
                timeoffs={convertTimeoffsMapToList(
                  recommendedTripsStore?.timeoffs
                )}
                onResourceLock={handleLockResource}
                onResourceReject={handleRejectResource}
                onAllResourceLock={handleAllResourceLock}
                disableAllResourceLock={disableAllResourceLock}
                isLoading={recommendedTripsStore?.isLoading}
                allRecordsLocked={allAssignmentsLocked}
              />
            </Box>
            <Resizer />
            <Box
              sx={{
                ...ganttLayoutStyles.POSSIBLERESOURCE_STYLES,
              }}
            >
              {resourceToReassign && (
                <Box sx={PossibleResourcesWrapper}>
                  <PossibleResourceFilter
                    type={resourceToReassign?.type}
                    onClose={handleClosePossibleResources}
                  />
                  <PossibleResoure
                    organizationId={driverFilters?.organizationId}
                    sandboxId={sandboxRefId?.current}
                    onAssignmentUpdate={handlePossibleAssignmentUpdate}
                  />
                </Box>
              )}
            </Box>
          </SplitResizer>
          <KpiFooter
            onConfirm={handleSaveOptimization}
            sandboxId={sandboxRefId?.current}
            onRestart={handleRefreshStore}
            onClosOptimization={handleCancelOptimization}
            onConfirmPopupCancel={handleCloseConfirmPopup}
          />
        </Box>
      </Box>
    );
  };
  if (recommendedTripsStore?.isInitialLoad)
    return (
      <OptytmizationLoader
        resumePreviousOptimization={resumePreviousOptimization}
        onClose={handleOptimizationClose}
      />
    );
  return (
    <BulkOptymizationModal slide={true}>
      <>
        <Header
          title={getHeaderTitle}
          onClose={handleCancelOptimization}
          containerStyles={{ padding: '10px 24px' }}
        />
        {renderBody()}
      </>
    </BulkOptymizationModal>
  );
};

export default observer(BulkOptymization);
