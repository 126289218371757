import { useFormContext } from 'react-hook-form';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import TractorController from '../../../../../subPages/tractors/Controller';
import { Tractor } from '../../../../../subPages/tractors/models/Tractor';
import { TractorSummary } from '../../../../../subPages/tractors/models/TractorSummary';
import { Trailer } from '../../../../../subPages/trailers/models/Trailer';
import { SingleAutocompleteForm } from '../../../../Ui/SingleAutocomplete';

const getNameCorrected = (name: string, documentType = false) => {
  const nameParts = documentType ? name.split('_') : name.split(' ');
  return nameParts
    .map(
      (namePart) => namePart[0].toUpperCase() + namePart.slice(1).toLowerCase()
    )
    .join(' ');
};

const getWarnings = (alertMessages) => {
  return alertMessages.reduce(
    (prevValue, currValue) =>
      currValue.issueType === 'CRITICAL'
        ? [
            ...prevValue,
            `Driver's ${getNameCorrected(
              currValue.documentType,
              true
            )} is due ${currValue.numberOfDays} days ago.`,
          ]
        : prevValue,
    []
  );
};

export const FormComponent = ({
  entity,
  entityData,
  handleWarningsLoad,
  handleWarningsDriver,
}: {
  entity: 'TRACTOR' | 'TRAILER';
  entityData: Tractor | Trailer;
  handleWarningsLoad: (warnings: boolean) => void;
  handleWarningsDriver: any;
}) => {
  const { control } = useFormContext();
  const { getGlobalTerminalsIds, getIsTerminalEnabled } = useRootStore();

  const getWarningsLoad = async (data: TractorSummary): Promise<void> => {
    let result;
    if (entity === 'TRACTOR') {
      result = await TractorController.instance().getDriverLoadWarnings({
        driverGroupId: data.id,
        tractorId: entityData.id,
        warningTypes: ['ShowDriverAssignmentWarning'],
      });
    } else {
      result = await TractorController.instance().getDriverLoadWarnings({
        driverGroupId: data.id,
        trailerId: entityData.id,
        warningTypes: ['ShowDriverAssignmentWarning'],
      });
    }

    handleWarningsLoad(result.warningsStates.ShowDriverAssignmentWarning);
  };

  const getWarningsDriver = async (data: TractorSummary): Promise<void> => {
    let result;
    if (entity === 'TRACTOR') {
      result = await TractorController.instance().getDriverMaintenanceWarnings({
        driverGroupId: data.id,
        scenarioType: 'ASSIGN_TRACTOR_TO_DRIVER',
      });
    } else {
      result = await TractorController.instance().getDriverMaintenanceWarnings({
        driverGroupId: data.id,
        scenarioType: 'ASSIGN_TRAILER_TO_DRIVER',
      });
    }

    const primaryWarnings = getWarnings(
      result.primaryDriverSafety.alertMessages
    );
    const secondaryWarnings = getWarnings(
      result.secondaryDriverSafety.alertMessages
    );
    handleWarningsDriver('setPrimaryWarnings', primaryWarnings);
    handleWarningsDriver('setSecondaryWarnings', secondaryWarnings);
  };

  const onDriverChange = (data) => {
    getWarningsLoad(data);
    getWarningsDriver(data);
    handleWarningsDriver(
      'setPrimaryDriver',
      getNameCorrected(data?.drivers[0].name)
    );
    if (data?.drivers[1]) {
      handleWarningsDriver(
        'setSecondaryDriver',
        getNameCorrected(data?.drivers[1].name)
      );
    } else {
      handleWarningsDriver('setSecondaryDriver', '');
    }
  };

  const getOptions = async (
    searchText: string | undefined,
    pageNumber: number
  ) => {
    let terminalIds = [];
    if (getIsTerminalEnabled) {
      terminalIds = getGlobalTerminalsIds || [];
    }
    const response = await TractorController.instance().getDriverGroups(
      searchText,
      pageNumber,
      terminalIds,
      true,
      entity === 'TRACTOR' ? 'hasNoTractorAssigned' : 'hasNoTrailerAssigned'
    );
    const updatedContent = response.content.filter((driver) => {
      return driver.displayName;
    });
    response.content = updatedContent;
    return response;
  };

  return (
    <SingleAutocompleteForm
      control={control}
      disableClear
      name="driverGroup"
      fieldName="displayName"
      label="Driver"
      onChangeCb={onDriverChange}
      getOptions={getOptions}
    />
  );
};
