import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import ButtonImproved from '../ButtonImproved';
import IconsComponent from '../IconsComponent';
import { SingleData, ToggleButtonGroupProps } from './interface';

export const ToggleButtonGroup = ({
  buttonList = [],
  onChangeList,
  selectedList = [],
  disabled,
}: ToggleButtonGroupProps) => {
  useEffect(() => {
    const temp = preSelected(selectedList, buttonList);
    setData(temp);
  }, [selectedList]);

  const preSelected = (sl: string[], bl: SingleData[]) => {
    const temp = [...bl];
    temp.forEach((item: any) => {
      if (sl.includes(item.key)) item.variant = 'contained';
      else item.variant = 'outlined';
    });
    return temp;
  };

  const [data, setData] = useState(
    selectedList && selectedList.length > 0
      ? preSelected(selectedList, buttonList)
      : buttonList
  );

  const onButtonClicked = (index: number) => {
    const temp = [...data];
    const currentVariant = temp[index].variant;
    temp[index] = {
      ...temp[index],
      variant: currentVariant == 'outlined' ? 'contained' : 'outlined',
    };
    setData(temp);
    const tempSelected = temp
      .map((item) => item.variant == 'contained' && item.key)
      .filter((value) => value);
    onChangeList(temp, tempSelected);
  };

  return (
    <Box>
      {data.map((item: SingleData, index: number) => (
        <ButtonImproved
          variant={item.variant}
          onClick={() => onButtonClicked(index)}
          startIcon={
            <IconsComponent
              iconName={
                item.variant == 'outlined'
                  ? item.disabledIconName
                  : item.enabledIconName
              }
              source={
                item.variant == 'outlined'
                  ? item.diabledSource
                  : item.enabledSource
              }
            />
          }
          label={item.label}
          styleProps={{ width: 'auto', marginLeft: 1, marginTop: 2 }}
          disabled={disabled}
        />
      ))}
    </Box>
  );
};
