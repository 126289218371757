import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { FC, useEffect } from 'react';
import Accordion from '../../../../../common/Accordion';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import MultilineCellAxele from '../../../../../ui-kit/components/MultilineCell';
import TextInput from '../../../../../ui-kit/components/TextField';
import { DirtyDetailsPanelManager } from '../../../../DetailsPanel/utils';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../../Permission';
import { useLoadPermission } from '../../../../hooks/useLoadPermission';
import { checkAll, switchText } from '../constants/constant';
import {
  AlertTypesSingleData,
  EditTypes,
  ListTypes,
} from '../constants/interface';
import { styles } from '../styles/styles';
import { ToggleButtonGroup } from './ToggleButtonGroup';

interface EditTrackerDetailsProps {
  item: ListTypes;
  onChangeFreeSolo: (
    event: any,
    newValue: string | null,
    select: boolean,
    item: ListTypes
  ) => void;
  onChangeData: (id: string, event: any, item: ListTypes) => void;
  onChangeSelectAll: (id: string, event: any, item: ListTypes) => void;
  onChangeList: (
    changedList: AlertTypesSingleData[],
    selectedList: string[],
    item: ListTypes
  ) => void;
  isSaved: boolean;
}

const EditTrackerDetails: FC<EditTrackerDetailsProps> = ({
  item,
  onChangeFreeSolo,
  onChangeData,
  onChangeSelectAll,
  onChangeList,
  isSaved,
}) => {
  const { hasLoadEditPermission } = useLoadPermission();
  useEffect(() => {
    DirtyDetailsPanelManager.panelName = !isSaved ? 'EditTracker' : null;

    DirtyDetailsPanelManager.isDirty = !isSaved;
  }, [isSaved]);

  useEffect(() => {
    return () => {
      DirtyDetailsPanelManager.panelName = null;
      DirtyDetailsPanelManager.isDirty = false;
    };
  }, []);

  const theme = useTheme();
  return (
    <Stack direction="column" spacing={1} width={500} ml={1} mr={1}>
      <Autocomplete
        disabled={!hasLoadEditPermission}
        id="name"
        freeSolo
        sx={{ width: '100%' }}
        value={item.name}
        options={item.contactList || []}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Name"
            variant="standard"
            error={item.nameValidation}
            required
          />
        )}
        onChange={(event: any, newValue: string | null) =>
          onChangeFreeSolo(event, newValue, true, item)
        }
        onInputChange={(event, newValue) => {
          if (event) onChangeFreeSolo(event, newValue, false, item);
        }}
        renderOption={(props, option: any) => (
          <li {...props}>{option.label}</li>
        )}
      />
      <TextInput
        disabled={!hasLoadEditPermission}
        id="email"
        label="Email"
        variant="standard"
        defaultValue={item.email ? item.email : ''}
        styleProps={{ width: '100%' }}
        onChange={(event: any) => onChangeData('email', event, item)}
        error={item.emailValidation}
        helperText={'Email is not valid.'}
      />
      <TextInput
        disabled={!hasLoadEditPermission}
        id="phone"
        label="Phone"
        variant="standard"
        InputProps={{ maxLength: 10 }}
        defaultValue={item.phone ? item.phone : ''}
        styleProps={{ width: '100%' }}
        onChange={(event: any) => onChangeData('phone', event, item)}
        error={item.phoneValidation}
        helperText={'Phone Number is not valid.'}
      />
      {item?.customValidation && (
        <Typography sx={{ color: theme.palette.error.main }}>
          Please enter a valid email or phone number
        </Typography>
      )}
      <Stack direction="row" spacing={2}>
        <AntSwitch
          checked={item.selectAll}
          onChange={(event: any) => onChangeSelectAll('selectAll', event, item)}
          inputProps={{ 'aria-label': 'ant design' }}
          sx={{ mt: 1 }}
          disabled={!hasLoadEditPermission}
        />
        <MultilineCellAxele
          title={switchText.title}
          subtitle={switchText.subTitle}
          titleStyle={styles.titleText}
          subtitleStyle={styles.subText}
          alignTitle="left"
          alignSubtitle="left"
        />
      </Stack>
      <ToggleButtonGroup
        buttonList={item.alertsType}
        selectedList={item.selectedList}
        onChangeList={(
          changedList: AlertTypesSingleData[],
          selectedList: string[]
        ) => onChangeList(changedList, selectedList, item)}
        disabled={!hasLoadEditPermission}
      />
      {item?.selecteValidation && (
        <Typography sx={{ color: theme.palette.error.main }}>
          Select atleast one notification
        </Typography>
      )}
    </Stack>
  );
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'primary.main',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const EditTracker = ({
  list = [],
  bulkList = [],
  setBulkList,
  onDeleteClicked,
}: EditTypes) => {
  const onChangeData = (id: string, event: any, item: ListTypes) => {
    item[id] = event.target.value;
    performBulkList(item);
  };

  const onChangeSelectAll = (id: string, event: any, item: ListTypes) => {
    item.selectAll = event.target.checked;
    item.selectedList =
      event.target.checked == true ? checkAll(item.alertsType) : [];
    performBulkList(item);
  };

  const onChangeFreeSolo = (
    event: any,
    newValue: any,
    select: boolean,
    item: ListTypes
  ) => {
    if (select) {
      item.name = newValue.fullName;
      item.email = newValue.email ? newValue.email : '';
      item.phone = newValue.phone ? newValue.phone : '';
      performBulkList(item);
    } else {
      item.name = newValue;
      performBulkList(item);
    }
  };

  const performBulkList = (item: ListTypes) => {
    let temp = [...bulkList];
    let found = false;
    temp.map((value: ListTypes) => {
      if (value.id == item.id) {
        value = item;
        found = true;
        return;
      }
    });
    if (found) {
      return;
    }
    {
      temp = [...temp, item];
    }
    setBulkList(temp);
  };

  const onChangeList = (
    changedList: AlertTypesSingleData[],
    selectedList: string[],
    item: ListTypes
  ) => {
    item.selectedList = selectedList;
    item.selectAll = item.selectedList.length == item.alertsType.length;
    performBulkList(item);
  };

  const renderSummary = (item: ListTypes) => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width={'100%'}
    >
      <Typography
        color={'primary.main'}
        align="left"
        fontSize={16}
        fontWeight={600}
      >
        {item.name ? item.name : ''}
      </Typography>
      <Permission contains={[AXELE_PERMISSION_TYPE.LOAD_EDIT]}>
        <IconButton color="error" onClick={() => onDeleteClicked(item)}>
          <IconsComponent iconName="DeleteOutlineOutlined" source="MUIcons" />
        </IconButton>
      </Permission>
    </Stack>
  );

  return (
    <Box sx={{ mb: '75px' }}>
      {list.map((item: ListTypes) => (
        <Box key={item.id} sx={styles.editMain}>
          <Accordion
            expanded={false}
            summaryRenderer={() => renderSummary(item)}
          >
            <EditTrackerDetails
              isSaved={!bulkList.length}
              item={item}
              onChangeFreeSolo={onChangeFreeSolo}
              onChangeList={onChangeList}
              onChangeData={onChangeData}
              onChangeSelectAll={onChangeSelectAll}
            />
          </Accordion>
        </Box>
      ))}
    </Box>
  );
};
