export const mockData = [
  {
    Age: '23',
    Dropoff: {
      city: 'Wichita',
      country: 'KS',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    LoadedMiles: {
      stops: '3 Stops',
      value: '4400mi',
    },
    MarketRate: {
      subvalue: '$1200-$2000',
      value: '$2/mi',
    },
    Mileage: {
      subvalue: '$1.2/total mi',
      value: '$1.5/loaded mi',
    },
    Offer: {
      profits: 'Profit: $1,400 , $500/day',
      value: '$3,500',
    },
    Pickup: {
      city: 'Orlando',
      country: 'FL',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    actions: {
      actionValue: 1,
      book: true,
      bookmark: true,
    },
    id: 1,
  },
  {
    Age: '23',
    Dropoff: {
      city: 'Wichita',
      country: 'KS',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    LoadedMiles: {
      stops: '3 Stops',
      value: '4400mi',
    },
    MarketRate: {
      subvalue: '$1200-$2000',
      value: '$2/mi',
    },
    Mileage: {
      subvalue: '$1.2/total mi',
      value: '$1.5/loaded mi',
    },
    Offer: {
      profits: 'Profit: $1,400 , $500/day',
      value: '$3,500',
    },
    Pickup: {
      city: 'New York',
      country: 'NY',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    actions: {
      actionValue: 2,
      book: false,
      bookmark: false,
    },
    id: 2,
  },
  {
    Age: '23',
    Dropoff: {
      city: 'Wichita',
      country: 'KS',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    LoadedMiles: {
      stops: '3 Stops',
      value: '4400mi',
    },
    MarketRate: {
      subvalue: '$1200-$2000',
      value: '$2/mi',
    },
    Mileage: {
      subvalue: '$1.2/total mi',
      value: '$1.5/loaded mi',
    },
    Offer: {
      profits: 'Profit: $1,400 , $500/day',
      value: '$3,500',
    },
    Pickup: {
      city: 'Orlando',
      country: 'FL',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    actions: {
      actionValue: 3,
      book: false,
      bookmark: true,
    },
    id: 3,
  },
  {
    Age: '23',
    Dropoff: {
      city: 'Wichita',
      country: 'KS',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    LoadedMiles: {
      stops: '3 Stops',
      value: '4400mi',
    },
    MarketRate: {
      subvalue: '$1200-$2000',
      value: '$2/mi',
    },
    Mileage: {
      subvalue: '$1.2/total mi',
      value: '$1.5/loaded mi',
    },
    Offer: {
      profits: 'Profit: $1,400 , $500/day',
      value: '$3,500',
    },
    Pickup: {
      city: 'Orlando',
      country: 'FL',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    actions: {
      actionValue: 1,
      book: false,
      bookmark: false,
    },
    id: 4,
  },
  {
    Age: '23',
    Dropoff: {
      city: 'Wichita',
      country: 'KS',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    LoadedMiles: {
      stops: '3 Stops',
      value: '4400mi',
    },
    MarketRate: {
      subvalue: '$1200-$2000',
      value: '$2/mi',
    },
    Mileage: {
      subvalue: '$1.2/total mi',
      value: '$1.5/loaded mi',
    },
    Offer: {
      profits: 'Profit: $1,400 , $500/day',
      value: '$3,500',
    },
    Pickup: {
      city: 'Orlando',
      country: 'FL',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    actions: {
      actionValue: 1,
      book: true,
      bookmark: false,
    },
    id: 5,
  },
  {
    Age: '23',
    Dropoff: {
      city: 'Wichita',
      country: 'KS',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    LoadedMiles: {
      stops: '3 Stops',
      value: '4400mi',
    },
    MarketRate: {
      subvalue: '$1200-$2000',
      value: '$2/mi',
    },
    Mileage: {
      subvalue: '$1.2/total mi',
      value: '$1.5/loaded mi',
    },
    Offer: {
      profits: 'Profit: $1,400 , $500/day',
      value: '$3,500',
    },
    Pickup: {
      city: 'Orlando',
      country: 'FL',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    actions: {
      actionValue: 1,
      book: false,
      bookmark: false,
    },
    id: 6,
  },
  {
    Age: '23',
    Dropoff: {
      city: 'Wichita',
      country: 'KS',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    LoadedMiles: {
      stops: '3 Stops',
      value: '4400mi',
    },
    MarketRate: {
      subvalue: '$1200-$2000',
      value: '$2/mi',
    },
    Mileage: {
      subvalue: '$1.2/total mi',
      value: '$1.5/loaded mi',
    },
    Offer: {
      profits: 'Profit: $1,400 , $500/day',
      value: '$3,500',
    },
    Pickup: {
      city: 'Orlando',
      country: 'FL',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    actions: {
      actionValue: 3,
      book: true,
      bookmark: true,
    },
    id: 7,
  },
  {
    Age: '23',
    Dropoff: {
      city: 'Wichita',
      country: 'KS',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    LoadedMiles: {
      stops: '3 Stops',
      value: '4400mi',
    },
    MarketRate: {
      subvalue: '$1200-$2000',
      value: '$2/mi',
    },
    Mileage: {
      subvalue: '$1.2/total mi',
      value: '$1.5/loaded mi',
    },
    Offer: {
      profits: 'Profit: $1,400 , $500/day',
      value: '$3,500',
    },
    Pickup: {
      city: 'Orlando',
      country: 'FL',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    actions: {
      actionValue: 1,
      book: false,
      bookmark: true,
    },
    id: 8,
  },
  {
    Age: '23',
    Dropoff: {
      city: 'Wichita',
      country: 'KS',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    LoadedMiles: {
      stops: '3 Stops',
      value: '4400mi',
    },
    MarketRate: {
      subvalue: '$1200-$2000',
      value: '$2/mi',
    },
    Mileage: {
      subvalue: '$1.2/total mi',
      value: '$1.5/loaded mi',
    },
    Offer: {
      profits: 'Profit: $1,400 , $500/day',
      value: '$3,500',
    },
    Pickup: {
      city: 'Orlando',
      country: 'FL',
      date: 'TUE 2/22',
      time: '14:00 50mi',
    },
    actions: {
      actionValue: 1,
      book: true,
      bookmark: true,
    },
    id: 9,
  },
];
