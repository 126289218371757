import { Box, SxProps } from '@mui/material';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Header } from './header';

interface IProps {
  file?: File;
  containerStyle?: SxProps;
  pdfStyles?: SxProps;
  allowActions?: boolean;
  actionsConfig?: {
    hideZoom?: boolean;
    hideRotate?: boolean;
    navigateActionContainerStyles?: SxProps;
  };
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const RotateAngles = {
  '0': 0,
  '1': 270,
  '2': 180,
  '3': 90,
} as { [key: string]: number };

const ScaleValues = {
  0: { value: 0.5, zoomLevel: '50%' },
  1: { value: 0.75, zoomLevel: '75%' },
  2: { value: 1, zoomLevel: '100%' },
  3: { value: 1.25, zoomLevel: '125%' },
  4: { value: 1.5, zoomLevel: '150%' },
  5: { value: 2, zoomLevel: '200%' },
} as { [key: number]: { value: number; zoomLevel: string } };

const PdfViewerStyles = {
  overflowX: 'auto',
  overflowY: 'hidden',
  border: `10px solid #5b5b5b`,
  borderTop: 'none',
  minHeight: '560px',
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    backgroundColor: 'uncategorized.panel',
    width: '10px',
    height: '10px',
  },
};

const PdfViewer = ({
  file,
  containerStyle,
  pdfStyles = {},
  allowActions = true,
  actionsConfig = {},
}: IProps) => {
  const [numPages, setNumPages] = useState<number>();
  const [currPage, setCurrPage] = useState<number>(1);
  const [rotateAngle, setRotateAngle] = useState<number>(0);
  const [currScale, setCurrScale] = useState<number>(2);

  const goToNextPage = () =>
    setCurrPage(currPage === numPages ? currPage : currPage + 1);
  const goToPrevPage = () =>
    setCurrPage(currPage === 1 ? currPage : currPage - 1);
  const handleZoomIn = () =>
    setCurrScale(currScale === 5 ? currScale : currScale + 1);
  const handleZoomOut = () =>
    setCurrScale(currScale === 0 ? currScale : currScale - 1);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  const rotatePdf = () => {
    setRotateAngle(rotateAngle === 3 ? 0 : rotateAngle + 1);
  };

  return (
    <Box sx={containerStyle}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {allowActions && (
          <Header
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
            currPage={currPage}
            totalPages={numPages}
            onRotateLeft={rotatePdf}
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
            zoomLevel={ScaleValues[currScale]?.zoomLevel}
            title={file?.name}
            hideZoom={actionsConfig?.hideZoom}
            hideRotate={actionsConfig?.hideRotate}
            navigateActionContainerStyles={
              actionsConfig?.navigateActionContainerStyles
            }
          />
        )}
        <Box
          id="pdf-viewer-container"
          sx={{ ...PdfViewerStyles, ...pdfStyles }}
        >
          <Page
            key={`page_${currPage}`}
            pageNumber={currPage}
            rotate={RotateAngles[`${rotateAngle}`]}
            scale={ScaleValues[currScale]?.value}
          />
        </Box>
      </Document>
    </Box>
  );
};

export default PdfViewer;
