import { Box, Button, SxProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { IAutocompleteOption } from '../../../views/trips/constants/types';

interface GridButtonGroupProps {
  id?: string;
  name: string;
  options: IAutocompleteOption[];
  defaultOptions?: IAutocompleteOption[];
  onChange?: (options: IAutocompleteOption[], name?: string) => void;
  isFormControl?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  ButtonSxProps?: SxProps;
}
export const GridButtonGroup = ({
  name,
  options,
  isFormControl,
  defaultOptions = [],
  onChange,
  ButtonSxProps = {},
}: GridButtonGroupProps) => {
  const [selectedOptions, setSelectedOptions] =
    useState<IAutocompleteOption[]>(defaultOptions);

  const hasSelected = (option: IAutocompleteOption): boolean => {
    return selectedOptions.some((op) => op.value === option.value);
  };

  const onClickHandler = (currOption: IAutocompleteOption) => {
    let newList = [];
    if (hasSelected(currOption)) {
      newList = selectedOptions.filter(
        (option) => option.value !== currOption.value
      );
    } else {
      newList = [...selectedOptions, currOption];
    }
    setSelectedOptions(newList);
    onChange?.(newList, name);
  };

  useEffect(() => {
    setSelectedOptions(defaultOptions);
  }, [defaultOptions]);

  return (
    <Box sx={{ width: '100%', display: 'flex', gap: '8px' }}>
      {options.map((option: IAutocompleteOption) => (
        <Button
          sx={{
            height: '28px !important',
            borderRadius: '6px',
            backgroundColor: hasSelected(option)
              ? 'primary.outlinedHoverBackground'
              : 'action.hover',
            color: hasSelected(option) ? 'primary.main' : 'text.secondary',
            borderColor: hasSelected(option)
              ? 'primary.main'
              : 'text.secondary',
            ...ButtonSxProps,
          }}
          variant={'outlined'}
          key={option.value}
          onClick={() => onClickHandler(option)}
        >
          <Typography
            variant="body2"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {option.label}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};
