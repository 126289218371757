import { FormControl, InputLabel } from '@mui/material';
import BaseSelect, { SelectProps } from '@mui/material/Select';

// Create Custom Propos Object that extends MUI defaults and adds any of our custom
type CustomSelectProps = SelectProps & {
  isPlain?: boolean;
};

// Define our customized Select Component
const CustomizedComponent = ({
  size = 'small',
  isPlain = false,
  ...props
}: CustomSelectProps) => {
  // Create the styles for the plain (borderless) version of our Select component
  const plainSelectStyles = isPlain
    ? {
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
      }
    : {};

  // Create a merged styles object that takes any additional MUI sx props and combines them with the plainSelectStyles. This allows us to assign both together in ones sx prop below
  const mergedSxStyles = { ...plainSelectStyles, ...props.sx };

  return (
    <FormControl size={size} sx={props.sx}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <BaseSelect size={size} {...props} sx={mergedSxStyles}></BaseSelect>
    </FormControl>
  );
};

// Export our customized component with the default name Select, so that when it is imported from @components, ours will be used.
export { CustomizedComponent as Select };
