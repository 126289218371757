import { Box, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { BREAKPOINT_LAPTOP } from '../../../common/hooks/useThemeResponsive';
import { StyledAuthIntro, StyledAuthIntroForPartner } from '../styles';
import { AuthIntro } from './intro.constant';
import { AuthPowerByIcon } from '../../../ui-kit/components/Assets';

export const AuthIntroduction = () => {
  const [searchParams] = useSearchParams();
  const source: string = searchParams.get('partner');
  const authIntro = AuthIntro[source] || null;

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        [theme.breakpoints.down(BREAKPOINT_LAPTOP)]: {
          display: 'none',
        },
      })}
    >
      <Box width={'100%'} height={'100%'}>
        {authIntro ? (
          <Stack
            width={'100%'}
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            pt={4}
            pl={7}
            pr={7}
          >
            <StyledAuthIntro
              sx={{
                color: '#32C8C0',
              }}
            >
              {authIntro.intro}
            </StyledAuthIntro>

            <Typography
              sx={{
                fontFamily: 'Oxanium',
                fontSize: '32px',
                fontWeight: '600',
                lineHeight: '45px',
                textAlign: 'left',
                color: '#669EFF',
                pt: 2,
              }}
            >
              {authIntro.intro1}
            </Typography>
            <Box
              sx={{
                marginLeft: '24px !important',
              }}
            >
              {authIntro.list.map((introStr: string, idx: number) => (
                <StyledAuthIntroForPartner key={idx} component={'li'}>
                  {introStr}
                </StyledAuthIntroForPartner>
              ))}
            </Box>
            <StyledAuthIntroForPartner
              sx={{
                textIndent: 0,
              }}
            >
              {authIntro.outro}
            </StyledAuthIntroForPartner>
          </Stack>
        ) : (
          <Box width={'100%'} height={'100%'}>
            <Stack
              className="intro"
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              pl={6}
              pt={6}
              height={'90%'}
            >
              <StyledAuthIntro>Run all your trucking</StyledAuthIntro>
              <StyledAuthIntro>operations in one place</StyledAuthIntro>
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  );
};
