import {
  HeadsetMicOutlined,
  LocationOn,
  LoopOutlined,
} from '@mui/icons-material';
import { Box, Skeleton, Stack, Theme, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import DriverGroupAssignment, {
  IDriverGroupAssignmentTrip,
} from '../../../../common/LoadTabPanel/tabs/Routes/components/DriverGroupAssignment';
import { ganttTimelineModule } from '../../../../common/PendoClassIDs/constants';
import { ITimeoffDialogData } from '../../../../common/TimeoffDialog/types';
import PopupMap from '../../../../common/Ui/Maps/PopupMap/PopupMap';
import { MarkersData } from '../../../../common/Ui/Maps/types';
import { IHOSDialogloadData } from '../../../../common/UpdateHOS';
import { useDispatchPermission } from '../../../../common/hooks/useDispatchPermission';
import { DriverTeamWithTrips, GetLastHosInfoRequest } from '../../../../models';
import {
  GetDispatchDetailsPanelMapDataRequest,
  GetDispatchDetailsPanelRequest,
} from '../../../../models/DTOs/Dispatch/Requests';
import {
  EDispatchDriverTimelineTripType,
  IDispatchDriverTimeline,
  IDispatchDriverTimelineTrip,
} from '../../../../models/DTOs/Dispatch/Timeline';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { DriverIcon } from '../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import { debounceTime } from '../../constants/dispatch';
import {
  EGanttTimelineReOrder,
  ESecondaryDetailsPanelType,
  SecondaryDetailsOpenProps,
} from '../../constants/types';
import { useDispatchDetailsPanelSettings } from '../../context/DispatchDetailsPanelProvider';
import DispatchController from '../../utils/Controller';
import { GanttCardAlert } from './CardAlert';
import { GanttCardList } from './GanttCardList';
import { StyledSectionHeader } from './styles';

export interface GanttTimelineProps extends Partial<SecondaryDetailsOpenProps> {
  driverTeam?: DriverTeamWithTrips;
  onUpdate?: () => void;
}

export const GanttTimeline = memo(
  ({
    driverTeam,
    handleSecondaryDetailsOpen,
    onUpdate,
  }: GanttTimelineProps): JSX.Element => {
    const { trackPromise } = useDetailsPanelStore;
    const { getPendingDispatchDriverGroupIds } = useRootStore();

    const { hasDispatchEditPermission } = useDispatchPermission();
    const { lastUpdateTime, onChangedDetailsPanel } =
      useDispatchDetailsPanelSettings();
    const controller = DispatchController.instance();
    const [driverTimeline, setDriverTimeline] =
      useState<IDispatchDriverTimeline | null>(null);

    const [updateHOSDialogData, setUpdateHOSDialogData] =
      useState<IHOSDialogloadData | null>(null);
    const [openMapPopup, setOpenMapPopup] = useState<boolean>(false);
    const [mapData, setMapData] = useState<MarkersData>();

    const [driverList, setDriverList] = useState<
      { driverId: number; name: string }[]
    >([]);
    const [reOrder, setReOrder] = useState<EGanttTimelineReOrder | null>(null);
    const [waitForTripPlanNotification, setWaitForTripPlanNotification] =
      useState<boolean>(false);
    const [driverGroupWarnings, setDriverGroupWarnings] = useState<
      Record<string, Record<string, string[]>>
    >({});

    const theme: Theme = useTheme();
    const { t, ready } = useTranslation();

    useEffect(() => {
      if (!getPendingDispatchDriverGroupIds?.length) return;
      getDispatchDetailsPanel();
    }, [JSON.stringify(getPendingDispatchDriverGroupIds)]);

    useEffect(() => {
      if (driverTeam) {
        const timeline = {
          driverGroupId: driverTeam.driverGroupId,
          driverGroupName: driverTeam.driverGroupName,
          dispatcherId: driverTeam.dispatcherId,
          dispatcherName: driverTeam.dispatcherName,
          organizationId: driverTeam.organizationId,
          currentTrip: driverTeam.currentTrip,
          assignedTrips: driverTeam.assignedTrips,
          futureTrips: driverTeam.futureTrips,
        } as IDispatchDriverTimeline;

        setDriverTimeline(timeline);
      }
      setWaitForTripPlanNotification(false);
      setReOrder(null);
    }, [driverTeam?.id]);

    const timeoffDialogData: ITimeoffDialogData = {
      driverGroupId: driverTeam?.id,
      driverName: driverTeam?.displayName,
      tractorId: driverTeam?.assetAssignment?.tractorId,
      trailerId: driverTeam?.assetAssignment?.trailerId,
      driverId: driverTeam?.drivers?.[0]?.id,
    };

    const titleAlert = () => {
      if (updateHOSDialogData && updateHOSDialogData?.isShowHOSWarning) {
        return `${t('titleAlertDriver')} (${
          driverTeam?.displayName || '--'
        }) ${t('titleAlertHosorLocationneedsupdating')}`;
      } else {
        return `${t('titleAlertUpdateDriver')} (${
          driverTeam?.displayName || '--'
        }) ${t('titleAlertLocationorHOS')}`;
      }
    };

    const getDispatchDetailsPanel = async () => {
      if (!driverTeam?.id) return;
      const response = await trackPromise(
        controller.getDispatchDetailsPanel(
          new GetDispatchDetailsPanelRequest({
            groupId: driverTeam?.id,
          })
        ),
        ESecondaryDetailsPanelType.DISPATCH
      );
      if (response !== null) {
        setDriverTimeline(response as IDispatchDriverTimeline);
      }
      setWaitForTripPlanNotification(false);
      setReOrder(null);
    };

    const getDispatchDetailsMapData = async () => {
      if (!driverTeam?.id) return;
      const response = await controller.getDispatchDetailsPanelMapData(
        new GetDispatchDetailsPanelMapDataRequest({
          groupId: driverTeam?.id,
        })
      );

      setMapData(response as unknown as MarkersData);
    };

    const getWarnings = async () => {
      if (!driverTeam?.id) return;
      const warnings = await loadService.getDriverGroupWarnings(driverTeam.id);
      if (!(warnings instanceof ServiceError)) {
        setDriverGroupWarnings(warnings);
        setUpdateHOSDialogData({
          driverGroupId: driverTeam?.id,
          driverId: driverTeam?.drivers?.[0]?.id,
          ignoreHOS: false,
          isShowHOSWarning: true,
        });
      }
    };
    const getHOS = async () => {
      if (!driverTeam?.id) return;
      const ignoreHosInfoResponse = await trackPromise(
        loadService.getIgnoreHosInfo(
          new GetLastHosInfoRequest({
            ids: [driverTeam?.id],
          }),
          true
        ),
        ESecondaryDetailsPanelType.DISPATCH
      );
      const lastHosInfoResponse = await trackPromise(
        loadService.getLastHosInfo(
          new GetLastHosInfoRequest({
            ids: [driverTeam?.id],
          }),
          true
        ),
        ESecondaryDetailsPanelType.DISPATCH
      );
      let ignoreHOS = false,
        isLocationOutdated = false;
      if (!(ignoreHosInfoResponse instanceof ServiceError)) {
        if (
          ignoreHosInfoResponse &&
          Array.isArray(ignoreHosInfoResponse) &&
          ignoreHosInfoResponse.length > 0
        ) {
          ignoreHOS = ignoreHosInfoResponse[0]?.ignoreHOSRestrictions
            ? true
            : false;
          isLocationOutdated = ignoreHosInfoResponse[0]?.isLocationOutdated;
        }
      }
      if (lastHosInfoResponse instanceof ServiceError) {
        //handling error API
      } else {
        const hos = lastHosInfoResponse.mapping[driverTeam?.id] || null;
        const isOutdated = hos === null || !!hos?.hosOutdatedWarning;
        const isShowHos = hos
          ? (isOutdated && !ignoreHOS) || isLocationOutdated
          : isLocationOutdated;
        setUpdateHOSDialogData({
          driverGroupId: driverTeam?.id,
          driverId: driverTeam?.drivers?.[0]?.id,
          isShowHOSWarning: isShowHos ? true : false,
          ignoreHOS: ignoreHOS,
        });
      }
    };

    const fetchDriverTeams = async (): Promise<void> => {
      if (driverTeam) {
        const newDriverList =
          driverTeam?.drivers?.map((item) => ({
            driverId: item.id,
            name: item.name,
          })) || [];
        setDriverList(newDriverList);
      }
    };

    const init = async (): Promise<void> => {
      fetchDriverTeams();
      getWarnings();
    };

    const onChangedDetailsPanelHandler = async (): Promise<void> => {
      onChangedDetailsPanel();
      if (onUpdate) {
        onUpdate();
      }
    };

    const onCloseUpdateHOSDialogData = async (): Promise<void> => {
      setUpdateHOSDialogData(null);
      getHOS();
    };

    const getTripAssignment = (): IDriverGroupAssignmentTrip => {
      const data: IDriverGroupAssignmentTrip = {
        id: undefined,
        driverGroupId: driverTeam?.id,
        tractor: null,
        trailer: null,
      };
      if (driverTeam?.assetAssignment?.tractorId) {
        data.tractor = {
          id: driverTeam?.assetAssignment?.tractorId,
          name: driverTeam?.assetAssignment?.tractorName,
        };
      }
      if (driverTeam?.assetAssignment?.trailerId) {
        data.trailer = {
          id: driverTeam?.assetAssignment?.trailerId,
          name: driverTeam?.assetAssignment?.trailerName,
        };
      }

      return data;
    };

    const onAfterUpdateHOS = async (): Promise<void> => {
      //@Sinh comment this code, will check later. Switch to refresh when received new notification
      // onChangedDetailsPanelHandler();
      // refreshGanttDriverLocationMarkers?.();
    };

    const onAfterUpdateTripAction = async (): Promise<void> => {
      controller.setSkipTrackPromise(true);
      getDispatchDetailsPanel();
    };
    const onAfterUpdateTimeOffAction = async (): Promise<void> => {
      controller.setSkipTrackPromise(true);
      getDispatchDetailsPanel();
    };

    const onRegenerateTimeline = debounce(() => {
      const groupId = driverTeam?.id;
      if (!groupId) return;
      controller.regenerateTimeline({ groupId });
    }, debounceTime);

    useEffect(() => {
      if (!driverTeam?.id) return;
      init();
      //Important: DependencyList should be primitive types
    }, [driverTeam?.id, driverTeam?.groupName, lastUpdateTime]);

    const onCancelledGanttCardList = () => {
      setReOrder(null);
    };

    const onAfterReorder = () => {
      setWaitForTripPlanNotification(true);
    };

    const canShowReorderButton = (
      tripList: IDispatchDriverTimelineTrip[]
    ): boolean => {
      return (
        tripList?.filter?.(
          (item) => item?.type !== EDispatchDriverTimelineTripType.GAP
        ).length > 1 && !reOrder
      );
    };

    if (!driverTimeline) {
      return (
        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          marginTop={2}
          alignItems="center"
        >
          <Skeleton
            variant="rectantextgular"
            height={'1rem'}
            width={'95%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectantextgular"
            height={'1rem'}
            width={'95%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectangular"
            height={'3rem'}
            width={'95%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectangular"
            height={'3rem'}
            width={'95%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectangular"
            height={'3rem'}
            width={'95%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectantextgular"
            height={'1rem'}
            width={'95%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectangular"
            height={'6rem'}
            width={'95%'}
            sx={{
              borderRadius: '5px',
            }}
          />
          <Skeleton
            variant="rectangular"
            height={'6rem'}
            width={'95%'}
            sx={{
              borderRadius: '5px',
            }}
          />
        </Stack>
      );
    }

    if (ready) {
      return (
        <Box sx={{ height: '100%', p: 2 }}>
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            width="100%"
            height={'100%'}
            mt={2}
          >
            {updateHOSDialogData && (
              <GanttCardAlert
                title={titleAlert}
                loadData={updateHOSDialogData}
                onClose={onCloseUpdateHOSDialogData}
                onActionResponse={onAfterUpdateHOS}
                driverGroupWarnings={driverGroupWarnings}
              />
            )}

            <Stack
              direction="row"
              spacing={2}
              width="100%"
              alignItems="flex-start"
              justifyContent={
                !driverList?.length ? 'flex-end' : 'space-between'
              }
            >
              {driverList.map((item) => (
                <ButtonImproved
                  id={ganttTimelineModule + 'Name' + item.driverId}
                  styleProps={{ width: '100%' }}
                  key={`${item.driverId}-${item.name}`}
                  color="primary"
                  label={item.name}
                  onClick={() => {
                    handleSecondaryDetailsOpen?.(
                      item.driverId,
                      ESecondaryDetailsPanelType.USER,
                      {
                        isGlobal: true,
                      }
                    );
                  }}
                  startIcon={
                    <DriverIcon
                      width={20}
                      height={20}
                      color={theme.palette.primary.main}
                      opacity={1}
                    />
                  }
                  variant="outlined"
                />
              ))}

              <Box
                sx={{
                  width: '38px',
                  height: '30px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  getDispatchDetailsMapData();
                  //Ony call Map data when user click on Map Icon. Improve the load at first time of DispatchDetailsPanel
                  setOpenMapPopup(true);
                }}
              >
                <LocationOn
                  color="primary"
                  fontSize="large"
                  sx={{
                    height: '30px',
                  }}
                />
              </Box>
            </Stack>

            {driverTimeline?.dispatcherId && (
              <Stack
                direction="column"
                spacing={2}
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <ButtonImproved
                  id={ganttTimelineModule + 'DispatcherName'}
                  styleProps={{
                    width: '100%',
                    backgroundColor: 'secondary.outlinedHoverBackground',
                    border: '1px solid',
                    borderColor: 'secondary.outlinedRestingBorder',
                    color: 'secondary.main',
                  }}
                  label={
                    driverTimeline?.dispatcherName ||
                    driverTimeline?.dispatcherId
                  }
                  onClick={() => {
                    handleSecondaryDetailsOpen?.(
                      driverTimeline?.dispatcherId,
                      ESecondaryDetailsPanelType.USER
                    );
                  }}
                  startIcon={
                    <HeadsetMicOutlined
                      sx={{
                        color: 'secondary.main',
                      }}
                    />
                  }
                  variant="outlined"
                />
              </Stack>
            )}

            <PopupMap
              openPopup={openMapPopup}
              handleClose={() => setOpenMapPopup(false)}
              markersList={mapData ? mapData.markers : []}
              generateRoute
            />
            <Stack direction="row" spacing={2} width={'100%'}>
              <DriverGroupAssignment
                trip={getTripAssignment()}
                onChangedHandler={onChangedDetailsPanelHandler}
              />
            </Stack>

            <Stack direction="column" spacing={2} width={'100%'}>
              <StyledSectionHeader
                sx={{
                  width: '100%',
                  paddingLeft: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {t('GanttTimelineCurrent')}
                <ButtonImproved
                  id={ganttTimelineModule + 'RegenerateTimeline'}
                  size="medium"
                  color="primary"
                  label={t('GanttTimelineRegenerateTimeline')}
                  onClick={onRegenerateTimeline}
                  startIcon={<LoopOutlined />}
                  variant="outlined"
                  disabled={!hasDispatchEditPermission}
                />
              </StyledSectionHeader>
              <GanttCardList
                timeoffDialogData={timeoffDialogData}
                tripList={driverTimeline?.currentTrip}
                isCurrent={true}
                handleSecondaryDetailsOpen={handleSecondaryDetailsOpen}
                emptyDescription={`${t('GanttCardListEmptyDescription')}`}
                driverTeam={driverTeam}
                onCancelled={onCancelledGanttCardList}
                onAfterReorder={onAfterReorder}
                onAfterUpdateTripAction={onAfterUpdateTripAction}
                onAfterUpdateTimeOffAction={onAfterUpdateTimeOffAction}
              />
            </Stack>

            <Stack direction="column" spacing={2} width={'100%'}>
              <StyledSectionHeader
                sx={{
                  width: '100%',
                  paddingLeft: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {reOrder === EGanttTimelineReOrder.Assigned
                  ? t('GanttTimelineReorderFutureTrips')
                  : t('GanttTimelineFuture')}
                {canShowReorderButton(driverTimeline?.assignedTrips) && (
                  <ButtonImproved
                    id={ganttTimelineModule + 'AssignedReorderTimeline'}
                    size="medium"
                    color="primary"
                    label={t('GanttTimelineReorder')}
                    onClick={() => setReOrder(EGanttTimelineReOrder.Assigned)}
                    startIcon={<LoopOutlined />}
                    variant="outlined"
                    disabled={!hasDispatchEditPermission}
                  />
                )}
              </StyledSectionHeader>
              <GanttCardList
                timeoffDialogData={timeoffDialogData}
                tripList={driverTimeline?.assignedTrips}
                currentTripList={driverTimeline?.currentTrip}
                isCurrent={false}
                handleSecondaryDetailsOpen={handleSecondaryDetailsOpen}
                emptyDescription={`${t('GanttCardListEmptyDescription1')}`}
                driverTeam={driverTeam}
                isReOrderActive={reOrder === EGanttTimelineReOrder.Assigned}
                waitForTripPlanNotification={waitForTripPlanNotification}
                onCancelled={onCancelledGanttCardList}
                onAfterReorder={onAfterReorder}
                onAfterUpdateTripAction={onAfterUpdateTripAction}
                onAfterUpdateTimeOffAction={onAfterUpdateTimeOffAction}
              />
            </Stack>
          </Stack>
        </Box>
      );
    }
  }
);

GanttTimeline.displayName = 'GanttTimeline';
