/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ServiceError } from './helperTypes';
import { Service } from './Service';
import {
  ELDRequest,
  ActivateCustomerRequest,
  DeactivateAccountRequest,
  RefreshAccountRequest,
  EditAccountRequest,
  TractorNamesRequest,
  GetDriverNamesRequest,
  DriverNamesResponseDTO,
  UnlinkDriverRequest,
  GetDriverSummaryRequest,
  LinkDriverRequest,
  LinkDriverValidateRequest,
  GetAxeleTractorsRequest,
  LinkTractorRequest,
  LinkTractorValidateRequest,
  UnlinkTractorRequest,
  CreateMultipleTractorRequest,
  CreateTractorRequest,
  CreateDriverRequest,
  CreateMultipleDriversRequest,
  TractorNamesResponseDTO,
  DriverSummaryResponseDTO,
  AxeleTractorResponseDTO,
  ELDResponseDTO,
  LinkTractorResponseDTO,
  LinkDriverResponseDTO,
  LinkValidateResponseDTO,
  UnlinkDriverResponseDTO,
  ActivateCustomerResponseDTO,
} from '../../models/DTOs/ELD/Requests';
export abstract class IELDService extends Service {
  abstract getEldProvdersList(
    requestData: ELDRequest
  ): Promise<ELDResponseDTO | null | ServiceError>;

  abstract activateProvider(
    requestData: ActivateCustomerRequest
  ): Promise<ActivateCustomerResponseDTO | null | ServiceError>;

  abstract deleteAccount(
    requestData: DeactivateAccountRequest
  ): Promise<DeactivateAccountRequest | null | ServiceError>;

  abstract refreshAccount(
    requestData: RefreshAccountRequest,
    suffixUrl: string
  ): Promise<ELDResponseDTO | null | ServiceError>;

  abstract editAccount(
    requestData: EditAccountRequest
  ): Promise<EditAccountRequest | null | ServiceError>;

  abstract validateAccount(
    requestData: EditAccountRequest
  ): Promise<EditAccountRequest | null | ServiceError>;
  abstract getAllDrivers(
    requestData: GetDriverNamesRequest
  ): Promise<DriverNamesResponseDTO>;

  abstract getAllTractors(
    requestData: TractorNamesRequest
  ): Promise<TractorNamesResponseDTO>;

  abstract unlinkDriver(
    requestData: UnlinkDriverRequest
  ): Promise<UnlinkDriverResponseDTO>;

  abstract getDriverSummary(
    requestData: GetDriverSummaryRequest
  ): Promise<DriverSummaryResponseDTO>;

  abstract linkDriver(
    requestData: LinkDriverRequest
  ): Promise<LinkDriverResponseDTO>;

  abstract linkDriverValidate(
    requestData: LinkDriverValidateRequest
  ): Promise<LinkValidateResponseDTO>;

  abstract linkTractorValidate(
    requestData: LinkTractorValidateRequest
  ): Promise<LinkValidateResponseDTO>;

  abstract getAxeleTractors(
    requestData: GetAxeleTractorsRequest
  ): Promise<AxeleTractorResponseDTO>;

  abstract linkTractor(
    requestData: LinkTractorRequest
  ): Promise<LinkTractorResponseDTO>;

  abstract unlinkTractor(
    requestData: UnlinkTractorRequest
  ): Promise<UnlinkTractorRequest>;

  abstract createMultipleTractor(
    requestData: CreateMultipleTractorRequest
  ): Promise<CreateMultipleTractorRequest>;

  abstract createTractor(
    requestData: CreateTractorRequest
  ): Promise<CreateTractorRequest>;

  abstract createDriver(
    requestData: CreateDriverRequest
  ): Promise<CreateDriverRequest>;

  abstract createMultipleDrivers(
    requestData: CreateMultipleDriversRequest
  ): Promise<CreateMultipleDriversRequest>;
}
