import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledLoadKPICard = styled(
  (props: BoxProps & { availability: string }) => <Box {...props} />
)(({ availability }: { theme: any; availability: string }) => ({
  width: '100%',
  ...(!availability && {
    backgroundColor: 'primary.contrast',
  }),
  borderRadius: '8px',
  padding: '16px',
}));
