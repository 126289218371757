import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { QueryParams } from '../../models';
import {
  CarrierDTO,
  CarrierInsuranceDTO,
  CarrierMessageResponse,
  CarrierStaticDataDTO,
  CreateCarrierContactRequest,
  CreateCarrierContactResponse,
  CreateCarrierInsuranceRequest,
  CreateCarrierRequest,
  ELookupExternalCarrierType,
  ExportCarrierPayload,
  ExportCarrierQueryParams,
  GetCarrierCountTotalResponse,
  GetCarrierOneRequest,
  GetPaginatedCarrierListQueryParams,
  GetPaginatedCarrierListRequest,
  GetPaginatedCarrierListResponse,
  GetSearchContactNameRequest,
  GetSearchContactNameResponse,
  GetSearchDotRequest,
  GetSearchDotResponse,
  GetSearchMcRequest,
  GetSearchMcResponse,
  GetSearchNameRequest,
  GetSearchNameResponse,
  GetSearchScacRequest,
  GetSearchScacResponse,
  LookupExternalCarrierItem,
  SearchContactNameResponseDTO,
  SearchDotResponseDTO,
  SearchMcResponseDTO,
  SearchNameResponseDTO,
  SearchScacResponseDTO,
  UpdateCarrierStatusRequest,
} from '../../models/DTOs/Carrier/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { docType } from '../../utils/Doc';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { ICarrierService } from '../interfaces/ICarrierService';
import { isAxiosErrorCancel } from './DispatchService';

const baseAPI = 'web/customer/api/v2/carriers';
const API_RESOURCE_URL = {
  carrier: {
    createCarrier: `${baseAPI}/carrier`,
    updateCarrier: `${baseAPI}/carrier/update`,
    deleteCarrier: `${baseAPI}/carrier`,
    updateCarrierStatus: `${baseAPI}/carrier/update/partial`,
    list: `${baseAPI}/carrier/list`,
    exportToExcel: `${baseAPI}/carrier/export-to-excel`,
    static: `${baseAPI}/static`,
    searchName: `${baseAPI}/search/name`,
    searchContactName: `${baseAPI}/search/contactname`,
    searchMc: `${baseAPI}/search/mcNumber`,
    searchDot: `${baseAPI}/search/dotNumber`,
    searchScac: `${baseAPI}/search/scac`,
    getCountTotal: `${baseAPI}/count/total`,
    getCarrierOne: `${baseAPI}/carrier/get`,
    getCarrierCountTotal: `${baseAPI}/count/total`,
  },
  contact: {
    createContact: `${baseAPI}/carrier/create-contact`,
  },
  insurance: {
    createInsurance: `${baseAPI}/carrier/create-insurance`,
    deleteInsurance: `${baseAPI}/carrier/delete-insurance`,
  },
  externalCarrier: {
    lookupByMc: `${baseAPI}/partner/by-mc`,
    lookupByDot: `${baseAPI}/partner/by-dot`,
    lookupByName: `${baseAPI}/partner/by-name`,
  },
};

export class CarrierService extends ICarrierService {
  async createCarrier(
    payload: CreateCarrierRequest
  ): Promise<CarrierDTO | ServiceError | null> {
    try {
      const result = await httpClient.post<CarrierDTO>(
        API_RESOURCE_URL.carrier.createCarrier,
        undefined,
        payload,
        CarrierDTO,
        false,
        undefined,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createCarrier',
      });
      return result;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: error?.message?.response?.data?.message,
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async updateCarrier(
    payload: CreateCarrierRequest
  ): Promise<CarrierDTO | ServiceError | null> {
    try {
      const result = await httpClient.put<CarrierDTO>(
        API_RESOURCE_URL.carrier.updateCarrier,
        undefined,
        payload,
        CarrierDTO,
        false,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateCarrier',
      });
      return result;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: error?.message?.response?.data?.message,
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPaginatedCarrierList(
    queryParams: GetPaginatedCarrierListQueryParams,
    payload: GetPaginatedCarrierListRequest
  ): Promise<GetPaginatedCarrierListResponse | ServiceError | null> {
    try {
      const result = await httpClient.put<GetPaginatedCarrierListResponse>(
        API_RESOURCE_URL.carrier.list,
        queryParams,
        payload,
        GetPaginatedCarrierListResponse,
        false,
        true
      );
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async exportToExcel(
    queryParams: ExportCarrierQueryParams,
    payload: ExportCarrierPayload
  ): Promise<docType | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        API_RESOURCE_URL.carrier.exportToExcel,
        queryParams,
        payload,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getStaticList(): Promise<CarrierStaticDataDTO | ServiceError | null> {
    try {
      const queryParams: QueryParams = new QueryParams(true);
      const result = await httpClient.get<CarrierStaticDataDTO>(
        API_RESOURCE_URL.carrier.static,
        queryParams,
        CarrierStaticDataDTO,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async searchName(
    queryParams: GetSearchNameRequest
  ): Promise<GetSearchNameResponse | ServiceError | null> {
    try {
      const result = await httpClient.get<GetSearchNameResponse>(
        API_RESOURCE_URL.carrier.searchName,
        queryParams,
        SearchNameResponseDTO,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async searchContactName(
    queryParams: GetSearchContactNameRequest
  ): Promise<GetSearchContactNameResponse | ServiceError | null> {
    try {
      const result = await httpClient.get<GetSearchContactNameResponse>(
        API_RESOURCE_URL.carrier.searchContactName,
        queryParams,
        SearchContactNameResponseDTO,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async searchMc(
    queryParams: GetSearchMcRequest
  ): Promise<GetSearchMcResponse | ServiceError | null> {
    try {
      const result = await httpClient.get<GetSearchMcResponse>(
        API_RESOURCE_URL.carrier.searchMc,
        queryParams,
        SearchMcResponseDTO,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async searchDot(
    queryParams: GetSearchDotRequest
  ): Promise<GetSearchDotResponse | ServiceError | null> {
    try {
      const result = await httpClient.get<GetSearchDotResponse>(
        API_RESOURCE_URL.carrier.searchDot,
        queryParams,
        SearchDotResponseDTO,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async searchScac(
    queryParams: GetSearchScacRequest
  ): Promise<GetSearchScacResponse | ServiceError | null> {
    try {
      const result = await httpClient.get<GetSearchScacResponse>(
        API_RESOURCE_URL.carrier.searchScac,
        queryParams,
        SearchScacResponseDTO,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getCountTotal(): Promise<
    GetCarrierCountTotalResponse | ServiceError | null
  > {
    try {
      const queryParams: any = new QueryParams(true);
      const result = await httpClient.getRaw(
        API_RESOURCE_URL.carrier.getCountTotal,
        queryParams,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async lookupExternalCarrier(
    searchText: string,
    type: ELookupExternalCarrierType
  ): Promise<LookupExternalCarrierItem[] | ServiceError | null> {
    const getParams = () => {
      const queryParams: any = new QueryParams(true);
      if (type === ELookupExternalCarrierType.DOT) {
        queryParams.dot = searchText;
        return {
          queryParams,
          url: API_RESOURCE_URL.externalCarrier.lookupByDot,
        };
      } else if (type === ELookupExternalCarrierType.MC) {
        queryParams.mcNumber = searchText;
        return {
          queryParams,
          url: API_RESOURCE_URL.externalCarrier.lookupByMc,
        };
      } else if (type === ELookupExternalCarrierType.NAME) {
        queryParams.name = searchText;
        return {
          queryParams,
          url: API_RESOURCE_URL.externalCarrier.lookupByName,
        };
      } else {
        throw new Error('Invalid type');
      }
    };
    const params = getParams();
    try {
      const result = await httpClient.get<
        Array<LookupExternalCarrierItem> | LookupExternalCarrierItem
      >(params.url, params.queryParams, LookupExternalCarrierItem, false, true);
      // {"name":null,"mc":"","dot":null,"address":null}
      if (Array.isArray(result)) {
        return result;
      } else {
        if (result?.name !== null && result?.dot !== null) {
          return [result];
        }
        return [];
      }
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async createCarrierContact(
    payload: CreateCarrierContactRequest
  ): Promise<CreateCarrierContactResponse | ServiceError | null> {
    try {
      const queryParams: any = new QueryParams(true);
      const result = await httpClient.post<CreateCarrierContactResponse>(
        API_RESOURCE_URL.contact.createContact,
        queryParams,
        payload,
        CreateCarrierContactResponse,
        false,
        undefined,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async createCarrierInsurance(
    payload: CreateCarrierInsuranceRequest
  ): Promise<CarrierInsuranceDTO | ServiceError | null> {
    try {
      const queryParams: any = new QueryParams(true);
      const result = await httpClient.post<CarrierInsuranceDTO>(
        API_RESOURCE_URL.insurance.createInsurance,
        queryParams,
        payload,
        CarrierInsuranceDTO,
        false,
        undefined,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async deleteCarrierInsurance(
    queryParams: GetCarrierOneRequest
  ): Promise<CarrierMessageResponse | ServiceError | null> {
    try {
      const result = await httpClient.delete<CarrierMessageResponse>(
        API_RESOURCE_URL.insurance.deleteInsurance,
        queryParams,
        undefined,
        CarrierMessageResponse,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getCarrierOne(
    queryParams: GetCarrierOneRequest
  ): Promise<CarrierDTO | ServiceError | null> {
    try {
      const result = await httpClient.get<CarrierDTO>(
        API_RESOURCE_URL.carrier.getCarrierOne,
        queryParams,
        CarrierDTO,
        false,
        true
      );

      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async deleteCarrier(
    queryParams: GetCarrierOneRequest
  ): Promise<CarrierMessageResponse | ServiceError | null> {
    try {
      const result = await httpClient.delete<CarrierMessageResponse>(
        API_RESOURCE_URL.carrier.deleteCarrier,
        queryParams,
        undefined,
        CarrierMessageResponse,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteCarrier',
      });
      return result;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: error?.message?.response?.data?.message,
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async updateCarrierStatus(
    payload: UpdateCarrierStatusRequest
  ): Promise<CarrierDTO | ServiceError | null> {
    try {
      const result = await httpClient.put<CarrierDTO>(
        API_RESOURCE_URL.carrier.updateCarrierStatus,
        undefined,
        payload,
        CarrierDTO,
        false,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateCarrierStatus',
      });
      return result;
    } catch (error) {
      if (!isAxiosErrorCancel(error)) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: error?.message?.response?.data?.message,
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getCarrierCountTotal(): Promise<number | ServiceError | null> {
    try {
      const result: number = await httpClient.get(
        API_RESOURCE_URL.carrier.getCarrierCountTotal,
        new QueryParams(true),
        undefined,
        false,
        true
      );
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
