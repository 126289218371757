import { PreferenceType } from '../../models/DTOs';
import {
  addLocationValidationSchema,
  gridPageSize,
  nullableNumberYup,
  nullableStringMaxValidation,
} from '../../utils';
import * as yup from 'yup';
import { contactValidationSchema } from '../../validator/validationSchemas';

export const locationDetailsDefaultData = {
  locationName: '',
  addressDTO: {
    address: '',
    center: { lat: 0, lng: 0 },
    city: '',
    fullAddress: '',
    state: '',
    streetAddress: '',
    streetAddress2: '',
    zipcode: '',
  },
  notes: '',
  avgHandlingTime: 120,
  contacts: [],
  locationPreference: PreferenceType.NONE,
};
export const locationListDefaultRequestData = {
  pageNumber: 1,
  pageSize: gridPageSize,
  sort: '+locationName',
  last: true,
};

export const locationDetailsFormValidationSchema = yup.object().shape({
  locationName: yup
    .string()
    .required('Business name is required.')
    .max(255, 'Business name must be at most 255 characters.'),
  notes: nullableStringMaxValidation(255),
  addressDTO: addLocationValidationSchema,
  avgHandlingTime: nullableNumberYup()
    .required('Average Handling Time is required.')
    .max(9999999999),
  contacts: yup.array().of(contactValidationSchema).compact(),
});

export const emptyTableInfo = {
  title: 'time to get social',
  body: 'Looks like you need to add some locations in to system. What is a carrier without a location after all?',
};
