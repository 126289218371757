import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { MenuItem, MenuItemProps, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { RefObject, forwardRef } from 'react';
import CustomizedTooltips from '../../../ui-kit/components/AxeleTooltip';

const StyledMenuItem = styled(MenuItem)({
  paddingLeft: '4px',
  paddingRight: '4px',
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledTypography = styled(Typography)({
  paddingLeft: '8px',
  paddingRight: '8px',
  textAlign: 'left',
});

const FlexBox = styled(Box)({
  display: 'flex',
});

interface IconMenuItemProps {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onClick?: () => void;
  label?: string;
  className?: string;
  MenuItemProps?: MenuItemProps;
  ref?: RefObject<HTMLLIElement>;
  isNestedItem?: boolean;
  totalAvailableSlot: number;
  selectedLabel?: string | undefined;
  subValue?: string | undefined;
}

const IconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(
  (
    {
      leftIcon,
      rightIcon,
      onClick,
      label,
      MenuItemProps,
      className,
      isNestedItem,
      selectedLabel,
      totalAvailableSlot,
      subValue,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {/* <StyledMenuItem
          {...MenuItemProps}
          ref={ref}
          className={className}
          onClick={onClick}
          {...props}
        >
          <FlexBox>
            {leftIcon}
            <AccountCircleOutlinedIcon fontSize={'small'} />
            <StyledTypography sx={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '150%',
              letterSpacing: '0.4px',
              color: 'rgba(0, 17, 34, 0.6)'
            }}>{label}</StyledTypography>
          </FlexBox>
          {rightIcon}
        </StyledMenuItem>
        <Typography sx={{
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '150%',
          letterSpacing: '0.4px',
          color: 'rgba(0, 17, 34, 0.6)',
          marginLeft: '5px'
        }}>5 available slots</Typography> */}

        <StyledMenuItem
          {...MenuItemProps}
          ref={ref}
          className={className}
          onClick={onClick}
          {...props}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {!isNestedItem && (
              <CustomizedTooltips
                placement={'bottom'}
                titleFirstLine={'firstline'}
                titleSecondLine={'secondline'}
                arrow={true}
                color={'primary'}
                enterDelay={20}
                leaveDelay={10}
                isCustomizationTitle={true}
                component={<p>This is tooltip</p>}
                children={
                  <Box sx={{}}>
                    {/* <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress
                    sx={{ color: 'secondary.dark' }}
                    variant="determinate"
                    value={75}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <AccountCircleOutlinedIcon fontSize={'large'} />
                  </Box>
                </Box> */}
                    <AccountCircleOutlinedIcon fontSize={'large'} />
                  </Box>
                }
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <StyledTypography
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '150%',
                    letterSpacing: '0.15px',
                    color: 'text.primary',
                  }}
                >
                  {label}
                </StyledTypography>
                {isNestedItem && (
                  <StyledTypography
                    sx={{
                      color: 'action.active',
                      fontWeight: '400',
                      fontSize: '10px',
                      lineHeight: '150%',
                    }}
                  >
                    {subValue}
                  </StyledTypography>
                )}
              </Box>
              {!isNestedItem && (
                <StyledTypography
                  sx={{
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '150%',
                    letterSpacing: '0.4px',
                    color: 'text.primary',
                    marginLeft: '5px',
                  }}
                >{`${totalAvailableSlot} available slots`}</StyledTypography>
              )}
            </Box>
          </Box>
        </StyledMenuItem>
      </>
    );
  }
);

IconMenuItem.displayName = 'IconMenuItem';
export { IconMenuItem };
