import { SxProps } from '@mui/system';
import TextInput from '../../../../ui-kit/components/TextField';
import { addressType } from '../constants';

type propsType = {
  data: addressType | null;
  errors: any;
  required: boolean;
  label: string;
  maxWidth?: number;
  disabled?: boolean;
  variant?: string;
  validateAddress?: boolean;
  textInputSxProps?: SxProps;
};

function ReadOnlyModeAddressField({
  data,
  required,
  label,
  disabled,
  variant,
  errors,
  validateAddress,
  textInputSxProps,
}: propsType) {
  const getHelperText = () => {
    if (!validateAddress) {
      return errors?.address?.message || '';
    }
    if (required && !data?.address && errors) {
      return `${label} is required.`;
    }
    return '';
  };

  return (
    <TextInput
      autoComplete="off"
      size="small"
      id="address"
      styleProps={{ width: '100%', ...textInputSxProps }}
      label={label}
      value={data?.fullAddress}
      variant={variant}
      error={!!errors}
      InputLabelProps={{
        shrink: !!data?.fullAddress,
      }}
      InputProps={{
        readOnly: true,
      }}
      disabled={disabled}
      required={required}
      helperText={getHelperText()}
    />
  );
}

export default ReadOnlyModeAddressField;
