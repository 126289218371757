import { EquipmentConstants } from '../../../locales/en/operations/equipment/index';
import { MaintenanceHistoryConstants } from '../../../locales/en/safety/maintenanceHistory/index';
import { IMaintenanceRecordFilters } from './types';

export enum EquipmentType {
  Tractor = 'TRACTOR',
  Trailer = 'TRAILER',
}

export const maintenanceRecordFilterFieldsConfig: {
  [key: string]: {
    fieldName: keyof IMaintenanceRecordFilters;
    label: string;
  };
} = {
  maintenanceItems: {
    fieldName: 'maintenanceItems',
    label: EquipmentConstants.maintenanceItem,
  },
  equipmentTypes: {
    fieldName: 'equipmentTypes',
    label: MaintenanceHistoryConstants.assetType,
  },
  assetId: {
    fieldName: 'assetId',
    label: 'Equipment',
  },
  vendor: {
    fieldName: 'vendor',
    label: 'Vendor',
  },
  dateRangeCompletedDate: {
    fieldName: 'dateRangeCompletedDate',
    label: MaintenanceHistoryConstants.completionDate,
  },
  expenseId: {
    fieldName: 'expenseIds',
    label: MaintenanceHistoryConstants.expenseId,
  },
};

export enum MaintenanceRecordViewMode {
  calendar = 'calendar',
  table = 'table',
}

export const strip = 'maintenaceHistory';
export const debounceTime = 300;
