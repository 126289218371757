import { SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Controller, ControllerProps } from 'react-hook-form';
import SingleAutocomplete from '../../../ui-kit/components/SingleAutocomplete';

// @TODO: Nvard - add generic types
export const SingleAutocompleteForm = ({
  id = '',
  name,
  fieldName,
  fieldValue,
  typedTextName,
  label,
  options,
  defaultValue,
  getOptions,
  renderOption = {
    enableEditIcon: false,
    enableOptionSubtitle: false,
  },
  control,
  onChangeCb,
  disableClear,
  disabled,
  sizes = { xs: 12 },
  groupBy,
  freeSolo,
  addNewOption,
  newOption,
  customTextInputStyles,
  customStyles = {},
  wrapperStyles = {},
  shouldUnregister,
  startAdornment = null,
  customValue,
  errorMessageFieldName,
  styledOption = {},
  restProps = {},
  readOnly,
  forcePopupIcon,
  required,
  disableFilter,
  ...props
}: {
  id?: string;
  control: any;
  name: string;
  fieldName: string;
  fieldValue?: string;
  typedTextName?: string;
  label: string;
  options?: any[];
  defaultValue?: any;
  groupBy?: string;
  disableClear?: boolean;
  newOption?: any;
  disabled?: boolean;
  sizes?: {
    xs: number;
  };
  freeSolo?: boolean;
  customTextInputStyles?: any;
  renderOption?: {
    enableEditIcon?: boolean;
    onCLickEditIconHandler?: (option: any) => void;
    enableOptionSubtitle?: boolean;
    renderOptionSubTitle?: (option: any) => JSX.Element;
  };
  getOptions?: (value: string, pageNumber: number) => any;
  onChangeCb?: (data: any, name: string, customValue?: object | string) => void;
  addNewOption?: (text: string) => void;
  customStyles?: any;
  wrapperStyles?: any;
  shouldUnregister?: ControllerProps['shouldUnregister'];
  startAdornment?: JSX.Element | null;
  customValue?: string | object;
  restProps?: Record<string, any>; //I do not know why don't expose BaseSingleAutocompleteTypes
  errorMessageFieldName?: string;
  styledOption?: SxProps<Theme>;
  readOnly?: boolean;
  forcePopupIcon?: boolean;
  required?: boolean;
  disableFilter?: boolean;
}) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => {
        let errorMessage;
        if (error) {
          if (errorMessageFieldName && error[errorMessageFieldName]) {
            errorMessage = error[errorMessageFieldName].message || '';
          } else {
            errorMessage = error.message || error[name]?.message || '';
          }
        }
        const errorObj = errorMessage ? { message: errorMessage } : null;
        return (
          <Grid
            xs={sizes.xs}
            item
            sx={{ marginBottom: '0px', ...wrapperStyles }}
          >
            <SingleAutocomplete
              {...field}
              label={label}
              id={id}
              name={name}
              getOptions={getOptions}
              fieldName={fieldName}
              fieldValue={fieldValue}
              typedTextName={typedTextName}
              onChangeCb={(data: any, name: string) => {
                const newData = data && fieldValue ? data[fieldValue] : data;
                field.onChange(newData, name);
                onChangeCb && onChangeCb(data, name);
              }}
              value={field.value}
              size="small"
              options={options}
              defaultValue={defaultValue}
              groupBy={groupBy}
              newOption={newOption}
              freeSolo={freeSolo}
              addNewOption={addNewOption}
              renderOption={renderOption}
              customTextInputStyles={customTextInputStyles}
              disabled={disabled}
              disableClear={disableClear}
              customStyles={customStyles}
              error={errorObj}
              startAdornment={startAdornment}
              customValue={customValue}
              styledOption={styledOption}
              {...restProps}
              readOnly={readOnly}
              forcePopupIcon={forcePopupIcon}
              required={required}
              disableFilter={disableFilter}
              {...props}
            />
          </Grid>
        );
      }}
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
    />
  );
};
