import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { teamDriverServiceType } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { TeamDriverStatus } from '../../../../common/Ui/StatusComponent/StatusComponent';
import { TEAM_DRIVER_TOOLTIPS } from '../../../../constants/tooltips/tooltip_messages';
import {
  GetDriverTeamRequest,
  PaginatedAllTeamDriverListRequest,
  TeamDriver,
  TeamDriverFilterObject,
} from '../../../../models/DTOs/TeamDriver/TeamDriver';
import { PaginatedResult, ViewMap, ViewMetaData } from '../../../../types';
import { driverWhiteIcon } from '../../../../ui-kit/components/Assets';
import { gridPageSize } from '../../../../utils';
import { CustomizedMenus } from '../components/TeamDriverCustomizedMenu';
import { TeamDriverTableSwitcher } from '../components/TeamDriverTableSwitcher';
import { TeamDriversObject } from './teamDriverModel';

export const groupMode = 'groupMode';
export const driverIds = 'driverIds';
export const activeForOperation = 'activeForOperation';
export const teamDriverTableHeaders = [
  {
    field: 'groupName',
    headerName: 'Primary Driver',
    flex: 1,
    minWidth: 180,
    permanent: true,
    sortable: true,
    renderCell: (params: {
      row: { drivers: Array<TeamDriversObject>; actionsMenus: Array<string> };
    }) => {
      return (
        <Grid>
          <Typography variant="body1" gutterBottom>
            {params.row?.drivers[0]?.name ? params.row.drivers[0].name : ''}
          </Typography>
        </Grid>
      );
    },
  },
  {
    field: 'secondaryDriver',
    headerName: 'Secondary Driver',
    flex: 1,
    minWidth: 180,
    permanent: true,
    sortable: false,
    renderCell: (params: { row: { drivers: Array<TeamDriversObject> } }) => {
      return (
        <>
          {params.row.drivers.length > 1 ? (
            <Typography variant="body1" gutterBottom>
              {params.row?.drivers[1]?.name ? params.row.drivers[1].name : ''}
            </Typography>
          ) : (
            <></>
          )}
        </>
      );
    },
  },
  {
    field: 'operationMode',
    headerName: 'Operation Mode',
    flex: 1,
    minWidth: 120,
    sortable: false,
    renderCell: (params: { row: { groupMode: string } }) => {
      return (
        <>
          {params.row.groupMode ? (
            <Typography variant="body1" gutterBottom>
              {params.row?.groupMode || ''}
            </Typography>
          ) : (
            <></>
          )}
        </>
      );
    },
  },
  {
    field: 'activeOperations',
    headerName: 'Operation Status',
    flex: 1,
    minWidth: 130,
    sortable: false,
    renderCell: (params: { row: { activeForOperation: boolean } }) => {
      return <TeamDriverStatus active={params.row.activeForOperation} />;
    },
  },
  {
    field: 'assignedTractor',
    headerName: 'Assigned Tractor',
    flex: 1,
    minWidth: 180,
    sortable: false,
    renderCell: (params: {
      row: { assetAssignment: { tractorName: string } };
    }) => {
      return (
        <Typography variant="body1" gutterBottom>
          {params.row?.assetAssignment?.tractorName || ''}
        </Typography>
      );
    },
  },
  {
    field: 'assignedTrailer',
    headerName: 'Assigned Trailer',
    flex: 1,
    minWidth: 180,
    sortable: false,
    renderCell: (params: {
      row: { assetAssignment: { trailerName: string } };
    }) => {
      return (
        <Typography variant="body1" gutterBottom>
          {params.row?.assetAssignment?.trailerName || ''}
        </Typography>
      );
    },
  },
  {
    field: 'assignImplicitTractor',
    headerName: 'Auto-assign Tractor',
    flex: 1,
    minWidth: 180,
    sortable: false,
    editable: true,
    renderCell: (params: {
      row: {
        assignImplicitTractor: { assignImplicitTractor: boolean };
        id: string;
      };
    }) => {
      return (
        <Tooltip title={TEAM_DRIVER_TOOLTIPS.TRACTOR}>
          <Box>
            <TeamDriverTableSwitcher
              defaultChecked={!!params.row.assignImplicitTractor}
              groupId={params.row.id}
              fieldName="assignImplicitTractor"
            />
          </Box>
        </Tooltip>
      );
    },
  },
  {
    field: 'assignImplicitTrailer',
    headerName: 'Auto-assign Trailer',
    flex: 1,
    minWidth: 180,
    sortable: false,
    editable: true,
    renderCell: (params: {
      row: {
        assignImplicitTrailer: { assignImplicitTrailer: boolean };
        id: string;
      };
    }) => {
      return (
        <Tooltip title={TEAM_DRIVER_TOOLTIPS.TRAILER}>
          <Box>
            <TeamDriverTableSwitcher
              defaultChecked={!!params.row.assignImplicitTrailer}
              groupId={params.row.id}
              fieldName="assignImplicitTrailer"
            />
          </Box>
        </Tooltip>
      );
    },
  },
  {
    field: 'action',
    headerName: 'Actions',
    flex: 1,
    minWidth: 80,
    sortable: false,
    permanent: true,
    renderCell: (params: { row: any }) => {
      return (
        <Typography variant="body1" gutterBottom>
          <CustomizedMenus menuList={params.row} />
        </Typography>
      );
    },
  },
];
export const getDriverTeamFilterList = async (
  requestparam: GetDriverTeamRequest
): Promise<PaginatedResult<TeamDriverFilterObject> | undefined> => {
  const request = new GetDriverTeamRequest(requestparam);
  const response = await teamDriverServiceType.getDriverTeamFilterList(
    request,
    true
  );
  return response instanceof ServiceError ? undefined : response;
};

export const driverTeamFiltersFormConfig = (terminalIds: Array<string>) => {
  const list = [
    {
      key: 1,
      name: driverIds,
      fieldName: 'driverName',
      getData: (name: string, pageNumber: number) => {
        const request = new GetDriverTeamRequest();
        request.pageNumber = pageNumber;
        request.pageSize = gridPageSize;
        request.terminalIds = terminalIds;
        request.nameFilter = name;
        return getDriverTeamFilterList(request);
      },
      label: 'Driver',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: groupMode,
      fieldName: 'value',
      fieldValue: 'key',
      options: [
        { key: 'SOLO', value: 'Solo' },
        { key: 'TEAM', value: 'Team' },
      ],
      label: 'Operation Mode',
      type: 'SingleAutocomplete',
      default: true,
    },
    {
      key: 3,
      name: activeForOperation,
      fieldName: 'value',
      fieldValue: 'key',
      options: [
        { key: 'YES', value: 'Yes' },
        { key: 'NO', value: 'No' },
      ],
      label: 'Active for Operations',
      default: true,
      type: 'SingleAutocomplete',
    },
  ];
  return list;
};

export const teamDriverFiltersList = [
  {
    field: 'Driver',
    label: 'Driver',
    title: 'driverName',
    option: [],
  },
  {
    field: 'operationMode',
    label: 'Operation Mode',
    title: 'name',
    option: [
      {
        name: 'Team',
      },
      {
        name: 'Solo',
      },
    ],
  },
  {
    field: 'activeforOperations',
    label: 'Active for Operations',
    title: 'name',
    option: [
      {
        name: 'Yes',
      },
      {
        name: 'No',
      },
    ],
  },
];

export const actionTabMenuList = [
  {
    labelText: 'Mark as Inactive',
    Icone: <CloseIcon> </CloseIcon>,
  },
  {
    labelText: 'Swap Drivers',
    Icone: <SwapHorizIcon> ,</SwapHorizIcon>,
  },
  {
    labelText: 'Delete',
    Icone: <DeleteIcon> </DeleteIcon>,
  },
];

type keyTextcompare = {
  [key: string]: string;
};

export const enumMenuList: keyTextcompare = {
  EDIT: 'Edit',
  ASSIGN_TRACTOR: 'Assign Tractor',
  ASSIGN_TRAILER: 'Assign Trailer',
  DELETE: 'Delete',
  MARK_INACTIVE: 'Mark Inactive',
  UNASSIGN_TRACTOR: 'Unassign Tractor',
  UNASSIGN_TRAILER: 'Unassign Trailer',
  MARK_ACTIVE: 'Mark Active',
};
export const enumMenuName: keyTextcompare = {
  EDIT: 'EDIT',
  ASSIGN_TRACTOR: 'ASSIGN_TRACTOR',
  ASSIGN_TRAILER: 'ASSIGN_TRAILER',
  DELETE: 'DELETE',
  MARK_INACTIVE: 'MARK_INACTIVE',
  UNASSIGN_TRACTOR: 'UNASSIGN_TRACTOR',
  UNASSIGN_TRAILER: 'UNASSIGN_TRAILER',
  MARK_ACTIVE: 'MARK_ACTIVE',
};

export const columnVisibilityData = {
  primaryDriver: true,
  secondaryDriver: true,
  operationMode: true,
  activeOperations: true,
  assignedTractor: true,
  assignedTrailer: true,
  assignImplicitTractor: true,
  assignImplicitTrailer: true,
};
export const columnVisibilityDataDefault = {
  primaryDriver: true,
  secondaryDriver: true,
  operationMode: false,
  activeOperations: false,
  assignedTractor: false,
  assignedTrailer: false,
  assignImplicitTractor: false,
  assignImplicitTrailer: false,
};

export const fetchDriverTeams = async (
  queryParams: ViewMetaData
): Promise<PaginatedResult<TeamDriver> | undefined> => {
  const request = new PaginatedAllTeamDriverListRequest(queryParams);
  const response = await teamDriverServiceType.getTeamDriverList(request, true);
  return response instanceof ServiceError ? undefined : response;
};

export const teamDriverFilter = {
  [groupMode]: '',
  [driverIds]: [],
  [activeForOperation]: '',
};

export const driverTeamView = {
  id: 'driverTeam',
  shared: true,
  parentId: null,
  name: 'Team Driver',
  entity: 'driverTeam',
  icon: driverWhiteIcon,
  active: true,
  default: true,
  columnFilters: columnVisibilityData,
  metaData: teamDriverFilter,
};
export const driverTeamViewformDefaultValuesMap: ViewMap = {
  driverTeam: driverTeamView,
};
type keyCompare = {
  [key: string]: boolean;
};
export const keyparingValue: keyCompare = {
  YES: true,
  NO: false,
};

export interface IAppliedFilte {
  groupMode?: string;
  driverIds?: Array<TeamDriverFilterObject>;
  activeForOperation?: boolean;
  sort?: string;
}

export interface ISelectedFilte {
  model?: string;
  ownerEmail?: string;
  status?: string;
  automateSettlementCalculation?: boolean;
  id?: number;
}

export const getLocalStorageListparams = (localStorage: any) => {
  let appliedFilte: IAppliedFilte = {};
  if (localStorage?.driverTeam?.filters) {
    const { driverIds, activeForOperation, groupMode } =
      localStorage.driverTeam.filters;
    appliedFilte.nameFilterList = filterDriverNames(driverIds) || [];
    appliedFilte.groupMode = groupMode?.key || null;
    appliedFilte.activeForOperation = activeForOperation?.key
      ? keyparingValue[activeForOperation?.key]
      : null;
    if (localStorage.driverTeam.sort) {
      appliedFilte.sort = localStorage.driverTeam.sort || '+groupName';
    }
  } else {
    appliedFilte = {
      sort: localStorage.driverTeam?.sort || '+groupName',
    };
  }
  return appliedFilte;
};

export const filterDriverNames = (list: Array<TeamDriverFilterObject>) => {
  const idList: Array<number> = [];
  list?.map((data: TeamDriverFilterObject) => {
    if (data?.driverName) {
      idList.push(data.driverName);
    }
  });
  return idList;
};
