import moment from 'moment';
import * as yup from 'yup';
import { PayStatementsEntityTypeEnum } from '../../models';
import {
  gridPageSize,
  isHasPermission,
  nullableDateYup,
  nullableNumberYup,
  nullableStringYup,
} from '../../utils/index';

import { AXELE_PERMISSION_TYPE } from '../../common/Permission';

import { Theme } from '@mui/material';
import { t } from 'i18next';
import { BulkSettlementActions } from './config/bulkActions.config';
export const listDefaultRequestData = {
  pageNumber: 1,
  pageSize: gridPageSize,
  sort: '-amount',
};

export const otherPaymentsFormValidationSchema = yup.object().shape({
  amountToShow: nullableNumberYup()
    .required('Amount is a required field')
    .min(0.0001, 'Value must be between 0.0001 and 99,999.9999')
    .max(999999999, 'Value must be between 0.0001 and 99,999.9999'),
  truckStop: nullableStringYup().max(100),
  description: nullableStringYup().max(255),
  payDate: nullableDateYup().required('Date is a required field'),
  expenseCategoryObj: yup
    .object()
    .nullable()
    .required('Expense Category is a required field'),
});

export const fuelPaymentsFormValidationSchema =
  otherPaymentsFormValidationSchema.concat(
    yup.object().shape({
      fuelQuantity: nullableNumberYup()
        .required('Fuel Quantity is a required field')
        .min(0.0001, 'Value must be between 0.0001 and 99,999.9999')
        .max(999999999, 'Value must be between 0.0001 and 99,999.9999'),
      fuelTypeObj: yup
        .object()
        .nullable()
        .required('Fuel Type is a required field'),
      state: yup.object().nullable().required('State is a required field'),
    })
  );

export const createInReviewPayStatementValidationSchema = yup.object().shape(
  {
    startDate: nullableDateYup().when(
      ['startDate', 'endDate'],
      (startDate, endDate) => {
        if (startDate && endDate) {
          return nullableDateYup().max(
            moment(endDate),
            'Start date must be before the End date.'
          );
        }
        return nullableDateYup().required('Start date is required.');
      }
    ),
    endDate: nullableDateYup().when(
      ['endDate', 'startDate'],
      (endDate, startDate) => {
        if (endDate && startDate) {
          return nullableDateYup().min(
            moment(startDate),
            'End date must be after the Start date.'
          );
        }

        return nullableDateYup().required('End date is required.');
      }
    ),
  },
  [
    ['startDate', 'endDate'],
    ['endDate', 'startDate'],
    ['endDate', 'endDate'],
    ['startDate', 'startDate'],
  ]
);

export const payStatementDetailsFormValidationSchema = yup.object().shape({
  fuelNonTripPaymentsList: yup.object().shape({
    content: yup.array().of(fuelPaymentsFormValidationSchema).compact(),
  }),
  otherNonTripPaymentsList: yup.object().shape({
    content: yup.array().of(otherPaymentsFormValidationSchema).compact(),
  }),
});

export const getSecondaryActionConfig = (
  entity: PayStatementsEntityTypeEnum,
  secondaryActionButtonIsDisabled?: boolean
) => {
  if (!entity) return [];
  const noPermissionForCloseSettlement = !isHasPermission([
    AXELE_PERMISSION_TYPE.FINANCIAL_PAYMENT_CHANGE_STATUS_INREVIEW_CLOSE,
  ]);
  const noPermissionForReopenSettlement = !isHasPermission([
    AXELE_PERMISSION_TYPE.FINANCIAL_PAYMENT_CHANGE_STATUS_CLOSE_INREVIEW,
  ]);
  const config = {
    pendingPayment: [
      {
        secondaryActionButtonLabel: t('createSettlement'),
        secondaryActionButtonIconName: 'PostAddOutlined',
        actionName: BulkSettlementActions.CREATE_PAY_STATMENT,
        secondaryActionButtonIsDisabled:
          noPermissionForReopenSettlement || secondaryActionButtonIsDisabled,
        onSecondaryAction: () => {},
      },
    ],
    inReviewStatement: [
      {
        secondaryActionButtonLabel: t('markAsReviewed'),
        secondaryActionButtonIconName: '',
        actionName: BulkSettlementActions.MARK_AS_REVIEWED,
        variant: 'outlined',
        onSecondaryAction: () => {},
        secondaryActionButtonIsDisabled: noPermissionForReopenSettlement,
      },
      {
        secondaryActionButtonLabel: t('closeSettlement'),
        secondaryActionButtonIconName: 'Check',
        actionName: BulkSettlementActions.CLOSE_PAY_STATMENT,
        secondaryActionButtonIsDisabled: noPermissionForCloseSettlement,
        onSecondaryAction: () => {},
      },
    ],
    reviewedStatement: [
      {
        secondaryActionButtonLabel: t('revertToInReview'),
        secondaryActionButtonIconName: '',
        actionName: BulkSettlementActions.REVERT_TO_IN_REVIEW,
        variant: 'outlined',
        onSecondaryAction: () => {},
        secondaryActionButtonIsDisabled: noPermissionForReopenSettlement,
      },
      {
        secondaryActionButtonLabel: t('closeSettlement'),
        secondaryActionButtonIconName: 'Check',
        actionName: BulkSettlementActions.CLOSE_PAY_STATMENT,
        secondaryActionButtonIsDisabled: noPermissionForCloseSettlement,
        onSecondaryAction: () => {},
      },
    ],
    closedStatement: [],
  };
  return config[entity];
};

export const deletePopupConfig = {
  pendingPayment: {
    title: 'Delete Payment',
    body: 'Are you sure you want to delete this Payment?',
    subtitle: '',
    buttonText: 'Delete',
  },
  inReviewStatement: {
    title: 'Delete Settlement',
    body: 'Are you sure you want to permanently delete the selected settlement(s)?',
    subtitle:
      'Please note, that after deletion the line items will be added to the pending payments.',
    buttonText: 'Delete',
  },
  reviewedStatement: {
    title: 'Delete Settlement',
    body: 'Are you sure you want to permanently delete the selected settlement(s)?',
    subtitle:
      'Please note, that after deletion the line items will be added to the pending payments.',
    buttonText: 'Delete',
  },
  closedStatement: {
    title: '',
    body: '',
    subtitle: '',
    buttonText: '',
  },
} as any;

export const paymentDeleteAction = {
  pendingPayment: {
    label: 'Delete',
    iconName: 'DeleteOutlineOutlined',
    source: 'MUIcons',
  },
  inReviewStatement: {
    label: 'Remove',
    iconName: 'UndoOutlined',
    source: 'MUIcons',
  },
  reviewedStatement: {
    label: 'Remove',
    iconName: 'UndoOutlined',
    source: 'MUIcons',
  },
  closedStatement: {
    label: '',
    iconName: '',
    source: '',
  },
};

export const SettlementStatusChangePopupConfig = (theme: Theme) =>
  ({
    REVERT_TO_IN_REVIEW: {
      title: 'Settlement In Review',
      body: 'Are you sure you want to mark the settlement(s) as In Review?',
      subtitle: '',
      buttonText: 'Mark as In Review',
      backgroundColor: theme?.palette?.primary?.main,
    },
    REVERT_TO_REVIEWED: {
      title: 'Settlement Reviewed',
      body: 'Are you sure you want to revert the settlement to Reviewed?',
      subtitle: '',
      buttonText: 'Mark as Reviewed',
      backgroundColor: theme?.palette?.primary?.main,
    },
    MARK_AS_REVIEWED: {
      title: 'Settlement Reviewed',
      body: 'Are you sure you want to mark the settlement(s) as Reviewed?',
      subtitle: '',
      buttonText: 'Mark as Reviewed',
      backgroundColor: theme?.palette?.primary?.main,
    },
    MARK_AS_IN_REVIEW: {
      title: 'Settlement In Review',
      body: 'Are you sure you want to mark the settlement(s) as In Review?',
      subtitle: '',
      buttonText: 'Mark as In Review',
      backgroundColor: theme?.palette?.primary?.main,
    },
  } as { [key in BulkSettlementActions]: any });
