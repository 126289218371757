import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  GetExpenseCategorySummaryResponse,
  GetExpenseDetailsGlobalTotalResponse,
  GetExpenseDetailsResponse,
  GetIncomeCategorySummaryResponse,
  GetIncomeDetailsResponse,
  GetInvoiceCategoriesResponse,
  IFASExpenseDetailsCarrierPayment,
  IFASExpenseDetailsDispatcherPayment,
  IFASExpenseDetailsDriverPayment,
  IFASExpenseDetailsExpensePayment,
  IFASExpenseDetailsTractorPayment,
} from '../../models/DTOs/FinanceAccountSummary/FinanceAccountSummary';
import {
  GetExpenseCategorySummaryRequest,
  GetExpenseDetailRequest,
  GetIncomeCategorySummaryRequest,
  GetIncomeDetailsRequest,
  GetInvoiceCategoriesRequest,
  GetSummaryGridExportRequest,
} from '../../models/DTOs/FinanceAccountSummary/FinanceAccountSummaryRequests';
import { annotateNameAsync } from '../interfaces';
import { IFinanceAccountSummaryService } from '../interfaces/IFinanceAccountSummaryService';
import { financialItemURL } from './requestConstants';

const RESOURCES_API_URL = {
  getIncomeCategorySummary: '/web/invoice/api/v2/incomeCategorySummary',
  getExpenseCategorySummary:
    '/web/expense/api/v2/expense/expenseCategorySummary',
  getIncomeDetails: '/web/invoice/api/v2/incomeCategoryDrillDown',
  getExpenseDetailsDriverPayment:
    '/web/dpm/api/v2/account-summary/expenseCategoryDrillDown',
  getExpenseDetailsTractorPayment:
    '/web/dpm/api/v2/account-summary/expenseCategoryDrillDown',
  getExpenseDetailsDispatcherPayment:
    '/web/dpm/api/v2/account-summary/expenseCategoryDrillDown',
  getExpenseDetailsExpensesPayment:
    '/web/expense/api/v2/expense/expenseCategoryDrillDown',
  getExpenseDetailsGlobalTotal:
    '/web/expense/api/v2/expense/expenseCategoryDrillDown/total',
  getInvoiceCategories: financialItemURL,
  getCarrierDetailsExpensesPayment:
    '/web/load/api/v2/tripexpense/carrier/expenseCategoryDrillDown',
};

const summaryExportUrls: { [key: string]: string } = {
  driverPayment:
    '/web/dpm/api/v2/account-summary/expenseCategoryDrillDown/export-to-excel',
  tractorPayment:
    '/web/dpm/api/v2/account-summary/expenseCategoryDrillDown/export-to-excel',
  dispatchPayment:
    '/web/dpm/api/v2/account-summary/expenseCategoryDrillDown/export-to-excel',
  expensePayment:
    '/web/expense/api/v2/expense/expenseCategoryDrillDown/export-to-excel',
  incomeDrillDown:
    '/web/invoice/api/v2/incomeCategoryDrillDown/export-to-excel',
  summaryIncome: '/web/invoice/api/v2/incomeCategorySummary/export-to-excel',
  summaryExpense:
    '/web/expense/api/v2/expense/expenseCategorySummary/export-to-excel',
  carrierPayment:
    '/web/load/api/v2/tripexpense/carrier/expenseCategoryDrillDown/export-to-excel',
};
export class FinanceAccountSummaryService extends IFinanceAccountSummaryService {
  @annotateNameAsync
  async getIncomeCategorySummary(
    queryParams: GetIncomeCategorySummaryRequest
  ): Promise<GetIncomeCategorySummaryResponse | undefined> {
    try {
      return await httpClient.get<GetIncomeCategorySummaryResponse>(
        RESOURCES_API_URL.getIncomeCategorySummary,
        queryParams,
        GetIncomeCategorySummaryResponse,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getExpenseCategorySummary(
    queryParams: GetExpenseCategorySummaryRequest
  ): Promise<GetExpenseCategorySummaryResponse | undefined> {
    try {
      return await httpClient.get<GetExpenseCategorySummaryResponse>(
        RESOURCES_API_URL.getExpenseCategorySummary,
        queryParams,
        GetExpenseCategorySummaryResponse,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async exportSummaryGridData(
    queryParams: GetSummaryGridExportRequest,
    category: string
  ) {
    try {
      const result = await httpClient.getRaw(
        summaryExportUrls[category],
        queryParams
      );
      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getIncomeDetails(
    queryParams: GetIncomeDetailsRequest
  ): Promise<GetIncomeDetailsResponse | undefined> {
    try {
      return await httpClient.get<GetIncomeDetailsResponse>(
        RESOURCES_API_URL.getIncomeDetails,
        queryParams,
        GetIncomeDetailsResponse,
        undefined,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getExpenseDetailsDriverPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsDriverPayment> | undefined
  > {
    try {
      return await httpClient.get<
        GetExpenseDetailsResponse<IFASExpenseDetailsDriverPayment>
      >(
        RESOURCES_API_URL.getExpenseDetailsDriverPayment,
        queryParams,
        GetExpenseDetailsResponse<IFASExpenseDetailsDriverPayment>,
        undefined,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getExpenseDetailsTractorPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsTractorPayment> | undefined
  > {
    try {
      return await httpClient.get<
        GetExpenseDetailsResponse<IFASExpenseDetailsTractorPayment>
      >(
        RESOURCES_API_URL.getExpenseDetailsTractorPayment,
        queryParams,
        GetExpenseDetailsResponse<IFASExpenseDetailsTractorPayment>,
        undefined,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }
  @annotateNameAsync
  async getExpenseDetailsDispatcherPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsDispatcherPayment> | undefined
  > {
    try {
      return await httpClient.get<
        GetExpenseDetailsResponse<IFASExpenseDetailsDispatcherPayment>
      >(
        RESOURCES_API_URL.getExpenseDetailsDispatcherPayment,
        queryParams,
        GetExpenseDetailsResponse<IFASExpenseDetailsDispatcherPayment>,
        undefined,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getExpenseDetailsExpensesPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsExpensePayment> | undefined
  > {
    try {
      return await httpClient.get<
        GetExpenseDetailsResponse<IFASExpenseDetailsExpensePayment>
      >(
        RESOURCES_API_URL.getExpenseDetailsExpensesPayment,
        queryParams,
        GetExpenseDetailsResponse<IFASExpenseDetailsExpensePayment>,
        undefined,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }
  @annotateNameAsync
  async getCarrierDetailsExpensesPayment(
    queryParams: GetExpenseDetailRequest
  ): Promise<
    GetExpenseDetailsResponse<IFASExpenseDetailsCarrierPayment> | undefined
  > {
    try {
      return await httpClient.get<
        GetExpenseDetailsResponse<IFASExpenseDetailsCarrierPayment>
      >(
        RESOURCES_API_URL.getCarrierDetailsExpensesPayment,
        queryParams,
        GetExpenseDetailsResponse<IFASExpenseDetailsCarrierPayment>,
        undefined,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getExpenseDetailsGlobalTotal(
    queryParams: GetExpenseDetailRequest
  ): Promise<GetExpenseDetailsGlobalTotalResponse | undefined> {
    try {
      return await httpClient.get<{ totalAmount: number }>(
        RESOURCES_API_URL.getExpenseDetailsGlobalTotal,
        queryParams,
        GetExpenseDetailsGlobalTotalResponse,
        undefined,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getInvoiceCategories(
    queryParams: GetInvoiceCategoriesRequest
  ): Promise<GetInvoiceCategoriesResponse | undefined> {
    try {
      const response = await httpClient.getRaw(
        RESOURCES_API_URL.getInvoiceCategories,
        queryParams,
        false,
        true
      );
      return {
        content: response.data,
        last: true,
      } as GetInvoiceCategoriesResponse;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }
}
