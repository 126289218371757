import ErrorIcon from '@mui/icons-material/Error';
import { Button, TextField, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useRef, useState } from 'react';
import StorageManager from '../../../../StorageManager/StorageManager';
import { loadboardService } from '../../../../api';
import { ComposedError } from '../../../../axios/axiosInstance';
import {
  CreateRequestCredentials,
  LoginAccountIntegration,
  UserDetailsRequest,
} from '../../../../models/DTOs/Loadboard/Requests';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import {
  IEditCard,
  UserPrefilledResponse,
} from '../../../../types/LoadboardTypes';
import AutocompleteField from '../../../../ui-kit/components/Autocomplete';
import IntegrationFormContent from '../../../../ui-kit/components/IntegrationFormContent';
import { emailValidation } from '../../../../views/settings/terminals/constants/constants';
import { Errors, IntegrationTooltipText } from './dispalyTestFields';
import { integrationLabelUseStyles } from './style';

export default function TabContents({
  tabIndex,
  allTerminals,
  needConnexion,
  haveConnexion,
  currentCustomerData,
  refreshOnCard,
  isEditTab,
  cardData,
  toggleDrawer,
}: IProps) {
  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(true);
  const [connectDATError, setConnectDATError] = useState<boolean>(false);
  const [formInputs, setFormInputs] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState('');
  const didMount = useRef(false);
  const [formInputValidation, setFormInputValidation] = useState<any>(0);
  const [prefilledCount, setPrefilledCount] = useState<number>(0);
  const [terminalId, setTerminalId] = useState<any>([]);
  const [validationErrorDAT, setValidationErrorDAT] = useState<any>({});
  const [validationErrorUI, setValidationErrorUI] = useState<any>({});
  const [formErr, setFormErr] = useState({
    integrationType: 'LOADBOARD',
  });

  useEffect(() => {
    // setFormInputs({});
    setFormInputValidation((prevState: any) => prevState + 1);
    setValidationErrorDAT({});
  }, [tabIndex]);

  const handleChange = (
    event:
      | React.SyntheticEvent
      | React.ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<any>,
    newValue: number
  ) => {
    setFormInputs({
      ...formInputs,
      [newValue]: event.target.value,
    });
    setFormInputValidation((prevState: any) => prevState + 1);
  };

  useEffect(() => {
    if (didMount.current) {
      let fieldCount = 0;
      fieldCount = Object.values(formInputs).filter(function (key: any) {
        return key;
      }).length;
      if (tabIndex === 0) {
        if (fieldCount === needConnexion.length) setIsButtonDisable(false);
        else {
          setIsButtonDisable(true);
        }
      }
      if (tabIndex === 1) {
        if (fieldCount === haveConnexion.length) setIsButtonDisable(false);
        else {
          setIsButtonDisable(true);
        }
      }
    } else {
      didMount.current = true;
    }
  }, [formInputValidation]);

  useEffect(() => {
    try {
      const getPrefilledData = async () => {
        const response = RootStoreInstence.getCompanyPreferences;
        if (!response) {
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'prefilledData',
          });
          return;
        }
        const val = StorageManager.getUser() || {};
        const userId = val['id'];
        const data: {
          userId: number;
        } = {
          userId: userId,
        };

        const userDetailsRequest = new UserDetailsRequest(data);
        const userDetailResponse: UserPrefilledResponse =
          await loadboardService.getUserDetails(userDetailsRequest);
        if (response?.['companyDetails']) {
          const prefilledData = {
            CARRIER_NAME: response?.['companyDetails']?.['name']
              ? response['companyDetails']['name']
              : '',

            DOT_NUMBER: response?.['companyDetails']?.['dot']
              ? response['companyDetails']['dot']
              : '',

            CONTACT_NAME:
              userDetailResponse?.['firstname'] &&
              userDetailResponse?.['lastname']
                ? `${userDetailResponse?.['firstname']} ${userDetailResponse?.['lastname']}`
                : '',

            CARRIER_USERNAME: response?.['companyDetails']?.['email']
              ? response['companyDetails']['email']
              : '',

            CONTACT_PHONE: userDetailResponse?.['phoneData']?.['phone']
              ? userDetailResponse?.['phoneData']?.['phone']
              : '',
          };
          const displayName = formInputs['DISPLAY_NAME'];
          setFormInputs({
            ...prefilledData,
            ...{ DISPLAY_NAME: displayName },
            ...{ INTEGRATION_EMAIL: formInputs['INTEGRATION_EMAIL'] },
          });
        }
      };
      if (tabIndex === 0 && prefilledCount >= 1) {
        getPrefilledData();
        if (!cardData['requestedCredentials'] && isEditTab) {
          setIsButtonDisable(false);
        } else if (isEditTab) {
          setIsButtonDisable(true);
        } else {
          setIsButtonDisable(false);
        }
      }
      setFormInputValidation((prevState: any) => prevState + 1);
    } catch (error) {
      return;
    }
  }, [prefilledCount]);

  useEffect(() => {
    const cardEditData: any = {};
    cardData &&
      cardData['data'] &&
      cardData['data'].length > 0 &&
      cardData['data'].map((item: IEditCard) => {
        cardEditData[item['name']] = item['value'];
      });
    setFormInputs({ ...cardEditData });
    setPrefilledCount((prevState: any) => prevState + 1);
  }, [isEditTab]);

  const formInputsValidation = () => {
    if (formInputs && formInputs['CARRIER_EMAIL']) {
      if (!emailValidation.test(formInputs['CARRIER_EMAIL'])) {
        setValidationErrorUI({
          validationErrorUI,
          CARRIER_EMAIL: ['Invalid Email'],
        });
      }
    }
  };

  const sendDATCredientials = async () => {
    const data: any = {};
    const formInputsKey = Object.keys(formInputs);
    const formInputsValues = Object.values(formInputs);
    const formData = [];
    for (let i = 0; i < formInputsKey.length; i++) {
      const fields: any = {};
      fields['name'] = formInputsKey[i];
      fields['value'] = formInputsValues[i];
      formData.push(fields);
    }
    console.log('formDataformDataformData', formData, 'isEditTab', isEditTab);
    data['data'] = formData;
    data['integrationType'] = 'LOADBOARD';
    data['isValid'] = false;
    data['lastUsedDate'] = null;
    data['lastValidationDate'] = null;
    data['terminalIds'] = [];
    data['organizationId'] = 1435;
    data['providerId'] = 7;
    isEditTab && cardData && (data['id'] = cardData['id']);

    const request = new CreateRequestCredentials(data);
    try {
      await loadboardService.createDATCredential(request);
      setFormInputs({});
      toggleDrawer(false);
      refreshOnCard();
    } catch (error: any) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        const statusCode = composedError.getStatusCode();
        if (statusCode === 406) {
          setValidationErrorDAT(
            error['error']['response']['data']['validationErrors']
          );
        }
      }
    }
  };

  const createLoginCard = async () => {
    const data: any = {};
    const formInputsKey = Object.keys(formInputs);
    const formInputsValues = Object.values(formInputs);
    const formData = [];
    for (let i = 0; i < formInputsKey.length; i++) {
      const fields: any = {};
      fields['name'] = formInputsKey[i];
      fields['value'] = formInputsValues[i];
      formData.push(fields);
    }
    data['data'] = formData;
    data['integrationType'] = 'LOADBOARD';
    data['isValid'] = true;
    data['lastUsedDate'] = null;
    data['lastValidationDate'] = null;
    data['providerId'] = 1;
    data['terminalIds'] = terminalId;
    isEditTab && (data['id'] = cardData['id']);

    const request = new LoginAccountIntegration(data);
    try {
      if (!isEditTab) await loadboardService.createLoginCard(request);
      else await loadboardService.editDATCredential(request);
      setFormInputs({});
      toggleDrawer(false);
      refreshOnCard();
    } catch (error) {
      if (error) {
        setConnectDATError(true);
        setErrorMsg(error['message']);
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    formInputsValidation();
    tabIndex === 0 && sendDATCredientials();
    tabIndex === 1 && createLoginCard();
  };

  const validationForm = (payload: any): boolean | undefined => {
    if (payload?.data) {
      const blankFormErr: any = {};
      payload?.data.map((item: { name: string; value: string }) => {
        if (!item['value']) {
          blankFormErr[item['name']] = [Errors[item['name']]];
        }
      });
      if (
        payload?.terminalIds.length === 0 &&
        payload?.data?.length < haveConnexion.length
      ) {
        blankFormErr['TERMINAL_IDS'] = [Errors['TERMINAL_IDS']];
      }
      setFormErr({
        ...{ integrationType: 'LOADBOARD' },
        ...blankFormErr,
      });
      if (Object.keys(blankFormErr).length === 0) {
        if (payload['providerId'] === 4) {
          sessionStorage.setItem('123Loadboard', JSON.stringify(payload));
          return false;
        }

        return true;
      }

      return false;
    }
  };

  const validateFormCreds = async (payload: any) => {
    payload['integrationType'] = 'LOADBOARD';
    const isValidate: boolean | undefined = validationForm(payload);
    if (isValidate) {
      const request = new LoginAccountIntegration(payload);
      try {
        await loadboardService.createLoginCard(request);
        refreshOnCard();
        toggleDrawer(false);
      } catch (error: any) {
        if (
          error &&
          error['error'] &&
          error['error']['response'] &&
          error['error']['response']['status'] === 406
        ) {
          const validationError =
            error['error']['response']['data']['validationErrors'];
          setFormErr({
            ...{ integrationType: 'LOADBOARD' },
            ...validationError,
          });
        }
      }
    }
  };

  const updateAccountCard = async (payload: any) => {
    payload['integrationType'] = 'LOADBOARD';
    const isValidate: boolean | undefined = validationForm(payload);
    if (isValidate) {
      const request = new LoginAccountIntegration(payload);
      try {
        await loadboardService.editDATCredential(request);
        toggleDrawer(false);
        refreshOnCard();
      } catch (error: any) {
        if (
          error &&
          error['error'] &&
          error['error']['response'] &&
          error['error']['response']['status'] === 404
        ) {
          const validationError =
            error['error']['response']['data']['validationErrors'];
          setFormErr({
            ...{ integrationType: 'LOADBOARD' },
            ...validationError,
          });
        }
      }
    }
  };
  const classes = integrationLabelUseStyles();
  return (
    <>
      {tabIndex === 0 && (
        <Box>
          <form>
            {needConnexion.map((item: any, index: number) => {
              const isIntegrationEmail =
                item?.name === 'INTEGRATION_EMAIL' && item?.stepNumber === 1;
              return (
                <>
                  {item['type'] === 'dropdown' && (
                    <AutocompleteField
                      label="Associated Terminals"
                      variant="standard"
                      required={true}
                      options={allTerminals}
                      multiple={true}
                    />
                  )}
                  {item['type'] !== 'dropdown' && (
                    <>
                      <TextField
                        sx={{ width: 310 }}
                        className={isIntegrationEmail ? classes.root : ''}
                        id={'FieldItem' + index}
                        label={
                          isIntegrationEmail ? (
                            <Box>
                              {item['displayText']}
                              <Tooltip
                                title={IntegrationTooltipText}
                                placement="top"
                                arrow
                              >
                                <ErrorIcon sx={{ cursor: 'pointer' }} />
                              </Tooltip>
                            </Box>
                          ) : (
                            item['displayText']
                          )
                        }
                        variant="standard"
                        type={item['type']}
                        required={true}
                        disabled={
                          !cardData['requestedCredentials'] && isEditTab
                            ? false
                            : isEditTab
                            ? true
                            : false
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(event, item['name'])}
                        value={
                          formInputs && formInputs[item['name']]
                            ? formInputs[item['name']]
                            : ''
                        }
                        error={
                          (validationErrorDAT &&
                          Object.keys(validationErrorDAT).length > 0 &&
                          Object.keys(validationErrorDAT).includes(item['name'])
                            ? true
                            : false) ||
                          (validationErrorUI &&
                          Object.keys(validationErrorUI).length > 0 &&
                          Object.keys(validationErrorUI).includes(item['name'])
                            ? true
                            : false)
                        }
                        helperText={
                          (validationErrorDAT &&
                            validationErrorDAT[item['name']] &&
                            validationErrorDAT[item['name']].length > 0 &&
                            validationErrorDAT[item['name']][0]) ||
                          (validationErrorUI &&
                            validationErrorUI[item['name']] &&
                            validationErrorUI[item['name']].length > 0 &&
                            validationErrorUI[item['name']][0])
                        }
                      />
                    </>
                  )}
                </>
              );
            })}
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{ textTransform: 'none' }}
                // type={'submit'}
                onClick={handleSubmit}
                variant="contained"
                disabled={isButtonDisable}
              >
                Request Connexion
              </Button>
            </Box>
          </form>
        </Box>
      )}

      {tabIndex === 1 && (
        <Box>
          {haveConnexion && (
            <IntegrationFormContent
              getTerminalOptions={allTerminals}
              currentCustomerData={currentCustomerData}
              sideDrawerDisplayData={haveConnexion}
              dataToUpdate={cardData}
              validateFormCreds={validateFormCreds}
              updateAccountCard={updateAccountCard}
              formErr={formErr}
              integrationType={'LOADBOARD'}
            />
          )}
          {connectDATError && (
            <Box sx={{ m: 1, p: 1 }}>
              <Typography
                sx={{ fontSize: 'typography.fontSize' }}
                color={'error.dark'}
              >
                {errorMsg}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

interface IProps {
  tabIndex: number;
  allTerminals: any;
  needConnexion: any;
  haveConnexion: any;
  currentCustomerData: any;
  refreshOnCard: () => void;
  isEditTab: boolean;
  cardData: any;
  toggleDrawer: (newOpen: boolean) => void;
}
