import { FormState } from 'react-hook-form';
import { Expense, ExpenseDetails } from '../../../models';
import { WarningPopoverConfigType } from '../../../types';
import { getListOfPaymentsByCompatible, hasClosedStatePayment } from './utils';
import { t } from 'i18next';

type WarningPopoverConfigPropsType = {
  configName: string;
  dynamicText?: string;
  secondaryDynamicText?: string;
  actionName?: string;
  buttonText?: string;
  data?: any;
};

export const getExpenseWarningPopoverConfig = ({
  configName,
  dynamicText = '',
  secondaryDynamicText = '',
  actionName,
  buttonText = 'Save',
  data,
}: WarningPopoverConfigPropsType): WarningPopoverConfigType => {
  const warningPopoverConfigConstant: {
    [key: string]: WarningPopoverConfigType;
  } = {
    DELETE_EXPENSE: {
      title: `Delete Expense${dynamicText}?`,
      body: `Are you sure you want to delete this Expense${dynamicText}?`,
      actionName: actionName || 'handleDeleteExpense',
      cancelText: t('cancel'),
      buttonText: 'Delete',
      width: '500',
    },
    DELETE_EXPENSE_WITH_SETTLEMENT: {
      title: `Delete Expense${dynamicText}?`,
      body: 'Change will cause linked settlement to be deleted?',
      actionName: actionName || 'handleDeleteExpense',
      cancelText: t('cancel'),
      buttonText: 'Delete',
      width: '500',
    },
    CANNOT_DELETE_EXPENSE: {
      title: `Delete Expense${dynamicText}?`,
      body: `Cannot delete expense${dynamicText} as linked settlement(s) status is closed.`,
      subTitle: ` Please reopen the settlement(s) before deleting the expense${dynamicText}.`,
      actionName: '',
      cancelText: 'Ok',
      width: '500',
    },
    CHANGE_STATUS: {
      title: `Change Status${+dynamicText > 1 ? 'es' : ''}?`,
      body: `${dynamicText} selected expense${
        +dynamicText > 1 ? 's' : ''
      } will be changed to "${secondaryDynamicText}" status `,
      actionName: actionName || 'handleChangeStatusBulk',
      buttonText: 'Change',
      cancelText: t('cancel'),
      backgroundColor: '#2B64CB',
      width: '500',
    },
    NET_EXPENSE_IS_CHANGED: {
      title: 'Save Changes?',
      body: `Expense amount has been changed, please update linked settlement(s)`,
      actionName: 'handleUpdateExpense',
      buttonText: 'Save Changes',
      cancelText: t('cancel'),
      backgroundColor: '#2B64CB',
      width: '500',
      data: data,
    },
    EXPENSE_DETAILS_IS_CHANGED: {
      title: 'Save Changes?',
      body: `Some of the expense details have changed, please update the linked settlement(s)`,
      actionName: 'handleUpdateExpense',
      buttonText: 'Save Changes',
      cancelText: t('cancel'),
      backgroundColor: '#2B64CB',
      width: '500',
      data: data,
    },
    CANNOT_UPDATE_EXPENSE: {
      title: 'Cannot Save Changes',
      body: `Expense cannot be changed as the linked settlement(s) status is closed.`,
      subTitle: `Please reopen settlement(s) before making any changes`,
      actionName: '',
      cancelText: 'Ok',
      backgroundColor: '#2B64CB',
      width: '500',
    },
    COMPANY_EXPENSE_IS_CHANGED: {
      title: 'Save Changes?',
      body: `Are you sure you want to change Company Card/Cash setting and/or Expense category?`,
      subTitle: `Change will cause the linked ${dynamicText} settlement item(s) to be deleted`,
      actionName: 'handleDeleteNonCompatiblePayments',
      buttonText: buttonText,
      cancelText: t('cancel'),
      backgroundColor: '#2B64CB',
      width: '500',
      data: data,
    },
  };

  return warningPopoverConfigConstant[configName];
};

export const handleOpenDeleteConfirmation = (
  setWarningPopoverConfig: React.Dispatch<
    React.SetStateAction<WarningPopoverConfigType | null>
  >,
  expenseDetailsData?: ExpenseDetails
) => {
  if (hasClosedStatePayment(expenseDetailsData?.payments)) {
    setWarningPopoverConfig(
      getExpenseWarningPopoverConfig({
        configName: 'CANNOT_DELETE_EXPENSE',
      })
    );
    return;
  }

  if (expenseDetailsData?.payments?.length) {
    setWarningPopoverConfig(
      getExpenseWarningPopoverConfig({
        configName: 'DELETE_EXPENSE_WITH_SETTLEMENT',
      })
    );
    return;
  }

  setWarningPopoverConfig(
    getExpenseWarningPopoverConfig({ configName: 'DELETE_EXPENSE' })
  );
};

export const handleOpenDeleteConfirmationForList = (
  setWarningPopoverConfig: React.Dispatch<
    React.SetStateAction<WarningPopoverConfigType | null>
  >,
  selectedExpenses?: Expense[]
) => {
  const dynamicText =
    selectedExpenses && selectedExpenses?.length > 1 ? 's' : '';
  if (selectedExpenses?.some((item) => hasClosedStatePayment(item?.payments))) {
    setWarningPopoverConfig(
      getExpenseWarningPopoverConfig({
        configName: 'CANNOT_DELETE_EXPENSE',
        dynamicText,
      })
    );
    return;
  }

  if (selectedExpenses?.some((item) => item?.payments?.length)) {
    setWarningPopoverConfig(
      getExpenseWarningPopoverConfig({
        configName: 'DELETE_EXPENSE_WITH_SETTLEMENT',
        actionName: 'handleDeleteExpenseBulk',
        dynamicText,
      })
    );
    return;
  }

  setWarningPopoverConfig(
    getExpenseWarningPopoverConfig({
      configName: 'DELETE_EXPENSE',
      actionName: 'handleDeleteExpenseBulk',
      dynamicText,
    })
  );
};

export const handleOpenChangeStatusConfirmationForList = (
  setWarningPopoverConfig: React.Dispatch<
    React.SetStateAction<WarningPopoverConfigType | null>
  >,
  selectedExpenses?: Expense[]
) =>
  setWarningPopoverConfig(
    getExpenseWarningPopoverConfig({
      configName: 'CHANGE_STATUS',
      dynamicText: selectedExpenses?.length?.toString(),
      secondaryDynamicText:
        selectedExpenses && selectedExpenses[0].status === 'IN_REVIEW'
          ? 'Reviewed'
          : 'Need Review',
    })
  );

export const handleCheckForUpdate = (
  expenseData: any,
  formState: FormState<any>,
  setWarningPopoverConfig: React.Dispatch<
    React.SetStateAction<WarningPopoverConfigType | null>
  >,
  handleUpdateExpense: (expenseData: ExpenseDetails) => void
) => {
  if (!expenseData.payments.length) {
    handleUpdateExpense(expenseData);
    return;
  }

  const { dirtyFields } = formState;

  if (
    hasClosedStatePayment(expenseData?.payments) &&
    (Object.keys(dirtyFields).includes('companyExpenseName') ||
      Object.keys(dirtyFields).includes('categoryObj') ||
      Object.keys(dirtyFields).includes('grossAmount') ||
      Object.keys(dirtyFields).includes('discountAmount') ||
      Object.keys(dirtyFields).includes('paidDate'))
  ) {
    setWarningPopoverConfig(
      getExpenseWarningPopoverConfig({
        configName: 'CANNOT_UPDATE_EXPENSE',
      })
    );
    return;
  }

  if (
    Object.keys(dirtyFields).includes('grossAmount') ||
    Object.keys(dirtyFields).includes('discountAmount')
  ) {
    setWarningPopoverConfig(
      getExpenseWarningPopoverConfig({
        configName: 'NET_EXPENSE_IS_CHANGED',
        data: expenseData,
      })
    );
    return;
  }

  if (
    Object.keys(dirtyFields).includes('categoryObj') ||
    Object.keys(dirtyFields).includes('equipmentObj') ||
    Object.keys(dirtyFields).includes('paidDate')
  ) {
    setWarningPopoverConfig(
      getExpenseWarningPopoverConfig({
        configName: 'EXPENSE_DETAILS_IS_CHANGED',
        data: expenseData,
      })
    );
    return;
  }

  const nonCompatiblePayments =
    getListOfPaymentsByCompatible(expenseData).nonCompatiblePayments;

  if (
    !hasClosedStatePayment(expenseData?.payments) &&
    Object.keys(dirtyFields).includes('companyExpenseName') &&
    !!nonCompatiblePayments.length
  ) {
    setWarningPopoverConfig(
      getExpenseWarningPopoverConfig({
        configName: 'COMPANY_EXPENSE_IS_CHANGED',
        dynamicText: nonCompatiblePayments[0].payCategoryName,
        data: expenseData,
        buttonText: 'Change',
      })
    );
    return;
  }

  handleUpdateExpense(expenseData);
};

export const handleCheckForCreate = (
  expenseData: any,
  formState: FormState<any>,
  setWarningPopoverConfig: React.Dispatch<
    React.SetStateAction<WarningPopoverConfigType | null>
  >,
  handleCreateExpense: (expenseData: ExpenseDetails) => void
) => {
  if (!expenseData.payments.length) {
    handleCreateExpense(expenseData);
    return;
  }

  const { dirtyFields } = formState;
  const nonCompatiblePayments =
    getListOfPaymentsByCompatible(expenseData).nonCompatiblePayments;

  if (
    !hasClosedStatePayment(expenseData?.payments) &&
    Object.keys(dirtyFields).includes('companyExpenseName') &&
    !!nonCompatiblePayments.length
  ) {
    setWarningPopoverConfig(
      getExpenseWarningPopoverConfig({
        configName: 'COMPANY_EXPENSE_IS_CHANGED',
        dynamicText: nonCompatiblePayments[0].payCategoryName,
        data: expenseData,
        buttonText: t('addExpense'),
      })
    );
    return;
  }

  handleCreateExpense(expenseData);
};
