import {
  IntegrationRequiredCredentialsDTO,
  IntergationsIDTO,
  ProviderFeaturesDTO,
  ProviderStatusMapDTO,
} from '../ELD/Requests';
import { QueryParams } from '../commonMixed/QueryParams';

export class GetLoadTendersRequest extends QueryParams {}

export class LoadTenderResponseDTO {
  integrationRequiredCredentials!: IntegrationRequiredCredentialsDTO[];
  integrationsDTO!: IntergationsIDTO[] | [];
  providerFeatures!: ProviderFeaturesDTO[];
  loadBoardIntegrations!: any;
  providersStatusMap!: ProviderStatusMapDTO[];
}

export class GetBrokersRequest extends QueryParams {}

export class AddBrokerRequest extends QueryParams {
  activationName!: string;
  broker!: string;
  clientId!: string;
  clientSecret!: string;
  brokerName!: string;
  turvoApiKey!: string;
  organizationId!: number;
  id!: string;
  password!: string;
  username!: string;
  carrierId!: string;
}

export class DeleteBrokerAccountRequest {
  id!: string;
  organizationId!: string | number;
}
