import * as MUIcons from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { FunctionComponent } from 'react';
import * as AlertIcons from '../Assets/AlertIcons/AlertIcons';
import * as AxeleIcons from '../Assets/AxeleIcons/AxeleIcons';
import * as CustomerIcons from '../Assets/CustomerLogos/customerIcons';
import * as CustomerLogos from '../Assets/CustomerLogos/customerLogos';
import * as OptymIcons from '../Assets/OptymIcons';

export interface Props {
  iconName?: string;
  source?: string;
  styleProps?: any;
}

const IconsComponent: FunctionComponent<Props> = ({
  iconName,
  source,
  styleProps,
}: Props) => {
  let sourceType = {};
  switch (source) {
    case 'MUIcons':
      sourceType = MUIcons;
      break;
    case 'AxeleIcon':
      sourceType = AxeleIcons;
      break;
    case 'CustomerLogos':
      sourceType = CustomerLogos;
      break;
    case 'CustomerIcons':
      sourceType = CustomerIcons;
      break;
    case 'AlertIcons':
      sourceType = AlertIcons;
      break;
    case 'OptymIcon':
      sourceType = OptymIcons;
      break;
  }
  const TempIcon = Object.entries(sourceType as any).find((key) => {
    return key[0] === iconName;
  });

  const Icon = TempIcon?.[1] as any;
  return (
    <SvgIcon
      sx={styleProps}
      component={source === 'MUIcons' ? Icon : null}
      children={
        source === 'MUIcons'
          ? null
          : typeof Icon === 'object'
          ? Icon
          : Icon({
              color: styleProps.color,
              width: styleProps.width,
              height: styleProps.height,
            })
      }
      inheritViewBox
    />
  );
};

export default IconsComponent;
