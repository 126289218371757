/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import AuthContext from '../../globalContexts/AuthContext';

export const PrivateRoute = ({ children }: { [props: string]: any }) => {
  const { user } = useContext(AuthContext);

  return user ? children : <Navigate to="/login" />;
};
