import moment from 'moment';
import { IMaintenanceHistory } from '../../../../models/DTOs/maintenance/maintenanceHistory/Model';
import IconsComponent from '../../../../ui-kit/components/IconsComponent';
import { numberWithThousandSeparator } from '../../../../utils';
import { currencyDisplay } from '../../../../utils/grid.utils';
import { EquipmentType } from '../../../../views/maintenanceRecords/constants/constants';
import GroupedTimeline from './GroupedTimeline';

interface GroupedMaintenanceProps {
  label: string;
  data: IMaintenanceHistory[];
  onUpdateMaintenanceClick?: any;
  selectedRecord: any;
}

export const GroupedMaintenance = ({
  label,
  data,
  onUpdateMaintenanceClick,
  selectedRecord,
}: GroupedMaintenanceProps) => {
  return data && data.length > 0 ? (
    <GroupedTimeline<IMaintenanceHistory>
      position={'right'}
      keyName="id"
      onRowClick={(record: IMaintenanceHistory) => {
        onUpdateMaintenanceClick?.(
          record.expense === 0 ? { ...record, expense: undefined } : record
        );
      }}
      value={selectedRecord?.id}
      label={label}
      data={data}
      renderTitle={(record: IMaintenanceHistory) => {
        return record.expense
          ? [
              record.historyMaintenanceItems[0]?.itemName,
              currencyDisplay(record.expense),
            ].join(', ')
          : record.historyMaintenanceItems[0]?.itemName;
      }}
      renderSubtitle={(record: IMaintenanceHistory) =>
        [
          record.equipmentType === EquipmentType.Tractor &&
            (record.odometer
              ? `${numberWithThousandSeparator(record.odometer)} ODO`
              : 'ODO Reading'),
          moment(record.completedDate).utcOffset(0, true).format('MM-DD-YYYY'),
        ]
          .filter(Boolean)
          .join(', ')
      }
      renderIcon={(record: IMaintenanceHistory) => (
        <IconsComponent
          source="MUIcons"
          iconName={record.historyMaintenanceItems[0]?.iconName}
          styleProps={{
            width: 20,
          }}
        />
      )}
      renderTooltip={(record: IMaintenanceHistory) => {
        return `Click to Modify “${record.historyMaintenanceItems[0]?.itemName}”`;
      }}
    />
  ) : (
    <></>
  );
};
