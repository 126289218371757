import React from 'react';
import { InvoiceModel } from '../../models/InvoiceModel';
import FormDialog from '../../../../common/Ui/FormDialog';
import { AttachmentsForm } from './InvoiceDownloadAction';

export const SendDocumentsForm = ({ invoice }: { invoice: InvoiceModel }) => {
  return (
    <>
      <AttachmentsForm
        invoice={invoice}
        subLabel={'(only PDF and image files are accepted)'}
      />
    </>
  );
};

export function SendDocumentsDialog({
  open,
  onAction,
  onClose,
  invoice,
}: {
  open: boolean;
  onAction: ({
    invoiceId,
    documentIds,
  }: {
    invoiceId: string;
    documentIds: string[];
  }) => void;
  onClose: () => void;
  invoice: InvoiceModel;
}) {
  return (
    <FormDialog
      data={{
        sendInvoice: false,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
        documentIds: [],
        invoiceId: invoice.id,
      }}
      titleText="Send Documents"
      actionLabel="Send"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <SendDocumentsForm invoice={invoice} />}
    />
  );
}
