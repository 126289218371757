import { useState } from 'react';
import { httpClient } from '../../../axios/axiosInstance';
import DetailsPanel from '../../../common/DetailsPanel';
import { FuelMatrix, QueryParams } from '../../../models';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import CreateFormContent from './create_contract_form';
import CreateContractTitle from './title_renderer';
import { saveFile } from '../../../utils/contract_payload_formatter';

interface IAddContractModalProps {
  data: any | undefined | null;
  panelTitle?: string | null | undefined;
  onClose: () => any;
  onCreatedContract: () => any;
  isDuplicate?: boolean;
  matrixData: FuelMatrix[];
}

const AddContractModal = (props: IAddContractModalProps) => {
  const {
    panelTitle,
    data,
    onClose,
    onCreatedContract,
    isDuplicate = false,
    matrixData,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [laneErr, setLaneErr] = useState({
    type: '',
    message: '',
  });
  const [laneErrIndex, setLaneErrIndex] = useState([]);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  }>({
    type: '',
    message: '',
  });

  const handleOnSubmit = async (values: any, attachedDocument?: any) => {
    setIsSaving(true);
    setErrorMessage({
      type: '',
      message: '',
    });
    try {
      const queryParams = new QueryParams();
      const response = await httpClient.postRaw(
        '/web/customer/api/v2/contracts/create',
        queryParams,
        values,
        true
      );
      await saveFile(response?.data, attachedDocument);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: `Successfully created contract ${response?.data?.name}`,
      });
      onCreatedContract?.(response);
      setIsSaving(false);
      return true;
    } catch (e) {
      const err = e?.getError?.()?.response?.data;
      if (err && err.validationType === 'NON_UNIQUE_NAME') {
        setErrorMessage({
          type: err.validationType,
          message: err.message,
        });
        setIsSaving(false);
        return;
      }
      if (err && err.validationType && err.message) {
        setLaneErr({
          type: err.validationType,
          message: err.message,
        });
        setLaneErrIndex(err?.errors);
        setIsSaving(false);
        return;
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'createContractErr',
      });
      setIsSaving(false);
    }
  };
  return (
    <DetailsPanel
      panelType="Contract"
      height={'100%'}
      isGlobal={true}
      showDetailsPanelLoader={false}
      panelTitle={panelTitle}
      titleRenderer={() => (
        <CreateContractTitle title={panelTitle} onClose={onClose} />
      )}
      data={data}
      onClose={onClose}
      contentRenderer={() => (
        <CreateFormContent
          isDuplicate={isDuplicate}
          id={data?.id}
          isCreate={true}
          title={panelTitle}
          onClose={() => onClose()}
          onSubmit={handleOnSubmit}
          isSaving={isSaving}
          formError={errorMessage}
          contractData={{
            customerName: data?.contractName,
            customerId: data?.customerId,
          }}
          error={laneErr}
          errorIndex={laneErrIndex}
          matrixData={matrixData}
        />
      )}
      autoExpanded={true}
      footerRenderer={() => null}
      showTitle={false}
      fullScreenCustomStyles={{
        background: 'none',
        boxShadow: 'none',
      }}
      contentCustomStyles={{}}
      panelWrapperCustomStyles={{
        background: 'rgba(3, 14, 24, 0.89)',
        width: '100%',
        maxWidth: '1500px',
        ml: 'auto',
        mr: 'auto',
      }}
      formCustomStyles={{ height: '48rem' }}
    />
  );
};

export default AddContractModal;
