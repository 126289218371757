import { Stack, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { dashboardPage } from '../../common/PendoClassIDs/constants';
import { performNaivgation } from '../../utils/commonNavigation';
import { CustomDashboardLoader } from '../../views/home/CustomDashboardLoader';
import { useThemeResponsive } from '../hooks/useThemeResponsive';
import {
  StyledSafetyStandingCard,
  StyledSafetyStandingItem,
} from './styles/safetyStanding.styles';
interface SafetyStandingCardItemProps {
  total: number;
  title: string;
  color?: string;
  background?: string;
  id?: string;
  loader?: boolean;
}
const SafetyStandingCardItem = ({
  total,
  title,
  color,
  background,
  id,
  loader,
}: SafetyStandingCardItemProps): JSX.Element => {
  const navigate = useNavigate();
  return (
    <StyledSafetyStandingItem
      id={id + dashboardPage}
      onClick={() => performNaivgation(id, navigate)}
      color={color}
      background={background}
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <CustomDashboardLoader loading={!!loader} size="40px" />
      {!loader && (
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '48px',
            lineHeight: '48px',
          }}
        >
          {total}
        </Typography>
      )}
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '18px',
        }}
      >
        {' '}
        {title}
      </Typography>
    </StyledSafetyStandingItem>
  );
};
export interface SafetyStandingCardProps {
  title: string;
  warning: number;
  critical: number;
  warningId: string;
  criticalId: string;
  loader: boolean;
}
export const SafetyStandingCard = ({
  title,
  warning,
  critical,
  warningId,
  criticalId,
  loader,
}: SafetyStandingCardProps): JSX.Element => {
  const theme: any = useTheme();
  const { isLaptop } = useThemeResponsive();
  return (
    <StyledSafetyStandingCard>
      <Typography
        component={'div'}
        align="center"
        variant="h7"
        color={'text.primary'}
      >
        {title}
      </Typography>
      <Stack
        direction="row"
        // justifyContent="space-between"
        alignItems="center"
        spacing={isLaptop ? 1 : 2}
        // justifyItems={'center'}
        marginTop={1}
      >
        <SafetyStandingCardItem
          id={warningId}
          total={warning}
          title={t('warning')}
          loader={loader}
        />
        <SafetyStandingCardItem
          id={criticalId}
          total={critical}
          title={t('critical')}
          color={theme.palette.error.main}
          background={theme.palette.error.outlinedHoverBackground}
          loader={loader}
        />
      </Stack>
    </StyledSafetyStandingCard>
  );
};
