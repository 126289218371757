import React, { useEffect, useState } from 'react';
import Email from '../EmailFormContainer/Email';
import DocumentActions from '../DocumentActions';
import { docType } from '../../../../utils/Doc';

export default function EmailDocument({
  handleEmailDialogClose,
  ids,
}: {
  handleEmailDialogClose: () => void;
  ids: string[];
}) {
  const [files, setFiles] = useState<docType[]>([]);

  useEffect(() => {
    const getFiles = async () => {
      const result = await DocumentActions.downloadDocuments(ids, false, true);
      if (result) {
        setFiles(result);
      }
    };
    getFiles();
  }, []);

  const emailDocuments = async (data: {
    to: string;
    message: string;
    subject: string;
    cc: string;
  }) => {
    handleEmailDialogClose();
    await DocumentActions.emailDocuments(data, files);
  };

  return (
    <Email
      handleUploadDialogClose={handleEmailDialogClose}
      emailDocument={emailDocuments}
      files={files}
      setFiles={setFiles}
    />
  );
}
