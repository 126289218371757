import React, { FC } from 'react';
import {
  GridColumnMenuContainer,
  GridColumnMenuProps,
} from '@mui/x-data-grid-pro';
import { LoadboardGridColDefSelf, SortField } from '../grid.config';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

interface CustomSortProps extends GridColumnMenuProps {
  value?: string;
  onValueChange?: (
    e: React.MouseEvent<HTMLButtonElement>,
    sortField: SortField
  ) => void;
}

const CustomSort: FC<CustomSortProps> = ({
  value,
  onValueChange,
  ...props
}) => {
  const { sortFields } = props.currentColumn as LoadboardGridColDefSelf;
  return (
    <GridColumnMenuContainer {...props}>
      {sortFields && sortFields.length > 0 && (
        <Box>
          <FormControl fullWidth>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              sx={{ padding: '5px 2px 0px 5px' }}
            >
              {sortFields.map((sortField, index) => (
                <>
                  <FormControlLabel
                    key={index}
                    value={sortField.sortingKey}
                    control={
                      <Radio
                        checked={value === sortField.sortingKey}
                        onClick={(event) => onValueChange?.(event, sortField)}
                      />
                    }
                    label={sortField.label}
                  />
                </>
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      )}
    </GridColumnMenuContainer>
  );
};

export default CustomSort;
