import { Moment } from 'moment';
import {
  fromIsoToMoment,
  getAssignedDocumentsFromDto,
  getPhoneFromDTO,
} from '../../../utils';
import { Model } from '../../../models/common/Model';
import { IAssignedDocuments, PhoneType } from '../../../types';

export class Trailer extends Model {
  status!: string;
  trailerName: string;
  vin: string;
  licensePlateNumber: string;
  licenseState: string | null;
  validTill: Moment | null;
  lastUpdated: Moment | null;
  assignedDocuments: IAssignedDocuments;
  phone: PhoneType;
  ownedCompany: string;
  ownerEmail: string;
  ownerAddress: string;
  lastLocation: string;
  ownershipType: string;
  make: string;
  model: string;
  year: number;
  gvw: number;
  type: string | null;
  trailerLength: number | null;
  payloadCapacity: number | null;
  terminalId?: any;
  locationSource: string;
  insuranceCompany: string;
  insuranceStartDate: Moment | null;
  insuranceEndDate: Moment | null;
  insuranceDate: [Moment, Moment];
  driverGroup: { name: string; id: string } | null;
  constructor(dto: any) {
    super(dto);
    this.status = dto.status;
    this.trailerName = dto.trailerName;
    this.vin = dto.vin;
    this.terminalId = dto.terminalId;
    this.licensePlateNumber = dto.licensePlateNumber;
    this.licenseState = dto.licenseState;
    this.lastLocation = dto.assetLocationDTO?.addressDTO;
    this.lastUpdated = fromIsoToMoment(dto.assetLocationDTO?.time);
    this.locationSource = dto.assetLocationDTO?.locationSource;
    this.validTill = fromIsoToMoment(dto.validTill);
    this.assignedDocuments = getAssignedDocumentsFromDto(dto, 'TRAILER');
    this.phone = getPhoneFromDTO(dto);
    this.ownedCompany = dto.ownedCompany;
    this.ownerEmail = dto.ownerEmail;
    this.ownerAddress = dto.ownerAddress;
    this.ownershipType = dto.ownershipType;
    this.make = dto.make;
    this.model = dto.model;
    this.year = dto.year;
    this.gvw = dto.gvw;
    this.payloadCapacity = dto.payloadCapacity;
    this.type = dto.trailerType;
    this.trailerLength = dto.trailerLength;
    this.insuranceCompany = dto.insuranceCompany;
    this.insuranceStartDate = fromIsoToMoment(dto.insuranceStartDate);
    this.insuranceEndDate = fromIsoToMoment(dto.insuranceEndDate);
    this.insuranceDate = [
      dto.insuranceStartDate || null,
      dto.insuranceEndDate || null,
    ];
    this.driverGroup = dto.groupDetails
      ? {
          name: dto.groupDetails.groupName,
          id: dto.groupDetails.id,
        }
      : null;
  }
}
