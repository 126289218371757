import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FileDnDIcon } from '../../../../ui-kit/components/Assets';
import React, { ReactNode, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import { SUPPORTED_FILE_SIZE } from '../../../../constants/fileSizeConstants';
import { FileType } from '../../../../types';
import uuid from '../../../../utils/uuid';
import Albom from '../Albom';
import UploadButton from '../../../../ui-kit/components/UploadButton';
import { containerStyle, main, styles } from './style';
import { LoadConstants } from '../../../../locales/en/allLoads/loads/index';
/* @TODO this file should be rewritten */

export const toBase64 = (file: File) =>
  new Promise<{
    src: string | ArrayBuffer | null;
    fileName: string;
    size: number;
  }>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({
        src: reader.result,
        fileName: file.name,
        size: file.size,
      });
    reader.onerror = (error) => reject(error);
  });

const accept =
  'image/*,application/pdf,.doc,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf';

export const docFileFormatsRegexp = new RegExp(
  /\.(?:image|jpg|JPG|jpeg|gif|GIF|doc|DOC|pdf|PDF|png|PNG|docx|xls|xlsx)/
);

const matchExp = (value: string, regexp: RegExp) => regexp.test(value);

// AXL2-4505: After DnD component enhancemnet/refactoring config approach is possible to be removed. It is used for Create Load custom case fixes.
const defaultConfigs = {
  uploadText: 'Drag & Drop Your File Here',
  uploadBtnLabel: LoadConstants.loadsDocumentsBrowseToUpload,
  showDnDIcon: true,
};

export type viewFileType = {
  fileName: any;
  id: string;
  src: string;
  size: number;
};

interface IProps {
  viewFile: viewFileType[] | {};
  isDarkBackground?: boolean;
  setViewFile: (data: viewFileType[]) => void;
  setFile: (file: FileType[]) => void;
  file?: any;
  loadUpload?: boolean;
  warning?: string;
  uploadHandler?: (files: FileType[], error: string) => void;
  containerCustomStyles?: SxProps<Theme>;
  consumerConfigs?: Configs;
  isCreateNewLoad?: boolean;
}

type Configs = {
  key?: string;
  uploadText: string;
  uploadBtnLabel: string;
  showDnDIcon?: boolean;
  filesNameRenderer?: () => ReactNode;
};

function DnD({
  setFile,
  viewFile,
  setViewFile,
  isDarkBackground = false,
  file,
  warning = '',
  consumerConfigs,
  containerCustomStyles = {},
  uploadHandler,
  isCreateNewLoad = false,
}: IProps) {
  const { isMobile } = useThemeResponsive();
  const configs = consumerConfigs
    ? { ...consumerConfigs }
    : { ...defaultConfigs };
  const [error, setError] = useState('');
  const color = isDarkBackground ? '#ffffff' : '#2B64CB';

  const hasUploadedFiles = Boolean(viewFile?.length);

  const onDrop = useCallback(async (acceptedFiles: FileType[]) => {
    const isSupportedType = matchExp(
      acceptedFiles[0].name.toLowerCase(),
      docFileFormatsRegexp
    );
    let error = '';
    if (!isSupportedType) {
      error = 'The file type is not supported.';
      setError(error);
      if (typeof uploadHandler === 'function') {
        uploadHandler([], error);
      }
      return;
    } else if (acceptedFiles[0].size > SUPPORTED_FILE_SIZE) {
      error = 'File Size can not be greater than 20 MB.';
      if (typeof uploadHandler === 'function') {
        uploadHandler([], error);
      }
      setError(error);
      return;
    }

    setError(error);
    if (typeof uploadHandler === 'function') {
      uploadHandler(acceptedFiles, error);
      return;
    }
    setFile(acceptedFiles);

    const base64Files = acceptedFiles.map(async (file: File) => {
      return await toBase64(file);
    });
    Promise.all(base64Files)
      .then((results) => {
        const base64File = results.map(({ src, fileName }) => ({
          id: uuid(),
          src,
          fileName,
        }));
        setViewFile(base64File);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    noKeyboard: true,
  });

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      onDrop(files);
    }
  };

  const setFiles = (restFiles: any) => {
    setViewFile(restFiles);
    setFile(restFiles);
  };

  const fileRenderer = () => {
    if (
      configs.key === 'CreateLoadPanel' &&
      typeof configs.filesNameRenderer === 'function'
    ) {
      return configs.filesNameRenderer();
    }
    return (
      <>
        <Albom viewFile={viewFile} setFiles={setFiles} />
        <div style={{}}>
          <UploadButton
            multiple
            onUpload={onUpload}
            accept={accept}
            text="Replace"
          />
        </div>
      </>
    );
  };

  const SharedUploadButton = () => {
    return (
      <UploadButton
        onUpload={onUpload}
        accept={accept}
        text={configs.uploadBtnLabel}
        multiple
        {...(isMobile && {
          sxProps: {
            width: '100%',
          },
        })}
      />
    );
  };

  return (
    <Box
      {...(!isMobile && {
        sx: main,
        ...getRootProps(),
      })}
      onClick={(e) => e.stopPropagation()}
    >
      {isMobile && isCreateNewLoad ? (
        <SharedUploadButton />
      ) : (
        <>
          {(!hasUploadedFiles || error || warning) && (
            <Box sx={{ ...styles.main, color }}>
              <Box sx={{ ...styles.container, ...containerCustomStyles }}>
                {configs.showDnDIcon && (
                  <FileDnDIcon color="#2B64CB" width={'58px'} height={'46px'} />
                )}
                <input className="dropzone-input" {...getInputProps()} />
                <Box sx={!configs.key && styles.text}>{configs.uploadText}</Box>
                <Box sx={!configs.key && styles.or}>OR</Box>
                {!configs.key && error && (
                  <Typography
                    variant={'body1'}
                    sx={
                      !configs.key && {
                        color: '#E31B0C',
                        marginBottom: '20px',
                        fontSize: 16,
                      }
                    }
                  >
                    {error}
                  </Typography>
                )}
                <SharedUploadButton />
              </Box>
            </Box>
          )}
        </>
      )}

      {hasUploadedFiles && !error && !warning && (
        <Box sx={containerStyle}>{fileRenderer()}</Box>
      )}
    </Box>
  );
}

export default DnD;
