import { fromIsoToMoment, getNullableModel } from '../../../utils';
import { Moment } from 'moment';
import { Model } from '../../../models/common/Model';
import { DriverShort, TerminalShort } from '../../../models/common/modelsShort';
import {
  DriverGroupBaseDetails,
  TrailerAlertSummaryDTO,
} from '../../../models';

export class TrailerSummary extends Model {
  trailerName: string;
  activeLoad!: { loadId: string; seqNumber: string } | null;
  status: string;
  groupDetails: DriverGroupBaseDetails | undefined;
  driver: DriverShort | null;
  terminal: TerminalShort | null;
  vin: string;
  licensePlateNumber: string;
  validTill: Moment | null;
  ownershipType: string;
  ownedCompany: string;
  warning: number;
  critical: string;
  trailerType: string;
  alertsList?: TrailerAlertSummaryDTO[] | undefined;
  lastLocation?: string;
  lastUpdated?: Moment | null;
  locationSource?: string;
  constructor(dto: any) {
    super(dto);
    this.trailerName = dto.trailerName;
    this.status = dto.status;
    this.groupDetails = dto.groupDetails;
    this.driver = getNullableModel(DriverShort, dto.groupDetails);
    this.terminal = getNullableModel(TerminalShort, dto.terminal);
    this.vin = dto.vin;
    this.activeLoad = dto.activeLoad;
    this.lastLocation = dto.assetLocationDTO?.addressDTO?.fullAddress;
    this.lastUpdated = fromIsoToMoment(dto.assetLocationDTO?.time);
    this.locationSource = dto.assetLocationDTO?.locationSource;
    this.licensePlateNumber = dto.licensePlateNumber;
    this.validTill = fromIsoToMoment(dto.validTill);
    this.ownershipType = dto.ownershipType;
    this.trailerType = dto.trailerType;
    this.ownedCompany = dto.ownedCompany;
    this.warning = dto.warningSafetyIssues;
    this.critical = dto.criticalSafetyIssues;
  }
}
