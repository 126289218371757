export const allLoadsStripId = 'allLoads';
export const needDriversStripId = 'needDrivers';
export const activeLoadsStripId = 'activeLoads';
export const toBeInvoicedStripId = 'toBeInvoiced';
export const toBeSettledStripId = 'toBeSettled';
export const pastLoadsStripId = 'pastLoads';

export const loadStatusesCompleted = [
  'LOAD_COMPLETED',
  'CANCELLED',
  'INVOICED',
  'PAID',
];
