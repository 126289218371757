import { Box, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ediService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { EDIProviderDTO } from '../../../../models/DTOs/EDI/Requests';
import { EDICompanyCard } from './EDICompanyCard';
import { getStyledEDIActivation } from './styles';
import ProviderHero from '../../../../ui-kit/components/ProviderHero';

const EDIActivation = () => {
  const [providers, setProviders] = useState<EDIProviderDTO[]>([]);
  const init = async () => {
    const data = await ediService.getProviders();
    if (data instanceof ServiceError) {
      setProviders([]);
    } else {
      setProviders(data || []);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const styles = getStyledEDIActivation();
  const ediData = {
    heading1: 'Electronic Data Interchange (EDI)',
    intro:
      'LoadOps EDI allows carriers to exchange information electronically with other businesses.With this feature, carriers can view loads shared on EDI, book or reject loads, and send shipment status updates. The feature also includes an automatic load creation in LoadOps, which eliminates manual data entry and reduces the risk of errors.This helps to increase profits and reduce manual workload, resulting in a more efficient and streamlined shipping process.',
    outtro:
      'Please contact LoadOps support team to activate or deactivate a customer integration.',
    email: 'loadops.support@optym.com',
    SCACCode: 'TRKN',
  };
  const companyList = providers.map((item: EDIProviderDTO) => {
    return {
      id: item.id,
      title: item.providerName,
      activeCommunications: [
        'Incoming Tenders (204)',
        'Tender Responses (990)',
        'Shipment Status Updates (214)',
        'Invoices (21)',
      ],
      scac: item.scac,
    };
  });
  return (
    <Box sx={styles.root}>
      <ProviderHero
        currentCustomerData={{
          status: companyList?.length && 'VALID',
          logoWhite: (
            <svg
              width="207"
              height="33"
              viewBox="0 0 207 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.87 26V1.85H5.91V21.45H16.34V26H0.87ZM19.6694 20.33V13.19C19.6694 9.41 21.5594 7.52 25.3394 7.52H30.2395C34.0194 7.52 35.9094 9.41 35.9094 13.19V20.33C35.9094 24.11 34.0194 26 30.2395 26H25.3394C21.5594 26 19.6694 24.11 19.6694 20.33ZM24.4994 20.68C24.4994 21.4267 24.8728 21.8 25.6194 21.8H29.9594C30.7061 21.8 31.0794 21.4267 31.0794 20.68V12.84C31.0794 12.0933 30.7061 11.72 29.9594 11.72H25.6194C24.8728 11.72 24.4994 12.0933 24.4994 12.84V20.68ZM45.607 26C41.827 26 39.937 24.145 39.937 20.435C39.937 16.725 41.827 14.87 45.607 14.87H50.437V12.84C50.437 12.0933 50.0637 11.72 49.317 11.72H41.897V9.095L43.997 7.52H49.597C53.377 7.52 55.267 9.41 55.267 13.19V26H45.607ZM44.767 20.68C44.767 21.4267 45.1403 21.8 45.887 21.8H50.437V18.58H45.887C45.1403 18.58 44.767 18.9533 44.767 19.7V20.68ZM60.1348 20.33V13.19C60.1348 9.41 62.0248 7.52 65.8048 7.52H71.1948V0.0999997H76.0248V26H65.8048C62.0248 26 60.1348 24.11 60.1348 20.33ZM64.9648 20.68C64.9648 21.4267 65.3381 21.8 66.0848 21.8H71.1948V11.72H66.0848C65.3381 11.72 64.9648 12.0933 64.9648 12.84V20.68Z"
                fill="#1DA59E"
              />
              <path
                d="M81.314 20.12V7.73C81.314 3.81 83.274 1.85 87.194 1.85H95.174C99.094 1.85 101.054 3.81 101.054 7.73V20.12C101.054 24.04 99.094 26 95.174 26H87.194C83.274 26 81.314 24.04 81.314 20.12ZM86.354 20.4C86.354 21.1 86.704 21.45 87.404 21.45H94.964C95.664 21.45 96.014 21.1 96.014 20.4V7.45C96.014 6.75 95.664 6.4 94.964 6.4H87.404C86.704 6.4 86.354 6.75 86.354 7.45V20.4ZM106.349 33V7.52H116.569C120.349 7.52 122.239 9.41 122.239 13.19V20.33C122.239 24.11 120.349 26 116.569 26H111.179V33H106.349ZM111.179 21.8H116.289C117.036 21.8 117.409 21.4267 117.409 20.68V12.84C117.409 12.0933 117.036 11.72 116.289 11.72H111.179V21.8ZM126.618 24.425V21.8H134.668C135.461 21.8 135.858 21.3567 135.858 20.47C135.858 19.56 135.531 19.0467 134.878 18.93L131.098 18.3C128.111 17.7867 126.618 16.06 126.618 13.12C126.618 9.38667 128.508 7.52 132.288 7.52H137.713L139.813 9.095V11.72H132.568C131.775 11.72 131.378 12.1633 131.378 13.05C131.378 13.9833 131.74 14.5083 132.463 14.625L136.208 15.255C139.171 15.745 140.653 17.4483 140.653 20.365C140.653 24.1217 138.751 26 134.948 26H128.718L126.618 24.425ZM154.149 26V1.85H171.054V7.345H160.239V10.95H170.004V16.445H160.239V20.505H171.054V26H154.149ZM175.8 26V1.85H184.83C191.783 1.85 195.26 5.32667 195.26 12.28V15.57C195.26 22.5233 191.783 26 184.83 26H175.8ZM181.89 20.505H185.18C187.84 20.505 189.17 19.1633 189.17 16.48V11.37C189.17 8.68667 187.84 7.345 185.18 7.345H181.89V20.505ZM200.288 26V1.85H206.378V26H200.288Z"
                fill="white"
              />
            </svg>
          ),
          gradient:
            'linear-gradient(90deg, rgba(53,68,107,1) 0%, rgba(53,68,107,1) 100%)',
        }}
      />
      <Box className={'body'} sx={styles.bodyRoot}>
        <Typography variant="h4" sx={styles.heading1}>
          {ediData.heading1}
        </Typography>
        <Typography sx={styles.intro}>{ediData.intro}</Typography>
        <Typography sx={styles.outtro}>{ediData.outtro}</Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            width: '571px',
            pb: 4,
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={0}
          >
            <Typography sx={styles.itemLabel}>Email</Typography>
            <Typography sx={styles.itemValue}>{ediData.email}</Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={0}
          >
            <Typography sx={styles.itemLabel}>Your SCAC Code</Typography>
            <Typography sx={styles.itemValue}>
              {companyList[0]?.scac}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {companyList.map((item) => (
            <EDICompanyCard
              key={item.id}
              title={item.title}
              activeCommunications={item.activeCommunications}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default observer(EDIActivation);
