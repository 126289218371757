import { Box, Link, Typography, useTheme } from '@mui/material';
import {
  ExpenseCategory,
  ExpenseStatus,
} from '../../common/Ui/StatusComponent/StatusComponent';
import { GridColDefSelf } from '../../types';
import {
  CompanyExpenseIcon,
  GridFileIcon,
} from '../../ui-kit/components/Assets';
import {
  amountFormat,
  fromIsoToMoment,
  getFormattedDateForGrid,
  gridPageSize,
  nullableDateYup,
  nullableNumberYup,
  nullableStringYup,
  numberWithThousandSeparator,
} from '../../utils';

import * as yup from 'yup';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { DirtyDetailsPanelManager } from '../../common/DetailsPanel/utils';
import { AXELE_PERMISSION_TYPE } from '../../common/Permission';
import { ExpensesConstant } from '../../locales/en/finance/expenses/index';
import {
  ExpenseDetails,
  ExpensesEntityTypeEnum,
  loadsIdAndNameType,
  PayItemDTO,
  PayStatementsTypeEnum,
  SetSecondaryPanelDataType,
} from '../../models';
import { calculateSettlementAmount } from '../../utils/expense';
import { ESecondaryDetailsPanelType } from '../../views/dispatch/constants/types';
import { getStyledHyperLink } from '../../views/trips/styles';
import { getMaintenanceList, getSettlementIdToSow } from './utils/utils';
export const concatWithCommas = (data: any) => {
  if (Array.isArray(data)) {
    return data.join(', ');
  }
  return data;
};

export const paymentTypeValidationSchema = yup.object().shape({
  payingEntityObj: yup
    .object()
    .nullable()
    .when('paymentType', (value, schema) => {
      const paymentTypeName =
        value === PayStatementsTypeEnum.driver ? 'driver' : 'tractor';
      return schema.required(`${paymentTypeName} is required`);
    }),
});

export const paymentValidationSchema = paymentTypeValidationSchema.concat(
  yup.object().shape({
    amount: nullableNumberYup()
      .required('amount is required')
      .min(0.0001, 'Value must be between 0.0001 and 99,999.9999')
      .max(99999.9999, 'Value must be between 0.0001 and 99,999.9999')
      .test('amount', (payAmount, context) => {
        const { expenseAmount, settlementAmount, companyExpense } =
          context.parent;

        if (expenseAmount - settlementAmount <= 0) {
          return context.createError({
            message: companyExpense
              ? `Value Cannot be more than net expense.`
              : `Value Cannot be more than expense amount.`,
            path: context.path,
          });
        }

        if (settlementAmount + payAmount > expenseAmount) {
          return context.createError({
            message: `Value must be between 0.0001 and ${
              expenseAmount - settlementAmount
            }`,
            path: context.path,
          });
        }
        return context.schema;
      }),
  })
);

export const paymentsListValidationSchema = paymentTypeValidationSchema.concat(
  yup.object().shape({
    amount: nullableNumberYup()
      .required('amount is required')
      .min(0.0001, 'Value must be between 0.0001 and 99,999.9999')
      .max(99999.9999, 'Value must be between 0.0001 and 99,999.9999')
      .test('amount', (amount, context) => {
        if (typeof context.originalValue !== 'string') return context.schema;

        const expense = context.from[1].value;
        const settlementAmount = calculateSettlementAmount(expense?.payments);

        if (settlementAmount > expense.amount) {
          return context.createError({
            message: `Sum of this expense settlements cannot be greater than ${expense.amount}`,
            path: context.path,
          });
        }
        return context.schema;
      }),
  })
);

export const terminalValidationSchema = yup.object().shape({
  terminal: yup.object().nullable().required('Terminal is required'),
});

export const otherExpenseDetailsFormValidationSchema = yup.object().shape(
  {
    paidDate: nullableDateYup().required('Date is required'),
    cardNumber: nullableStringYup().max(100),
    referenceNumber: nullableStringYup().max(100),
    grossAmount: nullableNumberYup()
      .required('Gross Amount is required')
      .min(0.0001, 'Value must be between 0.0001 and 99,999.9999')
      .max(99999.9999, 'Value must be between 0.0001 and 99,999.9999'),
    discountAmount: nullableNumberYup().when(
      ['grossAmount', 'discountAmount'],
      (grossAmount, discountAmount) => {
        if (discountAmount > grossAmount)
          return nullableNumberYup().max(
            grossAmount,
            `Value cannot be greater than ${grossAmount}`
          );
        return nullableNumberYup()
          .min(0, 'Value must be between 0 and 99,999.9999')
          .max(99999.9999, 'Value must be between 0.0001 and 99,999.9999');
      }
    ),
    description: nullableStringYup().max(255),
    truckstopAddress: nullableStringYup().max(100),
    categoryObj: yup
      .object()
      .nullable()
      .required('Expense Category is required'),

    payments: yup.array().of(paymentsListValidationSchema).compact(),
  },
  [
    ['discountAmount', 'grossAmount'],
    ['grossAmount', 'discountAmount'],
    ['grossAmount', 'grossAmount'],
    ['discountAmount', 'discountAmount'],
  ]
);

export const fuelExpenseDetailsFormValidationSchema =
  otherExpenseDetailsFormValidationSchema.concat(
    yup.object().shape({
      fuelQuantity: nullableNumberYup()
        .required('Fuel Quantity is required')
        .min(1, 'Value must be between 1 and 99,999')
        .max(99999, 'Value must be between 1 and 99,999'),
      fuelType: yup.object().nullable().required('Fuel Type is required'),
      state: yup.object().nullable().required('State is required'),
      equipmentObj: yup
        .object()
        .nullable()
        .when('equipmentType', (value, schema) => {
          const equipmentTypeName = value === 'TRACTOR' ? 'Tractor' : 'Trailer';
          return schema.required(`${equipmentTypeName} is required.`);
        }),
    })
  );

export const getExpenseDetailsFormValidationSchema = (
  isFuelExpense: boolean,
  IsTerminalEnabled: boolean
) => {
  const schema = isFuelExpense
    ? fuelExpenseDetailsFormValidationSchema
    : otherExpenseDetailsFormValidationSchema;
  return IsTerminalEnabled ? schema.concat(terminalValidationSchema) : schema;
};

export const getDefaultRequestData = (entity: ExpensesEntityTypeEnum) => {
  const allExpenseListDefaultRequestData = {
    pageNumber: 1,
    pageSize: gridPageSize,
    sort: '-paidDate',
  };

  const fuelListDefaultRequestData = {
    ...allExpenseListDefaultRequestData,
    categoryKey: ['FUEL'],
  };
  const otherListDefaultRequestData = {
    ...allExpenseListDefaultRequestData,
    excludeCategoryKey: ['FUEL'],
  };

  switch (entity) {
    case ExpensesEntityTypeEnum.fuelExpense:
      return fuelListDefaultRequestData;

    case ExpensesEntityTypeEnum.otherExpense:
      return otherListDefaultRequestData;

    default:
      return allExpenseListDefaultRequestData;
  }
};

export const statuses: any = {
  IN_REVIEW: 'Need Review',
  REVIEWED: 'Reviewed',
};

export const getExpensesColumns = (
  setSecondaryPanelData: React.Dispatch<
    React.SetStateAction<SetSecondaryPanelDataType | null>
  >,
  entity: ExpensesEntityTypeEnum,
  onClose: () => void,
  handleMaintenanceOnClick?: (
    expenseId: string,
    isGlobal?: boolean,
    expenseDetailsData?: ExpenseDetails
  ) => void
): GridColDefSelf[] => {
  const theme = useTheme();

  const fuelColumns: GridColDefSelf[] = [
    {
      flex: 1,
      field: 'cardNumber',
      headerName: t('fuelCard'),
      sortable: false,
      minWidth: 100,
    },
    {
      flex: 1,
      field: 'fuelQuantity',
      headerName: t('fuelQuantity'),
      sortable: false,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => {
        return `${numberWithThousandSeparator(params.value)}`;
      },
    },
  ];
  const expensesColumns: GridColDefSelf[] = [
    {
      flex: 1,
      field: 'seqNumber',
      headerName: 'ID',
      sortable: true,
      permanent: true,
      minWidth: 70,
    },
    {
      flex: 1,
      field: 'paidDate',
      headerName: 'Date',
      sortable: true,
      minWidth: 200,
      valueGetter: (params) => getFormattedDateForGrid(params.row.paidDate),
    },
    {
      flex: 1,
      field: 'status',
      headerName: 'Status',
      sortable: true,
      minWidth: 200,
      renderCell: (params) => {
        const { status } = params.row;
        return <ExpenseStatus status={status} />;
      },
    },
    {
      flex: 1,
      field: 'category',
      headerName: 'Category',
      sortable: true,
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.pinned) return <></>;
        const { categoryName } = params.row;
        return <ExpenseCategory category={categoryName} />;
      },
    },
    {
      flex: 1,
      field: 'equipmentName',
      headerName: 'Equipment',
      sortable: false,
      minWidth: 150,
      renderCell: (params) => {
        const { equipmentName, equipmentType, equipmentId } = params.row;

        if (!equipmentName) return '';
        return (
          <Link
            variant="h7"
            key={equipmentName}
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              whiteSpace: 'pre-wrap',
            }}
            onClick={(event) => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              event.stopPropagation();

              onClose();

              setSecondaryPanelData({
                type: equipmentType,
                id: equipmentId,
              });
            }}
          >
            {equipmentName}
          </Link>
        );
      },
    },
    {
      flex: 1,
      field: 'maintenanceDetails',
      headerName: t('maintenanceDetails'),
      sortable: false,
      minWidth: 150,
      renderCell: (params) => {
        const maintenanceData = getMaintenanceList(params.value);
        return (
          <Box
            sx={{
              ...getStyledHyperLink({ theme }),
              color: 'primary.main',
              variant: 'h7',
            }}
            onClick={(event) => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              event.stopPropagation();

              onClose();
              handleMaintenanceOnClick &&
                handleMaintenanceOnClick(params?.row?.id, false, params?.row);
            }}
          >
            {maintenanceData?.join(', ')}{' '}
          </Box>
        );
      },
    },
    {
      flex: 1,
      field: 'driverName',
      headerName: 'Driver',
      sortable: false,
      minWidth: 150,
    },
    {
      flex: 1,
      field: 'loadsIdAndName',
      headerName: t('loadId'),
      sortable: false,
      minWidth: 150,
      renderCell: (params) => {
        const { loadsIdAndName } = params.row;
        return !!loadsIdAndName?.length
          ? loadsIdAndName.map((item: loadsIdAndNameType, index: number) => {
              return (
                <div key={item.seqNumber}>
                  <Link
                    variant="h7"
                    key={item.seqNumber}
                    sx={{
                      color: 'primary.main',
                      textDecoration: 'underline',
                      whiteSpace: 'pre-wrap',
                    }}
                    onClick={(event) => {
                      if (DirtyDetailsPanelManager.isShouldPanelShake()) return;

                      event.stopPropagation();
                      onClose();

                      setSecondaryPanelData({
                        type: ESecondaryDetailsPanelType.LOAD,
                        id: item.loadIds[0],
                      });
                    }}
                  >
                    {item.seqNumber}
                  </Link>
                  <Typography
                    variant="h7"
                    key={item.seqNumber}
                    sx={{
                      color: 'text.primary',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {loadsIdAndName.length > index + 1 ? ';  ' : ''}
                  </Typography>
                </div>
              );
            })
          : null;
      },
    },
    {
      flex: 1,
      field: 'companyExpense',
      headerName: t('companyCardCash'),
      sortable: true,
      minWidth: 170,
      renderCell: (params) => {
        const { companyExpense } = params.row;
        return companyExpense ? <CompanyExpenseIcon /> : '';
      },
    },
    {
      flex: 1,
      field: 'amount',
      headerName: t('expenseAmount') + ' ($)',
      sortable: true,
      minWidth: 200,
      renderCell: (params) => {
        const { amount } = params.row;

        return (
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            {!!amount ? amountFormat(amount) : ''}
          </Typography>
        );
      },
    },
    {
      flex: 1,
      field: 'settlementAmount',
      headerName: 'Settlement Amount ($)',
      sortable: false,
      minWidth: 170,
      renderCell: (params) => {
        const { settlementAmount, companyExpense, pinned } = params.row;

        const amount = pinned
          ? settlementAmount
          : companyExpense
          ? -Math.abs(settlementAmount)
          : settlementAmount;

        return (
          <Typography
            variant="body2"
            sx={{
              color: amount > 0 ? 'text.primary' : 'error.main',
            }}
          >
            {!!settlementAmount ? amountFormat(amount) : ''}
          </Typography>
        );
      },
    },
    {
      flex: 1,
      field: 'finalAmount',
      headerName: 'Net Expense ($)',
      sortable: true,
      minWidth: 120,
      renderCell: (params) => {
        const { finalAmount } = params.row;
        return (
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            {!!finalAmount ? amountFormat(finalAmount) : ''}
          </Typography>
        );
      },
    },
    {
      flex: 1,
      field: 'payments',
      headerName: 'Settlement #',
      sortable: false,
      minWidth: 300,
      renderCell: (params) => {
        const { payments } = params.row;
        return !!payments?.length
          ? payments.map((item: PayItemDTO, index: number) => {
              return (
                <div key={item.id}>
                  <Link
                    variant="h7"
                    key={item.id}
                    sx={{
                      color: 'primary.main',
                      textDecoration: 'underline',
                      whiteSpace: 'pre-wrap',
                    }}
                    onClick={(event) => {
                      if (DirtyDetailsPanelManager.isShouldPanelShake()) return;

                      event.stopPropagation();
                      onClose();
                      setSecondaryPanelData({
                        type: ESecondaryDetailsPanelType.PAY_STATMENT,
                        id: item.settlementId || item.id!,
                        entity: item?.payToEntityId
                          ? item?.payToEntityType
                          : item?.entityType ??
                            ((
                              item as any
                            ).paymentTypeName?.toUpperCase?.() as PayStatementsTypeEnum),
                        entityId: item?.payToEntityId
                          ? item?.payToEntityId
                          : (item as any)?.entityId ??
                            (item.payingEntityObj?.id as number),
                        seqNumber: item.settlementSeqNumber,
                      });
                    }}
                  >
                    {getSettlementIdToSow(item)}
                  </Link>
                  <Typography
                    variant="h7"
                    key={item.id}
                    sx={{
                      color: 'primary.main',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {payments.length > index + 1 ? ';  ' : ''}
                  </Typography>
                </div>
              );
            })
          : null;
      },
    },
    {
      flex: 1,
      field: 'paidBy',
      headerName: t('paidBy'),
      sortable: true,
      minWidth: 200,
    },
    {
      flex: 1,
      field: 'paidTo',
      headerName: t('paidTo'),
      sortable: true,
      minWidth: 200,
    },
    {
      flex: 1,
      field: 'createdBy',
      headerName: 'Created By',
      sortable: false,
      minWidth: 120,
    },
    {
      flex: 1,
      field: 'lastUpdated',
      headerName: t('lastUpdated'),
      sortable: true,
      minWidth: 120,
      valueGetter: (params) =>
        getFormattedDateForGrid(fromIsoToMoment(params.row.lastUpdated)),
    },
    {
      flex: 1,
      field: 'referenceNumber',
      headerName: 'Ref.#',
      sortable: true,
      minWidth: 120,
    },
    {
      flex: 1,
      field: 'description',
      headerName: 'Description',
      sortable: false,
      minWidth: 120,
    },
    ...(entity === ExpensesEntityTypeEnum.allExpenses ||
    entity === ExpensesEntityTypeEnum.fuelExpense
      ? fuelColumns
      : []),
    {
      flex: 1,
      field: 'assignedDocuments',
      headerName: 'Attachment',
      sortable: false,
      minWidth: 120,
      renderCell: (params) => {
        const { assignedDocuments } = params.row;
        return !!assignedDocuments?.length ? <GridFileIcon /> : null;
      },
    },
  ];

  return expensesColumns;
};

export const payingEntityRadioGroupData = [
  { value: 'DRIVER', label: 'Driver', disabled: true },
  { value: 'TRACTOR', label: 'Tractor', disabled: true },
];

export const emptyTableInfo: {
  [key: string]: { title: string; body: string };
} = {
  allExpenses: {
    title: 'oh fudge.',
    body: ExpensesConstant.noExpenses,
  },
  fuelExpense: {
    title: 'oh fudge.',
    body: ExpensesConstant.noFuelExpense,
  },
  otherExpense: {
    title: 'oh fudge.',
    body: ExpensesConstant.noOtherExpense,
  },
};

export const financeExpensesRemovePermissions: {
  [key: string]: AXELE_PERMISSION_TYPE[];
} = {
  NFD: [AXELE_PERMISSION_TYPE.EXPENSE_EDIT],
  DI: [AXELE_PERMISSION_TYPE.EXPENSE_EDIT],
  DR: [AXELE_PERMISSION_TYPE.EXPENSE_EDIT],
  SP: [AXELE_PERMISSION_TYPE.EXPENSE_EDIT],
};

export const expenseTypes: { [key: string]: string } = {
  otherExpense: 'Other Expense',
  fuelExpense: '',
  allExpenses: '',
};
