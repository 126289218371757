import { PermissionProps } from '../types/types';
import * as permissionUtils from './permissionUtils';
type HasPermissionProps = Pick<
  PermissionProps,
  | 'containsPermit'
  | 'subscriptionPermits'
  | 'includes'
  | 'contains'
  | 'roleCodes'
>;
export const getHasPermission = ({
  containsPermit,
  subscriptionPermits,
  includes,
  contains,
  roleCodes,
}: HasPermissionProps): boolean => {
  const { roleCode, permissions, webFeaturesList } =
    permissionUtils.getCurrentUser();

  const hasRoleCodes: boolean =
    roleCode && roleCodes?.length ? roleCodes.includes(roleCode) : false;

  let hasPermission = !roleCodes?.length;

  const validateProperties = permissionUtils.validateProperties(
    permissions,
    webFeaturesList
  );
  const hasIncludes = permissionUtils.hasIncludes(permissions, webFeaturesList);
  const hasContains = permissionUtils.hasContains(permissions, webFeaturesList);

  if (validateProperties(includes, subscriptionPermits)) {
    hasPermission = hasIncludes(includes, subscriptionPermits);
  }
  if (contains && validateProperties(contains)) {
    hasPermission = !!hasContains(contains, permissions);
  }
  if (containsPermit && validateProperties([], containsPermit)) {
    if (
      contains &&
      contains.length &&
      hasContains(contains, permissions) === null
    ) {
      // return <></>;
      //TODO : double check old logic
    }
    hasPermission = !!hasContains(containsPermit, subscriptionPermits);
  }
  return hasPermission || hasRoleCodes;
};
