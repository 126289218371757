import { ILoadTenderService } from '../interfaces/ILoadTenderServices';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { ServiceError, annotateNameAsync } from '../interfaces';
import {
  AddBrokerRequest,
  DeleteBrokerAccountRequest,
  GetBrokersRequest,
  GetLoadTendersRequest,
  LoadTenderResponseDTO,
} from '../../models/DTOs/LoadTender/Requests';

const LOAD_TENDER_PATH = 'web/lbhub/api/v2';
const API_RESOURCE_URL = {
  filterList: `${LOAD_TENDER_PATH}/tenders`,
  tenderIntegration: `${LOAD_TENDER_PATH}/tender-integration`,
  brokerList: `${LOAD_TENDER_PATH}/turvo/get-broker`,
  registerBroker: `${LOAD_TENDER_PATH}/turvo/register-broker`,
  deleteBroker: `${LOAD_TENDER_PATH}/turvo/delete-broker?id=`,
  updtaeBroker: `${LOAD_TENDER_PATH}/turvo/update-broker`,
};

export class LoadTenderService extends ILoadTenderService {
  @annotateNameAsync
  async getLoadTenderList(
    request: GetLoadTendersRequest
  ): Promise<any | null | ServiceError> {
    try {
      const response = await httpClient.get<LoadTenderResponseDTO>(
        API_RESOURCE_URL.tenderIntegration,
        request,
        LoadTenderResponseDTO,
        false,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getBrokerList(
    request: GetBrokersRequest
  ): Promise<any | null | ServiceError> {
    try {
      const response = await httpClient.get<any>(
        API_RESOURCE_URL.brokerList,
        request,
        LoadTenderResponseDTO,
        false,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async registerBroker(
    payload: AddBrokerRequest
  ): Promise<any | null | ServiceError> {
    try {
      const response = await httpClient.post<any>(
        API_RESOURCE_URL.registerBroker,
        undefined,
        payload,
        false
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  @annotateNameAsync
  async updateBroker(
    payload: AddBrokerRequest
  ): Promise<any | null | ServiceError> {
    try {
      const response = await httpClient.put<any>(
        `${API_RESOURCE_URL.updtaeBroker}?organizationId=${payload?.organizationId}`,
        undefined,
        payload,
        false
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: composedError?.message || 'Failed to update broker details',
      });
      return error;
    }
  }

  @annotateNameAsync
  async deleteBrokerAccount({
    id,
    organizationId,
  }: DeleteBrokerAccountRequest) {
    try {
      const response = await httpClient.delete<DeleteBrokerAccountRequest>(
        `${API_RESOURCE_URL.deleteBroker}${id}&organizationId=${organizationId}`,
        undefined,
        id,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteBroker',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'deleteBroker',
      });
      throw error;
    }
  }
}
