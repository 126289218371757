import { loadService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  UpdateStatusPayload,
  UpdateStatusRequest,
  UpdateStatusResponse,
} from '../../../models';
import { ELoadStatus } from '../constants/constants';

let instance: LoadActionsController | null = null;
export default class LoadActionsController {
  static instance(): LoadActionsController {
    if (instance === null) {
      instance = new LoadActionsController();
    }
    return instance;
  }

  static destroy() {
    instance = null;
  }

  private constructor() {
    //
  }

  async handleActionOnLoad({
    loadId,
    status,
    updateData,
  }: {
    loadId: string;
    status: ELoadStatus;
    updateData?: UpdateStatusPayload;
  }): Promise<UpdateStatusResponse | ServiceError> {
    const result = await loadService.updateStatus(
      new UpdateStatusRequest({
        loadId: loadId,
        status: status,
      }),
      new UpdateStatusPayload(updateData || {})
    );

    return result;
  }
}
