import { GridFetchRowsParams } from '@mui/x-data-grid-pro';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { loadsearchService } from '../../api';
import { LoadboardPickerUtils } from '../../common/Ui/LoadboardPicker/LoadboardPicker.utils';
import { LoadBoardAccount } from '../../common/Ui/LoadboardPicker/types';
import { IDriverDataResponse } from '../../models/DTOs/Loadsearch/Model';
import {
  AvailableLoadboards,
  GetBookmarkListRequest,
  GetDriverListRequest,
} from '../../models/DTOs/Loadsearch/Request';
import LoadboardGridConfig, {
  SortField,
  defaultLoadboardGridFilters,
} from '../../views/loadboards/LoadboardDataGrid/grid.config';
import { IDriverGapData } from '../../views/loadboards/constants/types';
import * as loadTypes from '../../views/loadboards/loadTypes';
import {
  ILoadsearchTableItem,
  TSortBy,
} from '../../views/loadboards/loadTypes';
import {
  createDriverGapData,
  toLoadsearchRequest,
} from '../../views/loadboards/utils/api.utils';
import {
  createTableData,
  sortingOrder,
  updateProfit,
} from '../../views/loadboards/utils/grid.utils';

const defaultSorting = LoadboardGridConfig.getDefaultConfiguration()
  .columns.filter((column) => column.sortFields?.length)
  .reduce<{ [field: string]: SortField }>((result, column) => {
    result[column.field] = (column.sortFields as SortField[]).find(
      (sortField) => sortField.isDefault
    ) as SortField;
    return result;
  }, {});

class LoadboardStore {
  @observable loadRegistry: Map<string, loadTypes.ILoad> = new Map(); // Transformed data
  @observable load: loadTypes.ILoad | null = null;
  @observable submitting = false;
  @observable loadingInitial = false;
  @observable searchAPIloading = false;
  @observable triggerSearch = false;

  @observable selectedItemId: string | null = null;
  @observable savedPayload: any = {};

  @observable loadList: loadTypes.ILoad[] = []; // Raw data from server
  @observable bookmarkedId: Array<string> = [];
  @observable driversList: Array<IDriversListItem> = [];
  @observable selectedDriver: any = null;

  @observable sorting: {
    [field: string]: SortField;
  } = defaultSorting;
  @observable tableData: loadTypes.ILoadsearchTableItem[] = [];
  @observable loadboardGridFilters: loadTypes.ILoadboardFilters =
    defaultLoadboardGridFilters;
  @observable driverGapData: IDriverGapData | null = null;
  @observable loadboardAccounts: LoadBoardAccount[][] = [];
  @observable selectedBookLoad?: loadTypes.ILoad;
  @observable prevFilters?: loadTypes.ILoadboardFilters;
  @observable isZoneDisabled = false;

  constructor() {
    makeObservable(this);
  }

  @computed get getLoadsFromStore() {
    return Array.from(this.loadRegistry.values());
  }

  //New LoadboardDataGrid begin
  @computed
  get getLoadboardAccounts(): LoadBoardAccount[][] {
    return toJS(this.loadboardAccounts);
  }

  @computed get getLoadboardGridFilters(): loadTypes.ILoadboardFilters {
    return toJS(this.loadboardGridFilters);
  }

  @computed get getDriverGapData(): IDriverGapData | null {
    return this.driverGapData ? toJS(this.driverGapData) : null;
  }

  getLoadFromStore(id: string): loadTypes.ILoad | undefined {
    return this.loadRegistry.get(id);
  }

  @action
  deleteLoadFromStore = (id: string): boolean => {
    const result = this.loadRegistry.delete(id);
    this.createTableData();
    return result;
  };

  getTableItemIndex(id: string): number {
    return this.tableData.findIndex(({ keyId }) => keyId === id);
  }

  @action selectLoad = (id: string) => {
    this.selectedItemId = id;
    const load = this.getLoadFromStore(this.selectedItemId!);
    return toJS(load);
  };

  @action getLoad = async (id: string) => {
    const load = this.getLoadFromStore(id);
    if (load) {
      this.load = load;
      return toJS(load);
    }
  };

  @action saveFilterPayload = (data: any = {}) => {
    this.savedPayload = {
      ...this.savedPayload,
      ...data,
    };
  };

  @action setSelectedDriver = async (payload: any) => {
    this.selectedDriver = payload;
  };

  @action gridActionData = async (_data: any, id: string) => {
    this.bookmarkedId.push(id);
  };

  @action getBookmarkedLoads = async (isBooked?: boolean) => {
    this.loadingInitial = true;
    this.searchAPIloading = true;
    this.loadRegistry.clear();
    try {
      const request = new GetBookmarkListRequest();
      request.booked = isBooked || false;
      const loads: Array<ISavedloads> =
        await loadsearchService.getBookmarkedList(request);
      loads.forEach((load: ISavedloads): void => {
        this.loadRegistry.set(load.id, {
          ...load.savedLoad,
          loadId: load?.loadId,
          loadSeqNum: load?.loadSeqNum,
        });
      });
      await this.createTableData();
    } catch (err) {
      runInAction(() => {
        this.loadRegistry.clear();
      });
    } finally {
      setTimeout(() => {
        this.loadingInitial = false;
        this.searchAPIloading = false;
      }, 500);
    }
  };

  @action clearLoads = () => {
    this.loadRegistry.clear();
    this.createTableData();
  };

  @action setLoadboardGridFilters = (newValue: loadTypes.ILoadboardFilters) => {
    this.loadboardGridFilters = newValue;
  };

  @action fetchLoadboardGridData = async (
    filters: loadTypes.ILoadboardFilters = toJS(this.loadboardGridFilters)
  ) => {
    this.searchAPIloading = true;
    this.loadingInitial = true;
    this.tableData = [];
    try {
      const request = toLoadsearchRequest({
        loadboardGridFilters: filters,
        selectedDriver: toJS(this.selectedDriver),
      });
      this.prevFilters = filters;
      const loads: loadTypes.ILoad[] =
        await loadsearchService.getLoadsearchList(request);

      runInAction(async () => {
        this.loadRegistry.clear();
        loads.forEach((load) => {
          this.loadRegistry.set(load.id, load);
        });
        this.loadList = loads;
        await this.createTableData();
      });
    } catch (error) {
      runInAction(() => {
        this.loadRegistry.clear();
      });
    } finally {
      setTimeout(() => {
        this.loadingInitial = false;
        this.searchAPIloading = false;
      }, 500);
    }
  };

  @action fetchAvailableLoadboards = async (terminalIds: string[]) => {
    this.loadingInitial = true;
    try {
      const payload = {
        terminalIds: terminalIds,
      };
      const request = new AvailableLoadboards(payload);
      const response = await loadsearchService.getAvailableLoadboards(request);
      runInAction(() => {
        const loadboardAccounts: LoadBoardAccount[][] =
          LoadboardPickerUtils.fromLoadboardToAccount(response);
        this.isZoneDisabled = LoadboardPickerUtils.isZoneDisabled(response);
        this.loadboardAccounts = loadboardAccounts;
      });
    } finally {
      this.loadingInitial = false;
    }
  };

  @action fetchLoadboardDriverGapData = async () => {
    this.loadingInitial = true;

    try {
      const payload = toLoadsearchRequest({
        loadboardGridFilters: toJS(this.loadboardGridFilters),
        selectedDriver: toJS(this.selectedDriver),
      });
      const request = new GetDriverListRequest(payload);
      const driversList: IDriverDataResponse[] | null =
        await loadsearchService.getDriversList(request);
      let driverGapData: IDriverGapData | null = null;
      if (driversList) {
        driverGapData = createDriverGapData(driversList);
      }

      runInAction(() => {
        this.driverGapData = driverGapData;
      });
    } finally {
      this.loadingInitial = false;
    }
  };

  @action
  createTableData = async () => {
    this.tableData = createTableData(this.loadRegistry);
  };

  @computed get getGridDataTable() {
    return toJS(this.tableData);
  }

  @action setSorting = (field: string, value: SortField) => {
    this.sorting = {
      ...this.sorting,
      [field]: value,
    };
  };

  @action
  sort = (field: string, sortBy: TSortBy) => {
    runInAction(() => {
      const sortTableData: loadTypes.ILoadsearchTableItem[] = sortingOrder({
        tableData: this.tableData,
        columnName: field,
        sortBy,
        sortingKey: this.sorting[field]?.sortingKey,
        type: this.sorting[field]?.type,
      });
      this.tableData = sortTableData.concat();
    });
  };

  @action
  fetchRow = async (params: GridFetchRowsParams) => {
    const slice = this.getGridDataTable.slice(
      params.firstRowToRender,
      params.lastRowToRender
    );
    return {
      slice,
      total: this.getGridDataTable.length,
    };
  };

  @action
  bookMark = (id: string, bookMarkId: string | null) => {
    runInAction(() => {
      const index = this.getTableItemIndex(id);
      if (index >= 0) {
        const tableItem = this.tableData[index];
        tableItem.actions.savedLoadsData.bookMarkId = bookMarkId;
        this.tableData.splice(index, 1, tableItem);
        this.tableData = this.tableData.concat();
      }
    });
  };

  @action
  setSelectedBookLoad = (bookLoad: loadTypes.ILoad) => {
    this.selectedBookLoad = bookLoad;
  };

  @action
  updateProfit = (
    selectedRowData: ILoadsearchTableItem,
    data: any
  ): ILoadsearchTableItem => {
    const rowIndex = this.tableData.findIndex(
      ({ id }) => id === selectedRowData.id
    );
    if (rowIndex >= 0) {
      const [newSelectedRowData, newRow] = updateProfit(data, [
        selectedRowData,
        this.tableData[rowIndex],
      ]);
      this.tableData.splice(rowIndex, 1, newRow);
      return newSelectedRowData;
    }
    return selectedRowData;
  };

  @action
  setTriggerSearch = (newValue: boolean) => {
    this.triggerSearch = newValue;
  };
}

interface ISavedloads {
  dispatcherId: number;
  id: string;
  organizationId: number;
  loadId?: string;
  loadSeqNum?: string;
  savedLoad: any;
}

interface IDriversListItem {
  driverName: string | null;
  driverGroupId: string;
  driverId: Array<number>;
  gapData: Array<IGap>;
  preferredDriver: boolean;
}

interface IGap {
  originCity: string | null;
  originState: string | null;
  originLat: string | null;
  originLng: string | null;
  destinationCity: string | null;
  destinationState: string | null;
  destinationLat: string | null;
  destinationLon: string | null;
  gapStartTime: string | null;
  gapEndTime: string | null;
  previousLoadId: string | null;
  nextLoadId: string | null;
  defaultWeeklyRevenue: number;
}

const createLoadboardStore = () => new LoadboardStore();

export { createLoadboardStore };
