import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface IProps {
  label: string;
  value?: string;
  contentRenderer?: () => ReactNode;
  styles?: {};
}

export const SingleFieldInfoCard = ({
  label = '',
  value = '',
  contentRenderer,
  styles,
}: IProps) => {
  const content =
    typeof contentRenderer === 'function' ? (
      contentRenderer()
    ) : (
      <Typography
        sx={{ color: 'text.primary', fontSize: '16px', fontWeight: 400 }}
      >
        {value}
      </Typography>
    );

  return (
    <Box sx={{ ...styles }}>
      <Typography
        sx={{ color: 'text.secondary', fontSize: '12px', fontWeight: 400 }}
      >
        {label}
      </Typography>
      {content}
    </Box>
  );
};
