import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { gridPageSize } from '../../../utils';
import { getSortOptions } from '../utils/filters.utils';
import { GANTT_DEFAULT_ZOOM_LEVEL } from './gantt.const';
import { DispatchFiltersName, EGanttTabPanel, IDispatchFilters } from './types';

export const ganttHeaderAndFilterHeightPx = '160px';
export const fixedDetailsPanelWidth = '540px';
export const ganttTooltipWidth = '467px';
export const ganttPageSize = 50;

export const debounceTime = 300;

export const chunkProcessorMaxChunkSize = gridPageSize;
export const chunkProcessorDebounce = 300;

export const SCROLL_OFFSET = 500;

export const defaultDispatch2Filters: IDispatchFilters = {
  filters: {
    [DispatchFiltersName.sort]: getSortOptions?.()?.[0],
    [DispatchFiltersName.sortDirection]: true,
    [DispatchFiltersName.driver]: [],
    [DispatchFiltersName.startDate]:
      RelativeDateRangeUtils.getSettingsDispatch().options[1],
    [DispatchFiltersName.operationMode]: null,
    [DispatchFiltersName.primaryDispatcher]: [],
    [DispatchFiltersName.employmentType]: null,
    [DispatchFiltersName.operationType]: null,
    [DispatchFiltersName.displayMode]: EGanttTabPanel.Gantt,
    [DispatchFiltersName.zoomLevel]: GANTT_DEFAULT_ZOOM_LEVEL,
    [DispatchFiltersName.ganttColumnWidth]: null,
    [DispatchFiltersName.driverDetails]: [],
    [DispatchFiltersName.trips]: [],
    [DispatchFiltersName.hasViolation]: false,
    [DispatchFiltersName.originRadius]: null,
    [DispatchFiltersName.destinationRadius]: null,
    [DispatchFiltersName.origin]: null,
    [DispatchFiltersName.destination]: null,
  },
  query: {},
};

export const defaultNoValue = '--';

export const groupModeTeamDriver = 'TEAM';

export const PANEL_FIXED_WIDTH = 530;
