import ZingChart from 'zingchart-react';
import 'zingchart/es6';

export interface PieSerieProps extends Record<string, unknown> {
  values: number[];
  backgroundColor: string | unknown;
}

export interface PieGraphProps {
  color: string;
  title?: string;
  titleStyles?: React.CSSProperties;
  subTitleStyles?: React.CSSProperties;
  subTitle?: string;
  series: PieSerieProps[];
  plotOptions?: Record<string, unknown>;
  plotarea?: Record<string, unknown>;
  refAngle?: number;
  backgroundColor?: string;
}
export const PieGraph = ({
  color,
  title = undefined,
  titleStyles = {},
  subTitleStyles = {},
  subTitle,
  series,
  plotOptions = {},
  plotarea = {},
  refAngle = 270, ////relative to starting 90 degree position
  backgroundColor = 'transparent',
}: PieGraphProps) => {
  const valueBox = [];
  if (title !== undefined) {
    valueBox.push({
      // title text
      type: 'first',
      text: title,
      connected: false,
      fontColor: color,
      fontFamily: 'Poppins',
      fontSize: '14',
      lineHeight: '21',
      fontWeight: 500,
      verticalAlign: 'middle',
      textAlign: 'center',
      placement: 'center',
      visible: true,
      ...(subTitle !== undefined && {
        offsetY: '-5px',
      }),
      ...titleStyles,
    });
  }
  if (subTitle !== undefined) {
    valueBox.push({
      // subTitle text
      type: 'first',
      text: subTitle,
      connected: false,
      fontColor: color,
      fontFamily: 'Poppins',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500,
      verticalAlign: 'middle',
      textAlign: 'center',
      placement: 'center',
      visible: true,
      ...(title !== undefined && {
        offsetY: '10px',
      }),
      ...subTitleStyles,
    });
  }

  return {
    type: 'pie',
    globals: {
      fontFamily: 'Poppins',
    },
    backgroundColor: backgroundColor,

    plotarea: {
      margin: '2px',
      ...plotarea,
    },
    plot: {
      slice: '70%', // Ring width,
      detach: false, // Prevent ring piece from detaching on click
      hoverState: {
        visible: false,
      },
      refAngle: refAngle,
      valueBox: valueBox,
      animation: {
        delay: 0,
        effect: 'ANIMATION_EXPAND_VERTICAL',
        method: 'ANIMATION_LINEAR',
        sequence: 'ANIMATION_BY_PLOT',
        speed: '600',
      },
      ...plotOptions,
    },
    tooltip: {
      visible: false,
    },
    series: series,
  };
};

export type ZingChartData = Record<string, unknown>;

export interface PieChartProps {
  width: number;
  height: number;
  chartData: ZingChartData;
  chartOptions?: Record<string, unknown>;
}

export const PieChart = ({
  width,
  height,
  chartData,
  chartOptions = {},
}: PieChartProps): JSX.Element => {
  return (
    <ZingChart
      height={height}
      width={width}
      data={chartData}
      {...chartOptions}
    />
  );
};
