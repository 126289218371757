import { t } from 'i18next';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { TTripStatusOption } from '../../../models/DTOs/Trip/Types';
import { defaultDispatchFilters } from '../../../views/dispatch/constants/dispatch';
import { invoiceFilters } from '../../../views/finance/invoices/constants';
import {
  tractorFilters,
  trailerFilters,
} from '../../../views/operations/equipment/components/ViewsSection';
import { driverFilters } from '../../../views/settings/users/constants';
import { defaultFilters } from '../../../views/trips/components/TripDataGrid/grid.config';
import { entityTypes } from '../../entityTypesData';
const warningValue = { value: t('warning'), key: 'WARNING' };
const criticalValue = { value: t('critical'), key: 'CRITICAL' };

export const tractorFilterData = {
  tractorUnavailable: {
    ...tractorFilters,
    statusFilter: [{ key: 'UNAVAILABLE', value: 'Unavailable' }],
  },
  tractorAvailable: {
    ...tractorFilters,
    statusFilter: [{ key: 'AVAILABLE', value: 'Available' }],
  },
  tractorWarning: { ...tractorFilters, safetyIssueFilter: [warningValue] },
  tractorCritical: { ...tractorFilters, safetyIssueFilter: [criticalValue] },
  totalTractor: { ...tractorFilters },
};

export const trailerFilterData = {
  trailerWarning: { ...trailerFilters, safetyIssueFilter: [warningValue] },
  trailerCritical: { ...trailerFilters, safetyIssueFilter: [criticalValue] },
};

export const invoiceFilterData = {
  invoiceDelay: { ...invoiceFilters },
  invoicePending: { ...invoiceFilters },
  invoiceMissing: {
    ...invoiceFilters,
    documentNeeded: { name: 'Yes', value: true },
  },
  invoiceReady: { ...invoiceFilters },
};

export const invoiceDelayPaymentFilterData = {
  invoiceDelay: { ...invoiceFilters, isOverdue: { name: 'Yes', value: true } },
  invoicePending: { ...invoiceFilters },
  invoiceMissing: {
    ...invoiceFilters,
  },
  invoiceReady: { ...invoiceFilters },
};

export const invoiceDelayMissingFilterData = {
  invoiceDelay: { ...invoiceFilters },
  invoicePending: { ...invoiceFilters },
  invoiceMissing: {
    ...invoiceFilters,
    documentNeeded: { name: 'Yes', value: true },
    loadStatusList: ['LOAD_COMPLETED'],
  },
  invoiceReady: { ...invoiceFilters },
};

export const driverFilterData = {
  driverWarning: { ...driverFilters, safetyIssueType: [{ name: 'WARNING' }] },
  driverCritical: { ...driverFilters, safetyIssueType: [{ name: 'CRITICAL' }] },
};

export const dispatchFilterData = {
  driverNeedsLoads: {
    ...defaultDispatchFilters.filters,
    needLoad: { key: true, value: 'Yes' },
  },
  driverOnVacation: {
    ...defaultDispatchFilters.filters,
    timeOff: { key: true, value: 'Yes' },
  },
};

export const AVAILABLE_TRIPS_STATUS_FILTER: TTripStatusOption[] = [
  { label: 'Available', value: ELoadStatus.AVAILABLE },
];

export const tripsFilterData = {
  openTripsTotal: {},
  tripsNeedDriver: {
    ...defaultFilters,
    needDriversNow: { label: 'Yes', value: true },
    // isEmptyTrip: { label: 'Yes', value: true },
    statuses: [
      { label: 'Planned', value: 'ASSIGNMENT_PLANNED' },
      { label: 'Available', value: 'AVAILABLE' },
    ],
  },
  assignedPotentialDelay: {
    ...defaultFilters,
    delayViolations: { label: 'Yes', value: true },
    statuses: [
      { label: 'Assigned', value: 'OFFERED_TO_DRIVER' },
      { label: 'Dispatched', value: 'DISPATCHED' },
      { label: 'In Transit', value: 'IN_TRANSIT' },
    ],
  },
  assignTripsTotal: {},
};

export const navigationLinks = {
  [entityTypes.TRACTOR]: '/operations/equipment',
  [entityTypes.TRAILER]: '/operations/equipment',
  [entityTypes.INVOICE]: '/finance/invoices',
  [entityTypes.TRIPS]: '/loads/trips',
  [entityTypes.DRIVER]: '/settings/users',
  [entityTypes.DISPATCH]: '/dispatch',
  [entityTypes.LOAD]: '/loads/loadboards',
};
