import { Type } from 'class-transformer';
import {
  ContactPerson,
  DriverPaymentTermsGrouped,
  OwnerOperatorPaymentRecipient,
  SortResponse,
} from '../..';
import { PaginatedList, PhoneType, addressType } from '../../../types';
import { TerminalShort } from '../../common/modelsShort';

export class User {
  id: string;
  firstName: string;
  lastName: string;

  phoneData: PhoneType;
  email: string;
  address: addressType | null;
  roleCode: string;
  terminals: TerminalShort[];
  associatedTerminalsList: TerminalShort[];
  terminal: TerminalShort | null;
  terminalId: string;
  driveStatus: number;
  isDriver: boolean;
  isUser: boolean;
  employmentType: string;
  operationType: string;
  userType: string;
  provider: string;
  sendInvitation: boolean;
  warning: number;
  critical: number;
  dispatcher: PrimaryDispatcher | null;
  emergencyContact: ContactPerson;

  statusName: string;
  status: number;
  lastLoginDate: string;
  driverRefId: string;
  beRes: any;

  constructor(dto: any) {
    this.id = dto.id;
    this.firstName = dto.firstname;
    this.lastName = dto.lastname;
    this.phoneData = dto.phoneData;
    this.email = dto.email;
    this.roleCode = dto.roleCode;
    this.address = dto.addressData;
    this.driveStatus = dto.driveStatus;
    this.driverRefId = dto.driverRefId;
    this.isDriver = this.driveStatus === 2 || this.driveStatus === 1;
    this.isUser = this.driveStatus === 2 || this.driveStatus === 0;
    this.terminals = dto.terminals?.map(
      (t: { id: string; companyName: string }) => new TerminalShort(t)
    );
    this.associatedTerminalsList = dto.associatedTerminalsList?.map(
      (t: { id: string; companyName: string }) => new TerminalShort(t)
    );
    this.terminal = dto.terminal ? new TerminalShort(dto.terminal) : null;
    this.terminalId = dto.terminalId;
    this.employmentType = dto.employmentType || '';
    this.operationType = dto.operationType || '';
    this.userType = dto.userType;
    this.provider = dto.eldProviderName;
    this.sendInvitation = dto.sendInvitation;
    this.emergencyContact = dto.emergencyContact;
    this.warning = dto.warningSafetyIssues;
    this.critical = dto.criticalSafetyIssues;
    this.beRes = dto;

    this.statusName = dto.statusName;
    this.status = dto.status;
    this.lastLoginDate = dto.lastLoginDate;
    this.dispatcher = dto.dispatcher
      ? new PrimaryDispatcher(dto.dispatcher)
      : null;
  }
}

export class UserDetails extends User {
  isRemovable: boolean;
  driverLicense: any;
  timezone: string;
  eldProvider: EldIntegrationDetails | undefined;
  eldDriver: EldIntegrationDriverDetails | undefined;
  trackingSource: { eld: boolean; gps: boolean };
  allowDriverAcceptRejectLoad: boolean;
  eldCredentialId: string;
  eldProviderName: string;
  eldDriverId: string;
  driverELDId: string;
  eldDriverFirstName: string;
  eldDriverLastName: string;
  paymentTerms: DriverPaymentTermsGrouped | null;
  payToDetails?: OwnerOperatorPaymentRecipient;
  constructor(props: any) {
    super(props);
    this.isRemovable = props.isRemovable;
    this.driverLicense = props.orgDriverLicense;
    this.timezone = props.timezone;
    this.eldProvider = props.eldProvider;
    this.eldDriver = props.eldDriver;
    this.trackingSource = props.trackingSourceData;
    this.allowDriverAcceptRejectLoad = props.allowDriverAcceptRejectLoad;
    this.eldCredentialId = props.eldCredentialId;
    this.eldProviderName = props.eldProviderName;
    this.eldDriverId = props.eldDriverId;
    this.driverELDId = props.driverELDId;
    this.eldDriverFirstName = props.eldDriverFirstName;
    this.eldDriverLastName = props.eldDriverLastName;
    this.paymentTerms = props.paymentTerms;
    this.payToDetails =
      props?.payToDetails ?? new OwnerOperatorPaymentRecipient({});
  }
}
export const roleCodeListStatic = [
  { name: 'Accountant', roleCode: 'AC' },
  { name: 'Admin', roleCode: 'AD' },
  { name: 'Power Dispatcher', roleCode: 'PD' },
  { name: 'Financial Dispatcher', roleCode: 'DI' },
  { name: 'Dispatcher', roleCode: 'NFD' },
];

export class PrimaryDispatcher {
  id: string;
  fullName: string;
  constructor(props: any) {
    const roleName = roleCodeListStatic.find(
      (r) => r.roleCode === props.roleCode
    )?.name;
    this.id = props.id;
    this.fullName = `${props.firstname} ${props.lastname}${
      roleName ? ` (${roleName})` : ''
    }`;
  }
}

export class PaginatedUserList {
  @Type(() => User)
  content!: User[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
  totalCriticalIssueCount!: number;
  totalWarningIssueCount!: number;
}

export class UserRoleDTO {
  id!: number;
  roleCode!: string;
}

export class PaginatedRoleList {
  @Type(() => UserRoleDTO)
  content!: UserRoleDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class EmailDTO {
  id!: number;
  email!: string;
}

export class PaginatedEmailList {
  @Type(() => EmailDTO)
  content!: EmailDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class TerminalDTO {
  id!: number;
  email!: string;
}

export class PaginatedTerminalsList {
  @Type(() => TerminalDTO)
  content!: TerminalDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class NameDTO {
  id!: number;
  name!: string;
}

export class PaginatedNamesList {
  @Type(() => NameDTO)
  content!: NameDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class StatusDTO {
  key!: number;
  value!: string;
}

export class PaginatedStatusesList {
  @Type(() => StatusDTO)
  content!: StatusDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}
export class UserDriverActivation {
  dot!: number;
  email!: string;
  companyName!: string;
  phoneData!: PhoneType;
}

export class EldIntegrationDetails {
  id!: string;
  name!: string;
  terminalIds!: string[];
}
export class EldIntegrations extends PaginatedList {
  content!: EldIntegrationDetails[];
}

export class EldIntegrationDriverDetails {
  eldId!: number;
  firstname!: string;
  id!: string;
  lastname!: string;
  nameToShow!: string;
}
export class EldIntegrationDrivers extends PaginatedList {
  content!: EldIntegrationDriverDetails[];
}
export const ScheduleTractorAPITextConfig = {
  Payment: {
    entity: 'TractorId',
    typeId: 'tractorId',
    entityType: 'Payment',
    createAPI: '/web/asset/api/v2/tractorPaymentTerms/scheduledpay',
    deleteAPI: '/web/asset/api/v2/tractorPaymentTerms/scheduledpay',
    updateAPI: '/web/asset/api/v2/tractorPaymentTerms/scheduledpay',
    globalLable: 'scheduledPayment',
  },
  Deduction: {
    entity: 'TractorId',
    typeId: 'tractorId',
    entityType: 'Deduction',
    createAPI: '/web/asset/api/v2/tractorPaymentTerms/scheduleddeduction',
    deleteAPI: '/web/asset/api/v2/tractorPaymentTerms/scheduleddeduction',
    updateAPI: '/web/asset/api/v2/tractorPaymentTerms/scheduleddeduction',
    globalLable: 'scheduledDeduction',
  },
};
