/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  PaginatedTrailerListRequest,
  PaginatedTrailerListResponse,
  TrailerResponse,
  TrailerAlertSummaryDTO,
  CreateTrailerAlertQueryParamsRequest,
  TrailerAlertQueryParamsRequest,
  DefaultTrailersTypesListSummaryDTO,
  DefaultTrailersTypesDictionarySummaryDTO,
  QueryParams,
  DriverTrailerAssignRequest,
  DriverTrailerAssignResponse,
  ChangeTrailerStatusRequest,
  UpdateTrailerAlertListRequest,
} from '../../models';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class ITrailerService extends Service {
  abstract getPaginatedTrailerList(
    requestData: PaginatedTrailerListRequest
  ): Promise<PaginatedTrailerListResponse | null | ServiceError>;

  abstract getPaginatedTrailerListExport(
    requestData: PaginatedTrailerListRequest
  ): Promise<PaginatedTrailerListResponse | null | ServiceError>;

  abstract getTrailerStaticData(): any;
  abstract verifyTrailerDeletion(id: string): any;
  abstract deleteTrailerById(id: string): Promise<null | ServiceError>;
  abstract getTrailerById(id: any): any;
  abstract createTrailer(trailer: any): any;
  abstract updateTrailer(
    newTractor: any
  ): Promise<TrailerResponse | null | ServiceError>;

  abstract getTrailerByNameSearchExtendedList(
    keyword: string | undefined,
    pageNumber: number,
    statusList: string[],
    terminalIds?: string[]
  ): any;

  abstract changeTrailerStatus(
    requestData: ChangeTrailerStatusRequest
  ): Promise<TrailerResponse | ServiceError>;

  abstract driverTrailerAssign(
    requestData: DriverTrailerAssignRequest
  ): Promise<DriverTrailerAssignResponse | null | ServiceError>;

  abstract driverTrailerUnAssign(
    requestData: DriverTrailerAssignRequest
  ): Promise<DriverTrailerAssignResponse | null | ServiceError>;

  abstract getTrailerAlertList(
    requestData: TrailerAlertQueryParamsRequest
  ): Promise<TrailerAlertSummaryDTO[] | null | ServiceError>;

  abstract createTrailerAlert(
    requestData: CreateTrailerAlertQueryParamsRequest
  ): Promise<TrailerAlertSummaryDTO | null | ServiceError>;

  abstract updateTrailerAlertsBulk(
    requestData: UpdateTrailerAlertListRequest[]
  ): Promise<TrailerAlertSummaryDTO[] | null | ServiceError>;

  abstract getDefaultTrailersTypesList(
    requestData: QueryParams
  ): Promise<DefaultTrailersTypesListSummaryDTO[] | null | ServiceError>;

  abstract getDefaultTrailersTypesDictionary(
    requestData: QueryParams
  ): Promise<DefaultTrailersTypesDictionarySummaryDTO[] | null | ServiceError>;

  abstract deleteTrailerAlertById(id: string): Promise<null | ServiceError>;

  abstract markAsInactive(
    id: string
  ): Promise<{ hasErrors: boolean; message: string } | ServiceError>;
}
