import { Dialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import {
  createTheme,
  SxProps,
  ThemeProvider,
  useTheme,
} from '@mui/material/styles';
import DialogTitle from './AxeleDialogHeader';

export interface AxeleDialogProps {
  open: boolean;
  renderComponent?: any;
  handleClose?: () => void;
  titleText?: string;
  subTitleText?: string;
  isTabsPanel?: boolean;
  height?: string | number;
  dialogStyles?: SxProps;
  dialogPaperStyles?: SxProps;
  showTitle?: boolean;
}

const AxeleDialog = ({
  open,
  renderComponent,
  handleClose,
  titleText,
  subTitleText,
  isTabsPanel = false,
  height = '500px',
  dialogStyles,
  dialogPaperStyles,
  showTitle = true,
  ...other
}: AxeleDialogProps) => {
  const currentTheme = useTheme();
  const theme = createTheme(currentTheme, {
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            zIndex: 1300,
            MuiDialogContent: {
              padding: 10,
            },
          },
          paper: {
            width: '90%',
            maxWidth: '90%',
            minHeight: 389,
            borderRadius: 24,
            background: '#35446B',
            paddingTop: '8px',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '30px 10px 10px 10px !important',
          },
        },
      },
    },
  });

  const fitTheme = createTheme(currentTheme, {
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            zIndex: 1300,
            MuiDialogContent: {
              padding: 0,
            },
          },
          paper: {
            width: '90%',
            maxWidth: '90%',
            borderRadius: 24,
            h2: {
              paddingTop: '8px',
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '0px !important',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={isTabsPanel ? fitTheme : theme}>
      <Dialog
        open={open}
        {...other}
        sx={{
          '& .MuiDialogContent-root': {
            height,
          },
          ...dialogStyles,
        }}
        PaperProps={
          isTabsPanel
            ? {
                sx: {
                  backgroundColor: 'leftMenuCollapsed.canvas',
                  color: 'primary.contrast',
                  ...dialogPaperStyles,
                },
              }
            : {
                sx: { ...dialogPaperStyles },
              }
        }
      >
        {showTitle && (
          <DialogTitle
            onClose={() => {
              handleClose && handleClose();
            }}
            titleText={titleText}
            subTitleText={subTitleText}
          />
        )}
        <DialogContent
          sx={{
            mt: '5px',
            background: '#FFFFFF',
            borderRadius: '24px',
            overflow: 'hidden',
          }}
        >
          {renderComponent}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default AxeleDialog;
