import { preferencesService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  FuelMatrixType,
  Matrix,
  PreferencesFormType,
  PreferencesType,
} from '../../../models';
import {
  ActivateApiKeyRequest,
  DeactivateApiKeyRequest,
  DeleteApiKeyRequest,
  GenerateApiKeyRequest,
  GetCompanyLogoRequest,
  GetPreferencesRequest,
  UpdatePreferencesRequest,
} from '../../../models/DTOs/Preferences/Requests';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { docType } from '../../../utils/Doc';
import { truckStopOptionsMap } from './constants';

export default class PreferencesActions {
  static async getPreferences() {
    const request = new GetPreferencesRequest();
    const result = await preferencesService.getPreferences(request);
    if (result instanceof ServiceError) {
      return;
    }
    const preferencesFormData: PreferencesFormType = {
      ...result,
      driverPreferences: {
        ...result.driverPreferences,
        preferredTruckstops: (
          result.driverPreferences.preferredTruckstops || []
        ).map((truckStop) => ({
          key: truckStop,
          label: truckStopOptionsMap[truckStop],
        })),
      },
      companyDetails: {
        ...result.companyDetails,
        phoneData: {
          phone: result.companyDetails.phone,
          extension: result.companyDetails.extension,
          countryCode: result.companyDetails.countryCode,
        },
        faxData: {
          phone: result.companyDetails.fax,
          extension: result.companyDetails.faxExtension || '',
          countryCode: result.companyDetails.faxCountryCode,
        },
        addressData: {
          ...result.companyDetails.addressData,
          zip: result.companyDetails.addressData.zipcode,
        },
      },
      fuelMatrix: (result.fuelMatrix ?? []).reduce((acc, val) => {
        acc[val.region] = {
          price: val.price,
          useNationalAverage: val.useNationalAverage,
          id: val.id,
          organizationId: val.organizationId,
          defaultPrice: val.defaultPrice,
        };
        return acc;
      }, {} as Record<keyof typeof Matrix, Omit<FuelMatrixType, 'region'>>),
    };
    return preferencesFormData;
  }

  static async generateApiKey() {
    const request = new GenerateApiKeyRequest();
    const result = await preferencesService.generateApiKey(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async deactivateApiKey() {
    const request = new DeactivateApiKeyRequest();
    const result = await preferencesService.deleteApiKey(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async activateApiKey() {
    const request = new ActivateApiKeyRequest();
    const result = await preferencesService.activateApiKey(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async deleteApiKey() {
    const request = new DeleteApiKeyRequest();
    const result = await preferencesService.deleteApiKey(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async getCompanyLogo() {
    const request = new GetCompanyLogoRequest();
    const result = await preferencesService.getCompanyLogo(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async updatePreferences(data: PreferencesFormType) {
    delete data.fileChanged;
    delete data.logo;
    const seqNumber = data?.isSeqNumberManuallyUpdated ? data?.seqNumber : null;
    delete data?.isSeqNumberManuallyUpdated;
    const fuelMatrix: FuelMatrixType[] = Object.keys(data.fuelMatrix).map(
      (key) => ({
        region: key as keyof typeof Matrix,
        ...data.fuelMatrix[key],
      })
    );
    const requestData: PreferencesType = {
      ...data,
      seqNumber,
      driverPreferences: {
        ...data.driverPreferences,
        preferredTruckstops: (
          data.driverPreferences.preferredTruckstops || []
        ).map((truckStop) => truckStop.key),
      },
      companyDetails: {
        ...data.companyDetails,
        phone: data.companyDetails.phoneData.phone,
        extension: data.companyDetails.phoneData.extension,
        countryCode: data.companyDetails.phoneData.countryCode,
        fax: data.companyDetails.faxData.phone,
        faxExtension: data.companyDetails.faxData.extension,
        faxCountryCode: data.companyDetails.faxData.countryCode,
        addressData: {
          ...data.companyDetails.addressData,
          zipcode: data.companyDetails.addressData.zip,
        },
      },
      fuelMatrix,
    };
    const request = new UpdatePreferencesRequest(requestData);
    const result = await preferencesService.updatePreferences(request);
    if (result instanceof ServiceError) {
      return;
    }
    await RootStoreInstence.setCompanyPreferences();
    return result;
  }

  static async updateLogo(file: docType) {
    await preferencesService.updateLogo(file);
  }
}
