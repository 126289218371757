import { createContext, useCallback, useEffect, useState } from 'react';

import { paymentService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import {
  CreateDriverPaymentTerm,
  DriverPaymentTermsGrouped,
  ExpenseCategory,
  OperationMode,
  PaymentPerLoad,
  PaymentType,
  PaymentUnitType,
} from '../../../../models';
import { UserDetails } from '../../../../models/DTOs/user/User';
import { IPaymentTermDataDependency } from './PaymentTermTabs';
import StorageManager from '../../../../StorageManager/StorageManager';
import { LoadRateDTO } from '../../../../models/DTOs/PaymentTerms/request';

type OperationModeValues = {
  key: string;
  displayText: string;
};

type FormTypeContextType = {
  availablePayTypes: PaymentType[];
  expenseCategories: ExpenseCategory[];
  savedLoadPaymentTerms: PaymentPerLoad[];
  allPaymentTypes: PaymentType[];
  createNewPaymentTermForLoad: (paymentTerm: CreateDriverPaymentTerm) => void;
  setUserDetails: (value: React.SetStateAction<UserDetails>) => void;
  deletePaymentForLoad: (id: number) => void;
  filterOperationsModes: (payTypeId: string) => void;
  resetOperationModes: () => void;
  operationModesValues: OperationModeValues[];
  paymentUnit: OperationModeValues[];
  userType: string;
  scheduledPayment: PaymentPerLoad[];
  scheduledDeduction: PaymentPerLoad[];
};

const operationModesValues: OperationModeValues[] = [
  {
    key: 'SOLO',
    displayText: 'Solo',
  },
  {
    key: 'TEAM',
    displayText: 'Team',
  },
];

const FormContext = createContext({} as FormTypeContextType);

type Props = {
  setUserDetails: (value: React.SetStateAction<UserDetails>) => void;
  data: UserDetails;
  children: any;
  dataDependency: IPaymentTermDataDependency;
};

async function getDriverPaymentTerms(
  driverId: number
): Promise<DriverPaymentTermsGrouped | null> {
  const driverPaymentTerms = await paymentService.getDriverPaymentTerms(
    driverId
  );

  if (driverPaymentTerms instanceof ServiceError) {
    // should be decided what to do
    return null;
  } else if (driverPaymentTerms != null) {
    const savedPaymentTerms = driverPaymentTerms as DriverPaymentTermsGrouped;
    return savedPaymentTerms;
  }

  return null;
}

export const FormProvider = ({
  children,
  setUserDetails,
  data,
  dataDependency,
}: Props): JSX.Element => {
  const { allPaymentTypes, expenseCategories } = dataDependency;
  const loading = false;
  const [availablePayTypes, setAvailablePayTypes] = useState<PaymentType[]>([]);
  const [paymentUnit] = useState<PaymentUnitType[]>([]);
  const [operationModes, setOperationModes] = useState(operationModesValues);
  const [savedLoadPaymentTerms, setSavedLoadPaymentTerms] = useState<
    PaymentPerLoad[]
  >([]);
  const [scheduledPayment, setScheduledPayment] = useState<PaymentPerLoad[]>(
    []
  );
  const [scheduledDeduction, setScheduledDeduction] = useState<
    PaymentPerLoad[]
  >([]);
  const filterOperationsModes = useCallback(
    (payTypeId: string) => {
      const usedOperationModesForSelectedPaymentType = savedLoadPaymentTerms
        .filter((ppl) => ppl.payTypeId === payTypeId)
        .map((filteredItem) => filteredItem.operationMode);

      if (usedOperationModesForSelectedPaymentType.length) {
        const filteredOperationModes = operationModesValues.filter((opm) => {
          if (opm) {
            return !usedOperationModesForSelectedPaymentType.includes(
              opm.key as OperationMode
            );
          }
        });
        setOperationModes(filteredOperationModes);
      } else {
        setOperationModes(operationModesValues);
      }
      setOperationModes(operationModesValues);
    },

    [savedLoadPaymentTerms]
  );

  const resetOperationModes = useCallback(() => {
    setOperationModes(operationModesValues);
  }, []);

  const createNewPaymentTermForLoad = useCallback(
    (paymentTerm: CreateDriverPaymentTerm) => {
      const doSave = async () => {
        const userStorage = StorageManager.getUser();

        paymentTerm.driverId = data.id;
        paymentTerm.organizationId = userStorage.organizationId;

        const savedPaymentPerLoadResponse =
          await paymentService.createDriverPaymentTerms(paymentTerm);

        if (savedPaymentPerLoadResponse instanceof ServiceError) {
          // should be decided what to do
          return null;
        } else if (savedPaymentPerLoadResponse != null) {
          const savedPaymentPerLoad =
            savedPaymentPerLoadResponse as PaymentPerLoad;

          const copiedPaymentTerms = [...savedLoadPaymentTerms];
          copiedPaymentTerms.push(savedPaymentPerLoad);
          const newLoadRateDTO = copiedPaymentTerms?.map(
            (item) => new LoadRateDTO(item)
          );
          setUserDetails({
            ...data,
            paymentTerms: {
              ...data.paymentTerms,
              loadRateDTO: newLoadRateDTO,
            },
          });

          setSavedLoadPaymentTerms(newLoadRateDTO);
        }
      };

      doSave();
    },
    [data, allPaymentTypes, setUserDetails, savedLoadPaymentTerms]
  );
  const deletePaymentForLoad = useCallback(
    (id: number) => {
      const doDelete = async () => {
        const deleteResponse = await paymentService.deleteLoadRateByIDs([id]);

        if (deleteResponse instanceof ServiceError) {
          // should be decided what to do
          return null;
        } else {
          const filteredItems = savedLoadPaymentTerms.filter(
            (paymentTerm) => paymentTerm.id !== id
          );

          setSavedLoadPaymentTerms(filteredItems);
          setUserDetails({
            ...data,
            paymentTerms: {
              ...data.paymentTerms,
              loadRateDTO: filteredItems,
            },
          });
        }
      };

      doDelete();
    },
    [savedLoadPaymentTerms, setUserDetails, data]
  );

  const filterAvailablePaymentTypes = (
    driverPaymentTermsArg: DriverPaymentTermsGrouped | null,
    availablePayTypesArg: PaymentType[]
  ): PaymentType[] => {
    let loadPaymentTerms: PaymentPerLoad[] = [];
    let filteredPayTypes: PaymentType[] = [];

    if (driverPaymentTermsArg?.loadRateDTO?.length) {
      loadPaymentTerms = driverPaymentTermsArg.loadRateDTO;

      filteredPayTypes = availablePayTypesArg.filter((eachPaymentType) => {
        let paymentTypeUsageCounter = 0;
        for (let index = 0; index < loadPaymentTerms.length; index += 1) {
          if (loadPaymentTerms[index].payTypeId === eachPaymentType.id) {
            paymentTypeUsageCounter += 1;
          }

          if (paymentTypeUsageCounter === 2) {
            break;
          }
        }

        return paymentTypeUsageCounter !== 2;
      });
    } else {
      filteredPayTypes = availablePayTypesArg;
    }

    return filteredPayTypes;
  };

  useEffect(() => {
    async function prepareDataForForm() {
      if (!data?.id) return;
      const driverPaymentTerms = await getDriverPaymentTerms(data.id);

      const filteredPayTypes = filterAvailablePaymentTypes(
        driverPaymentTerms,
        allPaymentTypes
      );

      setAvailablePayTypes(filteredPayTypes);
      setSavedLoadPaymentTerms(driverPaymentTerms?.loadRateDTO || []);
      setScheduledPayment(driverPaymentTerms?.scheduledPayDTO || []);
      setScheduledDeduction(driverPaymentTerms?.scheduledDeductionDTO || []);
      setUserDetails({
        ...data,
        paymentTerms: driverPaymentTerms,
      });
    }
    prepareDataForForm();
  }, []);

  const contextData = {
    availablePayTypes,
    expenseCategories,
    paymentUnit,
    savedLoadPaymentTerms,
    allPaymentTypes,
    setAvailablePayTypes,
    operationModes,
    createNewPaymentTermForLoad,
    deletePaymentForLoad,
    filterOperationsModes,
    resetOperationModes,
    operationModesValues,
    scheduledPayment,
    scheduledDeduction,
  };
  return (
    <FormContext.Provider value={contextData}>
      {loading ? null : children}
    </FormContext.Provider>
  );
};

export default FormContext;
