import { ContentCopy, Delete, PostAddOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { customerService } from '../../../api';
import { httpClient } from '../../../axios/axiosInstance';
import DetailsPanel from '../../../common/DetailsPanel';
import LongMenu from '../../../common/LongMenu';
import { useContractPermission } from '../../../common/hooks/useContractPermission';
import {
  ContractPanelType,
  ContractTabStrip,
} from '../../../components/contract';
import {
  GetCustomerDetailsByIdRequest,
  UpdateCustomerRequest,
} from '../../../models';
import { CustomerSummaryDTO, QueryParams } from '../../../models/DTOs';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { useStores } from '../../../store/root.context';
import { IHttpErrorType } from '../../../types';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import { updateDocument } from '../../../utils/contract_payload_formatter';
import { ActionsSection } from '../actionsSection';
import {
  customerDefaultData,
  customerDetailsFormValidationSchema,
} from '../constants';
import ContractForm from '../forms/ContractForm';
import CustomerDetailsForm from '../forms/CustomerDetailsForm';
import { CustomerSummaryTableRow } from '../models/customer';
import {
  AddContractModal,
  CreateFormContent,
} from './../../../components/contract/add_contract/';
import DeletePopup from '../../../common/DeletePopup';
import { DirtyDetailsPanelManager } from '../../../common/DetailsPanel/utils';
import { secondaryPanelData } from '../../../common/LoadTabPanel/tabs/Activity/config';

interface IProps {
  data: any;
  isCreatePanelOpen: boolean;
  httpErrors: IHttpErrorType;
  onClose: () => void;
  onUpdated: (data: CustomerSummaryTableRow) => void;
  onDeleted: (id: string) => void;
  onCreated: (data: CustomerSummaryTableRow) => void;
  updateCustomerSummary: (data: CustomerSummaryTableRow) => void;
  autoExpanded: boolean;
  isGlobal: boolean;
  isContractPage: boolean;
  setOpenCreateForm?: () => void;
  handleContractDuplication: any;
}

const CustomerDetailsPanel: React.FC<IProps> = ({
  data,
  isCreatePanelOpen,
  onClose,
  onUpdated,
  onDeleted,
  onUpdate,
  onCreated,
  updateCustomerSummary,
  httpErrors,
  autoExpanded = false,
  isGlobal = false,
  handleContractDuplication,
  isContractPage = false,
  setOpenCreateForm,
  ...rest
}) => {
  const [customerDetailsData, setCustomerDetailsData] =
    useState<CustomerSummaryDTO | null>(customerDefaultData);
  const [selectedTab, setSelectedTab] = useState<'overview' | 'contracts'>(
    'overview'
  );
  const { hasContractViewPermission, hasContractCreatePermission } =
    useContractPermission();
  const [contractId, setContractId] = useState('');
  const [customerContracts, setCustomerContracts] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const { contractStore } = useStores();
  const [openAddContract, setOpenAddContract] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  }>({
    type: '',
    message: '',
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const handleUpdate = async (customer: CustomerSummaryTableRow): void => {
    const updatedCustomer = {
      ...customer,
      customerPreference: customer.customerPreference || 'NONE',
      ediCustomerId: customer?.ediCustomerId,
    };
    const requestData: UpdateCustomerRequest = new UpdateCustomerRequest(
      updatedCustomer
    );
    requestData?.contacts?.forEach((contact) => {
      delete contact.name;
      delete contact.extension;
    });
    try {
      const response = await customerService.updateCustomer(requestData);
      const customerSummary: any = new CustomerSummaryTableRow(response);
      setCustomerDetailsData({
        ...customerDetailsData,
        ...customerSummary,
        factoring: {
          id: customerSummary.factoringId || '',
          factoringName: customerSummary.factoringName || '',
        },
      });
      onUpdated && onUpdated(customerSummary);
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const handleCreateUser = (newCustomer: CustomerSummaryTableRow): void => {
    onCreated(newCustomer);
  };

  async function getCustomerDetails(id: number) {
    const request = new GetCustomerDetailsByIdRequest();
    request.id = id;
    try {
      const customerDetails = await customerService.getCustomerDetailsById(
        request
      );
      if (customerDetails instanceof CustomerSummaryDTO) {
        customerDetails.factoring = {
          id: customerDetails?.factoringId || '',
          factoringName: customerDetails?.factoringName || '',
        };
        setCustomerDetailsData(customerDetails);
      }
    } catch (error) {}
  }

  const fetchAllContracts = async (id: string) => {
    try {
      const queryParams = new QueryParams();
      const response: any = await httpClient.put(
        '/web/customer/api/v2/contracts/list',
        queryParams,
        {
          idFilter: [],
          rateMethodFilter: [],
          customerIdFilter: [id],
          statusFilter: ['ACTIVE', 'EXPIRED'],
          effectiveFrom: '',
          effectiveTo: '',
          expireFrom: '',
          expireTo: '',
          createdFrom: '',
          createdTo: '',
        },
        false,
        true
      );
      setCustomerContracts(response.content);
    } catch (e) {}
  };

  useEffect(() => {
    if (
      !contractStore?.financeDictionary?.length &&
      !contractStore?.equipmentType?.length
    ) {
      contractStore.fetchFinanceItems();
      contractStore.fetchEquipmentTypes();
    }
    setCustomerDetailsData(customerDefaultData);
    if (typeof data === 'object' && data?.id) {
      getCustomerDetails(data?.id);
      fetchAllContracts(data?.id);
    }
  }, [contractStore, data, data?.id, isCreatePanelOpen]);

  const actionsRenderer = () => {
    return (
      <>
        {customerDetailsData?.id && (
          <ActionsSection
            onDeleted={onDeleted}
            customerData={customerDetailsData}
          />
        )}
      </>
    );
  };

  const ContractContent = useCallback(() => {
    if (selectedTab === 'contracts')
      return (
        <>
          <Stack direction="column" p={2}>
            {!isContractPage && hasContractCreatePermission && (
              <Stack direction={'row-reverse'}>
                <ButtonImproved
                  styleProps={{
                    maxWidth: '140px',
                  }}
                  startIcon={<PostAddOutlined />}
                  variant="contained"
                  label={'Add Contract'}
                  onClick={() => {
                    setOpenCreateForm?.() || setOpenAddContract(true);
                  }}
                />
              </Stack>
            )}
            <ContractForm
              setContractId={setContractId}
              contractId={contractId}
              contracts={customerContracts}
            />
          </Stack>
        </>
      );
    if (selectedTab === 'overview')
      return (
        <Stack direction="column" p={2}>
          <CustomerDetailsForm
            data={customerDetailsData}
            isCreatePanelOpen={isCreatePanelOpen}
            updateCustomerSummary={updateCustomerSummary}
          />
        </Stack>
      );
  }, [
    contractId,
    customerContracts,
    customerDetailsData,
    isContractPage,
    isCreatePanelOpen,
    selectedTab,
    setOpenCreateForm,
    updateCustomerSummary,
  ]);

  const handleOnSubmit = async (
    values: any,
    newAttachedDocument: any,
    oldDocument: any
  ) => {
    setIsSaving(true);
    setErrorMessage({
      type: '',
      message: '',
    });
    try {
      const queryParams = new QueryParams();
      const response = await httpClient.putRaw(
        'web/customer/api/v2/contracts/update',
        queryParams,
        { ...values, id: contractId },
        true,
        true
      );
      if (response && response?.data) {
        await updateDocument(response?.data, newAttachedDocument, oldDocument);
      }
      contractStore.fetchContractList(1);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: `Successfully updated contract ${response?.data?.name}`,
      });
      await fetchAllContracts(values?.customerId);
      setIsSaving(false);
      return true;
    } catch (e) {
      const err = e?.getError?.()?.response?.data;
      if (err && err.validationType === 'NON_UNIQUE_NAME') {
        setErrorMessage({
          type: err.validationType,
          message: err.message,
        });
        setIsSaving(false);
        return;
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'createContractErr',
      });
      setIsSaving(false);
    }
  };

  const deleteContract = async () => {
    if (!contractId) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: 'Please select a contract to delete',
      });
      return;
    }
    try {
      const queryParams = new QueryParams();
      queryParams.id = contractId;
      const response = await httpClient.deleteRaw(
        'web/customer/api/v2/contracts/delete',
        queryParams,
        { id: data?.id }
      );
      if (response) {
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          message: 'Contract deleted !',
        });
        setContractId('');
        fetchAllContracts(data?.id);
        onUpdate?.();
      }
      setShowDeletePopup(false);
    } catch (e) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'createContractErr',
      });
      setShowDeletePopup(false);
    }
  };
  return (
    <>
      <DetailsPanel
        panelTitle={
          (!data?.id && 'Add New Customer') ||
          `${t('customer')}: ${customerDetailsData?.name} ${
            customerDetailsData?.mc ? `[MC${customerDetailsData.mc}]` : ''
          }`
        }
        data={customerDetailsData}
        actionButtonLabel={data?.id ? undefined : 'Add Customer'} //ToDo logic later
        entity="Customer"
        onClose={onClose}
        onUpdate={handleUpdate}
        onCreate={handleCreateUser}
        {...(hasContractViewPermission &&
          data.id && {
            tabStripRenderer: () => (
              <ContractTabStrip
                onChangeTab={(tab) => {
                  setSelectedTab(tab);
                }}
                selectedTab={selectedTab}
              />
            ),
          })}
        contentRenderer={() => <ContractContent />}
        validationSchema={customerDetailsFormValidationSchema}
        isSaveIconVisible={!isCreatePanelOpen}
        httpErrors={httpErrors}
        autoExpanded={autoExpanded || contractId}
        isFullscreen={Boolean(contractId)}
        isGlobal={isGlobal}
        actionsRenderer={actionsRenderer}
        {...rest}
      />
      {Boolean(contractId) && (
        <DetailsPanel
          autoExpanded={true}
          isFullscreen={Boolean(contractId)}
          panelTitle={
            (!data?.id && 'Add New Customer') ||
            `${t('customer')}: ${customerDetailsData?.name} ${
              customerDetailsData?.mc ? `[MC${customerDetailsData.mc}]` : ''
            }`
          }
          data={customerDetailsData}
          actionButtonLabel={data?.id ? undefined : 'Add Customer'} //ToDo logic later
          entity="Customer"
          onClose={onClose}
          onUpdate={handleUpdate}
          onCreate={handleCreateUser}
          footerRenderer={() => <></>}
          contentRenderer={() => (
            <Box sx={{ pl: 2, pr: 2 }}>
              <CreateFormContent
                panelType={ContractPanelType.customer}
                contractData={{
                  customerName: customerDetailsData?.name,
                  customerId: customerDetailsData?.id,
                }}
                onClose={onClose}
                onSubmit={handleOnSubmit}
                id={contractId}
                isSaving={isSaving}
                handleOnDelete={() => setShowDeletePopup(true)}
              />
            </Box>
          )}
          isGlobal={true}
        />
      )}
      <DeletePopup
        open={showDeletePopup}
        body={`
        Are you sure you want to delete the contract?
        `}
        title="Delete Contract"
        onAction={async () => await deleteContract()}
        onClose={() => setShowDeletePopup(false)}
      />
      {Boolean(openAddContract) && (
        <AddContractModal
          onCreatedContract={async () => {
            setOpenAddContract(false);
            fetchAllContracts(customerDetailsData?.id);
          }}
          data={{
            customerId: customerDetailsData?.id,
            contractName: customerDetailsData?.name,
          }}
          onClose={() => {
            setOpenAddContract(false);
          }}
          panelTitle={'Create a contract'}
        />
      )}
    </>
  );
};

export default CustomerDetailsPanel;
