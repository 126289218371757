import {
  GetCustomerDetailsByIdRequest,
  GetLocationDetailsByIdRequest,
  PaginatedVendorsListRequest,
  GetVendorFiltersRequest,
} from '../../../models';
import { customerService, locationService, vendorService } from '../../../api';
import { entityTypes } from '../../SearchContainer/constants';
import { VendorSummaryTableRow } from '../../../subPages/vendors/models/vendor';

const getCustomerDetails = async (id: number) => {
  const request = new GetCustomerDetailsByIdRequest();
  request.id = id;
  return await customerService.getCustomerDetailsById(request);
};

const getLocationDetails = async (id: number) => {
  const request = new GetLocationDetailsByIdRequest();
  request.id = id;
  return await locationService.getLocationDetailsById(request);
};

const getVendorDetails = async (id: any) => {
  const {
    content: [vendor],
  } = await vendorService.getPaginatedVendorsList(
    new PaginatedVendorsListRequest({
      pageNumber: 1,
      pageSize: 1,
      sort: '+name',
    }),
    new GetVendorFiltersRequest({
      vendorIds: [id],
      vendorContactNames: null,
      vendorAddress: null,
      preferredProhibitedEnum: null,
      vendorNames: null,
    })
  );
  return new VendorSummaryTableRow(vendor);
};

export const setEntityData = async (selectedSearchItem: any) => {
  switch (selectedSearchItem.entityType) {
    case entityTypes.LOCATION:
      return await getLocationDetails(selectedSearchItem.entityId);
    case entityTypes.CUSTOMER:
      return await getCustomerDetails(selectedSearchItem.entityId);
    case entityTypes.VENDOR:
      return await getVendorDetails(selectedSearchItem.entityId);
    default:
      return {};
  }
};
