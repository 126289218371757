import React from 'react';
import { propsType } from './types/AddressPropType';
import ManualEditableAddressField from './components/manualEditableAddressField';
import { t } from 'i18next';

function AddressManual({
  data,
  onChange,
  errors,
  required = false,
  label = t('TripAddNewLoadOption3AddressManual'),
  maxWidth,
  disabled = false,
  disableAddressLine = false,
  variant = 'standard',
  includeTimezone = false,
  singleInput = false,
  textInputSxProps,
}: propsType & { singleInput?: boolean }) {
  return (
    <React.Fragment>
      <ManualEditableAddressField
        disabled={disabled}
        variant={variant}
        data={data}
        onAddressChanged={onChange}
        errors={errors}
        required={required}
        label={label}
        maxWidth={maxWidth}
        disableAddressLine={disableAddressLine}
        includeTimezone={includeTimezone}
        singleInput={singleInput}
        textInputSxProps={textInputSxProps}
      />
    </React.Fragment>
  );
}

export default AddressManual;
