import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Delay, Detention } from '../../../../ui-kit/components/Assets';
import { WarningMsg } from '../constants';
import { MapsToolTipProps } from '../types';
import { getMarker } from '../utils';
import * as styles from './MapsToolTipStyle';

export enum EMapToolTipsHeader {
  SOLODRIVER = 'SOLODRIVER',
  TEAMDRIVER = 'TEAMDRIVER',
  DRIVER = 'DRIVER',
  HOS = 'HOS',
  TIMEOFF = 'TIME OFF',
  DROPOFF = 'DROP OFF',
  PICKUP = 'PICKUP',
  TRIP = 'TRIP',
  RELAY = 'RELAY',
  PICKUPANDDROPOFF = 'PICKUP AND DROP OFF',
}

const MapsToolTip = ({
  type,
  header,
  content,
  detention,
  delay,
  hos,
}: // onClick,
MapsToolTipProps) => {
  const toolTip = true;
  const warningPane = detention || delay || hos;

  const detentionWarning = (
    <Box sx={styles.warningContainerStyle}>
      <Detention />
      <Typography sx={styles.warningTextStyle}>
        {WarningMsg.DETENTION}
      </Typography>
    </Box>
  );

  const delayWarning = (
    <Box sx={styles.warningContainerStyle}>
      <Delay />
      <Typography sx={styles.warningTextStyle}>{WarningMsg.DELAY}</Typography>
    </Box>
  );

  const hosWarning = (
    <Box sx={styles.warningContainerStyle}>
      <AccessTimeIcon sx={{ color: '#C8372C', width: 20, height: 20 }} />
      <Typography sx={styles.warningTextStyle}>{WarningMsg.HOS}</Typography>
    </Box>
  );

  const displayValue = (value: string | null | undefined): string => {
    if (
      value === null ||
      value === undefined ||
      value === 'null' ||
      value === 'null, null'
    )
      return '-';
    return value;
  };

  useEffect(() => {
    //Disables pinch zoom.
    const listener = (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };
    window.addEventListener('wheel', listener, {
      passive: false,
    });

    return () => {
      window.removeEventListener('wheel', listener);
    };
  }, []);
  return (
    <Box id="MapsToolTipContainer" sx={styles.MapsToolTipContainerStyle}>
      <Box id="MapsToolTipHeader" sx={styles.MapsToolTipHeaderStyle}>
        <Box id="headerImage" sx={styles.headerImageStyle}></Box>
        <Box id="HeaderInfo">
          <Typography sx={styles.HeaderInfoTitleStyle}>
            {header?.value}
          </Typography>
          <Typography sx={styles.HeaderInfoSubTitleStyle}>
            {EMapToolTipsHeader[type]}
          </Typography>
        </Box>
      </Box>
      <Box
        id="MapsToolTipsContent"
        sx={{
          ...styles.MapsToolTipsContentStyle,
          borderRadius: warningPane ? '8px 8px 0 0' : 2,
        }}
      >
        {content &&
          content.map((item, index) => {
            return (
              <>
                {item?.value && (
                  <Box key={index} sx={styles.MapsToolTipsContentSubStyle}>
                    <Box id="ToolTipContentKey" sx={{ width: '50%' }}>
                      <Typography sx={styles.ToolTipContentKeyStyle}>
                        {item?.key}
                      </Typography>
                    </Box>
                    <Box
                      id="ToolTipContentValue"
                      sx={styles.ToolTipContentValueStyle}
                    >
                      <Typography
                        sx={{
                          ...styles.ToolTipContentValueTextStyle,
                          color: 'rgba(0, 17, 34, 0.75)',
                          // commenting below stylings it will be used in future
                          // color: item.id ? '#2B64CB' : 'rgba(0, 17, 34, 0.75)',
                          // cursor: item.id && 'pointer',
                        }}
                        // onClick={() =>
                        // removing click functionlity it will be unabled in future
                        //   item.id && onClick && onClick(item.key, item.id)
                        // }
                      >
                        {displayValue(item?.value)}
                      </Typography>
                      {item.subValue && (
                        <Typography sx={styles.ToolTipContentSubValueTextStyle}>
                          {item?.subValue}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )}
              </>
            );
          })}
      </Box>
      {warningPane && (
        <Box sx={styles.warningPaneStyle}>
          {Boolean(detention) && detentionWarning}
          {Boolean(delay) && delayWarning}
          {Boolean(hos) && hosWarning}
        </Box>
      )}
    </Box>
  );
};

export default MapsToolTip;
