import { AlertConstants } from './alerts';
import { ImportConstants } from './import';
import { ManageTypesConstants } from './manageTypes';
import { TerminalConstants } from './terminals';

export const SettingsConstants = {
  ...AlertConstants,
  ...ImportConstants,
  ...ManageTypesConstants,
  ...TerminalConstants,
};
