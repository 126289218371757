import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import { StopSolutionV3StopType } from '../../../../ui-kit/TripPlan';
import {
  StopSolutionV3Prop,
  TripStopActivityEnum,
  TripStopStatusEnum,
  TripV3DataProps,
} from '../../../../ui-kit/components/TripPlan';
import { ESecondaryDetailsPanelType } from '../../../../views/dispatch/constants/types';
import { getTripV3BrokeredOption } from '../../../../views/trips/utils';
import { DirtyDetailsPanelManager } from '../../../DetailsPanel/utils';
import { ELoadStatus } from '../../constants/constants';
import { IOpenSecondaryDetailsHandler } from '../../constants/interface';
import { Trip } from './Models';
import TripAssignmentComponent from './components/TripAssignmentComponent';
export const convertRouteStopToTripStopV3Format = (
  stops: any[],
  tripId?: string,
  prevTrip?: Trip,
  nextTrip?: Trip,
  isLoadIDvisible = false
): StopSolutionV3Prop[] => {
  return stops.map((stop: any) => {
    return {
      ...stop,
      ...(prevTrip && {
        prevTrip: {
          id: prevTrip.id || null,
          seqNumber: prevTrip.seqNumber || null,
        },
      }),
      ...(nextTrip && {
        nextTrip: {
          id: nextTrip.id || null,
          seqNumber: nextTrip.seqNumber || null,
        },
      }),
      ...(isLoadIDvisible &&
        stop.loadDetails?.length && {
          loadId: stop.loadDetails[0].seqNumber,
        }),
      ...(isLoadIDvisible &&
        stop.loadDetails?.length && {
          loadDetails: stop.loadDetails,
        }),
      location: {
        ...stop.location, //Added for using getLoadDetailsFromMyLoad (location/addressDTO)
        locationName: stop.location?.locationName || '',
        address: stop.location?.address || '',
        // address: stop.location?.fullAddress || '', API return fullAddress always empty
        state: stop.location?.state || '',
        city: stop.location?.city || '',
      },
      activityType: stop.activityType as TripStopActivityEnum,
      status: stop.status as TripStopStatusEnum,
      tripId: stop.tripId || tripId,
      contact: {
        name: [stop.contact?.firstname, stop.contact?.lastname]
          .filter((str) => !!str)
          .join(' '),
        email: stop.contact?.email,
        phone: [stop.contact?.extension, stop.contact?.phone]
          .filter((str) => !!str)
          .join('.'),
      },
    };
  });
};

export const getResequenceUpRule = (prevStopStatus: string) => {
  return !prevStopStatus || prevStopStatus === TripStopStatusEnum.NONE;
};
export const setLoadStopsResequenceRules = (
  tripDataList: TripV3DataProps[],
  isManifest = false,
  isManifestTrip = false,
  isManifestDetial = false,
  isLoadDetails = false
) => {
  const tripsStopsList = tripDataList.map((trip) => trip.stopList);
  const stops = tripsStopsList.reduce((prev, next) => [...prev, ...next], []);
  stops.forEach((stop, index: number) => {
    const { type, status } = stop;
    let resequenceActions = {
      showResequenceIcons: false,
      resequenceUp: false,
      resequenceDown: false,
    };
    const loadCondition = isManifestDetial
      ? type === StopSolutionV3StopType.TRIP ||
        type === StopSolutionV3StopType.LOAD
      : type === StopSolutionV3StopType.LOAD;
    if (loadCondition && (!status || status === TripStopStatusEnum.NONE)) {
      resequenceActions.showResequenceIcons = true;
      let resequenceUp = !!index;
      let resequenceDown = true;
      if (index > 0) {
        const prevStop = stops[index - 1];
        if (index === 1 && prevStop.type === StopSolutionV3StopType.RELAY) {
          resequenceUp = false;
        } else {
          resequenceUp = getResequenceUpRule(prevStop.status || '');
        }
        if (
          resequenceUp &&
          prevStop.type === StopSolutionV3StopType.RELAY &&
          stops[index - 2]
        ) {
          resequenceUp = getResequenceUpRule(stops[index - 2].status || '');
        }
      }
      const nextStop = stops[index + 1];
      const prevStop = stops[index - 1];
      if (isManifestDetial) {
        // if (type === StopSolutionV3StopType.TRIP && index === 1 && prevStop.type === StopSolutionV3StopType.LOAD) {
        //   resequenceUp = false;
        // }
        // if (
        //   type === StopSolutionV3StopType.LOAD &&
        //   index === stops.length &&
        //   prevStop.type === StopSolutionV3StopType.TRIP
        // ) {
        //   resequenceUp = false;
        // }
        // if (type === StopSolutionV3StopType.LOAD && index === 0 && nextStop.type === StopSolutionV3StopType.TRIP) {
        //   resequenceDown = false;
        // }
        // if (
        //   type === StopSolutionV3StopType.LOAD &&
        //   index === stops.length &&
        //   nextStop.type === StopSolutionV3StopType.TRIP
        // ) {
        //   resequenceDown = false;
        // }
      }
      if (
        index === stops.length - 1 ||
        (nextStop.type === StopSolutionV3StopType.RELAY && !stops[index + 2])
      ) {
        resequenceDown = false;
      }

      resequenceActions.resequenceUp = resequenceUp;
      resequenceActions.resequenceDown = resequenceDown;
      resequenceActions.showResequenceIcons = resequenceUp || resequenceDown;
    }
    if (isManifest) {
      resequenceActions = manifestResequenceIcons(
        resequenceActions,
        stops,
        index
      );
    } else if (isManifestTrip) {
      resequenceActions = manifestTripResequenceIcons(
        resequenceActions,
        stops,
        index,
        tripDataList,
        isLoadDetails
      );
    }

    stop.resequenceActions = resequenceActions;
  });
};
export const manifestTripResequenceIcons = (
  resequenceActions: any,
  stops: any,
  index: number,
  tripDataList: TripV3DataProps[],
  isLoadDetails: boolean
) => {
  const resequenceActionsUpdate = resequenceActions;
  if (
    resequenceActions.resequenceUp &&
    stops[index].type != StopSolutionV3StopType.RELAY
  ) {
    if (
      stops[index].type === StopSolutionV3StopType.TRIP &&
      stops[index - 1].length === 0
    ) {
      resequenceActionsUpdate.resequenceUp = false;
    } else if (
      stops[index].type === StopSolutionV3StopType.LOAD &&
      stops[index].loadDetails[0]?.loadId ===
        stops[index - 1]?.loadDetails[0]?.loadId
    ) {
      resequenceActionsUpdate.resequenceUp = false;
    }
    // if (
    //   stops.length - 1 === index &&
    //   stops[index].type === 'LOAD' &&
    //   stops[stops.length - 2].type === 'TRIP'
    // ) {
    //   resequenceActionsUpdate.resequenceUp = false;
    // }

    if (
      !isLoadDetails &&
      index === stops.length - 1 &&
      resequenceActionsUpdate.resequenceUp &&
      tripDataList.length &&
      tripDataList[tripDataList.length - 1].stopList.length === 2 &&
      tripDataList[tripDataList.length - 1].stopList[0].type === 'RELAY'
    ) {
      resequenceActionsUpdate.resequenceUp = false;
    }
  }
  if (
    resequenceActions.resequenceDown &&
    stops[index].type != StopSolutionV3StopType.RELAY
  ) {
    if (
      stops[index].type === 'TRIP' &&
      stops[index + 1].length === stops.length
    ) {
      resequenceActionsUpdate.resequenceUp = false;
    } else if (
      stops[index].type === 'LOAD' &&
      stops[index].loadDetails[0]?.loadId ===
        stops[index + 1].loadDetails[0]?.loadId
    ) {
      resequenceActionsUpdate.resequenceDown = false;
    }

    if (
      !isLoadDetails &&
      index === 0 &&
      resequenceActionsUpdate.resequenceDown &&
      tripDataList.length &&
      tripDataList[0].stopList.length === 2 &&
      tripDataList[0].stopList[1].type === 'RELAY'
    ) {
      resequenceActionsUpdate.resequenceDown = false;
    }
  }
  const stopTypes = stops?.map((e) => e?.type);

  if (
    index > 0 &&
    stops[index].type === StopSolutionV3StopType.LOAD &&
    stops[index - 1]?.type === StopSolutionV3StopType.RELAY
  ) {
    let ifFutureLoadStopExists = false;
    for (let i = index + 1; i < stops.length; i++) {
      if (stops[i].type === StopSolutionV3StopType.RELAY) break;
      if (stops[i].type === StopSolutionV3StopType.LOAD) {
        ifFutureLoadStopExists = true;
        break;
      }
    }
    if (!ifFutureLoadStopExists) resequenceActions.resequenceUp = false;
  }

  if (
    index < stops.length - 1 &&
    stops[index].type === StopSolutionV3StopType.LOAD &&
    stops[index + 1]?.type === StopSolutionV3StopType.RELAY
  ) {
    let ifPastLoadStopExists = false;
    for (let i = index - 1; i >= 0; i--) {
      if (stops[i].type === StopSolutionV3StopType.RELAY) break;
      if (stops[i].type === StopSolutionV3StopType.LOAD) {
        ifPastLoadStopExists = true;
        break;
      }
    }
    if (!ifPastLoadStopExists) resequenceActions.resequenceDown = false;
  }

  // if (
  //   resequenceActions.resequenceDown &&
  //   stops[index].type === 'TRIP' &&
  //   index === stops.length - 2
  // ) {
  //   resequenceActionsUpdate.resequenceDown = false;
  // }
  resequenceActionsUpdate.showResequenceIcons =
    resequenceActionsUpdate.resequenceUp ||
    resequenceActionsUpdate.resequenceDown;
  return resequenceActionsUpdate;
};

export const manifestResequenceIcons = (
  resequenceActions: any,
  stops: any,
  index: number
) => {
  const resequenceActionsUpdate = resequenceActions;
  if (resequenceActions.resequenceUp) {
    if (stops[index].tripSeqNumber === stops[index - 1].tripSeqNumber) {
      resequenceActionsUpdate.resequenceUp = false;
    }
  }
  if (resequenceActions.resequenceDown) {
    if (stops[index].tripSeqNumber === stops[index + 1].tripSeqNumber) {
      resequenceActionsUpdate.resequenceDown = false;
    }
  }
  resequenceActionsUpdate.showResequenceIcons =
    resequenceActionsUpdate.resequenceUp ||
    resequenceActionsUpdate.resequenceDown;
  return resequenceActionsUpdate;
};

export const convertToTripSpecificFormat = (
  trips: Trip[],
  {
    openSecondaryDetailsHandler,
    isManifest = false,
    isAssignmentDisable = false,
    reasonAssignmentDisable = '',
    isManifestDetailPanel = false,
    isOpeningTripAssignment,
    setOpeningTripAssignment,
    showProgressIcon,
    disabled,
    onHold,
    disableAssignment,
  }: IOpenSecondaryDetailsHandler,
  entityType: 'OTHER' | 'TRACKING_MAP' = 'OTHER',
  loadID = ''
): TripV3DataProps[] => {
  return (trips || []).map((trip: Trip, index: number) => {
    const brokered = getTripV3BrokeredOption({
      trip,
      isOpeningTripAssignment,
      onHold,
    });

    return {
      disabled: disabled,
      id: trip.id,
      expanded: index === 0,
      expandedHOSPLan: false,
      isCompleted: trip.completed,

      headerData: {
        title: (
          <Typography variant="body2Bold" sx={{ color: 'text.primary' }}>
            {entityType == 'TRACKING_MAP'
              ? `Load ID `
              : isManifest
              ? `Trip`
              : `${t('tripId')}: `}
            <Box
              component={'span'}
              sx={{
                textDecoration: 'underline',
                color: 'primary.main',
                paddingLeft: '2px',
              }}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
                openSecondaryDetailsHandler?.(
                  trip.id,
                  ESecondaryDetailsPanelType.TRIP
                );
              }}
            >
              {isAssignmentDisable
                ? trip.seqNumber
                : entityType == 'TRACKING_MAP'
                ? loadID
                : trip.seqNumber}
            </Box>
          </Typography>
        ),
        renderHeaderContents: (): JSX.Element => {
          return isManifest ? (
            <></>
          ) : (
            <TripAssignmentComponent
              originalTrip={trip}
              trip={trip}
              isAssignmentDisable={isAssignmentDisable}
              reasonAssignmentDisable={reasonAssignmentDisable}
              isBrokered={!!trip.brokerageTrip}
              panelType={ESecondaryDetailsPanelType.LOAD}
              setOpeningTripAssignment={setOpeningTripAssignment}
              disableAssignment={disableAssignment}
            />
          );
        },
      },
      stopList: convertRouteStopToTripStopV3Format(
        trip.stops,
        trip.id,
        trips[index - 1],
        trips[index + 1],
        isManifestDetailPanel || isManifest || isAssignmentDisable
      ),
      showProgressIcon:
        [
          ELoadStatus.DISPATCHED,
          ELoadStatus.IN_TRANSIT,
          ELoadStatus.LOAD_COMPLETED,
        ].includes(trip.tripStatus) && showProgressIcon,
      brokered: brokered,
    };
  });
};
