import FormDialog from '../../../../../common/Ui/FormDialog';
import {
  ContactSummaryCustomer,
  CreateCustomerContactRequest,
  CustomerSummaryDTO,
  UpdateCustomerRequest,
} from '../../../../../models';
import { contactValidationSchema } from '../../../../../validator/validationSchemas';
import AddContactForm from '../../../../customers/forms/AddContactForm';
import { customerFormService } from '../../services/customerForm.service';
import { PhoneDataResDTO } from '../../types/requests';

interface IProps {
  data?: any;
  type?: 'ADD' | 'EDIT' | null;
  open?: boolean;
  contacts?: Array<ContactSummaryCustomer>;
  customerDetails?: CustomerSummaryDTO;
  onUpdateCustomer?: (
    customer: CustomerSummaryDTO,
    updatedContact?: ContactSummaryCustomer
  ) => void;
  onClose?: () => void;
}

const ContactDetailsForm = ({
  data,
  type,
  open,
  contacts,
  customerDetails,
  onUpdateCustomer,
  onClose,
}: IProps) => {
  const handleContactsUpdate = async (contact: PhoneDataResDTO) => {
    if (type === 'ADD') {
      const contactRequest = new CreateCustomerContactRequest({
        ...contact,
        customerId: customerDetails?.id,
      });
      const response = await customerFormService.createCustomerNewContact(
        contactRequest
      );
      if (response instanceof ContactSummaryCustomer) {
        if (customerDetails && !customerDetails?.contacts)
          customerDetails.contacts = [];
        customerDetails?.contacts?.push?.(response);
        onUpdateCustomer?.(customerDetails!, response);
      }
    } else {
      const updatedContacts = contacts?.map?.((ct) => {
        if (ct.id === contact?.id) {
          return contact;
        } else {
          return {
            ...ct,
            isPrimary: contact.isPrimary ? false : ct.isPrimary,
          };
        }
      });
      const requestData: UpdateCustomerRequest = new UpdateCustomerRequest({
        ...customerDetails,
        contacts: updatedContacts,
      });
      try {
        const res = await customerFormService.updateCustomerContact(
          requestData
        );
        const updatedContact = res?.contacts?.find?.(
          (e) => e?.phoneData?.phone === contact?.phoneData?.phone
        );
        onUpdateCustomer?.(res, updatedContact!);
      } catch (error) {
        return;
      }
    }
  };

  return (
    <FormDialog
      data={data}
      titleText={type === 'ADD' ? 'Add New Contact' : data?.fullName}
      actionLabel={type === 'ADD' ? 'Add Contact' : 'Save Changes'}
      open={open}
      onAction={handleContactsUpdate}
      handleClose={onClose}
      validationSchema={contactValidationSchema}
      disableActionBtnByDefault={false}
      contentRenderer={() => <AddContactForm />}
    ></FormDialog>
  );
};
export { ContactDetailsForm };
