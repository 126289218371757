import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET,
} from '../../hooks/useThemeResponsive';

export const StyledSafetyStandingCard = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: 'none',
  width: '100%',
  [theme?.breakpoints?.down(BREAKPOINT_TABLET)]: {
    width: '100%',
  },
}));

export const StyledSafetyStandingItem = styled(
  (
    props: BoxProps & {
      color?: string;
      background?: string;
    }
  ) => <Box {...props} />
)(
  ({
    theme,
    color,
    background,
  }: {
    theme: any;
    color?: string;
    background?: string;
  }) => ({
    width: '100%',
    height: '85px',
    border: `1px solid transparent`,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    background: theme.palette.info.outlinedHoverBackground,
    ...(color && {
      color: color,
    }),
    ...(background && {
      background: background,
    }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme?.breakpoints?.down(BREAKPOINT_TABLET)]: {
      borderRadius: '4px',
      width: '100%',
      height: '85px',
    },
    [theme?.breakpoints?.between(BREAKPOINT_TABLET, BREAKPOINT_DESKTOP)]: {
      borderRadius: '4px',
      width: '100%',
      height: '85px',
    },
    [theme?.breakpoints?.up(BREAKPOINT_DESKTOP)]: {
      width: '100%',
      height: '75px',
    },
  })
);
