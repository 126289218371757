import { Box, Divider, Theme, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import moment from 'moment';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomGeofenceIcon } from '../../../../../_assets/static/svgs/CustomGeofenceLogo';
import AppointmentConfirmation from '../../../../../components_v2/generic/appointment-confirmation';
import { StopTypeEnum, StopTypeOptionsValues } from '../../schema/stops';
import {
  NotesSummaryStyle,
  StopSummaryContainerStyles,
  VerticalDividerStyle,
} from '../../style';
import { ILoadStop } from '../../types/requests';
import { toFixDigit } from '../../utils';

const StopsSummary: React.FC = () => {
  const { getValues } = useFormContext();
  const stops = getValues('stops');
  const totalMiles = getValues('totalMiles');
  const renderAppointmentConfirmationIcons = (stop: ILoadStop) => {
    return (
      <AppointmentConfirmation
        status={stop?.appointmentConfirmation}
        size={24}
      />
    );
  };

  const renderDropOffAppointmentConfirmationIcons = (stop: ILoadStop) => {
    return (
      <AppointmentConfirmation
        status={stop?.dropOffAppointmentConfirmation}
        size={24}
      />
    );
  };

  const containerStyles = useMemo(() => {
    const isAddressExists = stops?.some?.(
      (e: ILoadStop) => e?.location?.address?.length
    );
    if (isAddressExists) return { mt: '15px' };
    return { mt: 0 };
  }, [stops]);

  const renderNoApptDates = () => {
    return (
      <Box
        p={'2px 8px'}
        sx={(theme: Theme) => ({
          border: `1px dashed ${theme.palette?.grey?.[600]}`,
        })}
        borderRadius={'4px'}
      >
        <Typography variant="caption" color={'textSecondary'}>
          No Appt
        </Typography>
      </Box>
    );
  };

  const renderAppointmentDetails = (stop: ILoadStop) => {
    if (!stop?.appointmentStartDate && !stop?.appointmentEndDate)
      return renderNoApptDates();
    return (
      <Stack>
        <Box display={'flex'} alignItems={'center'} gap={'3px'}>
          {stop?.appointmentStartDate && (
            <Typography
              variant="body2"
              color="textPrimary"
              minWidth={'50px'}
              textAlign={'end'}
            >
              {moment(stop?.appointmentStartDate).format('MMM DD')}
            </Typography>
          )}
          {stop?.appointmentStartDate && stop?.appointmentEndDate && (
            <Divider
              sx={(theme: Theme) => ({
                width: '10px',
                height: '2px',
                background: theme.palette.grey[500],
              })}
            />
          )}
          {stop?.appointmentEndDate && (
            <Typography
              variant="body2"
              color="textPrimary"
              minWidth={'50px'}
              textAlign={'end'}
            >
              {moment(stop?.appointmentEndDate).format('MMM DD')}
            </Typography>
          )}
        </Box>
        <Box display={'flex'} gap="3px" width={'100%'}>
          {stop?.appointmentStartDate && stop?.appointmentStartTime && (
            <Typography
              variant="caption"
              ml="auto"
              pr={1}
              color={'textSecondary'}
            >
              {stop?.appointmentStartTime}
            </Typography>
          )}
          {stop?.appointmentEndDate && stop?.appointmentEndTime && (
            <Typography variant="caption" ml="auto" color={'textSecondary'}>
              {stop?.appointmentEndTime}
            </Typography>
          )}
        </Box>
        {!!stop?.location?.timezone && (
          <Typography variant="caption" textAlign={'end'} fontSize={10}>
            {stop?.location?.timezone}
          </Typography>
        )}
      </Stack>
    );
  };

  const renderHandlingTime = (estimatedActivityDuration: number) => {
    if (!estimatedActivityDuration) return <></>;
    return (
      <Typography variant="body2" color="textSecondary">
        Handling Time:
        {Math.floor(estimatedActivityDuration / 60) > 0 && (
          <span>
            <b>{' ' + Math.floor(estimatedActivityDuration / 60)}</b>h
          </span>
        )}
        {Math.floor(estimatedActivityDuration % 60) > 0 && (
          <span>
            <b>{' ' + Math.floor(estimatedActivityDuration % 60)}</b>min
          </span>
        )}
      </Typography>
    );
  };

  return (
    <>
      <Box width={'92%'}>
        {!!(
          stops[0]?.location?.address &&
          stops[stops.length - 1]?.location?.address
        ) && (
          <Typography variant="body1">
            <b>
              {stops[0].location.city + ', ' + stops[0].location.state}{' '}
              {stops[stops.length - 1]?.location?.address ? ' > ' : ''}{' '}
              <span style={{ fontWeight: 400 }}>
                {!!(stops?.length - 2) && `  ${stops?.length - 2}  Stops`}
              </span>
              {!!(stops?.length - 2) && ' > '}
              {stops[stops.length - 1]?.location?.city +
                ', ' +
                stops[stops.length - 1]?.location?.state}
            </b>
            {!!totalMiles && <> ({toFixDigit(totalMiles)} miles)</>}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
          m:
            stops[0]?.location?.businessName &&
            stops[stops.length - 1]?.location?.businessName
              ? 2
              : 0,
          ml: 0,
        }}
      >
        <Stack direction="column" gap={2} sx={containerStyles}>
          {stops.map((stop: any, index: number) => {
            if (!stop.location?.address) return null;
            return (
              <Box key={index} sx={{ pl: '35px', position: 'relative' }}>
                {(stop.type || stop.handlingTime) && (
                  <Stack direction="row" gap={1}>
                    {stop.type && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ flexGrow: '1' }}
                      >
                        {StopTypeOptionsValues[stop.type] ?? ''}
                      </Typography>
                    )}
                    {stop.type !== StopTypeEnum.RELAY && (
                      <Stack direction={'row'} gap={1} alignItems={'center'}>
                        {renderHandlingTime(stop.estimatedActivityDuration)}
                        {stop?.isDriverAssistRequired && (
                          <>
                            <Divider sx={VerticalDividerStyle} />
                            <Typography variant="body2">
                              Driver to assist
                            </Typography>
                          </>
                        )}
                      </Stack>
                    )}
                  </Stack>
                )}
                <Stack gap={'10px'} direction={'row'}>
                  <Stack>
                    <Stack direction="row" gap={1} sx={{ mt: '3px' }}>
                      <Box sx={StopSummaryContainerStyles}>{index + 1}</Box>
                      {stop.location?.locationName && (
                        <Typography variant="body1" sx={{ flexGrow: '1' }}>
                          <b>{stop.location?.locationName}</b>
                        </Typography>
                      )}
                    </Stack>
                    {stop.location?.address && (
                      <Stack direction="row" gap={0.5} alignItems={'center'}>
                        {!!stop.location?.geoCoordinates?.length && (
                          <CustomGeofenceIcon />
                        )}
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ flexGrow: '1' }}
                        >
                          {stop.location?.address}
                        </Typography>
                      </Stack>
                    )}

                    {(stop.contact?.contactName ||
                      stop.phone ||
                      stop.email) && (
                      <Stack direction="row" gap={1} sx={{ mt: '3px' }}>
                        {stop.contact?.contactName && (
                          <Typography variant="body2" color="textSecondary">
                            {stop.contact?.contactName}
                          </Typography>
                        )}
                        {stop.contact?.phone && (
                          <Typography variant="body2" color="textSecondary">
                            {stop.contact?.phone}
                          </Typography>
                        )}
                        {stop.contact?.email && (
                          <Typography variant="body2" color="textSecondary">
                            {stop.contact?.email}
                          </Typography>
                        )}
                      </Stack>
                    )}
                    {stop.referenceNumber && (
                      <Stack direction="row" gap={1} sx={{ mt: '3px' }}>
                        <span style={{ fontWeight: 400, fontSize: '14px' }}>
                          Reference #
                        </span>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            flexGrow: '1',
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {stop.referenceNumber}
                        </Typography>
                      </Stack>
                    )}

                    {stop.notes && (
                      <Box sx={NotesSummaryStyle}>
                        <Typography variant="body2">{stop.notes}</Typography>
                      </Box>
                    )}
                  </Stack>
                  {stop?.type !== StopTypeEnum.RELAY && (
                    <Stack
                      direction={'row'}
                      ml="auto"
                      alignItems={'flex-start'}
                      gap="3px"
                    >
                      {renderAppointmentConfirmationIcons(stop)}
                      <Box>{renderAppointmentDetails(stop)}</Box>
                    </Stack>
                  )}
                </Stack>

                {stop?.type === StopTypeEnum.RELAY && (
                  <Stack direction="row" alignItems={'flex-start'} gap={3}>
                    <Box ml="auto" textAlign={'end'}>
                      <Typography
                        variant="body2"
                        fontWeight={700}
                        sx={(theme: Theme) => ({
                          color: theme.palette.grey?.['a900'],
                        })}
                      >
                        Drop Trailer
                      </Typography>
                      {stop?.isRelayDropOffDriverAssistRequired && (
                        <Typography variant="caption">
                          Driver to assist
                        </Typography>
                      )}
                      {renderHandlingTime(
                        stop?.relayDropOffEstimatedActivityDuration
                      )}
                      <Stack direction={'row'} gap={0.5}>
                        {renderDropOffAppointmentConfirmationIcons(stop)}
                        {stop?.relayDropOffAppointmentStartDate &&
                        stop?.relayDropOffAppointmentEndDate ? (
                          <Box maxWidth={'113px'} ml="auto">
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gap={'3px'}
                            >
                              {stop?.relayDropOffAppointmentStartDate && (
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  minWidth={'50px'}
                                  textAlign={'end'}
                                >
                                  {moment(
                                    stop?.relayDropOffAppointmentStartDate
                                  ).format('MMM DD')}
                                </Typography>
                              )}
                              {stop?.relayDropOffAppointmentStartDate &&
                                stop?.relayDropOffAppointmentEndDate && (
                                  <Divider
                                    sx={(theme: Theme) => ({
                                      width: '10px',
                                      height: '2px',
                                      background: theme.palette.grey[500],
                                    })}
                                  />
                                )}
                              {stop?.relayDropOffAppointmentEndDate && (
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  minWidth={'50px'}
                                  textAlign={'end'}
                                >
                                  {moment(
                                    stop?.relayDropOffAppointmentEndDate
                                  ).format('MMM DD')}
                                </Typography>
                              )}
                            </Box>
                            <Box display={'flex'} gap="3px" width={'100%'}>
                              {stop?.relayDropOffAppointmentStartTime && (
                                <Typography
                                  variant="caption"
                                  ml="auto"
                                  pr={1}
                                  color={'textSecondary'}
                                >
                                  {stop?.relayDropOffAppointmentStartTime}
                                </Typography>
                              )}
                              {stop?.relayDropOffAppointmentEndTime && (
                                <Typography
                                  variant="caption"
                                  ml="auto"
                                  color={'textSecondary'}
                                >
                                  {stop?.relayDropOffAppointmentEndTime}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ) : (
                          renderNoApptDates()
                        )}
                      </Stack>
                      {!!(
                        stop?.location?.timezone &&
                        stop?.relayDropOffAppointmentStartDate
                      ) && (
                        <Typography
                          variant="caption"
                          textAlign={'end'}
                          position={'relative'}
                          bottom={8}
                          fontSize={10}
                        >
                          {stop?.location?.timezone}
                        </Typography>
                      )}
                    </Box>

                    <Box textAlign={'end'}>
                      <Typography
                        variant="body2"
                        fontWeight={700}
                        sx={(theme: Theme) => ({
                          color: theme.palette.grey?.['a900'],
                        })}
                      >
                        Pickup Trailer
                      </Typography>
                      {stop?.isDriverAssistRequired && (
                        <Typography variant="caption">
                          Driver to assist
                        </Typography>
                      )}
                      {renderHandlingTime(stop?.estimatedActivityDuration)}
                      <Stack
                        maxWidth={'150px'}
                        ml="auto"
                        gap={'3px'}
                        // alignItems={'center'}
                        justifyContent={'end'}
                        direction={'row'}
                      >
                        {renderAppointmentConfirmationIcons(stop)}
                        {renderAppointmentDetails(stop)}
                      </Stack>
                    </Box>
                  </Stack>
                )}
              </Box>
            );
          })}
        </Stack>
      </Box>
    </>
  );
};
export default StopsSummary;
