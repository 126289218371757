export const BULK_OPTYMIZATION_ENDPOINTS = {
  optimize: '/web/collector/api/v2/sandbox/optimize',
  rejectAssignment: '/web/collector/api/v2/sandbox/reject-action',
  lockAssignment: '/web/collector/api/v2/sandbox/lock-action',
  getPossibleDriverTrips:
    '/web/collector/api/v2/sandbox/possible-drivers-trips',
  validateLockAssignment:
    '/web/collector/api/v2/sandbox/validate-lock-combination',
  saveOptimization: '/web/collector/api/v2/sandbox/save-exit',
  validateOptimizationResult:
    '/web/collector/api/v2/sandbox/validate-save-exit',
  saveEnginePreference: '/web/collector/api/v2/sandbox/update-sandbox-settings',
  getEnginePreferences: '/web/collector/api/v2/sandbox/get-sandbox-settings',
  restartOptimization: '/web/collector/api/v2/sandbox/restart',
  cancelOptimization: '/web/collector/api/v2/sandbox/cancel-sandbox',
  sandboxWarnings: '/web/collector/api/v2/sandbox/warnings',
  possibleDriverTripsWarnings:
    '/web/collector/api/v2/sandbox/possible-driver-trip-warnings',
};
