import React from 'react';
import { SvgIcon } from '@mui/material';
import { SimCardAlertOutlined } from '@mui/icons-material';

const WarningsIcon = () => {
  return (
    <SvgIcon
      sx={{
        color: 'warning.dark',
        width: '20px',
        height: '20px',
      }}
    >
      <SimCardAlertOutlined sx={{ width: '20px', height: '20px' }} />
    </SvgIcon>
  );
};

export default WarningsIcon;
