import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';

// Note: later ButtonGroup component can be replaced by axele-ui-kit ButtonGroup component ( currenlty Axele Button Group is not available axele-ui-kit)
const ButtonGroup_Like_Dislike = ({
  name,
  control,
  sizes = { xs: 12 },
  style,
  options = [],
  disabled,
}: {
  name: string;
  control?: any;
  sizes?: { xs: number };
  style?: object;
  options?: {
    key: string;
    name: string;
    iconRenderer?: (selected: boolean) => ReactNode;
    getButtonStyle?: (selected: boolean) => object;
  }[];
  disabled?: boolean;
}) => {
  return (
    <Grid xs={sizes.xs} item style={style}>
      <Controller
        render={({ field }) => {
          return (
            <ButtonGroup
              {...field}
              size={'small'}
              aria-label="outlined primary button group"
            >
              {options.map((el) => {
                const selected = el.key === field.value;
                return (
                  <Button
                    size={'small'}
                    key={el.key}
                    variant={selected ? 'contained' : 'outlined'}
                    {...field}
                    onClick={() => {
                      const fieldValue = field.value !== el.key ? el.key : '';
                      field.onChange(fieldValue);
                    }}
                    style={
                      typeof el.getButtonStyle === 'function'
                        ? el.getButtonStyle(selected)
                        : {}
                    }
                    disabled={disabled}
                  >
                    {typeof el.iconRenderer === 'function'
                      ? el.iconRenderer(selected)
                      : el.name}
                  </Button>
                );
              })}
            </ButtonGroup>
          );
        }}
        name={name}
        control={control}
      />
    </Grid>
  );
};

export default ButtonGroup_Like_Dislike;
