export const getStyledEDIActivation = () => {
  return {
    root: {
      width: '100%',
      height: '76vh',
      overflow: 'auto',
    },
    headerRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      padding: '0px',
      backgroundColor: 'primary.contrast',
      borderRadius: '16px 16px 0px 0px',
    },
    headerContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '46px 32px',
      gap: '8px',
      width: '100%',
      height: '136px',
      backgroundColor: 'leftMenuCollapsed.canvas',
      borderRadius: '16px 16px 0px 0px',
    },
    bodyRoot: {
      p: 4,
    },
    heading1: {
      color: 'primary.main',
      pb: 3,
    },
    intro: {
      width: '866.5px',
      fontFamily: "'Poppins'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '200%',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.15px',
      color: 'text.secondary',
      pb: 4,
    },
    outtro: {
      fontFamily: "'Poppins'",
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '140%',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.15px',
      color: 'text.secondary',
      pb: 4,
    },
    itemLabel: {
      color: 'text.secondary',
    },
    itemValue: {
      fontFamily: "'Poppins'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20.5714px',
      lineHeight: '150%',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.192857px',
      color: 'text.primary',
    },
  };
};

export const getStyledEDICompanyCard = () => {
  return {
    Card: {
      width: 704,
    },
    title: {
      fontFamily: "'Poppins'",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '160%',
      letterSpacing: '0.15px',
      color: 'primary.main',
    },
    subheader: {
      fontFamily: "'Poppins'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.15px',
      color: 'text.primary',
    },
    CardContent: {
      pl: 8,
      pt: 0,
    },
    groupName: {
      fontFamily: "'Poppins'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '0.15px',
      color: 'text.secondary',
      mb: 1,
    },
    groupItem: {
      pl: 1,
      fontFamily: "'Poppins'",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      letterSpacing: '0.15px',
      color: 'text.primary',
    },
  };
};
