import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import { loadService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import {
  GetLoadOverviewDetailsRequest,
  LoadOverviewRemoveLoads,
  UpdateLoadReferenceDetailsRequest,
} from '../../../../models';
import { LoadOverviewDetails } from './models';
export const getLoadOverviewDetails = async (loadId: string) => {
  const requestData = new GetLoadOverviewDetailsRequest({
    loadId,
  });

  const response = await loadService.getLoadOverviewDetails(loadId);
  if (response instanceof ServiceError) {
    return null;
  }
  return response;
};

export const updateLoadReferenceDetails = async (
  loadDetails: LoadOverviewDetails
) => {
  const requestData = new UpdateLoadReferenceDetailsRequest(loadDetails);
  const response = await loadService.updateLoadReferenceDetails(requestData);
  return response;
};
export const removeLoadFromManifest = async (
  loadDetails: LoadOverviewRemoveLoads
) => {
  return await loadService.removeLoadFromManifest(loadDetails);
};
