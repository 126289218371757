import { FC, memo } from 'react';
import { LoadboardPicker } from './LoadboardPicker';
import { LoadBoardAccount } from './types';

export interface LoadboardPickerFilterProps {
  id?: string;
  column: any;
  defaultFilterValue: Record<string, any>;
  onChangeCb?: (option: LoadBoardAccount[], name?: string) => void;
  isFormControl?: boolean;
  field: Record<string, any> | undefined;
}

export const LoadboardPickerFilter: FC<LoadboardPickerFilterProps> = memo(
  ({
    column,
    defaultFilterValue,
    onChangeCb,
    isFormControl = false,
    field = {},
  }: LoadboardPickerFilterProps) => {
    return (
      <LoadboardPicker
        isFormControl={isFormControl}
        name={field.name || column.name}
        options={column.options}
        onChange={(option: LoadBoardAccount[], name?: string) => {
          const cloneNewValue = option;
          if (isFormControl) {
            field.onChange(cloneNewValue, name);
            column.onChange && column.onChange(cloneNewValue, name);
          } else {
            onChangeCb && onChangeCb(cloneNewValue, name);
          }
        }}
        defaultOptions={
          isFormControl ? field.value : defaultFilterValue[column.name]
        }
        width={isFormControl ? '270px' : column.width}
        variant={'outlined'}
      />
    );
  }
);
LoadboardPickerFilter.displayName = 'LoadboardPickerFilter';
