import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const EmptyList = ({ description }: { description: string }) => {
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Box
        sx={{
          pl: 0,
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 1,
            backgroundColor: 'info.outlinedHoverBackground',
            border: '1px dashed',
            borderColor: 'leftMenuExpanded.inactive',
            borderRadius: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: 'primary.main',
            }}
          >
            {t('noDataHere')}
          </Typography>
          <Typography
            sx={{
              width: '210px',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '150%',
              display: 'flex',
              alignItems: 'flex-end',
              textAlign: 'center',
              color: 'action.active',
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    );
  }
};
