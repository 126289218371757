import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { invoiceModule } from '../../../../common/PendoClassIDs/constants';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import DatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';
import FormDialog from '../../../../common/Ui/FormDialog';
import SliderForm from '../../../../common/Ui/Slider';
import Switch from '../../../../common/Ui/Switcher';
import TextField from '../../../../common/Ui/TextField/TextField';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import {
  DeleteButtonIcon,
  EditIcon,
} from '../../../../ui-kit/components/Assets';
import {
  amountFormat,
  fromMomentToIso,
  nullableDateYup,
  nullableNumberYup,
} from '../../../../utils';
import {
  PAID_LOAD_STATUS_NAME,
  receivePaymentFormRequiredFields,
} from '../../constants';
import {
  LineItem,
  ReceivePaymentFormRequiredFields,
} from '../../models/InvoiceModel';
import AccordionBlue from '../AccordionBlue';
import PaymentDetailsPanel from '../../../../views/finance/paymentManagement/components/PaymentDetailsPanel/PaymentDetailsPanel';
import SecondaryDetailsPanel from '../../../../common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import { useStores } from '../../../../store/root.context';
import { IconButton, Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { KeyboardReturnSharp } from '@mui/icons-material';
import DeletePopup from '../../../../common/DeletePopup';
const hoverStyles = {
  // display: 'flex',
  // justifyContent: 'space-between',
  // borderRadius: '8px',
  mb: 1,
  width: '100%',
};

const noneHoverStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '5px 15px',
};

export function InvoiceReceivedPayment({
  factoringChargeAmount,
  loadStatus,
  requiredFields = {
    ...receivePaymentFormRequiredFields,
  },
  isFactoreView,
  canEditInvoice,
}: {
  factoringChargeAmount: number;
  loadStatus: string;
  requiredFields?: ReceivePaymentFormRequiredFields;
  isFactoreView: boolean;
  canEditInvoice: boolean;
}) {
  const [addReceivePaymentDialogOpen, setAddReceivePaymentDialogOpen] =
    useState(false);
  const [deletePayment, setDeletePayment] = useState({
    id: null,
    open: false,
  });
  const {
    invoiceStore: { setPaymentPanel, deletePaymentToInvoice },
  } = useStores();
  const {
    control,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'receivedPayments',
  });
  const [
    receivedPayments = [],
    lineItems = [],
    formFactoringChargeAmount = factoringChargeAmount,
    factoringChargePercent = 0,
    total,
    childInvoices,
    amountDue,
    invoiceId,
  ] = watch([
    'receivedPayments',
    'lineItems',
    'formFactoringChargeAmount',
    'factoringChargePercent',
    'total',
    'childInvoices',
    'amountDue',
    'invoiceId',
  ]);
  const invoiceTotal: number = (lineItems || []).reduce(
    (accumulator: number, object: LineItem) => {
      return accumulator + object.amount;
    },
    0
  );

  const receivedPaymentsTotal: number = receivedPayments.reduce(
    (accumulator: number, object: { amount: number }) => {
      return accumulator + +object.amount;
    },
    0
  );
  const sumOfChildInvoice =
    childInvoices?.reduce(
      (total: number, currentObject: { total: number }) =>
        total + currentObject.total,
      0
    ) || 0;
  const isPaid = loadStatus === PAID_LOAD_STATUS_NAME;

  useEffect(() => {
    const sumTotal = sumOfChildInvoice + invoiceTotal;
    setValue('total', sumTotal);
    setValue('amountDue', sumTotal - receivedPaymentsTotal);
    setValue('amountDueTotal', amountDue);
  }, [amountDue, invoiceTotal, receivedPaymentsTotal, sumOfChildInvoice]);

  useEffect(() => {
    setValue(
      'formFactoringChargeAmount',
      (invoiceTotal * Number(factoringChargePercent)) / 100
    );
  }, [invoiceTotal, factoringChargePercent]);

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...receivedPayments[index],
    };
  });

  const handlePaymentDelete = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    remove(index);
  };

  const isReceivedPayment = !!(amountDue + sumOfChildInvoice > 0);
  const { hasInvoiceEditPermission } = useLoadPermission();

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            backgroundColor: 'uncategorized.oldHeaderSecondary',
            borderRadius: 2,
            p: 1,
          }}
        >
          <Box sx={noneHoverStyles}>
            <Box>
              <Typography variant={'subtitle2'}>Invoice Total</Typography>
            </Box>
            <Box>
              <Typography variant={'subtitle2'}>
                {amountFormat(total)}
              </Typography>
            </Box>
          </Box>
          {controlledFields.map((field, index) => {
            return (
              <>
                {/* <AccordionBlue
                  title={`Received ${field.advanced ? '(Advance)' : ''}`}
                  subTitle={
                    fromMomentToIso(
                      moment(field.paymentDate),
                      'MM/DD/YYYY'
                    ) as string
                  }
                  renderLabel={(expanded) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant={'subtitle1'}>{`-${amountFormat(
                        field.amount
                      )}`}</Typography>
                      {expanded && canEditInvoice && (
                        <Permission
                          contains={[
                            AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICE_EDIT,
                          ]}
                        >
                          <Box
                            sx={{ ml: 2 }}
                            onClick={(e) => handlePaymentDelete(e, index)}
                          >
                            <DeleteButtonIcon />
                          </Box>
                        </Permission>
                      )}
                    </Box>
                  )}
                >
                  <HideableReceivePaymentForm
                    nameStart={`receivedPayments.${index}.`}
                    isPaid={isPaid}
                    requiredFields={requiredFields}
                  />
                </AccordionBlue> */}
                <Stack
                  direction={{ sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                  key={index}
                  alignItems="center"
                  sx={{
                    borderRadius: 2,
                    mb: 1,
                    backgroundColor: '#DCE8FC',
                    width: '100%',
                    p: '5px 8px',
                    cursor: 'pointer',
                    '&:hover .editDeleteElement': {
                      display: 'contents !important',
                    },
                  }}
                  onClick={(e) => {
                    if (isDirty) return;
                    if (!hasInvoiceEditPermission) return;
                    setPaymentPanel({
                      id: field.id,
                      open: true,
                    });
                  }}
                >
                  <Box sx={{ width: '40%' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: '#040022',
                        fontWeight: '400',
                        pb: 0,
                        mb: 0,
                      }}
                    >
                      Payment : {field.seqNumber}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'inline',
                        fontSize: '10px',
                        pt: 0,
                        mt: 0,
                        position: 'relative',
                        top: '-5px',
                      }}
                    >
                      {fromMomentToIso(moment(field.paymentDate), 'Do MMM YY')}{' '}
                      {field.advanced ? ' | Advance' : ''}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '60%' }}>
                    {' '}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography variant={'subtitle1'}>{`-${amountFormat(
                        field.amount
                      )}`}</Typography>
                      {!isDirty && (
                        <Permission
                          contains={[
                            AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICE_EDIT,
                          ]}
                        >
                          <Box
                            className="editDeleteElement"
                            sx={{ display: 'none' }}
                          >
                            <IconButton
                              aria-label="Edit"
                              sx={{ ml: 1 }}
                              // onClick={(e) => handlePaymentDelete(e, index)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                            <Box
                              sx={{ ml: 1 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeletePayment({
                                  open: true,
                                  id: field.id,
                                });
                              }}
                            >
                              <DeleteButtonIcon />
                            </Box>
                          </Box>
                        </Permission>
                      )}
                    </Box>
                  </Box>
                </Stack>
              </>
            );
          })}
          {isFactoreView && (
            <Box sx={noneHoverStyles}>
              <Box>
                <Typography variant={'body1'}>Factoring Charges ($)</Typography>
              </Box>
              <Box>
                <Typography variant={'body1'}>
                  {formFactoringChargeAmount
                    ? `-${amountFormat(formFactoringChargeAmount)}`
                    : amountFormat(formFactoringChargeAmount)}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            sx={{ ...noneHoverStyles, mt: 2, color: '#2B64CB', fontSize: 20 }}
          >
            <Box>
              <Typography variant={'body1'}>Amount Due ($)</Typography>
            </Box>
            <Box>
              <Typography variant={'body1'}>
                {amountFormat(amountDue)}
              </Typography>
            </Box>
          </Box>
          {'amountDue' in errors && (
            <Box sx={{ ...noneHoverStyles, justifyContent: 'flex-end' }}>
              <Typography sx={{ color: 'error.main' }} variant={'body2'}>
                {errors.amountDue.message}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {isReceivedPayment &&
        canEditInvoice &&
        loadStatus != 'PAID' &&
        !isDirty && (
          <Permission
            contains={[
              AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICE_EDIT,
              AXELE_PERMISSION_TYPE.PAYMENT_EDIT,
              AXELE_PERMISSION_TYPE.PAYMENT_ADD,
            ]}
          >
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                id={invoiceModule + 'ReceivePayment'}
                sx={{ color: 'primary.main', cursor: 'pointer' }}
                // onClick={() => setAddReceivePaymentDialogOpen(true)}
                onClick={() => {
                  setPaymentPanel({
                    id: null,
                    open: true,
                  });
                }}
              >
                + Receive Payment
              </Typography>
            </Box>
          </Permission>
        )}
      {addReceivePaymentDialogOpen && (
        <ReceivePaymentDialog
          amountDue={amountDue}
          open={addReceivePaymentDialogOpen}
          onAction={(data) => {
            append(data);
            setAddReceivePaymentDialogOpen(false);
          }}
          onClose={() => setAddReceivePaymentDialogOpen(false)}
        />
      )}

      <DeletePopup
        title={'Remove Payment'}
        body={'Are you sure, you want to remove the payment from invoice?'}
        open={deletePayment.open}
        okText={'Remove'}
        onClose={() => {
          setDeletePayment({
            open: false,
            id: null,
          });
        }}
        onAction={() => {
          const params = {
            paymentId: deletePayment.id,
            invoiceId: invoiceId,
          };
          deletePaymentToInvoice(params);
          setDeletePayment({
            open: false,
            id: null,
          });
        }}
      />
    </>
  );
}

export function ReceivePaymentDialog({
  open,
  onAction,
  onClose,
  amountDue,
}: {
  open: boolean;
  onAction: (data: {
    [key: string]: string | number | null | string[];
  }) => void;
  onClose: () => void;
  amountDue: number;
}) {
  return (
    <FormDialog
      data={{
        amount: null,
        paymentDate: new Date(),
        referenceNumber: null,
        advanced: false,
      }}
      validationSchema={yup.object().shape({
        amount: nullableNumberYup()
          .required('Amount is required.')
          .min(1)
          .max(amountDue, `Value must be between 1 and ${amountDue}`),
        referenceNumber: yup
          .string()
          .test('length', 'Cannot be longer than 50 characters', (val) =>
            !!val ? val.length <= 50 : true
          )
          .nullable(),
        advanced: yup.boolean(),
        paymentDate: nullableDateYup()
          .required('Payment Date is required.')
          .nullable(),
      })}
      titleText="Receive Payment"
      actionLabel="Receive Payment "
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <ReceivePaymentForm amountDue={amountDue} />}
    />
  );
}

export function HideableReceivePaymentForm({
  nameStart,
  isPaid,
  requiredFields = {
    ...receivePaymentFormRequiredFields,
  },
}: {
  nameStart: string;
  isPaid: boolean;
  requiredFields?: ReceivePaymentFormRequiredFields;
}) {
  const { hasFinancialInvoiceEdit } = useLoadPermission();
  const { control } = useFormContext();
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: 1, mr: 3 }}>
        <DatePicker
          iso
          disableFuture
          control={control}
          name={`${nameStart}paymentDate`}
          label={'Date'}
          disabled={!hasFinancialInvoiceEdit || isPaid}
        />
      </Box>
      <Box sx={{ flex: 1, mr: 3 }}>
        <TextField
          control={control}
          name={`${nameStart}referenceNumber`}
          label={'Reference Number'}
          disabled={!hasFinancialInvoiceEdit || isPaid}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <TextField
          required={requiredFields.amount}
          type={'decimal'}
          control={control}
          name={`${nameStart}amount`}
          label={'Amount ($)'}
          onlyNumbers
          disabled={!hasFinancialInvoiceEdit || isPaid}
        />
      </Box>
    </Box>
  );
}

export function ReceivePaymentForm({ amountDue }: { amountDue: number }) {
  const { control } = useFormContext();

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 7, ml: 1 }}>
          <SliderForm
            control={control}
            name={'amount'}
            aria-label="Default"
            valueLabelDisplay="auto"
            min={1}
            max={amountDue}
          />
        </Box>
        <Box sx={{ flex: 2, ml: 2 }}>
          <TextField
            id={invoiceModule + 'ReceivePaymentAmount'}
            control={control}
            required
            name={'amount'}
            label={'Amount ($)'}
            onlyNumbers
          />
        </Box>
      </Box>
      <Box>
        <DatePicker
          required
          iso
          control={control}
          name={'paymentDate'}
          label={'Payment Date'}
          disableFuture
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1, ml: 1 }}>
          <TextField
            id={invoiceModule + 'ReceivePaymentReferenceNumber'}
            control={control}
            name={'referenceNumber'}
            label={'Reference Number'}
          />
        </Box>
        <Box sx={{ flex: 1, ml: 1 }}>
          <Switch
            id={invoiceModule + 'ReceivePaymentAdvance'}
            control={control}
            name={'advanced'}
            label={'Advance'}
            style={{ marginBottom: 0 }}
          />
        </Box>
      </Box>
    </>
  );
}
