import { Box, ThemeProvider } from '@mui/material';
import { Stack } from '@mui/system';
import { useSearchParams } from 'react-router-dom';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { AuthIntroduction } from './components/AuthIntroduction';
import { EPartner } from './constant';
import RegistrationPage from './RegistrationPage';
import { getRegistrationStyles } from './styles';
const RegistrationLandingPage = () => {
  const { themeLogin } = useThemeResponsive();
  const [searchParams] = useSearchParams();

  const isExistPartnerUser: boolean = Object.values(EPartner).includes(
    searchParams.get('partner')
  );

  const styles = getRegistrationStyles({
    theme: themeLogin,
    isExistPartnerUser: isExistPartnerUser,
  });
  return (
    <ThemeProvider theme={themeLogin}>
      <>
        <Box className="Auth-root" sx={styles.authRoot}>
          <Box className="Auth-backgroundImage" sx={styles.authBackgroundImage}>
            <Stack className="Auth-content" spacing={0} sx={styles.authContent}>
              <AuthIntroduction />

              <Box className="formWrapper" sx={styles.formWrapper}>
                <Box sx={{ height: '100%' }}>
                  <Box
                    className="formWrapperContent"
                    sx={styles.formWrapperContent}
                  >
                    <RegistrationPage />
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </>
    </ThemeProvider>
  );
};

export default RegistrationLandingPage;
