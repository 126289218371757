import { QueryParams } from '../commonMixed/QueryParams';

export class GetHomeFinance extends QueryParams {
  organizationId!: number;
  isIncludePrimaryTerminal!: boolean;
  revenueType!: string;
  loadStatusList!: string[];
  terminalIds!: string[];
  constructor() {
    super();
  }
}

export class FinanceDataRes {
  loadedMiles!: number;
  emptyMiles!: number;
  totalMiles!: number;
  emptyTotalMilesRatio!: number;
  driverCount!: number;
  totalRevenue!: number;
  avgRevenuePerDriver!: number;
  revenuePerLoadedMiles!: number;
  revenuePerTotalMiles!: number;
  targetWeeklyRevenue!: number;
  targetWeeklyRevenuePercent!: number;
  targetWeeklyRevenuePerDriver!: number;
  targetWeeklyRevenueTotalMile!: number;
  targetWeeklyRevenueTotalMileDiff!: number;
  targetWeeklyRevenueLoadedMile!: number;
  targetWeeklyRevenueTotalRevenueDiff!: number;
  targetRevenuePerLoadedMilePercent!: number;
}

export class GetSafetyDataDTO extends QueryParams {
  organizationId!: number;
  terminalIds!: string[];
}

export class CommonHomeAPIRes {
  count!: number;
  key!: string;
  service!: string;
  filters!: any;
  amount!: string;
}

export class GetMaps extends QueryParams {
  organizationId!: number;
  constructor() {
    super();
  }
}
