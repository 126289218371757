import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import {
  Divider,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';

interface IProps {
  goToNextPage?: () => void;
  goToPrevPage?: () => void;
  currPage?: number;
  totalPages?: number;
  onRotateLeft?: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  zoomLevel?: string;
  title?: string;
  hideZoom?: boolean;
  hideRotate?: boolean;
  navigateActionContainerStyles?: SxProps;
}

const IconStyles = (theme: any) => ({
  width: '18px',
  height: '18px',
  color: theme?.palette?.primary?.contrast,
});

const Header: React.FC<IProps> = ({
  title = '',
  goToPrevPage,
  goToNextPage,
  currPage,
  totalPages,
  onRotateLeft,
  onZoomOut,
  onZoomIn,
  zoomLevel,
  hideZoom,
  hideRotate,
  navigateActionContainerStyles,
}) => {
  return (
    <Stack
      zIndex={999}
      direction={'row'}
      alignItems={'center'}
      padding={'8px'}
      sx={(theme: Theme) => ({
        background: '#5b5b5b',
        borderRadius: '4px 4px 0 0',
      })}
      justifyContent={'space-between'}
    >
      <Typography
        sx={(theme: any) => ({
          color: theme?.palette?.primary?.contrast,
          width: '200px',
        })}
        variant="body2"
      >
        {title}
      </Typography>

      <Stack
        direction={'row'}
        alignItems={'center'}
        gap="6px"
        sx={navigateActionContainerStyles}
      >
        <Stack direction={'row'} alignItems={'center'} gap="8px">
          <Tooltip title="Previous Page">
            <IconButton onClick={goToPrevPage} disabled={currPage === 1}>
              <KeyboardArrowLeftIcon sx={IconStyles} />
            </IconButton>
          </Tooltip>
          <Typography
            sx={(theme: any) => ({
              color: theme?.palette?.primary?.contrast,
            })}
            variant="caption"
          >
            {currPage} / {totalPages}
          </Typography>
          <Tooltip title="Next Page">
            <IconButton
              onClick={goToNextPage}
              disabled={currPage === totalPages}
            >
              <KeyboardArrowRightIcon sx={IconStyles} />
            </IconButton>
          </Tooltip>
        </Stack>

        <Divider orientation="vertical" />

        {!hideZoom && (
          <>
            {' '}
            <Tooltip title="Zoom Out">
              <IconButton onClick={onZoomOut}>
                <RemoveIcon sx={IconStyles} />
              </IconButton>
            </Tooltip>
            <Typography
              variant="caption"
              sx={(theme: any) => ({
                color: theme?.palette?.primary?.contrast,
              })}
            >
              {zoomLevel}
            </Typography>
            <Tooltip title="Zoom In">
              <IconButton onClick={onZoomIn}>
                <AddIcon sx={IconStyles} />
              </IconButton>
            </Tooltip>
          </>
        )}

        {!hideRotate && (
          <>
            <Divider orientation="vertical" />

            <Tooltip title="Rotate left">
              <IconButton onClick={onRotateLeft}>
                <Rotate90DegreesCcwIcon sx={IconStyles} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export { Header };
