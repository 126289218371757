import Grid from '@mui/material/Grid';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRootStore } from '../../../../../../store/root-store/rootStateContext';
import FormDatePicker from '../../../../../../common/Ui/FormDatePicker/FormDatePicker';
import { t } from 'i18next';
export const MarkInvoiceForm = memo((): JSX.Element => {
  const { control } = useFormContext();
  const { getCompanyPreferences } = useRootStore();
  return (
    <Grid container spacing={1}>
      <FormDatePicker
        styleProps={{
          width: '100%',
        }}
        sizes={{
          xs: 12,
        }}
        disableFuture
        required
        control={control}
        label={t('invoiceDate')}
        name={`invoicedDate`}
        timezone={getCompanyPreferences?.timezone}
      />
    </Grid>
  );
});
MarkInvoiceForm.displayName = 'MarkInvoiceForm';
