import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import useFilterStorage from '../../../services/storage';
import { TractorsIcon, TrailersIcon } from '../../../ui-kit/components/Assets';
import { GridColDefSelf } from '../../../ui-kit/components/DataGridPro';
import { ERelativeDateRangeKey } from '../../../ui-kit/components/RelativeDateRange';
import { getCurrency, numberWithThousandSeparator } from '../../../utils';
import { loadStatuses } from '../../myLoads/components/LoadViewForms';
import { loadFilters } from '../../myLoads/components/ViewSection/constants';
import { defaultFilters } from '../../trips/components/TripDataGrid/grid.config';
import {
  TRIP_STATUS_LIST,
  tripFieldsConfig,
} from '../../trips/constants/constants';
import { BasicReportsContextType } from './BasicReportsContext';
import {
  doSearchCustomersName,
  doSearchDispatchersName,
  doSearchDriversName,
  doSearchTractorsName,
  doSearchTrailersName,
} from './utils';
export const getDateType = (selectedTab: string) => {
  return [
    {
      id: 1,
      name: selectedTab === 'customer' ? 'Pick-up Date' : 'Trip Start Date',
    },
    {
      id: 2,
      name: selectedTab === 'customer' ? 'Drop-off Date' : 'Trip End Date',
    },
  ];
};
export const getFilterDateId: {
  [key: string]: number;
} = {
  TRIP_START_DATE: 1,
  TRIP_END_DATE: 2,
};

export const filtersFormConfig = (selectedTab: string) => {
  let firstFilter = null;
  if (selectedTab === 'driver')
    firstFilter = {
      key: 1,
      name: 'driverIds',
      fieldName: 'driverName',
      getData: doSearchDriversName,
      label: 'Driver',
      default: true,
      type: 'MultipleAutocomplete',
    };
  else if (selectedTab === 'customer')
    firstFilter = {
      key: 1,
      name: 'customerIds',
      fieldName: 'name',
      getData: doSearchCustomersName,
      label: 'Customer',
      default: true,
      type: 'MultipleAutocomplete',
    };
  else if (selectedTab === 'tractor')
    firstFilter = {
      key: 1,
      name: 'tractorIds',
      fieldName: 'name',
      getData: doSearchTractorsName,
      label: 'Tractor',
      default: true,
      type: 'MultipleAutocomplete',
    };
  else if (selectedTab === 'trailer')
    firstFilter = {
      key: 1,
      name: 'trailerIds',
      fieldName: 'name',
      getData: doSearchTrailersName,
      label: 'Trailer',
      default: true,
      type: 'MultipleAutocomplete',
    };
  else if (selectedTab === 'dispatcher')
    firstFilter = {
      key: 1,
      name: 'dispatcherIds',
      fieldName: 'name',
      getData: doSearchDispatchersName,
      label: 'Dispatcher',
      default: true,
      type: 'MultipleAutocomplete',
    };

  const DATE_TYPE = getDateType(selectedTab);

  return [
    firstFilter,
    {
      key: 5,
      name: 'scheduleType',
      fieldValue: 'id',
      fieldName: 'name',
      options: SCHEDULE_TYPE,
      label: 'Schedule Type',
      default: true,
      defaultFilterValue: SCHEDULE_TYPE[0],
      disableClearable: true,
      type: 'ButtonGroup',
      multiple: false,
    },
    {
      key: 6,
      fieldName: 'name',
      fieldValue: 'id',
      name: 'dateType',
      options: DATE_TYPE,
      label: 'Date Type',
      type: 'ButtonGroup',
      multiple: false,
    },
    {
      key: 2,
      fieldName: 'name',
      name: 'period',
      label: 'Period',
      default: true,
      ...RelativeDateRangeUtils.getSettingsBasicReport(),
    },
  ].filter((el) => Boolean(el));
};

export const convertToUTC = (
  date = '',
  isEndDay = false,
  timezone = 'America/New_York'
) => {
  if (!date) return '';
  const momentDate = moment(date).tz(timezone).startOf('day').utc();
  if (isEndDay) {
    momentDate.millisecond(999);
  }
  return momentDate.toISOString();
};

export const numberFormat = (number: number, options = {}) =>
  new Intl.NumberFormat('en-US', options).format(number || 0);

const CurrencyCellRender = ({
  value,
  pinned,
}: {
  value: number;
  pinned: boolean;
}) => {
  if (isNaN(value)) return '';
  if (!pinned) return `$${numberWithThousandSeparator(value, false, 2)}`;
  return (
    <Typography
      variant="body2"
      sx={[
        {
          color: 'text.primary',
          fontSize: '14px',
          lineHeight: '21px',
          fontWeight: 700,
        },
      ]}
    >
      {`$${numberWithThousandSeparator(value, false, 2)}`}
    </Typography>
  );
};

const trailingColumns = (isTrip: boolean) => {
  return [
    {
      field: 'loadedMiles',
      headerName: isTrip ? 'Trip Miles' : 'Loaded Miles',
      width: 113,
      renderCell: ({ value, row: { pinned } }: { value: number }) => {
        return <NumberCellRender value={value} pinned={pinned} />;
      },
    },
    {
      headerName: 'Empty Miles',
      field: 'emptyMiles',
      width: 107,
      renderCell: ({ value, row: { pinned } }: { value: number }) => {
        return <NumberCellRender value={value} pinned={pinned} />;
      },
    },
    {
      headerName: isTrip
        ? 'Avg. Revenue/Trip Mile'
        : 'Avg. Revenue/Loaded Mile',
      field: 'revenueLoadedMile',
      width: 172,
      renderCell: ({ value }: { value: number }) => {
        return <PriceRender value={value} />;
      },
      nonInvoiced: true,
    },
    {
      headerName: 'Avg. Revenue/Total Mile',
      field: 'revenueTotalMile',
      width: 158,
      renderCell: ({ value }: { value: number }) => {
        return <PriceRender value={value} />;
      },
      nonInvoiced: true,
    },
    {
      headerName: isTrip ? 'Avg. Net/Trip Mile' : 'Avg. Net/Loaded Mile',
      field: 'netIncomeLoadedMile',
      width: 160,
      renderCell: ({ value }: { value: number }) => {
        return <PriceRender value={value} />;
      },
      nonInvoiced: true,
    },
    {
      headerName: 'Avg. Net/Total Mile',
      field: 'netIncomeTotalMile',
      width: 152,
      renderCell: ({ value }: { value: number }) => {
        return <PriceRender value={value} />;
      },
      nonInvoiced: true,
    },
  ];
};

export const PriceRender = ({
  value,
  pinned = false,
}: {
  value: number;
  pinned?: boolean;
}) => {
  const currency = getCurrency();
  return value ? (
    <Box
      sx={{
        textAlign: 'right',
        width: '100%',
        ...(pinned && {
          color: 'text.primary',
          fontSize: '14px',
          lineHeight: '21px',
          fontWeight: 700,
        }),
      }}
    >
      {numberFormat(value, { style: 'currency', currency })}
    </Box>
  ) : (
    ''
  );
};

export const NumberCellRender = ({
  value,
  pinned,
}: {
  value: number;
  pinned: boolean;
}) => {
  return (
    <Box
      sx={{
        textAlign: 'right',
        width: '100%',
        ...(pinned && {
          color: 'text.primary',
          fontSize: '14px',
          lineHeight: '21px',
          fontWeight: 700,
        }),
      }}
    >
      {numberFormat(value)}
    </Box>
  );
};

const frontColumns = (isTrip: boolean) => {
  return [
    {
      headerName: 'Total Revenue',
      field: 'grossRevenue',
      width: 123,
      renderCell: ({
        value,
        row: { pinned },
      }: {
        value: number;
        row: { pinned: boolean };
      }) => {
        return <CurrencyCellRender value={value} pinned={pinned} />;
      },
      nonInvoiced: true,
    },
    {
      headerName: isTrip ? 'Total Trip Expenses' : 'Total Load Expenses',
      field: 'totalExpense',
      width: 154,
      renderCell: ({
        value,
        row: { pinned },
      }: {
        value: number;
        row: { pinned: boolean };
      }) => {
        return <CurrencyCellRender value={value} pinned={pinned} />;
      },
      nonFinanced: true,
    },
    {
      headerName: 'Net Income',
      field: 'netIncome',
      width: 104,
      renderCell: ({
        value,
        row: { pinned },
      }: {
        value: number;
        row: { pinned: boolean };
      }) => {
        return <CurrencyCellRender value={value} pinned={pinned} />;
      },
      nonInvoiced: true,
    },
  ];
};

function HandleBasicReportRedirection(
  params: GridRenderCellParams,
  component: string
) {
  const {
    driverId,
    driverName,
    customerId,
    customerName,
    period,
    tractorId,
    tractorName,
    trailerId,
    trailerName,
    dispatcherId,
    dispatcherName,
  } = params.row;
  const areThereAnyCommas = (driverName || '').includes(',');
  const LStorageHook = useFilterStorage({ page: component });
  const LStorageHookBasicReport = useFilterStorage({ page: 'basicReports' });
  const storageData = LStorageHook.getStorage() || {};
  const storageDataBasicReport = LStorageHookBasicReport.getStorage() || {};
  const newPeriod = {
    ...RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
      ERelativeDateRangeKey.PeriodTimeCustom
    ),
    dateRange: period?.dateRange || [null, null],
  };
  if (driverId || customerId || tractorId || trailerId || dispatcherId) {
    const query = {};
    if (driverId || tractorId || trailerId || dispatcherId) {
      if (areThereAnyCommas && driverId) {
        query[tripFieldsConfig.driverOperationMode.fieldFilterName] = {
          label: 'Team',
          value: 'TEAM',
        };
      } else if (driverId) {
        query[tripFieldsConfig.driverOperationMode.fieldFilterName] = {
          label: 'Solo',
          value: 'SOLO',
        };
      }
      if (
        storageDataBasicReport.filters?.driver?.dateType.id ===
        getFilterDateId['TRIP_START_DATE']
      ) {
        query[tripFieldsConfig.tripStartDateRanges.fieldFilterName] = newPeriod;
      } else {
        query[tripFieldsConfig.tripEndDateRanges.fieldFilterName] = newPeriod;
      }
      query[tripFieldsConfig.tripStatus.fieldFilterName] = [
        {
          label: TRIP_STATUS_LIST.find(
            ({ value }) => value === ELoadStatus.LOAD_COMPLETED
          )?.label,
          value: ELoadStatus.LOAD_COMPLETED,
        },
        {
          label: TRIP_STATUS_LIST.find(
            ({ value }) => value === ELoadStatus.CANCELLED
          )?.label,
          value: ELoadStatus.CANCELLED,
        },
      ];
      const toSplitIds = driverId || tractorId || trailerId || dispatcherId;
      const toSplitName =
        driverName || tractorName || trailerName || dispatcherName;
      const Ids = toSplitIds
        .toString()
        .split(',')
        .map((x) => x.trim());
      const names = toSplitName.split(',').map((x) => x.trim());
      if (driverId || dispatcherId)
        query[
          driverId
            ? tripFieldsConfig.driver.fieldFilterName
            : tripFieldsConfig.dispatcher.fieldFilterName
        ] = Ids.map((item: string, index: number) => ({
          name: names[index],
          id: Number(item),
          nameToFilter: names[index],
          value: names[index],
        }));
      else
        query[
          tractorId
            ? tripFieldsConfig.tractor.fieldFilterName
            : tripFieldsConfig.trailer.fieldFilterName
        ] = Ids.map((item: string, index: number) => ({
          name: names[index],
          id: Number(item),
          nameToFilter: names[index],
          value: names[index],
        }));

      LStorageHook.updateStorage('stripId', 'allTrips');
      LStorageHook.updateStorage('allTrips', {
        ...storageData['allTrips'],
        filters: { ...defaultFilters, ...query },
      });
    } else if (customerId) {
      Object.assign(query, loadFilters);
      query.pickupDateRanges = newPeriod;
      query.loadStatusList = loadStatuses.filter(({ key }) =>
        [
          ELoadStatus.LOAD_COMPLETED,
          ELoadStatus.CANCELLED,
          ELoadStatus.INVOICED,
          ELoadStatus.PAID,
          ELoadStatus.READY_TO_INVOICE,
        ].includes(key)
      );
      query.customerNameFilter = [{ key: customerId, value: customerName }];
      LStorageHook.updateStorage('stripId', 'allLoads');
      LStorageHook.updateStorage('allLoads', {
        ...storageData['allTrips'],
        filters: query,
      });
    }
  } else if (!driverId) {
    const query = {};
    if (
      storageDataBasicReport.filters?.driver?.dateType.id ===
      getFilterDateId['TRIP_START_DATE']
    ) {
      query[tripFieldsConfig.tripStartDateRanges.fieldFilterName] = newPeriod;
    } else {
      query[tripFieldsConfig.tripEndDateRanges.fieldFilterName] = newPeriod;
    }
    query[tripFieldsConfig.tripStatus.fieldFilterName] = [
      {
        label: TRIP_STATUS_LIST.find(
          ({ value }) => value === ELoadStatus.LOAD_COMPLETED
        )?.label,
        value: ELoadStatus.LOAD_COMPLETED,
      },
      {
        label: TRIP_STATUS_LIST.find(
          ({ value }) => value === ELoadStatus.CANCELLED
        )?.label,
        value: ELoadStatus.CANCELLED,
      },
    ];
    query[tripFieldsConfig.driver.fieldFilterName] = [];
    query[tripFieldsConfig.dispatcher.fieldFilterName] = [];
    query[tripFieldsConfig.tractor.fieldFilterName] = [];
    query[tripFieldsConfig.trailer.fieldFilterName] = [];

    LStorageHook.updateStorage('stripId', 'allTrips');
    LStorageHook.updateStorage('allTrips', {
      ...storageData['allTrips'],
      filters: { ...defaultFilters, ...query },
    });
  }
}

const RenderCell = ({
  value,
  ...params
}: {
  value: number;
  params: GridRenderCellParams;
}) => {
  const { customerId } = params.row;
  let component = 'Trips';
  let url = '/loads/trips';

  if (customerId) {
    component = 'MyLoads';
    url = '/';
  }
  return (
    <Box
      onClick={() => {
        HandleBasicReportRedirection(params, component);
        window.location.href = url;
      }}
      sx={{
        color: 'primary.main',
        textAlign: 'right',
        width: '100%',
        ...(params?.row?.pinned && {
          fontSize: '14px',
          lineHeight: '21px',
          fontWeight: 700,
        }),
      }}
    >
      {numberFormat(value || 0)}
    </Box>
  );
};

const loadDriverPayments = {
  headerName: 'Trip Driver Payments',
  field: 'driverPayment',
  width: 164,
  renderCell: ({ value }: { value: number }) => {
    return <PriceRender value={value} />;
  },
  nonFinanced: true,
};

const loadDispatcherPayments = {
  headerName: 'Trip Dispatcher payments',
  field: 'dispatcherPayment',
  width: 164,
  renderCell: ({ value }: { value: number }) => {
    return <PriceRender value={value} />;
  },
  nonFinanced: true,
};

export const driverReportColumns: GridColDefSelf[] = [
  {
    field: 'driverName',
    headerName: 'Driver',
    width: 130,
    permanent: true,
    valueFormatter: ({ value }: { value: string | null | undefined }) => {
      return value
        ?.split(',')
        ?.map((it: string) => it.trim())
        ?.join(' & ');
    },
    flex: 1,
  },
  {
    field: 'loadCount',
    headerName: '# of Trips',
    width: 96,
    renderCell: RenderCell,
  },
  ...frontColumns(true),
  loadDriverPayments,
  ...trailingColumns(true),
];

export const customerReportColumns: GridColDefSelf[] = [
  {
    field: 'customerName',
    headerName: 'Customer',
    width: 130,
    permanent: true,
    flex: 1,
  },
  {
    field: 'loadCount',
    headerName: '# of Loads',
    width: 96,
    renderCell: RenderCell,
  },
  ...frontColumns(false),
  ...trailingColumns(false),
];

export const tractorReportColumns: GridColDefSelf[] = [
  {
    field: 'tractorName',
    headerName: 'Tractor',
    width: 130,
    permanent: true,
    flex: 1,
  },
  {
    field: 'loadCount',
    headerName: '# of Trips',
    width: 96,
    renderCell: RenderCell,
  },
  ...frontColumns(true),
  ...trailingColumns(true),
];

export const trailerReportColumns: GridColDefSelf[] = [
  {
    field: 'trailerName',
    headerName: 'Trailer',
    width: 130,
    permanent: true,
    flex: 1,
  },
  {
    field: 'loadCount',
    headerName: '# of Trips',
    width: 96,
    renderCell: RenderCell,
  },
  ...frontColumns(true),
  ...trailingColumns(true),
];

export const dispatcherReportColumns: GridColDefSelf[] = [
  {
    headerName: 'Dispatcher',
    field: 'dispatcherName',
    width: 130,
    permanent: true,
    flex: 1,
  },
  {
    field: 'loadCount',
    headerName: '# of Trips',
    width: 96,
    renderCell: RenderCell,
  },
  ...frontColumns(true),
  loadDispatcherPayments,
  ...trailingColumns(true),
];

const getObjectFromArray = (columnArray: any) => {
  const columnObject = {};
  columnArray.forEach((element: { field: string }) => {
    columnObject[element.field] = true;
  });
  return columnObject;
};

export const getDefaultStorageData = () => {
  const defaultFilter = {
    startDate: '',
    endDate: '',
    driverIds: [],
    sort: undefined,
    period:
      RelativeDateRangeUtils.getSettingsBasicReport().defaultOption || null,
    tractorIds: [],
    trailerIds: [],
    customerIds: [],
    dispatcherIds: [],
    scheduleType: SCHEDULE_TYPE[0],
    dateType: getDateType('driver')[1],
  };
  const defaultFilterWithoutTrip = {
    ...defaultFilter,
    dateType: getDateType('customer')[1],
  };

  return {
    selectedTab: 'driver',
    columns: {
      driver: getObjectFromArray(driverReportColumns),
      customer: getObjectFromArray(customerReportColumns),
      tractor: getObjectFromArray(tractorReportColumns),
      trailer: getObjectFromArray(trailerReportColumns),
      dispatcher: getObjectFromArray(dispatcherReportColumns),
    },
    filters: {
      driver: defaultFilter,
      customer: defaultFilterWithoutTrip,
      tractor: defaultFilter,
      trailer: defaultFilter,
      dispatcher: defaultFilter,
    },
  };
};

export const reportTabs = [
  {
    label: 'Driver',
    key: 'driver',
    iconName: 'PersonOutlined',
    source: 'MUIcons',
    columns: driverReportColumns,
  },
  {
    label: 'Customer',
    key: 'customer',
    iconName: 'GroupOutlined',
    source: 'MUIcons',
    columns: customerReportColumns,
  },
  {
    label: 'Tractor',
    key: 'tractor',
    icon: <TractorsIcon height={20} width={20} />,
    columns: tractorReportColumns,
  },
  {
    label: 'Trailer',
    key: 'trailer',
    icon: <TrailersIcon height={20} width={20} />,
    columns: trailerReportColumns,
  },
  {
    label: 'Dispatcher',
    key: 'dispatcher',
    iconName: 'SupervisedUserCircleOutlined',
    source: 'MUIcons',
    columns: dispatcherReportColumns,
  },
];

export const getStorageData = (storageHook: BasicReportsContextType) => {
  const defaultStorageData = getDefaultStorageData();

  return {
    selectedTab: storageHook.selectedTab || defaultStorageData.selectedTab,
    columns: {
      driver:
        storageHook.columns && storageHook.columns.driver
          ? storageHook.columns.driver
          : defaultStorageData.columns.driver,
      customer:
        storageHook.columns && storageHook.columns.customer
          ? storageHook.columns.customer
          : defaultStorageData.columns.customer,
      tractor:
        storageHook.columns && storageHook.columns.tractor
          ? storageHook.columns.tractor
          : defaultStorageData.columns.tractor,
      trailer:
        storageHook.columns && storageHook.columns.trailer
          ? storageHook.columns.trailer
          : defaultStorageData.columns.trailer,
      dispatcher:
        storageHook.columns && storageHook.columns.dispatcher
          ? storageHook.columns.dispatcher
          : defaultStorageData.columns.dispatcher,
    },
    filters: {
      driver:
        storageHook.filters && storageHook.filters.driver
          ? storageHook.filters.driver
          : defaultStorageData.filters.driver,
      customer:
        storageHook.filters && storageHook.filters.customer
          ? storageHook.filters.customer
          : defaultStorageData.filters.customer,
      tractor:
        storageHook.filters && storageHook.filters.tractor
          ? storageHook.filters.tractor
          : defaultStorageData.filters.tractor,
      trailer:
        storageHook.filters && storageHook.filters.trailer
          ? storageHook.filters.trailer
          : defaultStorageData.filters.trailer,
      dispatcher:
        storageHook.filters && storageHook.filters.dispatcher
          ? storageHook.filters.dispatcher
          : defaultStorageData.filters.dispatcher,
      period:
        storageHook.filters && storageHook.filters?.period
          ? storageHook.filters?.period
          : defaultStorageData.filters?.period,
    },
  };
};

export const SCHEDULE_TYPE = [
  {
    id: 1,
    name: 'Appointment',
  },
  {
    id: 2,
    name: 'Actual',
  },
];
