import { Box, IconButton, Stack, Theme, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import RecommendationButton from './RecommendationButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Rating from '@mui/material/Rating';
import { useStores } from '../../../store/root.context';
import { observer } from 'mobx-react';
import {
  DriverNameStyles,
  RatingContainerStyles,
  darkTextStyles,
  lightTextStyles,
} from '../styles/evaluation.style';
import { RatingPropsType } from '../types/evaluation.type';
import {
  OPTIMIZATION_SOURCES,
  RESOURCES_TYPE,
} from '../../../constants/gantt/gantt.const';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../common/Permission';
import { LOADAI_ELEMENTS } from '../../../constants/elementIds';

const RatingSection: React.FC<RatingPropsType> = (props) => {
  const {
    evaluationStore: { stopEvaluation, source, resourceType },
    bulkOptymizationStore: { setOptimizationSource },
    dispatch2GlobalStore: { startBulkOptymization },
  } = useStores();

  const handleRecommendation = () => {
    setOptimizationSource(OPTIMIZATION_SOURCES.EVALUATION);
    startBulkOptymization();
  };

  const renderRating = useMemo(() => {
    if (!isNaN(props?.rating!) && props?.rating! % 1 === 0)
      return Number(props?.rating)?.toFixed?.(1);
    return props?.rating;
  }, [props?.rating]);

  return (
    <Box>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography sx={DriverNameStyles}>{props?.driverName}</Typography>

        <Box display={'flex'} gap="4px" alignItems={'center'}>
          {source !== 'OPTIMIZATION' && props?.driverName && (
            <Permission contains={[AXELE_PERMISSION_TYPE.OPTIMIZATION_EDIT]}>
              <RecommendationButton
                id={
                  resourceType === RESOURCES_TYPE.DRIVER
                    ? LOADAI_ELEMENTS.EVALUATION_RECOMMEND_TRIPS
                    : LOADAI_ELEMENTS.EVALATION_RECOMMEND_DRIVERS
                }
                onClick={handleRecommendation}
                label={
                  resourceType === RESOURCES_TYPE.DRIVER
                    ? 'Recommend Trips'
                    : 'Recommend Drivers'
                }
              />
            </Permission>
          )}
          <IconButton
            sx={{ width: '24px', height: '24px' }}
            onClick={stopEvaluation}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      </Stack>

      {props?.rating! >= 0 && !props?.issuesKpiLength && (
        <Stack
          direction="row"
          gap="4px"
          alignItems={'center'}
          sx={RatingContainerStyles}
        >
          <Typography fontSize={10} sx={darkTextStyles}>
            Match Rating:{' '}
          </Typography>
          <Typography fontSize={12} sx={lightTextStyles}>
            {renderRating}
          </Typography>

          <Rating
            name="resource-rating"
            defaultValue={props?.rating ?? 0}
            precision={0.25}
            size={'small'}
            readOnly={true}
            sx={(theme: Theme) => ({
              '.MuiRating-icon': { color: theme?.palette?.text?.secondary },
            })}
          />
        </Stack>
      )}
    </Box>
  );
};

export default observer(RatingSection);
