import { useTheme } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { loadsearchService } from '../../../../api';
import DetailsPanel from '../../../../common/DetailsPanel';
import { MarkersData } from '../../../../common/Ui/Maps/types';
import { NumberToCurrency } from '../../../../constants/numberToCurrency';
import { LoadsearchMapDataRequest } from '../../../../models/DTOs/Loadsearch/Request';
import { ESecondaryDetailsPanelType } from '../../../dispatch2/constants/types';
import { ELoadboardsPanelTab } from '../../constants/detailsPanel';
import { TTypeOfBook } from '../../loadTypes';
import LoadboardMap from '../LoadboardMap/LoadboardMap';
import { ProfitCalculatorPopover } from '../ProfitCalculator/ProfitCalculatorPopover';
import DetailsTabStrips, { tabStripRendererByKeyFS } from './DetailsTabStrips';
import {
  LoadBoardsDetailsForm,
  LoadBoardsDetailsFormProps,
} from './LoadBoardsDetailsForm';

interface CommonPanelProps {
  onClose?: any;
  isDarkMode?: boolean;
  hasLoadAttributes?: boolean;
  closeLoadAttributePopup?: any;
  openLoadAttributePopup?: any;
  isLoadAttributePopupOpen?: boolean;
  expanded?: boolean;
  defaultTab?: ELoadboardsPanelTab;
}

export interface InvocationReqIdMetaDataProps {
  [id: string]: string | null | undefined;
}
interface DetailPanelProps extends CommonPanelProps {
  selectedLoad: any;
  selectedRowData: any;
  selectedDriverDetails: any;
  isDriverSelected: boolean;
  onBookHandler: (event: any, params: any, typeOfBook?: TTypeOfBook) => void;
  pendingLoadBookConfirmationStatus: any;
  actionBidBook: any;
  profitCalculatorUpdatedValue?: any;
  profitabilityTabRender?: number;
  invocationReqIdMetaData: InvocationReqIdMetaDataProps;
  setInvocationReqIdMetaData: React.Dispatch<
    React.SetStateAction<InvocationReqIdMetaDataProps>
  >;
}

export const DetailPanel = ({
  selectedLoad,
  selectedRowData,
  onClose,
  onBookHandler,
  hasLoadAttributes,
  openLoadAttributePopup: _openLoadAttributePopup,
  closeLoadAttributePopup,
  isLoadAttributePopupOpen,
  selectedDriverDetails,
  isDriverSelected,
  profitCalculatorUpdatedValue,
  profitabilityTabRender,
  invocationReqIdMetaData,
  setInvocationReqIdMetaData,
  defaultTab = ELoadboardsPanelTab.TRIP_PLAN,
  expanded = false,
  pendingLoadBookConfirmationStatus,
  actionBidBook,
}: DetailPanelProps) => {
  //delare state
  const [profitabilityData, setProfitabilityData] = useState({});
  const [pickupDropLocation, setPickupDropLocation] = useState('');
  const [detailTabData, setDetailTabData] = useState({});
  const [isMapPopupOpen, setIsMapPopupOpen] = useState<boolean>(false);
  const [loadboardMapData, setLoadboardMapData] = useState<MarkersData>();

  //delare state
  const initial = (): void => {
    const profitabilityItem: any = {
      offer: selectedRowData?.Offer?.value,
      estimatedProfit: selectedRowData?.Offer?.estimatedProfit,
      fixedCost: selectedRowData?.Offer?.fixedCost,
      variableCost: selectedRowData?.Offer?.variableCost,
    };
    const detailData: any = {
      mcNumber: selectedRowData?.Broker?.MC,
      creditRating: selectedRowData?.Broker?.creditRating
        ? selectedRowData?.Broker?.creditRating
        : '-',
      email: selectedRowData?.Broker?.email
        ? selectedRowData?.Broker?.email
        : '-',
      name: selectedRowData?.Broker?.value,
      reloadScore: selectedRowData?.ReloadScore?.value,
      weight: selectedRowData?.Equipment?.subvalue,
      equipmentType: selectedRowData?.Equipment?.value,
      customerPreference:
        selectedRowData?.actions?.savedLoadsData?.customerPreference,
      loadType: selectedRowData?.actions?.savedLoadsData?.loadType,
      length: selectedRowData?.Equipment?.length,
      currentScore: selectedRowData?.actions?.savedLoadsData?.currentScore,
      daysToPay: selectedRowData?.actions?.savedLoadsData?.daysToPay,
      comments: selectedRowData?.actions?.savedLoadsData?.comments,
      phoneNumber: selectedRowData?.phoneNumber,
    };
    const pickupLocation = selectedRowData?.Pickup?.city;
    const dropLocation = selectedRowData?.Dropoff?.city;
    const offerPrice = selectedRowData?.Offer?.value;
    const brokerName = selectedRowData?.Broker?.value;

    setPickupDropLocation(`${NumberToCurrency(offerPrice)} | ${brokerName}`);
    setProfitabilityData(profitabilityItem);
    setDetailTabData(detailData);
  };

  useEffect(() => {
    if (selectedLoad && selectedLoad?.id) {
      initial();
    }
  }, [profitabilityTabRender, selectedLoad?.id]);

  useEffect(() => {
    if (isMapPopupOpen && Object.keys(invocationReqIdMetaData).length > 0) {
      getLoadboardMapData();
    }
  }, [selectedLoad?.id, invocationReqIdMetaData, isMapPopupOpen]);

  const [selectedTab, setSelectedTab] =
    useState<ELoadboardsPanelTab>(defaultTab);

  const handleTabStripChange = (selected: ELoadboardsPanelTab): void => {
    setSelectedTab(selected);
  };

  const theme: any = useTheme();
  const panelWrapperCustomStyles = {
    display: 'flex',
    width: '33.3%',
    padding: '0 5px 5px 0',
    height: '100%',
    flexDirection: 'column',
    background: theme.palette?.leftMenuCollapsed?.canvas,
    boxShadow: 'unset',
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const onPopoverClose = useCallback(() => {
    setAnchorEl(null);
    closeLoadAttributePopup();
  }, [closeLoadAttributePopup]);

  const openLoadAttributePopup = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      _openLoadAttributePopup(event);
    },
    [_openLoadAttributePopup]
  );

  const AttributePopupContent = () => (
    <ProfitCalculatorPopover
      open={!!isLoadAttributePopupOpen}
      anchorEl={anchorEl}
      onClose={onPopoverClose}
      selectedRowData={selectedRowData}
      closeLoadAttributePopup={closeLoadAttributePopup}
      profitCalculatorUpdatedValue={profitCalculatorUpdatedValue}
    />
  );

  const tabStripsOptions = {
    hasLoadAttributes,
    openLoadAttributePopup,
    closeLoadAttributePopup,
    isLoadAttributePopupOpen,
    openMapPopup: () => setIsMapPopupOpen(true),
  };

  const tabStripRenderer = (keys: ELoadboardsPanelTab[]) => (
    <DetailsTabStrips
      defaultTab={defaultTab}
      onChange={handleTabStripChange}
      keys={keys}
      options={tabStripsOptions}
    />
  );
  const isEdit = !!selectedLoad.id;
  const onFullscreen = (isFullscreen: boolean): void => {
    //set default tab when fullscreen
    setSelectedTab(
      isFullscreen && isEdit ? ELoadboardsPanelTab.DETAILS : defaultTab
    );
  };
  const entity = 'Loadboards';
  const panelTitle = pickupDropLocation;
  const onUpdated = () => {
    //
  };
  const onDeleted = () => {
    //
  };
  const onCreated = () => {
    //
  };

  const detailsFormProps: Omit<LoadBoardsDetailsFormProps, 'selectedTab'> = {
    tripPlanProps: {
      selectedLoad,
      invocationReqIdMetaData,
      setInvocationReqIdMetaData,
      onBookHandler,
      pendingLoadBookConfirmationStatus,
      actionBidBook,
    },
    profitabilityProps: {
      data: profitabilityData,
      selectedDriverDetails,
      selectedRowData,
      isDriverSelected,
      onBookHandler,
      pendingLoadBookConfirmationStatus,
      actionBidBook,
    },
    detailsTabProps: {
      data: detailTabData,
      onBookHandler,
      pendingLoadBookConfirmationStatus,
      actionBidBook,
    },
  };

  const getLoadboardMapData = async () => {
    const payload = new LoadsearchMapDataRequest(true);
    (payload.driverGroupId =
      selectedDriverDetails?.value?.value.driverGroupId || null),
      (payload.invocationRequestId = invocationReqIdMetaData[selectedLoad?.id]),
      (payload.nextLoadId =
        selectedDriverDetails?.value?.subValue?.nextLoadId || null),
      (payload.previousLoadId =
        selectedDriverDetails?.value?.subValue?.previousLoadId || null),
      (payload.sandboxLoadDTO = { ...selectedLoad });
    if (payload.invocationRequestId) {
      const mapData = await loadsearchService.getloadboardMapData(payload);
      setLoadboardMapData(mapData as unknown as MarkersData);
    }
  };

  return (
    <>
      {isMapPopupOpen && (
        <LoadboardMap
          open={isMapPopupOpen}
          close={() => setIsMapPopupOpen(false)}
          mapData={loadboardMapData}
        />
      )}
      <DetailsPanel
        panelType={ESecondaryDetailsPanelType.LOAD_BOARD}
        data={selectedLoad}
        showDeleteButton={false}
        actionButtonLabel={selectedLoad.id ? undefined : 'Add Lo'}
        entity={entity}
        panelTitle={panelTitle}
        onClose={onClose}
        onUpdate={onUpdated}
        onDelete={onDeleted}
        onCreate={onCreated}
        contentRenderer={() => (
          // <ContentComponentByTab selectedTab={selectedTab} />
          <LoadBoardsDetailsForm
            selectedTab={selectedTab}
            {...detailsFormProps}
          />
        )}
        tabStripRenderer={() =>
          tabStripRenderer(Object.values(ELoadboardsPanelTab))
        }
        // httpErrors={httpErrors}
        footerRenderer={() => <></>}
        onFullscreen={onFullscreen}
        autoExpanded={expanded}
        {...(isEdit && {
          fullscreen: {
            panelPropList: [
              {
                data: selectedLoad,
                entity: entity,
                panelId: ELoadboardsPanelTab.TRIP_PLAN,
                panelTitle: panelTitle,
                onUpdate: () => {
                  //
                },
                contentRenderer: () => (
                  <LoadBoardsDetailsForm
                    selectedTab={ELoadboardsPanelTab.TRIP_PLAN}
                    {...detailsFormProps}
                  />
                ),
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: '18px 0 0 18px', //override border radius
                },
                tabStripRenderer: () =>
                  tabStripRendererByKeyFS(ELoadboardsPanelTab.TRIP_PLAN),
              },
              {
                data: selectedLoad,
                entity: entity,
                panelId: ELoadboardsPanelTab.PROFITABILITY,

                onUpdate: () => {
                  //
                },
                contentRenderer: () => (
                  <LoadBoardsDetailsForm
                    selectedTab={ELoadboardsPanelTab.PROFITABILITY}
                    {...detailsFormProps}
                  />
                ),
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: '0', //override border radius
                },
                panelTitle: '',
                tabStripRenderer: () =>
                  tabStripRendererByKeyFS(ELoadboardsPanelTab.PROFITABILITY),
              },
              {
                data: selectedLoad,
                entity: entity,
                panelId: 'CurrentContentForm',
                onUpdate: () => {
                  //
                },
                contentRenderer: () => (
                  <LoadBoardsDetailsForm
                    selectedTab={selectedTab}
                    {...detailsFormProps}
                  />
                ),
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: '0 18px 18px 0', //override border radius
                },
                panelTitle: '',
                tabStripRenderer: () => {
                  return tabStripRenderer([ELoadboardsPanelTab.DETAILS]);
                },
              },
            ],
          },
        })}
      />
      <AttributePopupContent />
    </>
  );
};
