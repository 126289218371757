import React, { FC } from 'react';
import { TabProps } from '@mui/material/Tab';
import StyledTab from './StyledTab';

const StyledExtraTab: FC<
  TabProps & {
    isDarkMode?: boolean;
  }
> = ({ isDarkMode, sx, ...props }) => {
  return (
    <StyledTab
      sx={{
        backgroundColor: 'transparent',
        minWidth: 28,
        borderBottom: 0,
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        ...sx,
      }}
      isDarkMode={isDarkMode}
      {...props}
    />
  );
};

export default StyledExtraTab;
