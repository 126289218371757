import React, { useState } from 'react';
import {
  DeleteButtonIcon,
  ImageIcon,
  BackIcon,
  NextIcon,
} from '../../../../ui-kit/components/Assets';
import { Box, IconButton, Typography } from '@mui/material';
import {
  main,
  container,
  fileContainer,
  fileViewer,
  fileNameContainer,
  fileName,
  iconColor,
  imageIconColor,
} from './style';
import { shortFileName } from '../../../../utils/shortFileName';
import Tooltip from '@mui/material/Tooltip';
import FileViewer from '../components/FileViewer';

/* @TODO this file should be rewritten */

/* IMPORTANT NOTE! Nvard - AXL2-4132: this component is used in Create Load panel.
In case of any changes/refactorings (which is really needed) check functionality in Create Load also (for example: deleting functionality, file name display */
interface IProps {
  viewFile: any;
  showFile?: boolean;
  isDarkBackground?: boolean;
  showFileName?: boolean;
  styles?: {};
  scale?: number;
  setFiles: (restFiles: Array<File>, index: number) => void;
}

function Albom({
  setFiles,
  viewFile = [],
  showFileName = true,
  scale,
  styles: stylesProps,
}: IProps) {
  const [index, setIndex] = useState<number>(0);
  const onDelete = (id: string, index: number) => {
    const restFiles: Array<File> = [];
    viewFile.forEach((file: any) => {
      if (file.id !== id) {
        restFiles.push(file);
      }
    });
    setFiles(restFiles, index);
  };

  const handleBack = () => {
    if (index === 0) {
      setIndex(viewFile.length - 1);
      return;
    }
    setIndex(index - 1);
  };

  const handleNext = () => {
    if (index === viewFile.length - 1) {
      setIndex(0);
      return;
    }
    setIndex(index + 1);
  };
  const renderFileName = (fileName: string) => {
    const [isShort, shortTitle] = shortFileName(fileName || '');

    return (
      <>
        {isShort ? (
          <Tooltip title={fileName}>
            <Typography>{shortTitle}</Typography>
          </Tooltip>
        ) : (
          <Typography>{fileName}</Typography>
        )}
      </>
    );
  };

  return (
    <Box sx={{ ...main, ...stylesProps?.main }}>
      <Box sx={{ ...container, ...stylesProps?.container }}>
        {viewFile && viewFile.length && (
          <Box sx={fileContainer}>
            {viewFile.length > 1 && (
              <IconButton aria-label="back" onClick={handleBack}>
                <BackIcon color={iconColor} />
              </IconButton>
            )}
            <FileViewer
              file={viewFile[index]}
              scale={scale}
              styles={stylesProps?.fileViewerStyles}
            />
            {viewFile.length > 1 && (
              <IconButton aria-label="next" onClick={handleNext}>
                <NextIcon color={iconColor} />
              </IconButton>
            )}
          </Box>
        )}
        {!viewFile && (
          <Box sx={{ ...fileViewer, ...stylesProps?.fileViewer }}>
            <FileViewer file={null} />
          </Box>
        )}
        <Box>
          {viewFile &&
            viewFile.map((eachFile: any, index: number) => {
              return (
                <Box key={eachFile.id} sx={fileNameContainer}>
                  {showFileName && (
                    <>
                      <ImageIcon color={imageIconColor} />
                      <Box sx={fileName}>
                        {renderFileName(eachFile.fileName)}
                      </Box>
                    </>
                  )}
                  {viewFile.length > 1 && (
                    <IconButton
                      style={{ padding: 0 }}
                      aria-label="close"
                      onClick={() => onDelete(eachFile.id, index)}
                    >
                      <DeleteButtonIcon color={imageIconColor} />
                    </IconButton>
                  )}
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
}

export default Albom;
