import { AirlineSeatIndividualSuiteOutlined } from '@mui/icons-material';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import WatchIcon from '@mui/icons-material/Watch';
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import {
  IconButton,
  styled,
  SvgIcon,
  SvgIconProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
} from '@mui/material';
import React from 'react';
import {
  DriverHOSEventView,
  ReSequenceStopIcon,
  Tractor02V2,
  TripPlanV2TimelineArrowUp,
  TripPlanV2TimelineDriverRM,
  TripPlanV2TimelineOilChange,
} from '../icons/loadSearch';
import { DriverActualMiles, Driver } from '../../components/Assets';
import IconsComponent from '../../components/IconsComponent';

interface MuiIconProps extends SvgIconProps {
  isActive?: boolean;
}
export const TimeLineIconMapping = {
  //dynamic icon
  PickupOrDropOff: ({ isActive, ...props }: MuiIconProps) => {
    if (isActive) {
      return (
        <CheckCircleIcon
          fontSize="medium"
          color={isActive ? 'primary' : 'disabled'}
          {...props}
        />
      );
    } else {
      return (
        <TripOriginOutlinedIcon
          fontSize="medium"
          color={isActive ? 'primary' : 'disabled'}
          {...props}
        />
      );
    }
  },
  Relay: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <SwapHorizOutlinedIcon
        fontSize="medium"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  PickupTripStop: ({ isActive, ...props }: MuiIconProps) => {
    if (isActive) {
      return (
        <FiberManualRecordIcon
          fontSize="medium"
          color={isActive ? 'primary' : 'disabled'}
          {...props}
        />
      );
    } else {
      return (
        <FiberManualRecordOutlinedIcon
          fontSize="medium"
          color={isActive ? 'primary' : 'disabled'}
          {...props}
        />
      );
    }
  },
  StickyNote: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <StickyNote2OutlinedIcon
        fontSize="medium"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  Break: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <FreeBreakfastOutlinedIcon
        fontSize="medium"
        color={isActive ? 'primary' : ('text.primary' as any)}
        {...props}
      />
    );
  },
  PreTripInspection: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <FactCheckOutlinedIcon
        fontSize="small"
        color={isActive ? 'primary' : ('text.primary' as any)}
        {...props}
      />
    );
  },
  AppointmentWork: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <CalendarTodayOutlinedIcon
        fontSize="small"
        color={isActive ? 'primary' : ('text.primary' as any)}
        {...props}
      />
    );
  },
  PickupTripStopRest: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <AirlineSeatIndividualSuiteOutlined
        fontSize="medium"
        color={isActive ? 'primary' : ('text.primary' as any)}
        {...props}
      />
    );
  },
  WorkOffOutlinedIcon: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <WorkOffOutlinedIcon
        fontSize="small"
        color={isActive ? 'primary' : ('text.primary' as any)}
        {...props}
      />
    );
  },

  //hardcode icon
  DriverHOSEventView: ({ color, ...props }: MuiIconProps) => {
    return (
      <SvgIcon
        sx={{ width: '20px', height: '20px' }}
        children={DriverHOSEventView({ color: color })}
        inheritViewBox
      />
    );
  },
  DriverAssist: (
    <SvgIcon
      sx={{ width: '25px', height: '25px' }}
      children={TripPlanV2TimelineDriverRM}
      inheritViewBox
    />
  ),
  ArrivalTime: (
    <IconsComponent
      source="AxeleIcon"
      iconName="ArrivalTime"
      styleProps={{ width: '18px', height: '25px' }}
    />
  ),
  WaitingTime: (
    <IconsComponent
      source="AxeleIcon"
      iconName="WaitingTime"
      styleProps={{ width: '16px', height: '25px' }}
    />
  ),
  TripPlanV2TimelineArrowUp: (
    <SvgIcon
      sx={{ width: '18px', height: '18px' }}
      children={TripPlanV2TimelineArrowUp}
      inheritViewBox
    />
  ),
  DepartureTime: (
    <IconsComponent
      source="AxeleIcon"
      iconName="DepartureTime"
      styleProps={{ width: '17px', height: '25px', paddingTop: '4px' }}
    />
  ),
  PickupTripStopOilChange: (
    <SvgIcon
      sx={{ width: '25px', height: '25px' }}
      children={TripPlanV2TimelineOilChange}
      inheritViewBox
    />
  ),

  //V3
  DriverActualMiles: (
    <SvgIcon
      sx={{ width: '20px', height: '20px' }}
      children={DriverActualMiles}
      inheritViewBox
    />
  ),
  Driver: (
    <SvgIcon
      sx={{ width: '20px', height: '20px' }}
      children={Driver}
      inheritViewBox
    />
  ),
  Timelapse: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <TimelapseOutlinedIcon
        fontSize="medium"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  BeenhereIcon: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <BeenhereIcon
        fontSize="medium"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  WatchIcon: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <WatchIcon
        fontSize="medium"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  DoubleArrowIcon: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <DoubleArrowIcon
        fontSize="medium"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  StickyNote2Icon: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <StickyNote2Icon
        fontSize="small"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  PinOutlinedIcon: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <PinOutlinedIcon
        fontSize="small"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  ContactsOutlinedIcon: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <ContactsOutlinedIcon
        fontSize="small"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  Tractor_02V2: ({
    isActive,
    toolTip,
  }: {
    isActive: boolean;
    toolTip: NonNullable<React.ReactNode>;
  }) => {
    const theme: any = useTheme();
    const TractorTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }: { theme: any }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        background: theme.palette.uncategorized.tooltip,
        padding: '10px',
      },
    }));
    return (
      <TractorTooltip title={toolTip} arrow placement="right">
        <IconButton
          disableRipple={true}
          disableFocusRipple={true}
          size={'small'}
          color="primary"
          sx={{
            marginLeft: '-5px',
            ...(isActive
              ? {
                  backgroundColor: theme.palette.primary.main,
                }
              : {}),
          }}
        >
          <SvgIcon
            sx={{ width: '18px', height: '18px' }}
            children={Tractor02V2({
              color: isActive
                ? theme.palette.primary.contrast
                : theme.palette.action.disabled,
            })}
            inheritViewBox
          />
        </IconButton>
      </TractorTooltip>
    );
  },
  HighlightOffOutlinedIcon: ({ isActive, ...props }: MuiIconProps) => {
    return (
      <HighlightOffOutlinedIcon
        fontSize="medium"
        color={isActive ? 'primary' : 'disabled'}
        {...props}
      />
    );
  },
  ReSequenceStopIcon: ({ sx }: any) => {
    return (
      <SvgIcon
        sx={{ width: '20px', height: '20px', ...sx }}
        children={ReSequenceStopIcon({})}
        inheritViewBox
      />
    );
  },
  //V3
};
