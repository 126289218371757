import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import {
  GridCellParams,
  GridColumnHeaderParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import { EllipsisText } from '../../../../common/Wrappers/EllipsisText';
import {
  IMPORT_CUSTOMER_NAME,
  IMPORT_DRIVER_NAME,
  IMPORT_LOCATION_NAME,
  IMPORT_TRACTOR_NAME,
  IMPORT_TRAILER_NAME,
  IMPORT_VENDOR_NAME,
} from '../../../../contexts/ImportContext';
import { GridColDefSelf } from '../../../../types';
import { InfoIcon } from '../../../../ui-kit/components/Assets';
import ImportActions from '../Import';
import { renderAddressEditInputCell } from '../components/Common/Address';
import { renderClearableSelectEditInputCell } from '../components/Common/ClearableSelect';
import { dateMaps } from '../components/Step1/constants';

export const mainColor = '#2B64CB';
export const fontFamily = 'Poppins';
export const body2Color = 'primary.main';
export const helperLinkColor = '#114199';

export const typographyStyles = {
  color: mainColor,
  fontFamily,
};

/*-------------------------------------------------------NOTE----------------------------------------------------------*/
/*                     Bellow this comment all functions are very important. Never modify them with special approve. @davits          */

const renderCellName = (params: GridCellParams) => {
  // If we have both errors and warnings we show errors
  // we are keeping errors with id key and fieldName key. That's why we are getting it by id and field
  // with id we get specific row with second part of condition we are getting specific cell
  if (
    params.row.errors &&
    params.row.errors[params.row.id] &&
    params.row.errors[params.row.id][params.field]
  ) {
    return 'error';
  }
  if (
    params.row.warnings &&
    params.row.warnings[params.row.id] &&
    params.row.warnings[params.row.id][params.field]
  ) {
    return 'warning';
  }
  return '';
};

const renderCell = (params: GridCellParams) => {
  let tooltipText = '';
  // we are keeping warnings with id key and fieldName key. That's why we are getting it by id and field
  // with id we get specific row with second part of condition we are getting specific cell
  if (params.row.warnings && params.row.warnings[params.row.id][params.field]) {
    tooltipText = params.row.warnings[params.row.id][params.field];
  }
  // we are keeping errors with id key and fieldName key. That's why we are getting it by id and field
  // with id we get specific row with second part of condition we are getting specific cell
  if (params.row.errors && params.row.errors[params.row.id][params.field]) {
    tooltipText = params.row.errors[params.row.id][params.field];
  }
  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} arrow>
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {params.row[params.field]}
        </Box>
      </Tooltip>
    );
  }
  return <EllipsisText>{params.row[params.field]}</EllipsisText>;
};

const renderHeader = (params: GridColumnHeaderParams, title: string) => {
  return (
    <>
      {params.colDef.headerName}
      <Tooltip title={title} placement={'top'} arrow>
        <span>
          <InfoIcon opacity={'0.6'} />
        </span>
      </Tooltip>
    </>
  );
};

// possible default types 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions';

export const customerColumns: GridColDefSelf[] = [
  {
    field: 'customerType',
    headerName: 'Customer Type',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.customerStaticData.customerType || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'customerName',
    headerName: 'Customer Name',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field. It should be Unique'),
  },
  {
    field: 'mc',
    headerName: 'MC',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 8 characters'),
  },
  {
    field: 'customerPreferenceCode',
    headerName: 'Preferred/Prohibited',
    minWidth: 200,
    editable: true,
    flex: 1,
    sortable: false,
    renderEditCell: (params) =>
      renderClearableSelectEditInputCell(params, ['Preferred', 'Prohibited']),
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'address',
    headerName: 'Matching Address',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    renderEditCell: renderAddressEditInputCell,
    valueSetter: (params: GridValueSetterParams) => {
      return { ...params.row, address: params.row.address };
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  {
    field: 'streetAddress',
    headerName: 'Street Address',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  // {
  //   field: 'state',
  //   headerName: 'State',
  //   minWidth: 160,
  //   editable: false,
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params: GridCellParams) => renderCellName(params),
  //   renderCell: (params: GridCellParams) => renderCell(params),
  //   renderHeader: (params) => {
  //     return (
  //       <>
  //         {params.field}
  //         <InfoIcon opacity={'0.6'} />
  //       </>
  //     );
  //   },
  // },
  // {
  //   field: 'city',
  //   headerName: 'City',
  //   minWidth: 160,
  //   editable: false,
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params: GridCellParams) => renderCellName(params),
  //   renderCell: (params: GridCellParams) => renderCell(params),
  //   renderHeader: (params) => {
  //     return (
  //       <>
  //         {params.field}
  //         <InfoIcon opacity={'0.6'} />
  //       </>
  //     );
  //   },
  // },
  // {
  //   field: 'zip',
  //   headerName: 'Zip',
  //   minWidth: 160,
  //   editable: false,
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params: GridCellParams) => renderCellName(params),
  //   renderCell: (params: GridCellParams) => renderCell(params),
  //   renderHeader: (params) => {
  //     return (
  //       <>
  //         {params.field}
  //         <InfoIcon opacity={'0.6'} />
  //       </>
  //     );
  //   },
  // },
  {
    field: 'commodityType',
    headerName: 'Commodity Type',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.commodityTypes || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'factoringCompany',
    headerName: 'Factoring Company',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.factoringStaticData.facoringCompany || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'contactName',
    headerName: 'Contact Name',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters'),
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Email field should contain @'),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 10 characters'),
  },
  {
    field: 'extension',
    headerName: 'Extension',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 5 characters'),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
];

export const vendorColumns: GridColDefSelf[] = [
  {
    field: 'vendorName',
    headerName: 'Vendor Name',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field. It should be Unique'),
  },
  {
    field: 'address',
    headerName: 'Matching Address',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    renderEditCell: renderAddressEditInputCell,
    valueSetter: (params: GridValueSetterParams) => {
      return { ...params.row, address: params.row.address };
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  {
    field: 'streetAddress',
    headerName: 'Street Address',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  // {
  //   field: 'state',
  //   headerName: 'State',
  //   minWidth: 160,
  //   editable: false,
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params: GridCellParams) => renderCellName(params),
  //   renderCell: (params: GridCellParams) => renderCell(params),
  //   renderHeader: (params) => {
  //     return (
  //       <>
  //         {params.field}
  //         <InfoIcon opacity={'0.6'} />
  //       </>
  //     );
  //   },
  // },
  // {
  //   field: 'city',
  //   headerName: 'City',
  //   minWidth: 160,
  //   editable: false,
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params: GridCellParams) => renderCellName(params),
  //   renderCell: (params: GridCellParams) => renderCell(params),
  //   renderHeader: (params) => {
  //     return (
  //       <>
  //         {params.field}
  //         <InfoIcon opacity={'0.6'} />
  //       </>
  //     );
  //   },
  // },
  // {
  //   field: 'zip',
  //   headerName: 'Zip',
  //   minWidth: 160,
  //   editable: false,
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params: GridCellParams) => renderCellName(params),
  //   renderCell: (params: GridCellParams) => renderCell(params),
  //   renderHeader: (params) => {
  //     return (
  //       <>
  //         {params.field}
  //         <InfoIcon opacity={'0.6'} />
  //       </>
  //     );
  //   },
  // },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters'),
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Email field should contain @'),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 10 characters'),
  },
  {
    field: 'extension',
    headerName: 'Extension',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 5 characters'),
  },
];

export const locationColumns: GridColDefSelf[] = [
  {
    field: 'locationName',
    headerName: 'Location Name',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field. It should be Unique'),
  },
  {
    field: 'averageHandlingTime',
    headerName: 'Average Handling Time (min)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field (minutes)'),
  },
  {
    field: 'address',
    headerName: 'Matching Address',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    renderEditCell: renderAddressEditInputCell,
    valueSetter: (params: GridValueSetterParams) => {
      return { ...params.row, address: params.row.address };
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  {
    field: 'streetAddress',
    headerName: 'Street Address',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  // {
  //   field: 'state',
  //   headerName: 'State',
  //   minWidth: 160,
  //   editable: false,
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params: GridCellParams) => renderCellName(params),
  //   renderCell: (params: GridCellParams) => renderCell(params),
  //   renderHeader: (params) => {
  //     return (
  //       <>
  //         {params.field}
  //         <InfoIcon opacity={'0.6'} />
  //       </>
  //     );
  //   },
  // },
  // {
  //   field: 'city',
  //   headerName: 'City',
  //   minWidth: 160,
  //   editable: false,
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params: GridCellParams) => renderCellName(params),
  //   renderCell: (params: GridCellParams) => renderCell(params),
  //   renderHeader: (params) => {
  //     return (
  //       <>
  //         {params.field}
  //         <InfoIcon opacity={'0.6'} />
  //       </>
  //     );
  //   },
  // },
  // {
  //   field: 'zip',
  //   headerName: 'Zip',
  //   minWidth: 160,
  //   editable: false,
  //   flex: 1,
  //   sortable: false,
  //   cellClassName: (params: GridCellParams) => renderCellName(params),
  //   renderCell: (params: GridCellParams) => renderCell(params),
  //   renderHeader: (params) => {
  //     return (
  //       <>
  //         {params.field}
  //         <InfoIcon opacity={'0.6'} />
  //       </>
  //     );
  //   },
  // },
  {
    field: 'contactName',
    headerName: 'Contact Name',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters'),
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Email field should contain @'),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 10 characters'),
  },
  {
    field: 'extension',
    headerName: 'Extension',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 5 characters'),
  },
];

export const tractorColumns: GridColDefSelf[] = [
  {
    field: 'number',
    headerName: 'Tractor #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        'Text field, max 50 characters. It should be Unique'
      ),
  },
  {
    field: 'vin',
    headerName: 'VIN #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        'Text field, max 17 characters. It should not contain the following letters: I, O or Q.'
      ),
  },
  {
    field: 'registrationPlate',
    headerName: 'Registration Plate #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 20 characters'),
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.states || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'registrationExpiry',
    headerName: 'Registration Expiry',
    minWidth: 160,
    editable: true,
    type: 'date',
    flex: 1,
    sortable: false,
    valueParser: (value) => {
      // we keep this dateMap because we have differences in converting date in FE and BE
      // in BE dd has this format 02 for example
      // in FE dd has this format thu. for example
      // we send BE their format and keep the equal format in FE using this map
      if (value) {
        return moment(value).format(dateMaps[ImportActions.selectedDateFormat]);
      }
      return '';
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        `Date field with ${ImportActions.selectedDateFormat} format`
      ),
  },
  {
    field: 'odometer',
    headerName: 'Odometer',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 1,000,000'),
  },
  {
    field: 'terminal',
    headerName: 'Terminal',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.terminals || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'year',
    headerName: 'Year',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Year field with YYYY format'),
  },
  {
    field: 'make',
    headerName: 'Make',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 100 characters'),
  },
  {
    field: 'model',
    headerName: 'Model',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 100 characters'),
  },
  {
    field: 'tollTagNumber',
    headerName: 'Toll Tag #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 100 characters'),
  },
  {
    field: 'fuelCapacity',
    headerName: 'Fuel Capacity (gal)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 1000'),
  },
  {
    field: 'sleeperBerth',
    headerName: 'Sleeper Berth',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.tractorStaticData.isSleeperBerthAvailable || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'transmission',
    headerName: 'Transmission',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.tractorStaticData.transmissionType || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'braking',
    headerName: 'Braking',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.tractorStaticData.brakeType || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'gvw',
    headerName: 'GVW (LBS)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 99,999'),
  },
];

export const trailerColumns: GridColDefSelf[] = [
  {
    field: 'number',
    headerName: 'Trailer #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        'Text field, max 50 characters. It should be Unique'
      ),
  },
  {
    field: 'vin',
    headerName: 'VIN #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        'Text field, max 17 characters. It should not contain the following letters: I, O or Q.'
      ),
  },
  {
    field: 'registrationPlate',
    headerName: 'Registration Plate #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 20 characters'),
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.states || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'registrationExpiry',
    headerName: 'Registration Expiry',
    minWidth: 160,
    editable: true,
    flex: 1,
    type: 'date',
    sortable: false,
    valueParser: (value) => {
      // we keep this dateMap because we have differences in converting date in FE and BE
      // in BE dd has this format 02 for example
      // in FE dd has this format thu. for example
      // we send BE their format and keep the equal format in FE using this map
      if (value) {
        return moment(value).format(dateMaps[ImportActions.selectedDateFormat]);
      }
      return '';
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        `Date field with ${ImportActions.selectedDateFormat} format`
      ),
  },
  {
    field: 'terminal',
    headerName: 'Terminal',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.terminals || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'make',
    headerName: 'Make',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 100 characters'),
  },
  {
    field: 'model',
    headerName: 'Model',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 100 characters'),
  },
  {
    field: 'year',
    headerName: 'Year',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Year field with YYYY format'),
  },
  {
    field: 'trailerType',
    headerName: 'Trailer Type',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.trailerStaticData.trailerType || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'length',
    headerName: 'Length',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field'),
  },
  {
    field: 'gvw',
    headerName: 'GVW (LBS)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 99,999'),
  },
  {
    field: 'payloadCapacity',
    headerName: 'Payload Capacity',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field'),
  },
];

export const driverColumns: GridColDefSelf[] = [
  {
    field: 'firstName',
    headerName: 'First Name',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters.'),
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters.'),
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Email field should contain @'),
  },
  {
    field: 'employmentType',
    headerName: 'Employment Type',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.driverStaticData.employmentType || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'operationType',
    headerName: 'Operation Type',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.driverStaticData.operationType || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 10 characters'),
  },
  {
    field: 'timezone',
    headerName: 'Timezone',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.driverStaticData.timezones || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'terminal',
    headerName: 'Terminal',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.terminals || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'fuelCardNumber',
    headerName: 'Fuel Card' + ' #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 100 characters'),
  },
];

export const fuelExpenseColumns: GridColDefSelf[] = [
  {
    field: 'transactionDate',
    headerName: 'Transaction Date',
    minWidth: 160,
    editable: true,
    type: 'date',
    flex: 1,
    sortable: false,
    valueParser: (value) => {
      // we keep this dateMap because we have differences in converting date in FE and BE
      // in BE dd has this format 02 for example
      // in FE dd has this format thu. for example
      // we send BE their format and keep the equal format in FE using this map
      if (value) {
        return moment(value).format(dateMaps[ImportActions.selectedDateFormat]);
      }
      return '';
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        `Date format should be in past with ${ImportActions.selectedDateFormat} format.`
      ),
  },
  {
    field: 'grossAmount',
    headerName: 'Gross Amount',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'discountAmount',
    headerName: 'Discount',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 0 and 999,999.99'),
  },
  {
    field: 'referenceNumber',
    headerName: 'Reference #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field. It should be Unique'),
  },
  {
    field: 'paidBy',
    headerName: 'Paid By',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => renderHeader(params, ''),
  },
  {
    field: 'loadName',
    headerName: 'Related Load',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => renderHeader(params, ''),
  },
  {
    field: 'tractorName',
    headerName: 'Related Tractor',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => renderHeader(params, ''),
  },
  {
    field: 'trailerName',
    headerName: 'Related Trailer',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => renderHeader(params, ''),
  },
  {
    field: 'cardNumber',
    headerName: 'Fuel Card' + ' #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 100 characters'),
  },
  {
    field: 'fuelType',
    headerName: 'Fuel Type',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    cellClassName: (params: GridCellParams) => renderCellName(params),
    valueOptions: () => {
      return ImportActions.expenseStaticData.fuelTypes || [];
    },
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'fuelQuantity',
    headerName: 'Fuel Quantity (US Gal)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.states || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'truckstopAddress',
    headerName: 'Truck stop',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 100 characters'),
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 100 characters'),
  },
  {
    field: 'terminal',
    headerName: 'Terminal',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.terminals || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
];

export const otherExpenseColumns: GridColDefSelf[] = [
  {
    field: 'category',
    headerName: 'Expense Category',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.expenseStaticData.expenseCategoryTypes || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'transactionDate',
    headerName: 'Transaction Date',
    minWidth: 160,
    editable: true,
    type: 'date',
    flex: 1,
    sortable: false,
    valueParser: (value) => {
      // we keep this dateMap because we have differences in converting date in FE and BE
      // in BE dd has this format 02 for example
      // in FE dd has this format thu. for example
      // we send BE their format and keep the equal format in FE using this map
      if (value) {
        return moment(value).format(dateMaps[ImportActions.selectedDateFormat]);
      }
      return '';
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        `Date format should be in past with ${ImportActions.selectedDateFormat} format.`
      ),
  },
  {
    field: 'grossAmount',
    headerName: 'Gross Amount',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'discountAmount',
    headerName: 'Discount Amount',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 0 and 999,999.99'),
  },
  {
    field: 'referenceNumber',
    headerName: 'Reference #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field. It should be Unique'),
  },
  {
    field: 'paidBy',
    headerName: 'Paid By',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => renderHeader(params, ''),
  },
  {
    field: 'loadName',
    headerName: 'Related Load',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => renderHeader(params, ''),
  },
  {
    field: 'tractorName',
    headerName: 'Related Tractor',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => renderHeader(params, ''),
  },
  {
    field: 'trailerName',
    headerName: 'Related Trailer',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) => renderHeader(params, ''),
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'terminal',
    headerName: 'Terminal',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => ImportActions.terminals || [],
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
];

export const futureLoadsColumns: GridColDefSelf[] = [
  {
    field: 'customerName',
    headerName: 'Customer',
    minWidth: 160,
    editable: false,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
  },
  {
    field: 'referenceNumber',
    headerName: 'Reference #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field. It should be Unique'),
  },
  {
    field: 'revenue',
    headerName: 'Revenue',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1.0000 and 999,999.9999'),
  },
  {
    field: 'terminal',
    headerName: 'Terminal',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.terminals || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'invoiceNotes',
    headerName: 'Note for Invoicing',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  {
    field: 'equipmentType',
    headerName: 'Required Equipment',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.loadStaticData.trailerTypes || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'sealNumber',
    headerName: 'Seal #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters'),
  },
  {
    field: 'assignedChassis',
    headerName: 'Chassis #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters'),
  },
  {
    field: 'assignedContainer',
    headerName: 'Container #',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters'),
  },
  {
    field: 'lowerLimitTemperature',
    headerName: 'Lower Limit Temperature(F)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between -500 and 500'),
  },
  {
    field: 'upperLimitTemperature',
    headerName: 'Upper Limit Temperature(F)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between -500 and 500'),
  },
  {
    field: 'weight',
    headerName: 'Weight (lbs)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'dispatchNotes',
    headerName: 'Dispatch Notes',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters.'),
  },
  {
    field: 'pickupBusinessName',
    headerName: 'Pickup Business Name',
    minWidth: 160,
    editable: false,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'pickupAddress',
    headerName: 'Pickup Address',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    renderEditCell: renderAddressEditInputCell,
    valueSetter: (params: GridValueSetterParams) => {
      return { ...params.row, pickupAddress: params.row.address };
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  {
    field: 'pickupAppointmentStartDate',
    headerName: 'Pickup Start Date and Time',
    minWidth: 160,
    editable: true,
    type: 'dateTime',
    flex: 1,
    sortable: false,
    valueParser: (value) => {
      // we keep this dateMap because we have differences in converting date in FE and BE
      // in BE dd has this format 02 for example
      // in FE dd has this format thu. for example
      // we send BE their format and keep the equal format in FE using this map
      if (value) {
        return moment(value).format(
          `${dateMaps[ImportActions.selectedDateFormat]} hh:mm`
        );
      }
      return '';
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        `Date format should be ${
          dateMaps[ImportActions.selectedDateFormat]
        }. Time format should be HH:MM, 24hour format`
      ),
  },
  {
    field: 'pickupAppointmentEndDate',
    headerName: 'Pickup End Date and Time',
    minWidth: 160,
    editable: true,
    type: 'dateTime',
    flex: 1,
    sortable: false,
    valueParser: (value) => {
      // we keep this dateMap because we have differences in converting date in FE and BE
      // in BE dd has this format 02 for example
      // in FE dd has this format thu. for example
      // we send BE their format and keep the equal format in FE using this map
      if (value) {
        return moment(value).format(
          `${dateMaps[ImportActions.selectedDateFormat]} hh:mm`
        );
      }
      return '';
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        `Date format should be ${
          dateMaps[ImportActions.selectedDateFormat]
        }. Time format should be HH:MM, 24hour format`
      ),
  },
  {
    field: 'pickupHandlingTime',
    headerName: 'Pickup Handling Time (minutes)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        'Handling Time should be between 1 and 480 minutes.'
      ),
  },
  {
    field: 'pickupNotes',
    headerName: 'Pickup Notes',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 1024 characters'),
  },
  {
    field: 'dropOffBusinessName',
    headerName: 'Drop Off Name',
    minWidth: 160,
    editable: false,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'dropOffAddress',
    headerName: 'Drop Off Address',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    renderEditCell: renderAddressEditInputCell,
    valueSetter: (params: GridValueSetterParams) => {
      return { ...params.row, dropOffAddress: params.row.address };
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  {
    field: 'dropOffAppointmentStartDate',
    headerName: 'Drop Off Start Date and Time',
    minWidth: 160,
    editable: true,
    type: 'dateTime',
    flex: 1,
    sortable: false,
    valueParser: (value) => {
      // we keep this dateMap because we have differences in converting date in FE and BE
      // in BE dd has this format 02 for example
      // in FE dd has this format thu. for example
      // we send BE their format and keep the equal format in FE using this map
      if (value) {
        return moment(value).format(
          `${dateMaps[ImportActions.selectedDateFormat]} hh:mm`
        );
      }
      return '';
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        `Date format should be ${
          dateMaps[ImportActions.selectedDateFormat]
        }. Time format should be HH:MM, 24hour format`
      ),
  },
  {
    field: 'dropOffAppointmentEndDate',
    headerName: 'Drop Off End Date and Time',
    minWidth: 160,
    editable: true,
    type: 'dateTime',
    flex: 1,
    sortable: false,
    valueParser: (value) => {
      // we keep this dateMap because we have differences in converting date in FE and BE
      // in BE dd has this format 02 for example
      // in FE dd has this format thu. for example
      // we send BE their format and keep the equal format in FE using this map
      if (value) {
        return moment(value).format(
          `${dateMaps[ImportActions.selectedDateFormat]} hh:mm`
        );
      }
      return '';
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        `Date format should be ${
          dateMaps[ImportActions.selectedDateFormat]
        }. Time format should be HH:MM, 24hour format`
      ),
  },
  {
    field: 'dropOffHandlingTime',
    headerName: 'Drop Off Handling Time (minutes)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(
        params,
        'Handling Time should be between 1 and 480 minutes.'
      ),
  },
  {
    field: 'dropOffNotes',
    headerName: 'Drop Off Notes',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 1024 characters'),
  },
  {
    field: 'dispatcher',
    headerName: 'Dispatcher',
    minWidth: 160,
    editable: false,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
  },
  {
    field: 'primaryDriver',
    headerName: 'Primary Driver',
    minWidth: 160,
    editable: false,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
  },
  {
    field: 'secondaryDriver',
    headerName: 'Secondary Driver',
    minWidth: 160,
    editable: false,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
  },
  {
    field: 'assignedTractor',
    headerName: 'Assigned Tractor',
    minWidth: 160,
    editable: false,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
  },
  {
    field: 'assignedTrailer',
    headerName: 'Assigned Trailer',
    minWidth: 160,
    editable: false,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
  },
  {
    field: 'primaryDriverPayment',
    headerName: 'Primary Driver Payment($)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 0.0001 and 999,999.9999'),
  },
  {
    field: 'secondaryDriverPayment',
    headerName: 'Secondary Driver Payment($)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 0.0001 and 999,999.9999'),
  },
];

export const pastLoadsColumns: GridColDefSelf[] = [
  ...futureLoadsColumns,
  ...[
    {
      field: 'seqNumber',
      headerName: 'Load ID',
      minWidth: 160,
      editable: true,
      flex: 1,
      sortable: false,
      cellClassName: (params: GridCellParams) => renderCellName(params),
      renderCell: (params: GridCellParams) => renderCell(params),
      renderHeader: (params: GridColumnHeaderParams) =>
        renderHeader(params, 'Text field, max 20 c alphabets, numbers and "-"'),
    },
    {
      field: 'isLoadCanceled',
      headerName: 'Canceled?',
      minWidth: 160,
      editable: true,
      flex: 1,
      sortable: false,
      type: 'singleSelect',
      valueOptions: () => {
        return ['Yes', 'No'];
      },
      cellClassName: (params: GridCellParams) => renderCellName(params),
      renderCell: (params: GridCellParams) => renderCell(params),
      renderHeader: (params: GridColumnHeaderParams) =>
        renderHeader(params, 'Predefined values. Click to select'),
    },
    {
      field: 'actualPickupArrivalDate',
      headerName: 'Actual Pickup Arrival Date and Time',
      minWidth: 160,
      editable: true,
      type: 'dateTime',
      flex: 1,
      sortable: false,
      valueParser: (value: string) => {
        // we keep this dateMap because we have differences in converting date in FE and BE
        // in BE dd has this format 02 for example
        // in FE dd has this format thu. for example
        // we send BE their format and keep the equal format in FE using this map
        if (value) {
          return moment(value).format(
            `${dateMaps[ImportActions.selectedDateFormat]} hh:mm`
          );
        }
        return '';
      },
      cellClassName: (params: GridCellParams) => renderCellName(params),
      renderCell: (params: GridCellParams) => renderCell(params),
      renderHeader: (params: GridColumnHeaderParams) =>
        renderHeader(
          params,
          `Date format should be ${
            dateMaps[ImportActions.selectedDateFormat]
          }. Time format should be HH:MM, 24hour format`
        ),
    },
    {
      field: 'actualPickupDepartureDate',
      headerName: 'Pickup Departure Date and Time',
      minWidth: 160,
      editable: true,
      type: 'dateTime',
      flex: 1,
      sortable: false,
      valueParser: (value: string) => {
        // we keep this dateMap because we have differences in converting date in FE and BE
        // in BE dd has this format 02 for example
        // in FE dd has this format thu. for example
        // we send BE their format and keep the equal format in FE using this map
        if (value) {
          return moment(value).format(
            `${dateMaps[ImportActions.selectedDateFormat]} hh:mm`
          );
        }
        return '';
      },
      cellClassName: (params: GridCellParams) => renderCellName(params),
      renderCell: (params: GridCellParams) => renderCell(params),
      renderHeader: (params: GridColumnHeaderParams) =>
        renderHeader(
          params,
          `Date format should be ${
            dateMaps[ImportActions.selectedDateFormat]
          }. Time format should be HH:MM, 24hour format`
        ),
    },
    {
      field: 'actualDropoffArrivalDate',
      headerName: 'Actual Drop Off Arrival Date and Time',
      minWidth: 160,
      editable: true,
      type: 'dateTime',
      flex: 1,
      sortable: false,
      valueParser: (value: string) => {
        // we keep this dateMap because we have differences in converting date in FE and BE
        // in BE dd has this format 02 for example
        // in FE dd has this format thu. for example
        // we send BE their format and keep the equal format in FE using this map
        if (value) {
          return moment(value).format(
            `${dateMaps[ImportActions.selectedDateFormat]} hh:mm`
          );
        }
        return '';
      },
      cellClassName: (params: GridCellParams) => renderCellName(params),
      renderCell: (params: GridCellParams) => renderCell(params),
      renderHeader: (params: GridColumnHeaderParams) =>
        renderHeader(
          params,
          `Date format should be ${
            dateMaps[ImportActions.selectedDateFormat]
          }. Time format should be HH:MM, 24hour format`
        ),
    },
    {
      field: 'actualDropoffDepartureDate',
      headerName: 'Drop off Departure Date and Time',
      minWidth: 160,
      editable: true,
      type: 'dateTime',
      flex: 1,
      sortable: false,
      valueParser: (value: string) => {
        // we keep this dateMap because we have differences in converting date in FE and BE
        // in BE dd has this format 02 for example
        // in FE dd has this format thu. for example
        // we send BE their format and keep the equal format in FE using this map
        if (value) {
          return moment(value).format(
            `${dateMaps[ImportActions.selectedDateFormat]} hh:mm`
          );
        }
        return '';
      },
      cellClassName: (params: GridCellParams) => renderCellName(params),
      renderCell: (params: GridCellParams) => renderCell(params),
      renderHeader: (params: GridColumnHeaderParams) =>
        renderHeader(
          params,
          `Date format should be ${
            dateMaps[ImportActions.selectedDateFormat]
          }. Time format should be HH:MM, 24hour format`
        ),
    },
    {
      field: 'emptyMiles',
      headerName: 'Empty Miles',
      minWidth: 160,
      editable: true,
      flex: 1,
      sortable: false,
      type: 'singleSelect',
      cellClassName: (params: GridCellParams) => renderCellName(params),
      renderCell: (params: GridCellParams) => renderCell(params),
      renderHeader: (params: GridColumnHeaderParams) =>
        renderHeader(params, 'Numeric field between 1 and 999,999'),
    },
    {
      field: 'loadedMiles',
      headerName: 'Loaded Miles',
      minWidth: 160,
      editable: true,
      flex: 1,
      sortable: false,
      type: 'singleSelect',
      cellClassName: (params: GridCellParams) => renderCellName(params),
      renderCell: (params: GridCellParams) => renderCell(params),
      renderHeader: (params: GridColumnHeaderParams) =>
        renderHeader(params, 'Numeric field between 1 and 999,999'),
    },
  ],
];

export const cityBasedLineItemColumns: GridColDefSelf[] = [
  {
    field: 'originCity',
    headerName: 'Origin City',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    renderEditCell: renderAddressEditInputCell,
    valueSetter: (params: GridValueSetterParams) => {
      return { ...params.row, originCity: params.row.city };
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  {
    field: 'originState',
    headerName: 'Origin State',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.states || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'destinationCity',
    headerName: 'Destination City',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    renderEditCell: renderAddressEditInputCell,
    valueSetter: (params: GridValueSetterParams) => {
      return { ...params.row, destinationCity: params.row.city };
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters'),
  },
  {
    field: 'destinationState',
    headerName: 'Destination State',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.states || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'totalMiles',
    headerName: 'Total Miles',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 8 characters'),
  },
  {
    field: 'weight',
    headerName: 'Weight (lbs)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'equipmentType',
    headerName: 'Required Equipment',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.lineItemsStaticData.trailerTypes || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'mode',
    headerName: 'Mode',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ['FTL', 'Partial', 'FTL/Partial'];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'rateType',
    headerName: 'Rate Type',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ['Flat Rate', 'Per Loaded Mile'];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'rate',
    headerName: 'Rate ($)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'minimumAmount',
    headerName: 'Minimum Amount',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1.0000 and 999,999.9999'),
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters.'),
  },
];

export const zipBasedLineItemColumns: GridColDefSelf[] = [
  {
    field: 'origZipStart',
    headerName: 'Origin Zip Start',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 6 characters'),
  },
  {
    field: 'origZipEnd',
    headerName: 'Origin Zip End',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 6 characters'),
  },
  {
    field: 'destZipStart',
    headerName: 'Destination Zip Start',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 6 characters'),
  },
  {
    field: 'destZipEnd',
    headerName: 'Destination Zip End',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 6 characters'),
  },
  {
    field: 'totalMiles',
    headerName: 'Total Miles',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 8 characters'),
  },
  {
    field: 'weight',
    headerName: 'Weight (lbs)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'equipmentType',
    headerName: 'Required Equipment',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.lineItemsStaticData.trailerTypes || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'mode',
    headerName: 'Mode',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ['FTL', 'Partial', 'FTL/Partial'];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'rateType',
    headerName: 'Rate Type',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ['Flat Rate', 'Per Loaded Mile'];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'rate',
    headerName: 'Rate ($)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'minimumAmount',
    headerName: 'Minimum Amount ($)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 99,999'),
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters.'),
  },
];

export const mileageBasedLineItemColumns: GridColDefSelf[] = [
  {
    field: 'minMiles',
    headerName: 'Min Miles',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters'),
  },
  {
    field: 'maxMiles',
    headerName: 'Max Miles',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 50 characters'),
  },
  {
    field: 'weight',
    headerName: 'Weight (lbs)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'equipmentType',
    headerName: 'Required Equipment',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ImportActions.lineItemsStaticData.trailerTypes || [];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'mode',
    headerName: 'Mode',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ['FTL', 'Partial', 'FTL/Partial'];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'rateType',
    headerName: 'Rate Type',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    type: 'singleSelect',
    valueOptions: () => {
      return ['Flat Rate', 'Per Loaded Mile'];
    },
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Predefined values. Click to select'),
  },
  {
    field: 'rate',
    headerName: 'Rate ($)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field between 1 and 999,999'),
  },
  {
    field: 'minimumAmount',
    headerName: 'Minimum Amount ($)',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Numeric field, max 99,999'),
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 160,
    editable: true,
    flex: 1,
    sortable: false,
    cellClassName: (params: GridCellParams) => renderCellName(params),
    renderCell: (params: GridCellParams) => renderCell(params),
    renderHeader: (params: GridColumnHeaderParams) =>
      renderHeader(params, 'Text field, max 255 characters.'),
  },
];

export const importTableHeadersMap: { [key: string]: GridColDefSelf[] } = {
  [IMPORT_CUSTOMER_NAME]: customerColumns,
  [IMPORT_VENDOR_NAME]: vendorColumns,
  [IMPORT_LOCATION_NAME]: locationColumns,
  [IMPORT_TRACTOR_NAME]: tractorColumns,
  [IMPORT_TRAILER_NAME]: trailerColumns,
  [IMPORT_DRIVER_NAME]: driverColumns,
};

export const expenseLoadTableHeadersMap: { [key: string]: GridColDefSelf[] } = {
  AXELE_FUEL: fuelExpenseColumns,
  AXELE_OTHER: otherExpenseColumns,
  FUTURE: futureLoadsColumns,
  PAST: pastLoadsColumns,
  CITY_BASED: cityBasedLineItemColumns,
  ZIP_BASED: zipBasedLineItemColumns,
  MILEAGE_BASED: mileageBasedLineItemColumns,
};
