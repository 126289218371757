import { IDispatchPreferencesFormData } from '../constants/types';

export const defaultDispatchPreferencesFormData: IDispatchPreferencesFormData =
  {
    useSleeperBerthProvision: false,
    // ignoreHOSRestrictions: false,
    preferredTruckstops: [],
    weeklyResetDay: 'FRIDAY',
    weeklyRevenueTarget: 5000,
    weeklyEarningsTarget: 1200,
    weeklyMileageTarget: 2500,
    dvirPreferences: 'NONE',
    workWeekType: 'D7_H60',
    drivingBehaviourPreference: 'ANY',
    statesToAvoid: [],
    locationPreference: [],
  };
