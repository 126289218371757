import { UsersEntityTypeEnum } from '../../../../models/DTOs/user/User';
import { ViewMap } from '../../../../types';
import {
  AllUsersIcon,
  DriversIcon,
  WebUserIcon,
} from '../../../../ui-kit/components/Assets';
import {
  getAllDriverNamesList,
  getDriverEmails,
  getDriverIdList,
  getDriverNamesList,
  getNamesList,
  getStatusesList,
  getUserEmails,
  getWebUserEmails,
  getWebUserNamesList,
} from '../utils';

const employmentTypeOptions = [
  { key: 'EMPLOYEE', name: 'Employee' },
  { key: 'OWNER_OPERATOR', name: 'Owner Operator' },
];
import { CommonConstants } from '../../../../locales/en/common';

export const emailFilter = 'emailFilter';
export const nameFilter = 'nameFilter';
export const roleCodeList = 'roleCodeList';
export const userStatus = 'userStatus';
export const userTypes = 'userTypes';
export const employmentType = 'employmentType';
export const driverIdList = 'driverIdList';
export const safetyIssueType = 'safetyIssueType';

export const getUsersPageFiltersFormConfig = ({
  entity,
  terminalIds,
}: {
  entity: UsersEntityTypeEnum;
  terminalIds: string[];
}) => {
  const webUserFiltersFormConfig = [
    {
      key: 1,
      name: nameFilter,
      fieldName: 'name',
      getData: (name: string, pageNumber: number) =>
        getWebUserNamesList(name, pageNumber, terminalIds),
      label: CommonConstants.name,
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: userStatus,
      fieldName: 'value',
      getData: getStatusesList,
      label: 'Status',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 3,
      name: roleCodeList,
      fieldName: 'name',
      fieldValue: 'roleCode',
      options: [
        { name: 'Accountant', roleCode: 'AC' },
        { name: 'Admin', roleCode: 'AD' },
        { name: 'Power Dispatcher', roleCode: 'PD' },
        { name: 'Financial Dispatcher', roleCode: 'DI' },
        { name: 'Dispatcher', roleCode: 'NFD' },
      ],
      label: 'Web User Role',
      default: true,
      type: 'ButtonGroup',
      multiple: true,
      immutableModel: true,
    },
    {
      key: 4,
      name: emailFilter,
      fieldName: 'email',
      getData: (name: string, pageNumber: number) =>
        getWebUserEmails(name, pageNumber, terminalIds),
      label: 'Email',
      default: false,
      type: 'MultipleAutocomplete',
    },
  ];

  const driverFiltersFormConfig = [
    {
      key: 1,
      name: nameFilter,
      fieldName: 'name',
      getData: (name: string, pageNumber: number) =>
        getAllDriverNamesList(name, pageNumber, terminalIds),
      label: CommonConstants.name,
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: userStatus,
      fieldName: 'value',
      getData: getStatusesList,
      label: 'Status',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 3,
      name: employmentType,
      fieldName: 'name',
      fieldValue: 'key',
      options: employmentTypeOptions,
      label: 'Driver Role',
      type: 'ButtonGroup',
      multiple: false,
      default: true,
    },
    {
      key: 4,
      name: driverIdList,
      fieldName: 'driverRefId',
      getData: (name: string, pageNumber: number) =>
        getDriverIdList(name, pageNumber, terminalIds),
      label: 'Driver ID',
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 5,
      name: emailFilter,
      fieldName: 'email',
      getData: (name: string, pageNumber: number) =>
        getDriverEmails(name, pageNumber, terminalIds),
      label: 'Email',
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 6,
      name: safetyIssueType,
      fieldName: 'name',
      fieldValue: 'name',
      options: [
        { name: 'NO WARNING OR CRITICAL' },
        { name: 'WARNING' },
        { name: 'CRITICAL' },
      ],
      label: 'Safety Issue',
      default: false,
      type: 'ButtonGroup',
      multiple: true,
    },
  ];

  const userFiltersFormConfig = [
    {
      key: 1,
      name: nameFilter,
      fieldName: 'name',
      getData: (name: string, pageNumber: number) =>
        getNamesList(name, pageNumber, terminalIds),
      label: CommonConstants.name,
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: userStatus,
      fieldName: 'value',
      // @TODO davits in all places where this is used change from static in the feature
      getData: getStatusesList,
      label: 'Status',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 3,
      name: userTypes,
      fieldName: 'name',
      // fieldValue: 'value',
      options: [
        { name: 'Web User', value: 0 },
        { name: 'Driver', value: 1 },
        // { name: 'Both', value: 2 },
      ],
      label: 'User Type',
      default: true,
      type: 'ButtonGroup',
      multiple: true,
      immutableModel: true,
    },
    {
      key: 4,
      name: roleCodeList,
      fieldName: 'name',
      fieldValue: 'roleCode',
      options: [
        { name: 'Accountant', roleCode: 'AC' },
        { name: 'Admin', roleCode: 'AD' },
        { name: 'Power Dispatcher', roleCode: 'PD' },
        { name: 'Financial Dispatcher', roleCode: 'DI' },
        { name: 'Dispatcher', roleCode: 'NFD' },
      ],
      label: 'Web User Role',
      default: false,
      type: 'ButtonGroup',
    },
    {
      key: 5,
      name: employmentType,
      fieldName: 'name',
      fieldValue: 'key',
      options: employmentTypeOptions,
      label: 'Driver Role',
      type: 'ButtonGroup',
      multiple: false,
      default: false,
    },
    {
      key: 6,
      name: emailFilter,
      fieldName: 'email',
      getData: (name: string, pageNumber: number) =>
        getUserEmails(name, pageNumber, terminalIds),
      label: 'Email',
      default: false,
      type: 'MultipleAutocomplete',
    },
  ];

  switch (entity) {
    case UsersEntityTypeEnum.user:
      return userFiltersFormConfig;

    case UsersEntityTypeEnum.webUser:
      return webUserFiltersFormConfig;

    default:
      return driverFiltersFormConfig;
  }
};

export const userFilters = {
  [nameFilter]: [],
  [userStatus]: [
    { key: 2, value: 'ACTIVE (NON-INVITED)' },
    { key: 3, value: 'ACTIVE (INVITED)' },
    { key: 1, value: 'ACTIVE' },
  ],
  [userTypes]: [],
  [roleCodeList]: [],
  [employmentType]: null,
  [emailFilter]: [],
};

export const webUserFilters = {
  [nameFilter]: [],
  [userStatus]: [
    { key: 2, value: 'ACTIVE (NON-INVITED)' },
    { key: 3, value: 'ACTIVE (INVITED)' },
    { key: 1, value: 'ACTIVE' },
  ],
  [roleCodeList]: [],
  [emailFilter]: [],
};
export const driverFilters = {
  [nameFilter]: [],
  [userStatus]: [
    { key: 2, value: 'ACTIVE (NON-INVITED)' },
    { key: 3, value: 'ACTIVE (INVITED)' },
    { key: 1, value: 'ACTIVE' },
  ],
  [employmentType]: null,
  [driverIdList]: [],
  [emailFilter]: [],
  [safetyIssueType]: [],
};

export const userColumnsInfo = {
  name: true,
  status: true,
  roleCode: true,
  type: true,
  employmentType: true,
  terminal: true,
  source: true,
  provider: true,
  phone: true,
  email: true,
  lastLoginDate: true,
};

export const webUserColumnsInfo = {
  name: true,
  status: true,
  roleCode: true,
  terminals: true,
  phone: true,
  email: true,
  lastLoginDate: true,
};

export const driverColumnsInfo = {
  name: true,
  status: true,
  employmentType: true,
  driverRefId: true,
  terminal: true,
  eldProvider: true,
  source: true,
  phone: true,
  email: true,
  warning: true,
  critical: true,
  lastLoginDate: true,
};

export const driverView = {
  id: 'driver',
  name: 'Drivers',
  icon: <DriversIcon />,
  entity: 'driver',
  shared: true,
  default: true,
  columnFilters: driverColumnsInfo,
  metaData: driverFilters,
};

export const userView = {
  id: 'user',
  shared: true,
  parentId: null,
  name: 'All Users',
  entity: 'user',
  icon: <AllUsersIcon />,
  active: true,
  default: true,
  columnFilters: userColumnsInfo,
  metaData: userFilters,
};

export const webUserView = {
  id: 'webUser',
  name: 'Web Users',
  icon: <WebUserIcon />,
  entity: 'webUser',
  shared: true,
  default: true,
  columnFilters: webUserColumnsInfo,
  metaData: webUserFilters,
};

export const formDefaultValuesMap: ViewMap = {
  user: userView,
  webUser: webUserView,
  driver: driverView,
};
