export const PreferencesConstants = {
  preferencePageTitle: 'वित्तीय लक्ष्य',
  preferencePageSubtitle:
    'डैशबोर्ड के वित्तीय घटकों को अद्यतन करने के लिए इस अनुभाग का उपयोग करें। यदि आप अपने साप्ताहिक लक्ष्यों तक पहुँच रहे हैं तो प्रत्येक घटक आपको कल्पना करने में मदद करता है।',
  targetRevenuePerMile: 'लक्ष्य राजस्व प्रति मील',
  targetRevenueSubtitle:
    'आप प्रति मील कितना राजस्व कमाना चाहेंगे? लाभ की गणना करते समय यह परिलक्षित होता है।',
  revenuePerMile: 'राजस्व प्रति मील',
  targetRevenuePerLoadMile: 'लक्षित राजस्व प्रति लोड मील',
  targetRevenuePerLoadMileSubtitle:
    'आप प्रति लोड मील कितना राजस्व अर्जित करना चाहेंगे? हम इसका उपयोग यह मापने के लिए करते हैं कि क्या आप अपने लक्ष्यों तक पहुँच रहे हैं।',
  revenuePerLoadedMile: 'प्रति लोड मील आय',
  targetWeeklyCompanyRevenue: 'लक्ष्य साप्ताहिक कंपनी राजस्व',
  targetWeeklyCompanyRevenueSubtitle:
    'आप प्रति सप्ताह कितना राजस्व कमाना चाहेंगे? हम इसका उपयोग प्रति चालक लक्षित राजस्व का पता लगाने के लिए भी करते हैं।',
  weeklyRevenue: 'साप्ताहिक राजस्व',
};
