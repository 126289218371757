import { Box, Dialog } from '@mui/material';
import { letter15 } from '../../../components/Subscription/constant';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../../ui-kit/components/IconsComponent';

export default function SubscribeModal({
  onclose,
  openSubscribeModal,
  navigate,
}: {
  onclose: () => void;
  openSubscribeModal: boolean;
  navigate: (x: string) => void;
}) {
  return (
    <Dialog onClose={onclose} open={openSubscribeModal}>
      <Box
        sx={{
          padding: '24px',
          width: '527px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '46px',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '24px',
          }}
        >
          <Box
            sx={(theme) => ({
              ...theme.typography.h6,
              color: 'text.primary',
            })}
          >
            Check subscription page
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={onclose}>
            <IconsComponent iconName="Close" source="MUIcons" />
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            ...theme.typography.body1,
            ...letter15,
            marginBottom: '24px',
            textAlign: 'center',
          })}
        >
          Our records indicate you are the owner / admin for this account,
          please follow the link below to the subscription page to update your
          account information.
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonImproved label="Cancel" variant="outlined" onClick={onclose} />
          <ButtonImproved
            label="To Subscription Page"
            variant="contained"
            onClick={() => {
              navigate('/subscription');
              onclose();
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
