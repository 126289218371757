import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { performNaivgation } from '../../utils/commonNavigation';
import {
  LoadOperationalChart,
  LoadOperationalChartProps,
} from './Chart/LoadOperationalChart';
import { GetLoadOperationChartColorSettings } from './Chart/LoadOperationalChart/utils';
import { DashboardLoader } from '../../views/home/DhaboardLoader';
import { dashboardPage } from '../../common/PendoClassIDs/constants';
import { CustomDashboardLoader } from '../../views/home/CustomDashboardLoader';

export interface LoadKPIChartProps {
  loadOperationalChart: LoadOperationalChartProps;
  chartTitle?: string;
}
export const LoadKPIChart = ({
  chartTitle,
  loadOperationalChart,
}: LoadKPIChartProps): JSX.Element => {
  const navigate = useNavigate();
  const { chartTitleColor } = GetLoadOperationChartColorSettings(
    loadOperationalChart.type
  );
  const onClick = () => {
    if (loadOperationalChart.id) {
      performNaivgation(loadOperationalChart.id, navigate);
    }
  };

  return (
    <Stack
      id={loadOperationalChart.id + dashboardPage}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
      }}
    >
      <CustomDashboardLoader loading={!!loadOperationalChart.loader} />
      {!loadOperationalChart.loader && (
        <LoadOperationalChart {...loadOperationalChart} />
      )}

      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '18px',
          textAlign: 'center',
          color: chartTitleColor,
        }}
      >
        {chartTitle}
      </Typography>
    </Stack>
  );
};
