import {
  DriversIcon,
  TractorsIcon,
  TrailersIcon,
  WebUserIcon,
} from '../../../../../ui-kit/components/Assets';
import React, { ReactNode } from 'react';
import { GetCustomerListRequest, GetNamesRequest } from '../../../../../models';
import {
  customerService,
  tractorService,
  userService,
} from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import CustomerForm from './forms/CustomerForm';
import DriverForm from './forms/DriverForm';
import TractorForm from './forms/TractorForm';
import TrailerForm from './forms/TrailerForm';
import DispatcherForm from './forms/DispatcherForm';

export const NEXT_ACTION_NAME = 'Next';
export const BACK_ACTION_NAME = 'Back';

export type sectionType = {
  key: string;
  name: string;
  label: string;
  icon: ReactNode;
  getList: (name: string, page: number) => Promise<any | undefined>;
};

export const getCustomerList = async (name: string, pageNumber: number) => {
  const request = new GetCustomerListRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.nameKeyword = name;
  const response = await customerService.getCustomerList(request);
  if (response instanceof ServiceError) {
    return;
  } else {
    response.content = response.content.map((c: any) => ({ name: c.name }));
    return response;
  }
};

export const getTractorList = async (name: string, pageNumber: number) => {
  const response = await tractorService.getTractorByNameSearchList(
    name,
    pageNumber
  );
  if (response instanceof ServiceError) {
    return;
  } else {
    response.content = response.content.map((tractorName: string) => ({
      name: tractorName,
    }));
    return response;
  }
};

export const getTrailerList = async (name: string, pageNumber: number) => {
  const response = await tractorService.getTrailerByNameSearchList(
    name,
    pageNumber,
    ['INACTIVE']
  );
  if (response instanceof ServiceError || !response) {
    return;
  } else {
    response.content = response.content.map((trailerName: string) => ({
      name: trailerName,
    }));
    return response;
  }
};

export const getDriverNamesList = async (name: string, pageNumber: number) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.name = name;
  request.roleCodeList = ['DR'];
  request.excludeStatuses = [0];
  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getWebUserNamesList = async (name: string, pageNumber: number) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.name = name;
  request.excludeStatuses = [0];
  request.excludeRoleCodeList = ['DR'];
  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const allSections: { [key: string]: sectionType } = {
  CUSTOMER: {
    key: 'customer',
    name: 'CUSTOMER',
    label: 'Customer',
    icon: WebUserIcon,
    getList: getCustomerList,
  },
  DRIVER: {
    key: 'driver',
    name: 'DRIVER',
    label: 'Driver',
    icon: DriversIcon,
    getList: getDriverNamesList,
  },
  TRACTOR: {
    key: 'tractor',
    name: 'TRACTOR',
    label: 'Assigned Tractors',
    icon: TractorsIcon,
    getList: getTractorList,
  },
  TRAILER: {
    key: 'trailer',
    name: 'TRAILER',
    label: 'Assigned Trailers',
    icon: TrailersIcon,
    getList: getTrailerList,
  },
  DISPATCHER: {
    key: 'dispatcher',
    name: 'DISPATCHER',
    label: 'Dispatcher',
    icon: WebUserIcon,
    getList: getWebUserNamesList,
  },
};

export const possibleSections = [
  'CUSTOMER',
  'DRIVER',
  'TRACTOR',
  'TRAILER',
  'DISPATCHER',
];

export const possibleIcons = {
  CUSTOMER: WebUserIcon,
  DRIVER: DriversIcon,
  TRACTOR: TractorsIcon,
  TRAILER: TrailersIcon,
  DISPATCHER: WebUserIcon,
};

export const formsMap: { [key: string]: React.ElementType } = {
  CUSTOMER: CustomerForm,
  DRIVER: DriverForm,
  TRACTOR: TractorForm,
  TRAILER: TrailerForm,
  DISPATCHER: DispatcherForm,
};
