import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { CopyAllOutlined } from '@mui/icons-material';

export const MenuOptions = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
  {
    label: 'Update ELD',
    icon: RefreshOutlinedIcon,
  },
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
  {
    label: 'Copy webhook endpoint',
    icon: CopyAllOutlined,
  },
];

export const OAuthMenuOptions = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
  {
    label: 'Update ELD',
    icon: RefreshOutlinedIcon,
  },
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: LockOutlinedIcon,
  },
];

export const MenuOptionsWithoutEditPermission = [
  {
    label: 'Update ELD',
    icon: RefreshOutlinedIcon,
  },
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
];

export const OAuthMenuOptionsWithoutEditPermission = [
  {
    label: 'Update ELD',
    icon: RefreshOutlinedIcon,
  },
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: LockOutlinedIcon,
  },
];

export const MenuOptionsWithoutDeactivatePermission = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
  {
    label: 'Update ELD',
    icon: RefreshOutlinedIcon,
  },
];

export const OAuthMenuOptionsWithoutDeactivatePermission = [
  {
    label: 'Update ELD',
    icon: RefreshOutlinedIcon,
  },
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: LockOutlinedIcon,
  },
];

export const MenuOptionsWithoutDeactivateAndEditPermission = [
  {
    label: 'Update ELD',
    icon: RefreshOutlinedIcon,
  },
];

export const OAuthMenuOptionsWithoutDeactivateAndEditPermission = [
  {
    label: 'Update ELD',
    icon: RefreshOutlinedIcon,
  },
];

export const loadboardOptions = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
];

export const loadboardOAuthOptions = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: LockOutlinedIcon,
  },
];

export const loadboardOptionsWithoutEditPermission = [
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
];

export const loadboardOAuthMenuOptionsWithoutEditPermission = [
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: LockOutlinedIcon,
  },
];

export const loadboardOptionsWithoutDeactivatePermission = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
];

export const loadboardOAuthOptionsWithoutDeactivatePermission = [
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
];

export const AccountingOptions = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
    disabled: true,
  },
  {
    label: 'Re-authenticate',
    icon: LockOutlinedIcon,
  },
];

export const AccountingOptionsWithoutEdit = [
  {
    label: 'Delete',
    icon: DeleteOutlineOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: LockOutlinedIcon,
  },
];

export const AccountingOptionsWithoutDelete = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: LockOutlinedIcon,
  },
];

export const AccountingOptionsWithoutEditAndDelete = [
  {
    label: 'Re-authenticate',
    icon: LockOutlinedIcon,
  },
];

export const FactoringMenuOptions = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
  {
    label: 'Deactivate',
    icon: LinkOffOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: RefreshOutlinedIcon,
  },
  // {
  //   label: 'Archive',
  //   icon: ArchiveIcon,
  // },
  {
    label: 'Delete',
    icon: DeleteIcon,
  },
];

export const FactoringMenuOtherFactore = [
  {
    label: 'Edit',
    icon: EditOutlinedIcon,
  },
  {
    label: 'Archive',
    icon: ArchiveIcon,
  },
  {
    label: 'Delete',
    icon: DeleteIcon,
  },
];

export const FactoringMenuOptionsWithoutEdit = [
  {
    label: 'Deactivate',
    icon: LinkOffOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: RefreshOutlinedIcon,
  },
  {
    label: 'Archive',
    icon: ArchiveIcon,
  },
  {
    label: 'Delete',
    icon: DeleteIcon,
  },
];

export const FactoringMenuOtherFactoreWithoutEdit = [
  {
    label: 'Delete',
    icon: DeleteIcon,
  },
  {
    label: 'Archive',
    icon: ArchiveIcon,
  },
];

export const FactoringMenuOptionsWithoutDelete = [
  {
    label: 'Deactivate',
    icon: LinkOffOutlinedIcon,
  },
  {
    label: 'Re-authenticate',
    icon: RefreshOutlinedIcon,
  },
  {
    label: 'Archive',
    icon: ArchiveIcon,
  },
];

export const FactoringMenuOtherFactoreWithoutDelete = [
  {
    label: 'Archive',
    icon: ArchiveIcon,
  },
];

export const FactoringMenuOptionsWithoutEditAndDelete = [
  {
    label: 'Re-authenticate',
    icon: RefreshOutlinedIcon,
  },
  {
    label: 'Archive',
    icon: ArchiveIcon,
  },
];
