import { Box, Typography } from '@mui/material';
import React from 'react';

const ConflictTooltip: React.FC<{ content?: string[] }> = ({ content }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap="2px">
      {content?.map((text) => (
        <Box display={'flex'} gap="6px" key={text} alignItems={'start'}>
          {content?.length > 1 && (
            <Box
              sx={{
                width: '4px',
                height: '4px',
                borderRadius: '50px',
                background: '#FFF',
                marginTop: '6px',
              }}
            ></Box>
          )}
          <Typography
            key={text}
            sx={{
              fontWeight: 500,
              fontSize: '10px',
              lineHeight: '140%',
              display: 'flex',
              alignItems: 'center',
              color: '#FFFFFF',
            }}
          >
            {text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ConflictTooltip;
