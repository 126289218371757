import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { dateTimeFormate } from '../../utils/grid.utils';
import { ILoadsearchTableItem } from '../../loadTypes';

type DropoffProps = ILoadsearchTableItem['Dropoff'];

const Dropoff: FC<DropoffProps> = (Dropoff) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '150%',
          letterSpacing: '0.15px',
        }}
        gutterBottom
      >
        {Dropoff?.city}, {Dropoff?.country}
      </Typography>
      <Typography
        variant="caption"
        gutterBottom
        sx={{ color: 'rgba(0, 17, 34, 0.75)' }}
      >
        <b>{dateTimeFormate(Dropoff?.date)}</b>{' '}
        <span style={{ color: '#000' }}>{Dropoff?.time}</span>
      </Typography>
    </Box>
  );
};

export default React.memo(Dropoff);
