import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import * as yup from 'yup';
import { AXELE_USER_TYPES } from '../../common/Permission';
import { GridColDefSelf, WarningPopoverConfigType } from '../../types';
import { ITabV2 } from '../../ui-kit/components/TabsV2';
import StorageManager from '../../StorageManager/StorageManager';
import {
  dateToHumanableDaysFormat,
  formatPhoneNumber,
  nullableStringYup,
} from '../../utils';
import { UserDetailsTabs } from '../shared/constants';
import { UserCustomStatus } from '../../common/Ui/StatusComponent/StatusComponent';
import { t } from 'i18next';
import { CommonConstants } from '../../locales/en/common/index';
type rolesMapType = { [key: string]: string };

export const userRolesMap: rolesMapType = {
  AC: 'Accountant',
  AD: 'Admin',
  PD: 'Power Dispatcher',
  DI: 'Financial Dispatcher',
  DR: 'Driver',
  NFD: 'Dispatcher',
  ...StorageManager.getItem('rolesList'),
};

export const driverRolesMap: rolesMapType = {
  OWNER_OPERATOR: 'Owner Operator',
  EMPLOYEE: 'Employee',
};

type userTypes = { [key: number]: string };

export const userTypes: userTypes = {
  0: 'Web User',
  1: 'Driver',
  2: 'Both',
};

export const driverStatuses: userTypes = {
  0: 'INACTIVE',
  1: 'ACTIVE',
  2: 'ACTIVE (NON-INVITED)',
  3: 'ACTIVE (INVITED)',
  4: 'ACTIVE (BLOCKED)',
};

export const userColumns: GridColDefSelf[] = [
  {
    flex: 1,
    field: 'name',
    headerName: 'Name',
    sortable: true,
    valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
    permanent: true,
    minWidth: 150,
  },
  {
    flex: 1,
    field: 'status',
    headerName: 'Status',
    minWidth: 210,
    sortable: false,
    renderCell: (params) => <UserCustomStatus status={params.row.status} />,
  },
  {
    flex: 1,
    field: 'type',
    headerName: 'User Type',
    sortable: false,
    minWidth: 100,
    valueGetter: (params) => userTypes[params.row.driveStatus],
  },
  {
    flex: 1,
    field: 'roleCode',
    headerName: 'Web User Role',
    sortable: true,
    minWidth: 130,
    valueGetter: (params) => userRolesMap[params.row.roleCode],
  },
  {
    flex: 1,
    field: 'employmentType',
    headerName: 'Driver Role',
    sortable: true,
    minWidth: 150,
    valueGetter: (params) => driverRolesMap[params.row.employmentType],
  },
  {
    flex: 1,
    field: 'terminal',
    headerName: 'Terminal',
    sortable: false,
    minWidth: 200,
    valueGetter: (params) => params.row.terminal?.companyName,
  },
  {
    flex: 1,
    field: 'phone',
    headerName: 'Phone',
    sortable: false,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams<Date>) => {
      const { row } = params;
      return formatPhoneNumber(row?.phoneData);
    },
  },
  {
    flex: 1,
    field: 'email',
    headerName: 'Email',
    sortable: true,
    minWidth: 250,
  },
  {
    flex: 1,
    field: 'lastLoginDate',
    headerName: 'Last Logged In',
    sortable: true,
    minWidth: 120,
    valueGetter: (params) =>
      dateToHumanableDaysFormat(params.row.lastLoginDate),
  },
];

export const defaultUserDetails = {
  id: null,
  name: '',
  firstName: '',
  lastName: '',
  status: '',
  type: '',
  role: '',
  employmentType: '',
  terminals: [],
  source: '',
  provider: '',
  phone: '',
  email: '',
  lastLoginDate: '',
  dispatcher: null,
  phoneData: {},
  emergencyContact: { phoneData: {} },
};

export const emptyTableInfo = {
  title: 'No users found :’(',
  body: 'Looks like you need to add some user in to the system. Import or get started with adding Users and Drivers.',
};

export interface DetailsTabStripsMenuListInterface extends ITabV2 {
  key: UserDetailsTabs;
}

export const ScheduleAPITextConfig = {
  Payment: {
    entity: 'Driver',
    typeId: 'driverId',
    entityType: 'Payment',
    createAPI: '/web/people/api/v2/paymentterms/scheduledpay',
    deleteAPI: '/web/people/api/v2/paymentterms/scheduledpay',
    updateAPI: '/web/people/api/v2/paymentterms/scheduledpay',
    globalLable: 'scheduledPayment',
  },
  Deduction: {
    entity: 'Driver',
    typeId: 'driverId',
    entityType: 'Deduction',
    createAPI: '/web/people/api/v2/paymentterms/scheduleddeduction',
    deleteAPI: '/web/people/api/v2/paymentterms/scheduleddeduction',
    updateAPI: '/web/people/api/v2/paymentterms/scheduleddeduction',
    globalLable: 'scheduledDeduction',
  },
};

export enum EScheduleType {
  Payment = 'Payment',
  Deduction = 'Deduction',
}

type WarningPopoverConfigPropsType = {
  configName: string;
  dynamicText?: string;
  secondaryDynamicText?: string;
  actionName?: string;
  buttonText?: string;
  data?: any;
  contentRenderer?: () => React.ReactNode;
};

export const getUserWarningPopoverConfig = ({
  configName,
  contentRenderer,
}: WarningPopoverConfigPropsType): WarningPopoverConfigType => {
  const warningPopoverConfigConstant: {
    [key: string]: WarningPopoverConfigType;
  } = {
    CANCEL: {
      title: 'Cancel Invitation',
      backgroundColor: '#2b64cb',
      body: 'Are you sure you want to cancel the user invitation?',
      buttonText: 'Cancel Invitation',
      actionName: 'handleStatusAction',
      cancelText: CommonConstants.cancel,
      data: 'NON_INVITED',
    },
    DEACTIVATE: {
      title: 'Deactivate',
      backgroundColor: '#2b64cb',
      subTitle:
        'Please note that driver will be non-visible for making assignments and the assigned equipment will be un-assigned.',
      body: 'Are you sure you want to deactivate the user?',
      width: '350px',
      buttonText: 'Deactivate',
      cancelText: CommonConstants.cancel,
      actionName: 'handleDeactivateValidationStatus',
      data: 'INACTIVE',
    },
    DEACTIVATE_WARNING: {
      title: t('warning'),
      backgroundColor: '#2b64cb',
      body: 'You cannot deactivate the driver as the driver is either part of a team or there are non-completed assignments.',
      width: '450px',
      buttonText: 'Ok',
    },
    ACTIVATE: {
      backgroundColor: '#2b64cb',
      title: 'Activate',
      body: 'Are you sure you want to activate this user?',
      buttonText: 'Activate',
      cancelText: CommonConstants.cancel,
      actionName: 'handleStatusAction',
      contentRenderer: contentRenderer,
      data: 'INACTIVE',
    },
    SEND: {
      title: 'Send Invitation',
      backgroundColor: '#2b64cb',
      body: 'Are you sure you want to send invitation to a user?',
      buttonText: 'Send',
      cancelText: CommonConstants.cancel,
      actionName: 'handleStatusAction',
      data: 'INVITED',
    },
    DELETE: {
      title: 'Are you sure you want to delete driver?',
      body: 'This will be deleted from all places',
      buttonText: 'Delete',
      cancelText: CommonConstants.cancel,
      actionName: 'handleValidateCanDeleteUser',
    },
    USER_DELETE_WARNING: {
      title: t('warning'),
      backgroundColor: '#2b64cb',
      body: 'User cannot be removed as the profile is connected to either Settlement, Maintenance, or Expense',
      width: '450px',
      buttonText: 'Ok',
    },
    DRIVER_DELETE_WARNING: {
      title: t('warning'),
      backgroundColor: '#2b64cb',
      body: 'Driver cannot be removed as the profile is connected to either Settlement, Maintenance, Expense, Load, Equipment or Driver Group',
      width: '450px',
      buttonText: 'Ok',
    },
  };

  return warningPopoverConfigConstant[configName];
};

export const otherUserResetPasswordValidationSchema = yup.object({
  password: nullableStringYup()
    .required('New Password is a required field')
    .min(6, 'Minimum 6 digits are required.'),
  confirmPassword: nullableStringYup().required(
    'Re-Enter New Password is a required field'
  ),
});

export const sameUserResetPasswordValidationSchema =
  otherUserResetPasswordValidationSchema.concat(
    yup.object().shape({
      oldPassword: nullableStringYup().required(
        'Current Password is a required field'
      ),
    })
  );

export const timezonesMap = [
  {
    value: 'America/New_York',
    key: 'America/New_York',
  },
  {
    value: 'America/Chicago',
    key: 'America/Chicago',
  },
  {
    value: 'America/Denver',
    key: 'America/Denver',
  },
  {
    value: 'America/Los_Angeles',
    key: 'America/Los_Angeles',
  },
  {
    value: 'America/Phoenix',
    key: 'America/Phoenix',
  },
];

export const invoiceManagementOption = [
  {
    value: 'No access to Invoicing',
    key: 1,
  },
  {
    value: 'Allow managing Invoice and Factoring',
    key: 2,
  },
  {
    value: 'Allow managing Factoring ',
    key: 3,
  },
];

export const ADMIN_ROLES = [
  AXELE_USER_TYPES.ADMIN,
  AXELE_USER_TYPES.OWNER_ADMIN,
];
