import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const FieldInfo = ({
  name,
  value,
}: {
  name?: string;
  value?: string;
}) => {
  if (!name || !value) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        variant="h7"
        sx={{
          color: 'text.secondary',
          fontSize: '12px',
          fontWeight: 600,
          paddingRight: '5px',
        }}
      >
        {name}
      </Typography>
      <Typography
        sx={{
          color: 'text.secondary',
          fontSize: '12px',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};
