import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import { SelectChangeEvent } from '@mui/material/Select';
import { ThemeProvider } from '@mui/material/styles';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  IMPORT_EXPENSE_NAME,
  useImport,
} from '../../../../../contexts/ImportContext';
import { theme } from '../Step1/DateFormatSelect';
import { ILocation } from './Entities';
import { defaultFileTypes, fileTypes } from './constants';

const renderGroupName = (name: string) => (
  <ListSubheader key={name}>{name}</ListSubheader>
);

const renderOptions = (
  groupInfo: { [p: string]: string[] },
  entity: string
) => {
  const menuItems: ReactNode[] = [];
  let key = 1;
  Object.keys(groupInfo).forEach((groupName) => {
    if (entity === IMPORT_EXPENSE_NAME) {
      menuItems.push(renderGroupName(groupName));
    }
    groupInfo[groupName].forEach((option) => {
      menuItems.push(
        <MenuItem key={key} value={option} sx={{ textTransform: 'capitalize' }}>
          {option}
        </MenuItem>
      );
      key++;
    });
  });
  return menuItems;
};

export default function FileTypeSelect() {
  const {
    currentEntity: { key, name: entityName },
    selectedFileType,
    setSelectedFileType,
  } = useImport();

  const location = useLocation() as ILocation;

  const handleChange = (event: SelectChangeEvent) => {
    event.stopPropagation();
    setSelectedFileType(event.target.value);
  };

  // effect for setting correct selected file type
  useEffect(() => {
    setSelectedFileType(
      entityName !== IMPORT_EXPENSE_NAME
        ? defaultFileTypes[key]
        : location?.state?.selectedValue || defaultFileTypes[key]
    );
  }, [key, setSelectedFileType, location?.state?.selectedValue]);

  const groupInfo = fileTypes[key];

  const currentTheme = useTheme();

  return (
    <ThemeProvider theme={theme(currentTheme)}>
      <FormControl
        sx={{ minWidth: 250, mr: 1, borderRadius: '10px', width: '100%' }}
        size="small"
      >
        <InputLabel>Select Type</InputLabel>

        <Select
          sx={{
            borderRadius: '10px',
            '.MuiOutlinedInput-input': {
              textTransform: 'capitalize',
            },
          }}
          value={selectedFileType}
          label="Select The Date Format"
          onChange={handleChange}
        >
          {renderOptions(groupInfo, entityName)}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
