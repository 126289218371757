import { useFormContext } from 'react-hook-form';

import { Divider, Grid, Typography } from '@mui/material';
import { StaticDataType } from '../../../../contexts/StaticDataContext';
import {
  PayStatementData,
  PayStatementsEntityTypeEnum,
  PayStatementsTypeEnum,
  PaymentDetails,
} from '../../../../models';
import NonTripPaymentForm from './NonTripPaymentForm';
import TripPaymentForm from './TripPaymentForm';

type propsType = {
  staticData: StaticDataType;
  payStatementData: PayStatementData;
  entityType: PayStatementsEntityTypeEnum;
  onCheckboxChange: (
    checked: boolean,
    ids: number[],
    payment?: PaymentDetails,
    paymentListNmae?: string
  ) => void;
};

function AddPendingPaymentForm({
  staticData,
  payStatementData,
  entityType,
  onCheckboxChange,
}: propsType) {
  const { watch } = useFormContext();

  const [tripPaymentsList, fuelNonTripPaymentsList, otherNonTripPaymentsList] =
    watch([
      'tripPaymentsList',
      'fuelNonTripPaymentsList',
      'otherNonTripPaymentsList',
    ]);

  return (
    <Grid container sx={{ paddingTop: '16px' }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        sx={{ paddingTop: '32px' }}
      >
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          Payment Per Trip
        </Typography>
      </Grid>
      {!!tripPaymentsList?.content?.length &&
        tripPaymentsList?.content.map((item: any, index: number) => (
          <TripPaymentForm
            key={item.id}
            id={index}
            onCheckboxChange={onCheckboxChange}
            showCheckbox
            entityType={entityType}
          />
        ))}

      <Divider sx={{ width: 'inherit', marginTop: '16px' }} />
      {payStatementData.payStatementType !==
        PayStatementsTypeEnum.dispatcher && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            sx={{ paddingTop: '32px' }}
          >
            <Grid item>
              <Typography variant="h7" sx={{ color: 'primary.main' }}>
                Fuel Payments
              </Typography>
            </Grid>
          </Grid>
          {!!fuelNonTripPaymentsList?.content?.length
            ? fuelNonTripPaymentsList?.content.map(
                (item: any, index: number) => (
                  <NonTripPaymentForm
                    isFuelPayment
                    staticData={staticData}
                    key={`${item.id}`}
                    id={index}
                    onCheckboxChange={onCheckboxChange}
                    entityType={entityType}
                    isInEditMode={false}
                    showCheckbox
                  />
                )
              )
            : null}
          <Divider sx={{ width: 'inherit', marginTop: '16px' }} />
        </>
      )}
      {payStatementData.payStatementType !==
        PayStatementsTypeEnum.dispatcher && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            sx={{ paddingTop: '32px' }}
          >
            <Grid item>
              <Typography variant="h7" sx={{ color: 'primary.main' }}>
                Other Payments
              </Typography>
            </Grid>
          </Grid>
          {!!otherNonTripPaymentsList?.content?.length
            ? otherNonTripPaymentsList?.content.map(
                (item: any, index: number) => (
                  <NonTripPaymentForm
                    staticData={staticData}
                    key={`${item.id}`}
                    id={index}
                    onCheckboxChange={onCheckboxChange}
                    entityType={entityType}
                    isInEditMode={false}
                    showCheckbox
                  />
                )
              )
            : null}
          <Divider sx={{ width: 'inherit', marginTop: '16px' }} />
        </>
      )}
    </Grid>
  );
}

export default AddPendingPaymentForm;
