import { useState, useReducer } from 'react';
import { Box } from '@mui/system';
import { FormComponent } from './DriverAssignmentForm';
import { Tractor } from '../../../../../subPages/tractors/models/Tractor';
import { Trailer } from '../../../../../subPages/trailers/models/Trailer';
import AssignmentFormWrapper from '../../../../LoadTabPanel/tabs/Routes/components/AssignmentFormWrapper';
import WarningWindowLoad from './WarningWindowLoad';
import WarningWindow from './WarningWindow';
import { t } from 'i18next';
interface DriverAssignmentProps {
  onClose: () => void;
  onAssign: (driverGroupId: string) => void;
  data: Tractor | Trailer;
  entity?: 'TRACTOR' | 'TRAILER';
}
interface IInitialState {
  primaryDriver: string;
  primaryWarnings: string[];
  secondaryDriver: string;
  secondaryWarnings: string[];
}

const initialState = {
  primaryDriver: '',
  primaryWarnings: [],
  secondaryDriver: '',
  secondaryWarnings: [],
};

const reducer = (
  state: IInitialState,
  action: { type: string; value: string }
) => {
  if (action.type === 'setPrimaryDriver')
    return { ...state, primaryDriver: action.value };
  if (action.type === 'setPrimaryWarnings')
    return { ...state, primaryWarnings: action.value };
  if (action.type === 'setSecondaryDriver')
    return { ...state, secondaryDriver: action.value };
  if (action.type === 'setSecondaryWarnings')
    return { ...state, secondaryWarnings: action.value };
};

export default function DriverAssignmentToEntity({
  onClose,
  onAssign,
  data,
  entity,
}: DriverAssignmentProps): JSX.Element {
  const [warningsLoad, setWarningsLoad] = useState<boolean>(false);
  const [warningsDriver, dispatch] = useReducer(reducer, initialState);
  const [initialData] = useState({ driverGroup: null, prevLoad: null });

  const handleWarningsLoad = (warnings: boolean) => {
    setWarningsLoad(warnings);
  };

  const handleWarningsDriver = (type: string, value: any) => {
    dispatch({ type, value });
  };

  // @TODO: Anna make separate component
  return (
    <AssignmentFormWrapper
      onAction={onAssign}
      onClose={onClose}
      data={initialData}
      title={t('assignDriver')}
      actionLabel="Assign"
    >
      <>
        <Box sx={{ marginBottom: '16px' }}>
          <WarningWindow warningsDriver={warningsDriver} />
        </Box>
        <FormComponent
          entityData={data}
          entity={entity}
          handleWarningsLoad={handleWarningsLoad}
          handleWarningsDriver={handleWarningsDriver}
        />
        {warningsLoad && (
          <Box sx={{ marginBottom: '16px' }}>
            <WarningWindowLoad />
          </Box>
        )}
      </>
    </AssignmentFormWrapper>
  );
}
