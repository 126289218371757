import Box from '@mui/material/Box';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { subscriptionService } from '../../api';
import AuthContext from '../../globalContexts/AuthContext';
import { CancelSubscriptionRequest, QueryParams } from '../../models';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../ui-kit/components/DeletePopup';
import IconsComponent from '../../ui-kit/components/IconsComponent';
import { currencyDisplay } from '../../utils/grid.utils';
import { NoteIcon } from './constant';
import { styles } from './styles';

type InvoiceCardProps = { data: any; getSubscription: () => void };

const InvoiceCard = ({ data, getSubscription }: InvoiceCardProps) => {
  const {
    current_period_ends_at = '',
    current_period_started_at = '',
    state,
    current_billing_amount_in_cents = 0,
    cancel_at_end_of_period = false,
  } = data;
  const { logoutUser } = useContext(AuthContext);
  const [statusInvoice, setStatusInvoice] = useState<string>('');
  const [daysRemaining, setDaysRemaining] = useState<number>(0);
  const [openResubscribe, setOpenResubscribe] = useState<boolean>(false);
  const [redirectTo, setRedirectTo] = useState<string>('');

  useEffect(() => {
    let status = '';
    const diffDays = moment()
      .startOf('day')
      .diff(moment(current_period_ends_at).startOf('day'), 'days');
    if (state === 'past_due') status = 'duePast';
    else if (diffDays >= 0) status = 'open';
    else status = 'upcoming';
    setStatusInvoice(status);
    const suspensionDate = moment(current_period_started_at).startOf('day');
    setDaysRemaining(suspensionDate.diff(moment().startOf('day'), 'days') + 5);
  }, [current_period_ends_at]);

  const getDate = (date: string, diffAdd: number) => {
    if (diffAdd) return moment(date).add(diffAdd, 'days').format('ll');
    return moment(date).format('ll');
  };

  const getProforma = async () => {
    const query = new QueryParams();
    query.status = 'draft';
    const proforma = await subscriptionService.getProforma(query);
    if (proforma.public_url) {
      setRedirectTo(proforma.public_url);
    }
  };

  useEffect(() => {
    getProforma();
  }, []);

  const statusColor = statusInvoice === 'duePast' ? 'error' : 'success';

  return (
    <Box
      sx={(theme) => ({
        ...styles.infocontainer,
        background: cancel_at_end_of_period
          ? theme.palette.grey.a200
          : theme.palette[statusColor].background,
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={(theme) => ({
            ...styles.infoPay,
            background: cancel_at_end_of_period
              ? theme.palette.grey.a300
              : theme.palette[statusColor].outlinedHoverBackground,
            color: cancel_at_end_of_period
              ? theme.palette.grey.a700
              : theme.palette[statusColor].dark,
          })}
        >
          <NoteIcon />
        </Box>
        <Box sx={styles.infobox}>
          <Box sx={styles.infostatus}>
            {cancel_at_end_of_period
              ? 'Canceled Subsription'
              : statusInvoice === 'upcoming'
              ? 'Upcoming Invoice'
              : 'Open Invoice'}
          </Box>
          <Box
            sx={(theme) => ({
              ...styles.infoState,
              ...theme.typography.h7,
            })}
          >
            {cancel_at_end_of_period
              ? 'Cancellation date: ' + getDate(current_period_ends_at)
              : statusInvoice === 'upcoming'
              ? ''
              : 'Invoice Created on ' +
                getDate(
                  state === 'past_due'
                    ? current_period_started_at
                    : current_period_ends_at
                )}
          </Box>
        </Box>
      </Box>
      {cancel_at_end_of_period ? (
        <Box
          sx={{
            display: 'flex',
            marginTop: '32px',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={(theme) => ({
              ...theme.typography.h7,
              color: theme.palette.grey.a800,
            })}
          >
            You can resubscribe your account anytime until{' '}
            {getDate(current_period_ends_at, -1)}, at 11:59pm.
          </Box>
          <Box sx={{ marginLeft: '10px' }}>
            {openResubscribe && (
              <DeletePopup
                buttonText="Yes"
                backgroundColor="uncategorized.popup"
                buttonTextColor="primary.main"
                open={true}
                title="Resubscribe Account?"
                body="Are you sure you want to resubscribe your account?"
                onAction={async () => {
                  const query = new CancelSubscriptionRequest();
                  query.delete = true;
                  const response = await subscriptionService.payAndCancel(
                    query
                  );
                  if (response) {
                    getSubscription();
                    // logoutUser();
                  }
                  setOpenResubscribe(false);
                }}
                onClose={() => setOpenResubscribe(false)}
              />
            )}
            <ButtonImproved
              variant="contained"
              label="Resubscribe"
              size="small"
              onClick={() => setOpenResubscribe(true)}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={(theme) => ({
              height: '70px',
              ...theme.typography.h3,
              color: theme.palette[statusColor].dark,
            })}
          >
            {currencyDisplay(current_billing_amount_in_cents / 100)}
            <Box
              sx={{ display: 'contents' }}
              onClick={() =>
                redirectTo.length && window.open(redirectTo, '_blank').focus()
              }
            >
              <IconsComponent
                source="MUIcons"
                iconName="Launch"
                styleProps={styles.launchIcon}
              />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              ...theme.typography.h7,
              color:
                statusColor === 'error'
                  ? theme.palette[statusColor].dark
                  : 'text.primary',
              height: '24px',
            })}
          >
            {statusInvoice === 'upcoming'
              ? 'Invoicing Date: ' + getDate(current_period_ends_at)
              : statusInvoice === 'open'
              ? 'Payment Due Date: ' + getDate(current_period_ends_at, 5)
              : 'Past Due Payment Date: ' +
                getDate(current_period_started_at, 5)}
          </Box>
          {statusInvoice === 'duePast' && (
            <Box
              sx={(theme) => ({
                ...theme.typography.h7,
                color:
                  statusColor === 'error'
                    ? theme.palette[statusColor].dark
                    : 'text.primary',
                height: '24px',
              })}
            >
              Account Suspension in {daysRemaining} days
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default InvoiceCard;
