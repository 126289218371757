import React, { SyntheticEvent } from 'react';
import {
  StyleFloatingTruckIcon,
  StyleFloatingTruckIconRoot,
} from '../../TripPlanV3/styles';
import { StopSolutionV3Prop } from '../../TripPlanV3/types';
import { TruckIconSection } from './TruckIconSection';

export interface FloatingTruckIconSectionSectionProps {
  stopSolution: StopSolutionV3Prop;
  expanded?: boolean;
  showFloatingActionButton?: boolean;
}

export const FloatingTruckIconSection = ({
  stopSolution,
  expanded,
  showFloatingActionButton,
}: FloatingTruckIconSectionSectionProps): JSX.Element => {
  return (
    <StyleFloatingTruckIconRoot
      expanded={expanded}
      showFloatingActionButton={showFloatingActionButton}
    >
      <StyleFloatingTruckIcon
        onClick={(event: SyntheticEvent<Element, Event>) => {
          event.stopPropagation();
        }}
      >
        <TruckIconSection stopSolution={stopSolution} />
      </StyleFloatingTruckIcon>
    </StyleFloatingTruckIconRoot>
  );
};
