import { mapValues } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import { array, date, mixed, object, string } from 'yup';
import { expenceService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { VendorNamesListRequest } from '../../../../../models';
import {
  IMaintenanceHistory,
  MaintenanceFormData,
} from '../../../../../models/DTOs/maintenance/maintenanceHistory/Model';
import {
  nullableIntegerRangeValidation,
  nullableNumberYup,
} from '../../../../../utils';
import { EquipmentType } from '../../../constants/constants';

export const parseInitialValueToFormProps = (
  initialValue: Partial<IMaintenanceHistory>
): Partial<MaintenanceFormData> => {
  const initFile = () => {
    if (initialValue.attachments?.[0]) {
      const file: File = new File([], initialValue.attachments[0].fileName);
      const list: DataTransfer = new DataTransfer();
      list.items.add(file);
      return list.files;
    }
    return null;
  };
  const catagoryObject = initialValue?.expenseCategoryId
    ? {
        id: initialValue?.expenseCategoryId,
        itemCode: initialValue?.expenseCategoryKey,
        itemName: initialValue?.expenseCategoryName,
      }
    : null;

  return {
    id: initialValue?.id,
    equipmentType: initialValue.equipmentType || EquipmentType.Tractor,
    schedule: false,
    expense: initialValue.expense?.toString() || '',

    maintenanceItems: initialValue.historyMaintenanceItems
      ? initialValue.historyMaintenanceItems.map(
          ({ id, itemCode, itemName, iconName }) => {
            return {
              label: itemName,
              value: id,
              itemCode,
              iconName,
            };
          }
        )
      : [],
    maintenanceVendor:
      initialValue.maintenanceVendor && initialValue.maintenanceVendor.id
        ? {
            label: initialValue.maintenanceVendor.name,
            value: initialValue.maintenanceVendor.id,
          }
        : undefined,
    equipmentItem: initialValue.equipmentId
      ? {
          value: initialValue.equipmentId,
          label:
            initialValue?.equipmentName || initialValue.equipmentId.toString(),
          terminalId: initialValue.terminalId || '',
          terminalName: initialValue.terminalName || '',
        }
      : undefined,
    completedDate: initialValue.completedDate
      ? moment(initialValue.completedDate)
      : undefined,
    notes: initialValue.notes || '',
    odometer: initialValue.odometer?.toString() || '',
    terminalId: initialValue.terminalId || '',
    terminalName: initialValue.terminalName || '',
    receipt: initFile() || null,
    fileId: initialValue?.attachments?.[0]?.documentId,
    createExpense: initialValue?.createExpense || false,
    expenseId: initialValue?.expenseId,
    expenseSeqNumber: initialValue?.expenseSeqNumber,
    categoryObj: initialValue?.categoryObj || catagoryObject,
  };
};

export const getVendorNamesList = async (name: string, pageNumber: number) => {
  const request = new VendorNamesListRequest(pageNumber, 25, name);
  const response = await expenceService.getVendorNamesList(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    const content = response?.content?.map((elem) => ({
      ...elem,
      label: elem.name,
      value: elem.id,
    }));
    const result = { ...response, content: content };
    return result;
  }
};

export const validationSchema = yup
  .object()
  .shape({
    expense: yup
      .number()
      // .transform((value) => (isNaN(value) ? undefined : +value))
      // .test(
      //   'is-decimal',
      //   'Amount cannot exceed 4 digits after decimal point',
      //   (val?: number) =>
      //     valueval === undefined || /^-?\d+(\.\d{0,4})?$/.test(noExponents(val))
      // )
      .positive('Amount must be a positive number')
      .nullable(true)
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .min(-0.0, 'The minimum should be 0.0001')
      .max(99999.9999, 'The maximum should be 99,999.9999')
      .when(['createExpense', 'expenseId'], {
        is: (createExpense: boolean, expenseId: string) =>
          createExpense || expenseId,
        then: nullableNumberYup().required('Amount is required'),
      }),
    maintenanceItems: array().min(1, 'Maintenance item is required'),
    equipmentItem: object().nullable().required('Equipment is required'),
    odometer: nullableIntegerRangeValidation(1, 1000000),
    completedDate: date()
      .required('Completion Date is required')
      .max(moment(), 'Completion date cannot be in future')
      .typeError('Invalid date'),
    schedules: mixed().when(
      ['schedule', 'equipmentType'],
      (schedule, equipmentType, schema, context) => {
        return !schedule
          ? schema
          : object(
              mapValues(context.value, () => {
                return object().shape({
                  nextDate:
                    equipmentType === EquipmentType.Tractor ||
                    equipmentType === EquipmentType.Trailer
                      ? date()
                          .required('Next date is required')
                          .test(
                            '>completedDate',
                            'Must be greater than completion date',
                            (value, context) =>
                              !!(
                                value &&
                                value > context.from[2].value.completedDate
                              )
                          )
                          .typeError('Invalid date')
                      : mixed(),
                });
              })
            );
      }
    ),
    notes: string().max(255, 'Cannot be longer than 255 characters'),
    categoryObj: object().when(['createExpense', 'expenseId'], {
      is: (createExpense: boolean, expenseId: string) =>
        createExpense || expenseId,
      then: object().nullable().required('Expense Catagory is required'),
    }),
  })
  .required();
