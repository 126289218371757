import { CountryCode, Address } from './smallHelperTypes';
import { PhoneType } from '../../../types/index';

export class UserBaseDetails {
  id!: string;
  name!: string;
  email!: string;
  mobileno!: string;
}

export class ContactPerson {
  firstName!: string;
  lastName!: string;
  phoneData!: PhoneType;
  countryCode!: CountryCode;
  extension!: string;
  email!: string;
  fax!: string;
  faxCountryCode!: CountryCode;
  faxExtension!: string;
  address!: Address;
  addressData!: Address;
}
