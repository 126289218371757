import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { AXELE_PERMISSION_TYPE, Permission } from '../common/Permission';
import { getHasPermission } from '../common/Permission/utils/helperUtils';
import TabsV2, { ITabV2 } from '../ui-kit/components/TabsV2';
import EDIActivation from '../views/settings/integrations/EDIActivation/EDIActivation';
import EldIntegration from '../views/settings/integrations/EldIntegration';
import { AccountingPopupEnum } from '../views/settings/integrations/Finance/AccountingPop/constants';
import Finance from '../views/settings/integrations/Finance/finance';
import LoadTenders from '../views/settings/integrations/LoadTender/LoadTender';
import LoadboardIntegration from '../views/settings/integrations/Loadboard';
import { FuelCards } from '@/views/settings/integrations/FuelCard';

const Integration = () => {
  const hasEDIPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.INTEGRATIONS_ELD_VIEW],
  });
  const hasFactorePermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.INTEGRATIONS_FACTORING_VIEW],
  });
  const hasQuickBookPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.INTEGRATIONS_ACCOUNTING_VIEW],
  });
  const hasLoadboardsPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.INTEGRATIONS_LOADBOARD_VIEW],
  });
  let integrations = [
    hasEDIPermission && {
      label: 'ELDs',
      key: 'eld',
      iconName: 'SendOutlined',
      source: 'MUIcons',
    },
    hasLoadboardsPermission && {
      label: 'Loadboards',
      key: 'loadboard',
      iconName: 'ViewListOutlined',
      source: 'MUIcons',
    },

    (hasFactorePermission || hasQuickBookPermission) && {
      label: 'Finance',
      key: 'finance',
      iconName: 'MonetizationOnOutlined',
      source: 'MUIcons',
    },
    {
      label: 'Load Tenders',
      key: 'loadTenders',
      iconName: 'ViewListOutlined',
      source: 'MUIcons',
    },
    {
      label: 'Fuel Card',
      key: 'fuelCards',
      iconName: 'LocalGasStationOutlined',
      source: 'MUIcons',
    },
  ];
  useEffect(() => {
    const elmId = sessionStorage.getItem('integrationTab');
    if (elmId) {
      setSelectedTab(elmId);
    }
  }, []);

  const onTabChanged = (tab: ITabV2) => {
    const elmId = tab.key;
    sessionStorage.setItem('integrationTab', elmId);
    setSelectedTab(elmId);
  };

  const pageStyle = {
    background: '#F7F7F7',
    borderRadius: '14px 14px 0 0',
  };

  const navStyle = {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    pr: '17px',
  };

  const mainContainerStyle = {
    background: '#FFFFFF',
    borderRadius: '24px',
    p: '0',
    overflow: 'auto',
    m: '30px 0 0 0',
    height: '100%',
  };
  integrations = integrations.filter((element) => {
    if (Object.keys(element).length !== 0) {
      return true;
    }
    return false;
  });

  const [selectedTab, setSelectedTab] = useState(integrations[0].key);

  return (
    <Grid
      container
      flexDirection="column"
      flexWrap="nowrap"
      width="100%"
      sx={pageStyle}
      height="100%"
    >
      <Grid item id="topPanel">
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid id="tabstrip" item xs={6}>
            <TabsV2
              keyName="key"
              tabs={integrations}
              selectedTab={selectedTab}
              setSelectedTab={onTabChanged}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item id="content" sx={mainContainerStyle}>
        {selectedTab === AccountingPopupEnum.ELD && (
          <Permission includes={[AXELE_PERMISSION_TYPE.INTEGRATIONS_ELD_VIEW]}>
            <EldIntegration />
          </Permission>
        )}
        {selectedTab === AccountingPopupEnum.LOADBOARD && (
          <LoadboardIntegration />
        )}
        {selectedTab === AccountingPopupEnum.FINANCE && <Finance />}
        {selectedTab === AccountingPopupEnum.EDIACTIVATION && <EDIActivation />}
        {selectedTab === AccountingPopupEnum.LOADTENDERS && <LoadTenders />}
        {selectedTab === AccountingPopupEnum.FUEL_CARDS && <FuelCards />}
      </Grid>
    </Grid>
  );
};

export default Integration;
