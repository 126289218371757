import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import Checkbox from '../../../../../../common/Ui/Checkbox';
import CurrencyFieldControl from '../../../../../../common/Ui/CurrencyField/CurrencyFieldControl';
import FileUploadUpdated from '../../../../../../common/Ui/FileUpload/indexUpdated';
import FormDatePickerWithoutTimeZone from '../../../../../../common/Ui/FormDatePickerWithoutTimeZone/FormDatePickerWithoutTimeZone';
import { SingleAutocompleteForm } from '../../../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../../../common/Ui/TextField/TextField';
import {
  getStateOptions,
  getStaticListOptions,
} from '../../../../utils/filters.utils';
import { CarrierNumberFormatProps } from '../../../../constants/constants';
const defaultTextFieldProps = {
  style: { marginBottom: '20px' },
};

export default function AddInsuranceFormForm() {
  const { control } = useFormContext();

  return (
    <Grid spacing={1} container>
      <SingleAutocompleteForm
        name={`type`}
        label="Insurance Type"
        fieldName="value"
        getOptions={async (searchString: string, pageNumber: number) => {
          return getStaticListOptions(
            searchString,
            pageNumber,
            'insuranceType'
          );
        }}
        control={control}
        sizes={{ xs: 6 }}
        required
      />
      <CurrencyFieldControl
        control={control}
        name="amount"
        label="Insurance Amount"
        sizes={{
          xs: 6,
        }}
        required
        {...defaultTextFieldProps}
        numberFormatProps={CarrierNumberFormatProps}
      />
      <FormDatePickerWithoutTimeZone
        control={control}
        disabledText
        name={'expDate'}
        label="Expiration"
        sizes={{ xs: 6 }}
        wrapperStyles={{ marginBottom: 0 }}
      />
      <TextField
        control={control}
        name="provider"
        label="Provider"
        sizes={{
          xs: 6,
        }}
        required
        {...defaultTextFieldProps}
      />
      <TextField
        control={control}
        name="producer"
        label="Producer"
        sizes={{
          xs: 12,
        }}
        {...defaultTextFieldProps}
      />
      <SingleAutocompleteForm
        name={`state`}
        label="State"
        fieldName="value"
        options={getStateOptions()}
        control={control}
        sizes={{ xs: 6 }}
      />
      <TextField
        control={control}
        name="policyNumber"
        label="Policy #"
        sizes={{
          xs: 6,
        }}
        onlyNumbers={true}
        {...defaultTextFieldProps}
      />
      <Grid xs={12} container>
        <Checkbox
          control={control}
          label={'Refrigeration Breakdown Coverage'}
          name={'fridgeBreakCoverage'}
          {...defaultTextFieldProps}
        />
      </Grid>
      <TextField
        sizes={{ xs: 12 }}
        control={control}
        label={'Notes'}
        name={'notes'}
        {...defaultTextFieldProps}
      />
      <Box sx={{ p: 2, pl: 1, pr: 1, width: '100%' }}>
        <FileUploadUpdated
          name={`assignedDocuments`}
          label="Upload"
          fileId={'UploadFilesInsuranceForm'}
          control={control}
          sizes={{ xs: 12 }}
        />
      </Box>
    </Grid>
  );
}
