import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import NoData from '../../component/NoData';
import { NotesContent } from './component/NotesContent';
import {
  NotesContext,
  defaultData,
  insertEditMode,
  makeIndexEdit,
} from './constants/constant';
import {
  createNotes,
  deleteNotes,
  getNotesListByLoadId,
} from './constants/helper';
import {
  DefaultDataTypes,
  ListTypes,
  NotesContextType,
  NotesTypes,
  OperationType,
} from './constants/interface';
import { styles } from './styles/styles';

export const Notes = ({ loadId = '', onHold = false }: NotesTypes) => {
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [noData, setNoData] = useState(false);
  const [data, setData] = useState<DefaultDataTypes>(defaultData);
  const [list, setList] = useState<ListTypes[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<ListTypes>({});

  useEffect(() => {
    getNotesList();
  }, [loadId, onHold]);

  const getNotesList = async () => {
    const getRes = await getNotesListByLoadId(loadId);
    setNoData(!getRes);
    const temp = insertEditMode(getRes);
    setList(temp);
    setDeleteOpen(false);
    setDeleteItem({});
    setOpenCreate(false);
    setError(null);
    setData(defaultData);
  };

  const selectEdit = (
    item: ListTypes,
    index: number,
    operation: OperationType
  ) => {
    if (operation == 'edit') {
      setOpenCreate(false);
      setError(null);
      const temp = makeIndexEdit(list, index);
      setList(temp);
      const tempData = {
        notesText: item.noteDesc,
        shareDriver: item.shareWithDriver,
        attachment: item.attachments,
      };
      setData(tempData);
    } else {
      setDeleteOpen(true);
      setDeleteItem(item);
    }
  };

  const checkBoxChange = (value = false) => {
    const tempData = { ...data, shareDriver: value };
    setData(tempData);
  };

  const onNotesChange = (event: any, id: string) => {
    const tempData = { ...data, [id]: event.target.value };
    setData(tempData);
  };

  const performDelete = async () => {
    setDeleteOpen(false);
    await deleteNotes(deleteItem);
    await getNotesList();
  };

  const onCancel = (mode: string) => {
    if (mode == 'create') {
      setOpenCreate(false);
    } else {
      const temp = insertEditMode(list);
      setList(temp);
    }
  };

  const onSave = async (
    mode: string,
    item: ListTypes = {},
    isAttachmentIncluded: boolean,
    previousData: any
  ) => {
    const tempError = data?.notesText?.length == 0 ? 'Required' : null;
    setError(tempError);
    if (!tempError) {
      await createNotes(
        mode,
        data,
        loadId,
        item,
        isAttachmentIncluded,
        previousData
      );
      await getNotesList();
    }
  };

  const onFileChange = (file: File[]) => {
    const tempData = { ...data, attachment: file };
    setData(tempData);
  };

  const onDeleteAttachment = (item: ListTypes = {}) => {
    const tempData = { ...data };
    if (item.attachments) tempData.deleteDocument = true;
    delete tempData.attachment;
    setData(tempData);
  };

  const onCreate = () => {
    setData(defaultData);
    setError(null);
    setOpenCreate(true);
    const temp = insertEditMode(list);
    setList(temp);
  };

  const notesContext: NotesContextType = {
    checkBoxChange: checkBoxChange,
    data: data,
    openCreate: openCreate,
    onNotesChange: onNotesChange,
    list: list,
    selectEdit: selectEdit,
    onCancel: onCancel,
    onCreate: onCreate,
    onSave: onSave,
    error: error,
    onFileChange: onFileChange,
    deleteOpen: deleteOpen,
    setDeleteOpen: setDeleteOpen,
    performDelete: performDelete,
    onDeleteAttachment: onDeleteAttachment,
  };

  if (noData) {
    return <NoData />;
  }

  return (
    <Box sx={styles.notesContainer}>
      <NotesContext.Provider value={notesContext}>
        <NotesContent />
      </NotesContext.Provider>
    </Box>
  );
};
