import { SxProps } from '@mui/material';
import { FC } from 'react';
import { AssignDocumentRequest } from '../../../models';
import { PhoneDTO } from '../../../models/DTOs/Contacts/Contacts';
import { Model } from '../../../models/common/Model';
import { FileType } from '../../../types';
import { IRelativeDateRangeOption } from '../../../ui-kit/components/RelativeDateRange';
import { ITabV2 } from '../../../ui-kit/components/TabsV2';
import { carrierFieldsConfig } from './constants';

export class CarrierContactSummary extends Model {
  name: string;
  description: string;
  phoneData: PhoneDTO;
  email: string;
  isPrimary: boolean;

  constructor(dto: any) {
    super(dto);
    this.name = dto.name;
    this.description = dto.description;
    this.phoneData = dto.phoneData;
    this.email = dto.email;
    this.isPrimary = dto.isPrimary;
  }
}
export class CarrierInsuranceSummary extends Model {
  constructor(dto: any) {
    super(dto);
  }
}

export interface ICarrierAutocompleteOption {
  value: string;
  key: string;
}

export interface ICarrierFiltersFormConfig {
  key?: number;
  name?: string;
  fieldName?: string;
  isHyperLink?: boolean;
  getData?: (searchString: string, pageNumber: number) => any;
  label: string;
  customStyles?: SxProps;
  PopperSxProps?: SxProps;
  type:
    | 'MultipleAutocomplete'
    | 'SingleAutocomplete'
    | 'DateRangePicker'
    | 'RelativeDateRange'
    | 'Custom';
  default: boolean;
  options?: ICarrierAutocompleteOption[] | IRelativeDateRangeOption[];
  customComponent?: FC<any>;
}

export interface ICarrierFilters {
  [carrierFieldsConfig.name.fieldFilterName]?: ICarrierAutocompleteOption[];
  [carrierFieldsConfig.status.fieldFilterName]?: ICarrierAutocompleteOption[];
  [carrierFieldsConfig.mode.fieldFilterName]?: ICarrierAutocompleteOption[];
  [carrierFieldsConfig.mc.fieldFilterName]?: ICarrierAutocompleteOption[];
  [carrierFieldsConfig.dot.fieldFilterName]?: ICarrierAutocompleteOption[];
  [carrierFieldsConfig.scac.fieldFilterName]?: ICarrierAutocompleteOption[];
  [carrierFieldsConfig.state.fieldFilterName]?: ICarrierAutocompleteOption[];
  [carrierFieldsConfig.contact.fieldFilterName]?: ICarrierAutocompleteOption[];
  sort?: string;
}

export enum ECarrierPanelTab {
  OVERVIEW = 'OVERVIEW',
  CONTRACT = 'CONTRACT',
  DOCUMENT = 'DOCUMENT',
}

export interface ICarrierPanelTab extends ITabV2 {
  key: ECarrierPanelTab;
}

export type CarrierDocumentsType =
  | ICarrierAssignDocument[]
  | AssignDocumentRequest[]
  | FileType[]
  | File[]
  | null;
export interface ICarrierInsuranceFormData {
  id?: string;
  seqNumber?: string;
  type: ICarrierAutocompleteOption;
  carrierId: string;
  fridgeBreakCoverage: boolean;
  amount: number;
  expDate: string;
  provider: string;
  producer: string;
  policyNumber: string;
  state: ICarrierAutocompleteOption;
  notes: string;
  assignedDocuments?: CarrierDocumentsType;
}

export interface ICarrierAssignDocument {
  organizationId: number;
  ownerId: number;
  ownerName: string;
  documentId: string;
  fileName: string;
  description: string;
  fileSize: number;
  permission: string;
  uploadDate: string;
  documentTypeId: string;
  documentType: string;
  attachedEntities: Array<{
    type: string;
    properties: Array<{
      id: string;
      title: any;
    }>;
  }>;
  id: string;
}

export enum ECarrierStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ECarrier3DotMenuType {
  DELETE = 'DELETE',
  ACTIVE = ECarrierStatus.ACTIVE,
  INACTIVE = ECarrierStatus.INACTIVE,
}
