import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import TextFieldForm from '../../../../common/Ui/TextField/TextFieldFormDeprecated';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TerminalController from '../../../../views/settings/terminals/TerminalController';
import { ICreateDriverProps } from '../../../../types/ELDManageTypes';

export const CreateDriverForm = ({ formData }: ICreateDriverProps) => {
  const { control, getValues } = useFormContext();
  const [firstname, lastname, email, terminalId] = getValues([
    'firstname',
    'lastname',
    'email',
    'id',
    'terminalId',
  ]);

  const { terminals } = TerminalController.instance();

  return (
    <Box>
      {formData.length > 1 ? (
        <>
          {terminals && terminals.length > 0 && (
            <Box>
              <SingleAutocompleteForm
                control={control}
                name={'terminalId'}
                fieldName={'name'}
                getOptions={() => new Promise((res) => res(terminals))}
                label={'Terminal'}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextFieldForm
              control={control}
              name="firstname"
              label="First Name"
              defaultValue={firstname}
            />
            <TextFieldForm
              control={control}
              name="lastname"
              label="Last Name"
              defaultValue={lastname}
            />
          </Box>
          <Box sx={{ pt: '32px' }}>
            <TextFieldForm
              control={control}
              name="email"
              label="Email"
              defaultValue={email}
            />
          </Box>
          {terminals && terminals.length > 0 && (
            <Box sx={{ pt: '32px' }}>
              <SingleAutocompleteForm
                control={control}
                name={'terminalId'}
                fieldName={'name'}
                getOptions={() => new Promise((res) => res(terminals))}
                label={'Terminal'}
                defaultValue={terminalId}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
