import { Autocomplete } from '@components/ui-kit/autocomplete';
import { IconButton } from '@components/ui-kit/icon-button';
import { MenuItem } from '@components/ui-kit/menu-item';
import { Select } from '@components/ui-kit/select';
import { TextField } from '@components/ui-kit/text-field';
import {
  DeleteOutline,
  ExpandLessRounded,
  ExpandMore,
} from '@mui/icons-material';
import { Box, InputAdornment, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { ILoadStaticContent } from '../../../../../../models';
import { RootStoreInstence } from '../../../../../../store/root-store/rootStateContext';
import { QUERY_KEYS } from '../../../constants/queryKeys';
import { MAX_COMMODITY_NAME_LENGTH } from '../../../schema';
import { createLoadService } from '../../../services/createLoad.service';
import { loadFormService } from '../../../services/loadForm.service';
import { CommoditiesTypeResDTO } from '../../../types/requests';
import { CommoditySummary } from './commodity-summary';

interface CommodityProps {
  remove: (index: number) => void;
  updateTotalWeight: () => void;
  index: number;
  commoditiesLength: number;
}

const CommodityItem: React.FC<CommodityProps> = ({
  remove,
  index,
  updateTotalWeight,
  commoditiesLength,
}) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const toggleIsDetailsExpanded = () => {
    setIsDetailsExpanded((state) => (state = !state));
  };
  const queryClient = useQueryClient();
  const loadStaticData = queryClient.getQueryData<ILoadStaticContent>(
    QUERY_KEYS.loadStaticData
  );
  const {
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useFormContext();
  const commodities = watch('load.commodities');
  const [dimensionalWeight, setDimensionalWeight] = useState(0);
  const handleSetDimensionalWeightClick = (e: any) => {
    // Handle click of "Use Dimensional Weight" Checkbox
    const isChecked = e?.target?.checked;
    // If the checkbox was checked, set the dimensional weight value, and the boolean that tracks we're using it
    if (isChecked) {
      setValue(`load.commodities.${index}.isDimensionalWeight`, true);
      setValue(`load.commodities.${index}.weight`, dimensionalWeight);
    } else {
      // Reset the value, update boolean
      setValue(`load.commodities.${index}.isDimensionalWeight`, false);
      setValue(`load.commodities.${index}.weight`, 0);
    }
  };
  const [commodityTypeSearchText, setCommodityTypeSearchText] =
    useState<string>();
  const loadRequiredFields = getValues('requiredFields.load');

  useEffect(() => {
    // When l,w,h fields change, calculate the volume and set it.
    const volume =
      commodities[index]?.length *
      commodities[index]?.width *
      commodities[index]?.height;
    setValue(`load.commodities.${index}.volume`, volume > 0 ? volume : null);
  }, [
    commodities[index]?.length,
    commodities[index]?.width,
    commodities[index]?.height,
  ]);

  useEffect(() => {
    // When volume changes (its calulated from l/w/h, update that state)
    const weightPerSquareFoot = 1; // @TODO - this should come from somewhere.
    setDimensionalWeight(commodities[index]?.volume * weightPerSquareFoot);
  }, [commodities[index]?.volume]);

  useEffect(() => {
    // When the dimentional weight changes, check to see if we're using it
    if (commodities[index]?.isDimensionalWeight) {
      // If we are, update the weight field value
      setValue(`load.commodities.${index}.weight`, dimensionalWeight);
    }
  }, [dimensionalWeight]);

  useEffect(() => {
    if (
      !errors.load?.commodities?.[index]?.description &&
      !errors.load?.commodities?.[index]?.length &&
      !errors.load?.commodities?.[index]?.width &&
      !errors.load?.commodities?.[index]?.height
    )
      return;
    setIsDetailsExpanded(true);
  }, [
    errors.load?.commodities?.[index]?.description,
    errors.load?.commodities?.[index]?.length,
    errors.load?.commodities?.[index]?.width,
    errors.load?.commodities?.[index]?.height,
  ]);

  const loadCommodityList = queryClient.getQueryData<any>(
    QUERY_KEYS.loadCommodityList
  );
  const loadCommodityListMutation = useMutation({
    mutationFn: (value: string) =>
      loadFormService.createNewCommodityType(value),
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEYS.loadCommodityList, [
        ...(loadCommodityList ?? []),
        data,
      ]);
      setValue(`load.commodities.${index}.commodityDetails`, data);
      setValue(`load.commodities.${index}.commodity`, data?.id ?? '');
    },
  });

  const getCommodityOptionLabel = useCallback(
    (value: object) =>
      createLoadService.getAutocompleteOptionLabel({
        list: loadCommodityList,
        value,
        fieldName: 'itemName',
      }),
    [loadCommodityList]
  );

  const commodityTypeOptionsMemo: Array<CommoditiesTypeResDTO> = useMemo(() => {
    return loadFormService.getCommoditiesDropdownOptions({
      list: loadCommodityList,
      searchText: commodityTypeSearchText,
    });
  }, [loadCommodityList, commodityTypeSearchText]);

  const handleOnChangeCommodityType = async (_: any, value: any) => {
    try {
      // create new commodity since no record exists
      if (value?.itemName && !value?.id) {
        //custom validation for comomdity type length
        if (value?.value?.length > MAX_COMMODITY_NAME_LENGTH) {
          setCommodityTypeSearchText('');
          return RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'commodityLengthValidation',
          });
        }
        loadCommodityListMutation?.mutate(value?.value);
      }
      setValue(`load.commodities.${index}.commodityDetails`, value);
      setValue(`load.commodities.${index}.commodity`, value?.id ?? '');
      setCommodityTypeSearchText('');
    } catch (error) {
      // alert error message
      setValue(`load.commodities.${index}.commodity`, '');
      setValue(`load.commodities.${index}.commodityDetails`, null);
      setCommodityTypeSearchText('');
    }
  };
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={0.5}>
        <Stack direction="row" spacing={1} useFlexGap>
          <Controller
            name={`load.commodities.${index}.commodity`}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={commodityTypeOptionsMemo || []}
                getOptionLabel={getCommodityOptionLabel}
                clearOnBlur
                sx={{ width: '220px' }}
                handleHomeEndKeys
                id="createLoad-commodity-type"
                fullWidth
                onInputChange={(_, v) => {
                  setCommodityTypeSearchText(v);
                }}
                onChange={handleOnChangeCommodityType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Commodity"
                    required={loadRequiredFields?.commodities}
                    error={errors?.load?.commodities?.[index]?.commodity}
                    helperText={
                      errors?.load?.commodities?.[index]?.commodity?.message
                    }
                  />
                )}
              />
            )}
          />

          <Controller
            name={`load.commodities.${index}.quantity`}
            render={({ field }) => (
              <TextField
                id={`load-commodities-${index}-quantity`}
                type="number"
                required={loadRequiredFields?.commodities}
                label="Pieces"
                {...field}
                sx={{ width: '80px' }}
                error={errors?.load?.commodities?.[index]?.quantity}
                helperText={
                  errors?.load?.commodities?.[index]?.quantity?.message
                }
              />
            )}
          />

          <Controller
            name={`load.commodities.${index}.package`}
            render={({ field }) => (
              <Select
                id={`load-commodities-${index}-package`}
                size="small"
                label="Package"
                {...field}
                sx={{ flexGrow: 1, maxWidth: '200px', height: 'fit-content' }}
              >
                {loadStaticData?.commodityPackageTypes?.map?.((e: any) => (
                  <MenuItem
                    value={e?.key}
                    onClick={(event) => {
                      field.onChange(
                        commodities[index]?.package === e.key ? '' : e.key
                      );
                    }}
                  >
                    {e?.value}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Controller
            name={`load.commodities.${index}.weight`}
            render={({ field }) => (
              <TextField
                {...field}
                onBlur={updateTotalWeight}
                id={`load-commodities-${index}-weight`}
                type="number"
                label="Weight"
                error={Boolean(errors?.load?.commodities?.[index]?.weight)}
                helperText={errors?.load?.commodities?.[index]?.weight?.message}
                sx={{ width: '120px', ml: 'auto' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">lbs</InputAdornment>
                  ),
                }}
              />
            )}
          />

          <IconButton
            size="small"
            sx={{ flexShrink: 0 }}
            onClick={toggleIsDetailsExpanded}
          >
            {isDetailsExpanded ? <ExpandLessRounded /> : <ExpandMore />}
          </IconButton>
        </Stack>

        {!isDetailsExpanded && (
          <CommoditySummary commodity={commodities?.[index]} />
        )}
      </Box>

      {isDetailsExpanded && (
        <Stack
          direction="column"
          gap={1}
          sx={{
            backgroundColor: ' #f3f3f3',
            borderRadius: '10px',
            pt: '10px',
            pb: '10px',
            px: '10px',
          }}
        >
          <Controller
            name={`load.commodities.${index}.description`}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                id={`load-commodities-${index}-description`}
                label="Description"
                error={errors.load?.commodities?.[index]?.description}
                helperText={
                  errors.load?.commodities?.[index]?.description?.message
                }
              />
            )}
          />
          {/* <Stack direction="row" gap={1}>
            <Controller
              name={`load.commodities.${index}.pickup`}
              render={({ field }) => (
                <Autocomplete
                  sx={{ flexGrow: 1, flexShrink: 0 }}
                  {...field}
                  id={`load-commodities-${index}-pickup`}
                  options={['Location 1', 'Location 2', 'Location 3']}
                  renderInput={(params) => (
                    <TextField {...params} label="Pickup" />
                  )}
                />
              )}
            />
            <Controller
              name={`load.commodities.${index}.dropoff`}
              render={({ field }) => (
                <Autocomplete
                  sx={{ flexGrow: 1, flexShrink: 0 }}
                  {...field}
                  id={`load-commodities-${index}-dropoff`}
                  options={['Location 1', 'Location 2', 'Location 3']}
                  renderInput={(params) => (
                    <TextField {...params} label="Drop off" />
                  )}
                />
              )}
            />
          </Stack> */}

          <Stack direction="row" gap={1}>
            <Controller
              name={`load.commodities.${index}.length`}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Length"
                  error={errors.load?.commodities?.[index]?.length}
                  helperText={
                    errors.load?.commodities?.[index]?.length?.message
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">ft</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name={`load.commodities.${index}.width`}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Width"
                  error={errors.load?.commodities?.[index]?.width}
                  helperText={errors.load?.commodities?.[index]?.width?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">ft</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name={`load.commodities.${index}.height`}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Height"
                  error={errors.load?.commodities?.[index]?.height}
                  helperText={
                    errors.load?.commodities?.[index]?.height?.message
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">ft</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name={`load.commodities.${index}.volume`}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  label="Volume"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cu ft</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {/* {commoditiesLength > 1 && ( */}
            <IconButton
              size="small"
              sx={{ flexShrink: 0 }}
              onClick={() => remove(index)}
            >
              <DeleteOutline></DeleteOutline>
            </IconButton>
            {/* )} */}
            {/* {dimensionalWeight != 0 && (
              <FormControlLabel
                onClick={
                  (e) => {
                    handleSetDimensionalWeightClick(e);
                    updateTotalWeight();
                  }
                  // setValue(
                  //   `load.commodities.${index}.weight`,
                  //   dimensionalWeight
                  // )
                }
                sx={{ ml: 'auto' }}
                control={<Checkbox />}
                label={`Use Dimensional Weight: ${dimensionalWeight}lbs`}
              />
            )} */}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default CommodityItem;
