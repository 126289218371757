import * as yup from 'yup';
export const HOLD_NOTES_TEXT_MAX_LENGTH = 500;
export const HoldLoadValidationSchema = yup.object().shape({
  notesText: yup
    .string()
    .transform((value: string, originalValue: string) =>
      originalValue?.toString().trim() === '' ? '' : value
    )
    .max(
      HOLD_NOTES_TEXT_MAX_LENGTH,
      `Cannot be longer than ${HOLD_NOTES_TEXT_MAX_LENGTH} characters`
    )
    .required('Notes is required'),
});
export enum EHoldLoadEntityType {
  LOAD = 'LOAD',
  INVOICE = 'INVOICE',
}
