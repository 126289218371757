import { NoteDetails } from '../models';
import { getCurrentDateTime } from './';
import StorageManager from '../StorageManager/StorageManager';

export const updateNoteDetails = (
  data: any,
  dirtyFieldName: string,
  dirtyFields: any,
  noteDetailsFieldName?: string
) => {
  const { id: userId } = StorageManager.getUser() || {};
  if (userId && dirtyFields[dirtyFieldName]) {
    const noteDetails = new NoteDetails();
    noteDetails.userId = userId;
    noteDetails.updateTime = getCurrentDateTime();
    data[noteDetailsFieldName || 'noteDetails'] = noteDetails;
  }
};
