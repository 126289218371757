import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTabContext } from '@mui/lab';
import { Button, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { DownloadIcon } from '../../icons/icons';
// import { downloadFile } from '../../utils/Doc';

const ExportToExcel = (props: any) => {
  const { getExcel, excelDoc, downloadFile } = props;

  const theme = useTheme();
  useEffect(() => {
    if (excelDoc) {
      const excelFileToDownload = {
        ...excelDoc,
        fileContent: excelDoc.file,
      };
      downloadFile(excelFileToDownload);
    }
  }, [excelDoc]);

  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Button
        variant="outlined"
        onClick={getExcel}
        fullWidth
        sx={{
          height: '36px',
        }}
      >
        <span style={{ marginRight: 10 }}>
          <FileDownloadOutlinedIcon
            sx={{ color: 'leftMenuExpanded.inactive' }}
          />
        </span>

        <Typography
          sx={{
            textTransform: 'capitalize',
            ...theme.typography?.buttonMedium,
          }}
        >
          {t('tripdownloadTableData')}
        </Typography>
      </Button>
    );
  }
};

export default React.memo(ExportToExcel);
