import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRoutesLoad } from '../../../../../../contexts/LoadRoutesContext';
import LoadController from '../../../../../../subPages/loadsList/LoadController';
import { SingleAutocompleteForm } from '../../../../../Ui/SingleAutocomplete';
import { Trip } from '../../Models';
import RoutesController from '../../RoutesController';
import AssignmentFormWrapper from '../AssignmentFormWrapper';
import PaymentAmountAssignComponent from '../DriverAssignment/DriverAmountComponent';
import { useTranslation } from 'react-i18next';

export default function DispatcherAssignmentToLoad({
  trip,
  onClose,
  onAssign,
}: {
  trip: Trip;
  onClose: () => void;
  onAssign: (dispatcher) => void;
}): JSX.Element {
  const { setDriverAmountForAssign, driverAmountForAssign } = useRoutesLoad();
  const [data] = useState({ dispatcher: null });
  const [selectedDispatcher, setSelectedDispatcher] = useState<[] | null>(null);

  const handleDispatcherSelection = (dispatcher) => {
    setSelectedDispatcher({
      ...dispatcher,
      name: 'Dispatcher',
      id: dispatcher.paymentDetails.dispatcherId,
    });
  };

  const handleAssign = async (data) => {
    if (data.dispatcher?.id) {
      const result = await RoutesController.assignDispatcherToLoad(
        data.dispatcher?.id,
        trip.id,
        driverAmountForAssign
      );
      if (!result) return;
      onAssign(data.dispatcher);
      setDriverAmountForAssign(null);
    }
    onClose();
  };

  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <AssignmentFormWrapper
        onAction={handleAssign}
        onClose={onClose}
        data={data}
        title={t('DispatcherAssignmentToLoadAssignDispatcher')}
        actionLabel={t('DispatcherAssignmentToLoadAssign')}
      >
        <>
          <FormComponent
            trip={trip}
            onDispatcherSelect={handleDispatcherSelection}
          />
          {selectedDispatcher && (
            <PaymentAmountAssignComponent items={[selectedDispatcher]} />
          )}
        </>
      </AssignmentFormWrapper>
    );
  }
}

const FormComponent = ({
  trip,
  onDispatcherSelect,
}: {
  trip: Trip;
  onDispatcherSelect: ([]) => void;
}) => {
  const { setDriverAmountForAssign } = useRoutesLoad();
  const { control } = useFormContext();

  const onDispatcherChange = async (data) => {
    setDriverAmountForAssign(null);
    const res = await RoutesController.getDispatcherInfoForLoadAssign(
      data.id,
      trip.id
    );
    onDispatcherSelect(res);
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <>
        <SingleAutocompleteForm
          control={control}
          disableClear
          name="dispatcher"
          fieldName="name"
          label={t('DispatcherAssignmentToLoadDispatcher')}
          onChangeCb={(data) => onDispatcherChange(data)}
          getOptions={(searchText, pageNumber) =>
            LoadController.instance().getDispatcherSummary(
              searchText,
              pageNumber,
              trip.terminal?.id ? [trip.terminal?.id] : undefined,
              [0]
            )
          }
        />
      </>
    );
  }
};
