import { MarkersData } from '../../common/Ui/Maps/types';
import { RolesResponseDTO, GetRoles } from '../../models';

import { Service } from './Service';
import { ServiceError } from './helperTypes';

export abstract class IRolesService extends Service {
  abstract getRolesListData(
    requestData: GetRoles
  ): Promise<RolesResponseDTO[] | null | ServiceError>;

  abstract getActiveRoles(
    requestData: GetRoles
  ): Promise<RolesResponseDTO[] | null | ServiceError>;

  abstract getRoleDetailsData(
    requestData: GetRoles
  ): Promise<RolesResponseDTO | null | ServiceError>;

  abstract addNewRole(payloadData: any): Promise<any | null | ServiceError>;

  abstract updateRole(payloadData: any): Promise<any | null | ServiceError>;
  abstract updateRoleMeteData(
    payloadData: any
  ): Promise<any | null | ServiceError>;
  abstract deleteRole(payloadData: any): Promise<any | null | ServiceError>;
}
