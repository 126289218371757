import { Grid, SxProps, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import DatePicker from '../../../../common/Ui/FormDatePickerWithoutTimeZone/FormDatePickerWithoutTimeZone';

interface ICreateInReviewPayStatementForm {
  note?: string;
  containerStyles?: SxProps;
}

function CreateInReviewPayStatementForm({
  note,
  containerStyles = {},
}: ICreateInReviewPayStatementForm) {
  const { control } = useFormContext();

  return (
    <Grid
      container
      spacing={0}
      justifyContent="space-between"
      sx={{ marginTop: '20px', ...containerStyles }}
    >
      {Boolean(note) && (
        <Typography
          variant="body3"
          lineHeight={'140%'}
          color={'text.secondary'}
          mb={2}
        >
          {note}
        </Typography>
      )}
      <DatePicker
        control={control}
        disabledText
        name="startDate"
        label="Start Date*"
        sizes={{ xs: 5.7 }}
      />
      <DatePicker
        control={control}
        disabledText
        name="endDate"
        label="End Date*"
        sizes={{ xs: 5.7 }}
      />
    </Grid>
  );
}

export default CreateInReviewPayStatementForm;
