import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { AXELE_PERMISSION_TYPE, Permission } from '../../common/Permission';
import { AddIcon } from '../../ui-kit/components/Assets';

import StorageManager from '../../StorageManager/StorageManager';

interface IProps {
  addClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  numberOfContacts: number;
}

export const ContactsSummary = ({
  addClickHandler,
  numberOfContacts,
}: IProps) => {
  const userStorageData = StorageManager.getUser();
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      spacing={0}
    >
      <Grid item>
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          Contacts
        </Typography>
        <Permission contains={[AXELE_PERMISSION_TYPE.CUSTOMER_ADD]}>
          <Button
            onClick={addClickHandler}
            variant="outlined"
            style={{ minWidth: 35, marginLeft: 10 }}
          >
            <AddIcon />
          </Button>
        </Permission>
      </Grid>
      {numberOfContacts > 0 && (
        <Grid item>
          <Grid container direction={'column'} alignItems={'flex-end'}>
            <Grid item>
              <Typography variant="h6" sx={{ color: 'primary.main' }}>
                {numberOfContacts}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="tooltip" sx={{ color: 'text.secondary' }}>
                Total #
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
