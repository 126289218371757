import { useFormContext } from 'react-hook-form';
import SettingBlock from '../Blocks/SettingBlock';
import {
  defaultEquipmentTypeOptions,
  LOAD_NAME,
  stopActivities,
} from '../../constants';
import { SettingBlockItem } from '../Blocks/SettingBlockItem';
import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import Switcher from '../../../../../common/Ui/Switcher';

function StopActivity() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Stop Activity Default Date and Time"
      subTitle="This is helpful to quickly update a driver’s stop activities on an In Progress load. Find this by selecting a load, then clicking Update Progress."
    >
      <SingleAutocompleteForm
        control={control}
        fieldName="value"
        fieldValue="key"
        name="stopActivitiesAutofillOption"
        label="Stop Activities"
        options={stopActivities}
        required
        disableClear
      />
    </SettingBlockItem>
  );
}

function DefaultEquipmentType() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Default Equipment Type"
      subTitle="Which trailer type do you typically use per load? This is applied during load creation."
    >
      <SingleAutocompleteForm
        control={control}
        fieldName="value"
        fieldValue="key"
        name="trailerType"
        label="Default Equipment Type"
        options={defaultEquipmentTypeOptions}
        required
        disableClear
      />
    </SettingBlockItem>
  );
}

function LoadTimings() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Collect Load/Unload Timings"
      subTitle="By default, the driver marks arrival at and departure from a stop. Enable this to also collect loading and unloading times."
    >
      <Switcher
        control={control}
        name="collectLoadUnloadTiming"
        label=""
        style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}

function MakeBOLMandatory() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Make Bill of Lading (BOL) Mandatory"
      subTitle="Do you require this document uploaded on each load?"
    >
      <Switcher
        control={control}
        name="billOfLadingMandatory"
        label=""
        style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}

function MakePODMandatory() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Make Proof of Delivery (POD) Mandatory"
      subTitle="Do you require this document uploaded on each load?"
    >
      <Switcher
        control={control}
        name="proofOfDeliveryMandatory"
        label=""
        style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}

function MakeDCMandatory() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Make Document Collection Mandatory on Mobile"
      subTitle="Enable this to require Drives to upload load documents before the load completes."
    >
      <Switcher
        control={control}
        name="documentsMandatory"
        label=""
        style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}

export default function Loads() {
  return (
    <section id={LOAD_NAME} className="section">
      <SettingBlock
        title="Loads"
        subTitle="We use these details to calculate metrics to help you evaluate load board results and efficiently create and manage your loads on the Web and Mobile App."
      >
        <StopActivity />
        <DefaultEquipmentType />
        <LoadTimings />
        <MakeBOLMandatory />
        <MakePODMandatory />
        <MakeDCMandatory />
      </SettingBlock>
    </section>
  );
}
