import { Divider, DividerProps, styled, Theme } from '@mui/material';
import { BREAKPOINT_TABLET } from '../../hooks/useThemeResponsive';

export const StyledLeftMenuDivider = styled((props: DividerProps) => (
  <Divider
    {...props}
    orientation="horizontal"
    flexItem
    sx={{
      borderColor: 'primary.contrast',
      opacity: 0.12,
      ml: 1,
      mr: 1,
    }}
  />
))(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.up(BREAKPOINT_TABLET)]: {
    display: 'none',
  },
}));

export const leftMenuStyles = (theme: Theme) => ({
  root: {
    height: '100%',
    width: '4.5rem',
    display: 'flex',
    // position: 'fixed',
    zIndex: 1201,
  },
  leftFixedMenu: {
    flexDirection: 'column',
    backgroundColor: 'leftMenuCollapsed.canvas',
    height: '100%',
    // position: 'fixed',
    width: '4.5',
    zIndex: 1200,
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      width: '100%',
      backgroundColor: 'leftMenuCollapsed.canvas',
      overflowY: 'auto',
    },
  },
  menuItemsLogo: {
    pt: '0px',
    minHeight: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    m: '0px 0',
    height: '61px',
    gap: '2px',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      display: 'none',
    },
  },
  navigationItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    mt: '1rem',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
      marginTop: '80px',
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: 'calc(100vh - 80px)',
      overflowY: 'auto',
    },
  },
  leftMenuTop: {
    display: 'none',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  leftMenuTerminal: {
    display: 'none',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      mt: 1,
      pl: 2,
      display: 'flex',
    },
  },
  menuListItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    m: '8px 0',
    height: '61px',
    gap: '2px',
    ':hover': { background: 'rgba(255, 255, 255, 0.15)' },
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      width: '100%',
      m: 0,
      height: 'unset',
      gap: 'unset',
      justifyContent: 'space-between',
      flexDirection: 'row',
      ':hover': { background: 'unset' },
    },
  },
  menuListItem: {
    width: '4.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
    minHeight: '60px',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
      pl: 2,
    },
  },
  menuListItemName: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '.6rem',
    lineHeight: '15px',
    pt: '5px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      textTransform: 'capitalize',
      fontSize: '17px',
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: '140%',
      letterSpacing: '0.15px',
      color: 'primary.contrast',
      pl: 1,
      fontFamily: "'Oxanium'",
    },
  },
  menuListItemCollapseIcon: {
    display: 'none',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      display: 'flex',
      pr: 1,
    },
  },
  menuListItemCollapseMenuList: {
    display: 'none',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      display: 'flex',
      width: '100%',
    },
  },
  leftMenuDivider: {
    display: 'none',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      display: 'inherit',
    },
  },
  leftMenuCollapseRoot: {
    flexDirection: 'column',
    boxShadow: '0 -11px 25px rgb(0 0 0 / 15%)',
    backdropFilter: 'blur(12px)',
    // ml: '75px',
    borderRadius: '5px,0px 0px 0px',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
      boxShadow: 'unset',
      backdropFilter: 'unset',
    },
  },
  leftMenuCollapseContainer: {
    height: '100vh',
    width: '220px',
    backgroundColor: 'leftMenuExpanded.sidePanel',
    borderRadius: '0 12px 12px 0',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      width: '100%',
      height: 'unset',
    },
  },
  leftMenuCollapseSubMenuItem: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    p: '8px',
    m: '0 20px 0 20px',
    borderRadius: '6px',
    ':hover': {
      backgroundColor: 'tabStrip.inactivePressed',
    },
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      padding: 1,
      paddingLeft: 5,
      borderRadius: 'unset',
      m: 'unset',
    },
  },
  leftMenuCollapseSubMenuItemName: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    cursor: 'pointer',
    letterSpacing: '0.15px',
    m: '8px 0 8px 0',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      textTransform: 'capitalize',
      fontSize: '17px',
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: '140%',
      letterSpacing: '0.15px',
      color: 'primary.light',
      fontFamily: 'Oxanium',
    },
  },
  leftMenuCollapseSubMenuItemSelectedDot: {
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      display: 'none',
    },
  },
  onlyForMobile: {
    display: 'none',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      display: 'inherit',
    },
  },
  notForMobile: {
    display: 'inherit',
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      display: 'none',
    },
  },
});
