import { observer } from 'mobx-react';
import { DispatchDetailsPanelProvider } from '../../context/DispatchDetailsPanelProvider';

import DispatchDetailsPanel, {
  DispatchDetailsPanelProps,
} from './DispatchDetailsPanel';

const DispatchDetailsPanelWithProvider = observer(
  (props: DispatchDetailsPanelProps): JSX.Element => {
    return (
      <DispatchDetailsPanelProvider>
        <DispatchDetailsPanel {...props} />
      </DispatchDetailsPanelProvider>
    );
  }
);

export default DispatchDetailsPanelWithProvider;
