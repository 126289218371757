import { Box } from '@mui/material';

export const Divider = ({ styles = {} }: { styles?: {} }) => {
  return (
    <Box
      style={{
        width: '2px',
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        ...styles,
      }}
    ></Box>
  );
};
