import { Theme } from '@mui/material';
import { BREAKPOINT_TABLET } from '../../../../../hooks/useThemeResponsive';

export const getAssignmentFormWrapperStyles = (theme: Theme) => {
  return {
    root: {
      width: '100%',
      [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
        maxWidth: '100%',
      },
      borderRadius: '16px',
      padding: 2,
      zIndex: 1,
      position: 'relative',
      backgroundColor: 'common.white',
      border: '1px solid',
      borderColor: 'primary.outlinedRestingBorder',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      pb: 2,
    },
    buttonActionRoot: {
      display: 'flex',
      justifyContent: 'flex-end',
      pt: 2,
    },
    buttonAction: {
      borderRadius: '6px',
      height: '28px',
    },
  };
};
