import { SxProps, Theme } from '@mui/material';
import { MapMarkerProps } from '.';

export const markerCompletedColor = {
  borderColor: 'rgba(189, 189, 189, 1)',
  backgroundColor: 'rgba(238, 238, 238, 1)',
};

export const getMapMarkerStyles = ({
  theme,
  completed,
  delayed,
  variant,
  size,
  title,
  subTitle,
  backgroundColor,
  borderColor,
  SxProps,
}: Pick<
  MapMarkerProps,
  | 'completed'
  | 'delayed'
  | 'variant'
  | 'size'
  | 'title'
  | 'subTitle'
  | 'backgroundColor'
  | 'borderColor'
  | 'SxProps'
> & {
  theme: Theme;
}): Record<string, SxProps> => {
  return {
    MapMarkerRoot: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    MapMarkerTooltipRoot: {
      position: 'absolute',
      mt: !(title && subTitle) ? '-50px' : '-70px',
      ml: '50%',
      transform: 'translateX(-50%)',
      width: 'max-content',
      ...SxProps?.MapMarkerTooltipRoot,
    },
    MapMarkerTooltip: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '8px',
      backgroundColor: 'common.white',
      marginBottom: '-1px',
      ...(completed
        ? {
            border: '1px solid',
            borderColor: markerCompletedColor.borderColor,
            backgroundColor: markerCompletedColor.backgroundColor,
          }
        : {
            border: 'none',
            boxShadow: theme.shadows[8],
          }),
      '&:before,&:after': {
        content: "''",
        position: 'absolute',
        top: '100%',
        left: '50%',
        border: '11px solid transparent',
        borderTopColor: 'common.white',
        ...(completed && {
          //For border of arrow
          borderTopColor: markerCompletedColor.borderColor,
        }),
        transform: 'translateX(-50%)',
      },
      '&:after': {
        border: '10px solid transparent',
        //For primary arrow
        ...(completed && {
          borderTopColor: markerCompletedColor.backgroundColor,
        }),
      },
    },
    MapMarkerTooltipTitle: {
      fontSize: '14px',
      fontWeight: '400',
      color: 'common.black',
      ...(completed && {
        color: 'text.secondary',
      }),
    },
    MapMarkerTooltipSubTitle: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'text.secondary',
      ...(completed && {
        color: 'text.disabled',
        fontWeight: 500,
      }),
      ...(delayed && {
        color: 'warning.main',
      }),
    },
    MapMarkerMarker: {
      boxShadow: theme.shadows[8],
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      ...(size === 'small' && {
        width: 32,
        height: 32,
      }),
      ...(size === 'medium' && {
        width: 40,
        height: 40,
      }),
      ...(size === 'large' && {
        width: 48,
        height: 48,
      }),
      ...(variant === 'filled' && {
        backgroundColor: backgroundColor ? backgroundColor : 'primary.main',
        border: '4px solid',
        borderColor: borderColor ? borderColor : 'common.white',
      }),
      ...(variant === 'outlined' && {
        backgroundColor: backgroundColor ? backgroundColor : 'common.white',
        border: '4px solid',
        borderColor: borderColor ? borderColor : 'common.black',
        ...(completed && {
          backgroundColor: markerCompletedColor.backgroundColor,
          borderColor: markerCompletedColor.borderColor,
        }),
      }),
    },
  };
};

export const MapMarkerStyles = {
  content: {
    fontSize: '14px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  smallContent: {
    fontSize: '8px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  largeContent: {
    fontSize: '23px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
};
