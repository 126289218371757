import React, { FC } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { amountFormat } from '../../../../utils';
import {
  financeRevenueTootTipCardStyle,
  financeRevenueTootTipStyle,
  propsTypeFinanceRevenueTootTip,
} from './FinanceOverviewModel';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import { t } from 'i18next';
const FinanceRevenueTootTip = ({
  onModelOpen,
  shareData,
}: propsTypeFinanceRevenueTootTip) => {
  return (
    <Box sx={financeRevenueTootTipCardStyle}>
      <Box sx={{ paddingBottom: '10px' }}>
        {shareData?.map((Details: any) => {
          return (
            <Typography
              sx={financeRevenueTootTipStyle}
              key={Details.revenueShare}
            >
              {t('loadId')}: {Details.seqNumber} ({Details.revenueShare}%) ={' '}
              {amountFormat(Details.revenueAmount)}
            </Typography>
          );
        })}
      </Box>

      <Divider />
      <Box sx={{ textAlign: 'center', mt: '10px', mb: '6px' }}>
        <ButtonImproved
          variant="contained"
          label={t('loadsFinanceChangeRevenueShare')}
          size="small"
          onClick={onModelOpen}
          styleProps={{ fontSize: '13px' }}
        />
      </Box>
    </Box>
  );
};

export default React.memo(FinanceRevenueTootTip);
