import {
  ContactSummaryDTO,
  CreateLocationContactRequest,
  CreateLocationRequest,
  DeleteLocationByIdRequest,
  EditLocationDetailsByIdRequest,
  GetLocationListDetailsByCoordsRequest,
  GetLocationListDetailsByCoordsResponse,
  GetAddressNamesListRequest,
  GetContactNamesListRequest,
  GetLocationFiltersRequest,
  GetLocationListByNameAddressSearchRequest,
  GetLocationListExcelRequest,
  GetLocationListRequest,
  GetLocationNamesListRequest,
  GetTimezoneByCoordsRequest,
  GetTimezoneByCoordsResponse,
  LocationSummaryDTO,
  PaginatedAddressFilterList,
  PaginatedContactsFilterList,
  PaginatedLocationAddressList,
  PaginatedLocationsFilterList,
  PaginatedLocationsList,
  ValidateLocationsUniqueNamesRequest,
  ValidateLocationsUniqueNamesResponse,
} from '../../models/index';

import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class ILocationService extends Service {
  abstract createLocation(
    payloadData: CreateLocationRequest
  ): Promise<LocationSummaryDTO | null | ServiceError>;

  abstract createLocations(
    payloadData: CreateLocationRequest[]
  ): Promise<LocationSummaryDTO[] | null | ServiceError>;

  abstract createLocationContact(
    payloadData: CreateLocationContactRequest
  ): Promise<ContactSummaryDTO | null | ServiceError>;

  abstract getLocationList(
    requestData: GetLocationListRequest,
    filtersData: GetLocationFiltersRequest
  ): Promise<PaginatedLocationsList | null | ServiceError>;

  abstract getLocationListByNameAddressSearch(
    requestData: GetLocationListByNameAddressSearchRequest
  ): Promise<PaginatedLocationAddressList | null | ServiceError>;

  abstract deleteLocationById(
    requestData: DeleteLocationByIdRequest
  ): Promise<null | undefined>;

  abstract getLocationDetailsById(
    requestData: DeleteLocationByIdRequest
  ): Promise<LocationSummaryDTO | null | ServiceError>;

  abstract editLocationDetailsById(
    payloadData: EditLocationDetailsByIdRequest
  ): Promise<LocationSummaryDTO | null | ServiceError>;

  abstract getLocationsNamesList(
    payloadData: GetLocationNamesListRequest
  ): Promise<PaginatedLocationsFilterList | null | ServiceError>;

  abstract getAddressNamesList(
    payloadData: GetAddressNamesListRequest
  ): Promise<PaginatedAddressFilterList | null | ServiceError>;

  abstract getContactNamesList(
    payloadData: GetContactNamesListRequest
  ): Promise<PaginatedContactsFilterList | null | ServiceError>;

  abstract getLocationListExport(
    requestData: GetLocationListExcelRequest,
    filtersData: GetLocationFiltersRequest
  ): Promise<PaginatedLocationsList | null | ServiceError>;

  abstract validateLocationsUniqueNames(
    payloadData: ValidateLocationsUniqueNamesRequest
  ): Promise<ValidateLocationsUniqueNamesResponse | null | ServiceError>;

  abstract getLocationListDetailsByCoords(
    requestData: GetLocationListDetailsByCoordsRequest
  ): Promise<GetLocationListDetailsByCoordsResponse | ServiceError>;

  abstract getTimezoneByCoords(
    requestData: GetTimezoneByCoordsRequest
  ): Promise<GetTimezoneByCoordsResponse | null | ServiceError>;
}
