import { TimerOutlined } from '@mui/icons-material';
import { useState } from 'react';
import LongMenu from '../../../../common/LongMenu';
import { TimeoffDialog } from '../../../../common/TimeoffDialog';
import {
  defaultTimeoffFormData,
  ITimeoffDialogData,
} from '../../../../common/TimeoffDialog/types';
import { EDispatch3DotMenuType } from '../../constants/types';
import { t } from 'i18next';
import { DriverTeamWithTrips } from '@/models';

interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}
interface DispatchDetailsPanelActionsSectionProps {
  hocDriverTeam: DriverTeamWithTrips;
  hocOnAction?: () => void;
}
export function DispatchDetailsPanelActionsSection(
  props: DispatchDetailsPanelActionsSectionProps
) {
  const { hocDriverTeam: driverTeam, hocOnAction } = props;
  let loadData: ITimeoffDialogData | {} = {};
  if (driverTeam) {
    loadData = {
      driverGroupId: driverTeam?.id,
      driverName: driverTeam?.displayName,
      tractorId: driverTeam.assetAssignment?.tractorId,
      trailerId: driverTeam.assetAssignment?.trailerId,
      driverId: driverTeam?.drivers?.[0]?.id,
    };
  }

  //Panel3DotMenuHeaderProps
  const [current3DotMenu, setCurrent3DotMenu] =
    useState<EDispatch3DotMenuType | null>(null);

  const onCloseMenu = () => {
    setCurrent3DotMenu(null);
  };

  const options: Action[] = [
    {
      startIcon: <TimerOutlined />,
      name: `${t('DispatchDetailsPanelActionsSectionRequesttimeoff')}`,
      action: (): void => {
        setCurrent3DotMenu(EDispatch3DotMenuType.REQUEST_TIMEOFF);
      },
    },
  ];

  const handleAction = () => {
    switch (current3DotMenu) {
      case EDispatch3DotMenuType.REQUEST_TIMEOFF:
        return (
          <TimeoffDialog
            canEditDate={true}
            loadData={loadData}
            timeoffFormData={defaultTimeoffFormData}
            onClose={onCloseMenu}
            action={EDispatch3DotMenuType.REQUEST_TIMEOFF}
            hocOnAction={hocOnAction}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <LongMenu color={'primary.contrast'} options={options} />
      {current3DotMenu && handleAction()}
    </>
  );
}
