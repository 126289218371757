import moment from 'moment';
import { IDispatchOption } from '../../types/DispatchTypes';

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const addDaysToDate = (date: Date | string, days: number) => {
  if (!date) return date;
  const d = new Date(date);
  const result = d.setDate(d.getDate() + days);
  return new Date(result);
};

export const subtractDaysFromDate = (date: Date | string, days: number) => {
  const d = new Date(date);
  const result = d.setDate(d.getDate() - days);
  return new Date(result);
};

export const isDateSame = (date1: string | Date, date2: string | Date) => {
  if (!date1 || !date2) return false;
  if (
    new Date(date1)?.getDate() === new Date(date2)?.getDate() &&
    new Date(date1)?.getMonth() === new Date(date2)?.getMonth() &&
    new Date(date1)?.getFullYear() === new Date(date2)?.getFullYear()
  )
    return true;
  return false;
};
export const getPercentage = (actualValue: number, maxValue: number) => {
  if (isNaN(actualValue) || isNaN(maxValue)) return 0;
  let result = Math.floor((actualValue * 100) / maxValue);
  result = result > 0 ? (result <= 100 ? result : 100) : 0;
  return result;
};

export const getHourFromMinutes = (minutes: number, unit = 'h'): string => {
  if (minutes <= 0 || isNaN(minutes)) {
    return `0m`;
  } else {
    return Math.floor(minutes / 60) + `${unit}`;
  }
};

export const getDecimalValueOfMinutes = (mins: number): string => {
  if (!mins || isNaN(mins)) return '';
  const { days } = getTimeDetailsFromMinutes(mins);
  if (days > 0) return `${(mins / 60 / 24)?.toFixed?.(1)} d`;
  return `${(mins / 60)?.toFixed?.(1)} h`;
};

export const getDifference = ({
  total,
  completed,
}: {
  total: string | number;
  completed: string | number;
}): number => {
  if (isNaN(Number(total)) || isNaN(Number(completed))) return 0;
  return Number(total) - Number(completed);
};

export const getTimeDetailsFromMinutes = (
  min: number
): { days: number; hours: number; minutes: number } => {
  let delta = min * 60;
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;
  return { days, hours, minutes };
};

export const getFormatedTimeInDayHoursAndMin = (data: number) => {
  const { days, hours, minutes } = getTimeDetailsFromMinutes(data);
  let time = '';
  if (days > 0) time = `${days}d `;
  if (hours > 0) time += `${hours}h `;
  if (minutes > 0) time += `${minutes}m`;
  return time;
};

export const prefixLabel = ({
  prefix = '$',
  label,
}: {
  prefix?: string;
  label: string;
}) => {
  if (!label) return '-';
  return `${prefix}${label}`;
};

export const postfixLabel = ({
  postfix = 'mi',
  label,
}: {
  postfix?: string;
  label: string;
}) => {
  if (!label) return '-';
  return `${label}${postfix}`;
};

export const toFixDigit = (value: number, digit = 0): string => {
  if (isNaN(value)) return '-';
  const number = Number(value)?.toFixed(digit);
  return Number(number).toLocaleString();
};

export const appendTextToDigit = ({
  val,
  prefixLabel = '',
  postfixLabel = '',
  requiredValidation = true,
}: {
  val: number | string;
  postfixLabel?: string;
  prefixLabel?: string;
  requiredValidation?: boolean;
}): string => {
  if (!requiredValidation) {
    if (val) return `${prefixLabel}${val}${postfixLabel}`;
    else return '-';
  }
  if (val === 0) return `${prefixLabel}${val}${postfixLabel}`;
  if (!val) return '-';
  return val
    ? `${prefixLabel}${toFixDigit(val as number)}${postfixLabel}`
    : '-';
};

export const formatedTextFromTwoValues = (
  firstValue: any,
  secondValue: any,
  separator?: string,
  emptyString = '-'
) => {
  const separatorVal = separator ? `${separator + ' '}` : '';
  if (firstValue && secondValue) {
    return firstValue + separatorVal + secondValue;
  } else if (firstValue) {
    return firstValue;
  } else if (secondValue) {
    return secondValue;
  } else {
    return emptyString;
  }
};

export const getFormatedDate = (date: string) => {
  if (date) {
    const CurrentDateObj = new Date();
    const month = moment().month(date?.split?.(' ')?.[0]).format('M');
    const dateVal = date?.split?.(' ')?.[1];
    if (!month ?? !dateVal) return '';
    return `${month}/${dateVal}/${CurrentDateObj.getFullYear()}`;
  }
};

export const getDayStartHours = (date?: Date | string) => {
  if (!date) return new Date(new Date().setHours(0, 0, 0, 0));
  return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const getSortOptions = (): IDispatchOption[] => {
  return [{ key: 'groupName', value: 'Name' }];
};

export const getMultiValueText = (
  value1: string | number | null,
  value2: string | number | null
) => {
  let text = '0' as any;
  if ((value1 || value1 == 0) && value1 != '-') {
    if ((value2 || value2 == 0) && value2 != '-') {
      text = `${value1}/ ${value2}`;
    } else {
      text = value1;
    }
  } else if ((value2 || value2 == 0) && value2 != '-') {
    text = `0/ ${value2}`;
  }
  return text;
};
