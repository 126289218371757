export const radioNames = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
];

export const weeklyRenderText = {
  intial: 'Recurs Every',
  end: 'week(s) on:',
};

export const endAfterText = {
  intial: 'End after:',
  end: 'occurrences',
};

export const monthlyRenderText = {
  intial: 'Day',
  end: 'of every month',
};

export const checkboxNames = [
  {
    value: 'Monday',
    label: 'MO',
  },
  {
    value: 'Tuesday',
    label: 'TU',
  },
  {
    value: 'Wednesday',
    label: 'WE',
  },
  {
    value: 'Thursday',
    label: 'TH',
  },
  {
    value: 'Friday',
    label: 'FR',
  },
  {
    value: 'Saturday',
    label: 'SA',
  },
  {
    value: 'Sunday',
    label: 'SU',
  },
];

export const endRadio = [
  {
    value: 'endBy',
    label: 'Daily',
  },
  // {
  //     value: 'endAfter',
  //     label: 'Weekly'
  // },
  // {
  //     value: 'noEnd',
  //     label: 'Monthly'
  // }
];

export const noEndText = 'No End Date';

export const defaultData = {
  main: 'weekly',
  weekDays: [],
  end: 'endBy',
};

export const buttonNames = {
  cancel: 'Cancel',
  add: 'Add',
  delete: 'Delete',
  edit: 'Save',
};

export const validationText = 'Select day of the week';
export const integerError = 'Please select one of the Range of Recurrence';

export const rangeConstText = 'Range of Recurrence';
export const recurseConstText = 'Recurs Every:';

export const requiredCheck = async (data: any) =>
  data.length == 0 ? 'Required' : null;

export const filterValidation = async (data: any) =>
  Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));

export const numValidate = async (value: string) =>
  /^[0-9]+$/.test(value) ? null : 'Must contain only digits';

export const requiredCheckWeeklyRecurs = async (data: any) =>
  data.length == 0 ? 'Please enter value here' : null;
