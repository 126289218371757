import { PaginatedResult, ViewMetaData } from '../../types';
import { prepareFiltersFromList } from '../../utils';
import {
  CreateUserRequest,
  DeleteUserRequest,
  GetUserByIdRequest,
  invitationsUserDeactivateRequest,
  invitationsUserStatusRequest,
  PaginatedUserListRequest,
  ValidateUserRequest,
} from '../../models';
import { userService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import { User, UserDetails } from '../../models/DTOs/user/User';
import {
  nameFilter,
  emailFilter,
  userStatus,
  roleCodeList,
} from '../../views/settings/users/constants';
import TerminalController from '../../views/settings/terminals/TerminalController';
import StorageManager from '../../StorageManager/StorageManager';

export const getFilteredRequest = (
  metaData: ViewMetaData,
  request: PaginatedUserListRequest,
  terminalIds?: string[]
) => {
  // @Todo davits
  request.nameFilter = prepareFiltersFromList(
    metaData[nameFilter],
    'nameToFilter'
  );
  request.emailFilter = prepareFiltersFromList(metaData[emailFilter], 'email');
  request.userStatus = prepareFiltersFromList(metaData[userStatus], 'key');
  request.roleCodeList = prepareFiltersFromList(
    metaData[roleCodeList],
    'roleCode'
  );
  request.terminalIds = terminalIds;
  request.excludeRoleCodeList = ['DR'];
  return request;
};

export const fetchUsers = async (
  queryParams: ViewMetaData,
  terminalIds?: string[],
  fetchingType = 'GET_DATA'
): Promise<PaginatedResult<User> | undefined> => {
  const request = new PaginatedUserListRequest(queryParams);

  const response = await userService.getPaginatedUserList(
    getFilteredRequest(queryParams, request, terminalIds),
    fetchingType
  );
  if (response instanceof ServiceError) {
  } else {
    return response;
  }
};

export const createUser = async (
  newUserData: any
): Promise<UserDetails | undefined> => {
  const request = new CreateUserRequest();
  const response = await userService.createUser(request, newUserData);
  if (response instanceof ServiceError) {
    return;
  } else {
    const { webUser } = response;
    if (webUser?.id) {
      return webUser;
    }
    return;
  }
};

export const updateUser = async (
  item: any
): Promise<
  { currentUser: UserDetails; removableId: null | string } | undefined
> => {
  const request = new CreateUserRequest();
  const response = await userService.updateUser(request, item);
  if (response instanceof ServiceError) {
    return;
  } else {
    const { webUser } = response;
    let removableId = null;
    if (!webUser.id) {
      removableId = item.id;
    }
    return {
      currentUser: webUser,
      removableId,
    };
  }
};

export const getUserById = async (
  userId: string
): Promise<UserDetails | undefined> => {
  const request = new GetUserByIdRequest();
  request.id = userId;
  request.loggedIn = 1;
  const result = await userService.getUserById(request);
  if (result instanceof ServiceError) {
    return;
  } else {
    const associatedTerminalsList = result?.associatedTerminalsList?.map(
      (terminal) => {
        return TerminalController.instance().terminalById(terminal?.id);
      }
    );

    const data = { ...result, associatedTerminalsList };

    const userDetails = new UserDetails(data);
    return userDetails;
  }
};

export const deleteUser = async (
  userId: string
): Promise<string | undefined> => {
  const request = new DeleteUserRequest(false);
  request.id = userId;
  const response = await userService.deleteUser(request);
  if (response instanceof ServiceError) {
    return;
  }
  return response;
};

/* @TODO davits fix return type */
export const validateUser = async (
  userId: string | null,
  email: string,
  role: string
): Promise<any> => {
  const user = StorageManager.getUser();
  if (user) {
    const request = new ValidateUserRequest();
    request.id = userId;
    request.email = email;
    request.dot = user.dot;
    request.roleCode = role;
    const response = await userService.validateUser(request);
    if (response instanceof ServiceError) {
      return response;
    }
    return response;
  }
};

export const invitationsUserStatus = async (
  statusInfo: invitationsUserStatusRequest
): Promise<UserDetails | undefined> => {
  const response = await userService.invitationsUserStatus(statusInfo);
  return response instanceof ServiceError ? undefined : response;
};

export const invitationsUserDeactivateValidation = async (
  statusInfo: invitationsUserStatusRequest
): Promise<invitationsUserDeactivateRequest | undefined> => {
  const response = await userService.invitationsUserDeactivateValidation(
    statusInfo
  );
  return response instanceof ServiceError ? undefined : response;
};
