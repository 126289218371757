import { AxiosConfigType, http } from '../../../axios/axios.service';
import { EvaluationResDTO } from '../types';

export const evaluateDriverRequest = async (
  payload?: Partial<AxiosConfigType>
): Promise<EvaluationResDTO> => {
  return (
    await http.put({
      apiEndpoint: '/web/collector/api/v2/sandbox/evaluate/driverTrip',
      ...payload,
    })
  )?.data;
};
