export const OPTYMIZATION_ACTIONS = {
  SET_RECOMMENDED_TRIPS: 'SET_RECOMMENDED_TRIPS',
  SET_FORMATTED_TRIPS: 'SET_FORMATTED_TRIPS',
  UPDATE_LOCK_FLAG: 'UPDATE_LOCK_FLAG',
  REJECT_RESOURCE: 'REJECT_RESOURCE',
  UNREJECT_RESOURCE: 'UNREJECT_RESOURCE',
  LOCK_POSSIBLE_RESOURCE: 'LOCK_POSSIBLE_RESOURCE',
  UNLOCK_POSSIBLE_DRIVER: 'UNLOCK_POSSIBLE_RESOURCE',
  REJECT_POSSIBLE_RESOURCE: 'REJECT_POSSIBLE_RESOURCE',
  UNREJECT_POSSIBLE_RESOURCE: 'UNLOCK_POSSIBLE_RESOURCE',
  LOCK_ALL_RECOMMEND_TRIPS: 'LOCK_ALL_RECOMMEND_TRIPS',
  UNLOCK_ALL_RECOMMEND_TRIPS: 'UNLOCK_ALL_RECOMMEND_TRIPS',
  REORDER_RECOMMENDATIONS: 'REORDER_RECOMMENDATIONS',
  REJECT_RESOURCE_ASSIGNMENT: 'REJECT_RESOURCE_ASSIGNMENT',
  REMOVE_DRIVER_ASSIGNMENT: 'REMOVE_DRIVER_ASSIGNMENT',
  UPDATE_ASSIGNMENTS: 'UPDATE_ASSIGNMENTS',
  START_LOADER: 'START_LOADER',
  STOP_LOADER: 'STOP_LOADER',
  LOCK_ASSIGNMENTS: 'LOCK_ASSIGNMENTS',
  REOPTIMIZATION_START: 'START_REOPTIMIZATION',
  REOPTIMIZATION_COMPLETED: 'REOPTIMIZATION_COMPLETED',
  OPTIMIZATION_FAILED: 'OPTIMIZATION_FAILED',
  CLEAR_OPTIMIZATION_FAILURE: 'CLEAR_OPTIMIZATION_FAILURE',
  RERUN_OPTIMIZATION: 'RERUN_OPTIMIZATION',
  LOCK_ASSIGNED_CANDIDATE: 'LOCK_ASSIGNED_CANDIDATE',
  CLEAR_OPTIMIZED_STORE: 'CLEAR_OPTIMIZED_STORE',
  UPDATE_RAW_STORE: 'UPDATE_RAW_STORE',
  MAP_SANDBOX_WARNINGS: 'MAP_SANDBOX_WARNINGS',
  CLEAR_CURRENT_REJECTED_RESOURCE: 'CLEAR_CURRENT_REJECTED_RESOURCE',
  STOP_INITIAL_LOAD: 'STOP_INITIAL_LOAD',
};

export const POSSIBLE_RESOURCE_ACTIONS = {
  SET_POSSIBLE_RESOURCES: 'SET_POSSIBLE_RESOURCES',
  LOCK_POSSIBLE_RESOURCE: 'LOCK_POSSIBLE_RESOURCE',
  UNLOCK_POSSIBLE_DRIVER: 'UNLOCK_POSSIBLE_RESOURCE',
  REJECT_POSSIBLE_RESOURCE: 'REJECT_POSSIBLE_RESOURCE',
  UNREJECT_POSSIBLE_RESOURCE: 'UNLOCK_POSSIBLE_RESOURCE',
  SET_LOADER: 'SET_LOADER',
  UPDATE_DRIVER_BY_GROUP_ID: 'UPDATE_DRIVER_BY_GROUP_ID',
  START_LOADER: 'START_LOADER',
  STOP_LOADER: 'STOP_LOADER',
  REJECT_DRIVER: 'REJECT_DRIVER',
  UN_REJECT_DRIVER: 'UN_REJECT_DRIVER',
  CLEAR_POSSIBLE_STORE: 'CLEAR_POSSIBLE_STORE',
  MAP_POSSIBLE_RESOURCE_WARNINGS: 'MAP_POSSIBLE_RESOURCE_WARNINGS',
};
