import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getCommodityTypes } from '../subPages/loadsList/LoadDetailsPanel/utils';
import { CommodityTypeSummary } from '../models';

export type TCommodityTypeContext = {
  commodityTypes: CommodityTypeSummary[];
  addNewCommodityType: React.Dispatch<
    React.SetStateAction<CommodityTypeSummary>
  >;
};

export const CommodityTypeContext = createContext<TCommodityTypeContext>({});

export const CommodityTypeProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [commodityTypes, setCommodityTypeOptions] = useState<
    CommodityTypeSummary[]
  >([]);

  const getCommodityTypeOptions = async () => {
    const commodityTypes = await getCommodityTypes();
    setCommodityTypeOptions(commodityTypes);
  };

  useEffect(() => {
    getCommodityTypeOptions();
  }, []);

  const addNewCommodityType = (newCommodity: CommodityTypeSummary) => {
    setCommodityTypeOptions([...commodityTypes, newCommodity]);
  };
  return (
    <CommodityTypeContext.Provider
      value={{
        commodityTypes,
        addNewCommodityType,
      }}
    >
      {children}
    </CommodityTypeContext.Provider>
  );
};

export const useCommodityTypes = () => useContext(CommodityTypeContext);
