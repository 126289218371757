import { QueryParams } from '../../models';

export const helpTextDOTMC: { [key: string]: Array<string> } = {
  DOT: [
    'Department of Transportation number',
    'Assigned to commercial motor carriers',
    '6 digits to 12 digits in length',
    'Lengths may differ by state or carrier',
    'Assigned by the FMCSA',
  ],
  MC: [
    'Motor Carrier number',
    'Applies to carriers of regulated commodities in interstate commerce',
    'Usually 7 digits in length',
    'Lengths may vary',
  ],
};
export const loginMethodObject: { [key: string]: string } = {
  'Unique Identifier': 'Unique Identifier',
  DOT: 'DOT Number',
  MC: 'MC Number',
};

export const regitrationPageText = {
  whatIsThis: "what's this?",
  Idontknow: "I don't know",
  characterMinimum: '8 character minimum',
  specialCharacter: '1 special character',
  MCNumber: 'MC Number',
  DOTNumber: 'DOT Number',
  signIn: 'Sign In',
  alreadyAccount: ' Already have an account?',
  dayFreetrial: '30 day free trial',
  CompleteVerification: 'Complete Verification',
  CompleteVerificationInfo:
    'To complete the verification process and begin using your LoadOps account, please follow these steps:',
  option1:
    'Check your email inbox, including your spam or junk folders, for a message from LoadOps.',
  option2:
    'Click the verification link in the email to access our platform and start streamlining your operations with LoadOps',
  LoadOpsPrivacy:
    'By clicking on sign-up, you agree to LoadOps Privacy Policy and Terms & Conditions',
};

export const regitrationPageStyle = {
  textInputRadius: { sx: { borderRadius: '16px' } },
  logoStyle: { mb: '10px', textAlign: '-webkit-center' },
  formContainer: { marginBottom: '20px', width: '100%' },
  inputBoxStyle: { width: '100%', mb: '30px' },
  mainContainer: { marginBottom: '15px', position: 'relative' },
  buttonGroupStyle: { width: '100%', mb: '50px', position: 'relative' },
  buttonStyle: {
    width: '100%',
    height: '54px',
    borderRadius: '16px',
    color: 'rgba(255, 255, 255, 0.6)',

    borderColor: 'rgba(255, 255, 255, 0.6)',
  },

  DOTStyle: {
    position: 'absolute',
    top: '58px',
    left: '10%',
    color: 'primary.main',
    cursor: 'pointer',
  },
  buttonMcStyle: {
    width: '100%',
    height: '54px',
    color: 'rgba(255, 255, 255, 0.6)',
    borderColor: 'rgba(255, 255, 255, 0.6)',
  },
  MCStyle: {
    position: 'absolute',
    top: '58px',
    left: '42%',
    color: 'primary.main',
    cursor: 'pointer',
  },
  modelDOT: {
    color: 'white',
    position: 'absolute',
    background: '#1F2E3D',
    width: '400px',
    right: '650px',
    mt: '-7%',
    borderRadius: '40px',
    p: '25px',
    boxShadow:
      '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
  },
  modelGridDOT: { fontSize: '24px', padding: ' 5px 20px 5px 45px' },
  listStyle: {
    pl: '0px',
    display: 'list-item',
    // listStylePosition: 'inside',
    fontSize: '14px',
    pt: '4px',
    pb: '4px',
  },
  firstNameStyle: { width: '50%', mb: '30px', pr: '5px' },
  lasrNameStyle: { width: '50%', mb: '30px', pl: '5px' },
  passwordStyle: {
    borderRadius: '16px',
    color: 'text.secondary',
    width: '100%',
    mb: '5px',
  },
  passwordRulesStyle: {
    color: 'rgba(255, 255, 255, 0.6)',
    display: 'inline-grid',
    mb: '15px',
    pl: '15px',
  },
  linkStyle: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  singInStyle: {
    color: 'text.primary',
    mt: 2,
    mb: 1,
    textAlign: 'center',
    fontSize: '14px',
    display: 'flex',
  },
  singInContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  privacyTextStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  privacyInfoStyle: {
    color: 'text.primary',
    mt: 2,
    mb: 1,
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 300,
    width: '308px',
  },
  verificationStyle: { marginBottom: '15px', height: '100%' },
  verificationListStyle: {
    listStyleType: 'disc',
    listStylePosition: 'inside',
    color: 'text.primary',
    pl: '20px',
  },
  verificationListItemStyle: {
    pl: '0px',
    display: 'list-item',
    listStyleType: 'number',
    listStylePosition: 'outside',
  },
  verificationSendStyle: {
    borderRadius: '14px',
    width: '100%',
  },

  inputAddressBoxStyle: { width: '100%', mb: '30px' },
  inputStateBoxStyle: { width: '30%', mb: '30px', ml: '15px' },
  inputciteBoxStyle: { width: '31%', mb: '30px' },
  inputstateBoxStyle: { borderRadius: '16px' },
};

export const formErrorObject = {
  firstName: '',
  lastName: '',
  email: '',
  isEmail: false,
  isDot: false,
  password: false,
  organizationName: '',
  dot: '',
  address: '',
  city: '',
  zipcode: '',
};
export const registrationFormdefaultData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  organizationName: '',
  loginMethod: 'DOT',
  dot: '',
  address: '',
  city: '',
  // state: '',
  zipcode: '',
};

export class RegistrationVerificationModel extends QueryParams {
  dot?: string;
  email?: string;
}
export class RegistrationDotValidationModel extends QueryParams {
  dot?: string;
}
export interface RegisterVerificationPageModel {
  email: string;
  organizationName: string;
  loginMethod?: string;
  dot: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}

export interface RegisterReCreateModel {
  organizationId?: number;
  ipAddress?: string;
}
