import React, { ReactElement } from 'react';
import AxeleTimeline, {
  TimelineProps,
} from '../../../../ui-kit/components/AxeleTimeline';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';

export interface GroupedTimelineProps<TRecord> extends TimelineProps<TRecord> {
  label: React.ReactNode;
}

const GroupedTimeline = <TRecord,>({
  label,
  ...TimeLineProps
}: GroupedTimelineProps<TRecord>): ReactElement<any, any> | null => {
  return (
    <Box>
      <Typography
        sx={(theme) => ({
          ...theme.typography.subtitle1,
        })}
      >
        {label}
      </Typography>
      <Box>
        <AxeleTimeline {...TimeLineProps} />
      </Box>
    </Box>
  );
};

export default GroupedTimeline;
