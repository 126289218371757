import { QueryParams } from '../commonMixed';

export class GetPaginatedContractListParams extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  sort!: string;
  contractName!: string;

  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class GetContractOneRequest extends QueryParams {
  organizationId!: number;
  id: string;
  constructor(dto: any) {
    super(true);
    this.id = dto.id;
  }
}

export class GetBestMatchContractParams extends QueryParams {
  organizationId!: number;
  customerId!: string;
  contractLaneRateItemId!: string;
  equipmentTypeList!: Array<string>;
  weight!: number;
  customerNameFilter!: Array<string | number>;
  mode!: string;
  pickupCity!: string;
  dropoffCity!: boolean;
  pickupState!: string;
  dropoffState!: string;
  pickupZip!: string;
  dropoffZip!: string;
  totalMiles!: number;

  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class ContractListData {
  customerTotal!: number;
  vendorTotal!: number;
  locationTotal!: number;
}
export class ContractDetailsDTO {
  organizationId?: number;
  id?: string;
  name?: string;
  notes?: string;
  customerId?: string;
  rateCount?: number;
  customerName?: string;
  effectiveDate?: string;
  expDate?: string;
  rateMethod?: string;
  createdDate?: string;
  status?: string;
  accessorialItems?: any[];
  assignedDocuments?: any[];
  createdBy?: string;
  modifiedBy?: any;
  modifiedDate?: string;

  constructor(data: any) {
    Object.assign(this, data);
    // this.id = data.id;
    // this.name = data.name;
    // this.customerId = data.customerId;
    // this.rateCount = data.rateCount;
    // this.customerName = data.customerName;
    // this.effectiveDate = data.effectiveDate;
    // this.expDate = data.expDate;
    // this.rateMethod = data.rateMethod;
    // this.createdDate = data.createdDate;
    // this.status = data.status;
  }
}
