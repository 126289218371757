/* eslint-disable @typescript-eslint/no-explicit-any */
import Grid from '@mui/material/Grid';
import React from 'react';
import { Controller } from 'react-hook-form';
import TextInput from '../../../ui-kit/components/TextField';

export interface TextInputProps {
  id?: string;
  label?: string;
  control?: any;
  name: string;
  variant?: any;
  defaultValue?: any;
  rows?: number;
  multiline?: boolean;
  type?: any;
  styleProps?: any;
  style?: any;
  sizes?: any;
  required?: boolean;
  error?: boolean | undefined;
  helperText?: any;
  onChange?: () => {};
  InputProps?: any;
  onBlur?: () => {};
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  onChangeCallback?: any;
  onBlurCallback?: any;
  onlyNumbers?: boolean;
}

const TextFieldForm: React.FC<TextInputProps> = ({
  control,
  name,
  onChangeCallback,
  variant = 'standard',
  sizes = { xs: 12 },
  style = {},
  styleProps = { width: '100%' },
  helperText,
  onBlurCallback,
  onlyNumbers = false,
  ...restProps
}) => {
  return (
    <Grid xs={sizes.xs} item style={{ width: '100%', ...style }}>
      <Controller
        render={({ field: { onChange }, fieldState: { error } }) => (
          <TextInput
            onChange={(event: any) => {
              if (onlyNumbers && isNaN(+event.target.value)) {
                return;
              }
              onChangeCallback && onChangeCallback(event.target.value);
              onChange(event);
            }}
            onBlur={(event: any) => {
              onBlurCallback && onBlurCallback(event.target.value, name);
            }}
            variant={variant}
            error={!!error}
            helperText={error ? error.message : helperText}
            styleProps={styleProps}
            {...restProps}
          />
        )}
        name={name}
        control={control}
        defaultValue=""
      />
    </Grid>
  );
};

export default React.memo(TextFieldForm);
