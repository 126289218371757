import React from 'react';
import { Grid, Typography } from '@mui/material';

export default function ProviderDescription({ currentCustomerData }: any) {
  return (
    <Grid item xs={9}>
      <Typography variant="h4" sx={{ color: 'primary.main' }}>
        {currentCustomerData && currentCustomerData.providerName}
      </Typography>
      <Typography
        sx={{
          letterSpacing: '0.15px',
          color: 'text.secondary',
          pt: '35px',
        }}
      >
        {currentCustomerData && currentCustomerData.description}
      </Typography>
    </Grid>
  );
}
