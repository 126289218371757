import { Box, IconButton, Modal, Slide } from '@mui/material';
import NewOptytmizationLoader from './loaders/newOptimizationAnimation';
import { LoaderContainerStyles, ModalContainerStyles } from '../styles';
import { LoadAiText } from '../static/loadAiText';
import ResumeOptytmizationLoader from './loaders/resumeOptimizationAnimation';
import { CloseOutlined } from '@mui/icons-material';

const OptytmizationLoader = ({
  resumePreviousOptimization,
  onClose,
}: {
  resumePreviousOptimization?: boolean;
  onClose?: () => void;
}) => {
  return (
    <Modal
      open={true}
      aria-labelledby="bulk-optymization"
      aria-describedby="bulk-optymization-modal"
      sx={{ ...ModalContainerStyles, display: 'flex' }}
    >
      <Slide direction="up" in={true} mountOnEnter>
        <Box id="loader-container" sx={LoaderContainerStyles}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '30px',
              position: 'relative',
            }}
          >
            <Box>
              <LoadAiText />
            </Box>
            <IconButton
              onClick={onClose}
              sx={{ position: 'absolute', right: '20px' }}
            >
              <CloseOutlined sx={{ color: '#FFF' }} />
            </IconButton>
          </Box>
          {resumePreviousOptimization ? (
            <ResumeOptytmizationLoader />
          ) : (
            <NewOptytmizationLoader />
          )}
        </Box>
      </Slide>
    </Modal>
  );
};

export default OptytmizationLoader;
