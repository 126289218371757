import { observer } from 'mobx-react';
import { useState } from 'react';
import { carrierService } from '../../../../api';
import LongMenu from '../../../../common/LongMenu';
import { useCarrierPermission } from '../../../../common/hooks/useCarrierPermission';
import {
  CarrierDTO,
  CarrierMessageResponse,
  GetCarrierOneRequest,
  UpdateCarrierStatusRequest,
} from '../../../../models/DTOs/Carrier/Requests';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import { ESecondaryDetailsPanelType } from '../../../../views/dispatch2/constants/types';
import { ECarrier3DotMenuType } from '../../constants/types';

interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}
interface CarrierDetailsPanelActionsSectionProps {
  on3DotMenuActionCallback?: (params: {
    type: ECarrier3DotMenuType;
    data: CarrierDTO;
    response: any;
  }) => void | Promise<void>;
  carrierDTO: CarrierDTO;
}
export const CarrierDetailsPanelActionsSection = observer(
  ({
    carrierDTO,
    on3DotMenuActionCallback,
  }: CarrierDetailsPanelActionsSectionProps) => {
    const { trackPromise } = useDetailsPanelStore;
    const { hasCarrierEditPermission, hasCarrierDeletePermission } =
      useCarrierPermission();

    const [current3DotMenu, setCurrent3DotMenu] =
      useState<ECarrier3DotMenuType | null>(null);

    const [secondaryPopup, setSecondaryPopup] = useState<{
      title: string;
      body: string;
      onAction?: any;
      onClose?: any;
      cancelText?: string;
    } | null>(null);

    const onCloseMenu = () => {
      setCurrent3DotMenu(null);
      setSecondaryPopup(null);
    };

    const options: Action[] = [];

    if (hasCarrierEditPermission) {
      if (carrierDTO.status === ECarrier3DotMenuType.INACTIVE) {
        options.push({
          name: `Activate`,
          action: (): void => {
            setCurrent3DotMenu(ECarrier3DotMenuType.ACTIVE);
          },
        });
      }
      if (carrierDTO.status === ECarrier3DotMenuType.ACTIVE) {
        options.push({
          name: `Deactivate`,
          action: (): void => {
            setCurrent3DotMenu(ECarrier3DotMenuType.INACTIVE);
          },
        });
      }
    }
    if (hasCarrierDeletePermission) {
      options.push({
        name: `Delete`,
        action: (): void => {
          setCurrent3DotMenu(ECarrier3DotMenuType.DELETE);
        },
      });
    }

    const onDeleteCarrierHandler = async () => {
      onCloseMenu();
      const response = await trackPromise(
        carrierService.deleteCarrier(
          new GetCarrierOneRequest({
            id: carrierDTO.id,
          })
        ),
        ESecondaryDetailsPanelType.CARRIER
      );
      if (response instanceof CarrierMessageResponse) {
        on3DotMenuActionCallback?.({
          type: ECarrier3DotMenuType.DELETE,
          data: carrierDTO,
          response: response,
        });
      } else {
        setSecondaryPopup({
          title: 'Delete Carrier',
          body: `The carrier cannot be removed as it is connected to load/payment`,
          onAction: undefined,
          onClose: onCloseMenu,
          cancelText: 'OK',
        });
      }
    };
    const onUpdateCarrierStatusHandler = async (type: ECarrier3DotMenuType) => {
      onCloseMenu();
      const updatedCarrierDTO = await trackPromise(
        carrierService.updateCarrierStatus(
          new UpdateCarrierStatusRequest({
            id: carrierDTO.id,
            status: type,
          })
        ),
        ESecondaryDetailsPanelType.CARRIER
      );

      if (updatedCarrierDTO instanceof CarrierDTO) {
        on3DotMenuActionCallback?.({
          type: type,
          data: updatedCarrierDTO,
          response: updatedCarrierDTO,
        });
      } else {
        setSecondaryPopup({
          title:
            type == ECarrier3DotMenuType.INACTIVE
              ? 'Deactivate Carrier'
              : 'Activate Carrier',
          body:
            type == ECarrier3DotMenuType.INACTIVE
              ? 'Warning: You cannot deactivate this carrier as there are non-completed assignments'
              : 'Warning: You cannot activate this carrier',
          onAction: undefined,
          onClose: onCloseMenu,
          cancelText: 'OK',
        });
      }
    };

    return (
      <>
        <LongMenu color={'primary.contrast'} options={options} />

        {current3DotMenu === ECarrier3DotMenuType.DELETE && (
          <DeletePopup
            open={true}
            title={`Delete Carrier?`}
            body={`Are you sure you want to delete the carrier?`}
            onAction={onDeleteCarrierHandler}
            onClose={onCloseMenu}
            buttonText={'Delete'}
          />
        )}

        {current3DotMenu === ECarrier3DotMenuType.INACTIVE && (
          <DeletePopup
            open={true}
            title={`Deactivate Carrier?`}
            body={`Are you sure you want to deactivate the carrier? Please note that the carrier will be non-visible for making assignments.`}
            onAction={() => {
              onUpdateCarrierStatusHandler(ECarrier3DotMenuType.INACTIVE);
            }}
            onClose={onCloseMenu}
            buttonText={'Deactivate'}
          />
        )}

        {current3DotMenu === ECarrier3DotMenuType.ACTIVE && (
          <DeletePopup
            open={true}
            title={`Activate Carrier?`}
            body={`Are you sure you want to activate this carrier?`}
            onAction={() => {
              onUpdateCarrierStatusHandler(ECarrier3DotMenuType.ACTIVE);
            }}
            onClose={onCloseMenu}
            buttonText={'Activate'}
          />
        )}

        {secondaryPopup && (
          <DeletePopup
            open={true}
            title={secondaryPopup.title}
            body={secondaryPopup.body}
            onAction={undefined}
            onClose={secondaryPopup.onClose}
            cancelText={secondaryPopup.cancelText}
          />
        )}
      </>
    );
  }
);
