import { IMaintenanceHistory } from '../../../../models/DTOs/maintenance/maintenanceHistory/Model';
import { remove } from 'lodash';
import { IGroupedMaitHistoriesByTimeline } from '../context/MaintenanceHistoryContext';

export const groupedMaitHistoriesByTimeline = (
  data: IMaintenanceHistory[]
): IGroupedMaitHistoriesByTimeline => {
  const list = [...data];
  const dateNow = new Date();
  const thisMonth = remove(list, (item: IMaintenanceHistory) => {
    return (
      new Date(item.completedDate).getMonth() === dateNow.getMonth() &&
      new Date(item.completedDate).getFullYear() === dateNow.getFullYear()
    );
  });
  const thisYear = remove(list, (item: IMaintenanceHistory) => {
    return new Date(item.completedDate).getFullYear() === dateNow.getFullYear();
  });
  return {
    thisMonth: thisMonth,
    thisYear: thisYear,
    theRest: list,
  };
};
