export const terminalStyles = {
  saveModal: {
    backgroundColor: 'primary.contrast',
    opacity: 0.5,
  },
  saveModalWrapper: {
    backgroundColor: 'primary.main',
    borderRadius: 6,
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
  },
  saveModalButtonWrapper: {
    backgroundColor: 'primary.contrast',
    borderRadius: 6,
    borderWidth: 1,
  },
  saveChangesText: {
    color: 'text.secondary',
    fontSize: 14,
    padding: 3,
    textAlign: 'center',
  },
  saveModalButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 16,
  },
  saveModalNeg: {
    textTransform: 'none',
    color: 'primary.main',
    borderRadius: 2,
    fontWeight: '400',
  },
  saveModalPos: {
    textTransform: 'none',
    backgroundColor: 'primary.main',
    borderRadius: 2,
  },
  saveButton: {
    textTransform: 'none',
    backgroundColor: 'primary.main',
    borderRadius: 2,
    marginTop: 2,
  },
  mainContainer: {
    overflow: 'hidden',
  },
  tabPanel: {
    paddingRight: 4,
    paddingLeft: 4,
    height: 60,
    width: 150,
    minHeight: 0,
    fontSize: 12,
    marginLeft: 2,
  },
};

export const addTerminalStyles = {
  popoverWrapper: {
    backgroundColor: 'error.main',
    width: 500,
    borderRadius: 12,
    overflow: 'hidden',
  },
  popoverInner: {
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    overflow: 'hidden',
  },
  terminalDetailsWrapper: {
    borderRadius: 12,
    overflow: 'auto',
    maxHeight: '82vh',
  },
  terminalDetailsText: {
    fontSize: 18,
    fontWeight: '600',
    padding: 2,
  },
  popoverButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 16,
  },
  buttonNag: {
    textTransform: 'none',
    marginRight: 2,
  },
  buttonPos: {
    textTransform: 'none',
    backgroundColor: 'primary.main',
  },
  shake: {
    zIndex: 2000,
    opacity: 5,
    padding: '50px 45px',
    inset: 0,
    position: 'fixed',
    backdropFilter: 'blur(10px)',
  },
  wrapper: {
    width: '95%',
    borderRadius: 12,
    overflow: 'auto',
    left: '3%',
    position: 'fixed',
  },
  terminalModal: {
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 2,
    overflow: 'hidden',
  },
};

export const deleteModalStyles = {
  deleteText: {
    textTransform: 'none',
    borderRadius: 2,
  },
  modalWrapper: {
    backgroundColor: 'primary.contrast',
    opacity: 0.5,
  },
  modalParent: {
    backgroundColor: 'error.main',
    borderRadius: 6,
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
  },
  modalInner: {
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  headerButton: {
    textTransform: 'none',
    color: 'primary.contrast',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonWrapper: {
    backgroundColor: 'primary.contrast',
    borderRadius: 12,
    borderWidth: 1,
  },
  deleteTitle: {
    color: 'text.secondary',
    fontSize: 14,
    padding: 3,
    textAlign: 'center',
  },
  button: {
    textTransform: 'none',
    fontWeight: '400',
  },
};

export const emptyStyles = {
  mainContainer: {
    padding: 40,
  },
  emptyTitle: {
    color: 'primary.main',
    fontSize: 60,
    fontWeight: '400',
  },
  emptySub: {
    color: 'text.secondary',
    fontSize: 20,
    fontWeight: '400',
  },
  button: {
    textTransform: 'none',
    backgroundColor: 'primary.main',
    marginTop: 3,
  },
};

export const bodyStyles = {
  mainContainer: {
    height: '90%',
    overflow: 'auto',
  },
  defaultLogoNameWrapper: {
    backgroundColor: 'primary.main',
    alignItems: 'center',
    borderRadius: 2,
  },
  defaultLogoText: {
    color: 'primary.contrast',
    fontSize: 16,
    padding: 2,
    textAlign: 'center',
  },
  recordWrapper: {
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: 'text.disabled',
    width: '95%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bodyWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  bodytitle: {
    color: 'text.primary',
    fontSize: 20,
    fontWeight: '500',
  },
  bodySub: {
    color: 'text.secondary',
    fontSize: 16,
    fontWeight: '400',
  },
  nameTitle: {
    color: 'primary.main',
    fontSize: 34,
    fontWeight: '400',
  },
  nameSub: {
    color: 'text.secondary',
    fontSize: 16,
    fontWeight: '400',
  },
};

export const companyLogoStyles = {
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const logoStyle = {
  width: 100,
  hight: 40,
};

export const customLogo = {
  border: '1px solid',
  borderRadius: '14px',
  background: '#2B64CB',
  height: '60px',
  alignItems: 'center',
  fontWeight: 500,
  color: '#FFFFFF',
  p: '14px 20px',
};

export const contentValueStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const logoErrorStyle = {
  display: 'flex',
  color: '#d32f2f',
  justifyContent: 'flex-end',
};
