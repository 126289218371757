import React, { ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import {
  CloseOrCancelButton,
  ECloseOrCancelButton,
} from './CloseOrCancelButton';
import { BaseStatusChip } from '../../../common/Ui/StatusComponent/StatusComponent';
import { Box } from '@mui/material';

const fixedHeight = 58;
interface IProps {
  title: ReactNode | string;
  showExpandIcon?: boolean;
  onClose: () => void;
  onExpand?: () => void;
  onResetChangesHandler?: () => void;
  renderCustomTitle?: (data: any) => JSX.Element | undefined;
  actionsRenderer?: (data: any) => JSX.Element;
  data: any;
  isDirty: boolean;
  showCloseIcon?: boolean;
  isLastIndex?: boolean;
  isFullScreen?: boolean;
}

const Title: React.FC<IProps> = ({
  title = '',
  onClose,
  onExpand,
  onResetChangesHandler,
  showExpandIcon,
  renderCustomTitle,
  actionsRenderer,
  data,
  isDirty,
  showCloseIcon = true,
  isLastIndex,
  isFullScreen,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: fixedHeight,
        padding: '10px 16px 18px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: `${theme?.palette?.leftMenuCollapsed?.canvas}`,
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        // position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: fixedHeight,
          overflow: 'hidden',
        }}
      >
        <Tooltip title={title}>
          <Typography
            sx={{
              color: 'primary.contrast',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {title}
          </Typography>
        </Tooltip>
        {renderCustomTitle && renderCustomTitle(data)}
      </div>

      <div style={{ display: 'flex' }}>
        {data?.id &&
          !isDirty &&
          ((isFullScreen && isLastIndex) || !isFullScreen) && (
            <div>{actionsRenderer?.(data)}</div>
          )}

        {!isDirty && showExpandIcon && (
          <CloseOrCancelButton
            onClose={() => {
              onExpand?.();
            }}
            isDirty={isDirty}
            type={ECloseOrCancelButton.EXPAND_ICON}
          />
        )}
        {showCloseIcon && (
          <CloseOrCancelButton
            onClose={onClose}
            isDirty={isDirty}
            type={ECloseOrCancelButton.CLOSE_ICON}
          />
        )}
      </div>
    </Box>
  );
};

export default Title;
