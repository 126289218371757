import { MomentInput } from 'moment';
import { useEffect, useState } from 'react';
import { IDriverLoadsGroupAssignment } from '../../models/DTOs/Dispatch/Dispatch';
import TimeoffDateTime from '../../utils/timeoffDateTime';
import DispatchController from '../../views/dispatch/utils/Controller';
import { ELoadStatus } from '../LoadTabPanel/constants/constants';
import FormDialog from '../Ui/FormDialog';
import { MarkProgressTimeoffForm } from './components/Form/MarkProgressTimeoffForm';
import { MarkProgressTimeoffDialogProps } from './types';
import { MarkProgressTimeoffFormValidationSchema } from './utils/validationSchema';
interface IMarkProgressTimeoffFormData {
  actualTimeOfArrival: MomentInput;
  actualTimeOfCompletion: MomentInput;
  actualTimeOfArrivalChecked: boolean;
  actualTimeOfCompletionChecked: boolean;
}

export const MarkProgressTimeoffDialog = ({
  data,
  onClose,
  hocOnAction,
  action,
  getDriverLoadsGroupAssignment,
}: MarkProgressTimeoffDialogProps): JSX.Element => {
  const [markProgressTimeoffFormData, setMarkProgressTimeoffFormData] =
    useState<IMarkProgressTimeoffFormData>({
      actualTimeOfArrival: null,
      actualTimeOfCompletion: null,
      actualTimeOfArrivalChecked: false,
      actualTimeOfCompletionChecked: false,
    });

  const [currentLoadGroupAssignment, setCurrentLoadGroupAssignment] =
    useState<IDriverLoadsGroupAssignment | null>(null);

  const init = async (): Promise<void> => {
    const loadGroupAssignment: IDriverLoadsGroupAssignment | null =
      await getDriverLoadsGroupAssignment();
    setCurrentLoadGroupAssignment(loadGroupAssignment);
    const currStop = loadGroupAssignment?.stops[0] || null;
    if (currStop) {
      setMarkProgressTimeoffFormData({
        actualTimeOfArrival: currStop.actualTimeOfArrival,
        actualTimeOfCompletion: currStop.actualTimeOfCompletion,
        actualTimeOfArrivalChecked: !!currStop.actualTimeOfArrival,
        actualTimeOfCompletionChecked: !!currStop.actualTimeOfCompletion,
      });
    }
  };

  useEffect(() => {
    init();
  }, [data?.id]);

  const onAction = async (formData: any): Promise<void> => {
    const stop = {
      ...currentLoadGroupAssignment?.stops[0],
      actualTimeOfArrival: formData.actualTimeOfArrivalChecked
        ? TimeoffDateTime.dateToISOString(formData.actualTimeOfArrival)
        : null,
      actualTimeOfCompletion: formData.actualTimeOfCompletionChecked
        ? TimeoffDateTime.dateToISOString(formData.actualTimeOfCompletion)
        : null,
      stopStatus: formData.actualTimeOfCompletionChecked
        ? 'COMPLETED'
        : 'ARRIVED',
    };

    const response =
      await DispatchController.instance().revertOrActivateTimeoff({
        ...currentLoadGroupAssignment,
        loadStatus: formData.actualTimeOfCompletionChecked
          ? ELoadStatus.LOAD_COMPLETED
          : ELoadStatus.IN_TRANSIT,
        stops: [stop],
      });

    if (response) {
      await onClose();
      await hocOnAction?.(action);
    }
  };

  const contentRenderer = () => {
    return <MarkProgressTimeoffForm />;
  };

  return (
    <FormDialog
      data={markProgressTimeoffFormData}
      titleText={'Mark Progress'}
      actionLabel="Save"
      open
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={contentRenderer}
      validationSchema={MarkProgressTimeoffFormValidationSchema}
    ></FormDialog>
  );
};
