import { MenuOutlined, QuestionAnswerOutlined } from '@mui/icons-material';
import {
  Avatar,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { MouseEvent, ReactNode, useState } from 'react';
import IconsComponent from '../../../ui-kit/components/IconsComponent';

import BreadCrumbs from '../BreadCrumbs';
import ButtonImproved from '../ButtonImproved';
import { NotificationTray } from '../NotificationTray';
import UserDropDown from './UserDropDown';
import { getClassesStyles, topMenuStyles } from './classes';
import { NotificationListTypes } from './interface';

interface TopMenuProps {
  subscriptionType?: string;
  isAdmin?: boolean;
  redirectToSubscriptionPage?: () => void;
  logout?: () => void;
  theme?: string;
  setTheme?: () => void;
  userName?: string;
  redirectToUserPage?: () => void;
  userImage?: string;
  daysLeft?: number;
  XPPoints?: any;
  page?: {
    iconName?: string;
    source?: string;
    sectionName?: string;
    pageName?: string;
    partition?: string;
  };

  onSearchText?: () => void;
  onSearch?: () => void;
  unreadCount?: number;
  notificationList?: NotificationListTypes[];
  onNotificationClicked?: any;
  getNotificationTrayList?: any;
  anchorEl?: any;
  setAnchorEl?: any;
  pastDue?: number | undefined;
  getIsTerminalEnabled?: boolean | undefined;
  pastDueClicked?: () => void;
  onClickMenuHandler?: (event: MouseEvent) => void;
  menuTerminalRender?: () => ReactNode;
  isSubscriptionView?: boolean;
  showResourceCenter?: boolean;
  trialDays?: number | undefined;
  userData?: {} | any;
  isOwnerAdmin?: boolean;
}

const TopMenu = ({
  redirectToUserPage,
  subscriptionType,
  isAdmin,
  redirectToSubscriptionPage,
  logout,
  theme,
  setTheme,
  userName,
  userImage,
  daysLeft,
  XPPoints,
  page = {},
  onSearchText,
  onSearch,
  unreadCount,
  notificationList,
  onNotificationClicked,
  getNotificationTrayList,
  anchorEl,
  setAnchorEl,
  pastDue,
  getIsTerminalEnabled = true,
  pastDueClicked,
  onClickMenuHandler,
  menuTerminalRender,
  isSubscriptionView = true,
  showResourceCenter = false,
  trialDays,
  userData,
  isOwnerAdmin,
}: TopMenuProps) => {
  const [isUserDropDownOpen, setIsUserDropDownOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [searchText, setSearchText] = useState('');

  const onCloseSearch = () => {
    setExpanded(false);
    setSearchText('');
  };
  const themeRS = useTheme();
  const topMenuStylesWithRS = topMenuStyles(themeRS);
  const classes = getClassesStyles(themeRS);

  return (
    <Box sx={topMenuStylesWithRS.container}>
      <IconButton
        sx={{
          ...topMenuStylesWithRS.menuIcon,
          ...topMenuStylesWithRS.onlyForMobile,
        }}
        onClick={onClickMenuHandler}
      >
        <MenuOutlined
          sx={{
            color: 'primary.contrast',
          }}
        />
      </IconButton>

      <Box sx={topMenuStylesWithRS.notForMobile}>
        <BreadCrumbs {...page} />
      </Box>

      <Box sx={topMenuStylesWithRS.onlyForMobile}>
        <Typography
          sx={{
            color: 'primary.contrast',
            textAlign: 'center',
            textTransform: 'capitalize',
            fontFamily: 'Oxanium',
            fontSize: '17px',
            fontWeight: '600',
            lineHeight: '24px',
          }}
        >
          {page.pageName ? page.pageName : page.sectionName?.toLowerCase()}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {trialDays ? (
          <Box sx={classes.daysleft}>
            {trialDays} Days Left In Trial
            <Tooltip
              title={
                isOwnerAdmin
                  ? ''
                  : `Contact ${userData?.firstname || '-'} ${
                      userData?.lastname || '-'
                    } (${userData?.email}) to activate`
              }
            >
              <Button
                size="small"
                variant={'contained'}
                sx={classes.activateBtnStyle}
                onClick={() => {
                  if (isOwnerAdmin)
                    redirectToSubscriptionPage && redirectToSubscriptionPage();
                }}
              >
                Activate!
              </Button>
            </Tooltip>
          </Box>
        ) : (
          ''
        )}

        {!expanded && (
          <>
            {pastDue ? (
              <Box sx={classes.due} onClick={pastDueClicked}>
                Payment Past Due! Your account will be cancelled in {pastDue}{' '}
                days
              </Box>
            ) : null}
            {subscriptionType === 'trial' && isAdmin ? (
              <>
                <Box sx={classes.daysleft}>20 Days Left</Box>
                <Box sx={{ marginRight: '13px' }}>
                  <ButtonImproved
                    label="Upgrade"
                    variant="outlined"
                    onClick={redirectToSubscriptionPage}
                    styleProps={{
                      backgroundColor: 'primary.contrast',
                    }}
                  />
                </Box>
              </>
            ) : null}
            <Box sx={topMenuStylesWithRS.notForMobile}>
              {getIsTerminalEnabled ? menuTerminalRender?.() : <></>}
            </Box>
          </>
        )}
        <ClickAwayListener onClickAway={onCloseSearch}>
          <Box sx={classes.search} onClick={onSearch}>
            <IconsComponent
              source="MUIcons"
              iconName="SearchOutlined"
              styleProps={{
                color: 'primary.contrast',
              }}
            />
          </Box>
        </ClickAwayListener>

        <Button
          sx={{
            color: 'primary.main',
            border: '1px solid',
            borderColor: 'tabStrip.actionIcon',
            borderRadius: '6px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginRight: '13px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'primary.outlinedHoverBackground',
            },
            ...topMenuStylesWithRS.notificationIconSxProps,
          }}
          id="resourceCenter"
        >
          <QuestionAnswerOutlined sx={{ color: 'primary.contrast' }} />
        </Button>
        {!expanded && (
          <>
            <NotificationTray
              notificationIconSxProps={
                topMenuStylesWithRS.notificationIconSxProps
              }
              unreadCount={unreadCount}
              notificationList={notificationList}
              onNotificationClicked={onNotificationClicked}
              getNotificationTrayList={getNotificationTrayList}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />

            <Box
              sx={{
                position: 'relative',
                height: '36px',
                ...topMenuStylesWithRS.notForMobile,
              }}
            >
              <Avatar
                sx={{
                  height: '36px',
                  width: '36px',
                  cursor: 'pointer',
                }}
                onClick={() => setIsUserDropDownOpen(true)}
              >
                {userName ? userName.split('')[0] : ''}
              </Avatar>

              {isUserDropDownOpen && (
                <UserDropDown
                  logout={logout}
                  handleClose={() => setIsUserDropDownOpen(false)}
                  subscriptionType={subscriptionType}
                  redirectToSubscriptionPage={() => {
                    if (redirectToSubscriptionPage)
                      redirectToSubscriptionPage();
                    setIsUserDropDownOpen(false);
                  }}
                  userName={userName}
                  redirectToUserPage={() => {
                    redirectToUserPage?.(), setIsUserDropDownOpen(false);
                  }}
                  userImage={userImage}
                  daysLeft={daysLeft}
                  XPPoints={XPPoints}
                  isSubscriptionView={
                    trialDays && !isAdmin ? false : isSubscriptionView
                  }
                  classes={classes}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TopMenu;
