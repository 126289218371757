import { QueryParams } from '../commonMixed';

export class ImportFileRequest extends QueryParams {
  dateFormat!: string;
  deductionType!: string;
  type!: string;
}

export class CancelImportRequest extends QueryParams {
  key!: string;
}

export class ExtractAndCreateRequest extends QueryParams {
  key!: string;
}

export class GetColumnsMappingRequest extends QueryParams {
  key!: string;
  headerList!: string[];
}

export class ExtractColumnsMappingRequest extends QueryParams {}

export class SaveColumnsMappingRequest extends QueryParams {}

export class GetImportedDataRequest extends QueryParams {
  key!: string;
  isWarning!: boolean;
  isError!: boolean;
  pageNumber!: number;
  pageSize!: number;
}

export class DownloadImportedFileRequest extends QueryParams {
  key!: string;
  isRejected!: boolean;
}

export class ChangeCellValueRequest extends QueryParams {}

export class ChangeAddressCellValueRequest extends QueryParams {}

export class GenerateFinalDataRequest extends QueryParams {
  key!: string;
}

export class FilterTableRequest extends QueryParams {
  key!: string;
}

export class GetMissingEntitiesRequest extends QueryParams {
  key!: string;
}

export class CreateMissingEntitiesRequest extends QueryParams {
  key!: string;
}

export class ApplyMissingEntitiesRequest extends QueryParams {
  key!: string;
}

export class GetCustomerStaticRequest extends QueryParams {}

export class GetDriverStaticRequest extends QueryParams {}

export class GetFactoringStaticRequest extends QueryParams {}

export class GetCommodityTypesRequest extends QueryParams {}

export class FinishImportRequest extends QueryParams {
  key!: string;
}

export class GetImportedLineItemsRequest extends QueryParams {
  key!: string;
}
