export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '70%',
  bgcolor: '#35446B',
  borderRadius: '16px',
  boxShadow:
    '0px 5px 6px -3px rgba(78, 101, 120, 0.25), 0px 9px 12px 1px rgba(78, 101, 120, 0.25), 0px 3px 16px 2px rgba(78, 101, 120, 0.25)',
};

export const backDropStyles = { background: 'rgba(245,248,249,0.7)' };
