/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  annotateNameAsync,
  ITrendAnalysisService,
  ServiceError,
} from '../interfaces';
import axiosInstance, { httpClient } from '../../axios/axiosInstance';
import {
  TrendAnalysisReport,
  TrendAnalysisRequest,
} from '../../models/DTOs/TrendAnalysis/TrendAnalysis';
import { omit } from 'lodash';
import { ExportExcelQueryExcel } from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

const routes = {
  weekly: '/web/interload/api/v2/trends/weekly',
  monthly: '/web/interload/api/v2/trends/monthly',
  export: {
    monthly: '/web/interload/api/v2/trends/monthly-report-export',
    weekly: '/web/interload/api/v2/trends/weekly-report-export',
  },
};

export class TrendAnalysisService extends ITrendAnalysisService {
  @annotateNameAsync
  async getTrendAnalysis(
    requestData: TrendAnalysisRequest
  ): Promise<TrendAnalysisReport | ServiceError> {
    const route = routes[requestData.type];
    const params = requestData;
    try {
      const response = await httpClient.post(
        route,
        undefined,
        params,
        undefined,
        false
      );
      return Promise.resolve(response);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'failedData',
      });
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async exportExcel(
    requestData: TrendAnalysisRequest
  ): Promise<ExportExcelQueryExcel> {
    const payload = requestData;
    const route = routes.export[requestData.type];
    try {
      const response = await axiosInstance.post(route, payload);
      return Promise.resolve(response?.data || {});
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'download',
      });
      return Promise.resolve({});
    }
  }
}
