import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { Trip } from '../../../models';

interface IGridSelectionData {
  selectedIds?: Array<string>;
  allRecords?: Array<Trip>;
}

export const isNonAvailableTripSelected = ({
  allRecords,
  selectedIds,
}: IGridSelectionData): boolean => {
  const isNonAvailableTripExist = allRecords?.some?.(
    (e) =>
      selectedIds?.includes?.(e?.id) &&
      (e?.status !== ELoadStatus.AVAILABLE || e?.onHold)
  );
  return isNonAvailableTripExist!;
};

export const isAvailableTripSelected = ({
  allRecords,
  selectedIds,
}: IGridSelectionData): boolean => {
  const isAvailableTripSelected = allRecords?.some?.(
    (e) => selectedIds?.includes?.(e?.id) && e?.status === ELoadStatus.AVAILABLE
  );
  return isAvailableTripSelected!;
};
