import { GetUpdatedManifestTripListRequest } from 'views/trips/constants/types';
import {
  CheckOrAssignAssetResponse,
  CreateTripStopResponse,
  GetRelayStopResponse,
  PaginatedTripLightList,
  PaginatedTripList,
  ResequenceTripStopResponse,
  Trip,
  TripsRevenueTotalResponse,
  TripStopResponse,
} from '../../models';
import {
  CheckOrAssignAssetRequest,
  CreateTripStopRequest,
  DeleteTripRequest,
  DeleteTripStopRequest,
  ExportTripFilterPayload,
  ExportTripFilterQueryParams,
  GetRelayStop,
  GetTripDetailByIdQueryParams,
  GetTripDetailsActionResponse,
  GetTripDetailsActionsRequest,
  GetTripStopRequest,
  PaginatedTripLightListRequest,
  PaginatedTripListRequest,
  ResequenceTripStopRequest,
  TripWarningByIdsRequest,
  UpdateDispatchRevenueQueryParams,
  UpdateDispatchRevenueResponse,
  UpdateRelayStopPayload,
  UpdateTripStatusPayload,
  UpdateTripStatusRequest,
  UpdateTripStatusResponse,
  UpdateTripStopRequest,
} from '../../models/DTOs/Trip/Requests';
import { docType } from '../../utils/Doc';
import { CreateEmptyTripDTO } from '../../views/dispatch2/components/Trip/emptyTrip/dto/createEmptyTripDto';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class ITripService extends Service {
  abstract getPaginatedTripList(
    requestData: PaginatedTripListRequest,
    skipLoader?: boolean
  ): Promise<PaginatedTripList | null | ServiceError>;

  abstract getPaginatedTripLightList(
    requestData: PaginatedTripLightListRequest
  ): Promise<PaginatedTripLightList | null | ServiceError>;

  abstract getTripStop(
    requestData: GetTripStopRequest
  ): Promise<TripStopResponse | ServiceError>;

  abstract createTripStop(
    requestData: CreateTripStopRequest
  ): Promise<CreateTripStopResponse | null | ServiceError>;

  abstract updateTripStop(
    requestData: UpdateTripStopRequest
  ): Promise<{} | null | ServiceError>;

  abstract deleteTripStop(
    requestData: DeleteTripStopRequest
  ): Promise<{} | null | ServiceError>;

  abstract resequenceTripStop(
    requestData: ResequenceTripStopRequest
  ): Promise<ResequenceTripStopResponse | null | ServiceError>;

  abstract exportToExcel(
    queryParams: ExportTripFilterQueryParams,
    payload: ExportTripFilterPayload
  ): Promise<docType | ServiceError>;

  abstract getRelayStop(
    queryParams: GetRelayStop
  ): Promise<GetRelayStopResponse | ServiceError>;

  abstract updateRelayStop(
    requestData: UpdateRelayStopPayload
  ): Promise<any | ServiceError>;

  abstract getTripDetailsActions(
    requestData: GetTripDetailsActionsRequest
  ): Promise<GetTripDetailsActionResponse | ServiceError>;

  abstract updateStatus(
    requestData: UpdateTripStatusRequest,
    payload: UpdateTripStatusPayload
  ): Promise<UpdateTripStatusResponse | ServiceError>;

  abstract deleteTrip(
    requestData: DeleteTripRequest
  ): Promise<any | ServiceError>;

  abstract getTripWarningsByIds(
    requestData: TripWarningByIdsRequest
  ): Promise<any | ServiceError>;

  abstract checkOrAssignAsset(
    requestData: CheckOrAssignAssetRequest
  ): Promise<CheckOrAssignAssetResponse | ServiceError>;

  abstract getTripsRevenueTotal(
    requestData: PaginatedTripListRequest
  ): Promise<TripsRevenueTotalResponse | ServiceError | null>;

  abstract updateTripDispatchRevenue(
    queryParams: UpdateDispatchRevenueQueryParams
  ): Promise<UpdateDispatchRevenueResponse | ServiceError | null>;

  abstract getTripDetailById(
    queryParams: GetTripDetailByIdQueryParams
  ): Promise<Trip | ServiceError>;
  abstract getMultipleTripLists(
    queryParams: GetUpdatedManifestTripListRequest
  ): Promise<Trip[] | ServiceError>;
  abstract createEmptyTrip(
    payload: CreateEmptyTripDTO
  ): Promise<any | ServiceError>;
}
