import Grid from '@mui/material/Grid';
import { Moment } from 'moment';
import React, { CSSProperties } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import DatePicker from '../Datepicker/Datepicker';

export const formatDate = (date: Moment | null, iso: boolean) => {
  if (!date) return null;

  if (iso) {
    return date?.format('YYYY-MM-DD');
  }

  return date.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
};

const FormDatePicker: React.FC<{
  iso?: boolean;
  control?: any;
  name: string;
  label: string;
  variant?: any;
  onDateChange?: any;
  required?: boolean;
  disableFuture?: boolean;
  initialDate?: any;
  styleProps?: any;
  helperText?: string;
  disabled?: boolean;
  disabledText?: boolean;
  startOfDate?: boolean;
  endOfDate?: boolean;
  sizes?: {
    xs: number;
  };
  shouldUnregister?: ControllerProps['shouldUnregister'];
  format?: boolean;
  wrapperStyles?: CSSProperties;
  icon?: React.ElementType<JSX.Element>;
  allowEmptyDate?: boolean;
}> = ({
  control,
  name,
  sizes = {},
  label,
  variant,
  onDateChange,
  required,
  disableFuture,
  initialDate,
  styleProps,
  helperText,
  disabled,
  iso = false,
  disabledText,
  shouldUnregister,
  wrapperStyles = {},
  icon,
  allowEmptyDate,
  ...restOptions
}) => {
  return (
    <Grid xs={sizes.xs} item sx={{ marginBottom: '20px', ...wrapperStyles }}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <DatePicker
              disabled={disabled}
              disabledManualInput={disabledText}
              label={label}
              variant={variant}
              onDateChange={(e: Moment | null) => {
                onDateChange?.(formatDate(e, iso));
                onChange(formatDate(e, iso));
              }}
              error={!!error}
              required={required}
              disableFuture={disableFuture}
              value={value}
              styleProps={styleProps}
              helperText={helperText || error?.message}
              icon={icon}
              allowEmptyDate={allowEmptyDate}
              {...restOptions}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue={initialDate}
        shouldUnregister={shouldUnregister}
      />
    </Grid>
  );
};

export default React.memo(FormDatePicker);
