import { Type } from 'class-transformer';
import { QueryParams } from '../commonMixed/QueryParams';
import { TerminalContentDTO } from '../Terminal/Terminal';
export class ELDRequest extends QueryParams {
  terminalIds!: Array<string> | undefined;
  integrationType!: string;
}

export class ELDResponseDTO {
  integrationRequiredCredentials!: IntegrationRequiredCredentialsDTO[];
  integrationsDTO!: IntergationsIDTO[] | [];
  providerFeatures!: ProviderFeaturesDTO[];
  providersStatusMap!: ProviderStatusMapDTO[];
}

export class IntegrationRequiredCredentialsDTO {
  id?: number;
  integrationType?: string | null;
  provider?: string;
  @Type(() => IntegrationCredentialsFieldsDTO)
  fields?: IntegrationCredentialsFieldsDTO[];
}

export class IntegrationCredentialsFieldsDTO {
  displayText?: string;
  name?: string;
  type?: string;
}
export class IntergationsIDTO {
  @Type(() => IntegrationsDataDTO)
  data!: IntegrationsDataDTO;
  id!: string;
}

export class IntegrationsDataDTO {
  name?: string;
  value?: string;
}

export class ProviderFeaturesDTO {
  id?: string;
  providerId?: number;
  supportedFeatures?: string[];
}

export class ProviderStatusMapDTO {
  providerId?: number;
  status?: string;
}

export class EditAccountRequest extends QueryParams {
  id!: string;
  integrationType!: string;
  providerId!: number;
  isValid!: boolean;
  status!: string;
  data!: [
    {
      name: string;
      value: string;
    }
  ];
  terminalIds!: ['string'];
}
export class ActivateCustomerRequest extends QueryParams {
  data!: [
    {
      name: string;
      value: string;
    }
  ];
  integrationType!: string;
  isValid!: boolean;
  organizationId!: number;
  providerId!: number;
  terminalIds!: Array<string>;
  constructor() {
    super();
  }
}

export class ActivateCustomerResponseDTO {
  data?: [{ name: string; value: string }];
  id?: string;
  organizationId?: number;
  providerId?: number;
  terminalIds?: [];
}
export class DeactivateAccountRequest extends QueryParams {
  id!: number;
  providerId!: number;
  organizationId!: number;
}
export class RefreshAccountRequest extends QueryParams {
  organizationId!: number;
  credentialId!: string;
  terminalIds!: string[] | undefined;
  integrationType!: string;
}
export class GetDriverNamesRequest extends QueryParams {
  credentialId!: string;
  pageNumber!: number;
  pageSize!: number;
  linkedFilter!: string;
  driverIds!: string[];
  pattern!: string;
}
export class DriverNamesResponseDTO {
  @Type(() => DriverNamesResponseModel)
  content!: DriverNamesResponseModel[];
  totalElements!: number;
}

export class DriverNamesResponseModel {
  id?: string;
  firstname?: string;
  lastname?: string;
  email?: string | null;
  mobileno?: string | null;
  organizationId?: number;
  axeleDriverId?: number;
  axeleDriverName?: string;
  axeleDriverSeqNumber?: string;
  axeleDriverEmail?: string | null;
  eldId?: string;
  providerId?: number;
  providerName?: string;
  credentialId?: string;
  fetchedTime?: string;
  terminalId?: string;
}

export class TractorNamesRequest extends QueryParams {
  credentialId!: string;
  pageNumber!: number;
  pageSize!: number;
  linkedFilter!: string;
  tractorIds!: string[];
  pattern!: string;
}

export class TractorNamesResponseDTO {
  @Type(() => TractorNamesResponseModel)
  content!: TractorNamesResponseModel;
  totalElements!: number;
}

export class TractorNamesResponseModel {
  id?: string;
  eldTractorVin?: string;
  axeleTractorVin?: string | null;
  tractorName?: string;
  email?: string | null;
  mobileno?: string | null;
  organizationId?: number;
  axeleTractorId?: number;
  axeleTractorName?: string;
  axeleTractorSeqNumber?: string | null;
  eldId?: string;
  providerId?: number;
  providerName?: string;
  credentialId?: string;
  fetchedTime?: string;
  terminalId?: string | null;
}
export class UnlinkDriverRequest extends QueryParams {
  credentialId!: string;
  eldDriverIds!: Array<string>;
  terminalId!: string;
}

export class UnlinkDriverResponseDTO {
  drivers?: [];
}

export class GetDriverSummaryRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  typeFilter!: string;
  nameFilter?: string;
  terminalIds?: Array<string> | null;
  excludeStatuses?: number;
}
export class GetDriverSummaryFactoreRequest extends QueryParams {
  pageNumber!: number;
  credentialId?: string;
  pageSize!: number;
  typeFilter!: string;
  nameFilter?: string;
  terminalIds?: Array<string> | null;
}

export class DriverSummaryResponseDTO {
  @Type(() => DriverSummaryResponseModel)
  content!: DriverSummaryResponseModel[];
}

export class DriverSummaryResponseModel {
  driverAxeleId?: string | null;
  driverEldId?: string | null;
  driverId?: number;
  driverName?: string;
  eldProviderId?: number | null;
  eldProviderName?: string | null;
  seqNumber?: string | null;
  terminalId?: string | null;
  optionName?: string;
  mappingName?: string;
  mappingDescription?: string;
}

export class LinkDriverRequest extends QueryParams {
  driverToEldDriverIds!: object;
  credentialId!: string;
  organizationId!: number;
}
export class LinkDriverResponseDTO {
  linkedDrivers?: [];
  unLinkedDrivers?: [];
}

export class LinkDriverValidateRequest extends QueryParams {
  axeleDriverId!: number;
  organizationId!: number;
}
export class LinkValidateResponseDTO {
  linked?: boolean;
  titleMessage?: string;
  subTitleMessage?: string;
}

export class LinkTractorValidateRequest extends QueryParams {
  axeleTractorId!: number;
  organizationId!: number;
}

export class GetAxeleTractorsRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  sort!: string;
  axeleIdFilters!: string;
  terminalIds?: Array<string> | null;
}

export class AxeleTractorResponseDTO {
  @Type(() => AxeleTractorResponseModel)
  content!: AxeleTractorResponseModel[];
}

export class AxeleTractorResponseModel {
  id?: number;
  organizationId?: number;
  tractorName?: string;
  status?: string;
  terminal?: TerminalContentDTO;
  vin?: string;
  terminalId?: string;
}

export class LinkTractorRequest extends QueryParams {
  entityToEldEntityIds!: object;
  credentialId!: string;
}

export class LinkTractorResponseDTO {
  linkedTractors?: [];
  unLinkedTractors?: [];
}
export class UnlinkTractorRequest extends QueryParams {
  credentialId!: string;
  eldTractorIds!: Array<string>;
  terminalId!: string;
}
export class CreateTractorRequest extends QueryParams {
  axeleTractorName!: string;
  axeleTractorVin!: string;
  id!: string;
  organizationId!: number;
  credentialId!: string;
  terminalId!: string;
}
export class CreateMultipleTractorRequest extends QueryParams {
  organizationId!: number;
  credentialId!: string;
  eldTractorIds!: string[];
  terminalId!: string;
}
export class CreateDriverRequest extends QueryParams {
  firstname!: string;
  lastname!: string;
  email!: string;
  axeleDriverName!: string;
  axeleDriverEmail!: string;
  eldId!: string;
  providerId!: number | null | undefined;
  providerName!: string | null | undefined;
  credentialId!: string | null | undefined;
  terminalId!: string;
}

export class CreateMultipleDriversRequest extends QueryParams {
  eldDriverIds!: string[] | null;
  credentialId!: string | undefined;
  terminalId!: string;
}
