import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, SwipeableDrawer } from '@mui/material';
import { TurvoLogo } from '../../../../../_assets/static/svgs/TurvoLogo';
import TurvoBrokerForm from './TurvoBrokerForm';

const SwipeableDrawerStyle = {
  '.MuiPaper-root': {
    width: '462px',
    height: '90%',
    bottom: '5px',
    top: 'unset',
    padding: '25px 40px 10px 32px',
    boxShadow:
      '0px 5px 6px -3px rgb(0 0 0 / 20%), 0px 9px 12px 1px rgb(0 0 0 / 14%), 0px 3px 16px 2px rgb(0 0 0 / 12%)',
    borderRadius: '16px 0px 0px 16px',
  },
};
export const TurvoBrokerModal = ({
  open,
  toggleDrawer,
  getTenderList,
  cardData,
  isEdit,
}: any) => {
  const closeSideModel = (value: boolean) => {
    toggleDrawer(value);
  };

  let formData = {};
  if (isEdit) {
    const newData = {
      id: '',
      carrierId: '',
      broker: { id: '', name: '' },
      username: '',
      password: '',
      activationName: '',
      clientId: '',
      clientSecret: '',
      turvoApiKey: '',
    } as any;

    cardData['data']?.map((data: any) => {
      if (data?.name == 'broker') {
        newData.broker.id = data?.value;
      }
      if (data?.name == 'brokerName') {
        newData.broker.name = data?.value;
      }
      if (data?.name !== 'broker' && data?.name in newData && data?.value) {
        newData[data.name] = data.value;
      }
      if (data?.name === 'displayName') {
        newData.activationName = data?.value;
      }
      if (data?.name === 'turvoApiKey') {
        newData.turvoApiKey = data?.value;
      }
    });
    formData = { ...newData };
  }
  return (
    <Box>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => closeSideModel(false)}
        onOpen={() => closeSideModel(true)}
        disableSwipeToOpen={false}
        sx={SwipeableDrawerStyle}
      >
        <Box className="drawer-content">
          <Box
            onClick={() => closeSideModel(false)}
            sx={{ float: 'right', cursor: 'pointer' }}
          >
            <CloseOutlinedIcon />
          </Box>
          <Box className="provider-logo" sx={{ pt: '25px' }}>
            <TurvoLogo />
          </Box>
          {open && (
            <TurvoBrokerForm
              closeDrawer={toggleDrawer}
              getTenderList={getTenderList}
              formData={formData}
              isEditForm={isEdit}
            />
          )}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};
