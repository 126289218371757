import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, Typography } from '@mui/material';
import Select from '../../../../common/Ui/Select';
import TextField from '../../../../common/Ui/TextField/TextField';
import FormContext from './FormContext';
import {
  paymentLineItemAmountText,
  paymentTermsLineItemText,
  paymentLineItemAmoutConfigText,
} from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewConstant';
import RadioGroup from '../../../../common/Ui/RadioGroup';
import { t } from 'i18next';
const AddNewPaymentPerLoadForm = ({}) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const {
    availablePayTypes,
    expenseCategories,
    operationModesValues,
    savedLoadPaymentTerms,
    filterOperationsModes,
  } = useContext(FormContext);
  const [selectedUnitText, SetselectedUnit] = useState<string>('Rate');
  const [selectedPayItem, SetselectedPayItem] = useState<any>({});
  const [selectedPaymentUnitList, SetSelectedPaymentUnitList] = useState<any[]>(
    []
  );
  const [selectedPaymentRecordList, SetSelectedPaymentRecordList] = useState<
    any[]
  >([]);

  const selectedUnitType = watch('unit');
  const selectedPaymentTypeId = watch('payTypeId');
  const selectedExpenseCategoryId = watch('expenseCategoryId');
  const selectedoperationMode = watch('operationMode');

  useEffect(() => {
    SetSelectedPaymentRecordList(availablePayTypes);
    setValue('operationMode', operationModesValues[0].key);
    updatedFilterList('SOLO');
  }, [availablePayTypes]);

  useEffect(() => {
    updatedFilterList(selectedoperationMode);
  }, [selectedoperationMode]);

  const findRecord = (list: any[], key: string) => {
    const result = list.find(
      (item: { itemCode: string }) => item?.itemCode === key
    );
    return result;
  };

  const updatedFilterList = (key: string): void => {
    if (availablePayTypes.length > 0) {
      const selectedData = filterList(key);
      SetSelectedPaymentRecordList(selectedData);
      setValue('availablePayTypes', selectedData);
      let defaultRecord = findRecord(selectedData, 'PER_LOADED_MILE');
      defaultRecord = defaultRecord ? defaultRecord : selectedData[0];
      setValue('payTypeId', defaultRecord.id);
      setValue(
        'expenseCategoryId',
        findRecord(expenseCategories, 'DRIVER_PAYMENT').id
      );
      SetSelectedPaymentUnitList(defaultRecord.unit);
      setValue('unit', defaultRecord.unit[0]);
    }
  };

  const filterList = (type: string): void => {
    const filterList: any[] = [];
    availablePayTypes?.map((record: { id: string }) => {
      const result = savedLoadPaymentTerms.find(
        (item: { id: string }) => item.payTypeId === record.id
      );
      if (result && result?.operationMode === type) {
      } else {
        filterList.push(record);
      }
    });
    return filterList;
  };

  useEffect(() => {
    if (!selectedPaymentTypeId) {
      return;
    }

    const foundPaymentType = availablePayTypes.find(
      (payType) => payType.id === selectedPaymentTypeId
    );
    SetselectedPayItem(foundPaymentType);
    setValue('description', foundPaymentType?.description);
    setValue('payType', foundPaymentType?.itemCode);
    setValue('unit', foundPaymentType?.unit);
    const selectedUnit = watch('unit', foundPaymentType?.unit);
    SetselectedUnit('Rate ' + paymentLineItemAmountText[selectedUnit[0]]);
    setValue('unit', selectedUnit[0]);
    filterOperationsModes(selectedPaymentTypeId);
    SetSelectedPaymentUnitList(foundPaymentType.unit);
  }, [selectedPaymentTypeId, setValue, availablePayTypes]);

  useEffect(() => {
    if (!selectedExpenseCategoryId) {
      return;
    }
    const foundEpenseCategory = expenseCategories.find(
      (expenseCategory) => expenseCategory.id === selectedExpenseCategoryId
    );
    setValue('expenseCategoryKey', foundEpenseCategory?.itemCode);
  }, [selectedExpenseCategoryId, expenseCategories, setValue]);

  useEffect(() => {
    SetselectedUnit('Rate ' + paymentLineItemAmountText[selectedUnitType]);
  }, [selectedUnitType]);

  const getRateLabel = () => {
    if (selectedUnitType === 'PERCENT') return selectedUnitText;
    if (
      selectedPaymentUnitList?.length > 1 ||
      (selectedUnitType?.[0] &&
        selectedPayItem.itemCode === 'USERDEFINED_PAYMENT')
    ) {
      return paymentLineItemAmountText[selectedUnitType];
    }
    return paymentLineItemAmoutConfigText[selectedUnitType];
  };

  return (
    <Grid container>
      <Grid container item spacing={2} xs={12}>
        <Select
          name={`operationMode`}
          label={'Operation Mode'}
          fieldName={'displayText'}
          sizes={{ xs: 6 }}
          options={operationModesValues}
          control={control}
          fieldValue={'key'}
          error={errors.operationModes}
          required
        />
        <Select
          name={`expenseCategoryId`}
          label={t('expenseCategory')}
          fieldName={'itemName'}
          sizes={{ xs: 6 }}
          options={expenseCategories}
          control={control}
          fieldValue={'id'}
          error={errors.expenseCategory}
          required
        />
      </Grid>
      {selectedPaymentRecordList.length > 0 && (
        <Select
          name={`payTypeId`}
          label={'Pay Type'}
          fieldName={'itemName'}
          sizes={{ xs: 12 }}
          options={selectedPaymentRecordList}
          control={control}
          fieldValue={'id'}
          error={errors.paymentType}
          required
        />
      )}
      {selectedPaymentUnitList.length > 1 && (
        <Grid container>
          <Grid item sx={{ pt: '9px', pb: '10px', pr: '20px' }}>
            <Typography component={'span'}>Unit </Typography>
          </Grid>
          <Grid item sx={{ pb: '10px', pl: '15px' }}>
            <RadioGroup
              row
              name="unit"
              data={selectedPaymentUnitList.map((item) => {
                return {
                  value: item,
                  label:
                    paymentTermsLineItemText[
                      item as keyof typeof paymentTermsLineItemText
                    ],
                };
              })}
            />
          </Grid>
        </Grid>
      )}
      <TextField
        defaultValue="0"
        control={control}
        name={`rate`}
        label={getRateLabel()}
        required
      />
      <TextField
        control={control}
        name={`description`}
        label="Description"
        sizes={{ xs: 12 }}
      />
    </Grid>
  );
};

AddNewPaymentPerLoadForm.defaultProps = {
  titleText: '',
};

export default AddNewPaymentPerLoadForm;
