import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import { styles } from './styles';
interface subscriptionCardProps {
  title?: string;
  titleStyle?: SxProps;
  subTitle?: string;
  subTitleStyle?: SxProps;
  containerStyle?: SxProps;
  content: ReactJSXElement;
  footer?: ReactJSXElement;
}

const SubscriptionCard = (props: any) => {
  const {
    title,
    titleStyle,
    subTitle,
    subTitleStyle,
    content,
    containerStyle,
    footer = <></>,
  } = props;
  return (
    <Stack
      display={'flex'}
      justifyContent={'space-between'}
      spacing={2}
      sx={{ ...styles.container, ...containerStyle }}
    >
      <Box>
        {title && (
          <Typography sx={{ ...styles.cardTitle, ...titleStyle }}>
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography sx={{ ...subTitleStyle }}>{subTitle}</Typography>
        )}
      </Box>
      {content}
      {footer}
    </Stack>
  );
};
export default SubscriptionCard;
