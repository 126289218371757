import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import TextField from '../../../../../common/Ui/TextField/TextField';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import FormPhoneField from '../../../../../common/Ui/FormPhoneField';
import {
  addNewFactorValidationSchema,
  CreatePayLoadFactor,
  TFactorAddPayload,
  TFactorDetails,
  TFactorpayLoadObject,
} from './FactoreUtil';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { financeIntegrationService } from '../../../../../api';
import { IRowActualData } from '../../../../../types/ELDManageTypes';
import { constantFactorText } from '../constants';
import {
  APEX_PROVIDER_ID,
  OTR_PROVIDER_ID,
  RTS_PROVIDER_ID,
  TRIUMPH_PROVIDER_ID,
} from '../../../../../subPages/invoices/constants';
import { ServiceError } from '../../../../../api/interfaces/helperTypes';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { t } from 'i18next';
export default function FactorAddForm({
  selectType,
  isEditForm,
  formData,
  selectedCardData,
  connectAuth,
  closeDrawer,
}: {
  selectType: TFactorDetails;
  isEditForm: boolean;
  formData: object;
  selectedCardData: object | undefined;
  connectAuth: (cardData: IRowActualData) => void;
  closeDrawer: (cardData: boolean) => void;
}) {
  const state = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(addNewFactorValidationSchema),
  });
  const { handleSubmit, setValue, control, reset, watch, setError } = state;
  const [editForm, setEditForm] = useState<boolean>(isEditForm);
  const [details, setDetails] = useState<object | undefined>(selectedCardData);

  const selectTypeData = watch('selectType');
  useEffect(() => {
    reset();
    if (formData && isEditForm) {
      reset(formData);
    }
    if (!isEditForm) {
      setdefaultData();
    }
    const setContextValue = selectType;
    setContextValue.isEdit = isEditForm;
    setValue('selectType', setContextValue);
  }, []);
  const setdefaultData = () => {
    if (selectType.providerId === OTR_PROVIDER_ID) {
      const phoneData = {
        countryCode: 'USA',
        extension: '',
        phone: constantFactorText.OTRFormPhoneNumber,
      };
      const OTRDefaultdata = {
        nameOnDocuments: constantFactorText.OTRFormDocumentData,
        address: constantFactorText.OTRFormAddress,
        email: constantFactorText.OTRFormEmail,
        phoneNumber: phoneData,
      };
      reset(OTRDefaultdata);
    }
    if (selectType.providerId === TRIUMPH_PROVIDER_ID) {
      setValue(
        'invoiceFactoringNote',
        constantFactorText.triumphFormDefaultData
      );
    }
    if (selectType.providerId === RTS_PROVIDER_ID) {
      setValue('invoiceFactoringNote', constantFactorText.RTSDefaultData);
    }
  };
  const onSubmitHandler = (data) => {
    delete data.selectType;
    const payLoadObject: TFactorpayLoadObject = CreatePayLoadFactor(data);
    const payload: TFactorAddPayload = {
      data: payLoadObject,
      integrationType: 'FACTORING',
      organizationId: StorageManager.getUser()?.organizationId,
      providerId: selectType.providerId,
      isValid: null,
      archive: false,
    };
    if (!editForm) {
      activateFactory(payload);
      return;
    }
    payload.id = details.id;
    payload.isValid = true;
    payload.archive = details?.archive;
    activateFactorEdit(payload);
  };
  const activateFactory = async (
    payloadData: TFactorAddPayload
  ): Promise<void> => {
    const response = await financeIntegrationService.activateFactoringProvider(
      payloadData
    );
    if (response instanceof ServiceError) {
      if (!response.composedError.message.response.data.displayNameUnique) {
        setError('displayName', {
          type: 'httpError',
          message: 'Display name should be unique',
        });
      }
      return;
    } else {
      const setContextValue = selectTypeData;
      setContextValue.isEdit = true;
      setValue('selectType', setContextValue);
      setEditForm(true);
      setDetails(response);
    }
  };
  const activateFactorEdit = async (
    payloadData: TFactorAddPayload
  ): Promise<void> => {
    const response =
      await financeIntegrationService.activateFactoringProviderUpdate(
        payloadData
      );
    if (response instanceof ServiceError) {
      return;
    } else {
      closeDrawer && closeDrawer(false);
    }
  };
  return (
    <FormProvider {...state}>
      <Stepper activeStep={0} orientation={'vertical'}>
        <Step key={22} expanded={true}>
          <StepLabel
            sx={{
              '.Mui-completed': {
                color: 'primary.main',
              },
              '.Mui-active': {
                color: 'primary.main',
              },
              '.Mui-disabled': {
                color: 'text.disabled',
              },
            }}
          >
            Enter Factoring Details
          </StepLabel>
          <StepContent>
            <TextField
              control={control}
              name={`displayName`}
              label={'Display Name'}
              sizes={{ xs: 12 }}
              required
            />
            <TextField
              control={control}
              name={`nameOnDocuments`}
              label={'Name on Documents'}
              sizes={{ xs: 12 }}
              required
            />
            <TextField
              control={control}
              name={`address`}
              label={t('address')}
              sizes={{ xs: 12 }}
              required
            />
            <TextField
              control={control}
              name={`email`}
              label={t('email')}
              sizes={{ xs: 12 }}
              required
            />
            <FormPhoneField
              control={control}
              name={`phoneNumber`}
              label={t('phone')}
              sizes={{ xs: 12 }}
              customeStyle={{ width: 'max-content' }}
              required
            />
            <FormPhoneField
              control={control}
              name={`fax`}
              label="Fax #"
              sizes={{ xs: 12 }}
              customeStyle={{ width: 'max-content' }}
            />
            <TextField
              control={control}
              name={`charge`}
              label={t('factoringCharges') + ' (%)'}
              sizes={{ xs: 12 }}
            />
            <TextField
              control={control}
              name={`invoiceFactoringNote`}
              label={'Factoring Notes on Invoice (default)'}
              sizes={{ xs: 12 }}
            />
            <Box display="flex" justifyContent="start" sx={{ height: '42px' }}>
              <ButtonImproved
                size="large"
                variant="contained"
                label={t('save')}
                styleProps={{ height: 42 }}
                onClick={handleSubmit(onSubmitHandler)}
              />
            </Box>
          </StepContent>
        </Step>
        {selectType.secondFormFieldDisplay || selectType.secondForm ? (
          <Step key={22} expanded={true}>
            <StepLabel
              sx={{
                '.Mui-completed': {
                  color: 'primary.main',
                },
                '.Mui-active': {
                  color: 'primary.main',
                },
                '.Mui-disabled': {
                  color: 'text.disabled',
                },
              }}
            >
              {selectType.displayStep || ''}
            </StepLabel>
            <StepContent>
              <Typography>{selectType.description || ''}</Typography>

              {selectType.providerId === TRIUMPH_PROVIDER_ID && (
                <>
                  <TextField
                    control={control}
                    name={`dot`}
                    label={'DOT Number'}
                    sizes={{ xs: 12 }}
                    required
                  />
                  <TextField
                    control={control}
                    name={`mcNumber`}
                    label={'MC Number'}
                    sizes={{ xs: 12 }}
                    required
                  />
                  <TextField
                    control={control}
                    name={`carrierName`}
                    label={'Carrier Name'}
                    sizes={{ xs: 12 }}
                    required
                  />
                  <TextField
                    control={control}
                    name={`carrierEmail`}
                    label={'Carrier Email'}
                    sizes={{ xs: 12 }}
                    required
                  />
                  <TextField
                    control={control}
                    name={`carrierAddress`}
                    label={'Carrier Address'}
                    sizes={{ xs: 12 }}
                    required
                  />{' '}
                </>
              )}

              {selectType.secondFormFieldDisplay && (
                <>
                  <TextField
                    control={control}
                    name={`username`}
                    label={'Enter Username (Eg: bob)'}
                    sizes={{ xs: 12 }}
                    required
                    inputProps={{ autoComplete: 'new-password' }}
                  />
                  <TextField
                    control={control}
                    name={`password`}
                    label={'Enter Your Password'}
                    sizes={{ xs: 12 }}
                    type={'password'}
                    required
                    inputProps={{ autoComplete: 'new-password' }}
                  />
                </>
              )}
              <Box
                display="flex"
                justifyContent="start"
                sx={{ height: '42px' }}
              >
                <ButtonImproved
                  size="large"
                  variant="contained"
                  label={selectType.saveText}
                  styleProps={{ height: 42 }}
                  onClick={() => {
                    if (details.providerId === APEX_PROVIDER_ID) {
                      details && connectAuth(details);
                      return;
                    }
                    handleSubmit(onSubmitHandler)();
                  }}
                  disabled={!editForm}
                />
              </Box>
            </StepContent>
          </Step>
        ) : null}
        <Step key={22} expanded={true}>
          <StepLabel
            sx={{
              '.Mui-completed': {
                color: 'primary.main',
              },
              '.Mui-active': {
                color: 'primary.main',
              },
              '.Mui-disabled': {
                color: 'text.disabled',
              },
            }}
          >
            Done
          </StepLabel>
        </Step>
      </Stepper>
    </FormProvider>
  );
}
