import { Divider, useTheme } from '@mui/material';
import { BREAKPOINT_TABLET } from '../../../../common/hooks/useThemeResponsive';

export const VerticalDivider = () => {
  const theme = useTheme();
  return (
    <Divider
      orientation="vertical"
      variant="middle"
      flexItem
      sx={{
        borderColor: 'rgba(0, 17, 34, 0.26)',
        [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
          marginLeft: '4px',
          marginRight: '4px',
        },
      }}
    />
  );
};
