import React, { useMemo } from 'react';
import { FormDialogMode } from '../../../../common/Ui/FormDialog/constants';
import FormDialog, { FormDialogProps } from '../../../../common/Ui/FormDialog';
import { Theme, Typography, useTheme } from '@mui/material';
import { MESSAGES } from '../../messages';

export interface ConfirmationPopupProps {
  onCancel?: () => void;
  onConfirm?: (confirmationMessage?: string) => void;
  type?: 'SAVE' | 'CANCEL';
  config?: FormDialogProps;
}

const cancelConfirmConfig = {
  title: MESSAGES.cancelTitle,
  body: <Typography>{MESSAGES.cancelBodyText}</Typography>,
  confirmText: 'Continue',
  cancelText: 'Go back',
  cancelBtnColor: 'error',
  style: { minHeight: '160px', width: '425px' },
  componentTheme: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '12px 10px!important',
        },
      },
    },
  },
  cancelBtnProps: { styleProps: { borderRadius: '14px' } },
};
const saveConfirmConfig = {
  title: MESSAGES.saveTitle,
  body: (
    <Typography>
      Continuing will implement everything you have locked. Note that{' '}
      <b>only locked</b> assignments will be implemented.
    </Typography>
  ),
  confirmText: 'Continue',
  cancelText: 'Cancel',
  cancelBtnColor: 'primary',
  style: {},
  componentTheme: {},
  cancelBtnProps: {},
};

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = (props) => {
  const { type = 'CANCEL', ...rest } = props;
  const theme = useTheme();
  const popupConfig = useMemo(() => {
    if (type === 'SAVE') return saveConfirmConfig;
    return cancelConfirmConfig;
  }, [type]);

  return (
    <FormDialog
      open={true}
      width={425}
      mode={FormDialogMode.LIGHT}
      titleText={
        <Typography variant="h6" fontWeight={500} color={'text.primary'}>
          {popupConfig.title}
        </Typography>
      }
      contentRenderer={() => {
        return popupConfig.body;
      }}
      actionLabel={popupConfig.confirmText}
      cancelLabel={popupConfig.cancelText}
      handleClose={rest?.onCancel}
      onAction={rest?.onConfirm}
      actionColor={popupConfig.cancelBtnColor}
      sx={{
        '& .MuiPaper-root': {
          border: `1px solid ${theme?.palette?.primary?.main}`,
          boxShadow: '0px -11px 25px rgba(78, 101, 120, 0.25)',
          backdropFilter: 'blur(6px)',
          borderRadius: '16px',
          maxWidth: rest?.config?.width ?? '430px',
          padding: '16px',
          ...(popupConfig.style as any),
          ...(rest?.config?.paperStyles as any),
        },
      }}
      componentTheme={popupConfig.componentTheme}
      cancelBtnProps={popupConfig.cancelBtnProps}
      {...rest?.config}
    />
  );
};

export default ConfirmationPopup;
