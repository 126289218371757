import { observer } from 'mobx-react';
import { useState } from 'react';
import { paymentManagementService } from '../../../../../api';
import {
  GetPaymentOneRequest,
  PaymentDetailsDTO,
  PaymentMessageResponse,
} from '../../../../../models/DTOs/Payment/DTOs';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import { LongMenu } from '../../../../../ui-kit/components/LongMenu';
import { EPayment3DotMenuType } from '../../constants/types';
import { hasPaymentEdit } from '../../utils/payment.permission';
interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}
interface PaymentDetailsPanelActionsSectionProps {
  on3DotMenuActionCallback?: (params: {
    type: EPayment3DotMenuType;
    data: PaymentDetailsDTO;
    response: any;
  }) => void | Promise<void>;
  paymentDetailsDTO: PaymentDetailsDTO;
}
export const PaymentDetailsPanelActionsSection = observer(
  ({
    paymentDetailsDTO,
    on3DotMenuActionCallback,
  }: PaymentDetailsPanelActionsSectionProps) => {
    const [current3DotMenu, setCurrent3DotMenu] =
      useState<EPayment3DotMenuType | null>(null);

    const [secondaryPopup, setSecondaryPopup] = useState<{
      title: string;
      body: string;
      onAction?: any;
      onClose?: any;
      cancelText?: string;
    } | null>(null);

    const onCloseMenu = () => {
      setCurrent3DotMenu(null);
      setSecondaryPopup(null);
    };

    const options: Action[] = [];

    if (hasPaymentEdit()) {
      options.push({
        name: `Delete`,
        action: (): void => {
          setCurrent3DotMenu(EPayment3DotMenuType.DELETE);
        },
      });
    }

    const onDeletePaymentHandler = async () => {
      onCloseMenu();
      const response = await paymentManagementService.deletePayment(
        new GetPaymentOneRequest({
          id: paymentDetailsDTO.id,
        })
      );
      if (response instanceof PaymentMessageResponse) {
        on3DotMenuActionCallback?.({
          type: EPayment3DotMenuType.DELETE,
          data: paymentDetailsDTO,
          response: response,
        });
      } else {
        setSecondaryPopup({
          title: 'Delete Payment',
          body: `The payment cannot be removed`,
          onAction: undefined,
          onClose: onCloseMenu,
          cancelText: 'OK',
        });
      }
    };

    return (
      <>
        {options.length > 0 && (
          <LongMenu color={'primary.contrast'} options={options} />
        )}

        {current3DotMenu === EPayment3DotMenuType.DELETE && (
          <DeletePopup
            open={true}
            title={'Delete Payment'}
            body={`Are you sure, you want to delete the payment ${paymentDetailsDTO.seqNumber}?`}
            subtitle={`Deleting the payment can also revert the status of invoice/load`}
            onAction={onDeletePaymentHandler}
            onClose={onCloseMenu}
            buttonText={'Delete'}
            width={'300px'}
          />
        )}

        {secondaryPopup && (
          <DeletePopup
            open={true}
            title={secondaryPopup.title}
            body={secondaryPopup.body}
            onAction={undefined}
            onClose={secondaryPopup.onClose}
            cancelText={secondaryPopup.cancelText}
          />
        )}
      </>
    );
  }
);
