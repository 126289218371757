import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, SwipeableDrawer } from '@mui/material';
import { FC } from 'react';
import { EFSCredentialsForm } from '@/views/settings/integrations/FuelCard/components/EFSCredentialsForm';
import { FormProvider } from 'react-hook-form';
import ButtonImproved from '@/ui-kit/components/ButtonImproved';
import { FuelCardAccount } from '@/models/DTOs/FuelCards/FuelCards';

const drawerStyles = {
  '.MuiPaper-root': {
    width: '462px',
    height: '90%',
    bottom: '5px',
    top: 'unset',
    padding: '25px 40px 10px 32px',
    boxShadow:
      '0px 5px 6px -3px rgb(0 0 0 / 20%), 0px 9px 12px 1px rgb(0 0 0 / 14%), 0px 3px 16px 2px rgb(0 0 0 / 12%)',
    borderRadius: '16px 0px 0px 16px',
  },
};

interface Props {
  open: boolean;
  logo: JSX.Element;
  onClose: () => void;
  onSubmit: (data: any) => void;
  form: any;
  activeAccount?: FuelCardAccount;
}

export const CredentialsDrawer: FC<Props> = ({
  open,
  logo,
  onClose,
  onSubmit,
  form,
  activeAccount,
}) => {
  return (
    <Box>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={onClose}
        onOpen={() => {}}
        disableSwipeToOpen={false}
        sx={drawerStyles}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box className="drawer-content">
            <Box onClick={onClose} sx={{ float: 'right', cursor: 'pointer' }}>
              <CloseOutlinedIcon />
            </Box>
            <Box className="provider-logo" sx={{ pt: '25px' }}>
              {logo}
            </Box>
            <FormProvider {...form}>
              <form onSubmit={onSubmit}>
                <EFSCredentialsForm activeAccount={activeAccount} />
              </form>
            </FormProvider>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonImproved
              variant="contained"
              label="Save"
              onClick={onSubmit}
            />
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};
