import { MaintenanceEquipment } from '../../../models/DTOs/maintenance/maintenanceEquipment/Model';
import { ServiceError } from '../helperTypes';
import { Service } from '../Service';
import { PaginatedResult } from '../../../types';
import { GetMaintenanceEquipmentByOrganizationIdRequest } from '../../../models/DTOs/maintenance/maintenanceEquipment/Requests';

export abstract class IMaintenanceEquipmentService extends Service {
  abstract getByOrganizationId(
    requestData: GetMaintenanceEquipmentByOrganizationIdRequest
  ): Promise<PaginatedResult<MaintenanceEquipment> | ServiceError>;
}
