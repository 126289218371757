import { dispatchService, teamDriverServiceType } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  LoadItemOptionsProps,
  generateLoadItemOptions,
} from '../../../common/TimeoffDialog/components/Form/LoadItem';
import {
  DriverTeamWithTrips,
  FetchDriverGroupDataWithTripsRequest,
  OptionType,
  PaginatedAllTeamDriverListRequest,
  PreferencesDTO,
  TeamDriver,
} from '../../../models';
import {
  CreateTimeoffResponse,
  GanttDriverLocationMarkersResponse,
  GanttWarningsResponse,
  GetDispatchDetailsPanelResponse,
  GetDriverLoadsGroupAssignmentResponse,
  GetPreferenceDriverResponse,
  GetPreviousLoadContent,
  IDispatchDriver,
  IDriverGroupWarningResponse,
  PaginatedDispatchDriverListResponse,
  PaginatedGetPreviousLoadResponse,
  PaginatedResponse,
  RegenerateTimelineResponse,
  RevertOrActivateTimeoffResponse,
  UpdateGroupAssgnmentResponse,
  UpdatePreferenceDriverResponse,
} from '../../../models/DTOs/Dispatch/Dispatch';
import {
  CreateTimeoffRequest,
  DeleteTimeoffRequest,
  GanttDriverLocationMarkersRequest,
  GanttWarningsRequest,
  GetDispatchDetailsPanelMapDataRequest,
  GetDispatchDetailsPanelRequest,
  GetDriverLoadsGroupAssignmentRequest,
  GetPreferenceDriverRequest,
  GetPreviousLoadRequest,
  PaginatedDispatchDriverRequest,
  PaginatedDispatchDriverRequestQuery,
  RegenerateTimelineRequest,
  RevertOrActivateTimeoffRequest,
  UpdateGroupAssgnmentRequest,
  UpdatePreferenceDriverRequest,
  UpdateTimeoffRequest,
} from '../../../models/DTOs/Dispatch/Requests';
import { defaultDispatchFilters } from '../constants/dispatch';
import {
  DispatchFiltersName,
  EDispatchDriverWorkDay,
  IDispatchFilters,
  IDispatchPreferencesFormData,
} from '../constants/types';
import { getSortOptions } from './filters.utils';

import { isEqual, sortBy } from 'lodash';
import moment from 'moment';
import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { filterDriverListByLocation } from '../../../services/map/driverLocation.service'; // TODO : Shivam make centralize this function
import { gridPageSize } from '../../../utils';
import { DateTimezoneUtils } from '../../../utils/Timezone.utils';
import { getGanttFinalData } from './dispatch';
let instance: DispatchController | null = null;
class DispatchController {
  static instance(): DispatchController {
    if (instance === null) {
      instance = new DispatchController();
    }
    return instance;
  }

  static destroy() {
    instance = null;
  }

  ganttDriver: PaginatedDispatchDriverListResponse = {
    content: [],
  };
  newDriverGroupIds: string[] = [];
  ganttDriverLocationMarkers: GanttDriverLocationMarkersResponse = {
    mappings: {},
    markers: [],
  };
  ganttWarnings: GanttWarningsResponse | null = [];
  terminalIds: string[] = [];
  preferences: PreferencesDTO | null = null;
  skipTrackPromise = false;

  private constructor() {
    //
  }

  async getPaginatedGanttDriverList(
    pageNumber: number,
    pageSize: number,
    requestData: PaginatedDispatchDriverRequest,
    filters: IDispatchFilters
  ): Promise<{
    response: PaginatedDispatchDriverListResponse | null;
    newDispatchDrivers: IDispatchDriver[];
  } | null> {
    const request = this.setRequestFilters(requestData, filters);

    const queryParamsObject = {
      pageNumber: pageNumber || 1,
      pageSize: pageSize || gridPageSize,
      sort: request?.sort,
    };
    const queryParams = new PaginatedDispatchDriverRequestQuery(
      queryParamsObject
    );
    const bodyParams = new PaginatedDispatchDriverRequest({
      ...request,
      ...this.getDefaultPreferencesRequest(),
    });

    const response: PaginatedDispatchDriverListResponse | null | ServiceError =
      await dispatchService.getPaginatedDriverList(
        bodyParams,
        queryParams,
        this.skipTrackPromise
      );

    this.setNewDriverGroupIds(response);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      const content = response?.content || [];

      let parsedResponse;
      let newDispatchDrivers: IDispatchDriver[] = [];
      if (pageNumber === 1) {
        parsedResponse = {
          ...response,
          content: content,
          number: pageNumber,
        };
        newDispatchDrivers = content;
      } else {
        parsedResponse = {
          ...response,
          content: this.ganttDriver?.content,
          number: pageNumber,
        };
        newDispatchDrivers = content.reduce(
          (list: IDispatchDriver[], item: IDispatchDriver) => {
            const isDuplicated =
              parsedResponse.content?.findIndex(
                (oldContent) =>
                  oldContent.driverGroupDetails.id ===
                  item.driverGroupDetails.id
              ) > -1;
            if (!isDuplicated) {
              list.push(item);
            }
            return list;
          },
          []
        );
        parsedResponse.content = [
          ...this.ganttDriver?.content,
          ...newDispatchDrivers,
        ];
      }
      this.ganttDriver = parsedResponse;
      return {
        response: {
          ...parsedResponse,
          content: [],
        },
        newDispatchDrivers: newDispatchDrivers,
      };
    }
  }

  async findGanttDriverByDriverId(
    driverId: number
  ): Promise<IDispatchDriver | null> {
    const queryParams = new PaginatedDispatchDriverRequestQuery({
      pageSize: 1,
      pageNumber: 1,
      sort: 'groupName',
    });
    const bodyParams = new PaginatedDispatchDriverRequest({
      driverIds: [driverId],
      ...this.getDefaultPreferencesRequest(),
    });

    const response: PaginatedDispatchDriverListResponse | null | ServiceError =
      await dispatchService.getPaginatedDriverList(
        bodyParams,
        queryParams,
        this.skipTrackPromise
      );

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response?.content[0] || null;
    }
  }

  async refreshGanttDriverByDriverIds(
    requestData: PaginatedDispatchDriverRequest,
    filters: IDispatchFilters
  ): Promise<{
    response: PaginatedDispatchDriverListResponse | null;
    newDispatchDrivers: IDispatchDriver[];
  } | null> {
    const request = this.setRequestFilters(requestData, filters);

    //override the driverIds
    const queryParams = new PaginatedDispatchDriverRequestQuery({
      pageNumber: 1,
      pageSize: gridPageSize,
      ...request,
    });
    const bodyParams = new PaginatedDispatchDriverRequest({
      ...request,
      driverIds: requestData.driverIds,
      ...this.getDefaultPreferencesRequest(),
    });

    const response: PaginatedDispatchDriverListResponse | null | ServiceError =
      await dispatchService.getPaginatedDriverList(
        bodyParams,
        queryParams,
        this.skipTrackPromise
      );

    this.setNewDriverGroupIds(response);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      const content = response?.content || [];

      this.ganttDriver.content = getGanttFinalData(
        this.ganttDriver?.content || [],
        content,
        'driverGroupDetails.id'
      );

      return {
        response: {
          ...this.ganttDriver,
          content: [],
        },
        newDispatchDrivers: content,
      };
    }
  }

  setNewDriverGroupIds(
    response: PaginatedDispatchDriverListResponse | null | ServiceError
  ): void {
    //TODO: caching the new driver group ids need to be fetched data on the next request API
    if (!response || response instanceof ServiceError) {
      this.newDriverGroupIds = [];
    } else {
      const ids =
        response.content?.map((item) => item.driverGroupDetails.id) || [];
      this.newDriverGroupIds = ids;
    }
  }

  getNewDriverGroupIds(): string[] {
    return this.newDriverGroupIds;
  }

  async getGanttWarnings(
    requestData: GanttWarningsRequest,
    filters: IDispatchFilters
  ): Promise<{
    response: IDriverGroupWarningResponse | null;
    newDriverGroupWarnings: IDriverGroupWarningResponse[];
  } | null> {
    const request = this.setRequestFilters(requestData, filters);
    const queryParams = new GanttWarningsRequest(request);

    const response: GanttWarningsResponse | null | ServiceError =
      await dispatchService.getGanttWarnings(queryParams, true);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      //TODO :  update current this.ganttWarnings
      this.ganttWarnings = getGanttFinalData(
        this.ganttWarnings || [],
        response,
        'driverGroupId'
      );
      return {
        response: this.ganttWarnings,
        newDriverGroupWarnings: response,
      };
    }
  }

  async getGanttDriverLocationMarkers(
    requestData: GanttDriverLocationMarkersRequest,
    filters: IDispatchFilters
  ): Promise<GanttDriverLocationMarkersResponse | null> {
    const request = this.setRequestFilters(requestData, filters);
    const requestBody = new GanttDriverLocationMarkersRequest(request);

    const response: GanttDriverLocationMarkersResponse | null | ServiceError =
      await dispatchService.getGanttDriverLocationMarkers(requestBody, true);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async getDispatchDetailsPanel(
    requestData: GetDispatchDetailsPanelRequest
  ): Promise<GetDispatchDetailsPanelResponse | null> {
    const queryParams = new GetDispatchDetailsPanelRequest(requestData);

    const response: GetDispatchDetailsPanelResponse | null | ServiceError =
      await dispatchService.getDispatchDetailsPanel(queryParams, true);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async getDispatchDetailsPanelMapData(
    requestData: GetDispatchDetailsPanelMapDataRequest
  ): Promise<GanttDriverLocationMarkersResponse | null> {
    const queryParams = new GetDispatchDetailsPanelMapDataRequest(requestData);

    const response: GanttDriverLocationMarkersResponse | null | ServiceError =
      await dispatchService.getDispatchDetailsPanelMapData(queryParams, true);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      const markersList: any = [];
      response?.markers?.map((marker: any) => {
        if (marker.driverToolTip) {
          const driverMarker = filterDriverListByLocation({
            drivers: marker?.driverToolTip?.drivers?.map?.((e: any) => ({
              ...e,
              routeFlag: marker?.routeFlag,
            })),
            currentWeek: marker?.driverToolTip?.currentWeek,
            hosConstants: marker?.driverToolTip?.hosConstants,
            isTooltipActionDisabled: true,
          });
          driverMarker?.locatedDrivers?.length
            ? markersList.push(...driverMarker?.locatedDrivers)
            : '';
        } else {
          markersList.push(marker);
        }
      });
      return { markers: markersList };
    }
  }

  async getPreviousLoad({
    seqNumber,
    pageNumber,
    driverGroupId,
    loadStatus = 'OFFERED_TO_DRIVER',
  }: {
    seqNumber: string;
    pageNumber: number;
    driverGroupId: string;
    loadStatus?: string;
  }): Promise<PaginatedResponse<LoadItemOptionsProps> | null> {
    const queryParams = new GetPreviousLoadRequest({
      pageNumber: pageNumber,
      pageSize: 25,
      seqNumber: seqNumber,
      driverGroupId: driverGroupId,
      loadStatus,
    });

    const response: PaginatedGetPreviousLoadResponse | null | ServiceError =
      await dispatchService.getPreviousLoad(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      const { content, ...restResponse } = response;
      const parsedContents: LoadItemOptionsProps[] = content.map(
        (item: GetPreviousLoadContent): LoadItemOptionsProps => {
          return generateLoadItemOptions(item);
        }
      );
      return {
        ...restResponse,
        content: parsedContents,
      } as PaginatedResponse<LoadItemOptionsProps>;
    }
  }

  async createTimeoff(
    requestData: CreateTimeoffRequest
  ): Promise<CreateTimeoffResponse | null> {
    const queryParams = new CreateTimeoffRequest(requestData);
    const response: CreateTimeoffResponse | null | ServiceError =
      await dispatchService.createTimeoff(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async updateTimeoff(
    requestData: UpdateTimeoffRequest
  ): Promise<CreateTimeoffResponse | null> {
    const queryParams = new UpdateTimeoffRequest(requestData);
    const response: CreateTimeoffResponse | null | ServiceError =
      await dispatchService.updateTimeoff(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async deleteTimeoff(
    requestData: DeleteTimeoffRequest
  ): Promise<CreateTimeoffResponse | null> {
    const queryParams = new DeleteTimeoffRequest(requestData);
    const response: CreateTimeoffResponse | null | ServiceError =
      await dispatchService.deleteTimeoff(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }
  async revertOrActivateTimeoff(
    requesData: any
  ): Promise<RevertOrActivateTimeoffResponse | null> {
    const queryParams = new RevertOrActivateTimeoffRequest(requesData);
    const response: RevertOrActivateTimeoffResponse | null | ServiceError =
      await dispatchService.revertOrActivateTimeoff(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async updateGroupAssgnment(
    requestData: UpdateGroupAssgnmentRequest
  ): Promise<UpdateGroupAssgnmentResponse | null> {
    const queryParams = new UpdateGroupAssgnmentRequest(requestData);
    const response: UpdateGroupAssgnmentResponse | null | ServiceError =
      await dispatchService.updateGroupAssgnment(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async getPreferenceDriver(
    requestData: GetPreferenceDriverRequest
  ): Promise<GetPreferenceDriverResponse | null> {
    const queryParams = new GetPreferenceDriverRequest({
      driverId: requestData.driverId,
    });
    const response: GetPreferenceDriverResponse | null | ServiceError =
      await dispatchService.getPreferenceDriver(queryParams, true);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }
  async getDriverLoadsGroupAssignment({
    groupId,
  }: {
    groupId: string;
  }): Promise<GetDriverLoadsGroupAssignmentResponse | null> {
    const queryParams = new GetDriverLoadsGroupAssignmentRequest({
      groupId: groupId,
    });
    const response:
      | GetDriverLoadsGroupAssignmentResponse
      | null
      | ServiceError = await dispatchService.getDriverLoadsGroupAssignment(
      queryParams,
      this.skipTrackPromise
    );

    if (!response || response instanceof ServiceError) {
      return null;
    }
    return response;
  }

  async updatePreferenceDriver(
    requestData: IDispatchPreferencesFormData
  ): Promise<UpdatePreferenceDriverResponse | null> {
    const queryParams = new UpdatePreferenceDriverRequest({
      ...requestData,
      id: requestData.id,
      driverId: requestData.driverId,
      useSleeperBerthProvision: requestData.useSleeperBerthProvision,
      ignoreHOSRestrictions: requestData.ignoreHOSRestrictions,
      dvirPreferences: requestData.dvirPreferences,
      workWeekType: requestData.workWeekType?.key,
      preferredTruckstops:
        requestData?.preferredTruckstops &&
        requestData?.preferredTruckstops?.length
          ? requestData.preferredTruckstops.map((option) => option.key)
          : [],
    });
    const response: UpdatePreferenceDriverResponse | null | ServiceError =
      await dispatchService.updatePreferenceDriver(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async addPreferenceDriver(
    requestData: IDispatchPreferencesFormData
  ): Promise<UpdatePreferenceDriverResponse | null> {
    const queryParams = new UpdatePreferenceDriverRequest({
      ...requestData,
      driverId: requestData.driverId,
      useSleeperBerthProvision: requestData.useSleeperBerthProvision,
      ignoreHOSRestrictions: requestData.ignoreHOSRestrictions,
      dvirPreferences: requestData.dvirPreferences,
      workWeekType: requestData.workWeekType?.key,
      preferredTruckstops:
        requestData?.preferredTruckstops &&
        requestData?.preferredTruckstops?.length
          ? requestData.preferredTruckstops.map((option) => option.key)
          : [],
    });
    const response: UpdatePreferenceDriverResponse | null | ServiceError =
      await dispatchService.addPreferenceDriver(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }

  async regenerateTimeline({
    groupId,
  }: {
    groupId: string;
  }): Promise<RegenerateTimelineResponse | null> {
    const queryParams = new RegenerateTimelineRequest({
      groupId: groupId,
    });
    const response: RegenerateTimelineResponse | null | ServiceError =
      await dispatchService.regenerateTimeline(queryParams);

    if (!response || response instanceof ServiceError) {
      return null;
    }
    return response;
  }

  async fetchDriverTeams({
    driverGroupId,
    isDetailsUrl = false,
  }: {
    driverGroupId: string;
    isDetailsUrl?: boolean;
  }): Promise<TeamDriver | null> {
    const request = new PaginatedAllTeamDriverListRequest({
      pageNumber: 1,
      pageSize: 1,
      driverGroupIds: [driverGroupId],
    });

    const response = await teamDriverServiceType.getTeamDriverList(
      request,
      isDetailsUrl
    );
    if (!(response instanceof ServiceError)) {
      const driverTeam: TeamDriver | null = response.content?.[0] || null;
      return driverTeam;
    }
    return null;
  }

  async fetchDriverGroupDataWithTrips(
    driverGroupId: string
  ): Promise<DriverTeamWithTrips | undefined> {
    const request = new FetchDriverGroupDataWithTripsRequest();
    request.groupId = driverGroupId;

    const response = await teamDriverServiceType.fetchDriverGroupDataWithTrips(
      request
    );
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  setPreference(data: PreferencesDTO) {
    this.preferences = data;
  }

  setTerminalIds(ids: string[]) {
    this.terminalIds = ids;
  }

  getTerminalIds() {
    return this.terminalIds;
  }

  isTerminalIdsChanged(newIds: string[]): boolean {
    return !isEqual(sortBy(newIds), this.terminalIds);
  }

  getFiltersDate(filters: IDispatchFilters): {
    startDate: Date;
    endDate: Date;
    window: number;
  } {
    const window =
      filters.filters[DispatchFiltersName.window]?.key ||
      defaultDispatchFilters.filters[DispatchFiltersName.window]?.key;
    const startDate = filters.filters[DispatchFiltersName.startDate]
      ?.dateRange?.[0]
      ? moment(
          filters.filters[DispatchFiltersName.startDate]?.dateRange?.[0]
        ).toDate()
      : RelativeDateRangeUtils.getSettingsDispatch().defaultOption
          ?.dateRange?.[0];
    const endDate = moment(startDate).add(window, 'days').toDate();

    return {
      startDate,
      endDate,
      window,
    };
  }

  findDriverIdsByDriverGroupIds(driverGroupIds: string[]): number[] {
    const ganttDrivers: IDispatchDriver[] =
      this.ganttDriver.content.filter((item) =>
        driverGroupIds.includes(item.driverGroupDetails.id)
      ) || null;
    if (ganttDrivers) {
      return ganttDrivers.reduce(
        (driverIds: number[], item: IDispatchDriver) => {
          if (item.driverGroupDetails.driverId) {
            driverIds.push(item.driverGroupDetails.driverId);
          }
          return driverIds;
        },
        []
      );
    }
    return [];
  }

  findDispatchDriverByDriverGroupId(
    driverGroupId: string
  ): IDispatchDriver | null {
    return (
      this.ganttDriver.content.find(
        (item) => item.driverGroupDetails.id === driverGroupId
      ) || null
    );
  }

  getDefaultPreferencesRequest(): {
    workWeekStartDay: string;
    timezone: string;
  } {
    return {
      workWeekStartDay:
        this.preferences?.workWeekStartDay || EDispatchDriverWorkDay.MONDAY,
      timezone: this.preferences?.timezone || 'America/New_York',
    };
  }

  setRequestFilters = (
    queryParams: Record<string, any>,
    filters: IDispatchFilters
  ) => {
    const sortFilter =
      filters.filters[DispatchFiltersName.sort]?.key || getSortOptions()[0].key;
    const sortDirection = filters.filters[DispatchFiltersName.sortDirection];
    const sort = sortDirection ? `${sortFilter}` : `-${sortFilter}`;

    const dispatcherIdList =
      filters.filters[DispatchFiltersName.primaryDispatcher]?.map(
        (item: OptionType) => item.key
      ) || null;
    const driverIds =
      filters.filters[DispatchFiltersName.driver]?.map(
        (item: OptionType) => item.key
      ) || null;
    const employmentType =
      filters.filters[DispatchFiltersName.employmentType]?.key || null;
    const groupMode =
      filters.filters[DispatchFiltersName.operationMode]?.key || null;

    const hasTimeOff =
      filters.filters[DispatchFiltersName.timeOff]?.key || null;
    const hasViolation =
      filters.filters[DispatchFiltersName.hasViolation]?.key || null;
    const needLoad = filters.filters[DispatchFiltersName.needLoad]?.key || null;

    const operationType =
      filters.filters[DispatchFiltersName.operationType]?.key || null;

    const { window, startDate } =
      DispatchController.instance().getFiltersDate(filters);

    return {
      ...queryParams,
      sort,
      startDate: DateTimezoneUtils.toStartDateISOString(startDate),
      dispatcherIdList,
      driverIds,
      employmentType,
      groupMode,
      ...(hasTimeOff !== null && {
        hasTimeOff,
      }),
      ...(hasViolation !== null && {
        hasViolation,
      }),
      ...(needLoad !== null && {
        needLoad,
      }),
      operationType,
      window,
    };
  };

  setSkipTrackPromise(newValue: boolean): void {
    this.skipTrackPromise = newValue;
  }
}

export default DispatchController;
