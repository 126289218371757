import { Box } from '@mui/material';
import React from 'react';
import { TripV3DataProps } from '../../TripPlanV3';
import {
  StyledChip,
  StyledDetailedDataSectionContentTitle,
  StyledRightTitle,
} from '../../TripPlanV3/styles';
import {
  ExpandableTimelineV3LoadIdEvents,
  ExpandableTimelineV3PanelView,
  StopSolutionV3StopType,
} from '../../TripPlanV3/types';
import { EditIconSection, EditIconSectionProps } from './EditIconSection';
import { getTripV3ActivityTitle } from './RightSubTitleSection';

export interface RightTitleSectionProps
  extends EditIconSectionProps,
    ExpandableTimelineV3LoadIdEvents {
  enablePreAndNext: boolean;
  isActive: boolean;
  panelView: ExpandableTimelineV3PanelView;
  renderTimelineSeparatorIconOnly: boolean;
  isHideCollapse?: boolean;
  isShowButtonIconActionSection?: boolean;
  readOnly?: boolean;
  data: TripV3DataProps;
  isManifest?: boolean;
  idEditAddEnable?: boolean;
  isManifestDetailPanel?: boolean;
}

const LoadIdHyperlink = ({
  onClick,
  loadId,
}: {
  onClick?: () => void;
  loadId: string | number;
}): JSX.Element => {
  return (
    <Box
      onClick={(event) => {
        onClick?.();
        event.stopPropagation();
      }}
      sx={{
        color: 'text.primary',
      }}
    >
      Load ID:{' '}
      <Box
        component={'span'}
        sx={{
          color: 'primary.main',
          textDecoration: 'underline',
        }}
      >
        {loadId}
      </Box>
    </Box>
  );
};

export const RightTitleSection = ({
  enablePreAndNext,
  isActive,
  panelView,
  renderTimelineSeparatorIconOnly,
  stopSolution,
  onClickEditIconHandler,
  onClickLoadIdHandler,
  isHideCollapse = false,
  isShowButtonIconActionSection = true,
  readOnly = false,
  data,
  isManifest = false,
  idEditAddEnable = false,
  isManifestDetailPanel = false,
}: RightTitleSectionProps): JSX.Element => {
  const canShowLoadHyperlink = (): boolean => {
    if (readOnly || stopSolution?.type !== StopSolutionV3StopType.LOAD)
      return false;
    if (
      stopSolution?.loadId &&
      (panelView === ExpandableTimelineV3PanelView.TripDetails ||
        panelView === ExpandableTimelineV3PanelView.Loadsearch)
    ) {
      return true;
    }
    return false;
  };
  const IsLoadIdVisible =
    !(
      stopSolution?.type === StopSolutionV3StopType.TRIP ||
      panelView === ExpandableTimelineV3PanelView.TripDetails ||
      stopSolution?.type === StopSolutionV3StopType.RELAY
    ) &&
    (idEditAddEnable || isManifest || isManifestDetailPanel);

  if (!renderTimelineSeparatorIconOnly) {
    return (
      <StyledRightTitle isActive={isActive} isHideCollapse={isHideCollapse}>
        <Box
          sx={{
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              paddingRight: '5px',
              display: 'inline-flex',
            }}
          >
            {enablePreAndNext && <>{getTripV3ActivityTitle(stopSolution)} @ </>}
            {[stopSolution?.location?.city, stopSolution?.location?.state]
              .filter((str) => !!str)
              .join(', ')}
            {isShowButtonIconActionSection &&
            !isManifest &&
            !idEditAddEnable ? (
              <EditIconSection
                data={data}
                stopSolution={stopSolution}
                onClickEditIconHandler={onClickEditIconHandler}
              />
            ) : (
              <></>
            )}
          </Box>
          <StyledDetailedDataSectionContentTitle
            sx={{
              paddingLeft: 'unset',
            }}
          >
            {canShowLoadHyperlink() || IsLoadIdVisible ? (
              <LoadIdHyperlink
                onClick={() => {
                  onClickLoadIdHandler?.(stopSolution, data);
                }}
                loadId={stopSolution?.loadId!}
              />
            ) : (
              <></>
            )}
            {!enablePreAndNext ? (
              <>
                {stopSolution?.type === StopSolutionV3StopType.TRIP ? (
                  <StyledChip
                    label="trip stop"
                    onClick={(event) => event.stopPropagation()}
                  />
                ) : (
                  <></>
                )}

                {stopSolution?.type === StopSolutionV3StopType.RELAY ? (
                  <StyledChip
                    label="relay"
                    onClick={(event) => event.stopPropagation()}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </StyledDetailedDataSectionContentTitle>
        </Box>
      </StyledRightTitle>
    );
  }
  return <></>;
};
