import {
  AssociatedTerminalType,
  DeleteTerminalDTO,
  DeleteTerminalLogoDTO,
  GetLogoDTO,
  GetTerminalListDTO,
  GetUserAssociatedTerminalsRequest,
  TerminalContentDTO,
  TerminalListDTO,
} from '../../models';
import { UpdateTerminalModeRequest } from '../../models/DTOs/Terminal/Requests';
import { Service } from './Service';
import { ServiceError } from './helperTypes';
export abstract class ITerminalService extends Service {
  abstract getTerminals(): Promise<any | null | ServiceError>;

  abstract getTerminalList(
    requestData: GetTerminalListDTO
  ): Promise<TerminalListDTO | null | ServiceError>;

  abstract createTerminal(
    payloadData: any
  ): Promise<TerminalContentDTO | null | ServiceError>;

  abstract updateTerminal(
    payloadData: any
  ): Promise<TerminalContentDTO | null | ServiceError>;

  abstract deleteTerminal(
    payloadData: DeleteTerminalDTO
  ): Promise<null | ServiceError>;

  abstract updateTerminalMode(
    payloadData: UpdateTerminalModeRequest
  ): Promise<any | null | ServiceError>;

  abstract getLogoForTerminal(
    requestData: GetLogoDTO
  ): Promise<any | null | ServiceError>;

  abstract postTerminalLogoData(
    requestData: GetLogoDTO,
    file: any
  ): Promise<any | null | ServiceError>;

  abstract deleteTerminalLogo(
    payloadData: DeleteTerminalLogoDTO
  ): Promise<null | undefined>;

  abstract getUserAssociatedTerminalsList(
    payloadData: GetUserAssociatedTerminalsRequest
  ): Promise<AssociatedTerminalType[] | ServiceError>;
}
