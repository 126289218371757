import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { UI_DATE_FORMAT } from '../../../../constants/date.constants';
import { GridColDefSelf } from '../../../../types';
import { getFormattedDateForGrid } from '../../../../utils';
import { currencyDisplay } from '../../../../utils/grid.utils';
import { IExpenseDetailsFilter } from './expenseDetails';
import { HyperLinkComp } from './expenseDetailsGrid';
import { t } from 'i18next';
export const FASIncomeDetailsColumns: GridColDefSelf[] = [
  {
    flex: 1,
    field: 'paidOn',
    headerName: 'Payment Date',
    sortable: true,
    valueGetter: (params) =>
      getFormattedDateForGrid(params.row.paidOn, UI_DATE_FORMAT),
  },
  {
    flex: 1,
    field: 'seqNumber',
    headerName: 'Invoice #',
    sortable: false,
    isHyperLink: true,
    renderCell: (params: GridRenderCellParams) => (
      <HyperLinkComp value={params.row?.seqNumber || ''} />
    ),
  },
  {
    flex: 1,
    field: 'customerName',
    headerName: 'Customer',
    sortable: true,
    isHyperLink: true,
    renderCell: (params: GridRenderCellParams) => (
      <HyperLinkComp value={params.row?.customerName || ''} />
    ),
  },
  {
    flex: 1,
    field: 'amount',
    headerName: 'Amount',
    sortable: true,
    renderCell: (params: { row: { amount: number } }) => {
      return currencyDisplay(params.row?.amount);
    },
  },
];

export const incomeDetailsFilters: IExpenseDetailsFilter = {
  query: {
    sort: '-paidOn',
  },
};
