export const DocumentConstants = {
  uploadedBy: 'Uploaded By',
  uploadDate: 'Upload Date',
  documentName: 'Document Name',
  fileNameRequired: 'file name is required',
  documentType: 'Document type',
  associatedType: 'Associated Type',
  associatedName: 'Associated Name',
  shareWithDriver: 'Share With Driver',
  shareWithDriverSublabel: 'Share the document with your driver',
  clickToExpand: 'Click to Expand',
  dragDrop: 'Drag & Drop Your File Here',
  replace: 'Replace',
  sendDocument: 'Send Document',
  validFrom: 'Valid From',
  validUntil: 'Valid Until',
  associatedItem: 'Associated Item',
  expiryDate: 'Expiry Date',
  licenseState: 'License State',
  endorsements: 'Endorsements',
  restrictions: 'Restrictions',
  latestDoc: 'Latest Document',
  isThisLatestDoc: 'Is this the latest document?',
  totalDocuments: 'Total Documents',
};
