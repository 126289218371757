import { useTheme } from '@mui/material';
import React from 'react';
export default function FinanceLoadKPI({
  title,
  value,
  textColor,
}: {
  title: string;
  value: string;
  textColor?: string;
}) {
  const theme = useTheme();
  return (
    <span
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        // marginRight: 16,
        minWidth: 105,
        paddingLeft: '5px',
      }}
    >
      <span
        style={{
          lineHeight: '30px',
          color: `${textColor ? textColor : theme.palette.primary.main}`,
          fontSize: '18px',
          fontWeight: 600,
        }}
      >
        {value}
      </span>
      <span
        style={{
          lineHeight: '14px',
          color: `${theme.palette.text.secondary}`,
          fontSize: '14px',
          fontWeight: 500,
        }}
      >
        {title}
      </span>
    </span>
  );
}
