import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { MarkersData } from '../../common/Ui/Maps/types';
import {
  CommonHomeAPIRes,
  FinanceDataRes,
  GetHomeFinance,
  GetMaps,
  GetSafetyDataDTO,
} from '../../models';
import { annotateNameAsync } from '../interfaces';
import { IHomeService } from '../interfaces/IHomeService';

const getFinanceDataURL = '/web/dashboard/api/v2/dashboard/weekly/financial';
const getSafetyDataURL =
  '/web/dashboard/api/v2/dashboard/safety/maintenance/assets';
const getTractorAvailabilityURL =
  '/web/dashboard/api/v2/dashboard/asset/summary/tractors';
// const driverLocationMarker = 'https://run.mocky.io/v3/9686d71f-2d82-4fe2-b62a-8e1e7c25fa3a'; //please keep this commented code for testing
const driverLocationMarker = '/web/gantt/api/v2/gantt/driverLocationMarkers';
const invoiceURLs = {
  delay:
    '/web/dashboard/api/v2/dashboard/operational/summary/age/gt30/invoices',
  pending:
    '/web/dashboard/api/v2/dashboard/operational/summary/pending/payment/invoices',
  missing:
    '/web/dashboard/api/v2/dashboard/operational/summary/document/needed/invoices',
  ready: '/web/dashboard/api/v2/dashboard/operational/summary/pending/invoices',
  driverChart:
    '/web/dashboard/api/v2/dashboard/operational/summary/total/drivers',
  driverLoads:
    '/web/dashboard/api/v2/dashboard/operational/summary/need/load/drivers',
  driverVacation:
    '/web/dashboard/api/v2/dashboard/operational/summary/time/off/drivers',
  assignTripsTotal:
    '/web/dashboard/api/v2/dashboard/operational/summary/with/driver/trips',
  openTripsTotal:
    '/web/dashboard/api/v2/dashboard/operational/summary/open/trips',
  tripsNeedDriver:
    '/web/dashboard/api/v2/dashboard/operational/summary/need/driver/trips',
  assignedPotentialDelay:
    '/web/dashboard/api/v2/dashboard/operational/summary/delayed/trips',
};

export class HomeService extends IHomeService {
  @annotateNameAsync
  async getFinanceData(requestData: GetHomeFinance) {
    try {
      return await httpClient.get<FinanceDataRes>(
        getFinanceDataURL,
        requestData,
        FinanceDataRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getSafetyData(requestData: GetSafetyDataDTO) {
    try {
      return await httpClient.get<FinanceDataRes>(
        getSafetyDataURL,
        requestData,
        FinanceDataRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getTractorAvailability(requestData: GetSafetyDataDTO) {
    try {
      return await httpClient.get<FinanceDataRes>(
        getTractorAvailabilityURL,
        requestData,
        FinanceDataRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getCommonHomeData(
    requestData: GetSafetyDataDTO,
    invoicesOperation: string
  ) {
    try {
      return await httpClient.get<CommonHomeAPIRes>(
        invoiceURLs[invoicesOperation as keyof typeof invoiceURLs],
        requestData,
        CommonHomeAPIRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getMapsData(requestData: GetMaps) {
    try {
      return await httpClient.post<MarkersData>(
        driverLocationMarker,
        undefined,
        requestData,
        undefined,
        true,
        undefined,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
}
