import { DispatchConstants } from '../../../locales/en/dispatch/index';
import { Label } from '../../../locales/en/dispatch/label';
import { StatusTypes } from '../../../types';
import {
  AssignedIcon,
  AvailableIcon,
  CancelledIcon,
  CheckedIcon,
  DropOffStatusIcon,
  InvoicedIcon,
  PaidIcon,
  PickupDropOffIcon,
  PickupStatusIcon,
  PlannedIcon,
} from '../../../ui-kit/components/Assets';
import { IStatusConfigs } from './StatusComponent';

export const loadStatuses: StatusTypes = {
  AVAILABLE: DispatchConstants.LoadStatusesConstantsAvailable,
  ASSIGNMENT_PLANNED: Label.LoadStatusesConstantsPlanned,
  OFFERED_TO_DRIVER: Label.LoadStatusesConstantsAssigned,
  DISPATCHED: Label.LoadStatusesConstantsDispatched,
  IN_TRANSIT: Label.LoadStatusesConstantsInTransit,
  LOAD_COMPLETED: Label.LoadStatusesConstantsCompleted,
  CANCELLED: Label.LoadStatusesConstantsCancelled,
  INVOICED: Label.LoadStatusesConstantsInvoiced,
  PAID: Label.LoadStatusesConstantsPaid,
  IN_PROGESS: Label.LoadStatusesConstantsInTransit,
  NEED_LOAD: Label.LoadStatusesConstantsNeedLoad,
  PARTIALLY_PAID: Label.LoadStatusesConstantsPartialPaid,
  READY_TO_INVOICE: Label.LoadStatusesConstantsReadytoInvoice,
};

export const stopStatusIcons: { [key: string]: JSX.Element } = {
  PICKUP: <DropOffStatusIcon />,
  DROPOFF: <PickupStatusIcon />,
  PICKUP_AND_DROPOFF: <PickupDropOffIcon />,
};

export const loadStatusIcons: { [key: string]: JSX.Element } = {
  AVAILABLE: <AvailableIcon />,
  ASSIGNMENT_PLANNED: <PlannedIcon />,
  OFFERED_TO_DRIVER: <AssignedIcon />,
  DISPATCHED: <PickupStatusIcon />,
  IN_TRANSIT: <DropOffStatusIcon />,
  LOAD_COMPLETED: <CheckedIcon />,
  CANCELLED: <CancelledIcon />,
  INVOICED: <InvoicedIcon />,
  // READY_TO_INVOICE: <InvoicedIcon />,
  PAID: <PaidIcon />,
  IN_PROGESS: <></>,
  NEED_LOAD: <></>,
};

// @TODO: Nvard - AXL2-2543 - replace backgroundColors with theme colors after adding collors for each theme in ui-kit
export const loadStatusChipConfigs = (
  nonInvoiceable?: boolean
): IStatusConfigs => {
  const getLabel = (label: string) =>
    `${label} ${nonInvoiceable ? '(Non-Invoiceable)' : ''}`;
  return {
    AVAILABLE: {
      color: 'common.black',
      backgroundColor: 'chip.availableBg',
      label: getLabel(loadStatuses['AVAILABLE']),
    },
    ASSIGNMENT_PLANNED: {
      color: 'other.liquidNitrogen',
      backgroundColor: 'chip.plannedBg',
      label: getLabel(loadStatuses['ASSIGNMENT_PLANNED']),
    },
    OFFERED_TO_DRIVER: {
      color: 'other.liquidNitrogen',
      backgroundColor: 'chip.assignedBg',
      label: getLabel(loadStatuses['OFFERED_TO_DRIVER']),
    },
    DISPATCHED: {
      color: 'leftMenuExpanded.active',
      backgroundColor: 'secondary.main',
      label: getLabel(loadStatuses['DISPATCHED']),
    },
    IN_TRANSIT: {
      color: 'other.liquidNitrogen',
      backgroundColor: 'chip.inTransitBg',
      label: getLabel(loadStatuses['IN_TRANSIT']),
    },
    LOAD_COMPLETED: {
      color: 'common.black',
      backgroundColor: 'chip.completedBg',
      label: getLabel(loadStatuses['LOAD_COMPLETED']),
    },
    INVOICED: {
      color: 'common.black',
      backgroundColor: 'chip.invoicedBg',
      label: getLabel(loadStatuses['INVOICED']),
    },
    READY_TO_INVOICE: {
      color: 'common.black',
      backgroundColor: '#AAD400',
      label: getLabel(loadStatuses['READY_TO_INVOICE']),
    },
    PAID: {
      color: 'common.black',
      backgroundColor: 'success.main',
      label: getLabel(loadStatuses['PAID']),
    },
    CANCELLED: {
      color: 'common.black',
      backgroundColor: 'chip.canceledBg',
      label: getLabel(loadStatuses['CANCELLED']),
    },
    IN_PROGESS: {
      color: 'other.liquidNitrogen',
      backgroundColor: 'chip.inTransitBg',
      label: getLabel(loadStatuses['IN_PROGESS']),
    },
    NEED_LOAD: {
      color: 'leftMenuExpanded.active',
      backgroundColor: 'error.main',
      label: getLabel(loadStatuses['NEED_LOAD']),
    },
    PARTIALLY_PAID: {
      color: 'other.liquidNitrogen',
      backgroundColor: 'secondary.dark',
      label: getLabel(loadStatuses['PARTIALLY_PAID']),
    },
  };
};

export const loadStatusConfigs = (
  nonInvoiceable?: boolean
): { [key in 'light' | 'dark']: IStatusConfigs } => {
  return {
    dark: loadStatusChipConfigs(nonInvoiceable),
    light: loadStatusChipConfigs(nonInvoiceable),
  };
};
type configType = {
  label: string;
  color: string;
  backgroundColor: string;
};

export const expenseConfig: IStatusConfigs = {
  IN_REVIEW: {
    label: 'Need Review',
    color: 'common.black',
    backgroundColor: 'success.main',
  },
  REVIEWED: {
    label: 'Reviewed',
    color: 'common.black',
    backgroundColor: 'chip.completedBg',
  },
};

export const assetConfig: { [key: string]: configType } = {
  AVAILABLE: {
    label: 'Available',
    color: 'common.black',
    backgroundColor: 'chip.availableBg',
  },
  ASSIGNED: {
    label: 'Assigned',
    color: 'common.white',
    backgroundColor: 'chip.assignedBg',
  },

  UNAVAILABLE: {
    label: 'Unavailable',
    color: 'common.black',
    backgroundColor: 'chip.completedBg',
  },
  INACTIVE: {
    label: 'Inactive',
    color: 'common.black',
    backgroundColor: 'chip.canceledBg',
  },
};

export const assetConfigDark: { [key: string]: configType } = {
  AVAILABLE: {
    label: 'Available',
    color: 'common.black',
    backgroundColor: 'leftMenuExpanded.inactive',
  },
  ASSIGNED: {
    label: 'Assigned',
    color: 'common.black',
    backgroundColor: 'chip.assigned2Bg',
  },
  UNAVAILABLE: {
    label: 'Unavailable',
    color: 'text.primary',
    backgroundColor: 'tabStrip.sidePanelTabIcon',
  },
  INACTIVE: {
    label: 'Inactive',
    color: 'text.primary',
    backgroundColor: 'chip.canceledBg',
  },
};

export const payStatementConfig: IStatusConfigs = {
  pendingPayment: {
    label: 'Pending',
    color: 'common.white',
    backgroundColor: 'info.dark',
  },
  inReviewStatement: {
    label: 'In-Review',
    color: 'common.black',
    backgroundColor: 'error.light',
  },
  reviewedStatement: {
    label: 'Reviewed',
    color: 'common.black',
    backgroundColor: 'chip.reviewedStatementBg',
  },
  closedStatement: {
    label: 'Closed',
    color: 'common.black',
    backgroundColor: 'chip.closedStatementBg',
  },
};

export const paymentManagementStatusConfig: IStatusConfigs = {
  PAID: {
    label: 'Paid',
    color: 'common.black',
    backgroundColor: 'success.main',
  },
  CLOSED: {
    label: 'Closed',
    color: 'common.black',
    backgroundColor: 'chip.closedStatementBg',
  },
};

export const teamDriverStatusConfigs: IStatusConfigs = {
  ACTIVE: {
    color: 'common.white',
    label: 'Active',
    backgroundColor: 'primary.main',
  },
  INACTIVE: {
    color: 'common.black',
    label: 'Inactive',
    backgroundColor: 'chip.canceledBg',
  },
};

export const brokeredTripConfig: IStatusConfigs = {
  BROKERED: {
    label: 'Brokered',
    color: 'common.white',
    backgroundColor: 'info.main',
  },
};

export const assetConfigUserStatus: IStatusConfigs = {
  0: {
    label: 'Inactive',
    color: 'common.black',
    backgroundColor: 'chip.canceledBg',
  },
  1: {
    label: 'Active',
    color: 'common.white',
    backgroundColor: 'primary.main',
  },
  2: {
    label: 'Active (Non-invited)',
    color: 'common.white',
    backgroundColor: 'primary.main',
  },
  3: {
    label: 'Active (Invited)',
    color: 'common.white',
    backgroundColor: 'primary.main',
  },
  4: {
    label: 'Active (Blocked)',
    color: 'common.white',
    backgroundColor: 'primary.main',
  },
};
