import { yupResolver } from '@hookform/resolvers/yup';
import { CloseOutlined } from '@mui/icons-material';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { DirtyDetailsPanelManager } from '../../../../DetailsPanel/utils';
import { BREAKPOINT_TABLET } from '../../../../hooks/useThemeResponsive';
import { useTranslation } from 'react-i18next';
import { getTripAssignmentComponentStyles } from './styles/TripAssignmentComponent.styles';
import { getAssignmentFormWrapperStyles } from './styles/AssignmentFormWrapper.styles';

export default function AssignmentFormWrapper({
  title,
  actionLabel,
  onClose,
  onAction,
  onAdditionalAction,
  children,
  data,
  validationSchema,
  additionalActionLabel,
  sxProps,
}: {
  data: any;
  validationSchema?: any;
  title: string;
  actionLabel: string;
  additionalActionLabel?: string;
  children: ReactNode;
  onClose: () => void;
  onAction: (d) => void;
  onAdditionalAction?: (d) => void;
  sxProps?: SxProps;
}): JSX.Element {
  const state = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: data,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });
  const [isAssigning, setIsAssigning] = useState(false);
  const handleActionClick = async (updatedData: any) => {
    setIsAssigning(true);
    if (onAction) await onAction({ ...updatedData });
    setIsAssigning(false);
  };

  const handleAdditionalActionClick = async (updatedData: any) => {
    setIsAssigning(true);
    if (onAdditionalAction) await onAdditionalAction({ ...updatedData });
    setIsAssigning(false);
  };

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = state;

  useEffect(() => {
    DirtyDetailsPanelManager.panelName = isDirty
      ? 'AssignmentFormWrapper'
      : null;

    DirtyDetailsPanelManager.isDirty = isDirty;
  }, [isDirty]);

  useEffect(() => {
    return () => {
      DirtyDetailsPanelManager.panelName = null;
      DirtyDetailsPanelManager.isDirty = false;
    };
  }, []);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, setValue]);

  const theme = useTheme();
  const styles = getAssignmentFormWrapperStyles(theme);
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Box
        className="AssignmentFormWrapperRoot"
        sx={() => ({
          ...styles.root,
          ...sxProps,
        })}
      >
        <Box sx={styles.container}>
          <Typography align="center" variant="h7" sx={{ lineHeight: '24px' }}>
            {title}
          </Typography>
          <Box component="button" onClick={onClose}>
            <CloseOutlined width="14px" />
          </Box>
        </Box>
        <Box>
          <FormProvider {...state}>
            <Box>
              <form
                onSubmit={handleSubmit(handleActionClick)}
                style={{ width: '100%' }}
              >
                {children}
              </form>
            </Box>
          </FormProvider>
        </Box>
        <Box sx={styles.buttonActionRoot}>
          <ButtonImproved
            styleProps={styles.buttonAction}
            onClick={onClose}
            size="small"
            color="primary"
            label={t('Cancel')}
            variant="outlined"
          />
          {additionalActionLabel && (
            <ButtonImproved
              disabled={!isDirty || isAssigning}
              startIcon={
                isAssigning && <CircularProgress size={20} color="text" />
              }
              styleProps={{
                ...styles.buttonAction,
                ml: 2,
              }}
              size="small"
              onClick={handleSubmit(handleAdditionalActionClick)}
              color="primary"
              label={additionalActionLabel}
              variant="contained"
            />
          )}
          <ButtonImproved
            disabled={!isDirty || isAssigning}
            startIcon={
              isAssigning && <CircularProgress size={20} color="text" />
            }
            styleProps={{
              ...styles.buttonAction,
              ml: 2,
            }}
            size="small"
            onClick={handleSubmit(handleActionClick)}
            color="primary"
            label={actionLabel}
            variant="contained"
          />
        </Box>
      </Box>
    );
  }
}
