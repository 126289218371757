import { Box } from '@mui/material';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { useEffect, useRef } from 'react';
import CustomizedTooltips from '../../../../ui-kit/components/AxeleTooltip';
import { MarkersProps } from '../types';
import { convertToMapMarker } from '../Components/utils';
import MarkerLoader from './MarkerLoader';
const Markers = ({
  map,
  lat,
  lng,
  type,
  status,
  issues,
  toolTip,
  isUnclusteredPoint,
  markerInfo,
  onClick,
  isLoader = false,
  onMarkerInitialize,
}: MarkersProps) => {
  const markerRef = useRef<any>();
  const markerInstance = useRef<any>();

  useEffect(() => {
    if (!map) return;
    if (isUnclusteredPoint) {
      markerInstance.current = new TrimbleMaps.Marker({
        element: markerRef.current,
      })
        .setLngLat([lng, lat])
        .addTo(map);
      onMarkerInitialize?.(markerInstance.current);
    } else markerInstance.current?.remove?.();
  }, [map, isUnclusteredPoint]);

  return (
    <CustomizedTooltips
      arrow={true}
      enterDelay={1000}
      component={toolTip?.tooltipComponent}
      isCustomizationTitle={Boolean(toolTip?.tooltipComponent)}
      titleFirstLine={toolTip?.toolTipText}
      backgroundColor={'#0D3C61'}
      toolTipStyleProps={{
        '& .MuiTooltip-tooltip': {
          padding: 0,
          maxWidth: '343px',
          borderRadius: '17px',
        },
      }}
    >
      <Box
        id="marker"
        ref={markerRef}
        sx={{ display: 'flex', flexDirection: 'column', position: 'absolute' }}
        onClick={onClick}
      >
        {isLoader && <MarkerLoader />}
        {!isLoader &&
          convertToMapMarker({
            iconType: type,
            issue: issues,
            toolTip: false,
            markerInfo,
            other: {
              map,
              lat,
              lng,
              type,
              status,
              issues,
              toolTip,
            },
          })}
      </Box>
    </CustomizedTooltips>
  );
};

export default Markers;
