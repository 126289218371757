import TerminalShort from '../models/common/TerminalShort';

export const getPrimaryTerminalOrFirstOne = (terminals: TerminalShort[]) =>
  terminals?.find((t) => t.isPrimary === true) || terminals[0];

export const getTerminalById = (id: string, terminals?: TerminalShort[]) => {
  if (terminals?.length && id) {
    return terminals.find((t) => t.id === id);
  }
  return null;
};
