import { generateUtilityClasses } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { AxeleThemeProps, CLOCK_HOUR_WIDTH } from '../constants';
export const classes = generateUtilityClasses('PrivateClockNumber', [
  'selected',
  'disabled',
]);

export const StyledList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  postion: 'relative',
  overflowY: 'auto',
}));

export const StyledTextField = styled(TextField)(
  ({ theme }: AxeleThemeProps) => ({
    //TODO refactoring moved to src/ui-kit/theme/components/MuiFormLabel.ts
    // '.MuiFormLabel-asterisk': {
    //   color: theme.palette.error.main,
    // },
  })
);

export const StyledDialog = styled(Dialog)(({ theme }: AxeleThemeProps) => ({
  '.MuiPaper-root': {
    backgroundColor: theme.palette?.leftMenuExpanded?.panel,
    '.MuiFormControl-root': {
      '&.MuiTextField-root': {
        '.MuiInputLabel-root, .MuiInput-root, .MuiInputAdornment-root': {
          color: theme.palette?.primary?.contrast,
        },
      },
    },
  },
  '.MuiDialogContent-root': {
    padding: 0,
    overflowY: 'hidden',
  },
  '& .MuiDialog-paper': {
    width: '286px',
    borderRadius: '14px',
  },
}));

export const StyledDialogHeader = styled('div')(
  ({ theme }: AxeleThemeProps) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '150%',
    color: theme.palette?.primary?.contrast,
    padding: '10px',
    textAlign: 'center',
    height: '100px',
    verticalAlign: 'middle',
  })
);

export const StyledDialogContent = styled('div')(
  ({ theme }: AxeleThemeProps) => ({
    borderRadius: '14px 14px 0 0 ',
    display: 'flex',
    flexDirection: 'row',
    background: theme.palette?.primary?.contrast,
    height: '342px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
  })
);
export const StyledDialogActions = styled(DialogActions)(
  ({ theme }: AxeleThemeProps) => ({
    background: theme.palette?.primary?.contrast,
    boxShadow: `0 1px 0 0 inset ${theme.palette.divider}`,
  })
);

export const StyledClockHeaderTime = styled('div')(
  ({ theme }: AxeleThemeProps) => ({
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '150%',
    color: theme.palette.text.secondary,
    position: 'relative',
  })
);

export const ClockNumberRoot = styled(Button)(({ theme }: AxeleThemeProps) => ({
  height: CLOCK_HOUR_WIDTH,
  width: CLOCK_HOUR_WIDTH,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '&.MuiButton-root': {
    minWidth: 'unset',
  },
  '&:focused': {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${classes.selected}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette?.primary?.contrast,
  },
  [`&.${classes.disabled}`]: {
    pointerEvents: 'none',
    color: theme.palette.text.disabled,
  },
}));
