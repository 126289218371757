import {
  EDispatchOperationType,
  EEmploymentType,
  EOperationMode,
} from '../../../views/dispatch/constants/types';
import { DriverMapMarkersRequestParams } from '../Driver/Requests';
import { QueryParams } from '../commonMixed';

export class PaginatedDispatchDriverRequest {
  startDate?: string;
  dispatcherIdList?: number[];
  driverIds?: number[];
  employmentType?: EEmploymentType;
  excludeTimeline?: boolean;
  groupMode?: EOperationMode;
  hasTimeOff!: boolean;
  hasViolation!: boolean;
  needLoad!: boolean;
  operationType?: EDispatchOperationType;
  terminalIds?: string[];
  timezone?: string;
  window?: number;

  constructor(dto: any) {
    this.startDate = dto.startDate;
    this.hasViolation = dto.hasViolation;
    this.hasTimeOff = dto.hasTimeOff;
    this.excludeTimeline = dto.excludeTimeline;
    this.groupMode = dto.groupMode;
    this.terminalIds = dto.terminalIds;
    this.timezone = dto.timezone;
    this.driverIds = dto.driverIds;
    this.needLoad = dto.needLoad;
    this.dispatcherIdList = dto.dispatcherIdList;
    this.employmentType = dto.employmentType;
    this.operationType = dto.operationType;
    this.window = dto.window;
  }
}

export class PaginatedDispatchDriverRequestQuery extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;

  constructor(dto: any) {
    super();
    this.pageNumber = dto.pageNumber;
    this.pageSize = dto.pageSize;
    this.sort = dto.sort;
  }
}

export class GanttWarningsRequest extends QueryParams {
  driverGroupIds!: string[];
  constructor(dto: any) {
    super();
    this.driverGroupIds = dto.driverGroupIds;
  }
}

export class GanttDriverLocationMarkersRequest extends QueryParams {
  constructor(dto: DriverMapMarkersRequestParams) {
    super();
    const { driverGroupIds, ...restDto } = dto;
    Object.assign(this, restDto);
  }
}

export class GetDispatchDetailsPanelRequest extends QueryParams {
  groupId!: string;
  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class GetDispatchDetailsPanelMapDataRequest extends QueryParams {
  groupId!: string;
  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class DriverTractorUnAssignRequest extends QueryParams {
  driverGroupId!: string;
  tractorId!: string;
  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
export class DriverTractorAssignRequest extends DriverTractorUnAssignRequest {
  // driverId!: string;
  // driverName!: string;
  constructor(dto: any) {
    super(dto);
    Object.assign(this, dto);
  }
}

export class DriverTrailerUnAssignRequest extends QueryParams {
  driverGroupId!: string;
  trailerId!: string;
  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

// @TODO: Anna - the same logic as in DTOs/Trailer/Requests.ts/ DriverTrailerAssignRequest
// I think that part is more well structured, it doesn't create new object
export class DriverTrailerAssignRequest extends DriverTrailerUnAssignRequest {
  // driverId!: string;
  // driverName!: string;
  constructor(dto: any) {
    super(dto);
    Object.assign(this, dto);
  }
}

export class GetPreviousLoadRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;
  seqNumber!: string;
  driverGroupId!: string;
  loadStatus!: string;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

//create timeoff
class CreateTimeoffRequestDto extends QueryParams {
  assignment!: Assignment;
  autoPositioning!: boolean;
  concernRaised!: boolean;
  connectedLoads!: any[];
  createDate!: string;
  driverGroupId!: string;
  driverId!: number;
  driverName!: string;
  hasBeenCancelled!: boolean;
  invoice!: Invoice;
  loadCreatorUserId!: number;
  loadCategory!: string;
  loadStatus!: string;
  numberOfStops!: number;
  operationType!: string;
  prevLoadId!: string;
  revenue!: number;
  stops!: Stop[];

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

interface Assignment {
  assignmentDate: string;
  loadedMiles: number;
  updateDate: string;
  tractorId: number;
  trailerId: number;
}

interface Invoice {
  //
}

interface Stop {
  activity: string;
  activityType: string;
  connectedStopDetails: any[];
  createDate: string;
  estimatedActivityDuration: string;
  fixedAppointment: boolean;
  id: number;
  isDriverAssistRequired: boolean;
  last: boolean;
  location: Location;
  sequenceNumber: number;
  stopCategory: string;
  stopStatus: string;
  timezone: string;
}

export interface Location {
  address: string;
  center: Center;
  city: string;
  country: string;
  fullAddress: string;
  lat: string;
  lng: string;
  state: string;
  streetAddress: string;
  timezone: string;
  zipcode: string;
}

export interface Center {
  lat: string;
  lng: string;
}

export class CreateTimeoffRequest extends CreateTimeoffRequestDto {
  id?: any;
  constructor(dto: any) {
    super(dto);
    Object.assign(this, dto);
  }
}

export class UpdateTimeoffRequest extends CreateTimeoffRequestDto {
  id?: any;
  constructor(dto: any) {
    super(dto);
    Object.assign(this, dto);
  }
}
export class DeleteTimeoffRequest extends QueryParams {
  id!: string;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
export class RevertOrActivateTimeoffRequest extends QueryParams {
  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class UpdateGroupAssgnmentRequest extends QueryParams {
  groupId!: string;
  loadIdsAndStatuses!: {
    loadId: string;
    loadStatus: string;
  }[];

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class UpdatePreferenceDriverRequest extends QueryParams {
  groupId!: string;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
export class GetPreferenceDriverRequest extends QueryParams {
  driverId!: number;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
export class GetDriverLoadsGroupAssignmentRequest extends QueryParams {
  groupId!: string;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
export class RegenerateTimelineRequest extends QueryParams {
  groupId!: string;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
export class GetTractorInfoForDriverAssignRequest extends QueryParams {
  axeleIdFilter!: string;
  statusList!: string;
  pageSize!: number;
  pageNumber!: number;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
export class GetTrailerInfoForDriverAssignRequest extends QueryParams {
  statusList!: string;
  pageSize!: number;
  pageNumber!: number;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}

export class ChangeStatusBulkSelectUnassignedTripsRequest extends QueryParams {
  tripIds?: string[];
  status?: string;
  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class GetDispatchGridDataRequest extends QueryParams {
  terminalIdsFilter!: string[];
  hasViolation!: boolean;
  originRadius!: number;
  destinationRadius!: number;
  tripIdsFilter!: string[];
  driverIdsFilter!: string[];
  originCityStateList!: { value: string }[];
  destinationCityStateList!: { value: string }[];
  originSearchCenter: any;
  destinationSearchCenter: any;
  sort?: string;
}
