import { useTheme } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { paymentService, tractorService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import DetailsPanel from '../../../common/DetailsPanel';
import { getTerminals } from '../../../common/Layouts/main/topMenu/constants/helper';
import { EQUIPMENT_PANEL_TABS } from '../../../constants/contacts';
import { useEquipment } from '../../../contexts/EquipmentContext';
import {
  AssetsCount,
  DriverTractorAssignResponse,
  ExpensesStaticDataType,
  OwnerOperatorPaymentRecipient,
  PaymentType,
  TractorAlertSummaryDTO,
  TractorResponse,
} from '../../../models';
import { GetFinancialItemRequest } from '../../../models/DTOs/FinanceLoadService';
import { UserDetails } from '../../../models/DTOs/PaymentTerms/TractorPaymentTermConstant';
import {
  LoadRateDTO,
  PaymentTermsRequest,
} from '../../../models/DTOs/PaymentTerms/request';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
import { EquipmentType } from '../../../views/maintenanceRecords/constants/constants';
import { renderCustomTitle } from '../../../views/operations/equipment/AssetsPanelCustomTitle';
import { useSafetyAlert } from '../../../views/safety/alerts/context/SafetyAlertContext';
import { getTractorPaymentTerms } from '../../equipment/TractorPaymentTerm/TractorPaymentTermFormContext';
import {
  getDocumentTypeList,
  getStateOptions,
} from '../../expenses/utils/utils';
import { saveFile, updateTractorAndTrailerDocs } from '../../shared/equipment';
import {
  getAllPaymentTypes,
  getAllPaymentTypesMap,
} from '../../users/components/PaymentTermForm/utils';
import TractorController from '../Controller';
import { ActionsSection } from '../actionsSection';
import { Tractor } from '../models/Tractor';
import { TractorSummary } from '../models/TractorSummary';
import { isUnavailable } from '../utils';
import DetailsTabStrips, { tabStripRendererByKeyFS } from './DetailsTabStrips';
import TractorDetailsForm, {
  getStaticDataTractorDetails,
} from './TractorDetailsForm';
import { EQUIPMENT_TRACTOR_VALIDATION_SCHEMA } from './ValidationSchema';

interface IProps {
  data: any;
  setData: React.Dispatch<React.SetStateAction<TractorSummary | null>>;
  setTableData: React.Dispatch<
    React.SetStateAction<(TractorSummary | Tractor)[]>
  >;
  onClose: () => void;
  onUpdated?: (data: any) => void;
  onDeleted: (data: any) => void;
  onCreated: (data: any) => void;
  defaultTab?: EQUIPMENT_PANEL_TABS;
  autoExpanded?: boolean;
  isGlobal?: boolean;
  onUpdateWarningsCount: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
  onAlertUpdated?: (response: any) => void;
}

export default function TractorDetailsPanel({
  data,
  onUpdateWarningsCount,
  onClose,
  onUpdated,
  onDeleted,
  onCreated,
  defaultTab = EQUIPMENT_PANEL_TABS.GENERAL,
  autoExpanded = false,
  isGlobal = false,
  onAlertUpdated,
}: IProps) {
  const { setEquipmentCount } = useEquipment();
  const [tractorData, setTractorData] = useState(data);
  const [httpErrors, setHttpErrors] = useState();
  const staticDataRef = React.useRef<ExpensesStaticDataType>({
    stateOptions: [],
    documentTypes: [],
    terminalsList: [],
  });

  const [selectedTab, setSelectedTab] =
    useState<EQUIPMENT_PANEL_TABS>(defaultTab);
  const [showOwnerOperatorChangeWarning, setShowOwnerOperatorChangeWarning] =
    useState<boolean>(false);
  const [updatedUserDetails, setUpdatedUserDetails] =
    useState<UserDetails | null>();

  useEffect(() => {
    if (
      (data.id || selectedTab === EQUIPMENT_PANEL_TABS.ALERTS) &&
      selectedTab !== EQUIPMENT_PANEL_TABS.PAYMENT_TERMS
    ) {
      const { getTractorById, getTractorAlertList } =
        TractorController.instance();
      let alertsList: TractorAlertSummaryDTO[] = [];

      getTractorAlertList(data.id, (alerts) => {
        alertsList = alerts;
        getTractorById(data.id, (tractor) => {
          setTractorData({ ...tractor, alertsList });
        });
      });
    }
  }, [data.id, selectedTab]);

  useEffect(async () => {
    if (data.id || selectedTab === EQUIPMENT_PANEL_TABS.PAYMENT_TERMS) {
      const paymentTerms = await getTractorPaymentTerms(data.id);
      setTractorData({
        ...tractorData,
        paymentTerms,
        payToDetails: formatPayToDetails(paymentTerms?.payToDetails!),
      });
    }
  }, [data.id, selectedTab]);

  const { fetchMainData } = useSafetyAlert();
  const hasUnavailableStatus = isUnavailable(tractorData.status);
  const handleUpdate = (
    panelTab: EQUIPMENT_PANEL_TABS,
    updatedTractor: TractorSummary
  ): void => {
    const { updateTractorAlertsBulk, updateTractor } =
      TractorController.instance();
    switch (panelTab) {
      case EQUIPMENT_PANEL_TABS.ALERTS:
        if (updatedTractor.alertsList?.length) {
          updateTractorAlertsBulk(updatedTractor.alertsList, (alertsList) => {
            fetchMainData?.();
            onAlertUpdated?.(alertsList);
            let warnings = 0;
            let criticals = 0;
            alertsList?.map((alert) => {
              if (
                alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'CRITICAL'
              ) {
                criticals += 1;
              }
              if (
                alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'WARNING'
              ) {
                warnings += 1;
              }
            });
            if (typeof onUpdateWarningsCount === 'function') {
              onUpdateWarningsCount(tractorData.id, warnings, criticals, false);
            }
            setTractorData({ ...tractorData, alertsList });
          });
        }
        break;

      case EQUIPMENT_PANEL_TABS.MAINTENANCE:
        break;

      case EQUIPMENT_PANEL_TABS.PAYMENT_TERMS:
        handlePaymentTractorTermsUpdate(updatedTractor);
        break;

      default:
        updateTractor(
          { ...updatedTractor, id: tractorData.id },
          async (res, errors) => {
            if (errors) {
              return setHttpErrors(errors);
            }
            await updateTractorAndTrailerDocs(
              updatedTractor,
              tractorData,
              staticDataRef.current,
              EquipmentType.Tractor
            );
            const data = await tractorService.getTractorById(tractorData.id);
            onUpdated?.(new TractorSummary(res));
            setTractorData({ ...new Tractor(data) });
          }
        );
        break;
    }
  };

  // @TODO: Anna make different component
  const handleTractorDriverAssign = async (updatedData: any) => {
    const driverGroupId = updatedData?.driverGroup.id;
    const tractorId = tractorData.id;
    const result = await TractorController.instance().driverTractorAssign(
      driverGroupId,
      tractorId
    );
    if (result instanceof DriverTractorAssignResponse) {
      const newTractorData = await tractorService.getTractorById(data.id);
      const updatedData = { ...data };
      updatedData.driver = {
        name: result.driverGroupName,
        id: result.driverGroupId,
      };
      updatedData.status = 'ASSIGNED';
      updatedData.locationSource = 'DRIVER';
      updatedData.locationShort =
        newTractorData.assetLocationDTO.addressDTO?.fullAddress;
      setTractorData((prevValues: Tractor) => ({
        ...prevValues,
        driverGroup: { name: result.driverGroupName, id: result.driverGroupId },
        status: 'ASSIGNED',
        locationSource: 'DRIVER',
        lastLocation: {
          address: newTractorData.assetLocationDTO.addressDTO?.address,
          city: newTractorData.assetLocationDTO.addressDTO?.city,
          fullAddress: newTractorData.assetLocationDTO.addressDTO?.fullAddress,
          state: newTractorData.assetLocationDTO.addressDTO?.state,
          streetAddress:
            newTractorData.assetLocationDTO.addressDTO?.streetAddress,
          streetAddress2:
            newTractorData.assetLocationDTO.addressDTO?.streetAddress2,
          zipcode: newTractorData.assetLocationDTO.addressDTO?.zipcode,
          timezone: newTractorData.assetLocationDTO.addressDTO?.timezone,
        },
      }));
      onUpdated?.(updatedData);
      return true;
    }
  };

  const deactivateTractor = () => {
    const updatedData = { ...tractorData, status: 'INACTIVE' };
    setTractorData(updatedData);
    onUpdated?.(updatedData);
  };

  const handleTractorDriverUnAssign = async (driverGroupId: string) => {
    const result = await TractorController.instance().driverTractorUnAssign(
      driverGroupId
    );
    if (result instanceof DriverTractorAssignResponse) {
      const updatedData = { ...data };
      updatedData.driver = null;
      updatedData.status = 'AVAILABLE';
      updatedData.locationSource = '';
      updatedData.locationShort = null;
      setTractorData((prevValues: Tractor) => ({
        ...prevValues,
        driverGroup: null,
        status: 'AVAILABLE',
        locationSource: null,
        lastLocation: {
          fullAddress: '',
          address: null,
          streetAddress: null,
          streetAddress2: null,
          timezone: null,
          zipcode: null,
          city: null,
          center: null,
          state: null,
        },
      }));
      onUpdated?.(updatedData);
      return true;
    }
  };

  const handleTractorStatusChange = async (
    tractorId: number,
    status: string
  ) => {
    const result = await TractorController.instance().changeTractorStatus(
      tractorId,
      status
    );
    if (result[0] instanceof TractorResponse) {
      const updatedData = { ...data };
      updatedData.status = result[0].status;
      setTractorData((prevValues: Tractor) => ({
        ...prevValues,
        status: result[0].status,
      }));

      onUpdated?.(updatedData);
      return true;
    }
  };

  const handlePaymentTractorTermsUpdate = async (updatedUser: UserDetails) => {
    if (
      updatedUser?.paymentTerms?.payToDetails?.payToEnabled &&
      updatedUser?.payToDetails?.id !==
        updatedUser?.paymentTerms?.payToDetails?.payToEntityId
    ) {
      setUpdatedUserDetails(updatedUser);
      return setShowOwnerOperatorChangeWarning(true);
    }
    handlePaymentTermsUpdate(updatedUser);
  };

  const handlePaymentTermsUpdate = async (updatedUser: UserDetails) => {
    try {
      if (
        updatedUser.paymentTerms === null &&
        updatedUser?.payToDetails === null
      ) {
        return;
      }

      const requestData = new GetFinancialItemRequest(true);
      const allPaymentTypes: PaymentType[] = await getAllPaymentTypes(
        requestData
      );
      const allPaymentTypesMap = getAllPaymentTypesMap(allPaymentTypes);

      const requestPaymentData = new PaymentTermsRequest(
        updatedUser.paymentTerms,
        allPaymentTypesMap,
        new OwnerOperatorPaymentRecipient({
          entityId: updatedUser?.id,
          payToEntityId: updatedUser?.payToDetails?.id,
          payToEntityName: updatedUser?.payToDetails?.name,
        })
      );
      const updatedPaymentTermsResponse =
        await paymentService.bulkUpdateForTractorPayment(requestPaymentData);

      if (updatedPaymentTermsResponse instanceof ServiceError) {
        // TODO: error on update, should be decided what should to do
        return;
      } else {
        const newLoadRateDTO = updatedPaymentTermsResponse?.loadRateDTO?.map(
          (item) => new LoadRateDTO(item)
        );
        updatedPaymentTermsResponse.loadRateDTO = newLoadRateDTO;
        setTractorData({
          ...tractorData,
          payToDetails: formatPayToDetails(
            updatedPaymentTermsResponse?.payToDetails!
          ),
          paymentTerms: updatedPaymentTermsResponse,
        });
      }
      setUpdatedUserDetails(null);
      setShowOwnerOperatorChangeWarning(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formatPayToDetails = (payToDetails: OwnerOperatorPaymentRecipient) => {
    if (!payToDetails?.payToEnabled) return null;
    return {
      ...payToDetails,
      id: payToDetails?.payToEntityId!,
      name: payToDetails?.payToEntityName,
    };
  };

  const getStaticData = async () => {
    const documentTypes = await getDocumentTypeList();
    const terminalsList = await getTerminals();
    const stateOptions = await getStateOptions();

    staticDataRef.current = {
      stateOptions,
      documentTypes,
      terminalsList: terminalsList.content,
    };
  };

  useEffect(() => {
    getStaticData();
  }, []);

  const handleCreate = (newTractor: TractorSummary): void => {
    TractorController.instance().createTractor(
      newTractor,
      async (res, errors) => {
        if (errors) {
          return setHttpErrors(errors);
        }
        setEquipmentCount({
          ...TractorController.instance().equipmentCount,
        } as AssetsCount);

        const tractorData = {
          ...res,
          assignedDocuments: newTractor?.assignedDocuments,
        };
        await saveFile(
          tractorData,
          staticDataRef.current,
          EquipmentType.Tractor
        );
        const data = await tractorService.getTractorById(tractorData.id);
        setTractorData({ ...new Tractor(data), alertsList: [] });
        onCreated(new TractorSummary(res));
      }
    );
  };

  const handleTabStripChange = (selected: EQUIPMENT_PANEL_TABS): void => {
    setSelectedTab(selected);
  };

  const theme: any = useTheme();
  const panelWrapperCustomStyles = {
    display: 'flex',
    width: '33.3%',
    padding: '0 5px 5px 0',
    height: '100%',
    flexDirection: 'column',
    background: theme.palette.leftMenuCollapsed.canvas,
    boxShadow: 'unset',
  };

  const tabStripRenderer = (keys: EQUIPMENT_PANEL_TABS[]) => (
    <DetailsTabStrips
      keys={keys}
      defaultTab={defaultTab}
      onChange={handleTabStripChange}
      tractorId={tractorData?.id}
    />
  );

  const isEdit = !!tractorData.id;
  const onFullscreen = (isFullscreen: boolean): void => {
    //set default tab when fullscreen
    setSelectedTab(
      isFullscreen && isEdit ? EQUIPMENT_PANEL_TABS.MAINTENANCE : defaultTab
    );
  };
  const entity = 'Tractor';

  useEffect(() => {
    getStaticDataTractorDetails(); //fetch 1 only
  }, []);

  const [openDialog, setOpenDialog] = useState<
    | 'markAvailable'
    | 'markUnavailable'
    | 'delete'
    | 'inactive'
    | 'inactiveFail'
    | ''
  >('');
  const [showPopup, setShowPopup] = useState<{
    allow?: boolean;
    body?: string;
  } | null>();

  const actionsRenderer = () => {
    if (tractorData.id) {
      return (
        <ActionsSection
          onDeleted={onDeleted}
          tractorData={tractorData}
          onUnassign={handleTractorDriverUnAssign}
          onStatusChange={handleTractorStatusChange}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          deactivateTractor={deactivateTractor}
        />
      );
    }
  };

  const onCancelPaymentWarning = () => {
    setUpdatedUserDetails(null);
    setShowOwnerOperatorChangeWarning(false);
  };

  const updatePaymentRecipientWarningText = useMemo(() => {
    if (!updatedUserDetails) return '';
    if (
      updatedUserDetails?.paymentTerms?.payToDetails?.payToEnabled &&
      !updatedUserDetails?.payToDetails?.id
    )
      return `Removing the Payment Recipient ${updatedUserDetails?.paymentTerms?.payToDetails?.payToEntityName} will restore Pending settlements to the tractor ${updatedUserDetails?.tractorName}.`;
    return `Changing the Payment Recipient from ${updatedUserDetails?.paymentTerms?.payToDetails?.payToEntityName} to ${updatedUserDetails?.payToDetails?.name} will affect all Pending tractor settlements.`;
  }, [updatedUserDetails]);

  return (
    <>
      <DetailsPanel
        data={tractorData}
        actionButtonLabel={tractorData.id ? undefined : t('addTractor')}
        entity="Tractor"
        panelTitle={
          tractorData.id
            ? `${t('tractor')}: ${tractorData?.tractorName || ''}`
            : t('addNewTractor')
        }
        isSaveIconVisible={tractorData.id}
        renderCustomTitle={
          tractorData.id && (() => renderCustomTitle(tractorData))
        }
        onClose={onClose}
        onUpdate={(userData) => handleUpdate(selectedTab, userData)}
        onCreate={handleCreate}
        contentRenderer={(expanded: boolean) => (
          <TractorDetailsForm
            hasUnavailableStatus={hasUnavailableStatus}
            expanded={expanded}
            selectedTab={selectedTab}
            onUpdateWarningsCount={onUpdateWarningsCount}
            onAssign={handleTractorDriverAssign}
            data={tractorData}
            setOpenDialog={setOpenDialog}
            setShowPopup={setShowPopup}
          />
        )}
        tabStripRenderer={() =>
          tabStripRenderer(Object.values(EQUIPMENT_PANEL_TABS))
        }
        validationSchema={EQUIPMENT_TRACTOR_VALIDATION_SCHEMA[selectedTab]}
        httpErrors={httpErrors}
        autoExpanded={autoExpanded}
        isGlobal={isGlobal}
        onFullscreen={onFullscreen}
        {...(isEdit && {
          fullscreen: {
            panelPropList: [
              {
                data: tractorData,
                entity: entity,
                panelId: EQUIPMENT_PANEL_TABS.GENERAL,
                panelTitle: `Tractor: ${tractorData.tractorName}`,
                renderCustomTitle: () => renderCustomTitle(tractorData),
                validationSchema:
                  EQUIPMENT_TRACTOR_VALIDATION_SCHEMA[
                    EQUIPMENT_PANEL_TABS.GENERAL
                  ],
                onUpdate: (userData) =>
                  handleUpdate(EQUIPMENT_PANEL_TABS.GENERAL, userData),
                contentRenderer: () => (
                  <TractorDetailsForm
                    hasUnavailableStatus={hasUnavailableStatus}
                    expanded={false}
                    selectedTab={EQUIPMENT_PANEL_TABS.GENERAL}
                    onUpdateWarningsCount={onUpdateWarningsCount}
                    onAssign={handleTractorDriverAssign}
                    data={tractorData}
                    setOpenDialog={setOpenDialog}
                    setShowPopup={setShowPopup}
                  />
                ),
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: '18px 0 0 18px', //override border radius
                },
                tabStripRenderer: () =>
                  tabStripRendererByKeyFS(EQUIPMENT_PANEL_TABS.GENERAL),
              },
              {
                data: tractorData,
                entity: entity,
                panelId: EQUIPMENT_PANEL_TABS.ALERTS,
                validationSchema:
                  EQUIPMENT_TRACTOR_VALIDATION_SCHEMA[
                    EQUIPMENT_PANEL_TABS.ALERTS
                  ],
                onUpdate: (userData) =>
                  handleUpdate(EQUIPMENT_PANEL_TABS.ALERTS, userData),
                contentRenderer: () => (
                  <TractorDetailsForm
                    hasUnavailableStatus={hasUnavailableStatus}
                    expanded={false}
                    selectedTab={EQUIPMENT_PANEL_TABS.ALERTS}
                    onUpdateWarningsCount={onUpdateWarningsCount}
                  />
                ),
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: 0, //override border radius
                },
                panelTitle: '',
                tabStripRenderer: () =>
                  tabStripRendererByKeyFS(EQUIPMENT_PANEL_TABS.ALERTS),
              },
              {
                data: tractorData,
                entity: entity,
                panelTitle: ``,
                panelId: 'CurrentContentForm',
                validationSchema:
                  EQUIPMENT_TRACTOR_VALIDATION_SCHEMA[selectedTab],
                onUpdate: (userData) => handleUpdate(selectedTab, userData),
                contentRenderer: () => {
                  return (
                    <TractorDetailsForm
                      hasUnavailableStatus={hasUnavailableStatus}
                      expanded={false}
                      selectedTab={selectedTab}
                      onUpdateWarningsCount={onUpdateWarningsCount}
                    />
                  );
                },
                panelWrapperCustomStyles: {
                  ...panelWrapperCustomStyles,
                  borderRadius: '0 18px 18px 0', //override border radius
                },
                tabStripRenderer: () => {
                  return tabStripRenderer([
                    EQUIPMENT_PANEL_TABS.MAINTENANCE,
                    EQUIPMENT_PANEL_TABS.PAYMENT_TERMS,
                  ]);
                },
              },
            ],
          },
        })}
        actionsRenderer={actionsRenderer}
      />

      {showOwnerOperatorChangeWarning && updatedUserDetails && (
        <DeletePopup
          open={true}
          title={t('updatePaymentRecipient')}
          body={updatePaymentRecipientWarningText}
          subtitle={'Other settlements will not be affected.'}
          onAction={() => handlePaymentTermsUpdate(updatedUserDetails!)}
          onClose={onCancelPaymentWarning}
          buttonText={'Continue'}
          width={'450px'}
          backgroundColor={theme?.palette?.primary?.main}
        />
      )}
    </>
  );
}
