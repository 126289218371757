import { observer } from 'mobx-react';
import moment from 'moment/moment';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { commonAlertService } from '../../../../api';
import {
  Alert,
  IAlert,
  PaginatedAlertList,
} from '../../../../models/DTOs/CommonAlert/CommonAlert';
import {
  AlertListCalendarRequest,
  PaginatedAlertListRequest,
} from '../../../../models/DTOs/CommonAlert/Request';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { ViewMetaData } from '../../../../types';
import { formatApiMaintenanceHistoryCompletedDate } from '../../../../utils/grid.utils';
import { RecordViewMode } from '../constants/constants';
import {
  ISafetyAlertFilters,
  ISafetyAlertQueryParamsCalendar,
  ISafetyAlertQueryParamsTable,
  ISettingsPagination,
  SafetyAlertQueryParams,
} from '../constants/types';

interface QueryParamsTableProps {
  reqPageNumber: number;
}

interface SafetyAlertContextInterface {
  calendarList: IAlert[];
  isCalendarLoading: boolean;
  getCalendarList: (
    queryParams: ISafetyAlertQueryParamsCalendar
  ) => Promise<void>;

  tableList: {
    totalRows: number;
    data: IAlert[];
  };
  getTableList: (queryParams: ISafetyAlertQueryParamsTable) => Promise<void>;

  settingsPagination: ISettingsPagination;
  setSettingsPagination: React.Dispatch<
    React.SetStateAction<ISettingsPagination>
  >;

  filters: ISafetyAlertFilters;
  setFilters: React.Dispatch<React.SetStateAction<ISafetyAlertFilters>>;
  resetFilters: () => void;

  defaultCurrentDate: Date;
  setDefaultCurrentDate: React.Dispatch<React.SetStateAction<Date>>;

  fetchMainData: () => Promise<void>;

  viewMode: RecordViewMode;
  setViewMode: Dispatch<SetStateAction<RecordViewMode>>;
}

const defaultSettingsPagination: ISettingsPagination = {
  isLoading: false,
  first: false,
  last: false,
  pageNumber: 1,
  pageSize: 20,
};
const defaultFilters: ISafetyAlertFilters = {
  maintenanceItems: [],
  alertStatus: [],
  equipmentName: [],
  equipmentType: [],
  startDate: null,
  endDate: null,
  sort: null,
};

export const SafetyAlertContext = createContext<SafetyAlertContextInterface>({
  settingsPagination: defaultSettingsPagination,
  tableList: {
    totalRows: 0,
    data: [],
  },
  filters: {},
  isCalendarLoading: false,
} as unknown as SafetyAlertContextInterface);

export const SafetyAlertProvider: FC = observer((props) => {
  const [tableList, setTableList] = useState<Alert[]>([]);
  const [calendarList, setCalendarList] = useState<Alert[]>([]);

  const [defaultCurrentDate, setDefaultCurrentDate] = useState<Date>(
    new Date()
  );
  const [settingsPagination, setSettingsPagination] =
    useState<ISettingsPagination>(defaultSettingsPagination);

  const [totalTableItems, setTotaTableItems] = useState(0);

  const [filters, setFilters] = useState<ISafetyAlertFilters>(defaultFilters);
  const [isCalendarLoading, setIsCalendarLoading] = useState<boolean>(false);

  const [viewMode, setViewMode] = useState<RecordViewMode>(
    RecordViewMode.calendar
  );

  const resetFilters = (): void => {
    setFilters(defaultFilters);
  };

  const getCalendarList = async (
    queryParams: ISafetyAlertQueryParamsCalendar
  ): Promise<void> => {
    try {
      setIsCalendarLoading(true);
      const data = await commonAlertService.getMaintenanceHistoryListCalendar(
        new AlertListCalendarRequest(queryParams)
      );
      setIsCalendarLoading(false);
      if (data instanceof Array<Alert>) {
        setCalendarList(data);
      }
    } catch (e) {
      setIsCalendarLoading(false);
    }
  };

  const getTableList = async (
    queryParams: ISafetyAlertQueryParamsTable
  ): Promise<void> => {
    setSettingsPagination((oldState) => {
      return {
        ...oldState,
        isLoading: true,
      };
    });

    try {
      const response = await commonAlertService.getPaginatedAlertList(
        new PaginatedAlertListRequest(queryParams)
      );
      if (response instanceof PaginatedAlertList) {
        setSettingsPagination((oldState) => {
          return {
            ...oldState,
            isLoading: false,
            first: response.first,
            last: response.last,
            pageNumber: response.number + 1,
          };
        });
        const items = response.content || [];
        if (response.number === 0) {
          //First page
          setTableList(items);
        } else {
          setTableList((oldState) => [...oldState, ...items]);
        }
        setTotaTableItems(response.numberOfElements);
      }
    } catch (e) {
      setSettingsPagination((oldState) => {
        return {
          ...oldState,
          isLoading: false,
        };
      });
    }
  };

  const { getGlobalTerminalsIds, getIsTerminalEnabled } = useRootStore();

  const filterToQuery = (
    filters: ISafetyAlertFilters
  ): SafetyAlertQueryParams => {
    return {
      ...(filters.maintenanceItems && filters.maintenanceItems.length
        ? {
            maintenanceItems: filters.maintenanceItems.map(
              ({ value }) => value
            ),
          }
        : {}),
      ...(filters.alertStatus && filters.alertStatus.length
        ? {
            alertStatus: filters.alertStatus.map(({ value }) => value),
          }
        : {}),
      ...(filters.equipmentName && filters.equipmentName.length
        ? {
            equipmentName: filters.equipmentName.map(({ value }) =>
              value.toString()
            ),
          }
        : {}),
      ...(filters.equipmentType && filters.equipmentType.length
        ? {
            equipmentType: filters.equipmentType.map(({ value }) => value),
          }
        : {}),
      lastServiceStartDate: filters.lastServiceStartDate,
      lastServiceEndDate: filters.lastServiceEndDate,
      nextServiceStartDate: filters.nextServiceStartDate,
      nextServiceEndDate: filters.nextServiceEndDate,
    };
  };

  const getQueryParamsCalendar = (): ISafetyAlertQueryParamsCalendar => {
    const overrideQuery: ViewMetaData = {};
    const startOfMonth = moment(defaultCurrentDate).startOf('month').toDate();
    const endOfMonth = moment(defaultCurrentDate).endOf('month').toDate();
    overrideQuery.startDate =
      formatApiMaintenanceHistoryCompletedDate(startOfMonth);
    overrideQuery.endDate =
      formatApiMaintenanceHistoryCompletedDate(endOfMonth);
    return {
      ...filterToQuery(filters),
      ...overrideQuery,
    };
  };

  const getQueryParamsTable = ({
    reqPageNumber,
  }: QueryParamsTableProps): ISafetyAlertQueryParamsTable => {
    return {
      ...filterToQuery(filters),
      pageSize: settingsPagination.pageSize,
      pageNumber: reqPageNumber,
    };
  };

  const fetchMainData = async (): Promise<void> => {
    const queryParams =
      viewMode === RecordViewMode.calendar
        ? getQueryParamsCalendar()
        : getQueryParamsTable({ reqPageNumber: 1 });
    if (getIsTerminalEnabled) {
      queryParams.terminalIds = getGlobalTerminalsIds;
    }
    if (viewMode === RecordViewMode.calendar) {
      await getCalendarList(queryParams as ISafetyAlertQueryParamsCalendar);
    } else {
      await getTableList(queryParams as ISafetyAlertQueryParamsTable);
    }
  };

  return (
    <SafetyAlertContext.Provider
      value={{
        calendarList: calendarList,
        getCalendarList: getCalendarList,
        isCalendarLoading,
        tableList: {
          totalRows: totalTableItems,
          data: tableList,
        },
        getTableList: getTableList,

        filters,
        setFilters,
        resetFilters,

        settingsPagination,
        setSettingsPagination,

        defaultCurrentDate,
        setDefaultCurrentDate,

        fetchMainData: fetchMainData,
        viewMode,
        setViewMode,
      }}
    >
      {props.children}
    </SafetyAlertContext.Provider>
  );
});

export const useSafetyAlert = () => useContext(SafetyAlertContext);
