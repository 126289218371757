import {
  QuickBooksOnline,
  QuickBooksOnlineWhite,
} from '../../../../ui-kit/components/Assets';

export const QuickBookProviderName = 'QuickBooks Online';

export const providersMap = [
  {
    providerId: 100,
    providerName: 'QuickBooks Online',
    logo: QuickBooksOnline,
    logoWhite: QuickBooksOnlineWhite,
    description:
      'QuickBooks is an accounting software that connects with your account to automatically import and categorize transactions. Sync with LoadOps and easily manage your invoices.',
    gradient:
      'linear-gradient(90.06deg, #2FA137 4.1%, rgba(255, 255, 255, 0.09) 55.11%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
];

export const sideDrawerDisplayData = {
  desc: 'Ensure you login with valid credentials to complete the integration. You will redirected to the vendor’s website to complete the integration.',
  fields: [],
  label: 'Enter Your Credentials:',
  userGuide: 'https://help.loadops.com/loadops-quickbook',
};

export const constantFactorText = {
  apexCardName: 'Apex Capital',
  apexFormStep: 'Enter Your Credentials',
  apexCardDescription:
    'Ensure you login with valid credentials to complete the integration. You will redirected to the vendor’s website to complete the integration.',
  apexFormSaveText: 'CONNECT',
  OFCCardName: 'Other Factoring Company',
  TAFSCardName: 'TAFS Capital Main',
  TAFSFormStep: 'Enter Your Credentials',
  TAFSFormSaveText: 'Authenticate',
  triumphCardName: 'Triumph Business Capital',
  triumphFormStep: 'Enter Your Credentials',
  triumphCardDescription:
    'Please verify all the below details as per your record in the Triumph system. LoadOps team will reach out to the Triumph team with the above information to activate the integration.',
  triumphFormSaveText: 'Request Integration',
  OTRCardName: 'OTR Capital',
  OTRFormStep: 'Enter Your Credentials',
  OTRFormSaveText: 'Authenticate',
  RTSCardName: 'RTS Capital',
  RTSFormStep: 'Enter Your Credentials',
  RTSFormSaveText: 'Request Integration',
  unlinkTitle: 'Unlink Customer?',
  unlinkDescription: 'Are you sure you want to unlink selected customer?',
  unlinkSaveText: 'Unlink Customer',
  deletetext: 'Delete Account ?',
  deleteDescription:
    'Are you sure you want to permanently delete the factoring company',
  deletSaveText: 'Delete',
  deActivateText: 'Deactivate Account ?',
  deActivateDescription: 'Are you sure you want to deactivate the provider?',
  deActivateSubTitle:
    'Please note that all related information will be permanently lost.',
  deActivateSaveText: 'Deactivate',
  RTSDefaultData:
    'This account has been transferred and assigned to RTS Financial Service, inc. By law payment must be made to: RTS Financial Service P.O. Box 840267 Dallas, TX 75284-0267.',
  triumphFormDefaultData:
    'THIS INVOICE HAS BEEN ASSIGNED TO AND MUST BE PAID DIRECTLY TO, ADVANCE BUSINESS CAPITAL LLC d/b/a TRIUMPH BUSINESS CAPITAL P.O. BOX 610028 DALLAS, TX 75261-0028',
  OTRFormDocumentData: 'OTR LLCC',
  OTRFormAddress: '1000 Holcomb Woods Parkway Building 300, Roswell, GA 30076',
  OTRFormEmail: 'noa@otrsolutions.com',
  OTRFormPhoneNumber: 7708820124,
};
