import React, { FC, Fragment } from 'react';
import { FuelCardProvidersMap } from '@/views/settings/integrations/FuelCard/constants';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { Provider } from '@/models/DTOs/FuelCards/FuelCards';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

interface Props {
  providers: Provider[];
  onProviderChange: (provider: Provider) => void;
}

export const Providers: FC<Props> = ({ providers, onProviderChange }) => {
  return (
    <List>
      {providers.map((provider, index: number) => {
        const { logo } = FuelCardProvidersMap[provider.providerId];
        return (
          <Fragment key={index}>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  my: '12px',
                  p: '20px 12px',
                  justifyContent: 'space-between',
                  '&:hover': {
                    background: '#D7E6FF',
                    borderRadius: '8px',
                  },
                  '&.selected-customer': {
                    background: '#D7E6FF',
                    borderRadius: '8px',
                  },
                }}
                onClick={() => onProviderChange(provider)}
                className={`${
                  provider.providerId === 1 ? 'selected-customer' : ''
                }`}
              >
                <ListItemIcon
                  aria-label="provider-name"
                  className="provider-logo"
                >
                  {logo}
                </ListItemIcon>
                <span aria-label="status-icon">
                  {provider.status === 'VALID' ? (
                    <DoneOutlinedIcon sx={{ color: '#4CAF50' }} />
                  ) : provider.status === 'INVALID' ? (
                    <WarningAmberOutlinedIcon />
                  ) : null}
                </span>
              </ListItemButton>
            </ListItem>
            {index !== providers.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </List>
  );
};
