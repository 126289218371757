export const MaintenanceHistoryConstants = {
  maintenanceRecords: 'Maintenance Records',
  maintenanceItem: 'Maintenance Item',
  assetType: 'Asset Type',
  vendor: 'Vendor',
  completionDate: 'Completion Date',
  deleteMaintenanceRecord: 'Delete maintenance record(s)',
  deleteMaintenanceRecordConfirmation:
    'Are you sure you want to permanently delete selected maintenance record(s)?',
  addContact: 'Add Contact',
  addNewVendor: 'Add New Vendor',
  fillInformation:
    'Please fill out the information below to add a new user to Axele.',
  vendorAffectBilling: 'Each New Vendor will affect your billing.',
  vendorDetails: 'Vendor Details',
  vendorName: 'Vendor name',
  address: 'Address',
  scheduleNextMaintenance: 'Schedule Next Maintenance',
  addNewMaintenanceRecord: 'Add New Maintenance Record',
  deleteMaintenanceHistory: 'Delete maintenance history',
  deleteMaintenanceHistoryConfirmation:
    'Are you sure you want to delete this record?',
  deleteMaintenanceWithExpense: `The linked expense(s)/settlement(s) will also be deleted`,
  closedSettelementforlinkedExpense: `Cannot delete maintenance record(s) as linked expense's settlement(s) status is closed. Please reopen the settlement(s) before deleting the maintenance record.`,
  addNewRecord: 'Add New Record',
  tableView: 'Table View',
  expenseId: 'Expense Id',
};
