import {
  Box,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { getThemeDispatchFilters } from '../../../dispatch2/components/gantt/Filters/styles';
import {
  IPossibleResourceFilters,
  defaultPossibleResourceFilters,
  getPossibleResourceFilters,
} from '../../constants/possibleResources.filters';
import { ResourceType } from '../../types/gantt.types';
import CloseButton from '../generic/CloseButton';
import { VerticalDivider } from '../generic/VerticalDivider';
import { useStores } from '../../../../store/root.context';
import { PossibleResourceTextStyles } from '../../styles/possibleResources.style';
import Filters from '../../../../ui-kit/components/DataGridPro/filter/Filters';
import { GlobalEvalButtonStyles } from '../../styles';
import { LightbulbOutlined } from '@mui/icons-material';

interface IPossibleResourceFilter {
  type?: ResourceType;
  onClose?: () => void;
}

const PossibleResourceFilter: React.FC<IPossibleResourceFilter> = (props) => {
  const { type, onClose } = props;
  const {
    bulkOptymizationStore: {
      possibleResourceFilters,
      setPossibleResourceFilters,
      resourceToReassign,
    },
    evaluationStore: {
      possibleOptionsEvaluation,
      setGlobalEvaluationType,
      setEvaluationDetails,
      clearPossibleOptionsEvaluation,
    },
  } = useStores();
  const currTheme = useTheme();
  const theme = getThemeDispatchFilters({
    currTheme,
    inputWithSearchIcon: false,
  });
  useEffect(() => {
    return () => {
      setPossibleResourceFilters(defaultPossibleResourceFilters);
      clearPossibleOptionsEvaluation();
    };
  }, []);

  const onPageFilterChange = (
    selectedValues: any,
    fieldFilterName: any
  ): void => {
    const finalFilters: IPossibleResourceFilters = {
      ...possibleResourceFilters,
      [fieldFilterName]: selectedValues,
    };
    setPossibleResourceFilters(finalFilters);
  };

  const handleOptionsEvaluation = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const rect = (event?.target as HTMLInputElement)?.getBoundingClientRect?.();
    const y = event.clientY - rect.top;
    setGlobalEvaluationType(true);
    setEvaluationDetails({
      source: 'OPTIMIZATION',
      targetPosition: {
        left: event.clientX,
        top: event.clientY + (rect?.height - y) + 5,
      },
      evaluationResult: possibleOptionsEvaluation!,
    });
  };

  const OptionsEvaluateButton = () => {
    if (!possibleOptionsEvaluation?.issuesKPI?.length) return <></>;
    return (
      <IconButton onClick={handleOptionsEvaluation} sx={GlobalEvalButtonStyles}>
        <LightbulbOutlined sx={{ color: '#FFF' }} />
      </IconButton>
    );
  };

  return (
    <Stack
      direction={'row'}
      gap="8px"
      alignItems={'center'}
      height="50px"
      sx={{ background: 'rgba(0, 17, 34, 0.04)', padding: '0 10px' }}
    >
      <Typography sx={PossibleResourceTextStyles}>
        Options for {resourceToReassign?.label}
      </Typography>

      <VerticalDivider />
      <ThemeProvider theme={theme}>
        <Box mt={'-16px'} display={'flex'} alignItems={'center'}>
          <Filters
            columns={getPossibleResourceFilters()}
            onChangeCb={onPageFilterChange}
            defaultFilterValue={possibleResourceFilters}
          />
        </Box>
      </ThemeProvider>
      <Box
        style={{ marginLeft: 'auto' }}
        display={'flex'}
        gap="8px"
        alignItems={'center'}
      >
        <OptionsEvaluateButton />
        <CloseButton
          onClick={onClose}
          style={{ marginLeft: 0, height: '32px', borderRadius: '10px' }}
        />
      </Box>
    </Stack>
  );
};

export default observer(PossibleResourceFilter);
