/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  customerExcelExportRequest,
  PaginatedCustomerExcelExportRequest,
} from '../../models';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IExportService extends Service {
  abstract getCustomersExportExcel(
    requestData: customerExcelExportRequest
  ): Promise<PaginatedCustomerExcelExportRequest | null | ServiceError>;
}
