import { IMask } from 'react-imask';
import {
  TIME_DURATION_24H_MASK,
  TIME_DURATION_MASK,
  TIME_DURATION_NUMBER_24H_FORMAT,
  TIME_DURATION_NUMBER_FORMAT,
} from './constants';

export const formatNumber = (_number: string | number): string => {
  return _number.toString().length === 1 ? `0${_number}` : _number.toString();
};

export const getTimeDurationSettings = ({
  maxValue,
  is24HFormat = false,
}: {
  maxValue: number;
  is24HFormat?: boolean;
}) => {
  const hoursMax: number = parseInt((maxValue / 60).toString());
  const timeDurationSettings = {
    numberformat: is24HFormat
      ? TIME_DURATION_NUMBER_24H_FORMAT
      : TIME_DURATION_NUMBER_FORMAT,
    mask: is24HFormat ? TIME_DURATION_24H_MASK : TIME_DURATION_MASK,
    lazy: false, // make placeholder always visible
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        from: formatNumber(0),
        to: formatNumber(hoursMax),
      },
      MM: {
        mask: IMask.MaskedRange,
        from: formatNumber(0),
        to: formatNumber(59),
      },
    },
  };
  return timeDurationSettings;
};

export const fromMinutesToTextMask = (
  _minutes: number,
  { is24HFormat = false }: { is24HFormat?: boolean }
): string => {
  const hours = formatNumber(parseInt((_minutes / 60).toString()));
  const minutes = formatNumber(parseInt((_minutes % 60).toString()));
  if (is24HFormat) {
    return `${hours}:${minutes}`;
  }
  return `${hours}h ${minutes}m`;
};

export const fromTextMaskToMinutes = (
  _textMask: string,
  { is24HFormat = false }: { is24HFormat?: boolean }
): number => {
  const tmpArr = _textMask.split(is24HFormat ? ':' : ' ');
  return parseInt(tmpArr[0]) * 60 + parseInt(tmpArr[1]);
};

export const fromTextMaskGetHours = (
  _textMask: string,
  { is24HFormat = false }: { is24HFormat?: boolean }
): number => {
  const tmpArr = _textMask.split(is24HFormat ? ':' : ' ');
  return parseInt(tmpArr[0]);
};
export const fromTextMaskGetMinutes = (
  _textMask: string,
  { is24HFormat = false }: { is24HFormat?: boolean }
): number => {
  const tmpArr = _textMask.split(is24HFormat ? ':' : ' ');
  return parseInt(tmpArr[1]);
};

export const fromTextMaskChangeHours = (
  _textMask: string,
  _hours: number,
  { is24HFormat = false }: { is24HFormat?: boolean }
): string => {
  const currMinutes = formatNumber(
    fromTextMaskGetMinutes(_textMask, { is24HFormat })
  );
  if (is24HFormat) {
    return `${_hours}:${currMinutes}`;
  }
  return `${_hours}h ${currMinutes}m`;
};

export const fromTextMaskChangeMinutes = (
  _textMask: string,
  _minutes: number,
  { is24HFormat = false }: { is24HFormat?: boolean }
): string => {
  const currHours = formatNumber(
    fromTextMaskGetHours(_textMask, { is24HFormat })
  );
  if (is24HFormat) {
    return `${currHours}:${_minutes}`;
  }
  return `${currHours}h ${_minutes}m`;
};
