export interface IExpenseDetailsFilter {
  query: {
    sort?: string;
  };
}

export const expenseDetailsFilterDefault: IExpenseDetailsFilter = {
  query: {
    sort: '-expenseDate',
  },
};

export const expenseDetailsExpensePaymentFilterDefault: IExpenseDetailsFilter =
  {
    query: {
      sort: '-paidDate',
    },
  };
export const carrierDetailsExpensePaymentFilterDefault: IExpenseDetailsFilter =
  {
    query: {
      sort: '-expenseDate',
    },
  };
