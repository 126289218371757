import styled from '@emotion/styled';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SxProps,
} from '@mui/material';
import React, { ChangeEvent } from 'react';
import { FieldGroupsConfig, IGroup } from '../FieldGroupsConfig';

interface IProps {
  sx?: SxProps;
  values?: Record<string, Record<string, boolean>>;
  onFieldChange?: (group: IGroup, value: boolean) => void;
  ignoredFieldsGroupLabel?: Array<string>;
}

// Define the Sidebar component
const Sidebar: React.FC<IProps> = ({
  onFieldChange,
  values,
  ignoredFieldsGroupLabel = [],
  ...props
}) => {
  // Get the fields visibility store instance

  const handleFieldGroupChange = (
    event: ChangeEvent<HTMLInputElement>,
    group: IGroup
  ) => onFieldChange?.(group, event.target?.checked);

  // Render the component
  return (
    <Box {...props}>
      <FormGroup>
        {Object.entries(FieldGroupsConfig).map(
          ([parent, { label, groups }]) => (
            <Box sx={{ mb: 1 }} key={parent}>
              <strong>{label}</strong>
              {/* Render the label for each parent category */}
              {Object.keys(groups).map((groupName) => {
                if (ignoredFieldsGroupLabel?.includes(groupName)) return null;
                return (
                  <div key={groupName}>
                    <WhiteLabel
                      disabled={!groups[groupName].toggleable}
                      control={
                        <WhiteCheckbox
                          defaultChecked
                          checked={
                            !values?.[groups[groupName]?.fieldSection]?.[
                              groups[groupName]?.fieldName
                            ]
                          }
                          sx={{ color: '#fff' }}
                          onChange={(data) =>
                            handleFieldGroupChange(data, groups[groupName])
                          }
                        />
                      }
                      label={groups[groupName].label}
                    />
                  </div>
                );
              })}
            </Box>
          )
        )}
      </FormGroup>
    </Box>
  );
};

// Export the Sidebar component
export default Sidebar;

const WhiteCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: white;
    margin-left: 16px;
  }

  &.Mui-disabled {
    color: white !important;
    opacity: 0.5;
  }
`;

const WhiteLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    color: white;
    .MuiTypography-root {
      color: white;
    }

    &.Mui-disabled {
      color: white !important;
      .MuiTypography-root {
        color: white !important;
        opacity: 0.5;
      }
    }
  }
`;
