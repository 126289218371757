/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ComposedError } from '../../axios/axiosInstance';
import { ServiceError } from './helperTypes';
import { ServiceCallsArgs } from './ServiceCallsArgs';

/* eslint-disable @typescript-eslint/no-empty-interface */
export abstract class Service {
  currentMethod!: string;

  getServiceError(error: Error | ComposedError): ServiceError {
    const requestedCallArgs =
      ServiceCallsArgs.Instance.getCallArgsInfoByMethodName(
        this.constructor.name,
        this.currentMethod
      );

    const serviceError = new ServiceError();
    serviceError.serviceName = this.constructor.name;
    serviceError.method = this.currentMethod;
    serviceError.args = requestedCallArgs;

    if (error instanceof ComposedError) {
      serviceError.composedError = error;
    } else {
      serviceError.error = error;
    }

    return serviceError;
  }
}
