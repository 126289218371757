import TabsV2 from '../../../../../ui-kit/components/TabsV2';
import { entities } from '../../constants';
import { PreferencesTabStripType } from '../../../../../models';

export default function TabStripPreferences({
  entity,
  setEntity,
}: {
  entity: PreferencesTabStripType;
  setEntity: (data: PreferencesTabStripType) => void;
}) {
  const handleTabChange = (entity: PreferencesTabStripType) => {
    setEntity(entity);
    document.getElementById(entity.name)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  return (
    <TabsV2
      keyName="key"
      tabs={entities}
      selectedTab={entity.key}
      setSelectedTab={handleTabChange}
    />
  );
}
