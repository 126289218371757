import {
  AssignDocumentRequest,
  AssignDocumentResponseType,
  DocumentSummaryResponse,
} from '../../../../../models';
import {
  getDocumentTypes,
  uploadDocument,
} from '../../../../../subPages/users/utils';
import { deleteFile } from '../../../../../subPages/expenses/utils/utils';
import { useFormContext } from 'react-hook-form';
import FileUploadUpdated from '../../../../../common/Ui/FileUpload/indexUpdated';
import PostAddIcon from '@mui/icons-material/PostAdd';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { documentService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
export const PaymentDocumentUpload = () => {
  const { watch, control, setValue } = useFormContext();
  const assignedDocuments = watch('assignedDocuments');
  const removePaymentList: () => Promise<void> = async () => {
    setValue('assignedDocuments', []);
  };
  return (
    <FileUploadUpdated
      name={`paymentDocument`}
      label="Upload"
      fileId={
        (assignedDocuments?.length && assignedDocuments[0]?.documentId) || ''
      }
      control={control}
      disabled={false}
      sizes={{ xs: 6 }}
      uploadBtnStyle={{
        width: '100%',
        margin: 0,
        marginTop: '10px',
        height: '40px',
        borderRadius: '14px',
      }}
      onRemoveCB={removePaymentList}
      uploadBtnText={
        <>
          <PostAddIcon sx={{ paddingRight: '2px' }} />
          Add Document
        </>
      }
    />
  );
};

export const saveFilePayment = async (
  paymentData: any,
  file: FileList
): Promise<AssignDocumentResponseType | undefined> => {
  if (paymentData && !!file?.length) {
    const uploadedFile = await uploadDocument(file[0]);

    if (uploadedFile) {
      const document = await assignDocument(uploadedFile, paymentData);

      if (document) return document;
    }
    return;
  }
};

export const updateDocument = async (
  paymentData,
  newAttachedDocument,
  oldDocument
) => {
  if (newAttachedDocument?.length > 0) {
    if (paymentData?.assignedDocuments?.length > 0) {
      await deleteFile([paymentData?.assignedDocuments[0]?.documentId]);
    }
    await saveFilePayment(paymentData, newAttachedDocument);
    return true;
  } else if (
    paymentData?.assignedDocuments?.length > 0 &&
    oldDocument?.length === 0
  ) {
    await deleteFile([paymentData?.assignedDocuments[0]?.documentId]);
    return true;
  }
  return true;
};
export const assignDocument = async (
  file: DocumentSummaryResponse,
  data: any
) => {
  const user = StorageManager.getUser() || {};

  const documentTypesList = await getDocumentTypes();

  if (!documentTypesList) return;
  const documentType = documentTypesList?.find(
    (item) => item.itemCode === 'RECEIPT'
  );

  const assignDocumentRequest = new AssignDocumentRequest({
    description: '',
    ownerName: user.firstname + ' ' + user.lastname,
    permission: 'PRIVATE',
    documentType: 'RECEIPT',
    documentTypeId: documentType?.id || '',
    documentId: file.id,
    fileName: file.fileName,
    fileSize: file.fileSize,
    uploadDate: file.uploadDate,
    ownerId: user.id,
    attachedEntities: [
      {
        properties: [
          {
            id: data?.id.toString() || '',
            title: data?.id.toString() || '',
          },
        ],
        type: 'PAYMENT',
      },
    ],
  });
  const response = documentService.assignDocument(assignDocumentRequest);
  return response instanceof ServiceError ? null : response;
};
