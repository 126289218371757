import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import TextFieldForm from '@/common/Ui/TextField/TextFieldFormDeprecated';
import { Stack } from '@mui/material';
import { FuelCardAccount } from '@/models/DTOs/FuelCards/FuelCards';
import TextField from '@/common/Ui/TextField/TextField';

interface Props {
  activeAccount?: FuelCardAccount;
}

export const EFSCredentialsForm: FC<Props> = ({ activeAccount }) => {
  const { control, watch, reset, setValue } = useFormContext();

  useEffect(() => {
    if (activeAccount) {
      reset({
        displayName: activeAccount.data.DISPLAY_NAME,
        userName: activeAccount.data.USERNAME,
        password: activeAccount.data.PASSWORD,
      });
    } else {
      reset({
        displayName: '',
        userName: '',
        password: '',
      });
    }
  }, [activeAccount]);

  return (
    <Stack spacing={2}>
      <TextField
        control={control}
        name="displayName"
        label="Display Name"
        required
      />
      <TextField control={control} name="userName" label="User Name" required />
      <TextField
        type="password"
        control={control}
        name="password"
        label="password"
        required
      />
    </Stack>
  );
};
