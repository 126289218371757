import { useEffect, useRef, useState } from 'react';

export const useScroll = (scrollableData: unknown[] = [], size = 10) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollNumber = useRef(1);
  const [data, setData] = useState<any>([]);
  const scrollableDataRef = useRef<any>([]);
  const [listSize, setListSize] = useState<number>();
  const dataListSizeRef = useRef(0);

  useEffect(() => {
    setListSize(size);
  }, [size]);

  useEffect(() => {
    if (scrollableData && scrollNumber.current === 1 && listSize) {
      dataListSizeRef.current = 0;
      setData(
        scrollableData.slice(0, Math.min(listSize, scrollableData?.length))
      );
    }
  }, [scrollableData?.length, listSize]);

  useEffect(() => {
    dataListSizeRef.current = data?.length;
  }, [data?.length]);

  useEffect(() => {
    scrollableDataRef.current = scrollableData;
    const element = scrollRef.current;
    if (!element) {
      return;
    }
    const handleScroll = () => {
      if (
        element.scrollTop + element.clientHeight >= element.scrollHeight - 20 &&
        data.length < scrollableData.length
      ) {
        if (dataListSizeRef?.current === scrollableData?.length) return;
        setData((data: unknown[]) => [
          ...data,
          ...scrollableData.slice(
            scrollNumber.current * size,
            scrollNumber.current * size + size
          ),
        ]);
        if (scrollNumber.current * size + size > listSize!) {
          setListSize(scrollNumber.current * size + size);
        }
        scrollNumber.current++;
      }
    };
    element.addEventListener('scroll', handleScroll);
    return () => {
      scrollNumber.current = 1;
      element.removeEventListener('scroll', handleScroll);
    };
  }, [scrollableData.length]);

  return {
    data: data.filter((item: any) => !!item.id),
    scrollRef,
  };
};
