import { useNavigate } from 'react-router-dom';
import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission';
import { getHasPermission } from '../../../../common/Permission/utils/helperUtils';
import { useEquipment } from '../../../../contexts/EquipmentContext';
import {
  IMPORT_TRACTOR_NAME,
  IMPORT_TRAILER_NAME,
} from '../../../../contexts/ImportContext';
import { getGridBottomMenuSettings } from '../../../../utils/gridBottomMenuSettings';
import { IEquipment } from '../components/ViewsSection';
import StorageManager from '../../../../StorageManager/StorageManager';

export const useEquipmentGridActions = () => {
  const { setAddNewClicked, currentView, equipmentCount } = useEquipment();
  const navigate = useNavigate();

  const currentEquipment: IEquipment = {
    tractor: { count: equipmentCount?.tractorCount, name: IMPORT_TRACTOR_NAME },
    trailer: { count: equipmentCount?.trailerCount, name: IMPORT_TRAILER_NAME },
  };

  const userData = StorageManager.getUser() || {};

  const equipmentAddPermissions: {
    [key: string]: AXELE_PERMISSION_TYPE[];
  } = {
    PD: [AXELE_PERMISSION_TYPE.EQUIPMENT_ADD],
    AC: [AXELE_PERMISSION_TYPE.EQUIPMENT_ADD],
    NFD: [AXELE_PERMISSION_TYPE.EQUIPMENT_ADD],
    DI: [AXELE_PERMISSION_TYPE.EQUIPMENT_ADD],
    DR: [AXELE_PERMISSION_TYPE.EQUIPMENT_ADD],
    SP: [AXELE_PERMISSION_TYPE.EQUIPMENT_ADD],
  };

  const canUploadPermission: boolean =
    !!currentView?.entity &&
    !!currentEquipment[currentView.entity as keyof IEquipment]['count'];

  const canAddPermission: boolean = getHasPermission({
    includes: equipmentAddPermissions[userData.roleCode],
  });

  const onAddHandler = () => {
    setAddNewClicked(true);
  };

  const onUploadHandler = () => {
    navigate('/settings/import', {
      state: {
        subPage:
          currentEquipment[currentView.entity as keyof IEquipment]['name'],
      },
    });
  };

  const gridBottomMenu = getGridBottomMenuSettings({
    onAddHandler: onAddHandler,
    onUploadHandler: onUploadHandler,
    canUploadPermission: canUploadPermission && canAddPermission,
    canAddPermission: canAddPermission,
    currentView: currentView,
  });

  return {
    canUploadPermission,
    canAddPermission,
    onAddHandler: onAddHandler,
    onUploadHandler: onUploadHandler,
    gridBottomMenu: gridBottomMenu,
  };
};
