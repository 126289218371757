import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FeatureItem from './FeatureItem';
import { typographyStyles } from '../../constants';
import { featureInfos } from './constants';

export default function Features() {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant={'h3'}
          sx={{ ...typographyStyles, fontSize: 24, mb: 2 }}
        >
          Features
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'wrap' }}>
        {featureInfos.map((featureInfo) => (
          <FeatureItem
            key={featureInfo.key}
            text={featureInfo.text}
            subText={featureInfo.subText}
            icon={featureInfo.icon}
          />
        ))}
      </Box>
    </>
  );
}
