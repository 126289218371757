import {
  Box,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Autocomplete } from '@components/ui-kit/autocomplete';
import { IconButton } from '@components/ui-kit/icon-button';
import { TextField } from '@components/ui-kit/text-field';
import { Typography } from '@components/ui-kit/typography';
import {
  AddOutlined,
  ExpandLessRounded,
  ExpandMoreRounded,
  RemoveCircleOutline,
} from '@mui/icons-material';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '../../../../../components_v2/ui-kit/select';
import { LineItem } from '../../../../../types';
import { unitValueLabelMap } from '../../../../settings/ManageTypes/constants';
import { FINANCE_TYPES } from '../../constants/fieldOptions';
import { createLoadService } from '../../services/createLoad.service';
import { rateFormService } from '../../services/rate.service';
import { AdditionalSummaryContainerStyles } from '../../style';
import { toFixDigit } from '../../utils';

type FeeItemProps = {
  addNew?: boolean;
  add: () => void;
  remove: () => void;
  index: number;
  feeItemOptions: Array<LineItem>;
  canEdit?: boolean;
};

const FeeItem: React.FC<FeeItemProps> = ({
  addNew = false,
  add,
  remove,
  index,
  feeItemOptions,
  canEdit = false,
}) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const toggleIsDetailsExpanded = () => {
    setIsDetailsExpanded((state) => (state = !state));
  };
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const quantity = watch(`rate.additionalFees.${index}.quantity`);
  const rate = watch(`rate.additionalFees.${index}.rate`);
  const additionalFee = watch(`rate.additionalFees.${index}`);
  const invoiceRate = watch(`rate.rate`);
  const invoiceQuantity = watch(`rate.quantity`);
  const totalMiles = watch(`totalMiles`);
  const isContract = watch(`rate.additionalFees.${index}.isContract`);
  const isAdditionalFeeContract = watch(
    `rate.additionalFees.${index}.feeDetail.isContract`
  );
  const baseRate = watch(`rate`);

  // Calculate total
  useEffect(() => {
    const qty = quantity || 0;
    const itemRate = rate || 0;
    const total = rateFormService.calculateLineItemRateByUnit({
      quantity: qty,
      rate: itemRate,
      unit: additionalFee?.unit,
    });
    setValue(
      `rate.additionalFees.${index}.total`,
      !!additionalFee?.feeDetail?.deduction
        ? -total.toFixed(2)
        : total.toFixed(2)
    );
  }, [quantity, rate, additionalFee?.unit, setValue, index]);

  const getFeeOptionsLabel = useCallback(
    (value: object) =>
      createLoadService.getAutocompleteOptionLabel({
        list: feeItemOptions,
        value,
        fieldName: 'itemName',
        returnDefaultValue: false,
      }),
    [feeItemOptions]
  );

  const handleLineItemUnitChange = (unit: string) => {
    if (unit === FINANCE_TYPES.PERCENT) {
      const qty = Number(Number(invoiceRate) * Number(invoiceQuantity || 0));
      setValue(
        `rate.additionalFees.${index}.quantity`,
        isNaN(qty) ? 0 : qty.toFixed(2)
      );
    } else if (unit === FINANCE_TYPES.PER_LOADED_MILE) {
      setValue(
        `rate.additionalFees.${index}.quantity`,
        Number(totalMiles)?.toFixed?.(2)
      );
    } else {
      setValue(`rate.additionalFees.${index}.quantity`, 1);
    }
    // if (additionalFee?.feeDetail?.rate) {
    setValue(
      `rate.additionalFees.${index}.rate`,
      additionalFee?.feeDetail?.rate ?? ''
    );
    // }
  };

  const renderFeeOption = useCallback((props, option) => {
    if (option?.isContract) {
      return (
        <Stack
          {...props}
          padding={1}
          sx={{ alignItems: 'flex-start!important' }}
        >
          <Typography variant="body1">{option.feeDetail?.itemName}</Typography>
          <Typography
            variant="caption"
            color={'textSecondary'}
            position={'relative'}
            bottom={3}
          >
            Contract: ${option?.rate?.toFixed?.(2)}/
            {unitValueLabelMap[option.feeDetail?.unit]}
          </Typography>
        </Stack>
      );
    }
    return (
      <Typography variant="body1" {...props}>
        {option?.itemName}
      </Typography>
    );
  }, []);

  const isQuantityFieldDisabled = useMemo(() => {
    if (additionalFee?.unit === FINANCE_TYPES.PERCENT) return true;
    return false;
  }, [additionalFee?.unit, additionalFee?.feeDetail?.unit]);

  const clearCurrentAdditionalItemValues = () => {
    setValue(`rate.additionalFees.${index}`, {
      fee: null,
      quantity: '',
      rate: '',
      feeDetail: null,
      unit: '',
      units: [],
      description: '',
    });
  };

  const handleFeeChange = (value: any) => {
    if (value && index === baseRate.additionalFees?.length - 1) add();
    if (!value) clearCurrentAdditionalItemValues();
    setValue(`rate.additionalFees.${index}.feeDetail`, value ?? null);
    setValue(`rate.additionalFees.${index}.description`, value?.description);
    const unit = (value as any)?.unit;
    setValue(
      `rate.additionalFees.${index}.units`,
      typeof unit === 'string' ? (value as any)?.units : unit
    );
    setValue(`rate.additionalFees.${index}.isContract`, !!value?.isContract);
    if (value?.isContract) {
      setValue(`rate.additionalFees.${index}.rate`, value?.rate);
      setValue(`rate.additionalFees.${index}.unit`, value?.unit ?? '');
      handleLineItemUnitChange(value?.unit);
    } else {
      let unit = null;
      if (value?.unit?.length === 1) unit = value?.unit?.[0];
      else {
        if (value?.unit?.[0] !== FINANCE_TYPES.PERCENT) unit = value?.unit?.[0];
        else unit = value?.unit?.[1];
      }
      setValue(`rate.additionalFees.${index}.quantity`, null);
      setValue(`rate.additionalFees.${index}.rate`, '');
      setValue(`rate.additionalFees.${index}.unit`, unit);
      handleLineItemUnitChange(unit);
    }
  };

  return (
    <>
      <Stack direction="row" gap={1} alignItems="flex-start">
        {!canEdit &&
          (addNew ? (
            <IconButton disabled>
              <AddOutlined />
            </IconButton>
          ) : (
            <IconButton onClick={remove}>
              <RemoveCircleOutline />
            </IconButton>
          ))}
        <Controller
          name={`rate.additionalFees.${index}.fee`}
          render={({ field }) => (
            <Autocomplete
              {...field}
              onChange={(_, value) => {
                handleFeeChange(value);
                field.onChange((value as any)?.id ?? null);
              }}
              options={feeItemOptions}
              getOptionLabel={getFeeOptionsLabel}
              renderOption={(props, option) => renderFeeOption(props, option)}
              sx={{ width: '200px' }}
              renderInput={(params) => {
                return (
                  <Tooltip title={params?.inputProps?.value}>
                    <div>
                      <TextField
                        {...params}
                        label="Fee"
                        error={errors?.rate?.additionalFees?.[index]?.fee}
                        helperText={
                          errors?.rate?.additionalFees?.[index]?.fee?.message
                        }
                      />
                    </div>
                  </Tooltip>
                );
              }}
              disabled={canEdit}
            />
          )}
        />
        <Controller
          name={`rate.additionalFees.${index}.unit`}
          render={({ field }) => (
            <Select
              id="unit"
              label="Unit"
              {...field}
              disabled={
                !additionalFee?.fee ||
                isContract ||
                isAdditionalFeeContract ||
                canEdit
              }
              sx={{ width: '100px' }}
              onChange={(event: SelectChangeEvent<unknown>) => {
                handleLineItemUnitChange(event.target?.value as string);
                field.onChange(event.target?.value);
              }}
              renderValue={(value: string) => {
                return (
                  <Tooltip
                    title={
                      additionalFee?.unit
                        ? unitValueLabelMap[additionalFee?.unit]
                        : undefined
                    }
                  >
                    <div>
                      <Typography
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {unitValueLabelMap[value]}
                      </Typography>
                    </div>
                  </Tooltip>
                );
              }}
            >
              {additionalFee?.units?.map?.((e: string) => (
                <MenuItem key={e} value={e}>
                  {unitValueLabelMap[e]}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <Controller
          name={`rate.additionalFees.${index}.quantity`}
          render={({ field }) => (
            <TextField
              {...field}
              label={
                <>
                  Quantity
                  <span
                    style={
                      !additionalFee?.fee || isQuantityFieldDisabled || canEdit
                        ? {}
                        : { color: 'red' }
                    }
                  >
                    *
                  </span>
                </>
              }
              sx={{ width: '125px' }}
              value={additionalFee?.quantity ?? ''}
              error={errors?.rate?.additionalFees?.[index]?.quantity}
              helperText={
                errors?.rate?.additionalFees?.[index]?.quantity?.message
              }
              disabled={
                !additionalFee?.fee || isQuantityFieldDisabled || canEdit
              }
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <Controller
          name={`rate.additionalFees.${index}.rate`}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              label={
                <>
                  Rate
                  <span
                    style={
                      !additionalFee?.fee ||
                      isContract ||
                      isAdditionalFeeContract ||
                      canEdit
                        ? {}
                        : { color: 'red' }
                    }
                  >
                    *
                  </span>
                </>
              }
              sx={{ width: '120px' }}
              error={errors?.rate?.additionalFees?.[index]?.rate}
              helperText={errors?.rate?.additionalFees?.[index]?.rate?.message}
              disabled={
                !additionalFee?.fee ||
                isContract ||
                isAdditionalFeeContract ||
                canEdit
              }
              InputProps={{
                endAdornment:
                  additionalFee?.unit === FINANCE_TYPES.PERCENT ? '%' : '',
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        {!!(additionalFee?.quantity && additionalFee.rate) && (
          <>
            <Controller
              name={`rate.additionalFees.${index}.total`}
              render={({ field }) => (
                <Typography
                  variant="body1"
                  style={{
                    marginLeft: 'auto',
                    marginTop: '6px',
                    wordWrap: 'break-word',
                    maxWidth: '70px',
                  }}
                >
                  {additionalFee?.feeDetail?.deduction && '-'}$
                  {toFixDigit(Math.abs(field.value))}
                </Typography>
              )}
            />
            <IconButton
              size="small"
              sx={{ flexShrink: 0 }}
              onClick={toggleIsDetailsExpanded}
            >
              {isDetailsExpanded ? (
                <ExpandLessRounded />
              ) : (
                <ExpandMoreRounded />
              )}
            </IconButton>
          </>
        )}
      </Stack>
      {!isDetailsExpanded && additionalFee?.description && (
        <Stack
          direction={'row'}
          sx={AdditionalSummaryContainerStyles}
          width={'fit-content'}
          py="10px"
          px="15px"
          ml="47px"
        >
          <Typography variant="body3">{additionalFee?.description}</Typography>
        </Stack>
      )}
      {isDetailsExpanded && (
        <Box
          sx={{
            backgroundColor: ' #f3f3f3',
            borderRadius: '10px',
            py: '10px',
            px: '15px',
            ml: '47px',
          }}
        >
          <Controller
            name={`rate.additionalFees.${index}.description`}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                label="Description"
                fullWidth
                disabled={canEdit}
              />
            )}
          />
        </Box>
      )}
    </>
  );
};

export default FeeItem;
