import {
  CarrierDTO,
  CarrierInsuranceDTO,
  CarrierMessageResponse,
  CarrierStaticDataDTO,
  CreateCarrierContactRequest,
  CreateCarrierContactResponse,
  CreateCarrierInsuranceRequest,
  CreateCarrierRequest,
  ELookupExternalCarrierType,
  ExportCarrierPayload,
  ExportCarrierQueryParams,
  GetCarrierCountTotalResponse,
  GetCarrierOneRequest,
  GetPaginatedCarrierListQueryParams,
  GetPaginatedCarrierListRequest,
  GetPaginatedCarrierListResponse,
  GetSearchContactNameRequest,
  GetSearchContactNameResponse,
  GetSearchDotRequest,
  GetSearchDotResponse,
  GetSearchMcRequest,
  GetSearchMcResponse,
  GetSearchNameRequest,
  GetSearchNameResponse,
  GetSearchScacRequest,
  GetSearchScacResponse,
  LookupExternalCarrierItem,
  UpdateCarrierStatusRequest,
} from '../../models/DTOs/Carrier/Requests';
import { docType } from '../../utils/Doc';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class ICarrierService extends Service {
  abstract createCarrier(
    requestData: CreateCarrierRequest
  ): Promise<CarrierDTO | null | ServiceError>;

  abstract updateCarrier(
    requestData: CreateCarrierRequest
  ): Promise<CarrierDTO | null | ServiceError>;

  abstract getPaginatedCarrierList(
    queryParams: GetPaginatedCarrierListQueryParams,
    requestData: GetPaginatedCarrierListRequest
  ): Promise<GetPaginatedCarrierListResponse | null | ServiceError>;

  abstract exportToExcel(
    queryParams: ExportCarrierQueryParams,
    payload: ExportCarrierPayload
  ): Promise<docType | ServiceError>;

  abstract getStaticList(): Promise<CarrierStaticDataDTO | null | ServiceError>;

  abstract searchName(
    queryParams: GetSearchNameRequest
  ): Promise<GetSearchNameResponse | null | ServiceError>;

  abstract searchContactName(
    queryParams: GetSearchContactNameRequest
  ): Promise<GetSearchContactNameResponse | null | ServiceError>;

  abstract searchMc(
    queryParams: GetSearchMcRequest
  ): Promise<GetSearchMcResponse | null | ServiceError>;

  abstract searchDot(
    queryParams: GetSearchDotRequest
  ): Promise<GetSearchDotResponse | null | ServiceError>;

  abstract searchScac(
    queryParams: GetSearchScacRequest
  ): Promise<GetSearchScacResponse | null | ServiceError>;

  abstract getCountTotal(): Promise<
    GetCarrierCountTotalResponse | null | ServiceError
  >;

  abstract lookupExternalCarrier(
    searchText: string,
    type: ELookupExternalCarrierType
  ): Promise<LookupExternalCarrierItem[] | null | ServiceError>;

  abstract createCarrierContact(
    requestData: CreateCarrierContactRequest
  ): Promise<CreateCarrierContactResponse | null | ServiceError>;

  abstract createCarrierInsurance(
    requestData: CreateCarrierInsuranceRequest
  ): Promise<CarrierInsuranceDTO | null | ServiceError>;

  abstract deleteCarrierInsurance(
    queryParams: GetCarrierOneRequest
  ): Promise<CarrierMessageResponse | null | ServiceError>;

  abstract getCarrierOne(
    queryParams: GetCarrierOneRequest
  ): Promise<CarrierDTO | null | ServiceError>;

  abstract deleteCarrier(
    queryParams: GetCarrierOneRequest
  ): Promise<CarrierMessageResponse | null | ServiceError>;

  abstract updateCarrierStatus(
    payload: UpdateCarrierStatusRequest
  ): Promise<CarrierDTO | null | ServiceError>;

  abstract getCarrierCountTotal(): Promise<number | null | ServiceError>;
}
