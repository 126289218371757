import BaseEvent from '../BaseEvent';
import { EventTypes } from '../EventTypes';
import { ESecondaryDetailsPanelType } from '../../views/dispatch/constants/types';

export interface EventUpdateProgressData {
  toTypes: ESecondaryDetailsPanelType[];
}

export default class EventUpdateProgress extends BaseEvent {
  data: EventUpdateProgressData;
  constructor(data: EventUpdateProgressData) {
    super(EventTypes.UPDATE_PROGRESS, data);
    this.data = data;
  }
}
