import { Box, Button, Grid, IconProps, Typography } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Accordion from '../../../common/Accordion';
import { paymentLineItemAmoutConfigText } from '../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewConstant';
import { Permission } from '../../../common/Permission';
import { getHasPermission } from '../../../common/Permission/utils/helperUtils';
import FormDialog from '../../../common/Ui/FormDialog';
import Select from '../../../common/Ui/Select';
import { SingleAutocompleteForm } from '../../../common/Ui/SingleAutocomplete';
import TextField from '../../../common/Ui/TextField/TextField';
import { useLoadPermission } from '../../../common/hooks/useLoadPermission';
import {
  CreateDriverPaymentTerm,
  CreateTractorPaymentTerm,
  ExpenseCategory,
  OwnerOperatorPaymentRecipient,
  PaymentPerLoad,
} from '../../../models';
import {
  ScheduleTractorAPITextConfig,
  UserDetails,
} from '../../../models/DTOs/PaymentTerms/TractorPaymentTermConstant';
import { AddIcon, DeleteButtonIcon } from '../../../ui-kit/components/Assets';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
import ThreeDotMenuIcon from '../../../ui-kit/components/ThreeDotMenuIcon';
import { amountFormat } from '../../../utils';
import { entityTypes } from '../../../utils/entityTypesData';
import { getOwnerOperatorList } from '../../expenses/utils/utils';
import { ITractorPaymentDataProps } from '../../tractors/components/WrapperTractorPayment';
import { IPaymentTermDataDependency } from '../../users/components/PaymentTermForm/PaymentTermTabs';
import ScheduledPaymentItem from '../../users/components/ScheduledPayment/ScheduledPayment';
import { addNewPaymentPerLoadTractorFormValidationSchema } from '../../webUsers/constants';
import AddNewTractorPaymentTermForm from './AddNewTractorPaymentTermForm';
import FormContext, {
  TractorPaymentTermFormContext,
} from './TractorPaymentTermFormContext';

const defaultTractorPaymentTerm = new CreateTractorPaymentTerm();

const getExpenseCategoryCodeById = (
  expenseCategoties: ExpenseCategory[],
  id: string
): string => {
  const foundExpenseCategory = expenseCategoties.find((exp) => exp.id === id);

  if (foundExpenseCategory) {
    return foundExpenseCategory.itemCode || '';
  }

  return '';
};

type PaymentTermDeleteActionType = {
  id?: number;
  label: string;
  icon: ({ color }: IconProps) => JSX.Element;
};

export const ItemsOfSectionSummary = ({
  addClickHandler,
  numberOfSectionItems,
  sectionName,
  permission,
  disableAllFields,
}: {
  addClickHandler: any;
  numberOfSectionItems: any;
  sectionName: any;
  permission: any;
  disableAllFields?: boolean;
}) => {
  const { allPaymentTypes, savedLoadPaymentTerms } =
    React.useContext(FormContext);
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      spacing={0}
      sx={{ minWidth: '410px' }}
    >
      <Grid
        className="payment-terms"
        item
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          {sectionName}
        </Typography>
        <Permission includes={[permission?.add]}>
          <>
            {savedLoadPaymentTerms.length < allPaymentTypes.length &&
              !disableAllFields && (
                <Button
                  onClick={addClickHandler}
                  variant="outlined"
                  style={{ minWidth: 35, marginLeft: 10 }}
                >
                  <AddIcon />
                </Button>
              )}
          </>
        </Permission>
      </Grid>
      <Grid item>
        <Grid container direction={'column'} alignItems={'flex-end'}>
          <Grid item>
            <Typography variant="h6" sx={{ color: 'primary.main' }}>
              {numberOfSectionItems}
            </Typography>
          </Grid>

          <Grid item>
            {/* @ISSUE: Vasil */}
            <Typography variant="tooltip" sx={{ color: 'text.secondary' }}>
              {t('loadsTrackingTotalNum')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PaymentPerLoadItem = ({
  data,
  expanded = false,
  foundIndex,
  permission,
  disableAllFields,
}: {
  data: PaymentPerLoad;
  expanded?: boolean;
  foundIndex: number;
  permission?: any;
  disableAllFields?: boolean;
}) => {
  const [cardExpanded, setCardExpanded] = useState(true);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { expenseCategories, deletePaymentForLoad, allPaymentTypes } =
    React.useContext(FormContext);
  const { id } = data;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteItem, setDeleteItem] = useState<boolean>(false);
  const [selecteDeleteID, setelecteDeleteID] = useState<number>();
  const [selecteDeleteText, setelecteDeleteText] = useState<string>();
  const paymentTermsData = watch('paymentTerms');
  const fieldPath = `paymentTerms.loadRateDTO.${foundIndex}`;
  const selectedExpenseCategoryId = watch(`${fieldPath}.expenseCategoryId`);
  let paymentTypeDisplayName = data.payType;
  const foundPaymentType = allPaymentTypes.find(
    (paymentType) => paymentType.id === data.payTypeId
  );

  if (foundPaymentType) {
    paymentTypeDisplayName = foundPaymentType.itemName;
  }

  const foundLoadRateDTO = paymentTermsData?.loadRateDTO?.find(
    (item) => item.payTypeId === data.payTypeId
  );

  const isDeduction =
    data.payTypeId === foundPaymentType?.id && foundPaymentType?.deduction;

  if (isDeduction) {
    data.rate = Math.abs(data.rate!);
  }

  useEffect(() => {
    if (!selectedExpenseCategoryId) {
      return;
    }

    const name = getExpenseCategoryCodeById(
      expenseCategories,
      selectedExpenseCategoryId
    );

    setValue(`${fieldPath}.expenseCategoryKey`, name);
  }, [selectedExpenseCategoryId, fieldPath, expenseCategories, setValue]);
  const paymentTermDeleteAction: PaymentTermDeleteActionType = {
    label: 'Delete',
    icon: DeleteButtonIcon,
  };

  const handleDeletePaymentTerm = (
    id: number | undefined,
    text: string | undefined
  ) => {
    setDeleteItem(true);
    setelecteDeleteID(id);
    setelecteDeleteText(`${text}`);
  };

  const handleMenuItemClick = (
    action: {
      icon: string;
      label: string;
      id: number;
      paymentTypeDisplayName: string;
      operationModeDisplayName: string;
    },
    accountData: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (action?.label !== 'Delete') return;
    handleDeletePaymentTerm(id, paymentTypeDisplayName);
    setAnchorEl(null);
  };

  const titleRenderer = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: (theme) => {
            return cardExpanded
              ? `${theme.palette.primary.contrast}`
              : `${theme.palette.grey['100']}`;
          },
        }}
      >
        <div>
          <Typography
            variant="h7"
            sx={{
              color: cardExpanded ? 'primary.main' : 'text.primary',
            }}
          >
            {`${paymentTypeDisplayName}`}
          </Typography>
          {!cardExpanded && (
            <div>
              <Typography variant="body3" sx={{ color: 'text.secondary' }}>
                {`${
                  data.unit === 'PERCENT'
                    ? `${data.rate} %`
                    : `${amountFormat(
                        isDeduction
                          ? -+foundLoadRateDTO.rate
                          : foundLoadRateDTO.rate
                      )}`
                } `}
              </Typography>
            </div>
          )}
        </div>
        <Box
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '-16px',
          }}
        >
          {!disableAllFields && (
            <Permission includes={[permission?.remove]}>
              <ThreeDotMenuIcon
                menuOptions={[
                  {
                    id,
                    paymentTypeDisplayName,
                    ...paymentTermDeleteAction,
                  },
                ]}
                open={Boolean(anchorEl)}
                handleMenuClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();
                  setAnchorEl(event.currentTarget);
                }}
                handleMenuClose={() => {
                  setAnchorEl(null);
                }}
                anchorEl={anchorEl}
                handleMenuItemClick={handleMenuItemClick}
              />
            </Permission>
          )}
        </Box>
      </Box>
    );
  };
  const deleteitem = (id: number) => {
    if (id) {
      setDeleteItem(false);
      deletePaymentForLoad(id);
    }
  };
  const amoutLabeltext = paymentLineItemAmoutConfigText[data.unit];
  const isEditPermission = !getHasPermission({
    includes: [permission?.edit],
  });
  const disabledField = isEditPermission || disableAllFields;
  return (
    <Box
      sx={{
        border: (theme) => {
          return cardExpanded ? `1px solid ${theme.palette.primary.main}` : '';
        },
        borderRadius: '8px',
        padding: '5px 5px',
        marginTop: '20px',
        backgroundColor: (theme) => {
          return cardExpanded
            ? `${theme.palette.primary.contrast}`
            : `${theme.palette.grey['100']}`;
        },
        width: '100%',
      }}
    >
      <Accordion
        key={`accordion: ${id}`}
        name={`${paymentTypeDisplayName} (${data.operationMode})`}
        summaryRenderer={titleRenderer}
        updateParentState={setCardExpanded}
      >
        <Select
          name={`${fieldPath}.expenseCategoryId`}
          label={t('expenseCategory')}
          fieldName={'itemName'}
          sizes={{ xs: expanded ? 3 : 6 }}
          options={expenseCategories}
          control={control}
          fieldValue={'id'}
          error={errors.expenseCategory}
          disabled={disabledField}
          required
        />

        <TextField
          control={control}
          name={`${fieldPath}.rate`}
          label={
            amoutLabeltext && amoutLabeltext != ''
              ? amoutLabeltext
              : ' Rate (%)'
          }
          required
          sizes={{ xs: 6 }}
          disabled={disabledField}
        />

        <TextField
          control={control}
          name={`${fieldPath}.description`}
          label="Description"
          sizes={{ xs: expanded ? 12 : 12 }}
          disabled={disabledField}
        />
      </Accordion>

      {isDeleteItem && (
        <DeletePopup
          open={isDeleteItem}
          onClose={() => setDeleteItem(false)}
          onAction={() => deleteitem(selecteDeleteID)}
          title={'Delete Payment Terms'}
          subtitle={`Are you sure you want to delete  ${selecteDeleteText} ?`}
        />
      )}
    </Box>
  );
};

const PaymentPreference = ({
  data,
  terminalId,
  userType,
}: {
  data: OwnerOperatorPaymentRecipient;
  terminalId?: string;
  userType?: string;
}) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue(
      'payToDetails',
      data?.payToEntityId
        ? {
            name: data?.payToEntityName ?? '',
            id: data?.payToEntityId,
          }
        : null
    );
  }, [data?.payToEntityId, setValue]);

  let paymentRecipientNoteLabel = 'paymentRecipientNoteForUser';
  switch (userType) {
    case entityTypes.DRIVER:
      paymentRecipientNoteLabel = 'paymentRecipientNoteForDriver';
      break;
    case entityTypes.TRACTOR:
      paymentRecipientNoteLabel = 'paymentRecipientNoteForTractor';
      break;
  }

  const { hasPaymentTractorPaymentPreferenceEdit } = useLoadPermission();

  return (
    <Box
      sx={{
        borderRadius: '8px',
        padding: '5px 10px',
        marginTop: '20px',
        width: '100%',
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
      }}
    >
      <Typography color="text.secondary" variant="caption">
        {t(paymentRecipientNoteLabel)}
      </Typography>
      {userType === entityTypes.TRACTOR && (
        <Box width={'100%'}>
          <SingleAutocompleteForm
            disabled={!hasPaymentTractorPaymentPreferenceEdit}
            control={control}
            name="payToDetails"
            defaultValue={{
              name: data?.payToEntityName ?? '',
              id: data?.payToEntityId,
            }}
            label={t('paymentRecipient')}
            fieldName="name"
            getOptions={(value: string, pageNumber: number) =>
              getOwnerOperatorList(value, pageNumber, [terminalId!])
            }
          />
          <Typography color={'text.secondary'} variant="caption">
            {t('paymentRecipientHelperText')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const sectionSummaryRenderer = (
  numberOfItemsInSection?: number,
  sectionName?: string,
  addNewItemHandler?: any,
  permission?: any,
  disableAllFields?: boolean
) => {
  return (
    <ItemsOfSectionSummary
      addClickHandler={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        addNewItemHandler();
      }}
      numberOfSectionItems={numberOfItemsInSection}
      sectionName={sectionName}
      permission={permission}
      disableAllFields={disableAllFields}
    />
  );
};

const AccordionSection = (
  sectionName: string,
  savedPaymentTerms: PaymentPerLoad[],
  payData: any,
  permission?: any,
  disableAllFields?: boolean
) => {
  const [showAddNewItemDialog, setShowAddNewItemDialog] = useState(false);
  const numberOfItemsInSection = savedPaymentTerms.length;
  const addNewItemHandlerCallback = useCallback(() => {
    setShowAddNewItemDialog(true);
  }, []);
  let components: JSX.Element[] = [];
  const { createNewPaymentTermForLoad, allPaymentTypes } =
    React.useContext(FormContext);
  if (numberOfItemsInSection) {
    components = savedPaymentTerms.map((paymentPerLoad, index) => {
      return (
        <PaymentPerLoadItem
          key={paymentPerLoad.id}
          data={paymentPerLoad}
          foundIndex={index}
          permission={permission}
          disableAllFields={disableAllFields}
        />
      );
    });
  }
  const saveNewPaymentForLoad = useCallback(
    (paymentTerm: CreateDriverPaymentTerm) => {
      const foundPaymentType = allPaymentTypes?.find(
        (item) => item.id === paymentTerm.payTypeId
      );
      if (foundPaymentType?.deduction && paymentTerm.rate) {
        paymentTerm.rate = -+paymentTerm.rate;
      }
      delete paymentTerm.parent;
      createNewPaymentTermForLoad(paymentTerm);
      setShowAddNewItemDialog(false);
    },
    [createNewPaymentTermForLoad]
  );
  return (
    <>
      <Accordion
        summaryRenderer={() =>
          sectionSummaryRenderer(
            numberOfItemsInSection,
            sectionName,
            addNewItemHandlerCallback,
            permission,
            disableAllFields
          )
        }
        expanded={!false}
      >
        {numberOfItemsInSection ? components.map((item) => item) : null}
      </Accordion>
      {showAddNewItemDialog && (
        <FormDialog
          data={defaultTractorPaymentTerm}
          titleText="Add Payment Per Load"
          actionLabel="Add"
          open={showAddNewItemDialog}
          onAction={saveNewPaymentForLoad}
          handleClose={() => {
            setShowAddNewItemDialog(false);
          }}
          validationSchema={addNewPaymentPerLoadTractorFormValidationSchema}
          contentRenderer={() => <AddNewTractorPaymentTermForm />}
        ></FormDialog>
      )}
    </>
  );
};

function TractorPaymentTermFormAccount({
  data,
  userType,
  permission,
  disableAllFields,
}: {
  data?: UserDetails | ITractorPaymentDataProps;
  expanded?: boolean;
  userType?: string;
  permission: any;
  disableAllFields?: boolean;
}) {
  const {
    payToDetails,
    savedLoadPaymentTerms,
    scheduledPayment,
    scheduledDeduction,
  } = React.useContext(FormContext);

  return (
    <>
      <PaymentPreference
        userType={userType}
        data={payToDetails}
        terminalId={data?.terminalId}
      />
      {AccordionSection(
        'Payment Per Trip',
        savedLoadPaymentTerms,
        data,
        permission,
        disableAllFields
      )}
      {userType === 'TRACTOR' &&
        ScheduledPaymentItem(
          'Tractor',
          scheduledPayment,
          'Payment',
          data.id,
          ScheduleTractorAPITextConfig.Payment,
          permission,
          disableAllFields
        )}
      {userType === 'TRACTOR' &&
        ScheduledPaymentItem(
          'Tractor',
          scheduledDeduction,
          'Deduction',
          data.id,
          ScheduleTractorAPITextConfig.Deduction,
          permission,
          disableAllFields
        )}
    </>
  );
}

const TractorPaymentTermForm = ({
  data,
  setUserDetails,
  userType,
  dataDependency,
  permission,
  disableAllFields,
}: {
  expanded?: boolean;
  data?: UserDetails | ITractorPaymentDataProps;
  userType: string;
  setUserDetails?: (value: React.SetStateAction<UserDetails>) => void;
  dataDependency: IPaymentTermDataDependency;
  permission?: any | undefined;
  disableAllFields?: boolean;
}) => {
  return (
    <TractorPaymentTermFormContext
      setUserDetails={setUserDetails}
      data={data}
      userType={userType}
      dataDependency={dataDependency}
    >
      <Permission includes={[permission?.view]}>
        <>
          <TractorPaymentTermFormAccount
            disableAllFields={disableAllFields}
            expanded={true}
            data={data}
            userType={userType}
            permission={permission && permission}
          />
        </>
      </Permission>
    </TractorPaymentTermFormContext>
  );
};

export default observer(TractorPaymentTermForm);
