import { Box, List, Typography, useTheme } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { reportColumnsDisplayName } from '../constant';
import { fieldListContainerStyle } from '../style';
import { FieldsCard } from './fieldCard';

export const FieldsSection = ({
  visibleFields,
  hiddenFields,
  handleVisibilityChange,
  isEditMode,
  changeFieldSequence,
  width,
}: {
  visibleFields: Array<any>;
  hiddenFields: Array<any>;
  handleVisibilityChange: (
    data: string,
    index: number,
    isVisible: boolean
  ) => void;
  isEditMode: boolean;
  changeFieldSequence: (
    dragIndex: number,
    hoverIndex: number,
    isVisible: boolean
  ) => void;
  width: string;
}) => {
  const theme = useTheme();

  const fieldsInEditMode = (
    data: Array<string | any>,
    isVisible: boolean,
    hideReorderingIcon = false,
    isHideVisibilityIcon = false
  ) => (
    <DndProvider backend={HTML5Backend}>
      <List dense sx={{ ...fieldListContainerStyle }}>
        {data?.map((item: string, index: number) => {
          const key = 'item' + index;
          return (
            // item ?
            <FieldsCard
              key={index}
              index={index}
              id={index}
              text={item}
              moveCard={changeFieldSequence}
              isVisible={isVisible}
              isHideVisibilityIcon={isHideVisibilityIcon}
              hideReorderingIcon={hideReorderingIcon}
              handleVisibilityChange={handleVisibilityChange}
            />
          );
        })}
      </List>
    </DndProvider>
  );
  return (
    <Box marginLeft={'10px'} width={width}>
      <Typography
        variant={'h6'}
        sx={(theme) => ({ color: theme.palette.text.primary })}
      >
        Visible Fields
      </Typography>
      {isEditMode && (
        <Typography
          variant={'subtitle2'}
          sx={(theme) => ({ color: theme.palette.text.disabled })}
        >
          Select the fields you want to see in the final report
        </Typography>
      )}
      <Box
        sx={{
          height: isEditMode ? '87%' : '90%',
          overflowY: 'auto',
        }}
      >
        {isEditMode ? (
          <>
            {fieldsInEditMode(visibleFields, true)}
            {fieldsInEditMode(hiddenFields, false)}
          </>
        ) : (
          <Box padding={'0px 16px 0px 8px'}>
            {visibleFields &&
              visibleFields.map((item: string) => {
                return (
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: theme.palette.text.primary,
                      width: '100%',
                      padding: '8px 0px 8px 0px',
                    }}
                  >
                    {reportColumnsDisplayName[item] || item}
                  </Typography>
                );
              })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
