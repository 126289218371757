import { Stack, SvgIcon, Typography } from '@mui/material';
import { InvoiceIcon } from '../../../../ui-kit/components/Assets';
import { observer } from 'mobx-react';
import {
  CustomizeCardHeader,
  LoadInvoiceKPICard,
  LoadInvoiceKPICardProps,
} from '../../../../common/Dashboard';
import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import { useStores } from '../../../../store/root.context';
import { currencyDisplay } from '../../../../utils/grid.utils';
import { StyledComponentRoot } from '../../styles/styles';
import { t } from 'i18next';
const NUMBER_OF_DAYS = '';

const LoadInvoices = (): JSX.Element => {
  const { dashboardStore } = useStores();
  const {
    invoiceDelay,
    invoicePending,
    invoiceMissing,
    invoiceReady,
    invoiceDelayLoader,
    invoicePendingLoader,
    invoiceMissingLoader,
    invoiceReadyLoader,
  } = dashboardStore;

  const invoiceList: LoadInvoiceKPICardProps[] = [
    {
      id: 'invoiceDelay',
      time: NUMBER_OF_DAYS,
      total: Boolean(invoiceDelay.count) ? invoiceDelay.count : '0',
      titles: [t('delayed'), t('payments')],
      loader: invoiceDelayLoader,
      subTitle:
        invoiceDelay && invoiceDelay.amount
          ? currencyDisplay(invoiceDelay.amount.replace('$', ''))
          : '$0',
    },
    {
      id: 'invoicePending',
      time: NUMBER_OF_DAYS,
      total: Boolean(invoicePending.count) ? invoicePending.count : '0',
      titles: [t('pending'), t('payments')],
      loader: invoicePendingLoader,
      subTitle:
        invoicePending && invoicePending.amount
          ? currencyDisplay(invoicePending.amount.replace('$', ''))
          : '$0',
    },
  ];

  const loadList: LoadInvoiceKPICardProps[] = [
    {
      id: 'invoiceMissing',
      time: NUMBER_OF_DAYS,
      total: Boolean(invoiceMissing.count) ? invoiceMissing.count : '0',
      titles: [t('missing'), t('documents')],
      loader: invoiceMissingLoader,
      subTitle:
        invoiceMissing && Boolean(invoiceMissing.amount)
          ? currencyDisplay(invoiceMissing.amount.replace('$', ''))
          : '$0',
    },
    {
      id: 'invoiceReady',
      time: NUMBER_OF_DAYS,
      total: Boolean(invoiceReady.count) ? invoiceReady.count : '0',
      titles: [t('ready'), t('toInvoice')],
      loader: invoiceReadyLoader,
      subTitle:
        invoiceReady && invoiceReady.amount
          ? currencyDisplay(invoiceReady.amount.replace('$', ''))
          : '$0',
    },
  ];
  const { isDesktop, isTablet, isLaptop } = useThemeResponsive();
  return (
    <StyledComponentRoot>
      <CustomizeCardHeader
        avatar={<SvgIcon component={() => <InvoiceIcon />} />}
        title={t('loadInvoices')}
        sxSvgIcon={{
          paddingLeft: '2px',
        }}
      />
      <Stack
        direction="column"
        justifyContent="space-evenly"
        alignItems="flex-start"
        mt={isDesktop ? 5 : 3}
        spacing={1}
        width={'100%'}
        height={'100%'}
        {...(isLaptop && {
          spacing: 3,
        })}
      >
        <Typography variant="h7" color={'text.primary'}>
          {t('invoices')}
        </Typography>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          width={'100%'}
          {...(isTablet && {
            direction: 'row',
            pt: '10px',
            pb: '10px',
          })}
        >
          {invoiceList.map((item) => (
            <LoadInvoiceKPICard key={item.id} {...item} />
          ))}
        </Stack>
        <Typography variant="h7" color={'text.primary'}>
          {t('loads')}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          width={'100%'}
          {...(isTablet && {
            direction: 'row',
            pt: '20px',
            pb: '16px',
          })}
        >
          {loadList.map((item) => (
            <LoadInvoiceKPICard key={item.id} {...item} />
          ))}
        </Stack>
      </Stack>
    </StyledComponentRoot>
  );
};

export default observer(LoadInvoices);
