import { Box, Grid, Stack } from '@mui/material';
import CustomViewsV2 from '../common/Views/customViewsV2';
import {
  CONTRACT_FILTERS,
  CONTRACT_STATUS_FILTERS,
  DEFAULT_CONTRACT_COLUMNS,
  DEFAULT_CONTRACT_VIEWS,
  EContractsEntityList,
  EXCEL_COLUMNS_HEADER,
  FORM_DEFAULT_CONTRACT_VIEWS,
  TABS_CONSTANT,
} from '../constants/contracts';
import { useStores } from '../store/root.context';
import { observer } from 'mobx-react';
import ButtonImproved from '../ui-kit/components/ButtonImproved';
import { AddBox, PostAddOutlined } from '@mui/icons-material';
import CommonDataGrid from '../common/DataGridPro';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AddContractModal,
  ContractDetailsPanel,
  ContractTabStrip,
} from '../components/contract';
import TableDataContainer from '../common/Wrappers/TableDataContainer';
import { FiltersWrapper } from '../ui-kit/components/DataGridPro/filter/FiltersWrapper';
import { useThemeResponsive } from '../common/hooks/useThemeResponsive';
import { contractRequestFormatter } from '../utils';
import { CustomerSummaryTableRow } from '../subPages/customers/models/customer';
import CustomerDetailsPanel from '../subPages/customers/components/CustomerDetailsPanel';
import { useFilterDataGrid } from '../common/hooks/useFilterDataGrid';
import useFilterStorage from '../services/storage';
import { GridColDefSelf } from '../types';
import { downloadFile } from '../utils/Doc';
import {
  RootStoreInstence,
  useRootStore,
} from '../store/root-store/rootStateContext';
import { FuelMatrix, PaginatedLoadListQueryParams } from '../models';
import { httpClient } from '../axios/axiosInstance';
import { contractExportToExcelUrl } from '../api/impl/requestConstants';
import { formatContractFilters } from '../utils/contract_payload_formatter';
import { persistUserState } from '../constants';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { getSortOption } from '../common/Sorting/sortingUtils';
import { NotificationType } from '../ui-kit/components/NotificationToast/NotificationToast';
import ShakeButton from '../common/ShakeButton/ShakeButton';
import { useContractPermission } from '../common/hooks/useContractPermission';
import { DirtyDetailsPanelManager } from '../common/DetailsPanel/utils';
import FuelMatrixActions from '@/subPages/settings/FuelMatrix/FuelMatrixActions';

const ContractPage = () => {
  const { contractStore } = useStores();
  const {
    setCurrentView,
    currentView: { id: stripId },
    tableData,
    component,
    dictionary,
    filters,
    pageNumber,
    last,
    sort,
    setFilters,
    fetchContractList,
  } = contractStore;
  const [isOpenAddContractPanel, setIsOpenAddContractPanel] = useState(false);
  const [isCustomerDetailsPanelOpen, setIsCustomerDetailsPanelOpen] =
    useState(false);
  const [isContractDetailsPanelOpen, setIsContractDetailsPanelOpen] =
    useState(false);
  const [matrixData, setMatrixData] = useState<FuelMatrix[]>([]);
  const [customerId, setCustomerId] = useState('');
  const [contractId, setContractId] = useState('');
  const { isTablet } = useThemeResponsive();
  const [loading, setLoading] = useState(false);
  const contractTableRef = useRef();
  const [allColumns, setAllColumns] = useState<GridColDefSelf[]>([
    ...DEFAULT_CONTRACT_COLUMNS,
  ]);
  const { hasContractCreatePermission } = useContractPermission();
  const currentView = useMemo(
    () => contractStore.currentView,
    [contractStore.currentView]
  );
  const [tableColumns, setTableColumns] = useState<GridColDefSelf[]>([]);
  const defaultFilters = useMemo(() => {
    return (
      persistUserState.pages.Contracts[stripId]?.filters || currentView.metaData
    );
  }, [stripId, currentView.id]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};
  LStorageHook.updateStorage(stripId, {
    ...storageData[stripId],
  });
  const {
    setDefaultColumns,
    onPageFilterChange,
    onAllFilterSubmit,
    onAllFilterReset,
    resetSettings,
    handleChangeColumns,
  } = useFilterDataGrid<any>({
    component,
    allColumns: DEFAULT_CONTRACT_COLUMNS,
    currentView: currentView,
    setTableColumns,
    setAllColumns,
    setFilters: (filters) => {
      setFilters(filters);
    },
    defaultFilters,
    currentViewPrimaryKey: 'id',
  });

  useEffect(() => {
    const getMatrixData = async () => {
      const fuelMatrixList = await FuelMatrixActions.getFuelMatrixList([
        'ACTIVE',
      ]);
      if (fuelMatrixList) {
        setMatrixData(fuelMatrixList);
      }
    };
    const fetchFinanceItems = async () => {
      setLoading(true);
      try {
        await getMatrixData();
        await contractStore.fetchFinanceItems();
        await contractStore.fetchContractList(1);
        await contractStore.fetchEquipmentTypes();
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    fetchFinanceItems();
  }, [contractStore]);

  const onResetColVisibility = () => {
    resetSettings();
  };

  const handleSorting = (model: GridSortModel): void => {
    const sortOptions = getSortOption(
      model,
      '-createDate',
      EXCEL_COLUMNS_HEADER
    );
    contractStore.setSorting(sortOptions);
    LStorageHook.updateStorage(stripId, {
      ...storageData[stripId],
    });
  };
  const exportToExcel = async ({
    gridColumnMetadataList,
  }: {
    gridColumnMetadataList: string[];
  }) => {
    const queryParams = new PaginatedLoadListQueryParams();
    queryParams.sort = '-createdDate';
    queryParams.gridColumnMetadataList = gridColumnMetadataList;
    try {
      const response = await httpClient.put(
        contractExportToExcelUrl,
        queryParams,
        formatContractFilters(filters),
        false,
        true
      );
      return downloadFile(response);
    } catch (e) {
      console.log(e);
    }
  };

  const exportToExcelHandler = useCallback(async (): Promise<void> => {
    const gridColumnMetadataList: Array<any> = [];
    tableColumns.forEach((data) => {
      gridColumnMetadataList.push(
        data.field in EXCEL_COLUMNS_HEADER
          ? EXCEL_COLUMNS_HEADER[data.field]
          : data.field
      );
    });
    return exportToExcel({
      gridColumnMetadataList: gridColumnMetadataList,
    });
  }, [filters, tableColumns]);

  const handleOpenAddContractPanel = () => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    if (hasContractCreatePermission) {
      setContractId('');
      setIsOpenAddContractPanel(true);
      return;
    }
    DirtyDetailsPanelManager.panelName = 'ADD_CONTRACT';
    DirtyDetailsPanelManager.isDirty = true;
    DirtyDetailsPanelManager.isShouldPanelShake();
    RootStoreInstence.setNotificationType({
      type: 'FAILURE',
      message: 'Oops! You are not allowed to perform that action',
    });
    return;
  };

  const tableConfigurationProps = useMemo(
    () => ({
      TableSettings: {
        handleChangeForColumnModel: handleChangeColumns,
        tableColumnsData: allColumns,
        getExcel: exportToExcelHandler,
        resetSettings: onResetColVisibility,
        downloadFile: downloadFile,
      },
    }),
    [allColumns, handleChangeColumns, onResetColVisibility]
  );

  const handleOnCellClick = (params) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    if (params.field === 'customer') {
      if (params.value) {
        setIsContractDetailsPanelOpen(false);
        setIsCustomerDetailsPanelOpen(true);
        setCustomerId(params?.row?.customerId);
        return;
      }
      return;
    }
    setIsCustomerDetailsPanelOpen(false);
    setIsContractDetailsPanelOpen(true);
    setContractId(params?.row?.id);
  };

  const formattedTableData = useMemo(
    () =>
      tableData?.content?.map((data) =>
        contractRequestFormatter.convertContractListRespToTableData(data)
      ) || [],
    [tableData?.content]
  );

  const onRowsScrollEnd = (): void => {
    if (last || (formattedTableData && !formattedTableData.length)) return;
    fetchContractList(pageNumber + 1);
  };

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetchContractList(1);
    }
  }, [filters, sort, currentView]);

  const handleOnContractCreation = (response: any) => {
    try {
      setLoading(true);
      contractStore.fetchContractList(1);
      setIsOpenAddContractPanel(false);
      setLoading(false);
      setIsCustomerDetailsPanelOpen(false);
      setContractId(response?.data?.id);
      setIsContractDetailsPanelOpen(true);
      return;
    } catch (e) {}
  };

  useEffect(() => {
    setDefaultColumns();
  }, [currentView]);

  const handleContractDuplicate = () => {
    setIsDuplicate(true);
    setIsOpenAddContractPanel(true);
  };

  return (
    <Stack direction={'column'} height="100%">
      <Stack direction={'row'} display={'flex'}>
        <CustomViewsV2
          disableAPI={true}
          component={component}
          entities={[
            EContractsEntityList.ALL_CONTRACT,
            EContractsEntityList.ACTIVE,
            EContractsEntityList.EXPIRED,
          ]}
          dictionary={dictionary}
          defaultViews={DEFAULT_CONTRACT_VIEWS}
          formDefaultValuesMap={FORM_DEFAULT_CONTRACT_VIEWS}
          defaultView={DEFAULT_CONTRACT_VIEWS[0]}
          modalTitle={'Create contract view'}
          isCustomActionsAvailable={false}
          currentView={currentView}
          formDefaultView={{
            [EContractsEntityList.ALL_CONTRACT]: DEFAULT_CONTRACT_VIEWS[0],
          }}
          setCurrentView={setCurrentView}
        />
        {hasContractCreatePermission && (
          <ShakeButton
            startIcon={<PostAddOutlined />}
            variant="contained"
            label={'Add Contract'}
            onClick={handleOpenAddContractPanel}
          />
        )}
      </Stack>
      <TableDataContainer key={'contracts'}>
        <Stack direction={'row'} width={'100%'}>
          <Stack direction="column" width="100%">
            <FiltersWrapper
              settingsPanel={true}
              onAllFilterReset={onAllFilterReset}
              defaultFilterValue={filters}
              onAllFilterSubmit={onAllFilterSubmit}
              onPageFilterChange={onPageFilterChange}
              filterColumns={CONTRACT_FILTERS}
              tableConfigurationProps={tableConfigurationProps}
              pageName={''}
              displayFilters={true}
              filterPerCol={
                isTablet ? [Math.ceil(CONTRACT_FILTERS.length / 2)] : [8]
              }
            />
            <Box
              sx={{
                width: '100%',
                height: '100%',
                '> div': {
                  ml: 0,
                  height: '100%',
                },
              }}
            >
              <CommonDataGrid
                apiRef={contractTableRef}
                disableColumnMenu
                rows={formattedTableData}
                columns={allColumns}
                onCellClick={handleOnCellClick}
                loading={loading}
                onRowsScrollEnd={onRowsScrollEnd}
                sortingMode="server"
                onSortModelChange={handleSorting}
              />
            </Box>
          </Stack>
          {Boolean(isCustomerDetailsPanelOpen) && (
            <Box mt={1} height={'100%'}>
              <CustomerDetailsPanel
                isContractPage={false}
                data={{ id: customerId }}
                isCreatePanelOpen={false}
                httpErrors={undefined}
                onClose={() => setIsCustomerDetailsPanelOpen(false)}
                autoExpanded={false}
                isGlobal={false}
                onUpdate={(data) => {
                  try {
                    if (data?.id) {
                      setContractId('');
                      setContractId(data?.id);
                      return;
                    }
                    fetchContractList(pageNumber);
                    setIsCustomerDetailsPanelOpen(false);
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            </Box>
          )}
          {Boolean(isContractDetailsPanelOpen) && (
            <Box mt={1} height={'100%'}>
              <ContractDetailsPanel
                handleContractDuplication={handleContractDuplicate}
                onUpdate={(data) => {
                  try {
                    fetchContractList(pageNumber);
                    if (data?.id) {
                      setContractId('');
                      setContractId(data?.id);
                      return;
                    }
                    setIsContractDetailsPanelOpen(false);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                data={{ id: contractId }}
                isCreatePanelOpen={false}
                httpErrors={undefined}
                onClose={() => setIsContractDetailsPanelOpen(false)}
                autoExpanded={false}
                isGlobal={false}
                matrixData={matrixData}
              />
            </Box>
          )}
        </Stack>
        {isOpenAddContractPanel && !loading && (
          <AddContractModal
            isDuplicate={isDuplicate}
            data={{
              id: contractId,
            }}
            onCreatedContract={handleOnContractCreation}
            onClose={() => {
              setIsDuplicate(false);
              setIsOpenAddContractPanel(false);
            }}
            panelTitle={'Add contract'}
            matrixData={matrixData}
          />
        )}
      </TableDataContainer>
    </Stack>
  );
};

export default observer(ContractPage);
