import { ActionDialogFormData } from '../../../views/myLoads/components/LoadDetailDialog';
import { LoadCancellationDetails, UpdateStatusPayload } from '../../../models';
import { E3DotMenuType } from '../../../views/myLoads/components/LoadDetailDialog/types';
import { getUserId } from '../../TimeoffDialog/utils';
import { ELoadStatus } from '../constants/constants';

export const generateLoadDetailPayload = (
  data: ActionDialogFormData,
  type: E3DotMenuType,
  entity: 'Load' | 'Trip'
): UpdateStatusPayload => {
  const requestBody: UpdateStatusPayload = {
    completionDate: data.completionDate?.toISOString(),
    invoicedDate: data.invoicedDate?.toISOString(),
    paidDate: data.paidDate?.toISOString(),
    invoiceNotes:
      type === E3DotMenuType.COMPLETE && entity === 'Load'
        ? data.invoiceNotes
        : undefined,
    noteDetails:
      type === E3DotMenuType.COMPLETE && entity === 'Load'
        ? {
            updateTime: new Date().toISOString(),
            userId: getUserId(),
          }
        : undefined,
  };

  if (type === E3DotMenuType.CANCEL) {
    const loadCancellationDetails: LoadCancellationDetails = {
      cancellationDate: data.cancellationDate?.toISOString() as string,
      invoiceNotes: data.invoiceNotes,
      keepInvoiceLineItems: data.keepInvoiceLineItems as boolean,
      markAsNonInvoiceable: data.markAsNonInvoiceable as boolean,
      noteDetails: {
        updateTime: new Date().toISOString(),
        userId: getUserId(),
      },
    };
    requestBody.loadCancellationDetails = loadCancellationDetails;
  }
  return requestBody;
};

export const mappingAction: {
  [key in E3DotMenuType]?: ELoadStatus;
} = {
  [E3DotMenuType.MARK_AS_INVOICE]: ELoadStatus.INVOICED,
  [E3DotMenuType.COMPLETE]: ELoadStatus.LOAD_COMPLETED,
  [E3DotMenuType.CANCEL]: ELoadStatus.CANCELLED,
  [E3DotMenuType.MARK_AS_PAID]: ELoadStatus.PAID,
};
