import {
  getOptmizedResult,
  fetchSandboxWarningsRequest,
} from '../network/recommendTrips.request';
import { useEffect, useReducer } from 'react';
import {
  OPTYMIZATION_ACTIONS,
  recommendTripsReducer,
  recommendedTripsInitialState,
} from '../store';
import {
  IRecommendTrips,
  IRecommendTripsPayload,
} from '../types/recommendTrips.types';
import { useStores } from '../../../store/root.context';
import {
  IBulkOptimizationRequestPayload,
  WarningReqPayloadType,
} from '../types/requestPayload.types';
import axios, { CancelTokenSource } from 'axios';
import { RESOURCES_TYPE } from '../constants/gantt.const';
import { RECOMMEND_ONLY_TRIPS_FILTER_STATUS } from '../constants/recommendedTrips.filters';
import {
  formatDriversListToMap,
  formatTripsToMap,
} from '../services/recommendTrips.service';
import { RECOMMENDED_RESOURCE_TYPE } from '../constants/optymization.const';

interface RecommendTripsHookProps {
  requestPayload?: IBulkOptimizationRequestPayload;
  requestParams?: object;
  previousSandboxId?: string;
  resumePreviousOptimization?: boolean;
}
let intervalCount = 0;
const useRecommendTrips = (props: RecommendTripsHookProps) => {
  const [recommendedTripsStore, dispatchRecommendedTrips] = useReducer(
    recommendTripsReducer,
    recommendedTripsInitialState
  );
  const {
    bulkOptymizationStore: {
      setHosConstants,
      setResourceToReassign,
      setKpiMetrics,
      onRecommendationFilterChange,
    },
    dispatch2GlobalStore: { setPreviousSandboxId },
    evaluationStore: { stopEvaluation, setGlobalEvaluationResult },
  } = useStores();

  let abortController: AbortController;
  let cancelTokenSource: CancelTokenSource;
  const abortAxiosRequest = () => abortController?.abort();
  const initAxiosReqController = () => {
    abortController = new AbortController();
    cancelTokenSource = axios.CancelToken.source();
  };

  useEffect(() => {
    runOptimization({
      params: props?.requestParams,
      payload: props?.requestPayload,
      isInitialLoad: true,
      previousSandboxId: props?.previousSandboxId,
      resumePreviousOptimization: props?.resumePreviousOptimization,
    });
    return () => abortAxiosRequest();
  }, []);

  const runOptimization = async ({
    params,
    payload,
    isInitialLoad = false,
    previousSandboxId,
    resumePreviousOptimization,
  }: any) => {
    try {
      intervalCount = 0;
      const resumeSandboxPayload = {
        isRefreshOnlyFlag: true,
        sandboxId: previousSandboxId,
      };
      initAxiosReqController();
      const recommendedTripsRes = await getOptmizedResult({
        data: resumePreviousOptimization ? resumeSandboxPayload : payload,
        params,
        signal: abortController?.signal,
        cancelToken: cancelTokenSource?.token,
      });
      const {
        drivers,
        trips,
        hosConstants,
        assignmentMetrics,
        lockedAssignmentMetrics,
        timeoffs,
        sandboxId,
        evaluateResponseDTO,
      } = recommendedTripsRes?.data;
      setGlobalEvaluationResult(evaluateResponseDTO);
      const tripsMap = formatTripsToMap(trips);
      const {
        driversMap,
        lockedDrivers,
        assignedCandidatesLockedCount,
        brokeredTripLockedCount,
      } = formatDriversListToMap(drivers, tripsMap);
      setHosConstants(hosConstants);
      const reducerPayload = {
        driversMap,
        tripsMap,
        hosConstants,
        timeoffs,
        sandboxId,
        lockedRecommendations: lockedDrivers,
        assignedCandidatesLockedCount,
        brokeredTripLockedCount,
      };
      dispatchRecommendedTrips({
        type: OPTYMIZATION_ACTIONS.SET_FORMATTED_TRIPS,
        payload: reducerPayload,
      });

      setKpiMetrics({ assignmentMetrics, lockedAssignmentMetrics });
      mapSandboxWarning({
        sandboxId,
        driverGroupId: null,
        tripId: null,
      });
      setPreviousSandboxId(sandboxId);
      handleRunPossibleResource({ isInitialLoad, driversMap });
    } catch (err: any) {
      dispatchRecommendedTrips({
        type: isInitialLoad
          ? OPTYMIZATION_ACTIONS.OPTIMIZATION_FAILED
          : OPTYMIZATION_ACTIONS.STOP_LOADER,
      });
    }
    intervalCount = 0;
  };

  const mapSandboxWarning = async (payload: WarningReqPayloadType) => {
    try {
      const warningsRes = await fetchSandboxWarningsRequest({ data: payload });
      const warnings = {
        trips: warningsRes?.tripWarnings,
        drivers: warningsRes?.driverWarnings,
      };
      dispatchRecommendedTrips({
        type: OPTYMIZATION_ACTIONS.MAP_SANDBOX_WARNINGS,
        payload: { warnings },
      });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleRunPossibleResource = ({
    isInitialLoad,
    driversMap,
  }: {
    isInitialLoad?: boolean;
    driversMap?: IRecommendTrips['drivers'];
  }) => {
    if (!isInitialLoad) return;
    const assignedCandidate = driversMap?.get(
      RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER
    );
    const unassignedDriver = driversMap?.get?.(
      RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER
    );
    const unassignedTrip = driversMap?.get?.(
      RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP
    );
    let resourceType;
    let resourceId = null;
    let displayName = '';
    let isResourceToReassignTrip = false;
    let candidateMap = null;
    // set default filters and auto open possible options if only 1 unassigned driver/trip exists
    if (assignedCandidate?.size === 0) {
      if (unassignedDriver?.size === 1) {
        candidateMap = unassignedDriver;
        resourceType = RESOURCES_TYPE.DRIVER;
      } else if (unassignedTrip?.size === 1) {
        candidateMap = unassignedTrip;
        resourceType = RESOURCES_TYPE.TRIP;
        isResourceToReassignTrip = true;
      }
    } else if (assignedCandidate?.size === 1) {
      if (unassignedDriver?.size === 0) {
        candidateMap = assignedCandidate;
        resourceType = RESOURCES_TYPE.DRIVER;
      } else if (unassignedTrip?.size === 0) {
        isResourceToReassignTrip = true;
        candidateMap = assignedCandidate;
        resourceType = RESOURCES_TYPE.TRIP;
      }
    }
    if (!candidateMap) return;
    if (isResourceToReassignTrip) {
      const id = Array.from(candidateMap?.keys?.())?.[0];
      displayName =
        candidateMap?.get(id)?.selectedTripData?.[0]?.tripSeqNumber!;
      resourceId = candidateMap?.get(id)?.selectedTripData?.[0]?.tripPrimaryId;
    } else {
      resourceId = Array.from(candidateMap?.keys?.())?.[0];
      displayName =
        candidateMap?.get(resourceId)?.driverGroupDetails?.groupName!;
    }
    if (resourceType === RESOURCES_TYPE.TRIP) {
      onRecommendationFilterChange?.(
        RECOMMEND_ONLY_TRIPS_FILTER_STATUS,
        'statuses'
      );
    }
    stopEvaluation();
    // setPossibleResourceFilters({
    //   ...possibleResourceFilters,
    //   noOfResult: getNoOfResultsOptions().find(({ key }) => key === 'all')!,
    // });
    setResourceToReassign({
      type: resourceType!,
      id: resourceId!,
      label: displayName ?? resourceType,
    });
  };

  const updateRecommendTripsByAction = (
    type: string,
    payload?: IRecommendTripsPayload
  ) => {
    dispatchRecommendedTrips({ type, payload });
  };

  return {
    recommendedTripsStore,
    updateRecommendTripsByAction,
    runOptimization,
  };
};

export default useRecommendTrips;
