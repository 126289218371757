import {
  ISafetyAlertQueryParamsCalendar,
  ISafetyAlertQueryParamsTable,
} from '../../../views/safety/alerts/constants/types';
import { QueryParams } from '../commonMixed';
import { AlertEntityType, AlertStatus } from './Types';

export class PaginatedAlertListRequest
  extends QueryParams
  implements ISafetyAlertQueryParamsTable
{
  startDate?: string | null;
  endDate?: string | null;

  maintenanceItems?: string[] | undefined;
  alertStatus?: AlertStatus[] | undefined;
  idList?: string[] | undefined;
  equipmentType?: AlertEntityType[] | undefined;

  pageSize?: number;
  pageNumber?: number;

  lastServiceStartDate?: string;
  lastServiceEndDate?: string;
  nextServiceStartDate?: string;
  nextServiceEndDate?: string;

  constructor(dto: ISafetyAlertQueryParamsTable) {
    super();

    this.pageSize = dto.pageSize;
    this.pageNumber = dto.pageNumber;

    if (dto.maintenanceItems) {
      this.maintenanceItems = dto.maintenanceItems;
    }
    if (dto.alertStatus) {
      this.alertStatus = dto.alertStatus;
    }
    if (dto.equipmentName) {
      this.idList = dto.equipmentName;
    }
    if (dto.equipmentType) {
      this.equipmentType = dto.equipmentType;
    }
    if (dto.startDate) {
      this.startDate = dto.startDate;
    }
    if (dto.endDate) {
      this.endDate = dto.endDate;
    }
    if (dto.lastServiceStartDate) {
      this.lastServiceStartDate = dto.lastServiceStartDate;
    }
    if (dto.lastServiceEndDate) {
      this.lastServiceEndDate = dto.lastServiceEndDate;
    }
    if (dto.nextServiceStartDate) {
      this.nextServiceStartDate = dto.nextServiceStartDate;
    }
    if (dto.nextServiceEndDate) {
      this.nextServiceEndDate = dto.nextServiceEndDate;
    }
    if (dto.terminalIds) {
      this.terminalIds = dto.terminalIds;
    }
  }
}

export class AlertListCalendarRequest
  extends QueryParams
  implements ISafetyAlertQueryParamsCalendar
{
  startDate?: string | null;
  endDate?: string | null;

  maintenanceItems?: string[];
  alertStatus?: AlertStatus[];
  idList?: string[];
  equipmentType?: AlertEntityType[];
  lastServiceStartDate?: string;
  lastServiceEndDate?: string;
  nextServiceStartDate?: string;
  nextServiceEndDate?: string;

  constructor(dto: ISafetyAlertQueryParamsCalendar) {
    super();
    this.startDate = dto.startDate;
    this.endDate = dto.endDate;

    if (dto.maintenanceItems) {
      this.maintenanceItems = dto.maintenanceItems;
    }
    if (dto.alertStatus) {
      this.alertStatus = dto.alertStatus;
    }
    if (dto.equipmentName) {
      this.idList = dto.equipmentName;
    }
    if (dto.equipmentType) {
      this.equipmentType = dto.equipmentType;
    }
    if (dto.lastServiceStartDate) {
      this.lastServiceStartDate = dto.lastServiceStartDate;
    }
    if (dto.lastServiceEndDate) {
      this.lastServiceEndDate = dto.lastServiceEndDate;
    }
    if (dto.nextServiceStartDate) {
      this.nextServiceStartDate = dto.nextServiceStartDate;
    }
    if (dto.nextServiceEndDate) {
      this.nextServiceEndDate = dto.nextServiceEndDate;
    }
    if (dto.terminalIds) {
      this.terminalIds = dto.terminalIds;
    }
  }
}

export class ExportSafetyAlertFilterQueryParams extends QueryParams {
  gridColumnMetadataList!: string[] | null;
  lastServiceStartDate: string | 'null';
  lastServiceEndDate: string | 'null';
  nextServiceStartDate: string | 'null';
  nextServiceEndDate: string | 'null';

  constructor({
    gridColumnMetadataList,
    lastServiceStartDate,
    lastServiceEndDate,
    nextServiceStartDate,
    nextServiceEndDate,
  }: any) {
    super();
    this.gridColumnMetadataList = gridColumnMetadataList;
    this.lastServiceStartDate = lastServiceStartDate;
    this.lastServiceEndDate = lastServiceEndDate;
    this.nextServiceStartDate = nextServiceStartDate;
    this.nextServiceEndDate = nextServiceEndDate;
  }
}

export class ExportSafetyAlertFilterPayload extends QueryParams {
  equipmentTypesFilter!: string[];
  maintenanceItemsFilter!: string[];
  idListFilter!: string[];
  alertStatusFilter!: string[];

  constructor({
    equipmentTypesFilter,
    maintenanceItemsFilter,
    idListFilter,
    alertStatusFilter,
  }: any) {
    super();
    this.equipmentTypesFilter = equipmentTypesFilter || [];
    this.maintenanceItemsFilter = maintenanceItemsFilter || [];
    this.idListFilter = idListFilter || [];
    this.alertStatusFilter = alertStatusFilter || [];
  }
}
