import { QueryParams } from '@/models';

export class GetEntityNotesRequest extends QueryParams {
  entityId!: string;
  entityType!: string;
}

export class CreateEntityNotesRequest extends QueryParams {
  entityId!: string;
  entityType!: string;
  note!: string;
}

export class UpdateEntityNotesRequest extends QueryParams {
  entityId!: string;
  entityType!: string;
  note!: string;
  id?: string;
}

export class DeleteEntityNotesRequest extends QueryParams {
  id!: string;
  documentId?: string;
}
