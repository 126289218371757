import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ganttPreferencesModule } from '../../../../common/PendoClassIDs/constants';
import { states } from '../../../../common/Ui/AddressField/constants/states';
import ButtonGroupRadio from '../../../../common/Ui/ButtonGroup';
import Checkbox from '../../../../common/Ui/Checkbox';
import { MultipleAutocompleteForm } from '../../../../common/Ui/MultipleAutocomplete';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import { PreferencesDTO } from '../../../../models';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import { defaultDispatchPreferencesFormData } from '../../../../views/dispatch/config';
import { DVIRDriverPreferenceOptions } from '../../../../views/settings/preferences/constants';
import {
  drivingBehaviourPreferences,
  getWorkWeekType,
} from '../../constants/preferences';
import { convertPreferenceDriverToPreferencesData } from '../../utils/dispatch';
import {
  StyledPreferenceAction,
  StyledPreferenceActionLabel,
  buttonGroupLable,
  buttonGroupStyle,
} from './styles';

import { useTranslation } from 'react-i18next';

export const GanttPreferences = () => {
  const { control, setValue, watch } = useFormContext();
  const fieldPath = 'preferencesData.';
  const [statesToAvoid, statesPreferred, workweek] = watch([
    `${fieldPath}statesToAvoid`,
    `${fieldPath}locationPreference`,
    `${fieldPath}workWeekType`,
  ]);
  const { t, ready } = useTranslation();

  const setPreferenceCompanyDriver = () => {
    const respone: PreferencesDTO | null =
      RootStoreInstence.getCompanyPreferences;
    if (respone && respone.driverPreferences) {
      const newFormData = convertPreferenceDriverToPreferencesData(
        respone.driverPreferences
      );
      setValue(
        `${fieldPath}useSleeperBerthProvision`,
        newFormData.useSleeperBerthProvision
      );
      // setValue(
      //   `${fieldPath}ignoreHOSRestrictions`,
      //   newFormData.ignoreHOSRestrictions
      // );
      setValue(`${fieldPath}dvirPreferences`, newFormData.dvirPreferences);
      setValue(`${fieldPath}workWeekType`, newFormData.workWeekType);
      setValue(
        `${fieldPath}drivingBehaviourPreference`,
        newFormData.drivingBehaviourPreference
      );
      setValue(`${fieldPath}weeklyResetDay`, newFormData.weeklyResetDay);
    }
  };

  const stateOptions = (stateToIgnore: Array<any>) => {
    if (stateToIgnore && Array.isArray(stateToIgnore)) {
      const finalStateList = states.filter(
        ({ value: id1 }) => !stateToIgnore.some(({ value: id2 }) => id2 === id1)
      );
      return finalStateList;
    } else {
      return states;
    }
  };
  useEffect(() => {
    setPreferenceCompanyDriver();
  }, []);

  const handleHosChange = (event?: any) => {
    setValue(`${fieldPath}ignoreHOSRestrictions`, event?.target?.checked);
  };

  return (
    <Box sx={{ height: '100%', pt: 2, pb: 2 }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid spacing={1} container margin={0} padding={2}>
          {/* <Grid item xs={12} marginBottom={'20px'}>
            <StyledPreferenceAction>
              <StyledPreferenceActionLabel>
                Ignore HOS restrictions
              </StyledPreferenceActionLabel>
              <Switcher
                control={control}
                name={`${fieldPath}ignoreHOSRestrictions`}
                label=""
                onChange={handleHosChange}
                style={{ maxWidth: '30px !important' }}
              />
            </StyledPreferenceAction>
          </Grid> */}
          <SingleAutocompleteForm
            id={ganttPreferencesModule + 'WorkWeekType'}
            control={control}
            defaultValue={defaultDispatchPreferencesFormData.workWeekType}
            name={`${fieldPath}workWeekType`}
            fieldName={'value'}
            label={'Work Week length'}
            getOptions={getWorkWeekType}
            sizes={{ xs: 12 }}
            disableClear={true}
            // disabled={applyHosRestriction}
          />
          <Grid item xs={12}>
            <StyledPreferenceAction>
              <StyledPreferenceActionLabel>
                Sleeper-berth in use
                <Typography fontSize={'12px'}>
                  Whether the driver will sleep inside the tractor/truck{' '}
                </Typography>
              </StyledPreferenceActionLabel>
              <Box>
                <Checkbox
                  id={'sleeper-birth'}
                  label=""
                  name={`${fieldPath}useSleeperBerthProvision`}
                  control={control}
                  // disabled={applyHosRestriction}
                />
              </Box>
            </StyledPreferenceAction>
          </Grid>
          <Grid item xs={12} marginTop={'10px'}>
            <StyledPreferenceActionLabel sx={{ ...buttonGroupLable }}>
              DVIR:
            </StyledPreferenceActionLabel>
            <ButtonGroupRadio
              buttonStyle={{ ...buttonGroupStyle }}
              marginBottom={10}
              control={control}
              name={`${fieldPath}dvirPreferences`}
              options={DVIRDriverPreferenceOptions}
            />
          </Grid>
          <MultipleAutocompleteForm
            customStyles={{ marginBottom: '5px' }}
            id={'locationPreference'}
            variant={'standard'}
            control={control}
            name={`${fieldPath}locationPreference`}
            fieldName={'value'}
            label={'States preferred'}
            getOptions={() => stateOptions(statesToAvoid)}
            sizes={{ xs: 12 }}
            avoidLazyLoading={true}
          />
          <MultipleAutocompleteForm
            customStyles={{ marginBottom: '5px' }}
            id={ganttPreferencesModule + 'statesToAvoid'}
            variant={'standard'}
            control={control}
            name={`${fieldPath}statesToAvoid`}
            fieldName={'value'}
            label={'States to avoid'}
            avoidLazyLoading={true}
            getOptions={() => stateOptions(statesPreferred)}
            sizes={{ xs: 12 }}
          />
          <Grid item xs={12}>
            <StyledPreferenceActionLabel sx={{ ...buttonGroupLable }}>
              Shift time preferred
            </StyledPreferenceActionLabel>
            <ButtonGroupRadio
              marginBottom={10}
              control={control}
              buttonStyle={{ ...buttonGroupStyle }}
              name={`${fieldPath}drivingBehaviourPreference`}
              options={drivingBehaviourPreferences}
            />
          </Grid>
          <Grid item xs={4} paddingLeft={'0px'}>
            <TextField
              control={control}
              name={`${fieldPath}weeklyMileageTarget`}
              label="Weekly miles target"
              id="outlined-start-adornment"
              type="number"
              endAdornmentText="mi"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              control={control}
              name={`${fieldPath}weeklyEarningsTarget`}
              type="number"
              label="Weekly earning target"
              id="outlined-start-adornment"
              startAdornmentText="$"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              control={control}
              name={`${fieldPath}weeklyRevenueTarget`}
              label="Weekly revenue target"
              id="outlined-start-adornment"
              type="number"
              startAdornmentText="$"
            />
          </Grid>

          {/* <Grid item xs={12} marginTop={'10px'}>
            <StyledPreferenceActionLabel sx={{ ...buttonGroupLable }}>
              Home time for weekly HOS Reset (Choose one):
            </StyledPreferenceActionLabel>
            <ButtonGroupRadio
              marginBottom={1}
              buttonStyle={{ ...buttonGroupStyle }}
              control={control}
              name={`${fieldPath}weeklyResetDay`}
              options={weeklyResetDayData}
            />
          </Grid> */}
        </Grid>
        <StyledPreferenceAction></StyledPreferenceAction>
      </Stack>
    </Box>
  );
};
