import React from 'react';
import { allCustomerFeatures } from '../../constants/customerFeaturesList';
import { Box, Grid, Typography } from '@mui/material';

export default function CustomerFeatures({ features }: any) {
  const titleStyle = {
    color: 'text.primary',
    fontSize: 20,
    letterSpacing: '0.15px',
    fontWeight: '500',
    lineHeight: 'normal',
  };
  const subtitleStyle = {
    color: 'text.secondary',
    letterSpacing: '0.15px',
    pt: '4px',
    fontSize: '14px',
    fontWeight: '400',
  };
  const headerStyle = {
    color: 'primary.main',
    letterSpacing: '0.15px',
    fontWeight: '500',
  };
  return (
    <Box>
      <Grid>
        <Typography variant="h5" sx={headerStyle}>
          Features
        </Typography>
      </Grid>
      <Grid container sx={{ pt: '16px' }}>
        {features.map((data: string, index: number) => {
          return (
            <Grid
              container
              className="feature"
              key={index}
              sx={{ pb: '32px', width: '100%' }}
              item
              xs={6}
            >
              <Box sx={{ pr: '18px', width: '10%', textAlign: 'center' }}>
                {allCustomerFeatures[data]?.icon}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '90%',
                }}
              >
                <Typography variant="subtitle1" sx={titleStyle}>
                  {allCustomerFeatures[data]?.title}
                </Typography>
                <Typography variant="caption" sx={subtitleStyle}>
                  {allCustomerFeatures[data]?.subtitle}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
