import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useCarrierPermission } from '../../../../../common/hooks/useCarrierPermission';
import { CarrierDTO } from '../../../../../models/DTOs/Carrier/Requests';
import { AddNewContactForm } from '../Forms/AddNewCarrierForm/AddNewContactForm';
import { AddNewInsuranceForm } from '../Forms/AddNewCarrierForm/AddNewInsuranceForm';
import { GenerateForm } from '../Forms/AddNewCarrierForm/GenerateForm';
import { getStyledAddNewCarrierForm } from '../Forms/AddNewCarrierForm/styles';
export interface CarrierOverviewProps {
  carrierDTO: CarrierDTO;
}

export const CarrierOverview = observer(
  ({ carrierDTO }: CarrierOverviewProps) => {
    const styles = getStyledAddNewCarrierForm();
    const { hasCarrierEditPermission } = useCarrierPermission();
    return (
      <Box
        className={'AddNewCarrierForm-root'}
        sx={styles.AddNewCarrierFormRoot}
      >
        <GenerateForm canEdit={hasCarrierEditPermission} />
        <AddNewInsuranceForm
          isCreatePanel={false}
          carrierDTO={carrierDTO}
          canEdit={hasCarrierEditPermission}
        />
        <AddNewContactForm
          isCreatePanel={false}
          carrierDTO={carrierDTO}
          canEdit={hasCarrierEditPermission}
        />
      </Box>
    );
  }
);
