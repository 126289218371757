import React, { FunctionComponent } from 'react';
import { icons } from '../assets/icons';

export const Icon: FunctionComponent<Props> = ({
  icon,
  placement,
  style = {},
  ...restProps
}: Props) => {
  return (
    <span
      style={style}
      className={`${
        placement === 'left' ? 'left-placed-icon' : 'right-placed-icon'
      }`}
    >
      <svg viewBox="0 0 1024 1024" width="20px" height="20px" {...restProps}>
        <path d={icons[icon]} />
      </svg>
    </span>
  );
};

interface Props {
  icon: keyof typeof icons;
  placement?: string;
  style?: Object;

  [x: string]: unknown;
}
