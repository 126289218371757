import { Moment } from 'moment';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { Model } from '../../../models/common/Model';
import {
  AssignmentShort,
  DispatcherShort,
  TerminalShort,
  customerManifestType,
  terminalsManifestType,
} from '../../../models/common/modelsShort';
import { StatusTypes } from '../../../types';
import { fromIsoToMoment, getNullableModel } from '../../../utils';
import { LoadPriorityEnum } from '../CreateLoad/dto/create-load-request.dto';
import { LoadListWarning } from './LoadListWarning';
import { TagCreateResponseDTO } from '@/models';

type AssignmentType = 'tractor' | 'trailer' | 'dispatcher' | 'driverGroup';

function setupDriverGroup(connectionDetails: any): AssignmentShort[] | null {
  const assignments = connectionDetails?.reduce(
    (assignments: AssignmentShort[], trip: any) => {
      if (trip.driverGroup) {
        // @TODO DispatchDetailsPanel is no working with driver id which isn ot correct. Panel should be changed to support driver groupid . In that case
        // this id should be changed to driver group id and setupAssignment function can be used.
        assignments.push(
          new AssignmentShort({
            id: trip.driverGroup.id,
            name: trip.driverGroup.name,
            tripId: trip.tripId,
          })
        );
      }
      return assignments;
    },
    []
  );

  return assignments?.length ? assignments : null;
}

const setupTrips = (connectionDetails: any): AssignmentShort[] =>
  connectionDetails?.map(
    (trip: any) =>
      new AssignmentShort({ id: trip.tripId, name: trip.tripSequenceNumber })
  );

function setupAssignment(
  connectionDetails: any,
  type: AssignmentType
): AssignmentShort[] | null {
  const assignments = connectionDetails?.reduce(
    (assignments: AssignmentShort[], trip: any) => {
      if (trip[type]) {
        assignments.push(
          new AssignmentShort({ ...trip[type], tripId: trip.tripId })
        );
      }
      return assignments;
    },
    []
  );

  return assignments?.length ? assignments : null;
}

interface LoadWarning {
  loadAssignmentDelayed: boolean;
  loadDetention: boolean;
  loadDetentionTime: number;
  numberOfWarnings: number;
  stopIsDelayed: boolean;
  rateConfirmationWarning: string;
  proofOfDeliveryWarning: string;
  billOfLadingWarning: string;
  delayedStopNames: string[];
  missingDocuments: string[];
}

export interface IEdiDetails {
  customer: string;
  customerIsa: string;
  scac: string;
  shipmentId: string;
  tenderId: string;
}
export class LoadSummary extends Model {
  trips: AssignmentShort[]; // should be changed type
  completeDate: Moment | null;
  customerContactId: null;
  customerMapped: null;
  customerId: number;
  customerName: string | null;
  distanceTraveled: number;
  driverGroupId: string | null;
  driverId: number | null;
  driverName: string | null;
  endDateTime: Moment | null;
  equipmentTypes: string | null;
  estimatedDistanceTraveled: number;
  estimatedTimeToCoverDistance: number;
  firstStopTimeZone: string | null;
  fromLocationName: string | null;
  driverGroups: AssignmentShort[] | null;
  primaryDriver: object | undefined;
  hasBeenCancelled: boolean;
  kpiList: any[];
  lastStopAppointmentStartDate: Moment | null;
  lastStopTimeZone: string | null;
  loadAxeleId: string | null;
  loadDetentionTime: number;
  loadStatus: ELoadStatus;
  loadSettlementStatus: string;
  tripConnectionDetails: any[] | null;
  missingDocuments: string[];
  lastUpdatedStop: StatusTypes | null;
  loadType: string | null;
  maxWeight: number;
  netRevenue: number;
  nextExpectedDepartureTime: string | null;
  nonInvoiceable: boolean;
  numberOfStops: number;
  onHold: boolean;
  onHoldNote: string;
  operationType: string | null;
  orderAxeleId: null;
  organizationId: number;
  referenceId: null;
  revenue: number;
  sealNumber: number | null;
  chassisNumber: number | null;
  bookingNumber?: string;
  appointmentNumber?: string;
  loadDirection?: string;
  containerNumber: number | null;
  totalWeight: number | null;
  seqNumber: string | null;
  startDateTime: Moment | null;
  terminal: TerminalShort | null;
  dispatchTerminal: TerminalShort | null;
  tractors: AssignmentShort[] | null;
  trailers: AssignmentShort[] | null;
  dispatchers: AssignmentShort[] | null;
  toLocationName: string | null;
  warning: LoadListWarning | null;
  loadWarning: LoadWarning | null;

  dispatcher: DispatcherShort | null;
  createDate: string;
  dispatchNotes: string | null;
  commodityTypes: string | null;
  manifestBaseDetails: {
    manifestId: string;
    seqNumber: string;
    status: string;
  };
  tags: Array<TagCreateResponseDTO> | null;
  manifestLoadIds: Array<string>;
  customers: customerManifestType | undefined;
  terminals: terminalsManifestType | undefined;
  ediDetails: IEdiDetails;
  source: string;
  invoiceHasPayment: boolean;
  loadPriority?: LoadPriorityEnum;
  canBeBrokered?: boolean;
  constructor(dto: any) {
    super(dto);
    this.completeDate = fromIsoToMoment(dto.completeDate);
    this.customerContactId = dto.customerContactId;
    this.customerMapped = dto.customerMapped;
    this.customerId = dto.customerId;
    this.customerName = dto.customerName;
    this.distanceTraveled = dto.distanceTraveled;
    this.driverGroupId = dto.driverGroupId;
    this.driverId = dto.driverId;
    this.driverName = dto.driverName;
    this.endDateTime = fromIsoToMoment(dto.endDateTime);
    this.equipmentTypes = dto.equipmentTypes;
    this.estimatedDistanceTraveled = dto.estimatedDistanceTraveled;
    this.estimatedTimeToCoverDistance = dto.estimatedTimeToCoverDistance;
    this.firstStopTimeZone = dto.firstStopTimeZone;
    this.fromLocationName = dto.fromLocationName;
    this.driverGroups = setupDriverGroup(dto.tripConnectionDetails);
    this.primaryDriver = dto.groupDetails?.drivers[0];
    this.hasBeenCancelled = dto.hasBeenCancelled;
    this.kpiList = dto.kpiList;
    this.lastStopAppointmentStartDate = fromIsoToMoment(
      dto.lastStopAppointmentStartDate
    );
    this.lastStopTimeZone = dto.lastStopTimeZone;
    this.loadAxeleId = dto.loadAxeleId;
    this.loadDetentionTime = dto.loadDetentionTime;
    this.loadStatus = dto.loadStatus;
    this.loadSettlementStatus = dto.loadSettlementStatus;
    this.tripConnectionDetails = dto.tripConnectionDetails;
    this.missingDocuments = [];
    this.lastUpdatedStop = dto.lastUpdatedStop;
    this.loadType = dto.loadType;
    this.maxWeight = dto.maxWeight;
    this.netRevenue = dto.netRevenue;
    this.nextExpectedDepartureTime = dto.nextExpectedDepartureTime;
    this.nonInvoiceable = dto.nonInvoiceable;
    this.numberOfStops = dto.numberOfStops;
    this.onHold = !!dto.onHold;
    this.onHoldNote = dto.onHoldNote;
    this.operationType = dto.operationType;
    this.orderAxeleId = dto.orderAxeleId;
    this.organizationId = dto.organizationId;
    this.referenceId = dto.referenceId;
    this.revenue = dto.revenue;
    this.sealNumber = dto.sealNumber;
    this.chassisNumber = dto.chassisNumber;
    this.bookingNumber = dto.bookingNumber;
    this.appointmentNumber = dto.appointmentNumber;
    this.loadDirection = dto.loadDirection;
    this.containerNumber = dto.containerNumber;
    this.totalWeight = dto.totalWeight;
    this.seqNumber = dto.seqNumber;
    this.manifestBaseDetails = dto.manifestBaseDetails;
    this.startDateTime = fromIsoToMoment(dto.startDateTime);
    this.terminal = getNullableModel(TerminalShort, dto.terminal);
    this.trips = setupTrips(dto.tripConnectionDetails || []);
    this.tractors = setupAssignment(dto.tripConnectionDetails, 'tractor');
    this.trailers = setupAssignment(dto.tripConnectionDetails, 'trailer');
    this.dispatchers = setupAssignment(dto.tripConnectionDetails, 'dispatcher');
    this.dispatchTerminal = getNullableModel(
      TerminalShort,
      dto.dispatchTerminal
    );
    this.toLocationName = dto.toLocationName;
    this.warning = dto.warning;
    this.loadWarning = dto.loadWarning;

    this.dispatcher = new DispatcherShort(dto);
    this.createDate = dto.createDate;
    this.dispatchNotes = dto.dispatchNotes;
    this.commodityTypes = dto.commodityTypes;
    this.ediDetails = dto.ediDetails;
    this.manifestLoadIds = dto.manifestLoadIds;
    this.tags = dto.tags || [];
    this.source = dto.source;
    this.customers = dto.customers;
    this.terminals = dto.terminals;
    this.invoiceHasPayment = dto.invoiceHasPayment;
    this.loadPriority = dto?.loadPriority;
    this.canBeBrokered = dto?.canBeBrokered;
  }

  addFieldsFromLoadDetailsDTO(dto: any) {
    this.customerName = dto.customerDetails?.name || this.customerName;
    this.sealNumber =
      dto.loadDetails?.equipmentAdditionalDetails?.sealNumber ||
      this.sealNumber;
    this.referenceId = dto.loadDetails?.referenceId || this.referenceId;
  }
}
