import React from 'react';
import HelperLink from './HelperLink';
import { guides } from './constants';

export default function Guides() {
  return (
    <>
      {guides.map((guide) => (
        <HelperLink
          key={guide.key}
          text={guide.text}
          icon={guide.icon}
          hasDownloadOption={guide.hasDownloadOption}
          linkURL={guide.linkURL}
        />
      ))}
    </>
  );
}
