import MultilineCellWithIcon from '@/ui-kit/components/MultilineCellWithIcon';
import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';

const accountTitleStyle = {
  color: 'text.secondary',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '20px',
  ml: '8px',
};

const accountIconStyle = { color: 'primary.main' };

interface Props {
  icon: React.ReactNode;
  value: string;
  title: string;
}

export const AccountInfoItem: FC<Props> = ({ icon, title, value }) => {
  return (
    <Grid
      className="detail"
      sx={{
        display: 'flex',
        paddingBottom: '16px',
        alignItems: 'center',
      }}
    >
      <MultilineCellWithIcon
        title={title}
        titleStyle={accountTitleStyle}
        icon={icon}
        iconStyle={accountIconStyle}
        subtitle={''}
        subtitleStyle={undefined}
      />
      <span style={{ paddingLeft: '5px', paddingRight: '16px' }}>:</span>
      <Box className="value-wrapper">
        <Typography
          className="value"
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            color: 'primary.main',
            wordBreak: 'break-all',
          }}
        >
          {value}
        </Typography>
      </Box>
    </Grid>
  );
};
