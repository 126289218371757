import {
  AddBrokerRequest,
  DeleteBrokerAccountRequest,
  GetLoadTendersRequest,
  LoadTenderResponseDTO,
} from '../../models/DTOs/LoadTender/Requests';
import { Service } from './Service';
import { ServiceError } from './helperTypes';

export abstract class ILoadTenderService extends Service {
  abstract getLoadTenderList(
    request: GetLoadTendersRequest
  ): Promise<LoadTenderResponseDTO | null | ServiceError>;

  abstract getBrokerList(
    request: GetLoadTendersRequest
  ): Promise<LoadTenderResponseDTO | null | ServiceError>;

  abstract registerBroker(
    payload: AddBrokerRequest
  ): Promise<any | null | ServiceError>;

  abstract updateBroker(
    payload: AddBrokerRequest
  ): Promise<any | null | ServiceError>;

  abstract deleteBrokerAccount(
    request: DeleteBrokerAccountRequest
  ): Promise<any | null | ServiceError>;
}
