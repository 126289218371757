import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

type PropTypes = {
  id?: string;
  expanded?: boolean;
  errors?: {
    type: string;
    message: string;
  };
  borderColor?: string;
  titleRenderer: (expanded: boolean | string) => JSX.Element | string;
  subTitleRenderer?: (expanded: boolean | string) => JSX.Element | string;
  renderLabel?: (expanded: boolean | string) => JSX.Element;
  updateParentState?: (expanded: boolean) => void;
  children: JSX.Element;
  renderCaption?: (expanded: boolean | string) => JSX.Element | string;
};

export default function AxeleAccordion({
  id = '',
  expanded: isExpanded = false,
  errors,
  borderColor,
  titleRenderer,
  subTitleRenderer,
  renderLabel,
  updateParentState,
  renderCaption,
  children,
}: PropTypes) {
  const [expanded, setExpanded] = React.useState<boolean>(isExpanded);
  const hasError = errors && !!Object.keys(errors).length;

  const handleChange = (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded);
    if (typeof updateParentState === 'function') {
      updateParentState(newExpanded);
    }
  };

  React.useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  React.useEffect(() => {
    if (hasError) setExpanded(true);
  }, [errors, hasError]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={(theme) => ({
        borderRadius: 2,
        border:
          !expanded && !hasError
            ? 'none'
            : `1px solid ${
                hasError
                  ? theme.palette.error.main
                  : borderColor || theme.palette.primary.main
              }`,
      })}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id={id}
        sx={(theme) => ({
          borderRadius: 2,

          backgroundColor: !expanded
            ? '#f5f5f5 !important'
            : theme.palette.primary.contrast,
        })}
      >
        <Box
          id="Test"
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Box>
              <Box>
                <Typography
                  variant={'h7'}
                  sx={{
                    color: expanded ? 'primary.main' : 'text.primary',
                  }}
                >
                  {titleRenderer(expanded)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body3" sx={{ color: 'text.secondary' }}>
                  {subTitleRenderer && subTitleRenderer(expanded)}
                </Typography>
              </Box>
            </Box>
            {renderLabel && (
              <Box>
                {typeof renderLabel === 'function' && renderLabel(expanded)}
              </Box>
            )}
          </Box>
          {renderCaption?.(expanded)}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ mt: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}
