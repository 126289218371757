import { Close, PeopleOutline } from '@mui/icons-material';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { DriverShort } from '../../../../../models/common/modelsShort';
import { IconBtnWrapper } from '../../../../Ui/IconBtnWrapper/IconBtnWrapper';
import { Trip } from '../Models';
import RoutesController from '../RoutesController';
import DispatcherAssignmentToLoad from './DispatcherAssignment/DispatcherAssignToLoad';
import DriverAssignmentToLoad from './DriverAssignment/DriverAssignmentToLoad';
import TerminalAssignmentToLoad from './TerminalAssignment/TerminalAssignmentToLoad';
import TractorAssignmentToLoad from './TractorAssignment';
// import { TripContext } from '../../../../../views/trips/context/TripContext';
import { Grid, Tooltip, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import EventEmitter from '../../../../../EventEmitter/EventEmitter';
import EventTripAssignment from '../../../../../EventEmitter/Events/EventTripAssignment';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import {
  AssignDriverIcon,
  AssignTractorIcon,
} from '../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import TextInput from '../../../../../ui-kit/components/TextField';
import { ESecondaryDetailsPanelType } from '../../../../../views/dispatch2/constants/types';
import { useLoadPermission } from '../../../../hooks/useLoadPermission';
import {
  IDisableTripAssignment,
  IOpenSecondaryDetailsHandler,
} from '../../../constants/interface';
import CarrierAssignmentToLoad from './CarrierAssignment/CarrierAssignmentToLoad';
import TrailerAssignmentToLoad from './TrailerAssignment/TrailerAssignmentToLoad';
import { getTripAssignmentComponentStyles } from './styles/TripAssignmentComponent.styles';
import {
  TripAssignmentEntityType,
  UnassignActionsType,
  entityTypes,
  unAssignConfig,
} from './types';

const unassignMessage = 'Are you sure you want to remove this assignment?';

export interface TripAssignmentComponentProps
  extends Pick<IOpenSecondaryDetailsHandler, 'disableAssignment'> {
  originalTrip: Trip;
  trip: Trip;
  isAssignmentDisable: boolean;
  reasonAssignmentDisable?: string;
  isBrokered: boolean;
  panelType?: ESecondaryDetailsPanelType;
  setOpeningTripAssignment?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DisableTooltip = ({
  children,
  entityType,
  disableAssignment,
}: {
  children: JSX.Element;
  entityType: TripAssignmentEntityType;
} & Pick<IOpenSecondaryDetailsHandler, 'disableAssignment'>) => {
  const disableTripAssignment = disableAssignment?.[entityType];
  if (disableTripAssignment?.disabled && disableTripAssignment?.reason) {
    return (
      <Tooltip
        title={disableTripAssignment?.reason}
        placement="top-start"
        arrow
      >
        <Box component={'div'}>{children}</Box>
      </Tooltip>
    );
  }
  return children;
};

const TripAssignmentComponent = ({
  originalTrip,
  trip: { id },
  trip: tripData,
  isAssignmentDisable,
  isBrokered = false,
  panelType,
  setOpeningTripAssignment,
  disableAssignment,
}: TripAssignmentComponentProps): JSX.Element => {
  const theme = useTheme();
  const styles = getTripAssignmentComponentStyles(theme);
  const { getIsTerminalEnabled } = useRootStore();
  const [assignmentType, setAssignmentType] = useState<string | null>(null);
  const [unassignType, setUnassignType] = useState<entityTypes | null>();
  const [popupBody, setPopupBody] = useState<string>('');
  const [trip, setTrip] = useState<Trip>(tripData);
  const { driverGroup: driver, tractor, trailer, dispatcher, terminal } = trip;
  const { carrierDetails = null } = originalTrip;

  useEffect(() => {
    setTrip(tripData);
  }, [tripData]);

  useEffect(() => {
    setOpeningTripAssignment?.(assignmentType === null ? false : true);
  }, [assignmentType]);

  function updateTrip(trip: Trip, tripStatusShouldBeUpdated?: boolean) {
    setTrip(trip);
    // setUpdatedLoadId && setUpdatedLoadId(trip.loadId); @TODO Anoush, should be deleted <event changes>
    EventEmitter.send(
      new EventTripAssignment({
        trip: trip,
        isStatusUpdated: tripStatusShouldBeUpdated,
      })
    );
  }

  const handleTrailerUnassign = async () => {
    const res = await RoutesController.unassignTrailerFromLoad(
      trailer.id,
      trip.id
    );
    if (!res) return;
    trip.trailer = res.trailer;
    updateTrip(trip);
    setUnassignType(null);
  };

  const handleTractorUnassign = async () => {
    const res = await RoutesController.unassignTractorFromLoad(
      tractor.id,
      trip.id
    );
    if (!res) return;
    if (res.canNotDeleteMessage) {
      setPopupBody(res.canNotDeleteMessage);
    } else {
      trip.tractor = res.tractor;
      updateTrip(trip);
      setUnassignType(null);
    }
  };

  const handleDispatcherUnassign = async () => {
    const res = await RoutesController.unassignDispatcherFromLoad(trip.id);
    if (!res) return;
    if (res.canNotDeleteMessage) {
      setPopupBody(res.canNotDeleteMessage);
    } else {
      trip.dispatcher = null;
      updateTrip(trip);
      setUnassignType(null);
    }
  };

  const handleDriverUnassign = async () => {
    const res = await RoutesController.unassignDriverFromLoad(trip.id);
    if (!res) return;
    if (res.canNotDeleteMessage) {
      setPopupBody(res.canNotDeleteMessage);
    } else {
      trip.driverGroup = null;
      if (!tractor?.explicit) {
        trip.tractor = null;
      }
      if (!trailer?.explicit) {
        trip.trailer = null;
      }
      updateTrip(trip, true);
      setUnassignType(null);
    }
  };

  const handleCarrierUnassign = async () => {
    const res = await RoutesController.unassignCarrierFromLoad(trip.id);
    if (!res) return;
    if (res.canNotDeleteMessage) {
      setPopupBody(res.canNotDeleteMessage);
    } else {
      trip.driverGroup = null;
      if (!tractor?.explicit) {
        trip.tractor = null;
      }
      if (!trailer?.explicit) {
        trip.trailer = null;
      }
      updateTrip(trip, true);
      setUnassignType(null);
    }
  };

  const handleUnassignActions: UnassignActionsType = {
    TRAILER: handleTrailerUnassign,
    TRACTOR: handleTractorUnassign,
    DISPATCHER: handleDispatcherUnassign,
    DRIVER: handleDriverUnassign,
    CARRIER: handleCarrierUnassign,
  };

  const handleDriverAssign = (driverGroup, assets) => {
    trip.driverGroup = new DriverShort(driverGroup);
    trip.tractor = assets.tractor;
    trip.trailer = assets.trailer;
    trip.dispatcher = driverGroup.dispatcherId;
    updateTrip(trip, true);
  };

  const handleTerminalAssign = (terminal) => {
    trip.terminal = terminal;
    updateTrip(trip);
  };

  const handleTractorAssign = (newTractor) => {
    trip.tractor = { ...newTractor, explicit: true };
    updateTrip(trip);
  };

  const handleTrailerAssign = (newTrailer) => {
    trip.trailer = { ...newTrailer, explicit: true };
    updateTrip(trip);
  };

  const handleDispatcherAssign = (dispatcher) => {
    trip.dispatcher = dispatcher;
    updateTrip(trip);
  };

  const handleCarrierAssign = (newCarrier) => {
    trip.carrierDetails = newCarrier;
    updateTrip(trip, true);
  };

  const onUnassignTrailer = () => {
    if (closeTrailer) {
      setUnassignType(entityTypes.TRAILER);
    } else {
      setAssignmentType(entityTypes.TRAILER);
    }
  };

  const onUnassignTractor = () => {
    if (closeTractor) {
      setUnassignType(entityTypes.TRACTOR);
    } else {
      setAssignmentType(entityTypes.TRACTOR);
    }
  };

  const onUnassignCarrier = () => {
    if (closeCarrier) {
      setUnassignType(entityTypes.CARRIER);
    } else {
      setAssignmentType(entityTypes.CARRIER);
    }
  };

  const handlePopupAction = () => {
    handleUnassignActions[unassignType as keyof UnassignActionsType]();
  };

  function handleClose() {
    setPopupBody('');
    setUnassignType(null);
  }

  const closeTrailer =
    trailer?.explicit || trip.tripStatus === 'IN_TRANSIT' || trip.completed;

  const closeTractor =
    tractor?.explicit || trip.tripStatus === 'IN_TRANSIT' || trip.completed;

  const closeCarrier = !!carrierDetails?.id;
  const isDisabled = (type: string) =>
    !!assignmentType && assignmentType !== type;

  const { hasLoadEditPermission } = useLoadPermission();

  const { t, ready } = useTranslation();

  const getDisableByEntityType = (
    entityType: TripAssignmentEntityType
  ): IDisableTripAssignment | undefined => {
    return disableAssignment?.[entityType];
  };
  const AssignmentForm = () => {
    return (
      <Box className={'AssignmentFormRoot'} sx={styles.AssignmentFormRoot}>
        {getIsTerminalEnabled && assignmentType === 'TERMINAL' && (
          <Box sx={styles.AssignmentFormItem}>
            <TerminalAssignmentToLoad
              onAssign={handleTerminalAssign}
              tripId={id}
              onClose={() => setAssignmentType(null)}
              terminal={terminal}
            />
          </Box>
        )}
        {assignmentType === 'DISPATCHER' && (
          <Box sx={styles.AssignmentFormItem}>
            <DispatcherAssignmentToLoad
              trip={trip}
              onAssign={handleDispatcherAssign}
              onClose={() => setAssignmentType(null)}
            />
          </Box>
        )}

        {!isBrokered && (
          <Box sx={styles.AssignmentFormItem}>
            {assignmentType === 'DRIVER' && (
              <DriverAssignmentToLoad
                onAssign={handleDriverAssign}
                trip={trip}
                onClose={() => setAssignmentType(null)}
              />
            )}
            {assignmentType === 'TRACTOR' && (
              <TractorAssignmentToLoad
                onAssign={handleTractorAssign}
                trip={trip}
                onClose={() => setAssignmentType(null)}
              />
            )}
            {assignmentType === 'TRAILER' && (
              <TrailerAssignmentToLoad
                onAssign={handleTrailerAssign}
                trip={trip}
                onClose={() => setAssignmentType(null)}
              />
            )}
          </Box>
        )}
        {isBrokered && assignmentType === 'CARRIER' && (
          <Box sx={styles.AssignmentFormItem}>
            <CarrierAssignmentToLoad
              onAssign={handleCarrierAssign}
              trip={trip}
              onClose={() => setAssignmentType(null)}
              panelType={panelType}
            />
          </Box>
        )}
      </Box>
    );
  };

  if (ready) {
    return (
      <Box sx={styles.root}>
        {/* TERMINAL */}
        {getIsTerminalEnabled && (
          <Box component="span" sx={{ ...styles.btnWrapStyle }}>
            {terminal ? (
              <Box sx={styles.TextInputWrapper}>
                <TextInput
                  disabled={!hasLoadEditPermission || isAssignmentDisable}
                  InputProps={{ readOnly: true }}
                  label="Terminal"
                  variant="standard"
                  styleProps={styles.TextInput}
                  defaultValue={terminal.companyName}
                />
                <IconBtnWrapper
                  disabled={
                    isDisabled('TERMINAL') ||
                    !hasLoadEditPermission ||
                    trip.completed ||
                    trip.tripStatus === 'CANCELLED' ||
                    isAssignmentDisable
                  }
                  onClick={() => setAssignmentType('TERMINAL')}
                  sx={styles.IconBtnWrapper}
                >
                  <EditOutlined sx={{ width: 15 }} />
                </IconBtnWrapper>
              </Box>
            ) : (
              <ButtonImproved
                disabled={
                  isDisabled('TERMINAL') ||
                  !hasLoadEditPermission ||
                  trip.completed ||
                  isAssignmentDisable
                }
                styleProps={styles.btnStyle}
                onClick={() => setAssignmentType('TERMINAL')}
                size="medium"
                color="primary"
                label="Terminal"
                startIcon={
                  <IconsComponent
                    iconName="OtherHousesOutlined"
                    source="MUIcons"
                  />
                }
                variant="contained"
              />
            )}
          </Box>
        )}
        {/* TERMINAL */}

        {/* DISPATCHER */}

        <Box component="span" sx={styles.btnWrapStyle}>
          {dispatcher ? (
            <Box sx={styles.TextInputWrapper}>
              <TextInput
                disabled={!hasLoadEditPermission || isAssignmentDisable}
                InputProps={{ readOnly: true }}
                label="Dispatcher"
                variant="standard"
                styleProps={styles.TextInput}
                defaultValue={dispatcher.name}
              />
              <IconBtnWrapper
                disabled={
                  isDisabled('DISPATCHER') ||
                  !hasLoadEditPermission ||
                  isAssignmentDisable
                }
                onClick={() => setUnassignType(entityTypes.DISPATCHER)}
                sx={styles.IconBtnWrapper}
              >
                <Close sx={{ width: '20px' }} />
              </IconBtnWrapper>
            </Box>
          ) : (
            <ButtonImproved
              styleProps={styles.btnStyle}
              disabled={
                isDisabled('DISPATCHER') ||
                !hasLoadEditPermission ||
                isAssignmentDisable
              }
              onClick={() => setAssignmentType('DISPATCHER')}
              size="medium"
              color="primary"
              label={t('TripAssignComponentAssignDispatcher')}
              startIcon={
                <IconsComponent
                  iconName="SupportAgentOutlined"
                  source="MUIcons"
                />
              }
              variant="contained"
            />
          )}
        </Box>

        {/* DISPATCHER */}

        {getIsTerminalEnabled &&
          (assignmentType === 'TERMINAL' ||
            assignmentType === 'DISPATCHER') && <AssignmentForm />}
        {!isBrokered && (
          <>
            {/* DRIVER */}
            <Box component="span" sx={styles.btnWrapStyle}>
              <DisableTooltip
                entityType={TripAssignmentEntityType.DRIVER}
                disableAssignment={disableAssignment}
              >
                {driver ? (
                  <Box sx={styles.TextInputWrapper}>
                    <TextInput
                      disabled={
                        !hasLoadEditPermission ||
                        isAssignmentDisable ||
                        getDisableByEntityType(TripAssignmentEntityType.DRIVER)
                          ?.disabled
                      }
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <Box
                            component="span"
                            sx={styles.TextInputStartAdornment}
                          >
                            <AssignDriverIcon color="rgba(0, 17, 34, 0.6)" />
                          </Box>
                        ),
                      }}
                      label="Driver"
                      variant="standard"
                      styleProps={styles.TextInput}
                      defaultValue={driver.name || driver.displayName}
                    />
                    {trip.tripStatus !== 'IN_TRANSIT' &&
                      trip.tripStatus !== 'DISPATCHED' && (
                        <IconBtnWrapper
                          disabled={
                            isDisabled('DRIVER') ||
                            !hasLoadEditPermission ||
                            isAssignmentDisable ||
                            getDisableByEntityType(
                              TripAssignmentEntityType.DRIVER
                            )?.disabled
                          }
                          onClick={() => setUnassignType(entityTypes.DRIVER)}
                          sx={styles.IconBtnWrapper}
                        >
                          <Close sx={{ width: '20px' }} />
                        </IconBtnWrapper>
                      )}
                  </Box>
                ) : (
                  <ButtonImproved
                    disabled={
                      isDisabled('DRIVER') ||
                      !hasLoadEditPermission ||
                      isAssignmentDisable ||
                      getDisableByEntityType(TripAssignmentEntityType.DRIVER)
                        ?.disabled
                    }
                    styleProps={styles.btnStyle}
                    onClick={() => setAssignmentType('DRIVER')}
                    size="medium"
                    color="primary"
                    label={t('TripAssignComponentAssignDriver')}
                    startIcon={<AssignDriverIcon />}
                    variant="contained"
                  />
                )}
              </DisableTooltip>
            </Box>
            {/* DRIVER */}

            {!getIsTerminalEnabled &&
              (assignmentType === 'DISPATCHER' ||
                assignmentType === 'DRIVER') && <AssignmentForm />}

            {/* TRACTOR */}
            <Box component="span" sx={styles.btnWrapStyle}>
              {tractor ? (
                <Box sx={styles.TextInputWrapper}>
                  <TextInput
                    disabled={!hasLoadEditPermission || isAssignmentDisable}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <Box
                          component="span"
                          sx={styles.TextInputStartAdornment}
                        >
                          <AssignTractorIcon color="rgba(0, 17, 34, 0.6)" />
                        </Box>
                      ),
                    }}
                    label={`Tractor${tractor.implicit ? ' (default)' : ''}`}
                    variant="standard"
                    styleProps={styles.TextInput}
                    defaultValue={tractor.name}
                  />
                  <IconBtnWrapper
                    disabled={
                      isDisabled('TRACTOR') ||
                      !hasLoadEditPermission ||
                      isAssignmentDisable
                    }
                    onClick={onUnassignTractor}
                    sx={styles.IconBtnWrapper}
                  >
                    {closeTractor ? (
                      <Close sx={{ width: '20px' }} />
                    ) : (
                      <EditOutlined sx={{ width: 15 }} />
                    )}
                  </IconBtnWrapper>
                </Box>
              ) : (
                <ButtonImproved
                  disabled={
                    isDisabled('TRACTOR') ||
                    !hasLoadEditPermission ||
                    isAssignmentDisable
                  }
                  styleProps={styles.btnStyle}
                  size="medium"
                  color="primary"
                  label={t('TripAssignComponentAssignTractor')}
                  onClick={() => setAssignmentType('TRACTOR')}
                  startIcon={<AssignTractorIcon />}
                  variant="contained"
                />
              )}
            </Box>
            {/* TRACTOR */}

            {getIsTerminalEnabled &&
              (assignmentType === 'DRIVER' || assignmentType === 'TRACTOR') && (
                <AssignmentForm />
              )}

            {/* TRAILER */}
            <Box component="span" sx={styles.btnWrapStyle}>
              {trailer ? (
                <Box sx={styles.TextInputWrapper}>
                  <TextInput
                    disabled={!hasLoadEditPermission || isAssignmentDisable}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <Box
                          component="span"
                          sx={styles.TextInputStartAdornment}
                        >
                          <AssignTractorIcon color="rgba(0, 17, 34, 0.6)" />
                        </Box>
                      ),
                    }}
                    label={`Trailer${trailer.implicit ? ' (default)' : ''}`}
                    variant="standard"
                    styleProps={styles.TextInput}
                    defaultValue={trailer.name}
                  />
                  <IconBtnWrapper
                    disabled={
                      isDisabled('TRAILER') ||
                      !hasLoadEditPermission ||
                      isAssignmentDisable
                    }
                    onClick={onUnassignTrailer}
                    sx={styles.IconBtnWrapper}
                  >
                    {closeTrailer ? (
                      <Close sx={{ width: '20px' }} />
                    ) : (
                      <EditOutlined sx={{ width: 15 }} />
                    )}
                  </IconBtnWrapper>
                </Box>
              ) : (
                <ButtonImproved
                  disabled={
                    isDisabled('TRAILER') ||
                    !hasLoadEditPermission ||
                    isAssignmentDisable
                  }
                  styleProps={styles.btnStyle}
                  size="medium"
                  color="primary"
                  label={t('TripAssignComponentAssignTrailer')}
                  onClick={() => setAssignmentType('TRAILER')}
                  startIcon={<AssignTractorIcon />}
                  variant="contained"
                />
              )}
            </Box>
            {/* TRAILER */}
          </>
        )}
        {!getIsTerminalEnabled &&
          (assignmentType === 'TRACTOR' || assignmentType === 'TRAILER') && (
            <AssignmentForm />
          )}

        {/* CARRIER */}
        {isBrokered && (
          <Grid sx={{ width: '100%' }}>
            <Box sx={styles.btnCarrierWrapStyle}>
              {carrierDetails ? (
                <Box sx={styles.TextInputWrapper}>
                  <TextInput
                    disabled={!hasLoadEditPermission || isAssignmentDisable}
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Carrier"
                    variant="standard"
                    styleProps={styles.TextInput}
                    defaultValue={carrierDetails.name}
                  />
                  {trip.tripStatus !== 'IN_TRANSIT' &&
                    trip.tripStatus !== 'DISPATCHED' && (
                      <IconBtnWrapper
                        disabled={
                          isDisabled('CARRIER') ||
                          !hasLoadEditPermission ||
                          isAssignmentDisable
                        }
                        onClick={onUnassignCarrier}
                        sx={styles.IconBtnWrapper}
                      >
                        {closeCarrier ? (
                          <Close sx={{ width: '20px' }} />
                        ) : (
                          <EditOutlined sx={{ width: 15 }} />
                        )}
                      </IconBtnWrapper>
                    )}
                </Box>
              ) : (
                <ButtonImproved
                  disabled={
                    isDisabled('CARRIER') ||
                    !hasLoadEditPermission ||
                    isAssignmentDisable
                  }
                  styleProps={styles.btnStyle}
                  size="medium"
                  color="primary"
                  label="Assign Carrier"
                  onClick={() => setAssignmentType('CARRIER')}
                  startIcon={<PeopleOutline />}
                  variant="contained"
                />
              )}
            </Box>
          </Grid>
        )}
        {/* CARRIER */}

        {getIsTerminalEnabled &&
          (assignmentType === 'TRAILER' ||
            (isBrokered && assignmentType === 'CARRIER')) && <AssignmentForm />}

        {!getIsTerminalEnabled &&
          isBrokered &&
          assignmentType === 'CARRIER' && <AssignmentForm />}

        {unassignType && (
          <DeletePopup
            open
            title={unAssignConfig[unassignType as keyof unAssignConfig]} // @TODO: Anoush correct type
            body={popupBody || unassignMessage}
            onAction={popupBody ? undefined : handlePopupAction}
            onClose={handleClose}
            buttonText={t('yes')}
            cancelText={popupBody ? t('ok') : t('cancel')}
            backgroundColor={!!popupBody ? '#2B64CB' : ''} // @TODO: Anoush should be 'primary.main' instead of hardcoded color, should be checked in the ui-kit, why it is not taking
            width={'320px'}
          />
        )}
      </Box>
    );
  }
};

export default observer(TripAssignmentComponent);
