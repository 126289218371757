import React, { useEffect, useRef, useState } from 'react';
import AxeleDialog from '../../../../../ui-kit/components/AxeleDialog';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { financeIntegrationService } from '../../../../../api';
import {
  FactoringListRequest,
  GetFactorMappingRequest,
} from '../../../../../models/DTOs/Factoring/Requests';
import {
  ManageFactoreIntegration,
  RowActualData,
  RowData,
} from '../ManageFactoreIntegration';
import {
  apexMapping,
  customerFilters,
  driverFilters,
  OTRMapping,
  tafsMapping,
} from '../ManageFactoreIntegration/integrationData';
import {
  GetAxeleFactoreRequest,
  UpdateMapCustomer,
} from '../../../../../models/DTOs/Factoring/Factoring';
import { IRowActualData } from '../../../../../types/ELDManageTypes';
import { AxeleDriverMapping } from '../ManageFactoreIntegration/AxeleDriverMapping';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { gridPageSize } from '../../../../../utils';
import { constantFactorText } from '../constants';
import {
  APEX_PROVIDER_ID,
  OTR_PROVIDER_ID,
  TAFS_PROVIDER_ID,
} from '../../../../../subPages/invoices/constants';
import { MenuActionsIcon } from '../../../../../common/MenuActions/constants';
import { FactoringApexModel } from './FactoringApexModel';
import { t } from 'i18next';
export default function MappingFactoring({
  selectedTabData,
  selectedId,
  selectedProviderId,
  activeAccount,
}: MappingFactoringPageProps) {
  const orgId = new FactoringListRequest();
  const [customerTableData, setCustomerTableData] = useState([]);
  const [isUnlinkPopupOpen, setUnlinkPopupOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(selectedTabData);
  const [driverTableData, setDriverTableData] = useState([]);
  const [tableColumnsCustomer, setTableColumnsCustomer] = useState({});
  const [tableColumnsDriver, setTableColumnsDriver] = useState({});
  const [customerCheckList, setCustomerCheckList] = useState([]);
  const [statusCheckList, setStatusCheckList] = useState<boolean | undefined>();
  const [driverCheckList, setDriverCheckList] = useState([]);
  const [driverStatusCheckList, setDriverStatusCheckList] = useState<
    boolean | undefined
  >();
  const [render, setRender] = useState<number>(0);
  const [customerTotalRowCount, setCustomerTotalRowCount] = useState<number>(0);
  const [driverTotalRowCount, setDriverTotalRowCount] = useState<number>(0);
  const firstUpdate = useRef(true);
  const [customerRequestData, setCustomerRequestData] = useState({
    pageNumber: 1,
    pageSize: gridPageSize,
  });
  const [driverRequestData, setDriverRequestData] = useState({
    pageNumber: 1,
    pageSize: gridPageSize,
  });
  const [isApexModelView, setApexModelView] = useState<boolean>(false);

  const [apexMappingDriver, setApexMappingDriver] =
    useState<RowActualData | null>(null);

  useEffect(() => {
    mappingTableRefresh(selectedTab);
    if (selectedTab === 'customersMapping') {
      getCustomerMappings(selectedId, statusCheckList, customerCheckList);
      return;
    }
    getDriverMappings(selectedId, driverStatusCheckList, driverCheckList);
  }, [
    customerRequestData.pageNumber,
    selectedTab,
    driverRequestData.pageNumber,
  ]);

  const [unlinkList, setUnlinkList] = useState<Array<RowData>>([]);

  const getCustomerMappings = async (
    id: string,
    status?: any,
    checkBoxValue?: Array<number>,
    isFilter?: boolean,
    isRefreshEnable?: boolean
  ): Promise<void> => {
    const request = new GetFactorMappingRequest();
    request.organizationId = orgId.organizationId;
    request.factoringId = id;
    request.sort = 'customerName';
    request.pageNumber = !isFilter ? customerRequestData.pageNumber : 1;
    request.pageSize = !isFilter ? customerRequestData.pageSize : gridPageSize;
    request.isMapped = status ? status : statusCheckList;
    request.customerIds = checkBoxValue ? checkBoxValue : customerCheckList;

    const response = await financeIntegrationService.customerMappings(request);
    if (response?.content) {
      if (isRefreshEnable) {
        setCustomerTableData([]);
      }
      const newData: any = [];
      !isFilter
        ? newData.push(...customerTableData, ...response.content)
        : newData.push(...response.content);
      const newDataUniqueByKey = [
        ...new Map(
          newData.map((item: { [x: string]: any }) => [item['id'], item])
        ).values(),
      ];

      setCustomerTableData(newDataUniqueByKey);
      setCustomerTotalRowCount(response?.totalElements);
    }
  };

  const getCustomerintialMappingRefresh = async (id: string): Promise<void> => {
    const params: GetAxeleFactoreRequest = {
      organizationId: orgId.organizationId,
      id: id,
    };
    await financeIntegrationService.customerintialMappingRefresh(params);
    mappingTableRefresh(selectedTab);
  };
  const getCustomerResetMapbyid = async (): Promise<void> => {
    const params: GetAxeleFactoreRequest = {
      organizationId: orgId.organizationId,
      id: selectedId,
      customerMappingIds: unlinkList,
    };
    const response = await financeIntegrationService.customerResetMapbyid(
      params
    );
    if (response) {
      setUnlinkPopupOpen(false);
      getCustomerMappings(
        selectedId,
        statusCheckList,
        customerCheckList,
        true,
        true
      );
    }
    mappingTableRefresh(selectedTab);
  };

  const getDriverMappings = async (
    id: string,
    status?: any,
    check?: any,
    isFilter?: boolean,
    isRefreshEnable?: boolean
  ): Promise<void> => {
    const request = new GetFactorMappingRequest();
    request.organizationId = orgId.organizationId;
    request.factoringId = id;
    request.sort = 'firstname,lastname';
    request.pageNumber = !isFilter ? driverRequestData.pageNumber : 1;
    request.pageSize = !isFilter ? driverRequestData.pageSize : gridPageSize;
    request.isMapped = status ? status : driverStatusCheckList;
    request.driverIds = check ? check : driverCheckList;

    const response = await financeIntegrationService.driverMappings(request);
    if (response?.content) {
      if (isRefreshEnable) {
        setDriverTableData([]);
      }
      const newData: any = [];
      !isFilter
        ? newData.push(...driverTableData, ...response.content)
        : newData.push(...response.content);
      const newDataUniqueByKey = [
        ...new Map(
          newData.map((item: { [x: string]: any }) => [item['id'], item])
        ).values(),
      ];
      setDriverTableData(newDataUniqueByKey);
      setDriverTotalRowCount(response?.totalElements);
    }
  };
  const getDriverintialUpdatedDriverList = async (
    id: string
  ): Promise<void> => {
    try {
      const params: GetAxeleFactoreRequest = {
        organizationId: orgId.organizationId,
        id: id,
      };
      const response =
        await financeIntegrationService.driverintialUpdatedDriverList(params);
      mappingTableRefresh(selectedTab);
      setDriverTableData(response?.content);
      setDriverTotalRowCount(response?.totalElements);
    } catch (error) {
      throw error;
    }
  };

  const getDriverResetMapbyid = async (): Promise<void> => {
    const params: GetAxeleFactoreRequest = {
      organizationId: orgId.organizationId,
      id: selectedId,
      customerMappingIds: unlinkList,
    };
    const response = financeIntegrationService.driverResetMapbyid(params);
    if (response) {
      setUnlinkPopupOpen(false);
      getDriverMappings(
        selectedId,
        driverStatusCheckList,
        driverCheckList,
        true,
        true
      );
    }
    mappingTableRefresh(selectedTab);
  };

  const onManageTabChanged = (data: any): void => {
    setSelectedTab(data);
    mappingTableRefresh(data);
  };

  const refreshModalData = async (name: string): Promise<void> => {
    if (name === 'UpdateDriverList') {
      getDriverintialUpdatedDriverList(selectedId);
    } else if (name === 'UpdateMapCustomers') {
      const params: UpdateMapCustomer = {
        organizationId: orgId.organizationId,
        pageNumber: 1,
        pageSize: gridPageSize,
        id: selectedId,
      };
      try {
        const response = await financeIntegrationService.updateMapCustomer(
          params
        );
        if (response) {
          setCustomerTableData(response.content);
          setCustomerTotalRowCount(response?.totalElements);
          mappingTableRefresh(selectedTab);
        }
      } catch (error) {
        throw error;
      }
    } else {
      getCustomerintialMappingRefresh(selectedId);
    }
  };

  // Do not remove comment code, It is important for indeterminate case
  // useEffect(() => {
  //     console.log("MAPPING VIII")
  // }, [selectedTab])

  const callBackFilters = (object: callBackObject): void => {
    const idList: Array<number> = [];
    let idListStatus: boolean | undefined = undefined;
    if (object.type === 'Customer') {
      object.value.map((item: { id: number }) => {
        idList.push(item.id);
      });
      setCustomerCheckList(idList);
    } else if (object.type === 'Driver') {
      object.value.map((item: { driverId: number }) => {
        idList.push(item.driverId);
      });
      setDriverCheckList(idList);
    } else if (object.type === 'Status') {
      if (object.value?.length === 1) {
        if (object.value[0].name === 'Linked') {
          idListStatus = true;
        } else {
          idListStatus = false;
        }
      } else {
        idListStatus = undefined;
      }
      setDriverStatusCheckList(idListStatus);
      setStatusCheckList(idListStatus);
    }
  };

  useEffect(() => {
    if (!firstUpdate.current) {
      if (selectedTab === 'customersMapping') {
        getCustomerMappings(
          selectedId,
          statusCheckList,
          customerCheckList,
          true
        );
      } else {
        getDriverMappings(
          selectedId,
          driverStatusCheckList,
          driverCheckList,
          true
        );
      }
    } else {
      firstUpdate.current = false;
    }
  }, [
    statusCheckList,
    customerCheckList,
    driverStatusCheckList,
    driverCheckList,
  ]);

  const unlinkByIDs = (data: any): void => {
    let idList: any = '';
    data.map((item: any) => {
      const str = item.id + ',';
      idList = idList + str;
    });
    idList = idList.slice(0, -1);
    setUnlinkList(idList);
    setUnlinkPopupOpen(true);
  };

  const refreshTableData = () => {
    if (selectedTab === 'customersMapping') {
      getCustomerMappings(selectedId);
    } else {
      getDriverMappings(selectedId);
    }
  };

  const unMapList = () => {
    if (selectedTab === 'customersMapping') {
      getCustomerResetMapbyid();
    } else {
      getDriverResetMapbyid();
    }
    setUnlinkPopupOpen(false);
  };

  const mappingTableRefresh = (selectedTab: string) => {
    const apexMappingHeader = {
      field: 'FactoringCustomer',
      headerName:
        selectedTab === 'customersMapping'
          ? 'Factoring Customer'
          : 'Funding account',
      flex: 1,
      renderCell: (params: {
        row: {
          mappingName: string;
          dropDownValue: string;
          factoringCustomerMCs: string;
          axeleDriverName: string;
          axeleId: number;
        };
      }) => {
        return (
          <>
            {params.row.axeleDriverName || params.row.axeleId ? (
              <Typography variant="body2" gutterBottom>
                {params.row.axeleDriverName}
              </Typography>
            ) : (
              <AxeleDriverMapping
                tabSelected={selectedTab}
                rowDetails={params.row}
                viewTable={'apexMapping'}
                mappingInfo={(data: {
                  mappingName: any;
                  mappingCode: string;
                }) => {
                  params.row.factoringCustomerMCs = data.mappingCode;
                  params.row.dropDownValue = data.mappingName;
                  params.row.mappingName = data.mappingName;
                  getCustomerMappings(selectedId, [], '');
                  getDriverMappings(selectedId, [], '');
                }}
                mappingTableUpdate={(status: boolean) => {
                  mappingTableUpdateCallBack(status);
                }}
              />
            )}
          </>
        );
      },
    };
    const apexFundingMapping = {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: {
        row: {
          mappingName: string;
          dropDownValue: string;
          factoringCustomerMCs: string;
          axeleDriverName: string;
          axeleId: number;
        };
      }) => {
        return (
          <>
            <Tooltip title="Actions">
              <IconButton
                onClick={() => {
                  setApexModelView(true);
                  setApexMappingDriver(params?.row);
                }}
              >
                <MenuActionsIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    };
    const tafsMappingHeader = {
      field: 'fundingAccountCode',
      headerName: 'Factoring Driver',
      flex: 1,
      renderCell: (params: {
        row: {
          mappingName: string;
          dropDownValue: string;
          mappingId: string;
          factoringCustomerMCs: string;
          axeleId: string;
          axeleDriverName: string;
          AxeleId: number;
        };
      }) => {
        return (
          <>
            {params.row.axeleDriverName || params.row.axeleId ? (
              <Typography variant="body2" gutterBottom>
                {params.row.axeleDriverName}
              </Typography>
            ) : (
              <AxeleDriverMapping
                tabSelected={selectedTab}
                rowDetails={params.row}
                viewTable={'TafsMapping'}
                mappingInfo={(data: {
                  mappingName: string;
                  mappingId: string;
                }) => {
                  params.row.mappingId = data.mappingId;
                  params.row.dropDownValue = data.mappingName;
                  params.row.mappingName = data.mappingName;
                  getCustomerMappings(selectedId, [], '');
                }}
                mappingTableUpdate={(status: boolean) => {
                  mappingTableUpdateCallBack(status);
                }}
              />
            )}
          </>
        );
      },
    };

    const OTRMappingHeader = {
      field: 'fundingAccountCode',
      headerName: t('customerName'),
      flex: 1,
      renderCell: (params: {
        row: {
          mappingName: string;
          dropDownValue: string;
          factoringCustomerMCs: string;
          axeleId: string;
          axeleDriverName: string;
          AxeleId: number;
        };
      }) => {
        return (
          <>
            {params.row.axeleDriverName || params.row.axeleId ? (
              <Typography variant="body2" gutterBottom>
                {params.row.axeleDriverName}
              </Typography>
            ) : (
              <AxeleDriverMapping
                tabSelected={selectedTabData}
                rowDetails={params.row}
                viewTable={'apexMapping'}
                mappingInfo={(data: {
                  mappingName: string;
                  mappingCode: string;
                }) => {
                  params.row.factoringCustomerMCs = data.mappingCode;
                  params.row.dropDownValue = data.mappingName;
                  params.row.mappingName = data.mappingName;
                  getCustomerMappings(selectedId, [], '');
                }}
                mappingTableUpdate={(status: boolean) => {
                  mappingTableUpdateCallBack(status);
                }}
              />
            )}
          </>
        );
      },
    };
    if (selectedProviderId === APEX_PROVIDER_ID) {
      apexMapping.customers[2] = apexMappingHeader;
      apexMapping.driver[5] = apexFundingMapping;
      setTableColumnsCustomer(apexMapping.customers);
      setTableColumnsDriver(apexMapping.driver);
    } else if (selectedProviderId === TAFS_PROVIDER_ID) {
      tafsMapping.driver[1] = tafsMappingHeader;
      setTableColumnsCustomer([]);
      setTableColumnsDriver([]);
      setTableColumnsCustomer(tafsMapping.customers);
      setTableColumnsDriver(tafsMapping.driver);
      setRender((prev) => prev + 1);
    } else if (selectedProviderId === OTR_PROVIDER_ID) {
      OTRMapping.customers[2] = OTRMappingHeader;
      setTableColumnsCustomer(OTRMapping.customers);
      setTableColumnsDriver(OTRMapping.driver);
    }
    sessionStorage.setItem('FactoringCustomerID', selectedId);
    // Do not remove commented item, It is very important to understand edge case if come.
    // getCustomerMappings(selectedId);
    // getCustomermappingsDropDownList(selectedId);
    // getDrivermappingsDropDownList();
    // getDriverMappings(selectedId);
    // setAxeleDialogView(true)
  };
  const mappingTableUpdateCallBack = (status: boolean) => {
    if (status) {
      mappingTableRefresh(selectedTab);
    }
  };

  const handleDriverScroll = () => {
    if (driverTableData.length < driverTotalRowCount - 1) {
      const pageNumber = driverRequestData.pageNumber + 1;
      const updatedRequestData = {
        ...driverRequestData,
        pageSize: gridPageSize,
        pageNumber: pageNumber,
      };
      setDriverRequestData(updatedRequestData);
    }
  };

  const handleCustomerScroll = () => {
    if (customerTableData.length < customerTotalRowCount - 1) {
      const pageNumber = customerRequestData.pageNumber + 1;
      const updatedRequestData = {
        ...customerRequestData,
        pageSize: gridPageSize,
        pageNumber: pageNumber,
      };
      setCustomerRequestData(updatedRequestData);
    }
  };

  const currentTheme = useTheme();

  const theme = createTheme(currentTheme, {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
    },
  });
  return (
    <Box className="selected-customer-block">
      <ThemeProvider theme={theme}>
        <DeletePopup
          width="25%"
          open={isUnlinkPopupOpen}
          onClose={() => setUnlinkPopupOpen(false)}
          onAction={() => unMapList()}
          title={constantFactorText.unlinkTitle}
          body={constantFactorText.unlinkDescription}
          buttonText={constantFactorText.unlinkSaveText}
        />
      </ThemeProvider>
      <ManageFactoreIntegration
        tableColumnsCustomer={tableColumnsCustomer}
        tableColumnsDriver={tableColumnsDriver}
        tableData={
          selectedTab === 'customersMapping'
            ? customerTableData
            : driverTableData
        }
        filters={
          selectedTab === 'customersMapping' ? customerFilters : driverFilters
        }
        onManageTabChanged={onManageTabChanged}
        selectedTab={selectedTab}
        selectedId={selectedId}
        refreshModalData={refreshModalData}
        callBackFilters={callBackFilters}
        unlinkByIDs={unlinkByIDs}
        selectedProviderId={selectedProviderId}
        refreshTableData={refreshTableData}
        handleCustomerScroll={handleCustomerScroll}
        handleDriverScroll={handleDriverScroll}
        customerTotalRowCount={customerTotalRowCount}
        driverTotalRowCount={driverTotalRowCount}
        render={render}
        activeAccount={activeAccount}
      />
      {isApexModelView && apexMappingDriver && (
        <AxeleDialog
          open={isApexModelView}
          titleText={'Edit Driver Mapping'}
          handleClose={() => {
            setApexModelView(false);
          }}
          renderComponent={
            <FactoringApexModel
              successCallBack={(data) => {
                const driverList: [] = [];
                driverTableData?.map((record) => {
                  if (record?.id === data?.id) {
                    record = data;
                  }
                  driverList.push(record);
                });
                setDriverTableData(driverList);
                setApexModelView(false);
                setApexMappingDriver(null);
              }}
              handleClose={() => {
                setApexModelView(false);
                setApexMappingDriver(null);
              }}
              selectData={apexMappingDriver}
            />
          }
          dialogStyles={{
            width: 600,
            margin: '0 auto',
            top: '-10%',
            '& .MuiPaper-root ': {
              marginBottom: '20px',
            },
            '& .MuiPaper-root': {
              minHeight: 'auto !important',
            },
            '& .MuiDialogContent-root': {
              height: 'auto',
            },
          }}
        />
      )}
    </Box>
  );
}

export interface MappingFactoringPageProps {
  selectedTabData: string;
  titleText: string;
  subTitleText: string;
  selectedId: string;
  selectedProviderId: number;
  activeAccount: boolean;
}

export interface mappingFactoringInterface {
  content: Array<IRowActualData>;
}

export interface callBackObject {
  type: string;
  value: Array<any>;
}
