import { PdfViewer } from '@components/ui-kit/file-viewer';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../store/root.context';

interface IProps {
  file?: File;
  sx?: SxProps;
}

const RateConFileViewer = observer(({ file, sx }: IProps) => {
  const {
    myLoadStore: { createLoadRateConFile, showRateConPdf },
  } = useStores();

  if (!createLoadRateConFile || !showRateConPdf) return <></>;
  return (
    <Box sx={sx}>
      <PdfViewer file={createLoadRateConFile} />
    </Box>
  );
});

export { RateConFileViewer };
