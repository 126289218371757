import {
  FactoringSummary,
  FactoringListRequest,
  ActivateFactoringCustomerRequest,
  DeactivateFactorinRequest,
  GetAxeleFactoreRequest,
  UpdateMapCustomer,
  GetCustomerRequest,
  GetDriverRequest,
  GetFactorMappingRequest,
} from '../../models';
import {
  ActivateCustomerRequest,
  DriverSummaryResponseDTO,
  GetDriverSummaryFactoreRequest,
} from '../../models/DTOs/ELD/Requests';
import { FactorePaginatedRoleList } from '../../views/settings/integrations/Finance/FactoreSelectedCustomer/Refresh';
import { TFactorAddPayload } from '../../views/settings/integrations/Finance/FactoreSidedrawer/FactoreUtil';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IFactoringService extends Service {
  abstract getFactoringList(
    requestData: FactoringListRequest
  ): Promise<FactoringSummary[]>;
  abstract activateFactoringProvider(
    requestData: TFactorAddPayload
  ): Promise<ActivateFactoringCustomerRequest | null | ServiceError>;
  abstract activateFactoringProviderUpdate(
    requestData: TFactorAddPayload
  ): Promise<ActivateFactoringCustomerRequest | null | ServiceError>;
  abstract factoringDeactive(
    requestData: ActivateFactoringCustomerRequest
  ): Promise<ActivateFactoringCustomerRequest | null | ServiceError>;
  abstract factoringArchived(
    requestData: ActivateFactoringCustomerRequest
  ): Promise<ActivateFactoringCustomerRequest | null | ServiceError>;

  abstract deleteFactoring(
    requestData: DeactivateFactorinRequest
  ): Promise<DeactivateFactorinRequest | null | ServiceError>;

  abstract getCustomerList(
    requestData: GetCustomerRequest
  ): Promise<GetCustomerRequest | null | ServiceError>;

  abstract getDriverList(
    requestData: GetDriverRequest
  ): Promise<GetCustomerRequest | null | ServiceError>;

  abstract customerMappings(
    query: GetFactorMappingRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract customermappingsOptionList(
    requestData: GetDriverSummaryFactoreRequest
  ): Promise<DriverSummaryResponseDTO | null | ServiceError>;

  abstract customermappingsDropDownList(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract customermappingsRefreshList(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract customerintialMappingRefresh(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract customerFactoringMapingRecord(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract customerRefreshandmapbyid(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract activateProvider(
    requestData: ActivateCustomerRequest
  ): Promise<ActivateCustomerRequest | null | ServiceError>;

  abstract driverMappings(
    requestData: GetFactorMappingRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract drivermappingsOptionList(
    requestData: GetDriverSummaryFactoreRequest
  ): Promise<DriverSummaryResponseDTO | null | ServiceError>;

  abstract drivermappingsDropDownList(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract drivermappingsRefreshList(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract driverintialMappingRefresh(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract driverFactoringMapingRecord(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract driverRefreshandmapbyid(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;
  abstract driverResetMapbyid(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract driverintialUpdatedDriverList(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;
  abstract customerResetMapbyid(
    requestData: GetAxeleFactoreRequest
  ): Promise<FactorePaginatedRoleList | null | ServiceError>;

  abstract updateMapCustomer(
    requestData: UpdateMapCustomer
  ): Promise<any | null | ServiceError>;
}
