import {
  GetFactoringStaticRequest,
  GetInvoiceTermsRequest,
  GetStatesRequest,
} from '../../models';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IStaticService extends Service {
  abstract getStates(
    requestData: GetStatesRequest
  ): Promise<any | ServiceError>;

  abstract getInvoiceTerms(
    requestData: GetInvoiceTermsRequest
  ): Promise<any | ServiceError>;

  abstract getFactoringStatic(
    requestData: GetFactoringStaticRequest
  ): Promise<any | ServiceError>;
}
