import React, { useEffect, useRef, useState } from 'react';
import { GridCellEditCommitParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react';
import { SquareIcon } from '../../../../../ui-kit/components/Assets';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import Box from '@mui/material/Box';
import { Stack, Switch, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import Table from '../Common/Table';
import {
  expenseLoadTableHeadersMap,
  fontFamily,
  importTableHeadersMap,
} from '../../constants';
import ImportActions from '../../Import';
import {
  IMPORT_EXPENSE_NAME,
  IMPORT_CONTRACT_RATE_LINE_ITEM_NAME,
  IMPORT_LOAD_NAME,
  useImport,
} from '../../../../../contexts/ImportContext';
import Footer from '../Common/Footer';
import {
  addressTypeFieldNames,
  getNextModalTitle,
  keepAlwaysValid,
} from './constants';
import { fileTypeCodesMap } from '../Landing/constants';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { t } from 'i18next';

const AntSwitch = styled(Switch)(() => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    // transition: theme.transitions.create(['width'], {
    //   duration: 200,
    // }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default observer(function Step3() {
  const apiRef = useGridApiRef();
  const {
    currentEntity: { routeName, name: currentEntityName, dictionary },
    sessionId,
    handleBack,
    handleNext,
    setKpiInfo,
    selectedFileType,
    handleImportModalClose,
    finishImportCb,
  } = useImport();
  const { isTerminalEnabled } = useRootStore();
  const isError = useRef(false);
  const isWarning = useRef(false);
  const pageNumber = useRef(1);
  const last = useRef(false);
  const refMap = {
    error: isError,
    warning: isWarning,
  };
  const [totalErrors, setTotalErrors] = useState(0);
  const [totalWarnings, setTotalWarnings] = useState(0);
  const [totalElementsCount, setTotalElementsCount] = useState(0);
  const [tableData, setTableData] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [shouldShowWarningPopup, setShouldShowWarningPopup] = useState(false);
  const [shouldShowWarningPopupOnNext, setShouldShowWarningPopupOnNext] =
    useState(false);

  // set table data, isLast, errors, warnings
  const setImportedData = (result: any) => {
    const { content, totalErrors, totalWarnings, last: isLast } = result;
    last.current = isLast;
    setTotalErrors(totalErrors);
    setTotalWarnings(totalWarnings);
    setTableData(content);
  };

  useEffect(() => {
    const getImportedData = async () => {
      const result = await ImportActions.getImportedData(
        routeName,
        dictionary,
        sessionId
      );
      if (result) {
        const { totalElements } = result;
        setTotalElementsCount(totalElements);
        setImportedData(result);
      }
    };
    getImportedData();
  }, []);

  if (!tableData) {
    return null;
  }

  const handleCellEditCommit = async (e: GridCellEditCommitParams) => {
    // disable editing if in edit mode user submitted import
    if (submitting) {
      return;
    }
    const { id, field, value } = e;
    const currentRow = apiRef.current.getRow(id);
    // some cells always should have predefined isValid = true. defined in constants
    const keepValidOnEditing =
      keepAlwaysValid[currentEntityName][field] || false;
    if (currentRow) {
      const cellData: Partial<{ [key: string]: any }> = {
        id,
        key: sessionId,
        columnName: field,
        prevValue: currentRow[field],
        pageNumber: pageNumber.current,
        isValid: keepValidOnEditing
          ? keepValidOnEditing
          : !currentRow.errors[id][field] && !currentRow.warnings[id][field],
      };
      if (isWarning.current) {
        cellData.isWarning = isWarning.current;
      }
      if (isError.current) {
        cellData.isError = isError.current;
      }

      // if prev value === new value disable request
      if (currentRow[field] !== value) {
        let result;
        // address here is specific case. In case of address we have separate API. In case of address we send object instead of primitive string
        if (addressTypeFieldNames.includes(field) && value) {
          cellData.addressDTO = value;
          result = await ImportActions.changeAddressCellValue(
            routeName,
            dictionary,
            cellData
          );
        } else {
          cellData.newValue = value;
          result = await ImportActions.changeCellValue(
            routeName,
            dictionary,
            cellData
          );
        }
        if (result) {
          const updatedResult = ImportActions.generateTableStructure(result);
          setImportedData(updatedResult);
        }
      }
    }
  };

  const handleOnGoNext = async () => {
    if (!submitting) {
      setShouldShowWarningPopupOnNext(false);
      setSubmitting(true);
      if (currentEntityName === IMPORT_CONTRACT_RATE_LINE_ITEM_NAME) {
        const result = await ImportActions.getImportedLineItems(sessionId);
        if (result) {
          finishImportCb?.(result);
          handleImportModalClose();
        }
      } else {
        const result = await ImportActions.generateFinalData(
          routeName,
          dictionary,
          sessionId
        );
        if (result) {
          setKpiInfo(result);
          handleNext();
        }
      }
      setSubmitting(false);
    }
  };

  const handleFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const refName = e.target.name as 'error' | 'warning';
    refMap[refName].current = e.target.checked;
    pageNumber.current = 1;
    const result = await ImportActions.getImportedData(
      routeName,
      dictionary,
      sessionId,
      {
        isError: isError.current,
        isWarning: isWarning.current,
        pageNumber: pageNumber.current,
      }
    );
    if (result) {
      const { content, last: isLast } = result;
      last.current = isLast;
      setTableData(content);
    }
  };

  const handleScroll = async () => {
    if (!last.current) {
      pageNumber.current++;
      const result = await ImportActions.getImportedData(
        routeName,
        dictionary,
        sessionId,
        {
          isError: isError.current,
          isWarning: isWarning.current,
          pageNumber: pageNumber.current,
        }
      );
      if (result) {
        last.current = result.last;
        setTableData((oldData: any) => [...oldData, ...result.content]);
      }
    }
  };

  const handleBackClicked = () => {
    setShouldShowWarningPopup(true);
  };

  const handleOnGoNextClicked = async () => {
    if (totalErrors > 0 || totalWarnings > 0) {
      setShouldShowWarningPopupOnNext(true);
    } else {
      await handleOnGoNext();
    }
  };

  const tableColumns = [
    IMPORT_EXPENSE_NAME,
    IMPORT_LOAD_NAME,
    IMPORT_CONTRACT_RATE_LINE_ITEM_NAME,
  ].includes(currentEntityName)
    ? expenseLoadTableHeadersMap[fileTypeCodesMap[selectedFileType]]
    : importTableHeadersMap[currentEntityName];

  return (
    <>
      {shouldShowWarningPopup && (
        <DeletePopup
          open={shouldShowWarningPopup}
          onClose={() => setShouldShowWarningPopup(false)}
          onAction={handleBack}
          title={
            ' If you go one step back you will lose all the changes done on this page. Still, want to go back?'
          }
          buttonText={t('yes')}
          cancelText={t('no')}
          width={'20%'}
        />
      )}
      {shouldShowWarningPopupOnNext && (
        <DeletePopup
          open={shouldShowWarningPopupOnNext}
          onClose={() => setShouldShowWarningPopupOnNext(false)}
          onAction={handleOnGoNext}
          title={getNextModalTitle(currentEntityName)}
          buttonText={'Ignore'}
          cancelText={t('yes')}
          width={'30%'}
        />
      )}
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          <Stack sx={{ mr: 2 }} direction="row" spacing={1} alignItems="center">
            <AntSwitch
              name={'error'}
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={handleFilterChange}
            />
            <Typography
              sx={{
                color: 'rgba(0, 17, 34, 0.75)',
                fontFamily,
                fontWeight: 400,
              }}
            >
              Error Cells ({totalErrors})
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              name={'warning'}
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={handleFilterChange}
            />
            <Typography
              sx={{
                color: 'rgba(0, 17, 34, 0.75)',
                fontFamily,
                fontWeight: 400,
              }}
            >
              Warning Cells ({totalWarnings})
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <SquareIcon opacity={'0.5'} />
          <Typography
            sx={{
              color: 'rgba(0, 17, 34, 0.75)',
              fontSize: 14,
              fontFamily,
              mr: 1,
              ml: 1,
            }}
          >
            Error
          </Typography>
          <SquareIcon color={'#FFA726'} opacity={'0.5'} />
          <Typography
            sx={{
              color: 'rgba(0, 17, 34, 0.75)',
              fontSize: 14,
              fontFamily,
              ml: 1,
            }}
          >
            Warning
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Box sx={{ width: '100%', mt: 3 }}>
          <Table
            apiRef={apiRef}
            tableData={tableData}
            tableColumns={tableColumns}
            handleCellEditCommit={handleCellEditCommit}
            handleScroll={handleScroll}
            totalElementsCount={totalElementsCount}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  terminal: isTerminalEnabled,
                },
              },
            }}
          />
        </Box>
      </Box>
      <Footer
        onBack={handleBackClicked}
        onNext={handleOnGoNextClicked}
        nextButtonTitle={'Import'}
        showNextIcon={false}
      />
    </>
  );
});
