import { array, date, mixed, object } from 'yup';
import { EFieldKey, getRenderedFields } from './components/Form/UpdateStopForm';
import moment from 'moment';
import { transformValidationDate } from '../RelayStopPopup';

export const isLoggedSchema = mixed().test(
  'is-logged',
  'The activity is not logged',
  (value, context) => {
    const collectLoadUnloadTiming =
      context.from[1].value.collectLoadUnloadTiming;
    const currentStopId = context.from[0].value.id;
    const currentFieldName = context.path.split('.').pop();
    const stops = context.from[1].value.stops;
    const currentStopIndex = stops.findIndex(({ id }) => id === currentStopId);
    const currentStop = stops[currentStopIndex];
    const currentFieldIndex = getRenderedFields(
      currentStop,
      collectLoadUnloadTiming
    ).findIndex(([f]) => f === currentFieldName);
    const renderedFields = getRenderedFields(
      currentStop,
      collectLoadUnloadTiming
    );
    if (!renderedFields.map(([f]) => f).includes(currentFieldName)) return true;
    if (value) return true;
    return !stops.some((stop, stopIndex) => {
      if (currentStopIndex > stopIndex) return false;
      if (currentStopIndex < stopIndex) {
        return getRenderedFields(stop, collectLoadUnloadTiming).some(
          ([fieldName]) => stop[fieldName]
        );
      }
      return getRenderedFields(stop, collectLoadUnloadTiming).some(
        ([f], fieldIndex) => {
          return fieldIndex > currentFieldIndex && stop[f];
        }
      );
    });
  }
);

export const dateSchema = date()
  .transform(transformValidationDate)
  .nullable()
  .notRequired();

export const isValidDateSchema = dateSchema
  .test(
    'not-in-future',
    'Date and Time cannot be in future',
    (value?: Date | null) => {
      return !value || value < new Date();
    }
  )
  .test('required', 'Date-time is required', (value?: Date | null, context) => {
    const currentFieldName = context.path.split('.').pop().replace(/Date$/, '');
    const stops = context.from[1].value.stops;
    const currentStopId = context.from[0].value.id;
    const currentStopIndex = stops.findIndex(({ id }) => id === currentStopId);
    const currentStop = stops[currentStopIndex];
    const renderedFields = getRenderedFields(
      currentStop,
      context.from[1].value.collectLoadUnloadTiming
    );
    if (!renderedFields.map(([f]) => f).includes(currentFieldName)) return true;
    return !context.parent[currentFieldName] || !!value;
  })
  .test('is-valid', 'Date is not valid in the sequence.', (value, context) => {
    const currentStopId = context.from[0].value.id;
    const currentFieldName = context.path.split('.').pop().replace(/Date$/, '');
    const stops = context.from[1].value.stops;
    const currentStopIndex = stops.findIndex(({ id }) => id === currentStopId);
    const currentStop = stops[currentStopIndex];
    const renderedFields = getRenderedFields(
      currentStop,
      context.from[1].value.collectLoadUnloadTiming
    );
    const currentFieldIndex = renderedFields.findIndex(
      ([f]) => f === currentFieldName
    );
    if (!renderedFields.map(([f]) => f).includes(currentFieldName)) return true;
    if (currentFieldIndex < 0) return true;
    if (currentFieldIndex === 0) {
      if (currentStopIndex === 0) return true;
      const prevStopIndex = currentStopIndex - 1;
      const prevValue = stops[prevStopIndex][`${EFieldKey.MarkDeparture}Date`];
      if (!value) return true;
      if (!prevValue) return false;
      return moment(value).endOf('minute') >= moment(prevValue);
    }
    const prevField = renderedFields[currentFieldIndex - 1][0];
    const prevFieldDate = `${prevField}Date`;
    const prevValue = currentStop[prevFieldDate];
    if (!value) return true;
    if (!prevValue) return false;
    return moment(value).endOf('minute') >= moment(prevValue);
  })
  .typeError('Date-time is not valid');

export const validationSchema = object().shape({
  stops: array().of(
    object().shape({
      [`${EFieldKey.MarkArrival}Date`]: isValidDateSchema,
      [`${EFieldKey.LoadingStarted}Date`]: isValidDateSchema,
      [`${EFieldKey.LoadingDone}Date`]: isValidDateSchema,
      [`${EFieldKey.MarkDeparture}Date`]: isValidDateSchema,

      [EFieldKey.MarkArrival]: isLoggedSchema,
      [EFieldKey.LoadingStarted]: isLoggedSchema,
      [EFieldKey.LoadingDone]: isLoggedSchema,
      [EFieldKey.MarkDeparture]: isLoggedSchema,
    })
  ),
});
