import { useState } from 'react';
import CustomerDetailsPanel from './CustomerDetailsPanel';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
import { CustomerSummaryTableRow } from '../../../subPages/customers/models/customer';
import {
  DeleteCustomerRequest,
  CustomerAssignedToLoadRequest,
} from '../../../../src/models';
import { customerService } from '../../../api';
import { deletePopupConfig } from '../../../subPages/customers/constants';
import { t } from 'i18next';
export const CustomerDetailsPanelWrapper = ({
  panelTitle,
  data,
  onCreated,
  onUpdated,
  onClose,
  onDeleted,
  isCreatePanelOpen,
  httpErrors,
  updateCustomerSummary,
  openDetailsPanel,
  handleClose,
  deleteCustomer,
  isGlobal = false,
}: any) => {
  const [deletePopupState, setDeletePopupState] = useState<{
    open: boolean;
    isCustomerAssignedToLoad: boolean;
    customerId: string;
  }>({ open: false, isCustomerAssignedToLoad: false, customerId: '' });

  const handleUpdatedWrapper = async (
    customer: CustomerSummaryTableRow
  ): Promise<void> => {
    onUpdated(customer);
  };

  const handleDeleteWrapper = async (id: string): Promise<void> => {
    const customerAssignedToLoadRequest = new CustomerAssignedToLoadRequest({
      customerId: id,
    });
    onDeleted(id);
    try {
      const isCustomerAssignedToLoad: { customerAssignedToLoad: boolean } =
        await customerService.customerAssignedToLoad(
          customerAssignedToLoadRequest
        );
      setDeletePopupState({
        open: true,
        isCustomerAssignedToLoad:
          isCustomerAssignedToLoad.customerAssignedToLoad,
        customerId: id,
      });
    } catch (error) {
      return;
    }
    handleClose();
  };

  const deleteCustomerWrapper = async () => {
    const { customerId, isCustomerAssignedToLoad } = deletePopupState;

    if (!isCustomerAssignedToLoad) {
      const requestData = new DeleteCustomerRequest({ id: customerId });
      try {
        await customerService.deleteCustomer(requestData);
      } catch (error) {
        return;
      }
      deleteCustomer(customerId);
    }
    setDeletePopupState({
      open: false,
      isCustomerAssignedToLoad: false,
      customerId: '',
    });
  };

  return (
    <>
      {openDetailsPanel() && (
        <CustomerDetailsPanel
          panelTitle={panelTitle}
          data={data}
          onCreated={onCreated}
          onUpdated={handleUpdatedWrapper}
          onDeleted={handleDeleteWrapper}
          onClose={onClose}
          isCreatePanelOpen={isCreatePanelOpen}
          httpErrors={httpErrors}
          updateCustomerSummary={updateCustomerSummary}
          isGlobal={isGlobal}
        />
      )}
      {deletePopupState.open && (
        <DeletePopup
          open={deletePopupState.open}
          onClose={() =>
            setDeletePopupState({
              open: false,
              isCustomerAssignedToLoad: false,
              customerId: '',
            })
          }
          onAction={() => {
            deleteCustomerWrapper();
          }}
          title={t('deleteCustomer') + ' ?'}
          body={
            deletePopupState.isCustomerAssignedToLoad
              ? deletePopupConfig.bodyText.isCustomerAssignedToLoad
              : deletePopupConfig.bodyText.default
          }
        />
      )}
    </>
  );
};
