export const FactoringIntegrationConstants = {
  apexDescription:
    'Factor your trucking company’s freight bills with Apex with a click of a button. You can receive payment in minutes after your freight invoices are purchased.',
  triumphDescription:
    'Fast invoice factoring lets you manage your cash flow and stay on track financially.',
  TAFSDescription:
    'TAFS is a factoring company that provides owner-operators accounts receivable financing. TAFS can also offer quick funding using the factoring program with LoadOps.',
  vistracksDescription:
    'VisTracks ELD is FMCSA certified and widely used by several GPS Fleet Tracking companies in the US and Canada.',
  otrDescription:
    'OTR Solutions leads the industry with straight forward, value driven, and innovative factoring solutions designed by experienced transportation experts.',
  otherFactoringDescription:
    'The integration allows you to sync invoices and related load documents from LoadOps to other factoring company.',
  rtsDescription:
    'With our factoring services, you get funding on open invoices within 24 hours and simple, transparent pricing with no hidden fees.',
};
