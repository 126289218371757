import MuiTimeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box } from '@mui/material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { ReactElement, ReactNode, useState } from 'react';
import { HandPointer } from '../Assets/AxeleIcons/AxeleIcons';

export interface TimelineProps<TRecord> {
  position?: 'left' | 'right' | 'alternate';
  renderTitle?: (record: TRecord) => ReactNode;
  renderSubtitle?: (record: TRecord) => ReactNode;
  renderIcon?: (record: TRecord) => ReactNode;
  renderTooltip?: (record: TRecord) => ReactNode;
  data?: TRecord[];
  onRowClick?: (record: TRecord) => void;
  keyName: keyof TRecord;
  value?: TRecord[keyof TRecord];
  tooltipProps?: Partial<TooltipProps>;
  styleProps?: {
    stylePropsTimelineItem?: React.CSSProperties;
    stylePropsTimelineItemHover?: React.CSSProperties;
    stylePropsTimelineItemSelected?: React.CSSProperties;
    stylePropsTimelineSeparator?: React.CSSProperties;
    stylePropsTimelineDot?: React.CSSProperties;
    stylePropsTimelineConnector?: React.CSSProperties;
    stylePropsTimelineContent?: React.CSSProperties;
    stylePropsTitle?: React.CSSProperties;
    stylePropsSubTitle?: React.CSSProperties;
  };
}

const WrapperContainer = ({
  tooltipTitle,
  tooltipProps = {},
  key = '',
  children,
}: {
  tooltipTitle: ReactNode;
  tooltipProps?: Partial<TooltipProps>;
  key: string | number;
  children: ReactElement;
}): ReactElement => {
  if (tooltipTitle) {
    return (
      <Tooltip
        arrow
        enterDelay={1000} //tooltip[1s delay from mouse-in]
        placement="top"
        title={tooltipTitle}
        key={key}
        {...tooltipProps}
      >
        {children}
      </Tooltip>
    );
  }
  return <> {children}</>;
};

export const AxeleTimeline = <TRecord,>({
  position = 'right',
  renderSubtitle,
  renderTitle,
  renderIcon,
  renderTooltip,
  data = [],
  onRowClick,
  keyName,
  value,
  styleProps = {},
  tooltipProps = {},
}: TimelineProps<TRecord>): ReactElement | null => {
  const {
    stylePropsTimelineItem = {},
    stylePropsTimelineItemHover = {},
    stylePropsTimelineItemSelected = {},
    stylePropsTimelineSeparator = {},
    stylePropsTimelineDot = {},
    stylePropsTimelineConnector = {},
    stylePropsTimelineContent = {},
    stylePropsTitle = {},
    stylePropsSubTitle = {},
  } = styleProps;
  const [selectedValue, setSelectedValue] = useState<
    TRecord[keyof TRecord] | undefined
  >(value);
  return (
    <MuiTimeline
      sx={{
        px: 0,
      }}
      position={position}
    >
      {data.map((record, index) => {
        const isSelected =
          record[keyName] === selectedValue && selectedValue !== undefined;
        return (
          <WrapperContainer
            tooltipTitle={renderTooltip ? renderTooltip(record) : ''}
            key={`TimelineItem-${index}`}
            tooltipProps={tooltipProps}
          >
            <TimelineItem
              sx={{
                px: '10px',
                minHeight: '50px',
                '&:before': {
                  content: 'none',
                },
                '&:hover': {
                  borderRadius: '8px',
                  backgroundColor: 'primary.outlinedHoverBackground',
                  // backgroundColor: '#F0F6FF',
                  ...stylePropsTimelineItemHover,
                },
                ...(isSelected
                  ? {
                      borderRadius: '8px',
                      backgroundColor: 'primary.outlinedHoverBackground',
                      ...stylePropsTimelineItemSelected,
                    }
                  : {}),
                ...stylePropsTimelineItem,
              }}
            >
              <TimelineSeparator
                sx={{
                  zIndex: 2,
                  ...stylePropsTimelineSeparator,
                }}
              >
                <TimelineDot
                  sx={{
                    mt: '12px',
                    mb: '4px',
                    border: 'none',
                    p: 0,
                    color: 'primary.main',
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    position: 'relative',
                    ...stylePropsTimelineDot,
                  }}
                >
                  {renderIcon && renderIcon(record)}
                  {isSelected && (
                    <Box
                      sx={{
                        position: 'absolute',
                        transform: 'translateY(100%)',
                        left: '0',
                        bottom: '-8px;',
                      }}
                    >
                      <HandPointer />
                    </Box>
                  )}
                </TimelineDot>
                {index !== data.length - 1 && (
                  <TimelineConnector
                    sx={{
                      width: '1px',
                      ...stylePropsTimelineConnector,
                    }}
                  />
                )}
              </TimelineSeparator>
              <TimelineContent
                onClick={() => {
                  setSelectedValue(record[keyName]);
                  onRowClick && onRowClick(record);
                }}
                sx={{
                  p: 0,
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    cursor: 'pointer',
                    p: 1,
                    ...stylePropsTimelineContent,
                    '&:hover': {
                      ml: -4,
                      pl: 5,
                    },
                    ...(isSelected
                      ? {
                          ml: -4,
                          pl: 5,
                        }
                      : {}),
                  }}
                >
                  <Typography
                    sx={{
                      color: 'primary.dark',
                      fontWeight: 600,
                      fontSize: 14,
                      '&:hover': {
                        textDecorationLine: 'underline',
                      },
                      ...stylePropsTitle,
                    }}
                  >
                    {renderTitle ? renderTitle(record) : null}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: 12,
                      ...stylePropsSubTitle,
                    }}
                  >
                    {renderSubtitle ? renderSubtitle(record) : null}
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </WrapperContainer>
        );
      })}
    </MuiTimeline>
  );
};

export default AxeleTimeline;
