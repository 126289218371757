import { FC } from 'react';
import { CardItem } from '@/views/settings/integrations/FuelCard/components/CardItem';
import { FuelCardAccount, Provider } from '@/models/DTOs/FuelCards/FuelCards';

interface Props {
  accounts: FuelCardAccount[];
  onAccountDelete: (id: number) => void;
  selectedProvider: Provider;
  onDrawerOpen: () => void;
  getAccounts: () => void;
  handleSetActiveAccount: (account: FuelCardAccount) => void;
}

export const CardsList: FC<Props> = ({
  accounts,
  onAccountDelete,
  selectedProvider,
  onDrawerOpen,
  handleSetActiveAccount,
  getAccounts,
}) => {
  return (
    <>
      {accounts.map((account) => (
        <CardItem
          key={account.id}
          account={account}
          onAccountDelete={onAccountDelete}
          selectedProvider={selectedProvider}
          onDrawerOpen={onDrawerOpen}
          onSelectedProviderAccountChange={handleSetActiveAccount}
          getAccounts={getAccounts}
        />
      ))}
    </>
  );
};
