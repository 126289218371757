import { string } from 'yup';
import { QueryParams } from '../commonMixed/QueryParams';

export class GetRoles extends QueryParams {
  organizationId!: number;
  custom!: boolean;
  constructor() {
    super();
  }
}

export class RolesResponseDTO {
  name!: string;
  description!: string;
  code!: string;
  permissionCategory!: Array<any>;
  custom!: boolean;
  editMode!: boolean;
}

export class AddUpdateRoleDTO {
  organizationId?: string;
  name!: string;
  code!: string;
  description!: string;
  custom!: boolean;
  permissionList!: string[];
  edit?: boolean;
}

export class PermissionCategory {
  Name!: string;
  permission!: PermissionType;
}

export class PermissionType {
  add!: Permission | null;
  edit!: Permission | null;
  remove!: Permission | null;
  view!: Permission | null;
}
export class Permission {
  name!: string;
  isSelected!: boolean;
}
