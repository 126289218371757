import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { subscriptionService } from '../../api';
import AuthContext from '../../globalContexts/AuthContext';
import { CancelSubscriptionRequest } from '../../models';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../ui-kit/components/IconsComponent';
import TextInput from '../../ui-kit/components/TextField';
import { currencyDisplay } from '../../utils/grid.utils';
import {
  CancelSubscriptionImage1,
  CancelSubscriptionImage2,
  ExitImage,
  borderPopup,
  fontSecondary,
  letter15,
} from './constant';
import { styles } from './styles';
import { t } from 'i18next';
type CancelSubscriptionProps = {
  onclose: () => void;
  subscriptionData: any;
  url: string;
};

const CancelSubscription = ({
  onclose,
  subscriptionData,
  url,
}: CancelSubscriptionProps) => {
  const [step, setStep] = useState<number>(1);
  const [reasons, setReasons] = useState([]);
  const [reasonCode, setReasonCode] = useState<Array<string>>([]);
  const [cancellationMessage, setCancellationMessage] = useState<string>('');
  const [error, setError] = useState({});
  const cancelResponse = {
    uid: 'current',
    due_date: subscriptionData.current_period_ends_at,
    due_amount: subscriptionData.current_billing_amount_in_cents / 100,
    public_url: url,
  };
  const [phoneNumnber, setPhone] = useState<boolean>(false);
  const dueAmount = cancelResponse.due_amount;
  const isPayable = dueAmount < 500;

  const { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    return () => {
      if (step === 3) logoutUser();
    };
  }, [step]);

  const withinMonth =
    moment()
      .startOf('day')
      .diff(moment(subscriptionData?.created_at).startOf('day'), 'days') <= 30;

  const onClickContinue = async () => {
    if (reasonCode.length && cancellationMessage.trim().length) {
      setError({
        cancellationMessage: false,
        reasonCode: false,
      });
      if (withinMonth) {
        const query = new CancelSubscriptionRequest();
        query.cancellationMessage = cancellationMessage;
        query.reasonCode = reasonCode.join(',');
        const response = await subscriptionService.cancelSubscription(query);
        if (response) {
          logoutUser();
        }
      } else setStep(2);
    } else {
      setError({
        cancellationMessage: cancellationMessage.trim().length === 0,
        reasonCode: reasonCode.length === 0,
      });
    }
  };

  const onClickPayAndContinue = async () => {
    const query = new CancelSubscriptionRequest();
    query.cancellationMessage = cancellationMessage;
    query.reasonCode = reasonCode.join(',');
    const response = await subscriptionService.payAndCancel(query);
    if (response) setStep(3);
  };

  const onBack = () => {
    if (step === 2) setStep(1);
    else onclose();
  };

  const fetchReasons = async () => {
    const response = await subscriptionService.getReasons();
    const reasoncodes = response.map(
      (x: { reason_code: string }) => x.reason_code
    );
    setReasons(reasoncodes);
  };

  useEffect(() => {
    fetchReasons();
  }, []);

  return (
    <Box sx={styles.cancelContainer}>
      <Box>
        <Box
          sx={(theme) => ({
            height: '90px',
            ...theme.typography.h2,
            textAlign: 'center',
            letterSpacing: '-0.5px',
            color: 'primary.main',
          })}
        >
          {step === 1
            ? 'Where’d we go wrong'
            : step === 2
            ? 'One more thing'
            : 'We’re sorry to see you go :('}
        </Box>
        {step === 2 && (
          <Box
            sx={(theme) => ({
              ...theme.typography.h6,
              ...letter15,
              textAlign: 'center',
            })}
          >
            Looks like you’ve still got a balance, to finish canceling
            subscription please resolve this balance
          </Box>
        )}
        <Grid
          container
          xs={12}
          sx={{ marginBottom: '24px', marginTop: '24px' }}
        >
          <Grid
            xs={12}
            md={6}
            item
            sx={{ display: 'flex', justifyContent: 'space-around' }}
          >
            {step === 1 ? (
              <Box sx={borderPopup}>
                <Box sx={fontSecondary}>Why are you leaving LoadOps?</Box>
                <Box
                  sx={(theme) => ({
                    ...theme.typography.body2,
                    ...letter15,
                    color: error.reasonCode ? 'error.main' : 'text.primary',
                  })}
                >
                  Pick at least one of the following options
                </Box>
                <Box sx={{ marginTop: '20px' }}>
                  {reasons.map((x: { description: string; code: string }) => {
                    const isSelected = reasonCode.includes(x.code);
                    return (
                      <ButtonImproved
                        label={x.description}
                        key={x.code}
                        variant={isSelected ? 'contained' : 'outlined'}
                        onClick={() => {
                          if (isSelected)
                            setReasonCode(
                              reasonCode.filter((el) => el !== x.code)
                            );
                          else setReasonCode([...reasonCode, x.code]);
                        }}
                        styleProps={{
                          marginRight: '20px',
                          marginBottom: '18px',
                        }}
                      />
                    );
                  })}
                </Box>
                <Box sx={fontSecondary}>How do we improve?</Box>
                <Box
                  sx={(theme) => ({
                    ...theme.typography.body2,
                    ...letter15,
                    marginBottom: '20px',
                  })}
                >
                  Give us some specific feedback to help us do better
                </Box>
                <TextInput
                  required={true}
                  variant="outlined"
                  onChange={(e: any) => {
                    setCancellationMessage(e.target.value);
                  }}
                  error={error.cancellationMessage}
                  helperText={'feedback is required.'}
                  multiline={true}
                  rows={6}
                  defaultValue={cancellationMessage}
                  styleProps={{ width: '100%' }}
                />
              </Box>
            ) : step === 3 ? (
              <Box sx={styles.image}>
                <ExitImage />
              </Box>
            ) : (
              <Box sx={styles.image}>
                <CancelSubscriptionImage2 />
              </Box>
            )}
          </Grid>
          <Grid
            xs={12}
            md={6}
            item
            sx={{ display: 'flex', justifyContent: 'space-around' }}
          >
            {step === 3 ? (
              <Box
                sx={(theme) => ({ ...borderPopup(theme), textAlign: 'center' })}
              >
                <Box sx={styles.cancelText}>
                  Your subscription to LoadOps has been canceled
                </Box>
                <Box
                  sx={{
                    fontSize: '28px',
                    lineHeight: '42px',
                    color: 'text.primary',
                  }}
                >
                  We’re sorry to see you go, but our doors are always open! Now
                  that you’ve canceled your subscription, your account will be
                  canceled on{' '}
                  {moment(subscriptionData?.current_period_ends_at).format(
                    'll'
                  )}
                  . You can resubscribe your account anytime until{' '}
                  {moment(subscriptionData?.current_period_ends_at)
                    .add(-1, 'days')
                    .format('ll')}
                  , at 11:59pm.
                </Box>
              </Box>
            ) : step === 1 ? (
              <Box sx={styles.image}>
                <CancelSubscriptionImage1 />
              </Box>
            ) : (
              <Box sx={{ minWidth: '425px', width: '100%', maxWidth: '525px' }}>
                <Box sx={borderPopup}>
                  <Box
                    sx={{
                      paddingLeft: '17px',
                      color: 'text.primary',
                      marginBottom: '24px',
                    }}
                  >
                    <Box
                      sx={(theme) => ({
                        ...letter15,
                        ...theme.typography.h4,
                      })}
                    >
                      Balance Breakdown
                    </Box>
                    <Box
                      sx={(theme) => ({
                        ...letter15,
                        ...theme.typography.body2,
                      })}
                    >
                      Here’s a breakdown of how we got that number
                    </Box>
                  </Box>
                  <Box sx={styles.flex}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={(theme) => ({ ...theme.typography.h5 })}>
                        Current Invoice
                      </Box>
                      <Box
                        sx={(theme) => ({
                          ...theme.typography.body1,
                          ...letter15,
                        })}
                      >
                        {moment(cancelResponse.due_date)
                          .startOf('day')
                          .format('ll')}
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          marginRight: '20px',
                          ...fontSecondary,
                          color: 'primary.main',
                        }}
                      >
                        {currencyDisplay(cancelResponse.due_amount)}
                      </Box>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          window
                            .open(cancelResponse.public_url, '_blank')
                            .focus()
                        }
                      >
                        <IconsComponent
                          source="MUIcons"
                          iconName="Launch"
                          styleProps={styles.launchIcon}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={(theme) => ({
                    ...borderPopup(theme),
                    justifyContent: 'space-between',
                    marginTop: '24px',
                    flexDirection: 'row',
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      ...theme.typography.h4,
                      color: 'text.primary',
                      letterSpacing: '0.25px',
                    })}
                  >
                    {t('amountDue')}
                  </Box>
                  <Box sx={styles.balance}>{currencyDisplay(dueAmount)}</Box>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box
          sx={(theme) => ({
            ...styles.support,
            background: theme.palette.info.background,
          })}
        >
          <Box sx={styles.flexContact}>
            <Box sx={fontSecondary}>Contact Support</Box>
            <Box
              sx={(theme) => ({
                ...theme.typography.body2,
                textAlign: 'center',
                color: 'text.primary',
              })}
            >
              Our support team is here to help with any questions you may have.
            </Box>
          </Box>
          {!isPayable && step === 2 ? (
            <Box sx={styles.help}>
              Call Our Support Center to Cancel (1-833-AXELE-01)
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <ButtonImproved
                onClick={() => {
                  setPhone(true);
                }}
                label={step === 2 ? 'Message Us' : 'Let’s Chat'}
                styleProps={{
                  marginRight: '7px',
                  marginBottom: '10px',
                  width: '166px',
                }}
                variant="contained"
                startIcon={
                  <IconsComponent
                    styleProps={{ height: '15px', width: '15px' }}
                    iconName="TextsmsOutlined"
                    source="MUIcons"
                  />
                }
              />
              {phoneNumnber && (
                <Box
                  sx={(theme) => ({
                    ...theme.typography.body2,
                    ...styles.contactPhone,
                    marginBottom: 0,
                  })}
                >
                  Phone Number:{' '}
                  <Box
                    sx={(theme) => ({
                      ...theme.typography.body2Bold,
                      marginLeft: '5px',
                    })}
                  >
                    1-833-AXELE-01
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {isPayable && step === 2 && (
          <Box
            sx={(theme) => ({
              padding: '5px 24px',
              marginTop: '10px',
              marginBottom: '34px',
              background: theme.palette.warning.background,
              borderWidth: '1px',
              borderColor: theme.palette.warning.outlinedRestingBorder,
              borderRadius: '8px',
              color: 'text.primary',
              ...theme.typography.body2,
            })}
          >
            Once you cancel your subscription, you will no longer be able to
            access you data.
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {step === 3 ? (
            <ButtonImproved
              label="Exit"
              variant="contained"
              startIcon={<IconsComponent source="MUIcons" iconName="Close" />}
              onClick={logoutUser}
            />
          ) : step === 1 ? (
            <>
              <ButtonImproved
                label="Back"
                onClick={onBack}
                variant="outlined"
                styleProps={{ marginRight: '16px' }}
              />
              {withinMonth ? (
                <ButtonImproved
                  label="Cancel Subscription"
                  variant="contained"
                  color="error"
                  styleProps={{ color: 'white' }}
                  onClick={onClickPayAndContinue}
                />
              ) : (
                <ButtonImproved
                  label="Continue"
                  variant="contained"
                  onClick={onClickPayAndContinue}
                />
              )}
            </>
          ) : isPayable ? (
            <>
              <ButtonImproved
                label="Back"
                variant="outlined"
                onClick={onBack}
                styleProps={{ marginRight: '16px' }}
              />
              <ButtonImproved
                label="Cancel"
                variant="contained"
                onClick={onClickPayAndContinue}
                color="error"
              />
            </>
          ) : (
            <ButtonImproved
              label="Back To Account"
              variant="outlined"
              onClick={onclose}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CancelSubscription;
