import { InvoiceModalTranslations } from './modal';

export const InvoicesConstants = {
  factoringAction: 'Factoring Action',
  changeStatus: 'Change Status',
  loadDeleteSubtitleWithInvoice:
    'Please note, that all associated data including invoices, payment details, documents, IFTA mileage data, etc. will be also deleted',
  loadDeleteSubtitle:
    'Please note, that all associated data of payment details will be also deleted',
  attachments: 'Attachments',
  rateConfirmation: 'Rate Confirmation',
  billOfLading: 'Bill of Landing',
  proofOfDelivery: 'Proof of Delivery',
  downloadInvoice: 'Download Invoice',
  markCompletedLoads: 'Mark completed Load(s) as Invoiced',
  emailInvoices: 'Email Invoices',
  imagesAcceptanceSubLabel: '(only PDF and image files are accepted)',
  sendDocs: 'Send Documents',
  factoringNotes: 'Factoring Notes',
  ref: 'Ref #',
  dueAmount: 'Due Amount',
  selectFundingTime: 'Select Funding Time',
  cutoffTimeAt12: 'Next day cut off time is 12:00',
  fundingMethod: 'Funding Method',
  addFundingMethod: 'Add Funding Method',
  additionalInstructions: 'Additional Instructions',
  sameDay: 'Same Day',
  nextDay: 'Next Day',
  submitForFunding: 'Submit for Funding',
  updateAtFactoring: 'Update at factoring',
  factoringInformation: 'Factoring Information',
  factoringActivationPending: 'Factoring activation is failed or is pending',
  factoringCompany: 'Factoring Company',
  factoringCommission: 'Factoring Commission',
  messageOnInvoice: 'Message on Invoice',
  factoringDisconnectConfirmation:
    'Are you sure you want to disconnect from factoring?',
  deduction: 'Deduction',
  quantity: 'Quantity',
  rate: 'Rate',
  baseAmount: 'Base Amount',
  addAccessorialItem: 'Add Accessorial Item',
  invoiceTotal: 'Invoice Total',
  factoringCharges: 'Factoring Charges',
  amountDue: 'Amount Due',
  receivePayment: 'Receive Payment',
  referenceNo: 'Reference Number',
  advance: 'Advance',
  invoiceSettings: 'Invoice Settings',
  customerNotMappedWithFactoring:
    'Customer is not Mapped with factoring company',
  customerName: 'Customer Name',
  mcNum: 'MC#',
  EDICustomerID: 'EDI Customer ID',
  contactInfo: 'Contact Info',
  invoiceTerm: 'Invoice Term',
  noOfDays: 'Number of Days',
  loadCompletionDate: 'Load Completion Date',
  invoiceDate: 'Invoice Date',
  paidDate: 'Paid Date',
  loadedMiles: 'Loaded Miles',
  emptyMiles: 'Empty Miles',
  internalInvoiceNotes: 'Internal Invoice Notes',
  missingLadingBill: 'Bill of lading is Missing.',
  missingRateConfirmation: 'Rate Confirmation is Missing',
  missingDeliveryProof: 'Proof of Delivery is Missing',
  invoiceOverDue: 'Invoice Overdue',
  detentionTime: 'Detention Time',
  noInvoices: 'No Invoices Yet',
  noInvoiceSubtitle:
    'No Invoice Yet. Invoices auto-populate once their load is completed. Check back once a load is completed!',
  failedFactoringActivation: 'Factoring activation is failed or is pending',
  factoringCharge: 'Factoring Charge',
  factoringStatus: 'Factoring Status',
  missingDocs: 'Missing Documents',
  invoiceStatus: 'Invoice Status',
  completedOn: 'Completed On',
  updatedOn: 'Updated On',
  invoicedOn: 'Invoiced On',
  paidOn: 'Paid On',
  missingEmailAddressForCustomers:
    'There are missing email addresses for some customers',
  checkDataTryAgain:
    'Please check data and try again, or use single email sending option',
  cannotSendGroupEmail:
    'You cannot send group email for the selected invoices.',
  cannotSendFactoringToAccounts:
    'You cannot send Invoices to different factoring accounts at the same time. Please select rows of the same factoring company',
  cannotsendMoreInvoices:
    'You cannot send more than 25 invoices at once. Please select less number of rows',
  sendInvoicesConfirmation:
    'Are you sure you want to send those invoices to Factoring?',
  cannotRefreshFactoringAccounts:
    'You cannot refresh the invoice statuses from different factoring accounts at the same time. Please select rows of the same factoring company',
  cannotRefreshMoreInvoices:
    'You cannot refresh the statuses of more than 25 invoices at once. Please select less number of rows',
  refreshInvoiceConfirmation:
    'Are you sure you want to refresh the invoice statuses from factoring?',
  cannotSendInvoicesToFactoringAccounts:
    'You cannot submit the invoices to different factoring accounts at the same time. Please select rows of the same factoring company',
  cannotSubmitMoreInvoices:
    'You cannot submit more than 25 invoices at once. Please select less number of rows',
  submitInvoiceForFundingConfirmation:
    'Are you sure you want to submit the invoice for funding by factoring?',
  refreshWarningTitle:
    'Are you sure you want to refresh the invoice status from factoring?',
  revertLoadCompletedWarningTitle:
    'Are you sure you want to revert this load status to Completed?',
  revertInvoicedToCompletedWarningSubTitle:
    'Please note, any changes made to invoice needs to be resynced with Factoring Company',
  revertToInvoicedWarningTitle:
    'Are you sure you want to revert this load status to Invoiced?',
  multipleInvoiceEmailSendWarning:
    'NOTE: The Default Recipient is factoring company‘s email if the invoice is marked for factoring. Otherwise, primary contact of load customer will be assumed as a recipient',
  revertToCompleted: 'Revert to Completed',
  paid: 'Paid',
  revertToInvoiced: 'Revert to Invoiced',
  invoiced: 'Invoiced',
  docSendingInProcess:
    'All invoices are accepted by the factoring company. The documents are being sent for for the accepted invoices and you will be notified separately once this process completes',
  invoiceNotAccepted:
    'None of the selected invoices are accepted by the factoring company',
  invoiceNotRefreshed:
    'None of the selected invoices are refreshed from the factoring company',
  invoiceDisconnected:
    'The selected invoices have been disconnected from the factoring company',
  noInvoiceDisconnected:
    'None of the selected invoices are disconnected from the factoring company',
  fundingNotAccepted:
    'The submission for funding is not accepted by the factoring company',
  invoiceNumber: 'Invoice #',
  financeInvoicesReadyToInvoice: 'Ready To Invoice',
  financeInvoicesInvoiced: 'Invoiced',
  financeInvoicesPaid: 'Paid',
  financeInvoicesAddCustomViews: 'Add Custom Views',
  financeInvoicesCustomViewData:
    'View your data in new and custom ways. Create your own or see what others have made',
  invoiceModalTitle: 'Create an Invoice View',
  modalNameYourView: '1. Name Your View',
  modalPickDate: '2. Pick Date',
  modalAddFilters: '3. Add Filters',
  modalEnterViewName: 'Enter View Name',
  modalPickIcon: 'Pick Icon',
  ...InvoiceModalTranslations,
  invoiceIsOnHold: 'This invoice is on hold',
};
