import Grid from '@mui/material/Grid';
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import FormDateTimePicker from '../../../../../../common/Ui/FormDateTimePicker/FormDateTimePicker';
import Switch from '../../../../../../common/Ui/Switcher';
import TextField from '../../../../../../common/Ui/TextField/TextField';
import { useRootStore } from '../../../../../../store/root-store/rootStateContext';

export const CancelLoadForm = memo((): JSX.Element => {
  const { control, watch } = useFormContext();
  const markAsNonInvoiceable = watch('markAsNonInvoiceable');
  const { getCompanyPreferences } = useRootStore();

  return (
    <Grid spacing={1} container>
      <FormDateTimePicker
        sizes={{
          xs: 12,
        }}
        styleProps={{
          width: '100%',
        }}
        required
        control={control}
        label="Canceled Date-Time"
        name={`cancellationDate`}
        timezone={getCompanyPreferences?.timezone}
        disableFuture
      />
      <Switch
        control={control}
        label="Mark as Non-Invoiceable"
        name={`markAsNonInvoiceable`}
        sizes={{ xs: 12 }}
      />

      {!markAsNonInvoiceable && (
        <React.Fragment>
          <Switch
            control={control}
            label="Keep Invoice Line-Items"
            name={`keepInvoiceLineItems`}
            sizes={{ xs: 12 }}
          />
          <TextField
            sizes={{
              xs: 12,
            }}
            control={control}
            label="Invoice Notes"
            name={`invoiceNotes`}
          />
        </React.Fragment>
      )}
    </Grid>
  );
});
CancelLoadForm.displayName = 'CancelLoadForm';
