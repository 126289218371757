import * as yup from 'yup';
import moment from 'moment';
import { nullableDateYup } from '../../../../utils';

export const safetyAlertValidationSchema = yup.object().shape(
  {
    documentTypeId: yup
      .string()
      .nullable()
      .required('Document Type is required.'),
    validFrom: nullableDateYup()
      .required('Valid From is required.')
      .when(['validTo', 'validFrom'], (validTo, validFrom) => {
        if (validTo && validFrom) {
          return nullableDateYup().max(
            moment(validTo).add(-1, 'days'),
            'Valid From must be before the Valid Until.'
          );
        }
        return nullableDateYup().required('Valid From is required.');
      }),
    validTo: nullableDateYup()
      .required('Next Due is required.')
      .when(['validTo', 'validFrom'], (validTo, validFrom) => {
        if (validTo && validFrom) {
          return nullableDateYup().min(
            moment(validFrom).add(1, 'days'),
            'Valid Until must be after the Valid From'
          );
        }

        return nullableDateYup().required('Valid Until is required.');
      }),
    notes: yup
      .string()
      .test('length', 'Cannot be longer than 255 characters.', (val) =>
        !!val ? val.length <= 255 : true
      )
      .nullable(),
  },
  [
    ['validFrom', 'validTo'],
    ['validTo', 'validFrom'],
    ['validTo', 'validTo'],
    ['validFrom', 'validFrom'],
  ]
);
