import * as React from 'react';
import Button from '@mui/material/Button';
import { ButtonGroup as MuiButtonGroup, SxProps } from '@mui/material';
import { FC } from 'react';

export type ButtonGroupOption =
  | {
      key: string;
      value: string;
    }
  | string;

export interface ButtonGroupProps {
  options?: ButtonGroupOption[];
  selected: string | null;
  onChange: (value: string | null) => void;
  disabled?: boolean;
  switcher?: boolean;
  fullWidth?: boolean;
  buttonStyle?: SxProps;
  fieldName?: string;
  fieldValue?: string;
}

const ButtonGroup: FC<ButtonGroupProps> = ({
  options,
  selected = null,
  onChange,
  disabled,
  switcher = false,
  fullWidth = false,
  buttonStyle = {},
  fieldName = 'value',
  fieldValue = 'key',
}) => {
  return (
    <MuiButtonGroup
      disabled={disabled}
      aria-label="outlined primary button group"
      fullWidth={fullWidth}
    >
      {options?.map((el) => {
        const key = el?.[fieldValue] || el;
        const s = key === selected;
        return (
          <Button
            key={el?.[fieldValue]}
            variant={s ? 'contained' : 'outlined'}
            onClick={(e) => {
              if (switcher && selected && selected === el?.[fieldValue]) {
                e.stopPropagation();
                return;
              }
              onChange(s ? null : el?.[fieldValue]);
            }}
            sx={buttonStyle}
          >
            {el?.[fieldName] || key}
          </Button>
        );
      })}
    </MuiButtonGroup>
  );
};
export default ButtonGroup;
