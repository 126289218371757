import {
  carrierColumnsInfo,
  defaultCarrierFilters,
} from '../../../../subPages/carriers/constants/constants';
import { ColumnsMap, ViewMap } from '../../../../types';
import {
  LocationsTabStripIcon,
  PeopleIcon,
  VendorsTabStripIcon,
} from '../../../../ui-kit/components/Assets';

import { customerColumns } from './CustomerConstants';
import { locationColumns } from './LocationConstants';
import { vendorColumns } from './VendorConstants';
const CarrierTabStripIcon = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666748 2.33337L6.00008 0.333374L11.3334 2.33337V6.39337C11.3334 9.76004 9.06008 12.9 6.00008 13.6667C2.94008 12.9 0.666748 9.76004 0.666748 6.39337V2.33337ZM6.00008 12.28C7.18843 11.8251 8.20886 11.017 8.92401 9.96452C9.63917 8.91205 10.0147 7.66574 10.0001 6.39337V3.26004L6.00008 1.76004L2.00008 3.26004V6.39337C1.9845 7.66596 2.35966 8.91269 3.07494 9.96534C3.79022 11.018 4.81119 11.8259 6.00008 12.28ZM4.66675 5.00004C4.66675 4.73633 4.74495 4.47855 4.89146 4.25928C5.03796 4.04001 5.2462 3.86912 5.48984 3.7682C5.73347 3.66728 6.00156 3.64088 6.2602 3.69233C6.51884 3.74377 6.75642 3.87076 6.94289 4.05723C7.12936 4.2437 7.25635 4.48128 7.3078 4.73992C7.35924 4.99856 7.33284 5.26665 7.23192 5.51029C7.131 5.75392 6.96011 5.96216 6.74084 6.10867C6.52158 6.25518 6.26379 6.33337 6.00008 6.33337C5.64646 6.33337 5.30732 6.1929 5.05727 5.94285C4.80722 5.6928 4.66675 5.35366 4.66675 5.00004ZM3.33341 8.00004C3.33341 7.11337 5.10675 6.66671 6.00008 6.66671C6.89341 6.66671 8.66675 7.11337 8.66675 8.00004V9.00004H3.33341V8.00004Z"
      fill="white"
    />
  </svg>
);
export const columnsMap: ColumnsMap = {
  customer: customerColumns,
  vendor: vendorColumns,
  location: locationColumns,
  carrier: customerColumns, //@TODO : @Sinh update later
};

export const type = 'typeList';
export const customerName = 'customerNameFilter';
export const customerMcFilter = 'customerMcFilter';
export const customerStateFilter = 'customerStateFilter';
export const factoringIds = 'factoringIds';
export const customerContactsFilter = 'customerContactsFilter';
export const customerPreferenceFilter = 'customerPreferenceFilter';
export const customerAddressFilter = 'customerAddressFilter';

export const vendorNames = 'vendorNames';
export const vendorIds = 'vendorIds';
export const vendorContactNames = 'vendorContactNames';
export const preferredProhibitedEnum = 'preferredProhibitedEnum';
export const vendorAddress = 'vendorAddress';

export const locationNameFilter = 'locationNamesFilter';
export const addressFilter = 'locationAddressFilter';
export const contactsFilter = 'locationContactsFilter';
export const timeZoneFilter = 'timezoneFilter';
export const locationPreferenceFilter = 'locationPreferenceFilter';

export const preferenceOptions = [
  { id: 'PREFERRED', name: 'Preferred' },
  { id: 'PROHIBITED', name: 'Prohibited' },
];

export const timeZoneOptions = [
  { id: 'America/New_York', name: 'America/New_York' },
  { id: 'America/Chicago', name: 'America/Chicago' },
  { id: 'America/Denver', name: 'America/Denver' },
  { id: 'America/Los_Angeles', name: 'America/Los_Angeles' },
  { id: 'America/Phoenix', name: 'America/Phoenix' },
];

export const customerFilters = {
  [type]: [],
  [customerName]: [],
  [customerMcFilter]: [],
  [factoringIds]: [],
  [customerContactsFilter]: [],
  [customerPreferenceFilter]: null,
  [customerAddressFilter]: [],
};

export const vendorFilters = {
  [vendorNames]: [],
  [vendorContactNames]: [],
  [preferredProhibitedEnum]: null,
  [vendorAddress]: [],
  [vendorIds]: [],
};

export const locationFilters = {
  [locationNameFilter]: [],
  [addressFilter]: [],
  [contactsFilter]: [],
  [timeZoneFilter]: null,
  [locationPreferenceFilter]: null,
};

export const customerColumnsInfo = {
  name: true,
  mc: true,
  type: true,
  factoringName: true,
  openCreditAmount: true,
  contact: true,
  phone: true,
  fullAddress: true,
  email: true,
  notes: true,
};

export const vendorColumnsInfo = {
  name: true,
  contact: true,
  phone: true,
  fullAddress: true,
  preferredProhibitedEnum: true,
  email: true,
  notes: true,
};

export const locationColumnsInfo = {
  locationName: true,
  contactName: true,
  contactPhone: true,
  contactEmail: true,
  avgHandlingTime: true,
  timezone: true,
  fullAddress: true,
  notes: true,
};

export const customersView = {
  id: 'customer',
  name: 'Customers',
  icon: <PeopleIcon color="#001122" />,
  entity: 'customer',
  shared: true,
  default: true,
  columnFilters: customerColumnsInfo,
  metaData: customerFilters,
};

export const vendorsView = {
  id: 'vendor',
  shared: true,
  parentId: null,
  name: 'Vendors',
  entity: 'vendor',
  icon: <VendorsTabStripIcon />,
  active: true,
  default: true,
  columnFilters: vendorColumnsInfo,
  metaData: vendorFilters,
};

export const locationsView = {
  id: 'location',
  name: 'Locations',
  icon: <LocationsTabStripIcon />,
  entity: 'location',
  shared: true,
  default: true,
  columnFilters: locationColumnsInfo,
  metaData: locationFilters,
};

export const carriersView = {
  id: 'carrier',
  name: 'Carriers',
  icon: <CarrierTabStripIcon />,
  entity: 'carrier',
  shared: true,
  default: true,
  columnFilters: carrierColumnsInfo,
  metaData: defaultCarrierFilters,
};

export const formDefaultValuesMap: ViewMap = {
  customer: customersView,
  vendor: vendorsView,
  location: locationsView,
  carrier: carriersView,
};

export const defaultSortName = '+name';
