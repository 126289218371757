import { View } from '../../../types';
import { defaultEDITendersGridFilters } from '../EDITendersGrid/grid.config';
import { EDITendersFieldsConfig } from './grid.constants';
import { IEDITendersFilters } from './types';
import { EDITendersConstants } from '../../../locales/en/allLoads/loadtenders';

export const EDITendersColumnFilters = Object.values(
  EDITendersFieldsConfig
).reduce<{ [field: string]: boolean }>(
  (result, { fieldName }) =>
    Object.assign(result, {
      [fieldName]: true,
    }),
  {}
);

const NewColumnFilters = {
  ...EDITendersColumnFilters,
};

const AcceptedColumnFilters = {
  ...EDITendersColumnFilters,
};

const DeclinedColumnFilters = {
  ...EDITendersColumnFilters,
};

const CanceledColumnFilters = {
  ...EDITendersColumnFilters,
};
const AllColumnFilters = {
  ...EDITendersColumnFilters,
};

const AcceptedMetaData: IEDITendersFilters = {
  ...defaultEDITendersGridFilters,
};
const DeclinedMetaData: IEDITendersFilters = {
  ...defaultEDITendersGridFilters,
};

const CanceledMetaData: IEDITendersFilters = {
  ...defaultEDITendersGridFilters,
};
const AllMetaData: IEDITendersFilters = {
  ...defaultEDITendersGridFilters,
};

export enum EEDITendersEntity {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
  ALL = 'ALL',
}

export const EDITendersNewView = {
  id: EEDITendersEntity.NEW,
  shared: true,
  parentId: null,
  name: EDITendersConstants.new,
  entity: EEDITendersEntity.NEW,
  icon: null,
  default: true,
  columnFilters: EDITendersColumnFilters,
  metaData: NewColumnFilters,
};

export const EDITendersAcceptedView = {
  id: EEDITendersEntity.ACCEPTED,
  shared: true,
  parentId: null,
  name: EDITendersConstants.accepted,
  entity: EEDITendersEntity.ACCEPTED,
  icon: null,
  active: true,
  default: true,
  columnFilters: AcceptedColumnFilters,
  metaData: AcceptedMetaData,
};

export const EDITendersDeclinedView = {
  id: EEDITendersEntity.DECLINED,
  shared: true,
  parentId: null,
  name: EDITendersConstants.declined,
  entity: EEDITendersEntity.DECLINED,
  icon: null,
  active: true,
  default: true,
  columnFilters: DeclinedColumnFilters,
  metaData: DeclinedMetaData,
};

export const EDITendersCanceledView = {
  id: EEDITendersEntity.CANCELED,
  shared: true,
  parentId: null,
  name: EDITendersConstants.canceledbyCustomer,
  entity: EEDITendersEntity.CANCELED,
  icon: null,
  active: true,
  default: true,
  columnFilters: CanceledColumnFilters,
  metaData: CanceledMetaData,
};
export const EDITendersAllView = {
  id: EEDITendersEntity.ALL,
  shared: true,
  parentId: null,
  name: EDITendersConstants.all,
  entity: EEDITendersEntity.ALL,
  icon: null,
  active: true,
  default: true,
  columnFilters: AllColumnFilters,
  metaData: AllMetaData,
};

export type ViewMap = {
  [key: string]: View[];
};

export const formDefaultValuesMap: any = {
  EDITendersNewView: EDITendersNewView,
  EDITendersAcceptedView: EDITendersAcceptedView,
  EDITendersDeclinedView: EDITendersDeclinedView,
  EDITendersCanceledView: EDITendersCanceledView,
  EDITendersAllView: EDITendersAllView,
};
