import React from 'react';
import { sectionType } from '../constants';
import MissingEntitiesMatchForm from './MissingEntitiesMatchForm';
import { IMPORT_CUSTOMER_NAME } from '../../../../../../contexts/ImportContext';

type pagePropTypes = {
  axeleValues: string[];
  currentSection: sectionType;
};

export default function CustomerForm({
  axeleValues,
  currentSection,
}: pagePropTypes) {
  return (
    <MissingEntitiesMatchForm
      axeleValues={axeleValues}
      currentSection={currentSection}
      fieldName={'name'}
      from={IMPORT_CUSTOMER_NAME}
    />
  );
}
