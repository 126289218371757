import { LoadsearchLaneContextProvider } from '../contexts/LoadsearchLaneContext';
import Loadboards from '../views/loadboards';

const LoadboardSearch = () => {
  return (
    <LoadsearchLaneContextProvider>
      <Loadboards />
    </LoadsearchLaneContextProvider>
  );
};

export default LoadboardSearch;
