const constants = {
  trimbleMapsApiKey: process.env.TRIMBLEMAPS_TOKEN,
  THEME: {
    DARK: 'transportation_dark',
    LIGHT: 'transportation',
  },
  SATELLITEVIEW: 'TrimbleMaps.Common.Style.TRANSPORTATION',
  cluster: {
    layer: {
      clusteredPoints: 'clusteredPoints',
      unclusteredMarkers: 'unclusteredMarkers',
      clusterCount: 'clusterCount',
      markersWithLocationOutdated: 'markersWithLocationOutdated',
    },
    source: {
      id: 'randomPoints',
    },
    querySourceFeatures: {
      filter: {
        clusteredPoints: ['has', 'point_count'],
        clusterCount: ['has', 'point_count'],
        unclusteredMarkers: [
          'all',
          ['!', ['has', 'point_count']],
          ['==', ['get', 'isLocationOutdated'], false],
        ],
        markersWithLocationOutdated: [
          'all',
          ['!', ['has', 'point_count']],
          ['==', ['get', 'isLocationOutdated'], true],
        ],
      },
    },
  },
};

export const allUnclusteredMarkerlayers = [
  constants.cluster.layer.unclusteredMarkers,
  constants.cluster.layer.markersWithLocationOutdated,
];

export default constants;

export enum MarkerEnum {
  PICKUP = 'Pickup',
  DROPOFF = 'Drop Off',
  PICKUPANDDROPOFF = 'Pickup & Drop Off',
  TRIPSTOP = 'Trip Stop',
  RELAYSTOP = 'Relay Stop',
  HOSSTOP = 'HoS Stop',
  CURRENTLOCATIONSOLO = 'Current Location Solo',
  CURRENTLOCATIONTEAM = 'Current Location Team',
  COMPLETED = 'COMPLETED',
}

export enum WarningMsg {
  DETENTION = 'Detention Detected!',
  DELAY = 'Delay Detected!',
  HOS = 'HoS Expired',
}

export enum ROUTEFLAG {
  NONE = 'NONE',
  FULLROUTE = 'FULL_ROUTE',
}

export enum TOOLTIPKEYS {
  TRIPID = 'Trip ID',
  LOADID = 'Load ID',
}
