import * as yup from 'yup';
import { EQUIPMENT_PANEL_TABS } from '../../../constants/contacts';
import {
  lastLocationValidationSchema,
  nullableNumberYup,
  nullableStringMaxValidation,
  nullableStringYup,
  phoneValidationSchema,
  vinValidationSchema,
} from '../../../utils';
import { trailerAlertValidationSchema } from '../../shared/equipment';
const textMaxSize = 100;
const trailerTabValidationSchema = yup
  .object()
  .shape(
    {
      trailerName: nullableStringMaxValidation(50).required(
        'Trailer # is a required'
      ),
      vin: vinValidationSchema(),
      licensePlateNumber: nullableStringYup().max(
        20,
        'Cannot be longer than 20 characters'
      ),
      ownedCompany: nullableStringMaxValidation(50),
      phone: phoneValidationSchema,
      ownerEmail: nullableStringYup().email('Email is not valid'),
      make: nullableStringYup().max(textMaxSize),
      model: nullableStringYup().max(textMaxSize),
      lastLocation: lastLocationValidationSchema,
      ownerAddress: lastLocationValidationSchema,
      gvw: nullableNumberYup().max(99999, 'Value must be between 0 and 99,999'),
      payloadCapacity: nullableNumberYup().max(
        100000,
        'Payload Capacity should be less or equal to 1000000.'
      ),
      insuranceCompany: nullableStringMaxValidation(50),
    },
    [
      ['vin', 'vin'],
      ['mobileno', 'mobileno'],
    ]
  )
  .required();

const alertTabValidationSchema = yup
  .object()
  .shape(
    {
      alertsList: yup.array().of(trailerAlertValidationSchema).compact(),
    },
    []
  )
  .required();

export const EQUIPMENT_TRAILER_VALIDATION_SCHEMA: {
  [property: string]: yup.AnyObjectSchema;
} = {
  [EQUIPMENT_PANEL_TABS.GENERAL]: trailerTabValidationSchema,
  [EQUIPMENT_PANEL_TABS.ALERTS]: alertTabValidationSchema,
};
