import { Box, Typography } from '@mui/material';

export const BulkActionDisabled = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px',
        height: '22px',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D',
        borderRadius: '4px',
        position: 'fixed',
        top: '-25px',
        width: 'calc(100% - 32px)',
        marginLeft: '16px',
        marginRight: '16px',
      }}
    >
      <Typography
        sx={{
          fontFamily: "'Poppins'",
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '10px',
          lineHeight: '140%',
          display: 'flex',
          alignItems: 'center',
          color: '#FFFFFF',
          textAlign: 'center',
        }}
      >
        These actions only apply to planned loads
      </Typography>
    </Box>
  );
};
