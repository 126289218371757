import { useFormContext } from 'react-hook-form';
import SettingBlock from '../Blocks/SettingBlock';
import { SettingBlockItem } from '../Blocks/SettingBlockItem';
import {
  DRIVER_NAME,
  dvirPreferences,
  truckStopsOptions,
  workWeekTypeOptions,
} from '../../constants';
import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import { MultipleAutocompleteForm } from '../../../../../common/Ui/MultipleAutocomplete';
import Switcher from '../../../../../common/Ui/Switcher';

function DVIRPreference() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="DVIR Preference"
      subTitle="DVIR Preference is used in trip plan calculation to determine load ETAs."
    >
      <SingleAutocompleteForm
        control={control}
        name="driverPreferences.dvirPreferences"
        label="DVIR Preference"
        fieldName="value"
        fieldValue="key"
        options={dvirPreferences}
        required
        disableClear
      />
    </SettingBlockItem>
  );
}

function WorkWeekType() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Work Week Type"
      subTitle="Which HOS clock do your drivers follow?"
    >
      <SingleAutocompleteForm
        control={control}
        name="driverPreferences.workWeekType"
        label="Work Week Type"
        fieldName="value"
        fieldValue="key"
        options={workWeekTypeOptions}
        required
        disableClear
      />
    </SettingBlockItem>
  );
}

function PreferredTruckStops() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Preferred Truck Stops"
      subTitle="Do you prefer a particular truck stop? We’ll consider this while creating trip plans."
    >
      <MultipleAutocompleteForm
        control={control}
        variant="standard"
        name="driverPreferences.preferredTruckstops"
        label="Preferred Truck Stops"
        fieldName="label"
        options={truckStopsOptions}
      />
    </SettingBlockItem>
  );
}

function IgnoreHOSRestrictions() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Ignore HOS Restrictions"
      subTitle="Do you want to ignore HOS restrictions while creating trip plans or while searching load on the load boards"
    >
      {/* <Switcher
        control={control}
        name="driverPreferences.ignoreHOSRestrictions"
        label=""
        style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      /> */}
    </SettingBlockItem>
  );
}

function UseSleeperBirthProvision() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Use Sleeper Berth Provision"
      subTitle="Will your drivers use the sleeper berth provision?"
    >
      <Switcher
        control={control}
        name="driverPreferences.useSleeperBerthProvision"
        label=""
        style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
      />
    </SettingBlockItem>
  );
}

export default function Driver() {
  return (
    <section id={DRIVER_NAME} className="section">
      <SettingBlock
        title="Driver"
        subTitle="These details are used to determine driver HOS based on driver logs"
      >
        <DVIRPreference />
        <WorkWeekType />
        <PreferredTruckStops />
        {/* <IgnoreHOSRestrictions /> */}
        <UseSleeperBirthProvision />
      </SettingBlock>
    </section>
  );
}
