import { Box, Grid, ThemeProvider, useTheme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { financeIntegrationService } from '../../../../../api';
import FormDialog from '../../../../../common/Ui/FormDialog';
import { GetCustomerRequest, GetDriverRequest } from '../../../../../models';
import {
  OTR_PROVIDER_ID,
  TAFS_PROVIDER_ID,
} from '../../../../../subPages/invoices/constants';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { CommonDataGrid } from '../../../../../ui-kit/components/DataGridPro';
import TabsV2, { ITabV2 } from '../../../../../ui-kit/components/TabsV2';
import { gridPageSize } from '../../../../../utils';
import { callBackObject } from '../FactoreSelectedCustomer/MappingFactoring';
import { CreateDriverForm } from '../forms/CreateDriverForm';
import {
  buttonTextDriver,
  manageIntegrationTabs,
  OTRTabs,
} from './integrationData';

export const ManageFactoreIntegration = ({
  tableData,
  handleCustomerScroll,
  handleDriverScroll,
  onManageTabChanged,
  customerTotalRowCount,
  driverTotalRowCount,
  selectedTab,
  refreshModalData,
  selectedProviderId,
  callBackFilters,
  unlinkByIDs,
  tableColumnsCustomer,
  tableColumnsDriver,
  activeAccount,
}: ManageFactoreDataProps) => {
  const [tableDisplayData, setTableDisplayData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState<Array<RowData>>([]);
  const [isFormDialogView, setFormDialogViewa] = useState<boolean>(false);
  const [customerNameFilterData, setCustomerNameFilterData] = useState<
    string[]
  >([]);
  const [customerStatusFilterData, setCustomerStatusFilterData] =
    useState<{} | null>([]);
  const [driverNameFilterData, setDriverNameFilterData] = useState<string[]>(
    []
  );
  const [driverStatusFilterData, setDriverStatusFilterData] =
    useState<{} | null>([]);
  const [checkedCustomer, setCheckedCustomer] = useState([]);
  const [buttonTextdrivers, setButtonTextDriver] = useState('Update Drivers');

  useEffect(() => {
    buttonTextDriver[selectedProviderId] &&
      setButtonTextDriver(buttonTextDriver[selectedProviderId]);
  }, [selectedProviderId]);

  useEffect(() => {
    frameData();
    if (
      selectedProviderId === TAFS_PROVIDER_ID &&
      selectedProviderId !== OTR_PROVIDER_ID
    ) {
      manageIntegrationTabs[1].label = 'Drivers Mapping';
    } else {
      manageIntegrationTabs[1].label = 'Funding Accounts Mapping';
    }
  }, [tableData]);

  const contentRenderer = () => {
    const data: any = {
      dropDownValue: selectedRowData[0]?.mappingName
        ? selectedRowData[0]?.mappingName
        : null,
    };
    return <CreateDriverForm formData={data} />;
  };

  const frameData = () => {
    const td: RowActualData[] = [];
    if (tableData?.length > 0) {
      tableData.map((element: any) => {
        if (selectedTab === 'customersMapping') {
          const mappedData = {
            id: element?.id || '',
            axeleCustomer: element?.customerName || '',
            axeleCustomerMC: element?.customerMc || '',
            factoringCustomer: element?.mappingName || null,
            factoringId: element?.factoringId || '',
            organizationId: element?.organizationId || '',
            customerId: element?.customerId || '',
            dropDownValue: element?.mappingName || null,
            driverId: element?.driverId || '',
            code: element?.code || '',
            customerName: element?.mappingName || '',
            factoringCustomerMCs: element?.mappingCode || '',
          };
          td.push(mappedData);
        } else {
          const mappedData = {
            id: element?.id || '',
            axeleDriver: `${element?.firstname} ${element?.lastname}` || '',
            fundingAccountCode: element?.mappingName || null,
            driverInvoiceCode: element?.driverId || '',
            fundingAccountDescription: element?.mappingDescription || '',
            driverInvoiceNotes: element?.driverInvoiceNotes || '',
            factoringId: element?.factoringId || '',
            organizationId: element?.organizationId || '',
            driverSecurityID: element?.DriverSecurityID || '',
            firstname: element?.firstname,
            lastname: element?.lastname,
            driverId: element?.driverId || '',
            dropDownValue: element?.mappingName || null,
            mappingName: element?.mappingName || null,
            mappingId: element?.mappingId || null,
            mappingCode: element?.mappingCode || null,
            mappingDescription: element?.mappingDescription || null,
            code: element?.code || null,
          };
          td.push(mappedData);
        }
      });
    }
    setTableDisplayData(td);
  };

  const selectRowData = (ids: Array<string>) => {
    setCheckedCustomer(ids);
    const rowData: Array<RowData> = [];
    ids.forEach((data: string) => {
      tableDisplayData.forEach((item: RowData) => {
        if (data === item.id) {
          rowData.push(item);
        }
      });
    });
    setSelectedRowData(rowData);
  };

  const editDriverFormSubmit = async (data: any) => {
    const payLoad: RowActualData = {
      code: selectedRowData[0].code,
      driverInvoiceNotes: data.driverInvoiceNotes,
      factoringId: selectedRowData[0].factoringId,
      firstname: selectedRowData[0].firstname,
      lastname: selectedRowData[0].lastname,
      mappingCode: data.undefined?.mappingCode,
      mappingDescription: data.undefined?.mappingDescription,
      mappingId: data.undefined?.mappingId,
      mappingName: data.undefined?.mappingName,
      organizationId: selectedRowData[0].organizationId,
      driverId: selectedRowData[0].driverId,
      id: selectedRowData[0].id,
    };
    await financeIntegrationService.driverFactoringMapingRecord(payLoad);
    setFormDialogViewa(false);
  };

  const openUnlinkPopup = () => {
    unlinkByIDs(selectedRowData);
  };

  const getCustomerName = async (
    _customerName: string,
    _pageNumber: number
  ) => {
    try {
      const request = new GetCustomerRequest();
      request.pageNumber = _pageNumber;
      request.pageSize = gridPageSize;
      request.customerName = _customerName || '';
      const response = await financeIntegrationService.getCustomerList(request);
      return response;
    } catch (error) {
      return [];
    }
  };

  const getDriverName = async (_driverName: string, _pageNumber: number) => {
    try {
      const request = new GetDriverRequest();
      request.pageNumber = _pageNumber;
      request.pageSize = gridPageSize;
      request.nameFilter = _driverName || '';
      request.excludeStatuses = 0;
      const response = await financeIntegrationService.getDriverList(request);
      return response;
    } catch (error) {
      return [];
    }
  };

  const defaultCustomerFilterValue = {
    customerFilter: customerNameFilterData,
    statusFilter: customerStatusFilterData,
  };

  const defaultDriverFilterValue = {
    driverFilter: driverNameFilterData,
    driverStatusFilter: driverStatusFilterData,
  };

  const handleOnChangeCustomerCb = (newData: any, name: string) => {
    if (name === 'customerFilter') {
      const filterNewData = {
        type: 'Customer',
        value: newData,
      };
      setCustomerNameFilterData(newData);
      callBackFilters(filterNewData);
    }

    if (name === 'statusFilter') {
      const filterNewData = {
        type: 'Status',
        value: newData?.label
          ? [
              {
                name: newData?.label,
              },
            ]
          : [],
      };
      setCustomerStatusFilterData(newData);
      callBackFilters(filterNewData);
    }
    if (name === 'driverFilter') {
      const filterNewData = {
        type: 'Driver',
        value: newData,
      };
      setDriverNameFilterData(newData);
      callBackFilters(filterNewData);
    }

    if (name === 'driverStatusFilter') {
      const filterNewData = {
        type: 'Status',
        value: newData?.label
          ? [
              {
                name: newData?.label,
              },
            ]
          : [],
      };
      setDriverStatusFilterData(newData);
      callBackFilters(filterNewData);
    }
  };

  const customerFilterColumnConfig = [
    {
      key: 1,
      name: 'customerFilter',
      fieldName: 'name',
      getData: getCustomerName,
      label: 'Customer',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: 'statusFilter',
      fieldName: 'label',
      options: [
        { label: 'Linked', value: 'true' },
        { label: 'Unlinked', value: 'false' },
      ],
      label: 'Status',
      default: true,
      type: 'SingleAutocomplete',
    },
  ];

  const driverFilterColumnConfig = [
    {
      key: 1,
      name: 'driverFilter',
      fieldName: 'driverName',
      getData: getDriverName,
      label: 'Driver',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: 'driverStatusFilter',
      fieldName: 'label',
      options: [
        { label: 'Linked', value: 'true' },
        { label: 'Unlinked', value: 'false' },
      ],
      label: 'Status',
      default: true,
      type: 'SingleAutocomplete',
    },
  ];

  const driverBulkActionConfig = [
    {
      iconName: 'LinkOff',
      iconSource: 'MUIcons',
      label: 'Unlink',
      onClick: openUnlinkPopup,
      disabled: false,
    },
  ];

  const currentTheme = useTheme();

  const themeStyle = createTheme(currentTheme, {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            marginLeft: '0px',
          },
        },
      },
    },
  });

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <Grid className="top-section">
        <Grid className="tabs-section">
          <TabsV2
            keyName="key"
            isDarkMode
            labelCentered
            tabs={
              selectedProviderId === OTR_PROVIDER_ID
                ? OTRTabs
                : manageIntegrationTabs
            }
            selectedTab={selectedTab}
            setSelectedTab={(tab: ITabV2) => {
              onManageTabChanged(tab.key as string);
            }}
          />
        </Grid>
      </Grid>

      <Box
        className="buttons-group"
        sx={{ position: 'absolute', right: '5px', top: 0, zIndex: 1 }}
      >
        {selectedTab === 'customersMapping' ? (
          <Grid container>
            {selectedProviderId !== TAFS_PROVIDER_ID &&
              selectedProviderId !== OTR_PROVIDER_ID && (
                <Box>
                  <ButtonImproved
                    label="Update Customer List"
                    variant="outlined"
                    size="small"
                    onClick={() => refreshModalData('UpdateCustomerList')}
                    styleProps={{
                      color: '#fff',
                      borderColor: '#fff',
                      marginLeft: 1,
                    }}
                  />
                </Box>
              )}
            <ButtonImproved
              styleProps={{ ml: 1, color: '#fff', borderColor: '#fff' }}
              label="Update & Map Customers"
              variant="outlined"
              size="small"
              onClick={() => refreshModalData('UpdateMapCustomers')}
            />
          </Grid>
        ) : (
          <Grid container>
            <>
              {activeAccount && (
                <ButtonImproved
                  label={buttonTextdrivers}
                  variant="outlined"
                  size="small"
                  onClick={() => refreshModalData('UpdateDriverList')}
                  styleProps={{ color: '#fff', borderColor: '#fff' }}
                />
              )}
            </>
          </Grid>
        )}
      </Box>
      <ThemeProvider theme={themeStyle}>
        {selectedTab === 'customersMapping' &&
          tableColumnsCustomer &&
          tableColumnsCustomer.length > 0 && (
            <CommonDataGrid
              tableData={tableDisplayData}
              tableColumnsData={tableColumnsCustomer}
              checkboxSelection={true}
              rowHeight={60}
              filterColumns={customerFilterColumnConfig}
              defaultFilterValue={defaultCustomerFilterValue}
              settingsPanel={false}
              displayFilters={false}
              disableSelectionOnClick={true}
              selectRow={selectRowData}
              onPageFilterChange={handleOnChangeCustomerCb}
              cardBackgroundColor={'background.paper'}
              bulkAction={
                checkedCustomer.length > 0 ? driverBulkActionConfig : undefined
              }
              handleScroll={handleCustomerScroll}
              totalDataInTable={customerTotalRowCount}
              isRowSelectable={(params) => params.row.dropDownValue}
            />
          )}
        {selectedTab === 'fundingAccountsMapping' &&
          tableColumnsDriver &&
          tableColumnsDriver.length > 0 && (
            <CommonDataGrid
              tableData={tableDisplayData}
              tableColumnsData={tableColumnsDriver}
              checkboxSelection={true}
              rowHeight={60}
              filterColumns={driverFilterColumnConfig}
              defaultFilterValue={defaultDriverFilterValue}
              settingsPanel={false}
              displayFilters={false}
              customFooter={false}
              disableSelectionOnClick={true}
              selectRow={selectRowData}
              onPageFilterChange={handleOnChangeCustomerCb}
              cardBackgroundColor={'background.paper'}
              bulkAction={
                checkedCustomer.length > 0 ? driverBulkActionConfig : undefined
              }
              handleScroll={handleDriverScroll}
              totalDataInTable={driverTotalRowCount}
              isRowSelectable={(params) => params.row.mappingName}
            />
          )}
      </ThemeProvider>

      <FormDialog
        data={{
          driverName: selectedRowData[0]?.axeleDriver
            ? selectedRowData[0]?.axeleDriver
            : '',
          fundingAccount: selectedRowData[0]?.fundingAccountCode
            ? selectedRowData[0]?.fundingAccountCode
            : '',
          driverInvoiceCode: selectedRowData[0]?.driverInvoiceCode
            ? selectedRowData[0]?.driverInvoiceCode
            : null,
          driverInvoiceNotes: selectedRowData[0]?.driverInvoiceNotes
            ? selectedRowData[0]?.driverInvoiceNotes
            : null,
        }}
        titleText={'Map Driver'}
        actionLabel={'Save'}
        open={isFormDialogView}
        onAction={editDriverFormSubmit}
        handleClose={() => setFormDialogViewa(false)}
        contentRenderer={contentRenderer}
      />
    </Box>
  );
};

export interface ManageFactoreDataProps {
  tableData: TableData;
  onManageTabChanged: (arg: string) => void;
  selectedTab: string;
  tableColumns: Array<TableColumns>;
  filters: Array<IFilter>;
  unlinkSelectedDriver?: string;
  linkDriver?: string;
  updateDriverList?: string;
  selectedId: string;
  refreshModalData: (arg: string) => void;
  callBackFilters: (arg: callBackObject) => void;
  unlinkByIDs: (arg: Array<any>) => void;
  refreshTableData: () => void;
  selectedProviderId?: number;
  handleCustomerScroll: (event: any) => void;
  handleDriverScroll: (event: any) => void;
  customerTotalRowCount: number;
  driverTotalRowCount: number;
  activeAccount: boolean;
}

export interface RowActualDataForm {
  axeleDriverEmail?: string | null;
  axeleDriverId?: number;
  axeleDriverName?: string | null;
  axeleDriverSeqNumber?: string | null;
  credentialId: string;
  eldId: string | null;
  email: string | null;
  fetchedTime: string | null;
  firstname?: string | null;
  id: string | null;
  lastname?: string | null;
  mobileno: string | null;
  organizationId: number;
  providerId: number;
  providerName: string;
  terminalId: Array<string> | string | null;
  axeleTractorId?: null;
  axeleTractorName?: null;
  axeleTractorSeqNumber?: null;
  axeleTractorVin?: null;
  eldTractorVin?: string | null;
  tractorName?: string | null;
}

export interface RowData {
  organizationId: any;
  mappingName: any;
  code: any;
  factoringId: any;
  driverId: any;
  firstname: string;
  lastname: string;
  axeleDriver: string | null;
  fundingAccountCode: string | null;
  driverInvoiceCode: number | null | string;
  driverInvoiceNotes: string | null;
  EldEmail: string | null;
  EldId: string | null;
  Terminal: string | null;
  id: string | null;
  customerName: string;
}

export interface TableData {
  push: any;
  length: number;
  map: any;
  content: Array<RowActualData>;
  first: boolean;
  integrationDetails: IIntegrationDetails;
  last: boolean;
  number: number;
  numberOfElements: number;
  order: [];
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface TableColumns {
  field: string;
  flex: number;
  headerName: string;
}

export interface IFilter {
  field: string;
  label: string;
}

export interface IIntegrationDetails {
  credentialId: string;
  entityType: string;
  lastFetchedTime: string;
  linkedEntityCount: number;
  organizationId: number;
  totalEntityCount: number;
}

export interface RowActualData {
  fundingAccountDescription: null;
  fundingAccountCode: null;
  axeleDriver: string;
  id: number;
  driverId?: number;
  axeleId?: number;
  driverInvoiceNotes?: string;
  factoringId: string;
  firstname?: string;
  lastname?: string;
  mappingCode?: string;
  mappingDescription?: string;
  mappingId?: number;
  mappingName?: string;
  organizationId?: number;
  code?: number;
  customerId?: string;
  customerMc?: string;
  customerName?: string;
  dropDownValue?: string;
  factoringCustomerMCs?: string;
  axeleDriverName?: string;
}
