export const validateTotalMiles = (value) => {
  if (value?.length > 50) {
    return 'Cannot be over 50 chars';
  }
  if (Number(value) < 0) {
    return 'Cannot be negative';
  }
  if (!Number(value) && value) {
    return 'Has to be a number';
  }
  if (value === undefined) return 'Cannot be empty';
  return false;
};

export const validateRequired = (value) => {
  if (!value) {
    return 'Required';
  }
  return false;
};

export const validateMaxMile = (value, minMiles) => {
  const err: any = validateRequired(value);
  if (!err && value && minMiles && Number(value) < Number(minMiles)) {
    return 'Max Miles should be greater than Min Miles.';
  }
  return err;
};

export const validateWeight = (value) => {
  if (!value && value?.length <= 0) {
    return false;
  }
  if (Number(value) < 0) {
    return 'Cannot be negative';
  }
  if (Number(value) <= 0 || Number(value) > 999999) {
    return 'Value should be between 1 and 999,999';
  }
  if (value === undefined) {
    return 'Cannot be empty!';
  }
  if (value && !Number(value)) {
    return 'Should be a numeric value';
  }
  return false;
};

export const validateRate = (value) => {
  if (value && !Number(value) && value != 0) {
    return 'Should be a numeric value';
  }
  if (Number(value) < 0) {
    return 'Cannot be negative';
  }
  if (Number(value) > 999999.9999) {
    return 'Value should be between 0 and 999,999.9999';
  }
  return false;
};

export const zipOriginValidate = (value, params = undefined) => {
  if (value === undefined && params?.field === 'zipStartOrigin')
    return 'Cannot be empty';
  if (value && value?.length > 6) {
    return 'Cannot be longer than 6 chars';
  }
  if (
    params?.row?.zipEndOrigin?.value &&
    Number(value) > Number(params?.row?.zipEndOrigin?.value)
  ) {
    return 'Start zip cannot be smaller than end zip';
  }
  return false;
};

export const zipDestValidate = (value, params = undefined) => {
  if (value === undefined && params?.field === 'zipStartDest')
    return 'Cannot be empty';
  if (value && value?.length > 6) {
    return 'Cannot be longer than 6 chars';
  }
  if (
    params?.row?.destinationEndOrigin?.value &&
    Number(value) > Number(params?.row?.destinationEndOrigin?.value)
  ) {
    return 'Start zip cannot be smaller than end zip';
  }
};

export const zipEndOriginValidate = (value, params = undefined) => {
  if (value === undefined && params?.field === 'zipEndOrigin') return false;
  if (value && value?.length > 6) {
    return 'Cannot be longer than 6 chars';
  }
  if (
    params?.row?.zipStartOrigin?.value &&
    Number(value) < Number(params?.row?.zipStartOrigin?.value)
  ) {
    return 'Zip End Origin Should be greater than Zip Start Origin';
  }
  return false;
};

export const zipEndDestValidate = (value, params = undefined) => {
  if (value === undefined && params?.field === 'destinationEndtOrigin')
    return 'Cannot be empty';
  if (value && value?.length > 6) {
    return 'Cannot be longer than 6 chars';
  }
  if (
    params?.row?.destinationStartOrigin?.value &&
    Number(value) < Number(params?.row?.destinationStartOrigin?.value)
  ) {
    return 'Zip End Destination Should be greater than Zip Start Destination';
  }
};

export const isRowEmpty = (row) => {
  const arr = [];
  const keys = Object.keys(row);
  keys.forEach((k) => {
    if (k === 'id' || k === 'isNew') {
      return;
    }
    arr.push(row[k]?.value);
  });
  if (!arr.length) {
    return true;
  }
  return false;
};

export const laneItemValidator = (type, value, params = undefined) => {
  switch (type) {
    case 'totalMiles':
      return validateTotalMiles(value);
    case 'origin':
    case 'destination':
    case 'region':
      return validateRequired(value);
    case 'weight':
      return validateWeight(value);
    case 'rate':
      return validateRate(value);
    case 'minimumAmount':
      return validateRate(value);
    case 'zipStartOrigin':
      let errO: any = zipOriginValidate(value, params);
      if (!errO) {
        errO = validateRequired(value);
      }
      return errO;
    case 'destinationStartOrigin':
      let err: any = zipDestValidate(value, params);
      if (!err) {
        err = validateRequired(value);
      }
      return err;
    case 'zipEndOrigin':
      return zipEndOriginValidate(value, params);
    case 'destinationEndOrigin':
      return zipEndDestValidate(value, params);
    case 'minMiles':
      return validateRequired(value);
    case 'maxMiles':
      return validateMaxMile(value, params?.row?.minMiles?.value);
  }
};

export const validateAccessorialRate = (value, unit) => {
  if (!value || !value?.length) {
    return validateRequired(value);
  } else if (Number(value) < 0) {
    return 'Cannot be negative';
  } else {
    const rateVal = Number(value);
    if (value && isNaN(value)) {
      return 'Should be a numeric value';
    } else if (unit == 'PERCENT' && (rateVal < 0 || rateVal > 100)) {
      return 'Value must be between 0 and 100';
    } else if (rateVal < 0 || rateVal > 999999.9999) {
      return 'Value must be between 0 and 999,999.9999';
    } else {
      return false;
    }
  }
};

export const accessoriaValidator = (type, value, params = undefined) => {
  switch (type) {
    case 'rate':
      return validateAccessorialRate(value, params?.unit?.value);
    case 'rateType':
    case 'fuelSurchargeName':
      return validateRequired(value);
  }
};

export const tableErrStatus = (table) => {
  const hasErrCells = table.map((row) => {
    const keys = Object.keys(row);
    return keys.map((k) => {
      return row[k]?.isInvalid;
    });
  });
  return Boolean(hasErrCells.includes(true));
};

export * as ContractValidators from './validators';
