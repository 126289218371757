export enum StopSolutionV3StopType {
  LOAD = 'LOAD', //activity
  RELAY = 'RELAY',
  TRIP = 'TRIP',
  HOS = 'HOS',
}

export enum ExpandableTimelineV3PanelView {
  LoadDetails = 'LoadDetails', //Routes
  TripDetails = 'TripDetails',
  Loadsearch = 'Loadsearch',
}

export enum DriverActivityType {
  Driving = 'Driving',
  Offduty = 'Offduty',
  PreTripInspection = 'Pre Trip Inspection',
  Breakstop = 'Breakstop',
  AppointmentWork = 'Appointment Work',
}
export enum DriverActivityTypeDisplay {
  Driving = 'Driving',
  Offduty = 'Off Duty',
  PreTripInspection = 'Pre Trip Inspection',
  Breakstop = 'Break',
  AppointmentWork = 'Appointment Work',
}

export enum DriverHOSStatusType {
  OnDuty = 'On duty',
  OffDuty = 'Off duty',
  Driving = 'Driving',
  SleeperBed = 'Sleeper Bed',
}

export enum DriverHOSStatusTypeDisplay {
  OnDuty = 'ON-DUTY',
  OffDuty = 'OFF DUTY',
  Driving = 'DRIVING',
  SleeperBed = 'SLEEPER BED',
}
