import { Theme } from '@mui/material';

export const OptymizeBtnStyles = (theme: Theme) => ({
  border: '1px solid rgba(43, 100, 203, 0.5)',
  borderRadius: '10px',
  boxShadow: theme?.shadows?.[2],
  marginLeft: 'auto',
});

export const OptymizeBtnGroupStyles = {
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FFA726',
  borderRadius: '10px',
  height: '32px',
  marginTop: '4px',
};
