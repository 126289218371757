import MapsDriverToolTip from './DriverTooltip';
import { IDriverTooltip } from '@optym/gantt';
import {
  MapsProps,
  MapsToolTipProps,
  MarkersData,
  ToolTipContent,
  ToolTipHeader,
} from '../types';
import MapsToolTip from './MapsToolTip';
import { ESecondaryDetailsPanelType } from '../../../../constants/gantt/gantt.const';
import { SecondaryDetailsPanelType } from '../../../../types/DispatchTypes';
interface IDMapToolTip {
  type: string;
  tooltip: MarkersData['tooltip'] | any;
  onFixDriverHosLocation?: MapsProps['onFixDriverHosLocation'];
  onAction?: (data: SecondaryDetailsPanelType) => void;
}

const MapToolTip = (props: IDMapToolTip) => {
  switch (props?.type) {
    case 'SOLODRIVER':
    case 'TEAMDRIVER':
    case 'DRIVER':
    case 'ENROUTE':
      return (
        <MapsDriverToolTip
          {...(props?.tooltip as IDriverTooltip)}
          onFixDriverHosLocation={props?.onFixDriverHosLocation}
          onAction={props?.onAction}
        />
      );
    case 'HOS':
    case 'TIMEOFF':
    case 'DROPOFF':
    case 'PICKUP':
    case 'TRIP':
    case 'RELAY':
    case 'PICKUPANDDROPOFF':
    case 'TRIP_START':
    case 'TRIP_END':
      return (
        <MapsToolTip
          type={props.type}
          header={props?.tooltip?.content?.find(
            (item: ToolTipHeader) => item.key === 'Header'
          )}
          content={props?.tooltip?.content?.filter(
            (item: ToolTipContent) => !(item.key === 'Header')
          )}
          detention={props?.tooltip?.detention}
          delay={props?.tooltip?.delay}
          hos={props?.tooltip?.hos}
        />
      );
    default:
      return <></>;
  }
};

export default MapToolTip;
