import { PaginatedList } from '../../types';
import { Model } from './Model';

export class DispatcherShort extends Model {
  name: string;

  constructor(dto: any) {
    super({ id: dto.dispatcherId });
    this.name = dto.dispatcherName;
  }
}

export class TerminalShort extends Model {
  name: string;
  isPrimary: boolean;
  companyName: string;
  constructor(dto: any) {
    super(dto);
    this.isPrimary = dto.isPrimary;
    this.name = `${dto.companyName}${dto.isPrimary ? ' (Primary)' : ''}`;
    this.companyName = dto.companyName;
  }
}

export class AssignmentShort extends Model {
  name: string;
  tripId: string;
  constructor(dto) {
    super(dto);
    this.name = dto.name;
    this.tripId = dto.tripId;
  }
}
export class customerManifestType extends Model {
  name: string;
  id: string;
  constructor(dto) {
    super(dto);
    this.name = dto.name;
    this.id = dto.id;
  }
}
export class terminalsManifestType extends Model {
  companyName: string;
  id: string;
  constructor(dto) {
    super(dto);
    this.companyName = dto.companyName;
    this.id = dto.id;
  }
}

export class PaginatedTerminalsShortList extends PaginatedList {
  content!: TerminalShort[];
}

export class DriverShort extends Model {
  name: string;
  displayName: string;
  constructor(dto: any) {
    super(dto);
    this.name = dto.displayName;
  }
}

export class MaintenanceData extends Model {
  id: string;
  historyMaintenanceItems: Array<{
    id: string;
    itemCode: string;
    itemName: string;
  }>;
  constructor(dto) {
    super(dto);
    this.id = dto?.id;
    this.historyMaintenanceItems = dto?.historyMaintenanceItems;
  }
}
