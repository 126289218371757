import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { ListSubheader } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Fragment } from 'react';

export default function CustomersList({
  allCustomers,
  selectProvider,
  selectedProviderDetails,
}: IcustomerListTypes) {
  return (
    <List>
      {allCustomers &&
        allCustomers.length > 0 &&
        allCustomers.map(
          (
            data: {
              description?: string;
              logo?: object;
              providerId: number;
              providerName?: string;
              status?: string;
              headerName?: string;
              logoWidth?: string;
            },
            index: number
          ) => {
            console.log(data);
            return (
              <Fragment key={index}>
                <ListItem disablePadding>
                  {data.headerName ? (
                    <ListSubheader
                      sx={{ fontSize: 20, fontWeight: 500, color: '#001122' }}
                    >
                      {data.headerName}
                    </ListSubheader>
                  ) : (
                    <ListItemButton
                      sx={{
                        my: '12px',
                        p: '20px 12px',
                        justifyContent: 'space-between',
                        '&:hover': {
                          background: 'rgba(43, 100, 203, 0.2)',
                          borderRadius: '8px',
                        },
                        '&.selected-customer': {
                          background: 'rgba(43, 100, 203, 0.2)',
                          borderRadius: '8px',
                        },
                        '.MuiListItemIcon-root': {
                          '&.provider-logo': {
                            svg: {
                              width: data?.logoWidth ? data.logoWidth : '130px',
                              height: '25px',
                            },
                          },
                        },
                      }}
                      onClick={() => selectProvider(data)}
                      className={`${
                        data &&
                        data?.providerId === selectedProviderDetails?.providerId
                          ? 'selected-customer'
                          : ''
                      }`}
                    >
                      <ListItemIcon
                        aria-label="provider-name"
                        className="provider-logo"
                      >
                        {data.logo}
                      </ListItemIcon>
                      <span aria-label="status-icon">
                        {data.status === 'WARNING' && (
                          <WarningAmberOutlinedIcon sx={{ color: '#114199' }} />
                        )}
                        {data.status === 'VALID' && (
                          <DoneOutlinedIcon sx={{ color: '#114199' }} />
                        )}
                        {data.status === 'PENDING' && (
                          <AccessTimeOutlinedIcon sx={{ color: '#114199' }} />
                        )}
                      </span>
                    </ListItemButton>
                  )}
                </ListItem>
                {!data.headerName && <Divider />}
              </Fragment>
            );
          }
        )}
    </List>
  );
}

export interface IcustomerListTypes {
  allCustomers: IcustomerListWithLogo[];
  selectProvider: (arg: any) => void;
  selectedProviderDetails: any;
}

export interface IcustomerListWithLogo {
  description?: string;
  logo?: object;
  providerId: number;
  providerName?: string;
  status?: string;
  logoWidth?: string;
}
