import { Grid, useTheme } from '@mui/material';
import FinanceLoadKPI from '../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceLoadKPI/FinanceLoadKPI';

import { RefreshOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import LoadController from '../LoadController';
import {
  convertToTripSpecificFormat,
  setLoadStopsResequenceRules,
} from '../../../common/LoadTabPanel/tabs/Routes/uiUtils';
import { useLoadPermission } from '../../../common/hooks/useLoadPermission';
import { defaultEquipmentTypeOptions } from '../../settings/Preferences/constants';
import { getOrganizationId } from '../../../common/TimeoffDialog/utils';
import StorageManager from '../../../StorageManager/StorageManager';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import {
  Trip,
  TripStop,
} from '../../../common/LoadTabPanel/tabs/Routes/Models';
import { amountFormat, numberWithThousandSeparator } from '../../../utils';
import { cloneDeep } from 'lodash';
import { ESecondaryDetailsPanelType } from '../../../views/dispatch2/constants/types';
import { useSecondaryDetailsPanel } from '../../../../src/common/HOC/withSecondaryDetailsPanel/useSecondaryDetailsPanel';
import SecondaryDetailsPanel from '../../../../src/common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import {
  ExpandableTimelineV3PanelView,
  StopSolutionV3NormalStop,
  StopSolutionV3Prop,
  TripV3DataProps,
  TripV3ListWithAccordion,
} from '../../../ui-kit/TripPlan';
import {
  CreateManifestFormpropsType,
  IManifestInvoke,
  IManifestInvokeResponse,
  IPayloadIdsType,
  arraymove,
  mainfestInputStyle,
  mainfestKPIStyle,
  propsTypeCreateManifestForm,
  updatedListManifestStop,
} from './utils';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import SingleAutocomplete from '../../../ui-kit/components/SingleAutocomplete';

const CreateManifestForm = ({
  loadIds,
  updateRequest,
}: CreateManifestFormpropsType) => {
  const [tripDataList, setTripDataList] = useState<TripV3DataProps[]>([]);
  const [preViewData, setPreViewData] = useState<propsTypeCreateManifestForm>();
  const [trailerValue, setTrailerValue] = useState<string | undefined>('');
  const { hasLoadEditPermission } = useLoadPermission();
  const theme = useTheme();
  const { secondaryDetailsPanelData, setSecondaryDetailsPanelData } =
    useSecondaryDetailsPanel();
  const preferences = RootStoreInstence.getCompanyPreferences;
  useEffect(() => {
    if (!loadIds) return;
    getTripData(loadIds);
  }, [loadIds?.loadIds]);

  useEffect(() => {
    const payLoad = {
      tripData: tripDataList,
      trailer: trailerValue,
    };
    updateRequest(payLoad);
  }, [tripDataList, trailerValue]);
  const getTripData = (loadIds: IPayloadIdsType) => {
    LoadController.instance().getManifestPreview(
      loadIds,
      (result: propsTypeCreateManifestForm) => {
        setPreViewData(result);
        const requestsInvoke: IManifestInvokeResponse = {
          dispatcherId: StorageManager.getUser()?.id || null,
          organizationId: getOrganizationId(),
          selectedLoads: result?.trips || [],
        };
        const defaultValue = result?.trailerType || preferences?.trailerType;
        setTrailerValue(defaultValue);
        setPreViewData(result);
        updatedObject(result.trips);
        delayData(requestsInvoke, result);
      }
    );
  };

  const updateRequestList = (stop: StopSolutionV3NormalStop, flag: boolean) => {
    let indexNummber = 0;
    let indexNummberfisht = 0;
    tripDataList[0]?.stopList?.map(
      (item: StopSolutionV3NormalStop, index: number) => {
        if (item.id === stop.id) {
          indexNummber = index;
        }
      }
    );
    if (flag) {
      indexNummberfisht = indexNummber - 1;
    } else {
      indexNummberfisht = indexNummber + 1;
    }
    if (!preViewData) return;
    const preViewDataList = preViewData;
    preViewDataList.trips[0].stops = arraymove(
      preViewDataList.trips[0]?.stops,
      indexNummber,
      indexNummberfisht
    );
    updatedObject(preViewDataList.trips);
  };
  const updatedObject = (data: Trip[]) => {
    const tripDataListUpdated = convertToTripSpecificFormat(data, {
      openSecondaryDetailsHandler,
      hasLoadEditPermission,
      isManifest: loadIds?.manifestIds?.length ? false : true,
      isAssignmentDisable: true,
    });
    setLoadStopsResequenceRules(tripDataListUpdated, false, true, true, true);
    setTripDataList(tripDataListUpdated);
  };

  const openSecondaryDetailsHandler = (
    id: string,
    type: ESecondaryDetailsPanelType
  ): void => {
    const options = {
      isGlobal: true,
    };
    setSecondaryDetailsPanelData({
      id: id,
      type: type,
      options,
    });
  };

  const recalculation = () => {
    const tripListIds: any[] = [];
    tripDataList[0]?.stopList?.map((item: StopSolutionV3NormalStop) => {
      tripListIds.push({
        stopId: item.id,
        stopType: item.type,
        lat: item.location.center?.lat,
        lng: item.location.center?.lng,
      });
    });

    const requestsInvoke: IManifestInvokeResponse = {
      dispatcherId: StorageManager.getUser()?.id || null,
      organizationId: getOrganizationId(),
      selectedLoads: preViewData?.trips || [],
    };
    if (!preViewData) return;
    delayData(requestsInvoke, preViewData);
  };
  const getSelectedStop = (id: string, ItemList: Trip[]) => {
    return ItemList.find((item: { id: string; stops: TripStop }) => {
      if (item.id === id) return item.stops;
    });
  };
  let countAPIcall = 0;
  const delayData = (
    requestsInvoke: Array<IManifestInvokeResponse>,
    previewResponse: propsTypeCreateManifestForm
  ) => {
    LoadController.instance().manifestInvoke(requestsInvoke, (response) => {
      LoadController.instance().manifestReCalculation(
        previewResponse,
        (result: propsTypeCreateManifestForm) => {
          setPreViewData(result);
          updatedObject(result.trips);
          getSolution(result, response, previewResponse);
          countAPIcall = 0;
        }
      );
    });
  };

  const getSolution = async (
    result: propsTypeCreateManifestForm,
    response: IManifestInvoke,
    previewResponse: propsTypeCreateManifestForm
  ) => {
    const data = await LoadController.instance().getSolutionDelay(
      response.data
    );
    if (!data && data === null && countAPIcall < 5) {
      getSolution(result, response, previewResponse);
      countAPIcall = countAPIcall + 1;
      return;
    }
    if (!data) {
      return;
    }
    const updatedList = result.trips.map((tripItem) => {
      const selectedTrip = getSelectedStop(tripItem.id, data.data.trips);
      const filterData = tripItem.stops.map((item) => {
        const item2 = selectedTrip?.stops.find(
          (itemObject: TripStop) => itemObject.id === item.id
        );
        return item2 ? { ...item, ...item2 } : item;
      });
      tripItem.stops = filterData;
      return tripItem;
    });
    previewResponse = result;
    previewResponse.trips = updatedList;
    setPreViewData(previewResponse);
    updatedObject(previewResponse.trips);
  };

  const updatedListManifest = (
    stop: StopSolutionV3NormalStop,
    flag: boolean
  ) => {
    if (!preViewData) return;
    const preViewDataList = updatedListManifestStop(
      stop,
      flag,
      preViewData,
      tripDataList
    );
    setPreViewData(cloneDeep(preViewDataList));
    updatedObject(cloneDeep(preViewDataList.trips));
  };

  const trailerValueRender = (data: string) => {
    if (data)
      return (
        <SingleAutocomplete
          fieldName="value"
          fieldValue="key"
          disableClear={true}
          label={'Equipment Type'}
          defaultValue={data}
          options={defaultEquipmentTypeOptions}
          onChangeCb={(data: any) => {
            setTrailerValue(data?.key || '');
          }}
          name={'name'}
          customTextInputStyles={mainfestInputStyle}
          customInputProps={{
            borderRadius: '5px',
          }}
        />
      );
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid sx={mainfestKPIStyle}>
            <FinanceLoadKPI
              title="Trip Miles"
              value={`${numberWithThousandSeparator(
                preViewData?.tripMiles || 0,
                true
              )} mi`}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid sx={mainfestKPIStyle}>
            <FinanceLoadKPI
              title="Load Miles"
              value={`${numberWithThousandSeparator(
                preViewData?.loadedMiles || 0,
                true
              )} mi`}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid sx={mainfestKPIStyle}>
            <FinanceLoadKPI
              title="Revenue"
              value={`${amountFormat(
                Math.round(preViewData?.revenue || 0),
                false,
                true
              )}`}
              textColor={theme.palette.success.dark}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          {trailerValue && trailerValueRender(trailerValue)}
        </Grid>
        <Grid item xs={5} sx={{ mt: 1 }}>
          <ButtonImproved
            size="medium"
            color="primary"
            label="Recalculate KPIs"
            onClick={recalculation}
            startIcon={<RefreshOutlined />}
            variant="contained"
          />
        </Grid>
        <Grid sx={{ paddingTop: '25px', paddingLeft: '15px' }}>
          <TripV3ListWithAccordion
            onClickResequenceUpHandler={(stop: StopSolutionV3Prop) => {
              loadIds?.manifestIds?.length
                ? updatedListManifest(stop, true)
                : updateRequestList(stop, true);
            }}
            onClickResequenceDownHandler={(stop: StopSolutionV3Prop) => {
              loadIds?.manifestIds?.length
                ? updatedListManifest(stop, false)
                : updateRequestList(stop, false);
            }}
            tripDataList={tripDataList}
            isManifest={loadIds?.manifestIds?.length ? false : true}
            panelView={ExpandableTimelineV3PanelView.LoadDetails}
            idEditAddEnable={true}
            onClickLoadIdHandler={(stopSolution: StopSolutionV3Prop) => {
              const loadId = stopSolution?.loadDetails[0]?.loadId;
              if (loadId) {
                setSecondaryDetailsPanelData({
                  id: loadId,
                  type: ESecondaryDetailsPanelType.LOAD,
                  options: secondaryDetailsPanelData?.options,
                });
              }
            }}
          />
        </Grid>
      </Grid>
      {secondaryDetailsPanelData && (
        <SecondaryDetailsPanel
          type={secondaryDetailsPanelData.type}
          id={secondaryDetailsPanelData.id}
          onClose={() => {
            setSecondaryDetailsPanelData(null);
          }}
          isGlobal={true}
          readonly={true}
          data={secondaryDetailsPanelData.data}
        />
      )}
    </>
  );
};
export default CreateManifestForm;
