import { Type } from 'class-transformer';
import { Model } from '../../../models/common/Model';
import { PhoneDTO } from '../../../models/DTOs/Contacts/Contacts';
import { Address } from '../../../models/DTOs/commonMixed/smallHelperTypes';

export class ContactSummary extends Model {
  name: string;
  description: string;
  phoneData: PhoneDTO;
  email: string;
  isPrimary: boolean;
  seqNumber: number;

  constructor(dto: any) {
    super(dto);
    this.name = dto.name;
    this.description = dto.description;
    this.phoneData = dto.phoneData;
    this.email = dto.email;
    this.isPrimary = dto.isPrimary;
    this.seqNumber = dto.seqNumber;
  }
}
export class VendorSummaryTableRow extends Model {
  id!: string;
  seqNumber!: number;
  organizationId!: number;
  name!: string;
  fullAddress!: string;
  addressDTO!: Address;
  notes!: string;
  @Type(() => ContactSummary)
  contacts!: ContactSummary[];
  contact!: string;
  email!: string;
  @Type(() => PhoneDTO)
  phoneData!: PhoneDTO;
  preferredProhibitedEnum!: string;

  constructor(dto: any) {
    super(dto);
    const dtoKeys = Object.keys(dto);
    dtoKeys.forEach((dtoKey) => {
      // @ts-ignore
      this[dtoKey] = dto[dtoKey];
      if (dtoKey === 'addressDTO') {
        this.fullAddress = dto[dtoKey].fullAddress;
      }
      const primaryContact = dto?.contacts?.find(
        (contact: ContactSummary) => contact.isPrimary // @TODO: Nvard - investigate class transformer
      );
      if (primaryContact) {
        const { name, email, phoneData } = primaryContact;
        this.contact = name;
        this.email = email;
        this.phoneData = phoneData;
      }
    });
  }
}
