import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import IconsComponent from '../../ui-kit/components/IconsComponent';
import PermissionWrapper from '../Permission/components/PermissionWrapper';
import { MenuActionsIcon } from './constants';
import { ActionType, IMenuActions } from './types';

// TODOs: Permission part can be enhanced, ActionType can be enhanced (for example by onClickHandler), icon can be customizable
export default function MenuActions({
  actions,
  handleMenuClick,
  handleMenuClose,
  handleMenuItemClick,
  entityData,
  styleProps,
}: IMenuActions) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (typeof handleMenuClick === 'function') {
      handleMenuClick();
    }
  };
  const onMenuClose = () => {
    setAnchorEl(null);
    if (typeof handleMenuClose === 'function') {
      handleMenuClose();
    }
  };
  if (!actions.length) {
    return null;
  }
  return (
    <Box className="right">
      <IconButton onClick={onMenuClick} sx={styleProps}>
        <MenuActionsIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onMenuClose}>
        {actions?.map((action: ActionType, index: number) => (
          <PermissionWrapper permissions={action.permissions} key={index}>
            <MenuItem
              key={index}
              onClick={(event: MouseEvent<HTMLElement>) => {
                handleMenuItemClick(action, entityData, event);
                setAnchorEl(null);
              }}
              sx={{ padding: '12px' }}
            >
              {action.icon && (
                <ListItemIcon>
                  <IconsComponent
                    iconName={action.icon.name}
                    source={action.icon.source}
                  />
                </ListItemIcon>
              )}
              <Typography variant="inherit">{action.label}</Typography>
            </MenuItem>
          </PermissionWrapper>
        ))}
      </Menu>
    </Box>
  );
}
