import { createTheme } from '@mui/material/styles';

export const themeOptions = createTheme({
  palette: {
    themeColor: '#FFFFFF', // Needs to be themecolor // white or (black: #334455)
    primary: {
      main: '#2b66cb',
      light: '#699eff',
      dark: '#114199',
    },
    secondary: {
      main: '#008280',
      light: '#19afaf',
      dark: '#0c5654',
    },
    text: {
      primary: 'rgba(0,17,34,0.75)',
      secondary: 'rgba(0,17,34,0.6)',
      disabled: 'rgba(0,17,34,0.35)',
    },
    warning: {
      main: '#ed6c02',
      light: '#ffb547',
      dark: '#c77700',
    },
    info: {
      main: '#2196f3',
      light: '#64b6f7',
      dark: '#0b79d0',
    },
    error: {
      main: '#f44336',
      light: '#f88078',
      dark: '#e31b0c',
    },
    success: {
      main: '#4caf50',
      light: '#7bc67e',
      dark: '#3b873e',
      contrastText: '#ffffff',
    },
    background: {
      default: '#E8E9EE', // dark: '#000000',
      paper: '#35446B',
    },
  },
  typography: {
    fontFamily: ['Poppins'].join(','),
    fontSize: 12,
    fontWeightLight: 500,
  },
  shape: {
    borderRadius: 4,
  },
});
