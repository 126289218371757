import React, { FC, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { NumberToCurrency } from '../../../../../constants/numberToCurrency';
import { ILoadsearchTableItem } from '../../../loadTypes';
import DisabledMarketRate from './DisabledMarketRate';
import MarketRateInfo from './MarketRateInfo';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import { MarketRateResponse } from '../../../../../types/LoadboardTypes';
import { MarketRateRequest } from '../../../../../models/DTOs/Loadboard/Requests';
import moment from 'moment';
import { loadboardService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { getHasPermission } from '../../../../../common/Permission/utils/helperUtils';
import { AXELE_PERMISSION_TYPE } from '../../../../../common/Permission';

interface MarketRateCellProps {
  loadsearchTableItem: ILoadsearchTableItem;
  marketRate?: ILoadsearchTableItem['MarketRate'];
}

const MarketRate: FC<MarketRateCellProps> = ({
  marketRate,
  loadsearchTableItem,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const [marketRateDetail, setMarketRateDetail] = useState<
    MarketRateResponse | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const disabled = !getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CARGO_CHIEF_LIVE_LANE_RATES],
  });

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const currentTarget = event.currentTarget;
    const request = new MarketRateRequest({
      destinationCity: loadsearchTableItem.Dropoff.city,
      destinationState: loadsearchTableItem.Dropoff.country,
      equipmentType: loadsearchTableItem.Equipment.value,
      originCity: loadsearchTableItem.Pickup.city,
      originState: loadsearchTableItem.Pickup.country,
      pickupDate: moment(loadsearchTableItem.Pickup.date).format('YYYY-MM-DD'),
    });
    setIsLoading(true);
    const marketRateDetail = await loadboardService.getMarketRate(request);
    setIsLoading(false);
    if (marketRateDetail && !(marketRateDetail instanceof ServiceError)) {
      setMarketRateDetail(marketRateDetail);
      setAnchorEl(currentTarget);
    }
  };

  const handleClose = () => {
    if (!disabled) setAnchorEl(null);
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    if (disabled) setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '150%',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0.15px',
          }}
          gutterBottom
          color="primary.main"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Box flexGrow={1}>
              {`${NumberToCurrency(marketRate?.value as string)}/mi`}
            </Box>
            <Box
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <IconButton
                sx={{
                  p: 0,
                }}
                onClick={handleClick}
                disabled={disabled}
              >
                {isLoading ? (
                  <CircularProgress size={20} thickness={4} />
                ) : (
                  <IconsComponent
                    styleProps={{
                      width: 20,
                      height: 20,
                      color: disabled ? 'rgba(0, 17, 34, 0.26)' : undefined,
                    }}
                    source="AxeleIcon"
                    iconName="MonetizationOnIcon"
                  />
                )}
              </IconButton>
            </Box>
          </Stack>
        </Typography>
        <Typography
          variant="caption"
          gutterBottom
          sx={{ color: '#000', fontWeight: 500 }}
        >
          {marketRate?.subvalue}
        </Typography>
      </Box>
      <Popover
        sx={{
          pointerEvents: disabled ? 'none' : undefined,
          '.MuiPopover-paper': {
            boxShadow:
              '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
            borderRadius: 2,
          },
          pl: 2,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 36,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        {disabled ? (
          <DisabledMarketRate onClose={handleMouseLeave} />
        ) : (
          marketRateDetail && (
            <MarketRateInfo onClose={handleClose} data={marketRateDetail} />
          )
        )}
      </Popover>
    </>
  );
};

export default MarketRate;
