import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useExpensesSettings } from '../../contexts/ExpensesContext';
import { expenseTypes } from '../../subPages/expenses/constants';
import { ImportIcon } from '../../ui-kit/components/Assets';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';

const bodyTextStyles = {
  color: 'rgba(0, 17, 34, 0.75)',
  letterSpacing: '0.15px',
  fontSize: 20,
  fontWeight: 500,
  lineHeight: '160%',
  marginBottom: '38px',
};

const titleTextStiles = {
  color: '#2B64CB',
  fontSize: 60,
  fontWeight: 300,
  marginBottom: 4,
};

export default function EmptyPage({
  title,
  body,
  buttonLabel = 'Import Instead',
  maxWidth = 350,
  entity,
  disabled,
}: {
  title: string;
  body: string;
  buttonLabel?: string;
  maxWidth?: number;
  entity: string;
  finishImportCb?: (data: unknown) => void;
  disabled?: boolean;
}) {
  const navigate = useNavigate();
  const { currentView } = useExpensesSettings();

  function handleNavigate() {
    navigate('/settings/import', {
      state: {
        subPage: entity,
        selectedValue: expenseTypes[currentView?.entity],
      },
    });
  }

  return (
    <Box sx={{ padding: '56px 48px' }}>
      <Typography sx={titleTextStiles}>{title}</Typography>
      <Typography sx={{ ...bodyTextStyles, maxWidth: maxWidth }}>
        {body}
      </Typography>
      {!disabled && (
        <ButtonImproved
          disabled={disabled}
          label={buttonLabel}
          onClick={handleNavigate}
          startIcon={<ImportIcon />}
          variant={'contained'}
        />
      )}
    </Box>
  );
}
