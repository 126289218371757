import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { loadTenderService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces/helperTypes';
import TextField from '../../../../../common/Ui/TextField/TextField';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { stepCountStyle } from '../../LoadTender/style/turvoStyle';

import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import { RootStoreInstence } from '../../../../../store/root-store/rootStateContext';
import { getCustomerNamesList } from '../../../../operations/contacts/utils/customer';
import { RegisterBrokerValidationSchema } from './TurvoUtil';

export default function TurvoBrokerForm({
  isEditForm = false,
  getTenderList,
  formData,
  closeDrawer,
}: {
  isEditForm?: boolean;
  formData: object | any;
  getTenderList: () => void;
  closeDrawer: (cardData: boolean) => void;
}) {
  const state = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(RegisterBrokerValidationSchema),
  });

  const {
    handleSubmit,
    setValue,
    control,
    formState: { isDirty },
    reset,
    getValues,
  } = state;
  const [broker] = getValues(['broker']);
  useEffect(() => {
    reset();
    if (formData && isEditForm) {
      reset(formData);
    }
    if (!isEditForm) {
      setdefaultData();
    }
  }, []);
  const setdefaultData = () => {
    const Defaultdata = {
      id: null,
      broker: '',
      username: '',
      carrierId: '',
      password: '',
      activationName: '',
      clientId: '',
      clientSecret: '',
      turvoApiKey: '',
    };
    reset(Defaultdata);
  };
  const onSubmitHandler = async (data: any) => {
    const userStorage = StorageManager.getUser();

    const payLoadObject = {
      ...data,
      broker: data?.broker?.id,
      brokerName: data.broker.name,
      organizationId: userStorage.organizationId,
    };
    let response;
    if (isEditForm) {
      response = await loadTenderService.updateBroker({ ...payLoadObject });
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: response.message,
      });
    } else {
      response = await loadTenderService.registerBroker({ ...payLoadObject });
    }
    if (!(response instanceof ServiceError)) {
      closeDrawer && closeDrawer(false);
      getTenderList();
    }
  };
  const handleCustomerChange = (data: any) => {
    setValue('broker', data);
  };
  return (
    <FormProvider {...state}>
      <Stepper activeStep={0} orientation={'vertical'}>
        <Step key={22} expanded={true}>
          <StepLabel sx={{ ...stepCountStyle }}>
            Enter Activation Details
          </StepLabel>
          <StepContent>
            <SingleAutocompleteForm
              id={'broker'}
              control={control}
              name={'broker'}
              fieldName={'name'}
              fieldValue={'id'}
              label={'Broker'}
              defaultValue={broker?.name || ''}
              required
              onChangeCb={handleCustomerChange}
              getOptions={(searchText: string, pageNumber: number) =>
                getCustomerNamesList(searchText, pageNumber)
              }
            />
            <TextField
              control={control}
              name={`carrierId`}
              label={'Carrier Id'}
              sizes={{ xs: 12 }}
              required
            />
            <TextField
              control={control}
              name={`activationName`}
              label={'Activation Name'}
              sizes={{ xs: 12 }}
              required
            />
          </StepContent>
        </Step>
        <Step key={23} expanded={true}>
          <StepLabel sx={{ ...stepCountStyle }}>
            Enter Turvo Credentials
          </StepLabel>
          <StepContent>
            <TextField
              control={control}
              name={`clientId`}
              label={'Client ID'}
              sizes={{ xs: 12 }}
              required
            />
            <TextField
              control={control}
              name={`clientSecret`}
              label={'Client Secret'}
              sizes={{ xs: 12 }}
              required
            />
            <TextField
              control={control}
              name={`username`}
              label={'Turvo Username'}
              sizes={{ xs: 12 }}
              required
            />
            <TextField
              control={control}
              name={`password`}
              label={'Turvo Password'}
              sizes={{ xs: 12 }}
              type={'password'}
              inputProps={{ autoComplete: 'new-password' }}
              required
            />
            <TextField
              control={control}
              name={`turvoApiKey`}
              label={'Turvo API Key'}
              sizes={{ xs: 12 }}
              required
            />
            <Box display="flex" justifyContent="start" sx={{ height: '42px' }}>
              <ButtonImproved
                size="large"
                variant="contained"
                disabled={isEditForm && !isDirty ? true : false}
                label="Save"
                styleProps={{ height: 42 }}
                onClick={handleSubmit(onSubmitHandler)}
              />
            </Box>
          </StepContent>
        </Step>
      </Stepper>
    </FormProvider>
  );
}
