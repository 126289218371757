/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { MarkersData } from '../../common/Ui/Maps/types';
import {
  IBookmarkedLoadsResponse,
  IDriverDataResponse,
  ILoadSearchGetSolutionResponse,
  ILoadSearchInvokeResponse,
} from '../../models/DTOs/Loadsearch/Model';
import {
  AssignLSLoadToDriverRequest,
  AvailableLoadboards,
  BookmarkLoadRequest,
  DeleteSavedLanesRequest,
  GetBookmarkListRequest,
  GetDriverListRequest,
  GetEquipmentRequest,
  GetSavedLanesRequest,
  LoadInstantBookingRequest,
  LoadSearchGetSolutionRequest,
  LoadSearchInvokeRequest,
  LoadsearchMapDataRequest,
  SaveBidOnLoadRequest,
  SaveKpiRequest,
  SaveLaneRequest,
  UnBookmarkLoadRequest,
  UpdateBidLoadRequest,
} from '../../models/DTOs/Loadsearch/Request';
import { ServiceError } from './helperTypes';
import { Service } from './Service';
export abstract class ILoadsearchService extends Service {
  abstract getLoadsearchList(
    requestData: any
  ): Promise<any | null | ServiceError>;

  abstract saveLane(
    payload: SaveLaneRequest
  ): Promise<any | null | ServiceError>;

  abstract getSavedLanes(
    payload: GetSavedLanesRequest
  ): Promise<any | null | ServiceError>;

  abstract getEquipmentsList(
    payload: GetEquipmentRequest
  ): Promise<any | null | ServiceError>;

  abstract deleteSavedLane(
    payload: DeleteSavedLanesRequest
  ): Promise<any | null | ServiceError>;

  abstract getAvailableLoadboards(
    requestData: AvailableLoadboards
  ): Promise<AvailableLoadboards | null | ServiceError>;

  abstract saveCalcultorKpi(
    payload: SaveKpiRequest
  ): Promise<SaveKpiRequest | null | ServiceError>;

  abstract bookmarkedLoad(
    requestData: BookmarkLoadRequest
  ): Promise<IBookmarkedLoadsResponse | null | ServiceError>;

  abstract unBookmarkedLoad(
    requestData: UnBookmarkLoadRequest
  ): Promise<any | null | ServiceError>;

  abstract loadInstantBooking(
    requestData: LoadInstantBookingRequest
  ): Promise<any | null | ServiceError>;

  abstract getBookmarkedList(
    requestData: GetBookmarkListRequest
  ): Promise<any | null | ServiceError>;

  abstract getDriversList(
    requestData: GetDriverListRequest
  ): Promise<Array<IDriverDataResponse> | null | ServiceError>;

  //Trip Plan tab in the Loadsearch Detail Panel  begin
  abstract getLSInvoke(
    payload: LoadSearchInvokeRequest
  ): Promise<ILoadSearchInvokeResponse | null | ServiceError>;

  abstract getLSGetSolution(
    payload: LoadSearchGetSolutionRequest
  ): Promise<ILoadSearchGetSolutionResponse | null | ServiceError>;
  //Trip Plan tab in the Loadsearch Detail Panel end

  abstract assignLSLoadToDriver(
    payload: AssignLSLoadToDriverRequest,
    additionalDetails: { [key: string]: any }
  ): Promise<{} | null | ServiceError>;

  abstract saveBidOnLoad(
    requestData: SaveBidOnLoadRequest
  ): Promise<any | null | ServiceError>;

  abstract updateBidLoad(
    requestData: UpdateBidLoadRequest
  ): Promise<any | null | ServiceError>;

  abstract getloadboardMapData(
    payload: LoadsearchMapDataRequest
  ): Promise<MarkersData | null | ServiceError>;
}
