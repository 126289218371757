import { t } from 'i18next';
import { maintenanceItemService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { RelativeDateRangeUtils } from '../../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { CommonConstants } from '../../../../locales/en/common/index';
import { ExpensesConstant } from '../../../../locales/en/finance/expenses/index';
import { ExpensesEntityTypeEnum } from '../../../../models';
import { IMaintenanceItem } from '../../../../models/DTOs/maintenance/maintenanceItem/Model';
import { ViewMap, ViewMetaData } from '../../../../types';
import {
  getDriverNameListShort,
  getExpenseCategoriesList,
  getExpensesListShort,
  getExpensesListShortByCardNumber,
  getFuelTypesList,
  getLoadList,
  tractorList,
  trailerList,
} from '../utils';
export const statusFilter = 'statusFilter';
export const companyCardFilter = 'companyCardFilter';
export const expenseIdFilter = 'expenseIdFilter';
export const maintananceItemFilter = 'maintenanceItems';
export const dateRangeFilter = 'dateRangeFilter';
export const expenseCategoryFilter = 'expenseCategoryFilter';
export const fuelTypeFilter = 'fuelTypeFilter';
export const cardFilter = 'cardFilter';
export const loadIdFilter = 'loadIdFilter';
export const tractorListFilter = 'tractorListFilter';
export const trailerListFilter = 'trailerListFilter';
export const driverListFilter = 'driverListFilter';
export const statusOptions = [
  { id: 'IN_REVIEW', name: t('needReview') },
  { id: 'REVIEWED', name: t('reviewed') },
];
export const companyCardOptions = [
  { id: 'COMPANY_CARD', name: t('companyCardCash') },
  { id: 'NON_COMPANY_CARD', name: t('nonCompanyCardCash') },
];

export const expenseColumns = [
  { name: 'seqNumber', label: CommonConstants.id, show: true, disabled: true },
  {
    name: 'paidDate',
    label: CommonConstants.paidDate,
    show: true,
    disabled: false,
  },
  {
    name: 'status',
    label: CommonConstants.status,
    show: true,
    disabled: false,
  },
  {
    name: 'category',
    label: CommonConstants.category,
    show: true,
    disabled: false,
  },
  {
    name: 'equipmentName',
    label: CommonConstants.equipment,
    show: true,
    disabled: false,
  },
  {
    name: 'maintenanceDetails',
    label: CommonConstants.maintenanceDetails,
    show: true,
    disabled: false,
  },
  {
    name: 'loadsIdAndName',
    label: ExpensesConstant.loadId,
    show: true,
    disabled: false,
  },
  {
    name: 'companyExpense',
    label: ExpensesConstant.companyCardCash,
    show: true,
    disabled: false,
  },
  {
    name: 'finalAmount',
    label: ExpensesConstant.expenseAmount + ' ($)',
    show: true,
    disabled: false,
  },
  {
    name: 'settlementAmount',
    label: ExpensesConstant.settlementAmount + ' ($)',
    show: true,
    disabled: false,
  },
  {
    name: 'amount',
    label: ExpensesConstant.netExpense + ' ($)',
    show: true,
    disabled: false,
  },
  {
    name: 'payments',
    label: ExpensesConstant.settlements + ' #',
    show: true,
    disabled: false,
  },
  {
    name: 'paidBy',
    label: ExpensesConstant.paidBy,
    show: true,
    disabled: false,
  },
  {
    name: 'paidTo',
    label: ExpensesConstant.paidTo,
    show: true,
    disabled: false,
  },
  {
    name: 'createdBy',
    label: ExpensesConstant.createdBy,
    show: true,
    disabled: false,
  },
  {
    name: 'lastUpdated',
    label: ExpensesConstant.lastUpdated,
    show: true,
    disabled: false,
  },
  {
    name: 'referenceNumber',
    label: ExpensesConstant.refNum,
    show: true,
    disabled: false,
  },
  {
    name: 'description',
    label: CommonConstants.description,
    show: true,
    disabled: false,
  },
  {
    name: 'assignedDocuments',
    label: ExpensesConstant.attachment,
    show: true,
    disabled: false,
  },
];

export const getFiltersFormConfig = ({
  entity,
  terminalIds,
}: {
  entity: ExpensesEntityTypeEnum;
  terminalIds: string[];
}) => {
  const statusOptions = [
    { id: 'IN_REVIEW', name: t('needReview') },
    { id: 'REVIEWED', name: t('reviewed') },
  ];

  const companyCardOptions = [
    { id: 'COMPANY_CARD', name: t('companyCardCash') },
    { id: 'NON_COMPANY_CARD', name: t('nonCompanyCardCash') },
  ];

  const config = [
    {
      key: 1,
      name: statusFilter,
      fieldName: 'name',
      options: statusOptions,
      label: t('status'),
      default: true,
      type: 'SingleAutocomplete',
    },
    {
      key: 2,
      name: companyCardFilter,
      fieldName: 'name',
      options: companyCardOptions,
      label: t('companyCardCash'),
      default: true,
      type: 'SingleAutocomplete',
    },
    {
      key: 3,
      name: expenseIdFilter,
      fieldName: 'seqNumber',
      getData: (name: string, pageNumber: number) => {
        const categoryKey =
          entity === ExpensesEntityTypeEnum.fuelExpense ? ['FUEL'] : undefined;
        const excludeCategoryKey =
          entity === ExpensesEntityTypeEnum.otherExpense ? ['FUEL'] : undefined;
        return getExpensesListShort(
          name,
          pageNumber,
          terminalIds,
          categoryKey,
          excludeCategoryKey
        );
      },
      label: t('expenseID'),
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 11,
      name: maintananceItemFilter,
      fieldName: 'label',
      getData: async (searchString: string, pageNumber: number) => {
        const response = await maintenanceItemService.getByOrganizationId();
        if (response instanceof ServiceError) {
          return {
            content: [],
            last: true,
          };
        }
        return {
          content: response.map((item: IMaintenanceItem) => {
            return {
              value: item.id,
              label: item.itemName,
            };
          }),
          last: true,
        };
      },
      label: 'Maintanance Item',
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 4,
      name: dateRangeFilter,
      fieldName: 'name',
      startText: 'From',
      endText: 'To',
      label: t('expenseExpDateRange'),
      default: false,
      ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
    },
    {
      key: 5,
      name: expenseCategoryFilter,
      fieldName: 'itemName',
      getData: (name: string, pageNumber: number) => {
        const excludeCategoryKey =
          entity === ExpensesEntityTypeEnum.otherExpense ? 'FUEL' : undefined;
        return getExpenseCategoriesList(name, pageNumber, excludeCategoryKey);
      },
      label: t('expenseCategory'),
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 6,
      name: fuelTypeFilter,
      fieldName: 'value',
      getData: getFuelTypesList,
      label: t('fuelType'),
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 7,
      name: cardFilter,
      fieldName: 'cardNumber',
      getData: (cardNumber: string, pageNumber: number) => {
        const categoryKey =
          entity === ExpensesEntityTypeEnum.fuelExpense ? ['FUEL'] : undefined;
        const excludeCategoryKey =
          entity === ExpensesEntityTypeEnum.otherExpense ? ['FUEL'] : undefined;
        return getExpensesListShortByCardNumber(
          cardNumber,
          pageNumber,
          terminalIds,
          categoryKey,
          excludeCategoryKey
        );
      },
      label: t('fuelCard'),
      type: 'MultipleAutocomplete',
      default: false,
    },
    {
      key: 8,
      name: loadIdFilter,
      fieldName: 'seqNumber',
      getData: (neme: string, pageNumber: number) =>
        getLoadList(neme, pageNumber, terminalIds),
      label: t('loadId'),
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 9,
      name: tractorListFilter,
      fieldName: 'name',
      getData: (neme: string, pageNumber: number) =>
        tractorList(neme, pageNumber, terminalIds, ['UNAVAILABLE', 'INACTIVE']),
      label: t('tractor'),
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 10,
      name: trailerListFilter,
      fieldName: 'name',
      getData: (neme: string, pageNumber: number) =>
        trailerList(neme, pageNumber, terminalIds, ['UNAVAILABLE', 'INACTIVE']),
      label: t('trailer'),
      default: false,
      type: 'MultipleAutocomplete',
    },
    {
      key: 11,
      name: driverListFilter,
      fieldName: 'name',
      getData: (neme: string, pageNumber: number) =>
        getDriverNameListShort(neme, pageNumber),
      label: t('driver'),
      default: false,
      type: 'MultipleAutocomplete',
    },
  ];
  if (entity === ExpensesEntityTypeEnum.allExpenses) return config;

  return entity === ExpensesEntityTypeEnum.fuelExpense
    ? config.filter((item) => item.name !== 'expenseCategoryFilter')
    : config.filter(
        (item) => item.name !== 'fuelTypeFilter' && item.name !== 'cardFilter'
      );
};

export type expenseFiltersType = {
  statusFilter?: ViewMetaData;
  companyCardFilter?: ViewMetaData;
  expenseIdFilter?: ViewMetaData;
  dateRangeFilter?: ViewMetaData;
  expenseCategoryFilter?: ViewMetaData;
  maintananceItemFilter?: ViewMetaData;
  fuelTypeFilter?: ViewMetaData;
  loadIdFilter?: ViewMetaData;
  tractorListFilter?: ViewMetaData;
};

export const expenseFilters = {
  [statusFilter]: null,
  [companyCardFilter]: null,
  [expenseIdFilter]: [],
  [dateRangeFilter]: null,
  [expenseCategoryFilter]: [],
  [maintananceItemFilter]: [],
  [fuelTypeFilter]: [],
  [cardFilter]: [],
  [loadIdFilter]: [],
  [tractorListFilter]: [],
  [trailerListFilter]: [],
  [driverListFilter]: [],
};

export const expenseColumnsInfo = {
  seqNumber: true,
  paidDate: true,
  status: true,
  category: true,
  equipmentName: true,
  maintenanceDetails: true,
  loadsIdAndName: true,
  driverName: true,
  companyExpense: true,
  finalAmount: true,
  settlementAmount: true,
  amount: true,
  payments: true,
  paidBy: true,
  paidTo: true,
  createdBy: true,
  lastUpdated: true,
  referenceNumber: true,
  description: true,
  cardNumber: true,
  fuelQuantity: true,
  assignedDocuments: true,
};

export const allExpensesView = {
  id: 'allExpenses',
  shared: true,
  parentId: null,
  name: ExpensesConstant.financeExpensesAllExpenses,
  entity: 'allExpenses',
  iconName: 'AttachMoneyOutlined',
  source: 'MUIcons',
  active: true,
  default: true,
  columnFilters: expenseColumnsInfo,
  metaData: expenseFilters,
};

export const fuelExpenseView = {
  id: 'fuelExpense',
  shared: true,
  parentId: null,
  name: ExpensesConstant.financeExpensesFuel,
  entity: 'fuelExpense',
  iconName: 'LocalGasStationOutlined',
  source: 'MUIcons',
  active: true,
  default: true,
  columnFilters: expenseColumnsInfo,
  metaData: expenseFilters,
};

export const otherExpenseView = {
  id: 'otherExpense',
  name: ExpensesConstant.financeExpensesOther,
  iconName: 'MoreHorizOutlined',
  source: 'MUIcons',
  entity: 'otherExpense',
  shared: true,
  default: true,
  columnFilters: expenseColumnsInfo,
  metaData: expenseFilters,
};

export const formDefaultValuesMap: ViewMap = {
  allExpenses: allExpensesView,
  fuelExpense: fuelExpenseView,
  otherExpense: otherExpenseView,
};
