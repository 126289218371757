import StorageManager from '../../../StorageManager/StorageManager';

export class Payload {
  organizationId!: number;
  userId!: number;

  constructor(setOrgId = false) {
    if (setOrgId) {
      let organizationId;
      const userStorage = StorageManager.getUser();

      if (userStorage) {
        organizationId = userStorage.organizationId;
      }
      if (!this.organizationId && organizationId) {
        this.organizationId = organizationId;
      }
    }
  }

  addUserId() {
    const user = StorageManager.getUser();
    if (user) {
      this.userId = user.id;
    }
  }
}
