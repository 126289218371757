import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  CreateNotesDTO,
  DeleteNotesDTO,
  EditNotesDTO,
  EntityNoteItem,
  GetNotesDTO,
  NotesListRes,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { INotesService, annotateNameAsync, ServiceError } from '../interfaces';
import {
  CreateEntityNotesRequest,
  DeleteEntityNotesRequest,
  GetEntityNotesRequest,
  UpdateEntityNotesRequest,
} from '@/models/DTOs/Notes/Requests';

const getNotesListURL = '/web/load/api/v2/loadnotes/list/load/id';
const createURL = '/web/load/api/v2/loadnotes';
const deleteURL = '/web/load/api/v2/loadnotes/delete/id';
const updateURL = '/web/load/api/v2/loadnotes';

export class NotesService extends INotesService {
  @annotateNameAsync
  async getNotesList(
    requestData: GetNotesDTO
  ): Promise<Array<NotesListRes> | null> {
    try {
      return await httpClient.get<Array<NotesListRes>>(
        getNotesListURL,
        requestData,
        NotesListRes,
        true,
        true
      );
    } catch (error) {
      console.error(error);
      return Promise.resolve(null);
    }
  }

  async createNotesData(payloadData: CreateNotesDTO) {
    try {
      const response = await httpClient.post<NotesListRes>(
        createURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createNotesData',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'createNotesData',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async deleteNotesData(payloadData: DeleteNotesDTO) {
    try {
      const response = await httpClient.deleteRaw(deleteURL, payloadData, null);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteNotesData',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'deleteNotesData',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async editNotesData(payloadData: EditNotesDTO) {
    try {
      const response = await httpClient.put<NotesListRes>(
        updateURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'editNotesData',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'editNotesData',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getEntityNotes(
    requestData: GetEntityNotesRequest
  ): Promise<EntityNoteItem[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `/web/asset/api/v2/asset-notes/list`,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async deleteEntityNotes(
    requestData: DeleteEntityNotesRequest
  ): Promise<boolean | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        `/web/asset/api/v2/asset-notes`,
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async createEntityNote(
    requestData: CreateEntityNotesRequest
  ): Promise<EntityNoteItem | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `/web/asset/api/v2/asset-notes`,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async updateEntityNote(
    requestData: UpdateEntityNotesRequest
  ): Promise<EntityNoteItem | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `/web/asset/api/v2/asset-notes`,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }
}
