import { PhoneType } from '../../../../types';
import { LoadTemplateDetailsDTO } from '../types/requests';
import { IQueryStringInputObject } from '../types/types';

export const generateQueryStringFromArray = (
  inputObject?: IQueryStringInputObject
) => {
  const out = [];
  for (const key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      out.push(key + '=' + encodeURIComponent(inputObject[key]));
    }
  }
  return '?' + out.join('&');
};

export const formatCustomerDataFromTemplate = (
  data: LoadTemplateDetailsDTO['customer']
) => ({
  customerName: data?.customerName,
  customerId: data?.customerId,
  customerContactId: data?.customerContactId,
  invoice: data?.id,
  contacts: data?.contacts,
  referenceNumber: data?.referenceNumber,
  requiredDocuments: [],
  loadPriority: 'None',
});

export const formatLoadDetailsFromTemplate = (
  load: LoadTemplateDetailsDTO['loadDetails']
) => ({
  equipment: load?.trailerTypes,
  temp: load?.equipmentAdditionalDetails?.temperature,
  // loadType
  // broker
  weight: load?.totalWeight,
  notes: load?.notes,
  commodities: load?.commodityData,
});

export const toBase64 = async (file: File) =>
  new Promise<{
    src: string | ArrayBuffer | null;
    fileName: string;
    size: number;
  }>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({
        src: reader.result,
        fileName: file.name,
        size: file.size,
      });
    reader.onerror = (error) => reject(error);
  });

export const formatPhoneNumber = (phoneData: PhoneType) => {
  if (!phoneData?.phone) return '';
  //Filter only numbers from the input
  const cleaned = ('' + phoneData?.phone).replace(/\D/g, '');
  const cleanedExtension = ('' + phoneData?.extension).replace(/\D/g, '');

  //Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  const extension = phoneData?.extension ? '' + cleanedExtension : '';

  if (match) {
    if (!extension) {
      return ' (' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return (
      ' (' + match[1] + ') ' + match[2] + '-' + match[3] + ' x' + extension
    );
  }
  return '';
};

export const formatTextToUpperCase = (str?: string) => {
  if (typeof str !== 'string') return str;
  const s = str.toLowerCase()?.replaceAll('_', ' ');
  return s[0]?.toUpperCase?.() + s.slice?.(1);
};

export const toFixDigit = (value: number, digit = 2): string => {
  if (isNaN(value)) return '-';
  const number = Number(value)?.toFixed(digit);
  return Number(number).toLocaleString();
};
