import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';

const IntegrationActivationPanel = ({
  status,
  toggleDrawer,
  Type,
  showActivationBtn,
  axelePermissions,
}: IProps) => {
  const elements = [
    {
      title: Type && Type === 'otherFactore' ? 'Not Available' : 'Instant',
      subtitle: 'Activation',
      icon: <LockOpenOutlinedIcon />,
    },
    {
      title: Type && Type === 'otherFactore' ? 'Not Available' : 'Password',
      subtitle: 'Authentication',
      icon: <BoltOutlinedIcon />,
    },
  ];
  const wrapperStyles = {
    alignItems: 'center',
    color: 'primary.main',
    pt: '16px',

    '&:first-of-type': {
      pt: 0,
    },
  };
  return (
    <Box>
      <Stack direction="column">
        {elements.map((data, index) => {
          return (
            <Stack
              className="multiline-icon-wrapper"
              direction="row"
              key={index}
              sx={wrapperStyles}
            >
              {data.icon}
              <Stack sx={{ pl: '16px' }}>
                <Typography
                  variant="subtitle1"
                  fontSize={20}
                  letterSpacing={0.15}
                  lineHeight={'normal'}
                >
                  {data.title}
                </Typography>
                <Typography
                  variant="caption"
                  color={'text.secondary'}
                  letterSpacing={0.15}
                  fontSize={14}
                  fontWeight={400}
                >
                  {data.subtitle}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      {(status === 'UNAVAILABLE' || showActivationBtn) &&
        axelePermissions.showAddBtn && (
          <Button
            className="activation-status"
            variant="contained"
            size="small"
            sx={{
              borderRadius: 2.5,
              fontSize: 14,
              lineHeight: '24px',
              p: '6px 16px',
              width: '100%',
              textTransform: 'capitalize',
              mt: 2,
            }}
            onClick={() => toggleDrawer?.(true)}
          >
            {Type && Type === 'otherFactore' ? 'Add Account' : 'Activate'}
          </Button>
        )}
    </Box>
  );
};

export interface IProps {
  status: string;
  toggleDrawer?: any;
  Type?: string;
  showActivationBtn?: boolean;
  axelePermissions?: any;
}

export default IntegrationActivationPanel;
