import React, { useState } from 'react';
import { DriverTeamWithTrips } from '../../../models';
import { useStores } from '../../../store/root.context';
import DispatchController from '../utils/Controller';
import { useDispatchSettings } from './DispatchProvider';
// import { useDispatch2Store } from '../../../views/dispatch2/hooks';

export interface DispatchDetailsPanelContextType {
  refreshDispatchDataByDriverGroup: () => Promise<void>;
  driverTeam: DriverTeamWithTrips | undefined;
  fetchDriverGroupDataWithTrips: (groupId: string) => Promise<void>;
  onChangedDetailsPanel: () => Promise<void>;
  lastUpdateTime: number;
}

export const DispatchDetailsPanelContext =
  React.createContext<DispatchDetailsPanelContextType>(
    {} as DispatchDetailsPanelContextType
  );

export const DispatchDetailsPanelProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const { refreshGanttDriverHandler } = useDispatchSettings();
  const {
    dispatch2GlobalStore: { setRefreshDriverIdsList },
  } = useStores();

  const controller = DispatchController.instance();

  const [driverTeam, setDriverTeam] = useState<DriverTeamWithTrips>();
  const [lastUpdateTime, setLastUpdateTime] = useState<number>(0);

  const fetchDriverGroupDataWithTrips = async (
    driverGroupId: string
  ): Promise<void> => {
    if (driverGroupId) {
      const data = await controller.fetchDriverGroupDataWithTrips(
        driverGroupId
      );
      if (data) {
        setDriverTeam(data);
      }
    }
  };

  const refreshDispatchDataByDriverGroup = async () => {
    controller.setSkipTrackPromise(true);
    setLastUpdateTime(new Date().getTime());
  };

  const onChangedDetailsPanel = async () => {
    const driverId = driverTeam?.drivers?.[0]?.id || null;
    if (driverId) {
      refreshGanttDriverHandler?.([driverId]);
      setRefreshDriverIdsList([driverId]);
    }
    refreshDispatchDataByDriverGroup?.();
  };

  return (
    <DispatchDetailsPanelContext.Provider
      value={{
        refreshDispatchDataByDriverGroup,
        driverTeam,
        fetchDriverGroupDataWithTrips,
        onChangedDetailsPanel,
        lastUpdateTime,
      }}
    >
      {children}
    </DispatchDetailsPanelContext.Provider>
  );
};

export const useDispatchDetailsPanelSettings = () =>
  React.useContext(DispatchDetailsPanelContext);
