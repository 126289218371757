import { lazy } from 'react';
import AxeleDialog from '../../../../ui-kit/components/AxeleDialog/AxeleDialog';
import { MapsProps } from '../types';
import { t } from 'i18next';

const Maps = lazy(() => import('../Maps'));

const PopupMap = (props: MapsProps) => {
  return (
    <AxeleDialog
      titleText={t('loadsRoutesMapView')}
      open={props.openPopup || false}
      handleClose={props.handleClose}
      height={'80vh'}
      renderComponent={<Maps {...props} />}
    />
  );
};

export default PopupMap;
