import useFilterStorage from '../../../services/storage';
import { DISPATCH_SETTINGS } from '../config';
import { IDispatchFilters } from '../constants/types';

//centralize dispatch storage
export const useDispatchStorage = () => {
  const component = DISPATCH_SETTINGS.page;
  const entity = DISPATCH_SETTINGS.entity;
  const LStorageHook = useFilterStorage({ page: component });

  const updateStorage = (newFilters: Partial<IDispatchFilters>): void => {
    const storageData = LStorageHook.getStorage() || {};
    LStorageHook.updateStorage(entity, {
      ...storageData[entity],
      ...newFilters,
    });
  };

  const getCurrentStorage = (): IDispatchFilters => {
    const storageData = LStorageHook.getStorage() || {};
    return storageData[entity];
  };

  return {
    updateStorage: updateStorage,
    getCurrentStorage: getCurrentStorage,
  };
};
