import { geofenceType } from '@/common/GeoCoding/GeoCodingCustomStyle';
import Trimblemaps from '@/common/GeoCoding/Trimblemaps';
import { addressType } from '@/common/Ui/AddressField/constants';
import fetchAddress from '@/utils/fetchAddress';
import { useTheme } from '@emotion/react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getCreateLoadPanelStyles } from '../../styles';
import { StopMapProps } from '../../utils/stops';

const StopsMap: React.FC<StopMapProps> = ({
  onClose,
  isOpen,
  address,
  callbackAddress,
  stopIndex,
}) => {
  const theme = useTheme();
  const styles = getCreateLoadPanelStyles(theme as Theme);

  const [geofenceData, setGeofenceData] = useState<geofenceType>();
  const [enableSaveBtn, setEnableSaveBtn] = useState<boolean>(false);
  useEffect(() => {
    const data = {
      center: address?.center!,
      geoCoordinates: address?.geoCoordinates!,
    };
    setGeofenceData(data);
  }, [address]);

  const getAddressDetails = async (data?: { lat: number; lng: number }) => {
    if (!data) return;
    const queryString = `${data.lat},${data.lng}`;
    const locations = await fetchAddress(queryString);

    if (locations) {
      const newAddress: addressType = {
        fullAddress: locations[0]?.ShortString || '',
        address: locations[0]?.ShortString,
        city: locations[0]?.Address?.City,
        state: locations[0]?.Address?.State,
        streetAddress: locations[0]?.Address?.StreetAddress,
        streetAddress2: '',
        geoCoordinates: geofenceData?.geoCoordinates,
        center: {
          lat: locations[0]?.Coords.Lat,
          lng: locations[0]?.Coords.Lon,
        },
        zipcode: locations[0]?.Address?.Zip,
      };
      callbackAddress(cloneDeep(newAddress));
    }
  };

  const renderAddressMap = (data?: addressType, readOnly?: boolean) => {
    return (
      <Trimblemaps
        addressData={data}
        readOnly={readOnly}
        valueName={`stops.${stopIndex}.location`}
        getGeoFenceData={(geoCoordinates: Array<Array<number>>) => {
          if (!geofenceData) return;
          const geoData = geofenceData;
          geoData.geoCoordinates = geoCoordinates;
          setGeofenceData(geoData);
          setEnableSaveBtn(true);
        }}
        getGeoCodingData={(center) => {
          if (!geofenceData) return;
          const geoData = geofenceData;
          geoData.center = center;
          setGeofenceData(geoData);
          setEnableSaveBtn(true);
        }}
      />
    );
  };

  const handleClose = () => {
    setEnableSaveBtn(false);
    onClose?.();
  };

  return (
    <Dialog
      sx={styles.dialog}
      onClose={handleClose}
      open={isOpen}
      maxWidth="lg"
    >
      <Stack direction="row" alignItems="center" sx={{ m: 0, p: 2 }}>
        <DialogTitle sx={{ flexGrow: 1, p: 0, paddingRight: 2 }}>
          Set Location and Geofence
          <Typography variant="body2" color="textSecondary">
            Adjust location by dragging pin. Set geofence by clicking corners of
            area, complete fence by clicking back on starting point.
          </Typography>
        </DialogTitle>
        <Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </Stack>
      <DialogContent>
        <Stack gap={2}>
          <Box
            sx={{
              backgroundColor: 'transparent',
              flexGrow: 1,
              pl: '13px',
              pr: '13px',
              height: '400px',
            }}
          >
            {renderAddressMap(address, false)}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ gap: '10px', padding: '15px 20px' }}>
        <Button onClick={handleClose} size="large">
          Cancel
        </Button>
        <ButtonGroup
          variant="contained"
          size="large"
          sx={{ boxShadow: 'none' }}
        >
          <Button
            disabled={!enableSaveBtn}
            onClick={() => {
              getAddressDetails(geofenceData?.center);
              handleClose();
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default StopsMap;
