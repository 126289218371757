import { Box } from '@mui/system';
import React from 'react';
import { SxProps, Theme } from '@mui/material';

interface FormItemProps {
  children: React.ReactNode;
}

export const FormItem: React.FC<FormItemProps> = ({
  children,
}: FormItemProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        padding: '5px 0',
      }}
    >
      {children}
    </Box>
  );
};

interface FormContainerProps {
  children: React.ReactNode;
  scrollable?: boolean;
  scrollableHeight?: string;
  styleProps?: SxProps<Theme>;
}

export const FormContainer: React.FC<FormContainerProps> = ({
  children,
  scrollable = false,
  scrollableHeight,
  styleProps = {},
}: FormContainerProps) => {
  return (
    <Box
      sx={{
        ...styleProps,
        ...(scrollable && {
          height: scrollableHeight,
          maxHeight: scrollableHeight,
          overflowY: 'auto',
          overflowX: 'hidden',
        }),
      }}
    >
      {children}
    </Box>
  );
};
