import Box from '@mui/material/Box';
import { useContext } from 'react';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../../Permission';
import NoData from '../../../component/NoData';
import { NotesContext, deletePopOver } from '../constants/constant';
import { ListTypes, NotesContextType } from '../constants/interface';
import { NotesItem } from './NotesItem';
import { SingleNote } from './SingleNote';
import { t } from 'i18next';

export const NotesContent = () => {
  const {
    openCreate,
    list,
    onCreate,
    deleteOpen = false,
    setDeleteOpen,
    performDelete,
  }: NotesContextType = useContext(NotesContext);

  const renderButton = () => (
    <Box display="flex" justifyContent="end">
      <ButtonImproved
        size="medium"
        variant="contained"
        disabled={false}
        label={t('newNote')}
        styleProps={{ mt: 2 }}
        startIcon={<IconsComponent iconName="AddOutlined" source="MUIcons" />}
        onClick={onCreate}
      />
    </Box>
  );

  const renderDeletePopup = () => (
    <DeletePopup
      open={deleteOpen}
      onAction={() => performDelete()}
      onClose={() => {
        setDeleteOpen(false);
      }}
      title={deletePopOver.title}
      body={deletePopOver.body}
    />
  );

  if (!list) {
    return <NoData />;
  }

  return (
    <>
      <Permission contains={[AXELE_PERMISSION_TYPE.LOAD_EDIT]}>
        {renderButton()}
      </Permission>
      {renderDeletePopup()}
      {openCreate && <SingleNote mode="create" />}
      {list.map((item: ListTypes, index: number) =>
        item.editMode ? (
          <SingleNote mode="edit" item={item} />
        ) : (
          <NotesItem item={item} index={index} />
        )
      )}
    </>
  );
};
