import { Stack } from '@mui/material';
import { FC } from 'react';
import Filters from '../../ui-kit/components/DataGridPro/filter/Filters';

type FiltersProps = Parameters<typeof Filters>[0];

const StyledFilters: FC<FiltersProps> = (props) => {
  return (
    <Stack
      direction="row"
      height={'100%'}
      sx={{
        '> .MuiBox-root': {
          pt: '0px',
          alignItems: 'center',
          display: 'flex',
        },
        '.MuiFormControl-root': {
          minHeight: 'unset',
          height: 'unset',
        },
      }}
    >
      <Filters {...props} />
    </Stack>
  );
};

export default StyledFilters;
