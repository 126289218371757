import moment from 'moment';
import {
  OPTYMIZATION_GANTT_PAGE_SIZE,
  TRIP_STATUSES,
} from '../../../constants/gantt/gantt.const';
import { toFixDigit } from '../../../services/gantt';
import { IGanttFilters } from '../../../types/DispatchTypes';
import {
  DEFAULT_GANTT_DATE_FORMAT,
  GANTT_DEFAULT_START_DATE,
  defaultGanttTimelineRange,
} from '../config/gantt.config';
import {
  RECOMMENDED_RESOURCE_TYPE,
  RESOURCE_STATE,
} from '../constants/optymization.const';
import { ERecommendedTripStatus } from '../constants/recommendedTrips.filters';
import {
  IGanttTimelineRange,
  IRecommendedResource,
  IRecommendedTrip,
} from '../types/gantt.types';
import { PossibleDriverResourceType } from '../types/possibleResource.types';
import {
  ILockAssignedMetrices,
  IRecommendTrips,
  IRecommendTripsPayload,
  IRecommendedTripFilters,
} from '../types/recommendTrips.types';
import {
  IBulkOptimizationRequestPayload,
  IBulkOptymization,
} from '../types/requestPayload.types';
import { filterRecommendationsByType } from './filters.service';
import {
  geSandboxResourceEntities,
  getCurrentResourceState,
} from './operations.service';

export const formatTripsToMap = (
  trips: IRecommendTripsPayload['trips']
): IRecommendTrips['trips'] => {
  const tripsMap = new Map();
  if (!trips) return tripsMap;
  trips?.forEach((trip) => {
    if (trip?.resourceId) {
      const existingTrips = tripsMap.get(trip.resourceId);
      {
        trip.miles = toFixDigit(trip?.miles as number);
        trip.tripId = trip.id;
        trip.tripPrimaryId = trip.id;
        trip.isEndGap = trip?.endDate && trip?.destination ? false : true;
        trip.id = `${trip.resourceId}${trip.id || trip?.startDate}`;
        trip.middleText = '';
        trip.endDate = validateTripEndDate(trip) as string;
        trip.status =
          trip.type === TRIP_STATUSES.EMPTY
            ? TRIP_STATUSES.DEADHEAD
            : trip.status;
        trip.type = '';
        tripsMap.set(
          trip.resourceId,
          existingTrips ? [...existingTrips, trip] : [trip]
        );
      }
    }
  });
  return tripsMap;
};

export const validateTripEndDate = (trip: IRecommendedTrip): Date | string => {
  const { startDate, endDate } = trip;
  const startTime = startDate?.split('[')?.[0];
  const endTime = endDate?.split('[')?.[0];
  if (isTripEndGap(trip))
    return moment(moment(startTime).format())
      .add(6, 'hours')
      .format(DEFAULT_GANTT_DATE_FORMAT);
  if (moment(startTime).isSame(endTime))
    return moment(startTime)
      .add(1, 'minutes')
      .format(DEFAULT_GANTT_DATE_FORMAT);
  return endDate;
};

const isTripEndGap = (trip: IRecommendedTrip) =>
  !(trip?.endDate && trip?.destination);

export const formatTimeoffsToMap = (
  timeoffs: IRecommendTripsPayload['timeoffs']
): IRecommendTrips['timeoffs'] => {
  const timeoffsMap = new Map();
  timeoffs?.forEach((timeoff) => {
    const existingTrips = timeoffsMap.get(timeoff.resourceId);
    timeoff.durationToDisplay = timeoff?.duration;
    timeoffsMap.set(
      timeoff.resourceId,
      existingTrips ? [...existingTrips, timeoff] : [timeoff]
    );
  });
  return timeoffsMap;
};

export const formatDriversListToMap = (
  drivers: IRecommendTripsPayload['drivers'],
  allTripsData?: IRecommendTrips['trips']
): {
  driversMap: IRecommendTrips['drivers'];
  lockedDrivers: Set<string>;
  assignedCandidatesLockedCount: Set<string>;
  brokeredTripLockedCount: Set<string>;
} => {
  const driversMap = new Map();
  driversMap.set(RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER, new Map());
  driversMap.set(RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP, new Map());
  driversMap.set(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER, new Map());
  driversMap.set(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP, new Map());
  const lockedDrivers = new Set<string>();
  const assignedCandidatesLockedCount = new Set<string>();
  const brokeredTripLockedCount = new Set<string>();
  drivers?.forEach((driver, i: number) => {
    if (isResourceTripDetailsPresent(driver)) {
      const driverTrips = allTripsData?.get(driver?.id);
      if (isResourceTypeAssignedDriver(driver)) {
        driver.selectedTripData = driverTrips?.filter?.((trip) =>
          driver.engineMultiTripOutput?.tripIdSelected?.includes?.(
            trip.tripPrimaryId as string
          )
        );
      } else if (
        isResourceTypeUnassignedTrip(driver) ||
        isResourceTypeBrokeredTrip(driver)
      ) {
        driver.selectedTripData = [
          driverTrips?.find(
            (d) => d.resourceId === driver.id
          ) as IRecommendedTrip,
        ];
      }
    }
    driver.resourceState = getCurrentResourceState(driver);
    driversMap.set?.(
      driver?.resourceType,
      driversMap?.get?.(driver?.resourceType).set?.(driver.id, driver)
    );
    if (driver?.engineMultiTripOutput?.isLocked) {
      lockedDrivers?.add(driver?.id);
      if (isResourceTypeAssignedDriver(driver))
        assignedCandidatesLockedCount.add(driver?.id);
      else if (isResourceTypeBrokeredTrip(driver))
        brokeredTripLockedCount.add(driver?.id);
    }
  });
  return {
    driversMap,
    lockedDrivers,
    assignedCandidatesLockedCount,
    brokeredTripLockedCount,
  };
};

export const convertTripsMapToList = (
  entityMap: IRecommendTrips['trips']
): Array<any> => {
  if (!entityMap || !entityMap?.size) return [];
  const allLists = Array?.from(entityMap?.values?.());
  const lists = [];
  for (const list of allLists) lists?.push(...list);
  return lists;
};

export const convertTimeoffsMapToList = (
  entityMap: IRecommendTrips['timeoffs']
): Array<any> => {
  if (!entityMap || !entityMap?.size) return [];
  const allLists = Array?.from(entityMap?.values?.());
  const lists = [];
  for (const list of allLists) lists?.push(...list);
  return lists;
};

export const getFilteredRecommendedDrivers = (
  drivers: IRecommendTrips['drivers'],
  filters: IRecommendedTripFilters,
  rejectedResource: IRecommendTrips['currentRejectedResource']
) => {
  return convertDriversMapToList(
    filterRecommendationsByType(drivers, filters),
    rejectedResource
  );
};

export const convertDriversMapToList = (
  driversMap: IRecommendTrips['drivers'],
  rejectedResource: IRecommendTrips['currentRejectedResource']
): Array<IRecommendedResource> => {
  const {
    assignedMap,
    brokeredTrips,
    unassignedDriversMap,
    unassignedTripsMap,
  } = geSandboxResourceEntities(driversMap);

  // if only one rejected item present, it is brokered trip else if more than one, rejected item is from assigned candidate(driver and trip)
  const rejectedSection =
    rejectedResource?.rejectedResources?.size === 1
      ? brokeredTrips
      : assignedMap;
  const rejectedSectionList = Array.from(rejectedSection?.values?.());
  if (
    rejectedResource?.rejectedResources?.size &&
    rejectedResource?.index! >= 0
  ) {
    const rejectedResourceValList = Array.from(
      rejectedResource?.rejectedResources?.values?.()
    );
    const unassignedDriverIndex = rejectedResourceValList?.findIndex(
      (d) => d.resourceType === RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER
    );
    if (unassignedDriverIndex >= 0) {
      const obj = { ...rejectedResourceValList[unassignedDriverIndex] };
      //shift driver object at first index
      rejectedResourceValList.splice?.(unassignedDriverIndex, 1);
      rejectedResourceValList?.unshift?.(obj);
    }
    rejectedSectionList?.splice?.(
      rejectedResource?.index!,
      0,
      ...rejectedResourceValList
    );
    for (const [driverId, driver] of rejectedResource?.rejectedResources) {
      unassignedDriversMap?.delete(driverId);
      unassignedTripsMap?.delete(driverId);
    }
  }
  const assignedCandidatesArr = assignedMap?.values?.() ?? [];
  const unassignedDrivers = unassignedDriversMap?.values?.() ?? [];
  const unassignedTrips = unassignedTripsMap?.values?.() ?? [];
  const brokeredTripsArr = brokeredTrips?.values?.() ?? [];
  if (rejectedResource?.rejectedResources?.size === 1)
    return [
      ...assignedCandidatesArr,
      ...rejectedSectionList,
      ...unassignedDrivers,
      ...unassignedTrips,
    ];
  return [
    ...rejectedSectionList,
    ...brokeredTripsArr,
    ...unassignedDrivers,
    ...unassignedTrips,
  ];
};

export const isDriverReassignAllowed = (
  data: IRecommendedResource
): boolean => {
  if (isResourceLocked(data)) return false;
  return true;
};

export const isTripReassignAllowed = (data: IRecommendedResource): boolean => {
  if (data?.resourceType === RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP)
    return true;
  return false;
};

export const isResourceRejected = (data: IRecommendedResource): boolean => {
  const engineMultiTripOutput = data?.engineMultiTripOutput ?? {};
  if (!Object.keys(engineMultiTripOutput)?.length) return true;
  return false;
};

export const isDriverActionDisabled = (data: IRecommendedResource): boolean => {
  if (isResourceTypeAssignedDriver(data) || isResourceTypeBrokeredTrip(data))
    return false;
  return true;
};

export const isResourceLocked = (driver: IRecommendedResource): boolean =>
  Boolean(driver?.engineMultiTripOutput?.isLocked);

export const isResourceTripDetailsPresent = (
  driver: IRecommendedResource
): boolean =>
  Boolean(
    driver?.resourceType === RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER ||
      driver?.resourceType === RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP ||
      driver?.resourceType === RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP
  );

export const updateDriverResourceState = (
  lock: boolean,
  driver: IRecommendedResource
) => {
  if (lock) {
    driver.resourceState = RESOURCE_STATE.LOCKED;
    return;
  }
  driver.resourceState = RESOURCE_STATE.UNLOCKED;
};

export const isRecommendTripTooltipDisabled = (driver: IRecommendedResource) =>
  driver.resourceType === RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER;

export const isRecommendedDriverTooltipDisabled = (
  driver: IRecommendedResource
) => driver.resourceType === RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP;

export const putNewUnassignedTrip = (
  data: IRecommendTrips['drivers'],
  newDriver: IRecommendedResource
) => {
  const drivers = data?.get(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP);
  if (!drivers) return new Map();
  if (drivers?.has(newDriver?.id)) drivers.delete(newDriver?.id);
  const driversArr = [newDriver, ...drivers?.values?.()];
  const driversMap = new Map();
  for (const driverArr of driversArr) driversMap.set(driverArr.id, driverArr);
  return driversMap;
};

export const putNewUnassignedDriver = (
  data: IRecommendTrips['drivers'],
  newDriver: IRecommendedResource
) => {
  const drivers = data?.get(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER);
  if (!drivers) return new Map();
  if (drivers?.has(newDriver?.id)) drivers.delete(newDriver?.id);
  const driversArr = [newDriver, ...drivers?.values?.()];
  const driversMap = new Map();
  for (const driverArr of driversArr) driversMap.set(driverArr.id, driverArr);
  return driversMap;
};

export const putNewAssignedTrip = (
  data: IRecommendTrips['drivers'],
  newDriver: IRecommendedResource
) => {
  const drivers = data?.get(RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER);
  if (!drivers) return new Map();
  if (drivers?.has(newDriver?.id)) drivers.delete(newDriver?.id);
  const driversArr = [newDriver, ...drivers?.values?.()];
  const driversMap = new Map();
  for (const driverArr of driversArr) driversMap.set(driverArr.id, driverArr);
  return driversMap;
};

export const putNewBrokeredTrip = (
  data: IRecommendTrips['drivers'],
  newDriver: IRecommendedResource
) => {
  const drivers = data?.get(RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP);
  if (!drivers) return new Map();
  if (drivers?.has(newDriver?.id)) drivers.delete(newDriver?.id);
  const driversArr = [newDriver, ...(drivers?.values?.() ?? [])];
  const driversMap = new Map();
  for (const driverArr of driversArr) driversMap.set(driverArr.id, driverArr);
  return driversMap;
};

export const deleteAssignedRecommendationById = (
  data: IRecommendTrips['drivers'],
  id: string
) => {
  if (!data || !id) return;
  const drivers = data?.get(RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER);
  return drivers?.delete(id);
};
export const deleteOptymizeRecommendationById = (
  data: IRecommendTrips['drivers'],
  id: string
) => {
  if (!data || !id) return;
  const assigned = data?.get(RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER);
  const unAssigned = data?.get(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER);
  const unAssignedTrips = data?.get(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP);
  const brokeredTrips = data?.get(RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP);
  assigned?.delete(id);
  unAssigned?.delete(id);
  unAssignedTrips?.delete(id);
  brokeredTrips?.delete?.(id);
};

export const deleteDriverTimeline = (
  trips: IRecommendTrips['trips'],
  id: string
) => {
  if (!trips || !id) return;
  return trips?.delete(id);
};

export const deleteDriverBreaks = (
  timeoffs: IRecommendTrips['timeoffs'],
  id: string
) => {
  if (!timeoffs || !id) return;
  return timeoffs?.delete(id);
};

export const formatLockedAssigmentMetrics = (
  lockedAssignmentMetrics: ILockAssignedMetrices
): ILockAssignedMetrices => {
  if (!lockedAssignmentMetrics)
    return {
      totalLoadedMiles: 0,
      totalDeadheadMiles: 0,
      totalRevenue: '$0',
      countLockedAssignments: 0,
    };
  return {
    totalLoadedMiles: `${(
      Math.floor(lockedAssignmentMetrics?.totalLoadedMiles as number) ?? 0
    )?.toLocaleString()}`,
    totalDeadheadMiles: `${(
      Math.floor(lockedAssignmentMetrics?.totalDeadheadMiles as number) ?? 0
    )?.toLocaleString()}`,
    totalRevenue: `$${(
      Math.floor(lockedAssignmentMetrics?.totalRevenue as number) ?? 0
    )?.toLocaleString()}`,
    countLockedAssignments: lockedAssignmentMetrics?.countLockedAssignments,
  };
};

export const generateGanttTimelineRange = (
  startDate: Date | string,
  range = 14
): IGanttTimelineRange => {
  if (!startDate) return defaultGanttTimelineRange;
  const sd = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  return {
    startDate: moment(sd)
      ?.subtract(7, 'days')
      ?.startOf('day')
      ?.format?.(DEFAULT_GANTT_DATE_FORMAT),
    endDate: moment(sd)
      ?.add(range, 'days')
      .startOf('day')
      .format?.(DEFAULT_GANTT_DATE_FORMAT),
  };
};

export const formatGanttStartDate = (date: Date | string) => {
  if (!date) date = GANTT_DEFAULT_START_DATE;
  return moment(date)?.startOf('day')?.format(DEFAULT_GANTT_DATE_FORMAT);
};

export const getRecommendationRequestPayload = ({
  driverFilters,
  driverIds,
  tripIds,
  tripFilters,
}: IBulkOptymization): IBulkOptimizationRequestPayload => {
  const payload = {
    ...driverFilters,
    driverGroupId: driverIds,
    isFirstSandboxRun: true,
    sandboxId: '',
    selectedIds: tripIds,
    tripFilterDTO: {
      ...tripFilters,
      terminalIds: driverFilters?.terminalIds,
      organizationId: driverFilters?.organizationId,
    },
  };
  delete payload?.pageNumber;
  delete payload?.organizationId;
  delete payload?.sort;
  return payload;
};
export const getRecommendationRequestParams = (
  driverFilters: IGanttFilters
) => {
  return {
    organizationId: driverFilters?.organizationId,
    sort: driverFilters?.sort,
    pageSize: OPTYMIZATION_GANTT_PAGE_SIZE,
    pageNumber: driverFilters?.pageNumber,
  };
};

export const disableLeftNavigation = (
  startDate: Date | string,
  startDateLimit: Date | string
): boolean => {
  if (!startDate || !startDateLimit) return false;
  if (new Date(startDate)?.getTime() <= new Date(startDateLimit)?.getTime())
    return true;
  return false;
};

export const disableRightNavigation = (
  endDate: Date | string,
  endDateLimit: Date | string
): boolean => {
  if (!endDate || !endDateLimit) return false;
  if (new Date(endDate)?.getTime() >= new Date(endDateLimit)?.getTime())
    return true;
  return false;
};

export const isResourceTypeUnassignedTrip = (
  trip: PossibleDriverResourceType
): boolean => {
  if (trip?.resourceType === RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP)
    return true;
  return false;
};

export const isResourceTypeBrokeredTrip = (
  trip: PossibleDriverResourceType
): boolean => {
  if (trip?.resourceType === RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP)
    return true;
  return false;
};

export const isResourceTypeUnassignedDriver = (
  trip: PossibleDriverResourceType
): boolean => {
  if (trip?.resourceType === RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER)
    return true;
  return false;
};

export const isEvaluateActionsAllowed = (
  resource: PossibleDriverResourceType
) => {
  if (
    isResourceTypeUnassignedDriver(resource) ||
    isResourceTypeUnassignedTrip(resource) ||
    isResourceTypeBrokeredTrip(resource)
  )
    return true;
  return false;
};

export const isResourceTypeAssignedDriver = (
  trip: PossibleDriverResourceType
): boolean => {
  if (trip?.resourceType === RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER)
    return true;
  return false;
};

export const getDefaultRequestParams = (driverFilters: IGanttFilters) => {
  return {
    organizationId: driverFilters?.organizationId,
    sort: driverFilters?.sort,
  };
};

export const getFormattedDriverObject = (
  driver: IRecommendedResource
): IRecommendedResource => {
  return {
    ...driver,
    tripPickupLocation: driver?.tripPickupLocation ?? null,
    timeToPickup: driver?.timeToPickup ?? null,
    driverAvailableLocation: driver?.driverAvailableLocation ?? null,
    driverAvailableTime: driver?.driverAvailableTime ?? null,
    resourceState: getCurrentResourceState(driver),
  };
};

export const isTripTypeRecommended = (trip: IRecommendedTrip) =>
  trip.status === TRIP_STATUSES.RECOMMENDED;

export const getRecommendationLockAllSection = (
  filters: Array<string>,
  isBulkAction?: boolean
) => {
  if (!isBulkAction) return {};
  if (
    filters?.includes?.(ERecommendedTripStatus.Assigned) &&
    filters?.includes?.(ERecommendedTripStatus.brokeredTrips)
  )
    return { assignedSection: [] };
  if (filters?.includes?.(ERecommendedTripStatus.Assigned))
    return { assignedSection: [RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER] };
  if (filters?.includes?.(ERecommendedTripStatus.brokeredTrips))
    return { assignedSection: [RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP] };
  return {};
};
