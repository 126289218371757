import { plainToInstance } from 'class-transformer';
import {
  AssignDocumentRequest,
  AttachedEntitiesRequest,
  CreateDocumentOnlyRequest,
  CreateFileDTO,
  CreateFileRequest,
  CreateLatestCertificationRequest,
  CreateLatestDriverLicenseRequest,
  DeleteDocumentRequest,
  DeleteDocumentsRequest,
  DocumentAssigments,
  DocumentByEntity,
  DocumentItemType,
  DocumentShareRequest,
  DocumentsTypeList,
  DocumentSummaryResponse,
  DownloadDocumentsRequest,
  EmailDocumentsRequest,
  EntityTypesRequest,
  FolderTypeResponse,
  GetAssociatedDriversRequest,
  GetDocumentFoldersRequest,
  GetDocumentsByEntityRequest,
  GetDocumentsListByDocIdRequest,
  GetDocumentsListRequest,
  GetDocumentTypeListRequest,
  GetDocumentTypesRequest,
} from '../../models';
import { QueryParams } from '../../models/DTOs/commonMixed/QueryParams';

import axiosInstance, {
  ComposedError,
  httpClient,
} from '../../axios/axiosInstance';

import {
  annotateNameAsync,
  IDocumentService,
  ServiceError,
} from '../interfaces';

import { FailureNotificationConstants } from '../../locales/en/notifications/failureNotifications';
import StorageManager from '../../StorageManager/StorageManager';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { LineItem } from '../../types';
import { docType } from '../../utils/Doc';

type associatedWithTypeURLs = { [k: string]: any };

const getDocumentFoldersURL =
  '/web/afs/api/v2/document/assignments/get/folders';
const getEntityTypes = '/web/afs/api/v2/document/assignments/get/byentity';
const getDocumentAssigments = '/web/afs/api/v2/document/assignments/get/list';
const deleteDocumentURL =
  '/web/afs/api/v2/document/assignments/assignment/delete/ids';
const downloadDocumentURL =
  'web/afs/api/v2/documents/document/get/list/download';

const downloadSharedDocumentURL = 'web/afs/api/v2/documents/document/get/list';
const createFileURL = '/web/afs/api/v2/documents/document/create/bulk';
const editDocumentURL = '/web/afs/api/v2/document/assignments/update';
const createLatestCertificationURL = 'web/people/api/v2/driver/alert';
const createLatestDriverLicenseURL = 'web/driver/api/v2/orgDriverLicenses';
const createDocumentURL = '/web/afs/api/v2/document/assignments/assign/bulk';
const shareDocumentURL = '/web/telegram/api/v2/messages/send';
const associatedWith: associatedWithTypeURLs = {
  LOAD: '/web/load/api/v2/loads/dropdown',
  DRIVER: '/web/people/api/v2/organization/dropdown',
  TRACTOR: '/web/asset/api/ax2/assets/tractor/dropdown',
  TRAILER: '/web/asset/api/ax2/assets/trailer/dropdown',
  EXPENSE: '/web/expense/api/v2/expense/dropdown',
  CARRIER: '/web/customer/api/v2/carriers/carrier/list/short',
};

const createDocument = '/web/afs/api/v2/documents/document/create';
const assignDocument = '/web/afs/api/v2/document/assignments/create';
const getDocumentTypeListURL =
  '/web/dictionary/api/v2/document/items/getByOrganizationId';

type FileType = {
  id: string;
  fileSize: string;
  fileName: string;
};

const getDocumentDTO = (data: any, file: FileType) => {
  const {
    id: ownerId,
    firstname,
    lastname,
    organizationId,
  } = StorageManager.getUser() || {};
  const { description, id: documentTypeId, itemCode } = data.documentType;
  const {
    permission,
    entities,
    entityTypes,
    terminal,
    fileName: singleFileName,
    notes,
  } = data;
  const { fileSize, id, fileName } = file;

  const ownerName = `${firstname} ${lastname}`;
  const defAttachedEntities = null;
  return {
    attachedEntities: entities?.id
      ? [
          {
            properties: [{ id: entities.id, title: entities.name }],
            type: `${entityTypes.key}`,
          },
        ]
      : defAttachedEntities,
    description: notes || description,
    documentId: id,
    documentType: itemCode,
    documentTypeId,
    fileName: singleFileName || fileName,
    fileSize,
    id: null,
    organizationId,
    ownerId,
    ownerName,
    terminalId: terminal?.id,
    permission: !permission ? 'PRIVATE' : 'PUBLIC',
    uploadDate: new Date(),
  };
};

export class DocumentService extends IDocumentService {
  @annotateNameAsync
  async getEntityTypes(requestData: EntityTypesRequest) {
    const params = requestData.getParamsFromRequest();
    try {
      const response = await axiosInstance.get(getEntityTypes, {
        params,
      });
      const data = await response.data;
      const customers = plainToInstance(DocumentsTypeList, data);
      return Promise.resolve(customers);
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async getDocumentAssigments(requestData: DocumentAssigments) {
    const params = requestData.getParamsFromRequest();
    try {
      const response = await axiosInstance.get(getDocumentAssigments, {
        params,
      });
      const data = await response.data;
      const customers = plainToInstance(DocumentsTypeList, data);
      return Promise.resolve(customers);
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async deleteDocument(requestData: DeleteDocumentRequest) {
    const params = requestData.getParamsFromRequest();
    try {
      const response = await axiosInstance.delete(deleteDocumentURL, {
        params,
      });
      const data = await response.data;
      const customers = plainToInstance(DocumentsTypeList, data);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'documentDelete',
      });
      return Promise.resolve(customers);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'documentDelete',
      });
      throw error;
    }
  }

  //todo for file
  @annotateNameAsync
  async createDocument(
    requestQuery: CreateFileRequest,
    files: File[],
    data: any
  ) {
    const formData = new FormData();
    requestQuery.addUserIdToParams();
    requestQuery.ownerId = requestQuery.userId;
    files.forEach((eachFile) => {
      formData.append('files', eachFile);
    });

    const headers = { 'Content-Type': 'multipart/form-data' };
    try {
      const createdFiles = await httpClient.post<CreateFileDTO[]>(
        createFileURL,
        requestQuery,
        formData,
        CreateFileDTO,
        false,
        headers
      );
      if (!data.entityTypes) return createdFiles;
      const documentUpdateDTOs = createdFiles.map((eachFile: any) => {
        return getDocumentDTO(data, eachFile);
      });
      const newDocs = await httpClient.postRaw(
        createDocumentURL,
        undefined,
        documentUpdateDTOs
      );
      return newDocs.data;
    } catch (error) {
      console.log('error:  ', error);
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async createDocumentOnly(
    requestData: CreateDocumentOnlyRequest
  ): Promise<DocumentSummaryResponse> {
    try {
      const { data: body, organizationId, ownerId } = requestData;
      const formData = new FormData();
      formData.append('file', body);
      const response = await axiosInstance.post(createDocument, formData, {
        params: { organizationId, ownerId },
        headers: {
          ContentType: 'multipart/form-data',
        },
      });

      const data = await response.data;
      const document = plainToInstance(DocumentSummaryResponse, data);
      return Promise.resolve(document);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message: FailureNotificationConstants.documentCreate,
      });
      throw error;
    }
  }

  @annotateNameAsync
  async editDocument(request: any) {
    const query = new QueryParams();
    try {
      const response = await httpClient.patchRaw(editDocumentURL, query, {
        undefined,
        ...request,
      });

      const data: DocumentAssigments = await response.data;
      const document = plainToInstance(DocumentsTypeList, data);
      return Promise.resolve(document);
    } catch (error) {
      throw error;
    }
  }

  //todo for file
  @annotateNameAsync
  async shareDocument(requestQuery: DocumentShareRequest) {
    try {
      const response = await httpClient.postRaw(
        shareDocumentURL,
        undefined,
        requestQuery
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'documentShare',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'documentShare',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getDocumentTypeList(
    requestData: GetDocumentTypeListRequest
  ): Promise<LineItem[]> {
    try {
      const response = await httpClient.getRaw(
        getDocumentTypeListURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  @annotateNameAsync
  async getDocumentFolders(
    requestData: GetDocumentFoldersRequest
  ): Promise<FolderTypeResponse | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getDocumentFoldersURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDocumentTypes(
    requestData: GetDocumentTypesRequest
  ): Promise<LineItem[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getDocumentTypeListURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDocumentsByEntity(
    requestData: GetDocumentsByEntityRequest
  ): Promise<DocumentByEntity[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getEntityTypes,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDocumentListByTypeId(
    requestData: GetDocumentsListByDocIdRequest
  ): Promise<DocumentItemType[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getDocumentAssigments,
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async downloadDocuments(
    requestData: DownloadDocumentsRequest,
    skipLoader = false,
    isSharing = false
  ): Promise<docType[] | ServiceError> {
    try {
      const url = isSharing ? downloadSharedDocumentURL : downloadDocumentURL;
      const response = await httpClient.getRaw(
        url,
        requestData,
        false,
        skipLoader
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async downloadDocumentList(
    requestData: DownloadDocumentsRequest,
    skipLoader = false
  ): Promise<docType[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        downloadDocumentURL,
        requestData,
        false,
        skipLoader
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async emailDocuments(
    requestData: EmailDocumentsRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        shareDocumentURL,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createLatestCertification(
    requestData: CreateLatestCertificationRequest
  ) {
    try {
      const response = await httpClient.putRaw(
        createLatestCertificationURL,
        undefined,
        requestData.certification
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createLatestDriverLicense(
    requestData: CreateLatestDriverLicenseRequest
  ) {
    try {
      const response = await httpClient.postRaw(
        createLatestDriverLicenseURL,
        undefined,
        requestData.driverLicense
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getAssociatedDrivers(requestData: GetAssociatedDriversRequest) {
    try {
      const response = await httpClient.getRaw(
        associatedWith[requestData.entity],
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async assignDocument(requestData: AssignDocumentRequest) {
    try {
      const response = await httpClient.postRaw(
        assignDocument,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteDocuments(requestData: DeleteDocumentsRequest) {
    try {
      const response = await httpClient.deleteRaw(
        deleteDocumentURL,
        requestData,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'documentDelete',
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'documentDelete',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDocumentsList(requestData: GetDocumentsListRequest) {
    try {
      const response = await httpClient.getRaw(
        getDocumentAssigments,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getAssociatedWith(
    requestData: AttachedEntitiesRequest,
    entity: string
  ) {
    try {
      const response = await httpClient.getRaw(
        associatedWith[entity],
        requestData,
        undefined
      );
      response.data.content = response.data.content.map(
        (item: { name: string }) => ({
          ...item,
          title: item.name,
          name: item.name,
        })
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
