import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  CloseViewRequest,
  CopyViewRequest,
  CreateViewRequest,
  DeleteViewRequest,
  GetAvailableViewsRequest,
  GetOwnViewsRequest,
  UpdateViewRequest,
  ValidateViewRequest,
} from '../../models';
import { View } from '../../types';
import {
  ICustomViewService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';

type urlMapType = { [key: string]: { [key: string]: string } };

/***********************************************
 * !!!!!!!!!!!!!!!! ATTENTION !!!!!!!!!!!!!!   *
 *          NEVER CHANGE SOMETHING HERE        *
 *                   @davits                   *
 ***********************************************/

export class CustomViewService extends ICustomViewService {
  @annotateNameAsync
  async getOwnCustomViews(
    requestData: GetOwnViewsRequest,
    dictionary: keyof urlMapType
  ): Promise<View[] | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const url = `/web/${dictionary}/api/v2/custom/view/find/list/own`;
      const response = await httpClient.getRaw(url, requestData, false, true);
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getAvailableViews(
    requestData: GetAvailableViewsRequest,
    dictionary: keyof urlMapType
  ): Promise<View[] | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const url = `/web/${dictionary}/api/v2/custom/view/find/list/shared`;
      const response = await httpClient.getRaw(url, requestData, false, true);
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createView(
    requestData: CreateViewRequest,
    data: View,
    dictionary: keyof urlMapType
  ): Promise<View | ServiceError> {
    try {
      const url = `/web/${dictionary}/api/v2/custom/view/create`;
      requestData.addUserIdToParams();
      const response = await httpClient.postRaw(url, undefined, {
        ...data,
        ...requestData,
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateView(
    requestData: UpdateViewRequest,
    data: View,
    dictionary: keyof urlMapType
  ): Promise<View | ServiceError> {
    try {
      const url = `/web/${dictionary}/api/v2/custom/view/update`;
      const response = await httpClient.putRaw(url, undefined, {
        ...data,
        ...requestData,
      });
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async copyView(
    requestData: CopyViewRequest,
    dictionary: keyof urlMapType
  ): Promise<View | ServiceError> {
    try {
      const url = `/web/${dictionary}/api/v2/custom/view/copy/from`;
      const response = await httpClient.postRaw(url, undefined, requestData);
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async closeView(
    requestData: CloseViewRequest,
    dictionary: keyof urlMapType
  ): Promise<string | ServiceError> {
    try {
      const url = `/web/${dictionary}/api/v2/custom/view/close`;
      const response = await httpClient.putRaw(url, undefined, requestData);
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteView(
    requestData: DeleteViewRequest,
    dictionary: keyof urlMapType
  ): Promise<string | ServiceError> {
    try {
      const url = `/web/${dictionary}/api/v2/custom/view/delete`;
      const response = await httpClient.deleteRaw(url, undefined, requestData);
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async validateView(
    requestData: ValidateViewRequest,
    dictionary: keyof urlMapType
  ): Promise<
    { hasErrors: boolean; errors: { [key: string]: string } } | ServiceError
  > {
    try {
      requestData.addUserIdToParams();
      const url = `/web/${dictionary}/api/v2/custom/view/validate`;
      const response = await httpClient.getRaw(url, requestData);
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
