import Grid from '@mui/material/Grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ViewBaseInfoForm from '../../../../common/AddView/components/ViewBaseInfoForm';
import { LoadboardColumnsForm } from './LoadboardColumnsForm';
import { LoadboardMetaDataForm } from './LoadboardMetaDataForm';

const columnsFormMap: { [key: string]: React.ElementType } = {
  loadsearch: LoadboardColumnsForm,
  bookmark: LoadboardColumnsForm,
};

const metadataFormMap: { [key: string]: React.ElementType } = {
  loadsearch: LoadboardMetaDataForm,
  bookmark: LoadboardMetaDataForm,
};

const FilterTitle = ({ title }: { title: string }) => (
  <span
    style={{
      color: 'rgba(0, 17, 34, 0.6)',
      fontSize: 20,
      fontWeight: 500,
      fontFamily: 'Poppins',
    }}
  >
    {title}
  </span>
);

export default function ViewForm({ customErrors }: any) {
  const { watch } = useFormContext();
  const entity = watch('entity');
  const CurrentColumnsForm = columnsFormMap[entity] || LoadboardColumnsForm;
  const CurrentMetaDataForm = metadataFormMap[entity] || LoadboardMetaDataForm;
  return (
    <Grid container justifyContent={'space-between'} style={{ height: '100%' }}>
      <Grid style={{ padding: 16 }} item sm={4}>
        <FilterTitle title={'1. Name Your Lane'} />
        <ViewBaseInfoForm customErrors={customErrors} />
      </Grid>
      <Grid
        style={{
          padding: 16,
          borderRight: '1.49777px solid #EEEEEE',
          borderLeft: '1.49777px solid #EEEEEE',
        }}
        item
        sm={4}
      >
        <FilterTitle title={'2. Pick Data'} />

        <CurrentColumnsForm />
      </Grid>
      <Grid
        style={{
          padding: 16,
        }}
        item
        sm={4}
      >
        <FilterTitle title={'3. Add Filters'} />
        <CurrentMetaDataForm />
      </Grid>
    </Grid>
  );
}
