import { GridCellParams } from '@mui/x-data-grid-pro';
import { getTypeByMaintenanceTypeId } from '../shared/equipment';

export const findNextServiceDueOdometerByFrequency = (
  value: number,
  maintenanceTypeId: string
) => {
  if (!value || !maintenanceTypeId) return null;

  const TractorType = getTypeByMaintenanceTypeId(maintenanceTypeId, 'TRACTOR');

  return TractorType?.frequencyByMiles
    ? value + TractorType.frequencyByMiles
    : value;
};

export interface ISecondaryPanelTypes {
  driver: object;
  activeLoad: object;
}

export const secondaryPanelTypes = (type: GridCellParams) => ({
  driver: {
    type: 'DISPATCH',
    id: type.value?.id,
  },
  activeLoad: {
    type: 'TRIP',
    id: type.row.activeLoad?.loadId,
  },
});

export const isInactive = (status: string) => status === 'INACTIVE';
export const isUnavailable = (status: string) => status === 'UNAVAILABLE';
