import moment from 'moment';
import React from 'react';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { titleCase } from '../../settings/alerts/constants/constants';

export const HomeContext = React.createContext({});

export const defaultFinanceData = {
  loadedMiles: 0,
  emptyMiles: 0,
  totalMiles: 0,
  emptyTotalMilesRatio: 0,
  driverCount: 0,
  totalRevenue: 0,
  avgRevenuePerDriver: 0,
  revenuePerLoadedMiles: 0,
  revenuePerTotalMiles: 0,
  targetWeeklyRevenue: 0,
  targetWeeklyRevenuePercent: 0,
  targetWeeklyRevenuePerDriver: 0,
  targetWeeklyRevenueTotalMile: 0,
  targetWeeklyRevenueTotalMileDiff: 0,
  targetWeeklyRevenueLoadedMile: 0,
  targetWeeklyRevenueTotalRevenueDiff: 0,
};

export const filterSafetyRes = (res: any, key: string) => {
  const temp = res.filter((item: any) => item.key === key);
  return temp[0];
};

export const dashboardLoaderWrapper = {
  defaultWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  financeWrapper: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    ml: '70px',
    mt: '10px',
  },
};

export const weekCalculation = () => {
  const res = RootStoreInstence.getCompanyPreferences;
  if (res && res.workWeekStartDay) {
    const workWeekStartDay = titleCase(res.workWeekStartDay);
    let start = moment().day(workWeekStartDay).format('ll');
    const current = moment().format('ll');
    let end = moment()
      .day(workWeekStartDay)
      .add(1, 'w')
      .subtract(1, 'd')
      .format('ll');

    if (moment(start).isAfter(current)) {
      start = moment().day(workWeekStartDay).subtract(1, 'w').format('ll');
      end = moment().day(workWeekStartDay).subtract(1, 'd').format('ll');
    }

    return `${start} - ${end}`;
  }
  return `${moment().startOf('isoWeek').format('ll')} - ${moment()
    .endOf('isoWeek')
    .format('ll')}`;
};
