import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { body2Color, fontFamily } from '../../constants';

const CompletedItemInfo = ({
  icon,
  text,
  subText,
}: {
  text: string;
  subText: string;
  icon: ReactElement;
}) => {
  return (
    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'flex-start', flex: 1 }}>
      <Box sx={{ mr: 2 }}>{icon}</Box>
      <Box>
        <Typography
          variant={'h6'}
          sx={{
            color: 'rgba(0, 17, 34, 0.75)',
            fontSize: 20,
            fontWeight: 500,
            fontFamily,
          }}
        >
          {text}
        </Typography>
        <Typography
          variant={'body2'}
          sx={{ fontFamily, fontSize: 14, color: body2Color, mt: 0.5 }}
        >
          {subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default CompletedItemInfo;
