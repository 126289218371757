import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { financeLoadService } from '../../../../../api';

import {
  FactoringActionReturnType,
  InvoiceModel,
  InvoiceUpdateModel,
  LineItem,
} from '../../../../../subPages/invoices/models/InvoiceModel';

import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import moment from 'moment';
import { EventTypes } from '../../../../../EventEmitter/EventTypes';
import { LoadTripActionData } from '../../../../../EventEmitter/Events/EventLoadTripAction';
import { useDetailsPanelStore } from '../../../../../store/DetailsPanel';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../../store/root.context';
import InvoiceActions from '../../../../../subPages/invoices/InvoiceActions';
import InvoiceDetailsForm from '../../../../../subPages/invoices/components/InvoiceDetailsForm';
import {
  invoiceDetailsCreatFormValidationSchema,
  invoiceDetailswithTerminalCreatFormValidationSchema,
} from '../../../../../subPages/invoices/constants';
import {
  IEdiDetails,
  LoadSummary,
} from '../../../../../subPages/loadsList/models/LoadSummary';
import useEmit, {
  EventCallbackFunction,
} from '../../../../../utils/hooks/useEmit';
import { ESecondaryDetailsPanelType } from '../../../../../views/dispatch2/constants/types';
import TerminalController from '../../../../../views/settings/terminals/TerminalController';
import { DirtyDetailsPanelManager } from '../../../../DetailsPanel/utils';
import { Footer } from '../../../../LoadSharedComponents/components/SaveChangesFooter';
import { loadPanelFinanceTab } from '../../../../PendoClassIDs/constants';
import { ELoadStatus } from '../../../constants/constants';

const FinanceOverviewInvoice = ({
  id,
  UpdatedCloseVisible,
  handleFactoringResponse,
  triggerAlert,
  handleUpdatedInvoice,
  isFinanceOverview = false,
  isStatusUpdated,
  onUpdated,
  onAction,
  setLoadData,
  ediDetails,
  panelType,
  onHold = false,
  invoiceData = 'LOAD',
  removeInvoice,
  status,
}: {
  id: string | undefined;
  UpdatedCloseVisible?: () => void;
  handleFactoringResponse?: (
    response: FactoringActionReturnType,
    selectedItem: InvoiceModel | null
  ) => void;
  handleUpdatedInvoice?: (data: InvoiceModel) => void;
  isStatusUpdated?: () => void;
  triggerAlert?: (actionName: string, message: string) => void;
  isFinanceOverview?: boolean;
  onAction?: (status?: ELoadStatus) => void;
  setLoadData: (data: LoadSummary) => void;
  ediDetails?: IEdiDetails;
  panelType: ESecondaryDetailsPanelType;
  onUpdated?: () => void;
  onHold?: boolean;
  removeInvoice?: () => void;
  invoiceData?: string;
  status?: string;
}) => {
  const { trackPromise } = useDetailsPanelStore;
  const [invoiceDetail, setInvoiceDetail] = useState<any>();
  const [isInvoiceView, setInvoiceView] = useState<boolean>(false);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    myLoadStore: { setUpdatedLoadId, currentView, getUpdatedList },
  } = useStores();
  const {
    invoiceStore: {
      updateSpecificRecord,
      removeRecord,
      setSelectedPayment,
      isPaymentUpdated,
      setPaymentUpdated,
    },
  } = useStores();
  const [, setLineItemsMap] = useState<Record<string, LineItem>>({});
  const { getIsTerminalEnabled } = useRootStore();
  TerminalController.instance().getTerminals();

  const state = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: invoiceDetail,
    ...(!getIsTerminalEnabled
      ? invoiceDetailsCreatFormValidationSchema
      : invoiceDetailswithTerminalCreatFormValidationSchema && {
          resolver: yupResolver(
            !getIsTerminalEnabled
              ? invoiceDetailsCreatFormValidationSchema
              : invoiceDetailswithTerminalCreatFormValidationSchema
          ),
        }),
  });
  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = state;

  useEffect(() => {
    if (isPaymentUpdated) {
      GetinvoiceDetailData();
      setPaymentUpdated(false);
      isStatusUpdated?.();
    }
  }, [isPaymentUpdated]);

  useEffect(() => {
    setInvoiceView(false);
    setInvoiceDetail(null);
    if (status != 'DELETED') {
      GetinvoiceDetailData();
    }
  }, [id, invoiceData, status]);

  const handleLoadTripAction: EventCallbackFunction<LoadTripActionData> = (
    data
  ) => {
    if (
      data.entity === 'Load' &&
      data.id === id &&
      [
        ELoadStatus.AVAILABLE,
        ELoadStatus.LOAD_COMPLETED,
        ELoadStatus.INVOICED,
        ELoadStatus.PAID,
        ELoadStatus.CANCELLED,
      ].includes(data.toStatus)
    ) {
      GetinvoiceDetailData();
    }
  };

  useEmit({
    [EventTypes.LOAD_TRIP_ACTION]: handleLoadTripAction,
  });

  useEffect(() => {
    DirtyDetailsPanelManager.panelName = isDirty
      ? 'FinanceOverviewInvoice'
      : null;

    DirtyDetailsPanelManager.isDirty = isDirty;
  }, [isDirty]);

  useEffect(() => {
    return () => {
      DirtyDetailsPanelManager.panelName = null;
      DirtyDetailsPanelManager.isDirty = false;
    };
  }, []);

  useEffect(() => {
    const getStates = async () => {
      await trackPromise(InvoiceActions.getStates(), panelType);
    };
    const getInvoiceLineItems = async () => {
      const lineItems = await trackPromise(
        InvoiceActions.getInvoiceLineItems(),
        panelType
      );
      if (lineItems) {
        setLineItemsMap(lineItems);
      }
    };
    getStates();
    getInvoiceLineItems();
  }, []);

  const GetinvoiceDetailData = async (): Promise<void> => {
    const data = await trackPromise(
      invoiceData === 'LOAD'
        ? financeLoadService.GetinvoiceDetail(id)
        : InvoiceActions.previewInvoice(id),
      panelType
    );
    setSelectedPayment(new InvoiceModel(data));
    setInvoiceDetail(new InvoiceModel(data));
    reset(new InvoiceModel(data));
    setInvoiceView(true);
    setIsLoading(false);
  };
  const InvoiceDetailsVisibility = () => {
    return (
      <Box sx={{ mb: '15px', display: 'flex', justifyContent: 'flex-start' }}>
        <Typography
          id={loadPanelFinanceTab + 'CloseNetInvoiceAmount'}
          sx={{ color: 'primary.main', cursor: 'pointer' }}
          onClick={() => UpdatedCloseVisible && UpdatedCloseVisible()}
        >
          <CloseIcon style={{ width: '20px' }} /> Close Net Invoice Amount
        </Typography>
      </Box>
    );
  };
  const handleUpdated = async (data: InvoiceModel) => {
    setSaving(true);
    if (invoiceDetail.notes !== data.notes && data?.noteDetails?.updateTime) {
      const user = StorageManager.getUser();
      data.noteDetails.updateTime = moment();
      data.noteDetails.userId = user.id;
    }
    data.childInvoices = data.childInvoices?.filter((obj) => obj.invoiceId);
    const payLoad = new InvoiceUpdateModel(data);
    const result = await InvoiceActions.updateInvoice(payLoad);
    if (result) {
      if (result.invoiceDeleted) {
        removeRecord?.(payLoad.invoiceId);
        return;
      }
      GetinvoiceDetailData();
      // setUpdatedLoadId && setUpdatedLoadId(id);
      getUpdatedList?.(id);
      const data = await InvoiceActions.previewInvoice(result.invoiceId);
      if (data) {
        updateSpecificRecord?.(data);
      }
      setSaving(false);
    } else {
      setSaving(false);
    }
  };
  const handleFactoring = (
    response: FactoringActionReturnType,
    selectedItem: InvoiceModel | null
  ) => {
    const { factoringDataDTO } = response;
    if (factoringDataDTO.length) {
      handleFactoringResponse &&
        handleFactoringResponse(response, selectedItem);
      delete factoringDataDTO[0].invoiceId;
      setInvoiceDetail({ ...invoiceDetail, ...factoringDataDTO[0] });
      setLoadData((load) => ({
        ...load,
        loadStatus: factoringDataDTO[0]['loadStatus'],
      }));
      onUpdated?.();
    }
  };

  if (isLoading) {
    return [...new Array(18)].map((index) => (
      <Skeleton height={'2rem'} width={'100%'} />
    ));
  }

  const handleCancel = () => {
    GetinvoiceDetailData();
  };

  const theme = useTheme();
  const saveContainerStyle = {
    position: 'fixed',
    background: `${theme.palette.background.default}`,
    width: '514px',
    padding: '20px 0px',
    bottom: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
    direction: 'row',
    height: 'auto',
    ml: '-15px',
  };
  return (
    <>
      <Box height="100%">
        <Grid
          className="FinanceOverviewInvoice"
          sx={{ pl: 0, pr: 0, height: '100%' }}
        >
          {UpdatedCloseVisible && InvoiceDetailsVisibility()}
          {isInvoiceView && invoiceDetail && (
            <FormProvider {...state}>
              {/* <Box height="100%"> */}
              {isInvoiceView && (
                <InvoiceDetailsForm
                  isLoading={isLoading}
                  triggerAlert={triggerAlert}
                  invoice={invoiceDetail}
                  handleFactoringResponse={handleFactoring}
                  isStatusUpdated={isStatusUpdated}
                  ediDetails={ediDetails}
                  onHold={onHold}
                  removeInvoice={removeInvoice}
                  onSave={handleSubmit(handleUpdated)}
                  isFactorValueUpdated={() => {
                    isStatusUpdated?.();
                  }}
                />
              )}
              {/* </Box> */}
            </FormProvider>
          )}
        </Grid>
        {isDirty && (
          <Grid
            className="FinanceOverviewAccordionSection-Footer"
            container
            sx={{
              pb: '25px',
            }}
          >
            <Grid item xs={12} md={12} sx={saveContainerStyle}>
              <Footer
                isSaving={isSaving}
                onSave={handleSubmit(handleUpdated)}
                onCancel={handleCancel}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};
export default observer(FinanceOverviewInvoice);
