import { ServiceError } from './helperTypes';
import { Service } from './Service';
import {
  GetLoadAlertsTypeDTO,
  GetStatic,
  DeleteLoadAlertDTO,
  LoadAlertsListRes,
  DTTAlertTypeRes,
  GetStaticForAlertsRes,
  loadAlertPrefDTOS,
  GetDTTAlertsListRes,
  SingleContentListDTO,
} from '../../models';
export abstract class IAlertsService extends Service {
  abstract getLoadAlertsType(
    requestData: GetLoadAlertsTypeDTO
  ): Promise<string[] | null | ServiceError>;

  abstract getLoadUserRoles(
    requestData: any
  ): Promise<string[] | null | ServiceError>;

  abstract getDriverAlertsType(
    requestData: GetLoadAlertsTypeDTO
  ): Promise<DTTAlertTypeRes | null | ServiceError>;

  abstract getStaticForAlerts(
    requestData: GetStatic
  ): Promise<GetStaticForAlertsRes | null | ServiceError>;

  abstract getStaticForTrailer(
    requestData: GetStatic
  ): Promise<GetStaticForAlertsRes | null | ServiceError>;

  abstract getStaticForTractor(
    requestData: GetStatic
  ): Promise<GetStaticForAlertsRes | null | ServiceError>;

  abstract getTrailerAlertsType(
    requestData: GetLoadAlertsTypeDTO
  ): Promise<DTTAlertTypeRes | null | ServiceError>;

  abstract getTractorAlertsType(
    requestData: GetLoadAlertsTypeDTO
  ): Promise<DTTAlertTypeRes | null | ServiceError>;

  abstract createLoadAlert(
    requestData: GetLoadAlertsTypeDTO,
    payloadData: loadAlertPrefDTOS
  ): Promise<loadAlertPrefDTOS | null | ServiceError>;

  abstract createDriverAlert(
    payloadData: SingleContentListDTO
  ): Promise<SingleContentListDTO | null | ServiceError>;

  abstract createTrailerAlert(
    payloadData: SingleContentListDTO
  ): Promise<SingleContentListDTO | null | ServiceError>;

  abstract createTractorAlert(
    payloadData: SingleContentListDTO
  ): Promise<SingleContentListDTO | null | ServiceError>;

  abstract editLoadAlert(
    requestData: GetLoadAlertsTypeDTO,
    payloadData: loadAlertPrefDTOS
  ): Promise<loadAlertPrefDTOS | null | ServiceError>;

  abstract editDriverAlert(
    payloadData: SingleContentListDTO
  ): Promise<GetDTTAlertsListRes | null | ServiceError>;

  abstract editTrailerAlert(
    payloadData: SingleContentListDTO
  ): Promise<GetDTTAlertsListRes | null | ServiceError>;

  abstract editTractorAlert(
    payloadData: SingleContentListDTO
  ): Promise<GetDTTAlertsListRes | null | ServiceError>;

  abstract getLoadAlertsList(
    requestData: GetLoadAlertsTypeDTO
  ): Promise<LoadAlertsListRes | null | ServiceError>;

  abstract getDriverAlertsList(
    requestData: GetLoadAlertsTypeDTO
  ): Promise<GetDTTAlertsListRes | null | ServiceError>;

  abstract getTractorAlertsList(
    requestData: GetLoadAlertsTypeDTO
  ): Promise<GetDTTAlertsListRes | null | ServiceError>;

  abstract getTrailerAlertsList(
    requestData: GetLoadAlertsTypeDTO
  ): Promise<GetDTTAlertsListRes | null | ServiceError>;

  abstract deleteLoadAlert(
    payloadData: DeleteLoadAlertDTO
  ): Promise<null | undefined>;

  abstract deleteDriverAlert(
    payloadData: DeleteLoadAlertDTO,
    selectedTab: string
  ): Promise<null | undefined>;
}
