import { t } from 'i18next';
import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { CommonConstants } from '../../../locales/en/common';
import { PayStatementsEntityTypeEnum } from '../../../models';
import { getFinancePayStatementGridColumns } from '../../../subPages/payStatement/utils/grid.utils';
import { GridColDefSelf, ViewMap, ViewMetaData } from '../../../types';
import { getCombinedNameList, getSettlementIdList } from './utils';
export const typeFilter = 'typeFilter';
export const nameFilter = 'nameFilter';
export const seqNumberFilter = 'seqNumberFilter';
export const statusFilter = 'statusFilter';
export const periodFilter = 'periodFilter';

export const typeOptions = [
  { id: 'DRIVER', name: 'Driver' },
  { id: 'TRACTOR', name: 'Tractor' },
  { id: 'DISPATCHER', name: 'Web User' },
];
export const statusOptions = [
  {
    tractorStatus: 'AVAILABLE,ASSIGNED',
    userStatus: '1,2,3,4',
    name: 'Active',
  },
  { tractorStatus: 'UNAVAILABLE,INACTIVE', userStatus: '0', name: 'Inactive' },
];

export const payStatementFilters = {
  [typeFilter]: null,
  [nameFilter]: [],
  [seqNumberFilter]: [],
  [statusFilter]: null,
  [periodFilter]: [null, null],
};

export const getFiltersFormConfig = ({
  filters,
  entity,
  terminalIds,
}: {
  filters: ViewMetaData;
  entity: PayStatementsEntityTypeEnum;
  terminalIds: string[];
}) => {
  return [
    {
      key: 1,
      name: typeFilter,
      fieldName: 'name',
      options: typeOptions,
      label: t('type'),
      default: true,
      type: 'SingleAutocomplete',
    },

    {
      key: 2,
      name: nameFilter,
      fieldName: 'entityNameToShow',
      getData: (name: string, pageNumber: number) =>
        getCombinedNameList(name, pageNumber, filters, terminalIds),
      label: t('name'),
      default: true,
      type: 'MultipleAutocomplete',
      width: 250,
      customStyles: {
        width: 250,
        '.MuiAutocomplete-tag': {
          maxWidth: 'calc(100% - 70px)',
        },
      },
    },
    ...(entity === PayStatementsEntityTypeEnum.pendingPayment
      ? [
          {
            key: 3,
            name: statusFilter,
            fieldName: 'name',
            options: statusOptions,
            label: t('status'),
            default: true,
            type: 'SingleAutocomplete',
          },
        ]
      : [
          {
            key: 4,
            name: periodFilter,
            fieldName: periodFilter,
            label: 'Period',
            default: true,
            ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
          },
          {
            key: 5,
            name: seqNumberFilter,
            fieldName: 'seqNumber',
            getData: (seqNumberFilter: string, pageNumber: number) =>
              getSettlementIdList(
                pageNumber,
                terminalIds,
                seqNumberFilter,
                entity,
                filters
              ),
            label: 'Settlement #',
            default: true,
            type: 'MultipleAutocomplete',
            width: 250,
            customStyles: {
              width: 250,
              '.MuiAutocomplete-tag': {
                maxWidth: 'calc(100% - 70px)',
              },
            },
          },
        ]),
  ];
};

export const getPayStatementColumns = (tab: PayStatementsEntityTypeEnum) =>
  getFinancePayStatementGridColumns(tab)
    .map((gridColDef: GridColDefSelf) => {
      return {
        [gridColDef.field]: true,
      };
    })
    .reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        ...currentValue,
      }),
      {}
    );

export const pendingPaymentsView = {
  id: PayStatementsEntityTypeEnum.pendingPayment,
  shared: true,
  parentId: null,
  name: CommonConstants.pending,
  entity: PayStatementsEntityTypeEnum.pendingPayment,
  iconName: 'AccessTimeOutlined',
  source: 'MUIcons',
  active: true,
  default: true,
  columnFilters: getPayStatementColumns(
    PayStatementsEntityTypeEnum.pendingPayment
  ),
  metaData: payStatementFilters,
};

export const inReviewStatementsView = {
  id: PayStatementsEntityTypeEnum.inReviewStatement,
  shared: true,
  parentId: null,
  name: CommonConstants.inReview,
  entity: PayStatementsEntityTypeEnum.inReviewStatement,
  iconName: 'EditOutlined',
  source: 'MUIcons',
  active: true,
  default: true,
  columnFilters: getPayStatementColumns(
    PayStatementsEntityTypeEnum.inReviewStatement
  ),
  metaData: payStatementFilters,
};

export const reviewedStatementsView = {
  id: PayStatementsEntityTypeEnum.reviewedStatement,
  shared: true,
  parentId: null,
  name: CommonConstants.reviewed,
  entity: PayStatementsEntityTypeEnum.reviewedStatement,
  iconName: 'LibraryAddCheckOutlined',
  source: 'MUIcons',
  active: true,
  default: true,
  columnFilters: getPayStatementColumns(
    PayStatementsEntityTypeEnum.reviewedStatement
  ),
  metaData: payStatementFilters,
};

export const closedStatementView = {
  id: PayStatementsEntityTypeEnum.closedStatement,
  shared: true,
  parentId: null,
  name: CommonConstants.closed,
  entity: PayStatementsEntityTypeEnum.closedStatement,
  iconName: 'CheckOutlined',
  source: 'MUIcons',
  active: true,
  default: true,
  columnFilters: getPayStatementColumns(
    PayStatementsEntityTypeEnum.closedStatement
  ),
  metaData: payStatementFilters,
};

export const formDefaultValuesMap: ViewMap = {
  [PayStatementsEntityTypeEnum.pendingPayment]: pendingPaymentsView,
  [PayStatementsEntityTypeEnum.inReviewStatement]: inReviewStatementsView,
  [PayStatementsEntityTypeEnum.reviewedStatement]: reviewedStatementsView,
  [PayStatementsEntityTypeEnum.closedStatement]: closedStatementView,
};
