import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { ILoadaiService } from '../../api/interfaces';
import { DriverDefaultParameters } from '../../models/DTOs/Loadai/Request';
import { LOADAI_ENDPOINTS } from './requestConstants';

export class LoadaiService extends ILoadaiService {
  async getDriverDefaultParameters(): Promise<any> {
    const requestData = new DriverDefaultParameters();
    try {
      return await httpClient.get(
        LOADAI_ENDPOINTS.DRIVER_DEFAULT_PARAMETERS,
        requestData,
        DriverDefaultParamsResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}

class DriverDefaultParamsResponse {}
