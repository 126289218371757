export const EDITendersConstants = {
  new: 'New',
  accepted: 'Accepted',
  declined: 'Declined',
  canceledbyCustomer: 'Cancelled by Customer',
  all: 'All',

  source: 'Source',
  customer: 'Customer',
  respondBy: 'Respond By',
  action: 'Action',
  rate: 'Rate',
  shipmentID: 'Shipment ID',
  stops: 'Stops',
  pickupCity: 'Pick Up City',
  pickupState: 'Pick Up State',
  pickupZipCode: 'Pick Up ZipCode',
  totalStops: 'Total Stops',
  actions: 'Actions',
  acceptDateandTime: 'Accept Date and Time',
  pickupDateandTime: 'Pick up Date and Time',
  dropoffCity: 'Dropoff City',
  dropoffState: 'Dropoff State',
  dropoffDateandTime: 'Dropoff Date and Time',
  status: 'Status',
  dropoffZipcode: 'Dropoff Zipcode',
  pickupZipcode: 'Pick Up Zipcode',
  loadedMiles: 'Loaded Miles',
  marketRate: 'Market Rate',
  loadID: 'Load ID',
  declinedDateTime: 'Declined Date Time',
  cancelledDateTime: 'Cancelled Date Time',
  pickup: 'Pickup',
  dropoff: 'Dropoff',
  loadStatus: 'Load Status',
  offer: 'Offer',
};
