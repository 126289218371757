import { documentService, locationService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { httpClient } from '../../../../axios/axiosInstance';
import {
  CreateFileRequest,
  CreateLocationNewRequest,
  LocationBasicData,
  TemplateDetailsResponse,
  ValidateLocationsUniqueNamesRequest,
} from '../../../../models';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import { FileType } from '../../../../types';
import { getDocumentTypeList } from '../../../expenses/utils/utils';
import { CreateTemplateRequest } from '../../CreateTemplate/dto/create-template.dto';
import { urls } from '../apis';
import { INVOICE_BASE_RATE_FLAT_TYPE } from '../constants/fieldValues';
import {
  CreateLoadRequestDTO,
  LineItemResDTO,
} from '../dto/create-load-request.dto';
import { ILoadStop } from '../types/requests';
import { ILoadDetails, ILoadRateInvoice } from '../types/types';
import { stopFormService } from './stopForm.service';

class CreateLoadFormService {
  getAutocompleteOptionLabel({
    list,
    value,
    fieldName,
    entityToCompare = 'id',
    returnDefaultValue = true,
  }: {
    list: Array<any>;
    value: object;
    fieldName: string;
    entityToCompare?: string;
    returnDefaultValue?: boolean;
  }): string {
    const selectedOption = list?.find?.(
      (e) => e?.[entityToCompare] === value
    )?.[fieldName];
    if (selectedOption) return selectedOption;
    if ((value as any)?.[fieldName]) return (value as any)?.[fieldName];
    if (returnDefaultValue) return value as any;
    return '';
  }

  validateForm(load: ILoadDetails) {
    const stopApptDatesValidation =
      stopFormService.validateStopAppointmentDates(load.stops);
    return { stopApptDatesValidation };
  }

  async createLoad(load: CreateLoadRequestDTO) {
    try {
      const res = await httpClient.postRaw(urls.createLoad, undefined, load);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createLoad',
      });
      return res;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'createLoad',
      });
      console.log('error: ', error);
      throw error;
    }
  }

  async validateStopLocations(
    stops: ILoadStop[]
  ): Promise<
    { positions: Array<number>; locations: Array<any> } | null | undefined
  > {
    const locations: LocationBasicData[] = [];
    const createLocationRequestDTO: CreateLocationNewRequest[] = [];
    const newLocationCreatedIndices: Array<number> = [];
    const createLocationRequestFinalDTO: CreateLocationNewRequest[] = [];
    const newLocationCreatedFinalIndices: Array<number> = [];
    let index = 0;
    for (const stop of stops) {
      if (
        stop.location?.businessName?.length &&
        stop.location?.locationName !== stop.location?.businessName &&
        stop.location?.address
      ) {
        locations.push({
          id: index,
          locationName: stop.location?.businessName,
        });
      }
      newLocationCreatedIndices.push(index);
      createLocationRequestDTO.push({
        addressDTO: {
          fullAddress: stop?.location?.address!,
          address: stop.location?.address!,
          city: stop.location?.city!,
          state: stop.location?.state!,
          streetAddress: stop.location?.streetAddress!,
          streetAddress2: stop.location?.address2!,
          zipcode: stop.location?.zipcode!,
          center: stop.location?.center!,
          timezone: stop.location?.timezone,
          geoCoordinates: stop.location?.geoCoordinates as any,
        },
        locationName: stop.location?.businessName!,
        notes: stop.notes!,
        avgHandlingTime: stop.estimatedActivityDuration!,
        contacts: [
          {
            email: stop?.contact?.email!,
            fullName: stop?.contact?.contactName!,
            description: '',
            isPrimary: true,
            phoneData: {
              countryCode: stop?.contact?.countryCode,
              phone: stop?.contact?.phone,
              extension: stop?.contact?.extension,
            },
          },
        ],
        locationPreference: 2,
      });
      index++;
    }
    if (locations.length) {
      const validateLocationRes =
        await locationService.validateLocationsUniqueNames(
          new ValidateLocationsUniqueNamesRequest({ locations })
        );
      if (validateLocationRes instanceof ServiceError)
        throw new Error('Failed to validate new locations!');
      const index = 0;
      for (const val of newLocationCreatedIndices) {
        if ((validateLocationRes?.locations as any)?.[val] === false) {
          newLocationCreatedFinalIndices.push(newLocationCreatedIndices[val]);
          createLocationRequestFinalDTO.push(createLocationRequestDTO[val]);
        }
      }
    }
    if (createLocationRequestFinalDTO?.length) {
      const res = await this.createLocations(createLocationRequestFinalDTO);
      if (res instanceof ServiceError)
        throw new Error('Failed to create locations!');
      return { positions: newLocationCreatedFinalIndices, locations: res! };
    }
  }

  async createLocations(locations: CreateLocationNewRequest[]) {
    return await locationService.createLocations(locations as any);
  }

  getLoadInvoiceLineItems(rate: ILoadRateInvoice): LineItemResDTO[] {
    const lineItems: LineItemResDTO[] = [];
    if (rate?.feeDetail && rate?.rate != null && rate?.rate != '') {
      lineItems?.push({
        itemId: rate?.feeDetail?.itemId ?? rate?.feeDetail?.id,
        type: rate?.feeDetail?.itemCode,
        description: rate?.description!,
        quantity:
          rate?.feeDetail?.itemCode === INVOICE_BASE_RATE_FLAT_TYPE
            ? 1
            : rate?.quantity!,
        rate: rate?.rate ?? 0,
        amount:
          Number(rate?.quantity) && Number(rate?.rate)
            ? Number(rate?.quantity) * Number(rate?.rate)
            : 0,
        contractId: rate?.contract?.id,
        contractRateId:
          rate?.contract?.id && rate?.feeDetail?.isContract
            ? rate?.feeDetail?.id!
            : null,
      });
    }
    const accessorialItems: LineItemResDTO[] = [];
    rate?.additionalFees?.forEach?.((e) => {
      if (e?.fee) {
        accessorialItems?.push({
          itemId: e?.fee,
          type: e?.feeDetail?.itemCode,
          description: e?.description,
          quantity: e?.quantity!,
          rate: e?.rate!,
          amount: e?.total,
          unit: e?.unit,
          contractId: e?.isContract ? rate?.contract?.id : undefined,
          contractRateId: e?.feeDetail?.contractRateId ?? null,
          deduction: e?.feeDetail?.deduction,
        });
      }
    });
    return [...lineItems, ...accessorialItems];
  }

  getValidationErrorMap(errorList: any): Map<string, object> {
    if (!Object.keys(errorList)?.length) return new Map();
    const errorMap = new Map<string, Array<string>>();
    let stopCount = 1;
    if (errorList?.stops?.length) {
      for (const stop of errorList.stops) {
        if (!stop) continue;
        for (const key of Object.keys(stop)) {
          const errorMsgs = [];
          for (const val of Object.values(stop[key])) {
            errorMsgs?.push((val as any)?.message);
          }
          errorMap?.set(`Stop ${stopCount}`, errorMsgs);
          stopCount++;
        }
      }
    }
    for (const key of Object.keys(errorList)) {
      if (key === 'stops') continue;
      const errorMsgs = [];
      for (const val of Object.values(errorList[key])) {
        errorMsgs?.push((val as any)?.message);
      }
      errorMap?.set(key, errorMsgs);
    }
    return errorMap;
  }

  async saveRateconFile({
    file,
    load,
  }: {
    file: File;
    load: { id: string; seqNumber: string; terminalId: string };
  }) {
    const request = new CreateFileRequest();
    const documentTypes = await getDocumentTypeList();
    const rateConDocType = documentTypes.find(
      (docType) => docType.itemCode === 'RATE_CONFIRMATION'
    );
    const data = {
      documentType: rateConDocType,
      entityTypes: {
        key: 'LOAD',
        value: 'Load',
      },
      entities: {
        id: load.id,
        name: load.seqNumber,
      },
      fileName: file.name,
      notes: '',
      permission: true,
      terminal: { id: load.terminalId },
    };
    const createdDocument = await documentService.createDocument(
      request,
      [file as FileType],
      data
    );
    if (createdDocument instanceof ServiceError || !createdDocument) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'uploadRateconFile',
      });
      throw new Error('Failed to save Ratecon file.');
    }
    return createdDocument;
  }

  async createTemplate({
    load,
    file,
    templateName,
  }: {
    load: ILoadDetails;
    file: File;
    templateName?: string;
  }): Promise<TemplateDetailsResponse> {
    try {
      const res = await new CreateTemplateRequest({
        assignedDocument: file,
        templateName,
      }).createTemplate(load);
      if (res instanceof ServiceError) {
        throw new Error('Failed to create tempalte.');
      }
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'templateCreation',
      });
      return res;
    } catch (error: any) {
      console.log('error:', error);
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        ...(error?.response?.data?.message
          ? { message: error?.response?.data?.message }
          : { serviceName: 'templateCreation' }),
      });
      throw error;
    }
  }

  validateHiddenFieldErrors(errors: any, hiddenFields: any) {
    const currentHiddenFields = hiddenFields;
    if (errors) {
      const sections = Object.keys(errors);
      for (const section of sections) {
        if (section !== 'stops') {
          if (errors[section]) {
            const fieldKeys = Object.keys(errors[section]);
            for (const field of fieldKeys) {
              if (currentHiddenFields?.[section]) {
                currentHiddenFields[section][field] = false;
              }
            }
          }
        } else {
          const stopsErrs = errors?.[section];
          if (stopsErrs?.length) {
            for (let i = 0; i < stopsErrs?.length; i++) {
              if (errors[section][i]) {
                const fieldKeys = Object.keys(errors[section][i]);
                for (const field of fieldKeys) {
                  if (currentHiddenFields?.[section]) {
                    if (
                      field === 'appointmentEndTime' ||
                      field === 'appointmentStartTime' ||
                      field === 'appointmentStartDate' ||
                      field === 'appointmentEndDate'
                    )
                      currentHiddenFields[section]['appointmentEndDate'] =
                        false;
                    if (field === 'referenceNumber') {
                      currentHiddenFields[section]['editReferenceNumber'] =
                        false;
                    }
                    if (field === 'contact') {
                      currentHiddenFields[section]['editContact'] = false;
                    }
                    currentHiddenFields[section][field] = false;
                  }
                }
              }
            }
          }
        }
      }
      return currentHiddenFields;
    }
  }
}
const createLoadService = new CreateLoadFormService();

export { createLoadService };

