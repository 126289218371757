import React, { lazy, Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from '../../../../common/Ui/TextField/TextField';
import EmailReceivers from '../../../../common/Email/EmailReceivers';

const Wisiwig = lazy(() => import('../../../../ui-kit/components/Wisiwig'));

export default function EmailForm() {
  const { control } = useFormContext();
  return (
    <>
      <EmailReceivers />
      <TextField control={control} name="subject" label="Subject" />
      <Suspense fallback={<></>}>
        <Wisiwig control={control} name="message" />
      </Suspense>
    </>
  );
}
