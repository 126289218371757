import React from 'react';
import Grid from '@mui/material/Grid';

interface IProps {
  title: string;
}

const FormSectionTitle: React.FC<IProps> = ({ title = '' }) => {
  return (
    <Grid item container style={{ color: 'primary.main' }}>
      {title}
    </Grid>
  );
};

export default FormSectionTitle;
