import { useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { getThemeObjectByMode } from '../../../theme';

export const useThemeResponsive = () => {
  const { themeMode } = useRootStore();
  const themeOverriden = useMemo(
    () => getThemeObjectByMode(themeMode),
    [themeMode]
  );
  const isMobile: boolean = useMediaQuery(
    themeOverriden.breakpoints.down('tablet'),
    {
      noSsr: true,
    }
  );
  return {
    themeOverriden,
    isMobile,
  };
};
