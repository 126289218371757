import { Box, Grid, Link, Typography } from '@mui/material';
import MultilineCellWithIcon from '../../MultilineCellWithIcon';
import ButtonImproved from '../../ButtonImproved';
import React, { useMemo, useState } from 'react';
import AxeleDialog from '../../AxeleDialog/AxeleDialog';
import moment from 'moment';
import { useDarkMode } from 'storybook-dark-mode';
import { getThemeObjectByMode } from '../../../theme';
import { ThemeProvider } from '@mui/material/styles';

export const AccountCardDetail = ({
  val,
  accountIconStyle,
  accountTitleStyle,
  acountCardData,
  accountName,
  accountData,
  componentToRenderInsideModal,
  integrationType,
  clickedParamToManage,
  accountMappingButton,
  resetFilters,
  resetRequestData,
}: any) => {
  let hasManage = false;
  const [isDialogOpen, setDialog] = useState(false);
  switch (val.label) {
    case 'Drivers':
    case 'Tractors':
    case 'Trailers':
    case 'Customer':
      hasManage = true;
  }
  const openDialog = (e: any) => {
    e.preventDefault();
    setDialog(true);
    clickedParamToManage(val.label, accountData, val.key);
  };
  const closeDialog = () => {
    setDialog(false);
    resetFilters && resetFilters();
    resetRequestData && resetRequestData();
  };
  return (
    <Box className="account-card-info">
      {val.value !== '' && val.type === 'text' && (
        <Grid
          className="detail"
          sx={{
            display: 'flex',
            paddingBottom: '16px',
            alignItems: 'center',
          }}
        >
          {val.icon ? (
            <MultilineCellWithIcon
              title={val.label}
              titleStyle={accountTitleStyle}
              icon={val.icon}
              iconStyle={accountIconStyle}
              subtitle={''}
              subtitleStyle={undefined}
            />
          ) : (
            val.label
          )}
          <span style={{ paddingLeft: '5px', paddingRight: '16px' }}>:</span>
          <Box className="value-wrapper">
            <Typography
              className="value"
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: 'primary.main',
                wordBreak: 'break-all',
              }}
            >
              {val.value}
            </Typography>
          </Box>
          {hasManage && val.value != '0 out of 0' && (
            <Link
              href="#"
              sx={{
                pl: '8px',
                color: 'primary.dark',
                textDecorationColor: 'primary.dark',
              }}
              onClick={openDialog}
            >
              Manage
            </Link>
          )}
        </Grid>
      )}

      {val.value !== '' && val.type === 'button' && (
        <Grid
          className="detail"
          sx={{
            display: 'flex',
            paddingBottom: '16px',
            alignItems: 'center',
          }}
        >
          <ButtonImproved
            id=""
            label={val.label}
            onClick={(e: Event) => {
              integrationType === 'ACCOUNTING' ||
              integrationType === 'FACTORING'
                ? openDialog(e)
                : accountMappingButton(val.label);
            }}
            variant={val.layOut}
          />
        </Grid>
      )}

      {val.value !== '' && val.type === 'footerText' && (
        <Grid
          className="detail"
          sx={{
            display: 'flex',
            paddingBottom: '5px',
            alignItems: 'center',
          }}
        >
          <Typography
            className="value"
            variant="subtitle1"
            sx={{
              fontSize: '12px',
              color: 'text.primary',
              fontWeight: 400,
            }}
          >
            {val.value}{' '}
            {val.hyperLink &&
              (integrationType === 'LOADBOARD' ||
              integrationType === 'ACCOUNTING' ? (
                <Link
                  href={val.hyperLink}
                  target={'_blank'}
                  sx={{
                    pl: '8px',
                    color: 'primary.dark',
                    textDecorationColor: 'primary.dark',
                  }}
                >
                  {val.footerUrlString}
                </Link>
              ) : (
                <Link
                  href="#"
                  sx={{
                    pl: '8px',
                    color: 'primary.dark',
                    textDecorationColor: 'primary.dark',
                  }}
                  onClick={openDialog}
                >
                  Link
                </Link>
              ))}
          </Typography>
        </Grid>
      )}
      <AxeleDialog
        open={isDialogOpen}
        isTabsPanel={true}
        titleText={accountName}
        subTitleText={acountCardData.lastUpdate.replace(
          '{time}',
          `${moment(accountData?.lastUsedDate).fromNow()}`
        )}
        handleClose={closeDialog}
        renderComponent={componentToRenderInsideModal}
        dialogStyles={{
          '.MuiDialogContent-root': {
            borderRadius: 0,
            height: 'auto',
          },
        }}
        dialogPaperStyles={{
          transform: `translate(50px, 30px)`,
          height: '90%',
          overflow: 'hidden',
        }}
      />
    </Box>
  );
};
