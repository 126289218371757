import moment, { Moment } from 'moment';
import 'moment-timezone';
import {
  API_MAINTENANCE_HISTORY_COMPLETED_DATE_FORMAT,
  UI_RELATIVE_DR_FORMAT,
  UI_RELATIVE_DR_SELECTION_FORMAT,
} from '../constants/date.constants';
import StorageManager from '../StorageManager/StorageManager';

export class DateTimezoneUtils {
  static convertToMomentDate = ({
    givenDateWithTz,
    timezone,
  }: {
    givenDateWithTz: Moment | Date | string | number;
    timezone?: string;
  }): Moment | null => {
    if (!givenDateWithTz) {
      return null;
    }
    if (givenDateWithTz instanceof Date) {
      return timezone
        ? moment(givenDateWithTz).tz(timezone)
        : moment(givenDateWithTz);
    }

    if (moment.isMoment(givenDateWithTz)) {
      return givenDateWithTz;
    }

    if (typeof givenDateWithTz === 'string') {
      return timezone
        ? moment(new Date(givenDateWithTz)).tz(timezone)
        : moment(new Date(givenDateWithTz));
    }

    if (typeof givenDateWithTz === 'number') {
      return timezone
        ? moment(givenDateWithTz).tz(timezone)
        : moment(givenDateWithTz);
    }
    throw Error('Invalid input');
  };

  static getDateISOString = ({
    inputMoment,
    defaultValue = undefined,
    timezone,
  }: {
    inputMoment: Moment | null | undefined;
    defaultValue?: any;
    timezone?: string;
  }): string | any => {
    if (!inputMoment) return defaultValue;
    const result = inputMoment
      .clone()
      .tz(timezone || getGridCompanyTimezoneSettings().timezone)
      .toISOString();

    return result;
  };

  static toStartDateISOString = (
    givenDateWithTz: Moment | Date | string,
    defaultValue = undefined,
    timezone?: string
  ): string | any => {
    if (!givenDateWithTz) return defaultValue;
    const dateMoment = DateTimezoneUtils.convertToMomentDate({
      givenDateWithTz,
      timezone: timezone || getGridCompanyTimezoneSettings().timezone,
    });
    if (!dateMoment) return defaultValue;
    return DateTimezoneUtils.getDateISOString({
      inputMoment: dateMoment.startOf('day'),
      defaultValue,
      timezone: timezone || getGridCompanyTimezoneSettings().timezone,
    });
  };
  static toEndDateISOString = (
    givenDateWithTz: Moment | Date | string,
    defaultValue = undefined,
    timezone?: string
  ): string | any => {
    if (!givenDateWithTz) return defaultValue;
    const dateMoment = DateTimezoneUtils.convertToMomentDate({
      givenDateWithTz,
      timezone: timezone || getGridCompanyTimezoneSettings().timezone,
    });
    if (!dateMoment) return defaultValue;
    return DateTimezoneUtils.getDateISOString({
      inputMoment: dateMoment.endOf('day'),
      defaultValue,
      timezone: timezone || getGridCompanyTimezoneSettings().timezone,
    });
  };
  static toNoTimeZoneDateString = (
    givenDateWithTz: Moment | Date,
    defaultValue?: any,
    isStartDate = true
  ): string | any => {
    if (!givenDateWithTz) return defaultValue;
    const dateMoment = DateTimezoneUtils.convertToMomentDate({
      givenDateWithTz,
      timezone: undefined,
    });

    if (!dateMoment) return defaultValue;

    const dateOnly: string = dateMoment.format(
      API_MAINTENANCE_HISTORY_COMPLETED_DATE_FORMAT
    );
    const zonedDateString = isStartDate
      ? new Date(new Date(dateOnly).setUTCHours(0, 0, 0, 0)).toISOString()
      : new Date(new Date(dateOnly).setUTCHours(23, 59, 59, 999)).toISOString();
    return zonedDateString || defaultValue;
  };

  static getDefaultTimezone = (timezone?: string): string => {
    if (timezone) {
      return timezone;
    }
    return StorageManager.getItem('preferredTimeZone') || '';
  };
}

export const getGridCompanyTimezoneSettings = (
  withTimeTz = true
): {
  timezone: string;
  workWeekStartDay: string;
  withTimeTz: boolean;
  dateFormat: string;
  dateSelectionFormat: string;
} => {
  return {
    timezone: StorageManager.getItem('preferredTimeZone') || 'America/New_York',
    workWeekStartDay:
      (StorageManager.getItem('workWeekStartDay') as string) || 'MONDAY',
    withTimeTz: withTimeTz,
    dateFormat: UI_RELATIVE_DR_FORMAT,
    dateSelectionFormat: UI_RELATIVE_DR_SELECTION_FORMAT,
  };
};
