import { InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Box, useTheme } from '@mui/system';
import { t } from 'i18next';
import moment, { Moment } from 'moment';
import { FC, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../../../StorageManager/StorageManager';
import {
  maintenanceEquipmentService,
  maintenanceItemService,
  tractorService,
  trailerService,
} from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { DirtyDetailsPanelManager } from '../../../../../common/DetailsPanel/utils';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../../common/Permission';
import Checkbox from '../../../../../common/Ui/Checkbox';
import FileUploadUpdated from '../../../../../common/Ui/FileUpload/indexUpdated';
import DatePicker from '../../../../../common/Ui/FormDatePicker/FormDatePicker';
import { MultipleAutocompleteForm } from '../../../../../common/Ui/MultipleAutocomplete';
import RadioGroup from '../../../../../common/Ui/RadioGroup';
import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import Switch from '../../../../../common/Ui/Switcher';
import TextField from '../../../../../common/Ui/TextField/TextField';
import { Link } from '../../../../../components_v2/ui-kit/link';
import {
  DefaultTractorsTypesListSummaryDTO,
  DefaultTrailersTypesListSummaryDTO,
  QueryParams,
  TractorAlertQueryParamsRequest,
  TractorAlertSummaryDTO,
  TrailerAlertQueryParamsRequest,
  TrailerAlertSummaryDTO,
} from '../../../../../models';
import { GetMaintenanceEquipmentByOrganizationIdRequest } from '../../../../../models/DTOs/maintenance/maintenanceEquipment/Requests';
import {
  IMaintenanceEquipmentOption,
  IMaintenanceItemOption,
  IMaintenanceVendorOption,
} from '../../../../../models/DTOs/maintenance/maintenanceHistory/Model';
import { MaintenanceItem } from '../../../../../models/DTOs/maintenance/maintenanceItem/Model';
import { createVendor } from '../../../../../subPages/expenses/utils/utils';
import { isHasPermission } from '../../../../../utils';
import { getExpenseCategoriesList } from '../../../../finance/expenses/utils';
import { EquipmentType } from '../../../constants/constants';
import { MaintenanceHistoryFormContext } from '../../../context/MaintenanceHistoryFormContext';
import { FormItem } from '../Form';
import { getVendorNamesList } from './utils';

export interface MaintenanceDetailFormProps {
  lockEquipment?: boolean;
  disableAutocomplate?: boolean;
  disableAllFields?: boolean;
  handleOpenExpensePanel?: (
    expenseId: string | number,
    expenseSeqNumber: string | number,
    isGlobal?: boolean
  ) => void;
}

const MaintenanceDetailForm: FC<MaintenanceDetailFormProps> = ({
  lockEquipment = false,
  disableAllFields,
  disableAutocomplate = false,
  handleOpenExpensePanel,
}) => {
  // State
  const {
    defaultTractorsTypesList,
    setDefaultTractorsTypesList,
    defaultTrailersTypesList,
    setDefaultTrailersTypesList,
    tractorAlertList,
    setTractorAlertList,
    trailerAlertList,
    setTrailerAlertList,
    initialData,
  } = useContext(MaintenanceHistoryFormContext);

  // TODO: should check
  const isEdit = !!initialData?.id;

  // Form
  const { control, watch, setValue } = useFormContext();
  const fileId = watch('fileId');
  const theme: any = useTheme();

  const [
    equipmentType,
    maintenanceItems,
    equipmentItem,
    completionDate,
    odometer,
    schedule,
    maintenanceVendor,
    expense,
    createExpense,
    expenseSeqNumber,
    expenseId,
    categoryObj,
  ] = watch([
    'equipmentType',
    'maintenanceItems',
    'equipmentItem',
    'completedDate',
    'odometer',
    'schedule',
    'maintenanceVendor',
    'expense',
    'createExpense',
    'expenseSeqNumber',
    'expenseId',
    'categoryObj',
  ]) as [
    EquipmentType,
    IMaintenanceItemOption[] | undefined | null,
    IMaintenanceEquipmentOption | undefined | null,
    Moment | undefined | null,
    string,
    boolean,
    IMaintenanceVendorOption | undefined | null,
    number | any,
    boolean,
    string | undefined,
    string | undefined,
    (
      | {
          id?: string;
          itemCode?: string;
          itemName?: string;
        }
      | undefined
    )
  ];
  const userStorageData = StorageManager.getUser();
  console.log('expense', expense);
  // useEffect(()=>{
  //   if(expense === 0){
  //     setValue('expense', null)
  //   }
  // },[expense])
  // Effect
  useEffect(() => {
    const getInitData = async () => {
      const [defaultTractorsTypesList, defaultTrailersTypesList] =
        await Promise.all([
          getDefaultTractorsTypesList(),
          getDefaultTrailersTypesList(),
        ]);
      setDefaultTractorsTypesList?.(defaultTractorsTypesList);
      setDefaultTrailersTypesList?.(defaultTrailersTypesList);
      if (!categoryObj?.id) {
        const catagoryObjData = await getExpenseCategoriesList(
          'MAINTENANCE',
          1,
          'FUEL'
        );
        if (catagoryObjData && catagoryObjData?.content) {
          setValue('categoryObj', {
            id: catagoryObjData?.content[0]?.id,
            itemCode: catagoryObjData?.content[0]?.itemCode,
            itemName: catagoryObjData?.content[0]?.itemName,
          });
        }
      }
    };
    getInitData();
  }, []);

  useEffect(() => {
    const getAlert = async () => {
      if (equipmentItem?.value) {
        if (isTractor) {
          const data = await getTractorAlertList(equipmentItem.value);
          setTractorAlertList?.(data);
        } else {
          const data = await getTrailerAlertList(equipmentItem.value);
          setTrailerAlertList?.(data);
        }
      }
    };
    getAlert();
  }, [equipmentItem?.value]);

  useEffect(() => {
    if (equipmentItem?.value) {
      setValue('terminalName', equipmentItem.terminalName);
    }
  }, [equipmentItem]);

  useEffect(() => {
    const equipmentItem = initialData.equipmentId
      ? {
          value: initialData.equipmentId,
          label:
            initialData?.equipmentName || initialData.equipmentId.toString(),
          terminalId: initialData.terminalId || '',
        }
      : null;
    setValue('equipmentItem', equipmentItem);
  }, [equipmentType]);

  const createNewVendor = async (name: string) => {
    const vendor = await createVendor(name);
    if (vendor?.id) {
      setValue('maintenanceVendor', { label: vendor.name, value: vendor.id });
    }
  };

  // Helper

  const isTractor = equipmentType === EquipmentType.Tractor;

  const getDefaultTractorType = (
    maintenanceItemId: string
  ): DefaultTractorsTypesListSummaryDTO | undefined => {
    return defaultTractorsTypesList.find(({ maintenanceTypeId }) => {
      return maintenanceTypeId === maintenanceItemId;
    });
  };

  const getDefaultTrailerType = (
    maintenanceItemId: string
  ): DefaultTrailersTypesListSummaryDTO | undefined => {
    return defaultTrailersTypesList.find(({ maintenanceTypeId }) => {
      return maintenanceTypeId === maintenanceItemId;
    });
  };

  const getDefaultType = (
    itemCode: string
  ):
    | DefaultTrailersTypesListSummaryDTO
    | DefaultTractorsTypesListSummaryDTO
    | undefined => {
    if (isTractor) {
      return getDefaultTractorType(itemCode);
    }
    return getDefaultTrailerType(itemCode);
  };

  const getTractorAlert = (
    maintenanceItemId: string
  ): TractorAlertSummaryDTO | undefined => {
    return tractorAlertList.find(
      ({ maintenanceTypeId, tractorId }) =>
        maintenanceTypeId === maintenanceItemId &&
        equipmentItem &&
        equipmentItem.value === tractorId
    );
  };

  const getTrailerAlert = (
    maintenanceItemId: string
  ): TrailerAlertSummaryDTO | undefined => {
    return trailerAlertList.find(
      ({ maintenanceTypeId, trailerId }) =>
        maintenanceTypeId === maintenanceItemId &&
        equipmentItem &&
        equipmentItem.value === trailerId
    );
  };

  const getAlert = (
    itemCode: string
  ): TrailerAlertSummaryDTO | TractorAlertSummaryDTO | undefined => {
    if (isTractor) {
      return getTractorAlert(itemCode);
    }
    return getTrailerAlert(itemCode);
  };

  const isTractorNextDateValid = (maintenanceItemId: string): boolean => {
    const tractorAlert = getTractorAlert(maintenanceItemId);
    return (
      !!tractorAlert &&
      !!completionDate &&
      moment(tractorAlert.lastConducted) < completionDate
    );
  };

  const isTrailerNextDateValid = (itemCode: string): boolean => {
    const trailerAlert = getTrailerAlert(itemCode);
    return (
      !!trailerAlert &&
      !!completionDate &&
      moment(trailerAlert.lastConducted) < completionDate
    );
  };

  const isScheduleNextDateValid = (maintenanceItemId: string): boolean => {
    if (isTractor) {
      return isTractorNextDateValid(maintenanceItemId);
    } else {
      return isTrailerNextDateValid(maintenanceItemId);
    }
  };

  const isScheduleNextOdoValid = (itemCode: string): boolean => {
    if (isTractor) {
      const tractorAlert = getTractorAlert(itemCode);
      return !!tractorAlert && +tractorAlert.lastServiceDueOdometer < +odometer;
    }
    return false;
  };

  const getValidScheduleItems = (): IMaintenanceItemOption[] => {
    return (
      (maintenanceItems || [])
        // at least 1 is configured
        .filter(({ value }) =>
          (isTractor
            ? defaultTractorsTypesList
            : defaultTrailersTypesList
          ).some(({ maintenanceTypeId }) => maintenanceTypeId === value)
        )
        // at least 1 is added
        .filter(({ value }) =>
          (isTractor ? tractorAlertList : trailerAlertList).some(
            ({ maintenanceTypeId }) => maintenanceTypeId === value
          )
        )
        .filter(({ value }) => {
          return (
            isScheduleNextDateValid(value) || isScheduleNextOdoValid(value)
          );
        })
    );
  };

  const isEnabledSchedule: boolean =
    !!getValidScheduleItems().length && !!completionDate;

  useEffect(() => {
    getValidScheduleItems().forEach(({ value }: IMaintenanceItemOption) => {
      const alert = getAlert(value);
      const defaultType = getDefaultType(value);
      if (alert && defaultType) {
        if (isScheduleNextDateValid(value) && defaultType.frequency) {
          setValue(
            `schedules.${value}.nextDate`,
            moment(completionDate).add(
              1,
              defaultType.frequency === 'YEARLY' ? 'year' : 'month'
            )
          );
        }
        if (isTractor) {
          const defaultTractorType = getDefaultTractorType(value);
          if (isScheduleNextOdoValid(value) && defaultTractorType) {
            setValue(
              `schedules.${value}.nextOdo`,
              (
                +odometer + (defaultTractorType.frequencyByMiles || 0)
              ).toString()
            );
          }
        }
      }
    });
  }, [
    completionDate,
    odometer,
    maintenanceItems,
    equipmentItem,
    schedule,
    isEnabledSchedule,
  ]);

  const noPermissionForEditMainetenance = !isHasPermission([
    AXELE_PERMISSION_TYPE.MAINTENANCE_HISTORY_EDIT,
  ]);

  const disabledField = noPermissionForEditMainetenance || disableAllFields;

  const renderNextMaintenanceFields = () => {
    return getValidScheduleItems().map(
      ({ value, label }: IMaintenanceItemOption) => (
        <Grid container key={value} spacing={3}>
          <Grid item xs={6}>
            <DatePicker
              shouldUnregister
              name={`schedules.${value}.nextDate`}
              control={control}
              label={`Next ${label} Date`}
            />
          </Grid>
          {isTractor && (
            <Grid item xs={6}>
              <TextField
                shouldUnregister
                name={`schedules.${value}.nextOdo`}
                control={control}
                label={`Next ${label} Odo (mi)`}
                type="number"
              />
            </Grid>
          )}
        </Grid>
      )
    );
  };

  const AssetTypeOptions = [
    {
      value: EquipmentType.Tractor,
      label: 'Tractor',
      disabled: isEdit || lockEquipment,
    },
    {
      value: EquipmentType.Trailer,
      label: 'Trailer',
      disabled: isEdit || lockEquipment,
    },
  ];

  const renderEquipmentItem = () => {
    return (
      <SingleAutocompleteForm
        disabled={isEdit || lockEquipment}
        getOptions={async (searchText, pageNumber) => {
          const response =
            await maintenanceEquipmentService.getByOrganizationId(
              new GetMaintenanceEquipmentByOrganizationIdRequest({
                equipmentType,
                pageNumber,
                nameFilter: searchText,
                pageSize: 20,
              })
            );
          if (response instanceof ServiceError) {
            return {
              content: [],
            };
          }
          return {
            content: response.content.map<IMaintenanceEquipmentOption>(
              ({ equipmentId, equipmentName, terminalId, terminalName }) => ({
                label: equipmentName,
                value: equipmentId,
                terminalId,
                terminalName,
              })
            ),
            last: !response.content.length,
          };
        }}
        name="equipmentItem"
        control={control}
        label={isTractor ? 'Tractor' : 'Trailer'}
        fieldName="label"
        shouldUnregister
        required
      />
    );
  };
  return (
    <Box
      sx={{
        p: 2,
        height: '100%',
      }}
    >
      <RadioGroup
        row
        label={t('assetType')}
        name="equipmentType"
        data={AssetTypeOptions}
        defaultValue={EquipmentType.Tractor}
        control={control}
        disabled={isEdit || lockEquipment || disabledField}
      />
      <MultipleAutocompleteForm
        name="maintenanceItems"
        variant="standard"
        control={control}
        label="Maintenance Item"
        required
        disabled={disableAutocomplate || disabledField}
        fieldName="label"
        getOptions={async () => {
          const response =
            (await maintenanceItemService.getByOrganizationId()) as MaintenanceItem[];
          return {
            last: true,
            content: response.map<IMaintenanceItemOption>(
              ({ itemCode, itemName, id, iconName }) => ({
                label: itemName,
                value: id,
                itemCode: itemCode,
                iconName: iconName,
              })
            ),
          };
        }}
      />
      <Grid spacing={3} container wrap="nowrap">
        <Grid item xs={6}>
          {isTractor && renderEquipmentItem()}
          {!isTractor && renderEquipmentItem()}
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            label="Terminal"
            name="terminalName"
            control={control}
          />
        </Grid>
      </Grid>
      <DatePicker
        styleProps={{
          width: 1,
        }}
        label={t('completionDate')}
        required
        disabled={disabledField}
        control={control}
        name="completedDate"
        disableFuture
      />
      {isTractor && (
        <TextField
          label="Odometer (mi)"
          control={control}
          name="odometer"
          disabled={disabledField}
          shouldUnregister
        />
      )}
      <Grid spacing={3} container wrap="nowrap">
        <Grid item xs={6}>
          <SingleAutocompleteForm
            disabled={disabledField}
            name={'maintenanceVendor'}
            label={t('vendor')}
            fieldName={'label'}
            getOptions={getVendorNamesList}
            control={control}
            freeSolo
            addNewOption={createNewVendor}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={disabledField}
            type="number"
            label="Amount"
            required={createExpense}
            name="expense"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid spacing={3} container wrap="nowrap">
        <Grid item xs={6}>
          {expenseId ? (
            <>
              <InputLabel
                htmlFor="expenseId"
                sx={{
                  color: 'rgba(0, 17, 34, 0.6)',
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  marginBottom: '7px',
                  fontSize: '0.71rem !important',
                  lineHeight: '1.4375em !important',
                }}
              >
                Expense Id
              </InputLabel>
              <Link
                variant="h7"
                id="expenseId"
                sx={{
                  color: 'primary.main',
                  textDecoration: 'underline',
                  whiteSpace: 'pre-wrap',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginLeft: '5px',
                }}
                onClick={(event) => {
                  if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
                  event.stopPropagation();
                  handleOpenExpensePanel &&
                    handleOpenExpensePanel(expenseId, expenseSeqNumber, true);
                }}
              >
                {expenseSeqNumber || expenseId}
              </Link>
            </>
          ) : (
            <Switch
              control={control}
              label={'Create Expense'}
              name={'createExpense'}
              disabled={expense || createExpense ? false : true}
              style={{ color: theme.palette.text.primary }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {createExpense && (
            <SingleAutocompleteForm
              id={'ExpenseCategory'}
              name="categoryObj"
              label={t('expenseCategory')}
              fieldName="itemName"
              required
              getOptions={(name: string, pageNumber: number) =>
                getExpenseCategoriesList(name, pageNumber, 'FUEL')
              }
              control={control}
            />
          )}
        </Grid>
      </Grid>
      <TextField
        name="notes"
        control={control}
        label="Notes"
        disabled={disabledField}
      />
      <FormItem>
        <Permission contains={[AXELE_PERMISSION_TYPE.MAINTENANCE_HISTORY_ADD]}>
          <FileUploadUpdated
            name="receipt"
            label="Upload"
            fileId={fileId}
            control={control}
            disabled={disableAllFields}
          />
        </Permission>
      </FormItem>
      {!isEdit && (
        <>
          <Checkbox
            disabled={!isEnabledSchedule}
            name="schedule"
            label={t('scheduleNextMaintenance')}
            control={control}
          />
          {isEnabledSchedule && schedule && renderNextMaintenanceFields()}
        </>
      )}
    </Box>
  );
};

const getDefaultTractorsTypesList = async (): Promise<
  DefaultTractorsTypesListSummaryDTO[]
> => {
  try {
    const requestData = new QueryParams();
    const data = await tractorService.getDefaultTractorsTypesList(requestData);

    if (!data || data instanceof ServiceError) {
      return [];
    } else {
      return data;
    }
  } catch (error) {
    return [];
  }
};

const getDefaultTrailersTypesList = async (): Promise<
  DefaultTrailersTypesListSummaryDTO[]
> => {
  try {
    const requestData = new QueryParams();
    const data = await trailerService.getDefaultTrailersTypesList(requestData);

    if (!data || data instanceof ServiceError) {
      return [];
    } else {
      return data;
    }
  } catch (error) {
    return [];
  }
};

const getTractorAlertList = async (
  tractorId: number
): Promise<TractorAlertSummaryDTO[]> => {
  try {
    const requestData = new TractorAlertQueryParamsRequest(tractorId);
    const data = await tractorService.getTractorAlertList(requestData);

    if (!data || data instanceof ServiceError) {
      return [];
    } else {
      return data;
    }
  } catch (error) {
    return [];
  }
};

const getTrailerAlertList = async (
  trailerId: number
): Promise<TrailerAlertSummaryDTO[]> => {
  try {
    const requestData = new TrailerAlertQueryParamsRequest(trailerId);
    const data = await trailerService.getTrailerAlertList(requestData);

    if (!data || data instanceof ServiceError) {
      return [];
    } else {
      return data;
    }
  } catch (error) {
    return [];
  }
};

export default MaintenanceDetailForm;
