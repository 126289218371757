import StorageManager from '../../../../StorageManager/StorageManager';
import { terminalService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { DeleteTerminalDTO, TerminalContentDTO } from '../../../../models';
import { PhoneType, addressType } from '../../../../types';

export interface ITerminalFormData {
  id?: string;
  terminalLogoFile?: File;
  companyName: string;
  contactName: string;
  dot: string;
  mc: number;
  phoneData: PhoneType;
  faxData: PhoneType;
  email: string;
  addressData: addressType;
}

export interface ICreateTerminalBodyRequest {
  addressData: addressType;
  companyName: string;
  contactPerson: {
    countryCode: string;
    email: string;
    extension: number;
    fax: number;
    faxCountryCode: string;
    faxExtension: number;
    firstName: string;
    phone: number;
  };
  dot: string;
  mc: number;
  isPrimary: boolean;
  organizationId: number;
}

export const fromTerminalContentDTOToFormData = (
  data: TerminalContentDTO | null
): ITerminalFormData => {
  return {
    id: data?.id ?? undefined,
    terminalLogo: null,
    companyName: data?.companyName || '',
    contactName: data?.contactPerson?.firstName || '',
    dot: data?.dot || '',
    mc: data?.mc ?? null,
    phoneData: {
      phone: data?.contactPerson?.phone,
      countryCode: data?.contactPerson?.countryCode || 'USA',
      extension: data?.contactPerson?.extension,
    },
    faxData: {
      phone: data?.contactPerson?.fax,
      countryCode: data?.contactPerson?.faxCountryCode || 'USA',
      extension: data?.contactPerson?.faxExtension,
    },
    email: data?.contactPerson?.email || '',
    addressData: data?.addressData
      ? {
          ...data?.addressData,
          zip: data?.addressData?.zipcode,
        }
      : {},
  };
};

export const fromTerminalFormDataToBodyRequest = (
  formData: ITerminalFormData
): ICreateTerminalBodyRequest => {
  const userStorage = StorageManager.getUser();
  return {
    id: formData.id,
    addressData: {
      fullAddress: formData.addressData?.fullAddress,
      address: formData.addressData?.address,
      city: formData.addressData?.city,
      state: formData.addressData?.state,
      streetAddress: formData.addressData?.streetAddress,
      zipcode: formData.addressData?.zip,
    },
    companyName: formData.companyName,
    contactPerson: {
      email: formData.email,
      countryCode: formData.phoneData.countryCode,
      extension: formData.phoneData.extension,
      phone: formData.phoneData.phone,
      faxCountryCode: formData.faxData.countryCode,
      faxExtension: formData.faxData.extension,
      fax: formData.faxData.phone,
      firstName: formData.contactName,
    },
    dot: formData.dot,
    isPrimary: false,
    mc: formData.mc,
    organizationId: userStorage.organizationId,
  };
};

export const createTerminalHandler = async ({
  formData,
}: {
  formData: ITerminalFormData;
}): Promise<TerminalContentDTO | null | ServiceError> => {
  const payload: ICreateTerminalBodyRequest =
    fromTerminalFormDataToBodyRequest(formData);
  const createdTerminal = await terminalService.createTerminal(payload);
  return createdTerminal;
};

export const updateTerminalHandler = async ({
  formData,
}: {
  formData: ITerminalFormData;
}): Promise<TerminalContentDTO | null | ServiceError> => {
  const payload: ICreateTerminalBodyRequest =
    fromTerminalFormDataToBodyRequest(formData);
  const updatedTerminal = await terminalService.updateTerminal(payload);
  return updatedTerminal;
};

export const deleteTerminalHandler = async (
  id: string
): Promise<null | ServiceError> => {
  const payload = new DeleteTerminalDTO();
  payload.id = id;
  const result = await terminalService.deleteTerminal(payload);
  return result;
};
