import React, { useEffect, useState } from 'react';
import { RecurrenceTypes } from './constants/interface';
import { RecurrenceContent } from './RecurrenceComponents/RecurrenceContent';
import {
  defaultData,
  filterValidation,
  numValidate,
  requiredCheck,
  requiredCheckWeeklyRecurs,
} from './constants/constants';
import { cloneDeep, isEmpty } from 'lodash';

const Recurrence = ({
  editData = {},
  dialog = true,
  onCloseDialog,
  useDarkMode = true,
  mode = 'create',
  createRecurrence,
  deleteRecurrence,
  entityType = '',
  showDelete = true,
  isInitialCreate = false,
  expenseCategoriesList = [],
  expenseCategoriesDefaultValue = '',
  disabledManualInput,
  today,
  endDay,
}: RecurrenceTypes) => {
  const [data, setData] = useState<{}>(
    mode == 'create' ? cloneDeep(defaultData) : editData
  );
  const [error, setError] = useState({});

  const onChangeRadio = (event: any, id: string) => {
    setError({});
    const tempData = { ...data, [id]: event.target.value };
    setData(tempData);
  };
  useEffect(() => {
    const dataSet = data;
    if (isInitialCreate && !dataSet.expenseType) {
      dataSet.expenseType = expenseCategoriesDefaultValue;
      setData(dataSet);
    }
  }, [expenseCategoriesDefaultValue]);
  const onChangeCheckbox = (event: any) => {
    const tempData = { ...data };
    if (event.target.checked) {
      tempData.weekDays.push(event.target.name);
    } else {
      const index = tempData.weekDays.indexOf(event.target.name);
      if (index > -1) tempData.weekDays.splice(index, 1);
    }
    setData(tempData);
  };

  const handleDateChange = (date: any, error: boolean, id: string) => {
    if (!date) return;
    const tempData = { ...data, [id]: date.toISOString() };
    setData(tempData);
  };

  const onDelete = async () => {
    const tempData = { ...data };
    await deleteRecurrence(tempData);
  };

  const onSubmit = async () => {
    const tempData = { ...data };
    const commonError = await commonValidation(tempData);
    let isError = {};
    if (tempData.main == 'weekly')
      isError = await weeklyValidation(tempData, commonError);
    if (tempData.main == 'monthly')
      isError = await monthlyValidation(tempData, commonError);
    if (!isEmpty(commonError)) {
      const errorList = { ...commonError, ...isError };
      setError(errorList);
      return;
    }
    if (isEmpty(isError) && isEmpty(commonError))
      await createRecurrence(tempData);
    else setError(isError);
  };

  const weeklyValidation = async (values: any, commonError: any) => {
    const tempError = { ...commonError };
    tempError.weeklyRecurs = await requiredCheckWeeklyRecurs(
      values.weeklyRecurs ? values.weeklyRecurs : ''
    );
    if (!tempError.weeklyRecurs)
      tempError.weeklyRecurs = await numValidate(
        values.weeklyRecurs ? values.weeklyRecurs : ''
      );
    if (!tempError.weeklyRecurs)
      tempError.weeklyRecurs =
        values.weeklyRecurs < 1 || values.weeklyRecurs > 4
          ? 'Please enter number from 1 to 4'
          : null;
    tempError.weekDays = await requiredCheck(
      values.weekDays ? values.weekDays : []
    );
    // if (tempError.weekDays)
    //   alert(validationText)
    const errRes = await filterValidation(tempError);
    return errRes;
  };

  const monthlyValidation = async (values: any, commonError: any) => {
    const tempError = { ...commonError };
    tempError.monthlyRecurs = await requiredCheckWeeklyRecurs(
      values.monthlyRecurs ? values.monthlyRecurs : ''
    );
    if (!tempError.monthlyRecurs)
      tempError.monthlyRecurs = await numValidate(
        values.monthlyRecurs ? values.monthlyRecurs : ''
      );
    if (!tempError.monthlyRecurs)
      tempError.monthlyRecurs =
        values.monthlyRecurs < 1 || values.monthlyRecurs > 31
          ? 'Day cannot be more than 31'
          : null;
    const errRes = await filterValidation(tempError);
    return errRes;
  };

  const amountValidation = async (values: any) => {
    values = Number(values);
    if (!isNaN(values) && values >= 0.0001 && values <= 999999999) {
      return null;
    } else {
      return 'Required';
    }
  };

  const commonValidation = async (values: any) => {
    const tempError = {};
    // if (values.end == 'endAfter') {
    //   tempError.endAfterField = await requiredCheck(values.endAfterField ? values.endAfterField : '')
    //   if (!tempError.endAfterField)
    //     tempError.endAfterField = await numValidate(values.endAfterField ? values.endAfterField : '')
    // }
    // if (values.end == 'endBy')
    if (isInitialCreate) {
      tempError.amount = await amountValidation(
        values.amount ? values.amount : null
      );
    }
    tempError.endByDate = await requiredCheck(
      values.endByDate ? values.endByDate : ''
    );
    tempError.rangeDate = await requiredCheck(
      values.rangeDate ? values.rangeDate : ''
    );
    if (values.rangeDate && values.endByDate)
      tempError.endByDate =
        values.rangeDate >= values.endByDate
          ? 'End Date can not be less than or equal to the Start Date'
          : null;

    if (values?.description?.length > 255) {
      tempError.description = 'Description must be at most 255 characters.';
    }

    const errRes = await filterValidation(tempError);
    return errRes;
  };
  const handleChangeDropDown = (event: any) => {
    const tempData = { ...data };
    expenseCategoriesDefaultValue = event;
    tempData.expenseType = event;
    setData(tempData);
  };
  return (
    <>
      <RecurrenceContent
        data={data}
        onChangeRadio={onChangeRadio}
        onChangeCheckbox={onChangeCheckbox}
        dialog={dialog}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onCloseDialog={onCloseDialog}
        error={error}
        useDarkMode={useDarkMode}
        handleDateChange={handleDateChange}
        handleChangeDropDown={handleChangeDropDown}
        mode={mode}
        showDelete={showDelete}
        entityType={entityType}
        endDay={endDay}
        isInitialCreate={isInitialCreate}
        expenseCategoriesList={expenseCategoriesList}
        expenseCategoriesDefaultValue={expenseCategoriesDefaultValue}
        disabledManualInput={disabledManualInput}
        today={today}
      />
    </>
  );
};

export default Recurrence;
