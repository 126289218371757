import { Box, Skeleton } from '@mui/material';

const EvaluateSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box>
        <Skeleton width={200} height={20} />
        <Skeleton width={200} animation="wave" height={20} />
      </Box>
      <Skeleton animation="wave" width={'100%'} height={32} sx={{ mt: 1 }} />

      <Box sx={{ display: 'flex', gap: '8px', height: '20px', mt: 1 }}>
        <Skeleton width={190} height={28} animation="wave" />
        <Skeleton
          animation="wave"
          height={28}
          width={190}
          sx={{ marginLeft: 'auto' }}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', height: '20px' }}>
        <Skeleton width={190} height={28} animation="wave" />
        <Skeleton
          animation="wave"
          height={28}
          width={190}
          sx={{ marginLeft: 'auto' }}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', height: '20px' }}>
        <Skeleton width={190} animation="wave" height={28} />
        <Skeleton
          animation="wave"
          height={28}
          width={190}
          sx={{ marginLeft: 'auto' }}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', height: '20px' }}>
        <Skeleton width={190} animation="wave" height={28} />
        <Skeleton
          animation="wave"
          height={28}
          width={190}
          sx={{ marginLeft: 'auto' }}
        />
      </Box>
    </Box>
  );
};

export default EvaluateSkeleton;
