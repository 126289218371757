import { Scheduler } from '@bryntum/schedulerpro';
import {
  AssignmentPreferenceType,
  LOCK_RESOURCE_KEYS,
  RECOMMENDED_RESOURCE_TYPE,
  REJECT_RESOURCE_KEYS,
  RESOURCE_STATE,
} from '../constants/optymization.const';
import { IRecommendedResource } from '../types/gantt.types';
import {
  IRecommendTrips,
  IRecommendTypeResource,
  LockOperationTypes,
} from '../types/recommendTrips.types';
import { IPossibleResource } from '../types/possibleResource.types';
import {
  isResourceTypeBrokeredTrip,
  isResourceTypeUnassignedTrip,
} from './recommendTrips.service';

export const generateLockOperationPayload = (
  type: LockOperationTypes,
  driver: IRecommendedResource,
  options = {}
) => {
  return {
    userSandboxOperationsType: type,
    driverGroupId: driver?.id,
    tripId: driver?.engineMultiTripOutput?.tripIdSelected,
    currentAssignmentPreference:
      driver?.engineMultiTripOutput?.assignmentPreferenceType,
    isBulkOperation: false,
    ...options,
  };
};

export const isResourceTypeSandbboxTrip = (resource: IRecommendedResource) => {
  if (
    isResourceTypeUnassignedTrip(resource) ||
    isResourceTypeBrokeredTrip(resource)
  )
    return true;
  return false;
};

export const getCurrentResourceLockActionType = (
  resource: IRecommendedResource
) => {
  // if (resource?.resourceState === RESOURCE_STATE.LOCKED)
  if (resource?.engineMultiTripOutput?.isLocked)
    return LOCK_RESOURCE_KEYS.UNLOCK;
  return LOCK_RESOURCE_KEYS.LOCK;
};

export const getCurrentResourceRejectActionType = (
  resource: IRecommendedResource
) => {
  if (resource?.engineMultiTripOutput?.isRejected)
    return REJECT_RESOURCE_KEYS.UNREJECT;
  return REJECT_RESOURCE_KEYS.REJECT;
};

export const getGanttColumnSize = (schedulerRef: Scheduler) => {
  const schedulerColumns = schedulerRef?.columns as any;
  const lockedWidth: number = schedulerColumns?.grid?.subGrids.locked.width;
  const columns: Array<{ width: number }> = [];
  schedulerColumns?.allRecords?.forEach((column: { width: number }) => {
    columns.push({ width: column?.width });
  });
  return { lockedWidth, columns };
};

export const isResourceOptimal = (
  resource: IPossibleResource | IRecommendedResource
) => resource?.engineMultiTripOutput?.optimalFlag;

export const updateAssignmentAsLocked = (
  resource: IRecommendedResource
): IRecommendedResource => {
  if (!resource?.engineMultiTripOutput?.tripIdSelected) return resource;
  resource.engineMultiTripOutput.isLocked = true;
  resource.engineMultiTripOutput.assignmentPreferenceType =
    AssignmentPreferenceType.lockedSelected;
  resource.resourceState = RESOURCE_STATE.LOCKED;
  return resource;
};

export const updateAssignmentAsUnLocked = (
  resource: IRecommendedResource
): IRecommendedResource => {
  if (!resource?.engineMultiTripOutput?.tripIdSelected) return resource;
  resource.engineMultiTripOutput.isLocked = false;
  resource.engineMultiTripOutput.assignmentPreferenceType =
    AssignmentPreferenceType.unlockedSelected;
  resource.resourceState = RESOURCE_STATE.UNLOCKED;
  return resource;
};

export const updateAssignmentAsRejected = (
  resource: IRecommendedResource
): IRecommendedResource => {
  if (!resource?.engineMultiTripOutput?.tripIdSelected) return resource;
  resource.engineMultiTripOutput.isRejected = true;
  resource.engineMultiTripOutput.assignmentPreferenceType =
    AssignmentPreferenceType.rejected;
  resource.resourceState = RESOURCE_STATE.REJECTED;
  return resource;
};

export const updateAssignmentAsUnRejected = (
  resource: IRecommendedResource
): IRecommendedResource => {
  if (!resource?.engineMultiTripOutput?.tripIdSelected) return resource;
  resource.engineMultiTripOutput.isRejected = false;
  resource.engineMultiTripOutput.assignmentPreferenceType =
    AssignmentPreferenceType.None;
  resource.resourceState = RESOURCE_STATE.NONE;
  return resource;
};

export const getCurrentResourceState = (resource: IRecommendedResource) => {
  if (resource.engineMultiTripOutput?.isLocked) return RESOURCE_STATE.LOCKED;
  else if (resource.engineMultiTripOutput?.isRejected)
    return RESOURCE_STATE.REJECTED;
  return RESOURCE_STATE.NONE;
};

export const shuffleList = (array: Array<any>): Array<any> => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const geSandboxResourceEntities = (
  driversMap: IRecommendTrips['drivers']
) => {
  const [assignedMap, brokeredTrips, unassignedDriversMap, unassignedTripsMap] =
    [
      new Map(
        driversMap?.get(
          RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER
        ) as IRecommendTypeResource
      ),
      new Map(
        driversMap?.get(
          RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP
        ) as IRecommendTypeResource
      ),
      new Map(
        driversMap?.get(
          RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER
        ) as IRecommendTypeResource
      ),
      new Map(
        driversMap?.get(
          RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP
        ) as IRecommendTypeResource
      ),
    ];
  return {
    assignedMap,
    brokeredTrips,
    unassignedDriversMap,
    unassignedTripsMap,
  };
};
