export const state = [
  {
    title: 'AL',
  },
  {
    title: 'AZ',
  },
  {
    title: 'AR',
  },
  {
    title: 'CA',
  },
  {
    title: 'CT',
  },
  {
    title: 'DC',
  },
  {
    title: 'DE',
  },
  {
    title: 'FL',
  },
  {
    title: 'GA',
  },
  {
    title: 'ID',
  },
  {
    title: 'IL',
  },
  {
    title: 'IN',
  },
  {
    title: 'IA',
  },
  {
    title: 'KS',
  },
  {
    title: 'KY',
  },
  {
    title: 'LA',
  },
  {
    title: 'ME',
  },
  {
    title: 'MD',
  },
  {
    title: 'MA',
  },
  {
    title: 'MI',
  },
  {
    title: 'MN',
  },
  {
    title: 'MS',
  },
  {
    title: 'MO',
  },
  {
    title: 'MT',
  },
  {
    title: 'NE',
  },
  {
    title: 'NV',
  },
  {
    title: 'NH',
  },
  {
    title: 'NJ',
  },
  {
    title: 'NY',
  },
  {
    title: 'NC',
  },
  {
    title: 'ND',
  },
  {
    title: 'OH',
  },
  {
    title: 'OK',
  },
  {
    title: 'OR',
  },
  {
    title: 'PA',
  },
  {
    title: 'RI',
  },
  {
    title: 'SC',
  },
  {
    title: 'SD',
  },
  {
    title: 'TN',
  },
  {
    title: 'TX',
  },
  {
    title: 'UT',
  },
  {
    title: 'VT',
  },
  {
    title: 'VA',
  },
  {
    title: 'WA',
  },
  {
    title: 'WV',
  },
  {
    title: 'WI',
  },
  {
    title: 'WY',
  },
];
export const zone = [
  {
    title: 'Zone 0',
    value: 'Z0',
  },
  {
    title: 'Zone 1',
    value: 'Z1',
  },
  {
    title: 'Zone 2',
    value: 'Z2',
  },
  {
    title: 'Zone 3',
    value: 'Z3',
  },
  {
    title: 'Zone 4',
    value: 'Z4',
  },
  {
    title: 'Zone 5',
    value: 'Z5',
  },
  {
    title: 'Zone 6',
    value: 'Z6',
  },
  {
    title: 'Zone 7',
    value: 'Z7',
  },
  {
    title: 'Zone 8',
    value: 'Z8',
  },
  {
    title: 'Zone 9',
    value: 'Z9',
  },
];
