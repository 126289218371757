import { EDispatch3DotMenuType } from '../../views/dispatch/constants/types';
import DispatchController from '../../views/dispatch/utils/Controller';
import FormDialog from '../Ui/FormDialog';
import { TimeoffForm } from './components/Form/TimeoffForm';
import { TimeoffDialogProps } from './types';
import { generateCreateTimeoffRequest } from './utils';
import { CreateOrEditTimeOffValidationSchema } from './utils/validationSchema';

export const TimeoffDialog = ({
  onClose,
  hocOnAction,
  loadData,
  action,
  timeoffFormData,
  canEditDate,
}: TimeoffDialogProps): JSX.Element => {
  const onAction = async (data: any): Promise<void> => {
    const timezone = data?.location?.timezone;
    const requestData = generateCreateTimeoffRequest({
      organizationId: loadData.organizationId,
      driverGroupId: loadData.driverGroupId,
      tractorId: loadData.tractorId,
      trailerId: loadData.trailerId,
      driverId: loadData.driverId,
      driverName: loadData.driverName,
      prevLoadId: data.previousLoad?.id,
      location: data.location,
      timezone: timezone,
      startDatePart: data.startDatePart,
      startTimePart: data.startTimePart,
      endDatePart: data.endDatePart,
      endTimePart: data.endTimePart,
      ...(action === EDispatch3DotMenuType.EDIT_TIMEOFF && {
        id: data.id,
      }),
    });
    let response;
    if (action === EDispatch3DotMenuType.REQUEST_TIMEOFF) {
      response = await DispatchController.instance().createTimeoff(requestData);
    } else if (action === EDispatch3DotMenuType.EDIT_TIMEOFF) {
      response = await DispatchController.instance().updateTimeoff(requestData);
    }

    if (response) {
      await onClose();
      await hocOnAction?.(action);
    }
  };

  const contentRenderer = () => {
    return (
      <TimeoffForm
        loadData={loadData}
        action={action}
        canEditDate={canEditDate}
      />
    );
  };

  return (
    <FormDialog
      data={timeoffFormData}
      titleText={
        action === EDispatch3DotMenuType.REQUEST_TIMEOFF
          ? 'Set Time Off'
          : 'Edit Time Off'
      }
      actionLabel="Save"
      open={true}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={contentRenderer}
      validationSchema={CreateOrEditTimeOffValidationSchema}
    ></FormDialog>
  );
};
