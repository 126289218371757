import { Button, ButtonProps, Typography } from '@mui/material';
import OptymLogo from '../../../_assets/static/svgs/OptymLogo';
import React from 'react';
import { darkTextStyles } from '../styles/evaluation.style';

interface IRecommendationButton extends ButtonProps {
  label?: string;
  id?: string;
}

const RecommendationButton: React.FC<IRecommendationButton> = (params) => {
  const { label = 'Recommendations', ...props } = params;
  return (
    <Button
      id={props?.id}
      variant="outlined"
      sx={{
        padding: '4px',
        gap: '8px',
        height: '28px',
      }}
      {...props}
    >
      <OptymLogo />
      <Typography
        fontSize={10}
        textTransform={'capitalize'}
        sx={darkTextStyles}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default RecommendationButton;
