import { PaymentType } from '../../../../../models';
import { getFinanceDeduction } from '../FinanceOverviewFormContext';
import { FinanceLoadloadexpenseListType } from '../FinanceOverviewModel';

export const fromRecordListToTotalAmount = ({
  recordList = [],
  allPaymentTypes,
}: {
  recordList: FinanceLoadloadexpenseListType[];
  allPaymentTypes: PaymentType[];
}): number => {
  let totalAmount = 0;
  recordList.forEach((recordData: any) => {
    const recordStatus = getFinanceDeduction(
      allPaymentTypes,
      recordData.loadRateType
    );
    if (recordStatus) {
      totalAmount = totalAmount - Number(recordData.amount);
    } else {
      totalAmount = totalAmount + Number(recordData.amount);
    }
  });

  return totalAmount;
};

export const getAvaiablePaymentLoadType = ({
  recordList = [],
  allPaymentTypes,
}: {
  recordList: FinanceLoadloadexpenseListType[];
  allPaymentTypes: PaymentType[];
}): PaymentType[] => {
  const excludePayTypes = recordList.reduce((output, { loadRateType }) => {
    output.push(loadRateType);
    return output;
  }, []);

  const result = allPaymentTypes.reduce((output, item) => {
    if (!excludePayTypes.includes(item.itemCode)) {
      output.push(item);
    }
    return output;
  }, []);
  return result;
};
