import { Box, Button, CircularProgress, Typography } from '@mui/material';
import FormDialog from '../../../../common/Ui/FormDialog';
import { FormDialogMode } from '../../../../common/Ui/FormDialog/constants';
import Popover, { PopoverProps } from '@mui/material/Popover';
import {
  ConfirmationPopupContainerStyles,
  bodyTextStyles,
  btnStyles,
  titleStyles,
} from '../../styles/confirmationPopup.style';
import { ResourceType } from '../../types/gantt.types';
import { RESOURCES_TYPE } from '../../constants/gantt.const';
import { useTranslation } from 'react-i18next';

export interface ResourceConflictDataType {
  trip: string;
  assignedDriver: string;
  newDriver: string;
  assignedDriverCount: number;
  newDriverCount: number;
  brokeredTrips?: Array<string>;
  isBrokeredTrip?: boolean;
}

export interface ConflictingConfirmPopupProps {
  data?: ResourceConflictDataType;
  onClose?: () => void;
  onConfirm?: () => void;
  anchorEl?: EventTarget;
  conflictType?: ResourceType;
  isLoading?: boolean;
  popover?: PopoverProps;
}

export const ConflictingConfirmPopup: React.FC<
  ConflictingConfirmPopupProps
> = ({
  data,
  onClose,
  onConfirm,
  anchorEl,
  conflictType,
  isLoading,
  popover,
}) => {
  const { t, ready } = useTranslation();

  const renderConflictText = () => {
    if (data?.isBrokeredTrip) {
      return (
        <Typography sx={bodyTextStyles}>
          Locking the assignment of Trip <b>{data?.assignedDriver}</b> for
          brokerage will remove the recommended assignment of Driver{' '}
          <b>{data?.newDriver}</b>. Do you want to continue?
        </Typography>
      );
    }
    if (data?.assignedDriverCount === 0) {
      return (
        <Typography sx={bodyTextStyles}>
          Locking the assignment of Driver <b>{data?.newDriver}</b> to{' '}
          {data?.brokeredTrips?.length! > 1 ? 'Trips' : 'Trip'}
          <b> {data?.brokeredTrips?.join?.('')}</b> will remove trip from
          recommendation for brokerage . Do you want to continue?
        </Typography>
      );
    }
    return (
      <Typography sx={bodyTextStyles}>
        Locking the assignment of Driver <b>{data?.newDriver}</b> to{' '}
        {data?.newDriverCount! > 1 ? 'Trips' : 'Trip'}
        <b> {data?.trip}</b> will unassign the recommended assignment of{' '}
        {conflictType === RESOURCES_TYPE.DRIVER
          ? data?.assignedDriverCount! > 1
            ? 'Drivers'
            : 'Driver'
          : data?.assignedDriverCount! > 1
          ? 'Trips'
          : 'Trip'}
        <b> {data?.assignedDriver}</b>
        {data?.brokeredTrips?.length
          ? ` and will remove trip ${data?.brokeredTrips?.join?.(
              ', '
            )} from recommendation for brokerage`
          : ''}
        . Do you want to continue?
      </Typography>
    );
  };

  if (ready) {
    return (
      <Popover
        open={true}
        anchorEl={anchorEl as Element}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={ConfirmationPopupContainerStyles}
        {...popover}
      >
        <Typography sx={titleStyles}>Are you sure?</Typography>
        {renderConflictText()}
        <Box display={'flex'} justifyContent={'end'} gap={'16px'}>
          <Button sx={btnStyles} disabled={isLoading} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ ...btnStyles, width: '96px' }}
            disabled={isLoading}
            variant="contained"
            onClick={onConfirm}
          >
            {isLoading ? <CircularProgress size={22} /> : 'Continue'}
          </Button>
        </Box>
      </Popover>
    );
  }
};
