import React from 'react';

export const LoadopsEdiLogo = () => {
  return (
    <svg
      width="131"
      height="21"
      viewBox="0 0 131 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 16.456V1.23516H3.67653V13.5883H10.2502V16.456H0.5Z"
        fill="#1DA59E"
      />
      <path
        d="M12.3962 12.8824V8.38236C12.3962 5.99996 13.5874 4.80876 15.9698 4.80876H19.0581C21.4405 4.80876 22.6317 5.99996 22.6317 8.38236V12.8824C22.6317 15.2648 21.4405 16.456 19.0581 16.456H15.9698C13.5874 16.456 12.3962 15.2648 12.3962 12.8824ZM15.4404 13.103C15.4404 13.5736 15.6757 13.8089 16.1462 13.8089H18.8816C19.3522 13.8089 19.5875 13.5736 19.5875 13.103V8.16177C19.5875 7.69117 19.3522 7.45587 18.8816 7.45587H16.1462C15.6757 7.45587 15.4404 7.69117 15.4404 8.16177V13.103Z"
        fill="#1DA59E"
      />
      <path
        d="M28.7912 16.456C26.4088 16.456 25.2176 15.2869 25.2176 12.9486C25.2176 10.6103 26.4088 9.44121 28.7912 9.44121H31.8354V8.16177C31.8354 7.69117 31.6001 7.45587 31.1295 7.45587H26.453V5.80143L27.7765 4.80876H31.306C33.6884 4.80876 34.8796 5.99996 34.8796 8.38236V16.456H28.7912ZM28.2618 13.103C28.2618 13.5736 28.4971 13.8089 28.9677 13.8089H31.8354V11.7795H28.9677C28.4971 11.7795 28.2618 12.0148 28.2618 12.4854V13.103Z"
        fill="#1DA59E"
      />
      <path
        d="M37.9951 12.8824V8.38236C37.9951 5.99996 39.1863 4.80876 41.5687 4.80876H44.9659V0.132202H48.01V16.456H41.5687C39.1863 16.456 37.9951 15.2648 37.9951 12.8824ZM41.0393 13.103C41.0393 13.5736 41.2746 13.8089 41.7452 13.8089H44.9659V7.45587H41.7452C41.2746 7.45587 41.0393 7.69117 41.0393 8.16177V13.103Z"
        fill="#1DA59E"
      />
      <path
        d="M51.3912 12.7501V4.94112C51.3912 2.47048 52.6265 1.23516 55.0971 1.23516H60.1266C62.5973 1.23516 63.8326 2.47048 63.8326 4.94112V12.7501C63.8326 15.2207 62.5973 16.456 60.1266 16.456H55.0971C52.6265 16.456 51.3912 15.2207 51.3912 12.7501ZM54.5677 12.9266C54.5677 13.3678 54.7883 13.5883 55.2295 13.5883H59.9943C60.4355 13.5883 60.6561 13.3678 60.6561 12.9266V4.76464C60.6561 4.32346 60.4355 4.10287 59.9943 4.10287H55.2295C54.7883 4.10287 54.5677 4.32346 54.5677 4.76464V12.9266Z"
        fill="#2F3B58"
      />
      <path
        d="M67.2174 20.8679V4.80876H73.6587C76.041 4.80876 77.2322 5.99996 77.2322 8.38236V12.8824C77.2322 15.2648 76.041 16.456 73.6587 16.456H70.2615V20.8679H67.2174ZM70.2615 13.8089H73.4822C73.9528 13.8089 74.1881 13.5736 74.1881 13.103V8.16177C74.1881 7.69117 73.9528 7.45587 73.4822 7.45587H70.2615V13.8089Z"
        fill="#2F3B58"
      />
      <path
        d="M80.0398 15.4634V13.8089H85.1135C85.6135 13.8089 85.8635 13.5295 85.8635 12.9707C85.8635 12.3971 85.6576 12.0736 85.2458 12.0001L82.8634 11.603C80.981 11.2795 80.0398 10.1912 80.0398 8.33824C80.0398 5.98526 81.231 4.80876 83.6134 4.80876H87.0326L88.3562 5.80143V7.45587H83.7899C83.2899 7.45587 83.0399 7.73529 83.0399 8.29412C83.0399 8.88237 83.2678 9.21326 83.7237 9.28679L86.0841 9.68386C87.9518 9.99269 88.8856 11.0662 88.8856 12.9045C88.8856 15.2722 87.687 16.456 85.2899 16.456H81.3634L80.0398 15.4634Z"
        fill="#2F3B58"
      />
      <path
        d="M97.4867 16.456V1.23516H108.141V4.69847H101.325V6.97057H107.48V10.4339H101.325V12.9927H108.141V16.456H97.4867Z"
        fill="#2F3B58"
      />
      <path
        d="M111.18 16.456V1.23516H116.871C121.254 1.23516 123.445 3.42638 123.445 7.80882V9.88239C123.445 14.2648 121.254 16.456 116.871 16.456H111.18ZM115.019 12.9927H117.092C118.769 12.9927 119.607 12.1471 119.607 10.4559V7.23528C119.607 5.54407 118.769 4.69847 117.092 4.69847H115.019V12.9927Z"
        fill="#2F3B58"
      />
      <path d="M126.662 16.456V1.23516H130.5V16.456H126.662Z" fill="#2F3B58" />
    </svg>
  );
};
