import { LocationOnOutlined } from '@mui/icons-material';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Typography } from '@mui/material';
import { textSecondary, timelineContentStyle } from '../style/style';
interface BookingTimelineProps {
  data: any;
}
export const BasicTimeline: React.FC<BookingTimelineProps> = ({ data }) => {
  return (
    <Timeline
      sx={{
        padding: '0px',
        '.MuiTimeline-root': {
          padding: 0,
        },
        '.MuiTimelineItem-root': {
          minHeight: '50px',
        },
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: '0px !important',
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <LocationOnOutlined sx={{ ...textSecondary }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ ...timelineContentStyle }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
            {`${data?.stops?.[0]?.location?.city}, ${data?.stops?.[0]?.location?.state}`}
          </Typography>
          {/* <Typography mb={'5px'} fontSize={'12px'} sx={{ ...textSecondary }}>
            <span style={{ fontWeight: 500 }}>PickUp:</span>
            {` ${TimeoffDateTime.dateToFormat({
              value: data?.stops?.[0]?.appointmentStartDate,
              format: UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE,
              timezone: data?.stops?.[0]?.location.timezone,
            })} - ${TimeoffDateTime.dateToFormat({
              value: data?.stops?.[0]?.appointmentEndDate,
              format: UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE,
              timezone: data?.stops?.[0]?.location.timezone,
            })}`}
          </Typography> */}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <LocationOnOutlined sx={{ ...textSecondary }} />
        </TimelineSeparator>
        <TimelineContent sx={{ ...timelineContentStyle }}>
          <Typography sx={{ fontSize: '14px', fontWeight: '700' }}>
            {`${data?.stops?.[1]?.location?.city}, ${data?.stops?.[1]?.location?.state}`}
          </Typography>
          {/* <Typography fontSize={'12px'} sx={{ ...textSecondary }}>
            <span style={{ fontWeight: 500 }}>DropOff: </span>
            {` ${TimeoffDateTime.dateToFormat({
              value: data?.stops?.[1]?.appointmentStartDate,
              format: UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE,
              timezone: data?.stops?.[1]?.location.timezone,
            })} - ${TimeoffDateTime.dateToFormat({
              value: data?.stops?.[1]?.appointmentEndDate,
              format: UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE,
              timezone: data?.stops?.[1]?.location.timezone,
            })}`}{' '}
          </Typography> */}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
