import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { numberFormat } from '../../../reports/basicReports/constant';
import { ILoadsearchTableItem } from '../../loadTypes';

type LoadedMiles = ILoadsearchTableItem['LoadedMiles'];

const LoadedMiles: FC<LoadedMiles> = (LoadedMiles) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '150%',
          letterSpacing: '0.15px',
        }}
        gutterBottom
      >
        {`${numberFormat(+LoadedMiles?.value, {
          minimumFractionDigits: 2,
        })}mi`}
      </Typography>
      <Typography variant="caption" gutterBottom sx={{ color: '#000' }}>
        {LoadedMiles.totalMiles}
        <span
          style={{ marginLeft: '5px' }}
        >{`(${LoadedMiles.stops} Stops)`}</span>
      </Typography>
    </Box>
  );
};

export default React.memo(LoadedMiles);
