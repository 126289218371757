import * as yup from 'yup';
import { rateSchema } from '../../../../../loadsList/CreateLoad/schema/rate';

export const MAX_DECIMAL_DIGITS_ALLOWED = 4;
export const DecimalDigitErrorMsg = 'Please enter upto 4 decimal digits.';
export const MAX_COMMODITY_NAME_LENGTH = 100;

export const createLoadInvoceRate = yup.object().shape({
  rate: yup.object().shape(rateSchema, [['baseRateType', 'rate']]),
});

// convert empty string to null for yup validation
export function transformEmptyStringToNull(value: any, originalValue: any) {
  if (originalValue?.trim?.() === '') {
    return null;
  }
  return value;
}

// convert empty string to number for yup validation
export function transformEmptyStringToNumber(value: any, originalValue: any) {
  if (originalValue?.trim?.() === '') {
    return null;
  }
  return isNaN(Number(value)) ? Number(value) : value;
}
