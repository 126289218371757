import { AXELE_ROLE_TYPE, AXELE_PERMISSION_TYPE } from '../../Permission';
import { getHasPermission } from '../../Permission/utils/helperUtils';

export const useCarrierPermission = () => {
  const hasCarrierViewPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CARRIER_VIEW],
  });
  const hasCarrierCreatePermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CARRIER_ADD],
  });
  const hasCarrierEditPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CARRIER_EDIT],
  });
  const hasCarrierRemovePermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CARRIER_REMOVE],
  });

  const hasCustomerAddPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.CUSTOMER_ADD],
  });

  const hasLocationAddPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOCATION_ADD],
  });
  const hasVendorAddPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.VENDOR_ADD],
  });

  return {
    hasCarrierViewPermission,
    hasCarrierCreatePermission: hasCarrierCreatePermission,
    hasCarrierEditPermission: hasCarrierEditPermission,
    hasCarrierDeletePermission: hasCarrierRemovePermission,
    hasCustomerAddPermission: hasCustomerAddPermission,
    hasLocationAddPermission: hasLocationAddPermission,
    hasVendorAddPermission: hasVendorAddPermission,
  };
};
