import { generateUtilityClasses } from '@mui/material';
import Box from '@mui/material/Box';
import { PickerSelectionState } from '@mui/x-date-pickers/internals/hooks/usePickerState';
import { MuiPickersAdapter } from '@mui/x-date-pickers/internals/models';
import clsx from 'clsx';
import React from 'react';
import { fromTextMaskGetHours, fromTextMaskGetMinutes } from '../utils';
import { ClockNumberRoot, StyledClockHeaderTime, StyledList } from './styles';
export const classes = generateUtilityClasses('PrivateClockNumber', [
  'selected',
  'disabled',
]);

export interface ClockNumberProps {
  className?: string;
  disabled: boolean;
  id: string | undefined;
  label: string;
  selected: boolean;
  onClick: () => void;
}
export function ClockNumber({
  className,
  disabled,
  label,
  selected,
  ...other
}: ClockNumberProps) {
  return (
    <ClockNumberRoot
      className={clsx(
        {
          [classes.selected]: selected,
          [classes.disabled]: disabled,
        },
        className
      )}
      aria-disabled={disabled ? true : undefined}
      aria-selected={selected ? true : undefined}
      role="option"
      {...other}
    >
      {label}
    </ClockNumberRoot>
  );
}

interface GetHourNumbersOptions {
  ampm: boolean;
  date: any;
  isDisabled: (value: number) => boolean;
  onChange: (value: number, isFinish?: PickerSelectionState) => void;
  /**
   * DOM id that the selected option should have
   * Should only be `undefined` on the server
   */
  selectedId: string | undefined;
  utils: MuiPickersAdapter<Date>;
  list: any[];
  isSelected: (value: number) => boolean;
  sx?: React.CSSProperties;
  title: string;
}

export function TimeNumbers({
  isDisabled,
  selectedId,
  onChange,
  list,
  isSelected,
  sx,
  title,
}: GetHourNumbersOptions) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0 16px',
        height: '100%',
        ...(sx ? sx : {}),
      }}
    >
      <StyledClockHeaderTime>{title}</StyledClockHeaderTime>
      <StyledList dense={true}>
        {list.map(([numberValue, label]) => {
          const selected = isSelected(numberValue);
          return (
            <ClockNumber
              onClick={() => onChange(numberValue)}
              key={numberValue}
              label={label}
              id={selected ? selectedId : undefined}
              disabled={isDisabled(numberValue)}
              selected={selected}
            />
          );
        })}
      </StyledList>
    </Box>
  );
}

export function HourNumbers(props: any) {
  const { textmaskValue, maxHours, utils, is24HFormat } = props;
  const currentHours = textmaskValue
    ? fromTextMaskGetHours(textmaskValue, { is24HFormat })
    : null;
  const f = utils.formatNumber;
  const list = [];
  for (let i = 0; i <= maxHours; i++) {
    list.push([i, f(i.toString().length === 1 ? `0${i}` : i.toString())]);
  }

  return (
    <TimeNumbers
      {...props}
      list={list}
      isSelected={(value: number) => {
        return value === currentHours;
      }}
    />
  );
}

export function MinutesNumbers(props: any) {
  const { minuteStep, textmaskValue, utils, is24HFormat } = props;
  const f = utils.formatNumber;
  const currentMinutes = textmaskValue
    ? fromTextMaskGetMinutes(textmaskValue, { is24HFormat })
    : null;
  const list = [];
  for (let i = 0; i <= 55; i += minuteStep) {
    list.push([i, f(i.toString().length === 1 ? `0${i}` : i.toString())]);
  }

  return (
    <TimeNumbers
      {...props}
      list={list}
      isSelected={(value: number) => {
        return value === currentMinutes;
      }}
    />
  );
}
