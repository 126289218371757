import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import TextFieldForm from '../../../../common/Ui/TextField/TextFieldFormDeprecated';
import TerminalController from '../../../../views/settings/terminals/TerminalController';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';

export const CreateTractorForm = ({ formData }: any) => {
  const { control, getValues } = useFormContext();
  const [axeleTractorName, axeleTractorVin, terminalId] = getValues([
    'axeleTractorName',
    'axeleTractorVin',
    'terminalId',
  ]);

  const { terminals } = TerminalController.instance();
  return (
    <Box>
      {formData.length > 1 ? (
        <>
          {terminals && terminals.length > 0 && (
            <Box>
              <SingleAutocompleteForm
                control={control}
                name={'terminalId'}
                fieldName={'name'}
                getOptions={() => new Promise((res) => res(terminals))}
                label={'Terminal'}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          <Box>
            <TextFieldForm
              control={control}
              name="axeleTractorName"
              label="Tractor #"
              defaultValue={axeleTractorName}
            />
          </Box>

          <Box sx={{ pt: '32px' }}>
            <TextFieldForm
              control={control}
              name="axeleTractorVin"
              label="Tractor VIN"
              defaultValue={axeleTractorVin}
            />
          </Box>
          {terminals && terminals.length > 0 && (
            <Box sx={{ pt: '32px' }}>
              <SingleAutocompleteForm
                control={control}
                name={'terminalId'}
                fieldName={'name'}
                getOptions={() => new Promise((res) => res(terminals))}
                label="Terminal"
                defaultValue={terminalId}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
