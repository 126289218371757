import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { convert2MomentTz } from '../../../../../utils/convert2MomentTz';

interface PickupDateTimeProps {
  value?: string;
  timezone?: string;
}

const PickupNDropoffDateTime: FC<PickupDateTimeProps> = ({
  value,
  timezone,
}) => {
  const momentValue = convert2MomentTz(value, timezone);
  if (!momentValue?.isValid()) return <></>;
  return (
    <Typography fontSize={14}>
      {momentValue.format('M/D/YY @ H:mmA z')}
    </Typography>
  );
};

export default PickupNDropoffDateTime;
