import CustomizedTooltips from '../../ui-kit/components/AxeleTooltip';
import HyperLink from '../../common/Ui/HyperLink/HyperLink';
import { AssetStatus } from '../../common/Ui/StatusComponent/StatusComponent';
import { GridColDefSelf } from '../../types';
import { getFormattedDateForGrid } from '../../utils';
import StatusBaseComponent from '../../ui-kit/components/Assets/StatusComponent/StatusBaseComponent';
import { t } from 'i18next';
export type AssetStatuses = {
  AVAILABLE: string;
  UNAVAILABLE: string;
  ASSIGNED: string;
};

type TractorSources = {
  MANUAL: string;
  ELD: string;
  DRIVER: string;
};

export const assetStatuses: AssetStatuses = {
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
  ASSIGNED: 'Assigned',
};

const tractorSources: TractorSources = {
  MANUAL: 'Manual',
  ELD: 'ELD',
  DRIVER: 'Driver',
};

export const columns: GridColDefSelf[] = [
  {
    flex: 1,
    minWidth: 120,
    field: 'tractorName',
    permanent: true,
    headerName: 'Tractor #',
    sortable: true,
    renderCell: (params) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <TractorGridIcon /> */}
        {params.value}
      </div>
    ),
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'status',
    headerName: 'Status',
    sortable: true,
    renderCell: (params) => {
      const key = params.value as keyof AssetStatuses;
      return <AssetStatus status={key} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'driver',
    headerName: 'Driver',
    isHyperLink: true,
    sortable: false,
    renderCell: (params) => <HyperLink value={params.value?.name} />,
  },
  {
    flex: 1,
    minWidth: 100,
    field: 'activeLoad',
    headerName: 'Active Load',
    isHyperLink: true,
    sortable: false,
    renderCell: (params) => (
      <HyperLink value={params.row.activeLoad?.seqNumber} />
    ),
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'terminal',
    headerName: 'Terminal',
    sortable: false,
    valueGetter: (params) => params.value?.name,
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'vin',
    headerName: 'Vin #',
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'licensePlateNumber',
    headerName: 'Reg. Plate #',
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'licenseValidTill',
    headerName: 'Reg. Expiry',
    sortable: true,
    valueGetter: (params) => getFormattedDateForGrid(params.value),
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'lastLocation',
    headerName: 'Last Location',
    sortable: false,
    renderCell: (params) => (
      <CustomizedTooltips
        placement="top"
        followCursor={false}
        titleFirstLine={params.value}
      >
        <span>{params.row.locationShort}</span>
      </CustomizedTooltips>
    ),
  },
  {
    flex: 1,
    minWidth: 120,
    sortable: false,
    field: 'lastUpdated',
    headerName: 'Last Updated At',
    valueGetter: (params) => getFormattedDateForGrid(params.value),
  },
  {
    flex: 1,
    minWidth: 150,
    sortable: false,
    field: 'locationSource',
    headerName: 'Location Source',
    valueGetter: (params) => {
      const key = params.value as keyof TractorSources;
      return tractorSources[key];
    },
  },
  {
    flex: 1,
    minWidth: 120,
    sortable: false,
    field: 'ownershipType',
    headerName: 'Ownership Type',
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'ownedCompany',
    headerName: 'Owned By',
    sortable: true,
  },
  {
    flex: 1,
    minWidth: 120,
    sortable: false,
    field: 'warning',
    headerName: 'Warning',
    renderCell: (params) => {
      return (
        params.value > 0 && (
          <StatusBaseComponent
            label={params.value}
            customStyles={{
              wrap: { backgroundColor: 'warning.main' },
              label: {
                color: 'primary.contrast',
              },
            }}
          />
        )
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    sortable: false,
    field: 'critical',
    headerName: 'Critical',
    renderCell: (params) => {
      return (
        params.value > 0 && (
          <StatusBaseComponent
            label={params.value}
            customStyles={{
              wrap: { backgroundColor: 'error.main' },
              label: {
                color: 'primary.contrast',
              },
            }}
          />
        )
      );
    },
  },
];

export const defaultFilters = [
  { default: true, name: 'statuses', data: [] },
  { default: true, name: 'tractors', data: [] },
  { default: false, name: 'terminals', data: [] },
  { default: false, name: 'drivers', data: [] },
];
