import { recurrenceService } from '../../../../api';
import StorageManager from '../../../../StorageManager/StorageManager';
import { DateTimezoneUtils } from '../../../../utils/Timezone.utils';
import {
  GetRecurrenceDTO,
  PaymentSchedulePayLoadObject,
} from './ScheduledPaymentConstant';

export const createDataScheduledPayment = async (
  payLoad: GetRecurrenceDTO,
  mode: string,
  url: string
) => {
  if (mode == 'create') {
    let request = new GetRecurrenceDTO();
    request = payLoad;
    const getRes = await recurrenceService.createRecurrenceData(request, url);
    return getRes;
  } else {
    let request = new GetRecurrenceDTO();
    request = payLoad;
    const getRes = await recurrenceService.updateRecurrenceData(request, url);
    return getRes;
  }
};

export const deleteDataScheduledPayment = async (
  templateSchedulerId: string,
  url: string
) => {
  const getRes = await recurrenceService.deleteRecurrenceData(
    [templateSchedulerId],
    url
  );
  return getRes;
};
export const setEditValues = (data: GetRecurrenceDTO, type: string) => {
  const userStorage = StorageManager.getUser();
  const editData: PaymentSchedulePayLoadObject = {};
  editData.driverId = data.driverId;
  editData.amount = data.value;
  editData.organizationId = userStorage.organizationId;
  //Important: need convert endByDate with 0 hours value
  editData.endByDate = new Date(
    new Date(
      data.templateSchedulerSummaryDTO.rangeOfRecurrence.endByRecurrence.endByDate
    ).setUTCHours(0, 0, 0, 0)
  );
  editData.rangeDate =
    data.templateSchedulerSummaryDTO.rangeOfRecurrence.startOfRecurrence;
  editData.main =
    data.templateSchedulerSummaryDTO.currentRecurrencePeriod.toLowerCase();
  editData.weekDays = [];
  if (
    data.templateSchedulerSummaryDTO.currentRecurrencePeriod.toUpperCase() ===
    'MONTHLY'
  ) {
    editData.monthlyRecurs =
      data.templateSchedulerSummaryDTO.monthlyRecurrence.dayOfMonth;
  } else if (
    data.templateSchedulerSummaryDTO.currentRecurrencePeriod.toUpperCase() ===
    'WEEKLY'
  ) {
    editData.weeklyRecurs =
      data.templateSchedulerSummaryDTO.weeklyRecurrence.recurrenceWeekFrequency;
    editData.weekDays =
      data.templateSchedulerSummaryDTO.weeklyRecurrence.weekDaysEnum;
  }
  if (type === 'driverId') {
    editData.driverId = data.driverId;
  } else {
    editData.tractorId = data.tractorId;
  }
  return editData;
};

const getRangeOfRecurrence = (
  data: PaymentSchedulePayLoadObject
): {
  endByRecurrence: {
    endByDate: string;
  };
  startOfRecurrence: string;
} => {
  return {
    endByRecurrence: {
      endByDate: DateTimezoneUtils.toNoTimeZoneDateString(
        data.endByDate,
        undefined,
        false
      ),
    },
    startOfRecurrence: DateTimezoneUtils.toNoTimeZoneDateString(
      data.rangeDate,
      undefined,
      true
    ),
  };
};
export const createPayloadScheduledPayment = (
  data: PaymentSchedulePayLoadObject,
  type: string,
  id: string,
  typeId: string
) => {
  const userStorage = StorageManager.getUser();
  const payload: GetRecurrenceDTO = {};
  (payload.value = data.amount),
    (payload.expenseCategoryId = data.expenseType.id),
    (payload.expenseCategoryKey = data.expenseType.itemCode),
    (payload.description = data?.description),
    (payload.organizationId = userStorage.organizationId),
    (payload.templateSchedulerSummaryDTO = {
      axeleEntityObj: {
        recurrenceEntityType: 'DRIVER_PAYMENT',
        templateTypeId: null,
      },
      currentRecurrencePeriod: data.main.toUpperCase(),
      id: null,
      organizationId: userStorage.organizationId,
      rangeOfRecurrence: getRangeOfRecurrence(data),
    });

  if (data.main.toUpperCase() === 'MONTHLY') {
    payload.templateSchedulerSummaryDTO.monthlyRecurrence = {
      dayOfMonth: data.monthlyRecurs,
    };
  } else if (data.main.toUpperCase() === 'WEEKLY') {
    const weeks = mapWeekDays(data.weekDays);
    payload.templateSchedulerSummaryDTO.weeklyRecurrence = {
      recurrenceWeekFrequency: data.weeklyRecurs,
      weekDaysEnum: weeks,
    };
  }
  if (typeId === 'driverId') {
    payload.driverId = id;
    payload.templateSchedulerSummaryDTO.axeleEntityObj.recurrenceEntityType =
      type === 'Payment' ? 'DRIVER_PAYMENT' : 'DRIVER_DEDUCTION';
  } else {
    payload.tractorId = id;
    payload.templateSchedulerSummaryDTO.axeleEntityObj.recurrenceEntityType =
      type === 'Payment' ? 'TRACTOR_PAYMENT' : 'TRACTOR_DEDUCTION';
  }
  return payload;
};

export const updatedPayloadScheduledPayment = (
  data: GetRecurrenceDTO,
  type: string,
  intialData: any,
  typeId: string
) => {
  const userStorage = StorageManager.getUser();
  const payload: GetRecurrenceDTO = {};
  (payload.driverId = intialData.driverId),
    (payload.value = data.amount),
    (payload.description = data.description);
  (payload.expenseCategoryId = data.expenseType.id),
    (payload.expenseCategoryKey = data.expenseType.itemCode),
    (payload.organizationId = userStorage.organizationId),
    (payload.id = intialData.id),
    (payload.templateSchedulerSummaryDTO = {
      axeleEntityObj: {
        recurrenceEntityType:
          intialData.templateSchedulerSummaryDTO.axeleEntityObj
            .recurrenceEntityType,
        templateTypeId:
          intialData.templateSchedulerSummaryDTO.axeleEntityObj.templateTypeId,
      },
      currentRecurrencePeriod: data.main.toUpperCase(),
      id: intialData.templateSchedulerSummaryDTO.id,
      organizationId: userStorage.organizationId,
      rangeOfRecurrence: getRangeOfRecurrence(data),
    });

  if (data.main.toUpperCase() === 'MONTHLY') {
    payload.templateSchedulerSummaryDTO.monthlyRecurrence = {
      dayOfMonth: data.monthlyRecurs,
    };
  } else if (data.main.toUpperCase() === 'WEEKLY') {
    const weeks = mapWeekDays(data.weekDays);
    payload.templateSchedulerSummaryDTO.weeklyRecurrence = {
      recurrenceWeekFrequency: data.weeklyRecurs,
      weekDaysEnum: weeks,
    };
  }
  if (typeId === 'driverId') {
    payload.driverId = intialData.driverId;
  } else {
    payload.tractorId = intialData.tractorId;
  }
  return payload;
};

export const mapWeekDays = (data: Array<string>) => {
  const mapWeek: any = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };
  data.sort((a, b) => {
    return mapWeek[a] - mapWeek[b];
  });
  return data;
};
