import { Button, Popover, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC } from 'react';
import LocationSelect, { TLocationSelectValue } from './index';

interface LocationSelectFilterProps {
  id?: string;
  column: any;
  defaultFilterValue: Record<string, any>;
  onChangeCb?: (value: TLocationSelectValue, name?: string) => void;
  isFormControl?: boolean;
  field: Record<string, any> | undefined;
}

const LocationSelectFilter: FC<LocationSelectFilterProps> = ({
  column,
  defaultFilterValue,
  onChangeCb,
  isFormControl = false,
  field = {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const value: TLocationSelectValue = isFormControl
    ? field.value
    : defaultFilterValue[column.name];
  const data: any = value.data;
  const name = field.name || column.name;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderLocationSelect = () => {
    return (
      <LocationSelect
        isZoneDisabled={column.isZoneDisabled}
        label={column.label}
        onChange={(value) => {
          if (isFormControl) {
            field.onChange(value, name);
            column.onChange && column.onChange(value, name);
          } else {
            onChangeCb && onChangeCb(value, name);
          }
        }}
        value={value}
        error={field.error}
        isSwapZoneToZip={column.isSwapZoneToZip}
      />
    );
  };
  if (!isFormControl) {
    return (
      <>
        <Button
          sx={{
            height: '40px',
            minWidth: '130px',
            borderColor: 'primary.main',
            borderRadius: '6px',
          }}
          aria-describedby={id}
          onClick={handleClick}
          variant="outlined"
        >
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography
              sx={(theme) => ({
                textTransform: 'none',
                ...theme.typography.buttonSmall,
              })}
            >
              {data && data.length > 0
                ? `${data[0]['title']} ${
                    data.length > 1 ? `+${data.length - 1}` : ''
                  }`
                : data?.city
                ? `${data.city}, ${data.state}`
                : column.label}
            </Typography>
          </Box>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { width: 300 },
          }}
        >
          <>
            <Box
              sx={{
                borderRadius: 5,
                textAlign: 'center',
                padding: 1,
              }}
            >
              {renderLocationSelect()}
            </Box>
          </>
        </Popover>
      </>
    );
  }
  return renderLocationSelect();
};

export default LocationSelectFilter;
