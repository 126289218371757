import { Dialog, DialogTitle } from '@components/ui-kit/feedback/dialog';
import { DialogContent, Stack, Theme, Typography } from '@mui/material';
import { Button } from '../../../../../components_v2/ui-kit/button';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

const CloseLoadFormDialog: React.FC<IProps> = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog
      open={open}
      sx={(theme: Theme) => ({
        '.MuiPaper-root': {
          border: `1px solid ${theme.palette?.primary?.main}`,
          borderRadius: '8px',
        },
      })}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight={700} color={'textPrimary'}>
          Discard Changes?
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" color={'textSecondary'}>
          You will lose all the changes you made.
        </Typography>

        <Stack justifyContent={'flex-end'} mt={2} direction={'row'} gap={'8px'}>
          <Button color="primary" variant="text" onClick={onClose}>
            Keep Editing
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={onSubmit}
            sx={(theme: Theme) => ({
              color: (theme.palette?.primary as any)?.contrast,
            })}
          >
            Discard
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export { CloseLoadFormDialog };
