import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomerFeatures from '../../../../../ui-kit/components/CustomerFeatures';
import AccountsSection from '../../../../../ui-kit/components/IntegrationAccountCards/AccountsSection';
import IntegrationActivationPanel from '../../../../../ui-kit/components/IntegrationActivationPanel';
import ProviderDescription from '../../../../../ui-kit/components/ProviderDescription';
import ProviderHero from '../../../../../ui-kit/components/ProviderHero';

import { accountingService } from '../../../../../api';
import { AXELE_PERMISSION_TYPE } from '../../../../../common/Permission';
import { getHasPermission } from '../../../../../common/Permission/utils/helperUtils';
import {
  CardDataDTO,
  CredentialValidDTO,
  CredentialValidRequestDTO,
  IncomingRequestPayloadDTO,
  ProviderFeaturesDTO,
} from '../../../../../models';
import { AccountingPopup } from '../AccountingPop/accountingPopup';
import Sidedrawer from './Sidedrawer';

const REDIRECT_URI = `${window.location.origin}/settings/integrations`;

export default function SelectedCustomerAccounting({
  currentCustomerData,
  providerData,
  getProviderDetails,
  allTerminals,
  terminalIdsList,
}: any) {
  const [open, setOpen] = useState(false);
  const [features, setFeatures] = useState<string[]>([]);
  const [isDeletePopupOpen, openDeletePopup] = useState<boolean>(false);
  const [selectedCardForDeletion, setCardForDeletion] = useState({});
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [activationErrMessage, setErrMessage] = useState('');
  const [formErr, setFormErr] = useState({});
  const [cardId, setCardId] = useState<string>('');
  const [entityType, setEntityType] = useState<string>('');
  const [terminalIds, setTerminalIds] = useState([]);

  useEffect(() => {
    getCustomerFeatures();
  }, [currentCustomerData?.providerId]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    setErrMessage('');
    setFormErr({});
    setDataToUpdate({});
  };

  const validateFormCreds = async (payload: IncomingRequestPayloadDTO) => {
    if (payload && payload.data) {
      const requestBody = {
        displayName: payload.data[0].value,
        terminalIds: payload.terminalIds,
        organizationId: payload?.organizationId,
        redirectURI: REDIRECT_URI,
      };
      const request = new CredentialValidRequestDTO();
      request.displayName = payload.data[0].value;
      request.terminalIds = payload.terminalIds;
      const res = (await accountingService.validateQBCredentials(
        request
      )) as CredentialValidDTO;
      if (res?.isDisplayNameUnique && !res?.isTerminalIdsInUse) {
        sessionStorage.setItem('QBOnline', JSON.stringify(requestBody));
        authenticateOAuth();
      } else {
        setFormErr(res);
      }
    }
  };

  const updateAccountCard = async (payload: IncomingRequestPayloadDTO) => {
    if (payload && payload.data) {
      const requestBody = {
        displayName: payload.data[0].value,
        terminalIds: payload.terminalIds,
        organizationId: payload.organizationId,
        id: payload.id,
      };
      await accountingService.updateIntegration(requestBody).then((res) => {
        if (res) {
          if (res?.error?.response?.data) {
            setFormErr(res?.error?.response?.data);
          } else {
            setOpen(false);
            if (getProviderDetails) {
              getProviderDetails(terminalIdsList);
            }
          }
        }
      });
    }
  };

  const getCustomerFeatures = (): void => {
    const currentCustomerFeatures = providerData?.providerFeatures?.filter(
      (item: ProviderFeaturesDTO) =>
        item.providerId === currentCustomerData?.providerId
    );
    currentCustomerFeatures &&
      currentCustomerFeatures.length > 0 &&
      setFeatures(currentCustomerFeatures[0]?.supportedFeatures as string[]);
  };

  const openConfirmationPopup = (cardData: CardDataDTO) => {
    openDeletePopup(true);
    setCardForDeletion(cardData);
  };

  const closeConfirmationPopup = () => {
    openDeletePopup(false);
  };

  const deactivateAccount = async (cardData: CardDataDTO) => {
    await accountingService
      .deleteIntegration(cardData.id, cardData.organisationId)
      .then((res) => {
        if (res) {
          closeConfirmationPopup();
          if (getProviderDetails) {
            getProviderDetails(terminalIdsList);
          }
        }
      });
  };

  const editAccount = async (cardData: CardDataDTO) => {
    setOpen(true);
    setDataToUpdate(cardData);
  };

  const reauthenticate = (cardData: any) => {
    const requestBody = {
      organizationId: cardData.organisationId,
      id: cardData.id,
      operation: 'updateQBAuth',
      redirectURI: REDIRECT_URI,
    };
    sessionStorage.setItem('QBOnline', JSON.stringify(requestBody));
    authenticateOAuth();
  };

  const authenticateOAuth = () => {
    localStorage.setItem('fromIntuit', 'true');

    const url =
      'https://appcenter.intuit.com/app/connect/oauth2?' +
      `client_id=${window.env.QB_CLIENT_ID}` +
      '&response_type=code' +
      `&scope=${process.env.QB_INTEGRATION_SCOPE}` +
      `&redirect_uri=${REDIRECT_URI}` +
      `&state=${window.env.QB_INTEGRATION_STATE}`;
    window.location = url as unknown as Location;
  };

  const getCardParams = (entityType: string, data: CardDataDTO) => {
    const cardId = data.id;
    setTerminalIds(data.terminalIds);
    setCardId(cardId as string);
    setEntityType(entityType);
  };

  const handleGetProviderDetails = () => {
    getProviderDetails(terminalIdsList);
  };

  const axelePermissions = {
    showAddBtn: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_ACCOUNTING_ADD],
    }),
    showEdit: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_ACCOUNTING_EDIT],
    }),
    showRemove: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_ACCOUNTING_REMOVE],
    }),
  };
  console.log('factoreSelectedProviderDetails***', currentCustomerData);

  return (
    <Box className="selected-customer-block ">
      <ProviderHero currentCustomerData={currentCustomerData} />
      <Stack sx={{ p: '50px 20px 0 30px' }}>
        <Grid container>
          <ProviderDescription currentCustomerData={currentCustomerData} />
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <IntegrationActivationPanel
              toggleDrawer={toggleDrawer(true)}
              status={currentCustomerData?.status as string}
              axelePermissions={axelePermissions}
            />
          </Grid>
        </Grid>
        <Stack sx={{ pt: '48px' }}>
          {/* <h1>AccountsSection</h1> */}

          <AccountsSection
            openDrawer={toggleDrawer(true)}
            currentCustomerData={currentCustomerData}
            providerData={providerData}
            allTerminals={allTerminals}
            getProviderDetails={handleGetProviderDetails}
            isDeletePopupOpen={isDeletePopupOpen}
            closeConfirmationPopup={closeConfirmationPopup}
            deactivateAccount={deactivateAccount}
            openConfirmationPopup={openConfirmationPopup}
            editAccount={editAccount}
            reauthenticate={reauthenticate}
            selectedCardForDeletion={selectedCardForDeletion}
            integrationType={'ACCOUNTING'}
            axelePermissions={axelePermissions}
            componentToRenderInsideModal={
              <AccountingPopup
                AccountCardId={cardId}
                entityType={entityType}
                terminalIds={terminalIds}
              />
            }
            clickedParamToManage={getCardParams}
          />
        </Stack>
        <Stack sx={{ pt: '48px' }}>
          <CustomerFeatures features={features} />
        </Stack>
        <Sidedrawer
          currentCustomerData={currentCustomerData}
          open={open}
          toggleDrawer={toggleDrawer}
          providerData={providerData}
          validateFormCreds={validateFormCreds}
          allTerminals={allTerminals}
          dataToUpdate={dataToUpdate}
          activationErrMessage={activationErrMessage}
          updateAccountCard={updateAccountCard}
          formErr={formErr}
          authenticateOAuth={authenticateOAuth}
          integrationType={'ACCOUNTING'}
        />
      </Stack>
    </Box>
  );
}
