import { InvoicePaymentDetails } from '../../../../../../models/DTOs/Payment/DTOs';

const invoiceColumnsInfo = {
  seqNumber: true,
  paymentReferenceId: true,
  invoiceDueDate: true,
  total: true,
  amountDue: true,
  amount: true,
  advance: true,
};

export const invoicePaymentView = {
  id: 'invoicePayment',
  shared: true,
  parentId: null,
  name: 'invoicePayment',
  entity: 'invoice',
  icon: <></>,
  active: true,
  default: true,
  columnFilters: {
    ...invoiceColumnsInfo,
    onHoldState: true,
  },
  metaData: [],
};

export const emptyInvoicePaymentRow: InvoicePaymentDetails & {
  isNew: boolean;
} = {
  isNew: true,
  invoiceId: `newInvoice`,
  invoiceSeqNumber: '',
  invoiceReferenceId: '',
  total: null,
  amountDue: null,
  invoiceDueDate: null,
  amount: null,
  advance: null,
};
