import { Box } from '@mui/material';
import {
  cHRobbinsonIcon,
  cHRobbinsonLogo,
  datLogo,
  loadBoardIcon,
  loadBoardLogo,
  loadSmartIcon,
  loadSmartLogo,
  truckStopIcon,
  truckStopLogo,
} from '../../../../ui-kit/components/Assets';

export const logoMap: any = {
  1: <>{datLogo}(Legacy)</>,
  2: truckStopLogo,
  3: loadSmartLogo,
  4: loadBoardLogo,
  6: cHRobbinsonLogo,
  7: datLogo,
};

export const loadboardsMap: any = {
  DAT: (
    <Box mr={2} display={'flex'}>
      {datLogo}(Legacy)
    </Box>
  ),
  ITS: truckStopIcon,
  3: loadSmartIcon,
  ONETWOTHREE: loadBoardIcon,
  CHROBINSON: cHRobbinsonIcon,
  DAT_V3: datLogo,
};

export const provideNameLogo: any = {
  DAT: <>{datLogo}(Legacy)</>,
  ITS: truckStopLogo,
  3: loadSmartLogo,
  ONETWOTHREE: loadBoardLogo,
  CHROBINSON: cHRobbinsonLogo,
  DAT_V3: datLogo,
};
