import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';
import {
  EDITenderDTO,
  LoadMappingResponse,
} from '../../../models/DTOs/EDI/Requests';
import {
  ExpandableTimelineV3PanelView,
  TripV3,
  TripV3Props,
  TripViewV3Mode,
} from '../../../ui-kit/components/TripPlan';
import { convertTripDetailToStopV3PropList } from '../../loadboards/components/DetailPanel/utils/trip.utils';

export interface EdiTenderTripPlanProps {
  tenderDTO: EDITenderDTO;
  tripPlanData: LoadMappingResponse | null;
}

export const EdiTenderTripPlan: FC<EdiTenderTripPlanProps> = observer(
  ({ tenderDTO, tripPlanData }) => {
    const tripProps: TripV3Props = useMemo((): TripV3Props => {
      return {
        panelView: ExpandableTimelineV3PanelView.Loadsearch,
        isMultipleExpanded: true,

        viewMode: TripViewV3Mode.DefaultView,
        enablePreAndNext: true,
        data: {
          id: '1',
          expanded: true,
          isCompleted: false,
          headerData: {
            title: 'Tender details',
            renderHeaderContents: (): JSX.Element => <></>,
          },

          stopList: tripPlanData?.stops
            ? convertTripDetailToStopV3PropList(
                tripPlanData.stops.map(({ loadStop }) => loadStop)
              )
            : [],
        },
      };
    }, [tripPlanData]);

    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          mt: 1,
        }}
      >
        <TripV3 {...tripProps} />
      </Box>
    );
  }
);
