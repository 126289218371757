import { Theme } from '@mui/material';

export const ReeferModeButtonStyles = (theme: Theme) => ({
  border: 'none',
  height: '100%',
  borderRadius: '20px!important',
  color: theme?.palette?.grey?.[500],
  ':hover': {
    border: 'none',
  },
});

export const ReeferModeButtonSelectedStyles = (theme: Theme) => ({
  borderRadius: '20px!important',
  height: '100%',
});

export const ReeferModeButtonGroup = (theme: Theme) => ({
  borderRadius: '20px',
  border: `1px solid ${theme?.palette?.grey?.[500]}`,
});

export const RateConFileNameViewerContainer = (theme: Theme) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  display: 'flex',
  p: 2,
  alignItems: 'center',
});

export const captionTextSpanStyle = { fontWeight: 'normal', fontSize: '12px' };

export const VerticalDividerStyle = (theme: Theme) => ({
  height: '14px',
  background: theme?.palette?.grey?.[500],
  width: '2px',
});

export const NotesSummaryStyle = (theme: Theme) => ({
  backgroundColor: 'rgba(255, 255, 136, 0.60);',
  display: 'inline-block',
  mt: 1,
  py: '5px',
  px: 2,
  wordBreak: 'break-word',
  color: theme.palette.grey?.['a300'],
});

export const ApptNoConfirmationInconStyles = {
  width: '14px',
  height: '14px',
  color: '#FFF',
  position: 'absolute',
  top: '4px',
  left: '4px',
};

export const StopSummaryContainerStyles = {
  position: 'absolute',
  left: '0',
  width: '20px',
  height: '20px',
  borderRadius: '20px',
  border: 'solid 1px #999',
  color: '#999',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '13px',
  lineHeight: '20px',
};

export const TextMedium = (theme: Theme) => ({
  color: theme.palette.grey['300'],
});

export const ErrorTextFieldStyles = {
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: `red !important`,
  },
};

export const ActionButtonStyles = (theme: Theme) => ({
  fontWeight: 'bold',
  color: (theme?.palette as any)?.leftMenuCollapsed.axeleIcon,
});

export const ActionButtonWithoutBoldStyles = (theme: Theme) => ({
  color: (theme?.palette as any)?.leftMenuCollapsed.axeleIcon,
});

export const AdditionalSummaryContainerStyles = (theme: Theme) => ({
  background: theme?.palette?.divider,
  borderRadius: '8px',
  padding: '4px 16px',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  wordBreak: 'break-word',
});

export const LoadPriorityDropdown = {
  flexShrink: 0,
  boxShadow: 'none',
  '.MuiSelect-select': { p: 0 },
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
};

export const ZipBasedLineItemInput = {
  alignItems: 'center',
  display: 'flex',
  marginRight: '3px',
};

export const ContractBaseItemTextStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const timezoneContainer = {
  position: 'relative',
};

export const timezoneTextStyle = {
  position: 'absolute',
  top: '40px',
  right: 0,
};
