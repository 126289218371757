import {
  CreateDriverPaymentTerm,
  DriverPaymentTermsGrouped,
  ExpenseCategory,
  PaymentPerLoad,
  PaymentType,
} from '../../models';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IPaymentTermsService extends Service {
  abstract getExpenseCategories(): Promise<
    ExpenseCategory[] | null | ServiceError
  >;

  abstract getPaymentTypes(): Promise<PaymentType[] | null | ServiceError>;

  abstract getDriverPaymentTerms(
    driverId: number
  ): Promise<DriverPaymentTermsGrouped | null | ServiceError>;

  abstract getDriverLoadRatePayment(
    driverId: number
  ): Promise<PaymentPerLoad[] | null | ServiceError>;

  abstract createDriverPaymentTerms(
    paymentTerm: CreateDriverPaymentTerm
  ): Promise<PaymentPerLoad | null | ServiceError>;

  abstract createTractorPaymentTerms(
    paymentTerm: CreateDriverPaymentTerm
  ): Promise<PaymentPerLoad | null | ServiceError>;

  abstract createWebUserPaymentTerms(
    paymentTerm: CreateDriverPaymentTerm
  ): Promise<PaymentPerLoad | null | ServiceError>;

  abstract deleteLoadRateByIDs(
    loadRateIDs: number[]
  ): Promise<null | ServiceError>;

  abstract deleteTractorLoadRateByIDs(
    loadRateIDs: number[]
  ): Promise<null | ServiceError>;

  abstract deleteWebUserLoadRateByIDs(
    loadRateIDs: number[]
  ): Promise<null | ServiceError>;

  abstract bulkUpdateForDriverPayment(
    driverPaymentTermsGrouped: DriverPaymentTermsGrouped
  ): Promise<DriverPaymentTermsGrouped | null | ServiceError>;

  abstract bulkUpdateForWebuserPayment(
    driverPaymentTermsGrouped: DriverPaymentTermsGrouped
  ): Promise<DriverPaymentTermsGrouped | null | ServiceError>;

  abstract bulkUpdateForTractorPayment(
    driverPaymentTermsGrouped: DriverPaymentTermsGrouped
  ): Promise<DriverPaymentTermsGrouped | null | ServiceError>;

  abstract existsPayTypeItemById(
    payTypeId: string
  ): Promise<boolean | ServiceError>;

  abstract getTractorPaymentTerms(
    tractorId: number
  ): Promise<DriverPaymentTermsGrouped | null | ServiceError>;

  abstract getWebUserPaymentTerms(
    dispatcherId: number
  ): Promise<DriverPaymentTermsGrouped | null | ServiceError>;
}
