import { Box } from '@mui/material';
import 'animate.css';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useDetailsPanelStore } from '../../store/DetailsPanel';
import { ESecondaryDetailsPanelType } from '../../views/dispatch2/constants/types';
import { useThemeResponsive } from '../hooks/useThemeResponsive';
import { Panel, PanelCommonProps, PanelFSProps, PanelProps } from './Panel';
import { customizeDetailsPanelStyles, styles } from './styles';

export type DirtyMetaData = Record<string, boolean>;

export interface DetailsPanelProps extends PanelCommonProps {
  panelType?: ESecondaryDetailsPanelType;
  isGlobal?: boolean;
  isZindex?: number;
  fullscreen?: {
    panelPropList: PanelFSProps[];
  };
  showDetailsPanelLoader?: boolean;
  height?: string;
  alwaysShowActionButtons?: boolean;
  hideExpandIcon?: boolean; //optional to hide the expand icon
}

export const DetailsPanel: React.FC<DetailsPanelProps> = observer(
  (props: DetailsPanelProps) => {
    const {
      isZindex,
      panelType,
      fullscreen,
      isGlobal: defaultIsGlobal = false,
      isEmbedded = false,
      onDelete,
      permissions,
      mainContentCustomStyles,
      showDetailsPanelLoader = true,
      height,
      hideExpandIcon = false,
      onReset,
      alwaysShowActionButtons = false,
      ...defaultModeProps
    } = props;
    const { isLoadingPanel } = useDetailsPanelStore;
    const [isGlobal] = useState(defaultIsGlobal);
    const { theme, isMobile } = useThemeResponsive();
    const { autoExpanded, onClose, onFullscreen, actionsRenderer } =
      defaultModeProps;
    const [isFullscreen, setIsFullscreen] = useState<boolean>(
      !!defaultModeProps?.autoExpanded
    );
    const [style, setStyle] = useState(
      autoExpanded ? styles.expanded : styles.default
    );

    const handleExpand = () => {
      if (style.shake) {
        setStyle(styles.default);
      } else {
        setStyle(styles.expanded);
      }
      setIsFullscreen((preState) => {
        onFullscreen?.(!preState);
        return !preState;
      });
    };

    useEffect(() => {
      setIsFullscreen(!!autoExpanded);
      setStyle(autoExpanded ? styles.expanded : styles.default);
    }, [autoExpanded]);

    const onCloseHandler = (): void => {
      onClose?.();
    };

    const sharePanelProps: Partial<PanelProps> = {
      isFullscreen,
      setIsFullscreen,
      handleExpand,
      onClose: onCloseHandler,
      onDelete,
      style,
    };

    const customPanelStyles = customizeDetailsPanelStyles(theme);
    const isLoading = isLoadingPanel(panelType);

    return (
      <Box
        className={'DetailsPanel-root'}
        sx={{
          height: height || '100%',
          ...style.shake,
          ...(!isEmbedded && customPanelStyles.wrapper),
          ...(isGlobal ? styles.global.wrapper : {}),
          ...(isZindex ? { zIndex: isZindex } : ''),
        }}
      >
        {!isMobile && isFullscreen && fullscreen ? (
          <div
            className={'DetailsPanel-fullscreen'}
            style={{
              ...style?.fullscreen,
            }}
          >
            {fullscreen?.panelPropList?.map(
              (fsModeProps: PanelFSProps, idx: number) => {
                const isLastIndex =
                  idx === fullscreen?.panelPropList?.length - 1;
                return (
                  <Panel
                    key={idx}
                    isFullscreen={true}
                    {...sharePanelProps}
                    alwaysShowActionButtons={alwaysShowActionButtons}
                    onClose={onCloseHandler}
                    data={fsModeProps.data}
                    panelTitle={fsModeProps.panelTitle}
                    renderCustomTitle={fsModeProps.renderCustomTitle}
                    validationSchema={fsModeProps.validationSchema}
                    onUpdate={fsModeProps.onUpdate}
                    contentRenderer={fsModeProps.contentRenderer}
                    panelWrapperCustomStyles={
                      fsModeProps.panelWrapperCustomStyles
                    }
                    tabStripRenderer={fsModeProps.tabStripRenderer}
                    showExpandIcon={isLastIndex && !isMobile && !hideExpandIcon}
                    showCloseIcon={isLastIndex}
                    permissions={permissions}
                    actionsRenderer={actionsRenderer}
                    isLastIndex={isLastIndex}
                    isFullScreen={true}
                    height={height}
                    footerRenderer={fsModeProps.footerRenderer}
                    contentCustomStyles={fsModeProps.contentCustomStyles}
                    onReset={onReset}
                  />
                );
              }
            )}
          </div>
        ) : (
          <Box
            sx={{
              ...(isFullscreen && {
                ...style?.fullscreen,
                ...defaultModeProps.fullScreenCustomStyles,
              }),
              height: '100%',
            }}
            className={'wrapperDetaulMode'}
          >
            <Panel
              height={height}
              {...defaultModeProps}
              {...sharePanelProps}
              panelWrapperCustomStyles={{
                ...defaultModeProps.panelWrapperCustomStyles,
                ...(isGlobal && !isFullscreen
                  ? styles.global.panelWrapperCustomStyles
                  : {}),
                ...(!isEmbedded && customPanelStyles.wrapper),
              }}
              permissions={permissions}
              mainContentCustomStyles={mainContentCustomStyles}
              showExpandIcon={!isMobile && !hideExpandIcon}
              onReset={onReset}
              alwaysShowActionButtons={alwaysShowActionButtons}
            />
          </Box>
        )}
      </Box>
    );
  }
);
