import Popover, { PopoverProps } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { ReactNode } from 'react';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import { t } from 'i18next';

export interface DetailsPanelClosePopoverProps {
  open: boolean;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  onPopoverClose?: () => void;
  onExit?: () => void;
  onSubmit?: (data: any) => void;
  title?: ReactNode;
  PopoverProps?: Partial<PopoverProps>;
  exitButtonTitle?: string;
  submitButtonTitle?: string;
}

export const DetailsPanelClosePopover: React.FC<
  DetailsPanelClosePopoverProps
> = ({
  open,
  anchorEl,
  onPopoverClose,
  exitButtonTitle = t('exit'),
  onExit,
  submitButtonTitle = t('no'),
  onSubmit,
  title,
  PopoverProps = {},
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onPopoverClose}
      {...PopoverProps}
    >
      <Box
        sx={{
          maxWidth: '370px',
          minHeight: '134px',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h7"
          sx={{
            color: 'text.primary',
          }}
        >
          {title ? title : 'Do you want to leave this page ?'}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonImproved
            id={exitButtonTitle}
            label={exitButtonTitle}
            size="small"
            variant="outlined"
            onClick={onExit}
          />
          <ButtonImproved
            id={submitButtonTitle}
            label={submitButtonTitle}
            size="small"
            variant="contained"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </Popover>
  );
};
