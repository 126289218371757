import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Button, IconButton, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StorageManager from '../../../../StorageManager/StorageManager';
import { loadsearchService } from '../../../../api';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { SaveBidOnLoadRequest } from '../../../../models/DTOs/Loadsearch/Request';
import '../../components/ProfitCalculator/inputStyles.css';
import { IProfitCalculatorProps } from '../../loadTypes';
import { FinanceLoadEmail } from './bidEMail';

const BidTooltip = ({
  selectedRowData,
  bidSent,
  pendingLoadBookStatus,
}: IProfitCalculatorProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [pendingLoads, setPendingLoads] = useState<Array<string>>([]);
  const [offerScoreValue, setOfferScoreValue] = useState(
    parseInt(selectedRowData?.Offer?.estimatedProfit) +
      parseInt(selectedRowData?.Offer?.variableCost) +
      parseInt(selectedRowData?.Offer?.fixedCost)
  );
  const [editableValue, setEditableValue] = useState<string>('offerPrice');
  const [nonEditableValue, setNonEditableValue] = useState<string>('mileage');
  const [calculatorType, setCalculatorType] = useState<string>('profit');
  const [netProfitValue, setProfit] = useState<number>(0);
  const [offerPrice, setOfferPrice] = useState<number>(0);
  const [variableCost, setVariableCost] = useState<number>(0);
  const [fixedCost, setFixedCost] = useState<number>(0);
  const [mileage, setMileage] = useState<number>(0);
  const [totalMiles, setTotalMiles] = useState<number>(0);
  const [mileScoreValue, setMileScoreValue] = useState<number>(
    parseFloat(selectedRowData?.Mileage?.subvalue)
  );
  const [recipentEmail, setRecipentEmail] = useState<string>(
    selectedRowData?.Broker?.email
  );
  const [bidDescription, setBidDescription] = useState<string>('');
  const [displayError, setDisplayError] = useState<{
    recipentEmail: string;
    bidDescription: string;
  }>({
    recipentEmail: '',
    bidDescription: '',
  });
  const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(true);

  const brokerDefaultEmail = selectedRowData?.Broker?.email;

  const pessimisticRate = Number(
    Number(selectedRowData?.MarketRate?.pessimisticRate).toFixed(2)
  );
  const optimisticRate = Number(
    Number(selectedRowData?.MarketRate?.optimisticRate).toFixed(2)
  );
  const avgRate = Number(
    (Number(pessimisticRate + optimisticRate) / 2).toFixed(2)
  );

  const theme: any = useTheme();

  useEffect(() => {
    const profit = Number(selectedRowData?.Offer?.estimatedProfit);
    const varCost = Number(selectedRowData?.Offer?.variableCost);
    const fixCost = Number(selectedRowData?.Offer?.fixedCost);
    const miles = Number(selectedRowData?.Mileage?.subvalue);
    const totalMile = Number(
      Number(selectedRowData?.LoadedMiles?.actualTotalMiles).toFixed(2)
    );
    setOfferPrice(Number((profit + varCost + fixCost).toFixed(2)));
    setProfit(Number(profit.toFixed(2)));
    setVariableCost(varCost);
    setFixedCost(fixCost);
    setMileage(miles);
    setTotalMiles(totalMile);
    setOfferScoreValue(Number((profit + varCost + fixCost).toFixed(2)));
  }, []);

  const marks = [
    {
      value: pessimisticRate,
      label: `$${pessimisticRate}`,
    },
    {
      value: avgRate,
      label: `$${avgRate} (Market Rate)`,
    },
    {
      value: optimisticRate,
      label: `$${optimisticRate}`,
    },
  ];
  const offerBreaks = [
    {
      value: Number((Number(pessimisticRate) * totalMiles).toFixed(2)),
      label: `$${Number((Number(pessimisticRate) * totalMiles).toFixed(2))}`,
    },
    {
      value: Number((Number(avgRate) * totalMiles).toFixed(2)),
      label: `$${Number(
        (Number(avgRate) * totalMiles).toFixed(2)
      )} (Market Rate)`,
    },
    {
      value: Number((Number(optimisticRate) * totalMiles).toFixed(2)),
      label: `$${Number((Number(optimisticRate) * totalMiles).toFixed(2))}`,
    },
  ];

  const swapValues = (): void => {
    if (editableValue === 'offerPrice') {
      setEditableValue('mileage');
      setNonEditableValue('offerPrice');
    } else {
      setEditableValue('offerPrice');
      setNonEditableValue('mileage');
    }
  };

  const offerPriceChangeHandler = (e: any) => {
    if (e.target.name === 'offerPrice') {
      setOfferPrice(Number(Number(e.target.value).toFixed(2)));
      setProfit(
        Number((Number(e.target.value) - fixedCost - variableCost).toFixed(2))
      );
      setOfferScoreValue(Number(Number(e.target.value).toFixed(2)));
      const m = (Number(e.target.value) / totalMiles).toFixed(2);
      setMileage(Number(m));
    } else {
      setMileage(Number(Number(e.target.value).toFixed(2)));
      setOfferPrice(Number((Number(e.target.value) * totalMiles).toFixed(2)));
      setProfit(
        Number((Number(e.target.value) * totalMiles - variableCost).toFixed(2))
      );
      setMileScoreValue(Number(Number(e.target.value).toFixed(2)));
    }
  };

  let sliderDefaultValue;
  if (calculatorType === 'offer') {
    if (editableValue === 'offerPrice') {
      sliderDefaultValue = offerScoreValue;
    } else sliderDefaultValue = mileScoreValue;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeOfferPrice = (offerPrice: number) => {
    setOfferPrice(offerPrice);
  };

  const bidLoad = async (data: any) => {
    const error: {
      recipentEmail: string;
      bidDescription: string;
    } = {
      recipentEmail: '',
      bidDescription: '',
    };

    let isError = false;
    if (!recipentEmail) {
      error['recipentEmail'] = 'Field is required';
      isError = true;
    }
    if (!bidDescription) {
      error['bidDescription'] = 'Field is required';
      isError = true;
    }
    setDisplayError(error);

    if (!isError) {
      const userDetails = StorageManager.getUser() || {};
      const payload: {
        bidPrice: number;
        dispatcherId: number;
        recipientEmailId: string;
        customMessage: string;
        savedLoad: any;
      } = {
        bidPrice: offerPrice,
        dispatcherId: userDetails.id,
        recipientEmailId: recipentEmail,
        customMessage: bidDescription,
        savedLoad: selectedRowData?.actions?.savedLoadsData,
      };
      try {
        const request = new SaveBidOnLoadRequest(payload);
        const response = await loadsearchService.saveBidOnLoad(request);
        bidSent(response);
        setPendingLoads((prev) => [...prev, selectedRowData.id]);
        setAnchorEl(null);
      } catch (err) {
        throw err;
      }
    }
  };

  const handleBid = async (data: any): void => {
    const userDetails = StorageManager.getUser() || {};
    const payload: {
      bidPrice: number;
      dispatcherId: number;
      recipientEmailId: string[];
      ccEmailId: string[];
      mailBody: string;
      bidSavedLoad: any;
      mailSubject: string;
    } = {
      bidPrice: offerPrice,
      dispatcherId: userDetails.id,
      recipientEmailId: data && data.to && data.to.length > 0 ? data?.to : [],
      ccEmailId: data && data.cc && data.cc.length > 0 ? data?.cc : [],
      mailBody: data?.message,
      mailSubject: data?.subject,
      bidSavedLoad: {
        organizationId: userDetails.organizationId,
        dispatcherId: userDetails.id,
        savedLoad: selectedRowData?.actions?.savedLoadsData,
      },
    };
    try {
      const request = new SaveBidOnLoadRequest(payload);
      const response = await loadsearchService.saveBidOnLoad(request);
      bidSent(response);
      setPendingLoads((prev) => [...prev, selectedRowData.id]);
      setAnchorEl(null);
    } catch (err) {
      throw err;
    }
  };

  const bidLoadRender = (selectedRowData: any) => {
    if (
      pendingLoads.includes(selectedRowData.id) ||
      selectedRowData?.actions?.savedLoadsData?.bidSent
    ) {
      if (
        selectedRowData?.actions?.savedLoadsData?.bidStatusAction === true ||
        (pendingLoadBookStatus &&
          pendingLoadBookStatus[selectedRowData.keyId] &&
          pendingLoadBookStatus[selectedRowData.keyId]['isBooked'])
      ) {
        if (
          pendingLoadBookStatus &&
          pendingLoadBookStatus[selectedRowData.keyId] &&
          pendingLoadBookStatus[selectedRowData.keyId]['isDropped']
        ) {
          return (
            <Button
              onClick={handleClick}
              sx={{
                height: 30,
                width: '40px',
                minWidth: '40px',
                borderColor: 'warning.main',
              }}
              variant={'outlined'}
            >
              <IconButton>
                <AccessTimeIcon
                  fontSize={'small'}
                  sx={{ color: 'warning.main' }}
                />
              </IconButton>
            </Button>
          );
        } else {
          return (
            <Button
              onClick={handleClick}
              sx={{
                height: 30,
                width: '40px',
                minWidth: '40px',
                borderColor: 'success.main',
              }}
              variant={'outlined'}
            >
              <IconButton>
                <CheckOutlinedIcon
                  fontSize={'small'}
                  sx={{ color: 'success.main' }}
                />
              </IconButton>
            </Button>
          );
        }
      } else {
        return (
          <Button
            onClick={handleClick}
            sx={{
              height: 30,
              width: '40px',
              minWidth: '40px',
              borderColor: 'warning.main',
            }}
            variant={'outlined'}
          >
            <IconButton>
              <AccessTimeIcon
                fontSize={'small'}
                sx={{ color: 'warning.main' }}
              />
            </IconButton>
          </Button>
        );
      }
    } else {
      return (
        <Button
          onClick={handleClick}
          sx={{ height: 30, width: '40px', minWidth: '40px' }}
          variant={'outlined'}
        >
          <IconButton>
            <EmailOutlinedIcon fontSize={'small'} color="primary" />
          </IconButton>
        </Button>
      );
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
      <>
        {bidLoadRender(selectedRowData)}
        <FinanceLoadEmail
          open={open}
          onAction={handleBid}
          onClose={handleClose}
          handleChangeOfferPrice={handleChangeOfferPrice}
          invoice={''}
          seqNumber={''}
          customerEmail={''}
          emailName={''}
          selectedRowData={selectedRowData}
        />
      </>
    </Permission>
  );
};

export default BidTooltip;
