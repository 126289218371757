import React from 'react';
import ThreeDotMenuIcon from '../../../ThreeDotMenuIcon';

export interface IGridThreeDotMenuOption {
  id?: number;
  label: string;
  icon: any;
  disabled?: boolean;
  onClickHandler?: (
    data: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

export interface GridThreeDotMenuProps {
  accountData?: any;
  menuOptions?: IGridThreeDotMenuOption[];
}

export const GridThreeDotMenu = ({
  accountData,
  menuOptions = [],
}: GridThreeDotMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    menuOption: IGridThreeDotMenuOption,
    entityData: any,
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const { disabled = false } = menuOption;
    if (disabled) {
      event.stopPropagation();
    } else {
      menuOption?.onClickHandler?.(entityData, event);
      setAnchorEl(null);
    }
  };

  return (
    <ThreeDotMenuIcon
      accountData={accountData}
      menuOptions={[...menuOptions]}
      open={Boolean(anchorEl)}
      handleMenuClick={handleMenuClick}
      handleMenuClose={handleMenuClose}
      anchorEl={anchorEl}
      handleMenuItemClick={handleMenuItemClick}
      styleProps={{
        color: 'primary.main',
      }}
    />
  );
};
