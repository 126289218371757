import * as yup from 'yup';
import { Address } from '../../models';
import {
  gridPageSize,
  lastLocationValidationSchema,
  nullableStringMaxValidation,
} from '../../utils';
import { defaultContactValidationSchema } from '../../validator/validationSchemas';

export const vendorListDefaultRequestData = {
  pageNumber: 1,
  pageSize: gridPageSize,
  sort: '+name',
};

const addressDefaultData = {
  fullAddress: '',
  address: '',
  city: '',
  state: '',
  streetAddress: '',
  streetAddress2: '',
  zipcode: '',
};

export const vendorDefaultData = {
  id: '',
  seqNumber: 0,
  isActive: true,
  name: '',
  addressDTO: new Address(addressDefaultData),
  notes: '',
  preferredProhibitedEnum: '',
  contacts: [],
};

export const contactDefaultData = {
  name: '',
  description: '',
  phoneData: {},
  email: '',
  extension: '',
  isPrimary: true,
};

export const emptyListViewConfig = {
  title: 'time to get social',
  body: 'Looks like you need to add some contacts in to the system. What is a carrier without a vendor after all?',
};

export const vendorContactValidationSchema = yup.object().shape({
  name: nullableStringMaxValidation(50).required('Name is requerd.'),
  ...defaultContactValidationSchema,
});

export const vendorDetailsFormValidationSchema = yup
  .object({
    name: yup
      .string()
      .required('Name is required.')
      .max(255, 'Name must be at most 255 characters.'),
    notes: nullableStringMaxValidation(255),
    addressDTO: lastLocationValidationSchema,
    contacts: yup.array().of(vendorContactValidationSchema).compact(),
  })
  .required();
