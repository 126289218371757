import Box from '@mui/material/Box';
import ButtonImproved from '@/ui-kit/components/ButtonImproved';
import ShakeButton from '@/common/ShakeButton/ShakeButton';
import IconsComponent from '@/ui-kit/components/IconsComponent';
import { useNoteForm } from '@/common/EntityNotes/hooks';
import TextField from '@/common/Ui/TextField/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid/Grid';
import FileUpload from '@/common/Ui/FileUpload/indexUpdated';
import { FC } from 'react';
import { NoteEntityType } from '@/common/EntityNotes/utils/types';
import { docType } from '@/utils/Doc';

interface Props {
  getEntityNotes: () => void;
  entityType: NoteEntityType;
  entityId: string;
  onCancel: () => void;
  defaultValues?: {
    note: string;
    attachment: { documentId: string; fileName: string }[] | null;
    id?: string;
  };
  file?: docType;
}

export const NoteForm: FC<Props> = ({
  getEntityNotes,
  entityId,
  entityType,
  defaultValues = {
    note: '',
    attachment: null,
  },
  onCancel,
  file,
}) => {
  const { onSubmit, handleSubmit, control, currentNote, handleFileChange } =
    useNoteForm(getEntityNotes, entityId, entityType, defaultValues);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          borderColor: 'primary.main',
          borderWidth: 1,
          borderRadius: 2,
          marginTop: 2,
          padding: 2,
          backgroundColor: '#FEF7EB',
        }}
      >
        <TextField control={control} name="note" label="Note" required />
        <Typography align="right" variant="body2" mt={0.5}>
          {`${currentNote.length}/500`}
        </Typography>
        <Grid item xs={12} style={{ marginBottom: 20, marginTop: 20 }}>
          <FileUpload
            control={control}
            name="attachment"
            fileId={file ? undefined : defaultValues.attachment?.[0].documentId}
            skipLoader
            defaultFile={file}
            onChangeCB={handleFileChange}
          />
        </Grid>
        <Box mt={2} display={'flex'} justifyContent={'space-between'}>
          <ButtonImproved
            variant="outlined"
            onClick={onCancel}
            label="cancel"
          />
          <ShakeButton
            variant="contained"
            label="save"
            type="button"
            startIcon={
              <IconsComponent iconName="AddOutlined" source="MUIcons" />
            }
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      </Box>
    </form>
  );
};
