import { Box, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { paymentManagementService } from '../../../../../api';
import DetailsPanel from '../../../../../common/DetailsPanel';
import {
  GetPaymentOneRequest,
  PaymentDetailsDTO,
  UpdatePaymentDTO,
} from '../../../../../models/DTOs/Payment/DTOs';
import { useStores } from '../../../../../store/root.context';
import { ESecondaryDetailsPanelType } from '../../../../dispatch2/constants/types';
import {
  EPayment3DotMenuType,
  EPaymentPanelTab,
  ICreatePaymentForm,
} from '../../constants/types';
import {
  fromCreatePaymentFormToCreatePaymentDTO,
  fromPaymentDetailsDTOToFormData,
  getPaymentManagementStatusComponent,
} from '../../utils';
import { getPaymentFilterDTO } from '../../utils/filters.utils';
import { createPaymentFormValidationSchema } from '../Form/validations';
import PaymentDetailsForm from './PaymentDetailsForm';
import { PaymentDetailsPanelActionsSection } from './PaymentDetailsPanelActionsSection';
import { saveFilePayment, updateDocument } from './PaymentDocumentUpload';

export interface IPaymentDetailsPanelData extends Partial<PaymentDetailsDTO> {
  id?: string; //id is payment id
}

export interface PaymentDetailsPanelProps {
  data?: IPaymentDetailsPanelData | null;
  onClose: () => void;
  onUpdated?: (data: PaymentDetailsDTO) => void;
  onDeleted?: (data: PaymentDetailsDTO) => void;
  onCreated?: (data: PaymentDetailsDTO) => void;
  defaultTab?: EPaymentPanelTab;
  autoExpanded?: boolean;
  isGlobal?: boolean;
  isInvoicePanel?: boolean;
  on3DotMenuActionCallback?: (params: {
    type: EPayment3DotMenuType;
    data: PaymentDetailsDTO;
    response: any;
  }) => void | Promise<void>;
}

const PaymentDetailsPanel = observer(
  ({
    data = null,
    onClose,
    onUpdated,
    onDeleted,
    onCreated,
    defaultTab = EPaymentPanelTab.OVERVIEW,
    autoExpanded = false,
    isGlobal = false,
    isInvoicePanel = false,
    on3DotMenuActionCallback,
  }: PaymentDetailsPanelProps): JSX.Element => {
    const {
      invoicePaymentStore: {
        tableData,
        amountToApply,
        setTableData,
        setCurrentPaidInvoices,
      },
      invoiceStore: { selectedPayment },
    } = useStores();
    const [paymentDetailsDTO, setPaymentDetailsDTO] =
      useState<PaymentDetailsDTO | null>(null);

    const initFetchPaymentOne = async () => {
      if (data?.id) {
        const response = await paymentManagementService.getPaymentOne(
          new GetPaymentOneRequest({
            id: data.id,
          })
        );
        if (response instanceof PaymentDetailsDTO) {
          setPaymentDetailsDTO(response);
          setTableData(response?.invoicedetails);
          setCurrentPaidInvoices(response?.invoicedetails);
        }
      } else if (isInvoicePanel && selectedPayment && !data?.id) {
        const filterObject = getPaymentFilterDTO(selectedPayment);
        setPaymentDetailsDTO(filterObject.generalDetail);
        setTableData([filterObject.tableData]);
      }
    };

    const [selectedTab, setSelectedTab] =
      useState<EPaymentPanelTab>(defaultTab);

    const theme: any = useTheme();
    const panelWrapperCustomStyles = {
      // display: 'flex',
      width: '33.3%',
      padding: '0 5px 5px 0',
      height: '100%',
      flexDirection: 'column',
      background: theme.palette.leftMenuCollapsed.canvas,
      boxShadow: 'unset',
    };

    const tabStripRenderer = (keys: EPaymentPanelTab[]) => {
      return <></>;
    };
    const isEdit = !!data?.id;
    const onFullscreen = (isFullscreen: boolean): void => {
      //set default tab when fullscreen
      setSelectedTab(
        isFullscreen && isEdit ? EPaymentPanelTab.OVERVIEW : defaultTab
      );
    };
    const entity = 'Payment';

    const panelTitle = useMemo(() => {
      return isEdit
        ? `Payment ID: ${paymentDetailsDTO?.seqNumber || ''}`
        : ' Receive Payment';
    }, [isEdit, paymentDetailsDTO?.seqNumber]);

    const getValidationSchemaByTab = useCallback(
      (tab: EPaymentPanelTab | string): yup.AnyObjectSchema | null => {
        if (tab == EPaymentPanelTab.OVERVIEW) {
          return createPaymentFormValidationSchema;
        }
        return null;
      },
      []
    );

    const actionsRenderer = () => {
      return paymentDetailsDTO ? (
        <PaymentDetailsPanelActionsSection
          paymentDetailsDTO={paymentDetailsDTO}
          on3DotMenuActionCallback={(params: {
            type: EPayment3DotMenuType;
            data: PaymentDetailsDTO;
            response: any;
          }) => {
            if (params.type === EPayment3DotMenuType.DELETE) {
              onClose();
            }
            on3DotMenuActionCallback?.(params);
          }}
        />
      ) : (
        <></>
      );
    };

    const onUpdateHandler = async (tab: EPaymentPanelTab, formData: any) => {
      if (!paymentDetailsDTO) return;
      if (tab == EPaymentPanelTab.OVERVIEW) {
        const payload = fromCreatePaymentFormToCreatePaymentDTO({
          formData,
          invoicePayments: tableData,
        });

        if (payload.amountReceived - amountToApply < 0) {
          return;
        }

        const updatedPayment = await paymentManagementService.updatePayment(
          payload as UpdatePaymentDTO
        );
        if (updatedPayment instanceof PaymentDetailsDTO) {
          const uploadFile = await updateDocument(
            paymentDetailsDTO,
            formData.paymentDocument,
            formData.assignedDocuments
          );
          if (uploadFile) {
            initFetchPaymentOne();
            onUpdated?.(paymentDetailsDTO);
          }
        }
      }
    };

    const onCreateHandler = async (formData: ICreatePaymentForm) => {
      const payload = fromCreatePaymentFormToCreatePaymentDTO({
        formData,
        invoicePayments: tableData,
      });
      if (payload.amountReceived - amountToApply < 0) {
        return;
      }

      const createdPayment = await paymentManagementService.createPayment(
        payload
      );

      if (createdPayment instanceof PaymentDetailsDTO) {
        const uploadFile = await saveFilePayment(
          createdPayment,
          formData.paymentDocument
        );
        if (uploadFile || !uploadFile) {
          onCreated?.(createdPayment);
        }
      }
    };

    const panelData = useMemo(() => {
      return fromPaymentDetailsDTOToFormData(
        isEdit || isInvoicePanel ? paymentDetailsDTO : null
      );
    }, [isEdit, paymentDetailsDTO]);
    //Side effect begin

    useEffect(() => {
      initFetchPaymentOne();
    }, [data?.id]);
    //Side effect end

    const onReset = () => {
      setTableData(paymentDetailsDTO?.invoicedetails || []);
    };
    const renderCustomTitle =
      isEdit && paymentDetailsDTO
        ? () => (
            <Box sx={{ pl: '5px' }}>
              {getPaymentManagementStatusComponent({
                remainingCredit: paymentDetailsDTO.remainingCredit,
              })}
            </Box>
          )
        : undefined;
    return (
      <>
        <DetailsPanel
          panelType={ESecondaryDetailsPanelType.PAYMENT}
          data={panelData}
          showTitle={true}
          actionButtonLabel={isEdit ? 'Save changes' : `Create`}
          entity={entity}
          panelTitle={panelTitle}
          renderCustomTitle={renderCustomTitle}
          onClose={onClose}
          onReset={onReset}
          onUpdate={(userData) => onUpdateHandler(selectedTab, userData)}
          onDelete={onDeleted}
          onCreate={onCreateHandler}
          isGlobal={isGlobal}
          autoExpanded={autoExpanded}
          contentRenderer={() => (
            <PaymentDetailsForm
              selectedTab={selectedTab}
              paymentDetailsDTO={paymentDetailsDTO}
              isCreatePanel={!isEdit}
            />
          )}
          {...(isEdit && {
            tabStripRenderer: () =>
              tabStripRenderer(Object.values(EPaymentPanelTab)),
          })}
          validationSchema={getValidationSchemaByTab(selectedTab)}
          onFullscreen={onFullscreen}
          {...(isEdit && {
            fullscreen: {
              panelPropList: [
                {
                  data: panelData,
                  entity: entity,
                  panelId: EPaymentPanelTab.OVERVIEW,
                  panelTitle,
                  renderCustomTitle,
                  onUpdate: (userData) =>
                    onUpdateHandler(EPaymentPanelTab.OVERVIEW, userData),
                  contentRenderer: () => (
                    <PaymentDetailsForm
                      selectedTab={EPaymentPanelTab.OVERVIEW}
                      paymentDetailsDTO={paymentDetailsDTO}
                      isCreatePanel={!isEdit}
                    />
                  ),
                  panelWrapperCustomStyles: {
                    ...panelWrapperCustomStyles,
                    borderRadius: '18px 18px 0 18px', //override border radius
                  },
                  tabStripRenderer: () => <></>,
                },
              ],
            },
          })}
          {...(isEdit && {
            actionsRenderer: actionsRenderer,
          })}
          {...(!isEdit && {
            // Create Panel
            hideExpandIcon: true,
            autoExpanded: true,
            panelWrapperCustomStyles: {
              paddingBottom: '8px',
              overflow: 'unset',
            },
            contentCustomStyles: {
              padding: '0px important',
              overflow: 'unset',
            },
            contentWrapperCustomStyles: {
              borderRadius: '16px',
              top: 0,
              height: 'auto',
            },
            fullScreenCustomStyles: {
              background: 'none',
              boxShadow: 'none',
              width: '850px',
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            formCustomStyles: { height: '100%' },
            actionButtonLabel: 'Add Payment',
          })}
          isZindex={1201}
        />
      </>
    );
  }
);

export default PaymentDetailsPanel;
