import { trim } from 'lodash';
import StorageManager from '../../../StorageManager/StorageManager';
import { carrierService, documentService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { addressType } from '../../../common/Ui/AddressField/constants';
import { StaticDataType } from '../../../contexts/StaticDataContext';
import {
  AssignDocumentRequest,
  CreateDocumentOnlyRequest,
  DeleteDocumentRequest,
  DocumentSummaryResponse,
  EntityTypes,
  QueryParams,
} from '../../../models';
import {
  CarrierDTO,
  CarrierInsuranceDTO,
  CarrierStaticDataDTO,
  CreateCarrierInsuranceRequest,
  CreateCarrierRequest,
  ExportCarrierPayload,
  ExportCarrierQueryParams,
  GetCarrierOneRequest,
  GetPaginatedCarrierListQueryParams,
  GetPaginatedCarrierListRequest,
  LookupExternalCarrierItem,
} from '../../../models/DTOs/Carrier/Requests';
import { FileType } from '../../../types';
import { docType, downloadFile } from '../../../utils/Doc';
import uuid from '../../../utils/uuid';
import { toBase64, viewFileType } from '../../../views/reports/documents/DnD';
import { ISettingsPagination } from '../../../views/trips/constants/types';
import { ICreateCarrierFormData } from '../components/CarrierDetailsPanel/Forms/AddNewCarrierForm/AddNewCarrierForm';
import {
  CARRIER_INSURANCE_DOCUMENT_TYPE,
  carrierFieldsConfig,
  defaultCarrierStaticData,
  defaultCarrierWorkModeOption,
} from '../constants/constants';
import {
  ICarrierAutocompleteOption,
  ICarrierFilters,
  ICarrierInsuranceFormData,
} from '../constants/types';
import { getStateOptions } from './filters.utils';

export interface IFromCarrierFilterToQuery {
  queryParams: GetPaginatedCarrierListQueryParams;
  payload: GetPaginatedCarrierListRequest;
}

export const fromCarrierFilterToQuery = (
  settingsPagination: Partial<ISettingsPagination>,
  filters: ICarrierFilters
): IFromCarrierFilterToQuery => {
  const getQueryParams = (
    fieldName?: keyof ICarrierFilters
  ): Array<string | number> => {
    if (!fieldName) return [];
    return (
      filters[fieldName]?.map(({ key }: ICarrierAutocompleteOption) => key) ||
      []
    );
  };

  return {
    queryParams: new GetPaginatedCarrierListQueryParams({
      pageNumber: settingsPagination.pageNumber,
      pageSize: settingsPagination.pageSize,
      sort: filters?.sort ?? '+name',
    }),
    payload: new GetPaginatedCarrierListRequest({
      nameFilter: getQueryParams(carrierFieldsConfig.name.fieldFilterName),
      modeFilter: getQueryParams(carrierFieldsConfig.mode.fieldFilterName),
      contactNameFilter: getQueryParams(
        carrierFieldsConfig.contact.fieldFilterName
      ),
      statusFilter: getQueryParams(carrierFieldsConfig.status.fieldFilterName),
      mcFilter: getQueryParams(carrierFieldsConfig.mc.fieldFilterName),
      dotFilter: getQueryParams(carrierFieldsConfig.dot.fieldFilterName),
      scacFilter: getQueryParams(carrierFieldsConfig.scac.fieldFilterName),
      stateFilter: getQueryParams(carrierFieldsConfig.state.fieldFilterName),
    }),
  };
};

export const fetchCarrierOne = async (
  id: string
): Promise<CarrierDTO | null> => {
  const response = await carrierService.getCarrierOne(
    new GetCarrierOneRequest({
      id: id,
    })
  );
  return response instanceof ServiceError ? null : response;
};

export const fetchStaticData = async (): Promise<CarrierStaticDataDTO> => {
  const response = await carrierService.getStaticList();
  return response instanceof ServiceError ? defaultCarrierStaticData : response;
};

export const fetchCarrierCountTotal = async (): Promise<number> => {
  const response = await carrierService.getCarrierCountTotal();
  return response instanceof ServiceError ? 0 : response;
};

const fromExternalAddressToObject = (address: string): addressType => {
  return {
    streetAddress: address,
  };
  const emptyAddress = { streetAddress: '', city: '', state: undefined };
  if (!address) return emptyAddress;
  const tmp = address.split(',').map((str) => trim(str));
  const myState = tmp.pop();
  const myCity = tmp.pop();
  const myStreetAddress = tmp.join(', ');
  return {
    streetAddress: myStreetAddress,
    city: myCity,
    state: myState,
  };
};

export const fromExternalCarrierToFormData = (
  data: LookupExternalCarrierItem | null
): ICreateCarrierFormData => {
  return {
    name: data?.name || '',
    mc: data?.mc || null,
    dot: data?.dot || '',
    scac: data?.scac || '',
    mode: defaultCarrierWorkModeOption,
    truckCount: data?.truckCount > 0 ? data?.truckCount : null,
    preferenceRate: data?.preferenceRate ?? 5,
    address: fromExternalAddressToObject(data?.address),
    notes: '',
    contacts: [],
    insurances: [],
  };
};

export const fromCarrierDTOToFormData = (
  data: CarrierDTO | null,
  staticDataDTO: CarrierStaticDataDTO | null
): ICreateCarrierFormData => {
  const modeOption: ICarrierAutocompleteOption = data?.mode
    ? staticDataDTO?.workMode?.find(({ key }) => key === data?.mode) ||
      defaultCarrierWorkModeOption
    : defaultCarrierWorkModeOption;
  return {
    id: data?.id ?? undefined,
    name: data?.name || '',
    mc: data?.mc ?? null,
    dot: data?.dot || '',
    scac: data?.scac || '',
    mode: modeOption,
    truckCount: data?.truckCount ?? null,
    preferenceRate: data?.preferenceRate ?? null,
    address: data?.address
      ? {
          ...data?.address,
          zip: data?.address?.zipcode,
        }
      : undefined,
    notes: data?.notes,
    contacts: data?.contacts,
    insurances: data?.insurances?.map((item: CarrierInsuranceDTO) => {
      const typeOption: ICarrierAutocompleteOption =
        staticDataDTO?.insuranceType?.find(({ key }) => key === item.type);
      const stateOption: ICarrierAutocompleteOption = getStateOptions().find(
        ({ key }) => key === item.state
      );
      return {
        ...item,
        type: typeOption,
        state: stateOption,
      };
    }),
  };
};

const fromFormDataToCarrierRequest = ({
  formData,
  isCreate,
}: {
  formData: ICreateCarrierFormData;
  isCreate: boolean;
}): CreateCarrierRequest => {
  return new CreateCarrierRequest({
    id: formData?.id ?? undefined,
    status: 'ACTIVE',
    name: formData.name,
    mc: formData.mc,
    dot: formData.dot,
    scac: formData.scac,
    mode: formData.mode?.key,
    truckCount: formData.truckCount,
    preferenceRate: formData.preferenceRate,
    address: formData.address
      ? {
          fullAddress: formData.address.fullAddress,
          address: formData.address.address,
          city: formData.address.city,
          state: formData.address.state,
          streetAddress: formData.address.streetAddress,
          streetAddress2: formData.address.streetAddress2,
          zipcode: formData.address.zip,
        }
      : null,
    notes: formData.notes,
    contacts: formData?.contacts?.map((item) => {
      return {
        ...item,
        carrierId: formData?.id,
      };
    }),
    ...(!isCreate && {
      //only update mode
      insurances: formData?.insurances?.map((formData) =>
        fromInsuranceFormDataToInsuranceDTO({ formData })
      ),
    }),
  });
};

const fromInsuranceFormDataToInsuranceDTO = ({
  formData,
}: {
  formData: ICarrierInsuranceFormData;
}): CarrierInsuranceDTO => {
  return new CarrierInsuranceDTO({
    id: formData.id,
    seqNumber: formData.seqNumber,
    carrierId: formData.carrierId,
    type: formData.type?.key,
    amount: formData.amount,
    expDate: formData.expDate,
    provider: formData.provider,
    producer: formData.producer,
    policyNumber: formData.policyNumber,
    state: formData.state?.key,
    notes: formData.notes,
    fridgeBreakCoverage: formData.fridgeBreakCoverage,
  });
};

export const createCarrierHandler = async ({
  formData,
  staticData,
}: {
  formData: ICreateCarrierFormData;
  staticData: StaticDataType;
}) => {
  //Step 1:Request API create new Carrier
  const createdCarrier = await carrierService.createCarrier(
    fromFormDataToCarrierRequest({
      formData,
      isCreate: true,
    })
  );
  if (createdCarrier instanceof CarrierDTO && formData.insurances.length) {
    //Step 2:Request API create both new (Carrier Insurance + Insurance Document and assign to created Carrier Insurance)
    const createdCarrierInsurances = await createCarrierInsuranceHandler({
      carrierDTO: createdCarrier,
      carrierInsuranceFormDataList: formData.insurances,
      staticData,
    });
  }
  return createdCarrier;
};

export const isFileInstance = (file: any): boolean => {
  return file instanceof File;
};

export const updateCarrierHandler = async ({
  carrierDTO,
  formData,
  staticData,
}: {
  carrierDTO: CarrierDTO;
  formData: ICreateCarrierFormData;
  staticData: StaticDataType;
}) => {
  //Step 1: Check there are any Carrier Insurance change (input field/ document)?
  //=> Request API update Carrier Insurance
  //=> Request API update Carrier Insurance Document
  const response = await formData.insurances.reduce<
    Promise<ICarrierInsuranceFormData[]>
  >(
    async (
      listP,
      carrierInsuranceFormData
    ): Promise<ICarrierInsuranceFormData[]> => {
      const list = await listP;
      const originalCarrierInsuranceDTO = carrierDTO?.insurances?.find(
        ({ id }) => id === carrierInsuranceFormData.id
      );

      if (!carrierInsuranceFormData?.assignedDocuments?.length) {
        //Delete Document
        const ids = originalCarrierInsuranceDTO?.assignedDocuments
          ?.map((item) => item?.documentId)
          ?.filter(Boolean);

        if (ids?.length) {
          deleteCarrierInsuranceDocuments(ids);
          return [
            ...list,
            {
              ...carrierInsuranceFormData,
              assignedDocuments: null,
            },
          ];
        }
      } else if (
        isFileInstance(carrierInsuranceFormData?.assignedDocuments?.[0])
      ) {
        //New/Changed Document
        const carrierInsuranceDTO = fromInsuranceFormDataToInsuranceDTO({
          formData: carrierInsuranceFormData,
        });
        const assignedDocument: AssignDocumentRequest | undefined =
          await assignDocumentHandler({
            carrierDTO,
            carrierInsuranceDTO,
            files: carrierInsuranceFormData?.assignedDocuments as File[],
            staticData,
            assignedDocuments:
              originalCarrierInsuranceDTO?.assignedDocuments as
                | FileType[]
                | undefined,
          });
        return [
          ...list,
          {
            ...carrierInsuranceFormData,
            assignedDocuments: assignedDocument ? [assignedDocument] : null,
          },
        ];
      }
      return list;
    },
    Promise.resolve([])
  );

  //Step 2: Request API update Carrier
  const updatedCarrier = await carrierService.updateCarrier(
    fromFormDataToCarrierRequest({
      formData,
      isCreate: false,
    })
  );
  return updatedCarrier;
};

export const createCarrierInsuranceHandler = async ({
  carrierDTO,
  carrierInsuranceFormDataList,
  staticData,
}: {
  carrierDTO: CarrierDTO;
  carrierInsuranceFormDataList: ICarrierInsuranceFormData[];
  staticData: StaticDataType;
}) => {
  const response = await carrierInsuranceFormDataList.reduce<
    Promise<CarrierInsuranceDTO[]>
  >(async (listP, carrierInsuranceFormData): Promise<CarrierInsuranceDTO[]> => {
    //Request API create  new Carrier Insurance
    const carrierInsuranceDTO = await carrierService.createCarrierInsurance(
      new CreateCarrierInsuranceRequest({
        carrierId: carrierDTO.id,
        type: carrierInsuranceFormData.type?.key,
        fridgeBreakCoverage: carrierInsuranceFormData.fridgeBreakCoverage,
        amount: carrierInsuranceFormData.amount,
        expDate: carrierInsuranceFormData.expDate,
        provider: carrierInsuranceFormData.provider,
        producer: carrierInsuranceFormData.producer,
        policyNumber: carrierInsuranceFormData.policyNumber,
        state: carrierInsuranceFormData.state?.key,
        notes: carrierInsuranceFormData.notes,
      })
    );
    const list = await listP;
    if (carrierInsuranceDTO instanceof CarrierInsuranceDTO) {
      //Request API create new Insurance Document and assign to created Carrier Insurance
      const assignedDocument: AssignDocumentRequest | undefined =
        await assignDocumentHandler({
          carrierDTO,
          carrierInsuranceDTO,
          files: carrierInsuranceFormData?.assignedDocuments as File[],
          staticData,
        });
      return [
        ...list,
        {
          ...carrierInsuranceDTO,
          assignedDocuments: assignedDocument ? [assignedDocument] : null,
        } as CarrierInsuranceDTO,
      ];
    }
    return list;
  }, Promise.resolve([]));

  return response;
};

export const deleteCarrierInsuranceHandler = async ({
  carrierInsuranceFormData,
}: {
  carrierInsuranceFormData: ICarrierInsuranceFormData;
}) => {
  if (carrierInsuranceFormData?.assignedDocuments?.[0]?.documentId) {
    await deleteCarrierInsuranceDocuments([
      carrierInsuranceFormData?.assignedDocuments?.[0].documentId,
    ]);
  }
  await carrierService.deleteCarrierInsurance(
    new GetCarrierOneRequest({
      id: carrierInsuranceFormData.id,
    })
  );
};

//#region assignDocument

export const assignDocumentHandler = async ({
  carrierDTO,
  carrierInsuranceDTO,
  files,
  staticData,
  assignedDocuments,
}: {
  carrierDTO: CarrierDTO;
  carrierInsuranceDTO: CarrierInsuranceDTO;
  files: File[];
  staticData: StaticDataType;
  assignedDocuments?: FileType[];
}) => {
  if (files?.length) {
    const fileRes = await uploadDocument(files[0]);
    if (fileRes) {
      const document = await assignDocument({
        fileRes,
        carrierDTO,
        carrierInsuranceDTO,
        staticData,
      });
      if (!!assignedDocuments?.length) {
        const ids = assignedDocuments
          ?.map((item) => item?.documentId)
          .filter(Boolean);
        deleteCarrierInsuranceDocuments(ids);
      }
      if (document) return document;
    }
  }
};

export const uploadDocument = async (assignedDocument: File) => {
  const queryParams = new QueryParams();
  queryParams.addUserIdToParams();

  const requestBody = new CreateDocumentOnlyRequest({
    data: assignedDocument,
    ownerId: queryParams.userId,
  });

  const response = await documentService.createDocumentOnly(requestBody);
  return response instanceof ServiceError ? null : response;
};

export const assignDocument = async ({
  fileRes,
  carrierDTO,
  carrierInsuranceDTO,
  staticData,
}: {
  fileRes: DocumentSummaryResponse;
  carrierDTO: CarrierDTO;
  carrierInsuranceDTO: CarrierInsuranceDTO;
  staticData: StaticDataType;
}) => {
  const user = StorageManager.getUser() || {};

  const documentType = staticData.documentTypes.find(
    (item) => item.itemCode === CARRIER_INSURANCE_DOCUMENT_TYPE
  );

  const assignDocumentRequest = new AssignDocumentRequest({
    description: carrierDTO.name,
    ownerName: user.firstname + ' ' + user.lastname,
    permission: 'PRIVATE',
    documentType: CARRIER_INSURANCE_DOCUMENT_TYPE,
    documentTypeId: documentType?.id ?? '',
    documentId: fileRes.id,
    fileName: fileRes.fileName,
    fileSize: fileRes.fileSize,
    uploadDate: fileRes.uploadDate,
    ownerId: fileRes.ownerId || Number.parseInt(user.id),
    attachedEntities: [
      {
        properties: [
          {
            id: carrierInsuranceDTO?.id,
            title: carrierDTO.name,
          },
        ],
        type: CARRIER_INSURANCE_DOCUMENT_TYPE,
      },
    ],
  });
  const response = documentService.assignDocument(assignDocumentRequest);
  return response instanceof ServiceError ? null : response;
};

export const deleteCarrierInsuranceDocuments = async (
  documentIds: string[]
): Promise<EntityTypes | undefined> => {
  if (documentIds) {
    const response = await documentService.deleteDocument(
      new DeleteDocumentRequest({
        documentIds: documentIds,
      })
    );

    return response;
  }
};

//#endregion

export const exportToExcelHandler = async ({
  settingsPagination,
  filters,
  gridColumnMetadataList,
}: {
  settingsPagination: Partial<ISettingsPagination>;
  filters: ICarrierFilters;
  gridColumnMetadataList: string[];
}) => {
  const carrierFilter = fromCarrierFilterToQuery(settingsPagination, filters);
  const exportPayload: ExportCarrierPayload = new ExportCarrierPayload({
    ...carrierFilter.payload,
  });
  const exportQueryParams = new ExportCarrierQueryParams({
    gridColumnMetadataList,
    sort: carrierFilter.queryParams.sort,
  });

  const response = await carrierService.exportToExcel(
    exportQueryParams,
    exportPayload
  );
  return response instanceof ServiceError ? null : downloadFile(response);
};

export const fromFileToDocType = async (
  acceptedFiles: File[]
): Promise<docType | null> => {
  const base64Files = acceptedFiles?.map(async (file: File) => {
    return await toBase64(file);
  });
  if (base64Files.length) {
    return Promise.all(base64Files)
      .then((results) => {
        const base64File = results?.map(({ src, fileName, size }) => ({
          id: uuid(),
          src,
          fileName,
          size,
        })) as viewFileType[];
        const [baseType, fileContent] = base64File[0].src.split(',');
        const contentType = baseType.split(/[:;]/)[1];
        const fileName = base64File[0].fileName;
        const size = base64File[0].size;
        const file = {
          contentType,
          fileContent,
          fileName,
          size,
        };
        return file;
      })
      .catch((e) => {
        console.error(e);
        return null;
      });
  }
  return null;
};

export const getDefaultCarrierDocument = async (
  assignedDocuments: File[] | null
) => {
  if (assignedDocuments === null) return null;
  return isFileInstance(assignedDocuments[0])
    ? await fromFileToDocType(assignedDocuments)
    : null;
};
