import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import React, { FunctionComponent, useEffect, useState } from 'react';

const AutocompleteField: FunctionComponent<Props> = ({
  multiple = false,
  options,
  label,
  variant,
  disabled = false,
  error = false,
  styleProps,
  addCustom = false,
  startAdornment = false,
  id,
  defaultValue,
  required,
  onChange,
  helperText,
  freeSolo = false,
  disableClearable = false,
  onOpen,
  onInputChange,
  limitTags = -1,
  size = 'medium',
  renderInput,
  isOptionEqualToValue,
}: Props) => {
  const [textFieldIcon, setTextFieldIcon] = useState<any>(defaultValue);
  const [autocompleteError, setautocompleteError] = useState<boolean>(error);
  const [autoCompleteValue, setAutocompleteValue] = useState<any>(defaultValue);

  useEffect(() => {
    setAutocompleteValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setautocompleteError(error);
  }, [error]);

  if (addCustom) {
    options.unshift({ label: 'Add Custom' });
  }

  const handleOnChange = (event: any, newValue: any) => {
    const requiredCheckCondition =
      required && (newValue === null || (multiple && newValue.length === 0));
    setAutocompleteValue(newValue);
    requiredCheckCondition
      ? setautocompleteError(true)
      : setautocompleteError(false);
    if (newValue && newValue.length >= 1) {
      if (newValue.some((item: any) => item.label === 'Add Custom')) {
        //alert("CustomItem Selected from multiple select");
      }
    }
    if (newValue && newValue.label === 'Add Custom') {
      //alert("CustomItem Selected from single select");
    }
    if (startAdornment)
      setTextFieldIcon(newValue && newValue.icon ? newValue.icon : null);
    if (onChange) {
      onChange(event, newValue, requiredCheckCondition, id);
    }
  };

  const handleOnOpen = (event: any) => {
    if (onOpen) onOpen(event);
  };

  const handleOnInputChange = (event: any, newValue: any) => {
    if (onInputChange) onInputChange(event, newValue);
  };

  return (
    <Autocomplete
      id={id}
      freeSolo={freeSolo}
      sx={styleProps}
      multiple={multiple}
      options={options}
      disableCloseOnSelect={multiple}
      value={autoCompleteValue || undefined}
      disabled={disabled}
      disableClearable={disableClearable}
      limitTags={limitTags}
      size={size}
      getOptionLabel={(option: any) => option.label || []}
      {...(isOptionEqualToValue && {
        isOptionEqualToValue,
      })}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {startAdornment && option.icon}
          {multiple && (
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
          )}
          {option.label}
        </li>
      )}
      onChange={(event, newValue) => handleOnChange(event, newValue)}
      onOpen={handleOnOpen}
      onInputChange={(event, newValue) => handleOnInputChange(event, newValue)}
      renderInput={(params) => {
        if (startAdornment) params.InputProps.startAdornment = textFieldIcon;
        if (renderInput) {
          return renderInput(params);
        }
        return (
          <TextField
            {...params}
            label={label}
            variant={variant}
            error={autocompleteError || error}
            required={required}
            helperText={autocompleteError && helperText}
          />
        );
      }}
    />
  );
};

export interface Props {
  id?: string;
  multiple?: boolean;
  options: Array<Object>;
  label?: string;
  variant?: any;
  disabled?: boolean;
  error?: boolean;
  styleProps?: object;
  addCustom?: boolean;
  freeSolo?: boolean;
  startAdornment?: boolean;
  defaultValue?: any;
  required?: boolean;
  onChange?: Function;
  helperText?: any;
  disableClearable?: boolean;
  onOpen?: Function;
  onInputChange?: Function;
  limitTags?: number;
  size?: 'small' | 'medium';
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
}

export default AutocompleteField;
