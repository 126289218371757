import { CANCEL_TRIP_POPUP_CONFIG } from '../../../../views/dispatch2/config';
import DeletePopup from '../../../../common/DeletePopup';
import React from 'react';

interface ICancelTripAssignment {
  onApprove: () => void;
  onCancel: () => void;
}

const ConfirmTripAssignmentCancel: React.FC<ICancelTripAssignment> = (
  props
) => {
  return (
    <DeletePopup
      open
      {...CANCEL_TRIP_POPUP_CONFIG}
      onAction={props?.onApprove}
      onClose={props?.onCancel}
    />
  );
};

export default ConfirmTripAssignmentCancel;
