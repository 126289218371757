import Grid from '@mui/material/Grid';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { AutocompleteAsync } from '../../../ui-kit/components/AutocompleteAsync/AutocompleteAsync';

export const AutoCompleteAsyncForm: React.FC<{
  control?: Control;
  name: string;
  getData: (name: string) => Promise<any>;
  fieldName: string;
  defaultValue?: any;
  label: string;
  disabled?: boolean;
  sizes?: {
    xs: number;
  };
  value?: any;
  error?: { message: string };
}> = ({
  control,
  name,
  getData,
  fieldName,
  defaultValue = [],
  label,
  disabled,
  sizes = { xs: 12 },
  ...rest
}) => {
  return (
    <Grid xs={sizes.xs} item style={{ marginBottom: 20 }}>
      <Controller
        render={({ field }) => (
          <AutocompleteAsync
            {...field}
            {...rest}
            label={label}
            name={name}
            disabled={disabled}
            getOptions={getData}
            fieldName={fieldName}
            defaultValue={defaultValue}
            onChangeCb={field.onChange}
            value={field.value}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </Grid>
  );
};

export const AutoCompleteAsyncFilter: React.FC<{
  name: string;
  label: string;
  getData: (name: string) => Promise<any>;
  fieldName: string;
  defaultValue?: any;
  value: any;
  onChange: (data: any, name: string) => void;
}> = ({
  name,
  getData,
  fieldName,
  defaultValue = [],
  label,
  value,
  onChange,
}) => {
  return (
    <AutocompleteAsync
      name={name}
      label={label}
      getOptions={getData}
      fieldName={fieldName}
      defaultValue={defaultValue}
      value={value}
      onChangeCb={onChange}
      customStyles={{
        minWidth: 200,
      }}
    />
  );
};

const AutoComplete: React.FC<{
  name: string;
  label: string;
  getData: (name: string) => Promise<any>;
  fieldName: string;
  defaultValue?: any;
  value: any;
  onChange: () => void;
}> = ({
  name,
  getData,
  fieldName,
  defaultValue = [],
  label,
  value,
  onChange,
}) => {
  return (
    <AutocompleteAsync
      name={name}
      label={label}
      getOptions={getData}
      fieldName={fieldName}
      defaultValue={defaultValue}
      value={value}
      onChangeCb={onChange}
    />
  );
};

export default AutoComplete;
