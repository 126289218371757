import { observer } from 'mobx-react';
import { FinanceOverviewFormProvider } from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewFormContext';
import { FinanceOverviewType } from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewModel';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { Trip } from '../../../../models';
import { ESecondaryDetailsPanelType } from '../../../dispatch2/constants/types';
import { CarrierFinanceOverview } from './CarrierFinanceOverview';

export const CarrierPayment = observer(
  ({
    trip,
    onClose,
    isBrokered = false,
  }: Pick<FinanceOverviewType, 'onClose' | 'isBrokered'> & { trip: Trip }) => {
    return (
      <FinanceOverviewFormProvider
        loadId={trip.id}
        loadStatus={trip.status}
        ModuleType={'FinanceTrip'}
        panelType={ESecondaryDetailsPanelType.TRIP}
        openPayments={undefined}
        onClose={onClose}
        isBrokered={isBrokered}
      >
        <Permission
          includes={[AXELE_PERMISSION_TYPE?.LOAD_FINANCIAL_PAYMENT_VIEW]}
        >
          <CarrierFinanceOverview trip={trip} />
        </Permission>
      </FinanceOverviewFormProvider>
    );
  }
);
