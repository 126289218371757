import { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { EQUIPMENT_PANEL_TABS as TABS } from '../../../constants/contacts';
import TerminalController from '../../../views/settings/terminals/TerminalController';
import TractorController from '../Controller';
import { Tractor } from '../models/Tractor';
import { TractorSummary } from '../models/TractorSummary';
import { isInactive } from '../utils';
import AlertsTabDetails from './AlertsTabDetails';
import GeneralTabDetails from './GeneralTabDetails';
import { WrapperMaintenanceTabDetails } from './WrapperMaintenanceTabDetails';
import { WrapperTractorPayment } from './WrapperTractorPayment';
import Documents from '@/views/reports/documents/sections/EntityDocumentsSection';
import { EntityNotes } from '@/common/EntityNotes';

export async function getStaticDataTractorDetails() {
  const controller = TractorController.instance();

  await Promise.all([
    controller.getYearOptions(),
    controller.getStateOptions(),
    controller.getBrakingOptions(),
    controller.getTollProviderOptions(),
    controller.getFuelProviderOptions(),
    controller.getOwnershipTypeOptions(),
    controller.getDefaultTractorsTypesList(),
    controller.getDefaultTractorsTypesDictionary(),
    TerminalController.instance().getTerminals(),
  ]);
}
export interface TractorDetailsFormProps {
  expanded: boolean;
  selectedTab: TABS;
  hasUnavailableStatus?: boolean;
  onUpdateWarningsCount: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
  onAssign: (driverGroupId: string) => void;
  data: TractorSummary | Tractor;
  setOpenDialog: React.Dispatch<
    React.SetStateAction<'' | 'markAvailable' | 'markUnavailable' | 'delete'>
  >;
  setShowPopup: React.Dispatch<
    React.SetStateAction<
      | {
          allow?: boolean | undefined;
          body?: string | undefined;
        }
      | null
      | undefined
    >
  >;
}
const TractorDetailsForm: FC<TractorDetailsFormProps> = ({
  expanded,
  selectedTab,
  onUpdateWarningsCount,
  onAssign,
  data,
  setOpenDialog,
  setShowPopup,
  hasUnavailableStatus,
}) => {
  const { control } = useFormContext();
  const inactive = isInactive(data?.status);
  return (
    <Fragment>
      {selectedTab === TABS.GENERAL && (
        <GeneralTabDetails
          expanded={expanded}
          control={control}
          onAssign={onAssign}
          disableAllFields={hasUnavailableStatus || inactive}
          data={data}
          setOpenDialog={setOpenDialog}
          setShowPopup={setShowPopup}
        />
      )}

      {selectedTab === TABS.ALERTS && (
        <AlertsTabDetails
          onUpdateWarningsCount={onUpdateWarningsCount}
          disableAllFields={hasUnavailableStatus || inactive}
        />
      )}
      {selectedTab === TABS.MAINTENANCE && (
        <WrapperMaintenanceTabDetails
          disableAllFields={hasUnavailableStatus || inactive}
        />
      )}

      {selectedTab === TABS.PAYMENT_TERMS && (
        <WrapperTractorPayment
          disableAllFields={hasUnavailableStatus || inactive}
        />
      )}
      {selectedTab === TABS.NOTES && (
        <EntityNotes entityId={data.id} entityType="TRACTOR" />
      )}
      {selectedTab === TABS.DOCUMENT && (
        <Documents
          hideShareWithDriver
          data={{
            id: data.id,
            entity: 'TRACTOR',
            entityObject: {
              id: data.id,
              name: `${data.tractorName}`,
            },
            terminal: null,
          }}
        />
      )}
    </Fragment>
  );
};

export default TractorDetailsForm;
