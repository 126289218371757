import { Box } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  ClassMappingDropdownItemType,
  ClassMappingListResponseType,
  ClassMappingTableType,
} from '../../../../../models';
import { GridColDefSelf, ViewMetaData } from '../../../../../types';
import { CommonDataGrid } from '../../../../../ui-kit/components/DataGridPro';
import SingleAutocomplete from '../../../../../ui-kit/components/SingleAutocomplete';
import ClassMappingActions from '../ClassMappingActions';
import {
  dropdownStyles,
  qclassMappingDefaultFilters,
  textInputStyles,
} from './constants';

export default function ClassMappingEntities({
  qbAccountId,
  terminalIds,
  refreshData,
  setRefreshData,
}: {
  qbAccountId: string;
  terminalIds: string[];
  refreshData: ClassMappingListResponseType;
  setRefreshData: (data: ClassMappingListResponseType | null) => void;
}) {
  const [classMappingInfo, setClassMappingInfo] =
    useState<ClassMappingListResponseType | null>(null);
  const [tableData, setTableData] = useState<ClassMappingTableType[]>([]);
  const [originalTableData, setOriginalTableData] = useState<
    ClassMappingTableType[]
  >([]);
  const [driverData, setDriverData] = useState<ClassMappingTableType[] | null>(
    null
  );
  const [tractorData, seTractorData] = useState<ClassMappingTableType[] | null>(
    null
  );
  const [mappingType, setMappingType] = useState('TRACTOR');
  const [filters, setFilters] = useState<ViewMetaData>(
    qclassMappingDefaultFilters
  );

  useEffect(() => {
    if (refreshData && mappingType) {
      const list = mappingType === 'TRACTOR' ? tractorData : driverData;
      const mapping =
        mappingType === 'TRACTOR'
          ? refreshData.tractorClassCategoriesMapping
          : refreshData.driverClassCategoriesMapping;
      const data = ClassMappingActions.generateTableData(mapping, list);
      setTableData(data);
      setOriginalTableData(data);
    }
    return () => {
      setRefreshData(null);
    };
  }, [refreshData, mappingType]);

  useEffect(() => {
    const getClassMappingList = async () => {
      const result = await ClassMappingActions.getClassMappingList(qbAccountId);
      if (result) {
        setClassMappingInfo(result);
        setMappingType(result.mappingEntityType);
      }
    };
    getClassMappingList();
  }, []);

  useEffect(() => {
    if (classMappingInfo && mappingType) {
      const getDrivers = async () => {
        const drivers = await ClassMappingActions.getDrivers(
          terminalIds,
          classMappingInfo.driverClassCategoriesMapping
        );
        setDriverData(drivers);
        if (mappingType === 'DRIVER') {
          setTableData(drivers);
          setOriginalTableData(drivers);
        }
      };
      const getTractors = async () => {
        const tractors = await ClassMappingActions.getTractors(
          terminalIds,
          classMappingInfo.tractorClassCategoriesMapping
        );
        seTractorData(tractors);
        if (mappingType === 'TRACTOR') {
          setTableData(tractors);
          setOriginalTableData(tractors);
        }
      };
      if (!driverData) {
        getDrivers();
      }
      if (!tractorData) {
        getTractors();
      }
    }
  }, [classMappingInfo, mappingType, driverData, tractorData]);

  const handleClassMappingChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const response = await ClassMappingActions.changeClassMappingType(
      qbAccountId,
      event.target.value
    );
    if (response) {
      const mapping =
        response.mappingEntityType === 'TRACTOR'
          ? response.tractorClassCategoriesMapping
          : response.driverClassCategoriesMapping;
      const list =
        response.mappingEntityType === 'TRACTOR' ? tractorData : driverData;
      const data = ClassMappingActions.generateTableData(mapping, list);
      setMappingType(event.target.value);
      setTableData(data);
      setOriginalTableData(data);
      setFilters(qclassMappingDefaultFilters);
    }
  };

  const handleMappingChange = async (
    value: ClassMappingDropdownItemType,
    mappingEntityId: string
  ) => {
    const response = await ClassMappingActions.mappingChange(
      qbAccountId,
      value,
      mappingType,
      mappingEntityId
    );
    if (response) {
      setTableData((tableData) => {
        return tableData.map((data) => {
          if (data.id === mappingEntityId) {
            return { ...data, mappingName: { id: value.id, name: value.name } };
          }
          return data;
        });
      });
      setOriginalTableData((tableData) => {
        return tableData.map((data) => {
          if (data.id === mappingEntityId) {
            return { ...data, mappingName: { id: value.id, name: value.name } };
          }
          return data;
        });
      });
    }
  };

  const handleGetMappingList = async () => {
    return await ClassMappingActions.getClassMappingDropdown(qbAccountId);
  };

  const handleFilterChangeCb = (data: string[], name: string) => {
    setFilters((oldFilters) => ({ ...oldFilters, [name]: data }));
  };

  useEffect(() => {
    const existingMapped = filters['mapped'];
    if (mappingType === 'TRACTOR') {
      const tractorIds = (filters['tractorIds'] || []).map(
        (tractor: { id: string }) => tractor.id
      );
      const mapped = filters['mapped']?.value === 'MAPPED';
      const updatedata = originalTableData
        .filter((row) =>
          tractorIds.length ? tractorIds.includes(row.id) : true
        )
        .filter((row) =>
          !existingMapped ? true : mapped ? !!row.mappingName : !row.mappingName
        );
      setTableData(updatedata);
    }
    if (mappingType === 'DRIVER') {
      const driverIds = (filters['driverIds'] || []).map(
        (driver: { id: string }) => driver.id
      );
      const mapped = filters['mapped']?.value === 'MAPPED';
      const updatedata = originalTableData
        .filter((row) => (driverIds.length ? driverIds.includes(row.id) : true))
        .filter((row) =>
          !existingMapped ? true : mapped ? !!row.mappingName : !row.mappingName
        );
      setTableData(updatedata);
    }
  }, [filters, mappingType]);

  const classMappingColumns: GridColDefSelf[] = [
    {
      flex: 1,
      field: 'name',
      headerName: mappingType === 'DRIVER' ? 'Driver Name #' : 'Tractor Name #',
      sortable: false,
      minWidth: 150,
    },
    {
      flex: 1,
      field: 'mapping',
      headerName: 'QuickBooks Class Name',
      sortable: false,
      minWidth: 150,
      renderCell: (params) => {
        const value = params.row.mappingName;
        return (
          <SingleAutocomplete
            name={'mappingName'}
            fieldName={'name'}
            label={value ? '' : 'Select Map'}
            getOptions={handleGetMappingList}
            value={value || null}
            customTextInputStyles={textInputStyles}
            customStyles={dropdownStyles}
            onChangeCb={(data) => handleMappingChange(data, params.row.id)}
          />
        );
      },
    },
  ];

  const classMappingDriverFilterColumnConfig = [
    {
      key: 1,
      name: 'driverIds',
      fieldName: 'name',
      options: driverData,
      label: 'Driver',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: 'mapped',
      fieldName: 'label',
      options: [
        { label: 'Mapped', value: 'MAPPED' },
        { label: 'Not Mapped', value: 'NOT_MAPPED' },
      ],
      label: 'Status',
      default: true,
      type: 'SingleAutocomplete',
    },
  ];

  const classMappingTractorFilterColumnConfig = [
    {
      key: 1,
      name: 'tractorIds',
      fieldName: 'name',
      options: tractorData,
      label: 'Tractor',
      default: true,
      type: 'MultipleAutocomplete',
    },
    {
      key: 2,
      name: 'mapped',
      fieldName: 'label',
      options: [
        { label: 'Mapped', value: 'MAPPED' },
        { label: 'Not Mapped', value: 'NOT_MAPPED' },
      ],
      label: 'Status',
      default: true,
      type: 'SingleAutocomplete',
    },
  ];

  const filtersConfig: Record<string, ViewMetaData[]> = {
    TRACTOR: classMappingTractorFilterColumnConfig,
    DRIVER: classMappingDriverFilterColumnConfig,
  };

  return (
    <Box
      sx={{
        color: '#000',
        display: 'flex',
        flexDirection: 'column',
        margin: 3,
        width: '100%',
      }}
    >
      <Box>
        <RadioGroup
          onChange={handleClassMappingChange}
          row
          aria-labelledby="class-mapping-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            checked={mappingType === 'TRACTOR'}
            value="TRACTOR"
            control={<Radio />}
            label="Tractor"
          />
          <FormControlLabel
            checked={mappingType === 'DRIVER'}
            value="DRIVER"
            control={<Radio />}
            label="Driver"
          />
        </RadioGroup>
      </Box>
      <Box sx={{ width: '100%' }}>
        <CommonDataGrid
          key={mappingType}
          tableData={tableData}
          tableColumnsData={classMappingColumns}
          displayFilters={false}
          settingsPanel={false}
          filterColumns={filtersConfig[mappingType]}
          defaultFilterValue={filters}
          onPageFilterChange={handleFilterChangeCb}
          disableSelectionOnClick={true}
          pageName="classMapping"
          cardBackgroundColor={'background.paper'}
          rowHeight={50}
        />
      </Box>
    </Box>
  );
}
