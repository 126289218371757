import { GridColDef } from '@mui/x-data-grid-pro';
import { Type } from 'class-transformer';
import { ReactNode } from 'react';
import { IStatusConfig } from '../common/Ui/StatusComponent/StatusComponent';
import { SortResponse } from '../models';
import { ITabV2 } from '../ui-kit/components/TabsV2';
import { ESecondaryDetailsPanelType } from '../views/dispatch2/constants/types';

//@TODO: Nvard (Sarkis, Davit,Nithin) - need to be refactored in FE/BE side to have the same name for zicode/zip
// to be able to share the same types/models for Address
// relates to location/customer/vendor ...

export type addressType = {
  fullAddress: string;
  address: string;
  city: string;
  state: string;
  streetAddress: string;
  streetAddress2: string;
  zipcode: string;
  zip?: string; // @TODO in customer zip is used instead of zipcode. This need to be consistent in all places
};

export type StatusTypes = { [key: string]: string };
export type StatusColorTypes = {
  [key: string]: IStatusConfig;
};
export type IconTypes = { [key: string]: JSX.Element };
export type ComponentType = {
  [key in ESecondaryDetailsPanelType]: ReactNode;
};

export interface GridColDefSelf extends GridColDef {
  isHyperLink?: boolean;
  permanent?: boolean;
  keepValidOnEditing?: boolean;
}

export interface HideFilter {
  [key: string]: boolean;
}

export interface Filter {
  default: boolean;
  name: string;
  data: string[] | boolean | string | Date;
}

export type ViewMetaData = {
  [key: string]: any;
};

export class UserView {
  id?: string | null;
  userId?: number;
  shared: boolean;
  parentId?: string | null;
  name: string;
  entity: string;
  icon?: string;
  active?: boolean;
  columnFilters: { [key: string]: boolean };
  metaData: ViewMetaData;
  default?: boolean;

  constructor(dto: any) {
    this.id = dto.id;
    this.userId = dto.userId;
    this.shared = dto.shared;
    this.parentId = dto.parentId;
    this.name = dto.name;
    this.entity = dto.entity;
    this.icon = dto.icon;
    this.active = dto.active;
    this.columnFilters = dto.columnFilters;
    this.metaData = dto.metaData;
    this.default = dto.default || false;
  }
}

export type CustomViewActions = {
  label: string;
  iconName: string;
  source: string;
  key: string;
  onClick: () => void;
};

export class View implements ITabV2<'id'> {
  key?: string;
  label!: string;
  actions?: CustomViewActions[];
  id: string;
  userId?: number;
  shared?: boolean;
  parentId?: string | null;
  name: string;
  entity: string;
  icon?: JSX.Element | null;
  iconName?: string | null;
  source?: string;
  active?: boolean;
  columnFilters: { [key: string]: boolean };
  metaData: ViewMetaData;
  default?: boolean;
  columns?: any;

  constructor(dto: any) {
    this.id = dto.id;
    this.userId = dto.userId;
    this.shared = dto.shared;
    this.parentId = dto.parentId;
    this.name = dto.name;
    this.entity = dto.entity;
    this.icon = dto.icon;
    this.icon = dto.icon;
    this.iconName = dto.iconName;
    this.source = dto.source;
    this.columnFilters = dto.columnFilters;
    this.metaData = dto.metaData;
    this.default = dto.default || false;
  }
}

export type IconProps = {
  width?: number | string;
  height?: number | string;
  color?: string;
  opacity?: number | string;
};

export type ColumnsMap = {
  [key: string]: GridColDefSelf[];
};

export type ViewMap = {
  [key: string]: View;
};

export class Model {
  id: string;

  constructor(dto: any) {
    this.id = dto?.id;
  }
}

export type PaginatedResult<T> = {
  content: T[];
  first?: boolean;
  last: boolean;
  size?: number;
  number?: number;
  numberOfElements?: number;
  totalElements?: number;
  totalPages?: number;
  dataExists?: boolean;
  calculateDetention?: boolean;
};

export type PhoneType = {
  countryCode: string;
  extension: number;
  phone: number;
};

export interface IHttpErrorType {
  fieldName: string;
  message: string;
}

export class PaginatedList {
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
  totalCriticalIssueCount!: number;
  totalWarningIssueCount!: number;
}

export interface FileType extends File {
  size: number;
  documentId: string;
  fileName?: string;
}

export type LineItem = {
  id: string;
  amount: number;
  description: string;
  itemId: string;
  itemCode: string;
  itemName: string;
  itemType: string;
  quantity: number;
  rate: number;
  type: string;
  destination?: any;
  origin?: any;
  weight: number;
  isContract?: boolean;
  itemCodeId?: string;
  totalMiles?: number;
  rateMethod?: string;
  minMiles?: number;
  maxMiles?: number;
  unit?: string;
  contractId?: string;
  contractRateId?: string;
  deduction?: boolean;
};

export type objectType = {
  name?: string;
  value?: string;
  key?: string;
  seqNumber?: string;
  id?: string;
  itemCode?: string;
  itemName?: string;
  payCategoryName?: string;
  payCategoryKey?: string;
  companyName?: string;
};

export class AssignedDocuments {
  documentId: string;
  documentType: string;
  documentTypeId: string;
  fileName?: string;
  name?: string;

  constructor(data: any) {
    this.documentId = data.documentId;
    this.documentType = data.documentType;
    this.documentTypeId = data.documentTypeId;
    this.fileName = data.fileName;
    this.name = data.fileName;
  }
}

export interface IAssignedDocuments {
  Registration: AssignedDocuments[] | null;
  Insurance: AssignedDocuments[] | null;
}

export type WarningPopoverConfigType = {
  title: string;
  data?: any;
  subTitle?: string;
  body?: string;
  actionName?: string;
  buttonText?: string;
  cancelText?: string;
  backgroundColor?: string;
  buttonTextColor?: string;
  width?: string;
  contentRenderer?: () => React.ReactNode;
};
