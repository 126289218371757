import {
  CreateRequestCredentials,
  DeactivateAccountCard,
  GetTerminalList,
  LoadboardRequest,
  LoginAccountIntegration,
  MarketRateRequest,
  UserDetailsRequest,
} from '../../models/DTOs/Loadboard/Requests';
import {
  AllTerminalResponse,
  ICreateDATSendCredentialsResponse,
  ICreateLoginCardResponse,
  IDeactivateCardResponse,
  IeditDATCredentialsResponse,
  LoadboardProviderData,
  MarketRateResponse,
  UserDetailsReponse,
} from '../../types/LoadboardTypes';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class ILoadboardService extends Service {
  abstract getLoadboardProvidersList(
    requestData: LoadboardRequest
  ): Promise<LoadboardProviderData | null | ServiceError>;

  abstract createDATCredential(
    requestData: CreateRequestCredentials
  ): Promise<ICreateDATSendCredentialsResponse | null | ServiceError>;

  abstract getTerminals(
    requestData: GetTerminalList
  ): Promise<AllTerminalResponse | null | ServiceError>;

  abstract createLoginCard(
    requestData: LoginAccountIntegration
  ): Promise<ICreateLoginCardResponse | null | ServiceError>;

  abstract editDATCredential(
    requestData: CreateRequestCredentials
  ): Promise<IeditDATCredentialsResponse | null | ServiceError>;

  abstract deactivatedAccountCard(
    requestData: DeactivateAccountCard
  ): Promise<IDeactivateCardResponse | null | ServiceError>;

  abstract oAuthEdit(
    requestData: CreateRequestCredentials
  ): Promise<IeditDATCredentialsResponse | null | ServiceError>;

  abstract getUserDetails(
    requestData: UserDetailsRequest
  ): Promise<UserDetailsReponse | null | ServiceError>;

  abstract getMarketRate(
    requestData: MarketRateRequest
  ): Promise<MarketRateResponse | null | ServiceError>;
}
