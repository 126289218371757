import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import React, { CSSProperties, useCallback, useEffect } from 'react';
import { IPanelName } from '../store/pageStores';
import { AvailableTripsLayout, GanttLayout } from '../views/dispatch2';
import DispatchChildrenPanel from '../views/dispatch2/components/Panel';
import { ESecondaryDetailsPanelType } from '../views/dispatch2/constants/types';

import { IGanttSubTitle, TRIP_TYPE } from '../views/dispatch2/types';

import PageContainer from '../common/Wrappers/PageContainer';
import { useThemeResponsive } from '../common/hooks/useThemeResponsive';
import { RECOMMEND_SORT_ENUMS } from '../constants/gantt/gantt.const';
import { useRootStore } from '../store/root-store/rootStateContext';
import { useStores } from '../store/root.context';
import { PANEL_FIXED_WIDTH } from '../views/dispatch2/constants/dispatch';
import Evaluation from '../views/evaluation';
import BulkOptymization from '../views/optymization';
import PreferenceSettingsPopup from '../views/optymization/components/preferences/PreferencesForm';
import { GANTT_DEFAULT_START_DATE } from '../views/optymization/config/gantt.config';
import { SecondaryPanelType } from '@/models/DTOs/Dispatch/Dispatch';

const SPACE = '14px';

const Dispatch: React.FC = () => {
  const { isMobile, isTablet } = useThemeResponsive();
  const { getDispatchPanelAutoOpenDriver } = useRootStore();
  const {
    dispatch2TripsStore: {
      secondaryPanelData,
      fullscreenPanel,
      setSecondaryPanelData,
      setThemeResponsive,
    },
    dispatch2GlobalStore: {
      getBulkOptymizationInputs,
      stopBulkOptymization,
      updatePreferenceSettings,
      onAfterPreferencesUpdate,
    },
    bulkOptymizationStore: { currentSandboxId, optimizationRequestResources },
  } = useStores();
  useEffect(() => {
    setThemeResponsive({ isMobile, isTablet });
  }, [isMobile, isTablet]);

  const getItemStyle = (panelName: IPanelName): CSSProperties =>
    fullscreenPanel
      ? {
          height: fullscreenPanel === panelName ? '100%' : undefined,
        }
      : {
          height: panelName === 'DRIVER' ? '60%' : '40%',
        };
  const handleTripViewPanel = useCallback((tripData: TRIP_TYPE) => {
    setSecondaryPanelData({
      type: ESecondaryDetailsPanelType.TRIP as any,
      id: tripData?.tripPrimaryId,
    });
  }, []);
  const handleLoadViewPanel = useCallback((load: object) => {
    setSecondaryPanelData({
      type: ESecondaryDetailsPanelType.LOAD as any,
      id: (load as any)?.id,
    });
  }, []);

  const handleDriverPanel = useCallback((driverGroupId: string) => {
    if (!driverGroupId) return;
    setSecondaryPanelData({
      type: ESecondaryDetailsPanelType.DISPATCH as any,
      id: driverGroupId,
    });
  }, []);

  const handleCustomerPanel = (customerData: SecondaryPanelType) => {
    if (!customerData.id) return;
    setSecondaryPanelData({
      type: ESecondaryDetailsPanelType.CUSTOMER as any,
      id: customerData.id,
    });
  };

  const onSubTitleClick = useCallback((data: IGanttSubTitle) => {
    if (!data?.value?.id) return;
    setSecondaryPanelData({
      type: data.type as ESecondaryDetailsPanelType,
      id: data?.value?.id,
      driverId: data?.driverId ?? null,
    });
  }, []);

  useEffect(() => {
    if (getDispatchPanelAutoOpenDriver) {
      handleDriverPanel(getDispatchPanelAutoOpenDriver.id);
    }
  }, [getDispatchPanelAutoOpenDriver]);

  const getBulkOptimizationProps = () => {
    return {
      ...getBulkOptymizationInputs,
      driverIds: optimizationRequestResources.driverIds,
      tripIds: optimizationRequestResources.tripIds,
      driverFilters: {
        ...getBulkOptymizationInputs.driverFilters,
        sort: RECOMMEND_SORT_ENUMS.groupName,
        startDate: GANTT_DEFAULT_START_DATE,
      },
    };
  };

  return (
    <>
      <PageContainer>
        <Grid
          id="dispatch-grid-container"
          container
          width="100%"
          flexDirection="row"
          flexWrap="nowrap"
          height="100%"
        >
          <Grid
            item
            sx={{
              width: secondaryPanelData
                ? `calc(100% - ${PANEL_FIXED_WIDTH}px)`
                : '100%',
            }}
          >
            <Grid
              container
              flexDirection="column"
              flexWrap="nowrap"
              height="100%"
              gap={SPACE}
            >
              <Grid item sx={getItemStyle(IPanelName.DRIVER)}>
                <GanttLayout
                  onTripView={handleTripViewPanel}
                  onLoadView={handleLoadViewPanel}
                  onCustomerView={handleCustomerPanel}
                  onDriverDetailView={handleDriverPanel}
                  onSubTitleClick={onSubTitleClick}
                />
              </Grid>
              <Grid item sx={getItemStyle(IPanelName.TRIP)}>
                <AvailableTripsLayout />
              </Grid>
            </Grid>
          </Grid>
          {secondaryPanelData && (
            <Grid item>
              <DispatchChildrenPanel />
            </Grid>
          )}
        </Grid>
      </PageContainer>
      {getBulkOptymizationInputs.startBulkOptymization && (
        <BulkOptymization
          {...(getBulkOptimizationProps() as any)}
          onClose={stopBulkOptymization}
        />
      )}
      <PreferenceSettingsPopup
        onClose={() => updatePreferenceSettings(false)}
        onPreferenceUpdate={() => {
          updatePreferenceSettings(false);
          onAfterPreferencesUpdate();
        }}
        sandboxId={currentSandboxId as string}
        showCloseIcon={true}
      />
      <Evaluation />
    </>
  );
};

export default observer(Dispatch);
