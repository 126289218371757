import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import FormDatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';

export default function CertificationForm({
  parentKey = '',
}: {
  parentKey?: string;
}) {
  const { control } = useFormContext();
  return (
    <Grid container rowSpacing={2} columnSpacing={4} sx={{ mt: 2 }}>
      <FormDatePicker
        control={control}
        name={`${parentKey}validFrom`}
        label={'Valid From*'}
        sizes={{ xs: 6 }}
        startOfDate
        iso
        disableFuture
      />
      <FormDatePicker
        control={control}
        name={`${parentKey}validTo`}
        label={'Valid Until*'}
        sizes={{ xs: 6 }}
        startOfDate
        iso
      />
    </Grid>
  );
}
