import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { terminalService } from '../../../../../api';
import { DetailsPanel } from '../../../../../common/DetailsPanel/DetailsPanel';
import { useTerminalPermission } from '../../../../../common/hooks/useTerminalPermission';
import { GetLogoDTO, TerminalContentDTO } from '../../../../../models';
import { ESecondaryDetailsPanelType } from '../../../../dispatch2/constants/types';
import {
  ETerminal3DotMenuType,
  ETerminalPanelTab,
} from '../../constants/types';
import { TerminalValidationSchema } from '../../constants/validationSchema';
import {
  ITerminalFormData,
  createTerminalHandler,
  fromTerminalContentDTOToFormData,
  updateTerminalHandler,
} from '../../utils/api.utils';
import TerminalDetailsForm from './TerminalDetailsForm';
import { TerminalDetailsPanelActionsSection } from './TerminalDetailsPanelActionsSection';

export interface TerminalDetailsPanelProps {
  data?: TerminalContentDTO | null;
  onClose: () => void;
  onUpdated?: (data: TerminalContentDTO) => void;
  onDeleted?: (data: TerminalContentDTO) => void;
  onCreated?: (data: TerminalContentDTO) => void;
  defaultTab?: ETerminalPanelTab;
  autoExpanded?: boolean;
  isGlobal?: boolean;
  on3DotMenuActionCallback?: (params: {
    type: ETerminal3DotMenuType;
    data: TerminalContentDTO;
    response: any;
  }) => void | Promise<void>;
}

export const TerminalDetailsPanel = observer(
  ({
    data = null,
    onClose,
    onUpdated,
    onDeleted,
    onCreated,
    defaultTab = ETerminalPanelTab.OVERVIEW,
    autoExpanded = false,
    isGlobal = false,
    on3DotMenuActionCallback,
  }: TerminalDetailsPanelProps): JSX.Element => {
    const userStorage = StorageManager.getUser();
    const { hasTerminalRemovePermission } = useTerminalPermission();
    const [terminalContentDTO, setTerminalContentDTO] =
      useState<TerminalContentDTO | null>(null);

    const [selectedTab, setSelectedTab] =
      useState<ETerminalPanelTab>(defaultTab);

    const isEdit = !!data?.id;
    const onFullscreen = (isFullscreen: boolean): void => {
      //set default tab when fullscreen
      setSelectedTab(
        isFullscreen && isEdit ? ETerminalPanelTab.OVERVIEW : defaultTab
      );
    };
    const entity = 'Terminal';

    const panelTitle = useMemo(() => {
      return isEdit
        ? `Terminal: ${terminalContentDTO?.companyName || ''}`
        : 'Add New Terminal';
    }, [isEdit, terminalContentDTO]);

    const getValidationSchemaByTab = useCallback(
      (tab: ETerminalPanelTab | string): yup.AnyObjectSchema | null => {
        if (tab == ETerminalPanelTab.OVERVIEW) {
          return TerminalValidationSchema;
        }
        return null;
      },
      []
    );

    const onUpdateHandler = async (tab: ETerminalPanelTab, formData: any) => {
      if (!terminalContentDTO) return;
      if (tab == ETerminalPanelTab.OVERVIEW) {
        if (formData.terminalLogoFile) {
          const requestData = new GetLogoDTO();
          requestData.organizationId = userStorage.organizationId;
          requestData.terminalId = formData.id;

          await terminalService.postTerminalLogoData(
            requestData,
            formData.terminalLogoFile
          );
        }
        const updatedTerminal = await updateTerminalHandler({
          formData,
        });
        if (updatedTerminal instanceof TerminalContentDTO) {
          setTerminalContentDTO(updatedTerminal);
          onUpdated?.(updatedTerminal);
        }
      }
    };

    const actionsRenderer = () => {
      return terminalContentDTO && hasTerminalRemovePermission ? (
        <TerminalDetailsPanelActionsSection
          terminalDTO={terminalContentDTO}
          on3DotMenuActionCallback={(params: {
            type: ETerminal3DotMenuType;
            data: TerminalContentDTO;
            response: any;
          }) => {
            if (params.type === ETerminal3DotMenuType.DELETE) {
              onClose();
            }
            on3DotMenuActionCallback?.(params);
          }}
        />
      ) : (
        <></>
      );
    };

    const onCancel = () => {
      //
    };

    const onCreateHandler = async (formData: ITerminalFormData) => {
      const createdTerminal = await createTerminalHandler({
        formData,
      });
      if (createdTerminal instanceof TerminalContentDTO) {
        onCreated?.(createdTerminal);
      }
    };

    const panelData = useMemo(() => {
      return fromTerminalContentDTOToFormData(terminalContentDTO);
    }, [isEdit, terminalContentDTO]);

    //Side effect begin
    useEffect(() => {
      setTerminalContentDTO(data);
    }, [data?.id]);
    //Side effect end

    return (
      <>
        <DetailsPanel
          panelType={ESecondaryDetailsPanelType.TERMINAL}
          data={panelData}
          actionButtonLabel={isEdit ? 'Save changes' : `Create`}
          entity={entity}
          panelTitle={panelTitle}
          onClose={onClose}
          onCancel={onCancel}
          onUpdate={(userData) => onUpdateHandler(selectedTab, userData)}
          onDelete={onDeleted}
          onCreate={onCreateHandler}
          isGlobal={isGlobal}
          autoExpanded={autoExpanded}
          contentRenderer={() => (
            <TerminalDetailsForm
              selectedTab={selectedTab}
              terminalDTO={terminalContentDTO}
              isCreatePanel={!isEdit}
            />
          )}
          validationSchema={getValidationSchemaByTab(selectedTab)}
          {...(selectedTab !== ETerminalPanelTab.OVERVIEW && {
            footerRenderer: () => undefined,
          })}
          onFullscreen={onFullscreen}
          actionsRenderer={actionsRenderer}
        />
      </>
    );
  }
);
