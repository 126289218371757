import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { t } from 'i18next';
import { isString } from 'lodash';
import { PdfViewer } from '../../../../components_v2/ui-kit/file-viewer';
import { previewFile } from '../../../../utils/Doc';
/* @TODO this file should be rewritten */

const styles = {
  main: {
    height: '100%',
    width: '100%',
    display: 'flex',
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '6px',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #1F2E3D',
    borderRadius: '6px',
    fontSize: 48,
    textTransform: 'uppercase',
    color: '#ffffff',
  },
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    borderRadius: '6px',
    height: '100%',
  },
  btn: {
    position: 'absolute',
    bottom: '14px',
    right: '16px',
    zIndex: 9,
    fontSize: '10px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D',
    padding: '4px 10px',
    borderRadius: '6px 0px',
    color: '#ffffff',
    backgroundColor: '#1F2E3D',
    '&:hover': {
      backgroundColor: '#1F2E3D',
    },
  },
};

const getFileType = (file: any) => {
  const { src, fileName } = file;
  if (fileName) {
    const index = fileName.lastIndexOf('.') + 1;
    return fileName.slice(index, fileName.length);
  }

  if (isString(src)) {
    const startIndex = src.indexOf('/') + 1;
    const endIndex = src.indexOf(';');
    return src.slice(startIndex, endIndex);
  }
  return '';
};

const encodeBase64Image = (image: any) => {
  if (!image || !image.contentType || !image.fileContent) return undefined;
  if (image.contentType && image.contentType.includes('jpeg'))
    return `data: image/jpeg;base64, ${image.fileContent}`;
  return `data: image/png;base64, ${image.fileContent}`;
};

interface IProps {
  file: any;
  allowPreview?: boolean;
  scale?: number;
  styles?: {};
}

const FileViewer = ({
  file,
  allowPreview = false,
  scale,
  styles: stylesProps,
}: IProps) => {
  const fileType = file && getFileType(file);

  const onPreview = () => {
    previewFile(file);
  };

  if (!file) {
    return (
      <Box sx={styles.spinner}>
        <CircularProgress />
      </Box>
    );
  }

  if (file.src && ['pdf'].includes(fileType.toLowerCase())) {
    return (
      <PdfViewer
        file={file.src}
        containerStyle={{ height: '340px', width: '280px' }}
        pdfStyles={{
          minHeight: '300px',
          height: '300px',
          width: '280px',
          overflowX: 'hidden',
        }}
        actionsConfig={{
          hideZoom: true,
          hideRotate: true,
          navigateActionContainerStyles: { width: '100%' },
        }}
      />
    );
  }

  if (['png', 'jpeg', 'jpg', 'pdf'].includes(fileType.toLowerCase())) {
    const isImage = ['png', 'jpeg', 'jpg'].includes(fileType.toLowerCase());
    const src = file.src ? file.src : encodeBase64Image(file);
    return (
      <Box
        sx={{
          ...styles.container,
          overflow: 'hidden',
        }}
      >
        {isImage && <img alt={`img - ${file.id}`} src={src} />}
        {!isImage && (
          <>
            {file.fileContent ? (
              <PdfViewer
                file={`data:application/pdf;base64,${file.fileContent}`}
                containerStyle={{ height: '396px', width: '480px' }}
                allowActions={false}
              />
            ) : (
              <canvas height="396" width="306"></canvas>
            )}
          </>
        )}
        {allowPreview && (
          <Button sx={styles.btn} onClick={onPreview}>
            {t('clickToExpand')}
          </Button>
        )}
      </Box>
    );
  }

  return (
    <Box style={styles.main}>
      <Box sx={styles.container}>{fileType} </Box>
    </Box>
  );
};

export default FileViewer;
