import Grid from '@mui/material/Grid';
import Address from './Address';
import AddressManual from './AddressManual';

import { SxProps } from '@mui/system';
import React from 'react';
import { Controller } from 'react-hook-form';
import { addressType } from './constants';

const FormAddress: React.FC<{
  locationData?: any;
  name?: string | undefined;
  control?: any;
  sizes?: {
    xs: number;
  };
  required?: boolean;
  onChangeCallback?: (data: addressType) => void;
  label?: string;
  disabled?: boolean;
  disableAddressLine?: boolean;
  includeTimezone?: boolean;
  isManual?: boolean;
  validateAddress?: boolean;
  singleInput?: boolean;
  textInputSxProps?: SxProps;
  style?: SxProps;
  showOnlyState?: boolean;
  autoFocus?: boolean;
}> = ({
  control,
  name = '',
  sizes = { xs: 12 },
  required = false,
  onChangeCallback,
  label,
  disabled,
  disableAddressLine,
  locationData,
  includeTimezone,
  isManual = false,
  validateAddress = true,
  singleInput = false,
  textInputSxProps,
  style = { marginBottom: 1 },
  showOnlyState = false,
  autoFocus = true,
}) => {
  return (
    <Controller
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <Grid xs={12} item style={style}>
            {!isManual ? (
              <Address
                onChange={(data: addressType) => {
                  onChange(data);
                  {
                    onChangeCallback && onChangeCallback(data);
                  }
                }}
                onlyState={showOnlyState}
                data={value}
                errors={error}
                required={required}
                label={label}
                disabled={disabled}
                disableAddressLine={disableAddressLine}
                includeTimezone={includeTimezone}
                validateAddress={validateAddress}
                textInputSxProps={textInputSxProps}
                autoFocus={autoFocus}
              />
            ) : (
              <AddressManual
                onChange={(data: addressType) => {
                  onChange(data);
                  {
                    onChangeCallback && onChangeCallback(data);
                  }
                }}
                data={value}
                errors={error}
                required={required}
                label={label}
                disabled={disabled}
                disableAddressLine={disableAddressLine}
                includeTimezone={includeTimezone}
                singleInput={singleInput}
                textInputSxProps={textInputSxProps}
              />
            )}
          </Grid>
        );
      }}
      name={name}
      control={control}
      defaultValue={locationData}
    />
  );
};

export default React.memo(FormAddress);
