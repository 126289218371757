export const AuthIntro: Record<
  string,
  {
    intro: string;
    intro1: string;
    list: string[];
    outro: string;
  }
> = {
  DAT: {
    intro: 'LoadOps and DAT bring you the best-in-class carrier TMS',
    intro1: 'With the LoadOps + DAT partnership, you will:',
    list: [
      'Find the most profitable loads from DAT to grow your business with automatic load matching.',
      'Manage every stage of the load lifecycle from confirmation to dispatch in one simple interface.',
      `Scan and upload documents directly into a central hub from wherever a driver may be and access them instantly when it’s time to invoice.`,
      'Save time on unnecessary emails and calls and show customers the real-time status of their loads with a unique tracking page.',
    ],
    outro:
      'Get all of this and more with a 30-day risk-free trial. Sign up today to see why DAT has chosen LoadOps as its preferred carrier TMS.',
  },
  TAFS: {
    intro: 'Get paid faster with LoadOps and TAFS',
    intro1: 'With the LoadOps + TAFS partnership, you will:',
    list: [
      `Improve cash flow and save time by sending invoice details and supporting documents directly to TAFS all within LoadOps.`,
      `Manage every stage of the load lifecycle from confirmation to dispatch in one simple interface.`,
      `Scan and upload documents directly into a central hub from wherever a driver may be and access them instantly when it’s time to invoice.`,
      `Save time on unnecessary emails and calls and show customers the real-time status of their loads with a unique tracking page.`,
    ],
    outro:
      'Get all of this and more with a 30-day risk-free trial. Sign up today to see how the LoadOps + TAFS partnership will simplify your daily operations.',
  },
  APEX: {
    intro: 'Get paid faster with LoadOps and APEX Factoring',
    intro1: 'With the LoadOps + APEX partnership, you will:',
    list: [
      `Improve cash flow and save time by sending invoice details and supporting documents directly to APEX all within LoadOps`,
      `Manage every stage of the load lifecycle from confirmation to dispatch in one simple interface.`,
      `Scan and upload documents directly into a central hub from wherever a driver may be and access them instantly when it’s time to invoice.`,
      `Save time on unnecessary emails and calls and show customers the real-time status of their loads with a unique tracking page.`,
    ],
    outro:
      'Get all of this and more with a 30-day risk-free trial. Sign up today to see how the LoadOps + APEX partnership will simplify your daily operations.',
  },
};
