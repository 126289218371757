import { TerminalShort } from '../../common/modelsShort';

export class TrailerSummaryDTO {}
export class PaginatedTrailerList {}
export class PaginatedTrailerListResponse {}
export class TrailerStaticResponse {}
export class DriverTrailerAssignResponse {}
export class TrailerResponse {
  terminal?: TerminalShort | null;
  terminalId?: string | null;
}

export class TrailerAlertSummaryDTO {
  id!: string;
  activeLoad!: number;
  organizationId!: number;
  trailerId!: number;
  maintenanceTypeId!: string;
  maintenanceType!: string;
  lastConducted!: Date;
  nextDue!: Date;
  itemName!: string;
  safetyIssueTypeWithDaysAndMiles?: {
    days: number;
    issueType: string;
    miles: number;
  };
}

export class DefaultTrailersTypesList {
  id!: string;
  organizationId!: string;
  trailerMaintenances!: DefaultTrailersTypesListSummaryDTO[];
}
export class DefaultTrailersTypesListSummaryDTO {
  id!: string;
  maintenanceTypeId!: string;
  maintenanceType!: string;
  warningDuration!: string;
  frequency!: string;
  frequencyValue!: number;
  recipient!: string;
  dispatchAllowedIfCertificationExpires!: boolean;
  warningDurationByDays!: number;
  alertByMiles!: number;
  frequencyByMiles!: number;
  iconPicker!: string;
}
export class DefaultTrailersTypesDictionarySummaryDTO {
  id!: string;
  itemName!: string;
  isDeduction!: boolean;
  itemCode!: string;
  description!: string;
  isCustom!: boolean;
  iconName!: string;
  createdBy!: string;
  createDate!: Date;
}
export class DefaultTrailersTypesSummaryDTO {
  id!: string;
  maintenanceTypeId!: string;
  maintenanceType!: string;
  itemName!: string;
}
