import {
  VisitorUpdateRequest,
  VisitorUpdateResponse,
} from '../../models/DTOs/Hubspot/Requests';
import { Service } from './Service';
import { ServiceError } from './helperTypes';
export abstract class IHubspotService extends Service {
  abstract visitorUpdate(
    requestData: VisitorUpdateRequest
  ): Promise<VisitorUpdateResponse | null | ServiceError>;
}
