import { Moment } from 'moment';
import { ExpenseEntityType, PaymentDetails } from '..';
import StorageManager from '../../../StorageManager/StorageManager';
import { getEmailMessageAndSubject } from '../../../subPages/payStatement/utils/ActionsSection';
import { setUtcDate } from '../../../utils';
import { DateTimezoneUtils } from '../../../utils/Timezone.utils';
import { QueryParams } from '../commonMixed/QueryParams';
import {
  PayStatementData,
  PayStatementType,
  PayStatementsEntityTypeEnum,
  PayStatementsTypeEnum,
} from './Payments';

export class GetPayStatementDetailsByIdRequest extends QueryParams {
  constructor(
    private id: number,
    private entityId: number,
    private seqNumber?: number,
    private entityType?: string
  ) {
    super();
    this.id = id;
    this.entityId = entityId;
    this.seqNumber = seqNumber;
    this.entityType = entityType?.toUpperCase?.();
  }
}
export class GetPayStatementListRequest extends QueryParams {
  sort?: string;
  pageNumber!: number;
  pageSize!: number;
  gridColumnMetadataList?: string[];
  entityType?: string;
  tractorStatus?: string;
  userStatus?: string;
  dispatcherIds?: number[];
  driverIds?: number[];
  tractorIds?: number[];
  status?: PayStatementStatusEnum;
  startDate?: string;
  endDate?: string;
  terminalIds?: string[];
  seqNumberList?: number[];
  seqNumberFilters?: number[];

  constructor(data: any, entity: PayStatementsEntityTypeEnum) {
    super();
    this.sort = data.sort;
    this.pageNumber = data.pageNumber;
    this.pageSize = data.pageSize;
    this.gridColumnMetadataList = data.gridColumnMetadataList;
    this.entityType = data.entityType;
    this.tractorStatus = data.tractorStatus;
    this.userStatus = data.userStatus;
    this.dispatcherIds = data.dispatcherIds;
    this.driverIds = data.driverIds;
    this.tractorIds = data.tractorIds;
    this.startDate = DateTimezoneUtils.toNoTimeZoneDateString(data.startDate);
    this.endDate = DateTimezoneUtils.toNoTimeZoneDateString(
      data.endDate,
      undefined,
      false
    );
    this.terminalIds = data.terminalIds;
    this.seqNumberList = data.seqNumberList;
    if (entity !== PayStatementsEntityTypeEnum.pendingPayment) {
      this.status = PayStatementStatusMap[entity];
    }
  }
}

export class GetBulkUpdateNonTripPaymentsRequest extends QueryParams {
  driverPaysDTO?: CreateNonTripPaymentRequest[];
  tractorPaysDTO?: CreateNonTripPaymentRequest[];
  payItemDTOs?: CreateNonTripPaymentRequest[];
  constructor(
    data: PaymentDetails[],
    payStatementData: PayStatementData,
    entityType: PayStatementsEntityTypeEnum
  ) {
    super();
    this.payItemDTOs = data.map(
      (item: PaymentDetails) =>
        new CreateNonTripPaymentRequest(item, payStatementData, entityType)
    );
  }
}

export enum PayStatementStatusEnum {
  IN_REVIEW = 'IN_REVIEW',
  CLOSED = 'CLOSED',
  REVIEWED = 'REVIEWED',
}

const PayStatementStatusMap: { [key: string]: PayStatementStatusEnum } = {
  inReviewStatement: PayStatementStatusEnum.IN_REVIEW,
  closedStatement: PayStatementStatusEnum.CLOSED,
  [PayStatementsEntityTypeEnum.reviewedStatement]:
    PayStatementStatusEnum.REVIEWED,
};

export type PayStatementListRequestType = {
  sort: string;
  pageNumber: number;
  pageSize: number;
  gridColumnMetadataList?: string[];
  status?: PayStatementStatusEnum;
  startDate?: string;
  endDate?: string;
};

export class CombinedNamesListRequest extends QueryParams {
  constructor(
    private nameFilter?: string,
    private pageNumber?: number,
    private pageSize?: number,
    private entityType?: string,
    private terminalIds?: string[],
    private excludeStatusList?: string[]
  ) {
    super();
    this.nameFilter = nameFilter;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.entityType = entityType;
    this.terminalIds = terminalIds;
    this.excludeStatusList = excludeStatusList;
  }
}

export class SettlementIdFilterRequest extends QueryParams {
  pageNumber?: number;
  pageSize?: number;
  entityType?: string;
  status?: string;
  terminalIds?: string[];
  seqNumberFilter?: number;
  constructor(dto: any) {
    super();
    this.pageNumber = dto.pageNumber;
    this.pageSize = dto.pageSize;
    this.terminalIds = dto.terminalIds;
    this.seqNumberFilter = dto.seqNumberFilter;
    this.entityType = dto.filters?.typeFilter?.id;
    this.status = PayStatementStatusMap[dto.entity];
  }
}

export class GetPaymentsListByIdRequest extends QueryParams {
  sort?: string;
  settlementId?: number;
  endDate?: Moment;
  entityType?: string;
  entityId?: number;
  constructor(
    data: any,
    entityType: PayStatementsEntityTypeEnum,
    isFetchingList: boolean
  ) {
    super();
    this.sort = '-payCategoryName';
    this.entityId = data?.payStatementId;
    this.settlementId =
      entityType !== PayStatementsEntityTypeEnum.pendingPayment
        ? data.id
        : undefined;

    if (!isFetchingList) {
      this.endDate =
        entityType !== PayStatementsEntityTypeEnum.pendingPayment
          ? data.endDate
          : undefined;
    }
    this.entityType = data?.payStatementType?.toUpperCase?.();
  }
}

export type EntitesType = 'DRIVER' | 'DISPATCHER' | 'TRACTOR';

const isDriverEntityTractorMapped = (data: any, payStatement: any) => {
  return (
    payStatement?.payStatementType === PayStatementsTypeEnum.driver &&
    data?.payToDetails?.entityId
  );
};
export class CreateNonTripPaymentRequest extends QueryParams {
  id?: number;
  amount: number;
  description: string;
  driverId?: number;
  tractorId?: number;
  expenseCategoryId: string;
  expenseCategoryKey: string;
  fuelQuantity: string;
  fuelType: string;
  isManuallyAdded: boolean;
  loadId: string;
  payCategory: string;
  payCategoryName: string;
  payDate: string | null;
  truckStop: string;
  state: string;
  settlementId?: number;
  expenseId?: string;
  entityType?: EntitesType;
  entityId?: number;
  payToEntityId?: number;
  payToEntityType?: ExpenseEntityType;
  constructor(
    data: any,
    payStatement: PayStatementData,
    entityType: PayStatementsEntityTypeEnum
  ) {
    super();
    this.id = data.id;
    this.description = data.description;
    this.expenseCategoryId = data.expenseCategoryObj?.id;
    this.expenseCategoryKey = data.expenseCategoryObj?.itemCode;
    this.fuelQuantity = data.fuelQuantity;
    this.fuelType = data.fuelTypeObj?.key;
    this.isManuallyAdded = true;
    this.loadId = data.loadId;
    this.payCategory = data.payCategory;
    this.entityType = isDriverEntityTractorMapped(data, payStatement)
      ? data?.payToDetails?.entity?.toUpperCase?.()
      : (payStatement?.payStatementType?.toUpperCase?.() as EntitesType);
    this.entityId = isDriverEntityTractorMapped(data, payStatement)
      ? data?.payToDetails?.entityId
      : payStatement.payStatementId;
    this.payToEntityId = isDriverEntityTractorMapped(data, payStatement)
      ? payStatement.payStatementId
      : undefined;
    this.payToEntityType = isDriverEntityTractorMapped(data, payStatement)
      ? payStatement.payStatementType
      : undefined;

    switch (data.payCategory) {
      case 'REIMBURSEMENT':
        this.payCategoryName = data.payCategoryName || 'Reimbursement';
        break;

      case 'DEDUCTION':
        this.payCategoryName = data.payCategoryName || 'Deduction';
        break;

      case 'PAYMENT':
        this.payCategoryName = data.payCategoryName || 'Payment';
        break;
      case 'NON_TAXABLE_PAYMENT':
        this.payCategoryName = data.payCategoryName || 'Non-Taxable Payment';
        break;

      default:
        this.payCategoryName = data.payCategoryName;
        break;
    }

    this.amount =
      this.payCategoryName === 'Scheduled Deduction' ||
      this.payCategoryName === 'Deduction'
        ? -Math.abs(data.amountToShow)
        : Math.abs(data.amountToShow);
    this.payDate = data?.payDate;
    this.truckStop = data.truckStop;
    this.state = data.state?.value;
    this.settlementId =
      entityType !== PayStatementsEntityTypeEnum.pendingPayment
        ? payStatement.id
        : undefined;
    this.expenseId = data.expenseId;
  }
}

export class BulkSettlementCreateRequest extends QueryParams {
  startDate?: Date | string;
  endDate?: Date | string;
  entityTypeAndEntityIdList?: { first: string; second: number }[];
  constructor(
    payments?: Array<PayStatementType>,
    startDate?: Date | string,
    endDate?: Date | string
  ) {
    super();
    this.startDate = startDate;
    this.endDate = endDate;
    this.entityTypeAndEntityIdList = [];
    payments?.forEach?.((e) => {
      this.entityTypeAndEntityIdList?.push({
        first: e?.entity,
        second: e?.entityId,
      });
    });
  }
}

export class DeleteNonTripPaymentByIdRequest extends QueryParams {
  constructor(private ids: number[]) {
    super();
    this.ids = ids;
  }
}

export type CreateInReviewPayStatementType = {
  ids: number[];
  endDate?: string;
  seqNumber?: number;
  startDate?: string;
  status: string;
  updateDate: Moment;
  createDate?: string;
};

export class CreateInReviewPayStatementRequest extends QueryParams {
  driverEmail?: string;
  endDate: Moment;
  startDate: Moment;
  status: string;
  updateDate?: Moment;
  entityId?: number;
  entityType?: ExpenseEntityType;
  payItemIds?: Array<number>;
  entityName?: string;
  email?: string;
  constructor(data: any, payStatement: any) {
    super();
    this.email = undefined;
    this.endDate = data.endDate;
    this.startDate = data.startDate;
    this.status = data.status;
    this.updateDate = setUtcDate();
    this.entityType = payStatement?.payStatementType;
    this.entityName = payStatement?.payStatementName;
    this.payItemIds = data?.ids;
    this.entityId = payStatement.payStatementId;
  }
}
export class ClosePayStatementRequest extends QueryParams {
  id: number;
  status: PayStatementStatusEnum;
  paymentDate?: Date;
  constructor(payStatement: any) {
    super();
    this.id = payStatement.id;
    this.status = PayStatementStatusEnum.CLOSED;
    this.paymentDate = payStatement?.paymentDate;
  }
}

export class SettlementStatusChangeRequest extends QueryParams {
  id: number;
  status: PayStatementStatusEnum;
  paymentDate?: Date;
  constructor(payStatement: any) {
    super();
    this.id = payStatement.id;
    this.status = payStatement?.toStatus;
    this.paymentDate = payStatement?.paymentDate;
  }
}

export class RemoveEditInReviewPaymentsRequest extends CreateInReviewPayStatementRequest {
  id: number;
  seqNumber?: number;
  createDate?: string;
  constructor(data: any, payStatement: PayStatementData) {
    super(data, payStatement);
    this.id = payStatement.id;
    this.seqNumber = payStatement.seqNumber;
    this.createDate = payStatement.createDate;
  }
}

export class DeleteInReviewPayStatementRequest extends QueryParams {
  settlementId?: number[];
  constructor(payStatements: PayStatementData[] | PayStatementType[]) {
    super();
    this.settlementId = [];
    payStatements?.forEach?.((payStatement) => {
      this.settlementId?.push?.(payStatement?.id);
    });
  }
}

export class DownloadBulkPayStatement extends QueryParams {
  settlementIds?: number[];
  constructor(payStatements: PayStatementType[]) {
    super();
    this.settlementIds = [];
    payStatements?.forEach?.((payStatement) => {
      this.settlementIds?.push?.(payStatement?.id);
    });
  }
}
export class ReopenPayStatementDTO extends QueryParams {
  id: number;
  status: PayStatementStatusEnum;
  constructor(payStatement: any, status: PayStatementStatusEnum) {
    super();
    this.id = payStatement.id;
    this.status = status ?? PayStatementStatusEnum.IN_REVIEW;
  }
}
export class GetPayStatementPdfRequest extends QueryParams {
  settlementId: number;
  entityId?: number;
  entityType?: ExpenseEntityType;
  constructor(payStatement: any) {
    super();
    this.settlementId = payStatement.id;
    this.entityId = payStatement?.payStatementId;
    this.entityType = payStatement?.payStatementType;
  }
}

export class SendSettlementEmailRequest extends QueryParams {
  combinePdfDocuments!: boolean;
  html!: boolean;
  emailSendingRequestDTO!: {
    cc: string[];
    driverPay?: { settlementId: number };
    tractorPay?: { settlementId: number };
    dispatcherPay?: { settlementId: number };
    message: string;
    subject: string;
    to: string[];
  }[];
  constructor(data: any, payStatementData: PayStatementData) {
    super();
    this.html = true;
    this.combinePdfDocuments = false;

    if (payStatementData.payStatementType === PayStatementsTypeEnum.driver) {
      this.emailSendingRequestDTO = [
        {
          cc: data.cc,
          to: data.to,
          driverPay: { settlementId: payStatementData?.id },
          message: data.message,
          subject: data.subject,
        },
      ];
    }

    if (payStatementData.payStatementType === PayStatementsTypeEnum.tractor) {
      this.emailSendingRequestDTO = [
        {
          cc: data.cc,
          to: data.to,
          tractorPay: { settlementId: payStatementData?.id },
          message: data.message,
          subject: data.subject,
        },
      ];
    }

    if (
      payStatementData.payStatementType === PayStatementsTypeEnum.dispatcher
    ) {
      this.emailSendingRequestDTO = [
        {
          cc: data.cc,
          to: data.to,
          dispatcherPay: { settlementId: payStatementData?.id },
          message: data.message,
          subject: data.subject,
        },
      ];
    }
  }
}

export class BulkSendSettlementEmailRequest extends QueryParams {
  combinePdfDocuments!: boolean;
  html!: boolean;
  emailSendingRequestDTO!: {
    cc: string[];
    driverPay?: { settlementId: number };
    tractorPay?: { settlementId: number };
    dispatcherPay?: { settlementId: number };
    message: string;
    subject: string;
    to: string[];
  }[];
  constructor(data: any, payStatements: PayStatementType[]) {
    super();
    this.html = true;
    this.combinePdfDocuments = false;
    this.emailSendingRequestDTO = [];
    const user = StorageManager.getUser();
    payStatements?.forEach?.((payStatementData) => {
      if (payStatementData?.email) {
        const mailBody = getEmailMessageAndSubject(
          new PayStatementData(payStatementData),
          `${user?.firstname} ${user?.lastname}`,
          user?.organizationName,
          data?.getIsTerminalEnabled
        );
        const emailRequestDTO = {
          cc: data.cc,
          to: [payStatementData?.email],
          driverPay: { settlementId: payStatementData?.id },
          message: mailBody.message,
          subject: mailBody.subject,
        } as any;
        if (payStatementData.entity === PayStatementsTypeEnum.tractor) {
          delete emailRequestDTO?.driverPay;
          emailRequestDTO.tractorPay = { settlementId: payStatementData?.id };
        }
        if (payStatementData.entity === PayStatementsTypeEnum.dispatcher) {
          delete emailRequestDTO?.driverPay;
          delete emailRequestDTO?.tractorPay;
          emailRequestDTO.dispatcherPay = {
            settlementId: payStatementData?.id,
          };
        }
        this.emailSendingRequestDTO?.push?.(emailRequestDTO);
      }
    });
  }
}
