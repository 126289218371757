import { Box, Skeleton, Stack } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { NestedDropDown } from '../../common/NestedDropDown/NestedDropDown';
import PageContainer from '../../common/Wrappers/PageContainer';
import { useLoadsearchLane } from '../../contexts/LoadsearchLaneContext';
import { useStores } from '../../store/root.context';
import { LoadboardDataGrid } from './LoadboardDataGrid/LoadboardDataGrid';
import { LoadboardViewsSection } from './components/ViewSection/LoadboardViewsSection';
import { ELoadBoardEntity } from './constants';
import { ISelectedDriverDetails } from './constants/types';

const Loadboards = observer((): JSX.Element => {
  const {
    loadboardStore: {
      fetchLoadboardDriverGapData,
      getDriverGapData,
      setSelectedDriver: setDriverDetails,
      selectedDriver: selectedDriverDetails,
      getLoadboardAccounts,
      setTriggerSearch,
    },
  } = useStores();
  const [isDriverSelected, setSelectedDriver] = useState<boolean>(false);

  const { currentView } = useLoadsearchLane();

  const onSelectedDriverHandler = (
    data: ISelectedDriverDetails,
    triggerSearch = false
  ) => {
    if (data?.label) {
      setDriverDetails(data);
      setSelectedDriver(true);
    } else setSelectedDriver(false);
    setTriggerSearch(triggerSearch);
  };

  const onClearSelectedDriverHandler = () => {
    setDriverDetails(null);
    setSelectedDriver(false);
  };

  useEffect(() => {
    fetchLoadboardDriverGapData();
  }, []);

  return (
    <PageContainer>
      <Stack width="100%" height="100%">
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="flex-end"
        >
          <Box
            sx={{
              position: 'relative',
              width: 'calc(100% - 200px)',
              mb: '15px',
              flex: '1 auto',
            }}
          >
            <LoadboardViewsSection />
          </Box>
          <Box mb="15px">
            {currentView?.entity !== ELoadBoardEntity.pendingLoad &&
              currentView?.entity !== ELoadBoardEntity.bookmark &&
              currentView?.entity !== ELoadBoardEntity.booked && (
                <NestedDropDown
                  getLoadboardAccounts={getLoadboardAccounts}
                  menuItemsData={getDriverGapData}
                  variant="contained"
                  onSelectedDriverHandler={onSelectedDriverHandler}
                  onClearSelectedDriverHandler={onClearSelectedDriverHandler}
                />
              )}
          </Box>
        </Stack>
        {currentView?.entity ? (
          <LoadboardDataGrid
            selectedDriverDetails={selectedDriverDetails}
            isDriverSelected={isDriverSelected}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <Skeleton variant="rectangular" height={'80vh'} width={'100%'} />
          </Box>
        )}
      </Stack>
    </PageContainer>
  );
});

export default Loadboards;
