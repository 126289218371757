import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { PermissionProps } from '../types/types';
import { getHasPermission } from '../utils/helperUtils';
import * as permissionUtils from '../utils/permissionUtils';
import { PermissionOutput } from './PermissionOutput';

export const Permission = ({
  denied,
  deniedSubscription,
  includes,
  subscriptionPermits,
  contains,
  containsPermit,
  execute = false,
  useDefault = false,
  prevent = false,
  children,
  render,
  roleCodes,
}: PropsWithChildren<PermissionProps>): JSX.Element => {
  const { permissions, webFeaturesList } = permissionUtils.getCurrentUser();
  const [hasPermission, setHasPermission] = useState<boolean>(execute);

  const init = useCallback((): void | JSX.Element => {
    const _hasPermission = getHasPermission({
      containsPermit,
      subscriptionPermits,
      includes,
      contains,
      roleCodes,
    });
    setHasPermission(_hasPermission);
  }, [includes, subscriptionPermits, contains, containsPermit, roleCodes]);

  useEffect(() => {
    init();
  }, [
    denied,
    deniedSubscription,
    includes,
    subscriptionPermits,
    contains,
    containsPermit,
    permissions,
    webFeaturesList,
    init,
    roleCodes,
  ]);

  return (
    <PermissionOutput
      denied={denied}
      deniedSubscription={deniedSubscription}
      hasPermission={hasPermission}
      useDefault={useDefault}
      render={render}
      prevent={prevent}
    >
      {children}
    </PermissionOutput>
  );
};
