import { AddBox } from '@mui/icons-material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { Box, IconButton, Popover, Stack, Theme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useLoadAiPermission } from '../../.../../../../../../common/hooks/useLoadaiPermission';
import OptymLogo from '../../../../../../_assets/static/svgs/OptymLogo';
import { loadService } from '../../../../../../api';
import { ELoadStatus } from '../../../../../../common/LoadTabPanel/constants/constants';
import { useLoadPermission } from '../../../../../../common/hooks/useLoadPermission';
import { LOADAI_ELEMENTS } from '../../../../../../constants/elementIds';
import { OPTIMIZATION_SOURCES } from '../../../../../../constants/gantt/gantt.const';
import { useStores } from '../../../../../../store/root.context';
import ButtonImproved from '../../../../../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';
import { getStyledManualDriverSelection } from '../ManualDriverSelection/styles';

export const ManualTripSelection = observer(
  ({ params }: { params: GridRenderCellParams }) => {
    const {
      dispatch2TripsStore: { changeStatusManualDriverSelection },
      dispatch2GlobalStore: {
        gapForTripAssignment,
        setTripAssignmentForGap,
        startBulkOptymization,
      },
      evaluationStore: { startEvaluation, setSource, setTripToAssignDetails },
      bulkOptymizationStore: {
        setOptimizationSource,
        setOptimizationRequestResources,
      },
    } = useStores();
    const { hasEngineEvaluationPermission, hasBulkOptimizationPermission } =
      useLoadAiPermission();
    const { hasLoadEditPermission } = useLoadPermission();
    const [apptDateMissingWarning, setIsApptDateMissingWarning] =
      useState<boolean>(false);

    const styles = getStyledManualDriverSelection();
    const popupState = usePopupState({
      variant: 'popover',
      popupId: 'ManualTripSelection-AddBox',
    });
    const customBindTrigger = bindTrigger(popupState);

    const onSubmitHandler = async ({
      event,
      toStatus,
    }: {
      event: MouseEvent;
      toStatus: ELoadStatus;
    }) => {
      const isValidationFailed = await loadService.validateLoadStopsLocations(
        params?.row
      );
      if (isValidationFailed) return setIsApptDateMissingWarning(true);
      let autoPositioning = true;
      // for first gap and middle gap and end gap which has a prevLoadId
      if (
        (gapForTripAssignment?.nextLoadId?.length &&
          !gapForTripAssignment?.prevLoadId) ||
        gapForTripAssignment?.prevLoadId?.length
      )
        autoPositioning = false;
      changeStatusManualDriverSelection({
        loadAssignmentStatus: toStatus,
        terminalId: params.row?.terminal?.id,
        loadId: params.row.id,
        groupId: gapForTripAssignment?.driverGroupId as string,
        prevLoadId: gapForTripAssignment?.prevLoadId ?? null,
        autoPositioning,
        onSuccess: () => {
          setTripAssignmentForGap(null);
        },
      });
      event.stopPropagation();
    };

    const handleRunEvaluation = (event: React.MouseEvent) => {
      if (!hasEngineEvaluationPermission) return;
      setSource('DISPATCH');
      setTripToAssignDetails({
        terminalId: params.row?.terminal?.id,
        loadId: params.row.id,
      });
      setOptimizationRequestResources({
        driverIds: [gapForTripAssignment?.driverGroupId!],
        tripIds: [],
      });
      startEvaluation({
        driverGroupId: gapForTripAssignment?.driverGroupId,
        gapId: gapForTripAssignment?.nextLoadId,
        isGapSelected: true,
        resourceType: 'DRIVER',
        tripIds: [`${params.row?.id}`],
        targetPosition: { left: event.clientX, top: event.clientY },
      });
    };
    const handleTripRecommendation = () => {
      setOptimizationRequestResources({
        driverIds: [gapForTripAssignment?.driverGroupId!],
        tripIds: [],
      });
      setOptimizationSource(OPTIMIZATION_SOURCES.EVALUATION);
      startBulkOptymization();
    };

    return (
      <Box
        component={'div'}
        onClick={(event) => {
          event.stopPropagation();
        }}
        display={'flex'}
        gap="4px"
      >
        <AddBox color="primary" {...customBindTrigger} />
        <Box>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={styles.CustomOptionActions}
            >
              {hasBulkOptimizationPermission && (
                <ButtonImproved
                  startIcon={<OptymLogo />}
                  styleProps={styles.CustomOptionActionButton}
                  label={'Recommend Trips'}
                  variant={'outlined'}
                  onClick={handleTripRecommendation}
                />
              )}
              {hasLoadEditPermission && (
                <>
                  {' '}
                  <ButtonImproved
                    styleProps={styles.CustomOptionActionButton}
                    label={'Mark as Planned'}
                    variant={'outlined'}
                    onClick={(event: any) => {
                      onSubmitHandler({
                        event,
                        toStatus: ELoadStatus.ASSIGNMENT_PLANNED,
                      });
                    }}
                  />
                  <ButtonImproved
                    styleProps={styles.CustomOptionActionButton}
                    label={'Mark as Assigned'}
                    color={'primary'}
                    variant="contained"
                    onClick={(event: any) => {
                      onSubmitHandler({
                        event,
                        toStatus: ELoadStatus.OFFERED_TO_DRIVER,
                      });
                    }}
                  />
                </>
              )}
            </Stack>
          </Popover>
        </Box>

        {hasEngineEvaluationPermission && (
          <IconButton
            id={LOADAI_ELEMENTS.DRIVER_TRIP_EVALUATION}
            sx={(theme: Theme) => ({
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '6px',
              height: '28px',
              width: '34px',
            })}
            onClick={handleRunEvaluation}
          >
            <LightbulbOutlinedIcon
              color="primary"
              sx={{ width: '18px', height: '18px' }}
            />
          </IconButton>
        )}
        <DeletePopup
          open={apptDateMissingWarning}
          title={t('MissingApptDatesTitle')}
          body={t('MissingApptDatesBody')}
          subtitle={t('MissingApptDatesSubtitle')}
          onClose={() => setIsApptDateMissingWarning(false)}
          cancelText={'Ok'}
          width={'370px'}
        />
      </Box>
    );
  }
);
