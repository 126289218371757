import {
  LoadPriorityEnum,
  RelayStopRequestDTO,
  StopDropRequestDTO,
} from '../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import { LoadTagType } from '../../../subPages/loadsList/CreateLoad/types/types';
import {
  StopSolutionV3StopType,
  TripStopActivityEnum,
  TripStopStatusEnum,
} from '../../../ui-kit/components/TripPlan';
import { ICustomerContact } from '../Customer/Customer';
import { LoadCarrierExpenseDTO } from '../FinanceLoadService';

export class PaginatedLoadListResponse {
  content!: [];
  last!: boolean;
  totalElements!: number;
}
export class TagCreateResponseDTO {
  organizationId!: number;
  name!: string;
  description!: string;
  tagType!: string;
  rgbColor!: RGBColor;
  active!: boolean;
  constructor(dto: any) {
    this.organizationId = dto.organizationId;
    this.name = dto.name;
    this.description = dto.description;
    this.tagType = dto.tagType;
    this.rgbColor = dto.rgbColor;
    this.active = dto.active;
  }
}

export class ITagsBulkAssignPayload {
  tagIdsToUpdate!: Array<number>;
  tagIdsToRemove?: Array<number>;
  loadIds!: Array<number>;
}

export class RGBColor {
  red!: number;
  green!: number;
  blue!: number;
  constructor(dto: any) {
    this.red = dto.red;
    this.green = dto.green;
    this.blue = dto.blue;
  }
}

export class ResequenceLoadStopResponse {
  applied!: boolean;
  removableTripIdToSeqNumber!: { [key: string]: number };
}

export class TerminalAssignmentToLoadResponse {}

export class LoadReferenceDetails {
  id!: string;
  organizationId!: string;
  referenceId!: string;
  seqNumber!: string;
}

export class CreateLoadStopResponse {} // to be defined later
export class UpdateLoadStopResponse {} // to be defined later

export class ValidateLoadReferenceIdResponse {
  valid!: boolean;
}

// @TODO: Nvard - will be impl later
export class LoadDetailsResponse {
  id?: string;
  seqNumber!: string;
  terminalId?: string;
  terminal!: {
    company: string;
    id: string;
  };
}

export class Template {
  id!: string;
  templateName!: string;
}

export interface ICommodity {
  id: string;
  qty: number;
  packageType: string;
  commodityType: string;
  commodityTypeId: string;
  itemName: string;
  itemCode?: string;
  description: string;
  weight: number;
  length: number;
  width: number;
  height: number;
  volume: number;
  stackable: string;
  floorLoad: string;
}

export interface ILoadDetailsDTO {
  referenceId?: string;
  trailerTypes: Array<string>;
  commodityData: ICommodity[];
  totalWeight: number;
  factoringId: string | number;
  useFactoring: boolean;
  factoringInvoiceId: number | string;
  factoringStatus: string;
  factoringName: string;
  factoringDocumentStatus: string;
  factoringInvoiceUpdateDate: string | Date;
  notes: string;
  noteDetails: object;
  factoringNotes: string;
  factoringScheduleId: string | number;
  factoringChargePercent: string;
  loadPriority?: LoadPriorityEnum;
  revenue?: number;
  equipmentAdditionalDetails: {
    chassisNumber: string;
    containerNumber: string;
    lowerLimitTemperature: string;
    upperLimitTemperature: string;
    bookingNumber: string;
    appointmentNumber: string;
    loadDirection: string;
    sealNumber: string;
    temperature: string;
    variance: string;
    reeferMode: string;
  };
  tags?: Array<LoadTagType>;
}

// @TODO: Nvard - will be impl later after David Raju's response
export class ParsedFileResponse {
  loadDetails?: ILoadDetailsDTO;
  customer?: {
    id?: string;
    customerId: number;
    customerContactId: number;
    customerName: string;
    contacts: Array<ICustomerContact>;
    referenceNumber?: string;
  };
  terminal?: {
    id: string;
    companyName: string;
  };
  stops?: Array<StopDropRequestDTO | RelayStopRequestDTO>;
  invoice?: ITemplateInvoice;
}

export interface ITemplateInvoice {
  number?: number;
  paid?: number;
  total?: number;
  amountDue?: number;
  loadedMiles?: number;
  emptyMiles?: number;
  lineItems?: {
    id: string;
    itemId: string;
    type: string;
    description: string;
    quantity: number;
    rate: number;
    amount: number;
    itemCode?: string;
  }[];
  receivedPayments?: any;
  contentType?: string;
  notes?: string;
  noteDetails?: any;
  invoiceTerm?: string;
  invoiceTermValue?: number;
  synchStatus?: string;
}
export class PackageTypesResponse {
  key!: string;
  value!: string;
}

export class GetAllStopsResponse {
  stopsActivityDataByTripId!: {
    [tripId: string]: {
      id: number;
      actualTimeOfArrival: string | null;
      loadingStartDate: string | null;
      loadingEndDate: string | null;
      actualTimeOfCompletion: string | null;
      stopType: StopSolutionV3StopType;
      stopStatus: TripStopStatusEnum;
      activityType: TripStopActivityEnum;
      location: {
        state: string;
        city: string;
        timezone: string;
      };
      appointmentStartDate: string;
      appointmentEndDate: string;
    }[];
  };
}

export class UpdateStatusResponse {
  message!: string;
}

class DeleteLoadRelatedEntity {
  id!: number;
  organizationId!: number;
  seqNumber!: number;
  driverId!: number;
}

export class DeleteLoadResponse {
  organizationId!: number;
  loadId!: string;
  settlementIdList!: DeleteLoadRelatedEntity[];
  tractorSettlementIdList!: DeleteLoadRelatedEntity[];
  dispatcherSettlement!: DeleteLoadRelatedEntity[];
  successfullyDeleted!: boolean;
  errorMessage!: string | null;
}

export class Point {
  lat!: number;
  lon!: number;
}

export class TripStopListCoords {
  id!: string | number;
  points!: Point[];
}

export class TripRevenueShare {
  id!: string;
  revenueShare!: number;
}

export class AssignCarrierToLoadDTO {
  errorMessage: string;
  successful: boolean;
  carrierExpenseDTO: LoadCarrierExpenseDTO;
}
