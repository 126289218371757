import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import {
  OptymizeBtnGroupStyles,
  OptymizeBtnStyles,
} from '../../../styles/button.styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useStores } from '../../../store/root.context';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { observer } from 'mobx-react';
import { useLoadPermission } from '../../../common/hooks/useLoadPermission';
import { useTranslation } from 'react-i18next';

const AssignOptions = [
  { id: 'ASSIGNED', name: 'Mark as Assigned' },
  { id: 'PLANNED', name: 'Mark as Planned' },
];

interface IActionSection {}

const ActionSection: React.FC<IActionSection> = (props) => {
  const {
    evaluationStore: {
      stopEvaluation,
      tripToAssignDetails,
      isActionDisabled,
      setActionDisabled,
      driverGroupId,
    },
    dispatch2TripsStore: { changeStatusManualDriverSelection },
    dispatch2GlobalStore: { gapForTripAssignment, setTripAssignmentForGap },
  } = useStores();
  const { hasLoadCRUDPermission } = useLoadPermission();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssignTrip = (status: ELoadStatus) => {
    if (!hasLoadCRUDPermission) return;
    setAnchorEl(null);
    setActionDisabled(true);
    changeStatusManualDriverSelection({
      loadAssignmentStatus: status,
      terminalId: tripToAssignDetails?.terminalId as string,
      loadId: tripToAssignDetails?.loadId as string,
      groupId: driverGroupId as string,
      prevLoadId: gapForTripAssignment?.prevLoadId,
      onFailure: () => {
        setActionDisabled(false);
      },
      onSuccess: () => {
        setActionDisabled(false);
        setTripAssignmentForGap(null);
        stopEvaluation();
      },
    });
  };

  const setTripAsAssigned = () =>
    handleAssignTrip(ELoadStatus.OFFERED_TO_DRIVER);
  const setTripAsPlanned = () =>
    handleAssignTrip(ELoadStatus.ASSIGNMENT_PLANNED);

  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Stack
        direction={'row'}
        display={'flex'}
        gap="8px"
        justifyContent={'end'}
        marginTop={'8px'}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: '6px',
            height: '28px',
            fontSize: '13px',
            textTransform: 'capitalize',
          }}
          onClick={stopEvaluation}
          disabled={isActionDisabled}
        >
          {t('cancel')}
        </Button>

        {hasLoadCRUDPermission && (
          <>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="optymize-btn-container"
              sx={{
                ...OptymizeBtnGroupStyles,
                height: '28px',
                marginTop: 0,
                background: 'none',
              }}
              disabled={isActionDisabled}
            >
              <Button
                sx={{
                  ...OptymizeBtnStyles,
                  fontSize: '13px',
                  textTransform: 'capitalize',
                  borderRadius: '6px',
                  height: '100%',
                }}
                disabled={isActionDisabled}
                onClick={setTripAsAssigned}
              >
                {t('assign')}
              </Button>
              <Button
                size="small"
                aria-controls={open ? 'optimization-group' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ height: '100%' }}
              >
                <ArrowDropDownIcon sx={{ width: '18px', height: '18px' }} />
              </Button>
            </ButtonGroup>

            <Menu
              id="preference-settings-menu"
              aria-labelledby="preference-settings-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{ '.MuiPaper-root': { borderRadius: '14px' } }}
            >
              {AssignOptions?.map((pref) => (
                <MenuItem
                  key={pref?.id}
                  onClick={() => {
                    if (pref.id === 'PLANNED') return setTripAsPlanned();
                    setTripAsAssigned();
                  }}
                >
                  {pref?.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Stack>
    );
  }
};

export default observer(ActionSection);
