import { InvoiceModel } from '@/subPages/invoices/models/InvoiceModel';
import { observer } from 'mobx-react';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import LoadInvoiceRate from '.';
import { CustomerRequiredDocOptions } from '../../../../loadsList/CreateLoad/constants/fieldOptions';

interface IProps {
  onClose: () => void;
  onSave?: (item: any[]) => void;
  data?: any;
  mainLineItem?: boolean;
  foundIndex?: number;
  canEditInvoice: boolean;
  invoice: InvoiceModel;
}

const LoadInvoiceRateLineItems: React.FC<IProps> = ({
  onClose,
  onSave,
  mainLineItem,
  foundIndex,
  data,
  canEditInvoice,
  invoice,
}) => {
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: mainLineItem ? 'lineItems' : `childInvoices.${foundIndex}.lineItems`,
  });
  const updateLineItem = (values: any) => {
    const billOfLadingMandatory = Boolean(
      values?.requiredDocuments?.includes(CustomerRequiredDocOptions?.[0]?.id)
    );
    const proofOfDeliveryMandatory = Boolean(
      values?.requiredDocuments?.includes(CustomerRequiredDocOptions?.[1]?.id)
    );
    if (mainLineItem) {
      setValue('lineItems', []);
      setValue(`loadedMiles`, values?.loadedMiles);
      setValue(`emptyMiles`, values?.emptyMiles);
      setValue(`completeDate`, values?.completeDate);
      setValue(`billOfLadingMandatory`, billOfLadingMandatory);
      setValue(`proofOfDeliveryMandatory`, proofOfDeliveryMandatory);
      setValue('isMilesManuallyUpdated', values?.isMilesManuallyUpdated);
    } else {
      setValue(`childInvoices.${foundIndex}.lineItems`, []);
      setValue(`childInvoices.${foundIndex}.loadedMiles`, values?.loadedMiles);
      setValue(`childInvoices.${foundIndex}.emptyMiles`, values?.emptyMiles);
      setValue(
        `childInvoices.${foundIndex}.completeDate`,
        values?.completeDate
      );
      setValue(
        `childInvoices.${foundIndex}.billOfLadingMandatory`,
        billOfLadingMandatory
      );
      setValue(
        `childInvoices.${foundIndex}.proofOfDeliveryMandatory`,
        proofOfDeliveryMandatory
      );
      setValue(
        `childInvoices.${foundIndex}.isMilesManuallyUpdated`,
        values?.isMilesManuallyUpdated
      );
    }
    values?.Items.map((item: any) => {
      append(item);
    });
    onSave?.();
  };

  return (
    <>
      <LoadInvoiceRate
        open={true}
        onClose={onClose}
        data={data}
        onSave={(values: any[]) => {
          updateLineItem(values);
        }}
        invoice={invoice}
        canEditInvoice={canEditInvoice}
      ></LoadInvoiceRate>
    </>
  );
};
export default observer(LoadInvoiceRateLineItems);
