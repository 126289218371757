import { DateRange } from '@mui/x-date-pickers-pro-v5';

export enum ERelativeDateRangeUnit {
  UNIT = 'UNIT',
  CUSTOMIZE = 'CUSTOMIZE',
}

export enum ERelativeDateRangeKey {
  PeriodTimeLastYear = 'PeriodTimeLastYear',
  PeriodTimeLastQuarter = 'PeriodTimeLastQuarter',
  PeriodTimeLast6Months = 'PeriodTimeLast6Months',
  PeriodTimeLast3Months = 'PeriodTimeLast3Months',
  PeriodTimeLast3MonthsOnward = 'PeriodTimeLast3MonthsOnward',
  PeriodTimeLastMonth = 'PeriodTimeLastMonth',
  PeriodTimeLastWeek = 'PeriodTimeLastWeek',
  PeriodTimeYesterday = 'PeriodTimeYesterday',
  PeriodTimeThisYear = 'PeriodTimeThisYear',
  PeriodTimeThisQuarter = 'PeriodTimeThisQuarter',
  PeriodTimeToday = 'PeriodTimeToday',
  PeriodTimeThisWeek = 'PeriodTimeThisWeek',
  PeriodTimeTomorrow = 'PeriodTimeTomorrow',
  PeriodTimeNext7Days = 'PeriodTimeNext7Days',
  PeriodTimeNext30Days = 'PeriodTimeNext30Days',
  PeriodTimeCustom = 'PeriodTimeCustom',
}

export interface IRelativeDateRangeOption {
  type: ERelativeDateRangeUnit;
  key: ERelativeDateRangeKey;
  name: string;
  dateRange: DateRange<Date | null>;
}
