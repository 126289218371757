import { saveAs } from '../utils/saveFile';

export type docType = {
  contentType: string;
  file?: string;
  fileContent: string;
  fileName?: string;
  size?: number;
  key?: null;
  id?: string;
};

export const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const convertData = (doc: docType) => {
  const { contentType, fileName } = doc;
  const content = doc.file || doc.fileContent;
  const byteToArray = base64ToArrayBuffer(content);
  //@anna
  const file = new Blob([byteToArray], { type: contentType });
  return {
    fileURL: URL.createObjectURL(file),
    file,
    fileName,
  };
};

const downloadExcelOrPDF = (doc: docType) => {
  const file = convertData(doc).fileURL;
  saveAs(file, doc.fileName);
};

const downloadImgfiles = (doc: docType) => {
  const { file, fileName } = convertData(doc);
  saveAs(file, fileName);
};

const downloadFile = (doc: docType) => {
  const { contentType } = doc;
  if (
    [
      'application/x-tika-msoffice',
      'application/x-tika-ooxml',
      'application/pdf',
      'application/vnd.ms-excel',
    ].includes(contentType)
  ) {
    downloadExcelOrPDF(doc);
  }
  if (contentType && contentType.match(/(image)*\/(?:jpg|gif|png|jpeg)/)) {
    downloadImgfiles(doc);
  }
};

const openTab = (append: string, url: string) => {
  const win = window.open(url);
  const { document }: any = win || {};
  if (document && typeof document.replaceWith === 'function') {
    setTimeout(() => {
      document.replaceWith(append);
    }, 0);
  }
};

const previewPDFfiles = (doc: docType) => {
  const { fileURL } = convertData(doc);
  const append = `
   <body style="margin: 0;">
     <embed 
       src="${fileURL}"
       frameborder="0"
       style="width:100%; height:100%;"
       allowfullscreen
       full-frame
       pdf-viewer-update-enabled=""
      />
   </body>
  `;
  openTab(append, fileURL);
};

const previewImgfiles = (doc: docType) => {
  const { fileURL, fileName } = convertData(doc);
  const append = `
   <html lang="en">
   <head>
     <title>${fileName}</title>
   </head>
   <body style="margin: 0;">
     <img src="${fileURL}"  alt=""/>
   </body>
   </html>
  `;
  openTab(append, fileURL);
};

const previewFile = (doc: docType) => {
  const { contentType } = doc;
  if (contentType === 'application/pdf') {
    previewPDFfiles(doc);
  }
  if (
    contentType === 'application/vnd.ms-excel' ||
    contentType === 'application/x-tika-ooxml'
  ) {
    downloadFile(doc);
  }
  if (
    contentType &&
    contentType.toLowerCase().match(/(image)*\/(?:jpg|gif|png|jpeg)/)
  ) {
    previewImgfiles(doc);
  }
};
export { downloadFile, previewFile };
