import { HTMLAttributes } from 'react';
import { MenuItemData } from '../definitions';
import { IconMenuItem } from './IconMenuItem';
import { NestedMenuItem } from './NestedMenuItem';
export interface nesteMenuItemsFromObjectProps {
  item: MenuItemData;
  isNestedItem?: boolean;
  totalAvailableSlot?: number;
  selectedLabel?: string | undefined;
  availableSlots?: number;
  subValue?: string | undefined;
  isOpen: boolean;
  onClick?: any;
  handleClose: (selectedItem: { label: string; value: any }) => void;
  props: HTMLAttributes<HTMLLIElement>;
}

export const renderOption = ({
  item,
  isOpen,
  handleClose,
  isNestedItem,
  selectedLabel,
  props,
}: nesteMenuItemsFromObjectProps) => {
  const { leftIcon, rightIcon, label, value, items, subValue, callback, uid } =
    item;

  if (items && items.length) {
    // Recurse deeper
    return (
      <NestedMenuItem
        key={uid || Math.random()}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        label={label}
        parentMenuOpen={isOpen}
      >
        {items.map((item: any) => {
          return renderOption({
            item,
            isNestedItem: true,
            isOpen,
            selectedLabel: label,
            totalAvailableSlot: items.length,
            handleClose,
            props,
          });
        })}
      </NestedMenuItem>
    );
  } else {
    // No children elements, return MenuItem
    return (
      <li {...props} key={uid || Math.random()}>
        <IconMenuItem
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          label={label}
          isNestedItem={isNestedItem}
          selectedLabel={selectedLabel}
          totalAvailableSlot={0}
          subValue={subValue}
          onClick={() => {
            const selectedItem = callback?.({ label, value, selectedLabel });
            handleClose(selectedItem);
          }}
        />
      </li>
    );
  }
};
