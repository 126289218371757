import React from 'react';
import { InvoiceModel } from '../../models/InvoiceModel';
import FormDialog from '../../../../common/Ui/FormDialog';
import { AttachmentsForm } from './InvoiceDownloadAction';
import TextArea from '../../../../common/Ui/TextArea';

/* @TODO davits this and send to factoring files completely are the same. use one after all */
export const UpdateAtFactoringForm = ({
  invoice,
}: {
  invoice: InvoiceModel;
}) => {
  return (
    <>
      <TextArea
        name={'note'}
        placeholder={'Factoring Notes'}
        customStyles={{
          backgroundColor: 'transparent',
          border: '1px solid #c5c8ca',
          color: '#c5c8ca',
          padding: 8,
        }}
      />
      <AttachmentsForm
        invoice={invoice}
        subLabel={'(only PDF and image files are accepted)'}
      />
    </>
  );
};

export function UpdateAtFactoringDialog({
  open,
  onAction,
  onClose,
  invoice,
  factoringNotes,
}: {
  open: boolean;
  onAction: ({
    invoiceId,
    documentIds,
  }: {
    invoiceId: string;
    documentIds: string[];
  }) => void;
  onClose: () => void;
  invoice: InvoiceModel;
  factoringNotes: string | null;
}) {
  return (
    <FormDialog
      data={{
        sendInvoice: false,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
        documentIds: [],
        invoiceId: invoice.id,
        note: factoringNotes,
      }}
      titleText="Update at factoring"
      actionLabel="Send"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <UpdateAtFactoringForm invoice={invoice} />}
    />
  );
}
