import { action, makeObservable, observable } from 'mobx';
import * as types from '../../views/evaluation/types';
import { evaluateDriverRequest } from '../../views/evaluation/network/evaluate.request';
import {
  IRecommendedResource,
  ResourceType,
} from '../../views/optymization/types/gantt.types';
import StorageManager from '../../StorageManager/StorageManager';
import { GlobalNotificationType } from '../../constants/globalNotificationMessages';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

type PositionType = { top: number; left: number };

interface IEvaluationParams extends Partial<types.EvaluateRequestDTO> {
  targetPosition?: PositionType;
  resourceType?: ResourceType;
  isTypeGlobalEvaluation?: boolean;
}

type TripToAssignType = {
  terminalId?: string;
  loadId?: string;
};
type EvaluationSourceType = 'DISPATCH' | 'OPTIMIZATION';

export enum OPTIMIZATION_TYPES {
  RECOMMENDED_RESOURCE = 'RECOMMENDED_RESOURCE',
  POSSIBLE_RESOURCES = 'POSSIBLE_RESOURCES',
}

export type OptimizationType = OPTIMIZATION_TYPES;

interface IEvaluationResult {
  resource?: IRecommendedResource;
  source: EvaluationSourceType;
  targetPosition: PositionType;
  allowAction?: boolean;
  evaluationResult: types.EvaluationResDTO;
  optimizationType?: OptimizationType;
}
export class EvaluationStore {
  @observable targetPosition: PositionType = { top: 0, left: 0 };
  @observable runEvaluation = false;
  @observable driverGroupId: string | null = null;
  @observable tripIds: Array<string> | null = null;
  @observable isGapSelected = false;
  @observable gapId: string | null = null;
  @observable isLoading = false;
  @observable evaluationResult: types.EvaluationResDTO | null = {
    evaluationKPI: [],
    customTripInfoList: [],
    evaluationRating: 0,
    driverName: '',
    issuesKPI: [],
  };
  @observable source: EvaluationSourceType = 'DISPATCH';
  @observable optimizationType: OptimizationType | null = null;
  @observable isActionDisabled = false;
  @observable driverDetailsToEvaluate: IRecommendedResource | null = null;
  @observable tripToAssignDetails: TripToAssignType | null = null;
  @observable resourceType: ResourceType | null = null;
  @observable actionTargetEl: EventTarget | null | undefined = null;
  @observable isTypeGlobalEvaluation?: boolean = false;
  @observable globalEvaluationResult: types.EvaluationResDTO | null = null;
  @observable possibleOptionsEvaluation: types.EvaluationResDTO | null = null;

  onLockFn: any;
  onRejectFn: any;
  onSwapFn: any;

  constructor() {
    makeObservable(this);
  }

  @action
  setTargetPosition = (pos: PositionType) => {
    this.targetPosition = pos;
  };
  @action
  startEvaluation = async (params: IEvaluationParams, networkCall = true) => {
    this.isLoading = true;
    this.driverGroupId = params.driverGroupId as string;
    this.tripIds = params.tripIds as Array<string>;
    this.gapId = params.gapId as string;
    this.isGapSelected = params.isGapSelected as boolean;
    this.targetPosition = params?.targetPosition as PositionType;
    this.resourceType = params.resourceType as ResourceType;
    this.runEvaluation = true;
    if (networkCall)
      await this.evaluateResourceRequest({
        driverGroupId: this.driverGroupId,
        tripIds: this.tripIds,
        gapId: this.gapId,
        organizationId: StorageManager?.getUser()?.organizationId,
        isGapSelected: this.isGapSelected,
      });
    this.isLoading = false;
    this.isTypeGlobalEvaluation = params?.isTypeGlobalEvaluation ?? false;
  };

  @action
  stopEvaluation = () => {
    this.runEvaluation = false;
    this.source = 'DISPATCH';
    this.driverDetailsToEvaluate = null;
    this.driverGroupId = null;
    this.tripIds = null;
    this.targetPosition = { top: 0, left: 0 };
    this.isActionDisabled = false;
    this.tripToAssignDetails = null;
    this.evaluationResult = null;
    this.resourceType = null;
    this.optimizationType = null;
    this.actionTargetEl = null;
    this.isTypeGlobalEvaluation = false;
  };
  @action
  setDriverToEvaluate = (id: string) => {
    this.isActionDisabled = false;
    this.driverGroupId = id;
  };
  @action
  setTripsToEvaluate = (ids: Array<string>) => {
    this.isActionDisabled = false;
    this.tripIds = ids;
  };

  @action setEvaluationResults = (res: types.EvaluationResDTO) => {
    this.runEvaluation = true;
    this.evaluationResult = res;
  };

  @action evaluateResourceRequest = async (
    reqBody: types.EvaluateRequestDTO
  ) => {
    try {
      const res = await evaluateDriverRequest({
        data: reqBody,
      });
      this.evaluationResult = res;
    } catch (error) {
      this.isLoading = false;
      this.showToast({
        type: 'FAILURE',
        serviceName: 'engineEvaluationFailure',
      });
      this.stopEvaluation();
    }
  };

  @action setSource = (src: EvaluationSourceType) => {
    this.source = src;
  };

  @action setOnLockCallback = (callback: any) => {
    this.onLockFn = callback;
  };
  @action setOnRejectCallback = (callback: any) => {
    this.onRejectFn = callback;
  };
  @action setOnSwapCallback = (callback: any) => {
    this.onSwapFn = callback;
  };

  @action onRejectAction = () => {
    this.setActionDisabled(true);
    return this.onRejectFn?.();
  };
  @action onLockAction = () => {
    this.setActionDisabled(true);
    return this.onLockFn?.();
  };

  @action onSwapAction = () => {
    return this.onSwapFn?.();
  };

  @action setDriverDetailsToEvaluate = (d: IRecommendedResource) => {
    this.driverDetailsToEvaluate = d;
  };
  @action setActionDisabled = (val: boolean) => {
    this.isActionDisabled = val;
  };

  @action setTripToAssignDetails = (details: TripToAssignType) => {
    this.tripToAssignDetails = details;
  };

  @action showToast = (config: GlobalNotificationType) => {
    RootStoreInstence.setNotificationType(config);
  };

  @action setActionTarget = (
    targetEl?: (EventTarget & { actionReference: 'anchorPosition' }) | null
  ) => {
    this.actionTargetEl = targetEl;
  };

  @action setEvaluationDetails = (result: IEvaluationResult) => {
    this.source = result.source;
    this.targetPosition = result.targetPosition;
    this.setDriverToEvaluate(result?.resource?.id!);
    this.setDriverDetailsToEvaluate(result?.resource!);
    this.optimizationType = result?.optimizationType!;
    this.setEvaluationResults(result?.evaluationResult);
    this.runEvaluation = true;
  };

  @action setGlobalEvaluationType = (val: boolean) => {
    this.isTypeGlobalEvaluation = val;
  };

  @action setGlobalEvaluationResult = (result: types.EvaluationResDTO) => {
    this.globalEvaluationResult = {
      ...result,
      driverName: 'Reasons for No Pairings',
    };
  };

  @action setPossibleOptionsEvaluation = (result: types.EvaluationResDTO) => {
    this.possibleOptionsEvaluation = {
      ...result,
      driverName: 'Reasons for No Pairings',
    };
  };
  @action clearPossibleOptionsEvaluation = () => {
    this.possibleOptionsEvaluation = null;
  };
}

export const createEvaluationStore = () => new EvaluationStore();
