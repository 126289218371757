import { FC, useEffect, useState } from 'react';

import DeletePopup from '../../../ui-kit/components/DeletePopup';
import { vendorService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  CreateVendorRequest,
  DeleteVendorRequest,
  UpdateVendorRequest,
} from '../../../models';
import { IHttpErrorType } from '../../../types';
import VendorDetailsPanel from '../components/VendorDetailsPanel';
import { vendorDefaultData } from '../constants';
import { VendorSummaryTableRow } from '../models/vendor';

import { useContacts } from '../../../contexts/ContactsContext';
import { getHttpErrorsFromResponse } from '../../../utils';

interface VendorDetailsPanelContainerProps {
  selectedItem: VendorSummaryTableRow | null;
  onClose: () => void;
  onUpdated: (data: VendorSummaryTableRow) => void;
  onDeleted: (id: string) => void;
  onCreated: (data: VendorSummaryTableRow) => void;
  updateVendorSummary: (data: VendorSummaryTableRow) => void;
  isGlobal: boolean;
}

const VendorDetailsPanelContainer: FC<VendorDetailsPanelContainerProps> = ({
  selectedItem: selectedItemProp,
  onClose,
  onUpdated,
  onDeleted,
  onCreated,
  updateVendorSummary,
  isGlobal = false,
}) => {
  const { setAddNewClicked, addNewClicked, listDataTotal } = useContacts();

  const [selectedItem, setSelectedItem] =
    useState<VendorSummaryTableRow | null>(null);

  const [deletePopupState, setDeletePopupState] = useState<{
    open: boolean;
    vendorId: string;
  }>({ open: false, vendorId: '' });

  const isCreatePanelOpen = false;
  const [httpErrors, setHttpErrors] = useState<IHttpErrorType | null>(null);

  useEffect(() => {
    if (addNewClicked) {
      setSelectedItem(new VendorSummaryTableRow(vendorDefaultData));
    }
  }, [addNewClicked]);

  const updateHttpErrors = (error: any) => {
    if (error instanceof ServiceError) {
      const errorStatusCode = error.composedError.error.response?.status;
      if (errorStatusCode === 409) {
        setHttpErrors(getHttpErrorsFromResponse(error));
      }
    }
  };

  const handleCreated = async (
    newVendor: VendorSummaryTableRow
  ): Promise<void> => {
    const contacts = newVendor.contacts.map((contact, index) => ({
      ...contact,
      seqNumber: index + 1,
    }));
    const vendor = {
      ...newVendor,
      contacts,
      // seqNumber: tableData.length + 1,
      preferredProhibitedEnum: newVendor.preferredProhibitedEnum || 'NONE',
    };
    const requestData: CreateVendorRequest = new CreateVendorRequest(vendor);
    try {
      const response = await vendorService.createVendor(requestData);
      handleClose();
      return onCreated(new VendorSummaryTableRow(response));
    } catch (error) {
      updateHttpErrors(error);
      handleClose();
    }
  };

  const handleUpdated = async (
    vendor: VendorSummaryTableRow
  ): Promise<void> => {
    const contacts = vendor.contacts.map((contact, index) => ({
      ...contact,
      seqNumber: index + 1,
    }));

    const updatedVendor = {
      ...vendor,
      contacts,
      preferredProhibitedEnum: vendor.preferredProhibitedEnum || 'NONE',
    };

    const requestData: UpdateVendorRequest = new UpdateVendorRequest(
      updatedVendor
    );
    try {
      const response = await vendorService.updateVendor(requestData);
      handleClose();
      return onUpdated(new VendorSummaryTableRow(response));
    } catch (error) {
      updateHttpErrors(error);
      handleClose();
      return;
    }
  };

  const deleteVendor = async () => {
    const { vendorId } = deletePopupState;
    const requestData = new DeleteVendorRequest({ id: vendorId });
    try {
      await vendorService.deleteVendor(requestData);
    } catch (error) {
      return;
    }
    handleClose();
    setDeletePopupState({
      open: false,
      vendorId: '',
    });
    onDeleted(vendorId);
  };

  const handleDelete = (id: string) => {
    setDeletePopupState({
      open: true,
      vendorId: id,
    });
  };

  useEffect(() => {
    setSelectedItem(selectedItemProp);
  }, [selectedItemProp]);

  const handleClose = () => {
    if (isGlobal) {
      onClose();
    } else {
      setSelectedItem(null);
      setAddNewClicked(false);
      setHttpErrors(null);
      onClose();
    }
  };

  return (
    <>
      {selectedItem && (
        <VendorDetailsPanel
          panelTitle={
            (isCreatePanelOpen && 'Add New Vendor') || selectedItem.name
          }
          data={selectedItem}
          onCreated={handleCreated}
          onUpdated={handleUpdated}
          onDeleted={handleDelete}
          onClose={handleClose}
          isCreatePanelOpen={isCreatePanelOpen}
          httpErrors={httpErrors}
          updateVendorSummary={updateVendorSummary}
          isGlobal={isGlobal}
        />
      )}
      {deletePopupState.open && (
        <DeletePopup
          open={deletePopupState.open}
          onClose={() =>
            setDeletePopupState({
              open: false,
              vendorId: '',
            })
          }
          onAction={() => {
            deleteVendor();
          }}
          title={'Delete Vendor?'}
          body="Are you sure you want to delete this vendor?"
        />
      )}
    </>
  );
};

export default VendorDetailsPanelContainer;
