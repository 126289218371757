import { ICriteria } from '../../../types/LoadsearchTypes';
import { CreateLoadRequest } from '../Loads/Requests';
import { Payload } from '../commonMixed/Payload';
import { QueryParams } from '../commonMixed/QueryParams';
import { ILoadSearchModel } from './Model';

export class LoadsearchRequest extends QueryParams {
  criteria: ICriteria;
  dispatcherId!: number;
  nextLoadId: string;
  previousLoadId: string;
  driverGroupId!: string;
  driverId!: number;
  gapStart!: string;
  organizationId!: number;
  sources!: any;
  reloadScoreFilter!: number;
  showActualOffersOnly: boolean | null;
  constructor(data: any) {
    super();
    this.criteria = data.criteria;
    this.dispatcherId = data.dispatcherId;
    this.nextLoadId = data.nextLoadId;
    this.previousLoadId = data.previousLoadId;
    this.driverGroupId = data.driverGroupId;
    this.driverId = data.driverId;
    this.gapStart = data.gapStart;
    this.sources = data.sources;
    this.reloadScoreFilter = data.reloadScoreFilter;
    this.showActualOffersOnly = data.showActualOffersOnly;
  }
}

export class AssignLSLoadToDriverRequest extends QueryParams {
  driverGroupId: string;
  dispatcherId: string;
  previousLoadId: string;
  nextLoadId: string;
  selectedLoad: CreateLoadRequest;
  action: string;

  constructor(data: any) {
    super();
    this.driverGroupId = data.driverGroupId;
    this.dispatcherId = data.dispatcherId;
    this.previousLoadId = data.previousLoadId;
    this.nextLoadId = data.nextLoadId;
    this.action = data.action;
    this.selectedLoad = data.load;
  }
}

export class SaveLaneRequest extends QueryParams {
  activeColumns!: object;
  dispatcherId!: number;
  iconName!: string;
  laneName!: string;
  loadSearchCriteria!: {
    ageHours: number;
    destinations: [
      {
        city: string;
        deadheadMiles: number;
        latitude: number;
        longitude: number;
        state: string;
        zone: string;
      }
    ];
    equipmentList: Array<string>;
    equipments: string;
    lengthFeet: number;
    loadType: string;
    origins: [
      {
        city: string;
        deadheadMiles: number;
        latitude: number;
        longitude: number;
        state: string;
        zone: string;
      }
    ];
    pickupEnd: string;
    pickupStart: string;
    weightPounds: number;
  };
  shareWithTeam!: boolean;
  sources!: [
    {
      source: string;
      sourceId: string;
    }
  ];
}

export class GetSavedLanesRequest extends QueryParams {
  dispatcherId!: number;
}

export class EditSavedLanesRequest extends SaveLaneRequest {
  id!: string;
}

export class GetEquipmentRequest extends QueryParams {}

export class DeleteSavedLanesRequest extends QueryParams {
  laneId!: string;
}

export class AvailableLoadboards extends QueryParams {
  organizationId!: number;
  terminalIds!: Array<string>;
  constructor(data: { terminalIds: string[] }) {
    super();
    this.terminalIds = data.terminalIds;
  }
}

export class SaveKpiRequest extends QueryParams {
  newRevenue!: number;
  destinationDeadhead!: number;
  dns!: [
    {
      equipmentType: string;
      value: number;
    }
  ];
  estimatedProfit!: number;
  fixedCost!: number;
  loadDuration!: number | undefined;
  loadMiles!: number;
  originDeadhead!: number;
  perDayRevenue!: number;
  perMileRevenue!: number;
  revenue!: number;
  variableCost!: number;
  organizationId!: number;
}
export class BookmarkLoadRequest extends QueryParams {
  organizationId!: number;
  dispatcherId!: number;
  id!: string;
  savedLoad!: any;
  constructor(data: any) {
    super();
    this.dispatcherId = data.dispatcherId;
    // this.id = data.id
    this.savedLoad = data.savedLoad;
  }
}

export class UnBookmarkLoadRequest extends QueryParams {
  bookMarkId!: string;
  constructor(data: any) {
    super();
    this.bookMarkId = data.id;
  }
}

export class GetBookmarkListRequest extends QueryParams {
  organizationId!: number;
  booked!: boolean;
}

export class GetDriverListRequest extends QueryParams {
  criteria: ICriteria;
  dispatcherId!: number;
  organizationId!: number;
  terminalIds!: Array<any>;
  constructor(data: any) {
    super();
    this.criteria = data.criteria;
    this.terminalIds = [];
  }
}

//Trip Plan tab in the Loadsearch Detail Panel  begin
export class LoadSearchInvokeRequest extends QueryParams {
  dispatcherId: number;
  driverGroupId: string | null;
  nextLoadId: number | null;
  previousLoadId: number | null;
  selectedLoad: ILoadSearchModel;

  constructor(data: any) {
    super(true);
    this.dispatcherId = data.dispatcherId;
    this.driverGroupId = data.driverGroupId || null;
    this.nextLoadId = data.nextLoadId || null;
    this.previousLoadId = data.previousLoadId || null;
    this.selectedLoad = data.selectedLoad;
  }
}

export class LoadsearchMapDataRequest extends Payload {
  driverGroupId!: string | null;
  invocationRequestId!: string | any;
  nextLoadId!: string | null;
  previousLoadId!: string | null;
  sandboxLoadDTO!: ILoadSearchModel;
}

export class LoadInstantBookingRequest extends QueryParams {
  selectedLoad!: ILoadSearchModel;
  constructor(data: any) {
    super(true);
    this.selectedLoad = data.selectedLoad;
  }
}
export class LoadSearchGetSolutionRequest extends QueryParams {
  dispatcherId: number;
  invocationRequestId: string;
  nextLoadId: number | null;
  previousLoadId: number | null;
  driverGroupId?: string;
  driverId?: number;

  constructor(data: any) {
    super(true);
    this.dispatcherId = data.dispatcherId;
    this.invocationRequestId = data.invocationRequestId;
    this.nextLoadId = data.nextLoadId || 'null';
    this.previousLoadId = data.previousLoadId || 'null';

    if (data.driverGroupId !== null) {
      this.driverGroupId = data.driverGroupId;
    }
    if (data.driverId !== null && !isNaN(data.driverId)) {
      this.driverId = data.driverId;
    }
  }
}

export class LoadSearchGetSolutionManifestRequest {}

//Trip Plan tab in the Loadsearch Detail Panel end

export class SaveBidOnLoadRequest extends QueryParams {
  bidPrice!: number;
  dispatcherId!: number;
  recipientEmailId!: string[];
  organizationId!: number;
  ccEmailId: string[];
  mailBody: string;
  mailSubject: string;
  bidSavedLoad: any;

  constructor(data: any) {
    super(true);
    this.ccEmailId = data.ccEmailId;
    this.mailBody = data.mailBody;
    this.mailSubject = data.mailSubject;
    this.bidSavedLoad = data.bidSavedLoad;
    this.bidPrice = data.bidPrice;
    this.dispatcherId = data.dispatcherId;
    this.recipientEmailId = data.recipientEmailId;
  }
}

export class UpdateBidLoadRequest extends QueryParams {
  savedLoad: any;
  bidStatusAction: boolean;

  constructor(data: any) {
    super(true);
    this.savedLoad = data.savedLoad;
    this.bidStatusAction = data.bidStatusAction;
  }
}
