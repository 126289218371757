import { Button, CircularProgress, Stack } from '@mui/material';
import React from 'react';
import { RoundedBtnStyles } from '../../styles';
import { PreferenceFooterContainerStyles } from '../../styles/preferenceSettings.style';
import { useTranslation } from 'react-i18next';

interface PreferenceFooterProps {
  onCancel?: () => void;
  onSave?: () => void;
  onReset?: () => void;
  disableSubmit?: boolean;
  isResetLoader?: boolean;
  isSaveLoader?: boolean;
}

const PreferenceFooter: React.FC<PreferenceFooterProps> = (props) => {
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Stack direction={'row'} sx={PreferenceFooterContainerStyles}>
        <Button
          disabled={props?.isResetLoader || props?.isSaveLoader}
          sx={{ ...RoundedBtnStyles, width: '86px', boxShadow: 'none' }}
          color="primary"
          onClick={props?.onReset}
          variant="outlined"
        >
          {props?.isResetLoader ? <CircularProgress size={20} /> : 'Reset'}
        </Button>
        <Button
          sx={{
            ...RoundedBtnStyles,
            ml: 'auto',
            width: '98px',
            boxShadow: 'none',
          }}
          color="primary"
          onClick={props?.onCancel}
          variant="outlined"
        >
          {t('cancel')}
        </Button>
        <Button
          disabled={
            props?.disableSubmit || props?.isSaveLoader || props?.isResetLoader
          }
          sx={{ ...RoundedBtnStyles, width: '82px' }}
          variant="contained"
          color="primary"
          onClick={props?.onSave}
        >
          {props?.isSaveLoader ? <CircularProgress size={20} /> : t('save')}
        </Button>
      </Stack>
    );
  }
};

export default PreferenceFooter;
