import StorageManager from '../../../../StorageManager/StorageManager';
import { manageTypesService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { CreateManageTypeRequest } from '../../../../models/DTOs/ManageTypes/Requests';
import { CommoditiesTypeResDTO } from '../types/requests';

class LoadFormService {
  getCommoditiesDropdownOptions({
    list,
    searchText,
  }: {
    list: Array<CommoditiesTypeResDTO>;
    searchText?: string;
  }): Array<CommoditiesTypeResDTO> {
    if (searchText?.length) {
      const isCommodityNameExists = list?.find?.(
        (e) => e?.itemName === searchText
      );
      if (isCommodityNameExists) return list;
      return [
        {
          type: 'NEW_COMMODITY',
          itemName: `Add “${searchText}”`,
          value: searchText,
        },
        ...(list ?? []),
      ];
    }
    return list ?? [];
  }

  createNewCommodityType = async (itemName: string) => {
    try {
      const user = StorageManager.getUser();
      const createdBy = `${user?.firstname} ${user?.lastname}`;
      const request = new CreateManageTypeRequest();
      request.itemName = itemName;
      request.iconName = null;
      request.deduction = false;
      request.description = null;
      request.unit = [];
      request.createdBy = createdBy;

      const res = await manageTypesService.createManageType(
        request,
        'commodity'
      );
      if (res instanceof ServiceError)
        throw new Error('Failed to create commodity.');
      return res;
    } catch (error) {
      return null;
    }
  };
}

export const loadFormService = new LoadFormService();
