import { Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { EventTypes } from '../../../../EventEmitter/EventTypes';
import { LoadTripActionData } from '../../../../EventEmitter/Events/EventLoadTripAction';
import StorageManager from '../../../../StorageManager/StorageManager';
import { financeLoadService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';

import {
  ByLoadIdRequest,
  ExpenseCategory,
  ExpenseEntityType,
  PaymentType,
} from '../../../../models';
import {
  GetFinancialItemRequest,
  LoadCarrierExpenseDTO,
  LoadCarrierExpenseDTORequest,
} from '../../../../models/DTOs/FinanceLoadService';

import { LoadFinanceLineItemRequest } from '../../../../models/DTOs/PaymentTerms/request';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { useStores } from '../../../../store/root.context';
import {
  getAllPaymentTypes,
  getAllPaymentTypesMap,
  getExpenseCategories,
} from '../../../../subPages/users/components/PaymentTermForm/utils';
import useEmit, {
  EventCallbackFunction,
} from '../../../../utils/hooks/useEmit';
import { ESecondaryDetailsPanelType } from '../../../../views/dispatch2/constants/types';
import { useTripSettings } from '../../../../views/trips/context/TripContext';
import { ELoadStatus } from '../../constants/constants';
import { EFinanceOverview } from './FinanceOverviewConstant';
import {
  EPaymentDetailType,
  FinanceLoadType,
  FinanceLoadloadexpenseListType,
  ListDataType,
  requestParamsFinanceOverview,
  requestStatuesStatementInfo,
} from './FinanceOverviewModel';
const arr = [...new Array(18)].map((e) => ~~(Math.random() * 40));

export type SelectEntityType = {
  type?: string;
  name?: string;
  status?: string;
  loadId?: string;
  paymentId?: string;
  id?: number;
};
type FormTypeContextType = {
  availablePayTypes: PaymentType[];
  expenseCategories: ExpenseCategory[];
  allPaymentTypes: PaymentType[];
  financeOverviewData: FinanceLoadType | undefined;
  selectRecordData: ListDataType | undefined;
  setFinanceOverviewData: (
    value: React.SetStateAction<FinanceLoadType | undefined>
  ) => void;
  setSelectRecordData: (
    value: React.SetStateAction<ListDataType | undefined>
  ) => void;
  statementInfo: requestStatuesStatementInfo;
  setStatementInfo: (
    value: React.SetStateAction<requestStatuesStatementInfo | undefined>
  ) => void;
  setSelectRecordList: (
    value: React.SetStateAction<FinanceLoadloadexpenseListType[]>
  ) => void;
  createNewFinanceOverViewForRecord: (
    FinanceRecord: FinanceLoadloadexpenseListType,
    type: string,
    id: string
  ) => void;
  updateFinanceOverViewForRecord: (
    FinanceRecord: FinanceLoadloadexpenseListType,
    type: string,
    id: string
  ) => void;
  resetFinanceOverviewData: (
    details: SelectEntityType,
    isResetForm: boolean
  ) => void;
  ModuleType: string;
  renderSection: string;
  setRenderSection: (Name: string) => void;
  setAvailablePayTypes: (value: React.SetStateAction<PaymentType[]>) => void;
  selectRecordList: FinanceLoadloadexpenseListType[];
  selectEntityType: SelectEntityType | undefined;
  setSelectEntityType: (
    value: React.SetStateAction<SelectEntityType | undefined>
  ) => void;
  isSavebtnVisible: boolean;
  setSavebtnVisible: (value: boolean) => void;
  openPayments: any;
  onClose: () => void;
  resetForm: (data: ListDataType) => void;
  deleteFinanceOverViewForLoad: (
    FinanceRecord: FinanceLoadloadexpenseListType,
    type: string,
    id: string
  ) => void;
  panelType: ESecondaryDetailsPanelType;
  isBrokered?: boolean;
  isCarrier?: boolean;
  updateLoadCarrierExpense: (
    payload: LoadCarrierExpenseDTO
  ) => Promise<LoadCarrierExpenseDTO | ServiceError | null>;
  loadId?: string;
  loadStatus?: string;
  setOpenPayments?: (data: any) => void;
  isCreatingNewPayment?: boolean;
  setIsCreatingNewPayment: (data: boolean) => void;
  prepareDataForForm?: () => void;
  isEmptyTrip?: boolean;
};
const FormContext = createContext({} as FormTypeContextType);

export async function getFinanceOverviewData(
  loadId: ByLoadIdRequest
): Promise<FinanceLoadType> {
  const data = await financeLoadService.GetLoadFinanceInitial(loadId);
  const params = data as FinanceLoadType;
  return params;
}

export const getFinanceDeduction = (
  availablePayTypesArg: PaymentType[],
  loadRateType: string
) => {
  const item = availablePayTypesArg.find(
    (item: PaymentType) => item.itemCode === loadRateType
  );
  return item?.deduction ? item.deduction : '';
};
export const getLabelName = (
  availablePayTypesArg: PaymentType[],
  loadRateTypeId: string
) => {
  const item = availablePayTypesArg.find(
    (item: PaymentType) => item.id === loadRateTypeId
  );
  return item?.itemName ? item.itemName : '';
};
export const filterAvailableFinanceOverview = (
  list: FinanceLoadloadexpenseListType[] | [],
  availablePayTypesArg: PaymentType[]
): PaymentType[] => {
  let recordList: FinanceLoadloadexpenseListType[] = [];
  let filteredPayTypes: PaymentType[] = [];
  if (list?.length) {
    recordList = list;
    filteredPayTypes = availablePayTypesArg.filter((eachPaymentType) => {
      let usageCounter = 0;
      for (let index = 0; index < recordList.length; index += 1) {
        if (recordList[index].loadRateTypeId === eachPaymentType.id) {
          usageCounter += 1;
        }
        if (usageCounter === 1) {
          break;
        }
      }
      return usageCounter !== 1;
    });
  } else {
    filteredPayTypes = availablePayTypesArg;
  }

  return filteredPayTypes;
};

export const RenderAsteriskName = (name: string, colorCode: string) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{ display: 'flex', color: colorCode != '' ? colorCode : '' }}
    >
      {name + ' '}
      <Typography sx={{ color: theme.palette.warning.dark + ' !important' }}>
        {' '}
        *
      </Typography>
    </Typography>
  );
};

export const getFinanceOverviewAccordionDataWithID = async (
  data: any
): Promise<ListDataType | undefined> => {
  let financeOverviewListData: any = {};
  if (!data?.type) {
    return;
  }
  switch (data?.type) {
    case EPaymentDetailType.Dispatcher:
      financeOverviewListData =
        await financeLoadService.GetFinanceLoadDispatcherList(data.loadId);
      break;
    case EPaymentDetailType.Tractor:
      financeOverviewListData =
        await financeLoadService.GetFinanceLoadTractorList(data.paymentId);
      break;
    case EPaymentDetailType.Driver:
      financeOverviewListData =
        await financeLoadService.GetFinanceLoadDriverList(data.paymentId);
      break;
    case EPaymentDetailType.Carrier:
      financeOverviewListData =
        await financeLoadService.GetFinanceLoadCarrierList(data.loadId);
      break;
  }

  if (financeOverviewListData instanceof ServiceError) {
  } else if (financeOverviewListData != null) {
    const data = financeOverviewListData as ListDataType;
    return data;
  }
};

export const getFinanceOverviewAccordionDataWithOutID = async (
  data: any
): Promise<ListDataType | undefined> => {
  let financeOverviewListData: any = {};
  switch (data.type) {
    case 'Driver':
      const paramdata: requestParamsFinanceOverview = {
        loadId: data.loadId,
        driverId: data.id,
      };
      financeOverviewListData =
        await financeLoadService.CreateFinanceLoadDriverInitialLineItem(
          paramdata
        );
      break;
    case 'Tractor':
      const param = {
        loadId: data.loadId,
        tractorId: data.id,
      };
      financeOverviewListData =
        await financeLoadService.CreateFinanceLoadTractorInitialLineItem(param);
      break;
    case 'Dispatcher':
      financeOverviewListData =
        await financeLoadService.CreateFinanceLoadDispatcherInitialLineItem(
          data.loadId
        );
      break;
    case EPaymentDetailType.Carrier:
      financeOverviewListData =
        await financeLoadService.CreateFinanceLoadCarrierInitialLineItem(
          data.loadId
        );
      break;
  }
  if (financeOverviewListData instanceof ServiceError) {
  } else if (financeOverviewListData != null) {
    const data = financeOverviewListData as ListDataType;
    return data;
  }
};

export const getFinanceOverviewStatementInfo = async (
  data: SelectEntityType
): Promise<requestStatuesStatementInfo | undefined> => {
  let financeOverviewListData:
    | requestStatuesStatementInfo
    | ServiceError
    | null = {} as requestStatuesStatementInfo;
  const userStorage = StorageManager.getUser();
  switch (data.type) {
    case 'Driver':
      const paramdata: requestParamsFinanceOverview = {
        loadId: data.loadId,
        entityId: data.id,
        organizationId: userStorage.organizationId,
        entityType: data?.type?.toUpperCase?.() as ExpenseEntityType,
      };
      financeOverviewListData =
        await financeLoadService.GetDriverStatuesStatementInfo(paramdata);
      break;
    case 'Tractor':
      const paramTractor: requestParamsFinanceOverview = {
        loadId: data.loadId,
        entityId: data.id,
        organizationId: userStorage.organizationId,
        entityType: data?.type?.toUpperCase?.() as ExpenseEntityType,
      };
      financeOverviewListData =
        await financeLoadService.GetTractorStatuesStatementInfo(paramTractor);
      break;
    case 'Dispatcher':
      const paramDispatcher: requestParamsFinanceOverview = {
        loadId: data.loadId,
        entityId: data.id,
        organizationId: userStorage.organizationId,
        entityType: data?.type?.toUpperCase?.() as ExpenseEntityType,
      };
      financeOverviewListData =
        await financeLoadService.GetDispatcherStatuesStatementInfo(
          paramDispatcher
        );
      break;
    case EPaymentDetailType.Carrier:
      financeOverviewListData = {} as requestStatuesStatementInfo;
      break;
  }

  if (financeOverviewListData instanceof ServiceError) {
  } else if (financeOverviewListData != null) {
    const data = financeOverviewListData as requestStatuesStatementInfo;
    return data;
  }
};

export const getPayTypeIdListName = (
  payTypeIdList: Array<string>,
  allPaymentTypes: PaymentType[]
) => {
  let displayInformation: any = '';
  if (payTypeIdList?.length > 0) {
    payTypeIdList.map((id: string) => {
      allPaymentTypes.filter((arr: PaymentType) => {
        if (id === arr.id) {
          if (displayInformation.length > 0) {
            displayInformation = displayInformation.concat(
              ' , ' + arr.itemName
            );
          } else {
            displayInformation = arr.itemName;
          }
        }
      });
    });
  }
  return displayInformation;
};

const fetchAllPaymentTypes = async (): Promise<PaymentType[]> => {
  const requestData = new GetFinancialItemRequest();
  const _allPaymentTypes: PaymentType[] = await getAllPaymentTypes(requestData);
  return _allPaymentTypes;
};
const fetchExpenseCategories = async (): Promise<ExpenseCategory[]> => {
  const _expenseCategories: ExpenseCategory[] = await getExpenseCategories();
  return _expenseCategories;
};

type Props = {
  loadId: string;
  loadStatus?: string;
  children: any;
  ModuleType: string;
  panelType: ESecondaryDetailsPanelType;
  openPayments: any;
  onClose: () => void;
  isBrokered?: boolean;
  isCarrier?: boolean;
  loadType?: string;
  isEmptyTrip?: boolean;
};

export const FinanceOverviewFormProvider = observer(
  ({
    loadId,
    loadStatus,
    children,
    ModuleType,
    panelType,
    openPayments,
    onClose,
    isBrokered,
    loadType,
    isEmptyTrip,
  }: Props): JSX.Element => {
    const { updateMainData } = useTripSettings();
    const { trackPromise } = useDetailsPanelStore;
    const {
      tripsStore,
      myLoadStore: { setUpdatedLoadId },
    } = useStores();
    const [availablePayTypes, setAvailablePayTypes] = useState<PaymentType[]>(
      []
    );
    const [allPaymentTypes, setAllPaymentTypes] = useState<PaymentType[]>([]);
    const [expenseCategories, setExpenseCategories] = useState<
      ExpenseCategory[]
    >([]);
    const [financeOverviewData, setFinanceOverviewData] = useState<
      FinanceLoadType | undefined
    >();
    const [renderSection, setRenderSection] = useState<string>(
      EFinanceOverview.FinanceOverview
    );
    const [openPayment, setOpenPayments] = useState<string>(openPayments);
    const [isCreatingNewPayment, setIsCreatingNewPayment] =
      useState<boolean>(false);
    const [selectRecordData, setSelectRecordData] = useState<
      ListDataType | undefined
    >();
    const [selectRecordList, setSelectRecordList] = useState<
      FinanceLoadloadexpenseListType[]
    >([]);
    const [selectEntityType, setSelectEntityType] = useState<
      SelectEntityType | undefined
    >();
    const [isSavebtnVisible, setSavebtnVisible] = useState<boolean>(false);
    const [statementInfo, setStatementInfo] = useState<
      requestStatuesStatementInfo | undefined
    >();
    const [loading, setLoading] = useState<boolean>(false);

    const isCarrier: boolean = useMemo(() => {
      return (
        selectEntityType?.type === EPaymentDetailType.Carrier ||
        openPayments?.type === EPaymentDetailType.Carrier
      );
    }, [selectEntityType?.type, openPayments?.type]);

    const { setValue, reset, getValues } = useFormContext();

    const onAfterAction = useCallback((): void => {
      const loadId = selectRecordData?.loadId;
      if (loadId) {
        setUpdatedLoadId?.(loadId);
        updateMainData?.({ updatedIds: [loadId] });
        tripsStore.updateMainData?.({ updatedIds: [loadId] });
      }
    }, [selectRecordData?.loadId, setUpdatedLoadId, updateMainData]);

    const createNewFinanceOverViewForRecord = useCallback(
      (
        FinanceRecord: FinanceLoadloadexpenseListType,
        type: string,
        id: string
      ) => {
        const doSave = async () => {
          const allPaymentTypesMap = getAllPaymentTypesMap(allPaymentTypes);
          const requestData = new LoadFinanceLineItemRequest(
            FinanceRecord,
            allPaymentTypesMap
          );
          const payLoad = {
            items: requestData,
            id: id,
          };
          let savedResponse;
          switch (type) {
            case 'Dispatcher':
              savedResponse =
                await financeLoadService.CreateFinanceLoadDispatcherLineItem(
                  payLoad
                );
              break;
            case 'Tractor':
              savedResponse =
                await financeLoadService.CreateFinanceLoadTractorLineItem(
                  payLoad
                );
              break;
            case 'Driver':
              savedResponse =
                await financeLoadService.CreateFinanceLoadLineItem(payLoad);
              break;
          }
          if (savedResponse instanceof ServiceError) {
            // should be decided what to do
            return null;
          } else {
            onAfterAction();
          }
        };

        doSave();
      },
      [allPaymentTypes, onAfterAction]
    );

    const updateFinanceOverViewForRecord = useCallback(
      (
        FinanceRecord: FinanceLoadloadexpenseListType,
        type: string,
        id: string
      ) => {
        const doSave = async () => {
          FinanceRecord.map((currElement: any, index: any) => {
            const isDeduction = getFinanceDeduction(
              allPaymentTypes,
              currElement.loadRateType
            );
            if (isDeduction) {
              FinanceRecord[index].amount = -Math.abs(currElement.amount);
            }
          });
          const payLoad = {
            items: FinanceRecord,
            id: id,
          };
          let savedResponse;
          switch (type) {
            case 'Dispatcher':
              savedResponse =
                await financeLoadService.UpdateFinanceLoadDispatcherLineItem(
                  payLoad
                );
              break;
            case 'Tractor':
              savedResponse =
                await financeLoadService.UpdateFinanceLoadTractorLineItem(
                  payLoad
                );
              break;
            case 'Driver':
              savedResponse =
                await financeLoadService.UpdateFinanceLoadLineItem(payLoad);
              break;
          }
          if (savedResponse instanceof ServiceError) {
            // should be decided what to do
            return null;
          } else {
            onAfterAction();
          }
        };

        doSave();
      },
      [allPaymentTypes, onAfterAction]
    );

    const updateLoadCarrierExpense = async (payload: LoadCarrierExpenseDTO) => {
      const updatedData = payload.items;
      updatedData?.map((currElement: any, index: any) => {
        const isDeduction = getFinanceDeduction(
          allPaymentTypes,
          currElement.loadRateType
        );
        if (isDeduction) {
          updatedData[index].amount = -Math.abs(currElement.amount);
        }
      });
      payload.items = updatedData;
      const savedResponse = await trackPromise(
        financeLoadService.updateLoadCarrierExpense(
          new LoadCarrierExpenseDTORequest(payload)
        ),
        panelType,
        200
      );
      return savedResponse;
    };

    const resetFinanceOverviewData = useCallback(
      (Details: SelectEntityType, isResetForm: boolean) => {
        const doUpdate = async () => {
          const request = new ByLoadIdRequest();
          request[loadType != 'MANIFEST' ? 'loadId' : 'manifestId'] = loadId;
          const financeOverviewData = await trackPromise(
            getFinanceOverviewData(request),
            panelType
          );

          if (financeOverviewData) {
            setFinanceOverviewData(financeOverviewData);
            setValue('financeOverviewData', financeOverviewData);
          }
          const data = await trackPromise(
            getFinanceOverviewAccordionDataWithID(Details),
            panelType
          );
          if (data) {
            const isDirtyCheck = getValues('isSavebtnVisible');
            if (isDirtyCheck && isResetForm) {
              const recordList = getValues('selectRecordList');
              const updatedList = data?.items;
              data?.items.map((eachPaymentType, index) => {
                for (
                  let indexNumber = 0;
                  indexNumber < recordList.length;
                  indexNumber += 1
                ) {
                  if (
                    recordList[indexNumber].loadRateType ===
                    eachPaymentType.loadRateType
                  ) {
                    updatedList[index] = recordList[indexNumber];
                  }
                }
              });
              data.items = updatedList;
            } else {
              reset();
            }
            setSelectRecordData(data);
            setSelectRecordList(data?.items ? data.items : []);
            setValue('selectRecordList', data?.items ? data.items : []);
            setValue('selectRecordData', data);
            if (isCarrier) {
              setValue('receivedPayments', data?.receivedPayments ?? []);
            }
          }
        };
        doUpdate();
      },
      [isCarrier]
    );

    const deleteFinanceOverViewForLoad = useCallback(
      (
        FinanceRecord: FinanceLoadloadexpenseListType,
        type: string,
        id: string
      ) => {
        const doDelete = async () => {
          const payLoad = {
            items: FinanceRecord,
            id: id,
          };
          let deleteResponse;
          switch (type) {
            case 'Dispatcher':
              deleteResponse =
                await financeLoadService.DeleteFinanceLoadDispatcherLineItem(
                  payLoad
                );
              break;
            case 'Tractor':
              deleteResponse =
                await financeLoadService.DeleteFinanceLoadTractorLineItem(
                  payLoad
                );
              break;
            case 'Driver':
              deleteResponse =
                await financeLoadService.DeleteFinanceLoadLineItem(payLoad);
              break;
          }
          if (deleteResponse instanceof ServiceError) {
            // should be decided what to do
            return null;
          } else {
            onAfterAction?.();
          }
        };

        doDelete();
      },
      [onAfterAction]
    );
    const resetForm = useCallback(
      (data: ListDataType) => {
        const doReset = () => {
          reset();
          setTimeout(() => {
            setSelectRecordData(data);
            setSelectRecordList(data.items);
            setValue('selectRecordList', data.items);
            setValue('selectRecordData', data);
            if (isCarrier) {
              setValue('receivedPayments', data?.receivedPayments ?? []);
            }
          }, 10);
        };
        doReset();
      },
      [0]
    );

    useEffect(() => {
      if (
        openPayments &&
        renderSection != EFinanceOverview.FinanceOverviewDetail
      ) {
        getPayItemFunction();
        if (openPayments?.type) {
          setSelectEntityType({ type: openPayments.type });
        }
        setRenderSection(EFinanceOverview.FinanceOverviewDetail);
      }
    }, [openPayments, openPayments?.driverAmountForAssign?.items]);

    const getPayItemFunction = () => {
      if (!openPayments) return;
      setSelectRecordData(openPayments.driverAmountForAssign);
      setSelectRecordList(
        openPayments.driverAmountForAssign?.items
          ? openPayments.driverAmountForAssign.items
          : []
      );
      setValue(
        'selectRecordList',
        openPayments.driverAmountForAssign?.items
          ? openPayments.driverAmountForAssign.items
          : []
      );
      setValue('selectRecordData', openPayments.driverAmountForAssign);
      if (isCarrier) {
        setValue(
          'receivedPayments',
          openPayments.driverAmountForAssign?.receivedPayments
        );
      }
      setAvailablePayTypes(allPaymentTypes);
    };

    async function prepareDataForForm() {
      setLoading(true);
      const request = new ByLoadIdRequest();
      request[loadType != 'MANIFEST' ? 'loadId' : 'manifestId'] = loadId;
      const financeOverviewData = await getFinanceOverviewData(request);
      const allPaymentTypes = await trackPromise(
        fetchAllPaymentTypes(),
        panelType
      );
      const expenseCategories = await trackPromise(
        fetchExpenseCategories(),
        panelType
      );
      setFinanceOverviewData(financeOverviewData);
      setAllPaymentTypes(allPaymentTypes);
      setExpenseCategories(expenseCategories);
      setLoading(false);
    }

    useEffect(() => {
      prepareDataForForm();
    }, [loadId]);

    const handleLoadTripAction: EventCallbackFunction<LoadTripActionData> = (
      data
    ) => {
      if (
        data.id === loadId &&
        [
          ELoadStatus.LOAD_COMPLETED,
          ELoadStatus.CANCELLED,
          ELoadStatus.AVAILABLE,
        ].includes(data.toStatus)
      ) {
        prepareDataForForm();
      }
    };

    useEmit({
      [EventTypes.LOAD_TRIP_ACTION]: handleLoadTripAction,
    });

    const contextData = {
      availablePayTypes,
      expenseCategories,
      financeOverviewData,
      allPaymentTypes,
      setAvailablePayTypes,
      renderSection,
      setRenderSection,
      ModuleType,
      selectRecordData,
      setSelectRecordData,
      selectRecordList,
      setSelectRecordList,
      selectEntityType,
      setSelectEntityType,
      isSavebtnVisible,
      setSavebtnVisible,
      createNewFinanceOverViewForRecord,
      deleteFinanceOverViewForLoad,
      updateFinanceOverViewForRecord,
      setFinanceOverviewData,
      resetFinanceOverviewData,
      openPayments: openPayment,
      setOpenPayments,
      onClose,
      statementInfo,
      setStatementInfo,
      resetForm,
      isBrokered,
      isCarrier,
      updateLoadCarrierExpense,
      panelType,
      loadId,
      loadStatus,
      loading,
      isCreatingNewPayment,
      setIsCreatingNewPayment,
      prepareDataForForm,
      isEmptyTrip,
    };

    return (
      <FormContext.Provider value={contextData}>
        {loading ? (
          <Stack
            mt={3}
            spacing={3}
            direction="column"
            sx={{
              height: '100%',
              width: '100%',
            }}
          >
            {arr.map((index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={'100%'}
                height={'1rem'}
              />
            ))}
          </Stack>
        ) : (
          children
        )}
      </FormContext.Provider>
    );
  }
);

export default FormContext;
