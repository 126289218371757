import React, { FC } from 'react';
import { EquipmentType } from '../../../views/maintenanceRecords/constants/constants';
import { MaintenanceHistoryContextWrapper } from './context/MaintenanceHistoryContext';
import MaintenanceTabDetailsWrapper from './MaintenanceTabDetailsWrapper';
import { IMaintenanceHistory } from '../../../models/DTOs/maintenance/maintenanceHistory/Model';

export interface MaintenanceTabDetailsProps {
  assetId: number;
  equipmentTypes: EquipmentType;
  defaultMaintenanceRecord?: Partial<IMaintenanceHistory>;
  disableAllFields?: boolean;
}
const MaintenanceTabDetails: FC<MaintenanceTabDetailsProps> = (
  props: MaintenanceTabDetailsProps
) => {
  return (
    <MaintenanceHistoryContextWrapper>
      <MaintenanceTabDetailsWrapper {...props} />
    </MaintenanceHistoryContextWrapper>
  );
};

export default MaintenanceTabDetails;
