export const LoadsearchConstants = {
  reloadScoreLabel: 'Reload Score Greater Than',
  reloadScore: 'Reload Score',
  reloadScoreDesc:
    'Based on the inbound and outbound loads of the destination market, we rank all US markets from 1 (Poor) to 5 (Best)',
  breakdownLabel: 'Here’s the breakdown',
  estimatedBidAMount: 'Estimated Bid Amount',
  offer: 'Offer',
  variableCosts: 'Variable Costs',
  fixedCosts: 'Fixed Costs',
  netProfit: 'Net Profit',
  brokerInfo: 'Broker Info',
  mcnumber: 'MC Number',
  creditRating: 'Credit Rating',
  highProbabilityOfFindingLoad:
    'High probabity of finding load at the destination',
  mediumProbabilityOfFindingLoad:
    "'Medium probabity of finding load at the destination",
  lowProbabilityOfFindingLoad:
    'Low probabity of finding load at the destination',
  age: 'Age',
  ageDesc: 'How long ago this load was posted, reposted, or updated',
  pickup: 'Pickup',
  city: 'City',
  dateTime: 'Date Time',
  deadheadMiles: 'Deadhead Miles',
  loadedMiles: 'Loaded Miles',
  totalMiles: 'Total Miles',
  deadheadOriginMiles: 'Deadhead Origin Miles',
  deadheadDestinationMiles: 'Deadhead Destination Miles',
  stops: 'Stops',
  dropOff: 'Dropoff',
  offerDesc: 'The amount that the load pays as posted by the shipper or broker',
  offerPrice: 'Offer Price',
  day: 'Day',
  profit: 'Profit',
  mile: 'Mile',
  mileageDesc: 'Estimated Revenue / Trip Miles',
  marketRate: 'Market Rate',
  marketRateDesc:
    'We maintain a database with up-to-date average rates for the entire US market. This data point can be useful during load revenue negotiation with the shipper or broker',
  equipment: 'Equipment',
  equipmentDesc: 'Equipment required to cover the load',
  equipmentLength: 'Equipment Length',
  equipmentWeight: 'Equipment Weight',
  broker: 'Broker',
  freightBroker: 'freight Broker',
  booked: 'Booked',
  dropped: 'Dropped',
  pending: 'Pending',
  book: 'Book',
  howDidItGo: 'How did it Go?',
  noLuck: 'No Luck',
  gotTheLoad: 'Got The Load',
  loadboards: 'Loadboards',
  origin: 'Origin',
  destination: 'Destination',
  pickupDate: 'Pickup Date',
  equipmentType: 'Equipment Type',
  customerPreference: 'Customer Preference',
  loadType: 'Load Type',
  showActualOffersOnly: 'Show Actual Offers Only?',
  maxAgeofLoad: 'Max Age of Load (Hour)',
  maxEquipmentLength: 'Max Equipment Length (in feet)',
  maxEquipmentWeight: 'Max Equipment Weight (in lbs)',

  loadSearchViewPendingLoads: 'Pending Loads',
  loadSearchViewBookedLoads: 'Booked Loads',

  loadSearchViewBookmarks: 'Bookmarks',
  loadSearchViewLoadsearch: 'Search Loads',

  NestedDropDownSelectDrivers: 'Select Drivers',

  onRefreshHandlerSearch: 'Search',
  onRefreshHandlerRefresh: 'Refresh',
  lastrefreshedat: 'last refreshed at',
  loadId: 'Load Id',
  appliedColumnsActions: 'Actions',
};
