import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

const MarkerLoader = ({}) => {
  return (
    <>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#bdbdbd',
        }}
        size={34}
        thickness={6}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: '#ffffff',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
            fill: '#2B64CB',
          },
        }}
        size={34}
        thickness={6}
      />
    </>
  );
};

export default MarkerLoader;
