import Grid from '@mui/material/Grid';
import React from 'react';
import { Controller } from 'react-hook-form';
import { TimeDuration, TimeDurationProps } from './TimeDuration';

export interface FormTimeDurationProps extends TimeDurationProps {
  control?: any;
  name: string;
  label?: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  styleProps?: React.CSSProperties;
  variant?: 'standard' | 'outlined' | 'filled';
  onChangeCallback?: (
    data: any,
    name: string | undefined,
    keyboardInputValue: any
  ) => void;
  sizes?: {
    xs: number;
  };
  is24HFormat?: boolean;
}
const FormTimeDuration = ({
  control,
  name,
  required = false,
  disabled = false,
  styleProps = {},
  variant = 'standard',
  label,
  onChangeCallback,
  value: defaultValue,
  sizes = {},
  helperText,
  is24HFormat,
  ...restProps
}: FormTimeDurationProps) => {
  return (
    <Grid xs={sizes.xs} item style={{ marginBottom: 20 }}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <TimeDuration
              name={name}
              label={label}
              value={value}
              variant={variant}
              required={required}
              disabled={disabled}
              error={!!error?.message}
              helperText={error ? error.message : helperText}
              onChange={(newValue: any, keyboardInputValue?: string) => {
                if (newValue != value) {
                  //Fix:  prevent infinite loops.
                  onChange(newValue, keyboardInputValue);
                  if (typeof onChangeCallback === 'function') {
                    onChangeCallback(newValue, name, keyboardInputValue);
                  }
                }
              }}
              styleProps={styleProps}
              is24HFormat={is24HFormat}
              {...restProps}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </Grid>
  );
};

export default React.memo(FormTimeDuration);
