import { Grid, SxProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { IRelativeDateRangeOption } from '../../../ui-kit/components/RelativeDateRange';
import { LoadboardPicker } from './LoadboardPicker';
import { LoadBoardAccount, LoadboardPickerProps } from './types';

export interface LoadboardPickerFormProps extends LoadboardPickerProps {
  control?: Control;
  sizes?: {
    xs?: number;
  };
  wrapperStyles?: SxProps;
  defaultValue?: IRelativeDateRangeOption;
}
export const LoadboardPickerForm = ({
  name,
  control,
  sizes = {},
  wrapperStyles = {},
  defaultValue,
  ...restProps
}: LoadboardPickerFormProps) => {
  return (
    <Grid xs={sizes.xs} item sx={{ marginBottom: '20px', ...wrapperStyles }}>
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <LoadboardPicker
              fullWidth={true}
              name={name}
              variant={'outlined'}
              isFormControl={true}
              onChange={(option: LoadBoardAccount[]) => {
                onChange(option, name);
              }}
              defaultOptions={value}
              {...restProps}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </Grid>
  );
};
