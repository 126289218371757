import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CommonConstants } from '../../../../../../locales/en/common';
import { DocumentConstants } from '../../../../../../locales/en/reports/document';
import { SteeringOutlined } from '../../../../../../ui-kit/components/Assets';
import MultilineCellAxele from '../../../../../../ui-kit/components/MultilineCell';
import UploadUpdated from '../../../../../Ui/FileUpload/UploadUpdated';
import TextField from '../../../../../Ui/TextField/TextField';
import { NotesStyles } from '../../../Notes/styles/styles';
import { HOLD_NOTES_TEXT_MAX_LENGTH } from './utils';

export const HoldLoadPopupForm = () => {
  const { control, watch, setValue } = useFormContext();
  const watchNotesText = watch('notesText');
  const watchShareDriver = watch('shareDriver');

  const onFileChange = (file: File[]) => {
    setValue('attachment', file, { shouldDirty: true });
  };

  const onDeleteAttachment = () => {
    setValue('attachment', undefined, { shouldDirty: true });
  };

  return (
    <Box>
      <Box>
        <TextField
          control={control}
          name="notesText"
          label={CommonConstants.notes}
          variant="outlined"
          inputProps={{ maxLength: HOLD_NOTES_TEXT_MAX_LENGTH }}
          rows={8}
          required={true}
          multiline
          styleProps={{
            borderRadius: '12px',
          }}
        />
        <Typography align="right" variant="body2">
          {`${watchNotesText?.length || 0}/${HOLD_NOTES_TEXT_MAX_LENGTH}`}
        </Typography>
      </Box>
      <Stack
        direction="row"
        width={'100%'}
        alignItems="center"
        mt={1}
        spacing={2}
      >
        <Box>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Box
                sx={{
                  ...(watchShareDriver
                    ? NotesStyles.driverChecked
                    : NotesStyles.driverUnchecked),
                  svg: {
                    ml: 1.5,
                  },
                }}
              >
                <Checkbox
                  disableRipple={true}
                  icon={<SteeringOutlined height={35} width={35} />}
                  checkedIcon={
                    <SteeringOutlined height={35} width={35} color="#FFFFFF" />
                  }
                  checked={Boolean(value)}
                  onChange={(event) => {
                    event.stopPropagation();
                    onChange(event);
                  }}
                />
              </Box>
            )}
            name="shareDriver"
            control={control}
          />
        </Box>
        <MultilineCellAxele
          title={DocumentConstants.shareWithDriver}
          subtitle={DocumentConstants.shareWithDriverSublabel}
          titleStyle={NotesStyles.shareDriver}
          subtitleStyle={NotesStyles.subShare}
          alignTitle="left"
          alignSubtitle="left"
        />
      </Stack>
      <Box mt={2} mb={2}>
        <UploadUpdated
          onChange={onFileChange}
          onRemoveCB={() => onDeleteAttachment()}
        />
      </Box>
    </Box>
  );
};
