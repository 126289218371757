import { Label } from './label';
import { PopupMessage } from './popup';
import { ToolTips } from './tooltips';
export const DashboardConstants = {
  financePerformanceTitle: 'Finance Performance for Company',
  tripOperationalSummary: 'Trip Operational Summary',
  revenueThisWeek: 'Revenue This Week',
  revenueTotalMile: 'Revenue / Total Miles',
  revenueLoadedMile: 'Revenue / Loaded Miles',
  revenueDriver: 'Revenue/Driver',
  openTrips: 'Open Trips',
  assignedTrips: 'Assigned Trips',
  potentialDelay: 'Potential Delay',
  needsAttention: 'Needs Attention',
  driverAvailibility: 'Driver Availability',
  tractorAvailibility: 'Tractor Availability',
  driverSafetyStanding: 'Driver Safety Standing',
  tractorSafetyStanding: 'Tractor Safety Standing',
  trailerSafetyStanding: 'Trailer Safety Standing',
  loadInvoices: 'Load Invoices',
  ...ToolTips,
  ...PopupMessage,
  ...Label,
};
