import EncryptedStorageManager from '../StorageManager/EncryptedStorageManager';
import { EventTypes } from '../EventEmitter/EventTypes';
import Events from '../EventEmitter/EventEmitter';
import axiosInstance from './axiosInstance';
import { jwtDecode } from 'jwt-decode';

export const refetchTokens = async () => {
  try {
    const refreshToken = EncryptedStorageManager.getItem('token')?.refresh;
    const tokens = await axiosInstance.get(
      '/web/auth/api/v2/users/token/refresh',
      {
        headers: {
          'REFRESH-TOKEN': refreshToken,
        },
      }
    );
    return tokens.data;
  } catch (e) {
    Events.emit(EventTypes.AUTH_ERROR);
    throw e;
  }
};

export const getPermisssionHashFromToken = (token?: any) => {
  if (token) {
    const tokenSub = jwtDecode(token).sub;
    if (tokenSub) {
      return JSON.parse(tokenSub).permissions;
    }
  }
  const localToken = EncryptedStorageManager.getItem('token')?.access;
  if (localToken) {
    const tokenSub = jwtDecode(localToken).sub;
    if (tokenSub) {
      return JSON.parse(tokenSub).permissions;
    }
  }
  return null;
};
