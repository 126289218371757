import { ServiceError } from '../../../../../api/interfaces';
import FormDialog from '../../../../../common/Ui/FormDialog';
import {
  ContactSummaryCustomer,
  CreateLocationContactRequest,
} from '../../../../../models';
import { contactValidationSchema } from '../../../../../validator/validationSchemas';
import AddContactForm from '../../../../customers/forms/AddContactForm';
import { stopFormService } from '../../services/stopForm.service';

interface IProps {
  data?: any;
  open?: boolean;
  onSuccess?: (newConact: ContactSummaryCustomer) => void;
  onClose?: () => void;
  locationId: number;
}

const LocationContacttDetailsForm = ({
  data,
  open,
  locationId,
  onSuccess,
  onClose,
}: IProps) => {
  const handleContactsUpdate = async (contact: ContactSummaryCustomer) => {
    const contactRequest = new CreateLocationContactRequest(
      contact,
      locationId
    );
    const response = await stopFormService.createLocationNewContact(
      contactRequest
    );
    if (response instanceof ServiceError) return;
    onSuccess?.(response!);
  };

  return (
    <FormDialog
      data={data}
      titleText={'Add New Contact'}
      actionLabel={'Add Contact'}
      open={open}
      onAction={handleContactsUpdate}
      handleClose={onClose}
      validationSchema={contactValidationSchema}
      disableActionBtnByDefault={false}
      contentRenderer={() => <AddContactForm />}
    ></FormDialog>
  );
};
export { LocationContacttDetailsForm };
