import TextField from '@mui/material/TextField';
import { FormEvent, useEffect, useState } from 'react';
import InputLabel from '../../InputLabel';
import { makeAsteriskRed } from '../Utils';

export interface TextInputProps {
  id?: string;
  label?: string;
  variant?: any;
  defaultValue?: any;
  fullWidth?: boolean;
  rows?: number;
  multiline?: boolean;
  type?: any;
  styleProps?: any;
  required?: boolean;
  error?: boolean | undefined;
  helperText?: any;
  onChange?: Function;
  onInput?: (event: FormEvent<HTMLInputElement>) => void;
  inputProps?: any;
  InputProps?: any;
  onBlur?: Function;
  autoFocus?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  name?: string;
  InputLabelProps?: any;
  size?: 'small' | 'medium' | undefined;
  fixedLabel?: boolean;
}

const TextInput = ({
  id = '',
  label = '',
  variant = '',
  defaultValue = '',
  rows = 1,
  multiline = false,
  type = '',
  styleProps,
  required = false,
  error = false,
  helperText,
  onChange,
  inputProps,
  InputProps,
  onBlur,
  fullWidth = false,
  autoComplete = 'off',
  autoFocus = false,
  disabled = false,
  placeholder = '',
  name,
  InputLabelProps = {},
  onInput,
  size,
  fixedLabel,
  ...props
}: TextInputProps) => {
  const [textValue, setTextValue] = useState();
  const [textError, setTextError] = useState<boolean>(error);

  useEffect(() => {
    setTextValue(defaultValue);
  }, [defaultValue]);

  const handleOnChange = (event: any) => {
    setTextValue(event.target.value);
    const textErrorCheck = required && event.target.value === '';
    textErrorCheck ? setTextError(true) : setTextError(false);
    if (onChange) onChange(event as any, textErrorCheck, id);
  };

  const handleOnBlur = (event: any) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <TextField
      fullWidth={fullWidth}
      id={id}
      label={fixedLabel ? <InputLabel>{label}</InputLabel> : label}
      variant={variant}
      onInput={onInput}
      value={
        props.hasOwnProperty('value') ? props.value : textValue ? textValue : ''
      }
      rows={rows}
      multiline={multiline}
      type={type}
      autoComplete={autoComplete}
      sx={{
        ...makeAsteriskRed,
        ...styleProps,
      }}
      required={required}
      error={(textError || error) && helperText}
      helperText={(textError || error) && helperText}
      onChange={handleOnChange}
      inputProps={inputProps}
      InputProps={InputProps}
      onBlur={handleOnBlur}
      autoFocus={autoFocus}
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      InputLabelProps={{ ...InputLabelProps, fixedLabel }}
      size={size}
    />
  );
};

export default TextInput;
