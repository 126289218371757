import axios from 'axios';
import { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
export const encodeSvg = (reactElement: ReactElement): string => {
  return (
    'data:image/svg+xml,' +
    encodeURIComponent(ReactDOMServer.renderToStaticMarkup(reactElement))
  );
};

export const checkPassScore = (pass: string) => {
  let score = 0;
  if (!pass) {
    return score;
  }

  // award every unique letter until 5 repetitions
  const letters: string[] = [];
  for (let i = 0; i < pass.length; i += 1) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = 0;

  Object.values(variations).forEach((variation) => {
    variationCount += +variation;
  });

  score += (variationCount - 1) * 10;

  return parseInt(score, 10);
};
export type keyCompareValidation = {
  [key: string]: string | boolean;
};
export const PASSWORD_SCORE = 50;

export const containsOnlyNumbers = (str: string) => /^\d+$/.test(str);

export const getCustomerIp = async () => {
  const res = await axios.get('https://api.ipify.org/?format=json');
  if (res?.data?.ip) {
    return res?.data?.ip;
  } else {
    return null;
  }
};
export const getClientTimeZoneOffset = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
};
