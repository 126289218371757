import { Type } from 'class-transformer';
import { CarrierDocumentsType } from '../../../subPages/carriers/constants/types';
import { PaginatedList, addressType } from '../../../types';
import { QueryParams } from '../commonMixed';

export enum ELookupExternalCarrierType {
  DOT = 'DOT',
  MC = 'MC',
  NAME = 'NAME',
}
export class CarrierContactDTO {
  phoneData: {
    extension: number;
    phone: string;
    countryCode: string;
  };
  email: string;
  description: string;
  name: string;
  isPrimary: boolean;
  constructor(dto: any) {
    Object.assign(this, dto);
  }
}
export class CarrierInsuranceDTO extends QueryParams {
  id: string;
  seqNumber: any;
  carrierId: string;
  type: string;
  amount: number;
  expDate: any;
  provider: string;
  producer: string;
  policyNumber: string;
  state: string;
  notes: string;
  fridgeBreakCoverage: boolean;
  assignedDocuments?: CarrierDocumentsType;
  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class CarrierDTO {
  id!: string;
  organizationId!: number;
  name!: string;
  status!: string;
  mc!: number;
  dot!: string;
  scac!: string;
  mode!: string;
  truckCount!: number | null;
  preferenceRate!: number | null;
  address!: {
    address: string;
    streetAddress: string;
    streetAddress2: any;
    city: string;
    state: string;
    zipcode: string;
    fullAddress: string;
    center: {
      lat: any;
      lng: any;
    };
  };
  contacts!: Array<CarrierContactDTO>;
  insurances!: Array<CarrierInsuranceDTO>;
  constructor(dto: any) {
    Object.assign(this, dto);
  }
}

export class CreateCarrierRequest extends QueryParams {
  id?: string;
  organizationId: number;
  status: string;
  name: string;
  mc: number;
  dot: string;
  scac: string;
  mode: string;
  truckCount: number;
  preferenceRate: number;
  notes: string;
  contacts: Array<CarrierContactDTO>;
  insurances: Array<CarrierInsuranceDTO>;
  address: addressType;

  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}
export class GetPaginatedCarrierListQueryParams extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  sort!: string;
  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class GetPaginatedCarrierListRequest extends QueryParams {
  nameFilter!: Array<string | number>;
  modeFilter!: Array<string | number>;
  contactNameFilter!: Array<string | number>;
  statusFilter!: Array<string | number>;
  mcFilter!: Array<string | number>;
  dotFilter!: Array<string | number>;
  scacFilter!: Array<string | number>;
  stateFilter!: Array<string | number>;
  constructor(dto: any) {
    super(false);
    Object.assign(this, dto);
  }
}

export class GetPaginatedCarrierListResponse extends PaginatedList {
  @Type(() => CarrierDTO)
  content!: CarrierDTO[];
}

export class CarrierStaticDataDTO {
  carrierStatus: Array<{
    value: string;
    key: string;
  }>;
  workMode: Array<{
    value: string;
    key: string;
  }>;
  insuranceType: Array<{
    value: string;
    key: string;
  }>;
}

export class GetSearchNameRequest extends QueryParams {
  carrierSearchName: string;
  pageNumber!: number;
  pageSize!: number;

  constructor(dto: any) {
    super(true);
    this.carrierSearchName = dto.carrierSearchName;
    this.pageNumber = dto.pageNumber;
    this.pageSize = dto.pageSize;
  }
}
export class SearchNameResponseDTO extends Array<string> {}
export class GetSearchNameResponse extends PaginatedList {
  @Type(() => SearchNameResponseDTO)
  content!: SearchNameResponseDTO[];
}

export class GetSearchContactNameRequest extends QueryParams {
  organizationId: number;
  carrierSearchContactName: string;

  constructor(dto: any) {
    super(true);
    this.carrierSearchContactName = dto.carrierSearchContactName;
  }
}
export class SearchContactNameResponseDTO extends Array<string> {}
export class GetSearchContactNameResponse extends PaginatedList {
  @Type(() => SearchContactNameResponseDTO)
  content!: SearchContactNameResponseDTO[];
}
export class GetSearchMcRequest extends QueryParams {
  organizationId: number;
  carrierSearchMc: string;

  constructor(dto: any) {
    super(true);
    this.carrierSearchMc = dto.carrierSearchMc;
  }
}

export class SearchMcResponseDTO extends Array<string> {}
export class GetSearchMcResponse extends PaginatedList {
  @Type(() => SearchMcResponseDTO)
  content!: SearchMcResponseDTO[];
}
export class GetSearchDotRequest extends QueryParams {
  organizationId: number;
  carrierSearchDot: string;

  constructor(dto: any) {
    super(true);
    this.carrierSearchDot = dto.carrierSearchDot;
  }
}
export class SearchDotResponseDTO extends Array<string> {}
export class GetSearchDotResponse extends PaginatedList {
  @Type(() => SearchDotResponseDTO)
  content!: SearchDotResponseDTO[];
}
export class GetSearchScacRequest extends QueryParams {
  organizationId: number;
  carrierSearchScac: string;

  constructor(dto: any) {
    super(true);
    this.carrierSearchScac = dto.carrierSearchScac;
  }
}
export class SearchScacResponseDTO extends Array<string> {}
export class GetSearchScacResponse extends PaginatedList {
  @Type(() => SearchScacResponseDTO)
  content!: SearchScacResponseDTO[];
}
export class GetCarrierCountTotalResponse {
  //
}
export class LookupExternalCarrierItem {
  name: string;
  mc: number | null;
  dot: string;
  address: string;
  truckCount: number | null;
  constructor(dto) {
    Object.assign(this, dto);
  }
}

export class CreateCarrierContactRequest extends QueryParams {
  organizationId: number;
  carrierId: string;

  constructor(dto: any) {
    super(false);
    Object.assign(this, dto);
  }
}
export class CreateCarrierContactResponse {
  //
}

export class CreateCarrierInsuranceRequest extends QueryParams {
  organizationId: number;

  constructor(dto: any) {
    super(false);
    Object.assign(this, dto);
  }
}

export class ExportCarrierQueryParams extends QueryParams {
  sort!: string;
  gridColumnMetadataList!: string[] | null;
  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class ExportCarrierPayload extends QueryParams {
  constructor(params: any) {
    super(false);
    Object.assign(this, params);
  }
}

export class GetCarrierOneRequest extends QueryParams {
  organizationId!: number;
  id: string;

  constructor(dto: any) {
    super(true);
    this.id = dto.id;
  }
}

export class CarrierMessageResponse {
  message: string;
}

export class UpdateCarrierStatusRequest extends QueryParams {
  id?: string;
  organizationId: number;
  status: string;
  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}
