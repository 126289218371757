import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { COLUMN_GAP, ROW_GAP, TAB_HEIGHT } from './constants';

const StyledTabs: ForwardRefRenderFunction<HTMLButtonElement, TabsProps> = (
  props,
  ref
) => {
  return (
    <Tabs
      sx={{
        minHeight: TAB_HEIGHT,
        '.MuiTabs-indicator': {
          display: 'none',
        },
        '.MuiTabs-flexContainer': {
          gap: `${ROW_GAP}px 1px`,
          flexWrap: 'wrap',
        },
      }}
      ref={ref}
      {...props}
    />
  );
};

export default forwardRef(StyledTabs);
