import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CustomersList from '../../../../ui-kit/components/CustomersList';
import { loadTenderService } from '../../../../api';
import { LoadTenderMap } from '../../../../utils/customerMap';
import TerminalController from '../../terminals/TerminalController';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { observer } from 'mobx-react';
import SelectedLoadTender from './selectedLoadTender';
import {
  GetLoadTendersRequest,
  LoadTenderResponseDTO,
} from '../../../../models/DTOs/LoadTender/Requests';
import { ServiceError } from '../../../../api/interfaces';
import {
  IloadTenderData,
  IloadTenderListWithLogo,
} from '../../../../types/LoadTenderTypes';
import { gridTenderStyle } from './style/turvoStyle';

const LoadTenders = () => {
  const [loadTenderData, setLoadTenderData] = useState<
    LoadTenderResponseDTO | ServiceError | null | IloadTenderData[]
  >();
  const [loadTenderListWithLogo, setLoadTenderListWithLogo] =
    useState<IloadTenderListWithLogo[]>();
  const [selectedLoadTender, setSelectedLoadTender] =
    useState<IloadTenderListWithLogo>();
  const [allTerminals, setAllTerminals] = useState<any[] | null>([]);

  const { terminals } = TerminalController.instance();
  const { getGlobalTerminalsIds } = useRootStore();

  const getTenderList = async () => {
    const request = new GetLoadTendersRequest();
    const response = (await loadTenderService.getLoadTenderList(request)) as
      | LoadTenderResponseDTO
      | ServiceError
      | any;
    setLoadTenderData(response);
    getLoadTenders(response);
  };

  useEffect(() => {
    getTenderList();
    initialSelectedProvider(loadTenderListWithLogo);
  }, []);

  const getLoadTenders = (loadTenderData: IloadTenderData): void => {
    const allLoadTenders: IloadTenderListWithLogo[] = [];

    loadTenderData?.providerStatusMap?.forEach(
      (data: { providerId: number; status: string }) => {
        if (data.providerId > 0) {
          LoadTenderMap.forEach((item) => {
            if (data.providerId === item.providerId) {
              const tender: IloadTenderListWithLogo = {
                providerId: item.providerId,
                providerName: item.providerName,
                logo: item.logo,
                status: data.status,
                description: item.description,
                logoWhite: item.logoWhite,
                gradient: item.gradient,
              };
              allLoadTenders.push(tender);
            }
          });
        }
      }
    );
    setLoadTenderListWithLogo([...allLoadTenders]);
    initialSelectedProvider([...allLoadTenders]);
  };

  const initialSelectedProvider = (
    allLoadTenders: Array<IloadTenderListWithLogo> | undefined
  ) => {
    const providerDetailsId = Number(
      sessionStorage.getItem('selectedLoadTender')
    );
    if (allLoadTenders && allLoadTenders.length > 0 && providerDetailsId) {
      const item = allLoadTenders.filter(
        (allLoadTenderItem: any) =>
          providerDetailsId === allLoadTenderItem['providerId']
      );
      if (item && item.length > 0) {
        setSelectedLoadTender(item[0]);
      }
    } else {
      if (allLoadTenders && allLoadTenders.length > 0 && allLoadTenders[0]) {
        setSelectedLoadTender(allLoadTenders[0]);
      }
    }
  };

  const selectProvider = (data: IloadTenderListWithLogo) => {
    sessionStorage.setItem('selectedLoadTender', data['providerId']);
    setSelectedLoadTender(data);
  };

  const getTerminals = async () => {
    await Promise.all([TerminalController.instance().getTerminals()]).then(
      (res) => {
        const terminalIds: Array<string> = [];
        res &&
          res.length > 0 &&
          res.map((data: any) => {
            data &&
              data.length > 0 &&
              data.map((el: any) => {
                terminalIds.push(el.id);
              });
          });
        setAllTerminals(res[0]);
      }
    );
  };

  useEffect(() => {
    getTerminals();
  }, [terminals, getGlobalTerminalsIds]);

  console.log(loadTenderListWithLogo);

  return (
    <Grid container>
      <Grid item xs={2} px={2} sx={{ ...gridTenderStyle }}>
        {selectedLoadTender && loadTenderListWithLogo && (
          <CustomersList
            allCustomers={loadTenderListWithLogo}
            selectedProviderDetails={selectedLoadTender}
            selectProvider={selectProvider}
          />
        )}
      </Grid>

      <Grid
        item
        xs={10}
        className="selected-transporter-block"
        sx={{ ...gridTenderStyle }}
      >
        {selectedLoadTender && (
          <SelectedLoadTender
            currentCustomerData={selectedLoadTender}
            loadTenderData={loadTenderData}
            allTerminals={allTerminals}
            getTenderList={getTenderList}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default observer(LoadTenders);
