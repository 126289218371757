import { Box } from '@mui/material';
import { ColumnHeader } from '../../../../components/Gantt';
import React, { forwardRef } from 'react';
import { LockButton } from '../generic';

interface IDriverColumnHeader {
  onLock?: () => void;
  isLocked?: boolean;
  lockButtonId?: string;
  className?: string;
  btnStyles?: object;
  allowLockAll?: boolean;
}

const ActionColumnHeader = forwardRef((props: IDriverColumnHeader, ref) => {
  const { allowLockAll = true, ...rest } = props;
  return (
    <Box>
      <Box display="flex" gap="10px">
        <ColumnHeader name={['Lock']} />
        <ColumnHeader name={['Reject']} />
      </Box>
      {allowLockAll && (
        <Box display={'flex'}>
          <LockButton
            ref={ref}
            id={props?.lockButtonId}
            isLocked={props.isLocked}
            onClick={props.onLock}
            className={props?.className}
            btnStyles={props?.btnStyles}
          />
        </Box>
      )}
    </Box>
  );
});

export default ActionColumnHeader;
