import { t } from 'i18next';
import moment, { Moment } from 'moment';
import { useFormContext } from 'react-hook-form';
import DatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';
import FormDialog from '../../../../common/Ui/FormDialog';
import { useInvoices } from '../../../../contexts/InvoicesContext';
import { StatusChangeRequest } from '../../../../models';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import { downloadFile } from '../../../../utils/Doc';
import { useInvoicePage } from '../../../../views/finance/invoices/InvoicePageContext';
import InvoiceActions from '../../InvoiceActions';
import {
  INVOICED,
  PAID,
  READY_TO_INVOICE,
  REVERT_TO_COMPLETED,
} from '../../constants';
import { EmailFormType, InvoiceModel } from '../../models/InvoiceModel';
import { AttachmentsDialog } from '../InvoiceActions/InvoiceDownloadAction';
import {
  InvoiceSendEmailDialog,
  MultipleSendEmailDialog,
} from '../InvoiceActions/InvoiceSendEmailAction';
import { useStores } from '../../../../store/root.context';
import { getOrganizationId } from '../../../../common/TimeoffDialog/utils';
import { InvoiceBulkDownloadDialog } from '../InvoiceActions/InvoiceBulkDownloadAction';
import { gridPageSize } from '@/utils';
import { DEFAULT_INVOICE_SORT_NAME } from '@/views/finance/invoices/constants';
import { getCurrentInvoiceStatuses } from '../../utils';
import useFilterStorage from '@/services/storage';
const params = {
  pageNumber: 1,
  pageSize: gridPageSize,
};
export default function Actions({
  showEmailPopup,
  setShowEmailPopup,
  selectDateDialogOpen,
  setSelectDateDialogOpen,
  currentAction,
  downloadDialogOpen = false,
  setDownloadDialogOpen,
  invoicedToCompletedModal,
  setInvoicedToCompletedModal,
  paidToInvoicedModal,
  setPaidToInvoicedModal,
  readyToInvoicedModal,
  setReadyToInvoicedModal,
  invoices,
  isStatusUpdated,
  showBulkDownloadModal = false,
  setShowBulkDownloadModal,
  canAddInvoice = true,
}: PropTypes) {
  const { getIsTerminalEnabled } = useRootStore();
  const {
    invoiceStore: {
      tableData,
      setTableData,
      selectedItem,
      setSelectedItem,
      warnings,
      currentView,
      filters,
      getInvoicesList,
      currentView: { id: stripId },
      component,
    },
  } = useStores();
  const LStorageHook = useFilterStorage({ page: component });

  const storageData = LStorageHook.getStorage() || {};

  const currentInvoicesOrInvoice = invoices.length > 1 ? invoices : invoices[0];

  const handleSendMultipleEmails = async (data: EmailFormType) => {
    setShowEmailPopup(false);
    const response = await InvoiceActions.sendMultipleEmail(
      invoices,
      data,
      getIsTerminalEnabled
    );
    if (response) {
      if (data.markAsInvoiced) {
        const requestData = new StatusChangeRequest();
        requestData.invoiceIds = invoices.map((item) =>
          item.childInvoice ? item.masterInvoiceId : item.id
        );
        requestData.invoicedOn = moment();
        requestData.paidOn = invoices[0].paidOn;
        requestData.status = INVOICED;
        requestData.organizationId = getOrganizationId();
        changeStatusRequest(requestData, invoices, INVOICED);

        if (selectedItem) {
          setSelectedItem(null);
        }
      } else {
        const requestParams = {
          ...filters,
          ...params,
          sort: storageData[stripId]?.sort || DEFAULT_INVOICE_SORT_NAME,
          pageNumber: 1,
        };
        const requestBody = getCurrentInvoiceStatuses(stripId, requestParams);
        setTimeout(() => {
          getInvoicesList(requestBody);
        }, 2000);
      }
    }
  };

  const handleInvoicesBulkDownload = async (data: Record<string, boolean>) => {
    setShowBulkDownloadModal?.(false);
    await InvoiceActions.downloadInvoicesAsZip(data, warnings, invoices);
  };

  const changeStatusRequest = async (
    requestData: StatusChangeRequest,
    invoices: InvoiceModel[],
    status: string
  ) => {
    const response = await InvoiceActions.changeStatusRequest(
      requestData,
      invoices,
      status,
      tableData!,
      setTableData,
      currentView,
      setSelectedItem,
      !selectedItem
    );
    if (response) {
      isStatusUpdated && isStatusUpdated();
    }
  };

  const fromPaidToInvoiced = async () => {
    setPaidToInvoicedModal(false);
    const requestData = new StatusChangeRequest();
    requestData.invoiceIds = invoices.map((item) => item.id);
    requestData.invoicedOn = moment();
    requestData.paidOn = invoices[0].paidOn;
    requestData.status = INVOICED;
    requestData.organizationId = getOrganizationId();
    await changeStatusRequest(requestData, invoices, INVOICED);
    if (selectedItem) {
      setSelectedItem(null);
    }
  };

  const fromInvoicedToCompletedOrCancelled = async () => {
    setInvoicedToCompletedModal(false);
    const requestData = new StatusChangeRequest();
    requestData.invoiceIds = invoices.map((item) => item.id);
    requestData.invoicedOn = moment();
    requestData.paidOn = invoices[0].paidOn;
    requestData.status = REVERT_TO_COMPLETED;
    requestData.organizationId = getOrganizationId();

    await changeStatusRequest(requestData, invoices, REVERT_TO_COMPLETED);
    if (selectedItem) {
      setSelectedItem(null);
    }
  };

  const fromCompletedOrCancelledToReadytoInvoice = async () => {
    setReadyToInvoicedModal(false);
    const requestData = new StatusChangeRequest();
    requestData.invoiceIds = invoices.map((item) => item.id);
    requestData.invoicedOn = moment();
    requestData.status = READY_TO_INVOICE;
    requestData.organizationId = getOrganizationId();

    await changeStatusRequest(requestData, invoices, READY_TO_INVOICE);
    if (selectedItem) {
      setSelectedItem(null);
    }
  };

  const revertToPaidOrInvoiced = async (data: { date: Moment }) => {
    setSelectDateDialogOpen(false);
    const requestData = new StatusChangeRequest();
    let status = PAID;
    if (currentAction === PAID || currentAction === 'Paid') {
      requestData.invoiceIds = invoices.map((item) => item.id);
      requestData.invoicedOn = moment();
      requestData.paidOn = data.date;
      requestData.status = PAID;
      requestData.organizationId = getOrganizationId();
    } else {
      status = INVOICED;

      requestData.invoiceIds = invoices.map((item) => item.id);
      requestData.invoicedOn = moment();
      requestData.paidOn = data.date;
      requestData.status = INVOICED;
      requestData.organizationId = getOrganizationId();
    }
    await changeStatusRequest(requestData, invoices, status);
    if (selectedItem) {
      setSelectedItem(null);
    }
  };

  const handleDownload = async (data: {
    invoiceId: string;
    documentIds: string[];
    pdfComplexity: string;
  }) => {
    setDownloadDialogOpen!(false);
    const result = await InvoiceActions.downloadInvoice(data);
    if (result) {
      result.forEach((document) => downloadFile(document));
    }
  };

  return (
    <>
      {downloadDialogOpen && (
        <AttachmentsDialog
          invoice={currentInvoicesOrInvoice as InvoiceModel}
          open={downloadDialogOpen}
          onAction={(data) => {
            handleDownload(data);
          }}
          onClose={() => setDownloadDialogOpen!(false)}
        />
      )}
      {showEmailPopup && invoices.length > 1 && (
        <MultipleSendEmailDialog
          open={showEmailPopup}
          onAction={(data) => handleSendMultipleEmails(data)}
          onClose={() => setShowEmailPopup(false)}
          invoices={invoices}
          canAddInvoice={canAddInvoice}
        />
      )}
      {showEmailPopup && invoices.length === 1 && (
        <InvoiceSendEmailDialog
          open={showEmailPopup}
          onAction={(data) => handleSendMultipleEmails(data)}
          onClose={() => setShowEmailPopup(false)}
          invoice={currentInvoicesOrInvoice as InvoiceModel}
          canAddInvoice={canAddInvoice}
        />
      )}
      {selectDateDialogOpen && (
        <SelectDateFormDialog
          open={selectDateDialogOpen}
          onAction={async (data) => {
            await revertToPaidOrInvoiced(data);
          }}
          onClose={() => setSelectDateDialogOpen(false)}
        />
      )}
      {paidToInvoicedModal && (
        <DeletePopup
          open={paidToInvoicedModal}
          onAction={fromPaidToInvoiced}
          onClose={() => setPaidToInvoicedModal(false)}
          title={t('revertToInvoicedWarningTitle')}
          cancelText={t('cancel')}
          buttonText={t('yes')}
        />
      )}
      {readyToInvoicedModal && (
        <DeletePopup
          open={readyToInvoicedModal}
          onAction={fromCompletedOrCancelledToReadytoInvoice}
          onClose={() => setReadyToInvoicedModal(false)}
          title={
            !(
              invoices[0]?.status === 'LOAD_COMPLETED' ||
              invoices[0]?.status === 'CANCELLED'
            )
              ? 'Revert to Ready to Invoice'
              : 'Mark as Ready to Invoice'
          }
          subtitle={
            !(
              invoices[0]?.status === 'LOAD_COMPLETED' ||
              invoices[0]?.status === 'CANCELLED'
            )
              ? 'Are you sure you want to revert the selected load(s) as Ready to Invoice?'
              : 'Are you sure you want to mark the selected load(s) as Ready to Invoice?'
          }
          cancelText={t('cancel')}
          buttonText={t('yes')}
          backgroundColor={'primary.main'}
        />
      )}

      {invoicedToCompletedModal && (
        <DeletePopup
          open={invoicedToCompletedModal}
          onAction={fromInvoicedToCompletedOrCancelled}
          onClose={() => setInvoicedToCompletedModal(false)}
          title={t('revertLoadCompletedWarningTitle')}
          subtitle={t('revertInvoicedToCompletedWarningSubTitle')}
          cancelText={t('cancel')}
          buttonText={t('yes')}
        />
      )}
      {showBulkDownloadModal && (
        <InvoiceBulkDownloadDialog
          open={showBulkDownloadModal}
          onAction={(data) => handleInvoicesBulkDownload(data)}
          onClose={() => setShowBulkDownloadModal?.(false)}
          invoices={invoices}
        />
      )}
    </>
  );
}

export function SelectDateFormDialog({
  titleName,
  open,
  onAction,
  onClose,
}: {
  titleName: string;
  open: boolean;
  onAction: ({ date }: { date: Moment }) => void;
  onClose: () => void;
}) {
  return (
    <FormDialog
      data={{
        date: moment(),
      }}
      titleText={titleName || 'Status change'}
      actionLabel="Save"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <SelectDateForm />}
    />
  );
}

export const SelectDateForm = () => {
  const { control } = useFormContext();
  return (
    <DatePicker
      control={control}
      disabledText={false}
      name={`date`}
      label="Date"
      initialDate={new Date()}
      required
      sizes={{ xs: 24 }}
      styleProps={{ width: '100%' }}
    />
  );
};

type PropTypes = {
  showEmailPopup: boolean;
  setShowEmailPopup: (prop: boolean) => void;
  selectDateDialogOpen: boolean;
  setSelectDateDialogOpen: (prop: boolean) => void;
  currentAction: string;
  downloadDialogOpen?: boolean;
  setDownloadDialogOpen?: (prop: boolean) => void;
  invoicedToCompletedModal: boolean;
  setInvoicedToCompletedModal: (prop: boolean) => void;
  paidToInvoicedModal: boolean;
  setPaidToInvoicedModal: (prop: boolean) => void;
  readyToInvoicedModal: boolean;
  setReadyToInvoicedModal: (prop: boolean) => void;
  isStatusUpdated?: () => void;
  invoices: InvoiceModel[];
  triggerAlert?: (actionName: string, message: string) => void;
  showBulkDownloadModal?: boolean;
  setShowBulkDownloadModal?: (data: boolean) => void;
  canAddInvoice?: boolean;
};
