import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import { ImportContextProvider, useImport } from '../../contexts/ImportContext';
import { ImportIcon } from '../../ui-kit/components/Assets';
import ImportData from '../../views/settings/import';
const ImportableComponent = ({ buttonLabel }: { buttonLabel: string }) => {
  const { handleImportModalOpen } = useImport();
  return (
    <ButtonImproved
      size="small"
      styleProps={{
        pl: 5,
        pr: 5,
        fontSize: 13,
      }}
      label={buttonLabel}
      onClick={handleImportModalOpen}
      startIcon={<ImportIcon color="#2B64CB" height={13} />}
      variant="outlined"
    />
  );
};
const Importable = ({
  importableEntityName,
  finishImportCb,
  children,
  defaultUploadType,
}: {
  importableEntityName: string;
  finishImportCb?: (data: unknown) => void;
  children: JSX.Element;
  defaultUploadType?: string;
}) => {
  return (
    <ImportContextProvider
      defaultUploadType={defaultUploadType}
      importableEntityName={importableEntityName}
      finishImportCb={finishImportCb}
    >
      <>
        <ImportData
          hideImportPage
          importableEntityName={importableEntityName}
        />
        {children}
      </>
    </ImportContextProvider>
  );
};
export const ImportButton = ({
  entity,
  buttonLabel,
  finishImportCb,
  defaultUploadType,
}: {
  entity: string;
  buttonLabel: string;
  finishImportCb?: (data: unknown) => void;
  defaultUploadType?: string;
}) => {
  return (
    <Importable
      defaultUploadType={defaultUploadType}
      importableEntityName={entity}
      finishImportCb={finishImportCb}
    >
      <ImportableComponent buttonLabel={buttonLabel} />
    </Importable>
  );
};
