import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Grid,
  Link,
  Stack,
  styled,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';

import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  UseFieldArrayRemove,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { financeLoadService } from '../../../../api';

import {
  ExpenseCategory,
  PaymentStatusEnum,
  PayStatementStatusEnum,
  PayStatementType,
  SETTLEMENT_CREATED_ENUMS,
} from '../../../../models';
import {
  LoadCarrierExpenseDTO,
  LoadCarrierExpenseLineItem,
  LoadCarrierExpenseReceivedPayment,
} from '../../../../models/DTOs/FinanceLoadService';
import {
  addNewFinanceOverViewFormValidationSchema,
  FinanceCarrierOverViewFormValidationSchema,
  FinanceOverViewFormValidationSchema,
} from '../../../../subPages/webUsers/constants';
import { amountFormat } from '../../../../utils';
import uuid from '../../../../utils/uuid';
import Accordion from '../../../Accordion';
import { DirtyDetailsPanelManager } from '../../../DetailsPanel/utils';
import { Footer } from '../../../LoadSharedComponents/components/SaveChangesFooter';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../Permission';
import FormDialog from '../../../Ui/FormDialog';
import Select from '../../../Ui/Select';
import TextField from '../../../Ui/TextField/TextField';
import { CarrierFinanceFooter } from './Carrier/CarrierFinanceFooter';
import {
  fromRecordListToTotalAmount,
  getAvaiablePaymentLoadType,
} from './Carrier/utils';
import FinanceLoadKPI from './FinanceLoadKPI/FinanceLoadKPI';
import { FinanceOverviewAccordionTopBar } from './FinanceOverviewAccordionTopBar';
import FinanceOverViewAddNewForm from './FinanceOverViewAddNewForm';
import {
  dispatcherPayType,
  EFinanceOverview,
  paymentLineItemAmountText,
  paymentLineItemDescription,
  paymentRateCompare,
  selectedEntityObjectFinanceOverView,
  statusNameKey,
} from './FinanceOverviewConstant';
import FormContext, {
  filterAvailableFinanceOverview,
  getFinanceDeduction,
  getFinanceOverviewStatementInfo,
  getLabelName,
} from './FinanceOverviewFormContext';
import {
  CreateFinanceLoadloadexpenseRecordType,
  CreateLoadFinanceExpenseDTO,
  EPaymentDetailType,
  FinanceLoadloadexpenseListType,
  requestStatuesStatementInfo,
  UpdateTractorDriverAccessQuery,
} from './FinanceOverviewModel';
import FinanceOverviewSettlementModal from './FinanceOverviewSettlementModal';
import { financeStyle } from './FinanceOverviewStyle';

import { t } from 'i18next';
import { ServiceError } from '../../../../api/interfaces';
import { DeleteButtonIcon } from '../../../../ui-kit/components/Assets';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import MultilineCellAxele from '../../../../ui-kit/components/MultilineCell';
import ThreeDotMenuIcon from '../../../../ui-kit/components/ThreeDotMenuIcon';
import { useLoadPermission } from '../../../hooks/useLoadPermission';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 27,
  height: 13.3,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2B64CB',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
const RenderFinanceOverviewKPI = () => {
  const theme = useTheme();
  const { setValue } = useFormContext();

  const {
    statementInfo,
    selectEntityType,
    setStatementInfo,
    financeOverviewData,
    isSavebtnVisible,
    isCreatingNewPayment,
  } = React.useContext(FormContext);
  const [showModalDialog, setShowModalDialog] = useState<boolean>(false);
  const settlementData: PayStatementType = {
    id: statementInfo?.id,
    seqNumber: statementInfo?.seqNumber,
    entityId: statementInfo?.payToEntityId! ?? selectEntityType?.id!,
    entity: statementInfo?.payToEntityId
      ? statementInfo?.payToEntityType!
      : selectEntityType?.type || '',
  };

  const hyperTextStyle = {
    lineHeight: '30px',
    color: `${theme.palette.primary.main}`,
    fontSize: '18px',
    fontWeight: 600,
    textTransform: 'lowercase',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  };
  const hyperTextStyleDisabled = {
    pointerEvents: 'none',
    cursor: 'default',
    textDecoration: 'auto',
    lineHeight: '30px',
    color: `${theme.palette.primary.main}`,
    fontSize: '18px',
    fontWeight: 600,
    textTransform: 'lowercase',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  };
  return (
    <>
      <Grid
        className="RenderFinanceOverviewKPI"
        sx={{ display: 'flex', width: '100%' }}
      >
        <Grid
          sx={{
            background: `${theme.palette.uncategorized.oldHeaderSecondary}`,
            padding: '12px',
            margin: '12px',
            width: '50%',
            ml: '0px',
            borderRadius: '13px',
          }}
        >
          <Typography
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              marginRight: 16,
              minWidth: 105,
              paddingLeft: '5px',
            }}
          >
            {statementInfo?.status ? (
              <Typography
                sx={isSavebtnVisible ? hyperTextStyleDisabled : hyperTextStyle}
                noWrap
                component={Link}
                onClick={() => {
                  setShowModalDialog(true);
                }}
              >
                {' '}
                <Link href="#" underline="none">
                  {statementInfo?.status}
                </Link>
              </Typography>
            ) : (
              <Typography
                sx={{
                  lineHeight: '30px',
                  color: `${theme.palette.primary.main}`,
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                {' '}
                {'--'}{' '}
              </Typography>
            )}

            <Typography
              style={{
                lineHeight: '14px',
                color: `${theme.palette.text.secondary}`,
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {'Status'}
            </Typography>
          </Typography>
        </Grid>
        <Grid
          sx={{
            background: `${theme.palette.uncategorized.oldHeaderSecondary}`,
            padding: '12px',
            margin: '12px',
            width: '50%',
            ml: '0px',
            borderRadius: '13px',
          }}
        >
          <FinanceLoadKPI
            title={t('settlementId')}
            value={statementInfo?.seqNumber ? statementInfo.seqNumber : '--'}
          />
        </Grid>
      </Grid>
      {showModalDialog && (
        <FinanceOverviewSettlementModal
          data={settlementData}
          showModalDialog={showModalDialog}
          loadId={selectEntityType?.loadId}
          onClose={async () => {
            const statementQuery = {
              loadId: selectEntityType?.loadId,
              id: selectEntityType?.id,
              type: selectEntityType?.type,
            };
            const datastatement: requestStatuesStatementInfo | undefined =
              await getFinanceOverviewStatementInfo(statementQuery);
            if (
              datastatement &&
              datastatement?.status != statementInfo?.status
            ) {
              setStatementInfo(datastatement);
              setValue('statementInfo', datastatement);
              const financeOverview = financeOverviewData;
              financeOverview?.tripDetails?.forEach((element, index) => {
                element.paymentDetails.forEach((record, indexNumber) => {
                  if (record?.id === selectEntityType?.id) {
                    financeOverview.tripDetails[index].paymentDetails[
                      indexNumber
                    ].paymentStatus = statusNameKey[datastatement?.status];
                  }
                });
              });
              setValue('financeOverviewData', financeOverview);
            }
            setShowModalDialog(false);
          }}
        />
      )}
    </>
  );
};
const RenderFinanceFooter = () => {
  const theme = useTheme();
  const { selectRecordData, isSavebtnVisible } = React.useContext(FormContext);
  const financeFooterStyle = {
    background: `${theme.palette.primary.outlinedHoverBackground}`,
    padding: '12px',
    margin: '12px 0px',
    borderRadius: '8px',
    display: 'block',
    width: '100%',
    mb: isSavebtnVisible ? '50px' : '12px',
  };
  const financeFooterText = {
    width: '50%',
    color: `${theme.palette.primary.main}`,
    fontSize: '20px',
    fontWeight: '600',
  };
  return (
    <Grid className="RenderFinanceFooter" sx={financeFooterStyle}>
      <Grid sx={financeStyle.financeFooterCount}>
        <Typography variant="h6" sx={financeFooterText}>
          Total Payment
        </Typography>
        <Typography align="right" sx={financeFooterText}>
          {Math.sign(selectRecordData?.total ? selectRecordData.total : 0) ===
            -1 && '-'}{' '}
          {amountFormat(selectRecordData?.total ? selectRecordData.total : 0)}
        </Typography>
      </Grid>
    </Grid>
  );
};
const RenderFinanceOverviewUserInfo = () => {
  const { selectEntityType, selectRecordData, setSelectRecordData } =
    React.useContext(FormContext);
  const userInfo =
    selectedEntityObjectFinanceOverView[
      selectEntityType?.type ? selectEntityType.type : ''
    ];
  const isShareDriver = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const request = new UpdateTractorDriverAccessQuery();
    request.shareWithDriver = event.target.checked;
    request.tractorExpenseId = selectRecordData?.id;
    const response = await financeLoadService.UpdateTractorDriverAccess(
      request
    );
    if (response) {
      setSelectRecordData((prev) => ({
        ...prev,
        shareWithDriver: request.shareWithDriver,
      }));
    }
  };
  return (
    <>
      <Grid
        className="RenderFinanceOverviewUserInfo"
        sx={{ display: 'flex', width: '100%', p: '13px 3px 5px 3px' }}
      >
        {userInfo?.icon}{' '}
        <Typography sx={{ fontSize: '16px', pl: '12px' }}>
          {userInfo?.name} :
        </Typography>{' '}
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            pl: '12px',
            color: 'primary.main',
          }}
        >
          {selectEntityType?.name}
        </Typography>
      </Grid>
      {selectEntityType?.type === 'Tractor' && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ p: '5px 0px' }}
        >
          <AntSwitch
            inputProps={{ 'aria-label': 'ant design' }}
            checked={selectRecordData?.shareWithDriver}
            onChange={isShareDriver}
          />
          <Typography style={{ fontWeight: '600' }}>
            Share with Driver
          </Typography>
        </Stack>
      )}
    </>
  );
};

const totalTextStyle = {
  fontSize: '12px',
};
const totalSubTextStyle = {
  color: '#669EFF !important',
  fontSize: '16px',
};
const defaultFinanceLoadloadexpenseType =
  new CreateFinanceLoadloadexpenseRecordType();

const getExpenseCategoryCodeById = (
  expenseCategoties: ExpenseCategory[],
  id: string
): string => {
  const foundExpenseCategory = expenseCategoties.find((exp) => exp.id === id);

  if (foundExpenseCategory) {
    return foundExpenseCategory.itemCode || '';
  }

  return '';
};

const PaymentPerLoadItem = ({
  data,
  foundIndex,
  removeCarrierLineItem,
}: {
  data: FinanceLoadloadexpenseListType;
  expanded?: boolean;
  foundIndex: number;
  removeCarrierLineItem: UseFieldArrayRemove;
}) => {
  const [cardExpanded, setCardExpanded] = useState(true);
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { isDirty },
  } = useFormContext();

  const {
    expenseCategories,
    allPaymentTypes,
    resetFinanceOverviewData,
    selectEntityType,
    selectRecordData,
    deleteFinanceOverViewForLoad,
    setSavebtnVisible,
    setSelectRecordData,
    setSelectRecordList,
    statementInfo,
    openPayments,
    isCarrier,
    isCreatingNewPayment,
  } = React.useContext(FormContext);
  const {
    hasLoadPaymentEditPermission,
    hasPaymentInReviewToClosedChangePermission,
    hasLoadPaymentDeletePermission,
  } = useLoadPermission();

  const isDisabledCarrier =
    isCarrier &&
    (selectRecordData?.status === 'CLOSED' ||
      selectRecordData?.status === PaymentStatusEnum.CLOSED);

  const isEditPermission = useMemo(() => {
    if (
      statementInfo?.status === 'CLOSED' ||
      statementInfo?.status === PaymentStatusEnum.CLOSED ||
      isDisabledCarrier
    )
      return true;
    if (
      SETTLEMENT_CREATED_ENUMS.includes(
        statementInfo?.status as PaymentStatusEnum
      )
    )
      return !hasPaymentInReviewToClosedChangePermission;
    return !hasLoadPaymentEditPermission;
  }, [statementInfo?.status, isDisabledCarrier]);

  const isDeletePayItemPermission = useMemo(() => {
    if (
      statementInfo?.status === 'CLOSED' ||
      statementInfo?.status === PaymentStatusEnum.CLOSED ||
      isDisabledCarrier
    )
      return true;
    if (
      SETTLEMENT_CREATED_ENUMS.includes(
        statementInfo?.status as PaymentStatusEnum
      )
    )
      return !hasPaymentInReviewToClosedChangePermission;
    return !hasLoadPaymentDeletePermission;
  }, [statementInfo?.status, isDisabledCarrier]);

  useEffect(() => {
    //Please run it at once
    if (!openPayments) {
      setSavebtnVisible(isDirty);
      setValue('isSavebtnVisible', isDirty);
    }
  }, [openPayments]);

  const { id } = data;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteItem, setDeleteItem] = useState<boolean>(false);
  const fieldPath = `selectRecordList.${foundIndex}`;
  const selectedExpenseCategoryId = watch(`${fieldPath}.expenseCategoryId`);
  const foundPaymentType = allPaymentTypes.find(
    (paymentType) => paymentType.id === data.loadRateTypeId
  );
  const loadRateType = watch(`${fieldPath}.loadRateType`);
  const selectedUnitType = watch(`${fieldPath}.unit`);
  const selectedAmount = watch(`${fieldPath}.amount`);
  const selectedQuantity = watch(`${fieldPath}.quantity`);
  const selectedRate = watch(`${fieldPath}.rate`);
  useEffect(() => {
    if (!selectedExpenseCategoryId) {
      return;
    }
    const name = getExpenseCategoryCodeById(
      expenseCategories,
      selectedExpenseCategoryId
    );
    setValue(`${fieldPath}.expenseCategoryKey`, name);
    if (name === 'PARTNER_CARRIER_PAYMENT') {
      setValue(
        `${fieldPath}.description`,
        data?.description || paymentLineItemDescription.PARTNER_CARRIER_PAYMENT
      );
    }
  }, [selectedExpenseCategoryId, fieldPath, expenseCategories, setValue]);

  const handleDeleteFinanceOverview = () => {
    setDeleteItem(true);
  };

  const paymentTermDeleteAction = {
    label: 'Delete',
    icon: DeleteButtonIcon,
  };

  const handleMenuItemClick = (event: any) => {
    if (event.label === paymentTermDeleteAction.label) {
      if (isCarrier) {
        deleteCarrierFinanceRecordLocally();
      } else {
        handleDeleteFinanceOverview();
      }
    }
    setAnchorEl(null);
  };

  const titleRenderer = () => {
    const isValue = getFinanceDeduction(allPaymentTypes, data.loadRateType);
    let totalAmout = '';
    if (isValue) {
      totalAmout = `-  ${amountFormat(
        Math.abs(selectedAmount) || Math.abs(data.amount)
      )}`;
    } else {
      totalAmout = `  ${amountFormat(
        Math.abs(selectedAmount) || Math.abs(data.amount)
      )}`;
    }
    data.amount = Math.abs(data.amount);
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: (theme: {
            palette: { primary: { contrast: any }; grey: { [x: string]: any } };
          }) => {
            return cardExpanded
              ? `${theme.palette.primary.contrast}`
              : `${theme.palette.grey['100']}`;
          },
        }}
      >
        <Box style={{ width: '60%' }}>
          <Typography
            variant="h7"
            sx={{
              color: cardExpanded ? 'primary.main' : 'text.primary',
            }}
          >
            {getLabelName(allPaymentTypes, data.loadRateTypeId)}
          </Typography>
          {!cardExpanded && (
            <Box>
              <Typography sx={{ color: 'text.secondary' }}>
                {data.description}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          onClick={(event: { stopPropagation: () => void }) => {
            event.stopPropagation();
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: 'primary.main',
              fontSize: '24px',
            }}
          >
            {totalAmout}
          </Typography>
          {!isDeletePayItemPermission && (
            <ThreeDotMenuIcon
              menuOptions={[{ ...paymentTermDeleteAction }]}
              open={Boolean(anchorEl)}
              handleMenuClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
              handleMenuClose={() => {
                setAnchorEl(null);
              }}
              anchorEl={anchorEl}
              handleMenuItemClick={handleMenuItemClick}
            />
          )}
        </Box>
      </Box>
    );
  };
  useEffect(() => {
    if (foundPaymentType?.deduction) {
      setTimeout(() => {
        setValue(`${fieldPath}.amount`, Math.abs(data.amount));
      }, 0);
    }
  }, [data.amount]);
  useEffect(() => {
    if (openPayments) {
      const recordList = getValues('selectRecordList');
      const selectRecord = getValues('selectRecordData');
      const updatedData = selectRecord;
      const totalAmount = fromRecordListToTotalAmount({
        recordList: recordList,
        allPaymentTypes,
      });
      if (updatedData) {
        updatedData.items = recordList;
        updatedData.total = totalAmount;
      }
      openPayments?.onPaymentTmpUpdate(updatedData);
      if (isCarrier) {
        setValue('receivedPayments', updatedData?.receivedPayments ?? []);
      }
    }
  }, [selectedAmount]);

  useEffect(() => {
    if (!isCreatingNewPayment) {
      setSelectRecordData(getValues('selectRecordData'));
      setSelectRecordList(getValues('selectRecordData')?.items);
    }
  }, [JSON.stringify(getValues('selectRecordData')), isCreatingNewPayment]);

  useEffect(() => {
    calculationBasedOnSelectItem();
  }, [selectedQuantity, selectedRate, selectedUnitType]);

  const calculationBasedOnSelectItem = (): void => {
    if (paymentRateCompare.includes(loadRateType)) {
      return setValue(`${fieldPath}.amount`, selectedRate);
    }
    if (selectedUnitType === 'PERCENT') {
      return setValue(
        `${fieldPath}.amount`,
        (selectedQuantity * selectedRate) / 100
      );
    }
    if (selectedUnitType !== 'PERCENT') {
      return setValue(`${fieldPath}.amount`, selectedQuantity * selectedRate);
    }
    if (selectedRate > 0 && selectedQuantity > 0) {
      if (selectedUnitType === 'PERCENT') {
        return setValue(
          `${fieldPath}.amount`,
          (selectedQuantity * selectedRate) / 100
        );
      }
      if (selectedUnitType !== 'PERCENT') {
        return setValue(`${fieldPath}.amount`, selectedQuantity * selectedRate);
      }
    }
    return setValue(`${fieldPath}.amount`, 0);
  };

  const deleteFinanceRecord = () => {
    if (openPayments) {
      const recordList = getValues('selectRecordList');
      const selectRecord = getValues('selectRecordData');
      const updatedData = selectRecord;
      const filteredItems = recordList.filter(
        (item: any) => item.loadRateType !== data.loadRateType
      );
      let totalAmount = 0;
      filteredItems.map((recordData: any) => {
        const recordStatus = getFinanceDeduction(
          allPaymentTypes,
          recordData.loadRateType
        );
        if (recordStatus) {
          totalAmount = totalAmount - recordData.amount;
        } else {
          totalAmount = totalAmount + recordData.amount;
        }
      });
      updatedData.items = filteredItems;
      updatedData.total = totalAmount;
      openPayments?.onPaymentTmpUpdate(updatedData);
      setSelectRecordData(updatedData);
      setSelectRecordList(filteredItems);
      setValue('selectRecordList', filteredItems);
      setValue('selectRecordData', updatedData);
    } else {
      const entityType = selectEntityType?.type ? selectEntityType.type : '';
      const parentId = selectRecordData?.id ? selectRecordData.id : '';
      deleteFinanceOverViewForLoad(data, entityType, parentId);
      setDeleteItem(false);
      selectEntityType && resetFinanceOverviewData(selectEntityType, true);
    }
  };

  const deleteCarrierFinanceRecordLocally = () => {
    //Delete Carrier payment locally
    const recordList = getValues('selectRecordList').filter(
      (_item, index) => index !== foundIndex
    );
    const filteredItems = recordList.filter(
      (item: any) => item.loadRateType !== data.loadRateType
    );
    const updatedData = getValues('selectRecordData');
    removeCarrierLineItem(foundIndex);

    const totalAmount = fromRecordListToTotalAmount({
      recordList: filteredItems,
      allPaymentTypes,
    });

    updatedData.items = filteredItems;
    updatedData.total = totalAmount;

    setValue('selectRecordList', filteredItems);
    setValue('selectRecordData', updatedData);
    setSavebtnVisible(!!isCarrier);
    setDeleteItem(false);
  };

  const getQuantityInputLabel = () => {
    if (
      selectedUnitType === 'PERCENT' ||
      paymentRateCompare.includes(loadRateType)
    ) {
      return 'Amount ($)';
    }
    return 'Quantity';
  };

  const getRateLabel = () => {
    if (selectedUnitType === 'PERCENT') return 'Rate (%)';
    return paymentLineItemAmountText[selectedUnitType];
  };
  return (
    <Box
      className="PaymentPerLoadItem"
      sx={{
        border: (theme) => {
          return cardExpanded ? `1px solid ${theme.palette.primary.main}` : '';
        },
        borderRadius: '8px',
        p: 1,
        backgroundColor: (theme) => {
          return cardExpanded
            ? `${theme.palette.primary.contrast}`
            : `${theme.palette.grey['100']}`;
        },
        width: '100%',
      }}
    >
      <Accordion
        key={`accordion: ${id}`}
        name={`${data.payType}`}
        summaryRenderer={titleRenderer}
        updateParentState={setCardExpanded}
      >
        {!paymentRateCompare.includes(loadRateType) && (
          <TextField
            control={control}
            name={`${fieldPath}.quantity`}
            label={getQuantityInputLabel()}
            required
            sizes={{ xs: 4 }}
            disabled={isEditPermission}
          />
        )}
        <TextField
          control={control}
          name={`${fieldPath}.rate`}
          label={
            !paymentRateCompare.includes(loadRateType)
              ? getRateLabel()
              : getQuantityInputLabel()
          }
          required
          sizes={{ xs: 4 }}
          disabled={isEditPermission}
        />
        {!paymentRateCompare.includes(loadRateType) && (
          <Grid container item xs={4}>
            <MultilineCellAxele
              title={'Amount'}
              subtitle={
                selectedAmount
                  ? `${'$ ' + Math.abs(selectedAmount).toFixed(2)}`
                  : '$ ' + Math.abs(data.amount).toFixed(2)
              }
              titleStyle={totalTextStyle}
              subtitleStyle={totalSubTextStyle}
              alignTitle="left"
              alignSubtitle="left"
            />
          </Grid>
        )}
        <Select
          name={`${fieldPath}.expenseCategoryId`}
          label={t('expenseCategory')}
          required
          fieldName={'itemName'}
          sizes={{ xs: 12 }}
          options={expenseCategories}
          control={control}
          disabled={isEditPermission}
          fieldValue={'id'}
        />
        <TextField
          control={control}
          name={`${fieldPath}.description`}
          label="Description"
          sizes={{ xs: 12 }}
          disabled={isEditPermission}
        />
      </Accordion>

      {isDeleteItem && (
        <DeletePopup
          open={isDeleteItem}
          onClose={() => setDeleteItem(false)}
          onAction={() => deleteFinanceRecord()}
          title={'Delete Payment Terms'}
          subtitle={`Are you sure you want to delete  ${getLabelName(
            allPaymentTypes,
            data.loadRateTypeId
          )} ?`}
        />
      )}
    </Box>
  );
};

const AccordionSection = () => {
  const {
    selectRecordData,
    selectRecordList,
    resetFinanceOverviewData,
    createNewFinanceOverViewForRecord,
    availablePayTypes,
    selectEntityType,
    openPayments,
    allPaymentTypes,
    setSelectRecordData,
    setSelectRecordList,
    statementInfo,
    setSavebtnVisible,
    isCarrier,
    isCreatingNewPayment,
  } = React.useContext(FormContext);
  const isDisabledCarrier =
    isCarrier &&
    (selectRecordData?.status === 'CLOSED' ||
      selectRecordData?.status === PaymentStatusEnum.CLOSED);
  const { setValue, getValues, control } = useFormContext();
  //Use for field management locally
  const useRecordFieldArray = useFieldArray({
    control,
    name: isCarrier ? 'selectRecordList' : 'temp',
  });

  const [showAddNewItemDialog, setShowAddNewItemDialog] = useState(false);
  const {
    hasLoadPaymentAddPermission,
    hasPaymentInReviewToClosedChangePermission,
  } = useLoadPermission();
  const isAddPaymentPermission = SETTLEMENT_CREATED_ENUMS.includes(
    statementInfo?.status as PaymentStatusEnum
  )
    ? hasPaymentInReviewToClosedChangePermission
    : hasLoadPaymentAddPermission;

  const numberOfItemsInSection: number = useMemo(() => {
    return isCarrier
      ? useRecordFieldArray?.fields?.length
      : selectRecordList?.length ?? 0;
  }, [
    selectRecordList?.length,
    useRecordFieldArray?.fields?.length,
    isCarrier,
  ]);

  const addItemStyle = {
    pr: '10px',
    color: `leftMenuExpanded.inactive`,
    fontSize: '13px',
    fontWeight: '500',
    textAlign: 'right',
    height: '35px',
    pt: '12px',
  };

  const saveNewPaymentForLoad = (
    financeRecord: FinanceLoadloadexpenseListType
  ) => {
    setShowAddNewItemDialog(false);
    if (isCarrier) return insertNewCarrierPaymentRecordLocally(financeRecord);

    const type = selectEntityType?.type ? selectEntityType.type : '';
    const id = selectRecordData?.id ? selectRecordData.id : '';
    if (openPayments) {
      financeRecord.id = uuid();
      let selectRecordLists = getValues('selectRecordList');
      const selectRecord = getValues('selectRecordData');
      const withNewItems: any = { ...selectRecord };
      withNewItems?.items?.push?.(financeRecord);
      openPayments?.onPaymentTmpUpdate?.(withNewItems);
      selectRecordLists = withNewItems.items;
      const totalAmount = fromRecordListToTotalAmount({
        recordList: selectRecordLists,
        allPaymentTypes,
      });
      if (withNewItems) withNewItems.total = totalAmount;
      setSelectRecordData(withNewItems);
      setSelectRecordList(withNewItems.items);
      setValue('selectRecordList', withNewItems.items);
      setValue('selectRecordData', withNewItems);
      if (!isCreatingNewPayment) setSavebtnVisible(false);
    } else {
      //Update via API request
      createNewFinanceOverViewForRecord(financeRecord, type, id);
      const lineItemID = selectEntityType;
      lineItemID.loadId = lineItemID?.loadId || id;
      lineItemID.paymentId = lineItemID?.paymentId || id;
      selectEntityType && resetFinanceOverviewData(lineItemID, true);
    }
  };

  const insertNewCarrierPaymentRecordLocally = (
    financeRecord: FinanceLoadloadexpenseListType
  ) => {
    //Add new Carrier payment locally
    financeRecord.id = uuid();
    const selectRecordLists = getValues('selectRecordList');
    const selectRecord = getValues('selectRecordData');
    const receivedPayments = getValues('receivedPayments');
    const withNewItems: any = selectRecord
      ? selectRecord
      : { items: selectRecordLists };
    withNewItems.items.push(financeRecord);
    const totalAmount = fromRecordListToTotalAmount({
      recordList: withNewItems.items,
      allPaymentTypes,
    });
    if (isCarrier) {
      //Adding the Paid item is not working in Carrier Payment Screen during Carrier Assignment
      withNewItems.receivedPayments = receivedPayments ?? [];
    }
    withNewItems.total = totalAmount;
    setValue('selectRecordList', withNewItems.items, {
      shouldDirty: !openPayments,
      shouldValidate: !openPayments,
    });
    setValue('selectRecordData', withNewItems, {
      shouldDirty: !openPayments,
      shouldValidate: !openPayments,
    });

    setSavebtnVisible(!openPayments);
  };

  const ControlledFields = useMemo(() => {
    if (numberOfItemsInSection)
      return (
        <Permission
          includes={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_VIEW]}
        >
          <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
            {(isCarrier ? useRecordFieldArray.fields : selectRecordList)?.map(
              (field, index) => {
                return (
                  <PaymentPerLoadItem
                    key={field.id}
                    data={field}
                    foundIndex={index}
                    removeCarrierLineItem={useRecordFieldArray.remove}
                  />
                );
              }
            )}
          </Box>
        </Permission>
      );
    return [];
  }, [numberOfItemsInSection]);
  return (
    <>
      {ControlledFields}
      <Permission
        includes={[AXELE_PERMISSION_TYPE?.LOAD_FINANCIAL_PAYMENT_ADD]}
      >
        <>
          {statementInfo?.status != 'CLOSED' &&
            (statementInfo?.status as PaymentStatusEnum) !==
              PaymentStatusEnum.CLOSED &&
            availablePayTypes.length > 0 &&
            !isDisabledCarrier &&
            isAddPaymentPermission && (
              <Grid className="AccordionSection" container>
                <Grid item xs={12} sx={addItemStyle}>
                  <Box sx={{ display: 'inline-block' }}>
                    <Typography
                      variant="buttonSmall"
                      onClick={() => {
                        setShowAddNewItemDialog(true);
                      }}
                      sx={financeStyle.addLineItemStyle}
                    >
                      <AddIcon sx={{ width: '20px', mb: '4px' }} />
                      Add Line Item
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
        </>
      </Permission>

      {showAddNewItemDialog && (
        <FormDialog
          data={defaultFinanceLoadloadexpenseType}
          titleText="Add Pay Items"
          actionLabel="Add"
          open={showAddNewItemDialog}
          onAction={saveNewPaymentForLoad}
          handleClose={() => {
            setShowAddNewItemDialog(false);
            // resetOperationModes();
          }}
          validationSchema={addNewFinanceOverViewFormValidationSchema}
          contentRenderer={() => <FinanceOverViewAddNewForm />}
        ></FormDialog>
      )}

      {isCarrier && <CarrierFinanceFooter />}
    </>
  );
};

function FinanceOverviewAccordionSection({
  data,
  showAccordionTopBarClose = true,
}: {
  data: FinanceLoadloadexpenseListType[];
  showAccordionTopBarClose?: boolean;
}) {
  const theme = useTheme();
  const {
    selectRecordData,
    selectRecordList,
    allPaymentTypes,
    setAvailablePayTypes,
    selectEntityType,
    isSavebtnVisible,
    openPayments,
    resetForm,
    resetFinanceOverviewData,
    updateFinanceOverViewForRecord,
    setSelectRecordData,
    setSelectRecordList,
    statementInfo,
    updateLoadCarrierExpense,
    isCarrier,
    setSavebtnVisible,
    isCreatingNewPayment,
    setRenderSection,
    prepareDataForForm,
    setIsCreatingNewPayment,
    setOpenPayments,
  } = React.useContext(FormContext);
  let filteredPayTypes = [];
  const state = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: selectRecordData,
    ...(FinanceOverViewFormValidationSchema && {
      resolver: yupResolver(FinanceOverViewFormValidationSchema),
    }),
    ...(isCarrier && {
      resolver: yupResolver(FinanceCarrierOverViewFormValidationSchema),
    }),
  });
  const [isInReviewState, setInReviewState] = useState<boolean>(false);
  const [isSavingPayment, setIsSavingPayment] = useState<boolean>(false);

  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
    watch,
  } = state;

  useEffect(() => {
    DirtyDetailsPanelManager.panelName = isDirty
      ? 'FinanceOverviewAccordionSection'
      : null;

    DirtyDetailsPanelManager.isDirty = isDirty;
  }, [isDirty]);

  useEffect(() => {
    return () => {
      DirtyDetailsPanelManager.panelName = null;
      DirtyDetailsPanelManager.isDirty = false;
    };
  }, []);

  const submitCarrierHandler = async (newFormData: {
    selectRecordList: LoadCarrierExpenseLineItem[];
    selectRecordData: LoadCarrierExpenseDTO;
    receivedPayments: LoadCarrierExpenseReceivedPayment[];
  }) => {
    const updatedData = newFormData?.selectRecordList ?? [];
    const updatedRecordData = newFormData.selectRecordData;
    const receivedPayments = newFormData?.receivedPayments ?? [];
    updatedRecordData.receivedPayments = receivedPayments;
    updatedData?.map((currElement: any, index: any) => {
      const isDeduction = getFinanceDeduction(
        allPaymentTypes,
        currElement.loadRateType
      );
      if (isDeduction) {
        updatedData[index].amount = -Math.abs(currElement.amount);
      }
    });
    updatedRecordData.items = updatedData;

    const response = await updateLoadCarrierExpense(updatedRecordData);
    if (response instanceof LoadCarrierExpenseDTO) {
      selectEntityType && resetFinanceOverviewData(selectEntityType, false);
      reset(
        {
          selectRecordList: response.items,
          selectRecordData: response,
          receivedPayments: response?.receivedPayments ?? [],
        },
        { keepDefaultValues: false, keepDirty: false }
      );
      setInReviewState(false);
      setSavebtnVisible(false);
    }
  };
  const onSubmitHandler = async (newFormData: any) => {
    if (!isCarrier) {
      if (openPayments) {
        if (isCreatingNewPayment) {
          return handleCreateNewPayments(newFormData);
        }
        const recordList = getValues('selectRecordList');
        const recordData = getValues('selectRecordData');
        let totalAmount = 0;
        recordList.map((recordData: any) => {
          totalAmount = Number(recordData.amount) + totalAmount;
        });
        recordData.items = recordList;
        recordData.total = totalAmount;
        openPayments?.onPaymentTmpUpdate(recordData);
        resetForm(recordData);
      } else {
        const entityType = selectEntityType?.type ? selectEntityType.type : '';
        const parentId = selectRecordData?.id ? selectRecordData.id : '';
        const updatedData = getValues('selectRecordList');
        const updatedRecordData = getValues('selectRecordData');
        updatedRecordData.items = updatedData;
        updateFinanceOverViewForRecord(updatedData, entityType, parentId);
        selectEntityType && resetFinanceOverviewData(selectEntityType, false);
        reset();
        resetForm(updatedRecordData);
        setInReviewState(false);
      }
    } else {
      return submitCarrierHandler(newFormData);
    }
  };

  const handleCreateNewPayments = async (newPayments: any) => {
    try {
      setIsSavingPayment(true);
      const requestPayload = new CreateLoadFinanceExpenseDTO(newPayments);
      const res = await financeLoadService.createNewLoadFinanceExpense(
        selectEntityType?.type!,
        requestPayload
      );
      if (res instanceof ServiceError) return setIsSavingPayment(false);
      setIsCreatingNewPayment(false);
      setRenderSection(EFinanceOverview.FinanceOverview);
      prepareDataForForm?.();
      setSelectRecordList([]);
      setSavebtnVisible(false);
      setOpenPayments?.(undefined);
      setIsSavingPayment(false);
    } catch (error) {
      console.log(error);
      setIsSavingPayment(false);
    }
  };

  const watchSelectRecordList = watch('selectRecordList');
  useEffect(() => {
    if (selectEntityType?.type === EPaymentDetailType.Dispatcher) {
      const filteredItems = allPaymentTypes.filter((paymentTerm) => {
        const isValue =
          dispatcherPayType[paymentTerm?.itemCode ? paymentTerm?.itemCode : ''];
        if (isValue) {
          return paymentTerm;
        }
      });
      if (data?.length > 0) {
        filteredPayTypes = filterAvailableFinanceOverview(data, filteredItems);
      } else {
        filteredPayTypes = filteredItems;
      }
    } else {
      if (isCarrier) {
        //Filtered field array locally
        filteredPayTypes = getAvaiablePaymentLoadType({
          recordList: openPayments
            ? selectRecordData?.items ?? []
            : watchSelectRecordList,
          allPaymentTypes,
        });
        setAvailablePayTypes(filteredPayTypes);
      } else {
        if (data?.length > 0) {
          filteredPayTypes = filterAvailableFinanceOverview(
            data,
            allPaymentTypes
          );
        } else {
          filteredPayTypes = allPaymentTypes;
        }
      }
    }
    setAvailablePayTypes(filteredPayTypes);
    setValue('selectRecordData', selectRecordData);
    setValue('selectRecordList', selectRecordList);
    if (isCarrier) {
      setValue('receivedPayments', selectRecordData?.receivedPayments ?? []);
    }
  }, [data, selectRecordData, selectRecordList, allPaymentTypes, isCarrier]);

  useEffect(() => {
    if (isCarrier) {
      //Filtered field array locally
      filteredPayTypes = getAvaiablePaymentLoadType({
        recordList: openPayments
          ? selectRecordData?.items ?? []
          : watchSelectRecordList,
        allPaymentTypes,
      });
      setAvailablePayTypes(filteredPayTypes);
    }
  }, [
    isCarrier,
    allPaymentTypes,
    JSON.stringify(watchSelectRecordList),
    JSON.stringify(selectRecordData?.items),
  ]);

  const saveContainerStyle = {
    position: 'absolute',
    right: '35px',
    background: `${theme.palette.background.default}`,
    width: '88%',
    textAlign: 'right',
    padding: '20px 0px',
    bottom: '5px',
  };
  const closeEdit = () => {
    if (openPayments) {
      selectRecordData && resetForm(selectRecordData);
    } else {
      reset();
      if (isCarrier) {
        //Reset the local changes
        selectRecordData && resetForm(selectRecordData);
      }
      setTimeout(() => {
        setSelectRecordData(selectRecordData);
        selectRecordData && setSelectRecordList(selectRecordData.items);
        selectRecordData &&
          setValue('selectRecordList', selectRecordData.items);
        setValue('selectRecordData', selectRecordData);
      }, 0);
    }
    setInReviewState(false);
    setSavebtnVisible(false);
  };
  return (
    <>
      <FinanceOverviewAccordionTopBar
        showAccordionTopBarClose={showAccordionTopBarClose}
      />
      {!openPayments && !isCarrier && RenderFinanceOverviewUserInfo()}
      {!openPayments && !isCarrier && RenderFinanceOverviewKPI()}

      {!openPayments || isCreatingNewPayment ? (
        <FormProvider {...state}>
          <AccordionSection />
        </FormProvider>
      ) : (
        <AccordionSection />
      )}

      {!isCarrier && RenderFinanceFooter()}

      {(isSavebtnVisible ||
        (isDirty && isCarrier) ||
        (isDirty && !openPayments)) && (
        <Grid
          className="FinanceOverviewAccordionSection-Footer"
          container
          sx={{
            pb: 2,
            position: openPayments ? 'absolute' : '',
            bottom: '0px',
          }}
        >
          <Grid
            id="save-footer-container"
            item
            xs={12}
            md={12}
            sx={saveContainerStyle}
          >
            <Footer
              onSave={() => {
                statementInfo?.status != PayStatementStatusEnum.IN_REVIEW
                  ? handleSubmit(onSubmitHandler)()
                  : setInReviewState(true);
              }}
              onCancel={closeEdit}
              isSaving={isSavingPayment}
            />
          </Grid>
        </Grid>
      )}
      {isInReviewState && (
        <DeletePopup
          open={isInReviewState}
          onClose={() => {
            setInReviewState(false);
            closeEdit();
          }}
          onAction={handleSubmit(onSubmitHandler)}
          title={``}
          subtitle={`Are you sure you want to continue?`}
          body={`Please note, that this change will overwrite already existing settlement.`}
          buttonText={t('yes')}
          backgroundColor={theme.palette.primary.main}
          width={'440px'}
        />
      )}
    </>
  );
}

export default observer(FinanceOverviewAccordionSection);
