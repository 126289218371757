import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  VisitorUpdateRequest,
  VisitorUpdateResponse,
} from '../../models/DTOs/Hubspot/Requests';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { IHubspotService } from '../interfaces/IHubspotService';

const API_RESOURCE_URL = {
  visitor: {
    update: `/web/organization/api/v2/hubspot/visitor/update`,
  },
};
export class HubspotService extends IHubspotService {
  @annotateNameAsync
  async visitorUpdate(
    requestData: VisitorUpdateRequest
  ): Promise<VisitorUpdateResponse | ServiceError | null> {
    try {
      return await httpClient.post<VisitorUpdateResponse>(
        API_RESOURCE_URL.visitor.update,
        undefined,
        requestData,
        VisitorUpdateResponse,
        false,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
