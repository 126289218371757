import SingleAutocomplete from '../../../../../ui-kit/components/SingleAutocomplete';
import { useState } from 'react';
import { RowActualData } from '.';
import { financeIntegrationService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { GetDriverSummaryFactoreRequest } from '../../../../../models/DTOs/ELD/Requests';
import { gridPageSize } from '../../../../../utils';
import { TMappingObjectType } from './integrationData';

export const AxeleDriverMapping = ({
  tabSelected,
  rowDetails,
  selected,
  viewTable,
  mappingInfo,
}: {
  tabSelected: string;
  rowDetails: any;
  viewTable: string;
  selected: (cardData: TMappingObjectType) => void;
  mappingInfo: (cardData: TMappingObjectType) => void;
}) => {
  const factoringID = sessionStorage.getItem('FactoringCustomerID');

  const [value, setValue] = useState<string | null>(
    tabSelected === 'customersMapping'
      ? rowDetails.dropDownValue
      : rowDetails.mappingName
  );

  const getOptions = async (name: string, pageNumber: number) => {
    const request = new GetDriverSummaryFactoreRequest();
    request.pageNumber = pageNumber;
    request.pageSize = gridPageSize;
    request.credentialId = factoringID;
    request.nameFilter = name;

    let responseData;
    if (viewTable === 'TafsMapping' || viewTable === 'FormData') {
      const response = await financeIntegrationService.drivermappingsOptionList(
        request
      );
      if (response instanceof ServiceError) {
        return;
      } else {
        responseData = response;
      }
    } else if (viewTable === 'apexMapping') {
      if (tabSelected === 'customersMapping') {
        const response =
          await financeIntegrationService.customermappingsOptionList(request);
        if (response instanceof ServiceError) {
          return;
        } else {
          const modifiedValues: any = [];
          response?.content?.length > 0 &&
            response?.content.forEach((el: any) => {
              const values = {
                driverInvoiceNotes: el.driverInvoiceNotes,
                mappingCode: el.mappingCode,
                mappingDescription: el.mappingDescription,
                mappingId: el.mappingId,
                mappingName: `${el.mappingName} (${el.mappingCode})`,
              };
              modifiedValues.push(values);
            });
          responseData = modifiedValues;
        }
      } else if (tabSelected === 'fundingAccountsMapping') {
        const response =
          await financeIntegrationService.drivermappingsOptionList(request);
        if (response instanceof ServiceError) {
          return;
        } else {
          responseData = response;
        }
      }
    }
    return responseData;
  };
  const handleChange = async (mappingObject: TMappingObjectType) => {
    setValue(mappingObject);
    if (viewTable === 'FormData') {
      selected(mappingObject);
    }
    if (viewTable === 'TafsMapping') {
      const payLoad: RowActualData = {
        id: rowDetails.id,
        driverId: rowDetails.driverId,
        driverInvoiceNotes: rowDetails.driverInvoiceNotes,
        factoringId: rowDetails.factoringId,
        firstname: rowDetails.firstname,
        lastname: rowDetails.lastname,
        mappingCode: mappingObject.mappingCode,
        mappingDescription: mappingObject.mappingDescription,
        mappingId: mappingObject.mappingId,
        mappingName: mappingObject.mappingName,
        organizationId: rowDetails.organizationId,
        code: rowDetails.code ? rowDetails.code : '',
      };
      const response =
        await financeIntegrationService.driverFactoringMapingRecord(payLoad);
      if (response) {
        mappingInfo && mappingInfo(mappingObject);
      }
    }
    if (viewTable === 'apexMapping') {
      const payLoad: RowActualData = {
        customerId: rowDetails.customerId,
        customerMc: rowDetails.axeleCustomerMC,
        customerName: rowDetails.axeleCustomer,
        factoringId: rowDetails.factoringId,
        id: rowDetails.id,
        mappingCode: mappingObject.mappingCode,
        mappingId: mappingObject.mappingId,
        driverId: rowDetails.driverId,
        firstname: rowDetails.firstname,
        lastname: rowDetails.lastname,
        mappingName: mappingObject.mappingName,
        organizationId: rowDetails.organizationId,
        mappingDescription: mappingObject.mappingDescription,
      };
      if (tabSelected === 'customersMapping') {
        const response =
          await financeIntegrationService.customerFactoringMapingRecord(
            payLoad
          );
        if (response instanceof ServiceError) {
          return;
        } else {
          mappingInfo && mappingInfo(mappingObject);
        }
      } else if (tabSelected === 'fundingAccountsMapping') {
        const response =
          await financeIntegrationService.driverFactoringMapingRecord(payLoad);

        if (response instanceof ServiceError) {
          return;
        } else {
          mappingInfo && mappingInfo(mappingObject);
        }
      }
    }
  };

  const textInputStyles = {
    outline: 'none',
    '.MuiInput-root': {
      '&:hover': {
        '&:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
      },
      '&::before': {
        borderBottom: 'none',
      },
    },
  };

  const dropdownStyles = {
    width: '100%',
    '.MuiInput-root': {
      '&::after': {
        border: 'none',
      },
    },
    '.MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  };
  return (
    <>
      <SingleAutocomplete
        name={'mappingName'}
        fieldName={'mappingName'}
        label={value ? null : 'Select Map'}
        getOptions={getOptions}
        value={value || null}
        customTextInputStyles={textInputStyles}
        customStyles={dropdownStyles}
        onChangeCb={handleChange}
      />
    </>
  );
};
