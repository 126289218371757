import { useFormContext } from 'react-hook-form';
import FormPhoneField from '../../../../../../common/Ui/FormPhoneField';
import TextField from '../../../../../../common/Ui/TextField/TextField';
const defaultTextFieldProps = {
  style: { marginBottom: 0 },
};
export default function ContactForm({
  index,
  canEdit,
}: {
  index: number;
  canEdit: boolean;
}) {
  const { control } = useFormContext();

  return (
    <>
      <TextField
        disabled={!canEdit}
        control={control}
        name={`contacts.${index}.name`}
        label="Name"
        sizes={{ xs: 12 }}
        required
        {...defaultTextFieldProps}
      />
      <FormPhoneField
        disabled={!canEdit}
        control={control}
        name={`contacts.${index}.phoneData`}
        label="Phone #"
        sizes={{ xs: 12 }}
        gridSxProp={{
          mt: 1,
          mb: 0,
        }}
      />
      <TextField
        disabled={!canEdit}
        control={control}
        name={`contacts.${index}.email`}
        label="Email"
        sizes={{ xs: 12 }}
        {...defaultTextFieldProps}
      />
      <TextField
        disabled={!canEdit}
        control={control}
        name={`contacts.${index}.description`}
        label="Description"
        sizes={{ xs: 12 }}
        {...defaultTextFieldProps}
      />
    </>
  );
}
