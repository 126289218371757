import { useEffect, useState } from 'react';
import EntityNotesActions from '@/common/EntityNotes/EntityNotesActions';
import { EntityNoteItem } from '@/models';
import { NoteEntityType } from '@/common/EntityNotes/utils/types';

export const useEntityNotes = (
  entityId: string,
  entityType: NoteEntityType
) => {
  const [entityNotes, setEntityNotes] = useState<EntityNoteItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEntityNotes();
  }, [entityId]);

  const getEntityNotes = async () => {
    setLoading(true);
    const notes = await EntityNotesActions.getEntityNotes(entityId, entityType);
    if (notes) {
      setEntityNotes(notes.reverse());
    }
    setLoading(false);
  };

  return {
    entityNotes,
    getEntityNotes,
    loading,
  };
};
