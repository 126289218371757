import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { LoadboardPickerFilter } from '../../../../common/Ui/LoadboardPicker/LoadboardPickerFilter';
import { LoadboardPickerForm } from '../../../../common/Ui/LoadboardPicker/LoadboardPickerForm';
import { MultipleAutocompleteForm } from '../../../../common/Ui/MultipleAutocomplete';
import { RelativeDateRangeUtils } from '../../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { RelativeDateRangeForm } from '../../../../common/Ui/RelativeDateRange/RelativeDateRangeForm';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import { IFiltersFormConfig } from '../../../trips/constants/types';
import LocationSelectFilter from '../../../../common/Ui/LocationSelect/LocationSelectFilter';
import { LocationSelectForm } from '../../../../common/Ui/LocationSelect/LocationSelectForm';
import LoadboardGridConfig from '../../LoadboardDataGrid/grid.config';
import { useStores } from '../../../../store/root.context';

export function LoadboardMetaDataForm() {
  const {
    loadboardStore: { getLoadboardAccounts, isZoneDisabled },
  } = useStores();
  const { control } = useFormContext();

  const gridConfig = new LoadboardGridConfig({
    loadboardAccounts: getLoadboardAccounts,
    isZoneDisabled,
  });
  const filtersFormConfig = gridConfig.config.getFiltersFormConfig();
  const { defaultOption, ...relativeDateRangeSettings } =
    RelativeDateRangeUtils.getSettingsLoadboards();

  return (
    <Box
      sx={{
        marginTop: '15px',
      }}
    >
      {filtersFormConfig.map((config: IFiltersFormConfig) => {
        if (config.type === 'RelativeDateRange') {
          return (
            <div key={`filter-form-${config.name}`}>
              <RelativeDateRangeForm
                control={control}
                name={`metaData[${config.name}]`}
                label={config.label}
                sizes={{ xs: 12 }}
                variant={'standard'}
                {...relativeDateRangeSettings}
              />
            </div>
          );
        } else if (config?.customComponent === LoadboardPickerFilter) {
          return (
            <div key={`filter-form-${config.name}`}>
              <LoadboardPickerForm
                control={control}
                name={`metaData[${config.name}]`}
                options={config.options}
                defaultValue={config.defaultValue}
              />
            </div>
          );
        } else if (
          config?.customComponent?.name === LocationSelectFilter?.name
        ) {
          return (
            <div key={`filter-form-${config.name}`}>
              <LocationSelectForm
                control={control}
                name={`metaData[${config.name}]`}
                label={config.label}
                options={config.options}
                defaultValue={config.defaultValue}
                isZoneDisabled={isZoneDisabled}
              />
            </div>
          );
        } else if (
          config.type === 'SingleAutocomplete' ||
          (config.type === 'ButtonGroup' && !config.multiple) ||
          config.type === 'GridSwitchFilter'
        ) {
          return (
            <div key={`filter-form-${config.name}`}>
              <SingleAutocompleteForm
                control={control}
                name={`metaData[${config.name}]`}
                fieldName={config.fieldName as string}
                label={config.label}
                options={config.options}
                defaultValue={config.defaultValue}
              />
            </div>
          );
        } else if (
          config.type === 'MultipleAutocomplete' ||
          (config.type === 'ButtonGroup' && config.multiple)
        ) {
          return (
            <div key={`filter-form-${config.fieldName}`}>
              <MultipleAutocompleteForm
                key={config.key}
                control={control}
                name={`metaData[${config.fieldName}]`}
                fieldName={config.fieldName}
                {...(config.getData && {
                  getOptions: config.getData,
                })}
                {...(config.options && {
                  options: config.options,
                })}
                label={config.label}
                variant={'standard'}
              />
            </div>
          );
        } else if (config.type === 'TextField') {
          return (
            <TextField
              key={`filter-form-${config.name}`}
              control={control}
              name={`metaData[${config.name}]`}
              type="number"
              label={config.label}
              defaultValue={config.defaultValue}
            />
          );
        } else {
          throw new Error('Invalid config');
        }
      })}
    </Box>
  );
}
