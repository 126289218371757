import { createContext, useContext } from 'react';
import { RootStore } from './rootStore';

export const RootStoreInstence = new RootStore();

export const RootStateContext = createContext<RootStore>(RootStoreInstence);

//We do not need to use RootStateProvider
// const RootStateProvider: FC<React.PropsWithChildren<{}>> = ({ children }) => {
//   return (
//     <RootStateContext.Provider value={RootStoreInstence}>
//       {children}
//     </RootStateContext.Provider>
//   );
// };

export function useRootStore(): RootStore {
  const store = useContext(RootStateContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
