import { Box } from '@mui/material';

interface PopupActionsProps {
  onBidHandler: () => void;
  onBookHandler: (event: any, params: any, typeOfBook: string) => void;
  pendingLoadBookConfirmationStatus?: any;
  actionBidBook: any;
}
export const PopupActions = ({
  actionBidBook,
}: PopupActionsProps): JSX.Element => {
  return (
    <Box
      sx={{
        padding: '16px 0',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {actionBidBook}
    </Box>
  );
};
