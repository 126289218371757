import { Theme } from '@mui/material';

export const ModalContainerStyles = {
  padding: '16px',
  marginLeft: '30px',
  marginRight: '30px',
  display: 'flex',
};

export const ModalInnerContainerStyle = {
  bgcolor: '#35446B',
  boxShadow: '0px -11px 25px rgba(78, 101, 120, 0.25)',
  borderRadius: '16px',
  width: '100%',
  height: '100%',
  padding: '0 16px 24px 16px',
  outline: 'none',
  position: 'relative',
  margin: 'auto',
  maxHeight: '100%',
};

export const ModalTitleStyles = {
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '150%',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.15px',
  color: '#FFFFFF',
};

export const ActionBodyStyles = {
  background: '#FFFFFF',
  borderRadius: '16px',
  width: '100%',
  height: '100%',
  padding: '16px 32px 0',
};

export const BodyContainerStyles = {
  height: 'calc(100% - 40px)',
};

export const LoaderContainerStyles = {
  position: 'relative' as const,
  margin: 'auto',
  borderRadius: '40px',
  maxWidth: '450px',
  width: '100%',
  outline: 'none',
  maxHeight: '100%',
  overflow: 'auto',
  background:
    'radial-gradient(50% 50.00% at 50% 50.00%, rgba(33, 39, 57, 0.89) 29.17%, rgba(15, 21, 37, 0.89) 100%)',
  boxShadow: '0px -11px 25px 0px rgba(78, 101, 120, 0.25)',
  backdropFilter: 'blur(6px)',
  opacity: '89%',
};

export const LoaderTextStyles = {
  fontFamily: 'Oxanium',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 200,
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: '#FFFFFF',
  height: '20px',
};

export const AnimationBodyStyles = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '0 40px 40px',
  outline: 'none',
};

export const RejectButtonStyles = (theme: Theme, contained?: boolean) => ({
  border: `1px solid ${(theme?.palette?.error as any)?.contrastText}`,
  color: contained
    ? (theme?.palette?.primary as any)?.contrast
    : (theme?.palette?.error as any)?.contrastText,
  ':hover': {
    color: (theme?.palette?.primary as any)?.contrast,
    background: (theme?.palette?.error as any)?.contrastText,
    '*': {
      color: (theme?.palette?.primary as any)?.contrast,
    },
  },
  background: contained
    ? (theme?.palette?.error as any)?.contrastText
    : 'inherit',
});
