import { IPaymentDataGridFilters } from '../../constants/types';

export const defaultPaymentDataGridFilters: IPaymentDataGridFilters = {
  paymentIds: [],
  paymentSeqNumberList: [],
  paymentMethodList: [],
  invoiceSeqNumberList: [],
  paymentStatusList: null,
  referenceIds: [],
  customerNameFilter: [],
  paymentFromDate: null,
  paymentToDate: null,
  remainCredit: true,
};

const allPaymentColumnsInfo = {
  id: true,
  seqNumber: true,
  customer: true,
  paymentStatus: true,
  paymentMethod: true,
  paymentDate: true,
  invoicedetails: true,
  paymentReferenceId: true,
  amountReceived: true,
  remainingCredit: true,
  memo: true,
  isAdvanced: true,
  assignedDocuments: true,
};

export const allPaymentView = {
  id: 'allPayment',
  shared: true,
  parentId: null,
  name: 'Payments',
  entity: 'payment',
  icon: <></>,
  active: true,
  default: true,
  columnFilters: {
    ...allPaymentColumnsInfo,
  },
  metaData: defaultPaymentDataGridFilters,
};
export const paymentDefaultViews = [
  {
    ...allPaymentView,
  },
];
export const paymentFormDefaultValuesMap = {
  payments: allPaymentView,
};
