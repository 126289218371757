import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box } from '@mui/material';
import MultipleAutocomplete from '../../../ui-kit/components/MultipleAutocomplete';
import { t } from 'i18next';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface MenuTerminalProps {
  terminals: any[];
  getTerminalData: () => Promise<void>;
  onChangeTerminals: (event: any, newValue: any) => void;
  selectedTerminal: any[];
}

const MenuTerminal = ({
  terminals,
  getTerminalData,
  onChangeTerminals,
  selectedTerminal,
}: MenuTerminalProps) => {
  const getDefault = (
    terminals: Array<object>,
    selectedTerminals: Array<object>
  ) => {
    let tempSelected: any = [];
    selectedTerminals.map((selected: any) => {
      terminals.map((allTerminals: any) => {
        if (selected.id == allTerminals.id)
          tempSelected = [...tempSelected, allTerminals];
      });
    });
    return tempSelected;
  };

  return (
    <Box>
      <MultipleAutocomplete
        id="checkboxes-tags-demo"
        options={terminals || []}
        onChangeCb={onChangeTerminals}
        value={getDefault(terminals, selectedTerminal)}
        fieldName={'companyName'}
        label={t('terminal')}
        customStyles={{
          width: 250,
          color: 'primary.contrast',
          marginRight: 2,
        }}
        icon={icon}
        checkedIcon={checkedIcon}
        onOpen={getTerminalData}
        customInputLabelProps={{
          color: 'primary.contrast',
        }}
        customTextInputStyles={{
          color: 'primary.contrast',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'tabStrip.actionIcon',
          },
          '& .MuiSvgIcon-root': {
            color: 'tabStrip.actionIcon',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'tabStrip.actionIcon',
          },
          '& label.Mui-focused': {
            color: 'tabStrip.actionIcon',
          },
          '& .MuiFormLabel-root.Mui-disabled': {
            color: 'tabStrip.actionIcon',
          },
          '& .MuiOutlinedInput-root:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'tabStrip.actionIcon',
            },
          },
        }}
        size={'small'}
        areChipsInsideOption
        ChipProps={{ color: 'primary', sx: { width: 100 } }}
      />
    </Box>
  );
};

export default MenuTerminal;
