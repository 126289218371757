import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FC } from 'react';
import { useThemeResponsive } from '../hooks/useThemeResponsive';
import { backDropStyles, style } from './style';

interface CustomModalProps {
  header: any;
  container: any;
  footer: any;
  show: boolean;
  onClose: () => void;
}

const CustomModal: FC<CustomModalProps> = ({
  header,
  container,
  footer,
  show,
  onClose,
}) => {
  const { isMobile } = useThemeResponsive();
  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onBackdropClick={onClose}
      BackdropProps={{ style: backDropStyles }}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          ...style,
          ...(isMobile && {
            width: '100%',
            height: '100%',
          }),
        }}
      >
        {header}
        {container}
        {footer}
      </Box>
    </Modal>
  );
};

export default CustomModal;
