import React, { ReactNode } from 'react';

/* Anoush will handle this part*/
interface IProps {
  children: ReactNode;
}

const TabStripContainer: React.FC<IProps> = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
};

export default TabStripContainer;
