import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ediService, loadTenderService } from '../../../../api';
import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission';
import { getHasPermission } from '../../../../common/Permission/utils/helperUtils';
import { ITerminals } from '../../../../types/LoadboardTypes';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import AccountsSection from '../../../../ui-kit/components/IntegrationAccountCards/AccountsSection';
import ProviderDescription from '../../../../ui-kit/components/ProviderDescription';
import ProviderHero from '../../../../ui-kit/components/ProviderHero';
// import { EDIProviderDTO } from 'models/DTOs/EDI/Requests';
import { ServiceError } from '../../../../api/interfaces';
import { CardDataDTO } from '../../../../models/DTOs';
import { EDIProviderDTO } from '../../../../models/DTOs/EDI/Requests';
import {
  IloadTenderData,
  IloadTenderListWithLogo,
} from '../../../../types/LoadTenderTypes';
import { LoadTenderProvider } from '../../../../utils/customerMap';
import { EDICompanyCard } from '../EDIActivation/EDICompanyCard';
import { getStyledEDIActivation } from '../EDIActivation/styles';
import { AccountingPopup } from '../Finance/AccountingPop/accountingPopup';
import { EMenuAction } from '../Finance/FactoreSidedrawer/FactoreUtil';
import { TurvoBrokerModal } from './Turvo/TurvoBrokerModal';
import {
  connectBtnStyle,
  ediStackStyle,
  tendetStackStyle,
} from './style/turvoStyle';

export default function SelectedLoadTender({
  currentCustomerData,
  loadTenderData,
  allTerminals,
  getTenderList,
}: IProps) {
  const [open, setOpen] = useState(false);
  const [isEditTab, setIsEditTab] = useState(false);
  const [cardData, setCardData] = useState({});

  const [cardId, setCardId] = useState<string>('');
  const [entityType, setEntityType] = useState<string>('');
  const [terminalIds, setTerminalIds] = useState<any>([]);
  const [selectedCardForDeletion, setCardForDeletion] = useState<{
    id: number;
    integrationType: string;
    organizationId: number;
    data: Array<any>;
  }>();

  const getCardParams = (entityType: string, data: CardDataDTO) => {
    const cardId = data.id;
    setTerminalIds(data.terminalIds);
    setCardId(cardId as string);
    setEntityType(entityType);
  };
  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
    if (isEditTab) {
      setIsEditTab(false);
      setCardData({});
    }
  };

  const deactivateAccount = async () => {
    if (selectedCardForDeletion) {
      let dataId = '';
      selectedCardForDeletion?.data?.filter(
        (cardData: { name: string; value: string }) => {
          if (cardData.name === 'id') {
            dataId = cardData.value;
          }
        }
      );
      const data = {
        id: dataId,
        organizationId: selectedCardForDeletion['organizationId'],
      };
      try {
        const response = await loadTenderService.deleteBrokerAccount(data);
        if (response) {
          setDeletePopupView(false);
          getTenderList();
        }
      } catch (err) {
        return;
      }
    }
  };

  const editAccount = (cardData: any) => {
    setIsEditTab(true);
    setCardData(cardData?.data);
    setOpen(true);
  };

  const axelePermissions = {
    showAddBtn: true,
    showEdit: true,
    showRemove: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_LOADBOARD_REMOVE],
    }),
  };
  const ediData = {
    heading1: 'Electronic Data Interchange (EDI)',
    intro:
      'LoadOps EDI allows carriers to exchange information electronically with other businesses.With this feature, carriers can view loads shared on EDI, book or reject loads, and send shipment status updates. The feature also includes an automatic load creation in LoadOps, which eliminates manual data entry and reduces the risk of errors.This helps to increase profits and reduce manual workload, resulting in a more efficient and streamlined shipping process.',
    outtro:
      'Please contact LoadOps support team to activate or deactivate a customer integration.',
    email: 'loadops.support@optym.com',
    SCACCode: 'TRKN',
  };
  const [providers, setProviders] = useState<EDIProviderDTO[]>([]);
  const init = async () => {
    const data = await ediService.getProviders();
    if (data instanceof ServiceError) {
      setProviders([]);
    } else {
      setProviders(data || []);
    }
  };
  useEffect(() => {
    init();
  }, []);
  const [isDeletePopupView, setDeletePopupView] = useState<boolean>(false);

  const styles = getStyledEDIActivation();
  const meunuPopupAction = (param: any): void => {
    if (param.type === EMenuAction.DELETE) {
      setCardForDeletion(param?.data);
      setDeletePopupView(true);
    }
  };
  const companyList =
    currentCustomerData.providerName === LoadTenderProvider.BITFREIGHTER
      ? [
          {
            id: currentCustomerData.providerName,
            title: LoadTenderProvider.BITFREIGHTER,
            activeCommunications: [
              'Incoming Tenders (204)',
              'Tender Responses (990)',
              'Shipment Status Updates (214)',
              'Invoices (21)',
            ],
            scac: '',
          },
        ]
      : providers?.map((item: EDIProviderDTO) => {
          return {
            id: item.id,
            title: item.providerName,
            activeCommunications: [
              'Incoming Tenders (204)',
              'Tender Responses (990)',
              'Shipment Status Updates (214)',
              'Invoices (21)',
            ],
            scac: item.scac,
          };
        });
  return (
    <Box className="selected-customer-block">
      <ProviderHero currentCustomerData={currentCustomerData} />
      <Stack sx={{ ...tendetStackStyle }}>
        <Box>
          <Grid container marginBottom={'30px'}>
            <Grid item xs={9}>
              <ProviderDescription currentCustomerData={currentCustomerData} />
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ ...connectBtnStyle }}>
                {currentCustomerData.providerName ==
                  LoadTenderProvider.TURVO && (
                  <>
                    {currentCustomerData.status == 'UNAVAILABLE' && (
                      <Button
                        variant="outlined"
                        sx={{ borderRadius: '5px' }}
                        onClick={() => toggleDrawer(true)}
                      >
                        Connect A Broker
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          {console.log(
            'currentCustomerData.providerName currentCustomerData.providerName',
            currentCustomerData.providerName,
            'companyList',
            companyList
          )}
          {[
            LoadTenderProvider.BITFREIGHTER,
            LoadTenderProvider.LOADOPSEDI,
          ].includes(currentCustomerData.providerName) && (
            <Box>
              <Typography sx={styles.outtro}>{ediData.outtro}</Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ ...ediStackStyle }}
              >
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={0}
                >
                  <Typography sx={styles.itemLabel}>Email</Typography>
                  <Typography sx={styles.itemValue}>{ediData.email}</Typography>
                </Stack>
                {currentCustomerData.providerName !=
                  LoadTenderProvider.BITFREIGHTER && (
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={0}
                  >
                    <Typography sx={styles.itemLabel}>
                      Your SCAC Code
                    </Typography>
                    <Typography sx={styles.itemValue}>
                      {companyList[0]?.scac}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                {companyList.map((item) => (
                  <EDICompanyCard
                    key={item.id}
                    title={item.title}
                    activeCommunications={item.activeCommunications}
                  />
                ))}
              </Stack>
            </Box>
          )}
        </Box>

        {currentCustomerData.status != 'UNAVAILABLE' &&
          currentCustomerData.providerName == LoadTenderProvider.TURVO && (
            <AccountsSection
              isCustomMenuHandling={true}
              openDrawer={() => toggleDrawer(true)}
              currentCustomerData={currentCustomerData}
              providerData={loadTenderData}
              allTerminals={allTerminals}
              sectionHeading={'Broker (s)'}
              isDeletePopupOpen={false}
              openConfirmationPopup={meunuPopupAction}
              editAccount={editAccount}
              integrationType={'LOADBOARD'}
              selectedCardForDeletion={cardData}
              axelePermissions={axelePermissions}
              isArchivedSection={false}
              clickedParamToManage={getCardParams}
              customAccountCardStyle={{ minHeight: '100px' }}
              cardWithIconStyle={{ minHeight: '165px' }}
              componentToRenderInsideModal={
                <AccountingPopup
                  AccountCardId={cardId}
                  entityType={entityType}
                  terminalIds={terminalIds}
                />
              }
            />
          )}
        <DeletePopup
          open={isDeletePopupView}
          onClose={() => setDeletePopupView(false)}
          onAction={() => deactivateAccount()}
          title={'Delete Broker'}
          body={'Are you sure you want to delete Broker account?'}
          buttonText={'Delete'}
        />
        <TurvoBrokerModal
          open={open}
          toggleDrawer={toggleDrawer}
          getTenderList={getTenderList}
          cardData={cardData}
          isEdit={isEditTab}
        />
      </Stack>
    </Box>
  );
}

interface IProps {
  currentCustomerData: IloadTenderListWithLogo;
  loadTenderData: Array<IloadTenderData> | any;
  allTerminals: Array<ITerminals> | null;
  getTenderList: () => void;
}
