import { Theme } from '@mui/material';

export const root = (theme: Theme) => ({
  display: 'flex',
  borderRadius: '16px',
  flexDirection: 'column',
  width: '100%',
  maxHeight: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  p: 1,
  pb: 5,
});

export const container = (theme: Theme) => ({
  height: '100%',
  borderRadius: '16px',
  backgroundColor: '#fff',
  padding: 1,
  overflowY: 'scroll',
});
