import { fromMomentToIso, ParseToDate } from '../../../utils';
import { Payload } from '../commonMixed/Payload';
import { QueryParams } from '../commonMixed/QueryParams';
import { TrailerAlertSummaryDTO } from '../../../models';

export class PaginatedTrailerListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;
  gridColumnMetadataList?: string[] | null;
  driverGroupFilter?: string[] | null;
  vinFilter?: string[];
  nameFilter?: string[];
  licensePlateFilter?: string[];
  safetyIssueFilter?: string[];
  statusFilter?: string[];
  inTransitFilter?: string[];
  regExpiryFrom?: string;
  regExpiryTo?: string;
  terminalIds?: string[];
}

export class VerifyTrailerByIdQueryParams extends QueryParams {
  trailerId: string;
  constructor(id: string) {
    super();
    this.trailerId = id;
  }
}

export class TrailerCreateQueryParams extends Payload {
  trailerName: string;
  terminalId: number | null;
  id?: string;
  vin?: string;
  inTransitFilter?: string[];
  status?: string; // should be deleted
  licensePlateNumber?: string;
  validTill?: string | null;
  assetLocationDTO: any;
  ownershipType?: string;
  ownedCompany?: string;
  licenseState?: string;
  extension?: number;
  mobileno?: number;
  ownerEmail?: string;
  ownerAddress?: any;
  make?: string;
  model?: string;
  year?: number;
  gvw?: number;
  insuranceCompany?: string;
  insuranceStartDate?: string | null;
  insuranceEndDate?: string | null;
  fieldsToBeSetNull?: string[];
  trailerLength?: number | null;
  trailerType?: string | null;
  payloadCapacity?: number | null;

  constructor(trailer: any) {
    super(true);
    if (trailer.id) {
      this.id = trailer.id;
    }
    this.status = trailer.status;
    this.trailerName = trailer.trailerName;
    this.terminalId = trailer.terminalId || null;
    this.vin = trailer.vin || null;
    this.licensePlateNumber = trailer.licensePlateNumber || null;
    this.validTill = fromMomentToIso(trailer.validTill);
    this.assetLocationDTO = this.setupAssetLocationDTO(trailer);
    this.ownershipType = trailer.ownershipType || null;
    this.ownedCompany = trailer.ownedCompany || null;
    this.licenseState = trailer.licenseState || null;
    this.extension = trailer.phone.extension || null;
    this.mobileno = trailer.phone.phone || null;
    this.ownerEmail = trailer.ownerEmail || null;
    this.ownerAddress = trailer.ownerAddress || null;
    this.make = trailer.make || null;
    this.model = trailer.model || null;
    this.trailerLength = trailer.trailerLength || null;
    this.trailerType = trailer.type || null;
    this.payloadCapacity = trailer.payloadCapacity || null;
    this.year = trailer.year || null;
    this.gvw = trailer.gvw || null;
    this.insuranceCompany = trailer.insuranceCompany || null;
    this.insuranceStartDate = trailer.insuranceDate[0];
    this.insuranceEndDate = trailer.insuranceDate[1];
    if (trailer.id) {
      this.setFieldsToBeSetNull();
    }
  }

  setFieldsToBeSetNull() {
    // to be sure that boolean values are not set to null
    const fields: string[] = [];
    Object.keys(this).forEach((k) => {
      const key = k as keyof TrailerCreateQueryParams;
      const item = this[key];
      if (!item && item !== false && item !== 0) {
        fields.push(key);
      }
    });
    this.fieldsToBeSetNull = fields;
    if (!this.assetLocationDTO.addressDTO?.address) {
      this.fieldsToBeSetNull.push('assetLocation');
      this.assetLocationDTO.addressDTO = null;
    }
    if (!this.ownerAddress?.address) {
      this.fieldsToBeSetNull.push('ownerAddress');
      this.ownerAddress = null;
    }
  }

  setupAssetLocationDTO(trailer: any) {
    return {
      addressDTO: trailer.lastLocation || null,
      locationSource: trailer.locationSource || null,
    };
  }
}

export class TrailerAlertQueryParamsRequest extends QueryParams {
  constructor(private trailerId: number) {
    super();
    this.trailerId = trailerId;
  }
}

export class CreateTrailerAlertQueryParamsRequest extends Payload {
  private trailerId: number;
  private maintenanceTypeId: string;
  private maintenanceType: string;
  private lastConducted: string;
  private nextDue: string;
  constructor(alert: TrailerAlertSummaryDTO) {
    super(true);
    this.trailerId = alert.trailerId;
    this.maintenanceTypeId = alert.maintenanceTypeId;
    this.maintenanceType = alert.maintenanceType;
    this.lastConducted = ParseToDate(alert.lastConducted);
    this.nextDue = ParseToDate(alert.nextDue);
  }
}

export class UpdateTrailerAlertListRequest extends QueryParams {
  private id: string;
  private trailerId: number;
  private maintenanceTypeId: string;
  private maintenanceType: string;
  private lastConducted: string;
  private nextDue: string;

  constructor(alert: TrailerAlertSummaryDTO) {
    super();
    this.id = alert.id;
    this.lastConducted = ParseToDate(alert.lastConducted);
    this.maintenanceType = alert.maintenanceType;
    this.maintenanceTypeId = alert.maintenanceTypeId;
    this.nextDue = ParseToDate(alert.nextDue);
    this.trailerId = alert.trailerId;
  }
}

export class TrailerAlertByIdQueryParams extends QueryParams {
  constructor(private id: string) {
    super();
    this.id = id;
  }
}

export class DriverTrailerAssignRequest extends QueryParams {
  driverGroupId!: string;
  trailerId!: number;
  constructor(dto: { driverGroupId: string; trailerId: number }) {
    super();
    this.driverGroupId = dto.driverGroupId;
    this.trailerId = dto.trailerId;
  }
}

export class ChangeTrailerStatusRequest extends QueryParams {
  id!: number;
  status!: string;
  constructor(dto: { trailerId: number; status: string }) {
    super(false);
    this.id = dto.trailerId;
    this.status = dto.status;
  }
}

export class MarkTrailerAsInactiveRequest extends QueryParams {
  trailerId!: string;
}
