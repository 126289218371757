import { Grid, SxProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import LocationSelect, { ILocationSelect, TLocationSelectValue } from './index';

export interface LocationSelectFormProps extends ILocationSelect {
  control?: Control;
  sizes?: {
    xs?: number;
  };
  wrapperStyles?: SxProps;
  defaultValue?: TLocationSelectValue;
}
export const LocationSelectForm = ({
  name,
  label,
  control,
  sizes = {},
  wrapperStyles = {},
  defaultValue,
  isZoneDisabled,
  ...restProps
}: LocationSelectFormProps) => {
  return (
    <Grid xs={sizes.xs} item sx={{ marginBottom: '20px', ...wrapperStyles }}>
      <Controller
        render={({ field: { onChange, value, error } }) => {
          return (
            <LocationSelect
              label={label}
              onChange={(value) => {
                onChange(value, name);
              }}
              value={value}
              error={error}
              isZoneDisabled={isZoneDisabled}
              {...restProps}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </Grid>
  );
};
