export const ToolTips = {
  getTripV3BrokeredOptionUnassignTheDriverTractorTrailer:
    'This action will disassociate the driver/tractor/trailer from the trip',
  getTripV3BrokeredOptionRevertTheStatus:
    'Revert the status of trip to available/planned/assigned in order to make changes',
  getTripV3BrokeredOptionUnassignTheCarrier:
    'This action will disassociate the carrier from the trip',
  FullScreenButtonMaximize: 'Maximize',

  gapTooltipPropsGap: 'Gap',
  gapTooltipPropsAvailabilityStart: 'Availability Start',
  gapTooltipPropsGapStart: 'Gap Start',
  gapTooltipPropsHOSatGapStart: 'HoS at Gap Start',
  getEmptyTripActionsAssignTrip: 'Assign Trip',
  getEmptyTripActionsSearchLoadboards: 'Search Loadboards',
  getEmptyTripActionsRecommendTrips: 'Recommend Trips',

  generateGapAdditionalSubtitlesEstAvailableDutyTime:
    'Est. Available Duty Time',
  generateGapAdditionalSubtitlesEstAvailableDriveTime:
    'Est. Available Drive Time',
  generateGapAdditionalSubtitlesDeadheadtoNext: 'Deadhead to Next',
  generateGapAdditionalSubtitlesGapEnd: 'Gap End',

  generateHOSProgressNextBreak: 'Next Break',
  generateHOSProgressDrive: 'Drive',
  generateHOSProgressDuty: 'Duty',
  generateHOSProgressCycle: 'Cycle',

  getTripTooltipTrip: 'Trip',
  getTripTooltipViewDetails: 'View Details',
  getTripTooltipReverttoAvailable: 'Revert to Available',

  generateTripSubtitlesLoads: 'Loads(s)',
  generateTripSubtitlesReference: 'Reference #',
  generateTripSubtitlesCustomer: 'Customer(s)',
  generateTripSubtitlesDispatcher: 'Dispatcher',

  generateTripDataBusinessName: 'Business Name:',

  generateKpiContentRevenue: 'Revenue',
  generateKpiContentDriverPay: 'Driver Pay',
  generateKpiContentTripTime: 'Trip Time',
  generateKpiContentTripMiles: 'Trip Miles',
  generateKpiContentLoadedMiles: 'Loaded Miles',
  generateKpiContentCommission: 'Commission($)',
  generateKpiContentCarrierPay: 'Carrier Pay($)',

  getDriverTooltiActionsRecommendTrips: 'Recommend Trips',
  getDriverTooltiActionsViewTimeline: 'View Timeline',

  generateDriverTooltipSubtitlesID: 'ID',
  generateDriverTooltipSubtitlesTractor: 'Tractor',
  generateDriverTooltipSubtitlesTrailer: 'Trailer',
  generateDriverTooltipSubtitlesType: 'Type',
  generateDriverTooltipSubtitlesHome: 'Home',
  generateDriverTooltipSubtitlesContact: 'Contact',

  generateDriverKpisMileage: 'Mileage',
  generateDriverKpisDriverPay: 'Driver Pay',
  generateDriverKpisRevenue: 'Revenue',

  generateDriverHosDetailsNextBreak: 'Next Break',
  generateDriverHosDetailsDrive: 'Drive',
  generateDriverHosDetailsDuty: 'Duty',
  generateDriverHosDetailsCycle: 'Cycle',

  getColumnToolTipPropsUpdated: 'Updated',
  getColumnToolTipPropsLastLocation: 'Last Location:',
  getColumnToolTipPropsWeek: 'Week',

  DRIVER_COLUMN_HEADER_TOOLTIPDriverName: 'Driver Name',
  DRIVER_COLUMN_HEADER_TOOLTIPDriverLocation: 'Driver Location',
  DRIVER_COLUMN_HEADER_TOOLTIPTractorID: 'Tractor ID',
  DRIVER_COLUMN_HEADER_TOOLTIPTrailerID: 'Trailer ID',
  DRIVER_COLUMN_HEADER_TOOLTIPProjectedRevenue: 'Projected Revenue',
  DRIVER_COLUMN_HEADER_TOOLTIPRevenueGoal: 'Revenue Goal',
  DRIVER_COLUMN_HEADER_TOOLTIPProjectedDriverEarning:
    'Projected Driver Earnings',
  DRIVER_COLUMN_HEADER_TOOLTIPTargetedDriverEarning: 'Targeted Driver Earnings',
  DRIVER_COLUMN_HEADER_TOOLTIPProjectedMileage: 'Projected Mileage',
  DRIVER_COLUMN_HEADER_TOOLTIPTargetedMileage: 'Targeted Mileage',

  driveTimetoLocation: 'Drive Time to Location',
  milestoLocation: 'Miles to Location',
};
