/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  IFTAPdfRequest,
  IFTAPdfResult,
  IFTAReportsRequest,
  IFTAReportsResult,
} from '../../models';
import { Service } from './Service';

export abstract class IIFTAService extends Service {
  abstract getIFTAReports(
    requestData: IFTAReportsRequest
  ): Promise<IFTAReportsResult>;

  abstract getIftaReportPdf(
    requestData: IFTAPdfRequest
  ): Promise<IFTAPdfResult>;
}
