import * as yup from 'yup';
import moment from 'moment';

import { nullableDateYup, nullableNumberYup } from '../../utils';
import {
  DeleteButtonIcon,
  OkIcon,
  PastDueIcon,
  UpcomingIcon,
} from '../../ui-kit/components/Assets';

import {
  AssignDocumentRequest,
  CreateDocumentOnlyRequest,
  DefaultTractorsTypesListSummaryDTO,
  DefaultTrailersTypesListSummaryDTO,
  DocumentSummaryResponse,
  EDocumentTypes,
  ExpensesStaticDataType,
  QueryParams,
  TractorAlertSummaryDTO,
  TrailerAlertSummaryDTO,
} from '../../models';

import Controller from '../tractors/Controller';
import TrailerController from '../trailers/TrailerController';
import { documentService } from '../../api';
import { Box, Grid, Typography } from '@mui/material';
import FileUploadUpdated from '../../common/Ui/FileUpload/indexUpdated';
import { deleteFile } from '../expenses/utils/utils';
import { Tractor } from '../tractors/models/Tractor';
import { Trailer } from '../trailers/models/Trailer';
import { EquipmentType } from '../../views/maintenanceRecords/constants/constants';
import { TractorSummary } from '../tractors/models/TractorSummary';
import { AXELE_PERMISSION_TYPE, Permission } from '../../common/Permission';
import { UI_MAINTENANCE_HISTORY_COMPLETED_DATE_FORMAT } from '../../constants/date.constants';
import StorageManager from '../../StorageManager/StorageManager';
import FileUpload from '../../common/Ui/FileUpload';

export const trailerAlertValidationSchema = yup.object().shape(
  {
    maintenanceTypeId: yup.string().required('Maintenance Item is required.'),
    lastConducted: nullableDateYup()
      .required('Last Done is required.')
      .when(['nextDue', 'lastConducted'], (nextDue, lastConducted) => {
        if (nextDue && lastConducted) {
          return nullableDateYup().max(
            moment(nextDue).add(-1, 'days'),
            'Last Conducted must be before the Next Due.'
          );
        }
        return nullableDateYup().required('Last Done is required.');
      }),
    nextDue: nullableDateYup()
      .required('Next Due is required.')
      .when(['nextDue', 'lastConducted'], (nextDue, lastConducted) => {
        if (nextDue && lastConducted) {
          return nullableDateYup().min(
            moment(lastConducted).add(1, 'days'),
            'Next Due must be after the Last Done'
          );
        }

        return nullableDateYup().required('Next Due is required.');
      }),
  },
  [
    ['lastConducted', 'nextDue'],
    ['nextDue', 'lastConducted'],
    ['nextDue', 'nextDue'],
    ['lastConducted', 'lastConducted'],
  ]
);

export const tractorAlertValidationSchema = trailerAlertValidationSchema.concat(
  yup.object().shape(
    {
      lastServiceDueOdometer: nullableNumberYup().when(
        ['lastServiceDueOdometer', 'nextServiceDueOdometer'],
        (lastServiceDueOdometer, nextServiceDueOdometer) => {
          if (
            typeof lastServiceDueOdometer === 'number' &&
            typeof nextServiceDueOdometer === 'number'
          ) {
            return nullableNumberYup().max(
              nextServiceDueOdometer - 1,
              'Last Odometer must be less than Next Odometer.'
            );
          }
          return nullableNumberYup();
        }
      ),
      nextServiceDueOdometer: nullableNumberYup().when(
        ['lastServiceDueOdometer', 'nextServiceDueOdometer'],
        (lastServiceDueOdometer, nextServiceDueOdometer) => {
          if (
            typeof lastServiceDueOdometer === 'number' &&
            typeof nextServiceDueOdometer === 'number'
          ) {
            return nullableNumberYup().min(
              lastServiceDueOdometer + 1,
              'Next Odometer must be more than  Last Odometer.'
            );
          }
          return nullableNumberYup();
        }
      ),
    },
    [
      ['lastServiceDueOdometer', 'nextServiceDueOdometer'],
      ['nextServiceDueOdometer', 'lastServiceDueOdometer'],
      ['nextServiceDueOdometer', 'nextServiceDueOdometer'],
      ['lastServiceDueOdometer', 'lastServiceDueOdometer'],
    ]
  )
);

export const MenuOptions = [
  {
    label: 'Delete',
    icon: DeleteButtonIcon,
  },
];

export const addNameToAlertFromDictionary = (
  defaultType:
    | TractorAlertSummaryDTO
    | DefaultTractorsTypesListSummaryDTO
    | TrailerAlertSummaryDTO
    | DefaultTrailersTypesListSummaryDTO,
  entityType: string
) => {
  const { defaultTractorsTypesDictionary } = Controller.instance();

  const { defaultTrailersTypesDictionary } = TrailerController.instance();

  const dictionary =
    entityType === 'TRACTOR'
      ? defaultTractorsTypesDictionary
      : defaultTrailersTypesDictionary;

  const type = dictionary.find((s) => s.id === defaultType.maintenanceTypeId);

  return { ...defaultType, itemName: type?.itemName };
};

export const defaultMarkDoneAlertData = (
  maintenanceData,
  equipmentType,
  data,
  getGlobalTerminals
) => {
  const foundTerminal = getGlobalTerminals?.find(
    (item) => item.id === data?.terminalId
  );
  const defaultMaintenanceData = {
    equipmentType,
    equipmentName: data?.tractorName || data?.trailerName || data.id,
    equipmentId: +data.id,
    completedDate: moment().format(
      UI_MAINTENANCE_HISTORY_COMPLETED_DATE_FORMAT
    ),
    terminalName: foundTerminal?.companyName,
    terminalId: foundTerminal?.id,
    historyMaintenanceItems: [
      {
        id: maintenanceData?.maintenanceTypeId,
        itemName: maintenanceData?.itemName,
        itemCode: maintenanceData?.maintenanceType,
      },
    ],
  };
  return defaultMaintenanceData;
};

export const getTypeByMaintenanceTypeId = (
  maintenanceTypeId: string,
  entityType: string
) => {
  const { defaultTractorsTypesList } = Controller.instance();
  const { defaultTrailersTypesList } = TrailerController.instance();

  const typesList =
    entityType === 'TRACTOR'
      ? defaultTractorsTypesList
      : defaultTrailersTypesList;

  return typesList?.find(
    (item) => item.maintenanceTypeId === maintenanceTypeId
  );
};

export const findNextDueDateByFrequency = (
  date: string,
  maintenanceTypeId: string,
  entityType: string
) => {
  if (!date || !maintenanceTypeId) return null;

  let frequencyNumber: number | null = null;
  const type = getTypeByMaintenanceTypeId(maintenanceTypeId, entityType);

  switch (type?.frequency) {
    case 'YEARLY':
      frequencyNumber = 12;
      break;

    case 'EVERY_6_MONTHS':
      frequencyNumber = 6;
      break;

    case 'EVERY_3_MONTHS':
      frequencyNumber = 3;
      break;

    case 'EVERY_30_DAYS':
      frequencyNumber = 1;
      break;

    default:
      break;
  }

  return frequencyNumber
    ? moment(date).add(frequencyNumber, 'months')
    : moment(date).add(type?.frequencyValue, 'days');
};

const uploadDocument = async (assignedDocument: File) => {
  const queryParams = new QueryParams();
  queryParams.addUserIdToParams();

  const requestBody = new CreateDocumentOnlyRequest({
    data: assignedDocument,
    ownerId: queryParams.userId,
  });

  const response = await documentService.createDocumentOnly(requestBody);
  return response || null;
};

const assignDocument = async (
  file: DocumentSummaryResponse,
  responseData: Tractor | Trailer,
  staticData: ExpensesStaticDataType,
  typeData: string,
  type: string
) => {
  const user = StorageManager.getUser() || {};
  const documentType = staticData.documentTypes.find(
    (item) => item.itemCode === typeData
  );
  const propertiesTitle =
    responseData?.tractorName || responseData?.trailerName;
  const assignDocumentRequest = new AssignDocumentRequest({
    description: '',
    ownerName: user.firstname + ' ' + user.lastname,
    permission: 'PRIVATE',
    documentType: typeData,
    documentTypeId: documentType?.id || '',
    documentId: file.id,
    fileName: file.fileName,
    fileSize: file.fileSize,
    uploadDate: file.uploadDate,
    ownerId: user.id,
    terminalId: responseData?.terminalId,
    attachedEntities: [
      {
        properties: [
          {
            id: responseData?.id || '',
            title: propertiesTitle,
          },
        ],
        type,
      },
    ],
  });
  const response = await documentService.assignDocument(assignDocumentRequest);
  return response || null;
};

const createDocument = async (
  fileType: string,
  responseData: Tractor | Trailer,
  staticData: ExpensesStaticDataType,
  type: string
) => {
  if (!responseData?.assignedDocuments?.[fileType]?.length) return;

  const fetchFile = await uploadDocument(
    responseData?.assignedDocuments?.[fileType][0]
  );

  if (!fetchFile) return;

  const regData =
    type === EquipmentType.Trailer
      ? EDocumentTypes.TRAILER_REGISTRATION
      : EDocumentTypes.TRACTOR_REGISTRATION;

  const insData =
    type === EquipmentType.Tractor
      ? EDocumentTypes.TRACTOR_INSURANCE
      : EDocumentTypes.TRAILER_INSURANCE;
  const typeData = fileType === 'Registration' ? regData : insData;

  await assignDocument(fetchFile, responseData, staticData, typeData, type);
};

export const saveFile = async (
  responseData: Tractor | Trailer,
  staticData: ExpensesStaticDataType,
  type: string
): Promise<void> => {
  if (responseData) {
    await createDocument('Registration', responseData, staticData, type);
    await createDocument('Insurance', responseData, staticData, type);
  }
};

export const documentUploadSummaryRendererUpdated = (
  name: string,
  control: any,
  fileId?: string,
  disabled?: boolean
) => {
  return (
    <>
      <Permission contains={[AXELE_PERMISSION_TYPE.EQUIPMENT_EDIT]}>
        <FileUploadUpdated
          name={`assignedDocuments.${name}`}
          label="Upload"
          fileId={fileId}
          control={control}
          disabled={disabled}
        />
      </Permission>
    </>
  );
};

export const documentUploadSummaryRenderer = (
  name: string,
  control: any,
  isDisabled?: boolean
) => {
  return (
    <>
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item>
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <Permission contains={[AXELE_PERMISSION_TYPE.EQUIPMENT_EDIT]}>
            <Box
              component="span"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <FileUpload
                disabled={isDisabled}
                name={`assignedDocuments.${name}`}
                label="Upload"
                control={control}
              />
            </Box>
          </Permission>
        </Grid>
      </Grid>
    </>
  );
};

export const updateTractorAndTrailerDocs = async (
  updatedData: Tractor | Trailer,
  currentData: Tractor | Trailer,
  staticData: ExpensesStaticDataType,
  type: string
) => {
  const newAssignedDocuments = {
    Registration: [],
    Insurance: [],
  };
  if (
    updatedData?.assignedDocuments?.Registration &&
    currentData?.assignedDocuments?.Registration
  ) {
    if (
      updatedData?.assignedDocuments?.Registration[0]?.documentId !==
      currentData?.assignedDocuments?.Registration[0]?.documentId
    ) {
      const registrationDocId =
        currentData?.assignedDocuments?.Registration[0]?.documentId;
      if (registrationDocId) await deleteFile([registrationDocId]);
      newAssignedDocuments.Registration.push(
        updatedData?.assignedDocuments?.Registration[0]
      );
    }
  }

  if (
    updatedData?.assignedDocuments?.Insurance &&
    currentData?.assignedDocuments?.Insurance
  ) {
    if (
      updatedData?.assignedDocuments?.Insurance[0]?.documentId !==
      currentData?.assignedDocuments?.Insurance[0]?.documentId
    ) {
      const insuranceDocId =
        currentData?.assignedDocuments?.Insurance[0]?.documentId;
      if (insuranceDocId) await deleteFile([insuranceDocId]);
      newAssignedDocuments.Insurance.push(
        updatedData?.assignedDocuments?.Insurance[0]
      );
    }
  }

  if (updatedData?.assignedDocuments?.Registration) {
    if (
      !currentData?.assignedDocuments?.Registration &&
      updatedData?.assignedDocuments?.Registration
    ) {
      newAssignedDocuments.Registration.push(
        updatedData?.assignedDocuments?.Registration[0]
      );
    }
  }
  if (updatedData?.assignedDocuments?.Insurance) {
    if (
      !currentData?.assignedDocuments?.Insurance &&
      updatedData?.assignedDocuments?.Insurance
    ) {
      newAssignedDocuments.Insurance.push(
        updatedData?.assignedDocuments?.Insurance[0]
      );
    }
  }

  if (
    !updatedData?.assignedDocuments?.Registration &&
    currentData?.assignedDocuments?.Registration
  ) {
    const registrationDocId =
      currentData?.assignedDocuments?.Registration[0]?.documentId;
    if (registrationDocId) await deleteFile([registrationDocId]);
    newAssignedDocuments.Registration = undefined;
  }

  if (
    !updatedData?.assignedDocuments?.Insurance &&
    currentData?.assignedDocuments?.Insurance
  ) {
    const insuranceDocId =
      currentData?.assignedDocuments?.Insurance[0]?.documentId;
    if (insuranceDocId) await deleteFile([insuranceDocId]);
    newAssignedDocuments.Insurance = undefined;
  }

  const newUpdatedTractorData = {
    ...updatedData,
    assignedDocuments: newAssignedDocuments,
  };

  await saveFile(newUpdatedTractorData, staticData, type);
};

export const deleteTractorAndTrailerDocs = async (
  currentData: Tractor | Trailer
) => {
  const registrationId =
    currentData?.assignedDocuments?.Registration![0]?.documentId;
  if (registrationId) await deleteFile([registrationId]);

  const insuranceId = currentData?.assignedDocuments?.Insurance![0]?.documentId;
  if (insuranceId) await deleteFile([insuranceId]);
};

export const getAlertStatusType = (
  alertData: TractorAlertSummaryDTO | TrailerAlertSummaryDTO
) => {
  const days = alertData?.safetyIssueTypeWithDaysAndMiles?.days;
  const miles = alertData?.safetyIssueTypeWithDaysAndMiles?.miles;
  const isTractor = alertData?.hasOwnProperty('tractorId');
  const issueType = alertData?.safetyIssueTypeWithDaysAndMiles?.issueType;

  if (!issueType) {
    return;
  }
  if (
    issueType === 'WARNING' &&
    typeof days === 'number' &&
    typeof miles === 'number'
  ) {
    const message = isTractor
      ? `Due in ${days! >= miles! ? days : miles} ${
          days! >= miles! ? 'days' : 'miles'
        }`
      : `Due in ${days} days`;
    return {
      message: message,
      icon: <UpcomingIcon />,
    };
  }
  if (
    issueType === 'CRITICAL' &&
    typeof days === 'number' &&
    typeof miles === 'number'
  ) {
    const message = isTractor
      ? `Due ${days! >= miles! ? days : miles} ${
          days! >= miles! ? 'days' : 'miles'
        } ago`
      : `Due ${days} days ago`;
    return {
      message: message,
      icon: <PastDueIcon />,
    };
  }
  return { icon: <OkIcon /> };
};

export const updateStatusCount = (
  id: string,
  warningValue: number,
  criticalValue: number,
  isSum: boolean,
  data: TractorSummary[]
): TractorSummary[] => {
  return data?.map((item) => {
    //@TODO - Anush and Slavik should refactor this
    if (item.id !== id) return item;
    if (isSum) {
      item.critical = item.critical + criticalValue;
      item.warning = item.warning + warningValue;
      return item;
    }
    item.critical = criticalValue;
    item.warning = warningValue;
    return item;
  });
};
