import { Stack, Typography } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { toJS } from 'mobx';
import { RequiredFieldsHeader } from '../../../../../../components/contract/utils';
import { CarrierNumberFormatProps } from '../../../../../../subPages/carriers/constants/constants';
import { GridColDefSelf } from '../../../../../../types';
import { getFormattedDateForGrid } from '../../../../../../utils';
import { currencyDisplay } from '../../../../../../utils/grid.utils';
import { IFiltersFormConfig } from '../../../../../trips/constants/types';
import { hasPaymentEdit } from '../../../utils/payment.permission';
import {
  GridRenderCellEditable,
  GridRenderEditCurrencyField,
  GridRenderEditSeqNumberObserver,
} from './GridRenderEditCell';
import { InvoicePaymentActionsSection } from './InvoicePaymentActionsSection';
export interface IInvoicePaymentGridFilters {
  //
}
export const defaultFilters: IInvoicePaymentGridFilters =
  {} as unknown as IInvoicePaymentGridFilters;

interface GridProps {
  [key: string]: any;
}

export default class InvoicesPaymentGridConfig {
  config: {
    columns: GridColDefSelf[];
    getFiltersFormConfig: () => IFiltersFormConfig[];
    defFilters: IInvoicePaymentGridFilters;
  };
  constructor(props: GridProps) {
    this.config = InvoicesPaymentGridConfig.getConfiguration(props);
  }

  static getConfiguration(props: GridProps) {
    return InvoicesPaymentGridConfig.getDefaultConfiguration(props);
  }

  static getDefaultConfiguration() {
    return {
      columns: [
        {
          flex: 1,
          field: 'seqNumber',
          headerName: 'Invoice#',
          permanent: true,
          minWidth: 250,
          sortable: false,
          renderCell: (params) => {
            const prepend = [];
            if (params.row?.invoiceReferenceId) {
              prepend.push(`Ref#: ${params.row.invoiceReferenceId}`);
            }
            if (params.row.advance) {
              prepend.push('Advance');
            }
            return (
              <GridRenderCellEditable
                params={params}
                value={
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography
                      component={'a'}
                      variant="body2"
                      sx={{
                        textDecorationLine: 'underline',
                        color: 'primary.dark',
                      }}
                    >
                      {params.row.invoiceSeqNumber}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      {prepend.join(' | ')}
                    </Typography>
                  </Stack>
                }
              />
            );
          },
          editable: hasPaymentEdit(),
          renderEditCell: (params: GridRenderEditCellParams) => {
            return <GridRenderEditSeqNumberObserver params={params} />;
          },
          preProcessEditCellProps: (params: any) => {
            return { ...params.props, error: false };
          },
        },
        {
          flex: 1,
          field: 'invoiceDueDate',
          headerName: 'Due Date',
          minWidth: 100,
          sortable: false,
          renderCell: (params) => {
            return (
              <GridRenderCellEditable
                params={params}
                value={getFormattedDateForGrid(params.value, 'MM/DD/yyyy')}
              />
            );
          },
          editable: false,
        },
        {
          flex: 1,
          field: 'total',
          headerName: 'Original Amount',
          minWidth: 75,
          sortable: false,
          renderCell: (params) => {
            return (
              <GridRenderCellEditable
                params={params}
                value={currencyDisplay(params.value)}
              />
            );
          },
          editable: false,
        },
        {
          flex: 1,
          field: 'amountDue',
          headerName: 'Open Balance',
          minWidth: 100,
          sortable: false,
          renderCell: (params) => {
            return (
              <GridRenderCellEditable
                params={params}
                value={currencyDisplay(params.value)}
              />
            );
          },
          editable: false,
        },
        {
          flex: 1,
          field: 'amount',
          renderHeader: () => RequiredFieldsHeader('Payment'),
          minWidth: 125,
          sortable: false,
          renderCell: (params: GridRenderEditCellParams) => {
            const row = toJS(params.row);
            return (
              <GridRenderCellEditable
                params={params}
                value={currencyDisplay(params.value)}
                toolTips={
                  row?.isInvalid && row.amount > 0
                    ? 'Amount entered here will make the open balance negative'
                    : 'Payment is required'
                }
              />
            );
          },
          editable: hasPaymentEdit(),
          renderEditCell: (params: GridRenderEditCellParams) => {
            return (
              <GridRenderEditCurrencyField
                params={params}
                name={'amount'}
                numberFormatProps={CarrierNumberFormatProps}
              />
            );
          },
          cellClassName: (params: GridRenderEditCellParams) => {
            const row = toJS(params.row);
            if (row?.isInvalid || !row.amount) {
              return 'error';
            }
            return '';
          },
        },
        {
          flex: 1,
          field: 'action',
          headerName: '',
          minWidth: 50,
          maxWidth: 50,
          sortable: false,
          renderCell: (params) => {
            return hasPaymentEdit() ? (
              <InvoicePaymentActionsSection data={toJS(params.row)} />
            ) : (
              <></>
            );
          },
        },
      ],
      getFiltersFormConfig: (): IFiltersFormConfig[] => {
        const filtersConfigs: IFiltersFormConfig[] = [];
        return filtersConfigs.map((config, index) => {
          return {
            ...config,
            key: index + 1,
          };
        });
      },
      defFilters: defaultFilters,
    };
  }
}
