import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';
import {
  FolderType,
  EntityType,
  DocumentByEntity,
  DocumentItemType,
  CurrentSelectedItem,
  SubFolderType,
} from '../../../models';
import { LineItem } from '../../../types';
import uuid from '../../../utils/uuid';
import DocumentActions from './DocumentActions';
import { endorsementsMap, restrictionsMap } from './constants';

export const increaseFoldersCount = (
  folders: FolderType[],
  entityKey: string,
  increaseBy = 1
) => {
  return folders.map((entity) => {
    if (entity.entityType === entityKey) {
      entity.count = entity.count + increaseBy;
    }
    entity.subTypes.map((subType) => {
      if (subType.entityType === entityKey) {
        subType.count = subType.count + increaseBy;
        entity.count = entity.count + increaseBy;
      }
    });
    return entity;
  });
};

export const dicreaseFoldersCount = (
  folders: FolderType[],
  entityKey: string,
  folderTypes: { [key: string]: number }
) => {
  return folders.map((entity) => {
    if (folderTypes[entity.entityType]) {
      entity.count = entity.count - folderTypes[entity.entityType];
    }
    entity.subTypes.map((subType) => {
      if (folderTypes[subType.entityType]) {
        subType.count = subType.count - folderTypes[subType.entityType];
        entity.count = entity.count - folderTypes[subType.entityType];
      }
    });
    return entity;
  });
};

export const increaseEntitiesCount = (
  documentEntities: EntityType[],
  documentType: LineItem,
  filesCount = 1
) => {
  let documentTypesExists = false;
  const data = documentEntities.map((entity) => {
    if (entity.documentTypeId === documentType.id) {
      documentTypesExists = true;
      entity.count = entity.count + filesCount;
    }
    return entity;
  });
  if (!documentTypesExists) {
    data.push({ documentTypeId: documentType.id, count: filesCount });
  }
  return data;
};

export const dicreaseEntitiesCount = (
  documentEntities: EntityType[],
  documentTypeIds: string[]
) => {
  const documentTypesAndCounts = documentTypeIds.reduce(
    (finalObject, documentTypeId) => {
      if (!finalObject[documentTypeId]) {
        finalObject[documentTypeId] = 1;
      } else {
        finalObject[documentTypeId]++;
      }
      return finalObject;
    },
    {} as { [key: string]: number }
  );
  return documentEntities.map((entity) => {
    if (documentTypesAndCounts[entity.documentTypeId]) {
      entity.count =
        entity.count - documentTypesAndCounts[entity.documentTypeId];
    }
    return entity;
  });
};

export const dicreaseMidEntitiesCount = (
  visibleDocuments: DocumentByEntity[],
  selectedItemId: string,
  decreaseBy = 1
) => {
  return visibleDocuments
    .map((doc) => {
      if (doc.id === selectedItemId) {
        doc.count = doc.count - decreaseBy;
      }
      return doc;
    })
    .filter((doc) => !!doc.count);
};

export const getDocumentTypeById = (documentTypeId: any) => {
  const itemCode =
    DocumentActions.documentsLineItemsMap[documentTypeId]?.itemCode || '';
  return itemCode;
};
export const getFoldersdataByAssociatedtypes = (
  folders: FolderType[],
  newType: string,
  oldType: string
) => {
  return folders.map((folder) => {
    if (folder.entityType === newType) {
      folder.count++;
    }
    if (folder.entityType === oldType) {
      folder.count--;
    }
    if (folder.subTypes?.length) {
      folder.subTypes.map((subType) => {
        if (subType.entityType === newType) {
          subType.count++;
          folder.count++;
        }
      });
      folder.subTypes.map((subType) => {
        if (subType.entityType === oldType) {
          subType.count--;
          folder.count--;
        }
      });
    }
    return folder;
  });
};

export const getUpdatedTableData = (
  tableData: DocumentItemType[],
  id: string,
  name: string,
  value: string | object
) => {
  return tableData.map((data) => {
    if (data.id === id) {
      data[name] = value;
    }
    return data;
  });
};

export const getEntitiesByDocumentType = (
  documentEntities: EntityType[],
  documentLineItemsMap: { [key: string]: LineItem },
  oldId: string,
  newId: string
) => {
  let currentDocumentItem: LineItem;
  const updatedEntities = documentEntities.map((entity) => {
    if (entity.documentTypeId === oldId) {
      entity.count--;
    }
    if (entity.documentTypeId === newId) {
      currentDocumentItem = documentLineItemsMap[newId];
      entity.count++;
    }
    return entity;
  });
  if (!currentDocumentItem) {
    const newDocumentItem = documentLineItemsMap[newId];
    if (!newDocumentItem) {
      return;
    }
    currentDocumentItem = newDocumentItem;
    updatedEntities.push({
      documentTypeId: newDocumentItem.id,
      count: 1,
      key: uuid(),
    });
  }
  return {
    updatedEntities,
    currentDocumentItem,
  };
};

export const getVisibleItemsOnRemove = (
  visibleDocuments: DocumentByEntity[],
  currentSelectedItem: CurrentSelectedItem
) => {
  return visibleDocuments
    .map((doc) => {
      if (doc.id.toString() == currentSelectedItem.id) {
        doc.count--;
      }
      return doc;
    })
    .filter((doc) => !!doc.count);
};

export const getVisibleDocuments = (
  visibleDocuments: DocumentByEntity[],
  newId: string,
  oldId: string
) => {
  return visibleDocuments.map((doc) => {
    if (doc.id.toString() == newId) {
      doc.count++;
    }
    if (doc.id.toString() == oldId) {
      doc.count--;
    }
    return doc;
  });
};

export const getFolderName = (name: string, count: number) => {
  if (!name) return '';
  const nameRightPart = name.slice(1).toLocaleLowerCase();
  if (count > 1) {
    return `${name[0]}${nameRightPart}s`;
  }
  return name[0] + nameRightPart;
};

export const sortData = (
  folders: FolderType[] | EntityType[] | DocumentByEntity[] | SubFolderType[]
) => {
  if (!folders) {
    return [];
  }
  return [...folders].sort((a, b) => {
    if (a.count < b.count) {
      return 1;
    }
    if (a.count > b.count) {
      return -1;
    }
    return 0;
  });
};

export const getEndorsements = (data: any) => {
  const result: { key: string; value: string }[] = [];
  if (data.h_endorsement) {
    result.push({
      key: 'h_endorsement',
      value: endorsementsMap['h_endorsement'],
    });
  }
  if (data.n_endorsement) {
    result.push({
      key: 'n_endorsement',
      value: endorsementsMap['n_endorsement'],
    });
  }
  if (data.t_endorsement) {
    result.push({
      key: 't_endorsement',
      value: endorsementsMap['t_endorsement'],
    });
  }
  if (data.x_endorsement) {
    result.push({
      key: 'x_endorsement',
      value: endorsementsMap['x_endorsement'],
    });
  }
  return result;
};

export const getRestrictions = (data: any) => {
  const result: { key: string; value: string }[] = [];
  if (data.e_restriction) {
    result.push({
      key: 'e_restriction',
      value: restrictionsMap['e_restriction'],
    });
  }
  if (data.l_restriction) {
    result.push({
      key: 'l_restriction',
      value: restrictionsMap['l_restriction'],
    });
  }
  if (data.z_restriction) {
    result.push({
      key: 'z_restriction',
      value: restrictionsMap['z_restriction'],
    });
  }
  if (data.m_restriction) {
    result.push({
      key: 'm_restriction',
      value: restrictionsMap['m_restriction'],
    });
  }
  if (data.n_restriction) {
    result.push({
      key: 'n_restriction',
      value: restrictionsMap['n_restriction'],
    });
  }
  if (data.o_restriction) {
    result.push({
      key: 'o_restriction',
      value: restrictionsMap['o_restriction'],
    });
  }
  if (data.v_restriction) {
    result.push({
      key: 'v_restriction',
      value: restrictionsMap['v_restriction'],
    });
  }
  return result;
};

export const getDriverLicenseRequestParams = (data: any) => {
  const currentLicense = data.currentLicense;
  const existingEndorsements = data.driverLicense.endorsements.map(
    (e) => e.key
  );
  const existingRestrictions = data.driverLicense.restrictions.map(
    (e) => e.key
  );
  currentLicense.licenseState = data.driverLicense.licenseState;
  currentLicense.category = data.driverLicense.category;
  currentLicense.validTillDate = data.driverLicense.validTo;
  currentLicense.h_endorsement = Number(
    existingEndorsements.includes('h_endorsement')
  );
  currentLicense.n_endorsement = Number(
    existingEndorsements.includes('n_endorsement')
  );
  currentLicense.t_endorsement = Number(
    existingEndorsements.includes('t_endorsement')
  );
  currentLicense.x_endorsement = Number(
    existingEndorsements.includes('x_endorsement')
  );
  currentLicense.e_restriction = Number(
    existingRestrictions.includes('e_restriction')
  );
  currentLicense.l_restriction = Number(
    existingRestrictions.includes('l_restriction')
  );
  currentLicense.z_restriction = Number(
    existingRestrictions.includes('z_restriction')
  );
  currentLicense.m_restriction = Number(
    existingRestrictions.includes('m_restriction')
  );
  currentLicense.n_restriction = Number(
    existingRestrictions.includes('n_restriction')
  );
  currentLicense.o_restriction = Number(
    existingRestrictions.includes('o_restriction')
  );
  currentLicense.v_restriction = Number(
    existingRestrictions.includes('v_restriction')
  );
  return currentLicense;
};
