import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Accordion from '../../../../common/Accordion';
import { ExpenseCategory } from '../../../../models/DTOs/PaymentTerms';
import {
  AddIcon,
  DeleteButtonIcon,
} from '../../../../ui-kit/components/Assets';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import ThreeDotMenuIcon from '../../../../ui-kit/components/ThreeDotMenuIcon';
import FormContext from '../PaymentTermForm/FormContext';
import AddScheduledPayment from './AddScheduledPayment';

import { EditOutlined } from '@mui/icons-material';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { Permission } from '../../../../common/Permission';
import { amountFormat } from '../../../../utils';
import FormContextTractor from '../../../equipment/TractorPaymentTerm/TractorPaymentTermFormContext';
import { EScheduleType } from '../../constants';
import {
  deleteDataScheduledPayment,
  setEditValues,
} from './HelperScheduleConfig';
import { PaymentSchedule } from './ScheduledPaymentConstant';

interface AddNewItemHandlerCallback {
  (): void;
}

interface UpdatedForm {
  (): void;
}

const sectionSummaryRenderer = (
  sectionName: string,
  addNewItemHandler: AddNewItemHandlerCallback,
  type: string,
  total: number,
  explength: number,
  permission: any,
  inactive?: boolean
) => {
  return (
    <ItemsOfSectionSummary
      addClickHandler={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        addNewItemHandler();
      }}
      sectionName={sectionName}
      type={type}
      total={total}
      explength={explength}
      permission={permission}
      inactive={inactive}
    />
  );
};
export const ItemsOfSectionSummary = ({
  addClickHandler,
  sectionName,
  type,
  total,
  explength,
  permission,
  inactive,
}: {
  addClickHandler: AddNewItemHandlerCallback;
  sectionName: any;
  type: string;
  total: number;
  explength: number;
  permission: any;
  inactive?: boolean;
}) => {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      spacing={0}
      sx={{ minWidth: '410px' }}
    >
      <Grid
        item
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          {sectionName}
        </Typography>
        <Permission includes={[permission?.add]}>
          <>
            {explength > 0 && !inactive && (
              <Button
                onClick={addClickHandler}
                variant="outlined"
                style={{ minWidth: 35, marginLeft: 10 }}
              >
                <AddIcon />
              </Button>
            )}
          </>
        </Permission>
      </Grid>
      <Grid item>
        <Grid container direction={'column'} alignItems={'flex-end'}>
          <Grid item>
            <Typography variant="h6" sx={{ color: 'primary.main' }}>
              {total}
            </Typography>
          </Grid>

          <Grid item>
            {/* @ISSUE: Vasil */}
            <Typography sx={{ color: 'text.secondary', fontSize: '10px' }}>
              Total {type}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const findItemFromList = (
  list: ExpenseCategory[],
  label: string,
  key: string
) => {
  const result = list.find((item) => item?.[label] === key);
  return result;
};
const PaymentPerLoadItem = ({
  data,
  configJson,
  type,
  Updated,
  sectionName,
  permission,
  inactive,
}: {
  data: PaymentSchedule;
  configJson: any;
  type: string;
  Updated: UpdatedForm;
  sectionName: string;
  permission: any;
  inactive?: boolean;
}) => {
  const [cardExpanded, setCardExpanded] = useState(true);

  const { setValue } = useFormContext();

  const { expenseCategories, scheduledPayment, scheduledDeduction } =
    React.useContext(
      sectionName === 'driver' ? FormContext : FormContextTractor
    );

  const { id, value, templateSchedulerSummaryDTO, expenseCategoryId } = data;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteItem, setDeleteItem] = useState<boolean>(false);
  // const [selectEditData, setSelectEditData] = useState<any>();
  const [isUpdate, setUpdate] = useState<boolean>(false);
  let selectEditData: any = {};
  const InformationBox = {
    backgroundColor: 'uncategorized.oldHeaderSecondary',
    borderRadius: '8px',
    margin: '0px 0px 0px 12px',
    padding: '15px',
    mb: '15px',
  };

  const InformationBoxForDescription = {
    backgroundColor: 'uncategorized.oldHeaderSecondary',
    borderRadius: '8px',
    margin: '15px 0px 0px 12px',
    padding: '15px',
    mb: '10px',
  };

  const InformationBoxHeading = {
    fontWeight: 400,
    fontSize: '16px',
    color: 'primary.main',
  };
  const InformationBoxhelpText = {
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '14px',
    color: '#6B92DB',
  };

  const InformationExpiredDatehelpText = {
    fontWeight: 400,
    fontsize: '14px',
    color: 'error.main',
    letterSpacing: '0.5px',
  };

  const InformationDatehelpText = {
    fontWeight: 400,
    fontsize: '14px',
    color: ' rgba(0, 17, 34, 0.6)',
  };
  const InformationDateText = {
    fontWeight: 500,
    fontsize: '14px',
    color: ' rgba(0, 17, 34, 0.75)',
  };
  const InformationDateTextNegative = {
    fontWeight: 400,
    fontsize: '12px',
    color: 'error.main',
  };
  const cardName = findItemFromList(expenseCategories, 'id', expenseCategoryId);
  const NEXT_SCHEDULED = findItemFromList(
    templateSchedulerSummaryDTO?.schedulerEventTrails
      ? templateSchedulerSummaryDTO.schedulerEventTrails
      : [],
    'runStatus',
    'NEXT_SCHEDULED'
  );
  const FAILED = findItemFromList(
    templateSchedulerSummaryDTO?.schedulerEventTrails
      ? templateSchedulerSummaryDTO.schedulerEventTrails
      : [],
    'runStatus',
    'FAILED'
  );
  const COMPLETED = findItemFromList(
    templateSchedulerSummaryDTO?.schedulerEventTrails
      ? templateSchedulerSummaryDTO.schedulerEventTrails
      : [],
    'runStatus',
    'COMPLETED'
  );
  const handleDeletePaymentTerm = () => {
    setDeleteItem(true);
  };

  const handleMenuItemClick = (action: {
    icon: string;
    label: string;
    id: number;
    paymentTypeDisplayName: string;
    operationModeDisplayName: string;
  }) => {
    if (action?.label === 'Delete') {
      handleDeletePaymentTerm();
      setAnchorEl(null);
    } else if (action?.label === 'Edit') {
      setUpdate(true);
      setAnchorEl(null);
    }
  };
  const deleteRecurrence = async () => {
    const payloadRequest = await deleteDataScheduledPayment(
      data.id,
      configJson.deleteAPI
    );
    setDeleteItem(false);
    if (payloadRequest) {
      if (EScheduleType.Payment === type) {
        const filteredItems = scheduledPayment.findIndex(
          (paymentTerm) => paymentTerm.id === data.id
        );
        setValue('scheduledPayment', scheduledPayment.splice(filteredItems, 1));
      } else if (EScheduleType.Deduction === type) {
        const filteredItems = scheduledDeduction.findIndex(
          (paymentTerm) => paymentTerm.id === data.id
        );
        setValue(
          'scheduledDeduction',
          scheduledDeduction.splice(filteredItems, 1)
        );
      }
      Updated();
    }
  };
  if (data.templateSchedulerSummaryDTO) {
    selectEditData = setEditValues(data, configJson.typeId);
    selectEditData.expenseType = cardName;
    selectEditData.amount = Math.abs(selectEditData.amount);
    selectEditData.description = data.description;
  }
  const titleRenderer = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: (theme) => {
            return cardExpanded
              ? `${theme.palette.primary.contrast}`
              : `${theme.palette.grey['100']}`;
          },
        }}
      >
        <div>
          <Typography
            variant="h7"
            sx={{
              color: cardExpanded ? 'primary.main' : 'text.primary',
            }}
          >
            {cardName?.itemName ? cardName.itemName : ''}
          </Typography>
        </div>
        <Box
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '-16px',
          }}
        >
          <Typography
            variant="h7"
            sx={{
              color:
                EScheduleType.Deduction != type ? 'primary.main' : 'error.main',
              fontSize: '24px',
            }}
          >
            {EScheduleType.Deduction === type && '- '}{' '}
            {amountFormat(Math.abs(value))}
          </Typography>
          <Permission includes={[permission?.remove, permission?.edit]}>
            <>
              {!inactive && (
                <ThreeDotMenuIcon
                  menuOptions={[
                    {
                      label: 'Delete',
                      icon: DeleteButtonIcon,
                    },
                    {
                      label: 'Edit',
                      icon: EditOutlined,
                    },
                  ]}
                  open={Boolean(anchorEl)}
                  handleMenuClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                  }}
                  handleMenuClose={() => {
                    setAnchorEl(null);
                  }}
                  anchorEl={anchorEl}
                  handleMenuItemClick={handleMenuItemClick}
                />
              )}
            </>
          </Permission>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        border: (theme) => {
          return cardExpanded ? `1px solid ${theme.palette.primary.main}` : '';
        },
        borderRadius: '8px',
        padding: '5px 5px',
        marginTop: '20px',
        backgroundColor: (theme) => {
          return cardExpanded
            ? `${theme.palette.primary.contrast}`
            : `${theme.palette.grey['100']}`;
        },
        width: '100%',
      }}
    >
      <Accordion
        key={`accordion: ${id}`}
        name={`${data.payType}`}
        summaryRenderer={titleRenderer}
        updateParentState={setCardExpanded}
      >
        {templateSchedulerSummaryDTO && (
          <Grid sx={{ width: '100%' }}>
            <Box sx={InformationBox}>
              <Typography sx={InformationBoxHeading}>
                {templateSchedulerSummaryDTO?.schedulerOccurrenceInfo
                  ? templateSchedulerSummaryDTO.schedulerOccurrenceInfo
                  : ''}
              </Typography>
              <Typography sx={InformationBoxhelpText}>
                {templateSchedulerSummaryDTO?.schedulerEffDatesInfo
                  ? templateSchedulerSummaryDTO.schedulerEffDatesInfo
                  : ''}
              </Typography>
            </Box>
            {data?.description?.trim() && (
              <Box sx={InformationBoxForDescription}>
                <Typography sx={InformationBoxHeading}>Description</Typography>
                <Typography sx={InformationBoxhelpText}>
                  {data?.description}
                </Typography>
              </Box>
            )}
            <Box sx={{ display: 'inherit', p: '2px 2px 0px 15px' }}>
              <Box sx={{ display: 'inline-flex' }}>
                <Typography sx={InformationDatehelpText}>Last Run:</Typography>
                <Typography sx={InformationDateText}>
                  {COMPLETED || FAILED
                    ? ` ${
                        COMPLETED?.scheduledEventDate
                          ? moment(COMPLETED.scheduledEventDate).format(
                              'MM/DD/YYYY'
                            )
                          : moment(FAILED.scheduledEventDate).format(
                              'MM/DD/YYYY'
                            )
                      }`
                    : ' --'}
                </Typography>
                {FAILED && (
                  <Typography sx={InformationDateTextNegative}>
                    (Failed)
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: 'inline-flex', float: 'right' }}>
                <Typography sx={InformationDatehelpText}>Next Due:</Typography>
                <Typography sx={InformationDateText}>
                  {NEXT_SCHEDULED?.scheduledEventDate ? (
                    moment(NEXT_SCHEDULED.scheduledEventDate).format(
                      'MM/DD/YYYY'
                    )
                  ) : (
                    <Typography sx={InformationExpiredDatehelpText}>
                      Expired
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
      </Accordion>

      {isDeleteItem && (
        <DeletePopup
          open={isDeleteItem}
          onClose={() => setDeleteItem(false)}
          onAction={() => deleteRecurrence()}
          title={'Delete Scheduled Payment Terms'}
          subtitle={`Are you sure you want to delete  ${
            cardName?.itemName ? cardName.itemName : ''
          } ?`}
        />
      )}

      {isUpdate && (
        <AddScheduledPayment
          isOpen={isUpdate}
          expenseCategoriesDefaultValue={cardName}
          expenseCategoriesList={expenseCategories}
          titleText={'Edit Scheduled ' + type}
          intialData={data}
          type={type}
          closeModel={() => {
            setUpdate(false);
          }}
          createdForm={() => {
            setUpdate(false);
            Updated();
          }}
          id={id}
          modeType={'Save'}
          editDataParams={selectEditData}
          configJson={configJson}
          sectionName={sectionName}
        />
      )}
    </Box>
  );
};

const ScheduledPaymentItem = (
  sectionName: string,
  savedPaymentTerms: PaymentSchedule[],
  type: string,
  id: number,
  configJson: any,
  permission: any,
  inactive?: boolean
) => {
  const { setValue } = useFormContext();
  const { expenseCategories, scheduledPayment, scheduledDeduction } =
    React.useContext(
      sectionName === 'driver' ? FormContext : FormContextTractor
    );
  const [showAddNewItemDialog, setShowAddNewItemDialog] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [savedPaymentTermsList, setSavedPaymentTerms] =
    useState(savedPaymentTerms);
  const addNewItemHandlerCallback = useCallback(() => {
    setShowAddNewItemDialog(true);
  }, [showAddNewItemDialog]);
  const foundExpenseCategoriesType = expenseCategories.find(
    (payType) => payType.itemCode === 'DRIVER_PAYMENT'
  );
  useEffect(() => {
    setSavedPaymentTerms(savedPaymentTerms);
  }, [savedPaymentTerms, setValue, isUpdated]);

  const updatedListRecord = () => {
    if (EScheduleType.Payment === type) {
      setValue('scheduledPayment', scheduledPayment);
      setSavedPaymentTerms(scheduledPayment);
    } else if (EScheduleType.Deduction === type) {
      setValue('scheduledDeduction', scheduledDeduction);
      setSavedPaymentTerms(scheduledDeduction);
    }

    setUpdated(!isUpdated);
  };
  let components: JSX.Element[] = [];
  if (savedPaymentTermsList.length) {
    components = savedPaymentTermsList.map((paymentPerLoad) => {
      return (
        <PaymentPerLoadItem
          key={paymentPerLoad.id}
          data={paymentPerLoad}
          type={type}
          Updated={updatedListRecord}
          configJson={configJson}
          sectionName={sectionName}
          permission={permission}
          inactive={inactive}
        />
      );
    });
  }
  return (
    <>
      <Accordion
        summaryRenderer={() =>
          sectionSummaryRenderer(
            'Scheduled ' + type,
            addNewItemHandlerCallback,
            type,
            savedPaymentTermsList.length,
            expenseCategories.length,
            permission,
            inactive
          )
        }
        expanded
      >
        {savedPaymentTermsList.length ? components.map((item) => item) : null}
      </Accordion>
      {showAddNewItemDialog && foundExpenseCategoriesType && (
        <AddScheduledPayment
          isOpen={showAddNewItemDialog}
          expenseCategoriesDefaultValue={foundExpenseCategoriesType}
          expenseCategoriesList={expenseCategories}
          titleText={'Add Scheduled ' + type}
          type={type}
          closeModel={() => {
            setShowAddNewItemDialog(false);
          }}
          createdForm={() => {
            setShowAddNewItemDialog(false);
          }}
          id={id}
          configJson={configJson}
          sectionName={sectionName}
        />
      )}
    </>
  );
};

export default ScheduledPaymentItem;
