import { action, makeObservable, observable } from 'mobx';
import { View } from '../../types';
import {
  DEFAULT_CONTRACT_VIEWS,
  TABS_CONSTANT,
} from '../../constants/contracts';
import { httpClient } from '../../axios/axiosInstance';
import {
  contractListUrl,
  financialItemURL,
} from '../../api/impl/requestConstants';
import {
  GetInvoiceLineItemsRequest,
  PaginatedLoadListQueryParams,
  QueryParams,
} from '../../models';
import { TSetData, setData } from '../../store/utils';
import * as types from '../../types';
import { formatContractFilters } from '../../utils/contract_payload_formatter';
import { invoiceService } from '../../api';
const component = 'Contracts';
const dictionary = 'Contracts';
class ContractStore {
  @observable component: string = component;
  @observable dictionary: string = dictionary;
  @observable currentView: View = DEFAULT_CONTRACT_VIEWS[0];
  @observable financeDictionary: any = [];
  @observable tableData = [];
  @observable filters: types.ViewMetaData = DEFAULT_CONTRACT_VIEWS;
  @observable pageNumber = 1;
  @observable last = false;
  @observable pageSize = 100;
  @observable equipmentType = [];
  @observable sort = '-createdDate';
  constructor() {
    makeObservable(this);
  }

  @action.bound
  setCurrentView(view: View) {
    if (view) {
      this.currentView = view;
      this.filters = setData(view.metaData, this.filters);
    }
  }

  @action.bound
  fetchEquipmentTypes = async () => {
    const EquipmentTypeUrl = 'web/load/api/v2/loads/static/equipment-type';
    try {
      const response = await httpClient.get(
        EquipmentTypeUrl,
        new QueryParams(),
        null,
        true,
        true
      );
      this.equipmentType = response?.loadEquipmentType;
      return response;
    } catch (e) {
      throw e;
    }
  };

  @action
  setFilters = (newValue: TSetData<types.ViewMetaData>) => {
    this.filters = setData(newValue, this.filters);
  };

  @action
  setSorting = (newValue: string) => {
    this.sort = setData(newValue, this.sort);
  };

  @action.bound
  fetchFinanceItems = async () => {
    try {
      // const response = await httpClient.get(
      //   financialItemURL,
      //   new QueryParams(),
      //   null,
      //   true,
      //   true
      // );
      const request = new GetInvoiceLineItemsRequest();
      request.isInvoiceItem = true;
      const response = (await invoiceService.getInvoiceLineItems(
        request
      )) as Array<any>;
      this.financeDictionary = response;
      return response;
    } catch (e) {
      throw e;
    }
  };

  @action.bound
  fetchContractList = async (pageNumber: number) => {
    const queryParams = new PaginatedLoadListQueryParams();
    queryParams.pageNumber = pageNumber;
    queryParams.pageSize = this.pageSize;
    queryParams.sort = this.sort;
    try {
      const response: any = await httpClient.put(
        `${contractListUrl}`,
        queryParams,
        formatContractFilters(this.filters),
        false
      );
      if (pageNumber > 1 && response) {
        this.tableData = {
          ...response,
          content: [...this.tableData?.content, ...response.content],
        };
        this.pageNumber = response?.number || 1;
      } else {
        this.tableData = response;
      }
      this.last = response.last;
      return response;
    } catch (e) {
      throw e;
    }
  };
}

export function createContractStore() {
  return new ContractStore();
}
