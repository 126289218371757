import { useFormContext } from 'react-hook-form';
import TextField from '../../../../../common/Ui/TextField/TextField';
import { FINANCE_NAME } from '../../constants';
import SettingBlock from '../Blocks/SettingBlock';
import { SettingBlockItem } from '../Blocks/SettingBlockItem';

function TargetRevenuePerMile() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Target Revenue per Mile"
      subTitle="How much revenue would you like to make per mile? This is reflected when doing profit calculations."
    >
      <TextField
        control={control}
        name="dashboardPreferences.targetRevenuePerTotalMile"
        label="Revenue per Mile"
        required
        onlyNumbers
      />
    </SettingBlockItem>
  );
}

function TargetRevenuePerLoadedMile() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Target Revenue per Loaded Mile"
      subTitle="How much revenue would you like to make per loaded mile? We use this to measure if you are reaching your goals."
    >
      <TextField
        control={control}
        name="dashboardPreferences.targetRevenuePerLoadedMile"
        label="Revenue per Loaded Mile"
        required
        onlyNumbers
      />
    </SettingBlockItem>
  );
}

function TargetWeeklyRevenue() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Target Weekly Company Revenue"
      subTitle="How much revenue would you like to make per week? We also use this to figure out the Target Revenue per Driver."
    >
      <TextField
        control={control}
        name="dashboardPreferences.targetWeeklyRevenue"
        label="Weekly Revenue"
        required
        onlyNumbers
      />
    </SettingBlockItem>
  );
}

function VariableCostPerMile() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Variable Cost per Mile"
      subTitle="How much do you typically spend per mile? We use this to calculate $/day."
    >
      <TextField
        control={control}
        name="variableCostPerMile"
        label="Variable Cost per Mile"
        required
        onlyNumbers
      />
    </SettingBlockItem>
  );
}

function AverageDailyCostPerTruck() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Average Daily Cost per Truck "
      subTitle="How much do you typically spend per day? We use this to calculate $/day."
    >
      <TextField
        control={control}
        name="fixedCostPerDay"
        label="Daily Cost per Truck"
        required
        onlyNumbers
      />
    </SettingBlockItem>
  );
}

export default function Finance() {
  return (
    <section id={FINANCE_NAME} className="section">
      <SettingBlock
        title="Financial Goals"
        subTitle="Use this section to update the Financial components of the Dashboard. Each component helps you visualize if you are reaching your weekly goals."
      >
        <TargetRevenuePerMile />
        <TargetRevenuePerLoadedMile />
        <TargetWeeklyRevenue />
        <VariableCostPerMile />
        <AverageDailyCostPerTruck />
      </SettingBlock>
    </section>
  );
}
