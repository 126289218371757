import { Autocomplete, Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ButtonImproved from '../../ButtonImproved';
import Datepicker from '../../Datepicker';
import TextInput from '../../TextField';
import { makeAsteriskRed } from '../../Utils';
import {
  buttonNames,
  checkboxNames,
  endAfterText,
  endRadio,
  monthlyRenderText,
  noEndText,
  radioNames,
  rangeConstText,
  recurseConstText,
  validationText,
  weeklyRenderText,
} from '../constants/constants';
import { t } from 'i18next';
export const RecurrenceContent = ({
  data = {},
  onChangeRadio,
  onChangeCheckbox,
  dialog,
  onSubmit,
  onCloseDialog,
  error = {},
  handleDateChange,
  onDelete,
  endDay = null,
  mode,
  entityType,
  showDelete,
  isInitialCreate,
  expenseCategoriesList,
  expenseCategoriesDefaultValue,
  handleChangeDropDown,
  erro,
  disabledManualInput,
  today,
}: any) => {
  const styles = {
    radio: {
      color: 'text.primary',
      '&.Mui-checked': {
        color: 'text.primary',
      },
    },
    checkbox: {
      color: 'text.primary',
      '&.Mui-checked': {
        color: 'text.primary',
      },
    },
    radioGroup: {
      color: 'text.primary',
    },
    checkBoxGroup: {
      color: 'text.primary',
      paddingTop: 2,
    },
    divider: {
      marginTop: 4,
      marginBottom: 4,
      width: dialog ? 500 : '95%',
      borderBottomWidth: 1,
      borderBottomColor: 'text.primary',
    },
    renderEnd: {
      color: 'text.primary',
      marginTop: 4,
    },
    mainContainer: {
      backgroundColor: 'transparent',
      padding: dialog ? 0 : 3,
    },
    actionButtonWrapper: {
      display: 'flex',
      justifyContent: 'end',
      gap: 1,
      width: '100%',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 1,
      width: dialog ? 500 : '95%',
      marginTop: 3,
    },
  };
  const renderWeekly = () => (
    <>
      <Stack direction="row" spacing={1} sx={{ paddingTop: 2 }}>
        <Typography
          color={'text.primary'}
          align="left"
          variant="subtitle1"
          fontWeight={400}
        >
          {weeklyRenderText.intial}
        </Typography>
        <TextInput
          label={''}
          variant="standard"
          error={!!error.weeklyRecurs}
          defaultValue={data.weeklyRecurs}
          styleProps={{ width: 40 }}
          onChange={(e: any) => {
            onChangeRadio(e, 'weeklyRecurs');
          }}
          type="number"
        />
        <Typography
          color={'text.primary'}
          align="left"
          variant="subtitle1"
          fontWeight={400}
        >
          {weeklyRenderText.end}
        </Typography>
      </Stack>
      {error.weeklyRecurs && (
        <Typography
          color={'error.main'}
          align="left"
          variant="subtitle1"
          fontWeight={400}
        >
          {error.weeklyRecurs}
        </Typography>
      )}
      <FormGroup sx={styles.checkBoxGroup}>
        <Box width={'100%'}>
          {checkboxNames.map((item: any) => (
            <FormControlLabel
              control={
                <Checkbox
                  sx={styles.checkbox}
                  checked={data.weekDays.includes(item.value)}
                  onChange={onChangeCheckbox}
                  name={item.value}
                />
              }
              label={item.label}
              key={item.value}
            />
          ))}
        </Box>
      </FormGroup>
      {error.weekDays && (
        <Typography
          color={'error.main'}
          align="left"
          variant="subtitle1"
          fontWeight={400}
        >
          {validationText}
        </Typography>
      )}
      <Divider sx={styles.divider} />
    </>
  );

  const renderMonthly = () => (
    <>
      <Typography
        color={'text.primary'}
        align="left"
        variant="subtitle1"
        fontWeight={600}
      >
        {recurseConstText}
      </Typography>
      <Stack direction="row" spacing={1} sx={{ paddingTop: 2 }}>
        <Typography
          color={'text.primary'}
          align="left"
          variant="subtitle1"
          fontWeight={400}
        >
          {monthlyRenderText.intial}
        </Typography>
        <TextInput
          label={''}
          variant="standard"
          error={!!error.monthlyRecurs}
          defaultValue={data.monthlyRecurs}
          styleProps={{ width: 40 }}
          onChange={(e: any) => {
            onChangeRadio(e, 'monthlyRecurs');
          }}
          type="number"
        />
        <Typography
          color={'text.primary'}
          align="left"
          variant="subtitle1"
          fontWeight={400}
        >
          {monthlyRenderText.end}
        </Typography>
      </Stack>
      {error.monthlyRecurs && (
        <Typography
          color={'error.main'}
          align="left"
          variant="subtitle1"
          fontWeight={400}
        >
          {error.monthlyRecurs}
        </Typography>
      )}
      <Divider sx={styles.divider} />
    </>
  );

  const renderEndBy = () => (
    <Datepicker
      label="End By"
      variant="standard"
      id="endByDate"
      onDateChange={handleDateChange}
      error={!!error.endByDate}
      helperText={error.endByDate || ''}
      required
      styleProps={{ width: 220 }}
      initialDate={data.endByDate || ''}
      minDate={today}
      maxDate={endDay}
      disabledManualInput={disabledManualInput}
    />
  );

  const renderEndAfter = () => (
    <Stack direction="row" spacing={1} sx={{ paddingTop: 2 }}>
      <Typography
        color={'text.primary'}
        align="left"
        variant="subtitle1"
        fontWeight={400}
      >
        {endAfterText.intial}
      </Typography>
      <TextInput
        label={''}
        variant="standard"
        // InputProps={{ style: { color: 'white' } }}
        error={!!error.endAfterField}
        helperText={error.endAfterField || ''}
        defaultValue={data.endAfterField}
        styleProps={{ width: 40 }}
        onChange={(e: any) => {
          onChangeRadio(e, 'endAfterField');
        }}
        type="number"
      />
      <Typography
        color={'text.primary'}
        align="left"
        variant="subtitle1"
        fontWeight={400}
      >
        {endAfterText.end}
      </Typography>
    </Stack>
  );

  const renderNoEnd = () => (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography
        color={'text.primary'}
        align="center"
        variant="subtitle1"
        fontWeight={400}
      >
        {noEndText}
      </Typography>
    </Box>
  );

  const renderEnd = () => (
    <RadioGroup
      name="end"
      value={data.end}
      onChange={(e) => {
        onChangeRadio(e, 'end');
      }}
      sx={styles.renderEnd}
    >
      <Stack direction="column" spacing={2}>
        {endRadio.map((item: any) => (
          <Stack direction="row">
            <FormControlLabel
              value={item.value}
              control={<Radio sx={styles.radio} />}
              label={''}
            />
            {item.value == 'endBy' && renderEndBy()}
            {/* {item.value == 'endAfter' && renderEndAfter()}
                        {item.value == 'noEnd' && renderNoEnd()} */}
          </Stack>
        ))}
      </Stack>
    </RadioGroup>
  );

  const renderActionButtons = () => (
    <Box sx={styles.buttonWrapper}>
      {mode == 'edit' && showDelete ? (
        <ButtonImproved
          variant="outlined"
          label={buttonNames.delete}
          onClick={onDelete}
          color={'error'}
        />
      ) : null}
      <Box sx={styles.actionButtonWrapper}>
        {dialog ? (
          <ButtonImproved
            variant="outlined"
            onClick={onCloseDialog}
            label={buttonNames.cancel}
          />
        ) : null}
        <ButtonImproved
          variant="contained"
          label={mode === 'create' ? buttonNames.add : buttonNames.edit}
          onClick={onSubmit}
          styleProps={{
            minWidth: '90px',
          }}
        />
      </Box>
    </Box>
  );

  const renderRange = () => (
    <>
      <Typography
        color={'text.primary'}
        align="left"
        variant="subtitle1"
        fontWeight={600}
      >
        {rangeConstText}
      </Typography>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        mt={2}
        width={dialog ? 500 : '95%'}
      >
        <Datepicker
          label="Start Recurrence On"
          variant="standard"
          id="rangeDate"
          onDateChange={handleDateChange}
          error={!!error.rangeDate}
          helperText={error.rangeDate || ''}
          required
          initialDate={data.rangeDate || ''}
          styleProps={{ width: 220 }}
          minDate={today}
          maxDate={endDay}
          disabledManualInput={disabledManualInput}
        />
        {renderEndBy()}
      </Stack>
    </>
  );

  const renderDescription = () => (
    <Grid sx={{ marginTop: '10px' }}>
      <TextInput
        fullWidth
        onChange={(e: any) => {
          onChangeRadio(e, 'description');
        }}
        variant="standard"
        label="Description"
        value={data.description}
        error={!!error.description}
        helperText={
          error.description ? 'Description must be at most 255 characters.' : ''
        }
      />
    </Grid>
  );

  const renderMainRadio = () => (
    <>
      <RadioGroup
        row
        name="main"
        value={data.main}
        onChange={(e) => {
          onChangeRadio(e, 'main');
        }}
        sx={styles.radioGroup}
      >
        <Stack direction="row" spacing={2}>
          {radioNames.map((item: any) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio sx={styles.radio} />}
              label={item.label}
            />
          ))}
        </Stack>
      </RadioGroup>
      <Divider sx={styles.divider} />
    </>
  );
  const renderIntialForm = () => (
    <>
      <Grid container spacing={4}>
        <Grid container item xs={6} sx={{ mb: '15px', pt: '25px' }}>
          <Autocomplete
            sx={{ pr: 2, mt: '-5px' }}
            color="primary"
            options={expenseCategoriesList}
            value={data.expenseType || expenseCategoriesDefaultValue}
            onChange={(event, value) => handleChangeDropDown(value)}
            getOptionLabel={(option: { itemName: any }) =>
              option.itemName || 'Select Expense Category'
            }
            disableClearable
            style={{ width: '100%', color: 'black' }}
            renderInput={(params: any) => (
              <TextField
                margin="dense"
                SelectProps={{
                  native: true,
                }}
                sx={makeAsteriskRed}
                size="small"
                color="primary"
                {...params}
                label={t('expenseCategory')}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  type: 'Search',
                }}
                required
              />
            )}
          />
        </Grid>
        <Grid container item xs={6}>
          <TextInput
            fullWidth
            autoComplete="off"
            onChange={(e: any) => {
              onChangeRadio(e, 'amount');
            }}
            variant="standard"
            label={'Amount'}
            value={data.amount}
            type="number"
            error={!!error.amount}
            helperText={
              error.amount ? 'Value must be between 0.0001 and 99,999.9999' : ''
            }
            required
          />
        </Grid>
      </Grid>
    </>
  );
  return (
    <Box sx={styles.mainContainer}>
      {isInitialCreate && renderIntialForm()}
      {renderMainRadio()}
      {data.main == 'weekly' && renderWeekly()}
      {data.main == 'monthly' && renderMonthly()}
      {renderRange()}
      {renderDescription()}
      {/* {renderEnd()} */}
      {renderActionButtons()}
    </Box>
  );
};
