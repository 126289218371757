import { Theme } from '@mui/material';
import { BREAKPOINT_TABLET } from '../../../../../hooks/useThemeResponsive';

export const getTripAssignmentComponentStyles = (theme: Theme) => {
  return {
    root: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      p: 1,
      pt: 0,
      mt: -1,
      backgroundColor: 'uncategorized.accordion',
    },
    btnWrapStyle: {
      p: 1,
      width: '50%',
      [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
        width: '100%',
      },
    },
    btnCarrierWrapStyle: {
      p: 1,
      width: '100%',
      [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
        width: '100%',
      },
    },
    assignmentWrapperStyle: (left?: string) => ({
      // left,
      position: 'relative',
      width: '448px',
      [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
        left: 0,
        width: '100%',
      },
    }),
    btnStyle: {
      width: '100%',
      whiteSpace: 'nowrap',
    },
    AssignmentFormRoot: {
      width: '100%',
      p: 1,
    },
    AssignmentFormItem: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    TextInputWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    TextInput: {
      width: '100%',
      mr: 1,
      '.MuiInput-root': {
        mt: 1.2,
      },
    },
    TextInputStartAdornment: {
      mr: 1,
    },
    IconBtnWrapper: {
      height: 36,
      minWidth: 36,
    },
  };
};
