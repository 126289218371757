import AddIcon from '@mui/icons-material/Add';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TripV3DataProps } from '../../TripPlanV3';
import {
  StyleFloatingActionAddButton,
  StyleFloatingActionAddButtonText,
  StyleFloatingActionButton,
  StyleFloatingActionRoot,
} from '../../TripPlanV3/styles';
import {
  ExpandableTimelineV3AddStopEvents,
  ExpandableTimelineV3ResequenceStopEvents,
  StopSolutionV3Prop,
} from '../../TripPlanV3/types';
import { classNamesTimeline } from './constants';

export interface FloatingActionButtonSectionProps
  extends ExpandableTimelineV3AddStopEvents,
    ExpandableTimelineV3ResequenceStopEvents {
  expanded?: boolean;
  showFloatingTruckIcon?: boolean;
  stopSolution: StopSolutionV3Prop;
  data: TripV3DataProps;
  hideAddLoadAction?: boolean;
  hideAddRelayAction?: boolean;
  isTripStop?: boolean;
}

export const FloatingActionButtonSection = ({
  expanded,
  stopSolution,
  onClickAddStopHandler,
  onClickAddRelayHandler,
  showFloatingTruckIcon,
  data,
  hideAddLoadAction,
  hideAddRelayAction,
  isTripStop,
}: FloatingActionButtonSectionProps): JSX.Element => {
  const [expandedRight, setExpandedRight] = useState<boolean>(false);
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <StyleFloatingActionRoot
        expanded={expanded}
        showFloatingTruckIcon={showFloatingTruckIcon}
        className={classNamesTimeline.StyleFloatingActionRoot}
      >
        <Stack direction="row" spacing={2}>
          <StyleFloatingActionButton
            color="primary"
            onClick={(event) => {
              setExpandedRight((preState) => !preState);
              event.stopPropagation();
            }}
          >
            <AddIcon />
          </StyleFloatingActionButton>
          <Collapse
            in={expandedRight}
            orientation={'horizontal'}
            timeout="auto"
            unmountOnExit
          >
            <Stack direction="row" spacing={2}>
              {!hideAddLoadAction && (
                <StyleFloatingActionAddButton
                  variant="text"
                  startIcon={<PinDropOutlinedIcon />}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClickAddStopHandler?.(stopSolution, data);
                  }}
                >
                  <StyleFloatingActionAddButtonText>
                    {isTripStop
                      ? t('FloatingActionButtonSectionAddTripStop')
                      : t('FloatingActionButtonSectionAddLoadStop')}
                  </StyleFloatingActionAddButtonText>
                </StyleFloatingActionAddButton>
              )}
              {!hideAddRelayAction && (
                <StyleFloatingActionAddButton
                  startIcon={<SwapHorizOutlinedIcon />}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClickAddRelayHandler?.(stopSolution, data);
                  }}
                >
                  <StyleFloatingActionAddButtonText>
                    {t('FloatingActionButtonSectionAddRelay')}
                  </StyleFloatingActionAddButtonText>
                </StyleFloatingActionAddButton>
              )}
            </Stack>
          </Collapse>
        </Stack>
      </StyleFloatingActionRoot>
    );
  }
};
