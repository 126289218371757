import { Box, Switch, Tooltip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';

const GridSwitch = ({ params, disabled }) => {
  const apiRef = useGridApiContext();
  const handleOnChange = (event) => {
    apiRef.current.updateRows([
      {
        id: params.id,
        autoPopulate: {
          ...params?.autoPopulate,
          value: event.target.checked,
        },
      },
    ]);
  };
  return (
    <Box>
      <Tooltip
        title={
          disabled
            ? 'Not Applicable'
            : 'Enabling this will auto create the accessorial item in the load'
        }
      >
        <Box>
          <Switch
            checked={params?.row?.autoPopulate?.value}
            disabled={disabled}
            // onChange={handleOnChange}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default GridSwitch;
