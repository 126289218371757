import { SxProps, Theme } from '@mui/material';
import { BREAKPOINT_TABLET } from '../../../common/hooks/useThemeResponsive';

export const ContainerStyles = {
  border: '1px solid rgba(0, 17, 34, 0.26)',
  borderRadius: '8px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

export const FilterContainer = {
  background: 'rgba(0, 17, 34, 0.04)',
  width: '100%',
  height: '46px',
  padding: '8px 10px',
  alignItems: 'center',
};

export const TooltipButtonStyles = {
  fontSize: '10px',
  borderRadius: '6px',
  height: '28px',
};

export const FullViewDisplayBlock = {
  display: 'block',
  visibility: 'visible',
  height: '100%',
  width: '100%',
};
export const DisplayNoneBlock = {
  // display: 'none',
  visibility: 'hidden',
  // width: 0,
  height: 0,
};

export const getStyledGanttHeader = ({
  theme,
}: {
  theme: Theme;
}): { [key: string]: SxProps } => {
  return {
    GanttFilterContainer: {
      background: 'rgba(0, 17, 34, 0.04)',
      width: '100%',
      paddingLeft: '10px',
      paddingRight: '10px',
      alignItems: 'center',
      [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
        paddingLeft: '4px',
        paddingRight: '4px',
      },
    },
    LayoutTitleStyles: {
      fontFamily: 'Oxanium',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '140%',
      letterSpacing: '0.15px',
      color: 'rgba(4, 0, 34, 0.75)',
      whiteSpace: 'nowrap',
      flexShrink: 0,
      [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
        fontSize: '18px',
        lineHeight: '100%',
      },
    },
  };
};

export const OptymizeBtnStyles = (theme: Theme) => ({
  border: '1px solid rgba(43, 100, 203, 0.5)',
  borderRadius: '10px',
  boxShadow: theme?.shadows?.[2],
  marginLeft: 'auto',
  height: '100%',
});

export const OptymizeBtnGroupStyles = {
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FFA726',
  borderRadius: '10px',
  height: '32px',
  marginTop: '4px',
};

export const OptymizeAlertStyles = {
  padding: '4px 8px',
  alignItems: 'center',
  minWidth: '70px',
  width: 'max-content',
};

export const OptymizeAlertTextStyles = {
  fontSize: '10px',
  fontStyle: 'normal',
  lineHeight: '120%',
  letterSpacing: '0.4px',
};
