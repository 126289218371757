// Customer Logos
import turvo from '../_assets/images/turvo.png';
import { BitFreighterLogo } from '../_assets/static/svgs/LoadopsBitFreighterLogo';
import { LoadopsEdiLogo } from '../_assets/static/svgs/LoadopsEdiLogo';
import { LoadOpsEDIWhiteLogo } from '../_assets/static/svgs/LoadOpsEdiWhiteLogo';
import { TurvoLogo } from '../_assets/static/svgs/TurvoLogo';
import {
  bigRoadLogo,
  bigRoadLogoWhite,
  cHRobbinsonLogo,
  cHRobbinsonWhiteLogo,
  datLogo,
  datWhiteLogo,
  eroadLogo,
  eroadLogoWhite,
  geotabLogo,
  geotabLogoWhite,
  goMotiveLogo,
  goMotiveLogoWhite,
  jjKellerLogoWhite,
  jjKillerLogo,
  linxupLogo,
  linxupLogoWhite,
  loadBoardLogo,
  loadBoardWhiteLogo,
  loadSmartLogo,
  loadSmartWhiteLogo,
  M2MLogo,
  m2mLogoWhite,
  mandoTrackingLogo,
  masterEldLogo,
  masterEldLogoWhite,
  mondoTrackingLogoWhite,
  OmnitracksLogo,
  omnitracksLogoWhite,
  samsaraLogo,
  samsaraLogoWhite,
  TeleTracLogo,
  teleTracLogoWhite,
  threeMDLogo,
  threeMDLogoWhite,
  trackEnsureLogo,
  trackEnsureLogoWhite,
  truckStopLogo,
  truckStopWhiteLogo,
  verizonConnectLogo,
  verizonConnectLogoWhite,
  visTracksLogo,
  visTracksLogoWhite,
} from '../ui-kit/components/Assets';

export const customerMap = [
  { providerId: 0 },
  {
    providerId: 1,
    providerName: '3MD ELD',
    logo: threeMDLogo,
    logoWhite: threeMDLogoWhite,
    description:
      '3MD ELD is an electronic logging device and tracking solutions provider with a complete FMCSA and IFTA compliance.',
    gradient:
      'linear-gradient(90.03deg, rgba(0, 123, 194, 0.9) 4.43%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 2,
    providerName: 'EROAD',
    logo: eroadLogo,
    logoWhite: eroadLogoWhite,
    description:
      'EROAD Limited provides electronic on-board units and software as a service to the transport industry globally.',
    gradient:
      'linear-gradient(90.03deg, rgba(238, 49, 35, 0.9) 4.43%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 3,
    providerName: 'Verizon Connect',
    logo: verizonConnectLogo,
    logoWhite: verizonConnectLogoWhite,
    description:
      'Verizon Connect is a modern electronic logging device (ELD) based tracking solution provider that gives a near real-time view of a fleet’s daily operations, to help improve driver safety, increase productivity and fleet management.',
    gradient:
      'linear-gradient(90.03deg, rgba(9, 26, 45, 0.9) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 4,
    providerName: 'Motive',
    logo: goMotiveLogo,
    logoWhite: goMotiveLogoWhite,
    description:
      'The Motive ELD is a simple electronic logging device (ELD) that helps managing records and ensures that truck drivers are in compliance with safety laws.',
    gradient:
      'linear-gradient(90.03deg, rgba(0, 0, 0, 1) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  //   { providerId: 5, providerName: 'LB Technology' },
  {
    providerId: 6,
    providerName: 'Samsara',
    logo: samsaraLogo,
    logoWhite: samsaraLogoWhite,
    description:
      'Samsara offers a complete Hours of Service solution for the ELD mandate to keep fleet compliance and simplify fleet management with integrated GPS tracking, fuel usage analysis, safety reports, and maintenance alerts.',
    gradient:
      'linear-gradient(90.03deg, rgba(8, 68, 90, 0.9) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  // {
  //   providerId: 7,
  //   providerName: 'Samsara', //'TMELD',
  //   logo: samsaraLogo,
  //   description: 'Some dummy description',
  // },
  {
    providerId: 8,
    providerName: 'Omnitracs',
    logo: OmnitracksLogo,
    logoWhite: omnitracksLogoWhite,
    description:
      'Omnitracs offers comprehensive set of solutions to ensure fleets get the most out of the Electronic Logging Device mandate.',
    gradient:
      'linear-gradient(90.03deg, rgba(58, 18, 82, 1) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 9,
    providerName: 'Geotab',
    logo: geotabLogo,
    logoWhite: geotabLogoWhite,
    description:
      'Geotab provides ELD compliance software, for both U.S. and Canada, that simplifies Hours of Service (HOS), Driver Vehicle Inspection Reporting (DVIR), Driver Identification and Messaging.',
    gradient:
      'linear-gradient(90.03deg, rgba(38, 71, 124, 0.9) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 10,
    providerName: 'JJKeller',
    logo: jjKillerLogo,
    logoWhite: jjKellerLogoWhite,
    description:
      'J. J. Keller provides a wide range of electronic logbook solutions, from ELogs to fleet technology to data management helping business comply with hours-of-service regulations and navigate complex topics, like personal conveyance and unassigned driving events.',
    gradient:
      'linear-gradient(90.03deg, rgba(38, 71, 124, 0.9) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 11,
    providerName: 'TrackEnsure',
    logo: trackEnsureLogo,
    logoWhite: trackEnsureLogoWhite,
    description:
      'TrackEnsure provides an easy to use ELD/HOS solution, compliant with FMCSA helping Owner Operators and Fleet Owners staying compliant and on track.',
    gradient:
      'linear-gradient(90.03deg, rgba(67, 92, 117, 1) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 12,
    providerName: 'BigRoad',
    logo: bigRoadLogo,
    logoWhite: bigRoadLogoWhite,
    description:
      'BigRoad provides mandate compliance solutions such as hours-of-service (HOS), and electronic logging device (ELD) to the North American transportation industry.',
    gradient:
      'linear-gradient(90.03deg, rgba(0, 0, 0, 1) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 13,
    providerName: 'Teletrac',
    logo: TeleTracLogo,
    logoWhite: teleTracLogoWhite,
    description:
      'Teletrac ELD is a modern system that provides the data required to make informed hours of service decisions. And, offers compliance centric features and services like Hours of Service (HOS), DOT roadside inspections, pre- and post-trip inspections and more.',
    gradient:
      'linear-gradient(90.03deg, rgba(153, 194, 33, 1) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 14,
    providerName: 'M2M In Motion',
    logo: M2MLogo,
    logoWhite: m2mLogoWhite,
    description:
      'M2M in Motion technology platform combines hours of service with GPS and sensor data, ensuring compliance, expand safety, and daily streamlining operations.',
    gradient:
      'linear-gradient(90.03deg, rgba(3, 155, 229, 1) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 15,
    providerName: 'Mondo Tracking',
    logo: mandoTrackingLogo,
    logoWhite: mondoTrackingLogoWhite,
    description:
      'MondoTracking offers solutions pertaining to GPS tracking and ELD for the Trucking Industry solving variety of purposes including fleet, vehicle, and asset management as well as personal/pet tracking.',
    gradient:
      'linear-gradient(90.03deg, rgba(22, 96, 149, 0.9) 4.11%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 16,
    providerName: 'Vistracks',
    logo: visTracksLogo,
    logoWhite: visTracksLogoWhite,
    description:
      'VisTracks ELD is FMCSA certified and widely used by serveral GPS Fleet Tracking companies in the US and Canada.',
    gradient:
      'linear-gradient(90.03deg, rgba(0, 154, 222, 0.9) 4.43%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    providerId: 17,
    providerName: 'Master ELD',
    logo: masterEldLogo,
    logoWhite: masterEldLogoWhite,
    description:
      'The MasterELD electronic logging device (ELD) is loaded with an array of fleet management and compliance features, for DOT, FMCSA, and HOS compliance.',
    gradient:
      'linear-gradient(90.03deg, rgba(0, 0, 0, 1) 4.43%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  // {
  //   providerId: 18,
  //   providerName: 'Mix Telematics',
  //   logo: samsaraLogo,
  //   description: '',
  // },
  {
    providerId: 19,
    providerName: 'Linxup',
    logo: linxupLogo,
    logoWhite: linxupLogoWhite,
    description:
      'Linxup offers fleet management tools and ELD solutions that help drivers with compliances like managing accurate logs of their Hours of service (HOS), in accordance to mandate requirements.',
    gradient:
      'linear-gradient(90.03deg, rgba(0, 85, 184, 1) 4.43%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
];

export const LoadboardCustomerMap = [
  {
    description:
      'TruckStop loadboard enables efficient planning, rates discovery, and identifying the best paying loads and get paid quickly, essential for a smooth trucking operation.',
    logo: truckStopLogo,
    providerId: 2,
    providerName: 'Truck Stop',
    logoWhite: truckStopWhiteLogo,
    gradient:
      'linear-gradient(90.03deg, rgba(58, 58, 58, 1) 4.43%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    description:
      'The DAT Network hosts millions of freight loads and trucks per year in the US and Canada. The network consists of several load board subscription services for small to midsize carriers, freight brokers, and shippers.',
    logo: datLogo,
    providerId: 7,
    providerName: 'DAT',
    logoWhite: datWhiteLogo,
    gradient:
      'linear-gradient(90.06deg, rgba(0, 98, 190, 1) 4.1%, rgba(255, 255, 255, 0.09) 55.11%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    description:
      'The DAT Network hosts millions of freight loads and trucks per year in the US and Canada. The network consists of several load board subscription services for small to midsize carriers, freight brokers, and shippers.',
    logo: <>{datLogo}(Legacy)</>,
    logoWidth: '100px',
    providerId: 1,
    providerName: 'DAT LEGACY',
    logoWhite: datWhiteLogo,
    gradient:
      'linear-gradient(90.06deg, rgba(0, 98, 190, 1) 4.1%, rgba(255, 255, 255, 0.09) 55.11%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    description:
      'Loadsmart offers an easy way to find the right freight to keep trucks full and quickly sorts through thousands of available loads by pickup location, destination, rate, and other factors.',
    logo: loadSmartLogo,
    providerId: 3,
    providerName: 'Load Smart',
    logoWhite: loadSmartWhiteLogo,
    gradient:
      'linear-gradient(90.03deg, rgba(19, 214, 76, 1) 4.43%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    description:
      'With C.H. Robinson, avoid empty miles, compare rates, and instantly book loads with the largest carrier load board in North America.',
    logo: cHRobbinsonLogo,
    providerId: 6,
    providerName: 'C.H. Robinson',
    logoWhite: cHRobbinsonWhiteLogo,
    gradient:
      'linear-gradient(90.03deg, rgba(39, 173, 234, 1) 4.43%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
  {
    description:
      '123Loadboard provides a freight matching web and mobile app marketplace for carriers, owner­-operators, brokers and shippers to engage with trucking tools to help professional truck drivers find loads and increase their loaded miles.',
    logo: loadBoardLogo,
    providerId: 4,
    providerName: '123LoadBoard',
    logoWhite: loadBoardWhiteLogo,
    gradient:
      'linear-gradient(90.03deg,  rgba(37, 178, 75, 1) 4.43%, rgba(255, 255, 255, 0.081) 66.64%), linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68))',
  },
];

export const LoadTenderProvider = {
  LOADOPSEDI: 'LoadOps EDI',
  TURVO: 'TURVO',
  BITFREIGHTER: 'BIT FREIGHTER',
};

export const LoadTenderMap = [
  {
    providerId: 1,
    providerName: LoadTenderProvider.LOADOPSEDI,
    logo: <LoadopsEdiLogo />,
    logoWhite: <LoadOpsEDIWhiteLogo />,
    description:
      'LoadOps EDI allows carriers to exchange information electronically with other businesses. With this feature, carriers can view loads shared on EDI, book or reject loads, and send shipment status updates. The feature also includes an automatic load creation in LoadOps, which eliminates manual data entry and reduces the risk of errors. This helps to increase profits and reduce manual workload, resulting in a more efficient and streamlined shipping process.',
    gradient:
      'linear-gradient(90deg, rgba(53,68,107,1) 0%, rgba(53,68,107,1) 100%)',
  },
  {
    providerId: 2,
    providerName: LoadTenderProvider.TURVO,
    logo: <TurvoLogo />,
    logoWhite: <img src={turvo} alt="turvo" />,
    description:
      'With Turvo integration, LoadOps users can easily connect with brokers and receive load tenders in real-time. This streamlines the load tender management process and eliminates the need for manual intervention, reducing errors and saving time.',
    gradient: 'linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.66) 65.1%)',
  },
  {
    providerId: 3,
    providerName: LoadTenderProvider.BITFREIGHTER,
    logo: <BitFreighterLogo />,
    logoWhite: <BitFreighterLogo />,
    description:
      'Integration with bitFreighter allows carriers to exchange information electronically with other businesses. With this feature, carriers can view loads shared on EDI, book or reject loads, and send shipment status updates. The feature also includes an automatic load creation in LoadOps, which eliminates manual data entry and reduces the risk of errors. This helps to increase profits and reduce manual workload, resulting in a more efficient and streamlined shipping process.',
    gradient:
      'linear-gradient(90deg, rgba(53,68,107,1) 0%, rgba(53,68,107,1) 100%)',
  },
];
