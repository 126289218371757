import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledLoadInvoiceKPICard = styled((props: StackProps) => (
  <Stack {...props} />
))(({ theme }: { theme: any }) => ({
  background: theme.palette.info.background,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px',
  width: '255px',
  padding: '16px 8px',
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const titleStyle = () => ({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '16px',
  letterSpacing: '0px',
  textAlign: 'left',
});

export const totalStyles = () => ({
  fontFamily: 'Poppins',
  fontSize: '2rem',
  fontWeight: '400',
  lineHeight: '48px',
  letterSpacing: '0px',
  textAlign: 'center',
});

export const subTitleStyles = () => ({
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '18px',
  letterSpacing: '0px',
  textAlign: 'left',
  width: '100%',
  whiteSpace: 'nowrap',
});

export const timeStampStyles = () => ({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '18px',
  letterSpacing: '0px',
  textAlign: 'center',
  width: '100%',
  whiteSpace: 'nowrap',
});
