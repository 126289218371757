import { add, endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import StorageManager from '../../../StorageManager/StorageManager';
import { TLocationSelectValue } from '../../../common/Ui/LocationSelect';
import {
  IDriverDataResponse,
  IGapData,
} from '../../../models/DTOs/Loadsearch/Model';
import { LoadsearchRequest } from '../../../models/DTOs/Loadsearch/Request';
import { IRelativeDateRangeOption } from '../../../ui-kit/components/RelativeDateRange';
import { DateTimezoneUtils } from '../../../utils/Timezone.utils';
import { IDriverGapData, ISelectedDriverDetails } from '../constants/types';
import { ILoadboardFilters } from '../loadTypes';

const createLocationData = (
  location: TLocationSelectValue | null | undefined,
  deadheadMile?: number | string
): Array<any> => {
  if (!location || !location?.data) return [];

  const value = location?.data;
  const type = location?.locationLevel;

  const originCity: Array<any> = [];
  switch (type) {
    case 'City':
      const locationData: any = {
        city: value?.['city'],
        deadheadMiles: deadheadMile,

        latitude: value?.['center']?.['lat'],
        longitude: value?.['center']?.['lng'],
        state: value?.['state'],
      };
      originCity.push(locationData);
      break;
    case 'State':
      value.map((item: { title: string }) => {
        const StateLocationData: any = {
          state: item['title'],
        };
        originCity.push(StateLocationData);
      });
      break;
    case 'Zone':
      value.map((item: { title: string; value: string }) => {
        const zoneLocationData: any = {
          zone: item['value'],
        };
        originCity.push(zoneLocationData);
      });
      break;
    default:
      break;
  }

  return originCity;
};

const toOrigins = (
  origins: TLocationSelectValue | null | undefined,
  maxOriginDeadhead?: number | string
) => {
  return createLocationData(origins, maxOriginDeadhead);
};
const toDestinations = (
  destinations: TLocationSelectValue | null | undefined,
  maxDestinationDeadhead?: number | string
) => {
  return createLocationData(destinations, maxDestinationDeadhead);
};

const toPickupRequest = (
  relativeDateRange?: IRelativeDateRangeOption | null | undefined
): Pick<LoadsearchRequest['criteria'], 'pickupStart' | 'pickupEnd'> => {
  switch (relativeDateRange?.name) {
    case 'Today': {
      const today = new Date();
      return {
        pickupStart: new Date().toISOString(),
        pickupEnd: DateTimezoneUtils.toNoTimeZoneDateString(
          endOfDay(today),
          null,
          false
        ),
      };
    }
    case 'Tomorrow': {
      const tomorrow = add(new Date(), {
        days: 1,
      });
      return {
        pickupStart: DateTimezoneUtils.toNoTimeZoneDateString(
          startOfDay(tomorrow),
          new Date(new Date().setUTCHours(0, 0, 0, 0))
        ),
        pickupEnd: DateTimezoneUtils.toNoTimeZoneDateString(
          endOfDay(tomorrow),
          null,
          false
        ),
      };
    }
    default: {
      const date = relativeDateRange?.dateRange?.[0]
        ? new Date(relativeDateRange?.dateRange?.[0] as Date)
        : new Date();
      const currentDate = new Date();
      let pickupStart = DateTimezoneUtils.toNoTimeZoneDateString(
        relativeDateRange?.dateRange?.[0] as Date,
        new Date(new Date().setUTCHours(0, 0, 0, 0))
      );
      if (
        date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear()
      ) {
        pickupStart = new Date().toISOString();
      }
      return {
        pickupStart,
        pickupEnd: DateTimezoneUtils.toNoTimeZoneDateString(
          relativeDateRange?.dateRange?.[1] as Date,
          null,
          false
        ),
      };
    }
  }
};

export const toLoadsearchRequest = ({
  loadboardGridFilters,
  selectedDriver,
}: {
  loadboardGridFilters: ILoadboardFilters;
  selectedDriver: ISelectedDriverDetails;
}): LoadsearchRequest => {
  const payload = {
    sources:
      loadboardGridFilters?.sources?.map((option) => ({
        source: option.groupName,
        sourceId: option.id,
      })) || [],
    criteria: {
      ageHours: loadboardGridFilters?.ageOfLoad || 0,
      origins: toOrigins(
        loadboardGridFilters?.origins,
        loadboardGridFilters?.maxOriginDeadhead
      ),
      destinations: toDestinations(
        loadboardGridFilters?.destinations,
        loadboardGridFilters?.maxDestinationDeadhead
      ),
      equipmentList:
        loadboardGridFilters?.equipmentList?.map((option) => option.name) || [],
      equipments: 'Vans, Standard', //@TODO why we hardcode here
      lengthFeet: loadboardGridFilters?.maxEquipmentLength || null, //Max equ length
      loadType: loadboardGridFilters?.loadType?.id || 'F',
      customerPreferenceType:
        loadboardGridFilters.customerPreferenceType?.id || 'NONE',
      reloadScoreFilter: loadboardGridFilters?.reloadScoreFilter?.id || 1,

      ...toPickupRequest(loadboardGridFilters.pickupDateRanges),
      weightPounds: loadboardGridFilters?.maxEquipmentWeight || null, //Max equ weight
    },
    showActualOffersOnly:
      loadboardGridFilters?.showActualOffersOnly?.id || false,

    dispatcherId: StorageManager.getUser()?.id || null, //User Id
    ...(selectedDriver?.value?.subValue?.nextLoadId && {
      nextLoadId: selectedDriver?.value?.subValue?.nextLoadId,
    }),
    ...(selectedDriver?.value?.subValue?.previousLoadId && {
      previousLoadId: selectedDriver?.value?.subValue?.previousLoadId,
    }),
    ...(selectedDriver?.value?.value?.driverGroupId && {
      driverGroupId: selectedDriver?.value?.value?.driverGroupId,
    }),
    ...(selectedDriver?.value?.value?.driverId &&
      selectedDriver?.value?.value?.driverId.length > 0 && {
        driverId: selectedDriver?.value?.value?.driverId[0],
      }),
    gapStart:
      selectedDriver?.value?.subValue?.gapStartTime &&
      new Date(selectedDriver.value.subValue.gapStartTime) > new Date()
        ? selectedDriver.value.subValue.gapStartTime
        : new Date(),
  };

  const request = new LoadsearchRequest(payload);
  return request;
};

export const createDriverGapData = (
  driversList: IDriverDataResponse[]
): IDriverGapData => {
  const driverData = {
    label: 'Select Drivers',
    selectedValue: null,
  };

  const driverItem: any = [];
  driversList.forEach((item: IDriverDataResponse) => {
    const driverChildItem: any = [];
    const driverParentItem: any = {
      uid: item['driverGroupId'],
      label: item['driverName'],
    };

    item?.['gapData'].forEach((subItem: IGapData) => {
      const selectValue = {
        value: item,
        subValue: subItem,
      };
      const childSubItem = {
        label: `${
          subItem['originCity'] && subItem['originState']
            ? `${subItem['originCity']}, ${subItem['originState']}`
            : 'Anywhere'
        } -> ${
          subItem['destinationCity'] && subItem['destinationState']
            ? `${subItem['destinationCity']}, ${subItem['destinationState']}`
            : 'Anywhere'
        }`,

        subValue: `${moment(new Date(subItem['gapStartTime'])).format(
          'MM/DD'
        )}, ${moment(new Date(subItem['gapStartTime'])).format('hh a')}, ${
          subItem?.gapDurationMins
            ? (subItem?.gapDurationMins / 60).toFixed(2)
            : 0
        }hr`,
        value: selectValue,
        callback: (item: { label: string; value: any }) => {
          return item;
        },
      };
      driverChildItem.push(childSubItem);
    });

    driverParentItem['items'] = driverChildItem;

    driverItem.push(driverParentItem);
  });

  if (!driverItem?.length) {
    driverData['label'] = 'No Driver';
  }

  driverData['items'] = driverItem;

  return driverData;
};
