import { Box, Theme, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { NotesSummaryStyle } from '../../style';
import { formatTextToUpperCase, toFixDigit } from '../../utils';

const RateSummary = ({
  hideNoteSection = false,
}: {
  hideNoteSection?: boolean;
}) => {
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const rate = getValues('rate');

  const containerStyles = useMemo(() => {
    if (Number(rate?.totalRate)) return { m: 1 };
    return {};
  }, [rate?.baseRateType]);
  if (!rate) return <></>;

  return (
    <>
      {Number(rate.totalRate) > 0 && (
        <Stack direction="row" gap={1}>
          <Typography variant="body1">
            <b>{toFixDigit(rate.totalRate)}</b>
          </Typography>
        </Stack>
      )}
      <Stack
        direction="column"
        gap={'2px'}
        sx={{ width: '100%', ...containerStyles, ml: 4 }}
      >
        {rate.contract && (
          <Typography
            variant="body1"
            fontWeight={600}
            sx={(theme: Theme) => ({ color: theme.palette.grey[600] })}
          >
            {rate.contract?.name}
          </Typography>
        )}
        {!!(Number(rate.rate) && rate?.feeDetail) && (
          <Stack direction="row" gap={1} justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              {rate?.feeDetail?.itemName ??
                formatTextToUpperCase(rate?.feeDetail?.type) ??
                ''}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              ${toFixDigit(Number(rate?.quantity || 0) * Number(rate.rate))}
            </Typography>
          </Stack>
        )}

        {rate?.additionalFees?.map?.((fee: any, index: number) => {
          if (fee?.fee && fee?.total) {
            return (
              <Stack
                direction="row"
                gap={1}
                justifyContent="space-between"
                key={index}
              >
                <Typography variant="body2" color="textSecondary">
                  {fee.feeDetail?.itemName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {fee?.feeDetail?.deduction && '-'} $
                  {toFixDigit(Math.abs(fee.total))}
                </Typography>
              </Stack>
            );
          }
          return <></>;
        })}

        {!hideNoteSection && (
          <>
            {rate?.internalNotes && !errors?.rate?.internalNotes && (
              <Stack direction="row" sx={{ wordBreak: 'break-word' }}>
                <Box sx={NotesSummaryStyle}>
                  <Typography variant="body2">{rate.internalNotes}</Typography>
                </Box>
              </Stack>
            )}

            {rate?.messageOnInvoice && !errors?.rate?.messageOnInvoice && (
              <Stack direction="row" sx={{ wordBreak: 'break-word' }}>
                <Box sx={NotesSummaryStyle}>
                  <Typography variant="body2">
                    {rate.messageOnInvoice}
                  </Typography>
                </Box>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </>
  );
};
export default RateSummary;
