/* @TODO this file should be rewritten */
export const containerStyle = {
  textAlign: 'center',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const main = {
  height: '100%',
  width: '100%',
  padding: '24px',
  border: '1px solid #669EEF',
  borderRadius: '14px',
};

export const styles = {
  main: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  or: {
    letterSpacing: '0.15px',
    marginTop: '8px',
    marginBottom: '22px',
  },
  text: {
    fontSize: '14px',
    marginTop: '24px !important',
    fontWeight: 400,
  },
  btn: {
    textTransform: 'none',
  },
};
