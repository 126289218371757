import moment, { Moment } from 'moment';
import { getUserLocalTimezone } from './';
import { getGridCompanyTimezoneSettings } from './Timezone.utils';

export const convert2MomentTz = (
  timeString?: string,
  tz?: string
): Moment | null => {
  if (!timeString) return null;

  const timezone =
    tz ||
    getGridCompanyTimezoneSettings().timezone ||
    getUserLocalTimezone() ||
    '';

  return timezone ? moment(timeString).tz(timezone) : moment(timeString);
};
