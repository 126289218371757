import React, { FC } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import { ToolTipBroker } from './tooltip';
import { Box, Typography } from '@mui/material';
import CustomizedTooltips from '../../../../ui-kit/components/AxeleTooltip';
import { ILoadsearchTableItem } from '../../loadTypes';

type BrokerProps = ILoadsearchTableItem['Broker'] &
  Pick<ILoadsearchTableItem['actions']['savedLoadsData'], 'customerPreference'>;

const Broker: FC<BrokerProps> = (props) => {
  return (
    <CustomizedTooltips
      placement={'bottom'}
      titleFirstLine={'firstline'}
      titleSecondLine={'secondline'}
      arrow={true}
      backgroundColor={'transparent'}
      enterDelay={20}
      leaveDelay={10}
      titleFirstIcon={<SettingsOutlinedIcon />}
      titleSecondIcon={<DnsOutlinedIcon />}
      isCustomizationTitle={true}
      component={<ToolTipBroker {...props} />}
    >
      <Box>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '150%',
            letterSpacing: '0.14px',
            alignItems: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '120px',
            textOverflow: 'ellipsis',
            color: '#2B64CB',
          }}
          gutterBottom
        >
          {props.value}
        </Typography>
        <Typography
          variant="caption"
          gutterBottom
          sx={{
            color: 'rgba(0, 17, 34, 0.35);',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '150%',
            letterSpacing: '0.14px',
          }}
        >
          {props.subvalue}
        </Typography>
      </Box>
    </CustomizedTooltips>
  );
};

export default React.memo(Broker);
