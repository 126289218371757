import { Card, CardHeader, Divider, Stack } from '@mui/material';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { AssignTrailerIcon } from '../../../../../ui-kit/components/Assets';
import { useNavigate } from 'react-router-dom';
import { useDispatchFindLoad } from '../../../../../common/hooks/useDispatchFindLoad';
import { useDispatchPermission } from '../../../../../common/hooks/useDispatchPermission';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { TeamDriver } from '../../../../../models';
import { IDispatchDriverTimelineTrip } from '../../../../../models/DTOs/Dispatch/Timeline';
import { performNaivgation } from '../../../../../utils/commonNavigation';
import TimeoffDateTime from '../../../../../utils/timeoffDateTime';
import { defaultNoValue } from '../../../constants/dispatch';
import {
  StyledGAPDriveTimeValue,
  StyledSectionETA,
  StyledSectionKPIValue,
} from '../styles';
import { useTranslation } from 'react-i18next';

const btnStyle = {
  width: '150px',
  height: '40px',
};

export interface GanttCardGapProps {
  isCurrent?: boolean;
  trip: IDispatchDriverTimelineTrip;
  driverTeam: TeamDriver | null;
}

export const GanttCardGap = ({
  trip,
  isCurrent = true,
  driverTeam,
}: GanttCardGapProps): JSX.Element => {
  const navigate = useNavigate();
  const { setFindLoadData } = useDispatchFindLoad();
  const { getCompanyPreferences } = useRootStore();
  const { hasDispatchFindLoadPermission } = useDispatchPermission();
  const findLoadHandler = (): void => {
    setFindLoadData({
      origin: trip.stops?.[0]?.location,
      destination: trip.stops?.[1]?.location,
      driverGroupId: driverTeam?.id,
      startTime: trip.startTime || null,
      endTime: trip.endTime || null,
      driverId: driverTeam?.drivers?.[0]?.id,
      groupName: driverTeam?.displayName,
    });
    performNaivgation('loadsearch', navigate);
  };
  const { t, ready } = useTranslation();

  if (ready) {
    return (
      <Card
        sx={{
          backgroundColor: 'info.outlinedHoverBackground',
          border: '1px dashed #669EFF',
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          width={'100%'}
          height={'100%'}
          padding={'8px'}
        >
          <CardHeader
            sx={{
              padding: 0,
            }}
            title={
              <StyledSectionETA whiteSpace={'nowrap'}>
                {t('GanttCardGapDriveTime')}{' '}
              </StyledSectionETA>
            }
            subheader={
              <StyledGAPDriveTimeValue isCurrent={isCurrent}>
                {TimeoffDateTime.minutesToDurationFormat(
                  trip.driveTimeMins,
                  defaultNoValue
                )}
              </StyledGAPDriveTimeValue>
            }
          />
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            width={'100%'}
            height={'100%'}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0.5}
              width={'100%'}
            >
              <CardHeader
                sx={{
                  padding: 0,
                }}
                title={
                  <StyledSectionKPIValue>
                    {t('GanttCardGapTimeAvailable')}
                  </StyledSectionKPIValue>
                }
                subheader={
                  <StyledSectionETA>
                    {TimeoffDateTime.getDateTimeDisplayTooltip({
                      dateTime: trip.startTime,
                      defaultValue: defaultNoValue,
                      timezone: getCompanyPreferences?.timezone || '',
                    })}
                  </StyledSectionETA>
                }
              />
              <ButtonImproved
                styleProps={btnStyle}
                size="medium"
                color="primary"
                label={t('GanttCardGapFindLoad')}
                startIcon={<AssignTrailerIcon />}
                variant="contained"
                onClick={findLoadHandler}
                disabled={!hasDispatchFindLoadPermission}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0.5}
              width={'100%'}
              marginTop={'8px !important'}
            >
              <CardHeader
                sx={{
                  padding: 0,
                }}
                title={
                  <StyledSectionKPIValue>
                    {t('GanttCardGapDutyTime')}
                  </StyledSectionKPIValue>
                }
                subheader={
                  <StyledSectionETA>
                    {TimeoffDateTime.minutesToDurationFormat(
                      trip.dutyTimeMins,
                      defaultNoValue
                    )}
                  </StyledSectionETA>
                }
              />

              {/* <ButtonImproved
                styleProps={btnStyle}
                size="medium"
                color="primary"
                label="Post Truck"
                startIcon={<AssignTractorIcon />}
                variant="contained"
              /> */}
            </Stack>
          </Stack>
        </Stack>
      </Card>
    );
  }
};
