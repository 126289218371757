import { Type } from 'class-transformer';
import { PaginatedList } from '../../../types';
import { AlertEntityType, AlertStatus } from './Types';

interface IAssignedDocuments {
  documentId: string;
  fileName: string;
  documentType: string;
}

export interface IAlert {
  id: number | string;
  organizationId: number;
  entityType: AlertEntityType;
  entityName: string;
  entityId: number;
  noExpiry: boolean;
  maintenanceTypeId: string;
  maintenanceType: string;
  maintenanceName: string;
  iconName: string;
  lastConducted: Date | string;
  nextDue: Date | string;
  lastServiceDueOdometer: number;
  nextServiceDueOdometer: number;
  assignedDocuments: IAssignedDocuments[];
  alertStatus: AlertStatus;
  notificationScheduleByDays: string;
  notificationScheduleByMiles: number | null;
}

export class Alert implements IAlert {
  id: number | string;
  organizationId: number;
  entityType: AlertEntityType;
  entityName: string;
  entityId: number;
  noExpiry: boolean;
  maintenanceTypeId: string;
  maintenanceType: string;
  maintenanceName: string;
  iconName: string;
  lastConducted: Date | string;
  nextDue: Date | string;
  lastServiceDueOdometer: number;
  nextServiceDueOdometer: number;
  assignedDocuments: IAssignedDocuments[];
  alertStatus: AlertStatus;
  notificationScheduleByDays: string;
  notificationScheduleByMiles: number | null;

  constructor(dto: IAlert) {
    Object.assign(this, dto);
  }
}

export class PaginatedAlertList extends PaginatedList {
  @Type(() => Alert)
  content!: Alert[];
}
