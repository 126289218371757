import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { SplitterContainerStyles } from '../styles';

interface ISplitResizer {
  enableResize?: boolean;
}

const SplitResizer: React.FC<ISplitResizer> = ({ children, enableResize }) => {
  useEffect(() => {
    const resizer = document.getElementById('resizer') as any;
    const topSide = resizer.previousElementSibling;
    const bottomSide = resizer.nextElementSibling;

    let y = 0;
    let topSideHeight = 0;

    const mouseDownHandler = function (e: any) {
      y = e.clientY;
      topSideHeight = topSide.getBoundingClientRect().height;

      const mouseMoveHandler = function (e: any) {
        document.body.style.userSelect = 'none';
        resizer.style.cursor = 'row-resize';
        document.body.style.cursor = 'row-resize';

        const dy = e.clientY - y;

        const newLeftWidth =
          ((topSideHeight + dy) * 100) /
          resizer.parentNode.getBoundingClientRect().height;
        topSide.style.height = `${newLeftWidth}%`;
      };

      const mouseUpHandler = function () {
        resizer.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');
        document.body.style.removeProperty('user-select');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    if (enableResize) {
      resizer.style.display = 'block';
      resizer.style.cursor = 'row-resize';
      topSide.style.height = `50%`;
      return resizer.addEventListener('mousedown', mouseDownHandler);
    }
    resizer.style.display = 'none';
    topSide.style.height = `100%`;
    resizer.removeEventListener('mousedown', mouseDownHandler);
  }, [enableResize]);

  return <Box sx={SplitterContainerStyles}>{children}</Box>;
};

const Resizer = () => <Box className="resizer" id="resizer"></Box>;

export { SplitResizer as default, Resizer };
