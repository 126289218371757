import { Link, Typography } from '@mui/material';
import { t } from 'i18next';
import { UI_DATE_FORMAT } from '../../../constants/date.constants';
import { PayStatementsEntityTypeEnum } from '../../../models';
import { GridColDefSelf } from '../../../types';
import { amountFormat, getFormattedDateForGrid } from '../../../utils';

export const getFinancePayStatementGridColumns = (
  entity: PayStatementsEntityTypeEnum,
  getIsTerminalEnabled = true
): GridColDefSelf[] => {
  if (entity === PayStatementsEntityTypeEnum.pendingPayment) {
    const paymentsColumns: GridColDefSelf[] = [
      {
        flex: 1,
        field: 'entity',
        headerName: t('type'),
        sortable: true,
        permanent: true,
        minWidth: 90,
        valueGetter: (params) =>
          params?.row?.entity === 'Dispatcher'
            ? 'Web User'
            : params?.row?.entity,
      },
      {
        flex: 1,
        field: 'entityName',
        headerName: t('name'),
        sortable: true,
        minWidth: 200,
        isHyperLink: true,
        renderCell: (params) => {
          return <Link component={'a'}>{params?.row?.entityName}</Link>;
        },
      },
      {
        flex: 1,
        field: 'terminal',
        headerName: t('terminal'),
        sortable: false,
        minWidth: 200,
        valueGetter: (params) => params?.row?.terminal?.companyName,
      },
      {
        flex: 1,
        field: 'amount',
        headerName: t('pendingAmount'),
        sortable: true,
        minWidth: 200,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: 'text.primary',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 700,
              }}
            >
              {amountFormat(params?.row?.amount)}
            </Typography>
          );
        },
      },
    ];

    return getIsTerminalEnabled
      ? paymentsColumns
      : paymentsColumns.filter((item) => item.field !== 'terminal');
  } else {
    const paymentsColumns: GridColDefSelf[] = [
      {
        flex: 1,
        field: 'entityId',
        headerName: 'Entity ID',
        sortable: true,
        permanent: true,
        minWidth: 90,
      },
      {
        flex: 1,
        field: 'entity',
        headerName: 'Type',
        sortable: true,
        permanent: true,
        minWidth: 90,
        valueGetter: (params) =>
          params?.row?.entity === 'Dispatcher'
            ? 'Web User'
            : params?.row?.entity,
      },
      {
        flex: 1,
        field: 'entityName',
        headerName: 'Name',
        sortable: true,
        minWidth: 200,
        isHyperLink: true,
        renderCell: (params) => {
          return <Link component={'a'}>{params?.row?.entityName}</Link>;
        },
      },
      {
        flex: 1,
        field: 'seqNumber',
        headerName: 'Settlement #',
        sortable: true,
        minWidth: 150,
        valueGetter: (params) => params?.row?.seqNumber,
      },
      {
        flex: 1,
        field: 'terminal',
        headerName: 'Terminal',
        sortable: false,
        minWidth: 200,
        valueGetter: (params) => params?.row?.terminal?.companyName,
      },
      {
        flex: 1,
        field: 'startDate',
        headerName: 'Start Date',
        sortable: true,
        minWidth: 100,
        valueGetter: (params) =>
          getFormattedDateForGrid(params?.row?.startDate, UI_DATE_FORMAT),
      },
      {
        flex: 1,
        field: 'endDate',
        headerName: 'End Date',
        sortable: true,
        minWidth: 100,
        valueGetter: (params) =>
          getFormattedDateForGrid(params?.row?.endDate, UI_DATE_FORMAT),
      },
      {
        flex: 1,
        field: 'createDate',
        headerName: 'Settlement Created Date',
        sortable: true,
        minWidth: 100,
        valueGetter: (params) =>
          getFormattedDateForGrid(params?.row?.createDate, UI_DATE_FORMAT),
      },
      ...(entity === PayStatementsEntityTypeEnum.closedStatement
        ? [
            {
              flex: 1,
              field: 'paymentDate',
              headerName: 'Payment Date',
              sortable: true,
              minWidth: 120,
              valueGetter: (params) =>
                getFormattedDateForGrid(
                  params?.row?.paymentDate,
                  UI_DATE_FORMAT
                ),
            },
          ]
        : []),
      {
        flex: 1,
        field: 'tripIncomeAmount',
        headerName: t('totalTripIncome'),
        sortable: true,
        minWidth: 200,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color:
                  params?.row?.tripIncomeAmount < 0
                    ? 'error.main'
                    : 'primary.main',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 700,
              }}
            >
              {amountFormat(params?.row?.tripIncomeAmount)}
            </Typography>
          );
        },
      },
      {
        flex: 1,
        field: 'reimbursementAmount',
        headerName: t('totalReimbursement'),
        sortable: true,
        minWidth: 200,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color:
                  params?.row?.reimbursementAmount < 0
                    ? 'error.main'
                    : 'primary.main',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 700,
              }}
            >
              {amountFormat(params?.row?.reimbursementAmount)}
            </Typography>
          );
        },
      },
      {
        flex: 1,
        field: 'deductionAmount',
        headerName: t('totalDeduction'),
        sortable: true,
        minWidth: 200,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color:
                  params?.row?.deductionAmount < 0
                    ? 'error.main'
                    : 'primary.main',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 700,
              }}
            >
              {amountFormat(params?.row?.deductionAmount)}
            </Typography>
          );
        },
      },
      {
        flex: 1,
        field: 'paymentAmount',
        headerName: 'Total Payment',
        sortable: true,
        minWidth: 200,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color:
                  params?.row?.paymentAmount < 0
                    ? 'error.main'
                    : 'primary.main',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 700,
              }}
            >
              {amountFormat(params?.row?.paymentAmount)}
            </Typography>
          );
        },
      },
      {
        flex: 1,
        field: 'amount',
        headerName: t('totalAmount'),
        sortable: true,
        minWidth: 200,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: params?.row?.amount < 0 ? 'error.main' : 'primary.main',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 700,
              }}
            >
              {amountFormat(params?.row?.amount)}
            </Typography>
          );
        },
      },
      {
        flex: 1,
        field: 'nonTaxableAmount',
        headerName: 'Total Non-Taxable Payment',
        sortable: true,
        minWidth: 200,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: params?.row?.amount < 0 ? 'error.main' : 'primary.main',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 700,
              }}
            >
              {amountFormat(params?.row?.nonTaxableAmount)}
            </Typography>
          );
        },
      },
    ];
    return getIsTerminalEnabled
      ? paymentsColumns
      : paymentsColumns.filter((item) => item.field !== 'terminal');
  }
};
