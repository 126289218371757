import { Button, Grid, IconProps, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  AddIcon,
  DeleteButtonIcon,
} from '../../../../ui-kit/components/Assets';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import ThreeDotMenuIcon from '../../../../ui-kit/components/ThreeDotMenuIcon';

import { t } from 'i18next';
import { observer } from 'mobx-react';
import Accordion from '../../../../common/Accordion';
import { paymentLineItemAmoutConfigText } from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewConstant';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { getHasPermission } from '../../../../common/Permission/utils/helperUtils';
import FormDialog from '../../../../common/Ui/FormDialog/index';
import Select from '../../../../common/Ui/Select';
import TextField from '../../../../common/Ui/TextField/TextField';
import { CreateDriverPaymentTerm, PaymentPerLoad } from '../../../../models';
import {
  ExpenseCategory,
  OperationMode,
} from '../../../../models/DTOs/PaymentTerms';
import { UserDetails } from '../../../../models/DTOs/user/User';
import { amountFormat } from '../../../../utils';
import { addNewPaymentPerLoadFormValidationSchema } from '../../../webUsers/constants';
import { ScheduleAPITextConfig } from '../../constants';
import ScheduledPaymentItem from '../ScheduledPayment/ScheduledPayment';
import AddNewPaymentPerLoadForm from './AddNewPaymentPerLoadForm';
import FormContext, { FormProvider } from './FormContext';
import { IPaymentTermDataDependency } from './PaymentTermTabs';
const defaultDriverPaymentTerm = new CreateDriverPaymentTerm();

const getExpenseCategoryCodeById = (
  expenseCategoties: ExpenseCategory[],
  id: string
): string => {
  const foundExpenseCategory = expenseCategoties.find((exp) => exp.id === id);

  if (foundExpenseCategory) {
    return foundExpenseCategory.itemCode || '';
  }

  return '';
};

type PaymentTermDeleteActionType = {
  id?: number;
  label: string;
  icon: ({ color }: IconProps) => JSX.Element;
};

const PaymentPerLoadItem = ({
  data,
  expanded = false,
  foundIndex,
  inactive,
}: {
  data: PaymentPerLoad;
  expanded?: boolean;
  foundIndex: number;
  inactive?: boolean;
}) => {
  const [cardExpanded, setCardExpanded] = useState(true);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { expenseCategories, deletePaymentForLoad, allPaymentTypes } =
    React.useContext(FormContext);
  const { id } = data;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteItem, setDeleteItem] = useState<boolean>(false);
  const [selecteDeleteID, setelecteDeleteID] = useState<number>();
  const [selecteDeleteText, setelecteDeleteText] = useState<string>();
  const paymentTermsData = watch('paymentTerms');
  const fieldPath = `paymentTerms.loadRateDTO.${foundIndex}`;

  const selectedExpenseCategoryId = watch(`${fieldPath}.expenseCategoryId`);
  const foundPaymentType = allPaymentTypes.find(
    (paymentType) => paymentType.id === data.payTypeId
  );

  const foundLoadRateDTO = paymentTermsData?.loadRateDTO?.find(
    (item) => item.payTypeId === data.payTypeId
  );

  let paymentTypeDisplayName = data.payType;
  let operationModeDisplayName = '';
  if (data.operationMode === OperationMode.SOLO) {
    operationModeDisplayName = 'Solo';
  } else if (data.operationMode === OperationMode.TEAM) {
    operationModeDisplayName = 'Team';
  }

  if (foundPaymentType) {
    paymentTypeDisplayName = foundPaymentType.itemName;
  }

  const isDeduction =
    data.payTypeId === foundPaymentType?.id && foundPaymentType?.deduction;

  if (isDeduction) {
    data.rate = Math.abs(data.rate!);
  }

  useEffect(() => {
    if (!selectedExpenseCategoryId) {
      return;
    }

    const name = getExpenseCategoryCodeById(
      expenseCategories,
      selectedExpenseCategoryId
    );

    setValue(`${fieldPath}.expenseCategoryKey`, name);
  }, [selectedExpenseCategoryId, fieldPath, expenseCategories, setValue]);

  const paymentTermDeleteAction: PaymentTermDeleteActionType = {
    label: 'Delete',
    icon: DeleteButtonIcon,
  };
  const isEditPermission = !getHasPermission({
    includes: [AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERM_EDIT],
  });

  const handleDeletePaymentTerm = (
    id: number | undefined,
    text: string | undefined,
    solo: string | undefined
  ) => {
    setDeleteItem(true);
    setelecteDeleteID(id);
    setelecteDeleteText(`${text} (${solo})`);
  };

  const handleMenuItemClick = (
    action: {
      icon: string;
      label: string;
      id: number;
      paymentTypeDisplayName: string;
      operationModeDisplayName: string;
    },
    accountData: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (action?.label !== 'Delete') return;
    handleDeletePaymentTerm(
      id,
      paymentTypeDisplayName,
      operationModeDisplayName
    );
    setAnchorEl(null);
  };

  const titleRenderer = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: (theme) => {
            return cardExpanded
              ? `${theme.palette.primary.contrast}`
              : `${theme.palette.grey['100']}`;
          },
        }}
      >
        <div>
          <Typography
            variant="h7"
            sx={{
              color: cardExpanded ? 'primary.main' : 'text.primary',
            }}
          >
            {`${paymentTypeDisplayName} (${operationModeDisplayName})`}
          </Typography>
          {!cardExpanded && (
            <div>
              <Typography variant="body3" sx={{ color: 'text.secondary' }}>
                {data.unit === 'PERCENT'
                  ? `${data.rate} % `
                  : `${amountFormat(
                      isDeduction
                        ? -+foundLoadRateDTO.rate
                        : foundLoadRateDTO.rate
                    )}`}
              </Typography>
            </div>
          )}
        </div>
        <Box
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '-16px',
          }}
        >
          <Permission
            includes={[AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERM_REMOVE]}
          >
            <>
              {!inactive && (
                <ThreeDotMenuIcon
                  menuOptions={[
                    {
                      id,
                      paymentTypeDisplayName,
                      operationModeDisplayName,
                      ...paymentTermDeleteAction,
                    },
                  ]}
                  open={Boolean(anchorEl)}
                  handleMenuClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                  }}
                  handleMenuClose={() => {
                    setAnchorEl(null);
                  }}
                  anchorEl={anchorEl}
                  handleMenuItemClick={handleMenuItemClick}
                />
              )}
            </>
          </Permission>
        </Box>
      </Box>
    );
  };
  const amoutLabeltext = paymentLineItemAmoutConfigText[data.unit];

  return (
    <Box
      sx={{
        border: (theme) => {
          return cardExpanded ? `1px solid ${theme.palette.primary.main}` : '';
        },
        borderRadius: '8px',
        padding: '5px 5px',
        marginTop: '20px',
        backgroundColor: (theme) => {
          return cardExpanded
            ? `${theme.palette.primary.contrast}`
            : `${theme.palette.grey['100']}`;
        },
        width: '100%',
      }}
    >
      <Accordion
        key={`accordion: ${id}`}
        name={`${data.payType} (${data.operationMode})`}
        summaryRenderer={titleRenderer}
        updateParentState={setCardExpanded}
      >
        <Select
          name={`${fieldPath}.expenseCategoryId`}
          label={t('expenseCategory') + '*'}
          fieldName={'itemName'}
          sizes={{ xs: expanded ? 3 : 6 }}
          options={expenseCategories}
          control={control}
          fieldValue={'id'}
          error={errors.expenseCategory}
          disabled={isEditPermission || inactive}
        />

        <TextField
          control={control}
          name={`${fieldPath}.rate`}
          label={
            amoutLabeltext && amoutLabeltext != ''
              ? amoutLabeltext
              : ' Rate (%)'
          }
          sizes={{ xs: 6 }}
          disabled={isEditPermission || inactive}
        />

        <TextField
          control={control}
          name={`${fieldPath}.description`}
          label="Description"
          sizes={{ xs: expanded ? 12 : 12 }}
          disabled={isEditPermission || inactive}
        />
      </Accordion>

      {isDeleteItem && (
        <DeletePopup
          open={isDeleteItem}
          onClose={() => setDeleteItem(false)}
          onAction={() => deletePaymentForLoad(selecteDeleteID)}
          title={'Delete Payment Terms'}
          subtitle={`Are you sure you want to delete  ${selecteDeleteText} ?`}
        />
      )}
    </Box>
  );
};

export const ItemsOfSectionSummary = ({
  addClickHandler,
  numberOfSectionItems,
  sectionName,
  inactive,
}: {
  addClickHandler: any;
  numberOfSectionItems: any;
  sectionName: any;
  inactive: boolean;
}) => {
  const { availablePayTypes } = React.useContext(FormContext);

  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      spacing={0}
      sx={{ minWidth: '410px' }}
    >
      <Grid
        item
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          {sectionName}
        </Typography>
        <Permission includes={[AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERM_ADD]}>
          <>
            {availablePayTypes.length > 0 && !inactive && (
              <Button
                onClick={addClickHandler}
                variant="outlined"
                style={{ minWidth: 35, marginLeft: 10 }}
              >
                <AddIcon />
              </Button>
            )}
          </>
        </Permission>
      </Grid>
      <Grid item>
        <Grid container direction={'column'} alignItems={'flex-end'}>
          <Grid item>
            <Typography variant="h6" sx={{ color: 'primary.main' }}>
              {numberOfSectionItems}
            </Typography>
          </Grid>

          <Grid item>
            {/* @ISSUE: Vasil */}
            <Typography variant="tooltip" sx={{ color: 'text.secondary' }}>
              {t('loadsTrackingTotalNum')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface AddNewItemHandlerCallback {
  (): void;
}

const sectionSummaryRenderer = (
  numberOfItemsInSection: number,
  sectionName: string,
  addNewItemHandler: AddNewItemHandlerCallback,
  inactive: boolean
) => {
  return (
    <ItemsOfSectionSummary
      addClickHandler={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        addNewItemHandler();
      }}
      numberOfSectionItems={numberOfItemsInSection}
      sectionName={sectionName}
      inactive={inactive}
    />
  );
};

const AccordionSection = (
  sectionName: string,
  savedPaymentTerms: PaymentPerLoad[],
  inactive: boolean
) => {
  const [showAddNewItemDialog, setShowAddNewItemDialog] = useState(false);
  const numberOfItemsInSection = savedPaymentTerms.length;
  const addNewItemHandlerCallback = useCallback(() => {
    setShowAddNewItemDialog(true);
  }, []);
  const { createNewPaymentTermForLoad, resetOperationModes, allPaymentTypes } =
    React.useContext(FormContext);

  const saveNewPaymentForLoad = useCallback(
    (paymentTerm: CreateDriverPaymentTerm) => {
      delete paymentTerm.availablePayTypes;
      delete paymentTerm.parent;
      const foundPaymentType = allPaymentTypes?.find(
        (item) => item.id === paymentTerm.payTypeId
      );
      if (foundPaymentType?.deduction && paymentTerm.rate) {
        paymentTerm.rate = -+paymentTerm.rate;
      }
      createNewPaymentTermForLoad(paymentTerm);
      setShowAddNewItemDialog(false);
      resetOperationModes();
    },
    [createNewPaymentTermForLoad]
  );

  let components: JSX.Element[] = [];
  if (numberOfItemsInSection) {
    components = savedPaymentTerms.map((paymentPerLoad, index) => {
      return (
        <PaymentPerLoadItem
          key={paymentPerLoad.id}
          data={paymentPerLoad}
          foundIndex={index}
          inactive={inactive}
        />
      );
    });
  }

  return (
    <>
      <Accordion
        summaryRenderer={() =>
          sectionSummaryRenderer(
            numberOfItemsInSection,
            sectionName,
            addNewItemHandlerCallback,
            inactive
          )
        }
        expanded
      >
        {numberOfItemsInSection ? components.map((item) => item) : null}
      </Accordion>
      {showAddNewItemDialog && (
        <FormDialog
          data={defaultDriverPaymentTerm}
          titleText="Add Payment Per Load "
          actionLabel="Add"
          open={showAddNewItemDialog}
          onAction={saveNewPaymentForLoad}
          handleClose={() => {
            setShowAddNewItemDialog(false);
            resetOperationModes();
          }}
          validationSchema={addNewPaymentPerLoadFormValidationSchema}
          contentRenderer={() => <AddNewPaymentPerLoadForm />}
        ></FormDialog>
      )}
    </>
  );
};

function PaymentTermForm({
  data,
  inactive,
}: {
  data: UserDetails;
  expanded: boolean;
  inactive?: boolean;
}) {
  const {
    savedLoadPaymentTerms,
    scheduledPayment,
    scheduledDeduction,
    userType,
  } = React.useContext(FormContext);
  const userPaymentTermPermission = {
    add: AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERM_ADD,
    remove: AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERM_REMOVE,
    view: AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERMS_VIEW,
    edit: AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERM_EDIT,
  };
  let paymentRecipientNoteLabel = 'paymentRecipientNoteForUser';
  if (data?.isDriver)
    paymentRecipientNoteLabel = 'paymentRecipientNoteForDriver';

  return (
    <>
      <Box
        sx={{
          borderRadius: '8px',
          padding: '5px 10px',
          marginTop: '20px',
          width: '100%',
          display: 'flex',
          gap: '16px',
          flexDirection: 'column',
        }}
      >
        <Typography color="text.secondary" variant="caption">
          {t(paymentRecipientNoteLabel)}
        </Typography>
      </Box>
      {AccordionSection('Payment Per Trip', savedLoadPaymentTerms, inactive!)}
      {ScheduledPaymentItem(
        'driver',
        scheduledPayment,
        'Payment',
        data.id,
        ScheduleAPITextConfig.Payment,
        userPaymentTermPermission,
        inactive
      )}
      {ScheduledPaymentItem(
        'driver',
        scheduledDeduction,
        'Deduction',
        data.id,
        ScheduleAPITextConfig.Deduction,
        userPaymentTermPermission,
        inactive
      )}
    </>
  );
}

const PaymentTermFormWithProvider = ({
  expanded,
  data,
  setUserDetails,
  handleChangeShowDetailsTab,
  dataDependency,
  inactive,
}: {
  expanded: boolean;
  data: UserDetails;
  handleChangeShowDetailsTab: (state: boolean) => void;
  setUserDetails: (value: React.SetStateAction<UserDetails>) => void;
  dataDependency: IPaymentTermDataDependency;
  inactive?: boolean;
}) => {
  return (
    <FormProvider
      setUserDetails={setUserDetails}
      data={data}
      dataDependency={dataDependency}
    >
      <Permission includes={[AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERMS_VIEW]}>
        <>
          <PaymentTermForm
            expanded={expanded}
            data={data}
            handleChangeShowDetailsTab={handleChangeShowDetailsTab}
            inactive={inactive!}
          />
        </>
      </Permission>
    </FormProvider>
  );
};

export default observer(PaymentTermFormWithProvider);
