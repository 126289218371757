export const MESSAGES = {
  cancelTitle: 'Are you sure you want to close the Optymization window?',
  cancelBodyText: 'You can resume this Optymization from the dispatch page.',
  saveTitle: 'Are you ready?',
  startNewOptimization: [
    'Crunching the numbers...',
    'Doing complicated math...',
    'Beep boop beep boop...',
    'Communicating with SkyNET...',
    'Trying to make all drivers happy...',
  ],
  resumeOptimization: [
    'Not doing the math again...',
    'Dusting off the old assignments...',
    'Copying my old work...',
  ],
};
