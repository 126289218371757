import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DetailsPanel from '../../../../common/DetailsPanel';
import DocumentDetailsForm from './DocumentDetailsForm';

import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { LineItem } from '../../../../types';
import { docType } from '../../../../utils/Doc';
import DocumentActions from '../DocumentActions';
import { entities } from '../constants';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { TerminalShort } from '../../../../models/common/modelsShort';
import LongMenu from '../../../../common/LongMenu';
import DeletePopup from '../../../../common/DeletePopup';
import AxeleDialog from '../../../../ui-kit/components/AxeleDialog';
import EmailDocument from './EmailDocument';
import { DocumentItemType } from '../../../../models';
import { getAccountTypeWithRoleCode, isHasPermission } from '../../../../utils';
import StorageManager from '../../../../StorageManager/StorageManager';

interface IProps {
  data: any;
  panelTitle: string;
  isCreatePanelOpen: boolean;
  onClose: () => void;
  onShare: (data: any) => void;
  onDelete: () => void;
  onDownload: () => void;
  documentLineItemsMap: { [key: string]: LineItem };
  editDocumentCallbacks: {
    editDocumentTypeCb: (
      oldId: string,
      newId: string,
      oldEntityType: string
    ) => void;
    editAssociatedTypeCb: (oldType: string, newType: string) => void;
    editAssociatedItemCb: (
      oldValue: { id: string },
      newType: { id: string },
      id: string,
      entityTypeKey: string,
      existingTypeKey: boolean
    ) => void;
    editTerminalCb?: (rowId: string) => void;
  };
  updateTable: (id: string, value: string | object, name: string) => void;
  setSelectedItem: (data: DocumentItemType) => void;
  isGlobal?: boolean;
  inactive?: boolean;
}

const DocumentDetailPanel: React.FC<IProps> = ({
  documentLineItemsMap,
  data,
  setSelectedItem,
  panelTitle,
  isCreatePanelOpen,
  onClose,
  onDelete,
  onDownload,
  editDocumentCallbacks,
  updateTable,
  isGlobal,
  inactive,
}) => {
  const { getAllTerminals } = useRootStore();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [file, setFile] = useState<docType | null>(null);
  const [formData, setFormData] = useState<{ [key: string]: any } | null>(null);
  const userStorageData = StorageManager.getUser();
  useEffect(() => {
    if (data.id) {
      const getFile = async () => {
        const result = await DocumentActions.downloadDocuments(
          [data.documentId],
          false,
          true
        );
        if (result) {
          setFile(result[0]);
        }
      };
      getFile();
    }
  }, [data.id]);

  useEffect(() => {
    const defaultData = {
      ownerName: data.ownerName,
      uploadDate: data.uploadDate,
      fileName: data.fileName,
      documentTypeId: data.documentTypeId,
      permission: data.permission === 'PUBLIC',
      terminalId:
        getAllTerminals.find(
          ({ id }: TerminalShort) => id === data.terminalId
        ) || null,
      entityTypes:
        entities.find((e) => e.key === data.attachedEntities?.[0]?.type) ||
        null,
      attachedEntities: data.attachedEntities?.[0]?.properties[0] || null,
      description: data.description,
    };
    setFormData(defaultData);
  }, [data]);

  const menuOptions = [
    { name: 'Share', action: () => setEmailDialogOpen(true) },
    { name: 'Download', action: () => onDownload() },
  ];

  const noPermissionForDeleteDocument = !isHasPermission([
    AXELE_PERMISSION_TYPE.DOCUMENT_REMOVE,
  ]);

  const actionsRenderer = () => {
    return (
      <>
        {!inactive && (
          <Permission contains={[AXELE_PERMISSION_TYPE.DOCUMENT_EDIT]}>
            <LongMenu
              color={'primary.contrast'}
              options={
                noPermissionForDeleteDocument
                  ? menuOptions
                  : [
                      ...menuOptions,
                      {
                        name: 'Delete',
                        action: () => setShowDeleteDialog(true),
                      },
                    ]
              }
            />
          </Permission>
        )}
      </>
    );
  };

  if (!formData) {
    return null;
  }

  return (
    <>
      <DetailsPanel
        darkHeader
        actionsRenderer={actionsRenderer}
        panelTitle={panelTitle}
        data={formData}
        entity="Document"
        onClose={onClose}
        isGlobal={isGlobal}
        contentRenderer={() => (
          <DocumentDetailsForm
            setSelectedItem={setSelectedItem}
            documentLineItemsMap={documentLineItemsMap}
            file={file}
            editDocumentCallbacks={editDocumentCallbacks}
            updateTable={updateTable}
            data={data}
            inactive={inactive}
          />
        )}
        isSaveIconVisible={!isCreatePanelOpen}
        footerRenderer={() => null}
        permissions={{
          save: [AXELE_PERMISSION_TYPE.DOCUMENT_EDIT],
        }}
      />
      {showDeleteDialog && (
        <DeletePopup
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          onAction={async () => {
            setShowDeleteDialog(false);
            await onDelete();
          }}
          defaultText
          entity="Document"
        />
      )}
      {emailDialogOpen && (
        <AxeleDialog
          open={emailDialogOpen}
          handleClose={() => setEmailDialogOpen(false)}
          renderComponent={
            <EmailDocument
              handleEmailDialogClose={() => setEmailDialogOpen(false)}
              ids={[data.documentId]}
            />
          }
          dialogPaperStyles={{
            backgroundColor: 'rgba(3, 14, 24, 0.89)',
            p: 0,
            '.MuiDialogContent-root': {
              m: 0,
              backgroundColor: 'rgba(3, 14, 24, 0.89)',
              height: 'unset',
            },
          }}
          dialogStyles={{ width: 1028, margin: '0 auto' }}
        />
      )}
    </>
  );
};

export default observer(DocumentDetailPanel);
