import {
  ContactsListDataTotal,
  ContractDetailsDTO,
  GetBestMatchContractParams,
  GetContractOneRequest,
  QueryParams,
} from '../../models';
import { Service } from './Service';

export abstract class IContactsService extends Service {
  abstract getContractList(
    requestData: QueryParams
  ): Promise<ContactsListDataTotal>;
  abstract getContractListName(
    requestData: QueryParams
  ): Promise<ContactsListDataTotal>;
  abstract getBestMatchContract(
    requestData: GetBestMatchContractParams,
    payload: GetBestMatchContractParams
  ): Promise<ContractDetailsDTO>;
  abstract getContractById(
    requestData: GetContractOneRequest
  ): Promise<ContractDetailsDTO>;
  abstract getContractByIdWithoutCancel(
    requestData: GetContractOneRequest
  ): Promise<ContractDetailsDTO>;
}
