import {
  SoloDriverIcon,
  TractorsIcon,
  TrailerIcon,
} from '../../../../ui-kit/components/Assets';

export enum EFinanceOverview {
  FinanceOverview = 'FinanceOverview',
  FinanceOverviewDetail = 'FinanceOverviewDetail',
  FinanceOverviewInvoice = 'FinanceOverviewInvoice',
}

type tplotOptions = {
  [key: string]: string;
};
type tplotOptionsStatus = {
  [key: string]: boolean;
};
export const financeLoadError = {
  loadRateType: {
    isrequired: false,
    errorText: 'Pay Type is required',
  },
  quantity: {
    isrequired: false,
    errorText: 'Value must be between 0.0001 and 99,999.9999',
    errorAmountText: 'Value must be between 0.0001 and 99,999.9999',
    errorPercentText: 'Value must be between 0.0001 and 100',
  },
  rate: {
    isrequired: false,
    errorText: 'Value must be between 0.0001 and 99,999.9999',
    errorAmountText: 'Value must be between 0.0001 and 99,999.9999',
    errorPercentText: 'Value must be between 0.0001 and 100',
  },
  expenseCategoryId: {
    isrequired: false,
    errorText: 'Pay Type is required',
  },
  amount: {
    isrequired: false,
    errorText: 'Value must be between 0.0001 and 99,999.9999',
    errorAmountText: 'Value must be between 0.0001 and 99,999.9999',
    errorPercentText: 'Value must be between 0.0001 and 100',
  },
};
export const financeLoadPayLoad = {
  amount: '',
  description: '',
  expenseCategoryId: '',
  expenseCategoryKey: '',
  id: '',
  loadRateType: '',
  loadRateTypeId: '',
  manuallyAdded: true,
  quantity: '',
  rate: '',
  unit: '',
};

export const paymentLineItemText: tplotOptions = {
  PER_STOP: 'Per Stop',
  PER_HOUR: 'Per Hour',
  PER_LOAD: 'Per Load',
  PER_MILE: 'Per Loaded mile',
  PER_DAY: 'Per Day',
  PER_OCCURENCE: 'Per Occurrence',
  PER_LOADED_MILE: ' Per Mile ($)',
  PER_DUTY_HOUR: 'Per Duty Hour',
  PERCENT: 'Percentage (%)',
};

export const paymentLineItemAmountText: tplotOptions = {
  PER_STOP: 'Rate Per Stop ($)',
  PER_HOUR: 'Rate Per Hour ($)',
  PER_LOAD: 'Rate Per Load ($)',
  PER_MILE: 'Rate Per Loaded mile ($)',
  PER_DAY: 'Rate Per Day ($)',
  PER_OCCURENCE: 'Rate Per Occurrence ($)',
  PER_LOADED_MILE: 'Rate Per Loaded Mile ($)',
  PER_DUTY: 'Rate Per Duty Hour ($)',
  PERCENT: ' (%)',
  PER_EMPTY_MILE: 'Rate Per Empty Mile ($)',
  PER_DUTY_HOUR: 'Rate Per Duty Hour ($)',
  PER_TOTAL_MILE: 'Rate Per Total Mile',
};

export const paymentTermsLineItemText: tplotOptions = {
  PER_STOP: ' Per Stop ',
  PER_HOUR: ' Per Hour ',
  PER_LOAD: ' Per Load ',
  PER_MILE: ' Per Loaded mile ',
  PER_DAY: ' Per Day',
  PER_LOADED_MILE: ' Per Loaded Mile',
  PER_OCCURENCE: ' Per Occurrence ',
  PER_DUTY_HOUR: ' Per Duty Hour ',
  PERCENT: ' Percent (%)',
};
export const paymentLineItemDescription: tplotOptions = {
  ADDITIONAL_STOP: 'Payment based on the additional stops done on the load',
  DETENTION: 'Payment based on detention  on load',
  DRIVER_ASSIST: 'Payment for driver-assist services',
  FLAT_RATE: 'Payment got from hauling the load',
  FUEL_SURCHARGE: 'Payment based on Fuel surcharge ',
  LAYOVER: 'Payment based on layover on load',
  LUMPER: 'Payment based on lumper for load',
  MISCELLANEOUS_DEDUCTION: 'Miscellaneous deduction on load',
  MISCELLANEOUS_PAYMENT: 'Miscellaneous payment on load',
  PER_EMPTY_MILE: 'Payment based on empty miles',
  PER_LOADED_MILE: 'Payment based on the loaded miles',
  PER_TOTAL_MILE: 'Payment based on Total miles ',
  REDELIVER: 'Payment for redelivery services',
  TONU: 'Truck Ordered Not Used (TONU)',
  REVENUE_SHARE: 'Payment based on percentage share of the load base revenue',
  PARTNER_CARRIER_PAYMENT: 'Expense related to carrier payment',
};
export const paymentLineItemUnitButton: tplotOptionsStatus = {
  ADDITIONAL_STOP: true,
  REVENUE_SHARE: true,
  DETENTION: true,
  DRIVER_ASSIST: true,
  FLAT_RATE: false,
  FUEL_SURCHARGE: true,
  LAYOVER: true,
  LUMPER: true,
  MISCELLANEOUS_DEDUCTION: false,
  MISCELLANEOUS_PAYMENT: false,
  PER_EMPTY_MILE: true,
  PER_LOADED_MILE: true,
  PER_TOTAL_MILE: true,
  REDELIVER: true,
  TONU: true,
};
export const paymentLineItemUnitPaymentTermButton: tplotOptionsStatus = {
  ADDITIONAL_STOP: true,
  DETENTION: true,
  DRIVER_ASSIST: true,
  FLAT_RATE: false,
  FUEL_SURCHARGE: true,
  LAYOVER: true,
  LUMPER: true,
  MISCELLANEOUS_DEDUCTION: false,
  MISCELLANEOUS_PAYMENT: false,
  PER_EMPTY_MILE: false,
  PER_LOADED_MILE: false,
  PER_TOTAL_MILE: false,
  REDELIVER: true,
  TONU: true,
  REVENUE_SHARE: true,
};

export const paymentLineItemHeadingText: tplotOptions = {
  ADDITIONAL_STOP: 'Additional Stop',
  DETENTION: 'Detention',
  DRIVER_ASSIST: 'Driver Assist',
  FLAT_RATE: 'Flat Rate',
  FUEL_SURCHARGE: 'Fuel Surcharge',
  LAYOVER: 'Layover',
  LUMPER: 'Lumper',
  MISCELLANEOUS_DEDUCTION: 'Miscellaneous Deduction',
  MISCELLANEOUS_PAYMENT: 'Miscellaneous Payment',
  PER_EMPTY_MILE: 'Per Empty Mile',
  PER_LOADED_MILE: 'Per Loaded Mile',
  PER_TOTAL_MILE: 'Per Total Mile',
  REDELIVER: 'Redeliver',
  TONU: 'Truck Ordered Not Used (TONU)',
  REVENUE_SHARE: 'Revenue Share',
};

export const paymentLineItemTextFeilds: tplotOptionsStatus = {
  ADDITIONAL_STOP: true,
  DETENTION: true,
  DRIVER_ASSIST: true,
  FLAT_RATE: false,
  FUEL_SURCHARGE: true,
  LAYOVER: true,
  LUMPER: true,
  MISCELLANEOUS_DEDUCTION: false,
  MISCELLANEOUS_PAYMENT: false,
  PER_EMPTY_MILE: true,
  PER_LOADED_MILE: true,
  PER_TOTAL_MILE: true,
  REDELIVER: true,
  TONU: true,
  REVENUE_SHARE: true,
};

export const paymentLineItemFieldText: tplotOptions = {
  ADDITIONAL_STOP: 'Rate Per Stop',
  DETENTION: 'Rate Per Hour',
  DRIVER_ASSIST: 'Rate Per Load',
  FLAT_RATE: 'Rate Per Load',
  FUEL_SURCHARGE: 'Rate Per Mile',
  LAYOVER: ' Rate Per Day',
  LUMPER: 'Rate Per Load',
  MISCELLANEOUS_DEDUCTION: 'Deduction',
  MISCELLANEOUS_PAYMENT: 'Payment',
  PER_EMPTY_MILE: 'Rate Per Empty Mile',
  PER_LOADED_MILE: 'Rate Per Loaded Mile',
  PER_TOTAL_MILE: 'Rate Per Total Mile',
  REDELIVER: 'Rate Per Occurrence',
  TONU: 'Rate Per Load',
  REVENUE_SHARE: 'Rate Per Load',
};

export const paymentLineItemAmoutConfigText: tplotOptions = {
  PER_STOP: 'Amount ($)',
  PER_HOUR: 'Amount ($)',
  PER_LOAD: 'Amount ($)',
  PER_MILE: 'Amount ($)',
  PER_DAY: 'Amount ($)',
  PER_OCCURENCE: 'Amount ($)',
  PER_LOADED_MILE: 'Rate Per Loaded Mile ($)',
  PER_DUTY: 'Amount ($)',
  PERCENT: 'Rate (%)',
  PER_TOTAL_MILE: 'Rate Per Total Mile ($)',
  PER_EMPTY_MILE: 'Rate Per Empty Mile ($)',
  PER_DUTY_HOUR: 'Rate Per Duty Hour ($)',
};

type objectKeyCampare = {
  [key: string]: objectKeyCampareType;
};

type objectKeyCampareType = {
  name?: string;
  keyLable: string;
  icon?: any;
};
export const selectedEntityObjectFinanceOverView: objectKeyCampare = {
  Driver: {
    name: 'Driver',
    icon: <SoloDriverIcon color="#2B64CB" height={20} width={20} />,
    keyLable: '',
  },
  Tractor: {
    name: 'Tractor',
    icon: <TractorsIcon color="#2B64CB" height={20} width={20} />,
    keyLable: '',
  },
  Dispatcher: {
    name: 'Dispatcher',
    icon: <TrailerIcon color="#2B64CB" height={20} width={20} />,
    keyLable: '',
  },
};

export const dispatcherPayType: tplotOptions = {
  REVENUE_SHARE: 'REVENUE_SHARE',
  FLAT_RATE: 'FLAT_RATE',
};

export const statusNameKey: tplotOptions = {
  PENDING: 'Pending',
  'IN REVIEW': 'In Review',
  Reviewed: 'Reviewed',
  CLOSED: 'Closed',
  Pending: 'Pending',
  'In Review': 'In Review',
  REVIEWED: 'REVIEWED',
  Closed: 'Closed',
};

export const paymentLineItemPerLoadQuantity: tplotOptionsStatus = {
  ADDITIONAL_STOP: false,
  DETENTION: false,
  DRIVER_ASSIST: true,
  FLAT_RATE: false,
  FUEL_SURCHARGE: false,
  LAYOVER: false,
  LUMPER: true,
  MISCELLANEOUS_DEDUCTION: false,
  MISCELLANEOUS_PAYMENT: false,
  PER_EMPTY_MILE: false,
  PER_LOADED_MILE: false,
  PER_TOTAL_MILE: false,
  REDELIVER: false,
  TONU: true,
  REVENUE_SHARE: false,
};
export const paymentRateCompare = [
  'FLAT_RATE',
  'MISCELLANEOUS_DEDUCTION',
  'MISCELLANEOUS_PAYMENT',
];
