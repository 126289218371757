import PopupMap from '../../../../common/Ui/Maps/PopupMap/PopupMap';

interface LoadboardMapProps {
  open: boolean;
  close: () => void;
  mapData: any;
}

const LoadboardMap = ({ open, close, mapData }: LoadboardMapProps) => {
  return (
    <PopupMap
      openPopup={open}
      handleClose={close}
      markersList={mapData ? mapData.markers : []}
      generateRoute
    />
  );
};

export default LoadboardMap;
