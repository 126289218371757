import { observer } from 'mobx-react';
import { CarrierStatus } from '../../../../../common/Ui/StatusComponent/StatusComponent';
import { CarrierStaticDataDTO } from '../../../../../models/DTOs/Carrier/Requests';
import { useStores } from '../../../../../store/root.context';

export const CarrierStaticDataCell = observer(
  ({
    staticDataKey,
    staticDataValue,
  }: {
    staticDataKey: keyof CarrierStaticDataDTO;
    staticDataValue: string | number;
  }) => {
    const {
      carrierStore: { staticDataDTO },
    } = useStores();
    const option = staticDataDTO[staticDataKey].find(
      ({ key }) => key === staticDataValue
    );
    if (!option) return <></>;
    if (staticDataKey === 'carrierStatus') {
      return <CarrierStatus status={option.key} />;
    } else {
      return <>{option.value}</>;
    }
  }
);
