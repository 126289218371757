import { Type } from 'class-transformer';
import { SortResponse } from '../commonMixed';
import { PhoneDTO } from '../Contacts/Contacts';

export class ContactSummary {
  name!: string;
  description!: string;
  phoneData!: PhoneDTO;
  email!: string;
  isPrimary!: boolean;
  seqNumber!: number;
}

export class VendorSummaryDTO {
  id!: string;
  seqNumber!: number;
  organizationId!: number;
  name!: string;
  address!: string;
  addressLine1!: string;
  addressLine2!: string;
  city!: string;
  state!: string;
  zip!: string;
  notes!: string;
  @Type(() => ContactSummary)
  contacts!: ContactSummary[];
}

export class PaginatedVendorsList {
  @Type(() => VendorSummaryDTO)
  content!: VendorSummaryDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export type vendorListRequestType = {
  pageNumber: number;
  pageSize: number;
  sort: string;
};

export type vendorFiltersRequestType = {
  vendorAddress: string[] | null;
  vendorContactNames: string[] | null;
  vendorNames: string[] | null;
  preferredProhibitedEnum: string[] | null;
  gridColumnMetadataList?: string[];
  vendorIds?: string[];
};

export type vendorListExcelRequestType = {
  sort: string;
  pageNumber: number;
  pageSize: number;
  gridColumnMetadataList: string[];
};
