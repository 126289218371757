import React from 'react';
import ChooseViewModal from './components/ChooseViewModal';
import { View, ViewMap } from '../../types';
import ViewDataModalV2 from './components/ViewDataModalV2';

const AddViewV2 = ({
  title,
  contentRenderer,
  onAddViewPanelClose,
  onCreate,
  onCopy,
  formDefaultValuesMap,
  addViewPanelOpen,
  getAvailableViews,
  defaultEntity,
  component,
  handleResetCustomErrors,
}: {
  title: string;
  defaultEntity: string;
  addViewPanelOpen: boolean;
  contentRenderer: () => JSX.Element;
  onCreate: (data: View, cb: (data: View) => void) => void;
  onCopy: (id: string) => void;
  onAddViewPanelClose: () => void;
  formDefaultValuesMap: ViewMap;
  getAvailableViews: () => Promise<View[] | undefined>;
  component: any;
  handleResetCustomErrors: () => void;
}) => {
  const [openViewForm, setOpenViewForm] = React.useState<boolean>(false);
  const handleViewFormOpen = (): void => setOpenViewForm(true);
  const handleViewFormClose = (): void => {
    setOpenViewForm(false);
    handleResetCustomErrors();
  };

  const handleViewSelect = (viewId: string | undefined) => {
    if (!viewId) {
      handleViewFormOpen();
    } else {
      onCopy(viewId);
    }
    onAddViewPanelClose();
  };

  return (
    <>
      {addViewPanelOpen && (
        <ChooseViewModal
          handleViewSelect={handleViewSelect}
          open={addViewPanelOpen}
          onClose={onAddViewPanelClose}
          getAvailableViews={getAvailableViews}
        />
      )}
      {openViewForm && (
        <ViewDataModalV2
          component={component}
          handleClose={handleViewFormClose}
          open={openViewForm}
          title={title}
          contentRenderer={contentRenderer}
          onSave={onCreate}
          formDefaultValuesMap={formDefaultValuesMap}
          defaultEntity={defaultEntity}
        />
      )}
    </>
  );
};

export default AddViewV2;
