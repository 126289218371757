export const UPLOAD_BACKEND_ERROR_MESSAGE =
  'Upload Failed. The file cannot be uploaded at the moment. Please try again';

export const SUPPORTED_FILE_SIZE = 5242880;

export const colorsMap = {
  success: '#3B873E',
  warning: '#ED6C02',
  error: '#F44336',
};

export const textsMap = {
  success: '#3B873E',
  warning: '#C77700',
  error: '#E31B0C',
};

export const possibleDateFormats = [
  'MM/dd/yyyy',
  'MM/dd/yy',
  'dd/MM/yy',
  'yy/MM/dd',
  'dd/MM/yyyy',
  'yyyy/MM/dd',
];

export const dateMaps: { [key: string]: string } = {
  'MM/dd/yyyy': 'MM/DD/YYYY',
  'MM/dd/yy': 'MM/DD/YY',
  'dd/MM/yy': 'DD/MM/YY',
  'yy/MM/dd': 'YY/MM/DD',
  'dd/MM/yyyy': 'DD/MM/YYYY',
  'yyyy/MM/dd': 'YYYY/MM/DD',
};

export const importDefaultDateType = 'MM/dd/yyyy';

export const possibleDeductionTypes = [
  'Create Tractor Deductions for Imported Expense',
  'Create Driver Deduction for Imported Expense',
];

export const possibleLineItemTypes = [
  'City Based',
  'Zip Based',
  'Mileage Based',
];

export const possibleDeductionTypesMap: Record<string, string> = {
  'Create Tractor Deductions for Imported Expense': 'TRACTOR',
  'Create Driver Deduction for Imported Expense': 'DRIVER',
};

export const rowsDataMap: { [key: string]: string } = {
  customer: '10,000',
  tractor: '10,000',
  trailer: '10,000',
  vendor: '10,000',
  location: '10,000',
  driver: '10,000',
  load: '500',
  expense: '10,000',
};
