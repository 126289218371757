import React, { FC } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import ProviderHero from '@/ui-kit/components/ProviderHero';
import { EFSLogo } from '@/_assets/static/svgs/EFS';
import { ProviderInfo } from '@/views/settings/integrations/FuelCard/components/ProviderInfo';
import CardWithIcon from '@/ui-kit/components/CardWithIcon';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomerFeatures from '@/ui-kit/components/CustomerFeatures';
import { CredentialsDrawer } from '@/views/settings/integrations/FuelCard/components/CredentialsDrawer';
import { useProviderDetails } from '@/views/settings/integrations/FuelCard/hooks';
import { CardsList } from '@/views/settings/integrations/FuelCard/components/CardsList';
import { Provider } from '@/models/DTOs/FuelCards/FuelCards';
import { FuelCardProvidersMap } from '@/views/settings/integrations/FuelCard/constants';
import wex from '@/_assets/images/Wex.png';

interface Props {
  selectedProvider: Provider;
  getProviders: () => void;
}

export const ProviderDetails: FC<Props> = ({
  selectedProvider,
  getProviders,
}) => {
  const {
    openDrawer,
    onClose,
    onOpen,
    handleCredentialsSubmit,
    form,
    accounts,
    handleAccountDelete,
    activeAccount,
    handleSetActiveAccount,
    getAccounts,
  } = useProviderDetails(selectedProvider, getProviders);

  const selectedProviderInfo =
    FuelCardProvidersMap[selectedProvider.providerId];

  return (
    <>
      <Box className="selected-customer-block">
        <ProviderHero
          currentCustomerData={{
            logoWhite: selectedProviderInfo.logoWhite,
            status: selectedProvider.status,
            gradient: selectedProviderInfo.gradient,
          }}
        />
        <Stack
          sx={{
            p: '50px 20px 0 30px',
            '.MuiBackdrop-root.MuiModal-backdrop.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop':
              {
                backgroundColor: 'transparent !important',
              },
          }}
        >
          <Box>
            <ProviderInfo selectedProvider={selectedProvider} />
            <Box className="accounts-section">
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                {`${accounts.length} Accounts`}
              </Typography>
              <Grid
                container
                sx={{ paddingTop: '32px', justifyContent: 'space-between' }}
              >
                <CardsList
                  selectedProvider={selectedProvider}
                  handleSetActiveAccount={handleSetActiveAccount}
                  accounts={accounts}
                  onAccountDelete={handleAccountDelete}
                  onDrawerOpen={onOpen}
                  getAccounts={getAccounts}
                />

                <CardWithIcon
                  cardAction={onOpen}
                  iconStyles={{ color: 'primary.main' }}
                  icon={AddOutlinedIcon}
                  cardSubtitleStyle={{
                    color: 'primary.main',
                    fontSize: 20,
                    fontWeight: 400,
                  }}
                />
              </Grid>
            </Box>

            <CustomerFeatures
              features={['SYNC_TRANSACTION', 'TRANSACTION_UPDATES']}
            />
          </Box>
        </Stack>
      </Box>
      <CredentialsDrawer
        form={form}
        activeAccount={activeAccount}
        onSubmit={handleCredentialsSubmit}
        open={openDrawer}
        onClose={onClose}
        logo={
          <img
            style={{ marginLeft: '-18px' }}
            width={300}
            height={300}
            src={wex}
            alt="wex"
          />
        }
      />
    </>
  );
};
