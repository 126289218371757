import { Box, Skeleton, SxProps, Typography, useTheme } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridCellEditStartParams,
  GridColumnVisibilityModel,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { GridEventListener } from '@mui/x-data-grid/models/events';
import { GridSlotsComponent } from '@mui/x-data-grid/models/gridSlotsComponent';
import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BulkAction from '../../ui-kit/components/DataGridPro/BulkAction';
import { GridThreeDotMenuProps } from '../../ui-kit/components/DataGridPro/components/ThreeDotMenu';
import SummaryColumn from '../../ui-kit/components/DataGridPro/footer/SummaryColumn';
import { getCommonDataGridStyles } from '../../ui-kit/components/DataGridPro/styles/CommonDataGrid.styles';
import {
  disableSelectionOnClickStyle,
  getcardViewStyles,
} from '../../ui-kit/components/DataGridPro/styles/grid.style';
import { preProcessTableColumnsData } from '../../ui-kit/components/DataGridPro/utils';
import { NoRowsOverlay } from '../../views/loadboards/LoadboardDataGrid/NoRowsOverlay';
import BouncingDotsLoader from '../bouncing_dots_loader';

const LoadingSkeleton = () => (
  <Box
    sx={{
      height: 'max-content',
    }}
  >
    {[...Array(150)].map((_, key) => (
      <Skeleton key={key} variant="rectangular" sx={{ my: 4, mx: 1 }} />
    ))}
  </Box>
);

function DetailPanelContent({ row, columns }: any) {
  // subRows property for array of sub table rows
  const displayRows = row.subRows || [row];

  return (
    <DataGridPro
      density="compact"
      columns={columns}
      rows={displayRows}
      sx={{ flex: 1 }}
    />
  );
}

export interface CommonDataGridProps extends DataGridProProps {
  selectRow?: (newSelectedIds: GridSelectionModel) => void;
  totalDataInTable?: ReactNode | null;
  totalNumberOfRows?: ReactNode | null;
  colVisibilityModel?: GridColumnVisibilityModel;
  isExpandable?: boolean;
  hideNumericColumnsCount?: boolean;
  getSelectedRowsCount?: (newSelectedIdsLength: number) => void;
  isCardView?: boolean;
  gridCustomeStyle?: any;
  disableSelectionRowStyle?: boolean;
  handleSelectItem?: DataGridProProps['onRowClick'];
  selectedRowId?: any;
  subTableColumnsData?: any[];
  customGridColumnMenu?: GridSlotsComponent['ColumnMenu'];
  primaryColumn3DotMenu?: GridThreeDotMenuProps;
  onCellClick?: any;
  lazyLoadingSettings?: any;
  slots?: Partial<GridSlotsComponent>;
  bulkAction?: {
    iconName: string;
    iconSource: string;
    label: string;
    onClick?: () => void;
    childOptions?: any;
  }[];
  containerStyles?: SxProps;
  showFocus?: boolean;
  handleCellEditStart?: (params?: GridCellEditStartParams, event?: any) => void;
  rowsCaching?: boolean;
}

const CommonDataGrid: FC<CommonDataGridProps> = ({
  columns,
  rows,
  totalNumberOfRows,
  colVisibilityModel,
  checkboxSelection = false,
  selectionModel,
  isExpandable = false,
  hideNumericColumnsCount = false,
  totalDataInTable,
  onCellDoubleClick,
  selectRow,
  getSelectedRowsCount,
  pinnedColumns,
  isCardView = false,
  gridCustomeStyle,
  disableSelectionRowStyle = false,
  handleSelectItem,
  onCellClick,
  onColumnOrderChange,
  selectedRowId,
  pinnedRows,
  subTableColumnsData = [],
  customGridColumnMenu,
  primaryColumn3DotMenu = undefined,
  componentsProps,
  bulkAction,
  showFocus = false,
  slots,
  containerStyles = {},
  handleCellEditStart,
  rowsCaching = true,
  lazyLoadingSettings = undefined,
  ...props
}) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<
    GridColumnVisibilityModel | undefined
  >();
  const theme = useTheme();
  const cardViewStyles = isCardView ? getcardViewStyles(theme, isCardView) : {};
  const commonDataGridStyles = getCommonDataGridStyles(theme, showFocus);
  useEffect(() => {
    if (colVisibilityModel) {
      setColumnVisibilityModel(colVisibilityModel);
    } else setColumnVisibilityModel(getColumnVisibiltyModal());
  }, [colVisibilityModel, columns]);

  function getColumnVisibiltyModal() {
    const visibiltyModal: { [key: string]: boolean } = {};
    columns &&
      columns.forEach((column: any) => {
        visibiltyModal[column.field] = column.hide ? false : true;
      });
    return visibiltyModal;
  }

  function fetchNumericColumns() {
    return columns.filter((column: any) => column.type === 'number');
  }

  const handleCellClick: GridEventListener<'cellClick'> = (
    params,
    event,
    details
  ) => {
    if ('isHyperLink' in params.colDef && onCellClick) {
      event.stopPropagation();
      return onCellClick(params, event, details);
    }
    // move this string in to constants
    if (params.field === '__check__') {
      event.stopPropagation();
      // onShowPanel(false);
    } else {
      // onShowPanel(true);
    }
  };

  const numberOfSelections =
    (Array.isArray(selectionModel) && selectionModel?.length) || 0;
  const Footer = () => {
    const { t, ready } = useTranslation();
    return (
      <>
        <div
          style={{
            padding: '0px 2px',
            bottom: '0',
            width: '100%',
            zIndex: 1,
            backgroundColor: '#F7F7F7',
          }}
        >
          <Typography
            variant="caption"
            style={{
              fontSize: '12px',
              color: 'rgba(0, 17, 34, 0.75)',
              lineHeight: '20px',
              fontWeight: 400,
            }}
          >
            {t('total')}:
            {` ${
              totalDataInTable
                ? totalDataInTable
                : totalNumberOfRows || rows?.length
            } `}
            {t('rows')}
          </Typography>
          {numberOfSelections > 0 && (
            <Typography
              variant="caption"
              style={{
                fontSize: '12px',
                color: 'rgba(0, 17, 34, 0.75)',
                lineHeight: '20px',
                fontWeight: 400,
              }}
            >
              ({t('selected')}: {numberOfSelections} {t('rows')})
            </Typography>
          )}
        </div>
        {!hideNumericColumnsCount && (
          <div
            style={{
              padding: '0px 2px',
              display: 'flex',
              flexDirection: 'row',
              marginRight: '2px',
            }}
          >
            {fetchNumericColumns().map((column: any) => (
              <SummaryColumn
                numericColumnField={column}
                tableData={rows}
                columnTotalData={columns}
                key={column.field}
              />
            ))}
          </div>
        )}
      </>
    );
  };
  const LoadingOverlay = Boolean(rows?.length)
    ? () => (
        <Box sx={{ height: '100%', width: '100%', mt: '40vh' }}>
          <BouncingDotsLoader />
        </Box>
      )
    : LoadingSkeleton;

  const MemorizedColumns = useMemo(() => columns, [JSON.stringify(columns)]);
  const MemorizedTableData = useMemo(() => rows, [rows]);
  const MemorizedColVisibility = useMemo(
    () => columnVisibilityModel,
    [columnVisibilityModel]
  );
  return (
    <Box sx={{ pt: 1, flexGrow: 1, m: 0, ...containerStyles }}>
      <DataGridPro
        {...props}
        headerHeight={65}
        sx={() => ({
          ...cardViewStyles,
          ...commonDataGridStyles,
          ...gridCustomeStyle,
          ...(disableSelectionRowStyle && disableSelectionOnClickStyle),
        })}
        getRowClassName={(params) =>
          `paxton-table--row ${
            params.row.id === selectedRowId ? 'Mui-selected2' : ''
          }, ${params.row.pinned ? 'Mui-pinned' : ''}`
        }
        getDetailPanelContent={
          isExpandable
            ? ({ row }) => (
                <DetailPanelContent row={row} columns={subTableColumnsData} />
              )
            : undefined
        }
        columnBuffer={2}
        columnThreshold={2}
        initialState={{
          pinnedColumns: pinnedColumns,
        }}
        components={{
          Footer: Footer,
          ColumnMenu: customGridColumnMenu,
          ...slots,
          NoRowsOverlay: slots?.NoRowsOverlay || NoRowsOverlay,
          LoadingOverlay: slots?.LoadingOverlay || LoadingOverlay,
        }}
        density={'compact'}
        rows={rowsCaching ? MemorizedTableData : rows}
        columns={
          primaryColumn3DotMenu
            ? preProcessTableColumnsData({
                primaryColumn3DotMenu,
                tableColumnsData: MemorizedColumns,
              })
            : MemorizedColumns
        }
        columnVisibilityModel={
          colVisibilityModel ? MemorizedColVisibility : undefined
        }
        onCellEditStart={handleCellEditStart}
        onColumnOrderChange={onColumnOrderChange}
        onRowClick={handleSelectItem}
        rowHeight={45}
        checkboxSelection={checkboxSelection}
        pinnedRows={pinnedRows}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectedIds: GridSelectionModel) => {
          if (getSelectedRowsCount) {
            getSelectedRowsCount(newSelectedIds?.length);
          }
          selectRow?.(newSelectedIds);
        }}
        onCellClick={onCellClick || handleCellClick}
        showColumnRightBorder={false}
        showCellRightBorder={false}
        componentsProps={componentsProps}
        disableVirtualization={false}
        experimentalFeatures={{
          rowPinning: true,
        }}
      />
      {Boolean(bulkAction) && numberOfSelections > 0 && (
        <BulkAction bulkAction={bulkAction} />
      )}
    </Box>
  );
};

export default CommonDataGrid;
