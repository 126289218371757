import { FullscreenOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, SxProps, Typography } from '@mui/material';
import { ModalTitleStyles } from '../styles';
import MinimizeIcon from '@mui/icons-material/Minimize';
interface IHeader {
  title?: string;
  onClose?: () => void;
  containerStyles?: object;
  showCloseIcon?: boolean;
  titleStyles?: SxProps;
}

const Header: React.FC<IHeader> = (props) => {
  return (
    <Box
      display={'flex'}
      sx={{ padding: '10px 32px', ...props?.containerStyles }}
    >
      <Typography sx={{ ...ModalTitleStyles, ...props?.titleStyles }}>
        {props?.title || 'Load Ai'}
      </Typography>
      <Box ml="auto">
        {props?.showCloseIcon ? (
          <CloseOutlinedIcon
            sx={{ color: '#FFF', cursor: 'pointer' }}
            onClick={props?.onClose}
          />
        ) : (
          <MinimizeIcon
            sx={{ color: '#FFF', cursor: 'pointer' }}
            onClick={props?.onClose}
          />
        )}
      </Box>
    </Box>
  );
};

export default Header;
