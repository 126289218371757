import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { emailService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import { GetEmailSecondaryReceiversRequest } from '../../models/DTOs/Email/Requests';
import { AutocomplateWithChip } from '../Ui/AutocomplateWithChip';

export const removeNonExistingEmails = (emails: string[]) =>
  emails.filter((email) => !!email);

export default function EmailReceivers({
  toOptions = [],
  ccOptions = [],
  hideTo = false,
  hideCc = false,
}: PropTypes) {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    const getSecondaryReceivers = async () => {
      const request = new GetEmailSecondaryReceiversRequest();
      const result = await emailService.getSecondaryReceivers(request);
      if (result instanceof ServiceError) {
        return;
      }
      setValue('cc', result);
    };
    getSecondaryReceivers();
  }, []);

  return (
    <>
      {!hideTo && (
        <Box>
          <AutocomplateWithChip
            control={control}
            name="to"
            label="To"
            fieldName="email"
            fieldValue="email"
            options={removeNonExistingEmails(toOptions)}
            variant="standard"
            freeSolo
          />
        </Box>
      )}
      {!hideCc && (
        <Box>
          <AutocomplateWithChip
            control={control}
            name="cc"
            label="CC"
            fieldName="email"
            fieldValue="email"
            options={removeNonExistingEmails(ccOptions)}
            variant="standard"
            freeSolo
          />
        </Box>
      )}
    </>
  );
}

export type PropTypes = {
  toOptions?: any[];
  ccOptions?: any[];
  hideTo?: boolean;
  hideCc?: boolean;
};
