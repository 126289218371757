import { AdapterMoment } from '@mui/x-date-pickers-v5/AdapterMoment';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { useMemo } from 'react';
import { themeOptions } from '../../shared/theme';

export const renderLabel = (label: string): JSX.Element => {
  const lastLetter = label.length - 1;
  return label[lastLetter] === '*' ? (
    <div>
      {label.slice(0, lastLetter)}
      <span
        style={{ color: themeOptions.palette.error.main, display: 'inline' }}
      >
        *
      </span>
    </div>
  ) : (
    <div>{label}</div>
  );
};

export const makeAsteriskRed = {
  //TODO refactoring moved to src/ui-kit/theme/components/MuiFormLabel.ts
  // '.MuiFormLabel-asterisk': {
  //   color: themeOptions.palette.error.main,
  // },
};

export const toTimezoneMoment = (
  value?: Moment | null,
  timezone?: string
): Moment | null => {
  const date = moment(value);
  if (date.isValid()) {
    if (isTimezoneValid(timezone)) {
      return date.tz(timezone as string);
    }
    return date;
  }
  return value || null;
};

export class TimezoneAdapterMoment extends AdapterMoment {
  getDiff = (date: Moment, comparing: Moment | string) => {
    return typeof comparing === 'string'
      ? date.diff(comparing)
      : date.diff(comparing) ||
          (date.format('Z') !== comparing.format('Z') ? 1 : 0);
  };
}

export const isTimezoneValid = (timezone?: string | null) =>
  timezone && moment.tz.zone(timezone);

export const useTimezoneAdapterMoment = (
  timezone?: string
): typeof AdapterMoment => {
  return useMemo(
    () =>
      class EnhancedTimezoneAdapterMoment extends TimezoneAdapterMoment {
        parse = (value: string, format: string): Moment | null => {
          if (value === '') {
            return null;
          }
          if (isTimezoneValid(timezone))
            return this.moment.tz(value, format, true, timezone);
          return this.moment(value, format, true);
        };

        date = (value?: any): Moment | null => {
          if (value === null) {
            return null;
          }

          let result;
          if (isTimezoneValid(timezone)) {
            result = this.moment.tz(value, timezone);
          } else {
            result = this.moment(value);
          }
          result.locale(this.locale as any);

          return result;
        };
      },
    [timezone]
  );
};
