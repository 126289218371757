import React, { useEffect, useState } from 'react';
import Sidedrawer from '../FactoreSidedrawer';

import CustomerFeatures from '../../../../../ui-kit/components/CustomerFeatures';
import ProviderHero from '../../../../../ui-kit/components/ProviderHero';
import ProviderDescription from '../../../../../ui-kit/components/ProviderDescription';
import IntegrationActivationPanel from '../../../../../ui-kit/components/IntegrationActivationPanel';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import AccountsSection from '../../../../../ui-kit/components/IntegrationAccountCards/AccountsSection';

import { Box, Grid, Stack } from '@mui/material';

import {
  IcustomerFeaturesProps,
  IeldCustomerFeatures,
  IeldProviderData,
} from './Refresh';
import { financeIntegrationService } from '../../../../../api';
import { integrationFormFieldList } from '../../../../../utils/factoringViewData';
import _ from 'lodash';
import { ActivateFactoringCustomerRequest } from '../../../../../models/DTOs/FinanceIntegration/financeIntegration';
import MappingFactoring from './MappingFactoring';
import { manageIntegrationTabs } from '../ManageFactoreIntegration/integrationData';
import { getHasPermission } from '../../../../../common/Permission/utils/helperUtils';
import { AXELE_PERMISSION_TYPE } from '../../../../../common/Permission';
import moment from 'moment';
import { CardDataDTO } from '../../../../../models';
import { EMenuAction } from '../FactoreSidedrawer/FactoreUtil';
import { constantFactorText } from '../constants';
import {
  APEX_PROVIDER_ID,
  OTHER_PROVIDER_ID,
} from '../../../../../subPages/invoices/constants';

const mountPoint = document.createElement('div');
document.body.appendChild(mountPoint);
export default function SelectedCustomerFactoring({
  currentCustomerData,
  eldProviderData,
  refreshFactoringData,
}: IcustomerFeaturesProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [features, setFeatures] = useState([]);
  const [integrationRequiredCredentials, setIntegrationRequiredCredentials] =
    useState([]);
  const [selectedCardData, setselectedCardData] = useState([]);
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [editId, setEditId] = useState('');
  const [phoneExtesion, SetPhoneExtesion] = useState({});
  const [providerData, SetProviderData] =
    useState<IeldProviderData>(eldProviderData);
  const [archiveList, SetArchiveList] = useState<IeldProviderData>();
  const [isDeActivatePopupView, setDeActivatePopupView] =
    useState<boolean>(false);
  const [isDeletePopupView, setDeletePopupView] = useState<boolean>(false);
  const [isArchiveAccountView, setArchiveAccountView] =
    useState<boolean>(false);
  const [payLoad, setPayLoad] = useState<ActivateFactoringCustomerRequest[]>(
    []
  );
  const [selectedTab, setSelectedTab] = useState<string>(
    manageIntegrationTabs[0].key
  );
  const [selectedMapName, setSelectedMapName] = useState<string>('');
  const [resetValidation, setResetValidation] = useState<boolean>(false);
  const [isReauthenticate, setIsReauthenticate] = useState<boolean>(false);
  const [isActiveAccount, setActiveAccount] = useState<boolean>(false);

  useEffect(() => {
    getIntegrationRequiredCredentials();
    getCustomerFeatures();
    SetProviderData(eldProviderData);
    const archiveList = _.cloneDeep(eldProviderData);
    archiveList.integrationsDTO = archiveList?.archiveIntegrationsDTO;
    SetArchiveList(archiveList);
  }, [currentCustomerData, eldProviderData]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    setIsEditForm(false);
    setEditId('');
    if (!newOpen) {
      setResetValidation(true);
      refreshFactoringData();
    } else {
      setResetValidation(false);
      getIntegrationRequiredCredentials();
    }
  };

  const getCustomerFeatures = (): void => {
    const currentCustomerFeatures = providerData?.providerFeatures?.filter(
      (item: IeldCustomerFeatures) =>
        item.providerId === currentCustomerData.providerId
    );
    currentCustomerFeatures?.length > 0 &&
      setFeatures(currentCustomerFeatures[0]?.supportedFeatures);
  };

  const getIntegrationRequiredCredentials = (): void => {
    const integrationFormFieldListData = _.cloneDeep(integrationFormFieldList);
    setIntegrationRequiredCredentials(
      integrationFormFieldListData[currentCustomerData.providerId]
    );
  };

  const editAccount = async (cardData: any): Promise<void> => {
    const updatedObject = integrationRequiredCredentials;
    updatedObject?.map((item, index) =>
      item.fields?.map((data: any, inx: number) => {
        const filtered = cardData.data.data.find((obj: { name: any }) => {
          return obj.name === data.name;
        });
        if (filtered) {
          updatedObject[index].fields[inx].value = filtered.value;
        }
      })
    );
    const phoneExtesionObject = {
      fax: cardData.data.data.find(
        (x: { name: string }) => x.name === 'faxExtension'
      ),
      phoneNumber: cardData.data.data.find(
        (x: { name: string }) => x.name === 'extension'
      ),
    };
    setselectedCardData(cardData);
    SetPhoneExtesion(phoneExtesionObject);
    setIntegrationRequiredCredentials(updatedObject);
    !isReauthenticate && setIsEditForm(true);
    setEditId(cardData.data.id);
    setOpen(true);
  };

  const meunuPopupAction = (param: any): void => {
    setPayLoad(param);
    setEditId(param.data.id);
    setIsEditForm(false);
    setIsReauthenticate(false);
    switch (param.type) {
      case EMenuAction.DEACTIVATE:
        setDeActivatePopupView(true);
        break;
      case EMenuAction.ARCHIVE:
        setArchiveAccountView(true);
        break;
      case EMenuAction.DELETE:
        setDeletePopupView(true);
        break;
      case EMenuAction.REAUTHENTICATE:
        if (currentCustomerData?.providerId === APEX_PROVIDER_ID) {
          reauthenticate(param.data);
          setIsReauthenticate(true);
        } else {
          setIsReauthenticate(true);
          editAccount(param);
        }
        break;
      case EMenuAction.MFAM:
        getSelectedMappingName(param.data);
        setSelectedTab(manageIntegrationTabs[1].key);
        break;
      case EMenuAction.MCM:
        getSelectedMappingName(param.data);
        setSelectedTab(manageIntegrationTabs[0].key);
        break;
      case EMenuAction.MDM:
        getSelectedMappingName(param.data);
        setSelectedTab(manageIntegrationTabs[1].key);
        break;
    }
  };

  const deactivateAccount = async (): Promise<void> => {
    const payLoadObject = payLoad.data;
    payLoadObject.isValid = false;
    setDeActivatePopupView(true);
    await financeIntegrationService
      .factoringDeactive(payLoadObject)
      .then(() => {
        refreshFactoringData();
      });
  };
  const archiveAccount = async () => {
    const payLoadObject = payLoad?.data;
    payLoadObject.archive = !payLoadObject.archive;
    payLoadObject.data = [];
    payLoadObject['isArchivedRequest'] = true;
    setArchiveAccountView(true);
    await financeIntegrationService
      .factoringArchived(payLoadObject)
      .then(() => {
        refreshFactoringData();
        setArchiveAccountView(false);
      });
  };
  const deleteAccount = async (): Promise<void> => {
    await financeIntegrationService
      .deleteFactoring(payLoad.data.id)
      .then(() => {
        refreshFactoringData();
        setDeletePopupView(false);
      });
  };

  const reauthenticate = (cardData: any): void => {
    const payload = cardData;
    payload.isValid = true;
    sessionStorage.setItem('ApexAuthCreds', JSON.stringify(payload));
    authenticateOAuth();
  };

  const authenticateOAuth = (): void => {
    const url =
      `${window.env.APEX_URL}` +
      `response_type=code` +
      `&state=${window.env.APEX_INTEGRATION_STATE}/` +
      `&client_id=${window.env.APEX_CLIENT_ID}` +
      `&scope=profile` +
      `&redirect_uri=${window.location.origin}${process.env.APEX_INTEGRATION_URL_REDIRECT}`;
    window.location = url;
  };

  const getSelectedMappingName = (List: IListMapping): void => {
    List.data?.find((obj: { name: string; value: string }) => {
      if (obj.name === 'displayName') {
        setSelectedMapName(obj.value);
      }
    });
  };

  const axelePermissions = {
    showAddBtn: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_FACTORING_ADD],
    }),
    showEdit: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_FACTORING_EDIT],
    }),
    showRemove: getHasPermission({
      includes: [AXELE_PERMISSION_TYPE?.INTEGRATIONS_FACTORING_REMOVE],
    }),
  };

  const clickedParamToManage = (
    _entityType: string,
    data: CardDataDTO,
    entityKey: string
  ) => {
    setEditId(data?.id);
    setActiveAccount(data.isValid);
    switch (entityKey) {
      case 'accountMapping':
        setSelectedTab(manageIntegrationTabs[1].key);
        break;
      case 'customerMapping':
        setSelectedTab(manageIntegrationTabs[0].key);
        break;
      case 'driverMapping':
        setSelectedTab(manageIntegrationTabs[1].key);
        break;
    }
  };
  return (
    <Box className="selected-customer-block">
      <ProviderHero currentCustomerData={currentCustomerData} />
      <Stack sx={{ p: '50px 20px 0 30px' }}>
        <Grid container>
          <ProviderDescription currentCustomerData={currentCustomerData} />
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <IntegrationActivationPanel
              toggleDrawer={toggleDrawer(true)}
              status={
                currentCustomerData.providerId === OTHER_PROVIDER_ID
                  ? providerData?.integrationsDTO[
                      currentCustomerData.providerId
                    ]?.length > 0
                    ? 'VALID'
                    : currentCustomerData.status
                  : currentCustomerData.status
              }
              Type={
                currentCustomerData.providerId === OTHER_PROVIDER_ID
                  ? 'otherFactore'
                  : null
              }
              axelePermissions={axelePermissions}
            />
          </Grid>
        </Grid>
        <Stack sx={{ pt: '48px' }}>
          {providerData?.integrationsDTO[currentCustomerData.providerId]
            ?.length > 0 && (
            <AccountsSection
              openDrawer={toggleDrawer(true)}
              currentCustomerData={currentCustomerData}
              providerData={providerData}
              isDeletePopupOpen={false}
              openConfirmationPopup={meunuPopupAction}
              editAccount={editAccount}
              integrationType={'FACTORING'}
              axelePermissions={axelePermissions}
              clickedParamToManage={clickedParamToManage}
              componentToRenderInsideModal={
                <MappingFactoring
                  selectedTabData={selectedTab}
                  titleText={selectedMapName}
                  activeAccount={isActiveAccount}
                  subTitleText={`Last Updated: ${
                    payLoad?.data?.lastValidationDate
                      ? moment(payLoad.data?.lastValidationDate).format(
                          'MM-DD-YYYY hh:mm a'
                        )
                      : ' --'
                  }`}
                  selectedId={editId}
                  selectedProviderId={currentCustomerData.providerId}
                />
              }
            />
          )}

          {!providerData?.integrationsDTO[currentCustomerData?.providerId] &&
            Object.keys(
              providerData?.integrationsDTO[currentCustomerData?.providerId] ||
                0
            ).length > 0 && (
              <AccountsSection
                openDrawer={toggleDrawer(true)}
                currentCustomerData={currentCustomerData}
                providerData={[]}
                isDeletePopupOpen={false}
                openConfirmationPopup={meunuPopupAction}
                editAccount={editAccount}
                integrationType={'FACTORING'}
                axelePermissions={axelePermissions}
              />
            )}

          {archiveList?.integrationsDTO[currentCustomerData.providerId]
            ?.length > 0 && (
            <AccountsSection
              openDrawer={toggleDrawer(true)}
              currentCustomerData={currentCustomerData}
              providerData={archiveList}
              isDeletePopupOpen={false}
              openConfirmationPopup={meunuPopupAction}
              editAccount={editAccount}
              integrationType={'FACTORING'}
              sectionHeading={'Archived'}
              axelePermissions={axelePermissions}
              isArchivedSection={true}
            />
          )}
        </Stack>
        <Stack sx={{ pt: '48px' }}>
          {features.length > 0 && <CustomerFeatures features={features} />}
        </Stack>
        <Sidedrawer
          currentCustomerData={currentCustomerData}
          currentCustomerIntegrationFormField={integrationRequiredCredentials}
          open={open}
          toggleDrawer={toggleDrawer(false)}
          eldProviderData={providerData}
          activateFactoriy={refreshFactoringData}
          isEditForm={isEditForm}
          editId={editId}
          phoneExtesion={phoneExtesion}
          connectAuth={reauthenticate}
          selectedCardData={selectedCardData.data}
          resetValidation={resetValidation}
          isReauthenticate={isReauthenticate}
        />
      </Stack>

      <DeletePopup
        open={isDeletePopupView}
        onClose={() => setDeletePopupView(false)}
        onAction={() => deleteAccount()}
        title={constantFactorText.deletetext}
        body={constantFactorText.deleteDescription}
        buttonText={constantFactorText.deletSaveText}
      />
      <DeletePopup
        open={isDeActivatePopupView}
        onClose={() => setDeActivatePopupView(false)}
        onAction={() => deactivateAccount()}
        title={constantFactorText.deActivateText}
        body={constantFactorText.deActivateDescription}
        subtitle={constantFactorText.deActivateSubTitle}
        buttonText={constantFactorText.deActivateSaveText}
      />
      <DeletePopup
        open={isArchiveAccountView}
        onClose={() => setArchiveAccountView(false)}
        onAction={() => archiveAccount()}
        title={`${payLoad?.data?.archive ? 'Restore' : 'Archive'} Account?`}
        subtitle={`Are you sure you want to ${
          payLoad?.data?.archive ? 'restore' : 'archive'
        } this account ?`}
        buttonText={payLoad?.data?.archive ? 'Restore' : 'Archive'}
      />
    </Box>
  );
}

interface IListMapping {
  archive: boolean;
  data: any[];
  id: string;
  integrationType: string | null;
  isValid: boolean;
  lastUsedDate: string | null;
  lastValidationDate: string | null;
  organizationId: number;
  providerId: number;
  status: string;
}
