import { Box, Stack, Typography } from '@mui/material';
import {
  AppStoreIcon,
  AuthActivationSuccessIcon,
  GooglePlayIcon,
} from '../../ui-kit/components/Assets';
import { StyledAuthFromWrapper } from './styles';

const UserActivationFormSuccess = () => {
  return (
    <StyledAuthFromWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AuthActivationSuccessIcon />
          </Box>

          <Typography
            sx={{
              fontFamily: 'Oxanium',
              fontSize: '42px',
              fontWeight: '600',
              lineHeight: '59px',
              textAlign: 'center',
              color: 'success.main',
              pt: 7,
            }}
          >
            Account is successfully activated!
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          pl: 9,
          pr: 9,
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '22px',
            textAlign: 'center',
            color: 'text.primary',
          }}
        >
          Download the LoadOps mobile app for drivers via App Store or Google
          Play Store
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          pt={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-evenly',
            pt: 1,
            cursor: 'pointer',
          }}
        >
          <Box
            component={'a'}
            href="https://apps.apple.com/us/app/axele/id1481972952"
          >
            <AppStoreIcon />
          </Box>
          <Box
            component={'a'}
            href="https://play.google.com/store/apps/details?id=com.axele&hl=en_US&gl=US"
          >
            <GooglePlayIcon />
          </Box>
        </Stack>
      </Box>
    </StyledAuthFromWrapper>
  );
};

export default UserActivationFormSuccess;
