export const manageIntegrationTabs = [
  {
    label: 'Driver',
    key: 'Driver',
    iconName: 'DriverSafety',
    source: 'AxeleIcon',
    // labelItemName: 'Driver Type',
    // errorType: 'Driver Type',
  },
  {
    label: 'Tractor',
    key: 'Tractor',
    iconName: 'Tractor',
    source: 'AxeleIcon',
    // labelItemName: 'Tractor Type',
    // errorType: 'Tractor Type',
  },
];
