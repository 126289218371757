import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import ButtonImproved from '../ButtonImproved';
import { Notification } from './Notification';
import { NotificationTrayProps } from './constants/interface';

export const NotificationTray = ({
  unreadCount = 0,
  notificationList = [],
  onNotificationClicked,
  getNotificationTrayList,
  anchorEl,
  setAnchorEl,
  notificationIconSxProps = {},
}: NotificationTrayProps) => {
  // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    await getNotificationTrayList();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          color: 'primary.main',
          border: '1px solid',
          borderColor: 'tabStrip.actionIcon',
          // height: "36px",
          //width: "20px",
          borderRadius: '6px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginRight: '13px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'primary.outlinedHoverBackground',
          },
          ...notificationIconSxProps,
        }}
      >
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsNoneIcon sx={{ color: 'primary.contrast' }} />
        </Badge>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ sx: { borderRadius: 4 } }}
      >
        <Stack
          direction="column"
          width={400}
          //height={600}
          maxHeight={600}
          alignItems="center"
          sx={{ borderRadius: 20 }}
        >
          <Stack
            direction="row"
            width={'100%'}
            alignItems="center"
            justifyContent={'space-between'}
            padding={4}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              //justifyContent={'space-between'}
            >
              <Typography
                align="left"
                sx={{
                  color: 'text.primary',
                  fontSize: 16,
                }}
              >
                {'Notifications'}
              </Typography>
              <ButtonImproved
                variant="contained"
                label={unreadCount ? unreadCount.toString() : `0`}
                size="small"
                styleProps={{ borderRadius: 10 }}
              />
            </Stack>
            <Button
              variant="text"
              sx={{ textTransform: 'none' }}
              onClick={() => onNotificationClicked({}, true)}
            >
              Mark All as Read
            </Button>
          </Stack>
          {notificationList.map((item: any, index: number) => (
            <Notification
              key={index}
              item={item}
              onNotificationClicked={onNotificationClicked}
            />
          ))}
        </Stack>
      </Popover>
    </Box>
  );
};
