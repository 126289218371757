import React, { memo } from 'react';
import { RelativeDateRange } from './RelativeDateRange';
import { IRelativeDateRangeOption } from './types';

export interface RelativeDateRangeFilterProps {
  id?: string;
  column: any;
  defaultFilterValue: Record<string, any>;
  onChangeCb?: (param: IRelativeDateRangeOption | null, name: string) => void;
  isFormControl?: boolean;
  field: Record<string, any> | undefined;
}

export const RelativeDateRangeFilter = memo(
  ({
    id = '',
    column,
    defaultFilterValue,
    onChangeCb,
    isFormControl = false,
    field = {},
  }: RelativeDateRangeFilterProps) => {
    return (
      <RelativeDateRange
        id={id}
        isFormControl={isFormControl}
        isSingleDate={!!column.isSingleDate}
        name={field.name || column.name}
        label={
          (!isFormControl || !column.hideLabelOnAllFilters) &&
          (field.label || column.label)
        }
        clearable={column.clearable}
        startDateShow={column.startDateShow}
        startDateDisable={column.startDateDisable}
        startDateRequire={column.startDateRequire}
        startDateDisableFuture={column.startDateDisableFuture}
        endDateShow={column.endDateShow}
        endDateDisable={column.endDateDisable}
        endDateRequire={column.endDateRequire}
        endDateDisableFuture={column.endDateDisableFuture}
        disabled={column.disabled}
        options={column.options}
        onChange={(
          newValue: IRelativeDateRangeOption,
          name?: string | undefined
        ) => {
          const cloneNewValue = { ...newValue };
          if (isFormControl) {
            field.onChange(cloneNewValue, name);
            column.onChange && column.onChange(cloneNewValue, name);
          } else {
            onChangeCb && onChangeCb(cloneNewValue, name);
          }
        }}
        defaultOption={
          isFormControl ? field.value : defaultFilterValue[column.name]
        }
        timezone={column.timezone}
        dateFormat={column.dateFormat}
        dateSelectionFormat={column.dateSelectionFormat}
        width={isFormControl || column?.default ? '270px' : column.width}
        optionWidth={column.optionWidth}
        variant={
          column.variant
            ? column.variant
            : isFormControl
            ? 'standard'
            : 'outlined'
        }
        fixedLabel={
          isFormControl &&
          (column.hideLabelOnAllFilters ||
            (column.fixedLabel === undefined ? true : column.fixedLabel))
        }
        placeholder={column.label}
      />
    );
  }
);
RelativeDateRangeFilter.displayName = 'RelativeDateRangeFilter';
