import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import RadioGroup from '../../../../common/Ui/RadioGroup';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import {
  getDriverNameListShort,
  tractorList,
} from '../../../../views/finance/expenses/utils';

function AddNewPaymentForm({ isFuelExpense }: { isFuelExpense: boolean }) {
  const { control, watch, setValue } = useFormContext();

  const [entityType] = watch(['entityType']);

  const onEquipmentTypeChange = () => setValue('payingEntityObj', undefined);

  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item xs={12} sx={{ marginBottom: '20px' }}>
        <RadioGroup
          row
          control={control}
          name="entityType"
          defaultValue="DRIVER"
          onChangeCb={onEquipmentTypeChange}
          data={[
            { value: 'DRIVER', label: 'Driver' },
            { value: 'TRACTOR', label: 'Tractor' },
          ]}
        />
      </Grid>
      {entityType !== 'TRACTOR' ? (
        <SingleAutocompleteForm
          name="payingEntityObj"
          label="Driver*"
          fieldName="name"
          getOptions={(name: string, pageNumber: number) =>
            getDriverNameListShort(name, pageNumber)
          }
          control={control}
          sizes={{ xs: 5.7 }}
        />
      ) : (
        <SingleAutocompleteForm
          name="payingEntityObj"
          label="Tractor*"
          fieldName="name"
          getOptions={(name: string, pageNumber: number) =>
            tractorList(name, pageNumber, null, ['UNAVAILABLE', 'INACTIVE'])
          }
          control={control}
          sizes={{ xs: 5.7 }}
        />
      )}
      <TextField
        control={control}
        name="amount"
        label="Amount ($)*"
        sizes={{ xs: 5.7 }}
      />
      {!isFuelExpense && (
        <TextField
          control={control}
          name="description"
          label="Description"
          sizes={{ xs: 12 }}
        />
      )}
    </Grid>
  );
}

export default AddNewPaymentForm;
