export const styles = {
  text: {
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#EFF4F8',
    marginRight: '20px',
  },
  details: {
    color: 'tabStrip.sidePanelTabText',
    paddingLeft: '10px',
    textAlign: 'right',
    marginBottom: '15px',
  },
  container: {
    borderWidth: '1px',
    borderColor: 'info.outlinedRestingBorder',
    borderRadius: '16px',
    padding: '15px 24px',
    marginRight: '-16px',
    height: '100%',
    background: '#fff',
    color: '#040022BF !important',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    color: 'text.primary',
  },
  addicon: {
    width: 0,
    minWidth: 0,
    paddingLeft: '25px',
    paddingRight: '13px',
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px',
  },
  cardRight: {
    width: '50%',
    minWidth: '210px',
    height: '162px',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardLeft: {
    width: '50%',
    height: '162px',
    background: '#2196F3',
    borderRadius: '8px',
    position: 'relative',
    paddingLeft: '11px',
    paddingBottom: '20px',
    paddingTop: '37px',
    minWidth: '210px',
  },
  cardRelative: { display: 'flex', position: 'relative', zIndex: 1 },
  font: {
    marginRight: '5px',
    marginBottom: '15px',
    width: '106px',
    height: '33px',
    color: 'primary.contrast',
    opacity: 0.7,
  },
  bankName: {
    width: '78px',
    height: '33px',
    color: 'primary.contrast',
    opacity: 0.7,
  },
  paymentRight: {
    width: '50%',
    minWidth: '210px',
    height: '162px',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infocontainer: {
    borderRadius: '16px',
    padding: '24px',
    height: '100%',
  },
  infoPay: {
    width: '56px',
    height: '56px',
    padding: '8px 10px',
    borderRadius: '8px',
    marginRight: '16px',
  },
  infobox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 72px)',
  },
  infostatus: {
    height: '32px',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    color: 'text.primary',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  infoState: {
    height: '24px',
    color: 'text.primary',
    letterSpacing: '0.15px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  launchIcon: {
    width: '18px',
    height: '18px',
    color: 'primary.main',
    cursor: 'pointer',
  },
  tableContainer: {
    padding: '24px',
    backgroundColor: 'uncategorized.card',
    borderRadius: '16px',
    marginTop: '10px',
    marginBottom: '20px',
    borderWidth: 1,
    minHeight: '200px',
  },
  flexcontainer: {
    display: 'flex',
    alignItems: 'center',
    height: '56px',
    padding: '16px',
    borderBottomColor: 'grey.a100',
    borderWidth: 0,
    borderBottomWidth: 1,
  },
  overflowcontainer: {
    overflow: 'auto',
    height: 'calc(100% - 85px)',
  },
  tableoverflow: {
    display: 'flex',
    alignItems: 'center',
    height: '56px',
    padding: '16px',
    borderBottomColor: 'grey.a100',
    borderWidth: 0,
    borderBottomWidth: 1,
  },
  contactContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '24px 110px 24px 24px',
    borderRadius: '16px',
  },
  flexContact: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '328px',
  },
  contactSupport: {
    display: 'flex',
    flexDirection: 'column',
    width: '340px',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  contactPhone: {
    marginBottom: '10px',
    letterSpacing: '0.15px',
    fontSize: '14px',
    display: 'flex',
    color: 'primary.main',
    alignItems: 'center',
  },
  cancelContainer: {
    position: 'absolute',
    overflow: 'auto',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'uncategorized.card',
    padding: '32px',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  image: {
    minHeight: '500px',
    maxWidth: '500px',
    width: '100%',
    height: '100%',
  },
  cancelText: {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '51px',
    color: 'text.primary',
    marginBottom: '32px',
  },
  balance: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '42px',
    letterSpacing: '0.25px',
    color: 'success.dark',
  },
  support: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '24px 110px 24px 24px',
    borderRadius: '16px',
    marginBottom: '23px',
  },
  help: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
    color: 'primary.main',
  },
  cardTitle: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#040022BF !important',
  },
  activeArrowStyle: {
    border: '1px solid',
    padding: '2px',
    background: '#6c8697',
    color: 'white',
    borderRadius: '5px',
  },
  planCardGridStyle: {
    padding: '0px !important',
    paddingRight: '30px !important',
  },
  trialCardStyle: {
    border: 'none',
  },
  planCardStyle: {
    margin: '0px',
    border: 'none',
    boxShadow: '0px 1px 10px 0px #0000001F',
    textAlign: 'center',
    fontFamily: 'oxanium !important',
    color: '#040022BF !important',
  },
  agreementHeadingStyle: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#040022BF',
  },
  agreementSubTitleStyle: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#040022BF',
  },
  agreementDetailsStyle: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#040022BF',
  },
  agreementPopupStyle: {
    width: '533px',
    height: '650px',
  },
  agreementPopupTitleStyle: {
    color: '#040022BF !important',
    marginBottom: '10px',
  },
};
