import {
  HosConstantsType,
  IScheduleTripLoads,
  ScheduleTripType,
} from '../../../types/DispatchTypes';
import {
  appendTextToDigit,
  formatedTextFromTwoValues,
  getColumnToolTipProps,
  getCustomerNamesHyperlink,
  getDriverTooltipSubtitlesWithoutLink,
  getPuckTooltipProps,
  getTimeOffTooltipProps,
  isTripTypeGap,
  toFixDigit,
} from '../../../services/gantt';
import { DRIVER_ACTION_KEYS } from '../constants/gantt.const';
import {
  IRecommendedResource,
  IRecommendedTrip,
  PuckTooltipPropsSchedule,
} from '../types/gantt.types';
import {
  isResourceLocked,
  isResourceTypeBrokeredTrip,
  isResourceTypeUnassignedDriver,
  isResourceTypeUnassignedTrip,
  isTripTypeRecommended,
} from './recommendTrips.service';
import {
  IPuckTooltipPropsOnClick,
  TooltipActionType,
  TooltipSubtitlesType,
} from '@optym/gantt';
import { RECOMMENDED_RESOURCE_TYPE } from '../constants/optymization.const';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { TIMEOFF_TOOLTIP_TYPE } from '../../../constants/gantt/gantt.const';
import { LOADAI_ELEMENTS } from '../../../constants/elementIds';
import { BROKERED_TRIP_KPI_CONFIG } from '../config/tooltips.config';

export const generateDriverTooltipActions = (data: IRecommendedResource) => {
  if (!((data?.resourceType as string) in RECOMMENDED_RESOURCE_TYPE)) return [];
  let actions: TooltipActionType[] = [];
  if (isResourceLocked(data)) actions = [];
  if (!isResourceTypeUnassignedTrip(data))
    actions = [
      {
        id: '1',
        label: `Options for ${data?.driverGroupDetails?.groupName}`,
        btnTextStyles: { textTransform: 'none!important' },
        variant: 'outlined',
        buttonProps: { startIcon: <AutorenewOutlinedIcon /> },
        key: DRIVER_ACTION_KEYS.CHANGE_TRIP,
      },
    ];
  return actions;
};

export const generateTripTooltipActions = (data: IRecommendedResource) => {
  if (!((data?.resourceType as string) in RECOMMENDED_RESOURCE_TYPE)) return [];
  let actions: TooltipActionType[] = [];
  if (isResourceLocked(data)) actions = [];
  if (isResourceTypeUnassignedTrip(data)) {
    actions = [
      {
        id: '1',
        label: 'Recommend Driver',
        variant: 'outlined',
        buttonProps: { startIcon: <AutorenewOutlinedIcon /> },
        key: DRIVER_ACTION_KEYS.CHANGE_DRIVER,
      },
    ];
  }
  return actions;
};

export const driverTooltipProps = (data: IRecommendedResource) => ({
  ...getDriverWithNoTripProps(data),
  kpis: null,
  actions: generateDriverTooltipActions(data),
  subTitles: getDriverTooltipSubtitlesWithoutLink(data),
});

export const getDriverWithNoTripProps = (data: IRecommendedResource) => {
  return {
    ...getColumnToolTipProps(data),
    kpis: null,
  };
};

export const getFormattedKpiValues = (
  kpis: Array<{ primaryText?: string }>
): Array<{ primaryText?: string }> => {
  if (!kpis?.length) return [];
  const updatedKpis = [];
  for (const kpi of kpis) {
    if (kpi?.primaryText?.length && kpi?.primaryText !== '-')
      updatedKpis?.push(kpi);
  }
  return updatedKpis;
};

export const getTimelinePuckTooltip = ({
  tripData,
  hosConstants,
  isTooltipForRecommendation = false,
  onActionClick,
}: {
  tripData: PuckTooltipPropsSchedule;
  hosConstants: HosConstantsType;
  isTooltipForRecommendation?: boolean;
  onActionClick?: (data: any) => void;
}): IPuckTooltipPropsOnClick | boolean => {
  if (tripData?.elementType === TIMEOFF_TOOLTIP_TYPE) {
    return getTimeOffTooltipProps(tripData?.resourceTimeRangeRecord?.data);
  }
  const tooltipProps = getPuckTooltipProps({
    tripData,
    hosConstants,
  }) as any;
  if (
    isResourceTypeUnassignedTrip(tripData?.resourceRecord?.data) &&
    tooltipProps?.kpis?.content?.length
  ) {
    tooltipProps?.kpis?.content?.splice(1, 1);
    tooltipProps.kpis.content = getFormattedKpiValues(
      tooltipProps?.kpis?.content
    );
  }
  if (isResourceTypeBrokeredTrip(tripData?.resourceRecord?.data)) {
    const content = BROKERED_TRIP_KPI_CONFIG?.map((e) => ({
      ...e,
      primaryText: appendTextToDigit({
        val: (tripData?.eventRecord?.data as any)?.[e?.val],
        prefixLabel: e?.prefixLabel,
      }),
    }));
    tooltipProps.kpis.content = getFormattedKpiValues(content);
  }
  if (!isTripTypeGap(tripData?.eventRecord?.data))
    tooltipProps.subTitles = getOptymizationTripTooltipSubtitles(
      tripData?.eventRecord?.data
    );
  else {
    if (tooltipProps?.hosDetails?.progress?.length)
      tooltipProps?.hosDetails?.progress?.shift?.();
    tooltipProps.subTitles = formatOptimizationGapTooltipSubtitles(
      tooltipProps?.subTitles
    );
  }
  return {
    ...tooltipProps,
    onActionClick,
    actions: getTripTooltipActions(
      tripData?.eventRecord?.data,
      isTooltipForRecommendation
    ),
  };
};

export const getTripTooltipActions = (
  trip: IRecommendedTrip,
  allowActions?: boolean
) => {
  if (!allowActions) return [];
  if (isTripTypeRecommended(trip))
    return [
      {
        id: trip.id,
        btnId: LOADAI_ELEMENTS.OPTIMIZATION_TRIP_OPTIONS_BUTTON,
        label: `Options for ${trip.tripSeqNumber}`,
        btnTextStyles: { textTransform: 'none!important' },
        variant: 'outlined',
        buttonProps: { startIcon: <AutorenewOutlinedIcon /> },
      },
    ];
};

const formatOptimizationGapTooltipSubtitles = (
  subtitles: TooltipSubtitlesType[]
) => {
  const updatedSubtitles = [];
  for (const subtitle of subtitles) {
    if (
      !subtitle?.additionalValue &&
      !subtitle?.values?.length &&
      !subtitle?.subValue?.length
    )
      continue;
    updatedSubtitles?.push(subtitle);
  }
  return updatedSubtitles;
};

const getOptymizationTripTooltipSubtitles = (data: IRecommendedTrip) => {
  const associatedLoads = data?.loads?.slice?.(0, 2);
  const referenceNumbers: Array<any> = [];
  data?.loads?.forEach?.((load: any) => {
    if (load?.loadRefNum)
      referenceNumbers?.push({
        loadId: load?.loadId,
        loadRefNum: load?.loadRefNum,
      });
  });
  const loadsToDisplay = associatedLoads?.length
    ? associatedLoads?.reduce(
        (p, c: IScheduleTripLoads) => p + (p?.length ? ', ' : '') + c?.loadNo,
        ''
      )
    : '-';
  const additonalLoadsCount =
    data?.loads?.length > 2 ? `, +${data?.loads?.length - 2}` : '';
  const referenceNumbersText = referenceNumbers
    ?.slice(0, 2)
    ?.reduce((p, c) => p + (p?.length ? ', ' : '') + c?.loadRefNum, '');
  const additionalReferenceCount =
    referenceNumbers?.length > 2 ? `, +${referenceNumbers?.length - 2}` : '';
  const customerTextToDisplay = getCustomerNamesHyperlink(
    data?.customer as Array<{ name: string; customer_id?: string | undefined }>
  )?.reduce?.((p, c) => p + (p?.length ? ', ' : '') + c?.name, '');
  return [
    {
      key: 'Loads(s)',
      additionalValue: `${loadsToDisplay}${additonalLoadsCount}`,
    },
    {
      key: 'Reference #',
      additionalValue: referenceNumbersText?.length
        ? `${referenceNumbersText}${additionalReferenceCount}`
        : '-',
    },
    {
      key: 'Customer(s)',
      additionalValue: customerTextToDisplay?.length
        ? `${customerTextToDisplay}`
        : '-',
    },
    {
      key: 'Dispatcher',
      additionalValue: data?.dispatcherName?.length
        ? data?.dispatcherName
        : '-',
    },
  ];
};

export const getDeadheadMilesValue = (miles?: number): string | null => {
  if (!miles || isNaN(miles)) return null;
  return Math.floor(miles) + 'mi';
};

export const formatDeadheadKpiVals = (val: number, postfix = 'mi'): string => {
  if (!val || isNaN(val)) return 'N/A';
  return `${Math.floor(val)} ${postfix}`;
};

export const formatDeadheadColumnVals = (
  val1: number,
  postfix1 = 'mi',
  val2: number,
  postfix2: string
): string => {
  if ((!val1 || isNaN(val1)) && (!val2 || isNaN(val2))) return 'N/A';
  return `${formatDeadheadKpiVals(val1, postfix1)} / ${formatDeadheadKpiVals(
    val2,
    postfix2
  )}`;
};

export const formatRevenueKpiVals = (
  val: number,
  postfix = '/hr'
): string | undefined => {
  if (!val || isNaN(val)) return '-';
  return `$${toFixDigit(val)}${postfix}`;
};

export const formatRevenueKpiColumn = (
  val1: number,
  postfix1 = '/hr',
  val2: number,
  postfix2: string
): string => {
  if ((!val1 || isNaN(val1)) && (!val2 || isNaN(val2))) return '-';
  return `${formatRevenueKpiVals(val1, postfix1)} - ${formatRevenueKpiVals(
    val2,
    postfix2
  )}`;
};
