import { ReeferModeEnum } from '../schema/load';

export const StopLocationInitialValue = {
  locationId: null,
  locationName: '',
  center: { lat: null, lng: null },
  city: '',
  state: '',
  zipcode: '',
  streetAddress: '',
  address: '',
  address2: '',
  geoCoordinates: [],
};

export const stopContactInitialValue = {
  id: '',
  contactName: '',
  countryCode: '',
  phone: '',
  extension: '',
  email: '',
  firstname: '',
  lastname: '',
};

export const LOAD_TYPE_KEYS = {
  FTL: 'FTL',
  LTL: 'Partial',
} as { [key: string]: string };

export const LoadPriorityEnumVals = {
  NONE: 'None',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  CRITICAL: 'Critical',
} as { [key: string]: string };

export const DefaultEquipmentAdditionalValue = {
  chassisNumber: null,
  containerNumber: null,
  bookingNumber: null,
  appointmentNumer: null,
  importExport: null,
  reeferMode: 'CONTINUOUS' as ReeferModeEnum,
};

export const ChassisAndContainerAdditionalValues = [
  'chassisNumber',
  'containerNumber',
  'bookingNumber',
  'appointmentNumer',
  'importExport',
];

export const ALLOWED_EQUIPMENT_TYPE_VALS = {
  CHASSIS_CONTAINER: 'Chassis_Container',
  REEFER: 'Reefer',
};

export const INVOICE_BASE_RATE_FLAT_TYPE = 'FLAT_RATE';

export const LOAD_SECTION_KEYS_LIST = [
  'quick-start',
  'customer',
  'stops',
  'load',
  'rate',
];

export const SECTION_LAST_ELEMENT_ID_LIST = [
  'quickStart-template-label',
  'loadPriority',
  'editMilesButton',
  'notes',
  'internalNotes',
];
