import { Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { unitValueLabelMap } from '../../subPages/settings/ManageTypes/constants';
import { ACCESSORIAL_ITEMS_COL_DEF } from './config';
import { FuelMatrix } from '@/models';

export const getCityStateInfo = (data) => {
  if (data && (data?.city || data?.state)) {
    return `${data?.city || ''}, ${data?.state || ''}`;
  } else {
    return '';
  }
};

export const formatRateMethod = (str) => {
  if (str) {
    const backup = str.slice(1).toLowerCase();
    const fLetter = str.charAt(0).toUpperCase();
    return fLetter + backup + ' ' + 'Based';
  }
};

export const AstriskSign = (
  <span color="danger" style={{ color: 'red' }}>
    *
  </span>
);

export const RequiredFieldsHeader = (field: string) => {
  return (
    <Tooltip title={field} sx={{}}>
      <Typography
        sx={{
          fontSize: '12px',
          color: '#1F2E3D',
          fontFamily: 'poppins',
          fontWeight: 500,
        }}
      >
        {field} {AstriskSign}
      </Typography>
    </Tooltip>
  );
};

export const getUnitTypes = (
  accessorialItemStaticData: Array<any>,
  rateType: string
) => {
  let unitTypes = accessorialItemStaticData.find(
    (dt) => dt.id === rateType
  )?.unit;
  if (unitTypes && unitTypes?.length > 0) {
    // if (!unitTypes?.includes('PERCENT')) unitTypes.push('PERCENT');
  } else if (rateType) {
    unitTypes = ['PERCENT'];
  }
  return unitTypes;
};

export const generateAccessorialItems = (
  staticData: any,
  matrixData: FuelMatrix[]
) => {
  if (staticData.length) {
    ACCESSORIAL_ITEMS_COL_DEF.forEach((col: GridColDef, index: number) => {
      if (col.field === 'rateType') {
        col.type = 'singleSelect';
        col.valueOptions = staticData.map((li) => {
          return { value: li?.id, label: li?.itemName };
        });
        col.valueFormatter = (params: { value: any }) => {
          return col?.valueOptions?.find(
            (li: { value: any }) => li?.value === params?.value?.value
          )?.label;
        };
      }
      if (col.field === 'unit') {
        col.type = 'singleSelect';
        col.valueOptions = ({ row }) => {
          const unitTypes = getUnitTypes(staticData, row?.rateType?.value);
          return unitTypes?.length
            ? unitTypes.map((ut) => ({
                value: ut,
                label: unitValueLabelMap[ut],
              }))
            : [];
        };
        col.valueFormatter = (params: any) => {
          return unitValueLabelMap[params?.value?.value];
        };
      }
      if (col.field === 'fuelSurchargeName') {
        col.type = 'singleSelect';
        col.valueOptions = ({ row }) => {
          if (
            row.unit.value === 'PERCENT_MATRIX' ||
            row.unit.value === 'PRICE_PER_MILE_MATRIX'
          ) {
            return matrixData.length
              ? matrixData.map((matrix) => ({
                  label: matrix.matrixName,
                  value: matrix.id,
                }))
              : [];
          }
          return [];
        };
        col.valueFormatter = (params: any) => {
          return (
            matrixData.find((matrix) => matrix.id === params.value.value)
              ?.matrixName ?? ''
          );
        };
      }
    });
  }
  return ACCESSORIAL_ITEMS_COL_DEF;
};

export const generateLaneItems = (headers, staticData, equipmentType) => {
  if (staticData) {
    headers.forEach((col: GridColDef) => {
      if (col?.field === 'rateType') {
        col.type = 'singleSelect';
        col.valueOptions = staticData.map((li) => ({
          value: li?.itemCode,
          label: li?.itemName,
        }));
        col.valueFormatter = (params) => {
          return col?.valueOptions?.find(
            (li) => li?.value === params?.value?.value
          )?.label;
        };
      }
      if (col?.field === 'equipmentType') {
        col.type = 'singleSelect';
        col.valueFormatter = (params) => {
          return equipmentType?.find?.((eq) => eq?.key === params?.value?.value)
            ?.value;
        };
        col.valueOptions = equipmentType.map((eq) => ({
          value: eq?.key,
          label: eq?.value,
        }));
      }
      if (col.field === 'mode') {
        col.valueFormatter = (params) => {
          return col?.valueOptions?.find(
            (li) => li?.value === params?.value?.value
          )?.label;
        };
      }
    });
  }
  return headers;
};
