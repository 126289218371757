import { Box, Button, Link, Stack, Tooltip, Typography } from '@mui/material';
import { styles } from './styles';
import { useState } from 'react';
import AdditionalModulePopup from './AdditionalModulPopup';
import {
  ADDITIONAL_INFO_KEYS,
  ADDITIONAL_MODULE_CONTENT,
  ADDITION_INFO_CONFIG,
} from '../../views/subscription/config/subscription.config';
import { ActivateAccount } from '../../models/DTOs/Subscription/Subscription';
import StorageManager from '../../StorageManager/StorageManager';
interface additionalModuleProps {
  data: any;
  handleActivateAccount: (prop: ActivateAccount) => void;
}

const AdditionalModulesCard = (props: additionalModuleProps) => {
  const { data, handleActivateAccount } = props;
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupKey, setPopUpKey] = useState<string>('');
  const [popupData, SetPopupData] = useState<any>();

  const handleOpenPopup = (key: string) => {
    if (key != ADDITIONAL_INFO_KEYS.LOAD_AI) {
      setShowPopup(true);
      setPopUpKey(key);
      if (key in data) {
        SetPopupData(data[key]);
      }
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopUpKey('');
  };
  const onActivateClick = () => {
    const userStorage = StorageManager.getUser();
    const query = {
      organizationId: userStorage.organizationId,
      subscriptionId: popupData?.subscription_id,
      componentId: popupData?.component_id,
      enabled: popupData?.enabled ? 0 : 1,
    };
    handleActivateAccount(query);
    handlePopupClose();
  };

  const additionalInfoItem = (key: string, additionalModuledata: any) => {
    return (
      key in ADDITION_INFO_CONFIG && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          key={key}
          sx={{ width: '100%' }}
        >
          <Box mr={'5px'}>
            <Typography fontSize={'13px'}>
              {ADDITION_INFO_CONFIG[key]?.title}
            </Typography>
            <Typography fontSize={'10px'}>
              {data[key]?.description}{' '}
              {ADDITION_INFO_CONFIG[key]?.url && (
                <Link
                  href={ADDITION_INFO_CONFIG[key]?.url}
                  target="_blank"
                  color="inherit"
                >
                  learn more
                </Link>
              )}
            </Typography>
          </Box>
          {ADDITION_INFO_CONFIG[key]?.btnUrl ? (
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleOpenPopup(key)}
            >
              <Link
                href={ADDITION_INFO_CONFIG[key]?.btnUrl}
                target="_blank"
                underline="none"
                color="inherit"
                width={'97px'}
              >
                Learn more
              </Link>
            </Button>
          ) : data && key in data && data[key]?.enabled ? (
            <Tooltip title="Contact Support">
              <Button size="small" sx={{ fontSize: '13px' }}>
                {' '}
                Deactivate
              </Button>
            </Tooltip>
          ) : (
            <Button
              variant="outlined"
              size="small"
              sx={{ fontSize: '13px' }}
              onClick={() => handleOpenPopup(key)}
            >
              {ADDITION_INFO_CONFIG[key].buttonText}
            </Button>
          )}
        </Box>
      )
    );
  };

  return (
    <Box sx={{ ...styles.container, marginRight: '5px' }}>
      <Box sx={styles.flex}>
        <Box sx={styles.title}>Additional Modules</Box>
      </Box>
      {showPopup && (
        <AdditionalModulePopup
          handleClose={() => handlePopupClose()}
          onActionClick={onActivateClick}
          title={
            popupKey == ADDITIONAL_INFO_KEYS.LANE_RATE
              ? `${ADDITION_INFO_CONFIG[popupKey].popupTitle} ${data[popupKey]?.description}?`
              : ADDITION_INFO_CONFIG[popupKey].popupTitle
          }
          content={ADDITIONAL_MODULE_CONTENT(
            popupKey,
            data[popupKey]?.description
          )}
          isCloseBtn={popupKey == ADDITIONAL_INFO_KEYS.EDI}
          isCancelBtn={popupKey != ADDITIONAL_INFO_KEYS.EDI}
          actionBtnText={popupKey != ADDITIONAL_INFO_KEYS.EDI ? 'Activate' : ''}
        />
      )}

      <Typography fontSize={'11px'}>
        Charge for Additional modules are added in addition to your current
        subscription
      </Typography>
      <Stack spacing={2} marginTop={'10px'}>
        {/* {additionalInfoItem(ADDITIONAL_INFO_KEYS.LANE_RATE, {
          title: ADDITION_INFO_CONFIG.cargo_chief.title,
          // subTitle:'$5/driver per month',
        })} */}
        {additionalInfoItem(ADDITIONAL_INFO_KEYS.LOAD_AI, {
          title: ADDITION_INFO_CONFIG.load_ai.title,
          isEnable: true,
        })}
        {additionalInfoItem(ADDITIONAL_INFO_KEYS.EDI, {
          title: ADDITION_INFO_CONFIG.edi.title,
          isEnable: true,
        })}
        {additionalInfoItem(ADDITIONAL_INFO_KEYS.BROKERAGE, {
          title: 'Brokerage-Beta',
          isEnable: true,
        })}
      </Stack>
    </Box>
  );
};
export default AdditionalModulesCard;
