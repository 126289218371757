export const DISPATCH_TOOLTIPS = {
  OPTIMIZATION_FOR_AVAILABLE:
    'Optymization is only possible for available trips.',
  TRIP_STATUS_CHANGE_FOR_PLANNED:
    'Mark Available/ Assigned are only possible for planned trips.',
  TRIP_ON_HOLD: 'Trip should not be on hold.',
};

export const TEAM_DRIVER_TOOLTIPS = {
  TRACTOR:
    'Enabling this will auto-assign the tractor to the trip during driver assignment',
  TRAILER:
    'Enabling this will auto-assign the trailer to the trip during driver assignment',
};
