export const SubscriptionConstants = {
  resolveBalance:
    'Looks like you’ve still got a balance, to finish canceling subscription please resolve this balance',
  leavingLoadops: 'Why are you leaving LoadOps?',
  pickOne: 'Pick at least one of the following options',
  giveFeedback: 'Give us some specific feedback to help us do better',
  feedbackRequired: 'feedback is required.',
  subscriptionCancelled: 'Your subscription to LoadOps has been canceled',
  accountCancelled:
    'We’re sorry to see you go, but our doors are always open! Now that you’ve canceled your subscription, your account will be canceled on',
  balanceBreakdown: 'Balance Breakdown',
  breakdownSub: 'Here’s a breakdown of how we got that number',
  currentInvoice: 'Current Invoice',
  amountDue: 'Amount Due',
  supportTeamToHelp:
    'Our support team is here to help with any questions you may have.',
  callSupportTeamToCancel: 'Call Our Support Center to Cancel',
  noLongerAccessdata:
    'Once you cancel your subscription, you will no longer be able to access you data.',
  exit: 'Exit',
  back: 'Back',
  cancelSubscription: 'Cancel Subscription',
  continue: 'Continue',
  backToAccount: 'Back To Account',
  invoiceDate: 'Invoice Date',
  dueDate: 'Due Date',
  paymentDate: 'Payment Date',
  firstName: 'First Name',
  fieldNotValid: 'This field is not valid. Please update it.',
  lastName: 'Last Name',
  cardNumber: 'Card Number',
  month: 'Month',
  year: 'Year',
  cvv: 'CVV',
  billingAddress: 'Billing Address (Optional)',
  city: 'City',
  country: 'Country',
  selectState: 'Select State',
  zipCode: 'Zip Code',
  bankName: 'Bank Name',
  routingNumber: 'Routing Number',
  accountNumber: 'Account Number',
  accountHolderType: 'Account Holder Type',
  noPaymentToday: 'No payment today.',
  cancelAnytime: 'Cancel anytime within the first 30 days!',
  markAsPrimaryFormOFPayment: 'Mark as Primary Form of Payment',
  cardUsedForMonthlyCharging:
    'Your card info will be used for monthly charging of payment per your subscription plan',
  accountMsg:
    'Please enter the two micro deposit made to your account in the verification link sent to your email. Once your account is verified, you can mark it as your primary payment account.',
  managePayments: 'Manage Payments',
  cards: 'Cards',
  achAccount: 'ACH Account',
  addPaymentMethod: 'Add Payment Method',
  addPayment: 'Add Payment',
};
