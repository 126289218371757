import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete,
  ButtonProps,
  MenuProps,
  TextField,
  ThemeProvider,
  useTheme,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { getThemeDispatchFilters } from '../../views/dispatch2/components/gantt/Filters/styles';
import { LoadBoardAccount } from '../Ui/LoadboardPicker/types';
import { useDispatchFindLoad } from '../hooks/useDispatchFindLoad';
import { ListboxComponent, StyledPopper } from './AutocompleHelper';
import { MenuItemData } from './definitions';
import { useTranslation } from 'react-i18next';

interface NestedDropdownProps {
  children?: React.ReactNode;
  menuItemsData?: MenuItemData;
  onSelectedDriverHandler?: (
    item: { label: string; value: any },
    triggerSearch?: boolean
  ) => void;
  onClearSelectedDriverHandler?: () => void;
  ButtonProps?: Partial<ButtonProps>;
  MenuProps?: Partial<MenuProps>;
  hideSearch?: boolean;
  getLoadboardAccounts: LoadBoardAccount[][];
}

export const NestedDropDown: FC<NestedDropdownProps> = (props) => {
  const [selectedItem, setSelectedItem] = useState<{
    label: string;
    value: any;
    selectedLabel?: string;
  } | null>(null);

  const {
    menuItemsData,
    onSelectedDriverHandler,
    onClearSelectedDriverHandler,
    getLoadboardAccounts,
  } = props;

  const handleClose = (
    item: {
      label: string;
      value: any;
      selectedLabel?: string;
    },
    triggerSearch?: boolean
  ) => {
    setSelectedItem(item);
    onSelectedDriverHandler?.(item, triggerSearch);
  };

  const { getPopulateFindLoadFilter } = useDispatchFindLoad();
  const populateFindLoadFilter = (): void => {
    const selectedItem = getPopulateFindLoadFilter({
      clearData: true,
      menuItemsData,
    });
    if (selectedItem) {
      handleClose(selectedItem, true);
    }
  };

  const onClearHandler = () => {
    setSelectedItem(null);
    onClearSelectedDriverHandler?.();
  };

  useEffect(() => {
    populateFindLoadFilter();
  }, [menuItemsData?.items]);

  useEffect(() => {
    if (getLoadboardAccounts?.length > 0 && menuItemsData === null) {
      //Handle failing API fetchLoadboardDriverGapData
      populateFindLoadFilter();
    }
  }, [JSON.stringify(menuItemsData), JSON.stringify(getLoadboardAccounts)]);

  const currTheme = useTheme();

  const overrideTheme = getThemeDispatchFilters({ currTheme });
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <ThemeProvider theme={overrideTheme}>
        <Autocomplete
          id="virtualize-demo"
          disableListWrap
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          filterOptions={(options, { inputValue, getOptionLabel }) => {
            return options.filter((option) =>
              getOptionLabel(option)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            );
          }}
          sx={{
            width: 200,
          }}
          size="small"
          clearIcon={<ClearIcon onClick={onClearHandler} />}
          value={selectedItem}
          disablePortal
          options={menuItemsData?.items || []}
          renderInput={(params) => (
            <TextField
              {...params}
              color="primary"
              label={t('NestedDropDownSelectDrivers')}
              sx={{
                '.MuiFormLabel-root': { color: 'primary.main' },
                '.MuiOutlinedInput-notchedOutline, .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'primary.main',
                    color: 'primary.main',
                  },
              }}
              InputProps={{
                ...params.InputProps,
                style: {
                  borderRadius: '8px',
                },
              }}
            />
          )}
          getOptionLabel={(option: any) =>
            option?.selectedLabel
              ? option.selectedLabel
              : option?.label
              ? option.label
              : menuItemsData?.label || ''
          }
          renderOption={(props, option, state) =>
            [props, option, state.index, handleClose] as React.ReactNode
          }
        />
      </ThemeProvider>
    );
  }
};
