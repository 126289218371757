import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  SxProps,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
interface SingleSelectProps<TModel> {
  isFormControl?: boolean;
  labelId: string;
  name: string;
  label: string;
  options: TModel[];
  customStyles?: SxProps;
  renderValueSxProps?: SxProps;
  fieldName: keyof TModel;
  fieldValue: keyof TModel;
  disabled?: boolean;
  defaultValue?: TModel;
  onChange?: (value: TModel | null, name: string) => void;
  selectProps?: SelectProps;
}

const SingleSelect = <TModel,>({
  isFormControl,
  labelId,
  name,
  label,
  options,
  customStyles,
  renderValueSxProps,
  fieldName,
  fieldValue,
  disabled,
  onChange,
  defaultValue,
  selectProps = {},
}: SingleSelectProps<TModel>) => {
  const onChangeHandler = (event: SelectChangeEvent) => {
    onChange?.(
      options.find(
        (option: TModel) => option[fieldValue] === event.target.value?.key
      ) || null,
      name
    );
  };
  return (
    <FormControl
      variant={isFormControl ? 'standard' : 'outlined'}
      sx={{
        ...customStyles,
        ...(isFormControl && {
          width: '100%',
        }),
      }}
      size="small"
    >
      {label && (
        <InputLabel
          sx={{
            color: 'primary.main',
          }}
          id={labelId}
        >
          {label}
        </InputLabel>
      )}

      <Select
        variant={isFormControl ? 'standard' : 'outlined'}
        style={{ width: '100%' }}
        name={name}
        labelId={labelId}
        label={label}
        renderValue={(value: TModel) => (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 15,
              color: 'primary.main',
              overflowX: 'hidden',
              ...renderValueSxProps,
            }}
          >
            {value[fieldName]}
          </Typography>
        )}
        value={defaultValue}
        onChange={onChangeHandler}
        disabled={disabled}
        sx={{
          borderRadius: '6px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid`,
            borderColor: 'primary.light',
          },
        }}
        {...selectProps}
      >
        {options.map((option: TModel) => {
          return (
            <MenuItem key={option[fieldValue]} value={option}>
              {option[fieldName]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SingleSelect;
