import * as yup from 'yup';
import { transformEmptyStringToNull } from './';

const DECIMAL_DIGIT_VALIDATION_REGEX = /^(?!0\d|$)\d*(\.\d{1,4})?$/;

const addtitionalFeeQuantityValidation = yup
  .number()
  .nullable()
  .min(0, 'Value must be between 0 and 999,999.9999.')
  .max(999999.9999, 'Value must be between 0 and 999,999.9999.')
  .transform(transformEmptyStringToNull)
  .typeError('Please enter a valid number.')
  .test(
    'maxDigitsAfterDecimal',
    'Please enter upto 4 decimal digits.',
    (number) => {
      if (!number) return true;
      return DECIMAL_DIGIT_VALIDATION_REGEX.test(`${number}`);
    }
  );

const additionalFeeRateValidation = yup
  .number()
  .nullable()
  .min(0, 'Value must be between 0 and 999,999.9999.')
  .max(999999.9999, 'Value must be between 0 and 999,999.9999.')
  .transform(transformEmptyStringToNull)
  .test(
    'maxDigitsAfterDecimal',
    'Please enter upto 4 decimal digits.',
    (number) => {
      if (!number) return true;
      return DECIMAL_DIGIT_VALIDATION_REGEX.test(`${number}`);
    }
  )
  .typeError('Please enter a valid number.');

export const rateSchema = {
  // contract: yup.string().nullable(),
  baseRateType: yup
    .string()
    .nullable()
    .when('rate', {
      is: (val: any) => Boolean(val) || val == 0,
      then: yup.string().required('Please select a base rate type.'),
    }),
  messageOnInvoice: yup
    .string()
    .nullable()
    .max(1024, 'Cannot be longer than 1024 characters.'),
  internalNotes: yup
    .string()
    .nullable()
    .max(1024, 'Cannot be longer than 1024 characters.'),
  rate: additionalFeeRateValidation.when('baseRateType', {
    is: (val: any) => Boolean(val),
    then: yup
      .number()
      .nullable()
      .min(0, 'Value must be between 0 and 999,999.9999.')
      .max(999999.9999, 'Value must be between 0 and 999,999.9999.')
      .transform(transformEmptyStringToNull)
      .typeError('Please enter a valid number.')
      .test(
        'maxDigitsAfterDecimal',
        'Please enter upto 4 decimal digits.',
        (number) => {
          if (!number) return true;
          return DECIMAL_DIGIT_VALIDATION_REGEX.test(`${number}`);
        }
      ),
  }),
  quantity: addtitionalFeeQuantityValidation,
  additionalFees: yup.array().of(
    yup.object().shape(
      {
        fee: yup
          .string()
          .nullable()
          .typeError('Fee is required.')
          .transform(transformEmptyStringToNull)
          .when('quantity', {
            is: (val: any) => Boolean(val),
            then: yup
              .string()
              .required('Fee is required.')
              .typeError('Fee is required.')
              .transform(transformEmptyStringToNull),
          })
          .when('rate', {
            is: (val: any) => Boolean(val),
            then: yup
              .string()
              .required('Fee is required.')
              .typeError('Fee is required.')
              .transform(transformEmptyStringToNull),
          }),
        quantity: addtitionalFeeQuantityValidation
          .when('fee', {
            is: (val: any) => Boolean(val),
            then: addtitionalFeeQuantityValidation.required(
              'Quantity is required.'
            ),
          })
          .when('rate', {
            is: (val: any) => Boolean(val),
            then: addtitionalFeeQuantityValidation.required(
              'Quantity is required.'
            ),
          }),
        rate: additionalFeeRateValidation
          .when('fee', {
            is: (val: any) => Boolean(val),
            then: additionalFeeRateValidation.required('Rate is required.'),
          })
          .when('quantity', {
            is: (val: any) => Boolean(val),
            then: additionalFeeRateValidation.required('Rate is required.'),
          })
          .when(['unit'], (unit, schema) => {
            return unit === 'PERCENT'
              ? schema
                  .min(0, 'Value must be between 0 and 100.')
                  .max(100, 'Value must be between 0 and 100.')
              : schema;
          }),
        description: yup.string().nullable().notRequired(),
        // total: yup.number().nullable(),
        internalNotes: yup.string().nullable(),
        messageOnInvoice: yup.string().nullable(),
      },
      [
        ['fee', 'quantity'],
        ['fee', 'rate'],
        ['quantity', 'rate'],
      ]
    )
  ),
  totalRate: yup
    .number()
    .nullable()
    .transform(function (value: any, originalValue: any) {
      if (originalValue?.trim?.() === '') {
        return null;
      }
      return isNaN(Number(value)) ? null : Number(value);
    })
    .min(0, 'The due amount cannot be negative. Please correct data'),
};
