import { Box, CircularProgress, useTheme } from '@mui/material';

import React, { useState } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import TableDataContainer from '../../../common/Wrappers/TableDataContainer';

import { ServiceError } from '../../../api/interfaces';
import { getOrganizationId } from '../../../common/TimeoffDialog/utils';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { StopSolutionV3NormalStop } from '../../../ui-kit/TripPlan';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
import LoadController from '../LoadController';
import { LoadSummary } from '../models/LoadSummary';
import CreateManifestForm from './CreateManifestForm';
import {
  propsTypeAddLoadtoManifest,
  propsTypeCreatManifest,
  propsTypeCreateManifestModel,
  propsTypeUpdaterequest,
} from './utils';

const CreateManifestModel = ({
  onModelClose,
  loadIds,
  manifestCreated,
  LoadItem,
}: propsTypeCreateManifestModel) => {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tripRequestData, setTripRequestData] =
    useState<propsTypeUpdaterequest>();
  const [manifestWartning, setManifestWartning] = useState({
    open: false,
    message: null,
  });
  const onClose = (): void => {
    onModelClose();
  };
  const createManifest = (): void => {
    setIsSubmitting(true);
    if (loadIds?.manifestIds?.length) {
      let selectedTripIds = {};

      tripRequestData?.tripData.map(
        (tripItem: { stopList: any; id: string; stops: TripV3DataProps[] }) => {
          const filterData: { tripId: string; stopId: string }[] = [];
          tripItem?.stopList?.map(
            (item: { tripId: string; realId: string }) => {
              filterData.push({
                tripId: item.tripId || '',
                stopId: item.realId,
              });
            }
          );

          selectedTripIds = {
            ...selectedTripIds,
            ...{ [tripItem.id]: filterData },
          };
        }
      );
      const addPayLoad: propsTypeAddLoadtoManifest = {
        organizationId: getOrganizationId(),
        manifestId: loadIds?.manifestIds[0],
        manifestTripToTripStops: selectedTripIds,
      };
      LoadController.instance().addLoadToManifest(
        addPayLoad,
        async (result: { data: LoadSummary }) => {
          if (result instanceof ServiceError) {
            if (result.composedError?.error?.response?.data?.message) {
              setManifestWartning({
                open: true,
                message: result.composedError?.error?.response?.data?.message,
              });
            }
          } else {
            setIsSubmitting(false);
            RootStoreInstence.setNotificationType({
              type: 'SUCCESS',
              message: `Successfully created the manifest ${
                result?.data?.manifestBaseDetails?.seqNumber || ''
              }`,
            });
            manifestCreated?.(result.data, loadIds);
            onModelClose();
          }
        }
      );
    } else {
      const tripListIds: { tripId: string; stopId: number }[] = [];
      tripRequestData?.tripData[0]?.stopList?.map(
        (item: StopSolutionV3NormalStop) => {
          if (!item.realId) return;
          tripListIds.push({
            tripId: item.tripId || '',
            stopId: item.realId,
          });
        }
      );
      const requestBody: propsTypeCreatManifest = {
        organizationId: getOrganizationId(),
        stops: tripListIds,
        trailerType: tripRequestData?.trailer || '',
      };
      LoadController.instance().creatManifest(
        requestBody,
        async (result: { data: LoadSummary }) => {
          if (result) {
            setIsSubmitting(false);
            RootStoreInstence.setNotificationType({
              type: 'SUCCESS',
              message: `Successfully created the manifest ${
                result?.data?.manifestBaseDetails.seqNumber || ''
              }`,
            });
            manifestCreated?.(result.data, loadIds);
            onModelClose();
          }
        }
      );
    }
  };

  const ManifestFooter = ({ isSubmitting }) => (
    <>
      <Box
        px={2}
        display="flex"
        justifyContent="end"
        sx={{ height: '42px', mb: 2 }}
      >
        <ButtonImproved
          variant="outlined"
          label="Cancel"
          onClick={onClose}
          styleProps={{
            marginRight: '20px',
          }}
        />
        <ButtonImproved
          disabled={isSubmitting}
          startIcon={
            isSubmitting && <CircularProgress size={20} color="text" />
          }
          variant="contained"
          label="Save Manifest"
          onClick={createManifest}
        />
      </Box>
    </>
  );
  return (
    <React.Fragment>
      <TableDataContainer>
        <DetailsPanel
          darkHeader
          isGlobal={true}
          panelTitle={
            loadIds?.manifestIds?.length
              ? `Manifest Id: ${LoadItem?.manifestBaseDetails.seqNumber}`
              : 'Add to Manifest'
          }
          data={FormData}
          entity="Document"
          onClose={onClose}
          contentRenderer={() => (
            <CreateManifestForm
              loadIds={loadIds}
              updateRequest={setTripRequestData}
            />
          )}
          footerRenderer={() => <ManifestFooter isSubmitting={isSubmitting} />}
        />
      </TableDataContainer>
      <DeletePopup
        open={manifestWartning.open}
        onClose={() => {
          setManifestWartning({
            open: false,
            message: null,
          });
        }}
        title={'Info'}
        body={manifestWartning.message}
        hideDelete={true}
        cancelText={'Okay'}
        backgroundColor={theme.palette.primary.main}
        width={'440px'}
      />
    </React.Fragment>
  );
};
export default CreateManifestModel;
