export const ContactConstants = {
  mc: 'MC',
  contacts: 'Contacts',
  customerName: 'Customer Name',
  contact: 'Contact',
  preferred: 'Preferred',
  prohobited: 'Prohibited',
  avgHandlingTime: 'Avg. Handling Time',
  timeZone: 'Time Zone',
  customers: 'Customers',
  vendors: 'Vendors',
  locations: 'Locations',
  deleteCustomer: 'Delete Customer',
  addNewCustomer: 'Add New Customer',
  addCustomer: 'Add Customer',
  customerDetails: 'Customer Details',
  commodity: 'Commodity',
  addNewContact: 'Add New Contact',
  name: 'Name',
  phone: 'Phone #',
  description: 'Description',
  email: 'Email',
  addContact: 'Add Contact',
  timeToGetSocial: 'time to get social',
  addContacts:
    'Looks like you need to add some contacts in to the system. What is a carrier without a customer after all?',
  deleteCustomerConfirmation: 'Are you sure you want to remove this customer?',
  customerNotRemoved:
    'The customer cannot be removed as it is either assigned to completed load(s) or current load(s).',
  addVendor: 'Add Vendor',
  deleteVendor: 'Delete Vendor?',
  deleteVendorConfirmation: 'Are you sure you want to delete this vendor?',
  addVendors:
    'Looks like you need to add some contacts in to the system. What is a carrier without a vendor after all?',
  businessName: 'Business Name',
  locationDetails: 'Location Details',
  addLocation: 'Add Location',
  deleteLocation: 'Delete Location',
  deleteLocationConfirmation: 'Are you sure you want to remove this location?',
  addLocations:
    'Looks like you need to add some locations in to system. What is a carrier without a location after all?',
  importLocations: 'Import Locations',
};
