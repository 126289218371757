import StorageManager from '../../../StorageManager/StorageManager';
import { homeService } from '../../../api';
import { GetHomeFinance } from '../../../models';
import { filterSafetyRes } from './constant';
import {
  DriverDataTypes,
  InvoiceCardName,
  SafetyDataTypes,
  TractorDataTypes,
} from './interface';

const loadStatusList: string[] = ['LOAD_COMPLETED', 'INVOICED', 'PAID'];

export const getFinanceData = async (
  isIncludePrimaryTerminal: boolean,
  revenueType: string,
  planType: string,
  terminalIds: string[]
) => {
  const userStorage = StorageManager.getUser();
  const request = new GetHomeFinance();
  request.organizationId = userStorage.organizationId;
  request.isIncludePrimaryTerminal = isIncludePrimaryTerminal;
  request.revenueType = revenueType;
  request.terminalIds = terminalIds;

  if (planType == 'Actual') request.loadStatusList = loadStatusList;

  return await homeService.getFinanceData(request);
};

export const getSafetyData = async (terminalIds: string[]) => {
  const userStorage = StorageManager.getUser();
  const request = new GetHomeFinance();
  request.organizationId = userStorage.organizationId;
  request.terminalIds = terminalIds;
  const res = await homeService.getSafetyData(request);
  if (res && res.length > 0) {
    const tempRes: SafetyDataTypes = {};
    tempRes.driverWarning = filterSafetyRes(res, 'driverWarning');
    tempRes.driverCritical = filterSafetyRes(res, 'driverCritical');
    tempRes.tractorWarning = filterSafetyRes(res, 'tractorWarning');
    tempRes.tractorCritical = filterSafetyRes(res, 'tractorCritical');
    tempRes.trailerWarning = filterSafetyRes(res, 'trailerWarning');
    tempRes.trailerCritical = filterSafetyRes(res, 'trailerCritical');
    return tempRes;
  } else {
    return {};
  }
};

export const getInvoiceData = async (
  terminalIds: string[],
  cardName: InvoiceCardName
) => {
  const userStorage = StorageManager.getUser();
  const request = new GetHomeFinance();
  request.organizationId = userStorage.organizationId;
  request.terminalIds = terminalIds;
  return await homeService.getCommonHomeData(request, cardName);
};

export const getDriverAvailabilityData = async (terminalIds: string[]) => {
  const userStorage = StorageManager.getUser();
  const request = new GetHomeFinance();
  request.organizationId = userStorage.organizationId;
  request.terminalIds = terminalIds;
  const tempRes: DriverDataTypes = {};
  tempRes.driverChart = await homeService.getCommonHomeData(
    request,
    'driverChart'
  );
  tempRes.driverLoads = await homeService.getCommonHomeData(
    request,
    'driverLoads'
  );
  tempRes.driverVacation = await homeService.getCommonHomeData(
    request,
    'driverVacation'
  );
  return tempRes;
};

export const getTractorAvailabilityData = async (terminalIds: string[]) => {
  const userStorage = StorageManager.getUser();
  const request = new GetHomeFinance();
  request.organizationId = userStorage.organizationId;
  request.terminalIds = terminalIds;
  const res = await homeService.getTractorAvailability(request);
  if (res && res.length > 0) {
    const tempRes: TractorDataTypes = {};
    tempRes.UNAVAILABLE = filterSafetyRes(res, 'UNAVAILABLE');
    tempRes.TOTAL = filterSafetyRes(res, 'TOTAL');
    tempRes.AVAILABLE = filterSafetyRes(res, 'AVAILABLE');
    return tempRes;
  } else {
    return {};
  }
};

export const getAssignTripsTotal = async (terminalIds: string[]) => {
  const userStorage = StorageManager.getUser();
  const request = new GetHomeFinance();
  request.organizationId = userStorage.organizationId;
  request.terminalIds = terminalIds;
  const tempRes: DriverDataTypes = {};
  return await homeService.getCommonHomeData(request, 'assignTripsTotal');
};

export const getTripsSummaryData = async (terminalIds: string[]) => {
  const userStorage = StorageManager.getUser();
  const request = new GetHomeFinance();
  request.organizationId = userStorage.organizationId;
  request.terminalIds = terminalIds;
  const tempRes: DriverDataTypes = {};
  tempRes.openTripsTotal = await homeService.getCommonHomeData(
    request,
    'openTripsTotal'
  );
  tempRes.tripsNeedDriver = await homeService.getCommonHomeData(
    request,
    'tripsNeedDriver'
  );
  tempRes.assignedPotentialDelay = await homeService.getCommonHomeData(
    request,
    'assignedPotentialDelay'
  );
  return tempRes;
};

export const getMapsData = async (requetBody: any) => {
  const userStorage = StorageManager.getUser();
  requetBody.organizationId = userStorage.organizationId;
  const tempRes = await homeService.getMapsData(requetBody);
  return tempRes;
};
