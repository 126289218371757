import moment, { Moment, MomentInput } from 'moment';

export const MOMEMT_FORMAT_DATE = 'MMM DD z';
export const MOMEMT_FORMAT_DATE_TIME = 'MMM DD, YYYY, HH:mm z';
export const MOMEMT_FORMAT_TIME = 'HH:mm z';
export const MOMEMT_DRIVER_LOCATION_UPDATE_FORMAT_TIME = 'HH:mm [on] MMM Do z';
export const MOMEMT_HOS_EVENT_VIEW_FORMAT_TIME = 'MMM Do [@] HH:mm';

export class TimelineFormatter {
  //return : 7y 7M 28d 9h 20m, 1h20m, 1h, 20m,...
  static durationFormat = (_minutes: number): string => {
    const formatedDuration = [];
    const durationObject = moment.duration(_minutes, 'minutes');
    const years = durationObject.years();
    const months = durationObject.months();
    const days = durationObject.days();
    const hours = durationObject.hours();
    const minutes = durationObject.minutes();
    if (years > 0) {
      formatedDuration.push(`${years}y`);
    }
    if (months > 0) {
      formatedDuration.push(`${months}M`);
    }
    if (days > 0) {
      formatedDuration.push(`${days}d`);
    }
    if (hours > 0) {
      formatedDuration.push(`${hours}h`);
    }
    if (minutes > 0) {
      formatedDuration.push(`${minutes}m`);
    }

    return formatedDuration.join(' ');
  };

  static minutesToHours = (
    _minutes: number | null,
    defaultValue = ''
  ): string => {
    if (_minutes === null) {
      return defaultValue;
    }
    return TimelineFormatter.durationFormat(_minutes);
  };

  static numberToMiles = (value: number | null, defaultValue = ''): string => {
    if ((!value && typeof value !== 'number') || isNaN(Number(value))) {
      return defaultValue;
    }
    return `${new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(Number(value))}mi`;
  };

  static dateToFormat = ({
    value,
    format,
    timezone,
  }: {
    value: MomentInput;
    format: string;
    timezone?: string | null;
  }): string => {
    if (value === null || value === '') {
      return '';
    }
    if (timezone) {
      return moment(value).tz(timezone).format(format);
    }
    return moment(value).format(format);
  };

  static getMomentObject = (dateTime: MomentInput): Moment => {
    return moment.isMoment(dateTime) ? dateTime : moment(dateTime);
  };

  static daysDiff = ({
    endDate,
    startDate,
    timezone,
  }: {
    endDate: MomentInput;
    startDate: MomentInput;
    timezone?: string | null;
  }): number => {
    const endDateWithTz = timezone
      ? moment(endDate).tz(timezone).startOf('day')
      : moment(endDate).startOf('day');
    const startDateWithTz = timezone
      ? moment(startDate).tz(timezone).startOf('day')
      : moment(startDate).startOf('day');
    const diff = endDateWithTz.diff(startDateWithTz, 'days');
    return diff;
  };

  static isSameDay = ({
    endDate,
    startDate,
    timezone,
  }: {
    endDate: MomentInput;
    startDate: MomentInput;
    timezone?: string | null;
  }): boolean => {
    return (
      TimelineFormatter.daysDiff({
        endDate,
        startDate,
        timezone,
      }) === 0
    );
  };

  static getDateTimeDisplayHumanReadable = ({
    dateTime,
    timezone,
    dateFormat,
    timeFormat,
  }: {
    dateTime: MomentInput;
    timezone?: string | null;
    dateFormat: string;
    timeFormat: string;
  }): string => {
    const myObjectDateTime = TimelineFormatter.getMomentObject(dateTime);
    const daysDiff = TimelineFormatter.daysDiff({
      endDate: dateTime,
      startDate: moment(),
      timezone,
    });

    const dateText =
      {
        '-1': 'Yesterday',
        '0': 'Today',
        '1': 'Tomorrow',
      }[daysDiff] || '';

    if (dateText) {
      return `${dateText}, ${TimelineFormatter.dateToFormat({
        value: myObjectDateTime,
        format: timeFormat,
        timezone,
      })}`;
    }

    return TimelineFormatter.dateToFormat({
      value: dateTime,
      format: dateFormat,
      timezone,
    });
  };

  static getDisplayAppointmentDateOrTime = (
    date: MomentInput,
    timezone?: string | null
  ): string => {
    if (!date) {
      return '';
    }
    return TimelineFormatter.dateToFormat({
      value: moment(date),
      timezone: timezone,
      format: MOMEMT_FORMAT_DATE_TIME,
    });
  };

  static getDisplayAppointmentDate = (
    arrivalTime: MomentInput,
    departureTime: MomentInput
  ): {
    arrivalTime: string;
    departureTime: string;
  } => {
    return {
      arrivalTime:
        TimelineFormatter.getDisplayAppointmentDateOrTime(arrivalTime),
      departureTime:
        TimelineFormatter.getDisplayAppointmentDateOrTime(departureTime),
    };
  };

  static getLeftSubTitleDateTime = (
    appointmentStartTime: MomentInput,
    appointmentEndTime: MomentInput,
    timezone?: string | null
  ): string[] => {
    const tmpStrArr: string[] = [
      TimelineFormatter.dateToFormat({
        value: appointmentStartTime,
        format: MOMEMT_FORMAT_DATE_TIME,
        timezone: timezone,
      }),
      ' - ',
      TimelineFormatter.dateToFormat({
        value: appointmentEndTime,
        format: MOMEMT_FORMAT_DATE_TIME,
        timezone,
      }),
    ];
    return tmpStrArr;
  };

  //return : 7y 7M 28d 9h 20m, 1h20m, 1h, 20m,...
  static minutesToTime = (minutes: number | null): string => {
    return TimelineFormatter.minutesToHours(minutes, '');
  };

  static getDisplayArrivalTime = ({
    actualTimeOfArrival,
    estimatedTimeOfArrival,
    timezone,
  }: {
    actualTimeOfArrival: MomentInput;
    estimatedTimeOfArrival: MomentInput;
    timezone?: string | null;
  }): string => {
    const tmpDateStr = actualTimeOfArrival
      ? actualTimeOfArrival
      : estimatedTimeOfArrival;
    return TimelineFormatter.getDisplayAppointmentDateOrTime(
      tmpDateStr,
      timezone
    );
  };

  static getDisplayDepartureTime = ({
    actualTimeOfCompletion,
    estimatedTimeOfCompletion,
    timezone,
  }: {
    actualTimeOfCompletion: MomentInput;
    estimatedTimeOfCompletion: MomentInput;
    timezone?: string | null;
  }): string => {
    const tmpDateStr = actualTimeOfCompletion
      ? actualTimeOfCompletion
      : estimatedTimeOfCompletion;
    return TimelineFormatter.getDisplayAppointmentDateOrTime(
      tmpDateStr,
      timezone
    );
  };

  static getDisplayTimeAtLocation = ({
    dwellTime,
    estimatedActivityDuration,
  }: {
    dwellTime: number | null;
    estimatedActivityDuration: number | null;
  }): string => {
    if (dwellTime !== null) {
      return dwellTime === 0
        ? '0m'
        : TimelineFormatter.minutesToHours(dwellTime);
    } else {
      return TimelineFormatter.minutesToHours(estimatedActivityDuration);
    }
  };

  static getDisplayDriverLocationUpdateTime = (date: MomentInput): string => {
    if (!date) {
      return '';
    }
    const myDate = moment(date);
    return myDate.format(MOMEMT_DRIVER_LOCATION_UPDATE_FORMAT_TIME);
  };

  static getDisplayHOSEventViewDateTime = (date: MomentInput): string => {
    if (!date) {
      return '';
    }
    const myDate = moment(date);
    return myDate.format(MOMEMT_HOS_EVENT_VIEW_FORMAT_TIME);
  };
}
