export const LOADAI_ELEMENTS = {
  OPTIMIZE_BUTTON: 'bulk-optimization',
  NEW_OPTIMIZATION: 'new-bulk-optimization',
  DRIVER_PREFERENCES: 'loadai-driver-preferences',
  RESUME_OPTIMIZATION: 'resume-bulk-optimization',
  LOCK_ACTION: 'assignment-lock-action',
  REJECT_ACTION: 'assignment-reject-action',
  POSSIBLE_OPTIONS: 'possible-options',
  BULK_OPTIMIZATION_EVALUATE_BUTTON: 'bulk-optimization-evaluation',
  POSSIBLE_OPTION_LOCK: 'possible-option-lock-action',
  POSSIBLE_OPTION_REJECT: 'possible-option-reject-action',
  DRIVER_SELECTION_RECOMMEND_DRIVERS: 'driver-selection-recommend-drivers',
  GAP_RECOMMEND_TRIPS: 'gap-recommend-trips',
  EVALATION_RECOMMEND_DRIVERS: 'evaluation-recommend-drivers',
  EVALUATION_RECOMMEND_TRIPS: 'evaluation-recommend-trips',
  DRIVER_TOOLTIP_RECOMMEND_TRIPS: 'driver-tooltip-recommend-trips',
  DRIVER_TRIP_EVALUATION: 'dispatch-driver-trip-evaluation',
  OPTIMIZATION_TRIP_OPTIONS_BUTTON: 'trip-options',
  TRIP_BULK_TRAY_OPTIMIZATION: 'trip-bulk-tray-optimization',
  TRIP_DRIVER_EVALUATION: 'dispatch-trip-driver-evaluation',
};

export const DISPATCH_ELEMENTS = {
  GAP_TOOLTIP_ASSIGN_TRIP: 'manual-assign-trip',
};
