export const KPI_BORDER_COLOR_BY_FACTOR = {
  '0': '#E0E0E0',
  '1': 'rgba(244, 67, 54, 0.50)',
  '2': 'rgba(237, 157, 2, 0.50)',
  '3': 'rgba(76, 175, 80, 0.50)',
};

export const KPI_COLOR_BY_FACTOR = {
  '0': 'inherit',
  '1': 'rgba(244, 67, 54, 0.08)',
  '2': 'rgba(237, 157, 2, 0.08)',
  '3': 'rgba(76, 175, 80, 0.08)',
};

export const DEFAULT_KPIS_DISPLAY_COUNT = 12;
