import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import { Box, useTheme } from '@mui/material';
import { MomentInput } from 'moment';
import { FunctionComponent } from 'react';
import IconsComponent from '../IconsComponent';
import CommonExpandableTimeline, {
  CommonExpandableTimelineContentProps,
  TimelineVariantType,
} from './components/CommonExpandableTimeline';
import { TimelineFormatter } from './utils/TimelineFormatter';

export interface ExpandableTimelineProps {
  timelineContent: Array<ExpandableTimelineContentProps>;
  withAccordian?: boolean;
  expandedContent?: JSX.Element;
  variant?: TimelineVariantType;
}

interface LocationDetailsProps {
  address: string;
  lat: number;
  lon: number;
}

export interface StopSolutionProp {
  appointmentStartTime: MomentInput;
  appointmentEndTime: MomentInput;
  mileage: number;
  eta: MomentInput;
  etd: MomentInput;
  actualArrivalTime: MomentInput;
  actualDepartureTime: MomentInput;
  appointmentWork: string;
  locationDetails: LocationDetailsProps;
}

export interface ExpandableTimelineContentProps {
  loadId: string;
  deadhead: number;
  loadedMiles: number;
  stopSolutions: StopSolutionProp[];
}

export const ExpandableTimeline: FunctionComponent<ExpandableTimelineProps> = ({
  timelineContent,
  expandedContent,
  variant = 'outlined' as TimelineVariantType,
}) => {
  const theme: any = useTheme();
  const StyledDetailedDataSection = ({
    icon,
    title,
    description,
    waitingTime = false,
  }: {
    icon: JSX.Element;
    title: string;
    description: string;
    waitingTime?: boolean;
  }) => {
    return (
      <Box
        sx={{
          padding: '5px 0',
          borderBottom: `1px solid ${theme.palette.divider}`,
          display: 'inline-flex',
          width: '100%',
          '&:last-child': {
            borderBottom: 'none',
          },
        }}
      >
        <Box
          component="span"
          sx={{
            width: '25px',
          }}
        >
          {icon}
        </Box>
        <Box>
          <Box
            component="span"
            sx={{
              ...theme.typography?.body2Bold,
              color: theme.palette.text.primary,
              paddingLeft: '5px',
              ...(waitingTime
                ? {
                    color: theme.palette.text.secondary,
                  }
                : {}),
            }}
          >
            {title}
          </Box>
          <Box
            component="span"
            sx={{
              ...theme.typography.body2,
              color: theme.palette.text.primary,

              paddingLeft: '5px',
              ...(waitingTime
                ? {
                    color: theme.palette.text.secondary,
                  }
                : {}),
            }}
          >
            {description}
          </Box>
        </Box>
      </Box>
    );
  };
  const DetailedDataSection = ({ item }: { item: StopSolutionProp }) => {
    const { arrivalTime, departureTime } =
      TimelineFormatter.getDisplayAppointmentDate(item.eta, item.etd);
    return (
      <Box>
        <StyledDetailedDataSection
          icon={
            <IconsComponent
              source="AxeleIcon"
              iconName="ArrivalTime"
              styleProps={{ width: '18px', height: '25px' }}
            />
          }
          title={arrivalTime}
          description={'Arrival Time'}
        />
        <StyledDetailedDataSection
          waitingTime={true}
          icon={
            <IconsComponent
              source="AxeleIcon"
              iconName="WaitingTime"
              styleProps={{ width: '16px', height: '25px' }}
            />
          }
          title={'5h'}
          description={'Time at Location (2 Hours Waiting)'}
        />
        <StyledDetailedDataSection
          icon={
            <IconsComponent
              source="AxeleIcon"
              iconName="DepartureTime"
              styleProps={{ width: '17px', height: '25px', paddingTop: '4px' }}
            />
          }
          title={departureTime}
          description={'Departure Time'}
        />
      </Box>
    );
  };

  const stopTypeList = ['Pickup', 'Stop', 'DropOff'];
  const PickUpIndex = 0;
  const DropOffIndex = timelineContent[0].stopSolutions.length - 1;

  const getLeftTitle = (stopIndex: number) => {
    if (stopIndex === PickUpIndex) {
      return stopTypeList[0];
    } else if (stopIndex === DropOffIndex) {
      return stopTypeList[2];
    } else {
      return `${stopTypeList[1]} ${stopIndex}`;
    }
  };

  const timelineContentArr: CommonExpandableTimelineContentProps<StopSolutionProp>[] =
    timelineContent[0].stopSolutions.map((stopSolution, stopIndex) => {
      return {
        id: (stopIndex + 1).toString(),
        variant: variant,
        needsConnector: 'block',
        isHideCollapse: false,
        renderLeftTitle: () => {
          return getLeftTitle(stopIndex);
        },
        renderLeftSubTitles: () => {
          return TimelineFormatter.getLeftSubTitleDateTime(
            stopSolution.appointmentStartTime,
            stopSolution.appointmentEndTime
          );
        },
        renderTimelineSeparatorIcon: (isActive: boolean) => {
          if (isActive) {
            return (
              <CheckCircleIcon
                fontSize="small"
                color={isActive ? 'primary' : 'disabled'}
              />
            );
          } else {
            return (
              <TripOriginOutlinedIcon
                fontSize="small"
                color={isActive ? 'primary' : 'disabled'}
              />
            );
          }
        },
        renderRightTitle: () => {
          return stopSolution.locationDetails.address;
        },
        renderRightSubTitle: () => <></>,
        renderRightSubContent: () => {
          return <DetailedDataSection item={stopSolution} />;
        },
        originalData: stopSolution,
      };
    });
  console.log('timelineContentArr*****', timelineContentArr);
  return (
    <CommonExpandableTimeline<StopSolutionProp>
      timelineContent={timelineContentArr}
      expandedContent={expandedContent}
    />
  );
};

export default ExpandableTimeline;
