import { action, makeObservable, observable } from 'mobx';
import { EventHoldLoadActionData } from '../../EventEmitter/Events/EventHoldLoadAction';
import { tripService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import { ISettingsPagination } from '../../contexts/TeamDriverContext';
import {
  GetRemoveLoadManifestDataResp,
  PaginatedTripList,
  PreferencesDTO,
  Trip,
  TripsRevenueTotalResponse,
} from '../../models';
import {
  GetTripDetailByIdQueryParams,
  PaginatedTripListRequest,
  TripWarningByIdsRequest,
} from '../../models/DTOs/Trip/Requests';
import { getMissingDocumentsWarning } from '../../subPages/loadsList/LoadController';
import { LoadListWarning } from '../../subPages/loadsList/models/LoadListWarning';
import { View } from '../../types';
import { loadAndTripPageSize } from '../../utils';
import { E3DotMenuType } from '../../views/myLoads/components/LoadDetailDialog/types';
import { defaultFilters } from '../../views/trips/components/TripDataGrid/grid.config';
import { allTrips } from '../../views/trips/components/ViewSection/constants';
import {
  GetUpdatedManifestTripListRequest,
  ITripFilters,
  ITripQueryParamsTable,
} from '../../views/trips/constants/types';
import { getQueryParamsTable } from '../../views/trips/services/queryParams.utils';

const defaultSettingsPagination: Partial<ISettingsPagination> = {
  isLoading: true,
  first: false,
  last: false,
  pageNumber: 0,
  pageSize: loadAndTripPageSize,
};

class TripStore {
  @observable staticData = {};
  @observable currentView: View = allTrips;
  @observable companyPreferences: PreferencesDTO | null = null;
  @observable addNewClicked = false;
  @observable tableList = [];
  @observable settingsPagination = defaultSettingsPagination;
  @observable totalTableItems = 0;
  @observable revenueTotalResponse: TripsRevenueTotalResponse | null = null;
  @observable filters = defaultFilters;
  @observable selectedRowId: string | null = null;
  @observable selectedItem: Trip | null = null;
  @observable showRenderFinance = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setAddNewClicked(value: boolean) {
    this.addNewClicked = value;
  }
  @action
  setCurrentView(view: View) {
    this.currentView = view;
  }

  // trip context

  @action
  async updateWarning(response: PaginatedTripList, newTableList: Trip[]) {
    const warningsResponse = await tripService.getTripWarningsByIds(
      new TripWarningByIdsRequest(response.content.map(({ id }) => id))
    );
    const warnings: { [key: string]: any } = {};
    if (!(warningsResponse instanceof ServiceError)) {
      warningsResponse?.data?.forEach((w: any) => {
        warnings[w.tripId] = w.warning;
      });
    }

    response.content = response.content.map((trip) => {
      if (warnings[trip.id]) {
        const tripListWarning = new LoadListWarning(warnings[trip.id], 'Trip');
        tripListWarning?.setupDetentionTime(
          trip.status,
          trip.tripDetentionTime
        );
        return {
          ...trip,
          missingDocuments: getMissingDocumentsWarning(warnings[trip.id]),
          warning: tripListWarning,
        };
      }
      return trip;
    });
    const newContent: any = newTableList.map((preRecord) => {
      const newRecord = response.content.find(
        (item) => item.id === preRecord.id
      );
      if (newRecord) {
        return newRecord;
      }
      return preRecord;
    });
    this.tableList = newContent;
  }

  @action
  updateTripWarning(response: PaginatedTripList, newTableList: Trip[]) {
    response.content = response.content.map((trip) => {
      const warning = trip.tripWarning;
      if (warning) {
        const tripListWarning = new LoadListWarning(warning, 'Trip');
        tripListWarning?.setupDetentionTime(
          trip.status,
          trip.tripDetentionTime
        );
        return {
          ...trip,
          missingDocuments: getMissingDocumentsWarning(warning),
          warning: tripListWarning,
        };
      }
      return trip;
    });
    const newContent: any = newTableList.map((preRecord) => {
      const newRecord = response.content.find(
        (item) => item.id === preRecord.id
      );
      if (newRecord) {
        return newRecord;
      }
      return preRecord;
    });
    this.tableList = newContent;
  }

  @action
  getTripsRevenueTotal = async (
    queryParams: ITripQueryParamsTable
  ): Promise<void> => {
    const response: any = await tripService.getTripsRevenueTotal(
      new PaginatedTripListRequest(queryParams)
    );

    if (!(response instanceof ServiceError) && response !== null)
      this.revenueTotalResponse = response;
  };

  @action
  getTableList = async (queryParams: ITripQueryParamsTable): Promise<void> => {
    this.settingsPagination = {
      ...this.settingsPagination,
      isLoading: true,
    };
    try {
      if (queryParams.pageNumber === 1) {
        this.getTripsRevenueTotal(queryParams);
      }
      const response = await tripService.getPaginatedTripList(
        new PaginatedTripListRequest(queryParams)
      );
      if (response instanceof PaginatedTripList) {
        this.settingsPagination = {
          ...this.settingsPagination,
          isLoading: false,
          first: response.first,
          last: response.last,
          pageNumber: response.number + 1,
        };
        const items = response.content || [];
        const newTableList: any = !response.number
          ? items
          : [...this.tableList, ...items];

        this.tableList = newTableList;
        this.totalTableItems = response.totalElements;
        this.updateTripWarning(response, newTableList);
      }
    } catch (e) {
      this.settingsPagination = {
        ...this.settingsPagination,
        isLoading: false,
      };
    }
  };

  @action
  updateMainData = async (
    {
      deletedIds,
      updatedIds,
    }: {
      deletedIds?: string[];
      updatedIds?: string[];
    },
    callback?: (trips: Trip[]) => void
  ) => {
    let response = new PaginatedTripList();
    let newTableList: any = this.tableList.concat();
    const newDeletedIds: string[] = deletedIds || [];
    if (updatedIds) {
      const content = await updatedIds.reduce<Promise<Trip[]>>(
        async (listP, tripId): Promise<Trip[]> => {
          const tripResponse = await tripService.getTripDetailById(
            new GetTripDetailByIdQueryParams({
              tripId: tripId,
            })
          );
          const list = await listP;
          if (tripResponse instanceof ServiceError || !tripResponse.id) {
            newDeletedIds.push(tripId);
            return list;
          }
          return [...list, tripResponse];
        },
        Promise.resolve([])
      );
      if (content) {
        response = new PaginatedTripList();
        response.content = content;
        const items: Trip[] = response.content || [];
        items.forEach((item) => {
          const index = newTableList.findIndex(
            ({ id }: Trip) => id === item.id
          );
          if (index < 0) {
            newTableList.unshift(item);
          } else {
            newTableList.splice(index, 1, item);
          }
        });
      }
    }
    newTableList = newTableList.filter(
      ({ id }: any) => !newDeletedIds.includes(id)
    );
    this.tableList = newTableList;
    if (response.content?.length) {
      await this.updateWarning(response, newTableList);
    }
    callback?.(newTableList);
  };

  @action
  fetchMainData = async ({
    nextPageNumber,
    nextFilters,
  }: {
    nextPageNumber: number;
    nextFilters: ITripFilters;
  }) =>
    this.getTableList(
      getQueryParamsTable({
        nextPageNumber: nextPageNumber,
        nextFilters: nextFilters,
        pageSize: this.settingsPagination.pageSize,
      })
    );

  @action
  updateSpecificRecord = async (record: any) => {
    this.tableList = this.tableList.filter((item: any) =>
      item.id == record.id ? record : item
    );
  };

  @action
  setSettingsPagination(settings: any) {
    this.settingsPagination = settings;
  }

  @action
  setFilters(filters: any) {
    this.filters = filters;
  }

  @action
  setSelectedItem(item: any) {
    this.selectedItem = item;
  }

  @action
  setSelectedRowId(id: any) {
    this.selectedRowId = id;
  }
  setShowRenderFinance = (value: boolean) => {
    this.showRenderFinance = value;
  };

  @action
  getMultipleTripData = async (
    queryParams: GetRemoveLoadManifestDataResp
  ): Promise<void> => {
    try {
      const response = await tripService.getMultipleTripLists(
        new GetUpdatedManifestTripListRequest({
          tripIds: queryParams.affectedTripIds,
        })
      );
      if (!(response instanceof ServiceError)) {
        let list = this.tableList.concat();
        if (Boolean(queryParams.removedTripIds.length)) {
          list = list.filter(
            (item: any) => item.id != queryParams.removedTripIds[0]
          );
        }
        const diffList = findArrayDiff(response, list, 'id');
        const updatedList = list?.map((el) => {
          const found = response?.find((s) => s['id'] === el['id']);
          if (found) {
            el = found;
          }
          return el;
        });
        if (diffList.uniqueArr1.length > 0) {
          updatedList.unshift(diffList.uniqueArr1[0]);
        }

        this.tableList = updatedList;
        const warningRes = new PaginatedTripList();
        warningRes.content = updatedList;
        await this.updateWarning(warningRes, updatedList);
      }
    } catch (e) {
      this.settingsPagination = {
        isLoading: false,
      };
    }
  };

  @action handleHoldOrReleaseLoadLocally = async ({
    tripIds,
    type,
  }: EventHoldLoadActionData) => {
    const newTableList: any = this.tableList.concat();
    newTableList.forEach((item: Trip) => {
      if (tripIds.includes(item.id)) {
        item.onHold = type === E3DotMenuType.MARK_AS_HOLD;
      }
    });
    this.tableList = newTableList;
  };
}

export function createTripStore() {
  return new TripStore();
}

export function findArrayDiff(
  arr1: { [x: string]: string }[],
  arr2: { [x: string]: string }[],
  key: any
) {
  const arr1Ids = arr1.map((obj: { [x: string]: string }) => obj[key]);
  const arr2Ids = arr2.map((obj: { [x: string]: string }) => obj[key]);

  const uniqueArr1 = arr1.filter(
    (obj: { [x: string]: string }) => !arr2Ids.includes(obj[key])
  );
  const uniqueArr2 = arr2.filter(
    (obj: { [x: string]: string }) => !arr1Ids.includes(obj[key])
  );

  return { uniqueArr1, uniqueArr2 };
}
