import { Type } from 'class-transformer';
import { docType } from '../../../utils/Doc';
import { EStopActivitiesAutofullOption } from '../../../views/settings/preferences/constants';
import { QueryParams } from '../commonMixed/QueryParams';

export class PreferencesGetRequestDTO extends QueryParams {
  organizationId!: number;
  constructor() {
    super();
  }
}

export class PreferencesRequestDTO extends QueryParams {
  organizationId!: number;
  constructor() {
    super();
  }
}

export class PreferencesDTO {
  billOfLadingMandatory?: boolean;
  collectLoadUnloadTiming?: boolean;
  calculateDetention?: boolean;
  documentsMandatory?: boolean;
  fixedCostPerDay?: number;
  id?: string;
  invoiceTerm?: string;
  invoiceTermValue?: string;
  loadPrefix?: string;
  manifestPrefix?: string;
  invoicePrefix?: string;
  locale?: string;
  organizationId?: number;
  proofOfDeliveryMandatory?: boolean;
  seqNumber?: number;
  defaultRadius?: string;
  settlementDate?: Date | null | undefined;
  settlementPeriod?: string;
  stopActivitiesAutofillOption?: EStopActivitiesAutofullOption;
  replyTo?: string;
  timezone?: string;
  trailerType?: string;
  variableCostPerMile?: number;
  workWeekStartDay?: string;
  @Type(() => CompanyDetailsDTO)
  companyDetails?: CompanyDetailsDTO;
  @Type(() => DashboardPreferenceDTO)
  dashboardPreferences?: DashboardPreferenceDTO;
  @Type(() => DriverPreferenceDTO)
  driverPreferences?: DriverPreferenceDTO;
  fieldsToBeSetNull?: Array<string> | null;
  tags?: Array<any> | null;
  apiKey?: string | null;
  apiKeyStatus?: boolean;
  enableGeofencing?: boolean;
  whiteListURLs?: string[];
  defaultAverageSpeed?: number;
}

export class CompanyDetailsDTO {
  @Type(() => CompanyAddressDTO)
  addressData?: CompanyAddressDTO;
  countryCode?: string;
  dot?: string;
  email?: string;
  extension?: string;
  fax?: string | null;
  faxCountryCode?: string;
  faxExtension?: string | null;
  isSupportTerminal?: boolean;
  mc?: string;
  name?: string;
  paymentToken?: string;
  phone?: string;
}

export class CompanyAddressDTO {
  address?: string;
  fullAddress?: string;
  streetAddress?: string;
  streetAddress2?: string;
  city?: string;
  state?: string | { name: string; value: string } | undefined;
  zipcode?: string;
  center?: center;
}

type center = {
  lat: number;
  lng: number;
};

export class DashboardPreferenceDTO {
  hideFinancialKPIs?: boolean;
  targetRevenuePerLoadedMile?: number;
  targetRevenuePerTotalMile?: number;
  targetWeeklyRevenue?: number;
}

export class DriverPreferenceDTO {
  dvirPreferences?: string;
  ignoreHOSRestrictions?: boolean;
  useSleeperBerthProvision?: boolean;
  workWeekType?: string;
  preferredTruckstops?: Array<string> | null;
  weeklyResetDay!: string | null;
  weeklyRevenueTarget!: string | number | null;
  weeklyEarningsTarget!: string | number | null;
  weeklyMileageTarget!: string | number | null;
  drivingBehaviourPreference!: string;
  locationPreference?: Array<any>;
  statesToAvoid?: Array<any>;
}

export class CompanyLogoGetRequestDTO extends QueryParams {
  organizationId!: number;
  constructor() {
    super();
  }
}

export class PreferencesPostRequestDTO extends QueryParams {
  organizationId!: number;
  constructor() {
    super();
  }
}

export class GenerateAPIRequest extends QueryParams {
  organizationId!: number;
  lastUpdated?: string;
  toggle?: boolean;
  Status?: boolean;
  delete?: boolean;
  constructor() {
    super();
  }
}

export enum Matrix {
  'US' = 'U.S',
  'PADD1B' = 'Central Atlantic',
  'PADD4' = 'Rocky Mountain',
  'PADD2' = 'Midwest',
  'PADD1A' = 'New England',
  'CALIFORNIA' = 'California',
  'PADD1' = 'East Coast',
  'PADD1C' = 'Lower Atlantic',
  'PADD3' = 'Gulf Coast',
  'PADD5' = 'West Coast',
  'PADD5EC' = 'West Coast less California',
}

export interface FuelMatrixType {
  id: string;
  organizationId: string;
  region: keyof typeof Matrix;
  useNationalAverage: boolean;
  price: string;
  defaultPrice: string;
}

export type PreferencesType = {
  dashboardPreferences: {
    targetRevenuePerLoadedMile: number;
    targetRevenuePerTotalMile: number;
    targetWeeklyRevenue: number;
  };
  variableCostPerMile: number;
  fixedCostPerDay: number;
  invoiceTerm: string;
  invoiceTermValue: number | null;
  loadPrefix: string;
  manifestPrefix: string;
  invoicePrefix: string;
  seqNumber: string | null;
  settlementDate: string;
  settlementPeriod: string;
  stopActivitiesAutofillOption: string;
  trailerType: string;
  collectLoadUnloadTiming: boolean;
  billOfLadingMandatory: boolean;
  proofOfDeliveryMandatory: boolean;
  documentsMandatory: boolean;
  driverPreferences: {
    dvirPreferences: string;
    workWeekType: string;
    preferredTruckstops: string[] | null;
    ignoreHOSRestrictions: boolean;
    useSleeperBerthProvision: boolean;
  };
  companyDetails: {
    name: string;
    dot: string;
    companyLogo: string;
    phone: string;
    fax: string;
    email: string;
    extension: string;
    countryCode: string;
    faxCountryCode: string;
    faxExtension: string;
    addressData: {
      address: string;
      city: string;
      fullAddress: string;
      state: string;
      streetAddress: string;
      zipcode: string;
    };
  };
  defaultAverageSpeed: number;
  replyTo: string;
  timezone: string;
  workWeekStartDay: string;
  locale: string;
  apiKey: string | null;
  fuelMatrix: Array<FuelMatrixType>;
};

export type PreferencesFormType = {
  dashboardPreferences: {
    targetRevenuePerLoadedMile: number;
    targetRevenuePerTotalMile: number;
    targetWeeklyRevenue: number;
  };
  variableCostPerMile: number;
  fixedCostPerDay: number;
  invoiceTerm: string;
  invoiceTermValue: number | null;
  loadPrefix: string;
  manifestPrefix: string;
  invoicePrefix: string;
  seqNumber: string;
  isSeqNumberManuallyUpdated?: boolean;
  settlementDate: string;
  settlementPeriod: string;
  stopActivitiesAutofillOption: string;
  trailerType: string;
  collectLoadUnloadTiming: boolean;
  billOfLadingMandatory: boolean;
  proofOfDeliveryMandatory: boolean;
  documentsMandatory: boolean;
  driverPreferences: {
    dvirPreferences: string;
    workWeekType: string;
    preferredTruckstops: { key: string; label: string }[] | null; //to be remove
    ignoreHOSRestrictions: boolean;
    useSleeperBerthProvision: boolean;
    weeklyResetDay?: number | string | null;
    weeklyRevenueTarget?: number | string | null;
    weeklyEarningsTarget?: number | string | null;
    weeklyMileageTarget?: number | string | null;
    drivingBehaviourPreference: string;
    statesToAvoid?: Array<any> | null;
    locationPreference?: Array<any> | null;
    considerTerminalLogo?: boolean;
  };
  companyDetails: {
    name: string;
    dot: string;
    companyLogo: string;
    phoneData: {
      phone: string;
      extension: string;
      countryCode: string;
    };
    faxData: {
      phone: string;
      extension: string;
      countryCode: string;
    };
    email: string;
    addressData: {
      address: string;
      city: string;
      fullAddress: string;
      state: string;
      streetAddress: string;
      zip: string;
    };
  };
  defaultAverageSpeed: number;
  logo?: docType[];
  fileChanged?: boolean;
  replyTo: string;
  timezone: string;
  workWeekStartDay: string;
  locale: string;
  apiKey: string | null;
  fuelMatrix: { [key: string]: Omit<FuelMatrixType, 'region'> };
};

export type PreferencesTabStripType = {
  label: string;
  key: string;
  iconName: string;
  source: string;
  name: string;
};
