import { t } from 'i18next';
import { useInvoices } from '../../../../contexts/InvoicesContext';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import { useInvoicePage } from '../../../../views/finance/invoices/InvoicePageContext';
import InvoiceActions from '../../InvoiceActions';
import { loadStatusListMap } from '../../constants';
import {
  EmailFormType,
  FactoringActionReturnType,
  InvoiceModel,
} from '../../models/InvoiceModel';
import {
  generateRefreshActionResponse,
  generateSendActionResponse,
  generateSubmitActionResponse,
  getValidInvoices,
} from '../../utils';
import { useStores } from '../../../../store/root.context';
import { SendToFactoringBulkDialog } from '../InvoiceActions/SendToFactoringAction';
import { SubmitForFundingDialog } from '../InvoiceActions/SubmitForFundingAction';

export default function FactoringActions({
  sendToFactoringDialogOpen,
  setSendToFactoringDialogOpen,
  refreshDialogOpen,
  setRefreshDialogOpen,
  submitForFundingDialogOpen,
  setSubmitForFundingDialogOpen,
  triggerAlert,
  invoices,
  handleFactoringResponse,
  invalidInvoices = {
    send: [],
    refresh: [],
    submit: [],
  },
  isFactorValueUpdated,
}: PropTypes) {
  const {
    invoiceStore: {
      currentView,
      selectedItem,
      setTableData,
      changeSelectionModel,
      tableData,
      selectionModel,
    },
  } = useStores();
  const handleBulkSend = async (data: EmailFormType) => {
    setSendToFactoringDialogOpen(false);
    let response;
    const validInvoices = getValidInvoices(invoices, invalidInvoices.send);
    if (invoices.length > 1) {
      response = await InvoiceActions.sendFactoringBulk(data, validInvoices);
    } else {
      response = await InvoiceActions.sendFactoring(data as any);
    }
    if (response) {
      generateSendActionResponse(response, triggerAlert);
      if (currentView.id === 'completed' || currentView.id === 'ready') {
        changeSelectionModel([]);
        const filteredArray = tableData.filter(
          (record1) => !selectionModel.some((record2) => record1.id === record2)
        );
        setTableData(filteredArray);
        isFactorValueUpdated?.();
      }
    }
  };

  const handleBulkRefresh = async () => {
    setRefreshDialogOpen(false);
    const validInvoices = getValidInvoices(invoices, invalidInvoices.refresh);
    const response = await InvoiceActions.refreshFactoringStatus(validInvoices);
    if (response) {
      handleFactoringResponse(response, selectedItem);
      generateRefreshActionResponse(response, triggerAlert);
    }
  };

  const handleBulkSubmit = async (data: any) => {
    setSubmitForFundingDialogOpen(false);
    const validInvoices = getValidInvoices(invoices, invalidInvoices.refresh);
    const response = await InvoiceActions.bulkSubmit(data, validInvoices);
    if (response) {
      handleFactoringResponse(response, selectedItem);
      generateSubmitActionResponse(response, triggerAlert);
    }
  };

  return (
    <>
      {sendToFactoringDialogOpen && (
        <SendToFactoringBulkDialog
          open={sendToFactoringDialogOpen}
          onAction={(data: any) => handleBulkSend(data)}
          onClose={() => setSendToFactoringDialogOpen(false)}
          invoices={invoices}
        />
      )}
      {refreshDialogOpen && (
        <DeletePopup
          open={refreshDialogOpen}
          onAction={handleBulkRefresh}
          onClose={() => setRefreshDialogOpen(false)}
          title={t('refreshWarningTitle')}
          cancelText={t('cancel')}
          buttonText={t('yes')}
        />
      )}
      {submitForFundingDialogOpen && (
        <SubmitForFundingDialog
          open={submitForFundingDialogOpen}
          onAction={handleBulkSubmit}
          onClose={() => setSubmitForFundingDialogOpen(false)}
          invoices={invoices}
        />
      )}
    </>
  );
}

type PropTypes = {
  sendToFactoringDialogOpen: boolean;
  setSendToFactoringDialogOpen: (prop: boolean) => void;
  refreshDialogOpen: boolean;
  setRefreshDialogOpen: (prop: boolean) => void;
  submitForFundingDialogOpen: boolean;
  setSubmitForFundingDialogOpen: (prop: boolean) => void;
  isFactorValueUpdated?: () => void | undefined;
  triggerAlert: (
    actionName: string,
    message: string,
    subMessage?: string
  ) => void;
  invoices: InvoiceModel[];
  handleFactoringResponse: (
    response: FactoringActionReturnType,
    selectedItem: InvoiceModel | null
  ) => void;
  invalidInvoices?: {
    send: InvoiceModel[];
    refresh: InvoiceModel[];
    submit: InvoiceModel[];
  };
};
