import { Scheduler } from '@bryntum/schedulerpro';
import { Box } from '@mui/material';
import {
  GanttChart as GC,
  IPuckTooltipPropsOnClick,
  NavigateToTypes,
  ResourceColumn,
  TooltipActionType,
  TooltipFooterContentType,
  ZoomChangeTypes,
} from '@optym/gantt';
import { observer } from 'mobx-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import StorageManager from '../../../../StorageManager/StorageManager';
import {
  IHOSDialogloadData,
  UpdateHOSDialog,
} from '../../../../common/UpdateHOS';
import { useDispatchFindLoad } from '../../../../common/hooks/useDispatchFindLoad';
import { useDispatchPermission } from '../../../../common/hooks/useDispatchPermission';
import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import {
  RootStoreInstence,
  useRootStore,
} from '../../../../store/root-store/rootStateContext';
import { performNaivgation } from '../../../../utils/commonNavigation';
import {
  DRIVER_COLUMN__DEFAULT_WIDTH,
  DRIVER_COLUMN_MAX_WIDTH,
  GANTT_DEFAULT_ZOOM_LEVEL,
  GAP_TRIP_ASSIGNMENT_ID,
} from '../../../../views/dispatch2/constants/gantt.const';
import {
  CLEAR_EMPTY_TRIP_ASSIGNMENT,
  EMPTY_TRIP_ASSIGNMENT,
} from '../../../../store/pageStores/dispatch';
import {
  EMPTY_TRIP_ACTION_KEYS,
  GANTT_WINDOW_SIZE,
  ganttDefaultControlsConfig,
  VIEW_PRESET_BY_WINDOW,
  ZOOM_LEVELS,
} from '../../../../constants/gantt/gantt.const';
import {
  defaultDriverDetailColumns,
  defaultResourceInfoColumnDetails,
  getDriverColumnHeaderText,
  resourceInfoColumnElProps,
} from '../../config';
import {
  defaultDispatch2Filters,
  ganttPageSize,
} from '../../constants/dispatch';
import { DriverGroupWarningType } from '../../../../constants/gantt/ganttWarnings.const';
import {
  DispatchFiltersName,
  EGanttTabPanel,
  ESecondaryDetailsPanelType,
  EVENT_STATUS,
  IDispatchFilters,
} from '../../constants/types';
import { useDriverScheduler } from '../../hooks';
import { useDispatchStorage } from '../../hooks/useDispatchStorage';
import {
  addDaysToDate,
  formatDriverScheduleParams,
  formatDriverSchedulerFilters,
  formatGanttFilter,
  formatLoadBoardData,
  getGanttColumnFileds,
  getPuckTooltipProps,
  getScheduleListFromMap,
  isDateSame,
  isGapNextTripDispatched,
  isTripTooltipAllowed,
  isTripTypeGap,
  unassignTripFromDriver,
} from '../../services';
import '../../styles/columns.css';
import {
  HosConstantsType,
  IGanttSubTitle,
  SearchLoadBoardsType,
  TRIP_TYPE,
} from '../../types';
import ConfirmTripAssignmentCancel from '../common/CancelTripAssignmentPopup';
import {
  GANTT_SELECTION_MODE_CONFIG,
  GANTT_TIMELINE_DATE_RANGE,
  defaultGanttProps,
  defaultTripColumnDetails,
  locationColumnElProps,
} from '../../config/gantt.config';
import { getColumnToolTipProps } from '../../../../services/gantt/driverTooltip.service';
import {
  IDriverSceduleRequestPayload,
  ScheduleDriverType,
  ScheduleTripType,
  IDriverScheduleRequestParams,
  IGanttFilters,
} from '../../../../types/DispatchTypes';
import { useStores } from '../../../../store/root.context';
import { CommonScrollBarStyles } from '../../../../styles/common.styles';
import {
  getPreferredGanttStartDate,
  isVisibleDateOverEndDate,
  isVisibleDateOverStartDate,
} from '../../../../services/gantt';
import { GanttSkeleton } from '../../../../skeleton';
import { useLoadAiPermission } from '../../../../common/hooks/useLoadaiPermission';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import {
  DRIVER_TOOLTIP_ACTION_KEYS,
  TRIP_TOOLTOP_ACTION_KEYS,
} from '../../../../constants/gantt/columnTooltip.config';
import { ServiceError } from '../../../../api/interfaces';
import '@bryntum/schedulerpro/thin/grid.classic.thin.css';
import { useTranslation } from 'react-i18next';

interface IGanttLayout {
  ganttStartDate?: Date;
  onTripView?: (tripData: ScheduleTripType) => void;
  onLoadView?: (loadData: object) => void;
  onDriverDetailView?: (driverGroupId: string) => void;
  onSubTitleClick?: (data: IGanttSubTitle) => void;
  onStartDateChange?: (windowSize: number, navTo: NavigateToTypes) => void;
  onAssignTrip?: () => void;
}

let CURRENT_GANTT_COLUMN_WIDTH: number;

interface ISchedulerRef extends Scheduler {
  instance?: any;
}

const GanttChart: React.FC<IGanttLayout> = (props) => {
  const { ganttStartDate, onStartDateChange, onSubTitleClick, ...restProps } =
    props;
  const navigate = useNavigate();
  const { isMobile } = useThemeResponsive();
  const { getGlobalTerminals } = useRootStore();
  const { setFindLoadData } = useDispatchFindLoad();
  const { getCurrentStorage, updateStorage } = useDispatchStorage();
  const { hasDispatchFindLoadPermission } = useDispatchPermission();
  const { hasBulkOptimizationPermission } = useLoadAiPermission();
  const {
    dispatch2GlobalStore: {
      setTripAssignmentForGap,
      gapForTripAssignment,
      filters,
      setFilters,
      // displayMode,
      updateBulkOptymizationDriverInput,
      removeDriverFromBulkOptymization,
      setAllDriversCount,
      setDriverFilters,
      ganttColumnToHide,
      startBulkOptymization,
    },
    bulkOptymizationStore: { setOptimizationRequestResources },
  } = useStores();

  const [zoomLevel, setZoomLevel] = useState<number>(
    getCurrentStorage()?.filters?.[DispatchFiltersName.zoomLevel] ??
      defaultDispatch2Filters.filters[DispatchFiltersName.zoomLevel]
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [ganttFilters, setGanttFilters] = useState<IGanttFilters>();
  const [ganttEndDate, setGanttEndDate] = useState<Date | string>();
  const [hosDialogloadData, setHosDialogloadData] =
    useState<IHOSDialogloadData | null>(null);
  const [cancelTripAssignment, setCancelTripAssignment] =
    useState<boolean>(false);
  const [previousTripAssignDriver, setPreviousTripAssignDriver] = useState<{
    id: string;
    driverGroupId: string;
  } | null>(null);
  const { hasLoadCRUDPermission } = useLoadPermission();
  const schedulerRef = useRef<ISchedulerRef>();

  const GANTT_DEFAULT_START_DATE = defaultDispatch2Filters.filters[
    DispatchFiltersName.startDate
  ]?.dateRange?.[0] as Date;
  const getDriverSchedulerPayload = (): IDriverSceduleRequestPayload =>
    formatDriverSchedulerFilters(ganttFilters);
  const getDriverSchedulerParams = (): IDriverScheduleRequestParams =>
    formatDriverScheduleParams({ sort: ganttFilters?.sort });

  const {
    driverSchedule,
    updateDriverSchedule,
    updateTripsByActionType,
    ganttAvailableDataRange,
    ganttVisibleDate,
  } = useDriverScheduler({
    requestPayload: getDriverSchedulerPayload(),
    requestParams: getDriverSchedulerParams(),
    ganttEndDate,
    onScheduleRequestFailure: () => setPageNumber((prev) => prev - 1),
    onDriversCountUpdate: (count: number) => setAllDriversCount(count),
  });
  useEffect(() => {
    setPageNumber(1);
    cancelTripAssignmentProcess();
    const formattedFilters = formatGanttFilter({
      filters: filters?.filters,
      terminalIds: getGlobalTerminals,
      pageNumber: 1,
    });
    setGanttFilters(formattedFilters);
    setDriverFilters(formattedFilters);
    return () => {
      setTripAssignmentForGap(null);
    };
  }, [JSON.stringify(filters), JSON.stringify(getGlobalTerminals)]);

  useEffect(() => {
    if (!gapForTripAssignment?.id) {
      if (previousTripAssignDriver?.id) {
        updateDriverRowClassName(previousTripAssignDriver?.driverGroupId, '');
        updateTripsByActionType(CLEAR_EMPTY_TRIP_ASSIGNMENT, {
          tripId: previousTripAssignDriver?.id,
          resourceId: previousTripAssignDriver?.driverGroupId,
        });
        setPreviousTripAssignDriver(null);
      }
      return;
    }
    const { id, driverGroupId } = gapForTripAssignment;
    setPreviousTripAssignDriver({ id, driverGroupId });
    updateDriverRowClassName(driverGroupId, 'b-highlight-grid-row');
  }, [gapForTripAssignment?.id, previousTripAssignDriver?.id]);

  const cancelTripAssignmentProcess = () => {
    if (!gapForTripAssignment?.id) return;
    clearGapTripAssignment({
      tripId: gapForTripAssignment?.id,
      resourceId: gapForTripAssignment?.driverGroupId,
    });
    updateDriverRowClassName(gapForTripAssignment?.driverGroupId, '');
    setTripAssignmentForGap(null);
  };

  const updateDriverRowClassName = (driverId: string, className: string) => {
    if (!schedulerRef?.current?.instance) return;
    const driverRowElement =
      schedulerRef.current.instance?.resourceStore?.getById?.(driverId);
    if (driverRowElement) driverRowElement.cls = className;
  };

  const updateDriverColumnWidthPreference = (
    ganttColumnWidth = CURRENT_GANTT_COLUMN_WIDTH
  ) => {
    updateUserPreferredSettings(
      {
        ganttColumnWidth,
      },
      'COLUMN'
    );
  };
  useEffect(() => {
    if (!ganttStartDate || !(zoomLevel in GANTT_WINDOW_SIZE)) return;
    setGanttEndDate(
      addDaysToDate(ganttStartDate, GANTT_WINDOW_SIZE[zoomLevel])
    );
    updateUserPreferredSettings({ zoomLevel });
  }, [JSON.stringify(ganttStartDate), zoomLevel]);

  const updateUserPreferredSettings = (
    updatedVals: {
      ganttColumnWidth?: number;
      zoomLevel?: number;
    },
    field?: 'COLUMN'
  ): void => {
    if (field === 'COLUMN' && updatedVals.ganttColumnWidth === 0) return;
    updateStorage({
      ...getCurrentStorage(),
      filters: {
        ...getCurrentStorage().filters,
        ...updatedVals,
      },
    });
  };

  const disableGanttResetMemo = useMemo(() => {
    if (
      isDateSame(ganttStartDate!, GANTT_DEFAULT_START_DATE) &&
      zoomLevel == GANTT_DEFAULT_ZOOM_LEVEL
    )
      return true;
    return false;
  }, [ganttStartDate, zoomLevel]);

  const handleZoomChange = (zoomType: ZoomChangeTypes) => {
    if (!isGanttActionAllowed() || !schedulerRef?.current?.instance) return;
    let zoomLevel = schedulerRef?.current?.instance?.zoomLevel;
    if (zoomType === 'OUT') {
      zoomLevel--;
      schedulerRef.current.instance.zoomLevel -= 1;
    } else {
      zoomLevel++;
      schedulerRef.current.instance.zoomLevel += 1;
    }
    setZoomLevel(zoomLevel);
    updateUserPreferredSettings({ zoomLevel });
  };
  const handleGanttNavigation = (navTo: NavigateToTypes) => {
    if (navTo === 'LEFT') {
      onStartDateChange?.(GANTT_TIMELINE_DATE_RANGE, navTo);
      return;
    }
    onStartDateChange?.(GANTT_TIMELINE_DATE_RANGE, navTo);
  };
  const handleGanttReset = () => {
    if (!isGanttActionAllowed()) return;
    setFilters((prev: IDispatchFilters) => ({
      ...prev,
      filters: {
        ...filters?.filters,
        [DispatchFiltersName.startDate]:
          defaultDispatch2Filters.filters[DispatchFiltersName.startDate],
        zoomLevel: GANTT_DEFAULT_ZOOM_LEVEL,
      },
    }));
    setZoomLevel(GANTT_DEFAULT_ZOOM_LEVEL);
  };

  const handleTooltipAction = (data: TRIP_TYPE) => {
    const { type, status, resourceId } = data?.eventRecord?.data;
    if (type === EVENT_STATUS.EMPTY || status === EVENT_STATUS.NEED_LOAD) {
      if (data?.action?.key === EMPTY_TRIP_ACTION_KEYS.ASSIGN) {
        cancelTripAssignmentProcess();
        updateEmptyTripForAssignment(
          data?.eventRecord?.data,
          data?.resourceRecord?.data?.driverGroupDetails?.groupName
        );
        delayExecution(() => {
          restProps?.onAssignTrip?.();
          delayExecution(() =>
            schedulerRef?.current?.instance?.scrollRowIntoView(resourceId)
          );
        });
        return;
      }
      if (data?.action?.key === EMPTY_TRIP_ACTION_KEYS.OPTIMIZE) {
        setOptimizationRequestResources({
          driverIds: [data?.eventRecord?.data?.resourceId],
          tripIds: [],
        });
        startBulkOptymization();
        return;
      }
      navigateToFindLoad(
        formatLoadBoardData({
          eventRecord: data?.eventRecord?.data,
          resourceRecord: data?.resourceRecord?.data,
        })
      );
      return;
    }
    if (data?.action?.key === TRIP_TOOLTOP_ACTION_KEYS.VIEW_DETAILS)
      return restProps?.onTripView?.(data?.eventRecord?.data);
    else if (data?.action?.key === TRIP_TOOLTOP_ACTION_KEYS.UNASSIGN)
      handleUnassignTrip(data?.eventRecord?.data?.tripPrimaryId);
  };

  const handleUnassignTrip = async (tripId: string) => {
    try {
      const response = await unassignTripFromDriver({ tripId });
      if (response instanceof ServiceError)
        return RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'tripUnAssignDriver',
        });
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tripUnAssignDriver',
      });
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tripUnAssignDriver',
      });
    }
  };

  const delayExecution = (callback: any) => setTimeout(() => callback?.());

  const updateEmptyTripForAssignment = (
    trip: ScheduleTripType,
    driverGroupName?: string
  ) => {
    const { id, resourceId, startDate, endDate, previousId, nextId } = trip;
    const driver = driverSchedule?.drivers?.find(
      (driver: ScheduleDriverType) => driver.id === trip?.resourceId
    );
    const gapDetails = {
      id,
      driverGroupId: resourceId,
      startDate,
      endDate,
      prevLoadId: previousId,
      nextLoadId: nextId,
      terminalId: driver?.terminalId,
      driverGroupName,
    };
    setTripAssignmentForGap(gapDetails);
    updateTripsByActionType(EMPTY_TRIP_ASSIGNMENT, {
      resourceId,
      tripId: trip?.id,
      windowStart: ganttAvailableDataRange?.startDate,
      windowEnd: ganttAvailableDataRange?.endDate,
    });
  };

  const puckTooltipCallback = (
    tripData: any
  ): IPuckTooltipPropsOnClick | boolean => {
    const tripDetails = tripData?.eventRecord?.data;
    let allowActions = true;
    if (isTripTypeGap(tripDetails)) {
      allowActions = !isGapNextTripDispatched(
        tripDetails,
        driverSchedule?.trips?.get?.(tripDetails?.resourceId)!,
        driverSchedule?.timeoffs?.get?.(tripDetails?.resourceId)!
      );
    }
    if (isTripTooltipAllowed(tripDetails))
      return {
        ...getPuckTooltipProps({
          tripData,
          hosConstants: driverSchedule?.hosConstants as HosConstantsType,
          hasLoadCRUDPermission,
          hasBulkOptimizationPermission,
          allowActions,
        }),
        onActionClick: (action: any) =>
          handleTooltipAction({ ...tripData, action }),
        onSubTitleClick: (content: IGanttSubTitle | object) =>
          handleTooltipContentClick({
            ...content,
            driverId:
              tripData?.resourceRecord?.data?.driverGroupDetails?.driverId,
          } as IGanttSubTitle),
      } as IPuckTooltipPropsOnClick;
    return false;
  };

  const handleTooltipContentClick = (data: IGanttSubTitle) => {
    switch (data?.type) {
      case ESecondaryDetailsPanelType.LOAD:
        restProps?.onLoadView?.({ id: data?.value?.id });
        break;

      case ESecondaryDetailsPanelType.CUSTOMER:
      case ESecondaryDetailsPanelType.USER:
        onSubTitleClick?.(data);
        break;
    }
  };

  const handleDriverAcions = (
    data: TooltipActionType,
    driver: ScheduleDriverType
  ) => {
    const driverGroupId = driver?.driverGroupDetails?.id;
    if (data?.key === DRIVER_TOOLTIP_ACTION_KEYS.RECOMMEND_TRIPS) {
      setOptimizationRequestResources({
        driverIds: [driverGroupId!],
        tripIds: [],
      });
      startBulkOptymization();
    } else restProps?.onDriverDetailView?.(driverGroupId!);
  };

  const driverColumnsMemo = useMemo(() => {
    return [
      {
        id: 'driverCount',
        text: getDriverColumnHeaderText(
          driverSchedule.numberOfDrivers,
          driverSchedule.currentWeekDate
        ),
        cls: 'resourceInfoHeaderCls',
        children: [
          {
            ...defaultResourceInfoColumnDetails,
            renderer: (event: any) => {
              const data = event?.record?.data;
              return (
                <ResourceColumn
                  {...resourceInfoColumnElProps(data)}
                  columnTooltipProps={{
                    ...getColumnToolTipProps({
                      ...data,
                      hasBulkOptimizationPermission,
                    }),
                    onSubTitleClick(titleContent: object) {
                      onSubTitleClick?.({
                        ...titleContent,
                        driverId: data?.driverGroupDetails?.driverId,
                      } as IGanttSubTitle);
                    },
                    onWarningAction(content: TooltipFooterContentType) {
                      handleTooltipWarningActions({
                        content,
                        driverId: data?.driverGroupDetails?.driverId,
                        driverGroupId: data?.id,
                        ignoreHOSRestrictions: data?.ignoreHOSRestrictions,
                      });
                    },
                    onActionClick: (action: TooltipActionType) => {
                      handleDriverAcions(action, data);
                    },
                  }}
                />
              );
            },
          },
          {
            ...defaultTripColumnDetails,
            renderer: (event: any) => {
              const { data } = event?.record;
              return (
                <ResourceColumn
                  {...locationColumnElProps(data)}
                  columnTooltipProps={{
                    ...getColumnToolTipProps({
                      ...data,
                      hasBulkOptimizationPermission,
                    }),
                    onSubTitleClick(titleContent: object) {
                      onSubTitleClick?.({
                        ...titleContent,
                        driverId: data?.driverGroupDetails?.driverId,
                      } as IGanttSubTitle);
                    },
                    onWarningAction(content: TooltipFooterContentType) {
                      handleTooltipWarningActions({
                        content,
                        driverId: data?.driverGroupDetails?.driverId,
                        driverGroupId: data?.id,
                      });
                    },
                    onActionClick: (action: TooltipActionType) => {
                      handleDriverAcions(action, data);
                    },
                  }}
                />
              );
            },
          },
          ...defaultDriverDetailColumns,
        ],
      },
    ];
  }, []);

  const handleTooltipWarningActions = ({
    content,
    driverId,
    driverGroupId,
    ignoreHOSRestrictions,
  }: {
    content: TooltipFooterContentType;
    driverId: number;
    driverGroupId: string;
    ignoreHOSRestrictions?: boolean;
  }) => {
    if (content.type === DriverGroupWarningType.DRIVER_HOS_WARNING)
      return onShowUpdateHOSDialogHandler({
        driverGroupId,
        driverId,
        ignoreHOS: ignoreHOSRestrictions,
      });
  };

  const handleGanttScroll = (e: any) => {
    const scrollelement = e?.source.byRef.bodyElement.children[1];
    if (
      scrollelement.scrollHeight -
        Math.ceil(scrollelement.scrollTop) -
        scrollelement.clientHeight -
        10 <=
      0
    ) {
      const expectedDataSize = driverSchedule.numberOfDrivers || 0;
      if (
        driverSchedule.isLoading ||
        (driverSchedule?.drivers as ScheduleDriverType[])?.length >=
          expectedDataSize
      )
        return;
      const page = pageNumber + 1;
      updateDriverSchedule({
        requestPayload: getDriverSchedulerPayload(),
        requestParams: {
          ...getDriverSchedulerParams(),
          pageNumber: page,
          pageSize: ganttPageSize,
        },
      });
      setPageNumber(page);
    }
  };

  const updateSchedulerColumnWidth = (scheduler: Scheduler) => {
    if (!scheduler?.subGrids?.locked?.width) return;
    const preferredColumnWidth =
      getCurrentStorage()?.filters?.[DispatchFiltersName.ganttColumnWidth];
    scheduler.subGrids.locked.width = preferredColumnWidth
      ? Number(preferredColumnWidth)
      : DRIVER_COLUMN__DEFAULT_WIDTH;
    scheduler.subGrids.locked.maxWidth = DRIVER_COLUMN_MAX_WIDTH;
  };

  const updateGridWidthConst = () => {
    // if (displayMode !== EGanttTabPanel.Gantt) return;
    if (
      CURRENT_GANTT_COLUMN_WIDTH !==
      schedulerRef?.current?.instance?.columns?.grid?.subGrids?.locked?.width
    ) {
      CURRENT_GANTT_COLUMN_WIDTH =
        schedulerRef?.current?.instance?.columns?.grid?.subGrids?.locked?.width;
      updateDriverColumnWidthPreference();
    }
  };

  const navigateToFindLoad = (loadData: SearchLoadBoardsType) => {
    if (!loadData || !hasDispatchFindLoadPermission) return;
    setFindLoadData(loadData);
    performNaivgation('loadsearch', navigate);
  };
  useEffect(() => {
    if (
      schedulerRef?.current?.instance?.columns?.getById('driverCount')?.text
    ) {
      schedulerRef.current.instance.columns.getById('driverCount').text =
        getDriverColumnHeaderText(
          driverSchedule.numberOfDrivers,
          driverSchedule.currentWeekDate
        );
    }
  }, [driverSchedule?.numberOfDrivers]);

  const onCloseUpdateHOSDialog = (): void => setHosDialogloadData(null);

  const onShowUpdateHOSDialogHandler = (hosUpdateParams: IHOSDialogloadData) =>
    setHosDialogloadData(hosUpdateParams);

  const handleCancelTripAssign = () => {
    setCancelTripAssignment(false);
    cancelTripAssignmentProcess();
  };

  const confirmCancelTripAssigment = () => setCancelTripAssignment(true);
  const isGanttActionAllowed = () => {
    if (gapForTripAssignment?.id) {
      confirmCancelTripAssigment();
      return false;
    }
    return true;
  };

  const handleTripClick = (data: TRIP_TYPE) => {
    if (data?.event?.target?.id === GAP_TRIP_ASSIGNMENT_ID)
      confirmCancelTripAssigment();
  };
  const clearGapTripAssignment = ({
    tripId,
    resourceId,
  }: {
    tripId: string;
    resourceId: string;
  }) =>
    updateTripsByActionType(CLEAR_EMPTY_TRIP_ASSIGNMENT, {
      tripId,
      resourceId,
    });
  const handleDriverSelection = (event: any) => {
    if (!hasBulkOptimizationPermission) return;
    if (event.action === 'select') {
      const selectedIds = event?.selection?.map((driver: any) => driver?.id);
      updateBulkOptymizationDriverInput(selectedIds);
    }
    if (event.action === 'deselect') {
      const deselectedIds = event?.deselected?.map((driver: any) => driver?.id);
      removeDriverFromBulkOptymization(deselectedIds);
    }
  };

  const selectionModeConfigMemo = useMemo(() => {
    if (hasBulkOptimizationPermission) return GANTT_SELECTION_MODE_CONFIG;
    return {};
  }, [hasBulkOptimizationPermission]);

  // if (driverSchedule?.isInitialLoad && !driverSchedule?.drivers?.length)
  //   return <GanttSkeleton />;

  const handleWindowChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    if (!ganttAvailableDataRange || driverSchedule?.isLoading) return;
    if (
      isVisibleDateOverStartDate({
        startDate: ganttAvailableDataRange?.startDate,
        visibleDate: startDate,
      })
    ) {
      handleGanttNavigation('LEFT');
    } else if (
      isVisibleDateOverEndDate({
        endDate: ganttAvailableDataRange?.endDate,
        visibleDate: endDate,
      })
    ) {
      handleGanttNavigation('RIGHT');
    }
  };

  useEffect(() => {
    if (schedulerRef?.current) {
      schedulerRef.current?.instance?.scrollToDate?.(
        new Date(ganttVisibleDate),
        {
          block: 'start',
          animate: 400,
        }
      );
    }
  }, [
    JSON.stringify(ganttVisibleDate),
    JSON.stringify(ganttAvailableDataRange?.startDate),
  ]);

  const handleVisibleDateChange = (data: any) => {
    if (!ganttAvailableDataRange?.startDate || !ganttVisibleDate) return;
    updateGridWidthConst();
    handleWindowChange(data?.new);
  };

  return (
    <>
      <Box sx={CommonScrollBarStyles}>
        <GC
          {...defaultGanttProps}
          listeners={{
            visibleDateRangeChange(data: any) {
              handleVisibleDateChange(data);
            },
            buffer: 300,
          }}
          emptyText={
            driverSchedule?.isInitialLoad
              ? 'Loading...'
              : 'No records to display'
          }
          ref={schedulerRef}
          columns={driverColumnsMemo}
          onZoomChange={handleZoomChange}
          startDate={ganttAvailableDataRange?.startDate}
          endDate={ganttAvailableDataRange?.endDate}
          resources={driverSchedule?.drivers}
          events={getScheduleListFromMap(driverSchedule?.trips)}
          resourceTimeRanges={getScheduleListFromMap(driverSchedule?.timeoffs)}
          puckTooltipPropsOnClick={puckTooltipCallback}
          viewPreset={VIEW_PRESET_BY_WINDOW[zoomLevel]}
          ganttControlsConfig={{
            ...ganttDefaultControlsConfig,
            disableReset: disableGanttResetMemo,
            disableZoomIn: zoomLevel === ZOOM_LEVELS.MAX,
            disableZoomOut: zoomLevel === ZOOM_LEVELS.MIN,
            showControlActions: !isMobile,
          }}
          onScroll={handleGanttScroll}
          onReset={handleGanttReset}
          onGanttReady={updateSchedulerColumnWidth}
          timeRanges={getPreferredGanttStartDate()}
          loader={{ status: driverSchedule.isLoading }}
          onEventClick={handleTripClick}
          selectionMode={selectionModeConfigMemo}
          onSelectionChange={handleDriverSelection}
          hideColumns={getGanttColumnFileds(ganttColumnToHide)}
        />
        {hosDialogloadData && (
          <UpdateHOSDialog
            onClose={onCloseUpdateHOSDialog}
            loadData={hosDialogloadData}
          />
        )}
        {cancelTripAssignment && (
          <ConfirmTripAssignmentCancel
            onApprove={handleCancelTripAssign}
            onCancel={() => {
              setCancelTripAssignment(false);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default observer(GanttChart);
