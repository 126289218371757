import { Box } from '@mui/material';
import React from 'react';
import { TimelineFormatter } from '../../../AxeleTimeline/utils/TimelineFormatter';

import {
  StyledDetailedDataSectionContent,
  StyledDetailedDataSectionContentDescription,
  StyledDetailedDataSectionContentTitle,
} from '../../TripPlanV3/styles';
import {
  StopSolutionV3Prop,
  StopSolutionV3StopType,
} from '../../TripPlanV3/types';
import { TimeLineIconMapping } from '../timelineIconsMapping';
import {
  getDisplayArrivalTimeTitle,
  getDisplayDepartureTimeTitle,
  isContactEmpty,
} from './timelineV3.utils';
import { useTranslation } from 'react-i18next';

export const StyledDetailedDataSection = ({
  icon,
  title,
  description,
}: {
  icon: JSX.Element;
  title: React.ReactNode;
  description: React.ReactNode;
}) => {
  return (
    <StyledDetailedDataSectionContent>
      <Box
        component="span"
        sx={{
          width: '25px',
        }}
      >
        {icon}
      </Box>
      <Box
        sx={{
          whiteSpace: 'pre-wrap',
        }}
      >
        {title ? (
          <StyledDetailedDataSectionContentTitle component="span">
            {title}
          </StyledDetailedDataSectionContentTitle>
        ) : (
          <></>
        )}
        {description ? (
          <StyledDetailedDataSectionContentDescription component="span">
            {description}
          </StyledDetailedDataSectionContentDescription>
        ) : null}
      </Box>
    </StyledDetailedDataSectionContent>
  );
};

export const DetailedDataSection = ({
  stopSolution,
  idx,
  isCancelled = false,
  isCompleted = false,
}: {
  stopSolution: StopSolutionV3Prop;
  idx: number;
  isCancelled?: boolean;
  isCompleted?: boolean;
}): JSX.Element => {
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Box
        sx={{
          paddingLeft: '30px',
        }}
      >
        {!isCancelled ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.DriverActualMiles}
            title={`${TimelineFormatter.numberToMiles(
              stopSolution?.totalMiles
            )}`}
            description={idx === 0 ? t('emptyMile') : t('totalMile')}
          />
        ) : (
          <></>
        )}

        {!isCancelled ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.Driver}
            title={TimelineFormatter.minutesToTime(
              stopSolution?.totalDriveTime
            )}
            description={t('totalDriveTime')}
          />
        ) : (
          <></>
        )}

        {stopSolution?.isDriverAssistRequired ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.DriverAssist}
            title={t('TripAddNewLoadOption3DriverAssist')}
            description={''}
          />
        ) : (
          <></>
        )}

        <StyledDetailedDataSection
          icon={TimeLineIconMapping.Timelapse({
            isActive: false,
            color: 'action',
          })}
          title={TimelineFormatter.minutesToTime(
            stopSolution?.estimatedActivityDuration
          )}
          description={t('handlingTime')}
        />

        <StyledDetailedDataSection
          icon={TimeLineIconMapping.BeenhereIcon({
            isActive: false,
            ...(stopSolution?.arrivalDelay && {
              color: 'error',
            }),
          })}
          title={
            <Box
              component={'div'}
              sx={{
                ...(stopSolution?.arrivalDelay && {
                  color: 'error.main',
                }),
                whiteSpace: 'nowrap',
                display: 'inline',
              }}
            >
              {TimelineFormatter.getDisplayArrivalTime({
                actualTimeOfArrival: isCancelled
                  ? stopSolution.actualTimeOfCompletion
                  : stopSolution.actualTimeOfArrival,
                estimatedTimeOfArrival: isCancelled
                  ? stopSolution.estimatedTimeOfCompletion
                  : stopSolution.estimatedTimeOfArrival,
                timezone: stopSolution?.location?.timezone,
              })}
            </Box>
          }
          description={
            <Box
              whiteSpace={'pre-wrap'}
              component={'div'}
              sx={{
                display: 'inline',
              }}
            >
              <Box
                whiteSpace={'pre-wrap'}
                component={'div'}
                sx={{
                  display: 'inline',
                }}
              >
                {getDisplayArrivalTimeTitle({
                  isCompleted,
                  isCancelled,
                  stopSolution,
                })}
              </Box>
              {stopSolution?.arrivalDelay ? (
                <Box
                  whiteSpace={'pre-wrap'}
                  sx={{
                    color: 'error.main',
                    paddingLeft: '5px',
                    display: 'inline',
                  }}
                  component={'div'}
                >
                  ({TimelineFormatter.minutesToTime(stopSolution?.arrivalDelay)}{' '}
                  {t('delay')})
                </Box>
              ) : (
                <></>
              )}
            </Box>
          }
        />

        {!isCancelled ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.WatchIcon({
              isActive: false,
              ...(stopSolution?.detentionTime &&
                stopSolution?.detentionTime > 0 && {
                  color: 'error',
                }),
            })}
            title={
              <Box
                component={'span'}
                sx={{
                  ...(stopSolution?.detentionTime &&
                    stopSolution?.detentionTime > 0 && {
                      color: 'error.main',
                    }),
                }}
              >
                {TimelineFormatter.getDisplayTimeAtLocation({
                  dwellTime: stopSolution.dwellTime,
                  estimatedActivityDuration:
                    stopSolution.estimatedActivityDuration,
                })}
              </Box>
            }
            description={
              <Box component={'span'} whiteSpace={'pre-wrap'}>
                {t('timeatLocation')}
                {stopSolution?.detentionTime ? (
                  <Box
                    whiteSpace={'pre-wrap'}
                    sx={{
                      paddingLeft: '5px',
                      color: 'error.main',
                    }}
                    component={'span'}
                  >
                    (
                    {TimelineFormatter.minutesToTime(
                      stopSolution?.detentionTime
                    )}{' '}
                    {t('detention')})
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            }
          />
        ) : (
          <></>
        )}

        {!isCancelled ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.DoubleArrowIcon({
              isActive: false,
              color: 'action',
            })}
            title={TimelineFormatter.getDisplayDepartureTime({
              actualTimeOfCompletion: stopSolution.actualTimeOfCompletion,
              estimatedTimeOfCompletion: stopSolution.estimatedTimeOfCompletion,
              timezone: stopSolution?.location?.timezone,
            })}
            description={getDisplayDepartureTimeTitle({
              isCompleted,
              stopSolution,
            })}
          />
        ) : (
          <></>
        )}

        {stopSolution.type !== StopSolutionV3StopType.RELAY ? (
          <>
            {stopSolution?.noteDetails?.note ? (
              <StyledDetailedDataSection
                icon={TimeLineIconMapping.StickyNote2Icon({
                  isActive: false,
                  color: 'action',
                })}
                title={''}
                description={stopSolution?.noteDetails?.note}
              />
            ) : (
              <></>
            )}

            {stopSolution?.refNumber ? (
              <StyledDetailedDataSection
                icon={TimeLineIconMapping.PinOutlinedIcon({
                  isActive: false,
                  color: 'action',
                })}
                title={stopSolution?.refNumber}
                description={t('TripAddNewLoadOption3Reference')}
              />
            ) : (
              <></>
            )}

            {!isContactEmpty(stopSolution?.contact) ? (
              <StyledDetailedDataSection
                icon={TimeLineIconMapping.ContactsOutlinedIcon({
                  isActive: false,
                  color: 'action',
                })}
                title={stopSolution?.contact?.name || ''}
                description={[
                  stopSolution?.contact?.phone,
                  stopSolution?.contact?.email,
                ]
                  .filter((item) => !!item)
                  .join(' / ')}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    );
  }
};
