import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCommodityTypes } from '../../../contexts/CommodityTypeContext';
import { CreateDefaultType } from '../../../models';
import { PackageTypesResponse } from '../../../models/DTOs/Loads/Loads';
import { Commodity as CommodityModel } from '../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { addNewCommodityType } from '../../../subPages/loadsList/LoadDetailsPanel/utils';
import { addLoadPageAddCommodity } from '../../PendoClassIDs/constants';
import Checkbox from '../../Ui/Checkbox';
import Select from '../../Ui/Select';
import { SingleAutocompleteForm } from '../../Ui/SingleAutocomplete';
import TextField from '../../Ui/TextField/TextField';
import { useLoadPermission } from '../../hooks/useLoadPermission';
import { useThemeResponsive } from '../../hooks/useThemeResponsive';
import { getTotalWeight } from '../components/Commodity/utils';
import { commodityFormRequiredFields } from '../constants';
import { CommodityFormRequiredFieldsType } from '../types';

export default function CommodityForm({
  id,
  nameStart = '',
  isViewMode = false,
  packageTypeOptions,
  requiredFields = {
    ...commodityFormRequiredFields,
  },
}: {
  id?: number;
  nameStart: string;
  isViewMode?: boolean;
  packageTypeOptions: PackageTypesResponse[];
  requiredFields?: CommodityFormRequiredFieldsType;
}) {
  const { isMobile } = useThemeResponsive();
  const { commodityTypes, addNewCommodityType: updateCommodityTypes } =
    useCommodityTypes();
  const { hasLoadEditPermission } = useLoadPermission();
  const { control, setValue, setError, clearErrors } = useFormContext();
  const [newCommodityType, setNewCommodityType] = useState<CreateDefaultType>(
    {}
  );

  const onAddNewCommodityType = async (itemName: string) => {
    if (itemName?.length > 100)
      return setError('commodity', {
        type: 'custom',
        message: 'Cannot be longer than 100 characters.',
      });
    clearErrors('commodity');
    if (itemName && typeof itemName === 'string') {
      try {
        const response = await addNewCommodityType(itemName);
        if (response) {
          setNewCommodityType(response);
          setValue(`${nameStart}.commodity`, response);
          handleCommodityChange({
            id: response.id,
            itemCode: response.itemCode,
            itemName: response.itemName,
          });
          updateCommodityTypes(response);
        }
      } catch (error) {
        return;
      }
    }
  };

  const commodityData = useWatch({
    control,
    name: 'commodityData',
  });

  const handleWeightChange = (value: string) => {
    const weight = Number(value);
    if (nameStart && !isNaN(weight)) {
      const prevTotalWeight = getTotalWeight(
        commodityData.filter(
          (_item: CommodityModel, index: number) => index !== id
        )
      );
      let totalWeight = null;
      if (prevTotalWeight) {
        totalWeight = prevTotalWeight + weight;
      } else {
        totalWeight = value ? weight : null;
      }
      // custom fix bcz of TextField component internal logic of falsy values
      if (totalWeight === 0) {
        totalWeight = '0';
      }
      setValue('totalWeight', totalWeight, {
        shouldDirty: true,
      });
    }
  };

  const handleCommodityChange = (data: {
    id: string;
    itemCode: string;
    itemName: string;
  }) => {
    setValue(`${nameStart}.commodityTypeId`, data?.id || '');
    setValue(`${nameStart}.commodityType`, data?.itemCode || '');
    setValue(`${nameStart}.commodityTypeDisplayValue`, data?.itemName || '');
  };
  const isFieldReadOnly = isViewMode || !hasLoadEditPermission;
  const { t, ready } = useTranslation();
  return (
    <Grid spacing={1} container>
      <TextField
        id={addLoadPageAddCommodity + 'Quantity'}
        control={control}
        name={`${nameStart}.qty`}
        label={t('TripAddNewLoadOption5Quantity')}
        sizes={{ xs: isMobile ? 12 : 6 }}
        disabled={isFieldReadOnly}
        required={requiredFields.qty}
      />
      <Select
        id={addLoadPageAddCommodity + 'PackageType'}
        control={control}
        name={`${nameStart}.packageType`}
        label={t('TripAddNewLoadOption5PackageType')}
        options={packageTypeOptions}
        fieldName="value"
        fieldValue="key"
        sizes={{ xs: isMobile ? 12 : 6 }}
        disabled={isFieldReadOnly}
        style={{ marginTop: '3px' }}
      />
      <SingleAutocompleteForm
        id={addLoadPageAddCommodity + 'CommodityType'}
        control={control}
        name={`${nameStart}.commodity`}
        errorMessageFieldName="itemName"
        label={t('TripAddNewLoadOption5CommodityType')}
        options={commodityTypes}
        fieldName="itemName"
        newOption={newCommodityType}
        freeSolo
        addNewOption={onAddNewCommodityType}
        onChangeCb={handleCommodityChange}
        disabled={isFieldReadOnly}
        required={requiredFields.commodity}
      />
      <TextField
        id={addLoadPageAddCommodity + 'Description'}
        control={control}
        name={`${nameStart}.description`}
        label={t('TripAddNewLoadOption5Description')}
        disabled={isFieldReadOnly}
      />
      <TextField
        id={addLoadPageAddCommodity + 'Weight'}
        control={control}
        name={`${nameStart}.weight`}
        label={t('TripAddNewLoadOption5Weight')}
        onChangeCallback={handleWeightChange}
        onlyNumbers
        sizes={{ xs: isMobile ? 12 : 6 }}
        disabled={isFieldReadOnly}
      />
      <TextField
        id={addLoadPageAddCommodity + 'Volume'}
        control={control}
        name={`${nameStart}.volume`}
        label={t('TripAddNewLoadOption5Volume')}
        sizes={{ xs: isMobile ? 12 : 6 }}
        disabled={isFieldReadOnly}
      />
      <Checkbox
        id={addLoadPageAddCommodity + 'Stackable'}
        name={`${nameStart}.stackable`}
        control={control}
        labelPlacement={'end'}
        label={t('TripAddNewLoadOption5Stackable')}
        disabled={isFieldReadOnly}
        style={{ width: '100%' }}
      />
      <Checkbox
        id={addLoadPageAddCommodity + 'FloorLoad'}
        name={`${nameStart}.floorLoad`}
        control={control}
        labelPlacement={'end'}
        label={t('TripAddNewLoadOption5FloorLoad')}
        disabled={isFieldReadOnly}
        style={{ width: '100%' }}
      />
      <Typography
        sx={{ color: 'text.secondary' }}
        style={{
          fontSize: 16,
          fontWeight: '700',
          padding: '20px 0px 16px 10px ',
          display: 'block',
          width: '100%',
        }}
      >
        {t('TripAddNewLoadOption5Dimensions')}
      </Typography>
      <TextField
        id={addLoadPageAddCommodity + 'Length'}
        control={control}
        name={`${nameStart}.length`}
        label={t('TripAddNewLoadOption5Length')}
        sizes={{ xs: isMobile ? 12 : 4 }}
        disabled={isFieldReadOnly}
      />
      <TextField
        id={addLoadPageAddCommodity + 'Width'}
        control={control}
        name={`${nameStart}.width`}
        label={t('TripAddNewLoadOption5Width')}
        sizes={{ xs: isMobile ? 12 : 4 }}
        disabled={isFieldReadOnly}
      />
      <TextField
        id={addLoadPageAddCommodity + 'Height'}
        control={control}
        name={`${nameStart}.height`}
        label={t('TripAddNewLoadOption5Height')}
        sizes={{ xs: isMobile ? 12 : 4 }}
        disabled={isFieldReadOnly}
      />
    </Grid>
  );
}
