import BaseAccordion, { AccordionProps } from '@mui/material/Accordion';
import BaseAccordionActions from '@mui/material/AccordionActions';
import BaseAccordionDetails from '@mui/material/AccordionDetails';
import BaseAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { alpha, styled } from '@mui/material/styles';

const StyledAccordion = styled(BaseAccordion)<AccordionProps>(({ theme }) => ({
  border: 'none',
  borderRadius: '10px!important',
  overflow: 'hidden',
  marginTop: theme.spacing(2),
  '&::before': {
    content: 'none',
  },
  '&.Mui-expanded': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const StyledAccordionSummary = styled(
  BaseAccordionSummary
)<AccordionSummaryProps>(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  fontWeight: theme.typography.fontWeightBold,
  alignItems: 'flex-start',
  '&.Mui-expanded': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    minHeight: theme.spacing(6),
  },
  '& .MuiAccordionSummary-content': {
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0),
  },
  '&.Mui-expanded > .MuiAccordionSummary-content': {
    color: theme.palette.primary.main,
  },
  '& .MuiAccordionSummary-content > .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginTop: theme.spacing(1),
  },
}));

const CustomizedAccordion = (props: AccordionProps) => (
  <StyledAccordion elevation={props.elevation ?? 0} {...props} />
);

const CustomizedAccordionSummary = (
  props: AccordionSummaryProps & { prefixIcon?: React.ReactNode }
) => (
  <StyledAccordionSummary
    expandIcon={props.expandIcon ? props.expandIcon : <ExpandMoreIcon />}
    {...props}
  >
    {props.prefixIcon && props.prefixIcon}
    {props.children}
  </StyledAccordionSummary>
);

export {
  CustomizedAccordion as Accordion,
  BaseAccordionActions as AccordionActions,
  BaseAccordionDetails as AccordionDetails,
  CustomizedAccordionSummary as AccordionSummary,
};
