import { Theme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LicenseInfo } from '@mui/x-license-pro';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useRoutes } from 'react-router-dom';
import 'reflect-metadata';
import zingchart from 'zingchart/es6';
import './common/Layouts/main/Layout';
import axeleRoutes from './common/auxComponents/axeleRoutes';
import { getNotificationMessage } from './constants/globalNotificationMessages';
import { AuthProvider } from './globalContexts/AuthContext';
import './i18n';
import { useRootStore } from './store/root-store/rootStateContext';
import { PageRootStoreProvider } from './store/root.context';
import './styles/main.css';
import NotificationToast from './ui-kit/components/NotificationToast/NotificationToast';
import { getThemeObjectByMode } from './ui-kit/theme';
zingchart.LICENSE = JSON.parse(process.env.ZINGCHART_TOKEN as string);

declare module '@mui/styles/defaultTheme' {
  type DefaultTheme = Theme;
}

function App() {
  const element = useRoutes(axeleRoutes.convertToRouteObjects());
  const queryClient = useMemo(() => new QueryClient(), []);
  const { getNotificationType, setNotificationType, themeMode } =
    useRootStore();
  const themeOverriden = useMemo(
    () => getThemeObjectByMode(themeMode),
    [themeMode]
  );
  LicenseInfo.setLicenseKey(process.env.MUI_TOKEN as string);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={themeOverriden}>
        <PageRootStoreProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <NotificationToast
              type={getNotificationType.type}
              message={
                getNotificationType.message ||
                getNotificationMessage(
                  getNotificationType.type,
                  getNotificationType.serviceName
                )
              }
              onToastOpen={() => setNotificationType({ type: null })}
            />
            <AuthProvider>{element}</AuthProvider>
            {process.env.REACT_Q_DEVTOOLS && (
              <ReactQueryDevtools initialIsOpen={true} />
            )}
          </LocalizationProvider>
        </PageRootStoreProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default observer(App);
