import {
  MenuOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { reportColumnsDisplayName } from '../constant';
import { fieldCardButtonStyle, fieldCardContainerStyle } from '../style';

const ItemTypes = {
  CARD: 'card',
};
const style = {
  border: '1px solid gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  // cursor: 'move',
};

export interface CardProps {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number, isVisible: boolean) => void;
  isHideVisibilityIcon: boolean;
  isVisible: boolean;
  hideReorderingIcon: boolean;
  handleVisibilityChange: (
    data: string,
    index: number,
    isVisible: boolean
  ) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const FieldsCard: FC<CardProps> = ({
  id,
  text,
  index,
  moveCard,
  isHideVisibilityIcon,
  isVisible,
  handleVisibilityChange,
  hideReorderingIcon,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, isVisible);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  return (
    <ListItem
      sx={{ ...fieldCardContainerStyle }}
      secondaryAction={
        !isHideVisibilityIcon && (
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => handleVisibilityChange(text, index, isVisible)}
          >
            {isVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
          </IconButton>
        )
      }
    >
      <ListItemButton disabled={!isVisible} sx={{ ...fieldCardButtonStyle }}>
        {
          <ListItemIcon ref={ref} data-handler-id={handlerId}>
            {isVisible && !hideReorderingIcon && <MenuOutlined />}
          </ListItemIcon>
        }
        <ListItemText primary={reportColumnsDisplayName[text] || text} />
      </ListItemButton>
    </ListItem>
  );
};
