import { Box, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import React from 'react';
import RightArrow from '../../../_assets/static/svgs/RightArrow';
import { observer } from 'mobx-react';
import {
  TripDetailArrowContainerStyles,
  TripNumberStyles,
  darkTextStyles,
  lightTextStyles,
} from '../styles/evaluation.style';
import { IEvaluationTrips } from '../types/evaluation.type';
import {
  getDecimalValueOfMinutes,
  getHourFromMinutes,
  toFixDigit,
} from '../../../services/gantt';

interface IEvaluateTripsDetail {
  trips: Array<IEvaluationTrips>;
}

const TripLocationOverview = ({
  location,
  containerStyles,
  time,
  delay,
}: {
  location?: string;
  time?: string;
  containerStyles?: SxProps;
  delay?: number;
}) => {
  return (
    <Box sx={containerStyles} maxWidth={140} width="100%">
      <Typography
        sx={{ ...darkTextStyles, ...TripNumberStyles, width: '100%' }}
        fontSize={14}
        height={'20px'}
      >
        {location}
      </Typography>

      <Box display={'flex'} alignItems={'center'} gap="4px">
        <Typography variant="caption" fontSize={10} sx={lightTextStyles}>
          {time}
        </Typography>
        {(delay as number) > 0 && (
          <Typography
            component={'span'}
            variant="caption"
            fontSize={10}
            sx={(theme: Theme) => ({
              color: theme.palette.error.dark,
              minWidth: '43px',
            })}
          >
            {'+ ' + getDecimalValueOfMinutes(delay!)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const EvaluateTripsDetail: React.FC<IEvaluateTripsDetail> = (props) => {
  return (
    <Box marginTop="2px">
      {props?.trips?.map((tripData: IEvaluationTrips) => {
        return (
          <Stack direction="row" key={tripData.tripSeqNo} gap="4px">
            <TripLocationOverview
              location={tripData?.originStopName}
              time={tripData?.originTime}
              delay={tripData?.originDelay}
            />

            <Box
              id="route-detail"
              width={130}
              sx={TripDetailArrowContainerStyles}
            >
              <Box
                display="flex"
                justifyContent={'center'}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <Tooltip title={'Trip ID'} arrow>
                  <Typography
                    variant="caption"
                    fontSize={10}
                    height={'12px'}
                    sx={{ cursor: 'default', ...TripNumberStyles }}
                    letterSpacing={'0.4px'}
                    textAlign={'end'}
                  >
                    {`${tripData?.tripSeqNo}:`}
                  </Typography>
                </Tooltip>
                {Boolean(tripData?.connectedLoadsInfo?.length) && (
                  <Tooltip title={'Load ID'} arrow>
                    <Typography
                      variant="caption"
                      fontSize={10}
                      height={'12px'}
                      paddingLeft="3px"
                      sx={{
                        cursor: 'default',
                        color: 'rgba(0, 17, 34, 0.35)',
                        ...TripNumberStyles,
                      }}
                      letterSpacing={'0.4px'}
                    >
                      {tripData?.connectedLoadsInfo?.join?.(', ')}
                    </Typography>
                  </Tooltip>
                )}
              </Box>

              <RightArrow />

              <Box display={'flex'} alignItems={'center'} gap="4px">
                <Typography
                  variant="caption"
                  fontSize={10}
                  letterSpacing={'0.4px'}
                >
                  {tripData?.tripDuration}
                </Typography>
                {Boolean(
                  tripData?.tripDuration && tripData?.tripMiles >= 0
                ) && (
                  <Box
                    sx={{
                      background: 'rgba(4, 0, 34, 0.75)',
                      height: '4px',
                      width: '4px',
                      borderRadius: '50%',
                    }}
                  />
                )}
                <Typography
                  variant="caption"
                  fontSize={10}
                  letterSpacing={'0.4px'}
                >
                  {`${toFixDigit(tripData?.tripMiles)} mi`}
                </Typography>
              </Box>
            </Box>

            <TripLocationOverview
              location={tripData?.destinationStopName}
              time={tripData?.destinationTime}
              delay={tripData?.destinationDelay}
              containerStyles={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                textAlign: 'end',
                ml: 'auto',
              }}
            />
          </Stack>
        );
      })}
    </Box>
  );
};

export default observer(EvaluateTripsDetail);
