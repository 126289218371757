import React from 'react';
import { Controller } from 'react-hook-form';
import PickIconInput from '../../../ui-kit/components/PickIconInput';

const IconPicker: React.FC<{
  name: string;
  width?: any;
  error?: boolean;
  helperText?: any;
  modalHeight?: number;
  control?: any;
}> = ({ name, width = 1, error, helperText, modalHeight, control }) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => {
        return (
          <PickIconInput
            onChange={(iconName: string) => onChange(iconName)}
            icon={value}
            width={width}
            helperText={helperText}
            error={error}
            modalHeight={modalHeight}
          />
        );
      }}
      control={control}
      name={name}
    />
  );
};

export default IconPicker;
