import React, { useEffect, useState } from 'react';
import {
  StyledLoginAuthFromWrapper,
  StyledFormTitle,
  SxPropAuthButton,
} from './styles';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { AuthAppLogo, CloseIcon } from '../../ui-kit/components/Assets';
import TextInput from '../../ui-kit/components/TextField';
import { Link, useNavigate } from 'react-router-dom';
import {
  emailValidation,
  passwordValid,
} from '../../views/settings/terminals/constants/constants';
import { subscriptionService } from '../../api';
import { RegisterPage } from '../../models';
import {
  RegistrationDotValidationModel,
  formErrorObject,
  helpTextDOTMC,
  loginMethodObject,
  registrationFormdefaultData,
  regitrationPageStyle,
  regitrationPageText,
} from './RegistrationPageUtils';
import { cloneDeep } from 'lodash';
import { getClientTimeZoneOffset, getCustomerIp } from './utils';
import { states } from '@/common/Ui/AddressField/constants/states';
import SingleAutocomplete from '@/ui-kit/components/SingleAutocomplete';

const RegistrationPage = () => {
  const [formData, setFormData] = useState<RegisterPage>(
    registrationFormdefaultData
  );
  const [formDataError, setFormDataError] = useState(formErrorObject);
  const [selectedDOTMC, setDOTMC] = useState<string>('');
  const [selectedState, setState] = useState<any>(states[0].value);
  const [selectloginMethodh, setloginMethod] = useState<string>('DOT');
  const [ipAddress, setIpAddress] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    getIPAddress();
  }, []);
  const getIPAddress = async () => {
    const ip = await getCustomerIp();
    setIpAddress(ip);
  };

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    const data = formData;
    data[name] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name == 'email' && formErrorObject.isEmail) {
      isEmailValidation(data.email);
    } else if (name === 'password' && formErrorObject.password) {
      isPasswordValidation(data.password);
    } else {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        [name]: '',
      }));
    }
  };
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!validateRegistrationForm(formData)) return;
    setLoading(true);
    const payLoadData = cloneDeep(formData);
    payLoadData.password = window.btoa(cloneDeep(formData.password));
    payLoadData.ipAddress = ipAddress;
    payLoadData.timeZone = getClientTimeZoneOffset();
    payLoadData.country = 'USA';
    payLoadData.state = selectedState;
    const response = await subscriptionService.userRegistration(payLoadData);

    if (response) {
      navigate('/registration/verification', {
        state: { dot: payLoadData.dot, email: payLoadData.email },
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleChangeloginMethod = (dot: string) => {
    setloginMethod(dot);
    setFormData((prevFormData) => ({
      ...prevFormData,
      loginMethod: dot,
      dot: '',
    }));
  };

  const isEmailValidation = (value: string) => {
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      isEmail: !emailValidation.test(value),
      email: emailValidation.test(value) ? '' : 'Email is not valid.',
    }));
    return !emailValidation.test(value);
  };
  const isPasswordValidation = (value: string) => {
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      password: !passwordValid.test(value),
    }));
    return !passwordValid.test(value);
  };
  const handleChangeBlur = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    if (!value) return;
    if (name === 'email') {
      isEmailValidation(value);
    } else if (name === 'dot') {
      dotValidation(value);
    } else if (name === 'password') {
      isPasswordValidation(value);
    }
  };
  const dotValidation = async (value: string) => {
    const reuqest = new RegistrationDotValidationModel();
    reuqest.dot = value;
    const response = await subscriptionService.getDotValidation(reuqest);
    if (response != 'Success') {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        isDot: true,
        dot: response,
      }));
    } else {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        isDot: false,
        dot: '',
      }));
    }
  };
  const validateRegistrationForm = (data: RegisterPage) => {
    const errorlist = cloneDeep(formDataError);
    if (data.dot === '') {
      errorlist.dot = 'Required';
    }
    if (data.organizationName === '') {
      errorlist.organizationName = 'Required';
    }
    if (data.firstName === '') {
      errorlist.firstName = 'Required';
    }
    if (data.lastName === '') {
      errorlist.lastName = 'Required';
    }
    if (data.city === '') {
      errorlist.city = 'Required';
    }

    if (data.address === '') {
      errorlist.address = 'Required';
    }
    if (data.zipcode === '') {
      errorlist.zipcode = 'Required';
    }
    if (data.email === '' || !emailValidation.test(data.email)) {
      errorlist.email = data.email ? 'Email is not valid.' : 'Required';
      errorlist.isEmail = true;
    }
    if (data.password === '' || !passwordValid.test(data.password)) {
      errorlist.password = true;
    }
    let isEmpty = Object.values(data).every((x) => x.trim() != '');
    if (errorlist.password || errorlist.isEmail || formDataError.isDot) {
      isEmpty = false;
    }
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      ...errorlist,
    }));

    return isEmpty;
  };
  return (
    <>
      <StyledLoginAuthFromWrapper>
        <Box sx={regitrationPageStyle.mainContainer}>
          <StyledFormTitle>{regitrationPageText.dayFreetrial}</StyledFormTitle>
        </Box>
        <Box>
          <Box sx={regitrationPageStyle.formContainer}>
            <TextInput
              label={'Company Name'}
              error={Boolean(formDataError.organizationName)}
              helperText={formDataError.organizationName}
              onChange={handleChange}
              name="organizationName"
              styleProps={regitrationPageStyle.inputBoxStyle}
              value={formData.organizationName}
              InputProps={regitrationPageStyle.textInputRadius}
              variant="outlined"
              required
            />
            <ButtonGroup
              variant="outlined"
              size="large"
              aria-label="large button group"
              sx={regitrationPageStyle.buttonGroupStyle}
            >
              <Button
                sx={{
                  ...regitrationPageStyle.buttonStyle,
                  ...{
                    background: selectloginMethodh === 'DOT' ? '#324a6e' : '',
                  },
                }}
                onClick={() => {
                  handleChangeloginMethod('DOT');
                }}
              >
                {regitrationPageText.DOTNumber}
              </Button>
              <Typography
                sx={regitrationPageStyle.DOTStyle}
                onClick={() => {
                  setDOTMC('DOT');
                }}
              >
                {regitrationPageText.whatIsThis}
              </Typography>
              <Button
                sx={{
                  ...regitrationPageStyle.buttonMcStyle,
                  ...{
                    background: selectloginMethodh === 'MC' ? '#324a6e' : '',
                  },
                }}
                onClick={() => {
                  handleChangeloginMethod('MC');
                }}
              >
                {regitrationPageText.MCNumber}
              </Button>
              <Typography
                sx={regitrationPageStyle.MCStyle}
                onClick={() => {
                  setDOTMC('MC');
                }}
              >
                {regitrationPageText.whatIsThis}
              </Typography>
              <Button
                sx={{
                  ...regitrationPageStyle.buttonStyle,
                  ...{
                    background:
                      selectloginMethodh === 'Unique Identifier'
                        ? '#324a6e'
                        : '',
                  },
                }}
                onClick={() => {
                  handleChangeloginMethod('Unique Identifier');
                }}
              >
                {regitrationPageText.Idontknow}
              </Button>
            </ButtonGroup>
            {selectedDOTMC && (
              <Box sx={regitrationPageStyle.modelDOT}>
                <Grid
                  container
                  justifyContent={'space-between'}
                  sx={regitrationPageStyle.modelGridDOT}
                >
                  <span>What is {selectedDOTMC} #?</span>
                  <IconButton
                    size={'large'}
                    onClick={() => {
                      setDOTMC('');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <List sx={{ listStyleType: 'disc', ml: '22px' }}>
                  {helpTextDOTMC[selectedDOTMC].map((value) => (
                    <ListItem key={value} sx={regitrationPageStyle.listStyle}>
                      {value}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            <TextInput
              label={loginMethodObject[selectloginMethodh]}
              error={Boolean(formDataError.dot)}
              helperText={formDataError.dot}
              onChange={handleChange}
              onBlur={handleChangeBlur}
              InputProps={regitrationPageStyle.textInputRadius}
              styleProps={regitrationPageStyle.inputBoxStyle}
              value={formData.dot}
              variant="outlined"
              name="dot"
              required
            />
            <TextInput
              label={'First Name'}
              error={Boolean(formDataError.firstName)}
              helperText={formDataError.firstName}
              onChange={handleChange}
              styleProps={regitrationPageStyle.firstNameStyle}
              value={formData.firstName}
              variant="outlined"
              name="firstName"
              InputProps={regitrationPageStyle.textInputRadius}
              required
            />
            <TextInput
              label={'Last Name'}
              error={Boolean(formDataError.lastName)}
              helperText={formDataError.lastName}
              onChange={handleChange}
              styleProps={regitrationPageStyle.lasrNameStyle}
              value={formData.lastName}
              variant="outlined"
              name="lastName"
              InputProps={regitrationPageStyle.textInputRadius}
              required
            />
            <TextInput
              label={'Address'}
              error={Boolean(formDataError.address)}
              helperText={formDataError.address}
              onChange={handleChange}
              styleProps={regitrationPageStyle.inputAddressBoxStyle}
              value={formData.address}
              variant="outlined"
              name="address"
              InputProps={regitrationPageStyle.textInputRadius}
              required
            />
            <TextInput
              label={'City'}
              error={Boolean(formDataError.city)}
              helperText={formDataError.city}
              onChange={handleChange}
              styleProps={regitrationPageStyle.inputciteBoxStyle}
              value={formData.city}
              variant="outlined"
              name="city"
              InputProps={regitrationPageStyle.textInputRadius}
              required
            />

            <SingleAutocomplete
              name={`state`}
              fieldName={`name`}
              label={'State'}
              options={states}
              fieldValue={'name'}
              onChangeCb={(data) => {
                setState(data.value);
              }}
              variant="outlined"
              required
              customInputProps={regitrationPageStyle.inputstateBoxStyle}
              customInputLabelProps={{
                display: 'inline-block',
              }}
              customStyles={{
                ml: '15px',
                display: 'inline-block',
                width: '31%',
              }}
              styledOption={{
                ' p': {
                  color: 'black !important',
                },
              }}
              defaultValue={states[0]}
            />

            <TextInput
              label={'Zip'}
              error={Boolean(formDataError.zipcode)}
              helperText={formDataError.zipcode}
              onChange={handleChange}
              styleProps={regitrationPageStyle.inputStateBoxStyle}
              value={formData.zipcode}
              variant="outlined"
              name="zipcode"
              InputProps={regitrationPageStyle.textInputRadius}
              required
            />
            <TextInput
              type={'text'}
              label={'Email'}
              error={Boolean(formDataError.email)}
              helperText={formDataError.email}
              onChange={handleChange}
              onBlur={handleChangeBlur}
              name="email"
              styleProps={regitrationPageStyle.inputBoxStyle}
              value={formData.email}
              InputProps={regitrationPageStyle.textInputRadius}
              variant="outlined"
              required
            />
            <TextInput
              type={'password'}
              label={'Password'}
              error={formDataError.password}
              onChange={handleChange}
              helperText={formData.password === '' ? 'Required' : ''}
              onBlur={handleChangeBlur}
              value={formData.password}
              variant="outlined"
              name="password"
              required
              styleProps={regitrationPageStyle.passwordStyle}
              InputProps={regitrationPageStyle.textInputRadius}
            />
            <Box sx={regitrationPageStyle.passwordRulesStyle}>
              <Typography
                sx={{
                  color:
                    formDataError.password && !(formData.password.length >= 8)
                      ? 'error.main'
                      : '',
                  fontSize: '13px',
                }}
              >
                {regitrationPageText.characterMinimum}
              </Typography>
              <Typography
                sx={{
                  color:
                    formDataError.password && formData.password.length >= 8
                      ? 'error.main'
                      : '',
                  fontSize: '13px',
                }}
              >
                {regitrationPageText.specialCharacter}
              </Typography>
            </Box>
            <Box sx={regitrationPageStyle.privacyTextStyle}>
              <Box sx={regitrationPageStyle.privacyInfoStyle}>
                By clicking on sign-up, you agree to LoadOps{' '}
                <a
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: '5px',
                    marginRight: '5px',
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://optym.com/loadops-privacy"
                >
                  Privacy Policy
                </a>{' '}
                and
                <a
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://optym.com/loadops-terms"
                >
                  Terms & Conditions
                </a>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={handleSubmit}
                sx={{
                  ...SxPropAuthButton,
                  ...(isLoading && {
                    background: '#3a4755 !important',
                    color: '#757e88 !important',
                  }),
                  width: '100%',
                }}
                size="large"
              >
                {isLoading && (
                  <CircularProgress
                    size={22}
                    sx={{ ml: '-16px', mr: '22px' }}
                  />
                )}{' '}
                {isLoading ? 'Signing Up' : 'Sign Up'}
              </Button>
            </Box>
            <Box sx={regitrationPageStyle.singInContainerStyle}>
              <Box sx={regitrationPageStyle.singInStyle}>
                {regitrationPageText.alreadyAccount}
                <Box sx={{ color: 'primary.main' }}>
                  <Link
                    style={regitrationPageStyle.linkStyle}
                    to={{
                      pathname: '/login',
                      search: window?.location?.search,
                    }}
                  >
                    {regitrationPageText.signIn}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledLoginAuthFromWrapper>
    </>
  );
};

export default RegistrationPage;
