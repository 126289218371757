import { ViewMetaData } from '../../../types';
import { QueryParams } from '../commonMixed/QueryParams';
import {
  CreateDocumentRequestType,
  EmailSendingRequestDTOType,
  deletedDocumentRequestType,
  documentAssigmentsRequestType,
  downloadDocumentRequestType,
  emailSendingRequestType,
  entityTypesRequestType,
} from './Document';

export class PaginatedDocumentsListRequest extends QueryParams {}
export class DocType extends QueryParams {
  terminalIds!: string[];
}

export class DocumentAssigments extends QueryParams {
  objectTypesAndIds?: string[] | null | string;
  documentTypeIds?: string[];
  terminalIds?: string[];
  permissionType?: string;
  startDate?: any;
  endDate?: any;
  pageNumber?: number;
  pageSize?: number;
  constructor({
    objectTypesAndIds,
    documentTypeIds,
    permissionType,
    startDate,
    endDate,
    terminalIds,
    pageNumber,
    pageSize,
  }: documentAssigmentsRequestType) {
    super();
    this.objectTypesAndIds = objectTypesAndIds;
    this.documentTypeIds = documentTypeIds;
    this.permissionType = permissionType;
    this.startDate = startDate;
    this.endDate = endDate;
    this.terminalIds = terminalIds;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
  }
}

export class EntityTypesRequest extends QueryParams {
  entityType: string;
  terminalIds: string[];
  constructor({ entityType, terminalIds }: entityTypesRequestType) {
    super();
    this.entityType = entityType;
    this.terminalIds = terminalIds;
  }
}

export class DeleteDocumentRequest extends QueryParams {
  documentIds: string[];
  constructor({ documentIds }: deletedDocumentRequestType) {
    super();
    this.documentIds = documentIds;
  }
}

export class DownloadDocumentRequest extends QueryParams {
  id: string[];
  constructor({ id }: downloadDocumentRequestType) {
    super();
    this.id = id;
  }
}

export class CreateFileRequest extends QueryParams {
  ownerId!: number;
}

export class AttachedEntitiesRequest extends QueryParams {
  pageNumber: string;
  search: string;
  terminalIds: string[];
  driverId: string;
  excludeStatusList?: string[];
  excludeStatuses?: number[];
  statusFilter?: string[];
  constructor({
    pageNumber,
    searchText,
    terminalIds,
    entityId,
    excludeStatusList,
    excludeStatuses,
    statusFilter,
  }: any) {
    super();
    this.pageNumber = pageNumber;
    this.search = searchText;
    this.terminalIds = terminalIds;
    this.driverId = entityId;
    this.excludeStatusList = excludeStatusList;
    this.excludeStatuses = excludeStatuses;
    this.statusFilter = statusFilter;
  }
}

export class DocumentShareRequest extends QueryParams {
  emailSendingRequestDTO: EmailSendingRequestDTOType;
  html: boolean;
  constructor({ emailSendingRequestDTO, html }: emailSendingRequestType) {
    super();
    this.emailSendingRequestDTO = emailSendingRequestDTO;
    this.html = html;
  }
}

type DocAttachedEntitiesProperties = {
  id: string;
  title: string;
};

type AttachedEntities = {
  properties: Array<DocAttachedEntitiesProperties>;
  type: string;
};
export class CreateDocumentRequest extends QueryParams {
  attachedEntities: AttachedEntities;
  description: string;
  documentId: string;
  documentType: string;
  documentTypeId: string;
  fileName: string;
  fileSize: number;
  id: null;
  ownerId: number;
  ownerName: string;
  permission: string;
  uploadDate: Date;
  constructor({
    attachedEntities,
    description,
    documentId,
    documentType,
    documentTypeId,
    fileName,
    fileSize,
    id,
    ownerId,
    ownerName,
    permission,
    uploadDate,
  }: CreateDocumentRequestType) {
    super();
    this.attachedEntities = attachedEntities;
    this.description = description;
    this.documentId = documentId;
    this.documentType = documentType;
    this.documentTypeId = documentTypeId;
    this.fileName = fileName;
    this.fileSize = fileSize;
    this.id = id;
    this.ownerId = ownerId;
    this.ownerName = ownerName;
    this.permission = permission;
    this.uploadDate = uploadDate;
  }
}

export class EditDocumentRequest {
  attachedEntities!: AttachedEntities[];
  description!: string;
  documentId!: string;
  documentType!: string;
  documentTypeId!: string;
  fileName!: string;
  fileSize!: number;
  terminalId!: string;
  id!: string;
  fieldsToBeSetNull!: string[];
  permission!: string;
}

export class CreateDocumentOnlyRequest extends QueryParams {
  data!: File;
  ownerId!: number;

  constructor(dto: { data: File; organizationId?: number; ownerId: number }) {
    super();

    if (dto) {
      this.data = dto.data;
      this.ownerId = dto.ownerId;
    }
  }
}

export class GetDocumentTypeListRequest extends QueryParams {}

/*NEW CODES HERE*/

export class GetDocumentFoldersRequest extends QueryParams {
  terminalIds!: string[];
}
export class GetDocumentsByEntityRequest extends QueryParams {
  entityType!: string;
  terminalIds!: string[];
}
export class GetDocumentsListByDocIdRequest extends QueryParams {
  documentTypeIds!: string[];
}
export class DownloadDocumentsRequest extends QueryParams {
  id!: string[];
}

export class GetAssociatedDriversRequest extends QueryParams {
  pageNumber!: number;
  searchText!: string;
  entity!: string;
}

export class DeleteDocumentsRequest extends QueryParams {
  documentIds!: string[];
}

export class GetDocumentsListRequest extends QueryParams {
  documentTypeIds!: string[];
  objectTypesAndIds!: string;
  entityTypes!: string[];
  terminalIds!: string[];
  ownerIds!: string[];
  permissionType!: string;
  startDate!: string;
  endDate!: string;
  pageNumber!: number;
  pageSize!: number;
}

export class EmailDocumentsRequest extends QueryParams {
  html!: boolean;
  emailSendingRequestDTO!: {
    to: string[];
    cc: string[] | null;
    subject: string;
    message: string;
    documentList: { id: string }[];
  }[];
}

export type SubFolderType = { entityType: string; count: number; key: string };

export type FolderType = {
  entityType: string;
  count: number;
  subTypes: SubFolderType[];
  key: string;
};

export type EntityType = { documentTypeId: string; count: number; key: string };

export type FolderTypeResponse = {
  entities: FolderType[];
  documentsData: {
    documents: EntityType[];
    count: number;
  };
};

export type DocumentByEntity = {
  count: number;
  id: string;
  title: string;
  uniqueId: string;
};

export type AttachedEntityType = {
  type: string;
  properties: { id: string; title: string }[];
};

export type DocumentItemType = {
  ownerId: number;
  ownerName: string;
  documentId: string;
  fileName: string;
  description: string;
  fileSize: number;
  permission: string;
  uploadDate: string;
  documentTypeId: string;
  documentType: string;
  attachedEntities: AttachedEntityType[];
  terminalId: null | string;
  id: string;
  contentType: string;
};

export enum CurrentSelectedType {
  FOLDER = 'FOLDER',
  SUB_FOLDER = 'SUB_FOLDER',
  ENTITY = 'ENTITY',
  MID_ITEM = 'MID_ITEM',
  PANEL = 'PANEL',
}

export type CurrentSelectedItem = {
  type: CurrentSelectedType;
  isSubEntity: boolean;
  hasSubEntities: boolean;
  id: string;
  name: string;
  documentsCount?: number;
  parentType?: string;
  filters?: ViewMetaData;
  showTable: boolean;
  allEntityType?: boolean;
};
