import { LineItem } from '../../../types';
import { QueryParams } from '../commonMixed/QueryParams';

export class GetFinancialItemRequest extends QueryParams {
  isSettlementItem?: boolean;
  constructor(isSettlementItem?: boolean) {
    super();
    this.isSettlementItem = isSettlementItem;
  }
}
export class GetFinanceLoadRequest extends QueryParams {
  loadId?: string;

  constructor(loadId: string) {
    super(true);
    this.loadId = loadId;
  }
}
export class GetFinanceLoadByIDRequest extends QueryParams {
  id?: string;
  constructor(id: string) {
    super(true);
    this.id = id;
  }
}
export class GetFinanceLoadByLoadIDRequest extends QueryParams {
  loadId?: string;
  constructor(loadId: string) {
    super(true);
    this.loadId = loadId;
  }
}

export class FinanceLoadListRequest {}

export class FinanceLoadDetailsType {
  data?: string;
  temporary?: boolean;
  closeDetailPage?: string;
  openDialogBox?: any;
  invoiceLineItemsList?: FinanceLoadFinancialType;
  expenseCategoriesList?: FinanceLoadExpenseType;
  loadFinanceDetailsList?: FinanceLoadloadExpenseType;
  financeLoadlistUpdated?: string;
  isEditFormUpdate?: string;
  selectedType?: any;
  financialItem?: any;
  intialData?: any;
  recordList?: any;
}

export interface FinanceType {
  loadId: string;
  onClose?: () => void;
  customerId?: number;
  openPayments?: FinanceLoadinvoiceDetailsType;
  data?: FinanceTabsType;
  ModuleType?: string;
}
export interface FinanceTabsType {
  id: string;
  loadStatus: string;
  seqNumber: string;
}
export class FinanceLoadType {
  invoiceDetails?: FinanceLoadinvoiceDetailsType;
  totalIn?: number;
  totalOut?: number;
  tripDetails?: Array<FinanceLoadinvoiceDetailsListType>;
  lineItems?: LineItem[];
}
export interface FinanceLoadInterfaceType {
  invoiceDetails: FinanceLoadinvoiceDetailsType;
  totalIn: number;
  totalOut: number;
  tripDetails: Array<FinanceLoadinvoiceDetailsListType>;
}
export interface FinanceLoadinvoiceDetailsType {
  map(arg0: () => void): unknown;
  quantity(quantity: number): number;
  unit(unit: number): number;
  amount: number;
  id: string;
  name: string;
  payTypeIdList: Array<string>;
  paymentId: string;
  paymentStatus: string;
  type: string;
}
export interface FinanceLoadinvoiceDetailsListType {
  paymentDetails: Array<FinanceLoadinvoiceDetailsType>;
  tripName: string;
}
export interface TripComponentType {
  data?: any;
  financialItem?: Array<object>;
  colorRedType?: boolean;
  isMainPage?: boolean;
  onChangeTripItem?: (data: any) => any;
}
export interface FinanceTripType {
  loadFinanceOnclick: string;
  data: FinanceLoadinvoiceDetailsType;
  financialItem: FinanceLoadFinancialType;
  colorRedType: boolean;
  onChangeTripItem?: (data: any) => any;
}
export interface FinanceLoadDetailHeaderType {
  data: any;
  temporary?: boolean;
  closeDetailPage: string;
  selectedType: any;
  intialData: any;
  isSaveVisible: any;
  selectedItemData: any;
  financeLoadlistUpdated: any;
}

export interface FinanceLoadAddItemType {
  data: FinanceLoadinvoiceDetailsType;
  expenseCategoriesList: FinanceLoadExpenseType;
  invoiceLineItemsList: FinanceLoadinvoiceDetailsType;
  errorValidation: {
    rate: any;
    loadRateType: any;
    quantity: any;
    amount: any;
    expenseCategoryId: any;
    isrequired: boolean;
    errorText: string;
  };
  isEditFormUpdate: string;
  isUpdated: boolean;
  isAddForm: string;
  totalAmountPass: string;
  originalRecords: any;
  financialItem: any;
}
export interface FinanceLoadExpenseType {
  find(
    arg0: (data: { itemCode: string }) => boolean
  ): import('react').SetStateAction<FinanceLoadExpenseType>;
  createDate: string;
  createdBy: string;
  custom: boolean;
  deduction: boolean;
  description: string;
  iconName: string;
  id: string;
  itemCode: string;
  itemName: string;
  organizationId: number;
}
export interface FinanceLoadFinancialType {
  createDate: string;
  createdBy: string;
  custom: boolean;
  deduction: boolean;
  description: string;

  iconName: string;
  id: string;
  invoiceItem: true;
  itemCode: string;
  itemName: string;
  itemType: string;
  organizationId: number;
  settlementItem: boolean;
  unit: Array<string>;
}
export interface FinanceLoadloadExpenseType {
  createDate: string;
  driverId: number;
  driverName: string;
  emptyMiles: number;
  groupDetails: null;
  id: string;
  items: Array<FinanceLoadloadexpenseListType>;
  loadId: string;
  loadedMiles: number;
  organizationId: number;
  total: number;
  updateDate: string;
}
export interface FinanceLoadloadexpenseListType {
  parcent?: string;
  payType: string | undefined;
  amount: number;
  description: string;
  expenseCategoryId: string;
  expenseCategoryKey: string;
  id: string;
  loadRateType: string;
  loadRateTypeId: string;
  manuallyAdded: boolean;
  quantity?: string;
  rate: string;
  unit: string;
  map(arg0: (currElement: any, index: number) => void): unknown;
}
export class DeleteFinanceLoadLineType {
  id!: string;
  items!: FinanceLoadloadexpenseListType;
}

export interface FinanceLoadloadExpenseConstantType {
  object?: FinanceLoadloadExpenseType | { items: [] };
}

export class GetFinanceLoadItemDriverIntialRequest extends QueryParams {
  loadId!: string;
  driverId!: string;
  organizationId!: number;

  // constructor(loadId: string,driverId: string,   organizationId:number ) {
  //   super(true);
  //   this.loadId = loadId;
  //   this.driverId = driverId;
  //   this.organizationId = organizationId;
  // }
}
export class GetFinanceLoadItemTractorIntialRequest extends QueryParams {
  loadId!: string;
  tractorId!: string;
  organizationId!: number;

  // constructor(loadId: string, tractorId: string,   organizationId:number ) {
  //   super(true);
  //   this.loadId = loadId;
  //   this.tractorId = tractorId;
  //   this.organizationId = organizationId;
  // }
}

export class LoadCarrierExpenseReceivedPayment {
  amount: number;
  paymentDate: string;
  referenceNumber: any;
  advanced: boolean;
}
export class LoadCarrierExpenseLineItem {
  id: string;
  description: string;
  expenseCategoryKey: any;
  expenseCategoryId: string;
  loadRateTypeId: string;
  loadRateType: string;
  quantity: number;
  rate: number;
  amount: number;
  manuallyAdded: boolean;
  unit: string;
}

export class LoadCarrierExpenseDTO {
  id: string;
  tripId: string;
  seqNumber: any;
  organizationId!: number;
  carrierId: string;
  items: Array<LoadCarrierExpenseLineItem>;
  receivedPayments: Array<LoadCarrierExpenseReceivedPayment>;
  createDate: string;
  updateDate: string;
  total: number;
  amountDue: number;
  status: string;

  constructor(dto: any) {
    this.id = dto?.id;
    this.tripId = dto?.tripId;
    this.seqNumber = dto?.seqNumber;
    this.carrierId = dto?.carrierId;
    this.items = dto?.items || [];
    this.receivedPayments = dto?.receivedPayments || [];
    this.createDate = dto?.createDate;
    this.updateDate = dto?.updateDate;
    this.total = dto?.total;
    this.amountDue = dto?.amountDue;
    this.status = dto?.status;
  }
}

export class LoadCarrierExpenseDTORequest extends LoadCarrierExpenseDTO {
  constructor(dto: any) {
    super(dto);
    const queryParams = new QueryParams(true);
    Object.assign(this, queryParams);
  }
}

export class LoadCarrierExpenseShortDTO {
  id: string;
  status: string;
  constructor(dto: any) {
    this.id = dto.id;
    this.status = dto.status;
  }
}
