import { Box } from '@mui/material';
import { FilterComponent } from './FilterComponent';

const Filters = ({
  columns,
  onChangeCb,
  defaultFilterValue,
  pageName,
  setRef,
}: any) => {
  return (
    <>
      {columns?.map((column: any, index: number) =>
        defaultFilterValue && column.name in defaultFilterValue ? (
          column.default ? (
            <Box
              ref={(ref) => setRef?.(index, ref, column)}
              key={column.key}
              sx={{
                pr: 1,
                pt: 1,
              }}
            >
              {FilterComponent(
                column,
                defaultFilterValue,
                onChangeCb,
                undefined,
                undefined,
                undefined,
                undefined,
                pageName
              )}
            </Box>
          ) : null
        ) : null
      )}
    </>
  );
};

export default Filters;
