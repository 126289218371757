import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import { PayStatementType } from '../../../../models';
import PaymentsDetailsPanel from '../../../../subPages/payStatement/components/PayStatementDetailsPanel';
import TableDataContainer from '../../../Wrappers/TableDataContainer';
type settlementModalType = {
  data: PayStatementType;
  showModalDialog: boolean;
  onClose?: () => Promise<void> | undefined;
  loadId?: string;
};
const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mt: '3%',
  height: 'calc(100% - 100px)',
};
export default function FinanceOverviewSettlementModal({
  data,
  showModalDialog,
  onClose,
  loadId,
}: settlementModalType) {
  const [open, setOpen] = React.useState(showModalDialog);
  const handleClose = () => {
    if (onClose && onClose()) {
      onClose();
    }
    setOpen(false);
  };
  return (
    <div className="FinanceOverviewSettlementModal">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <React.Fragment>
            <TableDataContainer>
              <PaymentsDetailsPanel
                data={data}
                onClose={handleClose}
                loadId={loadId}
              />
            </TableDataContainer>
          </React.Fragment>
        </Box>
      </Modal>
    </div>
  );
}
