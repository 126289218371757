import { SxProps } from '@mui/material';

export const getAutocompleteStyles = () => {
  const BorderStyles = {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: `primary.main`,
    },
  };
  const MuiInputLabelStyles: SxProps = {
    color: 'primary.main',
    '&[data-shrink=true]+ .MuiInputBase-root > .MuiInput-input::-webkit-input-placeholder':
      {
        color: 'text.secondary',
        opacity: '0.6 !important',
      },
  };

  const styles = {
    '.MuiAutocomplete-root': {
      ...BorderStyles,
      '.MuiInputLabel-root': {
        ...MuiInputLabelStyles,
      },
    },
    '.MuiAutocomplete-inputRoot': {
      color: 'primary.main',
      borderRadius: '10px',
    },
    '.MuiAutocomplete-paper': {
      borderRadius: '14px',
    },
    '.MuiTextField-root': {
      borderColor: `${'primary.main'} !important`,
      '.MuiOutlinedInput-root': {
        ...MuiInputLabelStyles,
        '.MuiTypography-root': {
          ...MuiInputLabelStyles,
        },
      },
      '.MuiInput-input': {
        ...MuiInputLabelStyles,
      },
      ...BorderStyles,
    },
  };

  return styles;
};
