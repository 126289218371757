import { Box } from '@mui/material';
import React from 'react';
import { TimelineFormatter } from '../../../AxeleTimeline/utils/TimelineFormatter';
import { TripV3DataProps } from '../../TripPlanV3';
import { StyledRightSubTitle } from '../../TripPlanV3/styles';
import {
  StopSolutionV3Prop,
  StopSolutionV3StopType,
} from '../../TripPlanV3/types';
import {
  TripStopActivityEnum,
  TripStopActivityTitleEnum,
  TripStopStatusEnum,
} from '../../types';
import {
  FloatingActionButtonSection,
  FloatingActionButtonSectionProps,
} from './FloatingActionButtonSection';
import { FloatingReSequenceStopIcon } from './FloatingReSequenceStopIcon';
import { FloatingTruckIconSection } from './FloatingTruckIconSection';
import { getDislayLocation } from './timelineV3.utils';

const getTripV3StopTypeTitleWithDefaultValue = (
  stopSolution: StopSolutionV3Prop,
  defaultValue: string
): string => {
  switch (stopSolution?.type) {
    case StopSolutionV3StopType.TRIP:
      return `Trip Stop`;
    case StopSolutionV3StopType.RELAY:
      return 'Relay';
    default:
      return defaultValue;
  }
};

export const getTripV3ActivityTitle = (
  stopSolution: StopSolutionV3Prop
): string => {
  switch (stopSolution?.activityType) {
    case TripStopActivityEnum.PICKUP:
    case TripStopActivityEnum.PICKUP_AND_DROPOFF:
    case TripStopActivityEnum.DROPOFF:
      return TripStopActivityTitleEnum[stopSolution?.activityType];
    default:
      return getTripV3StopTypeTitleWithDefaultValue(stopSolution, '');
  }
};

export const getTripV3StopTypeTitle = (
  stopSolution: StopSolutionV3Prop
): string => {
  return getTripV3StopTypeTitleWithDefaultValue(
    stopSolution,
    getTripV3ActivityTitle(stopSolution)
  );
};

export const RightSubTitleSection = ({
  enablePreAndNext,
  expanded,
  renderTimelineSeparatorIconOnly,
  showFloatingActionButton,
  stopSolution,
  onClickAddStopHandler,
  onClickAddRelayHandler,
  onClickResequenceDownHandler,
  onClickResequenceUpHandler,
  isCompleted,
  isCancelled,
  data,
  isNotCompletedBeforeEnRoute,
  isNotCompletedBeforeClosetEnRoute,
  isShowFloatingTruckIconSection,
  isShowButtonIconActionSection = true,
}: {
  enablePreAndNext?: boolean;
  expanded?: boolean;
  renderTimelineSeparatorIconOnly: boolean;
  showFloatingActionButton: boolean;
  isCompleted?: boolean;
  isCancelled?: boolean;
  data: TripV3DataProps;
  isNotCompletedBeforeEnRoute?: boolean;
  isNotCompletedBeforeClosetEnRoute?: boolean;
  isShowFloatingTruckIconSection?: boolean;
  isShowButtonIconActionSection?: boolean;
} & FloatingActionButtonSectionProps): JSX.Element => {
  if (!renderTimelineSeparatorIconOnly) {
    const appointmentWindow: string[] =
      TimelineFormatter.getLeftSubTitleDateTime(
        stopSolution?.appointmentStartDate,
        stopSolution?.appointmentEndDate,
        stopSolution?.location?.timezone
      );
    const RightSubTitle = ({
      children,
    }: {
      children: React.ReactNode;
    }): JSX.Element => {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {children}
          </Box>
          <StyledRightSubTitle
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {getDislayLocation({
              withLocationName: true,
              location: stopSolution.location,
            })}
          </StyledRightSubTitle>

          {!isCompleted && showFloatingActionButton ? (
            <FloatingActionButtonSection
              expanded={expanded}
              stopSolution={stopSolution}
              showFloatingTruckIcon={isNotCompletedBeforeClosetEnRoute}
              onClickAddStopHandler={onClickAddStopHandler}
              onClickAddRelayHandler={onClickAddRelayHandler}
              data={data}
            />
          ) : (
            <></>
          )}
          {isShowFloatingTruckIconSection ? (
            <FloatingTruckIconSection
              stopSolution={stopSolution}
              showFloatingActionButton={showFloatingActionButton}
              expanded={expanded}
            />
          ) : (
            <></>
          )}
          {isShowButtonIconActionSection &&
          !isCancelled &&
          stopSolution.type !== StopSolutionV3StopType.RELAY &&
          stopSolution.resequenceActions?.showResequenceIcons ? (
            <FloatingReSequenceStopIcon
              onClickResequenceUpHandler={onClickResequenceUpHandler}
              onClickResequenceDownHandler={onClickResequenceDownHandler}
              stopSolution={stopSolution}
              isSeparatorTruckIcon={
                stopSolution?.status === TripStopStatusEnum.ARRIVED
              }
            />
          ) : (
            <></>
          )}
        </Box>
      );
    };

    const AppointmentWindow = (): JSX.Element => {
      return (
        <StyledRightSubTitle
          sx={{
            display: 'inline',
            verticalAlign: 'top',
          }}
        >
          {`Appt: ${appointmentWindow.join(' ')} ${
            stopSolution?.arrivalDelay ? '/' : ''
          }`}
        </StyledRightSubTitle>
      );
    };
    const DelayInMinutes = (): JSX.Element => {
      return stopSolution?.arrivalDelay ? (
        <StyledRightSubTitle
          iSBold={true}
          sx={{
            paddingLeft: '5px',
            color: 'error.main',
            display: 'inline',
            verticalAlign: 'top',
            whiteSpace: 'pre-wrap',
          }}
        >
          {`${TimelineFormatter.minutesToHours(
            stopSolution?.arrivalDelay
          )} Delay`}
        </StyledRightSubTitle>
      ) : (
        <></>
      );
    };

    return (
      <RightSubTitle>
        {!enablePreAndNext ? (
          <StyledRightSubTitle
            iSBold={true}
            sx={{
              whiteSpace: 'nowrap',
              pr: '5px',
            }}
          >
            {getTripV3StopTypeTitle(stopSolution)} :{' '}
          </StyledRightSubTitle>
        ) : (
          <></>
        )}
        <Box
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          <AppointmentWindow />
          <DelayInMinutes />
        </Box>
      </RightSubTitle>
    );
  }
  return <></>;
};
