import * as yup from 'yup';
import {
  nullableNumberYup,
  nullableStringMaxValidation,
} from '../../../../../utils';

export const createPaymentFormValidationSchema = yup.object().shape({
  customerId: yup
    .object()
    .shape({
      id: yup.string().required('Customer is required'),
    })
    .required('Customer is required')
    .typeError('Customer is required'),
  // paymentMethod: '',
  amountReceived: nullableNumberYup()
    .required('Amount is required')
    .typeError('Amount is not valid')
    .min(0, 'Amount must be between 0 and 999,999.9999')
    .max(999999.9999, 'Amount must be between 0 and 999,999.9999'),
  paymentReferenceId: nullableStringMaxValidation(50),
  paymentDate: yup
    .date()
    .max(
      new Date(new Date().setUTCHours(23, 59, 59, 999)),
      'Date cannot be in future'
    )
    .required('Date is required')
    .typeError('Date is not valid'),
  memo: nullableStringMaxValidation(1024),
});
