import { Box, DialogContent, Grid, IconButton } from '@mui/material';
import { CloseIcon } from '../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { FC, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ContactSummary } from '../../../../../models';
import { TextField } from '../../../UI';
import {
  StyledDialog,
  StyledDialogCloseIcon,
  StyledDialogTitle,
} from '../../styles';
import { MaintenanceHistoryFormContext } from '../../../context/MaintenanceHistoryFormContext';
import { useTranslation } from 'react-i18next';

interface AddNewContactPopupProps {
  onClose: () => void;
}

const AddNewContactPopup: FC<AddNewContactPopupProps> = ({ onClose }) => {
  const { newVendorContacts, setNewVendorContacts } = useContext(
    MaintenanceHistoryFormContext
  );
  const [loading, setLoading] = useState(false);
  const { handleSubmit, reset, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: null,
      description: null,
      email: null,
      phone: null,
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const contact: ContactSummary = {
        name: data.name,
        description: data.description,
        phoneData: {
          phone: data.phone,
          countryCode: '',
          extension: 0,
        },
        email: data.email,
        isPrimary: newVendorContacts.length === 0,
        seqNumber: 0,
      };

      setNewVendorContacts?.((contacts) => [...contacts, contact]);
      reset();
      onClose?.();
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    onClose?.();
  };

  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <StyledDialog uncategorized>
        <StyledDialogTitle>
          {t('addNewContact')}
          <StyledDialogCloseIcon>
            <IconButton onClick={handleClose}>
              <CloseIcon width={14} height={14} />
            </IconButton>
          </StyledDialogCloseIcon>
        </StyledDialogTitle>

        <DialogContent
          style={{
            textAlign: 'left',
            padding: 16,
            borderRadius: '16px 16px 0px 0px',
            zIndex: 1,
          }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { marginBottom: 2 },
            }}
            autoComplete="off"
          >
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="name"
                  label={t('name')}
                  variant="standard"
                  {...field}
                  fullWidth
                  error={!!error}
                />
              )}
            />

            <Grid container>
              <Grid item xs={6}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="phone"
                      label={t('phone')}
                      variant="standard"
                      {...field}
                      error={!!error}
                      styleProps={{ mr: 3, width: '23ch' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="email"
                      label={t('email')}
                      variant="standard"
                      {...field}
                      error={!!error}
                      styleProps={{ width: '176px' }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="description"
                  label={t('description')}
                  variant="standard"
                  {...field}
                  error={!!error}
                  multiline
                  fullWidth
                />
              )}
            />

            <div>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  gap: 1,
                }}
              >
                <ButtonImproved
                  variant="outlined"
                  onClick={handleClose}
                  label={t('cancel')}
                />
                <ButtonImproved
                  variant="contained"
                  label={t('addContact')}
                  disabled={loading}
                  onClick={handleSubmit(onSubmit)}
                />
              </Box>
            </div>
          </Box>
        </DialogContent>
      </StyledDialog>
    );
  }
};

export default AddNewContactPopup;
