import { Type } from 'class-transformer';
import { QueryParams } from '../commonMixed';

class PaymentDTO {
  'billing_address'!: string;
  'chargify_token'!: string | null;
  'subscription_id'?: string;
  'payment_method'!: string;
  'billing_city'?: string;
  'billing_state'?: string;
  'billing_zip'?: string;
  'billing_country'?: string;
}

export class Register {
  country?: string;
  address!: string;
  dot!: string;
  email!: string;
  fleetSize!: number | string;
  mobileno!: string;
  organizationName!: string;
  password!: string;
  paymentToken!: string | null;
  firstname!: string;
  lastname?: string;
  @Type(() => PaymentDTO)
  paymentProfileDTO!: PaymentDTO;
  countryCode?: string;
  extension?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  ipAddress?: string;
}

export interface RegisterPage {
  country: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  organizationName: string;
  loginMethod: string;
  dot: string;
  ipAddress: string;
  timeZone: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
}

export class ForgotPasswordRequest {
  dot!: string;
  email!: string;
}

export class ResetPasswordRequest {
  activationKey!: string;
  password!: string;
  userId!: string;
  organizationId!: string;
}

export class CancelSubscriptionRequest extends QueryParams {
  cancellationMessage?: string;
  reasonCode?: string;
  delete?: boolean;
}

export class UpsertPaymentRequest {
  default_payment_type?: boolean;
  customer_id?: number;
  subscription_id?: number;
  chargify_token?: string;
  first_name?: string;
  last_name?: string;
  billing_address?: string;
  billing_city?: string;
  billing_state?: string;
  billing_zip?: string;
  billing_country?: string;
  subscription_state?: string;
  subscriptionId?: string;
  paymentProfileId?: string;
  payment_profile_id?: string;
}
export class UpsertPaymentRejoinRequest {
  organizationId?: number;
  paymentProfile?: UpsertPaymentRequest;
  ipAddress?: string;
}

export class RegisterResponse {
  dot!: string;
  email!: string;
  password!: string;
}

export class GetSubscriptionRequest extends QueryParams {}
export class GetSubscriptionResponse {
  subscription?: Record<string, any>;
  component?: Record<string, any>;
}

export class ActivateAccount {
  organizationId?: number;
  subscriptionId!: string;
  componentId!: string;
  enabled!: number | string;
}

export class SubscriptionPlanRequest {
  organizationId?: number;
  paymentProfile?: UpsertPaymentRequest;
  ipAddress?: string;
  pricePoint?: string | null;
}

export class GetInvoiceListSubscriptionRequest extends QueryParams {}
export class GetInvoiceListResponse {}

export class GetPaymentProfileRequest {
  customerId!: string;
}
