import { Service } from './Service';

export abstract class IDriverService extends Service {
  abstract getDriverList(
    nameFilter: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    activeForOperation?: boolean,
    typeFilter?: string
  ): any;
}
