import { Box } from '@mui/material';
import { MESSAGES } from '../../messages';
import * as animation from '../../../../_assets/json/loadai_optimization_resume_animation.json';
import Lottie from 'lottie-react';
import { Typewriter } from 'react-simple-typewriter';
import { LoaderTextStyles, AnimationBodyStyles } from '../../styles';
import { shuffleList } from '../../services/operations.service';

const ResumeOptytmizationLoader = () => {
  return (
    <Box sx={AnimationBodyStyles}>
      <Box maxHeight={300}>
        <Lottie animationData={animation} loop style={{ height: 300 }} />
      </Box>
      <span style={LoaderTextStyles}>
        <Typewriter
          words={shuffleList(MESSAGES.resumeOptimization)}
          loop={5}
          cursor
          cursorStyle=""
          typeSpeed={60}
          deleteSpeed={40}
          delaySpeed={1000}
        />
      </span>
    </Box>
  );
};

export default ResumeOptytmizationLoader;
