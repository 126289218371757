import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import FormDatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';
import FormDialog from '../../../../common/Ui/FormDialog';

const CloseBulkSettlements = ({
  onClose,
  onAction,
  payPeriodText,
  seqNumber,
  isMultiple = false,
}: {
  onClose?: () => void;
  onAction?: (data?: any) => void;
  payPeriodText?: string;
  seqNumber?: number;
  isMultiple?: boolean;
}) => {
  const { control, watch } = useFormContext();
  const [paymentDate] = watch(['paymentDate']);

  const getTitle = () => {
    if (!seqNumber) return `Close Settlement${isMultiple ? 's' : ''}`;
    return `Close Settlement #: ${seqNumber}`;
  };

  return (
    <FormDialog
      data={{}}
      titleText={getTitle()}
      actionLabel={t(isMultiple ? 'closeSettlements' : 'closeSettlement')}
      open={true}
      onAction={() => onAction?.({ paymentDate })}
      handleClose={onClose}
      contentRenderer={() => (
        <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
          {Boolean(payPeriodText) && (
            <Typography
              variant="body3"
              lineHeight={'140%'}
              color={'text.secondary'}
            >
              Settlement Period: <b>{payPeriodText}</b>
            </Typography>
          )}
          <FormDatePicker
            styleProps={{
              width: '100%',
            }}
            sizes={{
              xs: 12,
            }}
            required
            control={control}
            label={t('settlementPaymentDate')}
            name={`paymentDate`}
            // timezone={getCompanyPreferences?.timezone}
            caption={t('closeSettlementFormHelperText')}
          />
        </Box>
      )}
    />
  );
};

export default CloseBulkSettlements;
