import { SxProps, Theme } from '@mui/material';

export const getRelativeDateRangeStyles = ({
  isFormControl,
  theme,
  width,
  optionWidth,
}: {
  theme: Theme;
  isFormControl?: boolean;
  width: string;
  optionWidth?: string;
}): { [key: string]: SxProps } => {
  return {
    TextField: {
      cursor: 'pointer',
      fontWeight: 400,
      fontSize: 15,
      textAlign: 'left',
      whiteSpace: 'nowrap',
      textTransform: 'none',
      minWidth: '100%',
      width: width,
      display: 'flex',
      justifyContent: 'space-between',
      color: isFormControl ? 'text.primary' : 'primary.main',
      borderColor: isFormControl ? 'text.primary' : 'primary.main',
      borderRadius: isFormControl ? 0 : '6px',
      borderWidth: isFormControl ? '0 0 1px 0px' : '1px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: !isFormControl && 'rgba(43, 100, 203, 0.5)',
        borderRadius: !isFormControl && '6px',
      },
      '& .MuiOutlinedInput-input': {
        color: !isFormControl && 'rgba(43, 100, 203, 1)',
      },
      ...(isFormControl && {
        paddingLeft: 0,
      }),
    },
    TextFieldInputLabelProps: {
      ...(isFormControl
        ? {
            position: 'static',
            transform: 'none',
            overflow: 'visible',
            '+ .MuiInputBase-root': {
              marginTop: 0,
            },
          }
        : {
            color: 'rgba(43, 100, 203, 1)',
          }),
    },
    Menu: {
      '& .MuiPaper-root': {
        width: optionWidth ? optionWidth : width,
        maxHeight: '100vh',
        overflowY: 'auto',
        top: '16px',
      },
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
    },
    MenuItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    MenuItemTitle: {
      color: 'text.primary',
    },
    MenuItemSubTitle: {
      color: 'text.secondary',
    },
    MenuItemDatepicker: {
      width: '100%',
    },
  };
};
