export const BitFreighterLogo = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 369 85"
      fill="#2bdb19"
    >
      <g>
        <path d="M33.83,62.98c-2.99,0-5.68-.69-8.08-2.07-2.39-1.38-4.29-3.26-5.69-5.66-1.4-2.39-2.12-5.08-2.15-8.08V20.04c0-.69,.2-1.24,.6-1.64s.95-.6,1.64-.6c.65,0,1.17,.2,1.58,.6,.41,.41,.6,.95,.6,1.64v16.9c1.27-1.8,2.94-3.24,5.03-4.33,2.09-1.09,4.42-1.64,6.98-1.64,2.95,0,5.59,.7,7.93,2.1s4.19,3.31,5.55,5.72c1.36,2.41,2.04,5.13,2.04,8.16s-.7,5.8-2.1,8.22c-1.4,2.41-3.31,4.32-5.72,5.72-2.42,1.39-5.17,2.1-8.23,2.1Zm0-4.02c2.22,0,4.21-.53,5.98-1.58,1.76-1.05,3.15-2.48,4.17-4.28s1.53-3.85,1.53-6.15-.51-4.3-1.53-6.12-2.4-3.24-4.17-4.28-3.75-1.55-5.98-1.55-4.16,.52-5.91,1.55c-1.77,1.04-3.14,2.46-4.14,4.28s-1.5,3.86-1.5,6.12,.5,4.34,1.5,6.15c1,1.8,2.38,3.23,4.14,4.28,1.76,1.05,3.73,1.58,5.91,1.58Z" />
        <path d="M61.64,25.28c-.84,0-1.57-.3-2.18-.91-.61-.61-.91-1.36-.91-2.25,0-.96,.31-1.72,.95-2.27s1.37-.83,2.21-.83,1.52,.28,2.15,.83c.63,.55,.95,1.31,.95,2.27,0,.88-.31,1.63-.91,2.25-.61,.6-1.36,.91-2.25,.91Zm.06,37.41c-.69,0-1.24-.2-1.64-.6s-.6-.95-.6-1.64v-26.95c0-.69,.2-1.24,.6-1.64s.95-.6,1.64-.6c.65,0,1.17,.2,1.58,.6,.41,.41,.6,.95,.6,1.64v26.95c0,.69-.2,1.24-.6,1.64-.41,.41-.94,.6-1.58,.6Z" />
        <path d="M73.14,36.26c-.57,0-1.05-.18-1.43-.55-.38-.36-.57-.81-.57-1.35,0-.57,.19-1.04,.57-1.4s.86-.55,1.43-.55h14.66c.57,0,1.05,.19,1.43,.55s.57,.83,.57,1.4c0,.54-.19,.99-.57,1.35s-.86,.55-1.43,.55h-14.66Zm14.31,26.43c-2.03,0-3.85-.48-5.43-1.43-1.59-.96-2.85-2.27-3.76-3.94-.91-1.66-1.38-3.58-1.38-5.72V23.49c0-.65,.2-1.18,.6-1.61,.41-.43,.93-.63,1.58-.63s1.18,.21,1.61,.63,.63,.96,.63,1.61v28.11c0,1.95,.57,3.55,1.73,4.8s2.62,1.87,4.43,1.87h1.55c.61,0,1.11,.21,1.5,.63s.57,.96,.57,1.61-.23,1.17-.69,1.58c-.46,.41-1.04,.6-1.73,.6h-1.22Z" />
      </g>
      <g>
        <path
          className="cls-1"
          d="M100.14,36.24c-.61,0-1.09-.18-1.46-.54s-.54-.81-.54-1.34c0-.57,.18-1.04,.54-1.4s.84-.54,1.46-.54h14.89c.6,0,1.09,.18,1.46,.54s.54,.83,.54,1.4c0,.53-.18,.98-.54,1.34s-.85,.54-1.46,.54h-14.89Zm5.76,26.26c-.57,0-1.07-.2-1.49-.6-.42-.39-.62-.95-.62-1.63V31.33c0-2.66,.46-5,1.37-7.02,.91-2.02,2.27-3.59,4.05-4.71s3.98-1.68,6.57-1.68c.64,0,1.17,.19,1.6,.57,.42,.38,.62,.85,.62,1.42s-.21,1.05-.62,1.42c-.42,.38-.96,.57-1.6,.57-1.72,0-3.13,.39-4.25,1.17s-1.96,1.86-2.52,3.25c-.55,1.39-.83,2.98-.83,4.77v29.17c0,.69-.2,1.23-.6,1.63-.4,.38-.96,.58-1.68,.58Z"
        />
        <path
          className="cls-1"
          d="M125.08,62.49c-.69,0-1.22-.19-1.6-.57-.38-.38-.57-.91-.57-1.6v-26.89c0-.69,.19-1.22,.57-1.6s.91-.57,1.6-.57,1.22,.19,1.6,.57c.37,.38,.57,.91,.57,1.6v26.9c0,.69-.19,1.22-.57,1.6-.37,.37-.91,.56-1.6,.56Zm-.34-19.23c.11-2.36,.73-4.47,1.85-6.34,1.12-1.86,2.59-3.33,4.4-4.4,1.81-1.06,3.81-1.6,6.02-1.6,1.75,0,3.1,.25,4.05,.74,.95,.5,1.31,1.22,1.08,2.17-.16,.57-.41,.96-.77,1.14s-.8,.27-1.31,.23c-.51-.04-1.09-.07-1.75-.11-2.13-.19-4.02,.03-5.68,.65s-2.97,1.59-3.94,2.88c-.97,1.3-1.46,2.84-1.46,4.63h-2.5Z"
        />
        <path
          className="cls-1"
          d="M160.93,62.78c-3.09,0-5.81-.68-8.19-2.03s-4.24-3.22-5.59-5.62-2.03-5.13-2.03-8.22,.63-5.87,1.91-8.25,3.04-4.25,5.28-5.62c2.25-1.37,4.83-2.06,7.76-2.06s5.42,.65,7.57,1.96c2.15,1.31,3.81,3.13,5,5.45,1.17,2.32,1.77,4.97,1.77,7.93,0,.61-.19,1.09-.57,1.46s-.87,.54-1.49,.54h-24.26v-3.65h24.72l-2.45,1.77c.04-2.2-.36-4.19-1.2-5.94-.84-1.75-2.03-3.12-3.57-4.11s-3.38-1.49-5.51-1.49-4.07,.51-5.71,1.54c-1.63,1.03-2.9,2.44-3.79,4.25s-1.34,3.88-1.34,6.19,.5,4.38,1.52,6.16c1.01,1.79,2.39,3.2,4.14,4.22,1.75,1.03,3.76,1.54,6.05,1.54,1.37,0,2.75-.24,4.14-.72,1.39-.48,2.51-1.07,3.34-1.8,.42-.34,.89-.52,1.42-.54s.99,.12,1.37,.43c.5,.46,.75,.96,.77,1.49,.02,.53-.2,.99-.65,1.37-1.26,1.07-2.86,1.95-4.79,2.65-1.95,.74-3.83,1.09-5.61,1.09Z"
        />
        <path
          className="cls-1"
          d="M184.91,25.33c-.84,0-1.56-.3-2.17-.91-.61-.61-.91-1.35-.91-2.22,0-.95,.31-1.7,.95-2.26,.62-.55,1.36-.83,2.19-.83s1.51,.28,2.14,.83c.62,.55,.95,1.3,.95,2.26,0,.87-.3,1.62-.91,2.22-.61,.61-1.35,.91-2.22,.91Zm.05,37.16c-.69,0-1.23-.2-1.63-.6-.39-.39-.6-.95-.6-1.63v-26.78c0-.69,.2-1.23,.6-1.63,.39-.39,.95-.6,1.63-.6,.64,0,1.17,.2,1.57,.6,.39,.39,.6,.95,.6,1.63v26.78c0,.69-.2,1.23-.6,1.63-.4,.41-.93,.6-1.57,.6Z"
        />
        <path
          className="cls-1"
          d="M212.08,62.78c-3,0-5.68-.68-7.99-2.03s-4.14-3.22-5.45-5.62-1.96-5.13-1.96-8.22,.69-5.88,2.06-8.27c1.37-2.4,3.25-4.27,5.65-5.62s5.11-2.03,8.16-2.03,5.81,.68,8.19,2.03,4.24,3.22,5.59,5.62,2.05,5.16,2.08,8.27l-2.57,1.37c0,2.82-.59,5.32-1.77,7.5-1.18,2.19-2.81,3.9-4.89,5.13-2.08,1.24-4.45,1.86-7.11,1.86Zm.57,12.05c-3,0-5.65-.56-7.93-1.68-2.29-1.12-4.17-2.65-5.65-4.59-.42-.46-.6-.96-.54-1.49,.05-.53,.33-.97,.83-1.31s1.05-.46,1.65-.34c.6,.11,1.08,.39,1.42,.85,1.1,1.37,2.52,2.47,4.25,3.32,1.74,.83,3.74,1.26,6.02,1.26,2.13,0,4.05-.51,5.77-1.54,1.72-1.03,3.08-2.47,4.08-4.33,1.01-1.86,1.52-4.05,1.52-6.57v-8.68l1.65-3.32,2.68,.51v11.64c0,3.09-.69,5.85-2.06,8.3-1.37,2.45-3.23,4.4-5.59,5.82-2.37,1.43-5.07,2.15-8.11,2.15Zm-.11-16.05c2.25,0,4.23-.5,5.97-1.52,1.74-1.01,3.09-2.41,4.08-4.2,.99-1.79,1.49-3.85,1.49-6.16s-.5-4.39-1.49-6.19c-.99-1.81-2.35-3.21-4.08-4.22-1.74-1.01-3.72-1.52-5.97-1.52s-4.24,.51-6,1.52c-1.75,1.01-3.12,2.42-4.11,4.22-.99,1.81-1.49,3.88-1.49,6.19s.5,4.38,1.49,6.16,2.36,3.19,4.11,4.2c1.75,1.02,3.74,1.52,6,1.52Z"
        />
        <path
          className="cls-1"
          d="M239.19,49.77c-.69,0-1.23-.21-1.62-.62-.39-.42-.6-.95-.6-1.6V20.15c0-.69,.2-1.23,.6-1.63,.39-.39,.95-.6,1.62-.6s1.17,.2,1.57,.6c.41,.39,.6,.95,.6,1.63v27.4c0,.64-.2,1.18-.6,1.6-.39,.42-.93,.62-1.57,.62Zm0,12.72c-.69,0-1.23-.2-1.62-.6-.39-.39-.6-.93-.6-1.57v-26.83c0-.69,.2-1.23,.6-1.63,.39-.39,.95-.6,1.62-.6s1.17,.2,1.57,.6c.41,.39,.6,.95,.6,1.63v26.84c0,.64-.2,1.17-.6,1.57-.39,.39-.93,.59-1.57,.59Zm24.55,0c-.64,0-1.18-.21-1.6-.62s-.62-.94-.62-1.54v-14.84c0-2.36-.45-4.3-1.34-5.82s-2.11-2.66-3.65-3.43c-1.54-.76-3.28-1.14-5.22-1.14s-3.54,.36-5.05,1.08c-1.51,.73-2.69,1.72-3.57,2.97s-1.31,2.68-1.31,4.28h-3.16c.07-2.4,.72-4.54,1.94-6.42,1.22-1.88,2.84-3.37,4.85-4.45,2.02-1.08,4.26-1.63,6.74-1.63,2.7,0,5.11,.56,7.24,1.68s3.81,2.76,5.05,4.94,1.86,4.81,1.86,7.93v14.85c0,.61-.21,1.12-.62,1.54-.44,.42-.95,.62-1.55,.62Z"
        />
        <path
          className="cls-1"
          d="M273.61,36.24c-.57,0-1.05-.18-1.42-.54-.38-.36-.57-.81-.57-1.34,0-.57,.19-1.04,.57-1.4s.85-.54,1.42-.54h14.55c.57,0,1.05,.18,1.42,.54,.38,.36,.57,.83,.57,1.4,0,.53-.19,.98-.57,1.34s-.85,.54-1.42,.54h-14.55Zm14.21,26.26c-2.02,0-3.81-.48-5.39-1.42-1.58-.95-2.83-2.26-3.74-3.91-.91-1.65-1.37-3.54-1.37-5.68V23.58c0-.64,.2-1.17,.6-1.6,.39-.42,.93-.62,1.57-.62s1.17,.21,1.6,.62c.42,.42,.62,.96,.62,1.6v27.91c0,1.94,.57,3.53,1.72,4.77s2.61,1.85,4.4,1.85h1.54c.61,0,1.1,.21,1.49,.62,.38,.42,.57,.96,.57,1.6s-.23,1.17-.69,1.57-1.03,.6-1.72,.6h-1.2Z"
        />
        <path
          className="cls-1"
          d="M312.71,62.78c-3.09,0-5.81-.68-8.19-2.03s-4.24-3.22-5.59-5.62-2.03-5.13-2.03-8.22,.63-5.87,1.91-8.25,3.04-4.25,5.28-5.62c2.25-1.37,4.83-2.06,7.76-2.06s5.42,.65,7.57,1.96c2.15,1.31,3.81,3.13,5,5.45,1.17,2.32,1.77,4.97,1.77,7.93,0,.61-.19,1.09-.57,1.46s-.87,.54-1.49,.54h-24.26v-3.65h24.72l-2.45,1.77c.04-2.2-.36-4.19-1.2-5.94-.84-1.75-2.03-3.12-3.57-4.11s-3.38-1.49-5.51-1.49-4.07,.51-5.71,1.54c-1.63,1.03-2.9,2.44-3.79,4.25s-1.34,3.88-1.34,6.19,.5,4.38,1.52,6.16c1.01,1.79,2.39,3.2,4.14,4.22,1.75,1.03,3.76,1.54,6.05,1.54,1.37,0,2.74-.24,4.14-.72,1.39-.48,2.51-1.07,3.34-1.8,.42-.34,.89-.52,1.42-.54s.99,.12,1.37,.43c.5,.46,.75,.96,.77,1.49,.02,.53-.2,.99-.65,1.37-1.26,1.07-2.86,1.95-4.79,2.65-1.95,.74-3.83,1.09-5.61,1.09Z"
        />
        <path
          className="cls-1"
          d="M335.77,62.49c-.69,0-1.22-.19-1.6-.57-.38-.38-.57-.91-.57-1.6v-26.89c0-.69,.19-1.22,.57-1.6s.91-.57,1.6-.57,1.22,.19,1.6,.57c.37,.38,.57,.91,.57,1.6v26.9c0,.69-.19,1.22-.57,1.6-.38,.37-.91,.56-1.6,.56Zm-.34-19.23c.11-2.36,.73-4.47,1.85-6.34,1.12-1.86,2.59-3.33,4.4-4.4,1.81-1.06,3.81-1.6,6.02-1.6,1.75,0,3.1,.25,4.05,.74,.95,.5,1.31,1.22,1.08,2.17-.16,.57-.41,.96-.77,1.14s-.8,.27-1.31,.23c-.51-.04-1.09-.07-1.75-.11-2.13-.19-4.02,.03-5.68,.65s-2.97,1.59-3.94,2.88c-.97,1.3-1.46,2.84-1.46,4.63h-2.5Z"
        />
      </g>
      <path d="M369,4.69V80.52c0,2.42-1.97,4.4-4.4,4.4H93.88v-4.48H364.52V4.78H93.88V.3H364.61c2.42,0,4.39,1.96,4.39,4.39Z" />
      <path
        className="cls-1"
        d="M4.69,4.78V80.43H93.88v4.48H4.61c-2.42,0-4.4-1.97-4.4-4.4V4.69C.21,2.27,2.19,.29,4.61,.29H93.88V4.77H4.69Z"
      />
    </svg>
  );
};
