import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import { NumberToCurrency } from '../../../../constants/numberToCurrency';

interface MileageProps {
  value: number;
}

const Mileage: FC<MileageProps> = ({ value }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '150%',
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '0.15px',
        }}
        gutterBottom
        color="primary.main"
      >
        {`${NumberToCurrency(value)}/loaded mi`}
      </Typography>
      <Typography variant="caption" gutterBottom sx={{ color: '#000' }}>
        {`${NumberToCurrency(value)}/total mi`}
      </Typography>
    </Box>
  );
};

export default memo(Mileage);
