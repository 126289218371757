import { IDriverTooltip, ResourceColumn } from '@optym/gantt';
import {
  ColumnHeader,
  ColumnHeaderTooltipTemplate,
  MultiLineColumn,
  SingleLineColumn,
} from '../../../components/Gantt';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  COLUMN_FULL_HEIGHT,
  DRIVER_COLUMN_MAX_WIDTH,
  DRIVER_RESOURCE_NAME_CLASSNAME,
  OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS,
} from '../constants/gantt.const';
import {
  isResourceTypeAssignedDriver,
  isResourceTypeBrokeredTrip,
  isResourceTypeUnassignedDriver,
  isResourceTypeUnassignedTrip,
} from '../services/recommendTrips.service';
import { driverTooltipProps } from '../services/tooltip.service';
import {
  GANTT_DEFAULT_ZOOM,
  ganttLegend,
  textStyles,
} from '../../../constants/gantt/gantt.const';
import { IRecommendedResource } from '../types/gantt.types';
import moment from 'moment';
import { appendTextToDigit } from '../../../services/gantt';
import TripColumn from '../components/gantt/TripColumn';
import { LOADAI_COLUMN_HEADER_TOOLTIP } from '../../../constants/gantt/columnTooltip.config';
import { Box, Typography } from '@mui/material';
import { BrokeredTripDriverColumnStyles } from '../styles';

export const GANTT_WINDOW_SIZE = {
  0: 7,
  1: 5,
  2: 3,
} as any;

export const DEFAULT_ZOOM_LEVEL = GANTT_DEFAULT_ZOOM;
export const DEFAULT_WINDOW_SIZE = GANTT_WINDOW_SIZE[DEFAULT_ZOOM_LEVEL];
export const GANTT_DEFAULT_START_DATE = new Date(
  new Date().setHours(0, 0, 0, 0)
);

export const ZOOM_LEVELS = {
  MIN: 0,
  MAX: 2,
};

export const VIEW_PRESET_BY_WINDOW = {
  0: 'hourAndDay3',
  1: 'hourAndDay2',
  2: 'hourAndDay',
} as any;

export const optymizeGanttDefaultControlsConfig = {
  allowDefaultNavigationControl: false,
  showControlActions: true,
  allowDefaultZoomControl: true,
};

export const GANTT_LOCKED_SUBGRID_CONFIG = {
  width: 350,
  maxWidth: DRIVER_COLUMN_MAX_WIDTH,
};

const ganttLegendContent = [
  {
    color: '#B6BDC7',
    text: 'Completed',
    textStyles,
  },
  {
    color: '#5C667D',
    text: 'Planned',
    textStyles,
  },
  {
    color: '#2F3B58',
    text: 'Assigned',
    textStyles,
  },
  {
    color: '#008280',
    text: 'Dispatched',
    textStyles,
  },
  {
    color: '#0C6BB9',
    text: 'In Transit',
    textStyles,
  },
  {
    color: '#fff',
    text: 'Deadhead',
    textStyles,
    colorBoxStyles: {
      border: '2px solid #3B873E',
    },
  },
  {
    color: '#fff',
    text: 'Need Load',
    textStyles,
    colorBoxStyles: {
      border: '1px solid red',
    },
  },
];

export const optymizationGanttDefaultConfig = {
  zoomOnMouseWheel: false,
  infiniteScroll: false,
  forceFit: false,
  puckHeight: '22px',
  resourceMargin: 5,
  rowHeight: 33,
  disableTooltip: true,
  fontFamily: 'Poppins',
  legend: {
    ...ganttLegend,
    content: ganttLegendContent,
  },
  showCellTooltip: false,
  showCurrentTimeLine: false,
  transitionDuration: 0,
  height: 'calc(100% - 86px)',
  subGridConfigs: {
    locked: GANTT_LOCKED_SUBGRID_CONFIG,
  },
  showTooltipOnClick: true,
  resourceStore: { syncDataOnLoad: { keepMissingValues: false } },
};

export const possibleResourcesGanttConfig = {
  ...optymizationGanttDefaultConfig,
  height: 'calc(100% - 50px)',
  ganttBottomBarStyles: { display: 'none' },
};

const getColumnHeaderTooltipConfig = (
  type: keyof typeof LOADAI_COLUMN_HEADER_TOOLTIP
) => {
  return {
    hideDelay: 50,
    hoverDelay: 300,
    hideOnDelegateChange: true,
    html: renderToStaticMarkup(
      <ColumnHeaderTooltipTemplate
        labels={LOADAI_COLUMN_HEADER_TOOLTIP[type]}
      />
    ),
  };
};

export const driverNameColumn = ({
  onActionClick,
  onResetClick,
  allowReset = true,
  allowActions = true,
}: {
  onActionClick?: (data: any) => void;
  onResetClick?: (data: any) => void;
  allowReset?: boolean;
  allowActions?: boolean;
}) => ({
  id: '2',
  type: 'resourceInfo',
  draggable: false,
  width: 120,
  headerRenderer: ({
    headerElement,
  }: {
    headerElement: HTMLElement;
  }): string => {
    headerElement?.classList?.add?.(
      `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
    );
    return renderToStaticMarkup(
      <ColumnHeader name="Driver" textStyles={{ fontSize: '12px' }} />
    );
  },
  tooltip: getColumnHeaderTooltipConfig('DRIVER'),
  renderer: (event: any) => {
    const { data } = event?.record as { data: IRecommendedResource };
    const tooltipProps = driverTooltipProps(data);
    event?.cellElement?.children[0]?.classList?.add?.(COLUMN_FULL_HEIGHT);
    if (isResourceTypeUnassignedTrip(data))
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            background: 'rgba(0, 17, 34, 0.04)',
            minHeight: '35px',
          }}
        ></div>
      );
    if (isResourceTypeBrokeredTrip(data)) {
      return (
        <Typography sx={BrokeredTripDriverColumnStyles}>
          {BROKERED_TRIP_DRIVER_COLUMN_LABEL}
        </Typography>
      );
    }
    if (event.cellElement?.children?.length)
      event.cellElement?.children[0]?.classList.add(
        DRIVER_RESOURCE_NAME_CLASSNAME
      );
    return (
      <ResourceColumn
        showColumnTooltipOnClick={true}
        containerStyles={{ cursor: 'pointer' }}
        onResetClick={() => onResetClick?.(data)}
        columnDetails={[
          {
            allowReset: false,
            text: data?.driverGroupDetails?.groupName ?? '-',
            textStyles: {
              color: 'rgba(0, 17, 34, 0.75)',
              fontWeight: 400,
              letterSpacing: '0.15px',
            },
          },
        ]}
        columnTooltipProps={
          {
            ...tooltipProps,
            onActionClick: (action: any) => onActionClick?.({ action, data }),
            actions:
              allowActions && !data?.engineMultiTripOutput?.isLocked
                ? tooltipProps?.actions
                : [],
          } as IDriverTooltip
        }
      />
    );
  },
});
export const TRIP_COLUMN_ID = 'tripColumn';
export const TRIP_COLUMN_WIDTH = 100;
export const additionalDriverColumns = ({
  onCellClick,
}: {
  onCellClick?: (event: any) => void;
}) => [
  {
    id: TRIP_COLUMN_ID,
    draggable: false,
    width: TRIP_COLUMN_WIDTH,
    minWidth: TRIP_COLUMN_WIDTH,
    headerRenderer: ({ headerElement }: { headerElement: HTMLElement }) => {
      headerElement?.classList?.add?.(
        `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
      );
      return renderToStaticMarkup(
        <ColumnHeader
          name="Trip"
          textStyles={{ fontSize: '12px', letterSpacing: '0.15px' }}
        />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('TRIP'),
    renderer: (event: any) => {
      event?.cellElement?.children[0]?.classList.add?.(COLUMN_FULL_HEIGHT);
      const { data } = event?.record as { data: IRecommendedResource };
      if (isResourceTypeUnassignedDriver(data))
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              background: 'rgba(0, 17, 34, 0.04)',
              minHeight: '35px',
            }}
          ></div>
        );
      const columnDetails = data?.selectedTripData?.map((trip) => {
        return {
          allowReset: false,
          text: trip?.tripSeqNumber ?? '',
        };
      });
      return (
        <TripColumn
          columnDetails={columnDetails}
          defaultWidth={TRIP_COLUMN_WIDTH}
          containerStyle={{ cursor: 'pointer' }}
        />
      );
    },
  },
  {
    id: 'dhmiles',
    draggable: false,
    width: 80,
    headerRenderer: ({ headerElement }: { headerElement: HTMLElement }) => {
      headerElement?.classList?.add?.(
        `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
      );
      return renderToStaticMarkup(
        <ColumnHeader name={'Dh. Miles'} textStyles={{ fontSize: '12px' }} />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('DHMILES'),
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          containerStyle={{
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          data={[
            {
              text: appendTextToDigit({
                val: data?.engineMultiTripOutput?.tourKPI?.deadheadMiles,
                postfixLabel: ' mi',
              }),
              textStyles: { fontSize: '12px' },
              containerStyles: { width: '100%' },
            },
          ]}
        />
      );
    },
  },
  {
    id: 'delays',
    draggable: false,
    width: 60,
    headerRenderer: ({ headerElement }: { headerElement: HTMLElement }) => {
      headerElement?.classList?.add?.(
        `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
      );
      return renderToStaticMarkup(
        <ColumnHeader name={'Delays'} textStyles={{ fontSize: '12px' }} />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('DELAYS'),
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          containerStyle={{
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          data={[
            {
              text: appendTextToDigit({
                val: data?.engineMultiTripOutput?.tourKPI?.delay,
                postfixLabel: ' h',
              }),
              textStyles: { fontSize: '12px' },
              containerStyles: { width: '100%' },
            },
          ]}
        />
      );
    },
  },
  {
    id: 'revenue/h',
    draggable: false,
    width: 60,
    headerRenderer: ({ headerElement }: { headerElement: HTMLElement }) => {
      headerElement?.classList?.add?.(
        `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
      );
      return renderToStaticMarkup(
        <ColumnHeader
          name={'$/h'}
          containerStyles={{ display: 'flex' }}
          separator="-"
          textStyles={{ fontSize: '12px' }}
        />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('DOLLARPERHOUR'),
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          containerStyle={{ display: 'flex', cursor: 'pointer' }}
          data={[
            {
              text: appendTextToDigit({
                val: data?.engineMultiTripOutput?.tourKPI?.revenuePerHour,
                prefixLabel: '$',
                postfixLabel: '/h',
              }),
              textStyles: { fontSize: '12px' },
              containerStyles: { width: '100%' },
            },
          ]}
        />
      );
    },
  },
  {
    id: 'revenue/mi',
    draggable: false,
    width: 60,
    headerRenderer: ({ headerElement }: { headerElement: HTMLElement }) => {
      headerElement?.classList?.add?.(
        `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
      );
      return renderToStaticMarkup(
        <ColumnHeader
          name={'$/mi'}
          containerStyles={{ display: 'flex' }}
          textStyles={{ fontSize: '12px' }}
        />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('DOLLARPERMILE'),
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          containerStyle={{ display: 'flex', cursor: 'pointer' }}
          data={[
            {
              text: appendTextToDigit({
                val: data?.engineMultiTripOutput?.tourKPI?.revenuePerMile,
                postfixLabel: '/mi',
                prefixLabel: '$',
              }),
              textStyles: { fontSize: '12px' },
              containerStyles: { width: '100%' },
            },
          ]}
        />
      );
    },
  },
  {
    id: '6',
    draggable: false,
    width: 120,
    headerRenderer: ({ headerElement }: { headerElement: HTMLElement }) => {
      headerElement?.classList?.add?.(
        `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
      );
      return renderToStaticMarkup(
        <ColumnHeader
          name={['Tp. Pickup Loc']}
          textStyles={{ fontSize: '12px' }}
        />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('TRIPPICKUPLOC'),
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          containerStyle={{ cursor: 'pointer' }}
          data={[
            {
              text: data?.tripPickupLocation,
              textStyles: { fontSize: '12px' },
            },
          ]}
        />
      );
    },
  },
  {
    id: 'timeToPickup',
    draggable: false,
    width: 130,
    headerRenderer: ({ headerElement }: { headerElement: HTMLElement }) => {
      headerElement?.classList?.add?.(
        `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
      );
      return renderToStaticMarkup(
        <ColumnHeader
          name={'Time to Pickup'}
          textStyles={{ fontSize: '12px' }}
        />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('TIMETOPICKUP'),
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          containerStyle={{ cursor: 'pointer' }}
          data={[
            {
              text: data?.timeToPickup,
              textStyles: { fontSize: '12px' },
            },
          ]}
        />
      );
    },
  },
  {
    id: '7',
    draggable: false,
    width: 140,
    headerRenderer: ({ headerElement }: { headerElement: HTMLElement }) => {
      headerElement?.classList?.add?.(
        `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
      );
      return renderToStaticMarkup(
        <ColumnHeader
          name={['Dr. Avail. Loc']}
          textStyles={{ fontSize: '12px' }}
        />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('DRIVERAVAILABLELOC'),
    renderer: (event: any) => {
      const { data } = event?.record;
      return (
        <MultiLineColumn
          containerStyle={{ cursor: 'pointer' }}
          data={[
            {
              text: data?.driverAvailableLocation,
              textStyles: { fontSize: '12px' },
            },
          ]}
        />
      );
    },
  },
  {
    id: '8',
    draggable: false,
    width: 120,
    headerRenderer: ({ headerElement }: { headerElement: HTMLElement }) => {
      headerElement?.classList?.add?.(
        `${OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS}`
      );
      return renderToStaticMarkup(
        <ColumnHeader
          name={['Dr. Avail. Time']}
          textStyles={{ fontSize: '12px' }}
        />
      );
    },
    tooltip: getColumnHeaderTooltipConfig('DRIVERAVAILABLETIME'),
    renderer: (event: any) => {
      const { data } = event?.record;
      if (event?.row?.elements?.normal?.style) {
        if (isResourceTypeAssignedDriver(data)) {
          event.row.elements.normal.style.cursor = 'pointer';
        } else event.row.elements.normal.style.cursor = 'inherit';
      }
      return (
        <MultiLineColumn
          containerStyle={{ cursor: 'pointer' }}
          data={[
            {
              text: data?.driverAvailableTime,
              textStyles: { fontSize: '12px' },
            },
          ]}
        />
      );
    },
  },
];

export const timelineDateFormat = 'YYYY-MM-DD HH:mm:ss';

export const GANTT_LOADER_CONFIG = {
  text: '',
  progress: 0,
  maxProgress: 100,
  mode: 'dark',
  autoClose: 30000,
  icon: 'loader',
};
export const GANTT_DEFAULT_TIMELINE_RANGE = 21;
export const DEFAULT_GANTT_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const defaultGanttTimelineRange = {
  startDate: new Date(new Date().setHours(0, 0, 0, 0)),
  endDate: moment(new Date())
    .add(GANTT_DEFAULT_TIMELINE_RANGE, 'days')
    .format(DEFAULT_GANTT_DATE_FORMAT),
};
export const GANTT_ACTION_COLUMN_WIDTH = 155;

export const OPTIMIZE_GANTT_ALL_LOCK_ID = 'all-resource-lock';

export const BROKERED_TRIP_DRIVER_COLUMN_LABEL = 'Brokered';
