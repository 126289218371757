import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import FormDatePicker from '../../../common/Ui/FormDatePicker/FormDatePicker';
import Select from '../../../common/Ui/Select';
import TextField from '../../../common/Ui/TextField/TextField';
import { TractorAlertSummaryDTO } from '../../../models';
import { findNextServiceDueOdometerByFrequency } from '../utils';

import { useTranslation } from 'react-i18next';
import { findNextDueDateByFrequency } from '../../shared/equipment';

type props = {
  alertOptions: TractorAlertSummaryDTO[];
};

const AddNewAlertForm = ({ alertOptions = [] }: props) => {
  const { control, setValue, watch } = useFormContext();

  const [maintenanceTypeId, lastConducted, lastServiceDueOdometer] = watch([
    'maintenanceTypeId',
    'lastConducted',
    'lastServiceDueOdometer',
  ]);

  const onMaintenanceItemChanged = () => {
    onLastConductedDateChanged(lastConducted);
    onLastDueOdometerChanged(lastServiceDueOdometer);
  };

  const onLastConductedDateChanged = (lastConductedDate: string) => {
    setValue(
      'nextDue',
      findNextDueDateByFrequency(
        lastConductedDate,
        maintenanceTypeId,
        'TRACTOR'
      )
    );
  };

  const onLastDueOdometerChanged = (LastDueOdometer: number) => {
    setValue(
      'nextServiceDueOdometer',
      findNextServiceDueOdometerByFrequency(+LastDueOdometer, maintenanceTypeId)
    );
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Grid spacing={2} container>
        <Select
          control={control}
          name="maintenanceTypeId"
          label={t('maintenanceItem')}
          required
          options={alertOptions}
          fieldName="itemName"
          fieldValue="maintenanceTypeId"
          sizes={{ xs: 12 }}
          style={{ marginTop: 0 }}
          onChangeCallback={onMaintenanceItemChanged}
        />
        <FormDatePicker
          control={control}
          disabledText={false}
          name="lastConducted"
          label={t('lastDone')}
          sizes={{ xs: 6 }}
          required
          disableFuture
          onDateChange={onLastConductedDateChanged}
        />
        <TextField
          control={control}
          name="lastServiceDueOdometer"
          label={t('lastOdometer')}
          sizes={{ xs: 6 }}
          onChangeCallback={onLastDueOdometerChanged}
        />
        <FormDatePicker
          control={control}
          disabledText={false}
          name="nextDue"
          required
          label={t('nextDue')}
          sizes={{ xs: 6 }}
        />
        <TextField
          control={control}
          name="nextServiceDueOdometer"
          label={t('nextOdometer')}
          sizes={{ xs: 6 }}
        />
      </Grid>
    );
  }
};

AddNewAlertForm.defaultProps = {
  titleText: '',
};

export default AddNewAlertForm;
