import { Button, SxProps } from '@mui/material';

/* @TODO this file should be rewritten */
interface IProps {
  onUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accept: any;
  text: string;
  multiple?: boolean;
  sxProps?: SxProps;
}

const UploadButton = ({
  onUpload,
  accept,
  text,
  multiple = false,
  sxProps = {},
}: IProps) => {
  return (
    <Button
      id="browseFilesButtton"
      sx={{
        textTransform: 'none',
        color: 'primary.main',
        borderRadius: '10px',
        ...sxProps,
      }}
      size="medium"
      variant="outlined"
      component="label"
    >
      {text}
      <input
        hidden
        multiple={multiple}
        type="file"
        accept={accept}
        onChange={onUpload}
      />
    </Button>
  );
};

export default UploadButton;
