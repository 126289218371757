import { ComposedError, httpClient } from '../../../axios/axiosInstance';
import {
  MaintenanceHistory,
  MaintenanceHistoryListTotal,
} from '../../../models/DTOs/maintenance/maintenanceHistory/Model';
import {
  CreateMaintenanceHistoryRequest,
  DeleteBulkMaintenanceHistoryRequest,
  ExportMaintenanceHistoryFilterPayload,
  ExportMaintenanceHistoryFilterQueryParams,
  GetMaintHistoryListCalendarByEquipmentQueryParams,
  GetMaintenanceHistoryListCalendarQueryParams,
  MaintenanceHistoryListTotalRequest,
  PaginatedMaintenanceHistoryListRequest,
  UpdateMaintenanceHistoryRequest,
} from '../../../models/DTOs/maintenance/maintenanceHistory/Requests';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { PaginatedResult } from '../../../types';
import { docType } from '../../../utils/Doc';
import {
  IMaintenanceHistoryService,
  ServiceError,
  annotateNameAsync,
} from '../../interfaces';

const basePathApi = '/web/asset/api/v2/maintenance/history';
const resources = {
  list: `${basePathApi}/list`,
  total: `${basePathApi}/total`,
  listCalendar: `${basePathApi}/list/calendar`,
  create: `${basePathApi}/create`,
  update: basePathApi,
  bulkDelete: `${basePathApi}/delete/bulk`,
  exportToExcel: `${basePathApi}/maintenance/export-to-excel`,
};

export class MaintenanceHistoryService extends IMaintenanceHistoryService {
  @annotateNameAsync
  async getPaginatedList(
    requestData: PaginatedMaintenanceHistoryListRequest
  ): Promise<PaginatedResult<MaintenanceHistory> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        resources.list,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getMaintenanceHistoryListTotal(
    requestData: MaintenanceHistoryListTotalRequest
  ): Promise<number | ServiceError> {
    try {
      const response = await httpClient.get<MaintenanceHistoryListTotal>(
        resources.total,
        requestData,
        MaintenanceHistoryListTotal,
        undefined,
        true
      );
      if (response) {
        return response.totalAmount;
      }
      return 0;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getMaintenanceHistoryListCalendar(
    requestData: GetMaintenanceHistoryListCalendarQueryParams
  ): Promise<MaintenanceHistory[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        resources.listCalendar,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async getMaintHistoryListCalendarByEquipment(
    requestData: GetMaintHistoryListCalendarByEquipmentQueryParams
  ): Promise<MaintenanceHistory[] | ServiceError> {
    try {
      const response = await httpClient.get<MaintenanceHistory[]>(
        resources.listCalendar,
        requestData,
        MaintenanceHistory,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async create(
    requestData: CreateMaintenanceHistoryRequest
  ): Promise<MaintenanceHistory | ServiceError> {
    try {
      delete requestData['terminalName'];
      const response = await httpClient.postRaw(
        resources.create,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'addMaintenanceHistory',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'addMaintenanceHistory',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async update(
    requestData: UpdateMaintenanceHistoryRequest
  ): Promise<MaintenanceHistory | ServiceError> {
    try {
      delete requestData['terminalName'];
      const response = await httpClient.putRaw(
        resources.update,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateMaintenanceHistory',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'updateMaintenanceHistory',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async buckDelete(
    requestData: DeleteBulkMaintenanceHistoryRequest
  ): Promise<void | ServiceError> {
    try {
      await httpClient.delete(
        resources.bulkDelete,
        requestData,
        undefined,
        undefined
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteMaintenanceHistory',
      });
    } catch (error) {
      const composedError = error as ComposedError;
      if (
        this.getServiceError(composedError)?.error?.response?.data?.apierror
          ?.message != 'CLOSED_SETTLEMENT_EXISTS_FOR_LINKED_EXPENSE'
      ) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'deleteMaintenanceHistory',
        });
      }
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async exportToExcel(
    queryParams: ExportMaintenanceHistoryFilterQueryParams,
    payload: ExportMaintenanceHistoryFilterPayload
  ): Promise<docType | ServiceError> {
    try {
      delete payload['organizationId'];
      const response = await httpClient.putRaw(
        resources.exportToExcel,
        queryParams,
        payload
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
