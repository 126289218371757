import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { StopSolutionV3Prop } from '../../TripPlanV3/types';
import { TimeLineIconMapping } from '../timelineIconsMapping';
import { getDislayLocation } from './timelineV3.utils';
import { TimelineFormatter } from './DetailedDataSection';

export const TruckIconSection = ({
  // isActive,
  stopSolution,
}: {
  // isActive: boolean;
  stopSolution: StopSolutionV3Prop;
}): JSX.Element => {
  return TimeLineIconMapping.Tractor_02V2({
    isActive: true,
    toolTip: (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="tooltip">
          {getDislayLocation({
            withLocationName: false,
            location: stopSolution.location,
          })}
        </Typography>
        {stopSolution?.appointmentEndDate ? (
          <Typography variant="tooltip">
            Updated @{' '}
            {TimelineFormatter.getDisplayDriverLocationUpdateTime(
              stopSolution?.appointmentEndDate
            )}{' '}
            via ELD
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    ),
  });
};
