import { Box, Link, Stack, Typography } from '@mui/material';
import { styles } from '../../../components/Subscription/styles';
import { subscriptionService } from 'api';
import { subscriptionConfigConstant } from '../../../constants/subscriptionConstants';
interface AdditionalDetaisProps {
  [key: string]: any;
}
const laneRatePopupContent = (description: string) => (
  <Typography>
    {subscriptionConfigConstant.laneRatePopupContent}
    {description}.
  </Typography>
);

const ediPopupContent = (description: string) => (
  <Stack spacing={2}>
    <Typography>
      {subscriptionConfigConstant.ediPopupContent.description}
    </Typography>
    <Typography>{description}</Typography>
    <Typography>
      <Typography>
        {subscriptionConfigConstant.ediPopupContent.email}{' '}
        <Link href="#" color="inherit">
          {subscriptionConfigConstant.ediPopupContent.emailInfo}
        </Link>
        {''}
      </Typography>
    </Typography>
  </Stack>
);

const brokeragePopupContent = (
  <Stack spacing={2}>
    <Typography>
      {subscriptionConfigConstant.brokeragePopupContent.description}
    </Typography>
    <Typography>
      {subscriptionConfigConstant.brokeragePopupContent.description2}
    </Typography>
  </Stack>
);

export const ADDITIONAL_INFO_KEYS = {
  LANE_RATE: 'cargo_chief',
  LOAD_AI: 'load_ai',
  EDI: 'edi',
  BROKERAGE: 'brokerage',
};

export const ADDITIONAL_MODULE_CONTENT = (key: string, description: string) => {
  let content = <></>;
  switch (key) {
    // case ADDITIONAL_INFO_KEYS.LANE_RATE:
    //   content = laneRatePopupContent(description);
    //   break;

    case ADDITIONAL_INFO_KEYS.EDI:
      content = ediPopupContent(description);
      break;

    case ADDITIONAL_INFO_KEYS.BROKERAGE:
      content = brokeragePopupContent;
      break;
  }
  return content;
};
export const ADDITION_INFO_CONFIG: AdditionalDetaisProps = {
  [ADDITIONAL_INFO_KEYS.LANE_RATE]: {
    title: subscriptionConfigConstant.laneRateCardInfo.title,
    subTitle: subscriptionConfigConstant.laneRateCardInfo.subTitle,
    buttonText: subscriptionConfigConstant.laneRateCardInfo.buttonText,
    popupTitle: subscriptionConfigConstant.laneRateCardInfo.popupTitle,
    url: subscriptionConfigConstant.laneRateCardInfo.url,
  },
  [ADDITIONAL_INFO_KEYS.LOAD_AI]: {
    title: subscriptionConfigConstant.loadAiCardInfo.title,
    buttonText: subscriptionConfigConstant.loadAiCardInfo.buttonText,
    btnUrl: subscriptionConfigConstant.loadAiCardInfo.btnUrl,
  },
  [ADDITIONAL_INFO_KEYS.EDI]: {
    title: subscriptionConfigConstant.ediCardInfo.title,
    subTitle: subscriptionConfigConstant.ediCardInfo.subTitle,
    buttonText: subscriptionConfigConstant.ediCardInfo.buttonText,
    popupTitle: subscriptionConfigConstant.ediCardInfo.popupTitle,
  },
  [ADDITIONAL_INFO_KEYS.BROKERAGE]: {
    title: subscriptionConfigConstant.brokerageCradInfo.title,
    subTitle: ' ',
    buttonText: subscriptionConfigConstant.brokerageCradInfo.buttonText,
    popupTitle: subscriptionConfigConstant.brokerageCradInfo.popupTitle,
    url: subscriptionConfigConstant.brokerageCradInfo.url,
  },
};
export const PLAN_CARD_KEYS = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
  TRUCKS: 'truck',
};

export const SUBSCRIPTION_CARD_INFO = {
  TITLE: subscriptionConfigConstant.subscriptionCardInfo.title,
  CONTENT: (
    <Stack
      spacing={2}
      sx={{
        background: '#FAFAFA',
        padding: '5px',
        maxHeight: '482px',
        overflow: 'auto',
      }}
    >
      <Typography sx={{ ...styles.agreementHeadingStyle }}>
        {subscriptionConfigConstant.subscriptionCardInfo.subTitleLine}
      </Typography>
      <Typography sx={{ ...styles.agreementSubTitleStyle }}>
        {subscriptionConfigConstant.subscriptionCardInfo.firstConditionTitle}
      </Typography>
      <Typography sx={{ ...styles.agreementDetailsStyle }}>
        {
          subscriptionConfigConstant.subscriptionCardInfo
            .firstConditionDescription
        }
      </Typography>
      <Typography sx={{ ...styles.agreementSubTitleStyle }}>
        {subscriptionConfigConstant.subscriptionCardInfo.secondConditionTitle}
      </Typography>
      <Typography sx={{ ...styles.agreementDetailsStyle }}>
        {
          subscriptionConfigConstant.subscriptionCardInfo
            .secondConditionDescription
        }
      </Typography>
      <Typography sx={{ ...styles.agreementSubTitleStyle }}>
        {subscriptionConfigConstant.subscriptionCardInfo.thirdConditionTitle}
      </Typography>
      <Typography sx={{ ...styles.agreementDetailsStyle }}>
        {
          subscriptionConfigConstant.subscriptionCardInfo
            .thirdConditionDescription
        }
      </Typography>
      <Typography sx={{ ...styles.agreementSubTitleStyle }}>
        {subscriptionConfigConstant.subscriptionCardInfo.fourthConditionTitle}
      </Typography>
      <Typography sx={{ ...styles.agreementDetailsStyle }}>
        {
          subscriptionConfigConstant.subscriptionCardInfo
            .fourthConditionDescription
        }
      </Typography>
      <Typography sx={{ ...styles.agreementSubTitleStyle }}>
        {subscriptionConfigConstant.subscriptionCardInfo.fifthConditionTitle}
      </Typography>
      <Typography sx={{ ...styles.agreementDetailsStyle }}>
        {
          subscriptionConfigConstant.subscriptionCardInfo
            .fifthConditionDescription
        }
      </Typography>
      <Typography sx={{ ...styles.agreementDetailsStyle }}>
        {
          subscriptionConfigConstant.subscriptionCardInfo
            .fifthConditionDescription2
        }
      </Typography>{' '}
    </Stack>
  ),
  ACTION_BUTTON_TEXT:
    subscriptionConfigConstant.subscriptionCardInfo.actionBtnText,
};

const mothlyAndAnnualCardContent = (rate: string, isAnnualPlan?: boolean) => (
  <>
    <Box>
      {/* <Typography fontSize={'55px'} display={'flex'} alignItems={'flex-start'}><span style={{lineHeight:'27px', fontSize:'27px'}}>$</span><span>{rate}<span style={{fontSize:'29px'}}>/driver</span></span></Typography> */}
      <Typography fontSize={'55px'} sx={{ wordWrap: 'break-word' }}>
        <sup style={{ fontSize: '29px', verticalAlign: 'revert' }}>
          {subscriptionConfigConstant.mothlyAndAnnualCardContent.dollarSign}
        </sup>
        {rate}
        <span style={{ fontSize: '29px', color: '#00112299' }}>
          {subscriptionConfigConstant.mothlyAndAnnualCardContent.driverText}
        </span>
      </Typography>
      {/* <Stack fontSize={'55px'} direction="row"><Typography >$</Typography>{rate}<span style={{fontSize:'29px'}}>/driver</span></Stack> */}

      <Typography sx={{ fontSize: '20px', color: '#00112299' }}>
        {subscriptionConfigConstant.mothlyAndAnnualCardContent.perMonthText}
      </Typography>
    </Box>
    <Box>
      <Typography>
        {subscriptionConfigConstant.mothlyAndAnnualCardContent.billPaymentText}
      </Typography>
      {isAnnualPlan && (
        <Typography>
          {subscriptionConfigConstant.mothlyAndAnnualCardContent.agreementText}
        </Typography>
      )}
    </Box>
  </>
);

export const PLAN_CARD_CONTENT = (key: string, data: any) => {
  let content = <></>;
  switch (key) {
    case PLAN_CARD_KEYS.MONTHLY:
      content = mothlyAndAnnualCardContent(data);
      break;
    case PLAN_CARD_KEYS.ANNUAL:
      content = mothlyAndAnnualCardContent(data, true);
      break;
    case PLAN_CARD_KEYS.TRUCKS:
      content = (
        <Box>
          {
            subscriptionConfigConstant.mothlyAndAnnualCardContent
              .planCardConetent
          }
        </Box>
      );
      break;
  }
  return content;
};
