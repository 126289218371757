import { EditOutlined } from '@mui/icons-material';
import {
  AutocompleteRenderOptionState,
  Box,
  Button,
  ClickAwayListener,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { MouseEventHandler, useCallback, useState } from 'react';
import OptymLogo from '../../../../../../_assets/static/svgs/OptymLogo';
import { driverService, loadService } from '../../../../../../api';
import { ELoadStatus } from '../../../../../../common/LoadTabPanel/constants/constants';
import { useLoadPermission } from '../../../../../../common/hooks/useLoadPermission';
import { useLoadAiPermission } from '../../../../../../common/hooks/useLoadaiPermission';
import { LOADAI_ELEMENTS } from '../../../../../../constants/elementIds';
import { TripGroupDetail } from '../../../../../../models';
import { useStores } from '../../../../../../store/root.context';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';
import SingleAutocomplete from '../../../../../../ui-kit/components/SingleAutocomplete';
import { EUnassignedTripMenuAction } from '../../../../../trips/constants/types';
import { CustomOption } from './CustomOption';
import {
  CLASS_NAMES,
  getStyledManualDriverSelection,
  getThemeManualDriverSelection,
} from './styles';

export const ManualDriverSelection = observer(
  ({ params }: { params: GridRenderCellParams<TripGroupDetail> }) => {
    const theme = useTheme();
    const overrideTheme = getThemeManualDriverSelection(theme);
    const {
      dispatch2TripsStore: {
        changeStatusManualDriverSelection,
        setEditingId,
        getEditingId,
      },
      evaluationStore: {
        startEvaluation,
        runEvaluation,
        setSource,
        setTripToAssignDetails,
      },
      dispatch2GlobalStore: { gapForTripAssignment, startBulkOptymization },
      bulkOptymizationStore: { setOptimizationRequestResources },
    } = useStores();
    const { hasEngineEvaluationPermission, hasBulkOptimizationPermission } =
      useLoadAiPermission();
    const { hasLoadCRUDPermission } = useLoadPermission();
    const styles = getStyledManualDriverSelection();
    const open = getEditingId === params.row.id;
    const onOpen = () => {
      setEditingId(params.row.id);
    };
    const [apptDateMissingWarning, setIsApptDateMissingWarning] =
      useState<boolean>(false);
    const onClose = () => {
      setEditingId(null);
    };
    const onSubmitHandler = async (request: {
      event: MouseEventHandler;
      toStatus: EUnassignedTripMenuAction;
      option: any;
    }) => {
      const isValidationFailed = await loadService.validateLoadStopsLocations(
        params?.row
      );
      if (isValidationFailed) return setIsApptDateMissingWarning(true);

      changeStatusManualDriverSelection({
        loadAssignmentStatus:
          request.toStatus === EUnassignedTripMenuAction.MARK_AS_PLANNED
            ? ELoadStatus.ASSIGNMENT_PLANNED
            : ELoadStatus.OFFERED_TO_DRIVER,
        terminalId: params.row?.terminal?.id,
        loadId: params.row.id,
        groupId: request.option.id,
      });
    };

    const handleEvaluation = (options: any, event: React.MouseEvent) => {
      const currentTargetRect = event.currentTarget.getBoundingClientRect();
      const event_offsetX = event.pageX - currentTargetRect.left;
      setSource('DISPATCH');
      setTripToAssignDetails({
        terminalId: params.row?.terminal?.id,
        loadId: params.row.id,
      });
      startEvaluation({
        driverGroupId: options?.id,
        tripIds: [`${params.row?.id}`],
        gapId: gapForTripAssignment?.nextLoadId,
        isGapSelected: false,
        resourceType: 'TRIP',
        targetPosition: {
          left: event.clientX - event_offsetX - 10,
          top: event.clientY,
        },
      });
      setOptimizationRequestResources({
        driverIds: [],
        tripIds: [params.row.id],
      });
    };

    const handleRecommendation = () => {
      setOptimizationRequestResources({
        driverIds: [],
        tripIds: [params.row.id],
      });
      startBulkOptymization();
    };

    const customRenderOption = (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: any,
      state: AutocompleteRenderOptionState
    ) => {
      return (
        <CustomOption
          props={{ ...props }}
          option={{ ...option }}
          state={{ ...state }}
          onSubmitHandler={onSubmitHandler}
          fieldName="displayName"
          styles={styles}
          onEvaluation={handleEvaluation}
          showEvaluateAction={hasEngineEvaluationPermission}
          showRecommendationAction={hasBulkOptimizationPermission}
          showStatusUpdateAction={hasLoadCRUDPermission}
          onRecommendation={handleRecommendation}
          disableAllActions={params?.row?.onHold}
        />
      );
    };

    const handleClickListener = () => {
      if (runEvaluation) return;
      onClose?.();
    };

    const AutoCompletePaperTemplate = useCallback((props) => {
      return (
        <Paper {...props}>
          {props?.children}
          {hasBulkOptimizationPermission && (
            <Box
              padding="12px 8px"
              sx={{ borderTop: '1px solid rgba(0, 17, 34, 0.23)' }}
            >
              <Button
                disabled={params?.row?.onHold}
                id={LOADAI_ELEMENTS.DRIVER_SELECTION_RECOMMEND_DRIVERS}
                variant="outlined"
                startIcon={<OptymLogo />}
                fullWidth
                sx={{
                  fontSize: '13px',
                  height: '28px',
                  textTransform: 'capitalize',
                }}
                onClick={handleRecommendation}
              >
                Recommend Driver
              </Button>
            </Box>
          )}
        </Paper>
      );
    }, []);

    return (
      <>
        <ClickAwayListener onClickAway={handleClickListener}>
          <Box sx={styles.root}>
            <Stack
              component={'div'}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              sx={{
                ...styles.cell,
                ...(open && {
                  display: 'none',
                }),
              }}
              onClick={(event) => {
                onOpen();
                event.stopPropagation();
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'text.disabled',
                }}
              >
                Unassigned
              </Typography>
              <Box className={CLASS_NAMES.editIcon}>
                <EditOutlined sx={styles.editIcon} />
              </Box>
            </Stack>
            {open && (
              <Box
                sx={styles.SingleAutocomplete}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <ThemeProvider theme={overrideTheme}>
                  <SingleAutocomplete
                    openAsDefault={true}
                    disableAutoClose={true}
                    onCloseCb={onClose}
                    id="ManualDriverSelection-driver"
                    variant={'outlined'}
                    name={'driver'}
                    fieldName="displayName"
                    label="Select Driver"
                    size={'small'}
                    value={null}
                    forcePopupIcon={false}
                    customRenderOption={customRenderOption}
                    customInputProps={
                      styles.SACustomInputProps as CSSProperties
                    }
                    // PaperComponent={DriverSelectionTemplate()}
                    PaperComponent={AutoCompletePaperTemplate}
                    getOptions={(
                      searchText: string | undefined,
                      pageNumber: number
                    ): Promise<any> => {
                      return driverService.getDriverList(
                        searchText,
                        pageNumber,
                        params.row?.terminal?.id
                          ? [params.row?.terminal?.id]
                          : undefined,
                        true
                      );
                    }}
                  />
                </ThemeProvider>
              </Box>
            )}
          </Box>
        </ClickAwayListener>
        <DeletePopup
          open={apptDateMissingWarning}
          title={t('MissingApptDatesTitle')}
          body={t('MissingApptDatesBody')}
          subtitle={t('MissingApptDatesSubtitle')}
          onClose={() => setIsApptDateMissingWarning(false)}
          cancelText={'Ok'}
          width={'370px'}
        />
      </>
    );
  }
);
