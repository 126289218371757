import { DISPATCH_ENDPOINTS } from '../config';
import { AxiosConfigType, http } from '../../../axios/axios.service';

export const getDriverScheduleRequest = async (
  payload?: Partial<AxiosConfigType>
) => {
  return await http.put({
    apiEndpoint: DISPATCH_ENDPOINTS.getDriverSchedule,
    ...payload,
  });
};

export const getDriverScheduleWarnings = async (
  payload?: Partial<AxiosConfigType>
) => {
  return await http.put({
    apiEndpoint: DISPATCH_ENDPOINTS.getDriverSchedulerWarnings,
    ...payload,
  });
};
