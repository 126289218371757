import { FC } from 'react';
import { tripService } from '../../../../../../api';
import {
  AddressDTO,
  CheckOrAssignAssetResponse,
} from '../../../../../../models';
import { CheckOrAssignAssetRequest } from '../../../../../../models/DTOs/Trip/Requests';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';
import FormDialog from '../../../../../Ui/FormDialog';
import { businessAndAddress2Location } from '../../utils';
import { Business } from '../RelayStopPopup/RelayStopForm';
import AssetAssignmentPopupForm from './AssetAssignmentPopupForm';

export type FormAsset = {
  id: number;
  name: string;
} | null;

export interface AssetAssignmentFormData {
  business: Business | null;
  address: AddressDTO | null;
  userChoice: 'DRIVER' | 'LOAD';
  assetType: 'TRACTOR' | 'TRAILER';
  driverAsset?: FormAsset;
  tractor?: FormAsset;
  trailer?: FormAsset;
  tripId: string;
}

export interface AssetAssignmentPopupProps {
  onClose: () => void;
  data: AssetAssignmentFormData | null;
  assetAssignment: CheckOrAssignAssetResponse;
  onActionCompleted?: () => void;
}

const AssetAssignmentPopup: FC<AssetAssignmentPopupProps> = ({
  onClose,
  data,
  assetAssignment,
  onActionCompleted,
}) => {
  const contentRenderer = () => {
    return <AssetAssignmentPopupForm />;
  };

  const onAction = async (data: AssetAssignmentFormData) => {
    const location = businessAndAddress2Location({
      business: data.business,
      address: data.address!,
    });
    await tripService.checkOrAssignAsset(
      new CheckOrAssignAssetRequest({
        assetType: data.assetType,
        tripId: data.tripId,
        userChoice: data.userChoice,
        assetDropLocation: location,
      })
    );
    onActionCompleted?.();
  };

  return (
    <>
      {assetAssignment.conflictType === 'ASSET_OCCUPIED' && (
        <DeletePopup
          open
          body={assetAssignment.message}
          title=""
          onClose={onActionCompleted}
          cancelText="Ok"
        />
      )}
      {assetAssignment.conflictType === 'ASSET_MISMATCH' && (
        <FormDialog
          width={514}
          data={data}
          titleText={`${
            data?.assetType === 'TRAILER' ? 'Trailer' : 'Tractor'
          } Pick & Drop`}
          actionLabel="Save"
          open
          onAction={onAction}
          handleClose={onClose}
          contentRenderer={contentRenderer}
        />
      )}
    </>
  );
};

export default AssetAssignmentPopup;
