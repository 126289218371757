import { t } from 'i18next';
import { EDITendersConstants } from '../../../locales/en/allLoads/loadtenders';

export const EDITendersFieldsConfig: {
  [key: string]: {
    fieldName: string;
    label: string;
    fieldFilterName?: string;
    fieldFilterLabel?: string;
  };
} = {
  customer: {
    fieldName: 'customer',
    fieldFilterName: 'customer',
    label: EDITendersConstants.customer,
  },
  respondBy: {
    fieldName: 'respondBy',
    label: EDITendersConstants.respondBy,
    fieldFilterName: 'respondBy',
  },
  status: {
    fieldName: 'action',
    label: EDITendersConstants.status,
    fieldFilterName: 'Status',
  },
  shipmentId: {
    fieldName: 'shipmentId',
    label: EDITendersConstants.shipmentID,
    fieldFilterName: 'shipmentId',
  },
  acceptDateandTime: {
    fieldName: 'actionDateTime',
    label: EDITendersConstants.acceptDateandTime,
    fieldFilterName: 'acceptDateandTime',
  },
  pickupDateandTime: {
    fieldName: 'stops',
    label: EDITendersConstants.pickupDateandTime,
    fieldFilterName: 'pickupDateandTime',
  },
  pickupCity: {
    fieldName: 'pickupCity',
    label: EDITendersConstants.pickupCity,
    fieldFilterName: 'pickupCity',
  },
  pickupState: {
    fieldName: 'pickupState',
    label: EDITendersConstants.pickupState,
    fieldFilterName: 'pickupState',
  },
  pickupZipcode: {
    fieldName: 'pickupZipcode',
    label: EDITendersConstants.pickupZipcode,
    fieldFilterName: 'pickupZipcode',
  },
  totalStops: {
    fieldName: 'totalStops',
    label: EDITendersConstants.totalStops,
    fieldFilterName: 'totalStops',
  },
  dropoffDateandTime: {
    fieldName: 'dropoffDateandTime',
    label: EDITendersConstants.dropoffDateandTime,
    fieldFilterName: 'dropoffDateandTime',
  },
  dropoffCity: {
    fieldName: 'dropoffCity',
    label: EDITendersConstants.dropoffCity,
    fieldFilterName: 'dropoffCity',
  },
  dropoffState: {
    fieldName: 'dropoffState',
    label: EDITendersConstants.dropoffState,
    fieldFilterName: 'dropoffState',
  },
  dropoffZipcode: {
    fieldName: 'dropoffZipcode',
    label: EDITendersConstants.dropoffZipcode,
    fieldFilterName: 'dropoffZipcode',
  },
  loadedMiles: {
    fieldName: 'loadedMiles',
    label: EDITendersConstants.loadedMiles,
    fieldFilterName: 'loadedMiles',
  },
  marketRate: {
    fieldName: 'marketRate',
    label: EDITendersConstants.marketRate,
    fieldFilterName: 'marketRate',
  },
  loadID: {
    fieldName: 'loadId',
    label: EDITendersConstants.loadID,
    fieldFilterName: 'loadID',
  },
  declinedDateTime: {
    fieldName: 'actionDateTime',
    label: EDITendersConstants.declinedDateTime,
    fieldFilterName: 'declinedDateTime',
  },
  cancelledDateTime: {
    fieldName: 'actionDateTime',
    label: EDITendersConstants.cancelledDateTime,
    fieldFilterName: 'cancelledDateTime',
  },
  pickup: {
    fieldName: 'pickup',
    fieldFilterName: 'pickup',
    label: EDITendersConstants.pickup,
  },
  dropoff: {
    fieldName: 'dropoff',
    fieldFilterName: 'dropoff',
    label: EDITendersConstants.dropoff,
  },
  actions: {
    fieldName: 'actions',
    label: EDITendersConstants.actions,
  },
  loadStatus: {
    fieldName: 'loadStatus',
    fieldFilterName: 'loadStatus',
    label: EDITendersConstants.loadStatus,
  },
  source: {
    fieldName: 'source',
    fieldFilterName: 'source',
    label: EDITendersConstants.source,
  },
  offer: {
    fieldName: 'rate',
    fieldFilterName: 'rate',
    label: EDITendersConstants.offer,
  },
};
