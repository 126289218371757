import { Box, Divider, Popover, Typography } from '@mui/material';
import React from 'react';
import EvaluateTripsDetail from './components/EvaluateTripsDetail';
import EvaluateKpis from './components/EvaluateKpis';
import RatingSection from './components/RatingSection';
import { useStores } from '../../store/root.context';
import { observer } from 'mobx-react';
import ActionSection from './components/ActionSection';
import EvaluateSkeleton from './components/EvaluateLoader';
import OptimizationActions from './components/OptimizationActions';
import { IEvaluationTrips, KpiPropsType } from './types/evaluation.type';
import { ContainerStyles } from './styles/evaluation.style';
import CriticalIssue from './components/CriticalIssue';
import {
  isEvaluateActionsAllowed,
  isResourceTypeUnassignedDriver,
  isResourceTypeUnassignedTrip,
} from '../../views/optymization/services/recommendTrips.service';

interface IEvaluation {}

const Evaluation: React.FC<IEvaluation> = (props) => {
  const {
    evaluationStore: {
      targetPosition,
      stopEvaluation,
      evaluationResult,
      isLoading,
      source,
      runEvaluation,
      driverDetailsToEvaluate,
      isTypeGlobalEvaluation,
    },
  } = useStores();
  const renderContentByStatus = () => {
    if (isLoading) return <EvaluateSkeleton />;
    if (evaluationResult?.isCriticalIssue)
      return (
        <CriticalIssue
          title={evaluationResult?.driverName}
          content={evaluationResult?.issuesKPI?.[0]?.description}
        />
      );
    return (
      <>
        <RatingSection
          driverName={evaluationResult?.driverName}
          rating={evaluationResult?.evaluationRating}
          issuesKpiLength={evaluationResult?.issuesKPI?.length}
        />
        <EvaluateTripsDetail trips={evaluationResult?.customTripInfoList!} />

        <Divider sx={{ background: '#E5E5E5', margin: '8px 0' }} />

        <EvaluateKpis kpis={evaluationResult?.evaluationKPI!} />

        <EvaluateKpis
          type="ISSUES"
          kpis={evaluationResult?.issuesKPI!}
          hideIssuesTitle={isTypeGlobalEvaluation}
        />
      </>
    );
  };

  const renderActionTemplate = () => {
    if (
      isEvaluateActionsAllowed(driverDetailsToEvaluate!) ||
      isTypeGlobalEvaluation
    )
      return <></>;
    if (source === 'DISPATCH') return <ActionSection />;
    return <OptimizationActions />;
  };
  if (!runEvaluation) return <></>;
  return (
    <Popover
      key={'evaluation-popup'}
      anchorReference="anchorPosition"
      anchorPosition={targetPosition}
      open={true}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={stopEvaluation}
      sx={{
        '.MuiPaper-root': {
          borderRadius: '8px',
          border: '1px solid  rgba(43, 100, 203, 0.50)',
        },
      }}
    >
      <Box sx={ContainerStyles}>
        {renderContentByStatus()}
        {!isLoading && renderActionTemplate()}
      </Box>
    </Popover>
  );
};

export default observer(Evaluation);
