import { Type } from 'class-transformer';
import { SafetyAlertModel } from '../../../subPages/users/components/SafetyAlerts/models';
import { CommodityType, QueryParams, SortResponse } from '../commonMixed';
export class DocumentSummaryCustomer {
  id!: number;
  fullName!: string;
  countryCode!: string;
  description!: string;
  phone!: string;
  email!: string;
  extension!: string;
  isPrimary!: boolean;
}

export class DocumentSummaryDTO {
  id!: string;
  organizationId!: number;
  name!: string;
  type!: string;
  address!: string;
  streetAddress!: string;
  streetAddress2!: string;
  fullAddress!: string;
  city!: string;
  state!: string;
  zip!: string;
  status!: boolean;
  notes!: string;
  mc!: string;
  customerPreference!: string;
  useFactoring!: boolean;
  factoringId!: string;
  @Type(() => DocumentSummaryCustomer)
  contacts!: DocumentSummaryCustomer[];
  @Type(() => CommodityType)
  commodityTypes!: CommodityType[];
}

export class PaginatedDocumentsList {
  @Type(() => DocumentSummaryDTO)
  content!: DocumentSummaryDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}
export class EntityTypes {
  @Type(() => DocumentSummaryDTO)
  content!: DocumentSummaryDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class DocumentsTypeList {
  @Type(() => DocumentSummaryDTO)
  content!: DocumentSummaryDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export type documentListRequestType = {};

export type documentTypeRequestType = {};

export type entityTypesRequestType = {
  entityType: string;
  terminalIds: string[];
};

export enum EDocumentTypes {
  TRACTOR_REGISTRATION = 'TRACTOR_REGISTRATION',
  TRACTOR_INSURANCE = 'TRACTOR_INSURANCE',
  TRAILER_REGISTRATION = 'TRAILER_REGISTRATION',
  TRAILER_INSURANCE = 'TRAILER_INSURANCE',
}

export type documentAssigmentsRequestType = {
  objectTypesAndIds?: string | null;
  documentTypeIds?: string[];
  terminalIds?: string[];
  permissionType?: string;
  endDate?: any;
  startDate?: any;
  pageNumber?: number;
  pageSize?: number;
};

export type deletedDocumentRequestType = {
  documentIds: string[];
};

export type createDocumentRequestType = {
  ownerId: string;
};

export type emailSendingRequestType = {
  emailSendingRequestDTO: any;
  html: boolean;
};

export type downloadDocumentRequestType = {
  id: string[];
};

export class CreateDocument {
  fileName!: string;
  id!: string;
  uploadDate!: string;
  documentId!: string;
  documentTypeId!: string;
  documentType!: string;
  attachedEntities!: AttachedEntitiesType;
  ownerName!: string;
  notes!: string;
  terminalId!: string | null;
  permission!: string;
}

export class EditDocument {
  fileName!: string;
  description!: string;
}

//@Anna dublicate 2 times
type DocAttachedEntitiesProperties = {
  id: string;
  title: string;
};

type AttachedEntities = {
  properties: Array<DocAttachedEntitiesProperties>;
  type: string;
};

export class CreateFileDTO {
  contentType!: string;
  documentType!: null;
  fileContent!: null;
  fileName!: string;
  fileSize!: number;
  id!: string;
  organizationId!: number;
  ownerId!: number;
  terminalId!: string | null;
  uploadDate!: string;
}

export type CreateDocumentRequestType = {
  attachedEntities: AttachedEntities;
  description: string;
  documentId: string;
  documentType: string;
  documentTypeId: string;
  fileName: string;
  fileSize: number;
  id: null;
  ownerId: number;
  ownerName: string;
  permission: string;
  uploadDate: Date;
};

export type EditDocumentRequestType = {
  attachedEntities?: AttachedEntities;
  description?: string;
  documentId?: string;
  documentType?: string;
  documentTypeId?: string;
  fileName?: string;
  terminalId?: string;
  associatedWith?: string;
  id: string;
  fieldsToBeSetNull?: string[];
};

export class DocumentSummaryResponse {
  contentType?: string;
  documentType?: string;
  fileContent?: string;
  fileName?: string;
  fileSize?: number;
  id!: string;
  organizationId!: number;
  ownerId!: number;
  terminalId?: string;
  uploadDate?: string;
}

export type AssignDocumentResponseType = {
  contentType?: string;
  documentType?: string;
  fileContent?: string;
  fileName?: string;
  fileSize?: number;
  documentId: string;
};

export type AttachedEntitiesTypeType =
  | 'TRACTOR_PREVENTIVE_MAINTENANCE'
  | 'MAINTENANCE_HISTORY'
  | 'LOAD_NOTE'
  | 'TRACTOR_NOTE'
  | 'DRIVER_NOTE'
  | 'TRAILER_NOTE'
  | 'EXPENSE'
  | 'DRIVER'
  | 'TRACTOR'
  | 'TRAILER'
  | 'SETTLEMENT'
  | 'CARRIER_INSURANCE'
  | 'RECEIPT'
  | 'PAYMENT';

export type DocumentType =
  | 'RECEIPT'
  | 'MAINTENANCE_HISTORY'
  | 'DRIVER'
  | 'COMMERCIAL_DRIVER_LICENSE'
  | `TRACTOR_INSURANCE`
  | `TRACTOR_REGISTRATION`
  | `TRAILER_INSURANCE`
  | `TRAILER_REGISTRATION`
  | 'SETTLEMENT'
  | 'CARRIER_INSURANCE'
  | 'PAYMENT'
  | 'CUSTOMER_CONTRACT';

type PermissionType = string | null;

type AttachedEntitiesType = {
  properties: { id: string; title?: string }[];
  type: AttachedEntitiesTypeType;
};

export type AssignDocumentRequestType = {
  attachedEntities: AttachedEntitiesType[];
  description?: string;
  ownerName?: string;
  permission?: PermissionType;
  organizationId?: number;
  documentType?: DocumentType;
  documentId: string;
  fileName?: string;
  fileSize?: number;
  uploadDate?: string;
  ownerId: number;
  documentTypeId: string;
  terminalId?: string;
};

export class CreateLatestCertificationRequest extends QueryParams {
  certification!: SafetyAlertModel;
}

export class CreateLatestDriverLicenseRequest extends QueryParams {
  driverLicense: any;
}

export class AssignDocumentRequest extends QueryParams {
  attachedEntities!: AttachedEntitiesType[];
  description?: string;
  ownerName?: string;
  permission?: PermissionType;
  documentType?: DocumentType;
  documentId!: string;
  fileName?: string;
  fileSize?: number;
  uploadDate?: string;
  ownerId!: number;
  documentTypeId!: string;
  terminalId?: string;

  constructor(dto: AssignDocumentRequestType) {
    super();
    this.attachedEntities = dto.attachedEntities;
    this.description = dto.description;
    this.ownerName = dto.ownerName;
    this.permission = dto.permission;
    this.documentType = dto.documentType;
    this.documentId = dto.documentId;
    this.fileName = dto.fileName;
    this.fileSize = dto.fileSize;
    this.uploadDate = dto.uploadDate;
    this.ownerId = dto.ownerId;
    this.documentTypeId = dto.documentTypeId;
    this.terminalId = dto.terminalId;
  }
}

export type EmailSendingRequestDTOType = {
  to: string[];
  cc: string[];
  subject: string;
  message: string;
  documentList: string[];
};
