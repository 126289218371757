import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { t } from 'i18next';

interface BaseType {
  id: null;
  name: string;
}

export default function Asynchronous<OptionType extends BaseType>({
  getOptions,
  onSelect,
  label,
}: {
  getOptions: () => Promise<OptionType[] | undefined>;
  onSelect: (data: any) => void;
  label: string;
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly OptionType[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await getOptions(); // For demo purposes.

      if (active && res) {
        res.push({ id: null, name: t('createView') } as OptionType);
        setOptions([...res]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const currentTheme = useTheme();

  const theme = createTheme(currentTheme, {
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            //
          },
          inputRoot: {
            borderRadius: '10.4294px',
            border: '0.744959px solid rgba(0, 17, 34, 0.23)',
            padding: '0px 8.93951px',
            width: '251.8px',
            height: '41.84px',
          },
          popupIndicator: {
            color: 'rgba(0, 0, 0, 0.54)',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        id="asynchronous-demo"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(_, data) => data && onSelect(data.id)}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </ThemeProvider>
  );
}
