/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ExportExcelQueryExcel } from '../../models';
import {
  TrendAnalysisReport,
  TrendAnalysisRequest,
} from '../../models/DTOs/TrendAnalysis/TrendAnalysis';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class ITrendAnalysisService extends Service {
  abstract getTrendAnalysis(
    requestData: TrendAnalysisRequest
  ): Promise<TrendAnalysisReport | ServiceError>;

  abstract exportExcel(
    requestData: TrendAnalysisRequest
  ): Promise<ExportExcelQueryExcel>;
}
