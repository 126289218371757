import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FormDatePicker from '../../../../../common/Ui/FormDatePicker/FormDatePicker';
import { useFormContext } from 'react-hook-form';
import TextField from '../../../../../common/Ui/TextField/TextField';
import Select from '../../../../../common/Ui/Select';
import { getValidToDateByFrequency } from '../utils';
import { LineItem, SafetyAlertModel } from '../models';
import { CheckedIcon } from '../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import UploadFileDialog from '../../../../../views/reports/documents/UploadFileDialog';
import AlertDocumentForm from './AlertDocumentForm';
import SafetyAlertActions from '../SafetyAlertActions';
import { safetyAlertValidationSchema } from '../constants';
import { getAccountTypeWithRoleCode } from '../../../../../utils';
import { t } from 'i18next';

export default function AlertLineItem({
  documentTypeOptions,
  nameStart,
  documentLineItemsMap = {},
  documentLineItem,
  driverId,
  driverName,
  alert,
  index,
  inactive,
  onMarkDoneCompleteCallback,
}: {
  documentTypeOptions: LineItem[];
  nameStart: string;
  documentLineItemsMap?: { [key: string]: LineItem };
  documentLineItem?: LineItem;
  driverId?: string;
  driverName?: string;
  alert?: SafetyAlertModel;
  index?: number;
  inactive?: boolean;
  onMarkDoneCompleteCallback?: (
    result: any,
    formData?: { validFrom: string; validTo: string; notes: string }
  ) => void;
}) {
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const {
    control,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useFormContext();

  const [currentLineItem, setCurrentLineItem] = useState<LineItem>();

  const [validFrom] = watch([`${nameStart}validFrom`]);

  const handleValidFromChange = (date: string) => {
    const currentDocumentLineItem = documentLineItem || currentLineItem;
    if (currentDocumentLineItem && date) {
      const generatedValidTo = getValidToDateByFrequency(
        date,
        currentDocumentLineItem
      );
      setValue(`${nameStart}validTo`, generatedValidTo);
    }
  };

  const handleDocumentTypeChange = (id: string) => {
    const documentLineItem = documentLineItemsMap[id];
    setCurrentLineItem(documentLineItem);
    setValue(`${nameStart}documentType`, documentLineItem.itemCode);
    if (validFrom && documentLineItem) {
      const generatedValidTo = getValidToDateByFrequency(
        validFrom,
        documentLineItem
      );
      setValue(`${nameStart}validTo`, generatedValidTo);
    }
  };

  const onMarkDoneComplete = async (data: {
    validFrom: string;
    validTo: string;
    notes: string;
  }) => {
    setDocumentDialogOpen(false);
    const result = await SafetyAlertActions.createLatestCertification(
      data,
      alert!
    );
    if (result) {
      setValue(`${nameStart}validFrom`, data.validFrom);
      setValue(`${nameStart}validTo`, data.validTo);
      setValue(`${nameStart}notes`, data.notes);
      setValue(
        `${nameStart}safetyIssueTypeWithDaysAndMiles`,
        result.safetyIssueTypeWithDaysAndMiles
      );
      onMarkDoneCompleteCallback?.(result, data);
    }
  };

  const isItemDirty =
    typeof index === 'number' && dirtyFields?.alertsList?.[index];

  const noPermissionForAddRemoveEditUser = getAccountTypeWithRoleCode([
    'AC',
    'DR',
    'SP',
  ]);

  return (
    <>
      {documentDialogOpen && alert && (
        <UploadFileDialog
          open={documentDialogOpen}
          onClose={() => setDocumentDialogOpen(false)}
          onFormSubmit={onMarkDoneComplete}
          formDefaultValues={{
            documentType: null,
            description: '',
            validFrom: '',
            validTo: '',
            permission: false,
            entityTypes: { key: 'DRIVER', value: 'Driver' },
            entities: { id: driverId, name: driverName },
          }}
          renderContent={() => (
            <AlertDocumentForm
              alert={alert}
              documentLineItem={documentLineItem!}
            />
          )}
          validationSchema={safetyAlertValidationSchema}
        />
      )}
      <Grid spacing={2} container>
        {!nameStart && (
          <Select
            disabled={noPermissionForAddRemoveEditUser || inactive}
            control={control}
            name={`${nameStart}documentTypeId`}
            label="Document Type"
            options={documentTypeOptions}
            fieldName="itemName"
            fieldValue="id"
            sizes={{ xs: 12 }}
            // style={{ marginTop: 16 }}
            onChangeCallback={(id: string) => handleDocumentTypeChange(id)}
            required
          />
        )}
        <FormDatePicker
          disabled={noPermissionForAddRemoveEditUser || inactive}
          control={control}
          name={`${nameStart}validFrom`}
          label={'Valid From'}
          sizes={{ xs: 6 }}
          disableFuture
          onDateChange={(date: string) => handleValidFromChange(date)}
          required
        />
        <FormDatePicker
          disabled={noPermissionForAddRemoveEditUser || inactive}
          control={control}
          name={`${nameStart}validTo`}
          label={'Valid Until'}
          sizes={{ xs: 6 }}
          required
        />
        <TextField
          disabled={noPermissionForAddRemoveEditUser || inactive}
          control={control}
          name={`${nameStart}notes`}
          label={'Notes'}
        />
        {!!(driverId && !isItemDirty) && (
          <Grid
            item
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            xs={12}
          >
            <ButtonImproved
              disabled={noPermissionForAddRemoveEditUser || inactive}
              startIcon={<CheckedIcon color={'white'} opacity={'1'} />}
              label={t('markDone')}
              variant="contained"
              onClick={() => setDocumentDialogOpen(true)}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
