import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';
import {
  ExpenseCategory,
  OwnerOperatorPaymentRecipient,
  PaymentType,
} from '../../../models';
import { GetFinancialItemRequest } from '../../../models/DTOs/FinanceLoadService';
import { EquipmentType } from '../../../views/maintenanceRecords/constants/constants';
import TractorPaymentTermForm from '../../equipment/TractorPaymentTerm/TractorPaymentTermForm';
import {
  getAllPaymentTypes,
  getExpenseCategories,
} from '../../users/components/PaymentTermForm/utils';
export interface ITractorPaymentDataProps {
  equipmentType: EquipmentType;
  equipmentName: string;
  id: number;
  terminalName: string;
  terminalId: string;
  status: string;
  payToDetails?: OwnerOperatorPaymentRecipient;
}

export const WrapperTractorPayment: FC<{ disableAllFields?: boolean }> = ({
  disableAllFields,
}) => {
  const { getValues } = useFormContext();
  const data = getValues();
  const defaultMaintenanceRecord: ITractorPaymentDataProps = {
    equipmentType: EquipmentType.Tractor,
    equipmentName: data?.tractorName || data.id,
    id: +data.id,
    terminalName: data.terminal?.companyName,
    terminalId: data.terminalId || data.terminal?.id,
    status: data.status,
  };

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [allPaymentTypes, setAllPaymentTypes] = useState<PaymentType[]>([]);
  const [expenseCategories, setExpenseCategories] = useState<ExpenseCategory[]>(
    []
  );

  const fetchDependencydata = async (): Promise<void> => {
    const requestData = new GetFinancialItemRequest(true);
    const _allPaymentTypes: PaymentType[] = await getAllPaymentTypes(
      requestData
    );
    setAllPaymentTypes(_allPaymentTypes);

    const _expenseCategories: ExpenseCategory[] = await getExpenseCategories();
    setExpenseCategories(_expenseCategories);

    setIsLoaded(true);
  };

  useEffect(() => {
    fetchDependencydata();
  }, []);
  type permissionType = {
    add?: string;
    remove?: string;
    view?: string;
    edit?: string;
  };
  const equipmentPaymentTermPermission: permissionType = {
    add: AXELE_PERMISSION_TYPE?.EQUIPMENT_PAYMENT_TERM_ADD,
    remove: AXELE_PERMISSION_TYPE?.EQUIPMENT_PAYMENT_TERM_REMOVE,
    view: AXELE_PERMISSION_TYPE?.EQUIPMENT_PAYMENT_TERMS_VIEW,
    edit: AXELE_PERMISSION_TYPE?.EQUIPMENT_PAYMENT_TERM_EDIT,
  };
  return (
    <>
      {isLoaded && (
        <TractorPaymentTermForm
          //dependency data should request once only
          dataDependency={{
            allPaymentTypes,
            expenseCategories,
          }}
          //dependency data should request once only
          data={defaultMaintenanceRecord as any}
          userType={EquipmentType.Tractor}
          permission={equipmentPaymentTermPermission}
          disableAllFields={disableAllFields}
        />
      )}
    </>
  );
};
