export const ExpenceModalTranslations = {
  fuel: 'Fuel',
  paidDate: 'Date',
  netExpense: 'Net Expense',
  createdBy: 'Created By',
  attachment: 'Attachment',
  fuelType: 'Fuel Type',
  tractor: 'Tractor',
  trailer: 'Trailer',
  driver: 'Driver',
  loadId: 'Load ID',
  settlementAmount: 'Settlement Amount',
  lastUpdated: 'Last Updated',
  refNum: 'Ref #',
  addFuelExpense: 'Add Fuel Expense',
  createView: 'Create View',
};
