export enum EMyLoadDetailsPanelTabs {
  FINANCE = 'FINANCE',
  INVOICE = 'INVOICE',
  OVERVIEW = 'OVERVIEW',
  ROUTES = 'ROUTES',
  TRACKING = 'TRACKING',
  NOTES = 'NOTES',
  DOCUMENTS = 'DOCUMENTS',
  ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
  ACTIVITY = 'ACTIVITY',
}

export enum EMyLoadManifestDetailsPanelTabs {
  FINANCE = 'FINANCE',
  OVERVIEW = 'OVERVIEW',
  ROUTES = 'ROUTES',
}

export enum ELoadStatus {
  // Please do not re-order these
  AVAILABLE = 'AVAILABLE',
  ASSIGNMENT_PLANNED = 'ASSIGNMENT_PLANNED', // Planned
  OFFERED_TO_DRIVER = 'OFFERED_TO_DRIVER', // Assigned
  DISPATCHED = 'DISPATCHED',
  IN_TRANSIT = 'IN_TRANSIT',
  LOAD_COMPLETED = 'LOAD_COMPLETED',
  CANCELLED = 'CANCELLED',
  READY_TO_INVOICE = 'READY_TO_INVOICE',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
  DELETED = 'DELETED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
}

export const isAhead = (
  status: ELoadStatus,
  applicableStatus: ELoadStatus
): boolean => {
  const findIndex = (status: ELoadStatus) =>
    Object.values(ELoadStatus).findIndex((s) => s === status);
  return findIndex(status) < findIndex(applicableStatus);
};

export enum ELoad3DotMenuType {
  DUPLICATE = 'DUPLICATE',
}
export enum ETimeOffStatus {
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE',
}

export enum ELoadTextStatus {
  // Please do not re-order these
  AVAILABLE = 'Revert to Available',
  ASSIGNMENT_PLANNED = 'Revert to Plan', // Planned
  DISPATCHED = 'Dispatch',
  // CANCELLED = 'Mark as Canceled',
  INVOICED = 'Revert to Invoiced',
  PAID = 'Mark as Paid',
  DELETED = 'Delete',
  LOAD_COMPLETED = 'Revert to Completed',
  CANCELLED = 'Revert to Canceled',
}
