import { Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatchPermission } from '../../../../../common/hooks/useDispatchPermission';
import {
  UpdateHOSDialog,
  UpdateHOSDialogProps,
} from '../../../../../common/UpdateHOS';
import { StyledCardAlertRoot, warningMessageStyle } from '../styles';
interface CardAlertItemProps {
  title: string;
  actionText: string;
  onClick: () => void;
}
import { useTranslation } from 'react-i18next';

const CardAlertItem = ({ title, actionText, onClick }: CardAlertItemProps) => {
  const { hasDispatchEditPermission } = useDispatchPermission();
  return (
    <Typography
      variant="subtitle2"
      component={'li'}
      sx={{
        color: 'text.primary',
      }}
    >
      {`${title} `}
      <Typography
        variant="subtitle2"
        component={'span'}
        sx={{ color: 'primary.main', cursor: 'pointer' }}
        onClick={() => {
          if (!hasDispatchEditPermission) {
            return;
          }
          onClick();
        }}
      >
        {actionText}
      </Typography>
    </Typography>
  );
};
export interface GanttCardAlertProps extends UpdateHOSDialogProps {
  title: string;
  driverGroupWarnings: Record<string, Record<string, string[]>>;
}
export const GanttCardAlert = ({
  title,
  loadData,
  onClose: _onClose,
  onActionResponse,
  driverGroupWarnings,
}: GanttCardAlertProps): JSX.Element => {
  const { hasDispatchEditPermission } = useDispatchPermission();
  const [showUpdateHOSDialog, setShowUpdateHOSDialog] =
    useState<boolean>(false);

  const onClose = (): void => {
    _onClose();
    setShowUpdateHOSDialog(false);
  };
  const { t, ready } = useTranslation();
  const showWarnings = Object.keys(driverGroupWarnings).length > 0;
  if (!showWarnings) {
    return <></>;
  }

  return (
    <StyledCardAlertRoot>
      {Object.keys(driverGroupWarnings).map((driverGroupId) => {
        const warnings = driverGroupWarnings[driverGroupId];
        return Object.keys(warnings).map((warningsArrKey) => {
          const warningsArr = warnings[warningsArrKey];
          return warningsArr.map((warning: string, i: number) => {
            return (
              <CardAlertItem
                key={i}
                title={warning}
                actionText={
                  hasDispatchEditPermission &&
                  ['DRIVER_LOCATION', 'DRIVER_HOS'].includes(warningsArrKey)
                    ? t('GanttCardAlertUpdateNow')
                    : ''
                }
                onClick={() => {
                  if (!hasDispatchEditPermission) {
                    return;
                  }
                  setShowUpdateHOSDialog(true);
                }}
              />
            );
          });
        });
      })}
      {showUpdateHOSDialog && (
        <UpdateHOSDialog
          onClose={onClose}
          onActionResponse={onActionResponse}
          loadData={loadData}
        />
      )}
    </StyledCardAlertRoot>
  );
};
