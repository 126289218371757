import { Type } from 'class-transformer';
import { FactoringSummary } from '../../../models';
import { PhoneDTO } from '../../../models/DTOs/Contacts/Contacts';
import { Model } from '../../../models/common/Model';
import { addressType } from '../../../types';
export class FactoringDetails {
  id!: string;
  factoringName!: string;
  constructor(dto: FactoringSummary) {
    this.id = dto.id;
    this.factoringName = dto.displayName;
  }
}

export class Address {
  fullAddress: string;
  address: string;
  city: string;
  state: string;
  streetAddress: string;
  streetAddress2: string;
  zip: string | undefined;

  constructor(dto: addressType) {
    this.fullAddress = dto?.fullAddress;
    this.address = dto?.address;
    this.city = dto?.city;
    this.state = dto?.state;
    this.streetAddress = dto?.streetAddress;
    this.streetAddress2 = dto?.streetAddress2;
    this.zip = dto?.zipcode || dto?.zip;
  }
}
export class ContactSummary extends Model {
  fullName: string;
  description: string;
  phoneData: PhoneDTO;
  email: string;
  isPrimary: boolean;

  constructor(dto: any) {
    super(dto);
    this.fullName = dto.fullName;
    this.description = dto.description;
    this.phoneData = dto.phoneData;
    this.email = dto.email;
    this.isPrimary = dto.isPrimary;
  }
}
export class CustomerSummaryTableRow extends Model {
  id!: string;
  organizationId!: number;
  name!: string;
  fullAddress!: string;
  addressDTO!: Address;
  notes!: string;
  @Type(() => ContactSummary)
  contacts!: ContactSummary[];
  contact!: string;
  email!: string;
  @Type(() => PhoneDTO)
  phoneData!: PhoneDTO;
  customerPreference!: string;
  factoringId!: string;
  factoringName!: string;
  mc!: string;
  type!: string;
  ediCustomerId!: string;
  openCreditAmount!: number;

  constructor(dto: any) {
    super(dto);
    this.openCreditAmount = dto.openCreditAmount || 0;
    const dtoKeys = Object.keys(dto);
    dtoKeys.forEach((dtoKey) => {
      // @TODO: Nvard - fix ts issue
      this[dtoKey] = dto[dtoKey];
      if (dtoKey === 'addressDTO') {
        this.fullAddress = dto[dtoKey].fullAddress;
      }
      const primaryContact = dto.contacts.find(
        (contact: ContactSummary) => contact.isPrimary // @TODO: Nvard - investigate class transformer, fix ts issue
      );
      if (primaryContact) {
        const { fullName, email, phoneData } = primaryContact;
        this.contact = fullName;
        this.email = email;
        this.phoneData = phoneData;
      }
    });
  }
}

export class CustomerDetails extends CustomerSummaryTableRow {
  factoring!: { id: string; factoringName: string };

  constructor(dto: any) {
    super(dto);
    this.factoring = {
      id: dto.factoringId || '',
      factoringName: dto.factoringName || '',
    };
  }
}
