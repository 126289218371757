import {
  CreateSafetyAlertRequest,
  CreateUserRequest,
  DeleteSafetyAlertRequest,
  DeleteUserRequest,
  GetDocumentTypesRequest,
  GetDriverAlertListRequest,
  GetDriverIdListRequest,
  GetEldIntegrationDriversRequest,
  GetEldIntegrationsRequest,
  GetEmailsRequest,
  GetInactiveUserDriverMetadataRequest,
  GetInactiveUserDriveURLRequest,
  GetNamesRequest,
  GetPrimaryDispatcherRequest,
  GetRolesRequest,
  GetSafetyAlertListRequest,
  GetStatusesRequest,
  GetTerminalsRequest,
  GetUserByIdRequest,
  invitationsUserDeactivateRequest,
  invitationsUserStatusRequest,
  PaginatedAllUserListRequest,
  PaginatedUserListRequest,
  QueryParams,
  resetOtherUserPasswordRequest,
  resetPasswordRequest,
  SafetyAlertBulkUpdateRequest,
  UpdateUserRequest,
  ValidateUserRequest,
} from '../../models';

import {
  EldIntegrationDetails,
  EldIntegrationDrivers,
  PaginatedEmailList,
  PaginatedNamesList,
  PaginatedRoleList,
  PaginatedTerminalsList,
  PrimaryDispatcher,
  User,
  UserDetails,
  UserDriverActivation,
} from '../../models/DTOs/user/User';
import { LineItem } from '../../subPages/invoices/models/InvoiceModel';
import {
  DriverAlertType,
  SafetyAlertModel,
} from '../../subPages/users/components/SafetyAlerts/models';
import { PaginatedResult } from '../../types';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IUserService extends Service {
  abstract getPaginatedUserList(
    requestData: PaginatedUserListRequest,
    fetchingType: string
  ): Promise<PaginatedResult<User> | ServiceError>;

  abstract getPaginatedAllUserList(
    requestData: PaginatedAllUserListRequest,
    fetchingType: string
  ): Promise<PaginatedResult<User> | ServiceError>;

  abstract getUserById(
    requestData: GetUserByIdRequest
  ): Promise<UserDetails | ServiceError>;

  abstract getUserRoles(
    requestData: GetRolesRequest
  ): Promise<PaginatedRoleList | ServiceError>;

  abstract getUserEmails(
    requestData: GetEmailsRequest
  ): Promise<PaginatedEmailList | ServiceError>;

  abstract getPrimaryDispatchers(
    requestData: GetPrimaryDispatcherRequest,
    cancelable?: boolean,
    skipLoader?: boolean
  ): Promise<PaginatedResult<PrimaryDispatcher> | ServiceError>;

  abstract getTerminals(
    requestData: GetTerminalsRequest
  ): Promise<PaginatedTerminalsList | ServiceError>;

  abstract getNames(
    requestData: GetNamesRequest,
    skipLoader?: boolean
  ): Promise<PaginatedNamesList | ServiceError>;

  abstract getOwnerOperatorNames(
    requestData: GetNamesRequest,
    skipLoader?: boolean
  ): Promise<PaginatedNamesList | ServiceError>;

  abstract getStatuses(requestData: GetStatusesRequest): any;

  abstract createUser(
    requestData: CreateUserRequest,
    data: UserDetails
  ): Promise<{ [key: string]: UserDetails | null } | ServiceError>;

  abstract updateUser(requestData: UpdateUserRequest, data: any): any;

  abstract deleteUser(
    requestData: DeleteUserRequest
  ): Promise<string | ServiceError>;

  abstract validateUserDeletion(
    requestData: DeleteUserRequest
  ): Promise<string | ServiceError>;

  abstract validateResetPassword(
    requestData: resetPasswordRequest,
    passwordType: string
  ): Promise<string | ServiceError>;

  abstract resetPassword(
    requestData: resetPasswordRequest
  ): Promise<string | undefined>;

  abstract resetOtherUserPassword(
    requestData: resetOtherUserPasswordRequest
  ): Promise<string | undefined>;

  abstract getDriverIdList(requestData: GetDriverIdListRequest): any;

  /* @TODO davits add return type */
  abstract validateUser(requestData: ValidateUserRequest): any;

  abstract getDriverListDataTotal(
    requestData: QueryParams
  ): Promise<number | ServiceError>;

  abstract getInactiveDriverUserMetadata(
    requestData: GetInactiveUserDriverMetadataRequest,
    type: string
  ): Promise<UserDriverActivation | null>;

  abstract activateUserDriver(
    payloadData: GetInactiveUserDriverMetadataRequest
  ): Promise<string | ServiceError>;
  abstract activateUserURL(
    payloadData: GetInactiveUserDriveURLRequest,
    type: string
  ): Promise<string | ServiceError>;
  abstract getEldIntegrationsList(
    requestData: GetEldIntegrationsRequest
  ): Promise<EldIntegrationDetails[] | ServiceError>;

  abstract getEldIntegrationDriversByCredentialId(
    requestData: GetEldIntegrationDriversRequest
  ): Promise<EldIntegrationDrivers | ServiceError>;

  abstract getSafetyAlerts(
    requestData: GetSafetyAlertListRequest
  ): Promise<SafetyAlertModel[] | ServiceError>;

  abstract getDriverAlerts(
    requestData: GetDriverAlertListRequest
  ): Promise<DriverAlertType | ServiceError>;

  abstract getDocumentTypes(
    requestData: GetDocumentTypesRequest
  ): Promise<LineItem[] | ServiceError>;

  abstract createSafetyAlert(
    requestData: CreateSafetyAlertRequest
  ): Promise<LineItem[] | ServiceError>;

  abstract deleteSafetyAlert(
    requestData: DeleteSafetyAlertRequest
  ): Promise<string | ServiceError>;

  abstract safetyAlertBulkUpdate(
    requestData: SafetyAlertBulkUpdateRequest,
    alertsList: SafetyAlertModel[]
  ): Promise<SafetyAlertModel[] | ServiceError>;

  abstract invitationsUserStatus(
    requestData: invitationsUserStatusRequest
  ): Promise<UserDetails | ServiceError>;

  abstract invitationsUserDeactivateValidation(
    requestData: invitationsUserStatusRequest
  ): Promise<invitationsUserDeactivateRequest | ServiceError>;
}
