export interface IProps {
  key?: string;
}

export interface IState {
  loadboardProviderData: IloadboardProviderData[];
  customerListWithLogo: IcustomerListWithLogo[];
  selectedProviderDetails: IcustomerListWithLogo[];
  selectedTab: string;
  openDrawer: boolean;
  allCustomerCredentials: [];
  selectedCustomerCredentials: {};
  selectCustomerFeatures: [];
  credentialSteps: [];
  integrationDTO: {};
  openDeactivationPopUp: boolean;
  closeDeactivationPopUp: boolean;
  isEditTab: boolean;
  cardData: any;
  allTerminals: Array<ITerminals> | null;
  userTerminalIds?: Array<string> | undefined;
}

export interface IloadboardProviderData {
  loadBoardIntegrations: [];
  integrationRequiredCredentials: [];
  providerFeatures: IproviderFeatures[];
  providerStatusMap: [];
}

export class LoadboardProviderData {
  loadBoardIntegrations?: [];
  integrationRequiredCredentials?: [];
  providerFeatures?: IproviderFeatures[];
  providerStatusMap?: [];
}

export interface IcustomerListWithLogo {
  description: string;
  logo: object;
  logoWhite: any;
  providerId: number;
  providerName: string;
  gradient: string;
  status: string;
  logoWidth?: string;
}

export interface IproviderFeatures {
  id: string;
  providerId: number;
  supportedFeatures: Array<string>;
}

export interface IcustomerListTypes {
  allCustomers: IcustomerListWithLogo[];
  selectProvider: (arg: any) => void;
  selectedProviderDetails: any;
}

export interface IcustomerFeaturesProps {
  currentCustomerData: any;
  eldProviderData: any;
}

export interface IcustomerFeatures {
  name: string;
  title: string;
  subtitle: string;
  icon: object;
}

export interface IEditCard {
  name: string;
  value: string;
}

export interface ITerminals {
  companyName: string;
  id: string;
  isPrimary: boolean;
  name: string;
}

export interface IAllCustomers {
  description: string;
  logo: any;
  logoWhite: any;
  providerId: number;
  providerName: string;
  gradient: any;
  status: string;
  logoWidth?: string;
}

export interface ICustomerCredentials {
  authType: string | boolean | null;
  auth_fields: string | boolean | null;
  displayName: string;
  features: any;
  fields: Array<ICustomerFields>;
  integrationType: string | boolean | null;
  id: number;
  provider: string;
  stepDescriptions: any;
}

export interface ICustomerFields {
  name: string;
  type: string;
  displayText: string;
  stepNumber: number;
}

export interface IcurrentCustomerIntegrationFormField {
  displayStepName?: string;
  description?: string;
  hasReadmeGuide?: boolean;
  readmeURL?: string;
  hasFields?: boolean;
  fields?: [];
  activeStep?: boolean;
}

export interface ICreateDATSendCredentialsResponse {
  message: string;
}

export interface IDeactivateCardResponse {
  message: string;
}

export interface IeditDATCredentialsResponse {
  archive: boolean;
  data: {
    name: string;
    value: string;
  };
  id: string;
  integrationType: string | null;
  isValid: boolean;
  lastUsedDate: string;
  lastValidationDate: string;
  organizationId: number;
  providerId: number;
  requestedCredentials: boolean;
  status: string;
  terminalIds: Array<string>;
  timeCredentialsRequested: string | null;
  validationErrors: Array<any> | string | null;
}

export interface ICreateLoginCardResponse {
  archive: boolean;
  data: {
    name: string;
    value: string;
  };
  id: string;
  integrationType: string | null;
  isValid: boolean;
  lastUsedDate: string;
  lastValidationDate: string;
  organizationId: number;
  providerId: number;
  requestedCredentials: boolean;
  status: string;
  terminalIds: Array<string>;
  timeCredentialsRequested: string | null;
  validationErrors: Array<any> | string | null;
}

export class AllTerminalResponse {
  content?: Array<any>;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  order?: Array<any>;
  size?: number;
  totalElements?: number;
  totalPages?: number;
}

export interface IPrefilledDATResponse {
  billOfLadingMandatory: boolean;
  brokersToAvoid: [];
  calculateDetention: boolean;
  collectLoadUnloadTiming: boolean;
  companyDetails: IPrefilledCompanyDetails;
  countryCode: string;
  currency: string;
  dashboardPreferences: IPrefilledDataDashboardPreference;
  documentsMandatory: false;
  driverPreferences: IPrefilledDataDriverPreference;
  extension: number;
  fixedCostPerDay: number;
  id: string;
  invoiceTerm: string;
  invoiceTermValue: string | number | null;
  loadPrefix: string | null;
  manifestPrefix: string | null;
  invoicePrefix: string | null;
  loadType: string | null;
  locale: string;
  maxDeadhead: number;
  maxLoadWeight: number;
  organizationId: number;
  paymentToken: null;
  preferredBrokers: [];
  proofOfDeliveryMandatory: true;
  seqNumber: number;
  settlementDate: string;
  settlementPeriod: string;
  stopActivitiesAutofillOption: string;
  timezone: string;
  trailerType: string;
  variableCostPerMile: 1.7;
  workWeekStartDay: string;
}

export class PrefilledDATResponse {
  billOfLadingMandatory?: boolean;
  brokersToAvoid?: [];
  calculateDetention?: boolean;
  collectLoadUnloadTiming?: boolean;
  companyDetails?: IPrefilledCompanyDetails;
  countryCode?: string;
  currency?: string;
  dashboardPreferences?: IPrefilledDataDashboardPreference;
  documentsMandatory?: false;
  driverPreferences?: IPrefilledDataDriverPreference;
  extension?: number;
  fixedCostPerDay?: number;
  id?: string;
  invoiceTerm?: string;
  invoiceTermValue?: string | number | null;
  loadPrefix?: string | null;
  manifestPrefix?: string | null;
  invoicePrefix?: string | null;
  loadType?: string | null;
  locale?: string;
  maxDeadhead?: number;
  maxLoadWeight?: number;
  organizationId?: number;
  paymentToken?: null;
  preferredBrokers?: [];
  proofOfDeliveryMandatory?: true;
  seqNumber?: number;
  settlementDate?: string;
  settlementPeriod?: string;
  stopActivitiesAutofillOption?: string;
  timezone?: string;
  trailerType?: string;
  variableCostPerMile?: 1.7;
  workWeekStartDay?: string;
}

export interface IPrefilledAddressData {
  address: string | null;
  city: string | null;
  fullAddress: string | null;
  state: string | null;
  streetAddress: string | null;
  streetAddress2: string | null;
  zipcode: string | null;
  countryCode: string;
}

export interface IPrefilledCompanyDetails {
  address: string;
  addressData: IPrefilledAddressData;
  countryCode: string;
  dot: string;
  email: string;
  extension: string | null;
  fax: null;
  faxCountryCode: null;
  faxExtension: null;
  isSupportTerminal: true;
  mc: null;
  name: string;
  organizationId: string | null;
  phone: string;
}

export interface IPrefilledDataDriverPreference {
  avoidTollRoutes: boolean;
  dvirPreferences: string;
  easypass: boolean;
  expectedMinimumRevenue: 1;
  expectedWeekRevenue: number;
  ignoreHOSRestrictions: boolean;
  maxHaulLength: number;
  minHaulLength: number;
  preferredTruckstops: string | null;
  statesToAvoid: string | null;
  statesToAvoidForPickupDelivery: string | null;
  useSleeperBerthProvision: boolean;
  workWeekType: string;
}

export interface IPrefilledDataDashboardPreference {
  hideFinancialKPIs: boolean;
  targetRevenuePerLoadedMile: number;
  targetRevenuePerTotalMile: number;
  targetWeeklyRevenue: number;
}

export class UserDetailsReponse {
  accountStartDate?: string;
  addressData?: any;
  allowDriverAcceptRejectLoad?: boolean;
  assignedDocuments?: [];
  assignment?: any;
  associatedTerminalsList?: [];
  automateSettlementCalculation?: boolean;
  axeleId?: string | null;
  birthDate?: null;
  city?: string | null;
  cityOptions?: Array<Object>;
  companyWorks?: string | null;
  country?: null;
  dateOfJoining?: Date;
  dispatcher?: null;
  dispatcherFullName?: null;
  dot?: string;
  driveStatus?: 1;
  driveStatusOptions?: Array<Object>;
  driverELDId?: null;
  driverRefId?: string;
  effectiveStartDate?: string;
  effectiveTerminationDate?: string;
  eldCredentialId?: null;
  eldDriverFirstName?: null;
  eldDriverId?: null;
  eldDriverLastName?: null;
  eldProviderId?: null;
  eldProviderName?: null;
  email?: string;
  emergencyContact?: any;
  employmentType?: string;
  firstname?: string | null;
  hasEmailEnabled?: boolean;
  hasSmsEnabled?: boolean;
  id?: number;
  ipaddress?: null;
  isRemovable?: true;
  lastLoginDate?: null;
  lastname?: string;
  linkedUserId?: null;
  middlename?: string | null;
  operationType?: string;
  orgDriverLicense?: any;
  orgDriverOwnFinancial?: any;
  organizationId?: number;
  phoneData?: any;
  preferredTruckstopsType?: Array<Object>;
  prefix?: null;
  prefixOptions?: any;
  registerDate?: string;
  roleCode?: string;
  roleCodeOptions?: any;
  seqNumber?: null;
  shareTractorExpenses?: false;
  ssn?: string | null;
  startingYTD?: 0;
  state?: string | null;
  stateOptions?: any;
  status?: 2;
  statusOptions?: any;
  streetAddress?: string | null;
  suffix?: null;
  suffixOptions?: any;
  terminal?: any;
  terminalId?: string;
  timezone?: string;
  token?: null;
  trackingSourceData?: any;
  userType?: string;
  zipcode?: string;
}

export interface UserPrefilledResponse {
  firstname: string;
  lastname: string;
  phoneData: {
    phone: string;
  };
}

export class MarketRateResponse {
  allInCost!: number;
  allInCostLow!: number;
  allInCostHigh!: number;
  lineHaulCost!: number;
  lineHaulCostLow!: number;
  lineHaulCostHigh!: number;
  fuelCost!: number;
  fuelCostPerMile!: number;
  fuelCostPerGallon!: number;
  laneMiles!: number;
  allInCostPerMile!: number;
  allInCostLowPerMile!: number;
  allInCostHighPerMile!: number;
}
