import PhotoCameraOutlined from '@mui/icons-material/PhotoCameraOutlined';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../../StorageManager/StorageManager';
import { terminalService } from '../../../../api';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { useTerminalPermission } from '../../../../common/hooks/useTerminalPermission';
import {
  DeleteTerminalLogoDTO,
  GetLogoDTO,
  TerminalContentDTO,
} from '../../../../models';
import { debounceTime } from '../../../../subPages/carriers/constants/constants';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../../../ui-kit/components/IconsComponent';
import { defaultData, makeShort } from '../constants/constants';
import '../styles/TerminalBody.css';
import { bodyStyles, logoErrorStyle } from '../styles/styles';

const Input = styled('input')({
  display: 'none',
});

const CustomLoading = () => {
  return (
    <LoadingButton
      loading
      variant="outlined"
      sx={{
        height: '70px',
        width: '100px',
      }}
    ></LoadingButton>
  );
};

export const TerminalLogo = (props: { terminalDTO: TerminalContentDTO }) => {
  const userStorage = StorageManager.getUser();
  const { hasTerminalEditPermission } = useTerminalPermission();
  const [data, setData] = useState<TerminalContentDTO | null>(null);
  const [errorData, setErrorData] = useState(defaultData);
  const [isLoadingMeta, setIsLoadingMeta] = useState<Record<string, boolean>>(
    {}
  );
  const [logoData, setLogoData] = useState<any>();
  const [logoRes, setLogoRes] = useState({});
  const { setValue, watch } = useFormContext();
  const watchTerminalLogoFile = watch('terminalLogoFile');

  const getLogo = async () => {
    try {
      setIsLoadingMeta(() => ({
        [props.terminalDTO?.id]: true,
      }));

      const requestData = new GetLogoDTO();
      requestData.organizationId = userStorage.organizationId;
      requestData.terminalId = props.terminalDTO.id;
      const result = await terminalService.getLogoForTerminal(requestData);
      setLogoRes(result);
      setLogoData(encodeBase64Image(result));
    } catch (e) {
    } finally {
      setIsLoadingMeta((prev) => ({
        ...prev,
        [props.terminalDTO?.id]: false,
      }));
    }
  };

  const debouncedGetLogoHandler = useCallback(
    debounce(() => {
      getLogo();
    }, debounceTime),
    [props.terminalDTO?.id]
  );

  const onDeleteLogo = async () => {
    const requestData = new DeleteTerminalLogoDTO();
    requestData.organizationId = userStorage.organizationId;
    requestData.id = logoRes.id;
    await terminalService.deleteTerminalLogo(requestData);
    setLogoRes({});
    setLogoData(null);
  };

  const encodeBase64Image = (image: any) => {
    if (!image || !image.contentType || !image.fileContent) return undefined;
    if (image.contentType && image.contentType.includes('jpeg'))
      return `data: image/jpeg;base64, ${image.fileContent}`;
    return `data: image/png;base64, ${image.fileContent}`;
  };

  const onReset = () => {
    debouncedGetLogoHandler?.cancel();
    setLogoData(null);
    setValue('terminalLogoFile', null, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (props.terminalDTO?.id) {
      setData(props.terminalDTO);
      debouncedGetLogoHandler();
    }
    return () => {
      onReset;
    };
  }, [props.terminalDTO?.id]);

  useEffect(() => {
    if (watchTerminalLogoFile === undefined) {
      //onReset TerminalDetailsPanel handle reset Logo here
      debouncedGetLogoHandler();
    }
  }, [watchTerminalLogoFile]);

  const isLoading = !!isLoadingMeta[props.terminalDTO?.id];

  const CompanyLogo = () => {
    return (
      <>
        <Box
          sx={{
            display: isLoading ? 'block' : 'none',
          }}
        >
          <CustomLoading />
        </Box>

        <Box
          sx={{
            display: !isLoading && logoData ? 'block' : 'none',
            '.terminal-logo-cover': {
              visibility: 'hidden',
            },
            ...(hasTerminalEditPermission && {
              ':hover': {
                '.terminal-logo-cover': {
                  visibility: 'visible',
                },
              },
            }),
            position: 'relative',
            width: '100%',
            maxWidth: '200px',
            height: '70px',
          }}
        >
          <img
            src={logoData}
            style={{
              maxWidth: '200px',
              height: 'auto',
              maxHeight: '70px',
            }}
            alt="Company Logo"
            className="image"
          />
          <Box
            className={'terminal-logo-cover'}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              MsTransform: 'translate(-50%, -50%)',
              backgroundColor: 'gray',
              cursor: 'pointer',
              width: '100px',
            }}
          >
            <ButtonImproved
              label="Delete"
              variant="text"
              startIcon={
                <IconsComponent iconName={'DeleteOutlined'} source="MUIcons" />
              }
              styleProps={{ color: 'primary.contrast', pl: 2 }}
              onClick={() => {
                onDeleteLogo(logoData);
              }}
              size="large"
              disabled={!hasTerminalEditPermission || isLoading}
            />
          </Box>
        </Box>

        <Box
          className="customLogo"
          sx={{
            ...bodyStyles.defaultLogoNameWrapper,
            height: '70px',
            width: '100px',
            display: !isLoading && !logoData ? 'block' : 'none',
          }}
        >
          <Typography
            sx={{
              ...bodyStyles.defaultLogoText,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {makeShort(data?.companyName, 6)}
          </Typography>
        </Box>
      </>
    );
  };

  const handleLogoChange = (event: any) => {
    if (!event.target.files.length) return;
    const logoErrorString =
      'Logo must be an image(jpeg/png) file of size 1MB or less and not wider then 1200px';
    let tempError = errorData;
    const logoErrorCondition =
      !/\.(jpe?g|png)$/i.test(event.target.files[0].name) ||
      event.target.files[0].size > 1000000;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function (data) {
      const image = new Image();
      image.src = data?.target?.result as string;
      image.onload = function () {
        if (logoErrorCondition || image.width > 1200) {
          tempError = {
            ...errorData,
            logo: logoErrorString,
          };
        } else {
          tempError = {
            ...errorData,
            logo: '',
          };

          setLogoData(URL.createObjectURL(event.target.files[0]));
          setValue('terminalLogoFile', event.target.files[0], {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
        setErrorData(tempError);
      };
    };
  };

  return (
    <Box sx={{ display: 'flex', mb: 2, flexDirection: 'column' }}>
      <Typography variant="inputLabel" sx={{ color: 'text.secondary', mb: 1 }}>
        Company Logo
      </Typography>
      <Box sx={{ display: 'contents', justifyContent: 'center' }}>
        <Box
          className="contentValue"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box
            className="companyLogo"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70px',
            }}
          >
            <Box
              sx={{
                height: '70px',
              }}
            >
              <CompanyLogo />
            </Box>
            <Box>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={handleLogoChange}
                  disabled={!hasTerminalEditPermission || isLoading}
                />
                <Permission contains={[AXELE_PERMISSION_TYPE.TERMINALS_EDIT]}>
                  <IconButton
                    sx={{ color: 'primary.main' }}
                    aria-label="upload picture"
                    component="span"
                    disabled={isLoading}
                  >
                    <PhotoCameraOutlined />
                  </IconButton>
                </Permission>
              </label>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            {errorData?.logo?.length > 0 && (
              <Typography
                className="errorData.logo"
                variant="caption"
                sx={logoErrorStyle}
              >
                {errorData.logo}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
