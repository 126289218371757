import { payStatementService } from '../../../api';
import {
  CombinedNames,
  CombinedNamesListRequest,
  SettlementIdFilterRequest,
} from '../../../models';
import { ViewMetaData } from '../../../types';

export const getCombinedNameList = async (
  name: string,
  pageNumber: number,
  filters: ViewMetaData = [],
  terminalIds: string[]
) => {
  const request = new CombinedNamesListRequest(
    name,
    pageNumber,
    25,
    filters?.typeFilter?.id,
    terminalIds,
    filters?.typeFilter?.id === 'TRACTOR'
      ? ['UNAVAILABLE', 'INACTIVE']
      : undefined
  );

  const response = await payStatementService.getCombinedNameList(request);

  if (response) {
    return {
      ...response,
      content: response.content?.map(
        (item: CombinedNames) => new CombinedNames(item)
      ),
    };
  }
  return response;
};

export const getSettlementIdList = async (
  pageNumber: number,
  terminalIds: string[],
  seqNumberFilter?: string,
  entity?: string,
  filters: ViewMetaData = []
) => {
  const queryParams = {
    pageNumber,
    pageSize: 25,
    terminalIds,
    seqNumberFilter,
    entity,
    filters,
  };

  const request = new SettlementIdFilterRequest(queryParams);
  const response = await payStatementService.getSettlementIdList(request);

  if (response) {
    const newRes = response.content?.map((item) => ({ seqNumber: item }));
    return { ...response, content: newRes };
  }
};
