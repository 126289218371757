import { Divider, Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';

export function SettingBlockItem({
  title,
  subTitle,
  hasExceptions = false,
  children,
}: {
  title: string;
  subTitle: string;
  hasExceptions?: boolean;
  children: ReactNode;
}) {
  return (
    <>
      <Grid
        className="SettingBlockItem-root"
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 1 }}
        spacing={2}
      >
        <Grid xs={12} xl={hasExceptions ? 7 : 9.5} item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h6" color="text.primary">
                {title}
              </Typography>
            </Grid>
            <Grid item sx={{ mb: { xs: 2, sm: 2, md: 2, lg: 0 } }}>
              <Typography variant="subtitle1" color="text.secondary">
                {subTitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} xl={hasExceptions ? 5 : 2.5} item>
          {children}
        </Grid>
      </Grid>
      <Grid item sx={{ mt: 4 }} xs={5}>
        <Divider />
      </Grid>
    </>
  );
}
