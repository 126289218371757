import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import {
  ETripSettlementStatus,
  TTripSettlementStatusOption,
  TTripStatusOption,
} from '../../../models/DTOs/Trip/Types';
import { IAutocompleteBooleanOption, ITripFilters } from './types';

import { TripsConstants } from '../../../locales/en/allLoads/trips/index';

// const t = useTranslation();
export const tripFieldsConfig: {
  [key: string]: {
    fieldName: string;
    label: string;
    fieldFilterName?: keyof ITripFilters;
    fieldFilterLabel?: string;
  };
} = {
  plusButton: {
    fieldName: 'id',
    label: '',
  },
  tripId: {
    fieldName: 'seqNumber',
    label: TripsConstants.tripId,
    fieldFilterName: 'tripIdsFilter',
  },
  tripStatus: {
    fieldName: 'status',
    label: TripsConstants.tripStatus,
    fieldFilterName: 'statuses',
    fieldFilterLabel: 'Status',
  },
  driver: {
    fieldName: 'groupDetails',
    label: TripsConstants.driver,
    fieldFilterName: 'driverIdsFilter',
  },
  carrierDetails: {
    fieldName: 'carrierDetails',
    label: TripsConstants.carrier,
    fieldFilterName: 'carrierIdsFilter',
  },
  loadPriority: {
    fieldName: 'loadPriority',
    label: TripsConstants.loadPriority,
  },
  brokerageTrip: {
    fieldName: 'brokerageTrip',
    label: TripsConstants.brokered,
    fieldFilterName: 'brokerageTripFilter',
  },
  canBeBrokered: {
    fieldName: 'canBeBrokered',
    label: TripsConstants.canBeBrokered,
  },
  onHoldState: {
    fieldName: 'onHoldState',
    label: TripsConstants.onHoldState,
    fieldFilterName: 'onHoldState',
  },
  tractor: {
    fieldName: 'tractorName',
    label: TripsConstants.tractor + ' #',
    fieldFilterName: 'tractorIdsFilter',
  },
  trailer: {
    fieldName: 'trailerName',
    label: TripsConstants.trailer,
    fieldFilterName: 'trailerIdsFilter',
  },
  legs: {
    fieldName: 'numberOfStops',
    label: '# ' + TripsConstants.legs,
  },
  tripStart: {
    fieldName: 'startLocation',
    label: TripsConstants.tripStartLocation,
    fieldFilterName: 'originCityStateList',
  },
  tripEnd: {
    fieldName: 'endLocation',
    label: TripsConstants.tripEndLocation,
    fieldFilterName: 'destinationCityStateList',
  },
  tripStartDate: {
    fieldName: 'startDate',
    label: TripsConstants.tripStart,
    fieldFilterName: 'tripStartDateRanges',
  },
  tripEndDate: {
    fieldName: 'endDate',
    label: TripsConstants.tripEnd,
    fieldFilterName: 'destinationCityStateList',
  },
  dispatcher: {
    fieldName: 'dispatcherName',
    label: TripsConstants.dispatcher,
    fieldFilterName: 'dispatcherIdsFilter',
  },
  loadId: {
    fieldName: 'connectedLoads',
    label: TripsConstants.loadID,
    fieldFilterName: 'loadIdsFilter',
  },
  manifestId: {
    fieldName: 'manifestSeqNumbersFilter',
    label: TripsConstants.manifestID,
    fieldFilterName: 'manifestSeqNumbersFilter',
  },
  settlementStatus: {
    fieldName: 'settlementStatus',
    label: TripsConstants.settlementStatus,
    fieldFilterName: 'settlementStatuses',
  },
  settlementAmount: {
    fieldName: 'settlementAmount',
    label: TripsConstants.settlementAmount,
  },
  revenue: {
    fieldName: 'revenue',
    label: TripsConstants.revenue,
  },
  tripMiles: {
    fieldName: 'tripMileage',
    label: TripsConstants.tripMiles,
  },
  emptyMiles: {
    fieldName: 'emptyMileage',
    label: TripsConstants.emptyMiles,
  },
  tripStartDateRanges: {
    fieldName: 'tripStartDateRanges',
    fieldFilterName: 'tripStartDateRanges',
    label: TripsConstants.tripStartDate,
  },
  tripStartFrom: {
    fieldName: 'tripStartFrom',
    fieldFilterName: 'tripStartFrom',
    label: 'tripStartFrom',
  },
  tripStartTo: {
    fieldName: 'tripStartTo',
    fieldFilterName: 'tripStartTo',
    label: TripsConstants.tripStartTo,
  },
  tripEndDateRanges: {
    fieldName: 'tripEndDateRanges',
    fieldFilterName: 'tripEndDateRanges',
    label: TripsConstants.tripEndDate,
  },
  //@Sinh will remove these DatePicker later
  tripEndFrom: {
    fieldName: 'tripEndFrom',
    fieldFilterName: 'tripEndFrom',
    label: TripsConstants.tripEndFrom,
  },
  tripEndTo: {
    fieldName: 'tripEndTo',
    fieldFilterName: 'tripEndTo',
    label: TripsConstants.tripEndTo,
  },
  tripStartState: {
    fieldName: 'originStateList',
    label: TripsConstants.tripStartState,
  },
  tripEndState: {
    fieldName: 'destinationStateList',
    label: TripsConstants.tripEndState,
  },
  relay: {
    fieldName: 'relay',
    label: TripsConstants.hasRelay,
  },
  chassis: {
    fieldName: 'chassisNumbers',
    label: TripsConstants.chassis + ' #',
  },
  container: {
    fieldName: 'containerNumbers',
    label: TripsConstants.container + ' #',
  },
  customer: {
    fieldName: 'customers',
    label: TripsConstants.customer,
    fieldFilterName: 'customerIdsFilter',
  },
  reference: {
    fieldName: 'referenceIds',
    label: TripsConstants.reference + ' #',
    fieldFilterName: 'referenceIds',
  },
  terminal: {
    label: TripsConstants.dispatchTerminal,
    fieldName: 'terminal',
    fieldFilterName: 'terminalIds',
  },
  delayViolations: {
    label: TripsConstants.delayViolation,
    fieldName: 'delayViolations',
    fieldFilterName: 'delayViolations',
  },
  detention: {
    label: TripsConstants.detention,
    fieldName: 'detention',
    fieldFilterName: 'detention',
  },
  needDriversNow: {
    label: TripsConstants.needDriversNow,
    fieldName: 'needDriversNow',
    fieldFilterName: 'needDriversNow',
  },
  isEmptyTrip: {
    fieldName: 'isEmptyTrip',
    fieldFilterName: 'isEmptyTrip',
    label: TripsConstants.emptyTrip,
  },
  driverOperationMode: {
    label: TripsConstants.driverOperationMode,
    fieldName: 'driverOperationMode',
    fieldFilterName: 'driverOperationMode',
  },
  tags: {
    label: 'Tags',
    fieldName: 'tags',
  },
  warning: {
    fieldName: 'warning',
    label: '',
  },
  createDate: {
    fieldName: 'createDate',
    label: TripsConstants.createdDateTime,
  },
};

export enum RecordViewMode {
  calendar = 'calendar',
  table = 'table',
}

export const strip = 'trips';
export const debounceTime = 300;

export const TRIP_STATUS_LIST: TTripStatusOption[] = [
  {
    label: TripsConstants.TRIP_STATUS_LISTPlanned,
    value: ELoadStatus.ASSIGNMENT_PLANNED,
  },
  {
    label: TripsConstants.TRIP_STATUS_LISTAvailable,
    value: ELoadStatus.AVAILABLE,
  },
  {
    label: TripsConstants.TRIP_STATUS_LISTAssigned,
    value: ELoadStatus.OFFERED_TO_DRIVER,
  },
  {
    label: TripsConstants.TRIP_STATUS_LISTDispatched,
    value: ELoadStatus.DISPATCHED,
  },

  {
    label: TripsConstants.TRIP_STATUS_LISTInTransit,
    value: ELoadStatus.IN_TRANSIT,
  },
  {
    label: TripsConstants.TRIP_STATUS_LISTCompleted,
    value: ELoadStatus.LOAD_COMPLETED,
  },
  {
    label: TripsConstants.TRIP_STATUS_LISTCanceled,
    value: ELoadStatus.CANCELLED,
  },
];
export const SETTLEMENT_STATUS_LIST: TTripSettlementStatusOption[] = [
  {
    label: TripsConstants.TripSeltlementStatusPending,
    value: ETripSettlementStatus.Pending,
  },
  {
    label: TripsConstants.TripSeltlementStatusInReview,
    value: ETripSettlementStatus.InReview,
  },
  {
    label: TripsConstants.TripSeltlementStatusReviewed,
    value: ETripSettlementStatus.reviewed,
  },
  {
    label: TripsConstants.TripSeltlementStatusClosed,
    value: ETripSettlementStatus.Paid,
  },
];

export enum ETripPanelTabs {
  ROUTE = 'ROUTE',
  FINANCE = 'FINANCE',
  CARRIER_PAYMENT = 'CARRIER_PAYMENT',
}

export const getBrokeredYesNoOptions = (): IAutocompleteBooleanOption[] => {
  return [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];
};

export enum OnHoldStates {
  ALL = 'ALL',
  ONLY_HOLD = 'ONLY_HOLD',
  NO_HOLD = 'NO_HOLD',
}
