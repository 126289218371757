import { useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { loadService, tripService } from '../../../../../../api';
import { ServiceError } from '../../../../../../api/interfaces';
import {
  CheckOrAssignAssetResponse,
  UpdateAllActivitiesPayload,
  UpdateAllActivitiesRequest,
} from '../../../../../../models';
import { CheckOrAssignAssetRequest } from '../../../../../../models/DTOs/Trip/Requests';
import { useStores } from '../../../../../../store/root.context';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';
import { TripStopStatusEnum } from '../../../../../../ui-kit/components/TripPlan';
import { useTripSettings } from '../../../../../../views/trips/context/TripContext';
import FormDialog from '../../../../../Ui/FormDialog';
import { ELoadStatus } from '../../../../constants/constants';
import AssetAssignmentPopup from '../AssetAssignmentPopup';
import UpdateStopForm, {
  EFieldKey,
  UpdateActivityFormData,
  getRenderedFields,
} from './components/Form/UpdateStopForm';
import { validationSchema } from './utils';

export interface UpdateStopDialogProps {
  onClose: () => void;
  onAction: () => void;
  data: UpdateActivityFormData;
  onAutoAssign?: () => void;
  onTripStatusChanged?: () => void;
}

const UpdateStopActivitiesDialog = ({
  onClose,
  data: originalData,
  onAction: onActionProp,
  onAutoAssign,
  onTripStatusChanged,
}: UpdateStopDialogProps): JSX.Element => {
  const [assignment, setAssignment] = useState<{
    assetValidation: CheckOrAssignAssetResponse;
    data: UpdateActivityFormData;
  } | null>(null);
  const { updateMainData } = useTripSettings();
  const theme = useTheme();
  const [updatedProgress, setUpdatedProgress] = useState({
    open: false,
    message: null,
  });
  const {
    tripsStore,
    myLoadStore: { setUpdatedLoadId },
  } = useStores();
  const createPayload = (data: UpdateActivityFormData) => {
    const stops = [];
    for (let stopIndex = 0; stopIndex < data.stops.length; stopIndex++) {
      const prevStopStatus = stops[stopIndex - 1]?.stopStatus;
      let stopPayload = {};
      const stop = data.stops[stopIndex];
      const fields = getRenderedFields(stop, data.collectLoadUnloadTiming).map(
        ([f]) => f
      );
      let stopStatus: TripStopStatusEnum;
      if (stop[EFieldKey.MarkDeparture]) {
        stopStatus = TripStopStatusEnum.COMPLETED;
      } else if (
        fields.includes(EFieldKey.LoadingDone) &&
        stop[EFieldKey.LoadingDone]
      ) {
        stopStatus = TripStopStatusEnum.ACTIVITY_ENDED;
      } else if (
        fields.includes(EFieldKey.LoadingStarted) &&
        stop[EFieldKey.LoadingStarted]
      ) {
        stopStatus = TripStopStatusEnum.ACTIVITY_STARTED;
      } else if (stop[EFieldKey.MarkArrival]) {
        stopStatus = TripStopStatusEnum.ARRIVED;
      } else if (
        stopIndex === 0 &&
        [ELoadStatus.DISPATCHED, ELoadStatus.IN_TRANSIT].includes(
          data.tripStatus
        )
      ) {
        stopStatus = TripStopStatusEnum.EN_ROUTE;
      } else if (prevStopStatus === TripStopStatusEnum.COMPLETED) {
        stopStatus = TripStopStatusEnum.EN_ROUTE;
      } else {
        stopStatus = TripStopStatusEnum.NONE;
      }
      stopPayload = {
        actualTimeOfArrival: stop.actualTimeOfArrivalDate,
        actualTimeOfCompletion: stop.actualTimeOfCompletionDate,
        id: stop.id,
        loadingEndDate: data.collectLoadUnloadTiming
          ? stop.loadingEndDate
          : stop.actualTimeOfArrivalDate,
        loadingStartDate: data.collectLoadUnloadTiming
          ? stop.loadingStartDate
          : stop.actualTimeOfArrivalDate,
        stopStatus: stopStatus,
      };
      stops.push(stopPayload);
    }
    return stops;
  };

  const updateStopActivities = async (data: UpdateActivityFormData) => {
    const response = await loadService.updateAllActivities(
      new UpdateAllActivitiesRequest({
        tripId: data.tripId,
      }),
      new UpdateAllActivitiesPayload({
        activityUpdateDataList: createPayload(data),
      })
    );
    if (!(response instanceof ServiceError)) {
      onTripStatusChanged?.();
      setUpdatedLoadId?.(originalData.loadId);
      updateMainData?.({
        updatedIds: [originalData.tripId],
      });
      tripsStore.updateMainData({
        deletedIds: [],
        updatedIds: [originalData.tripId],
      });
      onActionProp?.();
    } else {
      setUpdatedProgress({
        open: true,
        message: response?.error?.response?.data?.message,
      });
    }
  };
  const onAction = async (data: UpdateActivityFormData) => {
    if (
      !originalData.stops[0][EFieldKey.MarkDeparture] &&
      data.stops[0][EFieldKey.MarkDeparture]
    ) {
      const response = await tripService.checkOrAssignAsset(
        new CheckOrAssignAssetRequest({
          assetType: 'TRAILER',
          tripId: data.tripId,
          userChoice: null,
        })
      );

      if (!(response instanceof ServiceError)) {
        if (
          ['ASSET_OCCUPIED', 'ASSET_MISMATCH'].includes(response.conflictType)
        ) {
          setAssignment({ assetValidation: response, data });
        } else {
          await updateStopActivities(data);
        }
      } else {
        await updateStopActivities(data);
      }
    } else {
      await updateStopActivities(data);
    }
  };

  const afterAssign = async () => {
    await updateStopActivities(assignment?.data as UpdateActivityFormData);
    onTripStatusChanged?.();
    setAssignment(null);
    onAutoAssign?.();
  };

  return (
    <>
      <FormDialog
        width={514}
        data={originalData}
        titleText="Update Progress"
        actionLabel="Update"
        open={true}
        onAction={onAction}
        handleClose={onClose}
        contentRenderer={() => <UpdateStopForm originalData={originalData} />}
        validationSchema={validationSchema}
      />
      {assignment?.assetValidation && (
        <AssetAssignmentPopup
          assetAssignment={assignment?.assetValidation}
          onClose={() => setAssignment(null)}
          onActionCompleted={afterAssign}
          data={{
            ...originalData.assetAssignmentFormData,
            driverAsset: assignment.assetValidation.driverAsset!,
          }}
        />
      )}
      <DeletePopup
        open={updatedProgress.open}
        onClose={() => {
          setUpdatedProgress({
            open: false,
            message: null,
          });
        }}
        title={'Info'}
        body={updatedProgress.message}
        cancelText={'Okay'}
        backgroundColor={theme.palette.primary.main}
        width={'440px'}
        hideDelete={true}
      />
    </>
  );
};

export default observer(UpdateStopActivitiesDialog);
