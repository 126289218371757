import { QueryParams } from '../../../../../models';
import { RowActualData } from '../ManageFactoreIntegration';

export class RefreshAccountRequest extends QueryParams {
  organizationId!: number;
  credentialId!: string;
}

export class mappingFactoringRes {
  content: any;
}

export class FactorePaginatedRoleList {
  content?: any;
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  order!: SortResponse[];
}

export class SortResponse {
  property!: string;
  directiion!: string;
}

export interface IeldProviderData {
  eldIntegrationsDTO: null;
  integrationRequiredCredentials: [];
  providerFeatures: IproviderFeatures[];
  providersStatusMap: [];
  integrationsDTO?: null;
}

export interface IcustomerListWithLogo {
  description: string;
  logo: object;
  providerId: number;
  providerName: string;
  status: string;
}

export interface IproviderFeatures {
  id: string;
  providerId: number;
  supportedFeatures: Array<string>;
}

export interface IcustomerListTypes {
  allCustomers: IcustomerListWithLogo[];
  selectProvider: (arg: any) => void;
  selectedProviderDetails: any;
}

export interface IcustomerFeaturesProps {
  currentCustomerData: any;
  eldProviderData: IeldProviderData;
  connectAuth?: (arg: any) => void;
  refreshFactoringData?: any;
}
export interface IeldCustomerFeatures {
  id: string;
  providerId: string;
  supportedFeatures: Array<string>;
}
export interface ILinearPopupErrors {
  displayName: string;
  nameOnDocuments: string;
  email: string;
  address: string;
  phoneNumber: number;
}
