import { Box } from '@mui/material';
import { LoadConstants } from '../../locales/en/allLoads/loads';
import { Trip } from '../../models';
import { ELoadStatus } from '../LoadTabPanel/constants/constants';
import {
  LoadStatus,
  StopStatus,
  TStatusChip,
  getOnHoldLockIcon,
} from '../Ui/StatusComponent/StatusComponent';

export const tripLoadCustomTitle = ({
  status,
  lastUpdatedStop,
  nonInvoiceable,
  onHold,
  onHoldNote,
}: {
  status: ELoadStatus | undefined;
  nonInvoiceable?: boolean;
} & Pick<Trip, 'onHold' | 'onHoldNote' | 'lastUpdatedStop'>) => {
  let statusChip;
  const iconConfig: Pick<TStatusChip, 'getIcon'> = getOnHoldLockIcon({
    onHold,
    title: onHoldNote
      ? `${LoadConstants.loadIsOnHold}: ${onHoldNote}`
      : LoadConstants.loadIsOnHold,
  });
  if (!status) statusChip = null;
  else if (
    [ELoadStatus.DISPATCHED, ELoadStatus.IN_TRANSIT].includes(status) &&
    lastUpdatedStop
  ) {
    statusChip = <StopStatus status={lastUpdatedStop} {...iconConfig} />;
  } else {
    statusChip = (
      <LoadStatus
        type="LOAD"
        theme="dark"
        status={status}
        nonInvoiceable={nonInvoiceable}
        {...iconConfig}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        color: 'primary.contrast',
        padding: '15px 15px 15px 5px',
      }}
    >
      {statusChip}
    </Box>
  );
};
