import {
  getColumnToolTipProps,
  getDriverTooltipSubtitlesWithoutLink,
} from '../../services/gantt';
import {
  GanttDriverLocationMarkersMapping,
  GanttDriverLocationMarkersMissing,
  IGanttDriverLocationMarker,
} from '../../models/DTOs/Dispatch/Dispatch';
import {
  HosConstantsType,
  ScheduleDriverType,
} from '../../types/DispatchTypes';
import { IDispatchFilters } from '../../views/dispatch2/constants/types';
import {
  formatDriverSchedulerFilters,
  formatGanttFilter,
} from '../../views/dispatch2/services';
import { IDriverTooltip, IWarnings } from '@optym/gantt';

export const getDriverLocationRequestPayload = ({
  filters,
  terminalIds,
}: {
  filters: IDispatchFilters;
  terminalIds?: Array<string>;
}) => {
  const formattedFilters = {
    ...formatGanttFilter({
      filters: filters?.filters,
    }),
    terminalIds,
  };
  return formatDriverSchedulerFilters(formattedFilters);
};

const isDriverLocationMissing = (driver: ScheduleDriverType): boolean => {
  if (
    !driver?.driverLocationDetails?.lat ||
    !driver?.driverLocationDetails?.lon
  )
    return true;
  return false;
};

export const filterDriverListByLocation = ({
  drivers,
  currentWeek,
  hosConstants,
  isTooltipActionDisabled,
}: {
  drivers: ScheduleDriverType[];
  currentWeek?: Array<string>;
  hosConstants?: HosConstantsType;
  isTooltipActionDisabled?: boolean;
}): {
  locatedDrivers: Array<IGanttDriverLocationMarker>;
  missingDrivers: Array<GanttDriverLocationMarkersMissing>;
} => {
  const [locatedDrivers, missingDrivers] = [
    [] as IGanttDriverLocationMarker[],
    [] as GanttDriverLocationMarkersMissing[],
  ];
  for (const driver of drivers) {
    if (isDriverLocationMissing(driver))
      missingDrivers?.push(
        formatUnlocatedDrivers({ ...driver, currentWeek, hosConstants })
      );
    else
      locatedDrivers?.push(
        formatDriverToLocationMarker(
          { ...driver, currentWeek, hosConstants },
          isTooltipActionDisabled
        )
      );
  }
  return { locatedDrivers, missingDrivers };
};

export const formatDriverToLocationMarker = (
  driver: ScheduleDriverType,
  isTooltipActionDisabled?: boolean
): IGanttDriverLocationMarker => {
  return {
    lat: driver?.driverLocationDetails?.lat,
    lng: driver?.driverLocationDetails?.lon,
    type: getDriverMarkerType(driver),
    status: '',
    issue: false,
    deadHead: false,
    isHOSOutdated: driver?.isHOSOutdated,
    isLocationOutdated: driver?.isLocationOutdated,
    routeFlag: driver?.routeFlag,
    driverGroupID: driver?.driverGroupDetails?.id!,
    driverGroupName: driver?.driverGroupDetails?.groupName!,
    shiftDriveTimeRemaining: driver?.driveTimeRemaining,
    shiftDutyTimeRemaining: driver?.dutyTimeRemaining,
    cycleTimeRemaining: driver?.cycleTimeRemaining,
    timeToBreak: driver?.timeToBreak,
    // locationSource: driver?.
    tooltip: getMapDriverTooltip({ driver, isTooltipActionDisabled }),
  };
};

export const formatUnlocatedDrivers = (
  driver: ScheduleDriverType
): GanttDriverLocationMarkersMissing => {
  return {
    id: driver?.driverGroupDetails?.id!,
    // organizatinId: ,
    groupName: driver?.driverGroupDetails?.groupName!,
    driverIds: [driver?.driverGroupDetails?.driverId!],
    activeForOperation: true,
    dispatcherId: Number(driver?.driverGroupDetails?.dispatcherId),
    // groupStatus: ,
  };
};

export const getDriverMarkerType = (driver: ScheduleDriverType): string => {
  if (driver?.driverGroupDetails?.groupMode === 'SOLO') return 'SOLODRIVER';
  else if (driver?.driverGroupDetails?.groupMode === 'TEAM')
    return 'TEAMDRIVER';
  return driver?.driverGroupDetails?.groupMode!;
};

export const getMapDriverTooltip = ({
  driver,
  isTooltipActionDisabled = true,
}: {
  driver: ScheduleDriverType;
  isTooltipActionDisabled?: boolean;
}): IDriverTooltip => {
  if (driver?.isLocationOutdated || driver?.isHOSOutdated) {
    driver.warnings = {};
    driver.warnings.driverHOSWarning = true;
  }
  const tooltip = getColumnToolTipProps(driver) ?? {};
  if (driver?.warnings?.driverHOSWarning)
    delete (tooltip?.footer as IWarnings)?.content?.[0]?.icon;
  if (isTooltipActionDisabled) {
    tooltip.actions = [];
    tooltip.footer = {};
    tooltip.subTitles = getDriverTooltipSubtitlesWithoutLink(driver);
  }
  return tooltip;
};
