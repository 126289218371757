import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';

import { FormProvider, useForm } from 'react-hook-form';
import {
  CreateInReviewPayStatementType,
  NonTripPaymentListType,
  PayStatementData,
  PayStatementDetails,
  PayStatementStatusEnum,
  PayStatementType,
  PayStatementsEntityTypeEnum,
  PayStatementsEntityTypeMap,
  PayStatementsTypeEnum,
  PaymentDetails,
  SelectedPaymentsType,
} from '../../../models';
import {
  bulkUpdateNonTripPayments,
  closePayStatement,
  createInReviewPayStatement,
  deleteInReviewPayStatement,
  getGrossYearToDateAmountById,
  getNonTripPaymentsListById,
  getPayStatementDetailsById,
  getTripPaymentsListById,
  reopenInReviewPayStatement,
  updateSettlementStatus,
} from '../utils/api.utils';

import DeletePopup from '../../../ui-kit/components/DeletePopup';

import { useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DirtyDetailsPanelManager } from '../../../common/DetailsPanel/utils';
import FormDialog from '../../../common/Ui/FormDialog';
import { PayStatementStatus } from '../../../common/Ui/StatusComponent/StatusComponent';
import { UI_DATE_FORMAT } from '../../../constants/date.constants';
import { useStaticData } from '../../../contexts/StaticDataContext';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { useStores } from '../../../store/root.context';
import { getFormattedDateForGrid } from '../../../utils';
import { useTripSettings } from '../../../views/trips/context/TripContext';
import {
  BulkSettlementActions,
  GlobalNotificationsForStatusChange,
  SettlementStatusByActionTypes,
} from '../config/bulkActions.config';
import {
  SettlementStatusChangePopupConfig,
  createInReviewPayStatementValidationSchema,
  getSecondaryActionConfig,
  payStatementDetailsFormValidationSchema,
} from '../constants';
import { ActionsSection } from '../utils/ActionsSection';
import {
  captilizeFirstChar,
  getPayStatmentTotalAmount,
} from '../utils/helpers';
import CloseBulkSettlements from './BulkActions/CloseSettlement';
import CreateInReviewPayStatementForm from './Form/CreateInReviewPayStatementForm';
import PayStatementDetailsForm from './Form/PayStatementDetailsForm';

type propsType = {
  data: PayStatementType;
  canAddPendingPaymentItem?: boolean;
  isGlobal?: boolean;
  onClose?: () => void;
  onUpdated?: (
    id?: number,
    totalAmount?: number,
    shouldClosPanel?: boolean
  ) => void;
  onRemoveTableRow?: (id: number, shouldClosPanel?: boolean) => void;
  onAddTableRow?: (newRow: PayStatementType) => void;
  loadId?: string;
  currentTabEntity?: PayStatementsEntityTypeEnum;
  onReset?: () => void;
};

function PaymentsDetailsPanel({
  data,
  canAddPendingPaymentItem = false,
  isGlobal = false,
  onClose,
  onUpdated,
  onRemoveTableRow,
  onAddTableRow,
  loadId,
  currentTabEntity,
  onReset,
}: propsType): JSX.Element | null {
  const { updateMainData } = useTripSettings();
  const { staticData, getStaticData } = useStaticData();
  const [payStatementDetailsData, setPayStatementDetailsData] =
    useState<PayStatementDetails | null>();
  const {
    tripsStore,
    myLoadStore: { setUpdatedLoadId },
  } = useStores();
  const [payStatementData, setPayStatementData] = useState<PayStatementData>(
    new PayStatementData()
  );
  const state = useForm({
    defaultValues: { paymentDate: new Date() },
  });
  const selectedTableRowDataRef = useRef(new PayStatementData());

  const [isFormDialogOpen, setIsFormDialogOpen] = useState<boolean>(false);
  const [selectedPaymentsIds, setSelectedPaymentsIds] = useState<number[]>([]);
  const [entityType, setEntityType] = useState<PayStatementsEntityTypeEnum>(
    PayStatementsEntityTypeEnum.pendingPayment
  );

  const [isInformationPopupOpen, setIsInformationPopupOpen] =
    useState<boolean>(false);
  const [currentAction, setCurrentAction] =
    useState<BulkSettlementActions | null>();

  useEffect(() => {
    getStaticData?.();
  }, []);

  const onAfterAction = (): void => {
    setUpdatedLoadId?.(loadId);
    updateMainData?.({ updatedIds: [loadId!] });
    tripsStore.updateMainData({ updatedIds: [loadId!] });
  };

  const updateTotalAmount = (nonTripPayments?: NonTripPaymentListType) => {
    if (!nonTripPayments) return;
    let totalAmount = 0;
    if (!!payStatementDetailsData?.tripPaymentsList?.totalAmount) {
      totalAmount =
        totalAmount + payStatementDetailsData?.tripPaymentsList?.totalAmount;
    }
    if (!!nonTripPayments?.fuelPayStatementList?.totalAmount) {
      totalAmount =
        totalAmount + nonTripPayments?.fuelPayStatementList?.totalAmount;
    }
    if (!!nonTripPayments?.otherPayStatementList?.totalAmount) {
      totalAmount =
        totalAmount + nonTripPayments?.otherPayStatementList?.totalAmount;
    }

    setPayStatementDetailsData((oldPayStatements) => {
      return { ...oldPayStatements, totalAmount };
    });

    onUpdated && onUpdated(payStatementData?.id, totalAmount, false);
    return totalAmount;
  };

  async function getPayStatementData(
    id: number,
    payStatementId: number,
    payStatementType: PayStatementsTypeEnum,
    seqNumber: number,
    updateSelectedRowDataRef = true
  ) {
    const response = await getPayStatementDetailsById(
      id,
      payStatementId,
      payStatementType,
      seqNumber
    );
    if (!response) return;
    setPayStatementData(response);
    if (updateSelectedRowDataRef) selectedTableRowDataRef.current = response;
    setEntityType(response.entity);
    fetchDetails(response.entity, response);
  }

  async function getGrossYearToDateAmount(
    updatedEntityType: PayStatementsEntityTypeEnum,
    payStatement: PayStatementData
  ) {
    const grossYearToDate = await getGrossYearToDateAmountById(
      payStatement,
      updatedEntityType
    );

    setPayStatementDetailsData((oldPayStatements) => {
      return {
        ...oldPayStatements,
        grossYearToDate,
        totalAmount: payStatement.amount,
      };
    });
  }

  async function getTripPaymentsList(
    updatedEntityType: PayStatementsEntityTypeEnum,
    payStatement: PayStatementData
  ) {
    try {
      const tripPaymentsList = await getTripPaymentsListById(
        payStatement,
        updatedEntityType,
        staticData
      );
      setPayStatementDetailsData((oldPayStatements) => {
        return { ...oldPayStatements, tripPaymentsList };
      });
    } catch (error) {}
  }

  async function getNonTripPaymentsList(
    updatedEntityType: PayStatementsEntityTypeEnum,
    payStatement: PayStatementData
  ) {
    const response = await getNonTripPaymentsListById(
      payStatement,
      staticData,
      updatedEntityType
    );
    setPayStatementDetailsData((oldPayStatements) => {
      return {
        ...oldPayStatements,
        fuelNonTripPaymentsList: response?.fuelPayStatementList,
        otherNonTripPaymentsList: response?.otherPayStatementList,
      };
    });

    return response;
  }

  async function fetchDetails(
    entity: PayStatementsEntityTypeEnum,
    payStatement: PayStatementData
  ) {
    getGrossYearToDateAmount(entity, payStatement);
    getTripPaymentsList(entity, payStatement);
    if (payStatement.payStatementType !== PayStatementsTypeEnum.dispatcher) {
      getNonTripPaymentsList(entity, payStatement);
    }
  }

  const fetchPaymentDetail = (item?: any) => {
    getPayStatementData(
      item?.id ?? data.id,
      item?.entityId ?? data.entityId,
      item?.entity ?? (data.entity as PayStatementsTypeEnum),
      item?.seqNumber ?? data.seqNumber,
      item?.updateSelectedRowDataRef ?? true
    );

    setPayStatementDetailsData(new PayStatementDetails({ id: data.id }));

    setSelectedPaymentsIds([]);
  };

  useEffect(() => {
    if (typeof data === 'object' && data?.entityId) {
      fetchPaymentDetail();
    }
  }, [data.id]);
  useEffect(() => {
    if (payStatementData.amount != data.amount) {
      onUpdated?.(payStatementData?.id, payStatementData.amount, false);
    }
  }, [payStatementData.amount]);

  const handleClose = () => {
    setPayStatementDetailsData(null);
    setSelectedPaymentsIds([]);
    onClose && onClose();
  };

  async function handleUpdate(payStatementDetails: PayStatementDetails) {
    const payments: PaymentDetails[] = [];
    if (!!payStatementDetails.fuelNonTripPaymentsList?.content?.length) {
      payments.push(
        ...payStatementDetails.fuelNonTripPaymentsList.content?.map?.((e) => ({
          ...e,
          entityId: e?.payToDetails?.entityId ?? null,
          entitiyName: e?.payToDetails?.entityName ?? null,
        }))
      );
    }
    if (!!payStatementDetails.otherNonTripPaymentsList?.content?.length) {
      payments.push(...payStatementDetails.otherNonTripPaymentsList.content);
    }
    const response = await bulkUpdateNonTripPayments(
      payments,
      payStatementData,
      entityType,
      staticData
    );
    if (response) {
      const nonTripPayments = await getNonTripPaymentsList(
        entityType,
        payStatementData
      );
      updateTotalAmount(nonTripPayments);
    }
  }

  const handleCheckboxChange = useCallback(
    (checked: boolean, ids: number[]) => {
      const filtered = selectedPaymentsIds.filter(
        (item) => !ids.includes(item)
      );
      if (checked) {
        setSelectedPaymentsIds([...filtered, ...ids]);
      } else {
        setSelectedPaymentsIds(filtered);
      }
    },
    [selectedPaymentsIds]
  );

  const handleSecondaryAction = (event?: any) => {
    switch (event?.actionName) {
      case BulkSettlementActions.CREATE_PAY_STATMENT:
        return setIsFormDialogOpen(true);
      default:
        setCurrentAction(event?.actionName);
        setIsInformationPopupOpen(true);
    }
  };

  const secondaryActionConfig = useMemo(
    () => getSecondaryActionConfig(entityType, !selectedPaymentsIds.length),
    [entityType, selectedPaymentsIds.length]
  );

  const handleCreateInReviewPayStatement = async (
    dialogData: CreateInReviewPayStatementType
  ) => {
    const response = await createInReviewPayStatement(
      {
        ...dialogData,
        ids: selectedPaymentsIds,
        status: PayStatementStatusEnum.IN_REVIEW,
      },
      payStatementData
    );

    if (response) {
      const { amount = 0 } = selectedTableRowDataRef.current;

      onUpdated && onUpdated(payStatementData?.id, amount - response?.amount);

      selectedTableRowDataRef.current = {
        ...selectedTableRowDataRef.current,
        amount: amount - response.amount,
      };

      const payStatement = {
        ...payStatementData,
        id: response.id,
        amount: response.amount,
        startDate: response.startDate,
        endDate: response.endDate,
        seqNumber: response.seqNumber,
        status: response?.status,
        entity: response?.entityType,
        entityType: response?.entityType,
        entityName: response?.entityName,
        entityId: response?.entityId,
      };
      setPayStatementData(payStatement);
      setEntityType(PayStatementsEntityTypeEnum.inReviewStatement);
      fetchDetails(PayStatementsEntityTypeEnum.inReviewStatement, payStatement);
      onAfterAction();
    }
    setIsFormDialogOpen(false);
  };

  const handleClosePayStatement = async (data?: any) => {
    payStatementData.paymentDate = data?.paymentDate ?? null;
    const response = await closePayStatement(payStatementData);

    if (response) {
      if (entityType === payStatementData.entity) {
        onRemoveTableRow && onRemoveTableRow(payStatementData.id);
      }
      setEntityType(PayStatementsEntityTypeEnum.closedStatement);
      onAfterAction();
    }
    setIsInformationPopupOpen(false);
    onUpdated && onUpdated();
  };

  const handleDeletePayStatement = async () => {
    const response = await deleteInReviewPayStatement([payStatementData]);

    if (response) {
      const { id, entity, amount = 0 } = selectedTableRowDataRef.current;
      if (entityType === entity) {
        onRemoveTableRow && onRemoveTableRow(payStatementData.id, true);
      } else {
        onUpdated?.(id, amount + payStatementData?.amount, true);
      }
      onClose && onClose();
      onAfterAction();
    }
    onUpdated && onUpdated();
  };

  const handleReopenPayStatement = async (status?: BulkSettlementActions) => {
    const toStatus = SettlementStatusByActionTypes[status];
    const response = await reopenInReviewPayStatement(
      payStatementData,
      toStatus
    );
    if (response) {
      setEntityType(PayStatementsEntityTypeMap[toStatus]);
      const { entity } = selectedTableRowDataRef.current;
      if (entityType === entity) {
        onRemoveTableRow && onRemoveTableRow(payStatementData.id);
      } else if (
        entity === PayStatementsEntityTypeMap[toStatus] ||
        currentTabEntity === PayStatementsEntityTypeMap[toStatus]
      ) {
        onAddTableRow?.(response);
      }
      onAfterAction();
    }
    onUpdated && onUpdated();
    fetchPaymentDetail({ ...response, updateSelectedRowDataRef: false });
  };

  const onAddPendingPayment = (selectedPayments: SelectedPaymentsType) => {
    const { id, entity, amount = 0 } = selectedTableRowDataRef.current;

    const total = getPayStatmentTotalAmount(selectedPayments);

    const updatedPayStatementAmount =
      total + (payStatementDetailsData?.totalAmount || 0);

    const updatedAmount =
      entityType === entity ? updatedPayStatementAmount : amount - total;
    getPayStatementData(
      payStatementData.id,
      payStatementData.payStatementId,
      payStatementData.payStatementType,
      payStatementData.seqNumber!
    );

    onUpdated && onUpdated(id, updatedAmount);
    selectedTableRowDataRef.current = {
      ...selectedTableRowDataRef.current,
      amount: updatedAmount,
    };
    onUpdated && onUpdated();
    onAfterAction();
  };

  const actionsRenderer = () => {
    return entityType !== PayStatementsEntityTypeEnum.pendingPayment ? (
      <ActionsSection
        entityType={entityType as PayStatementsEntityTypeEnum}
        onDeletePayStatement={handleDeletePayStatement}
        onReopenPayStatement={handleReopenPayStatement}
        payStatementData={payStatementData}
      />
    ) : (
      <></>
    );
  };
  const { t, ready } = useTranslation();
  const theme = useTheme();

  const handleUpdateSettlementStatus = async (data: any) => {
    try {
      payStatementData.toStatus =
        SettlementStatusByActionTypes[
          currentAction as keyof typeof SettlementStatusByActionTypes
        ];
      payStatementData.paymentDate = data?.paymentDate ?? null;
      const response = await updateSettlementStatus([payStatementData]);
      if (response?.length) {
        response[0].entity = response?.[0]?.entityType!;
        if (response?.[0]?.terminal)
          response[0].terminal.companyName = response?.[0]?.terminal?.name;
      }
      setEntityType(PayStatementsEntityTypeMap[payStatementData.toStatus]);
      // const { entity } = selectedTableRowDataRef.current;
      // if (entityType === entity) {
      //   onRemoveTableRow?.(payStatementData.id);
      // } else if (entity === currentTabEntity) {
      //   onAddTableRow?.(response?.[0]!);
      // }
      onReset?.();
      onAfterAction();
      setIsInformationPopupOpen(false);
      fetchPaymentDetail({ ...response?.[0], updateSelectedRowDataRef: false });
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName:
          GlobalNotificationsForStatusChange[currentAction as string],
      });
    } catch (error) {
      console.log(error);
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName:
          GlobalNotificationsForStatusChange[currentAction as string],
      });
    }
  };

  const getPayPeriodText = (): string => {
    return `${getFormattedDateForGrid(
      payStatementData?.startDate,
      UI_DATE_FORMAT
    )} - ${getFormattedDateForGrid(payStatementData?.endDate, UI_DATE_FORMAT)}`;
  };

  if (ready) {
    return (
      <>
        {payStatementDetailsData ? (
          <DetailsPanel
            data={payStatementDetailsData}
            panelTitle={`${
              payStatementData.payStatementType
                ? captilizeFirstChar(payStatementData.payStatementType)
                : '--'
            } : ${payStatementData.payStatementName ?? '--'} `}
            onClose={handleClose}
            onUpdate={handleUpdate}
            contentRenderer={() => (
              <PayStatementDetailsForm
                id={payStatementDetailsData.id}
                onAfterAction={onAfterAction}
                onTotalAmountUpdate={(
                  id: number,
                  totalAmount: number,
                  shouldUpdateTable: boolean
                ) => {
                  if (shouldUpdateTable && onUpdated) {
                    selectedTableRowDataRef.current = {
                      ...selectedTableRowDataRef.current,
                      amount: totalAmount,
                    };
                    onUpdated(id, totalAmount);
                  }
                }}
                onUpdated={(
                  id: number,
                  totalAmount: number,
                  shouldClosPanel?: boolean
                ) => {
                  onUpdated && onUpdated(id, totalAmount, shouldClosPanel);
                  selectedTableRowDataRef.current = {
                    ...selectedTableRowDataRef.current,
                    amount: totalAmount,
                  };
                }}
                staticData={staticData}
                payStatementData={payStatementData}
                onCheckboxChange={handleCheckboxChange}
                entityType={entityType}
                selectedTableRow={selectedTableRowDataRef.current}
                canAddPendingPaymentItem={
                  canAddPendingPaymentItem && !DirtyDetailsPanelManager.isDirty
                }
                onAddPendingPayment={onAddPendingPayment}
                isUpdated={() => {
                  fetchPaymentDetail();
                }}
              />
            )}
            validationSchema={payStatementDetailsFormValidationSchema}
            actionButtonLabel="Save Changes"
            isSaveIconVisible={true}
            showDeleteButton={false}
            renderCustomTitle={() => <PayStatementStatus status={entityType} />}
            actionsRenderer={actionsRenderer}
            isGlobal={isGlobal}
            secondaryActions={
              secondaryActionConfig?.map?.((e) => {
                e.onSecondaryAction = () => handleSecondaryAction(e);
                return e;
              }) ?? []
            }
          />
        ) : null}
        {!!isFormDialogOpen && (
          <FormDialog
            data={{
              startDate: staticData.payStatementDates.startDate,
              endDate: staticData.payStatementDates.endDate,
            }}
            titleText={t('settlementPeriod')}
            actionLabel="Create"
            actionLabelId="createButton"
            open={!!isFormDialogOpen}
            onAction={handleCreateInReviewPayStatement}
            handleClose={() => setIsFormDialogOpen(false)}
            contentRenderer={() => <CreateInReviewPayStatementForm />}
            validationSchema={createInReviewPayStatementValidationSchema}
          />
        )}
        {isInformationPopupOpen &&
          (currentAction === BulkSettlementActions.CLOSE_PAY_STATMENT ? (
            <FormProvider {...state}>
              <CloseBulkSettlements
                onClose={() => {
                  setIsInformationPopupOpen(false);
                  setCurrentAction(null);
                }}
                onAction={handleClosePayStatement}
                payPeriodText={getPayPeriodText()}
                seqNumber={payStatementData?.seqNumber}
              />
            </FormProvider>
          ) : (
            <DeletePopup
              open={!!isInformationPopupOpen}
              {...SettlementStatusChangePopupConfig(theme)?.[currentAction!]}
              onAction={handleUpdateSettlementStatus}
              onClose={() => {
                setCurrentAction(null);
                setIsInformationPopupOpen(false);
              }}
              width="450px"
            />
          ))}
      </>
    );
  }
}

export default observer(PaymentsDetailsPanel);
