import { SearchOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { carrierService } from '../../../../../../api';
import TextField from '../../../../../../common/Ui/TextField/TextField';
import {
  ELookupExternalCarrierType,
  LookupExternalCarrierItem,
} from '../../../../../../models/DTOs/Carrier/Requests';
import {
  StyledButton,
  StyledButtonGroup,
  getStyledAddNewCarrierForm,
} from './styles';

const SearchItem = ({
  data,
  onClick,
}: {
  data: LookupExternalCarrierItem;
  onClick: () => void;
}) => {
  const styles = getStyledAddNewCarrierForm();
  return (
    <Stack spacing={0.5} sx={styles.SearchItemRenderItem} onClick={onClick}>
      <Typography variant="h7" sx={styles.SearchItemRenderItemName}>
        {data.name || '-'}
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="body3" sx={styles.SearchItemRenderItemBody3}>
          <Box sx={styles.SearchItemRenderItemBody3Bold} component={'span'}>
            DOT:
          </Box>
          {data.dot || '-'}
        </Typography>
        <Typography variant="body3" sx={styles.SearchItemRenderItemBody3}>
          <Box sx={styles.SearchItemRenderItemBody3Bold} component={'span'}>
            MC:
          </Box>
          {data.mc || '-'}
        </Typography>
      </Stack>
      <Typography variant="body3" sx={styles.SearchItemRenderItemBody3}>
        <Box sx={styles.SearchItemRenderItemBody3Bold} component={'span'}>
          Address:
        </Box>
        {data.address || '-'}
      </Typography>
    </Stack>
  );
};
interface ISearchFormData {
  search: string;
}
interface IMenuTab {
  name: ELookupExternalCarrierType;
  label: string | JSX.Element;
  displayText: string;
}
const menuTabs: IMenuTab[] = [
  {
    name: ELookupExternalCarrierType.DOT,
    label: 'DOT',
    displayText: 'DOT',
  },
  {
    name: ELookupExternalCarrierType.MC,
    label: 'MC',
    displayText: 'MC',
  },
  {
    name: ELookupExternalCarrierType.NAME,
    label: <Typography sx={{ textTransform: 'capitalize' }}>Name</Typography>,
    displayText: 'Name',
  },
];

interface SearchFormProps {
  onSelected: (item: LookupExternalCarrierItem) => void;
}
export const SearchForm = ({ onSelected }: SearchFormProps) => {
  const formData: ISearchFormData = {
    search: '',
  };
  const state = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: formData,
  });
  const { control, handleSubmit } = state;
  const styles = getStyledAddNewCarrierForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [externalCarrierDataList, setExternalCarrierDataList] = useState<
    LookupExternalCarrierItem[]
  >([]);
  const [selectedMenuTab, setSelectedMenuTab] = useState<any>(menuTabs[0]);

  const onSearchHandler = async ({ search }: ISearchFormData) => {
    setExternalCarrierDataList([]);
    setIsLoading(true);
    const response = await carrierService.lookupExternalCarrier(
      search,
      selectedMenuTab.name
    );
    if (response instanceof Array<LookupExternalCarrierItem>) {
      setExternalCarrierDataList(response);
    } else {
      setExternalCarrierDataList([]);
    }
    setIsLoading(false);
    setIsSubmitted(true);
  };

  const showSearchResult =
    (isSubmitted && !isLoading) || externalCarrierDataList.length;

  return (
    <Box sx={{ width: '100%' }}>
      <StyledButtonGroup
        fullWidth
        color="primary"
        aria-label="Enter your DOT or MC to autofill relevant information"
      >
        {menuTabs.map((item: IMenuTab) => (
          <StyledButton
            key={item.name}
            isSelected={selectedMenuTab.name === item.name}
            onClick={() => {
              setSelectedMenuTab(item);
              setExternalCarrierDataList([]);
              setIsSubmitted(false);
            }}
          >
            {item.label}
          </StyledButton>
        ))}
      </StyledButtonGroup>
      <FormProvider {...state}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
          pt={2}
          flex={'1 auto'}
        >
          <Box className={'search-btn'} width={'100%'}>
            <TextField
              control={control}
              name={`search`}
              label={`Enter ${selectedMenuTab.displayText}`}
              variant={'outlined'}
              style={{ paddingLeft: '0 !important' }}
              styleProps={{
                ...styles.TextField,
                ...(showSearchResult && styles.TextFieldHasSearchResult),
              }}
              size={'small'}
            />
            {showSearchResult ? (
              <Stack
                className="SearchItemRenderRoot"
                spacing={0}
                divider={
                  <Divider
                    orientation="horizontal"
                    flexItem
                    sx={styles.Divider}
                  />
                }
                sx={styles.SearchItemRenderRoot}
              >
                {isSubmitted &&
                  !isLoading &&
                  !externalCarrierDataList.length && (
                    <Typography
                      variant="caption"
                      sx={styles.SearchItemRenderNoResult}
                    >
                      Search did not match any carriers.
                    </Typography>
                  )}
                {externalCarrierDataList.map(
                  (item: LookupExternalCarrierItem, idx: number) => (
                    <SearchItem
                      key={idx}
                      data={item}
                      onClick={() => onSelected(item)}
                    />
                  )
                )}
              </Stack>
            ) : (
              <></>
            )}
          </Box>
          <LoadingButton
            sx={styles.Button}
            type="submit"
            loading={isLoading}
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit(onSearchHandler)}
          >
            <SearchOutlined />
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Box>
  );
};
