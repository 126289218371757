import TextField, {
  TextFieldProps,
  TextFieldVariants,
} from '@mui/material/TextField';
import React from 'react';
import NumberFormat, {
  InputAttributes,
  NumberFormatProps,
} from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  numberFormatProps: NumberFormatProps;
}

const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, numberFormatProps, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      allowNegative={false}
      allowEmptyFormatting={false}
      isNumericString
      thousandSeparator
      prefix="$"
      {...numberFormatProps}
    />
  );
});
export interface CurrencyFieldProps extends TextFieldProps<TextFieldVariants> {
  numberFormatProps?: NumberFormatProps;
}
export const CurrencyField = (props: CurrencyFieldProps) => {
  const { numberFormatProps, ...restProps } = props;
  return (
    <TextField
      {...restProps}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        inputProps: { numberFormatProps },
      }}
    />
  );
};
