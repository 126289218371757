import * as yup from 'yup';
import { ReeferModeEnum } from '../../../../subPages/loadsList/CreateLoad/schema/load';
import {
  commodityValidationSchema,
  loadDetailsValidationRules,
  temperatureValidationRules,
} from '../../../../subPages/loadsList/LoadDetailsPanel/constants/constants';

export const loadDetailsValidationSchema = yup
  .object()
  .shape({
    commodityData: yup
      .array()
      .nullable()
      .of(commodityValidationSchema)
      .compact(),
  })
  .shape(loadDetailsValidationRules)
  .shape(temperatureValidationRules, [
    ['lowerLimitTemperature', 'upperLimitTemperature'],
    ['upperLimitTemperature', 'lowerLimitTemperature'],
    ['upperLimitTemperature', 'upperLimitTemperature'],
    ['lowerLimitTemperature', 'lowerLimitTemperature'],
  ]);

export const loadAdditionalDetailsDefaultData = {
  id: null,
  totalWeight: '',
  loadType: 'FTL',
  loadStatus: '',
  notes: '',
  noteDetails: null,
  sealNumber: '',
  containerNumber: '',
  lowerLimitTemperature: '',
  upperLimitTemperature: '',
  commodityData: null,
  equipmentTypes: [],
  temperature: null,
  tempVariance: null,
  reeferMode: 'CONTINUOUS' as ReeferModeEnum,
};
