import { useForm } from 'react-hook-form';
import EntityNotesActions from '@/common/EntityNotes/EntityNotesActions';
import { NoteEntityType, NoteFormType } from '@/common/EntityNotes/utils/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { entityNoteFormValidationSchema } from '@/common/EntityNotes/constants';

export const useNoteForm = (
  getEntityNotes: () => void,
  entityId: string,
  entityType: NoteEntityType,
  defaultValues: {
    note: string;
    attachment: { documentId: string; fileName: string }[] | null;
    id?: string;
    fileChanged?: boolean;
  }
) => {
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(entityNoteFormValidationSchema),
  });

  const [currentNote, fileChanged] = watch(['note', 'fileChanged']);

  const handleCreateNote = async (note: NoteFormType) => {
    const result = await EntityNotesActions.createEntityNote(
      note,
      entityType,
      entityId
    );
    if (result) {
      if (note.attachment) {
        const document = await EntityNotesActions.createDocument(
          note.attachment
        );
        if (document) {
          await EntityNotesActions.assignDocument(
            document[0],
            result,
            entityType
          );
        }
      }
      getEntityNotes();
    }
  };

  const handleUpdateNote = async (note: NoteFormType) => {
    const result = await EntityNotesActions.updateEntityNote(
      note,
      entityType,
      entityId
    );
    if (result) {
      if (fileChanged) {
        if (defaultValues.attachment) {
          EntityNotesActions.deleteDocument(
            defaultValues.attachment?.[0].documentId
          );
        }
        if (note.attachment) {
          const document = await EntityNotesActions.createDocument(
            note.attachment
          );
          if (document) {
            await EntityNotesActions.assignDocument(
              document[0],
              result,
              entityType
            );
          }
        }
      }
      getEntityNotes();
    }
  };

  const onSubmit = async (data: any) => {
    if (data.id) {
      await handleUpdateNote(data);
    } else {
      await handleCreateNote(data);
    }
  };

  const handleFileChange = () => setValue('fileChanged', true);

  return {
    handleSubmit,
    onSubmit,
    control,
    currentNote,
    handleFileChange,
  };
};
