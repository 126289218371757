/* @TODO this file should be rewritten */

export const main = {
  height: '100%',
  width: '100%',
  padding: '0 48px',
  borderRadius: '14px',
};
export const container = {
  textAlign: 'center',
  width: '100%',
};

export const fileContainer = {
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  canvas: {
    width: '100% !important',
    height: '100% !important',
  },
};

export const fileViewer = {
  display: 'flex',
  height: '100%',
  alignItems: 'center',
};

export const fileNameContainer = {
  display: 'flex',
  justifyContent: 'center',
  margin: '25px 0',
};

export const fileName = {
  color: 'rgba(255, 255, 255, 0.75)',
  padding: '0 17px',
  wordBreak: 'break-word',
};

export const iconColor = '#669EFF';
export const imageIconColor = 'rgba(255, 255, 255, 0.75)';
