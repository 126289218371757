import { Type } from 'class-transformer';
import { QueryParams } from '../commonMixed/QueryParams';

export class IFTAReportsRequest extends QueryParams {}

export class IFTAPdfRequest extends QueryParams {
  dot?: string;
  year?: number;
  quarter?: number;
  fuelType?: string;
}

export class IFTAPdfResult {
  contentType?: string;
  fileContent?: string;
  fileName?: string;
}

export class IFTAReportDTO {
  netTaxOwed?: number;
  quarter!: number;
  status!: string;
  year!: number;
}

export class IFTAReportsResult {
  @Type(() => IFTAReportDTO)
  reportCards?: IFTAReportDTO[];
}
