import { Typography } from '@mui/material';
import React from 'react';

function SummaryColumn({
  numericColumnField,
  tableData,
  columnTotalData,
}: any) {
  const [sum, setSum] = React.useState(0);

  React.useEffect(() => {
    let sum = 0;
    if (columnTotalData && columnTotalData[numericColumnField.field]) {
      sum = columnTotalData[numericColumnField.field];
    } else {
      tableData.forEach((item: any) => {
        sum += item[numericColumnField.field];
      });
    }
    setSum(sum);
  }, [tableData]);

  return (
    <>
      <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
        {numericColumnField.field} : {sum}
      </Typography>
    </>
  );
}

export default SummaryColumn;
