import {
  CreateTerminalDTO,
  DeleteTerminalDTO,
  DeleteTerminalLogoDTO,
  GetLogoDTO,
  GetTerminalListDTO,
  GetUserAssociatedTerminalsRequest,
  QueryParams,
  TerminalContentDTO,
  TerminalListDTO,
  TerminalListResponse,
  UpdateTerminalDTO,
} from '../../models';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { ITerminalService } from '../interfaces/ITerminalService';

import StorageManager from '../../StorageManager/StorageManager';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { UpdateTerminalModeRequest } from '../../models/DTOs/Terminal/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { getTerminalsURL } from './requestConstants';

const getTerminalURL = '/web/preference/api/v2/preferences/terminals/list';
const getUserAssociatedTerminalURL =
  '/web/preference/api/v2/preferences/terminals/list/user-terminals/dropdown';
const CRDTerminalURL = '/web/preference/api/v2/preferences/terminals';
const updateTerminalModeURL =
  '/web/preference/api/v2/preference/company/edit/terminal-support';
const getTerminalLogoURL = '/web/afs/api/v2/companyLogo/get';
const postTerminalLogoURL = '/web/afs/api/v2/companyLogo/create';
const deleteTerminalLogoURL = '/web/afs/api/v2/companyLogo/delete';

export class TerminalService extends ITerminalService {
  @annotateNameAsync
  async getTerminalList(requestData: GetTerminalListDTO) {
    try {
      return await httpClient.get<TerminalListDTO>(
        getTerminalURL,
        requestData,
        TerminalListDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getTerminals() {
    try {
      const requestData = new QueryParams();
      requestData.addUserIdToParams();
      return await httpClient.get(
        getTerminalsURL,
        requestData,
        TerminalListResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  async createTerminal(
    payloadData: CreateTerminalDTO
  ): Promise<TerminalContentDTO | null | ServiceError> {
    try {
      const response = await httpClient.post<TerminalContentDTO>(
        CRDTerminalURL,
        undefined,
        payloadData,
        TerminalContentDTO,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createTerminal',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      const errorObj = this.getServiceError(composedError);
      if (errorObj) {
        if (
          errorObj?.error?.response?.data?.apierror?.message ==
          'terminal exist or not unique, or not valid fields'
        )
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'duplicateTerminal',
          });
        else
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'createTerminal',
          });
        return errorObj;
      }
      return Promise.resolve(null);
    }
  }

  async updateTerminal(
    payloadData: UpdateTerminalDTO
  ): Promise<TerminalContentDTO | null | ServiceError> {
    try {
      const response = await httpClient.put<TerminalContentDTO>(
        CRDTerminalURL,
        undefined,
        payloadData,
        TerminalContentDTO,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateTerminal',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      const errors = this.getServiceError(composedError);
      if (errors) {
        if (
          errors?.error?.response?.data?.apierror?.message ==
          'terminal exist or not unique, or not valid fields'
        )
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'duplicateTerminal',
          });
        else
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'updateTerminal',
          });
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async deleteTerminal(
    payloadData: DeleteTerminalDTO
  ): Promise<null | ServiceError> {
    try {
      await httpClient.deleteRaw(CRDTerminalURL, payloadData, null);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteTerminal',
      });
      return null;
    } catch (error: any) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'deleteTerminal',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      } else {
        const errorMessage =
          error?.response?.data?.apierror?.subErrors?.[0]?.message;
        if (errorMessage) {
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'deleteTerminal',
          });
          throw new Error('Failed to delete terminal.');
        }
      }
      return Promise.resolve(null);
    }
  }

  async deleteTerminalLogo(payloadData: DeleteTerminalLogoDTO) {
    try {
      const deleteRes = await httpClient.deleteRaw(
        deleteTerminalLogoURL,
        payloadData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteTerminalLogo',
      });
      return deleteRes;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async updateTerminalMode(payloadData: UpdateTerminalModeRequest) {
    try {
      return await httpClient.put<any>(
        updateTerminalModeURL,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;

        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getLogoForTerminal(requestData: GetLogoDTO) {
    try {
      const logoData = await httpClient.get<any>(
        getTerminalLogoURL,
        requestData,
        QueryParams,
        true,
        true
      );
      return logoData;
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  async postTerminalLogoData(requestData: GetLogoDTO, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    try {
      const postResponse = await httpClient.post(
        postTerminalLogoURL,
        requestData,
        formData,
        QueryParams,
        false,
        headers
      );
      return postResponse;
    } catch (error) {
      return Promise.resolve(null);
    }
  }
  async getUserAssociatedTerminalsList(
    requestData: GetUserAssociatedTerminalsRequest
  ) {
    try {
      const storageUserData = StorageManager.getUser();
      requestData.loggedUserId = storageUserData.id;
      const response = await httpClient.getRaw(
        getUserAssociatedTerminalURL,
        requestData
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
