import React, { useEffect, useRef, useState } from 'react';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import '@trimblemaps/trimblemaps-js/trimblemaps.css';
import '@trimblemaps/trimblemaps-singlesearch/trimblemaps-singlesearch.css';
import TrimbleDraw from '@trimblemaps/trimblemaps-draw';
import '@trimblemaps/trimblemaps-draw/trimblemaps-draw.css';
import constants from '../Ui/Maps/constants';
import { addressType } from '../Ui/AddressField/constants';
import { Box, Button, ButtonGroup } from '@mui/material';
import { FormAddress } from '../Ui/AddressField';
import { useFormContext } from 'react-hook-form';
import fetchAddress from '../../utils/fetchAddress';
import {
  propsTypeTrimblemaps,
  center,
  mapButtonDeleteStyles,
  textInputSxProps,
  mapButtonStyles,
} from './GeoCodingCustomStyle';
import { useTranslation } from 'react-i18next';

TrimbleMaps.APIKey = constants.trimbleMapsApiKey as string;

const Trimblemaps = ({
  addressData,
  getGeoFenceData,
  getGeoCodingData,
  readOnly,
  valueName,
}: propsTypeTrimblemaps) => {
  const mapRef = useRef<any>(null);

  const [address, setAddressData] = useState<addressType>();
  const [mapViewMode, setMapViewMode] = useState<string>('transportation');
  const { control, setValue } = useFormContext();
  const controlContainer = document.getElementsByClassName(
    'trimblemaps-control-container'
  );
  const compact = document.getElementsByClassName('trimblemaps-compact');

  useEffect(() => {
    setAddressData(addressData);
  }, [addressData]);

  const geoCoordinatesModify = (data: any) => {
    const mapObject = data?.map((x: Array<string>) => {
      if (x) return { coordinates: x };
    });
    return mapObject;
  };
  const geoCoordinatesModifyPayload = (data: any) => {
    const mapObject = data?.map((x: { coordinates: Array<string> }) => {
      if (x?.coordinates) return x.coordinates;
    });
    return mapObject;
  };

  useEffect(() => {
    const lat: number | null = address?.center?.lat || null;
    const lng: number | null = address?.center?.lng || null;
    const geoCoordinates = address?.geoCoordinates
      ? geoCoordinatesModifyPayload(address?.geoCoordinates)
      : [];
    const map = new TrimbleMaps.Map({
      container: mapRef.current,
      style: mapViewMode,
      center: lat && lng ? [readOnly ? lng : lng - 0.006, lat] : [-77.38, 39],
      zoom: lat && lng ? (readOnly ? 12 : 14) : 3,
    });
    if (!lat && !lng) return;

    // const div = creatPopupNodeElement();
    const popup = new TrimbleMaps.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 40,
      className: 'popupStyle',
    });
    const marker = new TrimbleMaps.Marker({
      draggable: true,
    })
      .setLngLat([lng, lat])
      .setPopup(popup)
      .addTo(map);
    if (!readOnly) {
      marker.togglePopup('dragend');
    }
    const draw = new TrimbleDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      style: mapViewMode,
    });
    marker.on('dragend', function (e: any) {
      const { lng, lat } = e.target.getLngLat();
      getGeoCodingData?.({ lng, lat });
      getAddressDetails({ lng, lat });
    });
    map.addControl(
      new TrimbleMaps.NavigationControl({ showCompass: false }),
      'bottom-right'
    );
    map.on('draw.create', geofence);
    map.on('draw.update', geofence);
    map.on('draw.delete', geofence);
    map.addControl(draw, 'top-right');
    map.on('load', () => {
      if (geoCoordinates.length > 0) {
        draw.add({
          id: 'maine',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [geoCoordinates],
          },
        });
      }
    });
    updateStyle();
    compact[compact.length - 1].style.display = 'none';
    return () => {
      marker.remove();
      map.removeControl(draw, 'top-right');
      map.remove();
    };
  }, [address, mapViewMode, address?.geoCoordinates]);

  const geofence = (event: any) => {
    getGeoFenceData?.(
      geoCoordinatesModify(event.features[0].geometry.coordinates[0])
    );
  };

  const updateStyle = () => {
    if (readOnly) {
      mapRef.current.style.pointerEvents = 'none';
      mapRef.current.style.cursor = 'default';
      controlContainer[controlContainer.length - 1].style.display = 'none';
      controlContainer.forEach((box) => {
        box.style.display = 'none';
      });
    }
  };

  const getAddressDetails = async (data: center) => {
    const queryString = `${data.lat},${data.lng}`;
    const locations = await fetchAddress(queryString);
    if (locations) {
      const newAddress: addressType = {
        fullAddress: locations[0]?.ShortString || '',
        address: locations[0]?.ShortString,
        city: locations[0]?.Address?.City,
        state: locations[0]?.Address?.State,
        streetAddress: locations[0]?.Address?.StreetAddress,
        streetAddress2: '',
        geoCoordinates: address?.geoCoordinates,
        center: {
          lat: locations[0]?.Coords.Lat,
          lng: locations[0]?.Coords.Lon,
        },
        zipcode: locations[0]?.Address?.Zip,
      };
      setValue(valueName, newAddress);
    }
  };

  const updatedAddress = async (data: addressType) => {
    if (data?.address && address) {
      let addressData = address;
      addressData = data;
      addressData.geoCoordinates = address.geoCoordinates;
      setAddressData(addressData);
      const lng = addressData.center?.lng;
      const lat = addressData.center?.lat;
      getGeoCodingData?.({ lng, lat });
      getGeoFenceData?.(geoCoordinatesModify(address.geoCoordinates));
    }
    setTimeout(() => {
      mapRef.current.children[
        mapRef.current.children.length - 1
      ].style.display = '';
    });
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <>
        {!readOnly && (
          <>
            <Box
              sx={mapButtonDeleteStyles}
              onClick={() => {
                if (!address) return;
                const addressData = { ...address };
                addressData.geoCoordinates = [];
                getGeoFenceData?.([]);
                setAddressData(addressData);
              }}
            ></Box>
            <Box
              sx={{
                position: 'absolute',
                zIndex: '3',
                background: 'white',
                width: '35%',
                marginTop: '17px',
                marginLeft: '13px',
                borderRadius: 5,
                p: '0px 10px',
              }}
            >
              <FormAddress
                control={control}
                name={valueName}
                sizes={{ xs: 12 }}
                textInputSxProps={textInputSxProps}
                onChangeCallback={(data: addressType) => {
                  if (data?.address && address) {
                    updatedAddress(data);
                  }
                }}
              />
            </Box>
          </>
        )}

        <Box id="mapLocation" ref={mapRef}></Box>
        {!readOnly && (
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            sx={mapButtonStyles}
          >
            <Button
              variant={
                mapViewMode === 'transportation' ? 'contained' : 'outlined'
              }
              onClick={() => setMapViewMode('transportation')}
              sx={{ ml: '0px !important' }}
            >
              {t('TripAddNewLoadOption3Map')}
            </Button>
            <Button
              variant={mapViewMode === 'satellite' ? 'contained' : 'outlined'}
              onClick={() => setMapViewMode('satellite')}
              sx={{ ml: '0px !important' }}
            >
              {t('TripAddNewLoadOption3Satellite')}
            </Button>
          </ButtonGroup>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default Trimblemaps;
