import { Box, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import FormDatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';
import { MultipleAutocompleteForm } from '../../../../common/Ui/MultipleAutocomplete';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import Switch from '../../../../common/Ui/Switcher';
import { tripFieldsConfig } from '../../constants/constants';
import { IFiltersFormConfig } from '../../constants/types';
import TripGridConfig from '../TripDataGrid/grid.config';

export function UserColumnsForm() {
  const theme = useTheme();
  const gridConfig = new TripGridConfig({ theme });
  const disabledFields = [
    tripFieldsConfig.tripId.fieldName,
    tripFieldsConfig.warning.fieldName,
  ];

  const columns = gridConfig.config.columns.map((col) => {
    return {
      name: col.field,
      label: col.headerName || col.field,
      show: true,
      disabled: disabledFields.indexOf(col.field) > -1,
    };
  });
  const { control } = useFormContext();
  return (
    <>
      {columns.map((column, i) => (
        <Switch
          style={{ display: column.label === 'warning' ? 'none' : 'block' }}
          key={i}
          label={column.label || ''}
          name={`columnFilters[${column.name}]`}
          control={control}
          disabled={column.disabled}
          defaultChecked={true}
        />
      ))}
    </>
  );
}

export function UserMetaDataForm() {
  const { control } = useFormContext();
  const theme = useTheme();
  const gridConfig = new TripGridConfig({ theme });
  const filtersFormConfig = gridConfig.config.getFiltersFormConfig();
  return (
    <Box
      sx={{
        marginTop: '15px',
      }}
    >
      {filtersFormConfig
        // .filter((config: IFiltersFormConfig) => config.isPopupFilter)
        .map((config: IFiltersFormConfig) => {
          if (config.type === 'MultipleAutocomplete') {
            return (
              <div key={`filter-form-${config.name}`}>
                <MultipleAutocompleteForm
                  key={config.key}
                  control={control}
                  name={`metaData[${config.name}]`}
                  fieldName={config.fieldName}
                  getOptions={config.getData}
                  label={config.label}
                  variant="standard"
                />
              </div>
            );
          }
          if (config.type === 'SingleAutocomplete') {
            return (
              <div key={`filter-form-${config.name}`}>
                <SingleAutocompleteForm
                  control={control}
                  name={`metaData[${config.name}]`}
                  fieldName={config.fieldName as string}
                  label={config.label}
                  options={config.options}
                />
              </div>
            );
          }
          if (config.type === 'DatePicker') {
            return (
              <div key={`filter-form-${config.name}`}>
                <FormDatePicker
                  control={control}
                  name={`metaData[${config.name}]`}
                  disabledText={false}
                  label={config.label}
                  sizes={{ xs: 12 }}
                />
              </div>
            );
          }
        })}
    </Box>
  );
}
