export const RECOMMENDATION_CONFLICT_STATUS = {
  RECOMMENDED_ASSIGNMENT: 'Recommended Assignment',
  RECOMMENDED: 'recommended to',
  LOCKED: 'locked to',
  RECOMMENDED_BROKERED: 'is recommended for brokerage',
} as { [key: string]: string };

export const OPTIMAL_RECOMMENDATION_CONFLICT_STATUS = {
  RECOMMENDED_ASSIGNMENT: 'Recommended Assignment',
  RECOMMENDED: 'Recommended to',
  LOCKED: 'Locked to',
} as { [key: string]: string };

export const BROKERED_TRIP_KPI_CONFIG = [
  {
    val: 'revenue',
    prefixLabel: '$',
    secondaryText: 'Revenue',
    primaryTextStyles: { color: '#3B873E', fontSize: '14px' },
    containerStyles: { width: '100%' },
  },
  {
    val: 'commission',
    prefixLabel: '$',
    secondaryText: 'Commission',
    primaryTextStyles: { fontSize: '14px' },
    containerStyles: { width: '100%' },
  },
  {
    val: 'carrierPay',
    prefixLabel: '$',
    secondaryText: 'Carrier Pay',
    primaryTextStyles: { fontSize: '14px' },
    containerStyles: { width: '100%' },
  },
];

export const POSSIBLE_SOLUTIONS_WARNING_TYPE = {
  DRIVER_FIRST: 'DRIVER_FIRST',
  BROKERED_TRIP: 'BROKERED_TRIP',
};
