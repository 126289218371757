export enum TripStopTypeEnum {
  Pickup = 'Pickup',
  Stop = 'Stop',
  DropOff = 'DropOff',
  RelayStart = 'RelayStart',
  RelayEnd = 'RelayEnd',
  PickupTripStop = 'PickupTripStop',
  Break = 'Break',
  PreTripInspection = 'PreTripInspection',
  AppointmentWork = 'AppointmentWork',
  PickupTripStopOilChange = 'PickupTripStopOilChange',
  PickupTripStopRest = 'PickupTripStopRest',
  Relay = 'Relay',
}

export enum TripStopTypeTitleEnum {
  Pickup = 'Pickup',
  Stop = 'Stop',
  DropOff = 'DropOff',
  RelayStart = 'Relay Start',
  RelayEnd = 'Relay End',
  PickupTripStop = 'Pickup',
  Break = 'Break',
  PreTripInspection = 'Pre trip Inspection',
  AppointmentWork = 'Appointment Work',
  PickupTripStopOilChange = 'Oil Change',
  PickupTripStopRest = 'Rest',
  Relay = 'Relay',
}

export enum TripStopStatusEnum {
  NONE = 'NONE',
  EN_ROUTE = 'EN_ROUTE',
  ARRIVED = 'ARRIVED',
  ACTIVITY_STARTED = 'ACTIVITY_STARTED',
  ACTIVITY_ENDED = 'ACTIVITY_ENDED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum TripStopActivityEnum {
  PICKUP = 'PICKUP',
  DROPOFF = 'DROPOFF',
  PICKUP_AND_DROPOFF = 'PICKUP_AND_DROPOFF',
}

export enum TripStopActivityTitleEnum {
  PICKUP = 'Pickup',
  DROPOFF = 'DropOff',
  PICKUP_AND_DROPOFF = 'Pickup & DropOff',
}

export enum TripViewMode {
  DefaultView = 'DefaultView',
  TripStopView = 'TripStopView',
  DetailViewHoSView = 'DetailViewHoSView',
}

export enum TripViewV3Mode {
  DefaultView = 'DefaultView',
  HOSEventView = 'HOSEventView',
}
