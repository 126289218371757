import moment from 'moment';
import { LineItem } from './models';

export const getValidToDateByFrequency = (
  date: string,
  documentLineItem?: LineItem
) => {
  let frequencyNumber: number | null = null;
  switch (documentLineItem?.frequency) {
    case 'YEARLY':
      frequencyNumber = 12;
      break;

    case 'EVERY_6_MONTHS':
      frequencyNumber = 6;
      break;

    case 'EVERY_3_MONTHS':
      frequencyNumber = 3;
      break;

    case 'EVERY_30_DAYS':
      frequencyNumber = 1;
      break;

    default:
      break;
  }

  return frequencyNumber
    ? moment(date).add(frequencyNumber, 'months')
    : moment(date).add(documentLineItem?.frequencyValue, 'days');
};
