import { Box, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission';
import { ExpenseCategory, PaymentType } from '../../../../models';
import { GetFinancialItemRequest } from '../../../../models/DTOs/FinanceLoadService';
import { UserDetails } from '../../../../models/DTOs/user/User';
import TractorPaymentTermForm from '../../../equipment/TractorPaymentTerm/TractorPaymentTermForm';
import PaymentTermFormWithProvider from './PaymentTermForm';
import { getAllPaymentTypes, getExpenseCategories } from './utils';

export enum EPaymentTermTabs {
  webUser = 'webUser',
  driver = 'driver',
}

export interface IPaymentTermDataDependency {
  allPaymentTypes: PaymentType[];
  expenseCategories: ExpenseCategory[];
}

export default function PaymentTermTabs({
  expanded,
  data,
  setUserDetails,
  handleChangeShowDetailsTab,
  onChangeUserType,
  paymentTabUserType,
  inactive,
}: {
  expanded: boolean;
  data: UserDetails;
  handleChangeShowDetailsTab: (state: boolean) => void;
  setUserDetails: (value: React.SetStateAction<UserDetails>) => void;
  paymentTabUserType?: EPaymentTermTabs;
  onChangeUserType: (selectedTab: EPaymentTermTabs) => void;
  inactive: boolean;
}) {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [allPaymentTypes, setAllPaymentTypes] = useState<PaymentType[]>([]);
  const [ispaymentTabUserType, setPaymentTabUserType] =
    useState<string>(paymentTabUserType);
  const [expenseCategories, setExpenseCategories] = useState<ExpenseCategory[]>(
    []
  );

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: EPaymentTermTabs
  ) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;

    setPaymentTabUserType(newValue);
    onChangeUserType(newValue);
  };

  const fetchDependencydata = async (): Promise<void> => {
    const requestData = new GetFinancialItemRequest(true);
    const _allPaymentTypes: PaymentType[] = await getAllPaymentTypes(
      requestData
    );
    setAllPaymentTypes(_allPaymentTypes);

    const _expenseCategories: ExpenseCategory[] = await getExpenseCategories();
    setExpenseCategories(_expenseCategories);

    setIsLoaded(true);
  };
  const userPaymentTermPermission = {
    add: AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERM_ADD,
    remove: AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERM_REMOVE,
    view: AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERMS_VIEW,
    edit: AXELE_PERMISSION_TYPE?.USER_PAYMENT_TERM_EDIT,
  };
  useEffect(() => {
    if (data.driveStatus === 0) {
      onChangeUserType(EPaymentTermTabs.webUser);
      setPaymentTabUserType(EPaymentTermTabs.webUser);
    }
    fetchDependencydata();
  }, []);

  if (!isLoaded) {
    return (
      <Stack
        direction="column"
        sx={{ height: '100%', width: '100%' }}
        spacing={2}
      >
        {[...new Array(18)].map((index) => (
          <Skeleton
            key={index}
            height="2rem"
            width="100%"
            variant="rectangular"
          />
        ))}
      </Stack>
    );
  }

  return (
    <Box>
      {data.driveStatus === 2 && (
        <Tabs
          value={paymentTabUserType}
          onChange={handleChange}
          sx={{
            '& .MuiTabs-flexContainer ': {
              textAlign: 'center !important',
              display: 'block !important',
            },
          }}
        >
          <Tab
            sx={{ width: '50%' }}
            label="Driver"
            value={EPaymentTermTabs.driver}
          />
          <Tab
            sx={{ width: '50%' }}
            label="Web User"
            value={EPaymentTermTabs.webUser}
          />
        </Tabs>
      )}

      {data.driveStatus === 2 &&
        isLoaded &&
        ispaymentTabUserType === EPaymentTermTabs.driver && (
          <PaymentTermFormWithProvider
            //dependency data should request once only
            dataDependency={{
              allPaymentTypes,
              expenseCategories,
            }}
            //dependency data should request once only
            expanded={expanded}
            data={data}
            handleChangeShowDetailsTab={handleChangeShowDetailsTab}
            setUserDetails={setUserDetails}
            inactive={inactive}
          />
        )}
      {data.driveStatus === 2 &&
        isLoaded &&
        ispaymentTabUserType === EPaymentTermTabs.webUser && (
          <TractorPaymentTermForm
            //dependency data should request once only
            dataDependency={{
              allPaymentTypes,
              expenseCategories,
            }}
            //dependency data should request once only
            data={data}
            setUserDetails={setUserDetails}
            userType={'WEBUSER'}
            permission={userPaymentTermPermission}
          />
        )}
      {data.driveStatus === 0 && isLoaded && (
        <TractorPaymentTermForm
          //dependency data should request once only
          dataDependency={{
            allPaymentTypes,
            expenseCategories,
          }}
          //dependency data should request once only
          data={data}
          setUserDetails={setUserDetails}
          userType={'WEBUSER'}
          permission={userPaymentTermPermission}
        />
      )}
      {data.driveStatus === 1 && isLoaded && (
        <PaymentTermFormWithProvider
          //dependency data should request once only
          dataDependency={{
            allPaymentTypes,
            expenseCategories,
          }}
          //dependency data should request once only
          expanded={expanded}
          data={data}
          handleChangeShowDetailsTab={handleChangeShowDetailsTab}
          setUserDetails={setUserDetails}
          inactive={inactive}
        />
      )}
    </Box>
  );
}
