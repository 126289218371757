import { AddOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { useStores } from '../../../../../../store/root.context';
import ButtonImproved from '../../../../../../ui-kit/components/ButtonImproved';
import { hasPaymentEdit } from '../../../utils/payment.permission';
import { PaymentDetailsFormProps } from '../../PaymentDetailsPanel/PaymentDetailsForm';
import { InvoicesPaymentGrid } from './InvoicesPaymentGrid';
import { emptyInvoicePaymentRow } from './InvoicesPaymentGrid.constants';
export const InvoicePaymentForm = observer(
  ({ isCreatePanel }: Pick<PaymentDetailsFormProps, 'isCreatePanel'>) => {
    const {
      invoicePaymentStore: { addNewRowTable, unpaidInvoices },
    } = useStores();
    const { setValue } = useFormContext();
    const addNewInvoice = () => {
      if (!unpaidInvoices?.length) return;
      addNewRowTable({
        ...emptyInvoicePaymentRow,
        invoiceId: `newInvoice${new Date().getTime()}`,
      } as any);
      setValue('makeDirty', 'makeDirty', { shouldDirty: true });
    };
    return (
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
          width={'100%'}
        >
          <Typography variant="h7" color={'primary.main'}>
            Invoices
          </Typography>
          {hasPaymentEdit() && (
            <>
              <ButtonImproved
                startIcon={<AddOutlined />}
                label="Add New Invoice"
                variant="outlined"
                size="small"
                disabled={!unpaidInvoices?.length}
                onClick={addNewInvoice}
              />
            </>
          )}
        </Stack>
        <Box sx={{ mt: 1 }}>
          <InvoicesPaymentGrid isCreatePanel={isCreatePanel} />
        </Box>
      </Box>
    );
  }
);
