import { ThemeOptions } from '@mui/material';

export const MuiAutocomplete: ThemeOptions['components'] = {
  MuiAutocomplete: {
    styleOverrides: {
      root: ({ ownerState, theme }) =>
        theme.unstable_sx({
          ...(ownerState.fullWidth && {
            width: '100%',
          }),
        }),
      option: ({ theme }) =>
        theme.unstable_sx({
          '&:hover': {
            backgroundColor: 'primary.outlinedHoverBackground',
          },
        }),
    },
  },
};
