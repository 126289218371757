import { userService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import {
  CreateUserRequest,
  GetEldIntegrationDriversRequest,
  GetEldIntegrationsRequest,
  GetUserByIdRequest,
  PaginatedUserListRequest,
  QueryParams,
  ValidateUserRequest,
} from '../../models';
import {
  EldIntegrationDrivers,
  EldIntegrations,
  User,
  UserDetails,
} from '../../models/DTOs/user/User';
import { PaginatedResult, ViewMetaData } from '../../types';
import { prepareFiltersFromList } from '../../utils';
import TerminalController from '../../views/settings/terminals/TerminalController';

import {
  driverIdList,
  emailFilter,
  employmentType,
  nameFilter,
  safetyIssueType,
  userStatus,
} from '../../views/settings/users/constants';
import StorageManager from '../../StorageManager/StorageManager';

export const getFilteredRequest = (
  viewMetaData: ViewMetaData,
  request: PaginatedUserListRequest,
  terminalIds?: string[]
): PaginatedUserListRequest => {
  request.nameFilter = prepareFiltersFromList(
    viewMetaData[nameFilter],
    'nameToFilter'
  );
  request.driverIdList = prepareFiltersFromList(
    viewMetaData[driverIdList],
    'driverRefId'
  );
  request.employmentType = viewMetaData[employmentType]?.key;

  request.emailFilter = prepareFiltersFromList(
    viewMetaData[emailFilter],
    'email'
  );
  request.safetyIssueType = prepareFiltersFromList(
    viewMetaData[safetyIssueType],
    'name'
  );
  request.terminalIds = terminalIds;
  request.userStatus = prepareFiltersFromList(viewMetaData[userStatus], 'key');
  request.roleCodeList = ['DR'];
  return request;
};

export const fetchUsers = async (
  queryParams: ViewMetaData,
  terminalIds?: string[],
  fetchingType = 'GET_DATA'
): Promise<PaginatedResult<User> | undefined> => {
  const request = new PaginatedUserListRequest(queryParams);
  const response = await userService.getPaginatedUserList(
    getFilteredRequest(queryParams, request, terminalIds),
    fetchingType
  );
  if (response instanceof ServiceError) {
    return;
  } else {
    return response;
  }
};

export const createUser = async (
  newUserData: any
): Promise<User | undefined> => {
  const request = new CreateUserRequest();
  const response = await userService.createUser(request, newUserData);
  if (response instanceof ServiceError) {
    return;
  } else {
    const { driver } = response;
    if (driver?.id) {
      return driver;
    }
    return;
  }
};

export const updateUser = async (
  item: any
): Promise<{ currentUser: User; removableId: null | string } | undefined> => {
  const request = new CreateUserRequest();
  const response = await userService.updateUser(request, item);
  if (response instanceof ServiceError) {
    return;
  } else {
    const { driver } = response;
    let removableId = null;
    if (!driver.id) {
      removableId = item.id;
    }
    return {
      currentUser: driver,
      removableId,
    };
  }
};

export const getUserById = async (
  userId: string
): Promise<UserDetails | undefined> => {
  const request = new GetUserByIdRequest();
  request.id = userId;
  request.loggedIn = 1;
  const result = await userService.getUserById(request);
  if (result instanceof ServiceError) {
    return;
  } else {
    const associatedTerminalsList = result?.associatedTerminalsList?.map(
      (terminal) => {
        return TerminalController.instance().terminalById(terminal?.id);
      }
    );

    const data = { ...result, associatedTerminalsList };

    return new UserDetails(data);
  }
};

/* @TODO davits fix return type */
export const validateUser = async (
  userId: string | null,
  email: string,
  roleCode: string,
  driverRefId: string
): Promise<any> => {
  const user = StorageManager.getUser();
  if (user) {
    const request = new ValidateUserRequest();
    request.id = userId;
    request.email = email;
    request.dot = user.dot;
    request.roleCode = roleCode;
    request.driverRefId = driverRefId;
    const response = await userService.validateUser(request);
    if (response instanceof ServiceError) {
      return response;
    }
    return response;
  }
};

export const getDriverListDataTotal = async (): Promise<
  number | ServiceError
> => {
  const response = await userService.getDriverListDataTotal(new QueryParams());
  if (response instanceof ServiceError) {
    return response;
  }
  return response;
};

export const getEldIntegrationsDetailsList = async (
  integrationType = 'ELD'
): Promise<EldIntegrations | undefined> => {
  const response = await userService.getEldIntegrationsList(
    new GetEldIntegrationsRequest(integrationType)
  );

  if (response instanceof ServiceError) {
    return;
  } else {
    return {
      first: true,
      last: true,
      size: 25,
      number: 1,
      numberOfElements: 1,
      totalElements: 1,
      totalPages: 1,
      totalCriticalIssueCount: 0,
      totalWarningIssueCount: 0,
      order: [{ property: 'name', directiion: 'ASC' }],
      content: response,
    };
  }
};

export const groupEldDriverName = (
  id: number | string,
  firstName: string,
  lastName: string
) => {
  if (!id) return '';
  if (!lastName) return `${id} (${firstName})`;
  if (!firstName) return `${id} (${lastName})`;
  return `${id} (${firstName} ${lastName})`;
};

export const getEldIntegrationDriversByCredentialId = async ({
  credentialId,
  pageNumber,
  pageSize,
  pattern,
  linkedFilter,
  sort,
}: {
  credentialId: number;
  pageNumber: number;
  pageSize: number;
  pattern: string;
  linkedFilter: string;
  sort: string;
}): Promise<EldIntegrationDrivers | undefined> => {
  const response = await userService.getEldIntegrationDriversByCredentialId(
    new GetEldIntegrationDriversRequest(
      credentialId,
      pageNumber,
      pageSize,
      pattern,
      linkedFilter,
      sort
    )
  );
  if (response instanceof EldIntegrationDrivers) {
    const content = response.content.map((s) => {
      return {
        ...s,
        nameToShow: groupEldDriverName(s.eldId, s.firstname, s.lastname),
      };
    });
    return { ...response, content };
  }
};
