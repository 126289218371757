import { addressType } from '@/common/Ui/AddressField/constants';
import { Box, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TripRevenueShare } from '../../../../../models';
import {
  PER_LOADED_MILE_KEY,
  StopTypeOptions,
} from '../../constants/fieldOptions';
import { defaultAddNewStopConfig } from '../../data.config';
import { StopTypeEnum } from '../../schema/stops';
import { rateFormService } from '../../services/rate.service';
import { stopFormService } from '../../services/stopForm.service';
import { ILoadStop } from '../../types/requests';
import { BusinessProps } from '../../utils/stops';
import StopFooter from './footer';
import StopsMap from './map';
import { Stop } from './stop';

const StopsDetails: React.FC<BusinessProps> = () => {
  const { setValue, watch, getValues } = useFormContext();
  const { fields, remove, swap, insert } = useFieldArray({
    name: 'stops',
  });
  const stops = watch('stops');
  const isEmptyTrip = watch('isEmptyTrip');
  const isTotalMilesManuallyUpdate = watch('isTotalMilesManuallyUpdate');
  const feeDetail = watch('rate.feeDetail');
  const [isMapEnabled, setIsMapEnabled] = useState(false);
  const [openMapIndex, setOpenMapIndex] = useState(-1);
  // const [selBusinesses, setSelBusinesses] = useState<BusinessData[]>([]);
  const selBusinesses = stops;
  const addStopAfter = (index: number) => {
    if (isEmptyTrip) {
      insert(index + 1, {
        ...defaultAddNewStopConfig,
        type: StopTypeEnum.TRIP_STOP,
      });
      return;
    }
    insert(index + 1, defaultAddNewStopConfig);
    if (index === fields.length - 1) {
      setValue(
        `stops.${index}.type`,
        StopTypeOptions[StopTypeOptions.length - 1].value
      );
    }
  };
  const removeStop = (index: number) => {
    if (index === fields.length - 1) {
      setValue(
        `stops.${index - 1}.type`,
        StopTypeOptions[StopTypeOptions.length - 1].value
      );
    }
    if (index === 0) {
      setValue(`stops.${index + 1}.type`, StopTypeOptions[0].value);
    }
    remove(index);
  };

  const moveStopUp = (index: number) => {
    if (index === 0) return;
    swap(index, index - 1);
  };
  const moveStopDown = (index: number) => {
    if (index === fields.length - 1) return;
    swap(index, index + 1);
  };

  const enableStopMap = (index: number) => {
    setOpenMapIndex(!isMapEnabled ? index : -1);
    toggleMap();
  };

  const toggleMap = () => {
    setIsMapEnabled(!isMapEnabled);
  };

  const getMapAddress = () => {
    if (openMapIndex < 0 || openMapIndex >= selBusinesses.length) {
      return undefined;
    }
    return stops[openMapIndex].location;
  };

  const setMapAddress = (newAddress: addressType) => {
    if (openMapIndex < 0 || openMapIndex >= selBusinesses.length) {
      return;
    }
    if (
      newAddress?.center?.lat ==
        selBusinesses[openMapIndex]?.location?.center?.lat &&
      newAddress?.center?.lng ==
        selBusinesses[openMapIndex]?.location?.center?.lng
    ) {
      setValue(`stops.${openMapIndex}.location`, {
        ...(selBusinesses[openMapIndex]?.location ?? {}),
        geoCoordinates: newAddress?.geoCoordinates,
      });
      return;
    }
    setValue(`stops.${openMapIndex}.location`, {
      ...newAddress,
      // locationId: null,
      locationName: newAddress?.address,
    });
  };

  const handleUpdateRevenueShare = async () => {
    if (!stopFormService.validateIfRelayExists(stops)) return;
    const revenue = (await stopFormService.getRelayStopRevenueShare(
      stops
    )) as TripRevenueShare[];
    if (revenue) {
      const revenueShareCount = revenue?.length;
      if (revenueShareCount > 2) {
        for (let i = 1; i < revenueShareCount - 1; i++) {
          revenue?.splice(i + 1, 0, revenue[i]);
        }
      }
      let c = 0;
      const updatedStops = stops?.map((stop: ILoadStop) => {
        if (stop.type === 'RELAY') {
          stop.relayDropOffRevenueShare =
            revenue[c]?.revenueShare?.toFixed?.(2);
          c++;
          stop.relayPickupRevenueShare = revenue[c]?.revenueShare?.toFixed?.(2);
          c++;
        }
        return stop;
      });
      setValue('stops', updatedStops);
    }
  };

  const handleUpdateTotalMiles = async () => {
    if (isEmptyTrip) return;
    if (isTotalMilesManuallyUpdate) return;
    const miles = await stopFormService.getTotalMiles(stops);
    setValue('totalMiles', miles);
    if (feeDetail?.itemCode === PER_LOADED_MILE_KEY) {
      setValue('rate.quantity', miles);
    }
    const additionalFees = getValues('rate.additionalFees');
    const invoiceRate = getValues('rate.rate');
    if (!additionalFees?.length) return;
    const updatedFees = additionalFees?.map((e: any) => {
      const isPerLoadedType =
        e?.feeDetail?.unit === PER_LOADED_MILE_KEY ||
        e?.unit === PER_LOADED_MILE_KEY;
      const qty = isPerLoadedType ? miles : e?.quantity;
      return {
        ...e,
        quantity: qty,
        total: isPerLoadedType
          ? miles
          : e?.quantity
          ? rateFormService.calculateLineItemRateByUnit({
              quantity: qty,
              rate: Number(invoiceRate),
              unit: e?.feeDetail?.unit,
            })
          : e?.total,
      };
    });
    setValue('rate.additionalFees', updatedFees);
  };

  const completeAddresses = useMemo(() => {
    return stops?.reduce(
      (p: string, c: ILoadStop) => p + '' + `${c?.type}${c?.location?.address}`,
      ''
    );
  }, [JSON.stringify(stops)]);

  useEffect(() => {
    handleStopLocationChangeEffects();
  }, [completeAddresses]);

  useEffect(() => {
    handleUpdateTotalMiles();
  }, [isTotalMilesManuallyUpdate]);

  const handleStopLocationChangeEffects = () => {
    handleUpdateRevenueShare();
    handleUpdateTotalMiles();
  };

  return (
    <Stack gap={2}>
      <Box sx={{ pl: 0, pr: 2, pt: 1, pb: 1 }}>
        {fields.map((field, index) => (
          <Stop
            key={field.id}
            index={index}
            stops={fields}
            remove={removeStop}
            add={addStopAfter}
            moveUp={moveStopUp}
            moveDown={moveStopDown}
            enableMap={enableStopMap}
            // businesses={{ businesses, setBusinesses }}
            // onLocationChanged={handleStopLocationChangeEffects}
          />
        ))}
        <StopsMap
          address={getMapAddress()}
          callbackAddress={setMapAddress}
          onClose={toggleMap}
          isOpen={isMapEnabled}
          stopIndex={openMapIndex}
        />
      </Box>
      {!isEmptyTrip && <StopFooter />}
    </Stack>
  );
};

export default React.memo(StopsDetails);
