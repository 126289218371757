import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Maps from '../../common/Ui/Maps/Maps';
import { MapsProps } from '../../common/Ui/Maps/types';
import { GanttDriverLocationMarkersMissing } from '../../models/DTOs/Dispatch/Dispatch';
import { useRootStore } from '../../store/root-store/rootStateContext';
import { useStores } from '../../store/root.context';
import { SecondaryDetailsPanelType } from '../../types/DispatchTypes';

const DriversMap = ({
  onDriverDetailView,
  isMarkerActionDisabled,
  allowNavigationForUnlocatedDrivers,
  onFixDriverHosLocation,
  validateDateFilter = false,
  disableFilter = false,
}: {
  onDriverDetailView?: (driverGroupId: string) => void;
  isMarkerActionDisabled?: boolean;
  allowNavigationForUnlocatedDrivers?: boolean;
  onFixDriverHosLocation?: MapsProps['onFixDriverHosLocation'];
  validateDateFilter?: boolean;
  disableFilter?: boolean;
}) => {
  const {
    dispatch2TripsStore: { fullscreenPanel, setSecondaryPanelData },
    dispatch2GlobalStore: { fetchMapdata, getMapData, filters, setMapInstance },
  } = useStores();
  const [mapInstanceRef, setMapInstanceRef] = useState<any>(null);
  const { getGlobalTerminalsIds, setDispatchPanelAutoOpenDriver } =
    useRootStore();
  const navigate = useNavigate();

  const onUnlocatedDrivers = (driver: GanttDriverLocationMarkersMissing) => {
    if (allowNavigationForUnlocatedDrivers) {
      setDispatchPanelAutoOpenDriver({
        driverIds: driver.driverIds,
        id: driver.id,
        name: driver.groupName,
      });
      return navigate('/dispatch');
    }
    const driverGroupId = driver?.id;
    onDriverDetailView?.(driverGroupId);
  };

  const onMapLoaded = (mapRef: { target: TrimbleMaps.Map }) => {
    setMapInstanceRef(mapRef.target);
    setMapInstance(mapRef?.target);
  };

  const onMapInstanceCb = (mapInstance: TrimbleMaps.Map) => {
    setMapInstanceRef(mapInstance);
  };

  useEffect(() => {
    if (
      mapInstanceRef &&
      getMapData &&
      (getMapData?.markers?.length > 0 ||
        (getMapData?.missingMarkers?.length as number) > 0)
    ) {
      mapInstanceRef?.resize?.();
    }
  }, [fullscreenPanel, JSON.stringify(getMapData), mapInstanceRef]);

  useEffect(() => {
    if (validateDateFilter && !filters?.filters?.dateFilter) return;
    fetchMapdata({
      filters: disableFilter ? ({} as any) : filters,
      getGlobalTerminalsIds,
      isMarkerActionDisabled,
    });
  }, [filters, JSON.stringify(getGlobalTerminalsIds)]);

  const handleViewPanel = (data: SecondaryDetailsPanelType) => {
    setSecondaryPanelData(data);
  };

  return (
    <Maps
      markersList={(getMapData?.markers as any[]) || []}
      missingMarkers={getMapData?.missingMarkers || []}
      addFilter={true}
      handleUnlocatedDrivers={(driver: GanttDriverLocationMarkersMissing) =>
        onUnlocatedDrivers(driver)
      }
      onMapLoaded={onMapLoaded}
      cluster={true}
      onFixDriverHosLocation={onFixDriverHosLocation}
      onViewPanel={handleViewPanel}
    />
  );
};

export default observer(DriversMap);
