import { QueryParams } from '../commonMixed/QueryParams';
import { Type } from 'class-transformer';

export class GetLoadAlertsList extends QueryParams {
  id!: number;
  organizationId!: number;
  constructor() {
    super();
  }
}

export class GetLoadAlertsTypeDTO extends QueryParams {
  organizationId!: number;
}

export class LoadAlertsTypeDTO {
  first!: boolean;
  last!: boolean;
}

export class GetLoadUserRolesDTO extends QueryParams {
  loadAlertType!: string;
  organizationId!: number;
}

export class LoadUserRolesDTO {
  view!: boolean;
}

export class DeleteLoadAlertDTO extends QueryParams {
  id!: string;
  organizationId!: number;
  documentType!: string;
  documentTypeId!: string;
  maintenanceType!: string;
  maintenanceTypeId!: string;
}

export class GetStatic extends QueryParams {}

export class CreateLoadAlertDTO extends QueryParams {
  alertTypeCardTitle!: string;
  alertTypeRowTitle!: string;
  iconKey!: string;
  organizationId!: number;
  sendAlertsToRoles!: [string];
}

export class CreateDriverAlertDTO extends QueryParams {
  dispatchAllowedIfCertificationExpires!: boolean;
  documentType!: string;
  documentTypeId!: string;
  frequency!: string;
  frequencyValue!: number;
  iconPicker!: string;
  organizationId!: number;
  updateDate!: string;
  warningDuration!: string;
  warningDurationByDays!: number;
  recipient!: [string];
}

export class CreateTrailerDTO extends QueryParams {
  dispatchAllowedIfCertificationExpires!: boolean;
  maintenanceType!: string;
  maintenanceTypeId!: string;
  frequency!: string;
  frequencyValue!: number;
  iconPicker!: string;
  organizationId!: number;
  updateDate!: string;
  warningDuration!: string;
  warningDurationByDays!: number;
  recipient!: [string];
}

export class CreateTractorDTO extends QueryParams {
  alertByMiles!: number;
  dispatchAllowedIfCertificationExpires!: boolean;
  frequencyByMiles!: number;
  maintenanceType!: string;
  maintenanceTypeId!: string;
  frequency!: string;
  frequencyValue!: number;
  iconPicker!: string;
  organizationId!: number;
  updateDate!: string;
  warningDuration!: string;
  warningDurationByDays!: number;
  recipient!: [string];
}

export class LoadAlertsListRes {
  hasLoadAlertOrgAddFlag!: boolean;
  @Type(() => loadAlertPrefDTOS)
  loadAlertPrefDTOS!: loadAlertPrefDTOS[];
}
export class loadAlertPrefDTOS {
  organizationId!: number;
  alertTypeCardTitle!: string;
  alertTypeRowTitle!: string;
  sendAlertsToRoles!: string[];
  iconKey!: string;
  id!: string;
}

export class keyValue {
  key!: string;
  value!: string;
}

export class DTTAlertTypeRes {
  createDate!: string;
  createdBy!: string;
  custom!: boolean;
  deduction!: boolean;
  description!: string;
  iconName!: string;
  id!: string;
  itemCode!: string;
  itemName!: string;
  organizationId!: number;
}

export class GetStaticForAlertsRes {
  @Type(() => keyValue)
  dvirPreferences!: keyValue[];
  @Type(() => keyValue)
  frequencyData!: keyValue[];
  @Type(() => keyValue)
  preferredTruckstopsType!: keyValue[];
  @Type(() => keyValue)
  recipientData!: keyValue[];
  @Type(() => keyValue)
  warningDuration!: keyValue[];
  @Type(() => keyValue)
  workWeekType!: keyValue[];
}

export class GetDTTAlertsListRes {
  avoidTollRoutes!: boolean;
  dvirPreferences!: string;
  easypass!: boolean;
  expectedMinimumRevenue!: number;
  expectedWeekRevenue!: number;
  id!: string;
  ignoreHOSRestrictions!: boolean;
  maxHaulLength!: number;
  minHaulLength!: number;
  organizationId!: number;
  preferredTruckstops!: string;
  statesToAvoid!: string;
  statesToAvoidForPickupDelivery!: string;
  useSleeperBerthProvision!: boolean;
  workWeekType!: string;
  @Type(() => SingleContentListDTO)
  safety!: SingleContentListDTO[];
}

export class SingleContentListDTO {
  dispatchAllowedIfCertificationExpires!: boolean;
  documentType!: string;
  documentTypeId!: string;
  frequency!: string;
  frequencyValue!: number;
  iconPicker!: string;
  id!: string;
  updateDate!: string;
  warningDuration!: string;
  warningDurationByDays!: number;
  recipient!: string[];
}
