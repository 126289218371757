import { FormControlLabelProps } from '@mui/material/FormControlLabel';
import React from 'react';
import { Controller } from 'react-hook-form';
import { CheckBox } from '../../../ui-kit/components/Checkbox';

const Checkbox: React.FC<{
  id?: string;
  control?: any;
  name: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  label: string;
  subLabel?: string;
  disabled?: FormControlLabelProps['disabled'];
  style?: {};
  subLabelStyles?: {};
  onChangeCb?: (checked: boolean) => void;
  defaultChecked?: boolean;
}> = ({
  id = '',
  control,
  name,
  labelPlacement,
  label,
  subLabel,
  disabled,
  style,
  subLabelStyles,
  onChangeCb,
  defaultChecked,
}) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <CheckBox
          id={id}
          onChange={(event, checked: boolean) => {
            event.stopPropagation();
            onChange(event);
            onChangeCb && onChangeCb(checked);
          }}
          checked={Boolean(value)}
          label={label}
          labelPlacement={labelPlacement}
          style={style}
          disabled={disabled}
          defaultChecked={defaultChecked}
          subLabelStyles={subLabelStyles}
          subLabel={subLabel}
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default Checkbox;
