import { Add } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import {
  CloseIcon,
  DislikeIcon,
  LikeIcon,
} from '../../../../../ui-kit/components/Assets';
import { FC, useCallback, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Address as AddressField } from '../../../../../common/Ui/AddressField';
import { ContactSummary, CreateVendorRequest } from '../../../../../models';
import TerminalImage from '../../../../../_assets/images/addTerminalPopover.png';
import Contact from '../../../components/Contact';
import { createVendor } from '../../../services/api.utils';
import { TextField } from '../../../UI';
import { FormContainer } from '../Form';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogCloseIcon,
  StyledDialogHeader,
  StyledDialogTitle,
  StyledTypography,
  StyledVendorAddContactContainer,
  StyledVendorContentContainer,
  StyledVendorRequired,
  StyledVendorRequiredBackground,
  StyledVendorRequiredOverlay,
} from '../../styles';
import { MaintenanceHistoryFormContext } from '../../../context/MaintenanceHistoryFormContext';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import ButtonGroup_Like_Dislike from '../../../../../ui-kit/components/ButtonGroup_Like_Dislike/ButtonGroup_Like_Dislike';
import { t } from 'i18next';
interface AddNewMaintenancePopupProps {
  onClose: () => void;
}

const AddNewVendorPopup: FC<AddNewMaintenancePopupProps> = ({ onClose }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { newVendorContacts, setNewVendorContacts, setOpenAddNewContact } =
    useContext(MaintenanceHistoryFormContext);
  const { handleSubmit, control, reset, getValues } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: null,
      notes: null,
      address: null,
    },
  });

  const preferredProhibitedOptions = [
    {
      key: 'PREFERRED',
      name: 'Preferred',
      iconRenderer: (selected: boolean) => (
        <LikeIcon
          color={
            selected
              ? theme.palette.primary.contrast
              : theme.palette.primary.main
          }
        />
      ),
      getButtonStyle: (selected: boolean) => ({
        backgroundColor: selected
          ? theme.palette.primary.main
          : theme.palette.primary.contrast,
        borderRadius: '6px',
        borderColor: theme.palette.primary.main,
        marginRight: 5,
      }),
    },
    {
      key: 'PROHIBITED',
      name: 'Prohibited',
      iconRenderer: (selected: boolean) => (
        <DislikeIcon
          color={
            selected ? theme.palette.error.contrast : theme.palette.error.main
          }
        />
      ),
      getButtonStyle: (selected: boolean) => ({
        backgroundColor: selected
          ? theme.palette.error.main
          : theme.palette.error.contrast,
        borderColor: theme.palette.error.main,
        borderRadius: '6px',
      }),
    },
  ];

  const handlePrimaryContactChange = (
    contact: ContactSummary,
    value: boolean
  ) => {
    const updatedContacts = newVendorContacts.map((c) => {
      c.isPrimary = false;
      if (
        c.name === contact.name &&
        c.email === contact.email &&
        c.description === contact.description &&
        c.phoneData.phone === contact.phoneData.phone
      ) {
        c.isPrimary = value;
      }

      return c;
    });
    setNewVendorContacts?.(updatedContacts);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const requestBody = new CreateVendorRequest({
        isActive: true,
        name: data.name,
        notes: data.notes,
        preferredProhibitedEnum: data.preferredProhibitedEnum || 'NONE',
        addressDTO: data.address,
        contacts: newVendorContacts,
      });
      const response = await createVendor(requestBody);
      if (response) {
        setNewVendorContacts?.([]);
        handleClose();
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    setNewVendorContacts?.([]);
    onClose?.();
  };

  const onDeleteContactHandler = useCallback(
    (index: number) => {
      newVendorContacts.splice(index, 1);
      setNewVendorContacts?.([...newVendorContacts]);
    },
    [newVendorContacts, setNewVendorContacts]
  );

  return (
    <>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { marginBottom: 2 },
          position: 'relative',
          height: '100%',
        }}
        autoComplete="off"
      >
        <StyledDialog>
          <StyledDialogHeader>
            <StyledDialogTitle>
              Add New Vendor
              <StyledDialogCloseIcon>
                <IconButton onClick={handleClose}>
                  <CloseIcon width={14} height={14} />
                </IconButton>
              </StyledDialogCloseIcon>
            </StyledDialogTitle>
          </StyledDialogHeader>
          <StyledVendorRequired>
            <Typography fontSize={14}>{t('fillInformation')}</Typography>
            <StyledTypography color="primary" fontSize="0.875rem">
              {t('vendorAffectBilling')}
            </StyledTypography>
            <StyledVendorRequiredBackground src={TerminalImage} alt="" />
            <StyledVendorRequiredOverlay />
          </StyledVendorRequired>
          <StyledVendorContentContainer>
            <StyledTypography
              color="primary"
              fontSize="1rem"
              fontWeight={600}
              marginBottom="1rem"
              marginTop="1rem"
              padding="8px 16px"
            >
              {t('vendorDetails')}
            </StyledTypography>

            <FormContainer
              styleProps={{
                padding: '8px 16px',
              }}
              scrollable={true}
              scrollableHeight="calc(100% - 40px)"
            >
              <div style={{ display: 'flex' }}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="name"
                      label={t('vendorName')}
                      variant="standard"
                      {...field}
                      error={!!error}
                      styleProps={{
                        mr: 3,
                      }}
                      fullWidth
                    />
                  )}
                />
                <Box sx={{ width: '10ch', margin: 'auto 0' }}>
                  <ButtonGroup_Like_Dislike
                    name="preferredProhibitedEnum"
                    control={control}
                    options={preferredProhibitedOptions}
                  />
                </Box>
              </div>

              <Controller
                name="address"
                control={control}
                rules={{
                  required: true,
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <AddressField
                    label="Address"
                    errors={error}
                    data={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="notes"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="notes"
                    label="Notes"
                    variant="standard"
                    {...field}
                    error={!!error}
                    fullWidth
                    multiline
                  />
                )}
              />
              <StyledVendorAddContactContainer>
                <StyledTypography
                  fontSize="1rem"
                  color="primary"
                  fontWeight={600}
                >
                  {`${newVendorContacts.length} Contact${
                    newVendorContacts.length > 1 ? 's' : ''
                  }`}
                </StyledTypography>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ padding: '2px 5px', minWidth: 'auto' }}
                  onClick={() => setOpenAddNewContact?.(true)}
                >
                  <Add color="primary" />
                </Button>
              </StyledVendorAddContactContainer>

              <Box
                sx={{
                  marginBottom: newVendorContacts.length > 0 ? 4 : 0,
                  display: 'grid',
                  rowGap: 4,
                }}
              >
                {newVendorContacts.map((contact, index) => (
                  <Contact
                    key={contact.name}
                    contact={contact}
                    onPrimaryChange={(value) =>
                      handlePrimaryContactChange(contact, value)
                    }
                    onDeleteHandler={() => onDeleteContactHandler(index)}
                  />
                ))}
              </Box>
            </FormContainer>
          </StyledVendorContentContainer>
          <StyledDialogActions>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 8,
              }}
            >
              <ButtonImproved
                variant="outlined"
                onClick={handleClose}
                label="Cancel"
              />
              <ButtonImproved
                startIcon={<Add />}
                disabled={loading}
                variant="contained"
                label="Add Vendor"
                onClick={handleSubmit(onSubmit)}
              />
            </Box>
          </StyledDialogActions>
        </StyledDialog>
      </Box>
    </>
  );
};

export default AddNewVendorPopup;
