import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { dispatchService } from '../../../../../../../api';
import { GetTractorInfoForDriverAssignRequest } from '../../../../../../../models/DTOs/Dispatch/Requests';
import { gridPageSize } from '../../../../../../../utils';
import { SingleAutocompleteForm } from '../../../../../../Ui/SingleAutocomplete';
import { Trip } from '../../../Models';

export default function FormComponent({ trip }: { trip: Trip }) {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setTimeout(() => setValue('tractor', trip.tractor));
  }, [trip]);

  return (
    <>
      <SingleAutocompleteForm
        control={control}
        disableClear
        name="tractor"
        fieldName="name"
        label="Tractor"
        getOptions={(searchText: string | undefined, pageNumber: number) =>
          dispatchService.getTractorInfoForDriverAssign(
            new GetTractorInfoForDriverAssignRequest({
              axeleIdFilter: searchText,
              pageNumber,
              pageSize: gridPageSize,
              statusList: ['AVAILABLE'],
            })
          )
        }
      />
    </>
  );
}
