import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Stack } from '@mui/material';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import AllFilters from '../../../../ui-kit/components/DataGridPro/filter/AllFilters';
import Filters from '../../../../ui-kit/components/DataGridPro/filter/Filters';
import TableSettings from '../../../../ui-kit/components/DataGridPro/settings/TableSettings';

export interface CustomizeFiltersProps {
  [key: string]: any;
}

export const CustomizeFilters = ({
  filterColumns = [],
  onAllFilterSubmit,
  onAllFilterReset,
  filterPerCol,
  defaultFilterValue,
  onPageFilterChange,
  tableConfigurationProps,
  onSearchHandler: onSearchHandlerProp,
  onRefreshHandler: onRefreshHandlerProp,
}: CustomizeFiltersProps) => {
  const { isMobile } = useThemeResponsive();
  const [lastRefreshed, setLastRefreshed] = useState<Moment | null>(null);
  const onSearchHandler = (...params: any) => {
    setLastRefreshed(moment());
    onSearchHandlerProp(...params);
  };

  const onRefreshHandler = (...params: any) => {
    setLastRefreshed(moment());
    onRefreshHandlerProp?.();
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        spacing={2}
      >
        <Stack id="FilterGridRow" direction="row" flexWrap="wrap">
          {!isMobile && filterColumns?.length > 0 && (
            <Filters
              columns={filterColumns}
              onChangeCb={onPageFilterChange}
              defaultFilterValue={defaultFilterValue}
            />
          )}

          <AllFilters
            column={filterColumns.map((item: any) => {
              return {
                ...item,
              };
            })}
            handleOnSubimt={onAllFilterSubmit}
            handleOnReset={onAllFilterReset}
            filterPerCol={filterPerCol}
            defaultFilterValue={defaultFilterValue}
          />

          <TableSettings {...tableConfigurationProps.TableSettings} />

          <ButtonImproved
            size="medium"
            label={t('onRefreshHandlerSearch')}
            color={'primary'}
            styleProps={{
              whiteSpace: 'nowrap',
              background: 'primary.main',
              textTransform: 'capitalize',
              mt: 1,
              ml: 2,
              alignSelf: 'flex-start',
            }}
            startIcon={<SearchIcon />}
            variant="contained"
            onClick={onSearchHandler}
          />
        </Stack>
        <Box>
          <ButtonImproved
            size="medium"
            label={
              <div>
                {t('onRefreshHandlerRefresh')}
                <br />
              </div>
            }
            color="primary"
            styleProps={{
              whiteSpace: 'nowrap',
              background: 'primary.main',
              textTransform: 'capitalize',
              mt: 1,
            }}
            startIcon={<RefreshOutlinedIcon />}
            variant="outlined"
            onClick={onRefreshHandler}
            disabled={!onRefreshHandler}
          />
          {lastRefreshed && (
            <Box mt={1} display="block" fontSize={9} component="small">
              {t('lastrefreshedat')} {lastRefreshed.format('hh:mm A')}
            </Box>
          )}
        </Box>
      </Stack>
    );
  }
};
