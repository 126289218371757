import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { numberFormat } from '../../../reports/basicReports/constant';
import { ILoadsearchTableItem } from '../../loadTypes';

type EquipmentProps = ILoadsearchTableItem['Equipment'];

const displayValueFalsyCheck = (
  value?: string | number,
  suffix?: string | number
): string | undefined => {
  if (value === undefined || value === null) return;
  return `${value} ${suffix}`;
};

const Equipment: FC<EquipmentProps> = (Equipment) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '150%',
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '0.15px',
        }}
        gutterBottom
        color="primary.main"
      >
        {Equipment?.value}
      </Typography>
      <Typography
        variant="caption"
        gutterBottom
        sx={{
          color: '#000',
        }}
      >
        {[
          displayValueFalsyCheck(numberFormat(Equipment.subvalue), 'Lbs'),
          displayValueFalsyCheck(numberFormat(Equipment.length), 'ft'),
        ]
          .filter(Boolean)
          .join(', ')}
      </Typography>
    </Box>
  );
};

export default React.memo(Equipment);
