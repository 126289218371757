import React from 'react';
import { eldService } from '../../../../api';
import { GetDriverSummaryRequest } from '../../../../models/DTOs/ELD/Requests';
import SingleAutocomplete from '../../../../ui-kit/components/SingleAutocomplete';
import { useRootStore } from '../../../../store/root-store/rootStateContext';

export const AxeleDriverMapping = ({
  rowDetails,
  defaultValue,
  linkDriver,
  createNewDriverPopup,
  showCreateDriverBtn,
}: IAxeleDriverMapping) => {
  const { getGlobalTerminalsIds } = useRootStore();

  const handleChange = (values: any) => {
    if (values) {
      if (values?.driverName === 'Create New Driver') {
        createNewDriverPopup(rowDetails, values);
      } else {
        linkDriver(rowDetails?.id, values?.driverId);
      }
    }
  };

  const getOptions = async (name: string, pageNumber: number) => {
    const request = new GetDriverSummaryRequest();
    request.pageNumber = pageNumber;
    request.pageSize = 25;
    request.nameFilter = name;
    request.excludeStatuses = 0;
    request.terminalIds = getGlobalTerminalsIds;
    const res = await eldService.getDriverSummary(request);
    const driverValues = res?.content;
    const modifiedValues: any = [];
    driverValues?.length > 0 &&
      driverValues.forEach((el: any) => {
        const values = {
          driverId: el.driverId,
          driverAxeleId: el.driverAxeleId,
          driverEldId: el.driverEldId,
          driverName: el?.terminalName
            ? el.driverName + ` (${el?.terminalName})`
            : el.driverName,
          eldProviderId: el.eldProviderId,
          eldProviderName: el.eldProviderName,
          terminalId: el.terminalId,
        };
        modifiedValues.push(values);
      });
    pageNumber === 1 &&
      showCreateDriverBtn &&
      modifiedValues.unshift({ driverName: 'Create New Driver' });
    return modifiedValues;
  };

  const textInputStyles = {
    outline: 'none',
    '.MuiInput-root': {
      '&:hover': {
        '&:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
      },
      '&::before': {
        borderBottom: 'none',
      },
    },
  };

  const dropdownStyles = {
    width: '100%',
    '.MuiDataGrid-cell': {
      outline: 'none',
    },
    '.MuiInput-root': {
      '&::after': {
        border: 'none',
      },
    },
    '.MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  };

  return (
    <SingleAutocomplete
      name={'driverName'}
      fieldName={'driverName'}
      label={''}
      getOptions={getOptions}
      value={defaultValue}
      customTextInputStyles={textInputStyles}
      customStyles={dropdownStyles}
      onChangeCb={handleChange}
    />
  );
};

export interface IAxeleDriverMapping {
  defaultValue: null | IDefaultValue;
  rowDetails: IRowDetails;
  linkDriver?: any;
  createNewDriverPopup?: any;
  showCreateDriverBtn: boolean;
}

export interface IDefaultValue {
  driverName: string;
}

export interface IRowDetails {
  AxeleActualEmail: string | null;
  AxeleDriverName: string | null;
  AxeleEmail: string;
  AxeleId: number | null;
  EldActualEmail: string | null;
  EldDriverFirstName: string;
  EldDriverLastName: string | null;
  EldDriverName: string;
  EldEmail: string;
  EldId: string;
  Status: string;
  Terminal: string;
  credentialId: string;
  id: string;
  providerId: number;
  providerName: string;
}
