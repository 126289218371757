import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import React, { MouseEventHandler, ReactNode } from 'react';
import { CloseIcon, EditIcon } from '../../ui-kit/components/Assets';

interface Props {
  title: string;
  icon: ReactNode;
  onClick: MouseEventHandler<HTMLDivElement>;
  selected: boolean;
  onClose?: (event: React.MouseEvent) => void;
  onEditClicked?: (event: React.MouseEvent) => void;
  canEdit?: boolean;
}

const TabStripLarge = ({
  icon,
  title,
  onClick,
  selected,
  onClose,
  onEditClicked,
  canEdit = true,
}: Props): JSX.Element => {
  const Icon = icon;
  const titleColor = selected ? 'white' : 'rgba(0, 17, 34, 0.75)';
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        padding: '9px 30px',
        marginRight: '30px',
        height: '72px',
        borderRadius: '14px',
        backgroundColor: selected ? '#2B64CB' : 'none',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        '&:hover': {
          backgroundColor: selected ? '' : '#ffffff',
          filter: selected
            ? ''
            : 'drop-shadow(0px 1px 12px rgba(78, 101, 120, 0.15))',
        },
        '&:active': {
          transitionDuration: '300ms',
          background: selected ? '' : 'rgba(43, 100, 203, 0.08)',
        },
      }}
    >
      {onClose && (
        <span style={{ position: 'absolute', top: 0, right: 0 }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </span>
      )}
      {onEditClicked && canEdit && (
        <span style={{ position: 'absolute', bottom: 0, right: 0 }}>
          <IconButton onClick={onEditClicked}>
            <EditIcon />
          </IconButton>
        </span>
      )}
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '50%',
        }}
      >
        <Icon color={titleColor} />
      </span>
      <span
        style={{
          display: 'flex',
          fontSize: '14px',
          fontWeight: 500,
          alignItems: 'center',
          height: '50%',
          color: titleColor,
        }}
      >
        {title}
      </span>
    </Box>
  );
};

export default TabStripLarge;
