import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import RadioGroup from '../../../../common/Ui/RadioGroup';
import { TractorColumnsForm, TractorMetaDataForm } from './TractorViewForms';
import ViewBaseInfoForm from '../../../../common/AddView/components/ViewBaseInfoForm';
import { TrailerColumnsForm, TrailerMetaDataForm } from './TrailerViewForms';

const columnsFormMap: { [key: string]: React.ElementType } = {
  tractor: TractorColumnsForm,
  trailer: TrailerColumnsForm,
};

const metadataFormMap: { [key: string]: React.ElementType } = {
  tractor: TractorMetaDataForm,
  trailer: TrailerMetaDataForm,
};

const FilterTitle = ({ title }: { title: string }) => (
  <span
    style={{
      color: 'rgba(0, 17, 34, 0.6)',
      fontSize: 20,
      fontWeight: 500,
      fontFamily: 'Poppins',
    }}
  >
    {title}
  </span>
);

export default function ViewForm({
  mode,
  customErrors,
}: {
  mode: string;
  customErrors: any;
}) {
  const { control, watch } = useFormContext();
  const entity = watch('entity');
  const CurrentColumnsForm = columnsFormMap[entity] || TractorColumnsForm;
  const CurrentMetaDataForm = metadataFormMap[entity] || TractorMetaDataForm;

  return (
    <Grid container justifyContent={'space-between'} style={{ height: '100%' }}>
      <Grid style={{ padding: 16 }} item sm={4}>
        <FilterTitle title={'1. Name Your View'} />
        <ViewBaseInfoForm customErrors={customErrors} />
      </Grid>
      <Grid
        style={{
          padding: 16,
          borderRight: '1.49777px solid #EEEEEE',
          borderLeft: '1.49777px solid #EEEEEE',
        }}
        item
        sm={4}
      >
        <FilterTitle title={'2. Pick Data'} />
        {mode === 'create' && (
          <Grid item style={{ marginBottom: 22 }}>
            <RadioGroup
              control={control}
              name={'entity'}
              defaultValue={entity}
              data={[
                {
                  value: 'tractor',
                  label: 'Tractors',
                  defaultSelected: true,
                },
                { value: 'trailer', label: 'Trailer' },
              ]}
            />
          </Grid>
        )}
        <CurrentColumnsForm />
      </Grid>
      <Grid style={{ padding: 16 }} item sm={4}>
        <FilterTitle title={'3. Add Filters'} />
        <CurrentMetaDataForm />
      </Grid>
    </Grid>
  );
}
