import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  ModalContainerStyles,
  ModalInnerContainerStyle,
  ModalTitleStyles,
} from '../styles';
import { Slide, Typography } from '@mui/material';

interface IBulkOptymizationModal {
  children: JSX.Element;
  modalStyles?: object;
  innerContainerStyles?: object;
  slide?: boolean;
}

const BulkOptymizationModal: React.FC<IBulkOptymizationModal> = ({
  children,
  modalStyles,
  innerContainerStyles,
  slide = false,
}) => {
  return (
    <Modal
      open={true}
      // onClose={handleClose}
      aria-labelledby="bulk-optymization"
      aria-describedby="bulk-optymization-modal"
      sx={modalStyles ?? ModalContainerStyles}
    >
      <Slide direction="down" in={slide} mountOnEnter unmountOnExit>
        <Box sx={innerContainerStyles ?? ModalInnerContainerStyle}>
          {children}
        </Box>
      </Slide>
    </Modal>
  );
};

export default BulkOptymizationModal;
