import moment, { Moment } from 'moment';
import { loadService, locationService } from '../../../../api';
import { httpClient } from '../../../../axios/axiosInstance';
import {
  CreateLocationContactRequest,
  Point,
  TripStopListCoords,
} from '../../../../models';
import fetchAddress from '../../../../utils/fetchAddress';
import { getLocationListByNameAddressSearch } from '../../../../views/operations/contacts/utils/location';
import { urls } from '../apis';
import { StopTypeOptions } from '../constants/fieldOptions';
import { appendLoadStopDateTime } from '../dto/create-load-request.dto';
import { StopTypeEnum } from '../schema/stops';
import { ILoadStop } from '../types/requests';
import { ActivityType } from '../types/types';

class StopFormService {
  getStopTypeOptions({
    stopIndex,
    totalStopsCount,
    types,
    isEmptyTrip = false,
  }: {
    stopIndex: number;
    totalStopsCount: number;
    types: Array<ActivityType>;
    isEmptyTrip?: boolean;
  }) {
    const tripStop = StopTypeOptions?.find(
      (e) => e.value === StopTypeEnum.TRIP_STOP
    );
    //if empty trip, only need trip stop as stop type
    if (isEmptyTrip) return [tripStop];
    const pickupCount = types.filter((t) => t === StopTypeEnum.PICKUP)?.length;
    const dropOffCount = types.filter(
      (t) => t === StopTypeEnum.DROPOFF
    )?.length;
    const pickupStop = StopTypeOptions.find(
      (s) => s.value === StopTypeEnum.PICKUP
    );
    const dropoffStop = StopTypeOptions.find(
      (s) => s.value === StopTypeEnum.DROPOFF
    );

    const firstStopOptions = [StopTypeOptions[0], tripStop];
    const lastStopOptions = [
      StopTypeOptions[StopTypeOptions.length - 1],
      tripStop,
    ];

    if (stopIndex === 0) {
      let relayWithNoloadStop = false;
      // check for relays with no previous load stop, if found trip stop should not be allowed at first
      for (let i = 1; i < types.length; i++) {
        if (types[i] !== StopTypeEnum.TRIP_STOP) {
          if (types[i] === StopTypeEnum.RELAY) {
            relayWithNoloadStop = true;
            break;
          } else break;
        }
      }
      if (
        types.length > 2 &&
        types?.slice(1)?.includes(StopTypeEnum.PICKUP) &&
        !relayWithNoloadStop
      )
        return firstStopOptions;
      return [firstStopOptions[0]];
    }
    if (stopIndex === totalStopsCount - 1) {
      let relayWithNoloadStop = false;
      // check for relay with no future load stop, if found trip stop should not allowed at last
      for (let i = types.length - 2; i >= 0; i--) {
        if (types[i] !== StopTypeEnum.TRIP_STOP) {
          if (types[i] === StopTypeEnum.RELAY) {
            relayWithNoloadStop = true;
            break;
          } else break;
        }
      }
      if (
        types.length > 2 &&
        types?.slice(0, types.length - 1)?.includes(StopTypeEnum.DROPOFF) &&
        !relayWithNoloadStop
      )
        return lastStopOptions;
      return [lastStopOptions[0]];
    }
    if (dropOffCount === 1 && types[stopIndex] === StopTypeEnum.DROPOFF)
      return [dropoffStop];
    if (pickupCount === 1 && types[stopIndex] === StopTypeEnum.PICKUP)
      return [pickupStop];
    const isLoadStopExistInPast = this.ifLoadStopExistInPast(stopIndex, types);
    const isLoadStopExistInFuture = this.ifLoadStopExistInFuture(
      stopIndex,
      types
    );
    if (!(isLoadStopExistInPast && isLoadStopExistInFuture)) {
      // no load stop found either in past stop or in future. so no relay allow
      return StopTypeOptions.filter((s) => s.value !== StopTypeEnum.RELAY);
    }
    return StopTypeOptions;
  }

  ifLoadStopExistInPast(
    currentIndex: number,
    types: Array<ActivityType>
  ): boolean {
    let isLoadStopExistInPast = false;
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (types[i] !== StopTypeEnum.TRIP_STOP) {
        isLoadStopExistInPast = true;
        break;
      }
    }
    return isLoadStopExistInPast;
  }

  ifLoadStopExistInFuture(
    currentIndex: number,
    types: Array<ActivityType>
  ): boolean {
    let isLoadStopExistInFuture = false;
    for (let i = currentIndex + 1; i < types.length; i++) {
      if (types[i] !== StopTypeEnum.TRIP_STOP) {
        isLoadStopExistInFuture = true;
        break;
      }
    }
    return isLoadStopExistInFuture;
  }

  async fetchBusinessNameAndLocations({
    searchText,
    pageNumber,
    querySignal,
  }: {
    searchText?: string;
    pageNumber?: number;
    querySignal?: any;
  }) {
    try {
      if (!searchText?.length || pageNumber! > 1) {
        const locations = await getLocationListByNameAddressSearch(
          searchText!,
          pageNumber!,
          25
        );
        return (locations as { content: Array<any> })?.content;
      }
      const results = await Promise.all([
        getLocationListByNameAddressSearch(searchText, pageNumber!),
        fetchAddress(searchText),
      ]);
      const addressLocationMap = results?.[1]?.map((e: any) => {
        return {
          ...e,
          locationDisplayName: e?.ShortString,
        };
      });
      if (
        ((results?.[0] as { content: Array<any> }) ?? {})?.content?.length ===
        25
      )
        return (results?.[0] as { content: Array<any> })?.content;
      const trimbleAddresses = (results?.[0] as { content: Array<any> })
        ?.content;
      return [...(trimbleAddresses ?? []), ...(addressLocationMap ?? [])];
    } catch (error) {
      console.log('error while fetching locations: ', error);
      return [];
    }
  }

  async fetchAddressByTextList({
    searchText,
    pageNumber,
  }: {
    searchText?: string;
    pageNumber?: number;
  }) {
    try {
      if (!searchText?.length) return [];
      const results = await fetchAddress(searchText);
      return results?.map((e: any) => {
        return {
          ...e,
          locationDisplayName: e?.ShortString,
        };
      });
    } catch (error) {
      return [];
    }
  }

  async fetchBusinessNames({
    searchText,
    pageNumber,
  }: {
    searchText?: string;
    pageNumber?: number;
  }) {
    try {
      const locations = await getLocationListByNameAddressSearch(
        searchText!,
        pageNumber!
      );
      return (locations as { content: Array<any> })?.content;
    } catch (error) {
      console.log('error while fetching locations: ', error);
      return [];
    }
  }

  validateIfRelayExists(stops: ILoadStop[]): boolean {
    const relayStop = stops?.find?.((e) => e?.type === 'RELAY');
    return Boolean(relayStop);
  }

  async getRelayStopRevenueShare(stops: ILoadStop[]) {
    try {
      const stpPts: TripStopListCoords[] = [];
      let points: Point[] = [],
        c = 1;
      for (const stop of stops) {
        if (!stop?.location?.center?.lat || !stop?.location?.center?.lng)
          return null;
        points.push({
          lat: stop?.location?.center?.lat,
          lon: stop?.location?.center?.lng,
        });
        if (stop.type === 'RELAY') {
          stpPts.push({ id: c, points });
          c++;
          points = [];
          points.push({
            lat: stop?.location?.center?.lat,
            lon: stop?.location?.center?.lng,
          });
        }
      }
      stpPts.push({ id: ++c, points });
      return await loadService.getTripListRevenueShare(stpPts);
    } catch (error) {
      console.log('error: ', error);
      return null;
    }
  }

  async getTotalMiles(stops: ILoadStop[]): Promise<number | string> {
    try {
      const points: Point[] = [];
      for (const stop of stops) {
        if (
          !stop?.location?.center?.lat ||
          !stop?.location?.center?.lng ||
          stop?.type === StopTypeEnum.RELAY ||
          stop?.type === StopTypeEnum.TRIP_STOP
        )
          continue;
        points.push({
          lat: stop?.location?.center?.lat,
          lon: stop?.location?.center?.lng,
        });
      }
      if (points?.length < 2) return 0;
      const res = await httpClient.putRaw(
        urls.calculateLoadStopsTotalMiles,
        undefined,
        [{ id: 1, points }]
      );
      return isNaN(res?.data) ? 0 : parseFloat(res?.data).toFixed(2);
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  async createLocationNewContact(requestData: CreateLocationContactRequest) {
    return await locationService.createLocationContact(requestData);
  }

  validateStopAppointmentDates(stops: ILoadStop[]) {
    const errors: Array<
      { type: string; message: string; customMsg: boolean; fieldName: string }[]
    > = Array.from({ length: stops.length }, () => []);
    let errorCount = 0;
    let minDate = appendLoadStopDateTime(
      stops[0].appointmentStartDate!,
      stops[0].appointmentStartTime!,
      stops[0]?.location?.timezone!
    );
    if (moment.tz.zone(stops[0].location?.timezone!)) {
      minDate = moment(minDate).tz(stops[0].location?.timezone!).format();
    }
    for (let i = 0; i < stops.length - 1; i++) {
      const stop = stops[i];
      const nextStop = stops[i + 1];
      const startApptDate = appendLoadStopDateTime(
        stop?.appointmentStartDate!,
        stop?.appointmentStartTime!,
        stop?.location?.timezone!
      );
      const endApptDate =
        nextStop.type === 'RELAY'
          ? appendLoadStopDateTime(
              nextStop.relayDropOffAppointmentEndDate!,
              nextStop.relayDropOffAppointmentEndTime!,
              nextStop.location?.timezone!
            )
          : appendLoadStopDateTime(
              nextStop.appointmentEndDate!,
              nextStop.appointmentEndTime!,
              nextStop.location?.timezone!
            );
      const stopTimezone = stop.location?.timezone!;
      const nextLocationTimezone = nextStop.location?.timezone!;
      if (this.isInValidDate(startApptDate!, endApptDate!)) {
        errors[i].push({
          type: 'DATE',
          customMsg: true,
          fieldName: `stops.${i}.appointmentStartDate`,
          message:
            'Please ensure that the Start date & time of an earlier stop is not after the End date & time of a later stop.',
        });
        errorCount++;
      }
      //check if a stop appointment end date is note before appointment start date
      minDate = moment.min([moment(minDate), moment(endApptDate)]).format();
      if (moment(endApptDate).isBefore(minDate) && stops?.length > 2) {
        errors[i].push({
          type: 'DATE',
          customMsg: true,
          fieldName: `stops.${i}.appointmentStartDate`,
          message:
            'Please ensure that the Start date & time of an earlier stop is not after the End date & time of a later stop.',
        });
        errorCount++;
      }
    }
    for (let i = 0; i < stops.length; i++) {
      const stop = stops[i];
      const startApptDate = appendLoadStopDateTime(
        stop?.appointmentStartDate!,
        stop?.appointmentStartTime!,
        stop?.location?.timezone!
      );
      if (stop.type === 'RELAY') {
        //check if a relay stop pickup appointment date is note before dropoff appointment date
        if (
          this.isInValidDate(
            appendLoadStopDateTime(
              stop?.relayDropOffAppointmentStartDate!,
              stop?.relayDropOffAppointmentStartTime!,
              stop?.location?.timezone!
            )!,
            appendLoadStopDateTime(
              stop?.appointmentEndDate!,
              stop?.appointmentEndTime!,
              stop?.location?.timezone!
            )!
          )
        ) {
          errors[i].push({
            type: 'DATE',
            customMsg: true,
            fieldName: `stops.${i}.relayDropOffAppointmentStartDate`,
            message:
              'Please ensure that the Start date & time of Trailer DropOff is not after the End date & time of Pickup.',
          });
          errorCount++;
        }
        //check if a relay stop dropoff appointment end date is note before dropoff appointment start date
        if (
          this.isInValidDate(
            appendLoadStopDateTime(
              stop?.relayDropOffAppointmentStartDate!,
              stop?.relayDropOffAppointmentStartTime!,
              stop?.location?.timezone!
            )!,
            appendLoadStopDateTime(
              stops[i]?.relayDropOffAppointmentEndDate!,
              stops[i]?.relayDropOffAppointmentEndTime!,
              stops[i]?.location?.timezone!
            )!
          )
        ) {
          errors[i].push({
            type: 'DATE',
            customMsg: true,
            fieldName: `stops.${i}.relayDropOffAppointmentStartDate`,
            message:
              'Please ensure that the Start date & time is not after the End date & time.',
          });
          errorCount++;
        }
      } else {
        if (
          this.isInValidDate(
            startApptDate!,
            appendLoadStopDateTime(
              stops[i]?.appointmentEndDate!,
              stops[i]?.appointmentEndTime!,
              stops[i]?.location?.timezone!
            )!
          )
        ) {
          errors[i].push({
            type: 'DATE',
            customMsg: true,
            fieldName: `stops.${i}.appointmentStartDate`,
            message:
              'Please ensure that the Start date & time is not after the End date & time.',
          });
          errorCount++;
        }
      }
    }
    return { errors, errorCount };
  }

  isInValidDate(date1: string | Moment, date2: string | Moment) {
    return moment(date2).isBefore(date1);
  }

  appendStopDateAndTime(date: string, time: string) {
    if (!time) return date;
    const timeSplit = time?.split?.(':');
    const hours = timeSplit?.[0];
    const minutes = timeSplit?.[1];
    const t = moment(date)
      .set({
        hours: Number(hours ?? 0),
        minutes: Number(minutes ?? 0),
      })
      .format?.();
    return t;
  }
}

const stopFormService = new StopFormService();
export { stopFormService };
