import StorageManager from '../../../StorageManager/StorageManager';

export class QueryParams {
  organizationId!: number;
  userId!: number;
  dispatcherId!: number;

  constructor(setOrgId = true) {
    if (setOrgId) {
      let organizationId;
      const userStorage = StorageManager.getUser();
      if (userStorage) {
        organizationId = userStorage.organizationId;
      }
      if (!this.organizationId && organizationId) {
        this.organizationId = organizationId;
      }
    }
  }

  getParamsFromRequest(request: QueryParams = this): object {
    const entries = Object.entries(request);
    const params: {
      [propsKey: string]: any;
    } = {};
    for (const pair of entries) {
      let value = '';
      if (Array.isArray(pair[1])) {
        value = pair[1].join(',');
      } else {
        value = pair[1];
      }
      params[pair[0]] = value;
    }
    return params;
  }

  addUserIdToParams() {
    const user = StorageManager.getUser();
    if (user) {
      this.userId = user.id;
    }
  }

  addDispatcherIdToParams() {
    const dispatcher = StorageManager.getUser();
    if (dispatcher) {
      const { id } = dispatcher;
      this.dispatcherId = id;
    }
  }
}

export class ByIdQueryParams extends QueryParams {
  id: string;
  constructor(id: string) {
    super();
    this.id = id;
  }
}
