import React from 'react';
import { eldService } from '../../../../api';
import { GetAxeleTractorsRequest } from '../../../../models/DTOs/ELD/Requests';
import SingleAutocomplete from '../../../../ui-kit/components/SingleAutocomplete';
import { useRootStore } from '../../../../store/root-store/rootStateContext';

export const AxeleTractorMapping = ({
  rowDetails,
  defaultValue,
  linkTractor,
  createNewTractor,
  showCreateTractorBtn,
}: IAxeleTractorMapping) => {
  const { getGlobalTerminalsIds } = useRootStore();

  const handleChange = (values: any) => {
    if (values) {
      if (values?.tractorName === 'Create New Tractor') {
        createNewTractor(rowDetails, values);
      } else {
        linkTractor(rowDetails?.id, values?.id);
      }
    }
  };

  const getOptions = async (name: string, pageNumber: number) => {
    const request = new GetAxeleTractorsRequest();
    request.pageNumber = pageNumber;
    request.pageSize = 25;
    request.axeleIdFilters = name;
    request.terminalIds = getGlobalTerminalsIds;
    const res = await eldService.getAxeleTractors(request);
    const tractorValues = res?.content;
    const modifiedValues: any = [];
    tractorValues?.length > 0 &&
      tractorValues.forEach((el: any) => {
        const values = {
          id: el.id,
          organizationId: el.organizationId,
          status: el.status,
          terminalId: el.terminalId,
          tractorName: el?.terminal
            ? el.tractorName + ` (${el?.terminal?.companyName})`
            : el.tractorName,
          vin: el.vin,
        };
        modifiedValues.push(values);
      });
    showCreateTractorBtn &&
      pageNumber === 1 &&
      modifiedValues.unshift({ tractorName: 'Create New Tractor' });
    return modifiedValues;
  };

  const textInputStyles = {
    outline: 'none',
    '.MuiInput-root': {
      '&:hover': {
        '&:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
      },
      '&::before': {
        borderBottom: 'none',
      },
    },
  };

  const dropdownStyles = {
    width: '100%',
    '.MuiDataGrid-cell': {
      outline: 'none',
    },
    '.MuiInput-root': {
      '&::after': {
        border: 'none',
      },
    },
    '.MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  };

  return (
    <>
      <SingleAutocomplete
        name={'tractorName'}
        fieldName={'tractorName'}
        label={''}
        getOptions={getOptions}
        value={defaultValue}
        customTextInputStyles={textInputStyles}
        customStyles={dropdownStyles}
        onChangeCb={handleChange}
      />
    </>
  );
};

export interface IAxeleTractorMapping {
  defaultValue: null | IDefaultValue;
  rowDetails: IRowDetails;
  linkTractor?: any;
  createNewTractor?: any;
  showCreateTractorBtn?: boolean;
}

export interface IDefaultValue {
  tractorName: string;
}

export interface IRowDetails {
  AxeleTractorName: string | null;
  AxeleVIN: string;
  ELDTractorName: string;
  ELDTractorVIN: string;
  Terminal: string;
  id: string;
}

export interface IValues {
  id: number;
  licensePlateNumber: string | null;
  licenseValidTill: string | null;
  organizationId: number;
  ownedCompany: string | null;
  ownershipType: string | null;
  status: string;
  terminal: ITerminals;
  terminalId: string;
  tractorName: string;
  vin: string;
}

export interface ITerminals {
  address: string;
  companyName: string;
  dot: string;
  id: string;
  organizationId: number;
}
