import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';
import {
  ContactSummary,
  DefaultTractorsTypesListSummaryDTO,
  DefaultTrailersTypesListSummaryDTO,
  QueryParams,
  TractorAlertQueryParamsRequest,
  TractorAlertSummaryDTO,
  TrailerAlertQueryParamsRequest,
  TrailerAlertSummaryDTO,
} from '../../../models';
import { tractorService, trailerService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { IMaintenanceHistory } from '../../../models/DTOs/maintenance/maintenanceHistory/Model';

interface IMaintenanceHistoryFormContext {
  initialData: Partial<IMaintenanceHistory>;

  tractorAlertList: TractorAlertSummaryDTO[];
  trailerAlertList: TrailerAlertSummaryDTO[];
  defaultTractorsTypesList: DefaultTractorsTypesListSummaryDTO[];
  defaultTrailersTypesList: DefaultTrailersTypesListSummaryDTO[];
  newVendorContacts: ContactSummary[];
  openAddNewVendor: boolean;
  openAddNewContact: boolean;

  setTractorAlertList?: Dispatch<SetStateAction<TractorAlertSummaryDTO[]>>;
  setTrailerAlertList?: Dispatch<SetStateAction<TrailerAlertSummaryDTO[]>>;
  setDefaultTractorsTypesList?: Dispatch<
    SetStateAction<DefaultTractorsTypesListSummaryDTO[]>
  >;
  setDefaultTrailersTypesList?: Dispatch<
    SetStateAction<DefaultTrailersTypesListSummaryDTO[]>
  >;
  setNewVendorContacts?: React.Dispatch<React.SetStateAction<ContactSummary[]>>;
  setOpenAddNewVendor?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddNewContact?: React.Dispatch<React.SetStateAction<boolean>>;

  getTractorAlertList?: (
    tractorId: number
  ) => Promise<TractorAlertSummaryDTO[]>;
  getTrailerAlertList?: (
    trailerId: number
  ) => Promise<TrailerAlertSummaryDTO[]>;
  getDefaultTractorsTypesList?: () => Promise<
    DefaultTractorsTypesListSummaryDTO[]
  >;
  getDefaultTrailersTypesList?: () => Promise<
    DefaultTrailersTypesListSummaryDTO[]
  >;

  // Helper
  getTractorAlert?: (
    maintenanceItemId: string,
    id: number
  ) => TractorAlertSummaryDTO | undefined;

  getTrailerAlert?: (
    maintenanceItemId: string,
    id: number
  ) => TrailerAlertSummaryDTO | undefined;
}

export const MaintenanceHistoryFormContext =
  React.createContext<IMaintenanceHistoryFormContext>({
    tractorAlertList: [],
    trailerAlertList: [],
    defaultTractorsTypesList: [],
    defaultTrailersTypesList: [],
    initialData: {},
    newVendorContacts: [],
    openAddNewVendor: false,
    openAddNewContact: false,
  });

export const MaintenanceFormContextWrapper: FC<
  PropsWithChildren<{
    initialData: Partial<IMaintenanceHistory>;
  }>
> = ({ children, initialData }) => {
  const [tractorAlertList, setTractorAlertList] = useState<
    TractorAlertSummaryDTO[]
  >([]);

  const [trailerAlertList, setTrailerAlertList] = useState<
    TrailerAlertSummaryDTO[]
  >([]);

  const [defaultTractorsTypesList, setDefaultTractorsTypesList] = useState<
    DefaultTractorsTypesListSummaryDTO[]
  >([]);

  const [defaultTrailersTypesList, setDefaultTrailersTypesList] = useState<
    DefaultTrailersTypesListSummaryDTO[]
  >([]);

  const [newVendorContacts, setNewVendorContacts] = useState<ContactSummary[]>(
    []
  );

  const [openAddNewVendor, setOpenAddNewVendor] = useState<boolean>(false);
  const [openAddNewContact, setOpenAddNewContact] = useState<boolean>(false);

  const getTractorAlertList = async (
    tractorId: number
  ): Promise<TractorAlertSummaryDTO[]> => {
    try {
      const requestData = new TractorAlertQueryParamsRequest(tractorId);
      const data = await tractorService.getTractorAlertList(requestData);

      if (!data || data instanceof ServiceError) {
        return [];
      } else {
        setTractorAlertList(data);
        return data;
      }
    } catch (error) {
      return [];
    }
  };

  const getTrailerAlertList = async (
    trailerId: number
  ): Promise<TrailerAlertSummaryDTO[]> => {
    try {
      const requestData = new TrailerAlertQueryParamsRequest(trailerId);
      const data = await trailerService.getTrailerAlertList(requestData);

      if (!data || data instanceof ServiceError) {
        return [];
      } else {
        setTrailerAlertList(data);
        return data;
      }
    } catch (error) {
      return [];
    }
  };

  const getDefaultTractorsTypesList = async (): Promise<
    DefaultTractorsTypesListSummaryDTO[]
  > => {
    try {
      const requestData = new QueryParams();
      const data = await tractorService.getDefaultTractorsTypesList(
        requestData
      );

      if (!data || data instanceof ServiceError) {
        return [];
      } else {
        setDefaultTractorsTypesList(data);
        return data;
      }
    } catch (error) {
      return [];
    }
  };

  const getDefaultTrailersTypesList = async (): Promise<
    DefaultTrailersTypesListSummaryDTO[]
  > => {
    try {
      const requestData = new QueryParams();
      const data = await trailerService.getDefaultTrailersTypesList(
        requestData
      );

      if (!data || data instanceof ServiceError) {
        return [];
      } else {
        setDefaultTrailersTypesList(data);
        return data;
      }
    } catch (error) {
      return [];
    }
  };

  const getTractorAlert = (
    maintenanceItemId: string,
    id: number
  ): TractorAlertSummaryDTO | undefined => {
    return tractorAlertList.find(
      ({ maintenanceTypeId, tractorId }) =>
        maintenanceTypeId === maintenanceItemId && id === tractorId
    );
  };

  const getTrailerAlert = (
    maintenanceItemId: string,
    id: number
  ): TrailerAlertSummaryDTO | undefined => {
    return trailerAlertList.find(
      ({ maintenanceTypeId, trailerId }) =>
        maintenanceTypeId === maintenanceItemId && id === trailerId
    );
  };

  return (
    <MaintenanceHistoryFormContext.Provider
      value={{
        initialData,

        tractorAlertList,
        trailerAlertList,
        defaultTractorsTypesList,
        defaultTrailersTypesList,
        newVendorContacts,
        openAddNewVendor,
        openAddNewContact,

        setTractorAlertList,
        setTrailerAlertList,
        setDefaultTractorsTypesList,
        setDefaultTrailersTypesList,
        setNewVendorContacts,
        setOpenAddNewVendor,
        setOpenAddNewContact,

        getTractorAlertList,
        getTrailerAlertList,
        getDefaultTractorsTypesList,
        getDefaultTrailersTypesList,

        getTractorAlert,
        getTrailerAlert,
      }}
    >
      {children}
    </MaintenanceHistoryFormContext.Provider>
  );
};
