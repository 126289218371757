import { expenceService, loadService, userService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  FuelTypesListRequest,
  GetExpenseCategoriesListRequest,
  GetExpenseShortListByCardNumberRequest,
  GetExpenseShortListRequest,
  GetNamesRequest,
  PaginatedLoadListRequest,
} from '../../../models';
import Controller from '../../../subPages/tractors/Controller';
import TrailerController from '../../../subPages/trailers/TrailerController';

export const tractorList: any = (
  name: string,
  pageNumber: number,
  terminalIds: string[],
  excludeStatusList: string[] = []
) =>
  Controller.instance().getTractorLightListByNameSearch(
    name,
    pageNumber,
    terminalIds,
    excludeStatusList
  );

export const trailerList: any = (
  name: string,
  pageNumber: number,
  terminalIds: string[],
  excludeStatusList: string[] = []
) =>
  TrailerController.instance().getTrailerLightListByNameSearch(
    name,
    pageNumber,
    terminalIds,
    excludeStatusList
  );

export const getExpensesListShort = async (
  name: string,
  pageNumber: number,
  terminalIds: string[],
  categoryKey?: string[],
  excludeCategoryKey?: string[],
  hasLinkedMaintenance?: boolean
) => {
  const request = new GetExpenseShortListRequest(
    name,
    pageNumber,
    25,
    terminalIds,
    categoryKey,
    excludeCategoryKey,
    hasLinkedMaintenance
  );

  const response = await expenceService.getExpenseListShort(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getExpensesListShortByCardNumber = async (
  cardNumber: string,
  pageNumber: number,
  terminalIds: string[],
  categoryKey?: string[],
  excludeCategoryKey?: string[]
) => {
  const request = new GetExpenseShortListByCardNumberRequest(
    cardNumber,
    pageNumber,
    25,
    terminalIds,
    categoryKey,
    excludeCategoryKey
  );

  const response = await expenceService.getExpenseListShortByCardNumber(
    request
  );
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getExpenseCategoriesList = async (
  name: string,
  pageNumber: number,
  excludeCategoryKey?: string
) => {
  const request = new GetExpenseCategoriesListRequest(
    name,
    excludeCategoryKey,
    pageNumber
  );
  const response = await expenceService.getExpenseCategoriesList(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getFuelTypesList = async (name: string) => {
  const request = new FuelTypesListRequest(name);
  const response = await expenceService.getFuelTypesList(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return { content: response, last: true };
  }
};

export const getLoadList = async (
  name: string,
  pageNumber: number,
  terminalIds: string[]
) => {
  const request = new PaginatedLoadListRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.seqNumber = name;
  request.terminalIds = terminalIds;

  const response = await loadService.getLoadLightListByNameSearch(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getDriverNameListShort = async (
  name: string,
  pageNumber: number
) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.name = name;
  request.roleCodeList = ['DR'];
  request.excludeStatuses = [0];

  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};
