import { Close } from '@mui/icons-material';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../common/Accordion';
import DriverAssignmentToEntity from '../../../common/EquipmentTabPanel/tabs/General/components/DriverAssignmentToEntity';
import { equipmentPage } from '../../../common/PendoClassIDs/constants';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';
import { FormAddress } from '../../../common/Ui/AddressField';
import { statesinCountry } from '../../../common/Ui/AddressField/constants/states';
import ButtonGroupRadio from '../../../common/Ui/ButtonGroup';
import { DateRangePickerForm } from '../../../common/Ui/DateRangePicker/DateRangePickerForm';
import DatePicker from '../../../common/Ui/FormDatePicker/FormDatePicker';
import FormPhoneField from '../../../common/Ui/FormPhoneField';
import { IconBtnWrapper } from '../../../common/Ui/IconBtnWrapper/IconBtnWrapper';
import Select from '../../../common/Ui/Select';
import { SingleAutocompleteForm } from '../../../common/Ui/SingleAutocomplete';
import Switch from '../../../common/Ui/Switcher';
import TextField from '../../../common/Ui/TextField/TextField';
import { OptionType } from '../../../models';
import { AssignDriverIcon } from '../../../ui-kit/components/Assets';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import TextInput from '../../../ui-kit/components/TextField';
import { isHasPermission } from '../../../utils';
import { getFuelTypesList } from '../../../views/finance/expenses/utils';
import TerminalController from '../../../views/settings/terminals/TerminalController';
import { documentUploadSummaryRendererUpdated } from '../../shared/equipment';
import Controller from '../Controller';
import { Tractor } from '../models/Tractor';
import { isInactive } from '../utils';

const tractorSources = [
  { key: 'MANUAL', value: 'Manual' },
  { key: 'ELD', value: 'ELD' },
  { key: 'DRIVER', value: 'Driver' },
];
const btnStyle = {
  width: '100%',
  height: '40px',
};

export default function GeneralTabDetails({
  expanded,
  control,
  onAssign,
  data,
  setOpenDialog,
  setShowPopup,
  disableAllFields,
}: {
  expanded: boolean;
  control: any;
  onAssign: (driverGroupId: string) => void;
  data: Tractor;
  setOpenDialog: React.Dispatch<
    React.SetStateAction<'' | 'markAvailable' | 'markUnavailable' | 'delete'>
  >;
  setShowPopup: React.Dispatch<
    React.SetStateAction<
      | {
          allow?: boolean | undefined;
          body?: string | undefined;
        }
      | null
      | undefined
    >
  >;
  disableAllFields?: boolean;
}) {
  const {
    yearOptions,
    brakingOptions,
    tollProviderOptions,
    ownershipTypeOptions,
  } = Controller.instance();

  const [isDriverAccordionOpen, setIsDriverAccordionOpen] =
    useState<boolean>(false);

  const { terminals } = TerminalController.instance();

  const { watch, setValue } = useFormContext();

  const isLastLocation = watch('lastLocation');
  const locationSourceName = watch('locationSource');
  const registerDocumentId =
    watch('assignedDocuments')?.Registration?.[0]?.documentId;
  const insuranceDocumentId =
    watch('assignedDocuments')?.Insurance?.[0]?.documentId;

  useEffect(() => {
    if (isLastLocation?.address) {
      setValue('locationSource', tractorSources[0].key);
    }
  }, [isLastLocation]);

  const id = data?.id;
  const driver = data?.driverGroup;
  const status = data?.status;

  const onUnassignDriver = () => {
    setOpenDialog('markAvailable');
    setShowPopup({ allow: true });
  };

  const onAssignDriver = (driverGroupId: string) => {
    onAssign(driverGroupId);
    setIsDriverAccordionOpen(false);
  };

  const noPermission = !isHasPermission([
    id
      ? AXELE_PERMISSION_TYPE.EQUIPMENT_EDIT
      : AXELE_PERMISSION_TYPE.EQUIPMENT_ADD,
  ]);
  const noPermissionForAssignDriver = !isHasPermission([
    AXELE_PERMISSION_TYPE.EQUIPMENT_EDIT,
  ]);
  // @TODO: Anna and Anoush add different component for btn

  const disabledField = noPermission || disableAllFields || isInactive(status);

  const newOwnershipTypeOptions: OptionType[] = ownershipTypeOptions?.map(
    (item: OptionType) => ({ value: item.key!, key: item.value })
  );
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <>
        {id && (
          <Box
            sx={{
              marginTop: '11px',
              padding: '0 10px',
            }}
          >
            {driver ? (
              <Box
                sx={{
                  marginTop: '12px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <TextInput
                  id={equipmentPage + 'Driver'}
                  disabled={noPermissionForAssignDriver || disableAllFields}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <Box component="span" sx={{ marginRight: '10px' }}>
                        <AssignDriverIcon color="rgba(0, 17, 34, 0.6)" />
                      </Box>
                    ),
                  }}
                  label={t('driver')}
                  variant="standard"
                  styleProps={{ width: '119%' }}
                  defaultValue={driver.name}
                />

                <IconBtnWrapper
                  disabled={disableAllFields || noPermissionForAssignDriver}
                  onClick={onUnassignDriver}
                  sx={{ height: 30, minWidth: 30 }}
                >
                  <Close sx={{ width: '100%' }} />
                </IconBtnWrapper>
              </Box>
            ) : (
              <ButtonImproved
                id={equipmentPage + 'AssignDriver'}
                disabled={disableAllFields || noPermissionForAssignDriver}
                onClick={() => setIsDriverAccordionOpen(true)}
                size="medium"
                color="primary"
                label={t('assignDriver')}
                startIcon={<AssignDriverIcon />}
                variant="contained"
                styleProps={btnStyle}
              />
            )}
            {isDriverAccordionOpen && status === 'AVAILABLE' && (
              <DriverAssignmentToEntity
                onAssign={onAssignDriver}
                data={data}
                onClose={() => setIsDriverAccordionOpen(false)}
                entity={'TRACTOR'}
              />
            )}
          </Box>
        )}
        <Accordion name={t('registration')}>
          <TextField
            id={equipmentPage + 'Tractor'}
            disabled={disabledField}
            control={control}
            name="tractorName"
            label={t('tractor') + ' #'}
            required
            sizes={{ xs: expanded ? 3 : 6 }}
          />
          <TextField
            id={equipmentPage + 'VIN'}
            disabled={disabledField}
            control={control}
            name="vin"
            label={t('vin')}
            inputProps={{ maxlength: 17 }}
            sizes={{ xs: expanded ? 3 : 6 }}
          />
          <TextField
            id={equipmentPage + 'Registration'}
            disabled={disabledField}
            control={control}
            name="licensePlateNumber"
            label={t('registration') + ' #'}
            sizes={{ xs: expanded ? 2 : 4 }}
          />
          <Select
            id={equipmentPage + 'State'}
            disabled={disabledField}
            control={control}
            name="licenseState"
            label={t('state')}
            options={statesinCountry}
            fieldName="name"
            fieldValue="value"
            sizes={{ xs: expanded ? 2 : 4 }}
            style={{ marginTop: '3px' }}
          />
          <DatePicker
            disabled={disabledField}
            control={control}
            disabledText
            name="licenseValidTill"
            label={t('regExpiry')}
            sizes={{ xs: expanded ? 2 : 4 }}
            icon={DateRangeRoundedIcon}
            allowEmptyDate
          />
          {documentUploadSummaryRendererUpdated(
            'Registration',
            control,
            registerDocumentId,
            disableAllFields
          )}
        </Accordion>
        <Accordion name={t('tracking')}>
          <FormAddress
            disabled={
              locationSourceName === tractorSources[2].key || disabledField
            }
            control={control}
            name="lastLocation"
            sizes={{ xs: expanded ? 4 : 12 }}
            label={t('location')}
          />
          <Select
            id={equipmentPage + 'Source'}
            control={control}
            name="locationSource"
            label={t('source')}
            options={tractorSources}
            fieldName="value"
            fieldValue="key"
            disabled
            sizes={{ xs: expanded ? 2 : 6 }}
            style={{ marginTop: '3px' }}
          />
          <DatePicker
            disabled
            control={control}
            name="lastUpdated"
            label={t('lastUpdated')}
            sizes={{ xs: expanded ? 2 : 6 }}
          />
          <TextField
            id={equipmentPage + 'Odometer'}
            disabled={disabledField}
            control={control}
            name="currentOdometer"
            label={t('lastOdometer')}
            sizes={{ xs: expanded ? 2 : 6 }}
          />
        </Accordion>
        <Accordion name={t('ownership')}>
          <TextField
            id={equipmentPage + 'OwnedBy'}
            disabled={disabledField}
            control={control}
            name="ownedCompany"
            label={t('ownedBy')}
            sizes={{ xs: expanded ? 3 : 12 }}
          />
          <FormPhoneField
            id={equipmentPage + 'Phone'}
            disabled={disabledField}
            control={control}
            name="phone"
            label={t('phone')}
            sizes={{ xs: expanded ? 4 : 12 }}
            phoneFieldStyleProps={expanded ? undefined : { width: '100%' }}
          />
          <TextField
            id={equipmentPage + 'OwnerEmail'}
            disabled={disabledField}
            control={control}
            name="ownerEmail"
            label={t('ownerEmail')}
            sizes={{ xs: expanded ? 4 : 12 }}
          />
          <FormAddress
            disabled={disabledField}
            control={control}
            name="ownerAddress"
            sizes={{ xs: expanded ? 3 : 12 }}
            autoFocus={false}
          />
          {!!terminals?.length && (
            <Select
              id={equipmentPage + 'Terminal'}
              disabled={disabledField}
              control={control}
              name="terminalId"
              label={t('terminal')}
              options={terminals}
              fieldValue="id"
              fieldName="name"
              sizes={{ xs: expanded ? 3 : 12 }}
            />
          )}
          <ButtonGroupRadio
            disabled={disabledField}
            control={control}
            name="ownershipType"
            options={newOwnershipTypeOptions}
            fieldName={'key'}
            fieldValue={'key'}
          />
        </Accordion>
        <Accordion name={t('tractorDetails')}>
          <TextField
            id={equipmentPage + 'Make'}
            disabled={disabledField}
            control={control}
            name="make"
            label={t('make')}
            sizes={{ xs: expanded ? 2 : 4 }}
          />
          <TextField
            id={equipmentPage + 'Model'}
            disabled={disabledField}
            control={control}
            name="model"
            label={t('model')}
            sizes={{ xs: expanded ? 2 : 4 }}
          />
          <Select
            id={equipmentPage + 'Year'}
            disabled={disabledField}
            control={control}
            name="year"
            label={t('year')}
            options={yearOptions}
            fieldName="value"
            fieldValue="key"
            sizes={{ xs: expanded ? 2 : 4 }}
            style={{ marginTop: '3px' }}
          />
          <Select
            id={equipmentPage + 'TollProvider'}
            disabled={disabledField}
            control={control}
            name="tollProvider"
            label={t('tollProvider')}
            options={tollProviderOptions}
            fieldName="value"
            fieldValue="key"
            sizes={{ xs: expanded ? 3 : 6 }}
            style={{ marginTop: '3px' }}
          />
          <TextField
            id={equipmentPage + 'TollTag'}
            disabled={disabledField}
            control={control}
            name="tollTagNumber"
            label={t('tollTag') + ' #'}
            sizes={{ xs: expanded ? 3 : 6 }}
          />
          <TextField
            id={equipmentPage + 'FuelCapacity'}
            disabled={disabledField}
            control={control}
            name="fuelCapacity"
            label={t('fuelCapacity')}
            sizes={{ xs: expanded ? 3 : 6 }}
          />
          <SingleAutocompleteForm
            id={equipmentPage + 'FuelType'}
            disabled={disabledField}
            name="fuelType"
            label={t('fuelType')}
            fieldName="value"
            getOptions={getFuelTypesList}
            control={control}
            groupBy="categoryName"
            sizes={{ xs: expanded ? 3 : 6 }}
            wrapperStyles={{ marginTop: '3px' }}
          />
          <TextField
            id={equipmentPage + 'GVW'}
            disabled={disabledField}
            control={control}
            name="gvw"
            label={t('gvw') + ' (LBS)'}
            sizes={{ xs: expanded ? 3 : 6 }}
          />
          <ButtonGroupRadio
            disabled={disabledField}
            control={control}
            options={brakingOptions}
            name="braking"
          />
          <Switch
            id={equipmentPage + 'SleeperBerth'}
            disabled={disabledField}
            subLabel={t('sleeperBearthSubLabel')}
            label={t('sleeperBearth')}
            name="isSleeperBerthAvailable"
          />
          <Switch
            id={equipmentPage + 'ManualTransmission'}
            disabled={disabledField}
            subLabel={t('manualTransmissionSubLabel')}
            label={t('manualTransmission')}
            name="transmission"
          />
        </Accordion>
        <Accordion name={t('insurance')}>
          <TextField
            id={equipmentPage + 'Provider'}
            disabled={disabledField}
            control={control}
            name="insuranceCompany"
            label={t('provider')}
            sizes={{ xs: expanded ? 4 : 12 }}
          />
          <DateRangePickerForm
            inputFormat="MM-DD-yyyy"
            clearable={!noPermission}
            disabled={disabledField}
            control={control}
            name="insuranceDate"
            startText={`${t('validfrom')}`}
            toText="-"
            endText={`${t('validto')}`}
            sizes={{ xs: 12 }}
          />
          {documentUploadSummaryRendererUpdated(
            'Insurance',
            control,
            insuranceDocumentId,
            disableAllFields
          )}
        </Accordion>
      </>
    );
  }
}
