import { FC } from 'react';
import { invoiceService, loadService } from '../../../../../../api';
import {
  NOTE_TYPE,
  OnHoldStateInvoiceRequest,
  OnHoldStateInvoiceResponse,
  OnHoldStateLoadRequest,
  OnHoldStateLoadResponse,
} from '../../../../../../models';
import { LoadSummary } from '../../../../../../subPages/loadsList/models/LoadSummary';
import { E3DotMenuType } from '../../../../../../views/myLoads/components/LoadDetailDialog/types';
import FormDialog from '../../../../../Ui/FormDialog';
import { createNotes } from '../../../Notes/constants/helper';
import { HoldLoadPopupForm } from './HoldLoadPopupForm';
import { EHoldLoadEntityType, HoldLoadValidationSchema } from './utils';
import { ELoadType } from '../../../../../../subPages/loadsList/constants';
import { InvoiceModel } from '../../../../../../subPages/invoices/models/InvoiceModel';
import { EInvoiceType } from '../../../../../../subPages/invoices/constants';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';

export interface HoldLoadPopupFormData {
  notesText: string;
  shareDriver: boolean;
  attachment?: any;
}

export interface HoldLoadPopupProps {
  onClose: () => void;
  actionType: E3DotMenuType;
  loadData?: Pick<LoadSummary, 'id' | 'loadType'>;
  invoiceData?: Pick<InvoiceModel, 'id' | 'invoiceType'>;
  entityType: EHoldLoadEntityType;
  onActionCompleted?: () => void;
}

export const HoldLoadPopup: FC<HoldLoadPopupProps> = ({
  onClose,
  actionType,
  loadData,
  invoiceData,
  entityType,
  onActionCompleted,
}) => {
  const defaultFormData: HoldLoadPopupFormData = {
    notesText: '',
    shareDriver: false,
  };

  const contentRenderer = () => {
    return <HoldLoadPopupForm />;
  };

  const createNewNotesForLoadHandler = async (
    formData: HoldLoadPopupFormData
  ) => {
    if (!loadData?.id) return;
    try {
      await createNotes(
        'createOnHoldLoad',
        {
          notesText: formData.notesText,
          shareDriver: !!formData.shareDriver,
          attachment: formData.attachment,
          notesType:
            actionType === E3DotMenuType.MARK_AS_HOLD
              ? NOTE_TYPE.ONHOLD
              : NOTE_TYPE.RELEASE,
        },
        loadData.id,
        {},
        !!formData?.attachment,
        defaultFormData
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onStateLoadHandler = async ({ addedNote }: { addedNote: boolean }) => {
    if (!loadData?.id) return;
    const request = new OnHoldStateLoadRequest({
      id: loadData.id,
      onHold: actionType === E3DotMenuType.MARK_AS_HOLD,
      addedNote,
    });
    const response = await loadService.onHoldStateLoad(
      request,
      loadData?.loadType == ELoadType.MANIFEST
    );
    if (
      response instanceof OnHoldStateLoadResponse &&
      response?.message?.includes('successfully')
    ) {
      onActionCompleted?.();
    }
  };

  const onStateInvoiceHandler = async ({
    addedNote,
  }: {
    addedNote: boolean;
  }) => {
    if (!invoiceData?.id) return;
    const request = new OnHoldStateInvoiceRequest({
      id: invoiceData.id,
      onHold: actionType === E3DotMenuType.MARK_AS_HOLD,
      addedNote,
    });
    const response = await invoiceService.onHoldStateLoad(request);
    if (
      response instanceof OnHoldStateInvoiceResponse &&
      response?.message?.includes('successfully')
    ) {
      onActionCompleted?.();
    }
  };

  const onAction = async (formData: HoldLoadPopupFormData) => {
    if (entityType === EHoldLoadEntityType.LOAD) {
      await createNewNotesForLoadHandler(formData);
      await onStateLoadHandler({ addedNote: true });
    } else if (entityType === EHoldLoadEntityType.INVOICE) {
      //invoice handler
      await createNewNotesForLoadHandler(formData);
      await onStateInvoiceHandler({ addedNote: true });
    }
  };
  const onSecondaryAction = async (formData: HoldLoadPopupFormData) => {
    if (entityType === EHoldLoadEntityType.LOAD) {
      await onStateLoadHandler({ addedNote: false });
    } else {
      //invoice handler
      await onStateInvoiceHandler({ addedNote: false });
    }
  };

  const titleText =
    actionType === E3DotMenuType.MARK_AS_HOLD ? `Mark as Hold` : `Release`;

  if (
    entityType === EHoldLoadEntityType.INVOICE &&
    invoiceData?.invoiceType !== EInvoiceType.LOAD
  ) {
    //show confirmation popup, only for Invoice scope
    return (
      <DeletePopup
        width={400}
        title={titleText}
        body={`Are you sure you want to ${
          actionType === E3DotMenuType.MARK_AS_HOLD ? 'hold' : 'release'
        } this invoice ?`}
        open={true}
        cancelText={'Cancel'}
        backgroundColor={'primary.main'}
        onClose={onClose}
        onAction={onSecondaryAction}
        buttonText={
          actionType === E3DotMenuType.MARK_AS_HOLD ? 'Hold' : 'Release'
        }
        buttonTextColor={'primary.main'}
      />
    );
  }
  return (
    <>
      <FormDialog
        width={482}
        data={defaultFormData}
        titleText={titleText}
        actionLabel="Save"
        open
        onAction={onAction}
        handleClose={onClose}
        contentRenderer={contentRenderer}
        secondaryAction={{
          actionLabel: 'Skip',
          onAction: onSecondaryAction,
          checkIsDirty: false,
        }}
        validationSchema={HoldLoadValidationSchema}
      />
    </>
  );
};
