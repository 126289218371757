const click = (node: HTMLAnchorElement) => {
  node.dispatchEvent(new MouseEvent('click'));
};

const download = async (url: string, name: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((responseBlob) => saveAs(responseBlob, name))
    .catch(console.error);
};

export const saveAs = (blob: string | Blob, name = 'download') => {
  const anchorElement = document.createElementNS(
    'http://www.w3.org/1999/xhtml',
    'a'
  ) as HTMLAnchorElement;
  anchorElement.download = name;
  anchorElement.rel = 'noopener';

  if (typeof blob === 'string') {
    anchorElement.href = blob;

    if (anchorElement.origin === location.origin) {
      return click(anchorElement);
    }

    fetch(anchorElement.href)
      .then(() => download(blob, name))
      .catch(() => {
        anchorElement.target = '_blank';
        click(anchorElement);
      });
  } else {
    anchorElement.href = URL.createObjectURL(blob);
    setTimeout(() => URL.revokeObjectURL(anchorElement.href), 40);
    setTimeout(() => click(anchorElement));
  }
};
