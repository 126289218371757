import React from 'react';

export const ProgressCircle = (
  <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.92389L1.82196 6.97594C2.19598 6.32634 2.65529 5.73141 3.19335 5.19336C3.98513 4.40158 4.90595 3.78041 5.93176 3.34734C6.99256 2.89896 8.11898 2.67148 9.28259 2.67148C10.4462 2.67148 11.5748 2.89896 12.6334 3.34734C13.6592 3.78041 14.5801 4.40158 15.3718 5.19336C16.1636 5.98513 16.7848 6.90595 17.2178 7.93176C17.6684 8.99038 17.8959 10.119 17.8959 11.2826C17.8959 12.4462 17.6684 13.5748 17.22 14.6334C16.787 15.6592 16.1658 16.5801 15.374 17.3718C14.5822 18.1636 13.6614 18.7848 12.6356 19.2178C11.5748 19.6662 10.4484 19.8937 9.28478 19.8937C8.53456 19.8937 7.79309 19.7975 7.07568 19.6094C6.38233 19.4256 5.71085 19.1544 5.08311 18.8023C3.84514 18.1089 2.78434 17.1094 2.021 15.9108C1.95319 15.8036 1.88758 15.6964 1.82415 15.5871L0 16.6413C0.0787402 16.7791 0.161855 16.9125 0.244969 17.0459C2.15004 20.0249 5.48556 22 9.28259 22C15.2012 22 20 17.2012 20 11.2826C20 5.36396 15.2012 0.565186 9.28259 0.565186C5.31715 0.565186 1.85258 2.7196 0 5.92389Z"
      fill="#001122"
      fillOpacity="0.6"
    />
  </svg>
);

export const AlertError = (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
      fill="#001122"
      fillOpacity="0.6"
    />
  </svg>
);

export const AlertInfo = (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
      fill="#001122"
      fillOpacity="0.6"
    />
  </svg>
);

export const AlertReportProblem = (
  <svg viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 3.99L18.53 17H3.47L11 3.99ZM11 0L0 19H22L11 0ZM12 14H10V16H12V14ZM12 8H10V12H12V8Z"
      fill="#001122"
      fillOpacity="0.6"
    />
  </svg>
);

export const AlertCheckCircle = (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.59 5.58L8 12.17L4.41 8.59L3 10L8 15L16 7L14.59 5.58ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
      fill="#001122"
      fillOpacity="0.6"
    />
  </svg>
);
