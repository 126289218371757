import { action, makeObservable, observable } from 'mobx';
import * as driverGanttFilters from '../../../views/optymization/constants/recommendedTrips.filters';
import * as recommendTripsTypes from '../../../views/optymization/types/recommendTrips.types';
import { ICurrentResourceToReAssign } from '../../../views/optymization/types/possibleResource.types';
import * as DispatchTypes from '../../../types/DispatchTypes';
import * as possibleResourcesFilters from '../../../views/optymization/constants/possibleResources.filters';
import {
  formatGanttStartDate,
  formatLockedAssigmentMetrics,
} from '../../../views/optymization/services/recommendTrips.service';
import { DEFAULT_ZOOM_LEVEL } from '../../../views/optymization/config/gantt.config';
import { GlobalNotificationType } from '../../../constants/globalNotificationMessages';
import { RootStoreInstence } from '../../../store/root-store/rootStateContext';
import { OPTIMIZATION_SOURCES } from '../../../constants/gantt/gantt.const';

type SetDataInputFunction<T> = (data: T) => T;
type SetDataInput<T> = T | SetDataInputFunction<T>;

type IGanttColumnSize = {
  lockedWidth?: number;
  columns?: Array<{ id?: string; width: number }>;
};

const updateData = <T>(data: SetDataInput<T>, oldData: T) => {
  if (typeof data === 'function')
    return (data as SetDataInputFunction<T>)(oldData);
  return data;
};
const defaultDriverGanttStatusFilters = {
  assignedDrivers: 0,
  unAssignedDrivers: 0,
  unAssignedTrips: 0,
  brokeredTrips: 0,
};

type ConflictingResourceType = {
  id: string;
  type: 'ADD' | 'CLEAR';
};

type OptimizationSourceType = OPTIMIZATION_SOURCES;

interface IOptimizationRequestResources {
  driverIds?: Array<string>;
  tripIds?: Array<string>;
}
export class BulkOptymizationStore {
  @observable
  optimizationRequestResources: IOptimizationRequestResources = {
    driverIds: [],
    tripIds: [],
  };
  @observable
  recommendationFilters: recommendTripsTypes.IRecommendedTripFilters =
    driverGanttFilters.defaultRecommendedTripFilters;
  @observable
  possibleResourceFilters: possibleResourcesFilters.IPossibleResourceFilters =
    possibleResourcesFilters.defaultPossibleResourceFilters;
  @observable
  driverGanttStatusesFilters: recommendTripsTypes.IRecommendedTripStatusesFilters =
    defaultDriverGanttStatusFilters;
  @observable resourceToReassign: ICurrentResourceToReAssign | null = null;
  @observable lockedPossibleTrips: Map<string, Array<string>> = new Map();
  @observable lockedPossibleDrivers: Map<string, Array<string>> = new Map();
  @observable ganttColumnSize: IGanttColumnSize = {};
  @observable hosConstants: DispatchTypes.HosConstantsType = {};
  @observable conflictingResource: ConflictingResourceType | null = null;
  @observable ganttStartDate: Date | string = formatGanttStartDate(new Date());
  @observable zoomLevel: number = DEFAULT_ZOOM_LEVEL;
  @observable optimizationStartDate: Date | string = this.ganttStartDate;
  @observable recommnendationGanttRef: any = null;
  @observable cancelOptimization?: boolean = false;
  @observable currentSandboxId?: string | null = null;
  @observable optimizationSource?: OptimizationSourceType | null = null;
  @observable assignmentMetrics: recommendTripsTypes.IAssignmentMetrics = {
    assignedDrivers: 0,
    unAssignedDrivers: 0,
    unAssignedTrips: 0,
  };
  @observable
  lockedAssignmentMetrics: recommendTripsTypes.ILockAssignedMetrices = {
    totalLoadedMiles: 0,
    totalDeadheadMiles: 0,
    totalRevenue: 0,
    countLockedAssignments: 0,
  };
  @observable enableReoptimization = false;

  constructor() {
    makeObservable(this);
  }

  //Filters begin
  @action
  public setResourceToReassign = (
    newValue: SetDataInput<ICurrentResourceToReAssign | null>
  ) => {
    this.resourceToReassign = updateData(newValue, this.resourceToReassign);
  };

  @action
  setDriverGanttStatusesFilters = (
    newValue: SetDataInput<recommendTripsTypes.IRecommendedTripStatusesFilters>
  ) => {
    this.driverGanttStatusesFilters = updateData(
      newValue,
      this.driverGanttStatusesFilters
    );
  };

  @action
  setRecommendationFilters = (
    newValue: SetDataInput<recommendTripsTypes.IRecommendedTripFilters>
  ) => {
    this.recommendationFilters = updateData(
      newValue,
      this.recommendationFilters
    );
  };

  @action onRecommendationFilterChange = (
    selectedValues: any,
    fieldFilterName: any
  ): void => {
    const finalFilters: recommendTripsTypes.IRecommendedTripFilters = {
      ...this.recommendationFilters,
      [fieldFilterName]: selectedValues,
    };

    this.setRecommendationFilters(finalFilters);
  };
  //Filters end

  @action setLockedPossibleTrips = (tripId: string, driverIds: string[]) => {
    this.lockedPossibleTrips.set(tripId, driverIds);
  };
  @action setLockedPossibleDrivers = (driverId: string, tripIds: string[]) => {
    this.lockedPossibleDrivers.set(driverId, tripIds);
  };

  @action setGanttColumnSize = (data: IGanttColumnSize) => {
    this.ganttColumnSize = { ...this.ganttColumnSize, ...data };
  };

  @action setHosConstants = (hosVals: DispatchTypes.HosConstantsType) => {
    this.hosConstants = hosVals;
  };
  @action setConflictingResource = (data: ConflictingResourceType | null) => {
    this.conflictingResource = data;
  };
  @action setPossibleResourceFilters = (
    filters: possibleResourcesFilters.IPossibleResourceFilters
  ) => {
    this.possibleResourceFilters = filters;
  };
  @action setGanttStartDate = (date: Date | string) => {
    this.ganttStartDate = date;
  };
  @action setZoomLevel = (zoom: number) => {
    this.zoomLevel = zoom;
  };
  @action setOptimizationStartDate = (date: Date | string) => {
    this.optimizationStartDate = date;
    this.ganttStartDate = formatGanttStartDate(date);
  };
  @action setRecommendationGanttRef = (ref: any) => {
    this.recommnendationGanttRef = ref;
  };

  @action showToast = (config: GlobalNotificationType) => {
    RootStoreInstence.setNotificationType(config);
  };

  @action setCancelOptimization = (val: boolean) => {
    this.cancelOptimization = val;
  };
  @action setCurrentSandboxId = (id: string | null) => {
    this.currentSandboxId = id;
  };

  @action setOptimizationSource = (src: OptimizationSourceType | null) => {
    this.optimizationSource = src;
  };

  @action setOptimizationRequestResources = (
    data: IOptimizationRequestResources
  ) => {
    this.optimizationRequestResources = data;
  };
  @action resetOptimizationResources = () => {
    this.optimizationRequestResources = {
      driverIds: [],
      tripIds: [],
    };
  };

  @action setKpiMetrics = (data: {
    assignmentMetrics: recommendTripsTypes.IAssignmentMetrics;
    lockedAssignmentMetrics: recommendTripsTypes.ILockAssignedMetrices;
  }) => {
    if (data?.lockedAssignmentMetrics)
      this.lockedAssignmentMetrics = formatLockedAssigmentMetrics(
        data?.lockedAssignmentMetrics
      );
    this.assignmentMetrics = data?.assignmentMetrics;
  };

  @action setEnableReoptimization = (val: boolean) => {
    this.enableReoptimization = val;
  };

  @action clearGlobalStore = () => {
    this.resourceToReassign = null;
    this.recommendationFilters =
      driverGanttFilters.defaultRecommendedTripFilters;
    this.driverGanttStatusesFilters = defaultDriverGanttStatusFilters;
    this.lockedPossibleTrips = new Map();
    this.lockedPossibleDrivers = new Map();
    this.ganttColumnSize = {};
    this.currentSandboxId = null;
    this.hosConstants = {};
    this.conflictingResource = null;
    this.optimizationSource = null;
    this.enableReoptimization = false;
  };
}

export const createBulkOptymizationStore = () => new BulkOptymizationStore();
