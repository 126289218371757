import { QueryParams } from '../commonMixed/QueryParams';
import { Type } from 'class-transformer';

export class GetGlobalTerminalDTO extends QueryParams {
  userId!: number;
  organizationId!: number;
  constructor() {
    super();
  }
}

export class UpdateReceivedDTO extends QueryParams {
  id!: string;
  organizationId!: number;
  receiverId!: number;
  status!: boolean;
}

export class GetNotificationCountDTO extends QueryParams {
  organizationId!: number;
  receiverId!: number;
  notificationType!: string;
  trayReady!: boolean;
  readStatus!: boolean;
}

export class GetNotificationListDTO extends QueryParams {
  organizationId!: number;
  receiverId!: number;
  notificationType!: string;
  trayReady!: boolean;
  pageSize!: number;
  sort!: string;
  pageNumber!: number;
}

export class GetGlobalSerarchDTO extends QueryParams {
  organizationId!: number;
  keys!: string;
}

export class GetLoadByIDDTO extends QueryParams {
  organizationId!: number;
  loadId!: number;
}
