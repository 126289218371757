export const initialState: any = {
  loadedMiles: '',
  emptyMiles: '',
  completeDate: '',
  requiredDocuments: [],
  rate: {
    isContractEnabled: false,
    baseRateType: '',
    rate: 0,
    quantity: 1,
    internalNotes: '',
    messageOnInvoice: '',
    invoiceTerm: '',
    invoiceTermValue: '',
    additionalFees: [
      {
        fee: null,
        quantity: null,
        rate: null,
        description: '',
        unit: '',
        units: [],
      },
    ],
    totalRate: null,
  },
  totalMiles: 0,
  isTotalMilesManuallyUpdate: false,
  isTerminalEnabled: false,
};
