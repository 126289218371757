import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  FilterOptionsState,
  Autocomplete as MuiAutocomplete,
  SxProps,
  Theme,
} from '@mui/material';
import {
  AutocompleteRenderOptionState,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';
import React, {
  ChangeEvent,
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { makeAsteriskRed } from '../Utils';

import InputLabel from '../../InputLabel';
import {
  hideClearStyles,
  StyledOption,
  StyledOptionContent,
  StyledOptionSubTitle,
  StyledOptionTitle,
} from './styles';

export type BaseSingleAutocompleteTypes<TModel> = {
  id?: string;
  name: string;
  getOptions?: (value: string, pageNumber: number) => any;
  options?: any[];
  fieldName: string;
  typedTextName?: string;
  defaultValue?: any;
  label: string;
  onChangeCb?: (data: { [x: string]: string }, name: string) => void;
  customStyles?: SxProps<Theme>;
  customTextInputStyles?: SxProps<Theme>;
  value?: any;
  error?: { message: string };
  disabled?: boolean;
  groupBy?: string;
  freeSolo?: boolean;
  addNewOption?: (text: any, name: string) => void;
  fieldValue?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  customInputProps?: CSSProperties;
  customInputLabelProps?: CSSProperties;
  size?: 'small' | 'medium';
  renderOption?: {
    enableEditIcon?: boolean;
    onCLickEditIconHandler?: (option: TModel) => void;
    enableOptionSubtitle?: boolean;
    renderOptionSubTitle?: (option: TModel) => JSX.Element;
  };
  customRenderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TModel,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  startAdornment?: JSX.Element | null;
  disableClear?: boolean;
  customValue?: string | object;
  styledOption?: SxProps<Theme>;
  readOnly?: boolean;
  forcePopupIcon?: boolean;
  required?: boolean;
  disableFilter?: boolean;
  onCloseCb?: () => void;
  openAsDefault?: boolean;
  disableAutoClose?: boolean;
  PaperComponent?: any;
  fixedLabel?: boolean;
  placeholder?: string;
  customFilterOptions?: (
    options: TModel[],
    state: FilterOptionsState<TModel>
  ) => TModel[];
  autoFocus?: boolean;
};

const SingleAutocomplete = <TModel,>({
  id = '',
  name,
  getOptions,
  options: defaultOptions,
  fieldName,
  typedTextName,
  defaultValue,
  label,
  onChangeCb,
  customStyles = {},
  customTextInputStyles = {},
  value,
  error,
  disabled = false,
  groupBy,
  freeSolo,
  addNewOption,
  fieldValue,
  variant = 'standard',
  customInputProps = {},
  customInputLabelProps = {},
  size,
  renderOption = {
    enableEditIcon: false,
    enableOptionSubtitle: false,
  },
  customRenderOption,
  startAdornment = null,
  disableClear = false,
  customValue,
  styledOption = {},
  readOnly,
  forcePopupIcon = true,
  required,
  disableFilter,
  onCloseCb,
  openAsDefault = false,
  disableAutoClose = false,
  PaperComponent,
  fixedLabel = false,
  placeholder,
  customFilterOptions,
  autoFocus = false,
}: BaseSingleAutocompleteTypes<TModel>) => {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState<boolean>(openAsDefault);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState(false);
  const last = useRef(true);
  const isRequestInProgress = useRef(false);
  const pageNumber = useRef(1);

  const makeRequest = async (val: string, listBoxNode?: any) => {
    if (getOptions) {
      isRequestInProgress.current = true;
      setLoading(true);
      const result = await getOptions(val, pageNumber.current);
      if (!result) return;
      last.current = result.last;
      pageNumber.current++;
      if (listBoxNode && !defaultOptions) {
        setOptions((oldOptions) => {
          const newResults = result.content || result;
          return [...oldOptions, ...newResults];
        });
        setLoading(false);
        listBoxNode.scrollTop =
          listBoxNode.scrollHeight -
          listBoxNode.scrollHeight / (pageNumber.current - 1);
      } else {
        setOptions(result.content || result);
        setLoading(false);
      }
      isRequestInProgress.current = false;
    }
  };

  const handleClose = () => {
    if (disableAutoClose) return;
    setOpen(false);
    setLoading(false);
    setOptions([]);
    setInputValue('');
    onCloseCb?.();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleMakeRequestOnInputChange = async (value: string) => {
    pageNumber.current = 1;
    makeRequest(value);
  };

  const handleChange = (data: any) => {
    if (freeSolo && !addNewOption) {
      const newOptions = [
        ...options,
        { fieldName: { ...data, customCreated: true } },
      ];
      setOptions(newOptions);
    }
    const isExisting =
      options &&
      options.some((option: any) => {
        return inputValue === option[fieldName];
      });
    data && !data.id && addNewOption && addNewOption(data, name);
    if (!isExisting && customValue && inputValue) {
      onChangeCb && onChangeCb(customValue, name);
    } else {
      onChangeCb && onChangeCb(data, name);
    }
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textValue = event.target.value;
    setInputValue(textValue);
    if (typedTextName && typeof onChangeCb === 'function') {
      onChangeCb({ [typedTextName]: textValue }, name);
    }
  };

  useEffect(() => {
    if (open && !defaultOptions) {
      handleMakeRequestOnInputChange(inputValue);
    }
  }, [inputValue, open]);

  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);

  const filter = createFilterOptions<any>({
    ignoreCase: true,
  });

  const getOptionLabel = (option: any) => {
    if (option) {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option[fieldName];
      }
      // Regular option
      return option[fieldName];
    } else {
      return '';
    }
  };

  const filterOptions = (options: any[], params: any) => {
    if (customFilterOptions) return customFilterOptions(options, params);
    if (disableFilter) return options;
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting =
      options &&
      options.some(
        (option: any) =>
          inputValue.toLowerCase() === (option[fieldName] || '').toLowerCase()
      );

    if (freeSolo && inputValue !== '' && !isExisting) {
      filtered.unshift({
        inputValue,
        [fieldName]: `Add "${inputValue}"`,
      });
    }
    return filtered;
  };

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    if (typeof newValue === 'string') {
      handleChange(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      handleChange(newValue.inputValue);
    } else {
      handleChange(newValue);
    }
    return;
  };

  const onCLickEditIcon = useCallback(
    (event: SyntheticEvent<Element, Event>, option: TModel): void => {
      event?.stopPropagation();
      renderOption?.onCLickEditIconHandler?.(option);
    },
    []
  );

  return (
    <MuiAutocomplete
      id={id}
      ListboxProps={{
        onScroll: (event) => {
          const listBoxNode = event.currentTarget;
          if (
            Math.abs(
              listBoxNode.scrollHeight -
                listBoxNode.clientHeight -
                listBoxNode.scrollTop
            ) < 1 &&
            !last.current &&
            !isRequestInProgress?.current
          ) {
            makeRequest(inputValue, listBoxNode);
          }
        },
      }}
      PaperComponent={PaperComponent}
      limitTags={1}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      options={defaultOptions || options}
      value={
        fieldValue && defaultOptions
          ? defaultOptions.find((o) => o[fieldValue] === value)?.[fieldName] ||
            value
          : value
      }
      freeSolo={freeSolo}
      defaultValue={defaultValue}
      loading={loading}
      isOptionEqualToValue={(option: any, value) => {
        if (option) {
          return option[fieldName] === value[fieldName];
        }
        return false;
      }}
      sx={customStyles}
      onChange={handleOnChange}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      groupBy={(option) => groupBy && option[groupBy]}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          defaultValue={defaultValue}
          sx={
            disableClear
              ? {
                  ...makeAsteriskRed,
                  ...customTextInputStyles,
                  ...hideClearStyles,
                }
              : { ...makeAsteriskRed, ...customTextInputStyles }
          }
          required={required}
          size={size}
          onChange={(event) => {
            setInputValue(event.target.value);
            debouncedChangeHandler(event as ChangeEvent<HTMLInputElement>);
          }}
          label={fixedLabel ? <InputLabel>{label}</InputLabel> : label}
          error={!!error}
          onKeyDown={(e: any) => {
            e.code === 'Space' && e.stopPropagation();
          }}
          helperText={error ? error.message : null}
          InputLabelProps={{
            sx: customInputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'disabled',
            style: customInputProps,
            startAdornment:
              params.InputProps.startAdornment || startAdornment ? (
                <>
                  {startAdornment}
                  {params.InputProps.startAdornment}
                </>
              ) : undefined,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      )}
      disabled={disabled}
      renderOption={(
        props: React.HTMLAttributes<HTMLLIElement>,
        option: TModel,
        state: AutocompleteRenderOptionState
      ) => {
        if (customRenderOption) return customRenderOption(props, option, state);
        return (
          <StyledOption
            {...props}
            enableOptionSubtitle={!!renderOption.enableOptionSubtitle}
            sx={styledOption as any}
            key={props.id}
          >
            <StyledOptionContent>
              <StyledOptionTitle>{getOptionLabel(option)}</StyledOptionTitle>
              <StyledOptionSubTitle>
                {renderOption.enableOptionSubtitle ? (
                  <>{renderOption?.renderOptionSubTitle?.(option)}</>
                ) : (
                  <></>
                )}
              </StyledOptionSubTitle>
            </StyledOptionContent>

            {!(option as any)?.inputValue && //freeSolo/addNewOption logic edit icon should not be displayed.
            renderOption.enableEditIcon ? (
              <IconButton
                aria-label="Edit"
                onClick={(event) => onCLickEditIcon(event, option)}
              >
                <EditOutlinedIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </StyledOption>
        );
      }}
      readOnly={readOnly}
      forcePopupIcon={forcePopupIcon}
    />
  );
};

export default SingleAutocomplete;
