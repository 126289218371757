import { createContext, ReactNode, useContext } from 'react';
import { createEvaluationStore } from './EvaluationMode/Evaluation.store';
import {
  createCarrierStore,
  createContractStore,
  createDashboardStore,
  createDispatch2GlobalStore,
  createDispatchTripStore,
  createInvoiceStore,
  createLoadboardStore,
  createMyLoadStore,
  createRolesStore,
  createTerminalStore,
  createTripStore,
} from './pageStores';
import { createBulkOptymizationStore } from './pageStores/optymization/optymization.store';
import { createInvoicePaymentStore } from './pageStores/payment/invoicePayment.store';
import { createPaymentStore } from './pageStores/payment/payment.store';

const RootStore = {
  tripsStore: createTripStore(),
  loadboardStore: createLoadboardStore(),
  myLoadStore: createMyLoadStore(),
  dispatch2TripsStore: createDispatchTripStore(),
  dispatch2GlobalStore: createDispatch2GlobalStore(),
  bulkOptymizationStore: createBulkOptymizationStore(),
  carrierStore: createCarrierStore(),
  dashboardStore: createDashboardStore(),
  evaluationStore: createEvaluationStore(),
  invoiceStore: createInvoiceStore(),
  roleStore: createRolesStore(),
  contractStore: createContractStore(),
  terminalStore: createTerminalStore(),
  invoicePaymentStore: createInvoicePaymentStore(),
  paymentStore: createPaymentStore(),
};

export const StoreContext = createContext<typeof RootStore | undefined>(
  undefined
);

export const PageRootStoreProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  //only create the store once ( store is a singleton)
  const root = RootStore ?? RootStore;
  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
};

export const useStores = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }
  return context;
};
