import React, { FC } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import { ToolTipOffer } from './tooltip';
import { Box, Typography } from '@mui/material';
import { NumberToCurrency } from '../../../../constants/numberToCurrency';
import CustomizedTooltips from '../../../../ui-kit/components/AxeleTooltip';
import { ILoadsearchTableItem } from '../../loadTypes';

type OfferProps = ILoadsearchTableItem['Offer'] & {
  showActualOffersOnly: boolean;
};

const Offer: FC<OfferProps> = ({ showActualOffersOnly, ...Offer }) => {
  return (
    <CustomizedTooltips
      placement={'bottom'}
      titleFirstLine={'firstline'}
      titleSecondLine={'secondline'}
      arrow={true}
      backgroundColor={'transparent'}
      enterDelay={20}
      leaveDelay={10}
      titleFirstIcon={<SettingsOutlinedIcon />}
      titleSecondIcon={<DnsOutlinedIcon />}
      isCustomizationTitle={true}
      component={<ToolTipOffer {...Offer} />}
    >
      <Box>
        <Typography
          className="offer-price"
          sx={{
            color: `${
              Offer.isEstimatedOfferValue ? '#06989D' : 'primary.main'
            }`,
            margin: '0',
            lineHeight: '150%',
            fontSize: '16px',
            alignItems: 'center',
            letterSpacing: '0.15px',
            fontWeight: Offer.isEstimatedOfferValue ? 400 : 800,
          }}
          gutterBottom
        >
          {Offer.isEstimatedOfferValue
            ? !showActualOffersOnly
              ? `~${NumberToCurrency(Offer.value)}`
              : ''
            : NumberToCurrency(Offer.value)}
        </Typography>

        <Typography
          variant="caption"
          gutterBottom
          sx={{ color: 'rgba(0, 17, 34, 0.75)' }}
        >
          <b>{Offer.profits}</b>{' '}
          <span style={{ color: '#000' }}>{Offer.profitPerDay}</span>
        </Typography>
      </Box>
    </CustomizedTooltips>
  );
};

export default React.memo(Offer);
