import { memo } from 'react';
import { IAutocompleteOption } from '../../../../../views/trips/constants/types';
import { GridButtonGroup } from '../../../GridButtonGroup/GridButtonGroup';
import { SxProps } from '@mui/material';

export interface GridButtonGroupFilterProps {
  id?: string;
  column: any;
  defaultFilterValue: Record<string, any>;
  onChangeCb?: (param: IAutocompleteOption[] | null, name: string) => void;
  isFormControl?: boolean;
  field: Record<string, any> | undefined;
  ButtonSxProps?: SxProps;
  customStyles?: SxProps;
  styleProps?: SxProps;
}

export const GridButtonGroupFilter = memo(
  ({
    column,
    defaultFilterValue,
    onChangeCb,
    isFormControl = false,
    field = {},
    styleProps,
  }: GridButtonGroupFilterProps) => {
    const getDefaultOptions = (): IAutocompleteOption[] => {
      const tmpValue = isFormControl
        ? field.value
        : defaultFilterValue[column.name];
      if (Array.isArray(tmpValue)) {
        return tmpValue;
      }
      if (tmpValue) {
        return [tmpValue];
      }
      return [];
    };
    return (
      <GridButtonGroup
        isFormControl={isFormControl}
        multiple={!!column.multiple}
        name={field.name || column.name}
        label={field.label || column.label}
        disabled={column.disabled}
        options={column.options}
        onChange={(
          newValue: IAutocompleteOption[],
          name?: string | undefined
        ) => {
          const cloneNewValue = column.multiple
            ? [...newValue]
            : { ...newValue[0] };
          if (isFormControl) {
            field.onChange(cloneNewValue, name);
            column.onChange && column.onChange(cloneNewValue, name);
          } else {
            onChangeCb &&
              onChangeCb(cloneNewValue as IAutocompleteOption[], name!);
          }
        }}
        defaultOptions={getDefaultOptions()}
        fieldName={column.fieldName}
        fieldValue={column.fieldValue}
        customStyles={styleProps}
      />
    );
  }
);
GridButtonGroupFilter.displayName = 'GridButtonGroupFilter';
