import { ComponentType, FC } from 'react';
import { FloatingReSequenceStopIcon, FloatingReSequenceStopIconProps } from '.';
import { GanttCardLoader } from '../CardLoader';
import { StyledCardWapper } from '../styles';

interface WithResequenceProps {
  reSequenceStopProps: FloatingReSequenceStopIconProps;
  isReOrderActive?: boolean;
  waitForTripPlanNotification?: boolean;
}

const withResequence = <P,>(Component: ComponentType<P>) => {
  const EnhancedComponent: FC<P & WithResequenceProps> = (props) => {
    const {
      reSequenceStopProps,
      isReOrderActive = false,
      waitForTripPlanNotification = false,
    } = props;
    return (
      <StyledCardWapper isReOrderActive={isReOrderActive}>
        <FloatingReSequenceStopIcon {...reSequenceStopProps} />
        {isReOrderActive && waitForTripPlanNotification ? (
          <GanttCardLoader />
        ) : (
          <Component {...props} />
        )}
      </StyledCardWapper>
    );
  };
  return EnhancedComponent;
};

export default withResequence;
