import React, { RefObject, forwardRef, useEffect } from 'react';
import { GanttChart as GC, IGanttchartProps } from '@optym/gantt';
import { Box } from '@mui/material';
import { GANTT_LOADER_CLASSNAME } from '../../constants/gantt.const';
import { OPTYMIZATION_TIMELINE_HEADER_CONFIG } from '../../services/ganttColumns.service';
import { CommonScrollBarStyles } from '../../../../styles/common.styles';

interface OptymizationGanttChartProps extends IGanttchartProps {
  isLoading?: boolean;
}

const OptymizationGanttChart = forwardRef<any, OptymizationGanttChartProps>(
  (props, ref) => {
    useEffect(() => {
      if (!(ref as any)?.current?.instance) return;
      if (props?.isLoading)
        return (
          ref as any
        )?.current?.instance?.columns?.grid?.subGrids?.locked?.currentElement?.classList?.add?.(
          GANTT_LOADER_CLASSNAME
        );
      (
        ref as any
      )?.current?.instance?.columns?.grid?.subGrids?.locked?.currentElement?.classList?.remove?.(
        GANTT_LOADER_CLASSNAME
      );
    }, [props?.isLoading]);

    return (
      <Box sx={CommonScrollBarStyles}>
        <GC
          ref={ref}
          {...props}
          presets={OPTYMIZATION_TIMELINE_HEADER_CONFIG}
        />
      </Box>
    );
  }
);

OptymizationGanttChart.displayName = 'OptymizationGanttChart';

export default OptymizationGanttChart;
