import Box from '@mui/material/Box';
import { UploadIcon } from '../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import FileInfoItem from './FileInfoItem';
import {
  IMPORT_EXPENSE_NAME,
  IMPORT_LOAD_NAME,
  useImport,
} from '../../../../../contexts/ImportContext';
import { fileInfos, importPermissionsMap } from './constants';
import FileTypeSelect from './FileTypeSelect';
import { Permission } from '../../../../../common/Permission';

export default function FileInfo() {
  const {
    handleImportModalOpen,
    currentEntity: { key, name: entityName },
  } = useImport();
  return (
    <>
      {fileInfos[key].map((fileInfo) => (
        <FileInfoItem
          key={fileInfo.key}
          text={fileInfo.text}
          subText={fileInfo.subText}
          icon={fileInfo.icon}
        />
      ))}
      <Permission includes={importPermissionsMap[entityName]}>
        <>
          {(entityName === IMPORT_EXPENSE_NAME ||
            entityName === IMPORT_LOAD_NAME) && (
            <Box sx={{ mb: 2, width: '100%' }}>
              <FileTypeSelect />
            </Box>
          )}
          <Box sx={{ width: '100%' }}>
            <ButtonImproved
              variant={'contained'}
              startIcon={<UploadIcon width={12} height={14} />}
              onClick={handleImportModalOpen}
              label={'Upload'}
              styleProps={{ width: '100%' }}
            />
          </Box>
        </>
      </Permission>
    </>
  );
}
