import { GridColDef } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import momentTimezone from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';
import * as yup from 'yup';
import StorageManager from '../StorageManager/StorageManager';
import { ServiceError } from '../api/interfaces';
import { countryCodeOptions } from '../constants/contacts';
import {
  FORM_DATEPICKER_FORMAT,
  UI_DATETIME_MILITARY_FORMAT,
} from '../constants/date.constants';
import { EDocumentTypes, OptionType } from '../models';
import { TerminalShort } from '../models/common/modelsShort';
import {
  GridColDefSelf,
  IHttpErrorType,
  PhoneType,
  ViewMetaData,
} from '../types';
import { EquipmentType } from '../views/maintenanceRecords/constants/constants';
import { emailValidation } from '../views/settings/terminals/constants/constants';
import { integerFormat } from './grid.utils';

import { AXELE_PERMISSION_TYPE } from '../common/Permission';
import { getHasPermission } from '../common/Permission/utils/helperUtils';
import { useRootStore } from '@/store/root-store/rootStateContext';

export const gridPageSize = 100;
export const loadAndTripPageSize = 50;
const phoneRegExp = /^[0-9]{10}$/;

export function getEmailBodyForSendingPDF(emailFrom: string, pdfTitle: string) {
  const { t, ready } = useTranslation();
  return `<p>${t('dearSirMadam')},<br /><br />
  <p>${t('emailBody')}:<p><br />
  <p>${pdfTitle}</p><br />
  <p>${t('regards')},</p>
  <p>${emailFrom}</p>`;
}

export function getHttpErrorsFromResponse(
  response: ServiceError
): IHttpErrorType[] {
  // here should be added also error status code checking later
  const subErrors =
    response.composedError?.error?.response?.data?.apierror?.subErrors;
  return subErrors?.map((error) => {
    let fieldName = error.field;
    const index = fieldName.lastIndexOf('.');
    if (index !== -1) {
      fieldName = fieldName.slice(index + 1);
    }
    return { fieldName, message: error.message } as IHttpErrorType;
  });
}

export const nullableStringMaxValidation = (maxValue: number) =>
  yup
    .string()
    .max(maxValue, `Cannot be longer than ${maxValue} characters`)
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue?.toString().trim() === '' ? null : value
    );

function padTo2Digits(num: number) {
  if (num === 0) {
    return 0;
  }
  return num.toString().padStart(2, '0');
}

export function timeConvert(milliseconds: number): string | undefined {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m`;
}

export function getTimeFromMinutes(
  minutes: number | string
): string | undefined {
  let hours: number | string = Math.floor((minutes as number) / 60);
  minutes = (minutes as number) % 60;
  hours = hours ? `${hours}h` : '';
  minutes = minutes ? ` ${minutes}m` : '';
  return hours + minutes;
}

export function getNullableModel(model: any, data: any) {
  // @TODO Anoush model can be generic
  if (data) {
    return new model(data);
  }
  return null;
}

export function numberWithThousandSeparator(
  value: number,
  isRound = false,
  minimumFractionDigits = 0
) {
  if (value === null) return '';
  if (!isNaN(value)) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits,
    }).format(isRound ? Math.round(value) : value);
  }

  return value;
}

export function amountFormat(
  num: number,
  alwaysReturnNull = false,
  isNonDecimal = false
) {
  if (alwaysReturnNull) {
    return null;
  }
  if (!num) return '$0';
  const formated = Math.abs(num)
    .toFixed(isNonDecimal ? 0 : 2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num >= 0 ? `$${formated}` : `-$${formated}`;
}

export function getPhoneFromDTO(dto: any): PhoneType {
  return {
    countryCode: 'USA',
    extension: dto.extension,
    phone: dto.mobileno,
  } as PhoneType;
}

export function getAssignedDocumentsFromDto(dto: any, type: string) {
  const registrationType =
    type === EquipmentType.Tractor
      ? EDocumentTypes.TRACTOR_REGISTRATION
      : EDocumentTypes.TRAILER_REGISTRATION;
  const insuranceType =
    type === EquipmentType.Tractor
      ? EDocumentTypes.TRACTOR_INSURANCE
      : EDocumentTypes.TRAILER_INSURANCE;
  const regDoc = [];
  const insDoc = [];
  if (dto?.assignedDocuments?.length) {
    const registrationDoc = dto?.assignedDocuments?.find(
      (item) => item.documentType === registrationType
    );
    const insuranceDoc = dto?.assignedDocuments?.find(
      (item) => item.documentType === insuranceType
    );

    if (registrationDoc) {
      const newRegistrationDoc = {
        ...registrationDoc,
        name: registrationDoc.fileName,
      };
      regDoc.push(newRegistrationDoc);
    }
    if (insuranceDoc) {
      const newInsuranceDoc = { ...insuranceDoc, name: insuranceDoc.fileName };
      insDoc.push(newInsuranceDoc);
    }
  }
  return {
    Registration: regDoc.length ? regDoc : null,
    Insurance: insDoc.length ? insDoc : null,
  };
}

export function getFuelTypeFromDTO(dto: any) {
  return dto.fuelType ? { value: dto.fuelType } : null;
}

export function fromStringToOptionType(list: string[] = []) {
  return list.map((i) => ({ value: i } as OptionType));
}

export const setUtcDate = (momentDate?: Moment, hour = 0) => {
  const date = momentDate ? momentDate : moment();
  return moment.utc()?.set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
    hour: hour,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
};

export function fromMomentToIso(m: Moment | null, format?: string) {
  if (m) return m.format(format);
  return null;
}

export function fromIsoToMoment(iso?: string | null, timezone?: string) {
  if (iso) return timezone ? momentTimezone(iso).tz(timezone) : moment(iso);
  return null;
}

export const getUserLocalTimezone = () => moment.tz.guess();

export const getCurrentDateTime = (timezone?: string, format?: string) => {
  let currentDateTime: Moment | string = timezone
    ? moment().tz(timezone)
    : moment();
  if (format) {
    currentDateTime = currentDateTime.format(format);
  }

  return currentDateTime;
};

export function getFormattedDateForGrid(
  m?: Moment | string | null,
  format = FORM_DATEPICKER_FORMAT
): string {
  if (!m) return '';

  return momentTimezone
    .utc(m)
    ?.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .format(format);
}

export function getFormattedDatewithTimeZone(date?: Moment | string | null) {
  const { getCompanyPreferences } = useRootStore();

  return moment(date)
    .tz(getCompanyPreferences?.timezone)
    .format(FORM_DATEPICKER_FORMAT);
}
export function getFormattedDateTime(
  dateTime: Moment | string | null,
  format = UI_DATETIME_MILITARY_FORMAT
): string {
  if (moment.isMoment(dateTime)) {
    return dateTime.format(format);
  }
  return '';
}

export const getStartOfDay = (
  data: Moment,
  neededFormatName = 'YYYY/MM/DD HH:mm:ss.SSS',
  timezone = null,
  timeOptions = {}
) => {
  let date = moment.isMoment(data) ? data : moment(data || new Date());
  if (timezone) {
    date = moment(date).tz(timezone);
  }
  const mDate = date.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 1,
    ...timeOptions,
  });
  if (neededFormatName === 'IsoString') {
    return fromMomentToIso(mDate);
  }
  if (neededFormatName === 'moment') {
    return mDate;
  }
  return mDate.format(neededFormatName);
};

export const ParseToDate = (
  data: Moment | Date | string,
  format = 'YYYY-MM-DD'
): string => {
  const momentDate = moment.isMoment(data) ? data : moment(data);

  return momentDate.format(format);
};

export const getEndOfDay = (
  data: Moment,
  neededFormatName = 'YYYY/MM/DD HH:mm:ss.SSS',
  timezone = null
) => {
  let date = moment.isMoment(data) ? data : moment(data || new Date());
  if (timezone) {
    date = moment(date).tz(timezone);
  }
  const mDate = date.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  });
  if (neededFormatName === 'IsoString') {
    return fromMomentToIso(mDate);
  }
  if (neededFormatName === 'moment') {
    return mDate;
  }
  return mDate.format(neededFormatName);
};

export const exactLengthStringYup = (propertyName: string, length: number) =>
  yup
    .string()
    .nullable()
    .when(propertyName, {
      is: (value: string | any[]) => value?.length,
      then: (rule) => rule.length(length),
    });

export const nullableNumberYup = () =>
  yup
    .number()
    .typeError('Only number is accepted.')
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue?.toString().trim() === '' ? null : value
    );

export const extensionValidationSchema = () =>
  yup
    .string()
    .matches(/^\d+$/, 'Only number is accepted.')
    .max(5, 'Ext. should be max 5 digits')
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue?.toString().trim() === '' ? null : value
    );

export const nullableDateCommonYup = (validationMsg: string) =>
  yup
    .date()
    .nullable()
    .transform((value: string, originalValue: string) => {
      return originalValue?.toString().trim() === '' ? null : value;
    })
    .typeError(validationMsg);

export const nullableDateYup = () =>
  nullableDateCommonYup('Please enter correct date in DD/MM/YYYY format');

export const nullableDateTimeYup = () =>
  nullableDateCommonYup('Date-time is not valid');

export const nullableStringYup = () =>
  yup
    .string()
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue?.toString().trim() === '' ? null : value
    );

export const validAddressYup = nullableStringYup().test(
  'address',
  (value, context) => {
    let message = '';
    const { city, state } = context?.from?.[0]?.value;
    if (!value) {
      message = 'Address is required.';
    } else if (!city || !state) {
      message = 'Address is invalid.';
    }
    if (message) {
      return context.createError({
        message,
        path: context.path,
      });
    }
    return true;
  }
);

export const nullableIntegerRangeValidation = (min: number, max: number) =>
  yup
    .number()
    .integer('Only integer is accepted.')
    .transform((value: string, originalValue: string) => {
      if (originalValue?.toString().includes('.')) {
        return originalValue.replace(/[^1-9,.]/g, ' ');
      }
      if (originalValue?.toString().trim() === '') {
        return null;
      }
      return value;
    })
    .positive()
    .min(min, `Value must be between ${min} and ${integerFormat(max)}.`)
    .max(max, `Value must be between ${min} and ${integerFormat(max)}.`)
    .typeError('Please enter a correct number.')
    .nullable();

export const emailArrayValidationYup = (fieldName: string) =>
  yup
    .array()
    .of(nullableStringYup().email())
    .test(fieldName, t('emailisnotvalid'), (emails) => {
      const test = emails?.filter((e) => !emailValidation.test(e));
      return !test?.length;
    });

export const nullableEmailYup = () =>
  nullableStringYup().email('Email is not valid');

export const phoneNumberValidation = yup
  .string()
  .matches(phoneRegExp, 'Phone number is not valid')
  .length(10, 'Phone must be exactly 10 characters')
  .transform((value: string, originalValue: string) =>
    originalValue?.toString().trim() === '' ? null : value
  )
  .nullable();

export const phoneValidationSchema = yup.object().shape({
  phone: phoneNumberValidation,
  extension: extensionValidationSchema(),
});

export const requiredPhoneValidationSchema = yup.object().shape({
  phone: phoneNumberValidation.required('Phone is required'),
  extension: extensionValidationSchema(),
});
export const lastLocationValidationSchema = yup
  .object()
  .shape({
    streetAddress: nullableStringMaxValidation(50),
    streetAddress2: nullableStringMaxValidation(50),
  })
  .nullable()
  .default(null);

export const addLocationValidationSchema = yup
  .object()
  .shape({
    address: yup.string().required('address is required.'),
    streetAddress: nullableStringMaxValidation(50),
    streetAddress2: nullableStringMaxValidation(50),
    city: yup.string(),
    state: yup.string(),
  })
  .required();

const noExponents = (number: number): string => {
  const data = String(number).split(/[eE]/);
  if (data.length == 1) return data[0];

  let z = '',
    mag = Number(data[1]) + 1;
  const sign = number < 0 ? '-' : '',
    str = data[0].replace('.', '');

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
};

export const fourDigitValidationSchema = yup
  .number()
  .transform((value) => (isNaN(value) ? undefined : +value))
  .test(
    'is-decimal',
    'Amount cannot exceed 4 digits after decimal point',
    (val?: number) =>
      val === undefined || /^-?\d+(\.\d{0,4})?$/.test(noExponents(val))
  );

export const getFullName = ({
  firstName = '',
  lastName = '',
}: {
  firstName: string;
  lastName: string;
}) => `${firstName} ${lastName}`;

export const isRowSelected = (strip: string, selected: string) => {
  return strip === selected;
};

export const generateColumns = (
  columnsData: { [key: string]: boolean },
  columns: GridColDefSelf[]
) => {
  const tableColumns = cloneDeep(columns).reduce((previous, current) => {
    if (columnsData && !columnsData[current.field] && !current.permanent) {
      current.hide = true;
    } else if (columnsData && columnsData[current.field]) {
      current.hide = false;
    }
    previous.push(current);
    return previous;
  }, [] as (GridColDef & { isHyperLink?: boolean | undefined })[]);
  return tableColumns;
};

export const getTableColumns = (
  columnsData: { [key: string]: boolean },
  columns: GridColDefSelf[]
) => {
  return columnsData && columns?.filter((column) => columnsData[column.field]);
};

export const generateFilters = (filtersMetaData: ViewMetaData) => {
  return Object.keys(filtersMetaData).map((f) => {
    return {
      default: false,
      name: f,
      data: filtersMetaData[f],
    };
  });
};

export const getAssociatedTerminalNamesString = (
  terminals: TerminalShort[] = []
) => terminals.map((t) => t.name).join(', ');

export const prepareFiltersFromList = <T>(
  list: T[] | null,
  fieldName: keyof T,
  encode = false
): any[] | undefined => {
  if (!list?.length) return;
  return list.map((item: T) =>
    encode ? encodeURIComponent(item[fieldName] as string) : item[fieldName]
  );
};

export const prepareInvoiceFiltersFromList = <T>(
  list: T[] | null,
  fieldName: keyof T,
  encode = false
): any[] | undefined => {
  if (!list?.length) return;
  return list.map((item: T) => item[fieldName]);
};

export const getNeedShowEmptyPage = (items: { [key: string]: number }) => {
  return !Object.values(items).some((value) => value > 0);
};

export const getTimeZoneAbbreviation = (timezone: string) => {
  if (timezone) {
    return momentTimezone().tz(timezone).zoneAbbr();
  }
  return '';
};

const getCountryCodeByKey = (key: string) =>
  countryCodeOptions.find((country) => country.key === key)?.label || '';

export const formatPhoneNumber = (phoneData: PhoneType) => {
  if (!phoneData || !phoneData.phone) return '';
  //Filter only numbers from the input
  const cleaned = ('' + phoneData?.phone).replace(/\D/g, '');
  const cleanedExtension = ('' + phoneData?.extension).replace(/\D/g, '');

  //Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  const extension = phoneData?.extension ? '-' + cleanedExtension : '';

  if (match) {
    return (
      getCountryCodeByKey(phoneData?.countryCode) +
      ' (' +
      match[1] +
      ') ' +
      match[2] +
      '-' +
      match[3] +
      extension
    );
  }

  return '';
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0 || !bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'kb', 'mb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const dateToHumanableDaysFormat = (date: Date) => {
  if (!date) return '';

  const currentDate: Moment = getStartOfDay(moment(), 'moment') as Moment;
  const differenceDate = currentDate.diff(
    getStartOfDay(moment(date), 'moment'),
    'days'
  );
  return differenceDate === 0
    ? `< 1 days ago`
    : `${Math.abs(differenceDate)} days ago`;
};

export const availableCurrencySymbols = {
  USD: '$',
  RUB: '₽',
};

export const getCurrency = () => {
  const currency = 'USD';
  /* DO NOT REMOVE THIS COMMENTED CODE. IT WILL BE USED LATER. SUGGESTED BY DHANRAJ* */

  // const user = localStorage.getItem('user');
  // if (user) {
  //   const parseData = JSON.parse(user);
  //   if (parseData?.countryCode !== 'USA') currency = 'RUB';
  // }
  return currency;
};

export function copyToClipboard(text: string) {
  if (navigator.clipboard) navigator.clipboard.writeText(text);
}

export function isUrlValid(userInput: string) {
  const regexQuery =
    '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
  const url = new RegExp(regexQuery, 'i');
  return url.test(userInput);
}

export const nameShortener = (name: string, maxLenght: number) =>
  `${name?.slice(0, maxLenght)}${name?.length > maxLenght ? '...' : ''}`;

export const getAccountTypeWithRoleCode = (roleCodes: string[]): boolean => {
  const user = StorageManager.getUser();
  if (!user) return false;
  return roleCodes.includes(user?.roleCode);
};
export const isHasPermission = (
  permissions: AXELE_PERMISSION_TYPE[]
): boolean => {
  return getHasPermission({
    includes: permissions,
  });
};

export const vinValidationSchema = () =>
  //@TODO: Function must exclude special characters
  nullableStringYup()
    .matches(/^(?![^ioOqQI]*[ioOqQI])/, 'Cannot have the letters I, O or Q.')
    .min(17, 'Field should contain exactly 17 characters.');

export const removeExtraSpacesFromString = (text: string) => {
  if (typeof text !== 'string' || !text.length) return text;
  return text.replace(/ +(?= )/g, '').trim();
};

export const b64EncodeUnicode = (string: string) =>
  window.btoa(
    encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
export const unicodeDecodeB64 = (string: string) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(window.atob(string), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

export const decryptData = (key: string) => {
  const ls = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: process.env.SECRET_KEY,
  });
  return ls.get('login-data');
};

export const encryptData = (obj: {
  dot: string;
  email: string;
  password: string;
  rememberPassword: boolean;
}) => {
  const ls = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: process.env.SECRET_KEY,
  });
  ls.set('login-data', obj);
};

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return '';
  return str[0] + str.slice(1).toLocaleLowerCase();
};

export const capitalize = (str: string) => {
  return str && str.length
    ? str.charAt(0).toUpperCase() +
        (str.slice(1) ? str.slice(1).toLowerCase() : '')
    : '';
};

export * as contractRequestFormatter from './contract_payload_formatter';
