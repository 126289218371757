import { TextFieldVariants, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { NumberFormatProps } from 'react-number-format';
import { CurrencyField } from '.';

const CurrencyFieldControl: React.FC<{
  id?: string;
  control?: any;
  name: string;
  label: string;
  onChangeCallback?: any;
  sizes?: {
    xs: number;
  };
  style?: {};
  styleProps?: {};
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  shouldUnregister?: ControllerProps['shouldUnregister'];
  InputProps?: { [key: string]: boolean };
  inputProps?: {};
  onBlurCallback?: (value: string, name: string) => void;
  variant?: TextFieldVariants;
  size?: any;
  numberFormatProps?: NumberFormatProps;
}> = ({
  id = '',
  control,
  name,
  helperText,
  label,
  onChangeCallback,
  sizes = { xs: 12 },
  style = { mb: 2 },
  disabled = false,
  required,
  defaultValue = '',
  shouldUnregister,
  InputProps = {},
  inputProps,
  onBlurCallback,
  variant = 'standard',
  size,
  numberFormatProps = {
    decimalScale: 2,
    allowNegative: false,
    allowEmptyFormatting: true,
    isNumericString: true,
    thousandSeparator: true,
    prefix: '$',
  },
}) => {
  return (
    <Grid xs={sizes.xs} item sx={style}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CurrencyField
            id={id}
            fullWidth
            error={!!error?.message}
            helperText={error ? error.message : helperText}
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              onBlurCallback && onBlurCallback(event.target.value, name);
            }}
            autoComplete="off"
            onChange={(e) => {
              onChangeCallback && onChangeCallback(e.target.value, name);
              onChange(e);
            }}
            className="materialUIInput"
            variant={variant}
            size={size}
            sx={{ width: '100%' }}
            label={
              required ? (
                <>
                  {label}{' '}
                  <Typography
                    component="span"
                    sx={(theme) => ({
                      color: `${theme.palette.error.main} !important`,
                    })}
                  >
                    *
                  </Typography>
                </>
              ) : (
                label
              )
            }
            value={value ?? null}
            disabled={disabled}
            inputProps={inputProps}
            InputProps={InputProps}
            numberFormatProps={numberFormatProps}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
        shouldUnregister={shouldUnregister}
      />
    </Grid>
  );
};

export default React.memo(CurrencyFieldControl);
