import { BasicReportsConstants } from './basicReports';
import { DocumentConstants } from './document';
import { IFTAConstants } from './ifta';
import { TrendAnalysisConstants } from './trendAnalysis';

export const ReportsConstants = {
  ...BasicReportsConstants,
  ...DocumentConstants,
  ...IFTAConstants,
  ...TrendAnalysisConstants,
};
