import React from 'react';

const DetentionIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="3" fill="#FFBB58" />
      <path
        d="M9.65959 9.1912C9.94459 9.4762 10.0871 9.87203 10.0871 10.3866C10.0871 10.9012 9.94459 11.297 9.65959 11.5741C9.37459 11.8512 8.97875 11.9937 8.46417 11.9937H7.82292V8.7637H8.46417C8.97875 8.7637 9.37459 8.9062 9.65959 9.1912ZM17.9167 6.04036V14.7487C17.9167 15.6195 17.2042 16.332 16.3333 16.332H3.66667C2.79584 16.332 2.08334 15.6195 2.08334 14.7487V6.04036C2.08334 5.16953 2.79584 4.45703 3.66667 4.45703H16.3333C17.2121 4.45703 17.9167 5.16161 17.9167 6.04036ZM3.6825 7.40995L3.84084 11.202H4.98875L5.14709 7.40995H3.6825ZM5.23417 12.5004C5.23417 12.2866 5.155 12.1124 5.00459 11.9699C4.85417 11.8274 4.66417 11.7562 4.43459 11.7562C4.19709 11.7562 3.99917 11.8274 3.84875 11.9699C3.69834 12.1124 3.62709 12.2866 3.62709 12.5004C3.62709 12.7062 3.69834 12.8804 3.84875 13.0229C3.99917 13.1574 4.19709 13.2287 4.43459 13.2287C4.66417 13.2287 4.85417 13.1574 5.00459 13.0229C5.16292 12.8804 5.23417 12.7062 5.23417 12.5004ZM11.4646 10.3866C11.4646 9.84036 11.3458 9.35745 11.1004 8.93786C10.8629 8.51828 10.5225 8.18578 10.0871 7.9562C9.65167 7.72661 9.13709 7.60786 8.55125 7.60786H6.46917V13.1654H8.55125C9.12917 13.1654 9.64375 13.0466 10.0792 12.817C10.5225 12.5874 10.8629 12.2549 11.1004 11.8354C11.3458 11.4079 11.4646 10.9249 11.4646 10.3866ZM16.2146 7.60786H11.9158V8.69245H13.3883V13.1654H14.7421V8.69245H16.2146V7.60786Z"
        fill="white"
      />
    </svg>
  );
};

export default DetentionIcon;
