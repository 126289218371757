import { StopTypeOptions } from './constants/fieldOptions';
import {
  DefaultEquipmentAdditionalValue,
  LOAD_TYPE_KEYS,
} from './constants/fieldValues';
import { LoadPriorityEnum } from './dto/create-load-request.dto';
import { ILoadDetails } from './types/types';

export const DEFAULT_HANDLING_TIME = 120;

export const initialState: ILoadDetails = {
  templateId: '',
  assignToDriver: false,
  customer: {
    id: null,
    customerName: '',
    customerId: '',
    customerContactId: null,
    invoice: '',
    invoiceCompanyName: '',
    factoringId: '',
    contacts: [],
    referenceNumber: '',
    requiredDocuments: [],
    loadPriority: LoadPriorityEnum.None,
    factoringName: '',
    selectedPhoneData: null,
    selectedCustomer: null,
  },
  stops: [
    {
      type: StopTypeOptions[0].value,
      estimatedActivityDuration: DEFAULT_HANDLING_TIME,
      relayDropOffEstimatedActivityDuration: DEFAULT_HANDLING_TIME,
      referenceNumber: '',
      notes: '',
      fixedAppointment: false,
      isEndTimeVisible: false,
      isRelayDropOffEndTimeVisible: false,
      appointmentStartDate: '',
      appointmentEndDate: '',
      appointmentStartTime: '',
      appointmentEndTime: '',
      relayDropOffAppointmentStartDate: '',
      relayDropOffAppointmentStartTime: '',
      relayDropOffAppointmentEndDate: '',
      relayDropOffAppointmentEndTime: '',
      isDriverAssistRequired: false,
      isRelayDropOffDriverAssistRequired: false,
      relayPickupRevenueShare: null,
      relayDropOffRevenueShare: null,
      appointmentConfirmation: 'NO',
      dropOffAppointmentConfirmation: 'NO',
      location: {
        locationId: null,
        locationName: '',
        businessName: '',
        center: { lat: null, lng: null },
        city: '',
        state: '',
        zipcode: '',
        country: '',
        streetAddress: '',
        address: '',
        address2: '',
        geoCoordinates: [],
      },
      contact: {
        id: '',
        contactName: '',
        countryCode: '',
        phone: '',
        extension: '',
        email: '',
        firstname: '',
        lastname: '',
      },
      isHandlingTimeUpdatedManually: false,
      isDropOffHandlingTimeUpdatedManually: false,
      isNotesUpdatedManually: false,
      isRefNumberUpdatedManually: false,
      isContactDetailsUpdatedManually: false,
    },
    {
      type: StopTypeOptions[StopTypeOptions.length - 1].value,
      estimatedActivityDuration: DEFAULT_HANDLING_TIME,
      relayDropOffEstimatedActivityDuration: DEFAULT_HANDLING_TIME,
      referenceNumber: '',
      notes: '',
      fixedAppointment: false,
      appointmentStartDate: '',
      appointmentEndDate: '',
      appointmentStartTime: '',
      appointmentEndTime: '',
      isEndTimeVisible: false,
      isRelayDropOffEndTimeVisible: false,
      relayDropOffAppointmentStartDate: '',
      relayDropOffAppointmentStartTime: '',
      relayDropOffAppointmentEndDate: '',
      relayDropOffAppointmentEndTime: '',
      isDriverAssistRequired: false,
      isRelayDropOffDriverAssistRequired: false,
      relayPickupRevenueShare: null,
      relayDropOffRevenueShare: null,
      appointmentConfirmation: 'NO',
      dropOffAppointmentConfirmation: 'NO',
      location: {
        locationId: null,
        locationName: '',
        businessName: '',
        center: { lat: null, lng: null },
        city: '',
        state: '',
        zipcode: '',
        country: '',
        streetAddress: '',
        address: '',
        address2: '',
        geoCoordinates: [],
      },
      contact: {
        contactName: '',
        countryCode: '',
        phone: '',
        extension: '',
        email: '',
        firstname: '',
        lastname: '',
      },
      isHandlingTimeUpdatedManually: false,
      isDropOffHandlingTimeUpdatedManually: false,
      isNotesUpdatedManually: false,
      isRefNumberUpdatedManually: false,
      isContactDetailsUpdatedManually: false,
    },
  ],
  load: {
    equipment: [],
    seal: null,
    temp: null,
    tempVariance: null,
    loadType: LOAD_TYPE_KEYS.FTL,
    isBrokered: false,
    weight: null,
    notes: '',
    showTemperatureVariance: false,
    equipmentAdditionalDetails: DefaultEquipmentAdditionalValue,
    commodities: [],
    tags: [],
  },
  rate: {
    isContractEnabled: false,
    baseRateType: '',
    rate: '',
    quantity: 1,
    internalNotes: '',
    messageOnInvoice: '',
    description: '',
    invoiceTerm: '',
    invoiceTermValue: '',
    additionalFees: [
      {
        fee: null,
        unit: '',
        units: [],
        quantity: null,
        rate: null,
        description: '',
      },
    ],
    totalRate: null,
  },
  totalMiles: 0,
  isTotalMilesManuallyUpdate: false,
  isTerminalEnabled: false,
  isManualLoadCreation: true,
  hiddenFields: {
    customer: {
      customerContactId: false,
      factoringId: false,
      referenceNumber: false,
      requiredDocuments: false,
      factoringName: false,
    },
    load: {
      seal: false,
      commodities: false,
      notes: false,
    },
    stops: {
      estimatedActivityDuration: false,
      isDriverAssistRequired: false,
      editAddress: false,
      editContact: false,
      editReferenceNumber: false,
      appointmentEndDate: false,
      notes: false,
    },
  },
  requiredFields: {
    customer: { customerId: true },
    stops: { locationName: true, address: true },
    load: { equipment: true, commodities: true },
  },
};

export const defaultAddNewStopConfig = {
  ...initialState.stops[0],
  type: StopTypeOptions[1].value,
};
