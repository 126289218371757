import {
  CreateLoadFinanceExpenseDTO,
  DeleteFinanceExpense,
  ListDataType,
  requestParamsFinanceOverview,
  requestStatuesStatementInfo,
  UpdateTractorDriverAccessQuery,
} from '../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewModel';
import { IFinanceRevenueSharePayLoadType } from '../../common/LoadTabPanel/tabs/FinanceOverview/FinanceRevenueShare';

import { ByLoadIdRequest, UpdateStatusResponse } from '../../models';

import {
  DeleteFinanceLoadLineType,
  FinanceLoadListRequest,
  FinanceLoadloadExpenseType,
  FinanceLoadType,
  GetFinanceLoadByLoadIDRequest,
  GetFinanceLoadItemDriverIntialRequest,
  GetFinanceLoadItemTractorIntialRequest,
  GetFinancialItemRequest,
  LoadCarrierExpenseDTO,
  LoadCarrierExpenseDTORequest,
  LoadCarrierExpenseShortDTO,
} from '../../models/DTOs/FinanceLoadService';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IFinanceLoadService extends Service {
  abstract GetLoadFinanceInitial(
    loadId: ByLoadIdRequest
  ): Promise<FinanceLoadType | null | ServiceError>;
  abstract GetinvoiceDetail(
    loadId: string
  ): Promise<FinanceLoadType | null | ServiceError>;
  abstract GetFinanceLoadDriverList(
    id: string
  ): Promise<ListDataType | null | ServiceError>;
  abstract GetFinanceLoadDispatcherList(
    id: string
  ): Promise<ListDataType | null | ServiceError>;
  abstract GetFinanceLoadTractorList(
    id: string
  ): Promise<ListDataType | null | ServiceError>;

  abstract GetFinancialItem(
    requestData: GetFinancialItemRequest
  ): Promise<FinanceLoadListRequest | null | ServiceError>;
  abstract CreateFinanceLoadLineItem(
    requestData: DeleteFinanceLoadLineType
  ): Promise<DeleteFinanceLoadLineType | null | ServiceError>;
  abstract UpdateFinanceLoadLineItem(
    requestData: DeleteFinanceLoadLineType
  ): Promise<FinanceLoadloadExpenseType | null | ServiceError>;
  abstract DeleteFinanceLoadLineItem(
    requestData: DeleteFinanceLoadLineType
  ): Promise<FinanceLoadloadExpenseType | null | ServiceError>;
  abstract CreateFinanceLoadDispatcherLineItem(
    requestData: DeleteFinanceLoadLineType
  ): Promise<DeleteFinanceLoadLineType | null | ServiceError>;
  abstract UpdateFinanceLoadDispatcherLineItem(
    requestData: DeleteFinanceLoadLineType
  ): Promise<FinanceLoadloadExpenseType | null | ServiceError>;
  abstract createNewLoadFinanceExpense(
    type: string,
    requestData: CreateLoadFinanceExpenseDTO
  ): Promise<CreateLoadFinanceExpenseDTO | null | ServiceError>;
  abstract DeleteFinanceLoadDispatcherLineItem(
    requestData: DeleteFinanceLoadLineType
  ): Promise<FinanceLoadloadExpenseType | null | ServiceError>;
  abstract CreateFinanceLoadTractorLineItem(
    requestData: DeleteFinanceLoadLineType
  ): Promise<DeleteFinanceLoadLineType | null | ServiceError>;
  abstract UpdateFinanceLoadTractorLineItem(
    requestData: DeleteFinanceLoadLineType
  ): Promise<FinanceLoadloadExpenseType | null | ServiceError>;
  abstract DeleteFinanceLoadTractorLineItem(
    requestData: DeleteFinanceLoadLineType
  ): Promise<FinanceLoadloadExpenseType | null | ServiceError>;

  abstract CreateFinanceLoadDriverInitialLineItem(
    requestData: requestParamsFinanceOverview
  ): Promise<GetFinanceLoadItemDriverIntialRequest | null | ServiceError>;
  abstract CreateFinanceLoadTractorInitialLineItem(
    requestData: requestParamsFinanceOverview
  ): Promise<GetFinanceLoadItemTractorIntialRequest | null | ServiceError>;
  abstract CreateFinanceLoadDispatcherInitialLineItem(
    requestData: string
  ): Promise<GetFinanceLoadByLoadIDRequest | null | ServiceError>;

  abstract GetfinanceLoadDownload(
    loadId: any
  ): Promise<GetFinanceLoadByLoadIDRequest | null | ServiceError>;

  abstract GetAutopopulateDriver(
    requestData: string
  ): Promise<FinanceLoadType | null | ServiceError>;
  abstract GetAutopopulateTractor(
    requestData: string
  ): Promise<FinanceLoadType | null | ServiceError>;
  abstract GetAutopopulateDispatcher(
    requestData: string
  ): Promise<FinanceLoadType | null | ServiceError>;

  abstract GetDriverStatuesStatementInfo(
    requestData: requestParamsFinanceOverview
  ): Promise<requestStatuesStatementInfo | null | ServiceError>;
  abstract GetTractorStatuesStatementInfo(
    requestData: requestParamsFinanceOverview
  ): Promise<requestStatuesStatementInfo | null | ServiceError>;
  abstract GetDispatcherStatuesStatementInfo(
    requestData: requestParamsFinanceOverview
  ): Promise<requestStatuesStatementInfo | null | ServiceError>;
  abstract UpdateTractorDriverAccess(
    requestData: UpdateTractorDriverAccessQuery
  ): Promise<requestStatuesStatementInfo | null | ServiceError>;

  //Carrier module begin
  abstract GetFinanceLoadCarrierList(
    tripId: string
  ): Promise<LoadCarrierExpenseDTO | null | ServiceError>;
  abstract getTripCarrierAssignmentPreview(
    tripId: string,
    carrierId: string
  ): Promise<LoadCarrierExpenseDTO | null | ServiceError>;
  abstract CreateFinanceLoadCarrierInitialLineItem(
    tripId: string
  ): Promise<LoadCarrierExpenseDTO | null | ServiceError>;
  abstract updateLoadCarrierExpense(
    payload: LoadCarrierExpenseDTORequest
  ): Promise<LoadCarrierExpenseDTO | null | ServiceError>;
  abstract updateCarrierPaymentStatus(
    payload: LoadCarrierExpenseShortDTO
  ): Promise<LoadCarrierExpenseDTO | null | ServiceError>;
  abstract deleteCarrierPaymentById(
    carrierPaymentId: string
  ): Promise<UpdateStatusResponse | null | ServiceError>;
  abstract deleteCarrierPaymentByTripId(
    tripId: string
  ): Promise<UpdateStatusResponse | null | ServiceError>;
  //Carrier module end

  abstract updateFinanceRevenueShare(
    requestData: IFinanceRevenueSharePayLoadType
  ): Promise<{ message: string } | null | ServiceError>;

  abstract deleteLoadFinanceExpense(
    type: string,
    params: DeleteFinanceExpense
  ): Promise<any>;
}
