import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CompletedIcon } from '../../../../../ui-kit/components/Assets';
import { fontFamily } from '../../constants';

export default function EmptyPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        m: 1,
        minHeight: 500,
      }}
    >
      <Box sx={{ mb: 5 }}>
        <CompletedIcon />
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            color: 'rgba(0, 17, 34, 0.75)',
            fontSize: 34,
            fontWeight: 400,
            mb: 4,
            fontFamily,
          }}
          variant={'h4'}
        >
          No Missing Entities Found
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={'h6'}
          sx={{
            color: 'rgba(0, 17, 34, 0.75)',
            fontSize: 20,
            fontWeight: 500,
            mb: 4,
            fontFamily: fontFamily,
            letterSpacing: '0.15px',
          }}
        >
          All the entites such as customers, drivers, tractors, trailers were
          found and mapped automatically. Proceed to next step
        </Typography>
      </Box>
    </Box>
  );
}
