import { LoadViewTranslations } from '@/locales/en/allLoads/loads/modal';
import { TagListComponent } from '@/views/customTags/customTagChip';
import { SearchOutlined } from '@mui/icons-material';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import { Skeleton, Stack, Typography } from '@mui/material';
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { loadService, tripService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { ELoadStatus } from '../../../../common/LoadTabPanel/constants/constants';
import HyperLink from '../../../../common/Ui/HyperLink/HyperLink';
import { RelativeDateRangeUtils } from '../../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { tripLoadCustomTitle } from '../../../../common/UiUtils/uiUtils';
import { TripsConstants } from '../../../../locales/en/allLoads/trips/index';
import {
  GetLoadOriginDestinationRequest,
  ITripCountMetrics,
  PaginatedLoadListRequest,
  Trip,
  TripConnectedLoad,
  TripDestinationOption,
  TripGroupDetail,
} from '../../../../models';
import { PaginatedTripLightListRequest } from '../../../../models/DTOs/Trip/Requests';
import { TTripStatusOption } from '../../../../models/DTOs/Trip/Types';
import { getStateOptions } from '../../../../subPages/expenses/utils/utils';
import {
  getLoadReferenceIds,
  getLoadSeqNumbers,
  getPaginatedTagsList,
} from '../../../../subPages/invoices/utils';
import { LoadPriorityEnumVals } from '../../../../subPages/loadsList/CreateLoad/constants/fieldValues';
import LoadController from '../../../../subPages/loadsList/LoadController';
import WarningComponent from '../../../../subPages/loadsList/components/WarningComponent';
import TractorController from '../../../../subPages/tractors/Controller';
import TrailerController from '../../../../subPages/trailers/TrailerController';
import { GridColDefSelf } from '../../../../types';
import { numberWithThousandSeparator } from '../../../../utils';
import { renderDateTime } from '../../../../utils/NameAndDateTimeGridField';
import { DashboardLoader } from '../../../home/DhaboardLoader';
import { getDriverNamesList } from '../../../settings/users/utils';
import {
  SETTLEMENT_STATUS_LIST,
  tripFieldsConfig,
} from '../../../trips/constants/constants';
import {
  IFiltersFormConfig,
  ITripFilters,
} from '../../../trips/constants/types';
import { defaultSort } from '../../../trips/services/queryParams.utils';
import { displayTripSettlementStatus } from '../../../trips/utils';
import { ManualDriverSelection } from './components/ManualDriverSelection';
import { CLASS_NAMES } from './components/ManualDriverSelection/styles';
import { ManualTripSelection } from './components/ManualTripSelection';

export const getFilterStatusList = (
  tripCountMetrics?: ITripCountMetrics
): TTripStatusOption[] => {
  return [
    {
      value: ELoadStatus.ASSIGNMENT_PLANNED,
      label: `${t('tripplanned')}`,
    },
    {
      value: ELoadStatus.AVAILABLE,
      label: `${t('tripavailable')}`,
    },
  ];
};

export const defaultFilters: ITripFilters = {
  [tripFieldsConfig.reference.fieldFilterName!]: [],
  [tripFieldsConfig.dispatcher.fieldFilterName!]: [],
  [tripFieldsConfig.tripStatus.fieldFilterName!]: getFilterStatusList(),
  [tripFieldsConfig.tripId.fieldFilterName!]: [],
  sort: defaultSort,
};

const getTripIdData = async (searchString: string, pageNumber: number) => {
  const response = await tripService.getPaginatedTripLightList(
    new PaginatedTripLightListRequest({
      seqNumber: searchString,
      pageNumber,
      pageSize: 20,
    })
  );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: response?.content.map(({ tripIds, seqNumber }) => ({
      label: seqNumber,
      value: tripIds,
    })),
  };
};

interface GridProps {
  [key: string]: any;
}

const renderLoadData = (
  params: GridRenderCellParams<undefined, Trip>,
  field: string
) => {
  return params.row?.connectedLoads
    ?.map((load: TripConnectedLoad) => load[field as keyof TripConnectedLoad])
    .join(', ');
};

interface ITripGridConfig {
  columns: GridColDefSelf[];
  title: string;
  getFiltersFormConfig: ({
    showQuickFilter,
    tripCountMetrics,
  }: {
    showQuickFilter?: boolean;
    tripCountMetrics?: ITripCountMetrics;
  }) => IFiltersFormConfig[];
  defFilters: ITripFilters;
}

const TripYesOnlyOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: undefined,
  },
];
export default class TripGridConfig {
  name: string;

  config: ITripGridConfig;

  constructor(props: GridProps) {
    this.name = 'GridConfiguration';
    this.config = TripGridConfig.getConfiguration(props);
  }

  static getConfiguration(props: GridProps): ITripGridConfig {
    return TripGridConfig.getDefaultConfiguration(props);
  }

  static getDefaultConfiguration(props: GridProps): ITripGridConfig {
    return {
      title: t('tableView'),
      columns: [
        {
          flex: 1,
          width: 80,
          minWidth: 80,
          field: tripFieldsConfig.plusButton.fieldName,
          headerName: tripFieldsConfig.plusButton.label,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return <ManualTripSelection params={params} />;
          },
        },
        {
          maxWidth: 60,
          field: tripFieldsConfig.warning.fieldName,
          headerName: tripFieldsConfig.warning.label,
          permanent: true,
          sortable: false,
          renderCell: (params) => {
            if (params.row.warning) {
              return <WarningComponent warning={params.row.warning} />;
            } else {
              return <DashboardLoader size={'20px'} />;
            }
          },
        },
        {
          isHyperLink: true,
          minWidth: 100,
          maxWidth: 150,
          field: tripFieldsConfig.tripId.fieldName,
          headerName: tripFieldsConfig.tripId.label,
          permanent: true,
          sortable: true,
          renderCell: (
            params: GridRenderCellParams<TripConnectedLoad[], Trip>
          ) => <HyperLink value={params.row.seqNumber} />,
        },
        {
          field: tripFieldsConfig.tripStatus.fieldName,
          headerName: tripFieldsConfig.tripStatus.label,
          minWidth: 100,
          maxWidth: 150,
          renderCell: (params: GridRenderCellParams<ELoadStatus>) => {
            const tripData = params.row;
            return tripLoadCustomTitle({
              status: tripData.status,
              lastUpdatedStop: tripData.lastUpdatedStop,
              nonInvoiceable: false,
              onHold: !!tripData.onHold,
            });
          },
          permanent: true,
          sortable: false,
        },
        {
          flex: 1,
          minWidth: 200,
          field: 'tags',
          headerName: 'Tags',
          sortable: false,
          renderCell: (params) => {
            return (
              <Stack
                direction="row"
                flexWrap="wrap"
                gap={1}
                sx={{ mt: '5px', mb: '5px' }}
              >
                <TagListComponent tagList={params.row.tags} />
              </Stack>
            );
          },
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.driver.fieldName,
          headerName: tripFieldsConfig.driver.label,
          cellClassName: CLASS_NAMES.driverSelectionCellClassName,
          renderCell: (params: GridRenderCellParams<TripGroupDetail>) => {
            if (params.row.isPinnedRows) return '';
            return params.value?.name ? (
              <HyperLink value={params.value?.name} />
            ) : (
              <ManualDriverSelection params={params} />
            );
          },
          permanent: true,
          sortable: false,
          minWidth: 220,
        },
        {
          field: tripFieldsConfig.reference.fieldName,
          headerName: tripFieldsConfig.reference.label,
          minWidth: 95,
          maxWidth: 100,
          sortable: false,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) => {
            return renderLoadData(params, 'referenceId');
          },
        },
        {
          field: tripFieldsConfig.tripStart.fieldName,
          headerName: tripFieldsConfig.tripStart.label,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) =>
            params.row.fromLocationName,
          minWidth: 130,
          maxWidth: 200,
          sortable: true,
        },
        {
          field: tripFieldsConfig.tripEnd.fieldName,
          headerName: tripFieldsConfig.tripEnd.label,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) =>
            params.row.toLocationName,
          minWidth: 130,
          maxWidth: 200,
          sortable: false,
        },
        {
          field: tripFieldsConfig.tripStartDate.fieldName,
          headerName: tripFieldsConfig.tripStartDate.label,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) =>
            params.row?.startDateTime
              ? renderDateTime(
                  params.row.startDateTime,
                  params.row.firstStopTimeZone
                )
              : null,
          minWidth: 190,
          maxWidth: 200,
          sortable: true,
        },
        {
          field: tripFieldsConfig.tripEndDate.fieldName,
          headerName: tripFieldsConfig.tripEndDate.label,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) =>
            params.row?.endDateTime
              ? renderDateTime(
                  params.row.endDateTime,
                  params.row.lastStopTimeZone
                )
              : null,
          minWidth: 190,
          maxWidth: 200,
          sortable: false,
        },
        {
          field: tripFieldsConfig.revenue.fieldName,
          headerName: tripFieldsConfig.revenue.label,
          align: 'right',
          minWidth: 90,
          maxWidth: 160,
          headerAlign: 'center',
          renderCell: (params: GridRenderCellParams<Trip['revenue']>) => {
            if (params.value === undefined || isNaN(params.value)) return '';

            return (
              <Typography
                variant="body2"
                sx={[
                  {
                    color: 'text.primary',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 700,
                  },
                ]}
              >
                {(params?.value as unknown as string) === 'loading' ? (
                  <Skeleton variant="text" width="5rem" />
                ) : (
                  '$' + numberWithThousandSeparator(params.value, false, 2)
                )}
              </Typography>
            );
          },
          sortable: true,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.customer.fieldName,
          headerName: tripFieldsConfig.customer.label,
          minWidth: 115,
          maxWidth: 237,
          sortable: false,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) =>
            params.row.connectedLoads?.[0]?.customer?.name,
        },
        {
          field: tripFieldsConfig.tripMiles.fieldName,
          headerName: tripFieldsConfig.tripMiles.label,
          minWidth: 80,
          maxWidth: 115,
          renderCell: (params: GridRenderCellParams) => {
            return numberWithThousandSeparator(params.value);
          },
          sortable: false,
        },
        {
          field: tripFieldsConfig.emptyMiles.fieldName,
          headerName: tripFieldsConfig.emptyMiles.label,
          minWidth: 95,
          maxWidth: 100,
          renderCell: (params: GridRenderCellParams) => {
            return numberWithThousandSeparator(params.value);
          },
          sortable: false,
        },
        {
          sortable: false,
          field: tripFieldsConfig.terminal.fieldName,
          headerName: tripFieldsConfig.terminal.label,
          minWidth: 160,
          renderCell: (
            params: GridRenderCellParams<Trip['terminal'], Trip>
          ) => {
            return params.value?.name;
          },
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.dispatcher.fieldName,
          headerName: tripFieldsConfig.dispatcher.label,
          renderCell: (params: GridRenderCellParams) => {
            return <HyperLink value={params.value} />;
          },
          sortable: false,
          minWidth: 100,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.tractor.fieldName,
          headerName: tripFieldsConfig.tractor.label,
          minWidth: 100,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) => {
            const name = params.row.assignment?.tractorName;
            return name && <HyperLink value={name} />;
          },
          sortable: false,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.trailer.fieldName,
          headerName: tripFieldsConfig.trailer.label,
          minWidth: 100,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) => {
            const name = params.row.assignment?.trailerName;
            return name && <HyperLink value={name} />;
          },
          sortable: false,
        },
        {
          field: tripFieldsConfig.chassis.fieldName,
          headerName: tripFieldsConfig.chassis.label,
          minWidth: 100,
          sortable: false,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) => {
            return renderLoadData(params, 'chassisNumber');
          },
        },
        {
          field: tripFieldsConfig.container.fieldName,
          headerName: tripFieldsConfig.container.label,
          minWidth: 100,
          sortable: false,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) => {
            return renderLoadData(params, 'containerNumber');
          },
        },
        {
          field: tripFieldsConfig.loadPriority.fieldName,
          headerName: tripFieldsConfig.loadPriority.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) => {
            return (
              <Typography
                variant="body2"
                {...(params?.row?.loadPriority === 'CRITICAL'
                  ? { color: 'error' }
                  : {})}
              >
                {LoadPriorityEnumVals[params?.row?.loadPriority!] ?? ''}
              </Typography>
            );
          },
        },
        {
          field: tripFieldsConfig.canBeBrokered.fieldName,
          headerName: tripFieldsConfig.canBeBrokered.label,
          minWidth: 130,
          renderCell: (params: any) => {
            if (params?.row?.canBeBrokered == null) return '';
            return params?.row?.canBeBrokered ? 'Yes' : 'No';
          },
          sortable: false,
        },
        {
          field: tripFieldsConfig.isEmptyTrip.fieldName,
          headerName: tripFieldsConfig.isEmptyTrip.label,
          minWidth: 130,
          renderCell: (params: any) => {
            if (params?.row?.isEmptyTrip == null) return '';
            return params?.row?.isEmptyTrip ? 'Yes' : 'No';
          },
          sortable: false,
        },
        {
          field: tripFieldsConfig.legs.fieldName,
          headerName: tripFieldsConfig.legs.label,
          valueGetter: (params: GridRenderCellParams<undefined, Trip>) => {
            return params.row.numberOfStops
              ? params.row.numberOfStops - 1
              : null;
          },
          minWidth: 80,
          sortable: false,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.loadId.fieldName,
          headerName: tripFieldsConfig.loadId.label,
          minWidth: 80,
          renderCell: (
            params: GridRenderCellParams<TripConnectedLoad[], Trip>
          ) => {
            return params.value
              ?.filter((load) => !!load.loadSequenceNumber)
              ?.map((load, index) => {
                return (
                  load.loadSequenceNumber && (
                    <>
                      <HyperLink value={load.loadSequenceNumber} />
                      {!params.value?.length ||
                      index === params.value.length - 1
                        ? ''
                        : ', '}
                    </>
                  )
                );
              });
          },
          sortable: false,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.manifestId.fieldName,
          headerName: tripFieldsConfig.manifestId.label,
          minWidth: 80,
          renderCell: (
            params: GridRenderCellParams<TripConnectedLoad[], Trip>
          ) => {
            if (!params.row.manifestBaseDetails?.seqNumber) {
              return null;
            }
            return (
              <HyperLink value={params.row.manifestBaseDetails?.seqNumber} />
            );
          },
          sortable: false,
        },
        {
          field: tripFieldsConfig.settlementStatus.fieldName,
          headerName: tripFieldsConfig.settlementStatus.label,
          minWidth: 150,
          renderCell: (
            params: GridRenderCellParams<Trip['settlementStatus'], Trip>
          ) => displayTripSettlementStatus(params.value),
        },
        {
          field: tripFieldsConfig.settlementAmount.fieldName,
          headerName: tripFieldsConfig.settlementAmount.label,
          align: 'right',
          headerAlign: 'right',
          renderCell: (
            params: GridRenderCellParams<Trip['settlementAmount']>
          ) => {
            if (params.value === undefined || isNaN(params.value)) return '';
            return (
              <Typography
                variant="body2"
                sx={[
                  {
                    color: 'text.primary',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 700,
                  },
                ]}
              >
                {(params?.value as unknown as string) === 'loading' ? (
                  <Skeleton variant="text" width="5rem" />
                ) : (
                  '$' + numberWithThousandSeparator(params.value, false, 2)
                )}
              </Typography>
            );
          },
          minWidth: 180,
          sortable: false,
        },
        {
          sortable: true,
          field: tripFieldsConfig.createDate.fieldName,
          headerName: tripFieldsConfig.createDate.label,
          minWidth: 200,
          renderCell: (
            params: GridRenderCellParams<Trip['createDate'], Trip>
          ) =>
            params.value ? renderDateTime(params.value, props.timezone) : null,
        },
      ],
      getFiltersFormConfig: ({
        showQuickFilter,
        tripCountMetrics,
      }): IFiltersFormConfig[] => {
        const filtersFormConfig: IFiltersFormConfig[] = [
          {
            default: showQuickFilter,
            label: tripFieldsConfig.reference.label,
            name: tripFieldsConfig.reference.fieldFilterName,
            getData: (searchString, pageNumber) =>
              getLoadReferenceIds(searchString, pageNumber, undefined),
            type: 'MultipleAutocomplete',
            fieldName: 'referenceId',
            customStyles: {
              width: '127px',
            },
            PopperSxProps: {
              minWidth: '270px',
              maxWidth: '270px',
            },
          },
          {
            default: showQuickFilter,
            label: tripFieldsConfig.dispatcher.label,
            name: tripFieldsConfig.dispatcher.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'name',
            customStyles: {
              width: '118px',
            },
            PopperSxProps: {
              minWidth: '270px',
              maxWidth: '270px',
            },
            getData: async (searchString, pageNumber) =>
              LoadController.instance().getDispatcherSummary(
                searchString,
                pageNumber,
                undefined,
                undefined
              ),
            hideLabelOnAllFilters: false,
            groupName: 'General',
          },
          {
            default: showQuickFilter,
            label: tripFieldsConfig.tripStatus.label,
            name: tripFieldsConfig.tripStatus.fieldFilterName,
            type: 'ButtonGroup',
            multiple: true,
            showQuickFilterAsButtonGroup: true,
            options: getFilterStatusList(tripCountMetrics),
            fieldName: 'label',
            fieldValue: 'value',
            hideLabelOnAllFilters: true,
            groupName: 'Trip Information',
          },
          {
            default: false,
            label: tripFieldsConfig.tripId.label,
            name: tripFieldsConfig.tripId.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'label',
            getData: getTripIdData,
            hideLabelOnAllFilters: false,
            groupName: 'Trip Information',
          },
          {
            default: false,
            label: tripFieldsConfig.driver.label,
            name: tripFieldsConfig.driver.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'name',
            getData: (searchString, pageNumber) => {
              return getDriverNamesList(
                searchString,
                pageNumber,
                undefined,
                true
              );
            },
            hideLabelOnAllFilters: false,
            groupName: 'General',
          },
          {
            default: false,
            name: tripFieldsConfig.tractor.fieldFilterName,
            fieldName: 'value',
            getData: (searchText: string | undefined, pageNumber: number) =>
              TractorController.instance().getTractorByNameSearch(
                searchText,
                pageNumber
              ),
            label: tripFieldsConfig.tractor.label,
            type: 'MultipleAutocomplete',
            hideLabelOnAllFilters: false,
            groupName: 'General',
          },
          {
            default: false,
            name: tripFieldsConfig.trailer.fieldFilterName,
            fieldName: 'value',
            getData: (searchText: string | undefined, pageNumber: number) =>
              TrailerController.instance().getTrailerByNameSearch(
                searchText,
                pageNumber
              ),
            label: tripFieldsConfig.trailer.label,
            type: 'MultipleAutocomplete',
            hideLabelOnAllFilters: false,
            groupName: 'General',
          },
          {
            default: false,
            name: tripFieldsConfig.chassis.fieldName,
            fieldName: 'value',
            getData: (searchText: string | undefined, pageNumber: number) =>
              LoadController.instance().getChassisSearch(
                searchText,
                pageNumber,
                true
              ),
            label: tripFieldsConfig.chassis.label,
            type: 'MultipleAutocomplete',
          },
          {
            default: false,
            name: tripFieldsConfig.container.fieldName,
            fieldName: 'value',
            getData: (searchText: string | undefined, pageNumber: number) =>
              LoadController.instance().getContainerSearch(
                searchText,
                pageNumber,
                true
              ),
            label: tripFieldsConfig.container.label,
            type: 'MultipleAutocomplete',
          },
          {
            default: false,
            label: tripFieldsConfig.loadId.label,
            name: tripFieldsConfig.loadId.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'label',
            getData: async (searchString: string, pageNumber: number) => {
              const request = new PaginatedLoadListRequest();
              request.pageNumber = pageNumber;
              request.pageSize = 20;
              request.seqNumber = searchString;

              const response = await loadService.getLoadLightListByNameSearch(
                request
              );
              if (response instanceof ServiceError) {
                return {
                  content: [],
                  last: true,
                };
              } else {
                return {
                  content: response?.content?.map(
                    (item: { loadIds: string[]; seqNumber: string }) => {
                      return {
                        value: item?.loadIds,
                        label: item?.seqNumber,
                      };
                    }
                  ),
                  last: !response?.content?.length,
                };
              }
            },
            hideLabelOnAllFilters: false,
            groupName: 'General',
          },
          {
            key: 10,
            name: 'manifestSeqNumbersFilter',
            fieldName: 'seqNumber',
            getData: (searchText: string, pageNumber: number) =>
              getLoadSeqNumbers(searchText, pageNumber, [], [], 'MANIFEST'),
            label: 'Manifest ID',
            default: false,
            type: 'MultipleAutocomplete',
          },
          {
            default: false,
            label: tripFieldsConfig.customer.label,
            name: tripFieldsConfig.customer.fieldFilterName,
            getData: LoadController.instance().getCustomerForFilter,
            type: 'MultipleAutocomplete',
            fieldName: 'value',
          },
          {
            default: false,
            label: tripFieldsConfig.driverOperationMode.label,
            name: tripFieldsConfig.driverOperationMode.fieldFilterName,
            type: 'ButtonGroup',
            multiple: false,
            fieldName: 'label',
            fieldValue: 'value',
            options: [
              {
                label: t('driverOperationModeSolo'),
                value: 'SOLO',
              },
              {
                label: t('driverOperationModeTeam'),
                value: 'TEAM',
              },
            ],
            hideLabelOnAllFilters: true,
            groupName: 'General',
          },
          {
            default: false,
            label: tripFieldsConfig.settlementStatus.label,
            name: tripFieldsConfig.settlementStatus.fieldFilterName,
            options: SETTLEMENT_STATUS_LIST,
            type: 'ButtonGroup',
            multiple: true,
            fieldName: 'label',
            fieldValue: 'value',
            hideLabelOnAllFilters: true,
            groupName: 'General',
          },
          {
            default: false,
            label: tripFieldsConfig.tripStart.label,
            name: tripFieldsConfig.tripStart.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'label',
            getData: async (searchString: string, pageNumber: number) => {
              const requestData = new GetLoadOriginDestinationRequest();
              requestData.pageNumber = pageNumber;
              requestData.pageSize = 25;
              requestData.city = searchString;

              const response = await loadService.getLoadOriginDestinationList(
                requestData
              );
              if (response instanceof ServiceError) {
                return {
                  content: [],
                  last: true,
                };
              } else {
                return {
                  ...response,
                  content: response.content
                    .map((r) => new TripDestinationOption(r))
                    .map((item) => {
                      return {
                        value: item.value,
                        label: item.displayName,
                      };
                    }),
                };
              }
            },
            hideLabelOnAllFilters: false,
            groupName: 'Trip Information',
          },
          {
            default: false,
            label: tripFieldsConfig.tripEnd.label,
            name: tripFieldsConfig.tripEnd.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'label',
            getData: async (searchString: string, pageNumber: number) => {
              const requestData = new GetLoadOriginDestinationRequest();
              requestData.pageNumber = pageNumber;
              requestData.pageSize = 25;
              requestData.city = searchString;

              const response = await loadService.getLoadOriginDestinationList(
                requestData
              );
              if (response instanceof ServiceError) {
                return {
                  content: [],
                  last: true,
                };
              } else {
                return {
                  ...response,
                  content: response.content
                    .map((r) => new TripDestinationOption(r))
                    .map((item) => {
                      return {
                        value: item.value,
                        label: item.displayName,
                      };
                    }),
                };
              }
            },
            hideLabelOnAllFilters: false,
            groupName: 'Trip Information',
          },
          {
            default: false,
            label: tripFieldsConfig.tripStartDateRanges.label,
            name: tripFieldsConfig.tripStartDateRanges.fieldFilterName,
            ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
            hideLabelOnAllFilters: true,
            groupName: 'Route Information',
          } as any,
          {
            default: false,
            label: tripFieldsConfig.tripEndDateRanges.label,
            name: tripFieldsConfig.tripEndDateRanges.fieldFilterName,
            ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
            hideLabelOnAllFilters: true,
            groupName: 'Route Information',
          },
          {
            default: false,
            label: tripFieldsConfig.tripStartState.label,
            name: tripFieldsConfig.tripStartState.fieldName,
            type: 'MultipleAutocomplete',
            getData: async () => ({
              content: await getStateOptions(),
              last: true,
            }),
            fieldName: 'value',
            hideLabelOnAllFilters: false,
            groupName: 'Trip Information',
          },
          {
            default: false,
            label: tripFieldsConfig.tripEndState.label,
            name: tripFieldsConfig.tripEndState.fieldName,
            type: 'MultipleAutocomplete',
            getData: async () => ({
              content: await getStateOptions(),
              last: true,
            }),
            fieldName: 'value',
            hideLabelOnAllFilters: false,
            groupName: 'Trip Information',
          },
          {
            fieldName: 'label',
            fieldValue: 'value',
            default: false,
            label: tripFieldsConfig.relay.label,
            name: tripFieldsConfig.relay.fieldName,
            type: 'GridSwitchFilter',
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ],
          },
          {
            fieldName: 'label',
            fieldValue: 'value',
            default: false,
            label: tripFieldsConfig.delayViolations.label,
            name: tripFieldsConfig.delayViolations.fieldFilterName,
            type: 'GridSwitchFilter',
            options: TripYesOnlyOptions,
            hideLabelOnAllFilters: true,
            groupName: 'Route Information',
          },
          {
            fieldName: 'label',
            fieldValue: 'value',
            default: false,
            label: tripFieldsConfig.detention.label,
            name: tripFieldsConfig.detention.fieldFilterName,
            type: 'GridSwitchFilter',
            options: TripYesOnlyOptions,
            hideLabelOnAllFilters: true,
            groupName: 'General',
          },
          {
            default: false,
            label: tripFieldsConfig.needDriversNow.label,
            name: tripFieldsConfig.needDriversNow.fieldFilterName,
            type: 'GridSwitchFilter',
            fieldName: 'label',
            fieldValue: 'value',
            options: TripYesOnlyOptions,
            hideLabelOnAllFilters: true,
            groupName: 'General',
          },
          {
            key: 21,
            default: false,
            label: tripFieldsConfig.isEmptyTrip.label,
            name: tripFieldsConfig.isEmptyTrip.fieldFilterName,
            type: 'GridSwitchFilter',
            fieldName: 'label',
            fieldValue: 'value',
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: undefined,
              },
            ],
          },
          {
            key: 25,
            name: 'tagIds',
            fieldName: 'name',
            getData: getPaginatedTagsList,
            label: LoadViewTranslations.loadTags,
            type: 'MultipleAutocomplete',
            isCustomTag: true,
          },
        ];
        return filtersFormConfig.map((config, idx) => ({
          ...config,
          key: idx,
        }));
      },
      defFilters: defaultFilters,
    };
  }
}

const defaultViewConfig = {
  shared: true,
  parentId: null,
  active: true,
  default: true,
};

export const unassignedTripsId = 'unassignedTrips';
export const availableTrips = {
  id: unassignedTripsId,
  name: 'Unassigned Trips',
  label: 'Unassigned Trips',
  icon: <DnsOutlinedIcon />,
  ...defaultViewConfig,
  entity: unassignedTripsId,
  columnFilters: {
    [tripFieldsConfig.plusButton.fieldName]: false,
    [tripFieldsConfig.warning.fieldName]: true,
    [tripFieldsConfig.tripId.fieldName]: true,
    [tripFieldsConfig.tripStatus.fieldName]: true,
    [tripFieldsConfig.driver.fieldName]: true,
    [tripFieldsConfig.tractor.fieldName]: true,
    [tripFieldsConfig.trailer.fieldName]: true,
    [tripFieldsConfig.chassis.fieldName]: true,
    [tripFieldsConfig.container.fieldName]: true,
    [tripFieldsConfig.legs.fieldName]: true,
    [tripFieldsConfig.tripStart.fieldName]: true,
    [tripFieldsConfig.tripEnd.fieldName]: true,
    [tripFieldsConfig.tripStartDate.fieldName]: true,
    [tripFieldsConfig.tripEndDate.fieldName]: true,
    [tripFieldsConfig.dispatcher.fieldName]: true,
    [tripFieldsConfig.loadId.fieldName]: true,
    [tripFieldsConfig.manifestId.fieldName]: true,
    [tripFieldsConfig.customer.fieldName]: true,
    [tripFieldsConfig.reference.fieldName]: true,
    [tripFieldsConfig.settlementStatus.fieldName]: true,
    [tripFieldsConfig.settlementAmount.fieldName]: true,
    [tripFieldsConfig.revenue.fieldName]: true,
    [tripFieldsConfig.tripMiles.fieldName]: true,
    [tripFieldsConfig.emptyMiles.fieldName]: true,
    [tripFieldsConfig.terminal.fieldName]: true,
    [tripFieldsConfig.createDate.fieldName]: true,
  },
  metaData: {
    ...defaultFilters,
  },
};

export const dispatchComponentKey = 'Dispatch';

export const tripSequenceSearchConfig = [
  {
    key: 11,
    default: true,
    label: TripsConstants.TripSearchTrips,
    name: tripFieldsConfig.tripId.fieldFilterName,
    type: 'MultipleAutocomplete',
    fieldName: 'label',
    getData: getTripIdData,
    customStyles: {
      width: '208px',
    },
    PopperSxProps: {
      minWidth: '270px',
      maxWidth: '270px',
    },
    startAdornment: <SearchOutlined fontSize="small" />,
  },
];
