import React, { FC } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

const StyledArrowButton: FC<
  ButtonProps & {
    up?: boolean;
  }
> = ({ sx, up, ...props }) => {
  return (
    <Button
      sx={{
        transition: '200ms',
        width: 30,
        p: 0,
        height: 25,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        border: 'none',
        backgroundColor: '#35446B',
        minWidth: 'unset',
        '&:hover': {
          backgroundColor: '#5C84CB',
          color: '#fff',
        },
        '.MuiButton-startIcon': {
          m: 0,
          transform: `rotate(${up ? 90 : -90}deg)`,
        },
        ...(sx || {}),
      }}
      variant="contained"
      {...props}
    ></Button>
  );
};

export default StyledArrowButton;
