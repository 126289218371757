import { userService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  GetDriverTeamRequest,
  GetPrimaryDispatcherRequest,
  OptionType,
  TeamDriverFilterObject,
} from '../../../models';
import { PrimaryDispatcher } from '../../../models/DTOs/user/User';
import { PaginatedResult } from '../../../types';
import { gridPageSize } from '../../../utils';
import { getDriverTeamFilterList } from '../../operations/driversTeams/utils/integrationTeamDriver';

import StorageManager from '../../../StorageManager/StorageManager';
import {
  EDispatchOperationType,
  EEmploymentType,
  EOperationMode,
  IDispatchOption,
} from '../constants/types';

export const getSortOptions = (): IDispatchOption[] => {
  return [{ key: 'groupName', value: 'Name' }];
};

export const getOperationTypeOptions = (): IDispatchOption[] => {
  return [
    { key: EDispatchOperationType.LOCAL, value: 'Local' },
    { key: EDispatchOperationType.REGIONAL, value: 'Regional' },
    { key: EDispatchOperationType.OTR, value: 'Over-the-Road (OTR)' },
  ];
};
export const getOperationModeOptions = (): IDispatchOption[] => {
  return [
    { key: EOperationMode.TEAM, value: 'Team' },
    { key: EOperationMode.SOLO, value: 'Solo' },
  ];
};
export const getEmploymentTypeOptions = (): IDispatchOption[] => {
  return [
    { key: EEmploymentType.OWNER_OPERATOR, value: 'Owner Operator' },
    { key: EEmploymentType.EMPLOYEE, value: 'Employee' },
  ];
};

export const getDateRangeOptions = (): IDispatchOption[] => {
  return [
    { key: 3, value: '3 Days' },
    { key: 5, value: '5 Days' },
    { key: 7, value: '7 Days' },
  ];
};

export const getYesNoOptions = (): IDispatchOption[] => {
  return [
    { key: true, value: 'Yes' },
    { key: false, value: 'No' },
  ];
};

export const getDispatchDriverOptions = async (
  searchText: string | undefined,
  pageNumber: number
): Promise<PaginatedResult<OptionType> | undefined> => {
  const terminalIds =
    StorageManager.getItem('globalSelectedTerminals')?.map((item) => item.id) ||
    [];

  const request = new GetDriverTeamRequest();
  request.pageNumber = pageNumber;
  request.pageSize = gridPageSize;
  request.terminalIds = terminalIds;
  request.nameFilter = searchText;
  request.excludeStatuses = 0;
  const response = await getDriverTeamFilterList(request);

  if (response instanceof ServiceError)
    return {
      content: [],
      last: true,
    };
  else
    return {
      ...response,
      content: response.content.map((item: TeamDriverFilterObject) => {
        return {
          value: item.driverName,
          key: item.driverId,
        };
      }),
    };
};

export const getDispatchSummaryOptions = async (
  searchText: string | undefined,
  pageNumber: number
): Promise<PaginatedResult<OptionType> | undefined> => {
  const request: GetPrimaryDispatcherRequest =
    new GetPrimaryDispatcherRequest();
  request.name = searchText || '';
  request.pageNumber = pageNumber;
  request.excludeRoleCodeList = ['DR'];
  request.sort = '+firstname,+lastname';

  const response = await userService.getPrimaryDispatchers(request);
  if (response instanceof ServiceError)
    return {
      content: [],
      last: true,
    };
  else
    return {
      ...response,
      content: response.content
        .map((user) => new PrimaryDispatcher(user))
        .map((item) => {
          return {
            value: item.fullName,
            key: item.id,
          };
        }),
    };
};

export const getPrefferedTrukstopsOptions = (
  searchText: string | undefined,
  pageNumber: number
) => getDispatchSummaryOptions(searchText, pageNumber);
