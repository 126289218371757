import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { ELoadStatus } from '../../../../common/LoadTabPanel/constants/constants';
import { TripsConstants } from '../../../../locales/en/allLoads/trips/index';
import { ETripSettlementStatus } from '../../../../models/DTOs/Trip/Types';
import { ViewMap } from '../../../../types';
import { TrolleyIcon } from '../../../../ui-kit/components/Assets';
import { getGridCompanyTimezoneSettings } from '../../../../utils/Timezone.utils';
import { tripFieldsConfig } from '../../constants/constants';
import {
  getSettlementStatusByValues,
  getTripStatusByValues,
} from '../../utils';
import { defaultFilters } from '../TripDataGrid/grid.config';
const timezone = getGridCompanyTimezoneSettings().timezone;

export enum EEntityList {
  allTrips = 'allTrips',
  needDrivers = 'needDrivers',
  activeTrips = 'activeTrips',
  toBeSettled = 'toBeSettled',
  pastTrips = 'pastTrips',
}

const defaultViewConfig = {
  shared: true,
  parentId: null,
  active: true,
  default: true,
};

export const allTrips = {
  id: EEntityList.allTrips,
  name: TripsConstants.all,
  icon: <DnsOutlinedIcon />,
  ...defaultViewConfig,
  entity: EEntityList.allTrips,
  columnFilters: {
    // @Gevorg Todo ,needs refactoring to remove duplicate code
    [tripFieldsConfig.warning.fieldName]: true,
    [tripFieldsConfig.tripId.fieldName]: true,
    [tripFieldsConfig.tripStatus.fieldName]: true,
    [tripFieldsConfig.tags.fieldName]: true,
    [tripFieldsConfig.brokerageTrip.fieldName]: true,
    [tripFieldsConfig.driver.fieldName]: true,
    [tripFieldsConfig.carrierDetails.fieldName]: true,
    [tripFieldsConfig.tractor.fieldName]: true,
    [tripFieldsConfig.trailer.fieldName]: true,
    [tripFieldsConfig.chassis.fieldName]: true,
    [tripFieldsConfig.container.fieldName]: true,
    [tripFieldsConfig.legs.fieldName]: true,
    [tripFieldsConfig.loadPriority.fieldName]: true,
    [tripFieldsConfig.canBeBrokered.fieldName]: true,
    [tripFieldsConfig.tripStart.fieldName]: true,
    [tripFieldsConfig.tripEnd.fieldName]: true,
    [tripFieldsConfig.tripStartDate.fieldName]: true,
    [tripFieldsConfig.tripEndDate.fieldName]: true,
    [tripFieldsConfig.dispatcher.fieldName]: true,
    [tripFieldsConfig.loadId.fieldName]: true,
    [tripFieldsConfig.manifestId.fieldName]: true,
    [tripFieldsConfig.customer.fieldName]: true,
    [tripFieldsConfig.reference.fieldName]: true,
    [tripFieldsConfig.settlementStatus.fieldName]: true,
    [tripFieldsConfig.settlementAmount.fieldName]: true,
    [tripFieldsConfig.revenue.fieldName]: true,
    [tripFieldsConfig.tripMiles.fieldName]: true,
    [tripFieldsConfig.emptyMiles.fieldName]: true,
    [tripFieldsConfig.terminal.fieldName]: true,
    [tripFieldsConfig.createDate.fieldName]: true,
  },
  metaData: {
    ...defaultFilters,
    // [tripFieldsConfig.tripStartDate.fieldFilterName]:
    //   RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
    //     ERelativeDateRangeKey.PeriodTimeLast3Months,
    //     timezone
    //   ),
  },
};

export const needDriversView = {
  id: EEntityList.needDrivers,
  name: TripsConstants.needDrivers,
  icon: <AssignmentIndOutlinedIcon />,
  ...defaultViewConfig,
  entity: EEntityList.needDrivers,
  columnFilters: {
    [tripFieldsConfig.warning.fieldName]: true,
    [tripFieldsConfig.tripId.fieldName]: true,
    [tripFieldsConfig.tripStatus.fieldName]: true,
    [tripFieldsConfig.tags.fieldName]: true,
    [tripFieldsConfig.brokerageTrip.fieldName]: true,
    [tripFieldsConfig.driver.fieldName]: true,
    [tripFieldsConfig.carrierDetails.fieldName]: true,
    [tripFieldsConfig.tractor.fieldName]: true,
    [tripFieldsConfig.trailer.fieldName]: true,
    [tripFieldsConfig.chassis.fieldName]: true,
    [tripFieldsConfig.container.fieldName]: true,
    [tripFieldsConfig.legs.fieldName]: true,
    [tripFieldsConfig.tripStart.fieldName]: true,
    [tripFieldsConfig.tripEnd.fieldName]: true,
    [tripFieldsConfig.tripStartDate.fieldName]: true,
    [tripFieldsConfig.tripEndDate.fieldName]: true,
    [tripFieldsConfig.dispatcher.fieldName]: true,
    [tripFieldsConfig.loadId.fieldName]: true,
    [tripFieldsConfig.manifestId.fieldName]: true,
    [tripFieldsConfig.customer.fieldName]: true,
    [tripFieldsConfig.reference.fieldName]: true,
    [tripFieldsConfig.settlementStatus.fieldName]: false,
    [tripFieldsConfig.settlementAmount.fieldName]: false,
    [tripFieldsConfig.revenue.fieldName]: true,
    [tripFieldsConfig.tripMiles.fieldName]: true,
    [tripFieldsConfig.emptyMiles.fieldName]: true,
    [tripFieldsConfig.terminal.fieldName]: true,
    [tripFieldsConfig.createDate.fieldName]: true,
  },
  metaData: {
    ...defaultFilters,
    [tripFieldsConfig.tripStatus.fieldFilterName!]: getTripStatusByValues([
      ELoadStatus.AVAILABLE,
      ELoadStatus.ASSIGNMENT_PLANNED,
    ]),
  },
};

export const activeTripsView = {
  id: EEntityList.activeTrips,
  name: TripsConstants.activeTrips,
  icon: <TrolleyIcon />,
  ...defaultViewConfig,
  entity: EEntityList.activeTrips,
  columnFilters: {
    [tripFieldsConfig.warning.fieldName]: true,
    [tripFieldsConfig.tripId.fieldName]: true,
    [tripFieldsConfig.tripStatus.fieldName]: true,
    [tripFieldsConfig.tags.fieldName]: true,
    [tripFieldsConfig.brokerageTrip.fieldName]: true,
    [tripFieldsConfig.driver.fieldName]: true,
    [tripFieldsConfig.carrierDetails.fieldName]: true,
    [tripFieldsConfig.tractor.fieldName]: true,
    [tripFieldsConfig.trailer.fieldName]: true,
    [tripFieldsConfig.chassis.fieldName]: true,
    [tripFieldsConfig.container.fieldName]: true,
    [tripFieldsConfig.legs.fieldName]: true,
    [tripFieldsConfig.tripStart.fieldName]: true,
    [tripFieldsConfig.tripEnd.fieldName]: true,
    [tripFieldsConfig.tripStartDate.fieldName]: true,
    [tripFieldsConfig.tripEndDate.fieldName]: true,
    [tripFieldsConfig.dispatcher.fieldName]: true,
    [tripFieldsConfig.loadId.fieldName]: true,
    [tripFieldsConfig.manifestId.fieldName]: true,
    [tripFieldsConfig.customer.fieldName]: true,
    [tripFieldsConfig.reference.fieldName]: true,
    [tripFieldsConfig.settlementStatus.fieldName]: false,
    [tripFieldsConfig.settlementAmount.fieldName]: false,
    [tripFieldsConfig.revenue.fieldName]: true,
    [tripFieldsConfig.tripMiles.fieldName]: true,
    [tripFieldsConfig.emptyMiles.fieldName]: true,
    [tripFieldsConfig.terminal.fieldName]: true,
    [tripFieldsConfig.createDate.fieldName]: true,
  },
  metaData: {
    ...defaultFilters,
    [tripFieldsConfig.tripStatus.fieldFilterName!]: getTripStatusByValues([
      ELoadStatus.OFFERED_TO_DRIVER,
      ELoadStatus.DISPATCHED,
      ELoadStatus.IN_TRANSIT,
    ]),
  },
};

export const toBeSettledView = {
  id: EEntityList.toBeSettled,
  name: TripsConstants.toBeSettled,
  icon: <CheckOutlinedIcon />,
  ...defaultViewConfig,
  entity: EEntityList.toBeSettled,
  columnFilters: {
    [tripFieldsConfig.warning.fieldName]: true,
    [tripFieldsConfig.tripId.fieldName]: true,
    [tripFieldsConfig.tripStatus.fieldName]: true,
    [tripFieldsConfig.tags.fieldName]: true,
    [tripFieldsConfig.brokerageTrip.fieldName]: true,
    [tripFieldsConfig.driver.fieldName]: true,
    [tripFieldsConfig.carrierDetails.fieldName]: true,
    [tripFieldsConfig.tractor.fieldName]: true,
    [tripFieldsConfig.trailer.fieldName]: true,
    [tripFieldsConfig.chassis.fieldName]: true,
    [tripFieldsConfig.container.fieldName]: true,
    [tripFieldsConfig.legs.fieldName]: true,
    [tripFieldsConfig.tripStart.fieldName]: true,
    [tripFieldsConfig.tripEnd.fieldName]: true,
    [tripFieldsConfig.tripStartDate.fieldName]: true,
    [tripFieldsConfig.tripEndDate.fieldName]: true,
    [tripFieldsConfig.dispatcher.fieldName]: true,
    [tripFieldsConfig.loadId.fieldName]: true,
    [tripFieldsConfig.manifestId.fieldName]: true,
    [tripFieldsConfig.customer.fieldName]: true,
    [tripFieldsConfig.reference.fieldName]: true,
    [tripFieldsConfig.settlementStatus.fieldName]: true,
    [tripFieldsConfig.settlementAmount.fieldName]: true,
    [tripFieldsConfig.revenue.fieldName]: true,
    [tripFieldsConfig.tripMiles.fieldName]: true,
    [tripFieldsConfig.emptyMiles.fieldName]: true,
    [tripFieldsConfig.terminal.fieldName]: true,
    [tripFieldsConfig.createDate.fieldName]: true,
  },
  metaData: {
    ...defaultFilters,
    [tripFieldsConfig.settlementStatus.fieldFilterName!]:
      getSettlementStatusByValues([
        ETripSettlementStatus.Pending,
        ETripSettlementStatus.InReview,
      ]),
  },
};

export const pastTripsView = {
  id: EEntityList.pastTrips,
  name: TripsConstants.pastTrips,
  icon: <RequestQuoteOutlinedIcon />,
  ...defaultViewConfig,
  entity: EEntityList.pastTrips,
  columnFilters: {
    [tripFieldsConfig.warning.fieldName]: true,
    [tripFieldsConfig.tripId.fieldName]: true,
    [tripFieldsConfig.tripStatus.fieldName]: true,
    [tripFieldsConfig.tags.fieldName]: true,
    [tripFieldsConfig.brokerageTrip.fieldName]: true,
    [tripFieldsConfig.driver.fieldName]: true,
    [tripFieldsConfig.carrierDetails.fieldName]: true,
    [tripFieldsConfig.tractor.fieldName]: true,
    [tripFieldsConfig.trailer.fieldName]: true,
    [tripFieldsConfig.chassis.fieldName]: true,
    [tripFieldsConfig.container.fieldName]: true,
    [tripFieldsConfig.legs.fieldName]: true,
    [tripFieldsConfig.tripStart.fieldName]: true,
    [tripFieldsConfig.tripEnd.fieldName]: true,
    [tripFieldsConfig.tripStartDate.fieldName]: true,
    [tripFieldsConfig.tripEndDate.fieldName]: true,
    [tripFieldsConfig.dispatcher.fieldName]: true,
    [tripFieldsConfig.loadId.fieldName]: true,
    [tripFieldsConfig.manifestId.fieldName]: true,
    [tripFieldsConfig.customer.fieldName]: true,
    [tripFieldsConfig.reference.fieldName]: true,
    [tripFieldsConfig.settlementStatus.fieldName]: true,
    [tripFieldsConfig.settlementAmount.fieldName]: true,
    [tripFieldsConfig.revenue.fieldName]: true,
    [tripFieldsConfig.tripMiles.fieldName]: true,
    [tripFieldsConfig.emptyMiles.fieldName]: true,
    [tripFieldsConfig.terminal.fieldName]: true,
    [tripFieldsConfig.createDate.fieldName]: true,
  },
  metaData: {
    ...defaultFilters,
    [tripFieldsConfig.settlementStatus.fieldFilterName!]:
      getSettlementStatusByValues([ETripSettlementStatus.Paid]),
    // [tripFieldsConfig.tripEndDateRanges.fieldName]:
    //   RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
    //     ERelativeDateRangeKey.PeriodTimeLast3Months,
    //     timezone
    //   ),
  },
};

export const defaultViews = [
  allTrips,
  needDriversView,
  activeTripsView,
  toBeSettledView,
  pastTripsView,
];

export const formDefaultValuesMap: ViewMap = {
  [EEntityList.allTrips]: allTrips,
  [EEntityList.needDrivers]: needDriversView,
  [EEntityList.activeTrips]: activeTripsView,
  [EEntityList.toBeSettled]: toBeSettledView,
  [EEntityList.pastTrips]: pastTripsView,
};
