import { fuelMatrixService, preferencesService } from '@/api';
import { ServiceError } from '@/api/interfaces';
import {
  CreateUpdateFuelMatrixRequest,
  GetFuelMatrixRequest,
} from '@/models/DTOs/FuelMatrix/Requests';
import { FuelMatrix } from '@/models';

export default class FuelMatrixActions {
  static async getFuelMatrixList(statuses?: string[]) {
    const request = new GetFuelMatrixRequest();
    request.statusFilter = statuses;
    const result = await fuelMatrixService.getFuelMatrixList(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async createFuelMatrix(data: FuelMatrix) {
    const request = new CreateUpdateFuelMatrixRequest();
    request.matrixName = data.matrixName;
    request.description = data.description;
    request.status = 'ACTIVE';
    request.fuelSurchargeItems = data.fuelSurchargeItems;
    const result = await fuelMatrixService.createFuelMatrixList(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async updateFuelMatrix(data: FuelMatrix) {
    const request = new CreateUpdateFuelMatrixRequest();
    request.id = data.id;
    request.matrixName = data.matrixName;
    request.description = data.description;
    request.status = data.status;
    request.fuelSurchargeItems = data.fuelSurchargeItems;
    const result = await fuelMatrixService.updateFuelMatrixList(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }
}
