import {
  Box,
  BoxProps,
  Chip,
  ChipProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

export const StyledChip = styled((props: ChipProps) => <Chip {...props} />)(
  ({ theme }: any) => ({
    height: '26px',
    marginTop: '10px',
    ...theme.typography?.chip,
  })
);

export const StyledLeftTitle = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme, isActive = false, isDark = false }: any) => ({
  marginBottom: '5px',
  color: isActive
    ? isDark
      ? theme.palette.primary.dark
      : theme.palette.primary.main
    : theme.palette.text.primary,
  ...theme.typography.h7,
}));

export const StyledRightTitle = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme, isActive }: any) => ({
  color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
  ...theme.typography.h7,
}));

export const StyledRightSubTitle = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }: any) => ({
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap',
  ...theme.typography.body3,
}));

export const StyledDetailedDataSectionContent = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }: any) => ({
  padding: '5px 0',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'inline-flex',
  width: '100%',
  '&:last-child': {
    borderBottom: 'none',
  },
}));
