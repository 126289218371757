import Grid from '@mui/material/Grid';
import ViewBaseInfoForm from '../../../../common/AddView/components/ViewBaseInfoForm';
import { UserColumnsForm, UserMetaDataForm } from './UserViewForms';

const FilterTitle = ({ title }: { title: string }) => (
  <span
    style={{
      color: 'rgba(0, 17, 34, 0.6)',
      fontSize: 20,
      fontWeight: 500,
      fontFamily: 'Poppins',
    }}
  >
    {title}
  </span>
);

export default function ViewForm({
  customErrors,
}: {
  mode: string;
  customErrors: any;
}) {
  const CurrentColumnsForm = UserColumnsForm;
  const CurrentMetaDataForm = UserMetaDataForm;

  return (
    <Grid container justifyContent={'space-between'} style={{ height: '100%' }}>
      <Grid style={{ padding: 16 }} item sm={4}>
        <FilterTitle title={'1. Name Your View'} />
        <ViewBaseInfoForm customErrors={customErrors} />
      </Grid>
      <Grid
        style={{
          padding: 16,
          borderRight: '1.49777px solid #EEEEEE',
          borderLeft: '1.49777px solid #EEEEEE',
        }}
        item
        sm={4}
      >
        <FilterTitle title={'2. Pick Data'} />

        <CurrentColumnsForm />
      </Grid>
      <Grid style={{ padding: 16 }} item sm={4}>
        <FilterTitle title={'3. Add Filters'} />
        <CurrentMetaDataForm />
      </Grid>
    </Grid>
  );
}
