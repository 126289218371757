import { AXELE_ROLE_TYPE, AXELE_PERMISSION_TYPE } from '../../Permission';
import { getHasPermission } from '../../Permission/utils/helperUtils';

export const useDispatchPermission = () => {
  const hasDispatchViewPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.DISPATCHES_VIEW],
    // roleCodes: [
    //   AXELE_ROLE_TYPE.ADMIN,
    //   AXELE_ROLE_TYPE.FINANCIAL_DISPATCHER,
    //   AXELE_ROLE_TYPE.DISPATCHER,
    //   AXELE_ROLE_TYPE.ACCOUNTANT,
    //   AXELE_ROLE_TYPE.POWER_DISPATCHER,
    //   AXELE_ROLE_TYPE.SUPPORT,
    // ],
  });

  const hasDispatchEditPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.DISPATCH_EDIT],
    // roleCodes: [
    //   AXELE_ROLE_TYPE.ADMIN,
    //   AXELE_ROLE_TYPE.FINANCIAL_DISPATCHER,
    //   AXELE_ROLE_TYPE.DISPATCHER,
    //   AXELE_ROLE_TYPE.POWER_DISPATCHER,
    // ],
  });
  const hasDispatchPreferenceEditPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.DISPATCH_PREFERENCE_EDIT],
    // roleCodes: [
    //   AXELE_ROLE_TYPE.ADMIN,
    //   AXELE_ROLE_TYPE.FINANCIAL_DISPATCHER,
    //   AXELE_ROLE_TYPE.DISPATCHER,
    //   AXELE_ROLE_TYPE.POWER_DISPATCHER,
    // ],
  });

  const hasDispatchFindLoadPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.DISPATCHES_VIEW],
    // roleCodes: [
    //   AXELE_ROLE_TYPE.ADMIN,
    //   AXELE_ROLE_TYPE.FINANCIAL_DISPATCHER,
    //   AXELE_ROLE_TYPE.DISPATCHER,
    //   AXELE_ROLE_TYPE.POWER_DISPATCHER,
    //   AXELE_ROLE_TYPE.SUPPORT,
    // ],
  });

  return {
    hasDispatchViewPermission,
    hasDispatchEditPermission,
    hasDispatchPreferenceEditPermission,
    hasDispatchFindLoadPermission,
  };
};
