import { Model } from '../../../../../models/common/Model';

export class SafetyAlertModel extends Model {
  id: string;
  documentTypeId: string;
  documentType: string;
  driverId: number;
  validTo: Date | string;
  validFrom: Date | string;
  notes: string;
  safetyIssueTypeWithDaysAndMiles?: {
    days: number;
    issueType: string;
    miles: number;
  };
  organizationId: number;
  constructor(dto: any) {
    super(dto);
    this.id = dto.id;
    this.documentTypeId = dto.documentTypeId || '';
    this.validTo = dto.validTo || '';
    this.validFrom = dto.validFrom || '';
    this.notes = dto.notes || '';
    this.safetyIssueTypeWithDaysAndMiles = dto.safetyIssueTypeWithDaysAndMiles;
    this.organizationId = dto.organizationId;
    this.driverId = dto.driverId;
    this.documentType = dto.documentType;
  }
}

export type SafetyType = {
  id: string;
  documentTypeId: string;
  documentType: string;
  warningDuration: string;
  frequency: string;
  frequencyValue: null | number;
  recipient: null | number;
  dispatchAllowedIfCertificationExpires: boolean;
  warningDurationByDays: number;
  iconPicker: any;
  updateDate: string;
  itemName: string;
};

export type DriverAlertType = {
  safety: SafetyType[];
};

export type SafetyFormType = {
  documentTypeId: string;
  documentType: string;
  validFrom: Date;
  validTo: Date;
  notes: string;
};

export type LineItem = {
  id: string;
  amount: number;
  description: string;
  itemId: string;
  itemCode: string;
  itemName: string;
  itemType: string;
  quantity: number;
  rate: number;
  type: string;
  frequency: string;
  frequencyValue: number;
};
