import { CustomViews } from '../../../../common/Views/CustomViews';
import { useLoadsearchLane } from '../../../../contexts/LoadsearchLaneContext';
import {
  ELoadBoardEntity,
  bookedLoadView,
  formDefaultValuesMap,
  loadSearchView,
  pendingLoadView,
} from '../../constants';
import ViewForm from './ViewForm';

const defaultViews = [
  { ...loadSearchView },
  { ...pendingLoadView },
  { ...bookedLoadView },
];

export function LoadboardViewsSection() {
  return (
    <CustomViews
      entities={[ELoadBoardEntity.loadsearch, ELoadBoardEntity.pendingLoad]}
      hook={useLoadsearchLane}
      defaultViews={defaultViews}
      formDefaultValuesMap={formDefaultValuesMap}
      viewForm={ViewForm}
      defaultView={loadSearchView}
      modalTitle={'Create a Lane'}
    />
  );
}
