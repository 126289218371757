import { customerService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import {
  ContactSummaryCustomer,
  CreateCustomerContactRequest,
  CreateCustomerRequest,
  UpdateCustomerRequest,
} from '../../../../models';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import { SearchCustomerResDTO } from '../types/requests';
import { ILoad } from '../types/types';

class CustomerFormService {
  validateAndGetEquipmentValue = (
    existingValuesList: ILoad['equipment'],
    newInputs: ILoad['equipment']
  ): ILoad['equipment'] => {
    if (newInputs?.[newInputs?.length - 1] === 'Chassis_Container')
      return ['Chassis_Container'];
    if (existingValuesList?.includes?.('Chassis_Container')) {
      newInputs?.shift();
      return newInputs;
    }
    return newInputs;
  };

  getCustomerDropdownOptions({
    customersList,
    searchText,
  }: {
    customersList: Array<SearchCustomerResDTO[]>;
    searchText?: string;
  }): Array<SearchCustomerResDTO> {
    const customers: Array<SearchCustomerResDTO> = [];
    customersList?.forEach?.((e) => {
      if (e) {
        customers?.push(...(e as Array<SearchCustomerResDTO>));
      }
    });
    if (searchText?.length) {
      const isCustomerNameExists = customers?.find?.(
        (e: any) => e?.name?.toLowerCase?.() === searchText?.toLowerCase?.()
      );
      if (isCustomerNameExists) return customers;
      return [
        {
          type: 'NEW_CUSTOMER',
          name: `Add “${searchText}”`,
          value: searchText,
        },
        ...(customers ?? []),
      ];
    }
    return customers ?? [];
  }

  getCustomerContactDropdownOptions({
    contacts,
    searchText,
  }: {
    contacts: Array<ContactSummaryCustomer>;
    searchText?: string;
  }): Array<ContactSummaryCustomer> {
    if (searchText?.length) {
      const isContactExists = contacts?.find?.(
        (e) => e?.fullName === searchText
      );
      if (isContactExists) return contacts;
      return [
        {
          fullName: `Add “${searchText}”`,
          value: searchText,
          phoneData: {},
        },
        ...(contacts ?? []),
      ] as Array<ContactSummaryCustomer>;
    }
    return contacts ?? [];
  }

  async createNewCustomer(name: string) {
    if (!name?.length) return;
    const response = await customerService.createCustomer(
      new CreateCustomerRequest({
        name,
      })
    );
    if (response instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'customerCreate',
      });
      throw new Error('Failed to create customer');
    }
    RootStoreInstence.setNotificationType({
      type: 'SUCCESS',
      serviceName: 'customerCreate',
    });
    return response;
  }

  async updateCustomerContact(requestData: UpdateCustomerRequest) {
    return await customerService.updateCustomer(requestData);
  }

  async createCustomerNewContact(requestData: CreateCustomerContactRequest) {
    return await customerService.createContact(requestData);
  }
}

const customerFormService = new CustomerFormService();

export { customerFormService };
