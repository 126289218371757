import { ComposedError, httpClient } from '../../../axios/axiosInstance';
import { PaginatedMaintenanceEquipmentList } from '../../../models/DTOs/maintenance/maintenanceEquipment/Model';
import { GetMaintenanceEquipmentByOrganizationIdRequest } from '../../../models/DTOs/maintenance/maintenanceEquipment/Requests';
import {
  annotateNameAsync,
  IMaintenanceEquipmentService,
  ServiceError,
} from '../../interfaces';

const basePathApi = '/web/asset/api/v2/maintenance/history/equipment/details';
const resources = {
  getByOrganizationId: `${basePathApi}/list`,
};

export class MaintenanceEquipmentService extends IMaintenanceEquipmentService {
  @annotateNameAsync
  async getByOrganizationId(
    requestData: GetMaintenanceEquipmentByOrganizationIdRequest
  ): Promise<PaginatedMaintenanceEquipmentList | ServiceError> {
    try {
      const response = await httpClient.get<PaginatedMaintenanceEquipmentList>(
        resources.getByOrganizationId,
        requestData,
        PaginatedMaintenanceEquipmentList,
        undefined,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
