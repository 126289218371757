import { Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Recurrence from '../../../../ui-kit/components/Recurrence';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormContextTractor from '../../../equipment/TractorPaymentTerm/TractorPaymentTermFormContext';
import { EScheduleType } from '../../constants';
import FormContext from '../PaymentTermForm/FormContext';
import {
  createDataScheduledPayment,
  createPayloadScheduledPayment,
  updatedPayloadScheduledPayment,
} from './HelperScheduleConfig';
import { ScheduledPaymentTypes } from './ScheduledPaymentConstant';

const AddScheduledPayment = ({
  titleText = 'Add Scheduled Payment',
  dialog = true,
  recurrenceEntityType = '',
  showDelete = true,
  isOpen = false,
  expenseCategoriesList = [],
  closeModel,
  expenseCategoriesDefaultValue,
  id,
  createdForm,
  modeType,
  editDataParams,
  intialData,
  type,
  configJson,
  sectionName,
}: ScheduledPaymentTypes) => {
  const { setValue } = useFormContext();

  const [open, setOpen] = useState(isOpen);
  const [mode] = useState(modeType ? modeType : 'create');
  const [editData] = useState(editDataParams);
  const { scheduledPayment, scheduledDeduction } = React.useContext(
    sectionName === 'driver' ? FormContext : FormContextTractor
  );
  const onCloseDialog = () => {
    setOpen(false);
    closeModel && closeModel();
  };
  const theme = useTheme();
  const createRecurrence = async (data: any) => {
    if (mode === 'create') {
      const payload = createPayloadScheduledPayment(
        data,
        type,
        id,
        configJson.typeId
      );
      payload.value =
        Math.abs(payload.value) * (EScheduleType.Deduction === type ? -1 : 1);
      const payloadRequest = await createDataScheduledPayment(
        payload,
        mode,
        configJson.createAPI
      );
      if (payloadRequest) {
        if (EScheduleType.Payment === type) {
          setValue('scheduledPayment', scheduledPayment.push(payloadRequest));
        } else if (EScheduleType.Deduction === type) {
          setValue(
            'scheduledDeduction',
            scheduledDeduction.push(payloadRequest)
          );
        }
        createdForm && createdForm();
      }
    } else {
      const payload = updatedPayloadScheduledPayment(
        data,
        recurrenceEntityType,
        intialData,
        configJson.typeId
      );
      payload.value =
        Math.abs(payload.value) * (EScheduleType.Deduction === type ? -1 : 1);
      const payloadRequest = await createDataScheduledPayment(
        payload,
        mode,
        configJson.updateAPI
      );
      if (payloadRequest) {
        if (EScheduleType.Payment === type) {
          const filteredItems = scheduledPayment.findIndex(
            (paymentTerm) => paymentTerm.id === payloadRequest.id
          );
          setValue(
            'scheduledPayment',
            (scheduledPayment[filteredItems] = payloadRequest)
          );
        } else if (EScheduleType.Deduction === type) {
          const filteredItems = scheduledDeduction.findIndex(
            (paymentTerm) => paymentTerm.id === payloadRequest.id
          );
          setValue(
            'scheduledDeduction',
            (scheduledDeduction[filteredItems] = payloadRequest)
          );
        }
        createdForm && createdForm();
      }
    }
  };
  const today = new Date(new Date().setDate(new Date().getDate() + 1));
  const endDay = new Date(new Date().setFullYear(new Date().getFullYear() + 3));
  const renderDialog = () => (
    <>
      <Dialog
        open={open}
        PaperProps={{
          style: {
            borderRadius: 20,
            padding: 30,
            backgroundColor: '#fff',
          },
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            color={theme.palette.primary.main}
            align="left"
            variant="h6"
          >
            {titleText}
          </Typography>
          <IconButton onClick={onCloseDialog}>
            <CloseIcon sx={{ color: theme.palette.text.secondary }} />
          </IconButton>
        </Grid>
        <Recurrence
          dialog={dialog}
          useDarkMode={false}
          onCloseDialog={onCloseDialog}
          mode={mode}
          createRecurrence={createRecurrence}
          editData={editData}
          deleteRecurrence={''}
          showDelete={showDelete}
          entityType={recurrenceEntityType}
          isInitialCreate={true}
          expenseCategoriesDefaultValue={expenseCategoriesDefaultValue}
          expenseCategoriesList={expenseCategoriesList}
          disabledManualInput={true}
          today={today}
          endDay={endDay}
        />
      </Dialog>
    </>
  );
  return <>{renderDialog()}</>;
};

export default AddScheduledPayment;
