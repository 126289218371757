import { InvoiceModel } from '@/subPages/invoices/models/InvoiceModel';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@components/ui-kit/accordion';
import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AttachMoney } from '@mui/icons-material';
import { Box, Stack, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { LoadSectionIconPrimary } from '../../../../../_assets/static/svgs/LoadSectionIconPrimary';
import { useStores } from '../../../../../store/root.context';
import { LineItem } from '../../../../../types';
import Header from '../../../../loadsList/CreateLoad/components/header';
import {
  RateDetails,
  RateSummary,
} from '../../../../loadsList/CreateLoad/components/rate';
import { CloseLoadFormDialog } from '../../../../loadsList/CreateLoad/components/ui/close-load-form-dialog';
import {
  FLAT_RATE_KEY,
  PER_LOADED_MILE_KEY,
  PER_LOADED_WEIGHT_KEY,
} from '../../../../loadsList/CreateLoad/constants/fieldOptions';
import { LOAD_SECTION_KEYS_LIST } from '../../../../loadsList/CreateLoad/constants/fieldValues';
import { QUERY_KEYS } from '../../../../loadsList/CreateLoad/constants/queryKeys';
import { getRequiredDocsList } from '../../../../loadsList/CreateLoad/dto/template-res.dto';
import { rateFormService } from '../../../../loadsList/CreateLoad/services/rate.service';
import { getCreateLoadPanelStyles } from '../../../../loadsList/CreateLoad/styles';
import { ILoadDetails } from '../../../../loadsList/CreateLoad/types/types';
import { formatTextToUpperCase } from '../../../../loadsList/CreateLoad/utils';
import InvoiceActions from '../../../InvoiceActions';
import { Warnings } from '../WarningsSection';
import { LoadGroupInvoiceSection2 } from './LoadGroupInvoiceSection2';
import { initialState } from './defaultValue/data.config';
import { createLoadInvoceRate } from './schema';
interface IProps {
  onClose: () => void;
  open: boolean;
  onSave?: any;
  value?: ILoadDetails;
  data?: any;
  canEditInvoice: boolean;
  invoice: InvoiceModel;
}

const LoadInvoiceRate: React.FC<IProps> = ({
  onClose,
  open,
  onSave,
  value,
  data,
  canEditInvoice,
  invoice,
}) => {
  const theme = useTheme();
  const styles = getCreateLoadPanelStyles(theme as Theme);
  const {
    myLoadStore: { clearCreateLoadGlobalStates },
  } = useStores();
  const [closeAddLoadDialog, setCloseAddLoadDialog] = useState<boolean>(false);
  const closeAddLoadWarningDialog = () => setCloseAddLoadDialog(false);

  const [expandedSection, setExpandedSection] = React.useState<string | false>(
    'loadDetails'
  );
  const expandedSectionRef = useRef<string>();
  const addLoadRef = useRef<HTMLInputElement>();
  const handleExpandedSectionChange =
    (section: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedSection(isExpanded ? section : '');
    };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const form = useForm({
    resolver: yupResolver(createLoadInvoceRate),
    reValidateMode: 'onChange',
    defaultValues: {
      ...initialState,
    },
  });
  const lineitemList = InvoiceActions.invoiceLineItemsList;

  // To get Invoice line Item
  const invoiceLineItems = useQuery<{ base: LineItem[]; fees: LineItem[] }>(
    QUERY_KEYS.lineItems,
    async () => {
      const rate = form.getValues('rate');
      if (lineitemList?.length) {
        lineitemList?.forEach((item) => {
          //getting defaultItemcode from invoice line items since itemCode is manipulated and updated for
          // USERDEFINED_PAYMENT AND DEDUCTION in invoice details
          item.itemCode = item?.defaultItemCode ?? item?.itemCode;
        });
        const lineItemOptions = lineitemList.filter?.(
          (l) => l.itemType === 'BASE'
        );

        const accessorialOptions = lineitemList.filter?.(
          (item) => item.itemType !== 'BASE'
        );
        if (!rate?.baseRateType) {
          form.setValue('rate.feeDetail', lineItemOptions?.[0]);
          form.setValue('rate.baseRateType', lineItemOptions?.[0]?.id);
        }
        return {
          base: lineItemOptions as unknown as unknown as LineItem[],
          fees: accessorialOptions as unknown as LineItem[],
        };
      }
      const items = await rateFormService.getAccessorialFeeOptions();
      if (!rate?.baseRateType) {
        form.setValue('rate.feeDetail', items?.base?.[0]);
        form.setValue('rate.baseRateType', items?.base?.[0]?.id);
      }
      return items;
    },
    {
      refetchOnWindowFocus: false,
      enabled: open,
      keepPreviousData: true,
    }
  )?.data;

  useEffect(() => {
    if (!value || !open || !form) return;
    form.reset?.(value);
  }, [JSON.stringify(value), open]);

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyDownEvent);
    } else {
      document.removeEventListener('keydown', handleKeyDownEvent);
    }
  }, [open]);

  useEffect(() => {
    expandedSectionRef.current = expandedSection as string;
  }, [expandedSection]);

  function handleKeyDownEvent(event: any) {
    if (event?.shiftKey && event?.key === 'Tab') {
      event.stopPropagation();
      const index = LOAD_SECTION_KEYS_LIST.findIndex(
        (e) => e === expandedSectionRef?.current
      );
      if (index >= 0) {
        setExpandedSection(
          LOAD_SECTION_KEYS_LIST[
            index + 1 >= LOAD_SECTION_KEYS_LIST.length ? 0 : index + 1
          ]
        );
      }
    }
  }

  const closeAddLoad = () => {
    form.reset(initialState);
    setExpandedSection('loadDetails');
    clearCreateLoadGlobalStates?.();
    closeAddLoadWarningDialog();
    onClose?.();
  };

  const updateMissingRateTypes = async () => {
    const lineItemOptions = invoiceLineItems?.base;
    form.setValue('rate.baseRateType', lineItemOptions?.[0]?.id);
    form.setValue('rate.feeDetail', lineItemOptions?.[0]);
  };

  const handleOnSave = async () => {
    addLoadRef?.current?.scrollTo?.({ top: 0, behavior: 'smooth' });
    await form.trigger();
    form.clearErrors();
    const loadInvoiceRate = form.getValues();
    if (
      !loadInvoiceRate?.rate?.feeDetail &&
      !loadInvoiceRate?.rate?.baseRateType
    ) {
      updateMissingRateTypes();
    }
    // Check if loadInvoiceRate and loadInvoiceRate.rate are not undefined or null
    if (loadInvoiceRate && loadInvoiceRate.rate) {
      const rate = loadInvoiceRate.rate.feeDetail;
      const additionalFees = loadInvoiceRate.rate.additionalFees;
      const isContract = loadInvoiceRate.rate?.isContractEnabled;
      let baseLaneItemId: string | null = null;
      const Items: any[] = [];
      // Push BASE item
      if (isContract) {
        const itemList = lineitemList.find(
          (item) => item.itemCode === rate?.itemCode
        );
        if (itemList?.id) {
          baseLaneItemId = itemList?.id;
        }
      }
      if (
        loadInvoiceRate?.rate?.baseRateType &&
        loadInvoiceRate.rate?.rate != null &&
        loadInvoiceRate.rate?.rate != ''
      ) {
        Items.push({
          amount:
            parseInt(loadInvoiceRate?.rate?.quantity) *
            parseFloat(loadInvoiceRate?.rate?.rate),
          contractId: loadInvoiceRate?.rate?.contract?.id ?? null,
          contract: loadInvoiceRate?.rate?.contract,
          description: loadInvoiceRate.rate?.description,
          id: rate?.idExist,
          itemId: isContract ? baseLaneItemId : rate?.id,
          itemType: 'BASE',
          quantity: loadInvoiceRate.rate?.quantity,
          rate: loadInvoiceRate.rate?.rate,
          type: rate?.itemCode,
          contractRateId: rate?.contractRateId ?? null,
          isContract: !!rate?.isContract,
        });
      }
      // Check if additionalFees is not undefined or null before using forEach
      if (additionalFees) {
        additionalFees.forEach((d: any) => {
          if (d?.fee) {
            Items.push({
              amount: parseFloat(d?.total),
              description: d?.description,
              id: d?.idExist,
              itemId: d?.fee,
              itemType: 'ACCESSORIAL',
              quantity: parseFloat(d?.quantity),
              rate: parseFloat(d?.rate),
              type: d?.feeDetail?.itemCode,
              unit: d?.unit,
              contract: loadInvoiceRate?.rate?.contract,
              contractId: d?.contractId,
              contractRateId: d?.contractRateId ?? d?.feeDetail?.contractRateId,
              deduction: d?.deduction,
            });
          }
          // Push ACCESSORIAL items
        });
      }
      onSave({
        loadedMiles: loadInvoiceRate?.loadedMiles,
        emptyMiles: loadInvoiceRate?.emptyMiles,
        completeDate: loadInvoiceRate?.completeDate,
        requiredDocuments: loadInvoiceRate?.requiredDocuments,
        Items: Items,
        internalNotes: loadInvoiceRate?.rate?.internalNotes,
        messageOnInvoice: loadInvoiceRate?.rate?.messageOnInvoice,
        milesManuallyUpdated: loadInvoiceRate?.isMilesManuallyUpdated,
        isMilesManuallyUpdated: loadInvoiceRate?.isMilesManuallyUpdated,
      });
      form.reset(initialState);
      closeAddLoad();
    }
  };
  const handleDialogClose = (event: Event, reason: string) => {
    if (reason == 'backdropClick') return;
    setCloseAddLoadDialog(true);
  };

  const filterItemByItemCode = (list: any[], itemId: string) => {
    const additionalStopObject = list.find((item) => item.id === itemId);
    if (additionalStopObject) {
      return additionalStopObject;
    }
    return false;
  };

  const updateRateAdditionalFeeUnitOptions = (fees: LineItem[]) => {
    const additionalFees = form.getValues('rate.additionalFees');
    const updatedFeesWithUnits: any[] = [];
    additionalFees.forEach((item: any) => {
      const selectedItem = fees?.find?.((e) => e?.id === item?.id);
      updatedFeesWithUnits.push({ ...item, units: selectedItem?.unit });
    });
    form.setValue('rate.additionalFees', updatedFeesWithUnits);
  };

  const initiateValue = () => {
    const lineItemOptions: any[] = [];
    const feeItemOptions: any[] = [];
    let contract: any | null = null;
    if (data?.lineItems?.length) {
      data.lineItems.forEach((item: any) => {
        const itemList = filterItemByItemCode(lineitemList, item.itemId);
        const option = {
          id: item?.contractRateId ? item?.contractRateId : item.itemId,
          itemCode: item.type,
          description: item.description,
          rate: item.rate,
          destination: item.destination,
          quantity: item.quantity,
          idExist: item?.id,
          itemName: itemList?.itemName
            ? itemList?.itemName
            : formatTextToUpperCase(item.type),
          contractId: item?.contractId,
          contractRateId: item?.contractRateId,
          isContract: item?.isContract ?? Boolean(item?.contractId),
          deduction: item?.deduction,
        };
        if (item?.contractId) {
          contract = {
            ...item?.contract,
            id: item?.contract?.id ?? item?.contractId,
            name: '',
          };
        }
        if (
          item.type === FLAT_RATE_KEY ||
          item.type === PER_LOADED_MILE_KEY ||
          item.type === PER_LOADED_WEIGHT_KEY
        ) {
          lineItemOptions.push({
            ...option,
            totalMiles: item?.totalMiles || null,
          });
        } else {
          feeItemOptions.push({
            ...option,
            isContract: Boolean(item?.contractRateId),
            fee: item.itemId,
            total: item.amount,
            unit: item?.unit,
            units: itemList?.unit,
            feeDetail: {
              ...option,
              isContract: Boolean(item?.contractRateId),
              fee: item.itemId,
              total: item.amount,
              unit: item?.unit,
            },
          });
        }
      });
    }
    form.reset({
      ...initialState,
      loadedMiles: data?.loadedMiles,
      emptyMiles: data?.emptyMiles,
      completeDate: data?.completeDate,
      totalMiles: data?.loadedMiles,
      milesManuallyUpdated: data?.milesManuallyUpdated,
      mileageUpdatedBy: data?.mileageUpdatedBy,
      mileageUpdatedDate: data?.mileageUpdatedDate,
      requiredDocuments: getRequiredDocsList({
        bol: data?.billOfLadingMandatory,
        pod: data?.proofOfDeliveryMandatory,
      }),
      rate: {
        contract: contract,
        isContractEnabled: contract ? true : false,
        baseRateType: lineItemOptions?.[0]?.id,
        feeDetail: lineItemOptions?.[0],
        quantity: lineItemOptions?.[0]?.quantity,
        rate: lineItemOptions?.[0]?.rate,
        description: lineItemOptions?.[0]?.description,
        additionalFees: [
          ...feeItemOptions,
          initialState.rate?.additionalFees?.[0],
        ],
        contractRateId: lineItemOptions?.[0]?.contractRateId ?? null,
        internalNotes: data?.notes,
      },
    });
  };
  useEffect(() => {
    initiateValue();
  }, [data]);

  return (
    <>
      <Dialog
        sx={styles.dialog}
        onClose={handleDialogClose}
        open={open}
        maxWidth="lg"
      >
        <Header
          onClose={handleDialogClose as any}
          toggleSidebar={() => {}}
          title={`Associated Load ID : ${data?.seqNumber}`}
          isCreate={false}
        />
        <DialogContent id="add-load-dialog-content" sx={styles.root}>
          <Box sx={styles.container} className="CreateLoadPanel-root">
            <Box sx={styles.form} ref={addLoadRef}>
              <FormProvider {...form}>
                <form>
                  <Stack>
                    <Accordion
                      expanded={expandedSection === 'loadDetails'}
                      onChange={handleExpandedSectionChange('loadDetails')}
                    >
                      <AccordionSummary
                        id="loadDetails"
                        prefixIcon={<LoadSectionIconPrimary />}
                      >
                        Load Details
                      </AccordionSummary>
                      <AccordionDetails sx={{ px: 2, py: 2.5 }}>
                        <>
                          <LoadGroupInvoiceSection2
                            invoice={data}
                            invoiceData={invoice}
                            foundIndex={1}
                            type={''}
                            canEditInvoice={canEditInvoice}
                          />
                          {data.warnings?.length ||
                          data.missingDocuments?.length ? (
                            <Warnings
                              warnings={data.warnings}
                              missingDocuments={data.missingDocuments}
                            />
                          ) : null}
                        </>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                  <Accordion
                    expanded={expandedSection === 'rate'}
                    onChange={handleExpandedSectionChange('rate')}
                  >
                    <AccordionSummary id="rate" prefixIcon={<AttachMoney />}>
                      {expandedSection !== 'rate' &&
                      !!Number(form.getValues('rate')?.totalRate) ? (
                        <></>
                      ) : (
                        'Rate'
                      )}
                      {expandedSection !== 'rate' && (
                        <RateSummary hideNoteSection={true} />
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: 2, py: 2.5 }}>
                      <RateDetails
                        canEditInvoice={canEditInvoice}
                        hideNoteSection={true}
                      />
                    </AccordionDetails>
                  </Accordion>
                </form>
              </FormProvider>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ gap: '10px', padding: '15px 20px' }}>
          <Button onClick={handleDialogClose as any} size="large">
            Cancel
          </Button>
          <Button onClick={form.handleSubmit(handleOnSave) as any} size="large">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <CloseLoadFormDialog
        open={closeAddLoadDialog}
        onClose={closeAddLoadWarningDialog}
        onSubmit={closeAddLoad}
      />
    </>
  );
};
export default observer(LoadInvoiceRate);
