import { Box } from '@mui/system';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadService } from '../../../../../../api';
import { ELoadStatus } from '../../../../../../common/LoadTabPanel/constants/constants';
import { useRoutesLoad } from '../../../../../../contexts/LoadRoutesContext';
import { DriverShort } from '../../../../../../models/common/modelsShort';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';
import { Trip } from '../../Models';
import RoutesController from '../../RoutesController';
import AssignmentFormWrapper from '../AssignmentFormWrapper';
import PaymentAmountAssignComponent from './DriverAmountComponent';
import { FormComponent } from './DriverAssignmentForm';
import WarningWindow from './WarningWindow';

export default function DriverAssignmentToLoad({
  trip,
  onClose,
  onAssign,
}: {
  trip: Trip;
  onClose: () => void;
  onAssign: (driverGroup: DriverShort, assets) => void;
}): JSX.Element {
  const { setDriverAmountForAssign, driverAmountForAssign } = useRoutesLoad();
  const [data] = useState({
    driverGroup: null,
    prevLoad: null,
    loadStatus:
      trip?.tripStatus === ELoadStatus.LOAD_COMPLETED
        ? ELoadStatus.LOAD_COMPLETED
        : ELoadStatus.OFFERED_TO_DRIVER,
  });
  const [warnings, setWarnings] = useState<
    (string | { label: string; action?: () => void })[] | undefined
  >();
  const [selectedDrivers, setSelectedDrivers] = useState<[] | null>(null);
  const [apptDateMissingWarning, setIsApptDateMissingWarning] =
    useState<boolean>(false);
  const [showUpdateHOSDialog, setShowUpdateHOSDialog] =
    useState<boolean>(false);

  const handleWarnings = (warnings) => {
    setWarnings(warnings);
  };

  const finalWarnings = useMemo(() => {
    return warnings?.map((warning) => {
      if (typeof warning === 'object') {
        return {
          ...warning,
          action: () => {
            setShowUpdateHOSDialog(true);
          },
        };
      }
      return warning;
    });
  }, [setShowUpdateHOSDialog, warnings]);

  const handleDriverSelection = (driversInfo) => {
    setSelectedDrivers(driversInfo);
  };

  const handleAssign = async (data) => {
    let isApptDateMissing = false;
    if (trip?.stops?.length) {
      for (let i = 0; i < trip?.stops?.length; i++) {
        const stop = trip?.stops?.[i];
        if (!stop?.appointmentEndDate || !stop?.appointmentStartDate) {
          isApptDateMissing = true;
          break;
        }
      }
    } else {
      isApptDateMissing = await loadService.validateLoadStopsLocations(
        trip as any
      );
    }
    if (isApptDateMissing) {
      setIsApptDateMissingWarning(true);
      return;
    }
    if (data.driverGroup?.id) {
      !trip.dispatcher &&
        data.driverGroup.dispatcherId &&
        (await RoutesController.assignDispatcherToLoad(
          data.driverGroup.dispatcherId,
          trip.id,
          driverAmountForAssign
        ));
      const res = await RoutesController.assignDriverToLoad(
        data.driverGroup?.id,
        trip,
        data.prevLoad?.id,
        data.loadStatus,
        driverAmountForAssign
      );
      if (!res) return;
      onAssign(data.driverGroup, res);
      setDriverAmountForAssign(null);
    }
    onClose();
  };

  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <AssignmentFormWrapper
        onAction={handleAssign}
        onClose={onClose}
        data={data}
        title={t('DriverAssignmentToLoadAssignDriver')}
        actionLabel={t('DriverAssignmentToLoadSave')}
      >
        <>
          <Box sx={{ marginBottom: '16px' }}>
            <WarningWindow warningList={finalWarnings} />
          </Box>
          <FormComponent
            trip={trip}
            onDriverSelect={handleDriverSelection}
            onWarningsReady={handleWarnings}
            showUpdateHOSDialog={showUpdateHOSDialog}
            setShowUpdateHOSDialog={setShowUpdateHOSDialog}
          />
          {selectedDrivers && (
            <PaymentAmountAssignComponent items={selectedDrivers} />
          )}
          <DeletePopup
            open={apptDateMissingWarning}
            title={t('MissingApptDatesTitle')}
            body={t('MissingApptDatesBody')}
            subtitle={t('MissingApptDatesSubtitle')}
            onClose={() => setIsApptDateMissingWarning(false)}
            cancelText={'Ok'}
            width={'370px'}
          />
        </>
      </AssignmentFormWrapper>
    );
  }
}
