import { OperationMode, OwnerOperatorPaymentRecipient, PaymentType } from '.';
import { PaymentUnitType } from '../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewModel';
import { QueryParams } from '../commonMixed';

export class LoadRateDTO extends QueryParams {
  id?: number;
  driverId?: number;
  description?: string;
  expenseCategoryKey?: string;
  expenseCategoryId?: string;
  rate?: number;
  payTypeId?: string;
  payType?: string;
  operationMode?: OperationMode;
  unit?: string;
  tractorId?: string;
  PaymentUnit!: PaymentUnitType[] | null;
  constructor(dto?: any, allPaymentTypesMap?: any) {
    super();
    this.id = dto.id;
    this.driverId = dto.driverId;
    this.description = dto.description;
    this.expenseCategoryKey = dto.expenseCategoryKey;
    this.expenseCategoryId = dto.expenseCategoryId;
    this.rate = allPaymentTypesMap?.[dto.payTypeId]?.deduction
      ? -Number(dto.rate)
      : dto.rate;
    this.payTypeId = dto.payTypeId;
    this.payType = dto.payType;
    this.operationMode = dto.operationMode;
    this.unit = dto.unit;
    this.PaymentUnit = dto.PaymentUnit;
    this.dispatcherId = dto?.dispatcherId;
    this.tractorId = dto?.tractorId;
  }
}

export class PaymentTermsRequest {
  loadRateDTO: PaymentType[];
  scheduledDeductionDTO: [];
  scheduledPayDTO: [];
  payToDetails: OwnerOperatorPaymentRecipient;
  constructor(
    dto: any,
    allPaymentTypesMap?: PaymentType[],
    payToDetails?: OwnerOperatorPaymentRecipient
  ) {
    this.loadRateDTO = dto?.loadRateDTO?.map?.(
      (item) => new LoadRateDTO(item, allPaymentTypesMap)
    );
    this.scheduledDeductionDTO = dto.scheduledDeductionDTO;
    this.scheduledPayDTO = dto.scheduledPayDTO;
    this.payToDetails = payToDetails!;
  }
}

export class LoadFinanceLineItemRequest {
  amount: number;
  description: string;
  expenseCategoryId: string;
  expenseCategoryKey: string;
  id: string;
  loadRateType: string;
  loadRateTypeId: string;
  manuallyAdded: boolean;
  quantity: string | number;
  rate: string | number;
  unit: string;
  constructor(dto: any, allPaymentTypesMap?: any) {
    delete dto.parcent;
    this.amount = allPaymentTypesMap?.[dto.loadRateTypeId]?.deduction
      ? -Math.abs(dto.amount)
      : +dto.amount;
    this.description = dto.description;
    this.expenseCategoryId = dto.expenseCategoryId;
    this.expenseCategoryKey = dto.expenseCategoryKey;
    this.id = dto.id || '';
    this.loadRateType = dto.loadRateType;
    this.loadRateTypeId = dto.loadRateTypeId;
    this.manuallyAdded = true;
    this.quantity = dto.quantity;
    this.rate = dto.rate;
    this.unit = dto.unit;
  }
}
