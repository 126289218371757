import { useFormContext } from 'react-hook-form';
import DatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';
import { MultipleAutocompleteForm } from '../../../../common/Ui/MultipleAutocomplete';
import Switch from '../../../../common/Ui/Switcher';
import TractorController from '../../../../subPages/tractors/Controller';
import { getGridCompanyTimezoneSettings } from '../../../../utils/Timezone.utils';
import { t } from 'i18next';
import { ExpensesConstant } from '../../../../locales/en/finance/expenses/index';
import { RelativeDateRangeUtils } from '../../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
const columns = [
  { name: 'tractorName', label: 'Tractor #', show: true, disabled: true },
  { name: 'status', label: 'Status', show: true, disabled: false },
  { name: 'driver', label: 'Driver', show: true, disabled: false },
  { name: 'activeLoad', label: 'Active Load', show: true, disabled: false },
  { name: 'terminal', label: 'Terminal', show: true, disabled: false },
  { name: 'vin', label: 'Vin #', show: true, disabled: false },
  {
    name: 'licensePlateNumber',
    label: 'Reg. Plate #',
    show: true,
    disabled: false,
  },
  {
    name: 'licenseValidTill',
    label: ExpensesConstant.regExpiry,
    show: true,
    disabled: false,
  },
  { name: 'lastLocation', label: 'Last Location', show: true, disabled: false },
  {
    name: 'lastUpdated',
    label: 'Last Updated At',
    show: true,
    disabled: false,
  },
  {
    name: 'locationSource',
    label: 'Location Source',
    show: true,
    disabled: false,
  },
  {
    name: 'ownershipType',
    label: 'Ownership Type',
    show: true,
    disabled: false,
  },
  { name: 'ownedCompany', label: 'Owned By', show: true, disabled: false },
  { name: 'warning', label: 'Warning', show: true, disabled: false },
  { name: 'critical', label: 'Critical', show: true, disabled: false },
];

export const getStatuses = () => {
  return Promise.resolve(statusOptions);
};

export const statusOptions = [
  { key: 'AVAILABLE', value: 'Available' },
  { key: 'ASSIGNED', value: 'Assigned' },
  { key: 'UNAVAILABLE', value: 'Unavailable' },
  { key: 'INACTIVE', value: 'Inactive' },
];

export const getTransitState = () => {
  return Promise.resolve(transitStateOptions);
};

export const transitStateOptions = [
  { key: 'IN_TRANSIT', value: 'In-Transit' },
  { key: 'IDLE', value: 'Idle' },
];

export const getTractorFiltersFormConfig = () => [
  {
    key: 1,
    name: 'nameFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getTractorByNameSearch(
        searchText,
        pageNumber
      ),
    label: 'Name',
    default: true,
    type: 'MultipleAutocomplete',
  },
  {
    key: 2,
    name: 'statusFilter',
    fieldName: 'value',
    options: statusOptions,
    label: 'Status',
    default: true,
    type: 'ButtonGroup',
    multiple: true,
  },
  {
    key: 3,
    name: 'inTransitFilter',
    fieldName: 'value',
    fieldValue: 'key',
    options: transitStateOptions,
    label: 'Tractor In Transit',
    default: true,
    type: 'ButtonGroup',
    multiple: true,
    immutableModel: true,
  },
  {
    key: 4,
    name: 'safetyIssueFilter',
    fieldName: 'value',
    getData: () => TractorController.instance().getSafetyIssueTypeOptions(),
    label: 'Safety Issues',
    default: true,
    type: 'MultipleAutocomplete',
  },
  {
    key: 5,
    name: 'driverGroupFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getDriverGroupsAsAnOptionType(
        searchText,
        pageNumber
      ),
    label: 'Driver name',
    default: false,
    type: 'MultipleAutocomplete',
  },
  {
    key: 6,
    name: 'vinFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getVinList(searchText, pageNumber),
    label: 'Vin #',
    default: false,
    type: 'MultipleAutocomplete',
  },
  {
    key: 7,
    name: 'licensePlateFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getLicensePlateList(searchText, pageNumber),
    label: 'Reg Plate',
    default: false,
    type: 'MultipleAutocomplete',
  },
  {
    key: 8,
    name: 'regExpiry',
    fieldName: 'name',
    label: ExpensesConstant.regExpiry,
    default: false,
    ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
  },
];

export const tractorAutoCompleteFiltersFormConfig = [
  {
    key: 1,
    name: 'nameFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getTractorByNameSearch(
        searchText,
        pageNumber
      ),
    label: 'Name',
    default: true,
  },
  {
    key: 2,
    name: 'statusFilter',
    fieldName: 'value',
    getData: getStatuses,
    label: 'Status',
    default: true,
  },
  {
    key: 3,
    name: 'inTransitFilter',
    fieldName: 'value',
    getData: getTransitState,
    label: 'Tractor In Transit',
    default: true,
  },
  {
    key: 4,
    name: 'safetyIssueFilter',
    fieldName: 'value',
    getData: () => TractorController.instance().getSafetyIssueTypeOptions(),
    label: 'Safety Issues',
    default: true,
  },
  {
    key: 5,
    name: 'driverGroupFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getDriverGroupsAsAnOptionType(
        searchText,
        pageNumber
      ),
    label: 'Driver name',
    default: false,
  },
  {
    key: 6,
    name: 'vinFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getVinList(searchText, pageNumber),
    label: 'Vin #',
    default: false,
  },
  {
    key: 7,
    name: 'licensePlateFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getLicensePlateList(searchText, pageNumber),
    label: 'Reg Plate',
    default: false,
  },
];

export const tractorDatesFiltersFormConfig = [
  {
    key: 1,
    default: false,
    name: 'regExpiryFrom',
    label: 'Reg. Expiry From',
  },
  {
    key: 2,
    default: false,
    name: 'regExpiryTo',
    label: 'Reg. Expiry To',
  },
];

export function TractorColumnsForm() {
  const { control } = useFormContext();
  return (
    <>
      {columns.map((column, i) => (
        <Switch
          key={i}
          label={column.label}
          name={`columnFilters[${column.name}]`}
          control={control}
          disabled={column.disabled}
        />
      ))}
    </>
  );
}

export function TractorMetaDataForm() {
  const { control } = useFormContext();
  return (
    <>
      {tractorAutoCompleteFiltersFormConfig.map((config) => (
        <MultipleAutocompleteForm
          key={config.key}
          control={control}
          name={`metaData[${config.name}]`}
          fieldName={config.fieldName}
          getOptions={config.getData}
          label={config.label}
        />
      ))}
      {tractorDatesFiltersFormConfig.map((conf) => (
        <DatePicker
          iso
          key={conf.key}
          control={control}
          name={`metaData[${conf.name}]`}
          label={conf.label}
        />
      ))}
    </>
  );
}
