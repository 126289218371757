import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { ELoadStatus } from '../../../../../common/LoadTabPanel/constants/constants';
import { ITimeoffDialogData } from '../../../../../common/TimeoffDialog/types';
import { useDispatchPermission } from '../../../../../common/hooks/useDispatchPermission';
import { TeamDriver } from '../../../../../models';
import { UpdateGroupAssgnmentRequest } from '../../../../../models/DTOs/Dispatch/Requests';
import {
  EDispatchDriverTimelineTripType,
  IDispatchDriverTimelineTrip,
} from '../../../../../models/DTOs/Dispatch/Timeline';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { SecondaryDetailsOpenProps } from '../../../constants/types';
import DispatchController from '../../../utils/Controller';
import { moveArrayItemToNewIndex } from '../../../utils/dispatch';
import { GanttCardGap } from '../CardGap';
import { GanttCardTrip } from '../CardTrip';
import { GanttCardTripTimeOff } from '../CardTripTimeOff';
import { FloatingReSequenceStopIconProps } from '../Resequence';
import withResequence from '../Resequence/withResequence';
import { EmptyList } from './EmptyList';

export interface GanttCardListProps extends Partial<SecondaryDetailsOpenProps> {
  tripList?: IDispatchDriverTimelineTrip[];
  currentTripList?: IDispatchDriverTimelineTrip[];
  isCurrent?: boolean;
  timeoffDialogData?: ITimeoffDialogData | null;
  emptyDescription: string;
  driverTeam: TeamDriver | null;
  isReOrderActive?: boolean;
  waitForTripPlanNotification?: boolean;

  onAfterReorder?(): Promise<void>;

  onCancelled?(): Promise<void>;

  onAfterUpdateTripAction?(action: ELoadStatus): void;

  onAfterUpdateTimeOffAction?(): void;
}

export const GanttCardList = ({
  timeoffDialogData,
  tripList = [],
  currentTripList = [],
  isCurrent = false,
  handleSecondaryDetailsOpen,
  onAfterReorder,
  onCancelled,
  emptyDescription,
  driverTeam,
  isReOrderActive = false,
  waitForTripPlanNotification = false,
  onAfterUpdateTripAction,
  onAfterUpdateTimeOffAction,
}: GanttCardListProps): JSX.Element => {
  const { hasDispatchEditPermission } = useDispatchPermission();
  const [finalTripList, setFinalTripList] = useState<
    IDispatchDriverTimelineTrip[]
  >([]);

  useEffect(() => {
    const tmpFinalTripList = isReOrderActive
      ? tripList.filter(
          (item) => item.type !== EDispatchDriverTimelineTripType.GAP
        )
      : tripList;
    setFinalTripList(tmpFinalTripList);
  }, [JSON.stringify(tripList), isReOrderActive]);

  const ResequenceGanttCardTrip = useMemo(
    () => withResequence(GanttCardTrip),
    []
  );
  const ResequenceGanttCardGap = useMemo(
    () => withResequence(GanttCardGap),
    []
  );
  const ResequenceGanttCardTripTimeOff = useMemo(
    () => withResequence(GanttCardTripTimeOff),
    []
  );

  const getReSequenceStopProps = ({
    tripList = [],
    index,
  }: {
    tripList?: IDispatchDriverTimelineTrip[];
    index: number;
  }): FloatingReSequenceStopIconProps => {
    const validList = tripList?.filter?.(
      (item) => item?.type !== EDispatchDriverTimelineTripType.GAP
    );
    const idxInValidList = validList?.findIndex?.(
      (item) => item?.id === tripList?.[index]?.id
    );

    const updateGroupAssgnment = async (isUp: boolean): Promise<void> => {
      const newArr: IDispatchDriverTimelineTrip[] = isUp
        ? moveArrayItemToNewIndex(validList, idxInValidList, idxInValidList - 1)
        : moveArrayItemToNewIndex(
            validList,
            idxInValidList,
            idxInValidList + 1
          );
      setFinalTripList(newArr);
    };

    return {
      resequenceActions: {
        resequenceUp: idxInValidList > 0 ? true : false,
        resequenceDown: idxInValidList < validList.length - 1 ? true : false,
      },
      onClickResequenceUpHandler: () => {
        updateGroupAssgnment(true);
      },
      onClickResequenceDownHandler: () => {
        updateGroupAssgnment(false);
      },
    };
  };

  if (tripList.length === 0) {
    return <EmptyList description={emptyDescription} />;
  }

  const noReSequenceStopProps = {
    resequenceActions: {
      resequenceUp: false,
      resequenceDown: false,
    },
  };

  const onUpdateGroupAssgnmentHandler = async (): Promise<void> => {
    const currentTripLoadIdsAndStatuses =
      currentTripList?.map((item) => ({
        loadId: item.id,
        loadStatus: item.status,
      })) ?? [];
    const requestData = new UpdateGroupAssgnmentRequest({
      groupId: timeoffDialogData?.driverGroupId,
      loadIdsAndStatuses: [
        ...currentTripLoadIdsAndStatuses,
        ...finalTripList
          .map((item: IDispatchDriverTimelineTrip) => ({
            loadId: item.id,
            loadStatus: item.status,
          }))
          .filter((item) => !!item.loadId),
      ],
    });
    const response = await DispatchController.instance().updateGroupAssgnment(
      requestData
    );

    if (response) {
      onAfterReorder?.();
    }
  };

  return (
    <>
      <Box
        sx={{
          ...(isReOrderActive && {
            background: 'rgba(33, 150, 243, 0.08)',
            border: '1.5px dashed #0B79D0',
            borderRadius: '16px',
            p: 2,
            pl: 0,
          }),
        }}
      >
        {isReOrderActive && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            pl={2}
            pb={2}
            width={'100%'}
          >
            <ButtonImproved
              styleProps={{ width: '100%' }}
              color="primary"
              label={t('GanttCardListCancel')}
              onClick={onCancelled}
              variant="outlined"
              disabled={waitForTripPlanNotification}
            />
            <ButtonImproved
              styleProps={{ width: '100%' }}
              color="primary"
              label={t('GanttCardListSave')}
              onClick={onUpdateGroupAssgnmentHandler}
              variant="contained"
              disabled={waitForTripPlanNotification}
            />
          </Stack>
        )}

        {finalTripList?.map(
          (trip: IDispatchDriverTimelineTrip, index: number) => {
            switch (trip.type) {
              case EDispatchDriverTimelineTripType.TRIP:
                return (
                  <ResequenceGanttCardTrip
                    isReOrderActive={isReOrderActive}
                    waitForTripPlanNotification={waitForTripPlanNotification}
                    key={trip.id || index}
                    trip={trip}
                    handleSecondaryDetailsOpen={handleSecondaryDetailsOpen}
                    onAfterUpdateTripAction={onAfterUpdateTripAction}
                    {...(isReOrderActive && !waitForTripPlanNotification
                      ? {
                          reSequenceStopProps: hasDispatchEditPermission
                            ? getReSequenceStopProps({
                                tripList: finalTripList,
                                index,
                              })
                            : noReSequenceStopProps,
                        }
                      : {
                          reSequenceStopProps: noReSequenceStopProps,
                        })}
                  />
                );
              case EDispatchDriverTimelineTripType.GAP:
                //GAP have no re sequence feature
                return (
                  <ResequenceGanttCardGap
                    key={trip.id || index}
                    trip={trip}
                    isCurrent={isCurrent}
                    reSequenceStopProps={noReSequenceStopProps}
                    driverTeam={driverTeam}
                  />
                );
              case EDispatchDriverTimelineTripType.TIMEOFF:
                return (
                  <ResequenceGanttCardTripTimeOff
                    isReOrderActive={isReOrderActive}
                    waitForTripPlanNotification={waitForTripPlanNotification}
                    key={trip.id || index}
                    trip={trip}
                    timeoffDialogData={timeoffDialogData}
                    onAfterUpdateTimeOffAction={onAfterUpdateTimeOffAction}
                    {...(isReOrderActive && !waitForTripPlanNotification
                      ? {
                          reSequenceStopProps: hasDispatchEditPermission
                            ? getReSequenceStopProps({
                                tripList: finalTripList,
                                index,
                              })
                            : noReSequenceStopProps,
                        }
                      : {
                          reSequenceStopProps: noReSequenceStopProps,
                        })}
                  />
                );
            }
          }
        )}
      </Box>
    </>
  );
};
