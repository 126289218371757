import { Box, Grid, Paper, SxProps } from '@mui/material';
import React from 'react';
import { AccountCardDetail } from '../AccountCardDetail';
import { AccountCardHeader } from '../AccountCardHeader';

export const AccountCard = ({
  acountCardData,
  accountName,
  accountData,
  MenuOptions,
  handleMenuClick,
  handleMenuClose,
  handleMenuItemClick,
  anchorEl,
  open,
  isDeletePopupOpen,
  closeConfirmationPopup,
  deactivateAccount,
  accountTitleStyle,
  accountIconStyle,
  componentToRenderInsideModal,
  selectedCardForDeletion,
  integrationType,
  accountMappingButton,
  clickedParamToManage,
  resetFilters,
  resetRequestData,
  customAccountCardStyle = {},
}: Props) => {
  return (
    <Paper
      className="account"
      sx={{
        padding: '16px 34px 16px 18px',
        width: '48%',
        marginBottom: '32px',
        minHeight: '270px',
        ...customAccountCardStyle,
      }}
    >
      <AccountCardHeader
        acountCardData={acountCardData}
        accountName={accountName}
        accountData={accountData}
        MenuOptions={MenuOptions}
        handleMenuClick={handleMenuClick}
        handleMenuClose={handleMenuClose}
        handleMenuItemClick={handleMenuItemClick}
        anchorEl={anchorEl}
        open={open}
        isDeletePopupOpen={isDeletePopupOpen}
        closeConfirmationPopup={closeConfirmationPopup}
        deactivateAccount={deactivateAccount}
        selectedCardForDeletion={selectedCardForDeletion}
      />
      <Box
        className="bottom-panel"
        sx={{ marginTop: '16px', paddingTop: '17px' }}
      >
        <Grid container sx={{ flexDirection: 'column' }}>
          {acountCardData.actualData.map((val: any, index: number) => {
            return (
              <AccountCardDetail
                val={val}
                key={index}
                accountTitleStyle={accountTitleStyle}
                accountIconStyle={accountIconStyle}
                acountCardData={acountCardData}
                accountName={accountName}
                accountData={accountData}
                componentToRenderInsideModal={componentToRenderInsideModal}
                integrationType={integrationType}
                clickedParamToManage={clickedParamToManage}
                accountMappingButton={accountMappingButton}
                resetFilters={resetFilters}
                resetRequestData={resetRequestData}
              />
            );
          })}
        </Grid>
      </Box>
    </Paper>
  );
};

export interface Props {
  acountCardData: any;
  accountName: any;
  accountData: any;
  MenuOptions: any;
  handleMenuClick?: any;
  handleMenuClose?: any;
  handleMenuItemClick?: any;
  anchorEl?: any;
  open?: any;
  isDeletePopupOpen?: any;
  closeConfirmationPopup?: any;
  deactivateAccount?: any;
  accountTitleStyle?: any;
  accountIconStyle?: any;
  componentToRenderInsideModal?: any;
  selectedCardForDeletion?: any;
  integrationType: string;
  accountMappingButton: any;
  clickedParamToManage?: (arg1: string, arg2: any) => void;
  resetFilters?: any;
  resetRequestData?: any;
  customAccountCardStyle?: SxProps;
}
