import { Box, Grid, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormAddress } from '../../../../../../common/Ui/AddressField';
import RatingField from '../../../../../../common/Ui/Rating/RatingField';
import { SingleAutocompleteForm } from '../../../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../../../common/Ui/TextField/TextField';
import {
  carrierFieldsConfig,
  defaultCarrierWorkModeOption,
} from '../../../../constants/constants';
import { getStaticListOptions } from '../../../../utils/filters.utils';
import { getStyledAddNewCarrierForm } from './styles';

const defaultTextFieldProps = {
  sizes: {
    xs: 6,
  },
  style: { marginBottom: '16px' },
};

export const GenerateForm = ({ canEdit }: { canEdit: boolean }) => {
  const styles = getStyledAddNewCarrierForm();
  const { control } = useFormContext();
  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Typography
        variant="h7"
        sx={{
          color: 'primary.main',
          mb: 2,
          display: 'flex',
        }}
      >
        General
      </Typography>
      <Grid spacing={1} container>
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={carrierFieldsConfig.name.label}
          name={carrierFieldsConfig.name.fieldName}
          required
          disabled={!canEdit}
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={carrierFieldsConfig.mc.label}
          name={carrierFieldsConfig.mc.fieldName}
          onlyNumbers={true}
          disabled={!canEdit}
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={carrierFieldsConfig.dot.label}
          name={carrierFieldsConfig.dot.fieldName}
          disabled={!canEdit}
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={carrierFieldsConfig.scac.label}
          name={carrierFieldsConfig.scac.fieldName}
          disabled={!canEdit}
        />
        <SingleAutocompleteForm
          control={control}
          label={carrierFieldsConfig.mode.label}
          name={carrierFieldsConfig.mode.fieldName}
          fieldName="value"
          // fieldValue="key" //Important: must comment this property, I do not know why
          defaultValue={defaultCarrierWorkModeOption}
          getOptions={async (searchString: string, pageNumber: number) => {
            return getStaticListOptions(searchString, pageNumber, 'workMode');
          }}
          customStyles={{
            marginBottom: '0',
          }}
          sizes={{
            xs: 6,
          }}
          disabled={!canEdit}
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={carrierFieldsConfig.truckCount.label}
          name={carrierFieldsConfig.truckCount.fieldName}
          onlyNumbers={true}
          disabled={!canEdit}
        />
        <FormAddress
          isManual={true}
          control={control}
          name={carrierFieldsConfig.address.fieldName}
          sizes={{ xs: 12 }}
          disabled={!canEdit}
          style={{
            marginBottom: 20,
            paddingTop: 0,
          }}
        />
      </Grid>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{
          mb: 3,
          display: 'flex',
        }}
      >
        <Typography
          variant="h7"
          sx={{
            color: 'primary.main',
          }}
        >
          Preference
        </Typography>
        <RatingField
          control={control}
          name="preferenceRate"
          precision={0.5}
          size="large"
          styleRating={styles.Rating}
          disabled={!canEdit}
        />
      </Stack>
      <Grid spacing={1} container>
        <TextField
          {...defaultTextFieldProps}
          sizes={{ xs: 12 }}
          control={control}
          label={carrierFieldsConfig.notes.label}
          name={carrierFieldsConfig.notes.fieldName}
          disabled={!canEdit}
        />
      </Grid>
    </Box>
  );
};
