import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useContext, useState } from 'react';
import { NOTE_TYPE } from '../../../../../models';
import { SteeringOutlined } from '../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { isHasPermission } from '../../../../../utils';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../../Permission';
import { NotesContext } from '../constants/constant';
import { getNoteTypeDisplay } from '../constants/helper';
import { ItemNotesTypes, NotesContextType } from '../constants/interface';
import { styles } from '../styles/styles';

export const NotesItem = ({ item = {}, index }: ItemNotesTypes) => {
  const { selectEdit }: NotesContextType = useContext(NotesContext);
  const [hower, setHower] = useState<boolean>(false);
  const hasEditNotePermission = isHasPermission([
    AXELE_PERMISSION_TYPE.LOAD_NOTES_EDIT,
  ]);
  const hasRemoveNotePermission = isHasPermission([
    AXELE_PERMISSION_TYPE.LOAD_NOTES_REMOVE,
  ]);
  return (
    <Stack sx={styles.listItem} direction="column" spacing={1}>
      <Stack
        direction="row"
        width={'100%'}
        justifyContent={'space-between'}
        spacing={2}
        alignItems={'flex-start'}
      >
        <Typography variant="body2" fontWeight={500}>
          {item.noteDesc}
        </Typography>
        {(item.editable ||
          item.deletable ||
          hasEditNotePermission ||
          hasRemoveNotePermission) &&
          ![NOTE_TYPE.ONHOLD, NOTE_TYPE.RELEASE].includes(item.notesType) && (
            <Permission contains={[AXELE_PERMISSION_TYPE.LOAD_EDIT]}>
              <Box
                onMouseEnter={() => setHower(true)}
                onMouseLeave={() => setHower(false)}
              >
                {!hower ? (
                  <StickyNote2OutlinedIcon
                    fontSize="small"
                    sx={{ color: 'primary.main' }}
                  />
                ) : (
                  <Stack direction="row" spacing={1}>
                    {(item.editable || hasEditNotePermission) && (
                      <IconButton
                        onClick={() => selectEdit(item, index, 'edit')}
                      >
                        <EditOutlinedIcon
                          fontSize="small"
                          sx={{ color: 'primary.main' }}
                        />
                      </IconButton>
                    )}
                    {(item.deletable || hasRemoveNotePermission) && (
                      <IconButton
                        onClick={() => selectEdit(item, index, 'delete')}
                      >
                        <DeleteOutlineOutlinedIcon
                          fontSize="small"
                          sx={{ color: 'primary.main' }}
                        />
                      </IconButton>
                    )}
                  </Stack>
                )}
              </Box>
            </Permission>
          )}
      </Stack>

      <Box sx={{ alignItems: 'flex-start' }}>
        {(item.notesType == 'INTERNAL' ||
          item.notesType == 'DRIVER' ||
          item.notesType == 'ONHOLD' ||
          item.notesType == 'RELEASE') &&
          item.attachments &&
          item.attachments.length > 0 && (
            <ButtonImproved
              size="medium"
              variant="text"
              disabled={false}
              label={
                item.attachments && item.attachments.length
                  ? item.attachments[0].fileName
                  : ''
              }
              startIcon={<InsertPhotoIcon />}
              styleProps={{ color: 'text.primary' }}
              // onClick={() => window.open(item.attachments[0], '_blank').focus()}
            />
          )}
      </Box>
      <Stack
        direction="row"
        width={'100%'}
        justifyContent={'space-between'}
        spacing={1}
      >
        <Stack direction="column">
          <Typography sx={styles.userName}>
            {`${item.updatedBy || ''} ${
              item.roleCode ? `(${item.roleCode})` : ''
            }`}
          </Typography>
          <Typography sx={styles.date}>
            {item.updateDate ? moment(item.updateDate).format('LLL') : ''}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {item.shareWithDriver ? (
            <SteeringOutlined height={30} width={30} color="#00112299" />
          ) : null}
          {item.notesType != 'INTERNAL' && (
            <ButtonImproved
              variant="outlined"
              label={getNoteTypeDisplay(item.notesType)}
              size="small"
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
