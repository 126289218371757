import { DateTimezoneUtils } from '../../../utils/Timezone.utils';
import { tripFieldsConfig } from '../constants/constants';
import {
  IAutocompleteBooleanOption,
  ITripFilters,
  ITripQueryParamsTable,
  TripQueryParams,
} from '../constants/types';
export interface QueryParamsTableProps {
  nextPageNumber: number;
  nextFilters: ITripFilters;
  pageSize: number;
  skipBrokeredTrip?: boolean;
}

export const defaultSort = `-${tripFieldsConfig.createDate.fieldName}`;

export const getSort = (filters: ITripFilters): string => {
  if (!filters.sort) return defaultSort;
  if (
    filters.sort.includes('tractorName') ||
    filters.sort.includes('trailerName')
  )
    return filters.sort.replace(/tractorName|trailerName/, 'assignment');
  return filters.sort;
};

export const getIsBrokerageTrip = (
  brokerageTrip: IAutocompleteBooleanOption[] | undefined
): boolean | null => {
  if (
    !brokerageTrip ||
    brokerageTrip?.length === 0 ||
    brokerageTrip?.length === 2
  ) {
    return null;
  }
  return brokerageTrip?.[0]?.value;
};

export const filterToQuery = (
  filters: Partial<ITripFilters>,
  skipBrokeredTrip: boolean
): Omit<TripQueryParams, 'pageNumber' | 'pageSize'> => {
  return {
    sort: getSort(filters),
    tripStatusList: filters.statuses?.map(({ value }) => value),
    tagIds: filters.tagIds?.map(({ id }) => id),
    driverIdsFilter: filters.driverIdsFilter?.map(({ id }) => id),
    destinationCityStateList: filters.destinationCityStateList?.map(
      ({ value }) => value
    ),
    originCityStateList: filters.originCityStateList?.map(({ value }) => value),
    tags: filters.tags?.map(({ id }) => id),
    dispatcherIdsFilter: filters.dispatcherIdsFilter?.map(({ id }) => id),
    originStateList: filters.originStateList?.map(({ value }) => value),
    destinationStateList: filters.destinationStateList?.map(
      ({ value }) => value
    ),

    chassisNumbers: filters.chassisNumbers?.map(({ value }) => value),
    containerNumbers: filters.containerNumbers?.map(({ value }) => value),
    customerIdsFilter: filters.customerIdsFilter?.map(({ key }) => key),
    referenceIds: filters.referenceIds?.map(({ referenceId }) => referenceId),

    loadIdsFilter: filters.loadIdsFilter?.reduce<string[]>(
      (result, { label, value }) => result.concat(value),
      []
    ),
    manifestIdsFilter: filters.manifestIdsFilter?.reduce<string[]>(
      (result, { loadIds }) => result.concat(loadIds),
      []
    ),
    loadType: filters.loadType?.reduce<string[]>(
      (result, { key }) => result.concat(label),
      []
    ),
    tripIdsFilter: filters.tripIdsFilter?.reduce<string[]>(
      (result, { label, value }) => result.concat(value),
      []
    ),
    tractorIdsFilter: filters.tractorIdsFilter?.map(({ id }) => id),
    trailerIdsFilter: filters.trailerIdsFilter?.map(({ id }) => id),
    terminalIds: filters.terminalIds,

    settlementStatus: filters.settlementStatuses?.map(({ value }) => value),
    startFrom: DateTimezoneUtils.toStartDateISOString(
      filters.tripStartDateRanges?.dateRange?.[0]
    ),
    startTo: DateTimezoneUtils.toEndDateISOString(
      filters.tripStartDateRanges?.dateRange?.[1]
    ),
    endFrom: DateTimezoneUtils.toStartDateISOString(
      filters.tripEndDateRanges?.dateRange?.[0]
    ),
    endTo: DateTimezoneUtils.toEndDateISOString(
      filters.tripEndDateRanges?.dateRange?.[1]
    ),

    isRelay: filters.relay?.value,
    hasViolation: filters.delayViolations?.value,
    calculateDetention: filters.detention?.value,
    needToAssign: filters.needDriversNow?.value,
    operationMode: filters.driverOperationMode?.value,

    ...(skipBrokeredTrip
      ? {
          isBrokerageTrip: false,
        }
      : {
          carrierIdsFilter: filters.carrierIdsFilter?.map(({ id }) => id),
          isBrokerageTrip: getIsBrokerageTrip(filters?.brokerageTripFilter),
        }),
    onHoldState: filters?.onHoldState ? filters.onHoldState.value : null,
    isEmptyTrip: filters?.isEmptyTrip?.value
      ? filters?.isEmptyTrip?.value
      : null,
  };
};

export const getQueryParamsTable = ({
  nextPageNumber,
  nextFilters,
  pageSize,
  skipBrokeredTrip = false,
}: QueryParamsTableProps): ITripQueryParamsTable => {
  return {
    carrierIdsFilter: [],
    ...filterToQuery(nextFilters, skipBrokeredTrip),
    pageSize: pageSize,
    pageNumber: nextPageNumber,
  };
};
