// keep columns that always should be sent as valid to backend
// Object's keys should match with fields defining for tables
import {
  IMPORT_CUSTOMER_NAME,
  IMPORT_DRIVER_NAME,
  IMPORT_EXPENSE_NAME,
  IMPORT_CONTRACT_RATE_LINE_ITEM_NAME,
  IMPORT_LOAD_NAME,
  IMPORT_LOCATION_NAME,
  IMPORT_TRACTOR_NAME,
  IMPORT_TRAILER_NAME,
  IMPORT_VENDOR_NAME,
} from '../../../../../contexts/ImportContext';

export const keepAlwaysValid: { [key: string]: { [key: string]: boolean } } = {
  [IMPORT_CUSTOMER_NAME]: { customerName: true },
  [IMPORT_DRIVER_NAME]: { email: true },
  [IMPORT_TRAILER_NAME]: { number: true, vin: true, registrationPlate: true },
  [IMPORT_TRACTOR_NAME]: { number: true, vin: true, registrationPlate: true },
  [IMPORT_VENDOR_NAME]: { vendorName: true },
  [IMPORT_LOCATION_NAME]: { locationName: true },
  [IMPORT_EXPENSE_NAME]: {},
  [IMPORT_LOAD_NAME]: { referenceNumber: true },
  [IMPORT_CONTRACT_RATE_LINE_ITEM_NAME]: {},
};

export const getNextModalTitle = (currentEntityName: string) => {
  return `The ${currentEntityName}  containing the error value will not be imported. While  ${currentEntityName}  with warning value will still be imported.
    Please note that once you go to the next step you can not come back to this step. Would you like to correct it? `;
};

export const addressTypeFieldNames = [
  'address',
  'pickupAddress',
  'dropOffAddress',
  'originCity',
  'destinationCity',
];
