import React, { useEffect, useMemo, useState } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import {
  Expense,
  ExpenseDetails,
  SetSecondaryPanelDataType,
} from '../../../models/DTOs';
import {
  createExpense,
  createPaymentsBulk,
  deleteExpensesBulk,
  deleteFile,
  deletePaymentsBulk,
  getExpenseDetailsById,
  getListOfPaymentsByCompatible,
  saveFile,
  updateExpense,
  updatePaymentsBulk,
} from '../utils/utils';
import ExpensesDetailsForm from './form/ExpensesDetailsForm';

import { getExpenseDetailsFormValidationSchema } from '../constants';

import { observer } from 'mobx-react';
import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import StorageManager from '../../../StorageManager/StorageManager';
import LongMenu from '../../../common/LongMenu';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../common/Permission';
import WarningPopover from '../../../common/Ui/WarningPopover';
import { useExpensesSettings } from '../../../contexts/ExpensesContext';
import { useStaticData } from '../../../contexts/StaticDataContext';
import {
  RootStoreInstence,
  useRootStore,
} from '../../../store/root-store/rootStateContext';
import { WarningPopoverConfigType } from '../../../types';
import { DeleteButtonIcon } from '../../../ui-kit/components/Assets';
import { getPrimaryTerminalOrFirstOne } from '../../../utils/terminal';
import {
  handleCheckForCreate,
  handleCheckForUpdate,
  handleOpenDeleteConfirmation,
} from '../utils/warningPopover';

type propsType = {
  data: Expense | null;
  isCreatePanelOpen: boolean;
  onClose: () => void;
  onUpdated: (data?: Expense) => void;
  onDeleted: (data: string[]) => void;
  onCreated: (data: Expense) => void;
  setSecondaryPanelData: React.Dispatch<
    React.SetStateAction<SetSecondaryPanelDataType | null>
  >;
  isGlobal?: boolean;
  handleMaintenanceOnClick?: (
    expenseId: string,
    isGlobal?: boolean,
    expenseDetailsData?: ExpenseDetails
  ) => void;
};

function ExpenseDetailsPanel({
  data,
  isCreatePanelOpen,
  onClose,
  onUpdated,
  onDeleted,
  onCreated,
  setSecondaryPanelData,
  isGlobal = false,
  handleMaintenanceOnClick,
}: propsType): JSX.Element | null {
  const { staticData } = useStaticData();

  const { getIsTerminalEnabled, getAllTerminals } = useRootStore();

  const { isFuelExpensePanel } = useExpensesSettings();

  const [isFuelExpense, setIsFuelExpense] = useState<boolean>(true);
  const userStorageData = StorageManager.getUser();
  const [expenseDetailsData, setExpenseDetailsData] = useState<
    ExpenseDetails | undefined
  >(
    new ExpenseDetails({
      terminal: getPrimaryTerminalOrFirstOne(getAllTerminals),
      category: isFuelExpense ? 'FUEL' : null,
      companyExpense: true,
    })
  );
  const { t, ready } = useTranslation();
  const [warningPopoverConfig, setWarningPopoverConfig] =
    useState<WarningPopoverConfigType | null>(null);

  useEffect(() => {
    if (isFuelExpensePanel === undefined) return;
    if (isFuelExpensePanel !== isFuelExpense)
      setIsFuelExpense(!!isFuelExpensePanel);
    setExpenseDetailsData(
      new ExpenseDetails({
        terminal: getPrimaryTerminalOrFirstOne(getAllTerminals),
        category: isFuelExpensePanel ? 'FUEL' : null,
        companyExpense: true,
      })
    );
  }, [isFuelExpensePanel]);

  async function getExpenseDetails(id: string) {
    const details = await getExpenseDetailsById(
      id,
      getAllTerminals,
      staticData
    );

    if (details) {
      setIsFuelExpense(details.category === 'FUEL');

      setExpenseDetailsData(details);
    }
  }

  useEffect(() => {
    if (typeof data === 'object' && data?.id && !isCreatePanelOpen) {
      getExpenseDetails(data?.id);
    }
  }, [data]);

  async function handleDeleteNonCompatiblePayments(
    expenseData: ExpenseDetails
  ) {
    if (!!expenseData.payments?.length) {
      const { compatiblePayments, nonCompatiblePayments } =
        getListOfPaymentsByCompatible(expenseData);

      if (!!nonCompatiblePayments.length && !!expenseData.id) {
        const response = await deletePaymentsBulk(nonCompatiblePayments);
        if (!response) return;
      }

      if (!!expenseData.id) {
        handleUpdateExpense({ ...expenseData, payments: compatiblePayments });
      } else {
        handleCreateExpense({ ...expenseData, payments: compatiblePayments });
      }
    }
  }

  async function handleUpdateExpense(expenseData: ExpenseDetails) {
    console.log('expenseDataexpenseDataexpenseData', expenseData);
    if (
      expenseData &&
      expenseData?.maintenanceDetails &&
      expenseData?.maintenanceDetails?.historyMaintenanceItems?.length > 0
    ) {
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        message: 'The linked maintenance will also be updated',
      });
    }

    if (!!expenseData.payments?.length) {
      await updatePaymentsBulk(expenseData.payments);
    }

    if (
      (!!expenseDetailsData?.assignedDocuments?.length &&
        !expenseData?.assignedDocuments) ||
      (!!expenseDetailsData?.assignedDocuments?.length &&
        !!expenseData?.assignedDocuments?.length &&
        !expenseData?.assignedDocuments[0].documentId)
    ) {
      const id = expenseDetailsData?.assignedDocuments[0].documentId;
      if (id) await deleteFile([id]);
    }

    if (
      !!expenseData?.assignedDocuments?.length &&
      !expenseData?.assignedDocuments[0].documentId
    ) {
      await saveFile(expenseData, staticData);
    }

    const expense = await updateExpense(
      expenseData,
      getAllTerminals,
      staticData
    );
    setExpenseDetailsData(expense);
    onUpdated(new Expense(expense));
  }

  async function handleDeleteExpense() {
    async function deleteExpense() {
      if (!expenseDetailsData) return;
      const expenseIds = await deleteExpensesBulk([expenseDetailsData]);
      if (expenseIds?.length) {
        onDeleted(expenseIds);
        handleClose();
      }
    }

    if (!!expenseDetailsData?.payments?.length) {
      const paymentsIds = await deletePaymentsBulk(
        expenseDetailsData?.payments
      );
      if (paymentsIds) {
        await deleteExpense();
      }
    } else {
      await deleteExpense();
    }

    if (!!expenseDetailsData?.assignedDocuments?.length) {
      const id = expenseDetailsData?.assignedDocuments[0].documentId;
      if (id) await deleteFile([id]);
    }
  }

  async function handleCreateExpense(expenseData: ExpenseDetails) {
    const expense = await createExpense(
      expenseData,
      getAllTerminals,
      staticData
    );

    if (!!expense?.id) {
      if (!!expenseData.assignedDocuments?.length) {
        await saveFile(
          { ...expenseData, id: expense.id, seqNumber: expense.seqNumber },
          staticData
        );
      }
      if (!!expenseData.payments?.length) {
        await createPaymentsBulk(expenseData.payments, expense.id);
      }
      const expenseDetails = await getExpenseDetailsById(
        expense.id,
        getAllTerminals,
        staticData
      );
      // setExpenseDetailsData(expense);
      setExpenseDetailsData(expenseDetails);
      onCreated(new Expense(expenseDetails));
    }
  }

  const handleClose = () => {
    setExpenseDetailsData(undefined);
    onClose();
  };

  const expenseDetailsFormValidationSchema = useMemo(() => {
    return getExpenseDetailsFormValidationSchema(
      isFuelExpense,
      getIsTerminalEnabled
    );
  }, [isFuelExpense, getIsTerminalEnabled]);

  // TODO_Remove - kishor
  // const expenseDeletePermission: {
  //   [key: string]: AXELE_PERMISSION_TYPE[];
  // } = {
  //   DR: [AXELE_PERMISSION_TYPE.EXPENSE_REMOVE],
  //   SP: [AXELE_PERMISSION_TYPE.EXPENSE_REMOVE],
  //   DI: [AXELE_PERMISSION_TYPE.EXPENSE_REMOVE],
  //   NFD: [AXELE_PERMISSION_TYPE.EXPENSE_REMOVE],
  // };

  const actionsRenderer = () => {
    return (
      <>
        <Permission contains={[AXELE_PERMISSION_TYPE.EXPENSE_REMOVE]}>
          <LongMenu
            color={'primary.contrast'}
            options={[
              {
                name: 'Delete Expense',
                action: () =>
                  handleOpenDeleteConfirmation(
                    setWarningPopoverConfig,
                    expenseDetailsData
                  ),
                startIcon: DeleteButtonIcon({}),
              },
            ]}
          />
        </Permission>
      </>
    );
  };

  return expenseDetailsData || isCreatePanelOpen ? (
    <React.Fragment>
      <DetailsPanel
        data={expenseDetailsData}
        entity="Expense"
        panelTitle={
          !expenseDetailsData?.id
            ? isFuelExpense
              ? t('newFuelExpense')
              : t('newOtherExpense')
            : `Expense ID-${expenseDetailsData.seqNumber}`
        }
        onClose={handleClose}
        onUpdate={(expenseData: any, formState: FormState<any>) =>
          handleCheckForUpdate(
            expenseData,
            formState,
            setWarningPopoverConfig,
            handleUpdateExpense
          )
        }
        onCreate={(expenseData: any, formState: FormState<any>) =>
          handleCheckForCreate(
            expenseData,
            formState,
            setWarningPopoverConfig,
            handleCreateExpense
          )
        }
        contentRenderer={() => (
          <ExpensesDetailsForm
            expenseDetailsData={expenseDetailsData}
            onUpdated={onUpdated}
            setSecondaryPanelData={setSecondaryPanelData}
            isFuelExpense={isFuelExpense}
            handleMaintenanceOnClick={handleMaintenanceOnClick}
          />
        )}
        validationSchema={expenseDetailsFormValidationSchema}
        actionButtonLabel={
          expenseDetailsData?.id ? 'Save Changes' : t('addExpense')
        }
        isSaveIconVisible={!expenseDetailsData?.id}
        isGlobal={isGlobal}
        actionsRenderer={actionsRenderer}
      />
      {!!warningPopoverConfig && (
        <WarningPopover
          open={!!warningPopoverConfig}
          data={warningPopoverConfig.data}
          title={warningPopoverConfig.title}
          subTitle={warningPopoverConfig.subTitle}
          body={warningPopoverConfig.body}
          actions={{
            handleDeleteExpense,
            handleUpdateExpense,
            handleDeleteNonCompatiblePayments,
            handleCreateExpense,
          }}
          actionName={warningPopoverConfig.actionName}
          buttonText={warningPopoverConfig.buttonText}
          cancelText={warningPopoverConfig.cancelText}
          backgroundColor={warningPopoverConfig.backgroundColor}
          width={warningPopoverConfig.width}
          onClose={() => setWarningPopoverConfig(null)}
        />
      )}
    </React.Fragment>
  ) : null;
}

export default observer(ExpenseDetailsPanel);
