import {
  documentService,
  expenceService,
  payStatementService,
} from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { StaticDataType } from '../../../contexts/StaticDataContext';
import {
  AssignDocumentRequest,
  AssignDocumentResponseType,
  BulkSendSettlementEmailRequest,
  BulkSettlementCreateRequest,
  ClosePayStatementRequest,
  CombinedNames,
  CreateDocumentOnlyRequest,
  CreateInReviewPayStatementRequest,
  CreateInReviewPayStatementType,
  CreateNonTripPaymentRequest,
  DeleteDocumentRequest,
  DeleteInReviewPayStatementRequest,
  DeleteNonTripPaymentByIdRequest,
  DocumentSummaryResponse,
  DownloadBulkPayStatement,
  EntityListResDTO,
  EntityTypes,
  ExpenseCategory,
  FuelPaymentList,
  FuelTypesListRequest,
  FuelTypesType,
  GetBulkUpdateNonTripPaymentsRequest,
  GetDocumentTypeListRequest,
  GetExpenseCategoriesListRequest,
  GetPayStatementDetailsByIdRequest,
  GetPayStatementListRequest,
  GetPayStatementPdfRequest,
  GetPaymentsListByIdRequest,
  NonTripPaymentListType,
  OtherPaymentList,
  PaginatedPayStatementList,
  PayStatementData,
  PayStatementDatesType,
  PayStatementListRequestType,
  PayStatementStatusEnum,
  PayStatementType,
  PayStatementsEntityTypeEnum,
  PayStatementsTypeEnum,
  PaymentDetails,
  PaymentList,
  QueryParams,
  RemoveEditInReviewPaymentsRequest,
  ReopenPayStatementDTO,
  ReopenedPayStatement,
  SendSettlementEmailRequest,
  SettlementStatusChangeRequest,
  TractorEntityListRequest,
} from '../../../models';

import { FileType, LineItem, ViewMetaData } from '../../../types';

import StorageManager from '../../../StorageManager/StorageManager';
import { prepareFiltersFromList } from '../../../utils';
import { docType, downloadFile, previewFile } from '../../../utils/Doc';
import Controller from '../../tractors/Controller';

export const fetchPayStatementList = async (
  queryParams: PayStatementListRequestType,
  filters: ViewMetaData,
  entityType: PayStatementsEntityTypeEnum,
  globalTerminalsIds: string[],
  fetchingType = 'GET_DATA'
): Promise<PaginatedPayStatementList | undefined> => {
  const request = setPaymentsRequestFilters(
    filters,
    queryParams,
    globalTerminalsIds
  );
  const query = new GetPayStatementListRequest(request, entityType);
  const response = await payStatementService.getPayStatementList(
    query,
    entityType,
    fetchingType
  );

  if (!response || response instanceof ServiceError) {
    return Promise.resolve(undefined);
  } else {
    return response;
  }
};

export const getPayStatementDetailsById = async (
  id: number,
  payStatementId: number,
  payStatementType: PayStatementsTypeEnum,
  seqNumber?: number
): Promise<PayStatementData | undefined> => {
  const request = new GetPayStatementDetailsByIdRequest(
    id,
    payStatementId,
    seqNumber,
    payStatementType
  );

  const response = await payStatementService.getPayStatementDetailsById(
    request,
    payStatementType
  );

  return new PayStatementData(response);
};

export const getTotalAmount = async (
  queryParams: PayStatementListRequestType,
  filters: ViewMetaData,
  entityType: PayStatementsEntityTypeEnum,
  globalTerminalsIds: string[]
): Promise<PaginatedPayStatementList | undefined> => {
  const request = setPaymentsRequestFilters(
    filters,
    queryParams,
    globalTerminalsIds
  );
  const query = new GetPayStatementListRequest(request, entityType);
  const response = await payStatementService.getPayStatementList(
    query,
    entityType,
    'GET_DATA'
  );

  if (!response || response instanceof ServiceError) {
    return Promise.resolve(undefined);
  } else {
    return response;
  }
};

export const getTripPaymentsListById = async (
  data: PayStatementData,
  entityType: PayStatementsEntityTypeEnum,
  staticData: StaticDataType
): Promise<PaymentList | undefined> => {
  const request = new GetPaymentsListByIdRequest(data, entityType, true);
  const result = await payStatementService.getTripPaymentsListById(
    request,
    data.payStatementType
  );
  if (result instanceof ServiceError) {
    return;
  } else {
    return new PaymentList({ ...result }, staticData);
  }
};

export const getNonTripPaymentsListById = async (
  data: PayStatementData,
  staticData: StaticDataType,
  entityType: PayStatementsEntityTypeEnum
): Promise<NonTripPaymentListType | undefined> => {
  const request = new GetPaymentsListByIdRequest(data, entityType, true);
  const result = await payStatementService.getNonTripPaymentsListById(
    request,
    data.payStatementType
  );
  if (result instanceof ServiceError) {
    return;
  } else {
    return {
      fuelPayStatementList: new FuelPaymentList(result, staticData),
      otherPayStatementList: new OtherPaymentList(result, staticData),
    };
  }
};

export const getGrossYearToDateAmountById = async (
  data: PayStatementData,
  entityType: PayStatementsEntityTypeEnum
): Promise<number | undefined> => {
  const request = new GetPaymentsListByIdRequest(data, entityType, false);
  return payStatementService.getGrossYearToDateAmountById(
    request,
    data.payStatementType
  );
};

export const bulkUpdateNonTripPayments = async (
  paymentsList: PaymentDetails[],
  payStatementData: PayStatementData,
  entityType: PayStatementsEntityTypeEnum,
  staticData: StaticDataType
): Promise<NonTripPaymentListType | undefined> => {
  const request = new GetBulkUpdateNonTripPaymentsRequest(
    paymentsList,
    payStatementData,
    entityType
  );
  const result = await payStatementService.bulkUpdateNonTripPayments(
    request,
    payStatementData.payStatementType
  );
  if (result instanceof ServiceError) {
    return;
  } else {
    return {
      fuelPayStatementList: new FuelPaymentList(
        { content: result },
        staticData
      ),
      otherPayStatementList: new OtherPaymentList(
        { content: result },
        staticData
      ),
    };
  }
};

export const createNonTripPayment = async (
  payment: PaymentDetails,
  payStatement: PayStatementData,
  staticData: StaticDataType,
  entityType: PayStatementsEntityTypeEnum
): Promise<PaymentDetails | undefined> => {
  const request = new CreateNonTripPaymentRequest(
    payment,
    payStatement,
    entityType
  );
  const response = await payStatementService.createNonTripPayment(
    request,
    payStatement.payStatementType as PayStatementsTypeEnum
  );
  return new PaymentDetails(response, staticData);
};

export const createBulkSettlement = async (
  payments?: Array<PayStatementType>,
  startDate?: Date | string,
  endDate?: Date | string
): Promise<PaymentDetails | undefined> => {
  const request = new BulkSettlementCreateRequest(payments, startDate, endDate);
  const response = await payStatementService.createBulkSettlements(request);
  return response;
};

export const deleteNonTripPaymentById = async (
  payStatement: PaymentDetails,
  payStatementType: PayStatementsTypeEnum,
  documentIds: string[]
): Promise<number | undefined> => {
  const request = new DeleteNonTripPaymentByIdRequest([payStatement?.id]);
  const response = payStatementService.deleteNonTripPaymentById(
    request,
    payStatementType
  );

  if (!!documentIds.length) {
    deleteFile(documentIds);
  }
  return response;
};

//#region static data

type fuelResponseType = {
  content: FuelTypesType[];
  last: boolean;
};

export const getFuelTypesList = async (
  name = ''
): Promise<fuelResponseType> => {
  const request = new FuelTypesListRequest(name);
  const response = await expenceService.getFuelTypesList(request);
  return { content: response || [], last: true };
};

export const getTractorEntityList = async (data: {
  nameFilter?: string;
  payToEntityId?: number;
}): Promise<EntityListResDTO[]> => {
  try {
    const request = new TractorEntityListRequest(data);
    const response = await expenceService.getAssetListByEntity(request);
    return response!;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getStateOptions = async () => {
  const controller = Controller.instance();

  return controller.getStateOptions();
};

export const stateOptions: any = () => {
  return { content: Controller.instance().stateOptions, last: true };
};

export const getDocumentTypeList = (): Promise<LineItem[]> => {
  return documentService.getDocumentTypeList(new GetDocumentTypeListRequest());
};

export const getExpenseCategoriesList = async (name: string) => {
  const request = new GetExpenseCategoriesListRequest(name, 'FUEL');
  const response = await expenceService.getExpenseCategoriesList(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    return response;
  }
};

export const getExpenseCategoriesFullList = async (): Promise<
  ExpenseCategory[]
> => {
  const request = new GetExpenseCategoriesListRequest();
  const response = await expenceService.getExpenseCategoriesList(request);
  if (response instanceof ServiceError) {
    return [];
  } else {
    if (!!response?.content?.length) {
      return response.content;
    }
    return [];
  }
};

export const getPayStatementDates =
  async (): Promise<PayStatementDatesType> => {
    const request = new QueryParams();
    return (
      (await payStatementService.getPayStatementDates(request, true)) || {}
    );
  };

//#endregion static data

//#region assignDocument

export const uploadDocument = async (assignedDocument: File) => {
  const queryParams = new QueryParams();
  queryParams.addUserIdToParams();

  const requestBody = new CreateDocumentOnlyRequest({
    data: assignedDocument,
    ownerId: queryParams.userId,
  });

  const response = await documentService.createDocumentOnly(requestBody);
  return response instanceof ServiceError ? null : response;
};

export const assignDocument = async (
  file: DocumentSummaryResponse,
  payStatementData: PaymentDetails,
  staticData: StaticDataType
) => {
  const user = StorageManager.getUser() || {};

  const documentType = staticData.documentTypes.find(
    (item) => item.itemCode === 'RECEIPT'
  );

  const assignDocumentRequest = new AssignDocumentRequest({
    description: '',
    ownerName: user.firstname + ' ' + user.lastname,
    permission: 'PRIVATE',
    documentType: 'RECEIPT',
    documentTypeId: documentType?.id || '',
    documentId: file.id,
    fileName: file.fileName,
    fileSize: file.fileSize,
    uploadDate: file.uploadDate,
    ownerId: user.id,
    attachedEntities: [
      {
        properties: [
          {
            id: payStatementData?.id.toString() || '',
            title: payStatementData?.settlementSeqNumber
              ? payStatementData.settlementSeqNumber.toString()
              : 'Pending',
          },
        ],
        type: 'SETTLEMENT',
      },
    ],
  });
  const response = documentService.assignDocument(assignDocumentRequest);
  return response instanceof ServiceError ? null : response;
};

export const saveFile = async (
  payStatementData: PaymentDetails,
  file: FileList,
  staticData: StaticDataType,
  assignedDocuments?: FileType[]
): Promise<AssignDocumentResponseType | undefined> => {
  if (payStatementData && !!file.length) {
    const uploadedFile = await uploadDocument(file[0]);

    if (uploadedFile) {
      const document = await assignDocument(
        uploadedFile,
        payStatementData,
        staticData
      );
      if (!!assignedDocuments?.length) {
        const ids = assignedDocuments.map((item) => item.documentId);
        deleteFile(ids);
      }
      if (document) return document;
    }
    return;
  }
};

export const deleteFile = async (
  ids: string[]
): Promise<EntityTypes | undefined> => {
  if (ids) {
    const response = await documentService.deleteDocument(
      new DeleteDocumentRequest({
        documentIds: ids,
      })
    );

    return response;
  }
};

//#endregion

export const createInReviewPayStatement = async (
  data: CreateInReviewPayStatementType,
  payStatementData: PayStatementData
): Promise<PayStatementData | undefined> => {
  const request = new CreateInReviewPayStatementRequest(data, payStatementData);

  return payStatementService.createInReviewPayStatement(
    request,
    payStatementData.payStatementType
  );
};

export const closePayStatement = async (payStatementData: PayStatementData) => {
  const request = new ClosePayStatementRequest(payStatementData);
  return payStatementService.bulkCloseReopenPayStatement(
    [request],
    payStatementData.payStatementType
  );
};

export const updateSettlementStatus = async (
  payStatementData: Array<PayStatementData>
) => {
  const request = payStatementData?.map?.(
    (e) => new SettlementStatusChangeRequest(e)
  );
  return payStatementService.bulkCloseReopenPayStatement(
    request,
    payStatementData?.[0]?.payStatementType,
    true
  );
};

export const removeEditInReviewPayments = async (
  data: CreateInReviewPayStatementType,
  payStatementData: PayStatementData
) => {
  const request = new RemoveEditInReviewPaymentsRequest(data, payStatementData);
  return payStatementService.removeEditInReviewPayments(
    request,
    payStatementData.payStatementType
  );
};

export const deleteInReviewPayStatement = async (
  payStatementData: PayStatementData[] | PayStatementType[]
) => {
  const request = new DeleteInReviewPayStatementRequest(payStatementData);
  return payStatementService.deleteInReviewPayStatement(request);
};

export const downloadBulkPayStatement = async (
  payStatementData: PayStatementType[]
) => {
  const request = new DownloadBulkPayStatement(payStatementData);
  return payStatementService.downloadBulkPayStatements(request);
};

export const reopenInReviewPayStatement = async (
  payStatementData: PayStatementData,
  status: PayStatementStatusEnum
): Promise<PayStatementType | undefined> => {
  const request = new ReopenPayStatementDTO(payStatementData, status);

  const response = await payStatementService.bulkCloseReopenPayStatement(
    [request],
    payStatementData.payStatementType
  );
  if (response?.length) {
    return new ReopenedPayStatement(response?.[0]) as PayStatementType;
  }
  return Promise.resolve(undefined);
};

export const getPayStatementPdf = async (
  payStatementData: PayStatementData
): Promise<docType | undefined> => {
  const queryParams = new GetPayStatementPdfRequest(payStatementData);

  return payStatementService.getPayStatementPdf(
    queryParams,
    payStatementData.payStatementType
  );
};

export const previewPayStatementPdf = async (
  payStatementData: PayStatementData
) => {
  const result = await getPayStatementPdf(payStatementData);
  if (result) {
    previewFile(result);
  }
};
export const downloadPayStatementPdf = async (
  payStatementData: PayStatementData
) => {
  const result = await getPayStatementPdf(payStatementData);
  if (result) {
    downloadFile(result);
  }
};

export const sendEmail = async (
  data: any,
  payStatementData: PayStatementData
): Promise<string | undefined> => {
  const request = new SendSettlementEmailRequest(data, payStatementData);

  return payStatementService.sendEmail(request);
};

export const sendBulkEmail = async (
  data: any,
  payStatementData: PayStatementType[]
): Promise<string | undefined> => {
  const request = new BulkSendSettlementEmailRequest(data, payStatementData);
  return payStatementService.sendEmail(request);
};

export const setPaymentsRequestFilters = (
  metaData: ViewMetaData,
  query: PayStatementListRequestType,
  globalTerminalsIds: string[]
) => {
  const entityType = metaData['typeFilter']?.id;
  const tractorStatus = metaData['statusFilter']?.tractorStatus;
  const userStatus = metaData['statusFilter']?.userStatus;
  const dispatcherFilter = metaData['nameFilter']?.filter(
    (item: CombinedNames) =>
      item.entity !== 'Driver' && item.entity !== 'Tractor'
  );
  const dispatcherIds = prepareFiltersFromList(dispatcherFilter, 'entityId');

  const driverFilter = metaData['nameFilter']?.filter(
    (item: CombinedNames) => item.entity === 'Driver'
  );
  const driverIds = prepareFiltersFromList(driverFilter, 'entityId');

  const tractorFilter = metaData['nameFilter']?.filter(
    (item: CombinedNames) => item.entity === 'Tractor'
  );
  const tractorIds = prepareFiltersFromList(tractorFilter, 'entityId');

  const startDate = metaData['periodFilter']?.dateRange?.[0];
  const endDate = metaData['periodFilter']?.dateRange?.[1];

  const terminalIds = globalTerminalsIds;

  const seqNumberList = prepareFiltersFromList(
    metaData?.seqNumberFilter,
    'seqNumber'
  );

  return {
    entityType,
    driverIds,
    tractorIds,
    tractorStatus,
    seqNumberList,
    userStatus,
    dispatcherIds,
    startDate,
    endDate,
    terminalIds,
    ...query,
  };
};

export const isPayItemActionAllowed = (
  entityType: PayStatementsEntityTypeEnum
) => {
  if (entityType === PayStatementsEntityTypeEnum.inReviewStatement) return true;
  return false;
};
