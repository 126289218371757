import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { dateTimeFormate } from '../../utils/grid.utils';
import { ILoadsearchTableItem } from '../../loadTypes';

type PickupProps = ILoadsearchTableItem['Pickup'];

const Pickup: FC<PickupProps> = (Pickup) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '150%',
          letterSpacing: '0.15px',
        }}
        gutterBottom
      >
        {Pickup?.city}, {Pickup?.country}
      </Typography>
      <Typography
        variant="caption"
        gutterBottom
        sx={{ color: 'rgba(0, 17, 34, 0.75)', fontSize: '12px' }}
      >
        <b>{dateTimeFormate(Pickup?.date)}</b>{' '}
        <span style={{ color: '#000' }}>{Pickup?.time}</span>
      </Typography>
    </Box>
  );
};

export default React.memo(Pickup);
