/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Accordion from '../../../../../../common/Accordion';
import Switch from '../../../../../../common/Ui/Switcher';
import ThreeDotMenuIcon from '../../../../../../ui-kit/components/ThreeDotMenuIcon';
import { formatPhoneNumber } from '../../../../../../utils';
import { contactDeleteAction } from '../../../../../shared/constants';
import { CarrierContactSummary } from '../../../../constants/types';
import ContactForm from './ContactForm';

export default function ContactListForm({
  index,
  canEdit,
}: {
  index: number;
  canEdit: boolean;
}) {
  const [expanded, setExpanded] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { control, watch, setValue } = useFormContext();
  const [contactName, phoneData, email, isPrimary, contacts] = watch([
    `contacts.${index}.name`,
    `contacts.${index}.phoneData`,
    `contacts.${index}.email`,
    `contacts.${index}.isPrimary`,
    'contacts',
  ]);

  useEffect(() => {
    if (isPrimary) {
      const newContacts = contacts.map(
        (contact: CarrierContactSummary, idx: number) => {
          if (index !== idx) {
            return {
              ...contact,
              isPrimary: false,
            };
          }
          return { ...contact, isPrimary: true };
        }
      );
      setValue('contacts', newContacts);
    }
  }, [isPrimary]);

  const handleSwitchChange = (): void => {
    contacts.forEach((contact: CarrierContactSummary, idx: number) => {
      setValue(`contacts.${index}.isPrimary`, idx === index);
    });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    action: { icon: string; label: string },
    accountData: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (action?.label !== 'Delete') return;
    handleDeleteContact();
    setAnchorEl(null);
  };

  const handleDeleteContact = () => {
    const newContacts: CarrierContactSummary[] = [];
    contacts.forEach((contact: CarrierContactSummary, idx: number) => {
      if (index !== idx) {
        newContacts.push(contact);
      }
    });
    setValue('contacts', newContacts, { shouldDirty: true });
  };

  const titleRenderer = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: (theme) => {
            return expanded
              ? `${theme.palette.primary.contrast}`
              : `${theme.palette.grey['100']}`;
          },
        }}
      >
        <div>
          <Typography
            variant="h7"
            sx={{
              color: expanded ? 'primary.main' : 'text.primary',
            }}
          >
            {contactName}
          </Typography>
          {!expanded && (
            <div>
              <Typography
                variant="body3"
                sx={{ color: 'text.secondary' }}
              >{`${formatPhoneNumber(phoneData)} ${
                (!!phoneData?.phone && !!email && '|') || ''
              } ${email || ''}`}</Typography>
            </div>
          )}
        </div>
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '-16px',
          }}
        >
          <Switch
            label="Primary"
            name={`contacts.${index}.isPrimary`}
            disabled={isPrimary || !canEdit}
            control={control}
            onChangeCallback={handleSwitchChange}
            style={{ marginBottom: 0, marginRight: '-15px' }}
          />
          {!isPrimary && canEdit && (
            <ThreeDotMenuIcon
              menuOptions={[{ index, ...contactDeleteAction }]}
              open={Boolean(anchorEl)}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              anchorEl={anchorEl}
              handleMenuItemClick={handleMenuItemClick}
            />
          )}
        </div>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        border: (theme) => {
          return expanded ? `1px solid ${theme.palette.primary.main}` : '';
        },
        borderRadius: '8px',
        padding: '5px 5px',
        marginTop: '20px',
        backgroundColor: (theme) => {
          return expanded
            ? `${theme.palette.primary.contrast}`
            : `${theme.palette.grey['100']}`;
        },
        width: '100%',
      }}
    >
      <Accordion
        name="Registration"
        summaryRenderer={titleRenderer}
        updateParentState={setExpanded}
      >
        <ContactForm index={index} canEdit={canEdit} />
      </Accordion>
    </Box>
  );
}
