import { CarrierStaticDataDTO } from '../../../models/DTOs/Carrier/Requests';
import { ICarrierAutocompleteOption, ICarrierFilters } from './types';

export const carrierFieldsConfig: {
  [key: string]: {
    fieldName: string;
    label: string;
    fieldFilterName?: keyof ICarrierFilters;
    fieldFilterLabel?: string;
  };
} = {
  name: {
    fieldName: 'name',
    label: 'Carrier Name',
    fieldFilterName: 'nameFilter',
  },
  status: {
    fieldName: 'status',
    label: 'Status',
    fieldFilterName: 'statusFilter',
  },
  mode: {
    fieldName: 'mode',
    label: 'Mode',
    fieldFilterName: 'modeFilter',
  },
  mc: {
    fieldName: 'mc',
    label: 'MC#',
    fieldFilterName: 'mcFilter',
  },
  dot: {
    fieldName: 'dot',
    label: 'DOT',
    fieldFilterName: 'dotFilter',
  },
  scac: {
    fieldName: 'scac',
    label: 'SCAC',
    fieldFilterName: 'scacFilter',
  },
  state: {
    fieldName: 'state',
    label: 'State',
    fieldFilterName: 'stateFilter',
  },
  truckCount: {
    fieldName: 'truckCount',
    label: 'Number of Trucks',
  },
  address: {
    fieldName: 'address',
    label: 'Address',
  },
  contact: {
    fieldName: 'contact',
    label: 'Contact',
    fieldFilterName: 'contactNameFilter',
  },
  phone: {
    fieldName: 'phone',
    label: 'Phone',
  },
  email: {
    fieldName: 'email',
    label: 'Email',
  },
  notes: {
    fieldName: 'notes',
    label: 'Notes',
  },
};

export const carrierColumnsInfo = {
  [carrierFieldsConfig.name.fieldName]: true,
  [carrierFieldsConfig.status.fieldName]: true,
  [carrierFieldsConfig.mode.fieldName]: true,
  [carrierFieldsConfig.mc.fieldName]: true,
  [carrierFieldsConfig.dot.fieldName]: true,
  [carrierFieldsConfig.scac.fieldName]: true,
  [carrierFieldsConfig.state.fieldName]: true,
  [carrierFieldsConfig.truckCount.fieldName]: true,
  [carrierFieldsConfig.address.fieldName]: true,
  [carrierFieldsConfig.contact.fieldName]: true,
  [carrierFieldsConfig.phone.fieldName]: true,
  [carrierFieldsConfig.email.fieldName]: true,
};

export const defaultCarrierFilters: ICarrierFilters = {
  [carrierFieldsConfig.name.fieldFilterName!]: [],
  [carrierFieldsConfig.status.fieldFilterName!]: [],
  [carrierFieldsConfig.mode.fieldFilterName!]: [],
  [carrierFieldsConfig.contact.fieldFilterName!]: [],
  [carrierFieldsConfig.mc.fieldFilterName!]: [],
  [carrierFieldsConfig.dot.fieldFilterName!]: [],
  [carrierFieldsConfig.scac.fieldFilterName!]: [],
  [carrierFieldsConfig.state.fieldFilterName!]: [],
} as unknown as ICarrierFilters;

export const defaultCarrierStaticData: CarrierStaticDataDTO = {
  carrierStatus: [],
  workMode: [],
  insuranceType: [],
};

export const defaultCarrierWorkModeOption: ICarrierAutocompleteOption = {
  value: 'FTL',
  key: 'FTL',
};

export const debounceTime = 300;

export const CARRIER_DOCUMENT_TYPE = 'CARRIER';
export const CARRIER_INSURANCE_DOCUMENT_TYPE = 'CARRIER_INSURANCE';

export const CarrierNumberFormatProps = {
  decimalScale: 2,
  allowNegative: false,
  allowEmptyFormatting: false,
  isNumericString: true,
  thousandSeparator: true,
  prefix: '$',
};

export const emptyTableInfo = {
  title: 'time to get social',
  body: 'Looks like you need to add some carriers in to system. What is a broker without a carrier after all?',
};
