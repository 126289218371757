export const terminalFieldsConfig: {
  [key: string]: {
    fieldName: string;
    label: string;
  };
} = {
  companyName: {
    fieldName: 'companyName',
    label: 'Terminal',
  },
  dot: {
    fieldName: 'dot',
    label: 'DOT #',
  },
  mc: {
    fieldName: 'mc',
    label: 'MC #',
  },
  contact: {
    fieldName: 'contactPerson_contact',
    label: 'Contact',
  },
  phone: {
    fieldName: 'contactPerson_phone',
    label: 'Phone',
  },
  fax: {
    fieldName: 'contactPerson_fax',
    label: 'Fax',
  },
  email: {
    fieldName: 'contactPerson_email',
    label: 'Email',
  },
  address: {
    fieldName: 'contactPerson_address',
    label: 'Address',
  },
  city: {
    fieldName: 'addressData_city',
    label: 'City',
  },
  state: {
    fieldName: 'addressData_state',
    label: 'State',
  },
  zip: {
    fieldName: 'addressData_zip',
    label: 'Zip',
  },
};
