import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const GanttSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '16px',
        p: 1,
        height: 'calc(100% - 45px)',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Skeleton width={120} height={40} />
        <Skeleton width={120} height={40} />
        <Skeleton width={120} height={40} />
        <Skeleton variant="circular" width={20} height={20} />
        <Skeleton width={120} height={40} />
        <Skeleton width={120} height={40} />
      </Box>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Skeleton width={240} height={38} />
        <Skeleton animation="wave" height={38} width={'100%'} />
      </Box>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Skeleton width={240} height={38} />
        <Skeleton animation="wave" height={38} width={'100%'} />
      </Box>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Skeleton width={240} height={38} />
        <Skeleton animation="wave" height={38} width={'100%'} />
      </Box>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Skeleton width={240} height={38} />
        <Skeleton animation="wave" height={38} width={'100%'} />
      </Box>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Skeleton width={240} height={38} />
        <Skeleton animation="wave" height={38} width={'100%'} />
      </Box>
      <Box sx={{ display: 'flex', gap: '12px', height: '100%' }}>
        <Skeleton animation="wave" height={'100%'} width={'100%'} />
      </Box>
    </Box>
  );
};

export default GanttSkeleton;
