import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import { terminalFieldsConfig } from './constants';

const defaultViewConfig = {
  shared: true,
  parentId: null,
  active: true,
  default: true,
};

export const allTerminalsView = {
  id: 'allTerminals',
  name: 'All Terminals',
  icon: <DnsOutlinedIcon />,
  ...defaultViewConfig,
  entity: 'allTerminals',
  columnFilters: {
    [terminalFieldsConfig.companyName.fieldName]: true,
    [terminalFieldsConfig.dot.fieldName]: true,
    [terminalFieldsConfig.mc.fieldName]: true,
    [terminalFieldsConfig.contact.fieldName]: true,
    [terminalFieldsConfig.phone.fieldName]: true,
    [terminalFieldsConfig.fax.fieldName]: true,
    [terminalFieldsConfig.email.fieldName]: true,
    [terminalFieldsConfig.address.fieldName]: true,
    [terminalFieldsConfig.city.fieldName]: true,
    [terminalFieldsConfig.state.fieldName]: true,
    [terminalFieldsConfig.zip.fieldName]: true,
  },
  metaData: {},
};
