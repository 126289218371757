export interface UnassignActionsType {
  TRAILER: () => void;
  TRACTOR: () => void;
  DISPATCHER: () => void;
  DRIVER: () => void;
  CARRIER: () => void;
}

export enum entityTypes {
  DRIVER = 'DRIVER',
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  TERMINAL = 'TERMINAL',
  DISPATCHER = 'DISPATCHER',
  CARRIER = 'CARRIER',
}

export enum TripAssignmentEntityType {
  DRIVER = 'DRIVER',
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  TERMINAL = 'TERMINAL',
  DISPATCHER = 'DISPATCHER',
  CARRIER = 'CARRIER',
}

export enum unAssignConfig {
  TRAILER = 'Trailer Un-assignment',
  TRACTOR = 'Tractor Un-assignment',
  DISPATCHER = 'Dispatcher Un-assignment',
  DRIVER = 'Driver Un-assignment',
  CARRIER = 'Carrier Un-assignment',
}
