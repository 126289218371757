export const SummaryConstants = {
  financeSummary: 'Summary',
  financeSummaryIncome: 'Income',
  financeSummaryExpense: 'Expense',
  financeSummaryOperatingIncome: 'Operating Income',
  financeSummaryTableSettings: 'Table Settings',
  financeSummaryShowHideColumns: 'Show/Hide Columns',
  financeSummaryTableReset: 'Reset',
  driverSettlements: 'Driver Settlements',
  tractorSettlements: 'Tractor Settlements',
  dispatcherSettlements: 'Dispatcher Settlements',
  createExpenseView: 'Create a Expense View',
  subTotal: 'Sub-total',
  financeSummaryTableDescription: 'Description',
  financeSummaryTableDownload: 'Download Table Data',
  incomeCategory: 'Income Category',
  expenseCategory: 'Expense Category',
  paymentPreference: 'Payment Preference',
  paymentRecipient: 'Payment Recipient',
  paymentDate: 'Payment Date',
  driverName: 'Driver Name',
  settlementId: 'Settlement ID',
  tripId: 'Trip ID',
  paymentType: 'Payment Type',
  tractorName: 'Tractor Name',
  dispatcherName: 'Dispatcher Name',
  expId: 'Exp. ID',
  paidBy: 'Paid By',
  paidTo: 'Paid To',
  refNumber: 'Ref. #',
  invoice: 'Invoice #',
  paymentRecipientHelperText:
    'Payments for the tractor will be redirected to the selected driver.',
  paymentRecipientNoteForTractor:
    'Note: Payment terms established here will be automatically added to any trip a tractor is assigned to, at the time of assignment. Payments for any trip can be modified under the Finance tab of that trip.',
  paymentRecipientNoteForDriver:
    'Note: Payment terms established here will be automatically added to any trip a driver is assigned to, at the time of assignment. Payments for any trip can be modified under the Finance tab of that trip.',
  paymentRecipientNoteForUser:
    'Note: Payment terms established here will be automatically added to any trip a user is assigned to, at the time of assignment. Payments for any trip can be modified under the Finance tab of that trip.',
  updatePaymentRecipient: 'Update Payment Recipient',
};
