import { useFormContext } from 'react-hook-form';
import SettingBlock from '../Blocks/SettingBlock';
import {
  languageOptions,
  SETTINGS_NAME,
  timeZoneOptions,
  workStartWeekOptions,
} from '../../constants';
import { SettingBlockItem } from '../Blocks/SettingBlockItem';
import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../../common/Ui/TextField/TextField';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import Popover from '../../../../../ui-kit/components/Popover';
import IconButton from '@mui/material/IconButton';
import { copyToClipboard } from '../../../../../utils';
import { Alert, AlertColor, Stack } from '@mui/material';
import { GenerateAPIRequest } from '../../../../../models';
import moment from 'moment/moment';
import { preferenceSevice } from '../../../../../api';
import { useState } from 'react';
import { MoreVertOutlined } from '@mui/icons-material';
import StorageManager from '../../../../../StorageManager/StorageManager';
import PreferencesActions from '../../PreferencesActions';

function DefaultTimeZone() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Default Time Zone"
      subTitle="Select the time zone of your primary location."
    >
      <SingleAutocompleteForm
        control={control}
        name="timezone"
        fieldName="value"
        fieldValue="key"
        label="Default Time Zone"
        options={timeZoneOptions}
        required
        disableClear
      />
    </SettingBlockItem>
  );
}

function TractorAverageSpeed() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Default Average Speed of Tractor"
      subTitle="Configure average speed for the trip plan calculation"
    >
      <TextField
        control={control}
        name="defaultAverageSpeed"
        label="Default Average Speed of Tractor (mi/h)"
        onlyNumbers
        required
      />
    </SettingBlockItem>
  );
}

function WorkStartWeek() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Work Start Week"
      subTitle="Select the day of the week you start the work week."
    >
      <SingleAutocompleteForm
        control={control}
        name="workWeekStartDay"
        fieldName="value"
        fieldValue="key"
        label="Work Start Week"
        options={workStartWeekOptions}
        required
        disableClear
      />
    </SettingBlockItem>
  );
}

function DefaultLanguage() {
  const { control } = useFormContext();
  return (
    <SettingBlockItem
      title="Default Language"
      subTitle="Select the default language."
    >
      <SingleAutocompleteForm
        control={control}
        name="locale"
        fieldName="value"
        fieldValue="key"
        label="Default Language"
        options={languageOptions}
        required
        disableClear
      />
    </SettingBlockItem>
  );
}

function GeofenceMap() {
  const { control } = useFormContext();
  return (
    <>
      <SettingBlockItem
        title="Set default radius for geofence"
        subTitle="Draw a virtual boundary around the stop location to notify drivers when they reach within the boundary. You can setup a default radius for the boundary, this will be applied if no boundary is
      selected for a stop."
      >
        {/* <Switcher
          control={control}
          name="enableGeofencing"
          label=""
          style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
        /> */}
        <TextField
          // disabled={!enableGeofencingData}
          control={control}
          name="defaultRadius"
          label="Radius (miles)"
          style={{ justifyContent: { sx: 'flex-start', xl: 'flex-end' } }}
          onlyNumbers
        />
      </SettingBlockItem>
    </>
  );
}

function ApiKey() {
  const { control, getValues, setValue } = useFormContext();
  const [alert, setAlert] = useState({ status: '', alert: '', show: false });
  const [open, setOpen] = useState<HTMLAnchorElement | null>(null);
  const { roleCode } = StorageManager.getUser();
  const disableEdit = roleCode === 'AD' ? false : true;

  const getData = async () => {
    const data = await PreferencesActions.getPreferences();
    if (data) {
      setValue('apiKey', data.apiKey);
      setValue('apiKeyStatus', data.apiKeyStatus);
    }
  };

  const onGenerateAPIKey = async (e: any) => {
    const query = new GenerateAPIRequest();
    query.lastUpdated = moment().toISOString();
    if (e?.toggle) {
      query.toggle = true;
      query.Status = !getValues('apiKeyStatus');
    }
    if (e?.delete) query.delete = true;
    const response = await preferenceSevice.generateAPI(query);
    if (response) getData();
    if (e.generate && response) {
      setAlert({ show: true, alert: 'Api key generated', status: 'success' });
    } else if (e.generate) {
      setAlert({
        show: true,
        alert: 'Api key could not be generated',
        status: 'error',
      });
    }
  };

  return (
    <SettingBlockItem title="API Key" subTitle="Generate API Key">
      {alert.show && (
        <Alert
          sx={{
            position: 'fixed',
            bottom: '25px',
            right: '25px',
            color: 'white',
            backgroundColor:
              alert.status === 'success' ? 'success.main' : 'error.main',
            fontSize: '14px',
          }}
          variant="filled"
          severity={alert.status as AlertColor | undefined}
          onClose={() => setAlert({ show: false, alert: '', status: '' })}
        >
          {alert.alert}
        </Alert>
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          control={control}
          name="apiKey"
          label="API Key"
          disabled
          variant="standard"
        />
        <IconButton
          aria-label="copy"
          component="span"
          onClick={() => {
            copyToClipboard(getValues('apiKey') || ' ');
          }}
        >
          <IconsComponent iconName="CopyAllOutlined" source="MUIcons" />
        </IconButton>
        <Popover
          actions={
            [
              {
                label: 'Generate API',
                onClick: () => onGenerateAPIKey({ generate: true }),
                iconName: '',
                source: '',
              },
              getValues('apiKey')
                ? {
                    label: getValues('apiKeyStatus')
                      ? 'Deactivate'
                      : 'Activate',
                    onClick: () => onGenerateAPIKey({ toggle: true }),
                    iconName: '',
                    source: '',
                  }
                : null,
              getValues('apiKey')
                ? {
                    label: 'Delete API',
                    onClick: () => onGenerateAPIKey({ delete: true }),
                    iconName: '',
                    source: '',
                  }
                : null,
            ].filter((el) => el && el) as [] | undefined
          }
          width={150}
          open={open}
          onclose={() => setOpen(null)}
        />
        <ButtonImproved
          onClick={(e: any) => {
            setOpen(e.currentTarget);
          }}
          variant="outlined"
          styleProps={{
            width: '28px',
            minWidth: '28px',
            height: '28px',
            borderRadius: '6px',
          }}
          label={<MoreVertOutlined />}
          disabled={disableEdit}
        />
      </Stack>
    </SettingBlockItem>
  );
}

export default function Settings() {
  return (
    <section id={SETTINGS_NAME} className="section">
      <SettingBlock
        title="Misc. Settings"
        subTitle="Here are some of the less used features, but we wanted you to have it just in case :)"
      >
        <DefaultTimeZone />
        <TractorAverageSpeed />
        <WorkStartWeek />
        <GeofenceMap />
        <DefaultLanguage />
        <ApiKey />
      </SettingBlock>
    </section>
  );
}
