import { Grid, Stack, ThemeProvider, useTheme } from '@mui/material';
import AllFilters from '../../../../../ui-kit/components/DataGridPro/filter/AllFilters';
import Filters from '../../../../../ui-kit/components/DataGridPro/filter/Filters';
import { useThemeResponsive } from '../../../../../common/hooks/useThemeResponsive';
import { DispatchFiltersName } from '../../../constants/types';
import { getStyledCustomFilter, getThemeDispatchFilters } from './styles';
import useFiltersAutoResize, {
  mergeHiddenColumns,
} from '../../../../../common/hooks/useFiltersAutoResize';
import { forwardRef, useEffect, useMemo } from 'react';
import { useStores } from '../../../../../store/root.context';
import StyledTableSettings from '../../Generic/StyledTableSettings';
import { observer } from 'mobx-react';
import { useDispatchStorage } from '../../../hooks/useDispatchStorage';

export interface CustomizeFiltersProps {
  filterColumns: any[];

  [key: string]: any;
}

const CustomAllFilters = forwardRef<HTMLDivElement, CustomizeFiltersProps>(
  (
    {
      filterColumns = [],
      displayFilters = true,
      onAllFilterSubmit,
      onAllFilterReset,
      filterPerCol,
      defaultFilterValue,
    },
    ref
  ) => {
    const currTheme = useTheme();
    const styles = getStyledCustomFilter({ theme: currTheme });
    return (
      displayFilters && (
        <AllFilters
          column={filterColumns
            .filter(
              (item) =>
                item.name !== DispatchFiltersName.sortDirection &&
                item.name !== DispatchFiltersName.sort
            )
            .map((item) => {
              return {
                ...item,
                customStyles: null,
              };
            })}
          handleOnSubimt={onAllFilterSubmit}
          handleOnReset={onAllFilterReset}
          filterPerCol={filterPerCol}
          defaultFilterValue={defaultFilterValue}
          stylesProps={styles.AllFilters}
          ref={ref}
        />
      )
    );
  }
);

export const CustomizeFilters = observer(
  ({
    filterColumns = [],
    displayFilters = true,
    onAllFilterSubmit,
    onAllFilterReset,
    filterPerCol,
    defaultFilterValue,
    onPageFilterChange,
    isGridView,
  }: CustomizeFiltersProps) => {
    const { isMobile } = useThemeResponsive();
    const currTheme = useTheme();
    const overrideTheme = getThemeDispatchFilters({ currTheme });
    const styles = getStyledCustomFilter({ theme: currTheme });
    const { getCurrentStorage, updateStorage } = useDispatchStorage();

    const {
      dispatch2TripsStore: { secondaryPanelData },
      dispatch2GlobalStore: {
        ganttColumnToHide,
        setGanttColumnToHide,
        resetGanttColumnHeaders,
      },
    } = useStores();

    const { hiddenColumns, filterRefs, allFilterRef, containerRef } =
      useFiltersAutoResize({ panelState: !!secondaryPanelData });

    const displayFilterColumns = useMemo(
      () => mergeHiddenColumns(filterColumns, hiddenColumns),
      [filterColumns, hiddenColumns]
    );

    const handleColumnSwitch = (data: { [key: string]: boolean }) => {
      setGanttColumnToHide(data);
      updateStorage({ gantt: { columns: data } });
    };
    const handleColumnVisiblityReset = () => {
      resetGanttColumnHeaders?.();
      updateStorage({ gantt: { columns: {} } });
    };

    useEffect(() => {
      setGanttColumnToHide(getCurrentStorage()?.gantt?.columns!);
    }, []);

    return (
      <ThemeProvider theme={overrideTheme}>
        <Grid container sx={styles.LeftCustomizeFilters}>
          <Grid
            id="FilterGridRow"
            container
            sx={styles.FilterGridRow}
            ref={containerRef}
          >
            {!isMobile && filterColumns?.length > 0 ? (
              <Stack sx={styles.FilterGridRowContent}>
                <Filters
                  columns={displayFilterColumns}
                  onChangeCb={onPageFilterChange}
                  defaultFilterValue={defaultFilterValue}
                  setRef={(index: number, ref: HTMLDivElement, column: any) => {
                    filterRefs.current[index] = { name: column.name, el: ref };
                  }}
                />
                <CustomAllFilters
                  filterColumns={displayFilterColumns}
                  displayFilters={displayFilters}
                  onAllFilterSubmit={onAllFilterSubmit}
                  onAllFilterReset={onAllFilterReset}
                  filterPerCol={filterPerCol}
                  defaultFilterValue={defaultFilterValue}
                  ref={allFilterRef}
                />
                {!isMobile && !isGridView && (
                  <StyledTableSettings
                    getExcel={undefined}
                    excelDoc={undefined}
                    tableColumnsData={ganttColumnToHide}
                    handleChangeColumns={handleColumnSwitch}
                    resetSettings={handleColumnVisiblityReset}
                    handleChangeForColumnModel={() => {}}
                    children={undefined}
                    downloadFile={undefined}
                  />
                )}
              </Stack>
            ) : (
              <CustomAllFilters
                filterColumns={filterColumns}
                displayFilters={displayFilters}
                onAllFilterSubmit={onAllFilterSubmit}
                onAllFilterReset={onAllFilterReset}
                filterPerCol={filterPerCol}
                defaultFilterValue={defaultFilterValue}
              />
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
);
