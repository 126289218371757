export enum BulkSettlementActions {
  EMAIL = 'EMAIL',
  CLOSE_SETTLEMENT = 'CLOSE_SETTLEMENT',
  DOWNLOAD = 'DOWNLOAD',
  MARK_AS_REVIEWED = 'MARK_AS_REVIEWED',
  REVERT_TO_IN_REVIEW = 'REVERT_TO_IN_REVIEW',
  REVERT_TO_REVIEWED = 'REVERT_TO_REVIEWED',
  DELETE = 'DELETE',
  CREATE_SETTLEMENT = 'CREATE_SETTLEMENT',
  CREATE_PAY_STATMENT = 'CREATE_PAY_STATMENT',
  CLOSE_PAY_STATMENT = 'CLOSE_PAY_STATMENT',
  MARK_AS_IN_REVIEW = 'MARK_AS_IN_REVIEW',
}

export enum SettlementStatusByActionTypes {
  MARK_AS_REVIEWED = 'REVIEWED',
  REVERT_TO_IN_REVIEW = 'IN_REVIEW',
  CLOSE_SETTLEMENT = 'CLOSED',
  REVERT_TO_REVIEWED = SettlementStatusByActionTypes.MARK_AS_REVIEWED,
  CLOSE_PAY_STATMENT = SettlementStatusByActionTypes.CLOSE_SETTLEMENT,
  MARK_AS_IN_REVIEW = SettlementStatusByActionTypes.REVERT_TO_IN_REVIEW,
}

export const GlobalNotificationsForStatusChange: { [key: string]: string } = {
  MARK_AS_REVIEWED: 'markAsReviewed',
  REVERT_TO_IN_REVIEW: 'revertToInReview',
  REVERT_TO_REVIEWED: 'revertToReviewed',
  MARK_AS_IN_REVIEW: 'markAsInReview',
  CLOSE_SETTLEMENT: 'payStatementInReviewClose',
  CLOSE_PAY_STATMENT: 'payStatementInReviewClose',
};

export const PENDING_SETTLEMENT_BULK_ACTIONS = ({
  onClick,
  createSettlementPermission,
}: {
  onClick?: (data: any) => void;
  createSettlementPermission?: boolean;
}) => [
  {
    iconName: 'AddBoxOutlined',
    iconSource: 'MUIcons',
    label: 'Create Settlements',
    onClick: () => onClick?.(BulkSettlementActions.CREATE_SETTLEMENT),
    disabled: !createSettlementPermission,
  },
];

export const INREVIEW_SETTLEMENT_BULK_ACTIONS = ({
  onClick,
  permissions,
}: {
  onClick?: (data: any) => void;
  permissions: {
    changeInReViewToClosedPermission?: boolean;
    changeClosedToInReviewPermission?: boolean;
  };
}) => [
  {
    iconName: 'EmailOutlined',
    iconSource: 'MUIcons',
    label: 'Email',
    onClick: () => onClick?.(BulkSettlementActions.EMAIL),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'GetAppOutlined',
    iconSource: 'MUIcons',
    label: 'Download',
    onClick: () => onClick?.(BulkSettlementActions.DOWNLOAD),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'ArrowForwardOutlined',
    iconSource: 'MUIcons',
    label: 'Mark as Reviewed',
    onClick: () => onClick?.(BulkSettlementActions.MARK_AS_REVIEWED),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'CheckOutlined',
    iconSource: 'MUIcons',
    label: 'Close Settlement',
    onClick: () => onClick?.(BulkSettlementActions.CLOSE_SETTLEMENT),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'DeleteOutlined',
    iconSource: 'MUIcons',
    label: 'Delete',
    onClick: () => onClick?.(BulkSettlementActions.DELETE),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
];

export const REVIEWED_SETTLEMENT_BULK_ACTIONS = ({
  onClick,
  permissions,
}: {
  onClick?: (data: any) => void;
  permissions: {
    changeInReViewToClosedPermission?: boolean;
    changeClosedToInReviewPermission?: boolean;
  };
}) => [
  {
    iconName: 'EmailOutlined',
    iconSource: 'MUIcons',
    label: 'Email',
    onClick: () => onClick?.(BulkSettlementActions.EMAIL),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'GetAppOutlined',
    iconSource: 'MUIcons',
    label: 'Download',
    onClick: () => onClick?.(BulkSettlementActions.DOWNLOAD),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'ArrowBackOutlined',
    iconSource: 'MUIcons',
    label: 'Mark as In-Review',
    onClick: () => onClick?.(BulkSettlementActions.REVERT_TO_IN_REVIEW),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'CheckOutlined',
    iconSource: 'MUIcons',
    label: 'Close Settlement',
    onClick: () => onClick?.(BulkSettlementActions.CLOSE_SETTLEMENT),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'DeleteOutlined',
    iconSource: 'MUIcons',
    label: 'Delete',
    onClick: () => onClick?.(BulkSettlementActions.DELETE),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
];

export const CLOSED_SETTLEMENT_BULK_ACTIONS = ({
  onClick,
  permissions,
}: {
  onClick?: (data: any) => void;
  permissions: {
    changeInReViewToClosedPermission?: boolean;
    changeClosedToInReviewPermission?: boolean;
  };
}) => [
  {
    iconName: 'EmailOutlined',
    iconSource: 'MUIcons',
    label: 'Email',
    onClick: () => onClick?.(BulkSettlementActions.EMAIL),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'GetAppOutlined',
    iconSource: 'MUIcons',
    label: 'Download',
    onClick: () => onClick?.(BulkSettlementActions.DOWNLOAD),
    disabled: !permissions?.changeInReViewToClosedPermission,
  },
  {
    iconName: 'ArrowBackOutlined',
    iconSource: 'MUIcons',
    label: 'Mark as In-Review',
    onClick: () => onClick?.(BulkSettlementActions.REVERT_TO_IN_REVIEW),
    disabled: !permissions?.changeClosedToInReviewPermission,
  },
  {
    iconName: 'ArrowBackOutlined',
    iconSource: 'MUIcons',
    label: 'Mark as Reviewed',
    onClick: () => onClick?.(BulkSettlementActions.REVERT_TO_REVIEWED),
    disabled: !permissions?.changeClosedToInReviewPermission,
  },
];
