import {
  AssignmentIndOutlined,
  CheckOutlined,
  DocumentScannerOutlined,
  RequestQuoteOutlined,
} from '@mui/icons-material';
import DnsOutlined from '@mui/icons-material/DnsOutlined';
import {
  activeLoadsStripId,
  allLoadsStripId,
  needDriversStripId,
  pastLoadsStripId,
  toBeInvoicedStripId,
  toBeSettledStripId,
} from '../../../../constants/commonConstants';
import { LoadConstants } from '../../../../locales/en/allLoads/loads/index';
import { columns } from '../../../../subPages/loadsList/constants';
import { ColumnsMap } from '../../../../types';
import { getGridCompanyTimezoneSettings } from '../../../../utils/Timezone.utils';
import { ERelativeDateRangeKey } from '@/ui-kit/components/RelativeDateRange';
import { RelativeDateRangeUtils } from '@/common/Ui/RelativeDateRange/RelativeDateRange.utils';
const timezone = getGridCompanyTimezoneSettings().timezone;

export const loadColumnsInfo = {
  warning: true,
  seqNumber: true,
  referenceId: true,
  loadStatus: true,
  loadType: true,
  loadPriority: true,
  brokerageTrip: true,
  canBeBrokered: true,
  manifestBaseDetails: true,
  onHoldState: false,
  loadSettlementStatus: true,
  revenue: true,
  tripConnectionDetails: true,
  driver: true,
  carrierDetails: true,
  customerName: true,
  tractorName: true,
  trailerName: true,
  sealNumber: true,
  chassisNumber: true,
  bookingNumber: true,
  appointmentNumber: true,
  loadDirection: true,
  containerNumber: true,
  totalWeight: true,
  fromLocationName: true,
  toLocationName: true,
  numberOfStops: true,
  missingDocuments: true,
  terminal: true,
  dispatchTerminals: true,
  dispatchers: true,
  driverGroups: true,
  tractors: true,
  trailers: true,
  trips: true,
  tags: true,
  createDate: true,
  dispatchNotes: true,
  commodityTypes: true,
  equipmentTypes: true,
  pickup: true,
  dropOff: true,
};

export const loadFilters = {
  referenceIds: [],
  driverIdsFilter: [],
  customerIdsFilter: [],
  equipmentTypes: [],
  dispatcherIdsFilter: [],
  loadStatusList: [],
  chassisNumbers: [],
  containerNumbers: [],
  loadIdsFilter: [],
  manifestIdsFilter: [],
  originCityStateList: [],
  destinationCityStateList: [],
  loadSettlementStatusList: [],
  hasViolation: null,
  calculateDetention: null,
  needToAssign: null,
  tractorIdsFilter: [],
  trailerIdsFilter: [],
  operationMode: null, // should be removed
  loadIsInvoiceable: null,
  pickupDateRanges: null,
  appointmentDateRanges: null,
  originState: [],
  destinationState: [],
  dispatchers: [],
  driverGroups: [],
  tractors: [],
  trailers: [],
  trips: [],
  isRelay: null,
  commodityTypes: [],
  dispatchNotes: null,
  loadType: [],
  carrierIdsFilter: [],
  hasBrokerageTrip: [],
  bookingNumbers: [],
  appointmentNumbers: [],
  loadDirections: [],
  tagIds: [],
};

export const allLoadFilters = {
  ...loadFilters,
  pickupDateRanges: RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
    ERelativeDateRangeKey.PeriodTimeLast3MonthsOnward,
    timezone
  ),
};
export const loadView = {
  id: allLoadsStripId,
  shared: true,
  parentId: null,
  name: LoadConstants.allLoads,
  entity: 'load',
  icon: <DnsOutlined />,
  active: true,
  default: true,
  columnFilters: loadColumnsInfo,
  metaData: { ...allLoadFilters },
};
export const loadNeedDriverFilters = {
  ...loadFilters,
  loadStatusList: [
    { key: 'AVAILABLE', value: 'Available' },
    { key: 'ASSIGNMENT_PLANNED', value: 'Planned' },
  ],
};
export const loadViewNeedDrivers = {
  id: needDriversStripId,
  shared: true,
  parentId: null,
  name: LoadConstants.needDrivers,
  entity: 'load',
  icon: <AssignmentIndOutlined />,
  active: true,
  default: true,
  columnFilters: loadColumnsInfo,
  metaData: loadNeedDriverFilters,
};
export const activeLoadFilters = {
  ...loadFilters,
  loadStatusList: [
    { key: 'OFFERED_TO_DRIVER', value: 'Assigned' },
    { key: 'DISPATCHED', value: 'Dispatched' },
    { key: 'IN_TRANSIT', value: 'In-Transit' },
  ],
};
export const loadToBeInvoicedFilters = {
  ...loadFilters,
  loadStatusList: [
    { key: 'LOAD_COMPLETED', value: 'Completed' },
    { key: 'CANCELLED', value: 'Canceled' },
    { key: 'READY_TO_INVOICE', value: 'Ready to Invoice' },
  ],
  loadIsInvoiceable: { key: true, value: 'Yes' },
  pickupDateRanges: RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
    ERelativeDateRangeKey.PeriodTimeLast3MonthsOnward,
    timezone
  ),
};
export const loadToBeSettledFilters = {
  ...loadFilters,
  loadSettlementStatusList: [
    { key: 'PENDING', value: 'Pending' },
    { key: 'IN_REVIEW', value: 'In Review' },
    { key: 'REVIEWED', value: 'Reviewed' },
  ],
  pickupDateRanges: RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
    ERelativeDateRangeKey.PeriodTimeLast3MonthsOnward,
    timezone
  ),
};

export const pastLoadFilters = {
  ...loadFilters,
  loadStatusList: [
    { key: 'INVOICED', value: 'Invoiced' },
    { key: 'PAID', value: 'Paid' },
  ],
  // appointmentDateRanges: RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
  //   ERelativeDateRangeKey.PeriodTimeLast3Months,
  //   timezone
  // ),
};
export const defaultSortName = '-createDate';
export const formDefaultValuesMap = {
  load: loadView,
};

export const loadViewActiveLoads = {
  id: activeLoadsStripId,
  shared: true,
  parentId: null,
  name: LoadConstants.activeLoads,
  entity: 'load',
  icon: <DnsOutlined />,
  active: true,
  default: true,
  columnFilters: loadColumnsInfo,
  metaData: activeLoadFilters,
};
export const loadViewToBeInvoiced = {
  id: toBeInvoicedStripId,
  shared: true,
  parentId: null,
  name: LoadConstants.toBeInvoiced,
  entity: 'load',
  icon: <CheckOutlined />,
  active: true,
  default: true,
  columnFilters: loadColumnsInfo,
  metaData: loadToBeInvoicedFilters,
};
export const loadViewToBeSettled = {
  id: toBeSettledStripId,
  shared: true,
  parentId: null,
  name: LoadConstants.toBeSettled,
  entity: 'load',
  icon: <RequestQuoteOutlined />,
  active: true,
  default: true,
  columnFilters: loadColumnsInfo,
  metaData: loadToBeSettledFilters,
};
export const loadViewPastLoads = {
  id: pastLoadsStripId,
  shared: true,
  parentId: null,
  name: LoadConstants.pastLoads,
  entity: 'load',
  icon: <DocumentScannerOutlined />,
  active: true,
  default: true,
  columnFilters: loadColumnsInfo,
  metaData: pastLoadFilters,
};
export const defaultViews = [
  { ...loadView },
  { ...loadViewNeedDrivers },
  { ...loadViewActiveLoads },
  { ...loadViewToBeInvoiced },
  { ...loadViewToBeSettled },
  { ...loadViewPastLoads },
];

export const allLoadsColOrdering = [
  'warning',
  'seqNumber',
  'referenceId',
  'loadStatus',
  'loadType',
  'manifestBaseDetails',
  'onHoldState',
  'tags',
  'loadSettlementStatus',
  'revenue',
  'trips',
  'loadPriority',
  'brokerageTrip',
  'canBeBrokered',
  'driverGroups',
  'carrierDetails',
  'dispatchers',
  'terminal',
  'dispatchTerminals',
  'customerName',
  'tractors',
  'trailers',
  'equipmentTypes',
  'sealNumber',
  'chassisNumber',
  'containerNumber',
  'loadDirection',
  'bookingNumber',
  'appointmentNumber',
  'commodityTypes',
  'totalWeight',
  'fromLocationName',
  'toLocationName',
  'pickup',
  'dropOff',
  'numberOfStops',
  'missingDocuments',
  'dispatchNotes',
  'createDate',
];

export const columnsMap: ColumnsMap = {
  load: columns,
};

export const loadsearchColumnInfo = {
  Broker: false,
  Offer: false,
  'Reload Score': false,
  '$/Mile': false,
  'Market Rate': false,
  Equipment: false,
};
