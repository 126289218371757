import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  TooltipProps,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';
import OptymizedLogo from '../../static/optymizedLogo';
import { LockButton, RejectButton } from '../generic';
import InfoIcon from '@mui/icons-material/Info';
import ReassignResource from '../generic/ReassignResource';
import { ReassignOptionTypes } from '../../types/recommendTrips.types';
import ConflictTooltip from './ConflictTooltip';
import { useStores } from '../../../../store/root.context';
import { EvaluationResDTO } from '../../../../views/evaluation/types';
import {
  OPTIMIZATION_TYPES,
  OptimizationType,
} from '../../../../store/EvaluationMode/Evaluation.store';
import { IRecommendedResource } from '../../../../views/optymization/types/gantt.types';
import { isResourceTypeAssignedDriver } from '../../../../views/optymization/services/recommendTrips.service';
import LightbulbOutlined from '@mui/icons-material/LightbulbOutlined';
import ActionIconButton from '../generic/ActionIconButton';
import { LOADAI_ELEMENTS } from '../../../../constants/elementIds';
import { isEvaluationIssueCritical } from '../../services/ganttColumns.service';
import { RejectButtonStyles } from '../../styles';

interface IActionColumn {
  isLocked?: boolean;
  isRejected?: boolean;
  isResourceRejected?: boolean;
  isRecommended?: boolean;
  onLock?: (event?: React.MouseEvent<HTMLElement>) => void;
  onReject?: () => void;
  isOptymal?: boolean;
  isRejectDisabled?: boolean;
  isLockDisabled?: boolean;
  optymalTooltip?: string;
  conflictsTooltip?: string[] | null;
  showTooltip?: boolean;
  reassignOptions?: ReassignOptionTypes[];
  onReassign?: (val: ReassignOptionTypes) => void;
  allowReassign?: boolean;
  onReassignClick?: () => void;
  evaluationResult?: EvaluationResDTO;
  driver?: IRecommendedResource;
  optimizationType?: OptimizationType;
  isSourceRecommendation?: boolean;
}

const ActionColumn: React.FC<IActionColumn> = (props) => {
  const {
    evaluationStore: { setEvaluationDetails },
  } = useStores();
  const theme = useTheme();

  const handleEvluateResource = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setEvaluationDetails({
      source: 'OPTIMIZATION',
      targetPosition: {
        left: event.clientX,
        top: event.clientY,
      },
      resource: props?.driver!,
      optimizationType:
        props?.optimizationType ?? OPTIMIZATION_TYPES.RECOMMENDED_RESOURCE,
      evaluationResult: props?.evaluationResult!,
    });
  };

  const evaluationIssueCriticalColor = useMemo(
    () => (isEvaluationIssueCritical(props?.driver!) ? 'error' : 'primary'),
    [props?.driver?.evaluateResponseDTO]
  );

  return (
    <Stack
      direction={'row'}
      gap={'8px'}
      textAlign={'center'}
      alignItems="center"
    >
      <LockButton
        id={
          props?.isSourceRecommendation
            ? LOADAI_ELEMENTS.LOCK_ACTION
            : LOADAI_ELEMENTS.POSSIBLE_OPTION_LOCK
        }
        disabled={props.isLockDisabled ?? props?.isRejected}
        isLocked={props.isLocked}
        onClick={props?.onLock}
      />
      <RejectButton
        id={
          props?.isSourceRecommendation
            ? LOADAI_ELEMENTS.REJECT_ACTION
            : LOADAI_ELEMENTS.POSSIBLE_OPTION_REJECT
        }
        disabled={props.isLocked || props.isRejectDisabled}
        isRejected={props.isRejected}
        onClick={props.onReject}
      />

      {props?.allowReassign && Boolean(props?.reassignOptions?.length) && (
        <ReassignResource
          id={LOADAI_ELEMENTS.POSSIBLE_OPTIONS}
          options={props?.reassignOptions}
          onSelect={props?.onReassign}
          onReassignClick={props?.onReassignClick}
        />
      )}

      {props?.driver?.evaluateResponseDTO && (
        <ActionIconButton
          id={LOADAI_ELEMENTS.BULK_OPTIMIZATION_EVALUATE_BUTTON}
          onClick={handleEvluateResource as any}
          style={
            isEvaluationIssueCritical(props?.driver)
              ? RejectButtonStyles(theme, false)
              : {}
          }
          color={evaluationIssueCriticalColor}
        >
          <LightbulbOutlined
            color={evaluationIssueCriticalColor}
            sx={{ width: 14, height: 14 }}
          />
        </ActionIconButton>
      )}

      {props?.isOptymal && (
        <TooltipTemplate
          title={
            props?.optymalTooltip?.length ? (
              <ConflictTooltip content={[props?.optymalTooltip]} />
            ) : null
          }
          disableHoverListener={!props?.showTooltip}
        >
          <Box>
            <OptymizedLogo />
          </Box>
        </TooltipTemplate>
      )}
      {props?.conflictsTooltip?.length && (
        <TooltipTemplate
          disableHoverListener={!props?.showTooltip}
          title={<ConflictTooltip content={props?.conflictsTooltip} />}
        >
          <InfoIcon
            sx={{
              color: 'rgba(0, 17, 34, 0.26)',
              width: '18px',
              height: '18px',
            }}
          />
        </TooltipTemplate>
      )}
    </Stack>
  );
};

const TooltipTemplate = (props: TooltipProps) => {
  return (
    <Tooltip
      placement="top"
      arrow
      componentsProps={{
        popper: {
          sx: {
            '.MuiTooltip-tooltip': {
              background:
                'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D',
            },
            '.MuiTooltip-arrow': {
              color: '#485c70',
            },
          },
        },
      }}
      {...props}
    >
      {props?.children}
    </Tooltip>
  );
};

export default ActionColumn;
