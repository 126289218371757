import { ERelativeDateRangeKey } from '../../../ui-kit/components/RelativeDateRange';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import {
  GanttDriverLocationMarkersResponse,
  GanttWarningsResponse,
  IDispatchDriver,
  IDispatchDriverShort,
  PaginatedDispatchDriverListResponse,
} from '../../../models/DTOs/Dispatch/Dispatch';
import {
  GanttDriverLocationMarkersRequest,
  GanttWarningsRequest,
  PaginatedDispatchDriverRequest,
} from '../../../models/DTOs/Dispatch/Requests';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { LoadDetailsSummary } from '../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';

import {
  debounceTime,
  defaultDispatchFilters,
  ganttPageSize,
} from '../constants/dispatch';
import {
  DispatchFiltersName,
  EGanttTabPanel,
  GanttEventsType,
  GanttResourceTimeRangeType,
  GanttResourceType,
  IDispatchFilters,
} from '../constants/types';
import { useDispatchStorage } from '../hoc/useDispatchStorage';
import DispatchController from '../utils/Controller';
import { GanttServices } from '../utils/GanttService';
import {
  IWarningDriverGroup,
  mappWarningDriverGroupList,
} from '../utils/warning.utils';

export interface DispatchContextType {
  isLoading: boolean;
  filters: IDispatchFilters;
  setFilters: React.Dispatch<React.SetStateAction<IDispatchFilters>>;
  resetFilters: () => void;
  displayMode: EGanttTabPanel;
  setDisplayMode: React.Dispatch<React.SetStateAction<EGanttTabPanel>>;
  ganttDriver: PaginatedDispatchDriverListResponse | null;
  ganttWarnings: GanttWarningsResponse | null;
  ganttDriverLocationMarkers: GanttDriverLocationMarkersResponse | null;
  navigateTo: (isNext: boolean) => void;
  getPaginatedGanttDriverHandler: (params: {
    pageNumber: number;
    newFilters?: IDispatchFilters;
    fetchLocation?: boolean;
    terminalsIds?: string[];
  }) => Promise<void>;
  refreshGanttDriverHandler: (
    driverIds: number[],
    fetchLocation?: boolean
  ) => Promise<void>;
  warningDriverGroups: IWarningDriverGroup[];
  selectedDispatchDriver: IDispatchDriver | null;
  setSelectedDispatchDriver: React.Dispatch<
    React.SetStateAction<IDispatchDriver | IDispatchDriverShort | null>
  >;

  totalDrivers: number;
  ganttResources: GanttResourceType[];
  ganttEvents: GanttEventsType[];
  ganttResourceTimeRanges: GanttResourceTimeRangeType[];
  refreshGanttDriverLocationMarkers: (params: {
    terminalIds?: string[];
  }) => Promise<void>;
  getMapFilteredData: () => GanttDriverLocationMarkersResponse | null;

  newLoadData: LoadDetailsSummary | null;
  setNewLoadData: React.Dispatch<
    React.SetStateAction<LoadDetailsSummary | null>
  >;
}

export const DispatchContext = React.createContext<DispatchContextType>(
  {} as DispatchContextType
);

export const DispatchProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const {
    getGlobalTerminalsIds,
    getDispatchPanelAutoOpenDriver,
    setDispatchPanelAutoOpenDriver,
    getCompanyPreferences,
  } = useRootStore();

  const controller = DispatchController.instance();
  const { updateStorage } = useDispatchStorage();

  const [displayMode, setDisplayMode] = useState<EGanttTabPanel>(
    EGanttTabPanel.Gantt
  );
  const [selectedDispatchDriver, setSelectedDispatchDriver] = useState<
    IDispatchDriver | IDispatchDriverShort | null
  >(null);

  const [filters, setFilters] = useState<IDispatchFilters>(
    defaultDispatchFilters
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [ganttDriver, setganttDriver] =
    useState<PaginatedDispatchDriverListResponse | null>(null);
  const [ganttDriverLocationMarkers, setGanttDriverLocationMarkers] =
    useState<GanttDriverLocationMarkersResponse | null>(null);

  const [warningDriverGroups, setWarningDriverGroups] = useState<
    IWarningDriverGroup[]
  >([]);

  const [ganttResources, setGanttResources] = useState<GanttResourceType[]>([]);
  const [ganttEvents, setGanttEvents] = useState<GanttEventsType[]>([]);
  const [ganttResourceTimeRanges, setGanttResourceTimeRanges] = useState<
    GanttResourceTimeRangeType[]
  >([]);
  const [newLoadData, setNewLoadData] = useState<LoadDetailsSummary | null>(
    null
  );

  const resetFilters = (): void => {
    setFilters(defaultDispatchFilters);
  };

  const getGanttDriverLocationMarkers = async (
    newFilters = filters,
    terminalList = { terminalIds: getGlobalTerminalsIds }
  ): Promise<void> => {
    const _driverLocationMarkers =
      await controller.getGanttDriverLocationMarkers(
        new GanttDriverLocationMarkersRequest(terminalList),
        newFilters
      );
    setGanttDriverLocationMarkers(_driverLocationMarkers);
  };

  const getGanttDriverWarnings = async (): Promise<void> => {
    const driverGroupIds: string[] = controller.getNewDriverGroupIds();
    const data = await controller.getGanttWarnings(
      new GanttWarningsRequest({
        driverGroupIds,
      }),
      filters
    );
    if (data) {
      const { response, newDriverGroupWarnings } = data;
      setWarningDriverGroups(mappWarningDriverGroupList(response));
      assignWarningToGanttData(
        mappWarningDriverGroupList(newDriverGroupWarnings)
      );
    }
    setIsLoading(false);
  };

  const refreshGanttDriverHandler = async (
    driverIds: number[],
    fetchLocation = false
  ): Promise<void> => {
    if (driverIds && driverIds.length > 0) {
      controller.setSkipTrackPromise(true);
      if (fetchLocation) {
        getGanttDriverLocationMarkers(filters);
      }
      setIsLoading(true);
      const requestData: PaginatedDispatchDriverRequest =
        new PaginatedDispatchDriverRequest({
          pageNumber: 1,
          pageSize: ganttPageSize,
          terminalIds: getGlobalTerminalsIds,
          driverIds: driverIds,
        });

      const data = await controller.refreshGanttDriverByDriverIds(
        requestData,
        filters
      );

      if (data) {
        const { response, newDispatchDrivers } = data;
        generateNewOrUpdateGanttData(newDispatchDrivers);
        setganttDriver(response);

        getGanttDriverWarnings();
      } else {
        setIsLoading(false);
      }
    }
  };

  const getPaginatedGanttDriverHandler = async ({
    pageNumber,
    newFilters = filters,
    fetchLocation = false,
    terminalsIds = getGlobalTerminalsIds,
  }: {
    pageNumber: number;
    newFilters?: IDispatchFilters;
    fetchLocation?: boolean;
    terminalsIds?: string[];
  }): Promise<void> => {
    controller.setSkipTrackPromise(false);
    setIsLoading(true);
    if (fetchLocation) {
      getGanttDriverLocationMarkers(newFilters);
    }

    const requestData: PaginatedDispatchDriverRequest =
      new PaginatedDispatchDriverRequest({
        terminalIds: terminalsIds ? terminalsIds : getGlobalTerminalsIds,
      });
    const responseData = await controller.getPaginatedGanttDriverList(
      pageNumber,
      ganttPageSize,
      requestData,
      newFilters
    );

    if (responseData !== null) {
      const { response, newDispatchDrivers } = responseData;

      if (pageNumber === 1) {
        GanttServices.getInstance().resetGanttData();
      }

      generateNewOrUpdateGanttData(newDispatchDrivers);
      setganttDriver(response);
      getGanttDriverWarnings();
    } else {
      setIsLoading(false);
    }
  };

  const generateNewOrUpdateGanttData = (
    newDispatchDrivers: IDispatchDriver[]
  ): void => {
    const ganttData =
      GanttServices.getInstance().generateNewOrUpdateGanttDataByDrivers({
        newDispatchDrivers,
        timezone: controller.preferences?.timezone,
      });
    setGanttResources(ganttData.ganttResources);
    setGanttEvents(ganttData.ganttEvents);
    setGanttResourceTimeRanges(ganttData.ganttResourceTimeRanges);
  };

  const assignWarningToGanttData = (
    warningDriverGroups: IWarningDriverGroup[]
  ) => {
    const data =
      GanttServices.getInstance().assignWarningToGanttDataByWarnings(
        warningDriverGroups
      );
    setGanttEvents(data.ganttEvents);
  };

  const navigateTo = (isNext: boolean): void => {
    const { window, startDate: currStartDate } =
      controller.getFiltersDate(filters);
    const newStartDate: Date = isNext
      ? moment(currStartDate).add(window, 'days').toDate()
      : moment(currStartDate).subtract(window, 'days').toDate();
    const newFilters: IDispatchFilters = {
      ...filters,
      filters: {
        ...filters.filters,
        [DispatchFiltersName.startDate]: {
          ...RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
            ERelativeDateRangeKey.PeriodTimeCustom
          ),
          dateRange: [newStartDate, newStartDate],
        },
      },
    };
    setFilters(newFilters);

    updateStorage(newFilters);

    const callDebounceFunc = debounce(() => {
      getPaginatedGanttDriverHandler({ pageNumber: 1, newFilters });
    }, debounceTime);
    callDebounceFunc();
  };

  const refreshGanttDriverLocationMarkers = async (terminalIds: {
    terminalIds: string[];
  }): Promise<void> => {
    getGanttDriverLocationMarkers(filters, terminalIds);
  };

  const getMapFilteredData = (): GanttDriverLocationMarkersResponse | null => {
    const hasFilteredDriverId =
      filters.filters[DispatchFiltersName.driver]?.length > 0;

    if (hasFilteredDriverId) {
      const driverGroupIds: string[] =
        ganttResources?.map((item) => item.id) || [];

      const mappings = {};
      Object.keys(ganttDriverLocationMarkers?.mappings).forEach(
        (driverGroupId: string) => {
          if (driverGroupIds.includes(driverGroupId)) {
            mappings[driverGroupId] =
              ganttDriverLocationMarkers.mappings[driverGroupId];
          }
        }
      );

      const markers = ganttDriverLocationMarkers?.markers?.filter((item) =>
        driverGroupIds.includes(item.driverGroupID)
      );
      const missingMarkers = ganttDriverLocationMarkers?.missingMarkers?.filter(
        (item) => driverGroupIds.includes(item.id)
      );
      return {
        mappings,
        markers,
        missingMarkers,
      };
    }
    return ganttDriverLocationMarkers;
  };

  useEffect(() => {
    if (getDispatchPanelAutoOpenDriver) {
      const payload: IDispatchDriverShort = {
        driverGroupDetails: {
          driverId: getDispatchPanelAutoOpenDriver.driverIds[0],
          id: getDispatchPanelAutoOpenDriver.id,
        },
      };

      setSelectedDispatchDriver(payload);
      setDispatchPanelAutoOpenDriver(null);
    }
  }, [getDispatchPanelAutoOpenDriver]);

  useEffect(() => {
    if (getCompanyPreferences) {
      controller.setPreference(getCompanyPreferences);
    }
  }, [getCompanyPreferences]);

  return (
    <DispatchContext.Provider
      value={{
        isLoading,
        filters,
        setFilters,
        resetFilters,
        displayMode,
        setDisplayMode,
        ganttDriver,
        ganttDriverLocationMarkers,
        navigateTo,
        getPaginatedGanttDriverHandler,
        refreshGanttDriverHandler,
        warningDriverGroups,
        selectedDispatchDriver,
        setSelectedDispatchDriver,

        ganttResources,
        ganttEvents,
        ganttResourceTimeRanges,
        refreshGanttDriverLocationMarkers,
        getMapFilteredData,

        newLoadData,
        setNewLoadData,
      }}
    >
      {children}
    </DispatchContext.Provider>
  );
};

export const useDispatchSettings = () => React.useContext(DispatchContext);
