import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  CreateDriverAlertDTO,
  CreateLoadAlertDTO,
  CreateTractorDTO,
  CreateTrailerDTO,
  DeleteLoadAlertDTO,
  GetLoadAlertsTypeDTO,
  GetLoadUserRolesDTO,
  GetStatic,
  LoadAlertsTypeDTO,
  LoadUserRolesDTO,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { annotateNameAsync } from '../interfaces';
import { IAlertsService } from '../interfaces/IAlertsService';

const getLoadAlertsTypeURL =
  '/web/preference/api/v2/alert/load/preference/alert-type/list';
const getLoadUserRolesURL =
  '/web/preference/api/v2/alert/load/preference/alert-type/user-roles';
const createLoadURL = '/web/preference/api/v2/alert/load/preference';

const getDriverAlertsURL =
  '/web/dictionary/api/v2/document/items/getByOrganizationId';
const getStatic = '/web/preference/api/v2/preference/companydriver/static';
const createDriverAlertURL = '/web/preference/api/v2/preference/alert/driver';

const createTrailerAlertURL = '/web/preference/api/v2/preference/alert/trailer';
const createTractorAlertURL = '/web/preference/api/v2/preference/alert/tractor';

const getTrailerAlertsURL =
  '/web/dictionary/api/v2/maintenance/items/getByOrganizationId';
const getStaticTrailer =
  '/web/preference/api/v2/preference/companytrailer/static';
const getStaticTractor =
  '/web/preference/api/v2/preference/companytractor/static';

const getLoadAlertsListURL =
  '/web/preference/api/v2/alert/load/preference/list';
const getDriverAlertsListURL = '/web/preference/api/v2/preference/alert/driver';
const getTractorAlertsListURL =
  '/web/preference/api/v2/preference/alert/tractor';
const getTrailerAlertsListURL =
  '/web/preference/api/v2/preference/alert/trailer';

const deleteLoadAlertURL = '/web/preference/api/v2/alert/load/preference';
const deleteDriverAlertURL = '/web/preference/api/v2/preference/alert/driver';
const deleteTrailerAlertURL = '/web/preference/api/v2/preference/alert/trailer';
const deleteTractorAlertURL = '/web/preference/api/v2/preference/alert/tractor';

const editDriverAlertURL = '/web/preference/api/v2/preference/alert/driver';
const editTrailerAlertURL = '/web/preference/api/v2/preference/alert/trailer';
const editTractorAlertURL = '/web/preference/api/v2/preference/alert/tractor';

export class AlertsService extends IAlertsService {
  @annotateNameAsync
  async getLoadAlertsType(requestData: GetLoadAlertsTypeDTO) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getLoadAlertsTypeURL,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getLoadUserRoles(requestData: GetLoadUserRolesDTO) {
    try {
      return await httpClient.get<LoadUserRolesDTO>(
        getLoadUserRolesURL,
        requestData,
        LoadUserRolesDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getDriverAlertsType(requestData: GetLoadAlertsTypeDTO) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getDriverAlertsURL,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getTrailerAlertsType(requestData: GetLoadAlertsTypeDTO) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getTrailerAlertsURL,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getTractorAlertsType(requestData: GetLoadAlertsTypeDTO) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getTrailerAlertsURL,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getStaticForAlerts(requestData: GetStatic) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getStatic,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getStaticForTrailer(requestData: GetStatic) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getStaticTrailer,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getStaticForTractor(requestData: GetStatic) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getStaticTractor,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async editLoadAlert(
    requestData: GetLoadAlertsTypeDTO,
    payloadData: CreateLoadAlertDTO
  ) {
    try {
      const response = await httpClient.put(
        createLoadURL,
        requestData,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'editLoadAlert',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'editLoadAlert',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async editDriverAlert(payloadData: CreateDriverAlertDTO) {
    try {
      const response = await httpClient.put(
        editDriverAlertURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'editLoadAlert',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'editLoadAlert',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async editTrailerAlert(payloadData: CreateDriverAlertDTO) {
    try {
      const response = await httpClient.put(
        editTrailerAlertURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'editLoadAlert',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'editLoadAlert',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async editTractorAlert(payloadData: CreateDriverAlertDTO) {
    try {
      const response = await httpClient.put(
        editTractorAlertURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'editLoadAlert',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'editLoadAlert',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async createLoadAlert(
    requestData: GetLoadAlertsTypeDTO,
    payloadData: CreateLoadAlertDTO
  ) {
    try {
      const response = await httpClient.post(
        createLoadURL,
        requestData,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createLoadAlert',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'createLoadAlert',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async createDriverAlert(payloadData: CreateDriverAlertDTO) {
    try {
      const response = await httpClient.post(
        createDriverAlertURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createLoadAlert',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'createLoadAlert',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async createTrailerAlert(payloadData: CreateTrailerDTO) {
    try {
      const response = await httpClient.post(
        createTrailerAlertURL,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createLoadAlert',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'createLoadAlert',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async createTractorAlert(payloadData: CreateTractorDTO) {
    try {
      const response = await httpClient.post(
        createTractorAlertURL,
        undefined,
        payloadData,
        false,
        false,
        undefined,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createLoadAlert',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'createLoadAlert',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getLoadAlertsList(requestData: GetLoadAlertsTypeDTO) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getLoadAlertsListURL,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getDriverAlertsList(requestData: GetLoadAlertsTypeDTO) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getDriverAlertsListURL,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getTractorAlertsList(requestData: GetLoadAlertsTypeDTO) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getTractorAlertsListURL,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getTrailerAlertsList(requestData: GetLoadAlertsTypeDTO) {
    try {
      return await httpClient.get<LoadAlertsTypeDTO>(
        getTrailerAlertsListURL,
        requestData,
        LoadAlertsTypeDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async deleteLoadAlert(payloadData: DeleteLoadAlertDTO) {
    try {
      const response = await httpClient.deleteRaw(
        deleteLoadAlertURL,
        payloadData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'settingAlertDelete',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'settingAlertDelete',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async deleteDriverAlert(
    payloadData: DeleteLoadAlertDTO,
    selectedTab: string
  ) {
    try {
      let deleteURL = '';
      if (selectedTab === 'Driver') deleteURL = deleteDriverAlertURL;
      else if (selectedTab === 'Tractor') deleteURL = deleteTractorAlertURL;
      else if (selectedTab === 'Trailer') deleteURL = deleteTrailerAlertURL;

      const response = await httpClient.deleteRaw(
        deleteURL,
        undefined,
        payloadData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'settingAlertDelete',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'settingAlertDelete',
        });
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
}
