import { defaultCommodityData, Point } from './constants';
import { CommodityTypeSummary, OptionType } from '../../models';

import LoadController from '../../subPages/loadsList/LoadController';

export const getDefaultCommodity = (commodityTypes: CommodityTypeSummary[]) => {
  const defaultCommodityType = commodityTypes?.find(
    (item) => item.itemCode === 'MISCELLANEOUS'
  );
  if (defaultCommodityType) {
    return {
      ...defaultCommodityData,
      commodity: {
        id: defaultCommodityType.id,
        itemName: defaultCommodityType.itemName,
      },
      commodityTypeId: defaultCommodityType.id,
      commodityType: defaultCommodityType.itemCode,
      commodityTypeDisplayValue: defaultCommodityType.itemName,
      description: defaultCommodityType.description,
    };
  }
  return { ...defaultCommodityData };
};

export const getTrailerTypes = async (
  trailerTypes: string[]
): Promise<OptionType[]> => {
  if (!trailerTypes?.length) {
    return [];
  }
  const loadStaticData = await LoadController.instance().getLoadStatic();
  const equipmentTypeOptions = loadStaticData?.content || [];
  return (
    equipmentTypeOptions.filter((item: OptionType) =>
      trailerTypes.includes(item.key)
    ) || []
  );
};

export const isSamePoint = (point1: Point, point2: Point) =>
  point1?.lat === point2?.lat && point1?.lng === point2?.lng;
