import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  styled,
  SxProps,
} from '@mui/material';

export const StyledButton = styled(
  (props: ButtonProps & { isSelected?: boolean; disabled?: boolean }) => (
    <Button {...props} />
  )
)(({ theme, isSelected, disabled }: any) => ({
  borderRadius: '6px',
  padding: '5px',
  ...(isSelected
    ? {
        border: `1px solid ${theme.palette.primary.outlinedRestingBorder}`,
        backgroundColor: theme.palette.primary.outlinedHoverBackground,
      }
    : {}),
  ...(disabled && {
    color: theme.palette.action.disabled,
  }),
}));

export const StyledButtonGroup = styled((props: ButtonGroupProps) => (
  <ButtonGroup variant="outlined" size="medium" {...props} />
))(({ theme }) => ({
  borderRadius: '14px',
}));

export const getStyledAddNewCarrierForm = (): { [key: string]: SxProps } => {
  return {
    AddNewCarrierFormRoot: {
      p: 2,
    },
    AccordionRoot: {
      border: '1px solid',
      borderColor: 'primary.main',
      borderRadius: '8px',
    },
    TextField: {
      '.MuiOutlinedInput-root': {
        borderRadius: '10px !important',
      },
      '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '8px',
      },
    },
    TextFieldHasSearchResult: {
      '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '8px 8px 0px 0px  !important',
      },
    },
    Button: {
      borderRadius: '14px',
    },
    summaryRendererRoot: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'primary.contrast',
      borderRadius: '8px',
    },
    summaryRendererRootContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    SearchItemRenderRoot: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      border: '1px solid',
      borderColor: 'primary.main',
      borderRadius: '0px 0px 8px 8px',
      maxHeight: '210px',
      overflowY: 'auto',
      borderTop: 'unset',
    },
    SearchItemRenderNoResult: {
      p: 2,
      color: 'text.secondary',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    Divider: {
      '&.MuiDivider-root': {
        borderColor: 'primary.main',
      },
    },
    SearchItemRenderItem: {
      display: 'flex',
      flexDirection: 'column',
      p: 1,
      width: '100%',
      cursor: 'pointer',
    },
    SearchItemRenderItemName: {
      color: 'text.primary',
    },
    SearchItemRenderItemBody3: {
      color: 'text.secondary',
    },
    SearchItemRenderItemBody3Bold: {
      fontWeight: 600,
      pr: 1,
    },
    Rating: {
      '.MuiRating-iconFilled': {
        color: 'primary.main',
      },
    },
  };
};
