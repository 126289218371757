import { DateRange } from '@mui/x-date-pickers-pro-v5';
import moment, { Moment } from 'moment';
import { ERelativeDateRangeKey, IRelativeDateRangeOption } from './types';
import { isTimezoneValid } from '../Utils';

export class RelativeDateRangeUiKitUtils {
  static convertToMomentDate = (
    givenDateWithTz: Moment | Date | string | number,
    timezone?: string
  ): Moment | null => {
    if (!givenDateWithTz) {
      return null;
    }
    if (givenDateWithTz instanceof Date) {
      return isTimezoneValid(timezone)
        ? moment(givenDateWithTz).tz(timezone as string)
        : moment(givenDateWithTz);
    }

    if (moment.isMoment(givenDateWithTz)) {
      return givenDateWithTz;
    }

    if (typeof givenDateWithTz === 'string') {
      return isTimezoneValid(timezone)
        ? moment(new Date(givenDateWithTz)).tz(timezone as string)
        : moment(new Date(givenDateWithTz));
    }

    if (typeof givenDateWithTz === 'number') {
      return isTimezoneValid(timezone)
        ? moment(givenDateWithTz).tz(timezone as string)
        : moment(givenDateWithTz);
    }
    throw Error('Invalid input');
  };

  static isSameDateRanges = (
    dateRange1: DateRange<Date | null | string>,
    dateRange2: DateRange<Date | null | string>
  ): boolean => {
    return (
      moment(new Date(dateRange1[0])).isSame(new Date(dateRange2[0])) &&
      moment(new Date(dateRange1[1])).isSame(new Date(dateRange2[1]))
    );
  };
  static isNullDateRanges = (
    dateRange: DateRange<Date | null | string> | null
  ): boolean => {
    return !dateRange || (!dateRange?.[0] && !dateRange?.[1]);
  };

  static fromMomentDateToNativeDate = (
    inputMoment: Moment | null,
    timezone?: string
  ): Date | null => {
    if (inputMoment) {
      const zonedDate = RelativeDateRangeUiKitUtils.convertToMomentDate(
        inputMoment,
        timezone
      )!;
      return zonedDate.toDate();
    }
    return null;
  };

  static fromNativeDateToMomentDate = (
    inputDate: Date | null,
    timezone?: string
  ): Moment | null => {
    if (inputDate) {
      return RelativeDateRangeUiKitUtils.convertToMomentDate(
        inputDate,
        timezone
      );
    }
    return null;
  };

  static fromNativeDateToFormat = ({
    inputDate,
    timezone,
    dateFormat,
  }: {
    inputDate: Date | null;
    timezone?: string;
    dateFormat: string;
  }): string => {
    if (inputDate) {
      if (moment(inputDate).isValid()) {
        const zonedDate = RelativeDateRangeUiKitUtils.convertToMomentDate(
          inputDate,
          timezone
        )!;
        return zonedDate.format(dateFormat);
      }
    }
    return 'N/A';
  };

  static getDisplayOptionDateRange = ({
    option,
    timezone,
    dateFormat,
    isSingleDate,
  }: {
    option: IRelativeDateRangeOption;
    timezone?: string;
    dateFormat: string;
    isSingleDate: boolean;
  }): string => {
    if (
      [
        ERelativeDateRangeKey.PeriodTimeToday,
        ERelativeDateRangeKey.PeriodTimeYesterday,
        ERelativeDateRangeKey.PeriodTimeTomorrow,
      ].includes(option.key) ||
      isSingleDate
    ) {
      const startDate: string =
        RelativeDateRangeUiKitUtils.fromNativeDateToFormat({
          inputDate: option.dateRange[0],
          timezone,
          dateFormat,
        });
      return startDate;
    } else {
      const dateRanges = [
        RelativeDateRangeUiKitUtils.fromNativeDateToFormat({
          inputDate: option.dateRange[0],
          timezone,
          dateFormat,
        }),
        RelativeDateRangeUiKitUtils.fromNativeDateToFormat({
          inputDate: option.dateRange[1],
          timezone,
          dateFormat,
        }),
      ].join(' - ');
      if (option.key === ERelativeDateRangeKey.PeriodTimeCustom) {
        return dateRanges;
      }
      return `(${dateRanges})`;
    }
  };

  static correctFinalMomentDateRange = ({
    newStartDate = null,
    newEndDate = null,
    dateRangeCustomValue,
    isStartDate,
    isSingleDate,
  }: {
    newStartDate?: Moment | null;
    newEndDate?: Moment | null;
    dateRangeCustomValue: DateRange<Moment | null>;
    isStartDate: boolean;
    isSingleDate?: boolean;
  }): {
    finalStartDate: Moment | null;
    finalEndDate: Moment | null;
  } => {
    let finalStartDate: Moment | null = newStartDate;
    if (!newStartDate && !isStartDate) {
      finalStartDate = dateRangeCustomValue[0];
    }

    let finalEndDate: Moment | null = newEndDate;
    if (!newEndDate && isStartDate) {
      finalEndDate = dateRangeCustomValue[1];
    }

    if (!isSingleDate && finalStartDate && finalEndDate) {
      if (finalEndDate.isBefore(finalStartDate)) {
        finalEndDate = finalStartDate.clone();
      }
    }
    return {
      finalStartDate,
      finalEndDate,
    };
  };
}
