import { Box, Tooltip, Typography } from '@mui/material';
import {
  GridCellModes,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import {
  CurrencyField,
  CurrencyFieldProps,
} from '../../../../../../common/Ui/CurrencyField';
import { formatDate } from '../../../../../../common/Ui/FormDatePicker/FormDatePicker';
import { InvoicePaymentDetails } from '../../../../../../models/DTOs/Payment/DTOs';
import { useStores } from '../../../../../../store/root.context';
import Datepicker, {
  Props,
} from '../../../../../../ui-kit/components/Datepicker';
import BaseMultipleAutocomplete, {
  MultipleAutocompleteBaseTypes,
} from '../../../../../../ui-kit/components/MultipleAutocomplete';
import SingleAutocomplete, {
  BaseSingleAutocompleteTypes,
} from '../../../../../../ui-kit/components/SingleAutocomplete';
import { EMyLoadDetailsPanelTabs } from '../../../../../../common/LoadTabPanel/constants/constants';

// https://mui.com/x/react-data-grid/editing/#row-editing

export const GridRenderEditMultipleAutocompleteField = ({
  params,
  ...restProps
}: {
  params: GridRenderEditCellParams;
} & Partial<MultipleAutocompleteBaseTypes>) => {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();

  const onChangeCb = (data: any[], name: string) => {
    const newValue = data;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <BaseMultipleAutocomplete
      {...restProps}
      options={value}
      onChangeCb={onChangeCb}
      customStyles={{ width: '100%' }}
    />
  );
};

export const GridRenderEditSeqNumberObserver = observer(
  ({
    params,
    ...restProps
  }: {
    params: GridRenderEditCellParams;
  } & Partial<BaseSingleAutocompleteTypes<any>>) => {
    const {
      invoicePaymentStore: {
        getAvailableUnpaidInvoices,
        addNewRowTable,
        updateEdittingRow,
      },
    } = useStores();
    const { id, value, field } = params;
    const apiRef = useGridApiContext();

    const onChangeCb = (data: InvoicePaymentDetails, name: string) => {
      const newValue = data;
      if (newValue?.invoiceId) {
        apiRef.current.setEditCellValue({
          id: newValue.invoiceId, //Need to change to current selected invoiceId
          field,
          value: newValue,
        });
      } else {
        apiRef.current.setEditCellValue({
          id: id,
          field,
          value: newValue,
        });
      }
      restProps?.onChangeCb(newValue, name);
    };
    return (
      <SingleAutocomplete
        {...restProps}
        value={value}
        onChangeCb={onChangeCb}
        customStyles={{ width: '100%' }}
        autoFocus={true}
        fieldName={'invoiceSeqNumber'}
        options={toJS(getAvailableUnpaidInvoices)}
        onChangeCb={(row: InvoicePaymentDetails) => {
          updateEdittingRow(row, id as string);
        }}
      />
    );
  }
);

export const GridRenderEditDatePickerField = ({
  params,
  ...restProps
}: {
  params: GridRenderEditCellParams;
} & Partial<Props>) => {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();

  const onDateChange = (newDate: Moment | null, error: boolean) => {
    const newValue = new Date(formatDate(newDate, false, false, true));
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Datepicker
      {...restProps}
      initialDate={value}
      onDateChange={onDateChange}
      fullWidth={true}
      autoFocus={true}
    />
  );
};

export const GridRenderEditCurrencyField = ({
  params,
  ...restProps
}: {
  params: GridRenderEditCellParams;
} & Partial<CurrencyFieldProps>) => {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();

  const onChange = (event: Event) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };
  return (
    <CurrencyField
      {...restProps}
      value={value}
      onChange={onChange}
      fullWidth
      autoFocus={true}
    />
  );
};
export const GridRenderCellEditable = observer(
  ({
    params,
    value,
    toolTips,
  }: {
    params: GridRenderEditCellParams;
    value: JSX.Element;
    toolTips?: string;
  }) => {
    const {
      id,
      field,
      isEditable,
      colDef: { headerName },
      row,
    } = params;
    const apiRef = useGridApiContext();
    const {
      paymentStore,
      invoicePaymentStore: { addNewClicked },
    } = useStores();
    const { setSecondaryPanelData } = paymentStore;

    const onClick = (event: Event) => {
      if (!isEditable || (!id.includes('newInvoice') && field == 'seqNumber')) {
        if (!id.includes('newInvoice') && field == 'seqNumber') {
          setSecondaryPanelData({
            type: row.loadId ? 'LOAD' : 'INVOICE',
            id: row.loadId ? row.loadId : id,
            isGlobal: true,
            readonly: addNewClicked,
            defaultTab: EMyLoadDetailsPanelTabs.INVOICE,
          });
        }
        return;
      }
      apiRef.current?.setCellMode(id, field, GridCellModes.Edit);
      event.stopPropagation();
    };

    const isNewCellEmpty = params.row.isNew && !params.value;
    if (toolTips) {
      return (
        <Tooltip
          placement="bottom-start"
          arrow
          sx={{ height: '100%', width: '100%' }}
          title={toolTips}
        >
          <Box
            sx={{
              width: '100%',
              height: '20px',
            }}
            onClick={onClick}
          >
            {isNewCellEmpty ? (
              <Typography
                variant="body2"
                sx={{
                  ...(isNewCellEmpty && {
                    fontStyle: 'italic',
                    color: 'text.secondary',
                  }),
                }}
              >
                {headerName}
              </Typography>
            ) : (
              value
            )}
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <Box
          sx={{
            width: '100%',
            height: '20px',
          }}
          onClick={onClick}
        >
          {isNewCellEmpty ? (
            <Typography
              variant="body2"
              sx={{
                ...(isNewCellEmpty && {
                  fontStyle: 'italic',
                  color: 'text.secondary',
                }),
              }}
            >
              {headerName}
            </Typography>
          ) : (
            value
          )}
        </Box>
      );
    }
  }
);
