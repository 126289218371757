import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import { fontFamily, helperLinkColor } from '../../constants';
import { useImport } from '../../../../../contexts/ImportContext';

const HelperLink = ({
  text,
  icon,
  hasDownloadOption,
  linkURL,
}: {
  text: string;
  icon: ReactElement;
  hasDownloadOption: boolean;
  linkURL?: string | null;
}) => {
  const {
    currentEntity: { name },
    handleDownloadSampleFile,
  } = useImport();

  return (
    <Link
      sx={{ mt: 2, display: 'flex' }}
      download={hasDownloadOption}
      href={linkURL ? linkURL : null}
      underline="always"
      target="_blank"
      rel="noopener"
      onClick={() => handleDownloadSampleFile(name, hasDownloadOption)}
    >
      <Typography
        variant={'body2'}
        sx={{
          color: helperLinkColor,
          fontSize: 16,
          fontFamily: fontFamily,
          mr: 1.5,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        {text}
      </Typography>
      {icon}
    </Link>
  );
};

export default HelperLink;
