import { Theme } from '@mui/material';

export const TripDetailArrowContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto',
  alignItems: 'center',
};

export const titleStyles = (theme: Theme) => ({
  fontSize: '16px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: '#000',
});

export const darkTextStyles = (theme: Theme) => ({
  color: theme?.palette?.text?.primary,
  lineHeight: '160%',
  letterSpacing: '0.15px',
});

export const lightTextStyles = (theme: Theme) => ({
  color: theme?.palette?.text?.secondary,
  lineHeight: '160%',
  letterSpacing: '0.15px',
});

export const EvaluateDriverNameStyle = {
  fontSize: '16px',
  fontFamily: 'Poppins',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  ...darkTextStyles,
};

export const RatingContainerStyles = {
  background: '#F5F5F5',
  padding: '4px',
  borderRadius: '4px',
  width: 'fit-content',
};

export const KpiLabelStyles = (theme: Theme) => ({
  fontSize: '12px',
  wordBreak: 'break-word',
  ...darkTextStyles(theme),
});

export const kpiLabelContainerStyles = {
  // padding: '10px',
  border: '1px solid #E0E0E0',
  alignItems: 'center',
  display: 'flex',
  padding: '3px 10px',
};

export const EvaluationKpiViewButton = (theme: Theme) => ({
  background: '#F5F5F5',
  borderRadius: '6px',
  height: '28px',
  marginTop: '4px',
  fontSize: '13px',
  textTransform: 'capitalize',
  alignItems: 'center',
  ...darkTextStyles(theme),
  '*': {
    color: theme?.palette?.text?.primary,
  },
});

export const ActionBtnStyles = {
  borderRadius: '6px',
  height: '28px',
  fontSize: '13px',
  textTransform: 'capitalize',
  padding: '10px',
  display: 'flex',
  gap: '8px',
  width: 'fit-content',
};

export const ActionIconStyles = {
  width: '18px',
  height: '18px',
};

export const DriverNameStyles = (theme: Theme) => {
  return { color: `${theme?.palette?.text?.primary}` };
};

export const KpiUnitStyles = (theme: Theme) => ({
  fontSize: '10px',
  wordBreak: 'break-word',
  ...lightTextStyles(theme),
});

export const ContainerStyles = { padding: '10px', maxWidth: '460px' };

export const TripNumberStyles = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '70px',
};

export const IssuesTitleContainerStyles = (theme: Theme) => ({
  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), ${theme?.palette?.warning?.contrastText}`,
  border: `1px solid ${theme?.palette?.warning?.contrastText}`,
  display: 'flex',
  alignItems: 'flex-start',
  padding: '8px',
  borderRadius: '8px',
  marginBottom: '8px',
});
