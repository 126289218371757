import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CustomersList from '../../../ui-kit/components/CustomersList';
import React from 'react';
import { eldService } from '../../../api';
import {
  ELDRequest,
  RefreshAccountRequest,
} from '../../../models/DTOs/ELD/Requests';
import SelectedCustomer from '../../../subPages/integrations/eld/SelectedCustomer';
import {
  ICardData,
  IcustomerListWithLogo,
  IEldIntegrationProps,
  IEldIntegrationState,
  IproviderData,
} from '../../../types/ELDTypes';
import { customerMap } from '../../../utils/customerMap';

class EldIntegration extends React.Component<
  IEldIntegrationProps,
  IEldIntegrationState
> {
  constructor(props: IEldIntegrationProps) {
    super(props);
    this.state = {
      providerData: null,
      customerListWithLogo: [],
      selectedProviderDetails: {},
      activationStatus: '',
      userTerminalIds: [],
    };
  }

  getProviderDetails = async () => {
    const request = new ELDRequest();
    request.terminalIds = this.state.userTerminalIds;
    request.integrationType = 'ELD';
    await eldService.getEldProvdersList(request).then((res) => {
      this.setState({ providerData: res }, () => {
        this.getCustomerList(res);
      });
    });
  };

  componentDidMount() {
    this.getProviderDetails();
  }

  redirectToExistingProvider = () => {
    const stringVal = sessionStorage.getItem('SelectedELDProvider');
    const val = Number(stringVal);
    let data = {};
    customerMap.forEach((item) => {
      if (val === item.providerId) {
        data = item;
      }
    });
    this.setState({ selectedProviderDetails: data });
  };

  getCustomerList = (providerData: IproviderData): void => {
    const allCustomers: IcustomerListWithLogo[] = [];
    providerData?.providersStatusMap?.forEach(
      (data: { providerId: number; status: string }) => {
        if (data.providerId > 0) {
          customerMap.forEach((item) => {
            if (data.providerId === item.providerId) {
              const customer: any = {
                providerId: item.providerId,
                providerName: item.providerName,
                logo: item.logo,
                logoWhite: item.logoWhite,
                status: data.status,
                description: item.description,
                gradient: item.gradient,
              };
              allCustomers.push(customer);
            }
          });
        }
      }
    );
    this.setState({ customerListWithLogo: allCustomers }, () => {
      this.initialSelectedProvider(
        allCustomers && allCustomers.length > 0 && allCustomers[0]
      );
      this.getSearchParams();
    });
  };
  initialSelectedProvider = (customer: any) => {
    if (sessionStorage.getItem('SelectedELDProvider')) {
      this.redirectToExistingProvider();
    } else
      this.setState({
        selectedProviderDetails: customer,
      });
  };
  selectProvider = (data: IcustomerListWithLogo[]) => {
    this.setState({ selectedProviderDetails: data }, () => {
      sessionStorage.setItem(
        'SelectedELDProvider',
        (this.state.selectedProviderDetails?.providerId).toString()
      );
    });
  };

  getSearchParams = () => {
    const url = new URL(window.location.href);
    if (url.search) {
      const params = new URLSearchParams(url.search);
      const KeepTruckingCreds = sessionStorage.getItem('KeepTruckingCreds');
      const creds = JSON.parse(KeepTruckingCreds || '{}');
      if (creds?.data?.length > 0) {
        creds.data[1].value = params.get('code');
        creds !== '' && this.activateProvider(creds);
      }
    }
  };

  refreshAccountData = async (cardData: ICardData, suffixUrl: string) => {
    const request = new RefreshAccountRequest();
    request.credentialId = cardData.id;
    request.terminalIds = this.state.userTerminalIds;
    request.integrationType = 'ELD';
    await eldService.refreshAccount(request, suffixUrl).then((res) => {
      this.setState({ providerData: res });
    });
  };
  activateProvider = async (payload: any) => {
    sessionStorage.removeItem('KeepTruckingCreds');
    try {
      await eldService.activateProvider(payload);
      window.location.href = '/settings/integrations';
    } catch {
      window.location.href = '/settings/integrations';
    }
  };

  render() {
    return (
      <Box className="eld-container" sx={{ width: '100%' }}>
        {this.state.providerData && (
          <Paper sx={{ boxShadow: 'none' }}>
            <Grid container sx={{ height: '100%', overflow: 'hidden' }}>
              <Grid
                item
                xs={2}
                px={2}
                sx={{ height: '100%', overflow: 'auto' }}
              >
                <CustomersList
                  allCustomers={this.state.customerListWithLogo}
                  selectedProviderDetails={this.state.selectedProviderDetails}
                  selectProvider={this.selectProvider}
                />
              </Grid>
              <Grid
                item
                xs={10}
                className="selected-transporter-block"
                sx={{ height: '100%', overflow: 'auto' }}
              >
                <SelectedCustomer
                  currentCustomerData={this.state.selectedProviderDetails}
                  providerData={this.state.providerData}
                  getProviderDetails={this.getProviderDetails}
                  refreshAccountData={this.refreshAccountData}
                  customerListWithLogo={this.state.customerListWithLogo}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
      </Box>
    );
  }
}

export default EldIntegration;
