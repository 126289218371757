import moment from 'moment';
import { UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE } from '../constants/date.constants';
import { getUserLocalTimezone } from './';
import { getGridCompanyTimezoneSettings } from './Timezone.utils';

export const renderDateTime = (timeString: string, tz?: string): string => {
  if (!timeString) return '';

  const timezone =
    tz ||
    getGridCompanyTimezoneSettings().timezone ||
    getUserLocalTimezone() ||
    '';
  const zonedDate = timezone
    ? moment(timeString).tz(timezone)
    : moment(timeString);
  return zonedDate.format(UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE);
};
