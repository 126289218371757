import { debounce, throttle } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

interface UseFiltersAutoResizeProps {
  panelState?: boolean;
}

const useFiltersAutoResize = ({
  panelState,
}: UseFiltersAutoResizeProps = {}) => {
  const filterRefs = useRef<{ name: string; el: HTMLDivElement | null }[]>([]);
  const allFilterRef = useRef<HTMLDivElement | null>(null);
  const tableSettingsRef = useRef<HTMLButtonElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [hiddenColumns, setHiddenColumns] = useState<(string | undefined)[]>(
    []
  );

  const updateFilters = () => {
    const containerWidth =
      containerRef.current?.getBoundingClientRect().width || 0;
    const allFilterWidth =
      (allFilterRef.current?.getBoundingClientRect().width || 0) + 25;
    const tableSettingsWidth =
      tableSettingsRef.current?.getBoundingClientRect().width || 0;
    const filterWidths = filterRefs.current.map(
      ({ el }) => el?.getBoundingClientRect?.().width || 0
    );
    let breakPosition = filterWidths.length;
    const sum = (array: number[]) =>
      array.reduce((result, value) => (result += value), 0);
    while (
      containerWidth &&
      containerWidth - (allFilterWidth || 0) - (tableSettingsWidth || 0) <
        sum(filterWidths.slice(0, breakPosition)) &&
      breakPosition >= 1
    ) {
      breakPosition -= 1;
    }
    setHiddenColumns(
      filterWidths.map((_, index) => {
        if (breakPosition === undefined || index < breakPosition)
          return undefined;
        return filterRefs.current[index].name;
      })
    );
  };

  const onUpdateFilters = () => {
    updateFilters();
  };

  const onUpdateFiltersThrottle = useCallback(
    throttle(onUpdateFilters, 100), // Adjust the throttling interval as needed
    []
  );

  const onUpdateFiltersDebounce = useCallback(
    debounce(onUpdateFilters, 100), // Adjust the debounce interval as needed
    []
  );

  useEffect(() => {
    setHiddenColumns([]);
    onUpdateFiltersDebounce();
  }, [JSON.stringify(panelState)]);

  useEffect(() => {
    const onUpdateFiltersThrottleWrapper = () => {
      setHiddenColumns([]);
      onUpdateFiltersThrottle();
    };
    //Add  listener when window resized
    window.addEventListener('resize', onUpdateFiltersThrottleWrapper);

    setTimeout(() => {
      //Run 1 time only at first time access page
      onUpdateFiltersThrottleWrapper();
    }, 300);

    return () => {
      window.removeEventListener('resize', onUpdateFiltersThrottleWrapper);
    };
  }, []);

  return {
    filterRefs,
    allFilterRef,
    tableSettingsRef,
    hiddenColumns,
    containerRef,
  };
};

export default useFiltersAutoResize;

export const mergeHiddenColumns = (
  filterColumns: any[] = [],
  hiddenColumns: (string | undefined)[] = []
) =>
  filterColumns?.map((column) => {
    if (hiddenColumns.some((columnName) => columnName === column.name)) {
      return {
        ...column,
        default: false,
      };
    }
    return column;
  });
