import { Typography } from '@mui/material';

export default function WarningWindow({
  warningsDriver,
}: {
  warningsDriver: any;
}) {
  if (
    !warningsDriver.primaryWarnings.length &&
    !warningsDriver.secondaryWarnings.length
  )
    return null;

  return (
    <ul
      style={{
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),#FFA7267F',
        borderRadius: '7px',
        padding: '16px',
        listStyle: 'initial',
        paddingLeft: '18px',
      }}
    >
      {warningsDriver.primaryWarnings.toString() &&
        warningsDriver.secondaryDriver && (
          <Typography variant="subtitle2">
            {warningsDriver.primaryDriver}
          </Typography>
        )}
      {warningsDriver.primaryWarnings?.map((w, i) => (
        <li key={i} style={{ marginLeft: '25px', marginBottom: '5px' }}>
          <Typography variant="body2">{w}</Typography>
        </li>
      ))}
      {warningsDriver.secondaryWarnings.toString() && (
        <Typography variant="subtitle2">
          {warningsDriver.secondaryDriver}
        </Typography>
      )}
      {warningsDriver.secondaryWarnings?.map((w, i) => (
        <li key={i} style={{ marginLeft: '25px' }}>
          <Typography variant="body2">{w}</Typography>
        </li>
      ))}
    </ul>
  );
}
