import Grid from '@mui/material/Grid';
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import FormDateTimePicker from '../../../../../../common/Ui/FormDateTimePicker/FormDateTimePicker';
import TextField from '../../../../../../common/Ui/TextField/TextField';
import { useRootStore } from '../../../../../../store/root-store/rootStateContext';

export const CompleteLoadForm = memo(
  ({ entity }: { entity: 'Load' | 'Trip' | 'Manifest' }): JSX.Element => {
    const { control } = useFormContext();
    const { getCompanyPreferences } = useRootStore();

    return (
      <Grid spacing={1} container>
        <FormDateTimePicker
          sizes={{
            xs: 12,
          }}
          styleProps={{
            width: '100%',
          }}
          disableFuture={true}
          required
          control={control}
          label="Completion Date-Time"
          name={`completionDate`}
          timezone={getCompanyPreferences?.timezone}
        />
        {entity === 'Load' && (
          <TextField
            sizes={{
              xs: 12,
            }}
            control={control}
            label="Invoice Notes"
            name={`invoiceNotes`}
          />
        )}
      </Grid>
    );
  }
);
CompleteLoadForm.displayName = 'CompleteLoadForm';
