import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC } from 'react';
import { ediService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import HyperLink from '../../../common/Ui/HyperLink/HyperLink';
import { ELocationLevel } from '../../../common/Ui/LocationSelect';
import LocationSelectFilter from '../../../common/Ui/LocationSelect/LocationSelectFilter';
import { LoadStatus } from '../../../common/Ui/StatusComponent/StatusComponent';
import {
  EDITenderDTO,
  GetFilterListRequest,
} from '../../../models/DTOs/EDI/Requests';
import { GridColDefSelf } from '../../../ui-kit/components/DataGridPro';
import { numberWithThousandSeparator } from '../../../utils';
import { renderDateTime } from '../../../utils/NameAndDateTimeGridField';
import { EEDITendersEntity } from '../constants/customView';
import { EDITendersFieldsConfig } from '../constants/grid.constants';
import { EEDITenderSource, IEDITendersFilters } from '../constants/types';
import Actions from './Grid/Cell/Actions';
import PickupNDropoffDateTime from './Grid/Cell/PickupNDropoffDateTime';
import RespondBy from './Grid/Cell/RespondBy';

export const defaultEDITendersGridFilters: Partial<IEDITendersFilters> = {
  [EDITendersFieldsConfig.customer.fieldFilterName]: [],
  [EDITendersFieldsConfig.pickup.fieldFilterName]: {
    locationLevel: ELocationLevel.City,
  },
  [EDITendersFieldsConfig.dropoff.fieldFilterName]: {
    locationLevel: ELocationLevel.City,
  },
};

interface GridProps {
  [key: string]: any;
}

export interface SortField {
  field: string;
  label: string;
  sortingKey: string;
  type?: 'number' | 'string' | 'date';
  isDefault?: boolean;
}

export interface EDITendersGridColDefSelf extends GridColDefSelf {
  sortFields?: SortField[];
  views: EEDITendersEntity[];
}

interface EDITendersConfig {
  columns: EDITendersGridColDefSelf[];
  title: string;
  getFiltersFormConfig: () => {
    key: number;
    default: boolean;
    label: string;
    name: keyof IEDITendersFilters;
    type:
      | 'MultipleAutocomplete'
      | 'SingleAutocomplete'
      | 'DateRangePicker'
      | 'RelativeDateRange'
      | 'DatePicker';
    customComponent?: FC<any>;
    [key: string]: any;
  }[];
}

export default class EDITendersGridConfig {
  name: string;

  config: EDITendersConfig;

  constructor(props: GridProps) {
    this.name = 'Loadboard GridConfiguration';
    this.config = EDITendersGridConfig.getConfiguration(props);
  }

  static getConfiguration(props: GridProps) {
    return EDITendersGridConfig.getDefaultConfiguration(props);
  }

  static getDefaultConfiguration(props?: GridProps): EDITendersConfig {
    return {
      title: 'EDITenders grid',
      columns: [
        {
          field: EDITendersFieldsConfig.source.fieldName,
          headerName: EDITendersFieldsConfig.source.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.loadID.fieldName,
          headerName: EDITendersFieldsConfig.loadID.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          isHyperLink: true,
          views: [EEDITendersEntity.ACCEPTED],
          renderCell: (
            params: GridRenderCellParams<EDITenderDTO['loadId'], EDITenderDTO>
          ) => {
            return (
              <HyperLink value={params.row.seqNumber || params.value || ''} />
            );
          },
        },
        {
          field: EDITendersFieldsConfig.loadStatus.fieldName,
          headerName: EDITendersFieldsConfig.loadStatus.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          views: [EEDITendersEntity.ACCEPTED],
          renderCell: (
            params: GridRenderCellParams<
              EDITenderDTO['loadStatus'],
              EDITenderDTO
            >
          ) => {
            if (!params.value) return null;
            return <LoadStatus status={params.value} />;
          },
        },
        {
          field: EDITendersFieldsConfig.customer.fieldName,
          headerName: EDITendersFieldsConfig.customer.label,
          minWidth: 180,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params?.value?.name ? params?.value?.name : '';
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.respondBy.fieldName,
          headerName: EDITendersFieldsConfig.respondBy.label,
          minWidth: 180,
          flex: 1,
          sortable: false,
          renderCell: (
            params: GridRenderCellParams<
              EDITenderDTO['respondBy'],
              EDITenderDTO
            >
          ) => <RespondBy value={params.value} />,
          views: [EEDITendersEntity.NEW, EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.status.fieldName,
          headerName: EDITendersFieldsConfig.status.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.offer.fieldName,
          headerName: EDITendersFieldsConfig.offer.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            let amount = '';
            if (params?.row?.source === EEDITenderSource.turvo) {
              if (
                params.row.quotes &&
                Array.isArray(params.row?.quotes) &&
                params.row?.quotes?.length > 0 &&
                params.row?.quotes[0]?.amount
              ) {
                amount = `$${numberWithThousandSeparator(
                  params.row?.quotes[0]?.amount
                )}`;
              }
              return amount;
            } else {
              return params.value
                ? `$${numberWithThousandSeparator(params.value)}`
                : '';
            }
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.shipmentId.fieldName,
          headerName: EDITendersFieldsConfig.shipmentId.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            if (params?.row?.source === EEDITenderSource.turvo) {
              return params?.row?.customId;
            } else {
              return params.value;
            }
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.acceptDateandTime.fieldName,
          headerName: EDITendersFieldsConfig.acceptDateandTime.label,
          minWidth: 180,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params.value && renderDateTime(params.value);
          },
          views: [EEDITendersEntity.ACCEPTED],
        },
        {
          field: EDITendersFieldsConfig.declinedDateTime.fieldName,
          headerName: EDITendersFieldsConfig.declinedDateTime.label,
          minWidth: 180,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params.value && renderDateTime(params.value);
          },
          views: [EEDITendersEntity.DECLINED],
        },
        {
          field: EDITendersFieldsConfig.cancelledDateTime.fieldName,
          headerName: EDITendersFieldsConfig.cancelledDateTime.label,
          minWidth: 180,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params.value && renderDateTime(params.value);
          },
          views: [EEDITendersEntity.CANCELED],
        },
        {
          field: EDITendersFieldsConfig.pickupDateandTime.fieldName,
          headerName: EDITendersFieldsConfig.pickupDateandTime.label,
          minWidth: 180,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams<any, EDITenderDTO>) => {
            return (
              <PickupNDropoffDateTime
                value={params.row.stops?.[0]?.appointmentStartDate}
                timezone={params.row.stops?.[0]?.timezone}
              />
            );
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.pickupCity.fieldName,
          headerName: EDITendersFieldsConfig.pickupCity.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params.row.stops?.[0]?.city;
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.pickupState.fieldName,
          headerName: EDITendersFieldsConfig.pickupState.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params.row.stops?.[0]?.state;
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.pickupZipcode.fieldName,
          headerName: EDITendersFieldsConfig.pickupZipcode.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params.row.stops?.[0]?.zip;
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.totalStops.fieldName,
          headerName: EDITendersFieldsConfig.totalStops.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.dropoffDateandTime.fieldName,
          headerName: EDITendersFieldsConfig.dropoffDateandTime.label,
          minWidth: 180,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams<any, EDITenderDTO>) => {
            return (
              <PickupNDropoffDateTime
                value={
                  params.row.stops?.[params.row.stops.length - 1]
                    ?.appointmentEndDate
                }
                timezone={
                  params.row.stops?.[params.row.stops.length - 1]?.timezone
                }
              />
            );
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.dropoffCity.fieldName,
          headerName: EDITendersFieldsConfig.dropoffCity.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params.row.stops?.[params.row.stops.length - 1]?.city;
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.dropoffState.fieldName,
          headerName: EDITendersFieldsConfig.dropoffState.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params.row.stops?.[params.row.stops.length - 1]?.state;
          },
          views: [EEDITendersEntity.ALL],
        },
        {
          field: EDITendersFieldsConfig.dropoffZipcode.fieldName,
          headerName: EDITendersFieldsConfig.dropoffZipcode.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return params.row.stops?.[params.row.stops.length - 1]?.zip;
          },
          views: [EEDITendersEntity.ALL],
        },
        // {
        //   field: EDITendersFieldsConfig.loadedMiles.fieldName,
        //   headerName: EDITendersFieldsConfig.loadedMiles.label,
        //   minWidth: 100,
        //   flex: 1,
        //   sortable: false,
        //   views: [EEDITendersEntity.ALL],
        // },
        // {
        //   field: EDITendersFieldsConfig.marketRate.fieldName,
        //   headerName: EDITendersFieldsConfig.marketRate.label,
        //   minWidth: 100,
        //   flex: 1,
        //   sortable: false,
        //   renderCell: (params: GridRenderCellParams) => {
        //     return [currencyDisplay(params.row.rate), '/', params.row.rateType]
        //       .filter(String)
        //       .join('');
        //   },
        //   views: [EEDITendersEntity.ALL],
        // },
        {
          sortable: false,
          field: EDITendersFieldsConfig.actions.fieldName,
          headerName: EDITendersFieldsConfig.actions.label,
          width: 200,
          minWidth: 150,
          maxWidth: 200,
          permanent: true,
          renderCell: (params: GridRenderCellParams<any, EDITenderDTO>) => {
            return <Actions data={params.row} />;
          },
          views: [
            EEDITendersEntity.NEW,
            EEDITendersEntity.ACCEPTED,
            EEDITendersEntity.DECLINED,
          ],
        },
      ],
      getFiltersFormConfig: () => [
        {
          key: 1,
          default: true,
          label: EDITendersFieldsConfig.customer.label,
          name: EDITendersFieldsConfig.customer.fieldFilterName,
          getData: async () => {
            const response = await ediService.getFilterList(
              new GetFilterListRequest()
            );
            if (!response || response instanceof ServiceError) {
              return [];
            }
            return {
              content: response.customers.map(({ name, customerIsa }) => ({
                value: customerIsa,
                label: name,
              })),
              last: true,
            };
          },
          type: 'MultipleAutocomplete',
          fieldName: 'label',
        },
        {
          key: 2,
          default: true,
          label: EDITendersFieldsConfig.pickup.label,
          name: EDITendersFieldsConfig.pickup.fieldFilterName,
          type: 'Custom',
          customComponent: LocationSelectFilter,
          defaultValue:
            defaultEDITendersGridFilters[
              EDITendersFieldsConfig.pickup.fieldFilterName
            ],
          isSwapZoneToZip: true,
        },
        {
          key: 4,
          default: true,
          label: EDITendersFieldsConfig.dropoff.label,
          name: EDITendersFieldsConfig.dropoff.fieldFilterName,
          type: 'Custom',
          customComponent: LocationSelectFilter,
          defaultValue:
            defaultEDITendersGridFilters[
              EDITendersFieldsConfig.pickup.fieldFilterName
            ],
          isSwapZoneToZip: true,
        },
        {
          key: 5,
          label: EDITendersFieldsConfig.shipmentId.label,
          name: EDITendersFieldsConfig.shipmentId.fieldFilterName,
          getData: async () => {
            const response = await ediService.getFilterList(
              new GetFilterListRequest()
            );
            if (!response || response instanceof ServiceError) {
              return [];
            }
            return {
              content: response.shipmentId.map((value) => ({
                value,
              })),
              last: true,
            };
          },
          type: 'MultipleAutocomplete',
          fieldName: 'value',
        },
        {
          key: 6,
          label: EDITendersFieldsConfig.respondBy.label,
          name: EDITendersFieldsConfig.respondBy.fieldFilterName,
          getData: [],
          type: 'DatePicker',
        },
      ],
    };
  }
}

export const toExportExcelColumn = (
  fieldName: string,
  view?: EEDITendersEntity
): string => {
  if (
    [
      EDITendersFieldsConfig.acceptDateandTime.fieldName,
      EDITendersFieldsConfig.declinedDateTime.fieldName,
      EDITendersFieldsConfig.cancelledDateTime.fieldName,
    ].includes(fieldName)
  ) {
    switch (view) {
      case EEDITendersEntity.ACCEPTED:
        return 'acceptDateTime';
      case EEDITendersEntity.DECLINED:
        return 'declinedDateTime';
      case EEDITendersEntity.CANCELED:
        return 'cancelDateTime';
      default:
        return fieldName;
    }
  }
  return exportExcelFieldMapping[fieldName] || fieldName;
};

export const exportExcelFieldMapping = {
  [EDITendersFieldsConfig.customer.fieldName]: 'customer',
  [EDITendersFieldsConfig.respondBy.fieldName]: 'respondBy',
  [EDITendersFieldsConfig.status.fieldName]: 'status',
  [EDITendersFieldsConfig.shipmentId.fieldName]: 'shipmentId',
  [EDITendersFieldsConfig.pickupDateandTime.fieldName]: 'pickupDateTime',
  [EDITendersFieldsConfig.pickupCity.fieldName]: 'pickupCity',
  [EDITendersFieldsConfig.pickupState.fieldName]: 'pickupState',
  [EDITendersFieldsConfig.pickupZipcode.fieldName]: 'pickupZip',
  [EDITendersFieldsConfig.dropoffDateandTime.fieldName]: 'dropffDateTime',
  [EDITendersFieldsConfig.dropoffCity.fieldName]: 'dropffCity',
  [EDITendersFieldsConfig.dropoffState.fieldName]: 'dropffState',
  [EDITendersFieldsConfig.dropoffZipcode.fieldName]: 'dropffZip',
  [EDITendersFieldsConfig.totalStops.fieldName]: 'totalStops',
  [EDITendersFieldsConfig.acceptDateandTime.fieldName]: 'acceptDateTime',
  [EDITendersFieldsConfig.declinedDateTime.fieldName]: 'declinedDateTime',
  [EDITendersFieldsConfig.cancelledDateTime.fieldName]: 'cancelDateTime',
  [EDITendersFieldsConfig.loadID.fieldName]: 'loadId',
  [EDITendersFieldsConfig.loadStatus.fieldName]: 'loadStatus',
};
