import { Moment } from 'moment';
import { StaticDataType } from '../../../contexts/StaticDataContext';
import TerminalShort from '../../../models/common/TerminalShort';
import { FileType, LineItem, PaginatedList } from '../../../types';
import {
  calculateNetExpense,
  calculateSettlementAmount,
} from '../../../utils/expense';
import { getTerminalById } from '../../../utils/terminal';
import { ESecondaryDetailsPanelType } from '../../../views/dispatch/constants/types';
import { MaintenanceData } from '../../common/modelsShort';
import {
  PayItemDTO,
  PayStatementsTypeEnum,
  PayStatementsTypeTextEnum,
} from '../Payments/Payments';
import { OptionType } from '../commonMixed';

export class ExpensesListDataCount {
  allCount!: number | null;
  fuelCount!: number | null;
  otherCount!: number | null;
}

export enum ExpensesEntityTypeEnum {
  allExpenses = 'allExpenses',
  fuelExpense = 'fuelExpense',
  otherExpense = 'otherExpense',
}

type objectType = {
  name?: string;
  value?: string;
  seqNumber?: string;
  id?: string | number;
  itemCode?: string;
  itemName?: string;
  payCategoryName?: string;
  payCategoryKey?: string;
};

export class Payment {
  amount?: number;
  description: string;
  driverId?: number;
  driverName?: string;
  tractorId?: number;
  tractorName?: string;
  expenseCategoryId: string;
  expenseCategoryKey: string;
  expenseId: string;
  expenseSeqNumber: number;
  fuelQuantity: number;
  fuelType: string;
  id: number;
  loadId: string;
  payCategory: string;
  payCategoryName: string;
  payDate: string;
  settlementId: number;
  settlementSeqNumber: number;
  settlementStatus: string;
  state: string;
  truckStop: string;
  paymentType: ExpenseEntityType;
  paymentTypeName: string;
  payingEntityObj: objectType | undefined;
  constructor(data: any) {
    this.amount = Math.abs(data?.amount);
    this.description = data?.description;
    this.expenseCategoryId = data?.expenseCategoryId;
    this.expenseCategoryKey = data?.expenseCategoryKey;
    this.expenseId = data?.expenseId;
    this.expenseSeqNumber = data?.expenseSeqNumber;
    this.fuelQuantity = data?.fuelQuantity;
    this.fuelType = data?.fuelType;
    this.id = data?.id;
    this.loadId = data?.loadId;
    this.payCategory = data?.payCategory;
    this.payCategoryName = data?.payCategoryName;
    this.payDate = data?.payDate;
    this.settlementId = data?.settlementId;
    this.settlementSeqNumber = data?.settlementSeqNumber;
    this.settlementStatus = data?.settlementStatus;
    this.state = data?.state;
    this.truckStop = data?.truckStop;
    this.paymentType = data?.driverId
      ? PayStatementsTypeEnum.driver
      : PayStatementsTypeEnum.tractor;
    this.paymentTypeName = data?.driverId
      ? PayStatementsTypeTextEnum.driver
      : PayStatementsTypeTextEnum.tractor;
    this.payingEntityObj = { id: data?.entityId, name: data?.entityName };
  }
}

export type ExpenseEntityType = 'DRIVER' | 'TRACTOR' | 'DISPATCHER';

export class LoadAppointmentDetailsDTO {
  pickupDate?: Date;
  pickupTimeZone?: string;
  dropoffDate?: Date;
  dropoffTimeZone?: string;
  loadStatus?: any;
  seqNumber?: string;
  originCityStateList?: string;
  destinationCityStateList?: string;
  terminalId?: string;
  revenue?: number;
  referenceId?: string;
  connectedLoads?: Array<any>;
}

export type loadsIdAndNameType = {
  loadIds: string[];
  seqNumber: string;
};

export enum ExpenseEntityEnum {
  DRIVER = 'DRIVER',
  TRATOR = 'TRACTOR',
  DISPATCHER = 'DISPATCHER',
}

export class Expense {
  id?: string;
  seqNumber?: string;
  paidDate!: Moment;
  status!: string;
  category?: string;
  categoryName?: string;
  equipmentType?: string;
  equipmentName?: objectType;
  equipmentId?: number;
  driverName?: string;
  companyExpense!: boolean;
  grossAmount?: number;
  finalAmount: number;
  discountAmount?: number;
  settlementAmount!: number;
  amount!: number;
  payments?: Payment[];
  driverPays?: Payment[];
  tractorPays?: Payment[];
  paidBy!: string;
  paidTo!: string;
  createdBy?: string;
  lastUpdated?: string;
  referenceNumber!: string;
  description!: string;
  companyExpenseName?: string;
  assignedDocuments?: FileType[];
  loadsIdAndName?: loadsIdAndNameType[];
  maintenanceDetails!: MaintenanceData | null;
  fuelQuantity?: number | string;
  cardNumber?: string;
  constructor(data?: any) {
    this.id = data?.id;
    this.seqNumber = data?.seqNumber;
    this.paidDate = data?.paidDate;
    this.status = data?.status;
    this.category = data?.category;
    this.categoryName = data?.categoryName;
    this.equipmentName = data?.equipmentObj?.name || data?.equipmentName;
    this.equipmentType = data?.equipmentType || 'TRACTOR';
    this.driverName = data?.driverName;
    this.equipmentId = data?.equipmentId;
    this.companyExpense = data?.companyExpense;
    this.companyExpenseName = data?.companyExpense
      ? 'companyExpense'
      : 'nonCompanyExpense';

    this.payments = [];
    this.driverPays = data?.driverPays?.map(
      (driverPay: PayItemDTO) =>
        new PayItemDTO({ ...driverPay, isAmountRequiredInAbs: true })
    );

    this.tractorPays = data?.tractorPays?.map(
      (tractorPay: PayItemDTO) =>
        new PayItemDTO({ ...tractorPay, isAmountRequiredInAbs: true })
    );
    if (!!this.driverPays?.length) {
      this.payments = [...this.payments, ...this.driverPays];
    }
    if (!!this.tractorPays?.length) {
      this.payments = [...this.payments, ...this.tractorPays];
    }

    this.grossAmount = data?.grossAmount;
    this.discountAmount = data?.discountAmount;

    this.settlementAmount = calculateSettlementAmount(this.payments);
    this.amount = data?.amount;
    this.finalAmount = calculateNetExpense(
      this.companyExpense,
      this.amount,
      this.settlementAmount
    );
    this.paidBy = data?.paidByObj?.name || data?.paidBy;
    this.paidTo = data?.paidToObj?.name || data?.paidTo;
    this.createdBy = data?.createdBy;
    this.lastUpdated = data?.lastUpdated;
    this.referenceNumber = data?.referenceNumber;
    this.description = data?.description;
    this.maintenanceDetails = data?.maintenanceDetails;
    this.assignedDocuments = !!data?.assignedDocuments?.length
      ? [
          {
            ...data?.assignedDocuments[0],
            name: data?.assignedDocuments[0]?.fileName,
          },
        ]
      : undefined;
    this.loadsIdAndName = data?.loadsIdAndName || [];
    this.fuelQuantity = data?.fuelQuantity || '';
    this.cardNumber = data?.cardNumber || '';
  }
}
export class ExpenseDetails extends Expense {
  fuelType?: objectType | null;

  terminalId?: string;
  terminal?: TerminalShort | null;
  truckstopAddress?: string;
  state!: objectType | null;

  paidByObj!: objectType | null;
  paidToObj!: objectType | null;
  equipmentObj!: objectType | null;
  categoryObj!: objectType | null;
  payCategoryObj!: objectType | null;
  statusValue?: boolean;
  driverObj!: objectType | null;
  constructor(
    data?: any,
    terminals?: TerminalShort[],
    staticData?: StaticDataType
  ) {
    super(data);
    this.fuelType =
      staticData?.fuelTypes?.find(
        (item) => item.key === data?.fuelType || item.value === data?.fuelType
      ) || null;

    this.terminalId = data?.terminalId;

    this.terminal =
      data?.terminal || getTerminalById(data?.terminalId, terminals);
    this.truckstopAddress = data?.truckstopAddress;
    this.state = data?.state ? { value: data?.state } : null;

    this.paidByObj = data?.paidBy
      ? { name: data?.paidBy, id: data?.paidById }
      : null;
    this.paidToObj = data?.paidTo
      ? { name: data?.paidTo, id: data?.paidToId }
      : null;
    this.categoryObj = data?.category
      ? {
          itemName: data?.categoryName,
          id: data?.expenseCategoryId,
          itemCode: data?.category,
        }
      : null;

    this.equipmentObj = data?.equipmentId
      ? { name: data?.equipmentName, id: data?.equipmentId }
      : null;
    this.statusValue = data?.status === 'IN_REVIEW';
    this.payCategoryObj = data?.companyExpense
      ? { payCategoryName: 'Deduction', payCategoryKey: 'DEDUCTION' }
      : { payCategoryName: 'Reimbursement', payCategoryKey: 'REIMBURSEMENT' };
    this.driverObj = data?.driverId
      ? { name: data?.driverName, id: data?.driverId }
      : null;
  }
}

export class PaginatedExpenseList extends PaginatedList {
  content!: Expense[];
}

export type ExpenseListTotalsType = {
  totalAmount: number;
  totalFinalAmount: number;
  totalSettlementAmount: number;
};

type ExpenseCategories = {
  custom: boolean;
  description: string;
  id: string;
  itemCode: string;
  itemName: string;
};
export class PaginatedExpenseCategoriesList extends PaginatedList {
  content?: ExpenseCategories[];
}

export type VendorNamesType = {
  name: string;
  id: number;
};

export class PaginatedVendorNamesList extends PaginatedList {
  content?: VendorNamesType[];
}
export type FuelTypesType = {
  key: string;
  supportIFTA: boolean;
  value: string;
  type: string;
  categoryName: string;
};

export type ExpensesStaticDataType = {
  stateOptions: OptionType[];
  documentTypes: LineItem[];
};
export type SetSecondaryPanelDataType = {
  type: ESecondaryDetailsPanelType;
  id: number | string;
  entity?: string;
  entityId?: number;
  seqNumber?: number;
  isGlobal?: boolean;
};
