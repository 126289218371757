import { Stack, Typography, Box, IconButton } from '@mui/material';
import title from '../../../common/Popover/maintenanceRecords/title';
import { CloseIcon } from '../../../ui-kit/components/Assets';

const CreateContractTitle = ({ title, onClose }: any) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      sx={{
        pl: 2,
        borderRadius: '15px',
        // background: '#35446B',
        position: 'relative',
      }}
    >
      <Typography sx={{ color: '#ffffff' }} fontFamily={'oxanium'}>
        {title}
      </Typography>
      <Box
        sx={{ height: '4vh' }}
        display={'flex'}
        flexDirection={'row-reverse'}
        flexGrow={1}
      >
        <IconButton
          onClick={() => onClose()}
          size="small"
          sx={{ paddingRight: '1.5rem', pt: '.5rem' }}
        >
          <CloseIcon height={16} width={16} />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default CreateContractTitle;
