import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  DriverTeamWithTrips,
  FetchDriverGroupDataWithTripsRequest,
  GetDriverTeamRequest,
  ImplicitAssignUpdateRequest,
  PaginatedAllTeamDriverListRequest,
  TeamDriver,
  TeamDriverFilterObject,
  TeamDriverPaginatedRequest,
  TeamDriverPaginatedRespones,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { PaginatedResult } from '../../types';
import {
  ITeamDriverService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';
import { eldDriverSummary } from './ELDService';
import {
  creatTeamDriverURL,
  getDriverGroupDetailsSearchURL,
  getDriverGroupSearchURL,
  getListDataTractorURL,
  getListDataTrailerURL,
  implicitAssignURL,
  teamDriverActive,
  tractorAssign,
  tractorUnassign,
  trailerAssign,
  trailerUnassign,
  validate,
  validateWarnings,
} from './requestConstants';

export class TeamDriverService extends ITeamDriverService {
  @annotateNameAsync
  async getTeamDriverList(
    requestData: PaginatedAllTeamDriverListRequest,
    isDetailsUrl = false
  ): Promise<PaginatedResult<TeamDriver> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        isDetailsUrl ? getDriverGroupDetailsSearchURL : getDriverGroupSearchURL,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async fetchDriverGroupDataWithTrips(
    requestData: FetchDriverGroupDataWithTripsRequest
  ): Promise<DriverTeamWithTrips | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        'web/load/api/v2/timeline/group/trip',
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverTeamFilterList(
    requestData: GetDriverTeamRequest,
    skipTrackPromise = false
  ): Promise<PaginatedResult<TeamDriverFilterObject> | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        eldDriverSummary,
        requestData,
        false,
        skipTrackPromise
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async getDriverTeamTractorList(requestData: GetDriverTeamRequest) {
    try {
      return await httpClient.getRaw(getListDataTractorURL, requestData);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      return Promise.resolve(null);
    }
  }
  @annotateNameAsync
  async getDriverTeamTrailerList(requestData: GetDriverTeamRequest) {
    try {
      return await httpClient.getRaw(getListDataTrailerURL, requestData);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async creatTeamDriver(requestData: TeamDriverPaginatedRequest) {
    try {
      const response = await httpClient.post<TeamDriverPaginatedRespones>(
        creatTeamDriverURL,
        undefined,
        requestData,
        TeamDriverPaginatedRespones
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'teamGroupCreation',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        message:
          error?.message?.response?.data?.message || 'Sorry Please try again',
      });
      throw error;
    }
  }
  @annotateNameAsync
  async getValidateWarning(requestData: TeamDriverPaginatedRequest) {
    try {
      return await httpClient.post<TeamDriverPaginatedRespones>(
        validate,
        undefined,
        requestData,
        TeamDriverPaginatedRespones
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async deleteTeamDriver(URL: string) {
    try {
      const response = await httpClient.delete<string>(
        URL,
        undefined,
        URL,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'teamGroupDelete',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async teamDriverActive(payloadData: TeamDriverPaginatedRequest) {
    try {
      const response = await httpClient.put<TeamDriverPaginatedRespones>(
        teamDriverActive,
        undefined,
        payloadData,
        TeamDriverPaginatedRespones,
        false,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'teamGroupUpdate',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async assignTractor(requestData: TeamDriverPaginatedRequest) {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await httpClient.post<TeamDriverPaginatedRespones>(
        tractorAssign,
        undefined,
        requestData,
        TeamDriverPaginatedRespones,
        false,
        headers,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverTractorAssign',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      return Promise.resolve(null);
    }
  }
  @annotateNameAsync
  async unassignTractor(requestData: TeamDriverPaginatedRequest) {
    const headers = { 'Content-Type': 'application/json' };

    try {
      const response = await httpClient.post<TeamDriverPaginatedRespones>(
        tractorUnassign,
        undefined,
        requestData,
        TeamDriverPaginatedRespones,
        false,
        headers,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverTractorUnAssign',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      return Promise.resolve(null);
    }
  }
  @annotateNameAsync
  async assignTrailer(requestData: TeamDriverPaginatedRequest) {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await httpClient.post<TeamDriverPaginatedRespones>(
        trailerAssign,
        undefined,
        requestData,
        TeamDriverPaginatedRespones,
        false,
        headers,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverTrailerAssign',
      });
      return response;
    } catch (error) {
      return Promise.resolve(null);
    }
  }
  @annotateNameAsync
  async unassignTrailer(requestData: TeamDriverPaginatedRequest) {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await httpClient.post<TeamDriverPaginatedRespones>(
        trailerUnassign,
        undefined,
        requestData,
        TeamDriverPaginatedRespones,
        false,
        headers,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverTrailerUnAssign',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getValidateWarningsRecord(payloadData: TeamDriverPaginatedRequest) {
    try {
      return await httpClient.put<TeamDriverPaginatedRespones>(
        validateWarnings,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async implicitAssignUpdate(
    requestData: ImplicitAssignUpdateRequest
  ): Promise<string | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        implicitAssignURL,
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
