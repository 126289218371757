import {
  FuelMatrixType,
  Matrix,
  PreferencesFormType,
  PreferencesTabStripType,
} from '../../../models';
import * as yup from 'yup';
import {
  nullableNumberYup,
  nullableStringYup,
  phoneNumberValidation,
} from '../../../utils';
import { emailValidation } from '../../../views/settings/terminals/constants/constants';
import { object } from 'yup';

export const FINANCE_NAME = 'FINANCE_NAME';
export const INVOICE_NAME = 'INVOICE_NAME';
export const LOAD_NAME = 'LOAD_NAME';
export const DRIVER_NAME = 'DRIVER_NAME';
export const COMPANY_NAME = 'COMPANY_NAME';
export const SETTINGS_NAME = 'SETTINGS_NAME';
export const FUEL_PRICE_TYPE = 'FUEL_PRICE_TYPE';

export const financeType: PreferencesTabStripType = {
  label: 'Finance',
  key: 'finance',
  iconName: 'RequestQuoteOutlined',
  source: 'MUIcons',
  name: FINANCE_NAME,
};

export const invoiceType: PreferencesTabStripType = {
  label: 'Invoicing',
  key: 'invoice',
  iconName: 'ReceiptLongOutlined',
  source: 'MUIcons',
  name: INVOICE_NAME,
};

export const loadType: PreferencesTabStripType = {
  label: 'Loads',
  key: 'load',
  iconName: 'LoadsIconTemp',
  source: 'AxeleIcon',
  name: LOAD_NAME,
};

export const driverType: PreferencesTabStripType = {
  label: 'Driver',
  key: 'driver',
  iconName: 'PersonOutlined',
  source: 'MUIcons',
  name: DRIVER_NAME,
};

export const companyType: PreferencesTabStripType = {
  label: 'Company Info',
  key: 'company',
  iconName: 'ApartmentOutlined',
  source: 'MUIcons',
  name: COMPANY_NAME,
};

export const settingsType: PreferencesTabStripType = {
  label: 'Misc.',
  key: 'settings',
  iconName: 'SettingsOutlined',
  source: 'MUIcons',
  name: SETTINGS_NAME,
};

export const fuelPriceType: PreferencesTabStripType = {
  label: 'Fuel Price',
  key: 'fuelPrice',
  iconName: 'SettingsOutlined',
  source: 'MUIcons',
  name: FUEL_PRICE_TYPE,
};

export const entities: PreferencesTabStripType[] = [
  financeType,
  invoiceType,
  loadType,
  driverType,
  companyType,
  settingsType,
  fuelPriceType,
];

export const entityMap: Record<string, PreferencesTabStripType> = {
  [FINANCE_NAME]: financeType,
  [INVOICE_NAME]: invoiceType,
  [LOAD_NAME]: loadType,
  [DRIVER_NAME]: driverType,
  [COMPANY_NAME]: companyType,
  [SETTINGS_NAME]: settingsType,
  [FUEL_PRICE_TYPE]: fuelPriceType,
};

const matrixDefaultValues: {
  [key: string]: Omit<FuelMatrixType, 'region'>;
} = {};

Object.keys(Matrix).forEach((value, i) => {
  matrixDefaultValues[value] = {
    price: '0',
    useNationalAverage: false,
    organizationId: '1',
    id: i.toString(),
    defaultPrice: '0',
  };
});

export const formDefaultValues: PreferencesFormType = {
  // finance
  dashboardPreferences: {
    targetRevenuePerTotalMile: 0,
    targetRevenuePerLoadedMile: 0,
    targetWeeklyRevenue: 0,
  },
  variableCostPerMile: 0,
  fixedCostPerDay: 0,
  // invoice/settlement
  invoiceTerm: 'CUSTOM',
  invoiceTermValue: 0,
  loadPrefix: '',
  manifestPrefix: '',
  invoicePrefix: '',
  seqNumber: '',
  settlementDate: '',
  settlementPeriod: 'WEEK_1',
  // loads
  stopActivitiesAutofillOption: 'CURRENT_DATE_TIME',
  trailerType: 'Flatbed',
  collectLoadUnloadTiming: false,
  billOfLadingMandatory: false,
  proofOfDeliveryMandatory: false,
  documentsMandatory: false,
  // driver
  driverPreferences: {
    dvirPreferences: 'PRE_TRIP',
    workWeekType: 'D7_H60',
    weeklyResetDay: 'any',
    preferredTruckstops: [],
    ignoreHOSRestrictions: false,
    useSleeperBerthProvision: false,
    weeklyRevenueTarget: null,
    weeklyEarningsTarget: null,
    weeklyMileageTarget: null,
    drivingBehaviourPreference: '',
    statesToAvoid: [],
    locationPreference: [],
  },
  //
  companyDetails: {
    name: '',
    dot: '',
    companyLogo: '',
    phoneData: {
      phone: '',
      extension: '',
      countryCode: '',
    },
    faxData: {
      phone: '',
      extension: '',
      countryCode: '',
    },
    email: '',
    addressData: {
      address: '',
      city: '',
      fullAddress: '',
      state: '',
      streetAddress: '',
      zip: '',
    },
  },
  replyTo: 'SENDER',
  defaultAverageSpeed: 0,
  // settings
  timezone: 'America/New_York',
  workWeekStartDay: 'MONDAY',
  locale: 'en',
  apiKey: '',
  fuelMatrix: matrixDefaultValues,
};

export const preferencesValidationSchema = yup.object().shape({
  // finance part
  dashboardPreferences: yup.object().shape({
    targetRevenuePerTotalMile: nullableNumberYup()
      .required('Field is required')
      .min(0.0001, 'Value must be between 0.0001 and 99.9999')
      .max(99.9999, 'Value must be between 0.0001 and 99.9999'),
    targetRevenuePerLoadedMile: nullableNumberYup()
      .required('Field is required')
      .min(0.0001, 'Value must be between 0.0001 and 99.9999')
      .max(99.9999, 'Value must be between 0.0001 and 99.9999'),
    targetWeeklyRevenue: nullableNumberYup()
      .required('Field is required')
      .min(1, 'Value must be between 1.0000 and 1,000,000,000.9999')
      .max(
        1000000000.9999,
        'Value must be between 1.0000 and 1,000,000,000.9999'
      ),
  }),
  variableCostPerMile: nullableNumberYup()
    .required('Field is required')
    .min(1, 'Value must be between 1.0000 and 1,000,000,000.9999')
    .max(
      1000000000.9999,
      'Value must be between 1.0000 and 1,000,000,000.9999'
    ),
  fixedCostPerDay: nullableNumberYup()
    .required('Field is required')
    .min(0, 'Value must be between 0 and 999,999.99999')
    .max(999999.9999, 'Value must be between 0 and 999,999.9999'),
  // invoice settlement part
  invoiceTerm: nullableStringYup().required('Field is required'),
  invoiceTermValue: nullableNumberYup().when(
    ['invoiceTerm'],
    (invoiceTerm, schema) => {
      if (invoiceTerm === 'CUSTOM') {
        return schema
          .required('Field is mandatory')
          .min(0, 'Value must be between 0 and 366')
          .max(366, 'Value must be between 0 and 366');
      }
      return schema;
    }
  ),
  loadPrefix: nullableStringYup().max(
    11,
    'Cannot be longer than 11 characters'
  ),
  manifestPrefix: nullableStringYup().max(
    11,
    'Cannot be longer than 11 characters'
  ),
  invoicePrefix: nullableStringYup().max(
    11,
    'Cannot be longer than 11 characters'
  ),
  seqNumber: nullableNumberYup()
    .required('Field is required')
    .min(1, 'Value must be between 1 and 999,999,999')
    .max(999999999, 'Value must be between 1 and 999,999,999'),
  settlementDate: yup
    .date()
    .nullable()
    .typeError('Please enter correct date in DD/MM/YYYY format'),
  settlementPeriod: nullableStringYup().required('Field is required'),
  // load part
  stopActivitiesAutofillOption:
    nullableStringYup().required('Field is required'),
  trailerType: nullableStringYup().required('Field is required'),
  // driver part
  driverPreferences: yup.object().shape({
    dvirPreferences: nullableStringYup().required('Field is required'),
    workWeekType: nullableStringYup().required('Field is required'),
  }),
  // company details part
  companyDetails: yup.object().shape({
    dot: nullableStringYup().required('Field is required'),
    email: nullableStringYup()
      .required('Field is required')
      .test('email', 'Email is not valid.', (email) =>
        emailValidation.test(email || '')
      ),
    phoneData: yup
      .object()
      .shape(
        {
          phone: phoneNumberValidation,
          extension: nullableNumberYup().test(
            'ext',
            'Extension must be exactly 5 digits',
            (val) => {
              if (val) {
                return val.toString().length === 5;
              }
              return true;
            }
          ),
        },
        [['phone', 'phone']]
      )
      .nullable(true),
    faxData: yup
      .object()
      .shape(
        {
          phone: phoneNumberValidation,
          extension: nullableNumberYup().test(
            'ext',
            'Extension must be exactly 5 digits',
            (val) => {
              if (val) {
                return val.toString().length === 5;
              }
              return true;
            }
          ),
        },
        [['phone', 'phone']]
      )
      .nullable(true),
    addressData: object().shape({
      streetAddress: nullableStringYup().required('Address is required'),
      city: nullableStringYup().required('City is required.'),
      state: nullableStringYup().required('State is required.'),
    }),
  }),
  replyTo: nullableStringYup().required('Field is required'),
  defaultAverageSpeed: nullableNumberYup()
    .required('Field is required')
    .min(40, 'Value must be between 40 and 100')
    .max(100, 'Value must be between 40 and 100'),
  // settings
  timezone: nullableStringYup().required('Field is required'),
  workWeekStartDay: nullableStringYup().required('Field is required'),
  locale: nullableStringYup().required('Field is required'),
  defaultRadius: nullableNumberYup()
    .min(0.1, 'Value must be between 0.1 and 5')
    .max(5, 'Value must be between 0.1 and 5'),
  fuelMatrix: yup.object().shape({
    US: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    PADD1B: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    PADD4: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    PADD2: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    PADD1A: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    CALIFORNIA: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    PADD1: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    PADD1C: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    PADD3: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    PADD5: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
    PADD5EC: yup.object().shape({
      price: nullableNumberYup().required('Field is required'),
    }),
  }),
});

export const invoiceTermsOptions = [
  { key: 'DUE_ON_RECEIPT', value: 'Due on Receipt' },
  { key: 'NET_15', value: 'Net 15 days' },
  { key: 'NET_30', value: 'Net 30 days' },
  { key: 'CUSTOM', value: 'Custom' },
];

export const settlementPeriods = [
  { key: 'WEEK_1', value: '1 Week' },
  { key: 'WEEK_2', value: '2 Week' },
  { key: 'WEEK_3', value: '3 Week' },
  { key: 'WEEK_4', value: '4 Week' },
];

export const stopActivities = [
  { key: 'CURRENT_DATE_TIME', value: 'Current date & time' },
  { key: 'APPOINTMENT_DATE_TIME', value: 'Appointment date & time' },
];

export const defaultEquipmentTypeOptions = [
  { value: 'Chassis & Container', key: 'Chassis_Container' },
  { value: 'Flatbed', key: 'Flatbed' },
  { value: 'Flatbed (Hotshot)', key: 'Flatbed_Hotshot' },
  { value: 'Livestock', key: 'Livestock' },
  { value: 'Power Only', key: 'Power_Only' },
  { value: 'Reefer', key: 'Reefer' },
  { value: 'Removable Goose Neck', key: 'Removable_Goose_Neck' },
  { value: 'Step Deck', key: 'Step_Deck' },
  { value: 'Straight Van', key: 'Straight_Van' },
  { value: 'Tanker', key: 'Tanker' },
  { value: 'Van', key: 'Van' },
  { value: 'Van (Hotshot)', key: 'Van_Hotshot' },
];

export const dvirPreferences = [
  { key: 'PRE_TRIP', value: 'Pre Trip' },
  { key: 'NONE', value: 'None' },
];

export const workWeekTypeOptions = [
  { key: 'D7_H60', value: '60 hours/7 days' },
  { key: 'D8_H70', value: '70 hours/8 days' },
  { key: 'D7_H70_TX', value: '70 hours/7 days (Texas)' },
];

export const replyToOptions = [
  { key: 'SENDER', value: 'Sender' },
  { key: 'OWNER', value: 'Owner Admin' },
];

export const timeZoneOptions = [
  { key: 'America/New_York', value: 'America/New York (EST)' },
  { key: 'America/Chicago', value: 'America/Chicago (CST)' },
  { key: 'America/Denver', value: 'America/Denver (MST)' },
  { key: 'America/Los_Angeles', value: 'America/Los Angeles (PST)' },
  { key: 'America/Phoenix', value: 'America/Phoenix (MST)' },
];

export const workStartWeekOptions = [
  { key: 'MONDAY', value: 'Monday' },
  { key: 'TUESDAY', value: 'Tuesday' },
  { key: 'WEDNESDAY', value: 'Wednesday' },
  { key: 'THURSDAY', value: 'Thursday' },
  { key: 'FRIDAY', value: 'Friday' },
  { key: 'SATURDAY', value: 'Saturday' },
  { key: 'SUNDAY', value: 'Sunday' },
];

export const languageOptions = [{ key: 'en', value: 'English' }];

export const truckStopsOptions = [
  { label: 'Loves', key: 'LOVE' },
  { label: 'Pilot Flying J', key: 'FLYINGJ' },
  { label: 'TA Petro', key: 'TA_PETRO' },
  { label: 'Govt. Rest Areas', key: 'GOVT' },
];

export const truckStopOptionsMap: Record<string, string> = {
  LOVE: 'Loves',
  FLYINGJ: 'Pilot Flying J',
  TA_PETRO: 'TA Petro',
  GOVT: 'Govt. Rest Areas',
};

export const MatrixSectionInfo = [
  {
    title: 'U.S.',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'US',
  },
  {
    title: 'East Cost (PADD1)',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'PADD1',
  },
  {
    title: 'New England (PADD1A)',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'PADD1A',
  },
  {
    title: 'Central Atlantic (PADD1B)',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'PADD1B',
  },
  {
    title: 'Lower Atlantic (PADD1C)',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'PADD1C',
  },
  {
    title: 'Midwest (PADD2)',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'PADD2',
  },
  {
    title: 'Gulf Coast (PADD3)',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'PADD3',
  },
  {
    title: 'Rocky Mountain (PADD4)',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'PADD4',
  },
  {
    title: 'West Coast Less California',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'PADD5EC',
  },
  {
    title: 'California',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'CALIFORNIA',
  },
  {
    title: 'West Coast',
    subTitle: 'Select either custom price or latest EIA price for this region',
    matrix: 'PADD5',
  },
];
