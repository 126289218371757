import { AddOutlined } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import { FileTypeIcon } from '../ui-kit/components/Assets';
import {
  IGridBottomMenu,
  IGridBottomMenuOption,
} from '../ui-kit/components/DataGridPro';
import { capitalize } from 'lodash';
import {
  IMPORT_CUSTOMER_NAME,
  IMPORT_DRIVER_NAME,
  IMPORT_EXPENSE_NAME,
  IMPORT_LOAD_NAME,
  IMPORT_LOCATION_NAME,
  IMPORT_TRACTOR_NAME,
  IMPORT_TRAILER_NAME,
  IMPORT_VENDOR_NAME,
} from '../contexts/ImportContext';
import { View } from '../types';
import { t } from 'i18next';

export interface IGetGridBottomMenuSettings {
  currentView?: View;
  entityName?: string;
  canUploadPermission?: boolean;
  canAddPermission?: boolean;
  onUploadHandler?: () => void;
  onAddHandler?: () => void;
  menuOptions?: IGridBottomMenuOption[];
}

export const getGridBottomMenuSettings = ({
  currentView = undefined,
  entityName,
  onUploadHandler,
  onAddHandler,
  canUploadPermission = false,
  canAddPermission = false,
  menuOptions = [],
}: IGetGridBottomMenuSettings): IGridBottomMenu => {
  const getImportName = (): string => {
    if (currentView && currentView.entity) {
      if (currentView.entity === 'load') {
        return IMPORT_LOAD_NAME;
      } else if (currentView.entity === 'tractor') {
        return IMPORT_TRACTOR_NAME;
      } else if (currentView.entity === 'trailer') {
        return IMPORT_TRAILER_NAME;
      } else if (currentView.entity === 'customer') {
        return IMPORT_CUSTOMER_NAME;
      } else if (currentView.entity === 'vendor') {
        return IMPORT_VENDOR_NAME;
      } else if (currentView.entity === 'location') {
        return IMPORT_LOCATION_NAME;
      } else if (currentView.entity === 'driver') {
        return IMPORT_DRIVER_NAME;
      } else if (
        ['allExpenses', 'fuelExpense', 'otherExpense'].includes(
          currentView.entity
        )
      ) {
        return IMPORT_EXPENSE_NAME;
      }
    }

    return '';
  };
  const gridBottomMenu: IGridBottomMenu = {
    menuOptions: [],
  };
  if (canUploadPermission) {
    gridBottomMenu.menuOptions.push({
      label: `${getImportName()} Import`,
      icon: () => (
        <SvgIcon>
          <FileTypeIcon width={24} height={24} />
        </SvgIcon>
      ),
      onClickHandler: onUploadHandler,
    });
  }
  if (canAddPermission) {
    gridBottomMenu.menuOptions.push({
      icon: AddOutlined,
      label: `Add ${capitalize(currentView?.entity || entityName)}`,
      onClickHandler: onAddHandler,
    });
  }
  if (menuOptions) {
    gridBottomMenu.menuOptions.push(...menuOptions);
  }

  gridBottomMenu.menuOptions.push({
    label: t('cancel'),
  });

  return gridBottomMenu;
};
