import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ServiceError } from '../../../../api/interfaces';
// @TODO: Nvard - ask whether we have tooltip in ui-kit
import { loadService } from '../../../../api';
import FormDialog from '../../../../common/Ui/FormDialog/index';
import { useCommodityTypes } from '../../../../contexts/CommodityTypeContext';
import { PackageTypesResponse } from '../../../../models/DTOs/Loads/Loads';
import { commodityValidationSchema } from '../../../../subPages/loadsList/LoadDetailsPanel/constants/constants';
import { Commodity as CommodityModel } from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { AddIcon } from '../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import { useThemeResponsive } from '../../../hooks/useThemeResponsive';
import CommodityForm from '../../forms/CommodityForm';
import { CommodityFormRequiredFieldsType } from '../../types';
import { getDefaultCommodity } from '../../utils';
import { CommodityCard } from './CommodityCard';
import { getCommodityTypesNames, getTotalWeight } from './utils';
import { t } from 'i18next';

interface IProps {
  title?: string;
  styles?: {};
  showCollapsedViewInfo?: boolean;
  isViewMode?: boolean;
  handleCommodityDataUpdate?: (
    commodity: CommodityModel,
    totalWeight: number | null
  ) => void;
  deleteCommodity?: (id: number) => void;
  requiredFields?: CommodityFormRequiredFieldsType;
  shouldUpdateCommodityTypes?: boolean;
}

export const CommodityList = (props: IProps) => {
  const { isMobile } = useThemeResponsive();
  const { commodityTypes } = useCommodityTypes();
  const [addCommodityDialogOpen, setCommodityDialog] = useState(false);
  const [packageTypeOptions, setPackageTypeOptions] = useState<
    PackageTypesResponse[]
  >([]);
  const { setValue, watch } = useFormContext();
  let commodityData = watch('commodityData');
  const id = watch('id');

  const onAddCommodity = (commodity: CommodityModel) => {
    setCommodityDialog(false);

    const newCommodity = {
      ...commodity,
      commodity: {
        id: commodity.commodityTypeId,
        itemName: commodity.commodityTypeDisplayValue,
      },
      commodityTypeDisplayValue: commodity.commodityTypeDisplayValue,
    };
    if (commodityData?.length) {
      commodityData.push(newCommodity);
    } else {
      commodityData = [newCommodity];
    }

    setValue('commodityData', commodityData, { shouldDirty: false });
    const totalWeight = getTotalWeight(commodityData);
    setValue('totalWeight', totalWeight, {
      shouldDirty: !id,
    });
    const { handleCommodityDataUpdate } = props;
    if (id && typeof handleCommodityDataUpdate === 'function') {
      handleCommodityDataUpdate(commodity, totalWeight);
    }
  };

  useEffect(() => {
    const getPackageTypeOptions = async () => {
      const response = await loadService.getPackageTypes();
      if (!response || response instanceof ServiceError) {
        return;
      }
      setPackageTypeOptions(response);
    };
    getPackageTypeOptions();
  }, []);

  useEffect(() => {
    const { shouldUpdateCommodityTypes = true } = props;
    if (shouldUpdateCommodityTypes && commodityData?.length) {
      let commodityTypesNames = getCommodityTypesNames(
        commodityData,
        commodityTypes
      );
      commodityTypesNames = commodityTypesNames.map((item) => ({
        ...item,
        commodity: { id: item.commodityTypeId, itemName: item.itemName },
        commodityTypeDisplayValue: item.itemName,
      }));

      setValue('commodityData', commodityTypesNames, { shouldDirty: false });
    }
  }, [id, commodityTypes?.length]);

  const {
    title = t('commodity'),
    isViewMode = false,
    styles = {},
    deleteCommodity,
    requiredFields,
  } = props;
  return (
    <Box sx={{ ...styles }}>
      <Box
        sx={{
          paddingBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            variant="h7"
            sx={{
              paddingRight: '0px',
              color: 'primary.main',
            }}
          >
            {title}
          </Typography>
        </Box>

        {!isViewMode && !isMobile && (
          <ButtonImproved
            onClick={() => {
              setCommodityDialog(true);
            }}
            variant="contained"
            startIcon={<AddIcon color="#fff" />}
            label={t('TripAddNewLoadOption5AddNewCommodity')}
            styleProps={{ minWidth: 35, marginLeft: 10 }}
          />
        )}
      </Box>
      {commodityData?.map((_commodity: CommodityModel, index: number) => {
        const isLastItem = index === commodityData.length - 1;
        return (
          <Box sx={{ marginBottom: !isLastItem ? '24px' : '0px' }} key={index}>
            <CommodityCard
              key={index}
              id={index}
              isViewMode={isViewMode}
              packageTypeOptions={packageTypeOptions}
              showCollapsedViewInfo={props.showCollapsedViewInfo}
              deleteCommodity={deleteCommodity}
              requiredFields={requiredFields}
            />
          </Box>
        );
      })}
      {addCommodityDialogOpen && (
        <FormDialog
          data={getDefaultCommodity(commodityTypes)}
          titleText={t('TripAddNewLoadOption5AddNewCommodity')}
          actionLabel={t('TripAddNewLoadOption5AddCommodity')}
          open={addCommodityDialogOpen}
          onAction={onAddCommodity}
          handleClose={() => {
            setCommodityDialog(false);
          }}
          validationSchema={commodityValidationSchema}
          contentRenderer={() => (
            <CommodityForm
              packageTypeOptions={packageTypeOptions}
              nameStart=""
            />
          )}
        ></FormDialog>
      )}

      {!isViewMode && isMobile && (
        <ButtonImproved
          onClick={() => {
            setCommodityDialog(true);
          }}
          variant="contained"
          startIcon={<AddIcon color="#fff" />}
          label={t('TripAddNewLoadOption5AddNewCommodity')}
          styleProps={{
            width: '100%',
            margin: '16px 0 16px',
          }}
        />
      )}
    </Box>
  );
};
