import { CommonConstants } from './common/index';
import { DashboardConstants } from './dashboard';
import { IntegrationConstants } from './integration';
import { Notifications } from './notifications';
import { PreferencesConstants } from './preferences';
import { UserConstants } from './users';
import { DispatchConstants } from './dispatch';
import { AllLoadConstants } from './allLoads';
import { FinanceConstants } from './finance';
import { SafetyConstants } from './safety';
import { OperationsConstants } from './operations';
import { RegistrationConstants } from './registration';
import { ReportsConstants } from './reports';
import { SettingsConstants } from './settings';
import { SubscriptionConstants } from './Subscription';

export const EnTranslations = {
  ...CommonConstants,
  ...DashboardConstants,
  ...PreferencesConstants,
  ...IntegrationConstants,
  ...Notifications,
  ...UserConstants,
  ...DispatchConstants,
  ...AllLoadConstants,
  ...FinanceConstants,
  ...SafetyConstants,
  ...OperationsConstants,
  ...RegistrationConstants,
  ...ReportsConstants,
  ...SettingsConstants,
  ...SubscriptionConstants,
};
