import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { statesinCountry } from '../../../../../common/Ui/AddressField/constants/states';
import { GridColDefSelf } from '../../../../../types';
import { formatPhoneNumber } from '../../../../../utils';
import { IFiltersFormConfig } from '../../../../trips/constants/types';
import { terminalFieldsConfig } from '../../constants/constants';
import { ITerminalFilters } from '../../constants/types';

export const defaultFilters: ITerminalFilters =
  {} as unknown as ITerminalFilters;

interface GridProps {
  [key: string]: any;
}

export default class TerminalGridConfig {
  name: string;

  config: {
    columns: GridColDefSelf[];
    title: string;
    getFiltersFormConfig: () => IFiltersFormConfig[];
    defFilters: ITerminalFilters;
  };

  constructor(props: GridProps) {
    this.name = 'GridConfiguration';
    this.config = TerminalGridConfig.getConfiguration(props);
  }

  static getConfiguration(props: GridProps) {
    return TerminalGridConfig.getDefaultConfiguration(props);
  }

  static getDefaultConfiguration(props) {
    return {
      title: 'Terminal Grid',
      columns: [
        {
          flex: 1,
          minWidth: 200,
          field: terminalFieldsConfig.companyName.fieldName,
          headerName: terminalFieldsConfig.companyName.label,
          sortable: false,
        },
        {
          flex: 1,
          field: terminalFieldsConfig.dot.fieldName,
          headerName: terminalFieldsConfig.dot.label,
          minWidth: 80,
          sortable: false,
        },
        {
          flex: 1,
          field: terminalFieldsConfig.mc.fieldName,
          headerName: terminalFieldsConfig.mc.label,
          minWidth: 80,
          sortable: false,
        },
        {
          field: terminalFieldsConfig.contact.fieldName,
          headerName: terminalFieldsConfig.contact.label,
          renderCell: (params: GridRenderCellParams) => {
            const { firstName = '', lastName = '' } = params.row?.contactPerson;
            const contact = [firstName, lastName].filter(Boolean).join(' ');
            return <>{contact}</>;
          },
          sortable: false,
          minWidth: 200,
          flex: 1,
        },
        {
          field: terminalFieldsConfig.phone.fieldName,
          headerName: terminalFieldsConfig.phone.label,
          renderCell: (params: GridRenderCellParams) => {
            const phone = {
              countryCode: params.row?.contactPerson?.countryCode,
              extension: params.row?.contactPerson?.extension,
              phone: params.row?.contactPerson?.phone,
            };
            return formatPhoneNumber(phone);
          },
          sortable: false,
          minWidth: 180,
          flex: 1,
        },
        {
          field: terminalFieldsConfig.fax.fieldName,
          headerName: terminalFieldsConfig.fax.label,
          renderCell: (params: GridRenderCellParams) => {
            const fax = {
              countryCode: params.row?.contactPerson?.faxCountryCode,
              extension: params.row?.contactPerson?.faxExtension,
              phone: params.row?.contactPerson?.fax,
            };
            return formatPhoneNumber(fax);
          },
          sortable: false,
          minWidth: 180,
          flex: 1,
        },
        {
          field: terminalFieldsConfig.email.fieldName,
          headerName: terminalFieldsConfig.email.label,
          renderCell: (params: GridRenderCellParams) => {
            return <>{params.row?.contactPerson?.email || ''}</>;
          },
          sortable: false,
          minWidth: 250,
          flex: 1,
        },
        {
          field: terminalFieldsConfig.address.fieldName,
          headerName: terminalFieldsConfig.address.label,
          renderCell: (params: GridRenderCellParams) => {
            return <>{params.row?.addressData?.streetAddress || ''}</>;
          },
          sortable: false,
          minWidth: 200,
          flex: 1,
        },
        {
          field: terminalFieldsConfig.city.fieldName,
          headerName: terminalFieldsConfig.city.label,
          renderCell: (params: GridRenderCellParams) => {
            return <>{params.row?.addressData?.city || ''}</>;
          },
          sortable: false,
          minWidth: 100,
          flex: 1,
        },
        {
          field: terminalFieldsConfig.state.fieldName,
          headerName: terminalFieldsConfig.state.label,
          renderCell: (params: GridRenderCellParams) => {
            const state = statesinCountry.find(
              ({ value }) => value == params.row?.addressData?.state
            )?.name;
            return <>{state || ''}</>;
          },
          sortable: false,
          minWidth: 100,
          flex: 1,
        },
        {
          field: terminalFieldsConfig.zip.fieldName,
          headerName: terminalFieldsConfig.zip.label,
          renderCell: (params: GridRenderCellParams) => {
            return <>{params.row?.addressData?.zipcode || ''}</>;
          },
          sortable: false,
          minWidth: 100,
          flex: 1,
        },
      ],
      getFiltersFormConfig: () => [],
      defFilters: defaultFilters,
    };
  }
}
