import { createTheme, Theme } from '@mui/material';
import { Type } from 'class-transformer';
import { ExpenseEntityType, QueryParams } from '../../../../models';
import { LoadCarrierExpenseReceivedPayment } from '../../../../models/DTOs/FinanceLoadService';
import StorageManager from '../../../../StorageManager/StorageManager';
import { LoadSummary } from '../../../../subPages/loadsList/models/LoadSummary';
import { objectType } from '../../../../types';
import { ESecondaryDetailsPanelType } from '../../../../views/dispatch2/constants/types';
import { getOrganizationId } from '../../../TimeoffDialog/utils';

export class FinanceOverviewType {
  loadId!: string;
  onClose?: () => void;
  openPayments?: openPaymentsType;
  ModuleType!: string;
  panelType!: ESecondaryDetailsPanelType;
  setLoadData!: (data: LoadSummary) => void;
  isBrokered?: boolean;
  isCarrier?: boolean;
  loadType?: string;
  isEmptyTrip?: boolean;
}
export class openPaymentsType {
  driverAmountForAssign?: Array<ItemList>;
}

export interface ItemList {
  createDate: string;
  driverId: number;
  driverName: string;
  emptyMiles: number;
  groupDetails: null;
  id: string;
  items: Array<FinanceLoadloadexpenseListType>;
  loadId: string;
  loadedMiles: number;
  organizationId: number;
  total: number;
  updateDate: string;
}

export interface FinanceLoadloadexpenseListType {
  parcent?: string;
  payType: string | undefined;
  amount: number;
  description: string;
  expenseCategoryId: string;
  expenseCategoryKey: string;
  id: string;
  loadRateType: string;
  loadRateTypeId: string;
  manuallyAdded: boolean;
  quantity?: string;
  rate: string;
  unit: string;
  map(arg0: (currElement: any, index: number) => void): unknown;
}

export class FinanceLoadType {
  @Type(() => paymentDetailsType)
  invoiceDetails?: Array<paymentDetailsType>;
  totalIn?: number;
  totalOut?: number;
  tripDetails?: Array<TripDetailsType>;
}
export interface TripDetailsType {
  sharedAmount: number;
  paymentDetails: Array<paymentDetailsType>;
  tripLoadFinanceDetails: Array<tripLoadFinanceDetailsType>;
  tripName?: string;
  revenueShare?: number;
  loadId?: string;
}

export enum EPaymentDetailType {
  Dispatcher = 'Dispatcher',
  Carrier = 'Carrier',
  Driver = 'Driver',
  Tractor = 'Tractor',
}

export class paymentDetailsType {
  amount!: number;
  revenueShare!: number;
  id?: string;
  name!: string;
  payTypeIdList!: Array<string>;
  paymentId!: string;
  paymentStatus!: string;
  type!: string | EPaymentDetailType;
}
export class tripLoadFinanceDetailsType {
  loadId!: string;
  revenueAmount!: number;
  revenueShare!: number;
  seqNumber!: string;
}
export class requestParamsFinanceOverview {
  loadId?: string;
  entityId?: number;
  organizationId?: number;
  entityType?: ExpenseEntityType;
}

export class UpdateTractorDriverAccessQuery extends QueryParams {
  tractorExpenseId?: string;
  shareWithDriver?: boolean;
  organizationId!: number;
  constructor(dto?: any) {
    super();
    if (dto) {
      this.organizationId = getOrganizationId();
      this.tractorExpenseId = dto.tractorExpenseId;
      this.organizationId = dto.organizationId;
    }
  }
}

export class requestStatuesStatementInfo {
  id!: number;
  startDate?: string;
  endDate?: string;
  seqNumber?: number;
  status?: string;
  payToEntityId?: number;
  payToEntityType?: string;
}

export class ListDataType {
  loadRateType!: string;
  createDate!: string;
  seqNumber!: number;
  email!: string;
  driverId!: number;
  driverName!: string;
  emptyMiles!: number;
  groupDetails!: null;
  id?: string;
  items!: Array<FinanceLoadloadexpenseListType>;
  loadId!: string;
  loadedMiles!: number;
  organizationId!: number;
  total!: number;
  updateDate!: string;
  dispatcherId!: number;
  tractorId!: number;
  amount?: number;
  terminal?: objectType;
  shareWithDriver?: boolean;
  receivedPayments?: Array<LoadCarrierExpenseReceivedPayment>;
}

export class CreateFinanceLoadloadexpenseRecordType {
  description?: string;
  driverId?: string;
  expenseCategoryId?: string;
  expenseCategoryKey?: string;
  organizationId?: number;
  payType?: string;
  payTypeId?: string;
  rate?: number;
  parent: number | undefined;
  unit: PaymentUnitType | undefined;
}
export class PaymentUnitType {
  name?: string;
}
export const RevenueShareThemeStyle = (theme: Theme) =>
  createTheme(theme, {
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            '& .revenueShareDiv': {
              display: 'none',
            },
            '&:hover .revenueShareDiv': {
              display: 'block',
            },
          },
        },
      },
    },
  });
export const RevenueShareModelThemeStyle = (theme: Theme) =>
  createTheme(theme, {
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            ' .MuiTypography-h6 ': {
              color: '#000000 !important',
              fontSize: '20px',
            },
            fontSize: '16px',
            '& dialogTitle_class': {
              color: '#000000 !important',
              fontSize: '1px',
            },
          },
        },
      },
    },
  });

export const loadIdStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
  display: 'inline-flex',
};
export const loadCustomerStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 17, 34, 0.6)',
  display: 'inline-flex',
};
export const loadIconStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 17, 34, 0.6)',
  display: 'inline-flex',
  margin: '3px 5px',
};
export type IFinanceRevenueShareType = {
  shareData: any;
  loadType: string;
  loadId: string;
};
export type IFinanceRevenueShareUIType = {
  loadType: string;
  loadId: string;
  isSubmit: boolean;
  financeRevenueShareupdated: (load: any) => void;
};

export const FinanceRevenueShareWarning = {
  additionalAmount:
    'The sum of revenue amount should be equal to the load revenue. Please remove additional amount  ',
  remainingAmount:
    'The sum of revenue amount should be equal to the load revenue. Please add remaining amount   ',
};

export const financeRevenueTootTipCardStyle = {
  flexDirection: 'row',
  alignItems: 'center',
  padding: '6px 0px',
  background: ' #FFFFFF',
  boxShadow:
    '0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
  width: '280px',
  position: 'absolute',
  ml: '18px',
  flex: 'none',
  order: 1,
  flexGrow: 0,
  display: 'inline-grid',
  zIndex: 1,
};
export const financeRevenueTootTipStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
  flex: 'none',
  order: 2,
  alignSelf: 'stretch',
  flexGrow: 0,
  // height: '44px',
  padding: '12px 16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
export const tripDetailsStyle = {
  padding: '5px 5px',
  background: '#F5F5F5',
  borderRadius: '8px',
  marginTop: '15px',
  marginBottom: '15px',
  width: '100%',
  marginLeft: '5px',
};
export const shareTextStyle = {
  color: '#2B64CB',
  fontWeight: '600',
  paddingLeft: '5px ',
};
export type propsTypeFinanceRevenueTootTip = {
  onModelOpen: () => void;
  shareData: any;
};

export type IFinanceRevenueSharePayLoadType = {
  organizationId: number;
  loadIdToTripRevenueShare: {
    [key: string]: number;
  };
};
export type ITripDetails = {
  destination: string;
  origin: string;
  seqNumber: string;
  tripId: string;
  revenueShare: number;
  revenueSharePercentage: number;
  isMore?: boolean;
};
export type ILoadRevenueShareDetails = {
  amount: number;
  loadId: string;
  seqNumber: string;
  tripDetails: Array<ITripDetails>;
  calAmount?: number;
};
export type ILoadRevenueShareKey = {
  [key: string]: number;
};

export type IFinanceRevenueShareRespType = {
  organizationId: number;
  loadRevenueShareDetails: Array<ILoadRevenueShareDetails>;
};

export class ExpenseItem {
  id?: string;
  description?: string;
  expenseCategoryKey?: string;
  expenseCategoryId?: string;
  loadRateTypeId?: string;
  loadRateType?: string;
  quantity?: number;
  rate?: number;
  amount?: number;
  manuallyAdded?: boolean;
  unit?: string;
}
export class CreateLoadFinanceExpenseDTO {
  driverId?: string;
  tractorId?: string;
  dispatcherId?: number;
  loadId?: string;
  total?: number;
  seqNumber?: string;
  organizationId?: number;
  selectRecordData?: any;
  selectRecordList?: Array<any>;
  items?: Array<ExpenseItem>;
  constructor(data: CreateLoadFinanceExpenseDTO) {
    this.driverId = data?.driverId;
    this.tractorId = data?.tractorId;
    this.dispatcherId = data?.dispatcherId;
    this.loadId = data?.loadId;
    this.total = data?.selectRecordData?.total ?? data?.total;
    this.seqNumber = data?.seqNumber;
    this.organizationId =
      data?.organizationId || StorageManager.getUser()?.organizationId;
    this.items = data?.selectRecordList;
  }
}

export class DeleteFinanceExpense extends QueryParams {
  id?: string;
  constructor(data: any) {
    super();
    this.id = data?.paymentId;
  }
}
