import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Grid, SxProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { QuickBookProviderName } from '../../../../views/settings/integrations/Finance/constants';
import CardWithIcon from '../../CardWithIcon';
import Account from '../Account';

const AccountsSection = ({
  openDrawer,
  currentCustomerData,
  providerData,
  allTerminals,
  getProviderDetails,
  componentToRenderInsideModal,
  deactivateAccount,
  closeConfirmationPopup,
  openConfirmationPopup,
  isDeletePopupOpen,
  refreshAccountData,
  editAccount,
  reauthenticate,
  selectedCardForDeletion,
  integrationType,
  isCustomMenuHandling,
  sectionHeading,
  clickedParamToManage,
  resetFilters,
  resetRequestData,
  axelePermissions,
  isArchivedSection,
  customAccountCardStyle,
  cardWithIconStyle,
}: Props) => {
  const [currentProviderAccountsData, setAccountData] = useState([]);
  const [sectionHeadingText] = useState(
    sectionHeading && sectionHeading != '' ? sectionHeading : 'Account(s)'
  );
  const cardSubtitleStyle = {
    color: 'primary.main',
    fontSize: 20,
    fontWeight: 400,
  };

  useEffect(() => {
    setAccountDetails();
  }, [currentCustomerData, providerData]);

  const setAccountDetails = () => {
    const currentProviderId = currentCustomerData?.providerId;
    const integrationsDTO =
      providerData?.integrationsDTO ||
      providerData?.eldIntegrationsDTO ||
      providerData?.loadBoardIntegrations;
    if (
      currentProviderId &&
      integrationsDTO &&
      Object.keys(integrationsDTO).length > 0 &&
      integrationsDTO[currentProviderId]
    ) {
      setAccountData(integrationsDTO[currentProviderId]);
    }
  };
  if (!currentProviderAccountsData?.length) return <></>;
  return (
    <>
      {currentCustomerData?.providerName === QuickBookProviderName && (
        <h1>Accounts Section</h1>
      )}
      <Box className="accounts-section">
        <Typography variant="h5" sx={{ color: 'primary.main' }}>
          {`${currentProviderAccountsData.length} ${sectionHeadingText}`}
        </Typography>
        <Grid
          container
          sx={{ paddingTop: '32px', justifyContent: 'space-between' }}
        >
          {currentProviderAccountsData &&
            currentProviderAccountsData.length > 0 &&
            currentProviderAccountsData.map((ele: any, index: number) => {
              return (
                <Account
                  accountData={ele}
                  key={index}
                  allTerminals={allTerminals}
                  getProviderDetails={getProviderDetails}
                  refreshAccountData={refreshAccountData}
                  componentToRenderInsideModal={componentToRenderInsideModal}
                  isDeletePopupOpen={isDeletePopupOpen}
                  closeConfirmationPopup={closeConfirmationPopup}
                  deactivateAccount={deactivateAccount}
                  openConfirmationPopup={openConfirmationPopup}
                  editAccount={editAccount}
                  reauthenticate={reauthenticate}
                  selectedCardForDeletion={selectedCardForDeletion}
                  integrationType={integrationType}
                  isCustomMenuHandling={isCustomMenuHandling}
                  clickedParamToManage={clickedParamToManage}
                  resetFilters={resetFilters}
                  resetRequestData={resetRequestData}
                  axelePermissions={axelePermissions}
                  customAccountCardStyle={customAccountCardStyle}
                />
              );
            })}

          {axelePermissions.showAddBtn && !isArchivedSection && (
            <CardWithIcon
              cardAction={openDrawer}
              style={cardWithIconStyle}
              icon={AddOutlinedIcon}
              cardSubtitleStyle={cardSubtitleStyle}
              iconStyles={{ color: 'primary.main' }}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

export interface Props {
  openDrawer: Function;
  currentCustomerData: any;
  providerData: any;
  allTerminals?: any;
  getProviderDetails?: any;
  componentToRenderInsideModal?: any;
  isDeletePopupOpen?: boolean;
  closeConfirmationPopup?: any;
  deactivateAccount?: any;
  openConfirmationPopup?: any;
  refreshAccountData?: any;
  editAccount?: any;
  reauthenticate?: any;
  selectedCardForDeletion?: any;
  isCustomMenuHandling?: boolean;
  integrationType: string;
  sectionHeading?: string;
  clickedParamToManage?: (arg1: string, arg2: any) => void;
  resetFilters?: any;
  resetRequestData?: any;
  axelePermissions?: any;
  customAccountCardStyle?: SxProps;
  cardWithIconStyle?: SxProps;
  isArchivedSection?: boolean;
}

export default AccountsSection;
