import { E3DotMenuType } from '../../views/myLoads/components/LoadDetailDialog/types';
import BaseEvent from '../BaseEvent';
import { EventTypes } from '../EventTypes';

export interface EventHoldLoadActionData {
  loadIds: string[];
  tripIds: string[];
  invoiceIds: string[];
  type: E3DotMenuType;
}

export default class EventHoldLoadAction extends BaseEvent {
  data: EventHoldLoadActionData;
  constructor(data: EventHoldLoadActionData) {
    super(EventTypes.HOLD_LOAD, data);
    this.data = data;
  }
}
