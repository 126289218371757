import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import {
  CustomizeCardHeader,
  FinanceKPICard,
  FinanceKPICardProps,
} from '../../../../common/Dashboard';
import { KPIBarChart, ProgressChart } from '../../../../common/Dashboard/Chart';
import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import { useStores } from '../../../../store/root.context';
import { StudiesIcon } from '../../../../ui-kit/components/Assets';
import ButtonGroup from '../../../../ui-kit/components/ButtonGroup';
import { currencyDisplay } from '../../../../utils/grid.utils';
import { StyledComponentRoot } from '../../styles/styles';

export const FinanceKPI = observer((): JSX.Element => {
  const { dashboardStore } = useStores();
  const {
    revenue = 'GrossRevenue',
    plan = 'Actual',
    onRevenueChange,
    onPlanChange,
    financeData,
    financeLoader,
    WeekCalculationValue,
  } = dashboardStore;

  const financeKPIList: FinanceKPICardProps[] = [
    {
      renderLeftContent: () => (
        <ProgressChart
          percentComplete={
            financeData?.targetWeeklyRevenuePercent
              ? financeData?.targetWeeklyRevenuePercent
              : 0
          }
          subTitle={t('target')}
        />
      ),
      title: `${t('revenueThisWeek')}`,
      financeLoader: financeLoader,
      valueDisplay: currencyDisplay(
        financeData?.totalRevenue ? financeData?.totalRevenue : 0
      ),
      subTitle: `${t('target')}: ${currencyDisplay(
        financeData?.targetWeeklyRevenue ? financeData?.targetWeeklyRevenue : 0
      )}`,
    },
    {
      renderLeftContent: () => (
        <KPIBarChart
          value1={financeData?.revenuePerTotalMiles || 0}
          value2={financeData?.targetWeeklyRevenueTotalMile || 0}
          width={110}
          height={80}
          title={t('actualTarget')}
        />
      ),
      title: `${t('revenueTotalMile')}`,
      financeLoader: financeLoader,
      valueDisplay: currencyDisplay(
        financeData?.revenuePerTotalMiles
          ? financeData?.revenuePerTotalMiles
          : 0
      ),
      subTitle: `${t('target')}: ${currencyDisplay(
        financeData?.targetWeeklyRevenueTotalMile
          ? financeData?.targetWeeklyRevenueTotalMile
          : 0
      )}`,
    },
    {
      renderLeftContent: () => (
        <ProgressChart
          percentComplete={
            financeData?.targetRevenuePerLoadedMilePercent
              ? financeData?.targetRevenuePerLoadedMilePercent
              : 0
          }
          subTitle={t('target')}
        />
      ),
      title: `${t('revenueLoadedMile')}`,
      financeLoader: financeLoader,
      valueDisplay: currencyDisplay(
        financeData?.revenuePerLoadedMiles
          ? financeData?.revenuePerLoadedMiles
          : 0
      ),
      subTitle: `${t('target')}: ${currencyDisplay(
        financeData?.targetWeeklyRevenueLoadedMile
          ? financeData?.targetWeeklyRevenueLoadedMile
          : 0
      )}`,
    },
    {
      renderLeftContent: () => (
        <KPIBarChart
          value1={financeData?.avgRevenuePerDriver || 0}
          value2={financeData?.targetWeeklyRevenuePerDriver || 0}
          width={110}
          height={80}
          title={t('actualTarget')}
        />
      ),
      title: `${t('revenueDriver')}`,
      financeLoader: financeLoader,
      valueDisplay: currencyDisplay(
        financeData?.avgRevenuePerDriver ? financeData?.avgRevenuePerDriver : 0
      ),
      subTitle: `${t('target')}: ${currencyDisplay(
        financeData?.targetWeeklyRevenuePerDriver
          ? financeData?.targetWeeklyRevenuePerDriver
          : 0
      )}`,
    },
    {
      renderLeftContent: () => {
        if (!financeData?.loadedMiles && !financeData?.emptyMiles) return null;
        return (
          <KPIBarChart
            value1={financeData?.loadedMiles || 0}
            value2={financeData?.emptyMiles || 0}
            width={80}
            height={80}
            stacked={true}
          />
        );
      },
      title: `Mileage: ${financeData?.totalMiles || 0}`,
      financeLoader: financeLoader,
      valueDisplay: `${t('empty')}: ${financeData?.emptyMiles || 0} mi`,
      subTitle: `${t('loaded')}: ${financeData?.loadedMiles || 0} mi`,
      isMileage: true,
    },
  ];

  const { isDesktop, isMobile, isLaptop, isTablet } = useThemeResponsive();
  return (
    <StyledComponentRoot>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
        width={'100%'}
      >
        <CustomizeCardHeader
          avatar={<StudiesIcon />}
          title={t('financePerformanceTitle')}
          subheader={WeekCalculationValue}
          action={
            isDesktop || isLaptop || isTablet ? (
              <Box>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <ButtonGroup
                    onChange={(value) => onRevenueChange(value)}
                    options={[
                      {
                        key: 'GROSS',
                        value: t('grossRevenue'),
                      },
                      {
                        key: 'NET',
                        value: t('netRevenue'),
                      },
                    ]}
                    selected={revenue}
                  />
                  <ButtonGroup
                    onChange={(value) => onPlanChange(value)}
                    options={[
                      {
                        key: 'Actual',
                        value: t('actual'),
                      },
                      {
                        key: 'Projected',
                        value: t('projected'),
                      },
                    ]}
                    selected={plan}
                  />
                </Stack>
              </Box>
            ) : null
          }
        />
      </Stack>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            WebkitOverflowScrolling: 'touch',
            ...(isMobile && {
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
            }),
            ...(isTablet && {
              overflowX: 'unset',
              overflowY: 'unset',
              whiteSpace: 'unset',
            }),
          }}
        >
          {financeKPIList.map(
            (financeKPI: FinanceKPICardProps, idx: number) => (
              <Box
                key={idx}
                sx={{
                  width: '325px',
                  display: 'inline-block',
                  p: 1,
                  pl: 0,
                  ...(isMobile && {
                    width: '100%',
                  }),
                  ...(isTablet && {
                    width: idx < 3 ? `33.3%` : '50%',
                  }),
                  ...(isDesktop && {
                    width: '20%',
                  }),
                }}
              >
                <FinanceKPICard {...financeKPI} />
              </Box>
            )
          )}
        </Box>
      </Box>
    </StyledComponentRoot>
  );
});
