import { Box } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import {
  GridCellParams,
  GridColumnMenuProps,
  GridColumnVisibilityModel,
  GridFetchRowsParams,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { loadsearchService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { LoadTabPanel } from '../../../common/LoadTabPanel';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import SecondaryDetailsPanel from '../../../common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import { addressType } from '../../../common/Ui/AddressField/constants';
import {
  IStateValue,
  IZoneValue,
} from '../../../common/Ui/LocationSelect/StateZoneSelect';
import BouncingDotsLoader from '../../../common/bouncing_dots_loader';
import { useDispatchFindLoad } from '../../../common/hooks/useDispatchFindLoad';
import { useFilterDataGrid } from '../../../common/hooks/useFilterDataGrid';
import { persistUserState } from '../../../constants';
import { useLoadsearchLane } from '../../../contexts/LoadsearchLaneContext';
import { UpdateBidLoadRequest } from '../../../models/DTOs/Loadsearch/Request';
import useFilterStorage from '../../../services/storage';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { useStores } from '../../../store/root.context';
import CreateLoad from '../../../subPages/loadsList/CreateLoad';
import { CreateLoadRequestDTO } from '../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import { ILoadDetails } from '../../../subPages/loadsList/CreateLoad/types/types';
import { LoadDetailsSummary } from '../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { getLoadDetailsFromLSLoad } from '../../../subPages/loadsList/LoadDetailsPanel/utils';
import { CommonDataGrid } from '../../../ui-kit/components/DataGridPro';
import { ESecondaryDetailsPanelType } from '../../dispatch/constants/types';
import { IFiltersFormConfig } from '../../trips/constants/types';
import {
  DetailPanel,
  InvocationReqIdMetaDataProps,
} from '../components/DetailPanel';
import { ELoadBoardEntity } from '../constants';
import { ELoadboardsPanelTab } from '../constants/detailsPanel';
import { loadboardFieldsConfig } from '../constants/grid.constants';
import { ComponentType, GridColDefSelf } from '../constants/types';
import {
  ICreateLoadPanelState,
  IDetailsPanelState,
  IDriverAssignmentData,
  ILoad,
  ILoadTabPanelState,
  ILoadboardFilters,
  ILoadboardSelectOption,
  ILoadsearchTableItem,
  IPendingLoadBookStatus,
} from '../loadTypes';
import { bookLoad, toAssignmentData, toLoadInfo } from '../utils/grid.utils';
import { LoadboardDataGridFilters } from './Filters/LoadboardDataGridFilters';
import { TableColumnButtons } from './Grid/tableGridButton';
import { NoRowsOverlay } from './NoRowsOverlay';
import CustomSort from './Sort/CustomSort';
import { formatLoadSummaryToCreateLoadForm } from './dto/loadboard-create-load.dto';
import LoadboardGridConfig from './grid.config';

interface LoadboardDataGridProps {
  selectedDriverDetails: any;
  isDriverSelected: boolean;
}

const PAGE_SIZE = 50;

export const LoadboardDataGrid: FC<LoadboardDataGridProps> = observer(
  ({ selectedDriverDetails, isDriverSelected }) => {
    const apiRef = useGridApiRef();
    const {
      getGlobalTerminalsIds,
      themeMode,
      isTerminalEnabled,
      allTerminals,
      getCompanyPreferences,
    } = useRootStore();
    const {
      currentView,
      component,
      currentView: { id: stripId },
      commodityTypes,
    } = useLoadsearchLane();

    const LStorageHook = useFilterStorage({ page: component });
    const isPendingLoadTab =
      currentView.entity === ELoadBoardEntity.pendingLoad ||
      currentView.entity === ELoadBoardEntity.booked;

    const [tableColumns, setTableColumns] = useState<GridColDefSelf[]>([]);
    const [allColumns, setAllColumns] = useState<GridColDefSelf[]>([]);
    const defaultFilters =
      persistUserState.pages.Loadsearch[stripId]?.filters ||
      currentView.metaData;
    const [filters, setFilters] =
      useState<Partial<ILoadboardFilters>>(defaultFilters);
    const [newLoadPanelState, setCreateLoadPanelState] =
      useState<ICreateLoadPanelState>({
        open: false,
        data: {} as LoadDetailsSummary,
        assignmentData: null,
        raw: {} as LoadDetailsSummary,
      });
    const [loadDetailsPanelState, setLoadDetailsPanelState] =
      useState<IDetailsPanelState>({
        open: false,
        expanded: false,
      });
    const [isLoadAttributePopupOpen, setOpen] = useState<boolean>(false);
    const [selectedLoad, setSelectedLoad] = useState<ILoad>({} as ILoad);
    const [updatedSelectedRow, setUpdatedSelectedRow] = useState({});
    const [selectedRowData, setRowData] = useState<ILoadsearchTableItem>(
      {} as ILoadsearchTableItem
    );
    const [profitabilityTabRender, setProfitabilityTabRender] =
      useState<number>(0);
    const [invocationReqIdMetaData, setInvocationReqIdMetaData] =
      useState<InvocationReqIdMetaDataProps>({});
    const [pendingLoadBookStatus, setPendingLoadBookStatus] =
      useState<IPendingLoadBookStatus>({} as IPendingLoadBookStatus);
    const [loadTabPanelState, setLoadTabPanelState] =
      useState<ILoadTabPanelState>({ open: false, data: null });
    const [secondaryPanelData, setSecondaryPanelData] = useState<{
      type: keyof ComponentType;
      id: string | number;
      row: any;
    } | null>(null);
    const {
      loadboardStore: {
        getBookmarkedLoads,
        clearLoads,
        setLoadboardGridFilters,
        fetchLoadboardGridData,
        // tableData,
        sorting,
        setSorting,
        sort,
        bookMark,
        deleteLoadFromStore,
        fetchAvailableLoadboards,
        getLoadboardAccounts,
        selectedBookLoad,
        setSelectedBookLoad,
        updateProfit,
        prevFilters,
        isZoneDisabled,
        loadingInitial,
        getGridDataTable,
        fetchRow,
        triggerSearch,
        setTriggerSearch,
        searchAPIloading,
      },
    } = useStores();

    const { toLoadboardDataGridFilter } = useDispatchFindLoad();

    const gridConfig = new LoadboardGridConfig({
      loadboardAccounts: getLoadboardAccounts,
      showActualOffersOnly:
        (
          filters?.[
            loadboardFieldsConfig.showActualOffersOnly
              .fieldFilterName as keyof ILoadboardFilters
          ] as ILoadboardSelectOption
        )?.id || false,
      isZoneDisabled,
      themeMode,
    });

    const getFiltersFormConfig: IFiltersFormConfig[] =
      gridConfig.config.getFiltersFormConfig();

    const {
      setDefaultColumns,
      setDefaultFilters,
      onPageFilterChange,
      onAllFilterSubmit,
      onAllFilterReset,
      resetSettings,
      handleChangeColumns,
    } = useFilterDataGrid<ILoadboardFilters>({
      component,
      allColumns: gridConfig.config.columns,
      currentView,
      setTableColumns,
      setAllColumns,
      defaultFilters: defaultFilters,
      currentViewPrimaryKey: 'id',
      setFilters,
      isUpdateStorage: false,
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<
      GridColumnVisibilityModel | undefined
    >(undefined);

    const tableConfigurationProps = {
      TableSettings: {
        handleChangeForColumnModel: setColumnVisibilityModel,
        tableColumnsData: allColumns,
        resetSettings: resetSettings,
        handleChangeColumns: handleChangeColumns,
        tableSettingIconProps: {
          mt: 0,
          height: '48px',
        },
      },
    };

    const colVisibilityModel: Record<string, boolean> | undefined =
      useMemo(() => {
        if (!columnVisibilityModel) return undefined;
        return columnVisibilityModel;
      }, [JSON.stringify(columnVisibilityModel)]);

    const setupGridColumnVisibility = () => {
      const storageData = LStorageHook.getStorage() || {};
      const columnsDataTmp = !currentView.default
        ? currentView.columnFilters
        : storageData[currentView.id].columns;

      setColumnVisibilityModel(columnsDataTmp);
    };

    const onSearchHandler = async (newFilters?: ILoadboardFilters) => {
      const storageData = LStorageHook.getStorage() || {};
      LStorageHook.updateStorage(stripId, {
        ...storageData[stripId],
        filters: newFilters || filters,
      });
      clearGrid();
      await fetchLoadboardGridData(newFilters);
    };

    const debouncedOnSearchHandler = useMemo(
      () => debounce(onSearchHandler, 200),
      [onSearchHandler]
    );

    const onRefreshHandler = () => {
      const storageData = LStorageHook.getStorage() || {};
      if (!prevFilters) return;
      LStorageHook.updateStorage(stripId, {
        ...storageData[stripId],
        filters: prevFilters,
      });
      clearGrid();
      fetchLoadboardGridData(prevFilters);
      setFilters(prevFilters);
    };

    const handleSortModel = (sortModel: GridSortModel) => {
      if (sortModel?.length && sortModel[0]['field'] && sortModel[0]['sort']) {
        const field = sortModel[0]['field'];
        const sortBy = sortModel[0]['sort'];
        sort(field, sortBy);
      }
    };

    useEffect(() => {
      clearGrid();
    }, [currentView]);

    function CustomGridColumnMenu(props: GridColumnMenuProps) {
      return (
        <CustomSort
          value={sorting[props.currentColumn.field]?.sortingKey}
          onValueChange={(e, sortField) => {
            setSorting(props.currentColumn.field, sortField);
          }}
          {...props}
        />
      );
    }

    const unBookmark = async (data: ILoad, bookMarkId?: string | null) => {
      if (isPendingLoadTab) {
        if (bookMarkId) deleteLoadFromStore(bookMarkId);
      } else bookMark(data.id, null);
    };

    const onBook = async (
      selectedLoadData: ILoad,
      typeOfBook?: 'manualBook' | 'pending'
    ) => {
      setSelectedBookLoad(selectedLoadData);
      if (typeOfBook === 'pending') {
        return true;
      }
      if (selectedLoadData) {
        const loadDetails = await getLoadDetailsFromLSLoad(
          selectedLoadData,
          commodityTypes
        );
        const createLoadSchema = await formatLoadSummaryToCreateLoadForm({
          loadDetails,
          isTerminalEnabled,
          allTerminals,
        });
        setCreateLoadPanelState({
          open: true,
          data: createLoadSchema,
          assignmentData: toAssignmentData(
            selectedLoadData,
            selectedDriverDetails?.value?.value
          ),
          raw: loadDetails,
        });
      }
    };

    const onCloseCommonPanel = (): void => {
      setLoadDetailsPanelState({ open: false, expanded: false });
      closeLoadAttributePopup();
      setRowData({} as ILoadsearchTableItem);
      setUpdatedSelectedRow({});
    };

    const setPendingLoadConfirmation = (rowId: any, isLoadBook: boolean) => {
      if (isLoadBook) {
        setPendingLoadBookStatus({
          ...pendingLoadBookStatus,
          [rowId]: {
            isDropped: false,
            isBooked: true,
          },
        });
      }
    };

    const onNewLoadCreated = (
      data: CreateLoadRequestDTO,
      file?: File | null
    ) => {
      closeCreateLoadPanel();
      handleBookLoad(
        {
          ...data,
          assignedDriver: {
            ...newLoadPanelState?.assignmentData,
            assignToDriver: (data as any)?.assignToDriver,
          },
        } as any,
        file!
      );
      // openLoadDetailsPanel(data);
      // loadBookConfirm(selectedBookLoad);
    };

    const openLoadDetailsPanel = (data: ILoadDetails) => {
      setLoadTabPanelState({
        open: true,
        data: {
          id: data?.id!,
        },
      });
    };

    const loadBookConfirm = async (selectedBookLoad: any): Promise<void> => {
      try {
        const payload = {
          savedLoad: selectedBookLoad,
          bidStatusAction: true,
        };
        const request = new UpdateBidLoadRequest(payload);
        const response = await loadsearchService.updateBidLoad(request);
        if (response) {
          setPendingLoadConfirmation(selectedBookLoad?.id, true);
        }
      } catch (err) {
        throw err;
      }
    };
    const closeCreateLoadPanel = () => {
      setCreateLoadPanelState({
        open: false,
        data: {} as LoadDetailsSummary,
        assignmentData: null,
      });
    };

    const handleBookLoad = async (
      loadSummary: CreateLoadRequestDTO,
      // LoadDetailsSummary,
      assignedDocument: File
    ) => {
      const assignmentData: IDriverAssignmentData =
        newLoadPanelState.assignmentData as IDriverAssignmentData;
      const savedLoad = await bookLoad(
        loadSummary,
        assignedDocument,
        assignmentData,
        (loadSummary as any)?.assignedDriver
      );
      closeCreateLoadPanel();
      onCloseCommonPanel();
      if (savedLoad && !(savedLoad instanceof ServiceError)) {
        loadBookConfirm(selectedBookLoad);
        setLoadTabPanelState({
          open: true,
          data: toLoadInfo(
            loadSummary,
            savedLoad,
            assignmentData,
            (loadSummary as any).assignedDriver
          ),
        });
      }
    };

    const openLoadAttributePopup = (): void => {
      setOpen((preState) => !preState);
    };

    const closeLoadAttributePopup = (): void => {
      setOpen(false);
    };

    const profitCalculatorUpdatedValue = (data: any) => {
      const newSelectedRowData = updateProfit(selectedRowData, data);
      setRowData(newSelectedRowData);
      setUpdatedSelectedRow(newSelectedRowData);
      setProfitabilityTabRender((prev) => prev + 1);
      setOpen(false);
    };

    const onRowClick = (row: ILoadsearchTableItem) => {
      setLoadDetailsPanelState({ open: true, expanded: false });
      setRowData(row);
      setSelectedLoad(row.actions.savedLoadsData);
    };

    const appliedFilters = useMemo(() => {
      return {
        ...defaultFilters,
        ...filters,
        ...(getLoadboardAccounts?.length &&
          (filters?.sources === null || filters?.sources === undefined) && {
            sources: getLoadboardAccounts.map((items) => items[0]), //Important: selected all first account OR null
          }),
      };
    }, [getLoadboardAccounts, filters]);

    const autoFetchData = (nextFilters: Partial<ILoadboardFilters>) => {
      const storageData = LStorageHook.getStorage() || {};
      const finalFilters = {
        ...defaultFilters,
        ...nextFilters,
        ...(getLoadboardAccounts?.length &&
          (nextFilters?.sources === null ||
            nextFilters?.sources === undefined) && {
            sources: getLoadboardAccounts.map((items) => items[0]), //Important: selected all first account OR null
          }),
      };
      const hasLocation = (
        data?: IZoneValue[] | IStateValue[] | addressType | undefined
      ): boolean => {
        return (
          !!(data as IZoneValue[] | IStateValue[] | undefined)?.length ||
          !!Object.keys((data as addressType) || {}).length
        );
      };
      const hasSource = finalFilters.sources?.length;
      const hasOriginOrDestination =
        hasLocation(finalFilters.origins?.data) ||
        hasLocation(finalFilters.destinations?.data);

      if (hasSource && hasOriginOrDestination) {
        LStorageHook.updateStorage(stripId, {
          ...storageData[stripId],
          filters: finalFilters,
        });
        clearGrid();
        fetchLoadboardGridData(finalFilters);
      }
    };

    const appliedColumns = allColumns.concat([
      {
        field: 'actions',
        headerName: t('appliedColumnsActions'),
        width: 200,
        minWidth: 150,
        maxWidth: 200,
        permanent: true,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (
          params: GridRenderCellParams<any, ILoadsearchTableItem>
        ) => {
          return (
            <>
              <TableColumnButtons
                onBookmark={bookMark}
                unBookmark={unBookmark}
                isBookmarkIconRequired={true}
                pendingLoadBookConfirmationStatus={pendingLoadBookStatus}
                tabElm={stripId}
                params={params}
                handleBook={(event, params, typeOfBook) => {
                  event.stopPropagation();
                  onBook(params.row.actions.savedLoadsData, typeOfBook);
                }}
              />
            </>
          );
        },
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        width: 200,
        minWidth: 150,
        maxWidth: 200,
      },
    ]);

    //Side effect begin
    useEffect(() => {
      fetchAvailableLoadboards(getGlobalTerminalsIds);
    }, [JSON.stringify(getGlobalTerminalsIds)]);

    useEffect(() => {
      setDefaultColumns();
      setupGridColumnVisibility();
      const newFilters = setDefaultFilters();
      clearLoads();
      if (isPendingLoadTab) {
        getBookmarkedLoads(currentView.entity === ELoadBoardEntity.booked);
      } else autoFetchData(newFilters);
    }, [stripId]);

    useEffect(() => {
      if (!isPendingLoadTab) {
        autoFetchData(filters);
      }
    }, [JSON.stringify(getLoadboardAccounts)]);

    useEffect(() => {
      setDefaultColumns();
    }, [
      filters?.[
        loadboardFieldsConfig.showActualOffersOnly
          .fieldFilterName as keyof ILoadboardFilters
      ],
    ]);

    useEffect(() => {
      setDefaultFilters();
    }, [isZoneDisabled]);

    useEffect(() => {
      if (getLoadboardAccounts?.length) {
        setLoadboardGridFilters(appliedFilters);
      }
    }, [JSON.stringify(appliedFilters), JSON.stringify(getLoadboardAccounts)]);

    useEffect(() => {
      const syncNewChangeFilters = () => {
        if (selectedDriverDetails) {
          const newChangeFilters = toLoadboardDataGridFilter(
            selectedDriverDetails
          );
          setFilters((prev) => ({
            ...prev,
            ...newChangeFilters,
          }));
        }
      };
      syncNewChangeFilters();
    }, [JSON.stringify(selectedDriverDetails)]);

    useEffect(() => {
      if (triggerSearch) {
        debouncedOnSearchHandler();
        setTriggerSearch(false);
      }
    }, [triggerSearch]);
    //Side effect end

    const LoadingOverlay = () => (
      <Box sx={{ height: '100%', width: '100%', mt: '40vh' }}>
        <BouncingDotsLoader />
      </Box>
    );

    // Lazy loading begin
    const [initialRows, setInitialRows] = useState<any>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const clearGrid = () => {
      apiRef.current.setRows([]);
      setInitialRows([]);
      setRowCount(0);
    };

    const initData = async () => {
      const { slice, total } = await fetchRow({
        firstRowToRender: 0,
        lastRowToRender: PAGE_SIZE,
        sortModel: [],
        filterModel: {
          items: [],
        },
      });
      setInitialRows(slice);
      setRowCount(total);
    };

    const handleSecondaryDetailsOpen = (
      data: GridCellParams,
      event: { stopPropagation: () => void }
    ) => {
      if (data.field === loadboardFieldsConfig.MarketRate.fieldName) {
        return;
      } else if (
        data.field === loadboardFieldsConfig.loadId.fieldName &&
        data.value
      ) {
        if (loadDetailsPanelState.open) {
          onCloseCommonPanel();
        }
        event.stopPropagation();
        setSecondaryPanelData({
          type: ESecondaryDetailsPanelType.LOAD,
          id: data.value,
          row: data?.row,
        });
      }
    };
    const handleSecondaryPanelClose = (): void => {
      setSecondaryPanelData(null);
    };
    const handleSecondaryAction = (
      id: string,
      action: string,
      seqNumber: string
    ): void => {
      if (action !== ELoadStatus.DISPATCHED) {
        if (action === ELoadStatus.DELETED) {
          apiRef.current.updateRows([
            { id: secondaryPanelData?.row?.id, loadId: null, loadSeqNum: null },
          ]);
          handleSecondaryPanelClose();
        }
      }
    };

    useEffect(() => {
      if (!getGridDataTable.length) {
        return;
      }
      initData();
    }, [getGridDataTable]);

    const handleFetchRows = useCallback(
      async (params: GridFetchRowsParams) => {
        const { slice, total } = await fetchRow(params);
        apiRef.current.unstable_replaceRows(params.firstRowToRender, slice);
        setRowCount(total);
      },
      [apiRef, fetchRow]
    );

    const debouncedHandleFetchRows = useMemo(
      () => debounce(handleFetchRows, 200),
      [handleFetchRows]
    );
    // Lazy loading end

    return (
      <Box
        className={'LoadboardDataGrid-root'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'hidden',
          height: 'calc(100% - 64px)',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            '> div': {
              ml: 0,
            },
          }}
        >
          <CommonDataGrid
            onCellClick={handleSecondaryDetailsOpen}
            getRowId={(row: ILoadsearchTableItem) => row.id}
            pageName="Loadboards"
            onSortModelChange={handleSortModel}
            customeGridColumnMenu={CustomGridColumnMenu}
            colVisibilityModel={colVisibilityModel}
            totalNumberOfRows={getGridDataTable.length}
            tableColumnsData={appliedColumns}
            loading={loadingInitial || searchAPIloading}
            settingsColumnData={allColumns}
            settingsPanel={false} //set to false to use custom GEAR icon
            filterColumns={getFiltersFormConfig}
            isDisableColumnMenu={false}
            rowHeight={45}
            pinnedColumns={{ right: ['actions'] }}
            handleChangeColumns={handleChangeColumns}
            defaultFilterValue={appliedFilters}
            NoRowsOverlay={NoRowsOverlay}
            displayFilters={false} //set false to use customFilterComponent
            customFilterComponent={
              !isPendingLoadTab &&
              currentView?.entity !== ELoadBoardEntity.booked ? (
                <LoadboardDataGridFilters
                  filterColumns={getFiltersFormConfig}
                  defaultFilterValue={appliedFilters}
                  onPageFilterChange={onPageFilterChange}
                  onAllFilterSubmit={(data: any) => {
                    const updatedFilters = onAllFilterSubmit(data);
                    onSearchHandler({
                      ...filters,
                      ...updatedFilters,
                    });
                  }}
                  onAllFilterReset={onAllFilterReset}
                  tableConfigurationProps={tableConfigurationProps}
                  onSearchHandler={() => onSearchHandler()}
                  onRefreshHandler={prevFilters && onRefreshHandler}
                />
              ) : (
                () => null
              )
            }
            components={{
              LoadingOverlay: LoadingOverlay,
            }}
            tableDensity="comfortable"
            checkboxSelection={false}
            title={''}
            handleSelectItem={onRowClick}
            apiRef={apiRef}
            tableData={initialRows}
            lazyLoadingSettings={{
              rowCount: rowCount,
              onFetchRows: debouncedHandleFetchRows,
            }}
          />
        </Box>
        {loadDetailsPanelState.open && selectedLoad && (
          <DetailPanel
            hasLoadAttributes={true}
            openLoadAttributePopup={openLoadAttributePopup}
            closeLoadAttributePopup={closeLoadAttributePopup}
            isLoadAttributePopupOpen={isLoadAttributePopupOpen}
            selectedLoad={selectedLoad}
            selectedRowData={
              Object.keys(updatedSelectedRow) &&
              Object.keys(updatedSelectedRow).length > 0
                ? updatedSelectedRow
                : selectedRowData
            }
            profitabilityTabRender={profitabilityTabRender}
            selectedDriverDetails={selectedDriverDetails}
            isDriverSelected={isDriverSelected}
            profitCalculatorUpdatedValue={profitCalculatorUpdatedValue}
            invocationReqIdMetaData={invocationReqIdMetaData}
            setInvocationReqIdMetaData={setInvocationReqIdMetaData}
            onClose={onCloseCommonPanel}
            pendingLoadBookConfirmationStatus={pendingLoadBookStatus}
            actionBidBook={
              <>
                <TableColumnButtons
                  onBookmark={bookMark}
                  unBookmark={unBookmark}
                  isBookmarkIconRequired={false}
                  pendingLoadBookConfirmationStatus={pendingLoadBookStatus}
                  tabElm={stripId}
                  params={
                    {
                      row: selectedRowData,
                    } as GridRenderCellParams<any, ILoadsearchTableItem, any>
                  }
                  handleBook={(event, params, typeOfBook) => {
                    event.stopPropagation();
                    onBook(params?.row?.actions?.savedLoadsData, typeOfBook);
                  }}
                />
              </>
            }
            onBookHandler={(event, params, typeOfBook) => {
              onBook(params?.row?.actions?.savedLoadsData, typeOfBook);
            }}
            defaultTab={ELoadboardsPanelTab.TRIP_PLAN}
            expanded={loadDetailsPanelState.expanded}
          />
        )}
        {secondaryPanelData && (
          <SecondaryDetailsPanel
            type={secondaryPanelData.type}
            id={secondaryPanelData.id}
            onAction={handleSecondaryAction}
            onClose={handleSecondaryPanelClose}
          />
        )}
        {loadTabPanelState.open && (
          <LoadTabPanel
            onClose={() => {
              setLoadTabPanelState({
                open: false,
                data: null,
              });
            }}
            data={{ id: loadTabPanelState?.data.id as string }}
            customerId={loadTabPanelState.data?.customerId || 0}
            handleFactoringResponse={() => undefined}
            handleUpdatedInvoice={() => undefined}
            onDeleted={() => undefined}
            triggerAlert={() => undefined}
          />
        )}
        <CreateLoad
          open={newLoadPanelState.open}
          value={newLoadPanelState?.data as ILoadDetails}
          disableLoadCreation={true}
          onSubmit={onNewLoadCreated}
          onClose={closeCreateLoadPanel}
          assignmentData={newLoadPanelState?.assignmentData}
        />
      </Box>
    );
  }
);
