import { CloseOutlined } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { PER_LOADED_MILE_KEY } from '../../constants/fieldOptions';
import { SECTION_LAST_ELEMENT_ID_LIST } from '../../constants/fieldValues';
import { rateFormService } from '../../services/rate.service';
import {
  ActionButtonWithoutBoldStyles,
  captionTextSpanStyle,
} from '../../style';
import { toFixDigit } from '../../utils';

interface IProps {}

const StopFooter: React.FC<IProps> = () => {
  const { watch, setValue, getValues } = useFormContext();
  const miles = watch('totalMiles');
  const feeDetail = watch('rate.feeDetail');
  const isTotalMilesManuallyUpdate = watch('isTotalMilesManuallyUpdate');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  const [totalMiles, setTotalMiles] = useState<number | string>();

  useEffect(() => {
    setTotalMiles(miles);
  }, [miles]);

  const handleCloseEditMiles = () => {
    setTotalMiles(miles);
    toggleEditMode();
  };
  const handleAcceptManualMiles = () => {
    setValue('totalMiles', totalMiles);
    setValue('isTotalMilesManuallyUpdate', true);
    if (feeDetail?.itemCode === PER_LOADED_MILE_KEY) {
      setValue('rate.quantity', totalMiles);
    }
    const additionalFees = getValues('rate.additionalFees');
    const invoiceRate = getValues('rate.rate');
    if (!additionalFees?.length) return;
    const updatedFees = additionalFees?.map((e: any) => {
      const isPerLoadedType =
        e?.feeDetail?.unit === PER_LOADED_MILE_KEY ||
        e?.unit === PER_LOADED_MILE_KEY;
      const qty = isPerLoadedType ? totalMiles : e?.quantity;
      return {
        ...e,
        quantity: qty,
        total: isPerLoadedType
          ? rateFormService.calculateLineItemRateByUnit({
              quantity: qty,
              rate: Number(invoiceRate),
              unit: e?.feeDetail?.unit,
            })
          : e?.total,
      };
    });
    setValue('rate.additionalFees', updatedFees);
    toggleEditMode();
  };

  const resetTotalMiles = () => {
    setValue('isTotalMilesManuallyUpdate', false);
    setIsEditMode(false);
  };

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      useFlexGap
      justifyContent={'space-between'}
      padding={'8px 16px'}
      mt={2}
      sx={(theme: Theme) => ({ background: theme.palette.grey['100'] })}
    >
      {isEditMode ? (
        <>
          <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
            <Typography variant="h7" fontWeight={'bold'}>
              Loaded Miles
            </Typography>
            <TextField
              sx={{ width: '155px' }}
              size="small"
              autoFocus
              value={totalMiles}
              onChange={(event) => setTotalMiles(event?.target?.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mi</InputAdornment>
                ),
              }}
            />
            {/* <Typography variant="body3">Reason for override</Typography> */}
          </Stack>
          <Stack direction={'row'} alignItems={'center'}>
            {isTotalMilesManuallyUpdate && (
              <Button
                onClick={resetTotalMiles}
                variant="text"
                sx={ActionButtonWithoutBoldStyles}
              >
                Reset
              </Button>
            )}
            <Tooltip title={'Cancel'}>
              <IconButton
                size="medium"
                onClick={handleCloseEditMiles}
                sx={{ p: 0.5 }}
              >
                <CloseOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Accept miles">
              <IconButton
                size="large"
                color="primary"
                sx={{ p: 0.5 }}
                onClick={handleAcceptManualMiles}
              >
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </>
      ) : (
        <>
          <Typography variant="h7" fontWeight={'bold'}>
            Loaded Miles
            <span style={captionTextSpanStyle}>
              {' '}
              (
              {isTotalMilesManuallyUpdate
                ? 'updated manually'
                : 'estimated by PC Miler'}
              ){' '}
            </span>
          </Typography>
          <Box>
            <Tooltip title="Change miles">
              <Button
                variant="text"
                onClick={toggleEditMode}
                id={SECTION_LAST_ELEMENT_ID_LIST[2]}
              >
                Edit
              </Button>
            </Tooltip>
            <Typography variant="h7">
              {toFixDigit(miles)}{' '}
              <span style={captionTextSpanStyle}>miles</span>
            </Typography>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default StopFooter;
