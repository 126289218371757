import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { CancelToken } from 'axios';
import _ from 'lodash';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { API_RESOURCE_URL } from '../../../api/impl/DispatchService';
import { http } from '../../../axios/axios.service';
import { DispatchFiltersName } from '../../../constants/gantt/gantt.const';
import { QueryParams } from '../../../models';
import { GanttDriverLocationMarkersResponse } from '../../../models/DTOs/Dispatch/Dispatch';
import {
  filterDriverListByLocation,
  getDriverLocationRequestPayload,
} from '../../../services/map/driverLocation.service';
import { GridColDefSelf } from '../../../types';
import { IGanttFilters } from '../../../types/DispatchTypes';
import { defaultDispatch2Filters } from '../../../views/dispatch2/constants/dispatch';
import type { IDispatchFilters } from '../../../views/dispatch2/constants/types';
import { EGanttTabPanel } from '../../../views/dispatch2/constants/types';
import {
  getDispatchGanttColumnHeaderNames,
  getGanttGridColumnsVisiblityModel,
} from '../../../views/dispatch2/services';
import { getSortOptions } from '../../../views/dispatch2/utils/filters.utils';
import { ITripFilters } from '../../../views/trips/constants/types';
import { filterToQuery } from '../../../views/trips/services/queryParams.utils';
import { TSetData, setData } from '../../utils';

export interface IGapAssignment {
  id: string;
  driverGroupId: string;
  startDate: Date | string;
  endDate: Date | string;
  prevLoadId?: string;
  nextLoadId?: string;
  terminalId?: string | null;
  driverGroupName?: string | null;
}

export interface IBulkOptymizationInput {
  driverIds: Array<string>;
  tripIds: Array<string>;
  startBulkOptymization?: boolean;
  allDriversCount?: number;
  allTripsCount?: number;
  availableNonHoldTripCount?: number;
  driverFilters?: IGanttFilters;
  tripFilters?: Partial<ITripFilters>;
  openPreferenceSettings?: boolean;
  previousSandboxId?: string | null;
  onPreferenceUpdate?: any;
  resumePreviousOptimization?: boolean;
}

interface IDispatchBulkOptimizationInputs {
  driverIds?: Array<string>;
  tripIds?: Array<string>;
  resourceType?: 'DRIVER' | 'TRIP';
}
export class Dispatch2Store {
  @observable
  refreshDriverIdsList: number[] = [];

  @observable mapData: GanttDriverLocationMarkersResponse | null = null;

  @observable assignTripToGap: IGapAssignment | null = null;
  @observable bulkOptymizationInputs: IBulkOptymizationInput = {
    driverIds: [],
    tripIds: [],
    allDriversCount: 0,
    allTripsCount: 0,
    availableNonHoldTripCount: 0,
    startBulkOptymization: false,
  };
  @observable cancelTripAssignment = false;
  @observable displayMode: EGanttTabPanel = EGanttTabPanel.Gantt;
  @observable filters: IDispatchFilters = {
    filters: {
      ...defaultDispatch2Filters.filters,
      [DispatchFiltersName.startDate]: undefined,
    },
    query: {},
  };
  @observable ganttColumnToHide: GridColDefSelf[] =
    getDispatchGanttColumnHeaderNames();
  @observable isLoading = false;
  @observable mapInstance: TrimbleMaps.Map | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get getDriverIdsListToRefresh() {
    return this.refreshDriverIdsList;
  }

  @computed
  get getMapData() {
    return this.mapData;
  }

  @computed
  get gapForTripAssignment(): IGapAssignment | null {
    return toJS(this.assignTripToGap);
  }

  @action.bound
  setRefreshDriverIdsList(list: Array<number>) {
    this.refreshDriverIdsList = list;
  }

  @action.bound
  clearRefreshDriverIdsList() {
    this.refreshDriverIdsList = [];
  }

  @computed
  get getBulkOptymizationInputs(): IBulkOptymizationInput {
    return this.bulkOptymizationInputs;
  }

  @action
  fetchMapdata = async ({
    filters,
    getGlobalTerminalsIds,
    isMarkerActionDisabled = false,
    cancelToken,
    signal,
  }: {
    filters: IDispatchFilters;
    getGlobalTerminalsIds: string[];
    isMarkerActionDisabled?: boolean;
    signal?: AbortSignal;
    cancelToken?: CancelToken;
  }): Promise<void> => {
    try {
      this.isLoading = true;
      const requestBody = getDriverLocationRequestPayload({
        filters,
        terminalIds: getGlobalTerminalsIds,
      });
      const requestParams = new QueryParams(true);
      const response = (
        await http.post({
          apiEndpoint: API_RESOURCE_URL.driver.getDriverMapMarkers,
          params: requestParams,
          data: requestBody,
          cancelToken,
          signal,
        })
      )?.data;
      const { locatedDrivers: markers, missingDrivers: missingMarkers } =
        filterDriverListByLocation({
          drivers: response?.drivers!,
          currentWeek: response?.currentWeek,
          hosConstants: response?.hosConstants,
          isTooltipActionDisabled: isMarkerActionDisabled,
        });
      if (
        _.isEqual(this.mapData?.markers, markers) &&
        _.isEqual(this.mapData?.missingMarkers, missingMarkers)
      ) {
        return;
      }
      this.mapData = {
        markers,
        missingMarkers,
        hosConstants: response?.hosConstants,
        numberOfDrivers: response?.numberOfDrivers,
      };
    } catch (e: any) {
      if (e?.message === 'canceled') return;
      console.log('aaa');
      this.mapData = null;
    } finally {
      this.isLoading = false;
    }
  };

  @action.bound
  setTripAssignmentForGap(gapDetails: IGapAssignment | null) {
    this.assignTripToGap = gapDetails;
  }

  @action.bound
  updateBulkOptymizationDriverInput(newDriverIds: Array<string>) {
    this.bulkOptymizationInputs.driverIds = newDriverIds;
  }

  @action.bound
  updateBulkOptymizationTripInput(allTripIds: Array<string>) {
    if (!allTripIds) return;
    this.bulkOptymizationInputs.tripIds = allTripIds;
  }

  @action.bound
  startBulkOptymization() {
    this.bulkOptymizationInputs.resumePreviousOptimization = false;
    this.bulkOptymizationInputs.startBulkOptymization = true;
  }
  @action.bound
  resumePreviousOptimization = (previousSandboxId: string) => {
    this.bulkOptymizationInputs.previousSandboxId = previousSandboxId;
    this.bulkOptymizationInputs.resumePreviousOptimization = true;
    this.bulkOptymizationInputs.startBulkOptymization = true;
  };

  @action.bound
  stopBulkOptymization() {
    this.bulkOptymizationInputs.startBulkOptymization = false;
  }
  @action.bound
  setPreviousSandboxId(id: string | null) {
    this.bulkOptymizationInputs.previousSandboxId = id;
  }

  @action.bound
  clearBulkOptymizationInputs() {
    this.bulkOptymizationInputs = {
      driverIds: [],
      tripIds: [],
    };
  }

  @action.bound
  removeDriverFromBulkOptymization(allDriverIds: Array<string>) {
    allDriverIds?.forEach((driverId) => {
      const index = this.bulkOptymizationInputs.driverIds?.findIndex(
        (id) => id === driverId
      );
      if (index >= 0) this.bulkOptymizationInputs?.driverIds?.splice(index, 1);
    });
  }

  @action.bound
  removeTripFromBulkOptymization(tripId: string) {
    const index = this.bulkOptymizationInputs.tripIds?.findIndex(
      (id) => id === tripId
    );
    if (index >= 0) this.bulkOptymizationInputs?.tripIds?.splice(index, 1);
  }

  @action.bound
  setAllDriversCount(count: number) {
    this.bulkOptymizationInputs.allDriversCount = count;
  }

  @action.bound
  setAllTripsCount(count: number) {
    this.bulkOptymizationInputs.allTripsCount = count;
  }

  @action.bound
  setAllNonHoldTripsCount(count: number) {
    this.bulkOptymizationInputs.availableNonHoldTripCount = count;
  }

  @action.bound
  setDriverFilters(driverFilters: IBulkOptymizationInput['driverFilters']) {
    this.bulkOptymizationInputs.driverFilters = driverFilters;
  }

  @action.bound
  setTripFilters(tripFilters: IBulkOptymizationInput['tripFilters']) {
    this.bulkOptymizationInputs.tripFilters = {
      ...filterToQuery(tripFilters as Partial<ITripFilters>, false),
      tripStatusList: ['AVAILABLE'],
    } as unknown as Partial<ITripFilters>;
  }
  @action.bound
  updatePreferenceSettings(val: boolean) {
    this.bulkOptymizationInputs.openPreferenceSettings = val;
  }

  @action
  setDisplayMode = (newValue: TSetData<EGanttTabPanel>) => {
    this.displayMode = setData(newValue, this.displayMode);
  };

  @action
  setFilters = (newValue: TSetData<IDispatchFilters> | IDispatchFilters) => {
    if (
      (newValue as IDispatchFilters)?.filters &&
      !(newValue as IDispatchFilters)?.filters?.sortFilter?.value
    )
      (newValue as IDispatchFilters).filters.sortFilter = getSortOptions()?.[0];
    this.filters = setData(newValue, this.filters);
  };

  @action
  resetFilters = () => {
    this.filters = defaultDispatch2Filters;
  };

  @action setGanttColumnToHide = (config: { [key: string]: boolean }) => {
    if (config && Object?.keys?.(config)?.length)
      this.ganttColumnToHide = getGanttGridColumnsVisiblityModel(config);
    else this.ganttColumnToHide = getDispatchGanttColumnHeaderNames();
  };

  @action resetGanttColumnHeaders = () => {
    this.ganttColumnToHide = getDispatchGanttColumnHeaderNames();
  };

  @action setOnPreferenceUpdateCallback = (callback: any) => {
    this.bulkOptymizationInputs.onPreferenceUpdate = callback;
  };

  @action onAfterPreferencesUpdate = () => {
    this.bulkOptymizationInputs?.onPreferenceUpdate?.();
  };

  @action setMapInstance = (map: TrimbleMaps.Map | null) => {
    this.mapInstance = map;
  };

  @computed get currentDriversCountToOptimize(): number {
    if (this.bulkOptymizationInputs?.driverIds?.length > 0)
      return this.bulkOptymizationInputs?.driverIds?.length;
    return this.bulkOptymizationInputs.allDriversCount!;
  }

  @computed get currentTripsCountToOptimize(): number {
    if (this.bulkOptymizationInputs?.tripIds?.length > 0)
      return this.bulkOptymizationInputs?.tripIds?.length;
    return this.bulkOptymizationInputs.availableNonHoldTripCount!;
  }
}

export const createDispatch2GlobalStore = () => new Dispatch2Store();
