import { Divider, DividerProps } from '@mui/material';

// Create Custom Propos Object that extends MUI defaults and adds any of our custom
type CustomDividerProps = DividerProps & {
  isSubheader?: boolean;
};

// Define our customized Divider Component
const customizedComponent = ({
  isSubheader = false,
  ...props
}: CustomDividerProps) => {
  // Create the styles for the subheader version of our divider component
  const subHeaderStyles = isSubheader
    ? {
        pl: 0,
        '.MuiDivider-wrapper': {
          pl: 0,
          textTransform: 'uppercase',
          fontSize: '14px',
        },
        '&::before': {
          width: '0',
        },
      }
    : {};

  // Create a merged styles object that takes any additional MUI sx props and combines them with the subHeaderStyles. This allows us to assign both together in ones sx prop below
  const mergedSxStyles = { ...subHeaderStyles, ...props.sx };

  return <Divider {...props} sx={mergedSxStyles} />;
};

// Export our customized component as standard Divider name
export { customizedComponent as Divider };
