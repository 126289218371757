import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { dispatchService, loadService, tripService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import * as models from '../../../models';
import { PaginatedTripList, TripsRevenueTotalResponse } from '../../../models';
import { ChangeStatusBulkSelectUnassignedTripsRequest } from '../../../models/DTOs/Dispatch/Requests';
import {
  GetTripDetailByIdQueryParams,
  PaginatedTripListRequest,
  TripWarningByIdsRequest,
} from '../../../models/DTOs/Trip/Requests';
import { getMissingDocumentsWarning } from '../../../subPages/loadsList/LoadController';
import { LoadDetailsSummary } from '../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { LoadListWarning } from '../../../subPages/loadsList/models/LoadListWarning';
import { ComponentType } from '../../../types';
import { loadAndTripPageSize } from '../../../utils';
import { defaultFilters } from '../../../views/dispatch2/components/Trip/grid.config';
import * as types from '../../../views/trips/constants/types';
import {
  ITripFilters,
  ITripQueryParamsTable,
} from '../../../views/trips/constants/types';
import { getQueryParamsTable } from '../../../views/trips/services/queryParams.utils';
import { TSetData, setData } from '../../utils';

interface IDispatchSecondaryPanel {
  type: keyof ComponentType;
  id: string | number;
  isGlobal?: boolean;
  autoExpanded?: boolean;
  defaultTab?: string;
  driverId?: number | null;
}

const defaultSettingsPagination: types.ISettingsPagination = {
  isLoading: false,
  first: false,
  last: false,
  pageNumber: 0,
  pageSize: loadAndTripPageSize,
};

export enum IPanelName {
  DRIVER = 'DRIVER',
  TRIP = 'TRIP',
}

const defaultCountMetrics = {
  plannedTripCount: 0,
  availableTripCount: 0,
};
class Dispatch2TripsStore {
  @observable data: models.Trip[] = [];
  @observable settingsPagination: types.ISettingsPagination =
    defaultSettingsPagination;
  @observable totalTableItems = 0;
  @observable filters: types.ITripFilters = defaultFilters;
  @observable secondaryPanelData: IDispatchSecondaryPanel | null = null;
  @observable fullscreenPanel: IPanelName | null = null;
  @observable newLoadData: LoadDetailsSummary | null = null;
  @observable themeResponsive: {
    isMobile?: boolean;
    isTablet?: boolean;
  } = {
    isMobile: false,
    isTablet: false,
  };
  @observable gridActionState: types.IGridActionState | null = null;
  @observable editingId: string | null = null;
  @observable tripCountMetrics: models.ITripCountMetrics = defaultCountMetrics;
  @observable revenueTotalResponse: TripsRevenueTotalResponse | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get getEditingId(): string | null {
    return this.editingId;
  }

  @computed
  get tableList() {
    return {
      totalRows: this.totalTableItems,
      data: this.data,
    };
  }

  @computed
  get getSecondaryPanelData() {
    return toJS(this.secondaryPanelData);
  }

  @computed get getGridActionState(): types.IGridActionState | null {
    return toJS(this.gridActionState);
  }

  @action.bound
  setEditingId(newId: string | null) {
    this.editingId = newId;
  }

  @action
  setThemeResponsive = (
    themeResponsive: TSetData<{
      isMobile?: boolean;
      isTablet?: boolean;
    }>
  ) => {
    this.themeResponsive = setData(themeResponsive, this.themeResponsive);
  };

  @action resetFilters = () => {
    this.filters = defaultFilters;
  };

  @action
  updateWarning = async (
    response: models.PaginatedTripList,
    newTableList: models.Trip[]
  ): Promise<void> => {
    const warningsResponse = await tripService.getTripWarningsByIds(
      new TripWarningByIdsRequest(response.content.map(({ id }) => id))
    );
    const warnings: { [key: string]: any } = {};
    if (!(warningsResponse instanceof ServiceError)) {
      warningsResponse?.data?.forEach((w: any) => {
        warnings[w.tripId] = w.warning;
      });
    }

    response.content = response.content.map((trip) => {
      if (warnings[trip.id]) {
        const tripListWarning = new LoadListWarning(warnings[trip.id], 'Trip');
        tripListWarning?.setupDetentionTime(
          trip.status,
          trip.tripDetentionTime
        );
        return {
          ...trip,
          missingDocuments: getMissingDocumentsWarning(warnings[trip.id]),
          warning: tripListWarning,
        };
      }
      return trip;
    });
    const newContent = newTableList.map((preRecord) => {
      const newRecord = response.content.find(
        (item) => item.id === preRecord.id
      );
      if (newRecord) {
        return newRecord;
      }
      return preRecord;
    });
    this.data = newContent;
  };

  @action
  updateTripWarning(response: PaginatedTripList, newTableList: models.Trip[]) {
    response.content = response.content.map((trip) => {
      const warning = trip.tripWarning;
      if (warning) {
        const tripListWarning = new LoadListWarning(warning, 'Trip');
        tripListWarning?.setupDetentionTime(
          trip.status,
          trip.tripDetentionTime
        );
        return {
          ...trip,
          missingDocuments: getMissingDocumentsWarning(warning),
          warning: tripListWarning,
        };
      }
      return trip;
    });
    const newContent: any = newTableList.map((preRecord) => {
      const newRecord = response.content.find(
        (item) => item.id === preRecord.id
      );
      if (newRecord) {
        return newRecord;
      }
      return preRecord;
    });
    this.data = newContent;
  }

  @action
  getTableList = async (queryParams: ITripQueryParamsTable): Promise<void> => {
    this.settingsPagination = {
      ...this.settingsPagination,
      isLoading: true,
    };
    try {
      if (queryParams.pageNumber === 1) {
        this.getTripsRevenueTotal(queryParams);
      }
      const response = await tripService.getPaginatedTripList(
        new PaginatedTripListRequest({
          ...queryParams,
          calculateMetrics: true,
        }),
        true
      );
      if (response instanceof PaginatedTripList) {
        this.settingsPagination = {
          ...this.settingsPagination,
          isLoading: false,
          first: response.first,
          last: response.last,
          pageNumber: response.number + 1,
        };
        this.setTripCountMetric(response?.tripCountMetrics!);
        const items = response.content || [];
        let newTableList = [];
        if (response.number === 0) {
          //First page
          newTableList = items;
        } else {
          newTableList = [...this.data, ...items];
        }
        this.data = newTableList;
        this.totalTableItems = response.totalElements;
        this.updateTripWarning(response, newTableList);
      } else {
        this.settingsPagination = {
          ...this.settingsPagination,
          pageNumber: this.settingsPagination.pageNumber - 1,
          isLoading: false,
        };
      }
    } catch (e) {
      this.settingsPagination = {
        ...this.settingsPagination,
        isLoading: false,
      };
    }
  };

  @action
  fetchMainData = async ({
    nextPageNumber,
    nextFilters,
  }: {
    nextPageNumber: number;
    nextFilters: ITripFilters;
  }): Promise<void> =>
    this.getTableList(
      getQueryParamsTable({
        nextPageNumber: nextPageNumber,
        nextFilters: nextFilters,
        pageSize: this.settingsPagination.pageSize,
        skipBrokeredTrip: true,
      })
    );

  @action
  updateMainData = async (
    {
      deletedIds,
      updatedIds,
    }: {
      deletedIds?: string[];
      updatedIds?: string[];
    },
    callback?: (trips: models.Trip[]) => void
  ) => {
    let response = new models.PaginatedTripList();
    let newTableList = this.data.concat();
    const newDeletedIds: string[] = deletedIds || [];
    if (updatedIds) {
      const content = await updatedIds.reduce<Promise<models.Trip[]>>(
        async (listP, tripId): Promise<models.Trip[]> => {
          const tripResponse = await tripService.getTripDetailById(
            new GetTripDetailByIdQueryParams({
              tripId: tripId,
            })
          );
          const list = await listP;
          if (tripResponse instanceof ServiceError || !tripResponse.id) {
            newDeletedIds.push(tripId);
            return list;
          }
          return [...list, tripResponse];
        },
        Promise.resolve([])
      );
      if (content) {
        response = new models.PaginatedTripList();
        response.content = content;
        const items: models.Trip[] = response.content || [];
        items.forEach((item) => {
          const index = newTableList.findIndex(({ id }) => id === item.id);
          if (
            ![ELoadStatus.AVAILABLE, ELoadStatus.ASSIGNMENT_PLANNED].includes(
              item.status
            )
          ) {
            if (index >= 0) {
              newTableList.splice(index, 1);
            }
          } else if (index < 0) {
            newTableList.unshift(item);
          } else {
            newTableList.splice(index, 1, item);
          }
        });
      }
    }
    newTableList = newTableList.filter(({ id }) => !newDeletedIds.includes(id));
    this.data = newTableList;
    if (response.content?.length) {
      await this.updateWarning(response, newTableList);
    }
    callback?.(newTableList);
  };

  @action
  setFilters = (filters: TSetData<types.ITripFilters>) => {
    this.filters = setData(filters, this.filters);
  };

  @action
  setSettingsPagination = (
    settingsPagination: TSetData<types.ISettingsPagination>
  ) => {
    this.settingsPagination = setData(
      settingsPagination,
      this.settingsPagination
    );
  };

  @action
  setSecondaryPanelData = (
    secondaryPanelData: TSetData<IDispatchSecondaryPanel | null>
  ) => {
    if (secondaryPanelData) {
      this.secondaryPanelData = setData(
        {
          ...secondaryPanelData,
          isGlobal: this.themeResponsive.isTablet,
          autoExpanded: this.themeResponsive.isTablet,
        } as IDispatchSecondaryPanel,
        this.secondaryPanelData
      );
    } else {
      this.secondaryPanelData = null;
    }
  };

  @action
  setFullscreen = (fullscreenPanel: TSetData<IPanelName | null>) => {
    this.fullscreenPanel = setData(fullscreenPanel, this.fullscreenPanel);
  };

  @action
  setNewLoadData = (newLoadData: TSetData<LoadDetailsSummary | null>) => {
    this.newLoadData = setData(newLoadData, this.newLoadData);
  };

  @action setGridActionState = (newData: types.IGridActionState | null) => {
    this.gridActionState = newData;
  };

  @action changeStatusBulkSelect = async (
    toStatus: types.EUnassignedTripMenuAction
  ): Promise<void> => {
    try {
      if (!this.getGridActionState?.ids) return;
      const requestData = new ChangeStatusBulkSelectUnassignedTripsRequest({
        tripIds: this.getGridActionState.ids,
        status:
          toStatus === types.EUnassignedTripMenuAction.MARK_AVAILABLE
            ? ELoadStatus.AVAILABLE
            : ELoadStatus.OFFERED_TO_DRIVER,
      });
      const response =
        await dispatchService.changeStatusBulkSelectUnassignedTrips(
          requestData
        );
      runInAction(() => {
        if (!(response instanceof ServiceError)) {
          if (toStatus === types.EUnassignedTripMenuAction.MARK_AVAILABLE) {
            this.updateMainData({ updatedIds: this.getGridActionState?.ids });
          } else {
            this.updateMainData({ deletedIds: this.getGridActionState?.ids });
          }
          this.gridActionState = null;
        } else {
          //
        }
      });
    } catch (e) {}
  };

  @action changeStatusManualDriverSelection = async ({
    loadAssignmentStatus,
    terminalId,
    loadId,
    groupId,
    prevLoadId,
    onSuccess,
    onFailure,
    autoPositioning,
  }: {
    loadAssignmentStatus: ELoadStatus;
    terminalId: string;
    loadId: string;
    groupId: string;
    prevLoadId?: string | null;
    onSuccess?: () => void;
    onFailure?: () => void;
    autoPositioning?: boolean;
  }): Promise<void> => {
    const requestData = new models.AssignDriverToLoadRequest({
      loadId: loadId,
      groupId: groupId,
      autoPositioning: autoPositioning ?? !prevLoadId,
      loadAssignmentStatus: loadAssignmentStatus,
      terminalId: terminalId,
      prevLoadId,
    });
    const response = await loadService.assignDriverToLoad(requestData);

    runInAction(() => {
      if (!(response instanceof ServiceError)) {
        onSuccess?.();
        if (loadAssignmentStatus === ELoadStatus.OFFERED_TO_DRIVER) {
          this.updateMainData({ deletedIds: [loadId] });
        } else {
          this.updateMainData({ updatedIds: [loadId] });
        }
      } else {
        onFailure?.();
      }
    });
  };

  @action setTripCountMetric = (metric: models.ITripCountMetrics) => {
    if (!metric) return;
    this.tripCountMetrics = metric;
  };

  @action
  getTripsRevenueTotal = async (
    queryParams: ITripQueryParamsTable
  ): Promise<void> => {
    const response: any = await tripService.getTripsRevenueTotal(
      new PaginatedTripListRequest(queryParams)
    );

    if (!(response instanceof ServiceError) && response !== null)
      this.revenueTotalResponse = response;
  };
}

export const createDispatchTripStore = () => new Dispatch2TripsStore();
