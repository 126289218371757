import * as MUIcons from '@mui/icons-material';
import { Box, Skeleton, SvgIcon } from '@mui/material';
import { omit } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFilterStorage from '../../services/storage';
import { CustomViewActions, View, ViewMap } from '../../types';
import { AllUsersIcon } from '../../ui-kit/components/Assets';
import TabsV2 from '../../ui-kit/components/TabsV2';
import {
  getAvailableViews,
  getOwnCustomViews,
  validateView,
  viewClose,
  viewCopy,
  viewCreate,
  viewDelete,
  viewUpdate,
} from '../../utils/customView';
import AddViewV2 from '../AddView/AddViewV2';
import ViewDataModal from '../AddView/components/ViewDataModalV2';
import { DirtyDetailsPanelManager } from '../DetailsPanel/utils';
import { useStores } from './../../store/root.context';
type IconList = typeof MUIcons;

type PagePropTypes = {
  defaultViews: View[];
  formDefaultValuesMap: ViewMap;
  viewForm: React.ElementType;
  defaultView: View;
  modalTitle: string;
  entities: string[];
  isCustomActionsAvailable?: boolean; //hide the plus icon
  disableAPI?: boolean; //hide the plus icon
  component?: string;
  dictionary?: string;
  currentView: View;
  setCurrentView: (view: View) => void;
};

type Location = {
  state: { [key: string]: string | number };
};

const CustomViews = ({
  defaultViews,
  formDefaultValuesMap,
  viewForm,
  defaultView,
  modalTitle,
  entities,
  isCustomActionsAvailable = true,
  disableAPI = false,
  component,
  dictionary,
  currentView,
  setCurrentView,
}: PagePropTypes) => {
  const { state } = useLocation() as Location;
  const stripIdFromState = state?.stripId;
  const ViewForm = viewForm;
  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};
  const defaultViewsArr = defaultViews.map((view) => new View(view));
  const [customViews, setCustomViews] = useState<View[]>([]);
  const [addViewPanelOpen, setAddViewPanelOpen] = useState<boolean>(false);
  const [editableView, setEditableView] = useState<null | View>(null);
  const [backendValidationErrors, setBackendValidationErrors] = useState<{
    [key: string]: string;
  } | null>(null);
  const Icons: IconList = MUIcons;

  useEffect(() => {
    const getOwnViewList = async () => {
      const ownViews = await getOwnCustomViews(dictionary, entities);
      setCustomViews(ownViews);
      [...defaultViewsArr, ...ownViews].map((view: View) => {
        if (stripIdFromState && view.id === stripIdFromState) {
          LStorageHook.updateStorage('stripId', view.id);
          setCurrentView?.(view);
        } else {
          if (view.id === storageData.stripId) {
            setCurrentView?.(view);
          }
        }
      });
    };
    if (disableAPI) {
      setCustomViews([]);
      setCurrentView?.(defaultViewsArr[0]);
    } else {
      getOwnViewList();
    }
  }, []);

  const handleViewCreate = async (data: View, cb: (data: View) => void) => {
    const validationResult = await validateView(
      data.id,
      data.name,
      data.entity || dictionary,
      dictionary,
      entities
    );
    if (validationResult) {
      const { hasErrors, errors } = validationResult;
      if (hasErrors) {
        setBackendValidationErrors(errors);
        return;
      }
    } else {
      if (!data.entity) {
        data.entity = dictionary;
      }
      const newView = await viewCreate(data, dictionary);
      if (newView) {
        LStorageHook.updateStorage('stripId', newView.id);
        setCustomViews((views) => [...views, newView]);
        setCurrentView(newView);
        cb(data);
      }
    }
  };

  const handleViewCopy = async (id: string) => {
    const copiedView = await viewCopy(id, dictionary);
    if (copiedView) {
      LStorageHook.updateStorage('stripId', copiedView.id);
      setCustomViews((views) => [...views, copiedView]);
      setCurrentView(copiedView);
    }
  };

  const handleViewUpdate = async (data: View, cb: (data: View) => void) => {
    const validationResult = await validateView(
      data.id,
      data.name,
      data.entity,
      dictionary,
      entities
    );
    if (validationResult) {
      const { hasErrors, errors } = validationResult;
      if (hasErrors) {
        setBackendValidationErrors(errors);
        return;
      }
    } else {
      delete data?.columns;
      const updatedView = await viewUpdate(data, dictionary);
      if (updatedView) {
        LStorageHook.updateStorage('stripId', updatedView.id);
        const updatedViews = customViews.map((view: View) => {
          if (view.id === updatedView.id) {
            return updatedView;
          }
          return view;
        });
        setCustomViews(updatedViews);
        setCurrentView(updatedView);
        cb(data);
      }
    }
  };

  const handleViewClose = async (view: View) => {
    const viewId = view!.id as string;
    const closedViewId = await viewClose(viewId, dictionary);
    if (closedViewId) {
      LStorageHook.updateStorage('stripId', defaultView.id);
      const updatedViews = customViews.filter((view) => view.id !== viewId);
      setCustomViews(updatedViews);
      setCurrentView(defaultView);
    }
  };

  const handleViewDelete = async () => {
    const viewId = editableView!.id as string;
    const deletedViewId = await viewDelete(viewId, dictionary);
    if (deletedViewId) {
      LStorageHook.updateStorage('stripId', defaultView.id);
      const updatedViews = customViews.filter((view) => view.id !== viewId);
      setEditableView(null);
      setCustomViews(updatedViews);
      setCurrentView(defaultView);
    }
  };

  const handleStripChange = (currentView: View) => {
    LStorageHook.updateStorage('stripId', currentView.id);
    setCurrentView(omit(currentView, 'actions', 'label', 'key'));
  };

  const getAvailableViewList = async (): Promise<View[] | undefined> => {
    const views = await getAvailableViews(dictionary, entities);
    return views || [];
  };

  const handleResetCustomErrors = () => setBackendValidationErrors(null);

  const availableActions = (view: View): CustomViewActions[] => {
    const actions = [
      {
        label: 'Hide View',
        iconName: 'CancelOutlined',
        source: 'MUIcons',
        key: 'hide',
        onClick: () => {
          if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
          handleViewClose(view);
        },
      },
    ];
    if (!view.parentId) {
      actions.unshift({
        label: 'Edit',
        iconName: 'EditOutlined',
        source: 'MUIcons',
        key: 'edit',
        onClick: () => {
          if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
          setEditableView(
            omit(view, 'actions', 'label', 'key', 'icon', 'default')
          );
        },
      });
    }
    return actions;
  };

  const convertedTabsData = (): View[] => {
    return [...defaultViewsArr, ...customViews].map((view) => {
      view.columns = view?.columns || view?.columnFilters;
      view.key = view.id;
      view.label = view.name;
      view.icon = view.iconName ? (
        <SvgIcon
          style={{ color: '#001122' }}
          component={Icons[view.iconName as keyof IconList]}
          inheritViewBox
        />
      ) : (
        view.icon || <AllUsersIcon color="#001122" />
      );
      if (!view.default) {
        view.actions = [...availableActions(view)];
      }
      return view;
    });
  };

  if (currentView) {
    return (
      <Box className={'CustomViews-root'} sx={{ flex: 1 }}>
        <Box p={0}>
          <TabsV2<'id', View>
            keyName="id"
            tabs={convertedTabsData()}
            setSelectedTab={(tab) => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              handleStripChange(tab);
            }}
            selectedTab={currentView.id}
            onAddTabItem={() => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              setAddViewPanelOpen(true);
            }}
            isCustomActionsAvailable={isCustomActionsAvailable}
          />
        </Box>
        {isCustomActionsAvailable && (
          <AddViewV2
            addViewPanelOpen={addViewPanelOpen}
            onAddViewPanelClose={() => setAddViewPanelOpen(false)}
            component={component}
            title={modalTitle}
            contentRenderer={() => (
              <ViewForm
                mode={'create'}
                customErrors={backendValidationErrors}
              />
            )}
            onCreate={handleViewCreate}
            onCopy={handleViewCopy}
            getAvailableViews={getAvailableViewList}
            formDefaultValuesMap={formDefaultValuesMap}
            defaultEntity={defaultView.entity}
            handleResetCustomErrors={handleResetCustomErrors}
          />
        )}

        {editableView && (
          <ViewDataModal
            component={component}
            handleClose={() => {
              setEditableView(null);
              handleResetCustomErrors();
            }}
            open={true}
            title={editableView.name}
            contentRenderer={() => (
              <ViewForm mode={'edit'} customErrors={backendValidationErrors} />
            )}
            onSave={handleViewUpdate}
            defaultValues={editableView}
            onViewDelete={handleViewDelete}
          />
        )}
      </Box>
    );
  }
  return <Skeleton variant="rectangular" />;
};

export default observer(CustomViews);
