import React, { FC } from 'react';
import ThreeDotMenuIcon, { IProps } from '../ThreeDotMenuIcon';

const StyledThreeDotMenuIcon: FC<IProps> = (props) => {
  return (
    <ThreeDotMenuIcon
      className="tab-three-dots-icon"
      styleProps={{
        width: 20,
        height: 20,
        p: 0,
        svg: {
          height: 20,
          width: 'unset',
        },
      }}
      {...props}
    />
  );
};

export default StyledThreeDotMenuIcon;
