import React, { useEffect, useMemo, useState } from 'react';
import {
  ELoadboardsPanelTab,
  ILoadboardsPanelTab,
  loadboardsPanelTabs,
} from '../../constants/detailsPanel';
import TabsV2 from '../../../../ui-kit/components/TabsV2';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';

const getTabItems = (): ILoadboardsPanelTab[] => {
  return loadboardsPanelTabs;
};

const DetailsTabStrips: React.FC<{
  defaultTab?: ELoadboardsPanelTab;
  onChange: (selected: ELoadboardsPanelTab) => void;
  keys: ELoadboardsPanelTab[];
  options: Record<string, any>;
}> = ({ onChange, defaultTab, keys, options }) => {
  const tabItems: ILoadboardsPanelTab[] = useMemo(() => {
    return getTabItems().filter((item: ILoadboardsPanelTab) =>
      keys.includes(item.key)
    );
  }, [keys]);

  const getTab = (tabKey?: ELoadboardsPanelTab): ILoadboardsPanelTab =>
    tabItems.find(({ key }) => key === tabKey) || tabItems[0];

  const [selected, setSelected] = useState<ILoadboardsPanelTab>(
    getTab(defaultTab)
  );

  useEffect(() => {
    setSelected(getTab(defaultTab));
  }, [defaultTab]);

  const handleStripChange = (selected: ILoadboardsPanelTab) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    setSelected(selected);
    onChange(selected.key);
  };

  return (
    <TabsV2
      keyName="key"
      isDarkMode
      autoFill
      labelCentered
      tabs={tabItems}
      setSelectedTab={(tab: ILoadboardsPanelTab) => {
        handleStripChange(tab);
      }}
      selectedTab={selected.key}
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
      {...options}
    />
  );
};

const getTabByKey = (
  key: ELoadboardsPanelTab
): ILoadboardsPanelTab | undefined => {
  return getTabItems().find((tab) => tab.key === key);
};

export const tabStripRendererByKeyFS = (key: ELoadboardsPanelTab) => {
  return (
    <TabsV2<'key', ILoadboardsPanelTab>
      keyName="key"
      tabs={[getTabByKey(key)].filter(Boolean) as ILoadboardsPanelTab[]}
      selectedTab={getTabByKey(key)?.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

export default DetailsTabStrips;
