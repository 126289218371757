import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { tripService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  PaginatedTripList,
  PreferencesDTO,
  Trip,
  TripsRevenueTotalResponse,
} from '../../../models';
import {
  GetTripDetailByIdQueryParams,
  PaginatedTripListRequest,
  TripWarningByIdsRequest,
} from '../../../models/DTOs/Trip/Requests';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { getMissingDocumentsWarning } from '../../../subPages/loadsList/LoadController';
import { LoadListWarning } from '../../../subPages/loadsList/models/LoadListWarning';
import { loadAndTripPageSize } from '../../../utils';
import { defaultFilters } from '../components/TripDataGrid/grid.config';
import {
  ISettingsPagination,
  ITripFilters,
  ITripQueryParamsTable,
} from '../constants/types';
import { getQueryParamsTable } from '../services/queryParams.utils';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/root.context';

interface SafetyAlertContextInterface {
  tableList: {
    totalRows: number;
    data: Trip[];
  };
  getTableList: (queryParams: ITripQueryParamsTable) => Promise<void>;

  settingsPagination: ISettingsPagination;
  setSettingsPagination: React.Dispatch<
    React.SetStateAction<ISettingsPagination>
  >;

  filters: ITripFilters;
  setFilters: React.Dispatch<React.SetStateAction<ITripFilters>>;
  resetFilters: () => void;

  fetchMainData: (params: {
    nextPageNumber: number;
    nextFilters: ITripFilters;
  }) => Promise<void>;
  updateMainData: (
    updateData: {
      deletedIds?: string[];
      updatedIds?: string[];
    },
    callback?: (updatedTrips: Trip[]) => void
  ) => Promise<void>;
  preferences: PreferencesDTO | null;
  revenueTotalResponse: TripsRevenueTotalResponse | null;
}
const defaultSettingsPagination: ISettingsPagination = {
  isLoading: false,
  first: false,
  last: false,
  pageNumber: 0,
  pageSize: loadAndTripPageSize,
};

export const TripContext = createContext<SafetyAlertContextInterface>({
  settingsPagination: defaultSettingsPagination,
  tableList: {
    totalRows: 0,
    data: [],
  },
  filters: {},
} as unknown as SafetyAlertContextInterface);

export const TripProvider: FC = observer(
  ({ children }: { children?: ReactNode | undefined }) => {
    const [tableList, setTableList] = useState<Trip[]>([]);
    const { tripsStore } = useStores();
    const [settingsPagination, setSettingsPagination] =
      useState<ISettingsPagination>(defaultSettingsPagination);

    const [totalTableItems, setTotaTableItems] = useState(0);
    const [revenueTotalResponse, setRevenueTotalResponse] =
      useState<TripsRevenueTotalResponse | null>(null);

    const [filters, setFilters] = useState<ITripFilters>(defaultFilters);

    const [preferences, setPreferences] = useState<PreferencesDTO | null>(null);

    const resetFilters = (): void => {
      setFilters(defaultFilters);
    };

    const updateWarning = async (
      response: PaginatedTripList,
      newTableList: Trip[]
    ): Promise<void> => {
      tripsStore.updateWarning(response, newTableList);
      const warningsResponse = await tripService.getTripWarningsByIds(
        new TripWarningByIdsRequest(response.content.map(({ id }) => id))
      );
      const warnings: { [key: string]: any } = {};
      if (!(warningsResponse instanceof ServiceError)) {
        warningsResponse?.data?.forEach((w: any) => {
          warnings[w.tripId] = w.warning;
        });
      }

      response.content = response.content.map((trip) => {
        if (warnings[trip.id]) {
          const tripListWarning = new LoadListWarning(
            warnings[trip.id],
            'Trip'
          );
          tripListWarning?.setupDetentionTime(
            trip.status,
            trip.tripDetentionTime
          );
          return {
            ...trip,
            missingDocuments: getMissingDocumentsWarning(warnings[trip.id]),
            warning: tripListWarning,
          };
        }
        return trip;
      });
      const newContent = newTableList.map((preRecord) => {
        const newRecord = response.content.find(
          (item) => item.id === preRecord.id
        );
        if (newRecord) {
          return newRecord;
        }
        return preRecord;
      });
      setTableList(newContent);
    };

    const getTripsRevenueTotal = async (
      queryParams: ITripQueryParamsTable
    ): Promise<void> => {
      tripsStore.getTripsRevenueTotal(queryParams);
      const response = await tripService.getTripsRevenueTotal(
        new PaginatedTripListRequest(queryParams)
      );

      if (!(response instanceof ServiceError) && response !== null)
        setRevenueTotalResponse(response);
    };

    const getTableList = async (
      queryParams: ITripQueryParamsTable
    ): Promise<void> => {
      tripsStore.getTableList(queryParams);
      setSettingsPagination((oldState) => {
        return {
          ...oldState,
          isLoading: true,
        };
      });

      try {
        const response = await tripService.getPaginatedTripList(
          new PaginatedTripListRequest(queryParams)
        );
        if (queryParams.pageNumber === 1) {
          getTripsRevenueTotal(queryParams);
        }
        if (response instanceof PaginatedTripList) {
          setSettingsPagination((oldState) => {
            return {
              ...oldState,
              isLoading: false,
              first: response.first,
              last: response.last,
              pageNumber: response.number + 1,
            };
          });
          const items = response.content || [];
          let newTableList = [];
          if (response.number === 0) {
            //First page
            newTableList = items;
          } else {
            newTableList = [...tableList, ...items];
          }
          setTableList(newTableList);
          setTotaTableItems(response.totalElements);

          updateWarning(response, newTableList);
        }
      } catch (e) {
        setSettingsPagination((oldState) => {
          return {
            ...oldState,
            isLoading: false,
          };
        });
      }
    };

    const fetchMainData = async ({
      nextPageNumber,
      nextFilters,
    }: {
      nextPageNumber: number;
      nextFilters: ITripFilters;
    }): Promise<void> =>
      getTableList(
        getQueryParamsTable({
          nextPageNumber: nextPageNumber,
          nextFilters: nextFilters,
          pageSize: settingsPagination.pageSize,
        })
      );

    const updateMainData = async (
      {
        deletedIds,
        updatedIds,
      }: {
        deletedIds?: string[];
        updatedIds?: string[];
      },
      callback?: (trips: Trip[]) => void
    ) => {
      tripsStore.updateMainData({
        deletedIds,
        updatedIds,
      });
      let response = new PaginatedTripList();
      let newTableList = tableList.concat();
      const newDeletedIds: string[] = deletedIds || [];
      if (updatedIds) {
        const content = await updatedIds.reduce<Promise<Trip[]>>(
          async (listP, tripId): Promise<Trip[]> => {
            const tripResponse = await tripService.getTripDetailById(
              new GetTripDetailByIdQueryParams({
                tripId: tripId,
              })
            );
            const list = await listP;
            if (tripResponse instanceof ServiceError || !tripResponse.id) {
              newDeletedIds.push(tripId);
              return list;
            }
            return [...list, tripResponse];
          },
          Promise.resolve([])
        );
        if (content) {
          response = new PaginatedTripList();
          response.content = content;
          const items: Trip[] = response.content || [];
          items.forEach((item) => {
            const index = newTableList.findIndex(({ id }) => id === item.id);
            if (index < 0) {
              newTableList.unshift(item);
            } else {
              newTableList.splice(index, 1, item);
            }
          });
        }
      }
      newTableList = newTableList.filter(
        ({ id }) => !newDeletedIds.includes(id)
      );
      setTableList(newTableList);
      if (response.content?.length) {
        await updateWarning(response, newTableList);
      }
      callback?.(newTableList);
    };

    const { getCompanyPreferences } = useRootStore();

    useEffect(() => {
      if (getCompanyPreferences) setPreferences(getCompanyPreferences);
    }, [getCompanyPreferences]);

    return (
      <TripContext.Provider
        value={{
          tableList: {
            totalRows: totalTableItems,
            data: tableList,
          },
          getTableList: getTableList,

          filters,
          setFilters,
          resetFilters,

          settingsPagination,
          setSettingsPagination,

          fetchMainData,
          updateMainData,

          preferences,
          revenueTotalResponse,
        }}
      >
        {children}
      </TripContext.Provider>
    );
  }
);
export const useTripSettings = () => useContext(TripContext);
