import { createContext, useContext, useEffect, useState } from 'react';
import {
  ExpenseCategory,
  FuelTypesType,
  OptionType,
  PayStatementDatesType,
} from '../models';
import {
  getDocumentTypeList,
  getStateOptions,
} from '../subPages/expenses/utils/utils';
import {
  getExpenseCategoriesFullList,
  getFuelTypesList,
  getPayStatementDates,
} from '../subPages/payStatement/utils/api.utils';
import { LineItem } from '../types';
import { getActiveRoles } from '../views/settings/roles/constants/helper';
import StorageManager from '../StorageManager/StorageManager';

export type StaticDataType = {
  stateOptions: OptionType[];
  fuelTypes: FuelTypesType[];
  expenseCategories: ExpenseCategory[];
  documentTypes: LineItem[];
  payStatementDates: PayStatementDatesType;
};

export const StaticDataContext = createContext<{
  staticData: StaticDataType;
  getStaticData: () => void;
}>({});

export const StaticDataContextProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [staticData, setStaticData] = useState<StaticDataType>({
    stateOptions: [],
    fuelTypes: [],
    expenseCategories: [],
    documentTypes: [],
    payStatementDates: {},
  });

  const getStaticData = async () => {
    const stateOptions = await getStateOptions();

    const fuelTypes = await getFuelTypesList();
    //
    const expenseCategories = await getExpenseCategoriesFullList();
    const documentTypes = await getDocumentTypeList();
    const payStatementDates = await getPayStatementDates();

    setStaticData({
      stateOptions,
      fuelTypes: fuelTypes.content,
      expenseCategories: expenseCategories,
      documentTypes,
      payStatementDates,
    });
  };

  useEffect(() => {
    getStaticData();
    getRolesList();
  }, []);

  const getRolesList = async () => {
    try {
      const getRes = await getActiveRoles();
      if (getRes?.data as any[]) {
        const roles: any = {};
        getRes?.data?.map((d: any) => {
          roles[d.code] = d.name;
        });
        StorageManager.setItem('rolesList', roles);
      }
    } catch (e) {}
  };

  return (
    <StaticDataContext.Provider value={{ staticData, getStaticData }}>
      {children}
    </StaticDataContext.Provider>
  );
};

export const useStaticData = () => useContext(StaticDataContext);
