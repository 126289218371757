import React from 'react';
import Box from '@mui/material/Box';
import Table from '../Common/Table';
import {
  IMPORT_EXPENSE_NAME,
  useImport,
} from '../../../../../contexts/ImportContext';
import Footer from '../Common/Footer';
import ImportActions from '../../Import';
import {
  AxeleExpenseTempalteCodes,
  fileTypeCodesMap,
} from '../Landing/constants';
import Typography from '@mui/material/Typography';
import { importDefaultDateType, possibleDeductionTypesMap } from './constants';
import { capitalizeFirstLetter } from '../../../../../utils';

export default function UploadedResult() {
  const {
    tableData,
    tableColumns,
    setTableData,
    setFile,
    setWarnings,
    handleNext,
    sessionId,
    handleReset,
    currentEntity: { routeName, dictionary, name: entityName },
    handleSkip,
    setKpiInfo,
    selectedFileType,
  } = useImport();

  const handleOnGoBack = async () => {
    await ImportActions.cancelImport(sessionId, routeName, dictionary);
    setTableData(null);
    setFile(null);
    setWarnings([]);
    handleReset();
    ImportActions.deductionType = '';
    ImportActions.selectedDateFormat = importDefaultDateType;
  };

  const handleOnGoNext = async () => {
    if (
      AxeleExpenseTempalteCodes.includes(fileTypeCodesMap[selectedFileType]) ||
      entityName !== IMPORT_EXPENSE_NAME
    ) {
      handleNext();
    } else {
      const result = await ImportActions.extractAndCreate(
        sessionId,
        routeName,
        dictionary
      );
      if (result) {
        setKpiInfo(result);
        handleSkip(2);
      }
    }
  };

  return (
    <>
      <Box sx={{ mt: 4, height: '100%' }}>
        <Box
          sx={{
            mb: 4,
            color: 'rgba(0, 17, 34, 0.75)',
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          The first row has been selected as the header by default. This is a
          sample from the file, go next to do final extraction.
        </Box>
        {entityName === IMPORT_EXPENSE_NAME &&
          possibleDeductionTypesMap[ImportActions.deductionType] && (
            <Typography variant={'h7'}>
              <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                Note:
              </span>
              {` ${capitalizeFirstLetter(
                possibleDeductionTypesMap[ImportActions.deductionType]
              )} deductions will be created for imported records`}
            </Typography>
          )}
        <Box sx={{ display: 'flex', width: '100%', mt: 1, height: '80%' }}>
          {!!tableColumns.length && (
            <Table
              tableData={tableData}
              tableColumns={tableColumns}
              hideFooter
            />
          )}
        </Box>
      </Box>
      <Footer onBack={handleOnGoBack} onNext={handleOnGoNext} />
    </>
  );
}
