import { ITabV2 } from '../ui-kit/components/TabsV2';

export const contactPreferenceOptions = [
  { id: 'PREFERRED', name: 'Preferred' },
  { id: 'PROHIBITED', name: 'Prohibited' },
];

// @TODO: Anna -convert to static
export const customerTypeOptions = [
  { id: 'Shipper', name: 'Shipper' },
  { id: 'Broker', name: 'Broker' },
];

export const countryCodeOptions = [{ label: '+1', key: 'USA' }];

export enum EQUIPMENT_PANEL_TABS {
  GENERAL = 'GENERAL',
  ALERTS = 'ALERTS',
  MAINTENANCE = 'MAINTENANCE',
  PAYMENT_TERMS = 'PAYMENT_TERMS',
  DOCUMENT = 'DOCUMENT',
  NOTES = 'NOTES',
}

export interface equipmentPanelTab extends ITabV2 {
  key: EQUIPMENT_PANEL_TABS;
}

export const equipmentPanelTabs: equipmentPanelTab[] = [
  {
    key: EQUIPMENT_PANEL_TABS.ALERTS,
    label: 'Alerts Tab',
    iconName: 'TabAlerts',
    source: 'AxeleIcon',
  },
  {
    key: EQUIPMENT_PANEL_TABS.MAINTENANCE,
    label: 'Maintenance',
    iconName: 'TabMaintenance',
    source: 'AxeleIcon',
  },
  {
    key: EQUIPMENT_PANEL_TABS.PAYMENT_TERMS,
    label: 'Payment Terms',
    iconName: 'TabPaymentTerms',
    source: 'AxeleIcon',
  },
  {
    key: EQUIPMENT_PANEL_TABS.DOCUMENT,
    label: 'Documents',
    iconName: 'SnippetFolderOutlined',
    source: 'MUIcons',
  },
  {
    key: EQUIPMENT_PANEL_TABS.NOTES,
    label: 'Notes',
    iconName: 'SpeakerNotesOutlined',
    source: 'MUIcons',
  },
];

export const equipmentGeneralTab: equipmentPanelTab = {
  key: EQUIPMENT_PANEL_TABS.GENERAL,
  label: 'General',
  iconName: 'TabGeneral',
  source: 'AxeleIcon',
};
