import React from 'react';
import Typography from '@mui/material/Typography';

export interface MultilineCellProps {
  title: string;
  subtitle: string;
  titleStyle?: any;
  subtitleStyle?: any;
  alignTitle?: any;
  alignSubtitle?: any;
}

const MultilineCellAxele = ({
  alignTitle = 'left',
  alignSubtitle = 'left',
  title = '',
  subtitle = '',
  titleStyle = {},
  subtitleStyle = {},
}: MultilineCellProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
      <Typography variant="subtitle1" sx={titleStyle} align={alignTitle}>
        {title}
      </Typography>
      <Typography variant="caption" sx={subtitleStyle} align={alignSubtitle}>
        {subtitle}
      </Typography>
    </div>
  );
};

export default MultilineCellAxele;
