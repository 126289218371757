import { useState } from 'react';
import { Switch } from '@mui/material';
import { ImplicitAssignUpdateRequest } from '../../../../models';
import { teamDriverServiceType } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';

export const TeamDriverTableSwitcher = ({
  defaultChecked,
  groupId,
  fieldName,
}: {
  defaultChecked: boolean;
  groupId: string;
  fieldName: 'assignImplicitTractor' | 'assignImplicitTrailer';
}) => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleCheckedChange = async (checked: boolean) => {
    const request = new ImplicitAssignUpdateRequest();
    request[fieldName] = checked;
    request.driverGroupId = groupId;
    const result = await teamDriverServiceType.implicitAssignUpdate(request);
    if (!(result instanceof ServiceError)) {
      setChecked(checked);
    }
  };

  return (
    <Switch
      onChange={(event, checked) => handleCheckedChange(checked)}
      checked={checked}
    />
  );
};
