import { IAutocompleteOption } from '../../../trips/constants/types';
import { EPaymentMethod, EPaymentStatus, ICreatePaymentForm } from './types';

export const paymentMethods: IAutocompleteOption[] = [
  { label: 'Cash', value: EPaymentMethod.CASH },
  { label: 'Check', value: EPaymentMethod.CHECK },
  { label: 'Credit Card', value: EPaymentMethod.CREDIT_CARD },
  { label: 'Bank Account', value: EPaymentMethod.BANK_ACCOUNT },
  { label: 'Other', value: EPaymentMethod.OTHER },
];

export const paymenStatuses: IAutocompleteOption[] = [
  { label: 'Paid', value: EPaymentStatus.PAID },
  { label: 'Closed', value: EPaymentStatus.CLOSED },
];

export const defaultCreatePaymentData: ICreatePaymentForm = {
  paymentDate: new Date(),
  paymentMethod: null,
  paymentReferenceId: null,
  amountReceived: null,
  memo: null,
  customerId: null,
  isAdvanced: false,
  invoicePayDTO: [],
};
