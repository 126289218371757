import { Theme, useTheme } from '@mui/material';
import ZingChart from 'zingchart-react';
import 'zingchart/es6';
import { ZingGuiSettings } from './zingchart.settings';

const defaultSeri = {
  'border-width': 3,
  'line-style': 'solid',
  'border-left': 'none',
  'border-right': 'none',
  'border-bottom': 'none',
};
export interface BarGraphProps {
  value1: number;
  value2: number;
  title?: string;
  stacked?: boolean;
  backgroundColor?: string;
  theme: Theme;
}
const BarGraph = ({
  value1,
  value2,
  backgroundColor = 'transparent',
  title,
  stacked = false,
  theme,
}: BarGraphProps) => {
  return {
    type: 'bar',
    ...(title && {
      title: {
        text: title,
        'font-family': theme.typography.h8.fontFamily,
        'font-size': '12px',
        'font-weight': theme.typography.h8.fontWeight,
        'line-height': '16.8px',
        'text-align': 'center',
        color: theme.palette.text.secondary,
        height: '100%',
        marginBottom: 0,
        verticalAlign: 'bottom',
      },
    }),
    backgroundColor: backgroundColor,
    scaleY: {
      lineColor: 'none',
      tick: {
        visible: false,
      },
      guide: {
        visible: false,
      },
      item: {
        visible: false,
      },
    },
    scaleX: {
      visible: false,
    },
    plotarea: {
      margin: 0,
    },
    plot: {
      barSpace: 0,
      stacked: stacked,
      detach: false,
      hoverState: {
        visible: false,
      },
    },
    tooltip: {
      visible: false,
    },
    series: [
      {
        values: [value1],
        backgroundColor: stacked
          ? `${theme.palette.primary.light} ${theme.palette.primary.contrast}`
          : `${theme.palette.info.light} ${theme.palette.primary.contrast}`,
        'border-color': stacked
          ? `${theme.palette.primary.main}`
          : `${theme.palette.info.main}`,
        ...defaultSeri,
      },
      {
        values: [value2],
        backgroundColor: stacked
          ? `${theme.palette.warning.light} ${theme.palette.primary.contrast}`
          : `${theme.palette.primary.light} ${theme.palette.primary.contrast}`,

        'border-color': stacked
          ? `${theme.palette.warning.main}`
          : `${theme.palette.primary.main}`,
        ...defaultSeri,
      },
    ],
    gui: ZingGuiSettings,
  };
};

export interface KPIBarChartProps {
  width: number;
  height: number;
  value1: number;
  value2: number;
  title?: string;
  stacked?: boolean;
}

export const KPIBarChart = ({
  width,
  height,
  value1,
  value2,
  title,
  stacked,
}: KPIBarChartProps): JSX.Element => {
  const theme: Theme = useTheme();
  const chartData = BarGraph({ value1, value2, title, stacked, theme });
  return <ZingChart height={height} width={width} data={chartData} />;
};
