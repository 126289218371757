import { Typography, Box } from '@mui/material';

const conflictBodyTextStyles = { fontSize: '14px' };

export const conflictsPopupConfig = ({
  passedDriverGroupCount,
  failedDriverGroupCount,
}: {
  passedDriverGroupCount?: number;
  failedDriverGroupCount?: number;
}) => ({
  open: true,
  titleText: (
    <Typography
      variant="h6"
      fontSize="21px"
      fontWeight={500}
      color={'text.primary'}
      lineHeight={'160%'}
    >
      Conflict detected for some drivers
    </Typography>
  ),
  contentRenderer: () => (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
      <Typography sx={conflictBodyTextStyles}>
        {`Unable to assign trips for ${
          failedDriverGroupCount ?? ''
        } drivers as their planned assignment has changed.`}
      </Typography>
      <Box>
        <Typography sx={conflictBodyTextStyles} gutterBottom>
          Click <b>Update</b> to load the latest plan and re-run optimization.
        </Typography>
        <Typography sx={conflictBodyTextStyles}>
          Click <b>Continue</b> to save the remaining trips for the other{' '}
          {passedDriverGroupCount} drivers.
        </Typography>
      </Box>
    </Box>
  ),
  actionLabel: 'Continue',
  cancelLabel: 'Update',
  actionColor: 'primary',
  deleteActionLabel: 'Cancel',
  secondaryActionContainerStyles: { marginLeft: 'auto' },
  width: 490,
  paperStyles: { maxWidth: '490px', width: '490px', padding: '12px' },
});

export const cancelBtnProps = {
  styleProps: {
    width: '90px',
    height: '37px',
    borderRadius: '14px',
  },
};
