import { Box, Chip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UI_DATETIME_MILITARY_FORMAT_WITH_AT } from '../../../../../../../../constants/date.constants';
import { PreferencesDTO } from '../../../../../../../../models';
import { useRootStore } from '../../../../../../../../store/root-store/rootStateContext';
import {
  StopSolutionV3StopType,
  TripStopActivityEnum,
  TripStopStatusEnum,
} from '../../../../../../../../ui-kit/components/TripPlan';
import { EStopActivitiesAutofullOption } from '../../../../../../../../views/settings/preferences/constants';
import Checkbox from '../../../../../../../Ui/Checkbox';
import FormDateTimePicker from '../../../../../../../Ui/FormDateTimePicker/FormDateTimePicker';
import Switch from '../../../../../../../Ui/Switcher';
import { ELoadStatus } from '../../../../../../constants/constants';
import { AssetAssignmentFormData } from '../../../AssetAssignmentPopup';

export const toMomentTz = (dateString: string, timezone?: string): Moment => {
  if (!timezone) return moment(dateString);
  return moment(dateString).tz(timezone);
};

export const toApptTimeLabel = (
  dateString?: string | null,
  timezone?: string
): string => {
  if (!dateString) return '';
  return toMomentTz(dateString, timezone).format(
    UI_DATETIME_MILITARY_FORMAT_WITH_AT
  );
};

const stopLabelMap: { [key in TripStopStatusEnum]?: string } = {
  [TripStopStatusEnum.EN_ROUTE]: 'En route',
  [TripStopStatusEnum.ARRIVED]: 'Arrived',
  [TripStopStatusEnum.ACTIVITY_STARTED]: 'Activity Started',
  [TripStopStatusEnum.ACTIVITY_ENDED]: 'Activity Ended',
  [TripStopStatusEnum.COMPLETED]: 'Completed',
  [TripStopStatusEnum.CANCELLED]: 'Canceled',
};

export type UpdateActivityStopFormData = { [key in EFieldKey]: boolean } & {
  location: {
    city: string;
    state: string;
    timezone: string;
  };
  status: TripStopStatusEnum;
  id: number;
  type: StopSolutionV3StopType;
  activityType: TripStopActivityEnum;
  appointmentStartDate: string | null;

  actualTimeOfArrivalDate: string | null;
  loadingStartDate: string | null;
  loadingEndDate: string | null;
  actualTimeOfCompletionDate: string | null;

  actualTimeOfArrivalDisabled: boolean;
  loadingStartDisabled: boolean;
  loadingEndDisabled: boolean;
  actualTimeOfCompletionDisabled: boolean;
};

export interface UpdateActivityFormData {
  stops: UpdateActivityStopFormData[];
  tripStatus: ELoadStatus;
  prevTripStatus?: ELoadStatus;
  tripId: string;
  assetAssignmentFormData: Omit<AssetAssignmentFormData, 'driverAsset'>;
  collectLoadUnloadTiming?: boolean;
  loadId?: string;
}

export enum EFieldKey {
  MarkArrival = 'actualTimeOfArrival',
  LoadingStarted = 'loadingStart',
  LoadingDone = 'loadingEnd',
  MarkDeparture = 'actualTimeOfCompletion',
}

export const fields: Array<[EFieldKey, string]> = [
  [EFieldKey.MarkArrival, 'Mark Arrival'],
  [EFieldKey.LoadingStarted, 'Loading Started'],
  [EFieldKey.LoadingDone, 'Loading Done'],
  [EFieldKey.MarkDeparture, 'Mark Departure'],
];

const getLabel = (fieldName: EFieldKey, activityType: TripStopActivityEnum) => {
  switch (fieldName) {
    case EFieldKey.MarkArrival:
      return 'Mark Arrival';
    case EFieldKey.LoadingStarted:
      switch (activityType) {
        case TripStopActivityEnum.DROPOFF:
          return 'Unloading Started';
        case TripStopActivityEnum.PICKUP_AND_DROPOFF:
          return 'Activity Started';
        default:
          return 'Loading Started';
      }
    case EFieldKey.LoadingDone:
      switch (activityType) {
        case TripStopActivityEnum.DROPOFF:
          return 'Unloading Done';
        case TripStopActivityEnum.PICKUP_AND_DROPOFF:
          return 'Activity Done';
        default:
          return 'Loading Done';
      }
    case EFieldKey.MarkDeparture:
      return 'Mark Departure';
  }
};

export const getRenderedFields = (
  stop: UpdateActivityStopFormData,
  collectLoadUnloadTiming?: boolean
) =>
  fields.filter(
    ([name]) =>
      !['loadingStart', 'loadingEnd'].includes(name) ||
      (![StopSolutionV3StopType.RELAY, StopSolutionV3StopType.TRIP].includes(
        stop.type
      ) &&
        collectLoadUnloadTiming)
  );

interface UpdateStopFormProps {
  originalData: UpdateActivityFormData;
}

const UpdateStopForm: FC<UpdateStopFormProps> = ({ originalData }) => {
  const { getCompanyPreferences } = useRootStore();
  const { control, watch, setValue, getValues, setError, clearErrors } =
    useFormContext();
  const [preferenceData, setPreferenceData] = useState<PreferencesDTO | null>(
    null
  );
  const [, setIsRender] = useState<boolean>(false);
  const triggerRender = () => setIsRender((isRender) => !isRender);

  const [stops, tripStatus, prevTripStatus]: [
    UpdateActivityFormData['stops'],
    UpdateActivityFormData['tripStatus'],
    UpdateActivityFormData['prevTripStatus']
  ] = watch(['stops', 'tripStatus', 'prevTripStatus']);

  useEffect(() => {
    if (getCompanyPreferences) {
      setValue(
        'collectLoadUnloadTiming',
        getCompanyPreferences?.collectLoadUnloadTiming
      );
      setValue(
        'markAllActivities',
        stops.every((stop) =>
          getRenderedFields(
            stop,
            getCompanyPreferences?.collectLoadUnloadTiming
          ).every(([fieldName]) => stop[fieldName])
        )
      );
      setPreferenceData(getCompanyPreferences);
    }
  }, [getCompanyPreferences]);

  const renderActivityType = (
    type: StopSolutionV3StopType,
    activityType: TripStopActivityEnum
  ): string => {
    switch (type) {
      case StopSolutionV3StopType.LOAD: {
        switch (activityType) {
          case TripStopActivityEnum.PICKUP:
            return 'Pick Up';
          case TripStopActivityEnum.DROPOFF:
            return 'Drop Off';
          case TripStopActivityEnum.PICKUP_AND_DROPOFF:
            return 'Pick Up & Drop Off';
          default:
            return '';
        }
      }
      case StopSolutionV3StopType.RELAY:
        return 'Relay';
      case StopSolutionV3StopType.TRIP:
        return 'Trip Stop';
      default:
        return '';
    }
  };

  const setAutoDate = (
    stopIndex: number,
    dateFieldName: string,
    appointmentStartDate: string | null
  ): void => {
    const currentDateTime = moment();
    switch (preferenceData?.stopActivitiesAutofillOption) {
      case EStopActivitiesAutofullOption.CURRENT_DATE_TIME:
        setStopValue(stopIndex, dateFieldName, currentDateTime);
        break;
      case EStopActivitiesAutofullOption.APPOINTMENT_DATE_TIME:
        setStopValue(
          stopIndex,
          dateFieldName,
          appointmentStartDate || currentDateTime
        );
        break;
    }
  };

  const validate = (checkboxFieldName: string, checked: boolean) => {
    const message = 'The activity is not logged.';
    let isChecked = false;
    for (let stopIndex = stops.length - 1; stopIndex >= 0; stopIndex--) {
      const stop = stops[stopIndex];
      const renderedFields = getRenderedFields(
        stop,
        preferenceData?.collectLoadUnloadTiming
      );
      for (let j = renderedFields.length - 1; j >= 0; j--) {
        const [name] = renderedFields[j];
        const currentCheckboxFieldName = `stops[${stopIndex}].${name}`;
        const isCurrentChecked =
          currentCheckboxFieldName === checkboxFieldName
            ? checked
            : getValues(currentCheckboxFieldName);
        if (isChecked) {
          if (!isCurrentChecked)
            setError(currentCheckboxFieldName, {
              message,
            });
          else clearErrors(currentCheckboxFieldName);
        } else {
          clearErrors(currentCheckboxFieldName);
          if (isCurrentChecked) {
            isChecked = isCurrentChecked;
          }
        }
      }
    }
  };

  const setStopValue = (stopIndex: number, fieldName: string, value: any) => {
    setValue(`stops[${stopIndex}].${fieldName}`, value);
  };

  const checkPersistDisabled = (stopIndex: number, name: EFieldKey) =>
    tripStatus === ELoadStatus.LOAD_COMPLETED;
  // ||
  // (name === EFieldKey.MarkDeparture &&
  //   prevTripStatus &&
  //   prevTripStatus !== ELoadStatus.LOAD_COMPLETED &&
  //   stopIndex === 0) ||
  // (stopIndex !== 0 &&
  //   prevTripStatus &&
  //   prevTripStatus !== ELoadStatus.LOAD_COMPLETED);

  if (!preferenceData) return null;

  return (
    <Grid spacing={1} container>
      <Checkbox
        disabled={originalData.stops.every(
          (stop) => stop.status === TripStopStatusEnum.COMPLETED
        )}
        name="markAllActivities"
        label="Mark All Activities"
        onChangeCb={(markAllActivities) => {
          if (markAllActivities) {
            stops.forEach((stop, stopIndex) =>
              fields.forEach(([name]) => {
                const isPersistDisabled = checkPersistDisabled(
                  stopIndex,
                  name as EFieldKey
                );
                if (!isPersistDisabled) {
                  setAutoDate(
                    stopIndex,
                    `${name}Date`,
                    stop.appointmentStartDate
                  );
                  setStopValue(stopIndex, name, true);
                }
              })
            );
          } else {
            stops.forEach((stop, stopIndex) =>
              fields.forEach(([name]) => {
                const isPersistDisabled = checkPersistDisabled(stopIndex, name);
                if (!isPersistDisabled) {
                  setStopValue(stopIndex, `${name}Date`, null);
                  setStopValue(stopIndex, name, false);
                }
              })
            );
          }
        }}
      />
      {stops.map((stop, stopIndex) => {
        const renderedFields = getRenderedFields(
          stop,
          preferenceData?.collectLoadUnloadTiming
        );
        const displayStopStatus = originalData.stops[stopIndex].status;
        return (
          <>
            <Grid xs={12} item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      color: 'primary.main',
                      mr: 1,
                      fontSize: 24,
                      fontWeight: 700,
                    }}
                  >
                    {renderActivityType(stop.type, stop.activityType)}
                  </Box>

                  {displayStopStatus !== TripStopStatusEnum.NONE && (
                    <Chip
                      label={stopLabelMap[displayStopStatus] || ''}
                      variant="outlined"
                      sx={{
                        backgroundColor: 'info.light',
                        color: 'white',
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={(theme) => ({
                      fontWeight: 600,
                      fontSize: 20,
                      color: `${theme.palette.text.primary}!important`,
                      fontFamily: theme.typography.fontFamily,
                      textAlign: 'right',
                    })}
                  >
                    {`${[stop.location.city, stop.location.state].join(', ')}`}
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      fontWeight: 400,
                      fontSize: 12,
                      color: `${theme.palette.text.secondary}!important`,
                      fontFamily: theme.typography.fontFamily,
                    })}
                  >
                    Time Zone: {stop.location.timezone}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {renderedFields.map(([name], fieldIndex) => {
              const checkBoxFieldName = name;
              const dateFieldName = checkBoxFieldName + 'Date';
              const isDisabled =
                stop[checkBoxFieldName] === undefined
                  ? true
                  : !stop[checkBoxFieldName];
              const isPersistDisabled = checkPersistDisabled(stopIndex, name);
              return (
                <>
                  <Switch
                    disabled={isPersistDisabled}
                    control={control}
                    label={getLabel(name, stop.activityType)}
                    name={`stops[${stopIndex}].${checkBoxFieldName}`}
                    sizes={{ xs: 6 }}
                    onChange={async (e) => {
                      const checked = e.target.checked;
                      setStopValue(stopIndex, name, checked);
                      validate(
                        `stops[${stopIndex}].${checkBoxFieldName}`,
                        checked
                      );
                      if (!checked) {
                        setStopValue(stopIndex, dateFieldName, null);
                        setValue('markAllActivities', false);
                      } else {
                        const isAllChecked = stops.every((stop, stopIndex) =>
                          getRenderedFields(
                            stop,
                            preferenceData?.collectLoadUnloadTiming
                          ).every(([name]) =>
                            e.target.name === `stops[${stopIndex}].${name}`
                              ? true
                              : stop[name]
                          )
                        );
                        if (isAllChecked) {
                          setValue('markAllActivities', true);
                        }
                        setAutoDate(
                          stopIndex,
                          dateFieldName,
                          stop.appointmentStartDate
                        );
                      }
                      triggerRender();
                    }}
                  />
                  <Grid
                    item
                    xs={6}
                    sx={{
                      mt: fieldIndex === 0 ? 2 : undefined,
                    }}
                  >
                    <Typography
                      sx={(theme) => ({
                        fontFamily: theme.typography.fontFamily,
                        color: 'primary.main',
                        fontSize: 12,
                        fontWeight: 400,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        ...(isDisabled
                          ? {
                              cursor: 'not-allowed',
                              color: 'grey.a400',
                              pointerEvents: 'none',
                            }
                          : {}),
                      })}
                      component="a"
                      onClick={() => {
                        setStopValue(
                          stopIndex,
                          dateFieldName,
                          stop.appointmentStartDate
                        );
                      }}
                    >
                      Appt time :{' '}
                      {toApptTimeLabel(
                        stop.appointmentStartDate,
                        stop.location.timezone
                      )}
                    </Typography>
                    <FormDateTimePicker
                      disableFuture
                      disabled={
                        isPersistDisabled && stop[checkBoxFieldName]
                          ? false
                          : isDisabled
                      }
                      control={control}
                      name={`stops[${stopIndex}].${dateFieldName}`}
                      sizes={{ xs: 12 }}
                      timezone={stop.location.timezone}
                    />
                  </Grid>
                </>
              );
            })}
          </>
        );
      })}
    </Grid>
  );
};
export default UpdateStopForm;
