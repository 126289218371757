import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { MaintenanceHistoryConstants } from '../../../../locales/en/safety/maintenanceHistory/index';
import { ViewMap } from '../../../../types';
import { maintenanceRecordFilterFieldsConfig } from '../../constants/constants';
export enum EntityList {
  maintenanceRecords = 'maintenance',
}
export const maintView = {
  id: 'maintenance',
  name: MaintenanceHistoryConstants.maintenanceRecords,
  icon: <PeopleAltOutlinedIcon />,
  entity: EntityList.maintenanceRecords,

  shared: true,
  parentId: null,
  active: true,
  default: true,
  columnFilters: {
    historyMaintenanceItems: true,
    equipmentType: true,
    equipmentName: true,
    maintenanceVendor: true,
    completedDate: true,
    terminalName: true,
    expenseId: true,
    notes: true,
    expense: true,
  },
  metaData: {
    [maintenanceRecordFilterFieldsConfig.maintenanceItems.fieldName]: [],
    [maintenanceRecordFilterFieldsConfig.assetId.fieldName]: [],
    [maintenanceRecordFilterFieldsConfig.equipmentTypes.fieldName]: [],
    [maintenanceRecordFilterFieldsConfig.vendor.fieldName]: [],
  },
};

export const defaultViews = [maintView];

export const formDefaultValuesMap: ViewMap = {
  [EntityList.maintenanceRecords]: maintView,
};
