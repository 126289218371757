import { annotateNameAsync, IEmailService, ServiceError } from '../interfaces';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { GetEmailSecondaryReceiversRequest } from '../../models/DTOs/Email/Requests';

const getEmailSecondaryReceiversURL =
  '/web/preference/api/v2/preference/replyTo';

export class EmailService extends IEmailService {
  @annotateNameAsync
  async getSecondaryReceivers(
    requestData: GetEmailSecondaryReceiversRequest
  ): Promise<string[] | ServiceError> {
    try {
      requestData.addUserIdToParams();
      const response = await httpClient.getRaw(
        getEmailSecondaryReceiversURL,
        requestData,
        undefined
      );
      return [response.data];
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }
}
