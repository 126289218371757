import { useState } from 'react';

export const useNotesContent = () => {
  const [showAddNewForm, setShowAddNewForm] = useState(false);

  const handleShowAddNewFormChange = (show: boolean) => () =>
    setShowAddNewForm(show);

  return {
    showAddNewForm,
    handleShowAddNewFormChange,
  };
};
