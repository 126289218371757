import { FC } from 'react';
import { useGanttChartGrid } from '@/views/dispatch2/components/gantt/GanttChartGrid/hooks';
import { CommonDataGrid } from '@/ui-kit/components/DataGridPro';
import Box from '@mui/material/Box';
import { SecondaryPanelType } from '@/models/DTOs/Dispatch/Dispatch';

interface GanttChartGridProps {
  onLoadView: ((data: SecondaryPanelType) => void) | undefined;
  onTripView: ((data: SecondaryPanelType) => void) | undefined;
  onCustomerView: ((data: SecondaryPanelType) => void) | undefined;
  onDriverView: ((driverGroupId: string) => void) | undefined;
}

export const GanttChartGrid: FC<GanttChartGridProps> = ({
  onLoadView,
  onCustomerView,
  onTripView,
  onDriverView,
}) => {
  const { data, loading, columns, handleRowClick, handleSortChange } =
    useGanttChartGrid(onLoadView, onCustomerView, onTripView, onDriverView);
  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <CommonDataGrid
        onSortModelChange={handleSortChange}
        dataGridContainerHeight={'100%'}
        loading={loading}
        customFooter
        footerComponent={() => null}
        tableData={data}
        tableColumnsData={columns}
        sortingMode="server"
        settingsPanel={false}
        displayFilters={false}
        rowHeight={32}
        handleSelectItem={handleRowClick}
        pinnedColumns={{
          left: ['driverGroup'],
        }}
      />
    </Box>
  );
};
