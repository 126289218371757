import React from 'react';
import ThreeDotMenuIcon from '../../../ui-kit/components/ThreeDotMenuIcon';

export interface IDetailsPanelThreeDotMenuOption {
  id?: number;
  label: string;
  icon: any;
  disabled?: boolean;
  onClickHandler?: (
    data: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

export interface DetailsPanelThreeDotMenuProps {
  menuOptions?: IDetailsPanelThreeDotMenuOption[];
  deleteMenuOption?: IDetailsPanelThreeDotMenuOption;
  showDeleteButton?: boolean;
  onOpen3DotMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  styleProps?: React.CSSProperties;
}
export const DetailsPanelThreeDotMenu = ({
  menuOptions = [],
  showDeleteButton = true,
  deleteMenuOption,
  onOpen3DotMenu,
  styleProps = {},
}: DetailsPanelThreeDotMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    onOpen3DotMenu?.(event);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    menuOption: IDetailsPanelThreeDotMenuOption,
    entityData: any,
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const { disabled = false } = menuOption;
    if (disabled) {
      event.stopPropagation();
    } else {
      menuOption?.onClickHandler?.(entityData, event);
      setAnchorEl(null);
    }
  };

  return (
    <ThreeDotMenuIcon
      menuOptions={[...menuOptions, showDeleteButton ? deleteMenuOption : {}]}
      open={Boolean(anchorEl)}
      handleMenuClick={handleMenuClick}
      handleMenuClose={handleMenuClose}
      anchorEl={anchorEl}
      handleMenuItemClick={handleMenuItemClick}
      styleProps={{
        color: 'white',
        ...styleProps,
      }}
    />
  );
};
