import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useThemeResponsive } from '../../hooks/useThemeResponsive';
import {
  noResultContainer,
  noResultIconStyle,
  noResultTextContainer,
  noResultTextStyle,
  rightArrowStyle,
  searchHelperContainer,
  searchHelperIconStyle,
  searchHelperLinkContainer,
  searchHelperLinkContentContainer,
  searchHelperLinkStyle,
  searchHelperTextStyle,
} from './style';

const NoSearchResult: FC<{ searchValue: string }> = ({ searchValue }) => {
  const { isMobile } = useThemeResponsive();
  return (
    <Box className="globalSeachRoot">
      {searchValue && searchValue.length > 1 && (
        <Box sx={noResultContainer}>
          <ScreenSearchDesktopOutlinedIcon style={noResultIconStyle} />
          <Box sx={noResultTextContainer}>
            <Typography sx={noResultTextStyle}>
              No results for &nbsp;
            </Typography>
            <Typography sx={[noResultTextStyle, { color: 'primary.main' }]}>
              {searchValue}
            </Typography>
          </Box>
        </Box>
      )}

      <Box sx={searchHelperContainer}>
        <Box sx={noResultTextContainer}>
          <ScreenSearchDesktopOutlinedIcon sx={searchHelperIconStyle} />
          <Typography
            sx={[searchHelperTextStyle, { color: 'rgba(0, 17, 34, 0.6)' }]}
          >
            Here are a few things you can search for
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            ...(isMobile && {
              flexDirection: 'column',
            }),
          }}
        >
          <Box
            sx={{
              marginLeft: '5%',
              ...(isMobile && {
                p: 1,
                pb: 0,
                marginLeft: 'unset',
              }),
            }}
          >
            <Typography sx={searchHelperTextStyle}>
              &#9679; Load by ID and Reference # <br />
              &#9679; Web Users and Drivers by name and email <br />
              &#9679; Document name <br />
              &#9679; Tractor and Trailer by name and VIN #
            </Typography>
          </Box>
          <Box
            sx={{
              marginLeft: '15%',
              ...(isMobile && {
                p: 1,
                pt: 0,
                marginLeft: 'unset',
              }),
            }}
          >
            <Typography sx={searchHelperTextStyle}>
              &#9679; Invoice ID and Load Refrence #<br />
              &#9679; Customer name and primary contacts <br />
              &#9679; Vendor name and primary contacts <br />
              &#9679; Location name and primary contacts
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            ...searchHelperLinkContentContainer,
            ...(isMobile && {
              flexDirection: 'column',
            }),
          }}
        >
          <Box
            sx={{
              flexDirection: 'column',
              width: '30%',
              ...(isMobile && {
                width: '100%',
              }),
            }}
          >
            <Box sx={noResultTextContainer}>
              <StickyNote2OutlinedIcon sx={searchHelperIconStyle} />
              <Typography
                sx={[searchHelperTextStyle, { color: 'rgba(0, 17, 34, 0.6)' }]}
              >
                Getting Started
              </Typography>
            </Box>
            <Box sx={searchHelperLinkContainer}>
              <Link sx={searchHelperLinkStyle} href="/settings/users">
                Add Users <ArrowForwardOutlinedIcon sx={rightArrowStyle} />
              </Link>
              <Link
                sx={searchHelperLinkStyle}
                href="https://help.loadops.com/getting-started	"
                target="_blank"
              >
                Tutorial Guides
                <ArrowForwardOutlinedIcon sx={rightArrowStyle} />
              </Link>
              <Link
                sx={searchHelperLinkStyle}
                href="https://help.loadops.com/"
                target="_blank"
              >
                Learn <ArrowForwardOutlinedIcon sx={rightArrowStyle} />
              </Link>
              <Link sx={searchHelperLinkStyle} href="#"></Link>
            </Box>
          </Box>

          <Box
            sx={{
              flexDirection: 'column',
              width: '30%',
              ...(isMobile && {
                width: '100%',
              }),
            }}
          >
            <Box sx={noResultTextContainer}>
              <SettingsOutlinedIcon sx={searchHelperIconStyle} />
              <Typography
                sx={[searchHelperTextStyle, { color: 'rgba(0, 17, 34, 0.6)' }]}
              >
                System
              </Typography>
            </Box>
            <Box sx={searchHelperLinkContainer}>
              <Link sx={searchHelperLinkStyle} href="/settings/integrations">
                Integrations <ArrowForwardOutlinedIcon sx={rightArrowStyle} />
              </Link>
              <Link sx={searchHelperLinkStyle} href="/settings/preferences">
                Settings
                <ArrowForwardOutlinedIcon sx={rightArrowStyle} />
              </Link>
              <Link sx={searchHelperLinkStyle} href="/settings/import">
                Import Data <ArrowForwardOutlinedIcon sx={rightArrowStyle} />
              </Link>
              <Link sx={searchHelperLinkStyle} href="#"></Link>
            </Box>
          </Box>

          <Box
            sx={{
              flexDirection: 'column',
              width: '30%',
              ...(isMobile && {
                width: '100%',
              }),
            }}
          >
            <Box sx={noResultTextContainer}>
              <ManageSearchOutlinedIcon sx={searchHelperIconStyle} />
              <Typography
                sx={[searchHelperTextStyle, { color: 'rgba(0, 17, 34, 0.6)' }]}
              >
                Popular Searches
              </Typography>
            </Box>
            <Box sx={searchHelperLinkContainer}>
              <Link sx={searchHelperLinkStyle} href="/loads/loadboards">
                Load Search <ArrowForwardOutlinedIcon sx={rightArrowStyle} />
              </Link>
              <Link sx={searchHelperLinkStyle} href="/">
                My Loads
                <ArrowForwardOutlinedIcon sx={rightArrowStyle} />
              </Link>
              <Link sx={searchHelperLinkStyle} href="/dispatch">
                Dispatch <ArrowForwardOutlinedIcon sx={rightArrowStyle} />
              </Link>
              <Link sx={searchHelperLinkStyle} href="#"></Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NoSearchResult;
