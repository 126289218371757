import { accountingService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import {
  GetQuickbookInvoicesRequest,
  QBActionResponseType,
  QBInvoice,
  SuppressInvoicesRequest,
  SyncBackQBInvoicesRequest,
  SyncQBInvoicesRequest,
} from '../../../../../models';
import { ViewMetaData } from '../../../../../types';

class QuickbookInvoiceActions {
  static getFilteredRequest(
    filters: ViewMetaData,
    request: GetQuickbookInvoicesRequest
  ) {
    request.invoicedOnFromDate = filters.invoicedOn?.[0] || null;
    request.invoicedOnToDate = filters.invoicedOn?.[1] || null;
    if (filters.synchStatus) {
      request.synchStatus = filters.synchStatus['value'];
    }
    if (filters.loadStatusList) {
      request.loadStatusList = filters.loadStatusList.map(
        (loadStatus: { value: string }) => loadStatus.value
      );
    }
    request.pageNumber = filters.pageNumber;
    request.pageSize = filters.pageSize;
    request.sort = filters.sort;
    return request;
  }

  static async getQuickbookInvoices(
    filters: ViewMetaData,
    qbAccountId: string,
    terminalIds: string[]
  ) {
    const request = new GetQuickbookInvoicesRequest();
    request.terminalIds = terminalIds;
    request.qbAccountId = qbAccountId;
    const queryParams = this.getFilteredRequest(filters, request);
    const response = await accountingService.getQBInvoices(queryParams);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  static async suppressInvoices(invoiceIdList: string[], qbAccountId: string) {
    const request = new SuppressInvoicesRequest();
    request.ids = invoiceIdList;
    request.suppress = true;
    request.qbAccountId = qbAccountId;
    const response = await accountingService.suppressInvoices(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  static async releaseInvoices(invoiceIdList: string[], qbAccountId: string) {
    const request = new SuppressInvoicesRequest();
    request.ids = invoiceIdList;
    request.suppress = false;
    request.qbAccountId = qbAccountId;
    const response = await accountingService.suppressInvoices(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  static async syncBackQBInvoices(
    invoiceIdList: string[],
    qbAccountId: string
  ) {
    const request = new SyncBackQBInvoicesRequest();
    request.ids = invoiceIdList;
    request.qbAccountId = qbAccountId;
    const response = await accountingService.syncBackQBInvoices(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  static async syncQBInvoices(invoiceIdList: string[], qbAccountId: string) {
    const request = new SyncQBInvoicesRequest();
    request.ids = invoiceIdList;
    request.qbAccountId = qbAccountId;
    const response = await accountingService.syncQBInvoices(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  static generateUpdatedTableData(
    tableData: QBInvoice[],
    result: QBActionResponseType
  ) {
    const unMappedSeqNumbers: string[] = [];
    const invoiceMap = result.invoices.reduce((invoiceMap, currentInvoice) => {
      invoiceMap[currentInvoice.id] = currentInvoice;
      return invoiceMap;
    }, {} as { [key: string]: QBInvoice });

    const bodyMessage = result.errors
      .reduce((messages, currentError) => {
        unMappedSeqNumbers.push(currentError.seqNumber);
        messages.push(currentError.message);
        return messages;
      }, [] as string[])
      .join(', ');

    const updatedTableData = tableData.map((invoice) => {
      if (invoiceMap[invoice.id]) {
        invoice.synchStatus = invoiceMap[invoice.id].synchStatus;
        invoice.loadStatus = invoiceMap[invoice.id].loadStatus;
        invoice.amountDue = invoiceMap[invoice.id].amountDue;
      }
      return invoice;
    });
    return {
      updatedTableData,
      unMappedSeqNumbers,
      bodyMessage,
    };
  }

  static getSelectedInvoicesIds(selectedInvoices: QBInvoice[]) {
    return selectedInvoices.map((invoice) => invoice.id);
  }
}

export default QuickbookInvoiceActions;
