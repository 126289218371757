// @TODO: Nvard - move to ui-kit
export const MenuActionsIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M6.49999 8.75C6.91249 8.75 7.24999 9.0875 7.24999 9.5C7.24999 9.9125 6.91249 10.25 6.49999 10.25C6.08749 10.25 5.74999 9.9125 5.74999 9.5C5.74999 9.0875 6.08749 8.75 6.49999 8.75ZM6.49999 7.25C5.25499 7.25 4.24999 8.255 4.24999 9.5C4.24999 10.745 5.25499 11.75 6.49999 11.75C7.74499 11.75 8.74999 10.745 8.74999 9.5C8.74999 8.255 7.74499 7.25 6.49999 7.25ZM12.875 5.75L13.6925 3.9425L15.5 3.125L13.6925 2.3075L12.875 0.5L12.0575 2.3075L10.25 3.125L12.0575 3.9425L12.875 5.75ZM14.96 8.54L14.375 7.25L13.79 8.54L12.5 9.125L13.79 9.71L14.375 11L14.96 9.71L16.25 9.125L14.96 8.54ZM11.1875 9.5C11.1875 9.41 11.1875 9.3125 11.18 9.2225L12.635 8.12L10.76 4.8725L9.07999 5.5775C8.92999 5.48 8.76499 5.3825 8.59999 5.3L8.37499 3.5H4.62499L4.39999 5.3075C4.23499 5.39 4.07749 5.4875 3.91999 5.585L2.23999 4.8725L0.36499 8.12L1.81999 9.2225C1.81249 9.3125 1.81249 9.41 1.81249 9.5C1.81249 9.59 1.81249 9.6875 1.81999 9.7775L0.36499 10.88L2.23999 14.1275L3.91999 13.4225C4.06999 13.52 4.23499 13.6175 4.39999 13.7L4.62499 15.5H8.37499L8.59999 13.6925C8.76499 13.61 8.92249 13.52 9.07999 13.415L10.76 14.12L12.635 10.8725L11.18 9.77C11.1875 9.6875 11.1875 9.59 11.1875 9.5ZM10.1225 12.23L8.82499 11.6825C8.40499 12.1325 7.84999 12.4625 7.22749 12.605L7.04749 14H5.95249L5.77999 12.605C5.15749 12.4625 4.60249 12.1325 4.18249 11.6825L2.88499 12.23L2.33749 11.2775L3.45499 10.43C3.36499 10.1375 3.31999 9.83 3.31999 9.5075C3.31999 9.185 3.36499 8.8775 3.45499 8.585L2.33749 7.7375L2.88499 6.785L4.18249 7.3325C4.60249 6.8825 5.15749 6.5525 5.77999 6.41L5.95249 5H7.05499L7.22749 6.395C7.84999 6.5375 8.40499 6.8675 8.82499 7.3175L10.1225 6.77L10.67 7.7225L9.55249 8.57C9.64249 8.8625 9.68749 9.17 9.68749 9.4925C9.68749 9.815 9.64249 10.1225 9.55249 10.415L10.67 11.2625L10.1225 12.23Z"
        fill="#2B64CB"
      />
    </svg>
  );
};
