import {
  Box,
  Grid,
  Skeleton,
  Stack,
  ThemeProvider,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../Permission';
import FinanceOverviewAccordionSection from './FinanceOverviewAccordionSection';
import { EFinanceOverview } from './FinanceOverviewConstant';
import FormContext, {
  FinanceOverviewFormProvider,
  SelectEntityType,
  getFinanceOverviewAccordionDataWithID,
  getFinanceOverviewAccordionDataWithOutID,
  getFinanceOverviewData,
  getFinanceOverviewStatementInfo,
  getPayTypeIdListName,
} from './FinanceOverviewFormContext';
import FinanceOverviewInvoice from './FinanceOverviewInvoice/FinanceOverviewInvoice';
import {
  DeleteFinanceExpense,
  EPaymentDetailType,
  FinanceOverviewType,
  ListDataType,
  RevenueShareThemeStyle,
  TripDetailsType,
  paymentDetailsType,
  requestStatuesStatementInfo,
} from './FinanceOverviewModel';
import { financeStyle } from './FinanceOverviewStyle';
import { FinanceOverviewTrip } from './FinanceOverviewTrip';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { t } from 'i18next';
import { financeLoadService, loadService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { ByLoadIdRequest } from '../../../../models';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { LoadSummary } from '../../../../subPages/loadsList/models/LoadSummary';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import MultilineCellAxele from '../../../../ui-kit/components/MultilineCell';
import { amountFormat } from '../../../../utils';
import { loadPanelFinanceTab } from '../../../PendoClassIDs/constants';
import NoData from '../../component/NoData';
import AddTripPaymentPopOver from './AddTripPayment';
import FinanceRevenueShare from './FinanceRevenueShare';

const RenderFinanceHeader = (ModuleType: string) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <MultilineCellAxele
      title={
        ModuleType === 'FinanceOverView'
          ? t('loadFinancials')
          : t('tripFinancials')
      }
      titleStyle={financeStyle.totalText}
      alignTitle="left"
      alignSubtitle="left"
      subtitle={''}
    />
  </Stack>
);

const RenderFinanceFooter = () => {
  const theme = useTheme();
  const financeFooterStyle = {
    background: `${theme.palette.primary.outlinedHoverBackground}`,
    padding: '12px',
    margin: '12px 0px',
    borderRadius: '8px',
    display: 'block',
    width: '100%',
  };
  const financeFooterText = {
    width: '50%',
    color: `${theme.palette.text.primary}`,
    fontSize: '16px',
    fontWeight: '400',
  };
  const financeFooterTextOut = {
    width: '50%',
    color: `${theme.palette.error.main}`,
    fontSize: '16px',
    fontWeight: '400',
  };
  const { financeOverviewData, isEmptyTrip } = React.useContext(FormContext);
  return (
    <Grid className="RenderFinanceFooter" sx={financeFooterStyle}>
      {!isEmptyTrip && (
        <Grid sx={financeStyle.financeFooterCount}>
          <Typography variant="h6" sx={financeFooterText}>
            {t('totalMoneyIn')}:
          </Typography>
          <Typography
            align="right"
            sx={{
              color: theme.palette.text.primary,
              fontSize: '16px',
              fontWeight: '400',
              width: '50%',
            }}
          >
            {amountFormat(financeOverviewData?.totalIn || 0)}
          </Typography>
        </Grid>
      )}
      <Grid sx={financeStyle.financeFooterCount}>
        <Typography variant="h6" sx={financeFooterTextOut}>
          {t('totalMoneyOut')}:
        </Typography>
        <Typography
          align="right"
          sx={{
            color: theme.palette.error.main,
            fontSize: '16px',
            fontWeight: '400',
            width: '50%',
          }}
        >
          {Math.sign(
            financeOverviewData?.totalOut ? financeOverviewData.totalOut : 0
          ) === 1 && '-'}{' '}
          {amountFormat(financeOverviewData?.totalOut || 0)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const RenderFinanceOverviewHeader = (loadType: string) => {
  const theme = useTheme();

  const { financeOverviewData, ModuleType, allPaymentTypes, setRenderSection } =
    React.useContext(FormContext);
  const tripComponentStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    p: 1,
    borderRadius: '5px',
    '&:hover': {
      background:
        ModuleType === 'FinanceOverView' ? `${theme.palette.grey['100']}` : '',
    },
  };
  const invoiceDetails = financeOverviewData?.invoiceDetails;

  const navigateInvoice = () => {
    if (ModuleType === 'FinanceOverView') {
      setRenderSection(EFinanceOverview.FinanceOverviewInvoice);
    }
  };
  return (
    <>
      {invoiceDetails?.map((item: paymentDetailsType, index: number) => {
        return (
          <Grid
            className="invoiceDetails"
            sx={tripComponentStyle}
            key={index}
            onClick={() => {
              if (loadType != 'MANIFEST') {
                navigateInvoice();
              }
            }}
            id={loadPanelFinanceTab + 'NetInvoiceAndAmount'}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <>
                {ModuleType === 'FinanceOverView' ? (
                  <Tooltip
                    title={t('factoringchargededucted')}
                    placement="top-start"
                  >
                    <Typography variant="h6" sx={{ display: 'inline-flex' }}>
                      {item?.name ? item.name : ' '}
                      {loadType === 'MANIFEST' && (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            pt: '10px',
                            position: 'relative',
                            pl: '12px',
                          }}
                        >
                          {' '}
                          ({t('loadId')}: {item?.seqNumber || ''})
                        </Typography>
                      )}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={t('factoringchargededucted')}
                    placement="top-start"
                  >
                    <Typography variant="h6">{t('revenue')}</Typography>
                  </Tooltip>
                )}
              </>
              <>
                {ModuleType === 'FinanceOverView' ? (
                  <Typography variant="subtitle1" sx={financeStyle.whiteSpace}>
                    {getPayTypeIdListName(
                      item?.payTypeIdList ? item.payTypeIdList : [],
                      allPaymentTypes
                    )}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
                    {t('loadId')} : {item?.paymentId}
                  </Typography>
                )}
              </>
            </Box>
            <Box sx={{ ...financeStyle.totalCount, flexDirection: 'column' }}>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '24px',
                  fontWeight: '400',
                }}
              >
                {Math.sign(item?.amount || 0) === -1 && '-'}
                {amountFormat(item?.amount || 0)}
              </Typography>
              {ModuleType != 'FinanceOverView' && (
                <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
                  {item?.revenueShare ? item.revenueShare + ' %' : ''}
                </Typography>
              )}
            </Box>
          </Grid>
        );
      })}
    </>
  );
};

const FinanceOverviewSection = (loadType: string, loadId: string) => {
  const { ModuleType, financeOverviewData, isEmptyTrip } =
    React.useContext(FormContext);
  const invoiceDetails = financeOverviewData?.invoiceDetails || [];
  return (
    <>
      {Boolean(ModuleType) ? (
        RenderFinanceHeader(ModuleType)
      ) : (
        <Skeleton width={'100%'} height={'3rem'} />
      )}
      {invoiceDetails[0]?.id && !isEmptyTrip && (
        <Permission
          contains={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICES_VIEW]}
        >
          {RenderFinanceOverviewHeader(loadType)}
        </Permission>
      )}

      {FinanceOverviewTripSection(loadType, loadId)}
      {RenderFinanceFooter()}
    </>
  );
};

const FinanceOverviewTripSection = (loadType: string, loadId: string) => {
  const {
    financeOverviewData,
    ModuleType,
    setSelectRecordData,
    setSelectRecordList,
    setRenderSection,
    setSelectEntityType,
    setStatementInfo,
    isBrokered,
    panelType,
    setOpenPayments,
    setIsCreatingNewPayment,
    setSavebtnVisible,
    resetFinanceOverviewData,
    selectRecordList,
    prepareDataForForm,
  } = React.useContext(FormContext);
  const { trackPromise } = useDetailsPanelStore;
  const theme = useTheme();
  const { setValue } = useFormContext();
  const [showRevenueShareView, setShowRevenueShareView] = useState(true);
  const [paymentDeleteDetails, setPaymentDeleteDetails] =
    useState<paymentDetailsType | null>();
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [currTripPaymentIdToLoad, setCurrTripPaymentIdToLoad] = useState<
    string | null
  >(null);
  const [showAddPaymentLoader, setShowAddPaymentLoader] =
    useState<boolean>(false);

  const revenueShareStyle = {
    fontSize: '12px',
    background: '#669eff1f',
    color: theme.palette.primary.main,
    ml: '15px',
    padding: '5px 10px',
    borderRadius: '6px',
    paddingBottom: '0px',
    marginTop: '-8px',
    cursor: 'pointer',
    display: 'flex',
  };

  const tripDetail = financeOverviewData?.tripDetails;

  const navigateTripSection = async (
    item: TripDetailsType,
    Details: paymentDetailsType
  ) => {
    try {
      setCurrTripPaymentIdToLoad(Details.id!);
      const payLoadData = {
        loadId: item.loadId,
        paymentId: Details.paymentId,
        type: Details.type,
        id: Details.id,
      };
      setIsCreatingNewPayment(false);
      let data: ListDataType | undefined;
      if (Details.paymentId) {
        data = await trackPromise(
          getFinanceOverviewAccordionDataWithID(payLoadData),
          panelType
        );
      } else {
        data = await trackPromise(
          getFinanceOverviewAccordionDataWithOutID(payLoadData),
          panelType
        );
      }
      const statementQuery = {
        loadId: item.loadId,
        id: Details?.id,
        type: Details.type,
      };
      const datastatement: requestStatuesStatementInfo | undefined =
        await trackPromise(
          getFinanceOverviewStatementInfo(statementQuery),
          panelType
        );
      setStatementInfo(datastatement);
      setSelectRecordData(data);
      setSelectRecordList(data?.items ? data.items : []);

      const setEntityData: SelectEntityType = {
        type: Details.type,
        name: Details.name,
        status: Details.paymentStatus,
        loadId: item.loadId,
        paymentId: Details.paymentId,
        id: Details.id,
      };
      setSelectEntityType(setEntityData);
      setRenderSection(EFinanceOverview.FinanceOverviewDetail);
      setValue('selectRecordList', data?.items ? data.items : []);
      setCurrTripPaymentIdToLoad(null);
    } catch (error) {
      console.log(error);
      setCurrTripPaymentIdToLoad(null);
    }
  };

  const filterPaymentDetails = (list: paymentDetailsType[]) => {
    if (isBrokered) {
      return list?.filter(({ type }) =>
        [EPaymentDetailType.Dispatcher, EPaymentDetailType.Carrier].includes(
          type
        )
      );
    }
    return list;
  };

  const currentTheme = useTheme();

  const addPaymentOptions = (trip: TripDetailsType) => {
    return trip?.paymentDetails?.filter?.((e) => !e?.paymentId);
  };

  const handleOnCreatePayment = async (
    data: paymentDetailsType,
    item: TripDetailsType
  ) => {
    try {
      setShowAddPaymentLoader(true);
      let methodType = loadService.getSingleDriverInfoForLoadAssign;
      if (data?.type === 'Tractor')
        methodType = loadService.getTractorInfoForLoadAssign;
      else if (data?.type === 'Dispatcher')
        methodType = loadService.getDispatcherInfoForLoadAssign;
      const res = await methodType(data?.id!, item?.loadId ?? loadId);
      if (res instanceof ServiceError) {
        return setShowAddPaymentLoader(false);
      }
      setOpenPayments?.({
        type: loadType,
        driverAmountForAssign: res?.data,
        onPaymentTmpUpdate: (data: any) => {
          setSavebtnVisible(true);
        },
      });
      const entityData: SelectEntityType = {
        type: data?.type,
        name: data?.name,
        status: data?.paymentStatus,
        loadId: res?.data?.loadId,
        paymentId: data?.paymentId,
        id: data?.id,
      };
      setSelectEntityType(entityData);
      setValue('selectRecordData', res?.data);
      setSelectRecordData(res?.data);
      setSelectRecordList(res?.data?.items ?? []);
      setIsCreatingNewPayment(true);
      setRenderSection(EFinanceOverview.FinanceOverviewDetail);
      setShowAddPaymentLoader(false);
    } catch (error) {
      console.log(error);
      setShowAddPaymentLoader(false);
    }
  };

  const handlePaymentDeleteMenuClick = (Details: paymentDetailsType) => {
    setIsCreatingNewPayment(false);
    setPaymentDeleteDetails(Details);
  };

  const handlePaymentExpenseDelete = async (Details: paymentDetailsType) => {
    try {
      if (!Details?.paymentId) return;
      if (Details?.type === 'Carrier') {
        setDeleteLoader(true);
        const res = await financeLoadService.deleteCarrierPaymentById(
          Details.paymentId
        );
        if (res instanceof ServiceError) return;
        await prepareDataForForm?.();
        setPaymentDeleteDetails(null);
        setDeleteLoader(false);
      } else {
        setDeleteLoader(true);
        const res = await financeLoadService.deleteLoadFinanceExpense(
          Details?.type,
          new DeleteFinanceExpense(Details)
        );
        if (res instanceof ServiceError) return;
        await prepareDataForForm?.();
        setPaymentDeleteDetails(null);
        setDeleteLoader(false);
      }
    } catch (error) {
      setDeleteLoader(false);
      console.log(error);
    }
  };

  return (
    <>
      <Grid
        className="FinanceOverviewTripSection"
        sx={{ mt: 1, display: 'grid' }}
      >
        {tripDetail?.map((item: TripDetailsType) => {
          return (
            <>
              <Box
                component="div"
                display="inline-flex"
                sx={{ pt: 2 }}
                className="FinanceOverviewTripSection-tripDetail"
              >
                <Typography sx={{ textDecoration: 'underline' }}>
                  {item.tripName ? item.tripName : ''}
                </Typography>
                {ModuleType === 'FinanceOverView' && (
                  <ThemeProvider theme={RevenueShareThemeStyle(currentTheme)}>
                    <Typography sx={{ position: 'relative' }}>
                      <Typography
                        sx={revenueShareStyle}
                        onClick={() => {
                          setShowRevenueShareView(true);
                        }}
                      >
                        {t('totalRevenueShare')} ({item.revenueShare}%) ={' '}
                        <Typography sx={{ padding: '0px 3px' }}> </Typography>
                        {amountFormat(item.sharedAmount || 0)}
                        <KeyboardArrowDownIcon sx={{ paddingBottom: '5px' }} />
                      </Typography>
                      {item?.tripLoadFinanceDetails?.length > 0 && (
                        <Box className="revenueShareDiv">
                          <FinanceRevenueShare
                            shareData={item.tripLoadFinanceDetails}
                            loadType={loadType}
                            loadId={loadId}
                          />
                        </Box>
                      )}
                    </Typography>
                  </ThemeProvider>
                )}
              </Box>
              <Permission
                contains={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_ADD]}
              >
                <AddTripPaymentPopOver
                  options={addPaymentOptions(item)}
                  onSelect={(data) => handleOnCreatePayment(data!, item)}
                  showLoader={showAddPaymentLoader}
                />
              </Permission>
              {filterPaymentDetails(item.paymentDetails).length > 0 &&
                filterPaymentDetails(item.paymentDetails).map(
                  (Details: paymentDetailsType) => {
                    if (!Details?.paymentId) return <></>;
                    return (
                      <Box
                        className={'FinanceOverviewTrip-wrap'}
                        sx={{ cursor: 'pointer', mt: 1 }}
                        key={''}
                        // onClick={() => navigateTripSection(item, Details)}
                      >
                        <FinanceOverviewTrip
                          showLoader={Details?.id === currTripPaymentIdToLoad}
                          data={Details}
                          onPaymentDelete={() =>
                            handlePaymentDeleteMenuClick(Details)
                          }
                          onPaymentUpdate={() =>
                            navigateTripSection(item, Details)
                          }
                        />
                      </Box>
                    );
                  }
                )}
            </>
          );
        })}
      </Grid>
      {Boolean(paymentDeleteDetails) && (
        <DeletePopup
          open={true}
          title={'Delete Payment'}
          body={`Are you sure you want to delete the ${paymentDeleteDetails?.type} payment?`}
          onAction={() => handlePaymentExpenseDelete(paymentDeleteDetails!)}
          onClose={() => setPaymentDeleteDetails(null)}
          buttonText={'Delete'}
          width={'450px'}
          buttonLoader={deleteLoader}
        />
      )}
    </>
  );
};

const FinanceOverviewInvoiceSection = (
  setLoadData: (data: LoadSummary) => void
) => {
  const {
    financeOverviewData,
    setRenderSection,
    setFinanceOverviewData,
    panelType,
  } = React.useContext(FormContext);

  const invoiceViewRenderClose = async () => {
    setRenderSection(EFinanceOverview.FinanceOverview);
    const request = new ByLoadIdRequest();
    request.loadId = financeOverviewData?.invoiceDetails[0]?.id;
    const financeOverview = await getFinanceOverviewData(request);

    setFinanceOverviewData(financeOverview);
  };
  return (
    <>
      <FinanceOverviewInvoice
        id={financeOverviewData?.invoiceDetails[0]?.id}
        UpdatedCloseVisible={invoiceViewRenderClose}
        isFinanceOverview={true}
        setLoadData={setLoadData}
        panelType={panelType}
      />
    </>
  );
};

function FinanceOverview({
  setLoadData,
  loadId,
  loadType,
}: FinanceOverviewType) {
  const { financeOverviewData, renderSection, selectRecordList } =
    React.useContext(FormContext);

  if (!financeOverviewData) {
    return <NoData />;
  }
  return (
    <Grid className="FinanceOverview" sx={financeStyle.financeContainer}>
      {renderSection === EFinanceOverview.FinanceOverview &&
        financeOverviewData &&
        FinanceOverviewSection(loadType, loadId)}
      {renderSection === EFinanceOverview.FinanceOverviewDetail &&
        financeOverviewData && (
          <FinanceOverviewAccordionSection data={selectRecordList} />
        )}
      {renderSection === EFinanceOverview.FinanceOverviewInvoice &&
        financeOverviewData && (
          <Permission
            includes={[AXELE_PERMISSION_TYPE?.LOAD_FINANCIAL_INVOICES_VIEW]}
          >
            <>{FinanceOverviewInvoiceSection(setLoadData)}</>
          </Permission>
        )}
    </Grid>
  );
}

const FinanceOverviewFormWithProvider = ({
  loadId,
  openPayments,
  onClose,
  ModuleType,
  setLoadData,
  isBrokered = false,
  panelType,
  loadType,
  isEmptyTrip,
}: FinanceOverviewType) => {
  return (
    <FinanceOverviewFormProvider
      loadId={loadId}
      ModuleType={ModuleType}
      panelType={panelType}
      openPayments={openPayments}
      onClose={onClose}
      isBrokered={isBrokered}
      loadType={loadType}
      isEmptyTrip={isEmptyTrip}
    >
      <Permission
        includes={[AXELE_PERMISSION_TYPE?.LOAD_FINANCIAL_PAYMENT_VIEW]}
      >
        <FinanceOverview
          ModuleType={ModuleType}
          panelType={panelType}
          loadId={loadId}
          openPayments={openPayments}
          onClose={onClose}
          setLoadData={setLoadData}
          loadType={loadType}
        />
      </Permission>
    </FinanceOverviewFormProvider>
  );
};
export default observer(FinanceOverviewFormWithProvider);
