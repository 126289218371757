import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

export const StyledPreferenceAction = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const StyledPreferenceActionLabel = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.buttonLarge,
  fontSize: '16px !important',
  fontWeight: '400 !important',
}));

export const StyledPreferenceActionOptionLabel = styled(
  (props: TypographyProps) => <Typography {...props} />
)(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.body1,
}));

export const preferenceLabelStyle = {
  fontSize: '16px !important',
  fontWeight: '400 !important',
  fontFamily: 'Poppins !important',
};

export const buttonGroupLable = {
  marginBottom: '5px',
};

export const buttonGroupStyle = {
  textTransform: 'capitalize',
};
