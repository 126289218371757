import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { FC } from 'react';
import { LoadboardPickerFilter } from '../../../common/Ui/LoadboardPicker/LoadboardPickerFilter';
import { ELocationLevel } from '../../../common/Ui/LocationSelect';
import LocationSelectFilter from '../../../common/Ui/LocationSelect/LocationSelectFilter';
import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { GridColDefSelf } from '../../../ui-kit/components/DataGridPro';
import { getThemeObjectByMode } from '../../../ui-kit/theme';
import {
  getCustomerPreferenceOptions,
  getEquipments,
  getLoadTypeOptions,
  getReloadScores,
  getYesNoOptions,
  loadboardFieldsConfig,
} from '../constants/grid.constants';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { ILoadboardFilters, ILoadsearchTableItem } from '../loadTypes';
import Age from './Grid/Age';
import Broker from './Grid/Broker';
import Dropoff from './Grid/Dropoff';
import Equipment from './Grid/Equipment';
import LoadedMiles from './Grid/LoadedMiles';
import MarketRate from './Grid/MarketRate';
import Mileage from './Grid/Mileage';
import Offer from './Grid/Offer';
import Pickup from './Grid/Pickup';
import ReloadScore from './Grid/ReloadScore';
import HyperLink from '@/common/Ui/HyperLink/HyperLink';
export const defaultLoadboardGridFilters: Partial<ILoadboardFilters> = {
  sources: null, //set to null at first time
  origins: {
    locationLevel: ELocationLevel.City,
  },
  destinations: {
    locationLevel: ELocationLevel.City,
  },
  pickupDateRanges:
    RelativeDateRangeUtils.getSettingsLoadboards().defaultOption || null,

  ageHours: 2,
  equipmentList: [],
  customerPreferenceType: null,
  loadType: null,
  reloadScoreFilter: getReloadScores().find((item) => item.id === 1) || null,
  maxOriginDeadhead: 100,
  maxDestinationDeadhead: 100,
  maxEquipmentLength: null,
  maxEquipmentWeight: null,
  showActualOffersOnly: {
    id: false,
    name: 'No',
  },
};

interface GridProps {
  [key: string]: any;
}

export interface SortField {
  field: string;
  label: string;
  sortingKey: string;
  type?: 'number' | 'string' | 'date';
  isDefault?: boolean;
}

export interface LoadboardGridColDefSelf extends GridColDefSelf {
  sortFields?: SortField[];
}

interface LoadboardConfig {
  columns: LoadboardGridColDefSelf[];
  title: string;
  getFiltersFormConfig: () => {
    key: number;
    default: boolean;
    label: string;
    name: keyof ILoadboardFilters;
    type:
      | 'MultipleAutocomplete'
      | 'SingleAutocomplete'
      | 'DateRangePicker'
      | 'RelativeDateRange'
      | 'DatePicker'
      | 'Custom'
      | 'TextField'
      | 'ButtonGroup'
      | 'GridSwitchFilter';
    customComponent?: FC<any>;
    [key: string]: any;
  }[];
}

const defaultAutocompleteConfig = {
  fieldName: 'name',
  fieldValue: 'id',
  immutableModel: true,
};

export default class LoadboardGridConfig {
  name: string;

  config: LoadboardConfig;

  constructor(props: GridProps) {
    this.name = 'Loadboard GridConfiguration';
    this.config = LoadboardGridConfig.getConfiguration(props);
  }

  static getConfiguration(props: GridProps) {
    return LoadboardGridConfig.getDefaultConfiguration(props);
  }

  static getDefaultConfiguration(props?: GridProps): LoadboardConfig {
    const theme = createTheme(
      getThemeObjectByMode(props?.themeMode || 'light'),
      {
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                background: 'transparent !important',
              },
            },
          },
        },
      }
    );
    const loadboardAccounts = props?.loadboardAccounts;
    const showActualOffersOnly = props?.showActualOffersOnly;
    const isZoneDisabled = props?.isZoneDisabled;

    return {
      title: 'Loadboard grid',
      columns: [
        {
          flex: 1,
          field: loadboardFieldsConfig.Age.fieldName,
          headerName: loadboardFieldsConfig.Age.label,
          minWidth: 150,
          description: t('ageDesc'),
          permanent: true,
          sortFields: [
            {
              field: 'Age',
              label: t('age'),
              sortingKey: 'age',
              type: 'number',
              isDefault: true,
            },
          ],
          renderCell: (
            params: GridRenderCellParams<any, ILoadsearchTableItem>
          ) => {
            return (
              <ThemeProvider theme={theme}>
                <Age {...params.row.Age} />
              </ThemeProvider>
            );
          },
        },
        {
          isHyperLink: true,
          flex: 1,
          field: loadboardFieldsConfig.loadId.fieldName,
          headerName: loadboardFieldsConfig.loadId.label,
          hidden: true,
          description: t('loadId'),
          minWidth: 150,
          renderCell: (
            params: GridRenderCellParams<any, ILoadsearchTableItem>
          ) => {
            return (
              <HyperLink id={params.value} value={params.row?.loadSeqNum} />
            );
          },
        },
        {
          flex: 1,
          field: loadboardFieldsConfig.Pickup.fieldName,
          headerName: loadboardFieldsConfig.Pickup.label,
          description: t('origin'),
          minWidth: 175,
          permanent: true,
          sortFields: [
            {
              field: 'Pickup',
              label: t('city'),
              sortingKey: 'city',
              type: 'string',
              isDefault: true,
            },
            {
              field: 'Pickup',
              label: t('dateTime'),
              sortingKey: 'date',
              type: 'string',
              isDefault: false,
            },
            {
              field: 'Pickup',
              label: t('deadheadMiles'),
              sortingKey: 'deadheadMiles',
              type: 'number',
              isDefault: false,
            },
          ],
          renderCell: (
            params: GridRenderCellParams<any, ILoadsearchTableItem>
          ) => {
            return <Pickup {...params.row.Pickup} />;
          },
        },
        {
          flex: 1,
          field: loadboardFieldsConfig.LoadedMiles.fieldName,
          headerName: loadboardFieldsConfig.LoadedMiles.label,
          description:
            t('totalMiles') +
            '-' +
            '(' +
            t('deadheadOriginMiles') +
            '+' +
            t('deadheadDestinationMiles') +
            ')',
          minWidth: 175,
          permanent: true,
          sortFields: [
            {
              field: 'LoadedMiles',
              label: t('loadedMiles'),
              sortingKey: 'value',
              type: 'number',
              isDefault: true,
            },
            {
              field: 'LoadedMiles',
              label: t('stops'),
              sortingKey: 'stops',
              type: 'number',
              isDefault: false,
            },
            {
              field: 'LoadedMiles',
              label: t('totalMiles'),
              sortingKey: 'actualTotalMiles',
              type: 'number',
              isDefault: false,
            },
          ],
          renderCell: (
            params: GridRenderCellParams<any, ILoadsearchTableItem>
          ) => {
            return <LoadedMiles {...params.row.LoadedMiles} />;
          },
        },
        {
          flex: 1,
          field: loadboardFieldsConfig.Dropoff.fieldName,
          headerName: loadboardFieldsConfig.Dropoff.label,
          minWidth: 175,
          description: t('destination'),
          permanent: true,
          sortFields: [
            {
              field: 'Dropoff',
              label: t('city'),
              sortingKey: 'city',
              type: 'string',
              isDefault: true,
            },
            {
              field: 'Dropoff',
              label: t('dateTime'),
              sortingKey: 'date',
              type: 'string',
              isDefault: false,
            },
            {
              field: 'Dropoff',
              label: t('deadheadMiles'),
              sortingKey: 'deadheadMiles',
              type: 'number',
              isDefault: false,
            },
          ],
          renderCell: (
            params: GridRenderCellParams<any, ILoadsearchTableItem>
          ) => {
            return <Dropoff {...params.row.Dropoff} />;
          },
        },
        {
          flex: 1,
          field: loadboardFieldsConfig.Offer.fieldName,
          headerName: loadboardFieldsConfig.Offer.label,
          minWidth: 230,
          description: t('offerDesc'),
          sortFields: [
            {
              field: 'Offer',
              label: t('offerPrice'),
              sortingKey: 'value',
              type: 'number',
              isDefault: true,
            },
            {
              field: 'Offer',
              label: t('profit'),
              sortingKey: 'estimatedProfit',
              type: 'number',
              isDefault: false,
            },
            {
              field: 'Offer',
              label: '$/' + t('day'),
              sortingKey: 'perdayProfitInNumber',
              type: 'number',
              isDefault: false,
            },
          ],
          renderCell: (
            params: GridRenderCellParams<
              ILoadsearchTableItem['Offer'],
              ILoadsearchTableItem
            >
          ) => {
            return (
              <ThemeProvider theme={theme}>
                <Offer
                  {...params.row.Offer}
                  showActualOffersOnly={showActualOffersOnly}
                />
              </ThemeProvider>
            );
          },
        },
        {
          flex: 1,
          field: loadboardFieldsConfig.Mileage.fieldName,
          headerName: loadboardFieldsConfig.Mileage.label,
          minWidth: 150,
          description: t('mileageDesc'),
          sortFields: [
            {
              field: 'Mileage',
              label: '$/' + t('mile'),
              sortingKey: 'value',
              type: 'number',
              isDefault: true,
            },
          ],
          renderCell: (params: any) => {
            return <Mileage value={params.row.Mileage.value} />;
          },
        },
        {
          flex: 1,
          isHyperLink: true,
          field: loadboardFieldsConfig.MarketRate.fieldName,
          headerName: loadboardFieldsConfig.MarketRate.label,
          minWidth: 170,
          description: t('marketRateDesc'),
          sortFields: [
            {
              field: 'MarketRate',
              label: t('marketRate'),
              sortingKey: 'value',
              type: 'number',
              isDefault: true,
            },
          ],
          renderCell: (
            params: GridRenderCellParams<
              ILoadsearchTableItem['MarketRate'],
              ILoadsearchTableItem
            >
          ) => (
            <MarketRate
              marketRate={params.value}
              loadsearchTableItem={params.row}
            />
          ),
        },
        {
          flex: 1,
          field: loadboardFieldsConfig.Equipment.fieldName,
          headerName: loadboardFieldsConfig.Equipment.label,
          minWidth: 150,
          description: t('equipmentDesc'),
          sortFields: [
            {
              field: 'Equipment',
              label: t('equipment'),
              sortingKey: 'value',
              type: 'string',
              isDefault: true,
            },
            {
              field: 'Equipment',
              label: t('equipmentLength'),
              sortingKey: 'length',
              type: 'number',
              isDefault: false,
            },
            {
              field: 'Equipment',
              label: t('equipmentWeight'),
              sortingKey: 'subvalue',
              type: 'number',
              isDefault: false,
            },
          ],
          renderCell: (
            params: GridRenderCellParams<any, ILoadsearchTableItem>
          ) => {
            return <Equipment {...params.row.Equipment} />;
          },
        },
        {
          flex: 1,
          field: loadboardFieldsConfig.Broker.fieldName,
          headerName: loadboardFieldsConfig.Broker.label,
          minWidth: 150,
          description: t('freightBroker'),
          sortable: false,
          disableColumnMenu: true,
          renderCell: (
            params: GridRenderCellParams<any, ILoadsearchTableItem>
          ) => {
            return (
              <ThemeProvider theme={theme}>
                <Broker
                  {...params.row.Broker}
                  customerPreference={
                    params.row.actions.savedLoadsData.customerPreference
                  }
                />
              </ThemeProvider>
            );
          },
        },
        {
          flex: 1,
          field: loadboardFieldsConfig.ReloadScore.fieldName,
          headerName: loadboardFieldsConfig.ReloadScore.label,
          description: t('reloadScoreDesc'),
          minWidth: 150,
          sortFields: [
            {
              field: 'ReloadScore',
              label: t('reloadScore'),
              sortingKey: 'value',
              type: 'number',
              isDefault: true,
            },
          ],
          renderCell: (
            params: GridRenderCellParams<any, ILoadsearchTableItem>
          ) => {
            return <ReloadScore {...params.row.ReloadScore} />;
          },
        },
      ],
      getFiltersFormConfig: () => [
        {
          key: 1,
          default: true,
          label: loadboardFieldsConfig.sources.label,
          name: loadboardFieldsConfig.sources.fieldFilterName,
          type: 'Custom',
          customComponent: LoadboardPickerFilter,
          options: loadboardAccounts || [],
          defaultValue: defaultLoadboardGridFilters.sources,
          width: 180,
        },
        {
          key: 2,
          default: true,
          label: loadboardFieldsConfig.origins.label,
          name: loadboardFieldsConfig.origins.fieldFilterName,
          type: 'Custom',
          customComponent: LocationSelectFilter,
          defaultValue: defaultLoadboardGridFilters.origins,
          isZoneDisabled,
        },
        {
          key: 3,
          default: true,
          label: loadboardFieldsConfig.destinations.label,
          name: loadboardFieldsConfig.destinations.fieldFilterName,
          type: 'Custom',
          customComponent: LocationSelectFilter,
          defaultValue: defaultLoadboardGridFilters.destinations,
          isZoneDisabled,
        },
        {
          key: 4,
          default: false,
          label: loadboardFieldsConfig.maxOriginDeadhead.label,
          name: loadboardFieldsConfig.maxOriginDeadhead.fieldFilterName,
          type: 'TextField',
          defaultValue: defaultLoadboardGridFilters.maxOriginDeadhead,
        },
        {
          key: 5,
          default: false,
          label: loadboardFieldsConfig.maxDestinationDeadhead.label,
          name: loadboardFieldsConfig.maxDestinationDeadhead.fieldFilterName,
          type: 'TextField',
          defaultValue: defaultLoadboardGridFilters.maxDestinationDeadhead,
          customStylesAllFilter: {
            minWidth: '305px',
          },
        },
        {
          key: 6,
          label: loadboardFieldsConfig.pickupDateRanges.label,
          name: loadboardFieldsConfig.pickupDateRanges.fieldFilterName,
          default: true,
          ...RelativeDateRangeUtils.getSettingsLoadboards(),
        },
        {
          key: 7,
          default: false,
          label: loadboardFieldsConfig.ageOfLoad.label,
          name: loadboardFieldsConfig.ageOfLoad.fieldFilterName,
          type: 'TextField',
          defaultValue: defaultLoadboardGridFilters.ageOfLoad,
        },
        {
          key: 8,
          options: getReloadScores(),
          label: loadboardFieldsConfig.reloadScoreFilter.label,
          name: loadboardFieldsConfig.reloadScoreFilter.fieldFilterName,
          default: false,
          type: 'ButtonGroup',
          multiple: false,
          ...defaultAutocompleteConfig,
          defaultValue: defaultLoadboardGridFilters.reloadScoreFilter,
        },
        {
          key: 9,
          default: true,
          label: loadboardFieldsConfig.equipmentList.label,
          name: loadboardFieldsConfig.equipmentList.fieldFilterName,
          getData: getEquipments,
          type: 'MultipleAutocomplete',
          ...defaultAutocompleteConfig,
          customStyles: {
            width: '180px',
          },
        },
        {
          key: 10,
          default: false,
          label: loadboardFieldsConfig.maxEquipmentLength.label,
          name: loadboardFieldsConfig.maxEquipmentLength.fieldFilterName,
          type: 'TextField',
          defaultValue: defaultLoadboardGridFilters.maxEquipmentLength,
        },
        {
          key: 11,
          default: false,
          label: loadboardFieldsConfig.maxEquipmentWeight.label,
          name: loadboardFieldsConfig.maxEquipmentWeight.fieldFilterName,
          type: 'TextField',
          defaultValue: defaultLoadboardGridFilters.maxEquipmentWeight,
          customStylesAllFilter: {
            minWidth: '305px',
          },
        },
        {
          key: 12,
          default: false,
          labelId: loadboardFieldsConfig.loadType.fieldFilterName,
          label: loadboardFieldsConfig.loadType.label,
          name: loadboardFieldsConfig.loadType.fieldFilterName,
          options: getLoadTypeOptions(),
          defaultValue: getLoadTypeOptions()[0],
          type: 'ButtonGroup',
          multiple: false,
          ...defaultAutocompleteConfig,
        },
        {
          key: 13,
          default: false,
          label: loadboardFieldsConfig.customerPreferenceType.label,
          name: loadboardFieldsConfig.customerPreferenceType.fieldFilterName,
          options: getCustomerPreferenceOptions(),
          type: 'ButtonGroup',
          multiple: false,
          ...defaultAutocompleteConfig,
        },
        {
          key: 14,
          default: false,
          labelId: loadboardFieldsConfig.showActualOffersOnly.fieldFilterName,
          label: loadboardFieldsConfig.showActualOffersOnly.label,
          name: loadboardFieldsConfig.showActualOffersOnly.fieldFilterName,
          options: getYesNoOptions(),
          defaultValue: null,
          type: 'GridSwitchFilter',
          ...defaultAutocompleteConfig,
        },
      ],
    };
  }
}
