import {
  AXELE_PERMISSION_TYPE,
  AXELE_ROLE_TYPE,
  AXELE_WEB_FEATURE_TYPE,
} from '../types/AxelePermission';
import StorageManager from '../../../StorageManager/StorageManager';

export const validateProperties =
  (
    permissions: AXELE_PERMISSION_TYPE[],
    webFeaturesList: AXELE_WEB_FEATURE_TYPE[]
  ) =>
  (
    privileges?: AXELE_PERMISSION_TYPE[] | AXELE_WEB_FEATURE_TYPE[] | undefined,
    subPermits?: AXELE_WEB_FEATURE_TYPE[] | undefined
  ): boolean => {
    if (
      (!webFeaturesList || !subPermits) &&
      (!permissions || !privileges || !privileges.length)
    )
      return false;
    if (privileges && !Array.isArray(privileges)) {
      return false;
    }
    return !(subPermits && !Array.isArray(subPermits));
  };

export const hasIncludes =
  (
    permissions: AXELE_PERMISSION_TYPE[],
    webFeaturesList: AXELE_WEB_FEATURE_TYPE[]
  ) =>
  (
    privileges?: AXELE_PERMISSION_TYPE[] | undefined,
    permits?: AXELE_WEB_FEATURE_TYPE[] | undefined
  ): boolean => {
    if (!validateProperties(permissions, webFeaturesList)(privileges, permits))
      return false;
    const allowedPrivileges = privileges?.every((r) =>
      permissions?.includes(r)
    );
    const allowedPermits = permits?.every((r) => webFeaturesList?.includes(r));
    if (privileges?.length && permits?.length)
      return !!(allowedPrivileges && allowedPermits);
    if (privileges?.length) return !!allowedPrivileges;
    if (permits?.length) return !!allowedPermits;

    return false;
  };

export const hasContains =
  (
    permissions: AXELE_PERMISSION_TYPE[],
    webFeaturesList: AXELE_WEB_FEATURE_TYPE[]
  ) =>
  (
    perm: AXELE_PERMISSION_TYPE[] | AXELE_WEB_FEATURE_TYPE[],
    permissionsList:
      | AXELE_PERMISSION_TYPE[]
      | AXELE_WEB_FEATURE_TYPE[]
      | undefined
  ): boolean | null => {
    if (!validateProperties(permissions, webFeaturesList)(perm)) return false;
    let has = false;
    if (permissionsList) {
      for (let i = 0; i < perm?.length; i += 1) {
        if (permissionsList.includes(perm[i])) {
          has = true;
          break;
        }
      }
    }
    return has ? true : null;
  };

export const getCurrentUser = (): {
  permissions: AXELE_PERMISSION_TYPE[];
  webFeaturesList: AXELE_WEB_FEATURE_TYPE[];
  roleCode?: AXELE_ROLE_TYPE;
  userStorage?: any;
} => {
  const userStorage = StorageManager.getUser();
  if (userStorage) {
    const { permissions, webFeaturesList, roleCode } = userStorage;
    return {
      permissions,
      webFeaturesList,
      roleCode,
      userStorage,
    };
  }
  return {
    permissions: [],
    webFeaturesList: [],
    roleCode: undefined,
    userStorage: undefined,
  };
};
