import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from 'react';
import ActionIconButton from './ActionIconButton';
import { SxProps, Theme } from '@mui/material';
interface ILockButton {
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  isLocked?: boolean;
  disabled?: boolean;
  id?: string;
  style?: object;
  label?: string;
  iconStyles?: SxProps;
  btnStyles?: SxProps;
  className?: string;
}

const LockButton = React.forwardRef((props: ILockButton, ref) => {
  const IconToDisplay = props?.isLocked ? LockOutlinedIcon : LockOpenIcon;
  return (
    <ActionIconButton
      ref={ref}
      id={props?.id ?? 'lock-button'}
      variant={props.isLocked ? 'contained' : 'outlined'}
      onClick={props.onClick}
      disabled={props.disabled}
      className={props?.className ?? 'lock-button'}
      style={props?.btnStyles}
    >
      <>
        <IconToDisplay
          sx={{
            width: '14px',
            height: '14px',
            pointerEvents: 'none',
            ...props?.iconStyles,
          }}
          color={props.isLocked || props.disabled ? 'inherit' : 'primary'}
        />
        {props?.label}
      </>
    </ActionIconButton>
  );
});

export default LockButton;
