import {
  CreateNotesDTO,
  DeleteNotesDTO,
  EditNotesDTO,
  EntityNoteItem,
  GetNotesDTO,
  NotesListRes,
} from '../../models';
import { Service } from './Service';
import { ServiceError } from './helperTypes';
import {
  CreateEntityNotesRequest,
  DeleteEntityNotesRequest,
  GetEntityNotesRequest,
  UpdateEntityNotesRequest,
} from '@/models/DTOs/Notes/Requests';

export abstract class INotesService extends Service {
  abstract getNotesList(
    requestData: GetNotesDTO
  ): Promise<Array<NotesListRes> | null>;

  abstract createNotesData(
    payloadData: CreateNotesDTO
  ): Promise<NotesListRes | null | ServiceError>;

  abstract deleteNotesData(
    payloadData: DeleteNotesDTO
  ): Promise<NotesListRes | undefined>;

  abstract editNotesData(
    payloadData: EditNotesDTO
  ): Promise<NotesListRes | null | ServiceError>;

  abstract getEntityNotes(
    payloadData: GetEntityNotesRequest
  ): Promise<EntityNoteItem[] | ServiceError>;

  abstract deleteEntityNotes(
    payloadData: DeleteEntityNotesRequest
  ): Promise<boolean | ServiceError>;

  abstract createEntityNote(
    payloadData: CreateEntityNotesRequest
  ): Promise<EntityNoteItem | ServiceError>;

  abstract updateEntityNote(
    payloadData: UpdateEntityNotesRequest
  ): Promise<EntityNoteItem | ServiceError>;
}
