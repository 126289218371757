import Box from '@mui/material/Box';
import { FC } from 'react';
import { NotesContent } from './NotesContent';
import { useEntityNotes } from '@/common/EntityNotes/hooks';
import { Skeleton, Stack } from '@mui/material';
import { NoteEntityType } from '@/common/EntityNotes/utils/types';

interface Props {
  entityId: string;
  entityType: NoteEntityType;
}

export const EntityNotes: FC<Props> = ({ entityId, entityType }) => {
  const { entityNotes, getEntityNotes, loading } = useEntityNotes(
    entityId,
    entityType
  );

  return (
    <Box sx={{ overflowY: 'auto', padding: 1 }}>
      {loading ? (
        <Stack flexDirection="column" gap={4}>
          <Skeleton variant="rectangular" width={'100%'} height={60} />
          <Skeleton variant="rectangular" width={'100%'} height={60} />
          <Skeleton variant="rectangular" width={'100%'} height={60} />
          <Skeleton variant="rectangular" width={'100%'} height={60} />
        </Stack>
      ) : (
        <NotesContent
          entityId={entityId}
          entityType={entityType}
          getEntityNotes={getEntityNotes}
          entityNotes={entityNotes}
        />
      )}
    </Box>
  );
};
