import { EEDITendersEntity } from '../constants/customView';
import { EEDITendersStatus } from '../constants/types';
import {
  ELocationLevel,
  TLocationSelectValue,
} from '../../../common/Ui/LocationSelect';
import { addressType } from '../../../common/Ui/AddressField/constants';
import {
  IStateValue,
  IZoneValue,
} from '../../../common/Ui/LocationSelect/StateZoneSelect';

export const getCustomviewTenderStatus = (
  customview: EEDITendersEntity
): EEDITendersStatus => {
  switch (customview) {
    case EEDITendersEntity.NEW:
      return EEDITendersStatus.New;
    case EEDITendersEntity.ACCEPTED:
      return EEDITendersStatus.Accepted;
    case EEDITendersEntity.DECLINED:
      return EEDITendersStatus.Declined;
    case EEDITendersEntity.CANCELED:
      return EEDITendersStatus.Canceled;
    case EEDITendersEntity.ALL:
      return EEDITendersStatus.All;

    default:
      throw Error('Invalid');
  }
};

export const getLocationPayloadFormat = (
  type: 'pickup' | 'dropOff',
  value?: TLocationSelectValue
): Partial<{
  pickupState: string;
  pickupCity: string;
  pickupZip: string;
  dropOffState: string;
  dropOffCity: string;
  dropOffZip: string;
}> => {
  if (!value?.data) return {};
  switch (value?.locationLevel) {
    case ELocationLevel.City:
      return {
        [`${type}City`]: [(value.data as addressType).city]
          .filter(Boolean)
          .map((value) => (value || '').toUpperCase()),
      };
    case ELocationLevel.State:
      return {
        [`${type}State`]: (value.data as IStateValue[])
          .map(({ title }) => title)
          .filter(Boolean)
          .map((value) => (value || '').toUpperCase()),
      };
    case ELocationLevel.Zone:
      return {
        [`${type}Zip`]: (value.data as IZoneValue[])
          .map(({ value }) => value)
          .filter(Boolean)
          .map((value) => (value || '').toUpperCase()),
      };
    default:
      return {};
  }
};
