import { addressType } from '../../../types/index';
export class SortResponse {
  property!: string;
  directiion!: string;
}
export class CommodityType {
  commodityTypeId!: string;
  commodityType!: string;
}

export enum CountryCode {
  USA = 'USA',
}

export type OptionType = {
  key?: string;
  value: string;
};

export class Address {
  fullAddress: string;
  address: string;
  city: string;
  state: string;
  streetAddress: string;
  streetAddress2: string;
  zip: string;

  constructor(dto: addressType) {
    this.fullAddress = dto.fullAddress;
    this.address = dto.address;
    this.city = dto.city;
    this.state = dto.state;
    this.streetAddress = dto.streetAddress;
    this.streetAddress2 = dto.streetAddress2;
    this.zip = dto.zipcode;
  }
}
