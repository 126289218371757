import { get } from 'lodash';
import moment, { MomentInput } from 'moment';
import { DriverPreferenceDTO } from '../../../models';
import { GetPreferenceDriverResponse } from '../../../models/DTOs/Dispatch/Dispatch';
import {
  getDvirPreferences,
  getPreferredTruckstopsType,
  getWorkWeekType,
} from '../constants/preferences';
import { IDispatchPreferencesFormData } from '../constants/types';
import { states } from '../../../common/Ui/AddressField/constants/states';

export const isInutDateToday = (
  inputDate: Date | null = new Date()
): boolean => {
  return moment(inputDate).diff(moment(), 'days') === 0;
};

export const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const isGanttEventsStartWithin = ({
  startTime,
  hours,
}: {
  startTime: MomentInput;
  hours: number;
}): boolean => {
  const diffHours = moment(startTime).diff(moment(), 'hours');
  return diffHours <= hours && diffHours >= 0;
};

export const isGanttEventsLastGAP = ({
  startTime,
}: {
  startTime: MomentInput;
}): boolean => {
  return startTime === null;
};

export const convertPreferenceDriverToPreferencesData = (
  preferenceDriver: GetPreferenceDriverResponse | DriverPreferenceDTO
): IDispatchPreferencesFormData => {
  let locationPreferenceArray = [] as Array<any>;
  if (
    preferenceDriver?.locationPreference &&
    Object.keys(preferenceDriver?.locationPreference)
  ) {
    const keys = Object.keys(preferenceDriver?.locationPreference);
    locationPreferenceArray = states.filter((option) =>
      keys?.includes(option.value)
    );
  }
  const preferencesData: IDispatchPreferencesFormData = {
    ...preferenceDriver,
    dvirPreferences: preferenceDriver.dvirPreferences,
    workWeekType: preferenceDriver.workWeekType
      ? getWorkWeekType().find(
          (option) => option.key === preferenceDriver.workWeekType
        )
      : null,
    weeklyResetDay: preferenceDriver.weeklyResetDay || 'ANY',
    weeklyRevenueTarget: preferenceDriver.weeklyRevenueTarget,
    weeklyEarningsTarget: preferenceDriver.weeklyEarningsTarget,
    weeklyMileageTarget: preferenceDriver.weeklyMileageTarget,
    drivingBehaviourPreference: preferenceDriver.drivingBehaviourPreference,
    statesToAvoid:
      preferenceDriver?.statesToAvoid &&
      preferenceDriver?.statesToAvoid?.length > 0
        ? states.filter((option) =>
            preferenceDriver.statesToAvoid?.includes(option.value)
          )
        : [],
    preferredTruckstops: preferenceDriver.preferredTruckstops
      ? getPreferredTruckstopsType().filter((option) =>
          preferenceDriver?.preferredTruckstops.includes(option.key)
        )
      : [],
    locationPreference:
      locationPreferenceArray?.length > 0 ? locationPreferenceArray : [],
  };
  return preferencesData;
};

export const getGanttFinalData = <TModel,>(
  dataList: TModel[],
  newDataList: TModel[],
  primaryKey: keyof TModel
): TModel[] => {
  const finalAllData = dataList.map((preRecord) => {
    const newRecord = newDataList.find(
      (item: TModel) => get(item, primaryKey) === get(preRecord, primaryKey)
    );
    if (newRecord) {
      return newRecord;
    }
    return preRecord;
  });
  newDataList.forEach((newRecord) => {
    const oldRecord = finalAllData.find(
      (item: TModel) => get(item, primaryKey) === get(newRecord, primaryKey)
    );
    if (!oldRecord) {
      finalAllData.push(newRecord);
    }
  });

  return finalAllData;
};

export const driverPreferenceDataFormat = (updateData: any) => {
  if (
    updateData &&
    updateData?.preferencesData?.statesToAvoid &&
    updateData.preferencesData?.statesToAvoid?.length > 0
  ) {
    const stateData = [] as any[];
    updateData.preferencesData?.statesToAvoid?.map((state: any) => {
      if (state?.value) {
        stateData.push(state.value);
      } else if (state) {
        states.filter((option) => {
          if (state?.includes(state)) {
            stateData.push(option.value);
          }
        });
      }
    });
    updateData.preferencesData.statesToAvoid = stateData;
  }
  if (
    updateData.preferencesData?.locationPreference &&
    updateData.preferencesData?.locationPreference?.length > 0
  ) {
    const stateData = {} as { [key: string]: any };
    updateData.preferencesData?.locationPreference?.map((state: any) => {
      if (state.value) {
        stateData[state.value] = null;
      }
    });
    updateData.preferencesData.locationPreference = stateData;
  } else {
    updateData.preferencesData.locationPreference = {};
  }
  return updateData.preferencesData as IDispatchPreferencesFormData;
};
