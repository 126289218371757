import {
  Box,
  Chip,
  Autocomplete as MuiAutocomplete,
  Stack,
  SvgIconTypeMap,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import InputLabel from '../../InputLabel';
import { makeAsteriskRed } from '../Utils';
import {
  getColotInfo,
  getColotLabelInfo,
} from '@/views/customTags/customTagChip';

export type MultipleAutocompleteBaseTypes = {
  id?: string;
  name: string;
  getOptions?: (value: string, pageNumber: number) => any;
  options?: any[];
  fieldName: string;
  fieldValue?: string | number;
  immutableModel?: boolean;
  defaultValue?: any;
  label: string;
  onChangeCb?: (data: any[], name: string) => void;
  customStyles?: SxProps<Theme>;
  customTextInputStyles?: SxProps<Theme>;
  value?: any;
  error?: { message: string };
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  customInputProps?: SxProps<Theme>;
  customInputLabelProps?: SxProps<Theme>;
  freeSolo?: boolean;
  startAdornment?: JSX.Element | null;
  required?: boolean;
  areChipsInsideOption?: boolean;
  ChipProps?: any;
  onOpen?: () => {};
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  checkedIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  openAsDefault?: boolean;
  PopperSxProps?: SxProps;
  avoidLazyLoading?: boolean;
  fixedLabel?: boolean;
  placeholder?: string;
  isMultiLineOption?: boolean;
  subFieldName?: string;
  isCustomTag?: boolean;
};

interface InnerValue {
  name: string;
}

const BaseMultipleAutocomplete = ({
  id = '',
  name,
  getOptions,
  options: defaultOptions,
  fieldName,
  fieldValue,
  immutableModel = false,
  defaultValue = [],
  label,
  onChangeCb,
  customStyles = {},
  customTextInputStyles = {},
  customInputProps = {},
  value,
  error,
  disabled = false,
  variant = 'outlined',
  size,
  customInputLabelProps,
  freeSolo = false,
  startAdornment = null,
  required,
  areChipsInsideOption = false,
  ChipProps,
  onOpen,
  icon,
  checkedIcon,
  openAsDefault = false,
  avoidLazyLoading = false,
  PopperSxProps,
  fixedLabel,
  placeholder,
  isMultiLineOption,
  subFieldName,
  isCustomTag = false,
}: MultipleAutocompleteBaseTypes) => {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState<boolean>(openAsDefault);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState(false);
  const [innerValues, setInnerValues] = useState<InnerValue[]>(value || []);
  const last = useRef(true);
  const pageNumber = useRef(1);
  useEffect(() => {
    value && setInnerValues(value);
  }, [value]);

  const makeRequest = async (val: string, listBoxNode?: any) => {
    if (loading) return;
    if (getOptions) {
      setLoading(true);
      const result = await getOptions(val, pageNumber.current);
      try {
        if (result) {
          last.current = result.last;
          pageNumber.current++;
          if (listBoxNode && !defaultOptions) {
            setOptions((oldOptions) => {
              const newResults = result.content || result;
              return newResults?.length
                ? [...oldOptions, ...newResults]
                : oldOptions;
            });
            setLoading(false);
            listBoxNode.scrollTop =
              listBoxNode.scrollHeight -
              listBoxNode.scrollHeight / (pageNumber.current - 1);
          } else {
            setOptions(result?.content || result);
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setOptions([]);
    setInputValue('');
  };

  const handleOpen = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  const handleMakeRequestOnInputChange = async (value: string) => {
    pageNumber.current = 1;
    makeRequest(value);
  };

  const handleChange = (data: any[]) => {
    const newData =
      data && fieldValue
        ? data.map((d) => (typeof d === 'object' ? d[fieldValue] : d))
        : data;
    areChipsInsideOption && setInnerValues(data);
    onChangeCb && onChangeCb(immutableModel ? data : newData, name);
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (open && !defaultOptions) {
      handleMakeRequestOnInputChange(inputValue);
    }
  }, [inputValue, open]);

  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);

  const onDelete = (title: InnerValue) => () => {
    const data = innerValues.filter(
      (prevValue: InnerValue) =>
        prevValue[fieldName as keyof InnerValue] !==
        title[fieldName as keyof InnerValue]
    );
    setInnerValues(data);
    onChangeCb && handleChange(data);
  };

  const currTheme = useTheme();
  const overrideTheme = createTheme(currTheme, {
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          popper: ({ theme }: { theme: Theme }) =>
            theme.unstable_sx({
              ...(PopperSxProps
                ? {
                    width: 'unset !important',
                    ...PopperSxProps,
                    fontSize: '14px!important',
                  }
                : customStyles),
            }),
        },
      },
    },
  });

  return (
    <ThemeProvider theme={overrideTheme}>
      <MuiAutocomplete
        size={size}
        openOnFocus
        disableClearable={areChipsInsideOption && open}
        id={id}
        ListboxProps={{
          onScroll: (event) => {
            const listBoxNode = event.currentTarget;
            if (
              Math.abs(
                listBoxNode.scrollHeight -
                  listBoxNode.clientHeight -
                  listBoxNode.scrollTop
              ) < 1 &&
              !last.current
            ) {
              makeRequest(inputValue, listBoxNode);
            }
          },
        }}
        freeSolo={freeSolo}
        multiple
        limitTags={1}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        options={defaultOptions || options}
        value={areChipsInsideOption ? innerValues : value}
        disableCloseOnSelect
        defaultValue={defaultValue || []}
        loading={loading}
        isOptionEqualToValue={(option: any, value) => {
          if (option) {
            if (fieldValue) {
              if (typeof value === 'object' && immutableModel) {
                return option[fieldValue] === value[fieldValue];
              }
              return option[fieldName] === value;
            }
            return option[fieldName] === value[fieldName];
          }
          return false;
        }}
        sx={{
          ...customStyles,
        }}
        onChange={(event, value) => {
          handleChange(value);
        }}
        getOptionLabel={(option: any) => (option && option[fieldName]) || []}
        getOptionDisabled={(option: any) => option.disabled}
        renderOption={(props, option, { selected }) => {
          return (
            <>
              {areChipsInsideOption &&
                (props as any)?.['data-option-index'] === 0 &&
                innerValues[0] && (
                  <Box
                    sx={{
                      p: '14px',
                      borderBottom: '1px solid grey',
                    }}
                  >
                    <Stack direction="row" flexWrap="wrap" gap={1}>
                      {innerValues.map((innerValue) => (
                        <Chip
                          size="small"
                          key={innerValue[fieldName as keyof InnerValue]}
                          label={innerValue[fieldName as keyof InnerValue]}
                          onDelete={onDelete(innerValue)}
                          sx={
                            isCustomTag
                              ? getColotInfo(innerValue.rgbColor)
                              : null
                          }
                        />
                      ))}
                    </Stack>
                  </Box>
                )}

              <Typography
                fontSize={14}
                component={'li'}
                {...props}
                key={props.id}
              >
                <Checkbox
                  size="small"
                  checked={selected}
                  icon={icon}
                  checkedIcon={checkedIcon}
                />
                <Box>
                  <Box>
                    <Typography
                      variant="inputText"
                      sx={{
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-all',
                        color: 'text.primary',
                        fontSize: '14px',
                        ...(isCustomTag
                          ? getColotLabelInfo(option.rgbColor)
                          : null),
                      }}
                    >
                      {option[fieldName]}
                    </Typography>
                  </Box>
                  <Box>
                    {isMultiLineOption && (
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '12px !important',
                          fontWeight: 400,
                          lineHeight: 1.167,
                          color: 'text.primary',
                          padding: '5px 5px',
                        }}
                      >
                        {(subFieldName && option[subFieldName]) || ''}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Typography>
            </>
          );
        }}
        renderTags={
          areChipsInsideOption
            ? open
              ? () => <></>
              : (value, getTagProps) => {
                  const numTags = value.length;
                  const limitTags = 1;
                  return (
                    <>
                      {value.slice(0, limitTags).map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={index}
                          label={option[fieldName]}
                          size={size}
                          color={ChipProps?.color}
                          sx={
                            isCustomTag
                              ? getColotInfo(option.rgbColor)
                              : ChipProps?.sx
                          }
                          disabled={option.disabled}
                        />
                      ))}
                      {numTags > limitTags && (
                        <Typography component={'span'}>
                          +{numTags - limitTags}
                        </Typography>
                      )}
                      {/* {numTags > limitTags && ` +${numTags - limitTags}`} */}
                    </>
                  );
                }
            : (values, getTagProps) => {
                return values.map((value, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={fieldValue ? value : value[fieldName]}
                    key={index}
                    size={size}
                    color={ChipProps?.color}
                    disabled={value.disabled}
                    sx={
                      isCustomTag ? getColotInfo(value.rgbColor) : ChipProps?.sx
                    }
                  />
                ));
              }
        }
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            variant={variant}
            sx={{ ...makeAsteriskRed, ...customTextInputStyles }}
            size={size}
            onChange={debouncedChangeHandler}
            label={
              fixedLabel && label ? <InputLabel>{label}</InputLabel> : label
            }
            error={!!error}
            helperText={error ? error.message : null}
            InputLabelProps={{
              sx: customInputLabelProps,
            }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'disabled',
              sx: customInputProps,
              startAdornment: (params.InputProps.startAdornment ||
                startAdornment) && (
                <>
                  {startAdornment}
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress
                      sx={{
                        position: 'absolute',
                        right: 8,
                      }}
                      color="inherit"
                      size={16}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </React.Fragment>
              ),
            }}
            placeholder={placeholder}
          />
        )}
        ChipProps={ChipProps}
      />
    </ThemeProvider>
  );
};

export default BaseMultipleAutocomplete;
