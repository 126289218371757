import {
  maintenanceHistoryService,
  maintenanceItemService,
} from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { IMaintenanceHistory } from '../../../../models/DTOs/maintenance/maintenanceHistory/Model';
import {
  MaintenanceHistoryListTotalRequest,
  PaginatedMaintenanceHistoryListRequest,
} from '../../../../models/DTOs/maintenance/maintenanceHistory/Requests';
import { IMaintenanceItem } from '../../../../models/DTOs/maintenance/maintenanceItem/Model';
import { PaginatedResult, ViewMetaData } from '../../../../types';

const preGetArrayParams = (viewMetaData: ViewMetaData, fieldName: string) => {
  if (viewMetaData[fieldName]?.length > 0) {
    return viewMetaData[fieldName];
  }
  return null;
};
const getFilteredRequest = (
  viewMetaData: ViewMetaData,
  request: PaginatedMaintenanceHistoryListRequest
): PaginatedMaintenanceHistoryListRequest => {
  const { sort } = viewMetaData;

  Object.assign(request, {
    maintenanceItems: preGetArrayParams(viewMetaData, 'maintenanceItems'),
    assetId: preGetArrayParams(viewMetaData, 'assetId'),
    equipmentTypes: preGetArrayParams(viewMetaData, 'equipmentTypes'),
    sort,
  });
  return request;
};

export const getPaginatedMaintHistoryListRequest = (
  queryParams: ViewMetaData
): PaginatedMaintenanceHistoryListRequest => {
  const request = new PaginatedMaintenanceHistoryListRequest(queryParams);
  return getFilteredRequest(queryParams, request);
};

let instance: MaintenanceController | null = null;
class MaintenanceController {
  static instance(): MaintenanceController {
    if (instance === null) {
      instance = new MaintenanceController();
    }
    return instance;
  }

  static destroy() {
    instance = null;
  }

  maintenanceItems!: IMaintenanceItem[];

  private constructor() {
    this.maintenanceItems = [];
  }

  fetchMaintenanceItems = async (): Promise<IMaintenanceItem[]> => {
    try {
      if (this.maintenanceItems.length === 0) {
        //caching the option list
        const data = await maintenanceItemService.getByOrganizationId();

        if (data instanceof ServiceError) {
          return [];
        } else {
          this.maintenanceItems = data || [];
          return this.maintenanceItems;
        }
      }
      return this.maintenanceItems;
    } catch (error) {
      return [];
    }
  };

  fetchMaintenanceHistoriesByEquipment = async (
    queryParams: PaginatedMaintenanceHistoryListRequest
  ): Promise<PaginatedResult<IMaintenanceHistory> | null> => {
    try {
      const data = await maintenanceHistoryService.getPaginatedList(
        queryParams
      );
      if (data instanceof ServiceError) {
        return null;
      } else {
        return data || [];
      }
    } catch (error) {
      return null;
    }
  };

  fetchMaintenanceHistoryListTotal = async (
    queryParams: MaintenanceHistoryListTotalRequest
  ): Promise<number> => {
    try {
      const data =
        await maintenanceHistoryService.getMaintenanceHistoryListTotal(
          queryParams
        );

      if (data instanceof ServiceError) {
        return 0;
      } else {
        return data;
      }
    } catch (error) {
      return 0;
    }
  };
}

export default MaintenanceController;
