import axios from 'axios';

export default async (query: string | undefined, onlyState = false) => {
  const authToken = process.env.TRIMBLEMAPS_TOKEN;
  const countries = 'US, CA,MX';
  const countryType = 'ISO';
  const getAllHNRanges = false;
  const include = 'Meta';
  const maxResults = 25;
  const useCustomPlaces = false;

  const uri = `https://singlesearch.alk.com/NA/api/search?authToken=${authToken}&countryType=${countryType}&getAllHNRanges=${getAllHNRanges}&maxResults=${maxResults}&query=${query}&include=${include}&countries=${countries}&useCustomPlaces=${useCustomPlaces}&excludeResultsFor=RouteAlpha,POI,POIStreet,POIType,CustomPlace,CrossStreet,LatLon`;
  const onlyStateUri = `https://singlesearch.alk.com/NA/api/search?authToken=${authToken}&countryType=${countryType}&getAllHNRanges=${getAllHNRanges}&maxResults=${maxResults}&query=${query}&include=${include}&countries=${countries}&useCustomPlaces=${useCustomPlaces}&excludeResultsFor=Country,County,ZIP,SPLC,Street,RouteNumber,RouteAlpha,POI,POIStreet,FullPostCode,POIType,CrossStreet,LatLon,CustomPlace`;

  try {
    const requestUrl = onlyState ? onlyStateUri : uri;
    const response = await axios.get(requestUrl);
    if (response?.data) return response.data.Locations;
  } catch (error) {
    console.error(error);
    return [];
  }
};
