import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { AXELE_ROLE_TYPE } from '../../../../../common/Permission';
import { FormAddress } from '../../../../../common/Ui/AddressField';
import FormPhoneField from '../../../../../common/Ui/FormPhoneField';
import TextField from '../../../../../common/Ui/TextField/TextField';
import { TerminalContentDTO } from '../../../../../models';
import { TerminalLogo } from '../../../terminals/components/TerminalLogo';

const defaultTextFieldProps = {
  sizes: {
    xs: 6,
  },
  style: { marginBottom: '16px' },
};

export const Overview = ({
  canEdit,
  isCreatePanel,
  terminalDTO,
}: {
  canEdit: boolean;
  isCreatePanel: boolean;
  terminalDTO?: TerminalContentDTO;
}) => {
  const { control } = useFormContext();
  const userStorage = StorageManager.getUser();
  return (
    <Box className={'Overview-root'} sx={{ width: '100%' }}>
      {!isCreatePanel && terminalDTO && (
        <TerminalLogo terminalDTO={terminalDTO} />
      )}
      <Grid container>
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'Terminal Name'}
          name={'companyName'}
          required
          disabled={!canEdit}
          sizes={{ xs: 12 }}
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'Contact at this Terminal'}
          name={'contactName'}
          disabled={!canEdit}
          sizes={{ xs: 12 }}
          required
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'DOT Number'}
          name={'dot'}
          onlyNumbers={true}
          disabled={!canEdit}
          sizes={{ xs: 6 }}
          required
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'MC Number'}
          name={'mc'}
          disabled={!canEdit}
          sizes={{ xs: 6 }}
        />

        <FormPhoneField
          control={control}
          name={`phoneData`}
          label="Phone"
          sizes={{ xs: 12 }}
          gridSxProp={{
            mt: 1,
            mb: 0,
          }}
          disabled={!canEdit}
          required
        />
        <FormPhoneField
          control={control}
          name={`faxData`}
          label="Fax"
          sizes={{ xs: 12 }}
          gridSxProp={{
            mt: 1,
            mb: 0,
          }}
          disabled={!canEdit}
        />
        <TextField
          {...defaultTextFieldProps}
          control={control}
          label={'Email'}
          name={'email'}
          {...(isCreatePanel
            ? {
                disabled: !canEdit,
              }
            : {
                disabled: userStorage?.roleCode !== AXELE_ROLE_TYPE.ADMIN,
              })}
          sizes={{ xs: 12 }}
          required
        />
        <FormAddress
          isManual={true}
          control={control}
          name={'addressData'}
          sizes={{ xs: 12 }}
          disabled={!canEdit}
          style={{
            marginBottom: 20,
            paddingTop: 0,
          }}
          required
        />
      </Grid>
    </Box>
  );
};
