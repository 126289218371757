import {
  AddCustomViewIcon,
  CloseIcon,
} from '../../../ui-kit/components/Assets';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import Autocomplete from '../../Ui/TempAutocomplete/AutoComplete';
import { View } from '../../../types';
import { t } from 'i18next';
type PageProps = {
  open: boolean;
  handleViewSelect: (data: string) => void;
  onClose: () => void;
  getAvailableViews: () => Promise<View[] | undefined>;
};

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    '& .MuiDialog-paper': {
      width: 282,
      borderRadius: 14,
      background: 'rgba(3, 14, 24, 0.89)',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
      borderTop: '1.49777px solid #EEEEEE',
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
});

export default function ChooseViewModal({
  open,
  handleViewSelect,
  onClose,
  getAvailableViews,
}: PageProps): JSX.Element {
  const handleChange = (data: any) => {
    handleViewSelect(data);
  };

  return (
    <StyledDialog open={open}>
      <DialogTitle
        style={{
          backgroundColor: 'rgba(3, 14, 24, 0.89)',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon color={'#669EFF'} width={14} height={14} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: 'rgba(3, 14, 24, 0.89)',
          height: '100%',
        }}
      >
        <>
          <div
            style={{
              height: 199,
            }}
          >
            <div
              style={{
                marginTop: 16,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <div style={{ marginBottom: 13 }}>
                <AddCustomViewIcon />
              </div>
              <div
                style={{
                  color: '#fff',
                  fontSize: 20,
                  fontWeight: 500,
                  marginBottom: 5,
                  fontFamily: 'Poppins',
                }}
              >
                {t('financeInvoicesAddCustomViews')}
              </div>
              <div
                style={{ color: '#fff', fontSize: 12, fontFamily: 'Poppins' }}
              >
                {t('financeInvoicesCustomViewData')}
              </div>
            </div>
          </div>
          <div
            style={{
              height: 100,
              backgroundColor: 'white',
              width: '100%',
              borderTopLeftRadius: '19.3689px',
              borderTopRightRadius: '19.3689px',
              paddingTop: 18,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Autocomplete
              getOptions={getAvailableViews}
              onSelect={handleChange}
              label={''}
            />
          </div>
        </>
      </DialogContent>
    </StyledDialog>
  );
}
