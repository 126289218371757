import { SxProps, Theme } from '@mui/material';

export const getClassesStyles = (theme: Theme): { [key: string]: SxProps } => ({
  container: {
    backgroundColor: theme?.palette?.leftMenuCollapsed?.canvas,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 10px 5px 32px',
    position: 'fixed',
    top: '0px',
    width: '-webkit-fill-available',
    height: '56px',
    zIndex: 1200,
    ml: '105px',
  },
  subContainer: {
    position: 'absolute',
    bottom: '-14px',
    left: 0,
    backgroundColor: theme?.palette?.leftMenuCollapsed?.canvas,
    height: '14px',
    width: '14px',
  },
  border: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme?.palette?.uncategorized?.panel,
    height: '14px',
    width: '14px',
    borderTopLeftRadius: '14px',
  },
  due: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    textDecorationLine: 'underline',
    textTransform: 'capitalize',
    color: theme?.palette?.warning?.contrast,
    padding: '5px 8px',
    backgroundColor: theme?.palette?.warning?.main,
    borderRadius: '4px',
    marginRight: '8px',
    cursor: 'pointer',
  },
  daysleft: {
    fontWeight: 'fontWeightMedium',
    fontSize: 'fontSize',
    color: theme?.palette?.primary?.contrast,
    marginRight: '8px',
  },
  terminal: {
    color: 'primary.contrast',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme?.palette?.tabStrip?.actionIcon,
    },
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.tabStrip?.actionIcon,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme?.palette?.tabStrip?.actionIcon,
    },
    '& label.Mui-focused': {
      color: theme?.palette?.tabStrip?.actionIcon,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme?.palette?.tabStrip?.actionIcon,
    },
  },
  search: {
    color: theme?.palette?.primary?.main,
    border: '1px solid',
    borderColor: theme?.palette?.tabStrip?.actionIcon,
    height: '36px',
    padding: '0px 5px',
    borderRadius: '6px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '13px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme?.palette?.primary?.outlinedHoverBackground,
    },
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px',
    width: '316px',
    backgroundColor: theme?.palette?.background?.default,
    boxShadow: '0px 1px 12px rgba(78, 101, 120, 0.15)',
    borderRadius: '24px',
    position: 'absolute',
    right: 0,
  },
  flex: {
    pl: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  pro: {
    marginRight: '10px',
    backgroundColor: theme?.palette?.primary?.outlinedHoverBackground,
    borderRadius: '16px',
    color: theme?.palette?.primary?.main,
    padding: '3px 4px',
    width: '46px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: 'fontWeightBold',
  },
  theme: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '24px',
  },
  title: {
    color: theme?.palette?.text?.primary,
    fontWeight: 'fontWeightMedium',
    fontSize: 'fontSize',
  },
  light: {
    display: 'flex',
    height: '36px',
    backgroundColor: theme?.palette?.primary?.outlinedHoverBackground,
    borderRadius: '10px',
  },
  devider: {
    marginBottom: '24px',
    width: '100%',
    backgroundColor: 'divider',
    padding: '1px',
  },
  icon: {
    marginBottom: '20px',
    display: 'flex',
    color: theme?.palette?.primary?.main,
    cursor: 'pointer',
  },
  userImageWithIcon: {
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    backgroundColor: 'divider',
  },
  userImageWithoutIcon: {
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    backgroundColor: 'divider',
    display: 'grid',
    placeContent: 'center',
    fontSize: '25px',
    fontWeight: 500,
  },
  activateBtnStyle: {
    background: '#f7f7f7',
    color: '#2B64CB',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
});

export const topMenuStyles = (theme: Theme): { [key: string]: SxProps } => ({
  container: {
    backgroundColor: theme?.palette?.leftMenuCollapsed?.canvas,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 5px 5px 12px',
    // position: 'relative',
    // top: '0px',
    width: '-webkit-fill-available',

    height: '50px',
    zIndex: 1200,
    [theme?.breakpoints?.down('tablet')]: {
      ml: 0,
      padding: '5px 10px 5px 16px',
    },
    // [theme?.breakpoints?.up('tablet')]: {
    //   ml: '75px',
    // },
  },
  menuIcon: {
    color: theme?.palette?.primary?.main,
    border: '1px solid',
    borderColor: theme?.palette?.tabStrip?.actionIcon,
    height: '36px',
    padding: '6px',
    borderRadius: '6px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 9000,
    '&:hover': {
      backgroundColor: theme?.palette?.primary?.outlinedHoverBackground,
    },
  },
  notificationIconSxProps: {
    minWidth: '32px',
    [theme?.breakpoints?.down('tablet')]: {
      mr: 0,
    },
    [theme?.breakpoints?.up('tablet')]: {
      mr: 2,
    },
  },
  onlyForMobile: {
    display: 'none',
    [theme?.breakpoints?.down('tablet')]: {
      display: 'inherit',
    },
  },
  notForMobile: {
    [theme?.breakpoints?.down('tablet')]: {
      display: 'none',
    },
  },
});
