export const CommonConstants = {
  enterCredentials: 'Enter Your Credentials:',
  enterValidCredentialLabel:
    'Ensure you login with valid credentials to complete the integration. You will redirected to the vendor’s website to complete the integration',
  deactivate: 'Deactivate',
  activate: 'Activate',
  whitelistUrl: 'Whitelist URL',
  loads: 'Loads',
  driver: 'Driver',
  dot: 'DOT',
  phoneNumber: 'Phone Number',
  phone: 'Phone',
  fax: 'Fax',
  faxNumber: 'Fax Number',
  email: 'Email',
  grossRevenue: 'Gross Revenue',
  netRevenue: 'Net Revenue',
  actual: 'Actual',
  projected: 'Projected',
  upcoming: 'Upcoming',
  pastDue: 'Past Due',
  target: 'Target',
  actualTarget: 'Actual Target',
  empty: 'Empty',
  loaded: 'Loaded',
  needsLoads: 'Need Loads',
  onVacation: 'On Vacation',
  unavailable: 'Unavailable',
  available: 'Available',
  invoices: 'Invoices',
  unlocatedDrivers: 'Unlocated Drivers',
  loadInformation: 'Load Information',
  delayed: 'Delayed',
  payments: 'Payments',
  pending: 'Pending',
  missing: 'Missing',
  documents: 'Documents',
  ready: 'Invoiceable',
  toInvoice: 'Loads',
  drivers: 'Drivers',
  tractors: 'Tractors',
  trailers: 'Trailers',
  name: 'Name',
  status: 'Status',
  terminal: 'Terminal',
  lastLoggedIn: 'Last Logged In',
  send: 'Send',
  cancel: 'Cancel',
  warning: 'Warning',
  delete: 'Delete',
  ohFudge: 'oh fudge.',
  importInstead: 'Import  Instead',
  overview: 'Overview',
  activity: 'Activity',
  loadDetails: 'Load Details',
  manifestDetails: 'Manifest Details',
  routes: 'Routes',
  finance: 'Finance',
  invoice: 'Invoice',
  invoiceStatus: 'Invoice Status',
  tracking: 'Tracking',
  notes: 'Notes',
  additionalDetails: 'Additional Details',
  noDataToShow: 'No data to show',
  expenses: 'Expenses',
  total: 'Total',
  description: 'Description',
  period: 'Period',
  id: 'ID',
  date: 'Date',
  category: 'Category',
  customer: 'Customer',
  saveChanges: 'Save Changes',
  state: 'State',
  tractor: 'Tractor',
  trailer: 'Trailer',
  add: 'Add',
  deletePermanentlyWithDynamicName:
    'Are you sure you want to permanently delete this',
  yes: 'Yes',
  no: 'No',
  other: 'Other',
  brokered: 'Brokered',
  download: 'Download',
  amount: 'Amount',
  account: 'Account',
  factoring: 'Factoring',
  origin: 'Origin',
  missingDocuments: 'Missing Documents',
  destination: 'Destination',
  nameYourView: 'Name Your View',
  pickData: 'Pick Data',
  addFilters: 'Add Filters',
  inReview: 'In Review',
  reviewed: 'Reviewed',
  closed: 'Closed',
  type: 'Type',
  settlement: 'Settlement',
  remove: 'Remove',
  reopen: 'Reopen',
  startDate: 'Start Date',
  endDate: 'End Date',
  create: 'Create',
  saveBeforeLeaving: 'Save First before leaving',
  today: 'Today',
  tomorrow: 'Tomorrow',
  custom: 'Custom',
  allFilter: 'All Filter',
  reset: 'Reset',
  apply: 'Apply',
  allFilters: 'All Filters',
  search: 'Search',
  refresh: 'Refresh',
  accounts: 'Accounts',
  createSettingView: 'Create a Setting View',
  equipment: 'Equipment',
  receipt: 'Receipt',
  required: 'Required',
  ok: 'Ok',
  primary: 'Primary',
  alerts: 'Alerts',
  odometer: 'Odometer (mi)',
  fuelType: 'Fuel Type',
  YES: 'YES',
  close: 'Close',
  actions: 'Actions',
  note: 'Note',
  clickHere: ' Click Here',
  exportData: 'Export Data',
  ignore: 'Ignore',
  all: 'All',
  linked: 'Linked',
  nonLinked: 'Non Linked',
  somethingWentWrong: 'Something went Wrong!',
  completedOn: 'Completed On',
  updatedOn: 'Updated On',
  invoicedOn: 'Invoiced On',
  paidOn: 'Paid On',
  invoiceNotes: 'Invoice Notes',
  referenceId: 'Reference ID',
  needReview: 'Need Review',
  paidDate: 'Paid Date',

  dearSirMadam: 'Dear Sir/Madam',
  emailBody:
    'Please find attached relevant document(s) for processing your requirements',
  regards: 'Regards',
  emailisnotvalid: 'Email is not valid.',

  rows: 'Rows',
  selected: 'Selected',
  deleteMsg: 'Are you sure you want to delete?',
  pleasesaveyourchanges: 'Please save your changes',
  assign: 'Assign',
  save: 'Save',
  nameError: 'Name is required.',
  phoneError: 'Must contain 10 digits number',
  deleteQuestionMark: 'Delete ?',
  deleteForSure: 'Are you sure you want to delete?',
  newNote: 'Add Note',
  tripmiles: 'Trip Miles',
  emptymiles: 'Empty Miles',
  revenue: 'Revenue',
  noDataHere: 'No Data Here',
  address: 'Address',
  maintenanceDetails: 'Maintenance Item',
};
