import { httpClient } from '../../../../axios/axiosInstance';
import {
  FactoringSearchableListRequest,
  PaginatedAddressesListRequest,
  PaginatedContactsListRequest,
  PaginatedMcNumbersListRequest,
  PaginatedNamesListRequest,
} from '../../../../models';

const customerNamesListUrl = '/web/customer/api/v2/customers/name/dropdown';
const customerContactsListUrl =
  '/web/customer/api/v2/customers/get/contact/dropdown';
const customerMcNumbersListUrl = '/web/customer/api/v2/customers/mc/dropdown';
const factoringListUrl =
  '/web/factoring/api/v2/integrations/organization/factoring/search';
const customerAddressesListUrl =
  '/web/customer/api/v2/customers/address/dropdown';

export const getCustomerNamesList = async (
  text: string,
  pageNumber: number
) => {
  try {
    const requestData = new PaginatedNamesListRequest({
      pageNumber,
      pageSize: 25,
      nameKeyword: text,
    });

    const response = await httpClient.getRaw(
      customerNamesListUrl,
      requestData,
      undefined,
      true
    );
    return { content: response?.data.content, last: response?.data.last };
  } catch (error) {
    return [];
  }
};

export const getCustomerContactsList = async (
  text: string,
  pageNumber: number
) => {
  try {
    const requestData = new PaginatedContactsListRequest({
      pageNumber,
      pageSize: 25,
      customerContactNameKeyword: text || '',
    });

    const response = await httpClient.getRaw(
      customerContactsListUrl,
      requestData,
      undefined,
      true
    );
    return {
      content: response?.data.content || [],
      last: response?.data?.last,
    };
  } catch (error) {
    return [];
  }
};

export const getCustomerMcNumbersList = async (
  text: string,
  pageNumber: number
) => {
  try {
    const requestData = new PaginatedMcNumbersListRequest({
      pageNumber,
      pageSize: 25,
      mcKeyword: text,
    });

    const response = await httpClient.getRaw(
      customerMcNumbersListUrl,
      requestData,
      false,
      true
    );
    return response?.data?.content || [];
  } catch (error) {
    return [];
  }
};

export const getFactoringList = async (text: string, pageSize?: number) => {
  try {
    const requestData = new FactoringSearchableListRequest({
      pageNumber: 1,
      pageSize: pageSize ?? 25,
      keyword: text,
    });

    const response = await httpClient.getRaw(
      factoringListUrl,
      requestData,
      undefined,
      true
    );
    return { content: response?.data || [], last: true };
  } catch (error) {
    return [];
  }
};

export const getCustomerAddressesList = async (
  text: string,
  pageNumber: number
) => {
  try {
    const requestData = new PaginatedAddressesListRequest({
      pageNumber,
      pageSize: 25,
      addressKeyword: text,
    });

    const response = await httpClient.getRaw(
      customerAddressesListUrl,
      requestData,
      false,
      true
    );
    const filtredResponse = response.data.content?.filter(
      (item) => item.fullAddress !== ''
    );
    return { content: filtredResponse || [], last: response?.data?.last };
  } catch (error) {
    return [];
  }
};
