import { AXELE_PERMISSION_TYPE } from '../../Permission';
import { getHasPermission } from '../../Permission/utils/helperUtils';

export const useLoadPermission = () => {
  const hasLoadViewPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOADS_VIEW],
  });

  const hasLoadCRUDPermission: boolean = getHasPermission({
    includes: [
      AXELE_PERMISSION_TYPE.LOAD_ADD,
      AXELE_PERMISSION_TYPE.LOAD_EDIT,
      // AXELE_PERMISSION_TYPE.LOAD_REMOVE,
    ],
  });

  const hasRemovePermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_REMOVE],
  });

  const hasLoadAddPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_ADD],
  });

  const hasLoadEditPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_EDIT],
  });

  const has3DotsPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_EDIT],
  });

  const hasStatusChangeToCompleted: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_TO_COMPLETED],
  });

  const hasStatusChangeCompletedToAvailable: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_COMPLETED_TO_AVAILABLE],
  });

  const hasIdEditPermission: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_ID_EDIT],
  });

  const hasStatusChangeCompletedToInvoiced: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_COMPLETED_TO_INVOICED],
  });

  const hasStatusChangeInvoicedToCompleted: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_INVOICED_TO_COMPLETED],
  });

  const hasStatusChangeInvoicedToPaid: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_INVOICED_TO_PAID],
  });

  const hasStatusChangePaidToInvoiced: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_PAID_TO_INVOICED],
  });

  const hasStatusChangeToCancelled: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_TO_CANCELLED],
  });

  const hasFinancialInvoicesView: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICES_VIEW],
  });

  const hasFinancialInvoiceEdit: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICE_EDIT],
  });

  const hasFinancialPaymentView: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_VIEW],
  });

  const hasFinancialInvoicesViewByRoleCode: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICES_VIEW],
  });

  const hasCarrierViewPermission: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.CARRIER_VIEW],
  });

  const hasCustomerViewPermission: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.CUSTOMERS_VIEW],
  });
  const hasVenderViewPermission: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.VENDORS_VIEW],
  });
  const hasLocationViewPermission: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.LOCATIONS_VIEW],
  });
  const hasImportPermission: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.IMPORT_DATA],
  });

  const hasInvoiceAddPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.INVOICE_ADD],
  });

  const hasInvoiceEditPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.INVOICE_EDIT],
  });

  const hasInvoiceDeletePermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.INVOICE_DELETE],
  });

  const hasLoadPaymentEditPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_EDIT],
  });

  const hasLoadPaymentAddPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_ADD],
  });

  const hasLoadPaymentDeletePermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_REMOVE],
  });
  const hasPaymentInReviewToClosedChangePermission: boolean = getHasPermission({
    includes: [
      AXELE_PERMISSION_TYPE.FINANCIAL_PAYMENT_CHANGE_STATUS_INREVIEW_CLOSE,
    ],
  });

  const hasPaymentTractorPaymentPreferenceEdit: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.EQUIPMENT_PAYMENT_TERM_ADD],
  });

  const hasPaymentCompleteReadyInvoice: boolean = getHasPermission({
    includes: [
      AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_COMPLETED_TO_READYTOINVOICE,
    ],
  });

  const hasPaymentReadyInvoiceToInvoice: boolean = getHasPermission({
    includes: [
      AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_READYTOINVOICE_TO_INVOICE,
    ],
  });

  const hasPaymentInvoiceToReadyInvoice: boolean = getHasPermission({
    includes: [
      AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_INVOICED_TO_READYTOINVOICE,
    ],
  });

  const hasPaymentReadyToInvoiceComplete: boolean = getHasPermission({
    includes: [
      AXELE_PERMISSION_TYPE.LOAD_STATUS_CHANGE_READYTOINVOICE_TO_COMPLETED,
    ],
  });

  return {
    hasPaymentCompleteReadyInvoice,
    hasPaymentReadyInvoiceToInvoice,
    hasPaymentInvoiceToReadyInvoice,
    hasPaymentReadyToInvoiceComplete,
    hasRemovePermission,
    hasLoadEditPermission,
    hasStatusChangeToCompleted,
    hasStatusChangeCompletedToAvailable,
    hasLoadViewPermission,
    hasLoadAddPermission,
    hasIdEditPermission,
    hasStatusChangeCompletedToInvoiced,
    hasStatusChangeInvoicedToCompleted,
    hasStatusChangeInvoicedToPaid,
    hasStatusChangePaidToInvoiced,
    hasStatusChangeToCancelled,
    hasFinancialInvoicesView,
    hasFinancialInvoiceEdit,
    hasFinancialPaymentView,
    hasFinancialInvoicesViewByRoleCode,
    hasLoadCRUDPermission,
    has3DotsPermission,
    hasCarrierViewPermission,
    hasCustomerViewPermission,
    hasVenderViewPermission,
    hasLocationViewPermission,
    hasImportPermission,
    hasInvoiceAddPermission,
    hasInvoiceEditPermission,
    hasInvoiceDeletePermission,
    hasLoadPaymentEditPermission,
    hasLoadPaymentDeletePermission,
    hasPaymentInReviewToClosedChangePermission,
    hasLoadPaymentAddPermission,
    hasPaymentTractorPaymentPreferenceEdit,
  };
};
