import { Type } from 'class-transformer';
import { UserBaseDetails } from './userHelperTypes';

export class DriverGroupBaseDetails {
  id!: string;
  organizationId!: number;
  groupName!: string;
  activeForOperation!: boolean;
  dispatcherId!: number;
  @Type(() => UserBaseDetails)
  drivers!: UserBaseDetails[];

  employmentType!: string;
  displayName!: string;
  operationType!: string;
}
