import { Dialog, DialogTitle } from '@components/ui-kit/feedback/dialog';
import {
  Box,
  CircularProgress,
  DialogContent,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Button } from '../../../../../components_v2/ui-kit/button';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (name: string) => void;
  isLoading?: boolean;
}

const CreateTemplateNameDialog: React.FC<IProps> = ({
  open,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const [templateName, setTemplateName] = useState<string>('');

  useEffect(() => {
    setTemplateName('');
  }, [open]);

  return (
    <Dialog
      open={open}
      sx={(theme: Theme) => ({
        '.MuiPaper-root': {
          border: `1px solid ${theme.palette?.primary?.main}`,
          borderRadius: '8px',
        },
      })}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight={700} color={'textPrimary'}>
          Create Template
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box padding={'8px 0'}>
          <TextField
            value={templateName}
            autoFocus
            onChange={(event: any) => {
              setTemplateName(event.target?.value);
            }}
            sx={{ width: '320px' }}
            label="Template Name"
          />
        </Box>
        <Stack justifyContent={'flex-end'} mt={2} direction={'row'} gap={'8px'}>
          <Button
            color="primary"
            variant="text"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit?.(templateName)}
            disabled={!templateName || isLoading}
            sx={(theme: Theme) => ({
              color: (theme.palette?.primary as any)?.contrast,
            })}
            startIcon={isLoading ? <CircularProgress size={16} /> : <></>}
          >
            Save
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export { CreateTemplateNameDialog };
