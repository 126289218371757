import { Service } from '@/api/interfaces/Service';
import { ServiceError } from '@/api/interfaces/helperTypes';
import { FuelMatrix } from '@/models';
import {
  CreateUpdateFuelMatrixRequest, FuelMatrixValidationError,
  GetFuelMatrixRequest,
} from '@/models/DTOs/FuelMatrix/Requests';

export abstract class IFuelMatrixService extends Service {
  abstract getFuelMatrixList(
    params: GetFuelMatrixRequest
  ): Promise<FuelMatrix[] | ServiceError>;

  abstract createFuelMatrixList(
    params: CreateUpdateFuelMatrixRequest
  ): Promise<FuelMatrix | ServiceError | FuelMatrixValidationError>;

  abstract updateFuelMatrixList(
    params: CreateUpdateFuelMatrixRequest
  ): Promise<FuelMatrix | ServiceError | FuelMatrixValidationError>;
}
