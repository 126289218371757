import {
  StopSolutionV3Prop,
  StopSolutionV3StopType,
  TripStopActivityEnum,
} from '../../../../../ui-kit/components/TripPlan';
import { ILoadSearchStop } from '../../../../../models/DTOs/Loadsearch/Model';

export const convertTripDetailToStopV3PropList = (
  stops: ILoadSearchStop[]
): StopSolutionV3Prop[] => {
  return stops.map((stop: ILoadSearchStop, idx: number) => {
    return {
      ...stop,
      id: stop.id ? stop.id : idx,
      noteDetails: {
        note: stop.notes,
      },
      location: stop?.location
        ? {
            ...stop.location,
            locationName: stop.location?.locationName || '',
            address: stop.location?.fullAddress,
            city: stop.location?.city,
            state: stop.location?.state,
          }
        : null,
      type: StopSolutionV3StopType.LOAD,
      activityType: stop.activityType as TripStopActivityEnum,
      contact: stop?.contact
        ? {
            name: [stop.contact?.firstname, stop.contact?.lastname]
              .filter((str) => !!str)
              .join(' '),
            email: stop.contact?.email,
            phone: [stop.contact?.extension, stop.contact?.phone]
              .filter((str) => !!str)
              .join('.'),
          }
        : null,
    };
  });
};
