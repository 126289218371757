import { mapTripWarnings } from '../../../../services/gantt';
import { EngineSandboxWarningsType } from '../../../../types/DispatchTypes';
import { LOCK_RESOURCE_KEYS } from '../../constants/optymization.const';
import {
  getCurrentResourceState,
  updateAssignmentAsLocked,
  updateAssignmentAsRejected,
  updateAssignmentAsUnLocked,
  updateAssignmentAsUnRejected,
} from '../../services/operations.service';
import {
  deleteDriverBreaks,
  deleteDriverTimeline,
  deleteOptymizeRecommendationById,
  formatDriversListToMap,
  formatTimeoffsToMap,
  formatTripsToMap,
  getFormattedDriverObject,
  isResourceTypeAssignedDriver,
  isResourceTypeBrokeredTrip,
  isResourceTypeUnassignedDriver,
  isResourceTypeUnassignedTrip,
  putNewAssignedTrip,
  putNewBrokeredTrip,
  putNewUnassignedDriver,
  putNewUnassignedTrip,
} from '../../services/recommendTrips.service';
import {
  IRecommendedResource,
  IRecommendedTrip,
  RecommendationResourceType,
} from '../../types/gantt.types';
import { PossibleTripResourceType } from '../../types/possibleResource.types';
import {
  IRecommendTrips,
  IRecommendTripsPayload,
} from '../../types/recommendTrips.types';
import { OPTYMIZATION_ACTIONS } from '../optymization.actions';

export const recommendedTripsInitialState: IRecommendTrips = {
  drivers: new Map(),
  trips: new Map(),
  timeoffs: new Map(),
  isLoading: false,
  isInitialLoad: true,
  lockedRecommendations: new Set(),
  isReOptimizationRunning: false,
  currentRejectedResource: { rejectedResources: new Map() },
  assignedCandidatesLockedCount: new Set(),
  brokeredTripLockedCount: new Set(),
};

export const recommendTripsReducer = (
  state: IRecommendTrips,
  action: { type: string; payload?: IRecommendTripsPayload }
): IRecommendTrips => {
  const handleLockRecommendation = (driverId: string) =>
    state?.lockedRecommendations?.add(driverId);

  const handleUnLockRecommendation = (driverId: string) =>
    state?.lockedRecommendations?.delete(driverId);

  const addResourceLockedCountByType = (driver: IRecommendedResource) => {
    if (isResourceTypeAssignedDriver(driver)) {
      state?.assignedCandidatesLockedCount?.add?.(driver?.id);
    } else if (isResourceTypeBrokeredTrip(driver)) {
      state?.brokeredTripLockedCount?.add?.(driver?.id);
    }
  };

  const removeResourceLockedCountByType = (driver: IRecommendedResource) => {
    if (isResourceTypeAssignedDriver(driver)) {
      state?.assignedCandidatesLockedCount?.delete?.(driver?.id);
    } else if (isResourceTypeBrokeredTrip(driver)) {
      state?.brokeredTripLockedCount?.delete?.(driver?.id);
    }
  };

  const clearCurrentRejectedResources = () =>
    state.currentRejectedResource?.rejectedResources?.clear?.();

  const addToCurrentRejectedResource = (resource: IRecommendedResource) =>
    state.currentRejectedResource?.rejectedResources?.set(
      resource.id,
      resource
    );

  const setCurrentRejectedResourceIndex = (index: number) => {
    if (state.currentRejectedResource)
      state.currentRejectedResource.index = index;
  };

  const { type, payload = {} } = action;

  switch (type) {
    case OPTYMIZATION_ACTIONS.START_LOADER:
      return { ...state, isLoading: true };
    case OPTYMIZATION_ACTIONS.STOP_LOADER:
      return { ...state, isLoading: false };
    case OPTYMIZATION_ACTIONS.SET_RECOMMENDED_TRIPS:
      const { drivers, trips, hosConstants, timeoffs, sandboxId } = payload;
      clearCurrentRejectedResources();
      const tripsMap = formatTripsToMap(trips);
      const { driversMap, lockedDrivers } = formatDriversListToMap(
        drivers,
        tripsMap
      );
      return {
        ...state,
        drivers: driversMap,
        trips: tripsMap,
        timeoffs: formatTimeoffsToMap(timeoffs),
        hosConstants,
        sandboxId,
        lockedRecommendations: lockedDrivers,
        isLoading: false,
        isInitialLoad: false,
      };
    case OPTYMIZATION_ACTIONS.SET_FORMATTED_TRIPS: {
      const {
        driversMap,
        tripsMap,
        hosConstants,
        timeoffs,
        sandboxId,
        lockedRecommendations,
        isInitialLoad = false,
        assignedCandidatesLockedCount,
        brokeredTripLockedCount,
      } = payload;
      clearCurrentRejectedResources();
      return {
        ...state,
        drivers: driversMap!,
        trips: tripsMap!,
        timeoffs: formatTimeoffsToMap(timeoffs),
        hosConstants,
        sandboxId,
        isLoading: false,
        lockedRecommendations,
        isInitialLoad: isInitialLoad,
        assignedCandidatesLockedCount,
        brokeredTripLockedCount,
      };
    }
    case OPTYMIZATION_ACTIONS.REMOVE_DRIVER_ASSIGNMENT: {
      const { rejectedResource } = payload;
      clearCurrentRejectedResources();
      rejectedResource?.ids?.forEach((id: string) => {
        deleteOptymizeRecommendationById(state?.drivers, id);
        deleteDriverTimeline(state?.trips, id);
        deleteDriverBreaks(state?.timeoffs, id);
      });
      return { ...state, isLoading: false };
    }
    case OPTYMIZATION_ACTIONS.UPDATE_ASSIGNMENTS: {
      const {
        newDriverResources,
        newTrips,
        timeoffs,
        actionType,
        rejectedResourceIndex,
        rejectedResource,
      } = payload;
      if (actionType === 'REJECT')
        newDriverResources?.forEach((driver) => {
          state?.trips?.delete?.(driver?.id);
        });
      const tripsMap = new Map([
        ...(state?.trips ?? new Map()),
        ...(formatTripsToMap(newTrips) ?? new Map()),
      ]);
      let curRejectedResourceCount = 0;
      if (state?.currentRejectedResource?.rejectedResources?.size! > 0) {
        if (state?.currentRejectedResource?.rejectedResources?.size === 2)
          curRejectedResourceCount = 2;
        else curRejectedResourceCount = 1;
      }
      clearCurrentRejectedResources();
      rejectedResource?.ids?.forEach((id: string) =>
        deleteOptymizeRecommendationById(state?.drivers, id)
      );
      newDriverResources?.forEach((driver) => {
        deleteOptymizeRecommendationById(state?.drivers, driver?.id);
        let putMethod = putNewAssignedTrip;
        if (isResourceTypeAssignedDriver(driver)) {
          const driverTrips = tripsMap?.get?.(driver?.id);
          driver.selectedTripData = driverTrips?.filter?.(
            (trip: PossibleTripResourceType) =>
              driver?.engineMultiTripOutput?.tripIdSelected?.includes(
                trip?.tripPrimaryId as string
              )
          );
        }
        if (isResourceTypeUnassignedTrip(driver)) {
          putMethod = putNewUnassignedTrip;
          driver.selectedTripData = tripsMap?.get?.(driver?.id);
        }
        if (isResourceTypeUnassignedDriver(driver)) {
          putMethod = putNewUnassignedDriver;
          driver.selectedTripData = null;
          driver.engineMultiTripOutput = null;
        }
        if (isResourceTypeBrokeredTrip(driver)) {
          putMethod = putNewBrokeredTrip;
          driver.selectedTripData = tripsMap?.get?.(driver?.id);
        }
        state.drivers?.set(
          driver?.resourceType as RecommendationResourceType,
          putMethod(state?.drivers, getFormattedDriverObject(driver))
        );
        if (driver?.engineMultiTripOutput?.isLocked) {
          handleLockRecommendation(driver?.id);
          addResourceLockedCountByType(driver);
        } else {
          handleUnLockRecommendation(driver?.id);
          removeResourceLockedCountByType(driver);
        }
        if (actionType === 'REJECT' && rejectedResourceIndex! >= 0) {
          addToCurrentRejectedResource(driver);
          setCurrentRejectedResourceIndex(
            rejectedResourceIndex! >= curRejectedResourceCount
              ? rejectedResourceIndex! - curRejectedResourceCount
              : rejectedResourceIndex!
          );
        }
      });
      if (tripsMap && state?.trips) state.trips = tripsMap;
      if (timeoffs?.length) {
        const newTimeoffs = formatTimeoffsToMap(timeoffs);
        state.timeoffs = new Map([
          ...(state.timeoffs ?? new Map()),
          ...(newTimeoffs ?? new Map()),
        ]);
      }
      return { ...state, isLoading: false };
    }
    case OPTYMIZATION_ACTIONS.LOCK_ASSIGNMENTS: {
      const { drivers = [] } = payload;
      clearCurrentRejectedResources();
      for (const driver of drivers) {
        const assignedTrips = state?.trips?.get(driver?.id);
        driver.selectedTripData = assignedTrips?.filter(
          (trip: IRecommendedTrip) =>
            driver?.engineMultiTripOutput?.tripIdSelected?.includes(
              trip.tripPrimaryId as string
            )
        );
        driver.resourceState = getCurrentResourceState(driver);
        state?.drivers
          ?.get(driver?.resourceType as RecommendationResourceType)
          ?.set?.(driver?.id, driver);
        if (driver?.engineMultiTripOutput?.isLocked) {
          handleLockRecommendation(driver?.id);
          addResourceLockedCountByType(driver);
        } else {
          handleUnLockRecommendation(driver?.id);
          removeResourceLockedCountByType(driver);
        }
      }
      return { ...state, isLoading: false };
    }
    case OPTYMIZATION_ACTIONS.LOCK_ASSIGNED_CANDIDATE: {
      const { lockedResource } = payload;
      clearCurrentRejectedResources();
      const updateFn =
        lockedResource?.type === LOCK_RESOURCE_KEYS.LOCK
          ? updateAssignmentAsLocked
          : updateAssignmentAsUnLocked;
      if (lockedResource?.updateAll) {
        state?.drivers
          ?.get?.(lockedResource?.resourceType!)
          ?.forEach((driver: IRecommendedResource) => {
            driver = updateFn(driver);
            if (lockedResource?.type === LOCK_RESOURCE_KEYS.LOCK) {
              handleLockRecommendation(driver?.id);
              addResourceLockedCountByType(driver);
            } else {
              handleUnLockRecommendation(driver?.id);
              removeResourceLockedCountByType(driver);
            }
          });
      } else {
        let driver = state?.drivers
          ?.get(lockedResource?.resourceType!)
          ?.get?.(lockedResource?.lockForResourceId as string);
        driver = updateFn(driver as IRecommendedResource);
        if (lockedResource?.type === LOCK_RESOURCE_KEYS.LOCK) {
          handleLockRecommendation(driver?.id);
          addResourceLockedCountByType(driver);
        } else {
          handleUnLockRecommendation(driver?.id);
          removeResourceLockedCountByType(driver);
        }
      }
      return { ...state, drivers: state?.drivers };
    }
    case OPTYMIZATION_ACTIONS.REOPTIMIZATION_START:
      return { ...state, isReOptimizationRunning: true };
    case OPTYMIZATION_ACTIONS.REOPTIMIZATION_COMPLETED:
      return { ...state, isReOptimizationRunning: false };
    case OPTYMIZATION_ACTIONS.OPTIMIZATION_FAILED:
      return {
        ...state,
        optimizationFailed: true,
        isLoading: false,
        isInitialLoad: false,
      };
    case OPTYMIZATION_ACTIONS.CLEAR_OPTIMIZATION_FAILURE:
      return { ...state, optimizationFailed: false };
    case OPTYMIZATION_ACTIONS.RERUN_OPTIMIZATION:
      return { ...state, isInitialLoad: true, optimizationFailed: false };
    case OPTYMIZATION_ACTIONS.CLEAR_OPTIMIZED_STORE: {
      return { ...recommendedTripsInitialState };
    }
    case OPTYMIZATION_ACTIONS.MAP_SANDBOX_WARNINGS: {
      const { warnings } = payload;
      const { drivers, trips } = state;
      const tripsWithWarnings = mapTripWarnings({
        trips,
        warnings: warnings?.trips as EngineSandboxWarningsType,
        isWarningByDriverId: true,
      });
      return {
        ...state,
        trips: tripsWithWarnings,
        warnings,
      };
    }
    case OPTYMIZATION_ACTIONS.CLEAR_CURRENT_REJECTED_RESOURCE: {
      clearCurrentRejectedResources();
      return { ...state };
    }
    case OPTYMIZATION_ACTIONS.STOP_INITIAL_LOAD:
      return { ...state, isInitialLoad: false };
    case OPTYMIZATION_ACTIONS.REJECT_RESOURCE: {
      const { rejectedRecommendedResources } = payload;
      rejectedRecommendedResources?.forEach?.((resource) => {
        const driver = state?.drivers
          ?.get(resource?.resourceType!)
          ?.get?.(resource?.id);
        updateAssignmentAsRejected(driver!);
      });
      return { ...state };
    }
    case OPTYMIZATION_ACTIONS.UNREJECT_RESOURCE: {
      const { rejectedRecommendedResources } = payload;
      rejectedRecommendedResources?.forEach?.((resource) => {
        const driver = state?.drivers
          ?.get(resource?.resourceType!)
          ?.get?.(resource?.id);
        updateAssignmentAsUnRejected(driver!);
      });
      return { ...state };
    }
    default:
      return state;
  }
};
