import JSZip from 'jszip';
import { documentService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  AttachedEntitiesRequest,
  CreateLatestCertificationRequest,
  CreateLatestDriverLicenseRequest,
  DeleteDocumentsRequest,
  DocumentItemType,
  DownloadDocumentsRequest,
  EditDocumentRequest,
  EmailDocumentsRequest,
  GetAssociatedDriversRequest,
  GetDocumentFoldersRequest,
  GetDocumentsByEntityRequest,
  GetDocumentsListByDocIdRequest,
  GetDocumentsListRequest,
  GetDocumentTypesRequest,
} from '../../../models';
import { CARRIER_DOCUMENT_TYPE } from '../../../subPages/carriers/constants/constants';
import { ECarrierStatus } from '../../../subPages/carriers/constants/types';
import { LineItem, ViewMetaData } from '../../../types';
import { convertData, docType, downloadFile } from '../../../utils/Doc';
import { getDriverLicenseRequestParams } from './utils';
import { saveAs } from '../../../utils/saveFile';
class DocumentActions {
  static documentsLineItemsMap: Record<string, LineItem> = {};

  static async getDocumentFolders(getGlobalTerminalsIds: string[]) {
    const request = new GetDocumentFoldersRequest();
    request.terminalIds = getGlobalTerminalsIds;
    const response = await documentService.getDocumentFolders(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async getDocumentLineItems() {
    const request = new GetDocumentTypesRequest();
    const response = await documentService.getDocumentTypes(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async getDocumentsByEntity(entityType: string, terminalIds: string[]) {
    const request = new GetDocumentsByEntityRequest();
    request.terminalIds = terminalIds;
    request.entityType = entityType;
    const response = await documentService.getDocumentsByEntity(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async getDocumentListByTypeId(documentTypeIds: string[]) {
    const request = new GetDocumentsListByDocIdRequest();
    request.documentTypeIds = documentTypeIds;
    const response = await documentService.getDocumentListByTypeId(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async downloadDocuments(
    ids: string[],
    skipLoader = false,
    isSharing = false
  ) {
    const request = new DownloadDocumentsRequest();
    request.id = ids;
    const response = await documentService.downloadDocuments(
      request,
      skipLoader,
      isSharing
    );
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async getAssociatedDrivers(
    pageNumber: number,
    searchText: string,
    entity: string
  ) {
    const request = new GetAssociatedDriversRequest();
    request.pageNumber = pageNumber;
    request.searchText = searchText;
    request.entity = entity;
    const response = await documentService.getAssociatedDrivers(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async emailDocuments(
    data: { to: string; message: string; subject: string; cc: string },
    files: docType[]
  ) {
    const { cc, message, subject, to } = data;
    const documentList = files && files.map((file: any) => file.id);
    const emailSendingRequestDTO = [
      {
        to,
        cc,
        subject,
        message,
        documentList,
      },
    ];
    const request = new EmailDocumentsRequest();
    request.html = true;
    request.emailSendingRequestDTO = emailSendingRequestDTO;
    const response = await documentService.emailDocuments(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async deleteDocuments(ids: string[]) {
    const request = new DeleteDocumentsRequest();
    request.documentIds = ids;
    const response = await documentService.deleteDocuments(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static getDocumentFilters(filters: any) {
    const newFilters = { ...filters };
    if (filters.documentTypeIds) {
      newFilters.documentTypeIds = filters.documentTypeIds.map((type: any) => {
        if (!Array.isArray(type) && typeof type === 'object') {
          return type.id;
        }
        return type;
      });
    }
    if (filters.entityTypes && !filters.entities) {
      newFilters.objectTypesAndIds = `${filters.entityTypes?.key}:`;
    }
    if (filters.entities && filters.entityTypes) {
      const ids = filters.entities.map((e) => e.id).join(',');
      newFilters.objectTypesAndIds = `${filters.entityTypes?.key}:${[ids]}`;
    }
    if (filters.ownerIds) {
      newFilters.ownerIds = filters.ownerIds.map((user) => user.id);
    }
    if (filters.permissionType) {
      newFilters.permissionType = filters.permissionType.value;
    }
    if (filters.range) {
      newFilters.startDate = filters?.range?.dateRange?.[0];
      newFilters.endDate = filters?.range?.dateRange?.[1];
    }
    return newFilters;
  }

  static async getDocuments(filters: ViewMetaData) {
    const requestParams = this.getDocumentFilters(filters);
    const request = new GetDocumentsListRequest();
    request.documentTypeIds = requestParams.documentTypeIds as string[];
    request.objectTypesAndIds = requestParams.objectTypesAndIds as string;
    request.ownerIds = requestParams.ownerIds as string[];
    request.permissionType = requestParams.permissionType as string;
    request.startDate = requestParams.startDate as string;
    request.endDate = requestParams.endDate as string;
    request.pageNumber = requestParams.pageNumber;
    request.pageSize = requestParams.pageSize;
    request.terminalIds = requestParams.terminalIds;
    const response = await documentService.getDocumentsList(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async getEntityAssociatedItems(entityId: string | null) {
    const request = new AttachedEntitiesRequest({ entityId });
    const response = await documentService.getAssociatedWith(request, 'DRIVER');
    if (!(response instanceof ServiceError)) {
      return response.content;
    }
  }

  static async getAssociatedWith(
    pageNumber: number,
    searchText: string | undefined,
    entity: string,
    terminalIds: string[],
    getFullData = false
  ) {
    if (!entity) return [];
    const query: any = { pageNumber };
    if (searchText) {
      query.searchText = searchText;
    }
    if (terminalIds) {
      query.terminalIds = terminalIds;
    }
    const request = new AttachedEntitiesRequest(query);
    if (entity === 'TRACTOR' || entity === 'TRAILER') {
      request.excludeStatusList = ['INACTIVE'];
    }
    if (entity === 'DRIVER') {
      request.excludeStatuses = [0];
    }
    if (entity === CARRIER_DOCUMENT_TYPE) {
      request.statusFilter = [ECarrierStatus.ACTIVE];
    }
    const response = await documentService.getAssociatedWith(request, entity);
    if (response instanceof ServiceError) {
      return [];
    }
    if (getFullData) {
      return response;
    }
    response.content = response.content.map((associatedItem) => ({
      id: associatedItem.id,
      title: associatedItem.name.toString(),
    }));
    return response;
  }

  static async createDocument(data: any) {
    if (!data.state) {
      return;
    }
    if (data.state === 'certification') {
      const latestDocRequest = new CreateLatestCertificationRequest();
      latestDocRequest.certification = data.certification;
      latestDocRequest.certification.notes = data.notes;
      await documentService.createLatestCertification(latestDocRequest);
    } else {
      const latestDocRequest = new CreateLatestDriverLicenseRequest();
      latestDocRequest.driverLicense = getDriverLicenseRequestParams(data);
      await documentService.createLatestDriverLicense(latestDocRequest);
    }
  }

  static async downloadAsZip(docIds: string[]) {
    const result = await this.downloadDocuments(docIds);
    if (result) {
      const zip = new JSZip();
      result.forEach((doc) => {
        const { file } = convertData(doc);
        zip.file(doc.fileName || '', file);
      });
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, 'documents.zip');
    }
  }

  static async downloadMultipleDataAsZip(data: docType[]) {
    const zip = new JSZip();
    data.forEach((doc) => {
      const { file } = convertData(doc);
      zip.file(doc.fileName || '', file);
    });
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'documents.zip');
  }

  static async bulkDownload(docIds: string[]) {
    const result = await this.downloadDocuments(docIds);
    if (result) {
      result.forEach((doc) => downloadFile(doc));
    }
  }

  static async editDocumentType(
    id: string,
    documentTypeId: string,
    documentTypeCode: string,
    oldDocumentTypeId: string,
    oldEntityType: { key: string },
    editDocumentTypeCb: (
      oldId: string,
      newId: string,
      oldEntityType: string
    ) => void,
    updateTable: (id: string, value: string | object, name: string) => void,
    setSelectedItem: (data: DocumentItemType) => void
  ) {
    const request = new EditDocumentRequest();
    request.documentTypeId = documentTypeId;
    request.documentType = documentTypeCode;
    request.id = id;
    const [res] = await Promise.all([
      await documentService.editDocument(request),
      await updateTable(id, documentTypeId, 'documentTypeId'),
      await editDocumentTypeCb?.(
        oldDocumentTypeId,
        documentTypeId,
        oldEntityType?.key
      ),
    ]);
    if (res) {
      setSelectedItem(
        (item) =>
          ({
            ...item,
            documentTypeId: documentTypeId,
          } as DocumentItemType)
      );
    }
  }

  static async editDocument(
    id: string,
    value: string,
    name: string,
    data: DocumentItemType,
    editCb: (data: DocumentItemType) => void
  ) {
    const request = new EditDocumentRequest();
    request.id = id;
    if (data[name] === value) {
      return;
    }
    if (value) {
      request[name] = value;
    } else {
      request.fieldsToBeSetNull = [name];
    }
    const res = await documentService.editDocument(request);
    if (res) {
      editCb((item) => ({
        ...item,
        [name]: res[name],
      }));
    }
  }

  static async editTerminal(
    id: string,
    terminalId: string | undefined,
    updateTable: (id: string, value: string | object, name: string) => void,
    setSelectedItem: (data: DocumentItemType) => void,
    editTerminalCb?: (rowId: string) => void
  ) {
    const request = new EditDocumentRequest();
    request.fieldsToBeSetNull = ['attachedEntities'];
    request.id = id;
    if (terminalId) {
      request.terminalId = terminalId;
    } else {
      request.fieldsToBeSetNull.push('terminalId');
    }
    const [res] = await Promise.all([
      await documentService.editDocument(request),
      await updateTable(id, terminalId || '', 'terminalId'),
      await updateTable(id, [], 'attachedEntities'),
      await editTerminalCb?.(id),
    ]);
    if (res) {
      setSelectedItem(
        (item) =>
          ({
            ...item,
            terminalId,
            attachedEntities: res.attachedEntities,
          } as DocumentItemType)
      );
    }
  }

  static async editEntityType(
    id: string,
    data: { key: string },
    entityTypes: { key: string },
    editAssociatedTypeCb: (oldType: string, newType: string) => void,
    updateTable: (id: string, value: string | object, name: string) => void,
    setSelectedItem: (data: DocumentItemType) => void
  ) {
    const request = new EditDocumentRequest();
    request.id = id;
    if (!data) {
      request.fieldsToBeSetNull = ['attachedEntities'];
    } else {
      request.attachedEntities = [{ type: data.key, properties: [] }];
    }
    const [res] = await Promise.all([
      await documentService.editDocument(request),
      await updateTable(id, [], 'attachedEntities'),
      await editAssociatedTypeCb(entityTypes?.key || '', data?.key || ''),
    ]);
    if (res) {
      setSelectedItem(
        (item) =>
          ({
            ...item,
            attachedEntities: res.attachedEntities,
          } as DocumentItemType)
      );
    }
  }

  static async editAssociatedItem(
    id: string,
    data: { id: string },
    attachedEntities: { id: string },
    entityTypes: { key: string },
    editAssociatedItemCb: (
      oldValue: { id: string },
      newType: { id: string },
      id: string,
      entityTypeKey: string,
      existingTypeKey: boolean
    ) => void,
    updateTable: (id: string, value: string | object, name: string) => void,
    setSelectedItem: (data: DocumentItemType) => void,
    existingTypekey: string
  ) {
    const request = new EditDocumentRequest();
    request.id = id;

    if (!data) {
      request.fieldsToBeSetNull = ['attachedEntities'];
    } else {
      request.attachedEntities = [
        { type: entityTypes.key, properties: [data] },
      ];
    }
    const [res] = await Promise.all([
      await documentService.editDocument(request),
      await updateTable(
        id,
        [{ type: data ? entityTypes.key : '', properties: [data || ''] }],
        'attachedEntities'
      ),
      await editAssociatedItemCb(
        attachedEntities,
        data,
        id,
        entityTypes.key,
        !!existingTypekey
      ),
    ]);
    if (res) {
      setSelectedItem(
        (item) =>
          ({
            ...item,
            attachedEntities: res.attachedEntities,
          } as DocumentItemType)
      );
    }
  }
}

export default DocumentActions;
