import { QueryParams } from '../../commonMixed';
import { EquipmentType } from '../../../../views/maintenanceRecords/constants/constants';

export class GetMaintenanceEquipmentByOrganizationIdRequest extends QueryParams {
  equipmentType?: EquipmentType;
  pageNumber?: number;
  pageSize?: number;
  nameFilter?: string;
  constructor({
    equipmentType,
    pageNumber,
    pageSize,
    nameFilter,
  }: {
    equipmentType?: EquipmentType;
    pageSize?: number;
    pageNumber?: number;
    nameFilter?: string;
  }) {
    super();
    this.equipmentType = equipmentType;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.nameFilter = nameFilter;
  }
}
