import { MarkersData } from '../../common/Ui/Maps/types';
import {
  CommonHomeAPIRes,
  FinanceDataRes,
  GetHomeFinance,
  GetMaps,
  GetSafetyDataDTO,
} from '../../models';
import { Service } from './Service';
import { ServiceError } from './helperTypes';

export abstract class IHomeService extends Service {
  abstract getFinanceData(
    requestData: GetHomeFinance
  ): Promise<FinanceDataRes | null | ServiceError>;

  abstract getSafetyData(
    requestData: GetSafetyDataDTO
  ): Promise<FinanceDataRes | null | ServiceError>;

  abstract getTractorAvailability(
    requestData: GetSafetyDataDTO
  ): Promise<FinanceDataRes | null | ServiceError>;

  abstract getCommonHomeData(
    requestData: GetSafetyDataDTO,
    invoicesOperation: string
  ): Promise<CommonHomeAPIRes | null | ServiceError>;

  abstract getMapsData(
    requestData: GetMaps
  ): Promise<MarkersData | null | ServiceError>;
}
