import {
  StyledFormTitle,
  StyledLoginAuthFromWrapper,
  getRegistrationStyles,
} from './styles';
import { Box, List, ListItem, ThemeProvider, Typography } from '@mui/material';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import {
  RegistrationVerificationModel,
  regitrationPageStyle,
  regitrationPageText,
} from './RegistrationPageUtils';
import { subscriptionService } from '../../api';
import { Stack } from '@mui/system';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { AuthIntroduction } from './components/AuthIntroduction';
import { EPartner } from './constant';
const RegistrationVerificationPage = () => {
  const location = useLocation();
  const getVerificationEmail = async () => {
    const reuqest = new RegistrationVerificationModel();
    reuqest.email = location?.state.email;
    reuqest.dot = location?.state.dot;
    await subscriptionService.getVerificationEmail(reuqest);
  };

  const { themeLogin } = useThemeResponsive();
  const [searchParams] = useSearchParams();

  const isExistPartnerUser: boolean = Object.values(EPartner).includes(
    searchParams.get('partner')
  );

  const styles = getRegistrationStyles({
    theme: themeLogin,
    isExistPartnerUser: isExistPartnerUser,
  });
  return (
    <ThemeProvider theme={themeLogin}>
      <>
        <Box className="Auth-root" sx={styles.authRoot}>
          <Box className="Auth-backgroundImage" sx={styles.authBackgroundImage}>
            <Stack className="Auth-content" spacing={0} sx={styles.authContent}>
              <AuthIntroduction />

              <Box className="formWrapper" sx={styles.formWrapper}>
                <Box sx={{ height: '100%' }}>
                  <Box
                    className="formWrapperContent"
                    sx={styles.formWrapperContent}
                  >
                    <StyledLoginAuthFromWrapper>
                      <Box sx={regitrationPageStyle.verificationStyle}>
                        <Box
                          sx={{
                            height: '90%',
                          }}
                        >
                          <Box sx={{}}>
                            <StyledFormTitle>
                              {regitrationPageText.CompleteVerification}
                            </StyledFormTitle>
                          </Box>
                          <Box>
                            <Typography sx={{ color: 'text.primary' }}>
                              {regitrationPageText.CompleteVerification}
                            </Typography>

                            <List
                              sx={regitrationPageStyle.verificationListStyle}
                            >
                              <ListItem
                                sx={
                                  regitrationPageStyle.verificationListItemStyle
                                }
                              >
                                {regitrationPageText.option1}
                              </ListItem>

                              <ListItem
                                sx={
                                  regitrationPageStyle.verificationListItemStyle
                                }
                              >
                                {regitrationPageText.option2}
                              </ListItem>
                            </List>
                          </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                          <ButtonImproved
                            onClick={getVerificationEmail}
                            label="Resend Email"
                            size="large"
                            type="submit"
                            styleProps={
                              regitrationPageStyle.verificationSendStyle
                            }
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                    </StyledLoginAuthFromWrapper>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </>
    </ThemeProvider>
  );
};

export default RegistrationVerificationPage;
