import { useState } from 'react';
import { useTheme } from '@mui/material';
import {
  DriverTooltip,
  IDriverTooltip,
  TooltipActionType,
  TooltipFooterContentType,
} from '@optym/gantt';
import {
  IGanttSubTitle,
  ScheduleDriverType,
  SecondaryDetailsPanelType,
} from '../../../../types/DispatchTypes';
import { ESecondaryDetailsPanelType } from '../../../../constants/gantt/gantt.const';
import { MapsProps } from '../types';

interface IMapsDriverTooltip extends IDriverTooltip {
  onFixDriverHosLocation?: MapsProps['onFixDriverHosLocation'];
  actionUsingCallback?: boolean;
  onAction?: (data: SecondaryDetailsPanelType) => void;
}

const MapsDriverToolTip = (props: IMapsDriverTooltip) => {
  const theme = useTheme();

  const handleSubtitlesClick = (data: IGanttSubTitle) => {
    props?.onAction?.({
      type: data.type as ESecondaryDetailsPanelType,
      id: data?.value?.id!,
      driverId: data?.driverId ?? null,
    });
  };

  const handleActionClick = (action: TooltipActionType) => {
    console.log(action);
    props?.onAction?.({
      type: ESecondaryDetailsPanelType.DISPATCH,
      id: action?.id!,
    });
  };

  return (
    <DriverTooltip
      {...props}
      disableCloseAction={true}
      containerStyles={{
        border: `1px solid ${theme?.palette?.primary?.main}`,
      }}
      onSubTitleClick={handleSubtitlesClick as any}
      onActionClick={handleActionClick}
      onWarningAction={(data: TooltipFooterContentType) =>
        props?.onFixDriverHosLocation?.(data?.data as ScheduleDriverType)
      }
    />
  );
};

export default MapsDriverToolTip;
