import {
  CheckedIcon,
  InvoicedIcon,
  PaidIcon,
} from '../../../../ui-kit/components/Assets';
import { RelativeDateRangeUtils } from '../../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { ERelativeDateRangeKey } from '../../../../ui-kit/components/RelativeDateRange';
import { getGridCompanyTimezoneSettings } from '../../../../utils/Timezone.utils';

export const invoiceColumnsInfo = {
  seqNumber: true,
  loadSeqNumber: true,
  Loadstatus: true,
  customer: true,
  status: true,
  tags: true,
  driverName: true,
  factoringCompany: true,
  factoringAmount: true,
  total: true,
  amountDue: true,
  factoringStatus: true,
  origin: true,
  destination: true,
  missingDocuments: true,
  loadReferenceId: true,
  terminal: true,
  loadStatus: true,
  completeDate: true,
  updateDate: true,
  invoicedOn: true,
  paidOn: true,
  notes: true,
  invoiceType: true,
  onHoldState: false,
};

export const invoiceFilters = {
  fetchBySeqNumbers: [],
  loadSeqNumbers: [],
  customerNameFilter: [],
  driverNameFilter: [],
  factoringNameFilter: [],
  loadStatusList: [],
  referenceIds: [],
  originCityStateList: [],
  destinationCityStateList: [],
  invoiceSeqNumbers: [],
  terminalIds: [],
  completeDate: null,
  invoicedOn: null,
  paidOn: null,
  updatedOn: null,
  documentNeeded: null,
  invoiceTypeList: [],
  calculateDetention: null,
  isOverdue: null,
  emailIsSent: null,
  tagIds: [],
};
const timezone = getGridCompanyTimezoneSettings().timezone;

export const paidInvoiceFilters = {
  ...invoiceFilters,
  // invoicedOn: RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
  //   ERelativeDateRangeKey.PeriodTimeLast3Months,
  //   timezone
  // ),
};
// keys are strip ids. it is very important never modify this map
export const invoiceFiltersMap: { [key: string]: any } = {
  completed: invoiceFilters,
  ready: invoiceFilters,
  invoiced: invoiceFilters,
  paid: invoiceFilters,
};

export const DEFAULT_INVOICE_SORT_NAME = '+invoicedOn';

export const invoiceCompletedView = {
  id: 'completed',
  shared: true,
  parentId: null,
  name: 'Invoiceable Loads',
  entity: 'invoice',
  icon: <CheckedIcon />,
  active: true,
  default: true,
  columnFilters: invoiceColumnsInfo,
  metaData: invoiceFilters,
};

export const invoiceReadyView = {
  id: 'ready',
  shared: true,
  parentId: null,
  name: 'Ready to Invoice',
  entity: 'invoice',
  icon: <CheckedIcon />,
  active: true,
  default: true,
  columnFilters: invoiceColumnsInfo,
  metaData: invoiceFilters,
};

export const invoicedView = {
  id: 'invoiced',
  shared: true,
  parentId: null,
  name: 'Invoiced',
  entity: 'invoice',
  icon: <InvoicedIcon />,
  active: true,
  default: true,
  columnFilters: {
    ...invoiceColumnsInfo,
    onHoldState: true,
  },
  metaData: invoiceFilters,
};

export const invoicePaidView = {
  id: 'paid',
  shared: true,
  parentId: null,
  name: 'Paid',
  entity: 'invoice',
  icon: <PaidIcon />,
  active: true,
  default: true,
  columnFilters: {
    ...invoiceColumnsInfo,
    onHoldState: true,
  },
  metaData: invoiceFilters,
};

export const invoiceFormDefaultValuesMap = {
  completed: invoiceCompletedView,
  ready: invoiceReadyView,
  invoiced: invoicedView,
  paid: invoicePaidView,
};
export const invoiceDefaultViews = [
  {
    ...invoiceCompletedView,
  },
  {
    ...invoiceReadyView,
  },
  {
    ...invoicedView,
  },
  {
    ...invoicePaidView,
  },
];
