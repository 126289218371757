import { Box } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { IAutocompleteOption } from '../../../../../../views/trips/constants/types';
import Switcher from '../../../../Switcher';

interface GridSwitchProps {
  id?: string;
  label?: string;
  name: string;
  value?: IAutocompleteOption;
  onChange?: (options: IAutocompleteOption, name?: string) => void;
  isFormControl?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  fieldName?: string;
  fieldValue?: any;
  customStyles?: SxProps;
  options: IAutocompleteOption[];
}

export const GridSwitch = ({
  name,
  label,
  isFormControl,
  value = undefined,
  onChange,
  fieldName = 'value',
  fieldValue = 'key',
  customStyles = {},
  options,
}: GridSwitchProps) => {
  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const option = options.find((option) => !!option?.[fieldValue] === checked);
    option && onChange?.(option);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '32px',
        ...customStyles,
      }}
    >
      <Switcher
        checked={!!value?.[fieldValue]}
        name={name}
        onChange={onChangeHandler}
        labelColor="text.primary"
        label={label}
      />
    </Box>
  );
};
