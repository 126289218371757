import * as xDataGridPro from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { EventHoldLoadActionData } from '../../EventEmitter/Events/EventHoldLoadAction';
import { invoiceService, paymentManagementService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import * as Requests from '../../models/DTOs/Invoice/Requests';
import { PreviewInvoiceRequest } from '../../models/DTOs/Invoice/Requests';
import { updatePaymentToInvoiceMappingtParams } from '../../models/DTOs/Payment/DTOs';
import InvoiceActions from '../../subPages/invoices/InvoiceActions';
import { InvoiceModel } from '../../subPages/invoices/models/InvoiceModel';
import {
  getSelectedInvoices,
  getWarnings,
} from '../../subPages/invoices/utils';
import * as types from '../../types';
import { ViewMetaData } from '../../types';
import { invoiceCompletedView } from '../../views/finance/invoices/constants';
import { E3DotMenuType } from '../../views/myLoads/components/LoadDetailDialog/types';
import { TSetData, setData } from '../utils';

const component = 'Invoices';
const dictionary = 'invoice';

class InvoiceStore {
  @observable component: string = component;
  @observable dictionary: string = dictionary;
  @observable currentView: types.View = invoiceCompletedView;
  @observable addNewClicked = false;
  @observable showDetention = false;
  @observable totalRows = 0;
  @observable showEmptyPage = false;
  @observable isInvoiceCreate = false;
  @observable isPaymentUpdated = false;

  @observable tableList = [];
  //   @observable settingsPagination = defaultSettingsPagination;
  @observable totalTableItems = 0;
  @observable filters: types.ViewMetaData = {};
  @observable selectedRowId: string | null = null;
  @observable selectedItem: InvoiceModel | null = null;
  @observable invoiceCount: number | undefined = undefined;
  @observable pageIsLoading = false;
  @observable openSecondaryPanel: {
    id: string;
    open: boolean;
  } = {
    id: '',
    open: false,
  };
  @observable paymentPanel: {
    id: string | null;
    open: boolean;
  } | null = {
    id: '',
    open: false,
  };
  @observable isQuickBook = false;
  @observable isLoading = false;
  @observable tableData: InvoiceModel[] = [];
  @observable selectedInvoices: InvoiceModel[] = [];
  @observable selectionModel: xDataGridPro.GridSelectionModel = [];
  @observable warnings: Requests.InvoiceWarningType = {};
  @observable invoiceTableTotals = {
    totalAmount: 0,
    totalDueAmount: 0,
    totalFactoringCharge: 0,
  };
  @observable selectedPayment: InvoiceModel | null = null;
  @observable isLastPage = false;
  constructor() {
    makeObservable(this);
  }
  @action
  setAddNewClicked = (newValue: TSetData<boolean>) => {
    this.addNewClicked = setData(newValue, this.addNewClicked);
  };
  setAddInvoiceRecord = (newValue: boolean) => {
    if (newValue) {
      this.selectedItem = null;
    }
    this.isInvoiceCreate = newValue;
  };

  @action
  getInvoicesList = async (requestParams: ViewMetaData, scrolling = false) => {
    this.isLoading = true;
    this.isLastPage = false;
    const result = await InvoiceActions.getInvoiceList(requestParams);
    if (result) {
      const invoiceIds: string[] = result.content.map((invoice) => invoice.id);

      const { totalElements, dataExists } = result;
      this.totalRows = totalElements!;
      this.showEmptyPage = dataExists!;
      this.showDetention = result.calculateDetention ?? false;
      this.isLastPage = result?.last;
      this.getInvoicesWithWarnings(invoiceIds, this.showDetention);
      if (scrolling) {
        const newLoads = [...this.tableData, ...result.content];
        this.tableData = newLoads || [];
      } else {
        this.tableData = result?.content || [];
      }
      this.isLoading = false;
    }
    if (!scrolling) {
      const totals = await InvoiceActions.getInvoiceTotals(requestParams);
      if (totals) {
        this.invoiceTableTotals = totals;
      }
    }
  };
  @action
  getInvoicesWithWarnings = async (data: string[], showDetention = false) => {
    const warnings = await InvoiceActions.getWarnings(data);
    if (warnings) {
      this.warnings = { ...this.warnings, ...warnings };
    }
    if (!warnings) {
      return null;
    }
    const updatedData = this.tableData.map((invoice) => {
      const warningObj = warnings[invoice.id];
      if (!warningObj) {
        return invoice;
      }
      return {
        ...invoice,
        assignedDocuments: warningObj.assignedDocuments || [],
        warnings: getWarnings(warningObj.warnings, showDetention),
        loadId: warningObj.loadId || '',
        missingDocuments: warningObj.missingDocuments || [],
        showDetention,
      };
    });
    this.tableData = updatedData;
  };

  @action
  setTableTotals = (newValue: any) => {
    this.invoiceTableTotals = newValue;
  };

  @action
  updateSpecificRecord = async (record: InvoiceModel) => {
    const list = this.tableData.filter((item: InvoiceModel) => {
      if (record.status === 'LOAD_COMPLETED' || record.status === 'CANCELLED') {
        if (item.loadId === record.loadId) {
          return record;
        } else {
          return item;
        }
      } else {
        if (item.invoiceId === record.invoiceId) {
          return record;
        } else {
          return item;
        }
      }
    });
    this.tableData = list;
  };

  @action
  handleHoldOrReleaseInvoiceLocally = async ({
    invoiceIds,
    loadIds,
    type,
  }: EventHoldLoadActionData) => {
    const newTableList: any = this.tableData.concat();
    newTableList.forEach((item: InvoiceModel) => {
      if (invoiceIds.includes(item.id) || loadIds.includes(item.loadId)) {
        item.onHold = type === E3DotMenuType.MARK_AS_HOLD;
      }
    });
    this.tableData = newTableList;
  };

  @action
  setCurrentView = (newValue: TSetData<types.View>) => {
    this.tableData = [];
    this.totalRows = 0;
    this.invoiceTableTotals = {
      totalAmount: 0,
      totalDueAmount: 0,
      totalFactoringCharge: 0,
    };
    this.currentView = setData(newValue, this.currentView);
  };
  @action
  setSelectedItem = (newValue: InvoiceModel | null) => {
    this.selectedItem = newValue;
  };

  @action
  removeRecord = async (invoiceId: string) => {
    this.selectedItem = null;
    const list = this.tableData.filter(
      (obj: InvoiceModel) => obj.id !== invoiceId
    );
    this.tableData = list;
  };
  @action
  changeSelectionModel = (newValue: xDataGridPro.GridSelectionModel) => {
    this.selectionModel = newValue;
    const selectedInvoices = getSelectedInvoices(this.tableData, newValue);
    this.selectedInvoices = selectedInvoices;
  };
  @action
  setSelectedInvoices = (newValue: InvoiceModel[]) => {
    this.selectedInvoices = newValue;
  };
  @action
  setTableData = (newValue: InvoiceModel[]) => {
    this.tableData = newValue;
  };
  @action
  setWarnings = (newValue: Requests.InvoiceWarningType) => {
    this.warnings = newValue;
  };
  @action
  getLoadsCount = async (): Promise<void> => {};

  @action
  setFilters = (newValue: TSetData<types.ViewMetaData>) => {
    this.filters = setData(newValue, this.filters);
  };

  @action
  setPageIsLoading = (pageIsLoading: boolean) => {};
  @action
  setSelectQBClicked = (pageIsLoading: boolean) => {
    this.isQuickBook = pageIsLoading;
  };
  @action
  setOpenSecondaryPanel = (status: { id: string; open: boolean }) => {
    this.openSecondaryPanel = status;
  };
  @action
  setPaymentPanel = (status: { id: string | null; open: boolean } | null) => {
    this.paymentPanel = status;
  };

  @action
  addPaymentToInvoice = (data: any) => {};

  @action
  updatePaymentToInvoice = (data: any) => {};
  @action
  deletePaymentToInvoice = async (data: any) => {
    if (!(data.paymentId && data.invoiceId)) return;

    const updatedPayment =
      await paymentManagementService.updatePaymentToInvoiceMapping(
        new updatePaymentToInvoiceMappingtParams({
          paymentId: data.paymentId,
          invoiceId: data.invoiceId,
        })
      );
    if (updatedPayment?.status === 200) {
      this.isPaymentUpdated = true;
      if (window.location.pathname === '/finance/invoices') {
        this.getInvoiceById(this.selectedItem?.id).then((response) => {
          const updatedList = invoiceTablePaymentUpdate(
            this.currentView.id,
            response,
            this.tableData
          );
          this.tableData = cloneDeep(updatedList);
        });
      }
    }
  };
  @action
  getInvoiceById = async (id: string) => {
    const request = new PreviewInvoiceRequest();
    request.id = id;
    const response = await invoiceService.previewInvoice(request);
    if (response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  };
  @action
  setSelectedPayment = (newValue: InvoiceModel) => {
    this.selectedPayment = newValue;
  };
  @action
  setPaymentUpdated = (newValue: boolean) => {
    this.isPaymentUpdated = newValue;
    if (newValue && window.location.pathname === '/finance/invoices') {
      this.getInvoiceById(this.selectedItem?.id).then((response) => {
        const updatedList = invoiceTablePaymentUpdate(
          this.currentView.id,
          response,
          this.tableData
        );
        this.tableData = cloneDeep(updatedList);
      });
    }
  };
}

export function createInvoiceStore() {
  return new InvoiceStore();
}
const statusTabViewList = {
  ['LOAD_COMPLETED']: 'completed',
  ['CANCELLED']: 'completed',
  ['INVOICED']: 'invoiced',
  ['PAID']: 'paid',
};

export const invoiceTablePaymentUpdate = (
  view: string,
  record: InvoiceModel,
  invoiceList: InvoiceModel[]
) => {
  let tableList = invoiceList;
  record.hasPayment = !!record?.receivedPayments?.length;
  if (statusTabViewList[record.status] === view) {
    const indexes = invoiceList
      .map((item, index) => (item.id === record.invoiceId ? index : null))
      .filter((index) => index !== null);
    if (indexes?.length > 0) {
      tableList[indexes[0]] = new InvoiceModel(record);
    } else {
      tableList.unshift(new InvoiceModel(record));
    }
  } else {
    tableList = invoiceList.filter((item) => item.id != record.invoiceId);
  }
  return tableList;
};
