import { LoadConstants } from '../../../../../locales/en/allLoads/loads/index';
import { CommonConstants } from '../../../../../locales/en/common/index';
import { emailValidation } from '../../../../../views/settings/terminals/constants/constants';
import {
  AddData,
  AlertTypesSingleData,
  ContactListTypes,
  ErrorTypes,
  ListTypes,
} from './interface';

export const titleText = LoadConstants.loadsTrackingAddNewTracker;
export const dialoagButtonText = LoadConstants.loadsTrackingShareTracking;
export const buttonNames = {
  cancel: CommonConstants.cancel,
  add: LoadConstants.loadsTrackingAddTracker,
};

export const switchText = {
  title: LoadConstants.loadsTrackingAllNotifications,
  subTitle: LoadConstants.loadTsrackingSendAllNotifications,
};

export const addTrackerDefaultData = {
  selectAll: false,
  selectedList: [],
};

export const visibilityText = 'Visibility Provider';

export const toggleButtonValues = {
  variant: 'outlined',
  enabledSource: 'MUIcons',
  enabledIconName: 'Check',
  diabledSource: 'MUIcons',
  disabledIconName: 'CableOutlined',
};

export const deletePopOver = {
  title: CommonConstants.deleteQuestionMark,
  body: CommonConstants.deleteForSure,
};

export const phoneValidator = new RegExp('^[0-9]{10}$');

export const performValidation = async (values: AddData) => {
  const tempError: ErrorTypes = {};
  if (values.name != '' && !emailValidation.test(values.name))
    tempError.name = CommonConstants.nameError;
  if (!values.email && !values.phone) {
    tempError.customValidation = true;
  } else {
    if (values?.email && !emailValidation.test(values.email)) {
      tempError.email = CommonConstants.emailisnotvalid;
    }

    if (values?.phone && !phoneValidator.test(values.phone)) {
      tempError.phone = CommonConstants.phoneError;
    }
  }

  tempError.name = await requiredCheck(values.name ? values.name : '');
  tempError.selectedList = await requiredCheck(
    values.selectedList ? values.selectedList : ''
  );
  const errRes: ErrorTypes = await filterValidation(tempError);
  return errRes;
};

export const requiredCheck = async (data: any) =>
  data.length === 0 ? CommonConstants.required : null;

export const filterValidation = async (data: ErrorTypes) =>
  Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));

export const makeCustomerAlertFlag = (selectedList: string[]) => {
  let temp = {};
  selectedList.map((item: string) => (temp = { ...temp, [item]: true }));
  return temp;
};

export const checkAll = (alertTypes: AlertTypesSingleData[]) =>
  alertTypes.map((item: AlertTypesSingleData) => item.key);

export const makeSelectedList = (item: any) => {
  const temp = { ...item };
  const selectedList: string[] = [];
  Object.keys(temp).map((key) => {
    if (key !== 'custAlertId' && key !== 'id' && temp[key])
      selectedList.push(key);
  });
  return selectedList;
};

export const createSelectedList = async (
  data: any,
  alertsType: AlertTypesSingleData[],
  contactList: ContactListTypes[]
) => {
  let tempList: ListTypes[] = [];
  data.map((item: any) => {
    const selectedList = makeSelectedList(item.customerAlertFlag);
    tempList = [
      ...tempList,
      {
        ...item,
        alertsType: alertsType,
        selectedList: selectedList,
        contactList: contactList,
        selectAll: alertsType.length === selectedList.length,
      },
    ];
  });
  return tempList;
};

export const makeContactList = async (list: ContactListTypes[]) => {
  let tempList: ContactListTypes[] = [];
  list.map((item: ContactListTypes) => {
    tempList = [
      ...tempList,
      {
        ...item,
        key: item.id,
        label: item.fullName,
        phone: item.phoneData ? item.phoneData.phone : '',
      },
    ];
  });
  return tempList;
};

export const getDefault = (
  arr: Array<object>,
  findKey: Array<string> | string | null | undefined
) => (findKey === null ? [] : arr.find((obj: any) => obj.key === findKey));

export const editCommunicationNotifications = [
  { label: LoadConstants.loadsTrackingArrivedAtPickup },
  { label: LoadConstants.loadsTrackingEnRouteToDelivery },
  { label: LoadConstants.loadsTrackingArrivedAtPickup },
  { label: LoadConstants.loadsTrackingLoadingDoneAtPickupLocation },
  { label: LoadConstants.loadsTrackingUnloadingDoneAtDeliveryLocation },
];
export const editCommunicationNotificationsTurvo = [
  { label: LoadConstants.loadsTrackingEnRouteToPickup },
  { label: LoadConstants.loadsTrackingArrivedAtPickup },
  { label: LoadConstants.loadsTrackingEnRouteToDelivery },
  { label: LoadConstants.loadsTrackingArrivedAtDelivery },
  { label: LoadConstants.loadsTrackingCompletionOfDelivery },
];
