import { ExpenceModalTranslations } from './modal';

export const ExpensesConstant = {
  importExpenses: 'Import Expenses',
  changeStatus: 'Change Status',
  deleteExpense: 'Delete Expense',
  newFuelExpense: 'New Fuel Expense',
  newOtherExpense: 'New Other Expense',
  addExpense: 'Add Expense',
  settlements: 'Settlements',
  transactionDetails: 'Transaction Details',
  companyCardCash: 'Company Card/Cash',
  nonCompanyCardCash: 'Non-Company Card/Cash',
  grossAmount: 'Gross Amount',
  discountAmount: 'Discount Amount',
  expenseAmount: 'Expense Amount',
  paidTo: 'Paid To',
  paidBy: 'Paid By',
  reference: 'Reference',
  expenseCategory: 'Expense Category',
  fuelCard: 'Fuel Card',
  fuelDetails: 'Fuel Details',
  fuelQuantity: 'Fuel Quantity (US Gal)',
  truckStop: 'Truck-stop',
  associations: 'Associations',
  uploadReceipt: 'Upload Receipt',
  needsReview: 'Needs Review',
  paymentStatus: 'Payment Status',
  deletePayment: 'Delete Payment',
  deletePaymentConfirmation: 'Are you sure you want to delete this Payment?',
  noExpenses: 'You have no Expense in LoadOps. Why don’t we change that?',
  noFuelExpense:
    'You have no Fuel Expense in LoadOps. Why don’t we change that?',
  noOtherExpense:
    'You have no Other Expense in LoadOps. Why don’t we change that?',
  regPlate: 'Reg. Plate',
  regExpiry: 'Reg. Expiry',
  lastLocation: 'Last Location',
  lastUpdatedAt: 'Last Updated At',
  locationSource: 'Location Source',
  ownershipType: 'Ownership Type',
  critical: 'Critical',
  length: 'Length (ft)',
  financeExpensesAllExpenses: 'All Expenses',
  financeExpensesFuel: 'Fuel',
  financeExpensesOther: 'Other',
  expenseExpDateRange: 'Exp. Date Range',
  expenseID: 'Expense ID',
  clickHereToUploadDocument: 'Click Here To Upload Document',
  ...ExpenceModalTranslations,
};
