import { ITractorInfoForDriverAssign } from '../../../../../../../models/DTOs/Dispatch/Dispatch';
import { Trip } from '../../../Models';
import AssignmentFormWrapper from '../../AssignmentFormWrapper';
import FormComponent from './TractorAssignmentForm';

export default function TractorAssignmentToDriver({
  trip,
  onClose,
  onAssign,
  apiAssignHandler,
}: {
  trip: Trip;
  onClose: () => void;
  onAssign?: (tractor: ITractorInfoForDriverAssign) => void;
  apiAssignHandler: (tractor: ITractorInfoForDriverAssign) => Promise<void>;
}): JSX.Element {
  const handleAssign = async ({
    tractor,
  }: {
    tractor: ITractorInfoForDriverAssign;
  }) => {
    if (tractor?.id) {
      if (apiAssignHandler) {
        await apiAssignHandler(tractor);
      }
      onAssign?.(tractor);
    }
    onClose();
  };

  return (
    <AssignmentFormWrapper
      onAction={handleAssign}
      onClose={onClose}
      data={trip.tractor}
      title="Assign Tractor"
      actionLabel="Assign"
    >
      <FormComponent trip={trip} />
    </AssignmentFormWrapper>
  );
}
