import moment from 'moment';
import { ICommodity, ICustomerContact } from '../../../../models';
import { LineItem } from '../../../../types';
import { initialState } from '../../../invoices/components/InvoiceDetailsSections/LoadInvoiceRate/defaultValue/data.config';
import { getCommodityTypes } from '../../LoadDetailsPanel/utils';
import {
  CustomerRequiredDocOptions,
  FLAT_RATE_KEY,
  PER_LOADED_MILE_KEY,
  PER_LOADED_WEIGHT_KEY,
} from '../constants/fieldOptions';
import { LOAD_TYPE_KEYS } from '../constants/fieldValues';
import { ReeferModeEnum } from '../schema/load';
import { StopTypeEnum } from '../schema/stops';
import { rateFormService } from '../services/rate.service';
import {
  AppointmentConfirmationEnum,
  AppointmentConfirmationType,
  CreateLocationResDTO,
  ILoadStop,
  LoadTemplateDetailsDTO,
} from '../types/requests';
import {
  ActivityType,
  ICommodities,
  ICustomerObject,
  ILoad,
  ILoadDetails,
  ILoadRateInvoice,
} from '../types/types';
import { formatTextToUpperCase } from '../utils';
import {
  CreateLoadFormContact,
  CreateLocationRequestDTO,
  LoadPriorityEnum,
  RelayStopRequestDTO,
  StopContactDTO,
  StopDropRequestDTO,
} from './create-load-request.dto';

export interface CreateLoadLocationResDTO {
  id?: string;
  organizationId?: string;
  axeleId?: string;
  locationName?: string;
  fullAddress: string;
  address?: string;
  streetAddress?: string;
  streetAddress2?: string;
  city?: string;
  zipcode?: string;
  state?: string;
  lat?: number;
  lng?: number;
  contacts?: ICustomerContact[];
  center?: {
    lat: number;
    lng: number;
  };
  geoCoordinates?: Array<{ coordinates: Array<number> }>;
  timezone?: string;
  notes?: string;
  avgHandlingTime?: number;
  recordDate?: string;
  country?: string;
}

export class CreateLoadContactResDTO {
  id?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  countryCode?: string;
  phone?: string;
  extension?: string;
}

type NumStringType = string | number | null;
export class TemplateLoadStopToLoadStopDTO implements ILoadStop {
  type?: ActivityType;
  estimatedActivityDuration?: number;
  relayDropOffEstimatedActivityDuration?: number;
  referenceNumber?: string;
  notes?: string;
  fixedAppointment?: boolean;
  appointmentStartDate?: string;
  appointmentEndDate?: string;
  appointmentStartTime?: string;
  appointmentEndTime?: string;
  relayDropOffAppointmentStartDate?: string;
  relayDropOffAppointmentStartTime?: string;
  relayDropOffAppointmentEndDate?: string;
  relayDropOffAppointmentEndTime?: string;
  isDriverAssistRequired?: boolean;
  isRelayDropOffDriverAssistRequired?: boolean;
  relayPickupRevenueShare?: NumStringType;
  relayDropOffRevenueShare?: NumStringType;
  appointmentConfirmation?: AppointmentConfirmationType;
  dropOffAppointmentConfirmation?: AppointmentConfirmationType;
  location: CreateLocationResDTO;
  contacts?: any[];
  noteDetails?: any;
  sequenceNumber?: number | null;
  stopCategory?: string | null;
  stopStatus?: string | null;
  timezone?: string | null;
  contact?: CreateLoadFormContact;
  isEndTimeVisible?: boolean;
  enableTimezone?: boolean;

  constructor(stop: StopDropRequestDTO) {
    this.type = stop?.activityType
      ? (stop?.activityType as ActivityType)
      : StopTypeEnum.TRIP_STOP;
    this.estimatedActivityDuration = stop?.estimatedActivityDuration;
    this.referenceNumber = stop?.refNumber;
    this.notes = stop?.notes;
    this.fixedAppointment = Boolean(stop?.fixedAppointment);
    this.appointmentStartDate = !!(
      stop?.appointmentStartDate && stop.location?.timezone
    )
      ? moment(stop?.appointmentStartDate)
          .tz(stop.location?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop.appointmentStartDate;
    this.appointmentStartTime =
      stop?.location?.timezone && stop?.appointmentStartDate
        ? moment(stop?.appointmentStartDate)
            .tz(stop.location?.timezone, false)
            .format('YYYY-MM-DDTHH:mm:ss')
        : this.appointmentStartDate;
    this.appointmentEndDate = !!(
      stop?.appointmentEndDate && stop.location?.timezone
    )
      ? moment(stop?.appointmentEndDate)
          .tz(stop.location?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop.appointmentEndDate;
    this.appointmentEndTime =
      stop?.location?.timezone && stop?.appointmentEndDate
        ? moment(stop?.appointmentEndDate)
            .tz(stop.location?.timezone, false)
            .format('YYYY-MM-DDTHH:mm:ss')
        : this.appointmentEndDate;
    this.isDriverAssistRequired = stop?.isDriverAssistRequired;
    this.appointmentConfirmation =
      (stop?.appointmentConfirmation as AppointmentConfirmationType) ??
      AppointmentConfirmationEnum.NO;
    this.location = formatTemplateResLocationDTO(stop?.location!);
    this.noteDetails = stop?.noteDetails;
    this.sequenceNumber = isNaN(Number(stop?.sequenceNumber))
      ? null
      : Number(stop?.sequenceNumber);
    this.stopStatus = stop?.stopStatus;
    this.timezone = stop?.timezone ?? stop?.location?.timezone;
    this.stopCategory = stop?.stopCategory;
    this.contacts = stop?.contacts;
    this.contact = {
      id: stop.contact?.id,
      contactName: formatStopContactName(stop?.contact!),
      countryCode: stop.contact?.countryCode,
      phone: stop.contact?.phone,
      extension: stop.contact?.extension,
      email: stop.contact?.email,
    };
    this.isEndTimeVisible = !stop?.fixedAppointment;
    this.enableTimezone = true;
  }
}

export const formatStopContactName = (
  contact: CreateLoadContactResDTO
): string => {
  if (!contact?.firstname && !contact?.lastname) return '';
  if (contact?.firstname && contact?.lastname)
    return [contact?.firstname, contact?.lastname]?.join(' ');
  return contact?.firstname! ?? contact?.lastname!;
};

export const formatTemplateResLocationDTO = (
  location: CreateLocationRequestDTO
): CreateLocationResDTO => {
  const locName = location?.locationName ?? location?.address;
  const isLocationCoordsPresent =
    (location?.lat && location?.lng) ||
    (location?.center?.lat && location?.center?.lng);
  return {
    ...location,
    locationId: location?.id,
    address: isLocationCoordsPresent ? location?.address : '',
    locationName: isLocationCoordsPresent ? locName : '',
    center: location?.center ?? { lat: location?.lat, lng: location?.lng },
    businessName: isLocationCoordsPresent ? location?.locationName : '',
  } as CreateLocationResDTO;
};

export class TemplateRelayStopToLoadRelayStopDTO {
  type?: ActivityType;
  estimatedActivityDuration?: number;
  relayDropOffEstimatedActivityDuration: number;
  referenceNumber?: string;
  notes?: string;
  fixedAppointment?: boolean;
  relayDropOffFixedAppointment?: boolean;
  appointmentStartDate?: string;
  appointmentEndDate?: string;
  appointmentStartTime?: string;
  appointmentEndTime?: string;
  relayDropOffAppointmentStartDate: string;
  relayDropOffAppointmentStartTime: string;
  relayDropOffAppointmentEndDate: string;
  relayDropOffAppointmentEndTime: string;
  isDriverAssistRequired?: boolean;
  isRelayDropOffDriverAssistRequired: boolean;
  relayPickupRevenueShare: string | number | null;
  relayDropOffRevenueShare: string | number | null;
  appointmentConfirmation?: AppointmentConfirmationType;
  dropOffAppointmentConfirmation: AppointmentConfirmationType;
  location: CreateLocationResDTO;
  contacts?: StopContactDTO[];
  noteDetails?: any;
  sequenceNumber?: number | null;
  stopCategory?: string | null;
  stopStatus?: string | null;
  timezone?: string | null;
  contact?: CreateLoadFormContact;
  isEndTimeVisible?: boolean;
  isRelayDropOffEndTimeVisible?: boolean;
  enableTimezone?: boolean;

  constructor(stop: RelayStopRequestDTO) {
    const isLocationCoordsPresent =
      (stop?.connectionPoint?.lat && stop?.connectionPoint?.lng) ||
      (stop?.connectionPoint?.center?.lat &&
        stop?.connectionPoint?.center?.lng);
    this.type = 'RELAY';
    this.estimatedActivityDuration =
      stop?.pickupConnectionDetails?.estimatedActivityDuration;
    this.relayDropOffEstimatedActivityDuration =
      stop.dropOffConnectionDetails?.estimatedActivityDuration!;
    // this.referenceNumber = stop?.refNumber!;
    this.notes = stop?.connectionPoint?.notes;
    this.fixedAppointment = Boolean(
      stop?.pickupConnectionDetails?.fixedAppointment
    );
    this.relayDropOffFixedAppointment = Boolean(
      stop.dropOffConnectionDetails?.fixedAppointment
    );

    //format dates
    this.appointmentStartDate = !!(
      stop?.pickupConnectionDetails?.appointmentStartDate &&
      stop.connectionPoint?.timezone
    )
      ? moment(stop?.pickupConnectionDetails?.appointmentStartDate)
          .tz(stop.connectionPoint?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop?.pickupConnectionDetails?.appointmentStartDate;
    this.appointmentEndDate = !!(
      stop?.pickupConnectionDetails?.appointmentEndDate &&
      stop.connectionPoint?.timezone
    )
      ? moment(stop?.pickupConnectionDetails?.appointmentEndDate)
          .tz(stop.connectionPoint?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop.pickupConnectionDetails?.appointmentEndDate;
    this.appointmentStartTime =
      stop.connectionPoint?.timezone &&
      stop?.pickupConnectionDetails?.appointmentStartDate
        ? moment(stop?.pickupConnectionDetails?.appointmentStartDate)
            .tz(stop.connectionPoint?.timezone, false)
            .format('YYYY-MM-DDTHH:mm:ss')
        : this.appointmentStartDate;
    this.appointmentEndDate =
      stop.connectionPoint?.timezone &&
      stop?.pickupConnectionDetails?.appointmentEndDate
        ? moment(stop?.pickupConnectionDetails?.appointmentEndDate)
            .tz(stop.connectionPoint?.timezone!, false)
            .format('YYYY-MM-DDTHH:mm:ss')
        : this.appointmentEndDate;

    //dropoff
    this.relayDropOffAppointmentStartDate = !!(
      stop?.dropOffConnectionDetails?.appointmentStartDate &&
      stop.connectionPoint?.timezone
    )
      ? moment(stop?.dropOffConnectionDetails?.appointmentStartDate)
          .tz(stop.connectionPoint?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop?.dropOffConnectionDetails?.appointmentStartDate!;
    this.relayDropOffAppointmentEndDate = !!(
      stop?.dropOffConnectionDetails?.appointmentEndDate &&
      stop.connectionPoint?.timezone
    )
      ? moment(stop?.dropOffConnectionDetails?.appointmentEndDate)
          .tz(stop.connectionPoint?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop.dropOffConnectionDetails?.appointmentEndDate!;
    this.relayDropOffAppointmentStartTime =
      stop?.connectionPoint?.location?.timezone &&
      stop?.dropOffConnectionDetails?.appointmentStartDate
        ? moment(stop?.dropOffConnectionDetails?.appointmentStartDate)
            .tz(stop.connectionPoint?.timezone!, false)
            .format('YYYY-MM-DDTHH:mm:ss')
        : this.relayDropOffAppointmentStartDate;
    this.relayDropOffAppointmentEndTime =
      stop?.connectionPoint?.location?.timezone &&
      stop?.dropOffConnectionDetails?.appointmentEndDate
        ? moment(stop?.dropOffConnectionDetails?.appointmentEndDate)
            .tz(stop.connectionPoint?.timezone!, false)
            .format('YYYY-MM-DDTHH:mm:ss')
        : this.relayDropOffAppointmentEndDate;
    this.isDriverAssistRequired =
      stop?.pickupConnectionDetails?.driverAssistRequired;
    this.isRelayDropOffDriverAssistRequired =
      stop.dropOffConnectionDetails?.driverAssistRequired!;
    this.appointmentConfirmation =
      (stop?.pickupConnectionDetails
        ?.appointmentConfirmation as AppointmentConfirmationType) ??
      AppointmentConfirmationEnum.NO;
    this.dropOffAppointmentConfirmation =
      (stop?.dropOffConnectionDetails
        ?.appointmentConfirmation as AppointmentConfirmationType) ??
      AppointmentConfirmationEnum.NO;
    this.relayPickupRevenueShare = stop.pickupConnectionDetails?.revenueShare!;
    this.relayDropOffRevenueShare =
      stop.dropOffConnectionDetails?.revenueShare!;
    this.location = {
      ...stop?.connectionPoint,
      locationId: stop?.connectionPoint?.id,
      locationName: isLocationCoordsPresent
        ? stop?.connectionPoint?.locationName
        : '',
      businessName: isLocationCoordsPresent
        ? stop?.connectionPoint?.locationName
        : '',
      address: isLocationCoordsPresent ? stop?.connectionPoint?.address : '',
      center: stop?.connectionPoint?.center ?? {
        lat: stop?.connectionPoint?.lat,
        lng: stop?.connectionPoint?.lng,
      },
    } as CreateLocationResDTO;
    this.contacts = stop?.connectionPoint?.contacts;
    this.contact = {
      contactName: null,
      countryCode: null,
      phone: null,
      extension: null,
      email: null,
    };
    this.isEndTimeVisible = !stop?.pickupConnectionDetails?.fixedAppointment;
    this.isRelayDropOffEndTimeVisible =
      !stop?.dropOffConnectionDetails?.fixedAppointment;
    this.timezone =
      stop?.connectionPoint?.timezone ??
      stop?.connectionPoint?.location?.timezone;
    this.enableTimezone = true;
  }
}

export class TemplateResToLoadRateDTO implements ILoadRateInvoice {
  contract?: any;
  quantity?: number | null;
  internalNotes?: string;
  messageOnInvoice?: string;
  invoiceTerm?: string;
  invoiceTermValue?: string | null;
  totalRate?: string | null;
  description?: string | null;
  baseRateType?: string;
  feeDetail?: LineItem;
  rate?: number | null;
  isContractEnabled?: boolean;
  contractRateId?: string;
  additionalFees?: Array<{
    feeDetail?: LineItem;
    fee: string;
    feeType?: string;
    quantity: number | null;
    rate: number | null;
    description: string;
    total?: number;
    unit?: string;
    units?: string[];
  }>;
  constructor(load: LoadTemplateDetailsDTO) {
    const data = load?.invoice;
    this.internalNotes = data?.notes;
    this.invoiceTerm = data?.invoiceTerm;
    this.invoiceTermValue = data?.invoiceTermValue
      ? String(data?.invoiceTermValue)
      : null;
    (this.messageOnInvoice = load?.loadDetails?.factoringNotes),
      (this.quantity = 1);
    this.totalRate = String(load?.revenue ?? load.loadDetails?.revenue ?? 0);
    this.additionalFees = [];
  }

  async fetchAndUpdateLineItemValuesById(templateLineItems: LineItem[]) {
    try {
      const lineItemsRes = await rateFormService.getAccessorialFeeOptions();
      if (!templateLineItems?.length) {
        this.baseRateType = lineItemsRes?.base?.[0]?.id;
        this.feeDetail = lineItemsRes?.base?.[0];
        this.rate = Number(this.totalRate) || 0;
        return this;
      }
      const lineItemMap: Map<string, LineItem> = new Map();
      const result = [
        ...(lineItemsRes?.base ?? []),
        ...(lineItemsRes?.fees ?? []),
      ];
      result?.forEach((item) => {
        lineItemMap.set(item?.id, item);
      });

      const isContractSelected = templateLineItems?.some((e: any) =>
        Boolean(e?.contractId)
      );
      if (isContractSelected) {
        await this.setContractDetails(templateLineItems, lineItemsRes?.fees);
      } else {
        templateLineItems?.forEach?.((item, index) => {
          const selectedLineItem = lineItemMap.get(item.itemId);
          if (
            item?.type === FLAT_RATE_KEY ||
            item?.type === PER_LOADED_MILE_KEY ||
            item?.type === PER_LOADED_WEIGHT_KEY
          ) {
            this.baseRateType = selectedLineItem?.id;
            this.feeDetail = selectedLineItem;
            this.rate = item?.rate ?? null;
            this.quantity = item?.quantity;
            this.description = item?.description;
          } else {
            this.additionalFees?.push({
              fee: selectedLineItem?.id!,
              feeType: item?.type,
              quantity: item.quantity,
              rate: item?.rate ?? null,
              description: item?.description,
              total: item?.amount,
              feeDetail: selectedLineItem,
              unit: item?.unit,
              units: (selectedLineItem as any)?.unit,
              deduction: (selectedLineItem as any)?.deduction,
              contractId: item?.contractId,
              contractRateId: item?.contractRateId,
            } as any);
          }
        });
      }
      this.additionalFees?.push?.(initialState?.rate?.additionalFees?.[0]);
      return this;
    } catch (error) {
      return this;
    }
  }

  filterItemByItemCode(list: any[], itemId: string) {
    const additionalStopObject = list.find((item) => item.id === itemId);
    if (additionalStopObject) {
      return additionalStopObject;
    }
    return false;
  }

  async setContractDetails(lineItems: any, lineitemList: any) {
    const item = lineItems?.find((e: any) => Boolean(e?.contractId));
    const contractDetails = await rateFormService.getContractDetails(
      item?.contractId
    );
    const lineItemOptions: any[] = [];
    const feeItemOptions: any[] = [];
    let contract: any | null = null;
    if (lineItems?.length) {
      lineItems?.forEach?.((item: any) => {
        // item type is being updated manually in invoice details, so need to update here
        if (item?.type?.includes('USERDEFINED_PAYMENT')) {
          item.type = 'USERDEFINED_PAYMENT';
        } else if (item?.type?.includes?.('USERDEFINED_DEDUCTION')) {
          item.type = 'USERDEFINED_DEDUCTION';
        }
        const itemList = this.filterItemByItemCode(lineitemList, item.itemId);
        const option = {
          id: item?.contractRateId ? item?.contractRateId : item.itemId,
          itemCode: item.type,
          description: item.description,
          rate: item.rate,
          destination: item.destination,
          quantity: item.quantity,
          idExist: item?.id,
          unit: item?.unit,
          units: itemList?.units ?? itemList?.unit,
          itemName: itemList?.itemName
            ? itemList?.itemName
            : formatTextToUpperCase(item.type),
          deduction: itemList?.deduction,
          contractId: item?.contractId,
          contractRateId: item?.contractRateId,
          itemId: item?.itemId,
        };
        if (item?.contractId) {
          contract = {
            ...contractDetails,
            name: '',
          };
        }
        if (
          item.type === FLAT_RATE_KEY ||
          item.type === PER_LOADED_MILE_KEY ||
          item?.type === PER_LOADED_WEIGHT_KEY
        ) {
          lineItemOptions.push({
            ...option,
            isContract: Boolean(item?.contractId),
            totalMiles: item?.totalMiles || null,
          });
        } else {
          feeItemOptions.push({
            ...option,
            fee: item.itemId,
            total: item.amount,
            isContract: Boolean(item?.contractRateId),
            feeDetail: {
              ...option,
              isContract: Boolean(item?.contractRateId),
              fee: item.itemId,
              total: item.amount,
            },
          });
        }
      });
    }
    if (!contract) {
      feeItemOptions.push(initialState.rate.additionalFees[0]);
    }
    this.contract = contractDetails;
    this.isContractEnabled = contractDetails ? true : false;
    this.baseRateType = lineItemOptions?.[0]?.id;
    this.feeDetail = lineItemOptions?.[0];
    this.quantity = lineItemOptions?.[0]?.quantity;
    this.rate = lineItemOptions?.[0]?.rate;
    this.additionalFees = feeItemOptions;
    this.contractRateId = contractDetails ? lineItemOptions?.[0]?.id : null;
  }
}

export class TemplateInvoiceResDTO {
  number?: number;
  organizationId?: number;
  paid?: number;
  total?: number;
  amountDue?: number;
  loadedMiles?: number;
  emptyMiles?: number;
  lineItems?: Array<LineItem>;
  contentType?: string;
  notes?: string;
  noteDetails?: any;
  invoiceTerm?: string;
  synchStatus?: string;
}

export class LoadCommodityToCreateLoadDTO {
  commodities: ICommodities[];
  constructor() {
    this.commodities = [];
  }

  async fetchAndMapCommodityTypes(inputCommodites: ICommodity[]) {
    if (!inputCommodites?.length) return [];
    const commodityTypes = await getCommodityTypes();
    const commoditiesMap: Map<string, ICommodity> = new Map();
    commodityTypes?.forEach?.((c: ICommodity) => {
      commoditiesMap?.set(c?.id, c);
    });
    inputCommodites?.forEach?.((c) => {
      const selectedCommodity = commoditiesMap.get(c?.commodityTypeId);
      this.commodities?.push({
        ...c,
        id: c?.commodityTypeId,
        commodity: c?.commodityTypeId,
        quantity: c?.qty,
        itemName: selectedCommodity?.itemName!,
        itemCode: selectedCommodity?.itemCode!,
        commodityDetails: selectedCommodity,
        package: c?.packageType,
      });
    });
    return this.commodities;
  }
}

export class StopContactToLoadFormContactDTO {
  id?: number;
  customerId?: number;
  fullName?: string;
  phoneData?: {
    countryCode?: string;
    extension?: string;
    phone?: string;
  };
  email?: string;
  isPrimary?: boolean;
  isAfterHours?: boolean;
  description?: string;
  externalSystemId?: string;
  createSource?: boolean;
  constructor(data: ICustomerContact) {
    this.id = data?.id;
    this.customerId = data?.customerId;
    this.fullName =
      data?.firstname + (data?.lastname ? ' ' + data?.lastname : '');
    this.phoneData = {
      phone: data?.phone,
      extension: data?.extension,
      countryCode: data?.countryCode,
    };
    this.email = data?.email;
    this.isPrimary = data?.isPrimary;
  }
}

const getSelectedPhoneData = (contacts: StopContactDTO[], id: number) => {
  if (!contacts?.length) return null;
  const selectedPhoneData = contacts?.find?.((e) => e?.id === id);
  if (!selectedPhoneData || !id) {
    const primaryContact = contacts?.find?.((e) => e?.isPrimary);
    if (!primaryContact) return null;
    return new StopContactToLoadFormContactDTO(
      primaryContact as ICustomerContact
    );
  }
  return new StopContactToLoadFormContactDTO(
    selectedPhoneData as ICustomerContact
  );
};

export const getRequiredDocsList = ({
  bol,
  pod,
}: {
  bol?: boolean;
  pod?: boolean;
}) => {
  const list = [];
  if (bol) list.push(CustomerRequiredDocOptions[0]?.id);
  if (pod) list.push(CustomerRequiredDocOptions[1].id);
  return list;
};

export class TemplateResToLoadDTO implements ILoadDetails {
  templateId?: string;
  templateName?: string;
  customer: ICustomerObject;
  id?: string;
  // invoice?: TemplateInvoiceResDTO;
  load?: ILoad;
  numberOfStops?: number;
  stops: ILoadStop[];
  terminal?: any;
  totalMiles?: string | number;
  isTotalMilesManuallyUpdate?: boolean;
  rate?: ILoadRateInvoice;
  loadType?: string;
  isTerminalEnabled: boolean;
  constructor(
    data: LoadTemplateDetailsDTO,
    isTerminalEnabled: boolean,
    allTerminals?: Array<any>
  ) {
    const selContact = getSelectedPhoneData(
      data?.customer?.contacts,
      data?.customer?.customerContactId
    ) as any;
    let terminal = data?.terminal;
    if (!terminal) {
      terminal = allTerminals?.find?.((e: any) => e?.isPrimary);
    }
    this.templateId = data?.templateId;
    this.templateName = data?.templateName;
    this.customer = {
      id: data?.customer?.customerId ?? data?.customer?.id ?? '',
      contacts: data?.customer?.contacts?.map(
        (e) => new StopContactToLoadFormContactDTO(e) as any
      ),
      selectedPhoneData: selContact,
      customerContactId: selContact?.id ?? '',
      customerId: data?.customer?.customerId ?? data?.customer?.id ?? '',
      customerName: data?.customer?.customerName ?? data?.customer?.name ?? '',
      requiredDocuments: getRequiredDocsList({
        bol: data?.billOfLadingMandatory,
        pod: data?.proofOfDeliveryMandatory,
      }),
      factoringId: data?.loadDetails?.factoringId,
      referenceNumber: data?.loadDetails?.referenceId,
      invoice: isTerminalEnabled ? terminal?.id : '',
      invoiceCompanyName: isTerminalEnabled ? terminal?.companyName : '',
      loadPriority: data?.loadDetails?.loadPriority ?? LoadPriorityEnum.None,
      factoringName: data?.loadDetails?.factoringName ?? '',
      factoringChargePercent: data.loadDetails?.factoringChargePercent!,
    };
    this.numberOfStops = data?.numberOfStops;
    this.load = {
      equipment: data?.loadDetails?.trailerTypes,
      temp: isNaN(
        Number(data?.loadDetails?.equipmentAdditionalDetails?.temperature)
      )
        ? null
        : Number(data?.loadDetails?.equipmentAdditionalDetails?.temperature),
      loadType: data?.loadType ?? LOAD_TYPE_KEYS.FTL,
      // isBrokered:
      weight: data?.loadDetails?.totalWeight,
      notes: data?.loadDetails?.notes,
      seal: data.loadDetails?.equipmentAdditionalDetails?.sealNumber,
      equipmentAdditionalDetails: {
        chassisNumber:
          data.loadDetails?.equipmentAdditionalDetails?.chassisNumber,
        containerNumber:
          data.loadDetails?.equipmentAdditionalDetails?.containerNumber,
        importExport:
          data.loadDetails?.equipmentAdditionalDetails?.loadDirection,
        bookingNumber:
          data?.loadDetails?.equipmentAdditionalDetails?.bookingNumber,
        appointmentNumber:
          data?.loadDetails?.equipmentAdditionalDetails?.appointmentNumber,
        reeferMode:
          (data?.loadDetails?.equipmentAdditionalDetails
            ?.reeferMode as ReeferModeEnum) ?? ReeferModeEnum.CONTINUOUS,
      },
      tempVariance: data?.loadDetails?.equipmentAdditionalDetails?.variance,
      commodities: [],
      tags: data.loadDetails?.tags,
    };
    this.stops = data?.stops?.map((e) => {
      if (e.tripStop) {
        e.tripStop.activityType = StopTypeEnum.TRIP_STOP;
        return new TemplateLoadStopToLoadStopDTO(e?.tripStop!);
      }
      if (e.loadStop) return new TemplateLoadStopToLoadStopDTO(e?.loadStop);
      return new TemplateRelayStopToLoadRelayStopDTO(e?.relayStop!);
    });
    this.loadType = data?.loadType ?? LOAD_TYPE_KEYS.FTL;
    this.rate = new TemplateResToLoadRateDTO(data);
    this.isTerminalEnabled = isTerminalEnabled ?? true;
    this.totalMiles = data?.invoice?.loadedMiles;
    this.isTotalMilesManuallyUpdate = !!data?.invoice?.loadedMiles;
  }
}
