import { ButtonGroup } from '@components/ui-kit/button-group';
import { Select } from '@components/ui-kit/select';
import { TextField } from '@components/ui-kit/text-field';
import { Typography } from '@components/ui-kit/typography';
import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Theme,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useCarrierPermission } from '../../../../../common/hooks/useCarrierPermission';
import { ILoadStaticContent } from '../../../../../models';
import LazyLoadedMultiSelect from '../../../../../views/customTags/customTagChip';
import {
  FINANCE_TYPES,
  LoadReeferModeOptions,
  PER_LOADED_WEIGHT_KEY,
} from '../../constants/fieldOptions';
import {
  ALLOWED_EQUIPMENT_TYPE_VALS,
  DefaultEquipmentAdditionalValue,
  LOAD_TYPE_KEYS,
  SECTION_LAST_ELEMENT_ID_LIST,
} from '../../constants/fieldValues';
import { QUERY_KEYS } from '../../constants/queryKeys';
import { ReeferModeEnum } from '../../schema/load';
import { customerFormService } from '../../services/customerForm.service';
import { rateFormService } from '../../services/rate.service';
import {
  ActionButtonStyles,
  ActionButtonWithoutBoldStyles,
  ReeferModeButtonGroup,
  ReeferModeButtonSelectedStyles,
  ReeferModeButtonStyles,
} from '../../style';
import { ILoad } from '../../types/types';
import { ErrorText } from '../ErrorText';
import AccordionTotalsSummary from '../ui/accordion-totals-summary';
import CommodityItem from './commodity/commodity-item';

const LoadSection: React.FC = () => {
  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const load = watch('load');
  const commodities = watch('load.commodities');
  const loadHiddenFields = watch('hiddenFields.load');
  const loadRequiredFields = getValues('requiredFields.load');
  const feeDetail = getValues('rate.feeDetail');
  const additionalFees = watch(`rate.additionalFees`);
  const { hasCarrierViewPermission } = useCarrierPermission();

  const { fields, remove, insert } = useFieldArray({
    name: 'load.commodities',
  });

  const loadStaticData = useQueryClient().getQueryData<ILoadStaticContent>(
    QUERY_KEYS.loadStaticData
  );
  const queryClient = useQueryClient();

  const loadCommodityList = queryClient.getQueryData<any>(
    QUERY_KEYS.loadCommodityList
  );

  const addComoddity = () => {
    const miscCommodity = loadCommodityList?.find(
      (e: any) => e?.itemCode === 'MISCELLANEOUS'
    );
    insert(fields.length, {
      commodity: miscCommodity?.id ?? '',
      commodityDetails: miscCommodity ?? null,
      package: '',
      weight: null,
      description: '',
      pickup: '',
      dropoff: '',
      length: null,
      width: null,
      height: null,
      volume: null,
      isDimensionalWeight: false,
      quantity: 1,
    });
  };

  const removeCommodity = (index: number) => {
    remove(index);
    updateTotalWeight(index);
  };

  const toggleLoadType = (prevLoadType: string) => {
    const newValue = prevLoadType === 'FTL' ? 'LTL' : 'FTL';
    setValue(`load.loadType`, newValue);
  };

  const toggleBroker = () => {
    setValue(`load.isBrokered`, !load.isBrokered);
  };

  // const [isRange, setIsRange] = useState(false);
  const toggleIsRange = () => {
    setValue('load.showTemperatureVariance', !load?.showTemperatureVariance);
    // setIsRange((prevIsRange) => (prevIsRange = !prevIsRange));
  };

  const updateTotalWeight = (ignoreIndex?: number) => {
    const com = [...commodities];
    if (typeof ignoreIndex == 'number') {
      com.splice?.(ignoreIndex, 1);
    }
    const totalWeight = calculateTotalWeight(com);
    setValue('load.weight', totalWeight);
    if (feeDetail?.itemCode === PER_LOADED_WEIGHT_KEY)
      setValue('rate.quantity', totalWeight);
    updateLineItems(totalWeight);
  };

  const updateLineItems = (totalWeight?: number) => {
    if (!additionalFees?.length) return;
    const updatedFees = additionalFees?.map((e: any) => {
      const qty =
        e?.unit === FINANCE_TYPES.PER_WEIGHT ? totalWeight || 1 : e?.quantity;
      return {
        ...e,
        quantity: qty,
        total: rateFormService.calculateLineItemRateByUnit({
          quantity: qty,
          rate: Number(e?.rate),
          unit: e?.unit,
        }),
      };
    });
    setValue('rate.additionalFees', updatedFees);
  };

  const calculateTotalWeight = useCallback((commodities) => {
    return commodities.reduce((total: number, commodity: any) => {
      return total + (commodity.weight ? parseFloat(commodity.weight) : 0);
    }, 0);
  }, []);

  const handleEquipmentChange = (event: SelectChangeEvent<unknown>) => {
    setValue(
      'load.equipment',
      customerFormService.validateAndGetEquipmentValue(
        load.equipment,
        event?.target?.value as ILoad['equipment']
      )
    );
    setValue(
      'load.equipmentAdditionalDetails',
      DefaultEquipmentAdditionalValue
    );
    if (!(event?.target?.value as any)?.includes?.('Reefer')) {
      setValue('load.showTemperatureVariance', false);
      setValue('load.temp', null);
      setValue('load.tempVariance', null);
    }
  };

  const renderReeferTypeOptionalFields = () => {
    if (!load?.equipment?.includes?.(ALLOWED_EQUIPMENT_TYPE_VALS.REEFER))
      return <></>;

    return (
      <Stack
        direction={'row'}
        gap={1}
        alignItems={'flex-start'}
        useFlexGap
        mt={1}
      >
        <Box position={'relative'}>
          <Typography variant="body3" ml={1} position={'absolute'} top="-16px">
            Reefer Mode
          </Typography>
          <ButtonGroup
            variant="outlined"
            aria-label="reefer-mode"
            sx={ReeferModeButtonGroup}
          >
            {LoadReeferModeOptions.map((e) => (
              <Button
                key={e.id}
                onClick={() => {
                  if (e?.id === 'OFF') {
                    setValue('load.temp', null);
                    setValue('load.tempVariance', null);
                  }
                  setValue('load.equipmentAdditionalDetails.reeferMode', e.id);
                }}
                sx={(theme: Theme) =>
                  load?.equipmentAdditionalDetails?.reeferMode == e.id
                    ? ReeferModeButtonSelectedStyles(theme)
                    : ReeferModeButtonStyles(theme)
                }
                variant={
                  load?.equipmentAdditionalDetails?.reeferMode == e.id
                    ? 'contained'
                    : 'outlined'
                }
              >
                {e.label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        {load.equipmentAdditionalDetails?.reeferMode === ReeferModeEnum.OFF ? (
          <Typography
            variant="caption"
            color={'textSecondary'}
            mt={'auto'}
            mb="auto"
          >
            (Reefer is being used as a container only)
          </Typography>
        ) : (
          <>
            <Controller
              name="load.temp"
              render={({ field }) => (
                <TextField
                  id="createLoad-temp"
                  type="number"
                  label="Temp"
                  {...field}
                  sx={{ width: '120px' }}
                  error={errors?.load?.temp?.message}
                  helperText={errors?.load?.temp?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">F</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {load?.showTemperatureVariance ? (
              <Controller
                name="load.tempVariance"
                render={({ field }) => (
                  <TextField
                    id="createLoad-temp-variance"
                    type="number"
                    label="Set +/-"
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">F</InputAdornment>
                      ),
                    }}
                    sx={{ width: '120px' }}
                  />
                )}
              />
            ) : (
              <Button onClick={toggleIsRange}>Set +/-</Button>
            )}
          </>
        )}
      </Stack>
    );
  };

  const renderChassisAndContainerOptionalFields = () => {
    if (load?.equipment?.[0] !== ALLOWED_EQUIPMENT_TYPE_VALS.CHASSIS_CONTAINER)
      return <></>;
    return (
      <Grid container spacing={1} alignItems={'flex-start'}>
        <Grid item xs={12} md={4}>
          <Controller
            name="load.equipmentAdditionalDetails.chassisNumber"
            render={({ field }) => (
              <TextField
                id="createLoad-chassisNumber"
                label="Chassis #"
                {...field}
                error={errors?.load?.equipmentAdditionalDetails?.chassisNumber}
                helperText={
                  errors?.load?.equipmentAdditionalDetails?.chassisNumber
                    ?.message
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="load.equipmentAdditionalDetails.containerNumber"
            render={({ field }) => (
              <TextField
                id="createLoad-containerNumber"
                label="Container #"
                {...field}
                error={
                  errors?.load?.equipmentAdditionalDetails?.containerNumber
                }
                helperText={
                  errors?.load?.equipmentAdditionalDetails?.containerNumber
                    ?.message
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack sx={{ minWidth: '160px' }}>
            <Controller
              name="load.equipmentAdditionalDetails.importExport"
              render={({ field }) => (
                <Select
                  id="createLoad-importExport"
                  label="Import/Export"
                  {...field}
                  error={
                    errors?.load?.equipmentAdditionalDetails?.containerNumber
                  }
                >
                  {loadStaticData?.loadDirectionTypes?.map?.((e) => (
                    <MenuItem key={e.key} value={e?.key}>
                      {e?.value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <ErrorText
              text={
                errors?.load?.equipmentAdditionalDetails?.importExport?.message
              }
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="load.equipmentAdditionalDetails.bookingNumber"
            render={({ field }) => (
              <TextField
                id="createLoad-booking"
                label="Booking #"
                {...field}
                fullWidth
                error={errors?.load?.equipmentAdditionalDetails?.bookingNumber}
                helperText={
                  errors?.load?.equipmentAdditionalDetails?.bookingNumber
                    ?.message
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="load.equipmentAdditionalDetails.appointmentNumber"
            render={({ field }) => (
              <TextField
                id="createLoad-appointmentNumber"
                label="Appointment #"
                {...field}
                fullWidth
                error={
                  errors?.load?.equipmentAdditionalDetails?.appointmentNumber
                }
                helperText={
                  errors?.load?.equipmentAdditionalDetails?.appointmentNumber
                    ?.message
                }
              />
            )}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack direction="column" gap={2}>
      <Stack direction="row" gap={1} useFlexGap>
        <Stack sx={{ width: '100%', maxWidth: '275px' }}>
          <Controller
            name="load.equipment"
            render={({ field }) => (
              <Select
                id="createLoad-equipment"
                autoFocus
                label={
                  <>
                    Allowed Equipment Type(s)
                    {loadRequiredFields?.equipment && (
                      <span style={{ color: 'red' }}>*</span>
                    )}
                  </>
                }
                {...field}
                multiple
                onChange={handleEquipmentChange}
                error={errors?.load?.equipment}
              >
                {loadStaticData?.trailerType?.map?.((e) => (
                  <MenuItem value={e?.key}>{e?.value}</MenuItem>
                ))}
              </Select>
            )}
          />
          <ErrorText text={errors?.load?.equipment?.message} />
        </Stack>

        {!loadHiddenFields?.seal && (
          <Controller
            name="load.seal"
            render={({ field }) => (
              <TextField
                {...field}
                id="createLoad-load-seal"
                label="Seal #"
                sx={{ width: '200px' }}
                error={errors?.load?.seal}
                helperText={errors?.load?.seal?.message}
              />
            )}
          />
        )}
        <Button
          sx={ActionButtonStyles}
          onClick={() => toggleLoadType(load?.loadType)}
        >
          {LOAD_TYPE_KEYS?.[load?.loadType]}
        </Button>
        {hasCarrierViewPermission && !loadHiddenFields?.canBeBrokered && (
          <Box display={'flex'} ml="auto">
            <Button
              onClick={toggleBroker}
              sx={(theme: Theme) => ({
                width: '190px',
                ...ActionButtonStyles(theme),
              })}
            >
              Can be brokered? {load?.isBrokered ? <b> Yes</b> : 'No'}
            </Button>
          </Box>
        )}
      </Stack>

      {renderReeferTypeOptionalFields()}

      {renderChassisAndContainerOptionalFields()}

      <Stack
        direction="column"
        gap={1.5}
        display={loadHiddenFields?.commodities ? 'none' : undefined}
      >
        {fields.map((field, index) => (
          <CommodityItem
            key={field.id}
            index={index}
            commoditiesLength={commodities.length}
            updateTotalWeight={updateTotalWeight}
            remove={removeCommodity}
          />
        ))}
        <Stack direction="row" alignItems="center">
          <Button
            onClick={() => addComoddity()}
            sx={ActionButtonWithoutBoldStyles}
            startIcon={<Add />}
          >
            Add Commodity
          </Button>
        </Stack>
      </Stack>
      <Box>
        <LazyLoadedMultiSelect
          lable={'Tags to add'}
          value={load?.tags ?? []}
          keyValue="load.tags"
        />
      </Box>
      <Box>
        <Controller
          name="load.notes"
          render={({ field }) => (
            <TextField
              id={SECTION_LAST_ELEMENT_ID_LIST[3]}
              multiline
              rows={3}
              label="Add notes for dispatching..."
              {...field}
              sx={{
                width: '100%',
                display: loadHiddenFields?.notes ? 'none' : undefined,
              }}
              error={errors?.load?.notes}
              helperText={errors?.load?.notes?.message}
            />
          )}
        />
      </Box>
      {Boolean(commodities.length && load.weight) && (
        <AccordionTotalsSummary
          label="Total Weight"
          value={load.weight + 'lbs'}
        ></AccordionTotalsSummary>
      )}
    </Stack>
  );
};

export default React.memo(LoadSection);
