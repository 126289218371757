import { SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import { t } from 'i18next';
export const FileUnregistered = ({
  rootProps,
  uploadBtnText,
  uploadBtnStyle,
}: {
  rootProps: any;
  uploadBtnText?: string | any;
  uploadBtnStyle?: SxProps;
}) => {
  return (
    <Button
      {...rootProps}
      variant="contained"
      size="large"
      sx={{
        width: '100%',
        height: '48px',
        borderRadius: '5px',
        margin: '0!important',
        ...uploadBtnStyle,
      }}
    >
      {uploadBtnText ? uploadBtnText : '+ ' + t('clickHereToUploadDocument')}
    </Button>
  );
};
