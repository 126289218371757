import {
  ADD_DRIVER_SCHEDULE,
  EMPTY_TRIP_ASSIGNMENT,
  CLEAR_SCHEDULE,
  MAP_SCHEDULE_WARNINGS,
  SET_DRIVERS,
  SET_DRIVER_SCHEDULE,
  SET_LOADER,
  SET_SCHEDULE_WARNINGS,
  STOP_LOADER,
  UPDATE_DRIVER_SCHEDULE,
  CLEAR_EMPTY_TRIP_ASSIGNMENT,
  STOP_INITIAL_LOADER,
} from './driverSchedule.actions';
import {
  DriverWarningsType,
  HosConstantsType,
  IDriverSchedule,
  IDriverScheduleWarnings,
  ScheduleTimeoffsType,
  TripsWarningsType,
} from '../../../../views/dispatch2/types';
import {
  formatTimeoffsForSchedule,
  formatTripsForSchedule,
  generateEmptyTripAssignmentSchedule,
  getFormatedDate,
  removeTimezoneFromDateString,
} from '../../../../views/dispatch2/services';
import moment from 'moment';
import {
  mapDriverWarnings,
  mapTripWarnings,
} from '../../../../services/gantt/ganttWarnings.service';
import {
  ScheduleDriverType,
  ScheduleTripType,
} from '../../../../types/DispatchTypes';

export const driverScheduleInitialState: IDriverSchedule = {
  drivers: [],
  trips: new Map(),
  isLoading: false,
  currentPage: 1,
  hosConstants: {},
  timeoffs: new Map(),
  numberOfDrivers: 0,
  isInitialLoad: true,
};

export interface IDriverReducerPayload {
  drivers?: Array<ScheduleDriverType>;
  trips?: Array<ScheduleTripType>;
  timeoffs?: Array<ScheduleTimeoffsType>;
  isLoading?: boolean;
  currentPage?: number;
  timezone?: string;
  hosConstants?: HosConstantsType;
  warnings?: IDriverScheduleWarnings;
  numberOfDrivers?: number;
  currentWeek?: Array<string>;
  resourceId?: string;
  updateTripParams?: object;
  tripId?: string;
  windowStart?: Date | string;
  windowEnd?: Date | string;
}

export const driverScheduleReducer = (
  state: IDriverSchedule,
  action: { type: string; payload?: IDriverReducerPayload }
) => {
  const { type, payload } = action;
  let currentWeekDate = '';
  if (
    payload?.currentWeek &&
    Array.isArray(payload?.currentWeek) &&
    payload?.currentWeek?.length > 0
  ) {
    currentWeekDate = getFormatedDate(payload?.currentWeek[0]) ?? '';
  }
  switch (type) {
    case SET_DRIVERS:
      return {
        ...state,
        drivers: payload?.drivers,
        numberOfDrivers: payload?.numberOfDrivers,
        currentWeekDate: currentWeekDate,
      };
    case CLEAR_SCHEDULE:
      return driverScheduleInitialState;
    case SET_DRIVER_SCHEDULE:
      return {
        ...state,
        drivers: payload?.drivers,
        trips: formatTripsForSchedule({
          trips: payload?.trips as Array<ScheduleTripType>,
        }),
        timeoffs: formatTimeoffsForSchedule({ timeoffs: payload?.timeoffs }),
        hosConstants: payload?.hosConstants,
        isLoading: false,
        numberOfDrivers: payload?.numberOfDrivers,
        currentWeekDate: currentWeekDate,
        isInitialLoad: false,
      };
    case ADD_DRIVER_SCHEDULE:
      return {
        ...state,
        drivers: [
          ...(state?.drivers as Array<ScheduleDriverType>),
          ...(payload?.drivers as Array<ScheduleDriverType>),
        ],
        trips: new Map([
          ...(state?.trips as Map<string, Array<ScheduleTripType>>),
          ...formatTripsForSchedule({
            trips: payload?.trips as Array<ScheduleTripType>,
          }),
        ]),
        timeoffs: new Map([
          ...(state?.timeoffs as Map<string, Array<ScheduleTimeoffsType>>),
          ...formatTimeoffsForSchedule({ timeoffs: payload?.timeoffs }),
        ]),
        isLoading: false,
        numberOfDrivers: payload?.numberOfDrivers,
      };
    case SET_LOADER:
      return {
        ...state,
        isLoading: payload?.isLoading,
      };
    case SET_SCHEDULE_WARNINGS:
      return {
        ...state,
        isLoading: false,
        warnings: payload?.warnings,
      };
    case MAP_SCHEDULE_WARNINGS:
      const { drivers, trips, warnings } = state;
      const driversWithWarnings = mapDriverWarnings({
        drivers,
        warnings: warnings?.drivers as unknown as {
          [key: string]: DriverWarningsType;
        },
      });
      const tripsWithWarnings = mapTripWarnings({
        trips,
        warnings: warnings?.trips as unknown as {
          [key: string]: TripsWarningsType;
        },
      });
      return {
        ...state,
        drivers: driversWithWarnings,
        trips: tripsWithWarnings,
        warnings,
      };
    case STOP_LOADER:
      return {
        ...state,
        isLoading: false,
        isInitialLoad: false,
      };
    case STOP_INITIAL_LOADER:
      return {
        ...state,
        isInitialLoad: false,
        isLoading: false,
      };
    case UPDATE_DRIVER_SCHEDULE: {
      const { drivers, trips, timeoffs } = state;
      payload?.drivers?.forEach((driver: ScheduleDriverType) => {
        const driverToUpdateIndex = drivers?.findIndex(
          (d) => d.id === driver.id
        ) as number;
        if (driverToUpdateIndex >= 0)
          (drivers as Array<ScheduleDriverType>)[driverToUpdateIndex] = {
            ...(drivers as Array<ScheduleDriverType>)[driverToUpdateIndex],
            ...driver,
          };
      });
      const updatedTimeoffs = new Map([
        ...(timeoffs as Map<string, Array<ScheduleTimeoffsType>>),
        ...formatTimeoffsForSchedule({
          timeoffs: payload?.timeoffs,
          driverGroupIdsToMap: payload?.drivers?.map(
            (driver) => driver?.driverGroupDetails?.id as string
          ),
        }),
      ]);
      return {
        ...state,
        drivers,
        trips: new Map([
          ...(trips as Map<string, Array<ScheduleTripType>>),
          ...formatTripsForSchedule({
            trips: payload?.trips as Array<ScheduleTripType>,
            driverGroupIdsToMap: payload?.drivers?.map(
              (driver) => driver?.driverGroupDetails?.id as string
            ),
          }),
        ]),
        timeoffs: updatedTimeoffs,
        isLoading: false,
        numberOfDrivers: payload?.numberOfDrivers,
        currentWeekDate: currentWeekDate,
      };
    }
    case EMPTY_TRIP_ASSIGNMENT: {
      const { trips } = state;
      const { resourceId, tripId, windowStart, windowEnd } =
        payload as IDriverReducerPayload;
      const driverTrips = trips?.get(resourceId as string);
      const tripToUpdateIndex = driverTrips?.findIndex(
        (t) => t.id === tripId
      ) as number;
      if (!driverTrips?.length || tripToUpdateIndex < 0) return state;
      const TripSchedule = {
        startDate: moment(
          removeTimezoneFromDateString(
            driverTrips[tripToUpdateIndex]?.startDate
          )
        )
          ?.add(1, 'minutes')
          .format(),
        endDate: removeTimezoneFromDateString(
          driverTrips[tripToUpdateIndex]?.endDate
        ),
      };
      const { startDate, endDate } = generateEmptyTripAssignmentSchedule(
        TripSchedule,
        {
          startDate: windowStart,
          endDate: windowEnd,
        }
      );
      driverTrips[tripToUpdateIndex] = {
        ...driverTrips[tripToUpdateIndex],
        isTripSelect: true,
        originalDates: {
          startDate: driverTrips[tripToUpdateIndex]?.startDate,
          endDate: driverTrips[tripToUpdateIndex].endDate,
        },
        startDate: startDate as string,
        endDate: endDate as string,
      };
      trips?.set(resourceId as string, driverTrips);
      return {
        ...state,
        trips,
      };
    }
    case CLEAR_EMPTY_TRIP_ASSIGNMENT: {
      const { trips } = state;
      const { resourceId, tripId } = payload as IDriverReducerPayload;
      const driverTrips = trips?.get(resourceId as string);
      const tripToUpdateIndex = driverTrips?.findIndex(
        (t) => t.id === tripId
      ) as number;
      if (!driverTrips?.length || tripToUpdateIndex < 0) return state;
      driverTrips[tripToUpdateIndex] = {
        ...driverTrips[tripToUpdateIndex],
        isTripSelect: false,
        startDate: driverTrips[tripToUpdateIndex]?.originalDates
          ?.startDate as string,
        endDate: driverTrips[tripToUpdateIndex]?.originalDates
          ?.endDate as string,
      };
      trips?.set(resourceId as string, driverTrips);
      return {
        ...state,
        trips,
      };
    }
    default:
      return { ...state };
  }
};
