import { ArrowForwardIosOutlined } from '@mui/icons-material';
import {
  AutocompleteRenderOptionState,
  Box,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import {
  bindHover,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { MouseEventHandler } from 'react';
import { LOADAI_ELEMENTS } from '../../../../../../constants/elementIds';
import ButtonImproved from '../../../../../../ui-kit/components/ButtonImproved';
import { EUnassignedTripMenuAction } from '../../../../../trips/constants/types';
import { CLASS_NAMES } from './styles';

type CustomOptionProps = {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: any;
  state: AutocompleteRenderOptionState;
  onSubmitHandler?: (request: {
    event: MouseEventHandler;
    toStatus: EUnassignedTripMenuAction;
    option: any;
  }) => void;
  fieldName: string;
  styles: { [key: string]: SxProps };
  onEvaluation?: (option: any, event: React.MouseEvent) => void;
  showEvaluateAction?: boolean;
  showStatusUpdateAction?: boolean;
  showRecommendationAction?: boolean;
  onRecommendation?: () => void;
  disableAllActions?: boolean;
};

export const CustomOption = ({
  props: optionProps,
  option,
  onSubmitHandler,
  fieldName,
  styles,
  onEvaluation,
  showEvaluateAction = false,
  showStatusUpdateAction = false,
  disableAllActions = false,
}: CustomOptionProps) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'ManualDriverSelectionPopover-CustomOption',
  });
  const customBindTrigger = bindHover(popupState);

  return (
    <Box
      component={'li'}
      {...optionProps}
      key={optionProps.id}
      sx={styles.CustomOption}
      {...customBindTrigger}
    >
      <Box
        sx={styles.CustomOptionContent}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Typography variant="body1" color={'text.primary'}>
          {option[fieldName]}
        </Typography>
        <Box className={CLASS_NAMES.arrowForwardIcon}>
          <ArrowForwardIosOutlined sx={styles.arrowForwardIcon} />
        </Box>
      </Box>
      <Box>
        <HoverPopover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={styles.CustomOptionActions}
          >
            {showEvaluateAction && (
              <ButtonImproved
                disabled={disableAllActions}
                id={LOADAI_ELEMENTS.TRIP_DRIVER_EVALUATION}
                styleProps={styles.CustomOptionActionButton}
                label={'Evaluate'}
                variant={'outlined'}
                onClick={(event: React.MouseEvent) =>
                  onEvaluation?.(option, event)
                }
              />
            )}
            {showStatusUpdateAction && (
              <>
                <ButtonImproved
                  disabled={disableAllActions}
                  styleProps={styles.CustomOptionActionButton}
                  label={'Mark as Planned'}
                  variant={'outlined'}
                  onClick={(event: any) => {
                    onSubmitHandler?.({
                      event,
                      toStatus: EUnassignedTripMenuAction.MARK_AS_PLANNED,
                      option,
                    });
                  }}
                />
                <ButtonImproved
                  disabled={disableAllActions}
                  styleProps={styles.CustomOptionActionButton}
                  label={'Mark as Assigned'}
                  color={'primary'}
                  variant="contained"
                  onClick={(event: any) => {
                    onSubmitHandler?.({
                      event,
                      toStatus: EUnassignedTripMenuAction.MARK_AS_ASSIGNED,
                      option,
                    });
                  }}
                />
              </>
            )}
          </Stack>
        </HoverPopover>
      </Box>
    </Box>
  );
};
