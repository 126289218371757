import BaseEvent from '../BaseEvent';
import { EventTypes } from '../EventTypes';
import { Trip } from '../../common/LoadTabPanel/tabs/Routes/Models';

export interface IEventTripAssignmentData {
  trip: Trip;
  isStatusUpdated: boolean;
}

export default class EventTripAssignment extends BaseEvent {
  data: IEventTripAssignmentData;
  constructor(data: any) {
    super(EventTypes.TRIP_ASSIGNMENT_CHANGE, data);
    this.data = data;
  }
}
