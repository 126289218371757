import { Type } from 'class-transformer';
import { MomentInput } from 'moment';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { LoadPriorityEnum } from '../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import { PaginatedList } from '../../../types';
import {
  StopSolutionV3Contact2,
  StopSolutionV3NoteDetails,
  StopSolutionV3StopType,
  TripStopActivityEnum,
  TripStopStatusEnum,
} from '../../../ui-kit/components/TripPlan';
import { ICoordinates } from '../../../views/loadboards/loadTypes';
import { LoadOriginDestinationResponse } from '../Loads/Requests';
import { PreferenceType } from '../locations/Location';
import {
  ETractorModel,
  ETrailerModel,
  ETripEmployeeType,
  ETripGroupMode,
  ETripGroupOperationType,
  ETripSettlementStatus,
} from './Types';

export interface TripCarrierDetails {
  id: string;
  name: string;
  status: string;
  contacts: any[];
}

export class BrokeredTripOption {
  brokerageTrip?: boolean;
  carrierDetails?: TripCarrierDetails;
}
export class Trip extends BrokeredTripOption {
  organizationId!: number;
  id!: string;
  seqNumber!: string;
  status!: ELoadStatus;
  driverGroupId!: string;
  @Type(() => TripGroupDetail)
  groupDetails?: TripGroupDetail;
  @Type(() => TripAssignment)
  assignment!: TripAssignment;
  fromLocationName!: string;
  toLocationName!: string;
  startDateTime!: string;
  endDateTime!: string;
  dispatcherId!: number;
  dispatcherName!: string;
  @Type(() => TripConnectedLoad)
  connectedLoads!: TripConnectedLoad[];
  settlementStatus!: ETripSettlementStatus;
  settlementAmount!: number;
  revenue!: number;
  tripMileage!: number;
  emptyMileage!: number;
  numberOfStops!: number;
  firstStopTimeZone!: string;
  lastStopTimeZone!: string;
  lastUpdatedStop!: {
    message: string;
    stopActivityType: TripStopActivityEnum;
    stopStatus: TripStopStatusEnum;
  } | null;
  tripDetentionTime!: number | null;
  terminal!: {
    id: string;
    name: string;
  } | null;
  createDate!: string;
  dispatchRevenue?: number;
  manifestBaseDetails?: {
    manifestId: string;
    seqNumber: string;
    status: string;
  };
  loadDetails?: {
    loadId: string;
    seqNumber: string;
  };
  onHold!: boolean;
  onHoldNote!: string;
  loadPriority?: LoadPriorityEnum;
  tags?: Array<any>;
  isEmptyTrip?: boolean;
}

export class LightList {
  seqNumber!: string;
  tripIds!: string[];
}

export class TripGroupDetail {
  id!: string;
  organizationId!: number;
  groupName!: string;
  groupMode!: ETripGroupMode;
  @Type(() => TripDriver)
  drivers!: TripDriver[];
  activeForOperation!: boolean;
  dispatcherId!: number;
  employmentType!: ETripEmployeeType;
  operationType!: ETripGroupOperationType;
  displayName!: string;
  @Type(() => TripAssignment)
  assetAssignment!: TripAssignment;
}

export class TripDriver {
  id!: number;
  name!: string;
  email!: string;
  mobileno!: string;
  extension!: null; // TODO Anh
  countryCode!: null; // TODO Anh
  shareTractorExpenses!: boolean;
}

export class TripAssignment {
  tractorId!: number;
  tractorModel!: ETractorModel | null; // TODO Anh;
  tractorName!: string;
  trailerId!: number;
  trailerModel!: ETrailerModel | null;
  trailerName!: string;
}

export class TripTerminal {
  id!: string;
  companyName!: string;
}

export class TripConnectedLoad {
  loadId!: string;
  loadSequenceNumber!: string;
  terminalId!: null; // TODo Anh
  terminalName!: null; // TODO Anh
  chassisNumber!: string;
  containerNumber!: string;
  referenceId!: string;
  customer!: { id: number; name: string };
  manifestBaseDetails?: {
    manifestId: string;
    seqNumber: string;
    status: string;
  };
  loadDetails?: {
    loadId: string;
    seqNumber: string;
  };
}

export interface ITripCountMetrics {
  plannedTripCount: number;
  availableTripCount: number;
  availableNonHoldTripCount?: number;
}
export class PaginatedTripList extends PaginatedList {
  @Type(() => Trip)
  content!: Trip[];
  tripCountMetrics?: ITripCountMetrics;
}

export class PaginatedTripLightList extends PaginatedList {
  @Type(() => LightList)
  content!: LightList[];
}

export class TripDestinationOption extends LoadOriginDestinationResponse {
  displayName!: string;
  value!: string;

  constructor(dto: LoadOriginDestinationResponse) {
    super();
    this.city = dto.city;
    this.state = dto.state;
    this.displayName = `${dto.city}, ${dto.state}`;
    this.value = `${dto.city};${dto.state}`;
  }
}

export class CreateTripStopResponse {
  id!: string;
  organizationId!: number;
}

export class ResequenceTripStopResponse {
  applied!: boolean;
  removableTripIdToSeqNumber!: { [key: string]: number };
  updatedTripIdToSeqNumber!: { [key: string]: number };
}

class AdjacentTripDetails {
  tripId!: string;
  seqNumber!: number;
  canAcceptStop!: boolean;
  status!: ELoadStatus;
}

type Asset = { name: string; id: number; explicit: boolean } | null;
type User = { name: string; id: string } | null;

export class TripStopResponse {
  organizationId!: number;
  id!: string;
  seqNumber!: string;
  emptyMiles!: number;
  loadedMiles!: number;
  revenue!: number;
  previousTripDetails!: AdjacentTripDetails | null;
  nextTripDetails!: AdjacentTripDetails | null;
  @Type(() => TripStopTerminal)
  terminal!: TripStopTerminal | null;
  stops!: TripStop[];
  status!: ELoadStatus;
  driverGroup?: User;
  tractor?: Asset;
  trailer?: Asset;
  dispatcher?: User;
  dispatchRevenue!: number;
}

class TripStopTerminal {
  id!: string;
  companyName!: string;
  primary!: boolean;
}

class TripStopLoadDetail {
  loadId!: string;
  seqNumber!: string;
}

class TripStopSolutionDetail {
  start: MomentInput;
  end: MomentInput;
  routeId!: string; // TODO nh
  eta: MomentInput;
}

class StopTPLocationKPIHOS {
  remainingDuty!: number;
  remainingDrive!: 308.6333333333333;
  remainingWeekly!: number;
  remainingBCP!: number;
  cumulativeRest!: number;
}

class StopTPLocationKPIBuffer {
  initialBufferMinutes!: number;
  lastBufferMinutes!: number;
}

class StopTPLocationJPIPossibleAppointmentWindows {
  start: MomentInput;
  end: MomentInput;
}

class StopTPLocationKPI {
  volutionMinutes!: number;
  earlyVolutionMinutes!: number;
  milage!: number;
  driveMinutes!: number;
  dutyMinutes!: number;
  loadMilage!: number;
  tripMilage!: number;
  @Type(() => StopTPLocationKPIHOS)
  hos!: StopTPLocationKPIHOS;
  @Type(() => StopTPLocationKPIBuffer)
  buffer!: StopTPLocationKPIBuffer;
  @Type(() => StopTPLocationJPIPossibleAppointmentWindows)
  possibleAppoinmentWindows!: StopTPLocationJPIPossibleAppointmentWindows[];
}

export enum EActionName {
  DVIR = 'DVIR',
  BREAKSTOP = 'BREAKSTOP',
  DRIVE = 'DRIVE',
  OFFDUTY = 'OFFDUTY',
  APPOINTMENTWORK = 'APPOINTMENTWORK',
}

export enum EHOSEventType {
  ON_DUTY = 'ON_DUTY',
  DRIVING = 'DRIVING',
  OFF_DUTY = 'OFF_DUTY',
  SLEEPER_BED = 'SLEEPER_BED',
}

class StopTpSolutionTimeLineItemLocation {
  lat!: number;
  lon!: number;
}

class StopTpSolutionTimeLineItem {
  actionName!: EActionName;
  location!: StopTpSolutionTimeLineItemLocation;
  locationName!: string;
  locationDetails!: null;
  nextLocation!: StopTpSolutionTimeLineItemLocation;
  nextLocationName!: string;
  start: MomentInput;
  end: MomentInput;
  duration!: number;
  hosEventType!: EHOSEventType;
}

class StopTpSolution {
  stopId!: number;
  @Type(() => TripStopSolutionDetail)
  details!: TripStopSolutionDetail;
  kpis!: StopTPLocationKPI;
  type!: 'STOP'; // TODO Anh
  markers!: null;
  timeline!: StopTpSolutionTimeLineItem[];
}

export class TripStop {
  id!: number;
  refNumber!: string | null; // TODO Anh
  @Type(() => TripStopLoadDetail)
  loadDetails!: TripStopLoadDetail[];
  appointmentStartDate!: MomentInput;
  appointmentEndDate!: MomentInput;
  estimatedTimeOfArrival!: string; // TODO Anh
  estimatedTimeOfCompletion!: MomentInput;
  actualTimeOfArrival!: MomentInput;
  actualTimeOfCompletion!: MomentInput;
  arrivalDelay?: number;
  dwellTime!: number;
  detentionTime?: number;
  totalDriveTime!: number;
  totalMiles!: number;
  location!: TripLocation;
  estimatedActivityDuration!: number;
  type!: StopSolutionV3StopType.LOAD;
  activityType?: TripStopActivityEnum;
  noteDetails!: StopSolutionV3NoteDetails;
  status?: TripStopStatusEnum;
  contact!: StopSolutionV3Contact2 | null;
  isDriverAssistRequired!: boolean;

  // Extra props
  locationId!: string | null; // TODO Anh string or number
  fixedAppointment!: boolean;
  notes!: string | null;
  @Type(() => StopTpSolution)
  stopTpSolution!: StopTpSolution | null;
}

class GetRelayStopResponseItem {
  id!: number;
  stopStatus!: TripStopStatusEnum; //"NONE",
  refNumber!: number | null; //null,
  sequenceNumber!: number; // 2,
  location!: TripLocation;
  contact!: {
    firstname: string; //"Alok",
    lastname: string; //"Alok",
    email: string | null;
    countryCode: string; //"USA",
    phone: string; //"1234567898",
    extension: string | null; //null
  };
  appointmentStartDate!: string; //"2022-09-06T17:00:00Z",
  appointmentEndDate!: string; //"2022-09-07T17:00:00Z",
  estimatedActivityDuration!: number; //0,
  isDriverAssistRequired!: boolean; // true,
  notes!: string; //"Stop Notes1",
  noteDetails!: { userId: string | null; updateTime: string | null };
  stopCategory!: string | null;
  timezone!: string | null;
  activityType!: string; //"DROPOFF",
  fixedAppointment!: boolean; //false,
  stopDetentionTime!: string | null; //null
  tripId!: string;
  revenueShare!: number;
  appointmentConfirmation?: 'YES' | 'NO' | 'CONFIRMED';
}

export class GetRelayStopResponse {
  originalRelayStop!: GetRelayStopResponseItem;
  connectedRelayStop!: GetRelayStopResponseItem;
}

export interface TripLocation {
  geoCoordinates: ICoordinates | undefined;
  id: number; // 10129,
  organizationId: number; // 326,
  axeleId: null; //null,
  locationName: string;
  fullAddress: string;
  address: string; //"PO Valley, Fort Irwin, CA, US, San Bernardino 92310",
  streetAddress: string; //"PO Valley",
  streetAddress2: string; //"",
  city: string; //"Fort Irwin",
  zipcode: string; //"92310",
  state: string; // "CA",
  lat: number | null; //null,
  lng: number | null; //null,
  contacts: {
    id: number;
    description?: string;
    contactTitle: string | null; //null,
    prefix: string | null; //null,
    suffix: string | null; // null,
    firstname: string; //"Prim Cont",
    middlename: string; // "Prim Cont",
    lastname: string; //"Prim Cont",
    countryCode: string; // "USA",
    extension: string; //"12345",
    phone: string; //"1234567898",
    email: string; //"prim@cv.com",
    isPrimary: boolean; // false,
    isAfterHours: boolean | null; // null
  }[];
  center: { lat: number; lng: number };
  timezone: string; //"America/Los_Angeles",
  notes: string; //"Stop Notes1",
  avgHandlingTime: number; //120,
  recordDate: string | null; //null,
  country: string | null; //null
  locationPreference: PreferenceType;
}

export class CheckOrAssignAssetResponse {
  conflictType!: 'ASSET_OCCUPIED' | 'ASSET_MISMATCH' | 'NONE';
  message!: string;
  driverAsset?: {
    id: number;
    name: string;
    model: string;
    assetType: 'TR' | 'TL'; // TR: Tractor | TL: Trailer
  };
}

export class TripsRevenueTotalResponse {
  totalRevenue!: number;
  totalSettlementAmount!: number;
}
