import Grid from '@mui/material/Grid';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

//@TODO: Slavik - This functionality should be combined with MultipleAutocompleteForm, this is a temporary solution

export const AutocomplateWithChip = ({
  name,
  label,
  options,
  control,
  sizes,
  defaultValue,
  fieldName,
  fieldValue,
  disabled = false,
  freeSolo = false,
  variant,
  shouldUnregister = false,
  wrapperStyles = {},
}: {
  name: string;
  label: string;
  options?: any[];
  disabled?: boolean;
  control: any;
  sizes?: {
    xs: number;
  };
  defaultValue?: any;
  fieldName?: string;
  fieldValue?: string;
  freeSolo?: boolean;
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  shouldUnregister?: ControllerProps['shouldUnregister'];
  wrapperStyles?: React.CSSProperties;
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Controller
      render={({ field, fieldState: { error } }) => {
        return (
          <Grid
            xs={sizes?.xs}
            item
            style={{ marginBottom: 20, ...wrapperStyles }}
          >
            <Autocomplete
              {...field}
              onChange={(e, newValue) => {
                field.onChange(newValue);
                setInputValue('');
              }}
              value={field.value}
              multiple
              options={options}
              disabled={disabled}
              defaultValue={defaultValue}
              freeSolo={freeSolo}
              inputValue={inputValue}
              renderTags={(value: readonly string[], getTagProps) => {
                return value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    onChange={(e) => {
                      setInputValue(e.target.value);
                    }}
                    {...params}
                    variant={variant}
                    label={label}
                    error={error?.message}
                    helperText={error?.message}
                    onBlur={() => {
                      if (inputValue !== '') {
                        field.onChange([...field.value, inputValue]);
                      }
                      setInputValue('');
                    }}
                  />
                );
              }}
            />
          </Grid>
        );
      }}
      shouldUnregister={shouldUnregister}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};
