import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';

export const ToggleButtonGroup = ({
  buttonList = [],
  onChangeList,
  selectedList = [],
  disabled,
}: ToggleButtonGroupProps) => {
  const [data, setData] = useState(buttonList);
  useEffect(() => {
    const temp = preSelected(selectedList, buttonList);
    setData(temp);
  }, [selectedList]);

  const preSelected = (sl: string[], bl: SingleData[]) => {
    const temp = [...bl];
    let tempRes: any = [];
    temp.map((item: any) => {
      const temp = {
        ...item,
      };
      if (sl.length > 0 && sl.includes(item.key)) {
        temp.variant = 'contained';
      } else {
        temp.variant = 'outlined';
      }
      tempRes = [...tempRes, temp];
    });
    return tempRes;
  };

  const onButtonClicked = (index: number) => {
    const temp = [...data];
    const currentVariant = temp[index].variant;
    temp[index] = {
      ...temp[index],
      variant: currentVariant == 'outlined' ? 'contained' : 'outlined',
    };
    setData(temp);
    const tempSelected = temp
      .map((item) => item.variant == 'contained' && item.key)
      .filter((value) => value);
    onChangeList(temp, tempSelected);
  };

  return (
    <Box>
      {data.map((item: SingleData, index: number) => (
        <ButtonImproved
          variant={item.variant}
          onClick={() => onButtonClicked(index)}
          key={index}
          startIcon={
            <IconsComponent
              iconName={
                item.variant == 'outlined'
                  ? item.disabledIconName
                  : item.enabledIconName
              }
              source={
                item.variant == 'outlined'
                  ? item.diabledSource
                  : item.enabledSource
              }
            />
          }
          label={item.label}
          styleProps={{ width: 'auto', marginLeft: 1, marginTop: 2 }}
          disabled={disabled}
        />
      ))}
    </Box>
  );
};
export interface ToggleButtonGroupProps {
  buttonList?: SingleData[];
  onChangeList?: any;
  selectedList?: string[];
  isDarkMode?: boolean;
  disabled?: boolean;
}

export interface SingleData {
  key?: string;
  label?: string;
  variant?: 'text' | 'outlined' | 'contained';
  enabledSource?: string;
  enabledIconName?: string;
  diabledSource?: string;
  disabledIconName?: string;
}
