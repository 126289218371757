export const calculateSettlementAmount = (payments: any[]) =>
  payments?.length
    ? payments
        ?.map((item: any) => Number(item.amount) || 0)
        ?.reduce((a, b) => a + b, 0)
    : 0;

export const calculateNetExpense = (
  companyExpense: boolean,
  amount: number,
  settlementAmount: number
) =>
  companyExpense
    ? Math.abs(amount) - Math.abs(settlementAmount)
    : settlementAmount;
