import StorageManager from '../StorageManager/StorageManager';

interface IProps {
  page: string | undefined;
}
const useFilterStorage = (props: IProps) => {
  const key = 'user-settings';
  const { page } = props; // @todo Anna get it automatically from url
  const get = () => {
    return StorageManager.getItem(key) &&
      StorageManager.getItem(key).pages &&
      StorageManager.getItem(key).pages[page]
      ? StorageManager.getItem(key).pages[page]
      : undefined;
  };

  //data, generic

  const update = (field: string, newData: string | object) => {
    const dataAsObj = StorageManager.getItem(key);
    const updatedData = {
      ...dataAsObj,
      pages: {
        ...dataAsObj.pages,
        [page]: {
          ...dataAsObj.pages[page],
          [field]: newData, //dinamic
        },
      },
    };
    StorageManager.setItem(key, updatedData);
  };

  return {
    getStorage: get,
    updateStorage: update,
  };
};

export default useFilterStorage;
