import { tractorService, trailerService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import {
  ChangeTrailerStatusRequest,
  CreateTrailerAlertQueryParamsRequest,
  DefaultTrailersTypesDictionarySummaryDTO,
  DefaultTrailersTypesListSummaryDTO,
  DefaultTrailersTypesSummaryDTO,
  DriverTrailerAssignRequest,
  OptionType,
  PaginatedTrailerList,
  PaginatedTrailerListRequest,
  PaginatedTrailerListResponse,
  QueryParams,
  TrailerAlertQueryParamsRequest,
  TrailerAlertSummaryDTO,
  TrailerResponse,
  UpdateTrailerAlertListRequest,
} from '../../models';
import { GridColDefSelf, PaginatedResult } from '../../types';
import {
  fromStringToOptionType,
  getHttpErrorsFromResponse,
  gridPageSize,
  prepareFiltersFromList,
} from '../../utils';
import TerminalController from '../../views/settings/terminals/TerminalController';
import TractorController from '../tractors/Controller';
import { Trailer } from './models/Trailer';
import { TrailerSummary } from './models/TrailerSummary';

let instance: TrailerController | null = null;

export default class TrailerController {
  static instance() {
    if (instance === null) {
      instance = new TrailerController();
    }
    return instance;
  }

  static destroy() {
    instance = null;
  }

  total!: number;
  lastPage: boolean;
  trailerTypeOptions: OptionType[];
  listFetching: boolean;
  trailerListRequest: PaginatedTrailerListRequest;
  trailerList: TrailerSummary[];
  gettingTrailerStatic!: boolean;
  trailerAlertList!: TrailerAlertSummaryDTO[];
  defaultTrailersTypesList!: DefaultTrailersTypesListSummaryDTO[];
  defaultTrailersTypesDictionary!: DefaultTrailersTypesDictionarySummaryDTO[];
  filteredDefaultTrailersTypes!: DefaultTrailersTypesSummaryDTO[];

  constructor() {
    this.lastPage = false;
    this.listFetching = false;
    this.trailerList = [];
    this.trailerTypeOptions = [];
    this.trailerListRequest = this.setupTrailersListRequest();
    this.defaultTrailersTypesList = [];
    this.defaultTrailersTypesDictionary = [];
  }

  private async getTrailerStaticData(): Promise<void> {
    if (this.gettingTrailerStatic) return;
    this.gettingTrailerStatic = true;
    const trailerStatic = await trailerService.getTrailerStaticData();
    this.gettingTrailerStatic = false;
    this.trailerTypeOptions = trailerStatic.trailerType;
  }

  async getTrailerTypeOptions(): Promise<OptionType[]> {
    if (!this.trailerTypeOptions.length) {
      await this.getTrailerStaticData();
    }
    return this.trailerTypeOptions;
  }

  setupTrailersListRequest(): PaginatedTrailerListRequest {
    const req = new PaginatedTrailerListRequest();
    req.statusFilter = ['ASSIGNED', 'AVAILABLE'];
    req.pageSize = gridPageSize;
    req.sort = '+trailerName';
    return req;
  }

  async getTrailerByNameExtendedSearch(
    searchText: string | undefined,
    pageNumber: number,
    statusList: string[],
    terminalIds?: string[]
  ): Promise<PaginatedResult<OptionType> | undefined> {
    const response = await trailerService.getTrailerByNameSearchExtendedList(
      searchText,
      pageNumber,
      statusList,
      terminalIds
    );
    return response;
  }

  async getTrailerByNameSearch(
    searchText: string | undefined,
    pageNumber: number,
    excludeStatusList: string[] = []
  ): Promise<PaginatedResult<OptionType> | undefined> {
    const response = await tractorService.getTrailerByNameSearchList(
      searchText,
      pageNumber,
      excludeStatusList
    );
    response.content = fromStringToOptionType(response.content);
    return response;
  }

  async getTrailerLightListByNameSearch(
    searchText: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    excludeStatusList: string[] = []
  ): Promise<PaginatedResult<OptionType> | undefined> {
    try {
      return await tractorService.getTrailerLightListByNameSearch(
        searchText,
        pageNumber,
        terminalIds,
        excludeStatusList
      );
    } catch (err) {
      return;
    }
  }

  async getVinList(
    searchText: string | undefined,
    pageNumber: number
  ): Promise<PaginatedResult<OptionType> | undefined> {
    // @TODO: Anoush check in the custom view
    const response = await tractorService.getTrailerVinList(
      searchText,
      pageNumber
    );
    response.content = fromStringToOptionType(response?.content);
    return response;
  }

  async getLicensePlateList(
    searchText: string | undefined,
    pageNumber: number
  ): Promise<PaginatedResult<OptionType> | undefined> {
    const response = await tractorService.getTrailerLicensePlateList(
      searchText,
      pageNumber
    );
    response.content = fromStringToOptionType(response.content);
  }

  updateTrailerListRequestWithFilters(newFilters, paging: number) {
    const req = this.trailerListRequest;
    if (!paging) {
      req.pageNumber = 1;
    }
    if (newFilters.sort) {
      req.sort = newFilters.sort;
    }
    req.driverGroupFilter = prepareFiltersFromList(
      newFilters.driverGroupFilter,
      'key'
    );
    req.vinFilter = prepareFiltersFromList(newFilters.vinFilter, 'value');
    req.licensePlateFilter = prepareFiltersFromList(
      newFilters.licensePlateFilter,
      'value'
    );
    req.nameFilter = prepareFiltersFromList(newFilters.nameFilter, 'value');
    req.safetyIssueFilter = prepareFiltersFromList(
      newFilters.safetyIssueFilter,
      'key'
    );

    req.statusFilter = prepareFiltersFromList(newFilters.statusFilter, 'key');
    req.inTransitFilter = prepareFiltersFromList(
      newFilters.inTransitFilter,
      'key'
    );
    req.regExpiryFrom = newFilters.regExpiry?.dateRange?.[0];
    req.regExpiryTo = newFilters.regExpiry?.dateRange?.[1];
  }

  async getTrailerList(
    filters,
    getGlobalTerminalsIds,
    callback: (data: TrailerSummary[]) => void,
    paging = 0
  ): Promise<void> {
    if ((paging && this.lastPage) || this.listFetching) return;
    this.updateTrailerListRequestWithFilters(filters, paging);
    this.trailerListRequest.pageNumber += paging;
    this.trailerListRequest.getParamsFromRequest();
    this.trailerListRequest.terminalIds = getGlobalTerminalsIds;
    this.listFetching = true;
    const data = await trailerService.getPaginatedTrailerList(
      this.trailerListRequest
    );
    this.listFetching = false;
    if (data instanceof PaginatedTrailerListResponse) {
      this.lastPage = data.last;
      this.total = data.totalElements;
      const trailers = data?.content?.map((item) => {
        return new TrailerSummary(item);
      });
      this.trailerList = paging ? [...this.trailerList, ...trailers] : trailers;

      callback(this.trailerList);
    }
  }

  async getTrailerListExport(
    callback: (data: any) => void,
    tableColumns: GridColDefSelf[]
  ): Promise<void> {
    const gridColumnMetadataList: string[] = [];
    tableColumns.forEach((eachColumn: GridColDefSelf) => {
      gridColumnMetadataList.push(eachColumn.field);
    });
    this.trailerListRequest.gridColumnMetadataList = gridColumnMetadataList;
    const data = await trailerService.getPaginatedTrailerListExport(
      this.trailerListRequest
    );
    if (data instanceof PaginatedTrailerList) {
      callback(data);
      this.trailerListRequest.gridColumnMetadataList = null;
    }
  }

  async getTrailerById(
    id: string,
    callback: (data: Trailer) => void
  ): Promise<void> {
    const data = await trailerService.getTrailerById(id);
    if (data instanceof TrailerResponse) {
      callback(new Trailer(data));
    }
  }

  async createTrailer(
    tractor: TrailerSummary,
    callback: (data: TrailerResponse | null, validationErr?: any) => void
  ): Promise<void> {
    const data = await trailerService.createTrailer(tractor);
    if (data instanceof TrailerResponse) {
      data.terminal = TerminalController.instance().terminalById(
        data.terminalId
      );
      TractorController.instance().equipmentCount?.increaseTrailer();
      this.total += 1;
      callback(data);
    } else if (data instanceof ServiceError) {
      callback(null, getHttpErrorsFromResponse(data));
    }
  }

  async updateTrailer(
    tractor: any,
    callback: (data: TrailerResponse | null, validationErr?: any) => void
  ): Promise<void> {
    const data = await trailerService.updateTrailer(tractor);
    if (data instanceof TrailerResponse) {
      data.terminal = TerminalController.instance().terminalById(
        data.terminalId
      );
      callback(data);
    } else if (data instanceof ServiceError) {
      callback(null, getHttpErrorsFromResponse(data));
    }
  }

  async verifyTrailerDeletion(
    id: string,
    callback: (canDelete: {
      assetIsAllowedToDelete: ServiceError | boolean;
      message: string;
    }) => void
  ) {
    const canDelete = await trailerService.verifyTrailerDeletion(id);
    if (!(canDelete instanceof ServiceError)) {
      callback(canDelete);
    }
  }

  async deleteTrailerById(
    id: string,
    callback: (data: ServiceError | null) => void
  ) {
    const deleted = await trailerService.deleteTrailerById(id);
    if (!(deleted instanceof ServiceError)) {
      TractorController.instance().equipmentCount?.decreaseTrailer();
      this.total -= 1;
      callback(null);
    }
  }

  async driverTrailerAssign(driverGroupId: string, trailerId: number) {
    try {
      return await trailerService.driverTrailerAssign(
        new DriverTrailerAssignRequest({
          driverGroupId,
          trailerId,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  async driverTrailerUnAssign(driverGroupId: string) {
    try {
      return await trailerService.driverTrailerUnAssign(
        new DriverTrailerAssignRequest({
          driverGroupId,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  async changeTrailerStatus(trailerId: number, status: string) {
    try {
      return await trailerService.changeTrailerStatus(
        new ChangeTrailerStatusRequest({ trailerId, status })
      );
    } catch (error) {
      console.error(error);
    }
  }

  //#region  TrailerAlerts

  getTrailerAlertList = async (
    id: number,
    callback: (data: TrailerAlertSummaryDTO[]) => void
  ): Promise<void> => {
    const requestData = new TrailerAlertQueryParamsRequest(id);
    const data = await trailerService.getTrailerAlertList(requestData);

    if (!data || data instanceof ServiceError) {
      callback([]);
    } else {
      this.trailerAlertList = data;
      callback(data);
    }
  };

  async createTrailerAlert(
    trailerAlert: TrailerAlertSummaryDTO,
    callback: (data: TrailerAlertSummaryDTO) => void
  ): Promise<void> {
    const requestData = new CreateTrailerAlertQueryParamsRequest(trailerAlert);
    const alertData = await trailerService.createTrailerAlert(requestData);

    if (!alertData || alertData instanceof ServiceError) {
      return;
    } else {
      callback(alertData);
    }
  }

  async updateTrailerAlertsBulk(
    trailerAlertsList: TrailerAlertSummaryDTO[],
    callback: (data: TrailerAlertSummaryDTO[]) => void
  ): Promise<void> {
    const updatedTrailerAlertsList = trailerAlertsList?.map(
      (alert: TrailerAlertSummaryDTO) =>
        new UpdateTrailerAlertListRequest(alert)
    );

    const alertData = await trailerService.updateTrailerAlertsBulk(
      updatedTrailerAlertsList
    );

    if (!alertData || alertData instanceof ServiceError) {
      return;
    } else {
      callback(alertData);
    }
  }

  getDefaultTrailersTypesList = async (): Promise<void> => {
    const requestData = new QueryParams();
    const data = await trailerService.getDefaultTrailersTypesList(requestData);

    if (!data || data instanceof ServiceError) {
      return;
    } else {
      this.defaultTrailersTypesList = data;
    }
  };

  getDefaultTrailersTypesDictionary = async (): Promise<void> => {
    const requestData = new QueryParams();
    const data = await trailerService.getDefaultTrailersTypesDictionary(
      requestData
    );

    if (!data || data instanceof ServiceError) {
      return;
    } else {
      this.defaultTrailersTypesDictionary = data;
    }
  };

  async deleteTrailerAlertById(
    id: string,
    callback: (data: ServiceError | null) => void
  ) {
    const deleted = await trailerService.deleteTrailerAlertById(id);
    if (!(deleted instanceof ServiceError)) {
      callback(null);
    }
  }

  async markAsInactive(
    id: string,
    callback: (data: ServiceError | boolean) => void
  ) {
    const response = await trailerService.markAsInactive(id);
    if (!(response instanceof ServiceError)) {
      if (response.hasErrors) {
        callback(false);
      } else {
        callback(true);
      }
    }
  }
}
