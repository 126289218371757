export const UserConstants = {
  userType: 'User Type',
  webUserRole: 'Web User Role',
  driverRole: 'Driver Role',
  noUsersFound: 'No users found :’(',
  noUsersFoundSubtitle:
    'Looks like you need to add some user in to the system. Import or get started with adding Users and Drivers.',
  sendInvitation: 'Send Invitation',
  confirmInvitation: 'Are you sure you want to send invitation to this user?',
  cancelInvitation: 'Cancel Invitation',
  confirmCancel: 'Are you sure you want to cancel the user invitation?',
  deactivateSubtitle:
    'Please note that driver will be non-visible for making assignments.',
  confirmDeactivate: 'Are you sure you want to deactivate the user?',
  warningSubtitle:
    'You cannot deactivate the driver as the driver is either part of a team or there are non-completed assignments and/or assigned tractor/trailer available.',
  confirmActivate: 'Are you sure you want to activate this user?',
  confirmDelete: 'Are you sure you want to delete driver?',
  deleteSubtitle: 'This will be deleted from all places',
  userRemoveSubtitle:
    'User cannot be removed as the profile is connected to either Settlement, Maintenance, or Expense',
  driverRemoveSubtitle:
    'Driver cannot be removed as the profile is connected to either Settlement, Maintenance, Expense, Load, Equipment or Driver Group',
  currentPasswordRequired: 'Current Password is a required field',
  newPasswordRequired: 'New Password is a required field',
  requiredDigits: 'Minimum 6 digits are required.',
  reenterPasswordRequired: 'Re-Enter New Password is a required field',
};
