import { PreferencesDTO } from '../../../models/DTOs/Preferences/Preferences';

export const InvoiceTermsOptions = [
  { label: 'Due on Receipt', key: 'DUE_ON_RECEIPT' },
  { label: 'Net 15 days', key: 'NET_15' },
  { label: 'Net 30 days', key: 'NET_30' },
  { label: 'Net 45 days', key: 'NET_45' },
  { label: 'Custom', key: 'CUSTOM' },
];

export const SettlementPeriodLengthOptions = [
  { label: '1 Week', key: 'WEEK_1' },
  { label: '2 Week', key: 'WEEK_2' },
  { label: '3 Week', key: 'WEEK_3' },
  { label: '4 Week', key: 'WEEK_4' },
];

export enum EStopActivitiesAutofullOption {
  CURRENT_DATE_TIME = 'CURRENT_DATE_TIME',
  APPOINTMENT_DATE_TIME = 'APPOINTMENT_DATE_TIME',
}

export const ActivityAutofillOptionforLoadStopOptions = [
  {
    label: 'Current date & time',
    key: EStopActivitiesAutofullOption.CURRENT_DATE_TIME,
  },
  {
    label: 'Appointment date & time',
    key: EStopActivitiesAutofullOption.APPOINTMENT_DATE_TIME,
  },
];

export const replyToOptions = [
  { label: 'Sender', key: 'SENDER' },
  { label: 'Owner Admin', key: 'OWNER' },
];

export const DVIRPreferenceOptions = [
  { label: 'Pre Trip', key: 'PRE_TRIP' },
  { label: 'None', key: 'NONE' },
];

export const DVIRDriverPreferenceOptions = [
  { value: 'Pre Trip', key: 'PRE_TRIP' },
  { value: 'None', key: 'NONE' },
];
export const WorkWeekTypeOptions = [
  { label: '60 hours/7 days', key: 'D7_H60' },
  { label: '70 hours/8 days', key: 'D8_H70' },
  { label: '70 hours/7 days (Texas)', key: 'D7_H70_TX' },
];

export const PrefferedTruckStopsOptions = [
  { label: 'Loves', key: 'LOVE' },
  { label: 'Pilot Flying J', key: 'FLYINGJ' },
  { label: 'TA Petro', key: 'TA_PETRO' },
  { label: 'Govt. Rest Areas', key: 'GOVT' },
];

export const DefaultTimeZoneOptions = [
  { label: 'America/New York (EST)', key: 'America/New_York' },
  { label: 'America/Chicago (CST)', key: 'America/Chicago' },
  { label: 'America/Denver (MST)', key: 'America/Denver' },
  { label: 'America/Los Angeles (PST)', key: 'America/Los_Angeles' },
  { label: 'America/Phoenix (MST)', key: 'America/Phoenix' },
];

export const WorkStartWeekOptions = [
  { label: 'Monday', key: 'MONDAY' },
  { label: 'Tuesday', key: 'TUESDAY' },
  { label: 'Wednesday', key: 'WEDNESDAY' },
  { label: 'Thursday', key: 'THURSDAY' },
  { label: 'Friday', key: 'FRIDAY' },
  { label: 'Saturday', key: 'SATURDAY' },
  { label: 'Sunday', key: 'SUNDAY' },
];

export const DefaultLanguageOptions = [{ label: 'English', key: 'en' }];

export const navigationTabStrip = [
  {
    iconName: 'RequestQuoteOutlined',
    source: 'MUIcons',
    label: 'Finance',
    key: 'FinancialGoalsHeader',
  },
  {
    iconName: 'ReceiptLongOutlined',
    source: 'MUIcons',
    label: 'Invoicing',
    key: 'InvoiceAndSettlementHeader',
  },
  {
    // @TODO temp icon should be removed once IconComponent will be removed
    iconName: 'LoadsIconTemp',
    source: 'AxeleIcon',
    label: 'Loads',
    key: 'LoadsHeader',
  },
  {
    iconName: 'PersonOutlined',
    source: 'MUIcons',
    label: 'Driver',
    key: 'DriverHeader',
  },
  {
    iconName: 'ApartmentOutlined',
    source: 'MUIcons',
    label: 'Company Info',
    key: 'CompanyInfoHeader',
  },
  {
    iconName: 'SettingsOutlined',
    source: 'MUIcons',
    label: 'Misc.',
    key: 'MiscHeader',
  },
];

export const defaultData: PreferencesDTO = {
  apiKey: '',
  apiKeyStatus: false,
  whiteListURLs: [],
  variableCostPerMile: 1.7,
  fixedCostPerDay: 0,
  invoiceTerm: 'DUE_ON_RECEIPT',
  invoiceTermValue: '',
  loadPrefix: '',
  manifestPrefix: '',
  invoicePrefix: '',
  seqNumber: 1,
  settlementDate: null,
  settlementPeriod: 'WEEK_1',
  stopActivitiesAutofillOption: EStopActivitiesAutofullOption.CURRENT_DATE_TIME,
  trailerType: null,
  collectLoadUnloadTiming: false,
  calculateDetention: false,
  billOfLadingMandatory: true,
  proofOfDeliveryMandatory: true,
  documentsMandatory: false,
  timezone: 'America/New_York',
  workWeekStartDay: 'MONDAY',
  replyTo: 'OWNER',
  locale: 'en',
  dashboardPreferences: {
    hideFinancialKPIs: false,
    targetRevenuePerTotalMile: 2.5,
    targetRevenuePerLoadedMile: 3,
    targetWeeklyRevenue: 50000,
  },
  driverPreferences: {
    dvirPreferences: 'PRE_TRIP',
    workWeekType: 'D8_H70',
    preferredTruckstops: null,
    ignoreHOSRestrictions: false,
    useSleeperBerthProvision: false,
  },
  companyDetails: {
    name: '',
    countryCode: 'USA',
    phone: '',
    extension: '',
    faxCountryCode: 'USA',
    fax: null,
    faxExtension: null,
    email: '',
    addressData: {
      address: '',
      streetAddress: '',
      streetAddress2: '',
      city: '',
      state: '',
      zipcode: '',
    },
  },
  defaultAverageSpeed: 55,
};

export const errorText: IerrorText = {
  generalError: 'Please enter proper input',
  requiredError: 'is required',
  charToNumberError: 'Please enter a number',
  fixedCostPerDay: 'Value must be between 0 and 999,999.9999',
  seqNumber: 'Value must be between 1 and 999,999,999',
  variableCostPerMile: 'Value must be between 0.0001 and 99.9999',
  targetRevenuePerLoadedMile: 'Value must be between 0.0001 and 99.9999',
  targetRevenuePerTotalMile: 'Value must be between 0.0001 and 99.9999',
  targetWeeklyRevenue: 'Value must be between 1.0000 and 1,000,000,000.9999',
  name: 'Cannot be longer than 255 characters',
  logo: 'Logo must be an image(jpeg/png) file of size 1MB or less and not wider then 1200px',
  companyPhone: 'The phone number must be exactly 10 digits',
  companyFax: 'The fax number must be exactly 10 digits',
  email: 'Email is not valid',
  loadPrefix: 'Cannot be longer than 11 characters',
  manifestPrefix: 'Cannot be longer than 11 characters',
  invoicePrefix: 'Cannot be longer than 11 characters',
  invoiceTermValue: 'Value must be between 1 and 50',
  invoiceTerm: 'Invoice Terms is required',
  settlementPeriod: 'Settlement Period Length is required',
  stopActivitiesAutofillOption:
    'Activity Autofill Option for Load Stop is required',
  trailerType: 'Activity Autofill Option for Load Stop is required',
  dvirPreferences: 'DVIR Preference is required',
  workWeekType: 'Work Week Type is required',
  timezone: 'Default Time Zone is required',
  workWeekStartDay: 'Work Start Week is required',
  locale: 'Default Language is required',
  defaultAverageSpeed: 'Value must be between 40 and 100',
};

export interface IerrorText {
  generalError: string;
  requiredError: string;
  charToNumberError: string;
  fixedCostPerDay: string;
  seqNumber: string;
  variableCostPerMile: string;
  targetRevenuePerLoadedMile: string;
  targetRevenuePerTotalMile: string;
  targetWeeklyRevenue: string;
  name: string;
  logo: string;
  companyPhone: string;
  companyFax: string;
  email: string;
  loadPrefix: string;
  manifestPrefix: string;
  invoicePrefix: string;
  invoiceTermValue: string;
  invoiceTerm: string;
  settlementPeriod: string;
  stopActivitiesAutofillOption: string;
  trailerType: string;
  dvirPreferences: string;
  workWeekType: string;
  timezone: string;
  workWeekStartDay: string;
  locale: string;
  defaultAverageSpeed: string;
}

export const prefPageErrors = {
  variableCostPerMile: false,
  fixedCostPerDay: false,
  invoiceTerm: false,
  invoiceTermValue: false,
  customInvoiceTerms: false,
  loadPrefix: false,
  manifestPrefix: false,
  invoicePrefix: false,
  seqNumber: false,
  settlementDate: false,
  settlementPeriod: false,
  stopActivitiesAutofillOption: false,
  trailerType: false,
  collectLoadUnloadTiming: false,
  calculateDetention: false,
  billOfLadingMandatory: false,
  proofOfDeliveryMandatory: false,
  documentsMandatory: false,
  timezone: false,
  workWeekStartDay: false,
  locale: false,
  hideFinancialKPIs: false,
  targetRevenuePerTotalMile: false,
  targetRevenuePerLoadedMile: false,
  targetWeeklyRevenue: false,
  dvirPreferences: false,
  workWeekType: false,
  preferredTruckstops: false,
  ignoreHOSRestrictions: false,
  useSleeperBerthProvision: false,
  name: false,
  logo: false,
  companyPhone: false,
  companyFax: false,
  email: false,
  address: false,
  apiKey: false,
  defaultAverageSpeed: false,
};

export const countryCodeOptions = [{ label: '+1', key: 'USA' }];
