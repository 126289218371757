import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import { FC, useState } from 'react';
import { OptymLogo } from '../../../ui-kit/components/Assets';
import CustomModal from '../../Modal';
import SearchContainer from '../../SearchContainer';
import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_TABLET,
} from '../../hooks/useThemeResponsive';
import NoSearchResult from './NoSearchResult';
import {
  closeButtonStyle,
  containerStyle,
  footerStyle,
  headerStyle,
  linkColor,
  searchContainer,
  searchIconStyle,
  textBoxInputPropsStyle,
  textColor,
  textFieldStyle,
  textStyles,
} from './style';

function getFooter() {
  return (
    <Box
      sx={(theme) => ({
        ...footerStyle,
        [theme.breakpoints.down(BREAKPOINT_LAPTOP)]: {
          width: '100%',
          height: 'auto',
          pb: 1,
          borderRadius: 0,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          [theme.breakpoints.down(BREAKPOINT_LAPTOP)]: {
            display: 'inline-table',
          },
        })}
      >
        <Typography
          component={'span'}
          sx={[
            textStyles,
            textColor,
            {
              letterSpacing: 'unset',
            },
          ]}
        >
          Cant find what you are looking for? Check our&nbsp;
        </Typography>
        <Box component={'span'}>
          <Link
            sx={[textStyles, linkColor]}
            href="https://help.loadops.com/faqs"
            target="_blank"
          >
            FAQ page
          </Link>
        </Box>

        <Typography component={'span'} sx={[textStyles, textColor]}>
          &nbsp;or contact our&nbsp;
        </Typography>
        <Box component={'span'}>
          <Link
            sx={[textStyles, linkColor]}
            href="https://help.loadops.com/kb-tickets/new"
            target="_blank"
          >
            Support Team
          </Link>
        </Box>
      </Box>
      <Box>
        <OptymLogo />
      </Box>
    </Box>
  );
}

function getHeader(
  onSearch: any,
  clear: () => void,
  onClose: () => void,
  setSearchValue: (value: string) => void,
  setStartLoading: (value: boolean) => void,
  searchValue: string
) {
  const handleClose = () => {
    onClose(), setSearchValue(''), clear(), onSearch('');
  };

  return (
    <Box
      className="SearchModalRoot"
      sx={(theme) => ({
        ...headerStyle,
        [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
          borderRadius: 0,
        },
      })}
    >
      <Box className="SearchModalContainer" sx={searchContainer}>
        <SearchIcon style={searchIconStyle} />
        <TextField
          defaultValue={searchValue}
          autoFocus
          onChange={debounce((e) => {
            if (e.target.value && e.target.value.length > 1) {
              onSearch(e.target.value);
            } else {
              setSearchValue('');
              onSearch('');
              setStartLoading(false);
              clear();
            }
            setSearchValue(e.target.value);
          }, 300)}
          placeholder="Search"
          sx={textFieldStyle}
          InputProps={{ style: textBoxInputPropsStyle }}
        />
      </Box>
      <Box>
        <Button
          onClick={handleClose}
          style={closeButtonStyle}
          variant="outlined"
        >
          Esc
        </Button>
      </Box>
    </Box>
  );
}

interface SearchModalProps {
  data: any;
  clear: () => void;
  onClose: () => void;
  onSearch: (value: string) => void;
  show: boolean;
  onSearchItemClicked: any;
  isLoading: boolean;
  setStartLoading: (value: boolean) => void;
}

const SearchModal: FC<SearchModalProps> = ({
  data,
  clear,
  onClose,
  onSearch,
  show,
  onSearchItemClicked,
  isLoading,
  setStartLoading,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleClose = () => {
    onClose(), setSearchValue(''), clear(), onSearch('');
  };

  return (
    <CustomModal
      header={getHeader(
        onSearch,
        clear,
        onClose,
        setSearchValue,
        setStartLoading,
        searchValue
      )}
      container={
        <>
          {isLoading ? (
            <Box
              sx={containerStyle}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {' '}
              <CircularProgress />
            </Box>
          ) : (
            <Box
              className="SearchModalSearchContainer"
              sx={(theme) => ({
                ...containerStyle,
                [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
                  height: '100%',
                },
              })}
            >
              {data && data.resultList && data.resultList.length > 0 && (
                <SearchContainer
                  searchData={data}
                  onClose={handleClose}
                  onSearchItemClicked={onSearchItemClicked}
                ></SearchContainer>
              )}
              {(!data ||
                searchValue?.length === 0 ||
                (data.resultList?.length === 0 && searchValue.length > 0)) && (
                <NoSearchResult searchValue={searchValue} />
              )}
            </Box>
          )}
        </>
      }
      footer={getFooter()}
      show={show}
      onClose={handleClose}
    ></CustomModal>
  );
};

export default SearchModal;
