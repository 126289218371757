import { Box, Button, Skeleton, Stack, Theme, useTheme } from '@mui/material';

import { AddOutlined } from '@mui/icons-material';
import {
  GridCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EventTypes } from '../EventEmitter/EventTypes';
import { EventHoldLoadActionData } from '../EventEmitter/Events/EventHoldLoadAction';
import { LoadTripActionData } from '../EventEmitter/Events/EventLoadTripAction';
import { EventTripStopActionData } from '../EventEmitter/Events/EventTripStopAction';
import { IOpenPopupEventProps } from '../common/Appointment';
import CommonDataGrid from '../common/DataGridPro';
import { DirtyDetailsPanelManager } from '../common/DetailsPanel/utils';
import {
  ELoadStatus,
  EMyLoadDetailsPanelTabs,
} from '../common/LoadTabPanel/constants/constants';
import SecondaryDetailsPanel from '../common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import { getSortOption } from '../common/Sorting/sortingUtils';
import CustomViewsV2 from '../common/Views/customViewsV2';
import { useFilterDataGrid } from '../common/hooks/useFilterDataGrid';
import { useLoadPermission } from '../common/hooks/useLoadPermission';
import { useThemeResponsive } from '../common/hooks/useThemeResponsive';
import { persistUserState } from '../constants';
import { EQUIPMENT_PANEL_TABS } from '../constants/contacts';
import { LoadDetailsResponse, Trip } from '../models';
import useFilterStorage from '../services/storage';
import { useRootStore } from '../store/root-store/rootStateContext';
import { useStores } from '../store/root.context';
import { ECarrierPanelTab } from '../subPages/carriers/constants/types';
import { View } from '../types';
import { GridColDefSelf } from '../ui-kit/components/DataGridPro';
import { FiltersWrapper } from '../ui-kit/components/DataGridPro/filter/FiltersWrapper';
import { downloadFile } from '../utils/Doc';
import { onColumnOrderChange } from '../utils/gridColumnOrdering';
import useEmit from '../utils/hooks/useEmit';
import { CreateEmptyTrip } from '../views/dispatch2/components/Trip/emptyTrip/createEmptyTrip';
import { PANEL_FIXED_WIDTH } from '../views/dispatch2/constants/dispatch';
import { ESecondaryDetailsPanelType } from '../views/dispatch2/constants/types';
import { IFiltersFormConfig } from '../views/maintenanceRecords/constants/types';
import TripGridConfig from '../views/trips/components/TripDataGrid/grid.config';
import TripDetailsPanel from '../views/trips/components/TripDetailsPanel/TripDetailsPanel';
import ViewForm from '../views/trips/components/ViewSection/ViewForm';
import {
  EEntityList,
  allTrips,
  defaultViews,
  formDefaultValuesMap,
} from '../views/trips/components/ViewSection/constants';
import {
  debounceTime,
  tripFieldsConfig,
} from '../views/trips/constants/constants';
import { ITripFilters } from '../views/trips/constants/types';
import { exportToExcel } from '../views/trips/services/api.utils';
import { getStyledTable } from '../views/trips/styles';

const component = 'Trips';
const dictionary = 'asset';

const TripListPage = () => {
  const theme = useTheme();
  const { isTablet } = useThemeResponsive();
  const { isTerminalEnabled, getGlobalTerminals, companyPreferences } =
    useRootStore();
  const { tripsStore } = useStores();
  const [sortRequestData, setSortRequestData] = useState({});
  const [allColumns, setAllColumns] = useState<GridColDefSelf[]>([]);
  const [tableColumns, setTableColumns] = useState<GridColDefSelf[]>([]);
  const [openEmptyTripDialog, setOpenEmptyTripDialog] =
    useState<boolean>(false);
  const {
    hasFinancialInvoicesView,
    hasFinancialPaymentView,
    hasCarrierViewPermission,
  } = useLoadPermission();
  const { tableList, filters, selectedItem, handleHoldOrReleaseLoadLocally } =
    tripsStore;
  const currentView = useMemo(
    () => tripsStore.currentView,
    [tripsStore.currentView]
  );
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [secondaryPanelData, setSecondaryPanelData] = useState<{
    type: keyof typeof ESecondaryDetailsPanelType;
    id: string | number;
    isGlobal?: boolean;
    defaultTab?: string;
  } | null>(null);
  const stripId = currentView?.id;
  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};
  const gridConfig = new TripGridConfig({
    theme,
    timezone: companyPreferences?.timezone,
  });
  const getFilters = gridConfig.config
    .getFiltersFormConfig()
    .filter((item: any) => {
      if (item.name === tripFieldsConfig.brokerageTrip.fieldFilterName)
        return hasCarrierViewPermission;
      if (
        item.name === tripFieldsConfig.carrierDetails.fieldFilterName ||
        item.name == tripFieldsConfig.canBeBrokered.fieldName
      )
        return hasCarrierViewPermission;
      return true;
    });
  const getFiltersFormConfig: IFiltersFormConfig[] = getFilters;

  const allColumnsConfig = useMemo(
    () =>
      gridConfig.config.columns.filter((item: GridColDefSelf) => {
        if (item.field == tripFieldsConfig.terminal.fieldName)
          return isTerminalEnabled;
        if (item.field == tripFieldsConfig.revenue.fieldName)
          return hasFinancialInvoicesView;
        if (item.field == tripFieldsConfig.settlementAmount.fieldName)
          return hasFinancialPaymentView;
        if (item.field == tripFieldsConfig.carrierDetails.fieldName)
          return hasCarrierViewPermission;
        if (
          item.field == tripFieldsConfig.brokerageTrip.fieldName ||
          item.field == tripFieldsConfig.canBeBrokered.fieldName
        )
          return hasCarrierViewPermission;
        return true;
      }),
    [
      isTerminalEnabled,
      hasFinancialInvoicesView,
      hasFinancialPaymentView,
      hasCarrierViewPermission,
      hasCarrierViewPermission,
    ]
  );
  const defaultFilters = useMemo(() => {
    return (
      persistUserState.pages.Trips[stripId]?.filters || currentView.metaData
    );
  }, [stripId, currentView.id]);
  const {
    setDefaultColumns,
    setDefaultFilters,
    onPageFilterChange,
    onAllFilterSubmit,
    onAllFilterReset,
    resetSettings,
    handleChangeColumns,
  } = useFilterDataGrid<ITripFilters>({
    component,
    allColumns: allColumnsConfig,
    currentView: tripsStore.currentView,
    setTableColumns,
    setAllColumns,
    setFilters: (filters) => {
      tripsStore.setFilters(filters);
    },
    defaultFilters,
    currentViewPrimaryKey: 'id',
  });

  const handleOpenPopup = ({
    data,
    params,
  }: IOpenPopupEventProps<Trip>): void => {
    if (data?.row?.isPinnedRows) return;
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    if (
      params?.field === tripFieldsConfig.driver.fieldName &&
      data?.groupDetails?.id
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.DISPATCH,
        id: data?.groupDetails?.id,
        tripId: params.id,
      });
      tripsStore.setSelectedItem(null);
    } else if (
      params?.field === tripFieldsConfig.dispatcher.fieldName &&
      data?.dispatcherId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.USER,
        id: data?.dispatcherId,
        tripId: params.id,
      });
      tripsStore.setSelectedItem(null);
    } else if (
      params?.field === tripFieldsConfig.tractor.fieldName &&
      data?.assignment?.tractorId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.TRACTOR,
        id: data?.assignment?.tractorId,
        defaultTab: EQUIPMENT_PANEL_TABS.GENERAL,
        tripId: params.id,
      });
      tripsStore.setSelectedItem(null);
    } else if (
      params?.field === tripFieldsConfig.trailer.fieldName &&
      data?.assignment?.trailerId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.TRAILER,
        id: data?.assignment?.trailerId,
        defaultTab: EQUIPMENT_PANEL_TABS.GENERAL,
        tripId: params.id,
      });
      tripsStore.setSelectedItem(null);
    } else if (
      params?.field === tripFieldsConfig.loadId.fieldName &&
      data?.connectedLoads?.[0]?.loadId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.LOAD,
        id: data?.connectedLoads?.[0]?.loadId,
        defaultTab: EMyLoadDetailsPanelTabs.ROUTES,
        tripId: params.id,
      });
      tripsStore.setSelectedItem(null);
    } else if (
      params?.field === tripFieldsConfig.manifestId.fieldName &&
      data?.manifestBaseDetails?.manifestId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.LOAD,
        id: data?.manifestBaseDetails?.manifestId,
        defaultTab: EMyLoadDetailsPanelTabs.ROUTES,
        tripId: params.id,
      });

      tripsStore.setSelectedItem(null);
    } else if (
      params?.field === tripFieldsConfig.carrierDetails.fieldName &&
      data?.carrierDetails?.id
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.CARRIER,
        id: data?.carrierDetails?.id,
        defaultTab: ECarrierPanelTab.OVERVIEW,
        tripId: params.id,
      });
      tripsStore.setSelectedItem(null);
    } else {
      tripsStore.setSelectedItem(data);
      setSecondaryPanelData(null);
    }
  };

  const exportToExcelHandler = useCallback(async (): Promise<void> => {
    const terminalIds = isTerminalEnabled
      ? getGlobalTerminals.map(({ id }: { id: string }) => id)
      : [];
    exportToExcel({
      filters,
      gridColumnMetadataList: tableColumns.reduce(
        (result: string[], c: GridColDefSelf) => {
          if (c.field !== 'warning') result.push(c.field);
          return result;
        },
        []
      ),
      terminalIds,
    });
  }, [filters, getGlobalTerminals, isTerminalEnabled, tableColumns]);

  const debouncedChangeHandler = useCallback(
    debounce(
      ({
        nextPageNumber,
        nextFilters,
      }: {
        nextPageNumber: number;
        nextFilters: ITripFilters;
      }) => {
        tripsStore.fetchMainData({
          nextFilters: {
            ...nextFilters,
            detention: companyPreferences?.calculateDetention
              ? nextFilters.detention
              : undefined,
            terminalIds: isTerminalEnabled
              ? getGlobalTerminals.map(({ id }: { id: string }) => id)
              : undefined,
          },
          nextPageNumber,
        });
      },
      debounceTime
    ),
    [
      companyPreferences?.calculateDetention,
      isTerminalEnabled,
      getGlobalTerminals?.length,
    ]
  );

  const onClickHandler = (event: IOpenPopupEventProps<Trip>): void => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    handleOpenPopup(event);
  };

  const handleSortChanged = (model: GridSortModel): void => {
    const sortOptions = getSortOption(model, '-createDate');

    const updatedData = {
      nextPageNumber: 1,
      nextFilters: {
        ...filters,
        sort: sortOptions,
      },
    };
    sendRequestHandler(updatedData);

    LStorageHook.updateStorage(stripId, {
      ...storageData[stripId],
      query: { ...storageData[stripId].query, sort: sortOptions },
    });
  };

  const onCreatedLoadHandler = async (load: LoadDetailsResponse) => {
    //TODO: re-use this function for these dialog
    //1)CreateLoadPanel
    //2)CommonCreateLoadPanel
    if (load instanceof LoadDetailsResponse) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.LOAD,
        id: load.id,
        isGlobal: false,
        defaultTab: EMyLoadDetailsPanelTabs.ROUTES,
      });
      tripsStore.setSelectedItem(null);
      sendRequestHandler({
        nextPageNumber: 1,
        nextFilters: tripsStore.filters,
      });
    }
  };

  const handleSelectItem = (
    params: GridRowParams<Trip & { isPinnedRows?: boolean }>
  ) => {
    const data = params.row;
    if (!data.isPinnedRows)
      onClickHandler({
        data,
      });
  };

  const sendRequestHandler = (params: {
    nextPageNumber: number;
    nextFilters: ITripFilters;
  }) => {
    tripsStore.setSettingsPagination({
      ...tripsStore.settingsPagination,
      pageNumber: params.nextPageNumber,
    });

    tripsStore.setFilters(params.nextFilters);
    debouncedChangeHandler(params);
  };

  const onRowsScrollEnd = (): void => {
    const { pageNumber, isLoading, last }: any = tripsStore.settingsPagination;
    if (isLoading || last || (tableList && !tableList.length)) return;
    sendRequestHandler({
      nextPageNumber: pageNumber + 1,
      nextFilters: filters,
    });
  };

  useEffect(() => {
    //Important: Call in the first time
    const defaultFilters = setDefaultFilters();
    if (!companyPreferences) return;
    sendRequestHandler({
      nextPageNumber: 1,
      nextFilters: { ...defaultFilters, ...sortRequestData },
    });
    setPageIsLoading(false);
  }, [
    stripId,
    isTerminalEnabled,
    getGlobalTerminals?.length,
    companyPreferences?.calculateDetention,
  ]);

  useEffect(() => {
    setDefaultColumns();
    setDefaultSorting();
  }, [companyPreferences?.timezone, stripId]);

  useEffect(() => {
    if (tripsStore.selectedItem) {
      tripsStore.setSelectedItem(null);
    }
  }, []);

  const onResetColVisibility = () => {
    resetSettings();
  };

  const tableConfigurationProps = useMemo(
    () => ({
      TableSettings: {
        handleChangeForColumnModel: handleChangeColumns,
        tableColumnsData: allColumns.slice(1) || allColumns,
        getExcel: exportToExcelHandler,
        resetSettings: onResetColVisibility,
        downloadFile: downloadFile,
      },
    }),
    [
      allColumns,
      exportToExcelHandler,
      handleChangeColumns,
      onResetColVisibility,
    ]
  );

  const setDefaultSorting = () => {
    const sort = storageData[stripId]?.query?.sort;
    sort && setSortRequestData({ sort });
  };

  const handleClose = () => {
    tripsStore.setSelectedItem(null);
    tripsStore.setAddNewClicked(false);
  };
  const handleTripAssignmentChange = (data) => {
    tripsStore.updateMainData({
      updatedIds: [data.trip.id],
    });
  };
  const handleLoadTripAction = (data: LoadTripActionData) => {
    if (data.toStatus === ELoadStatus.DELETED) {
      if (data.tripIds) {
        tripsStore.updateMainData({
          deletedIds: data.tripIds,
        });
      }
    } else {
      if (data.entity === 'Trip') {
        tripsStore.updateMainData({
          updatedIds: [data.id],
        });
      } else if (data.tripIds) {
        tripsStore.updateMainData({
          updatedIds: data.tripIds,
        });
      }
    }
  };
  const handleTripStopAction = (data: EventTripStopActionData) => {
    if (
      data.toType === ESecondaryDetailsPanelType.TRIP &&
      data.updatedTripIds
    ) {
      updateTripList({
        updatedIds: data.updatedTripIds,
        deletedIds: data.updatedTripIds,
      });
    }
  };

  const handleHoldOrReleaseLoad = (data: EventHoldLoadActionData) => {
    if (!data.tripIds) return;
    handleHoldOrReleaseLoadLocally(data);
    updateTripList({
      updatedIds: data.tripIds,
    });
  };

  useEmit({
    [EventTypes.TRIP_ASSIGNMENT_CHANGE]: handleTripAssignmentChange,
    [EventTypes.LOAD_TRIP_ACTION]: handleLoadTripAction,
    [EventTypes.TRIP_STOP_ACTION]: handleTripStopAction,
    [EventTypes.HOLD_LOAD]: handleHoldOrReleaseLoad,
  });

  const onGridAction =
    (actionFn: {
      (data: any): Partial<ITripFilters>;
      (data: string[], name: keyof ITripFilters): Partial<ITripFilters>;
      (arg0: any): ITripFilters;
    }) =>
    (...params: any) =>
      sendRequestHandler({
        nextPageNumber: 1,
        nextFilters: {
          ...filters,
          ...actionFn(...params),
        },
      });

  const onCloseCommonPanel = (): void => {
    tripsStore.setSelectedItem(null);
    setSecondaryPanelData(null);
  };
  const onCloseSecondaryDetailsPanel = (): void => {
    setSecondaryPanelData(null);
  };

  const handleDelete = (): void => {
    tripsStore.updateMainData({
      deletedIds: [tripsStore.selectedItem.id],
    });
    tripsStore.setSelectedItem(null);
  };
  const handleOpenPopupLoads = (loadId: string, tripId: string): void => {
    tripsStore.setSelectedItem(null);
    setSecondaryPanelData({
      type: ESecondaryDetailsPanelType.LOAD,
      id: loadId,
      defaultTab: EMyLoadDetailsPanelTabs.ROUTES,
      tripId: tripId,
    });
  };
  const onCellClick = async (
    params: GridCellParams,
    event: Event
  ): Promise<void> => {
    if (tripsStore.selectedItem) {
      tripsStore.setSelectedItem(null);
    }
    if (
      params.field === 'manifestSeqNumbersFilter' ||
      params.field === 'connectedLoads'
    ) {
      handleOpenPopupLoads(event.target.id, params?.id);
      event?.stopPropagation(); //for stopping trigger other listener events
      return;
    }
    if (params.value) {
      onClickHandler({
        data: params.row,
        params,
      });
      event?.stopPropagation(); //for stopping trigger other listener events
      return;
    }
    handleSelectItem(params);
  };

  const handleFilterReset = () => {
    const nextFilters = onAllFilterReset();
    sendRequestHandler({
      nextPageNumber: 1,
      nextFilters: nextFilters,
    });
  };

  const updateTripList = ({
    deletedIds,
    updatedIds,
  }: {
    deletedIds?: string[];
    updatedIds?: string[];
  }) => {
    tripsStore.updateMainData({
      deletedIds,
      updatedIds,
    });
  };
  const pinnedRows = useMemo(
    () => ({
      bottom: [
        {
          id: Math.random(),
          [tripFieldsConfig.warning.fieldName]: {},
          [tripFieldsConfig.revenue.fieldName]:
            tripsStore.revenueTotalResponse?.totalRevenue !== undefined &&
            !isNaN(tripsStore.revenueTotalResponse?.totalRevenue)
              ? tripsStore.revenueTotalResponse?.totalRevenue
              : 'loading',
          [tripFieldsConfig.settlementAmount.fieldName]:
            tripsStore.revenueTotalResponse?.totalSettlementAmount !==
              undefined &&
            !isNaN(tripsStore.revenueTotalResponse?.totalSettlementAmount)
              ? tripsStore.revenueTotalResponse?.totalSettlementAmount
              : 'loading',
          isPinnedRows: true,
          pinned: true,
        },
      ],
    }),
    [tripsStore.revenueTotalResponse]
  );

  return (
    <>
      <Stack direction="column">
        {!pageIsLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '> div > div': {
                pl: 0,
              },
            }}
          >
            <CustomViewsV2
              component={component}
              dictionary={dictionary}
              entities={[
                EEntityList.allTrips,
                EEntityList.needDrivers,
                EEntityList.activeTrips,
                EEntityList.toBeSettled,
                EEntityList.pastTrips,
              ]}
              defaultViews={defaultViews}
              formDefaultValuesMap={formDefaultValuesMap}
              viewForm={ViewForm}
              defaultView={allTrips}
              modalTitle={'Create a Setting View'}
              setCurrentView={(view: View) => {
                if (tripsStore.selectedItem) {
                  tripsStore.setSelectedItem(null);
                }
                if (secondaryPanelData) {
                  setSecondaryPanelData(null);
                }
                tripsStore.setCurrentView(view);
              }}
              currentView={currentView}
            />

            <Button
              variant="contained"
              sx={{ p: 1 }}
              onClick={() => setOpenEmptyTripDialog(true)}
            >
              <AddOutlined sx={{ width: '18px', mr: 0.5 }} /> Empty Trip
            </Button>
          </Box>
        ) : (
          <Skeleton
            sx={{
              m: 1,
              ml: 2,
            }}
            variant="rectangular"
            height={'2rem'}
            width={'50%'}
          />
        )}
      </Stack>
      <Stack flexGrow={1} flexShrink={1} direction="row" overflow="hidden">
        <Stack
          sx={(theme: Theme) => ({
            height: '100%',
            width:
              secondaryPanelData || tripsStore.selectedItem
                ? `calc(100% - ${PANEL_FIXED_WIDTH}px)`
                : '100%',
          })}
          direction="column"
        >
          {!pageIsLoading ? (
            <>
              {/* <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}> */}
              <FiltersWrapper
                filterColumns={getFiltersFormConfig}
                onAllFilterSubmit={onGridAction(onAllFilterSubmit)}
                onPageFilterChange={onGridAction(onPageFilterChange)}
                defaultFilterValue={tripsStore.filters}
                displayFilters={true}
                pageName={''}
                tableConfigurationProps={tableConfigurationProps}
                settingsPanel={true}
                onAllFilterReset={handleFilterReset}
                filterPerCol={
                  isTablet ? [Math.ceil(getFiltersFormConfig.length / 2)] : [8]
                }
              />

              <Box sx={(theme) => getStyledTable({ theme })}>
                <CommonDataGrid
                  disableColumnMenu
                  onSortModelChange={handleSortChanged}
                  loading={
                    tripsStore.settingsPagination.isLoading || pageIsLoading
                  }
                  columns={allColumns?.filter(
                    ({ field }) =>
                      field !== tripFieldsConfig.onHoldState.fieldName
                  )}
                  rows={tripsStore.tableList}
                  onRowsScrollEnd={onRowsScrollEnd}
                  getRowId={(row: Trip) => row.id}
                  totalNumberOfRows={tripsStore.totalTableItems}
                  totalDataInTable={tripsStore.totalTableItems}
                  onCellClick={onCellClick}
                  sortingMode="server"
                  onColumnOrderChange={(params, event, details) =>
                    onColumnOrderChange(
                      {
                        params,
                        event,
                        details,
                      },
                      component,
                      stripId
                    )
                  }
                  handleSelectItem={handleSelectItem}
                  pinnedRows={pinnedRows}
                />
              </Box>
            </>
          ) : (
            <>
              <Skeleton
                variant="rectangular"
                height={'3rem'}
                width={'50%'}
                sx={{
                  m: 1,
                  ml: 2,
                }}
              />
              <Skeleton
                variant="rectangular"
                height={'80vh'}
                sx={{
                  m: 1,
                  ml: 2,
                }}
              />
            </>
          )}
        </Stack>

        {tripsStore.selectedItem && (
          <Box sx={{ flexGrow: 1 }}>
            <TripDetailsPanel
              onUpdated={() => {
                tripsStore.updateMainData({
                  deletedIds: [selectedItem.id],
                  updatedIds: [selectedItem.id],
                });
              }}
              onCreated={() => null}
              onAction={(status) => {
                if (status === ELoadStatus.DELETED) {
                  updateTripList({
                    updatedIds: [tripsStore.selectedItem.id],
                    deletedIds: [tripsStore.selectedItem.id],
                  });
                } else
                  updateTripList({
                    updatedIds: [tripsStore.selectedItem.id],
                  });
              }}
              key={tripsStore.selectedItem?.id}
              data={tripsStore.selectedItem}
              onDeleted={handleDelete}
              onClose={handleClose}
              onRelayDeleted={(data) => {
                sendRequestHandler({
                  nextPageNumber: 1,
                  nextFilters: filters,
                });
                if (data.type === 'pickup') tripsStore.setSelectedItem(null);
              }}
              onUpdatedTrips={updateTripList}
              onUpdateActivitiesCompleted={() => {
                if (selectedItem?.id)
                  updateTripList({
                    updatedIds: [selectedItem.id],
                  });
              }}
              onCreatedLoadHandler={onCreatedLoadHandler}
            />
          </Box>
        )}
        {secondaryPanelData && (
          <Box sx={{ flexGrow: 1 }}>
            <SecondaryDetailsPanel
              isGlobal={false}
              type={secondaryPanelData.type}
              id={secondaryPanelData.id}
              onClose={onCloseCommonPanel}
              defaultTab={secondaryPanelData.defaultTab}
              manifestDeleteForLoad={(id: string, status: boolean) => {
                if (!status) {
                  setSecondaryPanelData(null);
                }
              }}
              onUpdateMainData={(
                updatedIds: string[] = [],
                deletedIds: string[] = []
              ) => {
                tripsStore.updateMainData({
                  updatedIds,
                  deletedIds,
                });
              }}
              onUpdated={() => {
                tripsStore.updateMainData({
                  deletedIds: [],
                  updatedIds: [secondaryPanelData.tripId],
                });
              }}
              {...(secondaryPanelData.type ===
                ESecondaryDetailsPanelType.LOAD && {
                onClose: onCloseSecondaryDetailsPanel,
                isGlobal: secondaryPanelData.isGlobal,
                onAction: (status: ELoadStatus) => {
                  if (status === ELoadStatus.DELETED) {
                    handleClose();
                  }
                },
                onCreatedLoadHandler: onCreatedLoadHandler,
              })}
            />
          </Box>
        )}
      </Stack>
      <CreateEmptyTrip
        open={openEmptyTripDialog}
        onClose={() => setOpenEmptyTripDialog(false)}
        onAfterSubmit={(trip) => {
          setOpenEmptyTripDialog(false);
          setSecondaryPanelData({
            type: ESecondaryDetailsPanelType.TRIP,
            id: trip?.id!,
          });
          sendRequestHandler({
            nextPageNumber: 1,
            nextFilters: filters,
          });
        }}
      />
    </>
  );
};

export default observer(TripListPage);
