/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  PaginatedTractorsList,
  PaginatedTractorsListRequest,
  ChangeTractorStatusRequest,
  DriverTractorAssignRequest,
  DriverTractorAssignResponse,
  TractorResponse,
  TractorAlertQueryParamsRequest,
  TractorAlertSummaryDTO,
  CreateTractorAlertQueryParamsRequest,
  UpdateTractorAlertListRequest,
  DefaultTractorsTypesListSummaryDTO,
  DefaultTractorsTypesDictionarySummaryDTO,
  QueryParams,
} from '../../models';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class ITractorService extends Service {
  abstract getPaginatedTractorsList(
    requestData: PaginatedTractorsListRequest
  ): Promise<PaginatedTractorsList | null | ServiceError>;

  abstract getPaginatedTractorsListExport(
    requestData: PaginatedTractorsListRequest
  ): Promise<PaginatedTractorsList | null | ServiceError>;

  abstract changeTractorStatus(
    requestData: ChangeTractorStatusRequest
  ): Promise<TractorResponse | ServiceError>;

  abstract driverTractorAssign(
    requestData: DriverTractorAssignRequest
  ): Promise<DriverTractorAssignResponse | null | ServiceError>;

  abstract driverTractorUnAssign(
    requestData: DriverTractorAssignRequest
  ): Promise<DriverTractorAssignResponse | null | ServiceError>;

  abstract getVinList(vinKeyword: string | undefined, pageNumber: number): any;
  abstract getLicensePlateList(
    keyword: string | undefined,
    pageNumber: number
  ): any;
  abstract getTractorByNameSearchList(
    keyword: string | undefined,
    pageNumber: number,
    excludeStatusList: string[]
  ): any;

  abstract getDriverMaintenanceWarnings(dto: {
    driverGroupId: string;
    scenarioType: string;
  }): any;

  abstract getDriverLoadWarnings(dto: {
    driverGroupId: string;
    tractorId: number;
    warningTypes: string[];
  }): any;

  abstract getTractorByNameSearchExtendedList(
    keyword: string | undefined,
    pageNumber: number,
    statusList: string[],
    terminalIds?: string[]
  ): any;

  abstract getTractorLightListByNameSearch(
    keyword: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    excludeStatusList?: string[],
    pageSize?: number
  ): any;

  abstract getTrailerVinList(
    vinKeyword: string | undefined,
    pageNumber: number
  ): any;
  abstract getTrailerLicensePlateList(
    keyword: string | undefined,
    pageNumber: number
  ): any;
  abstract getTrailerByNameSearchList(
    keyword: string | undefined,
    pageNumber: number,
    excludeStatusList: string[]
  ): any;

  abstract getTrailerLightListByNameSearch(
    keyword: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    excludeStatusList?: string[]
  ): any;
  abstract getAssetsCount(): any;
  abstract getTractorStaticData(): any;
  abstract getExpenseStaticData(): any; // should be moved expenses services
  abstract getDriverGroups(
    nameFilter: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    activeForOperation?: boolean,
    typeFilter?: string
  ): any; // should be moved driver services
  abstract getStates(): any; // should be moved static service services

  abstract getTractorById(
    id: string
  ): Promise<TractorResponse | null | ServiceError>;

  abstract getTractorEntitySearch(
    url: string,
    searchText: string | undefined,
    pageNumber: number
  ): any;

  abstract verifyTractorDeletion(id: string): any;
  abstract deleteTractorById(id: string): Promise<null | ServiceError>;
  abstract markAsInactive(
    id: string
  ): Promise<{ hasErrors: boolean; message: string } | ServiceError>;

  abstract createTractor(
    newTractor: any
  ): Promise<TractorResponse | null | ServiceError>;

  abstract updateTractor(
    newTractor: any
  ): Promise<TractorResponse | null | ServiceError>;

  abstract getTractorAlertList(
    requestData: TractorAlertQueryParamsRequest
  ): Promise<TractorAlertSummaryDTO[] | null | ServiceError>;

  abstract createTractorAlert(
    requestData: CreateTractorAlertQueryParamsRequest
  ): Promise<TractorAlertSummaryDTO | null | ServiceError>;

  abstract updateTractorAlertsBulk(
    requestData: UpdateTractorAlertListRequest[]
  ): Promise<TractorAlertSummaryDTO[] | null | ServiceError>;

  abstract getDefaultTractorsTypesList(
    requestData: QueryParams
  ): Promise<DefaultTractorsTypesListSummaryDTO[] | null | ServiceError>;

  abstract getDefaultTractorsTypesDictionary(
    requestData: QueryParams
  ): Promise<DefaultTractorsTypesDictionarySummaryDTO[] | null | ServiceError>;

  abstract deleteTractorAlertById(id: string): Promise<null | ServiceError>;
}
