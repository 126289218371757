import Box from '@mui/material/Box';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { PreferencesTabStripType } from '../../../../../models';
import { entities, entityMap, fuelPriceType } from '../../constants';
import CompanyInfo from '../Sections/CompanyInfo';
import Driver from '../Sections/Driver';
import Finance from '../Sections/Finance';
import InvoiceSettlement from '../Sections/InvoiceSettlement';
import Loads from '../Sections/Loads';
import Settings from '../Sections/Settings';
import FuelPrice from '../Sections/FuelPrice';
export default function ContainerPreferences({
  entity: currentEntity,
  setEntity,
}: {
  entity: PreferencesTabStripType;
  setEntity: (data: PreferencesTabStripType) => void;
}) {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = scrollRef.current;
    if (!element) {
      return;
    }
    const handleScroll = debounce(() => {
      let currentSectionId = '';
      const sections = document.querySelectorAll('section') || [];
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (element.scrollTop >= sectionTop - sectionHeight / 3) {
          currentSectionId = section.getAttribute('id') as string;
        }
      });
      entities.forEach((entity) => {
        if (
          entity.name === currentSectionId &&
          currentEntity.name !== currentSectionId
        ) {
          setEntity(entityMap[currentSectionId] || fuelPriceType);
        }
      });
    }, 150);
    element.addEventListener('scroll', handleScroll);
    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [currentEntity.name]);

  return (
    <Box
      className={'ContainerPreferences-root'}
      sx={{
        mt: 2,
        pl: 4,
        pr: 4,
        pb: 4,
        backgroundColor: 'primary.contrast',
        borderRadius: '16px',
        overflow: 'auto',
        height: '100%',
      }}
      ref={scrollRef}
    >
      <Finance />
      <InvoiceSettlement />
      <Loads />
      <Driver />
      <CompanyInfo />
      <Settings />
      <FuelPrice />
    </Box>
  );
}
