import { useTheme } from '@mui/material';
import { eLoadOperationalChart } from '.';

export const GetLoadOperationChartColorSettings = (
  type: eLoadOperationalChart
): {
  textColor1: string;
  textColor2: string;
  chartTitleColor: string;
  background: string;
  ringBackgroundColor1: string;
  ringBackgroundColor2: string;
} => {
  const theme: any = useTheme();
  switch (type) {
    case eLoadOperationalChart.NeedsAttention:
      return {
        textColor1: theme.palette.primary.contrast,
        textColor2: theme.palette.primary.contrast,
        chartTitleColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
        ringBackgroundColor1: theme.palette.primary.dark,
        ringBackgroundColor2: theme.palette.primary.light,
      };
    case eLoadOperationalChart.PotentialDelay:
      return {
        textColor1: theme.palette.primary.contrast,
        textColor2: theme.palette.primary.contrast,
        background: theme.palette.error.main,
        chartTitleColor: theme.palette.error.main,
        ringBackgroundColor1: theme.palette.error.dark,
        ringBackgroundColor2: theme.palette.error.light,
      };
    case eLoadOperationalChart.NeedsLoads:
    case eLoadOperationalChart.Unvailable:
      return {
        textColor1: theme.palette.error.main,
        textColor2: theme.palette.error.main,
        chartTitleColor: theme.palette.error.main,
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336',
        ringBackgroundColor1: theme.palette.error.dark,
        ringBackgroundColor2: theme.palette.error.light,
      };
    case eLoadOperationalChart.OnVacation:
    case eLoadOperationalChart.Available:
      return {
        textColor1: theme.palette.warning.main,
        textColor2: theme.palette.warning.main,
        chartTitleColor: theme.palette.warning.main,
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02',
        ringBackgroundColor1: theme.palette.warning.dark,
        ringBackgroundColor2: theme.palette.warning.light,
      };
  }
};
