import {
  Box,
  Button,
  Grid,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { carrierService } from '../../../../../../api';
import Accordion from '../../../../../../common/Accordion';
import FormDialog from '../../../../../../common/Ui/FormDialog';
import { FormDialogMode } from '../../../../../../common/Ui/FormDialog/constants';
import {
  CarrierContactDTO,
  CarrierDTO,
  CreateCarrierContactRequest,
} from '../../../../../../models/DTOs/Carrier/Requests';
import { useDetailsPanelStore } from '../../../../../../store/DetailsPanel';
import { AddIcon } from '../../../../../../ui-kit/components/Assets';
import { ESecondaryDetailsPanelType } from '../../../../../../views/dispatch2/constants/types';
import { contactDefaultData } from '../../../../../customers/constants';
import { CarrierContactValidationSchema } from '../../../../constants/validationSchema';
import AddContactForm from '../Contact/AddContactForm';
import ContactListForm from '../Contact/ContactListForm';
import { t } from 'i18next';
interface AddNewContactFormProps {
  isCreatePanel: boolean;
  carrierDTO?: CarrierDTO;
  canEdit: boolean;
}
export const AddNewContactForm = ({
  isCreatePanel = true,
  carrierDTO,
  canEdit,
}: AddNewContactFormProps) => {
  const { trackPromise } = useDetailsPanelStore;
  const theme = useTheme();
  const [addContactDialogOpen, setAddContactDialog] = useState<boolean>(false);
  const [nextRender, setNextRender] = useState<number>(0);
  const { getValues, setValue } = useFormContext();
  const [contacts] = getValues(['contacts']);
  const numberOfContacts = contacts?.length;

  const handleOpenAddContactModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAddContactDialog(true);
  };

  const contactsSummaryRenderer = () => {
    return (
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={0}
      >
        <Grid item>
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            Contacts
          </Typography>
          {canEdit && (
            <Button
              onClick={handleOpenAddContactModal}
              variant="outlined"
              sx={{ minWidth: '35px', height: '30px', marginLeft: '16px' }}
            >
              <AddIcon />
            </Button>
          )}
        </Grid>
        {numberOfContacts > 0 && (
          <Grid item>
            <Grid container direction={'column'} alignItems={'flex-end'}>
              <Grid item>
                <Typography variant="h6" sx={{ color: 'primary.main' }}>
                  {numberOfContacts}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="tooltip" sx={{ color: 'text.secondary' }}>
                  {t('loadsTrackingTotalNum')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const onCloseAddContact = () => {
    setAddContactDialog(false);
  };

  const onAddContactHandler = async (contact: CarrierContactDTO) => {
    setAddContactDialog(false);
    if (contact.isPrimary) {
      contacts.forEach((contact: CarrierContactDTO, index: number) => {
        setValue(`contacts.${index}.isPrimary`, false);
      });
    }
    if (isCreatePanel) {
      contacts.push(contact);
    } else if (carrierDTO) {
      const createdContact = await trackPromise(
        carrierService.createCarrierContact(
          new CreateCarrierContactRequest({
            ...contact,
            carrierId: carrierDTO.id,
          })
        ),
        ESecondaryDetailsPanelType.CARRIER
      );
      contacts.push({ ...createdContact });
    }
    setValue('contacts', contacts, {
      shouldDirty: isCreatePanel ? true : false,
    });
    setNextRender((prev) => prev + 1);
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Accordion
        summaryRenderer={contactsSummaryRenderer}
        expanded={!isCreatePanel}
      >
        {numberOfContacts
          ? contacts.map((contact: CarrierContactDTO, index: number) => (
              <ContactListForm
                key={`${contact?.id ?? index}`}
                index={index}
                canEdit={canEdit}
              />
            ))
          : null}
      </Accordion>
      {addContactDialogOpen && (
        <FormDialog
          mode={FormDialogMode.LIGHT}
          data={{ ...contactDefaultData, isPrimary: !contacts?.length }}
          titleText={
            <Typography variant="h6" color={'text.primary'}>
              Add New Contact
            </Typography>
          }
          actionLabel="Add New Contact"
          actionLabelId="addContactButton"
          open={addContactDialogOpen}
          onAction={onAddContactHandler}
          disableActionBtnByDefault
          handleClose={onCloseAddContact}
          validationSchema={CarrierContactValidationSchema}
          contentRenderer={() => (
            <ThemeProvider theme={theme}>
              <AddContactForm />
            </ThemeProvider>
          )}
        ></FormDialog>
      )}
    </Box>
  );
};
