import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@components/ui-kit/feedback/dialog';
import { IconButton } from '@components/ui-kit/icon-button';
import { List, ListItem, ListItemText } from '@components/ui-kit/list';
import { TextField } from '@components/ui-kit/text-field';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ILoadStaticContent } from '../../../../../models';
import { QUERY_KEYS } from '../../constants/queryKeys';

export const SupportedVendorsDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const [searchedText, setSearchedText] = useState<string>();
  const { t, ready } = useTranslation();

  const loadStaticData = useQueryClient().getQueryData<ILoadStaticContent>(
    QUERY_KEYS.loadStaticData
  );

  function similar(string1: string, string2: string) {
    return (
      string1 === string2 ||
      string1.includes(string2) ||
      string2.includes(string1)
    );
  }

  const getVendorMatch = (arr: string[], pattern: string): string[] => {
    const list: string[] = [];
    arr.forEach((s1) => {
      if (similar(s1?.toLowerCase?.(), pattern?.toLowerCase?.())) {
        list?.push(s1);
      }
    });
    return list;
  };

  const getFilteredVendors = useMemo(() => {
    const list = loadStaticData?.supportedPdfFormats?.map?.((e) => e?.value);
    if (!searchedText?.length) return list ?? [];
    const filteredVendors = getVendorMatch(list!, searchedText);
    return filteredVendors;
  }, [searchedText, loadStaticData?.supportedPdfFormats]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ width: '420px' }}>
        Supported RateCon Vendors
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pt: 0 }}>
        <TextField
          onChange={(event: any) => {
            setSearchedText(event?.target?.value);
          }}
          placeholder="Search shipper or broker name"
          sx={{
            width: '100%',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <List dense sx={{ overflow: 'auto', height: 300, mt: 1, mb: 1 }}>
          {getFilteredVendors?.map?.((vendor, i: number) => (
            <ListItem key={i}>
              <ListItemText primary={vendor} />
            </ListItem>
          ))}
        </List>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ color: 'text.primary', fontFamily: 'Poppins' }}
          >
            {t(
              'SupportedRCDocumentsLoadOpsiscurrentlytoaddsupportformoreshippersbrokers'
            )}
          </Typography>
        </Box>
        <Box sx={{ paddingBottom: '15px', paddingTop: '10px' }}>
          <Typography
            variant="subtitle2"
            sx={{ color: 'text.primary', fontFamily: 'Poppins' }}
          >
            {t(
              'SupportedRCDocumentsIfyouwouldlikeLoadOpstosupportadditionalbrokers'
            )}{' '}
            <a
              href="mailto:loadops.support@optym.com"
              style={{ color: '#1976d2' }}
            >
              {t('SupportedRCDocumentssupportloadopscom')}
            </a>
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2Bold"
            sx={{
              color: 'text.primary',
              fontFamily: 'Poppins',
              fontWeight: 700,
            }}
          >
            {t(
              'SupportedRCDocumentsNotePDFsmustbeoriginalandcannotbescannedversions'
            )}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
