import React from 'react';
import { SelectedProviderInfo } from '@/views/settings/integrations/FuelCard/types/SelectedProvider';
import wex from '@/_assets/images/Wex.png';

export const FuelCardProvidersMap: Record<string, SelectedProviderInfo> = {
  1: {
    logo: <img width={110} height={35} src={wex} alt="wex" />,
    logoWhite: <img width={200} height={40} src={wex} alt="wex" />,
    description:
      'EFS (Electronic Funds Source LLC) is a leading provider of innovative and customizable corporate payment solutions for the transportation industry. One of their primary services is fuel card management. They offer fuel cards that provide fleet managers with convenient and secure ways to purchase fuel, manage expenses, and monitor driver spending. EFS fuel cards are widely accepted at fueling locations across the United States, helping businesses streamline their fuel purchasing processes, control costs, and improve overall efficiency in fleet management.',
    gradient:
      'linear-gradient(0deg, rgba(224, 232, 245, 0.68), rgba(224, 232, 245, 0.68)), linear-gradient(90.06deg, #7487CC 4.1%, rgba(255, 255, 255, 0.09) 55.11%)',
  },
};
