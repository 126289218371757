import {
  FuelCardAccount,
  TransactionRecord,
} from '@/models/DTOs/FuelCards/FuelCards';
import moment from 'moment';
import { fuelCardService } from '@/api';
import {
  FinishImportRequest,
  PullDataRequest,
} from '@/models/DTOs/FuelCards/Requests';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { ServiceError } from '@/api/interfaces';
import { getGridCompanyTimezoneSettings } from '@/utils/Timezone.utils';
import { formatDate } from '@/common/Ui/FormDatePickerWithoutTimeZone/FormDatePickerWithoutTimeZone';
import { RootStoreInstence } from '@/store/root-store/rootStateContext';

const timezone = getGridCompanyTimezoneSettings().timezone || '';

interface Filter {
  tractorIdsFilter: { id: string }[];
  driverIdsFilter: { id: string }[];
  driverRoleFilter: null | { key: string; value: string };
}

export const useTransactionRecords = (
  account: FuelCardAccount,
  handleImportKeyChange: (data: string) => void,
  rePullData: boolean,
  handleRePullDataChange: (data: boolean) => void
) => {
  const { watch, setValue, reset, getValues } = useFormContext();
  const [begDate, endDate, hideSyncedReports, key, deductionType] = watch([
    'begDate',
    'endDate',
    'hideSyncedReports',
    'key',
    'deductionType',
  ]);

  const [filters, setFilters] = useState<Filter>({
    tractorIdsFilter: [],
    driverIdsFilter: [],
    driverRoleFilter: null,
  });

  const [transactionRecords, setTransactionRecords] = useState<
    TransactionRecord[]
  >([]);
  const [isDataPulled, setIsDataPulled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (['driversDeduction', 'tractorsDeduction'].includes(deductionType)) {
      setValue('percentageToReimburse', 100);
      setValue('addDiscountInDescription', false);
    } else {
      setValue('percentageToReimburse', null);
      setValue('addDiscountInDescription', null);
    }
  }, [deductionType]);

  const pullData = async () => {
    setLoading(true);
    if (key) {
      finishImport();
      setTransactionRecords([]);
    }
    const request = new PullDataRequest();
    request.credentialId = account.id;
    request.begDate = formatDate(moment(begDate).tz(timezone), false) as
      | string
      | undefined;
    request.endDate = formatDate(moment(endDate).tz(timezone), false) as
      | string
      | undefined;
    request.hideSyncedReports = !!hideSyncedReports;
    request.driverRoleFilter = filters.driverRoleFilter?.key;
    request.driverIdsFilter = filters.driverIdsFilter.map(
      (driver) => driver.id
    );
    request.tractorIdsFilter = filters.tractorIdsFilter.map(
      (tractor) => tractor.id
    );
    const result = await fuelCardService.pullData(request);
    if (result instanceof ServiceError) {
      setLoading(false);
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'expenseEFSPull',
      });
      return;
    }
    setIsDataPulled(true);
    setTransactionRecords(result.content);
    handleImportKeyChange(result.key);
    setLoading(false);
    reset({
      ...getValues(),
      key: result.key,
    });
  };

  useEffect(() => {
    if (account.data.END_DATE) {
      setValue('begDate', account.data.END_DATE);
    }
  }, [account.data.END_DATE]);

  useEffect(() => {
    if (rePullData) {
      setTransactionRecords([]);
      pullData();
      handleRePullDataChange(false);
      finishImport();
    }
  }, [rePullData]);

  const finishImport = async () => {
    const request = new FinishImportRequest();
    request.key = key;
    await fuelCardService.finishImport(request);
  };

  const handleCheckboxSelectionChange = (ids: number[]) => {
    setValue('expenseIdsToCreate', ids);
  };

  const handleFilterChangeCb = (data: string[], name: string) => {
    setFilters((oldFilters) => ({ ...oldFilters, [name]: data }));
  };

  const isGetRecordsDisabled = !begDate || !endDate;

  return {
    pullData,
    transactionRecords,
    handleCheckboxSelectionChange,
    isDataPulled,
    isGetRecordsDisabled,
    loading,
    filters,
    handleFilterChangeCb,
    deductionType,
  };
};
