import React from 'react';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import TextField from '../../../../common/Ui/TextField/TextField';
import { FormAddress } from '../../../../common/Ui/AddressField';
import Button from '@mui/material/Button';
import ButtonGroup_Like_Dislike from '../../../../ui-kit/components/ButtonGroup_Like_Dislike';
import {
  AddIcon,
  DislikeIcon,
  LikeIcon,
} from '../../../../ui-kit/components/Assets';
import ContactListForm from './ContactListForm';
import FormDialog from '../../../../common/Ui/FormDialog/index';
import AddNewContactForm from './AddNewContactForm';
import { ContactSummaryDTO, LocationSummaryDTO } from '../../../../models/DTOs';
import Accordion from '../../../../common/Accordion';
import { Grid } from '@mui/material';
import { CreateLocationContactRequest } from '../../../../models';
import { locationService } from '../../../../api';
import { contactValidationSchema } from '../../../../validator/validationSchemas';
import { getAccountTypeWithRoleCode, isHasPermission } from '../../../../utils';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { locationPage } from '../../../../common/PendoClassIDs/constants';
import StorageManager from '../../../../StorageManager/StorageManager';
import GeoCodingModelPopup from '../../../../common/GeoCoding/GeoCodingModel';
import { addressType } from '../../../../common/Ui/AddressField/constants';
import { t } from 'i18next';

const preferredProhibitedOptions = [
  {
    key: 'PREFERRED',
    name: 'PREFERRED',
    iconRenderer: (selected: boolean) => (
      <LikeIcon color={selected ? '#fff' : '#2B64CB'} />
    ),
    getButtonStyle: (selected: boolean) => ({
      backgroundColor: selected ? '#2B64CB' : '#fff',
      borderRadius: '6px',
      borderColor: '#2B64CB',
      marginRight: 5,
    }),
  },
  {
    key: 'PROHIBITED',
    name: 'PROHIBITED',
    iconRenderer: (selected: boolean) => (
      <DislikeIcon color={selected ? '#fff' : '#F44336'} />
    ),
    getButtonStyle: (selected: boolean) => ({
      backgroundColor: selected ? '#F44336' : '#fff',
      borderColor: '#F44336',
      borderRadius: '6px',
    }),
  },
];

type propsTypes = {
  isCreatePanelOpen: boolean;
  onUpdated: (data: LocationSummaryDTO) => void;
};

function LocationsDetailsForm({ isCreatePanelOpen, onUpdated }: propsTypes) {
  const { control, getValues, setValue } = useFormContext();
  const contacts: ContactSummaryDTO[] = getValues('contacts');
  const addressInfo = getValues('addressDTO');
  const locationId: number = getValues('id');
  const location: LocationSummaryDTO = getValues() as LocationSummaryDTO;
  const userStorageData = StorageManager.getUser();
  const numberOfContacts = contacts?.length;
  const [isCreateContactFormDialogOpen, setIsCreateContactFormDialogOpen] =
    React.useState<boolean>(false);
  const handleOpenAddContactModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setIsCreateContactFormDialogOpen(true);
  };

  const handleCloseCreateContact = () => {
    setIsCreateContactFormDialogOpen(false);
  };

  const onAddContact = (contact: ContactSummaryDTO, shouldDirty: boolean) => {
    setIsCreateContactFormDialogOpen(false);
    if (contact.isPrimary) {
      contacts.forEach((item: ContactSummaryDTO, index: number) => {
        setValue(`contacts.${index}.isPrimary`, false);
      });
    }

    contacts.push(contact);

    contacts.sort((item) => (item.isPrimary ? -1 : 0));

    if (contact.isPrimary) {
      const updatedLocation = { ...location, contacts };
      onUpdated(updatedLocation);
    }
    setValue('contacts', contacts, { shouldDirty });
  };

  const saveContacts = async (contact: ContactSummaryDTO) => {
    if (!isCreatePanelOpen) {
      const request = new CreateLocationContactRequest(contact, locationId);

      try {
        const response = await locationService.createLocationContact(request);

        if (response instanceof ContactSummaryDTO) {
          onAddContact(response, false);
        }
      } catch (error) {}
    } else {
      onAddContact(contact, true);
    }
  };
  const noPermissionForEditLocation = !isHasPermission([
    AXELE_PERMISSION_TYPE.LOCATION_EDIT,
  ]);

  const locationAddPermissions: {
    [key: string]: AXELE_PERMISSION_TYPE[];
  } = {
    DR: [AXELE_PERMISSION_TYPE.LOCATION_ADD],
    SP: [AXELE_PERMISSION_TYPE.LOCATION_ADD],
  };
  const contactsSummaryRenderer = () => {
    return (
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={0}
      >
        <Grid item>
          <span>Contacts</span>
          <Permission includes={[AXELE_PERMISSION_TYPE.CUSTOMER_ADD]}>
            <Button
              onClick={handleOpenAddContactModal}
              variant="outlined"
              style={{
                minWidth: 35,
                minHeight: 35,
                marginLeft: 10,
                borderColor: '#2B64CB',
              }}
            >
              <AddIcon />
            </Button>
          </Permission>
        </Grid>
        {!!numberOfContacts && (
          <Grid item>
            <Grid container direction={'column'} alignItems={'flex-end'}>
              <Grid item>{numberOfContacts}</Grid>
              <Grid item sx={{ color: '#001122', fontSize: 10 }}>
                {t('loadsTrackingTotalNum')}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Accordion
        summaryRenderer={() => (
          // @ISSUE: Vasil
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            Location Details
          </Typography>
        )}
      >
        <TextField
          id={locationPage + 'BusinessName'}
          disabled={noPermissionForEditLocation}
          control={control}
          name="locationName"
          label="Business Name"
          sizes={{
            xs: 9,
          }}
          required
        />
        <ButtonGroup_Like_Dislike
          disabled={noPermissionForEditLocation}
          control={control}
          options={preferredProhibitedOptions}
          name="locationPreference"
          sizes={{ xs: 3 }}
          style={{
            justifyContent: 'flex-end',
            alignSelf: 'start',
            marginTop: '20px',
          }}
        />
        <TextField
          id={locationPage + 'AverageHandlingTime'}
          disabled={noPermissionForEditLocation}
          control={control}
          name="avgHandlingTime"
          label="Average Handling Time (min)"
          sizes={{
            xs: isCreatePanelOpen ? 12 : 6,
          }}
          required
        />
        {!isCreatePanelOpen && (
          <TextField
            id={locationPage + 'TimeZone'}
            control={control}
            name="timezone"
            label="Time Zone"
            sizes={{ xs: 6 }}
            disabled
          />
        )}
        <FormAddress
          disabled={noPermissionForEditLocation}
          control={control}
          name="addressDTO"
          sizes={{ xs: 11 }}
          required
        />
        <GeoCodingModelPopup
          address={addressInfo}
          valueName={'addressDTO'}
          callBackAddress={(data: addressType) => {
            setValue('addressDTO', data, { shouldDirty: true });
          }}
        />

        <TextField
          id={locationPage + 'Notes'}
          disabled={noPermissionForEditLocation}
          control={control}
          name="notes"
          label="Notes"
          sizes={{ xs: 12 }}
        />
      </Accordion>
      <Accordion
        summaryRenderer={contactsSummaryRenderer}
        summaryStyle={{ color: '#2B64CB', fontWeight: 600 }}
        expanded={!isCreatePanelOpen}
      >
        {numberOfContacts
          ? contacts.map((contact: ContactSummaryDTO, index: number) => (
              <ContactListForm key={`${contact.id}`} id={index} />
            ))
          : null}
      </Accordion>
      {isCreateContactFormDialogOpen && (
        <FormDialog
          data={{
            fullName: '',
            email: '',
            phoneData: {},
            description: '',
            isPrimary: !contacts.length,
          }}
          titleText={t('addNewContact')}
          actionLabel={t('addContact')}
          actionLabelId="addContactButton"
          open={isCreateContactFormDialogOpen}
          onAction={saveContacts}
          disableActionBtnByDefault
          handleClose={handleCloseCreateContact}
          contentRenderer={() => (
            <AddNewContactForm numberOfContacts={numberOfContacts} />
          )}
          validationSchema={contactValidationSchema}
        />
      )}
    </React.Fragment>
  );
}

export default LocationsDetailsForm;
