import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import ActionSection from './ActionSection';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useStores } from '../../../store/root.context';

interface ICriticalIssue {
  title?: string;
  content?: string;
}

const CriticalIssue: React.FC<ICriticalIssue> = (props) => {
  const {
    evaluationStore: { stopEvaluation },
  } = useStores();
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
        <Box display={'flex'}>
          <Typography fontSize={16}>{props?.title}</Typography>
          <IconButton
            sx={{ width: '24px', height: '24px', ml: 'auto' }}
            onClick={stopEvaluation}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Box>

        <Typography
          variant="caption"
          fontSize={16}
          color={'text.secondary'}
          lineHeight={'140%'}
        >
          {props?.content}
        </Typography>
      </Box>
      {/* <ActionSection /> */}
    </>
  );
};

export default CriticalIssue;
