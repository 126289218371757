import {
  Box,
  CardHeader,
  SvgIcon,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

interface CustomizeCardHeaderProps {
  title?: React.ReactNode;
  subheader?: React.ReactNode;
  avatar: JSX.Element;
  action?: React.ReactNode;
  sxSvgIcon?: SxProps<Theme> | undefined;
}
export const CustomizeCardHeader = ({
  title,
  subheader,
  avatar,
  action,
  sxSvgIcon,
}: CustomizeCardHeaderProps): JSX.Element => {
  return (
    <CardHeader
      sx={{
        width: '100%',
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
      }}
      avatar={
        <Box
          sx={{
            padding: '5px',
            backgroundColor: 'primary.main',
            borderRadius: '8px',
          }}
        >
          <SvgIcon
            sx={{
              width: '30px',
              height: '30px',
              ...sxSvgIcon,
            }}
            inheritViewBox
          >
            {avatar}
          </SvgIcon>
        </Box>
      }
      title={
        <Typography variant="h7" color={'text.primary'}>
          {title}
        </Typography>
      }
      subheader={subheader}
      action={action}
    />
  );
};
