import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { body2Color, fontFamily } from '../../constants';

export default function SampleData({
  columnName,
  data,
}: {
  columnName: string;
  data: string[];
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Box sx={{ marginBottom: 2, flex: 1 }}>
        <Typography
          variant={'h6'}
          sx={{
            color: 'rgba(0, 17, 34, 0.75',
            fontSize: 16,
            fontFamily,
            fontWeight: 600,
          }}
        >
          Data Preview for {columnName}
        </Typography>
      </Box>
      {data.map((simpleDataName, i) => (
        <Box key={i} sx={{ marginBottom: 3, flex: 1 }}>
          <Typography
            variant={'body2'}
            sx={{
              color: body2Color,
              fontSize: 14,
              fontFamily,
              fontWeight: 400,
            }}
          >
            {simpleDataName}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
