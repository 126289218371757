import { Box, ThemeProvider, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import Accordion from '../../../../../../common/Accordion';
import { addressType } from '../../../../../../common/Ui/AddressField/constants';
import { LookupExternalCarrierItem } from '../../../../../../models/DTOs/Carrier/Requests';
import {
  CarrierContactSummary,
  ICarrierInsuranceFormData,
} from '../../../../constants/types';
import { AddNewContactForm } from './AddNewContactForm';
import { AddNewInsuranceForm } from './AddNewInsuranceForm';
import { GenerateForm } from './GenerateForm';
import { SearchForm } from './SearchForm';
import { getStyledAddNewCarrierForm } from './styles';

export interface ICreateCarrierFormData {
  id?: string;
  name: string;
  mc: number | null;
  dot: string;
  scac: string;
  mode: string;
  truckCount: number;
  preferenceRate: number;
  address: addressType;
  notes: string;
  contacts: CarrierContactSummary[];
  insurances: ICarrierInsuranceFormData[];
}

export const AddNewCarrierForm = observer(
  ({
    setExternalCarrier,
  }: {
    setExternalCarrier: React.Dispatch<
      React.SetStateAction<LookupExternalCarrierItem | null>
    >;
  }) => {
    const theme = useTheme();
    const styles = getStyledAddNewCarrierForm();
    const summaryRenderer = (isExpanded: boolean) => {
      return (
        <Box sx={styles.summaryRendererRoot}>
          <Box sx={styles.summaryRendererRootContent}>
            <Typography
              variant="h7"
              sx={{
                color: 'primary.main',
              }}
            >
              Quick Start
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Enter your DOT or MC to autofill relevant information
            </Typography>
          </Box>
        </Box>
      );
    };
    return (
      <Box
        className={'AddNewCarrierForm-root'}
        sx={styles.AddNewCarrierFormRoot}
      >
        <Box className={'Accordion-root'} sx={styles.AccordionRoot}>
          <Accordion
            key={`AddNewCarrierForm`}
            name={'Quick Start'}
            summaryRenderer={summaryRenderer}
          >
            <ThemeProvider theme={theme}>
              <SearchForm onSelected={setExternalCarrier} />
            </ThemeProvider>
          </Accordion>
        </Box>
        <Box>
          <GenerateForm canEdit={true} />
          <AddNewInsuranceForm isCreatePanel={true} canEdit={true} />
          <AddNewContactForm isCreatePanel={true} canEdit={true} />
        </Box>
      </Box>
    );
  }
);
