import { DeleteButtonIcon } from '../../ui-kit/components/Assets';

type contactDeleteActionType = {
  id?: number;
  label: string;
  icon: any;
};

export const contactDeleteAction: contactDeleteActionType = {
  label: 'Delete',
  icon: DeleteButtonIcon,
};

export enum UserDetailsTabs {
  GeneralWithTerminal = 'GENERAL_WITH_TERMINAL',
  General = 'GENERAL',
  DriverDetails = 'DRIVER_DETAILS',
  Documents = 'DOCUMENTS',
  PaymentTerms = 'PAYMENT_TERMS',
  SafetyAlerts = 'SAFETY_ALERTS',
  Preferences = 'PREFERENCES',
  Notes = 'NOTES',
}
