import moment from 'moment';
import { customerService } from '../../../../api';
import { RelayStopFormData } from '../../../../common/LoadTabPanel/tabs/Routes/components/RelayStopPopup/RelayStopForm';
import { GetCustomerDetailsByIdRequest, Stop } from '../../../../models';
import { LOAD_TYPE_KEYS } from '../../../../subPages/loadsList/CreateLoad/constants/fieldValues';
import {
  DEFAULT_HANDLING_TIME,
  initialState,
} from '../../../../subPages/loadsList/CreateLoad/data.config';
import {
  CreateLoadFormContact,
  CreateLocationRequestDTO,
  StopContactDTO,
} from '../../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import {
  LoadCommodityToCreateLoadDTO,
  TemplateResToLoadRateDTO,
  formatStopContactName,
  formatTemplateResLocationDTO,
  getRequiredDocsList,
} from '../../../../subPages/loadsList/CreateLoad/dto/template-res.dto';
import { ReeferModeEnum } from '../../../../subPages/loadsList/CreateLoad/schema/load';
import { StopTypeEnum } from '../../../../subPages/loadsList/CreateLoad/schema/stops';
import { stopFormService } from '../../../../subPages/loadsList/CreateLoad/services/stopForm.service';
import {
  AppointmentConfirmationEnum,
  AppointmentConfirmationType,
  CreateLocationResDTO,
  ILoadStop,
} from '../../../../subPages/loadsList/CreateLoad/types/requests';
import {
  ActivityType,
  ICustomerObject,
  ILoad,
  ILoadDetails,
  ILoadRateInvoice,
} from '../../../../subPages/loadsList/CreateLoad/types/types';
import { LoadDetailsSummary } from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';

type NumStringType = string | number | null;
export class LoadBoardStopRequestDTO implements ILoadStop {
  type?: ActivityType;
  estimatedActivityDuration?: number;
  relayDropOffEstimatedActivityDuration?: number;
  referenceNumber?: string;
  notes?: string;
  fixedAppointment?: boolean;
  appointmentStartDate?: string;
  appointmentEndDate?: string;
  appointmentStartTime?: string;
  appointmentEndTime?: string;
  relayDropOffAppointmentStartDate?: string;
  relayDropOffAppointmentStartTime?: string;
  relayDropOffAppointmentEndDate?: string;
  relayDropOffAppointmentEndTime?: string;
  isDriverAssistRequired?: boolean;
  isRelayDropOffDriverAssistRequired?: boolean;
  relayPickupRevenueShare?: NumStringType;
  relayDropOffRevenueShare?: NumStringType;
  appointmentConfirmation?: AppointmentConfirmationType;
  dropOffAppointmentConfirmation?: AppointmentConfirmationType;
  location: CreateLocationResDTO;
  contacts?: any[];
  noteDetails?: any;
  sequenceNumber?: string | null;
  stopCategory?: string | null;
  stopStatus?: string | null;
  timezone?: string | null;
  contact?: CreateLoadFormContact;
  isEndTimeVisible?: boolean;
  enableTimezone?: boolean;

  constructor(stop: Stop) {
    this.type = (stop?.activityType ??
      (stop as any)?.stopType ??
      StopTypeEnum.TRIP_STOP) as ActivityType;
    this.estimatedActivityDuration =
      stop?.avgHandlingTime ?? stop?.estimatedActivityDuration;
    this.referenceNumber = stop?.refNumber;
    this.notes = stop?.notes;
    this.fixedAppointment = Boolean(stop?.fixedAppointment);
    this.appointmentStartDate = !!(
      stop?.appointmentStartDate && stop.location?.timezone
    )
      ? moment(stop?.appointmentStartDate)
          .tz(stop.location?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop.appointmentStartDate;
    this.appointmentEndDate = !!(
      stop?.appointmentEndDate && stop.location?.timezone
    )
      ? moment(stop?.appointmentEndDate)
          .tz(stop.location?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop.appointmentEndDate ?? this.appointmentStartDate;

    this.appointmentStartTime = stop?.location?.timezone
      ? moment(stop?.appointmentStartDate)
          .tz(stop.location?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : this.appointmentStartDate;

    this.appointmentEndTime = stop?.location?.timezone
      ? moment(stop?.appointmentEndDate)
          .tz(stop.location?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : this.appointmentEndDate;
    this.isDriverAssistRequired = stop?.isDriverAssistRequired;
    this.appointmentConfirmation =
      stop?.appointmentConfirmation ?? AppointmentConfirmationEnum.NO;
    this.location = formatTemplateResLocationDTO({
      ...stop?.location,
      ...stop?.addressDTO,
    } as CreateLocationRequestDTO);
    this.noteDetails = stop?.noteDetails;
    this.sequenceNumber = stop?.sequenceNumber;
    this.stopStatus = stop?.stopStatus;
    this.timezone = stop?.addressDTO?.timezone;
    this.isEndTimeVisible = !stop?.fixedAppointment;
    // this.stopCategory = stop?.stopCategory;
    // this.contacts = stop?.contacts!;
    this.contact = {
      id: stop.contact?.id,
      contactName:
        stop.contact?.fullName ?? formatStopContactName(stop?.contact!),
      countryCode: stop.contact?.countryCode,
      phone: stop.contact?.phone,
      extension: stop.contact?.extension,
      email: stop.contact?.email,
    };
    this.enableTimezone = false;
  }
}

export class RelayStopToCreateLoadFormDTO implements ILoadStop {
  type?: ActivityType;
  estimatedActivityDuration?: number;
  relayDropOffEstimatedActivityDuration: number;
  referenceNumber?: string;
  notes?: string;
  fixedAppointment?: boolean;
  relayDropOffFixedAppointment?: boolean;
  appointmentStartDate?: string | null;
  appointmentEndDate?: string | null;
  appointmentStartTime?: string;
  appointmentEndTime?: string;
  relayDropOffAppointmentStartDate: string | null;
  relayDropOffAppointmentStartTime: string;
  relayDropOffAppointmentEndDate: string;
  relayDropOffAppointmentEndTime: string;
  isDriverAssistRequired?: boolean;
  isRelayDropOffDriverAssistRequired: boolean;
  relayPickupRevenueShare: string | number | null;
  relayDropOffRevenueShare: string | number | null;
  appointmentConfirmation?: AppointmentConfirmationType;
  dropOffAppointmentConfirmation: AppointmentConfirmationType;
  location: CreateLocationResDTO;
  contacts?: StopContactDTO[];
  noteDetails?: any;
  sequenceNumber?: string | null;
  stopCategory?: string | null;
  stopStatus?: string | null;
  timezone?: string | null;
  contact?: CreateLoadFormContact;
  isEndTimeVisible?: boolean;
  isRelayDropOffEndTimeVisible?: boolean;
  constructor(stop: RelayStopFormData) {
    this.type = 'RELAY';
    this.estimatedActivityDuration = stop?.pickup?.handlingTime
      ? Number(stop?.pickup?.handlingTime)
      : DEFAULT_HANDLING_TIME;
    this.relayDropOffEstimatedActivityDuration = stop.dropOff?.handlingTime
      ? Number(stop.dropOff?.handlingTime)
      : DEFAULT_HANDLING_TIME;
    this.referenceNumber = stop?.refNumber as string;
    this.notes = stop?.noteDetails?.note;
    this.fixedAppointment = Boolean(stop?.pickup?.fixedAppointment);
    this.relayDropOffFixedAppointment = Boolean(stop.dropOff?.fixedAppointment);
    //format dates
    this.appointmentStartDate = !!(
      stop?.pickup?.startDate && stop?.address?.timezone
    )
      ? moment(stop?.pickup?.startDate)
          .tz(stop.address?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop?.pickup?.startDate!;
    this.appointmentEndDate = !!(
      stop?.pickup?.endDate && stop?.address?.timezone
    )
      ? moment(stop?.pickup?.endDate)
          .tz(stop?.address?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop.pickup?.endDate!;
    this.appointmentStartTime = stop?.address?.timezone
      ? moment(stop?.pickup?.startDate)
          .tz(stop.address?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : this.appointmentStartDate;
    this.appointmentEndDate = stop?.address?.timezone
      ? moment(stop?.pickup?.endDate)
          .tz(stop.address?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : this.appointmentEndDate;

    //dropoff
    this.relayDropOffAppointmentStartDate = !!(
      stop?.dropOff?.startDate && stop?.address?.timezone
    )
      ? moment(stop?.dropOff?.startDate)
          .tz(stop?.address?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop?.dropOff?.startDate!;
    this.relayDropOffAppointmentEndDate = !!(
      stop?.dropOff?.endDate && stop.address?.timezone
    )
      ? moment(stop?.dropOff?.endDate)
          .tz(stop?.address?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : stop.dropOff?.endDate!;
    this.relayDropOffAppointmentStartTime = stop?.address?.timezone
      ? moment(stop?.dropOff?.startDate)
          .tz(stop.address?.timezone!, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : this.relayDropOffAppointmentStartDate!;
    this.relayDropOffAppointmentEndTime = stop?.address?.timezone
      ? moment(stop?.dropOff?.endDate)
          .tz(stop.address?.timezone, false)
          .format('YYYY-MM-DDTHH:mm:ss')
      : this.relayDropOffAppointmentEndDate;

    this.isDriverAssistRequired = Boolean(stop?.pickup?.driverAssist);
    this.isRelayDropOffDriverAssistRequired = Boolean(
      stop?.dropOff?.driverAssist
    );
    this.appointmentConfirmation =
      stop?.pickup?.appointmentConfirmation ?? AppointmentConfirmationEnum.NO;
    this.dropOffAppointmentConfirmation =
      stop?.dropOff?.appointmentConfirmation ?? AppointmentConfirmationEnum.NO;
    this.relayPickupRevenueShare = stop.pickup?.revenueShare as string;
    this.relayDropOffRevenueShare = stop.dropOff?.revenueShare as string;
    this.location = formatTemplateResLocationDTO({
      ...stop?.businessName,
      ...stop.address,
    } as CreateLocationRequestDTO);
    this.noteDetails = stop?.noteDetails;
    this.sequenceNumber = stop?.seqNumber;
    this.stopStatus = stop?.stopStatus;
    this.timezone = stop?.address?.timezone;
    // this.stopCategory = stop?.stopCategory;
    // this.contacts = stop?.contacts!;
    this.contact = {
      id: stop.contact?.id,
      contactName:
        stop.contact?.firstname ?? formatStopContactName(stop?.contact),
      countryCode: stop.contact?.countryCode,
      phone: stop.contact?.phone,
      extension: stop.contact?.extension,
      email: stop.contact?.email,
    };
    this.isEndTimeVisible = !stop?.pickup?.fixedAppointment;
    this.isRelayDropOffEndTimeVisible = !stop?.dropOff?.fixedAppointment;
  }
}

export class CreateLoadBoardToCreateLoadDTO implements ILoadDetails {
  templateId?: string;
  templateName?: string;
  customer: ICustomerObject;
  id?: string;
  load?: ILoad;
  numberOfStops?: number;
  stops: ILoadStop[];
  terminal?: any;
  totalMiles?: string | number;
  isTotalMilesManuallyUpdate?: boolean;
  rate?: ILoadRateInvoice;
  loadType?: string;
  isTerminalEnabled?: boolean;
  isManualLoadCreation?: boolean;
  constructor(
    data: LoadDetailsSummary,
    isTerminalEnabled: boolean,
    allTerminals: Array<any>
  ) {
    let terminal = data?.terminal;
    if (!terminal) {
      terminal = allTerminals?.find?.((e: any) => e?.isPrimary);
    }
    this.isManualLoadCreation = true;
    this.isTerminalEnabled = isTerminalEnabled;
    this.customer = {
      id: data.customer?.id,
      customerContactId: (data?.contact?.id as number) ?? '',
      customerId: data?.customer?.id ?? '',
      customerName: data?.customer?.name ?? '',
      requiredDocuments: getRequiredDocsList({
        bol: data?.billOfLadingMandatory,
        pod: data?.proofOfDeliveryMandatory,
      }),
      factoringId: data?.factoring?.id,
      referenceNumber: data?.referenceId,
      invoice: isTerminalEnabled ? terminal?.id : '',
      invoiceCompanyName: isTerminalEnabled
        ? terminal?.name ?? terminal?.companyName
        : '',
      factoringName: data?.factoring?.displayName ?? '',
      factoringChargePercent: (data.factoring as any)?.factoringChargePercent!,
    };
    this.loadType = data?.loadType;
    this.load = {
      equipment: data?.equipmentTypes?.map((e) => e?.key)!,
      loadType: data?.loadType ?? LOAD_TYPE_KEYS.FTL,
      notes: data?.notes,
      weight: data?.totalWeight,
      seal: data?.sealNumber ?? null,
      equipmentAdditionalDetails: {
        chassisNumber: data?.chassisNumber ?? null,
        containerNumber: data?.containerNumber ?? null,
        importExport: data?.loadDirection ?? null,
        bookingNumber: data?.bookingNumber ?? null,
        appointmentNumber: data?.appointmentNumber ?? null,
        reeferMode:
          (data?.reeferMode as ReeferModeEnum) ?? ReeferModeEnum.CONTINUOUS,
      },
      commodities: data?.commodityData?.map?.((e) => ({
        ...e,
        comodity: e?.commodityTypeId,
      })) as Array<any>,
    };
    this.stops = data?.stops?.length
      ? data?.stops?.map?.((stop, i: number) => {
          if (i === data?.stops?.length - 1) {
            stop.activityType = StopTypeEnum.DROPOFF;
          }
          if (
            (stop as unknown as Stop)?.activityType === StopTypeEnum.RELAY ||
            (stop as RelayStopFormData)?.stopType === StopTypeEnum.RELAY
          )
            return new RelayStopToCreateLoadFormDTO(stop as RelayStopFormData);
          return new LoadBoardStopRequestDTO(stop as unknown as Stop);
        })
      : initialState.stops;
    this.rate = {
      totalRate: data?.revenue ? `${data?.revenue}` : null,
    };
    this.totalMiles = data?.invoice?.loadedMiles;
    this.isTotalMilesManuallyUpdate = true;
  }
}

export const formatLoadSummaryToCreateLoadForm = async ({
  loadDetails,
  isTerminalEnabled,
  allTerminals,
}: {
  loadDetails: LoadDetailsSummary;
  isTerminalEnabled?: boolean;
  allTerminals?: Array<any>;
}) => {
  const createLoadSchema: ILoadDetails = new CreateLoadBoardToCreateLoadDTO(
    loadDetails,
    isTerminalEnabled!,
    allTerminals!
  );
  const data = {
    ...loadDetails,
    invoice: {
      notes: loadDetails.invoiceNotes,
      invoiceTerm: loadDetails?.invoiceTerm,
      revenue: loadDetails.revenue,
    },
  };
  const templateToRateDTOInstance = new TemplateResToLoadRateDTO(data as any);
  const templateCommodityResDTO = new LoadCommodityToCreateLoadDTO();
  const request = new GetCustomerDetailsByIdRequest();
  request.id = loadDetails.customer?.id!;
  try {
    const [rateFormDetails, totalMiles, formattedCommodity, customerDetails] =
      await Promise.all([
        templateToRateDTOInstance.fetchAndUpdateLineItemValuesById(
          loadDetails?.lineItems as any
        ),
        stopFormService.getTotalMiles(createLoadSchema?.stops),
        templateCommodityResDTO.fetchAndMapCommodityTypes(
          loadDetails?.commodityData as any
        ),
        request?.id ? customerService.getCustomerDetailsById(request) : null,
      ]);
    const selectedPhone = customerDetails?.contacts?.find(
      (e) => e?.id === loadDetails.contact?.id
    );
    createLoadSchema.customer.contacts = customerDetails?.contacts ?? [];
    createLoadSchema.customer.selectedPhoneData = selectedPhone ?? null;
    createLoadSchema.customer.selectedCustomer = customerDetails ?? null;
    createLoadSchema.rate = rateFormDetails;
    createLoadSchema.rate.messageOnInvoice = loadDetails.factoringNotes;
    createLoadSchema.totalMiles = totalMiles;
    if (createLoadSchema?.load)
      createLoadSchema.load.commodities = formattedCommodity;
    return createLoadSchema;
  } catch (error) {
    return new CreateLoadBoardToCreateLoadDTO(
      loadDetails,
      isTerminalEnabled!,
      allTerminals!
    );
  }
};
