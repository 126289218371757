import { PaginatedNamesListRequest } from '../../../../models';
import { httpClient } from '../../../../axios/axiosInstance';
import { pageSize } from '../constants/constants';

const customerListUrl = '/web/customer/api/v2/customers/search';

export const getCustomerList = async (text: string, pageNumber: number) => {
  try {
    const requestData = new PaginatedNamesListRequest({
      pageNumber,
      pageSize,
      keyword: text,
      customerName: text,
    });

    const response = await httpClient.getRaw(
      customerListUrl,
      requestData,
      false,
      true
    );
    return response?.data || [];
  } catch (error) {
    return [];
  }
};
