import { Box, BoxProps, Divider } from '@mui/material';
import { styled } from '@mui/styles';
import React, { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import { TripPlanV2Finish, TripPlanV3Complete } from '../icons/loadSearch';

const encodeSvg = (reactElement: ReactElement): string => {
  return (
    'data:image/svg+xml,' +
    encodeURIComponent(ReactDOMServer.renderToStaticMarkup(reactElement))
  );
};

export const StyledDivider = styled((props: any) => (
  <Divider
    {...props}
    sx={{
      color: 'primary.main',
      '.MuiTypography-root': {
        fontFamily: (theme: any) => theme.typography.body2Bold.fontFamily,
        fontSize: (theme: any) => theme.typography.body2Bold.fontSize,
        fontWeight: (theme: any) => theme.typography.body2Bold.fontWeight,
        lineHeight: (theme: any) => theme.typography.body2Bold.lineHeight,
      },
      '::before': {
        borderTop: (theme) => `2px solid ${theme.palette.primary.main}`,
      },
      '::after': {
        borderTop: (theme) => `2px solid ${theme.palette.primary.main}`,
      },
    }}
  />
))(() => ({}));

export const StyledImageFinish = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme, isCompleted = false }: { theme: any; isCompleted?: boolean }) => ({
  width: '100%',
  marginBottom: '30px',
  minHeight: '230px',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${encodeSvg(
    <TripPlanV2Finish
      {...(isCompleted && {
        color: theme.palette.primary.main,
      })}
    />
  )})`,
}));

export const StyledImageComplete = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme, isCompleted = false }: { theme: any; isCompleted?: boolean }) => ({
  width: '100%',
  minHeight: '50px',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${encodeSvg(
    <TripPlanV3Complete
      {...(isCompleted && {
        color: theme.palette.primary.main,
      })}
    />
  )})`,
}));
