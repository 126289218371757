import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { PackageTypesResponse } from '../../../../models/DTOs/Loads/Loads';
import { FieldInfo } from '../../../../subPages/loadsList/LoadDetailsPanel/components/FieldInfo';
import { StyledFieldInfoContainer } from '../../../../subPages/loadsList/LoadDetailsPanel/styles';
import AxeleAccordion from '../../../../ui-kit/components/Accordion';
import { DeleteButtonIcon } from '../../../../ui-kit/components/Assets';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../Permission';
import { commodityFormRequiredFields } from '../../constants';
import CommodityForm from '../../forms/CommodityForm';
import { CommodityFormRequiredFieldsType } from '../../types';
import { getDimensions, getTotalWeight } from './utils';

export const CommodityCard = ({
  id,
  packageTypeOptions,
  showCollapsedViewInfo = false,
  isViewMode = false,
  deleteCommodity,
  requiredFields = {
    ...commodityFormRequiredFields,
  },
}: {
  id: number;
  packageTypeOptions: PackageTypesResponse[];
  showCollapsedViewInfo?: boolean;
  isViewMode?: boolean;
  requiredFields?: CommodityFormRequiredFieldsType;
  deleteCommodity?: (id: number) => void;
}) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [commodityData, commodityTypeDisplayValue] = watch([
    'commodityData',
    `commodityData.${id}.commodityTypeDisplayValue`,
  ]);

  const handleDeleteCommodity = (event: React.MouseEvent) => {
    event.stopPropagation();
    let shouldDirty = true;
    if (typeof deleteCommodity === 'function') {
      deleteCommodity(id);
      shouldDirty = false;
    }
    commodityData.splice(id, 1);
    setValue('commodityData', commodityData?.length ? commodityData : null, {
      shouldDirty,
    });
    setValue('totalWeight', getTotalWeight(commodityData));
  };

  const titleRenderer = (expanded: boolean | string) => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant={'h7'}
            sx={{
              color: 'text.primary',
            }}
          >
            {commodityTypeDisplayValue}
          </Typography>
          {showCollapsedViewInfo ? subTitleRenderer(expanded) : null}
        </Box>
        <Permission contains={[AXELE_PERMISSION_TYPE.LOAD_EDIT]}>
          <Box sx={{ marginLeft: '15px' }}>
            <IconButton onClick={handleDeleteCommodity}>
              <DeleteButtonIcon width={12} height={16} color="#F44336" />
            </IconButton>
          </Box>
        </Permission>
      </Box>
    );
  };

  const subTitleRenderer = (expanded: boolean | string) => {
    if (!expanded) {
      const [qty, length, width, height, weight, volume, description] = watch([
        `commodityData.${id}.qty`,
        `commodityData.${id}.length`,
        `commodityData.${id}.width`,
        `commodityData.${id}.height`,
        `commodityData.${id}.weight`,
        `commodityData.${id}.volume`,
        `commodityData.${id}.description`,
      ]);

      return (
        <Box>
          <StyledFieldInfoContainer>
            <FieldInfo name="Quantity: " value={qty ? `${qty}` : ''} />
            <FieldInfo
              name="Dimensions: "
              value={getDimensions(length, width, height)}
            />
            <FieldInfo name="Weight: " value={weight ? `${weight}lbs.` : ''} />
            <FieldInfo
              name="Volume: "
              value={volume ? `${volume}cu.ft.` : ''}
            />
          </StyledFieldInfoContainer>
          <StyledFieldInfoContainer>
            <FieldInfo name="Description: " value={description} />
          </StyledFieldInfoContainer>
        </Box>
      );
    }
    return null;
  };
  return (
    <AxeleAccordion
      titleRenderer={titleRenderer}
      errors={(errors.commodityData?.length && errors.commodityData[id]) || {}}
    >
      <Grid container spacing={1}>
        <CommodityForm
          id={id}
          nameStart={`commodityData.${id}`}
          isViewMode={isViewMode}
          packageTypeOptions={packageTypeOptions}
          requiredFields={requiredFields}
        />
      </Grid>
    </AxeleAccordion>
  );
};
