import { ArrowDropDownOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro-v5';
import { LocalizationProvider } from '@mui/x-date-pickers-v5';
import { debounce } from 'lodash';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import moment, { Moment } from 'moment';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import InputLabel from '../../InputLabel';
import Datepicker from '../Datepicker';
import { useTimezoneAdapterMoment } from '../Utils';
import { RelativeDateRangeUiKitUtils } from './RelativeDateRange.uikit.utils';
import { getRelativeDateRangeStyles } from './styles';
import {
  ERelativeDateRangeKey,
  ERelativeDateRangeUnit,
  IRelativeDateRangeOption,
} from './types';

export interface RelativeDateRangeProps {
  id?: string;
  name: string;
  label: string;
  options: IRelativeDateRangeOption[];
  defaultOption?: IRelativeDateRangeOption;
  onChange?: (option: IRelativeDateRangeOption, name?: string) => void;
  startDateShow?: boolean;
  startDateDisable?: boolean;
  startDateRequire?: boolean;
  startDateDisableFuture?: boolean;
  endDateShow?: boolean;
  endDateDisable?: boolean;
  endDateRequire?: boolean;
  endDateDisableFuture?: boolean;
  isSingleDate?: boolean;
  isFormControl?: boolean;
  disabled?: boolean;
  dateFormat?: string;
  dateSelectionFormat?: string;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  width?: string;
  timezone?: string;
  clearable?: boolean;
  fixedLabel?: boolean;
  placeholder?: string;
  optionWidth?: string;
}

const defaultDateRangeValues: DateRange<Moment | null> = [null, null];
const defaultCustomOption: IRelativeDateRangeOption = {
  type: ERelativeDateRangeUnit.CUSTOMIZE,
  key: ERelativeDateRangeKey.PeriodTimeCustom,
  name: 'Custom',
  dateRange: [null, null],
};

export const RelativeDateRange = ({
  //startDate
  startDateShow = true,
  startDateDisable = false,
  startDateRequire = false,
  startDateDisableFuture = false,
  //endDate
  endDateShow = true,
  endDateDisable = false,
  endDateRequire = false,
  endDateDisableFuture = false,
  //common
  id,
  name,
  label,
  options,
  defaultOption,
  onChange,
  isSingleDate = false,
  disabled = false,
  dateFormat = 'MM/DD/YYYY',
  dateSelectionFormat = 'MM/DD',
  variant = 'standard',
  isFormControl,
  width = '270px',
  optionWidth,
  timezone,
  clearable = false,
  fixedLabel,
  placeholder,
}: RelativeDateRangeProps) => {
  const theme = useTheme();
  const styles = getRelativeDateRangeStyles({
    theme,
    isFormControl,
    width,
    optionWidth,
  });

  const adapter = useTimezoneAdapterMoment(timezone);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  });
  const [selectedOption, setSelectedOption] =
    useState<IRelativeDateRangeOption | null>(null);

  const [dateRangeCustomValue, setDateRangeCustomValue] = useState<
    DateRange<Moment | null>
  >(defaultDateRangeValues);

  const handleClose = () => {
    popupState?.close();
  };

  const currentPeriodDisplay: string = useMemo((): string => {
    if (!selectedOption) return '';
    const isCustomOption =
      selectedOption.key === ERelativeDateRangeKey.PeriodTimeCustom;
    const dateRangeText = RelativeDateRangeUiKitUtils.getDisplayOptionDateRange(
      {
        option: selectedOption,
        timezone,
        dateFormat: isCustomOption ? dateFormat : dateSelectionFormat,
        isSingleDate,
      }
    );
    if (!isCustomOption) return `${selectedOption.name} ${dateRangeText}`;
    if (!dateRangeCustomValue[0] && !dateRangeCustomValue[1]) {
      return '';
    }
    return dateRangeText;
  }, [
    selectedOption,
    timezone,
    dateFormat,
    dateSelectionFormat,
    isSingleDate,
    dateRangeCustomValue,
  ]);

  const onChangeDateHandler = ({
    newStartDate = null,
    newEndDate = null,
    isStartDate,
  }: {
    newStartDate?: Moment | null;
    newEndDate?: Moment | null;
    isStartDate: boolean;
  }): void => {
    const { finalStartDate, finalEndDate } =
      RelativeDateRangeUiKitUtils.correctFinalMomentDateRange({
        newStartDate,
        newEndDate,
        dateRangeCustomValue,
        isStartDate,
        isSingleDate,
      });
    const dateRange: DateRange<Moment | null> = [finalStartDate, finalEndDate];
    const customOption: IRelativeDateRangeOption = { ...defaultCustomOption };

    const isValidStartDate: boolean =
      (finalStartDate && finalStartDate?.isValid()) || finalStartDate === null;
    const isValidEndDate: boolean =
      (finalEndDate && finalEndDate?.isValid()) || finalEndDate === null;

    if (isSingleDate) {
      if (isValidStartDate) {
        customOption.dateRange = [dateRange?.[0]?.toDate() || null, null];
        setSelectedOption(customOption);
        setDateRangeCustomValue(dateRange);
        onChange?.(customOption, name);
      }
    } else {
      if (isStartDate) {
        if (isValidStartDate) {
          customOption.dateRange = [
            dateRange?.[0]?.toDate() || null,
            dateRange?.[1]?.toDate() || null,
          ];
          setSelectedOption(customOption);
          setDateRangeCustomValue(dateRange);
          onChange?.(customOption, name);
        }
      } else {
        if (isValidEndDate) {
          customOption.dateRange = [
            dateRange?.[0]?.toDate() || null,
            dateRange?.[1]?.toDate() || null,
          ];
          setSelectedOption(customOption);
          setDateRangeCustomValue(dateRange);
          onChange?.(customOption, name);
        }
      }
    }
  };

  const debouncedOnChangeDateHandler = useCallback(
    debounce(onChangeDateHandler, 300),
    [dateRangeCustomValue]
  );

  const onChangeUnitOptionHandler = (
    option: IRelativeDateRangeOption
  ): void => {
    setSelectedOption(option);
    setDateRangeCustomValue(defaultDateRangeValues);
    onChange?.(option, name);
    handleClose();
  };

  const onClearHandler = (event: SyntheticEvent<Element, Event>) => {
    const customOption = { ...defaultCustomOption };
    setSelectedOption(customOption);
    setDateRangeCustomValue(defaultDateRangeValues);
    onChange?.(customOption, name);
    event?.stopPropagation();
  };

  const init = useCallback(() => {
    if (defaultOption) {
      const finalOption =
        defaultOption.key === ERelativeDateRangeKey.PeriodTimeCustom
          ? defaultOption
          : options.find((option) => option.key === defaultOption.key);

      if (
        !isSingleDate &&
        finalOption?.dateRange?.[0] &&
        finalOption?.dateRange?.[1]
      ) {
        if (
          moment(finalOption.dateRange[1]).isBefore(
            moment(finalOption.dateRange[0])
          )
        ) {
          finalOption.dateRange[1] = finalOption.dateRange[0];
        }
      }

      if (finalOption) {
        setSelectedOption(finalOption);
        if (
          !RelativeDateRangeUiKitUtils.isSameDateRanges(
            finalOption.dateRange,
            defaultOption.dateRange
          )
        ) {
          onChange?.(finalOption, name);
        }

        if (finalOption.dateRange) {
          const newDateRange: DateRange<Moment | null> = [
            RelativeDateRangeUiKitUtils.fromNativeDateToMomentDate(
              finalOption.dateRange[0],
              timezone
            ),
            RelativeDateRangeUiKitUtils.fromNativeDateToMomentDate(
              finalOption.dateRange[1],
              timezone
            ),
          ];
          setDateRangeCustomValue(newDateRange);
        }
      }
    }
  }, [defaultOption, isSingleDate, name, onChange, options, timezone]);

  const isNullDateRanges = useMemo(() => {
    return RelativeDateRangeUiKitUtils.isNullDateRanges(
      selectedOption?.dateRange || null
    );
  }, [selectedOption]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <FormControl sx={{ width: '100%' }} {...bindTrigger(popupState)}>
        <TextField
          id={id}
          size="small"
          sx={styles.TextField}
          InputLabelProps={{
            sx:
              variant === 'outlined' && !currentPeriodDisplay
                ? {
                    ...styles.TextFieldInputLabelProps,
                    transform: 'translate(18px, 6px) scale(1)',
                  }
                : styles.TextFieldInputLabelProps,
            fixedLabel,
          }}
          label={fixedLabel && label ? <InputLabel>{label}</InputLabel> : label}
          variant={variant}
          InputProps={{
            endAdornment: (
              <InputAdornment
                disablePointerEvents={disabled}
                position="end"
                sx={{ ml: 0 }}
              >
                {!disabled && clearable && !isNullDateRanges && (
                  <IconButton onClick={onClearHandler} sx={{ p: 0 }}>
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  disabled={disabled}
                  sx={{ p: 0 }}
                  {...(!isFormControl && {
                    edge: 'end',
                  })}
                >
                  <ArrowDropDownOutlined fontSize="medium" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={currentPeriodDisplay}
          disabled={disabled}
          placeholder={placeholder}
        />
      </FormControl>

      {!disabled && (
        <Menu
          sx={styles.Menu}
          {...bindMenu(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {(startDateShow || endDateShow) && (
            <LocalizationProvider
              dateLibInstance={moment}
              dateAdapter={adapter}
            >
              {startDateShow && (
                <MenuItem>
                  <Datepicker
                    initialDate={RelativeDateRangeUiKitUtils.fromMomentDateToNativeDate(
                      dateRangeCustomValue?.[0],
                      timezone
                    )}
                    timezone={timezone}
                    label={'Start Date'}
                    styleProps={styles.MenuItemDatepicker}
                    onDateChange={(newStartDate: Moment | null) => {
                      debouncedOnChangeDateHandler({
                        newStartDate,
                        isStartDate: true,
                      });
                    }}
                    disabled={startDateDisable}
                    disableFuture={startDateDisableFuture}
                    required={startDateRequire}
                  />
                </MenuItem>
              )}
              {endDateShow && (
                <MenuItem>
                  <Datepicker
                    initialDate={RelativeDateRangeUiKitUtils.fromMomentDateToNativeDate(
                      dateRangeCustomValue?.[1],
                      timezone
                    )}
                    minDate={RelativeDateRangeUiKitUtils.fromMomentDateToNativeDate(
                      dateRangeCustomValue?.[0],
                      timezone
                    )}
                    timezone={timezone}
                    label={'End Date'}
                    styleProps={styles.MenuItemDatepicker}
                    onDateChange={(newEndDate: Moment | null) => {
                      debouncedOnChangeDateHandler({
                        newEndDate,
                        isStartDate: false,
                      });
                    }}
                    disabled={endDateDisable}
                    disableFuture={endDateDisableFuture}
                    required={endDateRequire}
                  />
                </MenuItem>
              )}
              <Divider />
            </LocalizationProvider>
          )}

          {options.map((option: IRelativeDateRangeOption) => {
            return (
              <MenuItem
                selected={option.key === selectedOption?.key}
                key={option.key}
                onClick={() => {
                  onChangeUnitOptionHandler(option);
                }}
                sx={styles.MenuItem}
              >
                <Typography variant="body1" sx={styles.MenuItemTitle}>
                  {option.name}
                </Typography>
                <Typography variant="body2" sx={styles.MenuItemSubTitle}>
                  {RelativeDateRangeUiKitUtils.getDisplayOptionDateRange({
                    option,
                    timezone,
                    dateFormat,
                    isSingleDate,
                  })}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Box>
  );
};
