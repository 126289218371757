import React from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, Box, Typography } from '@mui/material';

/* @TODO this file should be rewritten */

const styles = {
  iconBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: 32,
    height: 30,
    // background: '#2B64CB',
    // boxShadow:
    //   ' 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '6px',
    border: '1px solid #669EEF',
  },
  iconBtnSelected: {
    background: '#2B64CB',
  },
  label: {
    color: 'rgba(0, 17, 34, 0.6)',
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: '0.15px',
  },
  subLabel: {
    fontSize: 12,
    letterSpacing: '0.4px',
    color: 'rgba(0, 17, 34, 0.6)',
    marginTop: -8,
    paddingLeft: 48,
  },
};

export const IconCheckbox: React.FC<{
  name: string | undefined;
  control?: any;
  required?: boolean;
  label?: string;
  subLabel?: string;
  countryCode?: string;
  icon: any;
  disableChange?: boolean;
}> = ({
  control,
  name = '',
  icon: Icon,
  label,
  subLabel,
  disableChange = false,
}) => {
  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        return (
          <Box style={{ width: 'fit-content' }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => {
                  if (!disableChange) {
                    onChange(!value);
                  }
                }}
              >
                <Box
                  sx={{
                    ...styles.iconBtn,
                    ...(value && styles.iconBtnSelected),
                  }}
                >
                  <Icon
                    color={value ? '#ffffff' : '#2B64CB'}
                    width={14}
                    height={14}
                  />
                </Box>
              </IconButton>
              <Typography style={styles.label}>{label}</Typography>
            </Box>

            <Typography style={styles.subLabel}>{subLabel}</Typography>
          </Box>
        );
      }}
      name={name}
      control={control}
      defaultValue=""
    />
  );
};

export const IconCheckboxComponent = ({
  label,
  subLabel,
  icon: Icon,
}: {
  label: string;
  subLabel: string;
  icon: React.ElementType;
}) => {
  return (
    <Box style={{ width: 'fit-content' }}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton aria-label="close">
          <Box
            sx={{
              ...styles.iconBtn,
              ...styles.iconBtnSelected,
            }}
          >
            <Icon color={'#ffffff'} width={14} height={14} />
          </Box>
        </IconButton>
        <Typography style={styles.label}>{label}</Typography>
      </Box>
      <Typography style={styles.subLabel}>{subLabel}</Typography>
    </Box>
  );
};
