import { useEffect, useState } from 'react';
import { fuelCardService } from '@/api';
import { Provider } from '@/models/DTOs/FuelCards/FuelCards';
import { ServiceError } from '@/api/interfaces';
import { GetFuelCardProvidersRequest } from '@/models/DTOs/FuelCards/Requests';

export const useFuelCards = () => {
  const [providers, setProviders] = useState<Provider[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<
    Provider | undefined
  >();

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = async () => {
    const request = new GetFuelCardProvidersRequest();
    const result = await fuelCardService.getFuelCardProviders(request);
    if (result instanceof ServiceError) {
      return;
    }
    setProviders(result);
    setSelectedProvider(result[0]);
  };

  const handleSelectedProviderChange = (provider: Provider | undefined) => {
    setSelectedProvider(provider);
  };

  return {
    providers,
    selectedProvider,
    handleSelectedProviderChange,
    getProviders,
  };
};
