import { plainToInstance } from 'class-transformer';
import {
  PaginatedCustomersList,
  customerExcelExportRequest,
} from '../../models';
import { annotateNameAsync, IExportService } from '../interfaces';
import axiosInstance, { ComposedError } from '../../axios/axiosInstance';

const getPaginatedCustomersListURL =
  '/web/customer/api/v2/customers/customer/export-to-excel';

// @TODO: Nvard - handle errors
export class ExcportService extends IExportService {
  @annotateNameAsync
  async getCustomersExportExcel(requestData: customerExcelExportRequest) {
    const params = requestData.getParamsFromRequest();
    try {
      const response = await axiosInstance.get(getPaginatedCustomersListURL, {
        params,
      });

      const data = await response.data;
      const customers = plainToInstance(PaginatedCustomersList, data);
      return Promise.resolve(customers);
    } catch (error) {
      return Promise.resolve(null);
    }
  }
}
