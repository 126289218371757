import { QueryParams } from '../commonMixed';
import { CreateLoadRequest } from '../Loads/Requests';

export class PaginatedTemplateListRequest extends QueryParams {
  constructor(
    public pageSize: number,
    public pageNumber: number,
    public templateName?: string,
    public loadTemplateIds?: string[],
    public customerIds?: string[],
    public from?: string[],
    public to?: string[],
    public terminalIds?: string[],
    public sort?: string,
    public nameKeyword?: string,
    public gridColumnMetadataList?: string[]
  ) {
    super();
  }
}

export class TemplateNameRequest extends QueryParams {
  public pageNumber!: number;
  public templateName?: string;
  public pageSize!: number;
  constructor() {
    super();
  }
}

export class TemplateDetailsRequest extends QueryParams {
  id!: string;
}

export class TemplateSummaryRequest extends QueryParams {
  loadTemplateId!: string;
}

export class CreateTemplateRequest extends CreateLoadRequest {
  templateName: string;
  assignedDocument: File;

  constructor(data: any) {
    super(data);
    this.templateName = data.newTemplateName;
    this.assignedDocument = data.assignedDocument;
  }
}
export class EditTemplateRequest extends CreateLoadRequest {
  id: string;
  templateName: string;
  constructor(data: any) {
    super(data);
    this.templateName = data.newTemplateName;
    this.id = data.id;
  }
}

export class DeleteTemplateRequest extends QueryParams {
  loadTemplateId!: string;
}
