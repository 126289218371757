import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box, Typography } from '@mui/material';
import { LoadValuesDisplay } from '../LoadValuesDisplay';
import { PopupActions } from '../Popup/PopupActions';
import { StyledPopupContent, StyledPopupRoot } from '../Popup/styles';

export interface DetailsTabProps {
  data: any;
  onBookHandler: (event: any, params: any, typeOfBook: string) => void;
  pendingLoadBookConfirmationStatus: any;
}
export const DetailsTab = ({
  data,
  onBookHandler,
  pendingLoadBookConfirmationStatus,
}: DetailsTabProps) => {
  const allDetails = [
    {
      heading: 'Broker Info',
      showAction: true,
      details: [
        { title: 'Name', value: data.name || '-' },
        { title: 'MC Number', value: data.mcNumber || '-' },
        { title: 'Email', value: data.email || '-' },
        { title: 'Phone Number', value: data.phoneNumber || '-' },
        { title: 'Credit Rating', value: data.creditRating || '-' },
      ],
    },
    {
      heading: 'Other',
      showAction: false,
      details: [
        { title: 'Weight', value: data.weight || '-' },
        { title: 'Equipment Type', value: data.equipmentType || '-' },
        { title: 'Reload Score', value: data.reloadScore || '-' },
        { title: 'Save for Later', value: '-' },
        { title: 'Length', value: data.length || '-' },
        { title: 'Load Type', value: data.loadType || '-' },
        { title: 'Current Score', value: data.currentScore || '-' },
        { title: 'Days To Pay', value: data.daysToPay || '-' },
        { title: 'Comment', value: data.comments || '-' },
      ],
    },
  ];

  return (
    <StyledPopupRoot>
      <StyledPopupContent>
        {allDetails.map((item, index) => {
          return (
            <Box key={index} sx={{ '&:last-child': { pt: '24px' } }}>
              <Box
                sx={{
                  pb: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant={'h6'}
                  sx={{
                    color: 'text.primary',
                  }}
                >
                  {item.heading}
                </Typography>
                {item.showAction && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      ml: '10px',
                    }}
                  >
                    {data?.customerPreference === 'PREFERRED' && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '1rem',
                        }}
                      >
                        <ThumbUpIcon sx={{ color: 'primary.main' }} />
                      </Box>
                    )}
                    {data?.customerPreference === 'PROHIBITED' && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '1rem',
                        }}
                      >
                        <ThumbDownIcon sx={{ color: 'error.main' }} />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              {item?.details.map((data2, index2) => {
                return (
                  <LoadValuesDisplay
                    title={data2.title}
                    value={data2.value}
                    key={index2}
                    isDetailTab={true}
                  />
                );
              })}
            </Box>
          );
        })}
      </StyledPopupContent>
      <PopupActions
        onBidHandler={() => {
          //
        }}
        onBookHandler={onBookHandler}
        pendingLoadBookConfirmationStatus={pendingLoadBookConfirmationStatus}
      />
    </StyledPopupRoot>
  );
};
