export const financeStyle = {
  financeContainer: {
    mt: 2,
    // height: '100%',
    paddingBottom: '40px',
  },
  totalText: {
    color: 'primary.main',
    fontSize: 16,
    fontWeight: '600',
  },
  FinanceOverviewTrip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    pl: 1,
    pr: 1,
    borderRadius: '5px',
    '&:hover': (theme) => ({
      backgroundColor: `${theme.palette.grey['100']}`,
    }),
  },
  FinanceOverviewTripLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    color: 'primary.main',
    maxWidth: '250px',
  },
  whiteSpaceClose: { color: 'primary.main' },
  totalCount: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'flex-end',
  },
  financeFooterCount: {
    display: 'flex',
    with: '100%',
    padding: '6px 4px',
  },
  addLineItemStyle: {
    fontSize: '13px',
    cursor: 'pointer',
    right: '20px',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'primary.main',
  },
  topButtons: {
    padding: '0px',
    height: '28px',
    minWidth: '38px',
    m: '0px 5px',
    cursor: 'pointer',
  },
};

export const FinanceTripPaymentWithLoader = {
  opactiy: 0.5,
  cursor: 'no-drop',
};

export const AddFinanceExpenseLoader = {
  opacity: 0.5,
  cursor: 'wait',
};
interface IStyles {
  shake?: React.CSSProperties;
  wrapper: React.CSSProperties;
}

export const styles: {
  expanded: IStyles;
  default: IStyles;
} = {
  expanded: {
    shake: {
      zIndex: 1250,
      padding: '50px 45px',
      inset: 0,
      position: 'fixed',
      backdropFilter: 'blur(10px)',
    },
    wrapper: {
      position: 'relative',
      zIndex: 1251,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      borderRadius: '18px',
      background: '#FFFFFF',
    },
  },
  default: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      width: '514px',
      minWidth: '514px',
      maxWidth: '514px',
      boxShadow: '0px 1px 12px rgba(78, 101, 120, 0.15)',
      borderRadius: '18px',
      background: '#FFFFFF',
      marginLeft: 20,
    },
  },
};
