import BaseTextField, { TextFieldProps } from '@mui/material/TextField';

const CustomizedTextField = ({ size = 'small', ...props }: TextFieldProps) => {
  return (
    <BaseTextField
      size={size}
      FormHelperTextProps={{ sx: { m: 0 } }}
      {...props}
    />
  );
};

export { CustomizedTextField as TextField };
