import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { dispatchService } from '../../../../../../api';
import { ServiceError } from '../../../../../../api/interfaces';
import { RoutesLoadContextProvider } from '../../../../../../contexts/LoadRoutesContext';
import {
  DispatchDriverGroupDetails,
  ITractorInfoForDriverAssign,
  ITrailerInfoForDriverAssign,
} from '../../../../../../models/DTOs/Dispatch/Dispatch';
import {
  DriverTractorAssignRequest,
  DriverTrailerAssignRequest,
} from '../../../../../../models/DTOs/Dispatch/Requests';
import {
  AssignTractorIcon,
  AssignTrailerIcon,
} from '../../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';
import IconsComponent from '../../../../../../ui-kit/components/IconsComponent';
import TextInput from '../../../../../../ui-kit/components/TextField';
import { IconBtnWrapper } from '../../../../../Ui/IconBtnWrapper/IconBtnWrapper';
import { useDispatchPermission } from '../../../../../hooks/useDispatchPermission';
import { getTripAssignmentComponentStyles } from '../styles/TripAssignmentComponent.styles';
import { entityTypes, unAssignConfig } from '../types';
import TractorAssignmentToDriver from './TractorAssignmentToDriver';
import TrailerAssignmentToDriver from './TrailerAssignmentToDriver';

export interface IDriverGroupAssignmentTrip {
  id?: string;
  driverGroupId: string;
  tractor?: { name: string; id: string } | null;
  trailer?: { name: string; id: string } | null;
}
interface DriverGroupAssignmentProps {
  trip: IDriverGroupAssignmentTrip | null;
  onChangedHandler?: (
    driverGroupDetails: Partial<DispatchDriverGroupDetails>
  ) => void;
}
export default function DriverGroupAssignment({
  trip,
  onChangedHandler,
}: DriverGroupAssignmentProps): JSX.Element {
  const theme = useTheme();
  const styles = getTripAssignmentComponentStyles(theme);
  const { hasDispatchEditPermission } = useDispatchPermission();
  const {
    driverGroupId,
    tractor: assignedTractor,
    trailer: assignedTrailer,
  } = trip;

  const [assignmentType, setAssignmentType] = useState<string | null>(null);
  const [unassignType, setUnassignType] = useState<entityTypes | undefined>();
  const [tractor, setTractor] = useState<object | null>(assignedTractor);
  const [trailer, setTrailer] = useState<object | null>(assignedTrailer);

  useEffect(() => {
    setTrailer(assignedTrailer);
  }, [assignedTrailer]);

  useEffect(() => {
    setTractor(assignedTractor);
  }, [assignedTractor]);

  const isDisabled = (type: string) =>
    !!assignmentType && assignmentType !== type;

  const settings: {
    [entityType: string]: {
      onClickActionIcon: () => Promise<void>;
      onClickAssignButton: () => void;
      apiAssignHandler: (
        assignedData: ITractorInfoForDriverAssign | ITrailerInfoForDriverAssign
      ) => Promise<void>;
      apiUnAssignHandler: () => Promise<void>;
      onAssign?: (newData: any) => void;
      onClose: () => void;
    };
  } = {
    tractor: {
      onClickActionIcon: async (): Promise<void> => {
        setUnassignType(entityTypes.TRACTOR);
      },
      onClickAssignButton: () => {
        setAssignmentType('TRACTOR');
      },
      apiAssignHandler: async (
        newTractor: ITractorInfoForDriverAssign
      ): Promise<void> => {
        const response = await dispatchService.driverTractorAssign(
          new DriverTractorAssignRequest({
            driverGroupId: driverGroupId,
            tractorId: newTractor.id,
          })
        );
        if (!(response instanceof ServiceError)) {
          setTractor({
            id: response.tractorId,
            name: response.tractorName,
          });
          onChangedHandler?.({
            tractorId: response.tractorId,
            tractorName: response.tractorName,
            tractorNumber: response.tractorName,
          });
        }
      },
      apiUnAssignHandler: async (): Promise<void> => {
        const response = await dispatchService.driverTractorUnAssign(
          new DriverTractorAssignRequest({
            driverGroupId: driverGroupId,
            tractorId: tractor?.id,
          })
        );
        if (!(response instanceof ServiceError)) {
          setTractor(null);
          setUnassignType(undefined);
          onChangedHandler?.({
            tractorId: null,
            tractorName: null,
            tractorNumber: null,
          });
        }
      },

      onClose: () => {
        setAssignmentType(null);
      },
    },
    trailer: {
      onClickActionIcon: async (): Promise<void> => {
        if (!trailer) {
          setAssignmentType(entityTypes.TRAILER);
        } else {
          setUnassignType(entityTypes.TRAILER);
        }
      },
      onClickAssignButton: () => {
        setAssignmentType('TRAILER');
      },
      // @TODO: Anna - In other parts of the project this part is written in controller
      //  I've used this same function in trailer part with a separate function in TrailerController
      // with a name driverTrailerAssign
      apiAssignHandler: async (
        newTrailer: ITrailerInfoForDriverAssign
      ): Promise<void> => {
        const response = await dispatchService.driverTrailerAssign(
          new DriverTrailerAssignRequest({
            driverGroupId: driverGroupId,
            trailerId: newTrailer.id,
          })
        );
        if (!(response instanceof ServiceError)) {
          setTrailer({
            id: response.trailerId,
            name: response.trailerName,
          });
          onChangedHandler?.({
            trailerId: response.trailerId,
            trailerName: response.trailerName,
            trailerNumber: response.trailerName,
          });
        }
      },
      apiUnAssignHandler: async (): Promise<void> => {
        const response = await dispatchService.driverTrailerUnAssign(
          new DriverTrailerAssignRequest({
            driverGroupId: driverGroupId,
            trailerId: trailer?.id,
          })
        );
        if (!(response instanceof ServiceError)) {
          setTrailer(null);
          setUnassignType(undefined);
          onChangedHandler?.({
            trailerId: null,
            trailerName: null,
            trailerNumber: null,
          });
        }
      },
      onClose: () => {
        setAssignmentType(null);
      },
    },
  };
  const onUnAssignHandler = () => {
    if (unassignType === entityTypes.TRACTOR) {
      settings.tractor.apiUnAssignHandler();
    }
    if (unassignType === entityTypes.TRAILER) {
      settings.trailer.apiUnAssignHandler();
    }
  };

  return (
    <RoutesLoadContextProvider>
      <Box sx={styles.root}>
        <Box component="span" sx={styles.btnWrapStyle}>
          {tractor ? (
            <Box sx={styles.TextInputWrapper}>
              <TextInput
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <Box component="span" sx={styles.TextInputStartAdornment}>
                      <IconsComponent
                        iconName="Tractor"
                        source="AxeleIcon"
                        styleProps={{ width: '20px' }}
                      />
                    </Box>
                  ),
                }}
                label={`${t('DriverGroupAssignmentTractor')}`}
                variant="standard"
                styleProps={styles.TextInput}
                defaultValue={tractor?.name || ''}
                disabled={!hasDispatchEditPermission}
              />
              <IconBtnWrapper
                disabled={isDisabled('TRACTOR') || !hasDispatchEditPermission}
                onClick={settings.tractor.onClickActionIcon}
                sx={{ height: 36, minWidth: 36 }}
              >
                <Close sx={{ width: '20px' }} />
              </IconBtnWrapper>
            </Box>
          ) : (
            <ButtonImproved
              disabled={isDisabled('TRACTOR') || !hasDispatchEditPermission}
              styleProps={styles.btnStyle}
              size="medium"
              color="primary"
              label={t('DriverGroupAssignmentAssignTractor')}
              onClick={settings.tractor.onClickAssignButton}
              startIcon={<AssignTractorIcon />}
              variant="contained"
            />
          )}
        </Box>
        <Box component="span" sx={styles.btnWrapStyle}>
          {trailer ? (
            <Box sx={styles.TextInputWrapper}>
              <TextInput
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <Box component="span" sx={styles.TextInputStartAdornment}>
                      <IconsComponent
                        iconName="Trailer"
                        source="AxeleIcon"
                        styleProps={{ width: '20px' }}
                      />
                    </Box>
                  ),
                }}
                label={`${t('DriverGroupAssignmentTrailer')}`}
                variant="standard"
                styleProps={styles.TextInput}
                defaultValue={trailer?.name || ''}
                disabled={!hasDispatchEditPermission}
              />
              <IconBtnWrapper
                disabled={isDisabled('TRAILER') || !hasDispatchEditPermission}
                onClick={settings.trailer.onClickActionIcon}
                sx={{ height: 36, minWidth: 36 }}
              >
                <Close sx={{ width: '20px' }} />
              </IconBtnWrapper>
            </Box>
          ) : (
            <ButtonImproved
              disabled={isDisabled('TRAILER') || !hasDispatchEditPermission}
              styleProps={styles.btnStyle}
              size="medium"
              color="primary"
              label={t('DriverGroupAssignmentAssignTrailer')}
              onClick={settings.trailer.onClickAssignButton}
              startIcon={
                <Box component="span" sx={{ marginRight: '10px' }}>
                  <AssignTrailerIcon />
                </Box>
              }
              variant="contained"
            />
          )}
        </Box>

        <Box className={'AssignmentFormRoot'} sx={styles.AssignmentFormRoot}>
          {assignmentType === 'TRACTOR' && hasDispatchEditPermission && (
            <Box sx={styles.AssignmentFormItem}>
              <TractorAssignmentToDriver
                apiAssignHandler={settings.tractor.apiAssignHandler}
                onAssign={settings.tractor.onAssign}
                trip={trip}
                onClose={settings.tractor.onClose}
              />
            </Box>
          )}
          {assignmentType === 'TRAILER' && hasDispatchEditPermission && (
            <Box sx={styles.AssignmentFormItem}>
              <TrailerAssignmentToDriver
                apiAssignHandler={settings.trailer.apiAssignHandler}
                onAssign={settings.trailer.onAssign}
                trip={trip}
                onClose={settings.trailer.onClose}
              />
            </Box>
          )}
        </Box>

        {unassignType && (
          <DeletePopup
            open
            title={unAssignConfig[unassignType as keyof unAssignConfig]}
            body={`Are you sure you want to remove this ${capitalize(
              unassignType
            )} ${
              unassignType === entityTypes.TRACTOR
                ? tractor?.name
                : trailer?.name
            } ?`}
            onAction={onUnAssignHandler}
            onClose={() => setUnassignType(undefined)}
            buttonText={t('DriverGroupAssignmentbuttonUnassign')}
          />
        )}
      </Box>
    </RoutesLoadContextProvider>
  );
}
