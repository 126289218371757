import { PayStatementsTypeEnum } from '../../../../models';

export enum EFinanceAccountSummaryExpense {
  DRIVER_PAYMENT = 'driverPayment',
  TRACTOR_PAYMENT = 'tractorPayment',
  DISPATCHER_PAYMENT = 'dispatchPayment',
  EXPENSE_PAYMENT = 'expensePayment',
  CARRIER_PAYMENT = 'carrierPayment',
}

export const EFinanceAccountSummaryEntityType = {
  driverPayment: PayStatementsTypeEnum.driver,
  tractorPayment: PayStatementsTypeEnum.tractor,
  dispatchPayment: PayStatementsTypeEnum.dispatcher,
  expensePayment: undefined,
  carrierPayment: undefined,
} as any;

export enum EFinanceAccountSummaryCategory {
  INCOME = 'summaryIncome',
  EXPENSE = 'summaryExpense',
}

export const ACCOUNT_SUMMARY_FILTERS: {
  period: string;
  categories: string;
} = {
  period: 'periodFilter',
  categories: 'categoriesFilter',
};
