import { useFormContext } from 'react-hook-form';
import TextField from '../../../../common/Ui/TextField/TextField';
import { useManageTypes } from '../../../../contexts/ManageTypesContext';
import Box from '@mui/material/Box';
import IconPicker from '../../../../common/Ui/IconPicker';
import EditableManageTypeForm from './EditableManageTypeForm';

export default function BaseManageTypeForm() {
  const {
    entity: { name },
  } = useManageTypes();

  const { control, getValues } = useFormContext();
  const [id, custom] = getValues(['id', 'custom']);

  if (id && !custom) {
    return <EditableManageTypeForm />;
  }
  return (
    <>
      <TextField
        name="itemName"
        required
        control={control}
        label={`${name} Type Name`}
      />
      <TextField
        name="description"
        control={control}
        label="Description"
        multiline
      />
      <Box sx={{ mb: 3 }}>
        <IconPicker name="iconName" control={control} width={'500'} />
      </Box>
    </>
  );
}
