import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import {
  StyledDetailedDataSectionContentDescription,
  StyledDetailedDataSectionContentRow,
  StyledDetailedDataSectionContentTitle,
  StyledHOSEventViewSectionContent,
} from '../../TripPlanV3/styles';
import {
  DriverActivityType,
  DriverActivityTypeDisplay,
  DriverHOSStatusType,
  DriverHOSStatusTypeDisplay,
  StopSolutionV3HOSStop,
  StopSolutionV3Prop,
} from '../../TripPlanV3/types';
import { TimeLineIconMapping } from '../timelineIconsMapping';
import { classNamesTimeline } from './constants';
import { TimelineFormatter } from './DetailedDataSection';

export interface HOSEventViewSectionProps {
  stopSolution: StopSolutionV3Prop;
}
export const HOSEventViewSection = ({
  stopSolution,
}: HOSEventViewSectionProps): JSX.Element => {
  if (stopSolution?.HOSList) {
    const theme: any = useTheme();
    const getDriverActivityIcon = (hosStop: StopSolutionV3HOSStop) => {
      switch (hosStop.driverActivity) {
        case DriverActivityType.Driving:
          return TimeLineIconMapping.DriverHOSEventView({
            color: theme.palette.text.primary,
          });
        case DriverActivityType.Breakstop:
          return TimeLineIconMapping.Break({});
        case DriverActivityType.PreTripInspection:
          return TimeLineIconMapping.PreTripInspection({});
        case DriverActivityType.AppointmentWork:
          return TimeLineIconMapping.AppointmentWork({});
        case DriverActivityType.Offduty:
          return TimeLineIconMapping.WorkOffOutlinedIcon({});
      }
    };
    const getDriverActivityTitleDisplay = (hosStop: StopSolutionV3HOSStop) => {
      switch (hosStop.driverActivity) {
        case DriverActivityType.Driving:
          return DriverActivityTypeDisplay.Driving;
        case DriverActivityType.Breakstop:
          return DriverActivityTypeDisplay.Breakstop;
        case DriverActivityType.PreTripInspection:
          return DriverActivityTypeDisplay.PreTripInspection;
        case DriverActivityType.AppointmentWork:
          return DriverActivityTypeDisplay.AppointmentWork;
        case DriverActivityType.Offduty:
          return DriverActivityTypeDisplay.Offduty;
      }
    };
    const getDriverHOSStatusTitleDisplay = (hosStop: StopSolutionV3HOSStop) => {
      switch (hosStop.driverHOSStatus) {
        case DriverHOSStatusType.OnDuty:
          return DriverHOSStatusTypeDisplay.OnDuty;
        case DriverHOSStatusType.OffDuty:
          return DriverHOSStatusTypeDisplay.OffDuty;
        case DriverHOSStatusType.Driving:
          return DriverHOSStatusTypeDisplay.Driving;
        case DriverHOSStatusType.SleeperBed:
          return DriverHOSStatusTypeDisplay.SleeperBed;
      }
    };
    return (
      <Box
        sx={{
          marginBottom: '-20px',
          marginTop: '30px',
          paddingLeft: '30px',
        }}
        className={classNamesTimeline.StyledHOSEventViewSection}
      >
        {stopSolution?.HOSList?.map(
          (hosStop: StopSolutionV3HOSStop, idx: number) => (
            <StyledHOSEventViewSectionContent key={`${idx}-${hosStop.type}`}>
              <Box
                component="span"
                sx={{
                  width: '25px',
                }}
              >
                {getDriverActivityIcon(hosStop)}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <StyledDetailedDataSectionContentRow>
                  <StyledDetailedDataSectionContentTitle component="span">
                    {getDriverActivityTitleDisplay(hosStop)}

                    <NoiseControlOffIcon
                      fontSize="small"
                      sx={{
                        width: '10px',
                        height: '10px',
                        verticalAlign: 'middle',
                        color: 'text.primary',
                        marginLeft: '5px',
                      }}
                    />

                    <StyledDetailedDataSectionContentDescription component="span">
                      {TimelineFormatter.getDisplayHOSEventViewDateTime(
                        hosStop.estimatedTime
                      )}
                    </StyledDetailedDataSectionContentDescription>
                  </StyledDetailedDataSectionContentTitle>

                  <StyledDetailedDataSectionContentTitle
                    component="span"
                    sx={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    [{getDriverHOSStatusTitleDisplay(hosStop)}]
                  </StyledDetailedDataSectionContentTitle>
                </StyledDetailedDataSectionContentRow>

                <StyledDetailedDataSectionContentRow>
                  <StyledDetailedDataSectionContentDescription component="div">
                    {hosStop.locationAddress}
                  </StyledDetailedDataSectionContentDescription>
                  <StyledDetailedDataSectionContentDescription
                    component="div"
                    sx={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {TimelineFormatter.minutesToHours(hosStop.duration)}
                  </StyledDetailedDataSectionContentDescription>
                </StyledDetailedDataSectionContentRow>
              </Box>
            </StyledHOSEventViewSectionContent>
          )
        )}
      </Box>
    );
  }
  return <></>;
};
