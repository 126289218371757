import { AccountingIntegrationConstants } from './accounting';
import { EldIntegrationConstants } from './eld';
import { FactoringIntegrationConstants } from './factoring';
import { LoadboardsIntegrationConstants } from './loadboards';

export const IntegrationConstants = {
  ...EldIntegrationConstants,
  ...LoadboardsIntegrationConstants,
  ...FactoringIntegrationConstants,
  ...AccountingIntegrationConstants,
};
