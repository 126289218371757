import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ITEM_HEIGHT = 48;

export function LongMenu({
  options,
  color,
  customStyles,
  onCloseCb,
}: {
  options: {
    name: string;
    action: (
      actionName: string,
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => void;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
    keepOptionsOpenAfterAction?: boolean;
    onCloseCb?: () => void;
  }[];
  color?: string;
  customStyles?: { [key: string]: string | number };
  onCloseCb?: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    onCloseCb && onCloseCb();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        sx={{
          color: color,
        }}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: customStyles ? `${customStyles.width}ch` : '10ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
            onClick={(e) => {
              !option.keepOptionsOpenAfterAction && handleClose();
              option.action(option.name, e);
            }}
          >
            {option.startIcon}
            {option.name}
            {option.endIcon}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
