import { ArrowForwardIosOutlined } from '@mui/icons-material';
import {
  Box,
  Collapse,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { styled } from '@mui/styles';
import { FC, useState } from 'react';
import { ContactSummary } from '../../../models';
import { StyledTypography } from '../Popups/styles';
import { DeleteButtonIcon } from '../../../ui-kit/components/Assets';
import ThreeDotMenuIcon from '../../../ui-kit/components/ThreeDotMenuIcon';

interface ContactProps {
  contact: ContactSummary;
  onPrimaryChange?: (value: boolean) => void;
  onDeleteHandler: () => void;
}

export const MenuOptions = [
  {
    label: 'Delete',
    icon: DeleteButtonIcon,
  },
];

const StyledContainer = styled('div')(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: 8,
  padding: 16,
  position: 'relative',
}));

const Contact: FC<ContactProps> = ({
  contact,
  onPrimaryChange,
  onDeleteHandler,
}) => {
  const [checked, setChecked] = useState(true);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action: { icon: string; label: string }) => {
    if (action?.label === 'Delete') {
      onDeleteHandler();
    }
  };

  return (
    <StyledContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          onClick={handleChange}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <ArrowForwardIosOutlined
            sx={{
              transform: `rotate(${checked ? '90deg' : '0'})`,
              width: 16,
              height: 16,
              marginRight: 1,
            }}
            fontSize="small"
            color="primary"
          />
          <StyledTypography color="primary" fontWeight={600}>
            {contact.name}
          </StyledTypography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            control={<Switch checked={contact.isPrimary} />}
            onChange={() => onPrimaryChange?.(!contact.isPrimary)}
            label="Primary"
            color="#2B64CB"
            sx={{
              fontWeight: 600,
            }}
          />
          <ThreeDotMenuIcon
            menuOptions={MenuOptions}
            open={Boolean(anchorEl)}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
            anchorEl={anchorEl}
            handleMenuItemClick={handleMenuItemClick}
          />
        </Box>
      </Box>
      <Collapse in={checked}>
        <TextField
          id="name"
          label="Name"
          variant="standard"
          value={contact.name}
          sx={{ mr: 2, width: '21ch' }}
        />
        <TextField
          id="email"
          label="Email"
          variant="standard"
          value={contact.email}
          sx={{ width: '21ch' }}
        />
        <Box>
          <TextField
            id="phone"
            label="Phone #"
            variant="standard"
            value={contact.phoneData.phone}
            sx={{ mr: 2, width: '21ch' }}
          />
          <TextField
            id="description"
            label="Description"
            variant="standard"
            value={contact.description}
            sx={{ width: '21ch' }}
          />
        </Box>
      </Collapse>
    </StyledContainer>
  );
};

export default Contact;
