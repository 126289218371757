import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemIcon,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { provideNameLogo } from '../../../views/loadboards/LoadboardDataGrid/Grid/logoMap';
import { LoadBoardAccordionProps, LoadBoardAccount } from './types';

export const LoadBoardAccordion = ({
  accounts,
  onSelectAccountHandler,
  isSelectedAccount,
}: LoadBoardAccordionProps) => {
  const groupName = accounts[0].groupName;
  const onToggleCheck = () => {
    onSelectAccountHandler(accounts[0]);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Checkbox
            id={groupName}
            checked={accounts.some((account: LoadBoardAccount) =>
              isSelectedAccount(account)
            )}
            onClick={onToggleCheck}
          />
          <ListItemIcon className="provider-logo">
            {provideNameLogo[groupName]}
          </ListItemIcon>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl>
          <Typography>Accounts</Typography>
          <RadioGroup>
            {accounts.map((account: LoadBoardAccount) => (
              <FormControlLabel
                key={account.id}
                value={account.id}
                control={
                  <Radio
                    id={account.id}
                    onChange={() => onSelectAccountHandler(account)}
                    checked={isSelectedAccount(account)}
                  />
                }
                label={account.accountName}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};
