import React from 'react';
import {
  ColumnMappingIcon,
  DurationIcon,
  FileIcon,
  FileTypeIcon,
  PointerIcon,
  ReadIcon,
  RoadIcon,
  RowsIcon,
  ShowIcon,
  TimerIcon,
  WatchTutorialIcon,
} from '../../../../../ui-kit/components/Assets';
import {
  IMPORT_CUSTOMER_NAME,
  IMPORT_DRIVER_NAME,
  IMPORT_EXPENSE_NAME,
  IMPORT_LOAD_NAME,
  IMPORT_LOCATION_NAME,
  IMPORT_TRACTOR_NAME,
  IMPORT_TRAILER_NAME,
  IMPORT_VENDOR_NAME,
} from '../../../../../contexts/ImportContext';
import { AXELE_PERMISSION_TYPE } from '../../../../../common/Permission';

export type FileInfoType = {
  key: number;
  text: string;
  subText: string;
  icon: JSX.Element;
};

export const featureInfos = [
  {
    key: 1,
    text: 'Column Mapping',
    subText: 'Map the file fields with LoadOps fields',
    icon: <ColumnMappingIcon />,
  },
  {
    key: 2,
    text: 'Bulk Editing',
    subText: 'Do bulk actions and make the changes as required',
    icon: <PointerIcon />,
  },
  {
    key: 3,
    text: 'Smart Error Detection',
    subText:
      'Resolve all import errors and warnings right during the import process',
    icon: <RoadIcon />,
  },
  {
    key: 4,
    text: 'Mapping Persistency',
    subText: 'Map the field once and do future imports easily',
    icon: <TimerIcon />,
  },
];

export const basicFileInfo: FileInfoType[] = [
  {
    key: 1,
    text: '.xls, .xlsx',
    subText: 'Supported Files',
    icon: <FileTypeIcon />,
  },
  { key: 2, text: '2-5 Mins', subText: 'Duration', icon: <DurationIcon /> },
  { key: 3, text: '2000 Rows', subText: 'Limit', icon: <RowsIcon /> },
  { key: 4, text: '5MB', subText: 'File Size', icon: <FileIcon /> },
];

export const equipmentDriverFileInfo: FileInfoType[] = [
  {
    key: 1,
    text: '.xls, .xlsx',
    subText: 'Supported Files',
    icon: <FileTypeIcon />,
  },
  { key: 2, text: '2-5 Mins', subText: 'Duration', icon: <DurationIcon /> },
  { key: 3, text: '3000 Rows', subText: 'Limit', icon: <RowsIcon /> },
  { key: 4, text: '5MB', subText: 'File Size', icon: <FileIcon /> },
];

export const loadExpenseFileInfo: FileInfoType[] = [
  {
    key: 1,
    text: '.xls, .xlsx',
    subText: 'Supported Files',
    icon: <FileTypeIcon />,
  },
  { key: 2, text: '2-5 Mins', subText: 'Duration', icon: <DurationIcon /> },
  { key: 3, text: '500 Rows', subText: 'Limit', icon: <RowsIcon /> },
  { key: 4, text: '5MB', subText: 'File Size', icon: <FileIcon /> },
];

export const fileInfos: { [key: string]: FileInfoType[] } = {
  customer: basicFileInfo,
  vendor: basicFileInfo,
  location: basicFileInfo,
  tractor: equipmentDriverFileInfo,
  trailer: equipmentDriverFileInfo,
  driver: equipmentDriverFileInfo,
  load: loadExpenseFileInfo,
  expense: loadExpenseFileInfo,
};

export const guides = [
  {
    key: 1,
    text: 'Read User Guide',
    icon: <ReadIcon />,
    hasDownloadOption: false,
    linkURL: 'https://help.loadops.com/loadops-account#import-data',
  },
  {
    key: 2,
    text: 'Watch Video Tutorial',
    icon: <WatchTutorialIcon />,
    hasDownloadOption: false,
  },
  {
    key: 3,
    text: 'Download  Sample File',
    icon: <ShowIcon />,
    hasDownloadOption: true,
  },
];

export const AxeleExpenseTempalteNames = ['Fuel Expense', 'Other Expense'];
export const AxeleExpenseTempalteCodes = ['AXELE_FUEL', 'AXELE_OTHER'];

export const fileTypes: { [key: string]: { [key: string]: string[] } } = {
  load: { future: ['future', 'past'] },
  expense: {
    'Axele Expense Import': AxeleExpenseTempalteNames,
    'Toll Cards': [
      'Bestpass Toll Card',
      'E-ZPass Toll Card',
      'PrePass Toll Card',
      'I-Pass Toll Card',
    ],
    'Fuel Cards': [
      'Apex TCS Fuel Card',
      'Comdata Fuel Card',
      'EFS Fuel Card',
      'Pilot Flying J Fuel Card',
      'MSFC Fuel Card',
    ],
  },
};

export const defaultFileTypes: { [key: string]: string } = {
  load: 'future',
  expense: 'Fuel Expense',
  fuelExpense: 'Fuel Expense',
  otherExpense: 'Other Expense',
  allExpenses: 'Fuel Expense',
};

export const fileTypeCodesMap: { [key: string]: string } = {
  'Apex TCS Fuel Card': 'APEX',
  'EFS Fuel Card': 'EFS',
  'Pilot Flying J Fuel Card': 'PILOT_FLYING_J',
  'MSFC Fuel Card': 'MSFC',
  'Bestpass Toll Card': 'BESTPASS',
  'E-ZPass Toll Card': 'EZPASS',
  'PrePass Toll Card': 'PREPASS',
  'I-Pass Toll Card': 'IPASS',
  'Fuel Expense': 'AXELE_FUEL',
  'Other Expense': 'AXELE_OTHER',
  'Comdata Fuel Card': 'COMDATA',
  future: 'FUTURE',
  past: 'PAST',
  'City Based': 'CITY_BASED',
  'Zip Based': 'ZIP_BASED',
  'Mileage Based': 'MILEAGE_BASED',
};

export const importPermissionsMap: { [key: string]: AXELE_PERMISSION_TYPE[] } =
  {
    [IMPORT_CUSTOMER_NAME]: [AXELE_PERMISSION_TYPE.CUSTOMER_ADD],
    [IMPORT_VENDOR_NAME]: [AXELE_PERMISSION_TYPE.VENDOR_ADD],
    [IMPORT_LOCATION_NAME]: [AXELE_PERMISSION_TYPE.LOCATION_ADD],
    [IMPORT_DRIVER_NAME]: [AXELE_PERMISSION_TYPE.USER_PROFILE_ADD],
    [IMPORT_TRACTOR_NAME]: [AXELE_PERMISSION_TYPE.EQUIPMENT_ADD],
    [IMPORT_TRAILER_NAME]: [AXELE_PERMISSION_TYPE.EQUIPMENT_ADD],
    [IMPORT_LOAD_NAME]: [AXELE_PERMISSION_TYPE.LOAD_ADD],
    [IMPORT_EXPENSE_NAME]: [AXELE_PERMISSION_TYPE.EXPENSE_ADD],
  };
