import {
  Box,
  Button,
  Grid,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Accordion from '../../../../../../common/Accordion';
import FormDialog from '../../../../../../common/Ui/FormDialog';
import { FormDialogMode } from '../../../../../../common/Ui/FormDialog/constants';
import { useStaticData } from '../../../../../../contexts/StaticDataContext';
import { CarrierDTO } from '../../../../../../models/DTOs/Carrier/Requests';
import { useDetailsPanelStore } from '../../../../../../store/DetailsPanel';
import { AddIcon } from '../../../../../../ui-kit/components/Assets';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';
import { ESecondaryDetailsPanelType } from '../../../../../../views/dispatch2/constants/types';
import { contactDeleteAction } from '../../../../../shared/constants';
import { ICarrierInsuranceFormData } from '../../../../constants/types';
import { CarrierInsuranceValidationSchema } from '../../../../constants/validationSchema';
import {
  createCarrierInsuranceHandler,
  deleteCarrierInsuranceHandler,
  getDefaultCarrierDocument,
} from '../../../../utils/api.utils';
import AddInsuranceFormForm from '../Insurance/AddInsuranceForm';
import InsuranceListForm from '../Insurance/InsuranceListForm';
import { ICreateCarrierFormData } from './AddNewCarrierForm';
import { t } from 'i18next';

interface AddNewInsuranceFormProps {
  isCreatePanel: boolean;
  carrierDTO?: CarrierDTO;
  canEdit: boolean;
}
export const AddNewInsuranceForm = observer(
  ({
    isCreatePanel = true,
    carrierDTO = undefined,
    canEdit,
  }: AddNewInsuranceFormProps) => {
    const { trackPromise } = useDetailsPanelStore;
    const theme = useTheme();
    const { staticData } = useStaticData();
    const [addInsuranceDialogOpen, setAddInsuranceDialog] =
      useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [nextRender, setNextRender] = useState<number>(0);

    const { getValues, setValue, handleSubmit } = useFormContext();
    const [insurances] = getValues(['insurances']);
    const numberOfInsurances = insurances?.length;

    const handleOpenAddContactModal = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation();
      setAddInsuranceDialog(true);
    };

    const insurancesSummaryRenderer = () => {
      return (
        <ThemeProvider theme={theme}>
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={0}
          >
            <Grid item>
              <Typography variant="h7" sx={{ color: 'primary.main' }}>
                Insurances
              </Typography>
              {canEdit && (
                <Button
                  onClick={handleOpenAddContactModal}
                  variant="outlined"
                  sx={{ minWidth: '35px', height: '30px', marginLeft: '16px' }}
                >
                  <AddIcon />
                </Button>
              )}
            </Grid>
            {numberOfInsurances > 0 && (
              <Grid item>
                <Grid container direction={'column'} alignItems={'flex-end'}>
                  <Grid item>
                    <Typography variant="h6" sx={{ color: 'primary.main' }}>
                      {numberOfInsurances}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="tooltip"
                      sx={{ color: 'text.secondary' }}
                    >
                      {t('loadsTrackingTotalNum')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </ThemeProvider>
      );
    };

    const onCloseAddInsurance = () => {
      setAddInsuranceDialog(false);
    };

    const onAddNewInsuranceHandler = async (
      carrierInsuranceFormData: ICarrierInsuranceFormData
    ) => {
      setAddInsuranceDialog(false);
      if (!isCreatePanel && carrierDTO) {
        const createdInsurances = await trackPromise(
          createCarrierInsuranceHandler({
            carrierDTO,
            carrierInsuranceFormDataList: [carrierInsuranceFormData],
            staticData,
          }),
          ESecondaryDetailsPanelType.CARRIER
        );
        if (createdInsurances?.length) {
          insurances.push({
            ...carrierInsuranceFormData,
            id: createdInsurances[0].id,
            seqNumber: createdInsurances[0].seqNumber,
            carrierId: createdInsurances[0].carrierId,
            assignedDocuments: createdInsurances[0]?.assignedDocuments,
          });
        }
      } else {
        const defaultDocument = await getDefaultCarrierDocument(
          (carrierInsuranceFormData?.assignedDocuments as File[]) || null
        );
        insurances.push({
          ...carrierInsuranceFormData,
          defaultDocument: defaultDocument,
        });
      }

      setValue('insurances', insurances, {
        shouldDirty: isCreatePanel ? true : false,
      });
      setNextRender((prev) => prev + 1);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (
      action: { index: number; icon: string; label: string },
      accountData: any,
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setSelectedIndex(action.index);
      event.stopPropagation();
      if (action?.label !== 'Delete') return;
      if (isCreatePanel) {
        deleteCarrierInsuranceLocal(action.index);
      } else {
        setIsDeletePopupOpen(true);
      }
      setAnchorEl(null);
    };

    const deleteCarrierInsuranceLocal = (deletedIndex: number) => {
      const newInsurances: ICarrierInsuranceFormData[] = [];
      insurances.forEach(
        (insuranceFormData: ICarrierInsuranceFormData, index: number) => {
          if (index !== deletedIndex) {
            newInsurances.push(insuranceFormData);
          }
        }
      );
      setValue('insurances', newInsurances, {
        shouldDirty: isCreatePanel ? true : false,
      });
      setNextRender((prev) => prev + 1);
    };

    const onDeleteCarrierInsuranceHandler = async (
      formData: ICreateCarrierFormData
    ) => {
      if (selectedIndex === null) return;

      const carrierInsuranceFormData = insurances[selectedIndex];
      deleteCarrierInsuranceLocal(selectedIndex);
      setIsDeletePopupOpen(false);

      if (!isCreatePanel && carrierDTO) {
        await trackPromise(
          deleteCarrierInsuranceHandler({
            carrierInsuranceFormData: carrierInsuranceFormData,
          }),
          ESecondaryDetailsPanelType.CARRIER
        );
      }
    };

    const onChangeCarrierInsuranceDocument = (
      currentIndex: number,
      files: File[] | null
    ): void => {
      const newInsurances: ICarrierInsuranceFormData[] = [...insurances];
      if (newInsurances[currentIndex]) {
        newInsurances[currentIndex].assignedDocuments = files;
        setValue('insurances', newInsurances, { shouldDirty: true });
      }
    };

    const insuranceDefaultData = {
      fridgeBreakCoverage: false,
      notes: '',
    };

    return (
      <Box sx={{ width: '100%', mt: 2 }}>
        <Accordion
          summaryRenderer={insurancesSummaryRenderer}
          expanded={!isCreatePanel}
        >
          {numberOfInsurances
            ? insurances.map(
                (insurance: ICarrierInsuranceFormData, index: number) => (
                  <InsuranceListForm
                    key={`${insurance?.id || index}`}
                    index={index}
                    isCreatePanel={isCreatePanel}
                    onChangeCarrierInsuranceDocument={(files) => {
                      onChangeCarrierInsuranceDocument(index, files);
                    }}
                    ThreeDotMenuSettings={{
                      menuOptions: [{ index: index, ...contactDeleteAction }],
                      open: Boolean(anchorEl),
                      handleMenuClick: handleMenuClick,
                      handleMenuClose: handleMenuClose,
                      anchorEl: anchorEl,
                      handleMenuItemClick: handleMenuItemClick,
                    }}
                    canEdit={canEdit}
                  />
                )
              )
            : null}
        </Accordion>
        {addInsuranceDialogOpen && (
          <FormDialog
            mode={FormDialogMode.LIGHT}
            data={{ ...insuranceDefaultData }}
            titleText={
              <Typography variant="h6" color={'text.primary'}>
                Add New Insurance
              </Typography>
            }
            actionLabel="Add New Insurance"
            actionLabelId="addInsuranceButton"
            open={addInsuranceDialogOpen}
            onAction={onAddNewInsuranceHandler}
            disableActionBtnByDefault
            handleClose={onCloseAddInsurance}
            validationSchema={CarrierInsuranceValidationSchema}
            contentRenderer={() => (
              <ThemeProvider theme={theme}>
                <AddInsuranceFormForm />
              </ThemeProvider>
            )}
          ></FormDialog>
        )}
        {isDeletePopupOpen && selectedIndex !== null && (
          <DeletePopup
            open={true}
            title={`Delete Carrier Insurance ${
              insurances[selectedIndex]?.type?.value ?? ''
            }`}
            body={`Are you sure you want to delete this Carrier Insurance ${
              insurances[selectedIndex]?.type?.value ?? ''
            }?`}
            onAction={handleSubmit(onDeleteCarrierInsuranceHandler)}
            onClose={() => setIsDeletePopupOpen(false)}
            buttonText={'Delete'}
          />
        )}
      </Box>
    );
  }
);
