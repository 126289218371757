import React from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from '../../../common/Ui/TextField/TextField';
import FormPhoneField from '../../../common/Ui/FormPhoneField';
import { getAccountTypeWithRoleCode, isHasPermission } from '../../../utils';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';

export default function ContactForm({ id }: { id: number }) {
  const { control } = useFormContext();
  const noPermissionForEditVendor = !isHasPermission([
    AXELE_PERMISSION_TYPE.VENDOR_EDIT,
  ]);

  return (
    <>
      <TextField
        disabled={noPermissionForEditVendor}
        control={control}
        name={`contacts.${id}.name`}
        label="Name"
        sizes={{ xs: 6 }}
        required
      />
      <TextField
        disabled={noPermissionForEditVendor}
        control={control}
        name={`contacts.${id}.email`}
        label="Email"
        sizes={{ xs: 6 }}
      />
      <FormPhoneField
        disabled={noPermissionForEditVendor}
        control={control}
        name={`contacts.${id}.phoneData`}
        label="Phone #"
        sizes={{ xs: 12 }}
      />
      <TextField
        disabled={noPermissionForEditVendor}
        control={control}
        name={`contacts.${id}.description`}
        label="Description"
        sizes={{ xs: 12 }}
      />
    </>
  );
}
