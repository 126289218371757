import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { unstable_useId as useId } from '@mui/utils';
import { useUtils } from '@mui/x-date-pickers/internals/hooks/useUtils';
import { Moment } from 'moment';
import { useState } from 'react';
import { HourNumbers, MinutesNumbers } from './DateTimeTimePicker/ClockNumbers';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogHeader,
} from './DateTimeTimePicker/styles';
import {
  fromTextMaskChangeHours,
  fromTextMaskChangeMinutes,
  fromTextMaskGetHours,
} from './utils';
import { useTranslation } from 'react-i18next';

export interface TimeDurationDialogs {
  maxValue: number; //in minutes value
  textmaskValue: string; //Textmask Value
  open: boolean;
  onClose?: (textmaskValue?: string) => void;
  minuteStep?: number;
  is24HFormat?: boolean;
}
export const TimeDurationDialog = (props: TimeDurationDialogs): JSX.Element => {
  const utils = useUtils<Moment>();
  const {
    minuteStep,
    maxValue,
    onClose,
    textmaskValue: valueProp,
    open,
    is24HFormat,
    ...other
  } = props;
  const maxHours: number = parseInt((maxValue / 60).toString());
  const [textmaskValue, setTextmaskValue] = useState<string>(valueProp);

  const handleHourChange = (newValue: number) => {
    setTextmaskValue(
      fromTextMaskChangeHours(textmaskValue, newValue, { is24HFormat })
    );
  };
  const handleMinuteChange = (newValue: number) => {
    setTextmaskValue(
      fromTextMaskChangeMinutes(textmaskValue, newValue, { is24HFormat })
    );
  };
  const handleCancel = () => {
    onClose?.(undefined);
  };

  const handleOk = () => {
    onClose?.(textmaskValue);
  };

  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <StyledDialog maxWidth="xs" open={open} {...other}>
        <DialogContent>
          <StyledDialogHeader>
            {textmaskValue ? textmaskValue : '--h --m'}
          </StyledDialogHeader>
          <StyledDialogContent>
            <HourNumbers
              maxHours={maxHours}
              title={'Hour'}
              ampm={false}
              textmaskValue={textmaskValue}
              onChange={handleHourChange}
              isDisabled={(value: number) => {
                return false;
              }}
              selectedId={useId()}
              utils={utils}
              is24HFormat={is24HFormat}
            />

            <MinutesNumbers
              minuteStep={minuteStep}
              title={'Minutes'}
              ampm={false}
              textmaskValue={textmaskValue}
              onChange={handleMinuteChange}
              isDisabled={(value: number) => {
                const currentHours = fromTextMaskGetHours(textmaskValue, {
                  is24HFormat,
                });

                if (currentHours === maxHours) {
                  const maxMinutes = maxValue % 60;
                  return value >= maxMinutes;
                }
                return false;
              }}
              selectedId={useId()}
              utils={utils}
              is24HFormat={is24HFormat}
            />
          </StyledDialogContent>
        </DialogContent>
        <StyledDialogActions>
          <Button onClick={handleCancel}>{t('cancel')}</Button>
          <Button onClick={handleOk}>{t('ok')}</Button>
        </StyledDialogActions>
      </StyledDialog>
    );
  }
};
