import { Box, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledOption = styled(
  (props: TypographyProps<'li'> & { enableOptionSubtitle: boolean }) => (
    <Typography component={'li'} {...props} />
  )
)(({ enableOptionSubtitle }: any) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  height: enableOptionSubtitle ? '51px' : 'auto',
}));

export const StyledOptionContent = styled(Box)(() => ({
  flex: 'auto',
}));

export const StyledOptionTitle = styled(Typography)(({ theme }: any) => ({
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  fontSize: '14px',
}));

export const StyledOptionSubTitle = styled(Typography)(({ theme }: any) => ({
  ...theme.typography.body4,
  color: theme.palette.text.primary,
  fontSize: '14px',
}));

export const hideClearStyles = {
  '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
    visibility: 'hidden',
  },
};
