import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Theme } from '@mui/material';
import DeletePopup from '../../../../common/DeletePopup';

interface ICloseButton {
  onClick?: () => void;
  withConfirmation?: boolean;
  confirmPopupConfig?: ConfirmationPopupConfigType;
}

type ConfirmationPopupConfigType = {
  onAction: () => void;
  title?: string;
  body?: string;
  defaultText?: boolean;
  isMultiple?: boolean;
  entity?: string;
  okText?: string;
  cancelText?: string;
};

const IconButtonStyles = (theme: Theme) => ({
  boxShadow: theme?.shadows?.[2],
  background: theme.palette.primary.main,
  borderRadius: '6px',
  width: '38px',
  height: '28px',
  ':hover': {
    background: theme.palette.primary.main,
  },
  '*': {
    color: '#FFF',
  },
});

const CloseButton: React.FC<ICloseButton> = (props) => {
  const { onClick, withConfirmation = true, confirmPopupConfig } = props;
  const [showConfirmPoup, setShowConfirmPopup] = useState<boolean>(false);

  const handleOnButtonClick = () => {
    if (!withConfirmation) return onClick?.();
    setShowConfirmPopup(true);
  };
  return (
    <>
      <IconButton onClick={handleOnButtonClick} sx={IconButtonStyles}>
        <CloseIcon sx={{ width: '18px', height: '18px' }} />
      </IconButton>

      {showConfirmPoup && (
        <DeletePopup
          open={true}
          onClose={() => setShowConfirmPopup(false)}
          {...(confirmPopupConfig as ConfirmationPopupConfigType)}
        />
      )}
    </>
  );
};

export default CloseButton;
