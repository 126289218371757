/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  RegisterReCreateModel,
  RegistrationDotValidationModel,
  RegistrationVerificationModel,
} from '../../views/register/RegistrationPageUtils';
import {
  ForgotPasswordRequest,
  GetInvoiceListSubscriptionRequest,
  QueryParams,
  Register,
  RegisterResponse,
  ResetPasswordRequest,
} from '../../models';
import {
  ActivateAccount,
  CancelSubscriptionRequest,
  GetInvoiceListResponse,
  GetPaymentProfileRequest,
  GetSubscriptionRequest,
  GetSubscriptionResponse,
  SubscriptionPlanRequest,
  RegisterPage,
  UpsertPaymentRejoinRequest,
  UpsertPaymentRequest,
} from '../../models/DTOs/Subscription/Subscription';
import { Service } from './Service';

export abstract class ISubscriptionService extends Service {
  abstract validateRegisterData(requestData: Register): Promise<any>;

  abstract userRegistration(requestData: RegisterPage): Promise<boolean>;

  abstract registerData(
    requestData: Register
  ): Promise<RegisterResponse | null>;

  abstract forgotPassword(requestData: ForgotPasswordRequest): Promise<boolean>;

  abstract resetPassword(requestData: ResetPasswordRequest): Promise<boolean>;

  abstract getSubscription(
    requestData: GetSubscriptionRequest
  ): Promise<GetSubscriptionResponse>;

  abstract activateAccount(requestData: ActivateAccount): Promise<boolean>;
  abstract subscriptionPlan(
    requestData: SubscriptionPlanRequest
  ): Promise<boolean>;

  abstract getInvoiceList(
    requestData: GetInvoiceListSubscriptionRequest
  ): Promise<GetInvoiceListResponse>;

  abstract getReasons(): Promise<GetInvoiceListResponse>;

  abstract cancelSubscription(
    requestData: CancelSubscriptionRequest
  ): Promise<boolean>;

  abstract payAndCancel(
    requestData: CancelSubscriptionRequest
  ): Promise<boolean>;

  abstract upsertPayment(requestData: UpsertPaymentRequest): Promise<boolean>;
  abstract upsertPaymentRejoin(
    requestData: UpsertPaymentRejoinRequest
  ): Promise<boolean>;

  abstract deletePayment(requestData: UpsertPaymentRequest): Promise<boolean>;

  abstract setDefaultPayment(
    requestData: UpsertPaymentRequest
  ): Promise<boolean>;

  abstract getPaymentProfiles(
    requestData: GetPaymentProfileRequest
  ): Promise<Record<string, string>>;

  abstract getProforma(
    requestData: QueryParams
  ): Promise<Record<string, string>>;
  abstract getDotValidation(
    requestData: RegistrationDotValidationModel
  ): Promise<string>;
  abstract getVerificationEmail(
    requestData: RegistrationVerificationModel
  ): Promise<string>;

  abstract ReactivationFlow(
    requestData: RegisterReCreateModel
  ): Promise<boolean>;
}
