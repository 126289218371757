import Box from '@mui/material/Box';
import StorageManager from '../../../StorageManager/StorageManager';

import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Accordion from '../../../common/Accordion';
import DeletePopup from '../../../common/DeletePopup';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import LongMenu from '../../../common/LongMenu';
import { useLoadPermission } from '../../../common/hooks/useLoadPermission';
import { useStores } from '../../../store/root.context';
import { amountFormat } from '../../../utils';
import {
  FLAT_RATE_KEY,
  PER_LOADED_MILE_KEY,
  PER_LOADED_WEIGHT_KEY,
} from '../../loadsList/CreateLoad/constants/fieldOptions';
import { formatTextToUpperCase } from '../../loadsList/CreateLoad/utils';
import { IEdiDetails } from '../../loadsList/models/LoadSummary';
import InvoiceActions from '../InvoiceActions';
import {
  FactoringActionReturnType,
  InvoiceModel,
} from '../models/InvoiceModel';
import { BasicActions } from './InvoiceDetailsSections/ActionsSection';
import { Factoring } from './InvoiceDetailsSections/FactoringSection';
import { InvoiceLineItemsSection } from './InvoiceDetailsSections/InvoiceLineItemsSection';
import { InvoiceReceivedPayment } from './InvoiceDetailsSections/InvoiceReceivedPaymentSection';
import { InvoiceSettings } from './InvoiceDetailsSections/InvoiceSettingsSection';
import LoadInvoiceRateLineItems from './InvoiceDetailsSections/LoadInvoiceRate/LoadInvoiceRateLineItems';
import { Warnings } from './InvoiceDetailsSections/WarningsSection';
import {
  InvoiceLoader,
  SingleAutocompleteGroupinvoice,
  loadGroupInvoiceTitleRender,
} from './loadGroupInvoiceItem';
const accordionStyle = { padding: '0px', marginBottom: '-15px' };
export default function InvoiceDetailsForm({
  invoice,
  triggerAlert,
  handleFactoringResponse,
  isStatusUpdated,
  ediDetails,
  isLoading = false,
  onHold = false,
  removeInvoice,
  onSave,
  isFactorValueUpdated,
}: {
  invoice: InvoiceModel;
  triggerAlert: (
    actionName: string,
    message: string,
    subMessage?: string
  ) => void;
  handleFactoringResponse: (
    response: FactoringActionReturnType,
    selectedItem: InvoiceModel | null
  ) => void;
  isStatusUpdated?: () => void;
  removeInvoice?: () => void;
  ediDetails?: IEdiDetails;
  isLoading?: boolean;
  onHold?: boolean;
  onSave?: () => void;
  isFactorValueUpdated?: () => void | undefined;
}) {
  const { control, setValue, watch, getValues } = useFormContext();
  const customerInfo = watch('customer');
  const childInvoices = watch('childInvoices');
  const invoiceType = getValues('invoiceType');

  // TODO watch loaded mile and empty
  const completeDate = getValues('completeDate');
  const loadedMiles = getValues('loadedMiles');
  const emptyMiles = getValues('emptyMiles');
  const watchLineItem = getValues('lineItems');

  const {
    invoiceStore: { isInvoiceCreate },
  } = useStores();
  const [selectedLoadId, setSelectedLoadId] = useState<number>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `childInvoices`,
  });
  // if (!invoice?.invoiceId && !isLoading) {
  //   return <NoData />;
  // }
  const [selAssociatedLoadItem, setSelAssociatedLoadItem] =
    useState<any>(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<{
    open: boolean;
    hideBtn: boolean;
    type: string;
    title: string;
    subMsg: string;
    okay?: () => void;
    cancel: string;
  }>({
    open: false,
    type: '',
    title: '',
    subMsg: '',
    cancel: '',
    hideBtn: false,
  });
  const [cardExpanded, setCardExpanded] = useState({
    invoiceSetting: true,
    associatedLoads: true,
    additionalInvoiceItems: true,
  });
  const { hasInvoiceEditPermission, hasFinancialInvoiceEdit } =
    useLoadPermission();
  const canEditInvoice =
    invoice?.status === ELoadStatus.PAID ||
    invoice?.status === ELoadStatus.INVOICED
      ? hasInvoiceEditPermission
      : true;

  let canEditPaidInvoice = hasInvoiceEditPermission
    ? invoice?.status === ELoadStatus.PAID
    : true;

  const userStorage = StorageManager.getUser();
  if (
    hasFinancialInvoiceEdit &&
    userStorage.roleCode === 'DI' &&
    canEditPaidInvoice
  ) {
    if (
      !(
        invoice?.status === ELoadStatus.PAID ||
        invoice?.status === ELoadStatus.INVOICED
      )
    ) {
      canEditPaidInvoice = false;
    }
  }
  const lineitemList = InvoiceActions.invoiceLineItemsList;
  const titleRenderer = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography
            variant="h7"
            sx={{
              color: 'primary.main',
            }}
          >
            Invoice Settings
          </Typography>
          {!cardExpanded.invoiceSetting && customerInfo?.name && (
            <div>
              <Typography
                variant="body3"
                sx={{ color: 'text.secondary', pr: 2 }}
              >
                <b> Customer: </b> {customerInfo?.name || ''}
              </Typography>
              {!isInvoiceCreate && (
                <Typography variant="body3" sx={{ color: 'text.secondary' }}>
                  <b> Invoice Date:</b> Jan 30, 2022
                </Typography>
              )}
            </div>
          )}
        </div>
      </Box>
    );
  };

  const associatedLoadsTitleRenderer = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box style={{ width: '60%' }}>
          <Typography
            variant="h7"
            sx={{
              color: cardExpanded ? 'primary.main' : 'text.primary',
            }}
          >
            Associated Loads
          </Typography>
        </Box>
        <Box
          onClick={(event: { stopPropagation: () => void }) => {
            event.stopPropagation();
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: 'primary.main',
              fontSize: '20px',
            }}
          >
            {amountFormat(
              childInvoices?.reduce(
                (total, currentObject) => total + currentObject.total,
                0
              ) || 0
            )}
          </Typography>
        </Box>
      </Box>
    );
  };
  const removingLoads = (invoiceId: number = selectedLoadId) => {
    remove(invoiceId);
    setDeleteDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const getTotalSumAmount = (foundIndex: number) => {
    if (!(childInvoices[foundIndex]?.total !== undefined)) return;
    childInvoices[foundIndex].total =
      childInvoices[foundIndex]?.lineItems?.reduce(
        (amount: number, currentObject: { amount: number }) =>
          amount + currentObject.amount,
        0
      ) || 0;
    return amountFormat(childInvoices[foundIndex].total || 0);
  };
  const onEditInvoiceLineItem = (item: any, index: number, type: string) => {
    item?.lineItems?.forEach((sItem: any) => {
      if (
        item?.type !== FLAT_RATE_KEY &&
        item?.type !== PER_LOADED_MILE_KEY &&
        item?.type !== PER_LOADED_WEIGHT_KEY
      ) {
        const lineItem = lineitemList?.find((e) => e?.id === sItem?.itemId);
        sItem.deduction = lineItem?.deduction;
      }
    });
    const updatedVals = getValues();
    setSelAssociatedLoadItem({
      item: {
        ...item,
        messageOnInvoice: updatedVals?.messageOnInvoice,
        internalNotes: updatedVals?.notes,
        milesManuallyUpdated: updatedVals?.milesManuallyUpdated,
        mileageUpdatedBy: updatedVals?.mileageUpdatedBy,
        mileageUpdatedDate: updatedVals?.mileageUpdatedDate,
      },
      index: index,
      type: type,
    });
  };
  const filterItemByItemCode = (list: any[], itemId: string) => {
    const itemObject = list.find((item) => item.id === itemId);
    if (itemObject) {
      return itemObject;
    }
    return false;
  };

  const displayLineItem = (items: any) => {
    return (
      <>
        {items.map((itemData: any) => {
          const itemList = filterItemByItemCode(lineitemList, itemData.itemId);
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '5px',
                fontSize: '14px',
              }}
            >
              <Box sx={{ textTransform: 'capitalize' }}>
                {' '}
                {itemList?.itemName
                  ? itemList?.itemName
                  : formatTextToUpperCase(itemData?.type)}
              </Box>
              <Box>{amountFormat(itemData.amount || 0)}</Box>
            </Box>
          );
        })}
      </>
    );
  };

  const lineItemSectionAccordion2 = (
    item: any,
    index: number,
    type: string
  ) => {
    return (
      <>
        <Box
          sx={{
            margin: '10px 0px',
            background: '#f5f5f5!important',
            padding: '16px',
            borderRadius: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {loadGroupInvoiceTitleRender(item, isInvoiceCreate, false)}
            <>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography
                  sx={(theme) => ({ color: theme.palette.primary.main })}
                >
                  {getTotalSumAmount(index)}
                </Typography>

                {type !== 'childInvoices' && (
                  <Box
                    sx={{ ml: 1, alignItems: 'start' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <LongMenu
                      color={'primary.main'}
                      iconButtonStyles={{ p: 0 }}
                      options={[
                        {
                          name: 'Edit',
                          action: () => {
                            onEditInvoiceLineItem(item, index, type);
                          },
                        },
                      ]}
                    />
                  </Box>
                )}
                {type === 'childInvoices' &&
                  invoice?.status != 'PAID' &&
                  canEditInvoice &&
                  !onHold && (
                    <Box
                      sx={{ ml: 1, alignItems: 'start' }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <LongMenu
                        color={'primary.main'}
                        options={[
                          {
                            name: 'Remove',
                            action: () => {
                              if (type === 'childInvoices' && isInvoiceCreate) {
                                removingLoads(index);
                              } else if (!isInvoiceCreate) {
                                setSelectedLoadId(index);
                                if (
                                  childInvoices.length === 1 &&
                                  invoice.lineItems.length === 0
                                ) {
                                  setDeleteDialogOpen({
                                    open: true,
                                    type: 'delete',
                                    title: 'Remove',
                                    //body:'Are you sure you want to remove the load?'
                                    subMsg:
                                      'Removing the load will also delete the combined invoice.',
                                    cancel: 'Okay',
                                    hideBtn: true,
                                    okay: removingLoads,
                                  });
                                }
                                if (childInvoices.length > 0) {
                                  setDeleteDialogOpen({
                                    open: true,
                                    type: 'delete',
                                    title: 'Remove Load',
                                    //body:'Are you sure you want to remove the load?'
                                    subMsg:
                                      'Removing the load, will also remove all of its invoice line items from combined invoice.',
                                    cancel: 'Okay',
                                    hideBtn: true,
                                    okay: removingLoads,
                                  });
                                }
                              }
                            },
                          },
                          {
                            name: 'Edit',
                            action: () => {
                              onEditInvoiceLineItem(item, index, type);
                            },
                          },
                        ]}
                        iconButtonStyles={{ p: 0 }}
                      />
                    </Box>
                  )}
              </Box>
            </>
          </Box>
          {displayLineItem(item?.lineItems)}

          {item.warnings?.length || item.missingDocuments?.length ? (
            <Warnings
              warnings={item.warnings}
              missingDocuments={item.missingDocuments}
            />
          ) : null}
        </Box>
      </>
    );
  };
  // const lineItemSectionAccordion = (item: any, index: number, type: string) => {
  //   return (
  //     <Box sx={{ margin: '10px 0px ' }}>
  //       <AxeleAccordion
  //         titleRenderer={() =>
  //           loadGroupInvoiceTitleRender(
  //             item,
  //             isInvoiceCreate,
  //             item.warnings?.length || item.missingDocuments?.length
  //           )
  //         }
  //         renderLabel={() => (
  //           <>
  //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //               <Typography
  //                 sx={(theme) => ({ color: theme.palette.primary.main })}
  //               >
  //                 {getTotalSumAmount(index)}
  //               </Typography>

  //               {type !== 'childInvoices' && (
  //                 <Box sx={{ ml: 1 }} onClick={(e) => e.stopPropagation()}>
  //                   <LongMenu
  //                     color={'primary.main'}
  //                     iconButtonStyles={{ p: 0 }}
  //                     options={[
  //                       {
  //                         name: 'Edit',
  //                         action: () => {
  //                           onEditInvoiceLineItem(item, index, type);
  //                         },
  //                       },
  //                     ]}
  //                   />
  //                 </Box>
  //               )}

  //               {type === 'childInvoices' &&
  //                 invoice?.status != 'PAID' &&
  //                 canEditInvoice &&
  //                 !onHold && (
  //                   <Box sx={{ ml: 1 }} onClick={(e) => e.stopPropagation()}>
  //                     <LongMenu
  //                       color={'primary.main'}
  //                       options={[
  //                         {
  //                           name: 'Remove',
  //                           action: () => {
  //                             if (type === 'childInvoices' && isInvoiceCreate) {
  //                               removingLoads(index);
  //                             } else if (!isInvoiceCreate) {
  //                               setSelectedLoadId(index);
  //                               if (
  //                                 childInvoices.length === 1 &&
  //                                 invoice.lineItems.length === 0
  //                               ) {
  //                                 setDeleteDialogOpen({
  //                                   open: true,
  //                                   type: 'delete',
  //                                   title: 'Remove',
  //                                   //body:'Are you sure you want to remove the load?'
  //                                   subMsg:
  //                                     'Removing the load will also delete the combined invoice.',
  //                                   cancel: 'Okay',
  //                                   hideBtn: true,
  //                                   okay: removingLoads,
  //                                 });
  //                               }
  //                               if (childInvoices.length > 0) {
  //                                 setDeleteDialogOpen({
  //                                   open: true,
  //                                   type: 'delete',
  //                                   title: 'Remove Load',
  //                                   //body:'Are you sure you want to remove the load?'
  //                                   subMsg:
  //                                     'Removing the load, will also remove all of its invoice line items from combined invoice.',
  //                                   cancel: 'Okay',
  //                                   hideBtn: true,
  //                                   okay: removingLoads,
  //                                 });
  //                               }
  //                             }
  //                           },
  //                         },
  //                         {
  //                           name: 'Edit',
  //                           action: () => {
  //                             onEditInvoiceLineItem(item, index, type);
  //                           },
  //                         },
  //                       ]}
  //                     />
  //                   </Box>
  //                 )}
  //             </Box>
  //           </>
  //         )}
  //       >
  //         <Box sx={{ width: '100%' }}>
  //           <LoadGroupInvoiceSection
  //             invoice={invoice}
  //             foundIndex={index}
  //             type={type}
  //             canEditInvoice={canEditInvoice}
  //           />
  //           {item.warnings?.length || item.missingDocuments?.length ? (
  //             <Warnings
  //               warnings={item.warnings}
  //               missingDocuments={item.missingDocuments}
  //             />
  //           ) : null}
  //           <InvoiceLineItemsSection
  //             invoice={item}
  //             isBaseLineItemExpanded={false}
  //             isCreating={isInvoiceCreate}
  //             foundIndex={index}
  //             mainLineItem={!(type === 'childInvoices')}
  //             canEditInvoice={canEditInvoice}
  //           />
  //           <DocumentsSection
  //             type={invoice.invoiceType}
  //             foundIndex={index}
  //             isDisabled={!canEditInvoice}
  //           />
  //         </Box>
  //       </AxeleAccordion>
  //     </Box>
  //   );
  // };

  const lineItemSection = () => {
    const isLoadSet = watch('isLoadSet');

    return (
      <Grid sx={{ md: 12, width: '100%', pb: 1, mt: -2 }}>
        <Box>
          <SingleAutocompleteGroupinvoice
            canEditInvoice={canEditInvoice}
            disabled={onHold}
          />
        </Box>

        {childInvoices?.map((item: any, index: number) => {
          return lineItemSectionAccordion2(item, index, 'childInvoices');
        })}

        {isLoadSet && (
          <Stack direction="column" sx={{ m: '5px 0px' }}>
            <Skeleton height={'3rem'} variant="rectangular" width={'100%'} />
          </Stack>
        )}
      </Grid>
    );
  };
  return (
    <Box
      sx={{ m: isInvoiceCreate ? '0px 20px 0px 20px' : '0px 10px 20PX 0PX' }}
    >
      {isLoading ? (
        InvoiceLoader()
      ) : (
        <Box sx={{ width: '100%', paddingBottom: '40px' }}>
          {!isInvoiceCreate && (
            <>
              {/* {invoice?.onHold && <OnHoldInvoiceWarning loadId="xxx" />} */}
              <BasicActions
                invoice={invoice}
                isStatusUpdated={isStatusUpdated}
                editDetails={ediDetails as any}
              />
              <hr style={{ marginTop: 12 }} />
            </>
          )}

          <Accordion
            name="Invoice Settings"
            updateParentState={(data) => {
              setCardExpanded((prevState) => ({
                ...prevState,
                invoiceSetting: data,
              }));
            }}
            summaryRenderer={titleRenderer}
            expanded={cardExpanded.invoiceSetting}
            summaryStyle={accordionStyle}
          >
            <InvoiceSettings
              invoice={invoice}
              canEditInvoice={canEditInvoice}
            />
          </Accordion>
          {invoiceType != 'LOAD' && (
            <>
              <hr style={{ marginTop: cardExpanded.invoiceSetting ? 0 : 10 }} />
              <Accordion
                name={'Associated Loads (' + childInvoices?.length || 0 + ')'}
                updateParentState={(data) => {
                  setCardExpanded((prevState) => ({
                    ...prevState,
                    associatedLoads: data,
                  }));
                }}
                summaryStyle={accordionStyle}
                summaryRenderer={associatedLoadsTitleRenderer}
                expanded={cardExpanded.associatedLoads}
              >
                <></>
              </Accordion>
              {cardExpanded.associatedLoads && lineItemSection()}
            </>
          )}

          <hr style={{ marginTop: 4, marginBottom: 12 }} />
          {invoiceType === 'LOAD' ? (
            lineItemSectionAccordion2(
              {
                ...invoice,
                completeDate: completeDate,
                emptyMiles: emptyMiles,
                loadedMiles: loadedMiles,
                lineItems: watchLineItem,
              },
              0,
              'lineItem'
            )
          ) : (
            <Grid sx={{ md: 6, width: '100%', pb: '2px' }}>
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: '600',
                  lineHeight: '1.167',
                  color: 'primary.main',
                }}
              >
                Additional Invoice Items
              </Typography>
              {/* {invoice.warnings?.length || invoice.missingDocuments?.length ? (
                <Warnings
                  warnings={invoice.warnings}
                  missingDocuments={invoice.missingDocuments}
                />
              ) : null} */}
              <InvoiceLineItemsSection
                invoice={invoice}
                isBaseLineItemExpanded={false}
                isCreating={isInvoiceCreate}
                foundIndex={0}
                mainLineItem={true}
                canEditInvoice={canEditInvoice}
              />
            </Grid>
          )}

          <hr />
          <InvoiceReceivedPayment
            factoringChargeAmount={invoice.factoringCharge!}
            loadStatus={invoice.status}
            isFactoreView={
              !isInvoiceCreate &&
              invoiceType === 'LOAD' &&
              !invoice.childInvoice
            }
            canEditInvoice={canEditInvoice}
          />
          {!isInvoiceCreate &&
            invoiceType === 'LOAD' &&
            !invoice.childInvoice && (
              <>
                <hr style={{ marginTop: 12, marginBottom: 12 }} />
                <Factoring
                  invoice={invoice}
                  triggerAlert={triggerAlert}
                  handleFactoringResponse={handleFactoringResponse}
                  canEditInvoice={canEditInvoice}
                  isFactorValueUpdated={isFactorValueUpdated}
                />
                {/* <hr style={{ marginTop: 12, marginBottom: 12 }} /> */}
              </>
            )}

          <DeletePopup
            title={deleteDialogOpen.title}
            body={deleteDialogOpen.subMsg}
            open={deleteDialogOpen?.open}
            cancelText={deleteDialogOpen?.cancel}
            // hideDelete={deleteDialogOpen.hideBtn}
            onClose={() =>
              setDeleteDialogOpen((prevState) => ({
                ...prevState,
                open: false,
              }))
            }
            onAction={() => removingLoads(selectedLoadId)}
            okText="Remove"
            okTextStyles={{ color: 'white' }}
          />

          {selAssociatedLoadItem && (
            <LoadInvoiceRateLineItems
              onClose={() => {
                setSelAssociatedLoadItem(false);
              }}
              data={selAssociatedLoadItem?.item}
              foundIndex={selAssociatedLoadItem?.index}
              mainLineItem={!(selAssociatedLoadItem?.type === 'childInvoices')}
              onSave={(item: any[]) => {
                setSelAssociatedLoadItem(false);
                onSave?.();
              }}
              canEditInvoice={canEditPaidInvoice}
              invoice={invoice}
            ></LoadInvoiceRateLineItems>
          )}
        </Box>
      )}
    </Box>
  );
}
