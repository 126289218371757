import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ActionIconButton from './ActionIconButton';

interface ICloseButton {
  onClick?: () => void;
  style?: object;
}

const CloseButton: React.FC<ICloseButton> = (props) => {
  const { onClick, style = {} } = props;

  return (
    <ActionIconButton
      onClick={onClick}
      variant="contained"
      style={{ ml: 'auto', width: '38px', minWidth: '38px', ...style }}
    >
      <CloseIcon sx={{ width: '18px', height: '18px' }} />
    </ActionIconButton>
  );
};

export default CloseButton;
