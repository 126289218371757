import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import TableSettings from '../../../../ui-kit/components/DataGridPro/settings/TableSettings';
import { Box } from '@mui/material';

type TableSettingsProps = Parameters<typeof TableSettings>[0];

const StyledTableSettings: ForwardRefRenderFunction<
  HTMLDivElement,
  TableSettingsProps
> = (props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        '> .MuiButtonBase-root': {
          pt: '0!important',
          mt: '0!important',
        },
      }}
    >
      <TableSettings {...props} />
    </Box>
  );
};

export default forwardRef(StyledTableSettings);
