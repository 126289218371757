import { carrierService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { statesinCountry } from '../../../common/Ui/AddressField/constants/states';
import {
  GetSearchContactNameRequest,
  GetSearchDotRequest,
  GetSearchMcRequest,
  GetSearchNameRequest,
  GetSearchScacRequest,
} from '../../../models/DTOs/Carrier/Requests';
import { ICarrierAutocompleteOption } from '../constants/types';

export const getStaticListOptions = async (
  searchString: string,
  pageNumber: number,
  primaryKey: 'carrierStatus' | 'workMode' | 'insuranceType'
) => {
  const response = await carrierService.getStaticList();
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    content: response?.[primaryKey] || [],
    last: true,
  };
};

const fromSearchResultToOptions = (
  data?: string[]
): ICarrierAutocompleteOption[] => {
  if (!data?.length) return [];
  return data.map((text) => ({
    key: text,
    value: text,
  }));
};

export const searchName = async (searchString: string, pageNumber: number) => {
  const response = await carrierService.searchName(
    new GetSearchNameRequest({
      carrierSearchName: searchString,
      pageNumber: pageNumber,
      pageSize: 25,
    })
  );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: fromSearchResultToOptions(response?.content),
  };
};
export const searchContactName = async (
  searchString: string,
  pageNumber: number
) => {
  const response = await carrierService.searchContactName(
    new GetSearchContactNameRequest({
      carrierSearchContactName: searchString,
    })
  );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: fromSearchResultToOptions(response?.content),
  };
};
export const searchMc = async (searchString: string, pageNumber: number) => {
  const response = await carrierService.searchMc(
    new GetSearchMcRequest({
      carrierSearchMc: searchString,
    })
  );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: fromSearchResultToOptions(response?.content),
  };
};
export const searchDot = async (searchString: string, pageNumber: number) => {
  const response = await carrierService.searchDot(
    new GetSearchDotRequest({
      carrierSearchDot: searchString,
    })
  );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: fromSearchResultToOptions(response?.content),
  };
};
export const searchScac = async (searchString: string, pageNumber: number) => {
  const response = await carrierService.searchScac(
    new GetSearchScacRequest({
      carrierSearchScac: searchString,
    })
  );
  if (response instanceof ServiceError) {
    return {
      content: [],
      last: true,
    };
  }
  return {
    ...response,
    content: fromSearchResultToOptions(response?.content),
  };
};

export const getStateOptions = (): ICarrierAutocompleteOption[] => {
  return statesinCountry.map(({ name, value }) => ({
    key: value,
    value: name,
  }));
};
