import {
  BulkSubmitRequest,
  DeleteInvoiceRequest,
  DisconnectFromFactoringRequest,
  DownloadInvoicePreviewRequest,
  DownloadInvoicesAsZipRequest,
  DownloadInvoicesRequest,
  DriverMappingType,
  ExportInvoiceListRequest,
  GetDriverMappingDetailsRequest,
  GetFundingAccountsRequest,
  GetIntegrationsRequest,
  GetInvoiceActionDataRequest,
  GetInvoiceLineItemsRequest,
  GetInvoiceListRequest,
  GetInvoiceNotesRequest,
  GetInvoiceSearchLoadsRequest,
  GetInvoiceWarningsRequest,
  GetloadIdListRequest,
  IntegrationTypeDTO,
  InvoiceSearchLoadsListType,
  InvoiceSearchLoadsType,
  InvoiceToTurvoRequest,
  InvoiceWarningType,
  OnHoldStateInvoiceRequest,
  OnHoldStateInvoiceResponse,
  PreviewInvoiceRequest,
  RefreshFactoringStatusRequest,
  RefreshLoadAndTripsDriversRequest,
  SendDocumentRequest,
  SendEmailRequest,
  SendFactoringBulkRequest,
  SendFactoringRequest,
  SendFactoringRequestType,
  SendMultipleEmailRequest,
  StatusChangeRequest,
  StatusChangeSingleRequest,
  UpdateInvoiceRequest,
  UpdateInvoiceResponse,
} from '../../models';
import {
  DriverGroupDetailType,
  FactoringActionReturnType,
  GetInvoiceNotesResponse,
  InvoiceCreatModel,
  InvoiceModel,
  InvoiceTotalsType,
  InvoiceUpdateModel,
  LineItem,
} from '../../subPages/invoices/models/InvoiceModel';
import { PaginatedResult, ViewMetaData } from '../../types';
import { docType } from '../../utils/Doc';
import { Service } from './Service';
import { ServiceError } from './helperTypes';

/* @TODO davit remove any-s as much as possible */
export abstract class IInvoiceService extends Service {
  abstract getInvoiceList(
    requestData: GetInvoiceListRequest,
    payLoad: GetInvoiceListRequest
  ): Promise<PaginatedResult<InvoiceModel> | ServiceError>;

  abstract getInvoiceWarningsList(
    requestData: GetInvoiceWarningsRequest
  ): Promise<InvoiceWarningType | ServiceError>;

  abstract getInvoiceTotals(
    requestData: GetInvoiceListRequest,
    payLoad: GetInvoiceListRequest
  ): Promise<InvoiceTotalsType | ServiceError>;

  abstract getInvoiceLineItems(
    requestData: GetInvoiceLineItemsRequest
  ): Promise<LineItem[] | ServiceError>;

  abstract updateInvoice(
    data: InvoiceUpdateModel
  ): Promise<InvoiceModel | ServiceError>;

  abstract downloadInvoice(
    requestData: DownloadInvoicesRequest
  ): Promise<docType[] | ServiceError>;

  abstract downloadInvoicesAsZip(
    requestData: DownloadInvoicesAsZipRequest
  ): Promise<docType[] | ServiceError>;

  abstract previewInvoice(
    requestData: PreviewInvoiceRequest
  ): Promise<docType | ServiceError>;
  abstract previewInvoiceDownload(
    requestData: DownloadInvoicePreviewRequest
  ): Promise<docType | ServiceError>;

  abstract statusChange(
    requestData: StatusChangeRequest
  ): Promise<InvoiceModel[] | ServiceError>;
  abstract statusChangeSingle(
    requestData: StatusChangeSingleRequest
  ): Promise<InvoiceModel | ServiceError>;

  abstract getDriverMappingDetails(
    requestData: GetDriverMappingDetailsRequest
  ): Promise<Record<string, DriverMappingType> | ServiceError>;

  abstract sendEmail(
    requestData: SendEmailRequest
  ): Promise<string | ServiceError>;

  abstract sendFactoring(
    requestData: SendFactoringRequest
  ): Promise<FactoringActionReturnType | ServiceError>;

  abstract sendFactoringBulk(
    requestData: SendFactoringBulkRequest
  ): Promise<FactoringActionReturnType | ServiceError>;

  abstract sendDocument(
    requestData: SendDocumentRequest,
    data: SendFactoringRequestType
  ): Promise<FactoringActionReturnType | ServiceError>;

  abstract updateAtFactoring(
    requestData: SendDocumentRequest,
    data: SendFactoringRequestType
  ): Promise<FactoringActionReturnType | ServiceError>;

  abstract exportInvoiceList(
    requestData: ExportInvoiceListRequest,
    filters: ViewMetaData,
    columns: string[],
    sort: string
  ): Promise<docType | ServiceError>;

  abstract getFundingAccounts(
    requestData: GetFundingAccountsRequest
  ): Promise<any | ServiceError>;

  abstract refreshFactoringStatus(
    requestData: RefreshFactoringStatusRequest
  ): Promise<FactoringActionReturnType | ServiceError>;

  abstract bulkSubmit(
    requestData: BulkSubmitRequest
  ): Promise<FactoringActionReturnType | ServiceError>;

  abstract disconnectFromFactoring(
    requestData: DisconnectFromFactoringRequest
  ): Promise<FactoringActionReturnType | ServiceError>;

  abstract getIntegrations(
    requestData: GetIntegrationsRequest
  ): Promise<{ integrationsDTO: IntegrationTypeDTO } | ServiceError>;

  abstract sendMultipleEmail(
    requestData: SendMultipleEmailRequest
  ): Promise<string | ServiceError>;

  abstract getInvoiceNotes(
    requestData: GetInvoiceNotesRequest
  ): Promise<GetInvoiceNotesResponse | ServiceError>;

  abstract loadAndTripsDrivers(
    requestData: RefreshLoadAndTripsDriversRequest
  ): Promise<DriverGroupDetailType[] | ServiceError>;
  abstract sendInvoiceToTurvo(
    requestData: InvoiceToTurvoRequest
  ): Promise<UpdateInvoiceResponse | ServiceError>;
  abstract sendInvoiceToBitFreighter(
    requestData: InvoiceToTurvoRequest
  ): Promise<UpdateInvoiceResponse | ServiceError>;
  abstract sendInvoiceToProviderViaEdi(
    requestData: UpdateInvoiceRequest,
    data: InvoiceModel
  ): Promise<UpdateInvoiceResponse | ServiceError>;
  abstract getInvoiceSearchLoads(
    requestData: GetInvoiceSearchLoadsRequest
  ): Promise<InvoiceSearchLoadsType | ServiceError>;
  abstract createInvoice(
    requestData: InvoiceCreatModel
  ): Promise<string | ServiceError>;
  abstract deleteInvoice(
    requestData: DeleteInvoiceRequest
  ): Promise<string | ServiceError>;
  abstract getloadIdList(
    requestData: GetloadIdListRequest
  ): Promise<InvoiceSearchLoadsListType | ServiceError>;
  abstract getInvoiceActionData(
    requestData: GetInvoiceActionDataRequest
  ): Promise<Array<string> | ServiceError>;
  abstract onHoldStateLoad(
    request: OnHoldStateInvoiceRequest
  ): Promise<OnHoldStateInvoiceResponse | ServiceError>;
}
