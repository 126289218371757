import MaintenanceTabDetails from '../../equipment/maintenance/MaintenanceTabDetails';
import { useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { EquipmentType } from '../../../views/maintenanceRecords/constants/constants';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { isInactive } from '../../tractors/utils';

export const WrapperMaintenanceTabDetails: FC<{}> = () => {
  const { watch, getValues } = useFormContext();
  const { getGlobalTerminals } = useRootStore();
  const assetId = watch('id');
  const data = getValues();
  const foundTerminal = getGlobalTerminals?.find(
    (item) => item.id === data?.terminalId
  );

  const defaultMaintenanceRecord = {
    equipmentType: EquipmentType.Trailer,
    equipmentName: data?.trailerName || data.id,
    equipmentId: +data.id,
    terminalName: foundTerminal?.companyName,
    terminalId: data.terminalId || data.terminal?.id,
    status: data.status,
  };
  return (
    <MaintenanceTabDetails
      assetId={assetId}
      equipmentTypes={EquipmentType.Trailer}
      defaultMaintenanceRecord={defaultMaintenanceRecord}
      disableAllFields={isInactive(data.status)}
    />
  );
};
