import Box from '@mui/material/Box';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import { Checkbox, Grid, Slider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import * as yup from 'yup';
import EmailReceivers from '../../../../common/Email/EmailReceivers';
import FormDialog from '../../../../common/Ui/FormDialog';
import TextField from '../../../../common/Ui/TextField/TextField';
import StorageManager from '../../../../StorageManager/StorageManager';
import { EmailFormType } from '../../../../subPages/invoices/models/InvoiceModel';
import {
  constantSymbolStyles,
  constantSymbolStylesVar2,
  gridBottomStyles,
  gridInnerWraaperStyle,
  inputStyles,
  inputStylesVar2,
  inputStylesVar3,
  swapWrapperStyles,
} from '../../components/ProfitCalculator/styles';
const Wisiwig = lazy(() => import('../../../../ui-kit/components/Wisiwig'));

export function InvoiceSendEmailForm({
  invoice,
  customerEmail,
  seqNumber,
  selectedRowData,
  handleChangeOfferPrice,
  message,
}: {
  invoice: string;
  customerEmail: string;
  seqNumber: string;
  selectedRowData: any;
  handleChangeOfferPrice: any;
  message?: string;
}) {
  const { control, setValue } = useFormContext();
  const [offerScoreValue, setOfferScoreValue] = useState(
    parseInt(selectedRowData?.Offer?.estimatedProfit) +
      parseInt(selectedRowData?.Offer?.variableCost) +
      parseInt(selectedRowData?.Offer?.fixedCost)
  );
  const [editableValue, setEditableValue] = useState<string>('offerPrice');
  const [nonEditableValue, setNonEditableValue] = useState<string>('mileage');
  const [calculatorType, setCalculatorType] = useState<string>('offer');
  const [netProfitValue, setProfit] = useState<number>(0);
  const [offerPrice, setOfferPrice] = useState<number>(0);
  const [variableCost, setVariableCost] = useState<number>(0);
  const [fixedCost, setFixedCost] = useState<number>(0);
  const [mileage, setMileage] = useState<number>(0);
  const [totalMiles, setTotalMiles] = useState<number>(0);
  const [mileScoreValue, setMileScoreValue] = useState<number>(
    parseFloat(selectedRowData?.Mileage?.subvalue)
  );
  const [minSliderValue, setMinSliderValue] = useState(0);
  const [recipentEmail, setRecipentEmail] = useState<string>(
    selectedRowData?.Broker?.email
  );
  const [bidDescription, setBidDescription] = useState<string>('');
  const [displayError, setDisplayError] = useState<{
    recipentEmail: string;
    bidDescription: string;
  }>({
    recipentEmail: '',
    bidDescription: '',
  });
  const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(true);

  const brokerDefaultEmail = selectedRowData?.Broker?.email;

  const pessimisticRate = Number(
    Number(selectedRowData?.MarketRate?.pessimisticRate).toFixed(2)
  );
  const optimisticRate = Number(
    Number(selectedRowData?.MarketRate?.optimisticRate).toFixed(2)
  );
  const avgRate = Number(
    (Number(pessimisticRate + optimisticRate) / 2).toFixed(2)
  );
  const [sliderDefaultValue, setSliderDefaultValue] = useState(0);

  useEffect(() => {
    const profit = Number(selectedRowData?.Offer?.estimatedProfit);
    const varCost = Number(selectedRowData?.Offer?.variableCost);
    const fixCost = Number(selectedRowData?.Offer?.fixedCost);
    const miles = Number(selectedRowData?.Mileage?.subvalue);
    const totalMile = Number(
      Number(selectedRowData?.LoadedMiles?.actualTotalMiles).toFixed(2)
    );
    setOfferPrice(Number((profit + varCost + fixCost).toFixed(2)));
    setProfit(Number(profit.toFixed(2)));
    setVariableCost(varCost);
    setFixedCost(fixCost);
    setMileage(miles);
    setTotalMiles(totalMile);
    setOfferScoreValue(Number((profit + varCost + fixCost).toFixed(2)));
  }, []);

  useEffect(() => {
    handleChangeOfferPrice(offerPrice);
  });
  //@TODO: Need to refactor
  useEffect(() => {
    setValue('message', message);
  }, [message]);
  const marks = [
    {
      value: pessimisticRate,
      label: `$${pessimisticRate}`,
    },
    {
      value: avgRate,
      label: `$${avgRate}`,
    },
    {
      value: optimisticRate,
      label: `$${optimisticRate}`,
    },
  ];
  const offerBreaks = [
    {
      value: Number((Number(pessimisticRate) * totalMiles).toFixed(2)),
      label: `$${Number((Number(pessimisticRate) * totalMiles).toFixed(2))}`,
    },
    {
      value: Number((Number(avgRate) * totalMiles).toFixed(2)),
      label: `$${Number(
        (Number(avgRate) * totalMiles).toFixed(2)
      )} (Market Rate)`,
    },
    {
      value: Number((Number(optimisticRate) * totalMiles).toFixed(2)),
      label: `$${Number((Number(optimisticRate) * totalMiles).toFixed(2))}`,
    },
  ];

  useEffect(() => {
    setMinSliderValue(
      Number((Number(pessimisticRate) * totalMiles).toFixed(2))
    );
  }, []);

  const swapValues = (): void => {
    if (editableValue === 'offerPrice') {
      setEditableValue('mileage');
      setNonEditableValue('offerPrice');
    } else {
      setEditableValue('offerPrice');
      setNonEditableValue('mileage');
    }
  };

  const offerPriceChangeHandler = (e: any) => {
    if (e.target.name === 'offerPrice') {
      setOfferPrice(Number(Number(e.target.value).toFixed(2)));
      setProfit(
        Number((Number(e.target.value) - fixedCost - variableCost).toFixed(2))
      );
      setOfferScoreValue(Number(Number(e.target.value).toFixed(2)));
      const m = (Number(e.target.value) / totalMiles).toFixed(2);
      setMileage(Number(m));
    } else {
      setMileage(Number(Number(e.target.value).toFixed(2)));
      setOfferPrice(Number((Number(e.target.value) * totalMiles).toFixed(2)));
      setProfit(
        Number((Number(e.target.value) * totalMiles - variableCost).toFixed(2))
      );
      setMileScoreValue(Number(Number(e.target.value).toFixed(2)));
    }
  };

  useEffect(() => {
    let sliderValue;
    if (calculatorType === 'offer') {
      if (editableValue === 'offerPrice') {
        sliderValue = offerScoreValue;
      } else sliderValue = mileScoreValue;

      setSliderDefaultValue(sliderValue);
    }
  }, [calculatorType, offerScoreValue, mileScoreValue, offerPrice]);

  return (
    <>
      <Stack
        sx={{
          p: '10px',
        }}
      >
        <Grid container>
          <Grid
            item
            sx={{
              width: '50%',
              margin: '0 auto',
              pt: '8px',
              ...(editableValue === 'mileage' && {
                width: '50%',
              }),
            }}
          >
            <Grid item sx={gridInnerWraaperStyle}>
              <Typography sx={constantSymbolStyles}>$</Typography>
              <input
                type="number"
                name={`${
                  editableValue === 'offerPrice' ? 'offerPrice' : 'mileage'
                }`}
                value={`${
                  editableValue === 'offerPrice' ? offerPrice : mileage
                }`}
                style={
                  editableValue === 'offerPrice' ? inputStyles : inputStylesVar2
                }
                onChange={offerPriceChangeHandler}
              />
              {editableValue === 'mileage' && (
                <Typography sx={constantSymbolStyles}>/mi</Typography>
              )}
            </Grid>

            <Grid item sx={gridBottomStyles}>
              <Typography sx={constantSymbolStylesVar2}>$</Typography>
              <input
                type="number"
                name="offerPrice"
                disabled
                value={`${
                  nonEditableValue === 'offerPrice' ? offerPrice : mileage
                }`}
                style={inputStylesVar3}
              />
              {editableValue === 'offerPrice' && (
                <Typography sx={constantSymbolStylesVar2}>/mi</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item sx={swapWrapperStyles}>
            <SwapVertOutlinedIcon onClick={swapValues} />
          </Grid>
        </Grid>
        <Stack>
          <Slider
            track={false}
            aria-label="track-false-slider"
            defaultValue={sliderDefaultValue}
            disabled={true}
            value={sliderDefaultValue}
            valueLabelDisplay="auto"
            step={3}
            marks={editableValue === 'offerPrice' ? offerBreaks : marks}
            min={
              editableValue === 'offerPrice'
                ? Number((pessimisticRate * totalMiles).toFixed(2))
                : pessimisticRate
            }
            max={
              editableValue === 'offerPrice'
                ? Number((optimisticRate * totalMiles).toFixed(2))
                : optimisticRate
            }
            sx={{
              width: '95%',
              m: '0 auto 20px',
              '.MuiSlider-markLabel': {
                fontWeight: 400,
                fontSize: '10px',
                lineHeight: ' 15px',
                letterSpacing: '0.4px',
                color: '#4E6578',
              },
              '.MuiSlider-thumb': {
                width: '16px',
                height: '16px',
                ...((mileScoreValue > optimisticRate ||
                  mileScoreValue < pessimisticRate) && {
                  backgroundColor: 'error.main',
                }),
                ...(((mileScoreValue > avgRate &&
                  mileScoreValue <= optimisticRate) ||
                  (mileScoreValue < avgRate &&
                    mileScoreValue >= pessimisticRate)) && {
                  backgroundColor: 'primary.main',
                }),
                ...((offerScoreValue < pessimisticRate * totalMiles ||
                  offerScoreValue > optimisticRate * totalMiles) && {
                  backgroundColor: 'error.main',
                }),
              },
            }}
          />
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              mt: 1,
            }}
          >
            <Stack
              sx={{
                width: '100%',
                padding: '5px, 5px, 5px, 5px',
              }}
            >
              <EmailReceivers />

              <Box>
                <TextField control={control} name="subject" label="Subject" />
              </Box>

              <Box sx={{ marginTop: '20px' }}>
                <Suspense fallback={<></>}>
                  <Wisiwig
                    customStyles={{
                      contentBackground: {
                        backgroundColor: 'transparent',
                      },
                      headerBackgroundColor: {
                        backgroundColor: 'transparent',
                      },
                      bodyColor: {
                        color: '#c5c8ca',
                      },
                    }}
                    control={control}
                    name="message"
                  />
                </Suspense>
              </Box>
            </Stack>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
}

export function FinanceLoadEmail({
  open,
  onAction,
  onClose,
  invoice,
  customerEmail,
  seqNumber,
  emailName,
  selectedRowData,
  handleChangeOfferPrice,
}: {
  open: boolean;
  onAction: (data: EmailFormType) => void;
  onClose: () => void;
  invoice: string;
  seqNumber: string;
  customerEmail: string;
  emailName: string;
  selectedRowData: any;
  handleChangeOfferPrice: any;
}) {
  const user = StorageManager.getUser();
  const [offerPrice, setOfferPrice] = useState<number>(
    selectedRowData.Offer.value
  );
  const to =
    selectedRowData &&
    selectedRowData.Broker &&
    selectedRowData.Broker.email &&
    selectedRowData.Broker.email;
  const organizationName = user.organizationName;
  const origin = `${selectedRowData.Pickup.city},${selectedRowData.Pickup.country}`;
  const destination = `${selectedRowData.Dropoff.city},${selectedRowData.Dropoff.country}`;

  const defaultData = useMemo(
    () => ({
      to: to ? [to] : [],
      cc: [],
      subject: getInvoiceEmailDefaultSubjectCustome(origin, destination),
      message: getInvoiceEmailDefaultMessageCustome(
        organizationName,
        emailName,
        origin,
        destination,
        offerPrice
      ),
      invoice: true,
      rateConfirmation: true,
      billOfLading: true,
      proofOfDelivery: true,
      other: true,
      markAsInvoiced: true,
      documentIds: [],
      invoiceId: invoice,
    }),
    [offerPrice]
  );

  const handleUpdateOfferPrice = (offerPrice: number) => {
    setOfferPrice(offerPrice);
    handleChangeOfferPrice(offerPrice);
  };
  return (
    <>
      <FormDialog
        data={defaultData}
        validationSchema={yup.object().shape({
          to: yup.array().of(yup.string()).nullable().min(1, 'To is required'),
        })}
        titleText="Quick Email Bid"
        actionLabel="Email"
        open={open}
        onAction={onAction}
        handleClose={onClose}
        contentRenderer={() => (
          <InvoiceSendEmailForm
            selectedRowData={selectedRowData}
            invoice={invoice}
            customerEmail={customerEmail}
            seqNumber={seqNumber}
            handleChangeOfferPrice={handleUpdateOfferPrice}
            message={defaultData?.message}
          />
        )}
      />
    </>
  );
}

export function MultipleSendEmailDialog({
  open,
  onAction,
  onClose,
}: {
  open: boolean;
  onAction: (data: EmailFormType) => void;
  onClose: () => void;
}) {
  return (
    <FormDialog
      data={{
        cc: [],
        invoice: true,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
      }}
      titleText={t('emailInvoices')}
      actionLabel="Email"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <MultipleSendEmailForm />}
    />
  );
}

export function MultipleSendEmailForm() {
  const { control } = useFormContext();
  return (
    <>
      {/*<EmailReceivers hideTo />*/}
      <Box sx={{ mb: 4 }}>
        <Typography variant={'subtitle2'}>Attachments:</Typography>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'rateConfirmation'}
          label={t('rateConfirmation')}
        />
      </Box>
    </>
  );
}

export const getInvoiceEmailDefaultMessageCustome = (
  organizationName: string,
  emailName: string,
  origin: string,
  destination: string,
  offerPrice: string | number
) => {
  return `<p> Hey there! ${emailName} </p><br /></p>I found this load from ${origin} to ${destination}.<br /> <br />I am available to deliver the load for ${offerPrice} <br /> <br />Let me know of you would like to proceed with this booking.<br /><br />Regards,<br /> <b>${organizationName} </b></p>`;
};

export const getInvoiceEmailDefaultSubjectCustome = (
  origin: string,
  destination: string
) => {
  return `You've received a bid for ${origin} => ${destination}`;
};
