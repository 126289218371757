import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';

export default function SettingBlock({
  title,
  subTitle,
  children,
}: {
  title: string;
  subTitle: string;
  children: ReactNode;
}) {
  return (
    <Grid
      className="SettingBlock-root"
      sx={{ mt: 1 }}
      container
      spacing={1}
      direction="column"
    >
      <Grid item>
        <Typography variant={'h4'} color={'primary.main'}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={'subtitle1'} color="text.secondary">
          {subTitle}
        </Typography>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
}
