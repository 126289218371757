export const getSelectedReportStyle = ({ theme }) => {
  return {
    background: theme.palette.uncategorized.oldHeaderSecondary,
    color: theme.palette.primary.main,
    '.MuiListItemText-secondary': {
      color: theme.palette.primary.main,
      fontSize: '12px',
    },
  };
};

export const reportHeadingStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
  marginBottom: '10px',
};

export const visibleFieldsContainer = {
  minWidth: '500px',
  border: '1px solid #9E9E9E',
  padding: '16px 16px 8px 16px',
  borderRadius: '8px',
  display: 'flex',
  height: '98%',
  marginRight: '5px',
};

export const reportFilterContainerStyle = {
  width: '100%',
  border: '1px solid #9E9E9E',
  padding: '16px 16px 8px 16px',
  borderRadius: '8px',
  // display: 'flex',
  height: '98%',
};
export const reportListStyle = {
  bgcolor: 'background.paper',
  borderRadius: '16px 16px 0px 0px',
  height: '100%',
  width: '100%',
  marginLeft: '4px',
  padding: '24px',
};
export const fieldsListStyle = {
  padding: '16px 0px 16px 0px',
  textAlign: 'center',
  borderRadius: '8px',
  marginBottom: '10px',
  height: '54px',
};

export const fieldListContainerStyle = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
  '.MuiListItem-root': {
    paddingLeft: '5px !important',
  },
};
export const fieldCardContainerStyle = {
  '.MuiListItemButton-root': {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  '.MuiListItem-root': {
    paddingLeft: '5px !important',
  },
};

export const fieldCardButtonStyle = {
  '.MuiListItemButton-root': {
    paddingRight: '0px',
  },
  '.MuiListItem-root': {
    paddingLeft: '5px !important',
  },
  '.MuiListItemIcon-root': { minWidth: '35px' },
};
