/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import axiosInstance from '../../axios/axiosInstance';
import {
  IFTAPdfRequest,
  IFTAPdfResult,
  IFTAReportsRequest,
  IFTAReportsResult,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { IIFTAService, annotateNameAsync } from '../interfaces';

const routes = {
  getIFTAReports: '/web/expense/api/v2/cards',
  getIftaReportPdf: '/web/expense/api/v2/iftaReportPdf',
};

export class IFTAService extends IIFTAService {
  @annotateNameAsync
  async getIFTAReports(
    requestData: IFTAReportsRequest
  ): Promise<IFTAReportsResult> {
    try {
      const route = routes.getIFTAReports;
      const response = await axiosInstance.get(route, {
        params: requestData,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'failedData',
      });
      return Promise.resolve({});
    }
  }

  @annotateNameAsync
  async getIftaReportPdf(requestData: IFTAPdfRequest): Promise<IFTAPdfResult> {
    try {
      const route = routes.getIftaReportPdf;
      const response = await axiosInstance.get(route, {
        params: requestData.getParamsFromRequest(),
      });
      return Promise.resolve(response.data);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'download',
      });
      return Promise.resolve({});
    }
  }
}
