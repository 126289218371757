import { loadsearchService } from '../../../api';
import { LoadsearchConstants } from '../../../locales/en/allLoads/loadsearch/index';
import { GetEquipmentRequest } from '../../../models/DTOs/Loadsearch/Request';
import { ILoadboardFilters, ILoadboardSelectOption } from '../loadTypes';

export const loadboardFieldsConfig: {
  [key: string]: {
    fieldName: string;
    label: string;
    fieldFilterName?: keyof ILoadboardFilters;
    fieldFilterLabel?: string;
  };
} = {
  Age: {
    fieldName: 'Age',
    label: LoadsearchConstants.age,
    fieldFilterName: 'AgeFilter',
  },
  Pickup: {
    fieldName: 'Pickup',
    label: LoadsearchConstants.pickup,
    fieldFilterName: 'Pickup',
    fieldFilterLabel: 'Pickup',
  },
  LoadedMiles: {
    fieldName: 'LoadedMiles',
    label: LoadsearchConstants.loadedMiles,
    fieldFilterName: 'LoadedMiles',
  },
  Dropoff: {
    fieldName: 'Dropoff',
    label: LoadsearchConstants.dropoff,
    fieldFilterName: 'Dropoff',
  },
  Offer: {
    fieldName: 'Offer',
    label: LoadsearchConstants.offer,
    fieldFilterName: 'Offer',
  },
  Mileage: {
    fieldName: 'Mileage',
    label: '$/' + LoadsearchConstants.mile,
  },
  MarketRate: {
    fieldName: 'MarketRate',
    label: LoadsearchConstants.marketRate,
    fieldFilterName: 'MarketRate',
  },
  Equipment: {
    fieldName: 'Equipment',
    label: LoadsearchConstants.equipment,
    fieldFilterName: 'Equipment',
  },
  Broker: {
    fieldName: 'Broker',
    label: LoadsearchConstants.broker,
    fieldFilterName: 'Broker',
  },
  ReloadScore: {
    fieldName: 'ReloadScore',
    label: LoadsearchConstants.reloadScore,
    fieldFilterName: 'ReloadScore',
  },
  //refactogin begin
  sources: {
    fieldName: 'sources',
    label: LoadsearchConstants.loadboards,
    fieldFilterName: 'sources',
  },
  origins: {
    fieldName: 'origins',
    label: LoadsearchConstants.origin,
    fieldFilterName: 'origins',
  },
  destinations: {
    fieldName: 'destinations',
    label: LoadsearchConstants.destination,
    fieldFilterName: 'destinations',
  },
  pickupDateRanges: {
    fieldName: 'pickupDateRanges',
    label: LoadsearchConstants.pickupDate,
    fieldFilterName: 'pickupDateRanges',
  },
  reloadScoreFilter: {
    fieldName: 'reloadScoreFilter',
    label: LoadsearchConstants.reloadScore,
    fieldFilterName: 'reloadScoreFilter',
  },
  equipmentList: {
    fieldName: 'equipmentList',
    label: LoadsearchConstants.equipmentType,
    fieldFilterName: 'equipmentList',
  },
  customerPreferenceType: {
    fieldName: 'customerPreferenceType',
    label: LoadsearchConstants.customerPreference,
    fieldFilterName: 'customerPreferenceType',
  },
  loadType: {
    fieldName: 'loadType',
    label: LoadsearchConstants.loadType,
    fieldFilterName: 'loadType',
  },
  showActualOffersOnly: {
    fieldName: 'showActualOffersOnly',
    label: LoadsearchConstants.showActualOffersOnly,
    fieldFilterName: 'showActualOffersOnly',
  },
  maxOriginDeadhead: {
    fieldName: 'maxOriginDeadhead',
    label: LoadsearchConstants.deadheadOriginMiles + '(in miles)',
    fieldFilterName: 'maxOriginDeadhead',
  },
  maxDestinationDeadhead: {
    fieldName: 'maxDestinationDeadhead',
    label: LoadsearchConstants.deadheadDestinationMiles + '(in miles)',
    fieldFilterName: 'maxDestinationDeadhead',
  },
  ageOfLoad: {
    fieldName: 'ageOfLoad',
    label: LoadsearchConstants.maxAgeofLoad,
    fieldFilterName: 'ageOfLoad',
  },
  maxEquipmentLength: {
    fieldName: 'maxEquipmentLength',
    label: LoadsearchConstants.maxEquipmentLength,
    fieldFilterName: 'maxEquipmentLength',
  },
  maxEquipmentWeight: {
    fieldName: 'maxEquipmentWeight',
    label: LoadsearchConstants.maxEquipmentWeight,
    fieldFilterName: 'maxEquipmentWeight',
  },
  loadId: {
    fieldName: 'loadId',
    label: LoadsearchConstants.loadId,
    fieldFilterName: 'loadId',
  },
  //refactogin end
};

export const actionDataEnum = {
  bookApi: 'BOOK_API',
  bookLink: 'BOOK_LINK',
};
export const getEquipments = async (
  searchText: string | undefined,
  pageNumber: number
) => {
  const request = new GetEquipmentRequest();
  const res = await loadsearchService.getEquipmentsList(request);
  const equipments: Array<{
    name: string;
    id: string;
  }> = [];
  if (res && res.equipmentClassType && res.equipmentClassType.length > 0) {
    res.equipmentClassType.map(
      (
        el: {
          value: string;
          key: string;
        },
        _index: any
      ) => {
        const data = {
          name: el.value,
          id: el.key,
        };
        equipments.push(data);
      }
    );
  }
  return { last: true, content: equipments };
};

export const getReloadScores = (): ILoadboardSelectOption[] =>
  Array.from(Array(5).keys()).map((newValue) => ({
    id: newValue + 1,
    name: `${newValue + 1}`,
  }));

export const getCustomerPreferenceOptions = (): ILoadboardSelectOption[] => [
  { name: 'Show Preferred', id: 'PREFERRED' },
  { name: 'Remove Blacklisted', id: 'PROHIBITED' },
  { name: 'Show All', id: 'NONE' },
];

export const getLoadTypeOptions = (): ILoadboardSelectOption[] => [
  { name: 'Full Loads', id: 'F' },
  { name: 'Partial Loads', id: 'P' },
];

export const getYesNoOptions = (): ILoadboardSelectOption[] => [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

export const getYesOption = (): ILoadboardSelectOption[] => [
  { id: true, name: 'Yes' },
];
