/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Switch from '../../../common/Ui/Switcher';
import ThreeDotMenuIcon from '../../../ui-kit/components/ThreeDotMenuIcon';

import Accordion from '../../../common/Accordion';
import {
  formatPhoneNumber,
  getAccountTypeWithRoleCode,
  isHasPermission,
} from '../../../utils';
import { contactDeleteAction } from '../../shared/constants';
import { ContactSummary } from '../models/customer';
import ContactForm from './ContactForm';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../common/Permission';

export default function ContactListForm({ id }: { id: number }) {
  const [expanded, setExpanded] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { control, watch, setValue } = useFormContext();
  const [contactName, phoneData, email, isPrimary, contacts] = watch([
    `contacts.${id}.fullName`,
    `contacts.${id}.phoneData`,
    `contacts.${id}.email`,
    `contacts.${id}.isPrimary`,
    'contacts',
  ]);

  useEffect(() => {
    if (isPrimary) {
      const newContacts = contacts.map(
        (contact: ContactSummary, index: number) => {
          if (id !== index) {
            return {
              ...contact,
              isPrimary: false,
            };
          }
          return { ...contact, isPrimary: true };
        }
      );
      setValue('contacts', newContacts);
    }
  }, [isPrimary]);

  const handleSwitchChange = (): void => {
    contacts.forEach((contact: ContactSummary, index: number) => {
      setValue(`contacts.${index}.isPrimary`, id === index);
    });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    action: { icon: string; label: string },
    accountData: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (action?.label !== 'Delete') return;
    handleDeleteContact();
    setAnchorEl(null);
  };

  const handleDeleteContact = () => {
    const newContacts: ContactSummary[] = [];
    contacts.forEach((contact: ContactSummary, index: number) => {
      if (index !== id) {
        newContacts.push(contact);
      }
    });
    setValue('contacts', newContacts, { shouldDirty: true });
  };

  const noPermissionForEditCustomer = !isHasPermission([
    AXELE_PERMISSION_TYPE.CUSTOMER_EDIT,
  ]);

  const titleRenderer = () => {
    return (
      <Box
        sx={{
          pl: 2,
          pr: 2,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: (theme) => {
            return expanded
              ? `${theme.palette.primary.contrastText}`
              : `${theme.palette.grey['100']}`;
            // @ISSUE: Vasil
            // Since on 'uncategorized' it throws error grey is used instead
            // `${theme.palette.uncategorized.accordion}`;
          },
        }}
      >
        <div>
          <Typography
            variant="h7"
            sx={{
              // @ISSUE: Vasil styles are not applied properly (neither variant nor sx)

              color: expanded ? 'primary.main' : 'text.primary',
            }}
          >
            {contactName}
          </Typography>
          {!expanded && (
            <div>
              <Typography
                // @ISSUE: Vasil
                variant="body3"
                sx={{ color: 'text.secondary' }}
              >{`${formatPhoneNumber(phoneData)} ${
                (!!phoneData?.phone && !!email && '|') || ''
              } ${email || ''}`}</Typography>
            </div>
          )}
        </div>
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '-16px',
          }}
        >
          <Switch
            label="Primary"
            name={`contacts.${id}.isPrimary`}
            disabled={isPrimary || noPermissionForEditCustomer}
            control={control}
            onChangeCallback={handleSwitchChange}
            style={{ marginBottom: 0, marginRight: '-15px' }}
          />
          {!isPrimary && !noPermissionForEditCustomer && (
            <ThreeDotMenuIcon
              menuOptions={[{ id, ...contactDeleteAction }]}
              open={Boolean(anchorEl)}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              anchorEl={anchorEl}
              handleMenuItemClick={handleMenuItemClick}
            />
          )}
        </div>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        border: (theme) => {
          return expanded ? `1px solid ${theme.palette.primary.main}` : '';
        },
        borderRadius: '8px',
        padding: '5px 5px',
        marginTop: '10px',
        backgroundColor: (theme) => {
          return expanded
            ? `${theme.palette.primary.contrast}`
            : `${theme.palette.grey['100']}`;
          // Since on 'uncategorized' it throws error grey is used instead
          // `${theme.palette.uncategorized.accordion}`;
        },
        width: '100%',
      }}
    >
      <Accordion
        name="Registration"
        summaryRenderer={titleRenderer}
        updateParentState={setExpanded}
      >
        <ContactForm id={id} />
      </Accordion>
    </Box>
  );
}
