import { InputLabel, Select as MuiSelect } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { Controller } from 'react-hook-form';
import { makeAsteriskRed } from '../../../ui-kit/components/Utils';
import { fontFamily } from '../../../views/settings/import/constants';

interface IProps {
  id?: string;
  control?: any;
  name: string;
  label: string;
  fieldName?: string;
  options: any;
  disabled?: boolean;
  sizes?: {
    xs: number;
  };
  style?: {};
  defaultValue?: string;
  fieldValue?: string;
  error?: { type: string; message: string };
  onChangeCallback?: any;
  rules?: any;
  disableDefaultValidation?: boolean;
  groupInfo?: { [key: string]: any[] };
  shouldUnregister?: boolean;
  required?: boolean;
}

const Select: React.FC<IProps> = ({
  id = '',
  control,
  name,
  label,
  options,
  fieldName,
  sizes = { xs: 12 },
  style = { mb: 2 },
  defaultValue,
  disabled = false,
  fieldValue,
  onChangeCallback,
  rules = {},
  disableDefaultValidation = false,
  groupInfo = null,
  shouldUnregister = false,
  required,
}) => {
  const renderOptions = () => {
    if (!groupInfo) {
      return options.map((option: any, i: number) => (
        <MenuItem key={i} value={fieldValue ? option[fieldValue] : option}>
          {fieldName ? option[fieldName] : option}
        </MenuItem>
      ));
    }
    // collect grouped data. make array from categories and subcategories
    const menuItems: any = [];
    let key = 1;
    /* @TODO davits after implementing color palette pass MenuItem add ability pass styles from outside */
    Object.keys(groupInfo).forEach((groupName) => {
      menuItems.push(renderGroupName(groupName));
      groupInfo[groupName].forEach((option) => {
        menuItems.push(
          <MenuItem
            sx={{
              fontFamily,
              fontWeight: 400,
              color: 'rgba(0, 17, 34, 0.75)',
              fontSize: 16,
              ml: 2,
              mr: 2,
            }}
            key={key}
            value={fieldValue ? option[fieldValue] : option}
          >
            {fieldName ? option[fieldName] : option}
          </MenuItem>
        );
        key++;
      });
    });
    return menuItems;
  };

  /* @TODO davits after implementing color palette pass MenuItem add ability pass styles from outside */
  const renderGroupName = (name: string) => (
    <ListSubheader
      sx={{
        fontFamily,
        fontSize: 14,
        fontWeight: 700,
        color: 'rgba(0, 17, 34, 0.6)',
      }}
    >
      {name}
    </ListSubheader>
  );

  return (
    <Grid xs={sizes.xs} item sx={{ ...style }}>
      <Controller
        shouldUnregister={shouldUnregister}
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <span style={{ width: '100%' }}>
            <FormControl fullWidth error={!!error && !disableDefaultValidation}>
              <InputLabel
                sx={makeAsteriskRed}
                required={required}
                variant="standard"
              >
                {label}
              </InputLabel>
              <MuiSelect
                size="small"
                id={id}
                style={{ width: '100%' }}
                labelId="key"
                //id="demo-simple-select"   Removing this since Dynamic id is passed now
                variant={'standard'}
                onChange={(e) => {
                  onChangeCallback && onChangeCallback(e.target.value, name);
                  onChange(e);
                }}
                disabled={disabled}
                {...props}
              >
                {renderOptions()}
              </MuiSelect>
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          </span>
        )}
        rules={rules}
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
      />
    </Grid>
  );
};

export default Select;
