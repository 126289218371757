import { Type } from 'class-transformer';
import { QueryParams } from '../commonMixed/QueryParams';
import TerminalController from '../../../views/settings/terminals/TerminalController';
import { SortResponse } from '../commonMixed';

export class providerListDTO {
  headerName?: string;
  providerId!: number;
  providerName?: string;
  logo?: object;
  logoWhite?: object;
  status?: string;
  description?: string;
  gradient?: string;
}

export class AccountingDTO {
  integrationsDTO?: [never];
  @Type(() => ProvidersStatusMapDTO)
  providersStatusMap?: [ProvidersStatusMapDTO];
  @Type(() => ProviderFeaturesDTO)
  providerFeatures?: [ProviderFeaturesDTO];
  @Type(() => IntegrationRequiredCredentialsDTO)
  integrationRequiredCredentials?: [IntegrationRequiredCredentialsDTO];
}

export class ProvidersStatusMapDTO {
  providerId?: number;
  providerName?: string | null;
  status?: string;
}

export class ProviderFeaturesDTO {
  id?: string;
  providerId?: number;
  supportedFeatures?: [string];
}

export class IntegrationRequiredCredentialsDTO {
  id?: number;
  integrationType?: string;
  provider?: string;
  authType?: string | null;
  @Type(() => FieldsDTO)
  fields?: [FieldsDTO];
  auth_field?: string | null;
}

export class FieldsDTO {
  name?: string;
  type?: string;
  displayText?: string;
}

export class ActivateProviderDTO {
  displayName?: string;
  terminalIds?: [string];
  organizationId?: number;
  redirectURI?: string;
  code?: string;
  realmId?: string;
}

export class UpdateQBAuthDTO {
  organizationId?: number;
  id?: string;
  operation?: string;
  redirectURI?: string;
  code?: string;
  realmId?: string;
}

export class IncomingRequestPayloadDTO {
  id?: string;
  integrationType?: string;
  providerId?: number;
  isValid?: boolean;
  status?: string;
  organizationId?: number;
  data?: [
    {
      name: string;
      value: string;
    }
  ];
  terminalIds?: [string];
}

export class CustomerFeaturesDTO {
  id?: string;
  providerId!: string;
  supportedFeatures?: Array<string>;
}

export class CardDataDTO {
  archive?: boolean;
  @Type(() => DataDTO)
  data?: [DataDTO];
  id?: string;
  integrationType?: string | null;
  isValid?: false;
  lastUsedDate?: string;
  lastValidationDate?: string;
  organizationId?: number;
  providerId?: number;
  status?: string | null;
  terminalIds?: Array<string>;
  organisationId?: number;
}

export class DataDTO {
  name?: string;
  value?: string;
}

export class SelectedCustomerAccountingDTO {
  currentCustomerData?: providerListDTO;
  providerData?: providerListDTO;
  getProviderDetails?: any;
  allTerminals?: TerminalController;
}

export class GetQBCustomersDTO extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  sort!: string;
  synchFilter!: boolean;
  axeleCustomerIds!: [string];
  qbAccountId: string;
  constructor({
    pageNumber,
    pageSize,
    sort,
    synchFilter,
    axeleCustomerIds = [],
    qbAccountId,
  }: any) {
    super();
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.sort = sort;
    this.synchFilter = synchFilter;
    this.axeleCustomerIds = axeleCustomerIds;
    this.qbAccountId = qbAccountId;
  }
}

export class GetPayItemsRequest extends QueryParams {
  isInvoiceItem!: boolean;
}

export class AccountingPopDTO {
  AccountCardId!: string;
  entityType!: string;
  terminalIds?: string[];
}

export class AxeleMapPayItemsCategory {
  createDate?: string | null;
  createdBy?: string | null;
  custom?: boolean;
  deduction?: boolean;
  description?: string;
  iconName?: string | null;
  id?: string;
  itemCode?: string;
  itemName?: string;
  organizationId?: number;
}

export class GetMapIncomeItemsDTO extends QueryParams {
  qbAccountId: string;
  constructor({ qbAccountId }: { qbAccountId: string }) {
    super();
    this.qbAccountId = qbAccountId;
  }
}

export class AccoutingDropDownDTO {
  label?: string;
}

export class SaveIncomeCategoryDTO {
  axeleIncomeCategoryId?: string;
  qbIncomeCategory?: string;
  qbAccountId?: string;
  organizationId?: number;
}

export class GetMapPayItemsDTO {
  @Type(() => ConnectionDTO)
  connectionDTO?: ConnectionDTO;
  incomeCategories?: any;
  organizationId?: number;
}

export class ConnectionDTO {
  connected!: boolean;
  id?: string;
  integrationStatus?: string;
  lastConnectionDate?: string;
  lastIntegrationDate?: string;
}

export class GetAxeleMapPayItemsDTO {
  createDate?: string | null;
  createdBy?: string | null;
  custom?: boolean;
  deduction?: boolean;
  description?: string;
  iconName?: string | null;
  id?: string;
  itemCode?: string;
  itemName?: string;
  organizationId?: number;
}

export class GetMapIncomeItemsResDTO {
  content!: string[];
  first?: boolean;
  last?: boolean;
  order?: string;
  number?: number;
  numberOfElements?: number;
  size?: number;
  totalElements?: number;
  totalPages?: number;
}

export class GetQBOCustomersDTO {
  @Type(() => ConnectionDTO)
  connectionDTO?: ConnectionDTO;
  @Type(() => GetQBOCustomersContentDTO)
  content!: GetQBOCustomersContentDTO[];
  first?: boolean;
  last?: boolean;
  order?: string[];
  number?: number;
  numberOfElements?: number;
  size?: number;
  totalElements?: number;
  totalPages?: number;
}

export class GetQBOCustomersContentDTO {
  customerId?: number;
  id?: string;
  name?: string;
  organizationId?: number;
  qbAccountId?: string;
  synch?: boolean;
}

export class SendCustomersToQBDTO {
  ids?: string[];
  qbAccountId?: string;
  organizationId?: number;
}

export class SendCustomersToQBResDTO {
  @Type(() => ConnectionDTO)
  connectionDTO?: ConnectionDTO;
  @Type(() => GetQBOCustomersContentDTO)
  content?: GetQBOCustomersContentDTO[];
  nonSyncedIds?: string[];
}

export class GetCustomerName extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  customerName!: string;
  qbAccountId!: string;
}

export class CustomerNameDTO {
  customerId!: number;
  id!: string;
  name!: string;
  organizationd!: number;
  synch!: boolean;
}

export class GetCustomerNameRes {
  @Type(() => CustomerNameDTO)
  content!: CustomerNameDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class GetQuickbookInvoicesRequest extends QueryParams {
  synchStatus!: string | null;
  invoicedOnFromDate!: string | null;
  invoicedOnToDate!: string | null;
  loadStatusList!: string[] | null;
  qbAccountId!: string;
  pageNumber!: number;
  pageSize!: number;
  terminalIds!: string[];
  sort!: string | null;
}

export class SuppressInvoicesRequest extends QueryParams {
  ids!: string[];
  suppress!: boolean;
  qbAccountId!: string;
}

export class SyncBackQBInvoicesRequest extends QueryParams {
  ids!: string[];
  qbAccountId!: string;
}

export class SyncQBInvoicesRequest extends QueryParams {
  ids!: string[];
  qbAccountId!: string;
}

export type QBInvoice = {
  id: string;
  organizationId: number;
  loadId: string;
  seqNumber: string;
  amountDue: number;
  total: number;
  customer: {
    customerId: number;
    organizationd: number;
    name: string;
    synch: boolean;
  };
  synchStatus: string;
  loadStatus: string;
  mappingEntityName: string;
};

export type QBConnectionDTOType = {
  id: string;
  integrationStatus: string;
  lastIntegrationDate: string;
  lastConnectionDate: string;
  connected: boolean;
};

export type QBInvoiceResult = {
  allTypesMapped: boolean;
  content: QBInvoice[];
  first: boolean;
  last: boolean;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  connectionDTO: QBConnectionDTOType;
};

export type QBErrorType = {
  id: string;
  seqNumber: string;
  message: string;
};

export type QBActionResponseType = {
  organizationId: number;
  invoices: QBInvoice[];
  connectionDTO: QBConnectionDTOType;
  errors: QBErrorType[];
};

export class CredentialValidDTO {
  isDisplayNameUnique?: boolean;
  isTerminalIdsInUse?: boolean;
  terminalsInUse?: string[];
}

export class CredentialValidRequestDTO extends QueryParams {
  displayName?: string;
  terminalIds?: string[];
  organizationId!: number;
}

export class GetIsClassMappingActiveRequest extends QueryParams {
  qbAccountId?: string;
}

export class GetClassMappingListRequest extends QueryParams {
  qbAccountId?: string;
}

export class ChangeClassMappingTypeRequest extends QueryParams {
  qbAccountId?: string;
  mappingEntityType?: string;
}

export class RefreshClassMappingTypeRequest extends QueryParams {
  qbAccountId?: string;
}

export class ChangeMappingRequest extends QueryParams {
  qbAccountId?: string;
  mappingEntityType?: string;
  mappingEntityId?: string;
  qbClassId?: string;
  qbClass?: string;
}

export class GetClassMappingDropdownRequest extends QueryParams {
  qbAccountId?: string;
}

export type EntityMappingType = {
  id: string;
  name: string;
};

export type ClassMappingListResponseType = {
  organizationId: number;
  connectionDTO: {
    id: string;
    integrationStatus: string;
    lastIntegrationDate: string | null;
    lastConnectionDate: string | null;
    connected: boolean;
  };
  mappingEntityType: string;
  tractorClassCategoriesMapping: Record<string, EntityMappingType>;
  driverClassCategoriesMapping: Record<string, EntityMappingType>;
};

export type ClassMappingDropdownItemType = {
  id: string;
  name: string;
};

export type ClassMappingTableType = {
  id: string;
  name: string;
  mappingName: ClassMappingDropdownItemType;
};
