import { Box, Button, Stack } from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import React from 'react';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Banner from '../../../_assets/images/Banner.svg';

export default function ProviderHero({ currentCustomerData }: IProps) {
  let activationStatusLabel = '',
    activationColor = '',
    activationStatusIcon = {};
  switch (currentCustomerData.status) {
    case 'VALID':
      activationStatusLabel = 'Activated';
      activationStatusIcon = <DoneOutlinedIcon />;
      activationColor = 'success';
      break;
    case 'WARNING':
    case 'INVALID':
      activationStatusLabel = 'Failed Activation';
      activationStatusIcon = <WarningAmberOutlinedIcon />;
      activationColor = 'error';
      break;
    case 'PENDING':
      activationStatusLabel = 'Pending Activation';
      activationStatusIcon = <AccessTimeOutlinedIcon />;
      activationColor = 'primary';
      break;
    default:
      activationStatusLabel = 'Not Activated';
      activationStatusIcon = '';
      activationColor = 'primary';
  }

  return (
    <>
      <Stack
        className="provider-hero"
        sx={{
          height: '140px',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '140px',
            backgroundImage: `${currentCustomerData.gradient}, url(${Banner})`,
          }}
        ></Box>
        <Box
          className="customer-logo"
          sx={{ position: 'absolute', pl: '40px' }}
        >
          {currentCustomerData && currentCustomerData.logoWhite}
        </Box>
      </Stack>
      <Button
        className="activation-status"
        variant="contained"
        size="small"
        color={activationColor}
        sx={{
          borderRadius: '10px',
          mt: '-30px',
          ml: '32px',
          fontSize: '14px',
          lineHeight: '24px',
          p: '6px 16px',
          textTransform: 'capitalize',
          cursor: 'auto',
          pointerEvents: 'none',
          color: 'success.contrast',
        }}
      >
        <Stack sx={{ marginRight: '7px' }}>{activationStatusIcon}</Stack>
        {activationStatusLabel}
      </Button>
    </>
  );
}

export interface IProps {
  currentCustomerData: any;
}
