import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import TractorController from '../../../../../../subPages/tractors/Controller';
import { SingleAutocompleteForm } from '../../../../../Ui/SingleAutocomplete';
import { Trip } from '../../Models';
import RoutesController from '../../RoutesController';
import { useTranslation } from 'react-i18next';

export default function FormComponent({
  trip,
  onTractorSelect,
  onTractorInfoReady,
}: {
  trip: Trip;
  onTractorSelect: ([]) => void;
  onTractorInfoReady: ({}) => void;
}) {
  const { control, setValue } = useFormContext();

  const onTractorChange = async (data) => {
    onTractorSelect(data);
    const res = await RoutesController.getTractorInfoForLoadAssign(
      data.id,
      trip.id
    );
    onTractorInfoReady(res);
  };

  const prepareStatusList = (status: string) => {
    if (
      ['AVAILABLE', 'ASSIGNMENT_PLANNED', 'OFFERED_TO_DRIVER'].includes(status)
    ) {
      return ['AVAILABLE', 'ASSIGNED'];
    }

    if (['DISPATCHED', 'IN_TRANSIT'].includes(status)) {
      return ['AVAILABLE'];
    }

    if (status === 'LOAD_COMPLETED') {
      return ['AVAILABLE', 'ASSIGNED', 'UNAVAILABLE'];
    }

    return [];
  };

  useEffect(() => {
    setTimeout(() => setValue('tractor', trip.tractor));
  }, [trip]);
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <>
        <SingleAutocompleteForm
          control={control}
          disableClear
          name="tractor"
          fieldName="name"
          label={t('TractorAssignmentFormTractor')}
          onChangeCb={(data) => onTractorChange(data)}
          getOptions={(searchText: string | undefined, pageNumber: number) =>
            TractorController.instance().getTractorByNameExtendedSearch(
              searchText,
              pageNumber,
              prepareStatusList(trip.tripStatus),
              trip.terminal?.id ? [trip.terminal?.id] : undefined
            )
          }
        />
      </>
    );
  }
}
