import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid/Grid';
import { docType } from '../../../../utils/Doc';
import withStyles from '@mui/styles/withStyles';
import FileViewer from '../../../../views/reports/documents/components/FileViewer';
import Typography from '@mui/material/Typography';
import { formatBytes } from '../../../../utils';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import { DeleteOutline } from '@mui/icons-material';

const ViewButton = withStyles({
  root: {
    color: '#fff',
    '&:hover': {
      border: '1px solid #FFFFFF',
    },
  },
})(Button);

export const FileApproved = ({
  rootProps,
  isIMGFormat,
  file,
  onPreview,
  onRemove,
  removable,
}) => {
  return (
    <Grid
      container
      alignItems="center"
      padding={isIMGFormat ? '10px' : '12px 10px'}
      sx={(theme) => ({
        borderRadius: '5px',
        background: `${theme.palette.primary.main}`,
      })}
      wrap="nowrap"
    >
      <Grid
        item
        xs={2}
        sx={isIMGFormat ? { mr: 3, height: '100%' } : { mr: 3 }}
      >
        {isIMGFormat ? (
          <ViewButton
            size="large"
            onClick={onPreview}
            sx={{ height: '100%', padding: '0px' }}
          >
            <FileViewer file={file} />
          </ViewButton>
        ) : (
          <ViewButton
            size="medium"
            onClick={onPreview}
            sx={(theme) => ({
              padding: '8px 12px',
              borderRadius: '16px',
              background: `${theme.palette.primary.dark}`,
            })}
          >
            VIEW
          </ViewButton>
        )}
      </Grid>
      <Grid item xs={8.5} spacing={1} zeroMinWidth {...rootProps}>
        <Typography
          fontFamily="Poppins"
          fontStyle="normal"
          fontWeight="600"
          fontSize="16px"
          color="primary.contrastText"
          noWrap
        >
          {file.fileName}
        </Typography>
        <Typography
          fontFamily="Poppins"
          fontStyle="normal"
          fontWeight="400"
          fontSize="12px"
          color="primary.contrastText"
        >
          {formatBytes(file?.size || file?.fileSize)},{' '}
          {moment(file?.lastModified).format('l')}
        </Typography>
      </Grid>
      {removable && (
        <Grid item xs={1}>
          <IconButton onClick={onRemove}>
            <DeleteOutline sx={{ color: 'primary.contrastText' }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
