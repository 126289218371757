import { Box, Stack, Typography } from '@mui/material';

export const CarrierPaymentNoData = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: 2,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'uncategorized.oldHeaderSecondary',
          borderRadius: '16px',
          width: '100%',
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          width={'100%'}
          p={3}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            width={'100%'}
          >
            <Typography variant="body2Bold" sx={{ color: 'text.primary' }}>
              Total Payment Amount
            </Typography>
            <Typography variant="body2Bold" sx={{ color: 'text.primary' }}>
              --
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            width={'100%'}
          >
            <Typography variant="kpiValue" sx={{ color: 'primary.main' }}>
              Payment Due
            </Typography>
            <Typography variant="h7" sx={{ color: 'primary.main' }}>
              --
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
