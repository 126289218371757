import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { body2Color, fontFamily, typographyStyles } from '../../constants';

const FileInfoItem = ({
  text,
  subText,
  icon,
}: {
  text: string;
  subText: string;
  icon: ReactElement;
}) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box sx={{ mr: 3 }}>{icon}</Box>
        <Box>
          <Typography variant={'h6'} sx={{ ...typographyStyles, fontSize: 20 }}>
            {text}
          </Typography>
          <Typography
            variant={'body2'}
            sx={{ fontFamily, fontSize: 14, color: body2Color, mt: 0.5 }}
          >
            {subText}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default FileInfoItem;
