import { Divider } from '@components/ui-kit/divider';
import { MenuItem } from '@components/ui-kit/menu-item';
import { Select } from '@components/ui-kit/select';
import { TextField } from '@components/ui-kit/text-field';
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  ExpandLessRounded,
  ExpandMoreRounded,
  MoreVert,
  Refresh,
  RemoveCircleOutline,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { ContractDetailsDTO } from '../../../../../models';
import { LineItem } from '../../../../../types';
import { urls } from '../../apis';
import {
  CONTRACT_TYPES,
  FINANCE_TYPES,
  PER_LOADED_MILE_KEY,
  PER_LOADED_WEIGHT_KEY,
  ZIP_BASED,
} from '../../constants/fieldOptions';
import { SECTION_LAST_ELEMENT_ID_LIST } from '../../constants/fieldValues';
import { QUERY_KEYS } from '../../constants/queryKeys';
import { initialState } from '../../data.config';
import { fetchDropdownData } from '../../services';
import { createLoadService } from '../../services/createLoad.service';
import { rateFormService } from '../../services/rate.service';
import {
  AdditionalSummaryContainerStyles,
  ContractBaseItemTextStyle,
  ZipBasedLineItemInput,
} from '../../style';
import { formatTextToUpperCase, toFixDigit } from '../../utils';
import { ErrorText } from '../ErrorText';
import AccordionTotalsSummary from '../ui/accordion-totals-summary';
import FeeItem from './fee-item';
interface BestMatchContractModal {
  contractId: string;
  contractLaneRateItemId: string;
}

const RateDetails: React.FC<{
  canEditInvoice?: boolean;
  hideNoteSection?: boolean;
}> = ({ canEditInvoice, hideNoteSection = false }) => {
  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'rate.additionalFees',
  });
  const [selectedContract, setSelectedContract] =
    useState<ContractDetailsDTO | null>();
  const [contractNameInput, setContractNameInput] = useState('');
  const additionalFees = watch(`rate.additionalFees`);
  const invoiceRate = watch(`rate.rate`);
  const quantity = watch(`rate.quantity`);
  const feeDetail = watch(`rate.feeDetail`);
  const rateContract = watch(`rate.contract`);
  const rateDescription = watch('rate.description');
  const [feeFullResult, setFeeFullResult] = useState<LineItem[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isContractLoading, setIsContractLoading] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const values = getValues();
  const totalMiles = watch('totalMiles');
  const isManualContractSelection = watch('rate.isManualContractSelection');
  const isBaseLineItemWithoutContract = watch(
    'rate.isBaseLineItemWithoutContract'
  );
  const loadHiddenFields = watch('hiddenFields.rate');
  const loadTotalWeight = getValues('load.weight');
  const updateDefaultContractDetailsRef = useRef(false);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const toggleIsDetailsExpanded = () => {
    setIsDetailsExpanded((state) => (state = !state));
  };
  const queryClient = useQueryClient();
  const lineItemsList = queryClient.getQueryData<{
    base: LineItem[];
    fees: LineItem[];
  }>(QUERY_KEYS.lineItems);
  const lineItemOptions = lineItemsList?.base ?? [];
  const feeItemOptions = lineItemsList?.fees ?? [];
  const [loading, setLoading] = useState<boolean>(false);
  const [bestMatchContract, setBestMatchContract] =
    useState<BestMatchContractModal | null>();

  useEffect(() => {
    let totalAccessorialFees = 0;
    additionalFees?.forEach?.((fee: any) => {
      totalAccessorialFees += fee.total ? parseFloat(fee.total) : 0;
    });
    const totalRate =
      totalAccessorialFees +
      (quantity && invoiceRate
        ? parseFloat(String(Number(quantity) * Number(invoiceRate)))
        : 0);
    setValue(`rate.totalRate`, totalRate.toFixed(2));
  }, [JSON.stringify(additionalFees), quantity, invoiceRate, setValue]);

  const addFeeItem = (value?: {
    rateTypeId: string;
    quantity: number;
    rate: number;
  }) => {
    append({
      fee: value?.rateTypeId ? value?.rateTypeId : null,
      quantity: value?.quantity ? value?.quantity : null,
      rate: value?.rate ? value?.rate : null,
      unit: '',
      units: [],
      feeDetail: null,
    });
  };

  const removeFeeItem = (index: number) => {
    // if (fields.length < 3) return;
    remove(index);
  };

  // get list of contract when when page load.
  const contractData = useQuery<any>(
    ['customer'],
    () =>
      fetchDropdownData({
        endpoint: urls.getContractsByName,
        query: { contractName: contractNameInput ?? '' },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getContractList = useMemo(
    () =>
      rateFormService.getContactDropdownOptions({
        customers: contractData?.data?.content as any,
        searchText: '',
      }),
    [contractData?.data?.content, contractNameInput]
  );

  useEffect(() => {
    if (!feeItemOptions?.length || !contractData?.data?.content?.length) return;
    if (rateContract?.id) {
      if (!rateContract?.name) {
        const selectedtItem: any = contractData?.data?.content?.find?.(
          (item: any) => item?.id === rateContract?.id
        );
        if (selectedtItem) {
          setValue('rate.contract', selectedtItem);
          setValue('rate.isContractEnabled', true);
        }
      }
      getContractDetails(rateContract?.id, {
        isEdit: bestMatchContract ? true : false,
        updateDefaultContractDetails: updateDefaultContractDetailsRef?.current,
      });
    }
  }, [
    rateContract?.id,
    bestMatchContract,
    JSON.stringify(contractData?.data?.content),
  ]);

  const getContractDetails = async (
    id: string,
    {
      isEdit = false,
      updateDefaultContractDetails = true,
    }: { updateDefaultContractDetails?: boolean; isEdit?: boolean }
  ) => {
    try {
      setIsContractLoading(true);
      const response = await rateFormService.getContractDetails(id);
      if (response) {
        setSelectedContract(response!);
        updateRates(response, { isEdit, updateDefaultContractDetails });
      }
      setIsContractLoading(false);
    } catch (error) {
      setIsContractLoading(false);
    }
  };

  const bestMatchingLane = async () => {
    setValue('rate.isContractEnabled', true);

    const pickUp = values?.stops?.[0];
    const dropOff = values?.stops?.[values?.stops?.length - 1];
    // payLoadTo get Best match
    const payload = {
      organizationId: StorageManager.getUser().organizationId,
      customerId: values?.customer?.customerId,
      equipmentTypeList: values?.load?.equipment,
      weight: values?.load?.weight,
      mode: values?.load?.loadType,
      pickupCity: pickUp?.location?.city,
      dropoffCity: dropOff?.location?.city,
      pickupState: pickUp?.location?.state,
      dropoffState: dropOff?.location?.state,
      pickupZip: pickUp?.location?.zipcode,
      dropoffZip: dropOff?.location?.zipcode,
      totalMiles: parseFloat(values?.totalMiles),
    };

    try {
      const response: any = await rateFormService.getMatchContract(
        payload as any
      );
      if ((response as BestMatchContractModal) && response?.contractId) {
        updateDefaultContractDetailsRef.current = true;
        setBestMatchContract(response as BestMatchContractModal);
        if (rateContract?.id === response?.contractId)
          return getContractDetails(rateContract?.id, {});
        setValue(`rate.contract`, {
          id: response?.contractId,
        });
      }
    } catch (err) {
      console.log('Something went wrong...', err);
    }
  };
  const resetSelectedContractDefaultValues = async () => {
    getContractDetails(rateContract?.id, {
      updateDefaultContractDetails: true,
    });
  };
  const filterItemByItemCode = (list: any[], itemId: string) => {
    const additionalStopObject = list.find((item) => item.id === itemId);
    if (additionalStopObject) {
      return additionalStopObject;
    }
    return false;
  };

  const updateRates = async (
    contractRates: any,
    {
      isEdit,
      updateDefaultContractDetails,
    }: { isEdit?: boolean; updateDefaultContractDetails?: boolean }
  ) => {
    const feeLineItems: any = [];
    const nonAutomateFeeLineItems: any = [];
    let lineItems: LineItem[] = [];
    let baseRateType: any = null;
    let itemValue: any = null;
    if (contractRates.laneRateItems?.length) {
      contractRates.laneRateItems.map((item: any) => {
        let laneQuantity = 1;

        if (item.rateType == PER_LOADED_MILE_KEY) {
          laneQuantity = totalMiles;
        }
        const baseItem = lineItemOptions?.find(
          (e: any) => e?.itemCode === item?.rateType
        );
        lineItems.push({
          id: item.id,
          itemCode: item.rateType,
          itemId: baseItem?.id,
          itemName: formatTextToUpperCase(item.rateType),
          rateMethod: contractRates?.rateMethod
            ? contractRates?.rateMethod
            : null,
          minMiles: item?.minMiles ? item?.minMiles : null,
          maxMiles: item?.maxMiles ? item?.maxMiles : null,
          description: item.description,
          rate: item.rate,
          destination: item.destination,
          origin: item.origin,
          weight: item.weight,
          quantity: item.quantity ? item.quantity : laneQuantity,
          isContract: true,
          totalMiles: item?.totalMiles || null,
          contractId: rateContract?.id,
          contractRateId: item?.id,
        } as LineItem);
      });
      // Default select first
      if (updateDefaultContractDetails) {
        if (bestMatchContract?.contractLaneRateItemId) {
          const matchLaneItem = lineItems.find(
            (d) => d.id === bestMatchContract.contractLaneRateItemId
          );
          if (matchLaneItem) {
            baseRateType = bestMatchContract.contractLaneRateItemId;
            itemValue = matchLaneItem;
          } else {
            // Handle the case when matchLaneItem is not found
            console.error('Match lane item not found');
            // settings default as first lane item
            if (lineItems.length > 0) {
              baseRateType = lineItems[0]?.id;
              itemValue = lineItems[0];
            }
          }
        } else {
          // Check if lineItems array is not empty
          if (lineItems.length > 0) {
            baseRateType = lineItems[0]?.id;
            itemValue = lineItems[0];
          }
        }
        handleBaseRateTypeChange(itemValue, false);
        setValue('rate.baseRateType', baseRateType);
      }
    }
    if (rateContract?.id) {
      if (contractRates.accessorialItems?.length) {
        contractRates.accessorialItems.map((item: any) => {
          const itemList = filterItemByItemCode(
            feeItemOptions,
            item.rateTypeId
          );
          const quantity = rateFormService.calculateQuantityByItemUnit({
            unit: item?.feeDetail?.unit ?? item?.unit,
            baseQuantity: rateFormService.getBaseQuantityByType({
              type: itemValue?.itemCode,
              totalMiles,
              totalWeight: loadTotalWeight ?? 1,
              quantity: item?.quantity ?? 1,
            }),
            baseRate: itemValue?.rate,
            totalMiles,
          });
          const feeItem = {
            ...itemList,
            fee: item?.rateTypeId,
            id: item?.rateTypeId,
            quantity,
            rate: item?.rate,
            type: item?.rateType,
            itemName: itemList?.itemName ? itemList?.itemName : item.rateType,
            isContract: true,
            unit: item?.unit,
            units: itemList?.units ?? itemList?.unit,
            contractId: rateContract?.id,
            contractRateId: item?.id,
            feeDetail: {
              ...item,
              quantity: item?.quantity ?? 1,
              rate: item?.rate,
              id: item?.rateTypeId,
              description: item?.description,
              unit: item?.unit,
              itemCode: item?.rateType,
              itemName: itemList?.itemName ? itemList?.itemName : item.rateType,
              contractId: rateContract?.id,
              contractRateId: item?.id,
            },
          };
          const isFeeItemExist = feeItemOptions?.find(
            (e) => e?.feeDetail?.id === feeItem?.id
          );
          if (item?.autoPopulate && updateDefaultContractDetails) {
            feeLineItems.push(feeItem);
          }
          if (!isFeeItemExist) nonAutomateFeeLineItems.push(feeItem);
        });
      }
      if (updateDefaultContractDetails) {
        setValue(
          'rate.additionalFees',
          feeLineItems?.length
            ? feeLineItems
            : [
                {
                  fee: '',
                  quantity: null,
                  rate: null,
                  description: '',
                },
              ]
        );
        if (feeLineItems?.length) addFeeItem();
      }
    }
    updateDefaultContractDetailsRef.current = false;
    // if no contract line items present, fetch default base items
    if (!lineItems?.length) {
      lineItems = (await rateFormService.getAccessorialFeeOptions())?.base;
      handleBaseRateTypeChange(lineItems[0], false);
      setValue('rate.baseRateType', lineItems[0]?.id);
    }
    queryClient.setQueryData(QUERY_KEYS.lineItems, {
      base:
        isManualContractSelection ||
        feeDetail?.contractRateId ||
        updateDefaultContractDetails
          ? [...lineItems]
          : lineItemOptions,
      fees: [...nonAutomateFeeLineItems, ...feeItemOptions],
    });
  };
  // TODO Better way
  const removeContract = () => {
    setValue('rate', initialState.rate);
    setValue('rate.additionalFees', [
      {
        fee: '',
        quantity: null,
        rate: null,
        description: '',
      },
    ]);
    setValue('rate.contract', null);
    setBestMatchContract(null);
    setContractNameInput('');
    queryClient.refetchQueries(QUERY_KEYS.lineItems);
  };

  const handleBaseRateTypeChange = (
    item: LineItem | null,
    updateLineItemsFlag = true
  ) => {
    if (item?.id === feeDetail?.id) return;
    let qty = null;
    if (item?.itemCode === PER_LOADED_WEIGHT_KEY) qty = loadTotalWeight;
    else if (item?.itemCode === PER_LOADED_MILE_KEY) qty = totalMiles;
    setValue('rate.feeDetail', item);
    setValue('rate.quantity', qty ?? 1);
    setValue('rate.rate', item?.rate ?? item?.quantity ?? '');
    setValue('rate.description', item?.description);
    if (updateLineItemsFlag)
      updateLineItems({
        newQty: qty ?? 1,
        rate: item?.rate ?? item?.quantity ?? 0,
      });
  };
  const handleOnChangeContract = async (
    _: any,
    value: {
      id: string;
      name: string;
      customerName?: string;
    }
  ) => {
    resetFeeItemOption();
    setValue('rate.contract', value ?? null);
    setValue('rate.contract.id', value?.id ?? null);
    setValue('rate.additionalFees', [
      {
        fee: '',
        quantity: null,
        rate: null,
        description: '',
      },
    ]);
    if (value?.id) {
      updateDefaultContractDetailsRef.current = true;
    } else {
      removeContractLineItem();
    }
  };

  const resetFeeItemOption = () => {
    const feeWithoutContract: LineItem[] = [];
    feeItemOptions?.forEach?.((item) => {
      if (!item?.isContract && !item?.contractId) {
        feeWithoutContract?.push(item);
      }
    });
    queryClient.setQueryData(QUERY_KEYS.lineItems, {
      base: lineItemOptions,
      fees: feeWithoutContract,
    });
  };

  const resetBaseLineItemOptionsToDefault = async (
    resetAccessorialItems = false
  ) => {
    const fees = await rateFormService.getAccessorialFeeOptions();
    queryClient.setQueryData(QUERY_KEYS.lineItems, {
      base: fees?.base,
      fees: resetAccessorialItems ? fees?.fees : feeItemOptions,
    });
    setValue('rate.baseRateType', fees?.base?.[0]?.id);
    setValue('rate.feeDetail', fees?.base?.[0]);
    setValue('rate.description', fees?.base?.[0]?.description);
  };

  const removeContractLineItem = () => {
    removeContract();
    setValue('rate.isContractEnabled', true);
    // queryClient.refetchQueries(QUERY_KEYS.lineItems);
  };

  const removeContractBaseLineItem = () => {
    setValue('rate.feeDetail', null);
    setValue('rate.baseRateType', null);
    setValue('rate.quantity', 1);
    setValue('rate.isBaseLineItemWithoutContract', true);
    // queryClient.refetchQueries(QUERY_KEYS.lineItems);
    resetBaseLineItemOptionsToDefault();
  };

  const isBaseTypePerLoaded = useMemo(() => {
    return (
      feeDetail?.itemCode === PER_LOADED_WEIGHT_KEY ||
      feeDetail?.itemCode === PER_LOADED_MILE_KEY
    );
  }, [feeDetail?.itemCode]);

  useEffect(() => {
    updateLineItems({});
  }, [quantity, invoiceRate]);

  const updateLineItems = ({
    newQty,
    rate,
  }: {
    newQty?: number;
    rate?: number;
  }) => {
    if (!additionalFees?.length) return;
    const updatedFees = additionalFees?.map((e: any) => {
      const qty = !!(
        e?.feeDetail?.unit === FINANCE_TYPES.PERCENT ||
        e?.unit === FINANCE_TYPES.PERCENT
      )
        ? Number(
            Number(rate ?? invoiceRate) * Number(newQty ?? quantity ?? 1)
          ).toFixed(2)
        : e?.quantity;
      return {
        fee: e?.fee ?? '',
        unit: e?.unit ?? '',
        ...e,
        quantity: qty,
        total: rateFormService.calculateLineItemRateByUnit({
          quantity: qty,
          rate: Number(invoiceRate),
          unit: e?.unit,
        }),
      };
    });
    setValue('rate.additionalFees', updatedFees);
  };
  const renderBaseItemValue = useCallback(
    (value: string) => {
      const selectedItem = lineItemOptions?.find?.((e) => e?.id === value);
      if (!selectedItem) return '';
      switch (selectedItem?.rateMethod) {
        case CONTRACT_TYPES.CITY:
          return (
            <Typography sx={ContractBaseItemTextStyle}>
              <b>
                {selectedItem?.origin?.city +
                  ', ' +
                  selectedItem?.origin?.state}
              </b>{' '}
              to{' '}
              <b>
                {selectedItem?.destination?.city +
                  ', ' +
                  selectedItem?.destination?.state}
              </b>{' '}
              ({selectedItem?.itemName}
              {selectedItem?.weight &&
                ` | ${toFixDigit(selectedItem.weight, 2)} lbs`}
              )
            </Typography>
          );
        case CONTRACT_TYPES.ZIP:
          return (
            <Typography sx={{ ...ContractBaseItemTextStyle, display: 'flex' }}>
              <b style={ZipBasedLineItemInput}>
                Zip
                <ArrowUpwardOutlined sx={{ width: '16px' }} />:
              </b>{' '}
              {selectedItem?.origin?.zipStart +
                ' - ' +
                selectedItem?.origin?.zipEnd}
              <span style={{ margin: '0 4px' }}></span>
              <b style={ZipBasedLineItemInput}>
                Zip
                <ArrowDownwardOutlined sx={{ width: '16px' }} />:
              </b>{' '}
              {selectedItem?.destination?.zipStart +
                ' - ' +
                selectedItem?.destination?.zipEnd}{' '}
              ({selectedItem?.itemName}
              {selectedItem?.weight &&
                ` | ${toFixDigit(selectedItem.weight, 2)} lbs`}
              )
            </Typography>
          );

        case CONTRACT_TYPES.MILEAGE:
          return (
            <Typography sx={ContractBaseItemTextStyle}>
              <b>
                {selectedItem?.minMiles !== null ? selectedItem?.minMiles : 0}{' '}
                miles to{' '}
                {selectedItem?.maxMiles !== undefined
                  ? selectedItem?.maxMiles
                  : 200}{' '}
                miles
              </b>{' '}
              {selectedItem?.itemName && <>({selectedItem?.itemName})</>}
            </Typography>
          );

        default:
          return selectedItem?.itemName;
      }
    },
    [lineItemOptions]
  );

  const renderContractBaseItemOptions = useCallback((e: any) => {
    const renderMileageBasedOption = () => {
      return (
        <Typography
          variant="h7"
          sx={{
            width: '250px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {e?.minMiles !== null ? e?.minMiles : 0} miles to{' '}
          {e?.maxMiles !== undefined ? e?.maxMiles : 200} miles
        </Typography>
      );
    };
    const renderDefaultBaseItemOption = () => {
      return (
        <Typography
          variant="h7"
          sx={{
            width: '250px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {e?.origin?.city && e?.destination?.city ? (
            <>
              {e?.origin?.city} {e?.origin?.state} to {e?.destination?.city}
            </>
          ) : (
            <>{e?.itemName || formatTextToUpperCase(e.itemCode)}</>
          )}
        </Typography>
      );
    };
    const renderZipBasedOption = () => {
      return (
        <Typography sx={{ ...ContractBaseItemTextStyle, display: 'flex' }}>
          <b style={ZipBasedLineItemInput}>
            Zip
            <ArrowUpwardOutlined sx={{ width: '16px' }} />:
          </b>{' '}
          {e?.origin?.zipStart + ' - ' + e?.origin?.zipEnd}
          <span style={{ margin: '0 4px' }}></span>
          <b style={ZipBasedLineItemInput}>
            Zip
            <ArrowDownwardOutlined sx={{ width: '16px' }} />:
          </b>{' '}
          {e?.destination?.zipStart + ' - ' + e?.destination?.zipEnd}
        </Typography>
      );
    };
    const renderTemplateByContractType = (type: string) => {
      if (type === CONTRACT_TYPES.MILEAGE) return renderMileageBasedOption();
      else if (type === CONTRACT_TYPES.ZIP) return renderZipBasedOption();
      return renderDefaultBaseItemOption();
    };
    return (
      <Stack
        direction="row"
        gap={1}
        justifyContent="space-between"
        width={'100%'}
      >
        <Stack direction="column">
          <Stack
            direction={'row'}
            gap={'8px'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            {renderTemplateByContractType(e?.rateMethod)}
          </Stack>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            alignContent="right"
          >
            {e?.itemName || formatTextToUpperCase(e.itemCode)}{' '}
            {e.weight ? <> | {e.weight} lbs</> : ''}
          </Typography>
        </Stack>
        <Stack direction="column">
          {e?.rate && (
            <Typography variant="h6" color="textSecondary">
              ${e?.rate}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }, []);

  const getContractOptionLabel = useCallback(
    (value: object) =>
      createLoadService.getAutocompleteOptionLabel({
        list: getContractList,
        value,
        fieldName: 'name',
        returnDefaultValue: false,
      }),
    [getContractList]
  );

  const renderContractOption = useCallback((props, option) => {
    return (
      <Stack
        direction={'row'}
        {...props}
        padding={1}
        sx={{ alignItems: 'flex-start!important' }}
      >
        <Typography variant="body1" fontWeight={500}>
          {option.name}
        </Typography>
        {option?.customerName && (
          <>
            <span style={{ margin: '0 4px' }}>{' | '}</span>
            <Typography variant="body1">{option.customerName}</Typography>
          </>
        )}
      </Stack>
    );
  }, []);
  return (
    <Stack direction="column" gap={2} useFlexGap>
      {values?.rate?.isContractEnabled && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography>Contract</Typography>
          <Controller
            name="rate.contract.id"
            render={({ field }) => (
              <Autocomplete
                sx={{ flexGrow: 1 }}
                disablePortal
                id="createLoad-rate-contract"
                options={getContractList}
                {...field}
                renderOption={(props, option) =>
                  renderContractOption(props, option)
                }
                getOptionLabel={getContractOptionLabel}
                onChange={(_, value: any) => {
                  setValue('rate.isManualContractSelection', true);
                  field.onChange(value?.id ?? null);
                  handleOnChangeContract(_, value);
                }}
                value={rateContract?.id ?? null}
                onInputChange={(_, v) => {
                  setContractNameInput(v);
                }}
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params) => (
                  <TextField {...params} label="Contract" />
                )}
                disabled={canEditInvoice}
              />
            )}
          />

          <IconButton
            disabled={!rateContract?.id || isContractLoading}
            onClick={resetSelectedContractDefaultValues}
          >
            {isContractLoading ? (
              <CircularProgress size={16} />
            ) : (
              <Tooltip title="Reset rate as per contract">
                <Refresh></Refresh>
              </Tooltip>
            )}
          </IconButton>
        </Stack>
      )}
      <Stack direction="row" alignItems="left">
        <Stack direction="column" alignItems="left" sx={{ width: '95%' }}>
          <Divider textAlign="left" isSubheader>
            Base Rate
          </Divider>
        </Stack>
        {!canEditInvoice && (
          <Stack direction="column" alignItems="right" sx={{ mt: '-10px' }}>
            <Tooltip title="More">
              <IconButton
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  setAnchorEl(event.currentTarget)
                }
                size="small"
                sx={{ ml: '10px', flexShrink: 0 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <MoreVert fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={() => setAnchorEl(null)}
              onClick={() => setAnchorEl(null)}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {!values?.rate?.isContractEnabled && (
                <MenuItem onClick={bestMatchingLane}>Add Contract</MenuItem>
              )}
              {values?.rate?.isContractEnabled && (
                <MenuItem onClick={removeContract}>Remove Contract</MenuItem>
              )}
            </Menu>
          </Stack>
        )}
      </Stack>
      <Stack>
        <Typography variant="caption" ml="auto" color={'textSecondary'} mr={5}>
          Total
        </Typography>
        <Stack direction="row" gap={1} justifyContent="space-between">
          {!!feeDetail?.contractRateId && (
            <IconButton onClick={removeContractBaseLineItem}>
              <RemoveCircleOutline />
            </IconButton>
          )}
          <Stack sx={{ flexGrow: 1, height: 'fit-content' }}>
            <Controller
              name="rate.baseRateType"
              render={({ field }) => (
                <Select
                  id="createLoad-base-rate"
                  {...field}
                  sx={{ maxWidth: '300px' }}
                  label={
                    feeDetail?.rateMethod === ZIP_BASED ? `Zip Based` : `Base`
                  }
                  autoFocus
                  renderValue={(value) => (
                    <Tooltip
                      enterDelay={300}
                      title={renderBaseItemValue(value as string)}
                      slotProps={{
                        popper: {
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              maxWidth: 'fit-content!important',
                            },
                          },
                        },
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        {renderBaseItemValue(value as string)}
                      </div>
                    </Tooltip>
                  )}
                  disabled={canEditInvoice}
                >
                  {lineItemOptions?.map((e) => (
                    <MenuItem
                      value={e.id}
                      key={e.id}
                      onClick={() => {
                        handleBaseRateTypeChange(e);
                        field.onChange(e?.id);
                      }}
                    >
                      {e.isContract ? (
                        renderContractBaseItemOptions(e)
                      ) : (
                        <>{e.itemName}</>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <ErrorText text={errors?.rate?.baseRateType?.message} />
          </Stack>
          {isBaseTypePerLoaded ? (
            <>
              <Controller
                name="rate.quantity"
                render={({ field }) => (
                  <TextField
                    id="createLoad-base-rate-quantity"
                    label={rateFormService.getQuantityFieldLabel(
                      feeDetail.itemCode
                    )}
                    {...field}
                    onChange={(event: any) => {
                      if (isNaN(Number(event?.target?.value))) return;
                      field.onChange?.(event?.target?.value);
                    }}
                    error={errors?.rate?.quantity?.message}
                    helperText={errors?.rate?.quantity?.message}
                    onBlur={() => updateLineItems({})}
                    disabled={canEditInvoice}
                  />
                )}
              />
              <Controller
                name="rate.rate"
                render={({ field }) => (
                  <TextField
                    id="createLoad-base-rate-rate"
                    label={rateFormService.getRateFieldLabel(
                      feeDetail?.itemCode
                    )}
                    {...field}
                    onChange={(event: any) => {
                      if (isNaN(Number(event?.target?.value))) return;
                      field.onChange?.(event?.target?.value);
                    }}
                    error={errors?.rate?.rate}
                    disabled={!!feeDetail?.contractRateId || canEditInvoice}
                    helperText={errors?.rate?.rate?.message}
                    onBlur={() => {
                      updateLineItems({});
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </>
          ) : (
            <Controller
              name="rate.rate"
              render={({ field }) => (
                <TextField
                  id="createLoad-base-rate-value"
                  label="Rate"
                  {...field}
                  onBlur={() => {
                    updateLineItems({});
                  }}
                  onChange={(event: any) => {
                    if (isNaN(Number(event?.target?.value))) {
                      return;
                    }
                    field.onChange?.(event?.target?.value);
                  }}
                  error={errors?.rate?.rate}
                  disabled={!!feeDetail?.contractRateId || canEditInvoice}
                  helperText={errors?.rate?.rate?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}

          <Typography
            variant="body1"
            mt="6px"
            mb="auto"
            textAlign={'end'}
            sx={{ wordBreak: 'break-word' }}
            maxWidth="120px"
            width="100%"
          >
            {!!(invoiceRate && quantity) ? (
              '$' + toFixDigit(Number(invoiceRate) * Number(quantity || 1), 4)
            ) : (
              <></>
            )}
          </Typography>

          <IconButton
            size="small"
            sx={{ flexShrink: 0 }}
            onClick={toggleIsDetailsExpanded}
          >
            {isDetailsExpanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </IconButton>
        </Stack>
        {!isDetailsExpanded && rateDescription && (
          <Stack
            direction={'row'}
            sx={AdditionalSummaryContainerStyles}
            width={'fit-content'}
            py="10px"
            px="15px"
            mt={1}
            ml={!!feeDetail?.contractRateId ? '47px' : 0}
          >
            <Typography variant="body3">{rateDescription}</Typography>
          </Stack>
        )}
        {isDetailsExpanded && (
          <Box
            sx={{
              backgroundColor: ' #f3f3f3',
              borderRadius: '10px',
              py: '10px',
              px: '15px',
              mt: 1,
            }}
          >
            <Controller
              name={`rate.description`}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Description"
                  fullWidth
                  disabled={canEditInvoice}
                />
              )}
            />
          </Box>
        )}
      </Stack>
      <Divider textAlign="left" isSubheader>
        Accessorial Fees
      </Divider>

      <Stack>
        <Typography variant="caption" ml="auto" mr={5} color={'textSecondary'}>
          Total
        </Typography>

        <Stack direction="column" gap={1}>
          {fields?.length ? (
            fields.map((field, index) => (
              <FeeItem
                key={field.id}
                addNew={index === fields.length - 1}
                remove={() => removeFeeItem(index)}
                add={addFeeItem}
                index={index}
                feeItemOptions={feeItemOptions}
                canEdit={canEditInvoice}
              />
            ))
          ) : (
            <FeeItem
              addNew={true}
              remove={() => removeFeeItem(0)}
              add={addFeeItem}
              index={0}
              feeItemOptions={feeItemOptions}
              canEdit={canEditInvoice}
            />
          )}
        </Stack>
      </Stack>

      {!hideNoteSection && (
        <>
          <Divider textAlign="left" isSubheader>
            Invoice
          </Divider>

          <Stack gap={1}>
            {!loadHiddenFields?.messageOnInvoice && (
              <Controller
                name="rate.messageOnInvoice"
                render={({ field }) => (
                  <TextField
                    id="createLoad-rate-message"
                    multiline
                    rows={3}
                    label="Message on invoice..."
                    {...field}
                    sx={{ width: '100%' }}
                    error={errors?.rate?.messageOnInvoice}
                    helperText={errors?.rate?.messageOnInvoice?.message}
                    disabled={canEditInvoice}
                  />
                )}
              />
            )}
            {!loadHiddenFields?.internalNotes && (
              <Controller
                name="rate.internalNotes"
                render={({ field }) => (
                  <TextField
                    id={SECTION_LAST_ELEMENT_ID_LIST[4]}
                    multiline
                    rows={2}
                    label="Internal notes..."
                    {...field}
                    sx={{ width: '100%' }}
                    error={errors?.rate?.internalNotes}
                    helperText={errors?.rate?.internalNotes?.message}
                    disabled={canEditInvoice}
                  />
                )}
              />
            )}
          </Stack>
        </>
      )}

      <Controller
        name={'rate.totalRate'}
        render={({ field }) => (
          <AccordionTotalsSummary
            label="Total Rate"
            value={`${field.value < 0 ? '- ' : ''}$${toFixDigit(
              Math.abs(field.value)
            )}`}
            error={errors?.rate?.totalRate?.message}
          ></AccordionTotalsSummary>
        )}
      />
    </Stack>
  );
};

export default React.memo(RateDetails);
