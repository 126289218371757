import { Type } from 'class-transformer';
import { Address } from '../../../subPages/customers/models/customer';
import { CommodityType } from '../commonMixed';
import { QueryParams } from '../commonMixed/QueryParams';
import { Contact } from '../Contacts/Contacts';
import {
  ContactSummaryCustomer,
  customerFiltersRequestType,
  customerListExcelRequestType,
  customerListRequestType,
} from './Customer';

export class PaginatedCustomersListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  sort!: string;
  constructor({ pageNumber, pageSize, sort }: customerListRequestType) {
    super();
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.sort = sort;
  }
}

export class GetCustomerFiltersRequest {
  customerNameFilter!: string[] | null;
  customerAddressFilter!: string[] | null;
  customerContactsFilter!: string[] | null;
  customerMcFilter!: string[] | null;
  factoringIds!: string[] | null;
  customerPreferenceFilter!: string[] | null;
  typeList!: string[] | null;
  gridColumnMetadataList?: string[];
  customerStateFilter!: string[] | null;
  constructor({
    customerNameFilter,
    customerAddressFilter,
    customerContactsFilter,
    customerMcFilter,
    factoringIds,
    customerPreferenceFilter,
    typeList,
    gridColumnMetadataList,
    customerStateFilter,
  }: customerFiltersRequestType) {
    this.customerNameFilter = customerNameFilter;
    this.customerAddressFilter = customerAddressFilter;
    this.customerContactsFilter = customerContactsFilter;
    this.customerMcFilter = customerMcFilter;
    this.factoringIds = factoringIds;
    this.customerPreferenceFilter = customerPreferenceFilter;
    this.typeList = typeList;
    this.gridColumnMetadataList = gridColumnMetadataList;
    this.customerStateFilter = customerStateFilter;
  }
}

export class GetCustomerListExcelRequest extends QueryParams {
  sort!: string;
  pageNumber!: number;
  pageSize!: number;
  gridColumnMetadataList: string[];
  constructor({
    sort,
    pageNumber,
    pageSize,
    gridColumnMetadataList,
  }: customerListExcelRequestType) {
    super(true);
    this.sort = sort;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.gridColumnMetadataList = gridColumnMetadataList;
  }
}

export class CreateCustomerRequest extends QueryParams {
  name!: string;
  type!: string;
  addressDTO!: Address;
  status!: boolean;
  notes!: string;
  mc!: string;
  customerPreference!: string;
  useFactoring!: boolean;
  factoringId!: string;
  @Type(() => ContactSummaryCustomer)
  contacts!: ContactSummaryCustomer[];
  @Type(() => CommodityType)
  commodityTypes!: CommodityType[];
  ediCustomerId!: string;

  constructor(data: any) {
    // @TODO: Nvard - use class transformer api
    super();
    this.name = data.name;
    this.type = data.type;
    this.notes = data.notes;
    this.status = data.status;
    this.customerPreference = data.customerPreference;
    this.addressDTO = new Address(data.addressDTO || {});
    this.mc = data.mc;
    this.contacts = data.contacts;
    this.commodityTypes = data.commodityTypes;
    this.useFactoring = data.useFactoring;
    this.factoringId = data.factoring?.id || '';
    this.ediCustomerId = data.ediCustomerId;
  }
}

export class CreateCustomerContactRequest extends Contact {
  customerId: number;
  constructor(data: any) {
    super(data);
    this.customerId = data.customerId;
  }
}
export class UpdateCustomerRequest extends QueryParams {
  id!: string;
  organizationId!: number;
  name!: string;
  type!: string;
  addressDTO!: Address;
  status!: boolean;
  notes!: string;
  mc!: string;
  customerPreference!: string;
  useFactoring!: boolean;
  factoringId!: string;
  ediCustomerId!: string;
  @Type(() => ContactSummaryCustomer)
  contacts!: ContactSummaryCustomer[];
  @Type(() => CommodityType)
  commodityTypes!: CommodityType[];
  ediCustomerId!: string;

  constructor(data: any) {
    // @TODO: Nvard - use class transformer api
    super();
    this.id = data.id;
    this.organizationId = data.organizationId; // @TODO: Nvard = organizationId may be removed later
    this.name = data.name;
    this.type = data.type;
    this.addressDTO = new Address(data.addressDTO);
    this.notes = data.notes;
    this.status = data.status;
    this.customerPreference = data.customerPreference;
    this.mc = data.mc;
    this.contacts = data.contacts;
    this.commodityTypes = data.commodityTypes;
    this.useFactoring = data.useFactoring;
    this.factoringId = data.factoring?.id || '';
    this.ediCustomerId = data.ediCustomerId;
  }
}

export class DeleteCustomerRequest extends QueryParams {
  id!: string;
  constructor(data: { id: string }) {
    super();
    this.id = data.id;
  }
}

export class CustomerAssignedToLoadRequest extends QueryParams {
  customerId!: string;
  constructor(data: { customerId: string }) {
    super();
    this.customerId = data.customerId;
  }
}

export class PaginatedContactsListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  customerContactNameKeyword!: string;

  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.customerContactNameKeyword = dto.customerContactNameKeyword;
    }
  }
}

export class PaginatedMcNumbersListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  mcKeyword!: string;

  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.mcKeyword = dto.mcKeyword;
    }
  }
}

export class FactoringSearchableListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  keyword!: string;
  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.keyword = dto.keyword;
    }
  }
}

export class PaginatedAddressesListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  addressKeyword!: string;

  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.addressKeyword = dto.addressKeyword;
    }
  }
}

export class PaginatedNamesListRequest extends QueryParams {
  pageSize!: number;
  pageNumber!: number;
  nameKeyword!: string;
  customerName!: string;

  constructor(dto?: any) {
    super();
    if (dto) {
      this.pageSize = dto.pageSize;
      this.pageNumber = dto.pageNumber;
      this.nameKeyword = dto.nameKeyword;
      this.customerName = dto.customerName;
    }
  }
}

export class GetCustomerListRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  nameKeyword!: string;
}

export class GetContractCustomerListRequest extends GetCustomerListRequest {
  contractName!: string;
}
export class GetCustomerDetailsByIdRequest extends QueryParams {
  id!: number;
  constructor(dto?: any) {
    super();
    Object.assign(this, dto);
  }
}
