import { SxProps, Theme } from '@mui/material';

export const getCreatePaymentPanelStyles = ({
  theme,
}: {
  theme: Theme;
}): Record<string, SxProps> => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'leftMenuCollapsed.canvas',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: 'common.white',
    p: 2,
  },
  cardLabel: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    letterSpacing: '0.15px',
    color: 'text.secondary',
    pb: 1,
  },
  cardValue: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '140%',
    letterSpacing: '0.15px',
    color: 'primary.main',
  },
  defaultTextFieldProps: {
    marginBottom: '8px',
  },
  colStyle: {
    paddingTop: '0 !important',
  },
});
