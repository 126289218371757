import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro-v5';
import { AdapterMoment } from '@mui/x-date-pickers-pro-v5/AdapterMoment';
import {
  DateRange,
  DateRangePicker,
  DateRangePickerProps,
} from '@mui/x-date-pickers-pro-v5/DateRangePicker';
import { Moment, MomentInput } from 'moment';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
  getFormatedDate,
  isValidDateRangeMomentObject,
} from './utils/date.utils';

const EndAdornment = ({
  newValue,
  onClearHandler,
  disabled,
  clearable,
}: {
  newValue: Date | null;
  onClearHandler: (event: SyntheticEvent<Element, Event>) => void;
  disabled: boolean;
  clearable: boolean;
}): JSX.Element => (
  <InputAdornment position="end">
    {!disabled && clearable && newValue && (
      <IconButton onClick={onClearHandler}>
        <CloseOutlinedIcon />
      </IconButton>
    )}
    <IconButton aria-label="show popup" edge="end" disabled={disabled}>
      <CalendarTodayOutlinedIcon />
    </IconButton>
  </InputAdornment>
);

export interface BasicDateRangePickerProps {
  startText: string;
  toText: string;
  endText: string;
  variant?: 'standard' | 'outlined' | 'filled';
  defaultValue?: DateRange<Date>;
  value?: DateRange<Date>;
  onChange?: (date: DateRange<Date>, keyboardInputValue?: string) => void;
  inputFormat?: string;
  disabled?: boolean;
  clearable?: boolean;
  rest?: Partial<DateRangePickerProps<Date, Date>>;
}

export const BasicDateRangePicker = ({
  startText,
  toText,
  endText,
  onChange,
  value: inputValue = [null, null],
  inputFormat = 'DD-MM-yyyy',
  variant = 'standard',
  defaultValue = [null, null],
  disabled = false,
  clearable = true,
  rest,
}: BasicDateRangePickerProps): JSX.Element => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false); //handle open the datepicker popup by manually
  const [value, setValue] = useState<DateRange<Date>>(defaultValue);

  useEffect(() => {
    if (inputValue) {
      setValue(inputValue);
    }
  }, [setValue, inputValue]);

  //handle state open datepicker
  const openDatePickerHandler = useCallback(
    (event: any): void => {
      if (disabled) return;
      event?.preventDefault();
      setOpenDatePicker(true);
    },
    [setOpenDatePicker, disabled]
  );

  const closeDatePickerHandler = useCallback((): void => {
    setOpenDatePicker(false);
  }, [setOpenDatePicker]);
  //handle state open datepicker

  const onDateChangeHandler = useCallback(
    (newValue: DateRange<Moment>, keyboardInputValue?: string): void => {
      if (isValidDateRangeMomentObject(newValue)) {
        setValue(getFormatedDate(newValue));
        onChange?.(getFormatedDate(newValue), keyboardInputValue);
      }
    },
    [setValue, onChange]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateRangePicker
        startText={startText}
        endText={endText}
        value={value || defaultValue}
        disabled={disabled}
        onChange={(
          newValue: DateRange<MomentInput>,
          keyboardInputValue?: string
        ) => {
          onDateChangeHandler(
            newValue as DateRange<Moment>,
            keyboardInputValue
          );
        }}
        inputFormat={inputFormat}
        renderInput={(startProps, endProps) => {
          return (
            <React.Fragment>
              <TextField
                variant={variant}
                {...startProps}
                onClick={(event: any) => {
                  startProps?.onClick?.(event);
                  openDatePickerHandler(event);
                }}
                InputProps={{
                  endAdornment: (
                    <EndAdornment
                      disabled={disabled}
                      clearable={clearable}
                      newValue={value[0]}
                      onClearHandler={(
                        event: SyntheticEvent<Element, Event>
                      ): void => {
                        event?.stopPropagation();
                        setValue([null, value[1]]);
                        onChange && onChange([null, value[1]], undefined);
                      }}
                    />
                  ),
                }}
              />
              <Box sx={{ mx: 2 }}> {toText} </Box>
              <TextField
                variant={variant}
                {...endProps}
                onClick={(event: any) => {
                  startProps?.onClick?.(event);
                  openDatePickerHandler(event);
                }}
                InputProps={{
                  endAdornment: (
                    <EndAdornment
                      disabled={disabled}
                      clearable={clearable}
                      newValue={value[1]}
                      onClearHandler={(
                        event: SyntheticEvent<Element, Event>
                      ): void => {
                        event?.stopPropagation();
                        setValue([value[0], null]);
                        onChange && onChange([value[0], null], undefined);
                      }}
                    />
                  ),
                }}
              />
            </React.Fragment>
          );
        }}
        {...rest}
        open={openDatePicker}
        onClose={closeDatePickerHandler}
      />
    </LocalizationProvider>
  );
};
