import { AXELE_PERMISSION_TYPE } from '../../Permission';
import { getHasPermission } from '../../Permission/utils/helperUtils';

export const useTerminalPermission = () => {
  const hasTerminalRemovePermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.TERMINALS_REMOVE],
  });
  const hasTerminalAddPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.TERMINALS_ADD],
  });
  const hasTerminalEditPermission: boolean = getHasPermission({
    includes: [AXELE_PERMISSION_TYPE.TERMINALS_EDIT],
  });

  return {
    hasTerminalRemovePermission,
    hasTerminalAddPermission,
    hasTerminalEditPermission,
  };
};
