import { memo } from 'react';
import SingleSelect from './SingleSelect';
import { SelectProps, SxProps } from '@mui/material';

export interface SingleSelectFilterProps<TModel> {
  column: any;
  defaultFilterValue: Record<string, any>;
  onChangeCb?: (param: TModel | null, name: string) => void;
  isFormControl: boolean;
  field: Record<string, any> | undefined;
  selectProps?: SelectProps;
}

export const SingleSelectFilter = memo(
  <TModel,>({
    column,
    defaultFilterValue,
    onChangeCb,
    isFormControl = false,
    field = {},
    selectProps,
  }: SingleSelectFilterProps<TModel>) => {
    return (
      <SingleSelect
        isFormControl={isFormControl}
        labelId={column.labelId}
        name={field.name || column.name}
        label={column.label}
        options={column.options}
        customStyles={column.customStyles}
        renderValueSxProps={column.renderValueSxProps}
        fieldName={column.fieldName}
        fieldValue={column.fieldValue}
        onChange={(newValue: TModel | null, name: string) => {
          field?.onChange?.(newValue, name);
          onChangeCb?.(newValue, name);
        }}
        defaultValue={
          isFormControl
            ? field.value
            : defaultFilterValue[column.name] ?? column?.options?.[0]
        }
        {...selectProps}
      />
    );
  }
);
SingleSelectFilter.displayName = 'SingleSelectFilter';
