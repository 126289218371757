import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import { Box, Grid, Slider, Stack, Typography, useTheme } from '@mui/material';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import { ChangeEvent, useEffect, useState } from 'react';
import { loadsearchService } from '../../../../api';
import { NumberToCurrency } from '../../../../constants/numberToCurrency';
import { SaveKpiRequest } from '../../../../models/DTOs/Loadsearch/Request';
import {
  ICalculatorBreakdownTypes,
  IProfitCalculatorProps,
} from '../../loadTypes';
import { CalculatorBreakdown } from '../CalculatorBreakdown';
import './inputStyles.css';
import {
  constantSymbolStyles,
  constantSymbolStylesVar2,
  contentBoxStyles,
  gridBottomStyles,
  gridContainerWrapper,
  gridInnerWraaperStyle,
  inputStyles,
  inputStylesVar2,
  inputStylesVar3,
  selectStyle,
  swapWrapperStyles,
  wrapperStyles,
} from './styles';
import { useTranslation } from 'react-i18next';

export const ProfitCalculator = ({
  selectedRowData,
  closeLoadAttributePopup,
  profitCalculatorUpdatedValue,
}: IProfitCalculatorProps) => {
  const [offerScoreValue, setOfferScoreValue] = useState(
    parseInt(selectedRowData?.Offer?.estimatedProfit) +
      parseInt(selectedRowData?.Offer?.variableCost) +
      parseInt(selectedRowData?.Offer?.fixedCost)
  );
  const [editableValue, setEditableValue] = useState<string>('offerPrice');
  const [nonEditableValue, setNonEditableValue] = useState<string>('mileage');
  const [calculatorType, setCalculatorType] = useState<string>('profit');
  const [netProfitValue, setProfit] = useState<number>(0);
  const [offerPrice, setOfferPrice] = useState<number>(0);
  const [variableCost, setVariableCost] = useState<number>(0);
  const [fixedCost, setFixedCost] = useState<number>(0);
  const [mileage, setMileage] = useState<number>(0);
  const [totalMiles, setTotalMiles] = useState<number>(0);
  const [mileScoreValue, setMileScoreValue] = useState<number>(
    parseFloat(selectedRowData?.Mileage?.subvalue)
  );

  const pessimisticRate = Number(
    Number(selectedRowData?.MarketRate?.pessimisticRate).toFixed(2)
  );
  const optimisticRate = Number(
    Number(selectedRowData?.MarketRate?.optimisticRate).toFixed(2)
  );
  const avgRate = Number(
    (Number(pessimisticRate + optimisticRate) / 2).toFixed(2)
  );

  const theme: any = useTheme();

  useEffect(() => {
    const profit = Number(selectedRowData?.Offer?.estimatedProfit);
    const varCost = Number(selectedRowData?.Offer?.variableCost);
    const fixCost = Number(selectedRowData?.Offer?.fixedCost);
    const miles = Number(selectedRowData?.Mileage?.subvalue);
    const totalMile = Number(
      Number(selectedRowData?.LoadedMiles?.actualTotalMiles).toFixed(2)
    );
    setOfferPrice(Number((profit + varCost + fixCost).toFixed(2)));
    setProfit(Number(profit.toFixed(2)));
    setVariableCost(varCost);
    setFixedCost(fixCost);
    setMileage(miles);
    setTotalMiles(totalMile);
    setOfferScoreValue(Number((profit + varCost + fixCost).toFixed(2)));
  }, []);
  const breakdownItems: Array<ICalculatorBreakdownTypes> = [
    { label: 'Offer', value: offerPrice },
    {
      label: 'Variable Costs',
      value: Number(Number(-selectedRowData?.Offer?.variableCost).toFixed(2)),
    },
    {
      label: 'Fixed Costs',
      value: Number(Number(-selectedRowData?.Offer?.fixedCost).toFixed(2)),
    },
  ];
  const marks = [
    {
      value: pessimisticRate,
      label: `$${pessimisticRate}`,
    },
    {
      value: avgRate,
      label: `$${avgRate} (Market Rate)`,
    },
    {
      value: optimisticRate,
      label: `$${optimisticRate}`,
    },
  ];
  const offerBreaks = [
    {
      value: Number((Number(pessimisticRate) * totalMiles).toFixed(2)),
      label: `$${Number((Number(pessimisticRate) * totalMiles).toFixed(2))}`,
    },
    {
      value: Number((Number(avgRate) * totalMiles).toFixed(2)),
      label: `$${Number(
        (Number(avgRate) * totalMiles).toFixed(2)
      )} (Market Rate)`,
    },
    {
      value: Number((Number(optimisticRate) * totalMiles).toFixed(2)),
      label: `$${Number((Number(optimisticRate) * totalMiles).toFixed(2))}`,
    },
  ];

  const swapValues = (): void => {
    if (editableValue === 'offerPrice') {
      setEditableValue('mileage');
      setNonEditableValue('offerPrice');
    } else {
      setEditableValue('offerPrice');
      setNonEditableValue('mileage');
    }
  };
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    setProfit(Number(Number((e.target as HTMLInputElement).value).toFixed(2)));
    setOfferPrice(
      Number((Number(e.target.value) + fixedCost + variableCost).toFixed(2))
    );
    const m = Number(
      (
        (Number(e.target.value) + fixedCost + variableCost) /
        totalMiles
      ).toFixed(2)
    );
    setMileScoreValue(m);
  };

  const offerPriceChangeHandler = (e: any) => {
    if (e.target.name === 'offerPrice') {
      setOfferPrice(Number(Number(e.target.value).toFixed(2)));
      setProfit(
        Number((Number(e.target.value) - fixedCost - variableCost).toFixed(2))
      );
      setOfferScoreValue(Number(Number(e.target.value).toFixed(2)));
      const m = (Number(e.target.value) / totalMiles).toFixed(2);
      setMileage(Number(m));
    } else {
      setMileage(Number(Number(e.target.value).toFixed(2)));
      setOfferPrice(Number((Number(e.target.value) * totalMiles).toFixed(2)));
      setProfit(
        Number((Number(e.target.value) * totalMiles - variableCost).toFixed(2))
      );
      setMileScoreValue(Number(Number(e.target.value).toFixed(2)));
    }
  };
  const selectChangeHandler = (e: any) => {
    setCalculatorType(e.target.value);
    setMileage(mileScoreValue);
  };

  const saveKpi = async () => {
    const request = new SaveKpiRequest();
    request.destinationDeadhead = selectedRowData?.KpiData?.destinationDeadhead;
    request.dns = selectedRowData?.KpiData?.dns;
    request.estimatedProfit = selectedRowData?.KpiData?.estimatedProfit;
    request.fixedCost = selectedRowData?.KpiData?.fixedCost;
    request.loadDuration = selectedRowData?.KpiData?.loadDuration;
    request.loadMiles = selectedRowData?.KpiData?.loadMiles;
    request.originDeadhead = selectedRowData?.KpiData?.originDeadhead;
    request.perDayRevenue = selectedRowData?.KpiData?.perDayRevenue;
    request.perMileRevenue = selectedRowData?.KpiData?.perMileRevenue;
    request.revenue = selectedRowData?.KpiData?.revenue;
    request.variableCost = selectedRowData?.KpiData?.variableCost;
    request.newRevenue = offerPrice;
    const res = await loadsearchService.saveCalcultorKpi(request);
    profitCalculatorUpdatedValue(res);
  };

  const costToCurrency = (cost: number): string => {
    if (cost > 0) {
      return `$${cost}`;
    } else {
      return `-$${Math.abs(cost)}`;
    }
  };

  let sliderDefaultValue;
  if (calculatorType === 'offer') {
    if (editableValue === 'offerPrice') {
      sliderDefaultValue = offerScoreValue;
    } else sliderDefaultValue = mileScoreValue;
  }

  const { t, ready } = useTranslation();

  if (ready) {
    return (
      <Stack sx={wrapperStyles}>
        {calculatorType === 'offer' ? (
          <Grid container sx={gridContainerWrapper}>
            <Grid
              item
              sx={{
                width: '65%',
                margin: '0 auto',
                pt: '8px',
                ...(editableValue === 'mileage' && {
                  width: '70%',
                }),
              }}
            >
              <Grid item sx={gridInnerWraaperStyle}>
                <Typography sx={constantSymbolStyles}>$</Typography>
                <input
                  type="number"
                  name={`${
                    editableValue === 'offerPrice' ? 'offerPrice' : 'mileage'
                  }`}
                  value={`${
                    editableValue === 'offerPrice' ? offerPrice : mileage
                  }`}
                  style={
                    editableValue === 'offerPrice'
                      ? inputStyles
                      : inputStylesVar2
                  }
                  onChange={offerPriceChangeHandler}
                />
                {editableValue === 'mileage' && (
                  <Typography sx={constantSymbolStyles}>/mi</Typography>
                )}
              </Grid>

              <Grid item sx={gridBottomStyles}>
                <Typography sx={constantSymbolStylesVar2}>$</Typography>
                <input
                  type="number"
                  name="offerPrice"
                  disabled
                  value={`${
                    nonEditableValue === 'offerPrice' ? offerPrice : mileage
                  }`}
                  style={inputStylesVar3}
                />
                {editableValue === 'offerPrice' && (
                  <Typography sx={constantSymbolStylesVar2}>/mi</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item sx={swapWrapperStyles}>
              <SwapVertOutlinedIcon onClick={swapValues} />
            </Grid>
          </Grid>
        ) : (
          <Grid
            sx={{ p: '32px 0', display: 'flex', width: '35%', m: '0 auto' }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography sx={constantSymbolStyles}>$</Typography>
              <input
                type="number"
                name="offerPrice"
                value={`${netProfitValue}`}
                style={inputStyles}
                onChange={onChangeHandler}
              />
            </Box>
          </Grid>
        )}
        <Stack sx={contentBoxStyles}>
          <Box sx={{ pb: '26px' }}>
            <select
              name="calc"
              onChange={selectChangeHandler}
              value={calculatorType}
              style={selectStyle}
            >
              <option value="profit">Profit Calculator</option>
              <option value="offer">Offer Calculator</option>
            </select>
          </Box>
          {calculatorType === 'offer' ? (
            <Slider
              track={false}
              aria-label="track-false-slider"
              defaultValue={sliderDefaultValue}
              disabled={true}
              value={sliderDefaultValue}
              valueLabelDisplay="auto"
              marks={editableValue === 'offerPrice' ? offerBreaks : marks}
              min={
                editableValue === 'offerPrice'
                  ? Number((pessimisticRate * totalMiles).toFixed(2))
                  : pessimisticRate
              }
              max={
                editableValue === 'offerPrice'
                  ? Number((optimisticRate * totalMiles).toFixed(2))
                  : optimisticRate
              }
              sx={{
                width: '95%',
                m: '0 auto 20px',
                '.MuiSlider-markLabel': {
                  fontWeight: 400,
                  fontSize: '10px',
                  lineHeight: ' 15px',
                  letterSpacing: '0.4px',
                  color: '#4E6578',
                },
                '.MuiSlider-thumb': {
                  width: '16px',
                  height: '16px',
                  ...((mileScoreValue > optimisticRate ||
                    mileScoreValue < pessimisticRate) && {
                    backgroundColor: 'error.main',
                  }),
                  ...(((mileScoreValue > avgRate &&
                    mileScoreValue <= optimisticRate) ||
                    (mileScoreValue < avgRate &&
                      mileScoreValue >= pessimisticRate)) && {
                    backgroundColor: 'primary.main',
                  }),
                  ...((offerScoreValue < pessimisticRate * totalMiles ||
                    offerScoreValue > optimisticRate * totalMiles) && {
                    backgroundColor: 'error.main',
                  }),
                },
              }}
            />
          ) : (
            <Slider
              track={false}
              aria-label="track-false-slider"
              defaultValue={mileScoreValue}
              value={mileScoreValue}
              disabled={true}
              valueLabelDisplay="auto"
              marks={marks}
              min={pessimisticRate}
              max={optimisticRate}
              sx={{
                width: '95%',
                m: '0 auto 20px',
                '.MuiSlider-markLabel': {
                  fontWeight: 400,
                  fontSize: '10px',
                  lineHeight: ' 15px',
                  letterSpacing: '0.4px',
                  color: '#4E6578',
                },
                '.MuiSlider-thumb': {
                  width: '16px',
                  height: '16px',
                  ...((mileScoreValue > optimisticRate ||
                    mileScoreValue < pessimisticRate) && {
                    backgroundColor: 'error.main',
                  }),
                  ...(((mileScoreValue > avgRate &&
                    mileScoreValue < optimisticRate) ||
                    (mileScoreValue < avgRate &&
                      mileScoreValue > pessimisticRate)) && {
                    backgroundColor: 'primary.main',
                  }),
                },
              }}
            />
          )}
          <Typography
            sx={{
              color: 'primary.main',
              fontSize: '1rem',
              pb: '8px',
              pt: '26px',
            }}
            variant={'h6'}
          >
            {`Here’s the break down`}
          </Typography>
          {breakdownItems.map(
            (data: ICalculatorBreakdownTypes, index: number) => {
              return <CalculatorBreakdown key={index} data={data} />;
            }
          )}
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              p: '8px',
              mt: '8px',
              bgcolor: 'primary.outlinedHoverBackground',
            }}
          >
            <Grid item>
              <Typography
                sx={{ color: 'primary.main', ...theme.typography.kpiValue }}
              >
                Net Profit:
              </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  color: `${
                    Number(netProfitValue) < 0 ? 'error.main' : 'primary.main'
                  }`,
                  ...theme.typography.kpiValue,
                }}
              >
                {NumberToCurrency(Number(netProfitValue))}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ pt: '26px', justifyContent: 'space-between' }}>
            <Grid item>
              <ButtonImproved
                variant={'outlined'}
                label={t('cancel')}
                onClick={closeLoadAttributePopup}
              />
            </Grid>
            <Grid item>
              <ButtonImproved
                variant={'contained'}
                label={t('save')}
                startIcon={<SaveOutlinedIcon />}
                styleProps={{ p: '7.5px 57.5px' }}
                onClick={saveKpi}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    );
  }
};
