import { QueryParams } from '../commonMixed/QueryParams';

export class TrendAnalysisRequest extends QueryParams {
  endDate?: string;
  customerIds?: Array<number>;
  tractorIds?: Array<number>;
  trailerIds?: Array<number>;
  driverIds?: Array<number>;
  startDate?: string;
  type!: string;
  period?: string;
  kpis?: any;
  terminalId?: string;
  scheduleType?: string;
  dispatchType?: string;
}

export class ReportObj {
  driverPayment!: number;
  emptyByTotal!: number;
  emptyMiles!: number;
  endWeekDate!: string;
  grossRevenue!: number;
  loadCount!: number;
  loadedByTotal!: number;
  loadedMiles!: number;
  organizationId!: number;
  revenueLoadedMile!: number;
  revenueTotalMile!: number;
  startWeekDate!: string;
  week!: number;
  year!: number;
}

export type TrendAnalysisReport = ReportObj[];
