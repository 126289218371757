import { Avatar, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import IconsComponent from '../IconsComponent';
import { ThemeModeSwitch } from './ThemeModeSwitch';

const UserDropDown = ({
  handleClose,
  logout,
  redirectToSubscriptionPage,
  userName,
  redirectToUserPage,
  userImage,
  daysLeft,
  XPPoints,
  subscriptionType = 'pro', //pro,trial pro,free,
  isSubscriptionView,
  classes,
}: any) => {
  const { setThemeMode, themeMode } = useRootStore();
  const onThemeModeChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newThemeMode = event.target.checked ? 'light' : 'dark';
    setThemeMode(newThemeMode);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={classes.dropdown}>
        <Box sx={{ display: 'flex', pb: '24px', alignItems: 'center' }}>
          <Box
            className="user-image"
            onClick={redirectToUserPage}
            sx={
              userImage
                ? classes.userImageWithIcon
                : classes.userImageWithoutIcon
            }
          >
            <Avatar
              sx={{
                height: '100%',
                width: '100%',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
            >
              {userName ? userName.split('')[0] : ''}
            </Avatar>
          </Box>
          <Box sx={classes.flex}>
            <Box
              sx={{
                fontWeight: 'fontWeightMedium',
                fontSize: '16px',
                color: 'primary.main',
                cursor: 'pointer',
              }}
              onClick={redirectToUserPage}
            >
              Hi {userName}!
            </Box>
          </Box>
        </Box>
        <Box sx={classes.devider} />

        {/* 
        // DO NOT REMOVE
        <Box sx={classes.icon}>
          <FormControlLabel
            control={
              <ThemeModeSwitch
                checked={themeMode === 'light'}
                onChange={onThemeModeChangeHandler}
              />
            }
            label={
              <Box sx={{ textTransform: 'capitalize' }}>{themeMode} mode</Box>
            }
          />
        </Box> */}
        <Box sx={classes.icon}>
          <IconsComponent
            styleProps={{ height: '20px', width: '20px' }}
            iconName="SettingsOutlined"
            source="MUIcons"
          />
          <Box
            onClick={() => {
              window.open('https://help.axele.com/user-manual/', '_blank');
            }}
            sx={{
              fontSize: 'fontSize',
              fontWeight: 'fontWeightMedium',
              marginLeft: '8px',
            }}
          >
            User Manual
          </Box>
        </Box>
        {isSubscriptionView && (
          <Box sx={classes.icon}>
            <IconsComponent
              styleProps={{ height: '20px', width: '20px' }}
              iconName="RotateLeft"
              source="MUIcons"
            />
            <Box
              onClick={() => {
                redirectToSubscriptionPage();
              }}
              sx={{
                fontSize: 'fontSize',
                fontWeight: 'fontWeightMedium',
                marginLeft: '8px',
              }}
            >
              Subscription
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            color: 'primary.main',
            cursor: 'pointer',
          }}
        >
          <IconsComponent
            styleProps={{ height: '20px', width: '20px' }}
            iconName="LogoutOutlined"
            source="MUIcons"
          />
          <Box
            sx={{
              fontSize: 'fontSize',
              fontWeight: 'fontWeightMedium',
              marginLeft: '8px',
            }}
            onClick={logout}
          >
            Logout
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default UserDropDown;
