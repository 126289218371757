import { SxProps, Theme } from '@mui/material';
import React from 'react';
import {
  ExpandableTimelineV3EditIconEvents,
  StopSolutionV3Prop,
} from '../../types';
import { ExpandableTimelineV3 } from '../CustomizeTimeline/ExpandableTimelineV3';
import { CommonExpandableTimelineContentProps } from '../../../AxeleTimeline';
export interface TripContentProps extends ExpandableTimelineV3EditIconEvents {
  timelineContent: CommonExpandableTimelineContentProps<StopSolutionV3Prop>[];
  isMultipleExpanded?: boolean;
  sx?: SxProps<Theme>;
  isManifest?: boolean;
  idEditAddEnable?: boolean;
  isManifestDetailPanel?: boolean;
}
export const TripContent = ({
  timelineContent,
  isMultipleExpanded,
  sx = {},
  isManifest,
  idEditAddEnable,
  isManifestDetailPanel,
}: TripContentProps): JSX.Element => {
  return (
    <ExpandableTimelineV3
      timelineContent={timelineContent}
      isMultipleExpanded={isMultipleExpanded}
      isManifest={isManifest}
      idEditAddEnable={idEditAddEnable}
      isManifestDetailPanel={isManifestDetailPanel}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...sx,
      }}
    />
  );
};
