import { Theme } from '@mui/material';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import {
  Feature,
  GeoJsonProperties,
  Geometry,
  Position,
} from '@trimblemaps/trimblemaps-js/geojson';
import { ServiceError } from '../../../api/interfaces';
import { GetDispatchDetailsPanelMapDataRequest } from '../../../models/DTOs/Dispatch/Requests';
import constants, { ROUTEFLAG } from './constants';
import { MarkersData, Stops } from './types';
import DispatchController from '../../../views/dispatch/utils/Controller';
import DriverMarker from '../../../_assets/images/DriverMarker.png';
import LocationOutdatedMarker from '../../../_assets/images/LocationOutdatedMarker.png';

const getGeoJsonFeatures = (
  markersData: MarkersData[]
): Feature<Geometry, GeoJsonProperties>[] => {
  const features: Feature<Geometry, GeoJsonProperties>[] = [];
  markersData?.forEach((marker) => {
    features.push({
      type: 'Feature',
      properties: {
        marker: marker,
        name: marker?.driverGroupName,
        isLocationOutdated: marker?.isLocationOutdated ?? false,
        coordinates: [marker?.lng, marker?.lat],
      },
      geometry: {
        type: 'Point',
        coordinates: [marker?.lng, marker?.lat],
      },
    });
  });
  return features;
};

export const getUnclusteredPoints = ({
  trimbelMap,
}: {
  trimbelMap: TrimbleMaps.Map;
}): Position[] => {
  const clusterSetting = constants.cluster;
  const sourceId = clusterSetting.source.id;
  const crrUnclusteredPoints = trimbelMap?.querySourceFeatures?.(sourceId, {
    filter: clusterSetting.querySourceFeatures.filter.unclusteredMarkers,
  });
  return (
    crrUnclusteredPoints?.map?.((point) =>
      JSON.parse(point?.properties?.coordinates || [])
    ) || []
  );
};

export const isUnclusteredPoint = ({
  markerData,
  unclusteredMarkers,
}: {
  markerData: MarkersData;
  unclusteredMarkers: Position[];
}): boolean => {
  return !!unclusteredMarkers.find((item) => {
    return markerData?.lng === item?.[0] && markerData?.lat === item?.[1];
  });
};

export const initMarkerCluster = ({
  trimbelMap,
  markersData,
  theme,
}: {
  trimbelMap: TrimbleMaps.Map;
  markersData?: MarkersData[];
  theme: Theme;
}) => {
  const clusterSetting = constants.cluster;
  if (!trimbelMap || !markersData) return;
  if (trimbelMap?.getSource(clusterSetting.source.id)) return;
  trimbelMap.addSource(clusterSetting.source.id, {
    type: 'geojson',
    cluster: true,
    clusterRadius: 40,
    clusterMaxZoom: 14,
    data: {
      type: 'FeatureCollection',
      features: getGeoJsonFeatures(markersData),
    },
  });

  // Use filter to shot clustered points
  trimbelMap.addLayer({
    id: clusterSetting.layer.clusteredPoints,
    type: 'circle',
    source: 'randomPoints',
    filter: clusterSetting.querySourceFeatures.filter.clusteredPoints,
    paint: {
      'circle-radius': ['step', ['get', 'point_count'], 15, 5, 20, 50, 25],
      'circle-color': theme.palette.primary.main,
      'circle-stroke-color': theme.palette.common.white,
      'circle-stroke-width': 3,
    },
  });
  // Show count for clustered points
  trimbelMap.addLayer({
    id: clusterSetting.layer.clusterCount,
    type: 'symbol',
    source: clusterSetting.source.id,
    filter: clusterSetting.querySourceFeatures.filter.clusterCount,
    layout: {
      'text-field': '{point_count}',
      'text-size': 14,
      'text-justify': 'center',
      'text-anchor': 'center',
      'text-allow-overlap': true,
    },
    paint: {
      'text-color': theme.palette.common.white,
    },
  });

  const driverWithLocation = 'driver-markers';
  const driverWithLocationOutdated = 'location-outdated-marker';

  trimbelMap.loadImage(DriverMarker, function (error: any, image: any) {
    if (!trimbelMap.hasImage(driverWithLocation)) {
      trimbelMap.addImage(driverWithLocation, image);
    }
  });
  trimbelMap.loadImage(
    LocationOutdatedMarker,
    function (error: any, image: any) {
      if (!trimbelMap.hasImage(driverWithLocationOutdated)) {
        trimbelMap.addImage(driverWithLocationOutdated, image);
      }
    }
  );
  setTimeout(() => {
    trimbelMap.addLayer({
      id: clusterSetting.layer.unclusteredMarkers,
      type: 'symbol',
      source: clusterSetting.source.id,
      filter: clusterSetting.querySourceFeatures.filter.unclusteredMarkers,
      layout: {
        'icon-image': driverWithLocation,
        'icon-size': 0.8,
        'text-field': ['get', 'name'],
        'text-size': 12,
        'text-anchor': 'bottom',
        'text-allow-overlap': true,
        'text-line-height': 3.5,
      },
    });
    trimbelMap.addLayer({
      id: clusterSetting.layer.markersWithLocationOutdated,
      type: 'symbol',
      source: clusterSetting.source.id,
      filter:
        clusterSetting.querySourceFeatures.filter.markersWithLocationOutdated,
      layout: {
        'icon-image': driverWithLocationOutdated,
        'icon-size': 0.8,
        'text-field': ['get', 'name'],
        'text-size': 12,
        'text-anchor': 'bottom',
        'text-allow-overlap': true,
        'text-line-height': 3.5,
      },
    });
  }, 1500);
};

export const removeMapLayers = (map: TrimbleMaps.Map) => {
  if (!map) return;
  if (map?.getLayer(constants.cluster.layer.clusteredPoints))
    map?.removeLayer(constants.cluster.layer.clusteredPoints);
  if (map?.getLayer?.(constants.cluster.layer.clusterCount))
    map?.removeLayer(constants.cluster.layer.clusterCount);
  if (map?.getLayer?.(constants.cluster.layer.unclusteredMarkers))
    map?.removeLayer(constants.cluster.layer.unclusteredMarkers);
  if (map?.getLayer?.(constants.cluster.layer.markersWithLocationOutdated))
    map?.removeLayer(constants.cluster.layer.markersWithLocationOutdated);
};

export const removeMapSources = (map: TrimbleMaps.Map) => {
  if (!map) return;
  if (map?.getSource(constants.cluster.source.id))
    map?.removeSource(constants.cluster.source.id);
};

export const getDispatchDetailsPanelMapData = async (
  groupId: string
): Promise<any> => {
  if (!groupId) return;
  const response =
    await DispatchController.instance().getDispatchDetailsPanelMapData(
      new GetDispatchDetailsPanelMapDataRequest({
        groupId: groupId,
      })
    );
  if (!response || response instanceof ServiceError) {
    return null;
  } else {
    return response;
  }
};

const getRouteColor = ({
  theme,
  stop,
}: {
  stop: MarkersData;
  theme: Theme;
}) => {
  return {
    color: stop.deadhead ? '#9E9E9E' : '#4385F4', //theme.palette.primary.main,
    opacity: stop.status == 'FUTURE' || stop.status == 'ACTIVE' ? 1 : 0.5,
  };
};
// theme.palette.primary.light

const getRoutes = ({
  stopsList,
  theme,
}: {
  stopsList: MarkersData[];
  theme: Theme;
}) => {
  const stops: Stops[] = stopsList.reduce(
    (arr: Stops[], stop: MarkersData, index: number) => {
      if (index === stopsList.length - 1) return arr;
      const nextStop = stopsList[index + 1];
      if (nextStop.routeFlag === ROUTEFLAG.NONE || !nextStop.routeFlag)
        return arr;
      arr.push({
        ...getRouteColor({ stop: nextStop, theme }),
        stops: [
          new TrimbleMaps.LngLat(stop.lng, stop.lat),
          new TrimbleMaps.LngLat(nextStop.lng, nextStop.lat),
        ],
      });
      return arr;
    },
    []
  );
  return stops;
};

export const generateMapRoute = ({
  map,
  stopsList,
  theme,
}: {
  map: TrimbleMaps.Map;
  stopsList?: MarkersData[];
  theme: Theme;
}) => {
  if (!stopsList) return;
  const routes = getRoutes({
    stopsList,
    theme,
  });
  routes.forEach((route: Stops, index: number) => {
    if (route.stops.length < 2) return;
    if (map) {
      // setTimeout(() => {
      new TrimbleMaps.Route({
        routeId: `Route-${index}-${Math.random()}`,
        routeColor: route.color,
        routePathOpacity: route.opacity,
        routeWidth: 8,
        stops: route.stops,
        showStops: false,
        frameRoute: false,
      }).addTo(map);
      // }, 2000);
    }
  });
};

export const generateMapForRouteMode = ({
  trimbelMap,
  stopsList,
  theme,
}: {
  trimbelMap: TrimbleMaps.Map;
  stopsList?: MarkersData[];
  theme: Theme;
}) => {
  if (!stopsList) return;
  const routes = getRoutes({ stopsList, theme });
  const routesInstance: TrimbleMaps.Route[] = [];
  routes.forEach((route: Stops, index: number) => {
    if (route.stops.length < 2 || !trimbelMap) return;
    const routeInstance = new TrimbleMaps.Route({
      routeId: `Route-${index}-${Math.random()}`,
      routeColor: route.color,
      routePathOpacity: route.opacity,
      routeWidth: 8,
      stops: route.stops,
      showStops: true,
      frameRoute: false,
    }).addTo(trimbelMap); //Important : must use trimbelMap instance
    routesInstance?.push(routeInstance);
  });
  return routesInstance;
};

export const findAndFormatUnclusteredMarkers = (
  allMarkers: MarkersData[],
  unclusteredPositions: Position[]
) => {
  const markers: MarkersData[] = [];
  for (const markerData of allMarkers) {
    for (const item of unclusteredPositions) {
      if (markerData?.lng === item?.[0] && markerData?.lat === item?.[1]) {
        markers?.push(markerData);
        break;
      }
    }
  }
  return markers;
};

export const debounce = (method: any, delay = 200) => {
  clearTimeout(method._tId);
  method._tId = setTimeout(function () {
    method();
  }, delay);
};

export const isCursorOutsideElement = (popupRef: any, evt: any): boolean => {
  if (!popupRef) return false;
  const [currX, currY] = [evt.originalEvent?.x, evt?.originalEvent?.y];
  const rect = popupRef?.getElement()?.getBoundingClientRect?.();
  const center = rect?.x! + rect?.width! / 2;
  if (
    currY < rect?.top! - 10 ||
    currY > rect?.bottom! + 10 ||
    currX < center - 10 ||
    currX > center + 10
  )
    return true;
  return false;
};
