import React, { useState } from 'react';
import { View } from '../types';
import {
  TeamDriver,
  TeamDriverFilterObject,
} from '../models/DTOs/TeamDriver/TeamDriver';
import { gridPageSize } from '../utils';
export type TeamDriverContextType = {
  currentView: View;
  setCurrentView: (data: View) => void;
  addNewClicked: boolean;
  setAddNewClicked?: (data: boolean) => void;
  component: string;
  teamDriver: Array<TeamDriver>;
  setTeamDriver: (data: Array<TeamDriver>) => void;
  setSelectTeamDriver?: (data: TeamDriver | null) => void;
  selectTeamDriver: TeamDriver | null;
  dictionary: string;
  filters: { [key: string]: any };
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  settingsPagination: ISettingsPagination;
  setSettingsPagination: React.Dispatch<
    React.SetStateAction<ISettingsPagination>
  >;
  setActionWorkFlow: (data: string) => void;
  actionWorkFlow: string;
};
export interface ISettingsPagination {
  isLoading: boolean;
  first: boolean;
  last: boolean;
  pageNumber: number;
  pageSize: number;
  groupMode: string;
  sort: string;
  activeForOperation: boolean | string;
  driverIds: Array<TeamDriverFilterObject>;
  terminalIds: Array<string>;
  totalElements: number;
}
const defaultSettingsPagination: ISettingsPagination = {
  isLoading: false,
  first: false,
  last: true,
  pageNumber: 1,
  pageSize: gridPageSize,
  groupMode: '',
  activeForOperation: '',
  driverIds: [],
  terminalIds: [],
  sort: '',
  totalElements: 0,
};
export const TeamDriverContextContext =
  React.createContext<TeamDriverContextType>({} as TeamDriverContextType);
const component = 'Teams';
const dictionary = 'location';

export const TeamDriverContextProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [currentView, setCurrentView] = useState<View>({} as View);
  const [addNewClicked, setAddNewClicked] = useState<boolean>(false);
  const [actionWorkFlow, setActionWorkFlow] = useState<string>('');
  const [teamDriver, setTeamDriver] = useState<TeamDriver[]>([]);
  const [filters, setFilters] = useState({});
  const [selectTeamDriver, setSelectTeamDriver] = useState<TeamDriver | null>(
    {} as TeamDriver
  );
  const [settingsPagination, setSettingsPagination] =
    useState<ISettingsPagination>(defaultSettingsPagination);
  return (
    <TeamDriverContextContext.Provider
      value={{
        currentView,
        setCurrentView,
        addNewClicked,
        setAddNewClicked,
        component,
        teamDriver,
        setTeamDriver,
        dictionary,
        selectTeamDriver,
        setFilters,
        filters,
        setSelectTeamDriver,
        settingsPagination,
        setSettingsPagination,
        actionWorkFlow,
        setActionWorkFlow,
      }}
    >
      {children}
    </TeamDriverContextContext.Provider>
  );
};

export const useTeamsDriver = () => React.useContext(TeamDriverContextContext);
