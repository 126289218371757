import { DeleteButtonIcon } from '../../ui-kit/components/Assets';
import DeletePopup from '../../ui-kit/components/DeletePopup';
import { useMemo, useState } from 'react';
import { vendorService } from '../../api';
import LongMenu from '../../common/LongMenu';
import { AXELE_PERMISSION_TYPE, Permission } from '../../common/Permission';
import { DeleteVendorRequest } from '../../models';
import { VendorSummaryTableRow } from './models/vendor';
import StorageManager from '../../StorageManager/StorageManager';

interface ActionsSectionProps {
  onDeleted: (id: string) => void;
  vendorData: VendorSummaryTableRow | null;
}

interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}

interface IPopupConfigs {
  text: string;
  canDelete: boolean;
}

export function ActionsSection({ onDeleted, vendorData }: ActionsSectionProps) {
  const defaultPopupConfigs: IPopupConfigs = {
    text: 'Are you sure you want to delete this vendor?',
    canDelete: true,
  };
  const [popupConfigs, setPopupConfigs] = useState<IPopupConfigs | null>(null);

  const id = vendorData?.id;
  const userStorageData = StorageManager.getUser();
  const options = useMemo(() => {
    const mainActions: Action[] = [];

    const deleteAction = {
      customStyles: { paddingRight: '5px' },
      name: 'Delete Vendor',
      action: () => {
        handleDeleteClick();
      },
      startIcon: DeleteButtonIcon({}),
    };

    mainActions.push(deleteAction);

    return mainActions;
  }, [id]);

  function handlePopupClose() {
    setPopupConfigs(null);
  }

  const handleDeleteClick = () => {
    setPopupConfigs(defaultPopupConfigs);
  };

  const deleteVendor = async () => {
    const requestData = new DeleteVendorRequest({ id: id });
    try {
      await vendorService.deleteVendor(requestData);
      onDeleted(id);
    } catch (error: any) {
      const response = error?.error?.response;
      if (response?.status === 400) {
        setPopupConfigs({
          text: response.data?.apierror?.message,
          canDelete: false,
        });
      }
      return;
    }
    setPopupConfigs(null);
  };

  return (
    <>
      <Permission contains={[AXELE_PERMISSION_TYPE.VENDOR_REMOVE]}>
        <LongMenu color={'primary.contrast'} options={options} />
      </Permission>
      {popupConfigs && (
        <DeletePopup
          open
          onClose={handlePopupClose}
          onAction={popupConfigs.canDelete ? deleteVendor : undefined}
          title={'Delete Vendor?'}
          body={popupConfigs.text}
          cancelText={popupConfigs.canDelete ? 'Cancel' : 'Ok'}
        />
      )}
    </>
  );
}
