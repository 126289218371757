import { Grid, Typography, useTheme } from '@mui/material';
import { NumberToCurrency } from '../../../../constants/numberToCurrency';

export const costToCurrency = (cost: number): string => {
  return `-$${Math.abs(cost)}`;
};

export const LoadValuesDisplay = ({
  title,
  subtitle,
  cost,
  value,
  isDetailTab = false,
}: IProps) => {
  const theme: any = useTheme();
  return (
    <Grid
      container
      sx={{ justifyContent: 'space-between', pb: '11px', alignItems: 'center' }}
    >
      <Grid item xs={4}>
        <Typography
          sx={{
            color: 'text.primary',
            ...(isDetailTab
              ? {
                  ...theme.typography?.body2,
                }
              : {
                  ...theme.typography?.h7,
                }),
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: 'text.secondary',
            ...theme.typography?.body3,
          }}
        >
          {subtitle}
        </Typography>
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          flexBasis: 'content',
          wordBreak: 'break-word',
          textAlign: 'right',
        }}
      >
        <Typography
          sx={{
            ...(isDetailTab
              ? {
                  ...theme.typography?.body2Bold,
                }
              : {
                  ...theme.typography?.h7,
                  color: 'error.main',
                }),
          }}
        >
          {isDetailTab ? value : NumberToCurrency(Number(cost))}
        </Typography>
      </Grid>
    </Grid>
  );
};

export interface IProps {
  title: string;
  subtitle?: string;
  cost?: number;
  value?: string;
  isDetailTab?: boolean;
}
