const NoAppointmentIcon = ({ size = 32 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={32} height={32} rx="16" fill="#2B64CB" fillOpacity="0.08" />
      <path
        d="M23 8.7207L8 23.7307"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3611 21.3639C16.3401 22.8749 18.4631 23.8409 20.4441 24.2159C21.5041 24.4159 22.5731 24.1069 23.3141 23.3659L23.9651 22.7149C24.7461 21.9339 24.7461 20.6679 23.9651 19.8859L22.7921 18.7129C22.2061 18.1269 21.2561 18.1269 20.6711 18.7129L19.7681 19.6169C19.5511 19.8339 19.2221 19.9069 18.9411 19.7829C18.2911 19.4959 17.6451 19.1359 17.0181 18.7089"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.868 16.8564C13.834 15.7604 13.032 14.5574 12.493 13.3354C12.37 13.0554 12.442 12.7264 12.659 12.5094L13.478 11.6904C14.149 11.0194 14.149 10.0704 13.563 9.48436L12.39 8.31036C11.609 7.52936 10.343 7.52936 9.56202 8.31036L8.91002 8.96236C8.16902 9.70336 7.86002 10.7724 8.06002 11.8324C8.53502 14.3464 9.96702 17.0854 12.255 19.4714"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoAppointmentIcon;
