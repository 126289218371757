import BaseEvent from '../BaseEvent';
import { EventTypes } from '../EventTypes';
import { LoadOverviewDetails } from '../../common/LoadTabPanel/tabs/Overview/models';

export default class LoadOverviewChange extends BaseEvent {
  data: LoadOverviewDetails;
  constructor(data: any) {
    super(EventTypes.LOAD_OVERVIEW_CHANGE, data);
    this.data = data;
  }
}
