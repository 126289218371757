import { Box, Typography } from '@mui/material';
import { AuthActivationErrorIcon } from '../../ui-kit/components/Assets';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { StyledAuthFromWrapper } from './styles';

const UserActivationFormError = () => {
  const { isMobile } = useThemeResponsive();
  return (
    <StyledAuthFromWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AuthActivationErrorIcon />
          </Box>

          <Typography
            sx={{
              fontFamily: 'Oxanium',
              fontSize: '42px',
              fontWeight: '600',
              lineHeight: '59px',
              textAlign: 'center',
              color: 'error.light',
              pt: 7,
            }}
          >
            Something went wrong with your activation!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          pl: 9,
          pr: 9,
          ...(isMobile && {
            pl: 2,
            pr: 2,
          }),
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '22px',
            textAlign: 'center',
            color: 'text.primary',
          }}
        >
          The account activation link is not valid. Please contact your company
          admin.
        </Typography>
      </Box>
    </StyledAuthFromWrapper>
  );
};

export default UserActivationFormError;
