import React, { useState } from 'react';

export type EquipmentContextType = {
  selectedDriverId: number | undefined;
  setSelectedDriverId: (data: { type: string; id: number } | undefined) => void;
  driverAmountForAssign: {} | undefined;
  setDriverAmountForAssign: (data: {} | undefined) => void;
};

export const RoutesLoadContext = React.createContext<EquipmentContextType>(
  {} as EquipmentContextType
);

export const RoutesLoadContextProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [selectedDriverId, setSelectedDriverId] = useState();
  const [driverAmountForAssign, setDriverAmountForAssign] = useState();

  return (
    <RoutesLoadContext.Provider
      value={{
        selectedDriverId,
        setSelectedDriverId,
        driverAmountForAssign,
        setDriverAmountForAssign,
      }}
    >
      {children}
    </RoutesLoadContext.Provider>
  );
};

export const useRoutesLoad = () => React.useContext(RoutesLoadContext);
