import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

export const SortIcon = ({
  up = true,
  onChange,
}: {
  up?: boolean;
  onChange?: (isUp: boolean) => void;
}) => {
  const [isUp, setIsUp] = useState<boolean>(up);

  const onChangeHandler = useCallback((): void => {
    setIsUp((preState) => !preState);
    onChange?.(!up);
  }, [onChange, up]);

  useEffect(() => {
    setIsUp(up);
  }, [up]);

  return (
    <IconButton
      sx={{ height: '28px', padding: '4px' }}
      aria-label="Sort"
      onClick={onChangeHandler}
    >
      {isUp ? (
        <ArrowUpwardOutlinedIcon color="primary" />
      ) : (
        <ArrowDownwardOutlinedIcon color="primary" />
      )}
    </IconButton>
  );
};
