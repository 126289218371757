import {
  DeleteOutline,
  InsertPhoto,
  UploadOutlined,
} from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { SUPPORTED_FILE_SIZE } from '../../../constants/fileSizeConstants';
import { docFileFormatsRegexp } from '../../../constants/regex.constants';
import {
  MSExcellIcon,
  MSWordIcon,
  PDFIcon,
} from '../../../ui-kit/components/Assets';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import { shortFileName } from '../../../utils/shortFileName';

type InputUploadProps = Omit<
  React.InputHTMLAttributes<HTMLButtonElement>,
  'onChange' | 'type' | 'value'
>;

export interface UploadProps extends InputUploadProps {
  onChange?: (files: FileList | null) => void;
  removeFiles?: (files: FileList | undefined) => void;

  // init?: MediaType;
  value?: FileList;
  accept?: string;
  label?: string;
  fileMissingText?: string;
  errors?: ErrorsType | undefined;
  helperText?: string;
  disabled?: boolean;
  disabledButton?: boolean;
}
interface IIconMap {
  [key: string]: JSX.Element;
}

type ErrorsType = {
  type: string;
  message: string;
};

const Upload: FC<UploadProps> = (props) => {
  const {
    accept = '*.pdf,*.docx',
    className,
    value,
    onChange,
    label,
    fileMissingText,
    removeFiles,
    disabledButton,
    disabled = false,
    // ...uploadProps
  } = props;

  const uploadRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileList | null>();
  const [error, setError] = useState<ErrorsType | undefined>();
  const [titleShort, setTitleShort] = useState('');
  const iconMap: IIconMap = {
    xls: <MSExcellIcon />,
    xlsx: <MSExcellIcon />,
    docx: <MSWordIcon />,
    pdf: <PDFIcon />,
    png: <InsertPhoto />,
    jpg: <InsertPhoto />,
  };

  const prepareTitle = () => {
    const [isShort, newTitle] = shortFileName(files?.[0]?.name || '');
    if (!isShort) return;

    setTitleShort(newTitle);
  };

  useEffect(() => {
    prepareTitle();
  }, [files]);

  useEffect(() => {
    setFiles(value);
    if (!value) {
      if (uploadRef.current) uploadRef.current.value = '';
    }
  }, [value]);

  const onClickUpload = () => {
    uploadRef.current?.click();
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentFiles = e.target.files;
    if (currentFiles?.length) {
      const { size, name } = currentFiles[0];

      if (!name.toLowerCase().match(docFileFormatsRegexp)) {
        setError({
          type: 'fileType',
          message: 'File type is not supported.',
        });
        return;
      }

      if (size && size > SUPPORTED_FILE_SIZE) {
        setError({
          type: 'fileSize',
          message: 'File size cannot be greater than 20MB.',
        });
        return;
      }

      setError(undefined);
      setFiles(currentFiles);
      onChange?.(currentFiles);
    }
  };

  const onRemove = () => {
    removeFiles && removeFiles(files);
    setFiles(null);
    onChange?.(null);
    if (uploadRef.current) uploadRef.current.value = '';
  };

  return (
    <div>
      <Box
        className={className}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          flexDirection: 'row',
          mb: 2,
          mt: 1,
        }}
      >
        <input
          ref={uploadRef}
          type="file"
          accept={accept}
          hidden
          onChange={onFileChange}
          // {...uploadProps}
        />
        {!disabled && (
          <ButtonImproved
            variant="outlined"
            startIcon={<UploadOutlined />}
            size="small"
            label={label}
            onClick={onClickUpload}
            disabled={disabledButton}
          />
        )}
        {files?.length ? (
          <>
            {
              iconMap[
                files[0].name.split('.').at(-1)?.toLowerCase() as keyof IIconMap
              ]
            }
            {titleShort ? (
              <Tooltip title={files?.[0].name}>
                <Typography>{titleShort}</Typography>
              </Tooltip>
            ) : (
              <Typography>{files?.[0].name}</Typography>
            )}
            {!disabled && (
              <IconButton onClick={onRemove} disabled={disabledButton}>
                <DeleteOutline />
              </IconButton>
            )}
          </>
        ) : null}
        {fileMissingText && !files?.length && (
          <Typography
            variant="h8"
            sx={{ color: 'text.primary', marginLeft: '10px' }}
          >
            {fileMissingText}
          </Typography>
        )}
      </Box>
      <Typography
        variant="body3"
        sx={{
          marginTop: '-10px',
          color: 'error.main',
          display: 'block',
        }}
      >
        {error?.message}
      </Typography>
    </div>
  );
};

export default Upload;
