import { Box, Button, Stack, Typography } from '@mui/material';
import { AddIcon } from '../../ui-kit/components/Assets';
import CommonDataGrid from '../../common/DataGridPro';
import {
  GridApi,
  GridCallbackDetails,
  GridCellEditStartParams,
  GridCellParams,
  GridColDef,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import React, {
  Ref,
  RefObject,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import {
  GRID_SELECT_TYPE_COLUMNS,
  LaneInfoEnum,
  getUnitTypes,
} from '../../components/contract';
import {
  AUTOMATE_UNIT_VAL,
  GRID_DEFAULT_VALUES,
} from '../../constants/contracts';
import { useContractPermission } from '../hooks/useContractPermission';
import {
  validateAccessorialRate,
  validateMaxMile,
} from '../../components/contract/validators';
import { Regions } from '@/subPages/settings/FuelMatrix/constants';

interface ILaneItemsTableProps {
  itemType: string;
  columns: (GridColDef & any)[];
  rows: { [key: string]: string }[];
  staticData?: any;
  tableRef: RefObject<GridApi>;
  setRows: SetStateAction<any>;
  isAccessorialTable: boolean;
  laneInfoType: string;
  validator?: ({ field, value, params }: any) => any;
  error?: any;
  isTableDirty?: boolean;
  setIsTableDirty?: any;
  handleRowChange?: (param: boolean) => void;
}

const NoRowOverlay = () => {
  return (
    <Box sx={{ textAlign: 'center', mt: 3 }}>
      <Typography variant="caption" fontFamily={'oxanium'}>
        No Data
      </Typography>
    </Box>
  );
};

const LaneItemsTable = (props: ILaneItemsTableProps) => {
  const {
    itemType,
    columns,
    rows,
    tableRef,
    setRows,
    isAccessorialTable,
    staticData,
    laneInfoType,
    validator,
    isTableDirty,
    setIsTableDirty,
  } = props;
  const { hasContractEditPermission } = useContractPermission();
  const [cellOldValue, setCellOldValue] = useState<object>({
    field: '',
    value: '',
  });
  const handleOnClickAddRow = useCallback(
    async (laneInfoType, isTab = false) => {
      const defaultRow: any = {};
      await columns.forEach((col) => {
        if (
          ((!isAccessorialTable && col?.field !== 'rateType') ||
            col?.field !== 'mode' ||
            col?.field !== 'isNew' ||
            col?.field !== 'remove') &&
          !col?.hide
        ) {
          defaultRow[col?.field as keyof typeof defaultRow] = {
            isInvalid: false,
            message: '',
            value: '',
          };
        }
      });
      const id = Math.random();
      let data: any = { id, ...defaultRow, isNew: true };
      if (
        laneInfoType === LaneInfoEnum.city ||
        laneInfoType === LaneInfoEnum.zip
      ) {
        data = {
          ...data,
          mode: {
            isInvalid: false,
            message: '',
            value: GRID_DEFAULT_VALUES.mode,
          },
          rateType: {
            isInvalid: false,
            message: '',
            value: GRID_DEFAULT_VALUES.rateType,
          },
          region: {
            isInvalid: false,
            message: '',
            value: GRID_DEFAULT_VALUES.region,
          },
        };
      }
      if (laneInfoType === LaneInfoEnum.mileage) {
        data = {
          ...data,
          mode: {
            isInvalid: false,
            message: '',
            value: GRID_DEFAULT_VALUES.mode,
          },
          rateType: {
            isInvalid: false,
            message: '',
            value: GRID_DEFAULT_VALUES.milageRateType,
          },
        };
      }
      setIsTableDirty(true);
      setRows((oldRows) => {
        return [...oldRows, { ...data }];
      });
      tableRef.current.setCellFocus(id, columns[isTab ? 0 : 1]?.field);
    },
    [columns, isAccessorialTable, setIsTableDirty, setRows, tableRef]
  );

  const handleCellEditCommit = useCallback(
    (params) => {
      if (hasContractEditPermission) {
        const newRows = [...rows];
        const index = newRows.findIndex((row) => row.id === params.id);
        let val = params?.value;
        const currentRow = newRows[index];
        const type = typeof val;
        if (val && type == 'object' && 'value' in val) {
          val = val?.value;
        }
        if (
          !isTableDirty &&
          cellOldValue &&
          cellOldValue?.field == params?.field
        ) {
          if (
            (params?.field == 'origin' || params?.field == 'destination') &&
            val?.city != cellOldValue?.value?.city &&
            val?.state != cellOldValue?.value?.state
          ) {
            setIsTableDirty(true);
          } else if (cellOldValue?.value != val) {
            setIsTableDirty(true);
          }
        } else if (GRID_SELECT_TYPE_COLUMNS.includes(params?.field)) {
          setIsTableDirty(true);
        } else if (
          !isTableDirty &&
          (params?.field == 'origin' || params?.field == 'destination') &&
          currentRow[params?.field] &&
          val?.city != currentRow[params?.field]?.value?.city &&
          val?.state != currentRow[params?.field]?.value?.state
        ) {
          setIsTableDirty(true);
        }
        if (validator) {
          const errMessage = validator(params?.field, val, params);
          newRows[index] = {
            id: params.id,
            ...rows.find((row) => row.id === params.id),
            [params.field]: {
              value: val || '',
              isInvalid: Boolean(errMessage),
              message: errMessage,
            },
          };
          if (
            params?.field == 'minMiles' &&
            val &&
            params?.row?.maxMiles?.value
          ) {
            const maxMileErr = validateMaxMile(
              params?.row?.maxMiles?.value,
              val
            );
            newRows[index] = {
              ...newRows[index],
              ['maxMiles']: {
                value: params?.row?.maxMiles?.value,
                isInvalid: Boolean(maxMileErr),
                message: maxMileErr,
              },
            };
          }
          if (isAccessorialTable && params.field == 'rateType' && val) {
            let unit = '';
            const unitTypes = getUnitTypes(staticData, val);
            if (unitTypes && unitTypes?.length > 0) {
              unit = unitTypes[0];
            }
            let unitAndAutomateData = {
              ['unit']: {
                value: unit,
                isInvalid: false,
                message: '',
              },
              ['fuelSurchargeName']: {
                value: '',
                isInvalid: false,
                message: '',
              },
            };
            if (unit && !AUTOMATE_UNIT_VAL.includes(unit)) {
              unitAndAutomateData = {
                ...unitAndAutomateData,
                ['autoPopulate']: {
                  value: false,
                  isInvalid: false,
                  message: '',
                },
              };
            }
            if (unit && currentRow?.rate?.value) {
              const rateErrMessage = validateAccessorialRate(
                currentRow?.rate?.value,
                unit
              );
              unitAndAutomateData = {
                ...unitAndAutomateData,
                ['rate']: {
                  value: currentRow?.rate?.value,
                  isInvalid: Boolean(rateErrMessage),
                  message: rateErrMessage,
                },
              };
            }
            newRows[index] = {
              ...newRows[index],
              ...unitAndAutomateData,
            };
          }
          if (
            params.field === 'fuelSurchargeName' ||
            params.field === 'rateType'
          ) {
            newRows[index] = {
              ...newRows[index],
              ['rate']: {
                value: '',
                isInvalid: false,
                message: '',
              },
            };
          }
          if (params.field == 'unit' && val) {
            if (!AUTOMATE_UNIT_VAL.includes(val)) {
              newRows[index] = {
                ...newRows[index],
                ['autoPopulate']: {
                  value: false,
                  isInvalid: false,
                  message: '',
                },
              };
            }
            if (currentRow?.rate?.value) {
              const rateErrMessage = validateAccessorialRate(
                currentRow?.rate?.value,
                val
              );
              newRows[index] = {
                ...newRows[index],
                ['rate']: {
                  value: currentRow?.rate?.value,
                  isInvalid: Boolean(rateErrMessage),
                  message: rateErrMessage,
                },
              };
            }
            newRows[index] = {
              ...newRows[index],
              ['fuelSurchargeName']: {
                value: '',
                isInvalid: false,
                message: '',
              },
              ['rate']: {
                value: '',
                isInvalid: false,
                message: '',
              },
            };
          }
          const unit = params.unit?.value ?? params.row?.unit.value;
          if (
            params?.field == 'rate' &&
            ['PRICE_PER_MILE_MATRIX', 'PERCENT_MATRIX'].includes(unit)
          ) {
            newRows[index] = {
              ...newRows[index],
              ['rate']: {
                value: '',
                isInvalid: false,
                message: '',
              },
            };
          }
        }
        if (JSON.stringify(rows) !== JSON.stringify(newRows))
          setIsTableDirty(true);
        setRows(newRows);
      }
    },
    [rows, setRows, validator]
  );

  const handleCellEditStart = (params: GridCellEditStartParams) => {
    setCellOldValue({ field: params?.field, value: params?.value?.value });
  };

  const handleOnCellClick = (params) => {
    if (params?.field === 'remove') {
      const newRows = [...rows];
      const index = newRows.findIndex((row) => row?.id === params?.id);
      newRows.splice(index, 1);
      setRows(newRows);
      setIsTableDirty(true);
      return;
    }
    if (params?.field == 'modifiedDate') return;
    if (params?.field == 'autoPopulate') {
      if (
        params?.row?.unit &&
        AUTOMATE_UNIT_VAL.includes(params?.row?.unit?.value)
      ) {
        setIsTableDirty(true);
        const newRows = [...rows];
        const index = newRows.findIndex((row) => row?.id === params?.id);
        newRows[index] = {
          ...rows.find((row) => row.id === params?.id),
          [params?.field]: {
            value: Boolean(!params?.value?.value),
            isInvalid: false,
            message: '',
          },
        };
        setRows(newRows);
      }
      return;
    }
    tableRef?.current?.setCellMode?.(params.row.id, params.field, 'edit');
    if (isAccessorialTable && params?.field == 'rateType') {
      const newRows = [...rows];
      const index = newRows.findIndex((row) => row.id === params.id);
      const value = params?.value?.value;
      const errMessage = validator
        ? validator(params?.field, value, params)
        : '';
      newRows[index] = {
        id: params?.id,
        ...rows.find((row) => row.id === params?.id),
        [params.field]: {
          value: value || '',
          isInvalid: Boolean(errMessage),
          message: errMessage,
        },
      };
      setRows(newRows);
    }
    return;
  };

  const handleTabPress = (
    apiparams: GridCellParams,
    event: MuiEvent,
    details: GridCallbackDetails
  ) => {
    if (event?.code === 'Tab') {
      if (apiparams?.field === 'remove') {
        handleOnClickAddRow(laneInfoType, true);
      }
    }
    if (event?.code === 'Enter') {
      if (apiparams?.field === 'remove') {
        const newRows = [...rows];
        const index = newRows.findIndex((row) => row?.id === apiparams?.id);
        newRows.splice(index, 1);
        setRows(newRows);
        setIsTableDirty(true);
        return;
      }
      if (
        apiparams?.field === 'autoPopulate' &&
        apiparams?.row?.unit &&
        AUTOMATE_UNIT_VAL.includes(apiparams?.row?.unit?.value)
      ) {
        const newRows = [...rows];
        const index = newRows.findIndex((row) => row?.id === apiparams?.id);
        newRows[index] = {
          ...rows.find((row) => row.id === apiparams?.id),
          [apiparams?.field]: {
            value: Boolean(!apiparams?.value?.value),
            isInvalid: false,
            message: '',
          },
        };
        setRows(newRows);
        setIsTableDirty(true);
        return;
      }
    }
  };
  return (
    <Stack spacing={1} direction="column">
      <Box
        sx={{
          width: '100%',
          maxHeight: '20rem',
        }}
      >
        <CommonDataGrid
          showFocus={true}
          componentsProps={{
            cell: {
              tabIndex: 1,
            },
          }}
          onCellKeyDown={handleTabPress}
          onCellEditStop={handleCellEditCommit}
          {...(tableRef && { apiRef: tableRef })}
          hideFooter
          editMode="cell"
          autoHeight={true}
          onCellEditCommit={handleCellEditCommit}
          handleCellEditStart={handleCellEditStart}
          onCellClick={handleOnCellClick}
          columns={columns}
          rows={rows}
          sortingMode="server"
          disableColumnMenu
          containerStyles={{
            width: '100%',
            height: '100%',
            maxHeight: '20rem',
            overflowY: 'scroll',
          }}
          slots={{
            NoRowsOverlay: () => <NoRowOverlay />,
          }}
        />
      </Box>
      <Button
        size="small"
        onClick={() => handleOnClickAddRow(laneInfoType)}
        sx={{
          flex: 1,
          mt: 1,
          width: '100%',
          backgroundColor: '#EEEEEE',
          color: '#212121',
        }}
        startIcon={<AddIcon color="#212121" />}
      >
        Add New {itemType}
      </Button>
    </Stack>
  );
};

export default LaneItemsTable;
