import { Box, Skeleton, Stack } from '@mui/material';
import CustomViews from '../common/Views/customViewsV2';
import { useStores } from '../store/root.context';
import { ComponentType, GridColDefSelf, View } from '../types';
import { AddIcon, FileTypeIcon } from '../ui-kit/components/Assets';
import ButtonImproved from '../ui-kit/components/ButtonImproved';
import { StopSolutionV3Prop } from '../ui-kit/components/TripPlan';
import ViewForm from '../views/myLoads/components/ViewForm';
import {
  defaultSortName,
  defaultViews,
  formDefaultValuesMap,
  loadView,
} from '../views/myLoads/components/ViewSection/constants';

import { BulkLoadCustomTagsDialog } from '@/views/customTags/customTagChip';
import { GridEventListener, GridSortModel } from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { EventTypes } from '../EventEmitter/EventTypes';
import { EventHoldLoadActionData } from '../EventEmitter/Events/EventHoldLoadAction';
import { LoadTripActionData } from '../EventEmitter/Events/EventLoadTripAction';
import { IEventTripAssignmentData } from '../EventEmitter/Events/EventTripAssignment';
import { EventTripStopActionData } from '../EventEmitter/Events/EventTripStopAction';
import { ServiceError } from '../api/interfaces';
import AddNewButton from '../common/AddNewButton/AddNewButton';
import CommonDataGrid from '../common/DataGridPro';
import { DirtyDetailsPanelManager } from '../common/DetailsPanel/utils';
import { LoadTabPanel } from '../common/LoadTabPanel';
import {
  ELoadStatus,
  EMyLoadDetailsPanelTabs,
} from '../common/LoadTabPanel/constants/constants';
import EmptyPage from '../common/Pages/EmptyPage';
import { AXELE_PERMISSION_TYPE, Permission } from '../common/Permission';
import SecondaryDetailsPanel from '../common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import { getSortOption } from '../common/Sorting/sortingUtils';
import TableDataContainer from '../common/Wrappers/TableDataContainer';
import { useFilterDataGrid } from '../common/hooks/useFilterDataGrid';
import { useLoadPermission } from '../common/hooks/useLoadPermission';
import { useThemeResponsive } from '../common/hooks/useThemeResponsive';
import { persistUserState } from '../constants';
import { IMPORT_LOAD_NAME } from '../contexts/ImportContext';
import {
  GetRemoveLoadManifestDataResp,
  GetUpdatedManifestDataRequest,
  LoadDetailsResponse,
} from '../models';
import { PaymentDetailsDTO } from '../models/DTOs/Payment/DTOs';
import useFilterStorage from '../services/storage';
import { useRootStore } from '../store/root-store/rootStateContext';
import CreateLoadPanel from '../subPages/loadsList/CreateLoad';
import LoadController from '../subPages/loadsList/LoadController';
import { LoadDetailsSummary } from '../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { getCreateLoadPanelFormDefaultData } from '../subPages/loadsList/LoadDetailsPanel/utils';
import CreateManifestModel from '../subPages/loadsList/Manifest/CreateManifest';
import {
  IPayloadIdsType,
  manifestSelectionRules,
} from '../subPages/loadsList/Manifest/utils';
import { FOnItemClick, columns } from '../subPages/loadsList/constants';
import { useLoadListGridActions } from '../subPages/loadsList/hook/useLoadListGridActions';
import { LoadSummary } from '../subPages/loadsList/models/LoadSummary';
import { FiltersWrapper } from '../ui-kit/components/DataGridPro/filter/FiltersWrapper';
import { isHasPermission } from '../utils';
import { downloadFile } from '../utils/Doc';
import { getGridColumnsLoad } from '../utils/grid.utils';
import { onColumnOrderChange } from '../utils/gridColumnOrdering';
import useEmit from '../utils/hooks/useEmit';
import { ESecondaryDetailsPanelType } from '../views/dispatch2/constants/types';
import PaymentDetailsPanel from '../views/finance/paymentManagement/components/PaymentDetailsPanel/PaymentDetailsPanel';
import { getLoadFiltersFormConfig } from '../views/myLoads/components/LoadViewForms';
import { tripFieldsConfig } from '../views/trips/constants/constants';

const openLinksSettings: { [x: string]: ESecondaryDetailsPanelType } = {
  dispatchers: ESecondaryDetailsPanelType.USER,
  tractors: ESecondaryDetailsPanelType.TRACTOR,
  trailers: ESecondaryDetailsPanelType.TRAILER,
  driverGroups: ESecondaryDetailsPanelType.DISPATCH,
  trips: ESecondaryDetailsPanelType.TRIP,
  carrierDetails: ESecondaryDetailsPanelType.CARRIER,
  brokerageTrip: ESecondaryDetailsPanelType.TRIP,
};

export const LoadList = () => {
  const {
    myLoadStore: {
      component,
      addNewClicked,
      setAddNewClicked,
      currentView,
      currentView: { id: stripId },
      updatedLoadId,
      setUpdatedLoadId,
      loadCount,
      setLoadCount,
      filters,
      setFilters,
      pageIsLoading,
      dictionary,
      getLoadList,
      setCurrentView,
      getUpdatedList,
      tableData,
      deleteLoadId,
      updateLoadsListByNewLoad,
      isLoading,
      getManifestUpdatedList,
      updateMainData,
      handleHoldOrReleaseLoadLocally,
      createLoadDialogOpen,
      setCreateLoadDialogOpen,
    },
    invoiceStore: { paymentPanel, setPaymentPanel, setPaymentUpdated },
  } = useStores();
  useEffect(() => {
    if (paymentPanel) setPaymentPanel(null);
  }, []);
  const {
    onUploadHandler,
    onAddHandler,
    canUploadPermission,
    canAddPermission,
  } = useLoadListGridActions();

  const handleCreateLoadDialogOpen = () => {
    setCreateLoadDialogOpen(true);
  };
  const handleCreateLoadDialogClose = () => {
    setCreateLoadDialogOpen(false);
  };

  const { isMobile, isTablet } = useThemeResponsive();

  const { getIsTerminalEnabled, getGlobalTerminals, getCompanyPreferences } =
    useRootStore();
  const [tableColumns, setTableColumns] = useState<GridColDefSelf[]>([]);
  const [allColumns, setAllColumns] = useState<GridColDefSelf[]>([]);

  const [totalRevenue, setTotalRevenue] = useState<number>(0);

  const [newLoad, setNewLoad] = useState<LoadDetailsSummary>(
    {} as LoadDetailsSummary
  );
  const [loadTabPanelState, setLoadTabPanelState] = useState({
    open: false,
    expanded: false,
  });
  const [tab, setTab] = useState<EMyLoadDetailsPanelTabs | undefined>();
  const [panelItem, setPanelItem] = useState<LoadSummary | null>(null);
  const [manifestItem, setManifestItem] = useState<LoadSummary | null>(null);
  const [secondaryPanelData, setSecondaryPanelData] = useState<{
    type: keyof ComponentType;
    subType?: keyof ComponentType;
    subId?: string | number;
    id: string | number;
    loadId?: string;
  } | null>(null);
  const { gridBottomMenu } = useLoadListGridActions();

  const total = LoadController.instance().total;
  const defaultFilters =
    persistUserState.pages.MyLoads[stripId]?.filters || currentView.metaData;

  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};

  const [manifestIds, setManifestIds] = useState<IPayloadIdsType | null>(null);
  const [manifestWarningList, setManifestWarning] = useState<Array<string>>([]);
  const [manifestCreated, setManifestCreated] = useState<Array<string>>([]);
  const [sortRequestData, setSortRequestData] = useState({});
  const [isManifestModel, setIsManifestModel] = useState<boolean>(false);
  const [isLoadingRevenue, setIsLoadingRevenue] = useState<boolean>(false);
  const [tagLoadRecords, settagLoadRecords] = useState<any | null>(null);
  const onResetColVisibility = () => {
    resetSettings();
  };
  const [tagstWarningList, setTagsWarning] = useState<Array<string>>([]);
  const handleLinkItemClick: FOnItemClick = (item, data) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    setLoadTabPanelState({ open: false, expanded: false });
    setPanelItem({} as LoadSummary);
    setSecondaryPanelData({
      type: openLinksSettings[data.field as keyof typeof openLinksSettings],
      id: item.id,
      loadId: data.id,
    });
  };
  const [isTagModel, setTagModel] = useState<boolean>(false);

  const {
    hasFinancialInvoicesView,
    hasLoadViewPermission,
    hasCarrierViewPermission,
  } = useLoadPermission();

  const loadColumns = useMemo(() => {
    return getGridColumnsLoad(
      columns(handleLinkItemClick, getCompanyPreferences?.timezone),
      getIsTerminalEnabled,
      hasFinancialInvoicesView,
      hasCarrierViewPermission
    );
  }, [
    getIsTerminalEnabled,
    hasFinancialInvoicesView,
    getCompanyPreferences?.timezone,
    hasCarrierViewPermission,
  ]);

  const {
    onPageFilterChange,
    setDefaultColumns,
    onAllFilterSubmit,
    onAllFilterReset,
    resetSettings,
    handleChangeColumns,
  } = useFilterDataGrid({
    component,
    allColumns: loadColumns as GridColDefSelf[],
    currentView,
    setFilters,
    setTableColumns,
    setAllColumns,
    defaultFilters,
    currentViewPrimaryKey: 'id',
  });

  const debouncedScroll = debounce(() => {
    if (!tableData.length) return;
    getLoadList(
      {
        ...filters,
        terminals: getGlobalTerminals,
        calculateDetention:
          getCompanyPreferences?.calculateDetention &&
          filters.calculateDetention,
      },
      stripId,
      false
    );
  }, 100);
  // @TODO: Initialise loacalStorage query with sort property
  const setDefaultSorting = () => {
    const sort = storageData[stripId]?.query?.sort;
    if (sort) {
      setSortRequestData({ sort });
    }
  };

  const setDefaultFilters = (currentView: View) => {
    const storageData = LStorageHook.getStorage() || {};
    const defFilters = !currentView.default
      ? currentView.metaData
      : storageData[stripId].filters;
    setFilters?.(defFilters);
  };

  useEffect(() => {
    setDefaultFilters(currentView);
    setDefaultColumns();
    setDefaultSorting();
  }, [currentView.id]);

  useEffect(() => {
    setDefaultColumns();
  }, [loadColumns]);

  useEffect(() => {
    if (updatedLoadId) {
      if (panelItem?.loadType === 'FTL') {
        getUpdatedList(updatedLoadId, (loads) => {
          setUpdatedLoadId(undefined);
        });
      } else {
        findManifestRelatedLoad?.(updatedLoadId);
      }
    }
  }, [updatedLoadId]);

  useEffect(() => {
    LoadController.instance().getLoadsCount((count) => {
      if (typeof count === 'number') setLoadCount(count);
    });
  }, []);

  const handleTripAssignmentChange = (data: IEventTripAssignmentData) => {
    if (!data.trip.loadId) return;
    if (panelItem?.loadType === 'FTL') {
      getUpdatedList(data.trip.loadId);
    } else {
      findManifestRelatedLoad?.(data.trip.loadId);
    }
    if (data.isStatusUpdated) {
      //Don't change @Sinh: any Trip assignment changes then need to refresh the Load Data Grid
      refreshCurrentRow();
    }
  };

  const handleLoadTripAction = (data: LoadTripActionData) => {
    if (!data.loadId) return;
    if (panelItem?.loadType === 'FTL') {
      getUpdatedList(data.loadId);
    } else {
      findManifestRelatedLoad?.(data.loadId);
    }
  };
  const handleTripStopAction = (data: EventTripStopActionData) => {
    if (!panelItem?.id) return;
    if (panelItem?.loadType === 'FTL') {
      getUpdatedList(panelItem.id, (loads) => {
        setPanelItem(loads.find(({ id }) => id === panelItem.id) || null);
      });
    } else {
      findManifestRelatedLoad?.(panelItem.id);
    }
  };

  const handleHoldOrReleaseLoad = (data: EventHoldLoadActionData) => {
    //Hold/Release Load then refresh loadIds row
    handleHoldOrReleaseLoadLocally(data);
    updateMainData({ updatedIds: data.loadIds });
  };

  useEmit({
    [EventTypes.TRIP_ASSIGNMENT_CHANGE]: handleTripAssignmentChange,
    [EventTypes.LOAD_TRIP_ACTION]: handleLoadTripAction,
    [EventTypes.TRIP_STOP_ACTION]: handleTripStopAction,
    [EventTypes.HOLD_LOAD]: handleHoldOrReleaseLoad,
  });

  useEffect(() => {
    if (loadTabPanelState.open && panelItem) {
      const newPanelItem = tableData.find((data) => data.id === panelItem.id);
      if (newPanelItem) setPanelItem(newPanelItem);
    }
  }, [tableData]);

  const fetchTotalRevenue = async () => {
    if (!isLoadingRevenue) {
      setIsLoadingRevenue(true);
      const revenue = await LoadController.instance().getTotalRevenue();
      setTotalRevenue(revenue);
      setIsLoadingRevenue(false);
    }
  };

  useEffect(() => {
    return () => {
      setFilters({});
    };
  }, []);

  // @TODO: Anna and Anoush this approach will be changed
  useEffect(() => {
    if (Object.keys(filters).length) {
      fetchFullData();
      if (!filters.paging) {
        fetchTotalRevenue();
      }
    }
  }, [filters, getGlobalTerminals?.length]);

  useEffect(() => {
    if (addNewClicked) {
      setNewLoad(getCreateLoadPanelFormDefaultData());
    }
    setAddNewClicked(false);
  }, [addNewClicked, setAddNewClicked]);

  useEffect(() => {
    return () => {
      debouncedScroll.cancel();
    };
  }, []);

  const onItemClicked: GridEventListener<'rowClick'> = (params) => {
    const data = params.row;

    if (!hasLoadViewPermission || data.pinned) {
      return;
    }
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    setSecondaryPanelData(null); //close USER/TRACTOR/TRAILER Details Panel
    if (data) {
      setPanelItem(data);
      setLoadTabPanelState({ open: true, expanded: false });
    }
    return; //should be implemented handle select part for details panel opening
  };

  const handleSecondaryDetailsOpen = (data: any, event: any) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    closeLoadTabPanel();
    event.stopPropagation();
    setSecondaryPanelData(null); //close USER/TRACTOR/TRAILER Details Panel
    if (data) {
      if (data.field === 'customerName' && event.target.id) {
        setSecondaryPanelData({
          type: 'CUSTOMER',
          id: event.target.id,
          loadId: data?.row?.id,
        });
        return;
      }
      setPanelItem(data);
      setLoadTabPanelState({ open: true, expanded: false });
    }
  };

  const handleCreateLoad = async (load: LoadDetailsResponse | ServiceError) => {
    if ((load as LoadDetailsResponse)?.id) {
      setNewLoad({} as LoadDetailsSummary);
      setLoadTabPanelState({ open: true, expanded: false });
      setTab(EMyLoadDetailsPanelTabs.ROUTES);
      setPanelItem({ id: (load as LoadDetailsResponse)?.id } as LoadSummary);
      const loadSummary = await LoadController.instance().getLoadSummaryById(
        (load as LoadDetailsResponse)?.id!
      );
      updateLoadsListByNewLoad(loadSummary);
      setSecondaryPanelData(null);
      setLoadCount((count) =>
        typeof count === 'number' ? count + 1 : undefined
      );
    }
  };

  const fetchFullData = () => {
    getLoadList(
      {
        ...filters,
        ...sortRequestData,
        terminals: getGlobalTerminals,
        calculateDetention:
          getCompanyPreferences?.calculateDetention &&
          filters.calculateDetention,
      },
      stripId,
      true
    );
  };

  const exportToExcelHandler = async () => {
    const excelDoc = await LoadController.instance().exportLoadList(
      {
        ...filters,
        terminals: getGlobalTerminals,
        calculateDetention:
          getCompanyPreferences?.calculateDetention &&
          filters.calculateDetention,
      },
      tableColumns
    );
    return excelDoc instanceof ServiceError ? null : downloadFile(excelDoc);
  };
  const tableConfigurationProps = useMemo(
    () => ({
      TableSettings: {
        handleChangeForColumnModel: handleChangeColumns,
        tableColumnsData: allColumns.slice(1) || allColumns,
        getExcel: exportToExcelHandler,
        resetSettings: onResetColVisibility,
        downloadFile: downloadFile,
      },
    }),
    [
      allColumns,
      exportToExcelHandler,
      handleChangeColumns,
      onResetColVisibility,
    ]
  );

  const closeLoadTabPanel = () => {
    setLoadTabPanelState({ open: false, expanded: false });
  };

  const onCloseCommonPanel = (): void => {
    closeLoadTabPanel();
    setTab(undefined);
  };

  const resetSecondaryDetailsPanel = (): void => {
    setSecondaryPanelData(null);
  };

  const openSecondaryDetailsHandler = (
    id: string,
    type: ESecondaryDetailsPanelType
  ) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    resetSecondaryDetailsPanel();
    setSecondaryPanelData({
      type: type,
      id: id,
    });
  };

  if (allColumns.length === 0) {
    return <></>;
  }

  const getFiltersFormConfig = (): any[] => {
    return getLoadFiltersFormConfig().filter((item: any) => {
      if (item.name === tripFieldsConfig.brokerageTrip.fieldFilterName)
        return hasCarrierViewPermission;
      if (
        item.name === tripFieldsConfig.carrierDetails.fieldFilterName ||
        item.name === tripFieldsConfig.canBeBrokered.fieldFilterName
      )
        return hasCarrierViewPermission;
      return true;
    });
  };

  function handleSortChanged(model: GridSortModel) {
    const sortOptions = getSortOption(model, defaultSortName);

    getLoadList(
      {
        ...filters,
        sort: sortOptions,
        terminals: getGlobalTerminals,
        calculateDetention:
          getCompanyPreferences?.calculateDetention &&
          filters.calculateDetention,
      },
      stripId,
      true
    );

    LStorageHook.updateStorage(stripId, {
      ...storageData[stripId],
      query: { ...storageData[stripId].query, sort: sortOptions },
    });
  }

  const handleClose = () => {
    setPanelItem(null);
  };
  const manifestLoadDelete = (panelItem: LoadSummary) => {
    const updateList = tableData?.filter((s) => s['id'] != panelItem['id']);
    const payLoad = new GetUpdatedManifestDataRequest({
      loadIds: panelItem?.manifestLoadIds,
    });
    updateLoadsListByNewManiFestLoad(payLoad, updateList);
  };

  const handleAction = (
    id: string,
    action: ELoadStatus | undefined,
    seq: string,
    data: any
  ) => {
    if (action === ELoadStatus.DELETED) {
      if (panelItem?.loadType === 'MANIFEST') {
        manifestLoadDelete(panelItem);
      }
      if (
        panelItem?.loadType === 'LTL' &&
        panelItem?.manifestBaseDetails?.manifestId
      ) {
        findManifestRelatedLoad?.(panelItem?.manifestBaseDetails?.manifestId);
      }
      deleteLoadId(panelItem?.id);
      setLoadCount((count) => (count ? count - 1 : undefined));
      setLoadTabPanelState({
        open: false,
        expanded: false,
      });
      handleClose();
    } else {
      if (
        panelItem?.loadType === 'LTL' &&
        panelItem?.manifestBaseDetails?.manifestId
      ) {
        if (data?.manifestRemoved) {
          deleteLoadId(panelItem?.manifestBaseDetails?.manifestId);
        } else {
          findManifestRelatedLoad?.(panelItem?.manifestBaseDetails?.manifestId);
        }
      } else {
        findManifestRelatedLoad?.(panelItem?.id);
      }
      setUpdatedLoadId(panelItem?.id);
    }
  };

  const noPermissionForImport = !isHasPermission([
    AXELE_PERMISSION_TYPE.IMPORT_DATA,
  ]);

  const onRowsSelectionHandler = (data: Array<string>) => {
    setManifestCreated(data);
    setManifestIds(null);
    setManifestItem(null);
    if (!data.length) return;
    const filterWarnigObject = manifestSelectionRules(data, tableData);

    setTagsWarning(filterWarnigObject.tagWaring);
    settagLoadRecords(filterWarnigObject.filterData);
    const mainfestRules = filterWarnigObject.payLoadIds;
    const warningList: Array<string> = [];
    Object.values(mainfestRules.warningObject).forEach((key) =>
      warningList.push(key)
    );
    if (warningList.length > 0) {
      setManifestWarning(warningList);
      setManifestIds(null);
    } else {
      setManifestWarning([]);
      const selectedIdList = {
        manifestIds: mainfestRules.manifestIds,
        loadIds: mainfestRules.loadIds,
      };
      mainfestRules.manifestRecord &&
        setManifestItem(mainfestRules.manifestRecord);
      setManifestIds(selectedIdList);
    }
  };

  const manifestDeleteForLoad = (
    data: GetRemoveLoadManifestDataResp,
    status: boolean
  ) => {
    if (data.deleted) {
      handleAction(data.manifestId, ELoadStatus.DELETED);
      const payLoad = new GetUpdatedManifestDataRequest({
        loadIds: [data.loadId],
      });
      const updateddata = tableData.filter(function (item) {
        return item.id !== data.manifestId;
      });
      updateLoadsListByNewManiFestLoad(payLoad, updateddata);
      setLoadCount((count) => (count ? count - 1 : undefined));
      setLoadTabPanelState({
        open: false,
        expanded: false,
      });
      handleClose();
    } else {
      const payLoad = new GetUpdatedManifestDataRequest({
        loadIds: [data.loadId, data.manifestId],
      });
      updateLoadsListByNewManiFestLoad(payLoad, tableData);
    }
  };
  const updateLoadsListByNewManiFestLoad = async (
    payLoad: GetUpdatedManifestDataRequest,
    data: LoadSummary[] = tableData,
    idNewLoadAdd = false
  ): Promise<void> => {
    LoadController.instance().getUpdatedManifestLoad(
      payLoad,
      async (loads: LoadSummary[] | null) => {
        const loadList = loads?.map((item: LoadSummary) => {
          return new LoadSummary(item);
        });
        if (loadList && Boolean(loads?.length)) {
          await LoadController.instance().setupLoadWarnings(loadList);
          const updatedList = data?.map((el) => {
            const found = loadList?.find((s) => s['id'] === el['id']);
            if (found) {
              el = found;
            }
            return el;
          });
          getManifestUpdatedList(updatedList);
          if (!idNewLoadAdd) {
            LoadController.instance().syncLoads(updatedList);
          }
        }
      }
    );
  };

  const findManifestRelatedLoad = (id: string) => {
    let findRecord = tableData?.find((s) => s['id'] === id);
    if (
      findRecord?.loadType === 'LTL' &&
      findRecord?.manifestBaseDetails?.manifestId
    ) {
      findRecord = tableData?.find(
        (s) => s['id'] === findRecord?.manifestBaseDetails?.manifestId
      );
    }
    if (findRecord?.manifestBaseDetails?.manifestId) {
      const payLoad = new GetUpdatedManifestDataRequest({
        loadIds: findRecord?.manifestLoadIds?.concat(findRecord.id),
      });
      updateLoadsListByNewManiFestLoad(payLoad, tableData);
    }
  };
  const manifestLoadCreation = (
    data: LoadSummary,
    idList: IPayloadIdsType | null
  ) => {
    const listRecord = tableData;
    if (!idList) return;
    const payLoad = new GetUpdatedManifestDataRequest({
      loadIds: Boolean(idList?.manifestIds.length)
        ? idList.loadIds.concat(idList.manifestIds.join(','))
        : idList.loadIds.concat(data?.id),
    });
    setTab(EMyLoadDetailsPanelTabs.ROUTES);
    if (!Boolean(idList?.manifestIds.length)) {
      const newRecord = new LoadSummary(data);
      listRecord.unshift(newRecord);
      setPanelItem({ id: data.id } as LoadSummary);
      updateLoadsListByNewManiFestLoad(payLoad, listRecord, true);
    } else {
      setPanelItem({ id: idList?.manifestIds[0] } as LoadSummary);
      updateLoadsListByNewManiFestLoad(payLoad, listRecord);
    }
    setLoadTabPanelState({ open: true, expanded: false });

    setManifestIds(null);
    setManifestCreated([]);
  };

  const refreshCurrentRow = () => {
    if (panelItem?.id && !panelItem?.manifestBaseDetails?.manifestId) {
      const updatedLoadIds: string[] = [panelItem.id];
      panelItem?.manifestLoadIds?.forEach((loadId) => {
        loadId && updatedLoadIds.push(loadId);
      });
      updateMainData({ updatedIds: updatedLoadIds });
    }
  };

  const onUpdatedLoadTabPanel = () => {
    refreshCurrentRow();
  };

  const isGridLoading = isLoading || pageIsLoading;
  return (
    <>
      {/* <TagColorChips /> */}
      <Stack direction="column" height="100%">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '> div > div': {
              pl: 0,
            },
          }}
        >
          <CustomViews
            entities={['load']}
            component={component}
            dictionary={dictionary}
            defaultViews={defaultViews}
            formDefaultValuesMap={formDefaultValuesMap}
            viewForm={ViewForm}
            defaultView={loadView}
            modalTitle={'Create an Load View'}
            currentView={currentView}
            setCurrentView={(view: View) => {
              setCurrentView(view);
            }}
          />
          {!isMobile && (
            <Stack direction="row" spacing={1}>
              {canUploadPermission && (
                <ButtonImproved
                  style={{
                    height: '2rem',
                  }}
                  label={<FileTypeIcon height={27} />}
                  onClick={(...params) => {
                    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
                    onUploadHandler(...params);
                  }}
                />
              )}

              <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
                <AddNewButton
                  style={{
                    height: '2rem',
                    borderRadius: '8px',
                  }}
                  title="Load"
                  onClick={(...params) => {
                    // ACTION FOR CREATE LOAD V2
                    setCreateLoadDialogOpen(true);
                    // ACTION FOR CREATE LOAD V1
                    // if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
                    // onAddHandler(...params);
                  }}
                  startIcon={<AddIcon color="#fff" />}
                />
              </Permission>
            </Stack>
          )}
        </Box>

        {currentView && currentView.entity === 'load' && (
          <>
            <TableDataContainer key={stripId}>
              <Box display="flex" flexDirection={'row'} width={'100%'}>
                {!pageIsLoading && loadCount === 0 ? (
                  <EmptyPage
                    title="oh fudge."
                    body="You have no loads in LoadOps. Why don’t we change that?"
                    buttonLabel="Import  Instead"
                    entity={IMPORT_LOAD_NAME}
                    disabled={noPermissionForImport}
                  />
                ) : (
                  <Stack
                    className="Grid"
                    width={
                      loadTabPanelState.open ? 'calc(100% - 500px)' : '100%'
                    }
                    sx={{}}
                  >
                    <FiltersWrapper
                      filterColumns={getFiltersFormConfig()}
                      onAllFilterSubmit={onAllFilterSubmit}
                      onAllFilterReset={onAllFilterReset}
                      onPageFilterChange={onPageFilterChange}
                      defaultFilterValue={filters}
                      displayFilters={true}
                      pageName={''}
                      tableConfigurationProps={tableConfigurationProps}
                      settingsPanel={true}
                      gridBottomMenu={gridBottomMenu}
                      panelState={
                        !!loadTabPanelState.open || !!secondaryPanelData
                      }
                      filterPerCol={
                        isTablet
                          ? [Math.ceil(getFiltersFormConfig().length / 2)]
                          : [8]
                      }
                    />

                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        '> div': {
                          ml: 0,
                          height: '100%',
                        },
                      }}
                    >
                      <CommonDataGrid
                        disableColumnMenu
                        loading={isGridLoading}
                        onSortModelChange={handleSortChanged}
                        handleSelectItem={onItemClicked}
                        columns={allColumns?.filter(
                          ({ field }) =>
                            field !== tripFieldsConfig.onHoldState.fieldName
                        )}
                        rows={tableData}
                        rowsCaching={false}
                        onRowsScrollEnd={debouncedScroll}
                        onColumnOrderChange={(params, event, details) =>
                          onColumnOrderChange(
                            {
                              params,
                              event,
                              details,
                            },
                            component,
                            stripId
                          )
                        }
                        onCellClick={(row, event) =>
                          handleSecondaryDetailsOpen(row, event)
                        }
                        // colVisibilityModel={colVisibility}
                        totalNumberOfRows={total}
                        rowHeight={54}
                        sortingMode="server"
                        pinnedRows={{
                          bottom: [
                            {
                              id: Math.random(),
                              warning: {},
                              revenue: totalRevenue,
                              pinned: true,
                            },
                          ],
                        }}
                        getRowClassName={(params) => params.row}
                        disableSelectionOnClick
                        checkboxSelection={canAddPermission}
                        selectRow={onRowsSelectionHandler}
                        selectionModel={manifestCreated}
                        bulkAction={[
                          {
                            iconName: 'AddBoxOutlined',
                            iconSource: 'MUIcons',
                            label: 'Add to Manifest',
                            disabled: Boolean(manifestWarningList.length),
                            customeTootTip: manifestWarningList,
                            onClick: () => {
                              setIsManifestModel(true);
                            },
                          },
                          {
                            iconName: 'LocalOfferOutlined',
                            iconSource: 'MUIcons',
                            label: 'Edit Tags',
                            disabled: Boolean(tagstWarningList.length),
                            customeTootTip: tagstWarningList,
                            onClick: () => {
                              setTagModel(true);
                            },
                          },
                        ]}
                        slots={{
                          LoadingOverlay: LoadingSkeleton,
                        }}
                      />
                    </Box>
                  </Stack>
                )}
                {loadTabPanelState.open && panelItem && (
                  <Box height={'88vh'} mt={1}>
                    <LoadTabPanel
                      key={panelItem.id}
                      onClose={onCloseCommonPanel}
                      data={{ id: panelItem.id }}
                      customerId={
                        panelItem.customerId ? panelItem.customerId : 0
                      }
                      expanded={loadTabPanelState.expanded}
                      onAction={handleAction}
                      onUpdateActivitiesCompleted={() => {
                        if (panelItem?.loadType === 'FTL') {
                          setUpdatedLoadId(panelItem.id);
                        } else {
                          findManifestRelatedLoad?.(panelItem.id);
                        }
                      }}
                      onUpdated={onUpdatedLoadTabPanel}
                      onCreatedLoadHandler={handleCreateLoad}
                      openSecondaryDetailsHandler={openSecondaryDetailsHandler}
                      tab={tab}
                      onClickLoadIdHandler={(stopSolution) => {
                        const loadId = stopSolution?.loadDetails[0]?.loadId;
                        if (loadId) {
                          setPanelItem({
                            id: loadId,
                          });
                          setLoadTabPanelState({
                            open: true,
                            expanded: false,
                          });
                        }
                      }}
                      manifestDeleteForLoad={manifestDeleteForLoad}
                    />
                  </Box>
                )}
              </Box>
              {secondaryPanelData && (
                <Box height={'88vh'} mt={1}>
                  <SecondaryDetailsPanel
                    type={secondaryPanelData.type}
                    id={secondaryPanelData.id}
                    onClose={() => {
                      onCloseCommonPanel();
                      setSecondaryPanelData(null);
                    }}
                    onUpdated={(data) => {
                      const loadId = secondaryPanelData?.loadId;
                      if (loadId) {
                        getUpdatedList(loadId);
                      }
                    }}
                    isGlobal={false}
                    {...(secondaryPanelData.type ===
                    ESecondaryDetailsPanelType.TRIP
                      ? {
                          onClose: () => {
                            resetSecondaryDetailsPanel();
                          },
                          onClickLoadIdHandler: (
                            stopSolution: StopSolutionV3Prop
                          ) => {
                            if (DirtyDetailsPanelManager.isShouldPanelShake())
                              return;
                            const loadId = stopSolution?.loadDetails[0]?.loadId;
                            if (loadId) {
                              setSecondaryPanelData((prevState) => ({
                                ...prevState,
                                subType: ESecondaryDetailsPanelType.LOAD,
                                subId: loadId,
                              }));
                            }
                          },
                        }
                      : {})}
                  />
                </Box>
              )}
              {secondaryPanelData?.subType && (
                <SecondaryDetailsPanel
                  type={secondaryPanelData.subType}
                  id={secondaryPanelData.subId}
                  onClose={() => {
                    setSecondaryPanelData((prevState) => ({
                      ...prevState,
                      subType: '',
                      subId: '',
                    }));
                  }}
                  isGlobal={true}
                  onCreatedLoadHandler={handleCreateLoad}
                />
              )}
            </TableDataContainer>
            <CreateLoadPanel
              open={createLoadDialogOpen}
              onClose={handleCreateLoadDialogClose}
              onLoadCreated={handleCreateLoad}
            />

            {/* CREATE LOAD V1 Do NOT DELETE */}
            {/* {newLoad?.loadStatus && (
            <CreateLoadPanelProvider mode={ECreateLoadPanelModes.CREATE_LOAD}>
              <CreateLoadPaneV1
                data={newLoad}
                onCreated={handleCreateLoad}
                onClose={() => {
                  setNewLoad({} as LoadDetailsSummary);
                }}
              />
            </CreateLoadPanelProvider>
          )} */}
            {/* CREATE LOAD V1 */}
          </>
        )}
        {isManifestModel && (
          <CreateManifestModel
            isView={isManifestModel}
            loadIds={manifestIds}
            LoadItem={manifestItem}
            onModelClose={() => {
              setIsManifestModel(false);
            }}
            manifestCreated={manifestLoadCreation}
          />
        )}
        {paymentPanel?.open && (
          <PaymentDetailsPanel
            onCreated={(createdPayment: PaymentDetailsDTO) => {
              setPaymentUpdated(true);
              setPaymentPanel(null);
            }}
            onUpdated={(createdPayment: PaymentDetailsDTO) => {
              setPaymentUpdated(true);
              setPaymentPanel(null);
            }}
            {...(paymentPanel.id && {
              data: { id: paymentPanel.id },
            })}
            isGlobal={true}
            isInvoicePanel={true}
            onClose={() => {
              setPaymentPanel(null);
            }}
            on3DotMenuActionCallback={() => setPaymentUpdated(true)}
          />
        )}
        <BulkLoadCustomTagsDialog
          open={isTagModel}
          loads={tagLoadRecords}
          onClose={() => setTagModel(false)}
          onAction={(idList) => {
            const payLoad = new GetUpdatedManifestDataRequest({
              loadIds: idList,
            });
            updateLoadsListByNewManiFestLoad(payLoad);
            setManifestCreated([]);
            setTagModel(false);
          }}
        />
      </Stack>
    </>
  );
};
const LoadingSkeleton = () => {
  return (
    <Box
      sx={{
        height: 'max-content',
      }}
    >
      {[...Array(50)].map((_, key) => (
        <Skeleton key={key} variant="rectangular" sx={{ my: 2, mx: 1 }} />
      ))}
    </Box>
  );
};
export default observer(LoadList);
