import { Theme } from '@mui/material';
import { BREAKPOINT_TABLET } from '../hooks/useThemeResponsive';

interface IStyles {
  shake?: React.CSSProperties;
  wrapper: React.CSSProperties;
  fullscreen?: React.CSSProperties;
  panelWrapperCustomStyles?: React.CSSProperties;
}

export const styles: {
  expanded: IStyles;
  default: IStyles;
  global: IStyles;
} = {
  expanded: {
    shake: {
      zIndex: 1250,
      padding: '15px 25px',
      inset: 0,
      position: 'absolute',
      backdropFilter: 'blur(10px)',
    },
    wrapper: {
      position: 'relative',
      zIndex: 1251,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f12',
      flexGrow: 1,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      borderRadius: '18px',
      background: '#FFFFFF',
      height: '100%',
    },
    fullscreen: {
      position: 'relative',
      zIndex: 1251,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      borderRadius: '18px',
      background: 'rgb(53, 68, 107)',
    },
  },
  default: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      // height: '100%',
      width: '514px',
      minWidth: '514px',
      maxWidth: '514px',
      boxShadow: '0px 1px 12px rgba(78, 101, 120, 0.15)',
      borderRadius: '18px',
      background: '#FFFFFF',
      marginLeft: '1rem',
      marginRight: '0',
    },
  },
  global: {
    wrapper: {
      zIndex: 1250,
      margin: '0 auto',
      paddingTop: '20px',
      paddingBottom: '20px',
      inset: '0',
      position: 'fixed',
      backdropFilter: 'blur(10px)',
      height: '100vh',
    },
    panelWrapperCustomStyles: {
      // margin: '20px auto',
      margin: 'auto',
    },
  },
};

export const customizeDetailsPanelStyles = (theme: Theme) => ({
  wrapper: {
    [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
      position: 'fixed',
      zIndex: 1300,
      inset: '0px',
      margin: 0,
      minWidth: 'unset',
      maxWidth: 'unset',
      width: '100%',
      height: '100vh',
    },
  },
});
