import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  HeadsetOffOutlined,
  UpdateOutlined,
} from '@mui/icons-material';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import { ComponentType, FC, useMemo, useState } from 'react';
import {
  DetailsPanelThreeDotMenuProps,
  IDetailsPanelThreeDotMenuOption,
} from '../../../../../common/DetailsPanel/components/ThreeDotMenu';
import { ELoadStatus } from '../../../../../common/LoadTabPanel/constants/constants';
import {
  MarkProgressTimeoffDialog,
  TimeoffDialog,
} from '../../../../../common/TimeoffDialog';
import {
  defaultTimeoffFormData,
  ITimeoffDialogData,
  TimeoffFormDataProps,
} from '../../../../../common/TimeoffDialog/types';
import { IDriverLoadsGroupAssignment } from '../../../../../models/DTOs/Dispatch/Dispatch';
import { IDispatchDriverTimelineTrip } from '../../../../../models/DTOs/Dispatch/Timeline';
import TimeoffDateTime from '../../../../../utils/timeoffDateTime';
import {
  EDispatch3DotMenuType,
  IActionTimeOffBaseProps,
} from '../../../constants/types';
import DispatchController from '../../../utils/Controller';

interface WithActionTimeOffProps extends IActionTimeOffBaseProps {
  data: IDispatchDriverTimelineTrip;
  loadData: ITimeoffDialogData;
  driverGroupId: string;
}

const withActionTimeOff = <P,>(Component: ComponentType<P>) => {
  const EnhancedComponent: FC<P & WithActionTimeOffProps> = (props) => {
    const { data, loadData, hocOnAction } = props;
    const timeoffFormData: TimeoffFormDataProps = {
      ...defaultTimeoffFormData,
      location: data?.stops[0]?.location,
      previousLoad: {
        id: data?.previousTripId,
      },
      ...TimeoffDateTime.fromAppointmentDateToTimeoffDate({
        appointmentStartDate: data?.stops[0]?.appointmentStartDate,
        appointmentEndDate: data?.stops[0]?.appointmentEndDate,
        timezone: data?.stops[0]?.location?.timezone,
      }),
      id: data.id,
    };

    //Panel3DotMenuHeaderProps
    const [current3DotMenu, setCurrent3DotMenu] =
      useState<EDispatch3DotMenuType | null>(null);

    const onCloseMenu = () => {
      setCurrent3DotMenu(null);
    };

    const generateMenuOptions = useMemo(() => {
      const menuOptions: IDetailsPanelThreeDotMenuOption[] = [];
      if (
        data.status === ELoadStatus.OFFERED_TO_DRIVER &&
        !(
          data.applicableActions?.includes('AVAILABLE') &&
          data.applicableActions?.length == 1
        )
      ) {
        menuOptions.push({
          label: 'Activate Time Off',
          icon: CheckCircleOutlined,
          onClickHandler: (): void => {
            setCurrent3DotMenu(EDispatch3DotMenuType.ACTIVATE_TIMEOFF);
          },
        });
      }
      if (data.status === ELoadStatus.DISPATCHED) {
        menuOptions.push({
          label: 'Revert Time Off',
          icon: HeadsetOffOutlined,
          onClickHandler: (): void => {
            setCurrent3DotMenu(EDispatch3DotMenuType.REVERT_TIMEOFF);
          },
        });
      }

      if (
        [ELoadStatus.DISPATCHED, ELoadStatus.IN_TRANSIT].includes(data.status)
      ) {
        menuOptions.push({
          label: 'Mark progress',
          icon: UpdateOutlined,
          onClickHandler: (): void => {
            setCurrent3DotMenu(EDispatch3DotMenuType.MARK_PROGRESS_TIMEOFF);
          },
        });
      }

      if (
        [
          ELoadStatus.OFFERED_TO_DRIVER,
          ELoadStatus.DISPATCHED,
          ELoadStatus.IN_TRANSIT,
        ].includes(data.status)
      ) {
        menuOptions.push({
          label: 'Edit',
          icon: EditOutlined,
          onClickHandler: (): void => {
            setCurrent3DotMenu(EDispatch3DotMenuType.EDIT_TIMEOFF);
          },
        });
      }

      if (data.status === ELoadStatus.OFFERED_TO_DRIVER) {
        menuOptions.push({
          label: 'Delete',
          icon: DeleteOutlined,
          onClickHandler: (): void => {
            setCurrent3DotMenu(EDispatch3DotMenuType.DELETE_TIMEOFF);
          },
        });
      }
      return menuOptions;
    }, [data?.status]);

    const panel3DotMenuHeaderSettings: DetailsPanelThreeDotMenuProps = {
      menuOptions: generateMenuOptions,
      showDeleteButton: false,
      styleProps: {
        color: 'primary.contrast',
      },
    };
    //Panel3DotMenuHeaderProps

    const onDeleteHanlder = async (): Promise<void> => {
      const response = await DispatchController.instance().deleteTimeoff({
        id: data.id,
      });
      if (response) {
        hocOnAction?.(current3DotMenu);
        onCloseMenu();
      }
    };

    const timeOffSetting = {
      activate: {
        applicableActions: ['AVAILABLE', 'DISPATCHED'],
        loadStatus: 'DISPATCHED',
      },
      revert: {
        applicableActions: ['OFFERED_TO_DRIVER', 'LOAD_COMPLETED'],
        loadStatus: 'OFFERED_TO_DRIVER',
      },
    };

    const getDriverLoadsGroupAssignment =
      async (): Promise<IDriverLoadsGroupAssignment | null> => {
        const driverLoadsGroupAssignment =
          await DispatchController.instance().getDriverLoadsGroupAssignment({
            groupId: loadData.driverGroupId,
          });
        if (driverLoadsGroupAssignment?.data) {
          const currrentLoadGroupAssignment =
            driverLoadsGroupAssignment.data?.find(
              (item) => item.id === data.id
            );
          return currrentLoadGroupAssignment;
        }
        return null;
      };

    const onRevertOrActivateTimeoffHandler = async (): Promise<void> => {
      const currentLoadGroupAssignment = await getDriverLoadsGroupAssignment();

      if (currentLoadGroupAssignment) {
        const response =
          await DispatchController.instance().revertOrActivateTimeoff({
            ...currentLoadGroupAssignment,
            ...(current3DotMenu === EDispatch3DotMenuType.ACTIVATE_TIMEOFF
              ? timeOffSetting.activate
              : timeOffSetting.revert),
          });
        if (response) {
          hocOnAction?.(current3DotMenu);
          onCloseMenu();
        }
      }
    };

    const renderChildPopup = () => {
      switch (current3DotMenu) {
        case EDispatch3DotMenuType.REQUEST_TIMEOFF:
          return (
            <TimeoffDialog
              canEditDate={true}
              loadData={loadData}
              onClose={onCloseMenu}
              timeoffFormData={timeoffFormData}
              action={EDispatch3DotMenuType.REQUEST_TIMEOFF}
              hocOnAction={hocOnAction}
            />
          );
        case EDispatch3DotMenuType.ACTIVATE_TIMEOFF:
          return (
            <DeletePopup
              title="Activate Time-off"
              body="Are you sure you want to activate this time-off?"
              buttonText={'Activate'}
              cancelText={'Cancel'}
              onAction={onRevertOrActivateTimeoffHandler}
              onClose={onCloseMenu}
            />
          );
        case EDispatch3DotMenuType.REVERT_TIMEOFF:
          return (
            <DeletePopup
              title="Revert Time-off"
              body="Are you sure you want to revert this time-off?"
              buttonText={'Revert'}
              cancelText={'Cancel'}
              onAction={onRevertOrActivateTimeoffHandler}
              onClose={onCloseMenu}
            />
          );
        case EDispatch3DotMenuType.MARK_PROGRESS_TIMEOFF:
          return (
            <MarkProgressTimeoffDialog
              data={data}
              onClose={onCloseMenu}
              hocOnAction={hocOnAction}
              action={EDispatch3DotMenuType.MARK_PROGRESS_TIMEOFF}
              getDriverLoadsGroupAssignment={getDriverLoadsGroupAssignment}
            />
          );

        case EDispatch3DotMenuType.EDIT_TIMEOFF:
          return (
            <TimeoffDialog
              canEditDate={data.status !== ELoadStatus.IN_TRANSIT}
              loadData={loadData}
              onClose={onCloseMenu}
              timeoffFormData={timeoffFormData}
              action={EDispatch3DotMenuType.EDIT_TIMEOFF}
              hocOnAction={hocOnAction}
            />
          );
        case EDispatch3DotMenuType.DELETE_TIMEOFF:
          return (
            <DeletePopup
              title="Delete Time-off"
              body="Are you sure you want to delete this time-off?"
              buttonText={'Remove'}
              cancelText={'Cancel'}
              onAction={onDeleteHanlder}
              onClose={onCloseMenu}
            />
          );
        default:
          return <></>;
      }
    };
    return (
      <>
        <Component {...panel3DotMenuHeaderSettings} {...props} />
        {renderChildPopup()}
      </>
    );
  };
  return EnhancedComponent;
};

export default withActionTimeOff;
