import { QueryParams } from '../commonMixed/QueryParams';

export class FactoringListRequest extends QueryParams {}
export class ActivateFactoryRequest extends QueryParams {
  data!: [
    {
      name: string;
      value: string;
    }
  ];
  integrationType!: string;
  isValid!: boolean;
  providerId!: number;
  terminalIds!: Array<string>;
  archive!: boolean;
}

export class DeactivateFactorinRequest {
  id!: string;
}

export class GetCustomerRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  customerName!: string;
}

export class GetDriverRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  nameFilter!: string;
  excludeStatuses?: number;
}

export class GetFactorMappingRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  organizationId!: number;
  factoringId!: string;
  sort!: string;
  customerIds?: Array<number>;
  driverIds?: Array<number>;
  isMapped?: boolean;
}
