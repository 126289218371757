import { Box, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE } from '../../../../constants/date.constants';
import { GetPreviousLoadContent } from '../../../../models/DTOs/Dispatch/Dispatch';
import TimeoffDateTime from '../../../../utils/timeoffDateTime';
import { LoadStatus } from '../../../Ui/StatusComponent/StatusComponent';

export interface LoadItemOptionsProps {
  id: string;
  title: string;
  location: string;
  date: string | null;
  loadStatus: string;
  seqNumber: any;
  timezone: string | null;
}
export const generateLoadItemOptions = (
  data: GetPreviousLoadContent
): LoadItemOptionsProps => {
  const {
    id,
    seqNumber,
    loadStatus,
    loadCategory,
    fromLocation = '--',
    toLocation = '--',
    startDate = null,
    endDate = null,
    firstStopTimezone,
    lastStopTimezone,
  } = data || {};

  const getDates = () => {
    const fromDate =
      (startDate &&
        TimeoffDateTime.dateToFormat({
          value: startDate,
          format: UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE,
          timezone: firstStopTimezone,
        })) ||
      null;
    const toDate =
      (endDate &&
        TimeoffDateTime.dateToFormat({
          value: endDate,
          format: UI_DATE_DISPATCH_CARD_TRIP_TIMEZONE,
          timezone: lastStopTimezone,
        })) ||
      null;
    if (startDate && endDate) {
      return `${fromDate} - ${toDate}`;
    }
    return fromDate || toDate;
  };
  const location =
    loadCategory === 'LOAD' && fromLocation && toLocation
      ? `${fromLocation} - ${toLocation}`
      : fromLocation || toLocation;

  return {
    id: id,
    seqNumber: seqNumber,
    title: loadCategory === 'LOAD' ? seqNumber : 'Time Off Scheduled',
    location: location,
    date: getDates(),
    loadStatus: loadStatus,
    timezone: firstStopTimezone || lastStopTimezone || null,
  };
};
export const LoadItem = memo(({ data }: { data: LoadItemOptionsProps }) => {
  const { date, location, loadStatus } = data;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      width={'100%'}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="body4">{location}</Typography>
        <Typography variant="body4">{date}</Typography>
      </Stack>
      <Box>
        <LoadStatus status={loadStatus} />
      </Box>
    </Stack>
  );
});
LoadItem.displayName = 'LoadItem';
