import React from 'react';
import { RouteObject } from 'react-router-dom';
import { getPermissionRouteByPathId } from '../Ui/LeftMenu2.0/utils';
import { PermissionRoute } from './PermissionRoute';

export type AxeleRoute = {
  pathId: string;
  parentId?: string;

  caseSensitive?: boolean;
  element?: React.ReactNode;
  index?: boolean;
  path?: string;
};

class AxeleRoutes {
  private readonly routes: AxeleRoute[] = [];

  public getRouteById(pathId: string): AxeleRoute | undefined {
    const foundRoute = this.routes.find((route) => route.pathId === pathId);

    return foundRoute;
  }

  public addRoute(newRoute: AxeleRoute): AxeleRoute | undefined {
    const foundRoute = this.getRouteById(newRoute.pathId);
    if (!foundRoute) {
      const permissionSetting = getPermissionRouteByPathId(newRoute.pathId);
      const cloned = {
        ...newRoute,
        ...(permissionSetting && {
          element: (
            <PermissionRoute {...permissionSetting}>
              {newRoute.element}
            </PermissionRoute>
          ),
        }),
      }; // not cloned ofc, but ok for now

      this.routes.push(cloned);

      return cloned;
    }
  }

  public addRouteAsChildOf(
    parentRoute: AxeleRoute,
    newRoute: AxeleRoute
  ): AxeleRoute | undefined {
    const addedRoute = this.addRouteAsChildOf(newRoute);
    if (addedRoute) {
      addedRoute.parentId = parentRoute.pathId;
      return addedRoute;
    }
  }

  public convertToRouteObjects(): RouteObject[] {
    const resultedRouteTree: RouteObject[] = [];
    type xxxMapType = {
      [propKey: string]: RouteObject;
    };
    const xxxMap: xxxMapType = {};
    for (const axeleRoute of this.routes) {
      const routeObject: RouteObject = {
        caseSensitive: axeleRoute.caseSensitive,
        children: [],
        element: axeleRoute.element,
        index: axeleRoute.index,
        path: axeleRoute.path,
      } as any;

      xxxMap[axeleRoute.pathId] = routeObject;
    }

    const allChildren = this.routes.filter((route) => route.parentId);

    for (const children of allChildren) {
      xxxMap[children.parentId || ''].children?.push(xxxMap[children.pathId]);
    }

    const topParents = this.routes.filter((route) => !route.parentId);
    for (const topParent of topParents) {
      resultedRouteTree.push(xxxMap[topParent.pathId]);
    }
    return resultedRouteTree;
  }
}

const routes = new AxeleRoutes();

export default routes;
