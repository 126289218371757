import { tractorService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import {
  AssetsCount,
  ChangeTractorStatusRequest,
  CreateTractorAlertQueryParamsRequest,
  DefaultTractorsTypesDictionarySummaryDTO,
  DefaultTractorsTypesListSummaryDTO,
  DefaultTractorsTypesSummaryDTO,
  DriverTractorAssignRequest,
  OptionType,
  PaginatedTractorsList,
  PaginatedTractorsListRequest,
  QueryParams,
  TractorAlertQueryParamsRequest,
  TractorAlertSummaryDTO,
  TractorResponse,
  TractorSummaryDTO,
  UpdateTractorAlertListRequest,
} from '../../models';
import { IMaintenanceItem } from '../../models/DTOs/maintenance/maintenanceItem/Model';
import { GridColDefSelf, PaginatedResult } from '../../types';
import {
  fromStringToOptionType,
  getHttpErrorsFromResponse,
  gridPageSize,
  prepareFiltersFromList,
} from '../../utils';
import TerminalController from '../../views/settings/terminals/TerminalController';
import { Tractor } from './models/Tractor';
import { TractorSummary } from './models/TractorSummary';

let instance: TractorController | null = null;
class TractorController {
  static instance(): TractorController {
    if (instance === null) {
      instance = new TractorController();
    }
    return instance;
  }

  static destroy() {
    instance = null;
  }

  total!: number;
  equipmentCount?: AssetsCount;
  listFetching!: boolean;
  lastPage!: boolean;
  tractors: Map<string, TractorSummary>;
  tractorList: TractorSummary[];
  stateOptions!: OptionType[];
  yearOptions!: OptionType[];
  brakingOptions!: OptionType[];
  safetyIssueType!: OptionType[];
  tollProviderOptions: OptionType[];
  fuelProviderOptions: OptionType[];
  ownershipTypeOptions!: OptionType[];
  tractorsListRequest: PaginatedTractorsListRequest;
  gettingTractorStatic!: boolean;
  gettingTollAndFuelStatic!: boolean;
  tractorAlertList!: TractorAlertSummaryDTO[];
  defaultTractorsTypesList!: DefaultTractorsTypesListSummaryDTO[];
  defaultTractorsTypesDictionary!: DefaultTractorsTypesDictionarySummaryDTO[];
  filteredDefaultTractorsTypes!: DefaultTractorsTypesSummaryDTO[];
  maintenanceItems!: IMaintenanceItem[];

  private constructor() {
    this.tractors = new Map();
    this.tractorList = [];
    this.stateOptions = [];
    this.yearOptions = [];
    this.safetyIssueType = [];
    this.brakingOptions = [];
    this.tollProviderOptions = [];
    this.fuelProviderOptions = [];
    this.ownershipTypeOptions = [];
    this.tractorsListRequest = this.setupTractorsListRequest();
    this.tractorAlertList = [];
    this.defaultTractorsTypesList = [];
    this.defaultTractorsTypesDictionary = [];
    this.maintenanceItems = [];
  }

  private async getAndSetupTractorStaticData(): Promise<void> {
    if (this.gettingTractorStatic) return;
    this.gettingTractorStatic = true;
    const tractorStatic = await tractorService.getTractorStaticData();
    this.gettingTractorStatic = false;
    this.yearOptions = tractorStatic.year;
    this.brakingOptions = tractorStatic.brakeType;
    this.safetyIssueType = tractorStatic.safetyIssueType;
    this.ownershipTypeOptions = tractorStatic.ownershipType;
  }

  private async getAndSetupTollFuelProviderOptions() {
    if (this.gettingTollAndFuelStatic) return;
    this.gettingTollAndFuelStatic = true;
    const expenseStatic = await tractorService.getExpenseStaticData();
    this.gettingTollAndFuelStatic = true;
    if (expenseStatic.cardProvider) {
      expenseStatic.cardProvider?.forEach(
        (o: { type: string; key: any; value: any }) => {
          if (o.type === 'Fuel') {
            this.fuelProviderOptions.push({
              key: o.key,
              value: o.value,
            } as OptionType);
          } else {
            this.tollProviderOptions.push({
              key: o.key,
              value: o.value,
            } as OptionType);
          }
        }
      );
    }
  }

  async getYearOptions(): Promise<OptionType[]> {
    if (!this.yearOptions.length) {
      await this.getAndSetupTractorStaticData();
    }
    return this.yearOptions;
  }

  async getDriverGroupsAsAnOptionType(
    searchText: string | undefined,
    pageNumber: number
  ): Promise<PaginatedResult<OptionType>> {
    const response = await this.getDriverGroups(searchText, pageNumber);
    response.content = response?.content?.map(
      (driver) => ({ key: driver.id, value: driver.displayName } as OptionType)
    );
    return response;
  }

  async getDriverGroups(
    searchText: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    activeForOperation?: boolean,
    typeFilter?: string
  ): Promise<PaginatedResult<OptionType>> {
    const response = await tractorService.getDriverGroups(
      searchText,
      pageNumber,
      terminalIds,
      activeForOperation,
      typeFilter
    );
    return response;
  }

  async getVinList(
    searchText: string | undefined,
    pageNumber: number
  ): Promise<PaginatedResult<OptionType> | undefined> {
    const response = await tractorService.getVinList(searchText, pageNumber);
    response.content = fromStringToOptionType(response?.content);
    return response;
  }

  async getLicensePlateList(
    searchText: string | undefined,
    pageNumber: number
  ): Promise<PaginatedResult<OptionType> | undefined> {
    const response = await tractorService.getLicensePlateList(
      searchText,
      pageNumber
    );
    response.content = fromStringToOptionType(response.content);
    return response;
  }

  async getTractorByNameSearch(
    searchText: string | undefined,
    pageNumber: number,
    excludeStatusList: string[] = []
  ): Promise<PaginatedResult<OptionType> | undefined> {
    const response = await tractorService.getTractorByNameSearchList(
      searchText,
      pageNumber,
      excludeStatusList
    );
    response.content = fromStringToOptionType(response.content);
    return response;
  }

  async getDriverLoadWarnings(dto: any): Promise<any> {
    return tractorService.getDriverLoadWarnings(dto);
  }

  async getDriverMaintenanceWarnings(dto: any): Promise<any> {
    return tractorService.getDriverMaintenanceWarnings(dto);
  }

  async getTractorByNameExtendedSearch(
    searchText: string | undefined,
    pageNumber: number,
    statusList: string[],
    terminalIds?: string[]
  ): Promise<PaginatedResult<OptionType> | undefined> {
    const response = await tractorService.getTractorByNameSearchExtendedList(
      searchText,
      pageNumber,
      statusList,
      terminalIds
    );
    return response;
  }

  async getTractorLightListByNameSearch(
    searchText: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    excludeStatusList: string[] = [],
    pageSize = 25
  ): Promise<PaginatedResult<OptionType> | undefined> {
    try {
      return await tractorService.getTractorLightListByNameSearch(
        searchText,
        pageNumber,
        terminalIds,
        excludeStatusList,
        pageSize
      );
    } catch (err) {
      return;
    }
  }

  async getBrakingOptions(): Promise<OptionType[]> {
    if (!this.brakingOptions.length) {
      await this.getAndSetupTractorStaticData();
    }
    return this.brakingOptions;
  }

  async getOwnershipTypeOptions(): Promise<OptionType[]> {
    if (!this.ownershipTypeOptions.length) {
      await this.getAndSetupTractorStaticData();
    }
    return this.ownershipTypeOptions;
  }

  async getSafetyIssueTypeOptions(): Promise<OptionType[]> {
    if (!this.safetyIssueType.length) {
      await this.getAndSetupTractorStaticData();
    }
    return this.safetyIssueType;
  }

  async getTollProviderOptions(): Promise<OptionType[]> {
    if (!this.tollProviderOptions.length) {
      await this.getAndSetupTollFuelProviderOptions();
    }
    return this.tollProviderOptions;
  }

  async getFuelProviderOptions(): Promise<OptionType[]> {
    if (!this.fuelProviderOptions.length) {
      await this.getAndSetupTollFuelProviderOptions();
    }
    return this.fuelProviderOptions;
  }

  async getStateOptions(): Promise<OptionType[]> {
    if (!this.stateOptions.length) {
      const states = await tractorService.getStates();
      if (states?.states) {
        this.stateOptions = states.states.map(
          (s: { abbreviation: any; name: any }) =>
            ({ key: s.abbreviation, value: s.name } as OptionType)
        );
      }
    }
    return this.stateOptions;
  }

  setupTractorsListRequest(): PaginatedTractorsListRequest {
    const req = new PaginatedTractorsListRequest();
    req.statusFilter = ['ASSIGNED', 'AVAILABLE'];
    req.pageSize = gridPageSize;
    req.sort = '+tractorName';
    return req;
  }

  updateTractorsListRequestWithFilters(newFilters, paging: number) {
    const req = this.tractorsListRequest;
    if (!paging) {
      req.pageNumber = 1;
    }
    if (newFilters.sort) {
      req.sort = newFilters.sort;
    }
    req.driverGroupFilter = prepareFiltersFromList(
      newFilters.driverGroupFilter,
      'key'
    );
    req.vinFilter = prepareFiltersFromList(newFilters.vinFilter, 'value');
    req.licensePlateFilter = prepareFiltersFromList(
      newFilters.licensePlateFilter,
      'value'
    );
    req.nameFilter = prepareFiltersFromList(newFilters.nameFilter, 'value');
    req.safetyIssueFilter = prepareFiltersFromList(
      newFilters.safetyIssueFilter,
      'key'
    );
    req.statusFilter = prepareFiltersFromList(newFilters.statusFilter, 'key');
    req.inTransitFilter = prepareFiltersFromList(
      newFilters.inTransitFilter,
      'key'
    );
    req.regExpiryFrom = newFilters.regExpiry?.dateRange?.[0];
    req.regExpiryTo = newFilters.regExpiry?.dateRange?.[1];
  }

  async getAssetsCount(callback: (data: AssetsCount | undefined) => void) {
    const assetsCount = await tractorService.getAssetsCount();
    this.equipmentCount = assetsCount;
    callback(this.equipmentCount);
  }

  async getTractorList(
    filters,
    getGlobalTerminalsIds,
    callback: (data: TractorSummary[]) => void,
    paging = 0
  ): Promise<void> {
    if ((paging && this.lastPage) || this.listFetching) return;
    this.updateTractorsListRequestWithFilters(filters, paging);
    this.tractorsListRequest.pageNumber += paging;
    this.tractorsListRequest.getParamsFromRequest();
    this.listFetching = true;
    this.tractorsListRequest.terminalIds = getGlobalTerminalsIds;
    const data = await tractorService.getPaginatedTractorsList(
      this.tractorsListRequest
    );
    this.listFetching = false;
    if (data instanceof PaginatedTractorsList) {
      this.lastPage = data.last;
      this.total = data.totalElements;
      const tractors = data?.content?.map((item: TractorSummaryDTO) => {
        return new TractorSummary(item);
      });
      this.tractorList = paging ? [...this.tractorList, ...tractors] : tractors;

      callback(this.tractorList);
    }
  }

  async getTractorListExport(
    callback: (data: any) => void,
    tableColumns: GridColDefSelf[]
  ): Promise<void> {
    const gridColumnMetadataList: string[] = [];
    tableColumns.forEach((eachColumn: GridColDefSelf) => {
      gridColumnMetadataList.push(eachColumn.field);
    });
    this.tractorsListRequest.gridColumnMetadataList = gridColumnMetadataList;
    const data = await tractorService.getPaginatedTractorsListExport(
      this.tractorsListRequest
    );
    if (data instanceof PaginatedTractorsList) {
      callback(data);
      this.tractorsListRequest.gridColumnMetadataList = null;
    }
  }

  async getTractorById(
    id: string,
    callback: (data: TractorResponse) => void
  ): Promise<void> {
    const data = await tractorService.getTractorById(id);
    if (data instanceof TractorResponse) {
      callback(new Tractor(data));
    }
  }

  async verifyTractorDeletion(
    id: string,
    callback: (canDelete: {
      assetIsAllowedToDelete: ServiceError | boolean;
      message: string;
    }) => void
  ) {
    const canDelete = await tractorService.verifyTractorDeletion(id);
    if (!(canDelete instanceof ServiceError)) {
      callback(canDelete);
    }
  }

  async deleteTractorById(
    id: string,
    callback: (data: ServiceError | null) => void
  ) {
    const deleted = await tractorService.deleteTractorById(id);
    if (!(deleted instanceof ServiceError)) {
      this.equipmentCount?.decreaseTractor();
      this.total -= 1;
      callback(null);
    }
  }

  async markAsInactive(
    id: string,
    callback: (data: ServiceError | boolean) => void
  ) {
    const response = await tractorService.markAsInactive(id);
    if (!(response instanceof ServiceError)) {
      if (response.hasErrors) {
        callback(false);
      } else {
        callback(true);
      }
    }
  }

  async createTractor(
    tractor: TractorSummary,
    callback: (data: TractorResponse | null, validationErr?: any) => void
  ): Promise<void> {
    const data = await tractorService.createTractor(tractor);
    if (data instanceof TractorResponse) {
      data.terminal = TerminalController.instance().terminalById(
        data.terminalId
      );
      this.equipmentCount?.increaseTractor();
      this.total += 1;
      callback(data);
    } else if (data instanceof ServiceError) {
      callback(null, getHttpErrorsFromResponse(data));
    }
  }

  async driverTractorAssign(driverGroupId: string, tractorId: number) {
    try {
      return await tractorService.driverTractorAssign(
        new DriverTractorAssignRequest({
          driverGroupId,
          tractorId,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  async driverTractorUnAssign(driverGroupId: string) {
    try {
      return await tractorService.driverTractorUnAssign(
        new DriverTractorAssignRequest({
          driverGroupId,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  async changeTractorStatus(tractorId: number, status: string) {
    try {
      return await tractorService.changeTractorStatus(
        new ChangeTractorStatusRequest({ tractorId: tractorId, status })
      );
    } catch (error) {
      console.error(error);
    }
  }

  async updateTractor(
    tractor: any,
    callback: (data: TractorResponse | null, validationErr?: any) => void
  ): Promise<void> {
    const data = await tractorService.updateTractor(tractor);
    if (data instanceof TractorResponse) {
      data.terminal = TerminalController.instance().terminalById(
        data.terminalId
      );
      callback(data);
    } else if (data instanceof ServiceError) {
      callback(null, getHttpErrorsFromResponse(data));
    }
  }

  //#region  TractorAlert

  getTractorAlertList = async (
    id: number,
    callback: (data: TractorAlertSummaryDTO[]) => void
  ): Promise<void> => {
    const requestData = new TractorAlertQueryParamsRequest(id);
    const data = await tractorService.getTractorAlertList(requestData);

    if (!data || data instanceof ServiceError) {
      callback([]);
    } else {
      this.tractorAlertList = data;
      callback(data);
    }
  };

  async createTractorAlert(
    tractorAlert: TractorAlertSummaryDTO,
    callback: (data: TractorAlertSummaryDTO) => void
  ): Promise<void> {
    const requestData = new CreateTractorAlertQueryParamsRequest(tractorAlert);
    const alertData = await tractorService.createTractorAlert(requestData);

    if (!alertData || alertData instanceof ServiceError) {
      return;
    } else {
      callback(alertData);
    }
  }

  async updateTractorAlertsBulk(
    tractorAlertsList: TractorAlertSummaryDTO[],
    callback: (data: TractorAlertSummaryDTO[]) => void
  ): Promise<void> {
    const updatedTractorAlertsList = tractorAlertsList?.map(
      (alert: TractorAlertSummaryDTO) =>
        new UpdateTractorAlertListRequest(alert)
    );

    const alertData = await tractorService.updateTractorAlertsBulk(
      updatedTractorAlertsList
    );

    if (!alertData || alertData instanceof ServiceError) {
      return;
    } else {
      callback(alertData);
    }
  }

  getDefaultTractorsTypesList = async (): Promise<void> => {
    const requestData = new QueryParams();
    const data = await tractorService.getDefaultTractorsTypesList(requestData);

    if (!data || data instanceof ServiceError) {
      return;
    } else {
      this.defaultTractorsTypesList = data;
    }
  };

  getDefaultTractorsTypesDictionary = async (): Promise<void> => {
    const requestData = new QueryParams();
    const data = await tractorService.getDefaultTractorsTypesDictionary(
      requestData
    );

    if (!data || data instanceof ServiceError) {
      return;
    } else {
      this.defaultTractorsTypesDictionary = data;
    }
  };

  async deleteTractorAlertById(
    id: string,
    callback: (data: ServiceError | null) => void
  ) {
    const deleted = await tractorService.deleteTractorAlertById(id);
    if (!(deleted instanceof ServiceError)) {
      callback(null);
    }
  }
}

export default TractorController;
