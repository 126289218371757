import * as yup from 'yup';
import { nullableStringYup } from '../../../utils';

export const UpdateItemHOSValidationSchema = yup.object({
  isRest: yup.boolean(),
  restDuration: nullableStringYup()
    .nullable(true)
    .when('isRest', {
      is: true,
      then: yup.string().nullable(true).required('Rest Duration is required'),
    }),
  enableHoS: yup.boolean(),
  dutyTimeRemaining: nullableStringYup()
    .nullable(true)
    .when('enableHoS', {
      is: true,
      then: yup
        .string()
        .nullable(true)
        .required('dutyTimeRemaining is required'),
    }),
  shiftDriveRemaining: nullableStringYup()
    .nullable(true)
    .when('enableHoS', {
      is: true,
      then: yup
        .string()
        .nullable(true)
        .required('shiftDriveRemaining is required'),
    }),
  weeklyHoursRemaining: nullableStringYup()
    .nullable(true)
    .when('enableHoS', {
      is: true,
      then: yup
        .string()
        .nullable(true)
        .required('weeklyHoursRemaining is required'),
    }),
  breakTimeRemaining: nullableStringYup()
    .nullable(true)
    .when('enableHoS', {
      is: true,
      then: yup
        .string()
        .nullable(true)
        .required('breakTimeRemaining is required'),
    }),
});

export const UpdateHOSValidationSchema = yup
  .object({
    enableLocation: yup.boolean(),
    location: yup.object().when('enableLocation', {
      is: true,
      then: yup
        .object()
        .shape({
          address: nullableStringYup().required('Address is required.'),
          city: nullableStringYup().required('City is required.'),
          state: nullableStringYup().required('State is required.'),
        })
        .required('The location is required.'),
    }),
    driversHosDataList: yup.array().of(UpdateItemHOSValidationSchema).compact(),
  })
  .required();
