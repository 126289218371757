import { BookmarkOutlined, CheckOutlined } from '@mui/icons-material';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { LoadsearchConstants } from '../../../locales/en/allLoads/loadsearch/index';
import { defaultLoadboardGridFilters } from '../LoadboardDataGrid/grid.config';
import { ILoadboardFilters } from '../loadTypes';
import { loadboardFieldsConfig } from './grid.constants';
import { View } from './types';

export const loadSearchColumnFilters = {
  [loadboardFieldsConfig.Age.fieldName]: true,
  [loadboardFieldsConfig.Pickup.fieldName]: true,
  [loadboardFieldsConfig.LoadedMiles.fieldName]: true,
  [loadboardFieldsConfig.Dropoff.fieldName]: true,
  [loadboardFieldsConfig.Offer.fieldName]: true,
  [loadboardFieldsConfig.Mileage.fieldName]: true,
  [loadboardFieldsConfig.MarketRate.fieldName]: true,
  [loadboardFieldsConfig.Equipment.fieldName]: true,
  [loadboardFieldsConfig.Broker.fieldName]: true,
  [loadboardFieldsConfig.ReloadScore.fieldName]: true,
  [loadboardFieldsConfig.loadId.fieldName]: false,
};

export const bookmarkColumnFilters = {
  ...loadSearchColumnFilters,
};

export const pendingColumnFilters = {
  ...loadSearchColumnFilters,
};

export const loadFilters: ILoadboardFilters = {
  ...defaultLoadboardGridFilters,
};

export const bookmarkFilters: ILoadboardFilters = {
  ...defaultLoadboardGridFilters,
};

export const pendingFilters: ILoadboardFilters = {};

export enum ELoadBoardEntity {
  loadsearch = 'loadsearch',
  bookmark = 'bookmark',
  pendingLoad = 'pendingLoad',
  booked = 'bookedLoad',
}
export const loadSearchView = {
  id: ELoadBoardEntity.loadsearch,
  shared: true,
  parentId: null,
  name: LoadsearchConstants.loadSearchViewLoadsearch,
  entity: ELoadBoardEntity.loadsearch,
  icon: <DnsOutlinedIcon />,
  default: true,
  columnFilters: loadSearchColumnFilters,
  metaData: loadFilters,
};

export const bookmarkView = {
  id: ELoadBoardEntity.bookmark,
  shared: true,
  parentId: null,
  name: LoadsearchConstants.loadSearchViewBookmarks,
  entity: ELoadBoardEntity.bookmark,
  icon: <BookmarkOutlined />,
  active: true,
  default: true,
  columnFilters: bookmarkColumnFilters,
  metaData: bookmarkFilters,
};

export const bookedLoadView = {
  id: ELoadBoardEntity.booked,
  shared: true,
  parentId: null,
  name: LoadsearchConstants.loadSearchViewBookedLoads,
  entity: ELoadBoardEntity.booked,
  icon: <CheckOutlined />,
  default: true,
  columnFilters: {
    ...loadSearchColumnFilters,
    [loadboardFieldsConfig.loadId.fieldName]: true,
  },
  metaData: loadFilters,
};
export const pendingLoadView = {
  id: ELoadBoardEntity.pendingLoad,
  shared: true,
  parentId: null,
  name: LoadsearchConstants.loadSearchViewPendingLoads,
  entity: ELoadBoardEntity.pendingLoad,
  icon: <WatchLaterOutlinedIcon />,
  active: true,
  default: true,
  columnFilters: pendingColumnFilters,
  metaData: pendingFilters,
};

export type ViewMap = {
  [key: string]: View[];
};

export const formDefaultValuesMap: any = {
  loadsearch: loadSearchView,
  pendingLoads: pendingLoadView,
  bookedLoads: bookedLoadView,
};
