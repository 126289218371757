import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Box from '@mui/material/Box';
import { debounce } from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import StyledArrowButton from './StyledArrowButton';
import StyledExtraTab from './StyledExtraTab';
import StyledIconsComponent from './StyledIconsComponent';
import Tabs from './StyledTabs';
import TabV2 from './TabV2';
import {
  ADD_ICON_WIDTH,
  CHAR_OFFSET,
  COLUMN_GAP,
  FONT,
  GAP,
  ICON_SIZE,
  ICON_WIDTH,
  MAX_TAB_WIDTH,
  MIN_TAB_WIDTH,
  ROW_GAP,
  TAB_HEIGHT,
  TAB_PADDING_X,
  THREE_DOTS_ICON_WIDTH,
} from './constants';

const getTextWidth = (text: string, font: string): number => {
  const canvas =
    (getTextWidth as any).canvas ||
    ((getTextWidth as any).canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

export type ITabV2<KeyName extends string = 'key'> = {
  [key in KeyName]: string;
} & {
  label: string;
  iconName?: string;
  source?: string;
  icon?: ReactElement;
  actions?: {
    label: string;
    iconName?: string;
    source?: string;
    key: string;
    onClick: (key: string) => void;
  }[];
};

export interface TabsV2Props<
  KeyName extends string = 'key',
  TTab extends ITabV2<KeyName> = ITabV2<KeyName>
> {
  tabs: TTab[];
  selectedTab?: TTab[KeyName];
  setSelectedTab?: (tab: TTab) => void;
  isCustomActionsAvailable?: boolean;
  onAddTabItem?: () => void;
  isDarkMode?: boolean;
  hasLoadAttributes?: boolean;
  openLoadAttributePopup?: (e: React.SyntheticEvent) => void;
  openMapPopup?: (e: React.SyntheticEvent) => void;
  isLoadAttributePopupOpen?: boolean;
  keyName: KeyName;
  autoFill?: boolean;
  labelCentered?: boolean;
  autoCollapse?: boolean;
  keepSelectedTabOnFirstRow?: boolean;
}

type TTabRow<TTab> = [
  { actualWidth: number; minWidth: number; appliedWidth: number },
  TTab
][];

const customTabButtonStyle = {
  width: 'unset',
  height: ICON_SIZE,
};

enum EXTRA_TABS {
  LoadAttributes = '_LoadAttributes',
  Map = '_Map',
}

const ADD_KEY = '_Add';

export default function TabsV2<
  KeyName extends string = 'key',
  TTab extends ITabV2<KeyName> = ITabV2<KeyName>
>({
  isDarkMode,
  tabs,
  selectedTab: selectedTabKey,
  setSelectedTab,
  isCustomActionsAvailable,
  onAddTabItem,
  hasLoadAttributes = false,
  openLoadAttributePopup,
  openMapPopup,
  keyName,
  autoFill,
  labelCentered,
  autoCollapse = true,
  keepSelectedTabOnFirstRow = true,
}: TabsV2Props<KeyName, TTab>): ReactElement | null {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === ADD_KEY) {
      onAddTabItem?.();
    } else if (newValue === EXTRA_TABS.LoadAttributes) {
      openLoadAttributePopup?.(event);
    } else if (newValue === EXTRA_TABS.Map) {
      openMapPopup?.(event);
    } else {
      setSelectedTab?.(
        renderedTabs.find(([, tab]) => tab[keyName] === newValue)?.[1] as TTab
      );
    }
  };

  const [tabsRef, setTabsRef] = useState<HTMLButtonElement | null>(null);
  const [rows, setRows] = useState<TTabRow<TTab>[]>([
    Array(tabs.length)
      .fill(undefined)
      .map((_, index) => {
        return [
          {
            actualWidth: 100,
            minWidth: 100,
            appliedWidth: 100,
          },
          tabs[index],
        ];
      }),
  ]);
  const renderedTabs = rows.reduce(
    (calculatedTabs, row) => calculatedTabs.concat(row),
    []
  );

  const calculateTabWidth = () => {
    if (tabsRef) {
      const addIconWidth = isCustomActionsAvailable
        ? ADD_ICON_WIDTH + TAB_PADDING_X * 2 + COLUMN_GAP
        : 0;
      const gapExtraIcon = hasLoadAttributes ? COLUMN_GAP : 0;
      const extraIconWidth = hasLoadAttributes
        ? (ADD_ICON_WIDTH + TAB_PADDING_X * 2) * 2 + COLUMN_GAP
        : 0;
      const fullWidth = tabsRef.getBoundingClientRect().width;
      const firstWidth =
        fullWidth - addIconWidth - gapExtraIcon - extraIconWidth;
      const calculatedWidths: [number, number][] = tabs.map((tab) => {
        const iconWidth = tab.icon || tab.iconName ? ICON_WIDTH : 0;
        const gap = (tab.icon || tab.iconName) && tab.label ? GAP : 0;
        const threeDotsWidth = tab.actions?.length
          ? THREE_DOTS_ICON_WIDTH + GAP
          : 0;
        const labelWidth = getTextWidth(tab.label || '', FONT) + CHAR_OFFSET;
        const padding = TAB_PADDING_X * 2;
        const actualWidth =
          iconWidth + gap + threeDotsWidth + labelWidth + padding;
        const minWidth =
          actualWidth -
          labelWidth +
          getTextWidth((tab.label || '').slice(0, 5), FONT);
        return [minWidth, actualWidth];
      });
      const totalGap = (calculatedWidths.length - 1) * COLUMN_GAP;
      const [containerMinWidth, containerActualWidth] = calculatedWidths.reduce(
        (result, [minWidth, width]) => {
          return [result[0] + minWidth, result[1] + width];
        },
        [totalGap, totalGap]
      );

      const shareSpaceEqually = (
        tabWidths: number[],
        rowWidth: number
      ): number[] => {
        const actualWidth = tabWidths.reduce<number>(
          (actualWidth, width) => (actualWidth += width),
          0
        );
        const totalTabGap = COLUMN_GAP * (tabWidths.length - 1);
        const availableSpace = rowWidth - totalTabGap - actualWidth;
        const availableSpaceForEachTab = availableSpace / tabWidths.length;
        return tabWidths.map(
          (actualWidth) => actualWidth + availableSpaceForEachTab
        );
      };

      const expandTabToFitContainer = (
        tabRows: TTabRow<TTab>[]
      ): TTabRow<TTab>[] => {
        tabRows.forEach((row, index) => {
          const newTabWidths = shareSpaceEqually(
            row.map(([{ appliedWidth }]) => appliedWidth),
            index === 0 ? firstWidth : fullWidth
          );
          if (!!tabRows[index + 1] || autoFill) {
            row.map((rowConfig, index) => {
              rowConfig[0].appliedWidth = newTabWidths[index];
            });
          }
        });
        return tabRows;
      };

      if (containerActualWidth > firstWidth) {
        if (containerMinWidth > firstWidth) {
          const selectedTabIndex = tabs.findIndex(
            (tab) => tab[keyName] === selectedTabKey
          );
          if (selectedTabIndex < 0) return;
          let consumedWidth = 0;
          const rows: TTabRow<TTab>[] = [];
          let currentRow = 0;
          let width = firstWidth;
          // Update rows
          let isAdd = false;
          for (let i = 0; i < tabs.length; i++) {
            const nextIndex = i + 1;
            const [minWidth, actualWidth] = calculatedWidths[i];
            const tabWidth = Math.min(
              Math.max(MIN_TAB_WIDTH, actualWidth),
              width,
              MAX_TAB_WIDTH
            );
            const [minSelectedTabWidth, selectedTabActualWidth] =
              calculatedWidths[selectedTabIndex];
            const selectedTabWidth = Math.min(
              Math.max(MIN_TAB_WIDTH, selectedTabActualWidth),
              width,
              MAX_TAB_WIDTH
            );
            const nextTab = tabs[nextIndex];
            let nextTabWidth;
            if (nextTab) {
              const nextActualWidth = calculatedWidths[nextIndex][1];
              nextTabWidth = Math.min(
                Math.max(MIN_TAB_WIDTH, nextActualWidth),
                width,
                MAX_TAB_WIDTH
              );
            }
            if (i === selectedTabIndex && keepSelectedTabOnFirstRow) {
              // if selected tab is on 1st row, keep the position else skip this
              if (
                consumedWidth + tabWidth + COLUMN_GAP <= width &&
                currentRow === 0
              ) {
                rows[currentRow] = (rows[currentRow] || []).concat([
                  [
                    {
                      minWidth,
                      actualWidth,
                      appliedWidth: tabWidth,
                    },
                    tabs[i],
                  ],
                ]);
                consumedWidth += tabWidth + COLUMN_GAP;
                isAdd = true;
              }
            } else if (
              nextTab &&
              nextTabWidth !== undefined &&
              !isAdd &&
              consumedWidth +
                tabWidth +
                COLUMN_GAP +
                selectedTabWidth +
                COLUMN_GAP <=
                width &&
              consumedWidth +
                tabWidth +
                COLUMN_GAP +
                selectedTabWidth +
                COLUMN_GAP +
                nextTabWidth +
                COLUMN_GAP >
                width &&
              keepSelectedTabOnFirstRow
            ) {
              // Add selected tab at the end of the 1st row
              isAdd = true;
              rows[currentRow] = (rows[currentRow] || []).concat([
                [{ minWidth, actualWidth, appliedWidth: tabWidth }, tabs[i]],
                [
                  {
                    minWidth: minSelectedTabWidth,
                    actualWidth: selectedTabActualWidth,
                    appliedWidth: selectedTabWidth,
                  },
                  tabs[selectedTabIndex],
                ],
              ]);
              consumedWidth +=
                tabWidth + COLUMN_GAP + selectedTabWidth + COLUMN_GAP;
            } else {
              // Add tab to rows
              if (consumedWidth + tabWidth + COLUMN_GAP > width) {
                currentRow += 1;
                consumedWidth = 0;
                width = fullWidth;
              }
              rows[currentRow] = (rows[currentRow] || []).concat([
                [{ minWidth, actualWidth, appliedWidth: tabWidth }, tabs[i]],
              ]);
              consumedWidth += tabWidth + COLUMN_GAP;
            }
          }
          setRows(expandTabToFitContainer(rows));
        } else {
          // Case 2: One row on minimized width of tabs
          const remainSpace = firstWidth - containerMinWidth;
          const length = calculatedWidths.filter(
            ([minWidth, actualWidth]) => minWidth !== actualWidth
          ).length;
          const offset = remainSpace / length;
          setRows([
            calculatedWidths.map(([minWidth, actualWidth], index) => {
              if (minWidth === actualWidth)
                return [
                  { minWidth, actualWidth, appliedWidth: actualWidth },
                  tabs[index],
                ];
              return [
                { appliedWidth: minWidth + offset, minWidth, actualWidth },
                tabs[index],
              ];
            }),
          ]);
        }
      } else {
        // Case 3: One row on actual width of tabs
        const rows: TTabRow<TTab>[] = [
          calculatedWidths.map(([minWidth, actualWidth], index) => [
            { minWidth, actualWidth, appliedWidth: actualWidth },
            tabs[index],
          ]),
        ];
        setRows(expandTabToFitContainer(rows));
      }
    }
  };

  useEffect(() => {
    calculateTabWidth();
  }, [tabsRef, tabs]);
  const [show, setShow] = useState<boolean>(false);

  const numberOfLines = rows.length;
  const isShowExpandButton = autoCollapse && rows.length > 1;

  const onResize = () => {
    calculateTabWidth();
  };

  const debouncedOnResize = useCallback(debounce(onResize, 200), [
    tabsRef,
    tabs,
  ]);

  useEffect(() => {
    setTimeout(() => onResize(), 0);
    window.addEventListener('resize', debouncedOnResize);
    return () => {
      window.removeEventListener('resize', debouncedOnResize);
    };
  }, [tabsRef, tabs]);

  const renderTabs = () => {
    const renderedTabsComponent = renderedTabs.map(
      ([{ appliedWidth, actualWidth }, tab]) => (
        <TabV2<KeyName, TTab>
          sx={{
            width: `${appliedWidth}px`,
          }}
          isOverflow={appliedWidth < actualWidth}
          key={tab[keyName]}
          value={tab[keyName]}
          tab={tab}
          isDarkMode={isDarkMode}
          labelCentered={labelCentered}
        />
      )
    );
    renderedTabsComponent.splice(
      rows[0]?.length,
      0,
      ...([
        hasLoadAttributes && (
          <StyledExtraTab
            isDarkMode={isDarkMode}
            key={EXTRA_TABS.LoadAttributes}
            value={EXTRA_TABS.LoadAttributes}
            label={<CalculateOutlinedIcon sx={customTabButtonStyle} />}
          />
        ),
        hasLoadAttributes && (
          <StyledExtraTab
            isDarkMode={isDarkMode}
            key={EXTRA_TABS.Map}
            value={EXTRA_TABS.Map}
            label={<LocationOnOutlinedIcon sx={customTabButtonStyle} />}
          />
        ),
        isCustomActionsAvailable && (
          <StyledExtraTab
            key={ADD_KEY}
            isDarkMode={isDarkMode}
            value={ADD_KEY}
            label={<StyledIconsComponent iconName="Add" source="MUIcons" />}
          />
        ),
      ].filter(Boolean) as ReactElement[])
    );
    return renderedTabsComponent;
  };

  return (
    <Box
      position="relative"
      bgcolor={isDarkMode ? '#35446B' : undefined}
      px={isDarkMode ? 1 : undefined}
      zIndex={1}
      width={'100%'}
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
    >
      <Box
        sx={{
          transition: '200ms',
        }}
        height={!show && autoCollapse ? TAB_HEIGHT : 'auto'}
        overflow="hidden"
      >
        <Tabs
          ref={setTabsRef}
          value={
            renderedTabs.find(
              ([, tab]) => tab[keyName] === selectedTabKey
            )?.[1]?.[keyName] || renderedTabs[0]?.[1]?.[keyName]
          }
          onChange={handleChange}
        >
          {renderTabs()}
        </Tabs>
      </Box>
      {isShowExpandButton && (
        <StyledArrowButton
          sx={{
            position: 'absolute',
            right: isDarkMode ? 8 : 0,
            top:
              TAB_HEIGHT * (show ? numberOfLines : 1) +
              ROW_GAP * (show ? numberOfLines - 1 : 0),
            zIndex: 1,
          }}
          onClick={() => {
            setShow(!show);
          }}
          startIcon={<ArrowBackIosNewIcon />}
          up={show}
        />
      )}
    </Box>
  );
}
