import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import LongMenu from '../../../../common/LongMenu';
import { invoiceModule } from '../../../../common/PendoClassIDs/constants';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import ReadOnlyField from '../../../../common/ReadOnlyField';
import FormDialog from '../../../../common/Ui/FormDialog';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import { BREAKPOINT_TABLET } from '../../../../common/hooks/useThemeResponsive';
import { useStores } from '../../../../store/root.context';
import AxeleAccordion from '../../../../ui-kit/components/Accordion';
import StatusBaseComponent from '../../../../ui-kit/components/Assets/StatusComponent/StatusBaseComponent';
import { amountFormat } from '../../../../utils';
import InvoiceActions from '../../InvoiceActions';
import {
  CANCELLED_LOAD_STATUS_NAME,
  PAID_LOAD_STATUS_NAME,
  invoiceLineItemFormRequiredFields,
  invoiceLineItemsDialogSchema,
} from '../../constants';
import {
  InvoiceLineItemFormRequiredFields,
  InvoiceModel,
  LineItem,
} from '../../models/InvoiceModel';

export function InvoiceLineItemsSection({
  invoice,
  isBaseLineItemExpanded = false,
  isCreating = false,
  isViewMode = false,
  requiredFields = {
    ...invoiceLineItemFormRequiredFields,
  },
  foundIndex,
  mainLineItem,
  canEditInvoice,
}: {
  isBaseLineItemExpanded: boolean;
  invoice: InvoiceModel;
  isCreating?: boolean;
  isViewMode?: boolean;
  requiredFields?: InvoiceLineItemFormRequiredFields;
  foundIndex: number;
  mainLineItem: boolean;
  canEditInvoice: boolean;
}) {
  const {
    invoiceStore: { isInvoiceCreate },
  } = useStores();
  const [addInvoiceLineItemDialogOpen, setAddInvoiceLineItemDialogOpen] =
    useState(false);
  const [addBaseLineItemDialogOpen, setAddBaseLineItemDialogOpen] =
    useState(false);
  const {
    control,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { lineItems: lineItemsErrors } = errors || {};

  const { fields, append, remove } = useFieldArray({
    control,
    name: mainLineItem ? 'lineItems' : `childInvoices.${foundIndex}.lineItems`,
  });
  const invoiceType = getValues('invoiceType');
  const invoiceStatus = getValues('status');
  const watchFieldArray = invoice.lineItems;
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const lineItemOptions = InvoiceActions.invoiceLineItemsList.filter(
    (l) => l.itemType === 'BASE'
  );

  const accessorialOptions = InvoiceActions.invoiceLineItemsList.filter(
    (item) => item.itemType !== 'BASE'
  );
  const paidOrInvoicedStatus = invoiceStatus === PAID_LOAD_STATUS_NAME;

  const { hasFinancialInvoiceEdit } = useLoadPermission();

  const hasEditPermission = (): boolean => {
    if (isCreating) {
      return true;
    }
    return hasFinancialInvoiceEdit;
  };

  const invoiceCanceledStatus =
    !(
      invoiceStatus === CANCELLED_LOAD_STATUS_NAME &&
      !invoice.lineItems.length &&
      invoice.nonInvoiceable
    ) || isCreating;

  let hasBaseLineItem = false;
  return (
    <>
      {controlledFields.map((field, index) => {
        const lineItem: LineItem =
          InvoiceActions.invoiceLineItemsMap[field.itemId] || ({} as LineItem);
        const isLineItemBase = lineItem.itemType === 'BASE';
        if (isLineItemBase) {
          hasBaseLineItem = true;
        }
        return (
          <Box sx={{ mt: 1, mb: 1, p: '10px 10px 5px 5px' }} key={field.id}>
            <AxeleAccordion
              expanded={isBaseLineItemExpanded && isLineItemBase}
              errors={
                isCreating
                  ? (lineItemsErrors?.length && lineItemsErrors[index]) || {}
                  : {}
              }
              titleRenderer={(expanded) => {
                if (isLineItemBase) {
                  return expanded
                    ? 'Revenue - Base'
                    : `${lineItem.itemName || ''}`;
                } else {
                  return expanded
                    ? 'Revenue - Accessorial'
                    : `${lineItem.itemName || ''}`;
                }
              }}
              subTitleRenderer={(expanded) => (
                <Box>
                  <Typography variant={'body2'} sx={{ mb: 0.5 }}>
                    {field.description}
                  </Typography>
                  {!expanded && lineItem.deduction && (
                    <StatusBaseComponent
                      label="Deduction"
                      customStyles={{
                        wrap: { backgroundColor: '#0C6BB9' },
                        label: { color: 'primary.contrast' },
                      }}
                    />
                  )}
                </Box>
              )}
              renderLabel={() => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={(theme) => ({ color: theme.palette.primary.main })}
                  >
                    {amountFormat(field.amount)}
                  </Typography>
                  {((isCreating && !isViewMode && !isLineItemBase) ||
                    (!isCreating && !paidOrInvoicedStatus) ||
                    isInvoiceCreate) &&
                    canEditInvoice && (
                      <Permission
                        contains={[
                          AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICE_REMOVE,
                        ]}
                      >
                        <Box
                          sx={{ ml: 1 }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <LongMenu
                            color={'primary.main'}
                            options={[
                              {
                                name: 'Remove',
                                action: () => remove(index),
                              },
                            ]}
                          />
                        </Box>
                      </Permission>
                    )}
                </Box>
              )}
            >
              <InvoiceLineItems
                nameStart={
                  mainLineItem
                    ? `lineItems.${index}.`
                    : `childInvoices.${foundIndex}.lineItems.${index}.`
                }
                lineItemOptions={
                  isLineItemBase ? lineItemOptions : accessorialOptions
                }
                disabled={
                  (isViewMode ||
                    !canEditInvoice ||
                    paidOrInvoicedStatus ||
                    !hasEditPermission()) &&
                  !isInvoiceCreate
                }
                requiredFields={requiredFields}
                isItemBase={isLineItemBase}
                isCreating={isCreating}
                isDeduction={lineItem.deduction ? -1 : 1}
                itemCode={lineItem.itemCode}
                lineItem={lineItem}
              />
            </AxeleAccordion>
          </Box>
        );
      })}
      {!isViewMode && canEditInvoice && (
        // (!paidOrInvoicedStatus || isCreating) &&
        // // accessorialOptions.length > 0 &&
        // (invoiceCanceledStatus || isCreating) &&
        <Permission
          contains={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICE_ADD]}
        >
          <Box
            sx={(theme) => ({
              mt: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
                justifyContent: 'center',
                width: '100%',
              },
            })}
          >
            {!hasBaseLineItem && !(mainLineItem && invoiceType != 'LOAD') && (
              <Typography
                sx={{ color: 'primary.main', cursor: 'pointer', mr: 2 }}
                onClick={() => setAddBaseLineItemDialogOpen(true)}
              >
                + Add Base Item
              </Typography>
            )}
            {!paidOrInvoicedStatus && (
              <Typography
                id={invoiceModule + 'AddAccessorialItem'}
                sx={{ color: 'primary.main', cursor: 'pointer' }}
                onClick={() => setAddInvoiceLineItemDialogOpen(true)}
              >
                + Add{' '}
                {mainLineItem && invoiceType != 'LOAD'
                  ? 'Invoice'
                  : 'Accessorial'}{' '}
                Item
              </Typography>
            )}
          </Box>
        </Permission>
      )}
      {addInvoiceLineItemDialogOpen && (
        <InvoiceLineItemDialog
          options={accessorialOptions}
          open={addInvoiceLineItemDialogOpen}
          onAction={(data) => {
            delete data.amountDue;
            append(data);
            console.log('data is here On Create Flat rate', data);
            console.log('after save the value', getValues());
            setAddInvoiceLineItemDialogOpen(false);
          }}
          onClose={() => setAddInvoiceLineItemDialogOpen(false)}
          nameAmount={
            mainLineItem ? `lineItems.` : `childInvoices.${foundIndex}.`
          }
          nameText={
            mainLineItem && invoiceType != 'LOAD' ? 'Invoice' : 'Accessorial'
          }
        />
      )}
      {addBaseLineItemDialogOpen && (
        <InvoiceLineItemDialog
          options={lineItemOptions}
          open={addBaseLineItemDialogOpen}
          onAction={(data) => {
            delete data.amountDue;
            console.log('Hello word to check', data);
            append(data);
            setAddBaseLineItemDialogOpen(false);
          }}
          onClose={() => setAddBaseLineItemDialogOpen(false)}
          isItemBase
          nameAmount={
            mainLineItem ? `lineItems.` : `childInvoices.${foundIndex}.`
          }
        />
      )}
    </>
  );
}

export function InvoiceLineItems({
  nameStart,
  lineItemOptions = [],
  disabled = false,
  isItemBase = false,
  isCreating = false,
  isDeduction = 1,
  itemCode = '',
  itemType = '',
  requiredFields = {
    ...invoiceLineItemFormRequiredFields,
  },
  lineItem,
}: {
  nameStart: string;
  lineItemOptions: LineItem[];
  disabled?: boolean;
  isItemBase?: boolean;
  isCreating?: boolean;
  isDeduction?: number;
  itemCode?: string;
  itemType?: string;
  requiredFields?: InvoiceLineItemFormRequiredFields;
  lineItem: any;
}) {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const [deduction, setDeduction] = useState(1);
  const [formItemCode, setFormItemCode] = useState('');

  useEffect(() => {
    setDeduction(isDeduction);
  }, [isDeduction]);

  const [quantity, rate, amount] = watch([
    `${nameStart}quantity`,
    `${nameStart}rate`,
    `${nameStart}amount`,
  ]);
  useEffect(() => {
    if (lineItem?.custom) {
      setValue(`${nameStart}.type`, lineItem?.itemName);
      setValue(`${nameStart}.isRemove`, true);
    }
  }, []);
  useEffect(() => {
    setValue(`${nameStart}amount`, quantity * rate * deduction);
  }, [quantity, rate, deduction]);

  const theme = useTheme();

  const isPerLoadedMiles =
    isItemBase && (itemCode || formItemCode) === 'PER_LOADED_MILE';
  const isCreateModeBaseItem = isCreating && isItemBase;
  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <SingleAutocompleteForm
          id={invoiceModule + 'BaseType'}
          control={control}
          name={`${nameStart}.type`}
          fieldName={`itemName`}
          label={isItemBase ? 'Base Type' : 'Accessorial Type'}
          options={lineItemOptions}
          fieldValue={'itemCode'}
          onChangeCb={(data) => {
            if (!data) return;
            setFormItemCode(data.itemCode);
            setValue(`${nameStart}itemId`, data.id);
            setValue(`${nameStart}itemType`, data.itemType);
            setValue(`${nameStart}description`, data.description);
            setDeduction(data.deduction ? -1 : 1);
            if (data.itemCode === 'FLAT_RATE') {
              setValue(`${nameStart}quantity`, 1);
            }
          }}
          disabled={disabled}
          disableClear={isItemBase}
          required={
            isCreateModeBaseItem && !rate && rate !== 0
              ? false
              : requiredFields.type
          }
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
            flexDirection: 'column',
          },
        })}
      >
        {(!isItemBase || isPerLoadedMiles) && (
          <Box
            sx={(theme) => ({
              mr: 3,
              flex: 1,
              [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
                mr: 0,
              },
            })}
          >
            <TextField
              id={invoiceModule + 'Quantity'}
              control={control}
              name={`${nameStart}quantity`}
              label={'Quantity'}
              disabled={disabled}
              onlyNumbers
              required={
                isCreateModeBaseItem && !rate && rate !== 0
                  ? false
                  : requiredFields.quantity
              }
            />
          </Box>
        )}
        {/* This part is very tricky. For base line items instead of rate we show Amount as for this case they are the same, For more information connect with @Tamara */}
        <Box sx={{ flex: 1 }}>
          <TextField
            id={invoiceModule + 'BaseAmount'}
            required={isCreateModeBaseItem ? false : requiredFields.rate}
            control={control}
            name={`${nameStart}rate`}
            label={
              isPerLoadedMiles || !isItemBase ? 'Rate ($)' : 'Base Amount ($)'
            }
            onlyNumbers
            disabled={disabled}
          />
        </Box>
        {!isItemBase && (
          <Box
            sx={(theme) => ({
              flex: 1,
              ml: 3,
              [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
                ml: 0,
                mb: 2,
              },
            })}
          >
            <ReadOnlyField
              title={'Amount'}
              text={`${amountFormat(Math.abs(amount))}`}
              customStyles={{
                text: { color: `${theme.palette.primary.main} !important` },
              }}
            />
          </Box>
        )}
      </Box>
      {errors?.amountTotal?.message && (
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={(theme) => ({
              color: `${theme.palette.error.main} !important`,
            })}
          >
            {errors.amountTotal.message}
          </Typography>
        </Box>
      )}
      <Box>
        <TextField
          id={invoiceModule + 'Description'}
          control={control}
          name={`${nameStart}description`}
          label={'Description'}
          disabled={disabled}
        />
      </Box>
    </>
  );
}

export function InvoiceLineItemDialog({
  nameAmount,
  open,
  onAction,
  onClose,
  options = [],
  isItemBase = false,
  nameText,
}: {
  nameAmount: string;
  open: boolean;
  onAction: (data: {
    [key: string]: string | number | null | string[];
  }) => void;
  onClose: () => void;
  options: LineItem[];
  isItemBase?: boolean;
  nameText?: string;
}) {
  const { watch } = useFormContext();
  const [amountDue, amountDueTotal] = watch([
    `${nameAmount}amountDue`,
    `${nameAmount}amountDueTotal`,
  ]);

  const nameStart = '';

  return (
    <FormDialog
      data={{
        type: null,
        quantity: null,
        rate: null,
        amount: null,
        itemType: '',
        description: '',
        amountDue: amountDueTotal || amountDue,
      }}
      validationSchema={invoiceLineItemsDialogSchema}
      titleText={nameText ? `Add ${nameText} Item` : 'Add Accessorial Item'}
      // titleText="Add Accessorial Item"

      actionLabel="Add"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => (
        <InvoiceLineItems
          nameStart={nameStart}
          lineItemOptions={options}
          isItemBase={isItemBase}
        />
      )}
    />
  );
}
