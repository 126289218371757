import Button, { ButtonProps } from '@mui/material/Button';
import { FC, ReactNode } from 'react';

export interface ButtonImprovedProps
  extends Omit<ButtonProps, 'sx' | 'children'> {
  styleProps?: ButtonProps['sx'];
  label?: ReactNode;
}

export const ButtonImproved: FC<ButtonImprovedProps> = ({
  styleProps,
  label,
  ...props
}) => (
  <Button sx={{ ...styleProps }} size="small" {...props}>
    {label}
  </Button>
);

export default ButtonImproved;
