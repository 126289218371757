import { useEffect } from 'react';
import StorageManager from '../../../StorageManager/StorageManager';
import { hubspotService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { VisitorUpdateRequest } from '../../../models/DTOs/Hubspot/Requests';
import { getCurrentUser } from '../../Permission/utils/permissionUtils';

const initHideDefaultHubspotChatWidget = () => {
  window.hsConversationsSettings = {
    loadImmediately: false,
  };
  if (window.HubSpotConversations) {
    window.HubSpotConversations?.widget?.remove();
  } else {
    window.hsConversationsOnReady = [
      function () {
        window.HubSpotConversations?.widget?.remove();
      },
    ];
  }
};

const initHubspotSetting = async ({ userStorage, lsToken }) => {
  function onConversationsAPIReady() {
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: userStorage.email,
      identificationToken: lsToken,
    };
    window.HubSpotConversations?.widget?.load?.();
  }
  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
};

export const LS_HUBSPOT_TOKEN = 'hubspottoken';

export const useHubspot = () => {
  useEffect(() => {
    initHideDefaultHubspotChatWidget();
  }, []);
  const { userStorage } = getCurrentUser();

  const setIdentity = async () => {
    if (!userStorage) {
      return;
    }
    let lsToken = StorageManager.getItemWithExpiry(LS_HUBSPOT_TOKEN);
    if (!lsToken) {
      const response = await hubspotService.visitorUpdate(
        new VisitorUpdateRequest({
          email: userStorage.email,
          firstname: userStorage?.firstname,
          lastname: userStorage?.lastname,
        })
      );
      if (response instanceof ServiceError || response === null) return;
      if (response?.token) {
        lsToken = response.token;
        StorageManager.setItemWithExpiry(LS_HUBSPOT_TOKEN, lsToken, 43200000); //This token is temporary and will expire after 12 hours
      }
    }
    lsToken && initHubspotSetting({ userStorage, lsToken });
  };

  return {
    setIdentity,
  };
};
