export type LaneInfoType = 'CITY_BASED' | 'ZIP_BASED' | 'MILEAGE_BASED';

export enum LaneInfoEnum {
  city = 'CITY_BASED',
  zip = 'ZIP_BASED',
  mileage = 'MILEAGE_BASED',
}

export type NotificationType = 'SUCCESS' | 'ERROR';
export type PanelType = 'Add' | 'Contract' | 'Customer';

export enum ContractPanelType {
  add = 'Add',
  contract = 'Contract',
  customer = 'Customer',
}

export const LaneInfoPlaceholderEnum = {
  [LaneInfoEnum.city]: 'Lane',
  [LaneInfoEnum.zip]: 'Zip',
  [LaneInfoEnum.mileage]: 'Mileage',
};

type CenterLatLongType = {
  lat: string;
  long: string;
};

type LaneItemMode = 'FTL' | 'FTL/PARTIAL' | 'PARTIAL';
export interface LocationInfoType {
  city: string;
  state: string;
  zipStart: string;
  zipEnd: string;
  center: CenterLatLongType;
}

export interface LaneRateItemType {
  id: string;
  origin: LocationInfoType;
  destination: LocationInfoType;
  rateMethod: LaneInfoType;
  totalMiles: string;
  weight: string;
  equipmentType: string;
  mode: LaneItemMode;
  rateType: string;
  rateTypeId: string;
  rate: string;
  region: string;
  minimumAmount: string;
  description: string;
  modifiedDate?: string;
  minMiles?: string | number;
  maxMiles?: string | number;
}

export interface ContractApiSchema {
  id: string;
  organizationId: string;
  name: string;
  customerId: string;
  effectiveDate: string;
  expDate: string;
  notes: string;
  laneRateItems: LaneRateItemType[];
}

export type ZipBasedItemType =
  | 'zipStartOrigin'
  | 'zipEndOrigin'
  | 'destinationStartOrigin'
  | 'destinationEndOrigin'
  | 'totalMiles'
  | 'weight'
  | 'equipmentType'
  | 'mode'
  | 'rateType'
  | 'rate'
  | 'minimumAmount'
  | 'description';

export interface ZipBasedLaneRateItemHeaders {
  zipStartOrigin: string;
  zipEndOrigin: string;
  destinationStartOrigin: string;
  destinationEndOrigin: string;
  totalMiles: string;
  weight: string;
  equipmentType: string;
  mode: string;
  rateType: string;
  rate: string | number;
  minimumAmount: string | number;
  description: string;
}

export type CityBasedItemType =
  | 'origin'
  | 'destination'
  | 'totalMiles'
  | 'rateMethod'
  | 'weight'
  | 'equipmentType'
  | 'mode'
  | 'rateType'
  | 'rate'
  | 'minimumAmount'
  | 'description';

export interface CityBasedLaneRateItemHeaders {
  origin: string;
  destination: string;
  totalMiles: string;
  weight: string;
  equipmentType: string;
  mode: string;
  rateType: string;
  rate: string | number;
  minimumAmount: string | number;
  description: string;
}

export type MileageBasedItemType =
  | 'minMiles'
  | 'maxMiles'
  | 'totalMiles'
  | 'weight'
  | 'equipmentType'
  | 'mode'
  | 'rateType'
  | 'rate'
  | 'number'
  | 'minimumAmount'
  | 'description'
  | 'remove';

export interface MileageBasedLaneRateItemHeaders {
  minMiles: string;
  maxMiles: string;
  totalMiles: string;
  weight: string;
  equipmentType: string;
  mode: string;
  rateType: string;
  rate: string | number;
  minimumAmount: string | number;
  description: string;
  remove: React.ReactNode;
}
