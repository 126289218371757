export const InvoiceModalTranslations = {
  completedIV: 'Completed',
  canceledIV: 'Cancelled',
  invoicedIV: 'Invoiced',
  paidIV: 'Paid',
  origin: 'Origin',
  originState: 'Origin State',
  destinationState: 'Destination State',
  calculateDetention: 'Calculate Detention',
  shareWithTeam: 'Share with Team',
  saveView: 'Save View',
  delayedPayments: 'Delayed Payments',
  contacts: 'Contacts',
};
