import { Card } from '@mui/material';
import Lottie from 'lottie-react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import * as logo from '../../_assets/json/loading_animation.json';
import { useRootStore } from '../../store/root-store/rootStateContext';

const pathnamesWithoutGlobalLoaders = [
  '/',
  '/loads/trips',
  '/loads/loadboards',
  '/',
  '/dispatch',
  '/operations/contracts',
  '/settings/contracts',
];

export const LoadingAnimation = () => {
  return (
    <div
      style={{
        position: 'fixed',
        left: '0',
        right: 0,
        top: '0',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 99999,
      }}
    >
      <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
        <Card sx={{ padding: '1rem', backgroundColor: '#ffffff' }}>
          <Lottie animationData={logo} loop style={{ height: 100 }} />
        </Card>
      </div>
    </div>
  );
};
export const LoadingComponent = observer(() => {
  const location = useLocation();
  const { getLoading } = useRootStore();
  // @TODO Added location pathname checking here to hide loading animation in dashboard page. Solution is not good and should be removed and implemented in a correct way.
  return (
    <div>
      {getLoading &&
        !pathnamesWithoutGlobalLoaders.includes(location.pathname) && (
          <LoadingAnimation />
        )}
    </div>
  );
});
