import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { mainColor } from '../../constants';
import Select from '../../../../../common/Ui/Select';
import { useImport } from '../../../../../contexts/ImportContext';
import { ColumnMappingInfoType } from './constants';
import {
  ArrowRightIcon,
  MappedIcon,
  ShowIcon,
} from '../../../../../ui-kit/components/Assets';
import TextInput from '../../../../../ui-kit/components/TextField';

type pagePropTypes = {
  columnsMappingInfo: ColumnMappingInfoType[] | null;
  handleSetSampleData: (columnName: string) => void;
  handleSetUnmappedColumns: (hasUnmappedColumns: boolean) => void;
};

export default function Mapping({
  columnsMappingInfo,
  handleSetSampleData,
  handleSetUnmappedColumns,
}: pagePropTypes) {
  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { tableColumns } = useImport();

  const [mappedValues, setMappedValues] = useState<string[]>([]);
  const [unMappedValues, setUnMappedValues] = useState<string[]>([]);

  // effect for setting default mapped and unMapped fields
  useEffect(() => {
    if (columnsMappingInfo) {
      const defaultMappedValues: string[] = [];
      columnsMappingInfo.forEach((col) => {
        if (col.destinationColumn) {
          defaultMappedValues.push(col.destinationColumn);
        }
      });
      const defaultUnMappedValues = tableColumns
        .filter((col) => !defaultMappedValues.includes(col.field))
        .map((col) => col.field);

      setMappedValues(defaultMappedValues);
      setUnMappedValues(defaultUnMappedValues);
    }
  }, [columnsMappingInfo]);

  // effect for detecting do we have unmapped columns or not
  useEffect(() => {
    if (columnsMappingInfo) {
      if (mappedValues.length < columnsMappingInfo.length) {
        handleSetUnmappedColumns(true);
      } else {
        handleSetUnmappedColumns(false);
      }
    }
  }, [mappedValues, columnsMappingInfo]);

  const columnsFromFile = tableColumns.map((c) => c.field);

  const formValues = watch();

  // handles column changes
  const handleOnColumnSelect = (data: string, name: string) => {
    const currentValues = getValues();
    // If the user selects already selected the old one should be reset
    for (const property in currentValues) {
      if (currentValues[property] === data) {
        setValue(property, '');
      }
    }
    // If the user selects already selected the old one should be remove from mapped values
    if (!mappedValues.includes(data)) {
      // if we change mapped value with unmapped value
      if (formValues[name]) {
        // 2 actions below. remove mapped value from mapped values and add to unMapped values
        const newTempMappedValues = mappedValues.filter(
          (v) => v !== formValues[name]
        );
        const newMappedValues = [...newTempMappedValues, data];
        // 2 actions below. new unmapped value add to mapped values and remove from unMapped values
        const newTempUnMappedValues = [...unMappedValues, formValues[name]];
        const newUnMappedValues = newTempUnMappedValues.filter(
          (v) => v !== data
        );
        setMappedValues(newMappedValues);
        setUnMappedValues(newUnMappedValues);
      } else {
        // if we change unmapped value with another unmapped value
        const newMappedValues = [...mappedValues, data];
        const newUnMappedValues = unMappedValues.filter((v) => v !== data);
        setMappedValues(newMappedValues);
        setUnMappedValues(newUnMappedValues);
      }
    } else {
      // if changing column which currently does not have any value with existing value
      if (formValues[name]) {
        const currentValue = formValues[name];
        const newMappedValues = mappedValues.filter((v) => v !== currentValue);
        const newUnMappedValues = [...unMappedValues, currentValue];
        setMappedValues(newMappedValues);
        setUnMappedValues(newUnMappedValues);
      }
    }
  };

  const handleShowSampleData = (columnName: string) => {
    handleSetSampleData(columnName);
  };

  if (!columnsMappingInfo) {
    return null;
  }

  const groupedData = {
    'Unmapped Column(s)': unMappedValues,
    'Mapped Column(s)': mappedValues,
  };

  return (
    <>
      {columnsMappingInfo.map((r, i) => (
        <Box
          key={i}
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: errors[r.sourceColumnCode]
              ? 'rgba(244, 67, 54, 0.08)'
              : 'transparent',
            mb: 3,
            p: 1,
          }}
        >
          <Box sx={{ flex: 4 }}>
            <TextInput
              placeholder={r.sourceColumn}
              disabled
              defaultValue={r.sourceColumn}
              label={`LoadOps Column`}
              variant={'standard'}
              styleProps={{ width: '100%' }}
              required={r.mandatory}
            />
          </Box>
          <Box sx={{ mr: 3, ml: 3 }}>
            {formValues[r.sourceColumnCode] ? (
              <MappedIcon />
            ) : (
              <ArrowRightIcon opacity={'0.6'} />
            )}
          </Box>
          <Box sx={{ flex: 4 }}>
            <Select
              control={control}
              label={'Columns in File'}
              name={r.sourceColumnCode}
              options={columnsFromFile}
              onChangeCallback={handleOnColumnSelect}
              rules={{ required: r.mandatory }}
              disableDefaultValidation
              groupInfo={groupedData}
              style={{ marginTop: 0, marginBottom: 0 }}
            />
          </Box>
          <Box
            onClick={() => {
              if (formValues[r.sourceColumnCode]) {
                handleShowSampleData(formValues[r.sourceColumnCode]);
              }
            }}
            sx={{ mr: 3, ml: 3, cursor: 'pointer' }}
          >
            <ShowIcon
              width={20}
              height={14}
              color={
                formValues[r.sourceColumnCode]
                  ? mainColor
                  : 'rgba(0, 17, 34, 0.35)'
              }
            />
          </Box>
        </Box>
      ))}
    </>
  );
}
