import { Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

export interface KPIItemProps {
  title: string;
  subTitle: string;
}
export const KPIItem = ({ title, subTitle }: KPIItemProps): JSX.Element => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'uncategorized.oldHeaderSecondary',
        borderRadius: '8px',
        padding: '8px',
      }}
    >
      <Typography
        variant="kpiValue"
        sx={{
          color: 'primary.main',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          color: 'text.secondary',
        }}
      >
        {subTitle}
      </Typography>
    </Box>
  );
};

interface KPIProps {
  data: KPIItemProps[];
}

export const KPI: FC<KPIProps> = ({ data, isLoading = true }) => {
  return (
    <Stack
      sx={{
        width: '100%',
        p: 2,
        pt: 0,
        pb: 1,
      }}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {isLoading ? (
        <Stack direction={'row'} spacing={1}>
          <Skeleton variant="rectangular" height={'4rem'} width={'10rem'} />
          <Skeleton variant="rectangular" height={'4rem'} width={'10rem'} />
          <Skeleton variant="rectangular" height={'4rem'} width={'10rem'} />
        </Stack>
      ) : (
        data.map((item: KPIItemProps, idx: number) => (
          <KPIItem {...item} key={idx} />
        ))
      )}
    </Stack>
  );
};
