import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useImport } from '../../../../../contexts/ImportContext';
import UploadProgress from './UploadProgress';
import UploadedResult from './UploadedFileResult';
import { BrowseFile } from './BrowseFile';
import ImportActions from '../../Import';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';

export default observer(function Step1() {
  const {
    file,
    uploadingFile,
    tableData,
    warnings,
    currentEntity: { name: entityName },
  } = useImport();
  const { getAllTerminals } = useRootStore();
  ImportActions.terminals = getAllTerminals.map(
    (t: { companyName: string }) => t.companyName
  );
  const [uploadErrors, setUploadErrors] = useState('');

  const handleUploadBackendError = (error: string) => setUploadErrors(error);

  useEffect(() => {
    ImportActions.getStatic(entityName);
  }, [entityName]);

  // backend error case show only red error progress
  if (uploadErrors) {
    return (
      <UploadProgress
        value={80}
        state={'error'}
        infos={[{ message: uploadErrors }]}
      />
    );
  }
  // zero state. user opened modal-> browse file page
  if (!file && !uploadingFile && !uploadErrors) {
    return <BrowseFile setBackendErrors={handleUploadBackendError} />;
  }
  // upload in progress. Waiting backend response
  if (uploadingFile) {
    return <UploadProgress uploading={uploadingFile} />;
  }
  // data successfully received from backend
  if (tableData) {
    const hasWarnings = !!warnings.length;
    return (
      <>
        <UploadProgress
          uploading={false}
          state={hasWarnings ? 'warning' : 'success'}
          infos={hasWarnings ? warnings : [{ message: `Upload Completed` }]}
        />
        <UploadedResult />
      </>
    );
  }
  return <></>;
});
