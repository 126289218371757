export const LoadAiText = () => {
  return (
    <svg
      width="160"
      height="40"
      viewBox="0 0 160 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.000244141 39.3286V4.66675H8.79575V31.4373H22.9011V39.3239H0.000244141V39.3286ZM27.1529 30.8903V21.044C27.1529 15.4168 29.9825 12.6056 35.6467 12.6056H42.7234C48.3875 12.6056 51.2172 15.4168 51.2172 21.044V30.8903C51.2172 36.5174 48.3875 39.3286 42.7234 39.3286H35.6467C29.9825 39.3286 27.1529 36.5174 27.1529 30.8903ZM35.5988 30.7904C35.5988 31.228 35.8191 31.442 36.2548 31.442H42.1201C42.5558 31.442 42.776 31.2232 42.776 30.7904V20.5445C42.776 20.1117 42.5558 19.8928 42.1201 19.8928H36.2548C35.8143 19.8928 35.5988 20.1117 35.5988 20.5445V30.7904ZM83.1051 30.8903V21.044C83.1051 15.4168 85.9348 12.6056 91.599 12.6056H98.2208V4.66675H106.667V39.3286H91.599C85.9348 39.3286 83.1051 36.5174 83.1051 30.8903ZM91.5511 30.7904C91.5511 31.228 91.7714 31.442 92.2071 31.442H98.2256V19.8881H92.2071C91.7666 19.8881 91.5511 20.1069 91.5511 20.5398V30.7856V30.7904ZM63.5367 39.3286C57.8725 39.3286 55.0428 36.5983 55.0428 31.1424C55.0428 25.6865 57.8725 22.9561 63.5367 22.9561H69.3493V20.5445C69.3493 20.1117 69.129 19.8928 68.6933 19.8928H58.0257V12.6104H69.3014C74.9656 12.6104 77.7953 15.4216 77.7953 21.0487V39.3334H63.5367V39.3286ZM63.484 30.7904C63.484 31.228 63.7043 31.442 64.14 31.442H69.3493V28.6784H64.14C63.6995 28.6784 63.484 28.8972 63.484 29.3301V30.7856V30.7904Z"
        fill="white"
      />
      <path
        d="M113.822 37.724L123.782 4.64244H137.066L147.026 37.724L147.51 39.3334H138.493L136.756 33.1484H124.087L122.35 39.3334H113.333L113.817 37.724H113.822ZM126.438 25.2542H134.405L130.729 12.5319H130.114L126.438 25.2542ZM151.263 8.46102V0.666748H160V8.46102H151.263ZM151.365 39.3334V12.5843H159.893V39.3334H151.365Z"
        fill="white"
      />
    </svg>
  );
};
