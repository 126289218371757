import {
  useEffect,
  useState,
  FC,
  createContext,
  PropsWithChildren,
  useContext,
} from 'react';
import useFilterStorage from '../services/storage';
import {
  documentType,
  ManageTypeEntityType,
  manageTypesMap,
  component,
} from '../subPages/settings/ManageTypes/constants';
import { ManageTypesItem } from '../models/DTOs/ManageTypes/Requests';

export type ManageTypesContextType = {
  entity: ManageTypeEntityType;
  setEntity: (entity: ManageTypeEntityType) => void;
  manageTypesList: ManageTypesItem[];
  handleUpdateManageTypesList: (data: ManageTypesItem[]) => void;
};

export const ManageTypesContext = createContext<ManageTypesContextType>(
  {} as ManageTypesContextType
);

export const ManageTypesContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const LStorageHook = useFilterStorage({ page: component });
  const storageData = LStorageHook.getStorage() || {};

  const [entity, setEntity] = useState<ManageTypeEntityType>(
    storageData[component]
  );
  const [manageTypesList, setManageTypesList] = useState<ManageTypesItem[]>([]);

  const handleUpdateManageTypesList = (data: ManageTypesItem[]) => {
    setManageTypesList(data);
  };

  useEffect(() => {
    setEntity(manageTypesMap[storageData.selectedTab] || documentType);
  }, []);

  return (
    <ManageTypesContext.Provider
      value={{
        entity,
        setEntity,
        manageTypesList,
        handleUpdateManageTypesList,
      }}
    >
      {children}
    </ManageTypesContext.Provider>
  );
};

export const useManageTypes = () => useContext(ManageTypesContext);
