import { action, makeObservable, observable, runInAction } from 'mobx';
import { ISettingsPagination } from '../../contexts/TeamDriverContext';
import useFilterStorage from '../../services/storage';
import { loadAndTripPageSize } from '../../utils';
import {
  defaultFinanceData,
  weekCalculation,
} from '../../views/home/constants/constant';
import {
  getAssignTripsTotal,
  getDriverAvailabilityData,
  getFinanceData,
  getInvoiceData,
  getMapsData,
  getSafetyData,
  getTractorAvailabilityData,
  getTripsSummaryData,
} from '../../views/home/constants/helper';
import { InvoiceCardName } from '../../views/home/constants/interface';

const defaultSettingsPagination: Partial<ISettingsPagination> = {
  isLoading: true,
  first: false,
  last: false,
  pageNumber: 0,
  pageSize: loadAndTripPageSize,
};

class DashboardStore {
  LStorage = useFilterStorage({ page: 'Home' });
  storageValue = this.LStorage.getStorage() || {};
  preFilters = this.storageValue.filters;

  @observable invoiceDelay = {};
  @observable invoicePending = {};
  @observable invoiceMissing = {};
  @observable invoiceReady = {};
  @observable mapsData = null;
  @observable revenue =
    this.preFilters && this.preFilters.revenue
      ? this.preFilters.revenue
      : 'GROSS';
  @observable plan =
    this.preFilters && this.preFilters.plan ? this.preFilters.plan : 'Actual';
  @observable WeekCalculationValue = '';
  @observable financeData = defaultFinanceData;
  @observable safetyData = {};
  @observable driverAvailabilityData = {};
  @observable tripsSummaryData = {};
  @observable invoiceDelayLoader = true;
  @observable invoicePendingLoader = true;
  @observable invoiceMissingLoader = true;
  @observable tractorAvailabilityData = {};
  @observable invoiceReadyLoader = true;
  @observable safetyLoader = true;
  @observable financeLoader = true;
  @observable driverAvailabilityLoader = true;
  @observable tractorAvailabilityLoader = true;
  @observable tripsSummaryLoader = true;

  constructor() {
    makeObservable(this);
  }

  @action
  getInvoice = async (
    terminalIds: string[] = [],
    cardName: InvoiceCardName
  ) => {
    try {
      const getRes = await getInvoiceData(terminalIds, cardName);
      if (cardName == 'delay') {
        this.invoiceDelay = getRes ? getRes : {};
        this.invoiceDelayLoader = false;
      } else if (cardName == 'pending') {
        this.invoicePending = getRes ? getRes : {};
        this.invoicePendingLoader = false;
      } else if (cardName == 'missing') {
        this.invoiceMissing = getRes ? getRes : {};
        this.invoiceMissingLoader = false;
      } else if (cardName == 'ready') {
        this.invoiceReady = getRes ? getRes : {};
        this.invoiceReadyLoader = false;
      } else {
        return {};
      }
    } catch (e) {}
  };

  @action
  getMapsDataHandler = async (terminalIds: string[]) => {
    try {
      const requetBody = {
        hasTimeOff: false,
        hasViolation: false,
        needLoad: false,
        excludeTimeline: false,
        pageNumber: 1,
        pageSize: 20,
        sort: '+groupName',
        terminalIds: terminalIds,
      };
      const getRes: any = await getMapsData(requetBody);
      this.mapsData = getRes ? getRes : {};
    } catch (e) {}
  };

  @action
  getSafety = async (terminalIds: string[] = []) => {
    try {
      const getRes = await getSafetyData(terminalIds);
      this.safetyData = getRes ? getRes : {};
      this.safetyLoader = false;
    } catch (e) {}
  };

  @action
  getFinance = async (
    isIncludePrimaryTerminal = true,
    revenueType = 'GROSS',
    planType = 'Actual',
    terminalIds: string[] = []
  ) => {
    try {
      this.financeLoader = true;
      const tempWeekCal = weekCalculation();
      this.WeekCalculationValue = tempWeekCal;
      const getRes = await getFinanceData(
        isIncludePrimaryTerminal,
        revenueType,
        planType,
        terminalIds
      );
      runInAction(() => {
        this.financeData = getRes ? getRes : defaultFinanceData;
        this.financeLoader = false;
      });
    } catch (e) {
      this.financeLoader = false;
    }
  };

  ChangeLocalStorage = async (name: string, value: string) => {
    const LStorageHook = useFilterStorage({ page: 'Home' });
    const storageData = LStorageHook.getStorage() || {};
    const oldFilters = storageData.filters;
    const newFilters = { ...oldFilters, [name]: value };
    LStorageHook.updateStorage('filters', newFilters);
  };

  @action
  onPlanChange = async (value: string) => {
    if (value) {
      this.plan = value;
      await this.getFinance(true, this.revenue, value, []);
      this.ChangeLocalStorage('plan', value);
    }
  };

  @action
  onRevenueChange = async (value: string) => {
    if (value) {
      this.revenue = value;
      await this.getFinance(true, value, this.plan, []);
      this.ChangeLocalStorage('revenue', value);
    }
  };

  @action
  getDriverAvailability = async (terminalIds: string[] = []) => {
    try {
      this.driverAvailabilityLoader = true;
      const getRes = await getDriverAvailabilityData(terminalIds);
      this.driverAvailabilityData = getRes ? getRes : {};
      this.driverAvailabilityLoader = false;
    } catch (e) {
      this.driverAvailabilityLoader = false;
    }
  };

  @action
  getTractorAvailability = async (terminalIds: string[] = []) => {
    try {
      this.tractorAvailabilityLoader = true;
      const getRes = await getTractorAvailabilityData(terminalIds);
      this.tractorAvailabilityData = getRes ? getRes : {};
      this.tractorAvailabilityLoader = false;
    } catch (e) {
      this.tractorAvailabilityLoader = false;
    }
  };

  @action
  getTripsSummary = async (terminalIds: string[] = []) => {
    try {
      this.tripsSummaryLoader = true;
      const getRes = await getTripsSummaryData(terminalIds);
      const tempTripSummaryData = getRes ? getRes : {};
      const getResAssign = await getAssignTripsTotal(terminalIds);
      tempTripSummaryData.assignTripsTotal = getResAssign ? getResAssign : {};
      this.tripsSummaryData = tempTripSummaryData;
      this.tripsSummaryLoader = false;
    } catch (e) {
      this.tripsSummaryLoader = false;
    }
  };
}

export function createDashboardStore() {
  return new DashboardStore();
}
