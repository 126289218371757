import {
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';
import useFilterStorage from '../../../services/storage';
import { GridColDefSelf, View } from '../../../types';
import { generateColumns, getTableColumns } from '../../../utils';
import {
  onColumnOrderChange,
  prepareColumnOrdering,
} from '../../../utils/gridColumnOrdering';

export const useFilterDataGrid = <TFilters extends Record<any, any> = any>({
  component,
  allColumns,
  currentView,
  currentViewPrimaryKey,
  setFilters,
  setTableColumns,
  setAllColumns,
  defaultFilters,
  isUpdateStorage = true,
  setColVisibility,
}: {
  component: string;
  allColumns: GridColDefSelf[];
  currentView: View;
  currentViewPrimaryKey: 'id';
  setTableColumns: Dispatch<SetStateAction<GridColDefSelf[]>>;
  setAllColumns: Dispatch<SetStateAction<GridColDefSelf[]>>;
  defaultFilters: TFilters;
  setFilters?: Dispatch<SetStateAction<TFilters>>;
  isUpdateStorage?: boolean;
  setColVisibility?: (data: Record<string, boolean>) => void;
  colVisibility?: { any: boolean }[];
}) => {
  const LStorageHook = useFilterStorage({ page: component });
  const stripId: string = currentView[
    currentViewPrimaryKey as keyof typeof currentView
  ] as string;

  //Important: Central columns handler here
  const changeColumnsHandler = (
    columnsData: Record<string, boolean>,
    allColumnsConfig: GridColDefSelf[]
  ): void => {
    const generatedTableColumns = getTableColumns(
      columnsData,
      allColumnsConfig
    );
    const orderedTableColumns = prepareColumnOrdering(
      generatedTableColumns,
      component,
      stripId
    );
    setTableColumns(orderedTableColumns);

    const generatedAllColumns = generateColumns(columnsData, allColumnsConfig);

    setTableColumns(generatedTableColumns);

    const orderedColumns = prepareColumnOrdering(
      generatedAllColumns,
      component,
      stripId
    );
    setAllColumns(orderedColumns);
    if (setColVisibility) {
      setColVisibility(columnsData);
    }
  };

  const handleChangeColumns = (columns: { [key: string]: boolean }): void => {
    const storageData = LStorageHook.getStorage() || {};
    const storageLoad = storageData[stripId];
    storageLoad.columns = columns;
    LStorageHook.updateStorage(stripId, storageLoad);
    changeColumnsHandler(columns, allColumns);
  };

  const setDefaultFilters = useCallback((): TFilters => {
    const storageData = LStorageHook.getStorage() || {};
    const defFilters = !currentView.default
      ? currentView.metaData
      : storageData?.[stripId]?.filters;
    LStorageHook.updateStorage(stripId, {
      ...storageData?.[stripId],
      filters: defFilters,
    });
    setFilters?.(defFilters);
    return defFilters;
  }, [currentView]);

  const setDefaultColumns = () => {
    const storageData = LStorageHook.getStorage() || {};
    const columnsData = currentView.default
      ? storageData[stripId]?.columns
      : currentView.columnFilters; //|| currentView.columnFilters
    allColumns?.forEach?.((e) => {
      if (columnsData && columnsData?.[e?.field] !== false) {
        columnsData[e?.field] = true;
      }
    });
    LStorageHook.updateStorage(stripId, {
      ...storageData[stripId],
      columns: columnsData,
    });
    changeColumnsHandler(columnsData, allColumns);
  };

  const onAllFilterSubmit = (data: any): Partial<TFilters> => {
    if (currentView.default) {
      const storageData = LStorageHook.getStorage() || {};
      if (isUpdateStorage)
        LStorageHook.updateStorage(stripId, {
          ...storageData[stripId],
          filters: data,
        });
    }
    setFilters?.((oldFilters) => {
      return { ...oldFilters, ...data };
    });
    return data || {};
  };

  const onAllFilterReset = (): TFilters => {
    if (!currentView.default) {
      setFilters?.(currentView.metaData as TFilters);
      return currentView.metaData as TFilters;
    } else {
      const storageData = LStorageHook.getStorage() || {};
      if (isUpdateStorage)
        LStorageHook.updateStorage(stripId, {
          ...storageData[stripId],
          filters: defaultFilters,
        });
      setFilters?.(defaultFilters);
      return defaultFilters;
    }
  };

  const resetSettings = () => {
    if (currentView.default) {
      const storageData = LStorageHook.getStorage() || {};
      const storageLoad = storageData[stripId];
      storageLoad.columns = currentView.columnFilters;
      storageLoad.columnsOrdering = []; //Important: reset columnsOrdering
      LStorageHook.updateStorage(stripId, storageLoad);
      setTableColumns(cloneDeep(allColumns));
      setAllColumns(cloneDeep(allColumns));
    } else {
      changeColumnsHandler(currentView.columnFilters, allColumns);
    }
    changeColumnsHandler(currentView.columnFilters, allColumns);
  };

  const onPageFilterChange = (
    data: string[],
    name: keyof TFilters
  ): Partial<TFilters> => {
    let newFilters;
    if (currentView.default) {
      const storageData = LStorageHook.getStorage() || {};
      if (storageData[stripId]?.filters) {
        const oldFilters = storageData[stripId].filters;
        newFilters = { ...oldFilters, [name]: data };
      } else {
        newFilters = { [name]: data };
      }
      if (isUpdateStorage)
        LStorageHook.updateStorage(stripId, {
          ...storageData[stripId],
          filters: newFilters,
        });
    }
    setFilters?.((oldFilters) => ({
      ...oldFilters,
      [name]: data,
    }));
    return (
      newFilters ||
      ({
        [name]: data,
      } as TFilters)
    );
  };

  const onColumnOrderChangeHandler = (
    params: GridColumnOrderChangeParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    onColumnOrderChange(
      {
        params,
        event,
        details,
      },
      component,
      stripId
    );
  };

  return {
    setDefaultColumns,
    setDefaultFilters,
    handleChangeColumns,
    onPageFilterChange,
    onAllFilterSubmit,
    onAllFilterReset,
    resetSettings,
    onColumnOrderChangeHandler,
  };
};
