import { CircularProgress, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { FC } from 'react';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../../ui-kit/components/IconsComponent';
import ShakeButton from '../../ShakeButton/ShakeButton';

export interface FooterProps {
  onSave: () => void;
  onCancel: () => void;
  isSaving?: boolean;
  height?: string;
  sx?: SxProps;
}

export const Footer: FC<FooterProps> = ({
  onSave,
  onCancel,
  isSaving,
  height,
  sx,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="end"
      sx={{ height: height || 'auto', ...sx }}
    >
      <ButtonImproved
        variant="outlined"
        onClick={onCancel}
        label={t('cancel')}
        size="large"
        styleProps={{
          marginRight: '10px',
          borderRadius: '5px',
          width: '150px',
        }}
      />
      <ShakeButton
        size="large"
        variant="contained"
        disabled={isSaving}
        label={t('saveChanges')}
        startIcon={
          isSaving ? (
            <CircularProgress size={20} />
          ) : (
            <IconsComponent iconName="Save" source="MUIcons" />
          )
        }
        onClick={onSave}
      />
    </Box>
  );
};
