import TabsV2 from '../../ui-kit/components/TabsV2';
import { TABS_CONSTANT } from '../../constants/contracts';
import { Box } from '@mui/material';

const TabStripRenderer = (props: any) => {
  const { onChangeTab, selectedTab, tabs } = props;
  return (
    <Box className={'CustomViews-root'} sx={{ flex: 1 }}>
      <TabsV2<'key', any>
        keyName="key"
        tabs={tabs || TABS_CONSTANT}
        selectedTab={selectedTab}
        isDarkMode
        autoFill
        {...props}
        labelCentered
        autoCollapse={false}
        keepSelectedTabOnFirstRow={false}
        setSelectedTab={(tab) => {
          onChangeTab?.(tab.key);
        }}
      />
    </Box>
  );
};

export default TabStripRenderer;
