import { useEffect, useState } from 'react';
import { financeLoadService } from '../../../api';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import InvoiceActions from '../../../subPages/invoices/InvoiceActions';
import CreateLoad from '../../../subPages/loadsList/CreateLoad';
import { ILoadDetails } from '../../../subPages/loadsList/CreateLoad/types/types';
import LoadController from '../../../subPages/loadsList/LoadController';
import { LoadDetailsSummary } from '../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { getLoadDuplicateDetailsFromMyLoad } from '../../../subPages/loadsList/LoadDetailsPanel/utils/myLoad';
import { LoadSummary } from '../../../subPages/loadsList/models/LoadSummary';
import { formatLoadSummaryToCreateLoadForm } from '../../../views/loadboards/LoadboardDataGrid/dto/loadboard-create-load.dto';
import { getTrailerTypes } from '../../LoadSharedComponents/utils';
import { IHandleCreateLoad } from '../constants/interface';

export interface CommonCreateLoadPanelProps extends IHandleCreateLoad {
  loadData: LoadSummary;
  onClose: () => void;
  isDuplicate: boolean;
}
export const CommonCreateLoadPanel = ({
  loadData,
  onClose: _onClose,
  onCreatedLoadHandler,
  isDuplicate = false,
}: CommonCreateLoadPanelProps) => {
  const [newLoadPanelState, setCreateLoadPanelState] = useState<{
    open: boolean;
    data: LoadDetailsSummary | ILoadDetails;
  }>({ open: false, data: {} as LoadDetailsSummary });
  const { isTerminalEnabled, allTerminals } = useRootStore();

  const onClose = (): void => {
    setCreateLoadPanelState({
      open: false,
      data: {} as LoadDetailsSummary,
    });
    _onClose();
  };

  const getLoadData = async (): Promise<void> => {
    //TODO : important need to fetch and caching the invoice type at first time
    await InvoiceActions.getInvoiceLineItems();

    //GetinvoiceDetail -> get the lineItems data to populate into Invoice Details section
    const invoiceDetail = await financeLoadService.GetinvoiceDetail(
      loadData.id
    );
    const loadDuplicateDetails =
      await LoadController.instance().findLoadDuplicateDetails(loadData.id);
    if (loadDuplicateDetails !== null) {
      const { trailerTypes } = loadDuplicateDetails.loadDetails;
      loadDuplicateDetails.loadDetails.trailerTypes = await getTrailerTypes(
        trailerTypes
      );

      const loadDetails = getLoadDuplicateDetailsFromMyLoad({
        invoiceDetail,
        loadDuplicateDetails,
      });
      const createLoadSchema = await formatLoadSummaryToCreateLoadForm({
        loadDetails: new LoadDetailsSummary(loadDetails),
        isTerminalEnabled,
        allTerminals,
      });
      if (createLoadSchema) {
        createLoadSchema.isManualLoadCreation = false;
        createLoadSchema?.stops?.forEach((e) => {
          if (e) e.enableTimezone = true;
        });
      }
      setCreateLoadPanelState({
        open: true,
        data: createLoadSchema,
      });
    }
  };

  const init = () => {
    getLoadData();
  };

  const onCreated = async (load: LoadDetailsSummary) => {
    onCreatedLoadHandler?.(load);
  };
  useEffect(() => {
    if (loadData) {
      init();
    }
  }, [loadData]);

  return (
    <CreateLoad
      open={newLoadPanelState.open}
      value={newLoadPanelState.data as ILoadDetails}
      onClose={onClose}
      onLoadCreated={onCreated}
    />
  );
};
