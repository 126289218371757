import { Box } from '@mui/material';
import { PaymentDetailsDTO } from '../../../../../models/DTOs/Payment/DTOs';
import { EPaymentPanelTab } from '../../constants/types';
import { CreatePaymentForm } from '../Form/CreatePaymentForm';
export interface PaymentDetailsFormProps {
  isCreatePanel?: boolean;
  isCustomerDisabled?: boolean;
  isDisabled?: boolean;
  selectedTab: EPaymentPanelTab;
  paymentDetailsDTO: PaymentDetailsDTO | null;
}
export default function PaymentDetailsForm({
  isCreatePanel = false,
  selectedTab,
  paymentDetailsDTO,
}: PaymentDetailsFormProps) {
  if (isCreatePanel) {
    return (
      <CreatePaymentForm
        isCreatePanel={isCreatePanel}
        paymentDetailsDTO={paymentDetailsDTO}
      />
    );
  }

  return (
    <Box>
      {selectedTab === EPaymentPanelTab.OVERVIEW && paymentDetailsDTO && (
        <CreatePaymentForm
          isCreatePanel={isCreatePanel}
          paymentDetailsDTO={paymentDetailsDTO}
        />
      )}
    </Box>
  );
}
