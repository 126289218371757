import React from 'react';
import ActionIconButton from './ActionIconButton';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider, SxProps, Theme } from '@mui/material';
import { ReassignOptionTypes } from '../../types/recommendTrips.types';

interface IReassignResource {
  id?: string;
  options?: ReassignOptionTypes[];
  onSelect?: (val: ReassignOptionTypes) => void;
  disabled?: boolean;
  onReassignClick?: () => void;
  btnStyles?: SxProps;
  label?: string;
  iconStyles?: SxProps;
}

const ReassignResource: React.FC<IReassignResource> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event?: React.MouseEvent<HTMLElement>) => {
    props?.onReassignClick?.();
    setAnchorEl(event?.currentTarget as HTMLElement);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <ActionIconButton
        id={props?.id ?? 'reassign-button'}
        onClick={handleOpenMenu}
        className="lock-button"
        disabled={props?.disabled}
        style={props?.btnStyles}
      >
        <>
          <AutorenewOutlinedIcon
            sx={{
              width: '14px',
              height: '14px',
              pointerEvents: 'none',
              ...props?.iconStyles,
            }}
          />
          {props?.label}
        </>
      </ActionIconButton>

      <Menu
        id={props?.id ?? 'reassign-button'}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            borderRadius: '14px',
          },
          '.MuiList-root': {
            minWidth: '280px',
            boxShadow:
              '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          },
          '.MuiMenuItem-root': {
            padding: '8px 16px',
          },
          '.Mui-disabled': {
            opacity: '1!important',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'reassign-resource',
        }}
      >
        <MenuItem
          key={'title'}
          onClick={handleClose}
          sx={{ fontWeight: 700, fontSize: '16px' }}
          disabled
        >
          Show Options for:
        </MenuItem>
        <Divider sx={{ background: '#BDBDBD' }} />
        {props?.options?.map((option: ReassignOptionTypes) => (
          <MenuItem
            key={option.id}
            onClick={() => {
              handleClose();
              props?.onSelect?.(option);
            }}
          >
            {option?.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ReassignResource;
