import { Theme } from '@mui/material';

export function getCommonDataGridStyles(theme: Theme, showFocus) {
  return {
    '& .MuiDataGrid-main': {
      border: '1px solid #BDBDBD',
      borderRadius: '5px',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      fontWeight: 500,
      justifyContent: 'center',
      letterSpacing: '0.14px',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: '#1F2E3D',
      fontFamily: 'poppins',

      '&:focus': {
        outline: 'none',
        weight: 400,
      },
      '&:hover': {
        cursor: 'pointer',
        // color: theme.palette.primary.main,
      },
    },
    '& .Mui-pinned > .MuiDataGrid-cell': {
      borderColor: 'transparent!important',
    },
    '& .MuiDataGrid-row': {
      backgroundColor: theme.palette.background.paper,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: '1px solid #BDBDBD',
      '&:hover': {
        backgroundColor: '#BDBDBD15',
        cursor: 'pointer',
      },
      '&:nth-child(1)': {
        borderLeft: '0px !important',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      color: 'transparent!important',
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: '#EEEEEE',
      alignItems: 'center',
      fontWeight: 600,
      border: 1,
      borderRight: 0,
      borderColor: '#BDBDBD',
      textAlign: 'center!important',
      borderTop: 0,
      fontSize: '12px',
      '&:hover': {
        outline: 0,
      },
      '&:nth-child(1)': {
        borderLeft: 0,
      },
    },

    '& .MuiDataGrid-cell': {
      border: 1,
      borderRight: 0,
      borderTop: 0,
      pr: 1,
      pl: 1,
      fontSize: '14px',
      borderColor: '#BDBDBD',
      fontColor: theme.palette.text.primary,
      letterSpacing: '0.15px',
      '&:focus': {
        '&:focus': {
          outline: showFocus ? '2px solid blue' : 'none',
          weight: 400,
        },
      },
    },
    '& .MuiDataGrid-cellContent': {
      fontFamily: 'Poppins',
      color: theme.palette.text.primary,
      fontSize: '14px',
      lineHeight: '143%',
      fontWeight: 400,
    },
    '& .MuiDataGrid-cell.error': {
      color: theme.palette.error.main,
    },
    '& .MuiDataGrid-cell.warning': {
      color: theme.palette.warning.main,
    },
    '& .MuiDataGrid-cell.primary': {
      color: theme.palette.primary.main,
    },
    '& .error': {
      backgroundColor: '#EB160733',
      border: '1px solid #F4433614',
    },
    '& .warning': {
      backgroundColor: theme.palette?.other?.gridWarningColor,
    },
    //scrollbar begin
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: theme.palette?.uncategorized?.panel,
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: '27px',
      backgroundColor: theme.palette?.other?.gridScrollbarColor,
      minHeight: 12,
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
      backgroundColor: theme.palette?.other?.gridScrollbarActiveColor,
    },
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
      backgroundColor: theme.palette?.other?.gridScrollbarActiveColor,
    },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette?.other?.gridScrollbarActiveColor,
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    //scrollbar end
  };
}
