import Box from '@mui/material/Box';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import PhoneField from '../../ui-kit/components/PhoneFieldWithContryandExtn/PhoneField';
import TextInput from '../../ui-kit/components/TextField';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ServiceError } from '../../api/interfaces';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import {
  activateUserURL,
  getInactiveDriverUserMetadata,
} from '../../common/Layouts/util';
import { countryCodeOptions } from '../../constants/contacts';
import { useRootStore } from '../../store/root-store/rootStateContext';
import { userActivationConfig } from './constant';
import {
  getPhoneFieldStylePropsAuthForm,
  StyledAuthFromWrapper,
  StyledFormTitle,
  SxPropAuthButton,
} from './styles';

type activationDataType = {
  id: number;
  key: string;
  organizationId: number;
};

const UserActivationForm = () => {
  const { themeLogin, isMobile } = useThemeResponsive();
  const navigate = useNavigate();
  const location = useLocation();

  const [activationData, setActivationData] = useState<activationDataType>();
  const [type, setType] = useState<string>();
  const [form, setForm] = useState({ password: '', confirmPassword: '' });
  const [error, setError] = useState({});
  const config = { ...userActivationConfig };
  const { setLoadingUrl, setLoadingFinishedUrl } = useRootStore();

  const callApi = async () => {
    if (validate()) {
      if (!activationData || !type) return;
      const { id, key, organizationId } = activationData;
      const conditionalUrl = 'resetpasswordform';
      setLoadingUrl(conditionalUrl);
      const result = await activateUserURL(
        id,
        key,
        organizationId,
        form.password,
        type
      );
      setLoadingFinishedUrl(conditionalUrl);
      if (result instanceof ServiceError) {
        navigate({
          pathname: '/account-activization-error',
          search: window?.location?.search,
        });
      } else {
        navigate({
          pathname:
            location.pathname === '/account-activization'
              ? '/login'
              : '/account-activization-success',
          search: window?.location?.search,
        });
      }
    }
  };

  const validate = () => {
    const toValid = config.form;
    const err = {};
    toValid.forEach((ele) => {
      ele.config.forEach((element) => {
        if (element.required && !form[element.name]) err[element.name] = true;
        else if (
          element.name === 'confirmPassword' &&
          form.confirmPassword !== form.password
        )
          err[element.name] = 'Passwords do not match.';
      });
    });
    setError(err);
    return isEmpty(err);
  };

  useEffect(() => {
    if (!location.search) {
      navigate('/login');
    }
    const params = new URLSearchParams(location.search);
    const urlParams = {
      id: params.get('uid'),
      key: params.get('key'),
      organizationId: params.get('organizationId'),
    };
    const { id, key, organizationId } = urlParams;
    if (!id || !key || !organizationId) return;
    const activationParams = { id: +id, key, organizationId: +organizationId };
    setActivationData(activationParams);
    getMetadata(activationParams);
  }, []);

  const getMetadata = async (activationParams: activationDataType) => {
    const activationType =
      location.pathname === '/account-activization' ? 'USER' : 'DRIVER';
    setType(activationType);
    if (!activationParams) return;
    const { id, key, organizationId } = activationParams;
    const result = await getInactiveDriverUserMetadata(
      id,
      key,
      organizationId,
      activationType
    );
    if (result.phoneData) {
      setForm({
        ...form,
        ...result,
        ...result.phoneData,
        active: true,
      });
    } else {
      setForm({
        ...form,
        active: true,
      });
    }
  };

  if (!form.active) return <></>;

  return (
    <StyledAuthFromWrapper>
      <Box
        className="activation-form-wrapper"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <StyledFormTitle>{config.title}</StyledFormTitle>
        </Box>
        <Box sx={{ width: '100%', overflow: 'auto' }}>
          {config.form.map((block: any, index: number) => {
            return (
              <Box key={index} sx={{ marginBottom: '20px', width: '100%' }}>
                <Box
                  sx={(theme) => ({
                    ...theme.typography.h5,
                    color: 'text.primary',
                    marginBottom: '20px',
                  })}
                >
                  {block.title}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  {block.config.map(
                    (
                      field: {
                        name: string;
                        label: string;
                        inputType?: string;
                        width?: string;
                        options?: any;
                        type: string;
                        required: boolean;
                        disabled: boolean;
                      },
                      i: number
                    ) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            width: isMobile ? '100%' : field.width || '100%',
                            marginBottom: '15px',
                          }}
                        >
                          {field.name === 'phone' ? (
                            <PhoneField
                              label={field.label}
                              required={field.required}
                              countryCodeOptions={countryCodeOptions}
                              countryCode={form.countryCode}
                              number={form.phone}
                              extn={form.extension}
                              variant="outlined"
                              disabled={true}
                              {...getPhoneFieldStylePropsAuthForm(isMobile)}
                            />
                          ) : (
                            <TextInput
                              disabled={field.disabled}
                              type={field.inputType || undefined}
                              label={field.label}
                              error={Boolean(error[field.name])}
                              helperText={error[field.name]}
                              onChange={(e: any) => {
                                const newForm = {
                                  ...form,
                                  [field.name]: e.target.value,
                                };
                                setForm(newForm);
                                setError({ ...error, [field.name]: '' });
                              }}
                              styleProps={{
                                width: '100%',
                                ...themeLogin.typography.inputText,
                                borderRadius: '14px',
                                color: 'text.secondary',
                              }}
                              defaultValue={form[field.name]}
                              variant="outlined"
                              required={field.required}
                            />
                          )}
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ButtonImproved
          onClick={callApi}
          label="Activate"
          size="large"
          styleProps={{
            ...SxPropAuthButton,
            ...themeLogin.typography.h6,
          }}
          variant="contained"
        />
      </Box>
    </StyledAuthFromWrapper>
  );
};

export default UserActivationForm;
