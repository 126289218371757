import { Box, Button, Grid, Typography } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { IAutocompleteOption } from '../../../views/trips/constants/types';
import InputLabel from '../../InputLabel';

interface GridButtonGroupProps {
  id?: string;
  label?: string;
  name: string;
  options: IAutocompleteOption[];
  defaultOptions?: IAutocompleteOption[];
  onChange?: (options: IAutocompleteOption[], name?: string) => void;
  isFormControl?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  fieldName?: string;
  fieldValue?: any;
  customStyles?: SxProps;
}

export const GridButtonGroup = ({
  name,
  label,
  options,
  isFormControl,
  defaultOptions = [],
  onChange,
  multiple = true,
  fieldName = 'value',
  fieldValue = 'key',
  customStyles = {},
}: GridButtonGroupProps) => {
  const [selectedOptions, setSelectedOptions] = useState<IAutocompleteOption[]>(
    defaultOptions || []
  );

  const hasSelected = (option: IAutocompleteOption): boolean => {
    const isSelected = selectedOptions.some(
      (op) => op[fieldValue] === option[fieldValue]
    );
    return isSelected;
  };

  const onClickHandler = (currOption: IAutocompleteOption) => {
    let newList = [];
    if (multiple) {
      if (hasSelected(currOption)) {
        newList = selectedOptions.filter(
          (option) => option[fieldValue] !== currOption[fieldValue]
        );
      } else {
        newList = [...selectedOptions, currOption];
      }
    } else {
      newList = selectedOptions.some(
        (option) => option[fieldValue] === currOption[fieldValue]
      )
        ? []
        : [{ ...currOption }];
    }
    setSelectedOptions(newList);
    onChange?.(newList, name);
  };

  useEffect(() => {
    setSelectedOptions(defaultOptions || []);
  }, [defaultOptions]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...customStyles,
      }}
    >
      {isFormControl && <InputLabel>{label}</InputLabel>}
      <Grid container spacing={1}>
        {options.map((option: IAutocompleteOption) => (
          <Grid item key={option[fieldValue]}>
            <Button
              sx={{
                borderRadius: '10px',
                backgroundColor: hasSelected(option)
                  ? 'primary.outlinedHoverBackground'
                  : 'action.hover',
                color: hasSelected(option) ? 'primary.main' : 'text.secondary',
                borderColor: hasSelected(option)
                  ? 'primary.main'
                  : 'text.secondary',
              }}
              variant={'outlined'}
              onClick={() => onClickHandler(option)}
            >
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                {option[fieldName]}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
