import { Stack, SvgIcon } from '@mui/material';
import { CustomizeCardHeader, LoadKPICard } from '../../../../common/Dashboard';

import {
  DriverIcon,
  Tractor02Icon,
} from '../../../../ui-kit/components/Assets';
import { StyledComponentRoot } from '../../styles/styles';
import {
  eLoadOperationalChart,
  LoadOperationalChartProps,
} from '../../../../common/Dashboard/Chart/LoadOperationalChart';
import { useStores } from '../../../../store/root.context';
import { t } from 'i18next';
export const AvailabilityKPI = (): JSX.Element => {
  const { dashboardStore } = useStores();
  const {
    driverAvailabilityData,
    tractorAvailabilityData,
    tractorAvailabilityLoader,
    driverAvailabilityLoader,
  } = dashboardStore;

  const driverChartList: LoadOperationalChartProps[] = [
    {
      id: 'driverNeedsLoads',
      total: driverAvailabilityData?.driverChart?.count || 0,
      value: driverAvailabilityData?.driverLoads?.count || 0,
      chartTitle: t('needsLoads'),
      type: eLoadOperationalChart.NeedsLoads,
      loader: driverAvailabilityLoader,
    },
    {
      id: 'driverOnVacation',
      total: driverAvailabilityData?.driverChart?.count || 0,
      value: driverAvailabilityData?.driverVacation?.count || 0,
      chartTitle: t('onVacation'),
      type: eLoadOperationalChart.OnVacation,
      loader: driverAvailabilityLoader,
    },
  ];
  const tractorChartList: LoadOperationalChartProps[] = [
    {
      id: 'tractorUnavailable',
      total: tractorAvailabilityData?.TOTAL?.count || 0,
      value: tractorAvailabilityData?.UNAVAILABLE?.count || 0,
      chartTitle: 'Unavailable',
      type: eLoadOperationalChart.Unvailable,
      loader: tractorAvailabilityLoader,
    },
    {
      id: 'tractorAvailable',
      total: tractorAvailabilityData?.TOTAL?.count || 0,
      value: tractorAvailabilityData?.AVAILABLE?.count || 0,
      chartTitle: t('available'),
      type: eLoadOperationalChart.Available,
      loader: tractorAvailabilityLoader,
    },
  ];
  return (
    <Stack
      direction="column"
      alignItems="center"
      height={'100%'}
      spacing={1}
      width={'100%'}
    >
      <StyledComponentRoot
        sx={{
          height: '246px',
        }}
      >
        <CustomizeCardHeader
          avatar={
            <DriverIcon height={30} width={30} color="#fff" opacity="1" />
          }
          title={t('driverAvailibility')}
        />
        <LoadKPICard isAvailabilityKPI={true} chartList={driverChartList} />
      </StyledComponentRoot>

      <StyledComponentRoot
        sx={{
          height: '246px',
        }}
      >
        <CustomizeCardHeader
          avatar={<SvgIcon component={() => <Tractor02Icon />} />}
          title={t('tractorAvailibility')}
          sxSvgIcon={{
            paddingTop: '5px',
          }}
        />
        <LoadKPICard isAvailabilityKPI={true} chartList={tractorChartList} />
      </StyledComponentRoot>
    </Stack>
  );
};
