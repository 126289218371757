import React, { memo } from 'react';
import TripDetailsPanelWrapper from './components/Trip/TripDataGrid';

interface IAvailableTripsWrapper {}

const AvailableTripsLayout: React.FC<IAvailableTripsWrapper> = () => {
  return <TripDetailsPanelWrapper />;
};

export default memo(AvailableTripsLayout);
