import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServiceError } from '../../../api/interfaces';
import LongMenu from '../../../common/LongMenu';
import { Permission, AXELE_PERMISSION_TYPE } from '../../../common/Permission';
import FormDialog from '../../../common/Ui/FormDialog';
import WarningPopover from '../../../common/Ui/WarningPopover';
import { invitationsUserStatusRequest } from '../../../models';
import { resetPasswordType, UserDetails } from '../../../models/DTOs/user/User';
import { IHttpErrorType, WarningPopoverConfigType } from '../../../types';

import {
  invitationsUserDeactivateValidation,
  invitationsUserStatus,
} from '../../webUsers/utils';

import {
  getUserWarningPopoverConfig,
  otherUserResetPasswordValidationSchema,
  sameUserResetPasswordValidationSchema,
} from '../constants';

import {
  deleteUser,
  resetOtherUserPassword,
  resetPassword,
  validateResetPassword,
  validateUserDeletion,
} from '../utils';

import ResetPasswordForm from './ResetPasswordForm';
import StorageManager from '../../../StorageManager/StorageManager';
interface UserActionsSectionProps {
  status: number;
  handleDeleted?: (data: string) => void;
  userDetails?: UserDetails | null;
  handleStatusUpdated?: (data: UserDetails | undefined) => void;
}

interface ActionUser {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}
interface checkBoxRefObject {
  checked: boolean | undefined;
}

export function UserActionsSection({
  status,
  userDetails,
  handleDeleted,
  handleStatusUpdated,
}: UserActionsSectionProps) {
  const navigate = useNavigate();
  const checkBoxRef = useRef<checkBoxRefObject>(null);
  const userStorageData = StorageManager.getUser();

  const [warningPopoverConfig, setWarningPopoverConfig] =
    useState<WarningPopoverConfigType | null>(null);

  const [isResetPasswordFormDialogOpen, setIsResetPasswordFormDialogOpen] =
    useState<boolean>(false);

  const [httpErrors, setHttpErrors] = useState<IHttpErrorType[] | null>();

  const [countOfWrongPasswords, setCountOfWrongPasswords] = useState<number>(0);

  useEffect(() => {
    if (countOfWrongPasswords === 5) {
      navigate('/login');
    }
  }, [countOfWrongPasswords, navigate]);

  const isSameUser: boolean = useMemo(() => {
    return !!userDetails && userStorageData.id === userDetails.id;
  }, [userDetails, userStorageData]);

  const handleValidateCanDeleteUser = async () => {
    if (!userDetails?.id) return;

    const validateResponse = await validateUserDeletion(userDetails.id);

    if (!validateResponse) {
      setWarningPopoverConfig(
        getUserWarningPopoverConfig({
          configName: userDetails.isDriver
            ? 'DRIVER_DELETE_WARNING'
            : 'USER_DELETE_WARNING',
        })
      );
      return;
    }
    const deleteResponse = await deleteUser(userDetails.id);
    if (handleDeleted && deleteResponse) handleDeleted(userDetails.id);
  };

  const handleStatusAction = async (statusText: string) => {
    const statusInfo = new invitationsUserStatusRequest({
      id: userDetails?.id || '',
      email: userDetails?.email || '',
      status: statusText,
      notifyUser: true,
    });
    if (status === 0) {
      statusInfo.notifyUser = checkBoxRef.current?.checked;
      statusInfo.status = checkBoxRef.current?.checked
        ? 'INVITED'
        : 'NON_INVITED';
    }
    const response = invitationsUserStatus(statusInfo);
    response && handleStatusUpdated && handleStatusUpdated(await response);
  };

  const handleDeactivateValidationStatus = async (statusText: string) => {
    const statusInfo = new invitationsUserStatusRequest({
      id: userDetails?.id || '',
      email: userDetails?.email || '',
      status: statusText,
      notifyUser: true,
    });
    invitationsUserDeactivateValidation(statusInfo).then((response) => {
      if (response?.valid) {
        handleStatusAction(statusText);
      } else {
        setWarningPopoverConfig(
          getUserWarningPopoverConfig({ configName: 'DEACTIVATE_WARNING' })
        );
      }
    });
  };

  const handleResetPassword = async (data: resetPasswordType) => {
    if (isSameUser) {
      const oldPasswordvalidationResponse = await validateResetPassword(
        data,
        'OLD_PASSWORD'
      );

      if (oldPasswordvalidationResponse instanceof ServiceError) {
        setCountOfWrongPasswords(countOfWrongPasswords + 1);
        setHttpErrors([
          {
            fieldName: 'oldPassword',
            message:
              oldPasswordvalidationResponse.composedError?.error?.response?.data
                ?.message,
          } as IHttpErrorType,
        ]);
        return;
      }

      setCountOfWrongPasswords(0);
    }

    const newPasswordvalidationResponse = await validateResetPassword(
      data,
      'New_PASSWORD'
    );

    if (newPasswordvalidationResponse instanceof ServiceError) {
      setHttpErrors([
        {
          fieldName: 'password',
          message:
            newPasswordvalidationResponse.composedError?.error?.response?.data
              ?.message,
        } as IHttpErrorType,
        {
          fieldName: 'confirmPassword',
          message:
            newPasswordvalidationResponse.composedError?.error?.response?.data
              ?.message,
        } as IHttpErrorType,
      ]);
      return;
    }

    const response = isSameUser
      ? await resetPassword(data)
      : await resetOtherUserPassword(data, userDetails?.id);

    if (response) setIsResetPasswordFormDialogOpen(false);
    setHttpErrors(null);
  };

  const sendInvitationNode = () => {
    const sendInvitationStyle = {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    };

    return (
      <>
        <Box sx={sendInvitationStyle}>
          <Typography sx={{ mr: '12px' }}> Send Invitation</Typography>{' '}
          <input ref={checkBoxRef} type="checkbox" defaultChecked />
        </Box>
      </>
    );
  };

  const optionsList = () => {
    const mainActions: ActionUser[] = [];

    if (!isSameUser && userDetails && userDetails.userType === 'OWNER_ADMIN') {
      return [];
    }

    const reset = {
      name: 'Reset Password',
      action: () => {
        setIsResetPasswordFormDialogOpen(true);
      },
    };

    const cancel = {
      name: 'Cancel Invitation',
      action: () => {
        setWarningPopoverConfig(
          getUserWarningPopoverConfig({ configName: 'CANCEL' })
        );
      },
    };

    const deactivate = {
      name: 'Deactivate ',
      action: () => {
        setWarningPopoverConfig(
          getUserWarningPopoverConfig({ configName: 'DEACTIVATE' })
        );
      },
    };

    const activate = {
      name: 'Activate',
      action: () => {
        setWarningPopoverConfig(
          getUserWarningPopoverConfig({
            configName: 'ACTIVATE',
            contentRenderer: sendInvitationNode,
          })
        );
      },
    };
    const send = {
      name: 'Send Invitation',
      action: () => {
        setWarningPopoverConfig(
          getUserWarningPopoverConfig({
            configName: 'SEND',
          })
        );
      },
    };
    const resend = {
      name: 'Resend Invitation',
      action: () => {
        handleStatusAction('INVITED');
      },
    };

    const deleteAction = {
      customStyles: { paddingRight: '5px' },
      name: 'Delete',
      action: () => {
        setWarningPopoverConfig(
          getUserWarningPopoverConfig({
            configName: 'DELETE',
          })
        );
      },
    };

    if (isSameUser) {
      mainActions.push(reset);
      return mainActions;
    }

    switch (status) {
      case 0:
        //INACTIVE
        mainActions.push(activate);
        break;
      case 1:
        //ACTIVE
        mainActions.push(deactivate);
        break;
      case 2:
        //ACTIVE (NON-INVITED)
        mainActions.push(send);
        mainActions.push(deactivate);
        break;
      case 3:
        //ACTIVE (INVITED)
        mainActions.push(resend);
        mainActions.push(cancel);
        mainActions.push(deactivate);
        break;
    }

    mainActions.push(deleteAction);
    if (userStorageData && userStorageData.userType === 'OWNER_ADMIN') {
      mainActions.push(reset);
    }

    return mainActions;
  };

  return (
    <>
      <Permission
        contains={
          isSameUser ? undefined : [AXELE_PERMISSION_TYPE.USER_PROFILE_REMOVE]
        }
      >
        <LongMenu color={'primary.contrast'} options={optionsList()} />
      </Permission>
      {!!warningPopoverConfig && (
        <WarningPopover
          open={!!warningPopoverConfig}
          data={warningPopoverConfig.data}
          title={warningPopoverConfig.title}
          subTitle={warningPopoverConfig.subTitle}
          body={warningPopoverConfig.body}
          actions={{
            sendInvitationNode,
            handleDeactivateValidationStatus,
            handleStatusAction,
            handleValidateCanDeleteUser,
          }}
          actionName={warningPopoverConfig.actionName}
          buttonText={warningPopoverConfig.buttonText}
          cancelText={warningPopoverConfig.cancelText}
          backgroundColor={warningPopoverConfig.backgroundColor}
          width={warningPopoverConfig.width}
          contentRenderer={warningPopoverConfig.contentRenderer}
          onClose={() => setWarningPopoverConfig(null)}
        />
      )}

      <FormDialog
        titleText="Reset Password"
        actionLabel="Reset"
        open={isResetPasswordFormDialogOpen}
        onAction={handleResetPassword}
        httpErrors={httpErrors}
        handleClose={() => {
          setHttpErrors(null);
          setIsResetPasswordFormDialogOpen(false);
        }}
        contentRenderer={() => <ResetPasswordForm isSameUser={isSameUser} />}
        validationSchema={
          isSameUser
            ? sameUserResetPasswordValidationSchema
            : otherUserResetPasswordValidationSchema
        }
      />
    </>
  );
}
