import styled from '@emotion/styled';
import { Dialog } from '@mui/material';
import { addressType } from '../Ui/AddressField/constants';
import { ICoordinates } from '../../views/loadboards/loadTypes';

export type propsType = {
  callBackAddress: (data: addressType) => void;
  address: addressType | undefined;
  valueName: string;
};
export type center = {
  lat: number;
  lng: number;
};
export const textInputSxPropsGeocoding = {
  '.MuiInputBase-colorPrimary': {
    color: '#ffffff !important',
    '-webkit-text-fill-color': '#ffffff !important',
  },
  '.MuiInputLabel-root': {
    color: '#ffffff !important',
    '-webkit-text-fill-color': '#ffffff !important',
  },
  '.Mui-disabled': {
    color: '#c5c8ca',
    '-webkit-text-fill-color': '#ffffff !important',
  },
  color: ' rgba(4, 0, 34, 0.75) !important',
  background: '#FFFFFF',
  boxShadow:
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
  borderRadius: '10px',
};

export const textInputSxProps = {
  '.MuiInputBase-colorPrimary': {
    color: '#000000 !important',
    '-webkit-text-fill-color': '#000000 !important',
    height: '34px !important',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    background: 'white !important',
    paddingLeft: '9px',
    fontSize: '13px',
  },
  '.MuiGrid-root': {
    margin: '0px !important',
    paddingLeft: '9px',
    fontSize: '13px',
  },
  '.MuiInputBase-readOnly': {
    paddingLeft: '9px',
    fontSize: '13px',
  },
  '.MuiAutocomplete-input': {
    padding: '1.5px 0px 0.5px 3px !important',
    fontSize: '14px',
    background: 'white !important',
  },
  '.MuiInputLabel-root': {
    color: '#000000 !important',
    '-webkit-text-fill-color': '#000000 !important',
    height: '34px !important',
    fontSize: '13px',
    padding: '1.5px 0px 0.5px 9px !important',
    zIndex: 1,
    paddingLeft: '9px',
  },
  '.Mui-disabled': {
    color: '#c5c8ca',
    '-webkit-text-fill-color': '#000000 !important',
    height: '34px !important',
    fontSize: '14px',
    padding: '1.5px 0px 0.5px 5px !important',
  },
};

export const geoCodingDiv = {
  height: '200px',
  padding: '0px 15px 15px 15px',
  pointerEvents: 'stroke',
  position: 'relative',
};
export const geoCodingText = {
  position: 'absolute',
  bottom: '40px',
  left: '42px',
};
export const geoCodingDialogBox = {
  height: '100%',
  width: '100%',
  backgroundColor: '#35446B !important',
  mb: 2,
};
export const geoCodingDialogContent = {
  height: 44,
  padding: '8px 16px 8px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const geoCodingPrevue = {
  height: 'calc(100vh - 200px)',
  padding: '0px 15px 15px 15px',
};
export const geoCodingPrevueSave = {
  marginRight: '20px',
};
export type geofenceType = {
  center: center;
  geoCoordinates: ICoordinates | Array<Array<number>>;
};

export type propsTypeTrimblemaps = {
  addressData?: addressType;
  getGeoFenceData?: (data: Array<Array<number>>) => void;
  getGeoCodingData?: (data: center) => void;
  readOnly?: boolean;
  valueName: string;
};

export const mapButtonStyles = {
  position: 'relative',
  top: '-50px',
  marginLeft: '13px',
  background: 'white',
};
export const mapButtonDeleteStyles = {
  position: 'absolute',
  padding: '16px',
  right: '23px',
  zIndex: '4',
  marginTop: '10px',
};

export const StyledDialog = styled(Dialog)(() => {
  return {
    '& .MuiDialog-paper': {
      width: 282,
      borderRadius: '0px !important',
      background: 'rgba(3, 14, 24, 0.89)',
      padding: '0px !important',
      backgroundColor: '#fff0 !important',
      boxShadow: 'none !important',
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiPaper': {
      padding: '0px !important',
      backgroundColor: '#fff0 !important',
      boxShadow: 'none !important',
    },
  };
});
