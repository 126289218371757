import { SearchOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import {
  GridCellEditCommitParams,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CommonDataGrid from '../../../../../../common/DataGridPro';
import { useFilterDataGrid } from '../../../../../../common/hooks/useFilterDataGrid';
import { InvoicePaymentDetails } from '../../../../../../models/DTOs/Payment/DTOs';
import { useStores } from '../../../../../../store/root.context';
import { GridColDefSelf } from '../../../../../../types';
import { onColumnOrderChange } from '../../../../../../utils/gridColumnOrdering';
import { PaymentDetailsFormProps } from '../../PaymentDetailsPanel/PaymentDetailsForm';
import InvoicesPaymentGridConfig from './InvoicePaymentGrid.config';
import { emptyInvoicePaymentRow } from './InvoicesPaymentGrid.constants';
export const InvoicesPaymentGrid = observer(
  ({ isCreatePanel }: Pick<PaymentDetailsFormProps, 'isCreatePanel'>) => {
    const {
      invoicePaymentStore: {
        component,
        currentView,
        currentView: { id: stripId },
        setFilters,
        resetDataGrid,
        totalRows,
        tableData,
        pageIsLoading,
        isLoading,
        getUnpaidInvoices,
        changeSelectionModel,
        selectionModel,
        updateEdittingRow,
        unpaidInvoices,
        currentPaidInvoices,
      },
    } = useStores();

    const gridConfig = new InvoicesPaymentGridConfig({});
    const [tableColumns, setTableColumns] = useState<GridColDefSelf[]>([]);
    const [allColumns, setAllColumns] = useState<GridColDefSelf[]>(
      gridConfig.config.columns
    );
    const [search, setSearch] = useState<string>('');
    const { watch, setValue } = useFormContext();
    const watchCustomer = watch('customerId');

    const {
      onPageFilterChange,
      setDefaultColumns,
      onAllFilterSubmit,
      onAllFilterReset,
      handleChangeColumns,
      setDefaultFilters,
    } = useFilterDataGrid({
      component,
      allColumns: gridConfig.config.columns,
      currentView,
      setFilters,
      setTableColumns,
      setAllColumns,
      defaultFilters: gridConfig.config.defFilters,
      currentViewPrimaryKey: 'id',
    });

    const onRowsScrollEnd = debounce(() => {
      if (isLoading || pageIsLoading || !watchCustomer?.id) {
        return;
      }
      const queryParams = {
        customerId: watchCustomer.id,
      };

      getUnpaidInvoices(queryParams);
    }, 100);

    useEffect(() => {
      return () => {
        onRowsScrollEnd.cancel();
      };
    }, []);

    useEffect(() => {
      if (watchCustomer) {
        onRowsScrollEnd();
      } else {
        resetDataGrid();
      }
    }, [watchCustomer]);

    const onCellEditCommit = (
      params: GridCellEditCommitParams,
      event: MuiEvent,
      details: any
    ) => {
      let paidAmount = 0;
      const items = currentPaidInvoices?.length
        ? currentPaidInvoices.filter((d) => d.invoiceId == params?.id)
        : unpaidInvoices.filter((list) => list.invoiceId == params.id);

      if (items?.length) {
        paidAmount = items[0].amount;
      }
      updateEdittingRow(
        {
          invoiceId: params?.id,
          [params.field]: parseFloat(params.value ? params.value : 0),
        } as any,
        undefined,
        paidAmount
      );
      setValue('makeDirty', 'makeDirty', { shouldDirty: true });
    };

    const checkboxSelection = !isCreatePanel;
    const isRowSelectable = (
      params: GridRowParams<InvoicePaymentDetails>
    ): boolean => {
      const isNewRow =
        params.row?.isNew === emptyInvoicePaymentRow.isNew &&
        params.row?.invoiceId === emptyInvoicePaymentRow.invoiceId;
      if (checkboxSelection && !isNewRow) {
        return true;
      }
      return false;
    };

    const tableDataMemo = useMemo(() => {
      if (!search) return tableData;
      return tableData.filter(
        (invoice) =>
          (invoice.invoiceSeqNumber &&
            invoice.invoiceSeqNumber
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (invoice.invoiceReferenceId &&
            invoice.invoiceReferenceId
              .toLowerCase()
              .includes(search.toLowerCase()))
      );
    }, [search, toJS(tableData)]);

    const onSearch = (val: string) => {
      setSearch(val);
    };

    return (
      <Box className={'InvoicesPaymentGrid-root'}>
        <Stack spacing={0} direction="row">
          <TextField
            id="standard-select-currency-native"
            sx={{
              width: '40%',
              border: '1px solid #2B64CB !important',
              borderRadius: '5px',
              fontSize: '13px',
              '.MuiOutlinedInput-input': {
                height: '20px !important',
              },
            }}
            placeholder={`Search`}
            value={search}
            InputProps={{
              startAdornment: (
                <SearchOutlined fontSize="small" color="primary" />
              ),
              endAdornment: (
                <>
                  {search && (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setSearch('');
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <CloseIcon fontSize="small" color="action" />
                    </IconButton>
                  )}
                </>
              ),
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onSearch(event.target.value);
            }}
            size="small"
            variant="outlined"
          />
        </Stack>
        <Stack
          spacing={2}
          direction="row"
          sx={{ display: 'flex', minHeight: '200px' }}
        >
          {currentView?.entity && allColumns && (
            <CommonDataGrid
              disableSelectionOnClick
              checkboxSelection={false}
              isRowSelectable={isRowSelectable}
              selectRow={changeSelectionModel}
              selectionModel={selectionModel}
              showFocus={true}
              componentsProps={{
                cell: {
                  tabIndex: 1,
                },
              }}
              disableColumnMenu
              editMode="cell"
              sortingMode="server"
              totalNumberOfRows={totalRows}
              loading={isLoading || pageIsLoading}
              columns={allColumns}
              rows={[...tableDataMemo]}
              rowsCaching={false}
              getRowId={(row: InvoicePaymentDetails) =>
                row?.invoiceId || emptyInvoicePaymentRow.invoiceId
              }
              onRowsScrollEnd={onRowsScrollEnd}
              onColumnOrderChange={(params, event, details) => {
                onColumnOrderChange(
                  {
                    params,
                    event,
                    details,
                  },
                  component,
                  stripId,
                  true
                );
              }}
              onCellEditCommit={onCellEditCommit}
            />
          )}
        </Stack>
      </Box>
    );
  }
);
