import CloseIcon from '@mui/icons-material/Close';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../../StorageManager/StorageManager';
import { financeLoadService, invoiceService } from '../../../../api';
import { downloadDispatchConfirmationURL } from '../../../../api/impl/requestConstants';
import {
  LoadCarrierExpenseDTO,
  LoadCarrierExpenseShortDTO,
} from '../../../../models/DTOs/FinanceLoadService';
import { ETripSettlementStatus } from '../../../../models/DTOs/Trip/Types';
import { useStores } from '../../../../store/root.context';
import { EmailFormType } from '../../../../subPages/invoices/models/InvoiceModel';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import { getAccountTypeWithRoleCode } from '../../../../utils';
import { downloadFile } from '../../../../utils/Doc';
import { DirtyDetailsPanelManager } from '../../../DetailsPanel/utils';
import LongMenu from '../../../LongMenu';
import DispatchSheet from '../Routes/components/DispatchSheet';
import { EFinanceOverview } from './FinanceOverviewConstant';
import { FinanceLoadEmail } from './FinanceOverviewEmail';
import FormContext, { getFinanceDeduction } from './FinanceOverviewFormContext';
import { EPaymentDetailType } from './FinanceOverviewModel';
import { financeStyle } from './FinanceOverviewStyle';
import { FinancePaymentStatusChip } from './FinanceOverviewTrip';

export const FinanceOverviewAccordionTopBar = ({
  showAccordionTopBarClose,
}: {
  showAccordionTopBarClose: boolean;
}) => {
  const {
    tripsStore,
    myLoadStore: { setUpdatedLoadId },
  } = useStores();
  const {
    setSelectRecordData,
    selectRecordData,
    setSelectRecordList,
    selectEntityType,
    isSavebtnVisible,
    setRenderSection,
    resetFinanceOverviewData,
    openPayments,
    statementInfo,
    onClose,
    isCarrier,
    setSavebtnVisible,
    allPaymentTypes,
    setOpenPayments,
    setIsCreatingNewPayment,
  } = React.useContext(FormContext);

  const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(false);
  const [isReCalculationView, setReCalculationView] = useState(false);
  const [isEnableCalculationView, setEnableCalculationView] = useState(false);
  const userStorage = StorageManager.getUser();
  const handleSendEmail = async (mailData: EmailFormType) => {
    setSendEmailDialogOpen(false);
    const request = {
      combinePdfDocuments: true,
      emailSendingRequestDTO: [
        {
          cc: mailData.cc,
          documentList: (mailData.documentIds as [])!.map((docId: string) => ({
            id: docId,
          })),
          invoiceList: [],
          message: mailData.message,
          rateConfirmationRequest: {
            loadId: selectRecordData?.loadId,
          },
          subject: mailData.subject,
          to: mailData.to,
        },
      ],
      html: true,
      organizationId: userStorage.organizationId,
    };
    switch (selectEntityType?.type) {
      case 'Dispatcher':
        request.emailSendingRequestDTO[0].rateConfirmationRequest.entityId =
          selectRecordData?.dispatcherId ? selectRecordData.dispatcherId : 0;
        request.emailSendingRequestDTO[0].rateConfirmationRequest.entityType =
          'dispatcher';
        break;
      case 'Tractor':
        request.emailSendingRequestDTO[0].rateConfirmationRequest.entityId =
          selectRecordData?.tractorId ? selectRecordData.tractorId : 0;
        request.emailSendingRequestDTO[0].rateConfirmationRequest.entityType =
          'tractor';
        break;
      case 'Driver':
        request.emailSendingRequestDTO[0].rateConfirmationRequest.entityId =
          selectRecordData?.driverId ? selectRecordData.driverId : 0;
        request.emailSendingRequestDTO[0].rateConfirmationRequest.entityType =
          'driver';
        break;
    }
    const response = await invoiceService.sendEmail(request);
    if (response && response === 'Message sent for process.') {
    } else {
    }
  };
  const sendEmailDialog = () => {
    setSendEmailDialogOpen(true);
  };
  const handleDownload = async () => {
    const params = {
      type: '',
      loadId: selectRecordData?.loadId,
      driverId: '',
    };
    switch (selectEntityType?.type) {
      case 'Dispatcher':
        params.type = 'entityType=dispatcher';
        params.driverId = `entityId=${selectRecordData?.dispatcherId}`;
        break;
      case 'Tractor':
        params.type = 'entityType=tractor';
        params.driverId = `entityId=${selectRecordData?.tractorId}`;
        break;
      case 'Driver':
        params.type = 'entityType=driver';
        params.driverId = `entityId=${selectRecordData?.driverId}`;
        break;
    }

    const result = await financeLoadService.GetfinanceLoadDownload(params);
    if (result) {
      downloadFile(result);
    }
  };
  const theme = useTheme();
  const reCalcuationlDialog = async () => {
    let RequestURL = '';
    let result: any = {};
    switch (selectEntityType?.type) {
      case 'Dispatcher':
        RequestURL = `?loadId=${selectRecordData?.loadId}&organizationId=${userStorage.organizationId}&dispatcherId=${selectRecordData?.dispatcherId}`;
        result = await financeLoadService.GetAutopopulateDispatcher(RequestURL);
        break;
      case 'Tractor':
        RequestURL = `?loadId=${selectRecordData?.loadId}&organizationId=${userStorage.organizationId}&tractorId=${selectRecordData?.tractorId}`;
        result = await financeLoadService.GetAutopopulateTractor(RequestURL);
        break;
      case 'Driver':
        RequestURL = `?loadId=${selectRecordData?.loadId}&organizationId=${userStorage.organizationId}&driverId=${selectRecordData?.driverId}`;
        result = await financeLoadService.GetAutopopulateDriver(RequestURL);
        break;
    }
    setEnableCalculationView(false);
    if (result) {
      setReCalculationView(false);
      if (result.items?.length === 0) {
        setEnableCalculationView(true);
      } else {
        resetFinanceOverviewData(selectEntityType, false);
      }
    }
  };
  const hyperTextStyle = {
    display: 'flex',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    pl: '0px !important',
    '&:hover': {
      backgroundColor: 'rgb(255 255 255 / 4%)',
    },
  };
  const { getValues, reset, setValue } = useFormContext();

  const noPermissionForRecalculate = getAccountTypeWithRoleCode([
    'NFD',
    'DR',
    'SP',
  ]);

  const onCloseHandler = () => {
    if (openPayments && onClose) {
      const recordList = getValues('selectRecordList');
      const recordData = getValues('selectRecordData');
      const receivedPayments = getValues('receivedPayments');
      recordList?.map((currElement: any, index: any) => {
        const isDeduction = getFinanceDeduction(
          allPaymentTypes,
          currElement.loadRateType
        );
        if (isDeduction) {
          recordList[index].amount = -Number(currElement.amount);
        }
      });
      recordData.items = recordList;
      if (isCarrier) {
        recordData.receivedPayments = receivedPayments;
        setSelectRecordData(recordData);
      }
      openPayments?.onPaymentTmpUpdate(recordData);
      reset();
      onClose();
      setOpenPayments?.(undefined);
      setIsCreatingNewPayment(false);
      return;
    }
    setOpenPayments?.(undefined);
    setIsCreatingNewPayment(false);
    setRenderSection(EFinanceOverview.FinanceOverview);
  };

  const onCarrierPaymentStatusChange = (response: LoadCarrierExpenseDTO) => {
    if (response) {
      setSelectRecordData(response);
      setSelectRecordList(response.items);
      setSavebtnVisible(false);
      tripsStore?.updateMainData({
        updatedIds: [response.tripId],
      });
      setUpdatedLoadId?.(response.tripId);
    }
  };

  const options = [];
  if (ETripSettlementStatus.Pending === selectRecordData?.status) {
    options.push({
      name: `Mark as Closed`,
      action: async () => {
        const response = await financeLoadService.updateCarrierPaymentStatus(
          new LoadCarrierExpenseShortDTO({
            id: selectRecordData.id,
            status: ETripSettlementStatus.Paid,
          })
        );
        onCarrierPaymentStatusChange(response);
      },
    });
  }
  if (ETripSettlementStatus.Paid === selectRecordData?.status) {
    options.push({
      name: `Re-open`,
      action: async () => {
        const response = await financeLoadService.updateCarrierPaymentStatus(
          new LoadCarrierExpenseShortDTO({
            id: selectRecordData.id,
            status: ETripSettlementStatus.Pending,
          })
        );
        onCarrierPaymentStatusChange(response);
      },
    });
  }

  const paymentStatusMapping = {
    CLOSED: 'Closed',
    PENDING: 'Pending',
    IN_REVIEW: 'In Review',
    NONE: 'None',
  };
  return (
    <>
      <Grid className="FinanceOverviewAccordionTopBar" container spacing={2}>
        {showAccordionTopBarClose && (
          <Grid
            item
            xs={8}
            md={8}
            className="FinanceOverviewAccordionTopBar-Close"
          >
            <Button
              onClick={onCloseHandler}
              variant="text"
              sx={hyperTextStyle}
              disabled={isSavebtnVisible && !openPayments}
            >
              <CloseIcon />
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                  pl: '0px !important',
                }}
              >
                Close {selectEntityType?.type} Payments
              </Typography>
            </Button>
          </Grid>
        )}
        {!openPayments &&
          (selectEntityType?.type !== EPaymentDetailType.Carrier ? (
            <Grid item xs={4} md={4}>
              <Grid
                sx={{
                  display: 'flex',
                  color: theme.palette.primary.main,
                  float: 'right',
                }}
              >
                <Tooltip title="Send Email" placement="left-end">
                  <Button
                    variant="outlined"
                    sx={financeStyle.topButtons}
                    onClick={sendEmailDialog}
                    disabled={isSavebtnVisible || noPermissionForRecalculate}
                  >
                    <EmailOutlinedIcon sx={{ width: '20px' }} />
                  </Button>
                </Tooltip>
                <Tooltip title="Download" placement="top">
                  <Button
                    variant="outlined"
                    sx={financeStyle.topButtons}
                    onClick={handleDownload}
                    disabled={isSavebtnVisible}
                  >
                    <DownloadOutlinedIcon sx={{ width: '20px' }} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Click to get the general Payment Terms again"
                  placement="left-end"
                >
                  <Button
                    variant="outlined"
                    sx={financeStyle.topButtons}
                    onClick={() => setReCalculationView(true)}
                    disabled={
                      isSavebtnVisible ||
                      statementInfo?.status === 'CLOSED' ||
                      noPermissionForRecalculate
                    }
                  >
                    <CurrencyExchangeRoundedIcon sx={{ width: '20px' }} />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              className="FinanceOverviewAccordionTopBar-DispatchSheet"
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: '14px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flex: '1 auto',
                    '> div': { width: '100%', mb: 0 },
                  }}
                >
                  <DispatchSheet
                    label="Carrier Rate Confirmation"
                    loadId={
                      selectRecordData?.carrierId
                        ? selectRecordData?.tripId
                        : selectRecordData?.loadId
                    }
                    carrierId={selectRecordData?.carrierId}
                    fileName="Carrier_Rate_Confirmation"
                    terminal={null}
                    seqNumber={selectRecordData?.seqNumber}
                    downloadURL={downloadDispatchConfirmationURL}
                    isDisabledAllAction={DirtyDetailsPanelManager.isDirty}
                  />
                </Box>
                {selectRecordData?.status &&
                  paymentStatusMapping[selectRecordData?.status] !==
                    paymentStatusMapping.NONE && (
                    <Box
                      sx={{
                        pl: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FinancePaymentStatusChip
                        paymentStatus={
                          paymentStatusMapping[selectRecordData?.status]
                        }
                      />
                      <LongMenu
                        color={'text.secondary'}
                        options={options}
                        canPerformClick={(
                          event: React.MouseEvent<HTMLElement>
                        ) => {
                          if (DirtyDetailsPanelManager.isShouldPanelShake()) {
                            return false;
                          }
                          return true;
                        }}
                      />
                    </Box>
                  )}
              </Box>
            </Grid>
          ))}
      </Grid>
      {sendEmailDialogOpen && (
        <FinanceLoadEmail
          invoice={selectEntityType?.type ? selectEntityType.type : ''}
          open={sendEmailDialogOpen}
          onAction={(data) => handleSendEmail(data)}
          onClose={() => setSendEmailDialogOpen(false)}
          customerEmail={selectRecordData?.email ? selectRecordData.email : ''}
          seqNumber={
            selectRecordData?.seqNumber ? selectRecordData.seqNumber : ''
          }
          emailName={selectEntityType?.name ? selectEntityType.name : ''}
        />
      )}
      {isReCalculationView && (
        <DeletePopup
          open={isReCalculationView}
          onClose={() => setReCalculationView(false)}
          onAction={() => reCalcuationlDialog()}
          title={``}
          subtitle={`Please note, that the recalculated payment will overwrite already existing settlement line items. Are you sure you want to continue?`}
          buttonText={t('yes')}
          backgroundColor={theme.palette.primary.main}
          width={'440px'}
        />
      )}
      {isEnableCalculationView && (
        <DeletePopup
          open={isEnableCalculationView}
          onClose={() => setEnableCalculationView(false)}
          onAction={() => setEnableCalculationView(false)}
          title={``}
          subtitle={`No payments are calculated. Please check the payment terms of a driver and try again.`}
          buttonText={t('yes')}
          backgroundColor={theme.palette.primary.main}
          width={'350px'}
        />
      )}
    </>
  );
};
