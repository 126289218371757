export const TerminalConstants = {
  addTerminal: 'Add Terminal',
  addNewTerminal: 'Add New Terminal',
  terminalDetails: 'Terminals Details',
  companyLogo: 'Company Logo',
  terminalLogoSub:
    'Used for preparing Inovices (1MB maximum, JPG or PNG, 1200px wide max)',
  companyName: 'Company Name',
  companyNameReq: 'Your Company’s Name',
  dotNumber: 'DOT Number',
  dot: 'Dot',
  dotRequired: 'DOT Number for this Terminal',
  mc: 'MC',
  mcNumber: 'MC Number',
  mcNumberSub: 'MC Number for this Terminal',
  contactName: 'Contact Name',
  contactPersonRequired: 'Contact person of the Terminal',
  displayedOnInvoices: 'Displayed on invoices',
  required: 'Required',
  terminalName: 'Terminal Name',
  emailSub: 'Email used in invoices and communication',
  addAdditionalTerminals:
    'Add additional terminals if you operate a dispatching service or would like to assign loads to a different carrier or owner operator.',
  logoSizeMsg:
    'Logo must be an image(jpeg/png) file of size 1MB or less and not wider then 1200px',
  deleteTerminal: 'Delete Terminal',
  deleteTerminalConfirmation:
    'Are you sure you want to permanently delete this terminal?',
  disable: 'Disable',
  disableTerminal: 'Disable Terminal?',
  disableTerminalConfirmation:
    'Are you sure you want to Disable this terminal?',
  dontSave: "Don't Save",
  saveBeforeLeaving:
    'Looks like you have updated some items, do you want to save them before leaving?',
  terminalTime: 'Stop! terminal time',
  enableTerminals: 'Enable Terminals',
  terminalTimeSub:
    'Terminals allows you to create smaller teams in LoadOps, you can have drivers and dispatch allocated to one terminal and they can manage their operations in isolation.',
  leave: 'Leave',
  sureToLeave: 'Are you sure you want to Leave?',
  cannotDeleteTerminal:
    'The terminal cannot be deleted. It has association with assets,loads,load templetes,expenses,and/or users.',
  cannotDisableTerminal:
    'You cannot disable the terminal model until you delete all Secondary Terminals',
};
