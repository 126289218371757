import { Theme } from '@mui/material';
import { getSxPropCardInfoInput } from './styles';

export const registerConfig = {
  0: {
    title: 'Register Now',
    form: [
      {
        title: 'Company Info',
        config: [
          {
            label: 'Company Name',
            name: 'organizationName',
            required: true,
          },
          {
            label: 'DOT/MC/Unique Identifier',
            name: 'dot',
            width: '48%',
            required: true,
          },
          {
            label: 'Fleet Size',
            name: 'fleetSize',
            width: '48%',
            required: true,
            inputType: 'number',
          },
          {
            label: 'Business Address',
            name: 'address',
            required: true,
            type: 'address',
          },
          {
            label: 'City',
            name: 'city',
            width: '30%',
            required: true,
          },
          {
            label: 'State',
            name: 'state',
            width: '30%',
            required: true,
          },
          {
            label: 'Zip',
            name: 'zipcode',
            width: '30%',
            required: true,
          },
        ],
      },
      {
        title: 'Email & Password',
        config: [
          {
            label: 'First Name',
            name: 'firstname',
            width: '48%',
            required: true,
          },
          {
            label: 'Last Name',
            name: 'lastname',
            width: '48%',
            required: true,
          },
          {
            label: 'Phone',
            name: 'mobileno',
            required: true,
          },
          {
            label: 'Email',
            name: 'email',
            required: true,
          },
          {
            label: 'Password',
            name: 'password',
            width: '48%',
            password: true,
            required: true,
            inputType: 'password',
          },
          {
            label: 'Confirm Password',
            name: 'confirmPassword',
            width: '48%',
            password: true,
            required: true,
            inputType: 'password',
          },
        ],
      },
    ],
  },
  1: {
    title: 'Payment Info',
    form: [
      {
        title: 'Enter Credit/Debit Card Details',
        config: [
          {
            name: 'cardName',
          },
          // {
          //   label: 'Use Business Address as Billing Address',
          //   name: 'same_billing_address',
          //   type: 'checkbox',
          // },
          // {
          //   label: 'Billing Address',
          //   name: 'address',
          //   required: true,
          //   type: 'address',
          // },
          // {
          //   label: 'City',
          //   name: 'city',
          //   width: '30%',
          //   required: true,
          // },
          // {
          //   label: 'State',
          //   name: 'state',
          //   width: '30%',
          //   required: true,
          // },
          // {
          //   label: 'Zip',
          //   name: 'zipcode',
          //   width: '30%',
          //   required: true,
          // },
        ],
      },
    ],
  },
};

export const rejoinConfig = {
  title: 'Payment Info',
  form: [
    {
      title: 'Enter Credit/Debit Card Details',
      config: [
        {
          name: 'cardName',
        },
        // {
        //   label: 'Billing Address',
        //   name: 'address',
        //   required: true,
        //   type: 'address',
        // },
        // {
        //   label: 'City',
        //   name: 'city',
        //   width: '30%',
        //   required: true,
        // },
        // {
        //   label: 'State',
        //   name: 'state',
        //   width: '30%',
        //   required: true,
        // },
        // {
        //   label: 'Zip',
        //   name: 'zipcode',
        //   width: '30%',
        //   required: true,
        // },
      ],
    },
  ],
};

export const loginConfig = {
  title: 'Welcome Back',
  form: [
    {
      config: [
        {
          label: 'DOT/MC/Unique Identifier',
          name: 'dot',
          required: true,
        },
        {
          label: 'Email',
          name: 'email',
          required: true,
        },
        {
          label: 'Password',
          name: 'password',
          password: true,
          required: true,
          inputType: 'password',
        },
        {
          label: 'Remember me',
          name: 'rememberPassword',
          type: 'checkbox',
        },
      ],
    },
  ],
};

export const forgotPasswordConfig = {
  title: 'Forgot Password?',
  form: [
    {
      config: [
        {
          label: 'DOT/MC/Unique Identifier',
          name: 'dot',
          required: true,
        },
        {
          label: 'Email',
          name: 'email',
          required: true,
        },
      ],
    },
  ],
};

export const resetPasswordConfig = {
  title: 'Reset Password',
  form: [
    {
      config: [
        {
          label: 'Password',
          name: 'password',
          password: true,
          required: true,
          inputType: 'password',
        },
        {
          label: 'Confirm Password',
          name: 'confirmPassword',
          password: true,
          required: true,
          inputType: 'password',
        },
      ],
    },
  ],
};

export const userActivationConfig = {
  title: 'Activate Account',
  form: [
    {
      title: 'Company Info',
      config: [
        {
          label: 'Company Name',
          name: 'companyName',
          disabled: true,
        },
        {
          label: 'DOT/MC/Unique Identifier',
          name: 'dot',
          disabled: true,
        },
      ],
    },
    {
      title: 'Email & Password',
      config: [
        {
          label: 'Phone',
          name: 'phone',
          disabled: true,
        },
        {
          label: 'Email',
          name: 'email',
          disabled: true,
        },
        {
          label: 'Password',
          name: 'password',
          width: '48%',
          password: true,
          required: true,
          inputType: 'password',
        },
        {
          label: 'Confirm Password',
          name: 'confirmPassword',
          width: '48%',
          password: true,
          required: true,
          inputType: 'password',
        },
      ],
    },
  ],
};

export const getCreditCardFields = ({ theme }: { theme: Theme }) => {
  const style = getSxPropCardInfoInput({ theme });
  return {
    firstName: {
      selector: '#chargify-firstName',
      label: '',
      placeholder: 'First Name',
      message: 'This field is not valid. Please update it.',
      style: style,
    },
    lastName: {
      selector: '#chargify-lastName',
      label: '',
      placeholder: 'Last Name',
      message: 'This field is not valid. Please update it.',
      style: style,
    },
    number: {
      selector: '#chargify-cardinfo',
      label: '',
      placeholder: 'Card Number *',
      message: 'This field is not valid. Please update it.',
      style: getSxPropCardInfoInput({ theme, hasStartIcon: true }),
    },
    month: {
      selector: '#chargify-cardmonth',
      label: '',
      placeholder: 'Month *',
      style: style,
    },
    year: {
      selector: '#chargify-cardyear',
      label: '',
      placeholder: 'Year *',
      style: style,
    },
    cvv: {
      selector: '#chargify-cardcvv',
      label: 'CVV',
      placeholder: 'CVV',
      style: style,
    },
    address: {
      selector: '#chargify-address',
      label: 'Business Address',
      placeholder: 'Business Address',
      style: style,
      value: '',
    },
    state: {
      selector: '#chargify-state',
      label: 'State',
      placeholder: 'State',
      style: style,
      value: '',
    },
    city: {
      selector: '#chargify-city',
      label: 'City',
      placeholder: 'City',
      style: style,
      value: '',
    },
    country: {
      selector: '#chargify-country',
      label: 'Country',
      placeholder: 'Country',
      style: style,
      value: '',
    },
    zip: {
      selector: '#chargify-zip',
      label: 'zip',
      placeholder: 'zip',
      style: style,
      value: '',
    },
  };
};

export const getACHFields = ({ theme }: { theme: Theme }) => {
  const style = getSxPropCardInfoInput({ theme });
  return {
    firstName: {
      selector: '#chargify-firstName',
      label: '',
      placeholder: 'First Name',
      message: 'This field is not valid. Please update it.',
      style: style,
    },
    lastName: {
      selector: '#chargify-lastName',
      label: '',
      placeholder: 'Last Name',
      message: 'This field is not valid. Please update it.',
      style: style,
    },
    bankName: {
      selector: '#chargify-bankName',
      label: '',
      placeholder: 'Bank Name *',
      message: 'This field is not valid. Please update it.',
      style: style,
    },
    routingNumber: {
      selector: '#chargify-routingNumber',
      label: '',
      placeholder: 'Routing Number *',
      message: 'This field is not valid. Please update it.',
      style: style,
    },
    accountNumber: {
      selector: '#chargify-accountNumber',
      label: '',
      placeholder: 'Account Number *',
      message: 'This field is not valid. Please update it.',
      style: style,
    },
    accountHolderType: {
      selector: '#chargify-accountHolderType',
      label: '',
      placeholder: 'Account Holder Type *',
      message: 'This field is not valid. Please update it.',
      style: style,
    },
  };
};

export const fontStyle = {
  fontFamily: 'Oxanium',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '40px',
  lineHeight: '60px',
  letterSpacing: '-0.5px',
  color: '#FFFFFF',
};

export function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const headerCss = {
  fontFamily: 'Oxanium',
  fontSize: '42px',
  fontWeight: '600',
  lineHeight: '59px',
  textAlign: 'center',
  color: 'primary.main',
};

export enum EPartner {
  DAT = 'DAT',
  TAFS = 'TAFS',
  APEX = 'APEX',
}

export const formDefaultValues = {
  login: {
    dot: '',
    email: '',
    password: '',
    rememberPassword: false,
  },
  forgotPassword: { dot: '', email: '' },
  registration: {
    same_billing_address: true,
    paymentTab: 'card',
    billing_address: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    privacy: false,
    dot: '',
    email: '',
    fleetSize: '',
    mobileno: '',
    organizationName: '',
    password: '',
    firstname: '',
    countryCode: 'USA',
    extension: '',
    lastname: '',
  },
};

export const formKeys = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
  FORGOTPASSWORD: 'forgot-password',
};
