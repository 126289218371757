import { Model } from '../../../models/common/Model';
import { getNullableModel } from '../../../utils';
import { TerminalShort } from '../../../models/common/modelsShort';

export class TemplateSummary extends Model {
  customerContactId: string;
  customerId: number;
  customerName: string;
  equipmentTypes: string;
  fromLocationName: string;
  templateName: string;
  toLocationName: string;
  terminal: TerminalShort | null;

  constructor(dto) {
    super(dto);
    this.customerContactId = dto.customerContactId;
    this.customerId = dto.customerId;
    this.customerName = dto.customerName;
    this.equipmentTypes = dto.equipmentTypes;
    this.fromLocationName = dto.fromLocationName;
    this.templateName = dto.templateName;
    this.toLocationName = dto.toLocationName;
    this.terminal = getNullableModel(TerminalShort, dto.terminal);
  }
}
