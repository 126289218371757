import { LoadConstants } from './loads';
import { LoadsearchConstants } from './loadsearch';
import { TripsConstants } from './trips';
import { TemplatesConstants } from './templates';
import { EDITendersConstants } from './loadtenders';

export const AllLoadConstants = {
  ...LoadConstants,
  ...LoadsearchConstants,
  ...TripsConstants,
  ...TemplatesConstants,
  ...EDITendersConstants,
};
