import { makeStyles } from '@mui/styles';

export const integrationLabelUseStyles = makeStyles({
  root: {
    '& .MuiFormLabel-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});
