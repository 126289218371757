import { QueryParams, ContactsListDataTotal } from '../../models';
import { annotateNameAsync, IContactsService } from '../interfaces';
import { httpClient } from '../../axios/axiosInstance';

const getListDataTotalURL = '/web/location/api/v2/contact/view/count/total';

export class ContactsService extends IContactsService {
  @annotateNameAsync
  async getListDataTotal(requestData: QueryParams) {
    try {
      return await httpClient.get<ContactsListDataTotal>(
        getListDataTotalURL,
        requestData,
        ContactsListDataTotal,
        true,
        true
      );
    } catch (error) {
      throw error;
    }
  }
}
