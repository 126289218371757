import { Box } from '@mui/material';
import React from 'react';

import {
  StyledDetailedDataSectionContent,
  StyledDetailedDataSectionContentDescription,
  StyledDetailedDataSectionContentTitle,
} from '../../TripPlanV3/styles';
import {
  StopSolutionV3Prop,
  StopSolutionV3StopType,
} from '../../TripPlanV3/types';
import { TimeLineIconMapping } from '../timelineIconsMapping';
import {
  getDisplayArrivalTimeTitle,
  getDisplayDepartureTimeTitle,
  isContactEmpty,
} from './timelineV3.utils';
import { useTranslation } from 'react-i18next';

export const StyledDetailedDataSection = ({
  icon,
  title,
  description,
}: {
  icon: JSX.Element;
  title: React.ReactNode;
  description: React.ReactNode;
}) => {
  return (
    <StyledDetailedDataSectionContent>
      <Box
        component="span"
        sx={{
          width: '25px',
        }}
      >
        {icon}
      </Box>
      <Box
        sx={{
          whiteSpace: 'pre-wrap',
        }}
      >
        {title ? (
          <StyledDetailedDataSectionContentTitle component="span">
            {title}
          </StyledDetailedDataSectionContentTitle>
        ) : (
          <></>
        )}
        {description ? (
          <StyledDetailedDataSectionContentDescription component="span">
            {description}
          </StyledDetailedDataSectionContentDescription>
        ) : null}
      </Box>
    </StyledDetailedDataSectionContent>
  );
};

export const DetailedDataSection = ({
  stopSolution,
  idx,
  isCancelled = false,
  isCompleted = false,
}: {
  stopSolution: StopSolutionV3Prop;
  idx: number;
  isCancelled?: boolean;
  isCompleted?: boolean;
}): JSX.Element => {
  const { t, ready } = useTranslation();

  const getContact = () => {
    if (stopSolution?.contact?.phone) {
      return (
        <>
          <a href={`tel:${stopSolution?.contact?.phone}`}>
            {stopSolution?.contact?.phone}
          </a>{' '}
          / {stopSolution?.contact?.email ?? ''}
        </>
      );
    }
    return <>{stopSolution?.contact?.email ?? ''}</>;
  };
  if (ready) {
    return (
      <Box
        sx={{
          paddingLeft: '30px',
        }}
      >
        {!isCancelled ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.DriverActualMiles}
            title={`${TimelineFormatter.numberToMiles(
              stopSolution?.totalMiles
            )}`}
            description={idx === 0 ? t('emptyMile') : t('totalMile')}
          />
        ) : (
          <></>
        )}

        {!isCancelled ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.Driver}
            title={TimelineFormatter.minutesToTime(
              stopSolution?.totalDriveTime
            )}
            description={t('totalDriveTime')}
          />
        ) : (
          <></>
        )}

        {stopSolution?.isDriverAssistRequired ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.DriverAssist}
            title={t('TripAddNewLoadOption3DriverAssist')}
            description={''}
          />
        ) : (
          <></>
        )}

        <StyledDetailedDataSection
          icon={TimeLineIconMapping.Timelapse({
            isActive: false,
            color: 'action',
          })}
          title={TimelineFormatter.minutesToTime(
            stopSolution?.estimatedActivityDuration
          )}
          description={t('handlingTime')}
        />

        <StyledDetailedDataSection
          icon={TimeLineIconMapping.BeenhereIcon({
            isActive: false,
            ...(stopSolution?.arrivalDelay && {
              color: 'error',
            }),
          })}
          title={
            <Box
              component={'div'}
              sx={{
                ...(stopSolution?.arrivalDelay && {
                  color: 'error.main',
                }),
                whiteSpace: 'nowrap',
                display: 'inline',
              }}
            >
              {TimelineFormatter.getDisplayArrivalTime({
                actualTimeOfArrival: isCancelled
                  ? stopSolution.actualTimeOfCompletion
                  : stopSolution.actualTimeOfArrival,
                estimatedTimeOfArrival: isCancelled
                  ? stopSolution.estimatedTimeOfCompletion
                  : stopSolution.estimatedTimeOfArrival,
                timezone: stopSolution?.location?.timezone,
              })}
            </Box>
          }
          description={
            <Box
              whiteSpace={'pre-wrap'}
              component={'div'}
              sx={{
                display: 'inline',
              }}
            >
              <Box
                whiteSpace={'pre-wrap'}
                component={'div'}
                sx={{
                  display: 'inline',
                }}
              >
                {getDisplayArrivalTimeTitle({
                  isCompleted,
                  isCancelled,
                  stopSolution,
                })}
              </Box>
              {stopSolution?.arrivalDelay ? (
                <Box
                  whiteSpace={'pre-wrap'}
                  sx={{
                    color: 'error.main',
                    paddingLeft: '5px',
                    display: 'inline',
                  }}
                  component={'div'}
                >
                  ({TimelineFormatter.minutesToTime(stopSolution?.arrivalDelay)}{' '}
                  {t('delay')})
                </Box>
              ) : (
                <></>
              )}
            </Box>
          }
        />

        {!isCancelled ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.WatchIcon({
              isActive: false,
              ...(stopSolution?.detentionTime &&
                stopSolution?.detentionTime > 0 && {
                  color: 'error',
                }),
            })}
            title={
              <Box
                component={'span'}
                sx={{
                  ...(stopSolution?.detentionTime &&
                    stopSolution?.detentionTime > 0 && {
                      color: 'error.main',
                    }),
                }}
              >
                {TimelineFormatter.getDisplayTimeAtLocation({
                  dwellTime: stopSolution.dwellTime,
                  estimatedActivityDuration:
                    stopSolution.estimatedActivityDuration,
                })}
              </Box>
            }
            description={
              <Box component={'span'} whiteSpace={'pre-wrap'}>
                {t('timeatLocation')}
                {stopSolution?.detentionTime ? (
                  <Box
                    whiteSpace={'pre-wrap'}
                    sx={{
                      paddingLeft: '5px',
                      color: 'error.main',
                    }}
                    component={'span'}
                  >
                    (
                    {TimelineFormatter.minutesToTime(
                      stopSolution?.detentionTime
                    )}{' '}
                    {t('detention')})
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            }
          />
        ) : (
          <></>
        )}

        {!isCancelled ? (
          <StyledDetailedDataSection
            icon={TimeLineIconMapping.DoubleArrowIcon({
              isActive: false,
              color: 'action',
            })}
            title={TimelineFormatter.getDisplayDepartureTime({
              actualTimeOfCompletion: stopSolution.actualTimeOfCompletion,
              estimatedTimeOfCompletion: stopSolution.estimatedTimeOfCompletion,
              timezone: stopSolution?.location?.timezone,
            })}
            description={getDisplayDepartureTimeTitle({
              isCompleted,
              stopSolution,
            })}
          />
        ) : (
          <></>
        )}

        {stopSolution.type !== StopSolutionV3StopType.RELAY ? (
          <>
            {stopSolution?.noteDetails?.note ? (
              <StyledDetailedDataSection
                icon={TimeLineIconMapping.StickyNote2Icon({
                  isActive: false,
                  color: 'action',
                })}
                title={''}
                description={stopSolution?.noteDetails?.note}
              />
            ) : (
              <></>
            )}

            {stopSolution?.refNumber ? (
              <StyledDetailedDataSection
                icon={TimeLineIconMapping.PinOutlinedIcon({
                  isActive: false,
                  color: 'action',
                })}
                title={stopSolution?.refNumber}
                description={t('TripAddNewLoadOption3Reference')}
              />
            ) : (
              <></>
            )}

            {!isContactEmpty(stopSolution?.contact) ? (
              <StyledDetailedDataSection
                icon={TimeLineIconMapping.ContactsOutlinedIcon({
                  isActive: false,
                  color: 'action',
                })}
                title={stopSolution?.contact?.name || ''}
                description={getContact()}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    );
  }
};
import moment, { Moment, MomentInput } from 'moment';

export const MOMEMT_FORMAT_DATE = 'MMM DD z';
export const MOMEMT_FORMAT_DATE_TIME = 'MMM DD, YYYY, HH:mm z';
export const MOMEMT_FORMAT_TIME = 'HH:mm z';
export const MOMEMT_DRIVER_LOCATION_UPDATE_FORMAT_TIME = 'HH:mm [on] MMM Do z';
export const MOMEMT_HOS_EVENT_VIEW_FORMAT_TIME = 'MMM Do [@] HH:mm';

export class TimelineFormatter {
  //return : 7y 7M 28d 9h 20m, 1h20m, 1h, 20m,...
  static durationFormat = (_minutes: number): string => {
    const formatedDuration = [];
    const durationObject = moment.duration(_minutes, 'minutes');
    const years = durationObject.years();
    const months = durationObject.months();
    const days = durationObject.days();
    const hours = durationObject.hours();
    const minutes = durationObject.minutes();
    if (years > 0) {
      formatedDuration.push(`${years}y`);
    }
    if (months > 0) {
      formatedDuration.push(`${months}M`);
    }
    if (days > 0) {
      formatedDuration.push(`${days}d`);
    }
    if (hours > 0) {
      formatedDuration.push(`${hours}h`);
    }
    if (minutes > 0) {
      formatedDuration.push(`${minutes}m`);
    }

    return formatedDuration.join(' ');
  };

  static minutesToHours = (
    _minutes: number | null,
    defaultValue = ''
  ): string => {
    if (_minutes === null) {
      return defaultValue;
    }
    return TimelineFormatter.durationFormat(_minutes);
  };

  static numberToMiles = (value: number | null, defaultValue = ''): string => {
    if ((!value && typeof value !== 'number') || isNaN(Number(value))) {
      return defaultValue;
    }
    return `${new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }).format(Number(value))}mi`;
  };

  static dateToFormat = ({
    value,
    format,
    timezone,
  }: {
    value: MomentInput;
    format: string;
    timezone?: string | null;
  }): string => {
    if (value === null || value === '') {
      return '';
    }
    if (timezone) {
      return moment(value).tz(timezone).format(format);
    }
    return moment(value).format(format);
  };

  static getMomentObject = (dateTime: MomentInput): Moment => {
    return moment.isMoment(dateTime) ? dateTime : moment(dateTime);
  };

  static daysDiff = ({
    endDate,
    startDate,
    timezone,
  }: {
    endDate: MomentInput;
    startDate: MomentInput;
    timezone?: string | null;
  }): number => {
    const endDateWithTz = timezone
      ? moment(endDate).tz(timezone).startOf('day')
      : moment(endDate).startOf('day');
    const startDateWithTz = timezone
      ? moment(startDate).tz(timezone).startOf('day')
      : moment(startDate).startOf('day');
    const diff = endDateWithTz.diff(startDateWithTz, 'days');
    return diff;
  };

  static isSameDay = ({
    endDate,
    startDate,
    timezone,
  }: {
    endDate: MomentInput;
    startDate: MomentInput;
    timezone?: string | null;
  }): boolean => {
    return (
      TimelineFormatter.daysDiff({
        endDate,
        startDate,
        timezone,
      }) === 0
    );
  };

  static getDateTimeDisplayHumanReadable = ({
    dateTime,
    timezone,
    dateFormat,
    timeFormat,
  }: {
    dateTime: MomentInput;
    timezone?: string | null;
    dateFormat: string;
    timeFormat: string;
  }): string => {
    const myObjectDateTime = TimelineFormatter.getMomentObject(dateTime);
    const daysDiff = TimelineFormatter.daysDiff({
      endDate: dateTime,
      startDate: moment(),
      timezone,
    });

    const dateText =
      {
        '-1': 'Yesterday',
        '0': 'Today',
        '1': 'Tomorrow',
      }[daysDiff] || '';

    if (dateText) {
      return `${dateText}, ${TimelineFormatter.dateToFormat({
        value: myObjectDateTime,
        format: timeFormat,
        timezone,
      })}`;
    }

    return TimelineFormatter.dateToFormat({
      value: dateTime,
      format: dateFormat,
      timezone,
    });
  };

  static getDisplayAppointmentDateOrTime = (
    date: MomentInput,
    timezone?: string | null
  ): string => {
    if (!date) {
      return '';
    }
    return TimelineFormatter.dateToFormat({
      value: moment(date),
      timezone: timezone,
      format: MOMEMT_FORMAT_DATE_TIME,
    });
  };

  static getDisplayAppointmentDate = (
    arrivalTime: MomentInput,
    departureTime: MomentInput
  ): {
    arrivalTime: string;
    departureTime: string;
  } => {
    return {
      arrivalTime:
        TimelineFormatter.getDisplayAppointmentDateOrTime(arrivalTime),
      departureTime:
        TimelineFormatter.getDisplayAppointmentDateOrTime(departureTime),
    };
  };

  static getLeftSubTitleDateTime = (
    appointmentStartTime: MomentInput,
    appointmentEndTime: MomentInput,
    timezone?: string | null
  ): string[] => {
    const tmpStrArr: string[] = [
      TimelineFormatter.dateToFormat({
        value: appointmentStartTime,
        format: MOMEMT_FORMAT_DATE_TIME,
        timezone: timezone,
      }),
      ' - ',
      TimelineFormatter.dateToFormat({
        value: appointmentEndTime,
        format: MOMEMT_FORMAT_DATE_TIME,
        timezone,
      }),
    ];
    return tmpStrArr;
  };

  //return : 7y 7M 28d 9h 20m, 1h20m, 1h, 20m,...
  static minutesToTime = (minutes: number | null): string => {
    return TimelineFormatter.minutesToHours(minutes, '');
  };

  static getDisplayArrivalTime = ({
    actualTimeOfArrival,
    estimatedTimeOfArrival,
    timezone,
  }: {
    actualTimeOfArrival: MomentInput;
    estimatedTimeOfArrival: MomentInput;
    timezone?: string | null;
  }): string => {
    const tmpDateStr = actualTimeOfArrival
      ? actualTimeOfArrival
      : estimatedTimeOfArrival;
    return TimelineFormatter.getDisplayAppointmentDateOrTime(
      tmpDateStr,
      timezone
    );
  };

  static getDisplayDepartureTime = ({
    actualTimeOfCompletion,
    estimatedTimeOfCompletion,
    timezone,
  }: {
    actualTimeOfCompletion: MomentInput;
    estimatedTimeOfCompletion: MomentInput;
    timezone?: string | null;
  }): string => {
    const tmpDateStr = actualTimeOfCompletion
      ? actualTimeOfCompletion
      : estimatedTimeOfCompletion;
    return TimelineFormatter.getDisplayAppointmentDateOrTime(
      tmpDateStr,
      timezone
    );
  };

  static getDisplayTimeAtLocation = ({
    dwellTime,
    estimatedActivityDuration,
  }: {
    dwellTime: number | null;
    estimatedActivityDuration: number | null;
  }): string => {
    if (dwellTime !== null) {
      return dwellTime === 0
        ? '0m'
        : TimelineFormatter.minutesToHours(dwellTime);
    } else {
      return TimelineFormatter.minutesToHours(estimatedActivityDuration);
    }
  };

  static getDisplayDriverLocationUpdateTime = (date: MomentInput): string => {
    if (!date) {
      return '';
    }
    const myDate = moment(date);
    return myDate.format(MOMEMT_DRIVER_LOCATION_UPDATE_FORMAT_TIME);
  };

  static getDisplayHOSEventViewDateTime = (date: MomentInput): string => {
    if (!date) {
      return '';
    }
    const myDate = moment(date);
    return myDate.format(MOMEMT_HOS_EVENT_VIEW_FORMAT_TIME);
  };
}
