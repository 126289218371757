import BaseMultipleAutocomplete from '../../../ui-kit/components/MultipleAutocomplete';

const defaultStyles = { width: 300 };

export const MultipleAutocompleteFilter = ({
  id = '',
  name,
  fieldName,
  fieldValue,
  label,
  value,
  options,
  defaultValue = [],
  getOptions,
  onChangeCb,
  customStyles,
  startAdornment = null,
  variant,
  isCustomDefaultInputStyles = true,
}: {
  id?: string;
  name: string;
  fieldName: string;
  fieldValue?: string;
  label: string;
  value: any;
  options?: any[];
  defaultValue?: any;
  getOptions?: (value: string, pageNumber: number) => any;
  onChangeCb?: (data: any[], name: string) => void;
  customStyles?: any;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  startAdornment?: JSX.Element | null;
  isCustomDefaultInputStyles?: boolean;
}) => {
  return (
    <BaseMultipleAutocomplete
      id={id}
      customTextInputStyles={
        isCustomDefaultInputStyles
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(43, 100, 203, 0.5)',
              },
            }
          : {}
      }
      variant={variant || 'outlined'}
      customInputProps={
        isCustomDefaultInputStyles
          ? {
              borderRadius: '10px',
            }
          : {}
      }
      customInputLabelProps={{
        color: variant === 'standard' ? undefined : undefined,
      }}
      size={'small'}
      name={name}
      fieldName={fieldName}
      fieldValue={fieldValue}
      label={label}
      value={value}
      options={options}
      defaultValue={defaultValue}
      getOptions={getOptions}
      onChangeCb={onChangeCb}
      customStyles={customStyles || defaultStyles}
      startAdornment={startAdornment}
    />
  );
};
