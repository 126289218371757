import { ITemplateService } from '../interfaces/ITemplateService';
import { annotateNameAsync } from '../interfaces';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  templateActionURL,
  deleteTemplateURL,
  getPaginatedTemplateListURL,
  getTemplateSummaryURL,
  exportTemplateListToExcelURL,
} from './requestConstants';
import {
  PaginatedTemplateListRequest,
  PaginatedTemplateListResponse,
  CreateTemplateRequest,
  EditTemplateRequest,
  TemplateSummaryRequest,
  DeleteTemplateRequest,
  TemplateDetailsResponse,
  TemplateSummaryResponse,
  DeleteTemplateResponse,
  QueryParams,
  TemplateDetailsRequest,
} from '../../models';

export class TemplateService extends ITemplateService {
  @annotateNameAsync
  async getPaginatedTemplateList(requestData: PaginatedTemplateListRequest) {
    try {
      const result = await httpClient.get<PaginatedTemplateListResponse>(
        getPaginatedTemplateListURL,
        requestData,
        PaginatedTemplateListResponse,
        true,
        true
      );

      result.content = result.content ?? [];
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async exportTemplateListToExcel(requestData: PaginatedTemplateListRequest) {
    try {
      const result = await httpClient.getRaw(
        exportTemplateListToExcelURL,
        requestData
      );

      return result.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createTemplate(payloadData: CreateTemplateRequest) {
    try {
      return await httpClient.post<TemplateDetailsResponse>(
        `${templateActionURL}create`,
        new QueryParams(true),
        payloadData,
        TemplateDetailsResponse,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async editTemplate(payloadData: EditTemplateRequest) {
    try {
      return await httpClient.put<TemplateDetailsResponse>(
        `${templateActionURL}update`,
        undefined,
        payloadData,
        TemplateDetailsResponse,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteTemplate(requestData: DeleteTemplateRequest) {
    try {
      const response = await httpClient.delete<DeleteTemplateResponse>(
        deleteTemplateURL,
        requestData,
        undefined,
        DeleteTemplateResponse
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTemplateDetails(requestData: TemplateDetailsRequest) {
    try {
      return await httpClient.get<TemplateDetailsResponse>(
        `${templateActionURL}get/one`,
        requestData,
        TemplateDetailsResponse,
        true,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTemplateSummary(requestData: TemplateSummaryRequest) {
    try {
      return await httpClient.get<TemplateSummaryResponse>(
        getTemplateSummaryURL,
        requestData,
        TemplateSummaryResponse,
        false,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
