/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import EditModeAddressField from './components/editableAddressField';
import ReadOnlyModeAddressField from './components/readOnlyAddressField';
import { propsType } from './types/AddressPropType';

function Address({
  data,
  onChange,
  errors,
  required = false,
  label = t('TripAddNewLoadOption3Address'),
  maxWidth,
  disabled = false,
  disableAddressLine = false,
  variant = 'standard',
  includeTimezone = false,
  validateAddress,
  textInputSxProps,
  onlyState = false,
  autoFocus = true,
}: propsType) {
  const [isInEditMode, setIsInEditMode] = useState(true);

  useEffect(() => {
    if (data?.fullAddress) {
      setIsInEditMode(false);
    }
  }, [data]);

  const onEditStarted = () => {
    if (disabled) return;
    setIsInEditMode(true);
    if (data) {
      onChange({ ...data, fullAddress: '' });
    }
  };

  return (
    <React.Fragment>
      {!isInEditMode && (
        <Box sx={{ maxWidth: maxWidth, width: '100%' }} onClick={onEditStarted}>
          <ReadOnlyModeAddressField
            data={data}
            errors={errors}
            required={required}
            label={label}
            maxWidth={maxWidth}
            disabled={disabled}
            variant={variant}
            validateAddress={validateAddress}
            textInputSxProps={textInputSxProps}
          />
        </Box>
      )}
      {isInEditMode && (
        <EditModeAddressField
          disabled={disabled}
          onlyState={onlyState}
          variant={variant}
          data={data}
          validateAddress={validateAddress}
          onAddressChanged={onChange}
          errors={errors}
          required={required}
          label={label}
          maxWidth={maxWidth}
          disableAddressLine={disableAddressLine}
          includeTimezone={includeTimezone}
          textInputSxProps={textInputSxProps}
          autoFocus={autoFocus}
        />
      )}
    </React.Fragment>
  );
}

export default Address;
