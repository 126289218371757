import { styled } from '@mui/styles';

export const StyledPopupRoot = styled('div')(
  ({ theme, isTripTab = false }: { isTripTab?: boolean; theme: any }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    background: theme.palette.primary.contrast,
    // height: 'calc(100% - 64px)',
    height: '100%',
    ...(isTripTab
      ? {
          padding: '16px 5px 16px 0',
        }
      : {
          padding: '16px',
        }),
  })
);

export const StyledPopupContent = styled('div')(() => ({
  position: 'relative',
  flex: '1 1 auto',
  overflowY: 'auto',
}));
