import Grid from '@mui/material/Grid';
import { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FORM_DATETIMEPICKER_FORMAT } from '../../../constants/date.constants';
import { DateTimePicker as DateTimePickerUiKit } from '../../../ui-kit/components/AxeleDatePicker/components/DateTimePicker';

const DateTimePicker: React.FC<{
  id?: string;
  control?: any;
  open?: boolean;
  name: string;
  label?: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  styleProps?: React.CSSProperties;
  helperText?: React.ReactNode;
  variant?: 'standard' | 'outlined' | 'filled';
  showActions?: boolean;
  renderInput?: (props: TextFieldProps) => React.ReactElement;
  useDarkMode?: boolean;
  onChangeCallback?: (
    data: any,
    name: string | undefined,
    keyboardInputValue: any
  ) => void;
  onError?: (reason: any, newValue: any) => void;
  sizes?: {
    xs: number;
  };
  disableFuture?: boolean;
  timezone?: string;
}> = ({
  id = '',
  control,
  name,
  required = false,
  disabled = false,
  styleProps = {},
  helperText = '',
  variant = 'standard',
  showActions = true,
  renderInput,
  label,
  onError,
  onChangeCallback,
  open,
  useDarkMode = false,
  sizes = {},
  disableFuture,
  timezone,
  inputFormat = FORM_DATETIMEPICKER_FORMAT,
  ...restProps
}) => {
  return (
    <Grid xs={sizes.xs} item style={{ marginBottom: 20 }}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <DateTimePickerUiKit
              id={id}
              open={open}
              label={label}
              value={value}
              variant={variant}
              required={required}
              disabled={disabled}
              error={!!error}
              helperText={helperText || error?.message}
              showActions={showActions}
              renderInput={renderInput}
              onError={onError}
              onChange={(newValue: any, keyboardInputValue?: string) => {
                onChange(newValue, keyboardInputValue);
                if (typeof onChangeCallback === 'function') {
                  onChangeCallback(newValue, name, keyboardInputValue);
                }
              }}
              styleProps={styleProps}
              useDarkMode={useDarkMode}
              inputFormat={inputFormat}
              {...restProps}
              hideTabs={true} //disable the native hour/time tabs on the top of calendar
              disableFuture={disableFuture}
              timezone={timezone}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue=""
      />
    </Grid>
  );
};

export default React.memo(DateTimePicker);
