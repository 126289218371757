import DateRangeIcon from '@mui/icons-material/DateRange';
import { Typography } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers-v5/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-v5/LocalizationProvider';
import { DateTimeValidationError } from '@mui/x-date-pickers-v5/internals/hooks/validation/useDateTimeValidation';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useCallback, useState } from 'react';
import {
  isTimezoneValid,
  toTimezoneMoment,
  useTimezoneAdapterMoment,
} from '../../../Utils';
import { AxeleThemeProps } from '../../constants';

const StyledTextField = styled(TextField)(({ theme }: AxeleThemeProps) => ({
  //TODO refactoring moved to src/ui-kit/theme/components/MuiFormLabel.ts
  // '.MuiFormLabel-asterisk': {
  //   color: theme.palette.error.main,
  // },
}));

export interface DateTimePickerProps
  extends Omit<MuiDateTimePickerProps<Moment, Moment>, 'renderInput'> {
  error?: boolean;
  required?: boolean;
  styleProps?: React.CSSProperties;
  helperText?: React.ReactNode;
  variant?: 'standard' | 'outlined' | 'filled';
  onClick?: (event: any) => void;
  renderInput?: (props: TextFieldProps) => React.ReactElement;
  timezone?: string;
  inputFormat?: string;
  name?: string;
  id?: string;
}

export const DateTimePicker = ({
  error = false,
  required = false,
  styleProps = {},
  helperText = 'Date-time is not valid',
  variant = 'standard',
  onClick,
  id,
  name,
  label,
  renderInput,
  onError,
  onChange,
  timezone,
  value,
  inputFormat = 'MM/DD/YYYY hh:mm a',
  ...restProps
}: DateTimePickerProps) => {
  const [datePickerError, setdatePickerError] = useState<boolean>(error);

  const onChangeHandler = useCallback(
    (newValue: Moment | null, keyboardInputValue?: string) => {
      if (onChange) {
        onChange(newValue, keyboardInputValue);
      }
      if (required && newValue === null) {
        setdatePickerError(true);
      }
    },
    [required, onChange]
  );

  const renderInputHandler = (params: TextFieldProps): React.ReactElement => {
    if (renderInput) {
      return renderInput(params);
    } else {
      return (
        <StyledTextField
          {...params}
          size="small"
          variant={variant}
          error={datePickerError || error}
          required={required}
          sx={styleProps}
          helperText={
            <>
              {isTimezoneValid(timezone) && (
                <Typography
                  fontSize="xx-small"
                  color="text.secondary"
                  variant="subtitle1"
                >
                  {timezone}
                </Typography>
              )}
              {(datePickerError || error) && helperText}
            </>
          }
          {...(onClick && { onClick })}
        />
      );
    }
  };
  const onErrorHandler = useCallback(
    (reason: DateTimeValidationError, newValue: Moment | null): void => {
      if (onError) {
        onError(reason, newValue);
      }
      setdatePickerError(reason !== null);
    },
    [onError]
  );

  const adapter = useTimezoneAdapterMoment(timezone);

  return (
    <LocalizationProvider dateLibInstance={moment} dateAdapter={adapter}>
      <MuiDateTimePicker
        {...restProps}
        value={toTimezoneMoment(value, timezone)}
        label={label}
        components={{
          OpenPickerIcon: DateRangeIcon,
        }}
        renderInput={(params: any) =>
          renderInputHandler({ ...params, id, name })
        }
        onChange={onChangeHandler}
        onError={onErrorHandler}
        inputFormat={inputFormat}
      />
    </LocalizationProvider>
  );
};
