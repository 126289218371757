export enum REJECT_RESOURCE_KEYS {
  REJECT = 'REJECT',
  UNREJECT = 'UN_REJECT',
}

export enum LOCK_RESOURCE_KEYS {
  LOCK = 'LOCK',
  UNLOCK = 'UN_LOCK',
}

export enum TRIP_STATE {
  LOCKED = 'LOCKED',
  REJECTED = 'REJECTED',
  OPTIMAL = 'OPTIMAL',
}

export enum REJECT_TYPE {
  DRIVER = 'DRIVER',
  TRIP = 'TRIP',
}

export enum RECOMMENDED_RESOURCE_TYPE {
  ASSIGNED_DRIVER = 'ASSIGNED_DRIVER',
  UNASSIGNED_DRIVER = 'UNASSIGNED_DRIVER',
  UNASSIGNED_TRIP = 'UNASSIGNED_TRIP',
  BROKERED_TRIP = 'BROKERED_TRIP',
}

export const UNASSIGNED_TRIP_ID_PREFIX = 'UNASSIGNED_TRIP:';
export const BROKERED_TRIP_ID_PREFIX = 'BROKERED_TRIP:';

export enum RESOURCE_STATE {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UN_LOCKED',
  REJECTED = 'REJECTED',
  NONE = 'NONE',
}

export const POSSIBLE_RESOURCES = {
  POSSIBLE_DRIVER: 'POSSIBLE_DRIVER',
  POSSIBLE_TRIP: 'POSSIBLE_TRIP',
};

export enum AssignmentPreferenceType {
  None = 'None',
  unlockedSelected = 'unlockedSelected',
  lockedSelected = 'lockedSelected',
  rejected = 'rejected',
}
