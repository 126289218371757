export const ReSequenceStopIcon = ({
  color = '#2B64CB',
}: {
  color?: string;
}) => {
  return (
    <svg
      width="20"
      height="11"
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 10.5L10 0.5L20 10.5H0Z" fill={color} />
    </svg>
  );
};
