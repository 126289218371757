import {
  annotateNameAsync,
  IFuelMatrixService,
  ServiceError,
} from '@/api/interfaces';
import { FuelMatrix } from '@/models';
import { ComposedError, httpClient } from '@/axios/axiosInstance';
import {
  CreateUpdateFuelMatrixRequest,
  FuelMatrixValidationError,
  GetFuelMatrixRequest,
} from '@/models/DTOs/FuelMatrix/Requests';
import { RootStoreInstence } from '@/store/root-store/rootStateContext';

export class FuelMatrixService extends IFuelMatrixService {
  @annotateNameAsync
  async getFuelMatrixList(
    requestData: GetFuelMatrixRequest
  ): Promise<FuelMatrix[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        'web/customer/api/v2/fuel-surcharge/get/list',
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async createFuelMatrixList(
    requestData: CreateUpdateFuelMatrixRequest
  ): Promise<FuelMatrix | ServiceError | FuelMatrixValidationError> {
    let response;
    try {
      response = await httpClient.postRaw(
        'web/customer/api/v2/fuel-surcharge',
        undefined,
        requestData,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createFuelMatrix',
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 417) {
        return {
          validationType: error.response.data.validationType,
          errors: error.response.data.errors,
          hasErrors: true,
        };
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'createFuelMatrix',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async updateFuelMatrixList(
    requestData: CreateUpdateFuelMatrixRequest
  ): Promise<FuelMatrix | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        'web/customer/api/v2/fuel-surcharge',
        undefined,
        requestData,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateFuelMatrix',
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 417) {
        return {
          validationType: error.response.data.validationType,
          errors: error.response.data.errors,
          hasErrors: true,
        };
      }
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'updateFuelMatrix',
      });
      return this.getServiceError(error as ComposedError);
    }
  }
}
