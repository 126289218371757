import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormPhoneField from '../../../common/Ui/FormPhoneField';
import TextField from '../../../common/Ui/TextField/TextField';
import { getAccountTypeWithRoleCode, isHasPermission } from '../../../utils';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';

export default function ContactForm({ id }: { id: number }) {
  const { control } = useFormContext();

  const noPermissionForEditCustomer = !isHasPermission([
    AXELE_PERMISSION_TYPE.CUSTOMER_EDIT,
  ]);

  return (
    <>
      <TextField
        disabled={noPermissionForEditCustomer}
        control={control}
        name={`contacts.${id}.fullName`}
        label="Name"
        sizes={{ xs: 6 }}
        required
        size={'small'}
      />
      <TextField
        disabled={noPermissionForEditCustomer}
        control={control}
        name={`contacts.${id}.email`}
        label="Email"
        sizes={{ xs: 6 }}
        size={'small'}
      />
      <FormPhoneField
        disabled={noPermissionForEditCustomer}
        control={control}
        name={`contacts.${id}.phoneData`}
        label="Phone #"
        sizes={{ xs: 12 }}
        size={'small'}
      />
      <TextField
        disabled={noPermissionForEditCustomer}
        control={control}
        name={`contacts.${id}.description`}
        label="Description"
        sizes={{ xs: 12 }}
        size={'small'}
      />
    </>
  );
}
