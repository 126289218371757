import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import IconsComponent from '../../ui-kit/components/IconsComponent';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import { useEffect, useState } from 'react';
import { subscriptionService } from '../../api';
import { UpsertPaymentRequest } from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import {
  ChargifyBankInfo,
  ChargifyCardInfo,
} from '../../views/register/Chargify';
import { bankFields, cardFields, letter15, message } from './constant';

const paymentOptions = [
  { key: 'card', value: 'Card' },
  // { key: 'bank', value: 'ACH Account' },
];

type PaymentInfoPopupProps = {
  setShowPaymentScreen: (x: boolean) => void;
  data: any;
  getSubscription: () => void;
};

const PaymentInfoPopup = ({
  setShowPaymentScreen,
  data,
  getSubscription,
}: PaymentInfoPopupProps) => {
  const [selectedMethod, setSelectedMethod] = useState<string>('card');
  const [cardData, setCardData] = useState({});
  const [isPrimary, setIsPrimary] = useState(false);
  const [generatedToken, setToken] = useState<string>('');

  const onAdd = () => {
    cardData.chargify.token(
      cardData.chargifyForm.current,
      (token: string) => {
        setToken(token);
      },
      (errorResponse: string | Array<string>) => {
        const errorMsg = Array.isArray(errorResponse?.errors)
          ? errorResponse?.errors[0]
          : errorResponse?.errors;
        RootStoreInstence.setNotificationType({
          message: errorMsg,
          type: 'FAILURE',
        });
      }
    );
  };

  const callApi = async () => {
    const query = new UpsertPaymentRequest();
    query.chargify_token = generatedToken;
    // query.billing_address = address.address;
    // query.billing_city = address.city;
    // query.billing_state = address.state;
    // query.billing_zip = address.zipcode;
    // query.billing_country = address.country;
    query.subscription_id = data?.id;
    query.customer_id = data?.customer?.id;
    query.subscription_state = data?.state;
    query.default_payment_type = isPrimary;
    const res = await subscriptionService.upsertPayment(query);
    if (res) {
      if (isPrimary) getSubscription();
      setShowPaymentScreen(false);
    }
  };

  useEffect(() => {
    if (generatedToken) callApi();
  }, [generatedToken]);

  useEffect(() => {
    setToken('');
  }, [selectedMethod]);

  const onclose = () => {
    setShowPaymentScreen(false);
  };

  return (
    <Dialog onClose={onclose} open={true}>
      <Box
        sx={{
          padding: '24px',
          width: '527px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '46px',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '24px',
          }}
        >
          <Box sx={(theme) => ({ ...theme.typography.h6, ...letter15 })}>
            Add Payment Method
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={onclose}>
            <IconsComponent iconName="Close" source="MUIcons" />
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 'calc(100vh - 219px)',
            minWidth: 'fit-content',
          }}
        >
          <ButtonGroup fullWidth size="large"></ButtonGroup>
          {selectedMethod === 'card' ? (
            <ChargifyCardInfo setCardData={setCardData} fields={cardFields} />
          ) : (
            <ChargifyBankInfo
              rejoin={false}
              setCardData={setCardData}
              fields={bankFields}
            />
          )}
          <Box>
            {selectedMethod === 'card' && (
              <Box
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={() => {
                  setIsPrimary(!isPrimary);
                }}
              >
                <Checkbox checked={isPrimary} />
                <Box
                  sx={(theme) => ({ ...theme.typography.body1, ...letter15 })}
                >
                  {message.markas}
                </Box>
              </Box>
            )}
            <Box
              sx={(theme) => ({
                ...theme.typography.body3,
                marginBottom: '24px',
                color: 'text.primary',
                letterSpacing: '0.4px',
              })}
            >
              {selectedMethod === 'card' ? message.note : message.account}
            </Box>
          </Box>
          {/* <Box sx={{ marginBottom: '24px' }}>
            <Address
              onChange={(data: addressType) => {
                setAddress(data);
              }}
              data={address}
              errors={error}
              required={true}
              label={'Billing Address'}
            />
          </Box> */}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonImproved label="Cancel" variant="outlined" onClick={onclose} />
          <ButtonImproved
            label="Add Payment"
            variant="contained"
            onClick={onAdd}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default PaymentInfoPopup;
