import { Box, Checkbox, Typography } from '@mui/material';
import React from 'react';

interface IColumnHeader {
  name: string | string[];
  allowCheckbox?: boolean;
  onCheckboxClick?: (name?: string | string[]) => void;
  containerStyles?: object;
  separator?: string | null;
  textStyles?: object;
}

export const DRIVER_HEADER_STYLE_INFO = {
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '160%',
  letterSpacing: '0.17px',
  color: 'rgba(4, 0, 34, 0.75)',
  fontFamily: 'Poppins',
  // textAlign: 'center',
};

export const ColumnHeaderTooltipContainer = {
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D',
  borderRadius: '4px',
  alignItems: 'center',
  padding: '4px 8px',
  display: 'flex',
};

export const TooltipLabelStyles = {
  fontSize: '10px',
  lineHeight: '140%',
  display: 'flex',
  color: '#FFFFFF',
  fontFamily: 'Poppins',
};

interface IHeaderTooltip {
  labels: Array<string>;
  labelStyles?: object;
}

const ColumnHeader: React.FC<IColumnHeader> = (props) => {
  const {
    name,
    allowCheckbox = false,
    containerStyles,
    separator,
    textStyles,
  } = props;
  return (
    <Box sx={containerStyles}>
      {allowCheckbox && <input type="checkbox" style={{ padding: '0 5px' }} />}
      {Array.isArray(name) ? (
        name?.map((val: string, i: number) => (
          <Typography
            style={{
              ...(DRIVER_HEADER_STYLE_INFO as object),
              fontSize: '10px',
              ...textStyles,
            }}
            key={val}
          >
            {separator?.length && i > 0 ? ` ${separator} ${val}` : val}
          </Typography>
        ))
      ) : (
        <Typography
          style={{ ...(DRIVER_HEADER_STYLE_INFO as object), ...textStyles }}
        >
          {props?.name}
        </Typography>
      )}
    </Box>
  );
};

const ColumnHeaderTooltipTemplate: React.FC<IHeaderTooltip> = (props) => {
  return (
    <Box style={ColumnHeaderTooltipContainer}>
      {props?.labels?.map((label: string, i: number) => (
        <Typography key={label} style={TooltipLabelStyles}>
          {i > 0 ? ` / ` : ''}
          {label}
        </Typography>
      ))}
    </Box>
  );
};

export { ColumnHeader as default, ColumnHeaderTooltipTemplate };
