import {
  OPTYMIZATION_GANTT_TIMELINE_HEADER_CLASS,
  RESOURCES_TYPE,
} from '../constants/gantt.const';
import { IRecommendedResource } from '../types/gantt.types';
import { ReassignOptionTypes } from '../types/recommendTrips.types';
import { isResourceTypeSandbboxTrip } from './operations.service';

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const getHoursIn24Format = (d: Date | string) => {
  const date = new Date(d);
  const hours = date.getHours();
  return hours < 10 ? `0${hours}:00` : `${hours}:00`;
};

export const formatDateToDayAndMonth = (
  date: Date | string,
  isMonthRequired = true
) => {
  const d = new Date(date);
  const mm = d.getMonth() + 1;
  const dd = d.getDate();
  const day = weekDays[d.getDay()];
  if (!isMonthRequired) return `${day} ${dd}`;

  return `${day} ${mm}/${dd}`;
};

export const formatDateToHHMM = (d: Date | string) => {
  const date = new Date(d);
  let hours = date.getHours() as any;
  let minutes = date.getMinutes() as any;
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

const ganttHeaderHourRenderer = ({ startDate, headerConfig }: any) => {
  headerConfig.headerCellCls = `dispatch-gantt-day-header ${OPTYMIZATION_GANTT_TIMELINE_HEADER_CLASS}`;
  if (
    new Date(startDate).getMinutes() == 0 &&
    new Date(startDate).getHours() == 0
  ) {
    return `${formatDateToDayAndMonth(startDate)}`;
  }
  return `${formatDateToHHMM(startDate)}`;
};

const ganttHeader24HoursRenderer = ({ startDate, headerConfig }: any) => {
  headerConfig.headerCellCls = `dispatch-gantt-hour-header ${OPTYMIZATION_GANTT_TIMELINE_HEADER_CLASS}`;
  return getHoursIn24Format(startDate);
};

export const OPTYMIZATION_TIMELINE_HEADER_CONFIG = [
  {
    id: 'hourAndDay3',
    name: 'Hour and day view',
    tickWidth: 70,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'day',
        increment: 1,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => ganttHeaderHourRenderer({ startDate, headerConfig }),
      },
      {
        unit: 'hours',
        increment: 8,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => ganttHeader24HoursRenderer({ startDate, headerConfig }),
      },
    ],
  },
  {
    id: 'hourAndDay2',
    name: 'Hour and day view',
    tickWidth: 70,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'day',
        increment: 1,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => ganttHeaderHourRenderer({ startDate, headerConfig }),
      },
      {
        unit: 'hours',
        increment: 6,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => ganttHeader24HoursRenderer({ startDate, headerConfig }),
      },
    ],
  },
  {
    id: 'hourAndDay',
    name: 'Hour and day view',
    tickWidth: 70,
    timeResolution: {
      unit: 'day',
      increment: 1,
    },
    headers: [
      {
        unit: 'day',
        increment: 1,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => ganttHeaderHourRenderer({ startDate, headerConfig }),
      },
      {
        unit: 'hours',
        increment: 4,
        renderer: (
          startDate: any,
          endDate: any,
          headerConfig: any,
          cellIdx: any
        ) => ganttHeader24HoursRenderer({ startDate, headerConfig }),
      },
    ],
  },
];

export const getResourceReassignOptions = (
  resource?: IRecommendedResource
): ReassignOptionTypes[] => {
  if (!resource) return [];
  const options: ReassignOptionTypes[] = [];
  if (!isResourceTypeSandbboxTrip(resource))
    options.push({
      id: resource.id,
      label: resource?.driverGroupDetails?.groupName,
      type: RESOURCES_TYPE.DRIVER,
    });
  resource?.selectedTripData?.forEach?.((trip) => {
    options.push({
      id: trip?.tripPrimaryId as string,
      label: trip?.tripSeqNumber,
      type: RESOURCES_TYPE.TRIP,
    });
  });
  return options;
};

export const isEvaluationIssueCritical = (
  driver: IRecommendedResource
): boolean => {
  if (driver?.evaluateResponseDTO?.isCriticalIssue) return true;
  return false;
};
