import { FC } from 'react';
import Box from '@mui/material/Box';
import SearchItem from './SearchItem';
import { groupContainer } from './style';

interface SearchItemProps {
  axeleId: string;
  city: string;
  entityId: string;
  entityType: string;
  loadId: string;
  name: string;
  organizationId: number;
  referenceId: string;
  searchDate: string;
  searchKey: string;
  seqNumber: string;
  state: string;
  status: string;
  text: string;
  vin: string;
}

interface SearchItemsGroupProps {
  grouppedValue: SearchItemProps[];
  showAll?: string;
  onClose: () => void;
  onSearchItemClicked: any;
}

const SearchItemsGroup: FC<SearchItemsGroupProps> = ({
  grouppedValue,
  showAll,
  onClose,
  onSearchItemClicked,
}) => {
  const showGrouppedValue = showAll ? grouppedValue : grouppedValue.slice(0, 3);
  return (
    <Box sx={groupContainer}>
      {showGrouppedValue.map((element: any, i: any) => {
        return (
          <SearchItem
            key={i}
            lineItem={element}
            onClose={onClose}
            onSearchItemClicked={onSearchItemClicked}
          ></SearchItem>
        );
      })}
    </Box>
  );
};

export default SearchItemsGroup;
