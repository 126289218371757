import { Box, Dialog, Grid, Typography } from '@mui/material';
import { debounce } from 'lodash';
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import StorageManager from '../../../StorageManager/StorageManager';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../common/Permission';
import useInfiniteScroll from '../../../common/hooks/useInfiniteScroll';
import { IMaintenanceHistory } from '../../../models/DTOs/maintenance/maintenanceHistory/Model';
import { PaginatedMaintenanceHistoryListRequest } from '../../../models/DTOs/maintenance/maintenanceHistory/Requests';
import { IMaintenanceItem } from '../../../models/DTOs/maintenance/maintenanceItem/Model';
import { AddIcon } from '../../../ui-kit/components/Assets';
import AutocompleteField from '../../../ui-kit/components/Autocomplete';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import { isHasPermission } from '../../../utils';
import { currencyDisplay } from '../../../utils/grid.utils';
import MaintenanceDetailPanelWrapper from '../../../views/maintenanceRecords/Popups/component/MaintenanceDetailsForm/MaintenanceDetailPanelWrapper';
import { IMaintenanceRecordFilters } from '../../../views/maintenanceRecords/constants/types';
import { MaintenanceTabDetailsProps } from './MaintenanceTabDetails';
import { GroupedMaintenance } from './components/GroupedMaintenance';
import { InfiniteScrollWrapper } from './components/InfiniteScrollWrapper';
import { MaintenanceHistoryContext } from './context/MaintenanceHistoryContext';
import { getPaginatedMaintHistoryListRequest } from './controllers/Controller';
import { getAutocompleteStyles } from './styles';
import { t } from 'i18next';

interface IOption {
  value: string;
  label: string;
}

const delayInMs = 300;

const MaintenanceTabDetailsWrapper: FC<MaintenanceTabDetailsProps> = ({
  assetId,
  equipmentTypes,
  defaultMaintenanceRecord = {},
  disableAllFields,
}) => {
  const {
    totalAmountMaintCostYTD,
    maintenanceItems = [],
    maintenanceHistoryList: { thisMonth, thisYear, theRest },
    filters,
    setFilters,
    getPaginatedMaintenanceHistoryList,
    maintenanceHistoryListPagination: { isLoading, last, pageNumber, pageSize },
    setMaintenanceHistoryListPagination,
  } = useContext(MaintenanceHistoryContext);

  const [maintenanceItemsFilter, setMaintenanceItemsFilter] = useState<
    string[]
  >([]);

  const isEdit = Boolean(assetId);
  const userStorageData = StorageManager.getUser();
  useEffect(() => {
    if (isEdit) {
      //only fetch data for editting equipment
      setFilters((oldVal) => ({
        ...oldVal,
        assetId: [assetId],
        equipmentTypes: [equipmentTypes],
      }));
    }
  }, [assetId, equipmentTypes]);

  const [selectedRecord, setSelectedRecord] =
    useState<Partial<IMaintenanceHistory> | null>(null);

  const handleOpenAddNewModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setSelectedRecord(defaultMaintenanceRecord);
  };

  const onChangeMaintItemsHandler = useMemo(
    () =>
      debounce((restProps: IOption[]) => {
        const maintenanceItemsFilter = restProps.map(
          (item: IOption) => item.value
        );
        setMaintenanceItemsFilter(maintenanceItemsFilter);
        setFilters((oldState: IMaintenanceRecordFilters) => {
          return { ...oldState, maintenanceItems: maintenanceItemsFilter };
        });
      }, delayInMs),
    []
  );

  interface QueryParamsTableProps {
    reqPageNumber: number;
  }

  const getQueryParamsTable = ({
    reqPageNumber,
  }: QueryParamsTableProps): PaginatedMaintenanceHistoryListRequest => {
    return getPaginatedMaintHistoryListRequest({
      ...filters,
      pageSize: pageSize,
      pageNumber: reqPageNumber,
    });
  };

  const canSendRequestTable = (): boolean => {
    return !!(
      !isLoading &&
      filters &&
      filters.assetId &&
      isEdit &&
      Object.keys(filters.assetId).length > 0
    );
  };

  const sendRequestTable = async ({
    reqPageNumber,
  }: {
    reqPageNumber: number;
  }): Promise<void> => {
    if (canSendRequestTable()) {
      const queryParams = getQueryParamsTable({
        reqPageNumber: reqPageNumber,
      });
      await getPaginatedMaintenanceHistoryList(queryParams);
    }
  };

  useEffect(() => {
    sendRequestTable({ reqPageNumber: 1 });
  }, [filters]);

  useEffect(() => {
    sendRequestTable({ reqPageNumber: pageNumber });
  }, [pageNumber]);

  const onRowsScrollEnd = (): void => {
    if (!isLoading && !last) {
      setMaintenanceHistoryListPagination((oldState) => {
        return {
          ...oldState,
          pageNumber: pageNumber + 1,
        };
      });
    }
  };

  const hasNextPage = !last && isEdit;
  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    delayInMs: delayInMs,
    isLoading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: onRowsScrollEnd,
    rootMargin: '0px 0px 200px 0px',
  });

  const maintItemsElementRef = useRef(null);

  const noPermissionForAddMaintenance = !isHasPermission([
    AXELE_PERMISSION_TYPE.MAINTENANCE_HISTORY_ADD,
  ]);

  const styles = getAutocompleteStyles();

  return (
    <Grid container padding={2}>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={0}
      >
        <Grid item>
          <Grid container justifyContent="center" alignContent="center">
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  ...theme.typography?.h7,
                })}
              >
                {t('maintenanceActivity')}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!disableAllFields && (
                <Permission
                  contains={[AXELE_PERMISSION_TYPE.MAINTENANCE_HISTORY_ADD]}
                >
                  <ButtonImproved
                    onClick={handleOpenAddNewModal}
                    variant="outlined"
                    styleProps={{
                      minWidth: 38,
                      minHeight: 38,
                      borderColor: 'primary.main',
                      ml: 2,
                    }}
                    label={<AddIcon />}
                  />
                </Permission>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid spacing={0} item container direction="column">
            <Grid container item justifyContent="flex-end">
              <Typography
                sx={{ color: 'primary.main', fontSize: 20, fontWeight: 600 }}
              >
                {currencyDisplay(isEdit ? totalAmountMaintCostYTD : 0)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: 'text.secondary',
                  fontSize: 10,
                  fontWeight: 500,
                }}
              >
                {t('maintenanceTotal')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        sx={{
          mt: 1.5,
        }}
      >
        <Grid item>
          <Grid item>
            <Box
              ref={maintItemsElementRef}
              sx={{
                position: 'relative',
              }}
            >
              <Box sx={styles}>
                <AutocompleteField
                  disabled={noPermissionForAddMaintenance}
                  limitTags={1}
                  multiple={true}
                  styleProps={{
                    minWidth: 200,
                  }}
                  size="small"
                  isOptionEqualToValue={(
                    option: IOption,
                    value: IOption
                  ): boolean => {
                    return value && option.value == value.value;
                  }}
                  options={maintenanceItems?.map(
                    (item: IMaintenanceItem): IOption => {
                      return {
                        value: item.id,
                        label: item.itemName,
                      };
                    }
                  )}
                  onChange={(_event: any, data: IOption[]) =>
                    onChangeMaintItemsHandler(data)
                  }
                  variant="outlined"
                  label={t('type')}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <InfiniteScrollWrapper
        rootRef={rootRef}
        infiniteRef={infiniteRef}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        sx={{
          mt: 3,
          marginTop: `${
            104 + maintItemsElementRef.current?.clientHeight || 0
          }px`,
          position: 'absolute',
          width: 'calc(100% - 30px)',
          height: `calc(100% - 195px - ${
            maintItemsElementRef.current?.clientHeight || 0
          }px)`,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <GroupedMaintenance
              label="This month"
              data={thisMonth}
              selectedRecord={selectedRecord}
              onUpdateMaintenanceClick={setSelectedRecord}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupedMaintenance
              label="This year"
              data={thisYear}
              selectedRecord={selectedRecord}
              onUpdateMaintenanceClick={setSelectedRecord}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupedMaintenance
              label="The rest"
              data={theRest}
              selectedRecord={selectedRecord}
              onUpdateMaintenanceClick={setSelectedRecord}
            />
          </Grid>
        </Grid>
      </InfiniteScrollWrapper>
      {selectedRecord && (
        <Dialog
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
          open
        >
          <MaintenanceDetailPanelWrapper
            data={selectedRecord}
            onCloseMaintenance={() => setSelectedRecord(null)}
            onCreateSuccess={() => {
              setSelectedRecord(null);
              sendRequestTable({ reqPageNumber: 1 });
            }}
            onUpdateSuccess={() => {
              setSelectedRecord(null);
              sendRequestTable({ reqPageNumber: 1 });
            }}
            onDeleteSuccess={() => {
              setSelectedRecord(null);
              sendRequestTable({ reqPageNumber: 1 });
            }}
            lockEquipment
            isGlobal
            disableAllFields={disableAllFields}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default MaintenanceTabDetailsWrapper;
