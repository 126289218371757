import { PaginatedList } from '../../../types';

export interface FASOrder {
  property: string;
  direction: string;
}

//IncomeCategorySummary begin
export interface IFASIncomeCategorySummaryContent {
  id: string;
  itemId: string;
  itemName: string;
  description: string;
  subTotal: number;
  terminalId: string;
}

export class GetIncomeCategorySummaryResponse {
  content!: IFASIncomeCategorySummaryContent[];
  totalAmount!: number;
  order!: FASOrder[];

  constructor(dto: any) {
    Object.assign(this, dto);
  }
}

export interface IFASIncomeDetailsContent {
  paidOn: string;
  loadSeqNumber: string;
  loadId: string;
  customerName: any;
  customerId: number;
  amount: number;
}

export class GetIncomeDetailsResponse {
  content!: IFASIncomeDetailsContent[];
  order!: FASOrder[];
  first!: boolean;
  last!: boolean;
  number!: number;
  numberOfElements!: number;
  size!: number;
  totalElements!: number;
  totalPages!: number;
  totalAmount!: number;
  constructor(dto: any) {
    Object.assign(this, dto);
  }
}
//IncomeCategorySummary end

//ExpenseCategorySummary begin

export interface IFASExpenseCategorySummaryContent {
  id: string;
  expenseCategoryId: string;
  expenseCategoryKey: string;
  expenseCategoryName: string;
  description: string;
  subTotal: number;
  terminalId: string;
}

export class GetExpenseCategorySummaryResponse {
  content!: IFASExpenseCategorySummaryContent[];
  totalAmount!: number;
  order!: FASOrder[];

  constructor(dto: any) {
    Object.assign(this, dto);
  }
}

export interface IFASExpenseDetailsContent {
  paidOn: string;
  loadSeqNumber: string;
  loadId: string;
  customerName: any;
  customerId: number;
  amount: number;
}

export interface IFASExpenseDetailsDriverPayment {
  expenseDate: string;
  settlementSeqNumber: number;
  settlementId: number;
  driverId: number;
  paidBy: any;
  paidTo: string;
  paymentMode: any;
  loadSeqNumber: any;
  loadId: any;
  expenseType: string;
  truck: any;
  amount: number;
  description: string;
}

export interface IFASExpenseDetailsTractorPayment {
  expenseDate: string;
  settlementSeqNumber: number;
  settlementId: number;
  tractorId: number;
  paidBy: any;
  tractorName: string;
  paymentMode: any;
  loadSeqNumber: any;
  loadId: any;
  expenseType: string;
  amount: number;
  description: string;
}
export interface IFASExpenseDetailsDispatcherPayment {
  expenseDate: string;
  settlementSeqNumber: number;
  settlementId: number;
  dispatcherId: number;
  paidBy: any;
  paidTo: string;
  paymentMode: any;
  loadSeqNumber: string;
  loadId: string;
  expenseType: string;
  truck: any;
  amount: number;
  description: string;
}
export interface IFASExpenseDetailsExpensePayment {
  id: string;
  organizationId: number;
  seqNumber: number;
  category: string;
  expenseCategoryId: string;
  lastUpdated: string;
  description: any;
  fuelType: string;
  fuelQuantity: number;
  state: any;
  truckstopAddress: any;
  grossAmount: number;
  discountAmount: any;
  amount: number;
  finalAmount: number;
  paidDate: string;
  paidBy: any;
  paidById: any;
  paidTo: any;
  paidToId: any;
  referenceNumber: any;
  createdBy: string;
  status: string;
  loadsIdAndName: { loadIds: string[]; seqNumber: string }[];
  driverId: any;
  driverName: any;
  equipmentType: string;
  cardProvider: any;
  cardNumber: any;
  equipmentId: number;
  equipmentName: string;
  source: string;
  assignedDocuments: any[];
  driverPays: any;
  tractorPays: any;
  terminalId: string;
  companyExpense: boolean;
  categoryName: string;
}

export class GetExpenseDetailsResponse<TModel> {
  content!: TModel[];
  order!: FASOrder[];
  first!: boolean;
  last!: boolean;
  number!: number;
  numberOfElements!: number;
  size!: number;
  totalElements!: number;
  totalPages!: number;
  totalAmount!: number;
  constructor(dto: any) {
    Object.assign(this, dto);
  }
}

export class GetExpenseDetailsGlobalTotalResponse {
  totalAmount!: number;
  constructor(dto: any) {
    Object.assign(this, dto);
  }
}
export interface InvoiceCategory {
  id: string;
  organizationId: number;
  itemName: string;
  itemCode: string;
  description: string;
  deduction: boolean;
  custom: boolean;
}
export class GetInvoiceCategoriesResponse extends PaginatedList {
  content?: InvoiceCategory[];
}
//ExpenseCategorySummary end

export interface IFASExpenseDetailsCarrierPayment {
  id?: number;
  expenseDate: string;
  settlementSeqNumber: number;
  settlementId: number;
  driverId: number;
  paidBy: any;
  paidTo: string;
  paymentMode: any;
  loadSeqNumber: any;
  loadId: any;
  expenseType: string;
  truck: any;
  amount: number;
  description: string;
}
