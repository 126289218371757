import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Card,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Stack,
  StackProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

export const StyledNavigationButton = styled(
  (props: ButtonProps & { isSelected?: boolean }) => <Button {...props} />
)(({ theme }) => ({
  borderRadius: '14px',
  padding: '6px',
  border: `1px solid ${theme.palette.primary.outlinedRestingBorder}`,
}));

export const StyledCardAlertRoot = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  width: '100%',
  background: '#FFEED3',
  borderRadius: '7px',
  padding: '16px',
}));

export const StyledCardTripRoot = styled((props: CardProps) => (
  <Card {...props} />
))(({ theme }) => ({
  width: '100%',
  border: '1px solid #E0E0E0',
  boxShadow: '0px 1px 12px rgba(78, 101, 120, 0.15)',
  borderRadius: '16px',
}));

export const StyledCardHeader = styled((props: CardHeaderProps) => (
  <CardHeader {...props} />
))(({ theme }) => ({
  width: '100%',
  borderRadius: '14px',
  padding: '6px',
  border: `1px solid ${theme.palette.primary.outlinedRestingBorder}`,
  '& .MuiCardHeader-title': {
    color: theme.palette.primary.main,
    ...theme.typography.buttonLarge,
  },
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiCardHeader-avatar, & .MuiCardHeader-content': {
    flex: 'unset',
  },
}));

export const StyledSectionHeader = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  fontWeight: '700',
  fontSize: '20px',
  color: 'rgba(4, 0, 34, 0.75)',
}));

export const StyledSectionETA = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  fontWeight: '400',
  fontSize: '12px',
  color: theme.palette.text.secondary,
}));

export const StyledSectionKPIValue = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  fontWeight: '600',
  fontSize: '14px',
  color: theme.palette.text.primary,
}));
export const StyledSectionLoadIds = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  fontWeight: '700',
  fontSize: '14px',
  color: theme.palette.primary.main,
}));

export const StyledSectionDelay = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  width: '100%',
  background:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 17.07%), #FFE9E0',
  borderRadius: '0px 0px 8px 8px',
}));

export const StyledSectionDelayTitle = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  fontWeight: '500',
  fontSize: '14px',
  color: theme.palette.text.primary,
}));

export const StyledGAPDriveTimeValue = styled(
  (props: TypographyProps & { isCurrent?: boolean }) => (
    <Typography {...props} />
  )
)(({ theme, isCurrent = true }) => ({
  fontWeight: '600',
  fontSize: '14px',
  textAlign: 'center',
  color: theme.palette.secondary.main,
}));

export const StyledSectionItem = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  paddingTop: '16px',
  paddingLeft: '16px',
  width: '100%',
}));

export const classNamesTimeline: { [property: string]: string } = {
  StyledFloatingReSequenceStopIconRoot: 'StyledFloatingReSequenceStopIconRoot',
};

export const StyleFloatingReSequenceStopIconRoot = styled((props: BoxProps) => (
  <Box {...props} />
))(() => ({
  marginLeft: '-18px',
}));

export const warningMessageStyle = {
  background: 'rgba(33, 150, 243, 0.08)',
};

export const StyledCardWapper = styled(
  ({
    isReOrderActive,
    ...props
  }: StackProps & { isReOrderActive: boolean }) => (
    <Stack
      {...props}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      pt={1}
      pb={1}
      sx={{
        [`.${classNamesTimeline.StyledFloatingReSequenceStopIconRoot}`]: {
          display: isReOrderActive ? 'block' : 'none',
        },
        '&:hover': {
          [`.${classNamesTimeline.StyledFloatingReSequenceStopIconRoot}`]: {
            display: isReOrderActive ? 'block' : 'none',
          },
        },
      }}
    />
  )
)(() => ({}));
