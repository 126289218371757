/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AssetDeletionVerify,
  AssetsCount,
  ByIdQueryParams,
  ChangeTractorStatusRequest,
  CreateTractorAlertQueryParamsRequest,
  DefaultTractorsTypesDictionarySummaryDTO,
  DefaultTractorsTypesList,
  DriverGroupSearchListParams,
  DriverGroupSearchResponse,
  DriverMaintenanceWarningParams,
  DriverTractorAssignRequest,
  DriverTractorAssignResponse,
  DriverWarningMaintenanceResponse,
  DriverWarningParams,
  DriverWarningResponse,
  EntitySearchListParams,
  EntitySearchResponse,
  MarkTractorAsInactiveRequest,
  PaginatedTractorsList,
  PaginatedTractorsListRequest,
  QueryParams,
  StatesListResponse,
  TractorAlertByIdQueryParams,
  TractorAlertQueryParamsRequest,
  TractorAlertSummaryDTO,
  TractorCreateQueryParams,
  TractorResponse,
  TractorStaticResponse,
  UpdateTractorAlertListRequest,
  VerifyTractorByIdQueryParams,
} from '../../models';

import { plainToInstance } from 'class-transformer';
import axiosInstance, {
  ComposedError,
  httpClient,
} from '../../axios/axiosInstance';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import {
  ITractorService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';
import {
  changeTractorStatusByIdStatusURL,
  createTractorAlertURL,
  createTractorURL,
  deleteTractorAlertByIdURL,
  deleteTractorByIdURL,
  driverTractorAssignURL,
  driverTractorUnAssignURL,
  exportToExcelURL,
  getAssetsCountURL,
  getDefaultTractorsTypesDictionaryURL,
  getDefaultTractorsTypesListURL,
  getDriverGroupSearchURL,
  getExpenseStaticDataURL,
  getPaginatedTractorsListURL,
  getStatesURL,
  getTractorAlertListURL,
  getTractorByIdURL,
  getTractorStaticDataURL,
  licensePlateURL,
  licenseTrailerPlateURL,
  markTractorAsInactiveURL,
  tractorLightListByNameURL,
  tractorNameSearchExtendedURL,
  tractorNameSearchURL,
  trailerLightListByNameURL,
  trailerNameSearchURL,
  updateTractorAlertsBulkURL,
  updateTractorURL,
  validateMaintenanceWarnings,
  validateWarnings,
  verifyAssetDeletionURL,
  vinSearchURL,
  vinTrailerSearchURL,
} from './requestConstants';

export class TractorService extends ITractorService {
  @annotateNameAsync
  async getAssetsCount() {
    const requestData = new QueryParams();
    try {
      return await httpClient.get(
        getAssetsCountURL,
        requestData,
        AssetsCount,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTractorStaticData() {
    const requestData = new QueryParams();
    try {
      return await httpClient.get(
        getTractorStaticDataURL,
        requestData,
        TractorStaticResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getExpenseStaticData() {
    const requestData = new QueryParams();
    try {
      return await httpClient.get(
        getExpenseStaticDataURL,
        requestData,
        TractorStaticResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverGroups(
    nameFilter: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    activeForOperation?: boolean,
    typeFilter?: string
  ) {
    const requestData = new DriverGroupSearchListParams(nameFilter);
    requestData.pageNumber = pageNumber;
    requestData.activeForOperation = activeForOperation;
    requestData.typeFilter = typeFilter;
    if (terminalIds) requestData.terminalIds = terminalIds;
    try {
      return await httpClient.get(
        getDriverGroupSearchURL,
        requestData,
        DriverGroupSearchResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getDriverLoadWarnings(dto: any) {
    const requestData = new DriverWarningParams(dto);
    try {
      return await httpClient.put(
        validateWarnings,
        undefined,
        requestData,
        DriverWarningResponse
      );
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }
  async getDriverMaintenanceWarnings(dto: any) {
    const requestData = new DriverMaintenanceWarningParams(dto);
    try {
      return await httpClient.post(
        validateMaintenanceWarnings,
        undefined,
        requestData,
        DriverWarningMaintenanceResponse
      );
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  async getTractorEntitySearch(
    url: string,
    searchText: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    excludeStatusList: string[] = [],
    pageSize = 25
  ) {
    const requestData = new EntitySearchListParams(searchText);
    requestData.pageNumber = pageNumber;
    requestData.pageSize = pageSize;
    requestData.excludeStatusList = excludeStatusList;
    if (terminalIds) requestData.terminalIds = terminalIds;
    try {
      return await httpClient.get(
        url,
        requestData,
        EntitySearchResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  getVinList(keyword: string | undefined, pageNumber: number) {
    return this.getTractorEntitySearch(vinSearchURL, keyword, pageNumber);
  }

  getTractorByNameSearchList(
    keyword: string | undefined,
    pageNumber: number,
    excludeStatusList: string[]
  ) {
    return this.getTractorEntitySearch(
      tractorNameSearchURL,
      keyword,
      pageNumber,
      [],
      excludeStatusList
    );
  }

  async getTractorByNameSearchExtendedList(
    keyword: string | undefined,
    pageNumber: number,
    statusList: string[],
    terminalIds?: string[]
  ) {
    const requestData = new QueryParams() as any;
    requestData.pageNumber = pageNumber;
    requestData.pageSize = 25;
    requestData.search = keyword;
    requestData.statusList = statusList;
    if (terminalIds) requestData.terminalIds = terminalIds;
    try {
      return await httpClient.get(
        tractorNameSearchExtendedURL,
        requestData,
        EntitySearchResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  getTractorLightListByNameSearch(
    keyword: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    excludeStatusList: string[] = [],
    pageSize = 25
  ) {
    return this.getTractorEntitySearch(
      tractorLightListByNameURL,
      keyword,
      pageNumber,
      terminalIds,
      excludeStatusList,
      pageSize
    );
  }

  getLicensePlateList(keyword: string | undefined, pageNumber: number) {
    return this.getTractorEntitySearch(licensePlateURL, keyword, pageNumber);
  }

  getTrailerByNameSearchList(
    keyword: string | undefined,
    pageNumber: number,
    excludeStatusList: string[] = []
  ) {
    return this.getTractorEntitySearch(
      trailerNameSearchURL,
      keyword,
      pageNumber,
      [],
      excludeStatusList
    );
  }

  getTrailerLightListByNameSearch(
    keyword: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    excludeStatusList?: string[]
  ) {
    return this.getTractorEntitySearch(
      trailerLightListByNameURL,
      keyword,
      pageNumber,
      terminalIds,
      excludeStatusList
    );
  }

  getTrailerVinList(keyword: string | undefined, pageNumber: number) {
    return this.getTractorEntitySearch(
      vinTrailerSearchURL,
      keyword,
      pageNumber
    );
  }

  getTrailerLicensePlateList(keyword: string | undefined, pageNumber: number) {
    return this.getTractorEntitySearch(
      licenseTrailerPlateURL,
      keyword,
      pageNumber
    );
  }

  @annotateNameAsync
  async getStates() {
    const requestData = new QueryParams();
    try {
      return await httpClient.get(
        getStatesURL,
        requestData,
        StatesListResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async changeTractorStatus(requestData: ChangeTractorStatusRequest) {
    try {
      const response = await httpClient.patchRaw(
        changeTractorStatusByIdStatusURL,
        undefined,
        [requestData]
      );
      const data = await response.data;
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorStatusChange',
      });
      const tractor = plainToInstance(TractorResponse, data);
      return Promise.resolve(tractor);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorStatusChange',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPaginatedTractorsList(requestData: PaginatedTractorsListRequest) {
    try {
      const result = await httpClient.getRaw(
        getPaginatedTractorsListURL,
        requestData,
        false,
        true
      );

      result.data.content = result.data.content ?? [];
      return plainToInstance(PaginatedTractorsList, result.data);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPaginatedTractorsListExport(
    requestData: PaginatedTractorsListRequest
  ) {
    try {
      const tractors = await httpClient.get<PaginatedTractorsList>(
        exportToExcelURL,
        requestData,
        PaginatedTractorsList,
        undefined,
        true
      );
      return tractors;
    } catch (error) {
      if (!(error instanceof ComposedError)) {
        throw this.getServiceError(error as Error);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getTractorById(id: string) {
    const queryParams = new ByIdQueryParams(id);

    try {
      const response = await axiosInstance.get(getTractorByIdURL, {
        params: queryParams.getParamsFromRequest(),
      });

      const data = await response.data;
      const tractor = plainToInstance(TractorResponse, data);
      return Promise.resolve(tractor);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createTractor(newTractor: any) {
    const queryParams = new TractorCreateQueryParams(newTractor);
    try {
      // return;

      const response = await httpClient.postRaw(
        createTractorURL,
        undefined,
        { ...queryParams },
        true
      );
      const data = await response.data;
      const tractor = plainToInstance(TractorResponse, data);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorCreation',
      });
      return Promise.resolve(tractor);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorCreation',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateTractor(newTractor: any) {
    const queryParams = new TractorCreateQueryParams(newTractor);
    try {
      const response = await httpClient.patchRaw(updateTractorURL, undefined, {
        undefined,
        ...queryParams,
      });

      const data = await response.data;
      const tractor = plainToInstance(TractorResponse, data);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorUpdate',
      });
      return Promise.resolve(tractor);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorUpdate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async verifyTractorDeletion(id: string) {
    const queryParams = new VerifyTractorByIdQueryParams(id);
    try {
      return await httpClient.get(
        verifyAssetDeletionURL,
        queryParams,
        AssetDeletionVerify,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteTractorById(id: string) {
    const payload = new ByIdQueryParams(id);
    try {
      const response = await httpClient.deleteRaw(
        deleteTractorByIdURL,
        payload,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorDelete',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorDelete',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async markAsInactive(id: string) {
    const payload = new MarkTractorAsInactiveRequest();
    payload.tractorId = id;
    try {
      const response = await httpClient.patchRaw(
        markTractorAsInactiveURL,
        payload,
        null
      );
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        if (error.getStatusCode() === 417) {
          return {
            message: error.message,
            hasErrors: true,
          };
        }
        throw this.getServiceError(composedError);
      }
    }
  }

  //#region Tractor Alerts

  @annotateNameAsync
  async getTractorAlertList(requestData: TractorAlertQueryParamsRequest) {
    try {
      const response = await httpClient.get<TractorAlertSummaryDTO[]>(
        getTractorAlertListURL,
        requestData,
        TractorAlertSummaryDTO,
        false,
        true
      );
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createTractorAlert(newAlert: CreateTractorAlertQueryParamsRequest) {
    try {
      const response = await httpClient.post<TractorAlertSummaryDTO>(
        createTractorAlertURL,
        undefined,
        newAlert,
        TractorAlertSummaryDTO
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorAlertCreate',
      });
      return Promise.resolve(response);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorAlertCreate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateTractorAlertsBulk(newAlertList: UpdateTractorAlertListRequest[]) {
    try {
      const response = await httpClient.put<TractorAlertSummaryDTO[]>(
        updateTractorAlertsBulkURL,
        undefined,
        newAlertList,
        TractorAlertSummaryDTO
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorAlertUpdate',
      });
      return Promise.resolve(response);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorAlertUpdate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDefaultTractorsTypesList(requestData: QueryParams) {
    try {
      const response = await httpClient.get<DefaultTractorsTypesList>(
        getDefaultTractorsTypesListURL,
        requestData,
        DefaultTractorsTypesList,
        false,
        true
      );
      return response.tractorMaintenances;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDefaultTractorsTypesDictionary(requestData: QueryParams) {
    try {
      return await httpClient.get<DefaultTractorsTypesDictionarySummaryDTO[]>(
        getDefaultTractorsTypesDictionaryURL,
        requestData,
        DefaultTractorsTypesDictionarySummaryDTO,
        false,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteTractorAlertById(id: string) {
    const payload = new TractorAlertByIdQueryParams(id);
    try {
      const response = await httpClient.deleteRaw(
        deleteTractorAlertByIdURL,
        payload,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorAlertDelete',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorAlertDelete',
      });
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async driverTractorAssign(
    requestData: DriverTractorAssignRequest
  ): Promise<DriverTractorAssignResponse | ServiceError> {
    try {
      const result = await httpClient.post<DriverTractorAssignResponse>(
        driverTractorAssignURL,
        undefined,
        requestData,
        DriverTractorAssignResponse
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorDriverAssign',
      });
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorDriverAssign',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async driverTractorUnAssign(
    requestData: DriverTractorAssignRequest
  ): Promise<DriverTractorAssignResponse | ServiceError> {
    try {
      const result = await httpClient.post<DriverTractorAssignResponse>(
        driverTractorUnAssignURL,
        undefined,
        requestData,
        DriverTractorAssignResponse
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorDriverUnAssign',
      });
      return result;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorDriverUnAssign',
      });
      return this.getServiceError(composedError);
    }
  }

  //#endregion
}
