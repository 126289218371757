import { createTheme, PaletteMode, Theme } from '@mui/material';
import {
  MuiAutocomplete,
  MuiButton,
  MuiButtonBase,
  MuiCheckbox,
  MuiChip,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
  MuiInput,
  MuiTextField,
} from './components';
import { darkThemeObject } from './darkTheme';
import './fonts.css';
import { lightThemeObject } from './lightTheme';

const themeObject: { [mode in PaletteMode]: Theme } = {
  light: lightThemeObject,
  dark: darkThemeObject,
};

function getThemeObjectByMode(mode: PaletteMode) {
  const modeThemeObject = themeObject[mode];
  return createTheme({
    palette: {
      ...modeThemeObject.palette,
      mode,
    },
    typography: {
      ...modeThemeObject.typography,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        mobile: 0,
        tablet: 768,
        desktop: 1024,
      },
    },
    components: {
      //node_modules/@mui/material/styles/components.d.ts
      // node_modules/@mui/material/styles/overrides.d.ts
      ...MuiButton,
      ...MuiButtonBase,
      ...MuiChip,
      ...MuiFormLabel,
      ...MuiTextField,
      ...MuiFormHelperText,
      ...MuiInput,
      ...MuiAutocomplete,
      ...MuiCheckbox,
      ...MuiFormControlLabel,
    },
  });
}

export { getThemeObjectByMode };
