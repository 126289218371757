import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Theme,
  ThemeProvider,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { getThemeDispatchFilters } from '../../../dispatch2/components/gantt/Filters/styles';

import {
  RecommendedTripSortFilters,
  getRecommendedTripStatusesFilters,
} from '../../constants/recommendedTrips.filters';
import {
  GlobalEvalButtonStyles,
  PreferenceIconBtnStyles,
  RecommendTripFilterContainerStyles,
  RoundedBtnStyles,
} from '../../styles';
import { VerticalDivider } from '../generic/VerticalDivider';
import ReOptymize from './ReOptymizeButton';
import { IAssignmentMetrics } from '../../types/recommendTrips.types';
import { useStores } from '../../../../store/root.context';
import StyledFilters from '../../../../common/StyledComponents/StyledFilters';
import { SettingsOutlined } from '@mui/icons-material';
import LightbulbOutlined from '@mui/icons-material/LightbulbOutlined';

interface IRecommendedTripFilters {
  filterLabels?: IAssignmentMetrics;
  layoutStyles?: object;
  onReOptymize?: () => void;
  isReOptymizationRunning?: boolean;
  disableReoptimization?: boolean;
}

const RecommendTripFilters: React.FC<IRecommendedTripFilters> = ({
  layoutStyles,
  onReOptymize,
  isReOptymizationRunning = false,
}) => {
  const {
    bulkOptymizationStore: {
      recommendationFilters,
      onRecommendationFilterChange,
      driverGanttStatusesFilters,
      setDriverGanttStatusesFilters,
      assignmentMetrics,
      enableReoptimization,
    },
    evaluationStore: {
      setEvaluationDetails,
      setGlobalEvaluationType,
      globalEvaluationResult,
    },
    dispatch2GlobalStore: { updatePreferenceSettings },
  } = useStores();
  const handleFilterChange = (filters: { label?: string; value?: string }[]) =>
    onRecommendationFilterChange(filters, 'statuses');

  useEffect(() => {
    if (assignmentMetrics) setDriverGanttStatusesFilters(assignmentMetrics);
  }, [assignmentMetrics]);

  const handleGlobalOptimize = (event: React.MouseEvent<HTMLButtonElement>) => {
    const rect = (event?.target as HTMLInputElement)?.getBoundingClientRect?.();
    const y = event.clientY - rect.top;
    setGlobalEvaluationType(true);
    setEvaluationDetails({
      source: 'OPTIMIZATION',
      targetPosition: {
        left: event.clientX,
        top: event.clientY + (rect?.height - y) + 5,
      },
      evaluationResult: globalEvaluationResult!,
    });
  };

  const GlobalEvaluateButton = () => {
    if (!globalEvaluationResult?.issuesKPI?.length) return <></>;
    return (
      <IconButton onClick={handleGlobalOptimize} sx={GlobalEvalButtonStyles}>
        <LightbulbOutlined sx={{ color: '#FFF' }} />
      </IconButton>
    );
  };

  return (
    <Stack
      direction={'row'}
      sx={{ ...RecommendTripFilterContainerStyles, ...layoutStyles }}
    >
      <Box display={'flex'}>
        <StyledFilters
          columns={RecommendedTripSortFilters}
          onChangeCb={onRecommendationFilterChange}
          defaultFilterValue={recommendationFilters}
        />
      </Box>

      <VerticalDivider />
      <Grid container>
        <Grid item xs={8}>
          <StyledFilters
            columns={getRecommendedTripStatusesFilters(
              driverGanttStatusesFilters
            )}
            onChangeCb={handleFilterChange}
            defaultFilterValue={recommendationFilters}
          />
        </Grid>
        <Grid item xs={4}>
          <Box ml={'auto'} display={'flex'} justifyContent={'end'} gap={'8px'}>
            <ReOptymize
              onClick={onReOptymize}
              isLoading={isReOptymizationRunning}
              disabled={!enableReoptimization}
            />
            <GlobalEvaluateButton />
            <IconButton
              sx={PreferenceIconBtnStyles}
              onClick={() => updatePreferenceSettings(true)}
            >
              <SettingsOutlined
                sx={{
                  width: '20px',
                  height: '20px',
                  color: '#FFF!important',
                }}
              />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default observer(RecommendTripFilters);
