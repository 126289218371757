import { styled } from '@mui/styles';

export const StyledPopupRoot = styled('div')(({ theme }: { theme: any }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  background: theme.palette.primary.contrast,
  height: '100%',
}));

export const StyledPopupContent = styled('div')(() => ({
  flex: '1 1 auto',
  overflowY: 'auto',
}));
