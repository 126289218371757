export const SMH = 'safetyMaintenanceHistory';
export const terminalPage = 'terminalPage';
export const dashboardPage = 'DashboardPage';
export const dispatchPage = 'dispatchPage';
export const ganttPreferencesModule = 'ganttPreferencesModule';
export const ganttTimelineModule = 'ganttTimelineModule';
export const equipmentPage = 'tractorPage';
export const trailerPage = 'trailerPage';
export const customerPage = 'customerPage';
export const contactFormModule = 'contactFormModule';
export const vendorPage = 'vendorPage';
export const locationPage = 'locationPage';
export const userPage = 'userPage';
export const userPageEmergencyContact = 'userPageEmergencyContact';
export const reportModule = 'reportModule';

// ADD LOAD
export const addLoadPage = 'addLoadPage';
export const addLoadPageCustomerDetails = 'addLoadPageCustomerDetails';
export const addLoadPageStopInfo = 'addLoadPageStopInfo';
export const addLoadPageLoadDetails = 'loadPageLoadDetails';
export const addLoadPageAddCommodity = 'loadPageAddCommodity';
export const addLoadPageInvoiceDetails = 'addLoadPageInvoiceDetails';
export const addLoadPageSaveTemplateForm = 'addLoadPageSaveTemplateForm';
export const addLoadPageAssignToDriverForm = 'addLoadPageAssignToDriverForm';
export const addLoadPageDetailsPanelFooterActions =
  'addLoadPageDetailsPanelFooterActions';

// LOAD TAB PANEL
export const loadPanelOverviewTab = 'loadPanelOverviewTab';
export const loadPanelRoutesTab = 'loadPanelRoutesTab';
export const loadPanelFinanceTab = 'loadPanelFinanceTab';
export const loadPanelNotesTab = 'loadPanelNotesTab';

// Invoice
export const invoiceModule = 'invoiceModule';
export const invoiceModuleSetting = 'invoiceModuleSetting';
export const invoiceModuleFactoringInfo = 'invoiceModuleFactoringInfo';

// Trips
export const tripsModulePanel = 'tripsModulePanel';

// OTHERS
export const dispatchSheetModule = 'dispatchSheetModule';
export const addPayItemsModule = 'addPayItemsModule';
export const addFuelPaymentsModule = 'addPaymentsModule';
export const syncWtihQuickBooks = 'syncWtihQuickBooks';
export const expensesModule = 'expensesModule';

export const EquipmentImportExportOptions = [
  { label: 'Import', key: 'IMPORT' },
  { label: 'Export', key: 'EXPORT' },
];

export const EquipmentImportExport = {
  IMPORT: 'Import',
  EXPORT: 'Export',
};
