export const TemplatesConstants = {
  template: 'Template',
  templates: 'Templates',
  templateName: 'Template Name',
  customer: 'Customer',
  origin: 'Origin',
  destination: 'Destination',
  actions: 'Actions',
  requiredEquipmentType: 'Required Equipment Type',
  invoicingTerminal: 'Invoicing Terminal',

  viewEdit: 'View/Edit',
  createLoad: 'Create Load',
  duplicate: 'Duplicate',
  delete: 'Delete',

  deleteTemplates: 'Delete Template(s)',
  deleteTemplatesSubTitle:
    'Are you sure you want to permanently delete the selected load template(s)?',
  deleteTemplate: 'Delete Template',
  deleteTemplateSubTitle: 'Are you sure you want to delete the load template?',

  addNewTemplate: 'Add New Template',
  templateDetails: 'Template Details',

  editTemplate: 'Edit Template',
};
