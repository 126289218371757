import { Dialog, Theme, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import moment from 'moment';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import StorageManager from '../../../../../StorageManager/StorageManager';
import {
  documentService,
  tractorService,
  trailerService,
} from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import DetailsPanel from '../../../../../common/DetailsPanel';
import LongMenu from '../../../../../common/LongMenu';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../../common/Permission';
import { getCurrentUser } from '../../../../../common/Permission/utils/permissionUtils';
import {
  AssignDocumentRequest,
  CreateDocumentOnlyRequest,
  DeleteDocumentRequest,
  TractorAlertSummaryDTO,
  TrailerAlertSummaryDTO,
} from '../../../../../models';
import {
  IMaintenanceHistory,
  MaintenanceFormData,
  MaintenanceHistory,
} from '../../../../../models/DTOs/maintenance/maintenanceHistory/Model';
import {
  CreateMaintenanceHistoryRequest,
  UpdateMaintenanceHistoryRequest,
} from '../../../../../models/DTOs/maintenance/maintenanceHistory/Requests';
import { RootStoreInstence } from '../../../../../store/root-store/rootStateContext';
import { getDocumentTypeList } from '../../../../../subPages/expenses/utils/utils';
import { DeleteButtonIcon } from '../../../../../ui-kit/components/Assets';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import { EquipmentType } from '../../../constants/constants';
import { MaintenanceHistoryFormContext } from '../../../context/MaintenanceHistoryFormContext';
import {
  assignDocument,
  createMaintenanceHistory,
  deleleMaintenanceHistory,
  updateMaintenanceItems,
  uploadDocument,
} from '../../../services/api.utils';
import { toUtcDate } from '../../../utils';
import AddNewContactPopup from './AddNewContactPopup';
import AddNewVendorPopup from './AddNewVendorPopup';
import MaintenanceDetailForm, {
  MaintenanceDetailFormProps,
} from './MaintenanceDetailForm';
import { parseInitialValueToFormProps, validationSchema } from './utils';

export interface MaintenanceDetailPanelProps
  extends MaintenanceDetailFormProps {
  data: Partial<IMaintenanceHistory>;
  onCloseMaintenance: () => void;
  onCreateSuccess?: (data: MaintenanceHistory) => void;
  onUpdateSuccess?: (data: MaintenanceHistory) => void;
  onDeleteSuccess?: () => void;
  onUpdateWarningsCount?: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
  equipmentId?: string;
  index?: number;
  setValue?: () => void;
  isGlobal?: boolean;
  disableAllFields?: boolean;
  height?: string;
  handleOpenExpensePanel?: (
    expenseId: string | number,
    expenseSeqNumber: string | number,
    isGlobal?: boolean
  ) => void;
}

const MaintenanceDetailPanel: FC<MaintenanceDetailPanelProps> = ({
  data,
  onCloseMaintenance,
  onCreateSuccess,
  onUpdateSuccess,
  onDeleteSuccess,
  lockEquipment,
  disableAutocomplate,
  onUpdateWarningsCount,
  equipmentId,
  index,
  setValue,
  isGlobal,
  disableAllFields,
  height,
  handleOpenExpensePanel,
}) => {
  const userStorage = StorageManager.getUser() || {};
  const context = useContext(MaintenanceHistoryFormContext);
  const formData = useMemo(() => parseInitialValueToFormProps(data), [data]);

  const isEdit = !!data.id;
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [deletePopupErr, setDeletePopupErr] = useState<boolean>(false);
  const theme: Theme = useTheme();

  const { permissions } = getCurrentUser();
  useEffect(() => {
    if (data.equipmentType === 'TRAILER') {
      validationSchema.fields.odometer = null;
    }
  }, []);

  const mapTractorScheduleFormDataRequest = (
    data: MaintenanceFormData
  ): TractorAlertSummaryDTO[] => {
    return Object.entries(data.schedules || {})
      .map<TractorAlertSummaryDTO | undefined>(
        ([maintenanceItemId, { nextDate, nextOdo }]) => {
          const tractorAlert = context.getTractorAlert?.(
            maintenanceItemId,
            data.equipmentItem.value
          );
          if (tractorAlert) {
            return {
              id: tractorAlert.id,
              organizationId: userStorage.organizationId,
              tractorId: tractorAlert.tractorId,
              maintenanceTypeId: tractorAlert.maintenanceTypeId,
              maintenanceType: tractorAlert.maintenanceType,
              lastConducted: toUtcDate(data.completedDate),
              nextDue: toUtcDate(nextDate),
              lastServiceDueOdometer: +(data.odometer as string),
              nextServiceDueOdometer: +nextOdo,
            };
          }
        }
      )
      .filter(Boolean) as TractorAlertSummaryDTO[];
  };

  const mapTrailerScheduleFormDataRequest = (
    data: MaintenanceFormData
  ): TrailerAlertSummaryDTO[] => {
    return Object.entries(data.schedules || {})
      .map<TrailerAlertSummaryDTO | undefined>(
        ([maintenanceItemId, { nextDate }]) => {
          const trailerAlert = context.getTrailerAlert?.(
            maintenanceItemId,
            data.equipmentItem.value
          );
          if (trailerAlert) {
            return {
              id: trailerAlert.id,
              organizationId: userStorage.organizationId,
              trailerId: trailerAlert.trailerId,
              maintenanceTypeId: trailerAlert.maintenanceTypeId,
              maintenanceType: trailerAlert.maintenanceType,
              lastConducted: toUtcDate(data.completedDate),
              nextDue: toUtcDate(nextDate),
            };
          }
        }
      )
      .filter(Boolean) as TrailerAlertSummaryDTO[];
  };

  const updateReceipt = async (
    maintenanceItemRes: MaintenanceHistory,
    data: MaintenanceFormData
  ): Promise<void> => {
    if (maintenanceItemRes) {
      // Remove old file in case update
      const isDelete =
        data.receipt && data.receipt.length
          ? Boolean(data?.receipt[0]?.type)
          : true;
      if (context.initialData?.attachments?.[0] && isDelete) {
        await documentService.deleteDocument(
          new DeleteDocumentRequest({
            documentIds: [context.initialData.attachments[0].documentId],
          })
        );
      }

      // Upload the new one
      if (
        data.receipt &&
        data.receipt.length &&
        Boolean(data?.receipt[0]?.type)
      ) {
        const requestBody = new CreateDocumentOnlyRequest({
          data: data.receipt[0],
          organizationId: userStorage.organizationId,
          ownerId: userStorage.id,
        });

        const fileRes = await uploadDocument(requestBody);
        const documentTypes = await getDocumentTypeList();

        const documentType = documentTypes?.find(
          (item) => item.itemCode === 'RECEIPT'
        );

        if (fileRes) {
          const assignDocumentRequest = new AssignDocumentRequest({
            description: '',
            ownerName: userStorage.firstname + ' ' + userStorage.lastname,
            permission: 'PRIVATE',
            organizationId:
              fileRes.organizationId || userStorage.organizationId,
            documentType: 'RECEIPT',
            documentId: fileRes.id,
            fileName: fileRes.fileName,
            fileSize: fileRes.fileSize,
            uploadDate: fileRes.uploadDate,
            ownerId: fileRes.ownerId || Number.parseInt(userStorage.id),
            documentTypeId: documentType?.id || '',
            attachedEntities: [
              {
                properties: [
                  {
                    id: maintenanceItemRes.id,
                    title: '',
                  },
                ],
                type: 'MAINTENANCE_HISTORY',
              },
            ],
          });
          await assignDocument(assignDocumentRequest);
        }
      }
    }
  };

  const handleDeleteClick = () => {
    if (permissions.includes(AXELE_PERMISSION_TYPE.MAINTENANCE_HISTORY_REMOVE))
      setDeletePopup(true);
  };

  const entity = 'Record';

  const renderCustomTitle = () =>
    isEdit ? (
      <Box sx={{ color: 'primary.contrast' }}>
        <div>
          {`${data.historyMaintenanceItems?.[0]?.itemName}${
            data.historyMaintenanceItems?.length &&
            data.historyMaintenanceItems?.length > 1
              ? ` + ${data.historyMaintenanceItems?.length - 1}`
              : ''
          }`}
        </div>
        <Box component="small" sx={{ display: 'block', mt: -0.5 }}>
          {moment(data.completedDate).format('Do MMM YYYY')}
        </Box>
      </Box>
    ) : (
      <Typography sx={{ color: 'primary.contrast' }}>
        {t('addNewMaintenanceRecord')}
      </Typography>
    );

  const actionsRenderer = () => {
    return (
      <>
        {data.id && !disableAllFields && (
          <Permission
            contains={[AXELE_PERMISSION_TYPE.MAINTENANCE_HISTORY_REMOVE]}
          >
            <LongMenu
              color={'primary.contrast'}
              options={[
                {
                  name: 'Delete Record',
                  action: handleDeleteClick,
                  startIcon: DeleteButtonIcon({}),
                },
              ]}
            />
          </Permission>
        )}
        {!!deletePopup && (
          <DeletePopup
            open
            title={t('deleteMaintenanceHistory')}
            body={
              formData?.expenseId ? (
                <>
                  <div>Are you sure you want to delete this record(s)?</div>
                  <i>{t('deleteMaintenanceWithExpense')}</i>
                </>
              ) : (
                t('deleteMaintenanceHistoryConfirmation')
              )
            }
            onAction={async () => {
              const response = await deleleMaintenanceHistory(
                [data as MaintenanceHistory],
                userStorage.organizationId
              );
              if (
                response &&
                response?.error?.response?.data?.apierror?.message ==
                  'CLOSED_SETTLEMENT_EXISTS_FOR_LINKED_EXPENSE'
              ) {
                setDeletePopupErr(true);
                setDeletePopup(false);
              }
              if (response === undefined) {
                onDeleteSuccess?.();
              }
            }}
            onClose={() => setDeletePopup(false)}
          />
        )}
        <DeletePopup
          open={deletePopupErr}
          backgroundColor={theme.palette.primary.main}
          width={'440px'}
          title={''}
          body={t('closedSettelementforlinkedExpense')}
          cancelText={'OK'}
          onClose={() => {
            setDeletePopupErr(false);
          }}
        />
      </>
    );
  };

  const updateAlertAfterMarkDone = (
    responseData:
      | TractorAlertSummaryDTO[]
      | TrailerAlertSummaryDTO[]
      | ServiceError
      | null
  ) => {
    const currentDate = Date.now();
    const formattedCurrentDate = new Date(currentDate);
    const nextDueDate = new Date(responseData[0].nextDue);
    setValue?.(`alertsList.${index}`, responseData[0]);
    if (
      typeof onUpdateWarningsCount === 'function' &&
      nextDueDate > formattedCurrentDate
    ) {
      onUpdateWarningsCount(equipmentId as string, 0, -1, true);
    }
  };

  return (
    <>
      <Box sx={{ height: height ? height : '100%' }}>
        <DetailsPanel
          isGlobal={isGlobal}
          actionsRenderer={actionsRenderer}
          showTitle
          actionButtonLabel={data.id ? 'Save changes' : t('addNewRecord')}
          data={formData}
          entity={entity}
          renderCustomTitle={renderCustomTitle}
          onClose={onCloseMaintenance}
          onUpdate={async (data: MaintenanceFormData) => {
            data?.expenseId &&
              data?.expenseSeqNumber &&
              RootStoreInstence.setNotificationType({
                type: 'SUCCESS',
                message: `The linked expense ${data?.expenseSeqNumber} will also be updated`,
              });
            const maintenanceItemRes = await updateMaintenanceItems(
              new UpdateMaintenanceHistoryRequest(
                context.initialData.id as string,
                data.expense === undefined ? { ...data, expense: '0' } : data
              )
            );
            if (maintenanceItemRes) {
              await updateReceipt(maintenanceItemRes, data);
              onUpdateSuccess?.(maintenanceItemRes);
            }
          }}
          onCreate={async (data: MaintenanceFormData) => {
            const isTractor = data.equipmentType === EquipmentType.Tractor;
            const maintenanceItemRes = await createMaintenanceHistory(
              new CreateMaintenanceHistoryRequest(data)
            );
            if (!maintenanceItemRes) alert('Create failed');
            else {
              if (data.schedule && data.schedules) {
                let response;
                if (isTractor) {
                  const requestData = mapTractorScheduleFormDataRequest(data);
                  response = await tractorService.updateTractorAlertsBulk(
                    requestData
                  );
                  if (response) {
                    updateAlertAfterMarkDone(response);
                  }
                } else {
                  const payload = mapTrailerScheduleFormDataRequest(data);
                  response = await trailerService.updateTrailerAlertsBulk(
                    payload
                  );
                  if (response) {
                    updateAlertAfterMarkDone(response);
                  }
                }
                if (response instanceof ServiceError) {
                  alert('Create schedule failed');
                }
              }
              await updateReceipt(maintenanceItemRes, data);
            }
            onCreateSuccess?.(maintenanceItemRes as MaintenanceHistory);
          }}
          contentRenderer={() => (
            <MaintenanceDetailForm
              lockEquipment={lockEquipment}
              disableAutocomplate={disableAutocomplate}
              disableAllFields={disableAllFields}
              handleOpenExpensePanel={handleOpenExpensePanel}
            />
          )}
          validationSchema={validationSchema}
        />
        {context.openAddNewVendor && (
          <Dialog
            PaperProps={{
              sx: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
              },
            }}
            open={true}
          >
            <Box
              sx={{
                width: 512,
                height: 844,
              }}
            >
              <AddNewVendorPopup
                onClose={() => {
                  context.setOpenAddNewVendor?.(false);
                }}
              />
            </Box>
          </Dialog>
        )}
        {context.openAddNewContact && (
          <Dialog
            PaperProps={{
              sx: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
              },
            }}
            open={true}
          >
            <Box
              sx={{
                width: 512,
                minHeight: 512,
              }}
            >
              <AddNewContactPopup
                onClose={() => context.setOpenAddNewContact?.(false)}
              />
            </Box>
          </Dialog>
        )}
      </Box>
    </>
  );
};

export default MaintenanceDetailPanel;
