export const styles = {
  dialog: {
    borderRadius: 20,
    padding: 30,
    backgroundColor: '#fff',
    width: 700,
  },
  addButton: {
    borderRadius: 2,
    borderWidth: 1,
    width: '42px',
    borderColor: 'primary.main',
  },
  actionButtonWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: 1,
  },
  titleText: {
    color: 'text.primary',
    fontSize: 16,
    fontWeight: '700',
  },
  subText: {
    color: 'text.primary',
    fontSize: 14,
  },
  totalText: {
    color: 'primary.main',
    fontSize: 20,
    fontWeight: '700',
  },
  totalSubText: {
    color: 'text.secondary',
    fontSize: 12,
  },
  trackingContainer: {
    mt: 2,
    // ml: 2,
    // mr: 2,
    // mb: 10,
  },
  urlText: {
    color: 'primary.main',
    fontSize: 16,
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
  },
  urlSub: {
    color: 'text.secondary',
    fontSize: 12,
  },
  visiblityText: {
    color: 'text.secondary',
    fontSize: 12,
  },
  visibilitySub: {
    color: 'text.primary',
    fontSize: 16,
  },
  editMain: {
    borderColor: 'primary.main',
    borderWidth: 1,
    borderRadius: 2,
    marginTop: 2,
  },
  ediCommunicationWrapper: {
    background: 'rgba(43, 100, 203, 0.08)',
    borderRadius: '8px',
  },
  labelStyles: {
    fontWeight: '500',
    fontSize: '18px',
    letterSpacing: '0.15px',
    color: 'rgba(4, 0, 34, 0.75)',
  },
  notifiationLabel: {
    pl: '10px',
    color: 'rgba(0, 17, 34, 0.6)',
    letterSpacing: '0.15px',
    fontSize: '14px',
  },
};
