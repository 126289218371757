import moment from 'moment';
import * as yup from 'yup';
import { getValidationDateTimeTimeOffDialog } from '.';
import { nullableDateYup } from '../../../utils';

const RequiredLocationValidationSchema = yup
  .object()
  .shape({
    address: yup.string().required('Address is required.'),
    city: yup.string().required('City is required.'),
    state: yup.string().required('State is required.'),
  })
  .required('The location is required.');

const requiredDateValidationSchema = yup
  .date()
  .required()
  .typeError('Please enter correct date in DD/MM/YYYY format');

const performTest = ({ context }) => {
  const result = getValidationDateTimeTimeOffDialog(context.from[0].value);
  if (result?.[context.path]?.message) {
    return context.createError({
      message: result?.[context.path]?.message,
      path: context.path,
    });
  }
  return context.schema;
};

export const CreateOrEditTimeOffValidationSchema = yup
  .object()
  .shape({
    location: RequiredLocationValidationSchema,
    startDatePart: requiredDateValidationSchema.test(
      ['startDatePart'],
      (startDatePart, context) => {
        return performTest({ context });
      }
    ),
    startTimePart: yup
      .string()
      .test(['startTimePart'], (startTimePart, context) => {
        return performTest({ context });
      }),
    endDatePart: requiredDateValidationSchema.test(
      ['endDatePart'],
      (endDatePart, context) => {
        return performTest({ context });
      }
    ),
    endTimePart: yup.string().test(['endTimePart'], (endTimePart, context) => {
      return performTest({ context });
    }),
  })
  .required();

export const MarkProgressTimeoffFormValidationSchema = yup
  .object()
  .shape(
    {
      actualTimeOfArrival: nullableDateYup().when(
        [
          'actualTimeOfArrival',
          'actualTimeOfCompletion',
          'actualTimeOfArrivalChecked',
        ],
        (
          actualTimeOfArrival,
          actualTimeOfCompletion,
          actualTimeOfArrivalChecked
        ) => {
          if (actualTimeOfArrival && actualTimeOfCompletion) {
            return nullableDateYup().max(
              moment(actualTimeOfCompletion).add(-1, 'minutes'),
              'Start Date must be before the Completion Date'
            );
          }
          if (actualTimeOfArrivalChecked) {
            return nullableDateYup().required('Start Date is required.');
          }
        }
      ),
      actualTimeOfCompletion: nullableDateYup().when(
        [
          'actualTimeOfArrival',
          'actualTimeOfCompletion',
          'actualTimeOfCompletionChecked',
        ],
        (
          actualTimeOfArrival,
          actualTimeOfCompletion,
          actualTimeOfCompletionChecked
        ) => {
          if (actualTimeOfArrival && actualTimeOfCompletion) {
            return nullableDateYup().min(
              moment(actualTimeOfArrival).add(1, 'minutes'),
              'Completion Date must be after  Start Date'
            );
          }

          if (actualTimeOfCompletionChecked) {
            return nullableDateYup().required('Completion Date is required.');
          }
        }
      ),
      actualTimeOfArrivalChecked: yup
        .boolean()
        .when('actualTimeOfCompletionChecked', {
          is: true,
          then: yup.boolean().oneOf([true], 'Start Date is not logged.'),
        }),
      actualTimeOfCompletionChecked: yup.boolean(),
    },
    [
      ['actualTimeOfArrival', 'actualTimeOfArrivalChecked'],
      ['actualTimeOfArrival', 'actualTimeOfArrival'],
      ['actualTimeOfArrival', 'actualTimeOfCompletion'],
      ['actualTimeOfCompletion', 'actualTimeOfCompletionChecked'],
      ['actualTimeOfCompletion', 'actualTimeOfArrival'],
      ['actualTimeOfCompletion', 'actualTimeOfCompletion'],
    ]
  )
  .required();
