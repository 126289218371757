import { Type } from 'class-transformer';
import { TerminalShort } from '../../common/modelsShort';
import {
  AssetStatus,
  SortResponse,
  DriverGroupBaseDetails,
  Terminal,
} from '../commonMixed';

export class DriverTractorAssignResponse {}
export class TractorSummaryDTO {
  id!: number;
  activeLoad!: { loadId: string; seqNumber: string } | null;
  organizationId!: number;
  licensePlateNumber!: string;
  ownershipType!: string;
  vin!: string;
  tractorName!: string;
  ownedCompany!: string;
  status!: AssetStatus;
  @Type(() => DriverGroupBaseDetails)
  groupDetails!: DriverGroupBaseDetails;
  @Type(() => Terminal)
  terminal!: Terminal;
  tollProvider!: string;
  tollTagNumber!: string;
  warningSafetyIssues!: number;
  criticalSafetyIssues!: number;
  totalWarningIssueCount!: number;
  totalCriticalIssueCount!: number;
  safetyIssueTypeSet!: string[];
  ownerEmail!: string;
  automateSettlementCalculation!: boolean;
  licenseValidTill!: string;
}

export class PaginatedTractorsList {
  @Type(() => TractorSummaryDTO)
  content!: TractorSummaryDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
  totalCriticalIssueCount!: number;
  totalWarningIssueCount!: number;
}

export class TractorResponse {
  terminal?: TerminalShort | null;
  terminalId?: string | null;
}
export class EntitySearchResponse {}
export class TractorStaticResponse {}
export class DriverGroupSearchResponse {}
export class DriverWarningResponse {}
export class DriverWarningMaintenanceResponse {}
export class AssetDeletionVerify {
  assetIsAllowedToDelete: boolean;
  constructor() {
    this.assetIsAllowedToDelete = false;
  }
}
export class AssetsCount {
  tractorCount: number | undefined;
  trailerCount: number | undefined;

  increaseTractor() {
    if (this.tractorCount !== undefined) {
      this.tractorCount += 1;
    }
  }

  decreaseTractor() {
    if (this.tractorCount !== undefined) {
      this.tractorCount -= 1;
    }
  }

  increaseTrailer() {
    if (this.tractorCount !== undefined) {
      this.trailerCount += 1;
    }
  }

  decreaseTrailer() {
    if (this.tractorCount !== undefined) {
      this.trailerCount -= 1;
    }
  }
}

export class TerminalListResponse {}
export class StatesListResponse {}

export class TractorAlertSummaryDTO {
  id!: string;
  organizationId!: number;
  tractorId!: number;
  maintenanceTypeId!: string;
  maintenanceType!: string;
  lastConducted!: Date;
  nextDue!: Date;
  lastServiceDueOdometer!: number;
  nextServiceDueOdometer!: number;
  itemName!: string;
  safetyIssueTypeWithDaysAndMiles?: {
    days: number;
    issueType: string;
    miles: number;
  };
}

export class DefaultTractorsTypesList {
  id!: string;
  organizationId!: string;
  @Type(() => DefaultTractorsTypesListSummaryDTO)
  tractorMaintenances!: DefaultTractorsTypesListSummaryDTO[];
}
export class DefaultTractorsTypesListSummaryDTO {
  id!: string;
  maintenanceTypeId!: string;
  maintenanceType!: string;
  warningDuration!: string;
  frequency!: string;
  frequencyValue!: number;
  recipient!: string;
  dispatchAllowedIfCertificationExpires!: boolean;
  warningDurationByDays!: number;
  alertByMiles!: number;
  frequencyByMiles!: number;
  iconPicker!: string;
}
export class DefaultTractorsTypesDictionarySummaryDTO {
  id!: string;
  itemName!: string;
  isDeduction!: boolean;
  itemCode!: string;
  description!: string;
  isCustom!: boolean;
  iconName!: string;
  createdBy!: string;
  createDate!: Date;
}
export class DefaultTractorsTypesSummaryDTO {
  id!: string;
  maintenanceTypeId!: string;
  maintenanceType!: string;
  itemName!: string;
}
