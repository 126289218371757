import { Box } from '@mui/system';
import { useState } from 'react';
import { useRoutesLoad } from '../../../../../../contexts/LoadRoutesContext';
import { Trip } from '../../Models';
import RoutesController from '../../RoutesController';
import AssignmentFormWrapper from '../AssignmentFormWrapper';
import PaymentAmountAssignComponent from '../DriverAssignment/DriverAmountComponent';
import WarningWindow from '../DriverAssignment/WarningWindow';
import FormComponent from './TractorAssignmentForm';
import { useTranslation } from 'react-i18next';

export default function TractorAssignmentToLoad({
  trip,
  trip: { id },
  onClose,
  onAssign,
  apiAssignHandler,
}: {
  trip: Trip;
  onClose: () => void;
  onAssign: (tractor) => void;
  apiAssignHandler: (data: any) => Promise<void>;
}): JSX.Element {
  const { setDriverAmountForAssign, driverAmountForAssign } = useRoutesLoad();
  const [data] = useState({ tractor: null });
  const [warnings, setWarnings] = useState<string[] | undefined>();
  const [selectedTractor, setSelectedTractor] = useState<[] | null>(null);

  const handleTractorInfoReady = (tractor) => {
    if (tractor.paymentDetails) {
      setSelectedTractor({
        ...tractor,
        name: 'Tractor',
        id: tractor?.paymentDetails?.tractorId,
      });
    }
  };

  const handleTractorSelect = async (tractor) => {
    setDriverAmountForAssign?.(null);
    const warningsList = await RoutesController.getTractorWarningsForLoadAssign(
      tractor.id,
      id,
      trip.driverGroup?.id
    );
    setWarnings(warningsList);
  };

  const handleAssign = async (data) => {
    if (data.tractor?.id) {
      if (apiAssignHandler) {
        await apiAssignHandler(data);
      } else {
        const response = await RoutesController.assignTractorToLoad(
          data.tractor?.id,
          trip.id,
          driverAmountForAssign
        );
        if (!response) return;
      }
      onAssign(data.tractor);
      setDriverAmountForAssign?.(null);
    }
    onClose();
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <AssignmentFormWrapper
        onAction={handleAssign}
        onClose={onClose}
        data={data}
        title={t('TractorAssignmentToLoadAssignTractor')}
        actionLabel={t('TractorAssignmentToLoadAssign')}
      >
        <>
          <Box sx={{ marginBottom: '16px' }}>
            <WarningWindow warningList={warnings} />
          </Box>
          <FormComponent
            trip={trip}
            onTractorSelect={handleTractorSelect}
            onTractorInfoReady={handleTractorInfoReady}
          />
          {selectedTractor && (
            <PaymentAmountAssignComponent items={[selectedTractor]} />
          )}
        </>
      </AssignmentFormWrapper>
    );
  }
}
