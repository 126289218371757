import { Grid, SxProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import {
  IRelativeDateRangeOption,
  RelativeDateRange,
  RelativeDateRangeProps,
} from '../../../ui-kit/components/RelativeDateRange';

export interface RelativeDateRangeFormProps extends RelativeDateRangeProps {
  control?: Control;
  sizes?: {
    xs?: number;
  };
  wrapperStyles?: SxProps;
  defaultValue?: IRelativeDateRangeOption;
}
export const RelativeDateRangeForm = ({
  control,
  name,
  label,
  variant,
  sizes = {},
  wrapperStyles = {},
  defaultValue,
  ...restProps
}: RelativeDateRangeFormProps) => {
  return (
    <Grid xs={sizes.xs} item sx={{ marginBottom: '20px', ...wrapperStyles }}>
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <RelativeDateRange
              name={name}
              label={label}
              variant={variant}
              onChange={(option: IRelativeDateRangeOption) => {
                onChange({ ...option }, name);
              }}
              defaultOption={value}
              {...restProps}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </Grid>
  );
};
