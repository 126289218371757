import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ImageIcon from '@mui/icons-material/Image';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import {
  SoloDriverIcon,
  TeamModeIcon,
  TractorsIcon,
  TrailersIcon,
  WebUserIcon,
} from '../../ui-kit/components/Assets';
import IconsComponent from '../../ui-kit/components/IconsComponent';

type EntityTypesType = {
  [key: string]: string;
};

type groupCategoriesType = {
  [key: string]: string;
};

type groupCategoriesIcon = {
  [key: string]: any;
};

export const entityTypes: EntityTypesType = {
  LOAD: 'LOAD',
  SOLO: 'SOLO',
  TEAM: 'TEAM',
  DRIVER: 'DRIVER',
  TRACTOR: 'TRACTOR',
  TRAILER: 'TRAILER',
  EQUIPMENT: 'EQUIPMENT',
  CUSTOMER: 'CUSTOMER',
  LOCATION: 'LOCATION',
  VENDOR: 'VENDOR',
  DOCUMENT: 'DOCUMENT',
  INVOICE: 'INVOICE',
  USER: 'USER',
  CARRIER: 'CARRIER',
};

export const groupCategories: groupCategoriesType = {
  LOAD: 'Loads',
  INVOICE: 'Invoices',
  DOCUMENT: 'Documents',
  DRIVER: 'Users/Drivers',
  EQUIPMENT: 'Equipment',
  LOCATION: 'Locations',
  VENDOR: 'Vendors',
  CUSTOMER: 'Customer',
  USER: 'Web users',
};

export const groupIcons: groupCategoriesIcon = {
  LOAD: <ContentPasteSearchIcon style={{ fontSize: 32, color: '#2B64CB' }} />,
  INVOICE: <ReceiptIcon style={{ fontSize: 32, color: '#2B64CB' }} />,
  DOCUMENT: {
    pdf: <PictureAsPdfIcon style={{ fontSize: 32, color: '#2B64CB' }} />,
    jpg: <ImageIcon style={{ fontSize: 32, color: '#2B64CB' }} />,
  },
  DRIVERTEAM: (
    <IconsComponent
      iconName="DriverAssist"
      source="AxeleIcon"
      styleProps={{ color: '#2B64CB' }}
    />
  ),
  SOLO: <SoloDriverIcon color="#2B64CB" height={32} width={32} />,
  TEAM: <TeamModeIcon color="#2B64CB" height={32} width={32} />,
  USER: <WebUserIcon color="#2B64CB" height={32} width={32} />,
  USERTEAM: <PersonOutlineIcon style={{ fontSize: 32, color: '#2B64CB' }} />,
  USERSOLO: <ReceiptIcon style={{ fontSize: 32, color: '#2B64CB' }} />,
  TRACTOR: <TractorsIcon color="#2B64CB" height={32} width={32} />,
  TRAILER: <TrailersIcon color="#2B64CB" height={30} width={30} />,
  LOCATION: (
    <LocationOnOutlinedIcon style={{ fontSize: 32, color: '#2B64CB' }} />
  ),
  VENDOR: <StorefrontIcon style={{ fontSize: 32, color: '#2B64CB' }} />,
  CUSTOMER: (
    <PeopleAltOutlinedIcon style={{ fontSize: 32, color: '#2B64CB' }} />
  ),
};
