import { Box, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCarrierPermission } from '../../../../../common/hooks/useCarrierPermission';
import { TagListComponent } from '../../../../../views/customTags/customTagChip';
import {
  ALLOWED_EQUIPMENT_TYPE_VALS,
  ChassisAndContainerAdditionalValues,
  LOAD_TYPE_KEYS,
} from '../../constants/fieldValues';
import { ReeferModeVals } from '../../schema/load';
import { NotesSummaryStyle, VerticalDividerStyle } from '../../style';

const LoadsSummary = () => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const load = watch('load');
  const { hasCarrierViewPermission } = useCarrierPermission();

  if (!load?.equipment?.length) return <></>;

  const isChassisAdditionalValuesGiven = (): boolean => {
    let isValuePresent = false;
    for (const key of ChassisAndContainerAdditionalValues) {
      if (load.equipmentAdditionalDetails?.[key]) {
        isValuePresent = true;
        break;
      }
    }
    return isValuePresent;
  };
  const renderEquipmentAdditionalDetails = () => {
    if (load.equipment?.includes?.(ALLOWED_EQUIPMENT_TYPE_VALS.REEFER)) {
      return (
        <span>
          (
          {load.temp && (
            <span>
              <b style={{ fontSize: '16px' }}>{load?.temp}</b>F
            </span>
          )}
          {!!load?.tempVariance && ` +/- ${load?.tempVariance}F`}
          {load.temp || load.tempVariance ? ', ' : ''}
          {(ReeferModeVals as any)[
            load?.equipmentAdditionalDetails?.reeferMode
          ] ?? ''}
          )
        </span>
      );
    } else if (
      load.equipment?.includes?.(
        ALLOWED_EQUIPMENT_TYPE_VALS.CHASSIS_CONTAINER
      ) &&
      isChassisAdditionalValuesGiven()
    ) {
      return (
        <>
          (
          {ChassisAndContainerAdditionalValues?.map?.((e, i: number) => {
            if (!load.equipmentAdditionalDetails?.[e]) return null;
            return (
              <>
                {i !== 0 && (
                  <Divider orientation="vertical" sx={VerticalDividerStyle} />
                )}
                <Typography
                  variant="body1"
                  fontWeight={400}
                  color="textSecondary"
                >
                  <b>{load.equipmentAdditionalDetails?.[e]}</b>
                </Typography>
              </>
            );
          })}
          )
        </>
      );
    }
    return <></>;
  };

  const renderSelectedEquipmentTitles = useMemo(() => {
    const equp = [...(load.equipment ?? [])];
    for (let i = 0; i < equp?.length; i++) {
      equp[i] = equp?.[i]?.replaceAll?.('_', ' ');
    }
    return equp ? equp?.join(', ') : '';
  }, [JSON.stringify(load.equipment)]);

  return (
    <Stack direction="column">
      {!!load.equipment?.length && (
        <Stack direction={'row'} width="100%" alignItems={'center'} gap={1}>
          <Typography
            variant="body1"
            sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
          >
            <b>{renderSelectedEquipmentTitles} </b>
            {renderEquipmentAdditionalDetails()}
          </Typography>
        </Stack>
      )}
      <Stack direction="row" gap={1} alignItems={'center'}>
        {Boolean(load.weight) && (
          <>
            <Typography variant="body1" color="textSecondary">
              <b>{load.weight}lbs</b>
            </Typography>
            <Divider orientation="vertical" sx={VerticalDividerStyle} />
          </>
        )}
        {!!(load.equipment?.length && load.loadType) && (
          <>
            <Typography variant="body1" color="textSecondary" fontWeight={600}>
              {LOAD_TYPE_KEYS[load.loadType]}
            </Typography>
            {hasCarrierViewPermission && (
              <Divider orientation="vertical" sx={VerticalDividerStyle} />
            )}
          </>
        )}

        {!!load.equipment?.length && hasCarrierViewPermission && (
          <Typography variant="body1" color="textSecondary" fontWeight={600}>
            <span style={{ fontWeight: 400 }}>Can be Brokered?</span>{' '}
            {load.isBrokered ? 'Yes' : 'No'}
          </Typography>
        )}
      </Stack>

      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {load.commodities.map((item: any, index: number) => {
          return item?.commodity ? (
            <li>
              <Stack
                direction="row"
                gap="5px"
                flexWrap="wrap"
                alignItems="center"
              >
                {item.commodity && (
                  <>
                    <Typography variant="body2" color="textSecondary">
                      {item.commodityDetails?.itemName}
                    </Typography>
                  </>
                )}
                {item.quantity && (
                  <>
                    <Divider orientation="vertical" sx={VerticalDividerStyle} />
                    <Typography variant="body2" color="textSecondary">
                      {item.quantity}
                    </Typography>
                  </>
                )}
                {item.package && (
                  <>
                    {/* <Divider orientation="vertical" sx={VerticalDividerStyle} /> */}
                    <Typography variant="body2" color="textSecondary">
                      {item.package}
                    </Typography>
                  </>
                )}
                {item.weight && (
                  <>
                    <Divider orientation="vertical" sx={VerticalDividerStyle} />
                    <Typography variant="body2" color="textSecondary">
                      {item.weight}lbs
                    </Typography>
                  </>
                )}
                {item.description && (
                  <>
                    <Divider orientation="vertical" sx={VerticalDividerStyle} />
                    <Typography variant="body2" color="textSecondary">
                      {item.description}
                    </Typography>
                  </>
                )}
                {/* {item.pickup != undefined || item.pickup != null ? (
                <>
                  <Divider orientation="vertical" sx={VerticalDividerStyle} />
                  <Typography variant="body2" color="textSecondary">
                    Pickup: {item.pickup}
                  </Typography>
                </>
              ) : (
                <></>
              )}
              {item.dropoff != undefined || item.dropoff != null ? (
                <>
                  <Divider orientation="vertical" sx={VerticalDividerStyle} />
                  <Typography variant="body2" color="textSecondary">
                    Dropoff: {item.dropoff}
                  </Typography>
                </>
              ) : (
                <></>
              )} */}
                {(item.length || item.width || item.height) && (
                  <>
                    <Divider orientation="vertical" sx={VerticalDividerStyle} />
                    <Typography variant="body2" color="textSecondary">
                      {item.length ? item.length + 'L x ' : ''}
                      {item.width ? item.width + 'W x ' : ''}
                      {item.height ? item.height + 'H' : ''}
                    </Typography>
                  </>
                )}

                {!!item?.volume && (
                  <>
                    <Divider orientation="vertical" sx={VerticalDividerStyle} />
                    <Typography variant="body2" color="textSecondary">
                      {item.volume + ' cu ft'}
                    </Typography>
                  </>
                )}
              </Stack>
            </li>
          ) : null;
        })}
      </ul>

      {!!load?.tags?.length && (
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={1}
          mt={1}
          sx={{ paddingRight: 2 }}
        >
          <TagListComponent tagList={load.tags} />
        </Stack>
      )}

      {load.notes && !errors?.load?.notes && (
        <Stack direction="row" sx={{ wordBreak: 'break-word' }}>
          <Box sx={NotesSummaryStyle}>
            <Typography variant="body2">{load.notes}</Typography>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
export default LoadsSummary;
