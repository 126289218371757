import { Box, Grid, Stack, ThemeProvider, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DirtyDetailsPanelManager } from '../../../../../common/DetailsPanel/utils';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../../common/Permission';
import { useThemeResponsive } from '../../../../../common/hooks/useThemeResponsive';
import { IPanelName } from '../../../../../store/pageStores/dispatch/dispatchTrip.store';
import { useStores } from '../../../../../store/root.context';
import { getCreateLoadPanelFormDefaultData } from '../../../../../subPages/loadsList/LoadDetailsPanel/utils';
import { useLoadListGridActions } from '../../../../../subPages/loadsList/hook/useLoadListGridActions';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { getGridCompanyTimezoneSettings } from '../../../../../utils/Timezone.utils';
import { CANCEL_TRIP_POPUP_CONFIG } from '../../../../../views/dispatch2/config';
import { FilterContainerDisabledStyles } from '../../../../../views/dispatch2/styles';
import CloseButton from '../../Generic/CloseButton';
import FullScreenButton from '../../Generic/FullScreenButton';
import FullScreenExitButton from '../../Generic/FullScreenExitButton';
import StyledFilters from '../../Generic/StyledFilters';
import {
  getStyledCustomFilter,
  getThemeDispatchFilters,
} from '../../gantt/Filters/styles';
import TripGridConfig, { tripSequenceSearchConfig } from '../grid.config';
import { CustomizeFilters } from './CustomizeFilters';

export interface GridFiltersProps {
  tableConfigurationProps?: any;
  setDefaultFilters?: any;
  onPageFilterChange?: any;
  onAllFilterSubmit?: any;
  onAllFilterReset?: any;
}

export const GridFilters = observer(
  ({
    tableConfigurationProps,
    onPageFilterChange,
    onAllFilterSubmit,
    onAllFilterReset,
  }: GridFiltersProps) => {
    const { isMobile, isTablet, isLaptop } = useThemeResponsive();
    const {
      dispatch2TripsStore: {
        secondaryPanelData,
        filters,
        setFullscreen,
        setNewLoadData,
        tripCountMetrics,
        fullscreenPanel,
      },
      dispatch2GlobalStore: { gapForTripAssignment, setTripAssignmentForGap },
    } = useStores();
    const hideFullScreenBtn = gapForTripAssignment?.id && isMobile;

    const showQuickFilter = useMemo((): boolean => {
      if (isMobile) {
        return false;
      }
      // if (isLaptop) {
      //   if (secondaryPanelData) return false;
      //   return true;
      // }
      return true;
    }, [isMobile, isTablet, isLaptop, secondaryPanelData]);
    const theme = useTheme();
    const overrideTheme = getThemeDispatchFilters({
      currTheme: theme,
      inputWithSearchIcon: true,
    });
    const styles = getStyledCustomFilter({
      theme,
      showQuickFilter,
    });

    const { canAddPermission } = useLoadListGridActions();
    const gridConfig = new TripGridConfig({
      theme,
      timezone: getGridCompanyTimezoneSettings().timezone,
    });

    const filterColumns = useMemo(() => {
      return gridConfig.config.getFiltersFormConfig({
        showQuickFilter,
        tripCountMetrics,
      });
    }, [showQuickFilter, tripCountMetrics]);

    const filterContainerMemo = useMemo(
      () => (gapForTripAssignment?.id ? FilterContainerDisabledStyles : {}),
      [gapForTripAssignment?.id]
    );

    const FullViewButton =
      fullscreenPanel === IPanelName.TRIP
        ? FullScreenExitButton
        : FullScreenButton;
    const { t, ready } = useTranslation();
    if (ready) {
      return (
        <Stack sx={styles.root}>
          <CustomizeFilters
            filterPerCol={isTablet ? [2] : [1, 2, 1]}
            groupOrder={['General', 'Route Information', 'Trip Information']}
            filterColumns={filterColumns}
            defaultFilterValue={filters}
            onPageFilterChange={onPageFilterChange}
            onAllFilterSubmit={onAllFilterSubmit}
            onAllFilterReset={onAllFilterReset}
            tableConfigurationProps={tableConfigurationProps}
            containerStyles={filterContainerMemo}
          />
          <ThemeProvider theme={overrideTheme}>
            <Grid
              sx={{
                ...styles.RightCustomizeFilters,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <Box
                alignItems={'center'}
                display="flex"
                sx={
                  gapForTripAssignment?.id ? FilterContainerDisabledStyles : {}
                }
              >
                <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
                  <ButtonImproved
                    size="small"
                    label={t('TripsAddLoad')}
                    color={'primary'}
                    styleProps={{
                      whiteSpace: 'nowrap',
                      backgroundColor: 'primary.main',
                      textTransform: 'capitalize',
                      marginRight: '6px',
                    }}
                    variant="contained"
                    onClick={() => {
                      if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
                      setNewLoadData(getCreateLoadPanelFormDefaultData());
                    }}
                  />
                </Permission>

                {showQuickFilter && (
                  <StyledFilters
                    columns={tripSequenceSearchConfig}
                    onChangeCb={onPageFilterChange}
                    defaultFilterValue={filters}
                  />
                )}
                <FullViewButton
                  styles={{
                    display: hideFullScreenBtn ? 'none' : 'block',
                  }}
                  onClick={() => {
                    setFullscreen((zoomedPanel) =>
                      zoomedPanel === IPanelName.TRIP ? null : IPanelName.TRIP
                    );
                  }}
                />
              </Box>
              {gapForTripAssignment?.id && (
                <Grid mt="auto" mb="auto">
                  <CloseButton
                    confirmPopupConfig={{
                      ...CANCEL_TRIP_POPUP_CONFIG,
                      onAction: () => setTripAssignmentForGap(null),
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </ThemeProvider>
        </Stack>
      );
    }
  }
);
