import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import TextInput from '../../ui-kit/components/TextField';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { subscriptionService } from '../../api';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { ResetPasswordRequest } from '../../models';
import { useRootStore } from '../../store/root-store/rootStateContext';
import { EPartner, getParameterByName, resetPasswordConfig } from './constant';
import {
  StyledFormTitle,
  StyledLoginAuthFromWrapper,
  getRegistrationStyles,
} from './styles';
import { ThemeProvider } from '@emotion/react';
import { Button, CircularProgress, Stack } from '@mui/material';
import { regitrationPageStyle } from './RegistrationPageUtils';
import { AuthIntroduction } from './components/AuthIntroduction';

const ResetPasswordForm = () => {
  const { themeLogin } = useThemeResponsive();
  const navigate = useNavigate();
  const [form, setForm] = useState({ password: '', confirmPassword: '' });
  const [error, setError] = useState({});
  const config = { ...resetPasswordConfig };
  const { setLoadingUrl, setLoadingFinishedUrl } = useRootStore();
  const [isLoading, setLoading] = useState<boolean>(false);

  const callApi = async (): Promise<void> => {
    if (validate()) {
      setLoading(true);
      const query = new ResetPasswordRequest();
      query.password = window.btoa(form.password);
      query.activationKey = getParameterByName('key');
      query.userId = getParameterByName('uid');
      query.organizationId = getParameterByName('organizationId');
      const conditionalUrl = 'resetpasswordform';
      // setLoadingUrl(conditionalUrl);
      const response = await subscriptionService.resetPassword(query);
      // setLoadingFinishedUrl(conditionalUrl);
      if (response) {
        navigate({
          pathname: '/login',
          search: window?.location?.search,
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const validate = () => {
    const toValid = config.form;
    const err = {};
    toValid.forEach((ele) => {
      ele.config.forEach((element) => {
        if (element.required && !form[element.name]) err[element.name] = true;
        else if (
          element.name === 'confirmPassword' &&
          form.confirmPassword !== form.password
        )
          err[element.name] = 'Confirm password does not match.';
      });
    });
    setError(err);
    return isEmpty(err);
  };
  const [searchParams] = useSearchParams();
  const isExistPartnerUser: boolean = Object.values(EPartner).includes(
    searchParams.get('partner')
  );

  const styles = getRegistrationStyles({
    theme: themeLogin,
    isExistPartnerUser: isExistPartnerUser,
  });
  return (
    <ThemeProvider theme={themeLogin}>
      <>
        <Box className="Auth-root" sx={styles.authRoot}>
          <Box className="Auth-backgroundImage" sx={styles.authBackgroundImage}>
            <Stack className="Auth-content" spacing={0} sx={styles.authContent}>
              <AuthIntroduction />

              <Box className="formWrapper" sx={styles.formWrapper}>
                <Box
                  sx={{
                    width: '100%',
                    height: 'max-content',
                    overflowX: 'auto',
                  }}
                >
                  <Box
                    className="formWrapperContent"
                    sx={styles.formWrapperContent}
                  >
                    <StyledLoginAuthFromWrapper>
                      <Box sx={regitrationPageStyle.verificationStyle}>
                        <Box
                          sx={{
                            height: '100%',
                          }}
                        >
                          <Box sx={{ width: '100%' }}>
                            <Box
                              sx={{
                                textAlign: 'center',
                                marginBottom: '15px',
                                position: 'relative',
                              }}
                            >
                              <StyledFormTitle>{config.title}</StyledFormTitle>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                              {config.form.map((block: any, index: number) => {
                                return (
                                  <Box
                                    key={index}
                                    sx={{ marginBottom: '20px', width: '100%' }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      {block.config.map(
                                        (
                                          field: {
                                            name: string;
                                            label: string;
                                            inputType?: string;
                                            width?: string;
                                            options?: any;
                                            type: string;
                                            required: boolean;
                                          },
                                          i: number
                                        ) => {
                                          return (
                                            <Box
                                              key={i}
                                              sx={{
                                                width: field.width || '100%',
                                                marginBottom: '15px',
                                              }}
                                            >
                                              {field.type === 'checkbox' ? (
                                                <Box
                                                  sx={(theme) => ({
                                                    ...theme.typography.body1,
                                                    color: 'text.primary',
                                                  })}
                                                >
                                                  <Checkbox
                                                    checked={form[field.name]}
                                                    onChange={(e) =>
                                                      setForm({
                                                        ...form,
                                                        [field.name]:
                                                          e.target.checked,
                                                      })
                                                    }
                                                  />
                                                  {field.label}
                                                </Box>
                                              ) : (
                                                <TextInput
                                                  type={
                                                    field.inputType || undefined
                                                  }
                                                  label={field.label}
                                                  error={Boolean(
                                                    error[field.name]
                                                  )}
                                                  helperText={error[field.name]}
                                                  onChange={(e: any) => {
                                                    const newForm = {
                                                      ...form,
                                                      [field.name]:
                                                        e.target.value,
                                                    };
                                                    setForm(newForm);
                                                    setError({
                                                      ...error,
                                                      [field.name]: '',
                                                    });
                                                  }}
                                                  styleProps={{ width: '100%' }}
                                                  value={form[field.name]}
                                                  variant="outlined"
                                                  required={field.required}
                                                />
                                              )}
                                            </Box>
                                          );
                                        }
                                      )}
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              variant="contained"
                              disabled={isLoading}
                              onClick={callApi}
                              sx={{
                                width: '238px',
                                color: 'primary.contrast',
                                ...themeLogin.typography.h6,
                                ...(isLoading && {
                                  background: '#3a4755 !important',
                                  color: '#757e88 !important',
                                }),
                              }}
                              size="large"
                            >
                              {isLoading && (
                                <CircularProgress
                                  size={22}
                                  sx={{ ml: '-16px', mr: '22px' }}
                                />
                              )}{' '}
                              {isLoading ? 'Reseting' : 'Reset'}
                            </Button>

                            {/* <ButtonImproved
                              onClick={callApi}
                              label="Reset"
                              size="large"
                              styleProps={{
                                width: '238px',
                                color: 'primary.contrast',
                                ...themeLogin.typography.h6,
                              }}
                              variant="contained"
                            /> */}
                          </Box>
                        </Box>
                      </Box>
                    </StyledLoginAuthFromWrapper>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </>
    </ThemeProvider>
  );
};

export default ResetPasswordForm;
