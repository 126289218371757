/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { plainToInstance } from 'class-transformer';
import axiosInstance, {
  ComposedError,
  httpClient,
} from '../../axios/axiosInstance';
import {
  AssetDeletionVerify,
  ByIdQueryParams,
  ChangeTrailerStatusRequest,
  CreateTrailerAlertQueryParamsRequest,
  DefaultTrailersTypesDictionarySummaryDTO,
  DefaultTrailersTypesList,
  DriverTrailerAssignRequest,
  DriverTrailerAssignResponse,
  EntitySearchResponse,
  MarkTrailerAsInactiveRequest,
  PaginatedTrailerList,
  PaginatedTrailerListRequest,
  PaginatedTrailerListResponse,
  QueryParams,
  TrailerAlertByIdQueryParams,
  TrailerAlertQueryParamsRequest,
  TrailerAlertSummaryDTO,
  TrailerCreateQueryParams,
  TrailerResponse,
  TrailerStaticResponse,
  UpdateTrailerAlertListRequest,
  VerifyTrailerByIdQueryParams,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { ITrailerService } from '../interfaces/ITrailerService';
import {
  changeTrailerStatusByIdStatusURL,
  createTrailerAlertURL,
  createTrailerURL,
  deleteTrailerAlertByIdURL,
  deleteTrailerByIdURL,
  exportTrailerToExcelURL,
  getDefaultTrailersTypesDictionaryURL,
  getDefaultTrailersTypesListURL,
  getPaginatedTrailerListURL,
  getTrailerAlertListURL,
  getTrailerByIdURL,
  getTrailerStaticDataURL,
  markTrailerAsInactiveURL,
  trailerAssign,
  trailerNameSearchExtendedURL,
  trailerUnassign,
  updateTrailerAlertsBulkURL,
  updateTrailerURL,
  verifyAssetDeletionURL,
} from './requestConstants';

export class TrailerService extends ITrailerService {
  @annotateNameAsync
  async getPaginatedTrailerList(
    requestData: PaginatedTrailerListRequest
  ): Promise<PaginatedTrailerListResponse | null | ServiceError> {
    try {
      const result = await httpClient.getRaw(
        getPaginatedTrailerListURL,
        requestData,
        false,
        true
      );
      return plainToInstance(PaginatedTrailerListResponse, result.data);
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPaginatedTrailerListExport(
    requestData: PaginatedTrailerListRequest
  ) {
    try {
      const tractors = await httpClient.get<PaginatedTrailerList>(
        exportTrailerToExcelURL,
        requestData,
        PaginatedTrailerList,
        undefined,
        true
      );
      return tractors;
    } catch (error) {
      if (!(error instanceof ComposedError)) {
        throw this.getServiceError(error as Error);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getTrailerStaticData() {
    const requestData = new QueryParams();
    try {
      return await httpClient.get(
        getTrailerStaticDataURL,
        requestData,
        TrailerStaticResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTrailerById(id: string) {
    const queryParams = new ByIdQueryParams(id);
    try {
      return await httpClient.get(
        getTrailerByIdURL,
        queryParams,
        TrailerResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createTrailer(newTractor: any) {
    const queryParams = new TrailerCreateQueryParams(newTractor);
    try {
      const response = await axiosInstance.post(createTrailerURL, {
        ...queryParams,
      });

      const data = await response.data;
      const tractor = plainToInstance(TrailerResponse, data);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'trailerCreation',
      });
      return Promise.resolve(tractor);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'trailerCreation',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateTrailer(newTractor: any) {
    const queryParams = new TrailerCreateQueryParams(newTractor);

    try {
      const response = await httpClient.patchRaw(updateTrailerURL, undefined, {
        undefined,
        ...queryParams,
      });

      const data = await response.data;
      const trailer = plainToInstance(TrailerResponse, data);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'trailerUpdate',
      });
      return Promise.resolve(trailer);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'trailerUpdate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async verifyTrailerDeletion(id: string) {
    const queryParams = new VerifyTrailerByIdQueryParams(id);
    try {
      return await httpClient.get(
        verifyAssetDeletionURL,
        queryParams,
        AssetDeletionVerify,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteTrailerById(id: string) {
    const payload = new ByIdQueryParams(id);
    try {
      const response = await httpClient.deleteRaw(
        deleteTrailerByIdURL,
        payload,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'trailerDelete',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'trailerDelete',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  //#region Trailer Alerts

  @annotateNameAsync
  async getTrailerAlertList(requestData: TrailerAlertQueryParamsRequest) {
    try {
      return await httpClient.get<TrailerAlertSummaryDTO[]>(
        getTrailerAlertListURL,
        requestData,
        TrailerAlertSummaryDTO,
        false,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTrailerByNameSearchExtendedList(
    keyword: string | undefined,
    pageNumber: number,
    statusList: string[],
    terminalIds?: string[]
  ) {
    const requestData = new QueryParams();
    requestData.pageNumber = pageNumber;
    requestData.pageSize = 25;
    requestData.search = keyword;
    requestData.statusList = statusList;
    if (terminalIds) requestData.terminalIds = terminalIds;
    try {
      return await httpClient.get(
        trailerNameSearchExtendedURL,
        requestData,
        EntitySearchResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createTrailerAlert(newAlert: CreateTrailerAlertQueryParamsRequest) {
    try {
      const response = await httpClient.post<TrailerAlertSummaryDTO>(
        createTrailerAlertURL,
        undefined,
        newAlert,
        TrailerAlertSummaryDTO
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'trailerAlertCreate',
      });
      return Promise.resolve(response);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'trailerAlertCreate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateTrailerAlertsBulk(newAlertList: UpdateTrailerAlertListRequest[]) {
    try {
      const response = await httpClient.put<TrailerAlertSummaryDTO[]>(
        updateTrailerAlertsBulkURL,
        undefined,
        newAlertList,
        TrailerAlertSummaryDTO
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'trailerAlertUpdate',
      });
      return Promise.resolve(response);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'trailerAlertUpdate',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDefaultTrailersTypesList(requestData: QueryParams) {
    try {
      const response = await httpClient.get<DefaultTrailersTypesList>(
        getDefaultTrailersTypesListURL,
        requestData,
        DefaultTrailersTypesList,
        false,
        true
      );
      return response.trailerMaintenances;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDefaultTrailersTypesDictionary(requestData: QueryParams) {
    try {
      return await httpClient.get<DefaultTrailersTypesDictionarySummaryDTO[]>(
        getDefaultTrailersTypesDictionaryURL,
        requestData,
        DefaultTrailersTypesDictionarySummaryDTO,
        false,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteTrailerAlertById(id: string) {
    const payload = new TrailerAlertByIdQueryParams(id);
    try {
      const response = await httpClient.deleteRaw(
        deleteTrailerAlertByIdURL,
        payload,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'trailerAlertDelete',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'trailerAlertDelete',
      });
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async driverTrailerAssign(
    requestData: DriverTrailerAssignRequest
  ): Promise<DriverTrailerAssignResponse | ServiceError> {
    try {
      const response = await httpClient.post<DriverTrailerAssignResponse>(
        trailerAssign,
        undefined,
        requestData,
        DriverTrailerAssignResponse
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'trailerDriverAssign',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'trailerDriverAssign',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async driverTrailerUnAssign(
    requestData: DriverTrailerAssignRequest
  ): Promise<DriverTrailerAssignResponse | ServiceError> {
    try {
      const response = await httpClient.post<DriverTrailerAssignRequest>(
        trailerUnassign,
        undefined,
        requestData,
        DriverTrailerAssignResponse
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'trailerDriverUnAssign',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'trailerDriverUnAssign',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async changeTrailerStatus(requestData: ChangeTrailerStatusRequest) {
    try {
      const response = await httpClient.patchRaw(
        changeTrailerStatusByIdStatusURL,
        undefined,
        [requestData]
      );

      const data = await response.data;
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'trailerStatusChange',
      });
      const tractor = plainToInstance(TrailerResponse, data);
      return Promise.resolve(tractor);
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'trailerStatusChange',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async markAsInactive(id: string) {
    const payload = new MarkTrailerAsInactiveRequest();
    payload.trailerId = id;
    try {
      const response = await httpClient.patchRaw(
        markTrailerAsInactiveURL,
        payload,
        null
      );
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        if (error.getStatusCode() === 417) {
          return {
            message: error.message,
            hasErrors: true,
          };
        }
        throw this.getServiceError(composedError);
      }
    }
  }

  //#endregion
}
