import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addLoadPageStopInfo } from '../../../common/PendoClassIDs/constants';
import AppointmentConfirmation from '../../../components_v2/generic/appointment-confirmation';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { AppointmentConfirmationOptions } from '../../../subPages/loadsList/CreateLoad/constants/fieldOptions';
import { AppointmentConfirmationType } from '../../../subPages/loadsList/CreateLoad/types/requests';
import { getLocationListByNameAddressSearch } from '../../../views/operations/contacts/utils/location';
import { ErrorMessage } from '../../ErrorMessage';
import GeoCodingModelPopup from '../../GeoCoding/GeoCodingModel';
import { FormAddress } from '../../Ui/AddressField';
import { addressType } from '../../Ui/AddressField/constants';
import DateTimePicker from '../../Ui/FormDateTimePicker/FormDateTimePicker';
import { SingleAutocompleteForm } from '../../Ui/SingleAutocomplete';
import Switch from '../../Ui/Switcher';
import { useThemeResponsive } from '../../hooks/useThemeResponsive';
import { stopFormRequiredFields } from '../constants';
import { StopFormRequiredFieldsType } from '../types';
import { isSamePoint } from '../utils';

export default function StopForm({
  id,
  nameStart = '',
  isViewMode = false,
  isStatusAvailable = true,
  requiredFields = {
    ...stopFormRequiredFields,
  },
  onStopAddressChange,
}: {
  id?: number;
  nameStart?: string;
  isViewMode?: boolean;
  isStatusAvailable?: boolean;
  requiredFields?: StopFormRequiredFieldsType;
  onStopAddressChange?: () => Promise<void>;
}) {
  const { isMobile } = useThemeResponsive();
  const { control, setValue, getValues, watch, formState } = useFormContext();
  const { errors } = formState;
  const [
    fixedAppointment,
    appointmentStartDate,
    appointmentEndDate,
    location,
    avgHandlingTime,
    notes,
    address,
  ] = watch([
    `${nameStart}fixedAppointment`,
    `${nameStart}appointmentStartDate`,
    `${nameStart}appointmentEndDate`,
    `${nameStart}location`,
    `${nameStart}avgHandlingTime`,
    `${nameStart}notes`,
    `${nameStart}addressDTO`,
  ]);
  const appointmentConfirmation = watch('appointmentConfirmation');

  const { getCompanyPreferences } = useRootStore();
  const defaultTimezone =
    address?.timezone || getCompanyPreferences?.timezone || '';

  // @TODO: AXL2-5440: remove locationNameError after finalization
  let locationNameError = '',
    datesDependencyError = '';
  // @TODO: Nvard - this is hot fix cause when setting stopsErrors array by setError it is converted to an object
  // Discuss with Nand Kishor
  if (nameStart) {
    if (errors?.stops) {
      if (Array.isArray(errors.stops)) {
        locationNameError = errors.stops[id]?.locationTextValue?.message;
        datesDependencyError = errors.stops[id]?.datesDependencyError?.message;
      } else {
        locationNameError =
          errors?.stops[`${id}`]?.locationTextValue?.message || '';
        datesDependencyError =
          errors?.stops[`${id}`]?.datesDependencyError?.message || '';
      }
    }
  }

  const updateStopDetailsByLocation = (location: {
    avgHandlingTime: number;
    notes: string;
  }) => {
    const { dirtyFields } = formState;
    const stopDirtyFields =
      id || id === 0 ? dirtyFields.stops && dirtyFields.stops[id] : dirtyFields;
    if (!avgHandlingTime || !stopDirtyFields?.avgHandlingTime) {
      setValue(`${nameStart}avgHandlingTime`, location.avgHandlingTime || 120);
    }
    if (!notes || !stopDirtyFields?.notes) {
      setValue(`${nameStart}notes`, location.notes);
    }
  };

  const handleLocationChange = (location: any) => {
    if (location?.id) {
      const {
        avgHandlingTime,
        notes,
        contacts = [],
        addressDTO,
        locationName,
        timezone,
      } = location;
      setValue(`${nameStart}locationTextValue`, locationName);
      setValue(`${nameStart}addressDTO`, addressDTO);
      updateStopDetailsByLocation({ avgHandlingTime, notes });

      if (timezone) {
        if (appointmentStartDate) {
          setValue(
            `${nameStart}appointmentStartDate`,
            moment(appointmentStartDate)
          );
        }
        if (appointmentEndDate) {
          setValue(
            `${nameStart}appointmentEndDate`,
            moment(appointmentEndDate)
          );
        }
      }
      const stopContact = getValues(`${nameStart}contact`);

      // If contact data is edited manually and is not empty than dont autopopulate location's primary contact data ( persist contact data)
      if (
        !stopContact ||
        stopContact.id ||
        (!stopContact.fullName &&
          !stopContact.email &&
          !stopContact.phoneData?.extension &&
          !stopContact.phoneData?.phone)
      ) {
        const primaryContact = contacts.find((contact) => contact.isPrimary);
        if (primaryContact) {
          primaryContact.fullName = primaryContact.fullName || '';
          const { email, phoneData, fullName, id: contactId } = primaryContact;
          setValue(`${nameStart}contact`, primaryContact);
          setValue(`${nameStart}contact.id`, contactId || null);
          setValue(`${nameStart}contact.fullName`, fullName);
          setValue(`${nameStart}contact.email`, email || '');
          setValue(`${nameStart}contact.phoneData`, phoneData || null);
        }
      }
      if (
        onStopAddressChange &&
        addressDTO?.address &&
        !isSamePoint(address?.center, addressDTO.center)
      ) {
        onStopAddressChange();
      }
    } else if (location) {
      const { locationName } = location;
      setValue(`${nameStart}locationTextValue`, locationName);
      setValue(`${nameStart}location`, {
        id: null,
        locationName,
        locationDisplayName: locationName,
      });
    } else {
      setValue(`${nameStart}locationTextValue`, '');
    }
  };

  const handleAddressChange = (data: addressType) => {
    if (!data?.center) return;
    const isSame = isSamePoint(data?.center, address?.center);
    if (location?.id && !isSame) {
      setValue(`${nameStart}location`, null);
    }
    if (onStopAddressChange && data?.address && !isSame) {
      onStopAddressChange();
    }
  };

  const handleFixedAppointment = (event: any) => {
    const value = event?.target.checked;
    if (value) {
      const appointmentStartDate = watch(`${nameStart}appointmentStartDate`);
      setValue(`${nameStart}appointmentEndDate`, appointmentStartDate);
    }
  };

  // Timezone setting logic can be removed if in DateTimePicker ui-kit component timezone will be handled automatically
  const handleDateTimeChange = (value: any, name: string | undefined) => {
    if (name === `${nameStart}appointmentStartDate`) {
      setValue(`${nameStart}appointmentStartDate`, value);
      if (fixedAppointment) {
        setValue(`${nameStart}appointmentEndDate`, value);
      }
    } else {
      setValue(`${nameStart}appointmentEndDate`, value);
    }
  };

  const { t, ready } = useTranslation();

  const confirmationCallPopupState = usePopupState({
    variant: 'popover',
    popupId: 'confirmationCallPopup',
  });

  const renderConfirmationIcon = () => {
    const confirmationCallStateIcon = (
      <AppointmentConfirmation status={appointmentConfirmation} />
    );
    return (
      <Box>
        <IconButton
          size="small"
          sx={{ flexShrink: 0 }}
          {...bindTrigger(confirmationCallPopupState)}
        >
          {confirmationCallStateIcon}
        </IconButton>
      </Box>
    );
  };

  const handleChangeConfirmationCall = (state: AppointmentConfirmationType) => {
    confirmationCallPopupState.close();
  };

  if (ready) {
    return (
      <>
        <SingleAutocompleteForm
          id={addLoadPageStopInfo + 'BusinessName'}
          control={control}
          fieldName="locationDisplayName"
          typedTextName="locationName"
          label={t('TripAddNewLoadOption3BusinessName')}
          name={`${nameStart}location`}
          disableFilter
          disabled={isViewMode}
          getOptions={(name, pageNumber) =>
            getLocationListByNameAddressSearch(name, pageNumber)
          }
          onChangeCb={handleLocationChange}
          renderOption={{
            enableOptionSubtitle: true,
            renderOptionSubTitle: (option: any): JSX.Element => {
              return (
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '0.625rem',
                    fontWeight: 400,
                    lineHeight: 1.167,
                    color: 'text.primary',
                    padding: '5px 5px',
                  }}
                >
                  {option.addressDTO?.fullAddress || ''}
                </Typography>
              );
            },
          }}
          sizes={{ xs: 12 }}
        />
        <FormAddress
          control={control}
          name={`${nameStart}addressDTO`}
          required={requiredFields.addressDTO}
          disabled={isViewMode}
          onChangeCallback={handleAddressChange}
          includeTimezone={true}
          validateAddress={false}
          sizes={{ xs: 10 }}
        />

        <GeoCodingModelPopup
          address={address}
          valueName={`${nameStart}addressDTO`}
          callBackAddress={(data: addressType) => {
            setValue(`${nameStart}addressDTO`, data);
          }}
        />
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          pl={2}
        >
          <Switch
            id={addLoadPageStopInfo + 'FixedAppointment'}
            control={control}
            label={t('TripAddNewLoadOption3FixedAppointment')}
            name={`${nameStart}fixedAppointment`}
            onChange={handleFixedAppointment}
            disabled={isViewMode}
            sizes={{ xs: 12 }}
            style={{ marginBottom: 0 }}
            subLabel={t('TripAddNewLoadOption3SubLabel')}
          />
          {renderConfirmationIcon()}
          <Menu {...bindMenu(confirmationCallPopupState)}>
            {AppointmentConfirmationOptions?.map((e) => (
              <MenuItem
                key={e?.value}
                selected={appointmentConfirmation == e.value}
                onClick={() => {
                  setValue(`appointmentConfirmation`, e.value, {
                    shouldDirty: true,
                  });
                  handleChangeConfirmationCall(e.value);
                }}
              >
                <ListItemIcon>
                  <AppointmentConfirmation status={e.value} />
                </ListItemIcon>
                {e.label}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
        <DateTimePicker
          id={addLoadPageStopInfo + 'StartDate'}
          control={control}
          name={`${nameStart}appointmentStartDate`}
          label={t('TripAddNewLoadOption3StartDateTime')}
          required={requiredFields.appointmentStartDate && isStatusAvailable}
          onChangeCallback={handleDateTimeChange}
          disabled={isViewMode}
          sizes={{ xs: isMobile ? 12 : 6 }}
          styleProps={{
            ...(isMobile && {
              width: '100%',
            }),
          }}
          timezone={defaultTimezone}
        />
        <DateTimePicker
          id={addLoadPageStopInfo + 'EndDate'}
          control={control}
          name={`${nameStart}appointmentEndDate`}
          label={t('TripAddNewLoadOption3EndDateTime')}
          sizes={{ xs: isMobile ? 12 : 6 }}
          styleProps={{
            ...(isMobile && {
              width: '100%',
            }),
          }}
          disabled={isViewMode || fixedAppointment}
          required={requiredFields.appointmentStartDate && isStatusAvailable}
          timezone={defaultTimezone}
        />
        {datesDependencyError ? (
          <ErrorMessage
            message={datesDependencyError}
            styles={{ paddingLeft: '8px', marginTop: '-15px' }}
          />
        ) : null}
      </>
    );
  }
}
