import React from 'react';

const DelayIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="3" fill="#FFBB58" />
      <path
        d="M10.4513 9.1912C10.7363 9.4762 10.8788 9.87203 10.8788 10.3866C10.8788 10.9012 10.7363 11.297 10.4513 11.5741C10.1663 11.8512 9.7625 11.9937 9.25584 11.9937H8.61459V8.7637H9.25584C9.7625 8.7637 10.1663 8.9062 10.4513 9.1912ZM17.9167 6.04036V14.7487C17.9167 15.6195 17.2042 16.332 16.3333 16.332H3.66667C2.79584 16.332 2.08334 15.6195 2.08334 14.7487V6.04036C2.08334 5.16953 2.79584 4.45703 3.66667 4.45703H16.3333C17.2042 4.45703 17.9167 5.16953 17.9167 6.04036ZM4.47417 7.40995L4.6325 11.202H5.78042L5.93875 7.40995H4.47417V7.40995ZM6.02584 12.5004C6.02584 12.2866 5.94667 12.1124 5.79625 11.9699C5.64584 11.8274 5.45584 11.7562 5.22625 11.7562C4.98875 11.7562 4.79084 11.8274 4.64042 11.9699C4.49 12.1124 4.41875 12.2866 4.41875 12.5004C4.41875 12.7062 4.49 12.8804 4.64042 13.0229C4.79084 13.1574 4.98875 13.2287 5.22625 13.2287C5.45584 13.2287 5.64584 13.1574 5.79625 13.0229C5.94667 12.8804 6.02584 12.7062 6.02584 12.5004ZM12.2563 10.3866C12.2563 9.84036 12.1375 9.35745 11.8921 8.93786C11.6546 8.51828 11.3142 8.18578 10.8788 7.9562C10.4433 7.72661 9.92875 7.60786 9.34292 7.60786H7.26084V13.1654H9.34292C9.92084 13.1654 10.4354 13.0466 10.8708 12.817C11.3142 12.5874 11.6546 12.2549 11.8921 11.8354C12.1296 11.4158 12.2563 10.9329 12.2563 10.3866ZM16.1354 12.1204H14.3621V7.60786H13.0083V13.1654H16.1354V12.1204Z"
        fill="white"
      />
    </svg>
  );
};

export default DelayIcon;
