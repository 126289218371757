import { ThemeOptions } from '@mui/material';

export const MuiFormHelperText: ThemeOptions['components'] = {
  MuiFormHelperText: {
    styleOverrides: {
      error: ({ theme }) =>
        theme.unstable_sx({
          color: 'error.main',
        }),
    },
  },
};
