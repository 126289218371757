import { GridColDefSelf } from '../../../../types';
import { formatPhoneNumber } from '../../../../utils';
import { getCustomerVendorLocationName } from '../utils/sharedMethods';

export const vendorColumns: GridColDefSelf[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    editable: false,
    sortable: true,
    permanent: true,
    renderCell: (params) => {
      return getCustomerVendorLocationName(params);
    },
    flex: 1,
  },
  {
    field: 'contact',
    headerName: 'Contact',
    sortable: false,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    sortable: false,
    minWidth: 250,
    renderCell: (params: any) => {
      const { row } = params;
      return formatPhoneNumber(row?.phoneData);
    },
  },
  {
    field: 'fullAddress',
    headerName: 'Address',
    sortable: false,
    minWidth: 250,
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: false,
    minWidth: 250,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    sortable: false,
    minWidth: 250,
  },
];
