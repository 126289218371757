export const alertFilterFieldsConfig = {
  alertType: {
    fieldName: 'maintenanceName',
    fieldFilterName: 'maintenanceItems',
    label: 'Alert Type',
  },
  entityName: {
    fieldName: 'entityName',
    fieldFilterName: 'equipmentName',
    label: 'Entity Name',
  },
  entityType: {
    fieldName: 'entityType',
    fieldFilterName: 'equipmentType',
    label: 'Entity Type',
  },
  alertStatus: {
    fieldName: 'alertStatus',
    fieldFilterName: 'alertStatus',
    label: 'Status',
  },
  lastConducted: {
    fieldName: 'lastConducted',
    label: 'Last Completed Date',
  },
  nextDue: {
    fieldName: 'nextDue',
    label: 'Next Due Date',
  },
  lastServiceDueOdometer: {
    fieldName: 'lastServiceDueOdometer',
    label: 'Last Completed Odometer',
  },
  nextServiceDueOdometer: {
    fieldName: 'nextServiceDueOdometer',
    label: 'Next Due Odometer',
  },
  notificationScheduleByDays: {
    fieldName: 'notificationScheduleByDays',
    label: 'Notification Schedule',
  },
};

export enum RecordViewMode {
  calendar = 'calendar',
  table = 'table',
}

export const strip = 'safetyAlerts';
export const debounceTime = 300;
