import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import Events from '../EventEmitter/EventEmitter';
import { EventTypes } from '../EventEmitter/EventTypes';
import EncryptedStorageManager from '../StorageManager/EncryptedStorageManager';
import StorageManager from '../StorageManager/StorageManager';
import { RootStoreInstence } from '../store/root-store/rootStateContext';
import { TokenWrapper } from '../models';
import axiosInstance from './axiosInstance';
import { getPermisssionHashFromToken, refetchTokens } from './helper';

export interface AxiosConfigType extends AxiosRequestConfig {
  apiEndpoint: string | undefined;
  contentType?: string;
  data?: any;
  headers?: Record<any, any>;
  defaultUrl?: boolean;
  signal?: AbortSignal;
  cancelToken?: CancelToken;
  params?: object;
  timeout?: number;
}

const apiRequest: any = async ({
  defaultUrl = true,
  timeout = 1000 * 90, // Wait for 1.5 mins
  ...config
}: AxiosConfigType): Promise<any> => {
  try {
    const currentRequestConfig = await createRequestConfig({
      ...config,
      defaultUrl,
      timeout,
    });
    axios.interceptors.response.use(
      (response) => response,
      async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 403) {
          Events.emit(EventTypes.SESSION_EXPIRED);
        }
        if (error?.response?.status === 401 && !originalRequest._retry) {
          const tokens = await refetchTokens();
          const localPermHash = await getPermisssionHashFromToken();
          if (tokens) {
            const remotePermHash = await getPermisssionHashFromToken(
              tokens?.token
            );
            if (localPermHash === remotePermHash) {
              originalRequest._retry = true;
              originalRequest.headers['mp-auth-token'] = tokens?.token;
              const newTokens = new TokenWrapper(
                tokens.token,
                tokens.refreshToken
              );
              EncryptedStorageManager.setItem(
                'token',
                JSON.stringify(newTokens)
              );
              return axiosInstance(originalRequest);
            } else {
              Events.emit(EventTypes.PERMISSION_CHANGED);
            }
          }
        }
        return Promise.reject(error);
      }
    );
    const response = (await axios(currentRequestConfig)) as AxiosRequestConfig;
    return response;
  } catch (error: any) {
    const statusCode = error?.response?.status;
    switch (statusCode) {
      case 401:
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'unauthorized',
        });
        return Events.emit(EventTypes.AUTH_ERROR);
      default:
    }
    throw error;
  }
};

async function createRequestConfig(config: AxiosConfigType) {
  const token: any = EncryptedStorageManager.getItem('token') || null;
  const headers = token
    ? {
        'mp-auth-token': token?.access,
      }
    : {};
  const requestConfig: AxiosRequestConfig = {
    method: config.method,
    url: config.apiEndpoint,
    headers: config.headers || (headers as any),
    data: config.data ? config.data : {},
    signal: config.signal,
    cancelToken: config.cancelToken,
    params: {
      organizationId: StorageManager?.getUser()?.organizationId,
      ...config.params,
    },
    timeout: config.timeout,
  };
  return requestConfig;
}

export const http = {
  get: async (config: Partial<AxiosConfigType>) =>
    apiRequest({ ...config, method: 'GET' }),
  post: async (config: Partial<AxiosConfigType>) =>
    apiRequest({ ...config, method: 'POST' }),
  delete: async (config: Partial<AxiosConfigType>) =>
    apiRequest({ ...config, method: 'DELETE' }),
  put: async (config: Partial<AxiosConfigType>) =>
    apiRequest({ ...config, method: 'PUT' }),
};
