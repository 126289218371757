import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import TimeoffDateTime from '../../../../utils/timeoffDateTime';
import { EDispatch3DotMenuType } from '../../../../views/dispatch/constants/types';
import DispatchController from '../../../../views/dispatch/utils/Controller';
import { FormAddress } from '../../../Ui/AddressField';
import FormDatePicker from '../../../Ui/FormDatePicker/FormDatePicker';
import { SingleAutocompleteForm } from '../../../Ui/SingleAutocomplete';
import TextField from '../../../Ui/TextField/TextField';
import FormTimeDuration from '../../../Ui/TimeDuration/FormTimeDuration';
import { ITimeoffDateWithTz, TimeoffFormProps } from '../../types';
import { LoadItem, LoadItemOptionsProps } from './LoadItem';

export const TimeoffForm: FC<TimeoffFormProps> = ({
  loadData: { driverGroupId },
  action,
  canEditDate = true,
}: TimeoffFormProps) => {
  const { getCompanyPreferences } = useRootStore();
  const { control, watch, setValue, setError, clearErrors } = useFormContext();
  const [startDatePart, endDatePart, startTimePart, endTimePart, location] =
    watch([
      'startDatePart',
      'endDatePart',
      'startTimePart',
      'endTimePart',
      'location',
    ]);

  const defaultTimezone =
    location?.timezone || getCompanyPreferences?.timezone || '';

  useEffect(() => {
    const updateDuration = () => {
      if (
        startDatePart === null ||
        startTimePart === null ||
        endDatePart === null ||
        endTimePart === null
      ) {
        return;
      }
      const data: ITimeoffDateWithTz = {
        startDatePart: startDatePart,
        startTimePart: startTimePart,
        endDatePart: endDatePart,
        endTimePart: endTimePart,
      };
      const { appointmentStartDate, appointmentEndDate } =
        TimeoffDateTime.calculateAppointmentDates(data);

      const { duration } = TimeoffDateTime.calculateDuration({
        startDate: appointmentStartDate,
        endDate: appointmentEndDate,
      });
      setValue('estimatedActivityDuration', duration);
    };
    updateDuration();
  }, [
    clearErrors,
    endDatePart,
    endTimePart,
    setError,
    setValue,
    startDatePart,
    startTimePart,
  ]);

  const minDate = moment().startOf('day').toDate();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid spacing={1} container>
        <Grid xs={12} item>
          {action == EDispatch3DotMenuType.REQUEST_TIMEOFF && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              ml={0}
            >
              <SingleAutocompleteForm
                styledOption={{
                  height: '70px !important',
                }}
                control={control}
                name="previousLoad"
                fieldName="title"
                getOptions={(seqNumber, pageNumber) =>
                  DispatchController.instance().getPreviousLoad({
                    seqNumber,
                    pageNumber,
                    driverGroupId,
                  })
                }
                renderOption={{
                  enableOptionSubtitle: true,
                  renderOptionSubTitle: (
                    option: LoadItemOptionsProps
                  ): JSX.Element => <LoadItem data={option} />,
                }}
                label="Select Previous Load"
                sizes={{ xs: 12 }}
              />
            </Stack>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            ml={0}
          >
            <FormAddress
              label="Search Location (Address/Zip Code/City)"
              control={control}
              name="location"
              required
              sizes={{ xs: 12 }}
              includeTimezone
            />
          </Stack>

          <Typography variant="subtitle1" mt={'20px'} mb={'10px'}>
            Time Off Start
          </Typography>

          <Grid container columnSpacing={2}>
            <FormDatePicker
              control={control}
              name="startDatePart"
              label="Start Date"
              sizes={{ xs: 6 }}
              minDate={minDate}
              required
              timezone={defaultTimezone}
              disabled={!canEditDate}
            />
            <FormTimeDuration
              minuteStep={15}
              maxValue={1440}
              required
              control={control}
              name={`startTimePart`}
              label="Start Time"
              sizes={{ xs: 6 }}
              styleProps={{
                float: 'right',
              }}
              disabled={!canEditDate}
              is24HFormat
            />
          </Grid>

          <Typography variant="subtitle1" mt={'20px'} mb={'10px'}>
            Time Off End
          </Typography>

          <Grid container columnSpacing={2}>
            <FormDatePicker
              control={control}
              name="endDatePart"
              label="End Date"
              required
              sizes={{ xs: 6 }}
              timezone={defaultTimezone}
              minDate={
                startDatePart
                  ? moment(startDatePart).startOf('days').toDate()
                  : minDate
              }
              disabled={!canEditDate}
            />
            <FormTimeDuration
              minuteStep={15}
              maxValue={1440}
              required
              control={control}
              name={`endTimePart`}
              label="End Time"
              sizes={{ xs: 6 }}
              styleProps={{
                float: 'right',
              }}
              disabled={!canEditDate}
              is24HFormat
            />
          </Grid>

          <Grid container mt={2} spacing={1} columnSpacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled
                control={control}
                name="estimatedActivityDuration"
                label="Duration"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};
