import { Type } from 'class-transformer';
import { PaginatedList } from '../../../types';
import {
  EPaymentMethod,
  EPaymentStatus,
} from '../../../views/finance/paymentManagement/constants/types';
import { QueryParams } from '../commonMixed';

export class GetUnpaidPaymentsQueryParams extends QueryParams {
  customerId: number;
  constructor({ customerId }: any) {
    super();
    this.customerId = customerId;
  }
}
export class InvoicePaymentDetails {
  invoiceId!: string;
  invoiceSeqNumber!: string;
  invoiceReferenceId!: string;
  total!: number;
  amountDue!: number;
  invoiceDueDate!: string;
  amount!: number;
  advance!: boolean;
  isInvalid?: boolean;
  loadId?: string;
}
export class InvoicePay {
  invoiceId!: string;
  amount!: number;
  advance!: boolean;
}
export class CreatePaymentDTO extends QueryParams {
  paymentDate!: string;
  paymentMethod!: EPaymentMethod;
  paymentReferenceId!: string;
  amountReceived!: number;
  memo!: string;
  customerId!: number;
  invoicePay!: InvoicePay[];
  assignedDocuments!: any;

  constructor(data: any) {
    super(true);
    Object.assign(this, data);
  }
}
export class UpdatePaymentDTO extends CreatePaymentDTO {
  id!: string;
  constructor(data: any) {
    super(data);
  }
}
export class CustomerSummaryDTO {
  //
}
export class AssignedFileDTO {
  //
}
export class PaymentDetailsDTO {
  id!: string;
  seqNumber!: string;
  organizationId!: string;
  paymentDate!: string;
  createdDate!: string;
  paymentStatus!: EPaymentStatus;
  paymentMethod!: EPaymentMethod;
  paymentReferenceId!: string;
  amountReceived!: number;
  remainingCredit!: number;
  memo!: string;
  customerId!: number;
  customer!: CustomerSummaryDTO;
  createdBy!: string;
  isAdvanced!: boolean;
  invoicedetails!: InvoicePaymentDetails[];
  assignedDocuments!: AssignedFileDTO[];
}

export class GetPaginatedPaymentListParams extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  sort!: string;

  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class GetPaginatedPaymentListPayload extends QueryParams {
  paymentIds!: Array<string | number>;
  paymentSeqNumberList!: Array<string | number> | null;
  paymentMethodList!: Array<string | number>;
  invoiceSeqNumberList!: Array<string | number>;
  referenceIds!: Array<string | number>;
  customerNameFilter!: Array<string | number>;
  paymentFromDate!: string;
  paymentToDate!: string;
  remainCredit!: boolean;
  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class GetPaginatedPaymentListResponse extends PaginatedList {
  @Type(() => PaymentDetailsDTO)
  content!: PaymentDetailsDTO[];
}

export class PaymentListViewDataRequest {
  paymentIds!: string[];
  paymentMethodList!: EPaymentMethod[];
  invoiceSeqNumberList!: string[];
  referenceIds!: string[];
  customerNameFilter!: string[];
  paymentFromDate!: string;
  paymentToDate!: string;
  remainCredit!: number;
}

export class PaymentExportRequestDTO {
  gridFiltersMetadataList?: PaymentListViewDataRequest;
  gridColumnMetadataList!: string;
  sort?: string;
}

export class GetPaymentCountTotalResponse {
  totalAmountReceived!: number;
  totalRemainCredit!: number;
}

export class GetPaymentOneRequest extends QueryParams {
  organizationId!: number;
  id: string;

  constructor(dto: any) {
    super(true);
    this.id = dto.id;
  }
}

export class GetPaymentOpenCreditRequest extends QueryParams {
  organizationId!: number;
  constructor() {
    super(true);
  }
}

export class GetPaymentOpenCreditResponse {
  [key: string]: number;
}

export class PaymentMessageResponse {
  message: string;
}

export class ExportPaymentQueryParams extends QueryParams {
  sort!: string;
  gridColumnMetadataList!: string[] | null;
  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class ExportPaymentPayload extends QueryParams {
  constructor(params: any) {
    super(false);
    Object.assign(this, params);
  }
}

export class PaginatedPaymentSeqNumberListParams extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  seqNumber?: string;

  constructor(params: {
    pageNumber: number;
    pageSize: number;
    seqNumber?: string;
  }) {
    super();
    this.pageNumber = params.pageNumber;
    this.pageSize = params.pageSize;
    this.seqNumber = params.seqNumber;
  }
}
class PaymentSeqNumber {
  seqNumber!: string;
  id!: string;
}
export class PaginatedPaymentSeqNumberList extends PaginatedList {
  @Type(() => PaymentSeqNumber)
  content!: PaymentSeqNumber[];
}

export class PaginatedPaymentReferenceListParams extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  referenceId?: string;

  constructor(params: {
    pageNumber: number;
    pageSize: number;
    referenceId?: string;
  }) {
    super();
    this.pageNumber = params.pageNumber;
    this.pageSize = params.pageSize;
    this.referenceId = params.referenceId;
  }
}
class PaymentReference {
  referenceId!: string;
  id!: string;
}
export class PaginatedPaymentReferenceList extends PaginatedList {
  @Type(() => PaymentReference)
  content!: PaymentReference[];
}

export class PaginatedPaymentInvoiceListParams extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  invoiceId?: string;

  constructor(params: {
    pageNumber: number;
    pageSize: number;
    invoiceId?: string;
  }) {
    super();
    this.pageNumber = params.pageNumber;
    this.pageSize = params.pageSize;
    this.invoiceId = params.invoiceId;
  }
}
class PaymentInvoice {
  invoiceId!: string;
  id!: string;
}
export class PaginatedPaymentInvoiceList extends PaginatedList {
  @Type(() => PaymentInvoice)
  content!: PaymentInvoice[];
}

export class updatePaymentToInvoiceMappingtParams extends QueryParams {
  paymentId!: string;
  invoiceId!: string;

  constructor(params: { paymentId: string; invoiceId: string }) {
    super();
    this.paymentId = params.paymentId;
    this.invoiceId = params.invoiceId;
  }
}
