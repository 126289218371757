import { yupResolver } from '@hookform/resolvers/yup';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import {
  Box,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  SxProps,
} from '@mui/material';
import { Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IHttpErrorType } from '../../../types';
import AxeleLoadingButton from '../../../ui-kit/AxeleLoadingButton/AxeleLoadingButton';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import {
  BREAKPOINT_TABLET,
  useThemeResponsive,
} from '../../hooks/useThemeResponsive';
import DialogTitle from './components/DialogTitle';
import { FormDialogMode } from './constants';

const color_1 = '#c5c8ca'; //we don't have this color. @todo Nand.

const muiBackdrop = {
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backdropFilter: 'blur(7px)',
        background: 'rgba(15, 26, 38, 0.2)', //we don't have this color. @todo Nand.
      },
    },
  },
};

const theme = (
  t: Theme,
  mode: FormDialogMode,
  { themeRs }: { themeRs: Theme },
  overRideComponentTheme?: any
) => {
  const { palette } = t;

  if (mode === FormDialogMode.LIGHT) {
    return createTheme(t, {
      components: {
        MuiDialog: {
          styleOverrides: {
            root: {
              MuiDialogContent: {
                padding: 10,
              },
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              padding: '30px 12px 10px 10px !important',
            },
          },
        },
        ...muiBackdrop,
        MuiButton: {
          styleOverrides: {
            ...t?.components?.MuiButton.styleOverrides,
            root: (params) => {
              return {
                ...t?.components?.MuiButton?.styleOverrides?.root(params),
                marginLeft: '20px !important',
              };
            },
          },
        },
        ...overRideComponentTheme,
      },
      breakpoints: themeRs.breakpoints,
    });
  }

  return createTheme(t, {
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            MuiDialogContent: {
              padding: 10,
            },
          },
        },
      },
      ...muiBackdrop,
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '30px 12px 10px 10px !important',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: color_1,
            borderColor: color_1,
            '&::before': {
              borderBottom: `1px solid ${color_1}`,
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: `1px solid ${color_1}`,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: color_1,
            borderColor: color_1,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          ...t?.components?.MuiButton?.styleOverrides,
          root: (params) => {
            return {
              ...t?.components?.MuiButton?.styleOverrides?.root(params),
              marginLeft: '20px !important',
              '&.Mui-disabled': {
                backgroundColor: palette.primary.dark,
              },
            };
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: color_1,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: color_1,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: color_1,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: color_1,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: color_1,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: `${color_1} !important`,
            '&.Mui-disabled': {
              opacity: 0.5,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: color_1,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: palette.primary.main,
            '& .MuiChip-deleteIcon': {
              color: '#19232c', //we don't have this color. @todo Nand.
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            '& .MuiSwitch-track': {
              backgroundColor: `${color_1} !important`,
            },
            '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
              backgroundColor: '#1890ff !important', //we don't have this color. @todo Nand.
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: color_1,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked.Mui-disabled': {
              color: palette.primary.main,
            },
            '&.Mui-disabled': {
              opacity: 0.5,
              color: color_1,
            },
          },
        },
      },
      ...overRideComponentTheme,
    },
    breakpoints: themeRs.breakpoints,
  });
};

export type FormDialogProps = {
  open: boolean;
  width?: number;
  mode?: FormDialogMode;
  handleClose?: () => void;
  onAction?: (data: any, formState) => void | Promise<void>;
  onDelete?: () => void;
  onReset?: () => void;
  titleText?: React.ReactNode;
  data?: any;
  contentRenderer?: () => React.ReactNode;
  actionLabel?: string;
  actionLabelId?: string;
  deleteActionLabel?: string;
  resetActionLabel?: string;
  validationSchema?: any;
  disableActionBtnByDefault?: boolean;
  showActionIcon?: boolean;
  httpErrors?: IHttpErrorType[] | null;
  cancelLabel?: string;
  actionColor?: string;
  sx?: SxProps;
  deleteActionBtnStyles?: object;
  secondaryActionContainerStyles?: object;
  cancelBtnProps?: object;
  onCloseIconClick?: () => void;
  componentTheme?: any;
  paperStyles?: SxProps;
  customStyles?: SxProps;
  isDisableResetBtn?: boolean;
  disableActionBtnProp?: boolean;
  secondaryAction?: {
    actionLabel: string;
    onAction: (data: any, formState) => void | Promise<void>;
    disableActionBtnByDefault?: boolean;
    checkIsDirty?: boolean;
  } & Pick<ButtonProps, 'size' | 'variant'>;
};

function FormDialog({
  contentRenderer,
  open,
  mode = FormDialogMode.LIGHT,
  width = 514,
  onAction,
  onDelete,
  onReset,
  handleClose,
  titleText = '',
  data,
  actionLabel = 'Save',
  cancelLabel = 'Cancel',
  actionLabelId = '',
  deleteActionLabel = 'Delete',
  resetActionLabel = 'Reset',
  validationSchema,
  disableActionBtnByDefault,
  showActionIcon = false,
  actionColor = 'primary',
  httpErrors,
  deleteActionBtnStyles,
  secondaryActionContainerStyles,
  cancelBtnProps,
  componentTheme,
  isDisableResetBtn = false,
  secondaryAction,
  customStyles,
  disableActionBtnProp = false,
  ...other
}: FormDialogProps) {
  const { theme: themeRs } = useThemeResponsive();
  const defaultValues = data;
  const state = useForm({
    reValidateMode: 'onSubmit',
    defaultValues,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });
  const [actionLoading, setActionLoading] = useState(false);
  const [secondaryActionLoading, setSecondaryActionLoading] =
    useState<boolean>(false);
  const {
    getValues,
    setValue,
    setError,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = state;
  const handleAction = async (updatedData: any) => {
    setActionLoading(true);
    if (onAction) await onAction({ ...updatedData }, { dirtyFields });
    setActionLoading(false);
  };

  const handleSecondaryAction = async () => {
    setSecondaryActionLoading(true);
    if (secondaryAction?.onAction)
      await secondaryAction?.onAction({ ...getValues() }, { dirtyFields });
    setSecondaryActionLoading(false);
  };

  const resetFormHandler = useCallback((): void => {
    reset(data);
  }, [data, reset]);

  useEffect(() => {
    if (data && !isEqual(data, defaultValues)) {
      resetFormHandler();
    }
  }, [data, setValue]);

  useEffect(() => {
    if (open) {
      resetFormHandler();
    }
  }, [open]);

  useEffect(() => {
    if (Array.isArray(httpErrors)) {
      httpErrors?.forEach((error) => {
        setError(error.fieldName, {
          type: 'httpError',
          message: error.message,
        });
      });
    }
  }, [httpErrors]);
  return (
    <ThemeProvider
      theme={(t: Theme) => theme(t, mode, { themeRs }, componentTheme)}
    >
      <Dialog
        disableEnforceFocus
        PaperProps={{
          sx: () => ({
            maxWidth: 'unset',
            minHeight: 250,
            width: width,
            borderRadius: customStyles?.borderRadius || 6,
            background: mode === 'LIGHT' ? 'primary.contrast' : '#19232c', //we don't have this color. @todo Nand.,
            padding: '16px',

            '& .dialogTitle_class': {
              fontSize: '15px',
              // color: customStyles?.color,
            },
            [themeRs.breakpoints.down(BREAKPOINT_TABLET)]: {
              height: '100vh',
              width: '100vw',
              maxHeight: '100%',
              maxWidth: 'unset',
              margin: '0',
              borderRadius: '0',
            },
          }),
        }}
        open={open}
        {...other}
        maxWidth={false}
      >
        <DialogTitle
          mode={mode}
          onClose={() => {
            if (other?.onCloseIconClick) other?.onCloseIconClick?.();
            else handleClose?.();
          }}
          titleText={titleText}
        />

        <DialogContent>
          <FormProvider {...state}>
            <Grid spacing={1} container>
              <form
                onSubmit={handleSubmit(handleAction)}
                style={{ width: '100%' }}
              >
                {contentRenderer && contentRenderer()}
              </form>
            </Grid>
          </FormProvider>
        </DialogContent>
        <DialogActions
          sx={{
            width: '100%',
            padding: 0,
            display: 'flex',
            justifyContent: onDelete || onReset ? 'space-between' : 'flex-end',
          }}
        >
          {(onDelete || onReset) && (
            <Box sx={secondaryActionContainerStyles}>
              {onDelete && (
                <ButtonImproved
                  onClick={() => {
                    if (typeof onDelete === 'function') {
                      onDelete();
                    }
                  }}
                  size="small"
                  variant="text"
                  label={deleteActionLabel}
                  styleProps={{
                    minWidth: 0,
                    padding: 0,
                    marginLeft: '0px !important',
                    color: 'error.dark',
                    fontWeight: 500,
                    fontSize: 15,
                    ...deleteActionBtnStyles,
                  }}
                />
              )}
              {onReset && (
                <ButtonImproved
                  startIcon={<RefreshOutlinedIcon />}
                  onClick={() => {
                    onReset();
                    resetFormHandler();
                  }}
                  variant="text"
                  label={resetActionLabel}
                  styleProps={{
                    marginLeft: '0px !important',
                  }}
                  disabled={isDisableResetBtn ? !isDirty : false}
                ></ButtonImproved>
              )}
            </Box>
          )}

          <Box>
            <ButtonImproved
              id={'cancelButton'}
              onClick={() => {
                handleClose?.();
              }}
              variant="outlined"
              label={cancelLabel}
              size={'medium'}
              {...cancelBtnProps}
              disabled={actionLoading || secondaryActionLoading}
            />
            {secondaryAction?.onAction && (
              <AxeleLoadingButton
                color={actionColor as any}
                startIcon={showActionIcon && <CheckOutlinedIcon />}
                onClick={() => {
                  if (!!secondaryAction?.checkIsDirty) {
                    handleSubmit(handleSecondaryAction);
                  } else {
                    handleSecondaryAction();
                  }
                }}
                variant={secondaryAction.variant || 'outlined'}
                size={secondaryAction.size || 'medium'}
                {...(!!secondaryAction?.checkIsDirty
                  ? {
                      disabled: actionLoading
                        ? true
                        : secondaryAction.disableActionBtnByDefault
                        ? !isDirty
                        : false,
                    }
                  : {
                      disabled: actionLoading,
                    })}
                loading={secondaryActionLoading}
                sx={{ minWidth: '90px' }}
              >
                {secondaryAction.actionLabel}
              </AxeleLoadingButton>
            )}
            <AxeleLoadingButton
              id={actionLabelId}
              color={actionColor as any}
              startIcon={showActionIcon && <CheckOutlinedIcon />}
              onClick={handleSubmit(handleAction)}
              variant="contained"
              size="medium"
              disabled={
                actionLoading || secondaryActionLoading || disableActionBtnProp
                  ? true
                  : disableActionBtnByDefault
                  ? !isDirty
                  : false
              }
              loading={actionLoading}
              sx={{ minWidth: '90px' }}
            >
              {actionLabel}
            </AxeleLoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

FormDialog.defaultProps = {
  open: false,
  titleText: '',
};

export default FormDialog;
