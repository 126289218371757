import { Stack, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { EventTypes } from '../../../../EventEmitter/EventTypes';
import { EventHoldLoadActionData } from '../../../../EventEmitter/Events/EventHoldLoadAction';
import { LoadTripActionData } from '../../../../EventEmitter/Events/EventLoadTripAction';
import { EventTripStopActionData } from '../../../../EventEmitter/Events/EventTripStopAction';
import { EventUpdateProgressData } from '../../../../EventEmitter/Events/EventUpdateProgress';
import { loadService, tripService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import DetailsPanel from '../../../../common/DetailsPanel';
import { PanelFSProps } from '../../../../common/DetailsPanel/Panel';
import { useSecondaryDetailsPanel } from '../../../../common/HOC/withSecondaryDetailsPanel/useSecondaryDetailsPanel';
import WithSecondaryDetailsPanel from '../../../../common/HOC/withSecondaryDetailsPanel/withSecondaryDetailsPanel';
import { ELoadStatus } from '../../../../common/LoadTabPanel/constants/constants';
import { ActionsSection } from '../../../../common/LoadTabPanel/hoc/actionsSection';
import { LoadOverviewDetails } from '../../../../common/LoadTabPanel/tabs/Overview/models';
import { FormAsset } from '../../../../common/LoadTabPanel/tabs/Routes/components/AssetAssignmentPopup';
import { RelayStopFormData } from '../../../../common/LoadTabPanel/tabs/Routes/components/RelayStopPopup/RelayStopForm';
import { BrokeredTripStatus } from '../../../../common/Ui/StatusComponent/StatusComponent';
import { tripLoadCustomTitle } from '../../../../common/UiUtils/uiUtils';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import {
  BrokeredUnAssignParams,
  LoadDetailsResponse,
  PreferencesDTO,
  Trip,
} from '../../../../models';
import {
  GetTripDetailByIdQueryParams,
  UpdateDispatchRevenueQueryParams,
} from '../../../../models/DTOs/Trip/Requests';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../store/root.context';
import { TripV3DataProps } from '../../../../ui-kit/TripPlan';
import {
  ExpandableTimelineV3LoadIdEvents,
  StopSolutionV3Prop,
} from '../../../../ui-kit/components/TripPlan';
import useEmit, {
  EventCallbackFunction,
} from '../../../../utils/hooks/useEmit';
import { ESecondaryDetailsPanelType } from '../../../dispatch2/constants/types';
import { E3DotMenuType } from '../../../myLoads/components/LoadDetailDialog/types';
import { ETripPanelTabs } from '../../constants/constants';
import { TripRouteValidationSchema } from '../../constants/validationSchema';
import DetailsTabStrips, { tabStripRendererByKeyFS } from './DetailsTabStrips';
import TripDetailsForm from './TripDetailsForm';

export interface TripDetailsPanelProps
  extends ExpandableTimelineV3LoadIdEvents {
  data: Trip;
  onClose: () => void;
  onUpdated: (data: any) => void;
  onDeleted: (data: any) => void;
  onCreated: (data: any) => void;
  defaultTab?: ETripPanelTabs;
  onRelayDeleted?: (data: Partial<RelayStopFormData>) => void;
  isGlobal?: boolean;
  autoExpanded?: boolean;
  onAction?: (status?: ELoadStatus) => void;
  onUpdatedTrips?: (data: {
    deletedIds: string[];
    updatedIds: string[];
  }) => void;
  onUpdateActivitiesCompleted?: () => void;
  onCreatedLoadHandler: (load: LoadDetailsResponse) => Promise<void>;
  onClickLoadIdHandler: (stopSolution: StopSolutionV3Prop) => void;
  setSecondaryPanelData?: () => void;
  readonly: boolean;
}

export default observer(function TripDetailsPanel({
  data,
  onClose,
  onUpdated,
  onDeleted,
  onCreated,
  defaultTab = ETripPanelTabs.ROUTE,
  onRelayDeleted,
  isGlobal,
  autoExpanded,
  onAction,
  onUpdatedTrips,
  onUpdateActivitiesCompleted: onUpdateActivitiesCompletedProp,
  onCreatedLoadHandler,
  setSecondaryPanelData,
  onClickLoadIdHandler: onClickLoadIdHandlerProp,
  readonly = false,
}: TripDetailsPanelProps) {
  const { trackPromise } = useDetailsPanelStore;
  const { getCompanyPreferences } = useRootStore();
  const { hasLoadViewPermission, hasFinancialPaymentView, has3DotsPermission } =
    useLoadPermission();
  const [tripData, setTripData] = useState<Partial<Trip> & Pick<Trip, 'id'>>(
    data
  );
  const { secondaryDetailsPanelData, setSecondaryDetailsPanelData } =
    useSecondaryDetailsPanel();
  const [httpErrors] = useState();
  const [selectedTab, setSelectedTab] = useState<ETripPanelTabs>(defaultTab);
  const [preferences, setPreferences] = useState<PreferencesDTO | null>(null);

  const { tripsStore } = useStores();
  const { showRenderFinance } = tripsStore;
  const theme: any = useTheme();
  const panelWrapperCustomStyles = {
    display: 'flex',
    width: '100%',
    padding: '0 5px 5px 0',
    height: '100%',
    flexDirection: 'column',
    background: theme.palette.leftMenuCollapsed.canvas,
    boxShadow: 'unset',
  };

  const tabStripRenderer = () => {
    const keys: ETripPanelTabs[] = [
      ...(hasLoadViewPermission ? [ETripPanelTabs.ROUTE] : []),
      ETripPanelTabs.FINANCE,
      ...(tripData?.brokerageTrip ? [ETripPanelTabs.CARRIER_PAYMENT] : []),
    ];
    return (
      <DetailsTabStrips
        defaultTab={selectedTab}
        onChange={handleTabStripChange}
        tripId={tripData?.id}
        keys={keys}
      />
    );
  };
  const entity = 'Trip';

  const getData = async (dispatchRevenue?: number) => {
    if (data.id) {
      const response = await trackPromise(
        tripService.getTripDetailById(
          new GetTripDetailByIdQueryParams({
            tripId: data.id,
          })
        ),
        ESecondaryDetailsPanelType.TRIP
      );
      if (!(response instanceof ServiceError)) {
        if (response) {
          setTripData(
            dispatchRevenue
              ? { ...response, dispatchRevenue: dispatchRevenue }
              : response
          );
        }
        tripsStore.updateSpecificRecord(data.id);
      }
    }
  };

  const handleTripAssignment = (eventData) => {
    if (
      eventData.trip.id === tripData.id &&
      (eventData.isStatusUpdated ||
        eventData.trip.tractor?.id !== tripData.assignment?.tractorId ||
        eventData.trip.trailer?.id !== tripData.assignment?.trailerId ||
        eventData.trip.carrierDetails?.id !== tripData?.carrierDetails?.id)
    ) {
      getData(); // get Data should be replaced with a new API which will provide only necessary data
    }
  };

  const handleLoadTripAction = (eventData: LoadTripActionData) => {
    const isEventCurrentTrip =
      (eventData.entity === 'Trip' && eventData.id === tripData.id) ||
      (eventData.entity === 'Load' && eventData.tripIds?.includes(tripData.id));
    if (isEventCurrentTrip) {
      if (eventData.toStatus === ELoadStatus.DELETED) {
        onClose?.();
      } else {
        getData(); // get Data should be replaced with a new API which will provide only necessary data
        onAction?.(eventData.toStatus as ELoadStatus);
      }
    }
  };

  const handleTripStopAction = async (eventData: EventTripStopActionData) => {
    if (
      eventData.tripId === tripData.id ||
      eventData?.updatedTripIds?.includes(tripData.id)
    ) {
      const response = await trackPromise(
        tripService.getTripDetailById(
          new GetTripDetailByIdQueryParams({
            tripId: tripData.id,
          })
        ),
        ESecondaryDetailsPanelType.TRIP
      );
      if (!(response instanceof ServiceError) && response?.id) {
        setTripData(response);
        // tripsStore.updateSpecificRecord(response);
        getData();
      } else {
        onClose?.();
      }
    } else {
      eventData.tripId && setTripData({ ...tripData, id: eventData.tripId });
    }
  };

  const handleUpdateProgress: EventCallbackFunction<EventUpdateProgressData> = (
    data
  ) => {
    if (data.toTypes.includes(ESecondaryDetailsPanelType.TRIP)) getData();
  };

  const handleUpdatedOverview: EventCallbackFunction<LoadOverviewDetails> = (
    data
  ) => {
    if (data.seqNumber !== tripData.seqNumber) getData();
  };

  const handleHoldOrReleaseLoad = (data: EventHoldLoadActionData) => {
    if (!data.tripIds) return;
    if (data.tripIds.includes(tripData.id)) {
      setTripData(
        (prev) =>
          ({
            ...prev,
            onHold: data.type === E3DotMenuType.MARK_AS_HOLD,
          } as unknown as Trip)
      );
    }
  };

  useEmit({
    [EventTypes.TRIP_ASSIGNMENT_CHANGE]: handleTripAssignment,
    [EventTypes.LOAD_TRIP_ACTION]: handleLoadTripAction,
    [EventTypes.TRIP_STOP_ACTION]: handleTripStopAction,
    [EventTypes.UPDATE_PROGRESS]: handleUpdateProgress,
    [EventTypes.LOAD_OVERVIEW_CHANGE]: handleUpdatedOverview,
    [EventTypes.HOLD_LOAD]: handleHoldOrReleaseLoad,
  });

  useEffect(() => {
    getData();
    if (!hasLoadViewPermission) {
      setSelectedTab(ETripPanelTabs.FINANCE);
    }
  }, [data.id]);

  const handleTabStripChange = (selected: ETripPanelTabs): void => {
    setSelectedTab(selected);
  };

  const isEdit = !!tripData?.id;
  const onFullscreen = (isFullscreen: boolean): void => {
    //set default tab when fullscreen
    const getDetaulTabFS = () => {
      if (hasLoadViewPermission) {
        if (isFullscreen) {
          if (isEdit) {
            return ETripPanelTabs.ROUTE;
          }
          return ETripPanelTabs.FINANCE;
        }
        return defaultTab;
      } else {
        return defaultTab === ETripPanelTabs.ROUTE
          ? ETripPanelTabs.FINANCE
          : defaultTab;
      }
    };
    setSelectedTab(getDetaulTabFS());
  };

  const renderCustomTitle = useMemo(() => {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        pl={1}
      >
        {tripData?.brokerageTrip && <BrokeredTripStatus />}
        {tripLoadCustomTitle({
          status: tripData.status,
          lastUpdatedStop: tripData.lastUpdatedStop,
          nonInvoiceable: false,
          onHold: !!tripData.onHold,
          onHoldNote: tripData.onHoldNote,
        })}
      </Stack>
    );
  }, [
    tripData?.status,
    tripData?.seqNumber,
    tripData?.lastUpdatedStop?.message,
    tripData?.brokerageTrip,
    tripData?.onHold,
    tripData?.onHoldNote,
  ]);

  const onUpdateActivitiesCompleted = (...params) => {
    getData();
    onUpdateActivitiesCompletedProp?.(...params);
  };

  const onClickLoadIdHandler = (stopSolution: StopSolutionV3Prop): void => {
    if (onClickLoadIdHandlerProp) {
      onClickLoadIdHandlerProp(stopSolution);
    } else {
      const loadId = stopSolution?.loadDetails[0]?.loadId;
      if (loadId) {
        setSecondaryDetailsPanelData({
          id: loadId,
          type: ESecondaryDetailsPanelType.LOAD,
          options: {
            isGlobal: true,
            onCreatedLoadHandler: onCreatedLoadHandler,
          },
        });
      }
    }
  };

  const onClickBrokeredHandler = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    item: TripV3DataProps,
    index: number
  ) => {
    const request = new BrokeredUnAssignParams({
      tripId: item.id,
      isBrokered: checked,
    });
    const response = await trackPromise(
      loadService.brokeredUnAssign(request),
      ESecondaryDetailsPanelType.TRIP
    );
    if (!(response instanceof ServiceError)) {
      getData();
      onUpdated?.({ ...tripData, brokerageTrip: checked });
    }
  };

  const onCloseHandler = (): void => {
    onClose?.();
  };

  const onUpdateHandler = async (
    selectedTab: ETripPanelTabs,
    data: any
  ): Promise<void> => {
    if (selectedTab === ETripPanelTabs.ROUTE) {
      onUpdated?.(data);

      const response = await tripService.updateTripDispatchRevenue(
        new UpdateDispatchRevenueQueryParams({
          dispatchRevenue: data.dispatchRevenue,
          tripId: data.id,
        })
      );
      tripsStore.updateMainData({
        deletedIds: [],
        updatedIds: [data.id],
      });
      if (!(!response || response instanceof ServiceError)) {
        getData(response as number);
      }
    }
  };

  const getValidationSchemaByTab = useCallback(
    (tab: ETripPanelTabs | string): yup.AnyObjectSchema | undefined => {
      if (tab == ETripPanelTabs.ROUTE) {
        return TripRouteValidationSchema;
      }
      return undefined;
    },
    []
  );

  useEffect(() => {
    if (getCompanyPreferences) {
      setPreferences(getCompanyPreferences);
    }
  }, [getCompanyPreferences]);

  const actionsRenderer = () => {
    return (
      <>
        {has3DotsPermission && (
          <ActionsSection
            entity={entity}
            tripData={tripData}
            assignedTractor={
              (tripData?.assignment?.tractorId && {
                id: tripData.assignment?.tractorId,
                name: tripData.assignment?.tractorName,
              }) as FormAsset
            }
            onCreatedLoadHandler={onCreatedLoadHandler}
            onStatusAction={(status) => {
              if (status === ELoadStatus.DELETED) onDeleted?.(tripData);
            }}
          />
        )}
        <WithSecondaryDetailsPanel
          secondaryDetailsPanelData={secondaryDetailsPanelData}
          setSecondaryDetailsPanelData={setSecondaryDetailsPanelData}
        />
      </>
    );
  };

  const CurrentTripContentRenderer = useCallback(
    () => (
      <TripDetailsForm
        onRelayDeleted={onRelayDeleted}
        selectedTab={selectedTab}
        trip={tripData}
        closeDetailsPanel={onCloseHandler}
        onUpdatedTrips={onUpdatedTrips}
        onClickLoadIdHandler={onClickLoadIdHandler}
        onClickBrokeredHandler={onClickBrokeredHandler}
        onUpdateActivitiesCompleted={onUpdateActivitiesCompleted}
        preferences={preferences}
      />
    ),
    [
      onClickLoadIdHandler,
      onClickBrokeredHandler,
      onCloseHandler,
      onRelayDeleted,
      onUpdateActivitiesCompleted,
      onUpdatedTrips,
      preferences,
      selectedTab,
      tripData,
    ]
  );

  const RouteContentRenderer = useCallback(() => {
    return (
      <TripDetailsForm
        selectedTab={ETripPanelTabs.ROUTE}
        trip={tripData}
        closeDetailsPanel={onCloseHandler}
        preferences={preferences}
        onClickBrokeredHandler={onClickBrokeredHandler}
      />
    );
  }, [onCloseHandler, preferences, tripData]);

  const FinanceContentRenderer = useCallback(() => {
    return (
      <TripDetailsForm selectedTab={ETripPanelTabs.FINANCE} trip={tripData} />
    );
  }, [tripData]);

  const CarrierPaymentContentRenderer = useCallback(() => {
    return (
      <TripDetailsForm
        selectedTab={ETripPanelTabs.CARRIER_PAYMENT}
        trip={tripData}
      />
    );
  }, [tripData]);

  const panelTitle = {
    panelTitle: `Trip Id: ${tripData?.seqNumber || ''}`,
    renderCustomTitle: () => renderCustomTitle,
  };

  const panelPropListFS: PanelFSProps[] = useMemo(() => {
    const panelPropList: PanelFSProps[] = [];

    if (hasLoadViewPermission) {
      panelPropList.push({
        data: tripData,
        entity: entity,
        panelTitle: '',
        onUpdate: (userData) => onUpdateHandler(ETripPanelTabs.ROUTE, userData),
        contentRenderer: RouteContentRenderer,
        panelWrapperCustomStyles: {
          ...panelWrapperCustomStyles,
          borderRadius: '18px 0 0 18px', //override border radius
        },
        tabStripRenderer: () => tabStripRendererByKeyFS(ETripPanelTabs.ROUTE),
        ...(showRenderFinance && {
          footerRenderer: () => undefined,
        }),
      });
    }
    if (hasFinancialPaymentView) {
      panelPropList.push({
        data: tripData,
        entity: entity,
        contentRenderer: FinanceContentRenderer,
        panelWrapperCustomStyles: {
          ...panelWrapperCustomStyles,
          borderRadius: '0 18px 18px 0',
        },
        panelTitle: '',
        onUpdate: (userData) =>
          onUpdateHandler(ETripPanelTabs.FINANCE, userData),
        tabStripRenderer: () => tabStripRendererByKeyFS(ETripPanelTabs.FINANCE),
      });
      if (tripData?.brokerageTrip) {
        panelPropList.push({
          data: tripData,
          entity: entity,
          contentRenderer: CarrierPaymentContentRenderer,
          panelWrapperCustomStyles: {
            ...panelWrapperCustomStyles,
            borderRadius: '0 18px 18px 0',
          },
          panelTitle: '',
          tabStripRenderer: () =>
            tabStripRendererByKeyFS(ETripPanelTabs.CARRIER_PAYMENT),
        });
      }
    }
    if (panelPropList?.[0]) {
      panelPropList[0] = {
        ...panelPropList[0],
        ...panelTitle,
      };
    }
    return panelPropList;
  }, [
    FinanceContentRenderer,
    RouteContentRenderer,
    hasFinancialPaymentView,
    hasLoadViewPermission,
    onUpdateHandler,
    panelWrapperCustomStyles,
    tripData,
  ]);

  return (
    <>
      <DetailsPanel
        panelType={ESecondaryDetailsPanelType.TRIP}
        isGlobal={isGlobal}
        autoExpanded={autoExpanded}
        data={tripData}
        actionButtonLabel={tripData?.id ? undefined : 'Add Trip'}
        entity={entity}
        {...panelTitle}
        onClose={onCloseHandler}
        onUpdate={(userData) => onUpdateHandler(selectedTab, userData)}
        onDelete={onDeleted}
        onCreate={onCreated}
        validationSchema={getValidationSchemaByTab(selectedTab)}
        contentRenderer={CurrentTripContentRenderer}
        tabStripRenderer={() => tabStripRenderer()}
        httpErrors={httpErrors}
        {...(selectedTab !== ETripPanelTabs.ROUTE && {
          footerRenderer: () => undefined,
        })}
        {...(selectedTab == ETripPanelTabs.ROUTE &&
          tripData?.brokerageTrip && {
            footerRenderer: () => undefined,
          })}
        {...(showRenderFinance && {
          footerRenderer: () => undefined,
        })}
        onFullscreen={onFullscreen}
        {...(isEdit && {
          fullscreen: {
            panelPropList: panelPropListFS,
          },
        })}
        actionsRenderer={!readonly ? actionsRenderer : undefined}
      />
    </>
  );
});
