import { Type } from 'class-transformer';
import { PaginatedList } from '../../../types';
import { SortResponse } from '../commonMixed';
import { QueryParams } from '../commonMixed/QueryParams';

export class GetTerminalListDTO extends QueryParams {
  constructor() {
    super(true);
  }
}
export class TerminalCenterDTO {
  lat!: number;
  lng!: number;
}
export class ContactPersonDTO {
  countryCode!: string;
  email!: string;
  extension!: string;
  fax!: string;
  faxCountryCode!: string;
  faxExtension!: string;
  firstName!: string;
  phone!: string;
}
export class TerminalAddressDTO {
  fullAddress!: string;
  address!: string;
  city!: string;
  state!: string;
  streetAddress!: string;
  streetAddress2!: string;
  zipcode!: string;
  @Type(() => TerminalCenterDTO)
  center!: TerminalCenterDTO;
}
export class CreateTerminalDTO extends QueryParams {
  @Type(() => TerminalAddressDTO)
  addressData!: TerminalAddressDTO;
  companyName!: string;
  @Type(() => ContactPersonDTO)
  contactPerson!: ContactPersonDTO;
  dot!: string;
  isPrimary!: boolean;
  mc!: string;
  organizationId!: number;
}
export class DeleteTerminalDTO extends QueryParams {
  id!: string;
}
export class CPDTO {
  firstName!: string;
  lastName!: string;
  phone!: string;
  countryCode!: string;
  extension!: string;
  email!: string;
  fax!: string;
  faxCountryCode!: string;
  faxExtension!: string;
}
export class TerminalContentDTO {
  id!: string;
  organizationId!: number;
  isPrimary!: boolean;
  companyName!: string;
  dot!: string;
  mc!: string;
  @Type(() => TerminalAddressDTO)
  addressData!: TerminalAddressDTO;
  @Type(() => CPDTO)
  contactPerson!: CPDTO;
  address!: string;
}
export class TerminalListDTO {
  @Type(() => TerminalContentDTO)
  content!: TerminalContentDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}
export class UpdateTerminalDTO extends QueryParams {
  @Type(() => TerminalAddressDTO)
  addressData!: TerminalAddressDTO;
  companyName!: string;
  @Type(() => ContactPersonDTO)
  contactPerson!: ContactPersonDTO;
  id!: string;
  dot!: string;
  isPrimary!: boolean;
  mc!: string;
  organizationId!: number;
}

export class GetLogoDTO extends QueryParams {
  organizationId!: number;
  terminalId!: string;
}

export class DeleteTerminalLogoDTO extends QueryParams {
  id!: string;
  terminalId!: string;
}

export class CreateTerminalRequestPayload extends QueryParams {
  addressData!: {
    streetAddress?: string;
    fullAddress?: string;
    city?: string;
    state?: string;
    zip?: string;
    zipcode?: string;
    address?: string;
  };
  companyName!: string;
  contactPerson!: {
    countryCode: string;
    email: string;
    extension: string;
    fax: string;
    faxCountryCode: string;
    faxExtension: string;
    firstName: string;
    phone: string;
  };
  dot!: string;
  id!: string | number;
  isPrimary!: boolean;
  mc!: string;
  constructor(dto?: any) {
    super();
    this.addressData = {
      zipcode: dto.companyAddress.zip,
      streetAddress: dto.companyAddress.streetAddress,
      state: dto.companyAddress.state,
      fullAddress: dto.companyAddress.fullAddress,
      city: dto.companyAddress.city,
      address: dto.companyAddress.address,
    };
    this.companyName = dto.terminalName;
    this.contactPerson = dto.contactPerson;
    this.dot = dto.dot;
    this.id = dto.id;
    this.isPrimary = dto.isPrimary;
    this.mc = dto.mc;
  }
}

export type AssociatedTerminalType = {
  id: string;
  organizationId: number;
  companyName: string;
  isVisible?: boolean;
  isChecked?: boolean;
  disabled: boolean;
};

export class GetPaginatedTerminalListQueryParams extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  sort!: string;
  constructor(dto: any) {
    super(true);
    Object.assign(this, dto);
  }
}

export class GetPaginatedTerminalListResponse extends PaginatedList {
  @Type(() => TerminalContentDTO)
  content!: TerminalContentDTO[];
}
