export const MapExternalComponentContainer = {
  position: 'absolute',
  top: '8px',
  right: '50px',
  zIndex: 1,
};

export const LoaderContainer = {
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

export const AutoCompleteStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'primary.main',
    backgroundColor: '#FFFFFF',
  },
  '& .MuiSvgIcon-root': {
    color: 'rgba(43, 100, 203, 1)',
    zIndex: 1,
  },
  '& .MuiInputBase-input': {
    color: 'rgba(43, 100, 203, 1)',
    zIndex: 1,
  },
};

export const UnlocatedDropdownContainer = {
  position: 'absolute',
  top: '37px',
  left: '37px',
  zIndex: 1,
  width: 270,
};

export const mapContainer = {
  height: '100%',
  width: '100%',
  borderRadius: '5px',
};
