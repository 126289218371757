export const FILE_NAME_TRUNCATE_SIZE = 10;

export const shortFileName = (
  fileName: string,
  size = FILE_NAME_TRUNCATE_SIZE
) => {
  if (fileName.length > size) {
    return [
      true,
      fileName.substring(0, size) + '...' + fileName.split('.').pop(),
    ];
  }
  return [false, fileName];
};
