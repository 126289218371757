import { Box, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import DetailsPanel from '../../../../common/DetailsPanel';
import { useStaticData } from '../../../../contexts/StaticDataContext';
import {
  CarrierDTO,
  CarrierStaticDataDTO,
  LookupExternalCarrierItem,
} from '../../../../models/DTOs/Carrier/Requests';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { ESecondaryDetailsPanelType } from '../../../../views/dispatch2/constants/types';
import { ECarrier3DotMenuType, ECarrierPanelTab } from '../../constants/types';
import { AddNewCarrierValidationSchema } from '../../constants/validationSchema';
import {
  createCarrierHandler,
  fetchCarrierOne,
  fetchStaticData,
  fromCarrierDTOToFormData,
  fromExternalCarrierToFormData,
  updateCarrierHandler,
} from '../../utils/api.utils';
import { CarrierStaticDataCell } from '../CarrierDataGrid/renderCell/CarrierStaticDataCell';
import { default as CarrierDetailsForm } from './CarrierDetailsForm';
import { CarrierDetailsPanelActionsSection } from './CarrierDetailsPanelActionsSection';
import DetailsTabStrips, { tabStripRendererByKeyFS } from './DetailsTabStrips';
import { ICreateCarrierFormData } from './Forms/AddNewCarrierForm/AddNewCarrierForm';

export type ICarrierDetailsPanelData = {
  id?: string; //id is carrier id
};

export interface CarrierDetailsPanelProps {
  data?: ICarrierDetailsPanelData | null;
  onClose: () => void;
  onUpdated?: (data: CarrierDTO) => void;
  onDeleted?: (data: CarrierDTO) => void;
  onCreated?: (data: CarrierDTO) => void;
  defaultTab?: ECarrierPanelTab;
  autoExpanded?: boolean;
  isGlobal?: boolean;
  on3DotMenuActionCallback?: (params: {
    type: ECarrier3DotMenuType;
    data: CarrierDTO;
    response: any;
  }) => void | Promise<void>;
}

const CarrierDetailsPanel = observer(
  ({
    data = null,
    onClose,
    onUpdated,
    onDeleted,
    onCreated,
    defaultTab = ECarrierPanelTab.OVERVIEW,
    autoExpanded = false,
    isGlobal = false,
    on3DotMenuActionCallback,
  }: CarrierDetailsPanelProps): JSX.Element => {
    const { trackPromise } = useDetailsPanelStore;
    const { staticData } = useStaticData();
    const [carrierDTO, setCarrierDTO] = useState<CarrierDTO | null>(null);

    const [staticDataDTO, setStaticDataDTO] =
      useState<CarrierStaticDataDTO | null>(null);
    const [externalCarrier, setExternalCarrier] =
      useState<LookupExternalCarrierItem | null>(null);

    const initStaticData = async () => {
      if (data?.id) {
        const response = await trackPromise(
          fetchStaticData(),
          ESecondaryDetailsPanelType.CARRIER
        );
        setStaticDataDTO(response);
      }
    };

    const initFetchCarrierOne = async () => {
      if (data?.id) {
        const response = await trackPromise(
          fetchCarrierOne(data.id),
          ESecondaryDetailsPanelType.CARRIER
        );
        setCarrierDTO(response);
      }
    };

    const [selectedTab, setSelectedTab] =
      useState<ECarrierPanelTab>(defaultTab);

    const handleTabStripChange = (selected: ECarrierPanelTab): void => {
      setSelectedTab(selected);
    };

    const theme: any = useTheme();
    const panelWrapperCustomStyles = {
      display: 'flex',
      width: '33.3%',
      padding: '0 5px 5px 0',
      height: '100%',
      flexDirection: 'column',
      background: theme.palette.leftMenuCollapsed.canvas,
      boxShadow: 'unset',
    };

    const tabStripRenderer = (keys: ECarrierPanelTab[]) => (
      <DetailsTabStrips
        keys={keys}
        defaultTab={defaultTab}
        onChange={handleTabStripChange}
      />
    );

    const isEdit = !!data?.id;
    const onFullscreen = (isFullscreen: boolean): void => {
      //set default tab when fullscreen
      setSelectedTab(
        isFullscreen && isEdit ? ECarrierPanelTab.OVERVIEW : defaultTab
      );
    };
    const entity = 'Carrier';

    const panelTitle = useMemo(() => {
      return isEdit ? `Carrier: ${carrierDTO?.name || ''}` : 'Add new Carrier';
    }, [isEdit, carrierDTO]);

    const getValidationSchemaByTab = useCallback(
      (tab: ECarrierPanelTab | string): yup.AnyObjectSchema | null => {
        if (tab == ECarrierPanelTab.OVERVIEW) {
          return AddNewCarrierValidationSchema;
        }
        return null;
      },
      []
    );

    const onUpdateHandler = async (tab: ECarrierPanelTab, formData: any) => {
      if (!carrierDTO) return;
      if (tab == ECarrierPanelTab.OVERVIEW) {
        const updatedCarrier = await trackPromise(
          updateCarrierHandler({
            carrierDTO,
            formData,
            staticData,
          }),
          ESecondaryDetailsPanelType.CARRIER
        );
        setCarrierDTO(updatedCarrier);
        onUpdated?.(updatedCarrier);
      }
    };

    const actionsRenderer = () => {
      return carrierDTO ? (
        <CarrierDetailsPanelActionsSection
          carrierDTO={carrierDTO}
          on3DotMenuActionCallback={(params: {
            type: ECarrier3DotMenuType;
            data: CarrierDTO;
            response: any;
          }) => {
            if (params.type === ECarrier3DotMenuType.DELETE) {
              onClose();
            } else {
              setCarrierDTO(params.data);
            }
            on3DotMenuActionCallback?.(params);
          }}
        />
      ) : (
        <></>
      );
    };

    const onCreateHandler = async (formData: ICreateCarrierFormData) => {
      const createdCarrier = await trackPromise(
        createCarrierHandler({
          formData,
          staticData,
        }),
        ESecondaryDetailsPanelType.CARRIER
      );
      onCreated?.(createdCarrier);
    };

    const panelData = useMemo(() => {
      return isEdit
        ? fromCarrierDTOToFormData(carrierDTO, staticDataDTO)
        : fromExternalCarrierToFormData(externalCarrier);
    }, [isEdit, carrierDTO, staticDataDTO, externalCarrier]);

    //Side effect begin
    useEffect(() => {
      initStaticData();
    }, []);

    useEffect(() => {
      initFetchCarrierOne();
    }, [data?.id]);
    //Side effect end

    const renderCustomTitle =
      isEdit && carrierDTO
        ? () => (
            <Box sx={{ pl: '5px' }}>
              <CarrierStaticDataCell
                staticDataKey={'carrierStatus'}
                staticDataValue={carrierDTO.status}
              />
            </Box>
          )
        : undefined;

    return (
      <>
        <DetailsPanel
          panelType={ESecondaryDetailsPanelType.CARRIER}
          data={panelData}
          actionButtonLabel={isEdit ? 'Save changes' : `Create`}
          entity={entity}
          panelTitle={panelTitle}
          renderCustomTitle={renderCustomTitle}
          onClose={onClose}
          onUpdate={(userData) => onUpdateHandler(selectedTab, userData)}
          onDelete={onDeleted}
          onCreate={onCreateHandler}
          isGlobal={isGlobal}
          autoExpanded={autoExpanded}
          contentRenderer={() => (
            <CarrierDetailsForm
              setExternalCarrier={setExternalCarrier}
              selectedTab={selectedTab}
              carrierDTO={carrierDTO}
              isCreatePanel={!isEdit}
            />
          )}
          {...(isEdit && {
            tabStripRenderer: () =>
              tabStripRenderer(Object.values(ECarrierPanelTab)),
          })}
          validationSchema={getValidationSchemaByTab(selectedTab)}
          {...(selectedTab !== ECarrierPanelTab.OVERVIEW && {
            footerRenderer: () => undefined,
          })}
          onFullscreen={onFullscreen}
          {...(isEdit && {
            fullscreen: {
              panelPropList: [
                {
                  data: panelData,
                  entity: entity,
                  panelId: ECarrierPanelTab.OVERVIEW,
                  panelTitle,
                  renderCustomTitle,
                  onUpdate: (userData) =>
                    onUpdateHandler(ECarrierPanelTab.OVERVIEW, userData),
                  contentRenderer: () => (
                    <CarrierDetailsForm
                      setExternalCarrier={setExternalCarrier}
                      selectedTab={ECarrierPanelTab.OVERVIEW}
                      carrierDTO={carrierDTO}
                      isCreatePanel={!isEdit}
                    />
                  ),
                  panelWrapperCustomStyles: {
                    ...panelWrapperCustomStyles,
                    borderRadius: '18px 0 0 18px', //override border radius
                  },
                  tabStripRenderer: () =>
                    tabStripRendererByKeyFS(ECarrierPanelTab.OVERVIEW),
                },
                // {
                //   data: panelData,
                //   entity: entity,
                //   panelId: ECarrierPanelTab.CONTRACT,
                //   validationSchema: getValidationSchemaByTab(
                //     ECarrierPanelTab.CONTRACT
                //   ),
                //   onUpdate: (userData) =>
                //     onUpdateHandler(ECarrierPanelTab.CONTRACT, userData),
                //   contentRenderer: () => (
                //     <CarrierDetailsForm
                //       setExternalCarrier={setExternalCarrier}
                //       selectedTab={ECarrierPanelTab.CONTRACT}
                //       carrierDTO={carrierDTO}
                //       isCreatePanel={!isEdit}
                //     />
                //   ),
                //   panelWrapperCustomStyles: {
                //     ...panelWrapperCustomStyles,
                //     borderRadius: '0 18px 18px 0', //override border radius
                //   },
                //   panelTitle: '',
                //   tabStripRenderer: () =>
                //     tabStripRendererByKeyFS(ECarrierPanelTab.CONTRACT),
                // },
                {
                  data: panelData,
                  entity: entity,
                  panelId: ECarrierPanelTab.DOCUMENT,
                  validationSchema: getValidationSchemaByTab(
                    ECarrierPanelTab.DOCUMENT
                  ),
                  onUpdate: (userData) =>
                    onUpdateHandler(ECarrierPanelTab.DOCUMENT, userData),
                  contentRenderer: () => (
                    <CarrierDetailsForm
                      setExternalCarrier={setExternalCarrier}
                      selectedTab={ECarrierPanelTab.DOCUMENT}
                      carrierDTO={carrierDTO}
                      isCreatePanel={!isEdit}
                    />
                  ),
                  panelWrapperCustomStyles: {
                    ...panelWrapperCustomStyles,
                    borderRadius: '0 18px 18px 0', //override border radius
                  },
                  panelTitle: '',
                  tabStripRenderer: () =>
                    tabStripRendererByKeyFS(ECarrierPanelTab.DOCUMENT),
                },
              ],
            },
          })}
          actionsRenderer={actionsRenderer}
        />
      </>
    );
  }
);

export default CarrierDetailsPanel;
