import { SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Controller } from 'react-hook-form';
import { BasicDateRangePicker } from '../../../ui-kit/components/DateRangePicker';

export const DateRangePickerForm = ({
  name,
  inputFormat,
  startText,
  toText,
  endText,
  control,
  sizes,
  disabled,
  onChangeCb,
  customStyles = { marginBottom: '20px', maxWidth: '270px' },
  variant = 'standard',
  defaultValue = [null, null],
  rest,
  clearable = true,
}: {
  name: string;
  startText: string;
  toText: string;
  endText: string;
  control: any;
  sizes?: {
    xs: number;
  };
  customStyles?: SxProps<Theme> | undefined;
  variant?: 'standard' | 'outlined' | 'filled';
  defaultValue?: DateRange<Date>;
  disabled?: boolean;
  inputFormat?: string;
  onChangeCb?: (date: DateRange<Date>, keyboardInputValue?: string) => void;
  rest?: any;
  clearable?: boolean;
}) => {
  return (
    <Controller
      render={({ field, field: { onChange } }) => {
        return (
          <Grid item xs={sizes?.xs} sx={{ ...customStyles }}>
            <BasicDateRangePicker
              {...field}
              startText={startText}
              toText={toText}
              endText={endText}
              disabled={disabled}
              variant={variant}
              onChange={(e) => {
                onChange(e);
                onChangeCb && onChangeCb(e);
              }}
              rest={rest}
              clearable={clearable}
              inputFormat={inputFormat}
            />
          </Grid>
        );
      }}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};
