import Box from '@mui/material/Box';
import {
  Divider,
  ListItemIcon,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { provideNameLogo } from './logoMap';
import { NumberToCurrency } from '../../../../constants/numberToCurrency';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import React from 'react';
import { ILoadsearchTableItem } from '../../loadTypes';

export const ToolTipOffer = React.memo(function ToolTipOffer({
  isEstimatedOfferValue,
  value,
  variableCost,
  fixedCost,
  estimatedProfit,
}: ILoadsearchTableItem['Offer']) {
  const currentTheme = useTheme();
  const theme = createTheme(currentTheme, {
    components: {
      // Name of the component
      MuiTooltip: {
        styleOverrides: {
          tooltipPlacementBottom: {
            marginTop: '0px',
          },
          tooltip: {
            backgroundColor: 'transparent',
          },
          tooltipArrow: {
            backgroundColor: '#fff',
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          root: {
            color: 'primary.main',
          },
        },
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Paper
          elevation={3}
          sx={{
            padding: 1,
          }}
        >
          <Typography
            sx={{
              p: 1,
              fontSize: '16px',
              lineHeight: '150%',
              letterSpacing: '0.15px',
              color: 'common.black',
            }}
          >
            Here’s the breakdown:
          </Typography>
          <Box sx={{ padding: 1, m: 1, color: 'black' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>
                {isEstimatedOfferValue ? 'Estimated Bid Amount' : 'Offer'}
              </Typography>
              <Typography
                fontWeight={isEstimatedOfferValue ? 400 : 800}
                color={isEstimatedOfferValue ? '#06989D' : '#1976d2'}
              >
                {(isEstimatedOfferValue ? '~' : '') + NumberToCurrency(value)}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Variable Costs</Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '150%',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'right',
                  letterSpacing: '0.15px',
                  color: 'error.main',
                }}
              >{`${NumberToCurrency('-' + variableCost)}`}</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Fixed Costs</Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '150%',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'right',
                  letterSpacing: '0.15px',
                  color: 'error.main',
                }}
              >{`${NumberToCurrency(
                Number(fixedCost) !== 0 ? '-' + fixedCost : fixedCost
              )}`}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 8px',
                gap: '16px',
                width: '284px',
                height: '46px',
                background: 'rgba(43, 100, 203, 0.08)',
                borderRadius: '3.67133px',
                flex: 'none',
                order: 0,
                alignSelf: 'stretch',
                flexGrow: 0,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '20px',
                    lineHeight: '150%',
                    letterSpacing: '0.15px',
                    color: 'primary.main',
                  }}
                >
                  Net Profit:
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '20px',
                    lineHeight: '150%',
                    letterSpacing: '0.15px',
                    color: +estimatedProfit < 0 ? 'error.main' : 'primary.main',
                  }}
                >{`${NumberToCurrency(estimatedProfit)}`}</Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </ThemeProvider>
    </>
  );
});

export const ToolTipBroker = React.memo(function ToolTipBroker({
  customerPreference,
  ...Broker
}: ILoadsearchTableItem['Broker'] &
  Pick<
    ILoadsearchTableItem['actions']['savedLoadsData'],
    'customerPreference'
  >) {
  const currentTheme = useTheme();
  const theme = createTheme(currentTheme, {
    components: {
      // Name of the component
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: 'primary.main',
          },
        },
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Paper
          elevation={3}
          sx={{
            width: '343px',
            height: 'auto',
            m: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '160%',
                letterSpacing: '0.15px',
                color: 'text.primary',
                flex: 'none',
                order: 0,
                flexGrow: 0,
              }}
            >
              Broker Info
            </Typography>
            <>
              {customerPreference === 'PREFERRED' && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '1rem',
                  }}
                >
                  <ThumbUpIcon sx={{ color: 'primary.main' }} />
                  {/* <ThumbDownOffAltOutlinedIcon sx={{ color: 'error.main' }} /> */}
                </Box>
              )}
              {customerPreference === 'PROHIBITED' && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '1rem',
                  }}
                >
                  {/* <ThumbUpOutlinedIcon sx={{ color: 'primary.main' }} /> */}
                  <ThumbDownIcon sx={{ color: 'error.main' }} />
                </Box>
              )}

              {/* {
                                data?.row?.actions?.savedLoadsData?.customerPreference === 'NONE' && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: '1rem'
                                        }}
                                    >
                                        <ThumbUpOutlinedIcon sx={{ color: 'primary.main' }} />
                                        <ThumbDownOffAltOutlinedIcon sx={{ color: 'error.main' }} />
                                    </Box>
                                )
                            } */}
            </>
          </Box>
          <Divider />
          <Box sx={{ padding: 1, m: 1, color: 'black' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '150%',
                  letterSpacing: '0.15px',
                  marginBottom: '5%',
                }}
              >
                Name
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '150%',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'right',
                  letterSpacing: '0.15px',
                  marginBottom: '5%',
                }}
              >
                {Broker?.value}
              </Typography>
            </Box>

            {Broker?.MC && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '150%',
                    letterSpacing: '0.15px',
                    marginBottom: '5%',
                  }}
                >
                  MC Number
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '150%',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'right',
                    letterSpacing: '0.15px',
                    marginBottom: '5%',
                  }}
                >
                  {Broker?.MC}
                </Typography>
              </Box>
            )}

            {Broker?.email && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '150%',
                    letterSpacing: '0.15px',
                    marginBottom: '5%',
                  }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '150%',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'right',
                    letterSpacing: '0.15px',
                    marginBottom: '5%',
                  }}
                >
                  {Broker?.email}
                </Typography>
              </Box>
            )}

            {Broker?.creditRating && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '150%',
                    letterSpacing: '0.15px',
                    marginBottom: '5%',
                  }}
                >
                  Credit Rating
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '150%',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'right',
                    letterSpacing: '0.15px',
                    marginBottom: '5%',
                  }}
                >
                  {Broker?.creditRating}
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </ThemeProvider>
    </>
  );
});

export const ToolTipLoadboard = React.memo(function ToolTipLoadboard({
  logo,
}: any) {
  const currentTheme = useTheme();
  const theme = createTheme(currentTheme, {
    components: {
      // Name of the component
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Paper
          elevation={3}
          sx={{
            width: '175px',
            height: '64px',
          }}
        >
          <Box sx={{ margin: '15% 5% 35% 30%' }}>
            <ListItemIcon aria-label="provider-name" className="provider-logo">
              {provideNameLogo[logo]}
            </ListItemIcon>
          </Box>
        </Paper>
      </ThemeProvider>
    </>
  );
});

export const ToolTipReloadScore = React.memo(function ToolTipReloadScore({
  value,
}: ILoadsearchTableItem['ReloadScore']) {
  const currentTheme = useTheme();
  const theme = createTheme(currentTheme, {
    components: {
      // Name of the component
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Paper
          elevation={3}
          sx={{
            width: '175px',
            height: '64px',
          }}
        >
          <Box
            sx={{
              margin: 'auto',
              padding: '10px',
            }}
          >
            <ListItemIcon aria-label="provider-name" className="provider-logo">
              {value > 3
                ? 'High probabity of finding load at the destination.'
                : value > 2
                ? 'Medium probabity of finding load at the destination.'
                : 'Low probabity of finding load at the destination.'}
            </ListItemIcon>
          </Box>
        </Paper>
      </ThemeProvider>
    </>
  );
});
