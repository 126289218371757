import { RECOMMENDED_RESOURCE_TYPE } from '../constants/optymization.const';
import {
  EPossibleResourceStatus,
  IPossibleResourceFilters,
} from '../constants/possibleResources.filters';
import { ERecommendedTripStatus } from '../constants/recommendedTrips.filters';
import { IRecommendedResource } from '../types/gantt.types';
import {
  IPossibleResourceStore,
  PossibleDriverResourceType,
  PossibleTripResourceType,
} from '../types/possibleResource.types';
import {
  IRecommendTrips,
  IRecommendedTripFilters,
} from '../types/recommendTrips.types';
import { geSandboxResourceEntities } from './operations.service';
import { convertPossibleDriversMapToList } from './possibleResources.service';

export const recommendedTripsFilter = {
  getAssignedDrivers: (resources: Array<IRecommendedResource>) => {
    return resources?.filter(
      (resource) =>
        resource?.resourceType === RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER
    );
  },
  getUnassignedDrivers: (resources: Array<IRecommendedResource>) => {
    return resources?.filter(
      (resource) =>
        resource?.resourceType === RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER
    );
  },
  getUnassignedTrips: (resources: Array<IRecommendedResource>) => {
    return resources?.filter(
      (resource) =>
        resource?.resourceType === RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP
    );
  },
};

export const filterRecommendationsByType = (
  resources: IRecommendTrips['drivers'],
  recommendedFilters: IRecommendedTripFilters
): IRecommendTrips['drivers'] => {
  const filters = recommendedFilters?.statuses?.map((filter) => filter.value);
  if (!filters?.length || filters?.length === 4) return resources;
  const tempResources = new Map(resources);
  if (!filters?.includes(ERecommendedTripStatus.Assigned))
    tempResources?.delete(RECOMMENDED_RESOURCE_TYPE.ASSIGNED_DRIVER);
  if (!filters.includes(ERecommendedTripStatus.UnassignedDrivers))
    tempResources?.delete(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_DRIVER);
  if (!filters.includes(ERecommendedTripStatus.UnassignedTrips))
    tempResources?.delete(RECOMMENDED_RESOURCE_TYPE.UNASSIGNED_TRIP);
  if (!filters.includes(ERecommendedTripStatus.brokeredTrips))
    tempResources?.delete(RECOMMENDED_RESOURCE_TYPE.BROKERED_TRIP);
  return tempResources;
};

export const filterTripsByDriver = (
  trips: IRecommendTrips['trips'],
  drivers: IRecommendTrips['drivers'],
  filters: IRecommendedTripFilters,
  rejectedResource?: IRecommendTrips['currentRejectedResource']
): IRecommendTrips['trips'] => {
  const newTripsMap = new Map();
  if (!trips || !drivers) return newTripsMap;
  const updatedDriversMap = filterRecommendationsByType(drivers, filters);
  const {
    assignedMap,
    brokeredTrips,
    unassignedDriversMap,
    unassignedTripsMap,
  } = geSandboxResourceEntities(updatedDriversMap);
  for (const [key, value] of trips) {
    if (
      assignedMap?.has(key) ||
      unassignedTripsMap?.has(key) ||
      unassignedDriversMap?.has(key) ||
      brokeredTrips?.has?.(key) ||
      rejectedResource?.rejectedResources?.has(key)
    ) {
      newTripsMap.set(key, value);
    }
  }
  return newTripsMap;
};

export const getRejectedPossibleResources = (
  resources: Array<PossibleDriverResourceType>
): Array<PossibleDriverResourceType> =>
  resources?.filter?.(
    (resource) => resource?.engineMultiTripOutput?.isRejected
  );

export const getLockedResources = (
  resources: Array<PossibleDriverResourceType>
): Array<PossibleDriverResourceType> =>
  resources?.filter?.((resource) => resource?.engineMultiTripOutput?.isLocked);

export const getPossibleResourcesByAvailability = (
  resources: Array<PossibleDriverResourceType>,
  type: string | 'top10' | 'top20' | 'all'
): Array<PossibleDriverResourceType> => {
  if (type === 'top10') return resources?.splice(0, 10);
  if (type === 'top20') return resources?.splice(0, 20);
  return resources;
};

export const getFilteredPossibleResources = (
  resources: Array<PossibleDriverResourceType>,
  filters: IPossibleResourceFilters
): Array<PossibleDriverResourceType> => {
  resources = getPossibleResourcesByAvailability(
    resources,
    filters.noOfResult?.key as string
  );
  if (filters?.statuses?.[0]?.value === EPossibleResourceStatus.ShowRejected)
    resources = getRejectedPossibleResources(resources);
  return resources;
};

export const getFilteredPossibleTrips = (
  resources: IPossibleResourceStore['possibleResourceDrivers'],
  trips: IPossibleResourceStore['possibleResourceTrips'],
  filters: IPossibleResourceFilters
): Array<PossibleTripResourceType> => {
  const resourcesArr = convertPossibleDriversMapToList(resources);
  const filteredResources = getFilteredPossibleResources(resourcesArr, filters);
  const filteredTrips: Array<PossibleTripResourceType> = [];
  for (const d of filteredResources) {
    if (trips?.get(d?.id))
      filteredTrips?.push(
        ...(trips?.get(d?.id) as Array<PossibleTripResourceType>)
      );
  }
  return filteredTrips;
};
