import { EmailFormType, InvoiceModel } from '../../models/InvoiceModel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '../../../../common/Ui/Checkbox';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormDialog from '../../../../common/Ui/FormDialog';
import { Button, ButtonGroup } from '@mui/material';

function InvoiceBulkDownloadForm() {
  const { control, setValue, watch } = useFormContext();
  const pdfComplexity = watch('pdfComplexity');
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant={'subtitle2'}
          sx={{ fontSize: '16px', fontWeight: '600' }}
        >
          Level of Detail
        </Typography>
      </Box>
      <Box sx={{ mb: 5 }}>
        <ButtonGroup
          aria-label="outlined primary button group"
          size="large"
          fullWidth={true}
        >
          <Button
            variant={pdfComplexity === 'SIMPLIFIED' ? 'contained' : 'outlined'}
            onClick={() => setValue('pdfComplexity', 'SIMPLIFIED')}
          >
            Summary
          </Button>
          <Button
            variant={pdfComplexity === 'MEDIUM' ? 'contained' : 'outlined'}
            sx={{ position: 'relative', left: ' -20px' }}
            onClick={() => setValue('pdfComplexity', 'MEDIUM')}
          >
            Detailed
          </Button>
          <Button
            variant={pdfComplexity === 'DETAILED' ? 'contained' : 'outlined'}
            sx={{ position: 'relative', left: ' -40px' }}
            onClick={() => setValue('pdfComplexity', 'DETAILED')}
          >
            Comprehensive
          </Button>
        </ButtonGroup>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography
          variant={'subtitle2'}
          sx={{ fontSize: '16px', fontWeight: '600' }}
        >
          Attachments
        </Typography>
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox disabled name={'sendInvoice'} label={'Invoice'} />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'rateConfirmation'}
          label={'Rate Confirmation'}
        />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'billOfLading'}
          label={'Bill of Lading'}
        />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'proofOfDelivery'}
          label={'Proof of Delivery'}
        />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox control={control} name={'other'} label={'Other'} />
      </Box>
    </>
  );
}

export function InvoiceBulkDownloadDialog({
  open,
  onAction,
  onClose,
  invoices,
}: {
  open: boolean;
  onAction: (data: EmailFormType) => void;
  onClose: () => void;
  invoices: InvoiceModel[];
}) {
  return (
    <FormDialog
      data={{
        sendInvoice: true,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
        pdfComplexity: 'DETAILED',
      }}
      titleText="Download Invoice"
      actionLabel="Download"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <InvoiceBulkDownloadForm />}
    />
  );
}
