import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { useFormContext } from 'react-hook-form';

export default function SaveChanges({
  handleSubmit,
}: {
  handleSubmit: () => void;
}) {
  const {
    formState: { isDirty },
  } = useFormContext();
  return (
    <ButtonImproved
      variant="contained"
      label=" Save Changes"
      onClick={handleSubmit}
      disabled={!isDirty}
      startIcon={<IconsComponent iconName="Save" source="MUIcons" />}
    />
  );
}
