import { EventTypes } from './EventTypes';

export default class BaseEvent {
  type: EventTypes;
  data?: any;
  constructor(type: EventTypes, data: any) {
    this.type = type;
    this.data = data;
  }
}
