import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import SimpleAccordion from '../../../../../subPages/invoices/components/AccordionBlue';
import {
  PAID_LOAD_STATUS_NAME,
  receivePaymentFormRequiredFields,
} from '../../../../../subPages/invoices/constants';
import {
  LineItem,
  ReceivePaymentFormRequiredFields,
} from '../../../../../subPages/invoices/models/InvoiceModel';
import { DeleteButtonIcon } from '../../../../../ui-kit/components/Assets';
import {
  amountFormat,
  fromMomentToIso,
  nullableDateYup,
  nullableNumberYup,
} from '../../../../../utils';
import { invoiceModule } from '../../../../PendoClassIDs/constants';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../../Permission';
import DatePicker from '../../../../Ui/FormDatePicker/FormDatePicker';
import FormDialog from '../../../../Ui/FormDialog';
import SliderForm from '../../../../Ui/Slider';
import Switch from '../../../../Ui/Switcher';
import TextField from '../../../../Ui/TextField/TextField';
import { useLoadPermission } from '../../../../hooks/useLoadPermission';
import FormContext from '../FinanceOverviewFormContext';
import { fromRecordListToTotalAmount } from './utils';
import { t } from 'i18next';

const hoverStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '8px',
  mb: 1,
};

const noneHoverStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  p: 1,
};
const totalPaymentSxProp = {
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
};

export function CarrierInvoiceReceivedPayment({
  requiredFields = {
    ...receivePaymentFormRequiredFields,
  },
}: {
  requiredFields?: ReceivePaymentFormRequiredFields;
}) {
  const { loadStatus, isCarrier, selectRecordData, allPaymentTypes } =
    useContext(FormContext);
  const isDisabledCarrier = isCarrier && selectRecordData?.status === 'CLOSED';
  const [addReceivePaymentDialogOpen, setAddReceivePaymentDialogOpen] =
    useState(false);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'receivedPayments',
  });

  const [receivedPayments = [], selectRecordList = []] = watch([
    'receivedPayments',
    'selectRecordList',
  ]);

  const invoiceTotal: number = useMemo(() => {
    return fromRecordListToTotalAmount({
      recordList: selectRecordList,
      allPaymentTypes,
    });
  }, [JSON.stringify(selectRecordList)]);

  const receivedPaymentsTotal: number = useMemo(() => {
    return receivedPayments.reduce(
      (accumulator: number, object: { amount: number }) => {
        return accumulator + +Number(object.amount);
      },
      0
    );
  }, [JSON.stringify(receivedPayments)]);

  const isPaid = loadStatus === PAID_LOAD_STATUS_NAME;

  const amountDue = useMemo(() => {
    return isPaid ? 0 : invoiceTotal - receivedPaymentsTotal;
  }, [isPaid, invoiceTotal, receivedPaymentsTotal]);

  const controlledFields = useMemo(() => {
    return fields.map((field, index) => {
      return {
        ...field,
        ...receivedPayments[index],
      };
    });
  }, [fields, receivedPayments]);

  const handlePaymentDelete = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    remove(index);
  };

  //Side effect begin
  useEffect(() => {
    setValue('amountDue', amountDue);
  }, [amountDue]);

  // this is set for validation
  useEffect(() => {
    setValue('amountDueTotal', amountDue);
  }, [invoiceTotal, receivedPaymentsTotal]);
  //Side effect end

  return (
    <Box className={'CarrierInvoiceReceivedPayment-root'} sx={{ flexGrow: 1 }}>
      <Box sx={{ mt: 1 }}>
        <Box
          sx={{
            backgroundColor: 'uncategorized.oldHeaderSecondary',
            borderRadius: 2,
          }}
        >
          <Box sx={noneHoverStyles}>
            <Box>
              <Typography variant={'subtitle2'} sx={totalPaymentSxProp}>
                Total Payment Amount
              </Typography>
            </Box>
            <Box>
              <Typography
                variant={'subtitle2'}
                sx={totalPaymentSxProp}
              >{`${amountFormat(invoiceTotal)}`}</Typography>
            </Box>
          </Box>
          {controlledFields.map((field, index) => {
            return (
              <Box key={index} sx={hoverStyles}>
                <SimpleAccordion
                  title={`Paid ${field.advanced ? '(Advance)' : ''}`}
                  subTitle={
                    fromMomentToIso(
                      moment(field.paymentDate),
                      'MM/DD/YYYY'
                    ) as string
                  }
                  renderLabel={(expanded) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant={'subtitle1'}>{`-${amountFormat(
                        field.amount
                      )}`}</Typography>
                      {expanded && (
                        <Permission
                          contains={[
                            AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICE_EDIT,
                          ]}
                        >
                          <Box
                            sx={{ ml: 2 }}
                            onClick={(e) => handlePaymentDelete(e, index)}
                          >
                            <DeleteButtonIcon />
                          </Box>
                        </Permission>
                      )}
                    </Box>
                  )}
                >
                  <CarrierHideableReceivePaymentForm
                    nameStart={`receivedPayments.${index}.`}
                    isPaid={isPaid}
                    requiredFields={requiredFields}
                  />
                </SimpleAccordion>
              </Box>
            );
          })}

          <Box
            sx={{
              ...noneHoverStyles,
              mt: 2,
              color: 'primary.main',
            }}
          >
            <Box>
              <Typography variant={'kpiValue'}>Payment Due</Typography>
            </Box>
            <Box>
              <Typography variant={'kpiValue'}>{`${amountFormat(
                amountDue
              )}`}</Typography>
            </Box>
          </Box>
          {'amountDue' in errors && (
            <Box sx={{ ...noneHoverStyles, justifyContent: 'flex-end' }}>
              <Typography sx={{ color: 'error.main' }} variant={'body2'}>
                {errors.amountDue.message}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {amountDue > 0 && (
        <Permission
          contains={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICE_EDIT]}
        >
          {!isDisabledCarrier && (
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                variant="buttonSmall"
                id={invoiceModule + 'ReceivePayment'}
                sx={{ color: 'primary.main', cursor: 'pointer' }}
                onClick={() => setAddReceivePaymentDialogOpen(true)}
              >
                + Add Payment Detail
              </Typography>
            </Box>
          )}
        </Permission>
      )}
      {addReceivePaymentDialogOpen && (
        <CarrierReceivePaymentDialog
          amountDue={amountDue}
          open={addReceivePaymentDialogOpen}
          onAction={(data) => {
            append(data);
            setAddReceivePaymentDialogOpen(false);
          }}
          onClose={() => setAddReceivePaymentDialogOpen(false)}
        />
      )}
    </Box>
  );
}

export function CarrierReceivePaymentDialog({
  open,
  onAction,
  onClose,
  amountDue,
}: {
  open: boolean;
  onAction: (data: {
    [key: string]: string | number | null | string[];
  }) => void;
  onClose: () => void;
  amountDue: number;
}) {
  return (
    <FormDialog
      data={{
        amount: null,
        paymentDate: new Date(),
        referenceNumber: null,
        advanced: false,
      }}
      validationSchema={yup.object().shape({
        amount: nullableNumberYup()
          .required('Amount is required.')
          .min(1)
          .max(amountDue, `Value must be between 1 and ${amountDue}`),
        referenceNumber: yup
          .string()
          .test('length', 'Cannot be longer than 50 characters', (val) =>
            !!val ? val.length <= 50 : true
          )
          .nullable(),
        advanced: yup.boolean(),
        paymentDate: nullableDateYup()
          .required('Payment Date is required.')
          .nullable(),
      })}
      titleText="Add Payment"
      actionLabel="Add Payment "
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => (
        <CarrierReceivePaymentForm amountDue={amountDue} />
      )}
    />
  );
}

export function CarrierHideableReceivePaymentForm({
  nameStart,
  isPaid,
  requiredFields = {
    ...receivePaymentFormRequiredFields,
  },
}: {
  nameStart: string;
  isPaid: boolean;
  requiredFields?: ReceivePaymentFormRequiredFields;
}) {
  const { hasFinancialInvoiceEdit } = useLoadPermission();
  const { control } = useFormContext();
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: 1, mr: 3 }}>
        <DatePicker
          iso
          disableFuture
          control={control}
          name={`${nameStart}paymentDate`}
          label={'Date'}
          disabled={!hasFinancialInvoiceEdit || isPaid}
        />
      </Box>
      <Box sx={{ flex: 1, mr: 3 }}>
        <TextField
          control={control}
          name={`${nameStart}referenceNumber`}
          label={t('referenceNo')}
          disabled={!hasFinancialInvoiceEdit || isPaid}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <TextField
          required={requiredFields.amount}
          type={'decimal'}
          control={control}
          name={`${nameStart}amount`}
          label={'Amount ($)'}
          onlyNumbers
          disabled={!hasFinancialInvoiceEdit || isPaid}
        />
      </Box>
    </Box>
  );
}

export function CarrierReceivePaymentForm({
  amountDue,
}: {
  amountDue: number;
}) {
  const { control } = useFormContext();

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 7, ml: 1 }}>
          <SliderForm
            control={control}
            name={'amount'}
            aria-label="Default"
            valueLabelDisplay="auto"
            min={1}
            max={amountDue}
          />
        </Box>
        <Box sx={{ flex: 2, ml: 2 }}>
          <TextField
            id={invoiceModule + 'ReceivePaymentAmount'}
            control={control}
            required
            name={'amount'}
            label={'Amount ($)'}
            onlyNumbers
          />
        </Box>
      </Box>
      <Box>
        <DatePicker
          required
          iso
          control={control}
          name={'paymentDate'}
          label={t('paymentDate')}
          disableFuture
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1, ml: 1 }}>
          <TextField
            id={invoiceModule + 'ReceivePaymentReferenceNumber'}
            control={control}
            name={'referenceNumber'}
            label={t('referenceNo')}
          />
        </Box>
        <Box sx={{ flex: 1, ml: 1 }}>
          <Switch
            id={invoiceModule + 'ReceivePaymentAdvance'}
            control={control}
            name={'advanced'}
            label={t('advance')}
            style={{ marginBottom: 0 }}
          />
        </Box>
      </Box>
    </>
  );
}
