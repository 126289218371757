import { useEffect, useMemo, useState } from 'react';
import {
  UpdateManualHosLocation,
  UpdateManualHosLocationRequest,
} from '../../models';
import LoadController from '../../subPages/loadsList/LoadController';
import { addressType } from '../../types';
import { getCurrentUser } from '../Permission/utils/permissionUtils';
import FormDialog from '../Ui/FormDialog';
import { UpdateHOSForm } from './components/Form/UpdateHOSForm';
import {
  MyLoadHoS,
  MyLoadHoSDriversHoS,
  UpdateHOSFormDataProps,
} from './types';
import { UpdateHOSValidationSchema } from './types/validationSchema';

export interface IHOSDialogloadData {
  driverGroupId: string;
  isShowHOSWarning?: boolean;
  ignoreHOS?: boolean;
  driverId: number; //Primary driver id of team
}
export interface UpdateHOSDialogProps {
  onClose: () => void;
  onActionResponse?: (response: any) => Promise<void>;
  loadData: IHOSDialogloadData;
}

export const UpdateHOSDialog = ({
  onClose,
  onActionResponse,
  loadData,
}: UpdateHOSDialogProps): JSX.Element => {
  const { userStorage } = getCurrentUser();
  const [myloadHoS, setMyloadHoS] = useState<MyLoadHoS | null>(null);
  const [isHOSLoading, setIsHOSLoading] = useState<boolean>(false);

  const getTripData = async () => {
    setIsHOSLoading(true);
    const hosLastLocation = await LoadController.instance().getHosLastLocation({
      ids: [loadData.driverGroupId],
    });
    if (hosLastLocation && hosLastLocation.mapping) {
      setMyloadHoS(Object.values(hosLastLocation.mapping)[0] || null);
    }
    setIsHOSLoading(false);
  };

  useEffect(() => {
    getTripData();
  }, [loadData]);

  const canUpdateHos = myloadHoS?.hosOutdated
    ? myloadHoS?.hosOutdated
    : !loadData?.ignoreHOS;
  const driversHosDataList: UpdateHOSFormDataProps[] = useMemo(() => {
    return (myloadHoS?.driversHos || []).map(
      (driversHos: MyLoadHoSDriversHoS) => {
        return {
          ...driversHos,
          enableHoS: myloadHoS?.hosOutdated,
          isRest: true,
          restDuration: driversHos?.restDuration || 34 * 60, //34h 00m
          dutyTimeRemaining: driversHos?.dutyTimeRemaining || 14 * 60, //14h 00m
          shiftDriveRemaining: driversHos?.shiftDriveRemaining || 11 * 60, //'11h 00m',
          weeklyHoursRemaining: driversHos?.weeklyHoursRemaining || 70 * 60, //70h 00m
          breakTimeRemaining: driversHos?.breakTimeRemaining || 8 * 60, //08h 00m
        } as UpdateHOSFormDataProps;
      }
    );
  }, [myloadHoS]);

  const location: addressType = useMemo(() => {
    return {
      fullAddress: myloadHoS?.location?.locationName,
      address: myloadHoS?.location?.locationName,
      city: myloadHoS?.location?.city,
      state: myloadHoS?.location?.state,
      streetAddress: myloadHoS?.location?.locationName,
      streetAddress2: '',
      zipcode: myloadHoS?.location?.zipcode || null,
      center: {
        lat: myloadHoS?.location?.lat,
        lng: myloadHoS?.location?.lng,
      },
    };
  }, [
    myloadHoS?.location?.city,
    myloadHoS?.location?.lat,
    myloadHoS?.location?.lng,
    myloadHoS?.location?.locationName,
    myloadHoS?.location?.state,
    myloadHoS?.location?.zipcode,
  ]);

  const onAction = async (data: any): Promise<void> => {
    const driverId =
      loadData?.driverId || myloadHoS?.location?.driversLocations[0]?.driverId;
    const driverIdToHos: { [property: string]: UpdateHOSFormDataProps } = {};
    data.driversHosDataList.forEach(
      (item: UpdateHOSFormDataProps, idx: number) => {
        const { enableHoS, isRest, ...restProps } = item;
        if (enableHoS) driverIdToHos[item.driverId] = restProps;
      }
    );
    const { center, ...restLocation } = data.location;
    const hosLocation: UpdateManualHosLocation = {
      city: restLocation.city,
      state: restLocation.state,
      address: restLocation.address,
      zipcode: restLocation.zipcode,
      lat: center.lat,
      lng: center.lng,
      driverId: driverId as number,
      organizationId: userStorage.organizationId,
    };
    const newdata = data?.enableLocation ? { location: hosLocation } : {};
    const requestData: UpdateManualHosLocationRequest = {
      driverIdToHos: driverIdToHos,
      groupId: loadData.driverGroupId,
      organizationId: userStorage.organizationId,
      ...newdata,
    };

    const response = await LoadController.instance().updateManualHosLocation(
      requestData
    );

    if (response) {
      onActionResponse?.(response);
      onClose();
    }
  };
  const onReset = () => {
    //
  };

  const contentRenderer = () => {
    return (
      <UpdateHOSForm
        values={{
          location,
          enableLocation: myloadHoS?.locationOutdated,
          driversHosDataList
        }}
        driversHosDataList={driversHosDataList}
        myloadHoS={myloadHoS}
        canUpdateHos={canUpdateHos}
        isLoading={isHOSLoading}
      />
    );
  };

  return (
    <FormDialog
      data={{
        driversHosDataList,
        enableLocation: myloadHoS?.locationOutdated,
        location: location,
      }}
      titleText="Update Location/HoS"
      actionLabel="Update"
      open={true}
      onAction={onAction}
      handleClose={onClose}
      onReset={onReset}
      contentRenderer={contentRenderer}
      validationSchema={UpdateHOSValidationSchema}
      isDisableResetBtn={true}
    ></FormDialog>
  );
};
