import {
  Alert,
  PaginatedAlertList,
} from '../../models/DTOs/CommonAlert/CommonAlert';
import {
  AlertListCalendarRequest,
  ExportSafetyAlertFilterPayload,
  ExportSafetyAlertFilterQueryParams,
  PaginatedAlertListRequest,
} from '../../models/DTOs/CommonAlert/Request';
import { docType } from '../../utils/Doc';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class ICommonAlertService extends Service {
  abstract getPaginatedAlertList(
    requestData: PaginatedAlertListRequest
  ): Promise<PaginatedAlertList | ServiceError>;

  abstract getMaintenanceHistoryListCalendar(
    requestData: AlertListCalendarRequest
  ): Promise<Alert[] | ServiceError>;

  abstract exportToExcel(
    queryParams: ExportSafetyAlertFilterQueryParams,
    payload: ExportSafetyAlertFilterPayload
  ): Promise<docType | ServiceError>;
}
