import { DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { CloseIcon } from '../../../../ui-kit/components/Assets';
import { useThemeResponsive } from '../../../hooks/useThemeResponsive';
import { FormDialogMode } from '../constants';

type DialogTitleProps = {
  titleText?: React.ReactNode;
  onClose: () => void;
  mode: FormDialogMode;
};

const Title = (props: DialogTitleProps) => {
  const { isMobile, theme } = useThemeResponsive();
  const { mode, titleText, onClose, ...other } = props;

  const isLightMode = mode === FormDialogMode.LIGHT;

  return (
    <DialogTitle
      sx={{
        padding: 0,
      }}
      {...other}
    >
      <Grid container justifyContent={'space-between'} alignItems="flex-start">
        <Typography
          variant="h6"
          className="dialogTitle_class"
          sx={{
            color: (theme: Theme) => {
              const {
                palette: {
                  primary: { contrastText, dark },
                  text: { primary },
                },
              } = theme;
              return isLightMode ? primary : contrastText;
            },
            width: 'calc(100% - 30px)',
          }}
        >
          {titleText}
        </Typography>
        {onClose ? (
          <IconButton onClick={onClose}>
            <CloseIcon
              color={isLightMode ? 'rgba(0, 0, 0, 0.54)' : '#ffffff'} // in the button is not taking theme color
              {...(isMobile && {
                width: '17px',
                height: '17px',
                color: theme?.palette?.primary?.main,
              })}
            />
          </IconButton>
        ) : null}
      </Grid>
    </DialogTitle>
  );
};

Title.defaultProps = {
  titleText: '',
};

export default Title;
