import { IDispatchOption } from '../../../types/DispatchTypes';
import StorageManager from '../../../StorageManager/StorageManager';
import {
  teamDriverServiceType,
  tractorService,
  userService,
} from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  GetDriverTeamRequest,
  GetNamesRequest,
  GetPrimaryDispatcherRequest,
  OptionType,
  TeamDriverFilterObject,
} from '../../../models';
import { PrimaryDispatcher } from '../../../models/DTOs/user/User';
import { PaginatedResult } from '../../../types';
import { ganttPageSize } from '../constants/dispatch';
import {
  EDispatchOperationType,
  EEmploymentType,
  EOperationMode,
} from '../constants/types';
import { getDriverNamesList } from '../../../views/settings/users/utils';
import LoadController from '../../../subPages/loadsList/LoadController';
import { t } from 'i18next';

export const getSortOptions = (): IDispatchOption[] => {
  return [
    { key: 'driverGroupName', value: t('getSortOptionsName') },
    { key: 'driverAvailability', value: t('getSortOptionsDriverAvailability') },
  ];
};

export const getOperationTypeOptions = (): IDispatchOption[] => {
  return [
    {
      key: EDispatchOperationType.LOCAL,
      value: t('GanttFilterOperationTypeLocal'),
    },
    {
      key: EDispatchOperationType.REGIONAL,
      value: t('GanttFilterOperationTypeRegional'),
    },
    {
      key: EDispatchOperationType.OTR,
      value: t('GanttFilterOperationTypeOTR'),
    },
    {
      key: EDispatchOperationType.CASUAL,
      value: t('GanttFilterOperationTypeCasual'),
    },
  ];
};
export const getOperationModeOptions = (): IDispatchOption[] => {
  return [
    { key: EOperationMode.TEAM, value: t('GanttFilterOperationModeTeam') },
    { key: EOperationMode.SOLO, value: t('GanttFilterOperationModeSolo') },
  ];
};
export const getEmploymentTypeOptions = (): IDispatchOption[] => {
  return [
    {
      key: EEmploymentType.OWNER_OPERATOR,
      value: t('GanttFilterEmploymentTypeOwnerOperator'),
    },
    {
      key: EEmploymentType.EMPLOYEE,
      value: t('GanttFilterEmploymentTypeEmployee'),
    },
  ];
};

export const getDateRangeOptions = (): IDispatchOption[] => {
  return [
    { key: 3, value: '3 Days' },
    { key: 5, value: '5 Days' },
    { key: 7, value: '7 Days' },
  ];
};

export const getYesNoOptions = (): IDispatchOption[] => {
  return [
    { key: true, value: 'Yes' },
    { key: false, value: 'No' },
  ];
};
export const getYesOnlyOptions = (): IDispatchOption[] => {
  return [
    { key: true, value: 'Yes' },
    { key: null as any, value: 'No' },
  ];
};

export const getDispatchDriverOptions = async (
  searchText: string | undefined,
  pageNumber: number
): Promise<PaginatedResult<OptionType> | undefined> => {
  const terminalIds =
    StorageManager.getItem('globalSelectedTerminals')?.map(
      (item: any) => item.id
    ) || [];

  const request = new GetDriverTeamRequest({
    pageNumber,
    pageSize: ganttPageSize,
    terminalIds: terminalIds,
    nameFilter: searchText,
    excludeStatuses: 0,
  });
  const response = await teamDriverServiceType.getDriverTeamFilterList(
    request,
    true
  );

  if (response instanceof ServiceError)
    return {
      content: [],
      last: true,
    };
  else
    return {
      ...response,
      content: response.content.map((item: TeamDriverFilterObject) => {
        return {
          value: item.driverName,
          key: item.driverId,
        };
      }),
    } as any;
};
export const getDispatchDriverOptionsV2 = async (
  searchText: string | undefined,
  pageNumber: number
): Promise<PaginatedResult<OptionType> | undefined> => {
  const terminalIds =
    StorageManager.getItem('globalSelectedTerminals')?.map(
      (item: any) => item.id
    ) || [];

  const response = await tractorService.getDriverGroups(
    searchText,
    pageNumber,
    terminalIds,
    true
  );

  if (response instanceof ServiceError)
    return {
      content: [],
      last: true,
    };
  else {
    return {
      ...response,
      content: response.content.map((item: any) => {
        return {
          value: item.displayName,
          key: item.drivers?.[0]?.id,
        };
      }),
    };
  }
};

export const getDriverNames = async (
  name: string,
  pageNumber: number,
  terminalIds?: string[],
  skipLoader?: boolean,
  pageSize = 50
) => {
  const response = await getDriverNamesList(
    name,
    pageNumber,
    terminalIds,
    skipLoader,
    pageSize
  );
  if (response instanceof ServiceError) {
    return [];
  } else {
    return {
      ...response,
      content: (
        response as { content: Array<{ id: number; name: string }> }
      )?.content?.map?.((item: any) => {
        return {
          value: item?.name,
          key: item?.id,
        };
      }),
    };
  }
};

export const getDispatcherList = async (
  searchText: string,
  pageNumber: number,
  terminalIds?: string[],
  excludeStatuses?: number[]
) => {
  const response = await LoadController.instance().getDispatcherSummary(
    searchText,
    pageNumber,
    terminalIds,
    excludeStatuses
  );
  if (response instanceof ServiceError) {
    return [];
  } else {
    return {
      ...response,
      content: (
        response as { content: Array<{ id: number; name: string }> }
      )?.content?.map?.((item: any) => {
        return {
          value: item?.name,
          key: item?.id,
        };
      }),
    };
  }
};

export const getDispatchSummaryOptions = async (
  searchText: string | undefined,
  pageNumber: number
): Promise<PaginatedResult<OptionType> | undefined> => {
  const request: GetPrimaryDispatcherRequest =
    new GetPrimaryDispatcherRequest();
  request.name = searchText || '';
  request.pageNumber = pageNumber;
  request.excludeRoleCodeList = ['DR'];
  request.sort = '+firstname,+lastname';

  const response = await userService.getPrimaryDispatchers(
    request,
    false,
    true
  );
  if (response instanceof ServiceError)
    return {
      content: [],
      last: true,
    };
  else
    return {
      ...response,
      content: response.content
        .map((user) => new PrimaryDispatcher(user))
        .map((item) => {
          return {
            value: item.fullName,
            key: item.id,
          };
        }),
    };
};

export const getPrefferedTrukstopsOptions = (
  searchText: string | undefined,
  pageNumber: number
) => getDispatchSummaryOptions(searchText, pageNumber);
