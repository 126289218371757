import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  Alert,
  PaginatedAlertList,
} from '../../models/DTOs/CommonAlert/CommonAlert';
import {
  AlertListCalendarRequest,
  ExportSafetyAlertFilterPayload,
  ExportSafetyAlertFilterQueryParams,
  PaginatedAlertListRequest,
} from '../../models/DTOs/CommonAlert/Request';
import { docType } from '../../utils/Doc';
import {
  ICommonAlertService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';

const basePathApi = '/web/asset/api/ax2/assets/alert';
const resources = {
  list: `${basePathApi}/list`,
  total: `${basePathApi}/total`,
  listCalendar: `${basePathApi}/list/calendar`,
  create: `${basePathApi}/create`,
  update: basePathApi,
  bulkDelete: `${basePathApi}/delete/bulk`,
  exportToExcel: `${basePathApi}/export-to-excel`,
};

export class CommonAlertService extends ICommonAlertService {
  @annotateNameAsync
  async getPaginatedAlertList(
    requestData: PaginatedAlertListRequest
  ): Promise<PaginatedAlertList | ServiceError> {
    try {
      return httpClient.get<PaginatedAlertList>(
        resources.list,
        requestData,
        PaginatedAlertList,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getMaintenanceHistoryListCalendar(
    requestData: AlertListCalendarRequest
  ): Promise<Alert[] | ServiceError> {
    try {
      return await httpClient.get<Alert[]>(
        resources.listCalendar,
        requestData,
        Alert,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async exportToExcel(
    queryParams: ExportSafetyAlertFilterQueryParams,
    payload: ExportSafetyAlertFilterPayload
  ): Promise<docType | ServiceError> {
    try {
      delete payload['organizationId'];
      const response = await httpClient.putRaw(
        resources.exportToExcel,
        queryParams,
        payload
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
