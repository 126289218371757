import { capitalize } from '@mui/material';
import { JSX } from 'react';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import { useTranslation } from 'react-i18next';

export default function AddNewButton({
  title,
  onClick,
  startIcon,
  style,
}: {
  title: string;
  onClick: () => void;
  startIcon?: JSX.Element;
  style?: any;
}) {
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <div style={{ display: 'flex' }}>
        <ButtonImproved
          startIcon={startIcon}
          label={`${t('add')} ${capitalize(title ?? '')}`}
          onClick={onClick}
          variant="contained"
          styleProps={style}
        />
      </div>
    );
  }
}
