import { ServiceError } from './helperTypes';
import { Service } from './Service';
import {
  GetRecurrenceDTO,
  GetRecurrenceRes,
  CreateRecurrenceDTO,
  DeleteRecurrenceDTO,
} from '../../models';

export abstract class IRecurrenceService extends Service {
  abstract getRecurrenceData(
    requestData: GetRecurrenceDTO,
    endURL: string
  ): Promise<GetRecurrenceRes | null | ServiceError>;

  abstract createRecurrenceData(
    payloadData: CreateRecurrenceDTO,
    endURL: string
  ): Promise<any | null | ServiceError>;

  abstract updateRecurrenceData(
    payloadData: CreateRecurrenceDTO,
    endURL: string
  ): Promise<any | null | ServiceError>;

  abstract deleteRecurrenceData(
    ids: number[],
    endURL: string
  ): Promise<null | undefined>;
}
