import {
  TemplateSummaryRequest,
  TemplateSummaryResponse,
  DeleteTemplateRequest,
  PaginatedLoadListRequest,
  PaginatedTemplateListRequest,
  TemplateNameRequest,
} from '../../models';
import { loadAndTripPageSize, prepareFiltersFromList } from '../../utils';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { templateService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import { TemplateSummary } from './models/TemplateSummary';
import { GridColDefSelf } from '../../types';

let instance: TemplateController | undefined;

export default class TemplateController {
  static instance(): TemplateController {
    if (!instance) {
      instance = new TemplateController();
    }
    return instance;
  }

  static destroy() {
    instance = undefined;
  }

  templates: TemplateSummary[];
  templateListRequest: PaginatedTemplateListRequest;
  listFetching!: boolean;
  lastPage!: boolean;
  total?: number;

  private constructor() {
    this.templates = [];
    this.templateListRequest = this.setupTemplateListRequest();
  }

  setupTemplateListRequest(): PaginatedTemplateListRequest {
    const req = new PaginatedLoadListRequest();
    req.pageNumber = 1;
    req.pageSize = loadAndTripPageSize;
    req.sort = '-startDateTime';
    return req;
  }

  getTemplateList = async (
    filters: any,
    callback: (list: TemplateSummary[]) => void,
    paging = 0
  ) => {
    if ((paging && this.lastPage) || this.listFetching) return;
    this.updateTemplateListRequestWithFilters(filters, paging);
    this.templateListRequest.pageNumber += paging;
    this.listFetching = true;
    const response = await templateService.getPaginatedTemplateList(
      this.templateListRequest
    );

    if (response instanceof ServiceError) {
      callback([]);
    } else {
      this.lastPage = response.last;
      this.total = response.totalElements;
      const templateList = response.content.map(
        (item) => new TemplateSummary(item)
      );
      this.templates = paging
        ? [...this.templates, ...templateList]
        : templateList;
      callback(this.templates);
    }
    this.listFetching = false;
  };

  updateTemplateListRequestWithFilters(newFilters: any, paging = 0) {
    const req = this.templateListRequest;

    if (!paging) {
      req.pageNumber = 1;
    }
    if (newFilters.sort) {
      req.sort = newFilters.sort;
    }

    req.terminalIds = newFilters.terminalIds;
    req.loadTemplateIds = prepareFiltersFromList(
      newFilters.templateNameFilter,
      'id'
    );
    req.customerIds = prepareFiltersFromList(newFilters.customerFilter, 'key');
    req.from = prepareFiltersFromList(newFilters.originFilter, 'value');
    req.to = prepareFiltersFromList(newFilters.destinationFilter, 'value');
  }

  templateNameFilter = async (searchText: string, pageNumber: number) => {
    const requestData = new TemplateNameRequest();
    requestData.templateName = searchText;
    requestData.pageNumber = pageNumber;
    requestData.pageSize = 25;
    const response = await templateService.getPaginatedTemplateList(
      requestData
    );

    if (response instanceof ServiceError) {
      return [];
    } else {
      const result: any = response;
      result.content = response.content.map((item: any) => ({
        templateName: item.templateName,
        id: item.id,
      }));
      return result;
    }
  };

  async getTemplateSummary(
    templateId: string
  ): Promise<TemplateSummaryResponse | ServiceError> {
    const requestData = new TemplateSummaryRequest();
    requestData.loadTemplateId = templateId;
    return await templateService.getTemplateSummary(requestData);
  }

  async updateTemplateListWithNewItem(
    templateId: string
  ): Promise<TemplateSummary[]> {
    const response = await this.getTemplateSummary(templateId);
    if (response instanceof TemplateSummaryResponse) {
      const templateSummary = new TemplateSummary(response);

      if (this.templates?.length) {
        const newTemplateIndex = this.templates.findIndex(
          (item) => item.id === templateSummary.id
        );
        if (newTemplateIndex < 0) {
          this.templates = [templateSummary, ...this.templates];
        } else {
          this.templates = this.templates.map((item, index) => {
            if (index !== newTemplateIndex) {
              return item;
            }
            return templateSummary;
          });
        }
      } else {
        this.templates = [templateSummary];
      }
    } else {
      // @TODO: AXL2-5386: Nvard - handle error case ( to be approved by PO)
      // either show some error message that will advice user to refresh the page or
      // get templatesmmary from newly created or updated template response
    }

    return this.templates;
  }
  deleteTemplate = async (
    templateId: string,
    callback: (list: TemplateSummary[]) => void
  ) => {
    const requestData = new DeleteTemplateRequest();
    requestData.loadTemplateId = templateId;
    const response = await templateService.deleteTemplate(requestData);
    const serviceName = 'templateDelete';
    if (response instanceof ServiceError) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName,
      });
    } else {
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName,
      });
      this.templates = this.templates.filter(({ id }) => id !== templateId);
      callback(this.templates);
    }
  };

  async exportTemplateList(
    filters,
    columns: GridColDefSelf[]
  ): Promise<object | null> {
    this.updateTemplateListRequestWithFilters(filters);
    this.templateListRequest.gridColumnMetadataList = columns.reduce(
      (exportColumns: string[], column) => {
        if (column.field !== 'action') exportColumns.push(column.field);
        return exportColumns;
      },
      []
    );
    const data = await templateService.exportTemplateListToExcel(
      this.templateListRequest
    );
    delete this.templateListRequest.gridColumnMetadataList;
    if (data instanceof ServiceError) {
      // error handling
    }
    return data;
  }
}
