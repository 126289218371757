import { userService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import {
  GetInactiveUserDriveURLRequest,
  GetInactiveUserDriverMetadataRequest,
} from '../../models';
import { UserDriverActivation } from '../../models/DTOs/user/User';
import { leftMenuContants, subMenuContants } from '../Ui/LeftMenu2.0/constants';

export const getCurrentPage = (path: string) => {
  const route: string = path || window.location.pathname;
  if (route === '/subscription') {
    return {
      icon: undefined,
      iconName: 'Subscriptions',
      id: 'subscription',
      name: 'Subscription',
      mainPage: false,
      redirectionRouteURL: route,
      fullheight: true,
    };
  }
  if (route === 'loads') {
    return {
      id: 'loads',
      icon: undefined,
      iconName: 'FactCheckOutlined',
      name: 'Loads',
      redirectionRouteURL: '/',
      fullheight: true,
    };
  }
  const mainMenuItem = leftMenuContants.find(
    (item) => item.redirectionPath === route
  );
  if (mainMenuItem) {
    const currentPage = {
      icon: mainMenuItem.icon,
      iconName: mainMenuItem.iconName,
      id: mainMenuItem.id,
      name: mainMenuItem.text,
      mainPage: undefined,
      redirectionRouteURL: mainMenuItem.redirectionPath,
      fullheight: mainMenuItem?.fullheight,
    };
    return currentPage;
  } else {
    const subMenuItem = subMenuContants[
      route.split('/')[1] as keyof typeof subMenuContants
    ]?.find((subItem: any) => subItem.redirectionPath === route);
    const currentPage = {
      iconName: subMenuItem?.iconName,
      id: subMenuItem?.id,
      name: subMenuItem?.text,
      mainPage: subMenuItem?.parent?.toUpperCase(),
      redirectionRouteURL: subMenuItem?.redirectionPath,
      fullheight: subMenuItem?.fullheight,
    };
    return currentPage;
  }
};

export const getInactiveDriverUserMetadata = async (
  id: number,
  key: string,
  organizationId: number,
  type: string
): Promise<UserDriverActivation | ServiceError | undefined> => {
  try {
    const request = new GetInactiveUserDriverMetadataRequest(
      id,
      key,
      organizationId
    );
    const result = await userService.getInactiveDriverUserMetadata(
      request,
      type
    );
    if (result instanceof UserDriverActivation) {
      return result;
    }
  } catch (error) {
    return error as ServiceError;
  }
};

export const activateUserDriver = (
  id: number,
  key: string,
  organizationId: number
): Promise<string | ServiceError> => {
  const request = new GetInactiveUserDriverMetadataRequest(
    id,
    key,
    organizationId
  );
  return userService.activateUserDriver(request);
};
export const activateUserURL = (
  id: number,
  key: string,
  organizationId: number,
  password: string,
  type: string
): Promise<string | ServiceError> => {
  const request = new GetInactiveUserDriveURLRequest(
    id,
    key,
    organizationId,
    password
  );
  return userService.activateUserURL(request, type);
};
