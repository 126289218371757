import { Box, IconButton, SvgIcon } from '@mui/material';
import { ReSequenceStopIcon } from '../icons';
import {
  classNamesTimeline,
  StyleFloatingReSequenceStopIconRoot,
} from '../styles';

export interface FloatingReSequenceStopIconProps {
  resequenceActions?: {
    resequenceUp: boolean;
    resequenceDown: boolean;
  };
  onClickResequenceUpHandler?: () => void;
  onClickResequenceDownHandler?: () => void;
}
export const FloatingReSequenceStopIcon = ({
  resequenceActions = {
    resequenceUp: true,
    resequenceDown: true,
  },
  onClickResequenceUpHandler,
  onClickResequenceDownHandler,
}: FloatingReSequenceStopIconProps): JSX.Element => {
  const hasResequenceIcon =
    resequenceActions?.resequenceUp || resequenceActions?.resequenceDown;

  return (
    <StyleFloatingReSequenceStopIconRoot
      className={classNamesTimeline.StyledFloatingReSequenceStopIconRoot}
      sx={{
        ...(!hasResequenceIcon && {
          marginLeft: 0,
          width: '20px',
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {resequenceActions?.resequenceUp && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onClickResequenceUpHandler?.();
            }}
          >
            <SvgIcon sx={{ width: '20px', height: '20px' }} inheritViewBox>
              <ReSequenceStopIcon />
            </SvgIcon>
          </IconButton>
        )}
        {resequenceActions?.resequenceDown && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onClickResequenceDownHandler?.();
            }}
            sx={{
              marginTop: '-15px',
            }}
          >
            <SvgIcon
              sx={{
                width: '20px',
                height: '20px',
                transform: 'rotate(180deg)',
              }}
              inheritViewBox
            >
              <ReSequenceStopIcon />
            </SvgIcon>
          </IconButton>
        )}
      </Box>
    </StyleFloatingReSequenceStopIconRoot>
  );
};
