import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { DotIcon } from '../../assets/icons';
import moment from 'moment';
import { NotificationItemProps } from './constants/interface';

export const Notification = ({
  item,
  onNotificationClicked,
}: NotificationItemProps) => {
  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
        width: '100%',
      }}
      onClick={() => onNotificationClicked(item, false)}
    >
      <Divider />
      <Stack direction="column" padding={3} width={'100%'} spacing={1}>
        <Stack
          direction="row"
          alignItems="center"
          width={'100%'}
          justifyContent={'space-between'}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent={'space-around'}
          >
            {!item.readStatus && <DotIcon color="red" width={8} height={8} />}
            <Typography
              color={item.readStatus ? 'text.primary' : 'primary.main'}
              align="left"
              fontSize={16}
              fontWeight={600}
            >
              {item.entityAxeleId.toString()}
            </Typography>
          </Stack>
          <Typography
            align="left"
            fontWeight={600}
            ml={2}
            sx={{
              color: 'text.secondary',
              fontSize: 14,
            }}
          >
            {item.recordDate ? moment(item.recordDate).format('LLL') : ''}
          </Typography>
        </Stack>
        <Typography
          align="left"
          fontWeight={400}
          sx={{
            color: 'text.secondary',
            fontSize: 14,
          }}
        >
          {item.message}
        </Typography>
      </Stack>
    </Box>
  );
};
