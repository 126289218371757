import React, { FC } from 'react';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { StyledButton, StyledButtonGroup } from '../gantt/Filters/styles';
import { Tooltip, Box } from '@mui/material';

interface IFullScreenExitButton {
  onClick?: () => void;
  styles?: object;
}

const FullScreenExitButton: FC<IFullScreenExitButton> = ({
  onClick,
  styles,
}) => {
  return (
    <StyledButtonGroup sx={styles}>
      <Tooltip title={'Minimize'} arrow placement="top">
        <Box>
          <StyledButton sx={{ height: '28px' }} onClick={onClick}>
            <FullscreenExitIcon
              fontSize="small"
              sx={{
                color: 'primary.main',
              }}
            />
          </StyledButton>
        </Box>
      </Tooltip>
    </StyledButtonGroup>
  );
};

export default FullScreenExitButton;
