import { MouseEvent } from 'react';
import { DeleteOutline } from '@mui/icons-material';
import Grid from '@mui/material/Grid/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import { FileRejection } from 'react-dropzone';

export const FileUnApproved = ({
  rootProps,
  onRemove,
  errors,
  removable = true,
}: {
  rootProps: any;
  onRemove: (event: MouseEvent<HTMLButtonElement>) => void;
  errors: FileRejection[];
  removable?: boolean;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      padding="12px 10px"
      sx={(theme) => ({
        borderRadius: '5px',
        background: `${theme.palette.error.dark}`,
      })}
      wrap="nowrap"
    >
      <Grid item xs={1.5} sx={{ mr: 3 }}>
        <ErrorIcon
          fontSize="large"
          sx={(theme) => ({
            color: `${theme.palette.primary.contrastText}`,
          })}
        />
      </Grid>
      <Grid item xs={8.5} spacing={1} zeroMinWidth {...rootProps}>
        <Typography
          fontFamily="Poppins"
          fontStyle="normal"
          fontWeight="600"
          fontSize="16px"
          color="primary.contrastText"
          noWrap
        >
          {errors[0].file.name}
        </Typography>
        <Typography
          fontFamily="Poppins"
          fontStyle="normal"
          fontWeight="400"
          fontSize="12px"
          color="primary.contrastText"
        >
          {errors[0].errors[0].message}
        </Typography>
      </Grid>
      {removable && (
        <Grid item xs={1}>
          <IconButton onClick={onRemove}>
            <DeleteOutline sx={{ color: 'primary.contrastText' }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
