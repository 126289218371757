import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import moment from 'moment';
import { reportModule } from '../../../../common/PendoClassIDs/constants';
import { MultipleAutocompleteFilter } from '../../../../common/Ui/MultipleAutocomplete';
import { RelativeDateRangeFilter } from '../../../../ui-kit/components/RelativeDateRange';
import Select from '../../../../ui-kit/components/Select';

export const FilterSection = ({
  selectedFilters,
  setSelectedFilters,
  allFilters,
  selectedFiltersConfig,
  handleFilterSelection,
  handleAddFilter,
  handleRemoveFilters,
}: {
  selectedFilters: object | any;
  setSelectedFilters: any;
  allFilters: object | any;
  selectedFiltersConfig: Array<any>;
  handleFilterSelection: (data: any, key: number, oldData: any) => void;
  handleAddFilter: () => void;
  handleRemoveFilters: (data: any, key: number) => void;
}) => {
  const renderFilter = (config: any) => {
    if (config.type === 'multipleAutoComplete') {
      return (
        <MultipleAutocompleteFilter
          id={reportModule + config.name}
          isCustomDefaultInputStyles={false}
          name={config.name}
          label={config.label}
          fieldName={config.fieldName}
          getOptions={config.getData}
          options={config.options}
          value={selectedFilters[config.name]}
          onChangeCb={(data: any, name: string) =>
            setSelectedFilters({ ...selectedFilters, [name]: data })
          }
          customStyles={{ width: '100%' }}
          variant={'outlined'}
        />
      );
    }
    if (config.type === 'RelativeDateRange') {
      if (!selectedFilters[config.name]) {
        setSelectedFilters({
          ...selectedFilters,
          [config.name]: {
            dateRange: [
              moment().subtract(1, 'months').toISOString(),
              moment().toISOString(),
            ],
            key: 'PeriodTimeCustom',
            name: 'Custom',
            type: 'CUSTOMIZE',
          },
        });
      }

      return (
        <RelativeDateRangeFilter
          id={`${reportModule}${config.name}`}
          column={{ ...config, width: '100%', optionWidth: '250px' }}
          defaultFilterValue={{ [config.name]: selectedFilters[config.name] }}
          onChangeCb={(selectedValues: any): void => {
            setSelectedFilters({
              ...selectedFilters,
              [config.name]: selectedValues,
            });
          }}
          field={config.name}
        />
      );
    }
    if (config.type === 'singleSelect') {
      return (
        <Select
          // id={`Report-${reportModule + config.name}`}
          options={config.options}
          value={selectedFilters[config.name]}
          variant={'outlined'}
          label={config.label}
          size="small"
          // styleProps={config.style || {}}
          fullWidth
          onChange={(val: any) => {
            setSelectedFilters({ ...selectedFilters, [config.name]: val });
          }}
        />
      );
    }
    return null;
  };
  return (
    <Box mt={'15px'}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          Filter On
        </Grid>
        <Grid item xs={3} textAlign={'center'}>
          Operator
        </Grid>
        <Grid item xs={4}>
          Values
        </Grid>
        <Grid item xs={1}></Grid>
        {selectedFiltersConfig?.map((filter, index) => {
          return (
            <>
              <Grid item xs={4}>
                <MuiSelect
                  disabled={filter?.isDefault}
                  id={`filter${index}`}
                  size="small"
                  value={filter?.name}
                  name={filter?.name}
                  fullWidth
                  variant="outlined"
                  onChange={(event: any) => {
                    handleFilterSelection(event.target, index, filter);
                  }}
                >
                  {[{ ...filter }, ...allFilters]?.map(
                    (filterInfo: { name: string; label: string }) => (
                      <MenuItem key={filterInfo?.name} value={filterInfo?.name}>
                        {filterInfo?.label}
                      </MenuItem>
                    )
                  )}
                </MuiSelect>
              </Grid>
              <Grid item xs={3} textAlign={'center'}>
                IN
              </Grid>
              <Grid item xs={4}>
                {renderFilter(filter)}
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  textAlign: 'center',
                  alignItems: 'center',
                }}
              >
                {!filter?.isDefault && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleRemoveFilters(filter, index)}
                  >
                    <CloseOutlined />
                  </IconButton>
                )}
              </Grid>
            </>
          );
        })}
      </Grid>
      {allFilters?.length > 0 && (
        <Grid item xs={4} marginTop={'10px'}>
          <Button
            variant="text"
            startIcon={<AddOutlined />}
            onClick={handleAddFilter}
          >
            Add New Filter
          </Button>
        </Grid>
      )}
    </Box>
  );
};
