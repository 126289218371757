import { Typography } from '@components/ui-kit/typography';
import { Divider, Stack } from '@mui/material';
import {
  AdditionalSummaryContainerStyles,
  VerticalDividerStyle,
} from '../../../style';
import { ICommodities } from '../../../types/types';

const CommoditySummary = ({ commodity }: { commodity: ICommodities }) => {
  if (!commodity?.description && !commodity?.volume) return <></>;
  return (
    <Stack
      direction={'row'}
      sx={AdditionalSummaryContainerStyles}
      width={'fit-content'}
    >
      {!!commodity?.description && (
        <>
          <Typography variant="body3" sx={{ wordBreak: 'break-word' }}>
            {commodity?.description}
          </Typography>
          {commodity?.length && commodity?.width && commodity?.height && (
            <Divider orientation="vertical" sx={VerticalDividerStyle} />
          )}
        </>
      )}

      {!!(commodity?.length && commodity?.width && commodity?.height) && (
        <>
          <Typography variant="body3">{`${commodity?.length ?? ''}L x ${
            commodity?.width ?? ''
          }W ${commodity?.height ?? ''}H`}</Typography>
          <Divider orientation="vertical" sx={VerticalDividerStyle} />
        </>
      )}

      {!!commodity?.volume && (
        <Typography variant="body3">{commodity?.volume} cu ft</Typography>
      )}
    </Stack>
  );
};

export { CommoditySummary };
