import { PaymentManagementStatus } from '../../../../common/Ui/StatusComponent/StatusComponent';
import {
  CreatePaymentDTO,
  GetPaginatedPaymentListParams,
  GetPaginatedPaymentListPayload,
  InvoicePaymentDetails,
  PaymentDetailsDTO,
  UpdatePaymentDTO,
} from '../../../../models/DTOs/Payment/DTOs';
import { ParseToDate, getFormattedDateForGrid } from '../../../../utils';
import { DateTimezoneUtils } from '../../../../utils/Timezone.utils';
import { ISettingsPagination } from '../../../trips/constants/types';
import { defaultCreatePaymentData, paymentMethods } from '../constants';
import {
  EPaymentStatus,
  ICreatePaymentForm,
  IPaymentDataGridFilters,
} from '../constants/types';
export const fromCreatePaymentFormToCreatePaymentDTO = ({
  formData,
  invoicePayments,
}: {
  formData: ICreatePaymentForm;
  invoicePayments: InvoicePaymentDetails[];
}): CreatePaymentDTO | UpdatePaymentDTO => {
  const payload = {
    id: formData?.id || undefined,
    paymentDate: ParseToDate(formData?.paymentDate || new Date()),
    paymentMethod: formData.paymentMethod?.value,
    paymentReferenceId: formData.paymentReferenceId,
    amountReceived: formData.amountReceived,
    memo: formData.memo,
    customerId: formData.customerId?.id,
    invoicePay:
      invoicePayments.map(({ invoiceId, amount, advance }) => ({
        invoiceId,
        amount,
        advance,
      })) || [],
  };
  if (!formData?.id) {
    return new CreatePaymentDTO(payload);
  }
  return new UpdatePaymentDTO(payload);
};

export interface IFromPaymentFilterToQuery {
  queryParams: GetPaginatedPaymentListParams;
  payload: GetPaginatedPaymentListPayload;
}

export const fromPaymentFilterToQuery = (
  settingsPagination: Partial<ISettingsPagination>,
  filters: IPaymentDataGridFilters
): IFromPaymentFilterToQuery => {
  return {
    queryParams: new GetPaginatedPaymentListParams({
      pageNumber: settingsPagination.pageNumber,
      pageSize: settingsPagination.pageSize,
      sort: filters?.sort ?? '+name',
    }),
    payload: new GetPaginatedPaymentListPayload({
      paymentIds: [],
      paymentSeqNumberList: filters.paymentSeqNumberList.map(
        ({ label }) => label
      ),
      paymentMethodList: filters.paymentMethodList.map(({ value }) => value),
      invoiceSeqNumberList: filters.invoiceSeqNumberList.map(
        ({ seqNumber }) => seqNumber
      ),
      referenceIds: filters.referenceIds.map(({ label }) => label),
      customerNameFilter: filters.customerNameFilter.map(({ label }) => label),
      paymentFromDate: DateTimezoneUtils.toStartDateISOString(
        filters.paymentDateFilter?.dateRange?.[0]
      ),
      paymentToDate: DateTimezoneUtils.toStartDateISOString(
        filters.paymentDateFilter?.dateRange?.[1]
      ),
      remainCredit: filters?.paymentStatusList
        ? filters?.paymentStatusList?.value == 'PAID'
          ? true
          : false
        : null,
    }),
  };
};

export const fromPaymentDetailsDTOToFormData = (
  data: PaymentDetailsDTO | null
): ICreatePaymentForm => {
  if (!data) return defaultCreatePaymentData;
  const paymentMethod =
    paymentMethods.find(({ value }) => value === data?.paymentMethod) || null;

  return {
    id: data?.id || undefined,
    paymentDate: data?.paymentDate
      ? getFormattedDateForGrid(data?.paymentDate, 'MM/DD/yyyy')
      : null,
    paymentMethod: paymentMethod,
    paymentReferenceId: data?.paymentReferenceId || null,
    amountReceived: data?.amountReceived
      ? Number(data?.amountReceived).toString()
      : null, //Important: must pass  number value as string
    memo: data?.memo || '',
    customerId: data?.customer || null,
    isAdvanced: data?.isAdvanced || null,
    invoicePayDTO: data?.invoicePayDTO || null,
    assignedDocuments: data?.assignedDocuments || null,
    paymentDocument: [],
  };
};

export const getPaymentManagementStatus = ({
  remainingCredit,
}: {
  remainingCredit: number;
}): EPaymentStatus => {
  //If remainingCredit > 0  == PAID else CLOSED
  return remainingCredit > 0 ? EPaymentStatus.PAID : EPaymentStatus.CLOSED;
};

export const getPaymentManagementStatusComponent = ({
  remainingCredit,
}: {
  remainingCredit: number;
}) => {
  return (
    <PaymentManagementStatus
      status={getPaymentManagementStatus({ remainingCredit })}
    />
  );
};
