import * as yup from 'yup';
import {
  APEX_PROVIDER_ID,
  OTHER_PROVIDER_ID,
  OTR_PROVIDER_ID,
  RTS_PROVIDER_ID,
  TAFS_PROVIDER_ID,
  TRIUMPH_PROVIDER_ID,
} from '../../../../../subPages/invoices/constants';
import {
  nullableNumberYup,
  nullableStringMaxValidation,
  nullableStringYup,
  phoneNumberValidation,
} from '../../../../../utils';
import { constantFactorText } from '../constants';

type keyCompareFactor = {
  [key: number]: TFactorDetails;
};
export type TFactorDetails = {
  providerId: number;
  providerName: string;
  displayStep: string | null;
  description: string | null;
  saveText: string | null;
  secondForm: boolean;
  secondFormFieldDisplay: boolean;
  isEdit?: boolean;
};
export const integrationFormFieldLabelText: keyCompareFactor = {
  1: {
    providerId: APEX_PROVIDER_ID,
    providerName: constantFactorText.apexCardName,
    displayStep: constantFactorText.apexFormStep,
    description: constantFactorText.apexCardDescription,
    saveText: constantFactorText.apexFormSaveText,
    secondForm: true,
    secondFormFieldDisplay: false,
  },
  2: {
    providerId: OTHER_PROVIDER_ID,
    providerName: constantFactorText.OFCCardName,
    displayStep: null,
    description: null,
    saveText: null,
    secondForm: false,
    secondFormFieldDisplay: false,
  },
  3: {
    providerId: TAFS_PROVIDER_ID,
    providerName: constantFactorText.TAFSCardName,
    displayStep: constantFactorText.TAFSFormStep,
    description: null,
    saveText: constantFactorText.TAFSFormSaveText,
    secondForm: true,
    secondFormFieldDisplay: true,
  },
  4: {
    providerId: TRIUMPH_PROVIDER_ID,
    providerName: constantFactorText.triumphCardName,
    displayStep: constantFactorText.triumphFormStep,
    description: constantFactorText.triumphCardDescription,
    saveText: constantFactorText.triumphFormSaveText,
    secondForm: true,
    secondFormFieldDisplay: false,
  },
  5: {
    providerId: OTR_PROVIDER_ID,
    providerName: constantFactorText.OTRCardName,
    displayStep: constantFactorText.OTRFormStep,
    description: null,
    saveText: constantFactorText.OTRFormSaveText,
    secondForm: true,
    secondFormFieldDisplay: true,
  },
  6: {
    providerId: RTS_PROVIDER_ID,
    providerName: constantFactorText.RTSCardName,
    displayStep: constantFactorText.RTSFormStep,
    description: null,
    saveText: constantFactorText.RTSFormSaveText,
    secondForm: true,
    secondFormFieldDisplay: true,
  },
};

export const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const addNewFactorValidationSchema = yup.object().shape({
  displayName: yup.string().required('Display Name  is required'),
  nameOnDocuments: yup.string().required('Name on Documents is required'),
  address: yup.string().required('Address is required'),
  email: nullableStringMaxValidation(50)
    .required('Email is required')
    .email('Email is not valid.')
    .matches(emailRegExp, 'Email is not valid.'),
  phoneNumber: yup
    .object()
    .required('Phone Number is required')
    .shape(
      {
        phone: phoneNumberValidation.required('Phone Number is required'),
        extension: nullableNumberYup().max(
          99999,
          'Extension should have 5 or less characters.'
        ),
      },
      [['phone', 'phone']]
    )
    .nullable(true),
  charge: nullableNumberYup(),
  username: yup.string().when('selectType', (selectType) => {
    if (
      selectType.isEdit &&
      selectType.providerId != TRIUMPH_PROVIDER_ID &&
      selectType.providerId != APEX_PROVIDER_ID &&
      selectType.providerId != OTHER_PROVIDER_ID
    ) {
      return nullableStringYup().required('Username is required');
    }
  }),
  password: yup.string().when('selectType', (selectType) => {
    if (
      selectType.isEdit &&
      selectType.providerId != TRIUMPH_PROVIDER_ID &&
      selectType.providerId != APEX_PROVIDER_ID &&
      selectType.providerId != OTHER_PROVIDER_ID
    ) {
      return nullableStringYup().required('password is required');
    }
  }),
  dot: yup.string().when('selectType', (selectType) => {
    if (selectType.isEdit && selectType.providerId === TRIUMPH_PROVIDER_ID) {
      return nullableStringYup().required('DOT Number is required');
    }
  }),
  mcNumber: yup.string().when('selectType', (selectType) => {
    if (selectType.isEdit && selectType.providerId === TRIUMPH_PROVIDER_ID) {
      return nullableStringYup().required('MC Number is required');
    }
  }),
  carrierName: yup.string().when('selectType', (selectType) => {
    if (selectType.isEdit && selectType.providerId === TRIUMPH_PROVIDER_ID) {
      return nullableStringYup().required('Carrier Name is required');
    }
  }),
  carrierEmail: nullableStringMaxValidation(50)
    .when('selectType', (selectType) => {
      if (selectType.isEdit && selectType.providerId === TRIUMPH_PROVIDER_ID) {
        return nullableStringYup().required('Email is required');
      }
    })
    .email('Email is not valid.')
    .matches(emailRegExp, 'Email is not valid.'),
  carrierAddress: yup.string().when('selectType', (selectType) => {
    if (selectType.isEdit && selectType.providerId === TRIUMPH_PROVIDER_ID) {
      return nullableStringYup().required('Carrier Address is required');
    }
  }),
});
type keyPhone = 'phoneNumber' | 'fax';
export const CreatePayLoadFactorPhone = (
  object: { [x: string]: string },
  name: keyPhone
) => {
  const List = Object.keys(object).map(function (key) {
    const keyName: string = key === 'phone' ? name : key;
    const Obj = {
      name: name === 'fax' && keyName != 'fax' ? 'fax' + keyName : keyName,
      value: object[key] || '',
    };
    return Obj;
  });
  return List;
};

export const CreatePayLoadFactor = (object: { [x: string]: string }) => {
  let list = Object.keys(object).map(function (key) {
    let Obj: object = {
      name: key,
      value: object[key],
    };
    if (
      (key === 'phoneNumber' || key === 'fax') &&
      object[key] &&
      object[key]
    ) {
      Obj = CreatePayLoadFactorPhone(object[key], key);
    }
    if (object[key]) {
      return Obj;
    }
  });
  list = list.flat();
  const results = list.filter((element) => {
    return element?.value !== undefined && element.value != '';
  });
  if (object['charge'] === 0) {
    const chargeObject = {
      name: 'charge',
      value: object['charge'],
    };
    results.push(chargeObject);
  }
  return results;
};

export const prepareFormDataFactor = (data: any[]) => {
  const result = data?.reduce(
    (obj: object, cur: { name: string; value: string }) => ({
      ...obj,
      [cur.name]: cur.value,
    }),
    {}
  );
  result.phoneNumber = {
    countryCode: result.countryCode,
    extension: result.extension,
    phone: result.phoneNumber,
  };
  result.fax = {
    countryCode: result.faxcountryCode,
    extension: result.faxextension,
    phone: result.fax,
  };
  return result;
};

export class TFactorAddPayload {
  data!: TFactorpayLoadObject;
  integrationType!: string;
  providerId!: number;
  isValid!: boolean | null;
  id?: number;
  archive!: boolean;
  organizationId!: number;
  isEdit!: boolean;
}

export type TFactorpayLoadObject = {
  displayName?: string;
  nameOnDocuments?: string;
  address?: string;
  email?: string;
  phoneNumber?: number;
  charge?: number;
  username?: string;
  password?: string;
  dot?: string;
  mcNumber?: number;
  carrierName?: string;
  carrierEmail?: string;
  carrierAddress?: string;
};
export enum EMenuAction {
  DEACTIVATE = 'Deactivate',
  ARCHIVE = 'Archive',
  DELETE = 'Delete',
  REAUTHENTICATE = 'Re-authenticate',
  MFAM = 'Manage Funding Accounts Mapping',
  MCM = 'Manage Customer Mapping',
  MDM = 'Manage Drivers Mapping',
}
