import { TooltipFooterContentType } from '@optym/gantt';
import {
  DRIVER_WARNINGS_BY_KEY,
  DriverGroupWarningType,
  LOAD_WARNINGS_BY_KEY,
  TripWarningType,
  WarningsInitialType,
} from '../../constants/gantt/ganttWarnings.const';
import { DelayIcon, DetentionIcon, WarningsIcon } from '../../static';
import {
  DriverWarningsArrayTypes,
  DriverWarningsType,
  EngineSandboxWarningsType,
  IDriverSchedule,
  LoadWarningType,
  ScheduleDriverType,
  ScheduleTripType,
  TripsWarningsType,
  WarningContentType,
  WarningsPropertyType,
} from '../../types/DispatchTypes';

export const mapDriverWarnings = ({
  drivers,
  warnings,
}: {
  drivers?: Array<ScheduleDriverType>;
  warnings: { [key: string]: DriverWarningsType };
}) => {
  if (!drivers?.length) return [];
  if (!(warnings && Object.keys(warnings)?.length)) return drivers;
  return drivers?.map((driver: ScheduleDriverType) => ({
    ...driver,
    warnings: warnings?.[driver.id],
  }));
};

export const mapTripWarnings = ({
  trips,
  warnings,
  isWarningByDriverId = false,
}: {
  trips: IDriverSchedule['trips'];
  warnings: { [key: string]: TripsWarningsType } | EngineSandboxWarningsType;
  isWarningByDriverId?: boolean;
}): IDriverSchedule['trips'] => {
  if (!trips?.size) return new Map();
  if (!(warnings && Object.keys(warnings)?.length)) return trips;
  const tripsMap = new Map();
  for (const [key, value] of trips) {
    if (!value?.length) continue;
    for (const v of value) {
      const tripWarnings = isWarningByDriverId
        ? (warnings as EngineSandboxWarningsType)?.[v.resourceId]?.[
            v.tripPrimaryId as string
          ]
        : (warnings as { [key: string]: TripsWarningsType })?.[
            v.tripPrimaryId as string
          ];
      const formattedTrip = {
        ...v,
        warnings: tripWarnings,
        isDelayed: tripWarnings?.warningsCount! > 0 || v?.onHold,
        warningsCount: tripWarnings?.warningsCount,
      };
      const existedTrip = tripsMap?.get(key);
      tripsMap?.set(
        key,
        existedTrip?.length ? [...existedTrip, formattedTrip] : [formattedTrip]
      );
    }
  }
  return tripsMap;
};

export const findWarningValueByName = (
  properties: LoadWarningType['properties'],
  name: string
) => properties?.find((p) => p.name === name)?.value ?? '';

export const getWarningsIconByType = (type: string) => {
  switch (type) {
    case TripWarningType.LOAD_DELAYS:
      return <DelayIcon />;
    case TripWarningType.LOAD_DETENTION:
      return <DetentionIcon />;
    default:
      return <WarningsIcon />;
  }
};

export const LOCATION_HOS_WARNING_CONFIG = {
  text: DRIVER_WARNINGS_BY_KEY?.driverHOSWarning,
  hyperlink: 'Fix now',
  hyperlinkStyles: { textAlign: 'end' },
  icon: getWarningsIconByType(''),
  type: DriverGroupWarningType.DRIVER_HOS_WARNING,
};

export const getDriverWarningsData = (driver: ScheduleDriverType) => {
  if (!driver?.warnings || Object.keys(driver.warnings).length === 0) return {};
  const warningsContent: Array<TooltipFooterContentType> = [];
  for (const warningKey of Object.keys(driver.warnings)) {
    const warnings = driver.warnings[warningKey];
    warnings.forEach((warning) => {
      const warningObj: any = {
        text: warning,
        icon: getWarningsIconByType(''),
      };
      if (warningKey === 'DRIVER_HOS' || warningKey === 'DRIVER_LOCATION') {
        warningObj.hyperlink = 'Fix now';
        warningObj.hyperlinkStyles = { textAlign: 'end' };
        warningObj.type = DriverGroupWarningType.DRIVER_HOS_WARNING;
      }
      warningsContent?.push(warningObj);
    });
  }
  return { content: warningsContent, containerStyles: { paddingRight: '3px' } };
};

export const getDriverWarnings = (
  driver: ScheduleDriverType
): { content: Array<TooltipFooterContentType> } | object => {
  if (!driver?.warnings) return {};
  const warningsContent: Array<TooltipFooterContentType> = [];
  if (
    (driver?.warnings?.driverHOSWarning && !driver?.ignoreHOSRestrictions) ||
    driver.isLocationOutdated
  ) {
    warningsContent?.push({ ...LOCATION_HOS_WARNING_CONFIG, data: driver });
  }
  for (const warning of Object.keys(driver?.warnings)) {
    if (warning in WarningsInitialType) {
      if (
        !driver?.warnings?.[warning as keyof DriverWarningsArrayTypes]?.length
      )
        continue;
      driver?.warnings?.[warning as keyof DriverWarningsArrayTypes]?.forEach(
        (w: WarningsPropertyType) => {
          warningsContent?.push({
            text: SafetyWarningsByType(w?.type, w?.properties),
            icon: getWarningsIconByType(w?.type),
          });
        }
      );
    }
  }
  return { content: warningsContent, containerStyles: { paddingRight: '3px' } };
};

export const SafetyWarningsByType = (
  type: string,
  warnings: { name: string; value: string }[]
): string => {
  switch (type) {
    case DriverGroupWarningType.SAFETY_DRIVER_ISSUE_CRITICAL:
      return `Critical (expired certificates): ${findWarningValueByName(
        warnings,
        'CERTIFICATE_NAME'
      )} certificate is expired by ${findWarningValueByName(
        warnings,
        'NUMBER_OF_DAYS'
      )} days`;
    case DriverGroupWarningType.SAFETY_ISSUE_WARNING:
      return `${findWarningValueByName(
        warnings,
        'CERTIFICATE_NAME'
      )} certificate renewal is due in ${findWarningValueByName(
        warnings,
        'NUMBER_OF_DAYS'
      )} days`;
    case DriverGroupWarningType.SAFETY_TRACTOR_MAINTENANCE_CRITICAL:
    case DriverGroupWarningType.SAFETY_TRAILER_MAINTENANCE_CRITICAL:
      return `Critical (expired maintenance): ${findWarningValueByName(
        warnings,
        'MAINTENANCE_NAME'
      )} preventive maintenance is overdue by ${findWarningValueByName(
        warnings,
        'NUMBER_OF_DAYS'
      )} days`;
    case DriverGroupWarningType.SAFETY_TRACTOR_MAINTENANCE_WARNING:
    case DriverGroupWarningType.SAFETY_TRAILER_MAINTENANCE_WARNING:
      return `Warning (due soon): ${findWarningValueByName(
        warnings,
        'MAINTENANCE_NAME'
      )} preventive maintenance is due in ${findWarningValueByName(
        warnings,
        'NUMBER_OF_DAYS'
      )} days`;
    case DriverGroupWarningType.SAFETY_HOS:
      return `HOS and/or location is outdated`;
    case DriverGroupWarningType.PLANNING_NEED_LOAD:
      return `Driver needs a load soon`;
    case DriverGroupWarningType.INCOMP_DRIVER_TRACTOR_WARNING:
      return DRIVER_WARNINGS_BY_KEY[
        DriverGroupWarningType.INCOMP_DRIVER_TRACTOR_WARNING
      ]?.[findWarningValueByName(warnings, 'INCOMPATIBILITY_NAME')];
    case DriverGroupWarningType.INCOMP_DRIVER_TRAILER_WARNING:
      return DRIVER_WARNINGS_BY_KEY[
        DriverGroupWarningType.INCOMP_DRIVER_TRAILER_WARNING
      ]?.[findWarningValueByName(warnings, 'INCOMPATIBILITY_NAME')];
    default:
      return '';
  }
};

export const getTripWarnings = (
  trip: ScheduleTripType
): { content: Array<WarningContentType> } | object => {
  if (!trip.warnings || !Object.keys(trip?.warnings).length) return {};
  const warningsContent: Array<WarningContentType> = [];
  for (const warningKey of Object.keys(trip.warnings)) {
    if (warningKey !== 'warningsCount') {
      const warnings = trip.warnings[warningKey];
      if (warnings?.length) {
        // fix optimization warnings api and update format as per new dispatch warnings schema.
        if (typeof warnings?.[0] !== 'string') {
          // generate trip warnings if trips old warnings format is used. eg: in loadai popup
          return generateTripWarningsForLoadai(trip);
        }
        const icon =
          warningKey === 'DELAY'
            ? TripWarningType.LOAD_DELAYS
            : TripWarningType.LOAD_DETENTION;
        warnings.forEach((warning) => {
          warningsContent?.push({
            text: warning,
            icon: getWarningsIconByType(icon),
          });
        });
      }
    }
  }
  return { content: warningsContent, containerStyles: { paddingRight: '3px' } };
};

export const generateTripWarningsForLoadai = (
  trip: ScheduleTripType
): { content: Array<WarningContentType> } | object => {
  if (!trip?.warnings?.loadWarnings?.length) return {};
  const warningsContent: Array<WarningContentType> = [];
  for (const warning of trip?.warnings?.loadWarnings) {
    if (warning?.type === TripWarningType?.LOAD_DELAYS) {
      const delayStops = findWarningValueByName(
        warning?.properties,
        'LOAD_STOPS_NAME'
      )?.split(', ');
      delayStops?.forEach((stop) =>
        warningsContent?.push({
          text: `${LOAD_WARNINGS_BY_KEY[warning?.type]} ${stop}/${
            trip?.warnings?.numberOfStops
          }`,
          icon: getWarningsIconByType(warning?.type),
        })
      );
      continue;
    }
    warningsContent?.push({
      text: LOAD_WARNINGS_BY_KEY[warning?.type],
      icon: getWarningsIconByType(warning?.type),
    });
  }
  return { content: warningsContent, containerStyles: { paddingRight: '3px' } };
};
