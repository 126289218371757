import EventEmitter from '../../EventEmitter/EventEmitter';
import { EventTypes } from '../../EventEmitter/EventTypes';
import { DeleteButtonIcon } from '../../ui-kit/components/Assets';
import { IDetailsPanelThreeDotMenuOption } from './components/ThreeDotMenu';

export const getFullName = (entity: {
  firstName: string;
  lastName: string;
}): string => {
  return `${entity.firstName} ${entity.lastName}`;
};

export const getTitle = (entity: {
  id: string;
  firstName: string;
  lastName: string;
}) => `${getFullName(entity)} [${entity.id}]`;

export const getDeleteMenuOption = ({
  entity,
  disableDelete,
  onClickHandler,
}: {
  entity: string;
  onClickHandler: () => void;
  disableDelete: boolean;
}): IDetailsPanelThreeDotMenuOption => {
  const deleteMenuOption: IDetailsPanelThreeDotMenuOption = {
    label: `Delete ${entity}`,
    icon: DeleteButtonIcon,
    disabled: disableDelete,
    onClickHandler: onClickHandler,
  };
  return deleteMenuOption;
};

export class DirtyDetailsPanelManager {
  static isDirty = false;
  static panelName: string | null = null; //@TODO : Sargis Remove after load panels refactoring

  static shouldShake() {
    EventEmitter.send({ type: EventTypes.PANEL_SHOULD_SHAKE, data: true });
  }

  static isShouldPanelShake(): boolean {
    if (this.isDirty) {
      this.shouldShake();
      return true;
    }
    return false;
  }
}

const AsyncFunction = (async () => {}).constructor;
export function isAsyncFunction(fn) {
  return fn instanceof AsyncFunction;
}
