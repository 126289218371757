import { useFormContext } from 'react-hook-form';
import DatePicker from '../../../../common/Ui/FormDatePicker/FormDatePicker';
import { MultipleAutocompleteForm } from '../../../../common/Ui/MultipleAutocomplete';
import Switch from '../../../../common/Ui/Switcher';
import TractorController from '../../../../subPages/tractors/Controller';
import TrailerController from '../../../../subPages/trailers/TrailerController';
import { getGridCompanyTimezoneSettings } from '../../../../utils/Timezone.utils';
import {
  getStatuses,
  getTransitState,
  statusOptions,
  tractorDatesFiltersFormConfig,
  transitStateOptions,
} from './TractorViewForms';
import { t } from 'i18next';
import { RelativeDateRangeUtils } from '../../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
const columns = [
  { name: 'trailerName', label: 'Trailer #', show: true, disabled: true },
  { name: 'status', label: 'Status', show: true, disabled: false },
  { name: 'driver', label: 'Driver', show: true, disabled: false },
  { name: 'activeLoad', label: 'Active Load', show: true, disabled: false },
  { name: 'terminal', label: 'Terminal', show: true, disabled: false },
  { name: 'vin', label: 'Vin #', show: true, disabled: false },
  {
    name: 'licensePlateNumber',
    label: 'Reg. Plate #',
    show: true,
    disabled: false,
  },
  {
    name: 'validTill',
    label: 'Reg. Expiry',
    show: true,
    disabled: false,
  },
  { name: 'lastLocation', label: 'Last Location', show: true, disabled: false },
  {
    name: 'lastUpdated',
    label: 'Last Updated At',
    show: true,
    disabled: false,
  },
  {
    name: 'locationSource',
    label: 'Location Source',
    show: true,
    disabled: false,
  },
  {
    name: 'trailerType',
    label: 'Type',
    show: true,
    disabled: false,
  },
  {
    name: 'ownershipType',
    label: 'Ownership Type',
    show: true,
    disabled: false,
  },
  { name: 'ownedCompany', label: 'Owned By', show: true, disabled: false },
  { name: 'warning', label: 'Warning', show: true, disabled: false },
  { name: 'critical', label: 'Critical', show: true, disabled: false },
];

export const trailerAutoCompleteFiltersFormConfig = [
  {
    key: 1,
    name: 'nameFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TrailerController.instance().getTrailerByNameSearch(
        searchText,
        pageNumber
      ),
    label: 'Name',
    default: true,
  },
  {
    key: 2,
    name: 'statusFilter',
    fieldName: 'value',
    getData: getStatuses,
    label: 'Status',
    default: true,
  },
  {
    key: 3,
    name: 'inTransitFilter',
    fieldName: 'value',
    getData: getTransitState,
    label: 'Trailer In Transit',
    default: true,
  },
  {
    key: 4,
    name: 'safetyIssueFilter',
    fieldName: 'value',
    getData: () => TractorController.instance().getSafetyIssueTypeOptions(),
    label: 'Safety Issues',
    default: true,
  },
  {
    key: 5,
    name: 'driverGroupFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getDriverGroupsAsAnOptionType(
        searchText,
        pageNumber
      ),
    label: 'Driver name',
    default: false,
  },
  {
    key: 6,
    name: 'vinFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TrailerController.instance().getVinList(searchText, pageNumber),
    label: 'Vin #',
    default: false,
  },
  {
    key: 7,
    name: 'licensePlateFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TrailerController.instance().getLicensePlateList(searchText, pageNumber),
    label: 'Reg Plate',
    default: false,
  },
];

export const getTrailerFiltersFormConfig = () => [
  {
    key: 1,
    name: 'nameFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TrailerController.instance().getTrailerByNameSearch(
        searchText,
        pageNumber
      ),
    label: 'Name',
    default: true,
    type: 'MultipleAutocomplete',
  },
  {
    key: 2,
    name: 'statusFilter',
    fieldName: 'value',
    options: statusOptions,
    label: 'Status',
    default: true,
    type: 'ButtonGroup',
    multiple: true,
  },
  {
    key: 3,
    name: 'inTransitFilter',
    fieldName: 'value',
    fieldValue: 'key',
    options: transitStateOptions,
    label: 'Trailer In Transit',
    default: true,
    type: 'ButtonGroup',
    multiple: true,
    immutableModel: true,
  },
  {
    key: 4,
    name: 'safetyIssueFilter',
    fieldName: 'value',
    getData: () => TractorController.instance().getSafetyIssueTypeOptions(),
    label: 'Safety Issues',
    default: true,
    type: 'MultipleAutocomplete',
  },
  {
    key: 5,
    name: 'driverGroupFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TractorController.instance().getDriverGroupsAsAnOptionType(
        searchText,
        pageNumber
      ),
    label: 'Driver name',
    default: false,
    type: 'MultipleAutocomplete',
  },
  {
    key: 6,
    name: 'vinFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TrailerController.instance().getVinList(searchText, pageNumber),
    label: 'Vin #',
    default: false,
    type: 'MultipleAutocomplete',
  },
  {
    key: 7,
    name: 'licensePlateFilter',
    fieldName: 'value',
    getData: (searchText: string | undefined, pageNumber: number) =>
      TrailerController.instance().getLicensePlateList(searchText, pageNumber),
    label: 'Reg Plate',
    default: false,
    type: 'MultipleAutocomplete',
  },
  {
    key: 8,
    name: 'regExpiry',
    fieldName: 'name',
    label: t('regExpiry'),
    default: false,
    ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
  },
];

export function TrailerColumnsForm() {
  const { control } = useFormContext();
  return (
    <>
      {columns.map((column, i) => (
        <Switch
          key={i}
          label={column.label}
          name={`columnFilters[${column.name}]`}
          control={control}
          disabled={column.disabled}
        />
      ))}
    </>
  );
}

export function TrailerMetaDataForm() {
  const { control } = useFormContext();
  return (
    <>
      {trailerAutoCompleteFiltersFormConfig.map((config) => (
        <MultipleAutocompleteForm
          key={config.key}
          control={control}
          name={`metaData[${config.name}]`}
          fieldName={config.fieldName}
          getOptions={config.getData}
          label={config.label}
        />
      ))}
      {tractorDatesFiltersFormConfig.map((conf) => (
        <DatePicker
          iso
          key={conf.key}
          control={control}
          name={`metaData[${conf.name}]`}
          label={conf.label}
        />
      ))}
    </>
  );
}
