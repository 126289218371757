import { AddOutlined, BoltOutlined, LaunchOutlined } from '@mui/icons-material';
import {
  Button,
  Divider,
  List,
  ListItemButton,
  Typography,
} from '@mui/material';
import Popover from '@mui/material/Popover';
import React from 'react';
import {
  BookingButtonStyle,
  BookingListItemStyle,
  BookingListStyle,
} from '../style/style';
import { actionDataEnum } from '../../constants/grid.constants';

export interface InstantBookingPopoverProps {
  open: boolean;
  anchorEl: Element;
  onPopoverClose: () => void;
  gotPendingLoad: (event: any) => void;
  onConfirmBookingClick: () => void;
  data: any;
}

export const InstantBookingPopover: React.FC<InstantBookingPopoverProps> = ({
  open,
  anchorEl,
  onPopoverClose,
  gotPendingLoad,
  onConfirmBookingClick,
  data,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onPopoverClose}
      sx={{
        '.MuiPopover-paper': { borderRadius: '16px' },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <List sx={{ ...BookingListStyle }}>
        {actionDataEnum.bookLink in data ? (
          <>
            <ListItemButton
              alignItems="flex-start"
              sx={{ ...BookingListItemStyle }}
            >
              <Typography>Book externally</Typography>
              <Button
                sx={{ ...BookingButtonStyle }}
                variant="text"
                startIcon={<LaunchOutlined fontSize={'small'} />}
                href={data[actionDataEnum.bookLink]}
                size="small"
                target="blank"
              >
                Book externally
              </Button>
            </ListItemButton>
            <Divider />
          </>
        ) : (
          actionDataEnum.bookApi in data && (
            <>
              <ListItemButton
                alignItems="flex-start"
                sx={{ ...BookingListItemStyle }}
                onClick={onConfirmBookingClick}
              >
                <Typography>Book the load now</Typography>
                <Button
                  sx={{ ...BookingButtonStyle }}
                  variant="text"
                  startIcon={<BoltOutlined fontSize={'small'} />}
                  size="small"
                >
                  Instant Book
                </Button>
              </ListItemButton>
              <Divider />
            </>
          )
        )}
        <ListItemButton
          alignItems="flex-start"
          sx={{ ...BookingListItemStyle }}
          onClick={(e) => {
            onPopoverClose();
            gotPendingLoad(e);
          }}
        >
          <Typography>Already booked the load?</Typography>
          <Button
            sx={{ ...BookingButtonStyle }}
            variant="text"
            // startIcon={<PersonOutlineIcon sx={{}} fontSize={'small'} />}
            startIcon={<AddOutlined fontSize={'small'} />}
            size="small"
          >
            Add Load
          </Button>
        </ListItemButton>
      </List>
    </Popover>
  );
};
