import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormDialog from '../../../../common/Ui/FormDialog';
import Checkbox from '../../../../common/Ui/Checkbox';
import { Button, ButtonGroup, IconButton } from '@mui/material';
import { CloseIcon, ImageIcon } from '../../../../ui-kit/components/Assets';
import { DocumentType, InvoiceModel } from '../../models/InvoiceModel';
import { formatBytes } from '../../../../utils';
import { useTheme } from '@mui/material/styles';

export const DocumentItem = ({
  document,
  docIds,
  type,
  removeDocument,
  removeDocumentIds,
}: {
  document: DocumentType;
  docIds: string[];
  type: string;
  removeDocument: (doc: DocumentType, type: string) => void;
  removeDocumentIds: (docIds: string[]) => void;
}) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', ml: '35px', mt: 2 }}>
      <ImageIcon color={theme.palette.text.primary} />
      <Box
        sx={{
          color: 'text.primary',
          padding: '0 17px',
        }}
      >
        {`${document.fileName} (${formatBytes(document.fileSize)})`}
      </Box>
      <IconButton
        aria-label="close"
        onClick={() => {
          removeDocumentIds(docIds.filter((d) => d !== document.documentId));
          removeDocument(document, type);
        }}
      >
        <CloseIcon color="#c5c8ca" width={14} height={14} />
      </IconButton>
    </Box>
  );
};

export function AttachmentsForm({
  invoice,
  subLabel,
  disableInvoiceCheckbox = false,
}: {
  invoice: InvoiceModel;
  subLabel?: string;
  disableInvoiceCheckbox?: boolean;
}) {
  const { control, setValue, watch } = useFormContext();
  const pdfComplexity = watch('pdfComplexity');

  const [documents, setDocuments] = useState<{ [key: string]: DocumentType[] }>(
    {
      RATE_CONFIRMATION: [],
      BILL_OF_LADING: [],
      PROOF_OF_DELIVERY: [],
      OTHER: [],
    }
  );
  const [docIds, setDocIds] = useState<string[]>([]);

  const rateConfirmationDocs: DocumentType[] = [];
  const billOfLadingDocs: DocumentType[] = [];
  const proofOfDeliveryDocs: DocumentType[] = [];
  const otherDocs: DocumentType[] = [];
  const documentIds: string[] = [];

  useEffect(() => {
    setValue('documentIds', docIds);
  }, [docIds]);

  useEffect(() => {
    // checkbox select if section has documents length
    if (documents['RATE_CONFIRMATION'].length) {
      setValue('rateConfirmation', true);
    }
    if (documents['BILL_OF_LADING'].length) {
      setValue('billOfLading', true);
    }
    if (documents['PROOF_OF_DELIVERY'].length) {
      setValue('proofOfDelivery', true);
    }
    if (documents['OTHER'].length) {
      setValue('other', true);
    }
  }, [documents]);

  useEffect(() => {
    invoice.assignedDocuments.forEach((doc) => {
      if (doc.documentType === 'RATE_CONFIRMATION') {
        rateConfirmationDocs.push(doc);
      } else if (doc.documentType === 'BILL_OF_LADING') {
        billOfLadingDocs.push(doc);
      } else if (doc.documentType === 'PROOF_OF_DELIVERY') {
        proofOfDeliveryDocs.push(doc);
      } else {
        otherDocs.push(doc);
      }
      documentIds.push(doc.documentId);
    });

    const newDocuments = {
      RATE_CONFIRMATION: rateConfirmationDocs,
      BILL_OF_LADING: billOfLadingDocs,
      PROOF_OF_DELIVERY: proofOfDeliveryDocs,
      OTHER: otherDocs,
    };
    setDocuments(newDocuments);
    setDocIds(documentIds);
  }, [invoice]);

  const handleRemoveDocument = (doc: DocumentType, type: string) => {
    const updatedDocuments = documents[type].filter(
      (d) => d.documentId !== doc.documentId
    );
    setDocuments((docs) => ({ ...docs, [type]: updatedDocuments }));
  };

  const removeDocumentIds = (ids: string[]) => setDocIds(ids);

  const handleCheckboxChange = (checked: boolean, type: string) => {
    if (!checked) {
      const excludedIds = documents[type].map((d) => d.documentId);
      setDocuments((docs) => ({ ...docs, [type]: [] }));
      setDocIds((ids) => ids.filter((id) => !excludedIds.includes(id)));
    } else {
      const filteredDocs = invoice.assignedDocuments.filter((d) => {
        if (type === 'OTHER') {
          return ![
            'RATE_CONFIRMATION',
            'BILL_OF_LADING',
            'PROOF_OF_DELIVERY',
          ].includes(d.documentType);
        }
        return d.documentType === type;
      });
      setDocuments((docs) => ({
        ...docs,
        [type]: filteredDocs,
      }));
      setDocIds((docIds) => [
        ...docIds,
        ...filteredDocs.map((d) => d.documentId),
      ]);
    }
  };
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant={'subtitle2'}
          sx={{ fontSize: '16px', fontWeight: '600' }}
        >
          Level of Detail
        </Typography>
      </Box>
      {pdfComplexity && (
        <Box sx={{ mb: 5 }}>
          <ButtonGroup
            aria-label="outlined primary button group"
            size="large"
            fullWidth={true}
          >
            <Button
              variant={
                pdfComplexity === 'SIMPLIFIED' ? 'contained' : 'outlined'
              }
              onClick={() => setValue('pdfComplexity', 'SIMPLIFIED')}
            >
              Summary
            </Button>
            <Button
              variant={pdfComplexity === 'MEDIUM' ? 'contained' : 'outlined'}
              sx={{ position: 'relative', left: ' -20px' }}
              onClick={() => setValue('pdfComplexity', 'MEDIUM')}
            >
              Detailed
            </Button>
            <Button
              variant={pdfComplexity === 'DETAILED' ? 'contained' : 'outlined'}
              sx={{ position: 'relative', left: ' -40px' }}
              onClick={() => setValue('pdfComplexity', 'DETAILED')}
            >
              Comprehensive
            </Button>
          </ButtonGroup>
        </Box>
      )}
      <Box sx={{ mb: 4 }}>
        <Typography variant={'subtitle2'}>Attachments:</Typography>
        {subLabel && <Typography variant={'subtitle2'}>{subLabel}</Typography>}
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          disabled={disableInvoiceCheckbox}
          name={'sendInvoice'}
          label={'Invoice'}
        />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'rateConfirmation'}
          label={'Rate Confirmation'}
          onChangeCb={(checked) => {
            handleCheckboxChange(checked, 'RATE_CONFIRMATION');
          }}
        />
        {documents['RATE_CONFIRMATION'].map((doc) => (
          <DocumentItem
            docIds={docIds}
            document={doc}
            removeDocument={handleRemoveDocument}
            removeDocumentIds={removeDocumentIds}
            key={doc.documentId}
            type={'RATE_CONFIRMATION'}
          />
        ))}
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'billOfLading'}
          label={'Bill of Lading'}
          onChangeCb={(checked) => {
            handleCheckboxChange(checked, 'BILL_OF_LADING');
          }}
        />
        {documents['BILL_OF_LADING'].map((doc) => (
          <DocumentItem
            docIds={docIds}
            document={doc}
            removeDocument={handleRemoveDocument}
            removeDocumentIds={removeDocumentIds}
            key={doc.documentId}
            type={'BILL_OF_LADING'}
          />
        ))}
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'proofOfDelivery'}
          label={'Proof of Delivery'}
          onChangeCb={(checked) => {
            handleCheckboxChange(checked, 'PROOF_OF_DELIVERY');
          }}
        />
        {documents['PROOF_OF_DELIVERY'].map((doc) => (
          <DocumentItem
            docIds={docIds}
            document={doc}
            removeDocument={handleRemoveDocument}
            removeDocumentIds={removeDocumentIds}
            key={doc.documentId}
            type={'PROOF_OF_DELIVERY'}
          />
        ))}
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'other'}
          label={'Other'}
          onChangeCb={(checked) => {
            handleCheckboxChange(checked, 'OTHER');
          }}
        />
        {documents['OTHER'].map((doc) => (
          <DocumentItem
            docIds={docIds}
            document={doc}
            removeDocument={handleRemoveDocument}
            removeDocumentIds={removeDocumentIds}
            key={doc.documentId}
            type={'OTHER'}
          />
        ))}
      </Box>
    </>
  );
}

export function AttachmentsDialog({
  open,
  onAction,
  onClose,
  invoice,
}: {
  open: boolean;
  onAction: ({
    invoiceId,
    documentIds,
  }: {
    invoiceId: string;
    documentIds: string[];
  }) => void;
  onClose: () => void;
  invoice: InvoiceModel;
}) {
  return (
    <FormDialog
      data={{
        sendInvoice: true,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
        documentIds: [],
        invoiceId: invoice.childInvoice ? invoice.masterInvoiceId : invoice.id,
        pdfComplexity: 'DETAILED',
      }}
      titleText="Download Invoice"
      actionLabel="Download"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => (
        <AttachmentsForm disableInvoiceCheckbox invoice={invoice} />
      )}
    />
  );
}
