import { SxProps } from '@mui/material/styles';
import { memo } from 'react';
import { IAutocompleteOption } from '../../../../../../views/trips/constants/types';
import { GridSwitch } from './GridSwitch';

export interface GridSwitchFilterProps {
  id?: string;
  column: any;
  defaultFilterValue: Record<string, any>;
  onChangeCb?: (param: IAutocompleteOption[] | null, name: string) => void;
  isFormControl?: boolean;
  field: Record<string, any> | undefined;
  customStyles: SxProps;
}

export const GridSwitchFilter = memo(
  ({
    column,
    defaultFilterValue,
    onChangeCb,
    isFormControl = false,
    field = {},
    customStyles,
  }: GridSwitchFilterProps) => {
    return (
      <GridSwitch
        isFormControl={isFormControl}
        name={field.name || column.name}
        label={field.label || column.label}
        disabled={column.disabled}
        onChange={(
          newValue: IAutocompleteOption,
          name?: string | undefined
        ) => {
          const cloneNewValue = { ...newValue };
          if (isFormControl) {
            field.onChange(cloneNewValue, name);
            column.onChange && column.onChange(cloneNewValue, name);
          } else {
            onChangeCb && onChangeCb(cloneNewValue, name);
          }
        }}
        value={isFormControl ? field.value : defaultFilterValue[column.name]}
        fieldName={column.fieldName}
        fieldValue={column.fieldValue}
        customStyles={customStyles}
        options={column.options}
      />
    );
  }
);
GridSwitchFilter.displayName = 'GridSwitchFilter';
