export const BasicReportsConstants = {
  createAdvancedReport: 'Create Advanced Report',
  exportDataSubtitle1:
    'Export data wizard can be used to Download raw data or report data for further analysis. You can also access the same data using API link which can be integrated with analytical tools like Power BI and Tableau.',
  exportDataSubtitle2:
    'To download the data using the API link, ensure your API access is enabled by the administrator in the company preference section.',
  exportDataSubtitle3:
    'Note: Accessing data using API links enables you to access more detailed level of data compared to the excel download option.',
  learnOnExportData: 'to learn more on the export data.',
  step: 'Step',
  selectDataSource: 'Select Data Source',
  whatDataExport: 'What report data you want to export?',
  applyFilters: 'Apply Filters',
  sliceDice: 'You can slice and dice your data before exporting.',
  chooseTypeOfLoads: 'Choose Type of Loads',
  chooseReportCriterias: 'Choose Report Criterias',
  copyApiLink: 'Copy API Link',
  scheduleType: 'Schedule Type',
  dateType: 'Date Type',
  lastYear: 'Last 1 Year',
  lastTwoYears: 'Last 2 Years',
  wholePeriod: 'Whole Period',
  entity: 'Entity',
  load: 'Load Report',
  loadTrendReport: 'Load Trend Report',
  iftaReport: 'IFTA Report',
  monthly: 'Monthly',
  weekly: 'Weekly',
  dispatcher: 'Dispatcher',
  tripMiles: 'Trip Miles',
  loadedMiles: 'Loaded Miles',
  emptyMiles: 'Empty Miles',
  avgRevenue: 'Avg. Revenue',
  tripMile: 'Trip Mile',
  loadedMile: 'Loaded Mile',
  totalMile: 'Total Mile',
  avgNet: 'Avg. Net',
  totalRevenue: 'Total Revenue',
  totalTripExpenses: 'Total Trip Expenses',
  totalLoadExpenses: 'Total Load Expenses',
  netIncome: 'Net Income',
  tripDriverPayments: 'Trip Driver Payments',
  tripDispatcherPayments: 'Trip Dispatcher payments',
};
