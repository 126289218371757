import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  ThemeProvider,
  createTheme,
  useTheme,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import MultilineCellAxele from '../../../../../ui-kit/components/MultilineCell';
import TextInput from '../../../../../ui-kit/components/TextField';
import { ToggleButtonGroup } from '../../../../../ui-kit/components/ToggleButtonGroup';
import {
  addTrackerDefaultData,
  buttonNames,
  switchText,
} from '../constants/constant';
import { styles } from '../styles/styles';
import { t } from 'i18next';
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'primary.main',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const TrackerContent = ({
  titleText = '',
  onCloseDialog,
  alertTypes = [],
  onAddSubmit,
  onChangeData,
  data = {},
  error = {},
  onChangeList,
  contactList = [],
  onChangeFreeSolo,
  onChangeSelectAll,
  isSubmitting,
}: any) => {
  const renderHeader = () => (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Typography color="text.primary" align="left" variant="h6">
        {titleText}
      </Typography>
      <IconButton onClick={onCloseDialog}>
        <CloseIcon sx={{ color: 'text.primary' }} />
      </IconButton>
    </Grid>
  );
  const renderContent = () => (
    <Stack direction="column" spacing={4}>
      <Autocomplete
        id="name"
        freeSolo
        sx={{ width: '100%' }}
        options={contactList || []}
        renderInput={(params) => (
          <TextField
            error={!!error.name}
            helperText={error.name || ''}
            required
            {...params}
            label={t('name')}
            variant="standard"
          />
        )}
        onChange={(event: any, newValue: string | null) =>
          onChangeFreeSolo(event, newValue, true)
        }
        onInputChange={(event, newValue) =>
          onChangeFreeSolo(event, newValue, false)
        }
        renderOption={(props, option: any) => (
          <li {...props}>{option.label}</li>
        )}
      />
      <TextInput
        id="email"
        label={t('email')}
        variant="standard"
        defaultValue={data.email ? data.email : ''}
        styleProps={{ width: '100%' }}
        onChange={(event: any) => onChangeData('email', event)}
        error={error.email ? true : false}
        helperText={error.email ? error.email : ''}
      />
      <TextInput
        id="phone"
        label={t('phone')}
        variant="standard"
        defaultValue={data.phone ? data.phone : ''}
        styleProps={{ width: '100%' }}
        InputProps={{ maxLength: 10 }}
        onChange={(event: any) => onChangeData('phone', event)}
        error={error.phone ? true : false}
        helperText={error.phone ? error.phone : ''}
      />
      {error.customValidation && (
        <Typography sx={{ color: 'error.main' }}>
          Please enter a valid email or phone number
        </Typography>
      )}
      <Stack direction="row" spacing={2}>
        <AntSwitch
          checked={data.selectAll}
          onChange={(event: any) => onChangeSelectAll('selectAll', event)}
          inputProps={{ 'aria-label': 'ant design' }}
          sx={{ mt: 1 }}
        />
        <MultilineCellAxele
          title={switchText.title}
          subtitle={switchText.subTitle}
          titleStyle={styles.titleText}
          subtitleStyle={styles.subText}
          alignTitle="left"
          alignSubtitle="left"
        />
      </Stack>
      <ToggleButtonGroup
        buttonList={alertTypes}
        selectedList={data.selectedList}
        onChangeList={onChangeList}
      />
      {error?.selectedList && (
        <Typography sx={{ color: 'error.main' }}>
          {t('loadsTrackingSelectAtleastOneNotification')}
        </Typography>
      )}
    </Stack>
  );

  const renderActionButtons = () => (
    <Box sx={styles.actionButtonWrapper} mt={4}>
      <ButtonImproved
        variant="outlined"
        onClick={onCloseDialog}
        label={buttonNames.cancel}
      />
      {data == addTrackerDefaultData ? (
        <></>
      ) : (
        <ButtonImproved
          disabled={isSubmitting}
          variant="contained"
          label={buttonNames.add}
          startIcon={
            isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              <IconsComponent iconName="Radar" source="MUIcons" />
            )
          }
          onClick={onAddSubmit}
        />
      )}
    </Box>
  );

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {renderActionButtons()}
    </>
  );
};
