import React from 'react';
import { AXELE_PERMISSION_TYPE, Permission } from '../common/Permission';
import SubscriptionComponent from '../components/Subscription/Subscription';

export default function Subscription() {
  return (
    <Permission includes={[AXELE_PERMISSION_TYPE.SUBSCRIPTION_VIEW]}>
      <SubscriptionComponent />
    </Permission>
  );
}
