import { useState } from 'react';
import { CompletedIcon } from '../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { fontFamily } from '../../constants';
import FinishFooter from '../Common/FinishFooter';
import {
  IMPORT_EXPENSE_NAME,
  useImport,
} from '../../../../../contexts/ImportContext';
import ImportActions from '../../Import';
import { downloadFile } from '../../../../../utils/Doc';
import KpiSection from './KpiSection';
import { capitalizeFirstLetter } from '../../../../../utils';
import { possibleDeductionTypesMap } from '../Step1/constants';

export default function Step4() {
  const {
    currentEntity: {
      routeName,
      displayName: entityDisplayName,
      dictionary,
      name: entityName,
    },
    sessionId,
    kpiInfo: { errorsCount },
    handleFinishImport,
  } = useImport();
  const hasErrors = errorsCount > 0;

  const [downloading, setDownloading] = useState(false);

  const handleDownloadFile = async (isRejected: boolean) => {
    if (!downloading) {
      setDownloading(true);
      const response = await ImportActions.downloadImportedFile(
        routeName,
        dictionary,
        sessionId,
        isRejected
      );
      if (response) {
        downloadFile(response);
      }
      setDownloading(false);
    }
  };

  const renderMessage = () => {
    if (hasErrors) {
      return 'Import has been successfully completed with a few errors.';
    }
    return 'Import has been successfully completed.';
  };

  return (
    <>
      <Box sx={{ display: 'flex', mt: 3, mr: 2 }}>
        <KpiSection />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'center',
          overflowY: 'scroll',
          m: 1,
        }}
      >
        <Box sx={{ mb: 5 }}>
          <CompletedIcon />
        </Box>
        <Box>
          <Typography
            variant={'h4'}
            sx={{
              color: 'rgba(0, 17, 34, 0.75)',
              fontSize: 34,
              fontWeight: 400,
              mb: 4,
              fontFamily,
            }}
          >
            Import Completed!
          </Typography>
        </Box>
        {entityName === IMPORT_EXPENSE_NAME &&
          possibleDeductionTypesMap[ImportActions.deductionType] && (
            <Box>
              <Typography
                variant={'h6'}
                sx={{
                  color: 'rgba(0, 17, 34, 0.75)',
                  fontSize: 20,
                  fontWeight: 500,
                  mb: 4,
                  fontFamily: fontFamily,
                  letterSpacing: '0.15px',
                }}
              >
                {` ${capitalizeFirstLetter(
                  possibleDeductionTypesMap[ImportActions.deductionType]
                )} deductions has been successfully created for imported records.`}
              </Typography>
            </Box>
          )}

        <Box>
          <Typography
            variant={'h6'}
            sx={{
              color: 'rgba(0, 17, 34, 0.75)',
              fontSize: 20,
              fontWeight: 500,
              mb: 4,
              fontFamily: fontFamily,
              letterSpacing: '0.15px',
            }}
          >
            {renderMessage()}
          </Typography>
        </Box>
        <Box sx={{ width: 212 }}>
          <Box sx={{ mb: 3 }}>
            <ButtonImproved
              variant={'contained'}
              label={`View Imported ${entityDisplayName}`}
              onClick={() => handleDownloadFile(false)}
              styleProps={{
                width: '100%',
              }}
            />
          </Box>
          {hasErrors && (
            <Box>
              <ButtonImproved
                variant={'outlined'}
                label={'Download Rejected Items'}
                onClick={() => handleDownloadFile(true)}
                styleProps={{
                  color: '#2196F3',
                  borderColor: '#2196F3',
                  width: '100%',
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <FinishFooter onFinish={handleFinishImport} />
    </>
  );
}
