import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  CreateEfsAccountRequest,
  GetEfsAccountsRequest,
  UpdateEfsAccountRequest,
} from '@/models/DTOs/FuelCards/Requests';
import { fuelCardService } from '@/api';
import { ServiceError } from '@/api/interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import { efsValidationSchema } from '@/views/settings/integrations/FuelCard/constants';
import { FuelCardAccount, Provider } from '@/models/DTOs/FuelCards/FuelCards';
import { Providers } from '@/views/settings/integrations/FuelCard/enums';

type EfsFormValues = {
  displayName: string;
  password: string;
  userName: string;
};

export const useProviderDetails = (
  selectedProvider: Provider,
  getProviders: () => void
) => {
  const efsForm = useForm<EfsFormValues>({
    defaultValues: {
      displayName: '',
      password: '',
      userName: '',
    },
    reValidateMode: 'onSubmit',
    resolver: yupResolver(efsValidationSchema),
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [accounts, setAccounts] = useState<FuelCardAccount[]>([]);
  const [activeAccount, setActiveAccount] = useState<
    FuelCardAccount | undefined
  >();

  useEffect(() => {
    getAccounts();
  }, [selectedProvider.providerId]);

  useEffect(() => {
    if (!openDrawer) {
      getCurrentForm().reset();
    }
  }, [openDrawer]);

  const getCurrentForm = () => {
    switch (selectedProvider.providerName) {
      case Providers.EFS:
        return efsForm;
      default:
        return efsForm;
    }
  };

  const getAccounts = async () => {
    switch (selectedProvider.providerName) {
      case Providers.EFS:
        await getEfsAccounts();
        break;
      default:
        await getEfsAccounts();
    }
  };

  const getEfsAccounts = async () => {
    const request = new GetEfsAccountsRequest();
    request.providerId = selectedProvider.providerId;
    const result = await fuelCardService.getEfsAccounts(request);
    if (result instanceof ServiceError) {
      return;
    }
    setAccounts(result);
  };

  const handleCredentialsSubmit = getCurrentForm().handleSubmit(
    async (data) => {
      if (activeAccount) {
        await updateAccount(data);
      } else {
        await createAccount(data);
      }
      setActiveAccount(undefined);
    }
  );

  const createEfsAccount = async (data: EfsFormValues) => {
    const request = new CreateEfsAccountRequest();
    request.providerId = selectedProvider.providerId;
    request.data = {
      DISPLAY_NAME: data.displayName,
      PASSWORD: data.password,
      USERNAME: data.userName,
    };
    const result = await fuelCardService.createEfsAccount(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  };

  const createAccountCallback = (account: FuelCardAccount) => {
    setAccounts((accounts) => [...accounts, account]);
    setOpenDrawer(false);
    getProviders();
  };

  const createAccount = async (data: EfsFormValues) => {
    switch (selectedProvider.providerName) {
      case Providers.EFS: {
        const account = await createEfsAccount(data);
        if (account) {
          createAccountCallback(account);
        }
        break;
      }
    }
  };

  const updateEfsAccount = async (data: EfsFormValues) => {
    if (activeAccount) {
      const request = new UpdateEfsAccountRequest();
      request.providerId = activeAccount.providerId;
      request.id = activeAccount.id;
      request.data = {
        DISPLAY_NAME: data.displayName,
        PASSWORD: data.password,
        USERNAME: data.userName,
      };
      const result = await fuelCardService.updateEfsAccount(request);
      if (result instanceof ServiceError) {
        return;
      }
      return result;
    }
  };

  const updateAccountCallback = (
    updatedAccount: FuelCardAccount,
    data: EfsFormValues
  ) => {
    setAccounts((accounts) =>
      accounts.map((account) => {
        if (account.id === activeAccount?.id) {
          account = {
            ...account,
            isValid: updatedAccount.isValid,
            data: {
              ...account.data,
              DISPLAY_NAME: data.displayName,
              PASSWORD: data.password,
              USERNAME: data.userName,
            },
          };
        }
        return account;
      })
    );
    setOpenDrawer(false);
    getProviders();
  };

  const updateAccount = async (data: EfsFormValues) => {
    if (activeAccount) {
      switch (selectedProvider.providerName) {
        case Providers.EFS: {
          const account = await updateEfsAccount(data);
          if (account) {
            updateAccountCallback(account, data);
          }
          break;
        }
      }
    }
  };

  const onClose = () => {
    setOpenDrawer(false);
    const form = getCurrentForm();
    form.reset();
    setActiveAccount(undefined);
  };
  const onOpen = () => setOpenDrawer(true);

  const handleAccountDelete = (accountId: number) => {
    setAccounts((accounts) =>
      accounts.filter((account) => account.id !== accountId)
    );
    getProviders();
  };

  const handleSetActiveAccount = (account: FuelCardAccount) =>
    setActiveAccount(account);

  return {
    openDrawer,
    onClose,
    onOpen,
    handleCredentialsSubmit,
    form: getCurrentForm(),
    accounts,
    handleAccountDelete,
    activeAccount,
    handleSetActiveAccount,
    getAccounts,
  };
};
