import {
  AssignmentPreferenceType,
  RECOMMENDED_RESOURCE_TYPE,
  RESOURCE_STATE,
} from '../constants/optymization.const';
import {
  IConflictingConfirmProps,
  IPossibleResource,
  IPossibleResourceStore,
  PossibleDriverResourceType,
  PossibleTripResourceType,
} from '../types/possibleResource.types';
import { toFixDigit } from '../../../services/gantt';
import {
  isResourceTypeAssignedDriver,
  isResourceTypeBrokeredTrip,
  validateTripEndDate,
} from './recommendTrips.service';
import { TRIP_STATUSES } from '../../../constants/gantt/gantt.const';
import { ResourceType } from '../types/gantt.types';
import { RESOURCES_TYPE } from '../constants/gantt.const';
import { isResourceOptimal } from './operations.service';
import {
  OPTIMAL_RECOMMENDATION_CONFLICT_STATUS,
  POSSIBLE_SOLUTIONS_WARNING_TYPE,
  RECOMMENDATION_CONFLICT_STATUS,
} from '../config/tooltips.config';
import { ResourceConflictDataType } from '../components/recommendedTrips/ConflictingConfirmPopup';

export const convertPossibleDriversListToMap = (
  drivers: Array<PossibleDriverResourceType>,
  tripsMap: IPossibleResourceStore['possibleResourceTrips']
): IPossibleResourceStore['possibleResourceDrivers'] => {
  const driversMap = new Map();
  drivers?.forEach((driver) => {
    if (
      isResourceTypeAssignedDriver(driver) ||
      isResourceTypeBrokeredTrip(driver)
    ) {
      const driverTrips = tripsMap?.get(driver?.id);
      const selectedTrip = driverTrips?.filter(
        (trip: PossibleTripResourceType) =>
          driver?.engineMultiTripOutput?.tripIdSelected?.includes(
            trip?.tripPrimaryId as string
          )
      );
      driver.selectedTripData = selectedTrip;
      driver.evaluateResponseDTO = driver.evaluateResponseDTO;
    }
    driver.validatePossibleConflictsDTO =
      driver?.validatePossibleConflictsDTO || null;
    driver.engineMultiTripOutput = driver.engineMultiTripOutput || null;
    driversMap.set(driver.id, driver);
  });
  return driversMap;
};
export const convertPossibleTripsListToMap = (
  trips: Array<PossibleTripResourceType>
): IPossibleResourceStore['possibleResourceTrips'] => {
  const tripsMap = new Map();
  trips?.forEach((trip) => {
    const existingTrips = tripsMap.get(trip?.resourceId) ?? [];
    trip.tripId = trip.id;
    trip.tripPrimaryId = trip.id;
    trip.id = `${trip?.resourceId}${trip?.id ?? Math.random()}`;
    trip.miles = toFixDigit(trip?.miles as number);
    trip.isEndGap = trip?.endDate && trip?.destination ? false : true;
    trip.endDate = validateTripEndDate(trip) as string;
    trip.status =
      trip.type === TRIP_STATUSES.EMPTY ? TRIP_STATUSES.DEADHEAD : trip.status;
    trip.type = '';
    tripsMap.set(trip.resourceId, [...existingTrips, trip]);
  });
  return tripsMap;
};

export const convertPossibleDriversMapToList = (
  drivers: IPossibleResourceStore['possibleResourceDrivers']
): Array<PossibleDriverResourceType> => {
  if (!drivers?.size) return [];
  return Array.from(drivers?.values());
};

export const convertPossibleTripsMapToList = (
  trips: IPossibleResourceStore['possibleResourceTrips']
): Array<PossibleTripResourceType> => {
  if (!trips?.size) return [];
  const allLists = Array?.from(trips?.values?.());
  const lists = [];
  for (const list of allLists) lists?.push(...list);
  return lists;
};

export const setDriverAsRejected = (
  driver: IPossibleResource
): IPossibleResource => {
  if (!driver?.engineMultiTripOutput) return driver;
  driver.engineMultiTripOutput.isRejected = true;
  driver.engineMultiTripOutput.assignmentPreferenceType =
    AssignmentPreferenceType.rejected;
  driver.resourceState = RESOURCE_STATE.REJECTED;
  return driver;
};
export const setDriverAsUnRejected = (
  driver: IPossibleResource
): IPossibleResource => {
  if (!driver?.engineMultiTripOutput) return driver;
  driver.engineMultiTripOutput.isRejected = false;
  driver.engineMultiTripOutput.assignmentPreferenceType =
    AssignmentPreferenceType.None;
  driver.resourceState = RESOURCE_STATE.NONE;
  return driver;
};

export const getConflictWarningType = (
  type: ResourceType,
  data: IConflictingConfirmProps
): ResourceConflictDataType => {
  const conflictedBrokeredTripList =
    data?.conflictingData
      ?.filter?.(
        (e) => e?.resourceId === POSSIBLE_SOLUTIONS_WARNING_TYPE.BROKERED_TRIP
      )
      ?.map?.((t) => t?.displayResourceName) ?? [];
  let newDriver = data?.driverGroupDetails?.groupName!;
  let assignedDriver =
    data?.conflictingData?.reduce((p: any, c: any) => {
      if (c?.resourceId === POSSIBLE_SOLUTIONS_WARNING_TYPE.BROKERED_TRIP)
        return p;
      if (p) return p + ', ' + c?.displayResourceName;
      return c?.displayResourceName;
    }, '') ?? '';
  if (isResourceTypeBrokeredTrip(data)) {
    newDriver =
      data?.validatePossibleConflictsDTO?.validateConflictsMap?.DRIVER?.[0]
        ?.displayResourceName ?? '';
    assignedDriver = data?.driverGroupDetails?.groupName;
  }
  return {
    assignedDriver,
    assignedDriverCount:
      data?.conflictingData?.length! - conflictedBrokeredTripList?.length,
    newDriverCount: data?.selectedTripData?.length!,
    trip:
      data?.selectedTripData?.reduce((p: any, c: any) => {
        if (p) return p + ', ' + c?.tripSeqNumber;
        return c?.tripSeqNumber;
      }, '') ?? '',
    newDriver,
    brokeredTrips: conflictedBrokeredTripList,
    isBrokeredTrip: isResourceTypeBrokeredTrip(data),
  };
};

export const isOptimalResourceWithConflict = (resource: IPossibleResource) => {
  if (
    resource?.validatePossibleConflictsDTO?.validateConflictsMap?.DRIVER ||
    resource?.validatePossibleConflictsDTO?.validateConflictsMap?.TRIP
  )
    return true;
  return false;
};

export const getOptimalFlagTooltip = (resource: IPossibleResource): string => {
  if (!isResourceOptimal(resource)) return '';
  if (!isOptimalResourceWithConflict(resource))
    return OPTIMAL_RECOMMENDATION_CONFLICT_STATUS?.RECOMMENDED_ASSIGNMENT;
  const conflictData =
    resource?.validatePossibleConflictsDTO?.validateConflictsMap?.DRIVER?.[0] ??
    resource?.validatePossibleConflictsDTO?.validateConflictsMap?.TRIP?.[0];
  return `${
    OPTIMAL_RECOMMENDATION_CONFLICT_STATUS[
      conflictData?.resourceAssignmentStatus as string
    ]
  } ${conflictData?.displayResourceName}`;
};

export const getConflictsTooltip = (
  resource: IPossibleResource,
  resourceType: ResourceType
): string[] | null => {
  if (isResourceOptimal(resource)) return null;
  if (!isOptimalResourceWithConflict(resource)) return null;
  const tripsWarnings =
    resource?.validatePossibleConflictsDTO?.validateConflictsMap?.TRIP;
  const driverWarnings =
    resource?.validatePossibleConflictsDTO?.validateConflictsMap?.DRIVER;
  let conflictData = tripsWarnings;
  if (resourceType === RESOURCES_TYPE.DRIVER) conflictData = driverWarnings;
  return conflictData?.map?.((conflict, i: number) => {
    if (
      conflict?.resourceId === POSSIBLE_SOLUTIONS_WARNING_TYPE.BROKERED_TRIP
    ) {
      return `Trip ${conflict?.displayResourceName} ${RECOMMENDATION_CONFLICT_STATUS.RECOMMENDED_BROKERED}`;
    } else if (
      driverWarnings?.[i]?.resourceId ===
      POSSIBLE_SOLUTIONS_WARNING_TYPE.DRIVER_FIRST
    ) {
      return `Driver ${driverWarnings?.[i]?.displayResourceName} is ${
        RECOMMENDATION_CONFLICT_STATUS[
          conflict?.resourceAssignmentStatus as string
        ]
      } Trip ${tripsWarnings?.[i]?.displayResourceName}`;
    }
    return `Trip ${tripsWarnings?.[i]?.displayResourceName} is ${
      RECOMMENDATION_CONFLICT_STATUS[
        conflict?.resourceAssignmentStatus as string
      ]
    } Driver ${driverWarnings?.[i]?.displayResourceName}`;
  }) as string[];
};

export const isResourceConflict = (
  resource: IPossibleResource,
  resourceType: ResourceType
) => {
  if (
    resourceType === RESOURCES_TYPE.DRIVER &&
    resource?.validatePossibleConflictsDTO?.validateConflictsMap?.DRIVER
  )
    return true;
  if (
    resourceType === RESOURCES_TYPE.TRIP &&
    resource?.validatePossibleConflictsDTO?.validateConflictsMap?.TRIP
  )
    return true;
  return false;
};
