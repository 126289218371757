import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { paymentManagementService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import { ISettingsPagination } from '../../../contexts/TeamDriverContext';
import {
  ExportPaymentPayload,
  ExportPaymentQueryParams,
  GetPaginatedPaymentListResponse,
  GetPaymentCountTotalResponse,
  GetPaymentOneRequest,
  PaymentDetailsDTO,
} from '../../../models/DTOs/Payment/DTOs';
import { View } from '../../../types';
import { loadAndTripPageSize } from '../../../utils';
import { downloadFile } from '../../../utils/Doc';
import { ESecondaryDetailsPanelType } from '../../../views/dispatch2/constants/types';
import {
  allPaymentView,
  defaultPaymentDataGridFilters,
} from '../../../views/finance/paymentManagement/components/PaymentDataGrid/PaymentDataGrid.constants';
import * as types from '../../../views/finance/paymentManagement/constants/types';
import { fromPaymentFilterToQuery } from '../../../views/finance/paymentManagement/utils';
import { TSetData, setData } from '../../utils';

const defaultSettingsPagination: Partial<ISettingsPagination> = {
  isLoading: true,
  first: false,
  last: false,
  pageNumber: 0,
  pageSize: loadAndTripPageSize,
};

const defaultPaymentCountTotal: GetPaymentCountTotalResponse = {
  totalAmountReceived: 0,
  totalRemainCredit: 0,
};

export interface ISecondaryPanelData {
  type: keyof typeof ESecondaryDetailsPanelType;
  id: string | number;
  isGlobal?: boolean;
  defaultTab?: string;
  isContractPage?: boolean;
  readonly?: boolean;
}
class PaymentStore {
  @observable component = 'Payments';
  @observable dictionary = 'asset';
  @observable currentView: View = allPaymentView;
  @observable addNewClicked = false;
  @observable tableList: PaymentDetailsDTO[] = [];
  @observable settingsPagination: Partial<ISettingsPagination> =
    defaultSettingsPagination;
  @observable totalTableItems = 0;
  @observable filters: types.IPaymentDataGridFilters =
    defaultPaymentDataGridFilters;
  @observable selectedItem: PaymentDetailsDTO | null = null;
  @observable SecondrySelectedPaymentItem: PaymentDetailsDTO | null = null;
  @observable paymentCountTotal: GetPaymentCountTotalResponse =
    defaultPaymentCountTotal;

  @observable secondaryPanelData: ISecondaryPanelData | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setAddNewClicked = (value: boolean) => {
    this.addNewClicked = value;
  };

  @action
  setCurrentView = (newValue: TSetData<View>) => {
    this.tableList = [];
    this.totalTableItems = 0;
    this.paymentCountTotal = defaultPaymentCountTotal;
    this.currentView = setData(newValue, this.currentView);
  };

  @action
  updateLocalTableList = async (
    {
      createdList = [],
      deletedList = [],
      updatedList = [],
    }: {
      createdList?: PaymentDetailsDTO[];
      deletedList?: PaymentDetailsDTO[];
      updatedList?: PaymentDetailsDTO[];
    },
    callback?: (paymentDetailsList: PaymentDetailsDTO[]) => void
  ) => {
    const newDeletedList: PaymentDetailsDTO[] = deletedList || [];
    if (createdList?.length || newDeletedList?.length) {
      this.fetchPaymentCountTotal();
    }

    let newTableList: PaymentDetailsDTO[] = this.tableList.concat();
    if (createdList?.length) {
      newTableList.unshift(...createdList);
    }

    if (updatedList?.length) {
      const updatedItemList = await updatedList?.reduce<
        Promise<PaymentDetailsDTO[]>
      >(async (listP, paymentDetailsDTO): Promise<PaymentDetailsDTO[]> => {
        const paymentResponse = await paymentManagementService.getPaymentOne(
          new GetPaymentOneRequest({ id: paymentDetailsDTO.id })
        );
        const list = await listP;
        if (paymentResponse instanceof ServiceError || !paymentResponse?.id) {
          newDeletedList.push(paymentDetailsDTO);
          return list;
        }
        return [...list, paymentResponse];
      }, Promise.resolve([]));
      if (updatedItemList) {
        updatedItemList.forEach((item: PaymentDetailsDTO) => {
          const index = newTableList.findIndex(
            ({ id }: PaymentDetailsDTO) => id === item.id
          );
          if (index < 0) {
            newTableList.unshift(item);
          } else {
            newTableList.splice(index, 1, item);
          }
        });
      }
    }

    if (newDeletedList?.length) {
      const newDeletedListIds = newDeletedList.map(({ id }) => id);
      newTableList = newTableList.filter(
        ({ id }) => !newDeletedListIds.includes(id)
      );
    }

    this.tableList = newTableList;
    this.totalTableItems =
      this.totalTableItems + createdList.length - deletedList.length;
    callback?.(newTableList);
  };

  @action
  fetchTableList = async ({
    nextPageNumber,
    nextFilters,
  }: {
    nextPageNumber: number;
    nextFilters: types.IPaymentDataGridFilters;
  }): Promise<void> => {
    this.setFilters(nextFilters);
    this.settingsPagination = {
      ...this.settingsPagination,
      pageNumber: nextPageNumber,
      isLoading: true,
    };

    try {
      const { queryParams, payload } = fromPaymentFilterToQuery(
        this.settingsPagination,
        nextFilters
      );
      const response = await paymentManagementService.getPaginatedPaymentList(
        queryParams,
        payload
      );

      runInAction(() => {
        if (response instanceof GetPaginatedPaymentListResponse) {
          this.settingsPagination = {
            ...this.settingsPagination,
            isLoading: false,
            first: response.first,
            last: response.last,
            pageNumber: response.number + 1,
          };
          const items = response.content || [];
          const newTableList: any = !response.number
            ? items
            : [...this.tableList, ...items];
          this.tableList = removeDuplicateObjects(newTableList, 'id');
          this.totalTableItems = response.totalElements;
        } else {
          this.tableList = [];
        }
      });
    } catch (e) {
      this.settingsPagination = {
        ...this.settingsPagination,
        isLoading: false,
      };
    }
  };

  @action
  getExcel = async ({
    gridColumnMetadataList,
  }: {
    gridColumnMetadataList: string[];
  }): Promise<void> => {
    try {
      const { queryParams, payload } = fromPaymentFilterToQuery(
        this.settingsPagination,
        this.filters
      );
      const exportPayload: ExportPaymentPayload = new ExportPaymentPayload({
        ...payload,
      });
      gridColumnMetadataList = gridColumnMetadataList.map((item) =>
        item === 'paymentStatus'
          ? 'status'
          : item === 'invoicedetails'
          ? 'invoiceSeqNumber'
          : item
      );
      const exportQueryParams = new ExportPaymentQueryParams({
        gridColumnMetadataList,
        sort: queryParams.sort,
      });

      const response = await paymentManagementService.exportToExcel({
        gridFiltersMetadataList: exportPayload,
        ...exportQueryParams,
      });
      if (!(response instanceof ServiceError)) {
        downloadFile(response);
      }
    } catch (e) {
      //
    }
  };

  @action
  fetchPaymentCountTotal = async (): Promise<void> => {
    try {
      this.settingsPagination = {
        ...this.settingsPagination,
        pageNumber: this.settingsPagination.pageNumber,
        isLoading: true,
      };
      const { queryParams, payload } = fromPaymentFilterToQuery(
        this.settingsPagination,
        this.filters
      );
      const response: GetPaymentCountTotalResponse | ServiceError =
        await paymentManagementService.getCountTotal(queryParams, payload);
      if (response instanceof GetPaymentCountTotalResponse) {
        this.paymentCountTotal = response;
      }
    } catch (e) {
      //
    }
  };

  @computed
  get getTableList(): PaymentDetailsDTO[] {
    return toJS(this.tableList);
  }

  @action
  setSettingsPagination = (newValue: TSetData<any>) => {
    this.settingsPagination = setData(newValue, this.settingsPagination);
  };

  @action
  setFilters = (newValue: TSetData<types.IPaymentDataGridFilters>) => {
    this.filters = setData(newValue, this.filters);
  };

  @action
  setSelectedItem = (newValue: TSetData<PaymentDetailsDTO | null>) => {
    this.selectedItem = setData(newValue, this.selectedItem);
    this.secondaryPanelData = null;
  };

  @action
  onCreatedPayment = (createdPaymentDTO: PaymentDetailsDTO) => {
    this.fetchTableList({ nextPageNumber: 1, nextFilters: this.filters });
    this.fetchPaymentCountTotal();
    this.setSelectedItem(createdPaymentDTO);
  };

  @action
  setSecondaryPanelData = (newValue: TSetData<ISecondaryPanelData | null>) => {
    const currentPanelDetails = toJS(this.secondaryPanelData);
    if (!(newValue?.isGlobal || currentPanelDetails?.isGlobal === true)) {
      this.setSelectedItem(null);
    }

    this.secondaryPanelData = setData(newValue, this.secondaryPanelData);
  };
}

export function createPaymentStore() {
  return new PaymentStore();
}

const removeDuplicateObjects = <T>(arr: T[], property: keyof T): T[] => {
  //fix: data grid UI broken issues
  return [...new Map(arr.map((obj: T) => [obj[property], obj])).values()];
};
