import { LocationOnOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { stopValidationSchema } from '../../../../../../subPages/loadsList/LoadDetailsPanel/constants/constants';
import { Stop as StopModel } from '../../../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import FormDialog from '../../../../../Ui/FormDialog';
import { useThemeResponsive } from '../../../../../hooks/useThemeResponsive';
import { AddOrEditStopForm } from './components/Form/AddOrEditStopForm';

export interface LoadStopPopupProps {
  onClose: () => void;
  onDelete?: () => void;
  onCreated: (data: any, formState: any) => void;
  data: StopModel;
  isLastStop?: boolean;
  isFirstStop?: boolean;
  isStatusAvailable?: boolean;
  stopType?: string;
  driverGroup: string;
}

export const LoadStopPopup = ({
  onClose,
  onDelete,
  onCreated,
  data,
  isFirstStop,
  isLastStop,
  stopType,
  driverGroup,
  isStatusAvailable = false,
}: LoadStopPopupProps): JSX.Element => {
  const { isMobile } = useThemeResponsive();
  const getTitleText = () => {
    const defaultTitleText = (data.id && 'Edit Stop') || 'Add Stop';
    if (!isMobile) return defaultTitleText;
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <LocationOnOutlined color="primary" />
        <Box>{defaultTitleText}</Box>
      </Stack>
    );
  };

  return (
    <FormDialog
      mode="LIGHT"
      width={1062}
      data={data}
      titleText={getTitleText()}
      actionLabel={(data.id && 'Save') || 'Add Stop'}
      deleteActionLabel="Delete Stop"
      open={true}
      onAction={onCreated}
      handleClose={onClose}
      onDelete={onDelete}
      validationSchema={stopValidationSchema({
        isDriverAssigned: Boolean(driverGroup) || !isStatusAvailable,
      })}
      contentRenderer={() => (
        <AddOrEditStopForm
          isFirstStop={isFirstStop}
          isLastStop={isLastStop}
          stopType={stopType}
          isStartEndDateRequired={!(Boolean(driverGroup) || isStatusAvailable)}
        />
      )}
    />
  );
};
