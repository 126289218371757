import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GridColDefSelf } from '../../../../types';

import {
  formatPhoneNumber,
  getTimeFromMinutes,
  getTimeZoneAbbreviation,
} from '../../../../utils/index';
import { getCustomerVendorLocationName } from '../utils/sharedMethods';

export const locationColumns: GridColDefSelf[] = [
  {
    field: 'locationName',
    headerName: 'Name',
    minWidth: 250,
    editable: false,
    sortable: true,
    permanent: true,
    renderCell: (params: GridRenderCellParams<Date>) => {
      return getCustomerVendorLocationName(params);
    },
    flex: 1,
  },
  {
    field: 'contactName',
    headerName: 'Contact',
    minWidth: 150,
    editable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Date>) => {
      const { row } = params;
      return row?.primaryContact?.fullName || '';
    },
  },
  {
    field: 'contactPhone',
    headerName: 'Phone',
    minWidth: 250,
    editable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Date>) => {
      const { row } = params;
      return formatPhoneNumber(row?.primaryContact?.phoneData);
    },
  },
  {
    field: 'contactEmail',
    headerName: 'Email',
    minWidth: 250,
    editable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Date>) => {
      const { row } = params;
      return row?.primaryContact?.email || '';
    },
  },
  {
    field: 'avgHandlingTime',
    headerName: 'Avg. Handling Time',
    minWidth: 160,
    editable: false,
    sortable: true,
    renderCell: (params: GridRenderCellParams<Date>) => {
      const { row } = params;
      return (
        getTimeFromMinutes(row.avgHandlingTime) ||
        (row.avgHandlingTime == 0 ? 0 : '')
      );
    },
  },
  {
    field: 'timezone',
    headerName: 'Time Zone',
    sortable: true,
    minWidth: 110,
    renderCell: (params: GridRenderCellParams<Date>) => {
      return getTimeZoneAbbreviation(params?.row?.timezone);
    },
  },
  {
    field: 'fullAddress',
    headerName: 'Address',
    minWidth: 300,
    editable: false,
    sortable: false,
  },
  {
    field: 'notes',
    headerName: 'notes',
    minWidth: 300,
    editable: false,
    sortable: false,
  },
];
