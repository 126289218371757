import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { GridSelectionModel } from '@mui/x-data-grid';
import {
  GridCellParams,
  GridColumnVisibilityModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EventTypes } from '../../../../EventEmitter/EventTypes';
import { EventHoldLoadActionData } from '../../../../EventEmitter/Events/EventHoldLoadAction';
import { LoadTripActionData } from '../../../../EventEmitter/Events/EventLoadTripAction';
import { EventTripStopActionData } from '../../../../EventEmitter/Events/EventTripStopAction';
import { loadService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { IOpenPopupEventProps } from '../../../../common/Appointment';
import CommonDataGrid from '../../../../common/DataGridPro';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import {
  ELoadStatus,
  EMyLoadDetailsPanelTabs,
} from '../../../../common/LoadTabPanel/constants/constants';
import { RoutesInfo } from '../../../../common/LoadTabPanel/tabs/Routes/Models';
import { getSortOption } from '../../../../common/Sorting/sortingUtils';
import { useFilterDataGrid } from '../../../../common/hooks/useFilterDataGrid';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import { useLoadAiPermission } from '../../../../common/hooks/useLoadaiPermission';
import { persistUserState } from '../../../../constants';
import { EQUIPMENT_PANEL_TABS } from '../../../../constants/contacts';
import { OPTIMIZATION_SOURCES } from '../../../../constants/gantt/gantt.const';
import { TripsConstants } from '../../../../locales/en/allLoads/trips';
import { ByLoadIdRequest, LoadDetailsResponse, Trip } from '../../../../models';
import useFilterStorage from '../../../../services/storage';
import { IPanelName } from '../../../../store/pageStores';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../store/root.context';
// import CreateLoadPanel from '../../../../subPages/loadsList/LoadDetailsPanel';
import CreateLoad from '../../../../subPages/loadsList/CreateLoad';
import { GridColDefSelf } from '../../../../types';
import { AVAILABLE_TRIPS_STATUS_FILTER } from '../../../../utils/commonNavigation/constants/constants';
import useEmit from '../../../../utils/hooks/useEmit';
import { ESecondaryDetailsPanelType } from '../../../dispatch/constants/types';
import {
  OnHoldStates,
  debounceTime,
  tripFieldsConfig,
} from '../../../trips/constants/constants';
import { ITripFilters } from '../../../trips/constants/types';
import { exportToExcel } from '../../../trips/services/api.utils';
import { useUnassgnedGridActions } from '../../hooks/useUnassgnedGridActions';
import { ContainerStyles, GanttChartStyles } from '../../styles';
import TripsLayoutHeader from './TripsHeader';
import TripGridConfig, {
  availableTrips as currentView,
  dispatchComponentKey,
  getFilterStatusList,
  unassignedTripsId,
} from './grid.config';
import { StyledCommonDataGrid } from './styles';

const TripWrapper: FC = () => {
  const theme = useTheme();
  const {
    dispatch2GlobalStore: {
      gapForTripAssignment,
      updateBulkOptymizationTripInput,
      setAllTripsCount,
      setAllNonHoldTripsCount,
      setTripFilters,
      getBulkOptymizationInputs,
      startBulkOptymization,
      currentDriversCountToOptimize,
      currentTripsCountToOptimize,
    },
    dispatch2TripsStore: {
      tableList: { data: tableData, totalRows },
      tripCountMetrics,
      filters,
      setFilters,
      settingsPagination: { pageNumber, isLoading, last },
      fetchMainData,
      updateMainData,
      setSettingsPagination,
      secondaryPanelData,
      setSecondaryPanelData,
      newLoadData,
      setNewLoadData,
      fullscreenPanel,
      getGridActionState,
      setGridActionState,
      changeStatusBulkSelect,
      revenueTotalResponse,
    },
    bulkOptymizationStore: {
      setOptimizationRequestResources,
      setOptimizationSource,
    },
  } = useStores();
  const { getCompanyPreferences: preferences } = useRootStore();
  const { hasBulkOptimizationPermission } = useLoadAiPermission();
  const isPageInitialRender = useRef(true);

  useEffect(() => {
    setAllTripsCount(tripCountMetrics?.availableTripCount ?? 0);
    setAllNonHoldTripsCount(tripCountMetrics?.availableNonHoldTripCount ?? 0);
  }, [tripCountMetrics?.availableTripCount]);

  const gridConfig = new TripGridConfig({
    theme,
    timezone: preferences?.timezone,
  });

  const LStorageHook = useFilterStorage({ page: dispatchComponentKey });

  const [tableColumns, setTableColumns] = useState<GridColDefSelf[]>([]);

  const [sortRequestData, setSortRequestData] = useState({});

  const { isTerminalEnabled, getGlobalTerminals } = useRootStore();

  const defaultFilters =
    persistUserState.pages[dispatchComponentKey][unassignedTripsId]?.filters ||
    currentView.metaData;

  const {
    hasFinancialInvoicesView,
    hasFinancialPaymentView,
    hasLoadCRUDPermission,
    hasCarrierViewPermission,
  } = useLoadPermission();

  const allColumnsConfig = gridConfig.config.columns.filter(
    (item: GridColDefSelf) => {
      if (item.field == tripFieldsConfig.terminal.fieldName)
        return isTerminalEnabled;
      if (item.field == tripFieldsConfig.revenue.fieldName)
        return hasFinancialInvoicesView;
      if (item.field == tripFieldsConfig.settlementAmount.fieldName)
        return hasFinancialPaymentView;
      if (item.field == tripFieldsConfig.carrierDetails.fieldName)
        return hasCarrierViewPermission;
      if (
        item.field == tripFieldsConfig.brokerageTrip.fieldName ||
        item.field == tripFieldsConfig.canBeBrokered.fieldName
      )
        return hasCarrierViewPermission;
      return true;
    }
  );
  const [allColumns, setAllColumns] =
    useState<GridColDefSelf[]>(allColumnsConfig);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<
    GridColumnVisibilityModel | undefined
  >();

  const {
    setDefaultColumns,
    setDefaultFilters,
    onPageFilterChange,
    onAllFilterSubmit,
    onAllFilterReset,
    resetSettings,
    handleChangeColumns,
    onColumnOrderChangeHandler,
  } = useFilterDataGrid<ITripFilters>({
    component: dispatchComponentKey,
    allColumns: allColumnsConfig,
    currentView,
    setTableColumns,
    setAllColumns,
    defaultFilters,
    currentViewPrimaryKey: 'id',
    setFilters,
    setColVisibility: setColumnVisibilityModel,
  });

  const setDefaultSorting = () => {
    const storageData = LStorageHook.getStorage() || {};
    const sort = storageData[unassignedTripsId]?.query?.sort;
    sort && setSortRequestData({ sort });
  };

  const handleTripAssignmentChange = (data: any) => {
    updateTripList({
      updatedIds: [data.trip.id],
      skipOpenDetailsPanel: data.skipOpenDetailsPanel,
    });
  };

  const handleLoadTripAction = (data: LoadTripActionData) => {
    if (data.toStatus === ELoadStatus.DELETED) {
      if (data.tripIds) {
        updateTripList({
          deletedIds: data.tripIds,
          skipOpenDetailsPanel: data.skipOpenDetailsPanel,
        });
      }
    } else {
      if (data.entity === 'Trip') {
        updateTripList({
          updatedIds: [data.id],
          skipOpenDetailsPanel: data.skipOpenDetailsPanel,
        });
      } else if (data.tripIds) {
        updateTripList({
          updatedIds: data.tripIds,
          skipOpenDetailsPanel: data.skipOpenDetailsPanel,
        });
      }
    }
  };

  const handleTripStopAction = (data: EventTripStopActionData) => {
    if (data.updatedTripIds) {
      updateTripList({
        updatedIds: data.updatedTripIds,
      });
    }
  };

  const handleHoldOrReleaseLoad = (data: EventHoldLoadActionData) => {
    if (!data.tripIds) return;
    updateTripList({
      updatedIds: data.tripIds,
    });
  };

  useEmit({
    [EventTypes.TRIP_ASSIGNMENT_CHANGE]: handleTripAssignmentChange,
    [EventTypes.LOAD_TRIP_ACTION]: handleLoadTripAction,
    [EventTypes.TRIP_STOP_ACTION]: handleTripStopAction,
    [EventTypes.HOLD_LOAD]: handleHoldOrReleaseLoad,
  });

  const onClickHandler = (event: IOpenPopupEventProps<Trip>): void => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    handleOpenPopup(event);
  };

  const sendRequestHandler = (params: {
    nextPageNumber: number;
    nextFilters: ITripFilters;
  }) => {
    setTripFilters(params?.nextFilters);
    setSettingsPagination((old) => ({
      ...old,
      pageNumber: params.nextPageNumber,
    }));
    setFilters(params.nextFilters);
    debouncedChangeHandler(params);
  };

  const debouncedChangeHandler = useCallback(
    debounce(
      ({
        nextPageNumber,
        nextFilters,
      }: {
        nextPageNumber: number;
        nextFilters: ITripFilters;
      }) => {
        const statuses = nextFilters?.statuses?.length
          ? nextFilters?.statuses
          : getFilterStatusList();
        fetchMainData({
          nextFilters: {
            ...nextFilters,
            statuses: statuses,
            detention: preferences?.calculateDetention
              ? nextFilters.detention
              : undefined,
            terminalIds: isTerminalEnabled
              ? nextFilters?.terminalIds?.length
                ? [nextFilters?.terminalIds]
                : getGlobalTerminals.map(({ id }: { id: string }) => id)
              : undefined,
          },
          nextPageNumber,
        });
      },
      debounceTime
    ),
    [
      preferences?.calculateDetention,
      isTerminalEnabled,
      getGlobalTerminals?.length,
      tableData,
    ]
  );

  const exportToExcelHandler = async (): Promise<void> => {
    const terminalIds = isTerminalEnabled
      ? getGlobalTerminals.map(({ id }: { id: string }) => id)
      : [];
    exportToExcel({
      filters,
      gridColumnMetadataList: tableColumns.reduce(
        (result: string[], c: GridColDefSelf) => {
          if (
            c.field !== tripFieldsConfig.warning.fieldName &&
            c.field !== tripFieldsConfig.plusButton.fieldName
          )
            result.push(c.field);
          return result;
        },
        []
      ),
      terminalIds,
    });
  };

  const handleOpenPopup = ({
    data,
    params,
  }: IOpenPopupEventProps<Trip>): void => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    if (
      params?.field === tripFieldsConfig.driver.fieldName &&
      data?.groupDetails?.id
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.DISPATCH,
        id: data?.groupDetails?.id,
      });
    } else if (
      params?.field === tripFieldsConfig.dispatcher.fieldName &&
      data?.dispatcherId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.USER,
        id: data?.dispatcherId,
      });
    } else if (
      params?.field === tripFieldsConfig.tractor.fieldName &&
      data?.assignment?.tractorId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.TRACTOR,
        id: data?.assignment?.tractorId,
        defaultTab: EQUIPMENT_PANEL_TABS.GENERAL,
      });
    } else if (
      params?.field === tripFieldsConfig.trailer.fieldName &&
      data?.assignment?.trailerId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.TRAILER,
        id: data?.assignment?.trailerId,
        defaultTab: EQUIPMENT_PANEL_TABS.GENERAL,
      });
    } else if (
      params?.field === tripFieldsConfig.loadId.fieldName &&
      data?.connectedLoads?.[0]?.loadId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.LOAD,
        id: data?.connectedLoads?.[0]?.loadId,
        defaultTab: EMyLoadDetailsPanelTabs.ROUTES,
      });
    } else if (
      params?.field === tripFieldsConfig.manifestId.fieldName &&
      data?.manifestBaseDetails?.manifestId
    ) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.LOAD,
        id: data?.manifestBaseDetails?.manifestId,
        defaultTab: EMyLoadDetailsPanelTabs.ROUTES,
      });
    } else if (params?.field === tripFieldsConfig.tripId.fieldName) {
      setSecondaryPanelData({
        type: ESecondaryDetailsPanelType.TRIP,
        id: data.id,
      });
    }
  };

  const updateTripList = ({
    deletedIds,
    updatedIds,
    skipOpenDetailsPanel,
  }: {
    deletedIds?: string[];
    updatedIds?: string[];
    skipOpenDetailsPanel?: boolean;
  }) => {
    updateMainData(
      {
        deletedIds,
        updatedIds,
      },
      (trips) => {
        if (skipOpenDetailsPanel) return;
        const panelId =
          secondaryPanelData?.id || deletedIds?.[0] === updatedIds?.[0]
            ? updatedIds?.[1]
            : updatedIds?.[0];
        if (trips.some(({ id }) => id === panelId)) {
          setSecondaryPanelData({
            type: ESecondaryDetailsPanelType.TRIP,
            id: panelId as string,
            isGlobal: false,
          });
        }
      }
    );
  };

  const handleSortChanged = (model: GridSortModel): void => {
    const sortOptions = getSortOption(model, '-createDate');
    const updatedData = {
      nextPageNumber: 1,
      nextFilters: {
        ...filters,
        sort: sortOptions,
      },
    };
    sendRequestHandler(updatedData);
    const storageData = LStorageHook.getStorage() || {};
    LStorageHook.updateStorage(unassignedTripsId, {
      ...storageData[unassignedTripsId],
      query: { ...storageData[unassignedTripsId].query, sort: sortOptions },
    });
  };

  const onRowsScrollEnd = (): void => {
    //Important: Call after the first time
    if (isLoading || last || !tableData.length) return;
    sendRequestHandler({
      nextPageNumber: pageNumber + 1,
      nextFilters: {
        ...filters,
        statuses: gapForTripAssignment?.id
          ? AVAILABLE_TRIPS_STATUS_FILTER
          : filters?.statuses,
        onHoldState: gapForTripAssignment
          ? ({
              value: OnHoldStates.NO_HOLD,
              label: TripsConstants.onHoldState,
            } as any)
          : undefined,
      },
    });
  };

  const handleSelectItem = (
    params: GridRowParams<Trip & { isPinnedRows?: boolean }>
  ) => {
    const data = params.row;
    if (!data?.isPinnedRows)
      onClickHandler({
        data,
      });
  };

  const onCellClick = async (params: GridCellParams): Promise<void> => {
    onClickHandler({
      data: params.row,
      params,
    });
  };

  const onGridAction =
    (actionFn: any) =>
    (...params: any) => {
      sendRequestHandler({
        nextPageNumber: 1,
        nextFilters: {
          ...filters,
          ...actionFn(...params),
        },
      });
    };

  const onCreatedLoadHandler = async (load: LoadDetailsResponse) => {
    const requestData = new ByLoadIdRequest();
    requestData['loadId'] = load.id;
    const routesInfoResponse = await loadService
      .getLoadRoutes(requestData)
      .then();
    if (
      !(routesInfoResponse instanceof ServiceError) &&
      routesInfoResponse.data
    ) {
      const routesInfo = new RoutesInfo(routesInfoResponse.data);
      const tripIds = routesInfo.trips.map(({ id }) => id);
      updateTripList({
        updatedIds: tripIds,
      });
    }
    setNewLoadData(null);
  };

  const tableConfigurationProps = {
    TableSettings: {
      handleChangeForColumnModel: setColumnVisibilityModel,
      tableColumnsData: allColumns.filter(
        (item) =>
          item.field !== tripFieldsConfig.warning.fieldName &&
          item.field !== tripFieldsConfig.plusButton.fieldName
      ),
      resetSettings: resetSettings,
      handleChangeColumns: handleChangeColumns,
      getExcel: exportToExcelHandler,
    },
  };

  const { getBulkActionConfig, changeSelectionModel } = useUnassgnedGridActions(
    {
      tableData,
      getGridActionState,
      setGridActionState,
      changeStatusBulkSelect,
      gapForTripAssignment,
      currentDriversCountToOptimize,
      currentTripsCountToOptimize,
      hasBulkOptimizationPermission,
      hasLoadCRUDPermission,
      onBulkOptimization: () => handleBulkOptimization(),
    }
  );

  const handleBulkOptimization = () => {
    setOptimizationRequestResources({
      driverIds: getBulkOptymizationInputs.driverIds,
      tripIds: getBulkOptymizationInputs.tripIds,
    });
    setOptimizationSource(OPTIMIZATION_SOURCES.DISPATCH);
    startBulkOptymization();
  };

  const setupManualTripSelection = () => {
    const storageData = LStorageHook.getStorage() || {};
    const showPlusButton = gapForTripAssignment !== null;
    const columnsDataTmp = !currentView.default
      ? currentView.columnFilters
      : storageData[currentView.id].columns;
    columnsDataTmp[tripFieldsConfig.plusButton.fieldName] = showPlusButton;
    let params: any = {};
    if (showPlusButton) {
      //This assign trip valid for only 'Available' trip, hence only 'Available' trips should be shown.
      params = {
        nextPageNumber: 1,
        nextFilters: {
          ...filters,
          onHoldState: gapForTripAssignment
            ? {
                value: OnHoldStates.NO_HOLD,
                label: TripsConstants.onHoldState,
              }
            : undefined,
          terminalIds: gapForTripAssignment?.terminalId,
          statuses: getFilterStatusList().filter(
            ({ value }) => value === ELoadStatus.AVAILABLE
          ),
        },
      };
    } else {
      params = {
        nextPageNumber: 1,
        nextFilters: {
          ...filters,
          onHoldState: gapForTripAssignment
            ? {
                value: OnHoldStates.NO_HOLD,
                label: TripsConstants.onHoldState,
              }
            : undefined,
        },
      };
    }
    setSettingsPagination((old) => ({
      ...old,
      pageNumber: params.nextPageNumber,
    }));
    debouncedChangeHandler(params);
    setColumnVisibilityModel(columnsDataTmp);
  };

  const colVisibilityModel: Record<string, boolean> | undefined =
    useMemo(() => {
      if (!columnVisibilityModel) return undefined;
      return {
        ...columnVisibilityModel,
        [tripFieldsConfig.plusButton.fieldName]: gapForTripAssignment !== null,
      };
    }, [
      JSON.stringify(columnVisibilityModel),
      JSON.stringify(gapForTripAssignment),
    ]);
  //Side effect section begin
  useEffect(() => {
    setFilters(defaultFilters);
  }, []);
  useEffect(() => {
    //Important: Call in the first time
    const defaultFilters = setDefaultFilters();
    if (!preferences) return;
    sendRequestHandler({
      nextPageNumber: 1,
      nextFilters: { ...defaultFilters, ...sortRequestData },
    });
  }, [
    isTerminalEnabled,
    getGlobalTerminals?.length,
    preferences?.calculateDetention,
  ]);

  useEffect(() => {
    setDefaultColumns();
    setDefaultSorting();
  }, [preferences?.timezone]);

  useEffect(() => {
    if (isPageInitialRender?.current) {
      isPageInitialRender.current = false;
      return;
    }
    setupManualTripSelection();
  }, [JSON.stringify(gapForTripAssignment)]);
  //Side effect section end

  const { bulkAction, checkboxSelection, selectionModel } =
    getBulkActionConfig();

  const handleRowSelect = (ids: GridSelectionModel) => {
    changeSelectionModel(ids as string[]);
  };

  useEffect(() => {
    const selectedAvailableTrips = tableData?.filter?.(
      (e) =>
        getGridActionState?.ids?.includes?.(e?.id) &&
        e?.status === ELoadStatus.AVAILABLE &&
        !e?.onHold
    );
    updateBulkOptymizationTripInput(
      selectedAvailableTrips?.map?.((e) => e?.id) ?? []
    );
  }, [getGridActionState?.ids]);

  const pinnedRows = useMemo(
    () => ({
      bottom: [
        {
          id: Math.random(),
          [tripFieldsConfig.warning.fieldName]: {},
          [tripFieldsConfig.revenue.fieldName]:
            revenueTotalResponse?.totalRevenue !== undefined &&
            !isNaN(revenueTotalResponse?.totalRevenue)
              ? revenueTotalResponse.totalRevenue
              : 'loading',
          [tripFieldsConfig.settlementAmount.fieldName]:
            revenueTotalResponse?.totalSettlementAmount !== undefined &&
            !isNaN(revenueTotalResponse?.totalSettlementAmount)
              ? revenueTotalResponse.totalSettlementAmount
              : 'loading',
          isPinnedRows: true,
          pinned: true,
        },
      ],
    }),
    [revenueTotalResponse]
  );
  return (
    <>
      <Box id="available-trips-wrapper" sx={ContainerStyles}>
        <TripsLayoutHeader
          tableConfigurationProps={tableConfigurationProps}
          onPageFilterChange={onGridAction(onPageFilterChange)}
          onAllFilterSubmit={onGridAction(onAllFilterSubmit)}
          onAllFilterReset={() => {
            const nextFilters = onAllFilterReset();
            sendRequestHandler({
              nextPageNumber: 1,
              nextFilters: nextFilters,
            });
          }}
        />
        <Box
          sx={{
            ...GanttChartStyles,
            display: fullscreenPanel === IPanelName.DRIVER ? 'none' : undefined,
          }}
        >
          <StyledCommonDataGrid
            footerWidth={
              secondaryPanelData
                ? 'calc(100% - 34px - 514px - 105px - 24px - 16px - 10px)!important'
                : undefined
            }
          >
            <CommonDataGrid
              rows={tableData}
              columns={allColumns}
              disableSelectionOnClick
              isRowSelectable={(param) => true}
              selectionModel={selectionModel}
              {...getBulkActionConfig()}
              selectRow={handleRowSelect}
              bulkAction={bulkAction}
              colVisibilityModel={colVisibilityModel}
              onSortModelChange={handleSortChanged}
              sortingMode="server"
              density="standard"
              rowHeight={32}
              getRowId={(row: Trip) => row.id}
              onRowsScrollEnd={onRowsScrollEnd}
              onCellClick={onCellClick}
              handleSelectItem={handleSelectItem}
              totalNumberOfRows={totalRows}
              onColumnOrderChange={(params, event, details) => {
                onColumnOrderChangeHandler(params, event, details);
              }}
              checkboxSelection={checkboxSelection as boolean}
              gridCustomeStyle={{
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                  width: '10px',
                  height: '10px',
                  backgroundColor: '#f1f1f1',
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
              }}
              pinnedRows={gapForTripAssignment ? undefined : pinnedRows}
              containerStyles={{ pt: 0 }}
            />
          </StyledCommonDataGrid>
        </Box>
      </Box>
      <CreateLoad
        open={Boolean(newLoadData)}
        onClose={() => setNewLoadData(null)}
        onLoadCreated={(response) => onCreatedLoadHandler(response)}
      />
    </>
  );
};

export default observer(TripWrapper);
