import React, { FC } from 'react';
import {
  ITripDetailsPanelContext,
  TripDetailsPanelContextProvider,
} from '../../../../contexts/TripDetailsPanelContext';
import TripDetailsPanel, { TripDetailsPanelProps } from './TripDetailsPanel';

interface TripDetailsPanelWrapperProps extends TripDetailsPanelProps {
  callbacks?: ITripDetailsPanelContext;
}
const TripDetailsPanelWrapper: FC<TripDetailsPanelWrapperProps> = ({
  callbacks = {},
  ...props
}) => {
  return (
    <TripDetailsPanelContextProvider {...callbacks}>
      <TripDetailsPanel {...props} />
    </TripDetailsPanelContextProvider>
  );
};

export default TripDetailsPanelWrapper;
