/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Accordion from '../../../../../../common/Accordion';
import ThreeDotMenuIcon from '../../../../../../ui-kit/components/ThreeDotMenuIcon';
import { renderDateTime } from '../../../../../../utils/NameAndDateTimeGridField';
import InsuranceForm from './InsuranceForm';

export default function InsuranceListForm({
  index,
  isCreatePanel,
  onChangeCarrierInsuranceDocument,
  ThreeDotMenuSettings,
  canEdit,
}: {
  index: number;
  isCreatePanel?: boolean;
  onChangeCarrierInsuranceDocument: (files: File[] | null) => void;
  ThreeDotMenuSettings: any;
  canEdit: boolean;
}) {
  const [expanded, setExpanded] = useState(true);
  const { watch } = useFormContext();
  const [type, expDate, provider] = watch([
    `insurances.${index}.type`,
    `insurances.${index}.expDate`,
    `insurances.${index}.provider`,
  ]);

  const titleRenderer = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: (theme) => {
            return expanded
              ? `${theme.palette.primary.contrast}`
              : `${theme.palette.grey['100']}`;
          },
        }}
      >
        <div>
          <Typography
            variant="h7"
            sx={{
              color: expanded ? 'primary.main' : 'text.primary',
            }}
          >
            {type?.value}
          </Typography>
          {!expanded && (
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant="body3" sx={{ color: 'text.secondary' }}>
                {renderDateTime(expDate)}
              </Typography>
              <Typography variant="body3" sx={{ color: 'text.secondary' }}>
                {provider}
              </Typography>
            </Box>
          )}
        </div>
        {canEdit && (
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '-16px',
            }}
          >
            <ThreeDotMenuIcon {...ThreeDotMenuSettings} />
          </div>
        )}
      </Box>
    );
  };
  return (
    <Box
      sx={{
        border: (theme) => {
          return expanded ? `1px solid ${theme.palette.primary.main}` : '';
        },
        borderRadius: '8px',
        padding: '5px 5px',
        marginTop: '20px',
        backgroundColor: (theme) => {
          return expanded
            ? `${theme.palette.primary.contrast}`
            : `${theme.palette.grey['100']}`;
        },
        width: '100%',
      }}
    >
      <Accordion
        summaryRenderer={titleRenderer}
        updateParentState={setExpanded}
      >
        <InsuranceForm
          index={index}
          onChangeCarrierInsuranceDocument={onChangeCarrierInsuranceDocument}
          canEdit={canEdit}
        />
      </Accordion>
    </Box>
  );
}
