import { Divider, Stack } from '@mui/material';
import { LoadsIcon } from '../../../../ui-kit/components/Assets';
import { CustomizeCardHeader, LoadKPICard } from '../../../../common/Dashboard';
import {
  eLoadOperationalChart,
  LoadOperationalChartProps,
} from '../../../../common/Dashboard/Chart/LoadOperationalChart';
import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import { useStores } from '../../../../store/root.context';
import { StyledComponentRoot } from '../../styles/styles';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';
export const LoadOperationalSummary = observer((): JSX.Element => {
  const { dashboardStore } = useStores();
  const { tripsSummaryData, tripsSummaryLoader } = dashboardStore;
  const { isTablet } = useThemeResponsive();
  const openLoadChartList: LoadOperationalChartProps[] = [
    {
      id: 'tripsNeedDriver',
      total: tripsSummaryData?.openTripsTotal?.count || 0,
      value: tripsSummaryData?.tripsNeedDriver
        ? tripsSummaryData.tripsNeedDriver.count
        : 0,
      chartTitle: t('needsAttention'),
      type: eLoadOperationalChart.NeedsAttention,
      loader: tripsSummaryLoader,
    },
    // {
    //   total: 2,
    //   title: 'Potential Delay',
    //   color: theme.palette.warning.main,
    // },
  ];
  const assignedLoadChartList: LoadOperationalChartProps[] = [
    {
      id: 'assignedPotentialDelay',
      total: tripsSummaryData?.assignTripsTotal?.count || 0,
      value: tripsSummaryData?.assignedPotentialDelay
        ? tripsSummaryData.assignedPotentialDelay.count
        : 0,
      chartTitle: t('potentialDelay'),
      type: eLoadOperationalChart.PotentialDelay,
      loader: tripsSummaryLoader,
    },
  ];

  return (
    <StyledComponentRoot height={isTablet ? '450px' : '500px'}>
      <CustomizeCardHeader
        avatar={<LoadsIcon width={30} height={30} color="white" />}
        title={t('tripOperationalSummary')}
      />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={2}
        width={'100%'}
      >
        <LoadKPICard
          id="openTripsTotal"
          groupTitle={t('openTrips')}
          chartList={openLoadChartList}
        />
        <LoadKPICard
          id="assignTripsTotal"
          groupTitle={t('assignedTrips')}
          chartList={assignedLoadChartList}
        />
      </Stack>
    </StyledComponentRoot>
  );
});
