export const styles = {
  notesContainer: {
    overflowY: 'auto',
  },
  singleNoteContainer: {
    borderColor: 'primary.main',
    borderWidth: 1,
    borderRadius: 2,
    marginTop: 2,
    padding: 2,
    backgroundColor: '#FEF7EB',
  },
  listItem: {
    padding: 1,
    borderRadius: 4,
    backgroundColor: '#FEF7EB',
    mt: 1,
  },
  date: {
    color: 'text.secondary',
    fontSize: 10,
  },
  userName: {
    color: 'text.primary',
    fontSize: 12,
  },
  subShare: {
    color: 'text.primary',
    fontSize: 12,
  },
  shareDriver: {
    color: 'text.primary',
    fontSize: 16,
    fontWeight: '700',
  },
  driverUnchecked: {
    borderRadius: 2,
    borderWidth: 1,
    width: 'auto',
    borderColor: 'text.secondary',
    alignItems: 'center',
  },
  driverChecked: {
    borderRadius: 2,
    borderWidth: 1,
    width: 'auto',
    backgroundColor: 'primary.main',
    alignItems: 'center',
  },
};

export const NotesStyles = styles;
