import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  GetGlobalSerarchDTO,
  GetLoadByIDDTO,
  GetNotificationCountDTO,
  GetNotificationListDTO,
  NotesListRes,
  TerminalListResponse,
  UpdateReceivedDTO,
} from '../../models';
import { QueryParams } from '../../models/DTOs/commonMixed/QueryParams';
import { annotateNameAsync, ILayoutService } from '../interfaces';

const getGlobalSearchData = '/web/genus/api/v2/genus/search';
const getGlobalTerminal = '/web/preference/api/v2/preferences/terminals/list';
const updateReceivedURL =
  '/web/quasar/api/v2/notifications/load/messages/message/received';
const readSingle = '/web/quasar/api/v2/notifications/load/messages/message';
const getCountURL = '/web/quasar/api/v2/notifications/load/messages/count';
const getListURL = '/web/quasar/api/v2/notifications/load/messages/all';
const getLoadbyIDURL = '/web/load/api/v2/details/load/details/request';

export class LayoutService extends ILayoutService {
  requestData: QueryParams;
  constructor() {
    super();
    this.requestData = new QueryParams();
  }

  @annotateNameAsync
  async getGlobalSearchResponseData(requestData: GetGlobalSerarchDTO) {
    try {
      return await httpClient.get(
        getGlobalSearchData,
        requestData,
        TerminalListResponse,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getGlobalTerminals() {
    try {
      const requestData = new QueryParams();
      requestData.addUserIdToParams();
      return await httpClient.get(
        getGlobalTerminal,
        requestData,
        TerminalListResponse,
        undefined,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getLoadByIDData(requestData: GetLoadByIDDTO) {
    try {
      return await httpClient.get<NotesListRes>(
        getLoadbyIDURL,
        requestData,
        NotesListRes,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async updateReceivedData(payloadData: UpdateReceivedDTO) {
    try {
      return await httpClient.put(
        updateReceivedURL,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async updateSingleRead(payloadData: UpdateReceivedDTO) {
    try {
      return await httpClient.put(readSingle, undefined, payloadData, false);
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getNotificationCountData(requestData: GetNotificationCountDTO) {
    try {
      return await httpClient.get(
        getCountURL,
        requestData,
        TerminalListResponse,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getNotificationListData(requestData: GetNotificationListDTO) {
    try {
      return await httpClient.get(
        getListURL,
        requestData,
        TerminalListResponse,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
}
