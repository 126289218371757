/* eslint-disable react-hooks/rules-of-hooks */
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import { Box, Collapse, SxProps, Theme } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { IExpandMetaData, useExpandableMetaData } from '../hooks';
import {
  StyledArrowForwardIos,
  StyledLeftSubTitle,
  StyledTimelineOppositeContent,
  StyledTimelineSeparator,
} from '../styles';

export interface CommonExpandableTimelineProps<TRecord> {
  timelineContent: CommonExpandableTimelineContentProps<TRecord>[];
  expandedContent?: JSX.Element;

  isMultipleExpanded?: boolean;

  positionCollapseIcon?: 'left' | 'right';
  CollapseIcon?: any;

  //extends TimelineProps;
  position?: 'left' | 'right' | 'alternate';
  sx?: SxProps<Theme>;
}

export type TimelineDotColorType =
  | 'inherit'
  | 'grey'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | undefined;
export type TimelineVariantType = 'filled' | 'outlined' | undefined;

export interface CommonExpandableTimelineContentProps<TRecord> {
  id: string; //it should be unique ID
  previousSelectedId?: string;
  variant: TimelineVariantType;
  isHideCollapse: boolean;

  isOpenCollapseDefault?: boolean; //set default State to open collapse
  hide?: boolean;
  styleProps?: SxProps<Theme>;
  needsConnector: string;
  timelineConnectorSxProps?: SxProps<Theme>;

  enableSecondConnector?: boolean;
  timelineSecondConnectorSxProps?: SxProps<Theme>;

  timelineSeparatorSxProps?: SxProps<Theme>;

  renderLeftTitle: (params: {
    isActive: boolean;
    expanded?: boolean;
  }) => ReactNode;
  renderLeftSubTitles: () => ReactNode[];
  renderTimelineSeparatorIcon: (isActive: boolean) => ReactNode;
  renderRightTitle: (params: {
    isActive: boolean;
    expanded?: boolean;
  }) => ReactNode;
  renderRightSubTitle: (params: {
    isActive: boolean;
    expanded?: boolean;
  }) => ReactNode;
  renderRightSubContent: (params: {
    isActive: boolean;
    expanded?: boolean;
  }) => ReactNode;
  renderRightPrependSubContent?: (params: {
    isActive: boolean;
    expanded?: boolean;
  }) => ReactNode;
  originalData?: TRecord;
}

export const CommonExpandableTimeline = <TRecord,>({
  timelineContent,
  expandedContent,
  isMultipleExpanded = false,

  positionCollapseIcon = 'left',
  CollapseIcon = KeyboardArrowRightIcon,

  //extends TimelineProps;
  position = 'right',
  sx = {},
}: CommonExpandableTimelineProps<TRecord>) => {
  if (timelineContent.length === 0) {
    return <></>; //nothing to render here
  }
  const initialMetaData: IExpandMetaData = {};
  timelineContent.forEach(
    (item: CommonExpandableTimelineContentProps<TRecord>) => {
      initialMetaData[item.id] = !!item.isOpenCollapseDefault;
    }
  );
  const [expandMetaData, setExpandMetaData] =
    useState<IExpandMetaData>(initialMetaData);
  const { handleExpand, canSetColorSelected } = useExpandableMetaData<TRecord>(
    expandMetaData,
    setExpandMetaData,
    isMultipleExpanded
  );

  const renderCollapseIcon = (
    item: CommonExpandableTimelineContentProps<TRecord>
  ): JSX.Element => {
    return (
      <StyledArrowForwardIos
        CollapseIcon={CollapseIcon}
        isActive={canSetColorSelected(item)}
        isHideCollapse={item.isHideCollapse}
        {...(item.isHideCollapse
          ? {}
          : {
              onClick: () => handleExpand(item),
            })}
      />
    );
  };

  return (
    <Timeline
      position={position}
      sx={{
        px: 0,
        ...sx,
      }}
    >
      {timelineContent
        .filter((item) => !item.hide)
        .map((item: CommonExpandableTimelineContentProps<TRecord>) => {
          return (
            <TimelineItem
              key={item.id} //Keys Must Only Be Unique Among Siblings
              sx={{
                paddingLeft: '10px',
                '&:before': {
                  content: 'none',
                },
                ...item.styleProps,
              }}
            >
              <StyledTimelineOppositeContent>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    cursor: 'pointer',
                  }}
                >
                  {positionCollapseIcon === 'left' && renderCollapseIcon(item)}

                  {item.renderLeftTitle({
                    isActive: canSetColorSelected(item),
                    expanded: expandMetaData[item.id],
                  })}
                </Box>
                <Box>
                  {item.renderLeftSubTitles().map((subTitle) => (
                    <StyledLeftSubTitle>{subTitle}</StyledLeftSubTitle>
                  ))}
                </Box>
              </StyledTimelineOppositeContent>

              <StyledTimelineSeparator
                isActive={canSetColorSelected(item)}
                sx={{ ...item.timelineSeparatorSxProps }}
              >
                {item.renderTimelineSeparatorIcon(canSetColorSelected(item))}
                <TimelineConnector
                  sx={{
                    display: item.needsConnector,
                    ...item.timelineConnectorSxProps,
                  }}
                />
                {/* TODO: some trip have 2 ways (first way is complete, the next way is in progress) */}
                {item.enableSecondConnector ? (
                  <TimelineConnector
                    sx={{
                      backgroundColor: 'action.disabled',
                      ...item.timelineSecondConnectorSxProps,
                    }}
                  />
                ) : (
                  <></>
                )}
              </StyledTimelineSeparator>

              <TimelineContent
                sx={{
                  padding: '6px',
                  zIndex: 1,
                  ...(positionCollapseIcon === 'right' && {
                    marginLeft: '10px',
                  }),
                }}
              >
                <Box
                  sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    ...(positionCollapseIcon === 'right' && {
                      marginLeft: '-22px',
                    }),
                  }}
                >
                  {positionCollapseIcon === 'right' &&
                    !item.isHideCollapse &&
                    renderCollapseIcon(item)}
                  <Box
                    sx={{
                      cursor: 'pointer',
                      width: '100%',
                    }}
                    {...(positionCollapseIcon === 'right' &&
                      !item.isHideCollapse && {
                        onClick: () => handleExpand(item),
                      })}
                  >
                    {item.renderRightTitle({
                      isActive: canSetColorSelected(item),
                      expanded: expandMetaData[item.id],
                    })}
                  </Box>
                </Box>

                {item.renderRightSubTitle({
                  isActive: canSetColorSelected(item),
                  expanded: expandMetaData[item.id],
                })}

                <Collapse
                  in={canSetColorSelected(item)}
                  mountOnEnter
                  unmountOnExit
                >
                  {expandedContent
                    ? expandedContent
                    : item.renderRightSubContent({
                        isActive: canSetColorSelected(item),
                        expanded: expandMetaData[item.id],
                      })}
                </Collapse>

                {item?.renderRightPrependSubContent?.({
                  isActive: canSetColorSelected(item),
                  expanded: expandMetaData[item.id],
                })}
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
};

export default CommonExpandableTimeline;
