import { Chip, useTheme } from '@mui/material';
import React from 'react';
import { TripStatusProps } from '../../types';

export const TripStatus = ({
  label,
  sx,
  ...restProps
}: TripStatusProps): JSX.Element => {
  const theme: any = useTheme();
  return (
    <Chip
      size="small"
      {...restProps}
      sx={{
        backgroundColor: 'secondary.light',
        color: 'primary.contrast',
        height: '26px',
        marginLeft: '10px',
        ...theme.typography.chip,
        ...sx,
      }}
      label={label}
    />
  );
};
