import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { LOADAI_ELEMENTS } from '../../../constants/elementIds';
import { DISPATCH_TOOLTIPS } from '../../../constants/tooltips/tooltip_messages';
import { Trip } from '../../../models';
import { IGapAssignment } from '../../../store/pageStores/dispatch';
import { IDataGridProProps } from '../../../ui-kit/components/DataGridPro';
import {
  EUnassignedTripAction,
  EUnassignedTripMenuAction,
  IGridActionState,
} from '../../trips/constants/types';
import { BulkActionDisabled } from '../components/Trip/components/BulkActionDisabled';
import { isNonAvailableTripSelected } from '../services/grid.service';

export const useUnassgnedGridActions = ({
  tableData,
  getGridActionState,
  setGridActionState,
  changeStatusBulkSelect,
  gapForTripAssignment,
  currentDriversCountToOptimize,
  currentTripsCountToOptimize,
  onBulkOptimization,
  hasBulkOptimizationPermission,
  hasLoadCRUDPermission,
}: {
  tableData: Trip[];
  getGridActionState: IGridActionState | null;
  setGridActionState: (newData: IGridActionState | null) => void;
  changeStatusBulkSelect: (
    nextStatus: EUnassignedTripMenuAction
  ) => Promise<void>;
  gapForTripAssignment: IGapAssignment | null;
  currentDriversCountToOptimize?: number;
  currentTripsCountToOptimize?: number;
  onBulkOptimization?: () => void;
  hasBulkOptimizationPermission?: boolean;
  hasLoadCRUDPermission?: boolean;
}) => {
  const getBulkActionConfig = (): Pick<
    IDataGridProProps,
    | 'bulkAction'
    | 'overBulkActionRender'
    | 'checkboxSelection'
    | 'selectionModel'
    | any
  > => {
    if (gapForTripAssignment && getGridActionState !== null) {
      //reset BULK_SELECT action
      setGridActionState(null);
    }

    if (getGridActionState?.action === EUnassignedTripAction.BULK_SELECT) {
      const isBulkActionDisabled: boolean =
        tableData
          .filter(
            ({ id, status, onHold }) =>
              getGridActionState.ids.includes(id) &&
              status === ELoadStatus.ASSIGNMENT_PLANNED &&
              !onHold
          )
          .map(({ id }) => id).length !== getGridActionState.ids.length;
      const isBulkOptimizeDisabled = isNonAvailableTripSelected({
        selectedIds: getGridActionState?.ids,
        allRecords: tableData,
      });
      const bulkAction = [];
      if (hasLoadCRUDPermission)
        bulkAction.push(
          ...[
            {
              iconName: 'ArrowBackOutlined',
              iconSource: 'MUIcons',
              label: 'Mark Available',
              disabled: isBulkActionDisabled,
              customeTootTip: isBulkActionDisabled
                ? [DISPATCH_TOOLTIPS.TRIP_STATUS_CHANGE_FOR_PLANNED]
                : [],
              onClick: () => {
                changeStatusBulkSelect(
                  EUnassignedTripMenuAction.MARK_AVAILABLE
                );
              },
            },
            {
              iconName: 'ArrowForwardOutlined',
              iconSource: 'MUIcons',
              label: 'Mark Assigned',
              disabled: isBulkActionDisabled,
              customeTootTip: isBulkActionDisabled
                ? [DISPATCH_TOOLTIPS.TRIP_STATUS_CHANGE_FOR_PLANNED]
                : [],
              onClick: () => {
                changeStatusBulkSelect(EUnassignedTripMenuAction.MARK_ASSIGNED);
              },
            },
          ]
        );
      if (hasBulkOptimizationPermission)
        bulkAction?.unshift?.({
          id: LOADAI_ELEMENTS.TRIP_BULK_TRAY_OPTIMIZATION,
          iconName: isBulkOptimizeDisabled ? 'OptymLogoDisabled' : 'OptymLogo',
          iconSource: 'OptymIcon',
          label: 'Optymize',
          disabled: isBulkOptimizeDisabled,
          customeTootTip: isBulkOptimizeDisabled
            ? [DISPATCH_TOOLTIPS.OPTIMIZATION_FOR_AVAILABLE]
            : [
                `${currentDriversCountToOptimize} Drivers`,
                `${currentTripsCountToOptimize} Trips`,
              ],
          onClick: () => {
            onBulkOptimization?.();
          },
        });
      return {
        overBulkActionRender: () =>
          isBulkActionDisabled ? <BulkActionDisabled /> : <></>,
        bulkAction,
        checkboxSelection: gapForTripAssignment === null,
        selectionModel: getGridActionState?.ids || [],
      };
    }
    return {
      overBulkActionRender: undefined,
      bulkAction: undefined,
      checkboxSelection: gapForTripAssignment === null,
      selectionModel: getGridActionState?.ids || [],
    };
  };
  const changeSelectionModel = (newModel: string[]) => {
    setGridActionState({
      action: EUnassignedTripAction.BULK_SELECT,
      ids: newModel,
    });
  };
  return {
    getBulkActionConfig,
    changeSelectionModel,
  };
};
