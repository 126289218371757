import { Box, Typography } from '@mui/material';
import oldLog from '../../../../../_assets/images/NoLog.png';
import {
  activityHeaderTextStyle,
  activityTextContainerStyle,
  noActivityImg,
  noActivityText,
} from '../styles';

export const OldLogMessage = () => {
  return (
    <Box textAlign={'center'}>
      <Box sx={{ ...activityTextContainerStyle }}>
        <sup>
          <Typography sx={{ ...activityHeaderTextStyle }}>
            Load Activity
          </Typography>
        </sup>
      </Box>
      <img src={oldLog} alt={'No Logs'} style={{ ...noActivityImg }} />
      <Typography sx={{ ...noActivityText }}>No activity available</Typography>
      <Typography fontSize={'16px'}>
        Only activity for loads created after May 26, 2023 will be shown
      </Typography>
    </Box>
  );
};
