import StorageManager from '../../StorageManager/StorageManager';
import { invoiceService, loadService, staticService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import { getOrganizationId } from '../../common/TimeoffDialog/utils';
import {
  BulkSubmitRequest,
  DeleteInvoiceRequest,
  DisconnectFromFactoringRequest,
  DownloadInvoicePreviewRequest,
  DownloadInvoicesAsZipRequest,
  DownloadInvoicesRequest,
  ExportInvoiceListRequest,
  FactoringFormType,
  GetDriverMappingDetailsRequest,
  GetFactoringStaticRequest,
  GetIntegrationsRequest,
  GetInvoiceActionDataRequest,
  GetInvoiceLineItemsRequest,
  GetInvoiceListRequest,
  GetInvoiceSearchLoadsRequest,
  GetInvoiceWarningsRequest,
  GetloadIdListRequest,
  InvoiceToTurvoRequest,
  InvoiceWarningType,
  PreviewInvoiceRequest,
  RefreshFactoringStatusRequest,
  RefreshLoadAndTripsDriversRequest,
  SendEmailRequest,
  SendFactoringBulkRequest,
  SendFactoringRequest,
  SendFactoringRequestType,
  StatusChangeRequest,
  StatusChangeSingleRequest,
  UpdateInvoiceRequest,
} from '../../models';
import { View, ViewMetaData } from '../../types';
import StaticActions from '../../utils/StaticActions';
import DocumentActions from '../../views/reports/documents/DocumentActions';
import {
  EmailFormType,
  InvoiceModel,
  InvoiceUpdateModel,
  LineItem,
} from './models/InvoiceModel';
import {
  fixUserDefinedItems,
  getEmailDefaultReceiver,
  getEmailOrganizationName,
  getIfNeedAddToTable,
  getInvoiceEmailDefaultMessage,
  getInvoiceEmailDefaultSubject,
  getInvoiceEmailDocumentList,
  getInvoiceEmailDocumentListObjects,
  getInvoiceFilteredRequest,
  getInvoiceFiltersForRequest,
  getSendDocumentTypes,
} from './utils';

class InvoiceActions {
  // keep all states static data
  static states = [];

  // keep invoice line items map here
  static invoiceLineItemsMap = <{ [key: string]: LineItem }>{};

  // keep invoice line items map here
  static invoiceLineItemsList: LineItem[] = [];

  // keep invoice terms here
  static invoiceTerms = [];

  // getting invoices list for grid view
  static async getInvoiceList(queryParams: { [key: string]: string | number }) {
    const request = new GetInvoiceListRequest();
    request.pageSize = queryParams.pageSize as number;
    request.pageNumber = queryParams.pageNumber as number;
    request.sort = queryParams.sort as string;
    let payLoad = new GetInvoiceListRequest();
    payLoad = getInvoiceFilteredRequest(queryParams, payLoad);
    const response = await invoiceService.getInvoiceList(request, payLoad);
    if (response instanceof ServiceError) {
      return;
    } else {
      response.content = response.content.map((invoice) => {
        invoice.lineItems = fixUserDefinedItems(invoice.lineItems);
        return new InvoiceModel(invoice);
      });
      return response;
    }
  }

  // create Invoice record
  static async createInvoice(payLoad: any) {
    const response = await invoiceService.createInvoice(payLoad);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // getting invoice warnings by ids
  static async getWarnings(invoiceIds: string[]) {
    const request = new GetInvoiceWarningsRequest();
    request.ids = invoiceIds;
    const response = await invoiceService.getInvoiceWarningsList(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  // getting invoices list for grid view
  static async getInvoiceTotals(queryParams: {
    [key: string]: string | number;
  }) {
    const request = new GetInvoiceListRequest();
    let payLoad = new GetInvoiceListRequest();
    payLoad = getInvoiceFilteredRequest(queryParams, payLoad);
    const response = await invoiceService.getInvoiceTotals(request, payLoad);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // edit new invoice line item
  // comes from StaticActions.tsx
  static async getStates() {
    const response = await StaticActions.getStates();
    if (response) {
      const { states } = response;
      this.states = states.forEach(
        (state: { abbreviation: string; name: string }) => ({
          [state.abbreviation]: state.name,
        })
      );
    }
  }

  // getting invoice line items
  static async getInvoiceLineItems() {
    const request = new GetInvoiceLineItemsRequest();
    request.isInvoiceItem = true;
    const response = await invoiceService.getInvoiceLineItems(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      const obj: Record<string, LineItem> = {};
      response.forEach((item: LineItem) => {
        obj[`${item.id}`] = item;
        if (
          item.itemCode?.startsWith('USERDEFINED_DEDUCTION') ||
          item.itemCode?.startsWith('USERDEFINED_PAYMENT')
        ) {
          item.defaultItemCode = item.itemCode;
          item.itemCode = `${item.itemCode}_${item.id}`;
        }
      });
      this.invoiceLineItemsMap = obj as { [key: string]: LineItem };
      this.invoiceLineItemsList = response;
      return obj;
    }
  }

  static async getInvoiceBaseLineItems() {
    if (!this.invoiceLineItemsList?.length) {
      await InvoiceActions.getInvoiceLineItems();
    }
    const baseLineItems = this.invoiceLineItemsList?.filter(
      (item) => item.itemType === 'BASE'
    );
    return baseLineItems;
  }

  static prepareInvoiceSaveRequest = (data: InvoiceUpdateModel) => {
    const requestData: any = { ...data };
    requestData.lineItems.forEach(
      (item: {
        amountDue?: number;
        type: string;
        isContract?: string;
        deduction?: boolean;
      }) => {
        delete item.amountDue;
        delete item?.isContract;
        delete item?.deduction;
        if (item.type?.startsWith('USERDEFINED_PAYMENT')) {
          item.type = 'USERDEFINED_PAYMENT';
        } else if (item.type?.startsWith('USERDEFINED_DEDUCTION')) {
          item.type = 'USERDEFINED_DEDUCTION';
        }
      }
    );
    delete requestData.customer;
    delete requestData.amountDueTotal;
    delete requestData.driverName;
    delete requestData.loadReferenceId;
    delete requestData.pinned;
    delete requestData.seqNumber;
    delete requestData.stops;
    delete requestData.terminal;
    delete requestData.groupDetails;
    delete requestData.assignedDrivers;
    delete requestData.nonInvoiceable;
    delete requestData.formFactoringChargeAmount;
    return requestData;
  };

  // getting invoice line items
  static async updateInvoice(data: InvoiceUpdateModel) {
    const requestData = this.prepareInvoiceSaveRequest(data);
    const response = await invoiceService.updateInvoice(requestData);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // getting invoice line items
  static async downloadInvoice(data: {
    invoiceId: string;
    documentIds: string[];
    pdfComplexity: string;
  }) {
    const request = new DownloadInvoicesRequest();
    request.id = data.invoiceId;
    request.documentIds = data.documentIds;
    request.invoicePdfComplexity = data.pdfComplexity;
    request.organizationId = getOrganizationId();
    const response = await invoiceService.downloadInvoice(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  static async downloadInvoicesAsZip(
    data: Record<string, boolean>,
    warnings: InvoiceWarningType,
    selectedInvoices: InvoiceModel[]
  ) {
    const res: Record<string, string[]> = {};
    selectedInvoices.forEach((invoice) => {
      if (warnings[invoice.id]) {
        const documents = warnings[invoice.id].assignedDocuments;
        if (documents.length) {
          documents.forEach((doc) => {
            if (
              (doc.documentType === 'BILL_OF_LADING' && data.billOfLading) ||
              (doc.documentType === 'PROOF_OF_DELIVERY' &&
                data.proofOfDelivery) ||
              (doc.documentType === 'RATE_CONFIRMATION' &&
                data.rateConfirmation) ||
              (![
                'BILL_OF_LADING',
                'PROOF_OF_DELIVERY',
                'RATE_CONFIRMATION',
              ].includes(doc.documentType) &&
                data.other)
            ) {
              if (res[invoice.id]) {
                res[invoice.id].push(doc.documentId);
              } else {
                res[invoice.id] = [doc.documentId];
              }
            }
          });
        }
      }
    });
    selectedInvoices.forEach((invoice) => {
      if (!res[invoice.id]) {
        res[invoice.id] = [];
      }
    });
    const invoiceDocumentsDTO = Object.keys(res).map((invoiceId) => ({
      id: invoiceId,
      documentIds: res[invoiceId],
      invoicePdfComplexity: data.pdfComplexity,
      organizationId: getOrganizationId(),
    }));
    const request = new DownloadInvoicesAsZipRequest();
    request.invoiceDocumentsDTO = invoiceDocumentsDTO;
    const response = await invoiceService.downloadInvoicesAsZip(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      await DocumentActions.downloadMultipleDataAsZip(response);
    }
  }

  // preview invoice
  static async previewInvoice(invoiceId: string) {
    const request = new PreviewInvoiceRequest();
    request.id = invoiceId;
    const response = await invoiceService.previewInvoice(request);
    if (response instanceof ServiceError) {
      return null;
    } else {
      return response;
    }
  }
  static async previewInvoiceDownload(invoice: InvoiceModel) {
    const request = new DownloadInvoicePreviewRequest();
    request.invoiceId = invoice.childInvoice
      ? invoice.masterInvoiceId
      : invoice.invoiceId;
    request.pdfComplexity = 'DETAILED';
    const response = await invoiceService.previewInvoiceDownload(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // send multiple emails
  static async sendMultipleEmail(
    invoices: InvoiceModel[],
    data: EmailFormType,
    getIsTerminalEnabled: boolean
  ) {
    if (invoices.length === 1) {
      return await this.sendEmail(data);
    }
    const sendDocumentTypes = getSendDocumentTypes(data);

    const user = StorageManager.getUser();
    const request = new SendEmailRequest();
    request.combinePdfDocuments = true;
    request.html = true;
    request.emailSendingRequestDTO = invoices.map((invoice) => {
      const emailOrganizationName = getEmailOrganizationName(
        invoice.terminal?.companyName,
        user.organizationName,
        getIsTerminalEnabled
      );
      return {
        cc: data.cc,
        to: [getEmailDefaultReceiver(invoice)].filter((email) => !!email),
        documentList: getInvoiceEmailDocumentListObjects(
          invoice,
          sendDocumentTypes,
          data.other
        ),
        invoiceList: [{ id: invoice.id, pdfComplexity: data.pdfComplexity }],
        message: getInvoiceEmailDefaultMessage(invoice, emailOrganizationName),
        subject: getInvoiceEmailDefaultSubject(
          invoice.seqNumber,
          emailOrganizationName,
          invoice.invoiceReferenceId
        ),
        type: 'invoice',
      };
    });
    const response = await invoiceService.sendEmail(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // send email
  static async sendEmail(data: EmailFormType) {
    const request = new SendEmailRequest();
    request.html = true;
    request.combinePdfDocuments = true;
    request.emailSendingRequestDTO = [
      {
        cc: data.cc,
        to: data.to,
        invoiceList: [
          { id: data.invoiceId, pdfComplexity: data.pdfComplexity },
        ] as unknown as { id: string }[],
        message: data.message,
        subject: data.subject,
        type: 'invoice',
        documentList: (data.documentIds as [])!.map((docId: string) => ({
          id: docId,
        })),
      },
    ];
    const response = await invoiceService.sendEmail(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // export excel
  static async exportInvoiceList(
    filters: ViewMetaData,
    columns: string[],
    sort: string
  ) {
    const request = new ExportInvoiceListRequest();
    const response = await invoiceService.exportInvoiceList(
      request,
      getInvoiceFiltersForRequest(filters, false),
      columns,
      sort
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // change invoice status to completed or cancelled
  static async statusChange(requestData: StatusChangeRequest) {
    const response = await invoiceService.statusChange(requestData);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }
  static async statusChangeSingle(requestData: StatusChangeSingleRequest) {
    const response = await invoiceService.statusChangeSingle(requestData);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // get mapping details for submit factoring action
  static async getDriverMappingDetails(
    factoringId: string,
    driverIds: string[]
  ) {
    const request = new GetDriverMappingDetailsRequest();
    request.factoringId = factoringId;
    request.driverIds = driverIds;
    const response = await invoiceService.getDriverMappingDetails(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // change invoice status to completed or cancelled
  static async updateLoad(
    loadUpdateData: { id: string; loadStatus: string }[]
  ) {
    const response = await loadService.updateLoad(loadUpdateData);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // factoring action -> send factoring
  static async sendFactoring(data: FactoringFormType) {
    const requestData = new SendFactoringRequestType(data);
    const response = await invoiceService.sendFactoring(requestData);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // factoring action -> send factoring bulk
  static async sendFactoringBulk(
    data: EmailFormType,
    selectedInvoices: InvoiceModel[]
  ) {
    const sendDocumentTypes = getSendDocumentTypes(data);
    const request = new SendFactoringBulkRequest();
    request.factoringId = selectedInvoices[0].factoringId!;
    request.factoringNote = data.note;
    request.invoicesCreate = selectedInvoices.map((invoice) => ({
      documentIds: getInvoiceEmailDocumentList(
        invoice,
        sendDocumentTypes,
        data.other
      ),
      factoringInvoiceId: invoice.factoringInvoiceId!,
      id: invoice.id!,
      loadId: invoice.loadId!,
    }));
    request.sendInvoice = data.sendInvoice;
    const response = await invoiceService.sendFactoringBulk(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // factoring action -> send factoring
  static async sendDocument(data: SendFactoringRequestType) {
    const request = new SendFactoringRequest();
    const response = await invoiceService.sendDocument(request, data);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // factoring action -> update at factoring
  static async updateAtFactoring(data: SendFactoringRequestType) {
    const request = new SendFactoringRequest();
    const response = await invoiceService.updateAtFactoring(request, data);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // factoring action -> refresh status
  static async refreshFactoringStatus(invoices: InvoiceModel[]) {
    const request = new RefreshFactoringStatusRequest();
    request.factoringId = invoices[0].factoringId!;
    request.invoiceFactoringDTO = invoices.map((invoice) => ({
      factoringInvoiceId: invoice.factoringInvoiceId!,
      invoiceId: invoice.id,
    }));
    const response = await invoiceService.refreshFactoringStatus(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // factoring action -> submit status
  static async bulkSubmit(data: any, invoices: InvoiceModel[]) {
    const request = new BulkSubmitRequest();
    data.fundings.forEach((funding) => {
      delete funding.id;
      funding.fundingId = funding.fundingIdObj.mappingId;
      delete funding.fundingIdObj;
    });
    request.sameDay = data.sameDay === 'sameDay';
    request.instructions = data.instructions;
    request.fundings = data.fundings.filter((f) => !!f.fundingId);
    request.factoringId = invoices[0].factoringId!;
    const invoiceIds: string[] = [];
    const axeleInvoiceIds: string[] = [];
    const invoiceNumbers: string[] = [];
    invoices.forEach((invoice) => {
      invoiceIds.push(invoice.factoringInvoiceId!);
      axeleInvoiceIds.push(invoice.id);
      invoiceNumbers.push(invoice.seqNumber);
    });
    request.axeleInvoiceIds = axeleInvoiceIds;
    request.invoiceIds = invoiceIds;
    request.invoiceNumbers = invoiceNumbers;
    const response = await invoiceService.bulkSubmit(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // factoring action -> disconnect from factoring
  static async disconnectFromFactoring(invoice: InvoiceModel) {
    const request = new DisconnectFromFactoringRequest();
    request.loadIds = [invoice.loadId];
    const response = await invoiceService.disconnectFromFactoring(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // get integrations data
  static async getIntegrations(terminalIds: string[]) {
    const request = new GetIntegrationsRequest();
    request.terminalIds = terminalIds;
    const response = await invoiceService.getIntegrations(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response['integrationsDTO'][100];
    }
  }
  static async changeStatusRequest(
    requestData: StatusChangeRequest,
    invoices: InvoiceModel[],
    status: string,
    tableData: InvoiceModel[],
    setTableData: (data: InvoiceModel[]) => void,
    currentView: View,
    setSelectedItem: (data: InvoiceModel) => void,
    disableUpdateItem = false
  ) {
    if (tableData) {
      let newTableData = [...tableData];
      for (const invoice of invoices) {
        if (invoice.loadStatus !== status) {
          if (getIfNeedAddToTable(status, tableData!, invoice, currentView)) {
            newTableData = [...[invoice], ...newTableData!];
          } else {
            newTableData = newTableData!.filter(
              (data) => data.id !== invoice.id
            );
          }
          if (!disableUpdateItem) {
            const updatedSelectedItem = new InvoiceModel({
              ...invoice,
              loadStatus: status,
            });
            setSelectedItem(updatedSelectedItem);
          }
        }
      }
      setTableData(newTableData);
    }
    const response = await InvoiceActions.statusChange(requestData);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async getFactoringStatic() {
    const request = new GetFactoringStaticRequest();
    const response = await staticService.getFactoringStatic(request);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  // load And TripsDrivers -> refresh status
  static async loadAndTripsDrivers(
    invoices: RefreshLoadAndTripsDriversRequest
  ) {
    const response = await invoiceService.loadAndTripsDrivers(invoices);
    if (!(response instanceof ServiceError)) {
      return response;
    }
  }

  static async sendInvoiceToProviderViaEdi(
    data: InvoiceModel,
    tenderId: string
  ) {
    const request = new UpdateInvoiceRequest();
    request.tenderId = tenderId;
    const requestData = this.prepareInvoiceSaveRequest(data);
    const response = await invoiceService.sendInvoiceToProviderViaEdi(
      request,
      requestData
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  static async sendInvoiceToTurvo(loadId: string) {
    const request = new InvoiceToTurvoRequest();
    request.loadId = loadId;
    const response = await invoiceService.sendInvoiceToTurvo(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  static async sendInvoiceToBitFreighter(loadId: string) {
    const request = new InvoiceToTurvoRequest();
    request.loadId = loadId;
    const response = await invoiceService.sendInvoiceToBitFreighter(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  static async getInvoiceSearchLoads(
    pageNumber: number,
    searchKey: string,
    customerId: string,
    terminalId: string,
    childInvoices: any
  ) {
    const queryParams = new GetInvoiceSearchLoadsRequest({
      pageNumber,
      pageSize: 25,
      customerId: customerId,
      terminalId: terminalId,
      search: searchKey,
    });
    const response = await invoiceService.getInvoiceSearchLoads(queryParams);

    if (!response || response instanceof ServiceError) {
      return {
        content: [],
        last: true,
      };
    }
    const { content, ...restResponse } = response;

    const parsedContents = content.filter((obj1) =>
      childInvoices.every((obj2) => obj1.invoiceId !== obj2.invoiceId)
    );

    return {
      ...restResponse,
      content: parsedContents,
    } as any;
  }

  // delete Invoice record
  static async deleteInvoice(id: string) {
    const request = new DeleteInvoiceRequest();
    request.id = id;
    const response = await invoiceService.deleteInvoice(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // get loads list
  static async getloadIdList(ids: Array<string>) {
    const request = new GetloadIdListRequest();
    request.ids = ids;
    const response = await invoiceService.getloadIdList(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }
  static async getInvoiceActionData(invoiceId: string) {
    const request = new GetInvoiceActionDataRequest();
    request.invoiceId = invoiceId;
    const response = await invoiceService.getInvoiceActionData(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }
}

export default InvoiceActions;

export interface LoadlistOptionsProps {
  destinationCity: string;
  destinationState: string;
  invoiceId: string;
  loadId: string;
  loadReferenceId: string;
  loadSeqNumber: string;
  originCity: string;
  originState: string;
  emptyMiles: number;
  loadedMiles: number;
  total: number;
}

export const generateLoadListOptions = (
  data: LoadlistOptionsProps
): LoadlistOptionsProps => {
  return {
    destinationCity: data.destinationCity,
    destinationState: data.destinationState,
    invoiceId: data.invoiceId,
    loadId: data.loadId,
    loadReferenceId: data.loadReferenceId,
    loadSeqNumber: data.loadSeqNumber,
    originCity: data.originCity,
    originState: data.originState,
    emptyMiles: data.emptyMiles,
    loadedMiles: data.loadedMiles,
    total: data.total,
  };
};
