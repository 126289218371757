export const SideBarConstants = {
  home: 'HOME',
  dispatch: 'DISPATCH',
  loads: 'LOADS',
  operations: 'OPERATIONS',
  finance: 'FINANCE',
  safety: 'SAFETY',
  reports: 'REPORTS',
  settings: 'SETTINGS',

  myLoads: 'Loads',
  myTrips: 'Trips',
  loadBoards: 'LoadBoards',
  myTemplates: 'Templates',
  LoadTenders: 'Load Tenders',

  operationsEquipment: 'Equipment',
  operationsContacts: 'Contacts',
  operationsTeamDriver: 'Team Driver',

  financeSummary: 'Summary',
  financeInvoices: 'Invoices',
  financeExpenses: 'Expenses',
  financeSettlements: 'Settlements',
  financePaymentManagement: 'Payments',

  safetyMaintenanceHistory: 'Maintenance History',
  safetyUpcomingEvents: 'Upcoming Events',

  reportsBasicReports: 'Basic Reports',
  reportsTrendAnalysis: 'Trend Analysis',
  reportsIftaCenter: 'IFTA Center',
  reportsDocument: 'Document',
  reportadvancedReport: 'Advanced Reports',

  settingsPreferences: 'Preferences',
  settingsIntegrations: 'Integrations',
  settingsUsers: 'Users',
  settingsAlerts: 'Alerts',
  settingsTerminals: 'Terminals',
  settingsManageTypes: 'Manage Types',
  settingsImport: 'Import',
};
