import { yupResolver } from '@hookform/resolvers/yup';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import EventEmitter from '../../../../EventEmitter/EventEmitter';
import EventOverviewChange from '../../../../EventEmitter/Events/EventOverviewChange';
import { ServiceError } from '../../../../api/interfaces';
import {
  ByLoadIdRequest,
  GetRemoveLoadManifestDataResp,
} from '../../../../models/DTOs/Loads/Requests';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../store/root.context';
import LoadController from '../../../../subPages/loadsList/LoadController';
import { LoadSummary } from '../../../../subPages/loadsList/models/LoadSummary';
import { getHttpErrorsFromResponse } from '../../../../utils';
import { ESecondaryDetailsPanelType } from '../../../../views/dispatch2/constants/types';
import { DirtyDetailsPanelManager } from '../../../DetailsPanel/utils';
import { Footer } from '../../../LoadSharedComponents/components/SaveChangesFooter';
import { Warnings } from '../../../LoadSharedComponents/components/Warnings';
import NoData from '../../component/NoData';
import { LoadDetails } from './components/LoadDetails';
import {
  loadOverviewDetailsDefaultData,
  loadReferenceDetailsValidationSchema,
} from './constants';
import { LoadOverviewDetails } from './models';
import { getLoadOverviewDetails, updateLoadReferenceDetails } from './utils';

interface IProps {
  load: LoadSummary;
  onUpdated: () => void;
  manifestDeleteForLoad?: (
    id: GetRemoveLoadManifestDataResp,
    status: boolean
  ) => void;
}

export const Overview = observer(
  ({ load, onUpdated, manifestDeleteForLoad }: IProps) => {
    const { trackPromise } = useDetailsPanelStore;
    const state = useForm({
      reValidateMode: 'onSubmit',
      defaultValues: { ...loadOverviewDetailsDefaultData },
      resolver: yupResolver(loadReferenceDetailsValidationSchema),
    }); //@TODO: Slavik - This component uses an internal useForm , needs to be refactored and connected to the main FormProvider!
    const {
      myLoadStore: { setUpdatedLoadId },
    } = useStores();

    const [httpErrors, setHttpErrors] = useState();
    const [noData, setNoData] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {
      handleSubmit,
      formState: { isDirty, errors },
      reset,
      setError,
      clearErrors,
    } = state;

    useEffect(() => {
      DirtyDetailsPanelManager.panelName = isDirty ? 'Overview' : null;

      DirtyDetailsPanelManager.isDirty = isDirty;
    }, [isDirty]);

    async function getWarnings() {
      await trackPromise(
        LoadController.instance().setupLoadWarnings([load]),
        ESecondaryDetailsPanelType.LOAD
      );
    }

    useEffect(() => {
      if (!load.warning) getWarnings();
      return () => {
        DirtyDetailsPanelManager.panelName = null;
        DirtyDetailsPanelManager.isDirty = false;
      };
    }, []);

    useEffect(() => {
      fetchData();
    }, [load]);
    const fetchData = async () => {
      const request = new ByLoadIdRequest();
      request[load.loadType != 'MANIFEST' ? 'loadId' : 'manifestId'] = load?.id;
      const data = await trackPromise(
        getLoadOverviewDetails(request),
        ESecondaryDetailsPanelType.LOAD
      );
      if (data) {
        const terminal = data.invoiceTerminal;
        if (terminal) {
          const { companyName, primary } = terminal;
          terminal.name = primary ? companyName + ' (Primary)' : companyName;
        }
        reset(
          new LoadOverviewDetails({
            ...data,
            loadStatus: load?.loadStatus,
            id: load?.id,
          })
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setNoData(true);
      }
    };

    const handleCancel = () => {
      setIsSaving(false);
      reset();
    };

    useEffect(() => {
      if (errors) {
        httpErrors?.forEach((error) => {
          setError(error.fieldName, {
            type: 'httpError',
            message: error.message,
          });
        });
      }
    }, [httpErrors]);

    const handleSave = async (data: LoadOverviewDetails) => {
      try {
        setIsSaving(true);
        const response = await updateLoadReferenceDetails(data);
        if (response instanceof ServiceError) {
          setIsSaving(false);
          return setHttpErrors(getHttpErrorsFromResponse(response));
        }
        setUpdatedLoadId && setUpdatedLoadId(data.id);
        reset(data, { keepDefaultValues: false });
        setHttpErrors(null);
        clearErrors('referenceId');
        EventEmitter.send(new EventOverviewChange(data));
        onUpdated?.();
        setIsSaving(false);
      } catch (error) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'loadOverDetailsUpdate',
        });
        setIsSaving(false);
      }
    };

    if (noData && !isLoading) {
      return <NoData />;
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: (theme) => {
            return `${theme.palette.uncategorized?.card}`;
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            mx: 0,
          }}
        >
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: '5px',
                height: '4rem',
                mt: 2,
              }}
            />
          ) : (
            <Box sx={{ mt: 2 }}>
              <Warnings load={load as LoadSummary} />
            </Box>
          )}
          <Box>
            <FormProvider {...state}>
              <LoadDetails
                loadStatus={load.loadStatus}
                loadType={load.loadType || ''}
                manifestDeleteForLoad={manifestDeleteForLoad}
                isLoading={isLoading}
                onHold={load.onHold}
                onUpdated={() => {
                  onUpdated?.();
                  fetchData();
                }}
              />
            </FormProvider>
          </Box>
        </Box>
        {isDirty && (
          <Footer
            sx={{ mb: 3 }}
            isSaving={isSaving}
            onSave={handleSubmit(handleSave)}
            onCancel={handleCancel}
          />
        )}
      </Box>
    );
  }
);
