export enum AlertEntityType {
  Driver = 'DRIVER',
  Tractor = 'TRACTOR',
  Trailer = 'TRAILER',
}
export enum AlertStatus {
  Upcoming = 'Coming Up',
  PastDue = 'Past Due',
  OK = 'OK',
}
