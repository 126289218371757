import React from 'react';

const OptymizeIcon = () => {
  return (
    <svg
      width="87"
      height="20"
      viewBox="0 0 87 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.8478 4.75385C52.6375 4.75385 51.5488 5.28452 50.7983 6.123C50.0478 5.28452 48.9644 4.75385 47.7488 4.75385C47.0195 4.75385 46.3377 4.94489 45.7458 5.27922V4.75385H43.6582V8.8613V10.5489V15.2454H45.7458V9.65202V8.86661C45.7458 7.75749 46.6443 6.85533 47.7488 6.85533C48.8534 6.85533 49.7519 7.75749 49.7519 8.86661V15.2507H51.8395V8.86661C51.8395 7.75749 52.7379 6.85533 53.8425 6.85533C54.9471 6.85533 55.8455 7.75749 55.8455 8.86661V15.2507H57.9331V8.86661C57.9384 6.59 56.1045 4.75385 53.8478 4.75385Z"
        fill="white"
      />
      <path
        d="M28.9944 13.1446C27.8899 13.1446 26.9914 12.2425 26.9914 11.1334V6.85078H29.803L28.9046 4.7546H26.9914V0.222595H24.9038V4.7546V6.85078V11.1334C24.9038 13.4047 26.7377 15.2408 28.9944 15.2408H32.4139L31.5154 13.1446H28.9944Z"
        fill="white"
      />
      <path
        d="M19.214 4.75385H16.952C14.69 4.75385 12.8613 6.5953 12.8613 8.8613V9.99696V15.2454V19.7774H14.9489V15.2454H19.214C21.4759 15.2454 23.3046 13.4039 23.3046 11.1379V8.86661C23.3099 6.59 21.4759 4.75385 19.214 4.75385ZM21.217 8.8613V11.1326C21.217 12.2417 20.3185 13.1439 19.214 13.1439H16.952C15.8474 13.1439 14.9489 12.2417 14.9489 11.1326V9.99696V8.8613C14.9489 7.75218 15.8474 6.85003 16.952 6.85003H19.214C20.3185 6.85003 21.217 7.75218 21.217 8.8613Z"
        fill="white"
      />
      <path
        d="M4.90508 13.1442C3.8005 13.1442 2.90205 12.242 2.90205 11.1329V8.24603H0.862019C0.830308 8.44769 0.814453 8.64935 0.814453 8.85631V11.1276C0.814453 13.3989 2.64836 15.2351 4.90508 15.2351H7.16708C7.37319 15.2351 7.57931 15.2192 7.77486 15.1873V13.1336H4.90508V13.1442Z"
        fill="white"
      />
      <path
        d="M39.839 4.75385L36.9428 11.5306L36.4989 12.5814L33.6027 5.79928L33.1534 4.75385H30.8809L34.9133 14.1946L35.3626 15.2454L33.5022 19.7774H35.7748L37.9047 14.6086L42.1116 4.75385H39.839Z"
        fill="white"
      />
      <path
        d="M7.18292 4.74213H4.92092C3.38826 4.74213 2.05643 5.58591 1.35352 6.83831H4.92092H7.18292C8.28749 6.83831 9.18595 7.74047 9.18595 8.84959V11.1209V14.703C10.4332 13.9972 11.2735 12.6599 11.2735 11.1209V8.84959C11.2735 6.57828 9.43963 4.74213 7.18292 4.74213Z"
        fill="white"
      />
      <path d="M62.258 4.75482H60.1704V15.2464H62.258V4.75482Z" fill="white" />
      <path
        d="M62.3426 0.958923H60.0964V3.21431H62.3426V0.958923Z"
        fill="white"
      />
      <path
        d="M86.9999 8.86228C86.9999 6.59097 85.166 4.75482 82.9093 4.75482H80.6473C78.3853 4.75482 76.5566 6.59628 76.5566 8.86228V11.1336C76.5566 13.4049 78.3906 15.241 80.6473 15.241H82.9093C84.4419 15.241 85.779 14.3973 86.4767 13.1449H80.642C79.5374 13.1449 78.6389 12.2427 78.6389 11.1336V11.0487H86.9999V8.86228ZM78.6389 8.86228C78.6389 7.75316 79.5374 6.851 80.642 6.851H82.904C83.4589 6.851 83.9557 7.07389 84.3204 7.44006C84.685 7.80623 84.907 8.30506 84.907 8.86228V8.94719H78.6389V8.86228Z"
        fill="white"
      />
      <path
        d="M75.052 4.75385H64.6035V6.85003H72.214L65.1214 12.7193C64.7938 12.99 64.6035 13.3933 64.6035 13.8178V15.2454H75.052V13.1492H67.4416L74.5341 7.27988C74.8618 7.00923 75.052 6.60591 75.052 6.18137V4.75385Z"
        fill="white"
      />
    </svg>
  );
};

export default OptymizeIcon;
