import { Typography } from '@mui/material';
import { t } from 'i18next';
import StorageManager from '../../StorageManager/StorageManager';
import { ELoadStatus } from '../../common/LoadTabPanel/constants/constants';
import { IOpenSecondaryDetailsHandler } from '../../common/LoadTabPanel/constants/interface';
import {
  Trip as LoadTrip,
  RouteTrip,
} from '../../common/LoadTabPanel/tabs/Routes/Models';
import TripAssignmentComponent from '../../common/LoadTabPanel/tabs/Routes/components/TripAssignmentComponent';
import {
  convertRouteStopToTripStopV3Format,
  getResequenceUpRule,
} from '../../common/LoadTabPanel/tabs/Routes/uiUtils';
import { LoadConstants } from '../../locales/en/allLoads/loads';
import { TripsConstants } from '../../locales/en/allLoads/trips/index';
import {
  EActionName,
  EHOSEventType,
  Trip,
  TripStopResponse,
} from '../../models';
import { AlertStatus } from '../../models/DTOs/CommonAlert/Types';
import {
  ETripSettlementStatus,
  TTripSettlementStatusOption,
  TTripStatusOption,
} from '../../models/DTOs/Trip/Types';
import { ITripV3BrokeredOption, TripV3DataProps } from '../../ui-kit/TripPlan';
import {
  DriverActivityType,
  DriverHOSStatusType,
  StopSolutionV3Prop,
  StopSolutionV3StopType,
  TripStopStatusEnum,
} from '../../ui-kit/components/TripPlan';
import { numberWithThousandSeparator } from '../../utils';
import { currencyDisplay } from '../../utils/grid.utils';
import { ESecondaryDetailsPanelType } from '../dispatch2/constants/types';
import { KPIItemProps } from './components/KPI/KPI';
import {
  SETTLEMENT_STATUS_LIST,
  TRIP_STATUS_LIST,
} from './constants/constants';

export const hexToRgb = (hex: string): number[] =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16)) || [];

export const getAlertColor = (alertStatus: AlertStatus, theme: any) =>
  alertStatus === AlertStatus.PastDue
    ? theme.palette.error.main
    : alertStatus === AlertStatus.Upcoming
    ? theme.palette.warning.main
    : theme.palette.primary.main;

export const getTripStatusByValues = (
  values: ELoadStatus[]
): TTripStatusOption[] =>
  TRIP_STATUS_LIST.filter(({ value }) => values.includes(value));

export const getSettlementStatusByValues = (
  values: ETripSettlementStatus[]
): TTripSettlementStatusOption[] =>
  SETTLEMENT_STATUS_LIST.filter(({ value }) => values.includes(value));

export const displayTripStatus = (statusCode?: ELoadStatus): string => {
  if (!statusCode) return '';
  const mapping: { [key in ELoadStatus]?: string } = {
    [ELoadStatus.AVAILABLE]: TripsConstants.TRIP_STATUS_LISTAvailable,
    [ELoadStatus.OFFERED_TO_DRIVER]: TripsConstants.TRIP_STATUS_LISTAssigned,
    [ELoadStatus.DISPATCHED]: TripsConstants.TRIP_STATUS_LISTDispatched,
    [ELoadStatus.IN_TRANSIT]: TripsConstants.TRIP_STATUS_LISTInTransit,
    [ELoadStatus.LOAD_COMPLETED]: TripsConstants.TRIP_STATUS_LISTCompleted,
    [ELoadStatus.ASSIGNMENT_PLANNED]: TripsConstants.TRIP_STATUS_LISTPlanned,
  };
  return mapping[statusCode] || statusCode;
};

export const displayTripSettlementStatus = (
  statusCode?: ETripSettlementStatus
): string => {
  if (!statusCode || statusCode === ETripSettlementStatus.None) return '';
  const option = SETTLEMENT_STATUS_LIST.find(
    ({ value }) => value === statusCode
  );
  if (!option) return statusCode;
  return option.label;
};

const toHosStatusType = (status: EHOSEventType): DriverHOSStatusType => {
  switch (status) {
    case EHOSEventType.ON_DUTY:
      return DriverHOSStatusType.OnDuty;
    case EHOSEventType.OFF_DUTY:
      return DriverHOSStatusType.OffDuty;
    case EHOSEventType.DRIVING:
      return DriverHOSStatusType.Driving;
    case EHOSEventType.SLEEPER_BED:
      return DriverHOSStatusType.SleeperBed;
  }
};

const toDriverActivityType = (type: EActionName): DriverActivityType => {
  switch (type) {
    case EActionName.DRIVE:
      return DriverActivityType.Driving;
    case EActionName.OFFDUTY:
      return DriverActivityType.Offduty;
    case EActionName.DVIR:
      return DriverActivityType.PreTripInspection;
    case EActionName.BREAKSTOP:
      return DriverActivityType.Breakstop;
    case EActionName.APPOINTMENTWORK:
      return DriverActivityType.AppointmentWork;
  }
};

export const setStopsResequenceRules = (
  stop: StopSolutionV3Prop,
  stopIndex: number,
  prevStop: StopSolutionV3Prop | undefined,
  nextStop: StopSolutionV3Prop | undefined,
  trip: TripStopResponse
) => {
  const resequenceActions = {
    showResequenceIcons: false,
    resequenceUp: false,
    resequenceDown: false,
  };
  const { type, status } = stop;
  const { previousTripDetails, nextTripDetails, stops } = trip;
  if (
    (type === StopSolutionV3StopType.TRIP ||
      type === StopSolutionV3StopType.LOAD) &&
    (!status || status === TripStopStatusEnum.NONE)
  ) {
    let resequenceUp =
        !!previousTripDetails?.tripId && previousTripDetails?.canAcceptStop,
      resequenceDown = true;

    if (prevStop) {
      if (
        type === StopSolutionV3StopType.TRIP &&
        stopIndex === 1 &&
        prevStop.type === StopSolutionV3StopType.LOAD
      ) {
        // TRIP stop can't be moved to the first position
        // resequenceUp === false;
        resequenceUp = true;
      } else if (
        type === StopSolutionV3StopType.LOAD &&
        stopIndex === stops.length - 1 &&
        prevStop.type === StopSolutionV3StopType.TRIP
      ) {
        // resequenceUp === false;
      } else {
        resequenceUp = getResequenceUpRule(prevStop.status || '');
        if (
          resequenceUp &&
          type === StopSolutionV3StopType.LOAD &&
          prevStop.type === StopSolutionV3StopType.LOAD
        ) {
          const prevStopLoadId = prevStop.loadDetails[0]?.loadId;
          resequenceUp = prevStopLoadId !== stop.loadDetails[0]?.loadId;
        }
      }
    }
    if (type === 'LOAD') {
      if (nextStop?.type === 'TRIP') {
        if (!stopIndex) {
          // TRIP stop can't be moved to the last position
          // resequenceDown = false;
        }
      } else if (nextStop?.type === StopSolutionV3StopType.LOAD) {
        const nextStopLoadId = nextStop.loadDetails[0]?.loadId;
        resequenceDown = nextStopLoadId !== stop.loadDetails[0]?.loadId;
      }
      if (type === 'LOAD' && prevStop?.type === 'TRIP') {
        // resequenceUp = false;
        resequenceUp = true;
      }
    } else {
      // TRIP stop case
      const { stops } = trip;
      if (stopIndex === stops.length - 2 && nextStop?.type === 'LOAD') {
        // resequenceDown = false;
      }
    }
    const stopTypes = trip?.stops?.map((e) => e?.type);
    const loadStopCount: number = stopTypes?.filter(
      (e) => e === StopSolutionV3StopType.LOAD
    )?.length;

    if (
      loadStopCount === 1 &&
      type === StopSolutionV3StopType.LOAD &&
      nextStop?.type === StopSolutionV3StopType.RELAY
    ) {
      resequenceDown = false;
    }

    if (
      loadStopCount === 1 &&
      type === StopSolutionV3StopType.LOAD &&
      prevStop?.type === StopSolutionV3StopType.RELAY
    ) {
      resequenceUp = false;
    }

    if (
      (!nextStop || nextStop.type === StopSolutionV3StopType.RELAY) &&
      (!nextTripDetails || !nextTripDetails.canAcceptStop)
    ) {
      resequenceDown = false;
    }
    const showResequenceIcons = resequenceUp || resequenceDown;
    resequenceActions.showResequenceIcons = showResequenceIcons;
    resequenceActions.resequenceUp = resequenceUp;
    resequenceActions.resequenceDown = resequenceDown;
  }
  stop.resequenceActions = resequenceActions;
};

export function convertTripIntoLoadTrip(trip: TripStopResponse): LoadTrip {
  const terminal = trip.terminal
    ? { ...trip.terminal, isPrimary: trip.terminal.primary }
    : null;

  const newTrip = new LoadTrip({
    id: trip.id,
    tripStatus: trip.status,
    terminal: terminal,
    driverGroup: trip.driverGroup,
    tractor: trip.tractor,
    trailer: trip.trailer,
    dispatcher: trip.dispatcher,
    seqNumber: trip.seqNumber,
    stops: [],
    loadId: trip.stops.reduce<string | undefined>((loadId, stop) => {
      if (loadId) return loadId;
      return stop.loadDetails?.[0]?.loadId;
    }, undefined),
  });
  return newTrip;
}

export const getTripV3BrokeredOption = ({
  trip,
  isOpeningTripAssignment,
  onHold,
}: {
  trip: Trip | RouteTrip;
  isOpeningTripAssignment?: boolean;
  onHold?: boolean;
}): ITripV3BrokeredOption | undefined => {
  if (!trip?.hasOwnProperty('brokerageTrip')) return undefined;
  const status = trip?.tripStatus || trip?.status;
  const brokered: ITripV3BrokeredOption = {
    showBrokered: true,
    isBrokered: !!trip.brokerageTrip,
    isDisabled:
      ![
        ELoadStatus.AVAILABLE,
        ELoadStatus.ASSIGNMENT_PLANNED,
        ELoadStatus.OFFERED_TO_DRIVER,
      ].includes(status) ||
      !!isOpeningTripAssignment ||
      onHold,
    tooltip: '',
  };

  if (!isOpeningTripAssignment) {
    if (brokered.isDisabled) {
      brokered.tooltip = t('getTripV3BrokeredOptionRevertTheStatus');
    } else if (brokered.isBrokered) {
      brokered.tooltip = t('getTripV3BrokeredOptionUnassignTheCarrier');
    } else {
      brokered.tooltip = t(
        'getTripV3BrokeredOptionUnassignTheDriverTractorTrailer'
      );
    }
  }
  if (onHold) {
    brokered.isDisabled = true;
    brokered.tooltip = LoadConstants.loadIsCurrentlyOnHold;
  }

  return brokered;
};

export const toTripPlan = ({
  data,
  trip,
  loadTrip,
  isOpeningTripAssignment,
  setOpeningTripAssignment,
  disabled,
  isAssignmentDisable = false,
  disableAssignment,
  showProgressIcon,
}: {
  data: TripStopResponse;
  trip: Trip;
  loadTrip?: LoadTrip;
  isOpeningTripAssignment?: boolean;
  setOpeningTripAssignment?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
  isAssignmentDisable?: boolean;
} & Pick<
  IOpenSecondaryDetailsHandler,
  'disableAssignment' | 'showProgressIcon'
>): TripV3DataProps[] => {
  const stops = data.stops.map((stop) => ({
    ...stop,
    noteDetails: { ...stop.noteDetails, note: stop.notes || '' },
    loadId: stop.loadDetails?.[0]?.seqNumber,
    HOSList: stop.stopTpSolution?.timeline.map((item) => ({
      type: StopSolutionV3StopType.HOS,
      driverActivity: toDriverActivityType(item.actionName),
      driverHOSStatus: toHosStatusType(item.hosEventType),
      duration: item.duration.toString(),
      locationAddress: item.locationName || '',
      estimatedTime: item.start,
    })),
  }));
  const stopList = convertRouteStopToTripStopV3Format(stops);
  stopList.forEach((stop, index: number) => {
    setStopsResequenceRules(
      stop,
      index,
      stopList[index - 1],
      stopList[index + 1],
      data
    );
  });
  const brokered = getTripV3BrokeredOption({
    trip,
    isOpeningTripAssignment,
    onHold: trip.onHold,
  });

  return [
    {
      disabled: disabled,
      id: data.id,
      expanded: true,
      showHOSPlan: data.stops.some((stop) => stop.stopTpSolution),
      showProgressIcon:
        [
          ELoadStatus.DISPATCHED,
          ELoadStatus.IN_TRANSIT,
          ELoadStatus.LOAD_COMPLETED,
        ].includes(trip.status) && showProgressIcon,
      expandedHOSPLan: false,
      isCompleted: trip.status === ELoadStatus.LOAD_COMPLETED,
      headerData: {
        title: (
          <Typography variant="body2Bold" sx={{ color: 'text.primary' }}>
            Trip
          </Typography>
        ),
        renderHeaderContents: (): JSX.Element =>
          loadTrip ? (
            <TripAssignmentComponent
              originalTrip={trip}
              trip={loadTrip}
              isAssignmentDisable={isAssignmentDisable}
              isBrokered={brokered?.isBrokered}
              panelType={ESecondaryDetailsPanelType.TRIP}
              setOpeningTripAssignment={setOpeningTripAssignment}
              disableAssignment={disableAssignment}
            />
          ) : (
            <></>
          ),
      },
      stopList,
      brokered: brokered,
    },
  ];
};

export const toKpi = (
  data: TripStopResponse,
  isEmptyTrip?: boolean
): KPIItemProps[] => {
  const user = StorageManager.getUser();
  return [
    {
      title: numberWithThousandSeparator(data?.loadedMiles).toString(),
      subTitle: t('tripmiles'),
    },
    {
      title: numberWithThousandSeparator(data?.emptyMiles).toString(),
      subTitle: t('emptymiles'),
    },
    ...(user.roleCode != 'NFD' && !isEmptyTrip
      ? [
          {
            title: currencyDisplay(data?.revenue).toString(),
            subTitle: t('revenue'),
          },
        ]
      : []),
  ];
};
