import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Moment } from 'moment';
import React, { CSSProperties } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import Datepicker from '../../../ui-kit/components/Datepicker';
import { fromIsoToMoment, getEndOfDay, getStartOfDay } from '../../../utils';

/* @TODO @davits , @anoush take a look */
export const formatDate = (
  date: Moment,
  startOfDate: boolean,
  endOfDate: boolean,
  iso: boolean,
  format?: boolean
) => {
  if (!startOfDate && !endOfDate && !iso) return date;
  if (!date) return date;

  if (startOfDate) {
    return getStartOfDay(date, iso ? 'IsoString' : undefined);
  }

  if (endOfDate) {
    return getEndOfDay(date, iso ? 'IsoString' : undefined);
  }

  if (format) {
    return date?.format();
  }

  if (iso) {
    return date?.format('YYYY-MM-DD');
  }
};

const DatePicker: React.FC<{
  iso?: boolean;
  control?: any;
  name: string;
  label: string;
  variant?: any;
  onDateChange?: any;
  required?: boolean;
  disableFuture?: boolean;
  initialDate?: any;
  styleProps?: any;
  helperText?: string;
  disabled?: boolean;
  disabledText?: boolean;
  startOfDate?: boolean;
  endOfDate?: boolean;
  rules?: any;
  sizes?: {
    xs: number;
  };
  shouldUnregister?: ControllerProps['shouldUnregister'];
  format?: boolean;
  wrapperStyles?: CSSProperties;
  icon?: React.ElementType<JSX.Element>;
  timezone?: string;
  allowEmptyDate?: boolean;
  caption?: string;
}> = ({
  control,
  name,
  sizes = {},
  label,
  variant,
  onDateChange,
  required,
  disableFuture,
  initialDate,
  styleProps,
  helperText,
  disabled,
  rules,
  iso = false,
  startOfDate = false,
  endOfDate = false,
  disabledText,
  shouldUnregister,
  format = false,
  wrapperStyles = {},
  icon,
  timezone,
  allowEmptyDate,
  caption,
  ...restOptions
}) => {
  return (
    <Grid xs={sizes.xs} item style={{ marginBottom: 20, ...wrapperStyles }}>
      <Controller
        render={({
          field: { onChange, value },
          fieldState: { error },
          ...rest
        }) => {
          return (
            <>
              <Datepicker
                {...rest}
                disabled={disabled}
                disabledText={disabledText}
                label={label}
                variant={variant}
                onDateChange={(e: Moment) => {
                  onDateChange &&
                    onDateChange(formatDate(e, startOfDate, endOfDate, iso));
                  onChange(formatDate(e, startOfDate, endOfDate, iso));
                }}
                error={!!error}
                required={required}
                disableFuture={disableFuture}
                initialDate={iso ? fromIsoToMoment(value) : value}
                styleProps={styleProps}
                helperText={helperText || error?.message}
                icon={icon}
                timezone={timezone}
                allowEmptyDate={allowEmptyDate}
                {...restOptions}
              />
              {caption && (
                <Typography color={'text.secondary'} variant="caption">
                  {caption}
                </Typography>
              )}
            </>
          );
        }}
        rules={rules}
        name={name}
        control={control}
        defaultValue={initialDate}
        shouldUnregister={shouldUnregister}
      />
    </Grid>
  );
};

export default React.memo(DatePicker);
