import { useCallback, useEffect, useState } from 'react';
import { dispatchService } from '@/api';
import { GetDispatchGridDataRequest } from '@/models/DTOs/Dispatch/Requests';
import { ServiceError } from '@/api/interfaces';
import {
  DispatchGridDataItem,
  SecondaryPanelType,
} from '@/models/DTOs/Dispatch/Dispatch';
import { useRootStore } from '@/store/root-store/rootStateContext';
import { getColumns } from '@/views/dispatch2/components/gantt/GanttChartGrid/constants/GanttChartGrid.constants';
import { GridCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { useStores } from '@/store/root.context';
import { DispatchFiltersName } from '@/views/dispatch2/constants/types';
import { getSortOption } from '@/common/Sorting/sortingUtils';

export const useGanttChartGrid = (
  onLoadView: ((data: SecondaryPanelType) => void) | undefined,
  onTripView: ((data: SecondaryPanelType) => void) | undefined,
  onCustomerView: ((data: SecondaryPanelType) => void) | undefined,
  onDriverView: ((driverGroupId: string) => void) | undefined
) => {
  const [data, setData] = useState<DispatchGridDataItem[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    dispatch2GlobalStore: { filters, setFilters },
  } = useStores();
  const { getGlobalTerminalsIds } = useRootStore();

  useEffect(() => {
    const handleTripPlanReadyEvent = () => {
      const notFormattedFilters = getFilters(filters);
      getGridData(notFormattedFilters);
    };
    window.addEventListener('TRIPPLANREADY', handleTripPlanReadyEvent);

    return () => {
      window.removeEventListener('TRIPPLANREADY', handleTripPlanReadyEvent);
    };
  }, [filters]);

  const getFilters = (filters: any) => {
    return {
      driverIdsFilter: filters.filters.driverFilter?.map(
        (driver: { key: string }) => driver.key
      ),
      sort: filters.sort,
      terminalIds: getGlobalTerminalsIds,
      hasViolation: filters.filters.hasViolation?.value,
      originRadius: filters.filters.originRadius,
      destinationRadius: filters.filters.destinationRadius,
      originCityStateList: filters.filters[DispatchFiltersName.origin]?.map(
        (origin: { value: string }) => origin.value
      ),
      originSearchCenter: filters.filters[DispatchFiltersName.origin]?.map(
        (origin: { center: { lat: number; lng: number } }) => origin.center
      ),
      destinationSearchCenter: filters.filters[
        DispatchFiltersName.destination
      ]?.map(
        (destination: { center: { lat: number; lng: number } }) =>
          destination.center
      ),
      destinationCityStateList: filters.filters[
        DispatchFiltersName.destination
      ]?.map((destination: { value: string }) => destination.value),
      tripIdsFilter: filters.filters[DispatchFiltersName.trips]
        ?.map((trip: { value: string[] }) => trip.value ?? [])
        .flat(),
    };
  };

  useEffect(() => {
    const notFormattedFilters = getFilters(filters);
    getGridData(notFormattedFilters);
  }, [JSON.stringify(filters), JSON.stringify(getGlobalTerminalsIds)]);

  const getGridData = async (notFormattedFilters: any) => {
    setLoading(true);
    const request = new GetDispatchGridDataRequest();
    request.terminalIdsFilter = notFormattedFilters.terminalIds;
    request.sort = notFormattedFilters.sort;
    request.hasViolation = notFormattedFilters.hasViolation;
    request.tripIdsFilter = notFormattedFilters.tripIdsFilter;
    request.driverIdsFilter = notFormattedFilters.driverIdsFilter;
    request.originRadius = notFormattedFilters.originRadius;
    request.destinationRadius = notFormattedFilters.destinationRadius;
    request.originCityStateList = notFormattedFilters.originCityStateList;
    request.originSearchCenter = notFormattedFilters.originSearchCenter;
    request.destinationSearchCenter =
      notFormattedFilters.destinationSearchCenter;
    request.destinationCityStateList =
      notFormattedFilters.destinationCityStateList;
    const data = await dispatchService.getDispatchGridData(request);
    if (!(data instanceof ServiceError)) {
      setData(data);
    }
    setLoading(false);
  };

  const columns = getColumns(onLoadView, onCustomerView, onTripView);

  const handleRowClick = (params: GridCellParams) => {
    const row = params as unknown as DispatchGridDataItem;
    onDriverView?.(row?.driverGroup?.id ?? '');
  };

  const handleSortChange = (model: GridSortModel) => {
    const sortOptions = getSortOption(model, '');
    setFilters((filters) => ({
      ...filters,
      sort: sortOptions,
    }));
  };

  return {
    data,
    loading,
    columns,
    handleRowClick,
    handleSortChange,
  };
};
