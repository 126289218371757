import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import React from 'react';
import { Controller } from 'react-hook-form';
import Switcher from '../../../ui-kit/components/Switcher';

interface IProps {
  id?: string;
  label: string;
  subLabel?: string;
  control?: any;
  name: string;
  value?: boolean;
  sizes?: {
    xs: number;
  };
  style?: {};
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  convertToNumber?: boolean;
  labelColor?: string;
}

const Switch: React.FC<IProps> = ({
  id = '',
  sizes = { xs: 12 },
  style,
  control,
  label = '',
  subLabel,
  name,
  disabled,
  onChange: onChangeCallback,
  defaultChecked = false,
  convertToNumber = false,
  labelColor,
}) => {
  return (
    <Grid item xs={sizes.xs} container sx={{ marginBottom: '20px', ...style }}>
      <Controller
        render={({
          field,
          field: { onChange, value },
          fieldState: { error },
        }) => {
          return (
            <FormControlLabel
              sx={{ marginLeft: '0px' }}
              {...field}
              control={
                <Switcher
                  id={id}
                  labelColor={error ? 'error.main' : labelColor}
                  label={label}
                  subLabel={
                    subLabel ? (
                      subLabel
                    ) : error ? (
                      <Box
                        sx={{
                          color: 'error.main',
                        }}
                      >
                        {error.message}
                      </Box>
                    ) : undefined
                  }
                  checked={!!value}
                  onChange={(e) => {
                    onChangeCallback && onChangeCallback(e);
                    onChange(
                      convertToNumber ? +e.target.checked : e.target.checked
                    );
                  }}
                />
              }
              label=""
              disabled={disabled}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultChecked}
      />
    </Grid>
  );
};

Switch.defaultProps = {
  disabled: false,
};
export default Switch;
