/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { annotateNameAsync, ServiceError } from '../interfaces';
import { httpClient } from '../../axios/axiosInstance';
import { ILoadboardService } from '../interfaces/ILoadboardServices';
import {
  CreateRequestCredentials,
  DeactivateAccountCard,
  GetTerminalList,
  LoadboardRequest,
  LoginAccountIntegration,
  MarketRateRequest,
  UserDetailsRequest,
} from '../../models/DTOs/Loadboard/Requests';
import {
  AllTerminalResponse,
  ICreateDATSendCredentialsResponse,
  ICreateLoginCardResponse,
  IDeactivateCardResponse,
  IeditDATCredentialsResponse,
  LoadboardProviderData,
  MarketRateResponse,
  UserDetailsReponse,
} from '../../types/LoadboardTypes';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

const getLoadboardServiceProvider =
  'web/omnibus/api/v2/integrations/organization/loadboard?integrationType=LOADBOARD';
const sendEmailToDAT = 'web/omnibus/api/v2/integrations/postmail';
const integrationLogin = 'web/omnibus/api/v2/integrations';
const deactivateAccount = 'web/omnibus/api/v2/integrations';
const terminalList = 'web/preference/api/v2/preferences/terminals/list/all';
const userDetails = 'web/people/api/v2/organization/people/person';
const oAuthPut = 'web/omnibus/api/v2/integrations/oauth';
const laneRatePut = 'web/loadselect/api/v2/loadselect/single/on-demand';

export class LoadboardServices extends ILoadboardService {
  @annotateNameAsync
  async getLoadboardProvidersList(
    payload: LoadboardRequest
  ): Promise<LoadboardProviderData> {
    try {
      return await httpClient.get<LoadboardProviderData>(
        getLoadboardServiceProvider,
        payload,
        LoadboardProviderData,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'providersList',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async createDATCredential(
    payload: CreateRequestCredentials
  ): Promise<ICreateDATSendCredentialsResponse> {
    try {
      const response = await httpClient.post<ICreateDATSendCredentialsResponse>(
        sendEmailToDAT,
        undefined,
        payload,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'datCreation',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'datCreation',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async getTerminals(payload: GetTerminalList): Promise<AllTerminalResponse> {
    try {
      return await httpClient.get<AllTerminalResponse>(
        terminalList,
        payload,
        null,
        undefined,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async createLoginCard(
    payload: LoginAccountIntegration
  ): Promise<ICreateLoginCardResponse> {
    try {
      const response = await httpClient.post<ICreateLoginCardResponse>(
        integrationLogin,
        undefined,
        payload,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'activationLogin',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'activationLogin',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async editDATCredential(
    payload: CreateRequestCredentials
  ): Promise<IeditDATCredentialsResponse> {
    try {
      const response = await httpClient.put<IeditDATCredentialsResponse>(
        integrationLogin,
        undefined,
        payload,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'editIntegration',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'editIntegration',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async deactivatedAccountCard(
    payload: DeactivateAccountCard
  ): Promise<IDeactivateCardResponse> {
    try {
      const response = await httpClient.delete<IDeactivateCardResponse>(
        `${deactivateAccount}/deactivate?id=${payload['id']}&integrationType=${payload['integrationType']}&providerId=${payload['providerId']}&organizationId=${payload['organizationId']}`,
        undefined,
        payload,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deactivate',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'deactivate',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async oAuthEdit(
    payload: CreateRequestCredentials
  ): Promise<IeditDATCredentialsResponse> {
    try {
      return await httpClient.put<IeditDATCredentialsResponse>(
        oAuthPut,
        undefined,
        payload,
        false
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'editIntegration',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async getUserDetails(
    payload: UserDetailsRequest
  ): Promise<UserDetailsReponse> {
    try {
      return await httpClient.get<UserDetailsReponse>(
        `${userDetails}?organizationId=${payload['organizationId']}&id=${payload['userId']}&loggedIn=1`,
        payload,
        UserDetailsReponse,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'getUserDetails',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async getMarketRate(
    requestData: MarketRateRequest
  ): Promise<MarketRateResponse | null | ServiceError> {
    try {
      return httpClient.put<MarketRateResponse>(
        laneRatePut,
        undefined,
        requestData,
        MarketRateResponse,
        true,
        true
      );
    } catch (error) {
      throw error;
    }
  }
}
