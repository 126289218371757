import { httpClient } from '../../../../axios/axiosInstance';
import { QueryParams } from '../../../../models';
import { IQueryStringInputObject } from '../types/types';
import { generateQueryStringFromArray } from '../utils';

interface IDropdownFetchParams {
  endpoint: string;
  formatter?: (data: any) => unknown;
  query?: IQueryStringInputObject;
  method?: 'PUT' | 'GET';
  pageNumber?: number;
  pageSize?: number;
}

export const fetchDropdownData = async (params: IDropdownFetchParams) => {
  const { endpoint, formatter = undefined, query, method = 'GET' } = params;
  const queryParams = new QueryParams(true);
  const customQueryParms = generateQueryStringFromArray(query);
  let response;
  (queryParams as any).pageSize = params?.pageSize || 100;
  (queryParams as any).pageNumber = params?.pageNumber || 1;
  if (method === 'PUT') {
    response = await httpClient.put(
      endpoint,
      queryParams,
      {
        statusFilter: [],
        idFilter: [],
        rateMethodFilter: [],
        customerIdFilter: [],
        effectiveFrom: '',
        effectiveTo: '',
        expireFrom: '',
        expireTo: '',
        createdFrom: '',
        createdTo: '',
      },
      true,
      true
    );
  }
  if (method === 'GET') {
    response = await httpClient.get(
      endpoint + customQueryParms,
      queryParams,
      undefined,
      true,
      true
    );
  }
  return formatter ? formatter(response) : response;
};

export const fetchCustomDropdownData = async (params: IDropdownFetchParams) => {
  const { endpoint, formatter = undefined, query, method = 'GET' } = params;
  const queryParams = new QueryParams(true);
  queryParams.customerName = query?.customerName;
  let response;
  (queryParams as any).pageSize = params?.pageSize || 100;
  (queryParams as any).pageNumber = params?.pageNumber || 1;
  if (method === 'PUT') {
    response = await httpClient.put(
      endpoint,
      queryParams,
      {
        statusFilter: [],
        idFilter: [],
        rateMethodFilter: [],
        customerIdFilter: [],
        effectiveFrom: '',
        effectiveTo: '',
        expireFrom: '',
        expireTo: '',
        createdFrom: '',
        createdTo: '',
      },
      true,
      true
    );
  }
  if (method === 'GET') {
    response = await httpClient.get(
      endpoint,
      queryParams,
      undefined,
      true,
      true
    );
  }
  return formatter ? formatter(response) : response;
};
