import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET,
} from '../../../common/hooks/useThemeResponsive';

export const StyledComponentRoot = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }: { theme: any }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: theme.palette.primary.contrast,
  boxShadow: `0px 4px 24px rgba(0, 0, 0, 0.15)`,
  width: '100%',
  padding: '1rem',
  borderRadius: '5px',
  height: '100%',
  overflow: 'hidden',
  overflowX: 'scroll',
  [theme?.breakpoints?.between(BREAKPOINT_TABLET, BREAKPOINT_DESKTOP)]: {
    padding: '8px',
  },
}));
