import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useEffect, useState } from 'react';
import StorageManager from '../../../../StorageManager/StorageManager';
import { loadsearchService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { ELoadStatus } from '../../../../common/LoadTabPanel/constants/constants';
import { styles } from '../../../../common/LoadTabPanel/tabs/Tracking/styles/styles';
import LoadActionsController from '../../../../common/LoadTabPanel/utils/LoadActionsController';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { getUserId } from '../../../../common/TimeoffDialog/utils';
import {
  LoadCancellationDetails,
  UpdateStatusPayload,
  UpdateStatusResponse,
} from '../../../../models';
import { ILoadSearchModel } from '../../../../models/DTOs/Loadsearch/Model';
import {
  BookmarkLoadRequest,
  LoadInstantBookingRequest,
  UnBookmarkLoadRequest,
  UpdateBidLoadRequest,
} from '../../../../models/DTOs/Loadsearch/Request';
import { RootStoreInstence } from '../../../../store/root-store/rootStateContext';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import BidTooltip from '../../bidLoad/components/Tooltip';
import {
  ILoad,
  ILoadsearchTableItem,
  IPendingLoadBookStatus,
} from '../../loadTypes';
import { BookingConfirmationPopover } from './bookingConfirmationPopover';
import { InstantBookingPopover } from './instantBookPopover';
import { actionDataEnum } from '../../constants/grid.constants';
import { BoltOutlined } from '@mui/icons-material';

export const TableColumnButtons = ({
  params,
  onBookmark,
  unBookmark,
  tabElm,
  handleBook,
  pendingLoadBookConfirmationStatus,
  isBookmarkIconRequired,
}: ITableColumButton) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState<string | null>(null);

  const [bookingAnchorEl, setBookingAnchorEl] = useState<Element | null>(null);
  const [isOpenBookingPopover, setIsOpenBookingPopover] =
    useState<boolean>(false);

  const [isOpenConfirmationPopover, setIsOpenConfirmationPopover] =
    useState<boolean>(false);

  const [, setRender] = useState<number>(0);
  const [savedBookmarkedResponse, setSavedBookmarkedResponse] = useState<any>();
  const [bidSentBookPending, setBidSendBookPending] = useState<Array<string>>(
    []
  );
  const [pendingLoadBookStatus, setPendingLoadBookStatus] =
    useState<IPendingLoadBookStatus>({});

  useEffect(() => {
    setPendingLoadBookStatus(pendingLoadBookConfirmationStatus);
  }, [pendingLoadBookConfirmationStatus]);

  useEffect(() => {
    if (!params || Object.keys(params).length === 0) return;
  }, [params]);
  const bookmarkLoads = async (e: any, data: any) => {
    e.stopPropagation();
    try {
      const userDetails = StorageManager.getUser() || {};
      const payload = {
        dispatcherId: userDetails.id,
        id: data.keyId,
        savedLoad: data['actions']['savedLoadsData'],
      };
      const request = new BookmarkLoadRequest(payload);
      const response = await loadsearchService.bookmarkedLoad(request);
      if (response && !(response instanceof ServiceError)) {
        setSavedBookmarkedResponse({
          ...savedBookmarkedResponse,
          ...{
            [response['savedLoad']['id']]: response['savedLoad']['bookMarkId'],
          },
        });
        onBookmark(data.keyId, response['savedLoad']['bookMarkId']);
      }
    } catch (err) {
      throw err;
    }
  };

  const unBookmarkLoads = async (
    e: React.MouseEvent<SVGSVGElement>,
    data: ILoad
  ) => {
    e.stopPropagation();
    try {
      const payload: {
        id: string;
      } = {
        id:
          savedBookmarkedResponse && savedBookmarkedResponse[data.id]
            ? savedBookmarkedResponse[data.id]
            : data['bookMarkId'],
      };

      const request = new UnBookmarkLoadRequest(payload);
      const response = await loadsearchService.unBookmarkedLoad(request);
      setRender((prev: number) => prev + 1);

      unBookmark(data, data['bookMarkId'], tabElm);
    } catch (err) {
      throw err;
    }
  };

  const bidSent = (data: any) => {
    setSavedBookmarkedResponse({
      ...savedBookmarkedResponse,
      ...{ [data['savedLoad']['id']]: data['savedLoad']['bookMarkId'] },
    });

    onBookmark(data['savedLoad']['id'], data['savedLoad']['bookMarkId']);
    setBidSendBookPending([...bidSentBookPending, data['savedLoad']['id']]);
  };

  const handleInstantBooking = async (
    selectedLoadData: ILoadSearchModel,
    rowId: string
  ) => {
    setIsOpenConfirmationPopover(false);
    try {
      const payload = {
        selectedLoad: selectedLoadData,
      };
      const request = new LoadInstantBookingRequest(payload);
      const response = await loadsearchService.loadInstantBooking(request);
      RootStoreInstence.setNotificationType({
        message: 'Booking request sent successfully',
        type: 'SUCCESS',
      });
      setPendingLoadBookStatus({
        ...pendingLoadBookStatus,
        [rowId]: {
          isDropped: false,
          isBooked: true,
        },
      });
    } catch (err) {
      if (err) {
        RootStoreInstence.setNotificationType({
          message:
            err?.error?.response?.data?.apierror?.message ||
            'something went wrong',
          type: 'FAILURE',
        });
      }
    }
  };

  const handleClosePendingLoads = () => {
    setAnchorEl(null);
    setSelectedAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setSelectedAnchorEl(event?.target?.innerText);
  };

  const gotPendingLoad = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLAnchorElement>
  ) => {
    await handleBook(event, params, 'manualBook');
    handleClosePendingLoads();
  };

  const updateLoadAction = async (
    loadId: string,
    nextStatus: ELoadStatus,
    payload?: UpdateStatusPayload
  ): Promise<ServiceError | UpdateStatusResponse> => {
    const response = await LoadActionsController.instance().handleActionOnLoad({
      loadId: loadId,
      status: nextStatus,
      updateData: payload,
    });
    return response;
  };

  const notBookPendingLoad = async (
    _event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLAnchorElement>,
    row: ILoadsearchTableItem
  ) => {
    try {
      const payload = {
        savedLoad: row?.actions?.savedLoadsData,
        bidStatusAction: false,
      };
      const request = new UpdateBidLoadRequest(payload);
      const response = await loadsearchService.updateBidLoad(request);
      if (response) {
        if (row.loadId) {
          const currentDate = moment();
          const requestBody: UpdateStatusPayload = {
            completionDate: '',
            invoicedDate: '',
            paidDate: '',
            invoiceNotes: undefined,
            noteDetails: undefined,
          };
          const loadCancellationDetails: LoadCancellationDetails = {
            cancellationDate: currentDate?.toISOString() as string,
            invoiceNotes: '',
            keepInvoiceLineItems: false,
            markAsNonInvoiceable: true,
            noteDetails: {
              updateTime: new Date().toISOString(),
              userId: getUserId(),
            },
          };
          requestBody.loadCancellationDetails = loadCancellationDetails;

          const loadResponse = await updateLoadAction(
            row?.loadId as string,
            ELoadStatus.CANCELLED,
            requestBody
          );
        }
        setPendingLoadBookStatus({
          ...pendingLoadBookStatus,
          [row.keyId]: {
            isDropped: true,
            isBooked: false,
          },
        });
        handleClosePendingLoads();
      }
    } catch (err) {
      throw err;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const bookDroppedBookedRender = () => {
    if (
      bidSentBookPending.includes(params?.row?.keyId) ||
      params?.row?.actions?.savedLoadsData?.bidSent
    ) {
      /* null value are present in bidStatusAction, We need to check for boolean false */
      if (
        params?.row?.actions?.savedLoadsData?.bidStatusAction === false ||
        (pendingLoadBookStatus &&
          pendingLoadBookStatus[params.row.keyId] &&
          pendingLoadBookStatus[params.row.keyId]['isDropped'])
      ) {
        if (
          pendingLoadBookStatus &&
          pendingLoadBookStatus[params.row.keyId] &&
          pendingLoadBookStatus[params.row.keyId]['isBooked']
        ) {
          return (
            <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
              <Button
                sx={{
                  height: 30,
                  fontSize: '13px',
                  textTransform: 'none',
                  color: 'success.main',
                  borderColor: 'success.main',
                }}
                variant="outlined"
                startIcon={
                  <CheckOutlinedIcon
                    fontSize={'small'}
                    sx={{ color: 'success.main' }}
                  />
                }
                size="small"
                onClick={(e) => {
                  handleBook(e, params, 'pending'), handleClick(e);
                }}
              >
                Booked
              </Button>
            </Permission>
          );
        } else {
          return (
            <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
              <Button
                sx={{
                  height: 30,
                  fontSize: '13px',
                  textTransform: 'none',
                  color: 'error.main',
                  borderColor: 'error.main',
                }}
                variant="outlined"
                startIcon={
                  <ClearIcon fontSize={'small'} sx={{ color: 'error.main' }} />
                }
                size="small"
                onClick={(e) => {
                  handleBook(e, params, 'pending'), handleClick(e);
                }}
              >
                Dropped
              </Button>
            </Permission>
          );
        }
      } else if (
        params?.row?.actions?.savedLoadsData?.bidStatusAction === true ||
        (pendingLoadBookStatus &&
          pendingLoadBookStatus[params.row.keyId] &&
          pendingLoadBookStatus[params.row.keyId]['isBooked'])
      ) {
        return (
          <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
            <Button
              sx={{
                height: 30,
                fontSize: '13px',
                textTransform: 'none',
                color: 'success.main',
                borderColor: 'success.main',
              }}
              variant="outlined"
              startIcon={
                <CheckOutlinedIcon
                  fontSize={'small'}
                  sx={{ color: 'success.main' }}
                />
              }
              size="small"
              onClick={(e) => {
                handleBook(e, params, 'pending'), handleClick(e);
              }}
            >
              Booked
            </Button>
          </Permission>
        );
      } else {
        return (
          <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
            <Button
              sx={{
                height: 30,
                fontSize: '13px',
                textTransform: 'none',
                color: 'warning.main',
                borderColor: 'warning.main',
              }}
              variant="outlined"
              startIcon={
                <AccessTimeOutlinedIcon
                  fontSize={'small'}
                  sx={{ color: 'warning.main' }}
                />
              }
              size="small"
              onClick={(e) => {
                handleBook(e, params, 'pending'), handleClick(e);
              }}
            >
              Pending
            </Button>
          </Permission>
        );
      }
    } else {
      if (
        pendingLoadBookStatus &&
        pendingLoadBookStatus[params.row.keyId] &&
        pendingLoadBookStatus[params.row.keyId]['isBooked']
      ) {
        return (
          <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
            <Button
              sx={{
                height: 30,
                fontSize: '13px',
                textTransform: 'none',
                color: 'success.main',
                borderColor: 'success.main',
              }}
              variant="outlined"
              startIcon={
                <CheckOutlinedIcon
                  fontSize={'small'}
                  sx={{ color: 'success.main' }}
                />
              }
              size="small"
              onClick={(e) => {
                handleBook(e, params, 'pending'), handleClick(e);
              }}
            >
              Booked
            </Button>
          </Permission>
        );
      } else if (
        params?.row?.actions?.savedLoadsData?.bidStatusAction === true
      ) {
        return (
          <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
            <Button
              sx={{
                height: 30,
                fontSize: '13px',
                textTransform: 'none',
                color: 'success.main',
                borderColor: 'success.main',
              }}
              variant="outlined"
              startIcon={
                <CheckOutlinedIcon
                  fontSize={'small'}
                  sx={{ color: 'success.main' }}
                />
              }
              size="small"
              onClick={(e) => {
                handleBook(e, params, 'pending'), handleClick(e);
              }}
            >
              Booked
            </Button>
          </Permission>
        );
      } else {
        return (
          <Permission includes={[AXELE_PERMISSION_TYPE.LOAD_ADD]}>
            <Button
              sx={{ height: 30, fontSize: '13px', textTransform: 'none' }}
              variant="contained"
              startIcon={
                actionDataEnum.bookApi in
                params.row.actions?.savedLoadsData?.actionData ? (
                  <BoltOutlined sx={{}} fontSize={'small'} />
                ) : (
                  ''
                )
              }
              size="small"
              onClick={(e) => {
                handleBook(e, params, 'pending');
                setIsOpenBookingPopover(true);
                setBookingAnchorEl(e.currentTarget);
                // gotPendingLoad(e);
              }}
            >
              Book
            </Button>
          </Permission>
        );
      }
    }
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <BidTooltip
          pendingLoadBookStatus={pendingLoadBookStatus}
          bidSent={bidSent}
          selectedRowData={params.row}
        />

        {bookDroppedBookedRender()}

        {isBookmarkIconRequired && (
          <>
            {params.row.actions.savedLoadsData.bookMarkId ? (
              <IconButton>
                <BookmarkIcon
                  onClick={(e) =>
                    unBookmarkLoads(e, params.row.actions.savedLoadsData)
                  }
                  sx={{ margin: '-10px' }}
                  fontSize={'small'}
                  color="primary"
                />
              </IconButton>
            ) : (
              <IconButton>
                <BookmarkBorderOutlinedIcon
                  onClick={(e) => bookmarkLoads(e, params.row)}
                  sx={{ margin: '-10px' }}
                  fontSize={'small'}
                  color="primary"
                />
              </IconButton>
            )}
          </>
        )}
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePendingLoads}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: '12%', borderRadius: '15px' },
        }}
      >
        <Box
          sx={{
            margin: '5px',
            padding: '5px',
          }}
        >
          <Box>
            <Box
              sx={{
                textAlign: 'center',
                // margin: '15px',
                p: '5px',
              }}
            >
              <Typography sx={{ color: 'text.primary', fontWeight: 600 }}>
                How did it Go?
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {selectedAnchorEl && selectedAnchorEl === 'Booked' ? (
                <Box>
                  <Button
                    sx={{
                      borderRadius: '14px',
                      borderColor: 'rgba(43, 100, 203, 0.5)',
                      textTransform: 'none',
                    }}
                    onClick={(event) => notBookPendingLoad(event, params.row)}
                    variant={'outlined'}
                  >
                    {' '}
                    No Luck
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Button
                    sx={{
                      borderRadius: '14px',
                      borderColor: 'rgba(43, 100, 203, 0.5)',
                      textTransform: 'none',
                    }}
                    onClick={(event) => gotPendingLoad(event)}
                    variant={'contained'}
                  >
                    {' '}
                    <CheckOutlinedIcon /> Got The Load
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
      <Dialog
        open={false}
        // onClose={() => setOpen(false)}
        PaperProps={{
          style: styles.dialog,
        }}
      >
        <Box>Data</Box>
      </Dialog>
      <BookingConfirmationPopover
        open={isOpenConfirmationPopover}
        data={params?.row?.actions?.savedLoadsData}
        handleCloseConfirmation={() => {
          setIsOpenConfirmationPopover(false);
        }}
        handleInstantBooking={(data) =>
          handleInstantBooking(data, params?.row?.id)
        }
      />
      <InstantBookingPopover
        open={isOpenBookingPopover}
        onConfirmBookingClick={() => {
          setIsOpenConfirmationPopover(true);
          setIsOpenBookingPopover(false);
        }}
        data={params?.row?.actions?.savedLoadsData?.actionData}
        anchorEl={bookingAnchorEl}
        onPopoverClose={() => setIsOpenBookingPopover(false)}
        gotPendingLoad={gotPendingLoad}
      />
      {isOpenBookingPopover && (
        <DeletePopup
          open={false}
          onClose={() => setIsOpenBookingPopover(false)}
          onAction={() => {
            setIsOpenBookingPopover(false);
            // onViewDelete && onViewDelete();
          }}
          title={'Delete View?'}
        />
      )}
    </>
  );
};

interface ITableColumButton {
  params: GridRenderCellParams<any, ILoadsearchTableItem>;
  onBookmark: (id: string, bookMarkId: string) => void;
  unBookmark: (
    data: ILoad,
    bookMarkId: ILoad['bookMarkId'],
    tabElm: ITableColumButton['tabElm']
  ) => void;
  tabElm?: string | undefined;
  handleBook: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLAnchorElement>,
    params: GridRenderCellParams<any, ILoadsearchTableItem>,
    typeOfBook?: 'manualBook' | 'pending'
  ) => void;
  pendingLoadBookConfirmationStatus: any;
  isBookmarkIconRequired: boolean;
}
