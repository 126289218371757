import { memo } from 'react';
import { SortIcon } from './SortIcon';

export interface SortIconFilterProps {
  column: any;
  defaultFilterValue: Record<string, any>;
  onChangeCb?: (isUp: boolean, name: string) => void;
  isFormControl: boolean;
  field: Record<string, any> | undefined;
}
export const SortIconFilter = memo(
  ({
    column,
    defaultFilterValue,
    onChangeCb,
    isFormControl = false,
    field = {},
  }: SortIconFilterProps) => {
    return (
      <SortIcon
        up={isFormControl ? field.value : defaultFilterValue[column.name]}
        onChange={(isUp: boolean) => {
          field?.onChange?.(isUp);
          onChangeCb?.(isUp, column.name);
        }}
      />
    );
  }
);
SortIconFilter.displayName = 'SortIconFilter';
