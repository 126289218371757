import { useFormContext } from 'react-hook-form';
import { DateRangePickerFilter } from '../common/Ui/DateRangePicker/DateRangePickerFilter';
import {
  MultipleAutocompleteFilter,
  MultipleAutocompleteForm,
} from '../common/Ui/MultipleAutocomplete';
import {
  SingleAutocompleteFilter,
  SingleAutocompleteForm,
} from '../common/Ui/SingleAutocomplete';

import { DateRangePickerForm } from '../common/Ui/DateRangePicker/DateRangePickerForm';

import { Box } from '@mui/material';
import FormDatePicker from '../common/Ui/FormDatePicker/FormDatePicker';
import Switch from '../common/Ui/Switcher';
import TextField from '../common/Ui/TextField/TextField';
import { getGridCompanyTimezoneSettings } from './Timezone.utils';

export function CreateFiltersMetaDataForm(
  formConfig: any,
  control: any,
  isFilterForm = false
) {
  return (
    <>
      {formConfig.map((config: any) => {
        const name = isFilterForm ? config.name : `metaData[${config.name}]`;

        switch (config.type) {
          case 'MultipleAutocomplete':
            return (
              <MultipleAutocompleteForm
                key={config.key}
                control={control}
                name={name}
                fieldName={config.fieldName}
                getOptions={config.getData}
                options={config.options}
                label={config.label}
                variant="standard"
              />
            );

          case 'SingleAutocomplete':
            return (
              <SingleAutocompleteForm
                key={config.key}
                control={control}
                name={name}
                fieldName={config.fieldName}
                fieldValue={config.fieldValue}
                options={config.options}
                label={config.label}
              />
            );

          case 'DateRangePicker':
            return (
              <DateRangePickerForm
                key={config.key}
                control={control}
                name={name}
                startText={config.startText}
                toText={'-'}
                endText={config.endText}
              />
            );
          case 'DatePicker':
            return (
              <FormDatePicker
                iso
                key={config.key}
                control={control}
                name={name}
                label={config.label}
                {...getGridCompanyTimezoneSettings()}
              />
            );
          case 'TextField':
            return (
              <TextField
                key={config.key}
                control={control}
                name={name}
                label={config.label}
              />
            );
          case 'Custom':
            const CustomComponentForm = config.customComponentForm;
            return (
              <CustomComponentForm
                key={config.key}
                control={control}
                name={name}
                label={config.label}
              />
            );

          default:
            return null;
        }
      })}
    </>
  );
}

type quickFiltersType = {
  config: any;
  filters: any;
  onChangeCallback: (data: string[], name: string) => Promise<void>;
};

const renderQuickFilter = ({
  config,
  filters,
  onChangeCallback,
}: quickFiltersType) => {
  if (config.name in filters) {
    let component = null;
    switch (config.type) {
      case 'MultipleAutocomplete':
        component = (
          <Box sx={{ marginRight: '20px' }}>
            <MultipleAutocompleteFilter
              key={config.key}
              name={config.name}
              label={config.label}
              fieldName={config.fieldName}
              getOptions={config.getData}
              value={filters[config.name]}
              onChangeCb={onChangeCallback}
              customStyles={config.customStyles}
            />
          </Box>
        );
        break;

      case 'SingleAutocomplete':
        component = (
          <Box sx={{ width: '200px', marginRight: '20px' }}>
            <SingleAutocompleteFilter
              key={config.key}
              name={config.name}
              fieldName={config.fieldName}
              label={config.label}
              value={filters[config.name]}
              options={config.options}
              onChangeCb={onChangeCallback}
            />
          </Box>
        );
        break;

      case 'DateRangePicker':
        component = (
          <Box sx={{ marginRight: '20px' }}>
            <DateRangePickerFilter
              label={config.label}
              key={config.key}
              name={config.name}
              startText={config.startText}
              toText={config.toText}
              endText={config.endText}
              onChangeCallback={onChangeCallback}
              value={filters[config.name]}
            />
          </Box>
        );
        break;

      default:
        break;
    }

    if (config.default) {
      return component;
    }
    if (filters[config.name] instanceof Array) {
      if (filters[config.name].length) {
        return component;
      }
    } else if (filters[config.name]) {
      return component;
    }
    return null;
  }
  return null;
};

export function CreateQuickFiltersForm(
  formConfig: any,
  filters: any,
  onChangeCallback: (data: string[], name: string) => Promise<void>
) {
  return (
    <>
      {formConfig.map((config: any) =>
        renderQuickFilter({ config, filters, onChangeCallback })
      )}
    </>
  );
}

export type columnsType = {
  name: string;
  label: string;
  disabled: boolean;
  defaultChecked?: boolean;
};

export function CreateColumnsFiltersForm(columns: columnsType[]) {
  const { control } = useFormContext();
  return (
    <>
      {columns.map((column, i) => (
        <Switch
          key={i}
          label={column.label}
          name={`columnFilters[${column.name}]`}
          control={control}
          disabled={column.disabled}
          defaultChecked={!!column.defaultChecked}
        />
      ))}
    </>
  );
}
