import {
  CloseViewRequest,
  CopyViewRequest,
  CreateViewRequest,
  DeleteViewRequest,
  GetAvailableViewsRequest,
  GetOwnViewsRequest,
  UpdateViewRequest,
  ValidateViewRequest,
} from '../models';
import { viewService } from '../api';
import { ServiceError } from '../api/interfaces';
import { View } from '../types';

export const getOwnCustomViews = async (
  dictionary: string | undefined,
  currentEntities: string[]
): Promise<View[]> => {
  const request = new GetOwnViewsRequest();
  request.entities = currentEntities;
  const result = await viewService.getOwnCustomViews(request, dictionary);
  if (result instanceof ServiceError) {
    return [];
  } else {
    const ownViews: View[] = result.map((view: any) => new View(view));
    return ownViews;
  }
};

export const viewCreate = async (
  data: View,
  dictionary: string
): Promise<View | undefined> => {
  const request = new CreateViewRequest();
  const response = await viewService.createView(request, data, dictionary);
  if (response instanceof ServiceError) {
    return;
  } else {
    const newView = new View(response);
    return newView;
  }
};

export const viewUpdate = async (
  data: View,
  dictionary: string
): Promise<View | undefined> => {
  const request = new UpdateViewRequest();
  const response = await viewService.updateView(request, data, dictionary);
  if (response instanceof ServiceError) {
    return;
  } else {
    return new View(response);
  }
};

export const viewCopy = async (
  id: string,
  dictionary: string
): Promise<View | undefined> => {
  const request = new CopyViewRequest();
  request.id = id;
  request.addUserIdToParams();
  const response = await viewService.copyView(request, dictionary);
  if (response instanceof ServiceError) {
    return;
  } else {
    const copiedView = new View(response);
    return copiedView;
  }
};

export const viewClose = async (
  viewId: string,
  dictionary: string
): Promise<string | undefined> => {
  const request = new CloseViewRequest();
  request.id = viewId;
  const result = await viewService.closeView(request, dictionary);
  if (result instanceof ServiceError) {
    return;
  } else {
    return result;
  }
};

export const viewDelete = async (
  viewId: string,
  dictionary: string
): Promise<string | undefined> => {
  const request = new DeleteViewRequest();
  request.id = viewId;
  const result = await viewService.deleteView(request, dictionary);
  if (result instanceof ServiceError) {
    return;
  } else {
    return result;
  }
};

export const getAvailableViews = async (
  dictionary: string,
  currentEntities: string[]
): Promise<View[] | undefined> => {
  const request = new GetAvailableViewsRequest();
  request.entities = currentEntities;
  const response = await viewService.getAvailableViews(request, dictionary);
  if (response instanceof ServiceError) {
    return;
  } else {
    return response.map((view: any) => new View(view));
  }
};

export const validateView = async (
  viewId: string,
  name: string,
  entity: string,
  dictionary: string | undefined,
  currentEntities: string[]
): Promise<
  { hasErrors: boolean; errors: { [key: string]: string } } | undefined
> => {
  const request = new ValidateViewRequest();
  request.id = viewId;
  request.name = name;
  request.entity = entity;
  request.entities = currentEntities;
  const response = await viewService.validateView(request, dictionary);
  if (response instanceof ServiceError) {
    return;
  } else {
    return response;
  }
};
