import { Dispatch, SetStateAction, useMemo } from 'react';
import StorageManager from '../../StorageManager/StorageManager';
import LongMenu from '../../common/LongMenu';
import { AXELE_PERMISSION_TYPE, Permission } from '../../common/Permission';
import { useEquipment } from '../../contexts/EquipmentContext';
import { AssetsCount } from '../../models';
import { DeleteButtonIcon } from '../../ui-kit/components/Assets';
import DeletePopup from '../../ui-kit/components/DeletePopup';
import { getAccountTypeWithRoleCode, isHasPermission } from '../../utils';
import { deleteTractorAndTrailerDocs } from '../shared/equipment';
import TractorController from '../tractors/Controller';
import TrailerController from './TrailerController';
import { Trailer } from './models/Trailer';
import { t } from 'i18next';
type Status = 'AVAILABLE' | 'UNAVAILABLE' | 'ASSIGNED';

interface ActionsSectionProps {
  trailerData: Trailer;
  onDeleted: (data: unknown) => void;
  deactivateTrailer: () => void;
  onUnassign: (driverGroupId: string | undefined) => any;
  onStatusChange: (trailerId: string, status: string) => Promise<boolean>;
  openDialog: 'markAvailable' | 'markUnavailable' | 'delete' | '';
  setOpenDialog: Dispatch<
    SetStateAction<
      | ''
      | 'markAvailable'
      | 'markUnavailable'
      | 'inactive'
      | 'inactiveFail'
      | 'delete'
    >
  >;
  showPopup?: { allow?: boolean; body?: string } | null;
  setShowPopup: Dispatch<
    SetStateAction<
      | {
          allow?: boolean | undefined;
          body?: string | undefined;
        }
      | null
      | undefined
    >
  >;
}

interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}

const showPopupConfigs = (message: string) => ({
  allowedDelete: {
    allow: true,
    body: t('deleteTrailerConfirmation'),
  },
  notAllowedDelete: {
    body: `${message}.`,
  },
  notAllowedMarkInactive: {
    body: `${message}.`,
  },
});

export function ActionsSection({
  trailerData,
  onDeleted,
  onStatusChange,
  onUnassign,
  openDialog,
  setOpenDialog,
  showPopup,
  setShowPopup,
  deactivateTrailer,
}: ActionsSectionProps) {
  const { setEquipmentCount } = useEquipment();

  const [id, status, driverGroupId] = [
    trailerData.id,
    trailerData.status,
    trailerData.driverGroup?.id,
  ];
  const storageUserData = StorageManager.getUser();
  const noPermissionForDeleteTrailer = !isHasPermission([
    AXELE_PERMISSION_TYPE.EQUIPMENT_REMOVE,
  ]);
  const options = useMemo((): Action[] => {
    const markUnavailable = {
      name: 'Mark as Unavailable',
      action: () => {
        setOpenDialog('markUnavailable');
        setShowPopup({ allow: true });
      },
    };

    const markAvailable = {
      name: 'Mark as Available',
      action: () => {
        setOpenDialog('markAvailable');
        setShowPopup({ allow: true });
      },
    };

    const deleteAction = {
      customStyles: { paddingRight: '5px' },
      name: 'Delete Trailer',
      action: () => {
        handleDeleteClick();
        setOpenDialog('delete');
      },
      startIcon: DeleteButtonIcon({}),
    };

    const markAsInactive = {
      customStyles: { paddingRight: '5px' },
      name: 'Mark as Inactive',
      action: () => {
        setOpenDialog('inactive');
        setShowPopup({ allow: true });
      },
    };

    if (status === 'AVAILABLE') {
      return !noPermissionForDeleteTrailer
        ? [markUnavailable, deleteAction, markAsInactive]
        : [markUnavailable, markAsInactive];
    } else if (status === 'UNAVAILABLE') {
      return !noPermissionForDeleteTrailer
        ? [markAvailable, deleteAction, markAsInactive]
        : [markAvailable, markAsInactive];
    } else if (status === 'INACTIVE') {
      return [markAvailable];
    } else {
      return [markAvailable];
    }
  }, [status]);

  const handleStatusAction = async () => {
    const statusAction: Status =
      status === 'AVAILABLE' ? 'UNAVAILABLE' : 'AVAILABLE';

    let response;
    let unAssignResponse;
    if (status === 'ASSIGNED') {
      unAssignResponse = await onUnassign(driverGroupId);
    } else {
      response = await onStatusChange(id, statusAction);
    }

    if (response || unAssignResponse) {
      setOpenDialog('');
      setShowPopup(null);
    }
  };

  function handleDeleteClick() {
    TrailerController.instance().verifyTrailerDeletion(id, (canDelete) => {
      if (canDelete.assetIsAllowedToDelete) {
        return setShowPopup(showPopupConfigs(canDelete.message).allowedDelete);
      }
      return setShowPopup(showPopupConfigs(canDelete.message).notAllowedDelete);
    });
  }

  function handleMarkAsInactive() {
    TrailerController.instance().markAsInactive(id, (canDelete) => {
      if (!canDelete) {
        setOpenDialog('');
        setShowPopup(null);
        setOpenDialog('inactiveFail');
        setShowPopup(showPopupConfigs('inactiveFail').notAllowedMarkInactive);
      } else {
        setOpenDialog('');
        setShowPopup(null);
        deactivateTrailer();
      }
    });
  }

  function handleDelete() {
    if (showPopup?.allow) {
      TrailerController.instance().deleteTrailerById(id, (result) => {
        deleteTractorAndTrailerDocs(trailerData);
        if (!result) {
          onDeleted(id);
          setEquipmentCount({
            ...TractorController.instance().equipmentCount,
          } as AssetsCount);
        }
      });
    }
    setShowPopup(null);
  }

  const dialogConfigs = useMemo(() => {
    return {
      markUnavailable: {
        title: t('markUnavailable'),
        backgroundColor: 'primary.main',
        body: t('markTrailerUnavailableConfirmation'),
        subtitle: undefined,
        width: '446px',
        buttonText: 'Yes',
        buttonTextColor: 'primary.main',
        onAction: handleStatusAction,
        onClose: () => setOpenDialog(''),
        cancelText: undefined,
      },
      inactive: {
        title: 'Deactivate',
        backgroundColor: 'primary.main',
        subtitle:
          'Are you sure you want to deactivate the trailer? Please note that the trailer will be non-visible for making assignments.',
        width: '446px',
        buttonText: 'Deactivate',
        buttonTextColor: 'primary.main',
        onAction: handleMarkAsInactive,
        onClose: () => setOpenDialog(''),
        cancelText: undefined,
      },
      inactiveFail: {
        title: t('warning'),
        backgroundColor: 'primary.main',
        subtitle:
          'You cannot deactivate the trailer as there are non-completed assignments',
        width: '446px',
        onAction: undefined,
        onClose: () => {
          setOpenDialog('');
          setShowPopup(null);
        },
        cancelText: 'Ok',
      },
      markAvailable: {
        title: t('markAsAvailable'),
        backgroundColor: 'primary.main',
        body: t('markTrailerAsAvailable'),
        subtitle: status === 'ASSIGNED' ? t('markAvailableNote') : undefined,
        width: '446px',
        buttonText: 'Yes',
        buttonTextColor: 'primary.main',
        onAction: handleStatusAction,
        onClose: () => setOpenDialog(''),
        cancelText: undefined,
      },
      delete: {
        title: t('deleteTrailer'),
        backgroundColor: undefined,
        buttonText: 'Yes',
        body: showPopup?.body,
        subtitle: undefined,
        width: '282px',
        onAction: showPopup?.allow ? handleDelete : undefined,
        onClose: () => {
          setOpenDialog('');
          setShowPopup(null);
        },
        cancelText: showPopup?.allow ? 'Cancel' : 'Ok',
      },
    };
  }, [setOpenDialog, showPopup]);

  return (
    <>
      <LongMenu color={'primary.contrast'} options={options} />

      {/* <Permission contains={[AXELE_PERMISSION_TYPE.EQUIPMENT_REMOVE]}>
        <LongMenu color={'primary.contrast'} options={options} />
      </Permission> */}
      {openDialog && showPopup && (
        <DeletePopup
          title={dialogConfigs[openDialog].title}
          backgroundColor={dialogConfigs[openDialog].backgroundColor}
          body={dialogConfigs[openDialog].body}
          subtitle={dialogConfigs[openDialog].subtitle}
          width={dialogConfigs[openDialog].width}
          buttonText={dialogConfigs[openDialog].buttonText}
          buttonTextColor={dialogConfigs[openDialog]?.buttonTextColor}
          onAction={dialogConfigs[openDialog].onAction}
          onClose={dialogConfigs[openDialog].onClose}
          cancelText={dialogConfigs[openDialog].cancelText}
          open
        />
      )}
    </>
  );
}
