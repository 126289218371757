import { Box } from '@mui/material';
import React from 'react';
import AppointmentConfirmation from '../../../../components_v2/generic/appointment-confirmation';
import {
  ExpandableTimelineV3PanelView,
  TripV3DataProps,
} from '../../TripPlanV3';
import { StyledRightSubTitle } from '../../TripPlanV3/styles';
import {
  StopSolutionV3Prop,
  StopSolutionV3StopType,
} from '../../TripPlanV3/types';
import {
  TripStopActivityEnum,
  TripStopActivityTitleEnum,
  TripStopStatusEnum,
} from '../../types';
import { TimelineFormatter } from './DetailedDataSection';
import {
  FloatingActionButtonSection,
  FloatingActionButtonSectionProps,
} from './FloatingActionButtonSection';
import { FloatingReSequenceStopIcon } from './FloatingReSequenceStopIcon';
import { FloatingTruckIconSection } from './FloatingTruckIconSection';
import { getDislayLocation } from './timelineV3.utils';

const getTripV3StopTypeTitleWithDefaultValue = (
  stopSolution: StopSolutionV3Prop,
  defaultValue: string
): string => {
  switch (stopSolution?.type) {
    case StopSolutionV3StopType.TRIP:
      return `Trip Stop`;
    case StopSolutionV3StopType.RELAY:
      return 'Relay';
    default:
      return defaultValue;
  }
};

export const getTripV3ActivityTitle = (
  stopSolution: StopSolutionV3Prop
): string => {
  switch (stopSolution?.activityType) {
    case TripStopActivityEnum.PICKUP:
    case TripStopActivityEnum.PICKUP_AND_DROPOFF:
    case TripStopActivityEnum.DROPOFF:
      return TripStopActivityTitleEnum[stopSolution?.activityType];
    default:
      return getTripV3StopTypeTitleWithDefaultValue(stopSolution, '');
  }
};

export const getTripV3StopTypeTitle = (
  stopSolution: StopSolutionV3Prop
): string => {
  return getTripV3StopTypeTitleWithDefaultValue(
    stopSolution,
    getTripV3ActivityTitle(stopSolution)
  );
};

export const RightSubTitleSection = ({
  enablePreAndNext,
  expanded,
  renderTimelineSeparatorIconOnly,
  showFloatingActionButton,
  stopSolution,
  onClickAddStopHandler,
  onClickAddRelayHandler,
  onClickResequenceDownHandler,
  onClickResequenceUpHandler,
  data,
  isNotCompletedBeforeClosetEnRoute,
  isShowFloatingTruckIconSection,
  showFloatingReSequenceStopIcon = false,
  currentIndex,
  panelView,
  isManifestDetailPanel,
}: {
  enablePreAndNext?: boolean;
  expanded?: boolean;
  renderTimelineSeparatorIconOnly: boolean;
  showFloatingActionButton: boolean;
  data: TripV3DataProps;
  isNotCompletedBeforeClosetEnRoute?: boolean;
  isShowFloatingTruckIconSection?: boolean;
  showFloatingReSequenceStopIcon?: boolean;
  currentIndex: number;
  panelView?: ExpandableTimelineV3PanelView;
  isManifestDetailPanel?: boolean;
} & FloatingActionButtonSectionProps): JSX.Element => {
  if (!renderTimelineSeparatorIconOnly) {
    const appointmentWindow: string[] =
      TimelineFormatter.getLeftSubTitleDateTime(
        stopSolution?.appointmentStartDate,
        stopSolution?.appointmentEndDate,
        stopSolution?.location?.timezone
      );

    const isAddRelayAllowed = () => {
      const stopActivityList = data?.stopList?.map((e) => e?.type);

      const isRelayStopsCount: number = stopActivityList?.filter(
        (e) => e === StopSolutionV3StopType.RELAY
      )?.length;
      if (isRelayStopsCount === 2) return true;
      let isLoadStopExistInPast = false;
      let isLoadStopExistInFuture = false;
      for (let i = currentIndex; i >= 0; i--) {
        if (stopActivityList[i] !== StopSolutionV3StopType.TRIP) {
          isLoadStopExistInPast = true;
          break;
        }
      }
      for (let i = currentIndex + 1; i < stopActivityList?.length; i++) {
        if (stopActivityList[i] !== StopSolutionV3StopType.TRIP) {
          isLoadStopExistInFuture = true;
          break;
        }
      }
      if (isLoadStopExistInPast && isLoadStopExistInFuture) return true;
      return false;
    };

    const hideAddRelayAction = !isAddRelayAllowed();

    const RightSubTitle = ({
      children,
    }: {
      children: React.ReactNode;
    }): JSX.Element => {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {children}
          </Box>
          <StyledRightSubTitle
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {getDislayLocation({
              withLocationName: true,
              location: stopSolution.location,
            })}
          </StyledRightSubTitle>

          {showFloatingActionButton ? (
            <FloatingActionButtonSection
              expanded={expanded}
              stopSolution={stopSolution}
              showFloatingTruckIcon={isNotCompletedBeforeClosetEnRoute}
              onClickAddStopHandler={onClickAddStopHandler}
              onClickAddRelayHandler={onClickAddRelayHandler}
              data={data}
              hideAddRelayAction={hideAddRelayAction}
              isTripStop={
                panelView === ExpandableTimelineV3PanelView.TripDetails ||
                isManifestDetailPanel
              }
            />
          ) : (
            <></>
          )}
          {isShowFloatingTruckIconSection ? (
            <FloatingTruckIconSection stopSolution={stopSolution} />
          ) : (
            <></>
          )}
          {showFloatingReSequenceStopIcon ? (
            <FloatingReSequenceStopIcon
              onClickResequenceUpHandler={onClickResequenceUpHandler}
              onClickResequenceDownHandler={onClickResequenceDownHandler}
              stopSolution={stopSolution}
              isSeparatorTruckIcon={
                stopSolution?.status === TripStopStatusEnum.ARRIVED
              }
            />
          ) : (
            <></>
          )}
        </Box>
      );
    };

    const AppointmentWindow = (): JSX.Element => {
      return (
        <StyledRightSubTitle
          sx={{
            display: 'inline',
            verticalAlign: 'top',
          }}
        >
          {`Appt: ${appointmentWindow.join(' ')}`}
        </StyledRightSubTitle>
      );
    };
    const DelayInMinutes = (): JSX.Element => {
      return stopSolution?.arrivalDelay ? (
        <StyledRightSubTitle
          iSBold={true}
          sx={{
            paddingLeft: '5px',
            color: 'error.main',
            display: 'inline',
            verticalAlign: 'top',
            whiteSpace: 'pre-wrap',
          }}
        >
          {`${TimelineFormatter.minutesToHours(
            stopSolution?.arrivalDelay
          )} Delay`}
        </StyledRightSubTitle>
      ) : (
        <></>
      );
    };

    return (
      <RightSubTitle>
        {!enablePreAndNext ? (
          <StyledRightSubTitle
            iSBold={true}
            sx={{
              whiteSpace: 'nowrap',
              pr: '5px',
            }}
          >
            {getTripV3StopTypeTitle(stopSolution)} :{' '}
          </StyledRightSubTitle>
        ) : (
          <></>
        )}
        <Box
          sx={{
            whiteSpace: 'pre-wrap',
            alignItems: 'center',
          }}
        >
          <AppointmentWindow />
          <AppointmentConfirmation
            status={stopSolution?.appointmentConfirmation}
            sx={{ position: 'relative', bottom: '5px', margin: '0 2px' }}
            size={24}
          />
          <StyledRightSubTitle
            sx={{
              display: 'inline',
              verticalAlign: 'top',
            }}
          >
            {stopSolution?.arrivalDelay ? '/' : ''}
          </StyledRightSubTitle>
          <DelayInMinutes />
        </Box>
      </RightSubTitle>
    );
  }
  return <></>;
};
