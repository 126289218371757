import { importService, loadService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import {
  GetCommodityTypesRequest,
  GetLoadAdditionalDetailsRequest,
  OptionType,
  UpdateLoadAdditionalDetailsRequest,
} from '../../../../models';
import { LoadSummary } from '../../../../subPages/loadsList/models/LoadSummary';
import { getCommodityTypesNames } from '../../../LoadSharedComponents/components/Commodity/utils';
import { LoadAdditionalDetails } from './models';

export const getLoadAdditionalDetails = async (
  loadId: string,
  equipmentTypeOptions: OptionType[]
) => {
  const requestData = new GetLoadAdditionalDetailsRequest({
    loadId,
  });

  const response = await loadService.getLoadAdditionalDetails(requestData);

  if (response instanceof ServiceError) {
    return null;
  }

  if (response.loadDetails) {
    const { trailerTypes } = response.loadDetails;

    response.loadDetails.trailerTypes =
      equipmentTypeOptions.filter((item) => trailerTypes.includes(item.key)) ||
      [];
    const request = new GetCommodityTypesRequest();
    const commodityTypes = await importService.getCommodityTypes(request);
    if (!(commodityTypes instanceof ServiceError)) {
      const { commodityData } = response.loadDetails;

      if (commodityData?.length) {
        response.loadDetails.commodityData = getCommodityTypesNames(
          commodityData,
          commodityTypes
        );
      }
    }
  }
  return response;
};

export const updateLoadAdditionalDetails = async (
  loadDetails: LoadAdditionalDetails
) => {
  const requestData = new UpdateLoadAdditionalDetailsRequest(loadDetails);
  const response = await loadService.updateLoadAdditionalDetails(requestData);
  return response instanceof ServiceError ? null : response;
};

export const updateLoadByEquipmentDetails = (
  load: LoadSummary,
  equipmentDetails: LoadAdditionalDetails
) => {
  load.sealNumber = equipmentDetails.sealNumber;
  load.chassisNumber = equipmentDetails.chassisNumber;
  load.containerNumber = equipmentDetails.containerNumber;
  load.loadDirection = equipmentDetails.loadDirection;
  load.appointmentNumber = equipmentDetails.appointmentNumber;
  load.appointmentNumber = equipmentDetails.appointmentNumber;
  load.containerNumber = equipmentDetails.containerNumber;
  load.totalWeight = equipmentDetails.totalWeight;
};
