import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { clone } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { SteeringOutlined } from '../../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import MultilineCellAxele from '../../../../../ui-kit/components/MultilineCell';
import { DirtyDetailsPanelManager } from '../../../../DetailsPanel/utils';
import { loadPanelNotesTab } from '../../../../PendoClassIDs/constants';
import ShakeButton from '../../../../ShakeButton/ShakeButton';
import UploadUpdated from '../../../../Ui/FileUpload/UploadUpdated';
import { NotesContext } from '../constants/constant';
import { NotesContextType, SingleNoteTypes } from '../constants/interface';
import { styles } from '../styles/styles';
import { CommonConstants } from '../../../../../locales/en/common/index';
import { DocumentConstants } from '../../../../../locales/en/reports/document/index';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export const SingleNote = ({ mode, item }: SingleNoteTypes) => {
  const {
    checkBoxChange,
    data,
    onNotesChange,
    onCancel,
    onSave,
    error,
    onFileChange,
    onDeleteAttachment,
  }: NotesContextType = useContext(NotesContext);

  const useDirty = (data: Record<any, any> = {}): boolean => {
    const originalData = useMemo(() => clone(data), []);

    const diffFields = Array.from(
      new Set(Object.keys(data).concat(Object.keys(originalData)))
    );

    return diffFields.some((field) => originalData?.[field] != data?.[field]);
  };

  const originalData = useMemo(() => clone(data), []);

  const getDirtyFields = (data) => {
    const dirtyFields = [];
    for (const key in data) {
      if (!(JSON.stringify(data[key]) === JSON.stringify(originalData[key]))) {
        dirtyFields.push(key);
      }
    }
    return dirtyFields;
  };

  const isDirty = useDirty(data);
  const dirtyFields = getDirtyFields(data);

  useEffect(() => {
    DirtyDetailsPanelManager.panelName = isDirty ? 'SingleNote' : null;

    DirtyDetailsPanelManager.isDirty = isDirty;
  }, [isDirty]);

  useEffect(() => {
    return () => {
      DirtyDetailsPanelManager.panelName = null;
      DirtyDetailsPanelManager.isDirty = false;
    };
  }, []);
  const { t, ready } = useTranslation();
  return (
    <Box sx={styles.singleNoteContainer}>
      <>
        <TextField
          id="notesText"
          label={CommonConstants.notes}
          variant="standard"
          inputProps={{ maxLength: 500 }}
          value={data?.notesText}
          sx={{ width: '100%' }}
          maxRows={4}
          onChange={(e: any) => onNotesChange(e, 'notesText')}
          required={true}
          multiline
          error={error ? true : false}
          helperText={error ? error : ''}
        />
        <Typography align="right" variant="body2" mt={0.5}>
          {`${data?.notesText?.length}/500`}
        </Typography>
      </>
      <Stack
        direction="row"
        width={'100%'}
        alignItems="center"
        mt={2}
        spacing={2}
      >
        {data?.shareDriver ? (
          <Box sx={styles.driverChecked}>
            <IconButton
              id={loadPanelNotesTab + 'ShareWithDriver'}
              sx={{ color: 'primary.main', alignItems: 'center', ml: 1.5 }}
              onClick={() =>
                item && item.notesType != 'INTERNAL'
                  ? checkBoxChange(true)
                  : checkBoxChange(!data?.shareDriver)
              }
            >
              <SteeringOutlined height={35} width={35} color="#FFFFFF" />
            </IconButton>
          </Box>
        ) : (
          <Box sx={styles.driverUnchecked}>
            <IconButton
              id={loadPanelNotesTab + 'ShareWithDriver'}
              onClick={() => checkBoxChange(!data?.shareDriver)}
              sx={{ ml: 1.5 }}
            >
              <SteeringOutlined height={35} width={35} />
            </IconButton>
          </Box>
        )}

        <MultilineCellAxele
          title={DocumentConstants.shareWithDriver}
          subtitle={DocumentConstants.shareWithDriverSublabel}
          titleStyle={styles.shareDriver}
          subtitleStyle={styles.subShare}
          alignTitle="left"
          alignSubtitle="left"
        />
      </Stack>

      {mode == 'create' ||
      (item &&
        (item.notesType == 'INTERNAL' || item?.notesType == 'DRIVER')) ? (
        <Grid item xs={12} style={{ marginBottom: 20, marginTop: 20 }}>
          <UploadUpdated
            onChange={onFileChange}
            onRemoveCB={() => onDeleteAttachment(item)}
            fileId={data?.attachment?.[0]?.documentId}
          />
        </Grid>
      ) : null}

      <Box mt={2} display={'flex'} justifyContent={'space-between'}>
        <ButtonImproved
          id={loadPanelNotesTab + 'Cancel'}
          variant="outlined"
          onClick={() => onCancel(mode)}
          label={t('cancel')}
        />
        <ShakeButton
          id={loadPanelNotesTab + 'Save'}
          variant="contained"
          label={t('save')}
          startIcon={<IconsComponent iconName="AddOutlined" source="MUIcons" />}
          onClick={() =>
            onSave(mode, item, dirtyFields.includes('attachment'), originalData)
          }
        />
      </Box>
    </Box>
  );
};
