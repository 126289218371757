import { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
}

const TableDataContainer: React.FC<IProps> = ({ children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      height: '100%',
      overflow: 'hidden',
      overflowX: 'scroll',
    }}
  >
    {children}
  </div>
);

export default TableDataContainer;
