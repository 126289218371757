import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Accordion from '../../../common/Accordion';
import { FormAddress } from '../../../common/Ui/AddressField';
import Checkbox from '../../../common/Ui/Checkbox';
import FormPhoneField from '../../../common/Ui/FormPhoneField/index';
import { MultipleAutocompleteForm } from '../../../common/Ui/MultipleAutocomplete';
import Select from '../../../common/Ui/Select';
import { SingleAutocompleteForm } from '../../../common/Ui/SingleAutocomplete';
// import Switch from '../../../common/Ui/Switcher';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import StorageManager from '../../../StorageManager/StorageManager';
import {
  userPage,
  userPageEmergencyContact,
} from '../../../common/PendoClassIDs/constants';
import { AXELE_ROLE_TYPE, AXELE_USER_TYPES } from '../../../common/Permission';
import FormDatePicker from '../../../common/Ui/FormDatePicker/FormDatePicker';
import TextField from '../../../common/Ui/TextField/TextField';
import { AssociatedTerminalType } from '../../../models';
import { UserDetails } from '../../../models/DTOs/user/User';
import TerminalShort from '../../../models/common/TerminalShort';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { getActiveRoles } from '../../../views/settings/roles/constants/helper';
import { getPrimaryDispatcherList } from '../../../views/settings/users/utils';
import { ADMIN_ROLES } from '../constants';
import {
  getByRoleIsFieldDisabled,
  getUserAssociatedTerminals,
  noAddUserPermission,
  noPermissionForAddRemoveEditUser,
} from '../utils';

const employmentTypes = [
  {
    value: 'Owner Operator',
    key: 'OWNER_OPERATOR',
  },
  {
    value: 'Employee',
    key: 'EMPLOYEE',
  },
];

const operationTypes = [
  {
    value: 'Local',
    key: 'LOCAL',
  },
  {
    value: 'Regional',
    key: 'REGIONAL',
  },
  {
    value: 'Over-the-Road (OTR)',
    key: 'OTR',
  },
  {
    value: 'Casual',
    key: 'CASUAL',
  },
];

/* @TODO davits make map for sizes instead of conditions */
function UserGeneralForm({
  expanded,
  data: {
    roleCode,
    id,
    userType,
    employmentType,
    operationType,
    dispatcher,
    email,
    status,
  },
  handleChangeShowDetailsTab,
}: {
  expanded: boolean;
  data: UserDetails;
  handleChangeShowDetailsTab: (state: boolean) => void;
}) {
  const {
    control,
    unregister,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [associatedTerminalsMap, setAssociatedTerminalsMap] = useState<{
    [key: string]: AssociatedTerminalType;
  }>({});
  const [associatedTerminalOptions, setAssociatedTerminalOptions] = useState(
    []
  );

  const { getIsTerminalEnabled, getAllTerminals } = useRootStore();
  const [formTitle, setFormTitle] = useState<string>('User Information');
  const isDriver = watch('isDriver');
  const isUser = watch('isUser');
  const sendInvitationValue = watch('sendInvitation');
  const associatedTerminalsList = watch('associatedTerminalsList');
  const terminalId = watch('terminalId');

  const selectedRoleCode = watch('roleCode');
  const userStorage = StorageManager.getUser();
  const [roles, setRoles] = useState<any[]>([]);
  useEffect(() => {
    getRolesList();
  }, []);

  const getRolesList = async () => {
    try {
      const getRes = await getActiveRoles();
      if (getRes?.data) {
        setRoles(getRes?.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (selectedRoleCode === 'AD' && userType === AXELE_USER_TYPES.OWNER_ADMIN)
      setValue('associatedTerminalsList', []);
  }, [selectedRoleCode]);

  useEffect(() => {
    const handleAssociatedTerminals = async () => {
      const result = await getUserAssociatedTerminals(id);
      setAssociatedTerminalsMap(result);
    };
    handleAssociatedTerminals();
  }, [id]);

  useEffect(() => {
    if (Object.keys(associatedTerminalsMap).length) {
      const associatedTerminalsDropdown = (getAllTerminals || []).map(
        (terminal: any) => {
          if (
            associatedTerminalsMap[terminal.id] &&
            !associatedTerminalsMap[terminal.id].isVisible
          ) {
            terminal.disabled = true;
          }
          return terminal;
        }
      );
      setAssociatedTerminalOptions(associatedTerminalsDropdown);
    }
  }, [associatedTerminalsMap, getAllTerminals]);

  useEffect(() => {
    if (Object.keys(associatedTerminalsMap).length) {
      (associatedTerminalsList || []).forEach((terminal: any) => {
        if (
          associatedTerminalsMap[terminal.id] &&
          (!associatedTerminalsMap[terminal.id].isVisible ||
            !getAssociatedTerminalsDisabled() ||
            getIsDisabled())
        ) {
          terminal.disabled = true;
        }
        return terminal;
      });
    }
  }, [associatedTerminalsMap, associatedTerminalsList]);

  // effect for show/hide employment type, operation type, driver details tab
  useEffect(() => {
    if (isDriver) {
      handleChangeShowDetailsTab(true);
      setValue('employmentType', employmentType || 'OWNER_OPERATOR');
      setValue('operationType', operationType || 'LOCAL');
      setValue('dispatcher', dispatcher);
    } else {
      handleChangeShowDetailsTab(false);
      setValue('employmentType', '');
      setValue('operationType', '');
      setValue('dispatcher', null);
    }
  }, [isDriver]);

  useEffect(() => {
    if (typeof sendInvitationValue !== 'boolean' && !id) {
      setValue('sendInvitation', true);
    }
  }, [associatedTerminalsList]);
  useEffect(() => {
    if (isUser & isDriver) {
      setFormTitle('User Information (Web User & Driver)');
    } else if (isUser) {
      setFormTitle('User Information (Web User)');
    } else if (isDriver) {
      setFormTitle('User Information (Driver)');
    }
  }, [isDriver, isUser]);

  useEffect(() => {
    if (!isUser) {
      setValue('roleCode', 'DR');
      unregister(['dispatcher']);
    }
  }, [unregister, isUser]);

  useEffect(() => {
    if (isUser) {
      if (roleCode === 'DR') {
        setValue('roleCode', 'AD');
      } else {
        setValue('roleCode', roleCode);
      }
    }
  }, [isUser]);

  useEffect(() => {
    if (!getIsTerminalEnabled) return;
    if (
      !associatedTerminalsList?.length ||
      associatedTerminalsList?.some((match: TerminalShort) => {
        return match.id === terminalId;
      })
    )
      return;

    const currentTerminal = associatedTerminalsList?.length
      ? associatedTerminalsList.find((item: TerminalShort) => item.isPrimary) ||
        associatedTerminalsList[0]
      : getAllTerminals[0];

    setValue('terminalId', currentTerminal.id);
  }, [
    associatedTerminalsList,
    setValue,
    getIsTerminalEnabled,
    getAllTerminals,
    terminalId,
  ]);

  const noPermissionForInactiveStatus = status === 0;

  const getIsDisabled = (fieldName?: string): boolean | undefined =>
    getByRoleIsFieldDisabled(userStorage.userType, userType, fieldName) ||
    (id ? noPermissionForAddRemoveEditUser() : noAddUserPermission()) ||
    noPermissionForInactiveStatus;

  const getAssociatedTerminalsDisabled = () =>
    userType !== AXELE_USER_TYPES.OWNER_ADMIN &&
    userStorage.roleCode === AXELE_ROLE_TYPE.ADMIN;

  const exceptionalRestriction = useMemo(() => {
    if (
      (userStorage.userType === 'ADMIN' &&
        userType === AXELE_USER_TYPES.OWNER_ADMIN) ||
      (userStorage.userType === AXELE_USER_TYPES.OWNER_ADMIN &&
        userType === AXELE_USER_TYPES.OWNER_ADMIN)
    ) {
      return true;
    }
    return false;
  }, [userStorage.userType, userType]);

  const getEmaiEditPermission = () => {
    if (!id) {
      return false;
    } else {
      return (
        (!!email && !ADMIN_ROLES.includes(userStorage.userType)) ||
        exceptionalRestriction
      );
    }
  };

  return (
    <Box
      sx={{
        pr: 2,
        pl: 2,
      }}
    >
      <Accordion name={formTitle}>
        <TextField
          id={userPage + 'FirstName'}
          disabled={getIsDisabled()}
          control={control}
          name="firstName"
          label="First Name"
          sizes={{ xs: expanded ? 3 : 6 }}
          required
        />
        <TextField
          id={userPage + 'LastName'}
          disabled={getIsDisabled()}
          control={control}
          name="lastName"
          label="Last Name"
          sizes={{ xs: expanded ? 3 : 6 }}
          required
        />
        <FormPhoneField
          disabled={getIsDisabled()}
          control={control}
          name={'phoneData'}
          label="Phone #"
          sizes={{ xs: expanded ? 3 : 12 }}
        />
        <TextField
          id={userPage + 'Email'}
          disabled={getEmaiEditPermission()}
          control={control}
          required
          name="email"
          label="Email"
          sizes={{ xs: expanded ? 3 : 12 }}
        />
        <FormAddress
          control={control}
          name="address"
          sizes={{ xs: 12 }}
          disabled={getIsDisabled()}
          autoFocus={false}
        />
        {/* <Switch
          label={'Web User'}
          name={'isUser'}
          control={control}
          disabled={!isDriver || userType === 'OWNER_ADMIN'}
        />
        <Switch
          label={'Driver'}
          name={'isDriver'}
          control={control}
          disabled={!isUser}
        /> */}
        {isUser && (
          <Select
            id={userPage + 'UserRole'}
            name={'roleCode'}
            label={'User Role'}
            fieldName={'name'}
            options={roles}
            control={control}
            fieldValue={'code'}
            error={errors.role}
            disabled={getIsDisabled()}
            required
          />
        )}
        {isUser && getIsTerminalEnabled && (
          <>
            <MultipleAutocompleteForm
              id={userPage + 'AssociatedTerminals'}
              disabled={!getAssociatedTerminalsDisabled() || getIsDisabled()}
              control={control}
              name="associatedTerminalsList"
              fieldName="companyName"
              options={associatedTerminalOptions}
              label={'Associated Terminals'}
              sizes={{ xs: expanded ? 3 : 12 }}
            />
            {selectedRoleCode === 'AD' && (
              <Typography
                sx={{
                  fontSize: '12px',
                  margin: '-10px 0px 10px 8px',
                }}
              >
                Admin always is associated with all terminals
              </Typography>
            )}
          </>
        )}
        {getIsTerminalEnabled && (
          <Select
            id={userPage + 'PrimaryTerminal'}
            disabled={getIsDisabled('terminalId')}
            name="terminalId"
            label="Primary terminal"
            fieldName="companyName"
            options={
              associatedTerminalsList?.length
                ? associatedTerminalsList
                : getAllTerminals
            }
            control={control}
            fieldValue="id"
            required
          />
        )}
        {isDriver && (
          <>
            <SingleAutocompleteForm
              id={userPage + 'PrimaryDispatcher'}
              disabled={getIsDisabled()}
              control={control}
              name={'dispatcher'}
              fieldName={'fullName'}
              label={'Primary Dispatcher'}
              getOptions={(name, pageNumber) =>
                getPrimaryDispatcherList(name, pageNumber)
              }
            />
            <Select
              id={userPage + 'EmploymentType'}
              disabled={getIsDisabled()}
              name={'employmentType'}
              label={'Employment Type'}
              fieldName={'value'}
              options={employmentTypes}
              control={control}
              fieldValue={'key'}
              error={errors.employmentType}
              sizes={{ xs: expanded ? 3 : 6 }}
            />
            <Select
              id={userPage + 'OperationType'}
              disabled={getIsDisabled()}
              name={'operationType'}
              label={'Operation Type'}
              fieldName={'value'}
              options={operationTypes}
              control={control}
              fieldValue={'key'}
              error={errors.operationType}
              sizes={{ xs: expanded ? 3 : 6 }}
            />
            <FormDatePicker
              control={control}
              name="effectiveStartDate"
              label="Employment Start Date"
              sizes={{ xs: 12 }}
              styleProps={{ width: '100%' }}
              startOfDate
              iso
              allowEmptyDate
            />
            <TextField
              id={userPage + 'InitalGrossPaymentYTD'}
              disabled={getIsDisabled()}
              control={control}
              name="startingYTD"
              label="Inital Gross Payment YTD"
              sizes={{ xs: 12 }}
            />
          </>
        )}
      </Accordion>
      <Accordion name="Emergency Contact">
        <TextField
          id={userPageEmergencyContact + 'FirstName'}
          disabled={getIsDisabled()}
          control={control}
          name="emergencyContact.firstName"
          label="First Name"
          sizes={{ xs: expanded ? 3 : 6 }}
        />
        <TextField
          id={userPageEmergencyContact + 'LastName'}
          disabled={getIsDisabled()}
          control={control}
          name="emergencyContact.lastName"
          label="Last Name"
          sizes={{ xs: expanded ? 3 : 6 }}
        />
        <FormPhoneField
          id={userPageEmergencyContact + 'Phone'}
          disabled={getIsDisabled()}
          control={control}
          name="emergencyContact.phoneData"
          label="Phone #"
          sizes={{ xs: expanded ? 3 : 12 }}
        />
        <TextField
          id={userPageEmergencyContact + 'Email'}
          disabled={getIsDisabled()}
          control={control}
          name="emergencyContact.email"
          label="Email"
          sizes={{ xs: expanded ? 3 : 12 }}
        />
        <FormAddress
          disabled={getIsDisabled()}
          control={control}
          name="emergencyContact.addressData"
          sizes={{ xs: 12 }}
          autoFocus={false}
        />
        {!id && (
          <Checkbox
            id={userPageEmergencyContact + 'SendInvitation'}
            disabled={getIsDisabled()}
            name={'sendInvitation'}
            control={control}
            labelPlacement={'end'}
            label={'Send Invitation'}
          />
        )}
      </Accordion>
    </Box>
  );
}

export default observer(UserGeneralForm);
