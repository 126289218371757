import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';

export default function ClearableSelect({
  options = [],
  onChangeCb,
  value: defaultValue,
}: {
  options: string[];
  onChangeCb: (data: string) => void;
  value: string;
}) {
  const [value, setValue] = useState(defaultValue || '');
  const handleClearClick = () => {
    setValue('');
    onChangeCb('');
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
    onChangeCb(event.target.value);
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <Select
        defaultOpen={!value}
        id="clearable-simple-select"
        value={value}
        onChange={handleChange}
        endAdornment={
          <IconButton
            sx={{ display: value ? 'visible' : 'none', mr: 2 }}
            onClick={handleClearClick}
          >
            <ClearIcon />
          </IconButton>
        }
      >
        {options.map((scoreValue) => {
          return (
            <MenuItem key={scoreValue} value={scoreValue}>
              {scoreValue}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

type PropTypes = {
  options: string[];
  params: GridRenderEditCellParams;
};

export function ClearableSelectEditInputCell({
  options = [],
  params,
}: PropTypes) {
  const { id, field, value } = params;
  const apiRef = useGridApiContext();

  const handleChange = async (newValue: string) => {
    // triggers changing event with new values
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    // triggers edit commit event and calls handleCellEditCommit method from Step3
    apiRef.current.commitCellChange({ id, field });
    // get back table in view mode
    apiRef.current.setCellMode(id, field, 'view');
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <ClearableSelect
        options={options || []}
        onChangeCb={handleChange}
        value={value}
      />
    </Box>
  );
}

export const renderClearableSelectEditInputCell = (
  params: GridRenderEditCellParams,
  options: string[]
) => {
  return <ClearableSelectEditInputCell options={options} params={params} />;
};
