export const headerStyle = {
  height: '7%',
  bgcolor: '#35446B',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const containerStyle = {
  height: '90%',
  bgcolor: '#FFFFFF',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  padding: '24px',
};

export const footerStyle = {
  height: '60px',
  bgcolor: 'primary.contrast',
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  position: 'fixed',
  bottom: '0',
};

export const textStyles = {
  fontFamily: 'Poppins',
  fontStyle: 'Regular',
  fontSize: '10px',
  lineHeight: '15px',
  letterSpacing: '0.4px',
  fontWeight: 500,
};

export const textColor = { color: 'rgba(0, 17, 34, 0.6)' };
export const linkColor = { color: 'primary.main' };

export const textBoxInputPropsStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'Medium',
  fontSize: '20px',
  lineHeight: '30px',
  letterSpacing: '0.15px',
  fontWeight: 500,
  color: 'rgba(255, 255, 255, 0.75)',
};

export const textFieldStyle = {
  width: '100%',
  '& .MuiInputLabel-root': { borderColor: 'transparent', color: 'white' },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: 'transparent',
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: 'transparent',
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& > fieldset': { borderColor: 'transparent', color: 'white' },
  },
};

export const searchContainer = {
  display: 'flex',
  alignItems: 'center',
  width: '90%',
};

export const searchIconStyle = {
  fontSize: 36,
  color: '#669EFF',
};

export const closeButtonStyle = {
  color: '#669EFF',
  borderColor: '#669EFF',
};

export const noResultIconStyle = {
  fontSize: 36,
  color: 'rgba(0, 17, 34, 0.6)',
};

export const noResultTextStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'Regular',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  fontWeight: 400,
  color: 'text.primary',
};

export const noResultContainer = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

export const noResultTextContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const searchHelperTextStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'Regular',
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.4px',
  fontWeight: 400,
  color: 'rgba(0, 17, 34, 0.75)',
  textecoration: 'none',
};

export const searchHelperLinkStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'Medium',
  fontSize: '13px',
  lineHeight: '22px',
  letterSpacing: '0.4px',
  textDecoration: 'underline',
  fontWeight: 500,
  color: 'primary.main',
  pl: '4px',
};

export const searchHelperIconStyle = {
  color: 'primary.main',
  marginRight: '1%',
  width: 22,
  height: 18,
};

export const rightArrowStyle = { color: 'primary.main', width: 12, height: 12 };

export const searchHelperLinkContainer = {
  marginTop: '2%',
  marginLeft: '10%',
  display: 'flex',
  flexDirection: 'column',
};

export const searchHelperLinkContentContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '5%',
};

export const searchHelperContainer = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '5%',
};
