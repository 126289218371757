import { useFormContext } from 'react-hook-form';
import FinancialManageTypeForm from './FinancialManageTypeForm';
import EditableManageTypeForm from './EditableManageTypeForm';

export default function FinanceManageTypeForm() {
  const { getValues } = useFormContext();
  const [id, custom] = getValues(['id', 'custom']);

  if (id && !custom) {
    return <EditableManageTypeForm />;
  }
  return <FinancialManageTypeForm />;
}
