import { TextField } from '@mui/material';
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import React, { useEffect } from 'react';

const CustomCellEdit = (props: GridRenderEditCellParams) => {
  const {
    id,
    field,
    hasFocus,
    type,
    value: valueProp,
    disabled = false,
  } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef() as any;
  const [value, setValue] = React.useState(valueProp);

  React.useLayoutEffect(() => {
    if (hasFocus) {
      ref?.current?.focus();
    }
  }, [hasFocus]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
      debounceMs: 400,
    });
    setValue(newValue);
    apiRef.current.commitCellChange({
      id,
      field,
    });
  };

  return (
    <TextField
      autoFocus
      disabled={disabled}
      ref={ref}
      key={id}
      type={type ? type : 'text'}
      InputProps={{
        disableUnderline: true,
      }}
      value={value?.value}
      onChange={handleValueChange}
    />
  );
};
export default CustomCellEdit;
