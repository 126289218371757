import { Type } from 'class-transformer';
import { QueryParams } from '../../../../models/DTOs/commonMixed/QueryParams';

export interface ScheduledPaymentTypes {
  titleText?: string;
  dialog?: boolean;
  templateSchedulerId?: string;
  useDarkMode?: boolean;
  recurrenceEntityType?: string;
  modeType?: string;
  showDelete?: boolean;
  isOpen?: boolean;
  expenseCategoriesList?: ExpenseCategory[];
  expenseCategoriesDefaultValue?: ExpenseCategory;
  closeModel?: () => void;
  id?: number;
  createdForm?: () => void;
  type?: string;
  editDataParams?: string;
  intialData?: PaymentSchedule;
  configJson?: any;
  sectionName?: string;
}

export class ExpenseCategory {
  id?: string;
  custom?: boolean;
  description?: string;
  itemCode?: string;
  itemName?: string;
  organizationId?: number;
  deduction?: number;
}

export class PaymentSchedule {
  id?: number;
  driverId?: number;
  organizationId?: number;
  description?: string;
  expenseCategoryKey?: string;
  expenseCategoryId?: string;
  rate?: number;
  payTypeId?: string;
  payType?: string;
  unit?: string;
  value?: number;
  templateSchedulerSummaryDTO?: any;
}

export enum EScheduleType {
  Payment = 'Payment',
  Deduction = 'Deduction',
}

export class PaymentScheduleObject {
  id?: number;
  driverId?: number;
  tractorId?: number;
  organizationId?: number;
  description?: string;
  expenseCategoryKey?: string;
  expenseCategoryId?: string;
  value?: number;
  templateSchedulerSummaryDTO: templateSchedulerSummaryDTOType | undefined;
}

export class templateSchedulerSummaryDTOType {
  axeleEntityObj?: recurrenceEntityType;
  currentRecurrencePeriod?: string;
  id?: string;
  organizationId?: number;
  rangeOfRecurrence?: endByRecurrence;
  startOfRecurrence?: string;
  monthlyRecurrence?: monthlyRecurrenceType;
  weeklyRecurrence?: weeklyRecurrenceType;
}

export class weeklyRecurrenceType {
  recurrenceWeekFrequency?: string;
  weekDaysEnum?: [string];
}
export class recurrenceEntityType {
  recurrenceEntityType?: string;
  templateTypeId?: string;
}
export class endByRecurrence {
  endByRecurrence?: {
    endByDate?: string;
  };
}
export class monthlyRecurrenceType {
  dayOfMonth?: string;
}

export class PaymentSchedulePayLoadObject {
  id?: number;
  driverId?: number;
  tractorId?: number;
  organizationId?: number;
  description?: string;
  expenseCategoryKey?: string;
  expenseCategoryId?: string;
  value?: number;
  rangeDate?: string;
  amount?: number;
  templateSchedulerSummaryDTO?: templateSchedulerSummaryDTOType;
  monthlyRecurs?: string;
  expenseType?: ExpenseCategory;
  endByDate?: string;
  main?: string;
  weeklyRecurs?: string;
  weekDays?: [string];
}

export class GetRecurrenceDTO extends QueryParams {
  templateSchedulerIds!: string;
  organizationId!: number;
  templateSchedulerSummaryDTO?: any;
  driverId: number | undefined;
  value: number | undefined;
  expenseCategoryId: string | undefined;
  expenseType: string | undefined;
  expenseCategoryKey: string | undefined;
  amount: number | undefined;
  tractorId: number | undefined;
  main: string | undefined;
  endByDate: string | undefined;
  rangeDate: string | undefined;
  monthlyRecurs: string | undefined;
  weeklyRecurs: string | undefined;
  weekDays: [string] | undefined;
  description: string | undefined;
  id: string | undefined;
  constructor() {
    super();
  }
}

export class GetLoadAlacacertsTypeDTO extends QueryParams {
  organizationId!: number;
}

export class GetRecurrenceRes {
  first!: boolean;
  last!: boolean;
}

export class CreateRecurrenceDTO extends QueryParams {
  @Type(() => axeleEntityObjDTO)
  axeleEntityObj!: axeleEntityObjDTO;
  currentRecurrencePeriod!: string;
  id!: string;
  @Type(() => monthlyRecurrenceDTO)
  monthlyRecurrence!: monthlyRecurrenceDTO;
  organizationId!: number;
  @Type(() => rangeOfRecurrenceDTO)
  rangeOfRecurrence!: rangeOfRecurrenceDTO;
  @Type(() => weeklyRecurrenceDTO)
  weeklyRecurrence!: weeklyRecurrenceDTO;
}

export class weeklyRecurrenceDTO {
  recurrenceWeekFrequency!: number;
  weekDaysEnum!: string[];
}

export class rangeOfRecurrenceDTO {
  startOfRecurrence!: string;
  @Type(() => endByRecurrenceDTO)
  endByRecurrence!: endByRecurrenceDTO;
}

export class endByRecurrenceDTO {
  endAfterNumberOfOccurrence!: number;
  endByDate!: string;
  noEndDate!: boolean;
}

export class axeleEntityObjDTO {
  recurrenceEntityType!: string;
  templateTypeId!: string;
}

export class monthlyRecurrenceDTO {
  dayOfMonth!: number;
}

export class DeleteRecurrenceDTO extends QueryParams {
  organizationId!: number;
}
