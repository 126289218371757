import React, { useEffect } from 'react';
import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import { useFormContext } from 'react-hook-form';
import TextField from '../../../../../common/Ui/TextField/TextField';
import {
  IconCheckbox,
  IconCheckboxComponent,
} from '../../../../../ui-kit/components/IconCheckbox';
import {
  DocumentScannerOutlinedIcon,
  DriverIcon,
} from '../../../../../ui-kit/components/Assets';
import { LineItem, SafetyAlertModel } from '../models';
import CertificationForm from '../../../../../views/reports/documents/forms/CertificationForm';

export const COMMERCIAL_DRIVER_LICENSE = 'COMMERCIAL_DRIVER_LICENSE';

export default function AlertDocumentForm({
  alert,
  documentLineItem,
}: PropTypes) {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (documentLineItem) {
      setValue('documentType', documentLineItem);
      setValue('documentTypeId', documentLineItem.id);
    }
  }, [documentLineItem]);

  useEffect(() => {
    if (alert) {
      setValue('validFrom', alert.validFrom);
      setValue('validTo', alert.validTo);
      setValue('notes', alert.notes);
    }
  }, [alert]);

  return (
    <>
      <SingleAutocompleteForm
        control={control}
        name="documentType"
        label="Document Type"
        options={[]}
        fieldName="itemName"
        disabled
        disableClear
        required
      />
      <TextField control={control} name="notes" label="Notes" />

      <IconCheckbox
        control={control}
        name="permission"
        icon={DriverIcon}
        label="Share With Driver"
        subLabel="Share the document with your driver"
      />
      <IconCheckboxComponent
        icon={DocumentScannerOutlinedIcon}
        label="Latest Document"
        subLabel="Is this the latest document?"
      />
      <CertificationForm />
    </>
  );
}

export type PropTypes = {
  alert: SafetyAlertModel;
  onMarkDoneComplete?: () => void;
  documentLineItem: LineItem;
};
