import {
  MapMarker,
  MapMarkerDropoff,
  MapMarkerHOSStop,
  MapMarkerPickup,
  MapMarkerPickupDropoff,
  MapMarkerRelayStop,
  MapMarkerStop,
  MapMarkerTimeOff,
  MapMarkerTripStop,
  HomeLocationMarker,
  EnrouteMarker,
  StartEndTripMarker,
} from '.';

export const convertToMapMarker = ({
  iconType,
  issue,
  toolTip = false,
  other,
  markerInfo,
}: {
  iconType: string;
  issue?: boolean;
  toolTip?: boolean;
  other?: any;
  markerInfo: any;
}) => {
  const driverName = markerInfo?.driverGroupName;

  const loadId = other?.toolTip?.tooltipComponent?.props?.content?.find(
    ({ key }: { key: string }) => key === 'Load ID'
  )?.value;
  const ETA = other?.toolTip?.tooltipComponent?.props?.content?.find(
    ({ key }: { key: string }) => key === 'ETA'
  )?.value;

  const delay = markerInfo?.tooltip?.delay;
  const date = markerInfo?.tooltip?.displayDate
    ? markerInfo?.tooltip?.displayDate
    : '';
  const completed = other.status === 'COMPLETED';

  switch (iconType) {
    case 'HOME':
      return <HomeLocationMarker title={driverName}></HomeLocationMarker>;

    case 'PICKUP':
      return (
        <MapMarkerPickup
          completed={completed}
          title={date}
          delayed={delay}
          subTitle={delay ? toHoursAndMinutes(delay) : ''}
        ></MapMarkerPickup>
      );
    case 'DROPOFF':
      return (
        <MapMarkerDropoff
          completed={completed}
          title={date}
          delayed={delay}
          subTitle={delay ? toHoursAndMinutes(delay) : ''}
        ></MapMarkerDropoff>
      );
    case 'PICKUPANDDROPOFF':
      return (
        <MapMarkerPickupDropoff
          completed={completed}
          title={date}
          delayed={delay}
          subTitle={delay ? toHoursAndMinutes(delay) : ''}
        ></MapMarkerPickupDropoff>
      );
    case 'TRIP':
      return (
        <MapMarkerTripStop
          completed={completed}
          title={'Trip Stop'}
          delayed={delay}
          subTitle={delay ? `${date} - ${toHoursAndMinutes(delay)}` : date}
        ></MapMarkerTripStop>
      );
    case 'RELAY':
      return (
        <MapMarkerRelayStop
          completed={completed}
          title={date}
          delayed={delay}
          subTitle={delay ? toHoursAndMinutes(delay) : ''}
        ></MapMarkerRelayStop>
      );
    case 'TIMEOFF':
      return (
        <MapMarkerTimeOff
          completed={completed}
          title={driverName}
          subTitle={`Time Off - ${date}`}
          variant={other.status == 'ACTIVE' ? 'filled' : 'outlined'}
        ></MapMarkerTimeOff>
      );
    case 'HOS':
      return <MapMarkerHOSStop completed={completed}></MapMarkerHOSStop>;
    case 'SOLODRIVER':
    case 'DRIVER':
    case 'TEAMDRIVER':
      if (markerInfo?.isHOSOutdated || markerInfo?.isLocationOutdated) {
        return (
          <MapMarker
            size="small"
            variant={'filled'}
            title={driverName}
            delayed={true}
            subTitle={'Loc/HOS Outdated'}
          ></MapMarker>
        );
      } else if (other.status === 'ENROUTE') {
        <EnrouteMarker
          completed={completed}
          title={driverName}
        ></EnrouteMarker>;
      } else {
        return (
          <MapMarker
            size="small"
            variant={'filled'}
            title={driverName}
          ></MapMarker>
        );
      }
    case 'COMPLETED':
      return (
        <MapMarkerStop subTitle={'Completed'} completed={true}></MapMarkerStop>
      );
    case 'TRIP_START':
      return (
        <StartEndTripMarker
          title={'Start of Trip'}
          completed={completed}
          subTitle={date}
        ></StartEndTripMarker>
      );
    case 'TRIP_END':
      return (
        <StartEndTripMarker
          title={'End of Trip'}
          completed={completed}
          subTitle={date}
        ></StartEndTripMarker>
      );
    default:
      return <></>;
  }
};

export const toHoursAndMinutes = (totalMinutes: number) => {
  if (!toHoursAndMinutes) return false;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const val = `+${hours ? hours + 'h ' : ''}${
    minutes ? minutes + 'm' : ''
  } delay`;
  return val;
};
