import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  createTheme,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_TABLET,
} from '../../../../../common/hooks/useThemeResponsive';

export const StyledButton = styled(
  (props: ButtonProps & { isSelected?: boolean; disabled?: boolean }) => (
    <Button {...props} />
  )
)(({ theme, isSelected, disabled }: any) => ({
  borderRadius: '6px',
  padding: '5px',
  ...(isSelected
    ? {
        border: `1px solid ${theme.palette.primary.outlinedRestingBorder}`,
        backgroundColor: theme.palette.primary.outlinedHoverBackground,
      }
    : {}),
  ...(disabled && {
    color: theme.palette.action.disabled,
  }),
}));

export const StyledNavigationButton = styled(
  (props: ButtonProps & { isSelected?: boolean }) => <Button {...props} />
)(({ theme }: any) => ({
  borderRadius: '6px',
  padding: '6px',
  border: `1px solid ${theme.palette.primary.outlinedRestingBorder}`,
}));

export const StyledButtonGroup = styled((props: ButtonGroupProps) => (
  <ButtonGroup variant="outlined" size="small" {...props} />
))(({ theme }) => ({
  borderRadius: '6px',
  paddingRight: '6px',
}));

export const getThemeDispatchFilters = ({
  currTheme,
  inputWithSearchIcon = false,
}: {
  currTheme: Theme;
  inputWithSearchIcon?: boolean;
}) => {
  const height = '28px';
  const MuiInputLabelStyles: SxProps = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    color: currTheme.palette.primary.main,
    '&[data-shrink=true]+ .MuiInputBase-root > .MuiInput-input::-webkit-input-placeholder':
      {
        fontSize: '14px',
        fontWeight: 400,
        color: currTheme.palette.text.secondary,
        opacity: '0.6!important',
      },
  };
  const MuiInputFixedLabelStyles: SxProps = {
    position: 'static!important',
    transform: 'none!important',
    pointerEvents: 'none',
    '&[data-shrink]+ .MuiInputBase-root': {
      marginTop: 0,
      '&> .MuiInput-input::-webkit-input-placeholder': {
        fontSize: '14px',
        fontWeight: 400,
        color: currTheme.palette.text.secondary,
        opacity: '0.6!important',
      },
    },
  };
  const MuiInputLabelShrinkStyles: SxProps = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '12px',
    color: currTheme.palette.primary.main,
  };

  const BorderStyles = {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: `${currTheme.palette.primary.main} !important`,
    },
  };
  const theme = createTheme(currTheme, {
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: ({ ownerState: { fixedLabel } }) => ({
            ...MuiInputLabelStyles,
            ...(fixedLabel ? MuiInputFixedLabelStyles : {}),
            '&.MuiInputLabel-shrink': {
              top: '3px !important',
              ...MuiInputLabelShrinkStyles,
            },
          }),
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: height,
            ...BorderStyles,
          },
          icon: {
            color: currTheme.palette.primary.main,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiInputLabel-root': {
              top: '-3px',
              ...MuiInputLabelStyles,
            },
            '.MuiAutocomplete-tag': {
              height: '20px',
              margin: '2px',
              maxWidth: inputWithSearchIcon
                ? 'calc(100% - 24px)'
                : 'calc(100% - 4px)',
            },
            '.MuiAutocomplete-clearIndicator': {
              marginRight: inputWithSearchIcon ? '-8px' : '-4px',
            },
            '.MuiChip-label': {
              fontSize: '10px',
            },
            ...BorderStyles,
          },
          inputRoot: {
            height: height,
            color: currTheme.palette.primary.main,
          },
          input: {
            lineHeight: height,
            width: inputWithSearchIcon ? '100%' : '100% !important',
          },
          hasClearIcon: {
            '.MuiAutocomplete-inputRoot': {
              display: 'flex !important',
              flexWrap: 'nowrap',
              paddingTop: '6px !important',
              paddingBottom: '2px !important',
            },
          },
          endAdornment: {
            right: '0 !important',
          },
          paper: {
            borderRadius: '0px 0px 5px 5px',
            boxShadow:
              '0px 3px 14px 2px #0000001F, 0px 8px 10px 1px #00000024, 0px 5px 5px -3px #00000033',
            '.MuiChip-root': {
              fontSize: '10px',
            },
          },
          listbox: {
            '> .MuiBox-root': {
              padding: '0 14px 8px 14px',
            },
            li: {
              paddingLeft: '5px!important',
              paddingTop: '2px!important',
              paddingBottom: '2px!important',
              '.MuiTypography-root': {
                fontSize: '14px',
                fontWeight: 400,
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderColor: `${currTheme.palette.primary.main} !important`,
            '.MuiOutlinedInput-root': {
              height: height,
              ...MuiInputLabelStyles,
              '.MuiTypography-root': {
                ...MuiInputLabelStyles,
              },
            },
            '.MuiInput-input': {
              ...MuiInputLabelStyles,
            },
            ...BorderStyles,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-root': {
              height: height,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '.MuiSvgIcon-root': {
              color: currTheme.palette.primary.main,
            },
          },
        },
      },
    },
  });
  return theme;
};

export const getStyledCustomFilter = ({
  theme,
  showQuickFilter,
}: {
  theme: Theme;
  showQuickFilter?: boolean;
}): { [key: string]: SxProps } => {
  return {
    root: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      // alignItems: 'center',
      width: '100%',
      flex: '1 auto',
    },
    LeftCustomizeFilters: {
      alignItems: 'center',
      flexDirection: 'row',
      width: 'unset',
      marginTop: 'auto',
      marginBottom: 'auto',
      // flexGrow: 1,
    },
    RightCustomizeFilters: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
      display: 'flex',
      marginTop: '6px',
      // marginTop: 'auto',
      // marginBottom: 'auto',
      [theme.breakpoints.between(BREAKPOINT_LAPTOP, BREAKPOINT_DESKTOP)]: {
        width: showQuickFilter ? 'auto' : 'min-content',
      },
      [theme.breakpoints.up(BREAKPOINT_LAPTOP)]: {
        // marginTop: 0,
        // height: '100%',
      },
    },
    FilterGridRow: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      padding: '6px 0',
    },
    FilterGridRowContent: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      '> .MuiBox-root': {
        p: '0',
      },
      '.MuiFormControl-root': {
        minHeight: 'unset',
        height: 'unset',
      },
      flexWrap: 'wrap',
    },
    AllFilters: {
      [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
        whiteSpace: 'nowrap',
        paddingTop: 0,
      },
    },
  };
};
