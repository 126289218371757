import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { loadboardService } from '../../../../api';
import { ComposedError } from '../../../../axios/axiosInstance';
import { LoginAccountIntegration } from '../../../../models/DTOs/Loadboard/Requests';
import {
  IAllCustomers,
  ITerminals,
  IcurrentCustomerIntegrationFormField,
} from '../../../../types/LoadboardTypes';
import IntegrationFormContent from '../../../../ui-kit/components/IntegrationFormContent';
import SideDrawerDAT from '../SideDrawerDAT';
import { Errors } from '../SideDrawerDAT/dispalyTestFields';

export default function SideDrawerFields({
  currentCustomerIntegrationFormField,
  currentCustomerData,
  allTerminals,
  refreshOnCard,
  isEditTab,
  cardData,
  isDAT,
  toggleDrawer,
}: ISelectedCustomer) {
  const [sidePanelFields, setSidePanelFields] = useState();
  const [formErr, setFormErr] = useState({
    integrationType: 'LOADBOARD',
  });

  useEffect(() => {
    if (!isDAT) {
      const sidePanelForm: {
        desc: string | undefined | null;
        fields: Array<object> | undefined | null;
        label: string | undefined | null;
        userGuide: string | undefined | null;
      } = {
        desc: '',
        fields: [],
        label: '',
        userGuide: '',
      };
      if (currentCustomerIntegrationFormField?.length > 0) {
        const formDetails =
          currentCustomerIntegrationFormField &&
          currentCustomerIntegrationFormField[0];
        sidePanelForm['desc'] = formDetails['description'];
        sidePanelForm['fields'] = formDetails['fields'];
        sidePanelForm['label'] = formDetails['displayStepName'];
        sidePanelForm['userGuide'] = formDetails['readmeURL'];
        setSidePanelFields(sidePanelForm);
      }
    } else {
      setSidePanelFields(currentCustomerIntegrationFormField);
    }
  }, [currentCustomerIntegrationFormField, isDAT]);

  const validationForm = (
    payload: any,
    isReauthenticate: boolean
  ): boolean | undefined => {
    if (payload?.data) {
      const blankFormErr: any = {};
      const checkOptionFields = {
        MC: false,
        DOT: false,
      };
      payload?.data.map((item: { name: string; value: string }) => {
        if (payload['providerId'] === 3) {
          if (!item['value']) {
            if (item['name'] === 'MC') {
              checkOptionFields['MC'] = true;
            } else if (item['name'] === 'DOT') {
              checkOptionFields['DOT'] = true;
            } else {
              blankFormErr[item['name']] = [Errors[item['name']]];
            }
          }
        } else if (!item['value']) {
          blankFormErr[item['name']] = [Errors[item['name']]];
        }
      });
      if (
        payload?.terminalIds.length === 0 &&
        payload?.data?.length < currentCustomerIntegrationFormField[0]?.fields
      ) {
        blankFormErr['TERMINAL_IDS'] = [Errors['TERMINAL_IDS']];
      }
      if (checkOptionFields['MC'] && checkOptionFields['DOT']) {
        blankFormErr['MC'] = [Errors['MC']];
        blankFormErr['DOT'] = [Errors['DOT']];
      }
      setFormErr({
        ...{ integrationType: 'LOADBOARD' },
        ...blankFormErr,
      });
      return Object.keys(blankFormErr).length === 0;
    }
  };

  const oAuthEditCard = async (payload: any) => {
    const request = new LoginAccountIntegration(payload);
    try {
      await loadboardService.oAuthEdit(request);
      toggleDrawer(false);
      refreshOnCard();
    } catch (error: any) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        const statusCode = composedError.getStatusCode();
        if (statusCode === 404) {
          const validationError =
            error['error']['response']['data']['validationErrors'];
          setFormErr({
            ...{ integrationType: 'LOADBOARD' },
            ...validationError,
          });
        }
      }
    }
  };

  const oAuthUpdate = (payload: any, isReauthenticate: boolean): boolean => {
    if (payload['providerId'] === 4 && !isReauthenticate) {
      oAuthEditCard(payload);
      return true;
    }
    if (payload['providerId'] === 4 && isReauthenticate) {
      sessionStorage.setItem('123Loadboard', JSON.stringify(payload));
      sessionStorage.setItem(
        '123LoadboardIsReauthenticate',
        JSON.stringify(isReauthenticate)
      );
      authenticateOAuth();
      return true;
    }
    return false;
  };

  const validateFormCreds = async (payload: any) => {
    payload['integrationType'] = 'LOADBOARD';
    if (payload?.data) {
      const isValidate: boolean | undefined = validationForm(payload, false);
      if (isValidate) {
        if (payload['providerId'] === 4) {
          sessionStorage.setItem('123Loadboard', JSON.stringify(payload));
          authenticateOAuth();
          return;
        }
        if (payload['providerId'] === 3) {
          payload?.data.map((data: any, index: number) => {
            if (!data['value']) {
              payload?.data.splice(index, 1);
            }
          });
        }
        const request = new LoginAccountIntegration(payload);
        try {
          await loadboardService.createLoginCard(request);
          refreshOnCard();
          toggleDrawer(false);
        } catch (error: any) {
          if (
            error &&
            error['error'] &&
            error['error']['response'] &&
            error['error']['response']['status'] === 406
          ) {
            const validationError =
              error['error']['response']['data']['validationErrors'];
            setFormErr({
              ...{ integrationType: 'LOADBOARD' },
              ...validationError,
            });
          }
        }
      }
    }
  };

  const updateAccountCard = async (payload: any, isReauthenticate: boolean) => {
    payload['integrationType'] = 'LOADBOARD';
    const isValidate: boolean | undefined = validationForm(
      payload,
      isReauthenticate
    );
    const isOAuthUpdate = oAuthUpdate(payload, isReauthenticate);
    if (isOAuthUpdate) {
      return;
    }
    if (isValidate) {
      const request = new LoginAccountIntegration(payload);
      try {
        await loadboardService.editDATCredential(request);
        toggleDrawer(false);
        refreshOnCard();
      } catch (error: any) {
        if (
          error &&
          error['error'] &&
          error['error']['response'] &&
          error['error']['response']['status'] === 404
        ) {
          const validationError =
            error['error']['response']['data']['validationErrors'];
          setFormErr({
            ...{ integrationType: 'LOADBOARD' },
            ...validationError,
          });
        }
      }
    }
  };

  const authenticateOAuth = () => {
    const url = `${window.env['123LOADBOARD_BASE_URL']}/authorize?response_type=code&client_id=${window.env.ONE_TWO_THREE_LOADBOARD_CLIENT_ID}&redirect_uri=${window.location.origin}/${process.env['123LOADBOARD_REDIRECT_URI']}`;
    window.location = url;
  };

  return (
    <Box>
      {isDAT ? (
        <SideDrawerDAT
          currentCustomerIntegrationFormField={
            currentCustomerIntegrationFormField
          }
          refreshOnCard={refreshOnCard}
          isEditTab={isEditTab}
          cardData={cardData}
          isDAT={false}
          toggleDrawer={toggleDrawer}
          allTerminals={allTerminals}
          currentCustomerData={currentCustomerData}
          sideDrawerDisplayData={sidePanelFields}
        />
      ) : (
        sidePanelFields && (
          <IntegrationFormContent
            getTerminalOptions={allTerminals}
            currentCustomerData={currentCustomerData}
            sideDrawerDisplayData={sidePanelFields}
            dataToUpdate={cardData}
            validateFormCreds={validateFormCreds}
            updateAccountCard={updateAccountCard}
            authenticateOAuth={authenticateOAuth}
            formErr={formErr}
            integrationType={'LOADBOARD'}
          />
        )
      )}
    </Box>
  );
}

export interface ISelectedCustomer {
  currentCustomerIntegrationFormField: Array<IcurrentCustomerIntegrationFormField>;
  currentCustomerData: Array<IAllCustomers>;
  allTerminals: Array<ITerminals> | null;
  isEditTab: boolean;
  cardData: any;
  refreshOnCard: () => void;
  toggleDrawer: (newOpen: boolean) => void;
  isDAT: boolean;
}
