import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import { useLoadPermission } from '../../../../hooks/useLoadPermission';
import TextField from '../../../../Ui/TextField/TextField';
import { loadPanelOverviewTab } from '../../../../PendoClassIDs/constants';
import { SingleAutocompleteForm } from '../../../../../common/Ui/SingleAutocomplete';
import { defaultEquipmentTypeOptions } from '../../../../../subPages/settings/Preferences/constants';
import { t } from 'i18next';

export default function LoadDetailsForm({ loadType }: { loadType?: string }) {
  const { control, getValues } = useFormContext();
  const equipmentTypes = getValues('equipmentTypes');
  const { hasLoadEditPermission, hasIdEditPermission } = useLoadPermission();
  return (
    <Grid container spacing={2}>
      <TextField
        id={loadPanelOverviewTab + 'LoadID'}
        control={control}
        name="seqNumber"
        label={loadType != 'MANIFEST' ? t('loadID') : t('manifestID')}
        required
        sizes={{ xs: 6 }}
        disabled={!hasIdEditPermission}
      />
      {loadType != 'MANIFEST' ? (
        <>
          <TextField
            id={loadPanelOverviewTab + 'Ref'}
            control={control}
            name="referenceId"
            label={t('refNumber')}
            sizes={{ xs: 6 }}
            disabled={!hasLoadEditPermission}
          />
        </>
      ) : (
        equipmentTypes && (
          <SingleAutocompleteForm
            control={control}
            fieldName="value"
            fieldValue="key"
            name="equipmentTypes"
            label={t('defaultEquipment')}
            options={defaultEquipmentTypeOptions}
            required
            disableClear
            sizes={{ xs: 6 }}
          />
        )
      )}
    </Grid>
  );
}
