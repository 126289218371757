import React from 'react';
import AxeleDialog from '../../../ui-kit/components/AxeleDialog';
import Stepper, { complexEntitySteps, steps } from './components/Stepper';
import ImportLandingPage from './components/Landing';
import { useImport } from '../../../contexts/ImportContext';
import ImportActions from './Import';

const Import = ({
  importableEntityName,
  hideImportPage,
}: {
  importableEntityName?: string;
  hideImportPage?: boolean;
}) => {
  const {
    showImportModal,
    handleImportModalClose,
    sessionId,
    currentEntity: {
      routeName,
      dictionary,
      name: entityName,
      hasAdditionalStep,
    },
    activeStep,
    handleFinishImport,
  } = useImport();

  const getIsLastStep = () => {
    let currentSteps = steps;
    if (hasAdditionalStep) {
      currentSteps = complexEntitySteps;
    }
    return activeStep === currentSteps.length - 1;
  };

  const handleModalClose = async () => {
    handleImportModalClose();
    if (getIsLastStep()) {
      handleFinishImport();
      return;
    }
    if (sessionId) {
      await ImportActions.cancelImport(sessionId, routeName, dictionary);
    }
  };

  return (
    <>
      {showImportModal && (
        <AxeleDialog
          titleText={`Bulk ${importableEntityName || entityName} Import`}
          open={showImportModal}
          handleClose={handleModalClose}
          renderComponent={<Stepper />}
          dialogStyles={{
            '.MuiPaper-root': {
              transform: `translate(50px, 30px)`,
              height: '90%',
            },
          }}
        />
      )}
      {!hideImportPage && <ImportLandingPage />}
    </>
  );
};

export default Import;
