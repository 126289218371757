/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DoSearchCustomerList,
  DoSearchCustomerNameRequest,
  DoSearchDispatcherList,
  DoSearchTractorList,
  DoSearchTrailerList,
  DoSearchTrailerNameRequest,
  DoSearchTractorNameRequest,
  DoSearchDispatcherNameRequest,
  DoSearchDriverList,
  DoSearchDriverNameRequest,
  PaginatedBasicReportsList,
  PaginatedBasicReportsRequest,
  ExportExcelQuery,
  ExportExcelQueryExcel,
  CreateAdvanceLoadResult,
  GetQuaInfoForReportsRequest,
  GetDriverTimeOffRequest,
} from '../../models';
import { docType } from '../../utils/Doc';
import { ServiceError } from './helperTypes';
import { Service } from './Service';

export abstract class IBasicReportsService extends Service {
  abstract getPaginatedBasicReportsList(
    requestData: PaginatedBasicReportsRequest
  ): Promise<PaginatedBasicReportsList>;

  abstract doSearchDriverName(
    requestData: DoSearchDriverNameRequest
  ): Promise<DoSearchDriverList | null | ServiceError>;

  abstract doSearchCustomerName(
    requestData: DoSearchCustomerNameRequest
  ): Promise<DoSearchCustomerList | null | ServiceError>;

  abstract doSearchTractorName(
    requestData: DoSearchTractorNameRequest
  ): Promise<DoSearchTractorList | null | ServiceError>;

  abstract doSearchTrailerName(
    requestData: DoSearchTrailerNameRequest
  ): Promise<DoSearchTrailerList | null | ServiceError>;

  abstract doSearchDispatcherName(
    requestData: DoSearchDispatcherNameRequest
  ): Promise<DoSearchDispatcherList | null | ServiceError>;

  abstract getExportExcel(
    requestData: ExportExcelQuery
  ): Promise<ExportExcelQueryExcel | null | ServiceError>;

  abstract createAdvanceLoadReport(
    requestData: any
  ): Promise<CreateAdvanceLoadResult>;

  abstract createIFTAReport(
    requestData: GetQuaInfoForReportsRequest
  ): Promise<docType | null | ServiceError>;
  abstract createDriverTimeOffReport(
    requestData: GetDriverTimeOffRequest
  ): Promise<docType | null | ServiceError>;
}
