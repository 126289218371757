import { DriverGroupSearchListParams } from '../../models/DTOs/Driver/Requests';
import { IDriverService } from '../../api/interfaces/IDriverService';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { DRIVER_INFO_ENDPOINTS } from './requestConstants';

export class DriverService extends IDriverService {
  async getDriverList(
    nameFilter: string | undefined,
    pageNumber: number,
    terminalIds?: string[],
    activeForOperation?: boolean,
    typeFilter?: string
  ) {
    const requestData = new DriverGroupSearchListParams(nameFilter);
    requestData.pageNumber = pageNumber;
    requestData.activeForOperation = activeForOperation;
    requestData.typeFilter = typeFilter;
    if (terminalIds) requestData.terminalIds = terminalIds;
    try {
      return await httpClient.get(
        DRIVER_INFO_ENDPOINTS.DRIVER_LIST,
        requestData,
        DriverGroupSearchResponse,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}

class DriverGroupSearchResponse {}
