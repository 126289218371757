/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import AuthContext from '../../globalContexts/AuthContext';
import {
  AXELE_PERMISSION_TYPE,
  AXELE_ROLE_TYPE,
  AXELE_WEB_FEATURE_TYPE,
} from '../Permission';
import { getHasPermission } from '../Permission/utils/helperUtils';

export interface PermissionRouteProps extends React.PropsWithChildren<{}> {
  redirectionPath?: string;
  permission?: AXELE_PERMISSION_TYPE[];
  permit?: AXELE_WEB_FEATURE_TYPE[];
  roleCodes?: AXELE_ROLE_TYPE[];
}

export const PermissionRoute = ({
  children,
  redirectionPath = '/',
  permission = undefined,
  permit = undefined,
  roleCodes = undefined,
}: PermissionRouteProps): React.ReactNode => {
  const hasPermission: boolean = getHasPermission({
    contains: permission,
    containsPermit: permit,
    roleCodes: roleCodes,
  });

  const { user } = useContext(AuthContext);
  return user && hasPermission ? children : <Navigate to={redirectionPath} />;
};
