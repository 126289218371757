import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';

export enum ETripType {
  Full = 'Full',
} // TODO Anh

export enum ETripEquipmentType {
  Van = 'Van',
}

export enum ETripGroupMode {
  Solo = 'SOLO',
}

export enum ETripSettlementStatus {
  None = 'NONE',
  Pending = 'PENDING',
  InReview = 'IN_REVIEW',
  reviewed = 'REVIEWED',
  Paid = 'CLOSED',
}
export enum ETripEmployeeType {
  OwnerOperator = 'OWNER_OPERATOR',
}

export enum ETripGroupOperationType {
  OTR = 'OTR',
}

export enum ETrailerModel {
  CA125 = 'CA125',
}

export enum ETractorModel {
  CA125 = 'CA125',
}

export enum ETripOperationType {
  InvoiceDispatch = 'INVOICE_DISPATCH',
}

export type TTripStatusOption = {
  label: string;
  value: ELoadStatus;
};

export type TTripSettlementStatusOption = {
  label: string;
  value: ETripSettlementStatus;
};

export type IEquipmentOption = {
  id: number;
  name: string;
};
