import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { cloneDeep, isEmpty } from 'lodash';
import { useState } from 'react';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import {
  emailValidation,
  stringContainsNumber,
} from '../../../../../views/settings/terminals/constants/constants';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../../Permission';
import { useLoadPermission } from '../../../../hooks/useLoadPermission';
import {
  addTrackerDefaultData,
  checkAll,
  dialoagButtonText,
  performValidation,
  requiredCheck,
  titleText,
} from '../constants/constant';
import { CUDData } from '../constants/helper';
import {
  AddData,
  AddTrackerTypes,
  AlertTypesSingleData,
  ErrorTypes,
} from '../constants/interface';
import { styles } from '../styles/styles';
import { TrackerContent } from './TrackerContent';

export const AddTracker = ({
  alertTypes = [],
  contactList = [],
  loadId = '',
  trackingGet,
}: AddTrackerTypes) => {
  const { hasLoadEditPermission } = useLoadPermission();
  const [open, setOpen] = useState<boolean>(false);
  const onCloseDialog = () => setOpen(false);
  const [data, setData] = useState<AddData>(cloneDeep(addTrackerDefaultData));
  const [error, setError] = useState<ErrorTypes>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onAddSubmit = async () => {
    setIsSubmitting(true);
    const tempData: AddData = { ...data };
    const isError = await performValidation(tempData);
    if (isEmpty(isError)) {
      await CUDData(tempData, loadId, 'create');
      setOpen(false);
      setData(cloneDeep(addTrackerDefaultData));
      setError({});
      await trackingGet();
      setIsSubmitting(false);
    } else {
      setError(isError);
      setIsSubmitting(false);
    }
  };

  const onChangeData = async (id: string, event: any) => {
    const tempData: AddData = { ...data, [id]: event.target.value };
    setData(tempData);
    const tempError = { ...error };
    if (id == 'email') {
      tempError.email = await requiredCheck(
        event.target.value ? event.target.value : ''
      );
      if (event.target.value != '' && !emailValidation.test(event.target.value))
        tempError.email = 'Email is not valid.';
    }
    if (id == 'phone') {
      tempError.phone = await requiredCheck(
        event.target.value ? event.target.value : ''
      );
      if (
        (!stringContainsNumber(event.target.value) &&
          event.target.value != '') ||
        (event.target.value.length != 10 && event.target.value != '')
      )
        tempError.phone = 'Must contain 10 digits number.';
    }
    setError(tempError);
  };

  const onChangeSelectAll = async (id: string, event: any) => {
    const temp: AddData = {
      ...data,
      [id]: event.target.checked,
      selectedList: event.target.checked == true ? checkAll(alertTypes) : [],
    };
    setData(temp);
  };

  const onChangeFreeSolo = (event: any, newValue: any, select: boolean) => {
    if (select) {
      const tempData: AddData = {
        ...data,
        name: newValue.fullName,
        email: newValue.email ? newValue.email : '',
        phone: newValue.phone ? newValue.phone : '',
      };
      setData(tempData);
    } else {
      const tempData: AddData = { ...data, name: newValue };
      setData(tempData);
    }
  };

  const onChangeList = (
    changedList: AlertTypesSingleData[],
    selectedList: string[]
  ) => {
    const tempData: AddData = {
      ...data,
      selectedList: [...selectedList],
      selectAll: selectedList.length == alertTypes.length ? true : false,
    };
    setData(tempData);
  };

  const onAddClicked = () => {
    setData(addTrackerDefaultData);
    setError({});
    setOpen(true);
  };

  const renderButton = () => (
    <Stack direction="row" alignItems="center" width={'70%'} spacing={2}>
      <Typography
        color={'primary.main'}
        align="left"
        fontSize={16}
        fontWeight={600}
      >
        {dialoagButtonText}
      </Typography>
      <Permission contains={[AXELE_PERMISSION_TYPE.LOAD_EDIT]}>
        <Box sx={styles.addButton}>
          <IconButton
            disabled={!hasLoadEditPermission}
            color="primary"
            onClick={onAddClicked}
          >
            <IconsComponent iconName="Add" source="MUIcons" />
          </IconButton>
        </Box>
      </Permission>
    </Stack>
  );

  const renderDialog = () => (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: styles.dialog,
      }}
    >
      <TrackerContent
        titleText={titleText}
        onCloseDialog={onCloseDialog}
        alertTypes={alertTypes}
        onAddSubmit={onAddSubmit}
        onChangeData={onChangeData}
        data={data}
        error={error}
        isSubmitting={isSubmitting}
        onChangeList={onChangeList}
        contactList={contactList}
        onChangeFreeSolo={onChangeFreeSolo}
        onChangeSelectAll={onChangeSelectAll}
      />
    </Dialog>
  );
  return (
    <>
      {renderButton()}
      {renderDialog()}
    </>
  );
};
