import { styled } from '@mui/styles';

export const StyledContentContainer = styled('div')(() => ({
  position: 'relative',
  // zIndex: 0,
  height: '100%',
  // paddingTop: 24,
}));

export const StyledTableView = styled('div')(() => ({
  height: '100%',
  width: '100%',
  transition: 'all 0.3s ease',
}));

export const getStyledTable = ({ theme }) => {
  return {
    width: '100%',
    // height: '100%',
    ml: 0,
    p: 0,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'hidden',
  };
};

export const getStyledCustomFooterComponent = ({ theme }) => {
  return {
    padding: '12px 18px',
    boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.25)',
    background: theme.palette.uncategorized.panel,
    height: '48px',
  };
};

export const getStyledCustomFooterComponentTotal = ({ theme }) => {
  return {
    ...theme.typography.helperText,
    color: theme.palette.text.primary,
    float: 'left',
  };
};

export const getStyledCustomFooterComponentTotalAmount = ({ theme }) => {
  return {
    ...theme.typography.helperText,
    float: 'right',
    paddingRight: '100px',
    color: theme.palette.text.primary,
  };
};

export const getStyledHyperLink = ({ theme }) => {
  return {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  };
};
