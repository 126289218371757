import { ServiceError } from './helperTypes';
import { Service } from './Service';
import {
  CreateEfsAccountRequest,
  CreateExpensesRequest,
  DeleteEfsAccountRequest,
  FinishImportRequest,
  GetEfsAccountsRequest,
  GetFuelCardProvidersRequest,
  PullDataRequest,
  UpdateEfsAccountRequest,
} from '@/models/DTOs/FuelCards/Requests';
import {
  FuelCardAccount,
  Provider,
  PullDataResponse,
} from '@/models/DTOs/FuelCards/FuelCards';

export abstract class IFuelCardService extends Service {
  abstract getFuelCardProviders(
    requestData: GetFuelCardProvidersRequest
  ): Promise<Provider[] | ServiceError>;

  abstract createEfsAccount(
    requestData: CreateEfsAccountRequest
  ): Promise<FuelCardAccount | ServiceError>;

  abstract updateEfsAccount(
    requestData: UpdateEfsAccountRequest
  ): Promise<FuelCardAccount | ServiceError>;

  abstract getEfsAccounts(
    requestData: GetEfsAccountsRequest
  ): Promise<any | ServiceError>;

  abstract pullData(
    requestData: PullDataRequest
  ): Promise<PullDataResponse | ServiceError>;

  abstract createExpenses(
    requestData: CreateExpensesRequest,
    params: { expenseIdsToCreate: string[] }
  ): Promise<void | ServiceError>;

  abstract deleteEfsAccount(
    requestData: DeleteEfsAccountRequest
  ): Promise<void | ServiceError>;

  abstract finishImport(
    requestData: FinishImportRequest
  ): Promise<void | ServiceError>;
}
