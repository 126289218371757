import SecureLS from 'secure-ls';
import ls from 'localstorage-slim';

const config = {
  ...ls.config,
  encrypt: true,
  decrypt: true,
  secret: process.env.SECRET_KEY,
};

export default class EncryptedStorageManager {
  static getItem(key: string): any {
    const value = ls.get(key, { decrypt: true });
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  static setItem(key: string, value: any) {
    ls.set(key, typeof value === 'object' ? JSON.stringify(value) : value, {
      encrypt: true,
    });
  }

  static removeItem(key: string) {
    ls.remove(key);
  }

  static removeItems(keys: string[]) {
    keys.forEach((key) => {
      ls.remove(key);
    });
  }

  static getUser() {
    return this.getItem('user');
  }

  static setWithExpiry(key: string, value: any, ttl: number) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    ls.set(key, JSON.stringify(item), { encrypt: true });
  }

  static getWithExpiry(key: string) {
    const itemStr = ls.get(key);

    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      ls.remove(key);
      return null;
    }
    return item.value;
  }
}
