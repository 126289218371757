import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FinanceOverviewAccordionSection from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewAccordionSection';
import { EFinanceOverview } from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewConstant';
import FormContext, {
  SelectEntityType,
  getFinanceOverviewAccordionDataWithID,
  getFinanceOverviewAccordionDataWithOutID,
  getFinanceOverviewStatementInfo,
} from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewFormContext';
import {
  ListDataType,
  requestStatuesStatementInfo,
} from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewModel';
import { financeStyle } from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverviewStyle';
import { Trip } from '../../../../models';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { CarrierPaymentNoData } from './CarrierPaymentNoData';

export const CarrierFinanceOverview = ({ trip }: { trip: Trip }) => {
  const {
    loadId,
    financeOverviewData,
    selectRecordList,
    setSelectRecordData,
    setSelectRecordList,
    panelType,
    setSelectEntityType,
    setRenderSection,
    renderSection,
    setStatementInfo,
  } = useContext(FormContext);

  const { reset } = useFormContext();
  const { trackPromise } = useDetailsPanelStore;
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const init = async () => {
      if (!pageIsLoading) return;
      if (!financeOverviewData?.tripDetails?.[0]?.paymentDetails) return;
      const Details = financeOverviewData.tripDetails[0]?.paymentDetails?.find(
        ({ type }) => type === 'Carrier'
      );
      if (!Details) return;

      const payLoadData = {
        loadId: loadId,
        paymentId: Details.paymentId,
        type: Details.type,
        id: Details.id,
      };
      let data: ListDataType | undefined;
      if (Details.paymentId) {
        data = await trackPromise(
          getFinanceOverviewAccordionDataWithID(payLoadData),
          panelType
        );
      } else {
        data = await trackPromise(
          getFinanceOverviewAccordionDataWithOutID(payLoadData),
          panelType
        );
      }

      setSelectRecordData(data);
      setSelectRecordList(data?.items ? data.items : []);
      reset(
        {
          selectRecordList: data?.items ? data.items : [],
          selectRecordData: data,
          receivedPayments: data?.receivedPayments ?? [],
        },
        { keepDefaultValues: false, keepDirty: false }
      );
      const statementQuery = {
        loadId: loadId,
        id: Details?.id,
        type: Details.type,
      };
      const datastatement: requestStatuesStatementInfo | undefined =
        await trackPromise(
          getFinanceOverviewStatementInfo(statementQuery),
          panelType
        );
      setStatementInfo(datastatement);

      const setEntityData: SelectEntityType = {
        type: Details.type,
        name: Details.name,
        status: Details.paymentStatus,
        loadId: loadId,
        paymentId: Details.paymentId,
        id: Details.id,
      };
      setSelectEntityType(setEntityData);
      setRenderSection(EFinanceOverview.FinanceOverviewDetail);
      setPageIsLoading(false);
    };
    init();
  }, [pageIsLoading, JSON.stringify(financeOverviewData), trip?.status]);

  useEffect(() => {
    if (trip && trip?.status) {
      //Refresh carrier payment when trip status is changed
      setPageIsLoading(true);
    }
  }, [trip?.status]);

  if (
    !financeOverviewData ||
    !financeOverviewData?.tripDetails?.[0]?.paymentDetails?.find(
      ({ type }) => type === 'Carrier'
    )
  ) {
    return <CarrierPaymentNoData />;
  }

  return (
    <Grid className="FinanceOverview" sx={financeStyle.financeContainer}>
      {renderSection === EFinanceOverview.FinanceOverviewDetail &&
        financeOverviewData && (
          <FinanceOverviewAccordionSection
            data={selectRecordList}
            showAccordionTopBarClose={false}
          />
        )}
    </Grid>
  );
};
