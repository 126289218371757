import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import { Grid, SxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

const ITEM_HEIGHT = 48;

export default function LongMenu({
  options = [],
  color,
  customStyles,
  onCloseCb,
  onClick,
  canPerformClick,
  isLoading,
  iconButtonStyles,
}: {
  options: {
    name: string;
    action: (
      actionName: string,
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => void;
    disabled?: boolean;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
    keepOptionsOpenAfterAction?: boolean;
    onCloseCb?: () => void;
  }[];
  color?: string;
  customStyles?: { [key: string]: string | number };
  onCloseCb?: () => void;
  onClick?: () => void;
  canPerformClick?: (event: React.MouseEvent<HTMLElement>) => boolean;
  isLoading?: boolean;
  iconButtonStyles?: SxProps;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (canPerformClick) {
      if (!canPerformClick?.(event)) {
        event?.stopPropagation();
        return false;
      }
    }
    setAnchorEl(event.currentTarget);
    onClick && onClick();
  };
  const handleClose = () => {
    onCloseCb && onCloseCb();
    setAnchorEl(null);
  };

  return (
    <div>
      {!!options?.length && (
        <IconButton
          sx={{
            color: color,
            ...iconButtonStyles,
          }}
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      )}

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: customStyles?.width ? `${customStyles.width}ch` : 'auto',
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {options.map((option) => (
          <MenuItem
            sx={{
              fontSize: '14px',
              padding: '0.5',
            }}
            disabled={option?.disabled}
            key={option.name}
            onClick={(e) => {
              !option.keepOptionsOpenAfterAction && handleClose();
              option.action(option.name, e);
            }}
          >
            <Grid container alignItems="center">
              <Grid marginRight={option.startIcon ? '10px' : 0}>
                {option.startIcon}
              </Grid>
              <Grid> {option.name}</Grid>
              <Grid marginLeft={option.startIcon ? '10px' : 0}>
                {option.endIcon}
              </Grid>
            </Grid>
          </MenuItem>
        ))}
        <LoadingButton
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...(!isLoading && {
              display: 'none',
            }),
          }}
          loading
          variant="text"
        ></LoadingButton>
      </Menu>
    </div>
  );
}
