import { useTheme } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { EventTypes } from '../../../EventEmitter/EventTypes';
import { EventHoldLoadActionData } from '../../../EventEmitter/Events/EventHoldLoadAction';
import { LoadTripActionData } from '../../../EventEmitter/Events/EventLoadTripAction';
import {
  EEventTripStopAction,
  EventTripStopActionData,
} from '../../../EventEmitter/Events/EventTripStopAction';
import { EventUpdateProgressData } from '../../../EventEmitter/Events/EventUpdateProgress';
import { ByLoadIdRequest, UpdateStatusResponse } from '../../../models';
import { useDetailsPanelStore } from '../../../store/DetailsPanel';
import { useStores } from '../../../store/root.context';
import LoadController from '../../../subPages/loadsList/LoadController';
import { LoadSummary } from '../../../subPages/loadsList/models/LoadSummary';
import { StopSolutionV3Prop } from '../../../ui-kit/components/TripPlan';
import useEmit, { EventCallbackFunction } from '../../../utils/hooks/useEmit';
import { ESecondaryDetailsPanelType } from '../../../views/dispatch2/constants/types';
import { E3DotMenuType } from '../../../views/myLoads/components/LoadDetailDialog/types';
import DetailsPanel from '../../DetailsPanel';
import { useSecondaryDetailsPanel } from '../../HOC/withSecondaryDetailsPanel/useSecondaryDetailsPanel';
import WithSecondaryDetailsPanel from '../../HOC/withSecondaryDetailsPanel/withSecondaryDetailsPanel';
import { tripLoadCustomTitle } from '../../UiUtils/uiUtils';
import { useLoadPermission } from '../../hooks/useLoadPermission';
import {
  ELoadStatus,
  EMyLoadDetailsPanelTabs,
  EMyLoadManifestDetailsPanelTabs,
} from '../constants/constants';
import { PropsTypes } from '../constants/interface';
import { ActionsSection } from '../hoc/actionsSection';
import { LoadOverviewDetails } from '../tabs/Overview/models';
import { FormAsset } from '../tabs/Routes/components/AssetAssignmentPopup';
import DetailsTabStrips, { tabStripRendererByKeyFS } from './DetailsTabStrips';
import MyLoadDetailsForm, { MyLoadDetailsFormProps } from './MyLoadDetailsForm';
export default function LoadTabPanel({
  data,
  customerId = 0,
  onClose,
  onDeleted,
  expanded = false,
  defaultTab = EMyLoadDetailsPanelTabs.ROUTES,
  isGlobal = false,
  onAction,
  onUpdateActivitiesCompleted,
  onCreatedLoadHandler,
  isStatusUpdated = false,
  tab,
  handleFactoringResponse,
  triggerAlert,
  handleUpdatedInvoice,
  onClickLoadIdHandler,
  onUpdated,
  manifestDeleteForLoad,
  readonly = false,
}: PropsTypes) {
  const {
    myLoadStore: { setUpdatedLoadId },
  } = useStores();
  const {
    hasLoadViewPermission,
    has3DotsPermission,
    hasInvoiceEditPermission,
  } = useLoadPermission();
  const { trackPromise } = useDetailsPanelStore;
  const [loadData, setLoadData] = useState<LoadSummary | undefined>();
  const { secondaryDetailsPanelData, setSecondaryDetailsPanelData } =
    useSecondaryDetailsPanel();
  const [httpErrors] = useState();
  const [selectedTab, setSelectedTab] = useState<string>(tab || defaultTab);

  const [loadTabList, setLoadTabList] = useState<any>(EMyLoadDetailsPanelTabs);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const handleTripAssignment = (data) => {
    if (
      data.trip.loadId === loadData?.id &&
      (data.isStatusUpdated ||
        data.trip.tractor?.id !== loadData?.tractors?.[0]?.id)
    ) {
      getData(); // this should be replaced by new API which will provide only load status
    }
  };

  // const request = new ByLoadIdRequest({loadIds : id})
  // const data = LoadController.instance().getLoadLightSummaryById(request);
  const handleLoadTripAction = (data: LoadTripActionData) => {
    if (
      (data.entity === 'Trip' && data.loadId === loadData?.id) ||
      (data.entity === 'Load' && data.id === loadData?.id)
    ) {
      getData(); // this should be replaced by new API which will provide only load status
    }

    if (
      data.toStatus === ELoadStatus.DELETED &&
      data.entity === 'Load' &&
      data.id === loadData?.id
    ) {
      onClose?.();
    }
  };

  const handleTripStopAction: EventCallbackFunction<EventTripStopActionData> = (
    data
  ) => {
    if (data.action === EEventTripStopAction.DELETED_PICKUP_RELAY) {
      setSecondaryDetailsPanelData(null);
    } else {
      getData();
    }
  };

  const handleUpdateProgress: EventCallbackFunction<EventUpdateProgressData> = (
    data
  ) => {
    getData();
  };

  const handleUpdatedOverview: EventCallbackFunction<LoadOverviewDetails> = (
    data
  ) => {
    if (data.seqNumber !== loadData?.seqNumber) {
      getData();
    }
  };

  const handleHoldOrReleaseLoad = (data: EventHoldLoadActionData) => {
    if (!loadData?.id) return;
    if (data.loadIds.includes(loadData.id)) {
      setLoadData(
        (prev) =>
          ({
            ...prev,
            onHold: data.type === E3DotMenuType.MARK_AS_HOLD,
          } as unknown as LoadSummary)
      );
      getData();
    }
  };

  useEmit({
    [EventTypes.TRIP_ASSIGNMENT_CHANGE]: handleTripAssignment,
    [EventTypes.LOAD_TRIP_ACTION]: handleLoadTripAction,
    [EventTypes.TRIP_STOP_ACTION]: handleTripStopAction,
    [EventTypes.UPDATE_PROGRESS]: handleUpdateProgress,
    [EventTypes.LOAD_OVERVIEW_CHANGE]: handleUpdatedOverview,
    [EventTypes.HOLD_LOAD]: handleHoldOrReleaseLoad,
  });

  useEffect(() => {
    setSelectedTab(tab || defaultTab);
  }, [tab]);

  const theme: any = useTheme();
  const entity = 'Load';
  const panelWrapperCustomStyles = {
    display: 'flex',
    width: '33.3%',
    padding: '0 5px 5px 0',
    height: '100%',
    flexDirection: 'column',
    background: theme.palette.leftMenuCollapsed.canvas,
    boxShadow: 'none',
  };

  const handleTabStripChange = (selected: EMyLoadDetailsPanelTabs): void => {
    setSelectedTab(selected);
  };

  const tabStripRenderer = (keys: EMyLoadDetailsPanelTabs[]) => (
    <DetailsTabStrips
      keys={
        hasLoadViewPermission
          ? keys
          : keys.filter((key) =>
              [
                EMyLoadDetailsPanelTabs.FINANCE,
                EMyLoadDetailsPanelTabs.INVOICE,
              ].includes(key)
            )
      }
      tab={selectedTab}
      onChange={handleTabStripChange}
    />
  );

  const getData = async () => {
    const request = new ByLoadIdRequest();
    request.loadId = data.id;
    const currLoadData = await trackPromise(
      LoadController.instance().getLoadLightSummaryById(request),
      ESecondaryDetailsPanelType.LOAD
    );
    if (!currLoadData.manifestBaseDetails?.manifestId) {
      onUpdated?.();
    }
    if (currLoadData) {
      setLoadData(currLoadData);
    }
  };

  const getLoadData = async () => {
    const request = new ByLoadIdRequest();
    request.loadId = data.id;
    const currLoadData = await trackPromise(
      LoadController.instance().getLoadLightSummaryById(request),
      ESecondaryDetailsPanelType.LOAD
    );
    if (currLoadData) {
      setLoadData(currLoadData);
    }
  };

  useEffect(() => {
    if (isStatusUpdated) getData();
  }, [isStatusUpdated]);

  useEffect(() => {
    if (data?.id) {
      getLoadData();
    }
  }, [data?.id]);

  useEffect(() => {
    //Fix: after duplicating load-> re-open the fullscreen for created load-> Need to set default tab
    onFullscreen(expanded);
  }, [expanded]);

  useEffect(() => {
    const tabList =
      loadData?.loadType != 'MANIFEST'
        ? EMyLoadDetailsPanelTabs
        : EMyLoadManifestDetailsPanelTabs;
    setLoadTabList(tabList);
  }, [loadData?.loadType]);

  const isEdit = !!loadData?.id;
  const onFullscreen = (isFullscreen: boolean): void => {
    setIsExpanded(isFullscreen);
    const getDefaultTabFS = () => {
      if (hasLoadViewPermission) {
        if (isFullscreen) {
          if (isEdit) {
            return EMyLoadDetailsPanelTabs.FINANCE;
          }
          return EMyLoadDetailsPanelTabs.OVERVIEW;
        }
        return defaultTab;
      } else {
        return defaultTab === EMyLoadDetailsPanelTabs.FINANCE
          ? EMyLoadDetailsPanelTabs.INVOICE
          : defaultTab;
      }
    };
    setSelectedTab(getDefaultTabFS());
  };

  const renderCustomTitle = useMemo(
    () =>
      tripLoadCustomTitle({
        status:
          loadData?.loadStatus === 'INVOICED' && loadData?.invoiceHasPayment
            ? 'PARTIALLY_PAID'
            : loadData?.loadStatus,
        lastUpdatedStop: loadData?.lastUpdatedStop,
        nonInvoiceable: loadData?.nonInvoiceable,
        onHold: !!loadData?.onHold,
        onHoldNote: loadData?.onHoldNote,
        invoiceHasPayment: loadData?.invoiceHasPayment,
      }),
    [
      loadData?.loadStatus,
      loadData?.seqNumber,
      loadData?.lastUpdatedStop?.message,
      loadData?.nonInvoiceable,
      loadData?.onHold,
      loadData?.onHoldNote,
      loadData?.invoiceHasPayment,
    ]
  );

  const openSecondaryDetailsHandler = (
    id: string,
    type: ESecondaryDetailsPanelType
  ): void => {
    const options: any = {
      isGlobal: true,
    };
    if (type === ESecondaryDetailsPanelType.TRIP) {
      options.onAction = (changedStatus: ELoadStatus) => {
        if (changedStatus === ELoadStatus.DELETED) {
          setSecondaryDetailsPanelData(null);
          getData();
          setUpdatedLoadId(data.id);
        }
      };
      options.onClickLoadIdHandler = (stopSolution: StopSolutionV3Prop) => {
        setSecondaryDetailsPanelData(null);
        onClickLoadIdHandler?.(stopSolution);
        getData();
      };
    }
    setSecondaryDetailsPanelData({
      id: id,
      type: type,
      options,
    });
  };

  useEffect(() => {
    //Fix: after duplicating load-> re-open the fullscreen for created load-> Need to set default tab
    onFullscreen(expanded);
  }, [expanded]);

  const onCloseHandler = (): void => {
    onClose?.();
  };

  const handleStatusAction = (
    changedStatus?: ELoadStatus | 'UPDATE_PROGRESS',
    data?: UpdateStatusResponse | null
  ) => {
    setLoadData((prevValues: any) => ({
      ...prevValues,
      loadStatus: changedStatus,
    }));
    onAction?.(loadData?.id, changedStatus, loadData?.seqNumber, data);
  };

  const actionsRenderer = () => {
    return (
      <>
        <ActionsSection
          onStatusAction={handleStatusAction}
          entity={entity}
          loadData={loadData}
          onDeleted={onDeleted}
          onCreatedLoadHandler={onCreatedLoadHandler}
          seqNumber={loadData?.seqNumber}
          assignedTractor={
            (loadData?.tractors && {
              id: loadData?.tractors[0].id,
              name: loadData?.tractors[0].name || '',
            }) as FormAsset
          }
          isExpanded={isExpanded}
        />
        <WithSecondaryDetailsPanel
          secondaryDetailsPanelData={secondaryDetailsPanelData}
          setSecondaryDetailsPanelData={setSecondaryDetailsPanelData}
        />
      </>
    );
  };

  if (!hasLoadViewPermission || !loadData) {
    return null;
  }

  const panelTitle = {
    panelTitle: `${
      loadData?.loadType != 'MANIFEST'
        ? `${t('loadID')}: ${loadData?.seqNumber || ''}`
        : `${t('manifestID')}: ${
            loadData?.manifestBaseDetails?.seqNumber || ''
          }`
    }`,
    renderCustomTitle: () => renderCustomTitle,
    isBetaStatus: loadData?.loadType === 'MANIFEST' ? true : false,
  };

  const defaultMyLoadDetailsFormProps: Omit<
    MyLoadDetailsFormProps,
    'selectedTab'
  > = {
    loadId: loadData,
    customerId: customerId,
    onClose: onCloseHandler,
    onUpdateActivitiesCompleted: onUpdateActivitiesCompleted,
    openSecondaryDetailsHandler: openSecondaryDetailsHandler,
    handleFactoringResponse: handleFactoringResponse,
    triggerAlert: triggerAlert,
    handleUpdatedInvoice: handleUpdatedInvoice,
    isStatusUpdated: getData,
    onAction: onAction,
    setLoadData: setLoadData,
    onUpdated: onUpdated,
    manifestDeleteForLoad: manifestDeleteForLoad,
    readonly: readonly,
    onUpdateMainData: getData,
  };

  const contentCustomStyles = {
    ...(selectedTab !== EMyLoadDetailsPanelTabs.NOTES &&
      loadData?.onHold && {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }),
  };
  return (
    <DetailsPanel
      data={loadData}
      actionButtonLabel={loadData?.id ? undefined : 'Add Load'}
      entity={entity}
      {...panelTitle}
      autoExpanded={expanded}
      onClose={onCloseHandler}
      contentRenderer={() => (
        <MyLoadDetailsForm
          selectedTab={selectedTab}
          {...defaultMyLoadDetailsFormProps}
        />
      )}
      tabStripRenderer={() => tabStripRenderer(Object.values(loadTabList))}
      httpErrors={httpErrors}
      isGlobal={isGlobal}
      footerRenderer={() => <></>}
      onFullscreen={onFullscreen}
      {...(isEdit && {
        fullscreen: {
          panelPropList: [
            {
              data: loadData,
              entity: entity,
              panelId: loadTabList.OVERVIEW,
              panelTitle: ` ${
                loadData?.loadType != 'MANIFEST'
                  ? `Load Id: ${loadData?.seqNumber || ''}`
                  : `Manifest Id: ${
                      loadData?.manifestBaseDetails?.seqNumber || ''
                    }`
              }    `,
              renderCustomTitle: () => renderCustomTitle,
              contentRenderer: () => (
                <MyLoadDetailsForm
                  selectedTab={loadTabList.OVERVIEW}
                  {...defaultMyLoadDetailsFormProps}
                />
              ),
              panelWrapperCustomStyles: {
                ...panelWrapperCustomStyles,
                borderRadius: '18px 0 0 18px', //override border radius
              },
              tabStripRenderer: () =>
                tabStripRendererByKeyFS(loadTabList.OVERVIEW),
              contentCustomStyles: contentCustomStyles,
            },
            {
              data: loadData,
              entity: entity,
              panelId: loadTabList.ROUTES,
              contentRenderer: () => (
                <MyLoadDetailsForm
                  selectedTab={loadTabList.ROUTES}
                  {...defaultMyLoadDetailsFormProps}
                />
              ),
              panelWrapperCustomStyles: {
                ...panelWrapperCustomStyles,
                borderRadius: '0', //override border radius
              },
              panelTitle: '',
              tabStripRenderer: () =>
                tabStripRendererByKeyFS(loadTabList.ROUTES),
              contentCustomStyles: contentCustomStyles,
            },
            {
              data: loadData,
              entity: entity,
              panelId: 'CurrentContentForm',
              contentRenderer: () => (
                <MyLoadDetailsForm
                  selectedTab={selectedTab}
                  {...defaultMyLoadDetailsFormProps}
                />
              ),
              panelWrapperCustomStyles: {
                ...panelWrapperCustomStyles,
                borderRadius: '0 18px 18px 0', //override border radius
              },
              panelTitle: '',
              tabStripRenderer: () =>
                tabStripRenderer(
                  loadData?.loadType != 'MANIFEST'
                    ? [
                        EMyLoadDetailsPanelTabs.FINANCE,
                        EMyLoadDetailsPanelTabs.INVOICE,
                        EMyLoadDetailsPanelTabs.TRACKING,
                        EMyLoadDetailsPanelTabs.NOTES,
                        EMyLoadDetailsPanelTabs.DOCUMENTS,
                        EMyLoadDetailsPanelTabs.ADDITIONAL_DETAILS,
                        EMyLoadDetailsPanelTabs.ACTIVITY,
                      ]
                    : [EMyLoadDetailsPanelTabs.FINANCE]
                ),
              contentCustomStyles: contentCustomStyles,
            },
          ],
        },
      })}
      status={loadData?.loadStatus}
      id={data.id}
      loadData={loadData}
      onAction={onAction}
      onCreatedLoadHandler={onCreatedLoadHandler}
      assignedTractor={
        loadData?.tractors && {
          id: loadData?.tractors[0].id,
          name: loadData?.tractors[0].name || '',
        }
      }
      seqNumber={loadData?.seqNumber}
      secondaryDetailsPanelData={secondaryDetailsPanelData}
      setSecondaryDetailsPanelData={setSecondaryDetailsPanelData}
      actionsRenderer={!readonly ? actionsRenderer : undefined}
      contentCustomStyles={contentCustomStyles}
    />
  );
}
