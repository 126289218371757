export const ErrorsMsg: { [key: string]: string } = {
  displayName: 'The Display Name is already used.',
  ACCESS_TOKEN: 'The Access Token is already used.',
  API_KEY: 'The API Key is already used.',
  GROUP_ID: 'The Group ID is already used.',
  COMPANY_ID: 'The Company ID is already used.',
  USER_ID: 'The User ID is already used.',
  USERNAME: 'The Username is already used.',
  DATABASE: 'The Database is already used.',
  mandatoryFields: 'This is a mandatory field',
  maxLength50: 'This field should not exceed more than 50 characters',
  maxLength255: 'This field should not exceed more than 255 characters',
  terminalInUse: 'is already in use',
};
