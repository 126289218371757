import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import { Divider, Stack, SvgIcon } from '@mui/material';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { CustomizeCardHeader } from '../../../../common/Dashboard';
import { SafetyStandingCard } from '../../../../common/Dashboard/SafetyStandingCard';
import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import { useStores } from '../../../../store/root.context';
import { HomeContext } from '../../../home/constants/constant';
import { HomeContextTypes } from '../../../home/constants/interface';
import { StyledComponentRoot } from '../../styles/styles';
import { t } from 'i18next';
import {
  SafteyIcon,
  Tractor02Icon,
  Trailer02Icon,
} from '../../../../ui-kit/components/Assets';

export const SafetyStanding = observer((): JSX.Element => {
  const { dashboardStore } = useStores();
  const { safetyData, safetyLoader } = dashboardStore;
  const { isLaptop, isMobile, isTablet } = useThemeResponsive();

  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      width={'100%'}
      spacing={1}
      // {...(isMobile && {
      //   direction: 'column',
      // })}
      // {...(isLaptop && {
      //   spacing: 0,
      // })}
    >
      <StyledComponentRoot>
        <CustomizeCardHeader
          avatar={
            <SafteyIcon height={30} width={30} color="#fff" opacity="1" />
          }
          title={t('driverSafetyStanding')}
        />

        <SafetyStandingCard
          title={''}
          warningId={'driverWarning'}
          criticalId={'driverCritical'}
          warning={
            safetyData?.driverWarning ? safetyData?.driverWarning?.count : 0
          }
          critical={
            safetyData?.driverCritical ? safetyData?.driverCritical?.count : 0
          }
          loader={safetyLoader}
        />
      </StyledComponentRoot>
      <StyledComponentRoot>
        <CustomizeCardHeader
          avatar={
            <SvgIcon
              component={() => <Tractor02Icon height={30} width={30} />}
            />
          }
          title={t('tractorSafetyStanding')}
        />

        <SafetyStandingCard
          title={''}
          warningId={'tractorWarning'}
          criticalId={'tractorCritical'}
          warning={
            safetyData?.tractorWarning ? safetyData?.tractorWarning?.count : 0
          }
          critical={
            safetyData?.tractorCritical ? safetyData?.tractorCritical?.count : 0
          }
          loader={safetyLoader}
        />
      </StyledComponentRoot>

      <StyledComponentRoot>
        <CustomizeCardHeader
          avatar={
            <SvgIcon
              component={() => <Trailer02Icon height={30} width={30} />}
            />
          }
          title={t('trailerSafetyStanding')}
        />

        <SafetyStandingCard
          title={''}
          warningId={'trailerWarning'}
          criticalId={'trailerCritical'}
          warning={
            safetyData?.trailerWarning ? safetyData?.trailerWarning?.count : 0
          }
          critical={
            safetyData?.trailerCritical ? safetyData?.trailerCritical?.count : 0
          }
          loader={safetyLoader}
        />
      </StyledComponentRoot>
    </Stack>
  );
});
