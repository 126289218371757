import { Service } from './Service';
import {
  PaginatedTemplateListRequest,
  PaginatedTemplateListResponse,
  DeleteTemplateRequest,
  CreateTemplateRequest,
  EditTemplateRequest,
  DeleteTemplateResponse,
  TemplateSummaryResponse,
  TemplateSummaryRequest,
  TemplateDetailsRequest,
  TemplateDetailsResponse,
} from '../../models';
import { ServiceError } from './helperTypes';

export abstract class ITemplateService extends Service {
  abstract getPaginatedTemplateList(
    requestData: PaginatedTemplateListRequest
  ): Promise<PaginatedTemplateListResponse | ServiceError>;

  abstract exportTemplateListToExcel(
    requestData: PaginatedTemplateListRequest
  ): Promise<object | ServiceError>;

  abstract getTemplateDetails(
    data: TemplateDetailsRequest
  ): Promise<TemplateDetailsResponse | ServiceError>;

  abstract getTemplateSummary(
    data: TemplateSummaryRequest
  ): Promise<TemplateSummaryResponse | ServiceError>;

  abstract createTemplate(
    requestData: CreateTemplateRequest
  ): Promise<TemplateDetailsResponse | ServiceError>;

  abstract editTemplate(
    requestData: EditTemplateRequest
  ): Promise<TemplateDetailsResponse | ServiceError>;

  abstract deleteTemplate(
    requestData: DeleteTemplateRequest
  ): Promise<DeleteTemplateResponse | ServiceError>;
}
