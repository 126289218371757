import React from 'react';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { ActoreFeatureIcon } from '../assets/icons';
import {
  CreatePaymentFactoringSVG,
  GetPaymentFactoringSVG,
  GetPaymentUpdates,
  SendInvoiceFactoringSVG,
} from '../components/Assets/AxeleIcons/AxeleIcons';
import { t } from 'i18next';
export const allCustomerFeatures: { [key: string]: any } = {
  HOS: {
    title: 'HoS Tracking',
    subtitle: 'Track Hours of Service (HoS) of drivers',
    icon: <TimerOutlinedIcon />,
  },
  GPS: {
    title: 'GPS Tracking',
    subtitle: 'Track latest location of drivers',
    icon: <LocationOnOutlinedIcon />,
  },

  DRIVER_ACTUAL_MILES: {
    title: 'Driver Actual Miles',
    subtitle:
      'Create driver settlements based on the duty hours recorded in ELD',
    icon: <TimerOutlinedIcon />,
  },

  DRIVER_DUTY_HOURS: {
    title: 'Driver Duty Hours',
    subtitle:
      'Create driver settlements based on the duty hours recorded in ELD',
    icon: <AccessTimeOutlinedIcon />,
  },

  TRACTOR_TRACKING: {
    title: 'Tractor Tracking',
    subtitle: 'Track latest location of tractors',
    icon: <AccessTimeOutlinedIcon />,
  },

  TRAILER_TRACKING: {
    title: 'Trailer Tracking',
    subtitle: 'Track latest location of tractors',
    icon: <TimerOutlinedIcon />,
  },

  SMART_LOAD_SEARCH: {
    title: 'Smart Load Search',
    subtitle:
      'Intelligently search loads based on driver’s location, HOS, preferences',
    icon: <ContentPasteSearchIcon />,
  },

  FILTER_BLACKLISTED_BROKERS: {
    title: 'Filter Blacklisted Brokers',
    subtitle:
      'Filter the loads in the search to block all blacklisted brokers defined by you',
    icon: <SentimentDissatisfiedIcon />,
  },

  VIEW_LANE_RATES: {
    title: 'View Lane Rates',
    subtitle: 'View lane rates from the load search result',
    icon: <TimerOutlinedIcon />,
  },

  FILTER_PREFERRED_BROKERS: {
    title: 'Filter Preferred Brokers',
    subtitle: 'Filter the loads in the search based on preferred brokers',
    icon: <SentimentSatisfiedIcon />,
  },

  TRUCK_POSTING: {
    title: 'Truck Posting',
    subtitle: 'Post and manage truck capacity on the load board',
    icon: <TimerOutlinedIcon />,
  },

  BOOK_LOADS: {
    title: 'Book Loads',
    subtitle: 'Book load directly with the broker',
    icon: <TimerOutlinedIcon />,
  },
  Send_Invoice_For_Factoring: {
    title: 'Send Invoices for Factoring',
    subtitle:
      'You can create an invoice from LoadOps into the factoring provider system. All your invoice line items, equipment’s, documents are synced with the invoice created in the factoring provider system',
    icon: <ActoreFeatureIcon />,
  },
  Send_Documents: {
    title: t('sendDocs'),
    subtitle:
      'Send all the documents to the factoring provider system required for factoring your invoice',
    icon: <SendInvoiceFactoringSVG />,
  },
  SYNC_INVOICES: {
    title: 'Sync Invoices',
    subtitle: 'Send invoices from LoadOps into QuickBooks',
    icon: <CurrencyExchangeIcon />,
  },
  GET_PAYMENT_UPDATES: {
    title: 'Get Payment Updates',
    subtitle:
      'Sync the payment status (Partial or full) from QuickBooks into LoadOps',
    icon: <GetPaymentUpdates />,
  },
  Create_Payment_Schedule: {
    title: 'Create Payment Schedule',
    subtitle:
      'Submit one or more invoices to the factoring by creating a schedule. You can also choose the funding account where the payments should credit.',
    icon: <CreatePaymentFactoringSVG />,
  },
  Get_Payment_Updates: {
    title: 'Get Payment Updates',
    subtitle:
      'Refresh the factoring status of an invoice in LoadOps to receive the payment status of the invoice',
    icon: <GetPaymentFactoringSVG />,
  },
  SYNC_TRANSACTION: {
    title: 'Sync Transaction',
    subtitle: 'Send fuel surcharge transaction to LoadOps',
    icon: <CurrencyExchangeIcon />,
  },
  TRANSACTION_UPDATES: {
    title: 'Transaction Updates',
    subtitle: 'Sync the transaction updates from fuel card API to LoadOps',
    icon: <GetPaymentUpdates />,
  },
};
