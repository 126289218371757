import { ThemeOptions } from '@mui/material';

export const MuiButtonBase: ThemeOptions['components'] = {
  MuiButtonBase: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          textTransform: 'none',
        }),
    },
  },
};
