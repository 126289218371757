import {
  ChangeStatusBulkRequest,
  DeleteExpenseRequest,
  DeletePayItemDTO,
  EntityListRequestDTO,
  EntityListResDTO,
  ExpenseDetails,
  ExpenseListTotalsType,
  ExpensesListDataCount,
  FuelTypesListRequest,
  FuelTypesType,
  GetExpenseCategoriesListRequest,
  GetExpenseDetailsByIdRequest,
  GetExpenseListRequest,
  GetExpenseShortListByCardNumberRequest,
  GetExpenseShortListRequest,
  PaginatedExpenseCategoriesList,
  PaginatedExpenseList,
  PaginatedVendorNamesList,
  Payment,
  QueryParams,
  SaveExpenseRequest,
  SavePayItemRequest,
  SavePaymentRequest,
  VendorNamesListRequest,
} from '../../models/index';

import { Service } from './Service';

export abstract class IExpenseService extends Service {
  abstract getExpenseList(
    requestData: GetExpenseListRequest,
    fetchingType: string
  ): Promise<PaginatedExpenseList | undefined>;

  abstract getTotals(
    requestData: GetExpenseListRequest
  ): Promise<ExpenseListTotalsType | undefined>;

  abstract getExpenseListShort(
    requestData: GetExpenseShortListRequest
  ): Promise<PaginatedExpenseList | undefined>;

  abstract getExpenseListShortByCardNumber(
    requestData: GetExpenseShortListByCardNumberRequest
  ): Promise<PaginatedExpenseList | undefined>;

  abstract getExpenseDetailsById(
    requestData: GetExpenseDetailsByIdRequest
  ): Promise<ExpenseDetails | undefined>;

  abstract getVendorNamesList(
    requestData: VendorNamesListRequest
  ): Promise<PaginatedVendorNamesList | undefined>;

  abstract getExpenseCategoriesList(
    requestData: GetExpenseCategoriesListRequest
  ): Promise<PaginatedExpenseCategoriesList | undefined>;

  abstract getFuelTypesList(
    requestData: FuelTypesListRequest
  ): Promise<FuelTypesType[] | undefined>;

  abstract getAssetListByEntity(
    requestData: EntityListRequestDTO
  ): Promise<EntityListResDTO[] | undefined>;

  abstract createExpense(
    paylodData: SaveExpenseRequest
  ): Promise<ExpenseDetails | undefined>;

  abstract updateExpense(
    paylodData: SaveExpenseRequest
  ): Promise<ExpenseDetails | undefined>;

  abstract deleteExpensesBulk(
    paylodData: DeleteExpenseRequest
  ): Promise<string[] | undefined>;

  abstract createPaymentsBulk(
    paylodData: SavePaymentRequest
  ): Promise<Payment[] | undefined>;

  abstract updatePaymentsBulk(
    paylodData: SavePayItemRequest
  ): Promise<Payment[] | undefined>;

  abstract deletePaymentsBulk(
    payItemDTOS?: DeletePayItemDTO
  ): Promise<string[] | number[] | undefined>;

  abstract getExpenseListCount(
    requestData: QueryParams
  ): Promise<ExpensesListDataCount | undefined>;

  abstract changeStatusBulk(
    requestData: ChangeStatusBulkRequest
  ): Promise<string | undefined>;
}
