import { Box, Stack, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import {
  GridThreeDotMenu,
  GridThreeDotMenuProps,
} from '../components/ThreeDotMenu';
import { GridColDefSelf } from '../types';

export const preProcessTableColumnsData = ({
  primaryColumn3DotMenu,
  tableColumnsData,
}: {
  primaryColumn3DotMenu: GridThreeDotMenuProps;
  tableColumnsData: GridColDefSelf[];
}): GridColDefSelf[] => {
  return tableColumnsData.map((tableColumn: GridColDefSelf) => {
    return {
      ...tableColumn,
      ...(!!tableColumn.permanent && {
        //override the renderCell
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              width="100%"
              sx={{
                '.GridThreeDotMenu': {
                  display: 'none',
                },
                '&:hover': {
                  '.GridThreeDotMenu': {
                    display: 'unset',
                  },
                },
              }}
            >
              {tableColumn?.renderCell
                ? tableColumn?.renderCell?.(params)
                : params.formattedValue}
              <Tooltip title="Actions" arrow>
                <Box className="GridThreeDotMenu">
                  <GridThreeDotMenu
                    accountData={params}
                    menuOptions={primaryColumn3DotMenu.menuOptions}
                  />
                </Box>
              </Tooltip>
            </Stack>
          );
        },
      }),
    } as GridColDefSelf;
  });
};
