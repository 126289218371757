import { Box } from '@mui/system';
import { lazy, Suspense, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import EmailReceivers from '../../../common/Email/EmailReceivers';
import LongMenu from '../../../common/LongMenu';
import Checkbox from '../../../common/Ui/Checkbox';
import FormDialog from '../../../common/Ui/FormDialog';
import TextField from '../../../common/Ui/TextField/TextField';
import {
  PayStatementData,
  PayStatementsEntityTypeEnum,
  PayStatementsTypeEnum,
} from '../../../models';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
import { isHasPermission } from '../../../utils';
import {
  downloadPayStatementPdf,
  previewPayStatementPdf,
  sendEmail,
} from './api.utils';

import { useTheme } from '@mui/material';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';
import { SettlementConstants } from '../../../locales/en/finance/settlements';
import StorageManager from '../../../StorageManager/StorageManager';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import { EmailFormValidationSchema } from '../../../validator/validationSchemas';
import { getEmailOrganizationName } from '../../invoices/utils';
import { BulkSettlementActions } from '../config/bulkActions.config';
import { SettlementStatusChangePopupConfig } from '../constants';
const Wisiwig = lazy(() => import('../../../ui-kit/components/Wisiwig'));

const deletePopupConfig = {
  pendingPayment: {
    title: '',
    body: '',
    subtitle: '',
    buttonText: '',
    backgroundColor: undefined,
  },
  inReviewStatement: {
    title: SettlementConstants.deleteSettlement,
    body: SettlementConstants.deleteSettlementConfirmation,
    subtitle: SettlementConstants.deleteSettlementSubtitle,
    buttonText: 'Delete',
    backgroundColor: undefined,
  },
  reviewedStatement: {
    title: SettlementConstants.deleteSettlement,
    body: SettlementConstants.deleteSettlementConfirmation,
    subtitle: SettlementConstants.deleteSettlementSubtitle,
    buttonText: 'Delete',
    backgroundColor: undefined,
  },
  closedStatement: {
    title: SettlementConstants.reopenSettlement,
    body: SettlementConstants.removeItemFromSettlement,
    subtitle: SettlementConstants.addedToPendingPayment,
    buttonText: 'Reopen',
    backgroundColor: '#2B64CB',
  },
};

export const getEmailMessageAndSubject = (
  payStatementData: PayStatementData,
  userName: string,
  organizationName: string,
  isTerminalEnabled: boolean
) => {
  const senderName = getEmailOrganizationName(
    payStatementData?.terminal?.companyName,
    organizationName,
    isTerminalEnabled
  );
  return {
    message: `<p>Dear ${payStatementData.payStatementName} ${
      payStatementData.payStatementType === PayStatementsTypeEnum.tractor
        ? ' owner'
        : ''
    }, 
  <p><br /></p>
  <p>Please find the attached Settlement for processing your requirements.</p>
  <p>The following documents are attached:</p>
  <p><br /></p>
  <p>${payStatementData.payStatementType}_Settlement_${
      payStatementData.payStatementName
    }.pdf</p>
  <p><br /></p>
  <p>Regards,</p>
  <p>${userName}</p>
  <p>${senderName}</p>
  </p>`,
    subject: `Settlement N${payStatementData?.seqNumber} for ${
      payStatementData.payStatementName
    } ${
      payStatementData.payStatementType === PayStatementsTypeEnum.tractor
        ? ' owner'
        : ''
    } from ${senderName}`,
  };
};

function SendEmailForm() {
  const { control } = useFormContext();

  return (
    <>
      <EmailReceivers />
      <Box>
        <TextField control={control} name="subject" label="Subject" />
      </Box>
      <Box sx={{ mb: 4 }}>
        <Suspense fallback={<></>}>
          <Wisiwig
            customStyles={{
              contentBackground: {
                backgroundColor: 'transparent',
              },
              headerBackgroundColor: {
                backgroundColor: 'transparent',
              },
            }}
            control={control}
            name="message"
          />
        </Suspense>
      </Box>
      <Checkbox
        control={control}
        disabled
        name={'isSettlement'}
        label={'Settlement'}
      />
    </>
  );
}

type propsType = {
  payStatementData: PayStatementData;
  entityType: PayStatementsEntityTypeEnum;
  onDeletePayStatement: () => void;
  onReopenPayStatement: (revertToStatus?: string) => void;
};

export function ActionsSection({
  payStatementData,
  entityType,
  onDeletePayStatement,
  onReopenPayStatement,
}: propsType) {
  const user = StorageManager.getUser();
  const { getIsTerminalEnabled } = useRootStore();
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [revertToStatus, setRevertToStatus] =
    useState<BulkSettlementActions | null>();
  const options = useMemo(() => {
    const noPermissionForReopenSettlement = !isHasPermission([
      AXELE_PERMISSION_TYPE.FINANCIAL_PAYMENT_CHANGE_STATUS_CLOSE_INREVIEW,
    ]);
    const noPermissionForCloseSettlement = !isHasPermission([
      AXELE_PERMISSION_TYPE.FINANCIAL_PAYMENT_CHANGE_STATUS_INREVIEW_CLOSE,
    ]);

    const noPermissionForDeleteSettlement = !isHasPermission([
      AXELE_PERMISSION_TYPE.FINANCIAL_PAYMENT_CHANGE_STATUS_INREVIEW_CLOSE,
    ]);

    const noPermissionForViewPreview = !isHasPermission([
      AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_VIEW,
    ]);

    const mainActions = [
      {
        name: 'Download',
        action: () => downloadPayStatementPdf(payStatementData),
        disabled: noPermissionForCloseSettlement,
      },
      {
        name: 'Email',
        action: () => setShowEmailPopup(true),
        disabled: noPermissionForCloseSettlement,
      },
      {
        name: 'Preview',
        action: () => previewPayStatementPdf(payStatementData),
        disabled: noPermissionForViewPreview,
      },
    ];

    const deleteAction = {
      name: 'Delete',
      action: () => setIsDeleteDialogOpen(true),
      disabled: noPermissionForCloseSettlement,
    };

    const revertToInReview = {
      name: 'Mark as In Review',
      action: () => {
        setRevertToStatus(BulkSettlementActions.REVERT_TO_IN_REVIEW);
        setIsDeleteDialogOpen(true);
      },
      disabled: noPermissionForReopenSettlement,
    };
    const revertToReviewed = {
      name: 'Mark as Reviewed',
      action: () => {
        setRevertToStatus(BulkSettlementActions.REVERT_TO_REVIEWED);
        setIsDeleteDialogOpen(true);
      },
      disabled: noPermissionForReopenSettlement,
    };

    if (entityType === PayStatementsEntityTypeEnum.closedStatement) {
      mainActions.push(revertToInReview);
      mainActions.push(revertToReviewed);
    }

    if (
      entityType === PayStatementsEntityTypeEnum.inReviewStatement ||
      entityType === PayStatementsEntityTypeEnum.reviewedStatement
    ) {
      mainActions.push(deleteAction);
    }

    return mainActions;
  }, [entityType, payStatementData]);

  const handleDeleteAction = () => {
    if (
      entityType === PayStatementsEntityTypeEnum.inReviewStatement ||
      entityType === PayStatementsEntityTypeEnum.reviewedStatement
    ) {
      onDeletePayStatement();
    } else {
      onReopenPayStatement(revertToStatus);
    }
    setIsDeleteDialogOpen(false);
  };

  const handleSendEmailAction = async (data: any) => {
    const response = await sendEmail(data, payStatementData);
    if (response) {
      setShowEmailPopup(false);
    }
  };
  const theme = useTheme();

  const renderDeletePopup = () => {
    if (
      revertToStatus === BulkSettlementActions.REVERT_TO_IN_REVIEW ||
      revertToStatus === BulkSettlementActions.REVERT_TO_REVIEWED
    ) {
      const templateConfig =
        SettlementStatusChangePopupConfig?.(theme)?.[revertToStatus];
      return (
        <DeletePopup
          open={!!isdeleteDialogOpen}
          {...templateConfig}
          onAction={handleDeleteAction}
          onClose={() => setIsDeleteDialogOpen(false)}
          width="450px"
        />
      );
    }
    return (
      <DeletePopup
        open={!!isdeleteDialogOpen}
        title={deletePopupConfig[entityType].title}
        body={deletePopupConfig[entityType].body}
        subtitle={deletePopupConfig[entityType].subtitle}
        onAction={handleDeleteAction}
        onClose={() => setIsDeleteDialogOpen(false)}
        buttonText={deletePopupConfig[entityType].buttonText}
        backgroundColor={deletePopupConfig[entityType].backgroundColor}
        width="450px"
      />
    );
  };

  return (
    <>
      <LongMenu
        color={'primary.contrast'}
        options={options}
        onClick={() => {
          setRevertToStatus(null);
        }}
      />
      {isdeleteDialogOpen && renderDeletePopup()}
      {showEmailPopup && (
        <FormDialog
          data={{
            to: payStatementData?.email ? [payStatementData?.email] : [],
            cc: [],
            subject: getEmailMessageAndSubject(
              payStatementData,
              `${user?.firstname} ${user?.lastname}`,
              user?.organizationName,
              getIsTerminalEnabled
            ).subject,
            message: () =>
              getEmailMessageAndSubject(
                payStatementData,
                `${user?.firstname} ${user?.lastname}`,
                user?.organizationName,
                getIsTerminalEnabled
              ).message,
            isSettlement: true,
          }}
          validationSchema={EmailFormValidationSchema}
          titleText="Email Settlement"
          actionLabel="Send Email"
          open={showEmailPopup}
          onAction={handleSendEmailAction}
          handleClose={() => setShowEmailPopup(false)}
          contentRenderer={() => <SendEmailForm />}
        />
      )}
    </>
  );
}
