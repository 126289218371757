import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataPreviewIcon } from '../../../../../ui-kit/components/Assets';
import { fontFamily } from '../../constants';

export default function DataPreview() {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: 616,
        border: '1px solid rgba(102, 158, 255, 0.5)',
        borderRadius: 2,
        filter: 'drop-shadow(0px -11px 25px rgba(78, 101, 120, 0.25)',
        backdropFilter: 'blur(12px)',
      }}
    >
      <Box sx={{ mb: 3 }}>
        <DataPreviewIcon />
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant={'h4'}
          sx={{ fontSize: 34, fontFamily, color: 'rgba(0, 17, 34, 0.75)' }}
        >
          Data Preview
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={'h6'}
          sx={{
            color: 'rgba(0, 17, 34, 0.75)',
            fontFamily,
            fontSize: 20,
            fontWeight: 500,
          }}
        >
          Map values and click on eye icon to view sample values
        </Typography>
      </Box>
    </Box>
  );
}
