import { memo, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Button, Divider, Grid, Link, Typography } from '@mui/material';

import StorageManager from '../../../../StorageManager/StorageManager';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';

import AxeleAccordion from '../../../../ui-kit/components/Accordion';
import { AddIcon } from '../../../../ui-kit/components/Assets';

import { useTranslation } from 'react-i18next';
import { KPIInfoCard } from '../../../../common/KPIInfoCard';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import FormDialog from '../../../../common/Ui/FormDialog/index';
import { UI_DATE_FORMAT } from '../../../../constants/date.constants';
import { StaticDataType } from '../../../../contexts/StaticDataContext';
import {
  PayStatementData,
  PayStatementStatusEnum,
  PayStatementsEntityTypeEnum,
  PayStatementsListTypeEnum,
  PayStatementsTypeEnum,
  PaymentDetails,
  PaymentList,
  SelectedPaymentsType,
  prepareFromList,
} from '../../../../models';
import { FileType } from '../../../../types';
import { useScroll } from '../../../../utils/hooks/useScroll';
import {
  amountFormat,
  getFormattedDateForGrid,
  isHasPermission,
  setUtcDate,
} from '../../../../utils/index';
import {
  createNonTripPayment,
  deleteNonTripPaymentById,
  getGrossYearToDateAmountById,
  isPayItemActionAllowed,
  removeEditInReviewPayments,
  saveFile,
} from '../../utils/api.utils';
import {
  calculatePaymentsTotalAmount,
  excludeItemFromListById,
  getPaymentsIds,
} from '../../utils/helpers';
import AddPendingPaymentDialog from '../AddPendingPaymentDialog';
import {
  fuelPaymentsFormValidationSchema,
  otherPaymentsFormValidationSchema,
} from './../../constants';
import AddNewPaymentForm from './AddNewPaymentForm';
import NonTripPaymentForm from './NonTripPaymentForm';
import TripPaymentForm from './TripPaymentForm';

type propsType = {
  staticData: StaticDataType;
  payStatementData: PayStatementData;
  entityType: PayStatementsEntityTypeEnum;
  selectedTableRow: PayStatementData;
  canAddPendingPaymentItem?: boolean;
  onTotalAmountUpdate: (
    id: number,
    totalAmount: number,
    shouldUpdateTable: boolean
  ) => void;
  onCheckboxChange: (checked: boolean, id: number[]) => void;
  onUpdated?: (
    id: number,
    totalAmount: number,
    shouldClosPanel?: boolean
  ) => void;
  onAddPendingPayment?: (selectedPayments: SelectedPaymentsType) => void;
  onAfterAction?: () => void;
  isUpdated?: () => void;
  id?: number;
};

function PayStatementDetailsForm({
  staticData,
  payStatementData,
  entityType,
  selectedTableRow,
  canAddPendingPaymentItem,
  onTotalAmountUpdate,
  onCheckboxChange,
  onUpdated,
  onAddPendingPayment,
  onAfterAction,
  isUpdated,
  id,
}: propsType) {
  const { watch, setValue } = useFormContext();
  const [createPaymentFormDialogMode, setCreatePaymentFormDialogMode] =
    useState<string | null>(null);
  const [isAddPendingPaymentModalOpen, setIsAddPendingPaymentModalOpen] =
    useState<boolean>(false);

  const userStorageData = StorageManager.getUser();
  const [
    totalAmount,
    grossYearToDate,
    tripPaymentsList,
    fuelNonTripPaymentsList,
    otherNonTripPaymentsList,
  ] = watch([
    'totalAmount',
    'grossYearToDate',
    'tripPaymentsList',
    'fuelNonTripPaymentsList',
    'otherNonTripPaymentsList',
  ]);

  const updateTotalAmount = (
    listTotalAmount: number,
    shouldUpdateTableAmount: boolean,
    isFuelPayment?: boolean
  ) => {
    let payStatementTotalAmount = 0;
    switch (isFuelPayment) {
      case undefined:
        payStatementTotalAmount =
          (fuelNonTripPaymentsList?.totalAmount ?? 0) +
          (otherNonTripPaymentsList?.totalAmount ?? 0) +
          listTotalAmount;
        break;
      case true:
        payStatementTotalAmount =
          (tripPaymentsList?.totalAmount ?? 0) +
          (otherNonTripPaymentsList?.totalAmount ?? 0) +
          listTotalAmount;
        break;

      default:
        payStatementTotalAmount =
          (tripPaymentsList?.totalAmount ?? 0) +
          (fuelNonTripPaymentsList?.totalAmount ?? 0) +
          listTotalAmount;
        break;
    }
    onTotalAmountUpdate(
      payStatementData.id,
      payStatementTotalAmount,
      shouldUpdateTableAmount
    );
    setValue('totalAmount', payStatementTotalAmount);
  };
  const updateGrossYearToDateAmount = async (
    payStatement: PayStatementData,
    entityType: PayStatementsEntityTypeEnum
  ) => {
    const grossYearToDate = await getGrossYearToDateAmountById(
      payStatement,
      entityType
    );
    setValue('grossYearToDate', grossYearToDate);
  };

  const addNonTripPayment = async (payment: PaymentDetails) => {
    const response = await createNonTripPayment(
      payment,
      payStatementData,
      staticData,
      entityType
    );

    if (response) {
      if (!!payment.assignedDocuments?.length) {
        const fileResponse = await saveFile(
          response,
          payment.assignedDocuments,
          staticData
        );
        if (fileResponse) {
          response.assignedDocuments[0] = {
            name: fileResponse.fileName,
            documentId: fileResponse.documentId,
          } as FileType;
        }
      }

      const isFuelPayment = response.expenseCategoryKey === 'FUEL';
      const content = isFuelPayment
        ? [...(fuelNonTripPaymentsList?.content ?? []), response]
        : [...(otherNonTripPaymentsList?.content ?? []), response];

      const nonTripListTotalAmount = calculatePaymentsTotalAmount(content);

      updateTotalAmount(
        nonTripListTotalAmount,
        entityType === selectedTableRow.entity,
        isFuelPayment
      );

      updateGrossYearToDateAmount(payStatementData, entityType);

      setValue(
        isFuelPayment ? 'fuelNonTripPaymentsList' : 'otherNonTripPaymentsList',
        { content, totalAmount: nonTripListTotalAmount }
      );
      onAfterAction?.();
    }

    setCreatePaymentFormDialogMode(null);
  };

  const deleteNonTripPayment = async (
    payment: PaymentDetails,
    isFuelPayment: boolean,
    documentIds: string[]
  ) => {
    const result = await deleteNonTripPaymentById(
      payment,
      payStatementData.payStatementType as PayStatementsTypeEnum,
      documentIds
    );

    if (result) {
      const content = isFuelPayment
        ? excludeItemFromListById(fuelNonTripPaymentsList?.content, payment.id)
        : excludeItemFromListById(
            otherNonTripPaymentsList?.content,
            payment.id
          );

      const nonTripListTotalAmount = calculatePaymentsTotalAmount(content);
      updateTotalAmount(nonTripListTotalAmount, true, isFuelPayment);

      setValue(
        isFuelPayment ? 'fuelNonTripPaymentsList' : 'otherNonTripPaymentsList',
        {
          content,
          totalAmount: nonTripListTotalAmount,
        }
      );
      onAfterAction?.();
    }
  };

  const RemoveInReviewPayments = useCallback(
    async (payment: PaymentDetails, isFuelPayment?: boolean) => {
      const result = await removeEditInReviewPayments(
        {
          startDate: payStatementData.startDate,
          endDate: payStatementData.endDate,
          updateDate: setUtcDate(),
          createDate: payStatementData.createDate,
          ids: getPaymentsIds({
            tripPaymentsList,
            fuelNonTripPaymentsList,
            otherNonTripPaymentsList,
            excludedId: payment.id,
          }),
          status: payment?.settlementStatus ?? PayStatementStatusEnum.IN_REVIEW,
        },
        payStatementData
      );

      let listName = '';
      let content = [];

      if (!result) return;
      onAfterAction?.();
      switch (isFuelPayment) {
        case undefined:
          listName = 'tripPaymentsList';

          content = excludeItemFromListById(
            tripPaymentsList?.content,
            payment.id
          );
          break;

        case true:
          listName = 'fuelNonTripPaymentsList';
          content = excludeItemFromListById(
            fuelNonTripPaymentsList?.content,
            payment.id
          );
          break;

        default:
          listName = 'otherNonTripPaymentsList';

          content = excludeItemFromListById(
            otherNonTripPaymentsList?.content,
            payment.id
          );
          break;
      }
      const ListTotalAmount = calculatePaymentsTotalAmount(content);
      updateTotalAmount(
        ListTotalAmount,
        entityType === selectedTableRow.entity,
        isFuelPayment
      );

      updateGrossYearToDateAmount(payStatementData, entityType);

      setValue(listName, {
        content,
        totalAmount: ListTotalAmount,
      });
      if (
        entityType !== selectedTableRow.entity &&
        typeof selectedTableRow?.amount === 'number'
      ) {
        onUpdated &&
          onUpdated(
            selectedTableRow.id,
            selectedTableRow.amount + payment.amount,
            false
          );
      }
    },
    [
      tripPaymentsList,
      fuelNonTripPaymentsList,
      otherNonTripPaymentsList,
      selectedTableRow,
    ]
  );

  const openTripPaymentModal = (mode: string) => {
    setCreatePaymentFormDialogMode(mode);
  };

  const closeTripPaymentModal = () => {
    setCreatePaymentFormDialogMode(null);
  };
  const noPermissionForEditPayStatement = !isHasPermission([
    AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_EDIT,
  ]);

  const paymentPerTripTitleRenderer = (): JSX.Element => {
    return (
      <Typography variant="h7" sx={{ color: 'primary.main' }}>
        Payment Per Trip
      </Typography>
    );
  };

  const paymentPerTripLabelRenderer = (): JSX.Element => {
    return (
      <Typography variant="h7" sx={{ color: 'primary.main' }}>
        {amountFormat(tripPaymentsList?.totalAmount)}
      </Typography>
    );
  };

  const fuelNonTripPaymentsLabelRenderer = (): JSX.Element => {
    return (
      <Typography variant="h7" sx={{ color: 'primary.main' }}>
        {amountFormat(fuelNonTripPaymentsList?.totalAmount)}
      </Typography>
    );
  };

  const fuelNonTripPaymentsTitleRenderer = (): JSX.Element => {
    return (
      <Grid item>
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          Fuel Payments
        </Typography>
        {isPayItemAllowedToEdit && (
          <Permission
            contains={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_ADD]}
          >
            <Button
              onClick={(event) => {
                event.stopPropagation();
                openTripPaymentModal('FUEL');
              }}
              variant="outlined"
              style={{
                minWidth: 35,
                minHeight: 35,
                marginLeft: 10,
              }}
            >
              <AddIcon color="#2B64CB" />
            </Button>
          </Permission>
        )}
      </Grid>
    );
  };

  const otherNonTripPaymentsLabelRenderer = (): JSX.Element => {
    return (
      <Typography variant="h7" sx={{ color: 'primary.main' }}>
        {amountFormat(otherNonTripPaymentsList?.totalAmount)}
      </Typography>
    );
  };

  const otherNonTripPaymentsTitleRenderer = (): JSX.Element => {
    return (
      <Grid item>
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          Other Payments
        </Typography>
        {isPayItemAllowedToEdit && (
          <Permission
            contains={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_ADD]}
          >
            <Button
              onClick={(event) => {
                event.stopPropagation();
                openTripPaymentModal('OTHER');
              }}
              variant="outlined"
              style={{
                minWidth: 35,
                minHeight: 35,
                marginLeft: 10,
              }}
            >
              <AddIcon color="#2B64CB" />
            </Button>
          </Permission>
        )}
      </Grid>
    );
  };

  const handleSelectAll = (
    paymentList: PaymentList,
    paymentListName: PayStatementsListTypeEnum,
    isChecked: boolean
  ) => {
    if (!paymentList?.content?.length) return;
    const updated = {
      ...paymentList,
      content: paymentList.content?.map((item: PaymentDetails) => {
        return {
          ...item,
          isChecked: isChecked,
        };
      }),
    };

    setValue(paymentListName, updated);

    const ids: number[] = prepareFromList(
      paymentList.content,
      'id'
    ) as number[];

    if (!ids) return;
    onCheckboxChange(isChecked, ids);
  };

  const { data: tripPaymentsListData, scrollRef: tripPaymentsListRef } =
    useScroll(tripPaymentsList?.content);
  const {
    data: fuelNonTripPaymentsListData,
    scrollRef: fuelNonTripPaymentsListRef,
  } = useScroll(fuelNonTripPaymentsList?.content);
  const {
    data: otherNonTripPaymentsListData,
    scrollRef: otherNonTripPaymentsListRef,
  } = useScroll(otherNonTripPaymentsList?.content);

  const isPayItemAllowedToEdit = useMemo(() => {
    return (
      entityType !== PayStatementsEntityTypeEnum.closedStatement &&
      entityType !== PayStatementsEntityTypeEnum.reviewedStatement
    );
  }, [entityType]);

  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <Grid className="PayStatementDetailsForm" container padding={2}>
        {entityType !== PayStatementsEntityTypeEnum.pendingPayment && (
          <Grid container direction={'column'}>
            <Typography
              variant="h7"
              sx={{
                color: 'text.primary',
                marginBottom: '20px',
                borderRadius: '8px',
                marginLeft: '16px',
              }}
            >
              {`Settlement #: ${payStatementData?.seqNumber || '--'}`}
            </Typography>
            <Grid
              sx={{
                minHeight: '40px',
                backgroundColor: 'uncategorized.oldHeaderSecondary',
                marginBottom: '20px',
                borderRadius: '8px',
              }}
            >
              <Typography
                variant="h7"
                sx={{
                  fontSize: '14px',
                  color: 'text.secondary',
                  marginLeft: '16px',
                  verticalAlign: '-webkit-baseline-middle',
                }}
              >
                Pay Period:{' '}
                <b>{`${getFormattedDateForGrid(
                  payStatementData?.startDate,
                  UI_DATE_FORMAT
                )} - ${getFormattedDateForGrid(
                  payStatementData?.endDate,
                  UI_DATE_FORMAT
                )}`}</b>
              </Typography>
              {entityType === PayStatementsEntityTypeEnum.closedStatement &&
                payStatementData?.paymentDate && (
                  <Typography
                    variant="h7"
                    sx={{
                      color: 'text.secondary',
                      fontSize: '14px',
                      marginLeft: '16px',
                      verticalAlign: '-webkit-baseline-middle',
                    }}
                  >
                    Pay Date:{' '}
                    <b>
                      {getFormattedDateForGrid(
                        payStatementData?.paymentDate as string,
                        UI_DATE_FORMAT
                      )}
                    </b>
                  </Typography>
                )}
            </Grid>
          </Grid>
        )}
        {canAddPendingPaymentItem &&
          !DirtyDetailsPanelManager.isDirty &&
          isPayItemActionAllowed(entityType) && (
            <Grid container justifyContent={'end'}>
              <Permission
                contains={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_ADD]}
              >
                <Link
                  variant="h7"
                  sx={{
                    color: 'primary.main',
                    textDecoration: 'underline',
                    whiteSpace: 'pre-wrap',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsAddPendingPaymentModalOpen(true);
                  }}
                >
                  <Grid sx={{ marginRight: '10px' }}>
                    <AddIcon color="#2B64CB" />
                  </Grid>
                  Add Pending Payment Item
                </Link>
              </Permission>
            </Grid>
          )}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <KPIInfoCard
            mainKpi={amountFormat(grossYearToDate)}
            secondaryKpi={
              entityType === PayStatementsEntityTypeEnum.pendingPayment
                ? 'Total YTD'
                : 'Gross Payment YTD'
            }
          />
          <KPIInfoCard
            mainKpi={amountFormat(totalAmount)}
            secondaryKpi={t('totalAmount')}
          />
        </Grid>
        {payStatementData?.payStatementType == PayStatementsTypeEnum.driver && (
          <Box
            sx={{
              marginTop: '20px',
              width: '100%',
              color: 'primary.main',
              fontWeight: '600',
              fontSize: '16px',
            }}
          >
            <Divider sx={{ width: 'inherit', marginTop: '16px' }} />
          </Box>
        )}
        <Box sx={{ marginTop: '20px', width: '100%' }}>
          <AxeleAccordion
            titleRenderer={paymentPerTripTitleRenderer}
            renderLabel={paymentPerTripLabelRenderer}
            borderColor="transparent"
          >
            <Box
              ref={tripPaymentsListRef}
              sx={{
                marginTop: '-10px',
                width: '100%',
                overflow: 'scroll',
                maxHeight: 600,
              }}
            >
              {entityType === PayStatementsEntityTypeEnum.pendingPayment &&
                !!tripPaymentsList?.content?.length && (
                  <Box sx={{ marginBottom: '30px', width: '100%' }}>
                    <Link
                      variant="h7"
                      sx={{
                        color: 'primary.main',
                        textDecoration: 'underline',
                        whiteSpace: 'pre-wrap',
                        alignItems: 'center',
                        marginLeft: '15px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();

                        handleSelectAll(
                          tripPaymentsList,
                          PayStatementsListTypeEnum.TRIP_PAYMENT,
                          true
                        );
                      }}
                    >
                      Select All
                    </Link>
                    <Link
                      variant="h7"
                      sx={{
                        color: 'primary.main',
                        textDecoration: 'underline',
                        whiteSpace: 'pre-wrap',
                        alignItems: 'center',
                        marginLeft: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();

                        handleSelectAll(
                          tripPaymentsList,
                          PayStatementsListTypeEnum.TRIP_PAYMENT,
                          false
                        );
                      }}
                    >
                      Unselect All
                    </Link>
                  </Box>
                )}
              {!!tripPaymentsListData?.length &&
                tripPaymentsListData.map((item: any, index: number) => (
                  <TripPaymentForm
                    disabledCheckbox={noPermissionForEditPayStatement}
                    key={item.id}
                    id={index}
                    isShowPayTo={
                      payStatementData?.payStatementType ==
                      PayStatementsTypeEnum.driver
                    }
                    onCheckboxChange={onCheckboxChange}
                    showCheckbox={
                      entityType === PayStatementsEntityTypeEnum.pendingPayment
                    }
                    onRemoveInReviewPayments={RemoveInReviewPayments}
                    entityType={entityType}
                    isUpdated={isUpdated}
                  />
                ))}
            </Box>
          </AxeleAccordion>
          <Divider sx={{ width: 'inherit', marginTop: '16px' }} />
        </Box>

        {payStatementData.payStatementType !==
          PayStatementsTypeEnum.dispatcher && (
          <>
            <Box sx={{ marginTop: '20px', width: '100%' }}>
              <AxeleAccordion
                titleRenderer={fuelNonTripPaymentsTitleRenderer}
                renderLabel={fuelNonTripPaymentsLabelRenderer}
                borderColor="transparent"
              >
                <Box
                  ref={fuelNonTripPaymentsListRef}
                  sx={{
                    marginTop: '-10px',
                    width: '100%',
                    overflow: 'scroll',
                    maxHeight: 600,
                  }}
                >
                  {entityType === PayStatementsEntityTypeEnum.pendingPayment &&
                    !!fuelNonTripPaymentsList?.content?.length && (
                      <Box sx={{ marginBottom: '30px', width: '100%' }}>
                        <Link
                          variant="h7"
                          sx={{
                            color: 'primary.main',
                            textDecoration: 'underline',
                            whiteSpace: 'pre-wrap',
                            alignItems: 'center',
                            marginLeft: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();

                            handleSelectAll(
                              fuelNonTripPaymentsList,
                              PayStatementsListTypeEnum.FUEL_PAYMENT,
                              true
                            );
                          }}
                        >
                          Select All
                        </Link>
                        <Link
                          variant="h7"
                          sx={{
                            color: 'primary.main',
                            textDecoration: 'underline',
                            whiteSpace: 'pre-wrap',
                            alignItems: 'center',
                            marginLeft: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();

                            handleSelectAll(
                              fuelNonTripPaymentsList,
                              PayStatementsListTypeEnum.FUEL_PAYMENT,
                              false
                            );
                          }}
                        >
                          Unselect All
                        </Link>
                      </Box>
                    )}
                  {fuelNonTripPaymentsListData?.map?.(
                    (item: any, index: number) => (
                      <NonTripPaymentForm
                        disabledCheckbox={noPermissionForEditPayStatement}
                        isFuelPayment
                        staticData={staticData}
                        key={`${item.id}`}
                        id={index}
                        onDeleteNonTripPayment={deleteNonTripPayment}
                        onRemoveInReviewPayments={RemoveInReviewPayments}
                        onCheckboxChange={onCheckboxChange}
                        entityType={entityType}
                        isInEditMode={isPayItemAllowedToEdit}
                        showCheckbox={
                          entityType ===
                          PayStatementsEntityTypeEnum.pendingPayment
                        }
                        payStatementData={payStatementData}
                      />
                    )
                  )}
                </Box>
              </AxeleAccordion>
              <Divider sx={{ width: 'inherit', marginTop: '16px' }} />
            </Box>
            <Box sx={{ marginTop: '20px', width: '100%' }}>
              <AxeleAccordion
                titleRenderer={otherNonTripPaymentsTitleRenderer}
                renderLabel={otherNonTripPaymentsLabelRenderer}
                borderColor="transparent"
              >
                <Box
                  ref={otherNonTripPaymentsListRef}
                  sx={{
                    marginTop: '-10px',
                    width: '100%',
                    overflow: 'scroll',
                    maxHeight: 600,
                  }}
                >
                  {entityType === PayStatementsEntityTypeEnum.pendingPayment &&
                    !!otherNonTripPaymentsList?.content?.length && (
                      <Box sx={{ marginBottom: '30px', width: '100%' }}>
                        <Link
                          variant="h7"
                          sx={{
                            color: 'primary.main',
                            textDecoration: 'underline',
                            whiteSpace: 'pre-wrap',
                            alignItems: 'center',
                            marginLeft: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();

                            handleSelectAll(
                              otherNonTripPaymentsList,
                              PayStatementsListTypeEnum.OTHER_PAYMENT,
                              true
                            );
                          }}
                        >
                          Select All
                        </Link>
                        <Link
                          variant="h7"
                          sx={{
                            color: 'primary.main',
                            textDecoration: 'underline',
                            whiteSpace: 'pre-wrap',
                            alignItems: 'center',
                            marginLeft: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();

                            handleSelectAll(
                              otherNonTripPaymentsList,
                              PayStatementsListTypeEnum.OTHER_PAYMENT,
                              false
                            );
                          }}
                        >
                          Unselect All
                        </Link>
                      </Box>
                    )}
                  {!!otherNonTripPaymentsListData?.length
                    ? otherNonTripPaymentsListData.map(
                        (item: any, index: number) => (
                          <NonTripPaymentForm
                            disabledCheckbox={noPermissionForEditPayStatement}
                            staticData={staticData}
                            key={`${item.id}`}
                            id={index}
                            onDeleteNonTripPayment={deleteNonTripPayment}
                            onRemoveInReviewPayments={RemoveInReviewPayments}
                            onCheckboxChange={onCheckboxChange}
                            entityType={entityType}
                            isInEditMode={isPayItemAllowedToEdit}
                            showCheckbox={
                              entityType ===
                              PayStatementsEntityTypeEnum.pendingPayment
                            }
                            payStatementData={payStatementData}
                          />
                        )
                      )
                    : null}
                </Box>
              </AxeleAccordion>
              <Divider sx={{ width: 'inherit', marginTop: '16px' }} />
            </Box>
          </>
        )}
        {!!createPaymentFormDialogMode && (
          <FormDialog
            data={
              new PaymentDetails(
                {
                  payDate: setUtcDate(),
                  expenseCategoryKey:
                    createPaymentFormDialogMode === 'FUEL' ? 'FUEL' : null,
                },
                staticData
              )
            }
            titleText={
              createPaymentFormDialogMode === 'FUEL'
                ? 'Add Fuel Payments'
                : 'Add Other Payments'
            }
            actionLabel="Add"
            actionLabelId="AddButton"
            open={!!createPaymentFormDialogMode}
            onAction={addNonTripPayment}
            handleClose={closeTripPaymentModal}
            contentRenderer={() => (
              <AddNewPaymentForm
                isFuelPayment={createPaymentFormDialogMode === 'FUEL'}
                entityType={payStatementData?.payStatementType}
                entityId={payStatementData?.payStatementId}
              />
            )}
            validationSchema={
              createPaymentFormDialogMode === 'FUEL'
                ? fuelPaymentsFormValidationSchema
                : otherPaymentsFormValidationSchema
            }
            width={createPaymentFormDialogMode === 'FUEL' ? undefined : 650}
          />
        )}

        {isAddPendingPaymentModalOpen && (
          <AddPendingPaymentDialog
            payStatementData={payStatementData}
            paymentsIds={getPaymentsIds({
              tripPaymentsList,
              fuelNonTripPaymentsList,
              otherNonTripPaymentsList,
            })}
            entityType={PayStatementsEntityTypeEnum.pendingPayment}
            open={isAddPendingPaymentModalOpen}
            staticData={staticData}
            onClose={() => setIsAddPendingPaymentModalOpen(false)}
            onAddPendingPayment={onAddPendingPayment}
          />
        )}
      </Grid>
    );
  }
}

export default memo(PayStatementDetailsForm);
