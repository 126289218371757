import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import RadioGroup from '../../../../common/Ui/RadioGroup';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import TextField from '../../../../common/Ui/TextField/TextField';
import {
  Payment,
  PayStatementsTypeEnum,
  SetSecondaryPanelDataType,
} from '../../../../models';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import ThreeDotMenuIcon from '../../../../ui-kit/components/ThreeDotMenuIcon';
import { contactDeleteAction } from '../../../shared/constants';
import { payingEntityRadioGroupData } from '../../constants';

import { Grid, Link } from '@mui/material';
import {
  getDriverNameListShort,
  tractorList,
} from '../../../../views/finance/expenses/utils';
import { deletePaymentsBulk, getSettlementIdToSow } from '../../utils/utils';

import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import StorageManager from '../../../../StorageManager/StorageManager';
import { amountFormat, isHasPermission } from '../../../../utils';
import {
  calculateNetExpense,
  calculateSettlementAmount,
} from '../../../../utils/expense';
import { ESecondaryDetailsPanelType } from '../../../../views/dispatch/constants/types';
type propsType = {
  id: number;
  isFuelExpense: boolean;
  onPaymentDeleted: (updatedPayments: Payment[]) => void;
  setSecondaryPanelData: React.Dispatch<
    React.SetStateAction<SetSecondaryPanelDataType | null>
  >;
};

function PaymentsListForm({
  id,
  isFuelExpense,
  onPaymentDeleted,
  setSecondaryPanelData,
}: propsType) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { control, watch, setValue } = useFormContext();
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const [
    payments,
    companyExpenseName,
    expenceAmount,
    payCategoryName,
    payCategory,
    paymentType,
    amount,
    settlementStatus,
    settlementId,
    payment,
  ] = watch([
    'payments',
    'companyExpenseName',
    'amount',
    `payments.${id}.payCategoryName`,
    `payments.${id}.payCategory`,
    `payments.${id}.entityType`,
    `payments.${id}.amount`,
    `payments.${id}.settlementStatus`,
    `payments.${id}.settlementId`,
    `payments.${id}`,
  ]);

  const isReadOnly = settlementStatus === 'CLOSED';
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const userStorageData = StorageManager.getUser();
  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const settlementAmount = payments.length
      ? calculateSettlementAmount(payments)
      : 0;

    setValue(
      'finalAmount',
      calculateNetExpense(
        companyExpenseName === 'companyExpense',
        expenceAmount,
        settlementAmount
      )
    );
  }, [amount]);

  const handleMenuItemClick = (
    action: { icon: string; label: string },
    data: any,
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.stopPropagation();
    if (action?.label !== 'Delete') return;
    setIsDeletePopupOpen(true);

    setAnchorEl(null);
  };

  const deletePaymentfromPanel = (): void => {
    const updatedPayments = payments.filter(
      (item: Payment, index: number) => index !== id
    );
    setValue('payments', updatedPayments, { shouldDirty: false });
    onPaymentDeleted(updatedPayments);
  };

  const handleDeletePayment = async (): Promise<void> => {
    if (payment?.expenseId) {
      const deletedIds = await deletePaymentsBulk([payment]);
      if (!deletedIds?.length) return;
    }
    deletePaymentfromPanel();
    setIsDeletePopupOpen(false);
  };

  const noPermissionForEditExpenses = !isHasPermission([
    AXELE_PERMISSION_TYPE.EXPENSE_EDIT,
  ]);
  const noPermissionForDeleteExpense = !isHasPermission([
    AXELE_PERMISSION_TYPE.LOAD_REMOVE,
  ]);

  return (
    <Grid
      container
      justifyContent={'space-between'}
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        borderRadius: '8px',
        padding: '20px 20px 20px 20px',
        marginTop: '20px',
        width: '100%',
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        spacing={0}
        alignItems="center"
        direction="row"
      >
        <Typography variant="h7" sx={{ color: 'primary.main' }}>
          {payCategoryName}
        </Typography>
        <Grid>
          <Grid container alignItems="center" direction="row">
            <Typography
              variant="h7"
              sx={{
                color: 'primary.main',
                marginRight: isReadOnly ? '40px' : 0,
              }}
            >
              {amountFormat(
                payCategory === 'DEDUCTION' ? -Math.abs(amount) : amount
              )}
            </Typography>
            {!isReadOnly && (
              <Permission contains={[AXELE_PERMISSION_TYPE.EXPENSE_REMOVE]}>
                <ThreeDotMenuIcon
                  menuOptions={[{ id, ...contactDeleteAction }]}
                  open={Boolean(anchorEl)}
                  handleMenuClick={handleMenuClick}
                  handleMenuClose={handleMenuClose}
                  anchorEl={anchorEl}
                  handleMenuItemClick={handleMenuItemClick}
                />
              </Permission>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5.7} sx={{ marginBottom: '20px' }}>
        <RadioGroup
          row
          control={control}
          name={`payments.${id}.entityType`}
          defaultValue="DRIVER"
          data={payingEntityRadioGroupData}
          disabled
        />
      </Grid>

      <Grid item xs={5.7} sx={{ marginBottom: '20px' }}>
        <Grid container direction={'column'}>
          <Typography variant="inputLabel" sx={{ color: 'text.secondary' }}>
            {t('paymentStatus')}
          </Typography>
          <Link
            variant="inputText"
            sx={{
              color: DirtyDetailsPanelManager.isDirty
                ? 'text.primary'
                : 'primary.main',
              textDecoration: 'underline',
              whiteSpace: 'pre-wrap',
              cursor: DirtyDetailsPanelManager.isDirty ? 'default' : 'pointer',
            }}
            key={payment.id}
            onClick={(e) => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              e.stopPropagation();
              setSecondaryPanelData({
                type: ESecondaryDetailsPanelType.PAY_STATMENT,
                id: payment.settlementId || payment.id,
                entity: payment?.payToEntityId
                  ? payment?.payToEntityType
                  : payment.paymentTypeName
                  ? payment.paymentTypeName?.toUpperCase?.()
                  : (payment?.entityType as PayStatementsTypeEnum),
                entityId: payment?.payToEntityId
                  ? payment?.payToEntityId
                  : (payment.payingEntityObj?.id as number),
                seqNumber: payment.settlementSeqNumber,
                isGlobal: true,
              });
            }}
          >
            {getSettlementIdToSow(payment)}
          </Link>
        </Grid>
      </Grid>
      {paymentType === 'DRIVER' ? (
        <SingleAutocompleteForm
          name={`payments.${id}.payingEntityObj`}
          label="Driver*"
          fieldName="name"
          getOptions={(name: string, pageNumber: number) =>
            getDriverNameListShort(name, pageNumber)
          }
          control={control}
          disabled={!!settlementId || noPermissionForEditExpenses}
          sizes={{ xs: 5.7 }}
        />
      ) : (
        <SingleAutocompleteForm
          name={`payments.${id}.payingEntityObj`}
          label="Tractor*"
          fieldName="name"
          getOptions={(name: string, pageNumber: number) =>
            tractorList(name, pageNumber, null, ['UNAVAILABLE', 'INACTIVE'])
          }
          control={control}
          sizes={{ xs: 5.7 }}
          disabled={!!settlementId}
        />
      )}
      <TextField
        control={control}
        name={`payments.${id}.amount`}
        label="Amount ($)*"
        disabled={isReadOnly || noPermissionForEditExpenses}
        sizes={{ xs: 5.7 }}
      />
      {!isFuelExpense && (
        <TextField
          control={control}
          name={`payments.${id}.description`}
          label="Description"
          disabled={isReadOnly}
          sizes={{ xs: 12 }}
        />
      )}
      {isDeletePopupOpen && (
        <DeletePopup
          open={isDeletePopupOpen}
          title={t('deletePayment')}
          body={t('deletePaymentConfirmation')}
          onAction={handleDeletePayment}
          onClose={() => setIsDeletePopupOpen(false)}
        />
      )}
    </Grid>
  );
}

export default PaymentsListForm;
