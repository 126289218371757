import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  annotateNameAsync,
  IFuelCardService,
  ServiceError,
} from '../interfaces';
import {
  CreateEfsAccountRequest,
  CreateExpensesRequest,
  DeleteEfsAccountRequest,
  FinishImportRequest,
  GetEfsAccountsRequest,
  GetFuelCardProvidersRequest,
  PullDataRequest,
  UpdateEfsAccountRequest,
} from '@/models/DTOs/FuelCards/Requests';
import {
  FuelCardAccount,
  Provider,
  PullDataResponse,
  TransactionRecord,
} from '@/models/DTOs/FuelCards/FuelCards';

export class FuelCardService extends IFuelCardService {
  @annotateNameAsync
  async getFuelCardProviders(
    requestData: GetFuelCardProvidersRequest
  ): Promise<Provider[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `web/expense/api/v2/fuel-card/providers`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createEfsAccount(
    requestData: CreateEfsAccountRequest
  ): Promise<FuelCardAccount | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `web/expense/api/v2/fuel-card/provider/integration`,
        undefined,
        requestData,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateEfsAccount(
    requestData: UpdateEfsAccountRequest
  ): Promise<FuelCardAccount | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        `web/expense/api/v2/fuel-card/provider/integration`,
        undefined,
        requestData,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getEfsAccounts(
    requestData: GetEfsAccountsRequest
  ): Promise<FuelCardAccount[] | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `web/expense/api/v2/fuel-card/provider/integrations`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async pullData(
    requestData: PullDataRequest
  ): Promise<PullDataResponse | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        `web/expense/api/v2/expense/efs/import/pull`,
        requestData,
        false,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createExpenses(
    requestData: CreateExpensesRequest,
    params: { expenseIdsToCreate: string[] }
  ): Promise<void | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        `web/expense//api/v2/expense/efs/import/create`,
        requestData,
        params
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async finishImport(
    requestData: FinishImportRequest
  ): Promise<void | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        `web/expense/api/v2/expense/efs/import/finish`,
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async deleteEfsAccount(
    requestData: DeleteEfsAccountRequest
  ): Promise<void | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        `web/expense/api/v2/fuel-card/provider/integration`,
        requestData,
        undefined
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
