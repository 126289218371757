export interface ILoadSearchStopModel {
  activity: any;
  stopStatus: any;
  appointmentWindow: any;
  timeOfArrival: any;
  onTimeStatus: any;
  createDate: any;
  shipments: Array<any>;
  id: number;
  refNumber: any;
  sequenceNumber: number;
  location: {
    id: any;
    organizationId: number;
    axeleId: any;
    locationName: any;
    fullAddress: any;
    address: string;
    streetAddress: any;
    streetAddress2: any;
    city: string;
    zipcode: any;
    state: string;
    lat: number;
    lng: number;
    contacts: Array<any>;
    center: {
      lat: number;
      lng: number;
    };
    timezone: any;
    notes: any;
    avgHandlingTime: any;
    recordDate: any;
  };
  contact: any;
  appointmentStartDate: string;
  appointmentEndDate: string;
  isDriverAssistRequired: boolean;
  estimatedActivityDuration: number;
  validationMessage: any;
  estimatedTimeOfArrival: any;
  violation: number;
  actualTimeSpent: number;
  totalMiles: number;
  totalDriveTime: number;
  actualTimeOfArrival: any;
  actualTimeOfCompletion: any;
  loadingStartDate: any;
  loadingEndDate: any;
  estimatedTimeSpent: number;
  totalActualMiles: number;
  totalActualDriveTime: number;
  beforeEvaluationMilage: number;
  notes: any;
  stopCategory: any;
  timezone: any;
  first: any;
  last: any;
  managerName: any;
  managerEmail: any;
  driveTime: number;
  milage: number;
  activityType: string;
  fixedAppointment: boolean;
  stopDetentionTime: any;
}

export interface ILoadSearchLoadDetails {
  referenceId: any;
  trailerTypes: Array<string>;
  commodityData: Array<{
    qty: number;
    packageType: any;
    commodityType: string;
    commodityTypeId: string;
    description: string;
    weight: number;
    length: number;
    width: any;
    height: any;
    volume: any;
    stackable: boolean;
    floorLoad: boolean;
  }>;
  totalWeight: number;
  useFactoring: any;
  factoringId: any;
  factoringInvoiceId: any;
  factoringStatus: any;
  factoringDocumentStatus: any;
  factoringInvoiceUpdateDate: any;
  notes: any;
  factoringNotes: any;
  factoringScheduleId: any;
  factoringChargePercent: any;
  equipmentAdditionalDetails: {
    chassisNumber: any;
    containerNumber: any;
    lowerLimitTemperature: any;
    upperLimitTemperature: any;
    sealNumber: any;
  };
}

export interface ILoadSearchAssignment {
  tractorId: any;
  tractorAxeleId: any;
  tractorModel: any;
  tractorName: any;
  trailerId: any;
  trailerAxeleId: any;
  trailerModel: any;
  trailerName: any;
  assignmentDate: any;
  timestamp: number;
  trailerExplicit: boolean;
  tractorExplicit: boolean;
}
export interface ILoadSearchModel {
  loadAxeleId: string;
  organizationId: number;
  orderAxeleId: any;
  driverId: number;
  driverName: any;
  loadStatus: string;
  trailerId: any;
  loadType: string;
  numberOfStops: any;
  signatureCollectionEnabled: any;
  distanceTraveled: any;
  maxWeight: any;
  estimatedDistanceTraveled: any;
  estimatedTimeToCoverDistance: any;
  defaultDeadhead: any;
  lastLocation: any;
  createDate: any;
  updateDate: any;
  completeDate: any;
  loadCreatorUserId: any;
  loadUpdateUserId: any;
  dispatchRevenue: any;
  stops: Array<ILoadSearchStopModel>;
  totalShipments: any;
  shipments: Array<any>;
  shipmentChanges: any;
  loadDetails: ILoadSearchLoadDetails;
  customerId: any;
  customerContactId: any;
  loadCategory: string;
  concernRaised: boolean;
  revenue: number;
  netRevenue: any;
  actualDispatchedTime: any;
  operationType: string;
  hasBeenCancelled: boolean;
  warning: any;
  assignment: ILoadSearchAssignment;
  seqNumber: any;
  proofOfDeliveryMandatory: boolean;
  billOfLadingMandatory: boolean;
  driverGroupId: any;
  nonInvoiceable: boolean;
  invoice: any;
  connectedLoads: any;
  terminalId: any;
  loadboardSource: any;
  visibilityProvidersIds: {};
  dispatcherName: any;
  dispatcherId: number;
  acceptedByDriver: boolean;
  loadDetentionTime: any;
  nextExpectedDepartureTime: any;
  id: string;
  groupDetails: any;
  dispatcherDetails: any;
  customerDetails: any;
  applicableActions: any;
  terminal: any;
  explicit: boolean;
  lbInternalId: string;
  previousLoadId: any;
  nextLoadId: any;
  loadBoard: string;
  loadBoardSourceId: string;
  originCity: string;
  originState: string;
  originDeadhead: number;
  destinationCity: string;
  destinationState: string;
  destinationDeadhead: number;
  loadPerTruck: number;
  rate: number;
  loadMiles: number;
  equipmentType: string;
  commodityType: string;
  perMileRevenue: number;
  length: number;
  weight: number;
  age: number;
  company: string;
  contactName: string;
  email: any;
  phoneNumber: string;
  comments: string;
  match: number;
  expectedRate: number;
  pessimisticRate: number;
  optimisticRate: number;
  destLoadsPerVan: number;
  currentScore: any;
  daysToPay: any;
  sandboxEta: string;
  numberOfDays: any;
  marketOrigin: any;
  marketDestination: any;
  numberOfCompanies: any;
  numberOfReports: any;
  perHourRevenue: number;
  perDayRevenue: number;
  fixedCost: number;
  variableCost: number;
  variableCostForDeadhead: number;
  estimatedProfit: number;
  revenueSourceType: string;
  dns: Array<{
    equipmentType: string;
    value: number;
  }>;
  mcNumber: string;
  customerPreference: string;
  bookingUrl: any;
  actionData: {};
  bookMarkId: any;
  bidPricing: any;
  loadDuration: number;
}

export interface ILoadSearchLsOutputSelection {
  driverId: number;
  selectedLoadId: string;
  loads: Array<{
    loadId: string;
    type: string;
    operationType: string;
    markers: any;
    status: string;
    stops: Array<{
      stopId: number;
      name: any;
      location: {
        lat: number;
        lon: number;
      };
      address: string;
      timezone: string;
      appointmentWindow: {
        start: string;
        end: string;
      };
      minimumHandlingTime: number;
      assistRequired: boolean;
      type: any;
      markers: any;
      status: string;
      actualArrival: any;
      actualDeparture: any;
    }>;
  }>;
  kpis: {
    milage: number;
    violations: number;
    hos: {
      remainingDuty: number;
      remainingDrive: number;
      remainingWeekly: number;
      remainingBCP: number;
      cumulativeRest: number;
    };
    end: string;
    differencePercentage: number;
    revenue: number;
  };
  tpOutput: {
    tripplans: Array<{
      id: number;
      initialHos: {
        remainingDuty: number;
        remainingDrive: number;
        remainingWeekly: number;
        remainingBCP: number;
        cumulativeRest: number;
      };
      kpis: {
        objectiveValue: number;
        violations: number;
        milage: number;
        driveMinutes: number;
        dutyMinutes: number;
        hos: {
          remainingDuty: number;
          remainingDrive: number;
          remainingWeekly: number;
          remainingBCP: number;
          cumulativeRest: number;
        };
        buffer: {
          initialBuffer: number;
        };
      };
      details: {
        start: string;
        end: string;
      };
      tripHos: Array<{
        start: string;
        end: string;
        eventType: string;
        source: string;
      }>;
      loads: Array<{
        loadId: string;
        details: {
          start: string;
          end: string;
        };
        kpis: {
          violations: number;
          milage: number;
          driveMinutes: number;
          dutyMinutes: number;
          tripMilage: number;
          hos: {
            remainingDuty: number;
            remainingDrive: number;
            remainingWeekly: number;
            remainingBCP: number;
            cumulativeRest: number;
          };
          buffer: {
            initialBuffer: number;
          };
          deadhead: {
            estDutyMinutes: number;
            estDriveMinutes: number;
            start: string;
            end: string;
            milage: number;
          };
        };
        type: string;
        stops: Array<{
          stopId: number;
          details: {
            start: string;
            end: string;
            routeId: string;
            eta: string;
          };
          kpis: {
            volutionMinutes: number;
            earlyVolutionMinutes: number;
            milage: number;
            driveMinutes: number;
            dutyMinutes: number;
            loadMilage: number;
            tripMilage: number;
            hos: {
              remainingDuty: number;
              remainingDrive: number;
              remainingWeekly: number;
              remainingBCP: number;
              cumulativeRest: number;
            };
            buffer: {
              initialBufferMinutes: number;
              lastBufferMinutes: number;
            };
            possibleAppoinmentWindows: Array<{
              start: string;
              end?: string;
            }>;
          };
          type: string;
          markers: any;
          timeline: Array<{
            actionName: string;
            location: {
              lat: number;
              lon: number;
            };
            locationName?: string;
            locationDetails: any;
            nextLocation?: {
              lat: number;
              lon: number;
            };
            nextLocationName?: string;
            start: string;
            end: string;
            duration: number;
            hosEventType: string;
          }>;
        }>;
        operationType: string;
      }>;
    }>;
  };
}

export interface ILoadSearchKpi {
  expectedArrivalTimes: Array<string>;
  violationCount: number;
  revenuePerHour: number;
  appointmentTimes: Array<
    Array<{
      start: string;
      end?: string;
    }>
  >;
  wastedRest: number;
  hosUtilization: number;
  endHosState: {
    remainingDuty: number;
    remainingDrive: number;
    remainingWeekly: number;
    remainingBCP: number;
    cumulativeRest: number;
  };
}
export class AssignLSLoadToDriverResponse {
  creatorId!: number;
  loadId!: string;
  loadStatus!: string;
  seqNumber!: string;
}
export interface ILoadSearchTpKpi {
  stopId: number;
  estimatedTimeOfArrival: string;
  estimatedTimeOfCompletion: string;
  violation: number;
  actualTimeSpent: number;
  totalMiles: number;
  totalDriveTime: number;
  driveTime: number;
  milage: number;
}
export interface ILoadSearchLoadSolutions {
  loadId: string;
  deadhead: number;
  loadedMiles: number;
  stopSolutions: Array<{
    appointmentStartTime: string;
    appointmentEndTime: string;
    mileage: number;
    eta: string;
    etd: string;
    actualArrivalTime: any;
    actualDepartureTime: any;
    appointmentWork: string;
    locationDetails: {
      address: string;
      lat: number;
      lon: number;
    };
    driverAssistRequired: boolean;
    driveDuration: string;
    stopActivityName: string;
  }>;
}
export interface ILoadSearchGetSolutionResponse {
  lsOutput: {
    selections: {
      [key: number]: Array<ILoadSearchLsOutputSelection>;
    };
  };
  kpi: ILoadSearchKpi;
  tpKpi: {
    tpKpi: {
      [key: string]: Array<ILoadSearchTpKpi>;
    };
  };
  selectedLoadKpis: {
    originDeadhead: number;
    loadMiles: number;
    perMileRevenue: number;
    destinationDeadhead: any;
    fixedCost: number;
    variableCost: number;
    estimatedProfit: number;
    perDayRevenue: number;
    revenue: number;
    dns: Array<any>;
    loadDuration: number;
  };
  status: string;
  panelOutput: {
    loadSolutions: Array<ILoadSearchLoadSolutions>;
    loadIndex: number;
    kpis: {
      originDeadhead: number;
      loadMiles: number;
      perMileRevenue: number;
      destinationDeadhead: any;
      fixedCost: number;
      variableCost: number;
      estimatedProfit: number;
      perDayRevenue: number;
      revenue: number;
      dns: Array<any>;
      loadDuration: number;
    };
  };
  lsTripDetails: ILoadSearchTripDetails;
}

export interface ILoadSearchTripDetails {
  organizationId: number;
  id: any;
  seqNumber: any;
  terminal: any;
  prevLoadId: any;
  nextLoadId: any;
  stops: ILoadSearchStop[];
  tripCompleted: boolean;
}

export interface ILoadSearchStop {
  id: any;
  refNumber: string;
  appointmentStartDate: string;
  appointmentEndDate: string;
  estimatedTimeOfArrival: string;
  estimatedTimeOfCompletion: any;
  actualTimeOfArrival: any;
  actualTimeOfCompletion: any;
  departureTime: any;
  arrivalDelay: number;
  dwellTime: number;
  detentionTime: number;
  totalDriveTime: number;
  totalMiles: number;
  location: ILoadSearchLocation;
  locationId: any;
  type: any;
  status: any;
  estimatedActivityDuration: number;
  isDriverAssistRequired: boolean;
  fixedAppointment: boolean;
  activityType: string;
  notes: string;
  contact: ILoadSearchContact;
}

interface ILoadSearchLocation {
  locationName: string;
  city: string;
  state: string;
  country: string;
  fullAddress: string;
  lng: number;
  lat: number;
}

export interface ILoadSearchContact {
  firstname: string;
  lastname: string;
  email: string;
  countryCode: string;
  phone: string;
  extension: string;
}

export interface ILoadSearchInvokeResponse {
  invocationRequestId: string;
  message: string;
  driverId: any;
}

export interface IDriverDataResponse {
  driverName: string | null | undefined;
  driverGroupId: string;
  driverId: Array<number>;
  gapData: Array<IGapData>;
  preferredDriver: boolean;
}

export interface IGapData {
  defaultWeeklyRevenue: number;
  destinationCity: string | null;
  destinationLat: string | null;
  destinationLon: string | null;
  destinationState: string | null;
  gapDurationMins: string | null;
  gapEndTime: string | null;
  gapStartTime: string | null;
  nextLoadId: string | null;
  originCity: string | null;
  originLat: string | null;
  originLng: string | null;
  originState: string | null;
  previousLoadId: string | null;
}

export interface IBookmarkedLoadsResponse {
  dispatcherId: number;
  id: string;
  organizationId: number;
  savedLoad: any;
}
