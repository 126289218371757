import { Box, IconButton, Stack, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { httpClient } from '../../../axios/axiosInstance';
import { QueryParams } from '../../../models';
import { BaseStatusChip } from '../../../common/Ui/StatusComponent/StatusComponent';
import { formatRateMethod } from '../../../components/contract';
import { getDateFromDateObject } from '../../../utils/contract_payload_formatter';
import { DirtyDetailsPanelManager } from '../../../common/DetailsPanel/utils';

const ContractList = ({ data, isSelected }: any) => {
  return (
    <Box
      sx={{
        // width: '100%',
        width: '30rem',
        height: 'fit-content',
        backgroundColor: '#F5F5F5!important',
        borderRadius: '8px',
        p: 1,
        mt: 1,
        border: isSelected && '1px solid #2352A6',
      }}
    >
      <Stack direction="column" spacing={0}>
        <Stack direction={'row'}>
          <Typography
            fontWeight={600}
            fontSize={'16px'}
            sx={{
              color: isSelected ? '#2352A6' : '#040022BF',
            }}
          >
            {data?.name}
          </Typography>
          <Box flexGrow={1}></Box>
          <BaseStatusChip
            config={{
              backgroundColor:
                data?.status === 'ACTIVE' ? '#0C6BB9' : '#B6BDC7',
              color: data?.status === 'ACTIVE' ? '#f1f1f1' : '#212121',
              label: data?.status,
            }}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography
            noWrap
            variant="caption"
            sx={{
              color: isSelected ? '#2352A6' : '#00112299',
            }}
          >
            {`${
              data?.effectiveDate
                ? getDateFromDateObject(data?.effectiveDate)
                : 'NA'
            } - ${data?.expDate ? getDateFromDateObject(data?.expDate) : 'NA'}`}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: isSelected ? '#2352A6' : '#00112299',
            }}
            fontWeight={700}
          >
            Customer:
          </Typography>
          <Typography
            noWrap
            variant="caption"
            sx={{
              color: isSelected ? '#2352A6' : '#00112299',
            }}
          >
            {data?.customerName || 'NA'}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: isSelected ? '#2352A6' : '#00112299',
            }}
            fontWeight={700}
          >
            #Rates:
          </Typography>
          <Typography
            noWrap
            variant="caption"
            sx={{
              color: isSelected ? '#2352A6' : '#00112299',
            }}
          >
            {data?.rateCount || 'NA'}
          </Typography>

          <Typography
            variant="caption"
            noWrap
            sx={{
              color: isSelected ? '#2352A6' : '#00112299',
            }}
            fontWeight={700}
          >
            {data?.rateMethod
              ? formatRateMethod(data?.rateMethod?.split('_')[0])
              : 'NA'}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

const ContractForm = ({ contracts, setContractId, contractId }: any) => {
  const [selectedKey, setSelectedKey] = useState(null);

  return (
    <Box sx={{ width: '100%', pt: 1 }}>
      {contracts?.map((con, key) => {
        return (
          <IconButton
            key={key}
            disableRipple
            onClick={() => {
              if (selectedKey === key) {
                setContractId('');
                return;
              }
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              setSelectedKey(key);
              setContractId(con.id);
            }}
          >
            <ContractList
              isSelected={con?.id === contractId ? true : false}
              data={con}
            />
          </IconButton>
        );
      })}
    </Box>
  );
};

export default ContractForm;
