import React, { useEffect, useState } from 'react';
import { CommodityTypeSummary } from '../models';
import { getCommodityTypes } from '../subPages/loadsList/LoadDetailsPanel/utils';
import { View } from '../types';

export type IHookPageContext = {
  currentView: View;
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
  filters: { [key: string]: any };
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  component: string;
  dictionary: string;
  commodityTypes: CommodityTypeSummary[];
};

export type LoadsearchLaneContextType = IHookPageContext;

export const LoadsearchLaneContext =
  React.createContext<LoadsearchLaneContextType>(
    {} as LoadsearchLaneContextType
  );

const component = 'Loadsearch';
const dictionary = 'loadselect';

export const LoadsearchLaneContextProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [currentView, setCurrentView] = useState<View>({} as View);
  const [filters, setFilters] = useState({});
  const [commodityTypes, setCommodityTypeOptions] = useState<
    CommodityTypeSummary[]
  >([]);

  const getCommodityTypeOptions = async () => {
    const commodityTypes = await getCommodityTypes();
    setCommodityTypeOptions(commodityTypes);
  };

  useEffect(() => {
    getCommodityTypeOptions();
  }, []);

  return (
    <LoadsearchLaneContext.Provider
      value={{
        currentView,
        setCurrentView,
        component,
        dictionary,
        filters,
        setFilters,
        commodityTypes,
      }}
    >
      {children}
    </LoadsearchLaneContext.Provider>
  );
};

export const useLoadsearchLane = () => React.useContext(LoadsearchLaneContext);
