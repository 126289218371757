import { Grid } from '@mui/material';
import { Box } from '@mui/system';

import { Divider } from '../../../../../../../Divider';
import {
  BREAKPOINT_TABLET,
  useThemeResponsive,
} from '../../../../../../../hooks/useThemeResponsive';
import StopDetailsForm from '../../../../../../../LoadSharedComponents/forms/StopDetailsForm';
import StopForm from '../../../../../../../LoadSharedComponents/forms/StopForm';

export const AddOrEditStopForm = ({
  isFirstStop,
  isLastStop,
  stopType,
  isStartEndDateRequired = true,
}: {
  isFirstStop?: boolean;
  isLastStop?: boolean;
  stopType?: string;
  isStartEndDateRequired?: boolean;
}): JSX.Element => {
  const { theme } = useThemeResponsive();
  return (
    <Box
      className="AddOrEditStopForm-root"
      sx={{
        display: 'flex',
        [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
          flexDirection: 'column',
          width: '100%',
        },
      }}
    >
      <Box
        className="StopForm-root"
        sx={{
          width: '50%',
          [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
            width: '100%',
          },
        }}
      >
        <Grid container spacing={2}>
          <StopForm isStatusAvailable={isStartEndDateRequired} />
        </Grid>
      </Box>
      <Divider styles={{ margin: '0px 24px' }} />
      <Box
        className="StopDetailsForm-root"
        sx={{
          width: '50%',
          [theme.breakpoints.down(BREAKPOINT_TABLET)]: {
            width: '100%',
          },
        }}
      >
        <Grid container spacing={2}>
          <StopDetailsForm
            isFirstStop={isFirstStop}
            isLastStop={isLastStop}
            stopType={stopType}
          />
        </Grid>
      </Box>
    </Box>
  );
};
