import TextInput from '../../../ui-kit/components/TextField';

export interface TextInputProps {
  id?: string;
  label?: string;
  variant?: unknown;
  defaultValue?: unknown;
  fullWidth?: boolean;
  rows?: number;
  multiline?: boolean;
  type?: unknown;
  styleProps?: unknown;
  required?: boolean;
  error?: boolean | undefined;
  helperText?: unknown;
  onChange?: unknown;
  InputProps?: unknown;
  onBlur?: unknown;
  autoFocus?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string | null;
}

export const TextField = (props: TextInputProps) => {
  return <TextInput {...props} />;
};
