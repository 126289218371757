import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const darkThemeObject: Theme = {
  palette: {
    mainContainerBG: {
      backgroundColor: '#272727',
    },
    primary: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#476FB2',
      contrast: '#1b1b1b',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #669EFF',
      outlinedHoverBackground: alpha('#669EFF', 0.26),
      outlinedRestingBorder: alpha('#2B64CB', 0.5),
      sideTab: alpha('#669EFF', 0.5),
    },
    error: {
      main: '#F44336',
      dark: '#D32F2F',
      light: '#E57373',
      contrast: '#00000',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #F44336',
      outlinedHoverBackground: alpha('#F44336', 0.26),
      outlinedRestingBorder: alpha('#F44336', 0.5),
      content:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #F44336',
      background:
        'linear-gradient(0deg, rgba(24, 6, 5, 0.9), rgba(24, 6, 5, 0.9)), #F44336',
    },
    warning: {
      main: '#FFA726',
      dark: '#F57C00',
      light: '#FFB74D',
      contrast: '#000000',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #FFA726',
      outlinedRestingBorder: alpha('#FFA726', 0.5),
      outlinedHoverBackground: alpha('#FFA726', 0.26),
      content:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FFA726',
      background:
        'linear-gradient(0deg, rgba(25, 15, 0, 0.9), rgba(25, 15, 0, 0.9)), #FFA726',
    },
    secondary: {
      main: '#09D9E0',
      light: '#95E7EA',
      dark: '#19AFAF',
      contrast: '#000000',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #09D9E0',
      outlinedHoverBackground: alpha('#09D9E0', 0.26),
      outlinedRestingBorder: alpha('#09D9E0', 0.5),
    },
    info: {
      main: '#29B6F6',
      dark: '#0288D1',
      light: '#4FC3F7',
      contrast: '#000000',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #29B6F6',
      outlinedRestingBorder: alpha('#29B6F6', 0.08),
      outlinedHoverBackground: alpha('#29B6F6', 0.5),
      content:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #29B6F6',
      background:
        'linear-gradient(0deg, rgba(3, 14, 24, 0.9), rgba(3, 14, 24, 0.9)), #29B6F6',
    },
    success: {
      main: '#66BB6A',
      dark: '#388E3C',
      light: '#81C784',
      contrast: '#000000',
      containedHoverBackground:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #66BB6A',
      outlinedRestingBorder: alpha('#66BB6A', 0.08),
      outlinedHoverBackground: alpha('#66BB6A', 0.5),
      content:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #66BB6A',
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), #66BB6A',
    },
    text: {
      primary: alpha('#FFFFFF', 0.75),
      secondary: alpha('#FFFFFF', 0.6),
      disabled: alpha('#FFFFFF', 0.35),
    },
    leftMenuCollapsed: {
      axeleIcon: '#FFFFFF',
      inactive: alpha('#FFFFFF', 0.6),
      active: '#669EFF',
      activeBackground:
        'linear-gradient(270deg, rgba(102, 158, 255, 0.27) 0%, rgba(102, 158, 255, 0) 67.19%)',
      canvas: '#3f3f3f',
    },
    leftMenuExpanded: {
      inactive: '#669EFF',
      active: '#FFFFFF',
      primaryInactiveHover: alpha('#FFFFFF', 0.15),
      primaryInactivePressed: alpha('#000000', 0.4),
      primaryActiveNormalGradient:
        'linear-gradient(270deg, rgba(255, 255, 255, 0.27) 0%, rgba(255, 255, 255, 0) 100%)',
      primaryActiveHoverGradient:
        'linear-gradient(270deg, rgba(240, 240, 240, 0.5) 0%, rgba(76, 97, 120, 0.4) 100%)',
      primaryActivePressedGradient:
        'linear-gradient(270deg, rgba(77, 77, 77, 0.27) 0%, rgba(0, 0, 0, 0.2) 100%)',
      secondaryInactiveNormal: alpha('#000000', 0.25),
      secondaryInactiveHover: alpha('#FFFFFF', 0.1),
      secondaryInactivePressed: alpha('#000000', 0.5),
      secondaryActiveNormal:
        'linear-gradient(270deg, rgba(121, 132, 150, 0.5) 0%, rgba(8, 13, 18, 0.4) 100%)',
      secondaryActiveHover:
        'linear-gradient(270deg, rgba(240, 240, 240, 0.4) 0%, rgba(48, 59, 77, 0.4) 100%)',
      secondaryActivePressed:
        'linear-gradient(270deg, rgba(90, 98, 112, 0.5) 0%, rgba(0, 0, 0, 0.4) 100%)',
      panelBorder: alpha('#000000', 0.2),
      panel: alpha('#1C2C3D', 0.89),
      sidePanel: alpha('#000', 0.8),
      selectedTabPanelTab: alpha('#669EFF', 0.12),
    },
    action: {
      active: alpha('#FFFFFF', 0.75),
      hover: alpha('#FFFFFF', 0.08),
      selected: alpha('#FFFFFF', 0.16),
      disabled: alpha('#FFFFFF', 0.3),
      disabledBackground: alpha('#FFFFFF', 0.12),
      focus: alpha('#FFFFFF', 0.12),
    },
    uncategorized: {
      break: '#FF715E',
      netProfit: '#12171F',
      canvas: '#000000',
      canvas_02: '#35446B',
      panel: '#12171F',
      panelTopGradient:
        'linear-gradient(180deg, rgba(41, 54, 68, 0.6) 0%, rgba(41, 54, 68, 0) 61.72%)',
      card: '#1F2E3D',
      tooltip:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #1F2E3D',
      popup: alpha('#030E18', 0.89), //? no value in light
      accordion: alpha('#FFFFF', 0.08), //? no value in light
      oldHeaderSecondary: '#142434',
      headerSecondary: '#0F1A26',
      headerSidePanel: '#35446B',
    },
    divider: alpha('#000000', 0.55),
    other: {
      outlineBorder: '#1F2E3D',
      standartInputLine: '#1F2E3D', //? should be renamed or in light or in dark
      backdropOverlay: alpha('#000000', 0.5),
      ratingActive: '#FFB400',
      snackbarBackground: '#FFFFFF',
      dataGridCellActive:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1F2E3D', // ? no value in light
      default: '#192530',
      liquidNitrogen: '#f1f1f2',
      gridColumnHeaderColor: '#1F2E3D',
      gridScrollbarColor: 'rgba(0, 0, 0, 0.09)',
      gridScrollbarActiveColor: '#878787',
      gridErrorColor: '#f4433614',
      gridWarningColor: '#ed6c0214',
      gridBulkActionDisabled: 'rgba(255, 255, 255, 0.35)',
    },
    tabStrip: {
      tabText: '#FFFFFF',
      actionIcon: alpha('#FFFFFF', 0.6),
      actionPressed:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #669EFF',
      inactiveHover: alpha('#1C2C3D', 0.89),
      inactivePressed: alpha('#669EFF', 0.26),
      addHover: alpha('#669EFF', 0.1),
      addPressed: alpha('#669EFF', 0.5),
      sidePanelTabText: '#FFFFFF',
      sidePanelTabIcon: alpha('#FFFFFF', 0.75),
    },
    grey: {
      a100: '#D5D5D5',
      a200: '#AAAAAA',
      a400: '#616161',
      a700: '#303030',
    },
    chip: {
      availableBg: '#F2984E',
      plannedColor: '#f1f1f2',
      plannedBg: '#5C667D',
      assignedColor: '#f1f1f2',
      assignedBg: '#2F3B58',
      assigned2Bg: '#78EAEE',
      inTransitColor: '#f1f1f2',
      inTransitBg: '#0C6BB9',
      completedBg: '#B6BDC7',
      canceledBg: '#F77B72',
      invoicedBg: '#82C784',
      closedStatementBg: '#9E9E9E',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    buttonLarge: {
      fontFamily: 'Poppins',
      fontSize: '0.9375rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    buttonMedium: {
      fontFamily: 'Poppins',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    buttonSmall: {
      fontFamily: 'Poppins',
      fontSize: '0.8125rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    avatar: {
      fontFamily: 'Poppins',
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    inputLabel: {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    helperText: {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    inputText: {
      fontFamily: 'Poppins',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    tooltip: {
      fontFamily: 'Poppins',
      fontSize: '0.625rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    chip: {
      fontFamily: 'Poppins',
      fontSize: '0.8125rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    tableHeader: {
      fontFamily: 'Poppins',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    alertTitle: {
      fontFamily: 'Poppins',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    badgeLabel: {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    kpiValue: {
      fontFamily: 'Poppins',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.167,
    },
    smallTab: {
      fontFamily: 'Poppins',
      fontSize: '0.6875rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    h7: {
      fontFamily: 'Poppins',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.167,
    },
    h8: {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.167,
    },
    body3: {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    tableFooterTotals: {
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: 1.167,
    },
    body4: {
      fontFamily: 'Poppins',
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: 1.167,
    },
    body2Bold: {
      fontFamily: 'Poppins',
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.167,
    },
  },
};
