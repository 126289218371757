import eventEmitter from 'events';
import BaseEvent from './BaseEvent';

class EventEmitter extends eventEmitter {
  constructor() {
    super();
    this.setMaxListeners(Infinity); // By default, max size of listener (11)
  }
  send(event: BaseEvent) {
    this.emit(event.type, event.data);
  }
}

export default new EventEmitter();
