import { QueryParams } from '../commonMixed/QueryParams';
import { Type } from 'class-transformer';

export class GetAllAlerts extends QueryParams {
  loadId!: string;
  constructor() {
    super();
  }
}

export class CustomerAlertFlagTypesSingleData {
  key!: string;
  value!: string;
  order!: string;
}

export class GetAllAlertsRes {
  @Type(() => CustomerAlertFlagTypesSingleData)
  customerAlertFlagTypes!: CustomerAlertFlagTypesSingleData[];
}

export class GetTrackerListRes {
  id!: string;
  axeleUserId!: number;
  organizationId!: number;
  loadId!: string;
  name!: string;
  email!: string;
  phone!: string;
  emailEnabled!: boolean;
  smsEnabled!: boolean;
  customerAlertFlag!: any;
}

export class GetTrackingURLRes {
  hashKey!: string;
}

export class CreateTrackerDTO extends QueryParams {
  loadId!: string;
  name!: string;
  email!: string;
  phone!: string;
  organizationId!: number;
  axeleUserId!: number;
  id!: string;
  customerAlertFlag!: any;
}

export class GetTrackerListDTO {
  loadId!: string;
}

export class GetContactListDTO extends QueryParams {
  organizationId!: number;
  customerId!: number;
}
