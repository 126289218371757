import React from 'react';
import Typography from '@mui/material/Typography';
import { body2Color, fontFamily, typographyStyles } from '../../constants';
import {
  IMPORT_LOAD_NAME,
  useImport,
} from '../../../../../contexts/ImportContext';

export default function AboutImport() {
  const {
    currentEntity: { name },
  } = useImport();
  return (
    <>
      <Typography variant={'h4'} sx={{ ...typographyStyles, fontSize: 34 }}>
        {`${name} Import`}
      </Typography>
      <Typography
        variant={'body2'}
        sx={{ fontFamily, fontSize: 14, color: body2Color, mt: 2 }}
      >
        {`This lets you import your existing ${name.toLowerCase()}
          data into LoadOps. You can add the data by importing your excel file. We
          will let you map your data while importing to LoadOps ${name.toLowerCase()} fields.
          Additionally you can download the Sample Data from below to see what an
          ideal import file will look like. You can also watch the tutorial for a
          quick overview or you could read the guide for more information.`}
      </Typography>
      {name === IMPORT_LOAD_NAME && (
        <Typography
          variant={'body2'}
          sx={{
            fontFamily,
            fontSize: 14,
            color: body2Color,
            mt: 2,
            fontWeight: 600,
          }}
        >
          Please import Customer, Locations, Drivers, Tractors and Trailers
          before you start Loads Import to ensure those entities are mapped with
          your loads.
        </Typography>
      )}
    </>
  );
}
