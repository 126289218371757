import { Box, Stack, Typography } from '@mui/material';
import { ErrorText } from '../ErrorText';

type AccordionSummaryProps = {
  label: string;
  value: string;
  error?: string;
};

// Accordion Totals Component is used to show a label / value pair at the bottom of an accordion section
// It accepts only those two props and adds default styles such as a gray background and bold text.
const AccordionTotalsSummary = ({
  label,
  value,
  error,
}: AccordionSummaryProps) => {
  return (
    <Box
      sx={{
        backgroundColor: ' #f8f8f8',
        borderRadius: '10px',
        py: '10px',
        px: '20px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {label}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {value}
        </Typography>
      </Stack>
      {error && <ErrorText text={error} />}
    </Box>
  );
};

export default AccordionTotalsSummary;
