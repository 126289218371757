import { Commodity } from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { CommodityType } from './constants';

export const getDimensions = (length: any, width: any, height: any) => {
  let dimensions = '';
  if (length) {
    dimensions += `${length}L`;
  }
  if (width) {
    dimensions += `${dimensions ? ' x ' : ''} ${width}W`;
  }
  if (height) {
    dimensions += `${dimensions ? ' x ' : ''} ${height}H`;
  }
  return dimensions;
};

export const getTotalWeight = (commodityData: Commodity[]) => {
  let total: number | null = null;
  commodityData?.forEach((item: Commodity) => {
    const weight = Number(item.weight);
    if (!isNaN(weight) && item.weight) {
      total = total ? total + weight : weight;
    }
  });
  return total;
};

export const getCommodityTypesNames = (
  commodityData: any,
  commodityTypes: CommodityType[]
) => {
  return commodityData.map((item) => {
    const commodiyType = commodityTypes.find(
      (ct) => ct.id == item.commodityTypeId
    );
    if (commodiyType) {
      return {
        ...item,
        itemName: commodiyType.itemName,
      };
    }

    return item;
  });
};
