import React, { ReactElement } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  TextField,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface StateZoneSelectProps<TOption> {
  value?: TOption[];
  options?: TOption[];
  onChange?: AutocompleteProps<
    TOption,
    boolean,
    undefined,
    boolean
  >['onChange'];
  label?: string;
  keyName: keyof TOption;
}

export interface IZoneValue {
  title: string;
  value: string;
}

export interface IStateValue {
  title: string;
}
const StateZoneSelect = <TOption extends { title: string }>({
  options,
  onChange,
  label,
  value,
  keyName,
}: StateZoneSelectProps<TOption>): ReactElement | null => {
  return (
    <Autocomplete<TOption, boolean, undefined, boolean>
      value={value}
      multiple
      freeSolo
      clearOnEscape
      onChange={onChange}
      disableCloseOnSelect
      getOptionLabel={(option) => (option as IStateValue)?.title}
      options={options || []}
      sx={{
        width: '100%',
      }}
      autoComplete
      includeInputInList
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" />
      )}
      isOptionEqualToValue={(option, value) => {
        return option[keyName] === value[keyName];
      }}
    />
  );
};

export default StateZoneSelect;
