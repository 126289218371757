import React, { FC, PropsWithChildren } from 'react';
import { SxProps, Typography } from '@mui/material';

const InputLabel: FC<PropsWithChildren<{ sx?: SxProps }>> = ({
  children,
  sx = {},
}) => {
  if (!children) return null;
  return (
    <Typography
      variant="h7"
      component="div"
      sx={{ color: 'primary.main', pb: 1, ...sx }}
    >
      {children}
    </Typography>
  );
};

export default InputLabel;
