import {
  Box,
  Button,
  ButtonGroup,
  InputAdornment,
  Theme,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EquipmentImportExportOptions,
  addLoadPageLoadDetails,
} from '../../../common/PendoClassIDs/constants';
import { useCommodityTypes } from '../../../contexts/CommodityTypeContext';
import { OptionType } from '../../../models';
import { LoadReeferModeOptions } from '../../../subPages/loadsList/CreateLoad/constants/fieldOptions';
import { ReeferModeEnum } from '../../../subPages/loadsList/CreateLoad/schema/load';
import {
  ReeferModeButtonGroup,
  ReeferModeButtonSelectedStyles,
  ReeferModeButtonStyles,
} from '../../../subPages/loadsList/CreateLoad/style';
import { loadTypeOptions } from '../../../subPages/loadsList/LoadDetailsPanel/constants/constants';
import { LoadSummary } from '../../../subPages/loadsList/models/LoadSummary';
import { MultipleAutocompleteForm } from '../../Ui/MultipleAutocomplete';
import Select from '../../Ui/Select';
import { SingleAutocompleteForm } from '../../Ui/SingleAutocomplete';
import TextField from '../../Ui/TextField/TextField';
import { useLoadPermission } from '../../hooks/useLoadPermission';
import { useThemeResponsive } from '../../hooks/useThemeResponsive';
import { equipmentFormRequiredFields } from '../constants';
import { EquipmentFormRequiredFieldsType } from '../types';
import { getDefaultCommodity } from '../utils';
import DatePicker from '../../../common/Ui/FormDatePicker/FormDatePicker';

export default function EquipmentForm({
  isViewMode = false,
  requiredFields = { ...equipmentFormRequiredFields },
  equipmentTypeOptions = [],
  load,
}: {
  isViewMode?: boolean;
  requiredFields?: EquipmentFormRequiredFieldsType;
  equipmentTypeOptions: OptionType[];
  load: LoadSummary;
}) {
  const { isMobile } = useThemeResponsive();
  const { commodityTypes } = useCommodityTypes();
  const { control, watch, setValue } = useFormContext();
  const [equipmentTypes, commodityData, reeferMode, mode] = watch([
    'equipmentTypes',
    'commodityData',
    'reeferMode',
    'mode',
  ]);

  const chassisContainerSelected = !!equipmentTypes?.find(
    (item) => item.key === 'Chassis_Container'
  );
  const loadEquipmentTypeOptions = chassisContainerSelected
    ? equipmentTypeOptions.filter((type) => type.key === 'Chassis_Container')
    : equipmentTypeOptions;
  const reeferSelected = !!equipmentTypes?.find(
    (item) => item.key === 'Reefer'
  );

  const handleEquipmentChange = (data) => {
    const chassisContainerTypes = data?.filter(
      (item) => item.key === 'Chassis_Container'
    );
    if (chassisContainerTypes?.length) {
      setValue('equipmentTypes', chassisContainerTypes);
    }
  };

  const handleTotalWeightChange = (value: string) => {
    if (!value) {
      setValue('commodityData', []);
    } else if (!isNaN(parseInt(value, 10))) {
      if (!commodityData?.length) {
        setValue('commodityData', [
          {
            ...getDefaultCommodity(commodityTypes),
            weight: value,
          },
        ]);
      } else {
        setValue('commodityData', [{ ...commodityData[0], weight: value }]);
      }
    }
  };
  const { hasLoadEditPermission } = useLoadPermission();
  const isFieldReadOnly = isViewMode || !hasLoadEditPermission;
  const { t, ready } = useTranslation();

  if (ready) {
    return (
      <>
        <MultipleAutocompleteForm
          id={addLoadPageLoadDetails + 'RequiredEquipmentType'}
          control={control}
          name="equipmentTypes"
          fieldName="value"
          options={loadEquipmentTypeOptions}
          label={t('TripAddNewLoadOption4RequiredEquipmentType')}
          required={requiredFields.equipmentTypes}
          variant="standard"
          sizes={{ xs: isMobile ? 12 : 6 }}
          onChangeCb={handleEquipmentChange}
          disabled={isFieldReadOnly}
        />

        <TextField
          id={addLoadPageLoadDetails + 'Seal'}
          control={control}
          name="sealNumber"
          label={t('TripAddNewLoadOption4Seal')}
          sizes={{ xs: isMobile ? 12 : 6 }}
          disabled={isFieldReadOnly}
        />

        {chassisContainerSelected && (
          <TextField
            id={addLoadPageLoadDetails + 'Chassis'}
            control={control}
            name="chassisNumber"
            label="Chassis #"
            sizes={{ xs: isMobile ? 12 : 6 }}
            disabled={isFieldReadOnly}
          />
        )}
        {chassisContainerSelected && (
          <TextField
            id={addLoadPageLoadDetails + 'Container'}
            control={control}
            name="containerNumber"
            label="Container #"
            sizes={{ xs: isMobile ? 12 : 6 }}
            disabled={isFieldReadOnly}
          />
        )}
        {chassisContainerSelected && (
          <SingleAutocompleteForm
            control={control}
            fieldValue="key"
            fieldName="label"
            name="loadDirection"
            label="Import/Export"
            options={EquipmentImportExportOptions}
            wrapperStyles={{ paddingRight: '2px' }}
          />
        )}
        {chassisContainerSelected && (
          <TextField
            id={addLoadPageLoadDetails + 'Booking '}
            control={control}
            name="bookingNumber"
            label="Booking #"
            sizes={{ xs: isMobile ? 12 : 6 }}
            disabled={isFieldReadOnly}
          />
        )}
        {chassisContainerSelected && (
          <TextField
            id={addLoadPageLoadDetails + 'Appointment'}
            control={control}
            name="appointmentNumber"
            label="Appointment #"
            sizes={{ xs: isMobile ? 12 : 6 }}
            disabled={isFieldReadOnly}
          />
        )}
        {reeferSelected && (
          <Box display={'block'} ml={2}>
            <Typography variant="body2" ml={1}>
              Reefer Mode
            </Typography>
            <ButtonGroup
              variant="outlined"
              aria-label="reefer-mode"
              sx={ReeferModeButtonGroup}
            >
              {LoadReeferModeOptions.map((e) => (
                <Button
                  key={e.id}
                  onClick={() => {
                    if (e?.id === 'OFF') {
                      setValue('temperature', null);
                      setValue('variance', null);
                    }
                    setValue('reeferMode', e.id, { shouldDirty: true });
                  }}
                  sx={(theme: Theme) =>
                    reeferMode == e.id
                      ? ReeferModeButtonSelectedStyles(theme)
                      : ReeferModeButtonStyles(theme)
                  }
                  variant={reeferMode == e.id ? 'contained' : 'outlined'}
                >
                  {e.label}
                </Button>
              ))}
            </ButtonGroup>
            <br />
            {reeferMode === ReeferModeEnum.OFF && (
              <Typography
                variant="caption"
                color={'textSecondary'}
                mt={'auto'}
                mb="auto"
              >
                (Reefer is being used as a container only)
              </Typography>
            )}
          </Box>
        )}
        {reeferSelected && reeferMode !== ReeferModeEnum.OFF && (
          <TextField
            id={addLoadPageLoadDetails + 'temp'}
            control={control}
            name="temperature"
            label="Temp"
            sizes={{ xs: isMobile ? 12 : 6 }}
            disabled={isFieldReadOnly}
            InputProps={{
              endAdornment: <InputAdornment position="end">F</InputAdornment>,
            }}
          />
        )}
        {reeferSelected && reeferMode !== ReeferModeEnum.OFF && (
          <TextField
            id="temp-variance"
            label="Set +/-"
            control={control}
            name="variance"
            sizes={{ xs: isMobile ? 12 : 6 }}
            disabled={isFieldReadOnly}
            InputProps={{
              endAdornment: <InputAdornment position="end">F</InputAdornment>,
            }}
          />
        )}
        <TextField
          id={addLoadPageLoadDetails + 'Weight'}
          control={control}
          name="totalWeight"
          label={t('TripAddNewLoadOption4Weight')}
          disabled={isFieldReadOnly || commodityData?.length > 1}
          onChangeCallback={handleTotalWeightChange}
          onlyNumbers
          sizes={{ xs: isMobile ? 12 : 6 }}
        />
        <Select
          id={addLoadPageLoadDetails + 'LoadType'}
          control={control}
          name="loadType"
          label={t('TripAddNewLoadOption4LoadType')}
          options={loadTypeOptions}
          fieldName="value"
          fieldValue="key"
          sizes={{ xs: isMobile ? 12 : 6 }}
          disabled={
            isFieldReadOnly ||
            Boolean(
              load?.loadType === 'LTL' && load?.manifestBaseDetails?.manifestId
            )
          }
          required={requiredFields.loadType}
        />
        <TextField
          id={addLoadPageLoadDetails + 'NotesForDispatching'}
          control={control}
          name="notes"
          label={t('TripAddNewLoadOption4NotesForDispatching')}
          sizes={{ xs: 12 }}
          disabled={isFieldReadOnly}
        />

        <SingleAutocompleteForm
          control={control}
          name="mode"
          label="Mode"
          options={[
            { value: 'Drayage', key: 'Drayage' },
            { value: 'TL', key: 'TL' },
          ]}
          wrapperStyles={{ paddingRight: '2px' }}
          fieldName="value"
          fieldValue="key"
          sizes={{ xs: 6 }}
        />

        {mode === 'Drayage' && (
          <>
            <TextField
              id={addLoadPageLoadDetails + 'serviceType'}
              control={control}
              name="serviceType"
              label={'Service Type'}
              sizes={{ xs: 6 }}
            />

            <TextField
              id={addLoadPageLoadDetails + 'steamShipLine'}
              control={control}
              name="steamShipLine"
              label={'Steamship Line'}
              sizes={{ xs: 6 }}
            />
            <TextField
              id={addLoadPageLoadDetails + 'containerTerminal'}
              control={control}
              name="containerTerminal"
              label={'Terminal info'}
              sizes={{ xs: 6 }}
            />
            <TextField
              id={addLoadPageLoadDetails + 'holdCategory'}
              control={control}
              name="holdCategory"
              label={'Holds Category'}
              sizes={{ xs: 6 }}
            />
            <TextField
              id={addLoadPageLoadDetails + 'holdType'}
              control={control}
              name="holdType"
              label={'Holds Type'}
              sizes={{ xs: 6 }}
            />
            <TextField
              id={addLoadPageLoadDetails + 'yardLocation'}
              control={control}
              name="yardLocation"
              label={'Yard Location'}
              sizes={{ xs: 6 }}
            />
            <DatePicker
              styleProps={{
                width: 1,
              }}
              label={'Last Free Day'}
              control={control}
              name="lastFreeDay"
              sizes={{ xs: 6 }}
            />
            <DatePicker
              styleProps={{
                width: 1,
              }}
              label={'Perdiem Start Date'}
              control={control}
              name="perdiemStartDate"
              sizes={{ xs: 6 }}
            />
            <DatePicker
              styleProps={{
                width: 1,
              }}
              label={'Full outgate date'}
              control={control}
              name="fullOutgateDate"
              sizes={{ xs: 6 }}
            />
            <TextField
              id={addLoadPageLoadDetails + 'holdCategory'}
              control={control}
              name="inPin"
              label={'In Pin'}
              sizes={{ xs: 6 }}
            />
            <TextField
              id={addLoadPageLoadDetails + 'holdType'}
              control={control}
              name="outPin"
              label={'Out Pin'}
              sizes={{ xs: 6 }}
            />
          </>
        )}
      </>
    );
  }
}
