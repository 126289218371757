import React, { useEffect, useState } from 'react';
import { AssetsCount } from '../models';
import TractorController from '../subPages/tractors/Controller';
import { View } from '../types';

type AssetCountUndefined = AssetsCount | undefined;

export type EquipmentContextType = {
  currentView: View;
  setCurrentView: React.Dispatch<React.SetStateAction<View>>;
  addNewClicked: boolean;
  setAddNewClicked: React.Dispatch<React.SetStateAction<boolean>>;
  component: string;
  equipmentCount: AssetCountUndefined;
  setEquipmentCount: React.Dispatch<React.SetStateAction<AssetCountUndefined>>;
  dictionary: string;
};

export const EquipmentContext = React.createContext<EquipmentContextType>(
  {} as EquipmentContextType
);
const component = 'Equipment';
const dictionary = 'asset';

export const EquipmentContextProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [currentView, setCurrentView] = useState<View>({} as View);
  const [addNewClicked, setAddNewClicked] = useState<boolean>(false);
  const [equipmentCount, setEquipmentCount] = useState<AssetCountUndefined>();

  useEffect(() => {
    TractorController.instance().getAssetsCount((count) => {
      setEquipmentCount(count);
    });
  }, []);

  return (
    <EquipmentContext.Provider
      value={{
        currentView,
        setCurrentView,
        addNewClicked,
        setAddNewClicked,
        component,
        equipmentCount,
        setEquipmentCount,
        dictionary,
      }}
    >
      {children}
    </EquipmentContext.Provider>
  );
};

export const useEquipment = () => React.useContext(EquipmentContext);
