import React, { useEffect, useMemo, useState } from 'react';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import TabsV2, { ITabV2 } from '../../../../ui-kit/components/TabsV2';
import { EEDITenderPanelTabs } from '../../constants/detailsPanel';

export interface EDITenderPanelTab extends ITabV2 {
  key: EEDITenderPanelTabs;
}

const getTabItems = (): EDITenderPanelTab[] => {
  return [
    {
      key: EEDITenderPanelTabs.TRIP_PLAN,
      label: 'Trip Plan',
      iconName: 'MapOutlined',
      source: 'MUIcons',
    },
    {
      key: EEDITenderPanelTabs.DETAILS,
      label: 'Details',
      iconName: 'InfoOutlined',
      source: 'MUIcons',
    },
  ];
};

const DetailsTabStrips: React.FC<{
  defaultTab?: EEDITenderPanelTabs;
  onChange: (selected: EEDITenderPanelTabs) => void;
  keys: EEDITenderPanelTabs[];
}> = ({ onChange, defaultTab, keys }) => {
  const tabItems = useMemo(() => {
    return getTabItems();
  }, [keys]);

  const getTab = (tabKey?: EEDITenderPanelTabs) =>
    tabItems.find(({ key }) => key === tabKey) || tabItems[0];

  const [selected, setSelected] = useState(getTab(defaultTab));

  useEffect(() => {
    setSelected(getTab(defaultTab));
  }, [defaultTab]);

  const handleStripChange = (selected: EDITenderPanelTab) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    setSelected(selected);
    onChange(selected.key);
  };

  return (
    <TabsV2<'key', EDITenderPanelTab>
      keyName="key"
      tabs={tabItems}
      setSelectedTab={(tab) => {
        handleStripChange(tab);
      }}
      selectedTab={selected.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

const getTabByKey = (
  key: EEDITenderPanelTabs
): EDITenderPanelTab | undefined => {
  return getTabItems().find((tab) => tab.key === key);
};

export const tabStripRendererByKeyFS = (key: EEDITenderPanelTabs) => {
  return (
    <TabsV2<'key', EDITenderPanelTab>
      keyName="key"
      tabs={[getTabByKey(key)].filter(Boolean) as EDITenderPanelTab[]}
      selectedTab={getTabByKey(key)?.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

export default DetailsTabStrips;
