import { lowerCase } from 'lodash';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { terminalService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { useTerminalPermission } from '../../../../../common/hooks/useTerminalPermission';
import { TerminalContentDTO } from '../../../../../models';
import { UpdateTerminalModeRequest } from '../../../../../models/DTOs/Terminal/Requests';
import {
  RootStoreInstence,
  useRootStore,
} from '../../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../../store/root.context';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import { LongMenu } from '../../../../../ui-kit/components/LongMenu';
import { ETerminal3DotMenuType } from '../../constants/types';
import { deleteTerminalHandler } from '../../utils/api.utils';

interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}
interface TerminalDetailsPanelActionsSectionProps {
  on3DotMenuActionCallback?: (params: {
    type: ETerminal3DotMenuType;
    data: TerminalContentDTO;
    response: any;
  }) => void | Promise<void>;
  terminalDTO: TerminalContentDTO;
}
export const TerminalDetailsPanelActionsSection = observer(
  ({
    terminalDTO,
    on3DotMenuActionCallback,
  }: TerminalDetailsPanelActionsSectionProps) => {
    const { setTerminalEnabled } = useRootStore();
    const {
      terminalStore: { tableList },
    } = useStores();
    const [current3DotMenu, setCurrent3DotMenu] =
      useState<ETerminal3DotMenuType | null>(null);

    const onCloseMenu = () => {
      setCurrent3DotMenu(null);
    };

    const options: Action[] = [];

    const { hasTerminalRemovePermission } = useTerminalPermission();

    const actionName = terminalDTO.isPrimary ? 'Disable' : `Delete`;
    if (hasTerminalRemovePermission) {
      options.push({
        name: actionName,
        action: (): void => {
          setCurrent3DotMenu(ETerminal3DotMenuType.DELETE);
        },
      });
    }

    const validateDelete = (): boolean => {
      if (terminalDTO.isPrimary) {
        if (tableList.length <= 1) {
          return true;
        } else {
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            serviceName: 'diabledTerminalValidation',
          });
          return false;
        }
      } else {
        return true;
      }
    };

    const onDeleteTerminalHandler = async () => {
      onCloseMenu();
      if (!validateDelete()) return false;

      if (terminalDTO.isPrimary) {
        const payload = new UpdateTerminalModeRequest(false);
        await terminalService.updateTerminalMode(payload);
        setTerminalEnabled(false);
        localStorage.setItem('terminalEnabled', JSON.stringify(false));
      } else {
        try {
          const result = await deleteTerminalHandler(terminalDTO.id);
          if (!(result instanceof ServiceError)) {
            on3DotMenuActionCallback?.({
              type: ETerminal3DotMenuType.DELETE,
              data: terminalDTO,
              response: null,
            });
          }
        } catch (error) {}
      }
    };

    return (
      <>
        <LongMenu color={'primary.contrast'} options={options} />
        {current3DotMenu === ETerminal3DotMenuType.DELETE && (
          <DeletePopup
            open={true}
            title={`${actionName} Terminal?`}
            body={`Are you sure you want to ${lowerCase(
              actionName
            )} the terminal?`}
            onAction={onDeleteTerminalHandler}
            onClose={onCloseMenu}
            buttonText={actionName}
          />
        )}
      </>
    );
  }
);
