import { SxProps, Theme } from '@mui/material';
import React from 'react';
import { CommonExpandableTimelineContentProps } from '../../../../AxeleTimeline';
import {
  ExpandableTimelineV3EditIconEvents,
  StopSolutionV3Prop,
} from '../../types';
import { ExpandableTimelineV3 } from '../CustomizeTimeline/ExpandableTimelineV3';

export interface TripContentProps extends ExpandableTimelineV3EditIconEvents {
  timelineContent: CommonExpandableTimelineContentProps<StopSolutionV3Prop>[];
  isMultipleExpanded?: boolean;
  sx?: SxProps<Theme>;
}

export const TripContent = ({
  timelineContent,
  isMultipleExpanded,
  sx = {},
}: TripContentProps): JSX.Element => {
  return (
    <ExpandableTimelineV3
      timelineContent={timelineContent}
      isMultipleExpanded={isMultipleExpanded}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...sx,
      }}
    />
  );
};
