export enum EventTypes {
  TRIP_ASSIGNMENT_CHANGE = 'TRIP_ASSIGNMENT_CHANGE',
  AUTH_ERROR = 'AUTH_ERROR',
  LOAD_TRIP_ACTION = 'LOAD_TRIP_ACTION',
  TRIP_STOP_ACTION = 'TRIP_STOP_ACTION',
  UPDATE_PROGRESS = 'UPDATE_PROGRESS',
  PANEL_SHOULD_SHAKE = 'PANEL_SHOULD_SHAKE',
  LOAD_OVERVIEW_CHANGE = 'LOAD_OVERVIEW_CHANGE',
  HOLD_LOAD = 'HOLD_LOAD',
  PERMISSION_CHANGED = 'PERMISSION_CHANGED',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
}
