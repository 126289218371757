import { httpClient } from '../../axios/axiosInstance';
import {
  ContractDetailsDTO,
  ContractListData,
  GetBestMatchContractParams,
  GetContractOneRequest,
  QueryParams,
} from '../../models';
import { IContactsService } from '../interfaces';

const contractList = '/web/location/api/v2/contact/view/count/total';
const contractNames = '/web/location/api/v2/contact/view/count/total';
const contractDetails = '/web/customer/api/v2/contracts/get';
const contractBestMatches = '/web/customer/api/v2/contracts/best-matching-lane';

export class ContractService extends IContactsService {
  async getContractList(requestData: QueryParams) {
    try {
      return await httpClient.get<ContractListData>(
        contractList,
        requestData,
        ContractListData,
        true,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  async getContractListName(requestData: QueryParams) {
    try {
      return await httpClient.get<ContractListData>(
        contractNames,
        requestData,
        ContractListData,
        true,
        true
      );
    } catch (error) {
      throw error;
    }
  }
  async getBestMatchContract(payload: GetBestMatchContractParams) {
    try {
      return await httpClient.put<ContractDetailsDTO>(
        contractBestMatches,
        undefined,
        payload,
        ContractDetailsDTO,
        false,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  async getContractById(requestData: GetContractOneRequest) {
    try {
      return await httpClient.get<ContractDetailsDTO>(
        contractDetails,
        requestData,
        ContractDetailsDTO,
        true,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  async getContractByIdWithoutCancel(requestData: GetContractOneRequest) {
    return await httpClient.get<ContractDetailsDTO>(
      contractDetails,
      requestData,
      ContractDetailsDTO,
      false,
      true
    );
  }
}
