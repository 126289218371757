import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';

interface columnProps {
  icon?: JSX.Element;
  text?: string;
  textStyles?: object;
  containerStyles?: SxProps;
}

const SingleLineColumn: React.FC<columnProps> = (props) => {
  const { icon, text, textStyles, containerStyles = {} } = props;
  return (
    <Box
      display={'flex'}
      key={text}
      alignItems={'center'}
      gap={'2px'}
      marginRight="4px"
      sx={containerStyles}
    >
      {icon && icon}
      <Typography
        sx={{
          fontSize: '14px',
          letterSpacing: '0.15px',
          color: 'rgba(0, 17, 34, 0.75)',
          width: '100%',
          textAlign: 'start',
          ...textStyles,
        }}
      >
        {text ? text : ' -'}
      </Typography>
    </Box>
  );
};

export default SingleLineColumn;
