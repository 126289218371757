import { Box, Grid, LinearProgress, Typography, useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import React from 'react';
import {
  PaymentStatusEnum,
  SETTLEMENT_CREATED_ENUMS,
} from '../../../../models';
import ThreeDotMenuIcon from '../../../../ui-kit/components/ThreeDotMenuIcon';
import { amountFormat } from '../../../../utils';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../Permission';
import { useLoadPermission } from '../../../hooks/useLoadPermission';
import FormContext, {
  getPayTypeIdListName,
} from './FinanceOverviewFormContext';
import { paymentDetailsType } from './FinanceOverviewModel';
import {
  FinanceTripPaymentWithLoader,
  financeStyle,
} from './FinanceOverviewStyle';
type FinanceOverviewTripType = {
  data: paymentDetailsType;
  onPaymentUpdate?: () => void;
  onPaymentDelete?: () => void;
  showLoader?: boolean;
};
type tplotOptions = {
  [key: string]: string;
};

export const FinancePaymentStatusChip = ({
  paymentStatus,
}: {
  paymentStatus: string;
}) => {
  const textColorMapping: tplotOptions = {
    None: 'common.black',
    Pending: 'common.black',
    'In Review': 'common.white',
    Reviewed: 'common.white',
    Closed: 'common.white',
  };
  const backgroundColorMapping: tplotOptions = {
    None: 'action.disabledBackground',
    Pending: 'action.disabledBackground',
    'In Review': 'warning.light',
    Reviewed: 'warning.light',
    Closed: 'success.main',
  };
  const backgroundColor: string = backgroundColorMapping[paymentStatus];
  const textColor: string = textColorMapping[paymentStatus];

  return (
    <Badge
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        ml: '6px',
        mt: '-4px',
        padding: '4px 10px',
        borderRadius: '16px',
        fontSize: '13px',
        fontWeight: '400',
        backgroundColor: backgroundColor,
        color: textColor,
      }}
    >
      {paymentStatus}
    </Badge>
  );
};

export const FinanceOverviewTrip = ({
  data,
  onPaymentUpdate,
  onPaymentDelete,
  showLoader,
}: FinanceOverviewTripType) => {
  const theme = useTheme();
  const { allPaymentTypes } = React.useContext(FormContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const paymentTypeName = getPayTypeIdListName(
    data?.payTypeIdList ? data.payTypeIdList : [],
    allPaymentTypes
  );

  const {
    hasLoadPaymentEditPermission,
    hasLoadPaymentDeletePermission,
    hasPaymentInReviewToClosedChangePermission,
  } = useLoadPermission();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePaymentActions = (data: any, accountData: any, event: any) => {
    if (data?.key === 'EDIT') {
      onPaymentUpdate?.();
    } else {
      onPaymentDelete?.();
    }
    setAnchorEl(null);
  };

  const renderPaymentUpdateOptions = () => {
    const options = [];
    let permissionToCheckForEdit = hasLoadPaymentEditPermission;
    let permissionToCheckForDelete = hasLoadPaymentDeletePermission;
    if (
      SETTLEMENT_CREATED_ENUMS.includes(
        data?.paymentStatus as PaymentStatusEnum
      )
    ) {
      permissionToCheckForEdit = hasPaymentInReviewToClosedChangePermission;
      permissionToCheckForDelete = hasPaymentInReviewToClosedChangePermission;
    }
    if (permissionToCheckForEdit) options?.push({ label: 'Edit', key: 'EDIT' });
    if (
      permissionToCheckForDelete &&
      data?.paymentStatus !== PaymentStatusEnum.CLOSED
    )
      options?.push({ label: 'Delete', key: 'DELETE' });
    if (!options?.length) return <></>;
    return (
      <ThreeDotMenuIcon
        menuOptions={options}
        open={Boolean(anchorEl)}
        handleMenuClick={handleMenuClick}
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
        handleMenuItemClick={handlePaymentActions}
        menuStyles={{
          '.MuiPaper-root': { borderRadius: '8px' },
        }}
      />
    );
  };

  return (
    <>
      {showLoader && (
        <Box sx={{ width: '100%', padding: '0 25px 0 10px' }}>
          <LinearProgress sx={{ height: '3px' }} />
        </Box>
      )}
      <Grid
        className="FinanceOverviewTrip"
        sx={{
          ...financeStyle.FinanceOverviewTrip,
          ...(showLoader ? FinanceTripPaymentWithLoader : {}),
        }}
      >
        <Box
          sx={financeStyle.FinanceOverviewTripLeft}
          onClick={onPaymentUpdate}
        >
          <Typography variant="h6">
            {data.name ? data.name : ''}{' '}
            {data.paymentStatus != 'None' && (
              <FinancePaymentStatusChip paymentStatus={data.paymentStatus} />
            )}
          </Typography>

          <Box sx={{ display: 'flex' }}>
            <Typography
              className="whiteSpace"
              variant="subtitle1"
              sx={financeStyle.whiteSpace}
            >
              {data.type} {paymentTypeName != '' && ' (' + paymentTypeName}
            </Typography>
            <Typography
              className="whiteSpaceClose"
              sx={financeStyle.whiteSpaceClose}
            >
              {paymentTypeName != '' && ' )'}
            </Typography>
          </Box>
        </Box>
        <Box className="totalCount" sx={financeStyle.totalCount}>
          <Permission
            contains={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_PAYMENT_ADD]}
          >
            <Typography
              sx={{
                color:
                  data.amount > 0
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                fontSize: '24px',
                fontWeight: '400',
              }}
            >
              {data.paymentId
                ? (data.amount > 0 ? '-' : '') +
                  `${amountFormat(data.amount || 0)}`
                : ''}
            </Typography>
          </Permission>

          {renderPaymentUpdateOptions()}
        </Box>
      </Grid>
    </>
  );
};
