import moment from 'moment';
import { basicReportsService } from '../../../api';
import {
  DoSearchDriverNameRequest,
  DoSearchCustomerNameRequest,
  DoSearchTractorNameRequest,
  DoSearchTrailerNameRequest,
  DoSearchDispatcherNameRequest,
} from '../../../models';
import { gridPageSize } from '../../../utils';
import StorageManager from '../../../StorageManager/StorageManager';

const getValidTerminals = () => {
  const allTerminalsStorage = StorageManager.getItem('allTerminals');
  const globalSelectedTerminalsStorage = StorageManager.getItem(
    'globalSelectedTerminals'
  );
  return globalSelectedTerminalsStorage?.length
    ? globalSelectedTerminalsStorage?.map((el: { id: string }) => el.id)
    : allTerminalsStorage?.map((el: { id: string }) => el.id);
};

export const doSearchDriversName = async (
  searchText: string,
  pageNumber: number
) => {
  const queryData = new DoSearchDriverNameRequest();
  queryData.nameFilter = searchText || '';
  queryData.pageSize = gridPageSize;
  queryData.pageNumber = pageNumber;
  queryData.sort = '+firstname,+lastname';
  queryData.terminalIds = getValidTerminals();
  queryData.excludeStatuses = [0];
  const driversList = await basicReportsService.doSearchDriverName(queryData);
  return driversList;
};

export const doSearchCustomersName = async (
  searchText: string,
  pageNumber: number
) => {
  const queryData = new DoSearchCustomerNameRequest();
  queryData.nameKeyword = searchText;
  queryData.sort = '+name';
  queryData.pageSize = gridPageSize;
  queryData.pageNumber = pageNumber;
  const customerList = await basicReportsService.doSearchCustomerName(
    queryData
  );
  return customerList;
};

export const doSearchTractorsName = async (
  searchText: string,
  pageNumber: number
) => {
  const queryData = new DoSearchTractorNameRequest();
  queryData.axeleIdFilter = searchText || '';
  queryData.pageSize = gridPageSize;
  queryData.pageNumber = pageNumber;
  queryData.sort = '+tractorName';
  queryData.terminalIds = getValidTerminals();
  queryData.excludeStatusList = ['INACTIVE'];
  const tractorList = await basicReportsService.doSearchTractorName(queryData);
  return tractorList;
};

export const doSearchTrailersName = async (
  searchText: string,
  pageNumber: number
) => {
  const queryData = new DoSearchTrailerNameRequest();
  queryData.axeleIdFilter = searchText || '';
  queryData.pageSize = gridPageSize;
  queryData.pageNumber = pageNumber;
  queryData.sort = '+trailerName';
  queryData.terminalIds = getValidTerminals();
  queryData.excludeStatusList = ['INACTIVE'];
  const trailerList = await basicReportsService.doSearchTrailerName(queryData);
  return trailerList;
};

export const doSearchDispatchersName = async (
  searchText: string,
  pageNumber: number
) => {
  const queryData = new DoSearchDispatcherNameRequest();
  queryData.name = searchText || '';
  queryData.pageSize = gridPageSize;
  queryData.pageNumber = pageNumber;
  queryData.excludeRoleCodeList = ['DR', 'SP'];
  queryData.terminalIds = getValidTerminals();
  const dispatcherList = await basicReportsService.doSearchDispatcherName(
    queryData
  );
  return dispatcherList;
};

export const getStartEndDays = (weekDay = 'MONDAY') => {
  let end,
    start = moment().day(weekDay);
  const day = start.day();

  if (day > moment().day()) {
    start = moment().day(-7 + day);
    end = moment().day(day - 1);
  } else {
    end = moment().day(day + 6);
  }
  return { start, end };
};

export const getWeeksByRangePeriodAndTZ = (range, period, tz) => {
  const start = period.start
    .clone()
    .subtract(range[1] * -7, 'days')
    .tz(tz)
    .startOf('day');
  const end = period.end
    .clone()
    .subtract(range[0] * -7, 'days')
    .tz(tz)
    .endOf('day');
  return { start, end };
};
