import { Button, CircularProgress, Theme } from '@mui/material';
import React from 'react';
import { RoundedBtnStyles } from '../../styles';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
interface ReOptymizeProps {
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const ReOptymize: React.FC<ReOptymizeProps> = ({
  onClick,
  isLoading,
  disabled,
}) => {
  return (
    <Button
      variant="contained"
      disabled={disabled || isLoading}
      sx={{
        ...(RoundedBtnStyles as object),
        flexDirection: 'inherit',
        width: '120px',
        height: '32px!important',
        borderRadius: '10px',
      }}
      onClick={onClick}
    >
      {isLoading ? <CircularProgress size={20} /> : 'Reoptymize'}
    </Button>
  );
};

export default ReOptymize;
