import React from 'react';
import { DataGridPro, GridCellEditCommitParams } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { GridColDefSelf } from '../../../../../types';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useTheme } from '@mui/material';

/* Everything from this page should be deleted after table's proper integration. Here I wrote just for temp. */
const Footer = ({ totalElementsCount }: { totalElementsCount: number }) => {
  return (
    <Box
      sx={{
        pt: 2,
        pl: 1,
        pb: 2,
        pr: 1,
        boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.25)',
        background: '#F7F7F7',
      }}
    >
      <span
        style={{
          fontSize: '12px',
          color: 'rgba(0, 17, 34, 0.75)',
          lineHeight: '20px',
        }}
      >
        Totals Extracted Rows
        {` ${totalElementsCount || 0} `}
      </span>
    </Box>
  );
};

type PagePropTypes = {
  apiRef?: React.MutableRefObject<GridApiPro>;
  tableData: any;
  tableColumns: GridColDefSelf[];
  handleCellEditCommit?: (e: GridCellEditCommitParams) => void;
  handleScroll?: () => void;
  totalElementsCount?: number;
  hideFooter?: boolean;
  initialState?: GridInitialStatePro;
};

export default function DataGrid({
  tableData,
  tableColumns,
  handleCellEditCommit,
  handleScroll,
  apiRef,
  totalElementsCount,
  hideFooter = false,
  initialState = {},
}: PagePropTypes) {
  const currentTheme = useTheme();
  const theme = createTheme(currentTheme, {
    components: {
      // Name of the component
      MuiDataGrid: {
        styleOverrides: {
          // remove borders from root
          root: {
            border: 'none',
            '.MuiDataGrid-columnHeaderTitleContainerContent ': {
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            },
            '& .MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer':
              {
                display: 'inherit',
              },
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor: '#F7F7F7',
              width: '12px',
              height: '12px',
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: '27px',
              minHeight: 12,
              width: 2,
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: 'transparent',
            },
          },
          row: {
            boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.12)',
            '&:hover': {
              // cursor: 'pointer',
              backgroundColor: '#FFFFFF',
            },
          },
          cell: {
            border: 'none',
            fontSize: '14px',
            color: 'rgba(0, 17, 34, 0.75)',
            weight: 400,
            '&:focus': {
              border: 'none',
              outline: 'none',
            },
          },
          columnHeader: {
            '&:focus': {
              outline: 'none',
            },
          },
          columnHeaders: {
            color: '#1F2E3D',
            border: 'none',
            fontSize: '12px',
            fontWeight: 500,
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <DataGridPro
        apiRef={apiRef}
        components={{
          Footer: Footer,
        }}
        hideFooter={hideFooter}
        componentsProps={{
          footer: { totalElementsCount },
        }}
        onCellEditCommit={handleCellEditCommit}
        onCellKeyDown={(data, event) => {
          // We disabled date field keyboard changes because we have native errors with formatting.
          // It allows to change date value by custom selecting from date picker
          if (
            (data.colDef.type === 'date' || data.colDef.type === 'dateTime') &&
            event.type === 'keydown'
          ) {
            event.preventDefault();
            return;
          }
        }}
        columns={tableColumns}
        rows={tableData}
        showColumnRightBorder={false}
        showCellRightBorder={false}
        onRowsScrollEnd={handleScroll}
        disableColumnMenu
        disableColumnReorder
        sx={{
          '& .warning': {
            backgroundColor: 'rgba(237, 108, 2, 0.08)',
            border: '1px solid rgba(237, 108, 2, 0.5)',
          },
          '& .error': {
            backgroundColor: 'rgba(244, 67, 54, 0.08)',
            border: '1px solid rgba(244, 67, 54, 0.5)',
          },
        }}
        initialState={initialState}
      />
    </ThemeProvider>
  );
}
