import { uniq } from 'lodash';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import StorageManager from '../../StorageManager/StorageManager';
import { preferenceSevice } from '../../api';
import * as globalNotificationMessages from '../../constants/globalNotificationMessages';
import { PreferencesDTO } from '../../models';
import TerminalShort from '../../models/common/TerminalShort';
import { mockData } from './mockDate';

const globalTerminalStorage = StorageManager.getItem('globalSelectedTerminals');
const isTerminalEnabledStorage = StorageManager.getItem('terminalEnabled');
const allTerminalsStorage = StorageManager.getItem('allTerminals');

export class RootStore {
  @observable callStates: string[] = [];
  @observable isLoading = false;

  @observable tripPlanReadyEvent: Record<
    string,
    string | number | boolean
  > | null = null;
  @observable dispatchPanelAutoOpenDriver: {
    driverIds: number[];
    id: string;
    name: string;
  } | null = null;
  @observable masterData: any[] = mockData;
  @observable title = 'Welcome to LoadOps';
  @observable terminalShortList: TerminalShort[] = [];
  @observable globalTerminals: any = globalTerminalStorage
    ? globalTerminalStorage
    : [];
  @observable allTerminals: any = allTerminalsStorage
    ? allTerminalsStorage
    : [];
  @observable isTerminalEnabled: boolean = isTerminalEnabledStorage
    ? isTerminalEnabledStorage
    : false;
  @observable
  notificationType?: globalNotificationMessages.GlobalNotificationType = {
    type: undefined,
    serviceName: undefined,
  };
  @observable pendingDispatchDriverGroupIds: string[] = [];

  @observable isHomeReady = false;

  @observable companyPreferences: PreferencesDTO | null = null;

  @observable themeMode: 'light' | 'dark' =
    StorageManager.getItem('themeMode') || 'light';

  @computed
  get getMasterDate() {
    return this.masterData;
  }
  @computed
  get loadMasterData() {
    return this.masterData;
  }

  @computed
  get getSelectedTerminalShortList(): TerminalShort[] {
    return this.terminalShortList;
  }

  @computed
  get getTripPlanReadyEvent() {
    return this.tripPlanReadyEvent;
  }

  @computed
  get getDispatchPanelAutoOpenDriver() {
    return this.dispatchPanelAutoOpenDriver;
  }

  @computed
  get getLoading() {
    return this.isLoading;
  }

  @computed
  get getCompanyPreferences() {
    return this.companyPreferences;
  }

  @action.bound
  setLoadingUrl(url: string) {
    this.callStates.push(url);
    this.isLoading = !!this.callStates.length;
  }

  @action.bound
  setLoadingFinishedUrl(url: string) {
    this.callStates = this.callStates.filter((item) => item !== url);
    this.isLoading = !!this.callStates.length;
  }
  @action.bound
  async setCompanyPreferences() {
    this.companyPreferences = await preferenceSevice.getPreferencesData();
    return this.companyPreferences;
  }

  @action.bound
  setDispatchPanelAutoOpenDriver(
    data: {
      driverIds: number[];
      id: string;
      name: string;
    } | null
  ): void {
    this.dispatchPanelAutoOpenDriver = data;
  }

  @action.bound
  setEventSourceData(data: Record<string, string | number | boolean>): void {
    this.tripPlanReadyEvent = data;
  }

  @action.bound
  resetTripPlanReadyEvent(): void {
    this.tripPlanReadyEvent = null;
  }

  @action.bound
  setSelectedTerminalShortList(data: TerminalShort[]): void {
    this.terminalShortList = data;
  }

  @action.bound
  setGlobalTerminals(data: any): void {
    this.globalTerminals = data;
  }

  @action.bound
  setAllTerminals(data: any): void {
    this.allTerminals = data;
  }

  @action.bound
  setTerminalEnabled(data: boolean): void {
    this.isTerminalEnabled = data;
  }

  @action.bound
  setHomeReady(data: boolean): void {
    this.isHomeReady = data;
  }

  @action.bound
  setNotificationType(data: any): void {
    this.notificationType = data;
  }

  @action.bound
  pushPendingDispatchDriverGroupIds(driverGroupIds: string[]): void {
    this.pendingDispatchDriverGroupIds = [];
    this.pendingDispatchDriverGroupIds = uniq(driverGroupIds);
  }

  @computed
  get getNotificationType(): any {
    return toJS(this.notificationType);
  }

  @computed
  get getAllTerminals(): any {
    return toJS(this.allTerminals);
  }

  @computed
  get getIsTerminalEnabled(): any {
    return toJS(this.isTerminalEnabled);
  }

  @computed
  get getIsHomeReady(): any {
    return toJS(this.isHomeReady);
  }

  @computed
  get getGlobalTerminalsIds(): any {
    const tempSelectedTerminals = this.getGlobalTerminals;
    if (tempSelectedTerminals) {
      let temp: string[] = [];
      tempSelectedTerminals.map((item: any) => {
        temp = [...temp, item.id];
      });
      return temp;
    } else {
      return null;
    }
  }

  @computed
  get getGlobalTerminals(): any {
    const tempGlobalTerminals = toJS(this.globalTerminals);
    if (tempGlobalTerminals?.length > 0) {
      return tempGlobalTerminals;
    } else {
      const tempIsTerminalEnabled = toJS(this.isTerminalEnabled);
      if (tempIsTerminalEnabled) return toJS(this.allTerminals);
      else return null;
    }
  }
  @computed
  get getPendingDispatchDriverGroupIds(): string[] {
    return toJS(this.pendingDispatchDriverGroupIds);
  }

  @action.bound
  setThemeMode(newThemeMode: 'light' | 'dark'): void {
    this.themeMode = newThemeMode;
    StorageManager.setItem('themeMode', newThemeMode);
  }

  constructor() {
    makeObservable(this);
  }
}
