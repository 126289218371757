import { createContext, useContext, FC, PropsWithChildren } from 'react';
import { ECreateLoadPanelModes } from '../subPages/loadsList/LoadDetailsPanel/constants/constants';

export type TCreateLoadPanelContext = {
  mode: ECreateLoadPanelModes;
  isViewMode: boolean;
  isLoadMode: boolean;
  isTemplateMode?: boolean;
  page?: string;
};

export const CreateLoadPanelContext = createContext<TCreateLoadPanelContext>({
  mode: ECreateLoadPanelModes.CREATE_LOAD,
  page: 'LOADS',
} as TCreateLoadPanelContext);

export const CreateLoadPanelProvider: FC<
  PropsWithChildren<{ mode: ECreateLoadPanelModes; page?: string }>
> = ({ mode, page, children }) => {
  const isLoadMode = mode === ECreateLoadPanelModes.CREATE_LOAD;
  const isTemplateMode =
    mode === ECreateLoadPanelModes.CREATE_TEMPLATE ||
    mode === ECreateLoadPanelModes.EDIT_TEMPLATE ||
    mode === ECreateLoadPanelModes.VIEW_TEMPLATE;
  const isViewMode = mode === ECreateLoadPanelModes.VIEW_TEMPLATE;
  return (
    <CreateLoadPanelContext.Provider
      value={{ mode, page, isViewMode, isLoadMode, isTemplateMode }}
    >
      {children}
    </CreateLoadPanelContext.Provider>
  );
};

export const useCreateLoadPanel = () => useContext(CreateLoadPanelContext);
