/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActivateFactoryRequest,
  DeactivateFactorinRequest,
  FactoringSummary,
  GetAxeleFactoreRequest,
  GetCustomerRequest,
  GetDriverRequest,
  GetFactorMappingRequest,
  PaginatedFactoringRequest,
  UpdateMapCustomer,
} from '../../models';
import { annotateNameAsync, IFactoringService } from '../interfaces';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  ActivateCustomerRequest,
  DriverSummaryResponseDTO,
  GetDriverSummaryFactoreRequest,
} from '../../models/DTOs/ELD/Requests';
import { FactorePaginatedRoleList } from '../../views/settings/integrations/Finance/FactoreSelectedCustomer/Refresh';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { TFactorAddPayload } from '../../views/settings/integrations/Finance/FactoreSidedrawer/FactoreUtil';

const routes = {
  getFactoring: '/web/factoring/api/v2/integrations/factoringDetails',
};
const integrationsURL = '/web/factoring/api/v2/integrations';
const factoringDeactiveURL = '/web/factoring/api/v2/integrations/alter';
const updateMapCustomer =
  '/web/factoring/api/v2/customermappings/refreshandmap';
const getCustomerURL = 'web/customer/api/v2/customers/search';
const factoringArchivedURL = '/web/factoring/api/v2/integrations/alter';
const driverListURL = '/web/people/api/v2/organization/summary/list';
const notification = RootStoreInstence.setNotificationType;
export class FinanceIntegrationService extends IFactoringService {
  @annotateNameAsync
  async getFactoringList(requestData: PaginatedFactoringRequest) {
    try {
      const response = await httpClient.get<FactoringSummary[]>(
        routes.getFactoring,
        requestData,
        FactoringSummary,
        false
      );
      return response;
    } catch (error) {
      notification({
        type: 'FAILURE',
        serviceName: 'providersList',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async activateFactoringProvider(payloadData: TFactorAddPayload) {
    try {
      const response = await httpClient.post<any>(
        integrationsURL,
        undefined,
        payloadData,
        false
      );
      notification({
        type: 'SUCCESS',
        serviceName: 'SavedFactoring',
      });
      return response;
    } catch (error) {
      notification({
        type: 'FAILURE',
        serviceName: 'SavedFactoring',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async activateFactoringProviderUpdate(payloadData: TFactorAddPayload) {
    try {
      const response = await httpClient.put<any>(
        integrationsURL,
        undefined,
        payloadData,
        false
      );
      if (!payloadData?.isEdit) {
        notification({
          type: 'SUCCESS',
          serviceName: 'activateQBProvider',
        });
      } else {
        notification({
          type: 'SUCCESS',
          serviceName: 'editIntegration',
        });
      }
      return response;
    } catch (error) {
      if (!payloadData?.isEdit) {
        notification({
          type: 'FAILURE',
          serviceName: 'activateQBProvider',
        });
      } else {
        notification({
          type: 'FAILURE',
          serviceName: 'activateQBProvider',
        });
      }
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async factoringDeactive(payloadData: ActivateFactoryRequest) {
    try {
      const response = await httpClient.put<ActivateFactoryRequest>(
        factoringDeactiveURL,
        undefined,
        payloadData,
        false
      );
      notification({
        type: 'SUCCESS',
        serviceName: 'deactivate',
      });
      return response;
    } catch (error) {
      notification({
        type: 'FAILURE',
        serviceName: 'deactivate',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async factoringArchived(payloadData: ActivateFactoryRequest) {
    try {
      const response = await httpClient.put<ActivateFactoryRequest>(
        factoringArchivedURL,
        undefined,
        payloadData,
        false
      );
      if (payloadData?.archive) {
        notification({
          type: 'SUCCESS',
          serviceName: 'archived',
        });
      } else {
        notification({
          type: 'SUCCESS',
          serviceName: 'unArchived',
        });
      }
      return response;
    } catch (error) {
      if (payloadData?.archive) {
        notification({
          type: 'FAILURE',
          serviceName: 'archived',
        });
      } else {
        notification({
          type: 'FAILURE',
          serviceName: 'unArchived',
        });
      }
      throw error;
    }
  }

  @annotateNameAsync
  async deleteFactoring(id: DeactivateFactorinRequest) {
    try {
      const response = await httpClient.delete<DeactivateFactorinRequest>(
        `/web/factoring/api/v2/integrations?id=${id}`,
        undefined,
        id,
        false
      );
      notification({
        type: 'SUCCESS',
        serviceName: 'deactivateFactoring',
      });
      return response;
    } catch (error) {
      if ((error as ComposedError)?.error?.response?.data?.message) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: (error as ComposedError)?.error?.response?.data?.message,
        });
      } else {
        notification({
          type: 'SUCCESS',
          serviceName: 'deactivate',
        });
      }
    }
  }

  @annotateNameAsync
  async getCustomerList(payload: GetCustomerRequest) {
    try {
      const response = await httpClient.get<GetCustomerRequest>(
        getCustomerURL,
        payload,
        GetCustomerRequest,
        false,
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async customerMappings(query: GetFactorMappingRequest) {
    const customerMappingURL = `web/factoring/api/v2/customermappings/list`;
    try {
      const response = await httpClient.get<FactorePaginatedRoleList>(
        `${customerMappingURL}`,
        query,
        FactorePaginatedRoleList,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async customermappingsOptionList(
    requestData: GetDriverSummaryFactoreRequest
  ) {
    try {
      return await httpClient.get<DriverSummaryResponseDTO>(
        `web/factoring/api/v2/customermappings/option/list`,
        requestData,
        null,
        undefined,
        true
      );
    } catch (error) {
      notification({
        type: 'FAILURE',
        serviceName: 'providersList',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async customermappingsDropDownList(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.get<FactorePaginatedRoleList>(
        `/web/customer/api/v2/customers/organization?organizationId=${requestData.organizationId}`,
        undefined,
        FactorePaginatedRoleList,
        false,
        true
      );
    } catch (error) {
      notification({
        type: 'FAILURE',
        serviceName: 'providersList',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async customermappingsRefreshList(requestData: GetAxeleFactoreRequest) {
    try {
      const response = await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/customermappings/refreshandmap?organizationId=${requestData.organizationId}&factoringId=${requestData.id}`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
      notification({
        type: 'SUCCESS',
        serviceName: 'customerListRefresh',
      });
      return response;
    } catch (error) {
      notification({
        type: 'FAILURE',
        serviceName: 'customerListRefresh',
      });
      throw error;
    }
  }
  @annotateNameAsync
  async customerintialMappingRefresh(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.post<FactorePaginatedRoleList>(
        `web/factoring/api/v2/customermappings/refresh?factoringId=${requestData.id}&organizationId=${requestData.organizationId}`,
        undefined,
        requestData,
        false
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async customerFactoringMapingRecord(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/customermappings`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async customerRefreshandmapbyid(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/customermappings/refreshandmapbyid?factoringId=${requestData.id}&customerMappingIds=${requestData.id}`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async customerResetMapbyid(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/customermappings/reset?pageSize=500&factoringId=${requestData.id}&customerMappingIds=${requestData.customerMappingIds}`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async activateProvider(payloadData: ActivateCustomerRequest) {
    try {
      return await httpClient.put<ActivateCustomerRequest>(
        integrationsURL,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async getDriverList(payload: GetDriverRequest) {
    try {
      return await httpClient.get<GetCustomerRequest>(
        driverListURL,
        payload,
        GetCustomerRequest,
        false,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async driverMappings(requestData: GetFactorMappingRequest) {
    const driverMappingURL = `web/factoring/api/v2/drivermappings/list`;
    try {
      const response = await httpClient.get<FactorePaginatedRoleList>(
        driverMappingURL,
        requestData,
        FactorePaginatedRoleList,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async drivermappingsOptionList(requestData: GetDriverSummaryFactoreRequest) {
    try {
      return await httpClient.get<DriverSummaryResponseDTO>(
        `web/factoring/api/v2/drivermappings/option/list`,
        requestData,
        null,
        undefined,
        true
      );
    } catch (error) {
      throw error;
    }
  }
  @annotateNameAsync
  async drivermappingsDropDownList(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.get<FactorePaginatedRoleList>(
        `web/people/api/v2/organization/summary/list?pageSize=500&organizationId=${requestData.organizationId}`,
        undefined,
        FactorePaginatedRoleList,
        false,
        true
      );
    } catch (error) {
      throw error;
    }
  }
  @annotateNameAsync
  async drivermappingsRefreshList(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/drivermappings/refreshandmap?pageSize=500&organizationId=${requestData.organizationId}&factoringId=${requestData.id}`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async driverintialMappingRefresh(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/drivermappings/option/refresh?pageSize=500&organizationId=${requestData.organizationId}&factoringId=${requestData.id}`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async driverFactoringMapingRecord(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/drivermappings`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
    } catch (error) {
      notification({
        type: 'FAILURE',
        serviceName: 'linkDriver',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async driverRefreshandmapbyid(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/drivermappings/refreshandmapbyid?pageSize=500&factoringId=${requestData.id}&customerMappingIds=${requestData.id}`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async driverResetMapbyid(requestData: GetAxeleFactoreRequest) {
    try {
      const response = await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/drivermappings/reset?pageSize=500&factoringId=${requestData.id}&driverMappingIds=${requestData.customerMappingIds}`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async driverintialUpdatedDriverList(requestData: GetAxeleFactoreRequest) {
    try {
      return await httpClient.put<FactorePaginatedRoleList>(
        `web/factoring/api/v2/drivermappings/refresh?pageSize=500&organizationId=${requestData.organizationId}&factoringId=${requestData.id}`,
        undefined,
        requestData,
        FactorePaginatedRoleList
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async updateMapCustomer(payloadData: UpdateMapCustomer) {
    try {
      const response = await httpClient.put<any>(
        `${updateMapCustomer}?pageNumber=${payloadData.pageNumber}&pageSize=${payloadData.pageSize}&sort=%2BcustomerName%2C&organizationId=${payloadData.organizationId}&factoringId=${payloadData.id}`,
        undefined,
        payloadData,
        false
      );
      notification({
        type: 'SUCCESS',
        serviceName: 'updateMapCustomer',
      });
      return response;
    } catch (error) {
      notification({
        type: 'FAILURE',
        serviceName: 'updateMapCustomer',
      });
      throw error;
    }
  }
}
