import { loadsearchService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import {
  ILoadSearchGetSolutionResponse,
  ILoadSearchInvokeResponse,
} from '../../../../../models/DTOs/Loadsearch/Model';
import {
  LoadSearchGetSolutionRequest,
  LoadSearchInvokeRequest,
} from '../../../../../models/DTOs/Loadsearch/Request';

export const getLSInvoke = async (
  invokeRequest: LoadSearchInvokeRequest
): Promise<ILoadSearchInvokeResponse | null> => {
  const lsInvokeResponse = await loadsearchService.getLSInvoke(invokeRequest);
  if (lsInvokeResponse instanceof ServiceError) {
    return null;
  } else {
    return lsInvokeResponse as ILoadSearchInvokeResponse;
  }
};

export const getLSGetSolution = async (
  getSolutionRequest: LoadSearchGetSolutionRequest
): Promise<ILoadSearchGetSolutionResponse | null> => {
  const lsGetSolutionResponse = await loadsearchService.getLSGetSolution(
    getSolutionRequest
  );
  if (lsGetSolutionResponse instanceof ServiceError) {
    return null;
  } else {
    return lsGetSolutionResponse as ILoadSearchGetSolutionResponse;
  }
};
