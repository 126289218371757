import { Box, Button, Theme, Typography } from '@mui/material';
import React from 'react';
import ErrorSvg from '../static/errorSvg';
import { RoundedBtnStyles } from '../styles';

const btnStyles = (theme: Theme) => ({
  ...RoundedBtnStyles,
  boxShadow: theme?.shadows?.[2],
  color: '#FFF',
});

interface IErrorTemplate {
  onCancel?: () => void;
  onRerun?: () => void;
}

const ErrorTemplate: React.FC<IErrorTemplate> = (props) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ErrorSvg />
      <Typography
        variant="h6"
        fontWeight={'medium'}
        fontFamily={'Poppins'}
        gutterBottom
        textAlign={'center'}
        sx={{ color: '#FFF', mt: 2 }}
      >
        Something went wrong! Please run optymization again!
      </Typography>
      <Box display="flex" mt={2} gap="8px">
        <Button
          variant="outlined"
          type="button"
          sx={btnStyles}
          onClick={props?.onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="button"
          sx={btnStyles}
          onClick={props?.onRerun}
        >
          Rerun
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorTemplate;
