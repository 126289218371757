import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InvoiceModel } from '../../models/InvoiceModel';
import FormDialog from '../../../../common/Ui/FormDialog';
import { AttachmentsForm } from './InvoiceDownloadAction';
import TextArea from '../../../../common/Ui/TextArea';
import { AttachmentsBulk } from './InvoiceSendEmailAction';

export const SendToFactoringForm = ({ invoice }: { invoice: InvoiceModel }) => {
  const { control } = useFormContext();
  return (
    <>
      <TextArea
        control={control}
        name={'note'}
        placeholder={'Factoring Notes'}
        customStyles={{
          backgroundColor: 'transparent',
          border: '1px solid #c5c8ca',
          color: '#c5c8ca',
          padding: 8,
        }}
      />
      <AttachmentsForm
        invoice={invoice}
        subLabel={'(only PDF and image files are accepted)'}
      />
    </>
  );
};

export function SendToFactoringDialog({
  open,
  onAction,
  onClose,
  invoice,
  factoringNotes,
}: {
  open: boolean;
  onAction: ({
    invoiceId,
    documentIds,
  }: {
    invoiceId: string;
    documentIds: string[];
  }) => void;
  onClose: () => void;
  invoice: InvoiceModel;
  factoringNotes: string | null;
}) {
  return (
    <FormDialog
      data={{
        sendInvoice: false,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
        documentIds: [],
        invoiceId: invoice.id,
        note: factoringNotes,
      }}
      titleText="Send to Factoring"
      actionLabel="Send"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <SendToFactoringForm invoice={invoice} />}
    />
  );
}
/*--------------------------------------------------------------------------------------------------BULK----------------------------------------------------------------------------*/

export const SendToFactoringBulkForm = () => {
  const { control } = useFormContext();
  return (
    <>
      <TextArea
        control={control}
        name={'note'}
        placeholder={'Factoring Notes'}
        customStyles={{
          backgroundColor: 'transparent',
          border: '1px solid #c5c8ca',
          color: '#c5c8ca',
          padding: 8,
        }}
      />
      <AttachmentsBulk />
    </>
  );
};

export function SendToFactoringBulkDialog({
  open,
  onAction,
  onClose,
  invoices,
}: {
  open: boolean;
  onAction: ({
    invoiceId,
    documentIds,
    note,
  }: {
    invoiceId: string;
    documentIds: string[];
    note: string;
  }) => void;
  onClose: () => void;
  invoices: InvoiceModel[];
}) {
  // if selected only one invoice it should behave like single action
  if (invoices.length === 1) {
    const invoice = invoices[0];
    return (
      <FormDialog
        data={{
          sendInvoice: false,
          rateConfirmation: true,
          billOfLading: true,
          proofOfDelivery: true,
          other: true,
          documentIds: [],
          invoiceId: invoice.id,
          note: invoice.sourceFactoringNotes || '',
        }}
        titleText="Send to Factoring"
        actionLabel="Send"
        open={open}
        onAction={onAction}
        handleClose={onClose}
        contentRenderer={() => <SendToFactoringForm invoice={invoice} />}
      />
    );
  }
  return (
    <FormDialog
      data={{
        sendInvoice: false,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
        note:
          invoices.find((i) => !!i.sourceFactoringNotes)
            ?.sourceFactoringNotes || '',
      }}
      titleText="Send to Factoring"
      actionLabel="Send"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <SendToFactoringBulkForm />}
    />
  );
}
