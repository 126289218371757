import { Service } from './Service';
import {
  GetOwnViewsRequest,
  GetAvailableViewsRequest,
  CreateViewRequest,
  UpdateViewRequest,
  CopyViewRequest,
  CloseViewRequest,
  DeleteViewRequest,
  ValidateViewRequest,
} from '../../models';
import { View } from '../../types';
import { ServiceError } from './helperTypes';

export abstract class ICustomViewService extends Service {
  abstract getOwnCustomViews(
    requestData: GetOwnViewsRequest,
    dictionary: string
  ): Promise<View[] | ServiceError>;

  abstract getAvailableViews(
    requestData: GetAvailableViewsRequest,
    dictionary: string
  ): Promise<View[] | ServiceError>;

  abstract createView(
    requestData: CreateViewRequest,
    data: View,
    dictionary: string
  ): Promise<View | ServiceError>;

  abstract updateView(
    requestData: UpdateViewRequest,
    data: View,
    dictionary: string
  ): Promise<View | ServiceError>;

  abstract copyView(
    requestData: CopyViewRequest,
    dictionary: string
  ): Promise<View | ServiceError>;

  abstract closeView(
    requestData: CloseViewRequest,
    dictionary: string
  ): Promise<string | ServiceError>;

  abstract deleteView(
    requestData: DeleteViewRequest,
    dictionary: string
  ): Promise<string | ServiceError>;

  abstract validateView(
    requestData: ValidateViewRequest,
    dictionary: string
  ): Promise<
    { hasErrors: boolean; errors: { [key: string]: string } } | ServiceError
  >;
}
