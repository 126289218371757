import { Box } from '@mui/material';
import { useMemo } from 'react';
import { PieChart, PieGraph, ZingChartData } from '../PieChart';
import { ZingGuiSettings } from '../zingchart.settings';
import { GetLoadOperationChartColorSettings } from './utils';

export enum eLoadOperationalChart {
  NeedsAttention = 'NeedsAttention',
  PotentialDelay = 'PotentialDelay',
  NeedsLoads = 'NeedsLoads',
  Unvailable = 'Unavailable',
  OnVacation = 'OnVacation',
  Available = 'Available',
}

export interface LoadOperationalChartProps {
  id?: string;
  value: number;
  total: number;
  type: eLoadOperationalChart;
  chartTitle?: string;
}
export const LoadOperationalChart = ({
  value,
  total,
  type,
}: LoadOperationalChartProps): JSX.Element => {
  const {
    textColor1,
    textColor2,
    background,
    ringBackgroundColor1,
    ringBackgroundColor2,
  } = GetLoadOperationChartColorSettings(type);

  const chartData: ZingChartData = useMemo(() => {
    const g1 = PieGraph({
      title: `${value}`,
      subTitle: `${total}`,
      plotOptions: {
        slice: 45, // Ring width
      },
      plotarea: {
        margin: 0,
      },
      titleStyles: {
        color: textColor1,
        fontSize: '30',
        lineHeight: '30',
        fontWeight: 500,
        textDecoration: 'underline',
        offsetY: '-10px',
      },
      subTitleStyles: {
        color: textColor2,
        fontSize: '18',
        lineHeight: '27',
        fontWeight: 700,
        alpha: 0.5,
        offsetY: '18px',
      },
      series: [
        {
          values: [value],
          backgroundColor: ringBackgroundColor1,
          shadow: false,
        },
        {
          values: [total - value],
          backgroundColor: ringBackgroundColor2,
          shadow: false,
        },
      ],
    });
    return {
      layout: 'auto',
      graphset: [g1],
      gui: ZingGuiSettings,
    };
  }, [
    value,
    total,
    textColor1,
    textColor2,
    ringBackgroundColor2,
    ringBackgroundColor1,
  ]);

  return (
    <Box
      sx={{
        background: background,
        borderRadius: '50%',
      }}
    >
      <PieChart height={90} width={90} chartData={chartData} />
    </Box>
  );
};
