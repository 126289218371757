import StorageManager from '../../StorageManager/StorageManager';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { PreferencesType, QueryParams } from '../../models';
import {
  ActivateApiKeyRequest,
  DeactivateApiKeyRequest,
  DeleteApiKeyRequest,
  GenerateApiKeyRequest,
  GetCompanyLogoRequest,
  GetPreferencesRequest,
  UpdatePreferencesRequest,
} from '../../models/DTOs/Preferences/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { docType } from '../../utils/Doc';
import {
  IPreferencesService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';

const updatePreferenceLocalStorate = (preference: PreferencesType) => {
  if (preference) {
    StorageManager.setItem('preferredTimeZone', preference?.timezone);
    StorageManager.setItem('workWeekStartDay', preference?.workWeekStartDay);
  }
};

export class PreferencesService extends IPreferencesService {
  @annotateNameAsync
  async getPreferences(
    requestData: GetPreferencesRequest
  ): Promise<PreferencesType | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        '/web/preference/api/v2/preference/company',
        requestData,
        false,
        true
      );
      updatePreferenceLocalStorate(response?.data);
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async generateApiKey(
    requestData: GenerateApiKeyRequest
  ): Promise<PreferencesType | ServiceError> {
    try {
      const response = await httpClient.postRaw(
        '/web/preference/api/v2/organization/apikey',
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async deleteApiKey(
    requestData: DeleteApiKeyRequest
  ): Promise<PreferencesType | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        '/web/preference/api/v2/preference/company',
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async deactivateApiKey(
    requestData: DeactivateApiKeyRequest
  ): Promise<PreferencesType | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        '/web/preference/api/v2/preference/company',
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async activateApiKey(
    requestData: ActivateApiKeyRequest
  ): Promise<PreferencesType | ServiceError> {
    try {
      const response = await httpClient.deleteRaw(
        '/web/preference/api/v2/preference/company',
        undefined,
        requestData
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }
  async getCompanyLogo(
    requestData: GetCompanyLogoRequest
  ): Promise<docType | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        '/web/afs/api/v2/companyLogo/get',
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async updatePreferences(
    requestData: UpdatePreferencesRequest
  ): Promise<PreferencesType | ServiceError> {
    try {
      const response = await httpClient.putRaw(
        '/web/preference/api/v2/preference/company',
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'savePayItemCategory',
      });
      updatePreferenceLocalStorate(response);
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'savePayItemCategory',
      });
      return this.getServiceError(error as ComposedError);
    }
  }

  @annotateNameAsync
  async updateLogo(file: any): Promise<docType | ServiceError> {
    try {
      const requestData = new QueryParams();
      const formData = new FormData();
      formData.append('file', file);
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await httpClient.postRaw(
        '/web/afs/api/v2/companyLogo/create',
        requestData,
        formData,
        false,
        headers
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'postCompanyLogo',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'postCompanyLogo',
      });
      return this.getServiceError(error as ComposedError);
    }
  }
}
