import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { AddUpdateRoleDTO, GetRoles, RolesResponseDTO } from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { annotateNameAsync } from '../interfaces';
import { IRolesService } from '../interfaces/IRolesService';
// ROLE CONNSTANT
const getRolesList = '/web/auth/api/v2/roles/all';
const getRoleById = '/web/auth/api/v2/roles/get/all';
const addNewRole = '/web/auth/api/v2/roles';
const updateRole = '/web/auth/api/v2/roles';
const updateRoleMeta = '/web/auth/api/v2/roles/metadata';
const deleteRole = '/web/auth/api/v2/roles';
const getActiveRole = '/web/auth/api/v2/roles/webuser';

//  END ROLE
export class RolesService extends IRolesService {
  @annotateNameAsync
  async getRolesListData(requestData: GetRoles) {
    try {
      return await httpClient.getRaw(getRolesList, requestData, true, true);
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async getRoleDetailsData(requestData: GetRoles) {
    try {
      return await httpClient.get<RolesResponseDTO>(
        getRoleById,
        requestData,
        RolesResponseDTO,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async addNewRole(requestData: AddUpdateRoleDTO) {
    try {
      return await httpClient.postRaw(addNewRole, undefined, requestData, true);
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async updateRole(requestData: AddUpdateRoleDTO) {
    try {
      const response = await httpClient.putRaw(
        updateRole,
        undefined,
        requestData,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateRole',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  async updateRoleMeteData(requestData: AddUpdateRoleDTO) {
    try {
      const response = await httpClient.putRaw(
        updateRoleMeta,
        undefined,
        requestData,
        true
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'updateRole',
      });
      return response;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
  async deleteRole(requestData: AddUpdateRoleDTO) {
    try {
      const response = await httpClient.deleteRaw(
        deleteRole,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'deleteRole',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      const errorObj = this.getServiceError(composedError);
      if (errorObj) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'deleteRole',
        });
        throw errorObj;
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getActiveRoles(requestData: GetRoles) {
    try {
      return await httpClient.getRaw(getActiveRole, requestData, true, true);
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
}
