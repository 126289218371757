import { Dialog, Box, Typography, SxProps } from '@mui/material';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import { letter15 } from './constant';
import IconsComponent from '../../ui-kit/components/IconsComponent';

interface AdditionalModulePopup {
  handleClose: () => void;
  isCloseBtn?: boolean;
  isCancelBtn?: boolean;
  actionBtnText?: string;
  isHideCancelIcon?: boolean;
  title: string;
  popUpStyle?: SxProps;
  titleContainerStyle?: SxProps;
  titleTextStyle?: any;
  content: any | Element;
  onActionClick: () => void;
}

const AdditionalModulePopup = (props: AdditionalModulePopup) => {
  const {
    handleClose,
    isCloseBtn,
    isCancelBtn,
    actionBtnText,
    title,
    content,
    onActionClick,
    popUpStyle,
    titleContainerStyle,
    titleTextStyle,
    isHideCancelIcon,
  } = props;
  return (
    <Dialog open={true}>
      <Box
        sx={{
          padding: '24px',
          width: '600px',
          color: '#040022',
          ...popUpStyle,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '46px',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '24px',
            ...titleContainerStyle,
          }}
        >
          <Box
            sx={{
              fontSize: '20px',
              fontWeight: 500,
              ...letter15,
              ...titleTextStyle,
            }}
          >
            {title}
          </Box>
          {!isHideCancelIcon && (
            <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
              <IconsComponent iconName="Close" source="MUIcons" />
            </Box>
          )}
        </Box>
        {content}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '30px',
          }}
        >
          {isCloseBtn && (
            <ButtonImproved
              label="Close"
              variant="outlined"
              onClick={handleClose}
            />
          )}
          {isCancelBtn && (
            <ButtonImproved label="Cancel" onClick={handleClose} />
          )}
          {actionBtnText && (
            <ButtonImproved
              style={{
                marginLeft: '5px',
              }}
              label={actionBtnText}
              variant="contained"
              onClick={onActionClick}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AdditionalModulePopup;
