import { Box } from '@mui/material';
import MultilineCellWithIcon from '../../MultilineCellWithIcon';
import ThreeDotMenuIcon from '../../ThreeDotMenuIcon';
import DeletePopup from '../../DeletePopup';
import React from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { toTimezoneMoment } from '../../Utils';

export const AccountCardHeader = ({
  acountCardData,
  accountName,
  accountData,
  MenuOptions,
  handleMenuClose,
  handleMenuClick,
  handleMenuItemClick,
  open,
  anchorEl,
  isDeletePopupOpen,
  closeConfirmationPopup,
  deactivateAccount,
  selectedCardForDeletion,
}: Props) => {
  const titleStyle = { color: 'primary.main', fontSize: 20, fontWeight: 500 };
  const subtitleStyle = {
    color: 'text.secondary',
    letterSpacing: '0.15px',
    lineHeight: '10px',
  };
  const successIconStyle = { color: 'success.main', marginRight: '9px' };
  const warningIconStyle = { color: 'error.main', marginRight: '9px' };
  const pendingIconStyle = { color: 'primary.main', marginRight: '9px' };
  let icon = {};
  if (
    accountData['status'] === 'Pending' ||
    accountData['status'] === 'PENDING'
  ) {
    icon = AccessTimeIcon;
  } else {
    if (accountData?.isValid) {
      icon = CheckCircleOutlineOutlinedIcon;
    } else icon = WarningAmberOutlinedIcon;
  }
  if (accountData['status'] === 'UNAVAILABLE') {
    icon = AccessTimeIcon;
  }
  let iconStyle = {};
  if (accountData['status'] === 'Pending') {
    iconStyle = pendingIconStyle;
  } else {
    if (accountData?.isValid) {
      iconStyle = successIconStyle;
    } else iconStyle = warningIconStyle;
  }

  if (accountData['status'] === 'UNAVAILABLE') {
    iconStyle = pendingIconStyle;
  }
  const preferredTimeZone = localStorage.getItem('preferredTimeZone');
  const setTimezoneText = () => {
    const timeZone = acountCardData?.lastUpdate;
    if (!timeZone) {
      return null;
    }
    if (accountData?.lastIntegrationDate || accountData?.lastUsedDate) {
      return accountData.integrationType === 'ACCOUNTING'
        ? timeZone.replace(
            '{time}',
            `${toTimezoneMoment(
              accountData?.lastIntegrationDate,
              preferredTimeZone
            ).format('MMM DD, YYYY hh:mm A')}`
          )
        : timeZone.replace(
            '{time}',
            `${toTimezoneMoment(
              accountData?.lastUsedDate,
              preferredTimeZone
            ).format('MMM DD, YYYY hh:mm A')}`
          );
    }
    return null;
  };
  return (
    <Box
      className="top-panel"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Box className="left">
        <MultilineCellWithIcon
          title={acountCardData.accountName.replace(
            '{accountName}',
            `${accountName}`
          )}
          subtitle={setTimezoneText()}
          titleStyle={titleStyle}
          subtitleStyle={subtitleStyle}
          icon={icon}
          iconStyle={iconStyle}
        />
      </Box>
      <Box className="right">
        {MenuOptions && MenuOptions.length > 0 && (
          <ThreeDotMenuIcon
            menuOptions={MenuOptions}
            open={open}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
            anchorEl={anchorEl}
            handleMenuItemClick={handleMenuItemClick}
            accountData={accountData}
          />
        )}
        <DeletePopup
          open={isDeletePopupOpen}
          onClose={closeConfirmationPopup}
          onAction={() => deactivateAccount(selectedCardForDeletion)}
          title={'Delete Account ?'}
          body={'Are you sure you want to delete the provider?'}
          subtitle={
            'Please note that all related information will be permanently lost.'
          }
          buttonText={'Delete'}
          width="20%"
        />
      </Box>
    </Box>
  );
};

export interface Props {
  acountCardData: any;
  accountName: any;
  accountData: any;
  MenuOptions?: any;
  handleMenuClose?: any;
  handleMenuClick?: any;
  handleMenuItemClick?: any;
  anchorEl?: any;
  open?: any;
  isDeletePopupOpen?: any;
  closeConfirmationPopup?: any;
  deactivateAccount?: any;
  selectedCardForDeletion?: any;
}
