import { CloseOutlined, FilterListOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { groupBy } from 'lodash';
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputLabel from '../../../InputLabel';
import { getAllFiltersStyles } from '../styles/AllFilters.styles';
import { useThemeResponsive } from '../utils/useThemeResponsive';
import { FilterComponent } from './FilterComponent';
import { t } from 'i18next';

interface IAllFiltersProps {
  filterPerCol: number[];
  groupOrder?: string[];

  [prop: string]: any;
}

const textInputLikeTypes = [
  'MultipleAutocomplete',
  'SingleAutocomplete',
  'DateRangePicker',
  'DatePicker',
  'TextField',
  'RelativeDateRange',
];

const AllFilters: ForwardRefRenderFunction<HTMLDivElement, IAllFiltersProps> = (
  {
    column,
    handleOnSubimt,
    handleOnReset,
    filterPerCol = [8],
    defaultFilterValue,
    pageName,
    stylesProps = {},
    groupOrder,
  },
  ref
) => {
  const theme = useTheme();
  const { isMobile } = useThemeResponsive();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: defaultFilterValue,
  });
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  const [nonVisibleActiveFilters, setNonVisibleActiveFilters] =
    useState<string>('');

  const styles = getAllFiltersStyles({
    theme: theme,
    isFilterActive,
  });
  useEffect(() => {
    reset(defaultFilterValue);
    if (!defaultFilterValue) return;
    const activeFilters = Object.entries(defaultFilterValue).filter(
      (item: any) => {
        if (item[1] !== undefined && item[1] !== null) {
          if (Array.isArray(item[1])) {
            return (
              item[1].length > 0 &&
              column.find(
                (filter: any) => filter.name === item[0] && !filter.default
              )
            );
          } else {
            return (
              Object.keys(item[1]).length > 0 &&
              column.find(
                (filter: any) => filter.name === item[0] && !filter.default
              )
            );
          }
        }
      }
    );
    setNonVisibleActiveFilters(
      activeFilters.length > 0 ? ' + ' + activeFilters.length : ''
    );
    setIsFilterActive(activeFilters.length > 0);
  }, [defaultFilterValue, column]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    reset(defaultFilterValue);
  };

  const onReset = () => {
    setAnchorEl(null);
    setIsFilterActive(false);
    reset();
    handleOnReset && handleOnReset();
  };

  const onSubmit = (data: any) => {
    setAnchorEl(null);
    handleOnSubimt && handleOnSubimt(data);
    reset();
  };

  const RenderFilters = () => {
    const filterBox: any = [];
    const max = filterPerCol[filterPerCol.length - 1];
    const getGroupIndex = (groupName: string) =>
      groupOrder ? groupOrder.findIndex((name) => name === groupName) : -1;
    let grouped = Object.entries(groupBy(column, 'groupName'))
      .map<[string, any[]]>(([name, items]) => [
        name === 'undefined' ? '' : name,
        items,
      ])
      .sort((aGroup, bGroup) => {
        if (aGroup[0] === '') return 1;
        if (bGroup[0] === '') return -1;
        return getGroupIndex(aGroup[0]) - getGroupIndex(bGroup[0]);
      });
    if (grouped.length === 1 && !grouped[0][0]) {
      grouped = grouped[0][1].map((item) => ['', [item]]);
    }
    for (
      let i = 0,
        j = 0,
        step = filterPerCol[j] === undefined ? max : filterPerCol[j];
      i < grouped.length;
      i += step,
        j += 1,
        step = filterPerCol[j] === undefined ? max : filterPerCol[j]
    ) {
      const chunk = grouped.slice(i, i + step);
      const col = (
        <Box id="filterCols" sx={{ display: 'flex', flexDirection: 'column' }}>
          {chunk.map(([groupName, items]: any, index) => {
            return [
              <InputLabel
                key={index}
                sx={{
                  pt: index !== 0 ? 2 : undefined,
                  pb: 0,
                }}
              >
                {groupName}
              </InputLabel>,
            ].concat(
              items.map((item: any, itemIndex: number) => (
                <Controller
                  key={item?.name}
                  render={({ field, fieldState: { error } }) => (
                    <Box
                      sx={{
                        mt: groupName || itemIndex !== 0 || index !== 0 ? 2 : 0,
                        ...(textInputLikeTypes.includes(item.type)
                          ? {
                              minHeight: 45,
                              display: 'flex',
                              alignItems: 'flex-end',
                            }
                          : {}),
                      }}
                    >
                      {FilterComponent(
                        {
                          ...item,
                          customStyles: item?.customStylesAllFilter, //this is styles for All Filter popup
                        },
                        defaultFilterValue,
                        undefined,
                        field,
                        error,
                        true,
                        isMobile,
                        pageName
                      )}
                    </Box>
                  )}
                  name={item?.name}
                  control={control}
                />
              ))
            );
          })}
        </Box>
      );
      if (i > 0 && i < grouped.length) {
        filterBox.push(
          <Box
            sx={{
              height: '300',
              width: 0,
              border: '1px solid rgba(238, 238, 238, 1)',
              m: '16px 10px 0 10px',
            }}
          />
        );
      }
      filterBox.push(col);
    }
    return (
      <Box
        className="nb02"
        sx={{
          display: 'flex',
          [theme?.breakpoints?.down('tablet')]: {
            flexDirection: 'column',
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            overflowX: 'hidden',
          },
        }}
      >
        {filterBox}
      </Box>
    );
  };

  const AllFilterBox = (
    <Box
      className="AllFilterBox-root"
      id="AllFilterBox"
      sx={styles.AllFilterBoxRoot}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        className="headerContainer AllFilterBox-container"
        sx={styles.AllFilterBoxContainer}
      >
        <Box
          className="headerContainerLeft"
          sx={styles.AllFilterBoxContainerLeft}
        >
          <Box sx={{ mr: 1, display: 'flex' }}>
            <FilterListOutlined sx={styles.FilterListOutlinedIconPopover} />
          </Box>
          <Typography
            component={'div'}
            variant="subtitle1"
            sx={{
              [theme?.breakpoints?.down('tablet')]: {
                ...theme?.typography?.h7,
                color: 'primary.main',
                fontSize: '14px',
              },
            }}
          >
            {isMobile
              ? t('GanttFilterManageFilters')
              : t('GanttFilterAllFilters')}
          </Typography>
        </Box>
        <Box
          className="headerContainerRight"
          sx={styles.AllFilterBoxContainerRight}
          onClick={handleClose}
        >
          <CloseOutlined sx={{ color: 'primary.main', cursor: 'pointer' }} />
        </Box>
      </Stack>

      <Box id="AllFilterContent" sx={styles.AllFilterContent}>
        <Box
          component={'form'}
          sx={{
            [theme?.breakpoints?.down('tablet')]: {
              width: '100%',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box
              className="nb01"
              sx={{
                pl: 2,
                pr: 2,
                [theme?.breakpoints?.down('tablet')]: {
                  width: '100%',
                },
              }}
            >
              <RenderFilters />
            </Box>
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{
              [theme?.breakpoints?.down('tablet')]: {
                flexDirection: 'column-reverse',
              },
              m: '15px 10px 12px 10px',
            }}
          >
            <Button
              onClick={onReset}
              variant="outlined"
              fullWidth={isMobile}
              size="medium"
              sx={styles.resetButton}
            >
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  ...theme.typography?.buttonMedium,
                }}
              >
                reset
              </Typography>
            </Button>
            <Button
              fullWidth={isMobile}
              onClick={handleSubmit(onSubmit)}
              type="submit"
              variant="contained"
              size="medium"
              sx={styles.submitButton}
            >
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  ...theme.typography?.buttonMedium,
                }}
              >
                {t('apply')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box ref={ref} sx={{ pr: 2, pt: 1 }}>
      <Button
        className="AllFilters-buttonRoot"
        variant="outlined"
        onClick={handleClick}
        sx={{
          ...styles.AllFiltersButtonRoot,
          ...stylesProps,
        }}
      >
        <Box
          className="AllFilters-icon"
          sx={{ marginRight: '10px', display: 'inline-flex' }}
        >
          <FilterListOutlined sx={styles.FilterListOutlinedIcon} />
        </Box>
        <Box
          className="AllFilters-text"
          sx={{ textTransform: 'capitalize', display: 'inline' }}
        >
          {isMobile ? t('GanttFilterFilters') : t('GanttFilterAllFilters')}{' '}
          {nonVisibleActiveFilters}
        </Box>
      </Button>
      <Popover
        slotProps={{
          paper: {
            sx: {
              display: 'flex',
            },
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onReset={onReset}
        sx={styles.AllFilterPopover}
      >
        {AllFilterBox}
      </Popover>
    </Box>
  );
};

export default forwardRef(AllFilters);
