import { FC } from 'react';
import Grid from '@mui/material/Grid';
import ProviderDescription from '@/ui-kit/components/ProviderDescription';
import IntegrationActivationPanel from '@/ui-kit/components/IntegrationActivationPanel';
import { Provider } from '@/models/DTOs/FuelCards/FuelCards';
import { FuelCardProvidersMap } from '@/views/settings/integrations/FuelCard/constants';

interface Props {
  selectedProvider: Provider;
}

export const ProviderInfo: FC<Props> = ({ selectedProvider }) => {
  const selectedProviderInfo =
    FuelCardProvidersMap[selectedProvider.providerId];
  return (
    <Grid container marginBottom={'30px'}>
      <Grid item xs={9}>
        <ProviderDescription
          currentCustomerData={{
            providerName: selectedProvider.providerName,
            description: selectedProviderInfo.description,
          }}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={2}>
        <IntegrationActivationPanel status="VALID" />
      </Grid>
    </Grid>
  );
};
