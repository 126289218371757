import {
  TimePicker as BaseTimePicker,
  TimePickerProps as BaseTimePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

const CustomizedTimePicker: React.FC<BaseTimePickerProps<any>> = ({
  slotProps,
  ...props
}) => {
  const modifiedSlotProps = {
    ...slotProps,
    textField: {
      ...(slotProps?.textField || {}),
      size: (slotProps?.textField as any)?.size || ('small' as const),
    },
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BaseTimePicker {...props} slotProps={modifiedSlotProps} />
    </LocalizationProvider>
  );
};

export { CustomizedTimePicker as TimePicker };
