import { RelativeDateRangeUtils } from '../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { getDateType, SCHEDULE_TYPE } from '../basicReports/constant';
import {
  doSearchCustomersName,
  doSearchDriversName,
  doSearchTractorsName,
  doSearchTrailersName,
} from '../basicReports/utils';
import { AdvancedReportList } from './report.config';

const entityFilters = [
  {
    name: 'driverIds',
    fieldName: 'driverName',
    getData: doSearchDriversName,
    label: 'Driver',
    default: true,
    type: 'multipleAutoComplete',
  },
  {
    name: 'customerIds',
    fieldName: 'name',
    getData: doSearchCustomersName,
    label: 'Customer',
    default: true,
    type: 'multipleAutoComplete',
  },
  {
    name: 'tractorIds',
    fieldName: 'name',
    getData: doSearchTractorsName,
    label: 'Tractor',
    default: true,
    type: 'multipleAutoComplete',
  },
  {
    name: 'trailerIds',
    fieldName: 'name',
    getData: doSearchTrailersName,
    label: 'Trailer',
    default: true,
    type: 'multipleAutoComplete',
  },
];

export const schedules = {
  key: 5,
  name: 'scheduleType',
  options: [...SCHEDULE_TYPE].map((s) => ({ label: s.name, value: s.id })),
  label: 'Schedule Type',
  type: 'singleSelect',
  style: {
    width: '100%',
  },
};

export const dates = {
  key: 6,
  name: 'dateType',
  options: getDateType('driver').map((s) => ({ label: s.name, value: s.id })),
  label: 'Date Type',
  type: 'singleSelect',
  style: {
    width: '100%',
  },
};

export const reportFilters = [
  {
    name: 'pickupRange',
    label: 'Period *',
    style: {
      width: '100%',
    },
    variant: 'standard',
    require: true,
    ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
  },
  schedules,
  dates,
  ...entityFilters,
];

export const entity = [
  { label: 'Driver', value: 'DRIVER' },
  { label: 'Customer', value: 'CUSTOMER' },
  { label: 'Tractor', value: 'TRACTOR' },
  { label: 'Trailer', value: 'TRAILER' },
];

export const period = [
  { label: 'Last 1 Year', value: 'one' },
  { label: 'Last 2 Years', value: 'two' },
  { label: 'Whole Period', value: 'whole' },
];

export const trendFilters = [
  {
    key: 1,
    name: 'entity',
    options: entity,
    label: 'Entity',
    type: 'singleSelect',
    style: {
      width: '100%',
    },
  },
  {
    key: 2,
    name: 'period',
    options: period,
    label: 'Period',
    type: 'singleSelect',
    style: {
      width: '100%',
    },
  },
  schedules,
  dates,
];

export const pillsOptions = [
  {
    name: 'Available',
    value: 'AVAILABLE',
  },
  {
    name: 'Assignment Planned',
    value: 'ASSIGNMENT_PLANNED',
  },
  {
    name: 'Offered To Driver',
    value: 'OFFERED_TO_DRIVER',
  },
  {
    name: 'Dispatched',
    value: 'DISPATCHED',
  },
  {
    name: 'In Transit',
    value: 'IN_TRANSIT',
  },
  {
    name: 'Load Completed',
    value: 'LOAD_COMPLETED',
  },
  {
    name: 'Canceled',
    value: 'CANCELLED',
  },
  {
    name: 'Invoiced',
    value: 'INVOICED',
  },
  {
    name: 'Paid',
    value: 'PAID',
  },
];

export const getPillLabel = (pill: string) =>
  pill
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
    .join(' ');

export const reportTypes = [
  { label: 'Load Report', value: 'load' },
  { label: 'Load Trend Report', value: 'trend' },
  { label: 'IFTA Report', value: 'IFTA' },
  { label: 'Driver Time Off Report', value: 'time_off' },
];

export const trendTypes = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
];

class DefaultLoadClass {
  statusList!: Record<string, number>[];
  Status!: Record<string, number>[];
  driverIds!: Record<string, number>[];
  customerIds!: Record<string, number>[];
  tractorIds!: Record<string, number>[];
  trailerIds!: Record<string, number>[];
  scheduleType!: number | string;
  dateType!: number | string;
  startDate?: string;
  endDate?: string;
  pickupRange?: any;
}

class DefaultTrendClass {
  period!: string;
  type!: string;
  entity!: string;
  scheduleType!: number;
  dateType!: number;
}

export type DefaultLoadFilter = DefaultLoadClass;
export type DefaultTrendFilter = DefaultTrendClass;

export const defaultSelect: DefaultLoadFilter = {
  statusList: [],
  driverIds: [],
  customerIds: [],
  tractorIds: [],
  trailerIds: [],
  scheduleType: '',
  dateType: '',
  Status: [],
};

export const defaultTrend: DefaultTrendType = {
  period: 'one',
  type: 'monthly',
  entity: 'DRIVER',
};

export const AdvancedReportDefaultFilters = {};

export class DefaultTrendType {
  period!: string;
  type!: string;
  entity!: string;
}

const timeOff_statue = [
  { label: 'Not Started', value: 'OFFERED_TO_DRIVER' },
  { label: 'Active', value: 'DISPATCHED,IN_TRANSIT' },
  { label: 'Completed', value: 'LOAD_COMPLETED' },
];

export const timeOffFilters = [
  {
    key: 1,
    name: 'pickupRange',
    label: 'Time-off Date',
    style: {
      width: '100%',
    },
    variant: 'standard',
    ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
  },
  {
    name: 'driverIds',
    fieldName: 'driverName',
    getData: doSearchDriversName,
    label: 'Driver',
    default: true,
    type: 'multipleAutoComplete',
  },
  {
    key: 3,
    name: 'Status',
    fieldName: 'label',
    options: timeOff_statue,
    label: 'Status',
    type: 'multipleAutoComplete',
    style: {
      width: '100%',
    },
  },
];

export const reportColumnsDisplayName = {
  // id: 'ID',
  LOAD_ID: 'Load ID',
  TRIP_ID: 'Trip ID',
  LOAD_AXELE_ID: 'Load Axele ID',
  REFERENCE_ID: 'Reference ID',
  ORGANIZATION_ID: 'Organization ID',
  TRIP_SEQUENCE: 'Trip Sequence',
  BROKERAGE_TRIP: 'Brokerage Trip',
  LANE_SEQUENCE: 'Lane Sequence',
  PRIMARY_DRIVER_NAME: 'Driver Name',
  SECONDARY_DRIVER_NAME: 'Secondary Driver Name',
  TRACTOR_NAME: 'Tractor Name',
  TRAILER_NAME: 'Trailer Name',
  CUSTOMER_NAME: 'Customer Name',
  CUSTOMER_TYPE: 'Customer Type',
  // CUSTOMER_NAME: 'Customer Name',
  // CUSTOMER_TYPE: 'Customer Type',
  ORIGIN_STOP_TYPE: 'Origin Stop Type',
  DESTINATION_STOP_TYPE: 'Destination Stop Type',
  LOAD_STATUS: 'Load Status',
  TERMINAL: 'Terminal',
  DISPATCHER_NAME: 'Dispatcher Name',
  LOAD_REVENUE: 'Load Revenue',
  GROSS_REVENUE: 'Gross Revenue',
  LOAD_EXPENSE: 'Load Expense',
  PRIMARY_DRIVER_SETTLEMENT: 'Primary Driver Settlement',
  SECONDARY_DRIVER_SETTLEMENT: 'Secondary Driver Settlement',
  NET_INCOME: 'Net Income',
  INVOICE_NOTES: 'Invoice Notes',
  DISPATCHER_NOTES: 'Dispatcher Notes',
  PICKUP_LOCATION_NAME: 'Pickup Location Name',
  PICKUP_LOCATION_ADDRESS: 'Pickup Location Address',
  PICKUP_CONTACT_NAME: 'Pickup Contact Name',
  PICKUP_CONTACT_NUMBER: 'Pickup Contact Number',
  PICKUP_CITY: 'Pickup City',
  PICKUP_STATE: 'Pickup State',
  PICKUP_ZIP: 'Pickup Zip',
  PICKUP_LATITUDE: 'Pickup Latitude',
  PICKUP_LONGITUDE: 'Pickup Longitude',
  PICKUP_EMAIL: 'Pickup Email',
  PICKUP_NOTES: 'Pickup Notes',
  DROP_OFF_LOCATION_NAME: 'Drop Off Location Name',
  DROP_OFF_CONTACT_NUMBER: 'Drop Off Contact Number',
  DROP_OFF_CONTACT_NAME: 'Drop Off Contact Name',
  DROP_OFF_LOCATION_ADDRESS: 'Drop Off Location Address',
  DROP_OFF_CITY: 'Drop Off City',
  DROP_OFF_STATE: 'Drop Off State',
  DROP_OFF_ZIP: 'Drop Off Zip',
  DROP_OFF_TRAILER: 'Drop Off Trailer',
  DROP_OFF_TRACTOR: 'Drop Off Tractor',
  DROP_OFF_LATITUDE: 'Drop Off Latitude',
  DROP_OFF_LONGITUDE: 'Drop Off Longitude',
  DROP_OFF_NOTES: 'Drop Off Notes',
  DROP_OFF_EMAIL: 'Drop Off Email',
  NUMBER_OF_STOPS: 'Number Of Stops',
  PICKUP_START_DATE_TIME: 'Pickup Start Date Time',
  PICKUP_END_DATE_TIME: 'Pickup End Date Time',
  PICKUP_ETA: 'Estimated Pickup Arrival',
  ACTUAL_PICKUP_ARRIVAL: 'Actual Pickup Arrival',
  ACTUAL_PICKUP_DEPARTURE: 'Actual Pickup Departure',
  DROP_OFF_START_DATE_TIME: 'Drop Off Start Date Time',
  DROP_OFF_END_DATE_TIME: 'Drop Off End Date Time',
  DROP_OFF_ETA: 'Estimated Drop Off Arrival',
  ACTUAL_DROP_OFF_ARRIVAL: 'Actual Drop Off Arrival',
  ACTUAL_DROP_OFF_DEPARTURE: 'Actual Drop Off Departure',
  INVOICE_CREATED_DATE: 'Invoice Date',
  INVOICE_PAID_DATE: 'Paid Date',
  DWELL_TIME_PICKUP: 'Dwell Time Pickup',
  DETENTION_PICKUP: 'Detention Pickup',
  DETENTION_DROP_OFF: 'Detention Drop Off',
  DWELL_TIME_DROP_OFF: 'Dwell Time Drop Off',
  EQUIPMENT_TYPES: 'Equipment Types',
  COMMODITY_TYPES: 'Commodity Types',
  OPERATION_TYPE: 'Operation Type',
  FACTORING_COMPANY: 'Factoring Company',
  LOADED_MILES: 'Loaded Miles',
  EMPTY_MILES: 'Empty Miles',
  GROSS_PER_LOADED_MILES: 'Gross Per Loaded Miles',
  GROSS_PER_TOTAL_MILES: 'Gross Per Total Miles',
  NET_INCOME_PER_LOADED_MILES: 'Net Income Per Loaded Miles',
  NET_INCOME_PER_TOTAL_MILES: 'Net Income Per Total Miles',
  TOTAL_WEIGHT: 'Total Weight',
  CHASSIS: 'Chassis',
  CONTAINER: 'Container',
  SEAL_NUMBER: 'Seal Number',
  ODOMETER_READING: 'Odometer Reading',
  INVOICE_LINE_ITEMS: 'Invoice Line Items',
  DRIVER_SETTLEMENT: 'Driver Settlement',
  TRACTOR_SETTLEMENT: 'Tractor Settlement',
  DRIVER_NAME: 'Driver Name',
  EMPLOYMENT_TYPE: 'Employment Type',
  SETTLEMENT_START_DATE: 'Settlement Start Date',
  SETTLEMENT_END_DATE: 'Settlement End Date',
  SETTLEMENT_LINE_ITEMS: 'Settlement Line Items',
  TRIP_PAY: 'Trip Pay',
  DISPATCHER: 'Dispatcher',
  ASSIGNED_TRAILER: 'Assigned Trailer',
  ASSIGNED_TRACTOR: 'Assigned Tractor',
  ORIGIN_LOCATION_NAME: 'Origin Location Name',
  ORIGIN_LOCATION_ADDRESS: 'Origin Location Address',
  ORIGIN_CITY: 'Origin City',
  ORIGIN_STATE: 'Origin State',
  ORIGIN_ZIP: 'Origin Zip',
  DESTINATION_LOCATION_NAME: 'Destination Location Name',
  DESTINATION_LOCATION_ADDRESS: 'Destination Location Address',
  DESTINATION_CITY: 'Destination City',
  DESTINATION_STATE: 'Destination State',
  DESTINATION_ZIP: 'Destination Zip',
  DRIVER_GROUP_NAME: 'Driver Group Name',
  STATUS: 'Status',
  CITY: 'City',
  STATE: 'State',
  STREET_ADDRESS: 'Street Address',
  APPOINTMENT_START_DATE: 'Appointment Start Date',
  APPOINTMENT_END_DATE: 'Appointment End Date',
  ACTUAL_TIME_OF_ARRIVAL: 'Actual Time Of Arrival',
  ACTUAL_TIME_OF_COMPLETION: 'Actual Time Of Completion',
  PLANNED_DURAION: 'Planned Duration (Minutes)',
  ACTUAL_TIME_OFF_DURATION: 'Actual Time-Off Duration (Minutes)',
  ENTITY_NAME: 'Entity Name',
  YEAR: 'Year',
  MONTH: 'Month',
  LOAD_COUNT: 'Load Count',
  LOADED_BY_TOTAL: 'Loaded By Total',
  EMPTY_BY_TOTAL: 'Empty By Total',
  DRIVER_PAYMENT: 'Driver Payment',
  REVENUE_LOADED_MILE: 'Avg.Gross Revenue $/Loaded Mile',
  REVENUE_TOTAL_MILE: 'Avg.Gross Revenue $/Total Mile',
  LOAD_PAYMENT: 'Load Payment',
  GROSS_REVENUE_TREND: 'Gross Revenue',
} as { [key: string]: any };

const driverAndTractorTripFields = [
  'LOAD_ID',
  'TRIP_ID',
  'TERMINAL',
  // 'NUMBER_OF_STOPS',
  'OPERATION_TYPE',
  'LOADED_MILES',
  'EMPTY_MILES',
  'DRIVER_NAME',
  'EMPLOYMENT_TYPE',
  'SETTLEMENT_START_DATE',
  'SETTLEMENT_END_DATE',
  // 'SETTLEMENT_LINE_ITEMS',
  'TRIP_PAY',
  'DISPATCHER',
  'ASSIGNED_TRAILER',
  'ASSIGNED_TRACTOR',
  'ORIGIN_LOCATION_NAME',
  'ORIGIN_LOCATION_ADDRESS',
  'ORIGIN_CITY',
  'ORIGIN_STATE',
  'ORIGIN_ZIP',
  'DESTINATION_LOCATION_NAME',
  'DESTINATION_LOCATION_ADDRESS',
  'DESTINATION_CITY',
  'DESTINATION_STATE',
  'DESTINATION_ZIP',
];
const tractorPaymentTripFields = [
  'LOAD_ID',
  'TRIP_ID',
  'TERMINAL',
  // 'NUMBER_OF_STOPS',
  // 'OPERATION_TYPE',
  'LOADED_MILES',
  'EMPTY_MILES',
  'DRIVER_NAME',
  // 'EMPLOYMENT_TYPE',
  'SETTLEMENT_START_DATE',
  'SETTLEMENT_END_DATE',
  // 'SETTLEMENT_LINE_ITEMS',
  'TRIP_PAY',
  'DISPATCHER',
  'ASSIGNED_TRAILER',
  'ASSIGNED_TRACTOR',
  'ORIGIN_LOCATION_NAME',
  'ORIGIN_LOCATION_ADDRESS',
  'ORIGIN_CITY',
  'ORIGIN_STATE',
  'ORIGIN_ZIP',
  'DESTINATION_LOCATION_NAME',
  'DESTINATION_LOCATION_ADDRESS',
  'DESTINATION_CITY',
  'DESTINATION_STATE',
  'DESTINATION_ZIP',
];
const loadAndTripFields = [
  'LOAD_ID',
  // 'TRIP_ID',
  // 'LOAD_AXELE_ID',
  'REFERENCE_ID',
  // 'ORGANIZATION_ID',
  // 'TRIP_SEQUENCE',
  // 'LANE_SEQUENCE',
  'PRIMARY_DRIVER_NAME',
  // 'SECONDARY_DRIVER_NAME',
  // 'DRIVER_NAME',
  'TRACTOR_NAME',
  'TRAILER_NAME',
  'CUSTOMER_NAME',
  'CUSTOMER_TYPE',
  // 'ORIGIN_STOP_TYPE',
  // 'DESTINATION_STOP_TYPE',
  'LOAD_STATUS',
  // 'TERMINAL',
  'DISPATCHER_NAME',
  'LOAD_REVENUE',
  // 'GROSS_REVENUE',
  // 'LOAD_EXPENSE',
  // 'PRIMARY_DRIVER_SETTLEMENT',
  // 'SECONDARY_DRIVER_SETTLEMENT',
  // 'NET_INCOME',
  'INVOICE_NOTES',
  'DISPATCHER_NOTES',
  'PICKUP_LOCATION_NAME',
  'PICKUP_LOCATION_ADDRESS',
  'PICKUP_CONTACT_NAME',
  'PICKUP_CONTACT_NUMBER',
  'PICKUP_CITY',
  'PICKUP_STATE',
  'PICKUP_ZIP',
  'PICKUP_LATITUDE',
  'PICKUP_LONGITUDE',
  'PICKUP_EMAIL',
  'PICKUP_NOTES',
  'DROP_OFF_LOCATION_NAME',
  'DROP_OFF_CONTACT_NUMBER',
  'DROP_OFF_CONTACT_NAME',
  'DROP_OFF_LOCATION_ADDRESS',
  'DROP_OFF_CITY',
  'DROP_OFF_STATE',
  'DROP_OFF_ZIP',
  'DROP_OFF_TRAILER',
  'DROP_OFF_TRACTOR',
  'DROP_OFF_LATITUDE',
  'DROP_OFF_LONGITUDE',
  'DROP_OFF_NOTES',
  'DROP_OFF_EMAIL',
  // 'NUMBER_OF_STOPS',
  'PICKUP_START_DATE_TIME',
  'PICKUP_END_DATE_TIME',
  'PICKUP_ETA',
  'ACTUAL_PICKUP_ARRIVAL',
  'ACTUAL_PICKUP_DEPARTURE',
  'DROP_OFF_START_DATE_TIME',
  'DROP_OFF_END_DATE_TIME',
  'DROP_OFF_ETA',
  'ACTUAL_DROP_OFF_ARRIVAL',
  'ACTUAL_DROP_OFF_DEPARTURE',
  'INVOICE_CREATED_DATE',
  'INVOICE_PAID_DATE',
  'DWELL_TIME_PICKUP',
  // 'DETENTION_PICKUP',
  // 'DETENTION_DROP_OFF',
  'DWELL_TIME_DROP_OFF',
  'EQUIPMENT_TYPES',
  'COMMODITY_TYPES',
  'OPERATION_TYPE',
  'FACTORING_COMPANY',
  'LOADED_MILES',
  'EMPTY_MILES',
  // 'GROSS_PER_LOADED_MILES',
  // 'GROSS_PER_TOTAL_MILES',
  // 'NET_INCOME_PER_LOADED_MILES',
  // 'NET_INCOME_PER_TOTAL_MILES',
  'TOTAL_WEIGHT',
  'CHASSIS',
  'CONTAINER',
  'SEAL_NUMBER',
  // 'ODOMETER_READING',
  // 'INVOICE_LINE_ITEMS',
  // 'DRIVER_SETTLEMENT',
  // 'TRACTOR_SETTLEMENT',
];

const tripFields = [
  'LOAD_ID',
  'TRIP_ID',
  // 'LOAD_AXELE_ID',
  // 'REFERENCE_ID',
  // 'ORGANIZATION_ID',
  'TRIP_SEQUENCE',
  'BROKERAGE_TRIP',
  // 'LANE_SEQUENCE',
  'PRIMARY_DRIVER_NAME',
  // 'SECONDARY_DRIVER_NAME',
  'TRACTOR_NAME',
  'TRAILER_NAME',
  // 'CUSTOMER_NAME',
  // 'CUSTOMER_TYPE',
  // 'ORIGIN_STOP_TYPE',
  // 'DESTINATION_STOP_TYPE',
  'LOAD_STATUS',
  // 'TERMINAL',
  'DISPATCHER_NAME',
  'LOAD_REVENUE',
  // 'GROSS_REVENUE',
  // 'LOAD_EXPENSE',
  // 'PRIMARY_DRIVER_SETTLEMENT',
  // 'SECONDARY_DRIVER_SETTLEMENT',
  // 'NET_INCOME',
  // 'INVOICE_NOTES',
  'DISPATCHER_NOTES',
  'PICKUP_LOCATION_NAME',
  'PICKUP_LOCATION_ADDRESS',
  'PICKUP_CONTACT_NAME',
  'PICKUP_CONTACT_NUMBER',
  'PICKUP_CITY',
  'PICKUP_STATE',
  'PICKUP_ZIP',
  'PICKUP_LATITUDE',
  'PICKUP_LONGITUDE',
  'PICKUP_EMAIL',
  'PICKUP_NOTES',
  'DROP_OFF_LOCATION_NAME',
  'DROP_OFF_CONTACT_NUMBER',
  'DROP_OFF_CONTACT_NAME',
  'DROP_OFF_LOCATION_ADDRESS',
  'DROP_OFF_CITY',
  'DROP_OFF_STATE',
  'DROP_OFF_ZIP',
  // 'DROP_OFF_TRAILER',
  // 'DROP_OFF_TRACTOR',
  'DROP_OFF_LATITUDE',
  'DROP_OFF_LONGITUDE',
  'DROP_OFF_NOTES',
  'DROP_OFF_EMAIL',
  // 'NUMBER_OF_STOPS',
  'PICKUP_START_DATE_TIME',
  'PICKUP_END_DATE_TIME',
  'PICKUP_ETA',
  'ACTUAL_PICKUP_ARRIVAL',
  'ACTUAL_PICKUP_DEPARTURE',
  'DROP_OFF_START_DATE_TIME',
  'DROP_OFF_END_DATE_TIME',
  'DROP_OFF_ETA',
  'ACTUAL_DROP_OFF_ARRIVAL',
  'ACTUAL_DROP_OFF_DEPARTURE',
  'INVOICE_CREATED_DATE',
  'INVOICE_PAID_DATE',
  'DWELL_TIME_PICKUP',
  // 'DETENTION_PICKUP',
  // 'DETENTION_DROP_OFF',
  'DWELL_TIME_DROP_OFF',
  'EQUIPMENT_TYPES',
  'COMMODITY_TYPES',
  'OPERATION_TYPE',
  // 'FACTORING_COMPANY',
  'LOADED_MILES',
  'EMPTY_MILES',
  // 'GROSS_PER_LOADED_MILES',
  // 'GROSS_PER_TOTAL_MILES',
  // 'NET_INCOME_PER_LOADED_MILES',
  // 'NET_INCOME_PER_TOTAL_MILES',
  'TOTAL_WEIGHT',
  'CHASSIS',
  'CONTAINER',
  'SEAL_NUMBER',
  // 'ODOMETER_READING',
  // 'INVOICE_LINE_ITEMS',
  // 'DRIVER_SETTLEMENT',
  // 'TRACTOR_SETTLEMENT',
];

export const reportColumns = {
  [AdvancedReportList.LOAD.id]: {
    [AdvancedReportList.LOAD_AND_INVOICE.id]: {
      visibleFields: [...loadAndTripFields],
      hiddenFields: [],
    },
    [AdvancedReportList.TRIPS_AND_STOPS.id]: {
      visibleFields: [...tripFields],
      hiddenFields: [],
    },
    [AdvancedReportList.DRIVER_TRIP_PAYMENTS.id]: {
      visibleFields: [...driverAndTractorTripFields],
      hiddenFields: [],
    },
    [AdvancedReportList.TRACTOR_TRIP_PAYMENTS.id]: {
      visibleFields: [...tractorPaymentTripFields],
      hiddenFields: [],
    },
  },
  [AdvancedReportList.DRIVER_TIMEOFF.id]: {
    visibleFields: [
      'DRIVER_NAME',
      'STATUS',
      'CITY',
      'STATE',
      'STREET_ADDRESS',
      'APPOINTMENT_START_DATE',
      'APPOINTMENT_END_DATE',
      'ACTUAL_TIME_OF_ARRIVAL',
      'ACTUAL_TIME_OF_COMPLETION',
      'PLANNED_DURAION',
      'ACTUAL_TIME_OFF_DURATION',
    ],
    hiddenFields: [],
  },
  [AdvancedReportList.LOAD_TREND.id]: {
    visibleFields: [
      // 'ORGANIZATION_ID',
      'ENTITY_NAME',
      'LOADED_MILES',
      'GROSS_REVENUE',
      'EMPTY_MILES',
      'YEAR',
      'MONTH',
      // 'LOAD_COUNT',
      // 'LOADED_BY_TOTAL',
      // 'EMPTY_BY_TOTAL',
      // 'DRIVER_PAYMENT',
      'REVENUE_LOADED_MILE',
      'REVENUE_TOTAL_MILE',
      // 'LOAD_PAYMENT',
      // 'GROSS_REVENUE_TREND',
    ],
    hiddenFields: [],
  },
  [AdvancedReportList.IFTA.id]: {
    visibleFields: [],
    hiddenFields: [],
  },
} as { [key: string]: any };
