import { financeLoadService, paymentService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import { ExpenseCategory, PaymentType } from '../../../../models';
import { GetFinancialItemRequest } from '../../../../models/DTOs/FinanceLoadService';

export async function getExpenseCategories(): Promise<ExpenseCategory[]> {
  const expenseCategories = await paymentService.getExpenseCategories();
  if (expenseCategories instanceof ServiceError) {
    // should be decided what to do
    return [];
  } else if (expenseCategories != null) {
    return expenseCategories as ExpenseCategory[];
  }

  return [];
}

export async function getAllPaymentTypes(
  request: GetFinancialItemRequest
): Promise<PaymentType[]> {
  const paymentTypes = await financeLoadService.GetFinancialItem(request);
  if (paymentTypes instanceof ServiceError) {
  } else if (paymentTypes != null) {
    return paymentTypes as PaymentType[];
  }

  return [];
}

export const getAllPaymentTypesMap = (allPaymentTypes: PaymentType[]) => {
  const allPaymentTypesMap: { [key: string]: {} } = {};
  allPaymentTypes.forEach(
    (item: PaymentType) => (allPaymentTypesMap[item.id as string] = item)
  );
  return allPaymentTypesMap;
};
