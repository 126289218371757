import { useState, useEffect } from 'react';
import Events from '../../EventEmitter/EventEmitter';
import { EventTypes } from '../../EventEmitter/EventTypes';

export type EventCallbackFunction<TData = any> = (data: TData) => void;

const useEmit = (listeners: {
  [key in EventTypes]?: EventCallbackFunction;
}) => {
  const [entries] = useState(Object.entries(listeners));
  const callbacks = Object.values(listeners);
  useEffect(() => {
    for (let i = 0; i < entries.length; i += 1) {
      Events.on(entries[i][0], callbacks[i]);
    }
    return () => {
      for (let i = 0; i < entries.length; i += 1) {
        Events.off(entries[i][0], callbacks[i]);
      }
    };
  }, [entries, ...callbacks]);
};

export default useEmit;
