import { createContext, FC, useContext } from 'react';

export interface ITripDetailsPanelContext {
  onTripStopCreated?: () => void;
  onRelayCreated?: (response: any) => void;
}

export const TripDetailsPanelContext = createContext<ITripDetailsPanelContext>(
  {}
);

export const TripDetailsPanelContextProvider: FC<ITripDetailsPanelContext> = ({
  children,
  ...props
}) => {
  return (
    <TripDetailsPanelContext.Provider value={props}>
      {children}
    </TripDetailsPanelContext.Provider>
  );
};

export const useTripDetailsPanel = () => useContext(TripDetailsPanelContext);
