import { Box, Link, Skeleton, Stack, Typography } from '@mui/material';

import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EventEmitter from '../../../../EventEmitter/EventEmitter';
import { EventTypes } from '../../../../EventEmitter/EventTypes';
import { LoadTripActionData } from '../../../../EventEmitter/Events/EventLoadTripAction';
import EventTripStopAction, {
  EEventTripStopAction,
  EventTripStopActionData,
} from '../../../../EventEmitter/Events/EventTripStopAction';
import EventUpdateProgress, {
  EventUpdateProgressData,
} from '../../../../EventEmitter/Events/EventUpdateProgress';
import { loadService, tripService } from '../../../../api';
import {
  CARRIER_PAYMENT_RESOURCES,
  downloadDispatchConfirmationURL,
} from '../../../../api/impl/requestConstants';
import { ServiceError } from '../../../../api/interfaces';
import { useSecondaryDetailsPanel } from '../../../../common/HOC/withSecondaryDetailsPanel/useSecondaryDetailsPanel';
import { defaultStopData } from '../../../../common/LoadSharedComponents/constants';
import NoData from '../../../../common/LoadTabPanel/component/NoData';
import { ELoadStatus } from '../../../../common/LoadTabPanel/constants/constants';
import { IOpenSecondaryDetailsHandler } from '../../../../common/LoadTabPanel/constants/interface';
import FinanceOverviewFormWithProvider from '../../../../common/LoadTabPanel/tabs/FinanceOverview/FinanceOverview';
import { LoadOverviewDetails } from '../../../../common/LoadTabPanel/tabs/Overview/models';
import { Trip as LoadTrip } from '../../../../common/LoadTabPanel/tabs/Routes/Models';
import DispatchSheet from '../../../../common/LoadTabPanel/tabs/Routes/components/DispatchSheet';
import { LoadStopPopup } from '../../../../common/LoadTabPanel/tabs/Routes/components/LoadStopPopup';
import RelayStopPopup from '../../../../common/LoadTabPanel/tabs/Routes/components/RelayStopPopup';
import { RelayStopFormData } from '../../../../common/LoadTabPanel/tabs/Routes/components/RelayStopPopup/RelayStopForm';
import UpdateStopActivitiesDialog from '../../../../common/LoadTabPanel/tabs/Routes/components/UpdateStopDialog';
import { UpdateActivityFormData } from '../../../../common/LoadTabPanel/tabs/Routes/components/UpdateStopDialog/components/Form/UpdateStopForm';
import { TripAssignmentEntityType } from '../../../../common/LoadTabPanel/tabs/Routes/components/types';
import {
  initCreateRelayStopFormData,
  initUpdateActivitiesFormData,
  initUpdateRelayStopFormData,
  validateAndSaveStopLocation,
} from '../../../../common/LoadTabPanel/tabs/Routes/utils';
import { tripsModulePanel } from '../../../../common/PendoClassIDs/constants';
import SecondaryDetailsPanel from '../../../../common/SecondaryDetailsPanel/SecondaryDetailsPanel';
import PopupMap from '../../../../common/Ui/Maps/PopupMap/PopupMap';
import { MarkersData } from '../../../../common/Ui/Maps/types';
import TextField from '../../../../common/Ui/TextField/TextField';
import { useLoadPermission } from '../../../../common/hooks/useLoadPermission';
import { loadStatusesCompleted } from '../../../../constants/commonConstants';
import { useRoutesLoad } from '../../../../contexts/LoadRoutesContext';
import { useTripDetailsPanel } from '../../../../contexts/TripDetailsPanelContext';
import { LoadConstants } from '../../../../locales/en/allLoads/loads';
import {
  CreateTripStopResponse,
  GetAllStopsRequest,
  GetTripMapDataRequest,
  PreferencesDTO,
  ResequenceTripStopResponse,
  Trip,
  TripStop,
  TripStopResponse,
  UpdateLoadStopRequest,
} from '../../../../models';
import {
  CreateTripStopRequest,
  DeleteTripStopRequest,
  GetRelayStop,
  GetTripStopRequest,
  ResequenceTripStopRequest,
  UpdateTripStopRequest,
} from '../../../../models/DTOs/Trip/Requests';
import { filterDriverListByLocation } from '../../../../services/map/driverLocation.service'; // TODO : Shivam make centralize this function
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../store/root.context';
import { Stop } from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import {
  BrokeredHandlerTimelineV3EditIconEvents,
  ExpandableTimelineV3LoadIdEvents,
  ExpandableTimelineV3PanelView,
  StopSolutionV3NormalStop,
  StopSolutionV3StopType,
  TripStopStatusEnum,
  TripV3DataProps,
  TripV3ListWithAccordion,
} from '../../../../ui-kit/TripPlan';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import useEmit, {
  EventCallbackFunction,
} from '../../../../utils/hooks/useEmit';
import { updateNoteDetails } from '../../../../utils/loads';
import { ESecondaryDetailsPanelType } from '../../../dispatch2/constants/types';
import { convertTripIntoLoadTrip, toKpi, toTripPlan } from '../../utils';
import { KPI } from '../KPI/KPI';
import { StyledPopupContent } from '../PopupActions/styles';
export interface TripPlanProps
  extends ExpandableTimelineV3LoadIdEvents,
    BrokeredHandlerTimelineV3EditIconEvents {
  trip: Trip;
  closeDetailsPanel?: () => void;
  onRelayDeleted?: (data: Partial<RelayStopFormData>) => void;
  onUpdatedTrips?: (data: {
    deletedIds: string[];
    updatedIds: string[];
  }) => void;
  onUpdateActivitiesCompleted?: () => void;
  preferences: PreferencesDTO | null;
}
export const loadIdStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
  pt: '5px',
};
export const searchHelperLinkStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'Medium',
  fontSize: '13px',
  lineHeight: '22px',
  letterSpacing: '0.4px',
  textDecoration: 'underline',
  fontWeight: 500,
  color: 'primary.main',
  pl: '4px',
};

interface StopPopupProps {
  open: boolean;
  data: Stop;
  previousStopId: number | null;
  loadId?: string | null;
  stopType: string;
  deleteAction: boolean;
}

interface IDeleteTripPopupState {
  open: boolean;
  moveUp: boolean;
  tripStopId: number | null;
  message: string;
  deleteCurrentTrip: boolean;
}

export const TripPlan: FC<TripPlanProps> = observer(
  ({
    trip: tripProps,
    closeDetailsPanel,
    onRelayDeleted,
    onUpdatedTrips,
    onClickLoadIdHandler,
    onClickBrokeredHandler,
    onUpdateActivitiesCompleted,
    preferences,
  }) => {
    const { trackPromise } = useDetailsPanelStore;
    const {
      selectedDriverId,
      setSelectedDriverId,
      driverAmountForAssign,
      setDriverAmountForAssign,
    } = useRoutesLoad();

    const [loadTrip, setLoadTrip] = useState<LoadTrip>();

    const { hasLoadEditPermission } = useLoadPermission();
    const { getTripPlanReadyEvent, resetTripPlanReadyEvent } = useRootStore();

    const [stopPopupState, setStopPopupState] = useState<StopPopupProps>({
      open: false,
      data: {} as Stop,
      previousStopId: null,
      deleteAction: false,
      stopType: '',
      loadId: null,
    });
    const [deleteTripPopupState, setDeleteTripPopupState] =
      useState<IDeleteTripPopupState>({
        open: false,
        moveUp: false,
        tripStopId: null,
        message: '',
        deleteCurrentTrip: false,
      });
    const [trip, setTrip] = useState<Trip>({} as Trip);
    const [tripStop, setTripStop] = useState<TripStopResponse>();
    const { tripsStore } = useStores();
    const { filters, updateMainData } = tripsStore;
    const { secondaryDetailsPanelData, setSecondaryDetailsPanelData } =
      useSecondaryDetailsPanel();
    const [relayStop, setRelayStop] =
      useState<Partial<RelayStopFormData> | null>(null);
    const [activities, setActivities] = useState<UpdateActivityFormData | null>(
      null
    );
    const [openMapPopup, setOpenMapPopup] = useState<boolean>(false);
    const [isOpeningTripAssignment, setOpeningTripAssignment] =
      useState<boolean>(false);
    const [mapData, setMapData] = useState<MarkersData>();
    const { control, setValue } = useFormContext();
    const { onRelayCreated } = useTripDetailsPanel();
    const [dispatchRevenue, setDispatchRevenue] = useState<string>();
    const newTripId = useRef('');
    const [isLoading, setIsLoading] = useState(true);
    const onChangedTripStop = (
      action: EEventTripStopAction,
      tripId?: string
    ): void => {
      EventEmitter.send(
        new EventTripStopAction({
          toType: ESecondaryDetailsPanelType.LOAD,
          action,
          tripId: tripId || trip.id,
          updatedTripIds: [tripId || trip.id],
          loadId: trip?.connectedLoads?.[0]?.loadId,
        })
      );
    };
    const refreshExternalData = async () => {
      updateMainData?.(
        newTripId.current !== trip.id
          ? { updatedIds: [newTripId.current], deletedIds: [trip.id] }
          : { updatedIds: [trip.id] }
      );
      newTripId.current = '';
    };

    const handleAssignmentChange = (data) => {
      if (data.trip.id === tripProps.id) {
        getData(tripProps.id);
      }
    };

    const handleLoadTripAction: EventCallbackFunction<LoadTripActionData> = (
      data
    ) => {
      if (
        data.toStatus !== ELoadStatus.DELETED &&
        ((data.entity === 'Trip' && data.id === tripProps.id) ||
          (data.entity === 'Load' && data.tripIds?.includes(tripProps.id)))
      ) {
        getData(tripProps.id);
      }
    };

    const handleTripStopAction: EventCallbackFunction<
      EventTripStopActionData
    > = (data: EventTripStopActionData) => {
      if (
        data.tripId === tripProps?.id ||
        data.loadId === tripProps?.connectedLoads?.[0]?.loadId
      ) {
        if (
          data.action === EEventTripStopAction.DELETED &&
          !data.updatedTripIds
        ) {
          refreshExternalData();
        } else {
          newTripId.current = data.tripId as string;
          getData(tripProps.id);
        }
      }
    };

    const handleUpdateProgress: EventCallbackFunction<
      EventUpdateProgressData
    > = (data) => {
      if (data.toTypes.includes(ESecondaryDetailsPanelType.TRIP)) {
        getData();
      }
    };

    const handleUpdatedOverview: EventCallbackFunction<LoadOverviewDetails> = (
      data
    ) => {
      if (data.seqNumber !== tripProps.seqNumber) getData();
    };

    useEmit({
      [EventTypes.TRIP_ASSIGNMENT_CHANGE]: handleAssignmentChange,
      [EventTypes.LOAD_TRIP_ACTION]: handleLoadTripAction,
      [EventTypes.TRIP_STOP_ACTION]: handleTripStopAction,
      [EventTypes.UPDATE_PROGRESS]: handleUpdateProgress,
      [EventTypes.LOAD_OVERVIEW_CHANGE]: handleUpdatedOverview,
    });

    const getData = async (tripId?: string, updateMainData = true) => {
      const tmpTripId = tripId || trip.id;
      if (!tmpTripId) return;

      const response = await trackPromise(
        tripService.getTripStop(
          new GetTripStopRequest({
            tripId: tmpTripId,
            fetchHosData: true,
          })
        ),
        ESecondaryDetailsPanelType.TRIP
      );
      if (response instanceof TripStopResponse) {
        setTripStop(response);
        setLoadTrip(convertTripIntoLoadTrip(response));
        setDispatchRevenue(
          response.dispatchRevenue === null
            ? `${response.revenue || ''}`
            : `${response.dispatchRevenue}`
        );
        if (updateMainData)
          tripsStore.updateMainData({
            deletedIds: [],
            updatedIds: [tripId],
          });
        setTimeout(() => {
          setValue(
            'dispatchRevenue',
            response.dispatchRevenue === null
              ? `${response.revenue || ''}`
              : `${response.dispatchRevenue}`
          );
        }, 300);
        setIsLoading(false);
      }
    };

    const getMapsData = async (tripId?: string) => {
      if (!tripId) return;
      const markersList: any = [];
      const request = new GetTripMapDataRequest();
      request.tripId = tripId;
      const response = await trackPromise(
        loadService.getTripMapData(request),
        ESecondaryDetailsPanelType.TRIP
      );
      response?.markers?.map((marker: any) => {
        if (marker.driverToolTip) {
          const driverMarker = filterDriverListByLocation({
            drivers: marker?.driverToolTip?.drivers?.map?.((e: any) => ({
              ...e,
              routeFlag: marker?.routeFlag,
            })),
            currentWeek: marker?.driverToolTip?.currentWeek,
            hosConstants: marker?.driverToolTip?.hosConstants,
            isTooltipActionDisabled: true,
          });
          driverMarker?.locatedDrivers?.length
            ? markersList.push(...driverMarker?.locatedDrivers)
            : '';
        } else {
          markersList.push(marker);
        }
      });
      setMapData({ markers: markersList } as unknown as MarkersData);
    };

    useEffect(() => {
      tripProps.dispatchRevenue =
        tripProps.dispatchRevenue || Number(dispatchRevenue);
      setTrip(tripProps);
    }, [tripProps]);

    useEffect(() => {
      //Do not change/Important: @Sinh - DependencyList should be primitive types
      //getData in TripDetailsPanel.tsx will trigger re-rendering and cause 2 times call these APIs
      getData(tripProps.id, false);
      getMapsData(tripProps.id);
    }, [tripProps?.id]);

    useEffect(() => {
      //Refresh Trip stop when toggle Brokered
      if (
        tripProps?.hasOwnProperty('brokerageTrip') &&
        trip?.hasOwnProperty('brokerageTrip') &&
        trip?.brokerageTrip !== tripProps?.brokerageTrip
      ) {
        getData(tripProps.id);
      }
    }, [tripProps?.brokerageTrip]);

    useEffect(() => {
      if (
        getTripPlanReadyEvent &&
        getTripPlanReadyEvent?.driverGroupId === tripProps.driverGroupId
      ) {
        getData(tripProps.id);
        getMapsData(tripProps.id);
        resetTripPlanReadyEvent();
      }
    }, [getTripPlanReadyEvent]);

    const fetchTripList = () => {
      if (filters && Object.keys(filters).length) {
        tripsStore.fetchMainData({
          nextFilters: filters,
          nextPageNumber: 1,
        });
      }
    };

    const closeDeleteTripPopup = () => {
      setDeleteTripPopupState({
        open: false,
        moveUp: false,
        tripStopId: null,
        message: '',
        deleteCurrentTrip: false,
      });
    };

    const closePopup = () => {
      setStopPopupState({
        open: false,
        data: {} as Stop,
        previousStopId: null,
        stopType: '',
        loadId: null,
        deleteAction: false,
      });
    };

    const { onTripStopCreated } = useTripDetailsPanel();

    const handleStopPopupAction = async (stop: Stop, formState: any) => {
      const { savedLocationId } = await validateAndSaveStopLocation(stop);
      if (savedLocationId && stop.location) {
        stop.location.id = savedLocationId;
      }
      updateNoteDetails(stop, 'notes', formState.dirtyFields);
      if (!stopPopupState.data?.id) {
        await createStop(stop);
        onTripStopCreated?.();
      } else {
        await updateStop(stop);
      }
      await refreshExternalData();
      closePopup();
    };

    const onCreateStop = (previousStop: TripStop) => {
      setStopPopupState((prevState) => ({
        ...prevState,
        open: true,
        data: { ...defaultStopData },
        previousStopId: previousStop?.id,
        stopType: 'TRIP',
      }));
    };

    const onUpdateStop = async (
      stop: StopSolutionV3NormalStop,
      trip: TripV3DataProps
    ) => {
      const stopIndex = (tripStop as TripStopResponse).stops.findIndex(
        ({ id }) => +id === stop.id
      );
      const currentStop = (tripStop as TripStopResponse).stops[
        stopIndex
      ] as TripStop;
      if (stop.type === StopSolutionV3StopType.RELAY) {
        const response = await tripService.getRelayStop(
          new GetRelayStop({
            relayStopId: stop.id,
            tripId: trip.id,
          })
        );
        if (!(response instanceof ServiceError)) {
          const type = stopIndex === 0 ? 'pickup' : 'dropOff';
          setRelayStop(
            initUpdateRelayStopFormData(response, currentStop, tripProps, type)
          );
        }
      } else {
        const stopDetails = tripStop?.stops.find((st) => st.id === stop.id);
        const loadStop = new Stop();
        loadStop.getStopDetailsFromLoadStopDTO(stopDetails);
        if (stop.type === 'LOAD') {
          loadStop.loadStopId = stop.loadStopId;
        }
        setStopPopupState((prevState) => ({
          ...prevState,
          open: true,
          data: loadStop,
          stopType: stop.type,
          loadId:
            stop.type === 'LOAD'
              ? stop.loadDetails && stop.loadDetails[0]?.loadId
              : null,
          deleteAction:
            stop.type === 'TRIP' && stop.status === TripStopStatusEnum.NONE,
        }));
      }
    };

    const createStop = async (stop: Stop) => {
      const response = await tripService.createTripStop(
        new CreateTripStopRequest({
          stop,
          tripId: trip.id,
          previousTripStopId: stopPopupState.previousStopId,
        })
      );
      if (response instanceof CreateTripStopResponse) {
        // @TODO: Nvard - clarify with backend what fields can be affected and should be updated in trip
        setTrip((prevTrip) => ({
          ...prevTrip,
          id: response.id,
        }));
        if (response.id !== trip.id) {
          tripsStore.updateMainData({
            updatedIds: [response.id],
            deletedIds: [trip.id],
          });
        }
        getData(response.id);
        onChangedTripStop(EEventTripStopAction.CREATED, response.id);
      }
    };

    const updateStop = async (stop: Stop) => {
      const { stopType, loadId } = stopPopupState;
      if (stopType === 'TRIP') {
        await tripService.updateTripStop(
          new UpdateTripStopRequest({
            stop,
            loadId: trip.id,
          })
        );
      } else if (stopType === 'LOAD') {
        await loadService.updateLoadStop(
          new UpdateLoadStopRequest({
            stop: {
              ...stop,
              id: stop.loadStopId || stop.id,
            } as Stop,
            loadId: loadId || null,
          })
        );
      }
      getData();
      onChangedTripStop(EEventTripStopAction.UPDATED);
    };

    const deleteTripStop = async () => {
      await tripService.deleteTripStop(
        new DeleteTripStopRequest({
          tripId: trip.id,
          stopId: stopPopupState.data.id,
        })
      );
      getData();
      onChangedTripStop(EEventTripStopAction.DELETED);
      refreshExternalData();
      closePopup();
    };

    const onResequenceToAdjacentTrip = async () => {
      const request = new ResequenceTripStopRequest({
        tripId: trip.id,
        tripStopId: deleteTripPopupState.tripStopId,
        moveUp: deleteTripPopupState.moveUp,
        applyMoveDeletion: true,
      });

      const response = await tripService.resequenceTripStop(request);

      if (response instanceof ResequenceTripStopResponse) {
        if (deleteTripPopupState.deleteCurrentTrip) {
          closeDeleteTripPopup();
          if (typeof closeDetailsPanel === 'function') {
            closeDetailsPanel();
          }
          fetchTripList();
          refreshExternalData();
        } else {
          closeDeleteTripPopup();
          getData();
          onChangedTripStop(EEventTripStopAction.RESEQUENCED);
          refreshExternalData();
        }
      }
    };

    const onClickResequenceHandler = async (
      stop: StopSolutionV3NormalStop,
      moveUp: boolean
    ) => {
      const request = new ResequenceTripStopRequest({
        tripId: trip.id,
        tripStopId: stop.id,
        moveUp,
        applyMoveDeletion: false,
      });
      const response = await tripService.resequenceTripStop(request);
      if (response instanceof ResequenceTripStopResponse) {
        const { applied } = response;
        if (!applied) {
          const { removableTripIdToSeqNumber, updatedTripIdToSeqNumber } =
            response;
          if (
            removableTripIdToSeqNumber &&
            removableTripIdToSeqNumber[trip.id]
          ) {
            const { previousTripDetails, nextTripDetails } = tripStop || {};
            const targetTripId = moveUp
              ? previousTripDetails?.seqNumber || ''
              : nextTripDetails?.seqNumber || '';
            const message = `This action will move the stop to the trip ${targetTripId} and also delete the Trip(s) ${trip.seqNumber}.`;
            setDeleteTripPopupState({
              open: true,
              tripStopId: stop.id,
              moveUp,
              message,
              deleteCurrentTrip: true,
            });
          } else if (!isEmpty(updatedTripIdToSeqNumber)) {
            const targetTripId = Object.values(updatedTripIdToSeqNumber)[0];
            if (targetTripId) {
              setDeleteTripPopupState({
                open: true,
                tripStopId: stop.id,
                moveUp,
                message: `Re-sequencing the stop will will move the stop to the trip ${targetTripId}.`,
                deleteCurrentTrip: false,
              });
            } else {
              getData();
              onChangedTripStop(EEventTripStopAction.RESEQUENCED);
              refreshExternalData();
            }
          }
        } else {
          getData();
          onChangedTripStop(EEventTripStopAction.RESEQUENCED);
          refreshExternalData();
        }
      }
    };

    const onPaymentTmpUpdate = (amountSelected) => {
      setDriverAmountForAssign((prev) => ({
        ...prev,
        [amountSelected.driverId ||
        amountSelected.tractorId ||
        amountSelected.dispatcherId ||
        amountSelected.carrierId]: amountSelected,
      }));
    };
    const renderFinance = () => {
      // @TODO: Anoush should be refactored this render finance part, as it is also used in another place[load - routes tab]
      if (selectedDriverId && tripStop) {
        tripsStore.setShowRenderFinance(true);
        return (
          <Box
            classname="renderFinance"
            sx={{
              position: 'absolute',
              zIndex: 100,
              backgroundColor: 'primary.contrast',
              height: '100%',
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'scroll',
            }}
          >
            <FinanceOverviewFormWithProvider
              loadId={tripStop.id}
              onClose={() => setSelectedDriverId(null)}
              openPayments={{
                type: selectedDriverId.type,
                driverAmountForAssign:
                  driverAmountForAssign[selectedDriverId.id],
                onPaymentTmpUpdate,
              }}
              panelType={ESecondaryDetailsPanelType.TRIP}
            />
          </Box>
        );
      } else {
        tripsStore.setShowRenderFinance(false);
      }
    };

    const commonConvertTripParams: Pick<
      IOpenSecondaryDetailsHandler,
      'disableAssignment' | 'showProgressIcon'
    > = {
      showProgressIcon: hasLoadEditPermission && !trip.onHold,
      disabled: !hasLoadEditPermission || trip.onHold,
      disableAssignment: {
        [TripAssignmentEntityType.DRIVER]: trip.onHold
          ? {
              disabled: !!trip.onHold,
              reason: LoadConstants.loadIsCurrentlyOnHold,
            }
          : undefined,
      },
    };
    const tripDataList = useMemo(
      () =>
        tripStop
          ? toTripPlan({
              data: tripStop,
              trip,
              loadTrip,
              isOpeningTripAssignment,
              setOpeningTripAssignment,
              isAssignmentDisable: false,
              ...commonConvertTripParams,
            })
          : [],
      [
        tripStop,
        trip,
        loadTrip,
        isOpeningTripAssignment,
        setOpeningTripAssignment,
        trip.onHold,
        hasLoadEditPermission,
        JSON.stringify(commonConvertTripParams),
      ]
    );

    if (!tripStop && !isLoading) return <NoData />;

    const onMapClick = () => {
      setOpenMapPopup(true);
    };

    const onClickProgressIconHandler = async (a, tripProp) => {
      if (!tripStop) return;
      const firstStop = tripStop.stops[0] as TripStop;
      const response = await loadService.getAllActivities(
        new GetAllStopsRequest({
          tripOrLoadId: tripProp.id,
        })
      );
      if (!(response instanceof ServiceError)) {
        setActivities(
          initUpdateActivitiesFormData(
            response,
            tripStop.id,
            tripStop.status,
            firstStop.location,
            'TRAILER',
            tripStop.previousTripDetails?.status,
            trip.assignment.trailerId
              ? {
                  id: trip.assignment.trailerId,
                  name: trip.assignment.trailerName,
                }
              : null,
            trip.assignment.tractorId
              ? {
                  id: trip.assignment.tractorId,
                  name: trip.assignment.tractorName,
                }
              : null,
            tripStop?.stops.reduce<string | undefined>((loadId, stop) => {
              return loadId || stop.loadDetails?.[0]?.loadId;
            }, undefined)
          )
        );
      }
    };
    const { t, ready } = useTranslation();
    if (ready) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'primary.contrast',
            height: '100%',
            pt: 2,
            overflow: selectedDriverId ? 'hidden' : '',
          }}
        >
          <StyledPopupContent
            style={{
              overflow: selectedDriverId ? 'hidden' : '',
              position: selectedDriverId ? 'initial' : 'relative',
            }}
          >
            {renderFinance()}
            <KPI
              data={Boolean(tripStop) && toKpi(tripStop, trip?.isEmptyTrip)}
              isLoading={isLoading}
            />
            <PopupMap
              openPopup={openMapPopup}
              handleClose={() => setOpenMapPopup(false)}
              markersList={mapData ? mapData.markers : []}
              generateRoute
            />

            <Box sx={{ p: 2, pt: 0 }}>
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'2rem'}
                />
              ) : (
                <DispatchSheet
                  label={`${
                    trip?.brokerageTrip
                      ? t('DispatchSheetCarrier')
                      : t('DispatchSheetDispatch')
                  } ${t('DispatchSheetRateConfirmation')}`}
                  loadId={trip?.id}
                  fileName={`${
                    trip?.brokerageTrip ? 'Carrier' : 'Dispatch'
                  }_Rate_Confirmation}`}
                  terminal={
                    trip?.terminal?.name || preferences?.companyDetails?.name
                  }
                  seqNumber={trip?.seqNumber}
                  downloadURL={
                    trip?.brokerageTrip
                      ? CARRIER_PAYMENT_RESOURCES.downloadCarrierRateConfirmationSheet
                      : downloadDispatchConfirmationURL
                  }
                  carrierId={trip?.carrierDetails?.id}
                  isDisabledAllAction={trip.onHold}
                />
              )}

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                style={{ padding: '0px 0px 4px 0px' }}
              >
                {isLoading ? (
                  <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{ mt: '2rem', alignItems: 'center' }}
                  >
                    <Skeleton variant="text" height={'3rem'} width={'24rem'} />
                    <Skeleton variant="text" height={'3rem'} width={'4rem'} />
                  </Stack>
                ) : (
                  <>
                    {!trip?.isEmptyTrip && (
                      <Typography sx={loadIdStyle}>
                        {trip.manifestBaseDetails?.seqNumber
                          ? ' Manifest ID:'
                          : ' Load ID:'}
                        <Link
                          sx={searchHelperLinkStyle}
                          href="#"
                          onClick={() => {
                            setSecondaryDetailsPanelData?.({
                              id:
                                trip?.manifestBaseDetails?.manifestId ||
                                tripStop?.loadDetails?.loadId,
                              type: 'LOAD',
                            });
                          }}
                        >
                          {trip.manifestBaseDetails?.seqNumber ||
                            tripStop?.loadDetails?.seqNumber}
                        </Link>
                      </Typography>
                    )}

                    <ButtonImproved
                      style={{ marginLeft: 'auto' }}
                      onClick={onMapClick}
                      label={t('TripPlanViewMap')}
                      variant={'outlined'}
                    ></ButtonImproved>
                  </>
                )}
              </Stack>

              {!trip?.brokerageTrip && (
                <>
                  {isLoading ? (
                    <Skeleton width={'100%'} height="4rem" sx={{ br: '5px' }} />
                  ) : (
                    <TextField
                      id={tripsModulePanel + 'DispatchRevenue'}
                      control={control}
                      disabled={
                        loadStatusesCompleted.includes(trip.status) ||
                        !hasLoadEditPermission ||
                        trip.onHold
                      }
                      name="dispatchRevenue"
                      label={t('TripPlanDispatchRevenue')}
                      style={{ marginBottom: 0 }}
                      sizes={{ xs: 12 }}
                      required
                    />
                  )}
                </>
              )}
            </Box>
            <Stack>
              {isLoading ? (
                <Box sx={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
                  <Skeleton
                    variant="rectangular"
                    height={'20vh'}
                    width={'100%'}
                    sx={{ borderRadius: '5px' }}
                  />
                </Box>
              ) : (
                <TripV3ListWithAccordion
                  panelView={ExpandableTimelineV3PanelView.TripDetails}
                  isMultipleExpanded={true}
                  tripDataList={tripDataList}
                  onClickResequenceUpHandler={(
                    stop: StopSolutionV3NormalStop
                  ) => {
                    onClickResequenceHandler(stop, true);
                  }}
                  onClickResequenceDownHandler={(
                    stop: StopSolutionV3NormalStop
                  ) => {
                    onClickResequenceHandler(stop, false);
                  }}
                  onClickProgressIconHandler={onClickProgressIconHandler}
                  onClickEditIconHandler={onUpdateStop}
                  onClickAddStopHandler={onCreateStop}
                  onClickAddRelayHandler={(leg) => {
                    const stop = tripStop?.stops.find(
                      ({ id }) => id === leg.id
                    ) as TripStop;
                    setRelayStop(initCreateRelayStopFormData(stop, tripStop));
                  }}
                  onClickLoadIdHandler={onClickLoadIdHandler}
                  onClickBrokeredHandler={onClickBrokeredHandler}
                />
              )}
            </Stack>
          </StyledPopupContent>
          {stopPopupState.open && (
            <LoadStopPopup
              onClose={closePopup}
              onCreated={handleStopPopupAction}
              data={stopPopupState.data}
              stopType={stopPopupState.stopType}
              isStatusAvailable={!!(trip?.status === 'AVAILABLE')}
              {...(stopPopupState.stopType === 'TRIP' &&
                stopPopupState.deleteAction && { onDelete: deleteTripStop })}
            />
          )}
          {relayStop && (
            <RelayStopPopup
              onCreated={(response) => {
                onRelayCreated?.(response);
                setRelayStop(null);
                const newTripIds = [
                  response.pickupLoad.id,
                  response.dropoffLoad.id,
                ];
                if (trip.id === newTripIds[0]) {
                  getData();
                }
                const shouldDeleteTripId =
                  Array.from(new Set([trip.id, ...newTripIds])).length !==
                  newTripIds.length;
                onUpdatedTrips?.({
                  updatedIds: Array.from(new Set([trip.id, ...newTripIds])),
                  deletedIds: [shouldDeleteTripId ? trip.id : ''],
                });
                onChangedTripStop(EEventTripStopAction.CREATED);
              }}
              onUpdated={() => {
                setRelayStop(null);
                getData();
                onChangedTripStop(EEventTripStopAction.UPDATED);
              }}
              onDeleted={(data) => {
                onRelayDeleted?.(data);
                setRelayStop(null);
                onChangedTripStop(EEventTripStopAction.DELETED);
                if (data.type === 'dropOff') {
                  getData();
                }
              }}
              data={relayStop}
              onClose={() => setRelayStop(null)}
              isStatusAvailable={!!(trip?.status === 'AVAILABLE')}
            />
          )}
          {deleteTripPopupState.open && (
            <DeletePopup
              title="Re-sequence the stop?"
              body={deleteTripPopupState.message}
              open={deleteTripPopupState.open}
              onAction={onResequenceToAdjacentTrip}
              onClose={closeDeleteTripPopup}
              {...(!deleteTripPopupState.deleteCurrentTrip && {
                buttonText: 'Continue',
              })}
            />
          )}

          {activities && (
            <UpdateStopActivitiesDialog
              onClose={() => setActivities(null)}
              onAction={() => {
                setActivities(null);
                getData();
                onUpdateActivitiesCompleted?.();
                EventEmitter.send(
                  new EventUpdateProgress({
                    toTypes: [ESecondaryDetailsPanelType.LOAD],
                  })
                );
              }}
              data={activities}
            />
          )}
          {secondaryDetailsPanelData && (
            <SecondaryDetailsPanel
              type={secondaryDetailsPanelData.type}
              id={secondaryDetailsPanelData.id}
              onClose={() => {
                setSecondaryDetailsPanelData(null);
              }}
              isGlobal={true}
              // readonly={true}
              data={secondaryDetailsPanelData.data}
            />
          )}
        </Box>
      );
    }
  }
);
