import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

export const AutocompleteAsync: React.FC<{
  name: string;
  getOptions: (value: string) => any;
  fieldName: string;
  defaultValue?: any;
  label: string;
  onChangeCb?: (data: any[], name: string) => void;
  customStyles?: any;
  customTextInputStyles?: any;
  value: any;
  error?: { message: string };
  disabled?: boolean;
}> = ({
  name,
  getOptions,
  fieldName,
  defaultValue = [],
  label,
  onChangeCb,
  customStyles = {},
  customTextInputStyles = {},
  value,
  error,
  disabled,
}) => {
  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState(false);

  const makeRequest = async (val: string) => {
    setLoading(true);
    const options = await getOptions(val);
    setOptions(options);
    setLoading(false);
  };

  const handleClose = () => {
    setLoading(false);
    setOptions([]);
  };

  const handleOpen = () => {
    makeRequest('');
  };

  const handleInputChange = async (value: string) => {
    makeRequest(value);
  };

  const handleChange = (data: any[]) => {
    onChangeCb && onChangeCb(data, name);
  };

  return (
    <MuiAutocomplete
      size="small"
      multiple
      limitTags={1}
      id="multiple-autocomplete-async"
      onClose={handleClose}
      onOpen={handleOpen}
      options={options}
      value={value}
      disableCloseOnSelect
      defaultValue={defaultValue}
      onInputChange={(_, newValue) => handleInputChange(newValue)}
      loading={loading}
      isOptionEqualToValue={(option: any, value) =>
        option[fieldName] === value[fieldName]
      }
      sx={customStyles}
      onChange={(event, value) => handleChange(value)}
      getOptionLabel={(option: any) => option[fieldName]}
      disabled={disabled}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option[fieldName]}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={'standard'}
          sx={customTextInputStyles}
          label={label}
          error={!!error}
          helperText={error ? error.message : null}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
