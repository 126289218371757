import { Box, CircularProgress, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { memo, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormAddress } from '../../../Ui/AddressField';
import Switch from '../../../Ui/Switcher';
import { UpdateHOSFormProps } from '../../types';
import { SourceAndTime } from './SourceAndTime';
import { UpdateOnlyHOSForm } from './UpdateOnlyHOSForm';

export const UpdateHOSForm = memo((props: UpdateHOSFormProps): JSX.Element => {
  const { myloadHoS, isLoading, canUpdateHos, values } = props;
  const { control, watch, reset } = useFormContext();
  const driversHosDataList = useWatch({
    control,
    name: `driversHosDataList`,
    defaultValue: props.driversHosDataList,
  });

  useEffect(() => {
    if (!isLoading) {
      reset(values);
    }
  }, [isLoading]);

  const enableLocation = watch(`enableLocation`);

  return (
    <>
      {isLoading ? (
        <Box>
          <Skeleton
            variant="rectangular"
            width={'100%'}
            height={'80px'}
            sx={{ marginBottom: '20px' }}
          />
          <Skeleton variant="rectangular" width={'100%'} height={'150px'} />
        </Box>
      ) : myloadHoS ? (
        <Grid spacing={1} container>
          <Grid xs={12} item>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Switch
                control={control}
                label="Location"
                name={`enableLocation`}
                sizes={{ xs: 6 }}
              />
              {enableLocation && <SourceAndTime myloadHoS={myloadHoS} />}
            </Stack>
          </Grid>
          {enableLocation && (
            <Grid xs={12} item>
              <FormAddress
                required={true}
                control={control}
                label="Location (Address/Zip Code/ City)"
                name={`location`}
              />
            </Grid>
          )}

          <Grid container spacing={1}>
            {driversHosDataList &&
              driversHosDataList.length > 0 &&
              driversHosDataList.map((_: any, foundIndex: number) => {
                return (
                  <UpdateOnlyHOSForm
                    {...props}
                    key={foundIndex}
                    foundIndex={foundIndex}
                    canUpdateHos={canUpdateHos}
                  />
                );
              })}
          </Grid>
        </Grid>
      ) : (
        <>No Data</>
      )}
    </>
  );
});
UpdateHOSForm.displayName = 'UpdateHOSForm';
