import { Edit, HighlightOff, Map } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CustomGeofenceIcon } from '../../../../../_assets/static/svgs/CustomGeofenceLogo';
interface IProps {
  stopIndex: number;
  onMapView?: () => void;
  onClose?: () => void;
  onEdit?: () => void;
  showCollapse?: boolean;
  onCollapse?: () => void;
}

const LocationSummary = ({
  stopIndex,
  onMapView,
  onClose,
  onEdit,
  showCollapse,
  onCollapse,
}: IProps) => {
  const { watch } = useFormContext();
  const selBusiness = watch(`stops.${stopIndex}`);
  const loadHiddenFields = watch('hiddenFields.stops');

  return (
    <Stack direction="row" alignItems="top">
      <Stack
        direction="column"
        flexGrow="1"
        sx={{
          overflow: 'hidden',
          '& *': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <Typography variant="body1">
          <b>{selBusiness.location?.businessName}</b>
        </Typography>

        <Stack direction="row" gap={0.5} alignItems={'center'}>
          {!!selBusiness.location?.geoCoordinates?.length && (
            <CustomGeofenceIcon />
          )}
          <Typography variant="body2">
            {selBusiness.location?.address}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <>
            {selBusiness.contact?.contactName && (
              <>
                <Typography variant="body2">
                  {selBusiness?.contact?.contactName}
                </Typography>

                {selBusiness?.contact.phone && (
                  <>
                    <Divider orientation="vertical" sx={{ height: '15px' }} />
                    <Typography variant="body2">
                      {selBusiness?.contact?.phone}
                    </Typography>
                  </>
                )}
                {selBusiness?.contact.email && (
                  <>
                    <Divider orientation="vertical" sx={{ height: '15px' }} />
                    <Typography variant="body2">
                      {selBusiness?.contact?.email}
                    </Typography>
                  </>
                )}
              </>
            )}
          </>
        </Stack>
        {selBusiness?.referenceNumber && (
          <Stack direction="row" gap={1}>
            <span style={{ fontWeight: 400, fontSize: '14px' }}>
              Reference #
            </span>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                flexGrow: '1',
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {selBusiness.referenceNumber}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        className="business-hoverActions"
      >
        <IconButton onClick={onClose}>
          <HighlightOff />
        </IconButton>
        <IconButton onClick={onMapView}>
          <Map />
        </IconButton>
        {showCollapse ? (
          <IconButton onClick={onCollapse}>
            <ExpandLessIcon />
          </IconButton>
        ) : (
          // !loadHiddenFields?.editAddress && (
          <IconButton onClick={onEdit}>
            <Edit />
          </IconButton>
          // )
        )}
      </Stack>
    </Stack>
  );
};

export { LocationSummary };
