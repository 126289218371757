import React, { useEffect, useState } from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  useTheme,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ImportActions from '../../Import';
import { ThemeProvider } from '@mui/material/styles';
import { possibleLineItemTypes } from './constants';
import { theme } from './DateFormatSelect';
import { useImport } from '../../../../../contexts/ImportContext';

export default function LineItemTypeSelect() {
  const { setSelectedFileType, selectedFileType } = useImport();
  const [lineItemType, setLineItemType] = useState(
    selectedFileType || 'City Based'
  );
  useEffect(() => {
    setSelectedFileType(selectedFileType || 'City Based');
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    event.stopPropagation();
    setLineItemType(event.target.value);
    setSelectedFileType(event.target.value);
    ImportActions.lineItemType = event.target.value;
  };

  const currentTheme = useTheme();

  return (
    <ThemeProvider theme={theme(currentTheme)}>
      <FormControl
        sx={{ minWidth: 250, mr: 4, borderRadius: '10px' }}
        size="small"
      >
        <InputLabel>Select Line Item Type</InputLabel>

        <Select
          disabled={Boolean(selectedFileType)}
          sx={{ borderRadius: '10' }}
          value={lineItemType}
          label="Select Line Item Type"
          onChange={handleChange}
        >
          {possibleLineItemTypes.map((type, i) => (
            <MenuItem key={i} onClick={(e) => e.stopPropagation()} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
