import Grid from '@mui/material/Grid';
import { CommonDataGrid } from '../../../../../ui-kit/components/DataGridPro';
import { customerTableColumnConfig } from './constants';

export const CustomerMapping = ({
  customerData,
  handleSelect,
  selectedCustomers,
  filterColumns,
  defaultFilterValue,
  handleOnChangeCb,
  totalDataInTable,
  handleCustomerScroll,
}: any) => {
  return (
    <Grid sx={{ margin: 3 }} container id="CustomerMapping-container">
      <Grid container id="Grid-Container">
        <Grid item id="CustomerGrid" xs={12}>
          <CommonDataGrid
            checkboxSelection={true}
            filterColumns={filterColumns}
            rowHeight={52}
            tableColumnsData={customerTableColumnConfig}
            tableData={customerData}
            selectRow={handleSelect}
            selectionModel={selectedCustomers}
            isRowSelectable={(params) =>
              params.row.synch === true ? false : true
            }
            onPageFilterChange={handleOnChangeCb}
            defaultFilterValue={defaultFilterValue}
            displayFilters={false}
            settingsPanel={false}
            cardBackgroundColor={'#FFF'}
            totalDataInTable={totalDataInTable}
            handleScroll={handleCustomerScroll}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
