import { ServiceError } from './helperTypes';
import { Service } from './Service';
import {
  GetAllAlerts,
  GetAllAlertsRes,
  CreateTrackerDTO,
  GetTrackerListRes,
  GetContactListDTO,
  GetTrackingURLRes,
} from '../../models';
import { ISendDocumentTurvoType } from '../../views/reports/documents/sections/EntityDocumentsSection';

export abstract class IVisibilityService extends Service {
  abstract getAllAlerts(
    requestData: GetAllAlerts
  ): Promise<GetAllAlertsRes | null | ServiceError>;

  abstract getTrackingURLData(
    requestData: GetAllAlerts
  ): Promise<GetTrackingURLRes | null | ServiceError>;

  abstract getTrackerList(
    loadId: string
  ): Promise<GetTrackerListRes[] | null | ServiceError>;

  abstract getContactList(
    requestData: GetContactListDTO
  ): Promise<any | null | ServiceError>;

  abstract createTrackerData(
    payloadData: CreateTrackerDTO
  ): Promise<any | null | ServiceError>;

  abstract deleteTrackerData(
    payloadData: CreateTrackerDTO[]
  ): Promise<null | undefined>;

  abstract updateTrackerData(
    payloadData: CreateTrackerDTO[]
  ): Promise<any | null | ServiceError>;
  abstract sendDocumentTurvoData(
    payloadData: ISendDocumentTurvoType
  ): Promise<any | null | ServiceError>;
}
