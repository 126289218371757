import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useRoutesLoad } from '../../../../../../contexts/LoadRoutesContext';
import { numberWithThousandSeparator } from '../../../../../../utils';
import HyperLink from '../../../../../Ui/HyperLink/HyperLink';

export default function PaymentAmountAssignComponent({
  items,
}: {
  items: any[];
}) {
  const {
    setDriverAmountForAssign,
    driverAmountForAssign,
    setSelectedDriverId,
  } = useRoutesLoad();

  const selectedItems = useMemo(
    () => getSelectedItems(),
    [items, driverAmountForAssign]
  );

  function getSelectedItems() {
    if (driverAmountForAssign) {
      return items?.map((driver) => {
        if (driverAmountForAssign[driver.id]) {
          driver.paymentDetails = driverAmountForAssign[driver.id];
        }
        return driver;
      });
    }
    return items;
  }

  function setupPaymentType(paymentDetails) {
    if (paymentDetails.driverId) {
      return {
        type: 'Driver',
        id: paymentDetails.driverId,
      };
    } else if (paymentDetails.tractorId) {
      return {
        type: 'Tractor',
        id: paymentDetails.tractorId,
      };
    } else if (paymentDetails.dispatcherId) {
      return {
        type: 'Dispatcher',
        id: paymentDetails.dispatcherId,
      };
    } else if (paymentDetails.carrierId) {
      return {
        type: 'Carrier',
        id: paymentDetails.carrierId,
      };
    }
  }

  function handleAmountClick({ paymentDetails }) {
    const selected = setupPaymentType(paymentDetails);
    if (!selected) return;
    let newData = {
      [selected.id]: paymentDetails,
    };
    if (driverAmountForAssign) {
      newData = {
        ...driverAmountForAssign,
        ...newData,
      };
    }
    setDriverAmountForAssign(newData);
    setSelectedDriverId(selected);
  }

  return (
    <>
      {selectedItems.map((item) => (
        <Box
          key={item.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h7" sx={{ lineHeight: '24px', fontWeight: 700 }}>
            {item.name} Payment Amount:
          </Typography>
          <HyperLink
            onClick={() => handleAmountClick(item)}
            sx={{ fontSize: '24px', fontWeight: 400, color: 'primary.main' }}
            value={
              item.paymentDetails.total
                ? `$${numberWithThousandSeparator(item.paymentDetails.total)}`
                : 'Add'
            }
          />
        </Box>
      ))}
    </>
  );
}
