import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DislikeIcon, LikeIcon } from '../../../../ui-kit/components/Assets';

export const getCustomerVendorLocationName = ({
  row,
  value,
}: GridRenderCellParams<Date>) => {
  const likeButtonJSX = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <LikeIcon width={10} height={9} />
      <div style={{ marginLeft: 7 }}>{value}</div>
    </div>
  );

  const dislikeButtonJSX = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DislikeIcon width={10} height={9} color={'#F44336'} />
      <div style={{ marginLeft: 7 }}>{value}</div>
    </div>
  );

  if (row?.hasOwnProperty('customerPreference')) {
    if (!row.customerPreference || row.customerPreference === 'NONE')
      return value;
    return row.customerPreference === 'PREFERRED'
      ? likeButtonJSX
      : dislikeButtonJSX;
  }
  if (row?.hasOwnProperty('preferredProhibitedEnum')) {
    if (!row.preferredProhibitedEnum || row.preferredProhibitedEnum === 'NONE')
      return value;
    return row.preferredProhibitedEnum === 'PREFERRED'
      ? likeButtonJSX
      : dislikeButtonJSX;
  }
  if (row?.hasOwnProperty('locationPreference')) {
    if (!row.locationPreference || row.locationPreference === 'NONE')
      return value;
    return row.locationPreference === 'PREFERRED'
      ? likeButtonJSX
      : dislikeButtonJSX;
  }
};
