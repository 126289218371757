import {
  IAvailableLoadboard,
  IIntegrationAvailableLoadboard,
} from '../../../views/loadboards/loadTypes';
import { LoadBoardAccount } from './types';

export class LoadboardPickerUtils {
  static fromLoadboardToAccount = (
    availableLoadboards: IIntegrationAvailableLoadboard
  ): LoadBoardAccount[][] => {
    const loadboardData: LoadBoardAccount[][] = [];
    Object.values(availableLoadboards).forEach(
      (groupData: IAvailableLoadboard[]) => {
        const accounts = groupData.map((item: IAvailableLoadboard) => {
          return {
            id: item.id,
            groupName: item.name,
            accountName: item.data[0].value,
          };
        });
        loadboardData.push(accounts);
      }
    );
    return loadboardData;
  };
  static getDisplayName = (selectedAccounts: LoadBoardAccount[]): string => {
    if (!selectedAccounts.length) return 'Loadboards';
    const remaining = selectedAccounts.length - 1;
    const displayGropname =
      selectedAccounts[0].groupName === 'DAT_V3'
        ? 'DAT'
        : selectedAccounts[0].groupName;
    let displayName = `${displayGropname}(${selectedAccounts[0].accountName})`;
    if (remaining) {
      displayName += ` +${remaining}`;
    }
    return displayName;
  };
  static isZoneDisabled = (
    availableLoadboards: IIntegrationAvailableLoadboard
  ) => {
    const isEnabled = Object.values(availableLoadboards).some((data: any) =>
      data?.some((child: any) => child.name === 'DAT')
    );
    return !isEnabled;
  };
}
