import moment from 'moment';
import * as yup from 'yup';
import { defaultStopData } from '../../../../common/LoadSharedComponents/constants';
import {
  appointmentValidationSchema as relayStopAppointmentValidationSchema,
  validationSchema as relayStopValidationSchema,
} from '../../../../common/LoadTabPanel/tabs/Routes/components/RelayStopPopup';
import {
  nullableDateTimeYup,
  nullableDateYup,
  nullableIntegerRangeValidation,
  nullableNumberYup,
  nullableStringMaxValidation,
  nullableStringYup,
  validAddressYup,
} from '../../../../utils';
import { loadContactValidationSchema } from '../../../../validator/validationSchemas';
import {
  invoiceLineItemsSchema,
  receivedPaymentsSchema,
} from '../../../invoices/constants';
import { loadTypeNameEnum } from '../../constants';
import { calculateTotalRevenueShare } from '../utils';
// import { t } from 'i18next';
import { TripsConstants } from '../../../../locales/en/allLoads/trips/index';

export const pageSize = 25;

export enum ECreateLoadPanelModes {
  CREATE_LOAD = 'CREATE_LOAD',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  VIEW_TEMPLATE = 'VIEW_TEMPLATE',
  DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE',
}

export const closePopoverTexts = {
  [ECreateLoadPanelModes.CREATE_LOAD]: TripsConstants.TripAddNewLoadPopOverText,
  [ECreateLoadPanelModes.CREATE_TEMPLATE]:
    'Do you want to leave this page without creating the template?',
  [ECreateLoadPanelModes.EDIT_TEMPLATE]:
    'Do you want to leave this page without saving the template?',
};

export const duplicatedFieldsValidationMsgs = {
  locationName: 'The Business Name is duplicated.',
  referenceId: 'The Reference # is already used.',
  appointmentDates:
    'Please ensure that the Start date & time of an earlier stop is not after the End date & time of a later stop.',
};

export const uploadFileSectionConfigs = {
  key: 'CreateLoadPanel',
  uploadText: TripsConstants.uploadFileSectionConfigsDragandDrop,
  uploadBtnLabel: TripsConstants.uploadFileSectionConfigsBrowseFiles,
};

export const loadTypeOptions = [
  { value: loadTypeNameEnum.FTL, key: 'FTL' },
  { value: loadTypeNameEnum.LTL, key: 'LTL' },
];

// @TODO: Nvard - remove duplicate
export const stopActivityTypeOptions = [
  { value: 'Pickup', key: 'PICKUP' },
  { value: 'Drop Off', key: 'DROPOFF' },
  { value: 'Pickup and Drop Off', key: 'PICKUP_AND_DROPOFF' },
];

export const appointmentDateValidationSchema = yup.object().shape(
  {
    appointmentStartDate: nullableDateYup()
      .required('Start date and time is required.')
      .when(
        ['appointmentEndDate', 'appointmentStartDate'],
        (appointmentEndDate, appointmentStartDate) => {
          if (appointmentEndDate && appointmentStartDate) {
            return nullableDateTimeYup().max(
              appointmentEndDate,
              'End date & time cannot be earlier than the Start date & time.'
            );
          }
          return nullableDateTimeYup().required(
            'Start date and time is required.'
          );
        }
      ),
    appointmentEndDate: nullableDateYup()
      .required('End date and time is required.')
      .when(
        ['appointmentEndDate', 'appointmentStartDate'],
        (appointmentEndDate, appointmentStartDate) => {
          if (appointmentEndDate && appointmentStartDate) {
            return nullableDateYup().min(
              moment(appointmentStartDate).add(1, 'days'),
              'End date & time cannot be earlier than the Start date & time.'
            );
          }
          return nullableDateYup().required('End date and time is required.');
        }
      ),
  },
  [
    ['appointmentStartDate', 'appointmentEndDate'],
    ['appointmentEndDate', 'appointmentStartDate'],
    ['appointmentEndDate', 'appointmentEndDate'],
    ['appointmentStartDate', 'appointmentStartDate'],
  ]
);

export const commodityValidationSchema = yup.object().shape({
  qty: nullableIntegerRangeValidation(1, 999999).required(
    'Quantity is required.'
  ),
  commodity: yup
    .object()
    .shape({
      itemName: yup
        .string()
        .required('Commodity Type  is required.')
        .max(100, 'Cannot be longer than 100 characters.')
        .typeError('Commodity Type is required.'),
    })
    .nullable(true)
    .required('Commodity Type  is required.')
    .typeError('Commodity Type is required.'),
  description: nullableStringYup().max(
    255,
    'Cannot be longer than 255 characters.'
  ),
  weight: nullableIntegerRangeValidation(1, 999999),
  // @TODO: Nvard - check for format for these fields validations
  volume: nullableNumberYup()
    .min(0.0001, 'Value must be between 0.0001 and 9,999.9999.')
    .max(9999.9999, 'Value must be between 0.0001 and 9,999.9999.'),
  length: nullableNumberYup()
    .min(0.0001, 'Value must be between 0.0001 and 999.9999.')
    .max(999.9999, 'Value must be between 0.0001 and 999.9999.'),
  width: nullableNumberYup()
    .min(0.0001, 'Value must be between 0.0001 and 999.9999.')
    .max(999.9999, 'Value must be between 0.0001 and 999.9999.'),
  height: nullableNumberYup()
    .min(0.0001, 'Value must be between 0.0001 and 999.9999.')
    .max(999.9999, 'Value must be between 0.0001 and 999.9999.'),
});

export const stopValidationSchema = ({
  isDriverAssigned = true,
}: {
  isDriverAssigned?: boolean;
}) =>
  yup
    .object()
    .shape({
      // @TODO: Nvard - change validation of avgHandlingTime when TextField will be replaced by DaterangePicker
      avgHandlingTime: yup
        .number()
        .required('Handling time is required.')
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .typeError('Only number is accepted.')
        .moreThan(0, 'Value should be from 1m to 480m.')
        .lessThan(481, 'Value should be from 1m to 480m.'),
      notes: nullableStringYup().max(
        1024,
        'Cannot be longer than 1024 characters.'
      ),
      refNumber: nullableStringYup().max(
        500,
        'Cannot be longer than 500 characters.'
      ),
      addressDTO: yup.object().shape({
        address: validAddressYup,
        city: nullableStringYup().required('City is required.'),
        state: nullableStringYup().required('State is required.'),
      }),
      contact: loadContactValidationSchema,
      locationTextValue: nullableStringYup().max(
        255,
        'Business name cannot be longer than 255 characters.'
      ),
    })
    .shape(
      {
        appointmentStartDate: !isDriverAssigned
          ? nullableDateTimeYup()
          : nullableDateTimeYup().when(
              ['appointmentEndDate', 'appointmentStartDate'],
              (appointmentEndDate, appointmentStartDate) => {
                if (appointmentEndDate && appointmentStartDate) {
                  return nullableDateYup().max(
                    appointmentEndDate,
                    'End date & time cannot be earlier than the Start date & time.'
                  );
                }
                return nullableDateTimeYup().required(
                  'Start date and time is required.'
                );
              }
            ),
        appointmentEndDate: !isDriverAssigned
          ? nullableDateTimeYup()
          : nullableDateTimeYup().when(
              [
                'appointmentEndDate',
                'appointmentStartDate',
                'fixedAppointment',
              ],
              (appointmentEndDate, appointmentStartDate, fixedAppointment) => {
                if (!fixedAppointment) {
                  if (appointmentEndDate && appointmentStartDate) {
                    return nullableDateTimeYup().min(
                      moment(appointmentStartDate),
                      'End date & time cannot be earlier than the Start date & time.'
                    );
                  }
                  return nullableDateTimeYup().required(
                    'End date and time is required.'
                  );
                }
              }
            ),
      },
      [
        ['appointmentStartDate', 'appointmentEndDate'],
        ['appointmentEndDate', 'appointmentStartDate'],
        ['appointmentEndDate', 'appointmentEndDate'],
        ['appointmentStartDate', 'appointmentStartDate'],
      ]
    );

export const temperatureValidationRules = {
  temperature: nullableNumberYup()
    .min(-500, 'Value must be between -500 and 500.')
    .max(500, 'Value must be between -500 and 500.'),
  lowerLimitTemperature: nullableNumberYup()
    .min(-500, 'Value must be between -500 and 500.')
    .max(500, 'Value must be between -500 and 500.')
    .when(
      ['lowerLimitTemperature', 'upperLimitTemperature'],
      (lowerLimitTemperature, upperLimitTemperature) => {
        if (lowerLimitTemperature && upperLimitTemperature) {
          return nullableNumberYup().max(
            upperLimitTemperature,
            'Lower Limit Temperature should be less than or equal to Upper Limit Temperature.'
          );
        }
      }
    ),
  upperLimitTemperature: nullableNumberYup()
    .min(-500, 'Value must be between -500 and 500.')
    .max(500, 'Value must be between -500 and 500.')
    .when(
      ['lowerLimitTemperature', 'upperLimitTemperature'],
      (lowerLimitTemperature, upperLimitTemperature) => {
        if (lowerLimitTemperature && upperLimitTemperature) {
          return nullableNumberYup().min(
            lowerLimitTemperature,
            'Lower Limit Temperature should be less than or equal to Upper Limit Temperature.'
          );
        }
      }
    ),
};

export const loadDetailsValidationRules = {
  sealNumber: nullableStringMaxValidation(50),
  chassisNumber: nullableStringMaxValidation(50),
  containerNumber: nullableStringMaxValidation(50),
  notes: nullableStringYup().max(
    1024,
    'Cannot be longer than 1024 characters.'
  ),
  totalWeight: nullableIntegerRangeValidation(1, 999999),
  equipmentTypes: yup.array().min(1, 'Equipment Type is required.'),
};

export const createTemplateFormValidationSchema = yup.object().shape({
  newTemplateName: nullableStringYup()
    .required('Template Name is required.')

    .max(255, 'Cannot be longer than 255 characters.')

    .typeError('Template Name is required.'),
});

const revenueShareValidationSchema = nullableNumberYup()
  .min(0, 'Value should be from 0 to 100.')
  .max(100, 'Value should be from 0 to 100.')
  .required('Revenue share is required.')
  .test('revenueShare', (revenueShare, context) => {
    const total = calculateTotalRevenueShare(context?.from?.[2].value?.stops);
    if (total !== 100) {
      return context.createError({
        message:
          'Sum of the revenue share should not be greater or less than 100%.',
        path: context.path,
      });
    }
    return context.schema;
  });

const relayStopFormValidationSchema = relayStopValidationSchema.shape({
  dropOff: relayStopAppointmentValidationSchema.shape(
    {
      startDate: nullableDateTimeYup().when(
        ['startDate'],
        (startDate, _schema, context) => {
          const pickupEndDate = context.from?.[1].value.pickup?.endDate;
          if (startDate && pickupEndDate) {
            return nullableDateYup().max(
              pickupEndDate,
              'Drop Off Start date & time cannot be later than the Pickup End date & time.'
            );
          }
          return nullableDateTimeYup().required(
            'Start date and time is required.'
          );
        }
      ),
      revenueShare: revenueShareValidationSchema,
    },
    [
      ['startDate', 'startDate'],
      ['revenueShare', 'revenueShare'],
    ]
  ),
  pickup: relayStopAppointmentValidationSchema.shape({
    revenueShare: revenueShareValidationSchema,
  }),
});

export const createLoadFormValidationSchema = yup
  .object()
  .shape({
    referenceId: nullableStringYup().max(
      50,
      'Reference # must be at most 50 characters.'
    ),
    customer: yup
      .object()
      .shape({
        name: yup
          .string()
          .max(255, 'Cannot be longer than 255 characters.')
          .typeError('Customer Name is required.'),
        id: yup.string().typeError('Customer Name is required.'),
      })
      .required('Customer Name is required.')
      .typeError('Customer Name is required.'),
    invoiceNotes: nullableStringYup().max(
      1024,
      'Cannot be longer than 1024 characters.'
    ),
    factoringNotes: nullableStringYup().max(
      1024,
      'Cannot be longer than 1024 characters.'
    ),
    commodityData: yup
      .array()
      .nullable()
      .of(commodityValidationSchema)
      .compact(),
    stops: yup
      .array()
      .of(
        yup.lazy((item) => {
          if (item.stopType === 'RELAY') {
            return relayStopFormValidationSchema;
          }
          return stopValidationSchema;
        })
      )
      .compact(),
    lineItems: yup
      .array()
      .of(
        invoiceLineItemsSchema.shape({
          quantity: nullableNumberYup()
            .min(1, 'Value must be between 1.0000 and 999,999.9999')
            .max(9999999.99999)
            .when('rate', (rate, schema, context) => {
              if (
                context.from?.[0]?.value?.type === 'PER_LOADED_MILE' &&
                !rate &&
                rate !== 0
              ) {
                return schema;
              }
              return schema.required('Quantity is required.');
            }),
          rate: nullableNumberYup()
            .min(0, 'Value must be between 0 and 999,999.9999')
            .max(9999999.99999, 'Value must be between 0 and 999,999.9999')
            .when('type', (type, schema) => {
              if (type === 'PER_LOADED_MILE' || type === 'FLAT_RATE') {
                return schema;
              }
              return schema.required('Rate is required.');
            }),
        })
      )
      .compact(),
    receivedPayments: yup.array().of(receivedPaymentsSchema).compact(),
  })
  .shape(loadDetailsValidationRules)
  .shape(temperatureValidationRules, [
    ['lowerLimitTemperature', 'upperLimitTemperature'],
    ['upperLimitTemperature', 'lowerLimitTemperature'],
    ['upperLimitTemperature', 'upperLimitTemperature'],
    ['lowerLimitTemperature', 'lowerLimitTemperature'],
  ]);

export const loadDetaislDefaultData = {
  id: null,
  loadCategory: 'LOAD',
  customer: undefined,
  referenceId: '',
  factoring: null,
  terminal: null,
  totalWeight: '',
  loadType: 'FTL',
  loadStatus: 'AVAILABLE',
  // lcoationName added for custom handling for validation of SingleAutocompleteForm
  locationTextValue: '',
  location: null,
  notes: '',
  commodityData: [],
  lineItems: [],
  receivedPayments: [],
  billOfLadingMandatory: true,
  proofOfDeliveryMandatory: true,
  factoringChargePercent: null,
  addressDTO: {
    address: '',
    center: { lat: 0, lng: 0 },
    city: '',
    fullAddress: '',
    state: '',
    streetAddress: '',
    streetAddress2: '',
    zipcode: '',
  },
  stops: [
    {
      ...defaultStopData,
      id: 1,
      activityType: 'PICKUP',
    },
    {
      ...defaultStopData,
      id: 2,
      activityType: 'DROPOFF',
    },
  ],
};
