import React, { FC } from 'react';
import { MaintenanceFormContextWrapper } from '../../../context/MaintenanceHistoryFormContext';
import MaintenanceDetailPanel, {
  MaintenanceDetailPanelProps,
} from './MaintenanceDetailPanel';

export type MaintenanceDetailPanelWrapperProps = MaintenanceDetailPanelProps;

const MaintenanceDetailPanelWrapper: FC<MaintenanceDetailPanelWrapperProps> = ({
  data,
  ...props
}) => {
  return (
    <MaintenanceFormContextWrapper initialData={data}>
      <MaintenanceDetailPanel data={data} {...props} />
    </MaintenanceFormContextWrapper>
  );
};

export default MaintenanceDetailPanelWrapper;
