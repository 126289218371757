import { cloneDeep } from 'lodash';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import {
  Trip,
  TripStop,
} from '../../../common/LoadTabPanel/tabs/Routes/Models';
import {
  StopSolutionV3NormalStop,
  TripV3DataProps,
} from '../../../ui-kit/TripPlan';
import { StatusTypes } from '../../../views/loadboards/constants/types';
import { LoadSummary } from '../models/LoadSummary';

export type CreateManifestFormpropsType = {
  loadIds: IPayloadIdsType | null;
  updateRequest: (data: propsTypeUpdaterequest) => void;
};

export type propsTypeCreateManifestForm = {
  trailerType: string | undefined;
  loadedMiles: number;
  organizationId: number;
  revenue: number;
  tripMiles: number;
  trips: Trip[];
};
export interface IManifestInvokeResponse {
  dispatcherId: string;
  organizationId: number;
  selectedLoads: Trip[] | [];
}
export interface IManifestInvoke {
  data: propsTypeSolutionrequest;
}
export type propsTypeUpdaterequest = {
  tripData: TripV3DataProps[];
  trailer: string | undefined;
};
export type propsTypeSolutionrequest = {
  driverGroupId: string | null;
  invocationRequestId: string;
  tripId: string;
};
export type propsTypeCreatManifest = {
  organizationId: number;
  stops: { tripId: string; stopId: number }[];
  trailerType: string;
};
export type propsTypeAddLoadtoManifest = {
  organizationId: number;
  manifestId: string;
  manifestTripToTripStops: {
    [key: string]: Array<string>;
  };
};

export type propsTypeCreateManifestModel = {
  isView: boolean;
  onModelClose: () => void;
  manifestCreated?: (load: LoadSummary, data: IPayloadIdsType | null) => void;
  loadIds: IPayloadIdsType | null;
  LoadItem: LoadSummary | null;
};
export const manifestWarning = {
  MULTIMANIFEST: 'Two manifests cannot be merged together',
  FTLLOAD: 'FTL Load cannot be merged in manifest',
  EXISTMANIFEST:
    'Load(s) connected with existing manifest cannot be merged to other manifest',
  SPLITTRIP: 'Loads with multiple trips cannot be merged in manifest',
  STATUSLOAD:
    'In Progress/Completed/Canceled/ Invoiced/Paid loads cannot be merged in Manifest',
  COMPLETEMANIFEST: 'Loads cannot be merged with the completed manifest',
  SINGLELOAD: 'At least two loads should be selected to create a manifest',
  ADDED_LOAD_IS_ON_HOLD: 'Hold load(s) cannot be added to manifest',
  MANIFEST_IS_ON_HOLD: 'Load(s) cannot be added to manifest which is on Hold',
};

export type IPayloadIdsType = {
  manifestIds: Array<string>;
  loadIds: Array<string>;
  manifestRecord?: LoadSummary | null;
  warningObject: StatusTypes;
};
export const updatedListManifestStop = (
  stop: StopSolutionV3NormalStop,
  flag: boolean,
  preViewData: propsTypeCreateManifestForm,
  tripDataList: TripV3DataProps[]
) => {
  const preViewDataList = cloneDeep(preViewData);
  if (!preViewDataList) return;
  let findRecord: TripStop | null = null;
  let findRecordIndex = 0;
  if (flag) {
    for (let i = tripDataList?.length - 1; i >= 0; i--) {
      for (let l = tripDataList[i]?.stopList?.length - 1; l >= 0; l--) {
        if (findRecord && checkListType(tripDataList[i]?.stopList, 'RELAY')) {
          preViewDataList.trips[i].stops.splice(1, 0, findRecord);
          findRecord = null;
          break;
        }
        if (findRecord && tripDataList[i]?.stopList[l].type != 'RELAY') {
          if (findRecordIndex != i) {
            preViewDataList.trips[i].stops.splice(l + 1, 0, findRecord);
          } else {
            preViewDataList.trips[i].stops.splice(l, 0, findRecord);
          }
          findRecord = null;
          break;
        } else if (
          stop.tripId === tripDataList[i]?.stopList[l].tripId &&
          stop.id === tripDataList[i]?.stopList[l].id
        ) {
          findRecord = preViewDataList.trips[i]?.stops[l];
          preViewDataList.trips[i].stops.splice(l, 1);
          findRecordIndex = i;
        }
      }
    }
  } else {
    for (let i = 0; i < tripDataList?.length; i++) {
      for (let l = 0; l < tripDataList[i]?.stopList?.length; l++) {
        if (findRecord && checkListType(tripDataList[i]?.stopList, 'RELAY')) {
          preViewDataList.trips[i].stops.splice(
            tripDataList[i].stopList.length - 2,
            0,
            findRecord
          );
          findRecord = null;
          break;
        }
        if (findRecord && tripDataList[i]?.stopList[l].type != 'RELAY') {
          preViewDataList.trips[i].stops.splice(l, 0, findRecord);
          findRecord = null;
          break;
        } else if (
          stop.tripId === tripDataList[i]?.stopList[l].tripId &&
          stop.id === tripDataList[i]?.stopList[l].id
        ) {
          findRecord = preViewDataList.trips[i]?.stops[l];
          preViewDataList.trips[i].stops.splice(l, 1);
        }
      }
    }
  }
  return preViewDataList;
};
const checkListType = (list: StopSolutionV3NormalStop[], status: string) => {
  const result = list.find((item: StopSolutionV3NormalStop) => {
    return item.type != status;
  });
  return !Boolean(result);
};

export const mainfestKPIStyle = {
  backgroundColor: 'uncategorized.oldHeaderSecondary',
  padding: '12px',
  margin: '12px',
  width: '100%',
  ml: '0px',
  borderRadius: '13px',
};
export const mainfestInputStyle = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(43, 100, 203, 0.5)',
  },
  '& .MuiInput-input': {
    color: 'black',
  },
  color: '#2b64cb',
};
export const arraymove = (
  arr: TripStop[],
  fromIndex: number,
  toIndex: number
) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

export const manifestSelectionRules = (
  data: Array<string>,
  tableData: LoadSummary[]
): any => {
  const filterData = data.map((id: string) => {
    const index = tableData.findIndex(
      (element: LoadSummary) => element.id === id
    );
    return tableData[index];
  }, []);
  let isTagWaring = false;
  const payLoadIds: IPayloadIdsType = {
    manifestIds: [],
    loadIds: [],
    manifestRecord: null,
    warningObject: {},
  };
  if (filterData.length === 1) {
    payLoadIds.warningObject['SINGLELOAD'] = manifestWarning['SINGLELOAD'];
  }
  let manifestCount = 0;

  filterData.map((item: LoadSummary) => {
    if (item.loadType === 'MANIFEST') {
      isTagWaring = true;
      if (manifestCount == 1) {
        payLoadIds.warningObject['MULTIMANIFEST'] =
          manifestWarning['MULTIMANIFEST'];
      }
      manifestCount = +1;
      if (item.onHold) {
        payLoadIds.warningObject.MANIFEST_IS_ON_HOLD =
          manifestWarning.MANIFEST_IS_ON_HOLD;
      }
    }
    if (item.loadType === 'FTL') {
      payLoadIds.warningObject['FTLLOAD'] = manifestWarning['FTLLOAD'];
    }
    if (item.loadType === 'LTL' && item.onHold) {
      payLoadIds.warningObject.ADDED_LOAD_IS_ON_HOLD =
        manifestWarning.ADDED_LOAD_IS_ON_HOLD;
    }
    if (item.trips?.length > 1 && item.loadType != 'MANIFEST') {
      payLoadIds.warningObject['SPLITTRIP'] = manifestWarning['SPLITTRIP'];
    }
    if (item.manifestBaseDetails?.manifestId && item.loadType === 'LTL') {
      payLoadIds.warningObject['EXISTMANIFEST'] =
        manifestWarning['EXISTMANIFEST'];
    }
    if (
      item.manifestBaseDetails?.status === ELoadStatus.LOAD_COMPLETED ||
      item.manifestBaseDetails?.status === ELoadStatus.CANCELLED
    ) {
      payLoadIds.warningObject['COMPLETEMANIFEST'] =
        manifestWarning['COMPLETEMANIFEST'];
    }
    if (
      item.loadType === 'LTL' &&
      (item.loadStatus === ELoadStatus.LOAD_COMPLETED ||
        item.loadStatus === ELoadStatus.CANCELLED ||
        item.loadStatus === ELoadStatus.IN_TRANSIT ||
        item.loadStatus === ELoadStatus.PAID ||
        item.loadStatus === ELoadStatus.INVOICED ||
        item.loadStatus === ELoadStatus.DISPATCHED)
    ) {
      payLoadIds.warningObject['STATUSLOAD'] = manifestWarning['STATUSLOAD'];
    }
    if (item.loadType === 'MANIFEST') {
      payLoadIds.manifestIds.push(item.id);
      payLoadIds.manifestRecord = item;
    } else if (item.loadType === 'LTL') {
      payLoadIds.loadIds.push(item.id);
    }
  });
  const filterDataWarning = {
    payLoadIds: payLoadIds,
    filterData: filterData,
    tagWaring: isTagWaring
      ? ['This action cannot be performed on Manifest ']
      : [],
  };
  return filterDataWarning;
};
