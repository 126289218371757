import moment, { MomentInput } from 'moment';
import { AddressDTO } from '../../../models';
import { CreateTimeoffRequest } from '../../../models/DTOs/Dispatch/Requests';
import TimeoffDateTime from '../../../utils/timeoffDateTime';
import { ITimeoffDateWithTz } from '../types';
import StorageManager from '../../../StorageManager/StorageManager';

export interface IGenerateCreateTimeoffRequest extends ITimeoffDateWithTz {
  organizationId: number;
  tractorId: string;
  trailerId: string;
  driverGroupId: string;
  driverId: string;
  driverName: string;
  loadCreatorUserId: string;
  prevLoadId: string;
  location: AddressDTO;
  id?: any;
}
export const generateCreateTimeoffRequest = ({
  tractorId,
  trailerId,
  driverGroupId,
  driverId,
  driverName,
  prevLoadId,
  location,
  timezone,
  startDatePart,
  startTimePart,
  endDatePart,
  endTimePart,
  id,
}: IGenerateCreateTimeoffRequest): CreateTimeoffRequest => {
  const currentDateTime = new Date().toISOString();
  const organizationId = getOrganizationId();
  const loadCreatorUserId = getUserId();
  const timeoffDateWithTz: ITimeoffDateWithTz = {
    timezone: timezone,
    startDatePart: startDatePart,
    startTimePart: startTimePart,
    endDatePart: endDatePart,
    endTimePart: endTimePart,
  };

  const { appointmentStartDate, appointmentEndDate } =
    TimeoffDateTime.calculateAppointmentDates(timeoffDateWithTz);
  const { durationInMinutes } = TimeoffDateTime.calculateDuration({
    startDate: appointmentStartDate,
    endDate: appointmentEndDate,
  });

  const request: CreateTimeoffRequest = {
    id,
    assignment: {
      assignmentDate: currentDateTime,
      tractorId,
      trailerId,
    },
    concernRaised: false,
    connectedLoads: [],
    createDate: currentDateTime,
    driverGroupId: driverGroupId,
    driverId: driverId,
    driverName: driverName,
    hasBeenCancelled: false,
    invoice: {},
    loadCreatorUserId: loadCreatorUserId,
    loadCategory: 'TIMEOFF',
    loadStatus: 'OFFERED_TO_DRIVER',
    numberOfStops: 1,
    operationType: 'INVOICE_DISPATCH',
    organizationId: organizationId,
    prevLoadId: prevLoadId,
    revenue: 0,
    stops: [
      {
        activity: 'string',
        activityType: 'PICKUP',
        appointmentEndDate: appointmentEndDate,
        appointmentStartDate: appointmentStartDate,
        connectedStopDetails: [],
        createDate: currentDateTime,
        estimatedActivityDuration: durationInMinutes,
        fixedAppointment: false,
        id: 1,
        isDriverAssistRequired: false,
        last: true,
        location: {
          address: location.address,
          center: location.center,
          city: location.city,
          country: location.country,
          fullAddress: location.fullAddress,
          lat: location.center.lat,
          lng: location.center.lng,
          organizationId: organizationId,
          state: location.state,
          streetAddress: location.streetAddress,
          timezone: timezone,
          zipcode: location.zipcode,
        },
        sequenceNumber: 0,
        stopCategory: 'HOME',
        stopStatus: 'NONE',
        timezone: timezone,
      },
    ],
  };
  return request;
};

export const getUserId = () => {
  return StorageManager.getUser()?.id;
};
export const getOrganizationId = () => {
  return StorageManager.getUser()?.organizationId;
};

export const getValidationDateTimeTimeOffDialog = ({
  startDatePart,
  startTimePart,
  endDatePart,
  endTimePart,
}: {
  startDatePart: MomentInput;
  startTimePart: string;
  endDatePart: MomentInput;
  endTimePart: string;
}): {
  startDatePart?: {
    isError: boolean;
    message?: string;
  };
  startTimePart?: {
    isError: boolean;
    message?: string;
  };
  endDatePart?: {
    isError: boolean;
    message?: string;
  };
  endTimePart?: {
    isError: boolean;
    message?: string;
  };
} => {
  const result = {};
  if (!startDatePart) {
    result.startDatePart = {
      isError: true,
      message: 'Start Date is required',
    };
  }
  if (startTimePart === null) {
    result.startTimePart = {
      isError: true,
      message: 'Start Time is required',
    };
  }
  if (!endDatePart) {
    result.endDatePart = {
      isError: true,
      message: 'End Date is required',
    };
  }
  if (endTimePart === null) {
    result.endTimePart = {
      isError: true,
      message: 'End Time is required',
    };
  }

  if (
    startDatePart &&
    endDatePart &&
    startTimePart !== null &&
    endTimePart !== null
  ) {
    const startDate = moment(startDatePart)
      .startOf('day')
      .add(startTimePart, 'minutes');
    const endDate = moment(endDatePart)
      .startOf('day')
      .add(endTimePart, 'minutes');
    const diffDays = endDate.diff(startDate, 'days');
    const diffMinutes = endDate.diff(startDate, 'minutes');

    if (diffDays === 0) {
      if (diffMinutes <= 0) {
        result.endTimePart = {
          isError: true,
          message: 'End Time must be after Start Time',
        };
      }
    } else if (diffDays < 0) {
      result.endDatePart = {
        isError: true,
        message: 'End Date must be after Start Date',
      };
    }
  }

  return result;
};
