import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import DeletePopup from '../../ui-kit/components/DeletePopup';
import IconsComponent from '../../ui-kit/components/IconsComponent';
import { formattedTwoDigitNumber, letter15 } from './constant';
import Radio from '@mui/material/Radio';
import { GetPaymentProfileRequest } from '../../models';
import { subscriptionService } from '../../api';

type ManagePaymentProps = {
  setShowManage: (x: boolean) => void;
  id: string;
  subscriptionId: number;
  defaultId: string;
  getSubscription: () => void;
};

const ManagePayment = ({
  setShowManage,
  id,
  subscriptionId,
  defaultId: primaryId,
  getSubscription,
}: ManagePaymentProps) => {
  const [paymentProfiles, setPaymentProfiles] = useState([]);
  const [deletePayment, setDeletePayment] = useState({});
  const [defaultId, setDefaultId] = useState<string>('');

  const cards = paymentProfiles.filter(
    (el: { payment_type: string }) => el.payment_type === 'credit_card'
  );
  const banks = paymentProfiles.filter(
    (el: { payment_type: string }) => el.payment_type === 'bank_account'
  );
  const isDefaultItemTypeBank = banks.find((el) => el.id === primaryId)
    ? true
    : false;

  useEffect(() => {
    setDefaultId(primaryId);
  }, [primaryId]);

  const deleteItem = async () => {
    const response = await subscriptionService.deletePayment({
      subscription_id: subscriptionId,
      payment_profile_id: deletePayment.id,
    });
    if (response) {
      setDeletePayment({});
      getPaymentProfiles();
      setDefaultId(primaryId);
    }
  };

  const setPrimary = async () => {
    const response = await subscriptionService.setDefaultPayment({
      subscriptionId,
      paymentProfileId: defaultId,
    });
    if (response) {
      setShowManage(false);
      getSubscription();
    }
  };

  const getPaymentProfiles = async () => {
    const query = new GetPaymentProfileRequest();
    query.customerId = id;
    const response = await subscriptionService.getPaymentProfiles(query);
    setPaymentProfiles(
      response.map((x: { payment_profile: string }) => x.payment_profile)
    );
  };

  useEffect(() => {
    if (id) getPaymentProfiles();
  }, [id]);

  const onclose = () => {
    setShowManage(false);
  };

  return paymentProfiles.length > 0 ? (
    <Dialog onClose={onclose} open={true}>
      <Box sx={{ padding: '24px' }}>
        <Box
          sx={{
            display: 'flex',
            height: '46px',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '24px',
          }}
        >
          <Box sx={(theme) => ({ ...theme.typography.h6, ...letter15 })}>
            Manage Payments
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={onclose}>
            <IconsComponent iconName="Close" source="MUIcons" />
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: 'calc(100vh - 250px)',
            overflow: 'auto',
            marginBottom: '24px',
          }}
        >
          {cards.length > 0 && (
            <>
              <Box sx={{ display: 'flex', marginBottom: '24px' }}>
                <Box
                  sx={(theme) => ({
                    ...theme.typography.body3,
                    color: 'text.primary',
                    letterSpacing: '0.4px',
                    marginRight: '10px',
                    width: '50px',
                  })}
                >
                  {!isDefaultItemTypeBank ? 'primary' : ''}
                </Box>
                <Box sx={(theme) => ({ ...letter15, ...theme.typography.h7 })}>
                  Cards
                </Box>
              </Box>
              {cards.map(
                (card: {
                  id: string;
                  card_type: string;
                  expiration_year: string;
                  masked_card_number: string;
                  expiration_month: string;
                }) => {
                  return (
                    <Box
                      key={card.id}
                      sx={{ display: 'flex', marginBottom: '24px' }}
                    >
                      <Box sx={{ margin: '-9px', marginRight: '32px' }}>
                        <Radio
                          checked={card.id === defaultId}
                          onChange={() => setDefaultId(card.id)}
                        />
                      </Box>
                      <Box
                        sx={(theme) => ({
                          ...letter15,
                          ...theme.typography.body1,
                          textTransform: 'capitalize',
                          width: '300px',
                        })}
                      >
                        {card.card_type} *{card.masked_card_number?.slice(-4)}{' '}
                        (Expires{' '}
                        {formattedTwoDigitNumber(card.expiration_month)}/
                        {card.expiration_year})
                      </Box>
                      {primaryId !== card.id && (
                        <Box
                          sx={{ cursor: 'pointer', marginLeft: '32px' }}
                          onClick={() => setDeletePayment(card)}
                        >
                          <IconsComponent
                            source="MUIcons"
                            iconName="DeleteOutlined"
                            styleProps={{
                              color: 'action.active',
                              width: '18px',
                              height: '18px',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  );
                }
              )}
            </>
          )}
          {banks.length > 0 && (
            <>
              <Box sx={{ display: 'flex', marginBottom: '24px' }}>
                <Box
                  sx={(theme) => ({
                    ...theme.typography.body3,
                    color: 'text.primary',
                    letterSpacing: '0.4px',
                    marginRight: '10px',
                    width: '50px',
                  })}
                >
                  {isDefaultItemTypeBank ? 'primary' : ''}
                </Box>
                <Box sx={(theme) => ({ ...letter15, ...theme.typography.h7 })}>
                  ACH Account
                </Box>
              </Box>
              {banks.map(
                (bank: {
                  id: string;
                  masked_bank_account_number: string;
                  bank_name: string;
                  verified: boolean;
                }) => {
                  return (
                    <Box
                      key={bank.id}
                      sx={{ display: 'flex', marginBottom: '24px' }}
                    >
                      <Box
                        sx={{
                          margin: '-9px',
                          marginRight: '32px',
                          width: '42px',
                        }}
                      >
                        {bank.verified ? (
                          <Radio
                            checked={bank.id === defaultId}
                            onChange={() => setDefaultId(bank.id)}
                          />
                        ) : null}
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                          sx={(theme) => ({
                            ...letter15,
                            ...theme.typography.body1,
                            width: '300px',
                            textTransform: 'capitalize',
                          })}
                        >
                          *{bank.masked_bank_account_number?.slice(-4)} (
                          {bank.bank_name})
                        </Box>
                        <Box
                          sx={(theme) => ({
                            ...letter15,
                            ...theme.typography.body1,
                            width: '300px',
                            color: bank.verified
                              ? 'success.main'
                              : 'error.main',
                          })}
                        >
                          {bank.verified ? 'verified' : 'verification pending'}
                        </Box>
                      </Box>
                      {primaryId !== bank.id && (
                        <Box
                          sx={{ cursor: 'pointer', marginLeft: '32px' }}
                          onClick={() => setDeletePayment(bank)}
                        >
                          <IconsComponent
                            source="MUIcons"
                            iconName="DeleteOutlined"
                            styleProps={{
                              color: 'action.active',
                              width: '18px',
                              height: '18px',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  );
                }
              )}
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonImproved label="Cancel" variant="outlined" onClick={onclose} />
          <ButtonImproved
            label="Save And Close"
            variant="contained"
            onClick={setPrimary}
          />
        </Box>
      </Box>
      <DeletePopup
        open={deletePayment.id ? true : false}
        onAction={deleteItem}
        onClose={() => setDeletePayment({})}
        title={`Delete Payment Method?`}
        body={`Are you sure you want to delete this ${deletePayment?.payment_type
          ?.split('_')
          ?.join(' ')}?`}
      />
    </Dialog>
  ) : (
    <></>
  );
};

export default ManagePayment;
