import { Box } from '@mui/material';
import {
  CommonExpandableTimelineContentProps,
  TimelineVariantType,
} from '../../../AxeleTimeline';
import { TripV3DataProps } from '../../TripPlanV3';
import {
  ExpandableTimelineV3AddStopEvents,
  ExpandableTimelineV3EditIconEvents,
  ExpandableTimelineV3LoadIdEvents,
  ExpandableTimelineV3PanelView,
  ExpandableTimelineV3ResequenceStopEvents,
  StopSolutionV3Prop,
} from '../../TripPlanV3/types';
import { TripViewV3Mode } from '../../types';
import { DetailedDataSection } from './DetailedDataSection';
import { HOSEventViewSection } from './HOSEventViewSection';
import { RightSubTitleSection } from './RightSubTitleSection';
import { RightTitleSection } from './RightTitleSection';
import { TimelineSeparatorIconSection } from './TimelineSeparatorIconSection';
import { classNamesTimeline } from './constants';
import { getTimelineActive } from './timelineV3.utils';

export interface ConvertToTimelineContentProps
  extends ExpandableTimelineV3EditIconEvents,
    ExpandableTimelineV3AddStopEvents,
    ExpandableTimelineV3ResequenceStopEvents,
    ExpandableTimelineV3LoadIdEvents {
  idx: number;
  id: string;
  stopSolution: StopSolutionV3Prop;
  previousSelectedId?: string;
  variant: TimelineVariantType;
  renderTimelineSeparatorIconOnly?: boolean;
  previousStopIndex?: number;
  isHideCollapse?: boolean;
  isHideLeftSide?: boolean;
  isHideRightSubContent?: boolean;
  hide?: boolean;
  isOpenCollapseDefault?: boolean;
  panelView: ExpandableTimelineV3PanelView;
  viewMode: TripViewV3Mode;
  showFloatingActionButton?: boolean;
  isCompleted?: boolean;
  isCancelled?: boolean;
  disabled?: boolean;
  enablePreAndNext?: boolean;
  readOnly?: boolean;
  isNotCompletedBeforeEnRoute?: boolean;
  isNotCompletedBeforeClosetEnRoute?: boolean;
  isShowFloatingTruckIconSection?: boolean;
  options?: Partial<CommonExpandableTimelineContentProps<StopSolutionV3Prop>>;
  data: TripV3DataProps;
  shouldDisableActionOnPreAndNext: () => boolean;
}

export const convertToTimelineContentV3 = ({
  idx,
  id,
  stopSolution,
  previousSelectedId,
  variant,
  renderTimelineSeparatorIconOnly = false,
  isHideCollapse = false,
  isHideRightSubContent = false,
  hide = false,
  isOpenCollapseDefault = false,
  panelView,
  viewMode,
  onClickEditIconHandler,
  onClickAddStopHandler,
  onClickAddRelayHandler,
  onClickResequenceUpHandler,
  onClickResequenceDownHandler,
  onClickLoadIdHandler,
  showFloatingActionButton = false,
  isCompleted = false,
  isCancelled = false,
  disabled = false,
  enablePreAndNext = false,
  readOnly = false,
  isNotCompletedBeforeEnRoute = false,
  isNotCompletedBeforeClosetEnRoute = false,
  isShowFloatingTruckIconSection = false,
  options = {},
  data,
  shouldDisableActionOnPreAndNext,
}: ConvertToTimelineContentProps): CommonExpandableTimelineContentProps<StopSolutionV3Prop> => {
  const shouldDisableActionOnLoadSearch = (): boolean => {
    return panelView === ExpandableTimelineV3PanelView.Loadsearch;
  };

  const isShowButtonIconActionSection =
    !readOnly && !isCompleted && !isCancelled && !disabled;

  const shouldEnableHoverEvent = (): boolean => {
    return (
      isShowButtonIconActionSection &&
      !shouldDisableActionOnPreAndNext() &&
      !shouldDisableActionOnLoadSearch()
    );
  };

  const shouldHideCollapse = (): boolean => {
    return (
      isHideCollapse ||
      renderTimelineSeparatorIconOnly ||
      shouldDisableActionOnPreAndNext()
    );
  };

  const isLastStop: boolean =
    stopSolution.id === data.stopList[data.stopList.length - 1].id;

  const shouldEnableFloatingActionButton = (): boolean => {
    return (
      showFloatingActionButton &&
      !shouldDisableActionOnLoadSearch() &&
      !isLastStop &&
      isShowButtonIconActionSection
    );
  };
  const timelineActive = getTimelineActive({
    isCompleted,
    isCancelled,
    disabled,
    stopSolution,
    isNotCompletedBeforeEnRoute,
  });

  return {
    id,
    previousSelectedId: previousSelectedId,
    variant: variant,
    hide: hide,
    styleProps: {
      minHeight: '100px',
      [`.${classNamesTimeline.StyleEditIconButton}`]: {
        display: 'none',
      },
      [`.${classNamesTimeline.StyleFloatingActionRoot}`]: {
        display: 'none',
      },
      [`.${classNamesTimeline.StyledFloatingReSequenceStopIconRoot}`]: {
        display: 'none',
      },
      ...(renderTimelineSeparatorIconOnly
        ? {
            minHeight: '20px',
          }
        : {}),
      ...(shouldEnableHoverEvent() && {
        '&:hover': {
          [`.${classNamesTimeline.StyleEditIconButton}`]: {
            display: 'inline-flex',
          },
          [`.${classNamesTimeline.StyleFloatingActionRoot}`]: {
            display: 'inherit',
          },
          [`.${classNamesTimeline.StyledFloatingReSequenceStopIconRoot}`]: {
            display: 'inherit',
          },
        },
      }),
      ...(shouldDisableActionOnPreAndNext() && {
        opacity: 0.7,
      }),
    },
    isHideCollapse: shouldHideCollapse(),
    isOpenCollapseDefault: isOpenCollapseDefault,
    needsConnector: isLastStop ? 'none' : 'block',
    timelineSeparatorSxProps: {
      ...(options.timelineSeparatorSxProps && {
        ...options.timelineSeparatorSxProps,
      }),
    },
    timelineConnectorSxProps: {
      //Important: must use !important rule
      backgroundColor: (theme) => `${theme.palette.action.disabled} !important`,
      ...(timelineActive.separator && {
        backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
      }),
      ...(options.timelineConnectorSxProps && {
        ...options.timelineConnectorSxProps,
      }),
      ...(isShowFloatingTruckIconSection && {
        height: '30%',
        maxHeight: '24px',
      }),
      ...(isCancelled && {
        backgroundColor: (theme) => `${theme.palette.error.main} !important`,
      }),
    },
    enableSecondConnector: isShowFloatingTruckIconSection,
    timelineSecondConnectorSxProps: {
      height: '30%',
      backgroundColor: (theme) => `${theme.palette.action.disabled} !important`,
      ...(isCancelled && {
        backgroundColor: (theme) => `${theme.palette.error.main} !important`,
      }),
    },
    renderLeftTitle: () => {
      return <></>;
    },
    renderLeftSubTitles: () => {
      return [];
    },
    renderRightTitle: ({ isActive }: { isActive: boolean }): JSX.Element => {
      return (
        <RightTitleSection
          isHideCollapse={shouldHideCollapse()}
          readOnly={readOnly}
          isShowButtonIconActionSection={isShowButtonIconActionSection}
          enablePreAndNext={enablePreAndNext}
          isActive={timelineActive.header}
          panelView={panelView}
          renderTimelineSeparatorIconOnly={renderTimelineSeparatorIconOnly}
          stopSolution={stopSolution}
          onClickEditIconHandler={onClickEditIconHandler}
          onClickLoadIdHandler={onClickLoadIdHandler}
          data={data}
        />
      );
    },
    renderRightSubTitle: ({
      expanded = false,
    }: {
      expanded?: boolean;
    }): JSX.Element => {
      return (
        <RightSubTitleSection
          enablePreAndNext={enablePreAndNext}
          isCompleted={false}
          isCancelled={isCancelled}
          expanded={expanded}
          renderTimelineSeparatorIconOnly={renderTimelineSeparatorIconOnly}
          showFloatingActionButton={shouldEnableFloatingActionButton()}
          isNotCompletedBeforeEnRoute={isNotCompletedBeforeEnRoute}
          isNotCompletedBeforeClosetEnRoute={isNotCompletedBeforeClosetEnRoute}
          isShowFloatingTruckIconSection={isShowFloatingTruckIconSection}
          stopSolution={stopSolution}
          onClickAddStopHandler={onClickAddStopHandler}
          onClickAddRelayHandler={onClickAddRelayHandler}
          isShowButtonIconActionSection={isShowButtonIconActionSection}
          onClickResequenceDownHandler={onClickResequenceDownHandler}
          onClickResequenceUpHandler={onClickResequenceUpHandler}
          data={data}
        />
      );
    },
    renderRightSubContent: (): JSX.Element => {
      if (!renderTimelineSeparatorIconOnly && !isHideRightSubContent) {
        return (
          <DetailedDataSection
            stopSolution={stopSolution}
            idx={idx}
            isCancelled={isCancelled}
            isCompleted={isCompleted}
          />
        );
      }
      return <></>;
    },
    renderRightPrependSubContent: (): JSX.Element => {
      if (!renderTimelineSeparatorIconOnly && !isHideRightSubContent) {
        return (
          <>
            {viewMode === TripViewV3Mode.HOSEventView ? (
              <HOSEventViewSection
                stopSolution={{
                  ...stopSolution,
                  HOSList: data.stopList[idx + 1]?.HOSList || [],
                }}
              />
            ) : (
              <></>
            )}
          </>
        );
      }
      return <></>;
    },
    renderTimelineSeparatorIcon: (isActive: boolean) => {
      //https://confluence.optym.net/display/OMP/Trip+Plan#TripPlan-ShowingStopStatus

      return (
        <TimelineSeparatorIconSection
          isActive={timelineActive.icon}
          stopSolution={stopSolution}
          isCompleted={isCompleted}
          isCancelled={isCancelled}
        />
      );
    },
    originalData: stopSolution,
  };
};

export const convertToHOSEventOnlyTimelineContentV3 = ({
  id,
  stopSolution,
  previousSelectedId,
  variant,
  hide = false,
  isOpenCollapseDefault = false,
}: ConvertToTimelineContentProps): CommonExpandableTimelineContentProps<StopSolutionV3Prop> => {
  return {
    id,
    previousSelectedId: previousSelectedId,
    variant: variant,
    hide: hide,
    styleProps: {
      paddingRight: 0,
      [`.${classNamesTimeline.StyleEditIconButton}`]: {
        display: 'none',
      },
      [`.${classNamesTimeline.StyleFloatingActionRoot}`]: {
        display: 'none',
      },
    },
    isHideCollapse: true,
    isOpenCollapseDefault: isOpenCollapseDefault,
    needsConnector: 'none',
    renderLeftTitle: (): JSX.Element => {
      return <></>;
    },
    renderLeftSubTitles: () => {
      return [];
    },
    renderRightTitle: (): JSX.Element => {
      return <></>;
    },
    renderRightSubTitle: (): JSX.Element => {
      return <></>;
    },
    renderRightSubContent: (): JSX.Element => {
      return (
        <Box
          sx={{
            marginLeft: '24px',
            marginTop: '-50px',
          }}
        >
          <HOSEventViewSection stopSolution={stopSolution} />
        </Box>
      );
    },
    renderTimelineSeparatorIcon: (): JSX.Element => {
      return <></>;
    },
    originalData: stopSolution,
  };
};
