import { ArrowDropDown } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FunctionComponent, useRef } from 'react';

interface SelectProps {
  options?: Array<Object>;
  onOpen?: Function;
  value?: string;
  label?: string;
  styleProps?: Object;
  helperText?: string;
  onChange?: Function;
  error?: boolean;
  inputLabelId?: string;
  selectLabelId?: string;
  selectId?: string;
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  size?: 'small' | 'medium' | undefined;
  fullWidth?: boolean;
  IconComponent?: any;
  MenuProps?: object;
  handleModalSize?: (width: number) => void;
}

const SelectInput: FunctionComponent<SelectProps> = ({
  options = [],
  onOpen,
  value,
  label,
  styleProps,
  helperText,
  onChange,
  MenuProps,
  error,
  inputLabelId,
  selectLabelId,
  selectId,
  variant = 'standard',
  size = 'medium',
  fullWidth = false,
  IconComponent = () => <ArrowDropDown />,
  handleModalSize,
}: SelectProps) => {
  const ref = useRef<HTMLInputElement>();
  ref && ref.current && handleModalSize?.(ref.current.clientWidth);
  return (
    <FormControl
      variant={variant}
      error={error}
      size={size}
      sx={fullWidth ? { width: '100%' } : {}}
    >
      <InputLabel id={inputLabelId}>{label}</InputLabel>
      <Select
        MenuProps={MenuProps}
        variant={variant}
        id={selectId}
        labelId={selectLabelId}
        label={label}
        sx={styleProps}
        size={size}
        value={value}
        onChange={(e) => (onChange ? onChange(e.target.value) : {})}
        onOpen={(e) => (onOpen ? onOpen(e) : {})}
        ref={ref}
        IconComponent={IconComponent}
      >
        {options.map((opt: any) => {
          return (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          );
        })}
      </Select>
      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;
