import React from 'react';
import Typography from '@mui/material/Typography';

export const ErrorMessage = ({
  message,
  styles = {},
}: {
  message: string;
  styles: {};
}) => {
  // NOTE: color: Have to replace  'error.main' by color: '#d32f2f !important' cause in the forms wrapped by FormDialog Typography color is overwritten
  return (
    <Typography
      sx={{
        color: '#d32f2f !important',
        fontSize: '12px',
        fontWeight: 400,
        ...styles,
      }}
    >
      {message}
    </Typography>
  );
};
