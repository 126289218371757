export const PreferenceModalContainerStyles = {
  // padding: '16px',
  // height: 'calc(100% - 90px)',
  marginLeft: '40px',
  marginRight: '40px',
  display: 'flex',
};

export const PreferenceModalInnerContainerStyle = {
  position: 'relative' as const,
  margin: 'auto',
  maxHeight: '100%',
  // top: '53%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  bgcolor: 'rgba(3,14,24,0.89)',
  boxShadow: '0px 1px 12px 0px rgba(78, 101, 120, 0.15)',
  borderRadius: '16px',
  maxWidth: '455px',
  width: '100%',
  overflow: 'auto',
};

export const PreferenceSettingsContainerStyles = {
  background: '#FFF',
  borderRadius: '16px',
  height: '100%',
  overflowY: 'auto',
  padding: '32px 5px',
};

export const SettingTitleStyles = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '34px',
  lineHeight: '120%',
  letterSpacing: '0.25px',
};

export const SettingsCaptionStyles = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '160%',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.15px',
  color: 'rgba(0, 17, 34, 0.6)',
  marginTop: '8px',
};

export const PreferenceTitleStyles = {
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '160%',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
};

export const PreferenceContainerStyles = {
  borderBottom: '1px solid #E5E5E5',
  paddingBottom: '32px',
  marginTop: '8px',
  paddingLeft: '0!important',
  marginLeft: '16px',
};

export const PreferenceFormContainerStyles = {
  padding: '0',
};

export const PreferenceFooterContainerStyles = {
  gap: '16px',
  // justifyContent: 'end',
  position: 'sticky',
  bottom: '0',
  background: '#FFF',
  padding: '0 10px',
  marginTop: '32px',
};

export const PreferenceRadioInputStyles = {
  height: '28px',
  padding: '10px',
  fontSize: '13px',
  minWidth: 'auto',
  borderRadius: 0,
};

export const PreferenceRadioInputStylesByIndex = ({
  index,
  totalCount,
}: {
  index?: number;
  totalCount?: number;
}) => {
  if (index === 0)
    return {
      ...PreferenceRadioInputStyles,
      borderRadius: '6px 0 0 6px',
    };
  if (index === totalCount! - 1)
    return {
      ...PreferenceRadioInputStyles,
      borderRadius: '0 6px 6px 0',
    };
  return PreferenceRadioInputStyles;
};

export const PreferenceHeadingStyles = {
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '160%',
  letterSpacing: '0.15px',
};

export const PreferncelabelStyles = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '140%',
  letterSpacing: '0.4px',
};
