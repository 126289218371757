import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Menu, SxProps, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import IconsComponent from '../IconsComponent';

export default function ThreeDotMenuIcon({
  menuOptions,
  open,
  handleMenuClick,
  handleMenuClose,
  anchorEl,
  handleMenuItemClick,
  accountData,
  styleProps,
  className,
  menuStyles,
}: IProps) {
  return (
    <Box className="right">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
        sx={styleProps}
        className={className}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        sx={menuStyles}
      >
        {menuOptions &&
          menuOptions.length > 0 &&
          menuOptions.map((option: any, index: number) => {
            const Icon = option.icon;
            return (
              <MenuItem
                key={index}
                disabled={Boolean(option?.disabled)}
                onClick={(event) =>
                  handleMenuItemClick(option, accountData, event)
                }
                sx={{ padding: '12px 20px', ...option?.style }}
              >
                {Boolean(Icon || option?.iconName) && (
                  <ListItemIcon>
                    {Icon ? <Icon /> : null}
                    {option.iconName ? (
                      <IconsComponent
                        iconName={option.iconName}
                        source={option.source}
                      />
                    ) : null}
                  </ListItemIcon>
                )}
                <Typography variant="inherit">{option.label}</Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
}

export interface IProps {
  menuOptions: any;
  open: any;
  handleMenuClick: any;
  handleMenuClose: any;
  anchorEl: any;
  handleMenuItemClick: any;
  accountData?: any;
  styleProps?: Object;
  className?: string;
  menuStyles?: SxProps;
}
