import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { ESecondaryDetailsPanelType } from '../../views/dispatch2/constants/types';

class DetailsPanelStore {
  @observable metaData: Record<ESecondaryDetailsPanelType, number> = {
    [ESecondaryDetailsPanelType.TRACTOR]: 0,
    [ESecondaryDetailsPanelType.TRAILER]: 0,
    [ESecondaryDetailsPanelType.LOAD]: 0,
    [ESecondaryDetailsPanelType.TRIP]: 0,
    [ESecondaryDetailsPanelType.USER]: 0,
    [ESecondaryDetailsPanelType.CUSTOMER]: 0,
    [ESecondaryDetailsPanelType.DISPATCH]: 0,
    [ESecondaryDetailsPanelType.PAY_STATMENT]: 0,
    [ESecondaryDetailsPanelType.EXPENSE]: 0,
    [ESecondaryDetailsPanelType.VENDOR]: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @computed get getMetaData(): Record<ESecondaryDetailsPanelType, number> {
    return toJS(this.metaData);
  }

  @action incrementPromiseCounter = (area: ESecondaryDetailsPanelType) => {
    runInAction(() => {
      if (Boolean(this.metaData[area])) {
        this.metaData[area]++;
      } else {
        this.metaData[area] = 1;
      }
    });
  };

  @action decrementPromiseCounter = (area: ESecondaryDetailsPanelType) => {
    runInAction(() => {
      this.metaData[area] > 0 && this.metaData[area]--;
    });
  };

  @action trackPromise = (
    promise: Promise<any>,
    area: ESecondaryDetailsPanelType,
    waitResolved = 0 //@param waitResolved — The number of milliseconds to delay Resolved.
  ) => {
    if (!(promise instanceof Promise)) throw Error('Invalid Promise');

    this.incrementPromiseCounter(area);

    const onResolveHandler = () => this.decrementPromiseCounter(area);

    promise.then(delay(waitResolved)).then(onResolveHandler, onResolveHandler);

    return promise;
  };

  isLoadingPanel = (panelType: ESecondaryDetailsPanelType): boolean => {
    return this.getMetaData?.[panelType] > 0;
  };
}

const delay = (time: number) => (result: any) =>
  new Promise((resolve) => setTimeout(() => resolve(result), time));

export const useDetailsPanelStore = new DetailsPanelStore();
