export const defaultData: DefaultData = {
  companyLogo: '',
  terminalName: '',
  dot: '',
  mc: '',
  contactName: '',
  email: '',
  phone: '',
  phoneExt: '',
  phoneCode: '',
  fax: '',
  faxExt: '',
  faxCode: '',
  companyAddress: '',
  id: '',
  imagePath: '',
  isPrimary: false,
  postLogoData: '',
  logo: '',
};

export type DefaultData = {
  companyLogo: string;
  terminalName: string;
  dot: string;
  mc: string;
  contactName: string;
  email: string;
  phone: string;
  phoneExt: string;
  phoneCode: string;
  fax: string;
  faxExt: string;
  faxCode: string;
  companyAddress: string;
  id: string;
  imagePath: any;
  isPrimary: boolean;
  postLogoData: any;
  logo: string;
};

export const editErrors: EditErrors = {
  terminalName: '',
  dot: '',
  mc: '',
  contactName: '',
  email: '',
  phone: '',
  phoneExt: '',
  fax: '',
  faxExt: '',
  companyAddress: '',
  logo: '',
  streetAddress: '',
  zip: '',
  state: '',
  city: '',
};

export type EditErrors = {
  terminalName: string;
  dot: string;
  mc: string;
  contactName: string;
  email: string;
  phone: string;
  phoneExt: string;
  fax: string;
  faxExt: string;
  companyAddress: string;
  logo: string;
  streetAddress: string;
  zip: string;
  state: string;
  city: string;
};

export const addTerminalData = {
  terminalName: '',
  dot: '',
  mc: '',
  contactName: '',
  email: '',
  phone: '',
  phoneExt: '',
  fax: '',
  faxExt: '',
  address: '',
  companyAddress: '',
};

export const addTerminalErrors = {
  terminalName: '',
  dot: '',
  mc: '',
  contactName: '',
  email: '',
  phone: '',
  phoneExt: '',
  fax: '',
  faxExt: '',
};

export const deleteTerminalText = {
  header: 'Delete Terminal?',
  title: 'Are you sure you want to permanently delete this terminal?',
  positiveButton: 'Delete',
  negativeButton: 'Cancel',
};

export const disbaleTerminalText = {
  header: 'Disable Terminal?',
  title: 'Are you sure you want to Disable this terminal?',
  positiveButton: 'Disable',
  negativeButton: 'Cancel',
};

export const saveChangesText = {
  header: 'Save Changes?',
  title:
    'Looks like you have updated some items, do you want to save them before leaving?',
  positiveButton: 'Save',
  negativeButton: `Don't Save`,
};

export const emptyStateText = {
  title: 'Stop! terminal time',
  subTitle:
    'Terminals allows you to create smaller teams in LoadOps, you can have drivers and dispatch allocated to one terminal and they can manage their operations in isolation.',
  enableButton: 'Enable Terminals',
};

export const leavePopup = {
  title: `Leave`,
  subtitle: `Are you sure you want to Leave?`,
  positiveButton: 'OK',
};

export const makeShort = (str: string, no: number) => {
  if (str) return str.length >= no ? str.slice(0, no) + '...' : str;
  return '';
};

export const countryCodeOptions = [{ label: '+1', key: 'USA' }];

export function stringContainsNumber(value: string) {
  return value === '' ? true : /^[0-9]+$/.test(value);
}

export const exitCheck = (values: any) =>
  Object.values(values).every((x) => x === null || x === '');

export const emailValidation = new RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
export const passwordValid = new RegExp(
  /^(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/
);

export const deleteValidError =
  'The terminal cannot be deleted. It has association with assets,loads,load templetes,expenses,and/or users.';

export const deleteError = 'Something went Wrong';

export const deletePrimaryTerminal =
  'You cannot disable the terminal model until you delete all Secondary Terminals';
