import { IRowActualData } from '../../../types/ELDManageTypes';

export class FactoringSummary {
  id!: string;
  displayName!: string;
}

export interface ISelectedCustomerTypes {
  currentCustomerIntegrationFormField?: Array<string>;
  closeDrawer: (value: boolean) => void;
  currentCustomerData?: object;
  selectedCardData?: object;
  isEditForm: boolean;
  editId?: number;
  phoneExtesion?: Array<string>;
  connectAuth: (cardData: IRowActualData) => void;
  open: boolean;
  toggleDrawer: (value: boolean) => void;
  resetValidation?: boolean;
  isReauthenticate?: boolean;
}

export interface IeldCustomerFeatures {
  id: string;
  providerId: string;
  supportedFeatures: Array<string>;
}
export class GetAxeleFactoreRequest {
  factoringId?: string;
  id?: string;
  status?: string;
  organizationId?: number;
  customer?: string;
  customerMappingIds?: Array<any>;
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
}

export class UpdateMapCustomer {
  id!: string;
  organizationId!: number;
  pageNumber!: number;
  pageSize!: number;
}

export interface LinearStepperType {
  currentCustomerIntegrationFormField?: Array<string>;
  closeDrawer?: (value: boolean) => void;
  currentCustomerData?: object;
  slectedCardData?: object;
  isEditForm?: boolean;
  editId?: number;
  phoneExtesion?: Array<string>;
  connectAuth: (cardData: IRowActualData) => void;
  open?: boolean;
  resetValidation?: boolean;
  isReauthenticate?: boolean;
}

export const sideDrawerDisplayData = {
  desc: '',
  fields: [],
  label: 'Enter Integration Credentials:',
  userGuide: '',
};
