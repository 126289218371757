import { Box, SxProps, Tooltip } from '@mui/material';
import AppointmentConfirmedIcon from '../../static/appointment/appointment_confirmed';
import AppointmentRequiredIcon from '../../static/appointment/appointment_required';
import NoAppointmentIcon from '../../static/appointment/no_appointment';

interface IProps {
  status?: 'YES' | 'NO' | 'CONFIRMED';
  sx?: SxProps;
  size?: number;
  showTooltip?: boolean;
}

const AppointmentConfirmation = ({
  status,
  sx,
  size,
  showTooltip = true,
}: IProps) => {
  switch (status) {
    case 'YES':
      return (
        <Tooltip title={showTooltip ? 'Appt confirmation required' : ''}>
          <Box display={'inline-flex'} sx={sx}>
            <AppointmentRequiredIcon size={size} />
          </Box>
        </Tooltip>
      );
    case 'NO':
      return (
        <Tooltip title={showTooltip ? 'Appt confirmation not required' : ''}>
          <Box display={'inline-flex'} sx={sx}>
            <NoAppointmentIcon size={size} />
          </Box>
        </Tooltip>
      );
    case 'CONFIRMED':
      return (
        <Tooltip title={showTooltip ? 'Appt confirmed' : ''}>
          <Box display={'inline-flex'} sx={sx}>
            <AppointmentConfirmedIcon size={size} />
          </Box>
        </Tooltip>
      );
    default:
      return <></>;
  }
};

export default AppointmentConfirmation;
