import { Type } from 'class-transformer';
import { StopContactDTO } from '../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import { phoneType } from '../../../types/index';
import { QueryParams } from '../commonMixed/QueryParams';
import {
  AddressDTO,
  CenterDTO,
  ContactSummaryDTO,
  locationFiltersRequestType,
  locationListExcelRequestType,
  locationListRequestType,
  PreferenceType,
} from './Location';

export class CreateLocationRequest {
  locationName!: string;
  addressDTO!: AddressDTO;
  notes!: string;
  avgHandlingTime!: number;
  contacts!: ContactSummaryDTO[];
  center!: CenterDTO;
  locationPreference!: PreferenceType;

  getLocationOfLoadStop(stop: any) {
    const { addressDTO, location, avgHandlingTime, contact } = stop;
    const { locationName } = location;
    this.locationName = locationName;
    this.addressDTO = addressDTO;
    this.avgHandlingTime = avgHandlingTime;
    this.locationPreference = 2;

    if (contact) {
      const { fullName, email, countryCode, phone, extension } = contact;
      const locationPrimaryContact = new ContactSummaryDTO();
      locationPrimaryContact.id = 1;
      locationPrimaryContact.isPrimary = true;
      locationPrimaryContact.fullName = fullName || '';
      locationPrimaryContact.email = email || '';
      locationPrimaryContact.description = '';
      locationPrimaryContact.phoneData = {
        countryCode,
        phone,
        extension,
      };
      this.contacts = [locationPrimaryContact];
    } else {
      this.contacts = [];
    }
  }
}

export class CreateLocationNewRequest {
  locationName!: string;
  addressDTO!: AddressDTO;
  notes!: string;
  avgHandlingTime!: number;
  contacts?: StopContactDTO[];
  center?: CenterDTO;
  locationPreference!: PreferenceType;
  id?: number;
  timezone?: string;

  constructor(stop: any) {
    const { addressDTO, location, avgHandlingTime, contact } = stop;
    const { locationName } = location;
    this.locationName = locationName;
    this.addressDTO = addressDTO;
    this.avgHandlingTime = avgHandlingTime;
    this.locationPreference = 2;

    if (contact) {
      const { fullName, email, countryCode, phone, extension } = contact;
      const locationPrimaryContact = new ContactSummaryDTO();
      locationPrimaryContact.id = 1;
      locationPrimaryContact.isPrimary = true;
      locationPrimaryContact.fullName = fullName || '';
      locationPrimaryContact.email = email || '';
      locationPrimaryContact.description = '';
      locationPrimaryContact.phoneData = {
        countryCode,
        phone,
        extension,
      };
      this.contacts = [locationPrimaryContact];
    } else {
      this.contacts = [];
    }
  }
}

export class GetLocationListRequest extends QueryParams {
  sort!: string;
  pageNumber!: number;
  pageSize!: number;
  constructor({ sort, pageNumber, pageSize }: locationListRequestType) {
    super(true);
    this.sort = sort;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
  }
}

export class GetLocationFiltersRequest {
  locationNamesFilter!: string[] | null;
  locationContactsFilter!: string[] | null;
  locationAddressFilter!: string[] | null;
  locationPreferenceFilter!: string[] | null;
  timezoneFilter!: string[] | null;
  gridColumnMetadataList?: string[];
  constructor({
    locationNamesFilter,
    locationContactsFilter,
    locationAddressFilter,
    locationPreferenceFilter,
    timezoneFilter,
    gridColumnMetadataList,
  }: locationFiltersRequestType) {
    this.locationNamesFilter = locationNamesFilter;
    this.locationContactsFilter = locationContactsFilter;
    this.locationAddressFilter = locationAddressFilter;
    this.locationPreferenceFilter = locationPreferenceFilter;
    this.timezoneFilter = timezoneFilter;
    this.gridColumnMetadataList = gridColumnMetadataList;
  }
}
export class GetLocationListExcelRequest extends QueryParams {
  sort!: string;
  pageNumber!: number;
  pageSize!: number;
  gridColumnMetadataList: string[];
  constructor({
    sort,
    pageNumber,
    pageSize,
    gridColumnMetadataList,
  }: locationListExcelRequestType) {
    super(true);
    this.sort = sort;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.gridColumnMetadataList = gridColumnMetadataList;
  }
}

export class DeleteLocationByIdRequest extends QueryParams {
  id!: number;
}
export class GetLocationDetailsByIdRequest extends QueryParams {
  id!: number;
}

export class EditLocationDetailsByIdRequest {
  id!: number;
  locationName!: string;
  addressDTO!: AddressDTO;
  notes!: string;
  avgHandlingTime!: number;
  contacts!: ContactSummaryDTO[];
  center!: CenterDTO;
  locationPreference!: PreferenceType;
}

export class GetLocationNamesListRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  nameKeyword!: string;
}
export class GetAddressNamesListRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  addressKeyword!: string;
}
export class GetContactNamesListRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  locationContactNameKeyword!: string;
}

export class GetLocationListByNameAddressSearchRequest extends QueryParams {
  pageNumber!: number;
  pageSize!: number;
  nameAddressKeyword!: string;
}

export class CreateLocationContactRequest {
  private email!: string;
  private phoneData!: phoneType;
  private fullName!: string;
  private description!: string;
  private isPrimary!: boolean;
  constructor(data: ContactSummaryDTO, private locationId: number) {
    this.locationId = locationId;
    this.isPrimary = data.isPrimary;
    this.email = data.email;
    this.phoneData = data.phoneData;
    this.fullName = data.fullName;
    this.description = data.description;
  }
}

export class LocationBasicData {
  id: number;
  locationName: string;
  constructor(data: any) {
    this.id = data.id;
    this.locationName = data.locationName;
  }
}
export class ValidateLocationsUniqueNamesRequest extends QueryParams {
  @Type(() => LocationBasicData)
  locations: LocationBasicData[];
  constructor(data: any) {
    super();
    this.locations = data.locations;
  }
}

export class GetLocationListDetailsByCoordsRequest {
  data!: CenterDTO[];
}

export class GetTimezoneByCoordsRequest extends QueryParams {
  latitude!: number;
  longitude!: number;

  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
