import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../StorageManager/StorageManager';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../common/Permission';
import FormDialog from '../../../common/Ui/FormDialog/index';
import { TrailerAlertSummaryDTO } from '../../../models';
import { AddIcon } from '../../../ui-kit/components/Assets';
import DeletePopup from '../../../ui-kit/components/DeletePopup';
import { useSafetyAlert } from '../../../views/safety/alerts/context/SafetyAlertContext';
import {
  addNameToAlertFromDictionary,
  trailerAlertValidationSchema,
} from '../../shared/equipment';
import { isInactive } from '../../tractors/utils';
import TrailerController from '../TrailerController';
import AddNewAlertForm from './AddNewAlertForm';
import AlertsTabCard from './AlertsTabCard';

function AlertsTabDetails({
  onUpdateWarningsCount,
}: {
  onUpdateWarningsCount: (
    id: string,
    warningValue: number,
    criticalValue: number,
    isSum: boolean
  ) => void;
}) {
  const [isCreateAlertFormDialogOpen, setIsCreateAlertFormDialogOpen] =
    useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deletedField, setDeletedField] = useState<TrailerAlertSummaryDTO>();

  const {
    createTrailerAlert,
    defaultTrailersTypesList,
    deleteTrailerAlertById,
  } = TrailerController.instance();

  const { watch, setValue, formState } = useFormContext();

  const alertList = watch('alertsList');

  const trailerId = watch('id');
  const status = watch('status');

  const dirtyFields = formState.dirtyFields['alertsList'];
  const storageUserData = StorageManager.getUser();

  const handleOpenAddAlertModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setIsCreateAlertFormDialogOpen(true);
  };

  const onAddAlert = (data: TrailerAlertSummaryDTO) => {
    const defaultTrailersType = defaultTrailersTypesList.find(
      (item) => item.maintenanceTypeId === data.maintenanceTypeId
    );
    if (!defaultTrailersType) return;

    const updatedData = {
      ...data,
      maintenanceType: defaultTrailersType.maintenanceType,
    };
    createTrailerAlert(updatedData, (alert: TrailerAlertSummaryDTO) => {
      fetchMainData?.();
      handleClose();
      setValue('alertsList', [...alertList, alert]);
      if (alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'WARNING') {
        onUpdateWarningsCount(trailerId, 1, 0, true);
      }
      if (alert?.safetyIssueTypeWithDaysAndMiles?.issueType === 'CRITICAL') {
        onUpdateWarningsCount(trailerId, 0, 1, true);
      }
    });
  };
  const handleClose = () => {
    setIsCreateAlertFormDialogOpen(false);
  };

  const confirmAlertDeletion = (alert: TrailerAlertSummaryDTO) => {
    setDeletedField(alert);
    setDeleteDialogOpen(true);
  };

  const { fetchMainData } = useSafetyAlert();

  const handleDelete = () => {
    setDeleteDialogOpen(false);

    if (!deletedField || !deletedField.id) return;
    const updatedAlerts = alertList.filter(
      (item: TrailerAlertSummaryDTO) => item.id !== deletedField.id
    );

    deleteTrailerAlertById(deletedField.id, () => {
      fetchMainData?.();
      setValue('alertsList', updatedAlerts);
    });
    if (
      deletedField?.safetyIssueTypeWithDaysAndMiles?.issueType === 'WARNING'
    ) {
      onUpdateWarningsCount(trailerId, -1, 0, true);
    }
    if (
      deletedField?.safetyIssueTypeWithDaysAndMiles?.issueType === 'CRITICAL'
    ) {
      onUpdateWarningsCount(trailerId, 0, -1, true);
    }
  };

  const getFilteredTypesList = () => {
    const defaultTrailersTypes = defaultTrailersTypesList.filter(
      (item) =>
        !alertList.find(
          (element: any) => element.maintenanceTypeId === item.maintenanceTypeId
        )
    );

    return defaultTrailersTypes.map((item) => {
      return addNameToAlertFromDictionary(
        item,
        'TRAILER'
      ) as TrailerAlertSummaryDTO;
    });
  };

  const equipmentAddAlertsPermission: {
    [key: string]: AXELE_PERMISSION_TYPE[];
  } = {
    AC: [AXELE_PERMISSION_TYPE.EQUIPMENT_ALERT_ADD],
    DR: [AXELE_PERMISSION_TYPE.EQUIPMENT_ALERT_ADD],
    SP: [AXELE_PERMISSION_TYPE.EQUIPMENT_ALERT_ADD],
  };

  return (
    <Grid container sx={{ p: 2 }}>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={0}
      >
        <Grid item>
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            Alerts
          </Typography>
          {defaultTrailersTypesList.length !== alertList?.length &&
            !isInactive(status) && (
              <Permission
                includes={
                  equipmentAddAlertsPermission[storageUserData?.roleCode]
                }
              >
                <Button
                  onClick={handleOpenAddAlertModal}
                  variant="outlined"
                  style={{
                    minWidth: 38,
                    minHeight: 38,
                    marginLeft: 10,
                    borderColor: 'primary.main',
                    marginBottom: 8,
                  }}
                >
                  <AddIcon />
                </Button>
              </Permission>
            )}
        </Grid>
        <Grid item>
          <Grid item container direction={'column'}>
            <Grid container item justifyContent="flex-end">
              <Typography variant="h7" sx={{ color: 'primary.main' }}>
                {alertList?.length || 0}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="tooltip" sx={{ color: 'text.secondary' }}>
                {t('totalAlerts')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {alertList && !isInactive(status)
        ? alertList.map((field: TrailerAlertSummaryDTO, index: number) => (
            <AlertsTabCard
              key={field.id}
              index={index}
              handleDelete={confirmAlertDeletion}
              dirtyFields={dirtyFields}
              onUpdateWarningsCount={onUpdateWarningsCount}
            />
          ))
        : null}
      {isCreateAlertFormDialogOpen && (
        <FormDialog
          data={{
            trailerId,
            maintenanceTypeId: getFilteredTypesList()[0]?.maintenanceTypeId,
          }}
          titleText={t('addNewAlert')}
          actionLabel={t('addAlert')}
          open={isCreateAlertFormDialogOpen}
          onAction={onAddAlert}
          handleClose={handleClose}
          contentRenderer={() => (
            <AddNewAlertForm alertOptions={getFilteredTypesList()} />
          )}
          validationSchema={trailerAlertValidationSchema}
        />
      )}
      {deleteDialogOpen && (
        <DeletePopup
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onAction={handleDelete}
          title={t('deleteAlert')}
          body="Are you sure you want to delete the alert?"
        />
      )}
    </Grid>
  );
}

export default AlertsTabDetails;
