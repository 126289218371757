import React from 'react';
import { SingleDateRangePicker } from '../../DateRangePicker';
import Datepicker from '../../Datepicker';
import BaseMultipleAutocomplete from '../../MultipleAutocomplete';
import { RelativeDateRangeFilter } from '../../RelativeDateRange';
import SingleAutocomplete from '../../SingleAutocomplete';
import TextInput from '../../TextField';
import { GridButtonGroupFilter } from '../components/Filters/GridButtonGroupFilter';
import { GridSwitchFilter } from '../components/Filters/GridSwitchFilter/GridSwitchFilter';

const defaultStyles = {
  width: 270,
  '.MuiAutocomplete-tag': {
    maxWidth: 'calc(100% - 95px)',
  },
};

export function FilterComponent(
  column: any,
  defaultFilterValue: any,
  onChangeCb?: any,
  field?: any,
  error?: any,
  isFormControl?: boolean,
  isMobile?: boolean,
  pageName?: string
) {
  const mobileCustomStyles = {
    ...(isMobile && { width: '100%' }),
  };
  const fixedLabel =
    isFormControl &&
    (column.hideLabelOnAllFilters ||
      (column.fixedLabel === undefined ? true : column.fixedLabel));
  switch (column.type) {
    case 'MultipleAutocomplete': {
      const value = isFormControl
        ? field?.value
        : defaultFilterValue[column.name] || [];
      return (
        <BaseMultipleAutocomplete
          id={`${pageName}-filter-${column.name}`}
          disabled={column.disabled}
          {...field}
          label={
            (!isFormControl || !column.hideLabelOnAllFilters) && column.label
          }
          name={column.name}
          getOptions={column.getData}
          fieldName={column.fieldName}
          fieldValue={column.fieldValue}
          immutableModel={column.immutableModel}
          onChangeCb={(data: any[], name: string) => {
            isFormControl && field.onChange(data, name);
            isFormControl && column.onChange && column.onChange(data, name);
            !isFormControl && onChangeCb && onChangeCb(data, name);
          }}
          value={
            isFormControl ? field?.value : defaultFilterValue[column.name] || []
          }
          options={column.options}
          error={error ? { message: error?.message || '' } : undefined}
          defaultValue={column.defaultValue}
          customStyles={{
            ...(column.customStyles || defaultStyles),
            ...mobileCustomStyles,
          }}
          customTextInputStyles={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: !isFormControl && 'primary.outlinedRestingBorder',
            },
          }}
          PopperSxProps={column?.PopperSxProps}
          variant={isFormControl ? 'standard' : 'outlined'}
          customInputProps={{
            borderRadius: !isFormControl && '6px',
          }}
          customInputLabelProps={{
            color: !isFormControl && 'primary.main',
            fontSize: '14px',
          }}
          size={'small'}
          areChipsInsideOption
          startAdornment={column.startAdornment}
          placeholder={isFormControl && !value?.length ? column.label : ''}
          fixedLabel={fixedLabel}
          isMultiLineOption={column?.isMultiLineOption}
          subFieldName={column?.subFieldName}
          isCustomTag={column?.isCustomTag}
        />
      );
    }
    case 'SingleAutocomplete': {
      const value = isFormControl
        ? field?.value
        : defaultFilterValue[column.name]?.length === 0
        ? null
        : defaultFilterValue[column.name];
      return (
        <SingleAutocomplete
          id={`${pageName}-filter-${column.name}`}
          disabled={column.disabled}
          {...field}
          label={
            (!isFormControl || !column.hideLabelOnAllFilters) && column.label
          }
          name={column.name}
          getOptions={column.getData}
          fieldName={column.fieldName}
          fieldValue={column.fieldValue}
          onChangeCb={(data: any[], name: string) => {
            isFormControl && field.onChange(data, name);
            isFormControl && column.onChange && column.onChange(data, name);
            !isFormControl && onChangeCb && onChangeCb(data, name);
          }}
          value={
            isFormControl
              ? field?.value
              : defaultFilterValue[column.name]?.length === 0
              ? null
              : defaultFilterValue[column.name]
          }
          options={column.options}
          error={error ? { message: error?.message || '' } : undefined}
          defaultValue={column.defaultValue}
          customStyles={{
            ...(column.customStyles || defaultStyles),
            ...mobileCustomStyles,
          }}
          customTextInputStyles={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: !isFormControl && 'primary.outlinedRestingBorder',
            },
          }}
          variant={isFormControl ? 'standard' : 'outlined'}
          customInputProps={{
            borderRadius: !isFormControl && '6px',
          }}
          customInputLabelProps={{
            color: !isFormControl && 'primary.main',
          }}
          size={'small'}
          groupBy={column.groupBy}
          newOption={column.newOption}
          freeSolo={column.freeSolo}
          addNewOption={column.addNewOption}
          disableClear={column.disableClearable}
          placeholder={isFormControl && !value ? column.label : ''}
          fixedLabel={fixedLabel}
        />
      );
    }
    case 'DateRangePicker':
      let sdate, edate;
      if (isFormControl) {
        if (field.value === null) {
          sdate = edate = null;
        } else {
          sdate = field?.value?.length > 0 ? field.value[0] : null;
          edate = field?.value?.length > 1 ? field.value[1] : null;
        }
      } else {
        if (defaultFilterValue[column.name] === null) {
          sdate = edate = null;
        } else {
          sdate =
            defaultFilterValue[column.name].length > 0
              ? defaultFilterValue[column.name][0]
              : null;
          edate =
            defaultFilterValue[column.name].length > 1
              ? defaultFilterValue[column.name][1]
              : null;
        }
      }
      return (
        <SingleDateRangePicker
          {...field}
          id={`${pageName}-filter-${column.name}`}
          format={column.format}
          startDate={sdate}
          endDate={edate}
          label={column.label}
          handleChange={(startDate: any, endDate: any, name = column.name) => {
            const newDateRanges =
              startDate === null && endDate === null
                ? []
                : [startDate, endDate];
            isFormControl && field.onChange(newDateRanges, name);
            isFormControl &&
              column.onChange &&
              column.onChange(newDateRanges, name);
            !isFormControl && onChangeCb && onChangeCb(newDateRanges, name);
          }}
          disabled={column.disabled}
          clearable={column.clearable}
          rest={column.rest}
          variant={isFormControl ? 'standard' : 'outlined'}
          size={'small'}
          styleTextFieldProps={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: !isFormControl && 'primary.outlinedRestingBorder',
              borderRadius: !isFormControl && '6px',
            },
            width: 270,
            '& .MuiOutlinedInput-input': {
              color: !isFormControl && 'primary.main',
            },
            ...mobileCustomStyles,
          }}
          customInputLabelProps={{
            color: !isFormControl && 'primary.main',
          }}
          timezone={column?.timezone}
          withTimeTz={column?.withTimeTz}
          fixedLabel={fixedLabel}
          placeholder={column.label}
        />
      );
    case 'DatePicker':
      return (
        <Datepicker
          {...field}
          id={`${pageName}-filter-${column.name}`}
          label={column.label}
          variant={isFormControl ? 'standard' : 'outlined'}
          onDateChange={(newDate: any, validationFlag: boolean, id: string) => {
            isFormControl && field.onChange(newDate, id);
            isFormControl &&
              column.onChange &&
              column.onChange(newDate, id, validationFlag);
            !isFormControl &&
              onChangeCb &&
              onChangeCb(newDate, id, validationFlag);
          }}
          required={column.required}
          disableFuture={column.disableFuture}
          error={error}
          disabled={column.disabled}
          allowEmptyDate
          inputFormat={column.inputFormat}
          initialDate={
            isFormControl ? field?.value : defaultFilterValue[column.name]
          }
          helperText={error ? { message: error?.message || '' } : undefined}
          styleProps={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: !isFormControl && 'primary.outlinedRestingBorder',
            },
            width: 270,
            ...column.customStyles,
            ...mobileCustomStyles,
          }}
          customInputProps={{
            borderRadius: !isFormControl && '6px',
          }}
          customInputLabelProps={{
            color: !isFormControl && 'primary.main',
          }}
          size={'small'}
          timezone={column?.timezone}
          fixedLabel={fixedLabel}
          placeholder={column.label}
        />
      );
    case 'TextField':
      return (
        <TextInput
          id={`${pageName}-filter-${column.name}`}
          disabled={column.disabled}
          {...field}
          label={column.label}
          name={column.name}
          onChange={(event: any, textErrorCheck: boolean, id: string) => {
            isFormControl && field.onChange(event.target.value, id);
            isFormControl &&
              column.onChange &&
              column.onChange(event.target.value, id, textErrorCheck);
            !isFormControl &&
              onChangeCb &&
              onChangeCb(event.target.value, id, textErrorCheck);
          }}
          value={
            isFormControl ? field?.value : defaultFilterValue[column.name] || ''
          }
          error={error ? { message: error?.message || '' } : undefined}
          defaultValue={column.defaultValue}
          styleProps={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: !isFormControl && 'primary.outlinedRestingBorder',
            },
            width: 270,
            ...column.customStyles,
            ...mobileCustomStyles,
          }}
          variant={isFormControl ? 'standard' : 'outlined'}
          InputProps={{
            borderRadius: !isFormControl && '6px',
          }}
          InputLabelProps={{
            color: !isFormControl && 'primary.main',
          }}
          size={'small'}
          placeholder={column.label}
          fixedLabel={fixedLabel}
        />
      );

    case 'RelativeDateRange':
      return (
        <RelativeDateRangeFilter
          id={`${pageName}-filter-${column.name}`}
          column={column}
          defaultFilterValue={defaultFilterValue}
          onChangeCb={onChangeCb}
          isFormControl={isFormControl}
          field={field}
        />
      );
    case 'ButtonGroup':
      //All Filter
      if (isFormControl || column?.showQuickFilterAsButtonGroup) {
        return (
          <GridButtonGroupFilter
            id={`${pageName}-filter-${column.name}`}
            column={column}
            defaultFilterValue={defaultFilterValue}
            onChangeCb={onChangeCb}
            field={field}
            error={error}
            isFormControl={isFormControl}
            styleProps={
              isFormControl
                ? {
                    ...(column.customStyles || defaultStyles),
                    ...mobileCustomStyles,
                  }
                : column.customStyles
            }
          />
        );
      }

      //Quick Filter
      const newColumn = {
        ...column,
      };
      if (newColumn.multiple) {
        newColumn.type = 'MultipleAutocomplete';
      } else {
        newColumn.type = 'SingleAutocomplete';
      }
      return FilterComponent(
        newColumn,
        defaultFilterValue,
        onChangeCb,
        field,
        error,
        isFormControl,
        isMobile,
        pageName
      );

    case 'GridSwitchFilter':
      return (
        <GridSwitchFilter
          id={`${pageName}-filter-${column.name}`}
          column={column}
          defaultFilterValue={defaultFilterValue}
          onChangeCb={onChangeCb}
          field={field}
          error={error}
          isFormControl={isFormControl}
          customStyles={{
            ...(column.customStyles || defaultStyles),
            ...mobileCustomStyles,
          }}
        />
      );

    case 'Custom':
      const CustomCopmponent = column.customComponent;
      return (
        <CustomCopmponent
          id={`${pageName}-filter-${column.name}`}
          column={column}
          defaultFilterValue={defaultFilterValue}
          onChangeCb={onChangeCb}
          field={field}
          error={error}
          isFormControl={isFormControl}
        />
      );
    default:
      return null;
  }
}
