import { ServiceError } from './helperTypes';
import { Service } from './Service';
import { PreferencesDTO } from '../../models';
export abstract class IPreferenceService extends Service {
  abstract getPreferencesData(): Promise<PreferencesDTO | null | ServiceError>;

  abstract getCompanyLogo(): Promise<any | null | ServiceError>;

  abstract postPreferencesData(
    queryData: any
  ): Promise<PreferencesDTO | null | ServiceError>;

  abstract postCompanyLogo(file: any): Promise<any | null | ServiceError>;
}
