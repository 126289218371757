import { Warning } from '../../locales/en/dispatch/warning';

export const DriverGroupWarningType = {
  SAFETY_ISSUE_WARNING: 'SAFETY_ISSUE_WARNING',
  INCOMP_LOAD_TRAILER_WARNING: 'INCOMP_LOAD_TRAILER_WARNING',
  INCOMP_LOAD_DRIVER_WARNING: 'INCOMP_LOAD_DRIVER_WARNING',
  SAFETY_DRIVER_ISSUE_CRITICAL: 'SAFETY_DRIVER_ISSUE_CRITICAL',
  INCOMP_DRIVER_TRACTOR_WARNING: 'INCOMP_DRIVER_TRACTOR_WARNING',
  SAFETY_TRACTOR_MAINTENANCE_CRITICAL: 'SAFETY_TRACTOR_MAINTENANCE_CRITICAL',
  SAFETY_TRAILER_MAINTENANCE_CRITICAL: 'SAFETY_TRAILER_MAINTENANCE_CRITICAL',
  SAFETY_TRAILER_MAINTENANCE_WARNING: 'SAFETY_TRAILER_MAINTENANCE_WARNING',
  SAFETY_TRACTOR_MAINTENANCE_WARNING: 'SAFETY_TRACTOR_MAINTENANCE_WARNING',
  SAFETY_HOS: 'SAFETY_HOS',
  PLANNING_NEED_LOAD: 'PLANNING_NEED_LOAD',
  DRIVER_HOS_WARNING: 'DRIVER_HOS_WARNING',
  INCOMP_DRIVER_TRAILER_WARNING: 'INCOMP_DRIVER_TRAILER_WARNING',
};

export const TripWarningType = {
  LOAD_DELAYS: 'LOAD_DELAYS',
  LOAD_DETENTION: 'LOAD_DETENTION',
  LOAD_UNASSIGNED: 'LOAD_UNASSIGNED',
  INCOMP_LOAD_DRIVER_WARNING: 'INCOMP_LOAD_DRIVER_WARNING',
  INCOMP_LOAD_TRAILER_WARNING: 'INCOMP_LOAD_TRAILER_WARNING',
};

export const WarningsInitialType = {
  driverPlanningWarnings: [],
  driverSafetyWarnings: [],
  driverTractorIncompatibilityWarnings: [],
  driverTrailerIncompatibilityWarnings: [],
  tractorSafetyWarnings: [],
  trailerSafetyWarnings: [],
};

export const DRIVER_WARNINGS_BY_KEY = {
  driverHOSWarning: Warning.driverHOSWarning,
  [DriverGroupWarningType.INCOMP_DRIVER_TRACTOR_WARNING]: {
    MANUAL_TRANSMISSION: Warning.MANUAL_TRANSMISSION,
    AIR_BRAKES: Warning.AIR_BRAKES,
  },
  [DriverGroupWarningType.INCOMP_DRIVER_TRAILER_WARNING]: {
    TRAILER: Warning.TRAILER,
    TANKER: Warning.TANKER,
  },
} as any;

export const LOAD_WARNINGS_BY_KEY = {
  [TripWarningType.LOAD_DELAYS]: Warning.TripWarningTypeLOAD_DELAYS,
  [TripWarningType.LOAD_DETENTION]: Warning.TripWarningTypeLOAD_DETENTION,
  [TripWarningType.LOAD_UNASSIGNED]: Warning.TripWarningTypeLOAD_UNASSIGNED,
  [TripWarningType.INCOMP_LOAD_DRIVER_WARNING]:
    Warning.TripWarningTypeINCOMP_LOAD_DRIVER_WARNING,
  [TripWarningType.INCOMP_LOAD_TRAILER_WARNING]:
    Warning.TripWarningTypeINCOMP_LOAD_TRAILER_WARNING,
};
