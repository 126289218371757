import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { CloseIcon } from '../../assets/icons';
import { lightThemeObject } from '../../theme/lightTheme';
import ButtonImproved from '../ButtonImproved/ButtonImproved';

const StyledDialogTitle = styled(DialogTitle)({
  margin: '0 auto',
  fontSize: 16,
  fontFamily: 'Poppins',
  width: '92%',
});

const StyledCloseIcon = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});

const StyledDialogContent = styled(DialogContent)({
  backgroundColor: 'background.default',
  textAlign: 'center',
  padding: 0,
  borderRadius: '19.3689px',
  color: 'primary.main',
  fontFamily: 'Poppins',
});

const StyledCloseButton = styled(Button)({
  borderRadius: 10,
  border: '1px solid rgba(43, 100, 203, 0.5)',
  color: 'primary.main',
  fontSize: 13,
  fontWeight: 500,
  fontFamily: 'Poppins',
  lineHeight: '22px',
  textTransform: 'capitalize',
  minWidth: 121,
});

const StyledDivContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
});

const StyledDivActionsWrapper = styled('div')({
  display: 'flex',
  textAlign: 'center',
  margin: '20px auto 0 auto',
});

const StylesDialogTitleWrapper = styled('div')(({ theme }) => {
  return theme.unstable_sx({
    display: 'flex',
    justifyContent: 'space-between',
    color: 'background.default',
  });
});

export default function DeletePopup({
  open = true,
  onAction,
  onClose,
  title = 'Delete',
  body,
  subtitle,
  buttonText = 'Delete',
  backgroundColor,
  cancelText = 'Cancel',
  width,
  buttonTextColor,
  contentRenderer,
  buttonLoader = false,
}: {
  open: boolean;
  onClose?: () => void;
  onAction?: () => void;
  title: string;
  body?: string;
  subtitle?: string;
  buttonText?: string;
  cancelText?: string;
  backgroundColor?: string;
  buttonTextColor?: string;
  width?: string;
  contentRenderer?: () => React.ReactNode;
  buttonLoader?: boolean;
}) {
  const StyledDialog = styled(Dialog)(({ theme }) => {
    return theme.unstable_sx({
      '& .MuiDialog-paper': {
        borderRadius: '19.3689px',
        textAlign: 'center',
        width: width ? width : 282,
      },
      '& .MuiDialog-paper .title-wrapper': {
        backgroundColor: backgroundColor
          ? backgroundColor
          : theme.palette.error.dark,
        color: theme.palette.background.default,
      },
    });
  });
  const StyledDeleteButton = styled(ButtonImproved)(({ theme }) => {
    return theme.unstable_sx({
      marginLeft: 2,
      minWidth: '121px',
      backgroundColor: buttonTextColor
        ? buttonTextColor
        : backgroundColor
        ? backgroundColor
        : theme.palette.error.dark,
      '&:hover': {
        backgroundColor: buttonTextColor
          ? buttonTextColor
          : backgroundColor
          ? backgroundColor
          : theme.palette.error.dark,
      },
    });
  });

  return (
    <StyledDialog disableEnforceFocus open={open}>
      <StylesDialogTitleWrapper className="title-wrapper">
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <StyledCloseIcon onClick={onClose}>
          <CloseIcon width={14} height={14} />
        </StyledCloseIcon>
      </StylesDialogTitleWrapper>
      <StyledDialogContent>
        <StyledDivContentWrapper>
          {body}
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: lightThemeObject.palette.leftMenuCollapsed.inactive,
              paddingTop: '8px',
            }}
          >
            {subtitle}
          </Typography>
          {contentRenderer && contentRenderer()}
          <StyledDivActionsWrapper>
            {onClose && (
              <ButtonImproved
                variant={'outlined'}
                onClick={onClose}
                label={cancelText}
              ></ButtonImproved>
            )}
            {onAction && (
              <StyledDeleteButton
                onClick={onAction}
                variant={'contained'}
                label={buttonText}
                startIcon={
                  buttonLoader ? <CircularProgress size={18} /> : <></>
                }
                disabled={buttonLoader}
              ></StyledDeleteButton>
            )}
          </StyledDivActionsWrapper>
        </StyledDivContentWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
}
