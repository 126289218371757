import { ESecondaryDetailsPanelType } from '../../views/dispatch/constants/types';
import BaseEvent from '../BaseEvent';
import { EventTypes } from '../EventTypes';
export enum EEventTripStopAction {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  RESEQUENCED = 'RESEQUENCED',
  DELETED_PICKUP_RELAY = 'DELETED_PICKUP_RELAY',
}
export interface EventTripStopActionData {
  toType?: ESecondaryDetailsPanelType;
  action: EEventTripStopAction;
  tripId?: string;
  loadId?: string;
  updatedTripIds?: string[];
}

export default class EventTripStopAction extends BaseEvent {
  data: EventTripStopActionData;
  constructor(data: EventTripStopActionData) {
    super(EventTypes.TRIP_STOP_ACTION, data);
    this.data = data;
  }
}
