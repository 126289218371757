import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

export const StyledTooltipSectionHeader = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '30px',
  color: 'rgba(4, 0, 34, 0.75)',
}));

export const StyledTooltipSectionETA = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '18px',
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap',
}));

export const StyledTooltipSectionKPIValue = styled((props: TypographyProps) => (
  <Typography {...props} />
))(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '20px',
  color: 'rgba(4, 0, 34, 0.75)',
}));

export const StyledTooltipSectionItem = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  paddingTop: '16px',
  paddingLeft: '16px',
  width: '100%',
}));
