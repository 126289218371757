import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SxProps, Theme } from '@mui/material';
import React, { memo } from 'react';
import {
  CommonExpandableTimeline,
  CommonExpandableTimelineContentProps,
} from '../../../../AxeleTimeline';
import {
  ExpandableTimelineV3AddStopEvents,
  ExpandableTimelineV3EditIconEvents,
  StopSolutionV3Prop,
} from '../../types';

export interface ExpandableTimelineV3Props
  extends ExpandableTimelineV3EditIconEvents,
    ExpandableTimelineV3AddStopEvents {
  timelineContent: CommonExpandableTimelineContentProps<StopSolutionV3Prop>[];
  isMultipleExpanded?: boolean;
  sx?: SxProps<Theme>;
}

export const ExpandableTimelineV3 = memo(
  ({
    timelineContent,
    isMultipleExpanded = false,
    sx = {},
  }: ExpandableTimelineV3Props): JSX.Element => {
    return (
      <CommonExpandableTimeline<StopSolutionV3Prop>
        timelineContent={timelineContent}
        isMultipleExpanded={isMultipleExpanded}
        positionCollapseIcon="right"
        position="right"
        CollapseIcon={ArrowRightIcon}
        sx={{
          '.MuiTimelineOppositeContent-root': {
            width: 0, //hide the left side
          },
          ...sx,
        }}
      />
    );
  }
);
