import { memo, useCallback, useEffect, useState } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import { PayStatementStatus } from '../../../common/Ui/StatusComponent/StatusComponent';
import { StaticDataType } from '../../../contexts/StaticDataContext';
import {
  PayStatementData,
  PayStatementDetails,
  PayStatementsEntityTypeEnum,
  PayStatementsTypeEnum,
  PaymentDetails,
  SelectedPaymentsType,
} from '../../../models';
import { setUtcDate } from '../../../utils';
import {
  getNonTripPaymentsListById,
  getTripPaymentsListById,
  removeEditInReviewPayments,
} from '../utils/api.utils';
import AddPendingPaymentForm from './Form/AddPendingPaymentForm';

type propsType = {
  payStatementData: PayStatementData;
  paymentsIds: number[];
  open: boolean;
  staticData: StaticDataType;
  entityType: PayStatementsEntityTypeEnum;
  onClose: () => void;
  onAddPendingPayment?: (selectedPayments: SelectedPaymentsType) => void;
};

function AddPendingPaymentDialog({
  payStatementData,
  paymentsIds,
  open = false,
  staticData,
  entityType,
  onClose,
  onAddPendingPayment,
}: propsType) {
  const [payStatementDetailsData, setPayStatementDetailsData] =
    useState<PayStatementDetails | null>();
  const [selectedPayments, setSelectedPayments] =
    useState<SelectedPaymentsType>({});

  const [selectedPaymentsIds, setSelectedPaymentsIds] = useState<number[]>([]);

  async function getTripPaymentsList() {
    try {
      const tripPaymentsList = await getTripPaymentsListById(
        payStatementData,
        entityType,
        staticData
      );

      setPayStatementDetailsData((oldPayStatements) => {
        return { ...oldPayStatements, tripPaymentsList };
      });
    } catch (error) {}
  }

  async function getNonTripPaymentsList() {
    const response = await getNonTripPaymentsListById(
      payStatementData,
      staticData,
      entityType
    );
    setPayStatementDetailsData((oldPayStatements) => {
      return {
        ...oldPayStatements,
        fuelNonTripPaymentsList: response?.fuelPayStatementList,
        otherNonTripPaymentsList: response?.otherPayStatementList,
      };
    });

    return response;
  }

  useEffect(() => {
    if (
      typeof payStatementData === 'object' &&
      payStatementData?.payStatementId
    ) {
      setSelectedPayments({
        tripPaymentsList: [],
        fuelNonTripPaymentsList: [],
        otherNonTripPaymentsList: [],
      });
      setSelectedPaymentsIds([]);

      setPayStatementDetailsData(
        new PayStatementDetails({ id: payStatementData.id })
      );
      getTripPaymentsList();
      if (
        payStatementData.payStatementType !== PayStatementsTypeEnum.dispatcher
      ) {
        getNonTripPaymentsList();
      }
    }
  }, []);

  const handleCheckboxChange = useCallback(
    (
      checked: boolean,
      ids: number[],
      payment?: PaymentDetails,
      paymentListNmae?: string
    ) => {
      if (checked) {
        if (payment && paymentListNmae) {
          setSelectedPayments({
            ...selectedPayments,
            [paymentListNmae]: [...selectedPayments[paymentListNmae], payment],
          });
        }

        setSelectedPaymentsIds([...selectedPaymentsIds, ...ids]);
      } else {
        if (payment && paymentListNmae) {
          setSelectedPayments({
            ...selectedPayments,
            [paymentListNmae]: selectedPayments[paymentListNmae].filter(
              (item) => item.id !== payment.id
            ),
          });
        }

        setSelectedPaymentsIds(
          selectedPaymentsIds.filter((item) => item !== ids[0])
        );
      }
    },
    [selectedPaymentsIds]
  );

  const handleAddPendingPayments = async () => {
    const result = await removeEditInReviewPayments(
      {
        startDate: payStatementData.startDate,
        endDate: payStatementData.endDate,
        seqNumber: payStatementData?.seqNumber,
        updateDate: setUtcDate(),
        ids: [...paymentsIds, ...selectedPaymentsIds],
        status: payStatementData?.status!,
      },
      payStatementData
    );

    if (result) {
      handleClose();
      onAddPendingPayment && onAddPendingPayment(selectedPayments);
    }
  };

  const handleClose = () => {
    setSelectedPayments({
      tripPaymentsList: [],
      fuelNonTripPaymentsList: [],
      otherNonTripPaymentsList: [],
    });
    setSelectedPaymentsIds([]);
    onClose();
  };

  return (
    <>
      {open && !!payStatementData?.id ? (
        <DetailsPanel
          data={payStatementDetailsData}
          panelTitle={`${payStatementData?.payStatementType}: ${payStatementData?.payStatementName}`}
          onClose={handleClose}
          isGlobal
          contentRenderer={() => (
            <AddPendingPaymentForm
              staticData={staticData}
              entityType={entityType}
              payStatementData={payStatementData}
              onCheckboxChange={handleCheckboxChange}
            />
          )}
          showDeleteButton={false}
          renderCustomTitle={() => <PayStatementStatus status={entityType} />}
          secondaryActions={[
            {
              secondaryActionButtonLabel: 'Add Payments',
              secondaryActionButtonIconName: 'PostAddOutlined',
              onSecondaryAction: () => handleAddPendingPayments(),
              secondaryActionButtonIsDisabled: !selectedPaymentsIds?.length,
            },
          ]}
        />
      ) : null}
    </>
  );
}

export default memo(AddPendingPaymentDialog);
