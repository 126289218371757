import { gridPageSize } from '../../../utils';

export const getTractorDefaultRequestData = {
  pageNumber: 1,
  pageSize: gridPageSize,
  linkedFilter: 'ALL',
  tractorIds: [],
};

export const getDriverDefaultRequestData = {
  pageNumber: 1,
  pageSize: gridPageSize,
  linkedFilter: 'ALL',
  driverIds: [],
};
