// import { GridButtonGroupFilter } from '../../../ui-kit/components/DataGridPro/components/Filters/GridButtonGroupFilter';
import { IDispatchOption } from '../../../types/DispatchTypes';
import { IAutocompleteOption } from '../../trips/constants/types';
import { GridButtonGroupFilter } from '../components/generic/GridButtonGroupFilter';
import {
  RecommendedTripSortFilters,
  defaultRecommendedTripFilters,
} from './recommendedTrips.filters';

export enum EPossibleResourceStatus {
  ShowRejected = 'ShowRejected',
}

export interface IPossibleResourceFilters {
  statuses: IAutocompleteOption[];
  noOfResult: IDispatchOption;
  sort?: IDispatchOption;
  sortDirection?: boolean;
}

const defaultAutocompleteConfig = {
  fieldName: 'value',
  fieldValue: 'key',
  immutableModel: true,
};

export const getNoOfResultsOptions = (): IDispatchOption[] => {
  return [
    { key: 'top10', value: 'Top 10' },
    { key: 'top20', value: 'Top 20' },
    { key: 'all', value: 'All Possible' },
  ];
};

export const getPossibleResourceFilters = () => {
  return [
    ...RecommendedTripSortFilters,
    {
      default: true,
      name: 'noOfResult',
      options: getNoOfResultsOptions(),
      label: '# of Results',
      type: 'SingleAutocomplete',
      disableClearable: true,
      ...defaultAutocompleteConfig,
      customStyles: {
        width: '147px',
      },
    },
    {
      default: true,
      label: '',
      name: 'statuses',
      type: 'Custom',
      options: [
        {
          value: EPossibleResourceStatus.ShowRejected,
          label: 'Show Rejected',
        },
      ],
      customComponent: GridButtonGroupFilter,
    },
  ];
};

export const defaultPossibleResourceFilters: IPossibleResourceFilters = {
  sort: defaultRecommendedTripFilters.sort,
  sortDirection: defaultRecommendedTripFilters.sortDirection,
  statuses: [],
  noOfResult: getNoOfResultsOptions().find(({ key }) => key === 'top20')!,
};
