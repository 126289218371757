import { useMemo } from 'react';
import StorageManager from '../../StorageManager/StorageManager';
import LongMenu from '../../common/LongMenu';
import { AXELE_PERMISSION_TYPE, Permission } from '../../common/Permission';
import { useEquipment } from '../../contexts/EquipmentContext';
import { AssetsCount } from '../../models';
import { DeleteButtonIcon } from '../../ui-kit/components/Assets';
import DeletePopup from '../../ui-kit/components/DeletePopup';
import { getAccountTypeWithRoleCode, isHasPermission } from '../../utils';
import { deleteTractorAndTrailerDocs } from '../shared/equipment';
import TractorController from './Controller';
import { Tractor } from './models/Tractor';
import { t } from 'i18next';
type Status = 'AVAILABLE' | 'UNAVAILABLE' | 'ASSIGNED';

interface ActionsSectionProps {
  tractorData: Tractor;
  onDeleted: (data: unknown) => void;
  deactivateTractor: () => void;
  onUnassign: (driverGroupId: string) => any;
  onStatusChange: (tractorId: number, status: string) => Promise<boolean>;
  openDialog: 'markAvailable' | 'markUnavailable' | 'delete' | '';
  setOpenDialog: React.Dispatch<
    React.SetStateAction<
      | ''
      | 'markAvailable'
      | 'markUnavailable'
      | 'inactive'
      | 'inactiveFail'
      | 'delete'
    >
  >;
  showPopup?: { allow?: boolean; body?: string } | null;
  setShowPopup: React.Dispatch<
    React.SetStateAction<
      | {
          allow?: boolean | undefined;
          body?: string | undefined;
        }
      | null
      | undefined
    >
  >;
}

interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}

const showPopupConfigs = (message: string) => ({
  allowedDelete: {
    allow: true,
    body: t('deleteTractorConfirmation'),
  },
  notAllowedDelete: {
    body: `${message}.`,
  },
  notAllowedMarkInactive: {
    body: `${message}.`,
  },
});

export function ActionsSection({
  tractorData,
  onDeleted,
  onStatusChange,
  onUnassign,
  openDialog,
  setOpenDialog,
  showPopup,
  setShowPopup,
  deactivateTractor,
}: ActionsSectionProps) {
  const { setEquipmentCount } = useEquipment();

  const [id, status] = [tractorData.id, tractorData.status];
  const driverGroupId = tractorData.driverGroup?.id;

  const storageUserData = StorageManager.getUser();
  const noPermissionForDelete = !isHasPermission([
    AXELE_PERMISSION_TYPE.EQUIPMENT_REMOVE,
  ]);

  const options = useMemo(() => {
    const mainActions: Action[] = [];

    const markUnavailable = {
      name: 'Mark as Unavailable',
      action: () => {
        setOpenDialog('markUnavailable');
        setShowPopup({ allow: true });
      },
    };

    const markAvailable = {
      name: 'Mark as Available',
      action: () => {
        setOpenDialog('markAvailable');
        setShowPopup({ allow: true });
      },
    };

    const deleteAction = {
      customStyles: { paddingRight: '5px' },
      name: 'Delete Tractor',
      action: () => {
        handleDeleteClick();
        setOpenDialog('delete');
      },
      startIcon: DeleteButtonIcon({}),
    };

    const markAsInactive = {
      customStyles: { paddingRight: '5px' },
      name: 'Mark as Inactive',
      action: () => {
        setOpenDialog('inactive');
        setShowPopup({ allow: true });
      },
    };

    if (status === 'AVAILABLE') {
      mainActions.push(markUnavailable);
      mainActions.push(markAsInactive);
      !noPermissionForDelete && mainActions.push(deleteAction);
    } else if (status === 'UNAVAILABLE') {
      mainActions.push(markAvailable);
      mainActions.push(markAsInactive);
      !noPermissionForDelete && mainActions.push(deleteAction);
    } else if (status === 'INACTIVE') {
      mainActions.push(markAvailable);
    } else {
      mainActions.push(markAvailable);
    }

    return mainActions;
  }, [status]);

  const handleStatusAction = async () => {
    let statusAction: Status;
    if (status === 'AVAILABLE') {
      statusAction = 'UNAVAILABLE';
    } else {
      statusAction = 'AVAILABLE';
    }
    let response;
    let unAssignResponse;
    if (status === 'ASSIGNED') {
      unAssignResponse = await onUnassign(driverGroupId);
    } else {
      response = await onStatusChange(id, statusAction);
    }

    if (response || unAssignResponse) {
      setOpenDialog('');
      setShowPopup(null);
    }
  };

  function handleDeleteClick() {
    TractorController.instance().verifyTractorDeletion(id, (canDelete) => {
      if (canDelete.assetIsAllowedToDelete) {
        setShowPopup(showPopupConfigs(canDelete.message).allowedDelete);
      } else {
        setShowPopup(showPopupConfigs(canDelete.message).notAllowedDelete);
      }
    });
  }

  function handleMarkAsInactive() {
    TractorController.instance().markAsInactive(id, (canDelete) => {
      if (!canDelete) {
        setOpenDialog('');
        setShowPopup(null);
        setOpenDialog('inactiveFail');
        setShowPopup(showPopupConfigs('inactiveFail').notAllowedMarkInactive);
      } else {
        setOpenDialog('');
        setShowPopup(null);
        deactivateTractor();
      }
    });
  }

  function handleDelete() {
    if (showPopup?.allow) {
      TractorController.instance().deleteTractorById(id, (res) => {
        deleteTractorAndTrailerDocs(tractorData);
        if (res === null) {
          setShowPopup(null);
          onDeleted(id);
          setEquipmentCount({
            ...TractorController.instance().equipmentCount,
          } as AssetsCount);
        }
      });
    } else {
      setShowPopup(null);
    }
  }

  const dialogConfigs = useMemo(() => {
    return {
      markUnavailable: {
        title: t('markUnavailable'),
        backgroundColor: 'primary.main',
        body: t('markTractorUnavailableConfirmation'),
        subtitle: undefined,
        width: '446px',
        buttonText: t('yes'),
        buttonTextColor: 'primary.main',
        onAction: handleStatusAction,
        onClose: () => setOpenDialog(''),
        cancelText: undefined,
      },
      markAvailable: {
        title: t('markAsAvailable'),
        backgroundColor: 'primary.main',
        body: t('markTractorAsAvailable'),
        subtitle: status === 'ASSIGNED' ? t('markAvailableNote') : undefined,
        width: '446px',
        buttonText: t('yes'),
        buttonTextColor: 'primary.main',
        onAction: handleStatusAction,
        onClose: () => setOpenDialog(''),
        cancelText: undefined,
      },
      inactive: {
        title: 'Deactivate',
        backgroundColor: 'primary.main',
        subtitle:
          'Are you sure you want to deactivate the tractor? Please note that the tractor will be non-visible for making assignments.',
        width: '446px',
        buttonText: 'Deactivate',
        buttonTextColor: 'primary.main',
        onAction: handleMarkAsInactive,
        onClose: () => setOpenDialog(''),
        cancelText: undefined,
      },
      inactiveFail: {
        title: t('warning'),
        backgroundColor: 'primary.main',
        subtitle:
          'You cannot deactivate the tractor as there are non-completed assignments',
        width: '446px',
        onAction: undefined,
        onClose: () => {
          setOpenDialog('');
          setShowPopup(null);
        },
        cancelText: 'Ok',
      },
      delete: {
        title: t('deleteTractor'),
        backgroundColor: undefined,
        buttonText: t('yes'),
        body: showPopup?.body,
        subtitle: undefined,
        width: '282px',
        onAction: showPopup?.allow ? handleDelete : undefined,
        onClose: () => {
          setOpenDialog('');
          setShowPopup(null);
        },
        cancelText: showPopup?.allow ? 'Cancel' : 'Ok',
      },
    };
  }, [setOpenDialog, showPopup]);

  return (
    <>
      <LongMenu color={'primary.contrast'} options={options} />
      {/* <Permission contains={[AXELE_PERMISSION_TYPE.EQUIPMENT_REMOVE]}>
      <LongMenu color={'primary.contrast'} options={options} />
      </Permission> */}
      {openDialog && showPopup && (
        <DeletePopup
          title={dialogConfigs[openDialog].title}
          backgroundColor={dialogConfigs[openDialog].backgroundColor}
          body={dialogConfigs[openDialog].body}
          subtitle={dialogConfigs[openDialog].subtitle}
          width={dialogConfigs[openDialog].width}
          buttonText={dialogConfigs[openDialog].buttonText}
          buttonTextColor={dialogConfigs[openDialog]?.buttonTextColor}
          onAction={dialogConfigs[openDialog].onAction}
          onClose={dialogConfigs[openDialog].onClose}
          cancelText={dialogConfigs[openDialog].cancelText}
          open
        />
      )}
    </>
  );
}
