export const getSuccessMsg = {
  loadCreation: (loadSeqNumber: number) =>
    `Successfully created the load ${loadSeqNumber}`,
  templateCreation: (templateName: string) =>
    `Successfully created the template ${templateName}`,
  templateEdit: (templateName: string) =>
    `Successfully updated the template ${templateName}`,
  templateSelection: (templateName: string) =>
    `Successfully selected ${templateName} template`,
  rateConDocumentUpload: (documentName: string) =>
    `Successfully uploaded a ratecon document ${documentName}`,
  locationsCreate: (locationNames: string) =>
    `Successfully created the location ${locationNames}`,
  assignLSLoadToDriver: (loadSeqNumber: number, driverName: string) =>
    `Load ${loadSeqNumber} successfully planned  to driver ${driverName}`,
};

export const getFailureMsg = {
  templateSelection: (templateName: string) =>
    `Error using ${templateName} template, please select another`,
  locationsCreate: (locationNames: string) =>
    `Error creating the location ${locationNames}`,
  assignLSLoadToDriver: (driverName: string) =>
    `Error planning the driver ${driverName} to the load`,
};

export const SuccessMsg = {
  homeService: 'Application Successfully updated',
  generalSuccessMsg: 'operation was successfull',
  loginSuccess: 'Logged In Successfully',
  activateQBProvider: 'Account Added Successfully',
  updateQBAuth: 'Authenticated Successfully',
  deleteQBProvider: 'Account Deleted Successfully',
  updateQBProvider: 'Account Updated Successfully',
  updateQBItemCategory: 'Item Category is updated',
  savePayItemCategory: 'Item saved successfully',
  fetchQBOCustomers: 'Customers Fetched Successfully',
  sendQBOCustomers: 'Customers sent to QuickBooks',
  connectToQBO: 'Session established with QuickBooks',
  suppressInvoices: 'Invoice(s) suppressed successfully',
  releaseInvoices: 'Invoice(s) released successfully',
  syncBackQBInvoices: 'Invoice synced Back Successfully',
  syncQBInvoices: 'Invoice synced Successfully',
  postCompanyLogo: 'Logo uploaded Successfully',
  datCreation: 'Credentials has been requested successfully',
  activationLogin: 'Integration activation successfull',
  editIntegration: 'Integration changes has been successfully saved',
  deactivate: 'Integration deactivated successfully',
  deleteBroker: 'Broker deleted successfully',
  deactivateFactoring: 'Account has been deleted successfully',
  deleteItem: 'Item deleted successfully',
  addMaintenanceHistory: 'Record Added Successfully',
  updateMaintenanceHistory: 'Record Updated Successfully',
  deleteMaintenanceHistory: 'Record(s) Deleted Successfully',
  deleteTerminal: 'Terminal Deleted Successfully',
  createTerminal: 'Terminal Created Successfully',
  updateTerminal: 'Terminal Updated Successfully',
  reset: 'Your password reset successfully',
  deleteCancellation: 'Cancellation deleted successfully',
  deletePayment: 'Payment method deleted successfully',
  primaryPayment: 'Primary payment method changed successfully',
  tractorLink: 'Tractor(s) linked successfully',
  driverLink: 'Driver(s) linked successfully',
  tractorUnLink: 'Tractor(s) unlinked successfully',
  driverUnLink: 'Driver(s) unlinked successfully',
  tractorCreation: 'Tractor was created successfully',
  tractorUpdate: 'Tractor was updated successfully',
  tractorDelete: 'Tractor was deleted successfully',
  tractorAlertCreate: 'Tractor was created alert successfully',
  tractorAlertUpdate: 'Tractor alert was updated successfully',
  tractorAlertDelete: 'Tractor alert  was deleted successfully',
  tractorDriverAssign: 'Tractor was assigned successfully',
  tractorDriverUnAssign: 'Tractor was unassigned successfully',
  tractorStatusChange: 'Tractor status change successfully',
  trailerCreation: 'Trailer was created successfully',
  trailerUpdate: 'Trailer was updated successfully',
  trailerDelete: 'Trailer was deleted successfully',
  trailerAlertCreate: 'Trailer was created alert successfully',
  trailerAlertUpdate: 'Trailer alert was updated successfully',
  trailerAlertDelete: 'Trailer alert  was deleted successfully',
  trailerDriverAssign: 'Trailer was assigned successfully',
  trailerDriverUnAssign: 'Trailer was unassigned successfully',
  trailerStatusChange: 'Trailer status change successfully',
  multipleTractorCreation: 'Tractors created successfully',
  driverCreation: 'Driver created successfully',
  multipleDriverCreation: 'Drivers created successfully',
  accountDelete: 'Account deleted successfully',
  dataRefresh: 'Data refreshed successfully',
  financeOverviewAdd: 'Line Item Added Successfully',
  financeOverviewUpdate: 'Line Item Updated Successfully',
  financeOverviewDelete: 'Line Item deleted successfully',
  paymentPerLoadAdd: 'Payment Per Load Added Successfully',
  paymentPerLoadUpdate: 'Payment Per Load Updated Successfully',
  paymentPerLoadDelete: 'Payment Per Load deleted successfully',
  scheduledPaymentAdd: 'Scheduled Payment Added Successfully',
  scheduledPaymentUpdate: 'Scheduled Payment Updated Successfully',
  scheduledPaymentDelete: 'Scheduled Payment deleted successfully',
  deleteNotesData: 'Note Deleted Successfully',
  createNotesData: 'Note Created Successfully',
  editNotesData: 'Note Updated Successfully',
  createTrackerData: 'Record Created Successfully',
  deleteTrackerData: 'Record Deleted Successfully',
  updateTrackerData: 'Record Updated Successfully',
  deleteTerminalLogo: 'Logo Deleted Successfully',
  editLoadAlert: 'Alert Updated Successfully',
  createLoadAlert: 'Alert Created Successfully',
  settingAlertDelete: 'Alert Deleted Successfully',
  customerListRefresh: 'Customer list has been refreshed',
  updateMapCustomer: 'List has been updated',
  archived: 'Account has been successfully archived',
  driverTractorAssign: 'Assign tractor successfully',
  driverTrailerAssign: 'Assign trailer successfully',
  driverTractorUnAssign: 'Unassgin tractor successfully',
  driverTrailerUnAssign: 'Unassgin trailer successfully',
  updatePreferenceDriver: 'Preference updated successfully',
  SavedFactoring: 'Data saved successfully',
  unArchived: 'Account has been successfully restored',
  updateManualHosLocation: 'Location/HoS Updated Successfully',
  teamGroupCreation: 'Team Created Successfully',
  teamGroupDelete: 'Record Deleted Successfully',
  teamGroupUpdate: 'Record Updated Successfully',
  createContact: 'Contact was created successfully',
  customerCreate: 'Customer was created successfully',
  customerUpdate: 'Customer was updated successfully',
  customerDelete: 'Customer was deleted successfully',
  vendorCreate: 'Vendor was created successfully',
  vendorUpdate: 'Vendor was updated successfully',
  vendorDelete: 'Vendor was deleted successfully',
  locationCreate: 'Location was created successfully',
  expenseFromEFSCreate: 'Expenses created successfully',
  locationUpdate: 'Location was updated successfully',
  locationDelete: 'Location was deleted successfully',
  userCreate: 'User was created successfully',
  userUpdate: 'User was updated successfully',
  userDelete: 'User was deleted successfully',
  userAlertCreate: 'User was created alert successfully',
  userAlertUpdate: 'User alert was updated successfully',
  userAlertDelete: 'User alert  was deleted successfully',
  userStatusChange: 'User status was change successfully',
  userInvitationStatus: 'User invitation sent successfully',
  payStatementNonTripCreate: 'Payment was created successfully',
  payStatementNonTripUpdate: 'Payment was updated successfully',
  payStatementNonTripDelete: 'Payment was deleted successfully',
  payStatementInReviewCreate: 'Settlement was moved to in review successfully',
  payStatementInReviewClose: 'Settlement was closed successfully',
  payStatementReopen: 'Settlement was reopened successfully.',
  payStatementInReviewDelete: 'Settlement was deleted successfully',
  payStatementEmailSend: 'Email was sent successfully',
  templateCreation: 'Successfully created new template.',
  templateDelete: 'Load template was deleted successfully',
  expenseCreate: 'Expense was created successfully',
  expenseUpdate: 'Expense was updated successfully',
  expenseDelete: 'Expense was deleted successfully',
  documentCreate: 'Document was created successfully',
  documentDelete: 'Document was deleted successfully',
  documentShare: 'You have successfully shared a document',
  importFile: 'File was imported successfully',
  invoiceUpdate: 'Invoice was updated successfully',
  invoiceEmailSend: 'Email was sent successfully',
  invoiceExport: 'Data was exported successfully',
  invoiceStatusChange: 'Invoice status was changed successfully',
  invoiceDownload: 'Invoice was downloaded successfully',
  tripAssignDriver: 'Successfully assigned driver',
  createFuelMatrix: 'Matrix created successfully',
  updateFuelMatrix: 'Matrix updated successfully',
  tripAssignCarrier: 'Successfully assigned carrier',
  tripUnAssignDriver: 'Successfully unassigned driver',
  tripUnAssignCarrier: 'Successfully unassigned carrier',
  tripAssignTractor: 'Successfully assigned tractor',
  tripUnAssignTractor: 'Successfully unassigned tractor',
  tripAssignTrailer: 'Successfully assigned trailer',
  tripUnAssignTrailer: 'Successfully unassigned trailer',
  tripAssignDispatcher: 'Successfully assigned dispatcher',
  tripUnAssignDispatcher: 'Successfully unassigned dispatcher',
  tripAssignTerminal: 'Successfully assigned new terminal',
  regenerateTimeline: 'Timeline was regenerated successfully',
  tripResequience: 'Stop is being successfully re-sequenced',
  updateLoadReferenceDetails: 'Load was updated successfully',
  updateTripDispatchRevenue: 'Trip was updated successfully',
  invoiceSentToProvider: 'Invoice sent to EDI successfully',
  invoiceSentToTurvo: 'Invoice sent to Turvo successfully',
  invoiceSentToBitf: 'Invoice sent to Bitfreighter successfully',
  tenderAccepted: 'Tender accepted successfully',
  tenderDeclined: 'Tender declined successfully',
  optymizationAssignmentReject: 'Assignment successfully rejected',
  optymizationAssignmentUnReject: 'Assignment successfully unrejected',
  optymizationAssignmentLock: 'Assignment successfully locked',
  optymizationAssignmentUnlock: 'Assignmnet successfully unlocked',
  enginePreferenceSaved: 'Successfully saved preferences',
  engineOptimizationRestart: 'Successfully updated optimization',
  engineReoptimizationSuccess: 'Successfully completed reoptimization',
  createCarrier: 'Carrier was created successfully',
  updateCarrier: 'Carrier was updated successfully',
  updateCarrierStatus: 'Carrier status was updated successfully',
  deleteCarrier: 'Carrier was deleted successfully',
  updateLoadCarrierExpense: 'Carrier payment was updated successfully',
  deleteCarrierPayment: 'Carrier payment was deleted successfully',
  manifestLoadRemove: '',
  activateAccount: 'Account activated successfully',
  updateRole: 'Role Successfully updated',
  deleteRole: 'Role has been deleted Successfully.',
  holdStateLoad: 'Hold Load successfully',
  releaseStateLoad: 'Release Load successfully',
  holdStateManifest: 'Hold Manifest successfully',
  releaseStateManifest: 'Release Manifest successfully',
  createPaymentManagement: 'Payment added successfully',
  updatePaymentManagement: 'Payment updated successfully',
  deletePaymentManagement: 'Payment deleted successfully',
  settlementsBulkDownload: 'Settlement report downloaded successfuly',
  settlementCreated: 'Settlement created successfully',
  revertToInReview: 'Settlement was reverted to in review successfully',
  revertToReviewed: 'Settlement was reverted to reviewed successfully',
  markAsInReview: 'Settlement was marked as in review successfully',
  markAsReviewed: 'Settlement was marked as reviewed successfully',
  financeExpenseCreated: 'Finance Expense created successfully',
  deleteFinanceExpense: 'Finance Expense delete successfully',
  createLoad: 'Load created successfully!',
  templateEdit: 'Successfully updated template.',
  createEmptyTrip: 'Successfully created Empty trip.',
};

export const FailureMsg = {
  homeService: 'Application Failed Please retry',
  generalFailureMsg: 'operation failed please try again',
  unauthorized: 'Unauthorized Request Please login',
  activateQBProvider: 'Sorry Please try again',
  updateQBAuth: 'Authentication Failed. Please Try again',
  deleteQBProvider: 'Sorry Please try again',
  updateQBProvider: 'Sorry Please try again',
  updateQBItemCategory: 'Sorry Please try again',
  savePayItemCategory: 'Sorry Please try again',
  expenseFromEFSCreate: 'Sorry Please try again',
  expenseEFSPull: 'Sorry Please try again',
  fetchQBOCustomers: 'Sorry Please try again',
  sendQBOCustomers: 'Sorry Please try again',
  connectToQBO: 'Session should be connected',
  suppressInvoices: 'Failed to suppress invoice(s)',
  releaseInvoices: 'Failed to release invoice(s)',
  syncBackQBInvoices: 'Sorry Please try again',
  syncQBInvoices: 'Sorry Please try again',
  postCompanyLogo: 'Sorry Please upload logo again',
  providersList: 'Failed to load the providers',
  datCreation: 'Failed to send email request for credentials',
  activationLogin: 'Sorry, failed to integrate, Please try again',
  editIntegration: 'Sorry, failed to edit integration, Please try again',
  deactivate: 'Sorry, failed to deactivate, Please try again',
  deleteBroker: 'Failed to deleted Broker account',
  prefilledData: 'Sorry, failed to get prefilled data',
  getUserDetails: 'Sorry, Failed to get user details',
  failedData: 'Failed to fetch data',
  getSubscription: 'Failed to fetch subscription data',
  download: 'Failed to download data',
  templateEdit: 'Failed to update template',
  templateDelete: 'Failed to delete the load template',
  addMaintenanceHistory: 'Add Record Failed, Please try again',
  updateMaintenanceHistory: 'Update Record Failed, Please try again',
  deleteMaintenanceHistory: 'Delete Record(s) Failed, Please try again',
  duplicateCompany:
    'Similar company is already registered. Please check data and try again, or contact LoadOps Support․',
  registration: 'Failed to register company',
  login:
    'Login credentials provided are not valid. Please check the credentials and try again.',
  forgotPassword:
    'There is no active user found with provided credentials. Please check data and try again.',
  reset: 'Your password reset link has expired.',
  cancelSubscription:
    'Failed to cancel subscription. Please contact support team to raise a query.',
  token: 'Something went wrong',
  deletePayment: 'Delete payment method failed',
  settingAlertDelete:
    'We cannot delete an Alert from the Alert page until all the specific alerts set for an entity are removed.',
  deleteTerminal:
    'Terminal cannot be deleted. It has associations with assets, loads, load templates, expenses, and/or users.',
  createTerminal: 'Sorry Please try again',
  updateTerminal: 'Sorry Please try again',
  templateCreation: 'Error creating template, please try again',
  loadCreation: 'Error creating load, please try again',
  rateConDocumentUpload: 'Error uploading ratecon document',
  tractorLink: 'Could not link tractor(s)',
  driverLink: 'Could not link driver(s)',
  axeleTractors: 'Failed to load LoadOps tractors',
  axeleDrivers: 'Failed to load LoadOps drivers',
  eldTractors: 'Failed to load tractors',
  eldDrivers: 'Failed to load drivers',
  tractorUnLink: 'Failed to unlink tractors(s)',
  driverUnLink: 'Failed to unlink driver(s)',
  tractorCreation: 'Failed to create tractor',
  tractorUpdate: 'Failed to update tractor',
  tractorDelete: 'Failed to delete tractor',
  tractorAlertCreate: 'Failed to create tractor alert',
  tractorAlertUpdate: 'Failed to update tractor alert',
  tractorAlertDelete: 'Failed to delete tractor alert',
  tractorDriverAssign: 'Failed to assign tractor to driver',
  tractorDriverUnAssign: 'Failed to unassign tractor to driver',
  tractorStatusChange: 'Failed to change tractor status',
  trailerCreation: 'Failed to create trailer',
  trailerUpdate: 'Failed to update trailer',
  trailerDelete: 'Failed to delete trailer',
  trailerAlertCreate: 'Failed to create trailer alert',
  trailerAlertUpdate: 'Failed to update trailer alert',
  trailerAlertDelete: 'Failed to delete trailer alert',
  trailerDriverAssign: 'Failed to assign trailer to driver',
  trailerDriverUnAssign: 'Failed to unassign trailer to driver',
  trailerStatusChange: 'Failed to change trailer status',
  multipleTractorCreation: 'Failed to create tractors',
  driverCreation: 'Failed to create driver',
  multipleDriverCreation: 'Failed to create drivers',
  activateProvider: 'Failed to activate',
  accountDelete: 'Failed to delete the account',
  dataRefresh: 'Failed to refresh the data',
  update: 'Failed to update',
  validation: 'Failed to validate',
  financeOverviewGeneral: 'Sorry Please try again',
  paymentPerLoadGeneral: 'Sorry Please try again',
  scheduledPaymentGeneral: 'Sorry Please try again',
  deleteNotesData: 'Failed to Delete Note, Please try again',
  createNotesData: 'Failed to Create Note, Please try again',
  editNotesData: 'Failed to Update Note, Please try again',
  createTrackerData: 'Failed to Create Record, Please try again',
  deleteTrackerData: 'Failed to Delete Record, Please try again',
  updateTrackerData: 'Failed to Update Record, Please try again',
  deleteTerminalLogo: 'Failed to Delete logo, Please try again',
  editLoadAlert: 'Failed to Update Alert, Please try again',
  createLoadAlert: 'Failed to Create Alert, Please try again',
  customerListRefresh: 'Failed to refresh customer refresh',
  updateMapCustomer: 'Failed to update list',
  invalidQBIntegration:
    'The integration with QuickBooks is not valid. Please contact your company admin.',
  inactiveQBIntegration:
    'The integration with QuickBooks is not active. Please contact your company admin.',
  unmappedQBIntegration:
    'Item categories are not mapped properly. Please map the item categories first before viewing the invoice tab.',
  driverTractorAssign: 'Failed to make tractor assignment, Please try again',
  driverTrailerAssign: 'Failed to make trailer assignment, Please try again',
  driverTractorUnAssign:
    'Failed to make tractor unassignment, Please try again',
  driverTrailerUnAssign:
    'Failed to make trailer unassignment, Please try again',
  archived: 'Sorry, Failed to archive, Please try again',
  unArchived: 'Sorry, Failed to restore, Please try again',
  updatePreferenceDriver: 'Failed to update preference, Please try again',
  duplicateTerminal: 'The Terminal Name is already used',
  closedSettelementforlinkedExpense: `Cannot delete maintenance record(s) as linked expense(s)/settlement(s) status is closed.Please reopen the settlement before deleting the maintenance record`,
  duplicateEmail: 'Email address is already used.',
  updateUser: 'Failed to update User, Please try again',
  failedGetTimelineTripDispatch:
    'Failed to get timeline trip data, Please try again',
  failedGetTimelineMapDispatch:
    'Failed to get timeline map data, Please try again',
  SavedFactoring: 'Sorry, Failed to save data',
  updateManualHosLocation: 'Failed to update Location/HoS, Please try again',
  customersNonSynced: 'Some of the customers were not synced',
  diabledTerminalValidation:
    'You cannot disable the terminal model until you delete all Secondary Terminals',
  linkDriver: 'Sorry, Failed to link, please try later',
  createContact: 'Failed to create contact',
  customerCreate: 'Failed to create customer',
  customerUpdate: 'Failed to update customer',
  customerDelete: 'Failed to delete customer',
  vendorCreate: 'Failed to create vendor',
  vendorUpdate: 'Failed to update vendor',
  vendorDelete: 'Failed to delete vendor',
  locationCreate: 'Failed to create location',
  locationUpdate: 'Failed to update location',
  locationDelete: 'Failed to delete location',
  userCreate: 'Failed to create user',
  userUpdate: 'Failed to update user',
  userDelete: 'Failed to delete user',
  userAlertCreate: 'Failed to create user alert',
  userAlertUpdate: 'Failed to update user alert',
  userAlertDelete: 'Failed to delete user alert',
  userStatusChange: 'Failed to change user status',
  userInvitationStatus: 'Failed to send user invitation',
  payStatementNonTripCreate: 'Failed to create payment',
  payStatementNonTripUpdate: 'Failed to update payment',
  payStatementNonTripDelete: 'Failed to delete payment',
  payStatementInReviewCreate: 'Failed to create settlement',
  payStatementInReviewClose: 'Failed to close settlement',
  payStatementReopen: 'Failed to reopen settlement.',
  payStatementInReviewDelete: 'Failed to delete settlement',
  payStatementEmailSend: 'Failed to send email',
  expenseCreate: 'Failed to create expense',
  expenseUpdate: 'Failed to update expense',
  expenseDelete: 'Failed to delete expense',
  documentCreate: 'Failed to create document',
  documentDelete: 'Failed to delete document',
  documentShare: 'Failed to share document',
  importFile: 'Failed to import file',
  invoiceUpdate: 'Failed to update invoice',
  invoiceEmailSend: 'Failed to send email',
  invoiceExport: 'Failed to export data',
  invoiceStatusChange: 'Failed to change invoice status',
  invoiceDownload: 'Failed to download invoice',
  tripAssignDriver: 'Failed to assign driver',
  createFuelMatrix: 'Failed to create matrix',
  updateFuelMatrix: 'Failed to update matrix',
  tripAssignCarrier: 'Failed to assign carrier',
  tripUnAssignDriver: 'Failed to unassign driver',
  tripUnAssignCarrier: 'Failed to unassign carrier',
  tripAssignTractor: 'Failed to assign tractor',
  tripUnAssignTractor: 'Failed to unassign tractor',
  tripAssignTrailer: 'Failed to assign trailer',
  tripUnAssignTrailer: 'Failed to unassign trailer',
  tripAssignDispatcher: 'Failed to assign dispatcher',
  tripUnAssignDispatcher: 'Failed to unassign dispatcher',
  tripAssignTerminal: 'Failed to assign new terminal',
  importIssue: 'The file cannot be uploaded at the moment. Please try again',
  regenerateTimeline: 'Failed to regenerate timeline',
  tripResequience: 'Failed to re-sequence the stop',
  updateLoadReferenceDetails: 'Error updating the load',
  updateTripDispatchRevenue: 'Error updating the trip',
  tractorVINMissing:
    'Tractor VIN is missing. Please create the tractor individually.',
  invoiceFactoringAction: 'Something went wrong. Please try later',
  changeStatusBulkSelectUnassignedTrips: 'Failed to change trip status',
  logAuditFailed: 'Failed to load the log audit',
  invoiceSentToProvider: 'Failed to send the invoice',
  tenderAccepted: 'Sorry Please try again',
  tenderDeclined: 'Sorry Please try again',
  engineOptimizationRestartFailed: 'Failed to update optimization',
  engineSaveAndExitFailure: 'Failed to Save and Exit optimization',
  engineAssignmentLockFailure: 'Failed to lock assignment',
  engineAssignmnetUnlockFailure: 'Failed to unlock assignment',
  engineAssignmentRejectFailure: 'Failed to reject assignment',
  engineAssignmentUnrejectFailue: 'Failed to unreject assignment',
  engineSaveWithNoLocks:
    'No Locked Assignments were selected so Save and Exit Operation was discarded',
  engineValidateResult: 'Failed to validate the sandbox',
  engineReoptimizationFailure: 'Failed to re-optimize',
  engineFetchPossibleResourceFailure: 'Failed to fetch possible records',
  enginePreferenceFailure: 'Failed to update preferences',
  createCarrier: 'Failed to create the carrier',
  updateCarrier: 'Failed to update the carrier',
  updateCarrierStatus: 'Failed to update status the carrier',
  deleteCarrier: 'Failed to delete the carrier',
  updateLoadCarrierExpense: 'Failed to updated carrier payment',
  deleteCarrierPayment: 'Failed to delete carrier payment',
  manifestLoadRemove: 'Sorry Please try again',
  engineEvaluationFailure: 'Evaluation failed! Please try again later.',
  enginePreferenceResetFailure: 'Failed to reset preferences!',
  activateAccount: 'Failed to activate the  account',
  createContractErr: 'Failed to create contract',
  deleteRole:
    'Users are associated with this role. Please change role and try again',
  holdStateLoad: 'Failed to Hold Load',
  releaseStateLoad: 'Failed to Release Load',
  holdStateManifest: 'Failed to Hold Manifest',
  releaseStateManifest: 'Failed to Release Manifest',
  settlementsBulkDownload: 'Failed to download settlement report',
  settlementCreated: 'Failed to create settlement',
  revertToInReview: 'Failed to revert settlement to in review',
  revertToReviewed: 'Failed to revert settlement to reviewed',
  markAsInReview: 'Failed to mark settlement as in review',
  markAsReviewed: 'Failed to mark settlement as reviewed',
  financeExpenseCreated: 'Failed to create finance expense',
  deleteFinanceExpense: 'Failed to delete expense',
  createLoad: 'Failed to create load.',
  commodityLengthValidation:
    'Commodity type cannot be more than 100 characters.',
  uploadRateconFile: 'Failed to upload Ratecon file.',
  missingCoordinates:
    'Failed to update location stop details due to missing coordinates.',
  loadOverDetailsUpdate:
    'Failed to update overview details. Please try again later!',
  createEmptyTrip: 'Failed to create Empty trip.',
};

export const getNotificationMessage = (
  type: string,
  serviceName: string,
  message?: string
) => {
  if (type === 'SUCCESS') {
    return message || SuccessMsg[serviceName as keyof typeof SuccessMsg];
  } else {
    return message || FailureMsg[serviceName as keyof typeof FailureMsg];
  }
};

export interface GlobalNotificationType {
  type?: string;
  serviceName?: string;
  message?: string;
}
