import { Fragment } from 'react';
import {
  EDITenderDTO,
  LoadMappingResponse,
} from '../../../../models/DTOs/EDI/Requests';
import { EDITenderDetails } from '../../EDITenderDetails/EDITenderDetails';
import { EdiTenderTripPlan } from '../../EDITenderTripPlan/EDITenderTripPlan';
import { EEDITenderPanelTabs } from '../../constants/detailsPanel';

export interface EEDITenderDetailsFormProps {
  selectedTab: EEDITenderPanelTabs;
  closeDetailsPanel?: () => void;
  tenderDTO: EDITenderDTO;
  tripPlanData: LoadMappingResponse | null;
}
export default function EEDITenderDetailsForm({
  selectedTab,
  tenderDTO,
  tripPlanData,
}: EEDITenderDetailsFormProps) {
  return (
    <Fragment>
      {selectedTab === EEDITenderPanelTabs.TRIP_PLAN && (
        <EdiTenderTripPlan tenderDTO={tenderDTO} tripPlanData={tripPlanData} />
      )}
      {selectedTab === EEDITenderPanelTabs.DETAILS && (
        <EDITenderDetails tenderDTO={tenderDTO} tripPlanData={tripPlanData} />
      )}
    </Fragment>
  );
}
