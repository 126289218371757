import { Box, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadConstants } from '../../../locales/en/allLoads/loads';
import { InvoicesConstants } from '../../../locales/en/finance/invoices';
import { NOTE_TYPE } from '../../../models';
import { getNotesListByLoadId } from '../tabs/Notes/constants/helper';
const styles = {
  root: {
    p: 2,
    borderWidth: '0 1px 1px 1px',
    borderRadius: '0px 0px 8px 8px',
    borderColor: 'other.warningBorderColor',
    backgroundColor: 'other.warningBackgroundColor',
  },
};
export const OnHoldLoadWarning = ({ loadId }: { loadId: string }) => {
  const [noteDesc, setNoteDesc] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const init = async () => {
    try {
      setIsLoading(true);
      const getRes = await getNotesListByLoadId(loadId);
      const reason = getRes?.find(
        ({ notesType }) => notesType === NOTE_TYPE.ONHOLD
      );
      if (reason && reason.noteDesc) {
        setNoteDesc(reason.noteDesc);
      } else {
        setNoteDesc('');
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, [loadId]);

  if (!loadId) return <></>;

  if (isLoading)
    return <Skeleton variant="rectangular" width={500} height={50} />;

  return (
    <Box sx={styles.root}>
      <ShowMore intro={LoadConstants.loadIsOnHold} text={noteDesc} end={90} />
    </Box>
  );
};

export const OnHoldInvoiceWarning = ({ invoiceId }: { invoiceId: string }) => {
  const [noteDesc, setNoteDesc] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const init = async () => {
    //Update logic later at here
  };

  useEffect(() => {
    init();
  }, [invoiceId]);

  if (!invoiceId) return <></>;

  if (isLoading)
    return <Skeleton variant="rectangular" width={500} height={50} />;

  return (
    <Box sx={styles.root}>
      <ShowMore
        intro={InvoicesConstants.invoiceIsOnHold}
        text={noteDesc}
        end={90}
      />
    </Box>
  );
};

const ShowMore = ({
  intro,
  text,
  end,
}: {
  intro: string;
  text: string;
  end: number;
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const hideButton = text.length <= end;
  const subString = text.substring(0, end);

  return (
    <Typography variant="body2">
      {`${intro}${text ? ': ' : ' '}`}
      {showMore ? text : `${subString}${hideButton ? '' : '...'}`}
      <Typography
        variant="body2"
        component={'span'}
        onClick={() => setShowMore(!showMore)}
        sx={{
          cursor: 'pointer',
          color: 'primary.main',
          pl: 1,
          ...(hideButton && {
            display: 'none',
          }),
        }}
      >
        {showMore ? 'Show less' : 'Show more'}
      </Typography>
    </Typography>
  );
};
