import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { locationService } from '../../../api';
import { addLoadPageStopInfo } from '../../../common/PendoClassIDs/constants';
import {
  ContactSummaryDTO,
  CreateLocationContactRequest,
} from '../../../models';
import { contactDefaultData } from '../../../subPages/customers/constants';
import AddContactForm from '../../../subPages/customers/forms/AddContactForm';
import { loadContactValidationSchema } from '../../../validator/validationSchemas';
import FormDialog from '../../Ui/FormDialog/index';
import FormPhoneField from '../../Ui/FormPhoneField';
import Select from '../../Ui/Select';
import { SingleAutocompleteForm } from '../../Ui/SingleAutocomplete';
import TextField from '../../Ui/TextField/TextField';
import { useThemeResponsive } from '../../hooks/useThemeResponsive';
import {
  stopActivityTypeOptions,
  stopDetailsFormRequiredFields,
} from '../constants';
import { StopDetailsFormRequiredFieldsType } from '../types';

export default function StopDetailsForm({
  nameStart = '',
  stopType = '',
  isFirstStop = false,
  isLastStop = false,
  isViewMode = false,
  requiredFields = {
    ...stopDetailsFormRequiredFields,
  },
}: {
  id?: number;
  nameStart?: string;
  stopType?: string;
  isFirstStop?: boolean;
  isLastStop?: boolean;
  isViewMode?: boolean;
  requiredFields?: StopDetailsFormRequiredFieldsType;
}) {
  const { isMobile } = useThemeResponsive();
  const { control, watch, setValue } = useFormContext();
  const [newContact, setNewContact] = useState<{ fullName: string }>({});
  const [addContactDialogOpen, setAddContactDialog] = useState(false);
  const location = watch(`${nameStart}location`);
  const contact = watch(`${nameStart}contact`);
  const locationContacts = location?.contacts || [];
  const appointmentConfirmation = watch('appointmentConfirmation');
  const onAddNewContact = async (contact: ContactSummaryDTO) => {
    const contactRequest = new CreateLocationContactRequest(
      contact,
      location?.id
    );
    try {
      const response = await locationService.createLocationContact(
        contactRequest
      );
      if (response instanceof ContactSummaryDTO) {
        setAddContactDialog(false);
        if (contact.isPrimary) {
          locationContacts.forEach((contact) => {
            contact.isPrimary = false;
          });
        }
        contact.id = response.id;
        locationContacts.push(contact);
        setNewContact(contact);
        setValue(`${nameStart}contact.fullName`, contact.fullName || '');
        setValue(
          `${nameStart}location`,
          { ...location, contacts: locationContacts },
          { shouldDirty: true }
        );
        setValue(`${nameStart}contact.email`, contact?.email || '');
        setValue(`${nameStart}contact.phoneData`, contact?.phoneData || null);
      }
    } catch (error) {
      return [];
    }
  };

  const handleContactChange = (data: any, name: string) => {
    if (name === `${nameStart}contact`) {
      if (data) {
        if (data.id) {
          setValue(`${nameStart}contact.email`, data?.email || '');
          setValue(`${nameStart}contact.phoneData`, data?.phoneData || null);
        } else {
          setValue(`${nameStart}contact.fullName`, data?.fullName || '');
          setValue(`${nameStart}contact.id`, null);
        }
      } else {
        setValue(`${nameStart}contact.id`, null);
        setValue(`${nameStart}contact.fullName`, '');
        setValue(`${nameStart}contact.email`, contact?.email || '');
        setValue(`${nameStart}contact.phoneData`, contact?.phoneData || null);
      }
    } else if (
      name === `${nameStart}contact.email` ||
      name === `${nameStart}contact.phoneData`
    ) {
      setValue(`${nameStart}contact.id`, null);
    }
  };
  const { t, ready } = useTranslation();

  return (
    <>
      {stopType !== 'TRIP' && (
        <Select
          id={addLoadPageStopInfo + 'Activity'}
          control={control}
          name={`${nameStart}activityType`}
          label={t('TripAddNewLoadOption3Activity')}
          options={stopActivityTypeOptions}
          fieldName="value"
          fieldValue="key"
          disabled={isViewMode || isFirstStop || isLastStop}
          sizes={{ xs: isMobile ? 12 : 6 }}
        />
      )}
      <TextField
        id={addLoadPageStopInfo + 'HandlingTime'}
        control={control}
        name={`${nameStart}avgHandlingTime`}
        label={t('TripAddNewLoadOption3HandlingTimeMin')}
        required={requiredFields.avgHandlingTime}
        disabled={isViewMode}
        sizes={{ xs: isMobile ? 12 : 6 }}
      />
      <TextField
        id={addLoadPageStopInfo + 'Notes'}
        control={control}
        name={`${nameStart}notes`}
        label={t('TripAddNewLoadOption3Notes')}
        disabled={isViewMode}
        sizes={{ xs: stopType !== 'TRIP' ? 12 : isMobile ? 12 : 6 }}
      />
      <TextField
        id={addLoadPageStopInfo + 'Reference'}
        control={control}
        name={`${nameStart}refNumber`}
        disabled={isViewMode}
        label={t('TripAddNewLoadOption3Reference')}
      />

      <SingleAutocompleteForm
        id={addLoadPageStopInfo + 'Name'}
        control={control}
        name={`${nameStart}contact`}
        label={t('TripAddNewLoadOption3Name')}
        options={locationContacts}
        fieldName="fullName"
        typedTextName="fullName"
        newOption={newContact}
        addNewOption={(name: string) => {
          if (name && typeof name === 'string') {
            setNewContact({ fullName: name });
            setAddContactDialog(true);
          }
        }}
        disabled={isViewMode}
        freeSolo={location?.id}
        onChangeCb={handleContactChange}
        sizes={{ xs: isMobile ? 12 : 6 }}
      />
      <TextField
        id={addLoadPageStopInfo + 'Email'}
        control={control}
        name={`${nameStart}contact.email`}
        label={t('TripAddNewLoadOption3Email')}
        onChangeCallback={handleContactChange}
        disabled={isViewMode}
        sizes={{ xs: isMobile ? 12 : 6 }}
      />
      <FormPhoneField
        id={addLoadPageStopInfo + 'Phone'}
        control={control}
        name={`${nameStart}contact.phoneData`}
        label={t('TripAddNewLoadOption3Phone')}
        onChangeCallback={handleContactChange}
        disabled={isViewMode}
        sizes={{ xs: 12 }}
      />
      {addContactDialogOpen && (
        <FormDialog
          data={{ ...contactDefaultData, fullName: newContact?.fullName }}
          titleText="Add New Contact"
          actionLabel={t('addContact')}
          open={addContactDialogOpen}
          onAction={onAddNewContact}
          handleClose={() => {
            setAddContactDialog(false);
          }}
          validationSchema={loadContactValidationSchema}
          disableActionBtnByDefault={false}
          contentRenderer={() => (
            <AddContactForm numberOfContacts={locationContacts?.length} />
          )}
        ></FormDialog>
      )}
    </>
  );
}
