import { IDriverPreferenceForm } from '../../../types/DispatchTypes';

const requiredFieldInputConfig = {
  required: true,
  sx: {
    '.MuiFormLabel-asterisk': { color: '#F44336' },
    '.MuiFormLabel-root': { display: 'flex', fontSize: 12 },
  },
};

export const preferenceConfig = [
  {
    title: 'Driver',
    caption:
      'Use this section to update driver preference information. This will be used to optimize trip selection and assignment.',
    inputStyles: {},
    preferences: [
      {
        title: 'Prefer Driver Based on Seniority',
        caption:
          'Drivers with longer duration in the company will be preferred during Trip assignment',
        label: 'Prefer Driver Based on Seniority',
        type: 'DROPDOWN',
        inputConfig: requiredFieldInputConfig,
        stateKey: 'applySeniority',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
      },
    ],
  },
  {
    title: 'Deadhead',
    caption:
      'Use this section to update the deadhead related information. This will be used to optimise trip selection and assignment.',
    inputStyles: {},
    preferences: [
      {
        title: 'Max Origin Deadhead',
        caption:
          'Maximum acceptable origin deadhead for a trip that a driver can be assigned to.',
        label: 'Max Origin Deadhead (mi)',
        type: 'INPUT',
        inputConfig: {},
        stateKey: 'maxOriginDeadhead',
      },
      {
        title: 'Max Destination Deadhead',
        caption:
          'Maximum acceptable destination deadhead for a trip that a driver can be assigned to.',
        label: 'Max Destination Deadhead (mi)',
        type: 'INPUT',
        inputConfig: {},
        stateKey: 'maxDestinationDeadhead',
      },
    ],
  },
  {
    title: 'Trip Information',
    caption:
      'Use this section to update the trip related information. This will be used to optimise trip selection and assignment.',
    inputStyles: {},
    preferences: [
      {
        title: 'Max Trip Duration',
        caption:
          'Maximum acceptable trip duration that should be assigned to a driver.',
        label: 'Max Trip Duration (h)',
        type: 'INPUT',
        inputConfig: requiredFieldInputConfig,
        stateKey: 'maxTourDuration',
      },
      {
        title: 'Max Trip Mileage',
        caption:
          'Maximum acceptable trip miles that should be assigned to a driver.',
        label: 'Max Trip Mileage (mi)',
        type: 'INPUT',
        inputConfig: requiredFieldInputConfig,
        stateKey: 'maxTourMileage',
      },
      {
        title: 'Max Connection Time to Next Load',
        caption: 'Maximum acceptable time gap between two loads.',
        label: 'Max Connection Time to Next Load (h)',
        type: 'INPUT',
        inputConfig: requiredFieldInputConfig,
        stateKey: 'maxLayoverTime',
      },
      {
        title: 'Max # Trips on Tour',
        caption: 'Maximum number of trips that can be assigned to a tour.',
        label: 'Max # Trips on Tour',
        type: 'INPUT',
        inputConfig: requiredFieldInputConfig,
        stateKey: 'maxTripCountInATour',
      },
    ],
  },
];

export const PreferenceFormDefaultState = {
  maxOriginDeadhead: '',
  maxDestinationDeadhead: '',
  maxTourDuration: '',
  maxTourMileage: '',
  maxLayoverTime: '',
  maxTripCountInATour: '',
  applySeniority: false,
  defaultPaymentPerLoadedMile: '',
  defaultPaymentPerEmptyMile: '',
  defaultPaymentPerHour: '',
  defaultRevenueShare: '',
  maxDelayPerStop: '',
};

export const preferenceFormConfig: Array<Array<IDriverPreferenceForm>> = [
  [
    {
      id: 'seniorty',
      heading: 'Driver',
      fields: [
        {
          id: 'applySeniority',
          label: 'Prefer Driver Based on Seniority',
          type: 'radioButton',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
      ],
    },
    {
      id: 'paymentDefaults',
      heading: 'Default Payment Terms',
      fields: [
        {
          id: 'defaultPaymentPerLoadedMile',
          label: 'Payment per Loaded Mile ($)',
          type: 'decimal',
          inputConfig: requiredFieldInputConfig,
        },
        {
          id: 'defaultPaymentPerEmptyMile',
          label: 'Payment per Empty Mile ($)',
          type: 'decimal',
          inputConfig: requiredFieldInputConfig,
        },
        {
          id: 'defaultPaymentPerHour',
          label: 'Payment per Hour ($)',
          type: 'decimal',
          inputConfig: requiredFieldInputConfig,
        },
        {
          id: 'defaultRevenueShare',
          label: 'Default Revenue Share (%)',
          type: 'percentage',
          inputConfig: requiredFieldInputConfig,
        },
      ],
    },
    {
      id: 'Deadhead',
      heading: 'Deadhead',
      fields: [
        {
          id: 'maxOriginDeadhead',
          label: 'Max Origin Deadhead (mi)',
          type: 'number',
        },
        {
          id: 'maxDestinationDeadhead',
          label: 'Max Destination Deadhead (mi)',
          type: 'number',
        },
      ],
    },
  ],
  [
    {
      id: 'Trip',
      heading: 'Trip Information',
      fields: [
        {
          id: 'maxTourDuration',
          label: 'Max Trip Duration (h)',
          type: 'decimal',
          inputConfig: requiredFieldInputConfig,
        },
        {
          id: 'maxDelayPerStop',
          label: 'Max Delay Per Stop (h)',
          type: 'decimal',
          inputConfig: requiredFieldInputConfig,
        },
        {
          id: 'maxLayoverTime',
          label: 'Max Connection Time to Next Load (h)',
          type: 'decimal',
          inputConfig: requiredFieldInputConfig,
        },
        {
          id: 'maxTourMileage',
          label: 'Max Trip Mileage (mi)',
          type: 'number',
          inputConfig: requiredFieldInputConfig,
        },
        {
          id: 'maxTripCountInATour',
          label: 'Max # Trips on Tour',
          type: 'radioButton',
          inputConfig: requiredFieldInputConfig,
          options: [
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
          ],
        },
      ],
    },
  ],
];
