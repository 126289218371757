import moment from 'moment';
import { DateTimezoneUtils } from '../../utils/Timezone.utils';
import { timelineDateFormat } from '../../constants/gantt/gantt.const';
import { UI_TIME_24HOURS } from '../../constants/date.constants';
import {
  IDispatchOption,
  ScheduleTimeoffsType,
  ScheduleTripType,
} from '../../types/DispatchTypes';
import { EVENT_STATUS } from '../../views/dispatch/constants/types';
import {
  ELoadStatus,
  ETimeOffStatus,
} from '../../common/LoadTabPanel/constants/constants';

export const getPreferredGanttStartDate = () => {
  const timezone = DateTimezoneUtils.getDefaultTimezone();
  const date = moment();
  if (timezone) {
    date.tz(timezone);
  }
  return [
    {
      id: 'current-time',
      startDate: date.format(timelineDateFormat),
      name: date.format(UI_TIME_24HOURS),
      cls: 'b-sch-current-time',
    },
  ];
};

export const formatSortFilterParam = (
  sort: IDispatchOption | undefined,
  sortDirection: boolean
): string => {
  if (sortDirection) return sort?.key as string;
  return `-${sort?.key}`;
};

export const isTripRevertToAvailableAllowed = (trip: ScheduleTripType) => {
  if (
    (trip.status === EVENT_STATUS.PLANNED ||
      trip.status === EVENT_STATUS.COMPLETED ||
      trip.status === EVENT_STATUS.ASSIGNED) &&
    !isTripOnHold(trip)
  )
    return true;
  return false;
};

export const isTripOnHold = (trip: ScheduleTripType) => {
  if (trip?.onHold) return true;
  return false;
};

export const isVisibleDateOverStartDate = (props: {
  startDate?: Date | string;
  visibleDate?: Date | string;
}): boolean => {
  const { startDate, visibleDate } = props;
  if (!startDate || !visibleDate) return false;
  if (new Date(visibleDate)?.getTime?.() <= new Date(startDate)?.getTime?.())
    return true;
  return false;
};

export const isVisibleDateOverEndDate = (props: {
  endDate?: Date | string;
  visibleDate?: Date | string;
}): boolean => {
  const { endDate, visibleDate } = props;
  if (!endDate || !visibleDate) return false;
  if (
    new Date(visibleDate)?.getTime?.() >=
    new Date(moment(endDate).subtract(3, 'hours').toDate())?.getTime?.()
  )
    return true;
  return false;
};

export const isTripInProgress = (trip: ScheduleTripType): boolean => {
  if (
    trip?.status === ELoadStatus.DISPATCHED ||
    trip?.status === ELoadStatus.IN_TRANSIT
  )
    return true;
  return false;
};

export const isResourceTimeOffActive = (timeoff: ScheduleTimeoffsType) => {
  if (timeoff?.status === ETimeOffStatus.ACTIVE) return true;
  return false;
};
