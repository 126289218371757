import { DateRange } from '@mui/x-date-pickers-pro-v5';
import { Moment } from 'moment';
import { FC } from 'react';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { QueryParams } from '../../../models';
import {
  ETripSettlementStatus,
  TTripSettlementStatusOption,
  TTripStatusOption,
} from '../../../models/DTOs/Trip/Types';
import { IRelativeDateRangeOption } from '../../../ui-kit/components/RelativeDateRange';

export interface IAutocompleteOption {
  // seqNumber: string;
  label: string;
  value: string;
  [key: string]: any;
}

export interface IAutocompleteBooleanOption {
  label: string;
  value: boolean;
}

export enum EOperationMode {
  TEAM = 'TEAM',
  SOLO = 'SOLO',
}

export interface IOperationModeAutocompleteOption {
  label: string;
  value: EOperationMode;
}

export interface IFiltersFormConfig {
  key?: number;
  name?: string;
  fieldName?: string;
  isHyperLink?: boolean;
  getData?: (searchString: string, pageNumber: number) => any;
  label: string;
  type:
    | 'MultipleAutocomplete'
    | 'SingleAutocomplete'
    | 'DateRangePicker'
    | 'RelativeDateRange'
    | 'Custom';
  default: boolean;
  options?:
    | IAutocompleteOption[]
    | IAutocompleteBooleanOption[]
    | IRelativeDateRangeOption[];
  customComponent?: FC<any>;
}

export interface ITripFilters {
  statuses?: TTripStatusOption[];
  driverIdsFilter?: IAutocompleteOption[];
  dispatcherIdsFilter?: IAutocompleteOption[];
  tripIdsFilter?: IAutocompleteOption[];
  settlementStatuses?: TTripSettlementStatusOption[];
  loadIdsFilter?: IAutocompleteOption[];
  manifestIdsFilter?: IAutocompleteOption[];

  tractorIdsFilter?: IAutocompleteOption[];
  trailerIdsFilter?: IAutocompleteOption[];
  terminalIds?: string[];

  originCityStateList?: IAutocompleteOption[];
  destinationCityStateList?: IAutocompleteOption[];

  tripStartDateRanges?: DateRange<Date | null>;

  originStateList?: { key: string; value: string }[];
  destinationStateList?: { key: string; value: string }[];
  relay?: { label: string; value: boolean };
  loadType?: { key: string; value: string };
  chassisNumbers?: { value: string }[];
  containerNumbers?: { value: string }[];
  customerIdsFilter?: { key: number; value: string }[];
  referenceIds?: { referenceId: string; loadIds: string[] }[];

  tripStartFrom?: Moment | string;
  tripStartTo?: Moment | string;
  tripEndDateRanges?: DateRange<Date | null>;
  tripEndFrom?: Moment | string;
  tripEndTo?: Moment | string;

  sort: string | null;
  delayViolations?: IAutocompleteBooleanOption;
  detention?: IAutocompleteBooleanOption;
  needDriversNow?: IAutocompleteBooleanOption;
  isEmptyTrip?: IAutocompleteBooleanOption;
  driverOperationMode?: IOperationModeAutocompleteOption;

  carrierIdsFilter?: IAutocompleteOption[];
  brokerageTripFilter?: IAutocompleteBooleanOption[];
  onHoldState?: IAutocompleteBooleanOption;
  tagIds?: Array<string>;
}

export interface ISettingsPagination {
  isLoading: boolean;
  first: boolean;
  last: boolean;
  pageNumber: number;
  pageSize: number;
}

export type ITripQueryParams = {
  tripStatusList?: ELoadStatus[];
  driverIdsFilter?: string[];
  dispatcherIdsFilter?: string[];
  settlementStatus?: ETripSettlementStatus[];
  loadIdsFilter?: string[];
  manifestIdsFilter?: string[];
  tripIdsFilter?: string[];
  tractorIdsFilter?: string[];
  trailerIdsFilter?: string[];
  originCityStateList?: string[];
  destinationCityStateList?: string[];
  originStateList?: string[];
  destinationStateList?: string[];
  isRelay?: boolean;
  chassisNumbers?: string[];
  containerNumbers?: string[];
  customerIdsFilter?: string[];
  referenceIds?: string[];
  startFrom?: string;
  startTo?: string;
  endFrom?: string;
  endTo?: string;
  loadType?: string;
  sort?: string;
  hasViolation?: boolean;
  calculateDetention?: boolean;
  needToAssign?: boolean;
  operationMode?: EOperationMode;
  terminalIds?: string[];
  carrierIdsFilter?: string[];
  isBrokerageTrip: boolean | null;
  isEmptyTrip: boolean | null;
  onHoldState: boolean | null;
  tagIds?: Array<string>;
};

export interface ITripQueryParamsTable extends ITripQueryParams {
  pageSize: number;
  pageNumber: number;
  calculateMetrics?: boolean;
}

export type TripQueryParams = ITripQueryParamsTable;

export interface IFetchMainData {
  queryParams: TripQueryParams;
}

export enum EUnassignedTripAction {
  BULK_SELECT = 'BULK_SELECT', //Selecting Planned Trips - Selecting Available Trips => Mark Available - Mark Assigned
  MANUAL_DRIVER_SELECTION = 'MANUAL_DRIVER_SELECTION', //Mark As Planned - Mark As Assigned
  MANUAL_TRIP_SELECTION = 'MANUAL_TRIP_SELECTION', //2 steps : Select a Driver in Gantt chart then select trips in Unassigned Trips Grid
}

export enum EUnassignedTripMenuAction {
  MARK_AVAILABLE = 'MARK_AVAILABLE',
  MARK_ASSIGNED = 'MARK_ASSIGNED',
  MARK_AS_PLANNED = 'MARK_AS_PLANNED',
  MARK_AS_ASSIGNED = 'MARK_AS_ASSIGNED',
  CANCEL = 'CANCEL',
}

export interface IGridActionState {
  action: EUnassignedTripAction;
  ids: string[];
}
export class GetUpdatedManifestTripListRequest extends QueryParams {
  organizationId!: number;
  tripIds!: Array<string>;
  constructor(dto: any) {
    super();
    Object.assign(this, dto);
  }
}
