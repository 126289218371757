import { Box } from '@mui/material';
import { Suspense, lazy } from 'react';
import { useFormContext } from 'react-hook-form';
import StorageManager from '../../../../../StorageManager/StorageManager';
import { getEmailBodyForSendingPDF } from '../../../../../utils';
import { EmailFormValidationSchema } from '../../../../../validator/validationSchemas';
import EmailReceivers from '../../../../Email/EmailReceivers';
import Checkbox from '../../../../Ui/Checkbox';
import FormDialog from '../../../../Ui/FormDialog';
import TextField from '../../../../Ui/TextField/TextField';
const Wisiwig = lazy(() => import('../../../../../ui-kit/components/Wisiwig'));

export function DispatchSheetSendEmailForm({
  fileName,
  seqNumber,
}: {
  fileName: string;
  seqNumber: string;
}) {
  const { control } = useFormContext();

  return (
    <>
      <EmailReceivers />
      <Box>
        <TextField control={control} name="subject" label="Subject" />
      </Box>
      <Box sx={{ mb: 4 }}>
        <Suspense fallback={<></>}>
          <Wisiwig
            customStyles={{
              contentBackground: {
                backgroundColor: 'transparent',
              },
              headerBackgroundColor: {
                backgroundColor: 'transparent',
              },
            }}
            control={control}
            name="message"
          />
        </Suspense>
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'dispatchSheet'}
          label={`${fileName}_${seqNumber}.pdf`}
          disabled
        />
      </Box>
    </>
  );
}

export function SendDispatchSheetEmailDialog({
  subject,
  fileName,
  terminal,
  seqNumber,
  onAction,
  onClose,
}: {
  subject: string;
  fileName: string;
  terminal: string;
  seqNumber: string;
  onAction: (data) => void;
  onClose: () => void;
}) {
  const user = StorageManager.getUser();
  const organizationName = user.organizationName;
  const pdfTitle = `${fileName}_${seqNumber}.pdf`;

  return (
    <FormDialog
      data={{
        to: [],
        cc: [],
        subject: `${subject} from ${terminal || organizationName}`,
        message: getEmailBodyForSendingPDF(
          terminal || organizationName,
          pdfTitle
        ),
        dispatchSheet: pdfTitle,
      }}
      validationSchema={EmailFormValidationSchema}
      titleText={`Email ${subject}`}
      actionLabel="Email"
      actionLabelId="Email"
      open
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => (
        <DispatchSheetSendEmailForm seqNumber={seqNumber} fileName={fileName} />
      )}
    />
  );
}
