import { Box, Typography } from '@mui/material';
import { PopupActions } from '../Popup/PopupActions';
import { StyledPopupContent, StyledPopupRoot } from '../Popup/styles';
import { ProgressBarComponent } from '../ProgressBarComponent';
import { ProfitabilityBreakDown } from './components/BreakDown';
import { IProfitablityValue } from './types';

export interface ProfitabilityProps {
  data: any;
  selectedDriverDetails: any;
  selectedRowData: any;
  isDriverSelected: any;
  onBookHandler: () => void;
}
export const Profitability = ({
  // data,
  selectedDriverDetails,
  selectedRowData,
  isDriverSelected,
  onBookHandler,
}: ProfitabilityProps) => {
  const profitablityValues: IProfitablityValue[] = [
    {
      title: 'Variable Costs',
      subtitle: 'Fuel, Driver pay, etc.',
      cost: selectedRowData?.Offer?.variableCost,
    },
    {
      title: 'Fixed Costs',
      subtitle: 'Equipment costs',
      cost: selectedRowData?.Offer?.fixedCost,
    },
  ];
  const weeklyRevenue: number =
    selectedDriverDetails?.value?.subValue?.defaultWeeklyRevenue;
  const offerPrice = selectedRowData?.Offer?.value;
  const totalGoal = Number(
    selectedDriverDetails?.value?.value?.driverGoal
  )?.toFixed(2);
  const driverName = selectedDriverDetails?.value?.value?.driverName;
  const driverGoal = Number(
    (((weeklyRevenue + +offerPrice) / +totalGoal) * 100).toFixed(2)
  );
  const profitabilityBreakdownData = {
    variableCost: Number(selectedRowData?.Offer?.variableCost),
    fixedCost: Number(selectedRowData?.Offer?.fixedCost),
    offerPrice: Number(offerPrice).toFixed(2),
    isEstimatedOfferValue: selectedRowData?.Offer?.isEstimatedOfferValue,
    profit: (
      (Number(offerPrice * 100) -
        (Number(selectedRowData?.Offer?.variableCost * 100) +
          Number(selectedRowData?.Offer?.fixedCost * 100))) /
      100
    ).toFixed(2),
  };
  const driverGoalInDollars = weeklyRevenue + Number(offerPrice);
  return (
    <>
      <StyledPopupRoot>
        <StyledPopupContent>
          {/*{isDriverSelected && (*/}
          {/*  <Box sx={{ pb: '24px' }}>*/}
          {/*    <Typography variant="h5">*/}
          {/*      <Box*/}
          {/*        sx={{*/}
          {/*          display: 'inline-block',*/}
          {/*          color: 'primary.main',*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {driverGoal}%*/}
          {/*      </Box>{' '}*/}
          {/*      {`of ${driverName}'s Goal`}*/}
          {/*    </Typography>*/}
          {/*    <ProgressBarComponent driverGoal={driverGoal} />*/}

          {/*    <Typography variant="body1">*/}
          {/*      <strong>{driverName}</strong> Will be at &nbsp;*/}
          {/*      <Box*/}
          {/*        sx={{*/}
          {/*          color: 'success.main',*/}
          {/*          fontWeight: 600,*/}
          {/*          display: 'inline-block',*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        ${driverGoalInDollars}*/}
          {/*      </Box>*/}
          {/*      &nbsp; of his ${totalGoal} Goal.*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*)}*/}
          <Typography
            sx={{
              color: 'primary.main',
            }}
            variant={'h6'}
          >
            {`Here’s the break down`}
          </Typography>
          <ProfitabilityBreakDown
            profitablityValues={profitablityValues}
            profitabilityBreakdownData={profitabilityBreakdownData}
          />
        </StyledPopupContent>
        <PopupActions
          onBidHandler={() => {
            //
          }}
          onBookHandler={onBookHandler}
        />
      </StyledPopupRoot>
    </>
  );
};
