import { AXELE_PERMISSION_TYPE } from '../../Permission';
import { getHasPermission } from '../../Permission/utils/helperUtils';

export const useLoadAiPermission = () => {
  const hasBulkOptimizationPermission: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.OPTIMIZATION_EDIT],
  });

  const hasEngineEvaluationPermission: boolean = getHasPermission({
    contains: [AXELE_PERMISSION_TYPE.OPTIMIZATION_EVALUATION_EDIT],
  });

  return {
    hasBulkOptimizationPermission,
    hasEngineEvaluationPermission,
  };
};
