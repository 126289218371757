import { useMemo } from 'react';
import FormDialog from '../../../../common/Ui/FormDialog';
import { CancelLoadForm } from './components/Form/CancelLoadForm';
import { CompleteLoadForm } from './components/Form/CompleteLoadForm';
import { MarkInvoiceForm } from './components/Form/MarkInvoiceForm';
import { MarkPaidForm } from './components/Form/MarkPaidForm';
import { Moment } from 'moment';
import { date, object, string } from 'yup';
import { transformValidationDate } from '../../../../common/LoadTabPanel/tabs/Routes/components/RelayStopPopup';
import { E3DotMenuType } from './types';

export interface ActionDialogFormData {
  seqNumber?: string | null;
  completionDate: Moment | null;
  invoiceNotes: string;
  invoicedDate: Moment | null;
  paidDate: Moment | null;
  //loadCancellationDetails
  cancellationDate?: Moment | null;
  keepInvoiceLineItems?: boolean;
  markAsNonInvoiceable?: boolean;
}

export interface LoadDetailDialogProps {
  type: E3DotMenuType | null;
  onClose: () => void;
  onAction: (data: ActionDialogFormData) => void;
  data: ActionDialogFormData;
  entity: 'Load' | 'Trip' | 'Manifest';
}

export const LoadDetailDialog = ({
  onClose,
  onAction: _onAction,
  data,
  type,
  entity,
}: LoadDetailDialogProps): JSX.Element => {
  const contentRenderer = () => {
    const renderContent = () => {
      switch (type) {
        case E3DotMenuType.COMPLETE:
          return <CompleteLoadForm entity={entity} />;
        case E3DotMenuType.CANCEL:
          return <CancelLoadForm />;
        case E3DotMenuType.MARK_AS_INVOICE:
          return <MarkInvoiceForm />;
        case E3DotMenuType.MARK_AS_PAID:
          return <MarkPaidForm />;
        default:
          return null;
      }
    };

    return <>{renderContent()}</>;
  };

  const onAction = async (data: ActionDialogFormData): Promise<void> => {
    _onAction(data);
  };

  const formDialogSetting = useMemo(() => {
    switch (type) {
      case E3DotMenuType.COMPLETE:
        return {
          titleText: `Complete ${entity}: ${data.seqNumber || 'N/A'}`,
          actionLabel: 'Save',
        };
      case E3DotMenuType.CANCEL:
        return {
          titleText: `Cancel ${entity}: ${data.seqNumber || 'N/A'}`,
          actionLabel: 'Save',
        };
      case E3DotMenuType.MARK_AS_INVOICE:
        return {
          titleText: `Mark as Invoiced`,
          actionLabel: 'Save',
        };
      case E3DotMenuType.MARK_AS_PAID:
        return {
          titleText: `Mark as Paid`,
          actionLabel: 'Save',
        };
    }
  }, [data.seqNumber, type]);

  const getValidationSchema = () => {
    switch (type) {
      case E3DotMenuType.COMPLETE:
        return object().shape({
          completionDate: date()
            .max(new Date(), 'Completion Date & Time Cannot be in future')
            .required('Completion date-time is required')
            .typeError('Date-time is not valid'),
          invoiceNotes: string().max(
            1024,
            'Cannot be longer than 1024 characters.'
          ),
        });
      case E3DotMenuType.CANCEL:
        return object().shape({
          cancellationDate: date()
            .max(new Date(), 'Date cannot be in future')
            .required('Canceled date-time is required')
            .typeError('Date-time is not valid'),
          invoiceNotes: string().when('markAsNonInvoiceable', {
            is: false,
            then: string().max(1024, 'Cannot be longer than 1024 characters.'),
          }),
        });
      case E3DotMenuType.MARK_AS_INVOICE:
        return object().shape({
          invoicedDate: date()
            .transform(transformValidationDate)
            .max(new Date(), 'Date cannot be in future')
            .required('Invoice date is required')
            .typeError('Invoice date is not valid'),
        });
      case E3DotMenuType.MARK_AS_PAID:
        return object().shape({
          paidDate: date()
            .transform(transformValidationDate)
            .max(new Date(), 'Date cannot be in future')
            .required('Payment date is required')
            .typeError('Date is not valid'),
        });
    }
  };

  return (
    <FormDialog
      data={data}
      titleText={formDialogSetting?.titleText}
      actionLabel={formDialogSetting?.actionLabel}
      open
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={contentRenderer}
      validationSchema={getValidationSchema()}
    />
  );
};
