import {
  documentService,
  expenceService,
  userService,
  vendorService,
} from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  AssignDocumentRequest,
  ChangeStatusBulkRequest,
  CreateDocumentOnlyRequest,
  CreateVendorRequest,
  DeleteDocumentRequest,
  DeleteExpenseRequest,
  DeletePayItemDTO,
  DocumentSummaryResponse,
  Expense,
  ExpenseDetails,
  ExpenseListTotalsType,
  ExpensesListRequestType,
  ExpensesStaticDataType,
  GetDocumentTypeListRequest,
  GetExpenseDetailsByIdRequest,
  GetExpenseListRequest,
  GetNamesRequest,
  PaginatedExpenseList,
  Payment,
  QueryParams,
  SaveExpenseRequest,
  SavePayItemRequest,
  SavePaymentRequest,
  VendorNamesListRequest,
  VendorSummaryDTO,
} from '../../../models';
import { LineItem, ViewMetaData } from '../../../types';
import Controller from '../../tractors/Controller';

import { StaticDataType } from '../../../contexts/StaticDataContext';
import { TerminalShort } from '../../../models/common/modelsShort';
import StorageManager from '../../../StorageManager/StorageManager';
import { prepareFiltersFromList } from '../../../utils';

export const fetchExpenses = async (
  queryParams: ExpensesListRequestType,
  filters: ViewMetaData,
  globalTerminalsIds: string[],
  fetchingType = 'GET_DATA'
): Promise<PaginatedExpenseList | undefined> => {
  const request = setExpensesRequestFilters(
    filters,
    queryParams,
    globalTerminalsIds
  );
  const query = new GetExpenseListRequest(request);

  const response = await expenceService.getExpenseList(query, fetchingType);

  if (!response || response instanceof ServiceError) {
    return Promise.resolve(undefined);
  } else {
    if (response.content) {
      return {
        ...response,
        content: response.content.map((i: Expense) => new Expense(i)),
      };
    } else {
      return response;
    }
  }
};

export const getTotals = async (
  queryParams: ExpensesListRequestType,
  filters: ViewMetaData,
  globalTerminalsIds: string[]
): Promise<ExpenseListTotalsType | undefined> => {
  const request = setExpensesRequestFilters(
    filters,
    queryParams,
    globalTerminalsIds
  );
  const query = new GetExpenseListRequest(request);

  return expenceService.getTotals(query);
};

export const getExpenseDetailsById = async (
  expenseId: string,
  terminals: TerminalShort[],
  staticData: StaticDataType
): Promise<ExpenseDetails | undefined> => {
  const request = new GetExpenseDetailsByIdRequest(expenseId);
  const result = await expenceService.getExpenseDetailsById(request);
  if (result instanceof ServiceError) {
    return;
  } else {
    return new ExpenseDetails(result, terminals, staticData);
  }
};

export const createExpense = async (
  expenseData: ExpenseDetails,
  terminals: TerminalShort[],
  staticData: StaticDataType
): Promise<ExpenseDetails | undefined> => {
  const request = new SaveExpenseRequest(expenseData);
  request.addUserIdToParams();
  const response = await expenceService.createExpense(request);
  if (response instanceof ServiceError) {
    return;
  } else {
    return new ExpenseDetails(response, terminals, staticData);
  }
};

export const updateExpense = async (
  expenseData: ExpenseDetails,
  terminals: TerminalShort[],
  staticData: StaticDataType
): Promise<ExpenseDetails | undefined> => {
  const request = new SaveExpenseRequest(expenseData);
  const response = await expenceService.updateExpense(request);
  if (response instanceof ServiceError) {
    return;
  } else {
    return new ExpenseDetails(response, terminals, staticData);
  }
};

export const deleteExpensesBulk = async (
  expense: Expense[]
): Promise<string[] | undefined> => {
  const expenseIds = new DeleteExpenseRequest(expense);
  const response = await expenceService.deleteExpensesBulk(expenseIds);
  if (response instanceof ServiceError) {
    return;
  } else {
    return response;
  }
};

export const getUsersNamesList = async (name: string, pageNumber: number) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.name = name;
  request.excludeRoleCodeList = ['SP'];
  request.excludeStatuses = [0];
  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
    return [];
  }
  return response;
};

export const getDriverNamesList = async (
  name: string,
  pageNumber: number,
  terminalIds?: string[]
) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.name = name;
  request.terminalIds = terminalIds;
  request.roleCodeList = ['DR'];
  request.excludeStatuses = [0];
  const response = await userService.getNames(request);
  if (response instanceof ServiceError) {
    return [];
  }
  return response;
};

export const getOwnerOperatorList = async (
  name: string,
  pageNumber: number,
  terminalIds?: string[]
) => {
  const request = new GetNamesRequest();
  request.pageNumber = pageNumber;
  request.pageSize = 25;
  request.name = name;
  request.terminalIds = terminalIds;
  request.roleCodeList = ['DR'];
  request.excludeStatuses = [0];
  const response = await userService.getOwnerOperatorNames(request);
  if (response instanceof ServiceError) {
    return [];
  }
  return response;
};

export const getVendorNamesList = async (name: string, pageNumber: number) => {
  const request = new VendorNamesListRequest(pageNumber, 25, name);

  const response = await expenceService.getVendorNamesList(request);
  if (response instanceof ServiceError) {
    return [];
  }
  return response;
};

export const getStateOptions = async () => {
  const controller = Controller.instance();

  return controller.getStateOptions();
};

export const getDocumentTypeList = (): Promise<LineItem[]> => {
  return documentService.getDocumentTypeList(new GetDocumentTypeListRequest());
};

export const stateOptions: any = () => {
  return { content: Controller.instance().stateOptions, last: true };
};

export const setExpensesRequestFilters = (
  metaData: ViewMetaData,
  query: ExpensesListRequestType,
  globalTerminalsIds: string[]
) => {
  const expenseStatuses = metaData['statusFilter']?.id;
  const companyExpense = metaData['companyCardFilter']
    ? metaData['companyCardFilter']?.id === 'COMPANY_CARD'
    : null;

  const expenseIds = prepareFiltersFromList(metaData['expenseIdFilter'], 'id');

  const dateFrom = metaData['dateRangeFilter']
    ? metaData['dateRangeFilter']?.dateRange?.[0]
    : null;

  const dateTo = metaData['dateRangeFilter']
    ? metaData['dateRangeFilter']?.dateRange?.[1]
    : null;

  const categoryId = prepareFiltersFromList(
    metaData['expenseCategoryFilter'],
    'id'
  );

  const fuelTypes = metaData['fuelTypeFilter']
    ?.map((item) => [item.key, item.value])
    ?.flat();

  const loadIds = prepareFiltersFromList(metaData['loadIdFilter'], 'seqNumber');
  const tractorIds = prepareFiltersFromList(
    metaData['tractorListFilter'],
    'id'
  );
  const trailerIds = prepareFiltersFromList(
    metaData['trailerListFilter'],
    'id'
  );
  const driverIds = prepareFiltersFromList(metaData['driverListFilter'], 'id');

  const terminalIds = globalTerminalsIds;

  const cardNumbers = prepareFiltersFromList(
    metaData['cardFilter'],
    'cardNumber'
  );

  const maintenanceItems = prepareFiltersFromList(
    metaData['maintenanceItems'],
    'value'
  );

  return {
    expenseStatuses,
    companyExpense,
    expenseIds,
    dateFrom,
    dateTo,
    categoryId,
    fuelTypes,
    loadIds,
    tractorIds,
    trailerIds,
    driverIds,
    terminalIds,
    cardNumbers,
    maintenanceItems,
    ...query,
  };
};

export const createVendor = async (
  name: string
): Promise<VendorSummaryDTO | null> => {
  const request = new CreateVendorRequest({ name });
  const response = await vendorService.createVendor(request);

  if (response instanceof ServiceError) {
    return null;
  }
  return response;
};

export const createPaymentsBulk = async (
  data: Payment[],
  expenseId?: string
): Promise<Payment[] | undefined> => {
  const request = new SavePaymentRequest(data, expenseId);
  return expenceService.createPaymentsBulk(request);
};

export const updatePaymentsBulk = async (
  data: Payment[]
): Promise<Payment[] | undefined> => {
  const request = new SavePayItemRequest(data);
  const response = await expenceService.updatePaymentsBulk(request);
  return response ? data : undefined;
};

export const deletePaymentsBulk = async (
  payments: Payment[]
): Promise<string[] | number[] | undefined> => {
  const deletePayItems = new DeletePayItemDTO(payments as any);
  const response = await expenceService.deletePaymentsBulk(deletePayItems);
  if (response instanceof ServiceError) {
    return;
  }
  return response;
};

export const uploadDocument = async (assignedDocument: File) => {
  const queryParams = new QueryParams();
  queryParams.addUserIdToParams();

  const requestBody = new CreateDocumentOnlyRequest({
    data: assignedDocument,
    ownerId: queryParams.userId,
  });

  const response = await documentService.createDocumentOnly(requestBody);

  return response instanceof ServiceError ? null : response;
};

export const assignDocument = async (
  file: DocumentSummaryResponse,
  expenseData: ExpenseDetails,
  staticData: ExpensesStaticDataType
) => {
  const user = StorageManager.getUser() || {};

  const documentType = staticData.documentTypes.find(
    (item) => item.itemCode === 'RECEIPT'
  );

  const assignDocumentRequest = new AssignDocumentRequest({
    description: '',
    ownerName: user.firstname + ' ' + user.lastname,
    permission: 'PRIVATE',
    documentType: 'RECEIPT',
    documentTypeId: documentType?.id || '',
    documentId: file.id,
    fileName: file.fileName,
    fileSize: file.fileSize,
    uploadDate: file.uploadDate,
    ownerId: user.id,
    terminalId: expenseData?.terminal?.id,
    attachedEntities: [
      {
        properties: [
          {
            id: expenseData?.id || '',
            title: expenseData?.seqNumber,
          },
        ],
        type: 'EXPENSE',
      },
    ],
  });
  const response = documentService.assignDocument(assignDocumentRequest);

  return response instanceof ServiceError ? null : response;
};

export const saveFile = async (
  expenseData: ExpenseDetails,
  staticData: ExpensesStaticDataType
): Promise<void> => {
  if (expenseData) {
    if (expenseData.assignedDocuments?.length) {
      const file = await uploadDocument(expenseData.assignedDocuments[0]);

      if (file) {
        await assignDocument(file, expenseData, staticData);
      }
    }
  }
};

export const deleteFile = async (ids: string[]): Promise<void> => {
  if (ids) {
    await documentService.deleteDocument(
      new DeleteDocumentRequest({
        documentIds: ids,
      })
    );
  }
};

const paymentStatusMapping = {
  CLOSED: 'Closed',
  PENDING: 'Pending',
  IN_REVIEW: 'In Review',
  NONE: 'None',
  REVIEWED: 'Reviewed',
} as any;

export const changeStatusBulk = async (
  ids: string[],
  status: string
): Promise<string | undefined> => {
  const params = new ChangeStatusBulkRequest({ ids, status });
  return expenceService.changeStatusBulk(params);
};

export const getSettlementIdToSow = (driverPayData: Payment) => {
  if (!driverPayData.expenseId) {
    return '';
  }
  if (driverPayData.settlementId) {
    return `${driverPayData.settlementSeqNumber} ${
      paymentStatusMapping[driverPayData.settlementStatus]
    }`;
  }
  return 'Pending';
};

export const getIsOpenAddPaymentsModalButtonDesabled = (
  expense: ExpenseDetails,
  isFuelExpense: boolean
): boolean =>
  isFuelExpense
    ? !expense.grossAmount ||
      !expense.paidDate ||
      !expense.fuelType ||
      !expense.fuelQuantity ||
      !expense.state
    : !expense.grossAmount || !expense.paidDate || !expense.categoryObj;

export const hasClosedStatePayment = (payments?: Payment[]) =>
  payments?.some((payments) => payments.settlementStatus === 'CLOSED');

export const getListOfPaymentsByCompatible = (
  expenseData: ExpenseDetails
): { [key: string]: Payment[] } => {
  const compatiblePayments: Payment[] = [];
  const nonCompatiblePayments: Payment[] = [];
  expenseData.payments?.forEach((item) => {
    if (item.payCategory === expenseData.payCategoryObj?.payCategoryKey) {
      compatiblePayments.push(item);
    } else nonCompatiblePayments.push(item);
  });

  return { compatiblePayments, nonCompatiblePayments };
};

export const getMaintenanceList = (maintenanceDetails: {
  historyMaintenanceItems?: Array<any>;
}) => {
  if (
    maintenanceDetails &&
    maintenanceDetails?.historyMaintenanceItems?.length
  ) {
    return maintenanceDetails?.historyMaintenanceItems?.map((data) => {
      return data?.itemName;
    });
  } else {
    return [];
  }
};
