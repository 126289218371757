import { ArrowForwardOutlined } from '@mui/icons-material';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { AuthPowerByIcon } from '../../ui-kit/components/Assets';
import { useSearchParams } from 'react-router-dom';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { AuthIntroduction } from './components/AuthIntroduction';
import { AuthLogo } from './components/AuthLogo';
import { EPartner, formDefaultValues, formKeys } from './constant';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import RegistrationPage from './RegistrationPage';
import ReJoin from './ReJoin';
import ResetPasswordForm from './ResetPasswordForm';
import { getRegistrationStyles } from './styles';
import UserActivationForm from './UserActivationForm';
import UserActivationFormError from './UserActivationFormError';
import UserActivationFormSuccess from './UserActivationFormSuccess';
import { useEffect, useState } from 'react';

const Registration = ({ rejoin }) => {
  const route = window.location.pathname.split('/')[1];
  const { isMobile, themeLogin } = useThemeResponsive();
  const [searchParams] = useSearchParams();
  const [loginFormValues, setLoginFormValues] = useState({
    ...formDefaultValues.login,
  });
  const [forgotPassWordFormValues, setForgotPassWordFormValues] = useState({
    ...formDefaultValues.forgotPassword,
  });
  const [registrationFormValues, setRegistrationFormValues] = useState({
    ...formDefaultValues.registration,
  });
  const isExistPartnerUser: boolean = Object.values(EPartner).includes(
    searchParams.get('partner')
  );

  const resestFormValues = (key: string) => {
    // switch (key) {
    //   case formKeys.LOGIN:
    //     setForgotPassWordFormValues({ ...formDefaultValues.forgotPassword });
    //     setRegistrationFormValues({ ...formDefaultValues.registration });
    //     break;
    //   case formKeys.FORGOTPASSWORD:
    //     setLoginFormValues({ ...formDefaultValues.login });
    //     setRegistrationFormValues({ ...formDefaultValues.registration });
    //     break;
    //   case formKeys.REGISTRATION:
    //     setForgotPassWordFormValues({ ...formDefaultValues.forgotPassword });
    //     setLoginFormValues({ ...formDefaultValues.login });
    //     break;
    //   default:
    //     setRegistrationFormValues({ ...formDefaultValues.registration });
    //     setForgotPassWordFormValues({ ...formDefaultValues.forgotPassword });
    //     setLoginFormValues({ ...formDefaultValues.login });
    //     break;
    // }
  };
  const AuthForm = () => {
    if (route === 'rejoin-registration') {
      return <ReJoin />;
    } else if (rejoin) {
      return <ReJoin />;
    } else if (route === 'login') {
      return (
        <LoginForm
          formValues={loginFormValues}
          setFormValues={setLoginFormValues}
        />
      );
    } else if (route === 'registration') {
      return <RegistrationPage />;
    } else if (route === 'forgot-password') {
      return (
        <ForgotPasswordForm
          formValues={forgotPassWordFormValues}
          setFormValues={setForgotPassWordFormValues}
        />
      );
    } else if (route === 'reset-password') {
      return <ResetPasswordForm />;
    } else if (
      route === 'account-activization' ||
      route === 'account-invitation'
    ) {
      return <UserActivationForm />;
    } else if (route === 'account-activization-success') {
      return <UserActivationFormSuccess />;
    } else if (route === 'account-activization-error') {
      return <UserActivationFormError />;
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    route && resestFormValues(route);
  }, [route]);

  const styles = getRegistrationStyles({
    theme: themeLogin,
    isExistPartnerUser: isExistPartnerUser,
  });
  return (
    <ThemeProvider theme={themeLogin}>
      <Box className="Auth-root" sx={styles.authRoot}>
        <Box className="Auth-backgroundImage" sx={styles.authBackgroundImage}>
          <Stack className="Auth-content" spacing={0} sx={styles.authContent}>
            <AuthIntroduction />

            <Box className="formWrapper" sx={styles.formWrapper}>
              <Box>
                <Box
                  className="formWrapperContent"
                  sx={styles.formWrapperContent}
                >
                  <AuthForm />
                </Box>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Registration;
