import { useCallback } from 'react';
import { CommonExpandableTimelineContentProps } from '../components/CommonExpandableTimeline';
export interface IExpandMetaData {
  [keyStr: string]: boolean;
}
export const useExpandableMetaData = <TRecord,>(
  expandMetaData: IExpandMetaData,
  setExpandMetaData: React.Dispatch<React.SetStateAction<IExpandMetaData>>,
  isMultipleExpanded: boolean
) => {
  const handleExpand = useCallback(
    (item: any): void => {
      if (isMultipleExpanded) {
        setExpandMetaData((previousState) => {
          return {
            ...previousState,
            [item.id]: !previousState[item.id],
          };
        });
      } else {
        setExpandMetaData((previousState) => {
          return {
            [item.id]: !previousState[item.id],
          };
        });
      }
    },
    [setExpandMetaData, isMultipleExpanded]
  );

  const isExpanded = useCallback(
    (item: CommonExpandableTimelineContentProps<TRecord>): boolean => {
      return expandMetaData[item.id];
    },
    [expandMetaData]
  );

  const canSetColorSelected = useCallback(
    (item: CommonExpandableTimelineContentProps<TRecord>): boolean => {
      if (isExpanded(item)) {
        return true;
      }
      if (
        item.previousSelectedId &&
        expandMetaData[item.previousSelectedId] === true
      ) {
        return true;
      }
      return false;
    },
    [expandMetaData, isExpanded]
  );

  return {
    expandMetaData,
    handleExpand,
    isExpanded,
    canSetColorSelected,
  };
};
