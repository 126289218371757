import { GridCellParams } from '@mui/x-data-grid-pro';
import {
  CancelImportRequest,
  ChangeCellValueRequest,
  DownloadImportedFileRequest,
  GenerateFinalDataRequest,
  GetColumnsMappingRequest,
  GetImportedDataRequest,
  ExtractColumnsMappingRequest,
  SaveColumnsMappingRequest,
  GetMissingEntitiesRequest,
  CreateMissingEntitiesRequest,
  GetCustomerStaticRequest,
  FinishImportRequest,
  GetFactoringStaticRequest,
  GetCommodityTypesRequest,
  ApplyMissingEntitiesRequest,
  GetDriverStaticRequest,
  ChangeAddressCellValueRequest,
  ExtractAndCreateRequest,
  FuelTypesListRequest,
  GetExpenseCategoriesListRequest,
  GetImportedLineItemsRequest,
} from '../../../models';
import {
  expenceService,
  importService,
  tractorService,
  trailerService,
} from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import TerminalController from '../terminals/TerminalController';
import {
  IMPORT_CUSTOMER_NAME,
  IMPORT_DRIVER_NAME,
  IMPORT_EXPENSE_NAME,
  IMPORT_CONTRACT_RATE_LINE_ITEM_NAME,
  IMPORT_LOAD_NAME,
  IMPORT_TRACTOR_NAME,
  IMPORT_TRAILER_NAME,
} from '../../../contexts/ImportContext';
import TrailerController from '../../../subPages/trailers/TrailerController';
import { importDefaultDateType } from './components/Step1/constants';

class Import {
  // keep customer static data here
  static customerStaticData: Partial<{ [key: string]: any }> = {};

  // keep expense static data here
  static expenseStaticData: Partial<{ [key: string]: any }> = {};

  // keep expense static data here
  static loadStaticData: Partial<{ [key: string]: any }> = {};

  // keep line items static data here
  static lineItemsStaticData: Partial<{ [key: string]: any }> = {};

  // keep factoring static data here
  static factoringStaticData: Partial<{ [key: string]: any }> = {};

  // keep commodity types data here
  static commodityTypes: Partial<{ [key: string]: any }> = {};

  // keep driver static data here
  static driverStaticData: Partial<{ [key: string]: any }> = {};

  // keep tractor static data here
  static tractorStaticData: Partial<{ [key: string]: any }> = {};

  // keep trailer static data here
  static trailerStaticData: Partial<{ [key: string]: any }> = {};

  // keep date format type
  static selectedDateFormat = importDefaultDateType;

  // keep deduction type. This works only for expense import type
  static deductionType = '';

  // keep line item type
  static lineItemType = '';

  // keep terminals data here
  static terminals: Partial<string[]> = [];

  // keep states data here
  static states: Partial<string[]> = [];

  // event fires after file drop or file choose
  static async importFile(
    file: File,
    entityName: string,
    dictionary: string,
    dateFormat: string,
    fileType: string,
    deductionType: string
  ) {
    const response = await importService.importFile(
      file,
      entityName,
      dictionary,
      dateFormat,
      fileType,
      deductionType
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // event fires on cancel or close import modal
  static async cancelImport(
    sessionId: string,
    entityName: string,
    dictionary: string
  ) {
    const request = new CancelImportRequest();
    request.key = sessionId;
    const response = await importService.cancelImport(
      request,
      entityName,
      dictionary
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // event fires on cancel or close import modal
  static async extractAndCreate(
    sessionId: string,
    entityName: string,
    dictionary: string
  ) {
    const request = new ExtractAndCreateRequest();
    request.key = sessionId;
    const response = await importService.extractAndCreate(
      request,
      entityName,
      dictionary
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // takes single object and generates columns which can use DataGrid component for step 1
  static generateColumns(columns: string[]) {
    return columns
      .map((column) => {
        if (column !== 'id') {
          return {
            field: column,
            headerName: column,
            minWidth: 158,
            editable: false,
            sortable: false,
            flex: 1,
          };
        }
      })
      .filter((column) => !!column);
  }

  // getting columns mapping info from backend
  static async getColumnsMapping(
    entityName: string,
    dictionary: string,
    sessionId: string,
    headerList: string[]
  ) {
    const request = new GetColumnsMappingRequest();
    request.key = sessionId;
    request.headerList = headerList;
    const response = await importService.getColumnsMapping(
      request,
      entityName,
      dictionary
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // save and extract new mapping
  static async extractMapping(
    entityName: string,
    dictionary: string,
    sessionId: string,
    mappingData: Partial<{ [key: string]: string | null }>
  ) {
    const request = new ExtractColumnsMappingRequest();
    const bodyParams = {
      importColumnMapping: mappingData,
      key: sessionId,
    };
    const response = await importService.extractColumnsMapping(
      request,
      entityName,
      dictionary,
      bodyParams
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // save and extract new mapping
  static async saveMapping(
    entityName: string,
    dictionary: string,
    sessionId: string,
    mappingData: Partial<{ [key: string]: string | null }>
  ) {
    const request = new SaveColumnsMappingRequest();
    const bodyParams = {
      importColumnMapping: mappingData,
      key: sessionId,
    };
    const response = await importService.saveColumnsMapping(
      request,
      entityName,
      dictionary,
      bodyParams
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // generate data structure for warnings and errors
  static generateTableStructure(response: any) {
    // generating new data structure for feature performance
    // from ..., warnings: [{ columnName: 'name', message: 'message' }]
    // to warnings: { id: { [columnName]: 'message' } }
    // after we can get all errors and warnings efficiently in O(1)
    // this wii be used settings/import/constants/Settings.tsx
    response.content = response.content.map((row: any) => {
      if (row.warnings) {
        const tempWarningsObj: Partial<{ [key: string]: any }> = {};
        row.warnings.forEach((w: any) => {
          tempWarningsObj[w.columnName] = w.message;
        });
        row.warnings = {
          [row.id]: tempWarningsObj,
        };
      }
      if (row.errors) {
        const tempErrorsObj: Partial<{ [key: string]: any }> = {};
        row.errors.forEach((w: any) => {
          tempErrorsObj[w.columnName] = w.message;
        });
        row.errors = {
          [row.id]: tempErrorsObj,
        };
      }
      return row;
    });
    return response;
  }

  // getting imported data
  static async getImportedData(
    entityName: string,
    dictionary: string,
    sessionId: string,
    params: { [key: string]: boolean | number } = {}
  ) {
    const request = new GetImportedDataRequest();
    const { isWarning = false, isError = false, pageNumber = 1 } = params;
    request.key = sessionId;
    if (isWarning) {
      request.isWarning = isWarning as boolean;
    }
    if (isError) {
      request.isError = isError as boolean;
    }
    request.pageNumber = pageNumber as number;
    request.pageSize = 25;
    const response = await importService.getImportedData(
      request,
      entityName,
      dictionary
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return this.generateTableStructure(response);
    }
  }

  // takes single object and generates columns which can use DataGrid component for step 3
  static generateColumnsStep3(columns: string[]) {
    return columns
      .filter((c) => !['id', 'errors', 'warnings'].includes(c))
      .map((column) => {
        return {
          field: column,
          headerName: column,
          width: 158,
          sortable: false,
          editable: true,
          // improve performance keeping Map
          cellClassName: (params: GridCellParams) => {
            /* set warning styles */
            if (params.row.warnings) {
              const arr = params.row.warnings.map((w) => w.columnName);
              if (arr && arr.includes(params.field)) {
                return 'warning';
              }
            }
            /* set errors styles */
            if (params.row.errors) {
              const arr = params.row.errors.map((w) => w.columnName);
              if (arr && arr.includes(params.field)) {
                return 'error';
              }
            }

            return '';
          },
        };
      });
  }

  // download rejected or imported items
  static async downloadImportedFile(
    entityName: string,
    dictionary: string,
    sessionId: string,
    isRejected: boolean
  ) {
    const request = new DownloadImportedFileRequest();
    request.key = sessionId;
    request.isRejected = isRejected;
    const response = await importService.downloadImportedFile(
      request,
      entityName,
      dictionary
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // change specific cell value by given cell id and cell name
  static async changeCellValue(
    entityName: string,
    dictionary: string,
    cellData: Partial<{ [key: string]: any }>
  ) {
    const request = new ChangeCellValueRequest();
    const response = await importService.changeCellValue(
      request,
      entityName,
      dictionary,
      cellData
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // change specific address cell value by given cell id and cell name
  static async changeAddressCellValue(
    entityName: string,
    dictionary: string,
    cellData: Partial<{ [key: string]: any }>
  ) {
    const request = new ChangeAddressCellValueRequest();
    const response = await importService.changeAddressCellValue(
      request,
      entityName,
      dictionary,
      cellData
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // generating data for our table and inserting it to our DB.
  static async generateFinalData(
    entityName: string,
    dictionary: string,
    sessionId: string
  ) {
    const request = new GenerateFinalDataRequest();
    request.key = sessionId;
    const response = await importService.generateFinalData(
      request,
      entityName,
      dictionary
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // filter and see only rows containing either warnings or errors
  static async filterTable(
    entityName: string,
    sessionId: string,
    dictionary: string
  ) {
    const request = new GenerateFinalDataRequest();
    request.key = sessionId;
    const response = await importService.generateFinalData(
      request,
      entityName,
      dictionary
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // get static data for extra step
  static async getMissingEntities(
    entityName: string,
    sessionId: string,
    dictionary: string
  ) {
    const request = new GetMissingEntitiesRequest();
    request.key = sessionId;
    const response = await importService.getMissingEntities(
      request,
      entityName,
      dictionary
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // creating missing entities (working on go back click)
  static async createMissingEntities(
    entityName: string,
    dictionary: string,
    sessionId: string,
    data: any
  ) {
    const request = new CreateMissingEntitiesRequest();
    const bodyParams = {
      missingEntityMapping: data,
      key: sessionId,
    };
    const response = await importService.createMissingEntities(
      request,
      entityName,
      dictionary,
      bodyParams
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // applying missing entities (working on go next click)
  static async applyMissingEntities(
    entityName: string,
    dictionary: string,
    sessionId: string,
    data: any
  ) {
    const request = new ApplyMissingEntitiesRequest();
    const bodyParams = {
      missingEntityMapping: data,
      key: sessionId,
    };
    const response = await importService.applyMissingEntities(
      request,
      entityName,
      dictionary,
      bodyParams
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // get customer static data
  static async getCustomerStaticData() {
    const request = new GetCustomerStaticRequest();
    const response = await importService.getCustomerStatic(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      Import.customerStaticData.customerType = response.customerType.map(
        (ct: { value: string }) => ct.value
      );
      Import.customerStaticData.commodityType = response.commodityType.map(
        (ct: { value: string }) => ct.value
      );
      Import.customerStaticData.useFactoringType =
        response.useFactoringType.map((ct: { value: string }) => ct.value);
      return response;
    }
  }

  // get driver static data
  static async getDriverStaticData() {
    const request = new GetDriverStaticRequest();
    const response = await importService.getDriverStatic(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      Import.driverStaticData.operationType = response.operationType.map(
        (ct: { value: string }) => ct.value
      );
      Import.driverStaticData.employmentType = response.employmentType.map(
        (ct: { value: string }) => ct.value
      );
      Import.driverStaticData.timezones = response.timezoneOptions.map(
        (ct: { value: string }) => ct.value
      );
      Import.driverStaticData.allData = {
        employmentType: response.employmentType,
        operationType: response.operationType,
      };
      return response;
    }
  }

  // get tractor static data
  static async getTractorStaticData() {
    const response = await tractorService.getTractorStaticData();
    if (response instanceof ServiceError) {
      return;
    } else {
      Import.tractorStaticData.brakeType = response.brakeType.map(
        (ct: { value: string }) => ct.value
      );
      Import.tractorStaticData.transmissionType = response.transmissionType.map(
        (ct: { value: string }) => ct.value
      );
      Import.tractorStaticData.isSleeperBerthAvailable =
        response.isSleeperBerthAvailable.map(
          (ct: { value: string }) => ct.value
        );
      return response;
    }
  }

  // get trailer static data
  static async getTrailerStaticData() {
    const response = await trailerService.getTrailerStaticData();
    if (response instanceof ServiceError) {
      return;
    } else {
      Import.trailerStaticData.trailerType = response.trailerType.map(
        (ct: { value: string }) => ct.value
      );
      return response;
    }
  }

  // get state static data
  static async getStates() {
    const response = await tractorService.getStates();
    if (response instanceof ServiceError) {
      return;
    } else {
      Import.states = response.states.map((ct: { name: string }) => ct.name);
      return response;
    }
  }

  // get state static data
  static async getStateCodes() {
    const response = await tractorService.getStates();
    if (response instanceof ServiceError) {
      return;
    } else {
      Import.states = response.states.map(
        (ct: { abbreviation: string }) => ct.abbreviation
      );
      return response;
    }
  }

  // get terminals data
  static async getTerminals() {
    const response = await TerminalController.instance().getTerminals();
    if (response instanceof ServiceError) {
      return;
    } else {
      if (response) {
        Import.terminals = response.map(
          (ct: { companyName: string }) => ct.companyName
        );
      }
      return response;
    }
  }

  // get terminals data
  static async getEquipmentTypes() {
    const response = await TrailerController.instance().getTrailerTypeOptions();
    if (response instanceof ServiceError) {
      return;
    } else {
      if (response) {
        Import.loadStaticData.trailerTypes = response.map(
          (ct: { value: string }) => ct.value
        );
        Import.lineItemsStaticData.trailerTypes = response.map(
          (ct: { value: string }) => ct.value
        );
      }
      return response;
    }
  }

  // get fuel types data
  static async getFuelTypes() {
    const request = new FuelTypesListRequest('');
    const response = await expenceService.getFuelTypesList(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      if (response) {
        Import.expenseStaticData.fuelTypes = response.map(
          (ct: { value: string }) => ct.value
        );
      }
      return response;
    }
  }

  // get expense category types
  static async getExpenseCategoryTypes() {
    const request = new GetExpenseCategoriesListRequest('', 'FUEL');
    const response = await expenceService.getExpenseCategoriesList(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      if (response && response.content) {
        Import.expenseStaticData.expenseCategoryTypes = response.content.map(
          (ct: { itemName: string }) => ct.itemName
        );
      }
      return response;
    }
  }

  // get factoring static data
  static async getFactoringStaticData() {
    const request = new GetFactoringStaticRequest();
    const response = await importService.getFactoringStatic(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      Import.factoringStaticData.facoringCompany = response.map(
        (fc: { displayName: string }) => fc.displayName
      );
      return response;
    }
  }

  // get commodity types
  static async getCommodityTypes() {
    const request = new GetCommodityTypesRequest();
    const response = await importService.getCommodityTypes(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      Import.commodityTypes = response.map(
        (ct: { itemName: string }) => ct.itemName
      );
      return response;
    }
  }

  // finish import. It does only some BE related stuff
  static async finishImport(
    sessionId: string,
    entityName: string,
    dictionary: string
  ) {
    const request = new FinishImportRequest();
    request.key = sessionId;
    const response = await importService.finishImport(
      request,
      entityName,
      dictionary
    );
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // finish line items import.
  static async getImportedLineItems(sessionId: string) {
    const request = new GetImportedLineItemsRequest();
    request.key = sessionId;
    const response = await importService.getImportedLineItems(request);
    if (response instanceof ServiceError) {
      return;
    } else {
      return response;
    }
  }

  // get necessary predefined values
  static getStatic(entityName: string) {
    if (
      entityName === IMPORT_CUSTOMER_NAME &&
      !Object.keys(this.customerStaticData).length
    ) {
      this.getCommodityTypes();
      this.getFactoringStaticData();
      this.getCustomerStaticData();
    }
    if (
      entityName === IMPORT_DRIVER_NAME &&
      !Object.keys(this.driverStaticData).length
    ) {
      this.getDriverStaticData();
    }
    if (
      entityName === IMPORT_TRACTOR_NAME &&
      !Object.keys(this.tractorStaticData).length
    ) {
      this.getTractorStaticData();
      this.getStates();
    }
    if (
      entityName === IMPORT_TRAILER_NAME &&
      !Object.keys(this.trailerStaticData).length
    ) {
      this.getTrailerStaticData();
      this.getStates();
    }
    if (
      entityName === IMPORT_EXPENSE_NAME &&
      !Object.keys(this.expenseStaticData).length
    ) {
      this.getFuelTypes();
      this.getStates();
      this.getExpenseCategoryTypes();
    }
    if (
      entityName === IMPORT_LOAD_NAME &&
      !Object.keys(this.loadStaticData).length
    ) {
      this.getEquipmentTypes();
    }
    if (
      entityName === IMPORT_CONTRACT_RATE_LINE_ITEM_NAME &&
      !Object.keys(this.lineItemsStaticData).length
    ) {
      this.getEquipmentTypes();
      this.getStateCodes();
    }
  }
}

export default Import;
