import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { observer } from 'mobx-react';
import React, { lazy, useEffect, useState } from 'react';
import { GanttDriverLocationMarkersMissing } from '../../../../../models/DTOs/Dispatch/Dispatch';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../../store/root.context';
import { toJS } from 'mobx';
import {
  IHOSDialogloadData,
  ScheduleDriverType,
} from '../../../../../types/DispatchTypes';
import Maps from '../../../../../common/Map';
import { UpdateHOSDialog } from '../../../../../common/UpdateHOS';
// const Maps = lazy(() => import('../../../../../common/Ui/Maps/Maps'));

const GanttChartMap = ({
  onDriverDetailView,
}: {
  onDriverDetailView?: (driverGroupId: string) => void;
}) => {
  const [hosDialogloadData, setHosDialogloadData] =
    useState<IHOSDialogloadData | null>(null);

  const handleFixLocation = (driver?: ScheduleDriverType) => {
    setHosDialogloadData({
      driverId: driver?.driverGroupDetails?.driverId!,
      driverGroupId: driver?.driverGroupDetails?.id!,
    });
  };

  const onCloseUpdateHOSDialog = (): void => setHosDialogloadData(null);

  return (
    <>
      <Maps
        onFixDriverHosLocation={handleFixLocation}
        onDriverDetailView={onDriverDetailView}
        validateDateFilter={true}
      />
      {hosDialogloadData && (
        <UpdateHOSDialog
          onClose={onCloseUpdateHOSDialog}
          loadData={hosDialogloadData}
        />
      )}
    </>
  );
};

export default observer(GanttChartMap);
