import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRoutesLoad } from '../../../../../../contexts/LoadRoutesContext';
import TractorController from '../../../../../../subPages/tractors/Controller';
import {
  LoadItem,
  LoadItemOptionsProps,
} from '../../../../../TimeoffDialog/components/Form/LoadItem';
import ButtonGroupRadio from '../../../../../Ui/ButtonGroup';
import { SingleAutocompleteForm } from '../../../../../Ui/SingleAutocomplete';
import { IHOSDialogloadData, UpdateHOSDialog } from '../../../../../UpdateHOS';
import { ELoadStatus } from '../../../../constants/constants';
import { Trip } from '../../Models';
import RoutesController from '../../RoutesController';
import { useTranslation } from 'react-i18next';
import { getDriverWarningsData } from '@/services/gantt';

export const FormComponent = ({
  trip,
  onDriverSelect,
  onWarningsReady,
  showUpdateHOSDialog,
  setShowUpdateHOSDialog,
}: {
  trip: Trip;
  onDriverSelect: ([]) => void;
  onWarningsReady: ([]) => void;
  showUpdateHOSDialog: boolean;
  setShowUpdateHOSDialog: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setDriverAmountForAssign } = useRoutesLoad();
  const { control, getValues, setValue, watch } = useFormContext();
  const [selectedDriver, setSelectedDriver] = useState<any>(null);
  const [HOSDialogloadData, setHOSDialogloadData] =
    useState<IHOSDialogloadData | null>(null);
  const assignmentType = watch('loadStatus');
  const selectedPrevLoad = watch('prevLoad');

  useEffect(() => {
    if (selectedDriver) {
      onDriverChange(selectedDriver, true);
    }
  }, [assignmentType]);

  useEffect(() => {
    if (selectedDriver && selectedPrevLoad) {
      onDriverChange(selectedDriver);
    }
  }, [selectedPrevLoad]);

  const hidePlanAction = [
    ELoadStatus.LOAD_COMPLETED,
    ELoadStatus.INVOICED,
    ELoadStatus.PAID,
    ELoadStatus.CANCELLED,
  ].includes(trip.tripStatus);

  const getDriverInfo = async (data, prevLoad: { id: string } | null) => {
    const res = await RoutesController.getDriverInfoForLoadAssign(
      data,
      trip.id,
      trip.completed ? trip.tripStatus : assignmentType,
      prevLoad?.id || null
    );
    onDriverSelect(res);
    setHOSDialogloadData({
      driverGroupId: data.id,
      driverId: data?.drivers[0]?.id,
    });
  };

  const getDriverWarnings = async (data) => {
    const res = await RoutesController.getDriverWarningsForLoadAssign(
      data.id,
      trip.id
    );
    onWarningsReady(res);
  };

  const onDriverChange = (data, resetPrevTrip = false) => {
    if (resetPrevTrip) {
      setValue('prevLoad', null);
    }
    if (data) {
      setDriverAmountForAssign(null);
      getDriverInfo(data, resetPrevTrip ? null : selectedPrevLoad);
      getDriverWarningsData(data);
      setSelectedDriver(data);
    }
  };

  const onUpdateHOSDialogResponse = async (): Promise<void> => {
    await getDriverWarningsData(selectedDriver);
  };
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <>
        <SingleAutocompleteForm
          control={control}
          disableClear
          name="driverGroup"
          fieldName="displayName"
          label={t('DriverAssignmentFormDriver')}
          onChangeCb={(data) => onDriverChange(data, true)}
          getOptions={(searchText: string | undefined, pageNumber: number) =>
            TractorController.instance().getDriverGroups(
              searchText,
              pageNumber,
              trip.terminal?.id ? [trip.terminal?.id] : undefined,
              true
            )
          }
        />
        <ButtonGroupRadio
          switcher
          fullwidth
          name="loadStatus"
          disabled={hidePlanAction}
          options={[
            { key: 'ASSIGNMENT_PLANNED', value: t('DriverAssignmentFormPlan') },
            {
              key: 'OFFERED_TO_DRIVER',
              value: t('DriverAssignmentFormAssign'),
            },
          ]}
        />
        <SingleAutocompleteForm
          styledOption={{
            height: '70px !important',
          }}
          control={control}
          disabled={!getValues('driverGroup')}
          disableClear
          name="prevLoad"
          fieldName="title"
          getOptions={(seqNumber, pageNumber) =>
            RoutesController.getPrevLoadsForDriverAssignPlan({
              seqNumber,
              pageNumber,
              driverGroupId: getValues('driverGroup').id,
              loadStatus: trip.completed ? trip.tripStatus : assignmentType,
            })
          }
          renderOption={{
            enableOptionSubtitle: true,
            renderOptionSubTitle: (
              option: LoadItemOptionsProps
            ): JSX.Element => {
              return <LoadItem data={option} />;
            },
          }}
          label={t('DriverAssignmentFormPreviousTrip')}
          sizes={{ xs: 12 }}
        />
        {showUpdateHOSDialog && HOSDialogloadData && (
          <UpdateHOSDialog
            loadData={HOSDialogloadData}
            onClose={() => {
              setShowUpdateHOSDialog(false);
            }}
            onActionResponse={onUpdateHOSDialogResponse}
          />
        )}
      </>
    );
  }
};
