export const Warning = {
  driverHOSWarning: 'Driver HoS or Location is expired',
  MANUAL_TRANSMISSION:
    'The driver has no permission to drive a tractor with manual transmission',
  AIR_BRAKES: 'The driver has no permission to drive a tractor with air brakes',

  TRAILER: 'The driver has no permission to carry a trailer',
  TANKER: 'The driver has no permission to carry a trailer of tanker type',

  TripWarningTypeLOAD_DELAYS: 'Potential Delay Detected at ',
  TripWarningTypeLOAD_DETENTION: 'Potential Detention Detected',
  TripWarningTypeLOAD_UNASSIGNED:
    'Load pickup is getting close and it is not assigned to the driver',
  TripWarningTypeINCOMP_LOAD_DRIVER_WARNING:
    'The driver has no hazmat certification required for this load',
  TripWarningTypeINCOMP_LOAD_TRAILER_WARNING:
    "The assigned trailer's type mismatches with the required equipment type for this load",

  hosOutdated_locationOutdated:
    "Driver's HoS and location needs to be updated.",
  locationOutdated: "Driver's location needs to be updated.",
  hosOutdated: "Driver's HoS needs to be updated.",

  titleAlertDriver: 'Driver',
  titleAlertHosorLocationneedsupdating: 'HoS or Location needs updating',
  titleAlertUpdateDriver: 'Update Driver',
  titleAlertLocationorHOS: 'Location or HoS',
  GanttCardAlertUpdateNow: 'Update Now',

  SetupStopsDelayPotentialdelayisdetectedfor: 'Potential delay is detected for',
  SetupStopsDelayisgettingcloseanditisnotassignedtoadriver:
    'is getting close and it is not assigned to a driver.',
  SetupStopsDelayTripstartdate: 'Trip start date',
  SetupStopsDelayLoadpickup: 'Load pickup',
  setupStopsDelayPaymentisnotreceived: 'Payment is not received',
  SetupDetentionTimeDetentionTime: 'Detention Time:',

  DriverAssignmentWarningWindowFixNow: 'Fix Now',
};
