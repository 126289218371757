import * as yup from 'yup';
const errorMsg = {
  DVIRPreference: 'DVIR Preference is required',
  workWeekType: 'Work Week Type is required',
  weeklyMileageTarget: 'Value must be between 0 and 5,000',
  weeklyRevenueTarget: 'Value must be between 0 and 20,000',
  weeklyEarningsTarget: 'Value must be between 0 and 10,000',
  targetValueType: 'Value must be a number. ',
};

export const GanttPreferencesValidationSchema = yup.object().shape({
  // dvirPreferences: yup
  //   .object()
  //   .shape({
  //     value: yup.string().typeError(errorMsg.DVIRPreference),
  //   })
  //   .required(errorMsg.DVIRPreference)
  //   .typeError(errorMsg.DVIRPreference),
  // workWeekType: yup
  //   .object()
  //   .shape({
  //     value: yup.string().typeError(errorMsg.workWeekType),
  //   })
  //   .required(errorMsg.workWeekType)
  //   .typeError(errorMsg.workWeekType),
  weeklyMileageTarget: yup
    .number()
    .typeError(errorMsg.targetValueType + errorMsg.weeklyMileageTarget)
    .min(0, errorMsg.weeklyMileageTarget)
    .max(5000, errorMsg.weeklyMileageTarget),
  weeklyRevenueTarget: yup
    .number()
    .typeError(errorMsg.targetValueType + errorMsg.weeklyRevenueTarget)
    .min(0, errorMsg.weeklyRevenueTarget)
    .max(20000, errorMsg.weeklyRevenueTarget),
  weeklyEarningsTarget: yup
    .number()
    .typeError(errorMsg.targetValueType + errorMsg.weeklyEarningsTarget)
    .min(0, errorMsg.weeklyEarningsTarget)
    .max(10000, errorMsg.weeklyEarningsTarget),
});

export const GanttValidationSchema = yup
  .object({
    preferencesData: GanttPreferencesValidationSchema,
  })
  .required();
