import { SxProps, Typography } from '@mui/material';

interface IProps {
  id?: string;
  text?: string;
  sx?: SxProps;
}

const ErrorText: React.FC<IProps> = ({ id, text, sx }) => {
  if (!text?.length) return <></>;
  return (
    <Typography id={id} variant="caption" sx={{ color: 'red', ...sx }}>
      {text}
    </Typography>
  );
};

export { ErrorText };
