import { Box, SxProps, Theme } from '@mui/material';
import React, { ReactNode } from 'react';

export const IconBtnWrapper = ({
  id = '',
  disabled,
  children,
  onClick,
  sx,
}: {
  id?: string;
  sx?: SxProps<Theme>;
  width?: number;
  disabled?: boolean;
  height?: number;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => (
  <Box
    id={id}
    component={'span'}
    sx={{
      width: 36,
      height: 30,
      display: 'flex',
      borderRadius: '6px',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: disabled ? '' : 'pointer',
      border: `1px solid`,
      borderColor: disabled
        ? 'action.disabledBackground'
        : 'primary.outlinedRestingBorder',
      ...(disabled && {
        color: 'text.disabled',
        'svg > path': {
          color: 'action.disabled',
          fill: 'currentcolor',
        },
        '.MuiTypography-root': {
          color: 'text.disabled',
        },
      }),
      ...sx,
    }}
    onClick={(e) => !disabled && onClick && onClick(e)}
  >
    {children}
  </Box>
);
