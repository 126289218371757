import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  BulkSettlementCreateRequest,
  ClosePayStatementRequest,
  CombinedNamesListRequest,
  CreateInReviewPayStatementRequest,
  CreateNonTripPaymentRequest,
  DeleteInReviewPayStatementRequest,
  DeleteNonTripPaymentByIdRequest,
  DownloadBulkPayStatement,
  GetBulkUpdateNonTripPaymentsRequest,
  GetPaymentsListByIdRequest,
  GetPayStatementDetailsByIdRequest,
  GetPayStatementListRequest,
  GetPayStatementPdfRequest,
  PaginatedCombinedNamesList,
  PaginatedPayStatementList,
  PaymentList,
  PayStatementData,
  PayStatementsEntityTypeEnum,
  PayStatementStatusEnum,
  PayStatementsTypeEnum,
  PayStatementType,
  QueryParams,
  RemoveEditInReviewPaymentsRequest,
  SendSettlementEmailRequest,
  SettlementIdFilterRequest,
} from '../../models';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { PaginatedResult } from '../../types';

import { annotateNameAsync, IPayStatementService } from '../interfaces';

const getPayStatementListURL =
  '/web/dpm/api/v2/payment/combined-payments/pending/list';

const getPayStatementListExportURL =
  '/web/dpm/api/v2/payment/combined-payments/pending/list/export-to-excel';

const getReviewClosedPayStatementListURL =
  '/web/dpm/api/v2/payment/combined-payments/statement/list';

const getReviewClosedPayStatementListExportURL =
  '/web/dpm/api/v2/payment/combined-payments/statement/list/export-to-excel';

const getNamesListUrl = '/web/people/api/v2/combined-asset/name/filter/list';

const getSettlementIdUrl =
  '/web/dpm/api/v2/payment/combined-payments/seqNumber/filter/list';

const getDriverNonTripPayStatementsListByIdURL =
  '/web/dpm/api/v2/payment/pay-item/list/non-load';

const getTractorNonTripPayStatementsListByIdURL =
  '/web/dpm/api/v2/payment/pay-item/list/non-load';

const bulkUpdateDriverNonTripPaymentsURL =
  '/web/dpm/api/v2/payment/pay-item/update/bulk';

const bulkUpdateTractorNonTripPaymentsURL =
  '/web/dpm/api/v2/payment/pay-item/update/bulk';

const removeDriverInReviewPaymentsURL =
  '/web/dpm/api/v2/payment/settlement-report/update';

const removeTractorInReviewPaymentsURL =
  '/web/dpm/api/v2/payment/settlement-report/update';

const removeDispatcherInReviewPaymentsURL =
  '/web/dpm/api/v2/payment/settlement-report/update';

const getDriverTripPayStatementsListByIdURL =
  '/web/dpm/api/v2/payment/pay-item/list/load';

const getTractorTripPayStatementsListByIdURL =
  '/web/dpm/api/v2/payment/pay-item/list/load';

const getDispatcherTripPayStatementsListByIdURL =
  '/web/dpm/api/v2/payment/pay-item/list/load';

const getDriverGrossYearToDateAmountByIdURL =
  '/web/dpm/api/v2/payment/pay-item/gross-ytd';

const getTractorGrossYearToDateAmountByIdURL =
  '/web/dpm/api/v2/payment/pay-item/gross-ytd';

const getDispatcherGrossYearToDateAmountByIdURL =
  '/web/dpm/api/v2/payment/pay-item/gross-ytd';

const createDriverNonTripPaymentURL = '/web/dpm/api/v2/payment/pay-item/create';

const createTractorNonTripPaymentURL =
  '/web/dpm/api/v2/payment/pay-item/create';

const deleteDriverNonTripPaymentByIdURL =
  '/web/dpm/api/v2/payment/pay-item/delete';

const deleteTractorNonTripPaymentByIdURL =
  '/web/dpm/api/v2/payment/pay-item/delete';

const settlementReportCreateURL =
  '/web/dpm/api/v2/payment/settlement-report/create';

const createBulkSettlementsURL =
  '/web/dpm/api/v2/payment/settlement-report/create/bulk';

const closeReopenDriverInReviewPayStatementURL =
  '/web/dpm/api/v2/payment/settlement-report/patch';

const closeReopenTractorInReviewPayStatementURL =
  '/web/dpm/api/v2/payment/settlement-report/patch';

const closeReopenDispatcherInReviewPayStatementURL =
  '/web/dpm/api/v2/payment/settlement-report/patch';

const getPayStatementDatesURL =
  '/web/preference/api/v2/preference/payStatement/date';

const deleteDriverInReviewPayStatementURL =
  '/web/dpm/api/v2/payment/settlement-report/delete';

const deleteTractorInReviewPayStatementURL =
  '/web/dpm/api/v2/payment/settlement-report/delete';

const deleteDispatcherInReviewPayStatementURL =
  '/web/dpm/api/v2/payment/settlement-report/delete';

const getDriverPayStatementPdfURL =
  '/web/dpm/api/v2/payment/pay-item/get/settlement-report-pdf';

const getTractorPayStatementPdfURL =
  '/web/dpm/api/v2/payment/pay-item/get/settlement-report-pdf';

const getDispatcherPayStatementPdfURL =
  '/web/dpm/api/v2/payment/pay-item/get/settlement-report-pdf';

const payStatementDetailsURL =
  '/web/dpm/api/v2/payment/combined-payments/statement/details';

const sendEmailfURL = 'web/telegram/api/v2/messages/send';

const downloadSettlementsURL =
  'web/dpm/api/v2/payment/pay-item/get/settlement-report-pdf/bulk';

export class PayStatementService extends IPayStatementService {
  @annotateNameAsync
  async getPayStatementList(
    queryParams: GetPayStatementListRequest,
    entityType: PayStatementsEntityTypeEnum,
    fetchingType: string
  ): Promise<PaginatedPayStatementList | undefined> {
    try {
      let url;
      if (fetchingType === 'GET_DATA') {
        url =
          entityType === PayStatementsEntityTypeEnum.pendingPayment
            ? getPayStatementListURL
            : getReviewClosedPayStatementListURL;
      } else {
        url =
          entityType === PayStatementsEntityTypeEnum.pendingPayment
            ? getPayStatementListExportURL
            : getReviewClosedPayStatementListExportURL;
      }

      const response = await httpClient.getRaw(url, queryParams, false, true);
      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getPayStatementDetailsById(
    queryParams: GetPayStatementDetailsByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PayStatementData | undefined> {
    try {
      const response = await httpClient.getRaw(
        payStatementDetailsURL,
        queryParams
      );

      return response?.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  async bulkUpdateNonTripPayments(
    payload: GetBulkUpdateNonTripPaymentsRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PaginatedPayStatementList | undefined> {
    try {
      const url =
        payStatementType === PayStatementsTypeEnum.driver
          ? bulkUpdateDriverNonTripPaymentsURL
          : bulkUpdateTractorNonTripPaymentsURL;
      const result = await httpClient.putRaw(url, undefined, payload, true);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'payStatementNonTripUpdate',
      });
      return result.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'payStatementNonTripUpdate',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getCombinedNameList(
    queryParams: CombinedNamesListRequest
  ): Promise<PaginatedCombinedNamesList | undefined> {
    try {
      const response = await httpClient.getRaw(
        getNamesListUrl,
        queryParams,
        false,
        true
      );
      return response.data;
    } catch (error) {
      Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async getSettlementIdList(
    queryParams: SettlementIdFilterRequest
  ): Promise<PaginatedResult<SettlementIdFilterRequest> | undefined> {
    try {
      const response = await httpClient.getRaw(
        getSettlementIdUrl,
        queryParams,
        false,
        true
      );
      return response.data;
    } catch (error) {
      Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async getTripPaymentsListById(
    queryParams: GetPaymentsListByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PaymentList | undefined> {
    let url;
    switch (payStatementType) {
      case PayStatementsTypeEnum.tractor:
        url = getTractorTripPayStatementsListByIdURL;
        break;

      case PayStatementsTypeEnum.dispatcher:
        url = getDispatcherTripPayStatementsListByIdURL;
        break;

      default:
        url = getDriverTripPayStatementsListByIdURL;
        break;
    }
    try {
      const response = await httpClient.getRaw(url, queryParams);
      return {
        content: response?.data?.payItemDTOs,
        totalAmount: response?.data?.totalAmount,
      };
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getNonTripPaymentsListById(
    queryParams: GetPaymentsListByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<PaymentList | undefined> {
    const url =
      payStatementType === PayStatementsTypeEnum.driver
        ? getDriverNonTripPayStatementsListByIdURL
        : getTractorNonTripPayStatementsListByIdURL;

    try {
      const response = await httpClient.getRaw(url, queryParams);
      return response.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getGrossYearToDateAmountById(
    queryParams: GetPaymentsListByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<number | undefined> {
    let url;
    switch (payStatementType) {
      case PayStatementsTypeEnum.tractor:
        url = getTractorGrossYearToDateAmountByIdURL;
        break;

      case PayStatementsTypeEnum.dispatcher:
        url = getDispatcherGrossYearToDateAmountByIdURL;
        break;

      default:
        url = getDriverGrossYearToDateAmountByIdURL;
        break;
    }

    try {
      const response = await httpClient.getRaw(url, queryParams);
      return response?.data;
    } catch (error) {
      Promise.resolve(undefined);
    }
  }

  @annotateNameAsync
  async createNonTripPayment(
    paylodParams: CreateNonTripPaymentRequest,
    payStatementType: PayStatementsTypeEnum
  ) {
    const url =
      payStatementType === PayStatementsTypeEnum.driver
        ? createDriverNonTripPaymentURL
        : createTractorNonTripPaymentURL;
    try {
      const response = await httpClient.postRaw(url, undefined, paylodParams);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'payStatementNonTripCreate',
      });
      return response?.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'payStatementNonTripCreate',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async deleteNonTripPaymentById(
    queryParams: DeleteNonTripPaymentByIdRequest,
    payStatementType: PayStatementsTypeEnum
  ): Promise<number | undefined> {
    const url =
      payStatementType === PayStatementsTypeEnum.driver
        ? deleteDriverNonTripPaymentByIdURL
        : deleteTractorNonTripPaymentByIdURL;
    try {
      const response = await httpClient.deleteRaw(url, queryParams, null);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'payStatementNonTripDelete',
      });
      return response?.status;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'payStatementNonTripDelete',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async createInReviewPayStatement(
    paylodParams: CreateInReviewPayStatementRequest,
    payStatementType: PayStatementsTypeEnum
  ) {
    const url = settlementReportCreateURL;
    try {
      const response = await httpClient.postRaw(url, undefined, paylodParams);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'payStatementInReviewCreate',
      });
      return response?.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'payStatementInReviewCreate',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async createBulkSettlements(payload: BulkSettlementCreateRequest) {
    try {
      const response = await httpClient.postRaw(
        createBulkSettlementsURL,
        undefined,
        payload
      );
      if (response?.data?.message !== 'NO_PAYMENTS_IN_DATE_RANGE') {
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName: 'settlementCreated',
        });
      }
      return response?.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'settlementCreated',
      });
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async bulkCloseReopenPayStatement(
    paylodParams: ClosePayStatementRequest[],
    payStatementType: PayStatementsTypeEnum,
    disableAlert = false
  ): Promise<PayStatementType[] | undefined> {
    let url;
    switch (payStatementType) {
      case PayStatementsTypeEnum.tractor:
        url = closeReopenTractorInReviewPayStatementURL;
        break;

      case PayStatementsTypeEnum.dispatcher:
        url = closeReopenDispatcherInReviewPayStatementURL;
        break;

      default:
        url = closeReopenDriverInReviewPayStatementURL;
        break;
    }
    try {
      const response = await httpClient.patchRaw(url, undefined, paylodParams);
      if (!disableAlert)
        RootStoreInstence.setNotificationType({
          type: 'SUCCESS',
          serviceName:
            paylodParams?.[0]?.status === PayStatementStatusEnum.CLOSED
              ? 'payStatementInReviewClose'
              : 'payStatementReopen',
        });
      return response?.data;
    } catch (error) {
      if (!disableAlert)
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName:
            paylodParams?.[0]?.status === PayStatementStatusEnum.CLOSED
              ? 'payStatementInReviewClose'
              : 'payStatementReopen',
        });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async removeEditInReviewPayments(
    paylodParams: RemoveEditInReviewPaymentsRequest,
    payStatementType: PayStatementsTypeEnum
  ) {
    let url;
    switch (payStatementType) {
      case PayStatementsTypeEnum.tractor:
        url = removeTractorInReviewPaymentsURL;
        break;

      case PayStatementsTypeEnum.dispatcher:
        url = removeDispatcherInReviewPaymentsURL;
        break;

      default:
        url = removeDriverInReviewPaymentsURL;
        break;
    }
    try {
      const response = await httpClient.putRaw(url, undefined, paylodParams);
      return response?.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async getPayStatementDates(queryParams: QueryParams, skipLoader?: boolean) {
    try {
      const response = await httpClient.getRaw(
        getPayStatementDatesURL,
        queryParams,
        undefined,
        skipLoader
      );

      return response?.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async deleteInReviewPayStatement(
    requestData: DeleteInReviewPayStatementRequest
  ) {
    const url = deleteTractorInReviewPayStatementURL;

    try {
      const response = await httpClient.deleteRaw(url, requestData, null);
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'payStatementInReviewDelete',
      });
      return response?.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'payStatementInReviewDelete',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async downloadBulkPayStatements(requestData: DownloadBulkPayStatement) {
    try {
      const response = await httpClient.getRaw(
        downloadSettlementsURL,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'settlementsBulkDownload',
      });
      return response?.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'settlementsBulkDownload',
      });
      if (error instanceof ComposedError) {
        throw this.getServiceError(error);
      }
      return;
    }
  }

  @annotateNameAsync
  async getPayStatementPdf(
    queryParams: GetPayStatementPdfRequest,
    payStatementType: PayStatementsTypeEnum
  ) {
    let url;
    switch (payStatementType) {
      case PayStatementsTypeEnum.tractor:
        url = getTractorPayStatementPdfURL;
        break;

      case PayStatementsTypeEnum.dispatcher:
        url = getDispatcherPayStatementPdfURL;
        break;

      default:
        url = getDriverPayStatementPdfURL;
        break;
    }
    try {
      const response = await httpClient.getRaw(url, queryParams, true);

      return response?.data;
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return;
    }
  }

  @annotateNameAsync
  async sendEmail(
    requestData: SendSettlementEmailRequest
  ): Promise<string | undefined> {
    try {
      const response = await httpClient.postRaw(
        sendEmailfURL,
        undefined,
        requestData
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'payStatementEmailSend',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'payStatementEmailSend',
      });
      Promise.resolve(undefined);
    }
  }
}
