import clsx from 'clsx';
import { ComponentProps, FC, useState } from 'react';
import { EventTypes } from '../../EventEmitter/EventTypes';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import useEmit from '../../utils/hooks/useEmit';
import { DirtyDetailsPanelManager } from '../DetailsPanel/utils';

const ShakeButton: FC<ComponentProps<typeof ButtonImproved>> = (props) => {
  const [showButtonTooltip, setShowButtonTooltip] = useState(false);

  useEmit({
    [EventTypes.PANEL_SHOULD_SHAKE]: () => {
      //@TODO : Sargis. Remove after load panels refactoring
      if (DirtyDetailsPanelManager.panelName) {
        setTimeout(() => {
          setShowButtonTooltip(false);
        }, 3000);

        setShowButtonTooltip(true);
      }
    },
  });

  return (
    <div
      className={clsx({
        animate__animated: showButtonTooltip,
        animate__pulse: showButtonTooltip,
        animate__infinite: showButtonTooltip,
      })}
    >
      <ButtonImproved {...props} />
    </div>
  );
};

export default ShakeButton;
