import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CallIcon from '@mui/icons-material/Call';
import GarageOutlinedIcon from '@mui/icons-material/GarageOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PersonIcon from '@mui/icons-material/Person';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { PrimaryColorTractorIcon } from '../../Assets/AxeleIcons/AxeleIcons';
import { AccountCard } from '../AccountCard';
import {
  AccountingOptions,
  AccountingOptionsWithoutDelete,
  AccountingOptionsWithoutEdit,
  FactoringMenuOptions,
  FactoringMenuOptionsWithoutDelete,
  FactoringMenuOptionsWithoutEdit,
  FactoringMenuOptionsWithoutEditAndDelete,
  FactoringMenuOtherFactore,
  FactoringMenuOtherFactoreWithoutDelete,
  FactoringMenuOtherFactoreWithoutEdit,
  MenuOptions,
  MenuOptionsWithoutDeactivatePermission,
  MenuOptionsWithoutEditPermission,
  OAuthMenuOptions,
  OAuthMenuOptionsWithoutDeactivatePermission,
  OAuthMenuOptionsWithoutEditPermission,
  loadboardOAuthMenuOptionsWithoutEditPermission,
  loadboardOAuthOptions,
  loadboardOAuthOptionsWithoutDeactivatePermission,
  loadboardOptions,
  loadboardOptionsWithoutDeactivatePermission,
  loadboardOptionsWithoutEditPermission,
} from './accountMenuItems';
export default function Account({
  accountData,
  allTerminals,
  refreshAccountData,
  componentToRenderInsideModal,
  openConfirmationPopup,
  deactivateAccount,
  closeConfirmationPopup,
  integrationType,
  isCustomMenuHandling,
  isDeletePopupOpen,
  editAccount,
  reauthenticate,
  selectedCardForDeletion,
  clickedParamToManage,
  resetFilters,
  resetRequestData,
  axelePermissions,
  customAccountCardStyle,
}: IProps) {
  const [accountName, setAccountName] = useState('');
  const [linkedDrivers, setLinkedDrivers] = useState('');
  const [allDrivers, setAllDrivers] = useState('');
  const [linkedTractors, setLinkedTractors] = useState('');
  const [allTractors, setAllTractors] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [username, setUsername] = useState('');
  const [database, setDatabase] = useState('');
  const [userid, setUserid] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [code, setCode] = useState('');
  const [groupId, setGroupId] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [associatedTerminals, setAssociatedTerminals] = useState('');
  const [footerContent, setFooterContent] = useState('');
  const [accountMapping, setAccountMapping] = useState('');
  const [customerMapping, setCustomerMapping] = useState('');
  const [driverMapping, setDriverMapping] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [charge, setCharge] = useState('');
  const [invoiceFactoringNote, setInvoiceFactoringNote] = useState('');
  const [iscustomer, setCustomer] = useState(false);
  const [activeButton, setActiveButton] = useState('');
  const [reactiveButton, setReactiveButton] = useState('');
  const [hyperLinkInfo, setHyperLinkInfo] = useState('');
  const [footerTextUrlString, setFooterTextUrlString] = useState('');
  const [integrationID, setIntegrationID] = useState('');
  const [carrirCode, setCarrierCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [MC, setMcNumber] = useState('');
  const [DOT, setDotNumber] = useState('');
  const [syncCustomers, setSyncCustomers] = useState('');
  const [totalCustomers, setTotalCustomers] = useState('');
  const [incomeItemsMapping, setIncomeItemsMapping] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [carrierId, setCarrierId] = useState('');
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (operation: any, cardData: any) => {
    handleMenuClose();
    if (integrationType === 'FACTORING' || isCustomMenuHandling === true) {
      const menuActionType = {
        data: accountData,
        type: '',
      };
      switch (operation.label) {
        case 'Deactivate':
          menuActionType.type = 'Deactivate';
          openConfirmationPopup(menuActionType);
          break;
        case 'Edit':
          editAccount(menuActionType);
          break;
        case 'Re-authenticate':
          menuActionType.type = 'Re-authenticate';
          openConfirmationPopup(menuActionType);
          break;
        case 'Archive':
          menuActionType.type = 'Archive';
          openConfirmationPopup(menuActionType);
          break;
        case 'Unarchive':
          menuActionType.type = 'Archive';
          openConfirmationPopup(menuActionType);
          break;
        case 'Restore':
          menuActionType.type = 'Archive';
          openConfirmationPopup(menuActionType);
          break;
        case 'Delete':
          menuActionType.type = 'Delete';
          openConfirmationPopup(menuActionType);
          break;
      }
    } else {
      switch (operation.label) {
        case 'Delete':
          openConfirmationPopup(cardData);
          break;
        case 'Edit':
          editAccount(cardData);
          break;
        case 'Update ELD':
          refreshAccountData(cardData);
          break;
        case 'Re-authenticate':
          reauthenticate(cardData);
        case 'Copy webhook endpoint':
          navigator.clipboard.writeText(
            `${window.location.origin}/backendpublic/load/api/v2/samsara-webhook/upload/${accountData.id}`
          );
          break;
      }
    }
  };
  const accountTitleStyle = {
    color: 'text.secondary',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
    ml: '8px',
  };
  const accountIconStyle = { color: 'primary.main' };

  useEffect(() => {
    resetValues();
    PrepareCustomerList();
  });

  const resetValues = () => {
    setAccountName('');
    setLinkedDrivers('');
    setAllDrivers('');
    setLinkedTractors('');
    setAllTractors('');
    setAccessToken('');
    setUsername('');
    setDatabase('');
    setUserid('');
    setCompanyId('');
    setCode('');
    setGroupId('');
    setFooterTextUrlString('');
    setIntegrationID('');
    setCarrierCode('');
    setCompanyName('');
    setMcNumber('');
    setDotNumber('');
    setSyncCustomers('');
    setTotalCustomers('');
    setIncomeItemsMapping('');
    setCustomerMapping('');
    setAccountMapping('');
    setDriverMapping('');
    setApiKey('');
    setCarrierId('');
  };

  const PrepareCustomerList = () => {
    const terminal_names = getTerminalListFromId(accountData?.terminalIds);
    if (
      integrationType === 'LOADBOARD' &&
      accountData['status'] === 'Pending'
    ) {
      setAssociatedTerminals('NA');
      setUsername('NA');
    } else {
      setAssociatedTerminals(terminal_names.join(','));
    }
    accountData?.data.map((item: any) => {
      switch (item.name) {
        case 'displayName':
        case 'DISPLAY_NAME':
          setAccountName(
            item?.value?.length > 15
              ? `${item.value.slice(0, 15)} ...`
              : item.value
          );
          break;
        case 'linkedDrivers':
          setLinkedDrivers(item.value);
          break;
        case 'allDrivers':
          setAllDrivers(item.value);
          break;
        case 'linkedTractors':
          setLinkedTractors(item.value);
          break;
        case 'allTractors':
          setAllTractors(item.value);
          break;
        case 'USERNAME':
          setUsername(item.value);
          break;
        case 'DATABASE':
          setDatabase(item.value);
          break;
        case 'USER_ID':
          setUserid(item.value);
          break;
        case 'COMPANY_ID':
          setCompanyId(item.value);
          break;
        case 'API_KEY':
          setApiKey(item.value);
          break;
        case 'carrierId':
          setCarrierId(item.value);
          break;
        case 'code':
          setCode(item.value);
          break;
        case 'GROUP_ID':
          setGroupId(item.value);
          break;
        case 'footerText':
          setFooterContent(item.value);
          break;
        case 'customerMapping':
          setCustomerMapping(item.value);
          break;
        case 'accountMapping':
          setAccountMapping(item.value);
          break;
        case 'driverMapping':
          setDriverMapping(item.value);
          break;
        case 'email':
          setEmail(item.value);
          break;
        case 'phoneNumber':
          setPhoneNumber(item.value);
          break;
        case 'charge':
          setCharge(`${item.value}${item.value && '%'}`);
          break;
        case 'invoiceFactoringNote':
          setInvoiceFactoringNote(item.value);
          break;
        case 'footerTextUrl':
          setHyperLinkInfo(item.value);
          break;
        case 'Customer':
          setCustomer(true);
          setHyperLinkInfo(item.value);
          break;
        case 'activate':
          setActiveButton(item.value);
          break;
        case 'reactivate':
          setReactiveButton(item.value);
          break;
        case 'footerTextUrlString':
          setFooterTextUrlString(item.value);
          break;
        case 'INTEGRATION_ID':
          setIntegrationID(item.value);
          break;
        case 'CARRIER_CODE':
          setCarrierCode(item.value);
          break;
        case 'COMPANY_NAME':
          setCompanyName(item.value);
          break;
        case 'MC':
          setMcNumber(item.value);
          break;
        case 'DOT':
          setDotNumber(item.value);
          break;
        case 'syncCustomers':
          setSyncCustomers(item.value);
          setCustomer(true);
          break;
        case 'totalCustomers':
          setTotalCustomers(item.value);
          break;
        case 'incomeItemsMapping':
          setIncomeItemsMapping(item.value);
          break;
        case 'apiKey':
          setApiKey(item.value);
          break;
      }
    });
  };

  const getTerminalListFromId = (arrayOfId: any) => {
    const terminal_name: any = [];
    allTerminals &&
      allTerminals.length > 0 &&
      allTerminals.map((data: any) => {
        arrayOfId?.length > 0 &&
          arrayOfId.map((ele: string) => {
            if (data.id === ele) {
              terminal_name.push(data.name);
            }
          });
      });
    return terminal_name;
  };

  const acountCardData = {
    accountName: `{accountName}`,
    lastUpdate: `Last Updated on {time}`,
    actualData: [
      {
        label: 'Drivers',
        icon: PermIdentityOutlinedIcon,
        value: `${linkedDrivers} out of ${allDrivers}`,
        type: 'text',
      },
      {
        label: 'Tractors',
        icon: PrimaryColorTractorIcon,
        value: `${linkedTractors} out of ${allTractors}`,
        type: 'text',
      },
      {
        label: 'Customer',
        icon: PersonIcon,
        value: `${syncCustomers} out of ${totalCustomers}`,
        hyperLink: '/',
        hyperLinkName: 'Manage',
        key: 'customer',
        type: 'text',
      },
      {
        label: 'Terminal(s)',
        icon: GarageOutlinedIcon,
        value: `${associatedTerminals}`,
        type: 'text',
      },
      {
        label: 'Integration Id',
        icon: GarageOutlinedIcon,
        value: `${integrationID}`,
        type: 'text',
      },
      {
        label: 'Carrier Code:',
        icon: GarageOutlinedIcon,
        value: `${carrirCode}`,
        type: 'text',
      },
      {
        label: 'Company Name:',
        icon: GarageOutlinedIcon,
        value: `${companyName}`,
        type: 'text',
      },
      {
        label: 'MC Number:',
        icon: GarageOutlinedIcon,
        value: `${MC}`,
        type: 'text',
      },
      {
        label: 'Dot Number:',
        icon: GarageOutlinedIcon,
        value: `${DOT}`,
        type: 'text',
      },
      {
        label: 'Access Token',
        icon: GarageOutlinedIcon,
        value: `${accessToken}`,
        key: 'ACCESS_TOKEN',
        type: 'text',
      },
      {
        label: 'Username',
        icon: AccountBoxOutlinedIcon,
        value: `${username}`,
        key: 'USERNAME',
        type: 'text',
      },
      {
        label: 'User ID',
        icon: AccountBoxOutlinedIcon,
        value: `${userid}`,
        key: 'USER_ID',
        type: 'text',
      },
      {
        label: 'Company ID',
        icon: BadgeOutlinedIcon,
        value: `${companyId}`,
        key: 'COMPANY_ID',
        type: 'text',
      },
      {
        label: 'Database',
        icon: StorageOutlinedIcon,
        value: `${database}`,
        key: 'DATABASE',
        type: 'text',
      },
      {
        label: 'API Key',
        icon: StorageOutlinedIcon,
        value: `${apiKey}`,
        key: 'API_KEY',
      },
      {
        label: 'Carrier Id',
        value: `${carrierId}`,
        key: 'carrierId',
        type: 'text',
      },
      {
        label: 'API Key',
        value: `${apiKey}`,
        key: 'apiKey',
        type: 'text',
      },
      {
        label: 'Code',
        icon: StorageOutlinedIcon,
        value: `${code}`,
      },
      {
        label: 'Group ID',
        icon: StorageOutlinedIcon,
        value: `${groupId}`,
      },
      {
        label: 'Email',
        icon: MailOutlineIcon,
        value: `${email}`,
        key: 'email',
        type: 'text',
      },
      {
        label: 'Phone Number',
        icon: CallIcon,
        value: `${phoneNumber}`,
        key: 'phoneNumber',
        type: 'text',
      },
      {
        label: t('factoringCharges'),
        icon: StorageOutlinedIcon,
        value: `${charge}`,
        key: 'charge',
        type: 'text',
      },
      {
        label: 'Factoring Notes On Invoice',
        icon: LocalAtmIcon,
        value: `${invoiceFactoringNote}`,
        key: 'invoiceFactoringNote',
        type: 'text',
      },
      {
        label: 'Manage Funding Accounts Mapping',
        icon: PersonIcon,
        value: `${accountMapping}`,
        key: 'accountMapping',
        type: 'button',
        layOut: 'outlined',
      },
      {
        label: 'Manage Customer Mapping',
        icon: PersonIcon,
        value: `${customerMapping}`,
        key: 'customerMapping',
        type: 'button',
        layOut: 'outlined',
      },
      {
        label: 'Manage Pay Items Mapping',
        icon: PersonIcon,
        value: `${incomeItemsMapping}`,
        key: 'incomeItemsMapping',
        type: 'button',
        layOut: 'outlined',
      },
      {
        label: 'Manage Drivers Mapping',
        icon: PersonIcon,
        value: `${driverMapping}`,
        key: 'driverMapping',
        type: 'button',
        layOut: 'outlined',
      },
      {
        label: 'Activate',
        icon: PersonIcon,
        value: `${activeButton}`,
        key: 'activate',
        type: 'button',
        layOut: 'contained',
      },
      {
        label: 'Reactivate',
        icon: PersonIcon,
        value: `${reactiveButton}`,
        key: 'reactivate',
        type: 'button',
        layOut: 'contained',
      },
      {
        label: 'footerText',
        icon: PersonIcon,
        value: `${footerContent}`,
        key: 'footerText',
        type: 'footerText',
        hyperLink: `${hyperLinkInfo}`,
        footerUrlString: `${footerTextUrlString}`,
      },
    ],
  };

  acountCardData.actualData = acountCardData.actualData.map((data) => {
    const objectData = data;
    if (
      ((objectData.label === 'Drivers' || objectData.label === 'Tractors') &&
        allDrivers === '' &&
        linkedDrivers === '') ||
      (objectData.label === 'Customer' && !iscustomer)
    ) {
      objectData.value = '';
      return objectData;
    } else {
      return data;
    }
  });

  const getMenuOptions = () => {
    switch (integrationType) {
      case 'LOADBOARD':
        let loadboardMenu;
        if (axelePermissions.showEdit && axelePermissions.showRemove) {
          if (accountData.providerId === 4) {
            loadboardMenu = loadboardOAuthOptions;
          } else if (accountData.providerId === 1) {
            loadboardMenu = loadboardOptions;
          } else {
            loadboardMenu = loadboardOptions;
          }
        } else if (!axelePermissions.showEdit && axelePermissions.showRemove) {
          if (accountData.providerId === 4) {
            loadboardMenu = loadboardOAuthMenuOptionsWithoutEditPermission;
          } else loadboardMenu = loadboardOptionsWithoutEditPermission;
        } else if (axelePermissions.showEdit && !axelePermissions.showRemove) {
          if (accountData.providerId === 4) {
            loadboardMenu = loadboardOAuthOptionsWithoutDeactivatePermission;
          } else loadboardMenu = loadboardOptionsWithoutDeactivatePermission;
        }
        return loadboardMenu;
      case 'ACCOUNTING':
        let accountingMenu;
        if (axelePermissions.showEdit && axelePermissions.showRemove) {
          accountingMenu = AccountingOptions;
        } else if (axelePermissions.showEdit && !axelePermissions.showRemove) {
          accountingMenu = AccountingOptionsWithoutDelete;
        } else if (!axelePermissions.showEdit && axelePermissions.showRemove) {
          accountingMenu = AccountingOptionsWithoutEdit;
        }
        return accountingMenu;
      case 'FACTORING':
        let MenuList;
        if (axelePermissions.showEdit && axelePermissions.showRemove) {
          if (accountData.providerId != 1) {
            if (accountData?.archive) {
              MenuList = FactoringMenuOtherFactoreWithoutEdit;
            } else {
              MenuList = FactoringMenuOtherFactore;
            }
          } else if (accountData?.archive) {
            MenuList = FactoringMenuOtherFactoreWithoutEdit;
          } else {
            MenuList = FactoringMenuOptions;
          }
        } else if (!axelePermissions.showEdit && axelePermissions.showRemove) {
          if (accountData.providerId != 1) {
            if (accountData?.archive) {
              MenuList = FactoringMenuOtherFactoreWithoutEdit;
            } else {
              MenuList = FactoringMenuOtherFactoreWithoutEdit;
            }
          } else if (accountData?.archive) {
            MenuList = FactoringMenuOtherFactoreWithoutEdit;
          } else {
            MenuList = FactoringMenuOptionsWithoutEdit;
          }
        } else if (axelePermissions.showEdit && !axelePermissions.showRemove) {
          if (accountData.providerId != 1) {
            if (accountData?.archive) {
              MenuList = FactoringMenuOtherFactoreWithoutEdit;
            } else {
              MenuList = FactoringMenuOtherFactoreWithoutDelete;
            }
          } else if (accountData?.archive) {
            MenuList = FactoringMenuOtherFactoreWithoutEdit;
          } else {
            MenuList = FactoringMenuOptionsWithoutDelete;
          }
        } else {
          if (accountData.providerId != 1) {
            if (accountData?.archive) {
              MenuList = FactoringMenuOtherFactoreWithoutEdit;
            } else {
              MenuList = FactoringMenuOtherFactoreWithoutDelete;
            }
          } else if (accountData?.archive) {
            MenuList = FactoringMenuOtherFactoreWithoutEdit;
          } else {
            MenuList = FactoringMenuOptionsWithoutEditAndDelete;
          }
        }
        if (MenuList[1]?.label) {
          if (accountData.archive) {
            MenuList[1].label = 'Restore';
          } else {
            MenuList[1].label = 'Archive';
          }
        }
        return MenuList;
      case 'ELD':
        let menu;
        if (axelePermissions.showEdit && axelePermissions.showRemove) {
          if (accountData.providerId === 4) {
            menu = OAuthMenuOptions;
          } else {
            const autoAsync = accountData.data.find(
              (data) => data.name === 'AUTO_SYNC'
            );
            if (autoAsync && autoAsync.value === 'true') {
              menu = MenuOptions;
            } else {
              menu = MenuOptions.filter(
                (option) => option.label !== 'Copy webhook endpoint'
              );
            }
          }
        } else if (!axelePermissions.showEdit && axelePermissions.showRemove) {
          if (accountData.providerId === 4) {
            menu = OAuthMenuOptionsWithoutEditPermission;
          } else menu = MenuOptionsWithoutEditPermission;
        } else if (axelePermissions.showEdit && !axelePermissions.showRemove) {
          if (accountData.providerId === 4) {
            menu = OAuthMenuOptionsWithoutDeactivatePermission;
          } else menu = MenuOptionsWithoutDeactivatePermission;
        }
        return menu;
    }
  };
  const accountMappingButton = (data: any) => {
    const menuActionType = {
      data: accountData,
      type: data,
    };
    openConfirmationPopup(menuActionType);
  };
  return (
    <AccountCard
      acountCardData={acountCardData}
      accountName={accountName}
      accountData={accountData}
      MenuOptions={getMenuOptions()}
      handleMenuClick={handleMenuClick}
      handleMenuClose={handleMenuClose}
      handleMenuItemClick={handleMenuItemClick}
      anchorEl={anchorEl}
      open={open}
      isDeletePopupOpen={isDeletePopupOpen}
      closeConfirmationPopup={closeConfirmationPopup}
      deactivateAccount={deactivateAccount}
      accountTitleStyle={accountTitleStyle}
      accountIconStyle={accountIconStyle}
      componentToRenderInsideModal={componentToRenderInsideModal}
      selectedCardForDeletion={selectedCardForDeletion}
      integrationType={integrationType}
      clickedParamToManage={clickedParamToManage}
      accountMappingButton={accountMappingButton}
      resetFilters={resetFilters}
      resetRequestData={resetRequestData}
      customAccountCardStyle={customAccountCardStyle}
    />
  );
}

export interface IProps {
  accountData: any;
  allTerminals?: any;
  getProviderDetails?: any;
  refreshAccountData?: any;
  componentToRenderInsideModal?: any;
  isDeletePopupOpen?: boolean;
  closeConfirmationPopup?: any;
  deactivateAccount?: any;
  openConfirmationPopup?: any;
  isCustomMenuHandling?: boolean;
  editAccount?: any;
  reauthenticate?: any;
  selectedCardForDeletion?: any;
  integrationType: string;
  clickedParamToManage?: (arg1: string, arg2: any) => void;
  resetFilters?: any;
  resetRequestData?: any;
  axelePermissions?: any;
  customAccountCardStyle?: SxProps;
}
