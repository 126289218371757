import Box from '@mui/material/Box';
import AddNewButton from '../../../../common/AddNewButton/AddNewButton';
import TabStripContainer from '../../../../common/Wrappers/TabStripContainer';
import { useEquipment } from '../../../../contexts/EquipmentContext';
import { columns } from '../../../../subPages/tractors/constants';
import { ColumnsMap, View } from '../../../../types';
import ViewForm from './ViewForm';

import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import CustomViewsV2 from '../../../../common/Views/customViewsV2';
import { useThemeResponsive } from '../../../../common/hooks/useThemeResponsive';
import {
  FileTypeIcon,
  TractorsIcon,
  TrailerIcon,
} from '../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import { useEquipmentGridActions } from '../hook/useEquipmentGridActions';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';

export const tractorColumnsInfo = {
  tractorName: true,
  status: true,
  driver: true,
  activeLoad: true,
  terminal: true,
  vin: true,
  licensePlateNumber: true,
  licenseValidTill: true,
  lastLocation: true,
  lastUpdated: true,
  locationSource: true,
  ownershipType: true,
  ownedCompany: true,
  warning: true,
  critical: true,
};

export const trailerColumnsInfo = {
  trailerName: true,
  status: true,
  driver: true,
  activeLoad: true,
  terminal: true,
  vin: true,
  trailerType: true,
  licensePlateNumber: true,
  validTill: true,
  lastLocation: true,
  lastUpdated: true,
  locationSource: true,
  type: true,
  ownershipType: true,
  ownedCompany: true,
  warning: true,
  critical: true,
};

export const tractorFilters = {
  nameFilter: [],
  statusFilter: [
    { key: 'AVAILABLE', value: 'Available' },
    { key: 'ASSIGNED', value: 'Assigned' },
  ],
  inTransitFilter: [],
  safetyIssueFilter: [],
  driverGroupFilter: [],
  vinFilter: [],
  licensePlateFilter: [],
  regExpiry: [],
};

export const trailerFilters = {
  nameFilter: [],
  statusFilter: [
    { key: 'AVAILABLE', value: 'Available' },
    { key: 'ASSIGNED', value: 'Assigned' },
  ],
  inTransitFilter: [],
  safetyIssueFilter: [],
  driverGroupFilter: [],
  vinFilter: [],
  licensePlateFilter: [],
  regExpiry: [],
};

export const tractorView = {
  id: 'tractor',
  shared: true,
  parentId: null,
  name: 'Tractor',
  entity: 'tractor',
  icon: <TractorsIcon />,
  active: true,
  default: true,
  columnFilters: tractorColumnsInfo,
  metaData: tractorFilters,
};

export const trailerView = {
  id: 'trailer',
  shared: true,
  parentId: null,
  name: 'Trailer',
  entity: 'trailer',
  icon: <TrailerIcon />,
  active: true,
  default: true,
  columnFilters: trailerColumnsInfo,
  metaData: trailerFilters,
};

export const formDefaultValuesMap = {
  tractor: tractorView,
  trailer: trailerView,
};

const defaultViews = [{ ...tractorView }, { ...trailerView }];

export const columnsMap: ColumnsMap = {
  tractor: columns,
  trailer: columns,
};

export interface IEquipment {
  tractor: { count: number | undefined; name: string };
  trailer: { count: number | undefined; name: string };
}

export function ViewsSection() {
  const { currentView, setCurrentView, component, dictionary } = useEquipment();
  const { isMobile } = useThemeResponsive();
  const {
    canUploadPermission,
    canAddPermission,
    onUploadHandler,
    onAddHandler,
  } = useEquipmentGridActions();

  return (
    <TabStripContainer>
      <CustomViewsV2
        component={component}
        entities={['tractor', 'trailer']}
        dictionary={dictionary}
        // hook={useEquipment}
        defaultViews={defaultViews}
        formDefaultValuesMap={formDefaultValuesMap}
        viewForm={ViewForm}
        defaultView={tractorView}
        modalTitle={'Create an Equipment View'}
        setCurrentView={(view: View) => {
          setCurrentView(view);
        }}
        currentView={currentView}
      />
      {!isMobile && (
        <Permission contains={[AXELE_PERMISSION_TYPE.EQUIPMENT_ADD]}>
          <Box sx={{ display: 'flex' }}>
            {canUploadPermission && (
              <ButtonImproved
                startIcon={<FileTypeIcon />}
                onClick={() => {
                  if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
                  onUploadHandler();
                }}
              />
            )}
            <AddNewButton
              title={currentView.entity}
              onClick={() => {
                if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
                onAddHandler();
              }}
            />
          </Box>
        </Permission>
      )}
    </TabStripContainer>
  );
}
