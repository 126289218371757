import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
} from '@mui/material';

const customizedComponent = ({ ...props }: DialogProps) => {
  return <Dialog {...props} />;
};

export {
  customizedComponent as Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
};
