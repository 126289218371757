import { Skeleton } from '@mui/lab';
import { Box } from '@mui/material';
import CustomizedTooltips from '../../../ui-kit/components/AxeleTooltip';
import {
  WarningLoadListDelayIcon,
  WarningLoadListDetentionIcon,
  WarningLoadListDriverIcon,
  WarningLoadListIcon,
  WarningLoadListInvoiceIcon,
} from '../../../ui-kit/components/Assets';
import { ReactNode } from 'react';

import { IconTypes, StatusTypes } from '../../../types';
import { LoadListWarning } from '../models/LoadListWarning';

const warningIcons: IconTypes = {
  delay: <WarningLoadListDelayIcon />,
  detention: <WarningLoadListDetentionIcon />,
  driver: <WarningLoadListDriverIcon />,
  invoice: <WarningLoadListInvoiceIcon />,
};

const warningColors: StatusTypes = {
  delay: '#D32F2F',
  detention: '#000000',
  driver: 'text.secondary',
  invoice: 'text.secondary',
};

const styles = {
  wrapper: {
    background: '#ffffff',
    opacity: 1,
    padding: '15px 18px 0',
  },
  multiIconWrap: {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lineWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: '15px',
  },
  warningCount: {
    color: '#F57C00',
    fontWeight: 400,
    fontSize: '14px',
    paddingLeft: '5px',
  },
  multiTitle: (color: string) => ({
    color,
    paddingLeft: '10px',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.15px',
    width: '100%',
  }),
};

function renderMultipleWarnings(
  warning: LoadListWarning,
  keys: string[]
): ReactNode {
  return (
    <Box sx={styles.wrapper}>
      {keys.map((k) => {
        const key = k as keyof LoadListWarning;
        return (
          warning[key] && (
            <Box component="span" key={key} sx={styles.lineWrap}>
              <Box component="span" sx={styles.multiIconWrap}>
                {warningIcons[key]}
              </Box>
              <Box component="span" sx={styles.multiTitle(warningColors[key])}>
                {warning[key]}
              </Box>
            </Box>
          )
        );
      })}
    </Box>
  );
}

export default function WarningComponent({
  warning,
}: {
  warning: LoadListWarning;
}): JSX.Element | null {
  if (warning) {
    const wTypes = Object.keys(warning).filter((key) => !!warning[key]);
    const count = wTypes.length;
    if (count === 0) {
      return null;
    }
    if (count > 2) {
      return (
        <CustomizedTooltips
          titleFirstLine={renderMultipleWarnings(warning, wTypes)}
          placement="bottom"
        >
          <Box component="span" sx={{ display: 'flex' }}>
            <WarningLoadListIcon />
            {/* color: 'warning.dark' didn't take a color */}
            <Box component="span" style={styles.warningCount}>
              +{count}
            </Box>
          </Box>
        </CustomizedTooltips>
      );
    }
    return (
      <>
        {wTypes.map((wType) => {
          const key = wType as keyof LoadListWarning;
          return (
            <Box key={key} component="span" sx={{ marginRight: '5px' }}>
              <CustomizedTooltips
                titleFirstLine={warning[key]}
                placement="bottom"
              >
                <Box>{warningIcons[key]}</Box>
              </CustomizedTooltips>
            </Box>
          );
        })}
      </>
    );
  }
  return <Skeleton variant="text" sx={{ width: '100%' }} />;
}
