import { observer } from 'mobx-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStores } from '../../../../../../store/root.context';
import DeletePopup from '../../../../../../ui-kit/components/DeletePopup';
import { LongMenu } from '../../../../../../ui-kit/components/LongMenu';
import { EPayment3DotMenuType } from '../../../constants/types';
interface Action {
  name: string;
  action: () => void;
  startIcon?: JSX.Element;
}
export const InvoicePaymentActionsSection = observer((data: any) => {
  const [current3DotMenu, setCurrent3DotMenu] =
    useState<EPayment3DotMenuType | null>(null);
  const {
    invoicePaymentStore: { updateEdittingRow, removeRecord },
  } = useStores();

  const onCloseMenu = () => {
    setCurrent3DotMenu(null);
  };

  const { setValue } = useFormContext();

  const options: Action[] = [];

  options.push({
    name: `Delete`,
    action: (): void => {
      setCurrent3DotMenu(EPayment3DotMenuType.DELETE);
    },
  });
  options.push({
    name: `${data?.data?.advance ? 'Remove Advance' : 'Advance'}`,
    action: (): void => {
      updateEdittingRow({
        ...data?.data,
        advance: data?.data?.advance ? false : true,
      });
      makeFormDirty();
    },
  });
  const onDeletePaymentHandler = async () => {
    try {
      removeRecord(data?.data?.invoiceId);
      makeFormDirty();
    } catch {}
    onCloseMenu();
  };
  const makeFormDirty = () => {
    setValue('makeDirty', 'makeDirty', { shouldDirty: true });
  };
  return (
    <>
      {options.length > 0 && (
        <LongMenu options={options} customStyles={{ width: 17 }} />
      )}

      {current3DotMenu === EPayment3DotMenuType.DELETE && (
        <DeletePopup
          open={true}
          title={`Remove Payment`}
          body={`Are you sure, you want to remove the payment from invoice?`}
          onAction={onDeletePaymentHandler}
          onClose={onCloseMenu}
          buttonText={'Remove'}
          width={'300px'}
        />
      )}
    </>
  );
});
