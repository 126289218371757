import React from 'react';
import MultilineCellAxele from '../MultilineCell';

const MultilineCellWithIcon = ({
  title,
  subtitle,
  titleStyle,
  subtitleStyle,
  icon,
  iconStyle,
}: Props) => {
  const wrapperStyles = {
    display: 'flex',
    alignItems: 'center',
  };
  const Icon = icon;
  return (
    <div className="multiline-icon-wrapper" style={wrapperStyles}>
      <Icon sx={iconStyle} />
      <MultilineCellAxele
        title={title}
        subtitle={subtitle}
        titleStyle={titleStyle}
        subtitleStyle={subtitleStyle}
      />
    </div>
  );
};

export default MultilineCellWithIcon;

export interface Props {
  title: string;
  subtitle: string;
  titleStyle: any;
  subtitleStyle: any;
  iconStyle?: any;
  icon: any;
}
