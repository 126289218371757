import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { vendorService } from '../../../api';
import Accordion from '../../../common/Accordion';
import { vendorPage } from '../../../common/PendoClassIDs/constants';
import { AXELE_PERMISSION_TYPE } from '../../../common/Permission';
import { FormAddress } from '../../../common/Ui/AddressField';
import FormDialog from '../../../common/Ui/FormDialog/index';
import TextField from '../../../common/Ui/TextField/TextField';
import {
  CreateVendorContactRequest,
  CreateVendorContactRequestParams,
} from '../../../models';
import { DislikeIcon, LikeIcon } from '../../../ui-kit/components/Assets';
import ButtonGroup_Like_Dislike from '../../../ui-kit/components/ButtonGroup_Like_Dislike';
import { isHasPermission } from '../../../utils';
import { ContactSummary } from '../../customers/models/customer';
import { ContactsSummary } from '../../shared/Contacts';
import {
  contactDefaultData,
  vendorContactValidationSchema,
} from '../constants';
import { VendorSummaryTableRow } from '../models/vendor';
import AddContactForm from './AddContactForm';
import ContactListForm from './ContactListForm';

const preferredProhibitedOptions = [
  {
    key: 'PREFERRED',
    name: 'Preferred',
    iconRenderer: (selected: boolean) => (
      <LikeIcon color={selected ? '#fff' : '#2B64CB'} />
    ),
    getButtonStyle: (selected: boolean) => ({
      backgroundColor: selected ? '#2B64CB' : '#fff',
      borderRadius: '6px',
      borderColor: '#2B64CB',
      marginRight: 5,
    }),
  },
  {
    key: 'PROHIBITED',
    name: 'Prohibited',
    iconRenderer: (selected: boolean) => (
      <DislikeIcon color={selected ? '#fff' : '#F44336'} />
    ),
    getButtonStyle: (selected: boolean) => ({
      backgroundColor: selected ? '#F44336' : '#fff',
      borderColor: '#F44336',
      borderRadius: '6px',
    }),
  },
];

export default function CustomerDetailsForm({
  isCreatePanelOpen,
  updateVendorSummary,
}: {
  isCreatePanelOpen: boolean;
  updateVendorSummary: (data: VendorSummaryTableRow) => void;
}) {
  const [addContactDialogOpen, setAddContactDialog] = useState(false);
  const { control, getValues, setValue } = useFormContext();
  const [id, contacts] = getValues(['id', 'contacts']);
  const numberOfContacts = contacts?.length;
  const primaryContact =
    numberOfContacts &&
    contacts.find((contact: ContactSummary) => contact.isPrimary);
  const primaryContactName = primaryContact?.name;
  const primaryContactEmail = primaryContact?.email;

  useEffect(() => {
    setValue('contact', primaryContactName);
  }, [primaryContactName, setValue]);

  useEffect(() => {
    setValue('email', primaryContactEmail);
  }, [primaryContactEmail, setValue]);

  const onCloseAddContact = () => {
    setAddContactDialog(false);
  };
  const onAddContact = async (contact: ContactSummary) => {
    // @TODO: Nvard - there is an issue with shouldDirty usage, if it is false and first contact is added contacts list is not updated
    let shouldDirty = true;
    if (!isCreatePanelOpen) {
      try {
        await vendorService.createContact(
          new CreateVendorContactRequest({
            ...contact,
            seqNumber: numberOfContacts + 1,
          }),
          new CreateVendorContactRequestParams({ vendorId: id })
        );
        shouldDirty = false;
      } catch (error) {
        return [];
      }
    }
    setAddContactDialog(false);
    if (contact.isPrimary) {
      contacts.forEach((contact: ContactSummary, index: number) => {
        setValue(`contacts.${index}.isPrimary`, false);
      });
    }

    contacts.push(contact);
    setValue('contacts', contacts, { shouldDirty });
    if (!isCreatePanelOpen) {
      const vendorSummary = getValues();
      updateVendorSummary(
        new VendorSummaryTableRow({ ...vendorSummary, contacts })
      );
    }
  };

  const handleOpenAddContactModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAddContactDialog(true);
  };

  const contactsSummaryRenderer = () => {
    return (
      <ContactsSummary
        addClickHandler={handleOpenAddContactModal}
        numberOfContacts={numberOfContacts}
      />
    );
  };
  const noPermissionForEditVendor = !isHasPermission([
    AXELE_PERMISSION_TYPE.VENDOR_EDIT,
  ]);

  return (
    <>
      <Accordion
        summaryRenderer={() => (
          // @ISSUE: Vasil
          <Typography variant="h7" sx={{ color: 'primary.main' }}>
            {t('vendorDetails')}
          </Typography>
        )}
      >
        <TextField
          id={vendorPage + 'VendorName'}
          disabled={noPermissionForEditVendor}
          control={control}
          name="name"
          label="Vendor Name"
          sizes={{ xs: 9 }}
          required
        />
        <ButtonGroup_Like_Dislike
          disabled={noPermissionForEditVendor}
          control={control}
          options={preferredProhibitedOptions}
          name="preferredProhibitedEnum"
          sizes={{ xs: 3 }}
          style={{
            justifyContent: 'flex-end',
            alignSelf: 'start',
            marginTop: '20px',
          }}
        />

        <FormAddress
          control={control}
          name="addressDTO"
          sizes={{ xs: 12 }}
          disabled={noPermissionForEditVendor}
        />
        <TextField
          id={vendorPage + 'Notes'}
          disabled={noPermissionForEditVendor}
          control={control}
          name="notes"
          label="Notes"
          sizes={{ xs: 12 }}
        />
      </Accordion>

      <Accordion
        summaryRenderer={contactsSummaryRenderer}
        expanded={!isCreatePanelOpen}
      >
        {numberOfContacts
          ? contacts.map((_contact: ContactSummary, index: number) => (
              <ContactListForm key={`${index}`} id={index} />
            ))
          : null}
      </Accordion>
      {addContactDialogOpen && (
        <FormDialog
          data={{ ...contactDefaultData }}
          titleText={t('addNewContact')}
          actionLabel={t('addContact')}
          actionLabelId="addContactButton"
          open={addContactDialogOpen}
          onAction={onAddContact}
          disableActionBtnByDefault
          handleClose={onCloseAddContact}
          contentRenderer={() => (
            <AddContactForm numberOfContacts={numberOfContacts} />
          )}
          validationSchema={vendorContactValidationSchema}
        ></FormDialog>
      )}
    </>
  );
}
