/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { annotateNameAsync, ServiceError } from '../interfaces';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { IELDService } from '../interfaces/IELDService';
import {
  ELDRequest,
  ActivateCustomerRequest,
  DeactivateAccountRequest,
  RefreshAccountRequest,
  EditAccountRequest,
  TractorNamesRequest,
  GetDriverSummaryRequest,
  LinkDriverRequest,
  LinkDriverValidateRequest,
  GetAxeleTractorsRequest,
  LinkTractorRequest,
  LinkTractorValidateRequest,
  LinkValidateResponseDTO,
  UnlinkTractorRequest,
  UnlinkDriverRequest,
  CreateDriverRequest,
  CreateTractorRequest,
  CreateMultipleTractorRequest,
  CreateMultipleDriversRequest,
  GetDriverNamesRequest,
  DriverNamesResponseDTO,
  TractorNamesResponseDTO,
  DriverSummaryResponseDTO,
  AxeleTractorResponseDTO,
  ELDResponseDTO,
  LinkTractorResponseDTO,
  LinkDriverResponseDTO,
  UnlinkDriverResponseDTO,
  ActivateCustomerResponseDTO,
} from '../../models/DTOs/ELD/Requests';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

const integrationsURL = `/web/omnibus/api/v2/integrations`;
const eldManageDriverURL = '/web/omnibus/api/v2/drivers';
const eldManageDriverLinkValidateURL =
  '/web/omnibus/api/v2/drivers/link/validate';
const eldManageTractorURL = '/web/omnibus/api/v2/tractors';
const eldManageTractorLinkValidateURL =
  '/web/omnibus/api/v2/tractors/link/validate';
export const eldDriverSummary = 'web/people/api/v2/organization/summary/list';
const axele2TractorURL = 'web/asset/api/ax2/assets/tractor';
const tractorCreationURL = 'web/omnibus/api/v2/tractors/create';
export class ELDService extends IELDService {
  @annotateNameAsync
  async getEldProvdersList(requestData: ELDRequest) {
    try {
      return await httpClient.get<ELDResponseDTO>(
        integrationsURL + `/organization/eld`,
        requestData,
        null
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'providersList',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async activateProvider(payloadData: ActivateCustomerRequest) {
    try {
      return await httpClient.post<ActivateCustomerResponseDTO>(
        integrationsURL,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'activateProvider',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async deleteAccount(payloadData: DeactivateAccountRequest) {
    try {
      const response = await httpClient.delete<DeactivateAccountRequest>(
        integrationsURL +
          `/deactivate?id=${payloadData.id}&integrationType=ELD&providerId=${payloadData.providerId}&organizationId=${payloadData.organizationId}`,
        undefined,
        payloadData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'accountDelete',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'accountDelete',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async refreshAccount(requestData: RefreshAccountRequest, suffixUrl = '') {
    try {
      const response = await httpClient.get<ELDResponseDTO>(
        integrationsURL + `/organization/eld/refresh${suffixUrl}`,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'dataRefresh',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'dataRefresh',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async editAccount(payloadData: EditAccountRequest) {
    try {
      return await httpClient.put<EditAccountRequest>(
        integrationsURL,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'update',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async validateAccount(payloadData: EditAccountRequest) {
    try {
      return await httpClient.put<EditAccountRequest>(
        integrationsURL + `/validate/credentials`,
        undefined,
        payloadData,
        false
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'validation',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async getAllDrivers(requestData: GetDriverNamesRequest) {
    try {
      return await httpClient.get<DriverNamesResponseDTO>(
        eldManageDriverURL,
        requestData,
        null
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'eldDrivers',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async getAllTractors(requestData: TractorNamesRequest) {
    try {
      return await httpClient.get<TractorNamesResponseDTO>(
        eldManageTractorURL,
        requestData,
        null
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'eldTractors',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async unlinkDriver(requestData: UnlinkDriverRequest) {
    try {
      const response = await httpClient.put<UnlinkDriverResponseDTO>(
        eldManageDriverURL + `/unlink`,
        undefined,
        requestData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverUnLink',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'driverUnLink',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async getDriverSummary(requestData: GetDriverSummaryRequest) {
    try {
      return await httpClient.get<DriverSummaryResponseDTO>(
        eldDriverSummary,
        requestData,
        null,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'axeleDrivers',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async linkDriver(requestData: LinkDriverRequest) {
    try {
      const response = await httpClient.put<LinkDriverResponseDTO>(
        eldManageDriverURL + '/link',
        undefined,
        requestData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverLink',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'driverLink',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async linkDriverValidate(requestData: LinkDriverValidateRequest) {
    try {
      const response = await httpClient.post<LinkValidateResponseDTO>(
        eldManageDriverLinkValidateURL,
        undefined,
        requestData,
        null
      );
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'driverLink',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async linkTractorValidate(requestData: LinkTractorValidateRequest) {
    try {
      const response = await httpClient.post<LinkValidateResponseDTO>(
        eldManageTractorLinkValidateURL,
        undefined,
        requestData,
        null
      );
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'driverLink',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async getAxeleTractors(requestData: GetAxeleTractorsRequest) {
    try {
      return await httpClient.get<AxeleTractorResponseDTO>(
        axele2TractorURL + '/list',
        requestData,
        null,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'axeleTractors',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async linkTractor(requestData: LinkTractorRequest) {
    try {
      const response = await httpClient.put<LinkTractorResponseDTO>(
        eldManageTractorURL + '/link',
        undefined,
        requestData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorLink',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorLink',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async unlinkTractor(requestData: UnlinkTractorRequest) {
    try {
      const response = await httpClient.put<UnlinkTractorRequest>(
        eldManageTractorURL + `/unlink`,
        undefined,
        requestData,
        null
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'tractorUnLink',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'tractorUnLink',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async createTractor(requestData: CreateTractorRequest) {
    try {
      const response = await httpClient.post<any>(
        eldManageTractorURL + '/createTractor',
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'multipleTractorCreation',
      });
      return response;
    } catch (error: any) {
      const errorArray = error?.message?.response?.data?.errors;
      if (errorArray) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: errorArray.join(''),
        });
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'tractorCreation',
        });
      }
    }
  }

  @annotateNameAsync
  async createMultipleTractor(requestData: CreateMultipleTractorRequest) {
    try {
      const response = await httpClient.post<any>(
        tractorCreationURL,
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'multipleTractorCreation',
      });
      return response;
    } catch (error: any) {
      const errorArray = error?.message?.response?.data?.errors;
      if (errorArray.length > 0) {
        errorArray.map((item: string) => {
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            message: item,
          });
          RootStoreInstence.setNotificationType({ type: null });
        });
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'tractorCreation',
        });
      }
    }
  }

  @annotateNameAsync
  async createDriver(requestData: CreateDriverRequest) {
    try {
      const response = await httpClient.post<CreateDriverRequest>(
        eldManageDriverURL + '/createDriver',
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'driverCreation',
      });
      return response;
    } catch (error: any) {
      const errorArray = error?.message?.response?.data?.message;
      if (errorArray) {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          message: errorArray.join(''),
        });
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'driverCreation',
        });
      }
      throw error;
    }
  }

  @annotateNameAsync
  async createMultipleDrivers(requestData: CreateMultipleDriversRequest) {
    try {
      const response = await httpClient.post<CreateMultipleDriversRequest>(
        eldManageDriverURL + '/create',
        undefined,
        requestData,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'multipleDriverCreation',
      });
      return response;
    } catch (error: any) {
      const errorArray = error?.message?.response?.data?.message;
      if (errorArray.length > 0) {
        errorArray.map((item: string) => {
          RootStoreInstence.setNotificationType({
            type: 'FAILURE',
            message: item,
          });
          RootStoreInstence.setNotificationType({ type: null });
        });
      } else {
        RootStoreInstence.setNotificationType({
          type: 'FAILURE',
          serviceName: 'multipleDriverCreation',
        });
      }
      throw error;
    }
  }
}
