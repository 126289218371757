import { documentService, notesService } from '@/api';
import { ServiceError } from '@/api/interfaces';
import {
  CreateEntityNotesRequest,
  DeleteEntityNotesRequest,
  GetEntityNotesRequest,
  UpdateEntityNotesRequest,
} from '@/models/DTOs/Notes/Requests';
import { NoteEntityType, NoteFormType } from '@/common/EntityNotes/utils/types';
import {
  AssignDocumentRequest,
  CreateDocument,
  CreateFileRequest,
  DeleteDocumentRequest,
  DocumentType,
  EntityNoteItem,
} from '@/models';
import { FileType } from '@/types';
import StorageManager from '@/StorageManager/StorageManager';

export default class EntityNotesActions {
  static async getEntityNotes(entityId: string, entityType: string) {
    const request = new GetEntityNotesRequest();
    request.entityId = entityId;
    request.entityType = entityType;
    const result = await notesService.getEntityNotes(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async createEntityNote(
    data: NoteFormType,
    entityType: string,
    entityId: string
  ) {
    const request = new CreateEntityNotesRequest();
    request.entityId = entityId;
    request.entityType = entityType;
    request.note = data.note;
    const result = await notesService.createEntityNote(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async updateEntityNote(
    data: NoteFormType,
    entityType: string,
    entityId: string
  ) {
    const request = new UpdateEntityNotesRequest();
    request.entityId = entityId;
    request.entityType = entityType;
    request.note = data.note;
    request.id = data.id;
    const result = await notesService.updateEntityNote(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async deleteEntityNote(id: string, documentId?: string) {
    const request = new DeleteEntityNotesRequest();
    request.id = id;
    request.documentId = documentId;
    const result = await notesService.deleteEntityNotes(request);
    if (result instanceof ServiceError) {
      return;
    }
    return result;
  }

  static async createDocument(file: FileType[]) {
    const request = new CreateFileRequest();

    const response = (await documentService.createDocument(request, file, {
      entityTypes: null,
    })) as any;
    if (response instanceof ServiceError) {
      return;
    }
    return response;
  }

  static async deleteDocument(documentId: string) {
    const request = new DeleteDocumentRequest({
      documentIds: [documentId],
    });

    const response = documentService.deleteDocument(request);
    if (response instanceof ServiceError) {
      return;
    }
    return response;
  }

  static async assignDocument(
    file: CreateDocument,
    note: EntityNoteItem,
    entityType: NoteEntityType
  ) {
    const {
      id: ownerId,
      firstname,
      lastname,
      organizationId,
    } = StorageManager.getUser() || {};
    const assignDocumentRequest = new AssignDocumentRequest({
      description: '',
      ownerName: firstname + ' ' + lastname,
      permission: 'PRIVATE',
      organizationId: organizationId,
      documentType: 'RECEIPT' as DocumentType,
      documentId: file.id,
      fileName: file.fileName,
      uploadDate: file.uploadDate,
      ownerId: ownerId,
      documentTypeId: '',
      attachedEntities: [
        {
          properties: [
            {
              id: note.id,
              title: '',
            },
          ],
          type: `${entityType}_NOTE`,
        },
      ],
    });
    await documentService.assignDocument(assignDocumentRequest);
  }
}
