/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IActivities } from '../../common/LoadTabPanel/tabs/Activity/models';
import { IFinanceRevenueShareRespType } from '../../common/LoadTabPanel/tabs/FinanceOverview/FinanceRevenueShare';
import { MarkersData } from '../../common/Ui/Maps/types';
import {
  AssignDispatcherToLoadRequest,
  AssignDriverToLoadRequest,
  AssignTractorToLoadRequest,
  AssignTrailerToLoadRequest,
  BrokeredUnAssignParams,
  ByLoadIdOnlyRequest,
  ByLoadIdRequest,
  CreateLoadRequest,
  CreateLoadStopRequest,
  CreateLoadStopResponse,
  DeleteLoadManifestRequest,
  DeleteLoadRequest,
  DeleteLoadResponse,
  DeleteLoadStopRequest,
  FindLoadDetailsRequest,
  GetAllStopsRequest,
  GetAllStopsResponse,
  GetHosLastLocationRequest,
  GetInvoiceSummaryListRequest,
  GetLastHosInfoRequest,
  GetLoadAdditionalDetailsRequest,
  GetLoadDetailsActionsRequest,
  GetLoadOriginDestinationRequest,
  GetLoadRefNumberRequest,
  GetLoadRefNumberResponse,
  GetLoadSummaryListRequest,
  GetManifestPreviewRequest,
  GetPrevLoadsRequest,
  GetRemoveLoadManifestDataResp,
  GetTripMapDataRequest,
  GetUpdatedManifestDataRequest,
  ILoadStaticContent,
  ITagsAssignParamsRequest,
  ITagsBulkAssignParamsRequest,
  ITagsBulkAssignPayload,
  LoadDetailsResponse,
  LoadOriginDestinationResponse,
  LoadOverviewRemoveLoads,
  LoadReferenceDetails,
  LoadSummaryListResponse,
  LoadWarningByIdsRequest,
  OnHoldStateLoadRequest,
  OnHoldStateLoadResponse,
  PackageTypesResponse,
  PaginatedLoadListQueryParams,
  PaginatedLoadListRequest,
  PaginatedLoadListRequestNew,
  PaginatedLoadListResponse,
  PaginatedTagsListQueryParams,
  ParsedFileResponse,
  QueryParams,
  ResequenceLoadStopRequest,
  ResequenceLoadStopResponse,
  SplitLoadRequest,
  TagCreateResponseDTO,
  TerminalAssignmentToLoadResponse,
  TripRevenueShare,
  TripStopListCoords,
  UnAssignDriverFromLoadRequest,
  UpdateAllActivitiesPayload,
  UpdateAllActivitiesRequest,
  UpdateLoadAdditionalDetailsRequest,
  UpdateLoadReferenceDetailsRequest,
  UpdateLoadRequest,
  UpdateLoadStopRequest,
  UpdateLoadStopResponse,
  UpdateManualHosLocationRequest,
  UpdateStatusPayload,
  UpdateStatusRequest,
  UpdateStatusResponse,
  ValidateLoadReferenceIdRequest,
  ValidateLoadReferenceIdResponse,
  WarningsForLoadAssignRequest,
  assignCarrierToLoadParams,
} from '../../models';
import { PaginatedGetPreviousLoadResponse } from '../../models/DTOs/Dispatch/Dispatch';
import { ILoadSearchGetSolutionResponse } from '../../models/DTOs/Loadsearch/Model';
import {
  propsTypeAddLoadtoManifest,
  propsTypeCreatManifest,
} from '../../subPages/loadsList/Manifest/CreateManifest';
import {
  IManifestInvoke,
  IManifestInvokeResponse,
  propsTypeCreateManifestForm,
  propsTypeSolutionrequest,
} from '../../subPages/loadsList/Manifest/CreateManifestForm';
import { LoadSummary } from '../../subPages/loadsList/models/LoadSummary';
import { PaginatedResult } from '../../types';
import { docType } from '../../utils/Doc';
import { Service } from './Service';
import { ServiceError } from './helperTypes';

export abstract class ILoadService extends Service {
  abstract getPrevLoadsForDriverAssignPlan(
    requestData: GetPrevLoadsRequest
  ): Promise<PaginatedGetPreviousLoadResponse | ServiceError>;

  abstract getLoadSummaryById(id: ByLoadIdRequest): Promise<any | ServiceError>;

  abstract getLoadsCount(): Promise<any>;

  abstract getPaginatedLoadList(
    queryParams: PaginatedLoadListQueryParams,
    requestData: PaginatedLoadListRequestNew
  ): Promise<PaginatedLoadListResponse | null | ServiceError>;

  abstract exportLoadListToExcel(
    queryParams: PaginatedLoadListQueryParams,
    requestData: PaginatedLoadListRequestNew
  ): Promise<docType | ServiceError>;

  abstract downloadDispatchSheet(
    loadId: string,
    downloadURL?: string
  ): Promise<docType | ServiceError>;
  abstract downloadCarrierSheet(
    tripId: string
  ): Promise<docType | ServiceError>;

  abstract createLoadStop(
    requestData: CreateLoadStopRequest
  ): Promise<CreateLoadStopResponse | null | ServiceError>;

  abstract updateLoadStop(
    requestData: UpdateLoadStopRequest
  ): Promise<UpdateLoadStopResponse | null | ServiceError>;

  abstract deleteLoadStop(
    requestData: DeleteLoadStopRequest
  ): Promise<{} | null | ServiceError>;

  abstract resequenceLoadStop(
    requestData: ResequenceLoadStopRequest
  ): Promise<ResequenceLoadStopResponse | null | ServiceError>;

  abstract getLoadStaticData(
    requestData?: QueryParams
  ): Promise<object | ServiceError | { data: ILoadStaticContent }>;

  abstract getLoadRoutes(id: ByLoadIdRequest): Promise<any | ServiceError>;

  abstract getDriverInfoForLoadAssign(
    driverGroupId: string,
    loadId: string,
    loadStatus: string,
    prevLoadId: string
  ): Promise<any | ServiceError>;

  abstract updateLoadDriverLineItems(payload): Promise<any | ServiceError>;
  abstract updateLoadTractorLineItems(payload): Promise<any | ServiceError>;
  abstract updateLoadDispatcherLineItems(payload): Promise<any | ServiceError>;

  abstract getWarningsForLoadAssign(
    payloadData: WarningsForLoadAssignRequest
  ): Promise<any | ServiceError>;

  abstract getSingleDriverInfoForLoadAssign(
    driverGroupId: string,
    loadId: string
  ): Promise<any | ServiceError>;

  abstract getTractorInfoForLoadAssign(
    tractorId: string,
    loadId: string
  ): Promise<any | ServiceError>;

  abstract getDispatcherInfoForLoadAssign(
    dispatcherId: string,
    loadId: string
  ): Promise<any>;

  abstract assignTractorToLoad(
    requestData: AssignTractorToLoadRequest
  ): Promise<any | ServiceError>;

  abstract assignTrailerToLoad(
    requestData: AssignTrailerToLoadRequest
  ): Promise<any | ServiceError>;

  abstract unassignTrailerFromLoad(
    requestData: AssignTrailerToLoadRequest
  ): Promise<any | ServiceError>;

  abstract unassignTractorFromLoad(
    requestData: AssignTractorToLoadRequest
  ): Promise<any | ServiceError>;

  abstract assignDispatcherToLoad(
    requestData: AssignDispatcherToLoadRequest
  ): Promise<any | ServiceError>;

  abstract unassignDispatcherFromLoad(
    requestData: AssignDispatcherToLoadRequest
  ): Promise<any | ServiceError>;

  abstract unassignDriverFromLoad(
    requestData: UnAssignDriverFromLoadRequest
  ): Promise<any | ServiceError>;

  abstract assignDriverToLoad(
    requestData: AssignDriverToLoadRequest
  ): Promise<any | ServiceError>;

  abstract getLoadWarningsByIds(
    requestData: LoadWarningByIdsRequest
  ): Promise<any | ServiceError>;

  abstract getChassisSearch(
    searchText: string | undefined,
    pageNumber: number,
    skipLoader?: boolean
  ): Promise<object | ServiceError>;

  abstract getAppointmentAndBookingSearch(
    url: string,
    request: any
  ): Promise<object | ServiceError>;

  abstract assignDispatchTerminalToLoad(
    tripId: string,
    terminalId: string
  ): Promise<TerminalAssignmentToLoadResponse | ServiceError>;

  abstract getContainerSearch(
    searchText: string | undefined,
    pageNumber: number,
    skipLoader?: boolean
  ): Promise<object | ServiceError>;

  abstract getLoadLightListByNameSearch(
    requestData: PaginatedLoadListRequest
  ): Promise<PaginatedLoadListResponse | null | ServiceError>;
  abstract createLoad(
    requestData: CreateLoadRequest
  ): Promise<LoadDetailsResponse | ServiceError>;

  abstract createLoadWithAssignedDocument(
    requestData: CreateLoadRequest,
    assignedDocument: File
  ): Promise<LoadDetailsResponse | ServiceError>;

  abstract getLoadAdditionalDetails(
    requestData: GetLoadAdditionalDetailsRequest
  ): Promise<ServiceError | any>;

  abstract findLoadDetails(
    requestData: FindLoadDetailsRequest
  ): Promise<ServiceError | any>;

  abstract findLoadDuplicateDetails(
    requestData: FindLoadDetailsRequest
  ): Promise<ServiceError | any>;

  abstract getLoadOverviewDetails(
    requestData: ByLoadIdRequest
  ): Promise<ServiceError | any>;

  abstract updateLoadReferenceDetails(
    requestData: UpdateLoadReferenceDetailsRequest
  ): Promise<LoadReferenceDetails | ServiceError | any>;

  abstract updateLoadAdditionalDetails(
    requestData: UpdateLoadAdditionalDetailsRequest
  ): Promise<any | ServiceError>;

  abstract validateLoadReferenceId(
    queryParams: ValidateLoadReferenceIdRequest
  ): Promise<ValidateLoadReferenceIdResponse | ServiceError>;

  abstract getParsedLoadDetailsFromFile(
    queryParams: QueryParams,
    payload: File,
    loadParse?: boolean
  ): Promise<ParsedFileResponse | null | ServiceError>;

  abstract getPackageTypes(): Promise<
    PackageTypesResponse[] | null | ServiceError
  >;
  abstract getLoadSummaryList(
    requestData: GetLoadSummaryListRequest
  ): Promise<PaginatedResult<LoadSummaryListResponse> | ServiceError>;

  abstract getLoadRefIdList(
    requestData: GetLoadRefNumberRequest
  ): Promise<PaginatedResult<GetLoadRefNumberResponse> | ServiceError>;

  abstract getLoadOriginDestinationList(
    requestData: GetLoadOriginDestinationRequest
  ): Promise<PaginatedResult<LoadOriginDestinationResponse> | ServiceError>;

  // @TODO @anoush @nvard change any with Load model
  abstract updateLoad(
    requestData: UpdateLoadRequest[]
  ): Promise<PaginatedResult<any> | ServiceError>;

  abstract splitLoad(
    requestData: SplitLoadRequest
  ): Promise<any | ServiceError>;

  abstract getLastHosInfo(
    requestData: GetLastHosInfoRequest,
    skipTrackPromise?: boolean
  ): Promise<any | ServiceError>;

  abstract getIgnoreHosInfo(
    requestData: GetLastHosInfoRequest,
    skipTrackPromise?: boolean
  ): Promise<any | ServiceError>;

  abstract getDriverGroupWarnings(
    driverTeamId: string
  ): Promise<Record<string, Record<string, string[]>> | ServiceError>;

  abstract getHosLastLocation(
    requestData: GetHosLastLocationRequest
  ): Promise<any | ServiceError>;

  abstract updateManualHosLocation(
    requestData: UpdateManualHosLocationRequest
  ): Promise<any | ServiceError>;

  abstract getLoadDetailsActions(
    requestData: GetLoadDetailsActionsRequest
  ): Promise<any | ServiceError>;

  abstract getAllActivities(
    requestData: GetAllStopsRequest
  ): Promise<GetAllStopsResponse | ServiceError>;

  abstract updateAllActivities(
    requestData: UpdateAllActivitiesRequest,
    payload: UpdateAllActivitiesPayload
  ): Promise<string | ServiceError>;

  abstract updateStatus(
    requestData: UpdateStatusRequest,
    payload: UpdateStatusPayload
  ): Promise<UpdateStatusResponse | ServiceError>;

  abstract deleteLoad(
    requestData: DeleteLoadRequest
  ): Promise<DeleteLoadResponse | ServiceError>;
  abstract deleteLoadManifest(
    requestData: DeleteLoadManifestRequest
  ): Promise<DeleteLoadResponse | ServiceError>;

  abstract getLoadMapData(
    requestData: ByLoadIdOnlyRequest
  ): Promise<MarkersData | ServiceError>;

  abstract getTripMapData(
    requestData: GetTripMapDataRequest
  ): Promise<MarkersData | ServiceError>;

  abstract getTotalRevenue(
    requestData: PaginatedLoadListRequestNew
  ): Promise<string | ServiceError>;

  abstract getAuditLogs(requestData: string): Promise<IActivities>;
  abstract getTripListRevenueShare(
    requestData: TripStopListCoords[]
  ): Promise<TripRevenueShare[] | ServiceError>;

  abstract downloadCarrierRateConfirmationSheet(
    loadId: string,
    carrierId: string
  ): Promise<docType | ServiceError>;

  abstract getManifestPreview(
    requestData: GetManifestPreviewRequest
  ): Promise<propsTypeCreateManifestForm | ServiceError>;
  abstract creatManifest(
    requestData: propsTypeCreatManifest
  ): Promise<LoadSummary | ServiceError>;
  abstract manifestReCalculation(
    requestData: propsTypeCreateManifestForm
  ): Promise<LoadSummary | ServiceError>;
  abstract manifestInvoke(
    requestData: Array<IManifestInvokeResponse>
  ): Promise<IManifestInvoke | ServiceError>;
  abstract removeLoadFromManifest(
    requestData: LoadOverviewRemoveLoads
  ): Promise<ServiceError | GetRemoveLoadManifestDataResp>;
  abstract getSolutionDelay(
    payload: propsTypeSolutionrequest
  ): Promise<ILoadSearchGetSolutionResponse | null | ServiceError>;
  abstract getUpdatedManifestLoad(
    payload: GetUpdatedManifestDataRequest
  ): Promise<Array<LoadSummary> | null | ServiceError>;
  abstract addLoadToManifest(
    payload: propsTypeAddLoadtoManifest
  ): Promise<LoadSummary | null | ServiceError>;
  abstract getFinanceRevenueShare(
    payload: ByLoadIdRequest
  ): Promise<IFinanceRevenueShareRespType | null | ServiceError>;
  abstract getCarrierList(name: string | undefined, pageNumber: number): any;
  abstract brokeredUnAssign(request: BrokeredUnAssignParams): any;
  abstract assignCarrierToLoad(request: assignCarrierToLoadParams): any;
  abstract getInvoiceSeqNumberList(
    requestData: GetInvoiceSummaryListRequest
  ): Promise<PaginatedResult<LoadSummaryListResponse> | ServiceError>;
  abstract onHoldStateLoad(
    request: OnHoldStateLoadRequest,
    isManifest: boolean
  ): Promise<OnHoldStateLoadResponse | ServiceError>;
  abstract validateLoadStopsLocations(
    data: Partial<LoadSummary>
  ): Promise<boolean>;
  abstract getPaginatedTagsList(
    queryParams: PaginatedTagsListQueryParams
  ): Promise<PaginatedLoadListResponse | ServiceError>;
  abstract createUserDefinedTag(
    payload: TagCreateResponseDTO
  ): Promise<any | ServiceError>;
  abstract loadAssignmentTag(
    payload: Array<TagCreateResponseDTO>,
    paramsRequest: ITagsAssignParamsRequest
  ): Promise<any | ServiceError>;
  abstract loadBulkAssignmentTag(
    payload: ITagsBulkAssignPayload,
    paramsRequest: ITagsBulkAssignParamsRequest
  ): Promise<any | ServiceError>;

  abstract getLoadTotalMilesById(loadId: string): Promise<any | ServiceError>;
}
