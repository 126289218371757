import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@mui/material';
import { cardFields, letter15 } from './constant';
import { useState } from 'react';
import { ChargifyCardInfo } from '../../views/register/Chargify';
import ButtonImproved from '../../ui-kit/components/ButtonImproved';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

interface ActivatePlanFormProps {
  onClose: () => void;
  handleActivatePlan: (token: string) => void;
  planType: string;
  isLoadingSubscriptionAPI: boolean;
}

const ActivatePlanForm = (props: ActivatePlanFormProps) => {
  const { onClose, handleActivatePlan, planType, isLoadingSubscriptionAPI } =
    props;
  const [cardData, setCardData] = useState<any>({});

  const onActive = () => {
    cardData.chargify.token(
      cardData.chargifyForm.current,
      (token: string) => {
        handleActivatePlan(token);
      },
      (errorResponse: string | Array<any>) => {
        const errorMsg =
          Array.isArray(errorResponse?.errors) &&
          errorResponse?.errors[0]?.length
            ? errorResponse?.errors[0]
            : errorResponse?.errors;
        RootStoreInstence.setNotificationType({
          message: errorMsg,
          type: 'FAILURE',
        });
      }
    );
  };
  return (
    <Dialog onClose={onclose} open={true}>
      <Box
        sx={{
          padding: '24px',
          width: '533px',
          height: 'fit-content',
          maxHeight: '100%',
        }}
      >
        <Box
          sx={{
            height: '46px',
            marginBottom: '24px',
          }}
        >
          <Box sx={(theme) => ({ ...theme.typography.h6, ...letter15 })}>
            Activate your {planType} plan
          </Box>
          <Typography
            sx={{
              fontFamily: 'oxanium',
              fontSize: '13px',
            }}
          >
            Once your payment details are submitted, your subscription will be
            active
          </Typography>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 'calc(100vh - 219px)',
            minWidth: 'fit-content',
          }}
        >
          <ChargifyCardInfo setCardData={setCardData} fields={cardFields} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '20px',
          }}
        >
          <ButtonImproved label="Back" variant="outlined" onClick={onClose} />

          <Button
            variant="contained"
            disabled={isLoadingSubscriptionAPI}
            onClick={onActive}
            sx={{
              ...(isLoadingSubscriptionAPI && {
                background: '#3a4755 !important',
                color: '#757e88 !important',
              }),
              marginLeft: '10px',
            }}
            size="large"
          >
            {isLoadingSubscriptionAPI && (
              <CircularProgress size={22} sx={{ ml: '-16px', mr: '22px' }} />
            )}{' '}
            {isLoadingSubscriptionAPI ? 'Activating' : 'Activate'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default ActivatePlanForm;
