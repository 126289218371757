import moment from 'moment';
import StorageManager from '../../../StorageManager/StorageManager';
import { toFixDigit } from '../../../services/gantt/utility.service';
import { TRIP_STATUSES } from '../../../constants/gantt/gantt.const';
import { defaultDispatch2Filters } from '../constants/dispatch';
import { DEFAULT_WINDOW_TO_FETCH } from '../constants/gantt.const';
import {
  DispatchFiltersName,
  EDriverDetails,
  ESecondaryDetailsPanelType,
  EVENT_STATUS,
  IDispatchFilters,
} from '../constants/types';
import {
  GanttAvailableDataRangeType,
  GanttFilterWithDispatchProps,
  IDriverSchedule,
  ITerminals,
  ScheduleRange,
  ScheduleTimeoffsType,
  SearchLoadBoardsType,
} from '../types';
import {
  IGanttFilters,
  ScheduleTripType,
  IDriverSceduleRequestPayload,
} from '../../../types/DispatchTypes';
import { DateTimezoneUtils } from '../../../utils/Timezone.utils';
import { GridColDefSelf } from '../../../types';
import { driverColumnFileds, driverColumnNameByField } from '../config';
import { ELoadStatus } from '../../../common/LoadTabPanel/constants/constants';
import { UpdateStatusPayload } from '../../../models/DTOs/Loads/Requests';
import { tripService } from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  UpdateTripStatusResponse,
  UpdateTripStatusRequest,
  UpdateTripStatusPayload,
} from '../../../models/DTOs/Trip/Requests';
import {
  isResourceTimeOffActive,
  isTripInProgress,
  isTripTypeGap,
} from '../../../services/gantt';

export const DriverColumnHeaderStyle = (data: any) => {
  return {
    [data.headerElement.style.fontSize]: '12px',
    [data.headerElement.style.fontWeight]: '500',
    [data.headerElement.style.fontFamily]: 'Poppins',
  };
};

export const formatDriverScheduleFilter = ({ filters }: IDispatchFilters) => {
  const filter = filters as any;
  return {
    primaryDispatcher: filter?.primaryDispatcher?.join(','),
    operationType: filter?.operationType?.value,
    startDate: filter?.dateFilter?.dateRange?.[0],
    sort: filter?.sortFilter?.key,
  };
};

export const formatDriverSchedulerFilters = (
  filters?: IGanttFilters
): IDriverSceduleRequestPayload => {
  return {
    startDate: filters?.startDate,
    terminalIds: filters?.terminalIds,
    window: DEFAULT_WINDOW_TO_FETCH,
    timezone: localStorage?.getItem('preferredTimeZone') || 'America/New_York',
    operationType: filters?.operationType,
    dispatcherIdList: filters?.dispatcherIdList,
    driverIds: filters?.driverIds,
    hasViolation: filters?.hasViolation,
    needLoad: filters?.needLoad,
    groupMode: filters?.groupMode,
    employmentType: filters?.employmentType,
    hasTimeOff: filters?.hasTimeOff,
  };
};

export const formatDriverScheduleParams = ({ sort }: { sort?: string }) => {
  return {
    sort: sort,
    pageNumber: 1,
    organizationId: StorageManager?.getUser()?.organizationId,
  };
};

export const formatGanttFilter = (
  filters: GanttFilterWithDispatchProps
): IGanttFilters => {
  const sort = filters?.filters?.sortDirectionFilter ? '' : '-';
  const startDate = (filters?.filters as any)?.[DispatchFiltersName.startDate]
    ?.dateRange?.[0];
  return {
    startDate: startDate,
    terminalIds: filters?.terminalIds?.map?.((e: ITerminals) => e?.id),
    window: DEFAULT_WINDOW_TO_FETCH,
    timezone: StorageManager?.getUser()?.preferredTimeZone,
    operationType: (filters?.filters as any)?.operationType?.key ?? null,
    dispatcherIdList: (filters?.filters as any)?.primaryDispatcher?.map?.(
      (e: any) => e?.key
    ),
    driverIds: (filters?.filters as any)?.driverFilter?.map?.(
      (e: any) => e?.key
    ),
    sort: `${sort}${filters?.filters?.sortFilter?.key}`,
    pageNumber: filters?.pageNumber,
    organizationId:
      filters?.organizationId ?? StorageManager?.getUser?.()?.organizationId,
    hasViolation:
      filters?.filters?.driverDetails?.some?.(
        ({ key }) => key === EDriverDetails.hasViolation
      ) ?? null,
    needLoad:
      filters?.filters?.driverDetails?.some?.(
        ({ key }) => key === EDriverDetails.needLoad
      ) ?? null,
    groupMode: filters?.filters?.operationMode?.key ?? null,
    employmentType: filters?.filters?.employmentType?.key ?? null,
    hasTimeOff:
      filters?.filters?.driverDetails?.some?.(
        ({ key }) => key === EDriverDetails.timeOff
      ) ?? null,
  };
};

export const getWindowSizeDateRange = (
  date: Date | string
): GanttAvailableDataRangeType => {
  const start = new Date(date);
  const end = new Date(date);
  return {
    startDate: new Date(
      new Date(start.setDate(start.getDate() - 7))?.setHours?.(0, 0, 0, 0)
    ),
    endDate: new Date(
      new Date(end.setDate(end.getDate() + 14))?.setHours?.(0, 0, 0, 0)
    ),
  };
};

export const formatLoadBoardData = (data: any): SearchLoadBoardsType => ({
  origin: data?.eventRecord?.origin,
  destination: data?.eventRecord?.destination,
  driverGroupId: data?.eventRecord?.resourceId,
  startTime: data?.eventRecord?.startDate,
  endTime: data?.eventRecord?.isEndGap ? null : data?.eventRecord?.endDate,
  driverId: data?.resourceRecord?.driverGroupDetails?.driverId,
  groupName: data?.resourceRecord?.driverGroupDetails?.groupName,
});

export const formatTripsForSchedule = ({
  trips,
  allTripsSize,
  driverGroupIdsToMap,
}: {
  trips: Array<ScheduleTripType>;
  allTripsSize?: number;
  driverGroupIdsToMap?: Array<string>;
}): Map<string, ScheduleTripType[]> => {
  const tripsMap = new Map();
  driverGroupIdsToMap?.forEach((driverGroupId) => {
    tripsMap?.set(driverGroupId, []);
  });
  trips?.map((trip, i: number) => {
    const existedTrips = tripsMap?.get(trip?.resourceId);
    const formattedTrip = {
      ...trip,
      id: `${trip?.resourceId}${trip?.id}`,
      tripPrimaryId: trip?.id,
      endDate: validateTripEndDate(trip),
      isEndGap: Boolean(trip?.endDate && trip?.destination) ? false : true,
      miles: trip?.needLoadStatus
        ? trip?.destination
          ? toFixDigit(trip?.deadheadMileage as number)
          : null
        : toFixDigit(trip?.miles as number),
      middleText: '',
      status: trip?.needLoadStatus ? TRIP_STATUSES.NEED_LOAD : trip?.status,
      type: trip?.needLoadStatus ? TRIP_STATUSES.TRIP : trip?.type,
      isDelayed: trip?.onHold,
    };
    if (trip?.type === EVENT_STATUS.EMPTY || trip?.needLoadStatus)
      formattedTrip['id'] = `${Math.random()}`;
    if (formattedTrip?.isEndGap) {
      formattedTrip.status = '';
      formattedTrip.type = TRIP_STATUSES.EMPTY;
    }
    tripsMap?.set(
      trip?.resourceId,
      existedTrips?.length ? [...existedTrips, formattedTrip] : [formattedTrip]
    );
  });
  return tripsMap;
};

export const validateTripEndDate = (trip: ScheduleTripType): Date | string => {
  const { startDate, endDate } = trip;
  if (isTripEndGap(trip))
    return moment(moment(removeTimezoneFromDateString(startDate)).format())
      .add?.(6, 'hours')
      .format?.();
  const startTime = startDate?.split?.('[')?.[0];
  const endTime = endDate?.split?.('[')?.[0];
  if (moment(startTime).isSame(endTime))
    return moment(startTime).add(1, 'minutes').format();
  return endDate;
};

const isTripEndGap = (trip: ScheduleTripType) =>
  !(trip?.endDate && trip?.destination);

export const getScheduleListFromMap = (
  entityMap: IDriverSchedule['trips'] | IDriverSchedule['timeoffs']
): Array<ScheduleTripType | ScheduleTimeoffsType> => {
  if (!entityMap || !entityMap?.size) return [];
  const allLists = Array?.from(entityMap?.values?.() as any) as Array<
    ScheduleTimeoffsType | ScheduleTripType
  >;
  const lists = [];
  for (const list of allLists) lists?.push(...(list as any));
  return lists;
};

export const formatTimeoffsForSchedule = ({
  timeoffs,
  driverGroupIdsToMap,
}: {
  timeoffs?: Array<ScheduleTimeoffsType>;
  driverGroupIdsToMap?: Array<string>;
}): Map<string, ScheduleTimeoffsType[]> => {
  const timeoffsMap = new Map();
  driverGroupIdsToMap?.forEach((driverGroupId) => {
    timeoffsMap?.set(driverGroupId, []);
  });
  timeoffs?.forEach((timeoff) => {
    const existedTrips = timeoffsMap?.get(timeoff?.resourceId);
    (timeoff.id = `${timeoff.resourceId}${
      timeoff?.timeoffId ?? timeoff?.startDate
    }`),
      (timeoff.durationToDisplay = timeoff?.duration);
    timeoffsMap?.set(
      timeoff?.resourceId,
      existedTrips?.length ? [...existedTrips, timeoff] : [timeoff]
    );
  });
  return timeoffsMap;
};

export const getMaxIndexId = (lists: Array<ScheduleTripType>) => {
  if (!lists?.length) return 1;
  let maxIndex = 0;
  for (const list of lists) {
    maxIndex = Math.max(maxIndex, Number(list?.id));
  }
  return maxIndex + 1;
};

export const generateEmptyTripAssignmentSchedule = (
  trip: ScheduleRange,
  window: ScheduleRange
): ScheduleRange => {
  const assignTripDates = { ...trip };
  if (moment(trip.startDate).isBefore(window.startDate))
    assignTripDates.startDate = window.startDate;
  if (moment(trip.endDate).isAfter(window.endDate))
    assignTripDates.endDate = window.endDate;
  const customEndDate = moment(assignTripDates.startDate).add(1, 'day');
  if (moment(customEndDate).isBefore(assignTripDates.endDate))
    assignTripDates.endDate = customEndDate.format('YYYY-MM-DD HH:mm');
  return assignTripDates;
};

export const isTripTooltipAllowed = (trip: ScheduleTripType): boolean =>
  trip?.isTripSelect !== true;

export const removeTimezoneFromDateString = (date: string) => {
  let d = date?.split?.('[');
  if (!d?.length) return date;
  if (d?.length === 1) return d?.[0];
  const isTimezoneAhead = d?.[0]?.split?.('+');
  let splitType = '+';
  if (isTimezoneAhead?.length === 1) splitType = '-';
  d = d?.[0]?.split?.(splitType);
  d?.pop?.();
  return d?.join?.('-');
};

export const getGanttColumnHeadeName = (
  field: keyof typeof driverColumnNameByField
) => driverColumnNameByField[field];

export const getDispatchGanttColumnHeaderNames = (): GridColDefSelf[] => {
  const names: GridColDefSelf[] = [];
  Object.values(driverColumnFileds)?.forEach((field) => {
    if (field !== driverColumnFileds?.driver_name)
      names?.push({
        field,
        headerName: getGanttColumnHeadeName(
          field as keyof typeof driverColumnNameByField
        ),
        hide: false,
      });
  });
  return names;
};

export const getGanttColumnFileds = (columns: GridColDefSelf[]): string[] => {
  const hiddenCols: string[] = [];
  columns.forEach?.((c) => {
    if (c.hide) hiddenCols.push(c.field);
  });
  return hiddenCols;
};

export const unassignTripFromDriver = async ({
  payload,
  tripId,
}: {
  tripId: string;
  payload?: UpdateStatusPayload;
}): Promise<ServiceError | UpdateTripStatusResponse> =>
  tripService.updateStatus(
    new UpdateTripStatusRequest({
      tripId,
      status: ELoadStatus.AVAILABLE,
    }),
    new UpdateTripStatusPayload(payload)
  );

export const getGanttGridColumnsVisiblityModel = (config: {
  [key: string]: boolean;
}): GridColDefSelf[] => {
  if (!config) return [];
  const keys = Object.keys(config);
  const newHiddenColumns: GridColDefSelf[] = [];
  keys?.forEach((field) => {
    newHiddenColumns?.push({
      field,
      headerName: getGanttColumnHeadeName(field as any),
      hide: !config[field],
    });
  });
  return newHiddenColumns;
};

export const isGapNextTripDispatched = (
  gap: ScheduleTripType,
  trips: ScheduleTripType[],
  timeoffs: ScheduleTimeoffsType[]
): boolean => {
  if (!isTripTypeGap(gap)) return false;
  const nextId = gap?.nextId;
  if (!nextId) return false;
  const trip = trips?.find?.((trip) => trip?.tripPrimaryId === nextId);
  if (trip && isTripInProgress(trip!)) return true;
  const nextTimoff = timeoffs?.find?.((t) => t?.timeoffId === nextId);
  if (nextTimoff && isResourceTimeOffActive(nextTimoff)) return true;
  return false;
};
