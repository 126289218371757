import { Stack } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { ActionBtnStyles } from '../styles/evaluation.style';
import { useStores } from '../../../store/root.context';
import { observer } from 'mobx-react';
import { isResourceLocked } from '../../../views/optymization/services/recommendTrips.service';
import { IRecommendedResource } from '../../../views/optymization/types/gantt.types';
import ReassignResource from '../../../views/optymization/components/generic/ReassignResource';
import { getResourceReassignOptions } from '../../../views/optymization/services/ganttColumns.service';
import {
  LockButton,
  RejectButton,
} from '../../../views/optymization/components/generic';
import { ReassignOptionTypes } from '../../../views/optymization/types/recommendTrips.types';
import { OPTIMIZATION_TYPES } from '../../../store/EvaluationMode/Evaluation.store';

interface IOptimizationActions {}

const OptimizationActions: React.FC<IOptimizationActions> = (props) => {
  const {
    evaluationStore: {
      onRejectAction,
      onLockAction,
      isActionDisabled,
      driverDetailsToEvaluate,
      stopEvaluation,
      optimizationType,
      setActionTarget,
    },
    bulkOptymizationStore: { setResourceToReassign },
  } = useStores();

  const lockBtnRef = useRef<HTMLElement | null>(null);

  const actionDisabled = useMemo(() => {
    if (isActionDisabled) return true;
    return false;
  }, [isActionDisabled]);

  const isDriverLocked: boolean = useMemo(() => {
    if (isResourceLocked(driverDetailsToEvaluate as IRecommendedResource))
      return true;
    return false;
  }, [driverDetailsToEvaluate]);

  const isDriverRejected: boolean = useMemo(() => {
    if (driverDetailsToEvaluate?.engineMultiTripOutput?.isRejected) return true;
    return false;
  }, [driverDetailsToEvaluate]);

  useEffect(() => {
    setActionTarget({
      target: lockBtnRef?.current,
      actionReference: 'anchorElement',
    } as any);
  }, [lockBtnRef]);

  return (
    <Stack
      direction={'row'}
      display={'flex'}
      gap="8px"
      justifyContent={'end'}
      marginTop={'8px'}
    >
      <RejectButton
        style={ActionBtnStyles}
        disabled={actionDisabled || isDriverLocked}
        onClick={onRejectAction}
        label={isDriverRejected ? 'Rejected' : 'Reject'}
        iconStyles={{ width: '18px', height: '18px' }}
        isRejected={isDriverRejected}
      />
      {optimizationType !== OPTIMIZATION_TYPES.POSSIBLE_RESOURCES && (
        <ReassignResource
          disabled={actionDisabled}
          btnStyles={ActionBtnStyles}
          label="Swap"
          iconStyles={{ width: '18px', height: '18px' }}
          options={getResourceReassignOptions(driverDetailsToEvaluate!)}
          onSelect={(val: ReassignOptionTypes) => {
            stopEvaluation();
            setResourceToReassign(val);
          }}
        />
      )}
      <LockButton
        ref={lockBtnRef}
        disabled={actionDisabled || isDriverRejected}
        btnStyles={ActionBtnStyles}
        label={isDriverLocked ? 'Locked' : 'Lock'}
        isLocked={isDriverLocked}
        iconStyles={{ width: '18px', height: '18px' }}
        onClick={onLockAction}
      />
    </Stack>
  );
};

export default observer(OptimizationActions);
