import React, { useEffect, useMemo, useState } from 'react';
import { DirtyDetailsPanelManager } from '../../../../common/DetailsPanel/utils';
import { EDispatchPanelTab, IDispatchPanelTab } from '../../constants/types';
import TabsV2 from '../../../../ui-kit/components/TabsV2';
import { t } from 'i18next';

const getTabItems = (): IDispatchPanelTab[] => {
  return [
    {
      key: EDispatchPanelTab.TIMELINE,
      label: t('EDispatchPanelTabTIMELINETimeline'),
      iconName: 'SpeakerNotes',
      source: 'MUIcons',
    },
    {
      key: EDispatchPanelTab.PREFERENCES,
      label: t('EDispatchPanelTabPREFERENCESPreferences'),
      iconName: 'ManageAccountsOutlined',
      source: 'MUIcons',
    },
  ];
};

const DetailsTabStrips: React.FC<{
  defaultTab?: EDispatchPanelTab;
  onChange: (selected: EDispatchPanelTab) => void;
  keys: EDispatchPanelTab[];
}> = ({ onChange, defaultTab, keys }) => {
  const tabItems = useMemo(() => {
    return getTabItems().filter((item: IDispatchPanelTab) =>
      keys.includes(item.key)
    );
  }, [keys]);

  const getTab = (tabKey?: EDispatchPanelTab) =>
    tabItems.find(({ key }) => key === tabKey) || tabItems[0];

  const [selected, setSelected] = useState(getTab(defaultTab));

  useEffect(() => {
    setSelected(getTab(defaultTab));
  }, [defaultTab]);

  const handleStripChange = (selected: IDispatchPanelTab) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    setSelected(selected);
    onChange(selected.key);
  };

  return (
    <TabsV2
      keyName="key"
      tabs={tabItems}
      setSelectedTab={(tab: IDispatchPanelTab) => {
        handleStripChange(tab);
      }}
      selectedTab={selected.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

const getTabByKey = (key: EDispatchPanelTab): IDispatchPanelTab | undefined => {
  return getTabItems().find((tab) => tab.key === key);
};

export const tabStripRendererByKeyFS = (key: EDispatchPanelTab) => {
  return (
    <TabsV2
      keyName="key"
      tabs={[getTabByKey(key)].filter(Boolean) as IDispatchPanelTab[]}
      selectedTab={getTabByKey(key)?.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

export default DetailsTabStrips;
