import {
  GetExpenseCategoriesRequest,
  ExpenseCategory,
  GetPaymentTypesRequest,
  PaymentType,
  GetDriverPaymentTermsRequest,
  DriverPaymentTermsGrouped,
  CreateDriverPaymentTerm,
  PaymentPerLoad,
  ExistsPayTypeItemByIdRequest,
  QueryParams,
} from '../../models';

import {
  getExpenseCategoriesURL,
  getPaymentTypesURL,
  getDriverPaymentTermsURL,
  getDriverLoadRatePaymentURL,
  createDriverPaymentTermsURL,
  deleteLoadRateByIDs,
  existsPayTypeItemByIdURL,
  bulkUpdateDriverPaymentTermsURL,
  getTractorPaymentTermsURL,
  createTractorPaymentTermsURL,
  deleteTractorLoadRateByIDs,
  bulkUpdateTractorPaymentTermsURL,
  createWebUserPaymentTermsURL,
  deleteWebUserLoadRateByIDs,
  getWebUserPaymentTermsURL,
  bulkUpdateWebUserPaymentTermsURL,
} from './requestConstants';

import { annotateNameAsync, IPaymentTermsService } from '../interfaces';
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import { boolean } from 'yup/lib/locale';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';

export class PaymentTermsService extends IPaymentTermsService {
  @annotateNameAsync
  async getExpenseCategories() {
    const requestData = new GetExpenseCategoriesRequest();
    try {
      return await httpClient.get<ExpenseCategory[]>(
        getExpenseCategoriesURL,
        requestData,
        ExpenseCategory,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getPaymentTypes() {
    const requestData = new GetPaymentTypesRequest();
    try {
      return await httpClient.get<PaymentType[]>(
        getPaymentTypesURL,
        requestData,
        PaymentType,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverPaymentTerms(driverId: number) {
    const requestData = new GetDriverPaymentTermsRequest(driverId);
    try {
      return await httpClient.get<DriverPaymentTermsGrouped>(
        getDriverPaymentTermsURL,
        requestData,
        DriverPaymentTermsGrouped,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createDriverPaymentTerms(paymentTerm: CreateDriverPaymentTerm) {
    try {
      const response = await httpClient.post<PaymentPerLoad>(
        createDriverPaymentTermsURL,
        undefined,
        paymentTerm,
        PaymentPerLoad
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadAdd',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async createWebUserPaymentTerms(paymentTerm: CreateDriverPaymentTerm) {
    try {
      const response = await httpClient.post<PaymentPerLoad>(
        createWebUserPaymentTermsURL,
        undefined,
        paymentTerm,
        PaymentPerLoad
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadAdd',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async createTractorPaymentTerms(paymentTerm: CreateDriverPaymentTerm) {
    try {
      const response = await httpClient.post<PaymentPerLoad>(
        createTractorPaymentTermsURL,
        undefined,
        paymentTerm,
        PaymentPerLoad
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadAdd',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async deleteLoadRateByIDs(loadRateIDs: number[]) {
    try {
      const response = await httpClient.deleteRaw(
        deleteLoadRateByIDs,
        new QueryParams(),
        loadRateIDs
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadDelete',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async deleteTractorLoadRateByIDs(loadRateIDs: number[]) {
    try {
      const response = await httpClient.deleteRaw(
        deleteTractorLoadRateByIDs,
        new QueryParams(),
        loadRateIDs
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadDelete',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async deleteWebUserLoadRateByIDs(loadRateIDs: number[]) {
    try {
      const response = await httpClient.deleteRaw(
        deleteWebUserLoadRateByIDs,
        new QueryParams(),
        loadRateIDs
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadDelete',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriverLoadRatePayment(driverId: number) {
    const requestData = new GetDriverPaymentTermsRequest(driverId);
    try {
      return await httpClient.get<PaymentPerLoad[]>(
        getDriverLoadRatePaymentURL,
        requestData,
        PaymentPerLoad,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async existsPayTypeItemById(payTypeId: string) {
    const requestData = new ExistsPayTypeItemByIdRequest(payTypeId);
    try {
      return await httpClient.get<boolean>(
        existsPayTypeItemByIdURL,
        requestData,
        boolean,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async bulkUpdateForDriverPayment(
    driverPaymentTermsGrouped: DriverPaymentTermsGrouped
  ) {
    try {
      const response = await httpClient.putRaw(
        bulkUpdateDriverPaymentTermsURL,
        undefined,
        driverPaymentTermsGrouped
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadUpdate',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async bulkUpdateForWebuserPayment(
    driverPaymentTermsGrouped: DriverPaymentTermsGrouped
  ) {
    try {
      const response = await httpClient.put<DriverPaymentTermsGrouped>(
        bulkUpdateWebUserPaymentTermsURL,
        undefined,
        driverPaymentTermsGrouped,
        DriverPaymentTermsGrouped
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadUpdate',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async bulkUpdateForTractorPayment(
    driverPaymentTermsGrouped: DriverPaymentTermsGrouped
  ) {
    try {
      const response = await httpClient.putRaw(
        bulkUpdateTractorPaymentTermsURL,
        undefined,
        driverPaymentTermsGrouped
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'paymentPerLoadUpdate',
      });
      return response.data;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getTractorPaymentTerms(tractorId: number) {
    const requestData = new GetDriverPaymentTermsRequest(tractorId);
    try {
      return await httpClient.get<DriverPaymentTermsGrouped>(
        getTractorPaymentTermsURL,
        requestData,
        DriverPaymentTermsGrouped,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getWebUserPaymentTerms(dispatcherId: number) {
    const requestData = new GetDriverPaymentTermsRequest(dispatcherId);
    try {
      return await httpClient.get<DriverPaymentTermsGrouped>(
        getWebUserPaymentTermsURL,
        requestData,
        DriverPaymentTermsGrouped,
        undefined,
        true
      );
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'paymentPerLoadGeneral',
      });
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }
}
