import StorageManager from '../../../../../StorageManager/StorageManager';
import { layoutService, visibilityService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces/helperTypes';
import {
  CreateTrackerDTO,
  GetAllAlerts,
  GetContactListDTO,
  GetLoadByIDDTO,
} from '../../../../../models';
import { ISendDocumentTurvoType } from '../../../../../views/reports/documents/sections/EntityDocumentsSection';
import {
  createSelectedList,
  makeContactList,
  makeCustomerAlertFlag,
  toggleButtonValues,
} from './constant';
import { CUDOperations } from './interface';

export const getAllAlerts = async (loadId: string) => {
  const request = new GetAllAlerts();
  request.loadId = loadId;
  const getRes = await visibilityService.getAllAlerts(request);

  if (getRes instanceof ServiceError) {
    // should be decided what to do
    return [];
  } else if (getRes != null) {
    let tempRes: any = [];
    getRes.customerAlertFlagTypes.map((item: any) => {
      const temp = {
        ...item,
        ...toggleButtonValues,
        label: item.value?.replace('En route', 'En Route'),
      };
      delete temp.value;
      delete temp.order;
      tempRes = [...tempRes, temp];
    });
    return tempRes;
  }
};

export const getTrackingURL = async (loadId: string) => {
  const request = new GetAllAlerts();
  request.loadId = loadId;
  const getRes: any = await visibilityService.getTrackingURLData(request);
  if (getRes instanceof ServiceError) {
    // should be decided what to do
    return '';
  } else if (getRes != null) {
    return getRes.hashKey ? getRes.hashKey : '';
  }
};

export const CUDData = async (
  data: any,
  loadId: string,
  operation: CUDOperations
) => {
  const userStorage = StorageManager.getUser();
  const payload = new CreateTrackerDTO();
  payload.loadId = loadId;
  payload.name = data.name;
  payload.email = data.email;
  payload.phone = data.phone;
  payload.customerAlertFlag = makeCustomerAlertFlag(data.selectedList);

  if (operation == 'create') {
    const createRes = await visibilityService.createTrackerData(payload);
    return createRes;
  } else if (operation == 'update') {
    payload.organizationId = userStorage.organizationId;
    payload.axeleUserId = userStorage.id;
    payload.id = data.id;
    return payload;
  } else if (operation == 'delete') {
    payload.organizationId = userStorage.organizationId;
    payload.axeleUserId = userStorage.id;
    payload.id = data.id;
    const deleteRes = await visibilityService.deleteTrackerData([payload]);
    return deleteRes;
  } else {
  }
};

export const updateTracker = async (payload: any) => {
  const updateRes = await visibilityService.updateTrackerData(payload);
  return updateRes;
};

export const getList = async (
  loadId: string,
  alertsType: any,
  tempContactList: any
) => {
  const getRes = await visibilityService.getTrackerList(loadId);
  const temp = await createSelectedList(getRes, alertsType, tempContactList);
  return temp;
};

export const getContactList = async (customerId: number) => {
  const userStorage = StorageManager.getUser();
  const request = new GetContactListDTO();
  request.organizationId = userStorage.organizationId;
  request.customerId = customerId;
  const getRes = await visibilityService.getContactList(request);
  const temp = await makeContactList(
    getRes.contactSummaries ? getRes.contactSummaries : []
  );
  return temp;
};

export const getVisibilityProviderIdsData = async (id: number) => {
  const userStorage = StorageManager.getUser();
  const request = new GetLoadByIDDTO();
  request.loadId = id;
  request.organizationId = userStorage.organizationId;
  const getRes = await layoutService.getLoadByIDData(request);
  return getRes;
};

export const getTinyUrl = async (url: string) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  const temp = await fetch(url, requestOptions);
  return temp ? await temp.text() : '-';
};

export const sendDocumentTurvoData = async (
  payLoad: ISendDocumentTurvoType
) => {
  const getRes = await visibilityService.sendDocumentTurvoData(payLoad);
  return getRes;
};
