import { Button, ButtonGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { Suspense, lazy } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import StorageManager from '../../../../StorageManager/StorageManager';
import EmailReceivers from '../../../../common/Email/EmailReceivers';
import Checkbox from '../../../../common/Ui/Checkbox';
import FormDialog from '../../../../common/Ui/FormDialog';
import TextField from '../../../../common/Ui/TextField/TextField';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { emailArrayValidationYup } from '../../../../utils';
import { EmailFormValidationSchema } from '../../../../validator/validationSchemas';
import { COMPLETED_LOAD_STATUS_NAME, READY_TO_INVOICE } from '../../constants';
import { EmailFormType, InvoiceModel } from '../../models/InvoiceModel';
import {
  getEmailOrganizationName,
  getInvoiceEmailDefaultMessage,
  getInvoiceEmailDefaultReceivers,
  getInvoiceEmailDefaultSubject,
} from '../../utils';
import { AttachmentsForm } from './InvoiceDownloadAction';
const Wisiwig = lazy(() => import('../../../../ui-kit/components/Wisiwig'));

export function InvoiceSendEmailForm({
  invoice,
  canAddInvoice,
}: {
  invoice: InvoiceModel;
  canAddInvoice: boolean;
}) {
  const { control } = useFormContext();

  return (
    <>
      <EmailReceivers
        toOptions={
          invoice.customer?.contacts?.map((contact) => contact.email) || []
        }
      />
      <Box>
        <TextField control={control} name="subject" label="Subject" />
      </Box>
      <Box sx={{ mb: 4 }}>
        <Suspense fallback={<></>}>
          <Wisiwig control={control} name="message" />
        </Suspense>
      </Box>
      {(invoice.status === COMPLETED_LOAD_STATUS_NAME ||
        invoice.status === READY_TO_INVOICE) && (
        <Box sx={{ mt: 4, mb: 4 }}>
          <Checkbox
            control={control}
            disabled={invoice?.onHold ? true : !canAddInvoice}
            name={'markAsInvoiced'}
            label={
              invoice.status === READY_TO_INVOICE
                ? 'Mark Ready to Invoice Load(s) as Invoiced'
                : 'Mark completed Load(s) as Invoiced'
            }
          />
        </Box>
      )}
      <AttachmentsForm disableInvoiceCheckbox invoice={invoice} />
    </>
  );
}

export function InvoiceSendEmailDialog({
  open,
  onAction,
  onClose,
  invoice,
  canAddInvoice,
}: {
  open: boolean;
  onAction: (data: EmailFormType) => void;
  onClose: () => void;
  invoice: InvoiceModel;
  canAddInvoice: boolean;
}) {
  const { getIsTerminalEnabled } = useRootStore();
  const user = StorageManager.getUser();
  const to = getInvoiceEmailDefaultReceivers([invoice]);
  const emailOrganizationName = getEmailOrganizationName(
    invoice.terminal?.companyName,
    user.organizationName,
    getIsTerminalEnabled
  );
  return (
    <FormDialog
      data={{
        to: to,
        cc: [],
        subject: getInvoiceEmailDefaultSubject(
          invoice.seqNumber,
          emailOrganizationName,
          invoice?.invoiceReferenceId
        ),
        message: getInvoiceEmailDefaultMessage(invoice, emailOrganizationName),
        sendInvoice: true,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
        markAsInvoiced: invoice?.onHold
          ? false
          : canAddInvoice
          ? invoice.status === COMPLETED_LOAD_STATUS_NAME ||
            invoice.status === READY_TO_INVOICE
          : false,
        documentIds: [],
        invoiceId: invoice.id,
        pdfComplexity: 'DETAILED',
      }}
      validationSchema={EmailFormValidationSchema}
      titleText="Email Invoices"
      actionLabel="Email"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => (
        <InvoiceSendEmailForm invoice={invoice} canAddInvoice={canAddInvoice} />
      )}
    />
  );
}

export function MultipleSendEmailDialog({
  open,
  onAction,
  onClose,
  invoices,
  canAddInvoice,
}: {
  open: boolean;
  onAction: (data: EmailFormType) => void;
  onClose: () => void;
  invoices: InvoiceModel[];
  canAddInvoice: boolean;
}) {
  let isMarkAsInvoicedAvailable = true;
  invoices.forEach((i) => {
    if (i.status !== COMPLETED_LOAD_STATUS_NAME) {
      isMarkAsInvoicedAvailable = false;
    }
  });

  return (
    <FormDialog
      data={{
        cc: [],
        markAsInvoiced: canAddInvoice ? isMarkAsInvoicedAvailable : false,
        sendInvoice: true,
        rateConfirmation: true,
        billOfLading: true,
        proofOfDelivery: true,
        other: true,
        pdfComplexity: 'DETAILED',
      }}
      validationSchema={yup.object().shape({
        cc: emailArrayValidationYup('cc'),
      })}
      titleText="Email Invoices"
      actionLabel="Email"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => (
        <MultipleSendEmailForm
          isMarkAsInvoicedAvailable={isMarkAsInvoicedAvailable}
          canAddInvoice={canAddInvoice}
        />
      )}
    />
  );
}

export function MultipleSendEmailForm({
  isMarkAsInvoicedAvailable,
  canAddInvoice,
}: {
  isMarkAsInvoicedAvailable: boolean;
  canAddInvoice: boolean;
}) {
  const { control, setValue, watch } = useFormContext();
  const pdfComplexity = watch('pdfComplexity');
  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Typography variant={'subtitle2'}>
          {t('multipleInvoiceEmailSendWarning')}
        </Typography>
      </Box>
      <Box>
        <EmailReceivers hideTo />
      </Box>

      {pdfComplexity && (
        <Box sx={{ mb: 5 }}>
          <ButtonGroup
            aria-label="outlined primary button group"
            size="large"
            fullWidth={true}
          >
            <Button
              variant={
                pdfComplexity === 'SIMPLIFIED' ? 'contained' : 'outlined'
              }
              onClick={() => setValue('pdfComplexity', 'SIMPLIFIED')}
            >
              Summary
            </Button>
            <Button
              variant={pdfComplexity === 'MEDIUM' ? 'contained' : 'outlined'}
              sx={{ position: 'relative', left: ' -20px' }}
              onClick={() => setValue('pdfComplexity', 'MEDIUM')}
            >
              Detailed
            </Button>
            <Button
              variant={pdfComplexity === 'DETAILED' ? 'contained' : 'outlined'}
              sx={{ position: 'relative', left: ' -40px' }}
              onClick={() => setValue('pdfComplexity', 'DETAILED')}
            >
              Comprehensive
            </Button>
          </ButtonGroup>
        </Box>
      )}
      {isMarkAsInvoicedAvailable && (
        <Box sx={{ mt: 4, mb: 4 }}>
          <Checkbox
            control={control}
            name={'markAsInvoiced'}
            label={'Mark completed Load(s) as Invoiced'}
            disabled={!canAddInvoice}
          />
        </Box>
      )}
      <AttachmentsBulk disableInvoiceType />
    </>
  );
}

export function AttachmentsBulk({
  disableInvoiceType = false,
}: {
  disableInvoiceType?: boolean;
}) {
  const { control } = useFormContext();
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography variant={'subtitle2'}>Attachments:</Typography>
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          disabled={disableInvoiceType}
          control={control}
          name={'sendInvoice'}
          label={'Invoice'}
        />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'rateConfirmation'}
          label={'Rate Confirmation'}
        />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'billOfLading'}
          label={'Bill of Lading'}
        />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox
          control={control}
          name={'proofOfDelivery'}
          label={'Proof of Delivery'}
        />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Checkbox control={control} name={'other'} label={'Other'} />
      </Box>
    </>
  );
}
