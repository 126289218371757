import moment, { MomentInput } from 'moment';
import { AddressDTO } from '../../../models';
import { IDriverLoadsGroupAssignment } from '../../../models/DTOs/Dispatch/Dispatch';
import { IDispatchDriverTimelineTrip } from '../../../models/DTOs/Dispatch/Timeline';
import {
  EDispatch3DotMenuType,
  IActionTimeOffBaseProps,
} from '../../../views/dispatch/constants/types';
import { LoadItemOptionsProps } from '../components/Form/LoadItem';

export interface ITimeoffDialogData {
  driverGroupId: string;
  driverName?: string;
  tractorId?: string;
  trailerId?: string;
  driverId?: string;
}

export interface TimeoffFormProps {
  loadData: ITimeoffDialogData;
  action: EDispatch3DotMenuType;
  canEditDate?: boolean;
}
export interface TimeoffDialogProps
  extends TimeoffFormProps,
    IActionTimeOffBaseProps {
  onClose: () => Promise<void>;
  timeoffFormData: TimeoffFormDataProps;
}
export interface MarkProgressTimeoffDialogProps
  extends TimeoffFormProps,
    IActionTimeOffBaseProps {
  data: IDispatchDriverTimelineTrip;
  onClose: () => Promise<void>;
  getDriverLoadsGroupAssignment: () => Promise<IDriverLoadsGroupAssignment | null>;
}

export interface ITimeoffDate {
  startDatePart: MomentInput;
  startTimePart: number;
  endDatePart: MomentInput;
  endTimePart: number;
}
export interface ITimeoffDateWithTz extends ITimeoffDate {
  timezone?: string;
  appointmentStartDate?: MomentInput;
  appointmentEndDate?: MomentInput;
}

export interface TimeoffFormDataProps extends ITimeoffDate {
  id: any;
  location?: AddressDTO | null;
  previousLoad?: LoadItemOptionsProps | null;
}

const getCurrentTimeInMinutes = (): number => {
  return moment().diff(moment().startOf('day'), 'minutes');
};

export const defaultTimeoffFormData: TimeoffFormDataProps = {
  startDatePart: new Date(),
  startTimePart: getCurrentTimeInMinutes(),
  endDatePart: new Date(),
  endTimePart: getCurrentTimeInMinutes() + 15,
  location: null,
  previousLoad: null,
};
