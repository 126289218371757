import { SxProps } from '@mui/material';
import { memo } from 'react';
import { IAutocompleteOption } from '../../../../views/trips/constants/types';
import { GridButtonGroup } from '../../../../common/Ui/GridButtonGroup/GridButtonGroup';

export interface GridButtonGroupFilterProps {
  id?: string;
  column: any;
  defaultFilterValue: Record<string, any>;
  onChangeCb?: (param: IAutocompleteOption[] | null, name: string) => void;
  isFormControl?: boolean;
  field: Record<string, any> | undefined;
  ButtonSxProps?: SxProps;
}
export const GridButtonGroupFilter = memo(
  ({
    column,
    defaultFilterValue,
    onChangeCb,
    isFormControl = false,
    field = {},
  }: GridButtonGroupFilterProps) => {
    return (
      <GridButtonGroup
        isFormControl={isFormControl}
        multiple={!!column.multiple}
        name={field.name || column.name}
        disabled={column.disabled}
        options={column.options}
        onChange={(
          newValue: IAutocompleteOption[],
          name?: string | undefined
        ) => {
          const cloneNewValue = [...newValue];
          if (isFormControl) {
            field.onChange(cloneNewValue, name);
            column.onChange && column.onChange(cloneNewValue, name);
          } else {
            onChangeCb && onChangeCb(cloneNewValue, name!);
          }
        }}
        defaultOptions={
          isFormControl ? field.value : defaultFilterValue[column.name]
        }
        ButtonSxProps={column.ButtonSxProps}
      />
    );
  }
);
GridButtonGroupFilter.displayName = 'GridButtonGroupFilter';
