import { ArrowBackOutlined } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  ThemeProvider,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import StorageManager from '../../StorageManager/StorageManager';
import { subscriptionService } from '../../api';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { ChargifyCardInfo } from './Chargify';
import { regitrationPageStyle } from './RegistrationPageUtils';
import RegistrationReJoinInitial from './RegistrationReJoinInitial';
import { AuthIntroduction } from './components/AuthIntroduction';
import { EPartner } from './constant';
import {
  StyledFormTitle,
  StyledLoginAuthFromWrapper,
  SxPropAuthButton,
  getRegistrationStyles,
} from './styles';

const RegistrationReJoinPage = () => {
  const { themeLogin, isMobile } = useThemeResponsive();
  const [searchParams] = useSearchParams();
  const [cardData, setCardData] = useState<any>({});
  const [chargifyToken, setChargifyToken] = useState<string>('');
  const userStorage = StorageManager.getUser();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [showPaymentScreen, setShowPaymentScreen] = useState<boolean>(false);
  const navigate = useNavigate();
  const isExistPartnerUser: boolean = Object.values(EPartner).includes(
    searchParams.get('partner')
  );
  const [policyError, setPolicyError] = useState<string>('initial');

  const styles = getRegistrationStyles({
    theme: themeLogin,
    isExistPartnerUser: isExistPartnerUser,
  });

  const getChargifyToken = () => {
    if (policyError != 'isSelected') {
      setPolicyError('notSelected');
      return;
    }
    setLoading(true);
    cardData.chargify.token(
      cardData.chargifyForm.current,
      (token: string) => {
        setChargifyToken(token);
      },
      (errorResponse: string | Array<any>) => {
        setLoading(false);
        const errorMsg = Array.isArray(errorResponse?.errors)
          ? errorResponse?.errors[0]
          : errorResponse?.errors;
        RootStoreInstence.setNotificationType({
          message: errorMsg,
          type: 'FAILURE',
        });
      }
    );
  };
  useEffect(() => {
    if (chargifyToken) {
      handleSubscriptionPlan();
    }
  }, [chargifyToken]);
  const handleSubscriptionPlan = async () => {
    const payload = {
      organizationId: userStorage.organizationId,
      paymentProfile: {
        chargify_token: chargifyToken,
      },
    };
    const response = await subscriptionService.subscriptionPlan(payload);
    if (response) {
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'accountActivation',
      });
      setLoading(false);
      navigate('/login');
    } else {
      setLoading(false);
    }
  };
  return (
    <ThemeProvider theme={themeLogin}>
      <>
        <Box className="Auth-root" sx={styles.authRoot}>
          <Box className="Auth-backgroundImage" sx={styles.authBackgroundImage}>
            <Stack className="Auth-content" spacing={0} sx={styles.authContent}>
              <AuthIntroduction />

              <Box className="formWrapper" sx={styles.formWrapper}>
                <Box
                  sx={{
                    width: '100%',
                    height: 'max-content',
                    overflowX: 'auto',
                  }}
                >
                  <Box
                    className="formWrapperContent"
                    sx={styles.formWrapperContent}
                  >
                    <StyledLoginAuthFromWrapper>
                      <Box sx={regitrationPageStyle.verificationStyle}>
                        <Box
                          sx={{
                            height: '100%',
                          }}
                        >
                          {!showPaymentScreen && (
                            <RegistrationReJoinInitial
                              setShowPaymentScreen={setShowPaymentScreen}
                            />
                          )}
                          {showPaymentScreen && (
                            <>
                              <Box
                                sx={{
                                  marginBottom: '50px',
                                  position: 'relative',
                                }}
                              >
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setShowPaymentScreen(false)}
                                >
                                  <ArrowBackOutlined
                                    fontSize={isMobile ? 'medium' : 'large'}
                                    sx={{ color: 'text.primary' }}
                                  />
                                </Box>

                                <StyledFormTitle>
                                  Enter Credit/Debit Card Details
                                </StyledFormTitle>
                              </Box>

                              <ChargifyCardInfo
                                setCardData={setCardData}
                                isAuthPage
                              />

                              <>
                                <Box>
                                  <Box
                                    sx={(theme) => ({
                                      ...theme.typography.body1,
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      lineHeight: '22px',
                                      color: 'text.primary',
                                      marginBottom: true ? '5px' : '25px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      width: '100%',
                                      mt: 2,
                                    })}
                                  >
                                    <Checkbox
                                      onChange={(e) => {
                                        setPolicyError(
                                          e.target.checked
                                            ? 'isSelected'
                                            : 'notSelected'
                                        );
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        display: 'inline-table',
                                      }}
                                    >
                                      I agree with LoadOps’
                                      <Box
                                        component={'span'}
                                        sx={{
                                          color: 'primary.main',
                                        }}
                                      >
                                        <a
                                          style={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            marginLeft: '5px',
                                            marginRight: '5px',
                                          }}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="https://optym.com/privacy-policy/"
                                        >
                                          Privacy Policy
                                        </a>
                                      </Box>
                                      and
                                      <Box
                                        component={'span'}
                                        sx={{
                                          color: 'primary.main',
                                        }}
                                      >
                                        <a
                                          style={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            marginLeft: '5px',
                                          }}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="https://optym.com/loadops-terms/"
                                        >
                                          Terms of Conditions
                                        </a>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {!(
                                    policyError.includes('initial') ||
                                    policyError.includes('isSelected')
                                  ) && (
                                    <Box
                                      sx={(theme) => ({
                                        ...theme.typography.body1,
                                        color: 'error.main',
                                        marginBottom: '25px',
                                        fontSize: '12px',
                                        paddingLeft: '40px',
                                        width: '100%',
                                      })}
                                    >
                                      Please agree to Privacy Policy.
                                    </Box>
                                  )}
                                </Box>

                                <Button
                                  variant="contained"
                                  disabled={isLoading}
                                  onClick={getChargifyToken}
                                  sx={{
                                    ...SxPropAuthButton,
                                    ...(isLoading && {
                                      background: '#3a4755 !important',
                                      color: '#757e88 !important',
                                    }),
                                    width: '100%',
                                  }}
                                  size="large"
                                >
                                  {isLoading && (
                                    <CircularProgress
                                      size={22}
                                      sx={{ ml: '-16px', mr: '22px' }}
                                    />
                                  )}{' '}
                                  {isLoading ? 'Joining Up' : 'Join'}
                                </Button>
                              </>
                            </>
                          )}
                        </Box>
                      </Box>
                    </StyledLoginAuthFromWrapper>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </>
    </ThemeProvider>
  );
};

export default RegistrationReJoinPage;
