export const TeamDriverConstants = {
  assignedAssetUnassigned:
    'Please note that assigned asset(s) will be unassigned as a result of deactivating the driver.',
  editTeam: 'Edit Team',
  createTeam: 'Create Team',
  deleteTeamDriver: 'Remove Team driver',
  deleteTeamDriverConfirmation:
    'Are you sure you want to remove the team driver?',
  removeAssignment: 'Are you sure you want to remove this assignment?',
  cannotMarkActive: 'Cannot mark active',
  cannotMarkActiveSubtitle:
    'The team cannot be marked as active as some of its member drivers are already included in other active teams.',
  assign: 'Assign',
  primaryDriver: 'Primary Driver',
  secondaryDriver: 'Secondary Driver',
  operationMode: 'Operation Mode',
  operationStatus: 'Operation Status',
  assignedTractor: 'Assigned Tractor',
  assignedTrailer: 'Assigned Trailer',
  activeForOperations: 'Active for Operations',
  markAsInactive: 'Mark as Inactive',
  swapDrivers: 'Swap Drivers',
};
