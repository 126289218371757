import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { paymentService } from '../../../../api';
import { PaymentType } from '../../../../models/DTOs/PaymentTerms';
import MultilineCellAxele from '../../../../ui-kit/components/MultilineCell';
import { addPayItemsModule } from '../../../PendoClassIDs/constants';
import RadioGroup from '../../../Ui/RadioGroup';
import Select from '../../../Ui/Select';
import TextField from '../../../Ui/TextField/TextField';
import {
  paymentLineItemAmountText,
  paymentLineItemDescription,
  paymentLineItemText,
  paymentRateCompare,
} from './FinanceOverviewConstant';
import FormContext from './FinanceOverviewFormContext';
import { EPaymentDetailType, PaymentUnitType } from './FinanceOverviewModel';
import { t } from 'i18next';

const totalTextStyle = {
  fontSize: '12px',
};
const totalSubTextStyle = {
  color: '#669EFF !important',
  fontSize: '16px',
};

const FinanceOverViewAddNewForm = ({}) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const {
    availablePayTypes,
    expenseCategories,
    selectEntityType,
    financeOverviewData,
  } = useContext(FormContext);

  const [selectedPayItem, SetselectedPayItem] = useState<
    PaymentType | undefined
  >({});
  const [paymentsData, setPaymentsData] = useState([]);
  const [selectedFinanceUnitList, SetSelectedFinanceUnitList] = useState<
    PaymentUnitType[]
  >([]);
  const selectedUnitType = watch('unit');
  const loadRateType = watch('loadRateType');
  const selectedFinanceTypeId = watch('loadRateTypeId');
  const selectedExpenseCategoryId = watch('expenseCategoryId');
  const calculatedAmount = watch('amount');
  const calculatedQuantity = watch('quantity');
  const calculatedRate = watch('rate');
  useEffect(() => {
    let payTypeRecord = findRecord(availablePayTypes, 'FLAT_RATE');
    let expRecord = findRecord(
      expenseCategories,
      selectEntityType?.type === 'Dispatcher'
        ? 'DISPATCHER_PAYMENT'
        : selectEntityType?.type === EPaymentDetailType.Carrier
        ? 'PARTNER_CARRIER_PAYMENT'
        : 'DRIVER_PAYMENT'
    );
    expRecord = expRecord ? expRecord : expenseCategories[0];
    payTypeRecord = payTypeRecord ? payTypeRecord : availablePayTypes[0];
    SetselectedPayItem(payTypeRecord);
    setValue('loadRateType', payTypeRecord?.itemCode);
    setValue('loadRateTypeId', payTypeRecord?.id);
    setValue('description', payTypeRecord?.description);
    setValue('loadRateType', payTypeRecord?.itemCode);
    setValue('unit', payTypeRecord?.unit[0]);
    SetSelectedFinanceUnitList(
      payTypeRecord[0]?.unit ? payTypeRecord[0].unit : []
    );
    setValue('expenseCategoryId', expRecord?.id);
    setValue('expenseCategoryKey', expRecord?.itemCode);
  }, [availablePayTypes]);

  const findRecord = (list: any[], key: string) => {
    const result = list.find(
      (item: { itemCode: string }) => item?.itemCode === key
    );
    return result;
  };

  const getPaymentsTermData = async () => {
    if (!selectEntityType?.id) return;
    if (selectEntityType?.type === 'Driver') {
      const driverPaymentTermsData = await paymentService.getDriverPaymentTerms(
        selectEntityType?.id as number
      );
      return driverPaymentTermsData;
    }
    if (selectEntityType?.type === 'Dispatcher') {
      const dispatcherPaymentTermsData =
        await paymentService.getWebUserPaymentTerms(
          selectEntityType?.id as number
        );
      return dispatcherPaymentTermsData;
    }
    if (selectEntityType?.type === 'Tractor') {
      const tractorPaymentTermsData =
        await paymentService.getTractorPaymentTerms(
          selectEntityType?.id as number
        );
      return tractorPaymentTermsData;
    }
  };

  useEffect(() => {
    const getPaymentsData = async () => {
      const data = await getPaymentsTermData();
      if (data?.loadRateDTO?.length >= 1) {
        setPaymentsData(data?.loadRateDTO);
      }
    };
    getPaymentsData();
  }, []);

  useEffect(() => {
    setValue('rate', 0);
    setValue('quantity', 0);
    if (paymentsData.length >= 1) {
      const foundPayItem = paymentsData?.find(
        (item) => item.payTypeId === selectedFinanceTypeId
      );
      const driversCount =
        financeOverviewData?.tripDetails?.[0]?.paymentDetails.reduce(
          (count, elem) => {
            if (elem.type === 'Driver') {
              count++;
            }
            return count;
          },
          0
        );
      if (paymentRateCompare.includes(loadRateType)) {
        return setValue('rate', Math.abs(foundPayItem?.rate || 0));
      }
      if (selectEntityType?.type === 'Driver') {
        const foundTeamDriver = paymentsData?.find(
          (item) =>
            item.payTypeId === selectedFinanceTypeId &&
            item.operationMode === 'TEAM'
        );
        const foundSoloDriver = paymentsData?.find(
          (item) => item.payTypeId === selectedFinanceTypeId
        );
        if (
          foundTeamDriver &&
          foundTeamDriver?.unit === selectedUnitType &&
          driversCount === 2
        ) {
          return setValue('rate', foundTeamDriver?.rate);
        }
        if (!foundTeamDriver && foundSoloDriver?.unit === selectedUnitType) {
          return setValue('rate', foundSoloDriver?.rate);
        }
      }
      if (foundPayItem && foundPayItem?.unit === selectedUnitType) {
        setValue('rate', foundPayItem?.rate);
      }
    }
  }, [selectedUnitType, paymentsData, loadRateType, selectedFinanceUnitList]);

  useEffect(() => {
    if (!selectedFinanceTypeId) {
      return;
    }
    const foundFinanceType = availablePayTypes.find(
      (payType) => payType.id === selectedFinanceTypeId
    );
    SetselectedPayItem(foundFinanceType);
    setValue('description', foundFinanceType?.description);
    setValue('loadRateType', foundFinanceType?.itemCode);
    setValue('loadRateTypeId', foundFinanceType?.id);
    setValue('unit', foundFinanceType?.unit[0]);
    SetSelectedFinanceUnitList(foundFinanceType?.unit);
  }, [selectedFinanceTypeId, setValue, availablePayTypes]);

  useEffect(() => {
    if (!selectedExpenseCategoryId) {
      return;
    }
    const foundEpenseCategory = expenseCategories.find(
      (expenseCategory) => expenseCategory.id === selectedExpenseCategoryId
    );
    setValue('expenseCategoryKey', foundEpenseCategory?.itemCode);
    setValue('expenseCategoryId', foundEpenseCategory?.id);
    if (foundEpenseCategory?.itemCode === 'PARTNER_CARRIER_PAYMENT') {
      setValue(
        'description',
        paymentLineItemDescription.PARTNER_CARRIER_PAYMENT
      );
    }
  }, [selectedExpenseCategoryId, expenseCategories, setValue]);

  useEffect(() => {
    calculationBasedOnSelectItem();
  }, [calculatedQuantity, calculatedRate, selectedUnitType]);

  const calculationBasedOnSelectItem = (): void => {
    if (paymentRateCompare.includes(loadRateType)) {
      return setValue('amount', calculatedRate);
    }
    if (selectedUnitType === 'PERCENT' && selectedFinanceUnitList?.length > 1) {
      return setValue('amount', (calculatedQuantity * calculatedRate) / 100);
    }
    if (selectedUnitType !== 'PERCENT' && selectedFinanceUnitList?.length > 1) {
      return setValue('amount', calculatedQuantity * calculatedRate);
    }
    if (calculatedRate > 0 && calculatedQuantity > 0) {
      if (selectedUnitType === 'PERCENT') {
        return setValue('amount', (calculatedQuantity * calculatedRate) / 100);
      }
      if (selectedUnitType !== 'PERCENT') {
        return setValue('amount', calculatedQuantity * calculatedRate);
      }
    }
    return setValue('amount', 0);
  };

  const filterSettlementItems = (data: PaymentType[]) => {
    return data.filter((item: PaymentType) => item.settlementItem);
  };

  const getRateLabel = () => {
    if (selectedUnitType === 'PERCENT') return 'Rate (%)';
    return paymentLineItemAmountText[selectedUnitType];
  };

  const getQuantityInputLabel = () => {
    if (
      selectedUnitType === 'PERCENT' ||
      paymentRateCompare.includes(loadRateType)
    ) {
      return 'Amount ($)';
    }
    return 'Quantity';
  };

  return (
    <Grid container>
      <Select
        id={addPayItemsModule + 'PayType'}
        name={`loadRateTypeId`}
        label={'Pay Type '}
        required
        fieldName={'itemName'}
        sizes={{ xs: 12 }}
        options={filterSettlementItems(availablePayTypes)}
        control={control}
        fieldValue={'id'}
        error={errors.FinanceType}
      />
      {selectedFinanceUnitList?.length > 1 && (
        <Grid container>
          <Grid item sx={{ pt: '9px', pb: '10px', pr: '20px' }}>
            <Typography>Unit </Typography>
          </Grid>
          <Grid item sx={{ pb: '10px', pl: '15px' }}>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="unit"
              data={selectedFinanceUnitList.map((item) => {
                return {
                  value: item,
                  label: paymentLineItemText[item],
                };
              })}
            />
          </Grid>
        </Grid>
      )}
      <Grid container item spacing={2} xs={12}>
        {!paymentRateCompare.includes(loadRateType) && (
          <TextField
            id={addPayItemsModule + 'Quantity'}
            control={control}
            name={`quantity`}
            required
            defaultValue={'1'}
            label={getQuantityInputLabel()}
            sizes={{ xs: 4 }}
          />
        )}
        <TextField
          id={addPayItemsModule + 'Rate'}
          control={control}
          name={`rate`}
          defaultValue={'0'}
          required
          label={
            !paymentRateCompare.includes(loadRateType)
              ? getRateLabel()
              : getQuantityInputLabel()
          }
          sizes={{ xs: 4 }}
        />
        <Grid container item xs={4}>
          <MultilineCellAxele
            title={'Amount'}
            subtitle={'$ ' + calculatedAmount}
            titleStyle={totalTextStyle}
            subtitleStyle={totalSubTextStyle}
            alignTitle="left"
            alignSubtitle="left"
          />
        </Grid>
      </Grid>
      <Select
        id={addPayItemsModule + 'ExpenseCategory'}
        name={`expenseCategoryId`}
        required
        label={t('expenseCategory')}
        fieldName={'itemName'}
        sizes={{ xs: 12 }}
        options={expenseCategories}
        control={control}
        fieldValue={'id'}
        error={errors.expenseCategory}
      />
      <TextField
        id={addPayItemsModule + 'Description'}
        control={control}
        name={`description`}
        label="Description"
        sizes={{ xs: 12 }}
      />
    </Grid>
  );
};

FinanceOverViewAddNewForm.defaultProps = {
  titleText: '',
};

export default FinanceOverViewAddNewForm;
