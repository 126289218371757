import { Type } from 'class-transformer';
import { PhoneDTO } from '../Contacts/Contacts';
import { CommodityType, SortResponse } from '../commonMixed';

export class ContactSummaryCustomer {
  id!: number;
  fullName!: string;
  description!: string;
  phoneData!: PhoneDTO;
  email!: string;
  isPrimary!: boolean;
  type?: string;
  value?: string;
}

export class CustomerSummaryDTO {
  id?: string;
  organizationId?: number;
  name?: string;
  type?: string;
  address?: string;
  streetAddress?: string;
  streetAddress2?: string;
  fullAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  status?: boolean;
  notes?: string;
  mc?: string;
  customerPreference?: string;
  useFactoring?: boolean;
  factoringId?: string;
  factoring?: { id: string; factoringName: string };
  @Type(() => ContactSummaryCustomer)
  contacts!: ContactSummaryCustomer[];
  @Type(() => CommodityType)
  commodityTypes!: CommodityType[];
  openCreditAmount?: number;
}

export class CustomerSummaryDTOExcel {
  id!: string;
  organizationId!: number;
  name!: string;
  type!: string;
  address!: string;
  streetAddress!: string;
  streetAddress2!: string;
  fullAddress!: string;
  city!: string;
  state!: string;
  zip!: string;
  status!: boolean;
  notes!: string;
  mc!: string;
  customerPreference!: string;
  useFactoring!: boolean;
  factoringId!: string;
  @Type(() => ContactSummaryCustomer)
  contacts!: ContactSummaryCustomer[];
  @Type(() => CommodityType)
  commodityTypes!: CommodityType[];
}
export class PaginatedCustomersList {
  @Type(() => CustomerSummaryDTO)
  content!: CustomerSummaryDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export type customerListRequestType = {
  pageNumber: number;
  pageSize: number;
  sort: string;
};

export type customerFiltersRequestType = {
  customerNameFilter: string[] | null;
  customerAddressFilter: string[] | null;
  customerContactsFilter: string[] | null;
  customerMcFilter: string[] | null;
  factoringIds: string[] | null;
  customerPreferenceFilter: string[] | null;
  typeList: string[] | null;
  gridColumnMetadataList?: string[];
  customerStateFilter: string[];
};

export type customerListExcelRequestType = {
  sort: string;
  pageNumber: number;
  pageSize: number;
  gridColumnMetadataList: string[];
};
export type customerAssignedToLoadType = {
  customerAssignedToLoad: boolean;
  customerAssignedToInvoice: boolean;
  customerAssignedToPayment: boolean;
};

export interface ICustomerContact {
  id: number;
  organizationId: number;
  title: string;
  prefix: string;
  suffix: string;
  firstname: string;
  middlename: string;
  lastname: string;
  phone: string;
  countryCode: string;
  extension: string;
  email: string;
  isPrimary: boolean;
  rate: string;
  note: string;
  role: string;
  customerId: number;
}
