import * as yup from 'yup';
import { nullableStringMaxValidation } from '../../../utils';
import { defaultContactValidationSchema } from '../../../validator/validationSchemas';
import { carrierFieldsConfig } from './constants';

const errorMsgConfig = {
  carrierName: {
    required: 'Carrier name is required.',
    validation: 'Carrier name cannot be longer than 255 characters',
  },
  mc: {
    validation: 'MC# cannot be longer than 8 characters',
  },
  carrierNotes: {
    validation: 'Notes cannot be longer than 255 characters',
  },
  contactName: {
    required: 'Name is required.',
  },
  insuranceType: {
    required: 'Insurance Type is required.',
  },
  insuranceAmount: {
    required: 'Insurance amount is required.',
  },
  insuranceProvider: {
    required: 'Provider is required.',
  },
  insuranceNotes: {
    validation: 'Notes cannot be longer than 255 characters',
  },
};

export const CarrierInsuranceValidationSchema = yup.object().shape({
  type: yup
    .object()
    .shape({
      key: yup
        .string()
        .required(errorMsgConfig.insuranceType.required)
        .typeError(errorMsgConfig.insuranceType.required),
    })
    .required(errorMsgConfig.insuranceType.required)
    .typeError(errorMsgConfig.insuranceType.required),
  amount: yup
    .number()
    .typeError(errorMsgConfig.insuranceAmount.required)
    .required(errorMsgConfig.insuranceAmount.required),
  provider: yup.string().required(errorMsgConfig.insuranceProvider.required),
  notes: yup
    .string()
    .nullable()
    .max(255, errorMsgConfig.insuranceNotes.validation)
    .typeError(errorMsgConfig.insuranceNotes.validation),
});

export const CarrierContactValidationSchema = yup.object().shape({
  name: nullableStringMaxValidation(50).required(
    errorMsgConfig.contactName.required
  ),
  ...defaultContactValidationSchema,
  // phoneData: requiredPhoneValidationSchema,
});

export const AddNewCarrierValidationSchema = yup.object().shape({
  [carrierFieldsConfig.name.fieldName]: yup
    .string()
    .required(errorMsgConfig.carrierName.required)
    .max(255, errorMsgConfig.carrierName.validation)
    .typeError(errorMsgConfig.carrierName.required),
  [carrierFieldsConfig.mc.fieldName]: yup
    .number()
    .nullable()
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .max(9999999, errorMsgConfig.mc.validation),
  [carrierFieldsConfig.notes.fieldName]: yup
    .string()
    .nullable()
    .max(255, errorMsgConfig.carrierNotes.validation)
    .typeError(errorMsgConfig.carrierNotes.validation),
  insurances: yup.array().of(CarrierInsuranceValidationSchema).compact(),
  contacts: yup.array().of(CarrierContactValidationSchema).compact(),
});
