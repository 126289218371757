export const EquipmentConstants = {
  noTractors: 'You have no tractors in LoadOps. Why don’t we change that?',
  noTrailers: 'You have no trailers in LoadOps. Why don’t we change that?',
  noDrivers: 'You have no drivers in LoadOps. Why don’t we change that?',
  addTrailer: 'Add Trailer',
  addNewTrailer: 'Add New Trailer',
  maintenanceItem: 'Maintenance Item',
  lastDone: 'Last Done',
  lastOdometer: 'Last Odometer (mi)',
  nextDue: 'Next Due',
  nextOdometer: 'Next Odometer (mi)',
  markDone: 'Mark Done',
  totalAlerts: 'Total Alerts',
  addNewAlert: 'Add New Alert',
  addAlert: 'Add Alert',
  createAlert: 'Create Alert',
  deleteAlert: 'Delete Alert?',
  assignDriver: 'Assign Driver',
  vin: 'VIN',
  tractorDetails: 'Tractor Details',
  registration: 'Registration',
  regExpiry: 'Reg. Expiry',
  location: 'Location',
  source: 'Source',
  lastUpdated: 'Last Updated',
  ownedBy: 'Owned By',
  ownerEmail: 'Owner Email',
  make: 'Make',
  model: 'Model',
  year: 'Year',
  tollProvider: 'Toll Provider',
  tollTag: 'Toll Tag',
  fuelCapacity: 'Fuel Capacity (gal)',
  gvw: 'GVW',
  sleeperBearth: 'Sleeper Berth',
  sleeperBearthSubLabel: 'Used for planning overnight trips',
  manualTransmission: 'Manual Transmission',
  manualTransmissionSubLabel:
    'Manually select the gears by operating a gear stick and clutch',
  provider: 'Provider',
  addTractor: 'Add Tractor',
  addNewTractor: 'Add New Tractor',
  deleteTractorConfirmation: 'Are you sure you want to delete the tractor?',
  markUnavailable: 'Mark as Unavailable?',
  markAsAvailable: 'Mark as Available?',
  markTractorUnavailableConfirmation:
    'Are you sure you want to mark this tractor as Unavailable?',
  markTrailerUnavailableConfirmation:
    'Are you sure you want to mark this trailer as Unavailable?',
  markTractorAsAvailable:
    'Are you sure you want to mark this tractor as Available?',
  markTrailerAsAvailable:
    'Are you sure you want to mark this trailer as Available?',
  markAvailableNote:
    'Please note that this action will be reflected on the current and future loads of the assigned driver',
  deleteTractor: 'Delete Tractor',
  deleteTrailer: 'Delete Trailer',
  activeLoad: 'Active Load',
  payloadCapacity: 'Payload Capacity',
  deleteTrailerConfirmation: 'Are you sure you want to remove the trailer?',
  validfrom: 'Valid from',
  validto: 'Valid to',
  insurance: 'Insurance',
  trailerDetails: 'Trailer Details',
  tracking: 'Tracking',
  ownership: 'Ownership',
  ownershipType: 'Ownership Type',

  maintenanceActivity: 'Maintenance Activity',
  maintenanceTotal: 'Total Maint. Cost YTD',
};
