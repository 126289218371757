import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ErrorOutlinedIcon } from '../../../../../ui-kit/components/Assets';
import { LoadStatus } from '../../../../../common/Ui/StatusComponent/StatusComponent';
import { GridColDefSelf } from '../../../../../types';
import {
  fromIsoToMoment,
  getFormattedDateForGrid,
  numberWithThousandSeparator,
} from '../../../../../utils';
import { t } from 'i18next';
import { CommonConstants } from '../../../../../locales/en/common';
import { InvoicesConstants } from '../../../../../locales/en/finance/invoices';
export const getCustomerDefaultRequestData = {
  pageNumber: 1,
  pageSize: 25,
  sort: `+name`,
  synchFilter: null,
  axeleCustomerIds: [],
  qbAccountId: '',
};

export const accountingTabs = [
  {
    label: 'Map Invoice Pay Items',
    key: 'mapInvoiceItems',
    iconName: 'SwapHorizOutlined',
    source: 'MUIcons',
  },
  {
    label: 'Class Mapping',
    key: 'classMapping',
    iconName: 'ReceiptOutlined',
    source: 'MUIcons',
  },
  {
    label: 'Customer Mapping',
    key: 'customerMapping',
    iconName: 'PhotoCameraFrontOutlined',
    source: 'MUIcons',
  },
  {
    label: 'Invoices',
    key: 'invoices',
    iconName: 'ReceiptOutlined',
    source: 'MUIcons',
  },
];

const iconProps = {
  iconName: 'quickBookOnlineIcon',
  source: 'CustomerIcons',
};

export const buttonsArray = [
  {
    label: 'Start QuickBooks Session',
    variant: 'contained',
    onClick: 'handleQBOConnect',
    section: ['mapInvoiceItems', 'customerMapping', 'invoices'],
    visibiltyType: 'disconnected',
    styles: { backgroundColor: '#2FA137' }, //once the color is been added to the theme i will include it here in next PR
    disabled: false,
  },
  {
    label: 'QuickBooks Connected',
    variant: 'contained',
    onClick: 'handleQBODisconnect',
    section: ['mapInvoiceItems', 'customerMapping', 'invoices'],
    visibiltyType: 'connected',
    styles: { backgroundColor: '#2FA137' }, //once the color is been added to the theme i will include it here in next PR
    disabled: false,
  },
  {
    label: 'Update QuickBooks Item Category',
    variant: 'outlined',
    onClick: 'handleUpdateQBOCategory',
    section: ['mapInvoiceItems'],
    disabled: true,
    styles: { border: '1px solid #fff', color: '#fff' },
  },
  {
    label: 'Send To QuickBooks',
    variant: 'outlined',
    onClick: 'handleSendToQBO',
    section: ['customerMapping'],
    disabled: true,
    styles: { border: '1px solid #fff', color: '#fff' },
  },
  {
    label: 'Fetch Customers',
    variant: 'outlined',
    onClick: 'handleFetchCustomer',
    section: ['customerMapping'],
    disabled: true,
    styles: { border: '1px solid #fff', color: '#fff' },
  },
  {
    label: 'Refresh',
    variant: 'outlined',
    onClick: 'handleRefreshClassMapping',
    section: ['classMapping'],
    disabled: true,
    styles: { border: '1px solid #fff', color: '#fff' },
  },
];

export const customerTableColumnConfig = [
  {
    field: 'name',
    headerName: 'Customer',
    width: 250,
  },
  {
    field: 'synch',
    headerName: 'QuickBooks Sync Status',
    width: 250,
    valueFormatter: (params: any) => {
      return params.value === true ? 'Synced' : 'Not Synced';
    },
  },
];

export enum AccountingPopupEnum {
  CUSTOMERMAPPING = 'customerMapping',
  INVOICES = 'invoices',
  CLASS_MAPPING = 'classMapping',
  MAPINVOICEITEMS = 'mapInvoiceItems',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  CUSTOMER = 'Customer',
  ELD = 'eld',
  LOADBOARD = 'loadboard',
  FINANCE = 'finance',
  EDIACTIVATION = 'EDIActivation',
  LOADTENDERS = 'loadTenders',
  FUEL_CARDS = 'fuelCards',
  HANDLEQBOCONNECT = 'handleQBOConnect',
  HANDLEQBODISCONNECT = 'handleQBODisconnect',
  HANDLEUPDATEQBOCATEGORY = 'handleUpdateQBOCategory',
  HANDLESENDTOQBO = 'handleSendToQBO',
  HANDLEFETCHCUSTOMER = 'handleFetchCustomer',
  HANDLEREFRESHCLASSMAPPING = 'handleRefreshClassMapping',
  SENDTOQUICKBOOKS = 'Send To QuickBooks',
  CUSTOMERFILTER = 'customerFilter',
  STATUSFILTER = 'statusFilter',
}

export const quickbookInvoiceColumns: GridColDefSelf[] = [
  {
    flex: 1,
    field: 'seqNumber',
    headerName: InvoicesConstants.invoiceNumber,
    sortable: true,
    minWidth: 150,
  },
  {
    flex: 1,
    field: 'loadStatus',
    headerName: CommonConstants.invoiceStatus,
    sortable: true,
    minWidth: 150,
    renderCell: (params) => <LoadStatus status={params.value} />,
  },
  {
    flex: 1,
    field: 'customerName',
    headerName: 'Customer',
    sortable: false,
    minWidth: 150,
    renderCell: (params) => (
      <>
        {!params.row.customer.synch && (
          <Tooltip title={'Customer in not synced with QB.'} placement={'top'}>
            <Box sx={{ mr: 1 }}>
              <ErrorOutlinedIcon />
            </Box>
          </Tooltip>
        )}
        {params.row.customer.name}
      </>
    ),
  },
  {
    flex: 1,
    field: 'mappingEntityName',
    headerName: 'Class Mapping',
    sortable: false,
    minWidth: 150,
  },
  {
    flex: 1,
    field: 'terminal',
    headerName: 'Terminal',
    sortable: false,
    minWidth: 150,
  },
  {
    flex: 1,
    field: 'invoicedOn',
    headerName: 'Invoice On',
    sortable: true,
    minWidth: 150,
    renderCell: (params) =>
      getFormattedDateForGrid(fromIsoToMoment(params.value)),
  },
  {
    flex: 1,
    field: 'total',
    headerName: InvoicesConstants.invoiceTotal,
    sortable: false,
    minWidth: 150,
    renderCell: (params) => {
      return (
        <Typography
          variant="body2"
          sx={[
            {
              color: 'text.primary',
              fontSize: '14px',
              lineHeight: '21px',
              fontWeight: 700,
            },
          ]}
        >
          ${numberWithThousandSeparator(params.value)}
        </Typography>
      );
    },
  },
  {
    flex: 1,
    field: 'amountDue',
    headerName: InvoicesConstants.amountDue,
    sortable: false,
    minWidth: 150,
    renderCell: (params) => {
      return (
        <Typography
          variant="body2"
          sx={[
            {
              color: 'text.primary',
              fontSize: '14px',
              lineHeight: '21px',
              fontWeight: 700,
            },
          ]}
        >
          ${numberWithThousandSeparator(params.value)}
        </Typography>
      );
    },
  },
  {
    flex: 1,
    field: 'synchStatus',
    headerName: 'QuickBooks Status',
    sortable: true,
    minWidth: 150,
    renderCell: (params) => QBStatusMap[params.value],
  },
];

export const quickbookInvoiceFilterColumnConfig = [
  {
    key: 1,
    name: 'synchStatus',
    fieldName: 'label',
    options: [
      { label: 'Synced', value: 'SYNCED' },
      { label: 'Non-synced', value: 'NON_SYNCED' },
      { label: 'Modified', value: 'MODIFIED' },
      { label: 'Suppressed', value: 'SUPPRESSED' },
      { label: 'Paid', value: 'PAID' },
    ],
    label: 'QB Status',
    default: true,
    type: 'SingleAutocomplete',
  },
  {
    key: 2,
    name: 'loadStatusList',
    fieldName: 'name',
    getData: () => [
      { name: 'Invoiced', value: 'INVOICED' },
      { name: 'Paid', value: 'PAID' },
    ],
    label: CommonConstants.invoiceStatus,
    default: true,
    type: 'MultipleAutocomplete',
  },
  {
    key: 3,
    name: 'invoicedOn',
    fieldName: 'name',
    startText: 'Invoiced From',
    endText: 'Invoiced To',
    label: CommonConstants.invoicedOn,
    default: true,
    type: 'DateRangePicker',
  },
];

export const quickbookInvoiceDefaultFilters = {
  synchStatus: null,
  invoicedOn: null,
  loadStatusList: null,
};

export const QBStatusMap: { [key: string]: string } = {
  SYNCED: 'Synced',
  NON_SYNCED: 'Non-synced',
  MODIFIED: 'Modified',
  SUPPRESSED: 'Suppressed',
  PAID: t('paid'),
};

export const warningsMap: {
  [key: string]: {
    title: string;
    subTitle: string;
    actionName: string;
    buttonText: string;
  };
} = {
  suppress: {
    title: 'Are you sure you want to suppress invoice(s)?',
    subTitle:
      'Please note that once suppressed the invoice will not be subject to sending to QuickBooks.',
    actionName: 'suppress',
    buttonText: 'Suppress',
  },
  release: {
    title: 'Are you sure you want to release invoice(s)?',
    subTitle: '',
    actionName: 'release',
    buttonText: 'Release',
  },
  sendBack: {
    title:
      'Are you sure you want to sync back the QB status for the selected invoice(s)? ',
    subTitle:
      'Note: Received Payments on QuickBooks will overwrite the received payments on LoadOps',
    actionName: 'syncBack',
    buttonText: 'Sync Back',
  },
};

export const textInputStyles = {
  outline: 'none',
  '.MuiInput-root': {
    '&:hover': {
      '&:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
    '&::before': {
      borderBottom: 'none',
    },
  },
};

export const dropdownStyles = {
  width: '100%',
  '.MuiInput-root': {
    '&::after': {
      border: 'none',
    },
  },
  '.MuiAutocomplete-clearIndicator': {
    display: 'none',
  },
};

export const qclassMappingDefaultFilters = {
  mapped: null,
  tractorIds: null,
  driverIds: null,
};
