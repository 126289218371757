import React, { FC } from 'react';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import { StyledButton, StyledButtonGroup } from '../gantt/Filters/styles';
import { Tooltip, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FullScreenButton {
  onClick?: () => void;
  styles?: object;
}

const FullScreenButton: FC<FullScreenButton> = ({ onClick, styles }) => {
  const { t, ready } = useTranslation();
  if (ready) {
    return (
      <StyledButtonGroup sx={styles}>
        <Tooltip title={t('FullScreenButtonMaximize')} arrow placement="top">
          <Box>
            <StyledButton sx={{ height: '28px' }} onClick={onClick}>
              <FullscreenOutlinedIcon
                fontSize="small"
                sx={{
                  color: 'primary.main',
                }}
              />
            </StyledButton>
          </Box>
        </Tooltip>
      </StyledButtonGroup>
    );
  }
};

export default FullScreenButton;
