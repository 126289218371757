import Box from '@mui/material/Box';

const NoData = () => {
  return (
    <Box sx={{ padding: '35px', textAlign: 'center', color: 'primary.main' }}>
      No data to show.
    </Box>
  );
};

export default NoData;
