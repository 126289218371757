import { Skeleton, Stack, Typography } from '@mui/material';
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { loadService, tripService } from '../../../../api';
import { ServiceError } from '../../../../api/interfaces';
import HyperLink from '../../../../common/Ui/HyperLink/HyperLink';
import {
  GetLoadOriginDestinationRequest,
  PaginatedLoadListRequest,
  Trip,
  TripCarrierDetails,
  TripConnectedLoad,
  TripDestinationOption,
  TripGroupDetail,
} from '../../../../models';
import { PaginatedTripLightListRequest } from '../../../../models/DTOs/Trip/Requests';
import LoadController from '../../../../subPages/loadsList/LoadController';
import TractorController from '../../../../subPages/tractors/Controller';
import TrailerController from '../../../../subPages/trailers/TrailerController';
import { GridColDefSelf } from '../../../../types';
import { numberWithThousandSeparator } from '../../../../utils';
import {
  SETTLEMENT_STATUS_LIST,
  TRIP_STATUS_LIST,
  getBrokeredYesNoOptions,
  tripFieldsConfig,
} from '../../constants/constants';
import { IFiltersFormConfig, ITripFilters } from '../../constants/types';
import { displayTripSettlementStatus } from '../../utils';

import { LoadViewTranslations } from '@/locales/en/allLoads/loads/modal';
import { TagListComponent } from '@/views/customTags/customTagChip';
import { t } from 'i18next';
import { ELoadStatus } from '../../../../common/LoadTabPanel/constants/constants';
import { statesinCountry } from '../../../../common/Ui/AddressField/constants/states';
import { RelativeDateRangeUtils } from '../../../../common/Ui/RelativeDateRange/RelativeDateRange.utils';
import { tripLoadCustomTitle } from '../../../../common/UiUtils/uiUtils';
import {
  getLoadReferenceIds,
  getLoadSeqNumbers,
  getPaginatedTagsList,
} from '../../../../subPages/invoices/utils';
import { LoadPriorityEnumVals } from '../../../../subPages/loadsList/CreateLoad/constants/fieldValues';
import WarningComponent from '../../../../subPages/loadsList/components/WarningComponent';
import { renderDateTime } from '../../../../utils/NameAndDateTimeGridField';
import { getDriverNamesList } from '../../../settings/users/utils';
import { getCarrierNamesList } from '../../services/api.utils';

export const defaultFilters: ITripFilters = {
  [tripFieldsConfig.tripStatus.fieldFilterName!]: [],
  [tripFieldsConfig.driver.fieldFilterName!]: [],
  [tripFieldsConfig.dispatcher.fieldFilterName!]: [],
  [tripFieldsConfig.carrierDetails.fieldFilterName!]: [],
  [tripFieldsConfig.brokerageTrip.fieldFilterName!]: [],
} as unknown as ITripFilters;

export const allTripFilters: ITripFilters = {
  [tripFieldsConfig.tripStatus.fieldFilterName!]: [],
} as unknown as ITripFilters;

interface GridProps {
  // theme: Theme;
  [key: string]: any;
}

const renderLoadData = (
  params: GridRenderCellParams<undefined, Trip>,
  field: string
) => {
  return params.row?.connectedLoads?.map(
    (load: TripConnectedLoad, index: number) => (
      <span key={index}>
        {!!index && load[field as keyof TripConnectedLoad] && ', '}
        {load[field as keyof TripConnectedLoad]}
      </span>
    )
  );
};
const renderCustomerLoadData = (
  params: GridRenderCellParams<undefined, Trip>,
  field: string
) => {
  return params.row?.connectedLoads?.map(
    (load: TripConnectedLoad, index: number) => (
      <span key={index}>
        {!!index && load[field as keyof TripConnectedLoad] && ', '}
        {load?.customer?.name}
      </span>
    )
  );
};

export default class TripGridConfig {
  name: string;

  config: {
    columns: GridColDefSelf[];
    title: string;
    getFiltersFormConfig: () => IFiltersFormConfig[];
    defFilters: ITripFilters;
  };

  constructor(props: GridProps) {
    this.name = 'GridConfiguration';
    this.config = TripGridConfig.getConfiguration(props);
  }

  static getConfiguration(props: GridProps) {
    return TripGridConfig.getDefaultConfiguration(props);
  }

  static getDefaultConfiguration(props) {
    return {
      title: t('tableView'),
      columns: [
        {
          flex: 1,
          minWidth: 60,
          field: tripFieldsConfig.warning.fieldName,
          headerName: tripFieldsConfig.warning.label,
          permanent: true,
          sortable: false,
          renderCell: (params) => (
            <WarningComponent warning={params.row.warning} />
          ),
        },
        {
          flex: 1,
          minWidth: 80,
          field: tripFieldsConfig.tripId.fieldName,
          headerName: tripFieldsConfig.tripId.label,
          permanent: true,
          sortable: true,
        },
        {
          field: tripFieldsConfig.tripStatus.fieldName,
          headerName: tripFieldsConfig.tripStatus.label,
          minWidth: 180,
          renderCell: (params: GridRenderCellParams<ELoadStatus>) => {
            const tripData = params.row;
            return tripLoadCustomTitle({
              status: tripData.status,
              lastUpdatedStop: tripData.lastUpdatedStop,
              nonInvoiceable: false,
              onHold: !!tripData.onHold,
              onHoldNote: tripData.onHoldNote,
            });
          },
          sortable: false,
        },
        {
          flex: 1,
          minWidth: 200,
          field: 'tags',
          headerName: 'Tags',
          sortable: false,
          renderCell: (params) => {
            return (
              <Stack
                direction="row"
                flexWrap="wrap"
                gap={1}
                sx={{ mt: '5px', mb: '5px' }}
              >
                <TagListComponent tagList={params.row.tags} />
              </Stack>
            );
          },
        },
        {
          field: tripFieldsConfig.brokerageTrip.fieldName,
          headerName: tripFieldsConfig.brokerageTrip.label,
          minWidth: 180,
          renderCell: (params: GridRenderCellParams<boolean>) => {
            return params.value ? t('brokered') : <></>;
          },
          sortable: true,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.driver.fieldName,
          headerName: tripFieldsConfig.driver.label,
          renderCell: (params: GridRenderCellParams<TripGroupDetail>) => {
            return (
              params.row?.groupDetails && (
                <HyperLink value={params.row?.groupDetails?.name} />
              )
            );
          },
          sortable: false,
          minWidth: 100,
          flex: 1,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.carrierDetails.fieldName,
          headerName: tripFieldsConfig.carrierDetails.label,
          renderCell: (params: GridRenderCellParams<TripCarrierDetails>) => {
            return (
              params.value?.name && <HyperLink value={params.value?.name} />
            );
          },
          sortable: false,
          minWidth: 100,
          flex: 1,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.dispatcher.fieldName,
          headerName: tripFieldsConfig.dispatcher.label,
          renderCell: (params: GridRenderCellParams) => {
            return <HyperLink value={params.value} />;
          },
          sortable: false,
          minWidth: 100,
          flex: 1,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.tractor.fieldName,
          headerName: tripFieldsConfig.tractor.label,
          minWidth: 100,
          flex: 1,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) => {
            const name = params.row.assignment?.tractorName;
            return name && <HyperLink value={name} />;
          },
          sortable: false,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.trailer.fieldName,
          headerName: tripFieldsConfig.trailer.label,
          minWidth: 100,
          flex: 1,
          renderCell: (params: GridRenderCellParams<undefined, Trip>) => {
            const name = params.row.assignment?.trailerName;
            return name && <HyperLink value={name} />;
          },
          sortable: false,
        },
        {
          field: tripFieldsConfig.chassis.fieldName,
          headerName: tripFieldsConfig.chassis.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) => {
            return renderLoadData(params, 'chassisNumber');
          },
        },
        {
          field: tripFieldsConfig.container.fieldName,
          headerName: tripFieldsConfig.container.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) => {
            return renderLoadData(params, 'containerNumber');
          },
        },
        {
          field: tripFieldsConfig.legs.fieldName,
          headerName: tripFieldsConfig.legs.label,
          valueGetter: (params: GridValueGetterParams<undefined, Trip>) => {
            return params.row.numberOfStops
              ? params.row.numberOfStops - 1
              : null;
          },
          minWidth: 80,
          flex: 1,
          sortable: false,
        },
        {
          field: tripFieldsConfig.loadPriority.fieldName,
          headerName: tripFieldsConfig.loadPriority.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) => {
            return (
              <Typography
                variant="body2"
                {...(params?.row?.loadPriority === 'CRITICAL'
                  ? { color: 'error' }
                  : {})}
              >
                {LoadPriorityEnumVals[params?.row?.loadPriority!] ?? ''}
              </Typography>
            );
          },
        },
        {
          field: tripFieldsConfig.canBeBrokered.fieldName,
          headerName: tripFieldsConfig.canBeBrokered.label,
          minWidth: 130,
          renderCell: (params: any) => {
            if (params?.row?.canBeBrokered == null) return '';
            return params?.row?.canBeBrokered ? 'Yes' : 'No';
          },
          sortable: false,
        },
        {
          field: tripFieldsConfig.isEmptyTrip.fieldName,
          headerName: tripFieldsConfig.isEmptyTrip.label,
          minWidth: 130,
          renderCell: (params: any) => {
            if (params?.row?.isEmptyTrip == null) return '';
            return params?.row?.isEmptyTrip ? 'Yes' : 'No';
          },
          sortable: false,
        },
        {
          field: tripFieldsConfig.tripStart.fieldName,
          headerName: tripFieldsConfig.tripStart.label,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) =>
            params.row.fromLocationName,
          minWidth: 200,
          sortable: true,
        },
        {
          field: tripFieldsConfig.tripEnd.fieldName,
          headerName: tripFieldsConfig.tripEnd.label,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) =>
            params.row.toLocationName,
          minWidth: 200,
          sortable: false,
        },
        {
          field: tripFieldsConfig.tripStartDate.fieldName,
          headerName: tripFieldsConfig.tripStartDate.label,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) =>
            params.row?.startDateTime
              ? renderDateTime(
                  params.row.startDateTime,
                  params.row.firstStopTimeZone
                )
              : null,
          minWidth: 200,
          sortable: true,
        },
        {
          field: tripFieldsConfig.tripEndDate.fieldName,
          headerName: tripFieldsConfig.tripEndDate.label,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) =>
            params.row?.endDateTime
              ? renderDateTime(
                  params.row.endDateTime,
                  params.row.lastStopTimeZone
                )
              : null,
          minWidth: 200,
          sortable: true,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.loadId.fieldName,
          headerName: tripFieldsConfig.loadId.label,
          minWidth: 80,
          flex: 1,
          renderCell: (
            params: GridRenderCellParams<TripConnectedLoad[], Trip>
          ) => {
            return params.value
              ?.filter((load) => !!load.loadSequenceNumber)
              ?.map((load, index) => {
                return (
                  load.loadSequenceNumber && (
                    <>
                      <HyperLink
                        id={load?.loadId}
                        value={load.loadSequenceNumber}
                      />
                      {index === params.value.length - 1 ? '' : ', '}
                    </>
                  )
                );
              });
          },
          sortable: false,
        },
        {
          isHyperLink: true,
          field: tripFieldsConfig.manifestId.fieldName,
          headerName: tripFieldsConfig.manifestId.label,
          minWidth: 80,
          flex: 1,
          renderCell: (
            params: GridRenderCellParams<TripConnectedLoad[], Trip>
          ) => {
            return (
              <HyperLink
                id={params.row.manifestBaseDetails?.manifestId}
                value={params.row.manifestBaseDetails?.seqNumber}
              />
            );
          },
          sortable: false,
        },
        // {
        //   field: tripFieldsConfig.onHoldState.fieldName,
        //   headerName: tripFieldsConfig.onHoldState.label,
        //   renderCell: () => {
        //     return null;
        //   },
        // },
        {
          isHyperLink: true,
          field: tripFieldsConfig.customer.fieldName,
          headerName: tripFieldsConfig.customer.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) => {
            return renderCustomerLoadData(params, 'customer');
          },
        },
        {
          field: tripFieldsConfig.reference.fieldName,
          headerName: tripFieldsConfig.reference.label,
          minWidth: 100,
          flex: 1,
          sortable: false,
          renderCell: (params: GridValueGetterParams<undefined, Trip>) => {
            return renderLoadData(params, 'referenceId');
          },
        },
        {
          field: tripFieldsConfig.revenue.fieldName,
          headerName: tripFieldsConfig.revenue.label,
          align: 'right',
          minWidth: 120,
          maxWidth: 160,
          headerAlign: 'center',
          renderCell: (params: GridRenderCellParams) => {
            if (isNaN(params.value)) return '';

            return (
              <Typography
                variant="body2"
                sx={[
                  {
                    color: 'text.primary',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 700,
                  },
                ]}
              >
                {params.value === 'loading' ? (
                  <Skeleton variant="text" width="5rem" />
                ) : (
                  '$' + numberWithThousandSeparator(params.value, false, 2)
                )}
              </Typography>
            );
          },
          flex: 1,
          sortable: true,
        },
        {
          field: tripFieldsConfig.settlementStatus.fieldName,
          headerName: tripFieldsConfig.settlementStatus.label,
          minWidth: 150,
          flex: 1,
          renderCell: (
            params: GridValueGetterParams<Trip['settlementStatus'], Trip>
          ) => displayTripSettlementStatus(params.value),
        },
        {
          field: tripFieldsConfig.settlementAmount.fieldName,
          headerName: tripFieldsConfig.settlementAmount.label,
          align: 'right',
          headerAlign: 'right',
          renderCell: (params: GridRenderCellParams) => {
            if (isNaN(params.value)) return '';
            return (
              <Typography
                variant="body2"
                sx={[
                  {
                    color: 'text.primary',
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 700,
                  },
                ]}
              >
                {params.value === 'loading' ? (
                  <Skeleton variant="text" width="5rem" />
                ) : (
                  '$' + numberWithThousandSeparator(params.value, false, 2)
                )}
              </Typography>
            );
          },
          minWidth: 180,
          flex: 1,
          sortable: false,
        },
        {
          field: tripFieldsConfig.tripMiles.fieldName,
          headerName: tripFieldsConfig.tripMiles.label,
          minWidth: 80,
          flex: 1,
          renderCell: (params: GridRenderCellParams) => {
            return numberWithThousandSeparator(params.value);
          },
          sortable: false,
        },
        {
          field: tripFieldsConfig.emptyMiles.fieldName,
          headerName: tripFieldsConfig.emptyMiles.label,
          minWidth: 100,
          flex: 1,
          renderCell: (params: GridRenderCellParams) => {
            return numberWithThousandSeparator(params.value);
          },
          sortable: false,
        },
        {
          sortable: false,
          field: tripFieldsConfig.terminal.fieldName,
          headerName: tripFieldsConfig.terminal.label,
          minWidth: 160,
          flex: 1,
          renderCell: (
            params: GridRenderCellParams<Trip['terminal'], Trip>
          ) => {
            return params.value?.name;
          },
        },
        {
          sortable: true,
          field: tripFieldsConfig.createDate.fieldName,
          headerName: tripFieldsConfig.createDate.label,
          minWidth: 200,
          flex: 1,
          renderCell: (
            params: GridRenderCellParams<Trip['createDate'], Trip>
          ) =>
            params.value ? renderDateTime(params.value, props.timezone) : null,
        },
      ],
      getFiltersFormConfig: (): IFiltersFormConfig[] => {
        const filtersConfigs = [
          {
            key: 1,
            default: false,
            label: tripFieldsConfig.tripId.label,
            name: tripFieldsConfig.tripId.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'label',
            getData: async (searchString: string, pageNumber: number) => {
              const response = await tripService.getPaginatedTripLightList(
                new PaginatedTripLightListRequest({
                  seqNumber: searchString,
                  pageNumber,
                  pageSize: 20,
                })
              );
              if (response instanceof ServiceError) {
                return {
                  content: [],
                  last: true,
                };
              }
              return {
                ...response,
                content: response?.content.map(({ tripIds, seqNumber }) => ({
                  label: seqNumber,
                  value: tripIds,
                })),
              };
            },
          },
          {
            key: 2,
            default: true,
            label: tripFieldsConfig.tripStatus.label,
            name: tripFieldsConfig.tripStatus.fieldFilterName,
            getData: async () => {
              return {
                content: TRIP_STATUS_LIST,
                last: true,
              };
            },
            type: 'MultipleAutocomplete',
            fieldName: 'label',
          },
          {
            key: 2,
            default: false,
            label: tripFieldsConfig.brokerageTrip.label,
            name: tripFieldsConfig.brokerageTrip.fieldFilterName,
            options: getBrokeredYesNoOptions(),
            type: 'ButtonGroup',
            multiple: true, //   type: 'MultipleAutocomplete',
            fieldName: 'label',
            fieldValue: 'value',
          },
          {
            key: 3,
            default: true,
            label: tripFieldsConfig.driver.label,
            name: tripFieldsConfig.driver.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'name',
            getData: getDriverNamesList,
          },
          {
            key: 3,
            default: false,
            label: tripFieldsConfig.carrierDetails.label,
            name: tripFieldsConfig.carrierDetails.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'name',
            getData: getCarrierNamesList,
          },
          {
            key: 4,
            default: false,
            name: tripFieldsConfig.tractor.fieldFilterName,
            fieldName: 'name',
            getData: (searchText: string | undefined, pageNumber: number) =>
              TractorController.instance().getTractorLightListByNameSearch(
                searchText,
                pageNumber,
                [],
                ['INACTIVE']
              ),
            label: tripFieldsConfig.tractor.label,
            type: 'MultipleAutocomplete',
          },
          {
            key: 5,
            default: false,
            name: tripFieldsConfig.trailer.fieldFilterName,
            fieldName: 'name',
            getData: (searchText: string | undefined, pageNumber: number) =>
              TrailerController.instance().getTrailerLightListByNameSearch(
                searchText,
                pageNumber,
                ['INACTIVE']
              ),
            label: tripFieldsConfig.trailer.label,
            type: 'MultipleAutocomplete',
          },
          {
            key: 6,
            default: false,
            name: tripFieldsConfig.chassis.fieldName,
            fieldName: 'value',
            getData: (searchText: string | undefined, pageNumber: number) =>
              LoadController.instance().getChassisSearch(
                searchText,
                pageNumber
              ),
            label: tripFieldsConfig.chassis.label,
            type: 'MultipleAutocomplete',
          },
          {
            key: 7,
            default: false,
            name: tripFieldsConfig.container.fieldName,
            fieldName: 'value',
            getData: (searchText: string | undefined, pageNumber: number) =>
              LoadController.instance().getContainerSearch(
                searchText,
                pageNumber
              ),
            label: tripFieldsConfig.container.label,
            type: 'MultipleAutocomplete',
          },
          {
            key: 8,
            default: true,
            label: tripFieldsConfig.dispatcher.label,
            name: tripFieldsConfig.dispatcher.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'name',
            getData: async (...params: any) => {
              return LoadController.instance().getDispatcherSummary(...params);
            },
          },
          {
            key: 9,
            default: false,
            label: tripFieldsConfig.loadId.label,
            name: tripFieldsConfig.loadId.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'label',
            getData: async (searchString: string, pageNumber: number) => {
              const request = new PaginatedLoadListRequest();
              request.pageNumber = pageNumber;
              request.pageSize = 20;
              request.seqNumber = searchString;

              const response = await loadService.getLoadLightListByNameSearch(
                request
              );
              if (response instanceof ServiceError) {
                return {
                  content: [],
                  last: true,
                };
              } else {
                return {
                  content: response?.content?.map((item) => {
                    return {
                      value: item?.loadIds,
                      label: item?.seqNumber,
                    };
                  }),
                  last: !response?.content?.length,
                };
              }
            },
          },
          {
            //   key: 10,
            //   default: false,
            //   label: tripFieldsConfig.manifestId.label,
            //   name: tripFieldsConfig.manifestId.fieldName,
            //   type: 'MultipleAutocomplete',
            //   fieldName: 'seqNumber',
            //   getData: (searchText: string, pageNumber: number) =>
            //   getLoadSeqNumbers(searchText, pageNumber, [], [], 'MANIFEST'),
            // },

            key: 10,
            name: 'manifestIdsFilter',
            fieldName: 'seqNumber',
            getData: (searchText: string, pageNumber: number) =>
              getLoadSeqNumbers(searchText, pageNumber, [], [], 'MANIFEST'),
            label: 'Manifest ID',
            default: false,
            type: 'MultipleAutocomplete',
          },
          {
            key: 10,
            default: false,
            label: tripFieldsConfig.customer.label,
            name: tripFieldsConfig.customer.fieldFilterName,
            getData: LoadController.instance().getCustomerForFilter,
            type: 'MultipleAutocomplete',
            fieldName: 'value',
          },
          {
            key: 11,
            default: false,
            label: tripFieldsConfig.reference.label,
            name: tripFieldsConfig.reference.fieldName,
            getData: getLoadReferenceIds as (
              searchString: string,
              pageNumber: number
            ) => any,
            type: 'MultipleAutocomplete',
            fieldName: 'referenceId',
          },
          {
            key: 12,
            default: false,
            label: tripFieldsConfig.settlementStatus.label,
            name: tripFieldsConfig.settlementStatus.fieldFilterName,
            options: SETTLEMENT_STATUS_LIST,
            type: 'ButtonGroup',
            multiple: true,
            fieldName: 'label',
            fieldValue: 'value',
          },
          {
            key: 13,
            default: false,
            label: tripFieldsConfig.tripStart.label,
            name: tripFieldsConfig.tripStart.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'label',
            getData: async (searchString: string, pageNumber: number) => {
              const requestData = new GetLoadOriginDestinationRequest();
              requestData.pageNumber = pageNumber;
              requestData.pageSize = 25;
              requestData.city = searchString;

              const response = await loadService.getLoadOriginDestinationList(
                requestData
              );
              if (response instanceof ServiceError) {
                return {
                  content: [],
                  last: true,
                };
              } else {
                return {
                  ...response,
                  content: response.content
                    .map((r) => new TripDestinationOption(r))
                    .map((item) => {
                      return {
                        value: item.value,
                        label: item.displayName,
                      };
                    }),
                };
              }
            },
          },
          {
            key: 14,
            default: false,
            label: tripFieldsConfig.tripEnd.label,
            name: tripFieldsConfig.tripEnd.fieldFilterName,
            type: 'MultipleAutocomplete',
            fieldName: 'label',
            getData: async (searchString: string, pageNumber: number) => {
              const requestData = new GetLoadOriginDestinationRequest();
              requestData.pageNumber = pageNumber;
              requestData.pageSize = 25;
              requestData.city = searchString;

              const response = await loadService.getLoadOriginDestinationList(
                requestData
              );
              if (response instanceof ServiceError) {
                return {
                  content: [],
                  last: true,
                };
              } else {
                return {
                  ...response,
                  content: response.content
                    .map((r) => new TripDestinationOption(r))
                    .map((item) => {
                      return {
                        value: item.value,
                        label: item.displayName,
                      };
                    }),
                };
              }
            },
          },
          {
            key: 11,
            default: false,
            label: tripFieldsConfig.tripStartDateRanges.label,
            name: tripFieldsConfig.tripStartDateRanges.fieldFilterName,
            ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
          },
          {
            key: 13,
            default: false,
            label: tripFieldsConfig.tripEndDateRanges.label,
            name: tripFieldsConfig.tripEndDateRanges.fieldFilterName,
            ...RelativeDateRangeUtils.getGenericDateRangeFilter(),
          },
          {
            key: 15,
            default: false,
            label: tripFieldsConfig.tripStartState.label,
            name: tripFieldsConfig.tripStartState.fieldName,
            type: 'MultipleAutocomplete',
            fieldName: 'name',
            getData: async () => ({
              content: statesinCountry,
              last: true,
            }),
          },
          {
            key: 16,
            default: false,
            label: tripFieldsConfig.tripEndState.label,
            name: tripFieldsConfig.tripEndState.fieldName,
            type: 'MultipleAutocomplete',
            getData: async () => ({
              content: statesinCountry,
              last: true,
            }),
            fieldName: 'name',
          },
          {
            key: 17,
            default: false,
            label: tripFieldsConfig.relay.label,
            name: tripFieldsConfig.relay.fieldName,
            type: 'GridSwitchFilter',
            fieldName: 'label',
            fieldValue: 'value',
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ],
          },
          {
            key: 18,
            default: false,
            label: tripFieldsConfig.delayViolations.label,
            name: tripFieldsConfig.delayViolations.fieldFilterName,
            type: 'GridSwitchFilter',
            fieldName: 'label',
            fieldValue: 'value',
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: undefined,
              },
            ],
          },
          {
            key: 19,
            default: false,
            label: tripFieldsConfig.detention.label,
            name: tripFieldsConfig.detention.fieldFilterName,
            type: 'GridSwitchFilter',
            fieldName: 'label',
            fieldValue: 'value',
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: undefined,
              },
            ],
          },
          {
            key: 20,
            default: false,
            label: tripFieldsConfig.needDriversNow.label,
            name: tripFieldsConfig.needDriversNow.fieldFilterName,
            type: 'GridSwitchFilter',
            fieldName: 'label',
            fieldValue: 'value',
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: undefined,
              },
            ],
          },
          {
            key: 21,
            default: false,
            label: tripFieldsConfig.isEmptyTrip.label,
            name: tripFieldsConfig.isEmptyTrip.fieldFilterName,
            type: 'GridSwitchFilter',
            fieldName: 'label',
            fieldValue: 'value',
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: undefined,
              },
            ],
          },
          // TODO Commented and Enable for feature
          // {
          //   key: 2,
          //   label: tripFieldsConfig.onHoldState.label,
          //   name: tripFieldsConfig.onHoldState.fieldFilterName,
          //   options: [
          //     { value: true, label: 'Yes' },
          //     { value: null, label: 'No' },
          //   ],
          //   type: 'GridSwitchFilter',
          //   multiple: false,
          //   fieldName: 'label',
          //   fieldValue: 'value',
          // },
          {
            key: 22,
            default: false,
            label: tripFieldsConfig.driverOperationMode.label,
            name: tripFieldsConfig.driverOperationMode.fieldFilterName,
            type: 'ButtonGroup',
            multiple: false,
            fieldName: 'label',
            fieldValue: 'value',
            options: [
              {
                label: 'Solo',
                value: 'SOLO',
              },
              {
                label: 'Team',
                value: 'TEAM',
              },
            ],
          },
          {
            key: 26,
            name: 'tagIds',
            fieldName: 'name',
            getData: getPaginatedTagsList,
            label: LoadViewTranslations.loadTags,
            type: 'MultipleAutocomplete',
            isCustomTag: true,
          },
        ];
        return filtersConfigs.map((config, index) => {
          return {
            ...config,
            key: index + 1,
          };
        });
      },
      defFilters: defaultFilters,
    };
  }
}
