import { Box, Popper, Tooltip } from '@mui/material';
import { numberFormat } from '../../views/reports/basicReports/constant';

const CustomGridCell = (props) => {
  let { value, err, field, colDef } = props;
  if (colDef?.type == 'numeric' && value && !isNaN(value)) {
    value = numberFormat(value);
  }
  // if (field === 'rate' && value && value?.length > 0) {
  //   value = `$${value}`;
  // }
  if (err) {
    return (
      <Tooltip
        placement="bottom-start"
        arrow
        sx={{ height: '100%', width: '100%' }}
        title={err}
      >
        <Box style={{ minWidth: '30px', maxWidth: '100%' }}>{value || ''}</Box>
      </Tooltip>
    );
  }
  return <span>{value || ''}</span>;
};

export default CustomGridCell;
