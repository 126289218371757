export const PreferencesConstants = {
  // Financial Goals
  preferencePageTitle: 'Financial Goals',
  preferencePageSubtitle:
    'Use this section to update the Financial components of the Dashboard. Each component helps you visualize if you are reaching your weekly goals.',
  targetRevenuePerMile: 'Target Revenue per Total Mile',
  targetRevenueSubtitle:
    'How much revenue would you like to make per mile? This is reflected when doing profit calculations.',
  targetRevenuePerLoadMile: 'Target Revenue per Loaded Mile ($)',
  targetRevenuePerLoadMileSubtitle:
    'How much revenue would you like to make per loaded mile? We use this to measure if you are reaching your goals.',
  targetWeeklyCompanyRevenue: 'Target Weekly Company Revenue',
  targetWeeklyCompanyRevenueSubtitle:
    'How much revenue would you like to make per week? We also use this to figure out the Target Revenue per Driver.',
  variableCostPerMile: 'Variable Cost per Mile ($)',
  variableCostPerMileSubtitle:
    'How much do you typically spend per mile? We use this to calculate $/day.',
  averageDailyCostPerTruck: 'Average Daily Cost Per Truck ($)',
  averageDailyCostPerTruckSubtitle:
    'What is your typical cost per truck? We use this to calculate $/day.',
  weeklyRevenue: 'Weekly Revenue ($)',
  dailyCostPerTruck: 'Daily Cost Per Truck ($)',
  // Invoice and Settlement
  invoiceAndSettlement: 'Invoice and Settlement',
  invoiceAndSettlementSubtitle:
    'These details are reflected on documents such as your customer invoices, driver settlements, and more.',
  invoiceTerms: 'Invoice Terms',
  invoiceTermsSubtitle: 'How long do your customers have to pay their invoice?',
  loadInvoiceIdPrefix: 'Load/Invoice ID Prefix',
  loadInvoiceIdPrefixSubtitle:
    'Would you like each Load ID to start with anything specific? (E.g. LD1222)',
  nextLoadInvoiceIdNumber: 'Next Load/Invoice ID Number',
  nextLoadInvoiceIdNumberSubtitle:
    'Which number would you like associated with the next load created?',
  settlementStartDate: 'Settlement Start Date',
  settlementStartDateSubtitle:
    'When would you like to begin driver settlements?',
  settlementPeriodLength: 'Settlement Period Length',
  settlementPeriodLengthSubtitle:
    'What is the length of each driver settlement?',
  noOfDays: 'Number of Days',
  loadIdPrefix: 'Load ID Prefix',
  // Loads
  loadsSubtitle:
    'We use these details to calculate metrics to help you evaluate load board results and efficiently create and manage your loads on the Web and Mobile App.',
  stopActivityTitle: 'Stop Activity Default Date and Time',
  stopActivitySubtitle:
    'This is helpful to quickly update a driver’s stop activities on an In Progress load. Find this by selecting a load, then clicking Update Progress.',
  activityAutofill: 'Activity Autofill Option for Load Stop',
  activityAutofillSubtitle:
    'This is helpful to quickly update a driver’s stop activities on an In Progress load. Find this by selecting a load, then clicking Update Progress.',
  stopActivityAutofill: 'Stop Activity Autofill Option',
  defaultEquipment: 'Default Equipment Type',
  defaultEquipmentSubtitle:
    'Which trailer type do you typically use per load? This is applied during load creation.',
  loadUnloadTimings: 'Collect Load/Unload Timings',
  loadUnloadTimingsSubtitle:
    'By default, the driver marks arrival at and departure from a stop. Enable this to also collect loading and unloading times.',
  calculateDetention: 'Calculate Detention',
  calculateDetentionSubtitle:
    'Calculate the detention time on each stops of load.',
  billOfLanding: 'Make Bill of Lading (BOL) Mandatory',
  billOfLandingSubtitle: 'Do you require this document uploaded on each load?',
  proofOfDelivery: 'Make Proof of Delivery (POD) Mandatory',
  proofOfDeliverySubtitle:
    'Do you require this document uploaded on each load?',
  documentCollectionMandatory: 'Make Document Collection Mandatory on Mobile',
  documentCollectionMandatorySubtitle:
    'Enable this to require Drives to upload load documents before the load completes.',
  // Driver
  driverSubtitle:
    'These details are used to determine driver HOS based on driver logs',
  dvirPref: 'DVIR Preference',
  dvirPrefSubtitle:
    'DVIR Preference is used in trip plan calculation to determine load ETAs.',
  workWeekType: 'Work Week Type',
  workWeekTypeSubtitle: 'Which HOS clock do your drivers follow?',
  preferredTruckStops: 'Preferred Truck Stops',
  preferredTruckStopsSubtitle:
    'Do you prefer a particular truck stop? We’ll consider this while creating trip plans.',
  ignoreHOS: 'Ignore HOS Restrictions',
  ignoreHOSSubtitle:
    'Do you want to ignore HOS restrictions while creating trip plans or while searching load on the load boards',
  sleeperBirth: 'Use Sleeper Berth Provision',
  sleeperBirthSubtitle: 'Will your drivers use the sleeper berth provision?',
  // Company Info
  companyInfo: 'Company Info',
  companyInfoSubtitle:
    'These details are reflected on documents such as your customer invoices, driver settlements. These will also be reflected on your primary terminal.',
  companyDOT: 'Company DOT',
  dotForCompany: 'DOT Number for Company',
  companyName: 'Company Name',
  companyNameSubtitle: 'Your Company’s Name, I mean what else is there to say?',
  companyLogo: 'Company Logo',
  companyLogoSubtitle:
    'Used for preparing Inovices (1MB maximum, JPG or PNG, 1200px wide max)',
  displayedOnInvoices: 'Displayed on invoices',
  emailSubtitle: 'Email used in Invoices and communication.',
  emailFrom: 'Email From',
  emailFromSubtitle: 'Default Reply email for all Invoices and communication.',
  companyAddress: 'Company Address',
  companyAddressSubtitle: 'Company address used in Invoices.',
  replyEmail: 'Reply Email',
  // Misc
  misc: 'Misc. Settings',
  miscSubtitle:
    'Here are some of the less used features, but we wanted you to have it just in case :)',
  defaultTimeZone: 'Default Time Zone',
  defaultTimeZoneSubtitle: 'Select the time zone of your primary location.',
  workStartWeek: 'Work Start Week',
  workStartWeekSubtitle: 'Select the day of the week you start the work week.',
  defaultLanguage: 'Default Language',
  defaultLanguageSubtitle: 'Select the default langauage.',
  apiKey: 'API Key',
  generateApiKey: 'Generate API Key',
  hideFinancial: 'Hide Financial KPIs from Non-Admin Users',
  hideFinancialSubtitle:
    'Only show important financial KPIs like profit and revenue to Admins in the system.',
};
