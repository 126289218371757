import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { i18n } from 'i18next';
import { ExpenseStatus } from '../../../../common/Ui/StatusComponent/StatusComponent';
import { UI_DATE_FORMAT } from '../../../../constants/date.constants';
import { GridColDefSelf } from '../../../../types';
import { getFormattedDateForGrid } from '../../../../utils';
import { currencyDisplay } from '../../../../utils/grid.utils';
import { EFinanceAccountSummaryExpense } from './types';

export const HyperLinkComp = ({ value }: { value: string | number }) => {
  return (
    <Typography variant="body2" color={'primary'}>
      {value || ''}
    </Typography>
  );
};
export function FASExpenseDetailsColumns({
  t,
}: {
  t: i18n['t'];
}): Record<EFinanceAccountSummaryExpense, GridColDefSelf[]> {
  return {
    [EFinanceAccountSummaryExpense.DRIVER_PAYMENT]: [
      {
        flex: 1,
        field: 'expenseDate',
        headerName: t('paymentDate'),
        sortable: true,
        valueGetter: (params) =>
          getFormattedDateForGrid(params.row.expenseDate, UI_DATE_FORMAT),
      },
      {
        flex: 1,
        field: 'paidTo',
        headerName: t('driverName'),
        sortable: false,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.paidTo || ''} />
        ),
      },
      {
        flex: 1,
        field: 'settlementSeqNumber',
        headerName: t('settlementId'),
        sortable: true,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.settlementSeqNumber || ''} />
        ),
      },
      {
        flex: 1,
        field: 'loadSeqNumber',
        headerName: 'Trip ID',
        sortable: true,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.loadSeqNumber || ''} />
        ),
      },
      {
        flex: 1,
        field: 'expenseType',
        headerName: t('paymentType'),
        sortable: false,
      },
      {
        flex: 1,
        field: 'description',
        headerName: 'Description',
        sortable: false,
      },
      {
        flex: 1,
        field: 'amount',
        headerName: 'Amount',
        sortable: true,
        renderCell: (params: { row: { amount: number } }) => {
          return currencyDisplay(params.row?.amount);
        },
      },
    ],
    [EFinanceAccountSummaryExpense.TRACTOR_PAYMENT]: [
      {
        flex: 1,
        field: 'expenseDate',
        headerName: t('paymentDate'),
        sortable: true,
        valueGetter: (params) =>
          getFormattedDateForGrid(params.row.expenseDate, UI_DATE_FORMAT),
      },
      {
        flex: 1,
        field: 'tractorName',
        headerName: 'Tractor Name',
        sortable: false,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.tractorName || ''} />
        ),
      },
      {
        flex: 1,
        field: 'settlementSeqNumber',
        headerName: t('settlementId'),
        sortable: true,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.settlementSeqNumber || ''} />
        ),
      },
      {
        flex: 1,
        field: 'loadSeqNumber',
        headerName: 'Trip ID',
        sortable: true,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.loadSeqNumber || ''} />
        ),
      },
      {
        flex: 1,
        field: 'expenseType',
        headerName: t('paymentType'),
        sortable: false,
      },
      {
        flex: 1,
        field: 'description',
        headerName: 'Description',
        sortable: false,
      },
      {
        flex: 1,
        field: 'amount',
        headerName: 'Amount',
        sortable: true,
        renderCell: (params: { row: { amount: number } }) => {
          return currencyDisplay(params.row?.amount);
        },
      },
    ],
    [EFinanceAccountSummaryExpense.DISPATCHER_PAYMENT]: [
      {
        flex: 1,
        field: 'expenseDate',
        headerName: t('paymentDate'),
        sortable: true,
        valueGetter: (params) =>
          getFormattedDateForGrid(params.row.expenseDate, UI_DATE_FORMAT),
      },
      {
        flex: 1,
        field: 'paidTo',
        headerName: t('dispatcherName'),
        sortable: false,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.paidTo || ''} />
        ),
      },
      {
        flex: 1,
        field: 'settlementSeqNumber',
        headerName: t('settlementId'),
        sortable: true,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.settlementSeqNumber || ''} />
        ),
      },
      {
        flex: 1,
        field: 'loadSeqNumber',
        headerName: 'Trip ID',
        sortable: true,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.loadSeqNumber || ''} />
        ),
      },
      {
        flex: 1,
        field: 'expenseType',
        headerName: t('paymentType'),
        sortable: false,
      },
      {
        flex: 1,
        field: 'description',
        headerName: 'Description',
        sortable: false,
      },
      {
        flex: 1,
        field: 'amount',
        headerName: 'Amount',
        sortable: true,
        renderCell: (params: { row: { amount: number } }) => {
          return currencyDisplay(params.row?.amount);
        },
      },
    ],
    [EFinanceAccountSummaryExpense.CARRIER_PAYMENT]: [
      {
        flex: 1,
        field: 'expenseDate',
        headerName: t('paymentDate'),
        sortable: true,
        valueGetter: (params) =>
          getFormattedDateForGrid(params.row.expenseDate, UI_DATE_FORMAT),
      },
      {
        flex: 1,
        field: 'carrierName',
        headerName: 'Carrier Name',
        sortable: false,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.carrierName || ''} />
        ),
      },
      {
        flex: 1,
        field: 'tripSeqNumber',
        headerName: 'Trip ID',
        sortable: true,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.tripSeqNumber || ''} />
        ),
      },
      {
        flex: 1,
        field: 'expenseType',
        headerName: t('paymentType'),
        sortable: false,
      },
      {
        flex: 1,
        field: 'description',
        headerName: 'Description',
        sortable: false,
      },
      {
        flex: 1,
        field: 'amount',
        headerName: 'Amount',
        sortable: true,
        renderCell: (params: { row: { amount: number } }) => {
          return currencyDisplay(params.row?.amount);
        },
      },
    ],
    [EFinanceAccountSummaryExpense.EXPENSE_PAYMENT]: [
      {
        flex: 1,
        field: 'seqNumber',
        headerName: t('expId'),
        sortable: false,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.seqNumber || ''} />
        ),
      },
      {
        flex: 1,
        field: 'paidDate',
        headerName: 'Date',
        sortable: false,
        valueGetter: (params) =>
          getFormattedDateForGrid(params.row.paidDate, UI_DATE_FORMAT),
      },
      {
        flex: 1,
        field: 'status',
        headerName: 'Status',
        sortable: false,
        minWidth: 200,
        renderCell: (params) => {
          const { status } = params.row;
          return <ExpenseStatus status={status} />;
        },
      },
      {
        flex: 1,
        field: 'categoryName',
        headerName: 'Category',
        sortable: false,
      },
      {
        flex: 1,
        field: 'paidBy',
        headerName: t('paidBy'),
        sortable: false,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.paidBy || ''} />
        ),
      },
      {
        flex: 1,
        field: 'paidTo',
        headerName: t('paidTo'),
        sortable: false,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.paidTo || ''} />
        ),
      },
      {
        flex: 1,
        field: 'referenceNumber',
        headerName: t('refNumber'),
        sortable: false,
      },
      {
        flex: 1,
        field: 'equipmentName',
        headerName: 'Equipment',
        sortable: false,
        isHyperLink: true,
        renderCell: (params: GridRenderCellParams) => (
          <HyperLinkComp value={params.row?.equipmentName || ''} />
        ),
      },
      {
        flex: 1,
        field: 'description',
        headerName: 'Description',
        sortable: false,
      },
      {
        flex: 1,
        field: 'amount',
        headerName: 'Amount',
        sortable: false,
        renderCell: (params: { row: { amount: number } }) => {
          return currencyDisplay(params.row?.amount);
        },
      },
    ],
  };
}
