export const subscriptionConfigConstant = {
  laneRatePopupContent:
    'While searching loads, view realtime lane rates from over 500,000 carriers. You will be charged ',
  ediPopupContent: {
    description:
      'Exchange loads & information electronically with other businesses & Shippers. View shared loads, book or reject loads, and send shipment status updates.',
    toActivate: 'To activate:',
    email: 'email',
    emailInfo: 'loadops.support@optym.com ',
    contact: 'or call 1-833-293-5301',
  },
  brokeragePopupContent: {
    description:
      'Create records for each carrier, and streamline the process of assigning carriers to specific loads for transportation.',
    description2:
      'This BETA feature is free during the development period. Once the full feature is released the feature will be priced competitively with other Broker TMS platforms on the market.',
  },
  laneRateCardInfo: {
    title: 'Lane Rates By cargo chief',
    subTitle: '$5/driver per month',
    buttonText: 'Activate',
    popupTitle: 'Activate Lanes Rates -',
    url: 'https://help.loadops.com/cargo-chief-lane-rates',
  },
  loadAiCardInfo: {
    title: 'LoadAi Beta',
    buttonText: 'Learn More',
    btnUrl: 'https://help.loadops.com/loadai',
  },
  brokerageCradInfo: {
    title: 'Brokerage-Beta',
    subTitle: ' ',
    buttonText: 'Activate',
    popupTitle: 'Activate Brokerage Beta?',
    url: 'https://help.loadops.com/load-brokerage',
  },
  subscriptionCardInfo: {
    title: 'Terms of Agreement',
    subTitleLine: 'Early Termination',
    firstConditionTitle: '1. Term and Termination',
    firstConditionDescription: `This Agreement shall commence on the Effective Date and continue for a period of twelve (12) months thereafter (the "Term"), unless earlier terminated as provided herein. The User may terminate this Agreement prior to the end of the Term, subject to the provisions of Clause 2 below.`,
    secondConditionTitle: '2. Early Termination by User',
    secondConditionDescription: `In the event that the User elects to terminate this Agreement prior to the end of the Term, the User shall notify the Company in writing of its intention to terminate ("Early Termination Notice"). Upon receipt of the Early Termination Notice, the User will be liable to pay an early termination fee ("Early Termination Fee") to the Company.`,
    thirdConditionTitle: '3. Calculation of Early Termination Fee',
    thirdConditionDescription: `The Early Termination Fee shall be calculated as fifty percent (50%) of
      the aggregate minimum contractual fees due for the remaining term of the
      Agreement. This amount will be immediately due and payable upon issuance
      of the Early Termination Notice.`,
    fourthConditionTitle: '4. Consequences of Early Termination',
    fourthConditionDescription: `On termination of this Agreement for any reason, any rights, remedies,
      obligations or liabilities of the parties that have accrued up to the
      date of termination, including the right to claim damages in respect of
      any breach of the Agreement which existed at or before the date of
      termination, shall not be affected or prejudiced.`,
    fifthConditionTitle: '5. Governing Law and Disputes.',
    fifthConditionDescription: `This Agreement and any dispute or claim arising out of or in connection
      with it or its subject matter or formation (including non-contractual
      disputes or claims) shall be governed by and construed in accordance
      with the laws of the governing jurisdiction as specified in this
      Agreement.`,
    fifthConditionDescription2: `By using the Company's services, the User acknowledges that they have read, understood, and agree to be bound by the terms of this Agreement, including the Early Termination Fee outlined above.`,
    actionBtnText: 'I Agree, Next',
  },
  ediCardInfo: {
    title: 'LoadOps EDI Integration (Kleinschmidt)',
    subTitle: '$100/month + $0.01/character',
    buttonText: 'Activate',
    popupTitle: 'EDI Integration',
  },
  mothlyAndAnnualCardContent: {
    dollarSign: '$',
    driverText: '/driver',
    perMonthText: 'per month',
    billPaymentText: 'Billed monthly',
    agreementText: '12 month agreement',
    planCardConetent: 'Contact us for custom pricing',
  },
};
