import { Typography } from '@mui/material';

export default function WarningWindowLoad() {
  return (
    <div
      style={{
        borderRadius: '7px',
        listStyle: 'initial',
      }}
    >
      <Typography
        variant="body2"
        sx={{ fontFamily: 'Poppins', fontWeight: 400 }}
      >
        NOTE:
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontFamily: 'Poppins',
          fontWeight: 100,
          fontStyle: 'italic',
          letterSpacing: '0.15px',
        }}
      >
        this assignment will be also reflected on the current and future loads
        of this driver
      </Typography>
    </div>
  );
}
