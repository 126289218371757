export enum ELoadboardsPanelTab {
  TRIP_PLAN = 'TRIP_PLAN',
  PROFITABILITY = 'PROFITABILITY',
  DETAILS = 'DETAILS',
}

export interface ILoadboardsPanelTab {
  key: ELoadboardsPanelTab;
  label: string;
  iconName: string;
  source: string;
}

export const loadboardsPanelTabs: ILoadboardsPanelTab[] = [
  {
    key: ELoadboardsPanelTab.TRIP_PLAN,
    label: 'Trip Plan',
    iconName: 'MapOutlined',
    source: 'MUIcons',
  },
  {
    key: ELoadboardsPanelTab.PROFITABILITY,
    label: 'Profitabilty',
    iconName: 'AttachMoneyOutlined',
    source: 'MUIcons',
  },
  {
    key: ELoadboardsPanelTab.DETAILS,
    label: 'Details',
    iconName: 'InfoOutlined',
    source: 'MUIcons',
  },
];
