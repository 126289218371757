import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ButtonImproved from '../../../../../ui-kit/components/ButtonImproved';
import { ArrowRightIcon } from '../../../../../ui-kit/components/Assets';
import ImportActions from '../../Import';
import { useImport } from '../../../../../contexts/ImportContext';

type PagePropTypes = {
  onBack: () => void;
  onNext: () => void;
  nextButtonTitle?: string;
  showNextIcon?: boolean;
};

export default function Footer({
  onBack,
  onNext,
  nextButtonTitle = 'Next',
  showNextIcon = true,
}: PagePropTypes) {
  const {
    sessionId,
    handleImportModalClose,
    currentEntity: { routeName, dictionary },
  } = useImport();

  const handleCloseImport = async () => {
    handleImportModalClose();
    if (!sessionId) {
      return;
    }
    await ImportActions.cancelImport(sessionId, routeName, dictionary);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <ButtonImproved
            onClick={() => onBack()}
            variant={'outlined'}
            label={'Go Back'}
          />
        </Box>
        <Box>
          <Tooltip
            title={'Clicking here will cancel all steps'}
            placement={'top'}
          >
            <ButtonImproved
              onClick={handleCloseImport}
              variant={'outlined'}
              label={'Cancel'}
              styleProps={{
                marginRight: 1,
              }}
            />
          </Tooltip>
          <ButtonImproved
            onClick={() => onNext()}
            variant={'contained'}
            startIcon={
              showNextIcon && (
                <ArrowRightIcon width={14} height={14} color={'white'} />
              )
            }
            label={nextButtonTitle}
            styleProps={{
              marginRight: 1,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
