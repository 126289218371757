import { useState } from 'react';
import {
  FactoringActionReturnType,
  InvoiceModel,
} from '../../models/InvoiceModel';
import InvoiceActions from '../../InvoiceActions';
import DeletePopup from '../../../../ui-kit/components/DeletePopup';
import { SendDocumentsDialog } from '../InvoiceActions/SendDocumentsAction';
import { UpdateAtFactoringDialog } from '../InvoiceActions/UpdateAtfactoringAction';
import {
  FactoringFormType,
  SendFactoringRequestType,
} from '../../../../models';
import {
  generateDisconnectActionResponse,
  generateSendActionResponse,
} from '../../utils';
import { useInvoicePage } from '../../../../views/finance/invoices/InvoicePageContext';
import { useStores } from '../../../../store/root.context';

export default function FactoringSingleActions({
  disconnectDialogOpen,
  setDisconnectDialogOpen,
  updateAtFactoringDialogOpen,
  setUpdateAtFactoringDialogOpen,
  sendDocumentDialogOpen,
  setSendDocumentDialogOpen,
  invoice,
  handleFactoringResponse,
  triggerAlert,
}: PropTypes) {
  const {
    invoiceStore: { selectedItem },
  } = useStores();
  const [disconnectErrorMessage, setDisconnectErrorMessage] = useState('');
  const sendDocument = async (data: FactoringFormType) => {
    setSendDocumentDialogOpen(false);
    const requestData = new SendFactoringRequestType(data);
    const response = await InvoiceActions.sendDocument(requestData);
    if (response) {
      handleFactoringResponse(response, selectedItem);
      generateSendActionResponse(response, triggerAlert);
    }
  };

  const updateAtFactoring = async (data: FactoringFormType) => {
    setUpdateAtFactoringDialogOpen(false);
    const requestData = new SendFactoringRequestType(data);
    const response = await InvoiceActions.updateAtFactoring(requestData);
    if (response) {
      handleFactoringResponse(response, selectedItem);
      generateSendActionResponse(response, triggerAlert);
    }
  };

  const disconnectFromFactoring = async () => {
    setDisconnectDialogOpen(false);
    const response = await InvoiceActions.disconnectFromFactoring(invoice);
    if (response) {
      handleFactoringResponse(response, selectedItem);
      const message = generateDisconnectActionResponse(response);
      if (!message) {
        return;
      }
      setDisconnectErrorMessage(message);
    }
  };

  return (
    <>
      {disconnectDialogOpen && (
        <DeletePopup
          open={disconnectDialogOpen}
          onAction={disconnectFromFactoring}
          onClose={() => setDisconnectDialogOpen(false)}
          title={'Are you sure you want to disconnect from factoring?'}
          cancelText={'Cancel'}
          buttonText={'Yes'}
        />
      )}
      {disconnectErrorMessage && (
        <DeletePopup
          open={!!disconnectErrorMessage}
          onClose={() => setDisconnectErrorMessage('')}
          title={disconnectErrorMessage}
          cancelText="Ok"
        />
      )}
      {updateAtFactoringDialogOpen && (
        <UpdateAtFactoringDialog
          open={updateAtFactoringDialogOpen}
          onAction={(data) => updateAtFactoring(data)}
          onClose={() => setUpdateAtFactoringDialogOpen(false)}
          invoice={invoice}
          factoringNotes={invoice.sourceFactoringNotes}
        />
      )}
      {sendDocumentDialogOpen && (
        <SendDocumentsDialog
          open={sendDocumentDialogOpen}
          onAction={(data) => sendDocument(data)}
          onClose={() => setSendDocumentDialogOpen(false)}
          invoice={invoice}
        />
      )}
    </>
  );
}

type PropTypes = {
  disconnectDialogOpen: boolean;
  setDisconnectDialogOpen: (prop: boolean) => void;
  updateAtFactoringDialogOpen: boolean;
  setUpdateAtFactoringDialogOpen: (prop: boolean) => void;
  sendDocumentDialogOpen: boolean;
  setSendDocumentDialogOpen: (prop: boolean) => void;
  triggerAlert: (
    actionName: string,
    message: string,
    subMessage?: string
  ) => void;
  invoice: InvoiceModel;
  handleFactoringResponse: (
    response: FactoringActionReturnType,
    selectedItem: InvoiceModel | null
  ) => void;
};
