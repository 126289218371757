import {
  CenterDTO,
  ContactSummary,
  CreateLocationNewRequest,
  ICommodity,
  ICustomerContact,
  ILoadDetailsDTO,
  ITemplateInvoice,
} from '../../../../models';
import {
  CreateLoadFormContact,
  CreateLoadStopRequestStopDTO,
  StopContactDTO,
} from '../dto/create-load-request.dto';
import { ActivityType } from './types';

export interface LoadTemplateDetailsDTO {
  id: string;
  templateId?: string;
  templateName?: string;
  organizationId: number;
  terminal: {
    id: string;
    companyName: string;
  };
  loadType?: string;
  loadDetails: ILoadDetailsDTO;
  proofOfDeliveryMandatory: boolean;
  billOfLadingMandatory: boolean;
  revenue?: number;
  invoiceTerm?: string | null;
  invoiceTermValue?: string | null;
  stops: Array<CreateLoadStopRequestStopDTO>;
  invoice: ITemplateInvoice;
  customer: {
    id?: string;
    customerId: number;
    customerContactId: number;
    customerName: string;
    name?: string;
    contacts: Array<ICustomerContact>;
    referenceNumber?: string;
  };
  numberOfStops?: number;
}

export type SearchCustomerResDTO = {
  id?: number;
  organizationId?: number;
  name?: string;
  value?: string;
  type?: string;
  mc?: string;
  addressDTO?: {
    address: string;
    streetAddress: string;
    streetAddress2: string;
    city: string;
    state: string;
    zip: number;
    fullAddress: string;
    center: CenterDTO;
  };
  status?: string;
  factoringName?: string;
  factoringCharge?: string;
  notes?: string;
  registerDate?: string;
  customerPreference?: string;
  useFactoring?: false;
  factoringId?: string;
  contacts?: Array<ICustomerContact>;
  commodityTypes?: Array<ICommodity>;
  ediCustomerId?: number;
};

export interface PhoneDataResDTO extends ContactSummary {
  id?: number;
  customerId?: number;
  fullName?: string;
  isAfterHours?: boolean;
  externalSystemId?: string;
  createSource?: string;
}

export type CommoditiesTypeResDTO = {
  id?: string;
  itemName?: string;
  itemCode?: string;
  description?: string;
  iconName?: string;
  createdBy?: string;
  createDate?: string;
  deduction?: boolean;
  custom?: boolean;
  type?: string;
  value?: string;
};

export type AppointmentConfirmationType = 'CONFIRMED' | 'NO' | 'YES';
export enum AppointmentConfirmationEnum {
  CONFIRMED = 'CONFIRMED',
  NO = 'NO',
  YES = 'YES',
}

type NumStringType = number | string | null;
export interface ILoadStop {
  type?: ActivityType;
  estimatedActivityDuration?: number;
  relayDropOffEstimatedActivityDuration?: number;
  referenceNumber?: string;
  notes?: string;
  fixedAppointment?: boolean;
  appointmentStartDate?: string | null;
  appointmentEndDate?: string | null;
  appointmentStartTime?: string;
  appointmentEndTime?: string;
  relayDropOffAppointmentStartDate?: string | null;
  relayDropOffAppointmentStartTime?: string;
  relayDropOffAppointmentEndDate?: string | null;
  relayDropOffAppointmentEndTime?: string;
  isDriverAssistRequired?: boolean;
  isRelayDropOffDriverAssistRequired?: boolean;
  relayPickupRevenueShare?: NumStringType;
  relayDropOffRevenueShare?: NumStringType;
  appointmentConfirmation?: AppointmentConfirmationType;
  dropOffAppointmentConfirmation?: AppointmentConfirmationType;
  location: CreateLocationResDTO;
  sequenceNumber?: string | number | null;
  stopCategory?: string | null;
  stopStatus?: string | null;
  timezone?: string | null;
  contacts?: Array<StopContactDTO>;
  noteDetails?: any;
  contact?: CreateLoadFormContact;
  isEndTimeVisible?: boolean;
  isRelayDropOffEndTimeVisible?: boolean;
}

export interface ICommodityResDTO {
  id?: string;
  itemName?: string;
  itemCode?: string;
  description?: string;
  iconName?: string;
  createdBy?: string;
  createDate?: string;
  unit?: Array<string>;
  itemType?: string;
  invoiceItem?: boolean;
  settlementItem?: boolean;
  deduction?: boolean;
  custom?: boolean;
}

export class CreateLocationResDTO {
  locationId?: number | null;
  locationName?: string;
  center?: CenterDTO;
  city?: string;
  state?: string;
  zipcode?: string;
  streetAddress?: string;
  address?: string;
  address2?: string;
  geoCoordinates?: Array<{ coordinates: Array<number> }>;
  country?: string;
  businessName?: string | null;
  isExternal?: string;
  timezone?: string;
  lat?: number;
  lng?: number;
  streetAddress2?: string;
  constructor(data: CreateLocationNewRequest) {
    this.locationId = data?.id;
    this.locationName = data?.locationName;
    this.center = data?.addressDTO?.center;
    this.city = data?.addressDTO?.city;
    this.zipcode = data?.addressDTO?.zipcode;
    this.streetAddress = data?.addressDTO?.streetAddress;
    this.state = data?.addressDTO?.state;
    this.address = data?.addressDTO?.address;
    this.address2 = data?.addressDTO?.streetAddress2;
    this.geoCoordinates = data?.addressDTO?.geoCoordinates as any;
    this.country = data?.addressDTO?.country;
    this.businessName = data?.locationName;
    this.timezone = data?.timezone;
  }
}
