import * as React from 'react';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../../../ui-kit/components/IconsComponent';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';

export default function BasicPopover(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Permission includes={[AXELE_PERMISSION_TYPE.ALERTS_EDIT]}>
        <IconButton
          aria-label="Settings"
          sx={{ color: 'text.secondary' }}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Permission>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack
          spacing={1}
          justifyContent="center"
          alignItems="flex-start"
          sx={{ padding: 1 }}
        >
          <ButtonImproved
            label="Edit"
            variant="text"
            startIcon={
              <IconsComponent iconName={'EditOutlined'} source="MUIcons" />
            }
            styleProps={{ color: 'text.primary', paddingRight: 10 }}
            onClick={() => {
              props.performEdit(props.params);
              handleClose();
            }}
            size="large"
          />

          <ButtonImproved
            label="Delete"
            variant="text"
            startIcon={
              <IconsComponent iconName={'DeleteOutlined'} source="MUIcons" />
            }
            styleProps={{ color: 'text.primary', paddingRight: 10 }}
            onClick={() => {
              props.setDeleteOpen(true);
              props.setDeleteItem(props.params);
              handleClose();
            }}
            size="large"
          />
        </Stack>
      </Popover>
    </div>
  );
}
