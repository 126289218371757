import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function ReadOnlyField({
  title,
  text,
  customStyles = { textStyles: {}, titleStyles: {} },
  xs = 5.7,
}: {
  title: string;
  text: string;
  customStyles?: { [key: string]: { [key: string]: string | number } };
  xs?: number;
}) {
  return (
    <Grid container item xs={xs} direction={'column'}>
      <Typography
        variant="h8"
        sx={{
          color: 'text.secondary',
          marginBottom: '10px',
          ...customStyles.title,
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="inputText"
        sx={{ color: 'text.primary', ...customStyles.text }}
      >
        {text}
      </Typography>
    </Grid>
  );
}
