import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React from 'react';
import { TripV3DataProps } from '../../TripPlanV3';
import { StyleEditIconButton } from '../../TripPlanV3/styles';
import {
  ExpandableTimelineV3EditIconEvents,
  StopSolutionV3Prop,
} from '../../TripPlanV3/types';
import { TripStopStatusEnum } from '../../types';
import { classNamesTimeline } from './constants';

export interface EditIconSectionProps
  extends ExpandableTimelineV3EditIconEvents {
  data: TripV3DataProps;
  stopSolution: StopSolutionV3Prop;
}
export const EditIconSection = ({
  data,
  stopSolution,
  onClickEditIconHandler,
}: EditIconSectionProps): JSX.Element => {
  if (
    stopSolution?.status === null ||
    stopSolution?.status === undefined ||
    stopSolution?.status === TripStopStatusEnum.NONE ||
    stopSolution?.status === TripStopStatusEnum.EN_ROUTE ||
    stopSolution?.status === TripStopStatusEnum.ARRIVED
  ) {
    return (
      <StyleEditIconButton
        className={classNamesTimeline.StyleEditIconButton}
        onClick={(event) => {
          onClickEditIconHandler?.(stopSolution, data);
          event.stopPropagation();
        }}
      >
        <EditOutlinedIcon fontSize="small" color="primary" />
      </StyleEditIconButton>
    );
  }
  return <></>;
};
