// @TODO: Nvard - CustomizedTooltips from storybook has issues so Tooltip is used
import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { numberWithThousandSeparator } from '../../../../../utils';
import { KPIInfoCard } from '../../../../KPIInfoCard';
import { AXELE_PERMISSION_TYPE, Permission } from '../../../../Permission';
import { t } from 'i18next';
interface IProps {
  kpis: {
    loadedMiles: number;
    emptyMiles: number;
    revenue: number;
    tripMiles: number;
  };
  loadType?: string;
}

export const KPICards = ({
  kpis: {
    loadedMiles: loadedMilesKpi,
    emptyMiles: emptyMilesKpi,
    revenue: revenueKpi,
    tripMiles: tripMiles,
  },
  loadType,
}: IProps) => {
  const totalMiles = emptyMilesKpi + loadedMilesKpi;
  const revenuePerTotalMilesKpi =
    revenueKpi && totalMiles
      ? numberWithThousandSeparator(revenueKpi / totalMiles)
      : '$0';
  const kpis = {
    emptyMiles:
      emptyMilesKpi || emptyMilesKpi == 0
        ? `${numberWithThousandSeparator(emptyMilesKpi)} ${t(
            'loadsOverviewMiEmpty'
          )}`
        : '--',
    loadedMiles:
      loadedMilesKpi ||
      tripMiles ||
      loadedMilesKpi == 0 ||
      (loadType && loadType === 'MANIFEST')
        ? numberWithThousandSeparator(
            loadType && loadType != 'MANIFEST'
              ? loadedMilesKpi || 0
              : tripMiles || 0
          ) +
          `mi  ${
            loadType && loadType != 'MANIFEST'
              ? t('loadsOverviewLoaded')
              : t('loadsOverviewTrip')
          }`
        : '--',
    revenue:
      revenueKpi || revenueKpi == 0
        ? `$${numberWithThousandSeparator(revenueKpi)} ${t(
            'loadsOverviewRevenue'
          )}`
        : '--',

    revenuePerTotalMiles: `${revenuePerTotalMilesKpi}/${t(
      'loadsOverviewMile'
    )}`,
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      sx={{ overflowY: 'auto' }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        flex={'1 auto'}
      >
        <Tooltip
          placement="bottom"
          arrow
          title="To edit empty and loaded miles
        please go to invoice tab."
        >
          <KPIInfoCard
            mainKpi={kpis.loadedMiles ? kpis.loadedMiles : '--'}
            secondaryKpi={kpis.emptyMiles ? kpis.emptyMiles : '--'}
            styles={{ width: '100%', minWidth: 211 }}
          />
        </Tooltip>
        <Permission
          contains={[AXELE_PERMISSION_TYPE.LOAD_FINANCIAL_INVOICES_VIEW]}
        >
          <KPIInfoCard
            mainKpi={kpis.revenue ? kpis.revenue : '--'}
            secondaryKpi={
              kpis.revenuePerTotalMiles ? kpis.revenuePerTotalMiles : '--'
            }
            styles={{ width: '100%', minWidth: 200 }}
          />
        </Permission>
      </Stack>
    </Stack>
  );
};
