import { Type } from 'class-transformer';
import { DriverPaymentTermsGrouped } from '..';
import { groupEldDriverName } from '../../../subPages/drivers/utils';
import { SafetyAlertModel } from '../../../subPages/users/components/SafetyAlerts/models';
import {
  addressType,
  FileType,
  PaginatedList,
  PhoneType,
} from '../../../types';
import { TerminalShort } from '../../common/modelsShort';
import { ContactPerson, SortResponse } from '../commonMixed';
import { IDispatchPreferencesFormData } from '../../../views/dispatch2/constants/types';

export enum UsersEntityTypeEnum {
  user = 'user',
  webUser = 'webUser',
  driver = 'driver',
}

export class User {
  id: string;
  firstName: string;
  lastName: string;

  phoneData: PhoneType;
  email: string;
  address: addressType | null;
  roleCode: string;
  terminals: TerminalShort[];
  associatedTerminalsList: TerminalShort[];
  terminal: TerminalShort | null;
  terminalId: string;
  driveStatus: number;
  isDriver: boolean;
  isUser: boolean;
  employmentType: string;
  operationType: string;
  userType: string;
  sendInvitation: boolean;
  warning: number;
  critical: number;
  dispatcher: PrimaryDispatcher | null;
  emergencyContact: ContactPerson;
  statusName: string;
  status: number;
  lastLoginDate: string;
  driverRefId: string;
  beRes: any;
  startingYTD: number;
  effectiveStartDate: Date;
  invoiceAccessType: number;
  eldProvider: EldIntegrationDetails | null;
  constructor(dto: any) {
    this.id = dto.id;
    this.firstName = dto.firstname?.trim() || '';
    this.lastName = dto.lastname?.trim() || '';
    this.phoneData = dto.phoneData;
    this.email = dto.email;
    this.roleCode = dto.roleCode;
    this.address = dto.addressData;
    this.driveStatus = dto.driveStatus;
    this.driverRefId = dto.driverRefId;
    this.isDriver = this.driveStatus === 2 || this.driveStatus === 1;
    this.isUser = this.driveStatus === 2 || this.driveStatus === 0;
    this.terminals = dto.terminals
      ? dto.terminals
          ?.filter((item) => !!item)
          .map((t: { id: string; companyName: string }) => new TerminalShort(t))
      : [];
    this.associatedTerminalsList = dto.associatedTerminalsList
      ? dto.associatedTerminalsList
          .filter((item) => !!item)
          ?.map(
            (t: { id: string; companyName: string }) => new TerminalShort(t)
          )
      : [];

    this.terminal = dto.terminal ? new TerminalShort(dto.terminal) : null;
    this.terminalId = dto.terminalId;
    this.employmentType = dto.employmentType || '';
    this.operationType = dto.operationType || '';
    this.userType = dto.userType;
    this.sendInvitation = dto.sendInvitation;
    this.emergencyContact = dto.emergencyContact;
    this.warning = dto.warningSafetyIssues;
    this.critical = dto.criticalSafetyIssues;
    this.beRes = dto;

    this.statusName = dto.statusName;
    this.status = dto.status;
    this.lastLoginDate = dto.lastLoginDate;
    this.dispatcher = dto.dispatcher
      ? new PrimaryDispatcher(dto.dispatcher)
      : null;
    this.startingYTD = dto.startingYTD;
    this.effectiveStartDate = dto.effectiveStartDate;
    this.invoiceAccessType = dto.invoiceAccessType;
    this.eldProvider = dto.eldCredentialId
      ? {
          id: dto.eldCredentialId,
          name: dto.eldProviderName,
        }
      : null;
  }
}

export class UserDetails extends User {
  isRemovable: boolean;
  driverLicense: any;
  timezone: string;
  eldDriver: EldIntegrationDriverDetails | undefined;
  trackingSource: { eld: boolean; gps: boolean };
  allowDriverAcceptRejectLoad: boolean;
  paymentTerms: DriverPaymentTermsGrouped | null;
  alertsList: SafetyAlertModel[];
  fuelCardProvider: string;
  fuelCardNumber: string;
  hidePendingPaymentsFromMobile: boolean;
  canUpdateCommodity: boolean;
  assignedDocuments?: FileType[];
  preferencesData?: IDispatchPreferencesFormData | null;
  constructor(props: any) {
    super(props);
    this.isRemovable = props.isRemovable;
    this.driverLicense = props.orgDriverLicense;
    this.timezone = props.timezone;

    this.eldDriver = {
      id: props?.eldDriverId,
      nameToShow: groupEldDriverName(
        props?.driverELDId,
        props?.eldDriverFirstName,
        props?.eldDriverLastName
      ),
      eldId: +props?.driverELDId,
      firstname: '',
      lastname: '',
    };

    this.fuelCardProvider = props.fuelCardProvider || null;
    this.fuelCardNumber = props.fuelCardNumber || null;
    this.trackingSource = props.trackingSourceData;
    this.allowDriverAcceptRejectLoad = props.allowDriverAcceptRejectLoad;
    this.paymentTerms = props.paymentTerms;
    this.alertsList = [];
    this.hidePendingPaymentsFromMobile = props.hidePendingPaymentsFromMobile;
    this.canUpdateCommodity = props.canUpdateCommodity;
    this.assignedDocuments = props.assignedDocuments;
  }
}

export const roleCodeListStatic = [
  { name: 'Accountant', roleCode: 'AC' },
  { name: 'Admin', roleCode: 'AD' },
  { name: 'Power Dispatcher', roleCode: 'PD' },
  { name: 'Financial Dispatcher', roleCode: 'DI' },
  { name: 'Dispatcher', roleCode: 'NFD' },
];

export class PrimaryDispatcher {
  id: string;
  fullName: string;
  constructor(props: any) {
    const roleName = roleCodeListStatic.find(
      (r) => r.roleCode === props.roleCode
    )?.name;
    this.id = props.id;
    this.fullName = `${props.firstname} ${props.lastname}${
      roleName ? ` (${roleName})` : ''
    }`;
  }
}

export class PaginatedUserList {
  @Type(() => User)
  content!: User[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
  totalCriticalIssueCount!: number;
  totalWarningIssueCount!: number;
}

export class UserRoleDTO {
  id!: number;
  roleCode!: string;
}

export class PaginatedRoleList {
  @Type(() => UserRoleDTO)
  content!: UserRoleDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class EmailDTO {
  id!: number;
  email!: string;
}

export class PaginatedEmailList {
  @Type(() => EmailDTO)
  content!: EmailDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class TerminalDTO {
  id!: number;
  email!: string;
}

export class PaginatedTerminalsList {
  @Type(() => TerminalDTO)
  content!: TerminalDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class NameDTO {
  id!: number;
  name!: string;
  nameToFilter!: string;
}

export class PaginatedNamesList {
  content!: NameDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}

export class StatusDTO {
  key!: number;
  value!: string;
}

export class PaginatedStatusesList {
  @Type(() => StatusDTO)
  content!: StatusDTO[];
  first!: boolean;
  last!: boolean;
  totalPages!: number;
  totalElements!: number;
  numberOfElements!: number;
  size!: number;
  number!: number;
  @Type(() => SortResponse)
  order!: SortResponse[];
}
export class UserDriverActivation {
  dot!: number;
  email!: string;
  companyName!: string;
  phoneData!: PhoneType;
}

export class EldIntegrationDetails {
  id!: string;
  name!: string;
}
export class EldIntegrations extends PaginatedList {
  content!: EldIntegrationDetails[];
}

export class EldIntegrationDriverDetails {
  eldId!: number;
  firstname!: string;
  id!: string;
  lastname!: string;
  nameToShow!: string;
}
export class EldIntegrationDrivers extends PaginatedList {
  content!: EldIntegrationDriverDetails[];
}

export type resetPasswordType = {
  confirmPassword: string;
  oldPassword: string;
  password: string;
};
