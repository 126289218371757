import { SxProps, Theme } from '@mui/material';

export function getTableSettingsStyles({ theme }: { theme: Theme }): {
  [key: string]: SxProps;
} {
  return {
    TableSettingsBoxRoot: {
      display: 'flex',
      backgroundColor: 'uncategorized.popup',
      flexDirection: 'column',
      borderRadius: '16px 16px 0 0',
      [theme?.breakpoints?.down('tablet')]: {
        backgroundColor: 'primary.contrast',
        width: '100%',
        borderRadius: 0,
      },
    },
    TableSettingsBoxContainer: {
      display: 'flex',
      justifyContent: 'center',
      height: 44,
      color: 'primary.contrast',
      alignItems: 'center',
      [theme?.breakpoints?.down('tablet')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        pt: 1,
      },
    },
    headerContainerLeft: {
      display: 'flex',
      justifyContent: 'center',
      height: 44,
      color: 'primary.contrast',
      alignItems: 'center',
      [theme?.breakpoints?.down('tablet')]: {
        pl: 2,
      },
    },
    headerContainerRight: {
      display: 'none',
      pr: 2,
      [theme?.breakpoints?.down('tablet')]: {
        display: 'flex',
      },
    },
    TableSettingsContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'primary.contrast',
      borderRadius: '16px',
      pt: 2,
      maxHeight: 600,
      [theme?.breakpoints?.down('tablet')]: {
        maxHeight: '100%',
      },
    },
    TableSettingsBoxPopover: {
      '& .MuiPaper-root': {
        overflow: 'hidden',
        minWidth: 300,
        borderRadius: '16px',
        [theme?.breakpoints?.down('tablet')]: {
          borderRadius: 0,
          minWidth: '100%',
          minHeight: '100%',
          top: `0 !important`,
          left: `0 !important`,
          overflowY: 'auto',
        },
      },
    },
    ListItemText: {
      color: 'text.primary',
      ml: 1,
      cursor: 'pointer',
      ...theme.typography?.h7,
    },
    SettingsOutlinedIcon: {
      width: 22,
      height: 22,
      [theme?.breakpoints?.down('tablet')]: {
        color: 'primary.main',
      },
    },
    title: {
      [theme?.breakpoints?.down('tablet')]: {
        ...theme?.typography?.h7,
        color: 'primary.main',
      },
    },
    resetButton: {
      height: '36px',
      mt: 1,
      // borderColor: 'uncategorized.accordion',
    },
  };
}
