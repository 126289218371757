/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance, {
  ComposedError,
  httpClient,
} from '../../axios/axiosInstance';
import {
  ContactSummaryCustomer,
  CreateCustomerContactRequest,
  CreateCustomerRequest,
  CustomerAssignedToLoadRequest,
  CustomerSummaryDTO,
  DeleteCustomerRequest,
  GetCustomerDetailsByIdRequest,
  GetCustomerFiltersRequest,
  GetCustomerListExcelRequest,
  GetCustomerListRequest,
  PaginatedCustomersList,
  PaginatedCustomersListRequest,
  UpdateCustomerRequest,
} from '../../models';
import { QueryParams } from '../../models/DTOs/commonMixed/QueryParams';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import {
  ICustomerService,
  ServiceError,
  annotateNameAsync,
} from '../interfaces';

const getPaginatedCustomersListURL =
  '/web/customer/api/v2/customers/get/customer/list';
const getCustomerListURL = '/web/customer/api/v2/customers/name/dropdown';
const createContactURL =
  '/web/customer/api/v2/customers/customer/create-contact';
const createCustomerURL = '/web/customer/api/v2/customers/customer/create';
const updateCustomerURL = '/web/customer/api/v2/customers/customer/update';
const deleteCustomerURL = '/web/customer/api/v2/customers/customer';
const customerAssignedToLoadURL =
  '/web/load/api/v2/loads/customerAssignedToLoad';
const getPaginatedCustomersListExcelURL =
  '/web/customer/api/v2/customers/customer/export-to-excel';
const getCustomerDetailsByIdURL = '/web/customer/api/v2/customers/get/customer';
// @TODO: Nvard - handle errors
export class CustomerService extends ICustomerService {
  @annotateNameAsync
  async getPaginatedCustomersList(
    requestData: PaginatedCustomersListRequest,
    filtersData: GetCustomerFiltersRequest
  ) {
    try {
      return await httpClient.put<PaginatedCustomersList>(
        getPaginatedCustomersListURL,
        requestData,
        filtersData,
        PaginatedCustomersList,
        true,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      throw error;
    }
  }

  @annotateNameAsync
  async createCustomer(requestData: CreateCustomerRequest) {
    try {
      return await httpClient.post<CustomerSummaryDTO>(
        createCustomerURL,
        undefined,
        requestData,
        CustomerSummaryDTO,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async createContact(requestData: CreateCustomerContactRequest) {
    try {
      const response = await httpClient.post<ContactSummaryCustomer>(
        createContactURL,
        new QueryParams(),
        requestData,
        ContactSummaryCustomer,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'createContact',
      });
      return response;
    } catch (error) {
      const composedError = error as ComposedError;
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'createContact',
      });
      return this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateCustomer(requestData: UpdateCustomerRequest) {
    try {
      const response = await httpClient.put<CustomerSummaryDTO>(
        updateCustomerURL,
        undefined,
        requestData,
        CustomerSummaryDTO,
        false
      );
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'customerUpdate',
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'customerUpdate',
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      throw error;
    }
  }

  @annotateNameAsync
  async deleteCustomer(requestData: DeleteCustomerRequest) {
    const params = requestData.getParamsFromRequest();
    try {
      const response = await axiosInstance.delete(deleteCustomerURL, {
        params,
      });

      const data = await response.data;
      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName: 'customerDelete',
      });
      return Promise.resolve(data);
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName: 'customerDelete',
      });
      throw error;
    }
  }

  @annotateNameAsync
  async customerAssignedToLoad(requestData: CustomerAssignedToLoadRequest) {
    const params = requestData.getParamsFromRequest();
    try {
      const response = await axiosInstance.get(customerAssignedToLoadURL, {
        params,
      });

      const data = await response.data;
      return Promise.resolve(data);
    } catch (error) {
      throw error;
    }
  }

  async getPaginatedCustomersListExcel(
    requestData: GetCustomerListExcelRequest,
    filtersData: GetCustomerFiltersRequest
  ) {
    try {
      return await httpClient.put<PaginatedCustomersList>(
        getPaginatedCustomersListExcelURL,
        requestData,
        filtersData,
        PaginatedCustomersList,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async getCustomerList(
    requestData: GetCustomerListRequest
  ): Promise<any | ServiceError> {
    try {
      const response = await httpClient.getRaw(
        getCustomerListURL,
        requestData,
        undefined,
        true
      );
      return response.data;
    } catch (error) {
      const composedError = error as ComposedError;
      return this.getServiceError(composedError);
    }
  }

  async getCustomerDetailsById(requestData: GetCustomerDetailsByIdRequest) {
    try {
      return await httpClient.get<CustomerSummaryDTO>(
        getCustomerDetailsByIdURL,
        requestData,
        CustomerSummaryDTO,
        false,
        true
      );
    } catch (error) {
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        throw this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }
}
