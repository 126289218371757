import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import moment from 'moment';
import { FC } from 'react';
import { ELoadStatus } from '../LoadTabPanel/constants/constants';
import { formatDate } from '../Ui/FormDatePicker/FormDatePicker';
import { entityTypes, groupIcons } from './constants';
import {
  additionalInfoStyle,
  entityTypeStyle,
  itemContainerBoxStyle,
  keyboardReturnIconStyle,
  labelStyle,
  lineInfoContainerStyle,
  lineItemContainerStyle,
} from './style';
import './styles.css';
interface SearchItemProps {
  lineItem: any;
  onClose: () => void;
  onSearchItemClicked: any;
}

interface PropertyProps {
  id: string;
  key: string;
  value: string;
}

function getLabel(lineItem: any) {
  if (lineItem.entityType === entityTypes.LOAD) {
    return `LOAD-${lineItem.seqNumber}`;
  } else if (lineItem.entityType === entityTypes.INVOICE) {
    return lineItem.seqNumber;
  } else {
    return lineItem.name;
  }
}

function getAdditionalData(lineItem: any) {
  const status = lineItem?.status || '--';
  const city = lineItem?.city || '--';
  const state = lineItem?.state || '--';
  const name = lineItem?.name || '--';
  const vin = lineItem?.vin || '--';
  const entityId = lineItem?.entityId || '--';
  const text = lineItem?.text || '--';
  const email = lineItem?.email || '--';
  const containerNumber = lineItem?.containerNumber;
  const chassisNumber = lineItem?.chassisNumber;
  const appointmentNumber = lineItem?.appointmentNumber;
  const bookingNumber = lineItem?.bookingNumber;
  let documentDetails = lineItem?.loadId
    ? `Load ID : ${lineItem.loadId}`
    : 'Load ID -- ';

  const properties = lineItem?.properties || [];

  const loadStatuses: { [key: string]: string } = {
    [ELoadStatus.AVAILABLE]: 'Available',
    [ELoadStatus.OFFERED_TO_DRIVER]: 'Assigned',
    [ELoadStatus.DISPATCHED]: 'Dispatched',
    [ELoadStatus.IN_TRANSIT]: 'In Transit',
    [ELoadStatus.LOAD_COMPLETED]: 'Completed',
    [ELoadStatus.ASSIGNMENT_PLANNED]: 'Planned',
  };

  properties.forEach((property: PropertyProps) => {
    if (property.value) {
      switch (property.key) {
        case entityTypes.DRIVER: {
          documentDetails += ` Driver: ${property.value}`;
          break;
        }
        case entityTypes.TRACTOR: {
          documentDetails += ` Tractor: ${property.value}`;
          break;
        }
        case entityTypes.TRAILER: {
          documentDetails += ` Trailer: ${property.value}`;
          break;
        }
        default:
          break;
      }
    }
  });

  switch (lineItem?.entityType) {
    case entityTypes.LOAD: {
      const referenceNum = lineItem?.referenceId || '--';
      return (
        <>
          <span style={entityTypeStyle}>Ref.# </span>
          {referenceNum}, <span style={entityTypeStyle}>Status: </span>
          {loadStatuses[status] || status},{' '}
          <span style={entityTypeStyle}>Driver: </span>
          {name}
          {Boolean(containerNumber) ? (
            <span>
              <span style={entityTypeStyle}>, Container #: </span>
              {containerNumber}
            </span>
          ) : (
            ''
          )}
          {Boolean(chassisNumber) ? (
            <span>
              <span style={entityTypeStyle}>, Chassis #: </span>
              {chassisNumber}
            </span>
          ) : (
            ''
          )}
          {Boolean(bookingNumber) ? (
            <span>
              <span style={entityTypeStyle}>, Booking #: </span>
              {bookingNumber}
            </span>
          ) : (
            ''
          )}
          {Boolean(appointmentNumber) ? (
            <span>
              <span style={entityTypeStyle}>, Appointment #: </span>
              {appointmentNumber}
            </span>
          ) : (
            ''
          )}
        </>
      );
    }
    case entityTypes.SOLO:
      return (
        <>
          <span style={entityTypeStyle}>Operation Mode: </span>Solo
        </>
      );
    case entityTypes.TEAM:
      return (
        <>
          <span style={entityTypeStyle}>Operation Mode: </span>Team
        </>
      );
    case entityTypes.CUSTOMER:
      return (
        <>
          <span style={entityTypeStyle}>City: </span>
          {city}, <span style={entityTypeStyle}>State: </span>
          {state}, <span style={entityTypeStyle}>Type: </span>
          {text}
        </>
      );
    case entityTypes.USER:
      return (
        <>
          <span style={entityTypeStyle}>Operation Mode: </span>None,{' '}
          <span style={entityTypeStyle}>Email: </span>
          {email}
        </>
      );
    case entityTypes.TRACTOR:
      return (
        <>
          <span style={entityTypeStyle}>VIN: </span>
          {vin}, <span style={entityTypeStyle}>Status: </span>
          {status}
        </>
      );
    case entityTypes.TRAILER:
      return (
        <>
          <span style={entityTypeStyle}>VIN: </span>
          {vin}, <span style={entityTypeStyle}>Status: </span>
          {status}, <span style={entityTypeStyle}>Trailer Type: </span>
          {text}
        </>
      );
    case entityTypes.LOCATION:
      return (
        <>
          <span style={entityTypeStyle}>City: </span>
          {city}, <span style={entityTypeStyle}>State: </span>
          {state}
        </>
      );
    case entityTypes.DOCUMENT:
      return (
        <span>
          {lineItem?.loadId ? (
            <>
              <span style={entityTypeStyle}>Load ID: </span> {lineItem.loadId}
            </>
          ) : (
            <span style={entityTypeStyle}>Load ID --</span>
          )}
        </span>
      );
    case entityTypes.VENDOR:
      return (
        <>
          <span style={entityTypeStyle}>City: </span>
          {city}, <span style={entityTypeStyle}>State: </span>
          {state}
        </>
      );
    case entityTypes.INVOICE: {
      return (
        <>
          <span style={entityTypeStyle}>{t('invoiceDate')}: </span>
          {formatDate(moment(text, 'YYYY-MM-DD'), false, false, true)}
        </>
      );
    }
    default:
      return '--';
  }
}

const SearchItem: FC<SearchItemProps> = ({ lineItem, onSearchItemClicked }) => {
  return (
    <Button
      sx={itemContainerBoxStyle}
      id="parent"
      onClick={() => {
        onSearchItemClicked(lineItem);
      }}
    >
      <Box sx={lineItemContainerStyle}>
        {lineItem?.entityType === entityTypes.DOCUMENT
          ? lineItem?.text === 'image/jpeg' || lineItem?.text === 'image/png'
            ? groupIcons[lineItem?.entityType].jpg
            : groupIcons[lineItem?.entityType].pdf
          : groupIcons[lineItem?.entityType]}
        <Box sx={lineInfoContainerStyle}>
          <Typography sx={labelStyle} align="left">
            {getLabel(lineItem)}
          </Typography>
          <Typography sx={additionalInfoStyle} align="left">
            {getAdditionalData(lineItem)}
          </Typography>
        </Box>
      </Box>
      <Box className="hidden-child">
        <KeyboardReturnIcon style={keyboardReturnIconStyle} />
      </Box>
    </Button>
  );
};

export default SearchItem;
