const CustomGeofenceIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1710_12624)">
        <g clipPath="url(#clip1_1710_12624)">
          <path
            d="M6 12V2"
            stroke="#2B64CB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 8.6665V13.2638"
            stroke="#2B64CB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 7.99998V11.5547C14.0003 11.6944 13.9566 11.8307 13.875 11.9441C13.7935 12.0576 13.6782 12.1424 13.5457 12.1867C12.6808 12.475 10.8284 13.0926 10.2076 13.2996C10.0717 13.3449 9.92475 13.3445 9.78907 13.2986L6.21084 12.1057C6.07398 12.06 5.92602 12.06 5.78916 12.1057L2.87751 13.0763C2.77729 13.1097 2.67057 13.1189 2.56614 13.1029C2.46172 13.087 2.36257 13.0465 2.27687 12.9847C2.19117 12.923 2.12138 12.8417 2.07324 12.7477C2.0251 12.6536 2 12.5495 2 12.4439V3.74184C2 3.60192 2.04402 3.46555 2.12583 3.35204C2.20764 3.23853 2.32309 3.15364 2.45583 3.10939L5.57831 2.06843C5.85202 1.97719 6.14794 1.97719 6.42165 2.06843L7.33333 2.37235"
            stroke="#2B64CB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.5268 5.80451L13.8048 3.52651C14.0642 3.26633 14.0642 2.84536 13.8048 2.58518L12.7482 1.52851C12.488 1.26914 12.067 1.26914 11.8068 1.52851L9.52883 3.80651C9.40396 3.93134 9.33371 4.10061 9.3335 4.27718V5.99984H11.0562C11.2327 5.99963 11.402 5.92938 11.5268 5.80451Z"
            stroke="#2B64CB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1710_12624">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_1710_12624">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 -0.666748)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { CustomGeofenceIcon };
