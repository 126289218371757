import { Grid, Stack, ThemeProvider, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useThemeResponsive } from '../../../../../common/hooks/useThemeResponsive';
import { IPanelName } from '../../../../../store/pageStores/dispatch/dispatchTrip.store';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../../store/root.context';
import { LoadDetailsSummary } from '../../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { defaultDispatch2Filters } from '../../../constants/dispatch';
import {
  DriverFilter,
  getGanttFilterColumns,
} from '../../../constants/filters';
import {
  DispatchFiltersName,
  IDispatchFilters,
} from '../../../constants/types';
import { useDispatchStorage } from '../../../hooks/useDispatchStorage';
import FullScreenButton from '../../Generic/FullScreenButton';
import FullScreenExitButton from '../../Generic/FullScreenExitButton';
import StyledFilters from '../../Generic/StyledFilters';
import { CustomizeFilters } from './CustomizeFilters';
import {
  GanttDisplayMode,
  GanttTabPanelProps,
  ganttTabsPanel,
} from './DisplayMode';
import { getStyledCustomFilter, getThemeDispatchFilters } from './styles';
export interface DispatchFiltersProps {
  onChangeDisplayMode: (tab: GanttTabPanelProps) => void;
  onChange?: (newFilters?: IDispatchFilters) => void;
  isMapView: boolean;
  isGridView: boolean;
  newLoadData: LoadDetailsSummary | null;
  setNewLoadData: React.Dispatch<
    React.SetStateAction<LoadDetailsSummary | null>
  >;
}

export const DispatchFilters = observer(
  ({
    onChangeDisplayMode,
    onChange,
    isMapView,
    isGridView,
  }: DispatchFiltersProps) => {
    const { isMobile, isTablet, isLaptop } = useThemeResponsive();
    const {
      dispatch2TripsStore: {
        secondaryPanelData,
        setFullscreen,
        fullscreenPanel,
      },
      dispatch2GlobalStore: { displayMode, filters, setFilters, resetFilters },
    } = useStores();

    const showQuickFilter = useMemo((): boolean => {
      if (isMobile) {
        return false;
      }
      return true;
    }, [isMobile, isTablet, isLaptop, secondaryPanelData]);

    const currTheme = useTheme();
    const theme = getThemeDispatchFilters({
      currTheme,
      inputWithSearchIcon: true,
    });
    const styles = getStyledCustomFilter({
      theme: currTheme,
      showQuickFilter: showQuickFilter,
    });

    const {
      getDispatchPanelAutoOpenDriver,
      setDispatchPanelAutoOpenDriver,
      getGlobalTerminalsIds,
    } = useRootStore();
    const { getCurrentStorage, updateStorage } = useDispatchStorage();

    const setDefaultFilters = (): void => {
      const defFilters: IDispatchFilters =
        getCurrentStorage() || defaultDispatch2Filters;
      updateStorage(defFilters);

      const finalFilters: IDispatchFilters = {
        ...filters,
        ...defFilters,
      };
      setFilters(finalFilters);

      onChange?.(finalFilters); //TODO: this is first init loading
    };

    const handlePageFilterChange = (finalFilters: IDispatchFilters): void => {
      onChange?.(finalFilters);
    };

    const onPageFilterChange = (
      selectedValues: any,
      fieldFilterName: any
    ): void => {
      const finalFilters: IDispatchFilters = {
        ...filters,
        filters: {
          ...filters.filters,
          [fieldFilterName]: selectedValues,
        },
      };
      updateStorage(finalFilters);
      setFilters(finalFilters);
      handlePageFilterChange(finalFilters);
    };

    const onAllFilterSubmit = (newFilters: IDispatchFilters): void => {
      const finalFilters: IDispatchFilters = {
        ...filters,
        filters: {
          ...filters.filters,
          ...newFilters,
        },
      };
      updateStorage(finalFilters);
      setFilters(finalFilters);
      onChange?.(finalFilters);
    };

    const onAllFilterReset = (): void => {
      updateStorage(defaultDispatch2Filters);
      resetFilters();
      onChange?.(defaultDispatch2Filters);
    };

    const ganttFilers = useMemo(() => {
      const tmpFilter = getGanttFilterColumns({
        showQuickFilter: showQuickFilter,
      });
      if (isMapView) {
        const payload = tmpFilter
          .filter(
            (item: any) =>
              (!item.labelId ||
                !['WindowId', 'SortId'].includes(item.labelId)) &&
              (!item.fieldName || item.fieldName !== 'dateFilter') &&
              item.name !== 'sortDirectionFilter'
          )
          .filter(
            (item) =>
              item.name &&
              ![
                DispatchFiltersName.hasViolation,
                DispatchFiltersName.origin,
                DispatchFiltersName.originRadius,
                DispatchFiltersName.destination,
                DispatchFiltersName.destinationRadius,
                DispatchFiltersName.trips,
              ].includes(item.name)
          );
        return payload;
      }
      if (isGridView) {
        return tmpFilter.filter(
          (item) =>
            [
              DispatchFiltersName.hasViolation,
              DispatchFiltersName.origin,
              DispatchFiltersName.originRadius,
              DispatchFiltersName.destination,
              DispatchFiltersName.destinationRadius,
              DispatchFiltersName.trips,
              DispatchFiltersName.driver,
            ].includes(item.name) &&
            (!item.labelId || !['WindowId', 'SortId'].includes(item.labelId)) &&
            (!item.fieldName || item.fieldName !== 'dateFilter') &&
            item.name !== 'sortDirectionFilter'
        );
      }
      return tmpFilter.filter(
        (item) =>
          ![
            DispatchFiltersName.hasViolation,
            DispatchFiltersName.origin,
            DispatchFiltersName.originRadius,
            DispatchFiltersName.destination,
            DispatchFiltersName.destinationRadius,
            DispatchFiltersName.trips,
          ].includes(item.name)
      );
    }, [isMapView, showQuickFilter, isGridView]);

    const onChangeDisplayModeHandler = (tab: GanttTabPanelProps) => {
      onChangeDisplayMode?.(tab);
      updateStorage({ displayMode: tab.key } as any);
    };

    //Side effect begin

    useEffect(() => {
      setDefaultFilters();
    }, []);

    useEffect(() => {
      if (getDispatchPanelAutoOpenDriver) {
        onPageFilterChange(
          [
            {
              value: getDispatchPanelAutoOpenDriver.name,
              key: getDispatchPanelAutoOpenDriver.driverIds[0],
            },
          ],
          DispatchFiltersName.driver
        );
        setDispatchPanelAutoOpenDriver(null);
      }
    }, [getDispatchPanelAutoOpenDriver]);

    //Side effect end

    const activeDisplayMode: GanttTabPanelProps = useMemo(
      () =>
        ganttTabsPanel?.find(
          (panel: GanttTabPanelProps) => panel.key === displayMode
        ) || ganttTabsPanel[0],
      [ganttTabsPanel, displayMode]
    );

    const FullViewButton =
      fullscreenPanel === IPanelName.DRIVER
        ? FullScreenExitButton
        : FullScreenButton;
    return (
      <Stack sx={styles.root}>
        <CustomizeFilters
          filterColumns={ganttFilers}
          defaultFilterValue={filters.filters}
          onPageFilterChange={onPageFilterChange}
          onAllFilterSubmit={onAllFilterSubmit}
          onAllFilterReset={onAllFilterReset}
          filterPerCol={[3, 8]}
          isGridView={isGridView}
        />
        <Grid id="gantt-additional-filters" sx={styles.RightCustomizeFilters}>
          {showQuickFilter && (
            <ThemeProvider theme={theme}>
              <StyledFilters
                columns={DriverFilter}
                onChangeCb={onPageFilterChange}
                defaultFilterValue={filters.filters}
              />
            </ThemeProvider>
          )}

          <GanttDisplayMode
            defaultTab={activeDisplayMode}
            onChange={onChangeDisplayModeHandler}
          />
          <FullViewButton
            onClick={() => {
              setFullscreen((zoomedPanel) =>
                zoomedPanel === IPanelName.DRIVER ? null : IPanelName.DRIVER
              );
            }}
          />
        </Grid>
      </Stack>
    );
  }
);
