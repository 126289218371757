import { CheckCircleOutline } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { getStyledEDICompanyCard } from './styles';

export const EDICompanyCard = ({
  title,
  activeCommunications,
}: {
  title: string;
  activeCommunications: string[];
}) => {
  const styles = getStyledEDICompanyCard();
  return (
    <Card sx={styles.Card}>
      <CardHeader
        avatar={<CheckCircleOutline color="success" />}
        title={<Typography sx={styles.title}>{title}</Typography>}
      />

      <CardContent sx={styles.CardContent}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Typography sx={styles.groupName}>Active Communications</Typography>
            {activeCommunications.map((com, idx) => (
              <Typography key={idx} component={'li'} sx={styles.groupItem}>
                {com}
              </Typography>
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
