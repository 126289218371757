import { ResourceType } from '../types/gantt.types';

export const UNASSIGNED_RESOURCE_CLASSNAME = 'unassigned-resource-wrapper';
export const DRIVER_RESOURCE_NAME_CLASSNAME = 'b-resource-name-container';
export const COLUMN_FULL_HEIGHT = 'b-column-full-height';

export const DRIVER_ACTION_KEYS = {
  CHANGE_TRIP: 'CHANGE_TRIP',
  CHANGE_DRIVER: 'CHANGE_DRIVER',
};

export const RESOURCES_TYPE = {
  DRIVER: 'DRIVER' as ResourceType,
  TRIP: 'TRIP' as ResourceType,
};

export const DRIVER_COLUMN_MAX_WIDTH = 1250;

export const GANTT_LOADER_CLASSNAME = 'optimize-gantt-loader';
export const OPTYMIZATION_GANTT_COLUMN_HEADER_CLASS =
  'optimization-gantt-column-header';
export const OPTYMIZATION_GANTT_TIMELINE_HEADER_CLASS =
  'optimization-gantt-timeline-header';
