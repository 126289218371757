import React from 'react';

export const OptymLogo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4413_34207)">
        <path
          d="M9.40278 19.2033C6.86159 19.2033 4.80191 17.1453 4.80141 14.6067V8.01172H0.10404C0.0351825 8.47465 0 8.9426 0 9.41055V14.6067C0 19.7943 4.20883 23.9993 9.40177 23.9998H14.6043C15.0727 23.9998 15.5411 23.9647 16.0045 23.8959V19.2033H9.40278Z"
          fill="white"
        />
        <path
          d="M14.6053 9.27559e-07H9.40832C6.01069 -0.00150535 2.87694 1.83163 1.21582 4.79247H14.6053C17.1439 4.79247 19.2021 6.84854 19.2026 9.3846V22.7593C22.1665 21.0994 24.0015 17.9694 24 14.5752V9.3846C24 4.201 19.7942 9.27559e-07 14.6053 9.27559e-07Z"
          fill="#47A9F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_4413_34207">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const OptymLogoDisabled = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4413_34207)">
        <path
          d="M9.40278 19.2033C6.86159 19.2033 4.80191 17.1453 4.80141 14.6067V8.01172H0.10404C0.0351825 8.47465 0 8.9426 0 9.41055V14.6067C0 19.7943 4.20883 23.9993 9.40177 23.9998H14.6043C15.0727 23.9998 15.5411 23.9647 16.0045 23.8959V19.2033H9.40278Z"
          fill="rgba(255, 255, 255, 0.35)"
        />
        <path
          d="M14.6053 9.27559e-07H9.40832C6.01069 -0.00150535 2.87694 1.83163 1.21582 4.79247H14.6053C17.1439 4.79247 19.2021 6.84854 19.2026 9.3846V22.7593C22.1665 21.0994 24.0015 17.9694 24 14.5752V9.3846C24 4.201 19.7942 9.27559e-07 14.6053 9.27559e-07Z"
          fill="rgba(255, 255, 255, 0.35)"
        />
      </g>
      <defs>
        <clipPath id="clip0_4413_34207">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
