import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { terminalService } from '../../../api';
import { ISettingsPagination } from '../../../contexts/TeamDriverContext';
import {
  GetPaginatedTerminalListQueryParams,
  TerminalContentDTO,
  TerminalListDTO,
} from '../../../models';
import { View } from '../../../types';
import { loadAndTripPageSize } from '../../../utils';
import * as types from '../../../views/settings/terminalsV2/constants/types';
import { allTerminalsView } from '../../../views/settings/terminalsV2/constants/views';
import { TSetData, setData } from '../../utils';

const defaultSettingsPagination: Partial<ISettingsPagination> = {
  isLoading: true,
  first: false,
  last: false,
  pageNumber: 0,
  pageSize: loadAndTripPageSize,
};

class TerminalStore {
  @observable component = 'Terminals';
  @observable dictionary = 'asset';
  @observable currentView: View = allTerminalsView;
  @observable addNewClicked = false;
  @observable tableList: TerminalContentDTO[] = [];
  @observable settingsPagination: Partial<ISettingsPagination> =
    defaultSettingsPagination;
  @observable totalTableItems = 0;
  @observable filters: types.ITerminalFilters = {};
  @observable selectedItem: TerminalContentDTO | null = null;
  @observable gridIsLoading = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setAddNewClicked = (value: boolean) => {
    this.addNewClicked = value;
  };

  @action
  setCurrentView = (view: View) => {
    this.currentView = view;
  };

  @action
  updateLocalTableList = async (
    {
      createdList = [],
      deletedList = [],
      updatedList = [],
    }: {
      createdList?: TerminalContentDTO[];
      deletedList?: TerminalContentDTO[];
      updatedList?: TerminalContentDTO[];
    },
    callback?: (carriers: TerminalContentDTO[]) => void
  ) => {
    const newDeletedList: TerminalContentDTO[] = deletedList || [];
    if (createdList?.length || newDeletedList?.length) {
      //
    }

    let newTableList: TerminalContentDTO[] = this.tableList.concat();
    if (createdList?.length) {
      newTableList.unshift(...createdList);
    }

    if (updatedList?.length) {
      updatedList.forEach((item: TerminalContentDTO) => {
        const index = newTableList.findIndex(
          ({ id }: TerminalContentDTO) => id === item.id
        );
        if (index < 0) {
          newTableList.unshift(item);
        } else {
          newTableList.splice(index, 1, item);
        }
      });
    }

    if (newDeletedList?.length) {
      const newDeletedListIds = newDeletedList.map(({ id }) => id);
      newTableList = newTableList.filter(
        ({ id }) => !newDeletedListIds.includes(id)
      );
    }
    this.totalTableItems =
      this.totalTableItems + createdList.length - newDeletedList.length; //Update total items in locally
    this.tableList = newTableList;
    callback?.(newTableList);
  };

  @action
  fetchTableList = async ({
    nextPageNumber,
    nextFilters,
  }: {
    nextPageNumber: number;
    nextFilters: types.ITerminalFilters;
  }): Promise<void> => {
    this.gridIsLoading = true;
    this.settingsPagination = {
      ...this.settingsPagination,
      pageNumber: nextPageNumber,
      isLoading: true,
    };

    try {
      const request = new GetPaginatedTerminalListQueryParams({
        pageNumber: nextPageNumber,
        pageSize: this.settingsPagination.pageSize,
      });

      const response = await terminalService.getTerminalList(request);

      runInAction(() => {
        if (response instanceof TerminalListDTO) {
          this.settingsPagination = {
            ...this.settingsPagination,
            isLoading: false,
            first: response.first,
            last: response.last,
            pageNumber: response.number + 1,
          };
          const items = response.content || [];
          const newTableList: any = !response.number
            ? items
            : [...this.tableList, ...items];
          this.tableList = newTableList;
          this.totalTableItems = response.totalElements;
        } else {
          this.tableList = [];
        }
      });
      this.gridIsLoading = false;
    } catch (e) {
      this.settingsPagination = {
        ...this.settingsPagination,
        isLoading: false,
      };
    }
  };

  @computed
  get getTableList(): TerminalContentDTO[] {
    return toJS(this.tableList);
  }

  @action
  setSettingsPagination = (newValue: TSetData<any>) => {
    this.settingsPagination = setData(newValue, this.settingsPagination);
  };

  @action
  setFilters = (newValue: TSetData<types.ITerminalFilters>) => {
    this.filters = setData(newValue, this.filters);
  };

  @action
  setSelectedItem = (newValue: TSetData<TerminalContentDTO | null>) => {
    this.selectedItem = setData(newValue, this.selectedItem);
  };
}

export function createTerminalStore() {
  return new TerminalStore();
}
