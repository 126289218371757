import { Box, Stack } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import StorageManager from '../../../StorageManager/StorageManager';
import { layoutService } from '../../../api';
import AuthContext from '../../../globalContexts/AuthContext';
import '../../../pages';
import Registration from '../../../pages/register';
import Subscription from '../../../pages/subscription';
import { useRootStore } from '../../../store/root-store/rootStateContext';
import TopMenu from '../../../ui-kit/components/TopMenu';

import { lazyWithRetry } from '../../LazyWithRetry';
import { OpenGlobalPanel } from '../../OpenGlobalPanel';
import LeftMenu from '../../Ui/LeftMenu2.0/LeftMenu';
import MenuTerminal from '../../Ui/LeftMenu2.0/MenuTerminal';
import { PrivateRoute } from '../../auxComponents/PrivateRoute';
import axeleRoutes from '../../auxComponents/axeleRoutes';
import SearchModal from '../SearchModal';
import { getCurrentPage } from '../util';
import SubscribeModal from './SubscribeModal';
import './TrackingMap/TrackingMap';
import {
  getGlobalSearchPanelData,
  getNotificationCount,
  getNotificationList,
  updateReceived,
  updateSingleReadData,
} from './topMenu/constants/helper';
import { NotificationListTypes } from './topMenu/constants/interface';

import { observable } from 'mobx';
import { StaticDataContextProvider } from '../../../contexts/StaticDataContext';
import Contract from '../../../pages/contract';
import Dashboard from '../../../pages/dashboard';
import DispatchPage from '../../../pages/dispatch';
import MyLoads from '../../../pages/myLoads.loadList';
import Trips from '../../../pages/trips.tripList';
import { useEDITendersStore } from '../../../store/EDITenders/EDITenders';
import {
  chunkProcessor,
  chunkProcessorDebounce,
  chunkProcessorMaxChunkSize,
} from '../../../utils/chunkProcessor';
import RegistrationLandingPage from '../../../views/register/RegistrationLandingPage';
import RegistrationVerificationPage from '../../../views/register/RegistrationVerificationPage';
import { DirtyDetailsPanelManager } from '../../DetailsPanel/utils';
import { layoutMenuStyles } from './styles';
// import { InvoiceList } from '../../../pages/invoices.invoicesList';
import { AXELE_USER_TYPES } from '../../../common/Permission/types/AxelePermission';
import { AdvancedReport } from '../../../pages/report.advanceReport';
import ForgotPasswordForm from '../../../views/register/ForgotPasswordForm';
import LoginForm from '../../../views/register/LoginForm';
import RegistrationReJoinPage from '../../../views/register/RegistrationReJoinPage';
import ResetPasswordForm from '../../../views/register/ResetPasswordForm';
import ReactivationFlowPage from '@/views/register/ReactivationFlow';

const Templates = lazyWithRetry(
  () => import('../../../pages/myLoads.templates')
);
const Equipment = lazyWithRetry(
  () => import('../../../pages/operations.equipment')
);
const Contacts = lazyWithRetry(
  () => import('../../../pages/operations.contacts')
);
const Documents = lazyWithRetry(
  () => import('../../../pages/reports.documents')
);
const Expenses = lazyWithRetry(() => import('../../../pages/finance.expenses'));
const FinanceAccountSummary = lazyWithRetry(
  () => import('../../../pages/finance.accountSummary')
);
const Payments = lazyWithRetry(
  () => import('../../../pages/finance.payStatements')
);
const SettingsUsers = lazyWithRetry(
  () => import('../../../pages/settings.users')
);
const Terminal = lazyWithRetry(
  () => import('../../../pages/settings.terminals')
);
const LoadboardSearch = lazyWithRetry(
  () => import('../../../pages/loads.search')
);
const Invoices = lazyWithRetry(() => import('../../../pages/finance.invoices'));
const Import = lazyWithRetry(() => import('../../../pages/settings.import'));

const BasicReports = lazyWithRetry(
  () => import('../../../pages/reports.basicReports')
);
const Integration = lazyWithRetry(
  () => import('../../../pages/settings.integrations')
);
const TeamsDriver = lazyWithRetry(
  () => import('../../../pages/operations.teamsDriverInfo')
);
const MaintenanceRecords = lazyWithRetry(
  () => import('../../../pages/safety.maintenanceHistory')
);
const SafetyAlertsPage = lazyWithRetry(
  () => import('../../../pages/safety.alerts')
);
const TrendAnalysis = lazyWithRetry(
  () => import('../../../pages/reports.trendAnalysis')
);

const IFTA = lazyWithRetry(() => import('../../../pages/reports.iftaCenter'));

const ManageTypesPage = lazyWithRetry(
  () => import('../../../pages/settings.manageTypes')
);
const PreferencesPage = lazyWithRetry(
  () => import('../../../pages/settings.preferences')
);
const EDITendersPage = lazyWithRetry(
  () => import('../../../pages/loads.EDITenders')
);
// const RegistrationReJoinPage = lazyWithRetry(
//   () => import('../../../views/register/RegistrationReJoinPage')
// );
const InvoiceList = lazyWithRetry(
  () => import('../../../pages/invoices.invoicesList')
);
const FuelMatrix = lazyWithRetry(
  () => import('../../../pages/settings.fuelMatrix')
);
const Roles = lazyWithRetry(() => import('../../../pages/settings.roles'));
const PaymentPage = lazyWithRetry(
  () => import('../../../pages/finance.payment')
);

type LayoutProps = {};

const TRIPPLANREADY = 'TRIPPLANREADY';
const Layout = ({}: LayoutProps): JSX.Element => {
  const userStorage = StorageManager.getUser();
  const globalTerminalStorage = StorageManager.getItem(
    'globalSelectedTerminals'
  );
  const [pastDue, setPastDue] = useState<number | undefined>(undefined);
  const [trialDays, setTrialDays] = useState<number | undefined>(undefined);
  const [openSubscribeModal, setOpenSubscribeModal] = useState<boolean>(false);
  const connectionURL = `${window.location.origin}/notifications/load/messages/reactive?organizationId=${userStorage.organizationId}&receiverId=${userStorage.id}&delay=10`;

  const { logoutUser } = useContext(AuthContext);
  let sse: any = null;
  let chunkProcessorDispose: any = null;

  const {
    setGlobalTerminals,
    setAllTerminals,
    setTerminalEnabled,
    getIsTerminalEnabled,
    pushPendingDispatchDriverGroupIds,
    setHomeReady,
    setEventSourceData,
    setCompanyPreferences,
    themeMode,
  } = useRootStore();

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [terminalData, setTerminalData] = useState([]);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<{
    type: string;
    content: string;
  }>({ type: 'info', content: '' });

  const [currentPage, setCurrentPage] = useState<any>({});
  const [mainMenu, setMainMenu] = useState<string>('');
  const [subMenu, setSubMenu] = useState<string>('');
  const [selectedTerminal, setSelectedTerminal] = useState(
    globalTerminalStorage ? globalTerminalStorage : []
  );

  const [startLoading, setStartLoading] = useState<boolean>(false);

  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [notificationList, setNotificationList] = useState<
    NotificationListTypes[]
  >([]);

  const [showOpenGlobalPanel, setShowOpenGlobalPanel] = useState(false);
  const [selectedSearchItem, setSelectedSearchItem] = useState({});
  const [globalPanelSource, setGlobalPanelSource] = useState<
    'globalSearch' | 'notification' | 'other' | undefined
  >('globalSearch');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const navigate = useNavigate();

  const sseExecution = async () => {
    sse = new EventSource(connectionURL);
    sse.onmessage = (e: any) => {
      if (e.data != 'Connection Live Event!') handleStream(e);
    };
    sse.onerror = (e: any) => {
      sse.close();
    };
  };

  useEffect(() => {
    const pathName =
      window.location.pathname === '/' ? 'loads' : window.location.pathname;
    const mainMenu =
      pathName === 'loads' ? 'loads' : window.location.pathname.split('/')[1];
    setCurrentPage(getCurrentPage(pathName));
    setMainMenu(mainMenu);
    setSubMenu(window.location.pathname.split('/')[2]);
  }, [window.location.pathname]);

  useEffect(() => {
    initializePendo(userStorage);
  }, []);

  useEffect(() => {
    sseExecution();
    return () => {
      sse.close();
    };
  }, []);

  const MINUTE_MS = 5000;
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        sse.readyState == EventSource.CLOSED &&
        !(process.env.NODE_ENV === 'development')
      ) {
        sseExecution();
        getNotificationTrayList();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const initializePendo = (userData: any) => {
    if (!window.hasOwnProperty('pendo')) return;
    (window as any)?.pendo.initialize({
      visitor: {
        id: userData.id,
        email: userData.email,
        full_name: `${userData.firstname} ${userData.lastname}`,
        role: userData.roleCode,
      },
      account: {
        id: userData.organizationId,
        name: userData.organizationName,
        subscriptionType: userData.subscriptionType,
      },
    });
  };

  const setNotificationListHandler = (list: NotificationListTypes[]) => {
    setNotificationList(
      list?.map((item) => {
        return {
          ...item,
          entityAxeleId: item?.entityAxeleId || 'EDI',
        };
      })
    );
  };

  const driverGroupIdObservable = observable([]);
  chunkProcessorDispose = chunkProcessor(
    driverGroupIdObservable,
    (chunkItems) => {
      pushPendingDispatchDriverGroupIds(chunkItems);
    },
    chunkProcessorDebounce,
    chunkProcessorMaxChunkSize
  );

  const handleStream = async (event: any) => {
    let toastData = { message: '' };
    try {
      if (event?.data) {
        toastData = JSON.parse(event.data);
        if ((toastData as any)?.event === TRIPPLANREADY) {
          dispatchEvent(new Event(TRIPPLANREADY));
          setEventSourceData(toastData);
          if ((toastData as any)?.driverGroupId) {
            driverGroupIdObservable.push(toastData?.driverGroupId);
          }
        } else {
          setSnackbarMessage({ type: 'success', content: toastData.message });
          setIsSnackbarOpen(true);
        }
      }
    } catch (error) {
      console.error(error);
    }

    const tempUnreadCount = await getNotificationCount();
    setUnreadCount(tempUnreadCount);
  };

  const setNotificationReceived = (toastData: any) => {
    if (toastData.trayReady) return;
    return updateReceived(toastData.id);
  };

  const getNotificationTrayList = async () => {
    const tempNotificationList = await getNotificationList();
    setNotificationListHandler(tempNotificationList);

    const tempUnreadCount = await getNotificationCount();
    setUnreadCount(tempUnreadCount);
  };

  useEffect(() => {
    getTerminalData();
    getNotificationTrayList();
    getSupportTerminalAndPrefferedTimeZone();
    return () => {
      chunkProcessorDispose?.();
    };
  }, []);

  useEffect(() => {
    const current_period_ends_at = userStorage.effectiveTerminationDate;
    const duePast =
      userStorage?.subscriptionStatus === 'PAST_DUE' ||
      userStorage.cancelAtEndOfPeriod;
    if (current_period_ends_at) {
      const suspensionDate = moment(current_period_ends_at).startOf('day');
      const daysRemaining = suspensionDate.diff(
        moment().startOf('day'),
        'days'
      );
      if (daysRemaining) {
        if (duePast) setPastDue(daysRemaining);
        if (userStorage?.subscriptionStatus === 'EVALUATION')
          setTrialDays(daysRemaining);
      }
      if (userStorage?.subscriptionStatus !== 'EVALUATION')
        setOpenSubscribeModal(userStorage?.userType === 'OWNER_ADMIN');
    } else {
      setTrialDays(undefined);
    }
  }, [userStorage.effectiveTerminationDate]);

  const onclose = () => {
    setOpenSubscribeModal(false);
  };

  const getSupportTerminalAndPrefferedTimeZone = async () => {
    const res = await setCompanyPreferences();
    const isSupportTerminal: boolean =
      res && res.companyDetails && res.companyDetails.isSupportTerminal
        ? res.companyDetails.isSupportTerminal
        : false;
    setTerminalEnabled(isSupportTerminal);
    StorageManager.setItem('terminalEnabled', isSupportTerminal);
  };

  const getTerminalData = async () => {
    const response: any = await layoutService.getGlobalTerminals();
    setTerminalData(response?.content ? response?.content : []);
    setAllTerminals(response?.content ? response?.content : []);
    StorageManager.setItem(
      'allTerminals',
      response?.content ? response.content : []
    );
    if (response?.content && globalTerminalStorage) {
      const tempFilter = globalTerminalStorage.filter(
        ({ id: id1 }: any) =>
          !response.content.some(({ id: id2 }: any) => id2 === id1)
      );
      if (tempFilter.length > 0) {
        let tempSelected: any = [];
        globalTerminalStorage.map((storageItem: any) => {
          tempFilter.map((filterItem: any) => {
            if (storageItem.id != filterItem.id)
              tempSelected = [...tempSelected, storageItem];
          });
        });
        setSelectedTerminal(tempSelected);
        setGlobalTerminals?.(tempSelected);
        StorageManager.setItem('globalSelectedTerminals', tempSelected);
      }
    }
    setHomeReady(true);
  };

  const onChangeTerminals = (newValue: any, event: any) => {
    setSelectedTerminal(newValue);
    setGlobalTerminals?.(newValue);
    StorageManager.setItem('globalSelectedTerminals', newValue);
  };

  const { setOpenEDITenderId } = useEDITendersStore();
  const onNotificationClicked = async (
    item: NotificationListTypes,
    markAll: boolean
  ) => {
    setAnchorEl(null);
    if (item?.entityType !== 'EDI_TENDER') {
      setGlobalPanelSource('notification');
      setShowOpenGlobalPanel(true);
      setSelectedSearchItem(item);
    }
    await updateSingleReadData(markAll ? '' : item.id, markAll);
    const tempUnreadCount = await getNotificationCount();
    setUnreadCount(tempUnreadCount);
    const tempNotificationList = await getNotificationList();
    setNotificationListHandler(tempNotificationList);

    if (item?.entityType === 'EDI_TENDER') {
      // if (item.entityId) setOpenEDITenderId(item.entityId);
      handleRedirection('/loads/EDITenders');
    }
  };

  const handleRedirection = (redirectionURL: string) => {
    navigate(redirectionURL);
  };

  const onSearchItemClicked = async (lineItem: any) => {
    if (lineItem?.entityType && lineItem.entityType != 'DOCUMENT') {
      setGlobalPanelSource('globalSearch');
      setShowOpenGlobalPanel(true);
      setSelectedSearchItem(lineItem);
    }
  };

  async function getGlobalSearchData(inputString: string): Promise<any | null> {
    setStartLoading(true);
    const getRes = await getGlobalSearchPanelData(inputString);
    setStartLoading(false);
    setSearchData(getRes);
  }

  const [openLeftMenu, setOpenLeftMenu] = useState<boolean>(false);
  const onClickMenuHandler = (event: MouseEvent): void => {
    event?.preventDefault();
    setOpenLeftMenu((prev) => !prev);
  };

  const deleteItemFromData = (entityId: number) => {
    const filtredSearchData = (searchData as any)?.resultList?.filter(
      (item: any) => item.entityId !== entityId?.toString()
    );
    setSearchData({ resultList: filtredSearchData } as any);
  };

  const menuTerminalRender = useCallback(() => {
    return (
      <MenuTerminal
        terminals={terminalData}
        getTerminalData={getTerminalData}
        onChangeTerminals={onChangeTerminals}
        selectedTerminal={selectedTerminal}
      />
    );
  }, [getTerminalData, onChangeTerminals, selectedTerminal, terminalData]);

  return (
    <Box
      sx={() => ({
        // height: currentPage?.fullheight ? '100%' : 'calc(100% - 30px)',
        backgroundColor: '#35446B',
        width: '100%',
        height: '100%',
      })}
    >
      {/* please be sure that the top navigation will not affect to the whole page scrolling functional */}
      <Stack direction="row" height="100%">
        <LeftMenu
          handleClick={handleRedirection}
          mainMenu={mainMenu}
          subMenu={subMenu}
          getIsTerminalEnabled={getIsTerminalEnabled}
          menuTerminalRender={menuTerminalRender}
          open={openLeftMenu}
          onClose={onClickMenuHandler as (event: MouseEvent<any>) => void}
          userStorage={userStorage}
        />
        <Stack direction="column" flexGrow={1} width="calc(100% - 75px)">
          <TopMenu
            subscriptionType={
              userStorage?.subscriptionType
                ? userStorage.subscriptionType.toLowerCase()
                : ''
            }
            isAdmin={userStorage?.roleCode === 'AD'}
            page={{
              iconName: currentPage.iconName,
              source: 'MUIcons',
              sectionName: currentPage.mainPage
                ? currentPage.mainPage
                : currentPage?.name,
              pageName: currentPage.mainPage ? currentPage.name : '',
            }}
            redirectToSubscriptionPage={() => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;

              handleRedirection('/subscription');
            }}
            redirectToUserPage={() => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              const settings = StorageManager.getItem('user-settings');

              if (settings) {
                if (settings?.pages?.Users)
                  settings.pages.Users.stripId = 'user';
                StorageManager.setItem('user-settings', settings);
              }
              StorageManager.setItem('userPanel', true);
              if (window.location.pathname === '/settings/users')
                window.location.replace('/settings/users');
              else handleRedirection('/settings/users');
            }}
            logout={logoutUser}
            notificationList={notificationList}
            userName={userStorage?.firstname}
            daysLeft={20}
            XPPoints="3,200"
            onSearch={() => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              setShowSearchModal(true);
              setSearchData([]);
            }}
            unreadCount={unreadCount}
            onNotificationClicked={onNotificationClicked}
            getNotificationTrayList={getNotificationTrayList}
            getIsTerminalEnabled={getIsTerminalEnabled}
            anchorEl={anchorEl}
            setAnchorEl={(...params) => {
              if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
              setAnchorEl(...params);
            }}
            trialDays={trialDays}
            pastDue={pastDue}
            pastDueClicked={() => {
              if (userStorage.userType === 'OWNER_ADMIN')
                navigate('/subscription');
            }}
            onClickMenuHandler={onClickMenuHandler}
            userData={userStorage}
            menuTerminalRender={menuTerminalRender}
            isSubscriptionView={!!(userStorage.userType === 'OWNER_ADMIN')}
            isOwnerAdmin={userStorage.userType === AXELE_USER_TYPES.OWNER_ADMIN}
          />
          <StaticDataContextProvider>
            <Box className={'Layout-content'} sx={layoutMenuStyles}>
              {/* <Suspense fallback={<LoadingAnimation />}> */}
              <Suspense fallback={<></>}>
                <Outlet />
              </Suspense>
            </Box>
          </StaticDataContextProvider>
        </Stack>
      </Stack>

      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          sx={{
            color: 'primary.contrast',
            fontSize: '14px',
            width: '100%',
          }}
          variant="filled"
          color={
            snackbarMessage?.type as
              | 'success'
              | 'info'
              | 'warning'
              | 'error'
              | undefined
          }
          severity={snackbarMessage.type as AlertColor}
        >
          {snackbarMessage?.content}
        </Alert>
      </Snackbar>
      {openSubscribeModal && (
        <SubscribeModal
          onclose={onclose}
          openSubscribeModal={openSubscribeModal}
          navigate={navigate}
        />
      )}
      <SearchModal
        show={showSearchModal}
        onSearch={(inputString: string) => {
          getGlobalSearchData(inputString);
        }}
        onClose={() => {
          setShowSearchModal(false);
        }}
        clear={() => {
          setSearchData([]);
        }}
        isLoading={startLoading}
        setStartLoading={setStartLoading}
        data={searchData}
        onSearchItemClicked={onSearchItemClicked}
      />
      {showOpenGlobalPanel && (
        <OpenGlobalPanel
          selectedSearchItem={selectedSearchItem}
          source={globalPanelSource}
          deleteItemCB={deleteItemFromData}
          setShowSearchModal={setShowSearchModal}
          setShowOpenGlobalPanel={setShowOpenGlobalPanel}
        />
      )}
    </Box>
  );
};

export default observer(Layout);

axeleRoutes.addRoute({
  path: '/',
  pathId: 'layout',
  element: (
    <PrivateRoute>
      <Layout />
    </PrivateRoute>
  ),
});

axeleRoutes.addRoute({
  path: '/home',
  pathId: 'home',
  parentId: 'layout',
  element: <Dashboard />,
});

axeleRoutes.addRoute({
  path: '/dispatch',
  pathId: 'dispatch',
  parentId: 'layout',
  element: <DispatchPage />,
});

axeleRoutes.addRoute({
  path: '/operations/equipment',
  pathId: 'Equipment',
  parentId: 'layout',
  element: <Equipment />,
});

axeleRoutes.addRoute({
  path: '/settings/contracts',
  pathId: 'settings',
  parentId: 'layout',
  element: <Contract />,
});

axeleRoutes.addRoute({
  path: '/',
  pathId: 'my-loads',
  parentId: 'layout',
  element: <MyLoads />,
});

axeleRoutes.addRoute({
  path: '/loads/templates',
  pathId: 'templates',
  parentId: 'layout',
  element: <Templates />,
});

axeleRoutes.addRoute({
  path: '/login',
  pathId: 'login',
  element: <LoginForm />,
});

axeleRoutes.addRoute({
  path: '/operations/contacts',
  pathId: 'Con',
  parentId: 'layout',
  element: <Contacts />,
});

axeleRoutes.addRoute({
  path: '/reports/documents',
  pathId: 'Default Types',
  parentId: 'layout',
  element: <Documents />,
});

axeleRoutes.addRoute({
  path: '/finance/expenses',
  pathId: 'Expenses',
  parentId: 'layout',
  element: <Expenses />,
});

axeleRoutes.addRoute({
  path: '/finance/settlement',
  pathId: 'settlement',
  parentId: 'layout',
  element: <Payments />,
});

axeleRoutes.addRoute({
  path: '/finance/summary',
  pathId: 'Summary',
  parentId: 'layout',
  element: <FinanceAccountSummary />,
});

axeleRoutes.addRoute({
  path: '/settings/users',
  pathId: 'Users',
  parentId: 'layout',
  element: <SettingsUsers />,
});

axeleRoutes.addRoute({
  path: '/loads/loadboards',
  pathId: 'loadboards',
  parentId: 'layout',
  element: <LoadboardSearch />,
});

axeleRoutes.addRoute({
  path: '/loads/EDITenders',
  pathId: 'EDITenders',
  parentId: 'layout',
  element: <EDITendersPage />,
});

axeleRoutes.addRoute({
  path: '/settings/terminals',
  pathId: 'Terminals',
  parentId: 'layout',
  element: <Terminal />,
});

axeleRoutes.addRoute({
  path: '/settings/import',
  pathId: 'Import',
  parentId: 'layout',
  element: <Import />,
});

axeleRoutes.addRoute({
  path: '/finance/invoicesOld',
  pathId: 'Invoices',
  parentId: 'layout',
  element: <Invoices />,
});

axeleRoutes.addRoute({
  path: '/settings/manageTypes',
  pathId: 'ManageTypes',
  parentId: 'layout',
  element: <ManageTypesPage />,
});

axeleRoutes.addRoute({
  path: '/reports/basic-reports',
  pathId: 'basic-reports',
  parentId: 'layout',
  element: <BasicReports />,
});

axeleRoutes.addRoute({
  path: '/reports/advanced-report',
  pathId: 'advanced-reports',
  parentId: 'layout',
  element: <AdvancedReport />,
});

axeleRoutes.addRoute({
  path: '/settings/integrations',
  pathId: 'integrations',
  element: <Integration />,
  parentId: 'layout',
});

axeleRoutes.addRoute({
  path: '/safety/maintenance-history',
  pathId: 'Maintenance Records',
  parentId: 'layout',
  element: <MaintenanceRecords />,
});

axeleRoutes.addRoute({
  path: '/operations/teams',
  pathId: 'teams',
  parentId: 'layout',
  element: <TeamsDriver />,
});
axeleRoutes.addRoute({
  path: '/safety/upcoming-events',
  pathId: 'upcoming-events',
  parentId: 'layout',
  element: <SafetyAlertsPage />,
});

axeleRoutes.addRoute({
  path: '/reports/trend-analysis',
  pathId: 'trend-analysis',
  parentId: 'layout',
  element: <TrendAnalysis />,
});
axeleRoutes.addRoute({
  path: '/reports/ifta-center',
  pathId: 'ifta-center',
  parentId: 'layout',
  element: <IFTA />,
});

axeleRoutes.addRoute({
  path: '/loads/trips',
  pathId: 'Trips',
  parentId: 'layout',
  element: <Trips />,
});

axeleRoutes.addRoute({
  path: '/registration',
  pathId: 'RegistrationPage',
  element: <RegistrationLandingPage />,
});
axeleRoutes.addRoute({
  path: '/registration/verification',
  pathId: 'verification',
  element: <RegistrationVerificationPage />,
});

axeleRoutes.addRoute({
  path: '/forgot-password',
  pathId: 'forgotPassword',
  element: <ForgotPasswordForm />,
});

axeleRoutes.addRoute({
  path: '/reset-password',
  pathId: 'resetPassword',
  element: <ResetPasswordForm />,
});

axeleRoutes.addRoute({
  path: '/subscription',
  pathId: 'subscription',
  parentId: 'layout',
  element: <Subscription />,
});

axeleRoutes.addRoute({
  path: '/account-activization',
  pathId: 'accountActivization',
  element: <Registration />,
});

axeleRoutes.addRoute({
  path: '/account-invitation',
  pathId: 'accountInvitation',
  element: <Registration />,
});

axeleRoutes.addRoute({
  path: '/account-activization-success',
  pathId: 'accountActivizationSuccess',
  element: <Registration />,
});
axeleRoutes.addRoute({
  path: '/account-activization-error',
  pathId: 'accountActivizationError',
  element: <Registration />,
});

axeleRoutes.addRoute({
  path: '/settings/preferences',
  pathId: 'preferences',
  parentId: 'layout',
  element: <PreferencesPage />,
});
axeleRoutes.addRoute({
  path: '/settings/fuel-surcharge-rate-matrix',
  pathId: 'fuelMatrix',
  parentId: 'layout',
  element: <FuelMatrix />,
});
axeleRoutes.addRoute({
  path: '/finance/invoices',
  pathId: 'complete',
  parentId: 'layout',
  element: <InvoiceList />,
});
axeleRoutes.addRoute({
  path: '/rejoin-registration',
  pathId: 'rejoin-registration',
  element: <RegistrationReJoinPage />,
});
axeleRoutes.addRoute({
  path: '/reactivation',
  pathId: 'reactivation',
  element: <ReactivationFlowPage />,
});
axeleRoutes.addRoute({
  path: '/settings/roles',
  pathId: 'roles',
  parentId: 'layout',
  element: <Roles />,
});
axeleRoutes.addRoute({
  path: '/settings/fuel-surcharge-rate-matrix',
  pathId: 'roles',
  parentId: 'layout',
  element: <Roles />,
});
axeleRoutes.addRoute({
  path: '/finance/payments',
  pathId: 'payments',
  parentId: 'layout',
  element: <PaymentPage />,
});
