import { Type } from 'class-transformer';
import { RelayStopFormData } from '../../../../common/LoadTabPanel/tabs/Routes/components/RelayStopPopup/RelayStopForm';
import { LoadStopContact, NoteDetails } from '../../../../models';
import { PhoneDTO } from '../../../../models/DTOs/Contacts/Contacts';
import uuid from '../../../../utils/uuid';
import { ICoordinates } from '../../../../views/loadboards/loadTypes';
import {
  LineItem,
  ReceivePayment,
} from '../../../invoices/models/InvoiceModel';
import { AppointmentConfirmationEnum } from '../../CreateLoad/types/requests';

// @TODO: Nvard - refactor models

class Location {
  id!: number | null;
  locationName?: string;
  locationPreference?: string;
  avgHandlingTime?: number;
  timezone?: string;
  fullAddress?: string;
  notes?: string;
  locationDisplayName?: string;

  getLoadStopLocation(dto: any) {
    this.id = dto.id || null;
    this.locationName = dto.locationName || '';
    this.fullAddress = dto.fullAddress || dto.address || '';
    this.timezone = dto.timezone || '';
    this.locationDisplayName = dto.locationName || '';
  }
}
export class CenterDTO {
  lat!: number;
  lng!: number;
}

class AddressDTO {
  fullAddress!: string;
  address!: string;
  city!: string;
  state!: string;
  streetAddress!: string;
  streetAddress2!: string;
  zipcode!: string;
  @Type(() => CenterDTO)
  center!: CenterDTO;
  timezone!: string;
  geoCoordinates!: ICoordinates;

  getAddressDetailsFromLoadStopDTO(dto: any) {
    this.city = dto.city;
    this.address = dto.address;
    this.fullAddress = dto.fullAddress || dto.address;
    this.state = dto.state;
    this.zipcode = dto.zipcode;
    this.streetAddress = dto.streetAddress || '';
    this.streetAddress2 = dto.streetAddress2 || '';
    const center = new CenterDTO();
    center.lat = dto.lat;
    center.lng = dto.lng;
    this.center = center;
    this.timezone = dto.timezone;
    this.geoCoordinates = dto.geoCoordinates;
  }
}

class StopContact {
  fullName: string;
  email: string;
  @Type(() => PhoneDTO)
  phoneData: PhoneDTO | null;

  constructor(data: LoadStopContact) {
    this.fullName = data?.firstname || '';
    this.email = data?.email || '';
    const phoneData = new PhoneDTO();
    phoneData.phone = data?.phone;
    phoneData.extension = data?.extension;
    phoneData.countryCode = data?.countryCode;
    this.phoneData = phoneData;
  }
}
// @TODO: AXL2-4132: review usages of Stop and handle key field
export class Stop {
  id!: number;
  key?: string;
  stopType?: string;
  loadStopId?: number;
  @Type(() => Location)
  location!: Location | null;
  @Type(() => AddressDTO)
  addressDTO!: AddressDTO | null;
  activityType!: string;
  isDriverAssistRequired!: boolean;
  refNumber!: string;
  appointmentStartDate!: string;
  appointmentEndDate!: string;
  fixedAppointment!: boolean;
  avgHandlingTime!: number;
  stopStatus!: string;
  notes!: string;
  noteDetails?: NoteDetails | null;
  @Type(() => StopContact)
  contact!: StopContact | null;
  appointmentConfirmation?: AppointmentConfirmationEnum;
  getStopDetailsFromLoadStopDTO(dto: any) {
    this.id = dto.id;
    this.activityType = dto.activityType;
    this.isDriverAssistRequired = dto.isDriverAssistRequired || false;
    this.fixedAppointment = dto.fixedAppointment || false;
    this.avgHandlingTime =
      dto.estimatedActivityDuration || dto.avgHandlingTime || 120;
    this.appointmentStartDate = dto.appointmentStartDate;
    this.appointmentEndDate = dto.appointmentEndDate;
    this.notes = dto.notes;
    this.noteDetails = dto.noteDetails || null;
    this.refNumber = dto.refNumber;
    let location = null;
    let addressDTO = {};
    if (dto.location) {
      location = new Location();
      location.getLoadStopLocation(dto.location);
      addressDTO = new AddressDTO();
      addressDTO.getAddressDetailsFromLoadStopDTO(dto.location);
    }
    this.location = location;
    this.addressDTO = addressDTO;
    this.contact = new StopContact(dto.contact);
    this.appointmentConfirmation =
      dto?.appointmentConfirmation ?? AppointmentConfirmationEnum.NO;
  }
  setUniqueKey() {
    this.key = uuid();
  }
}
export class EquipmentType {
  key!: string;
  value!: string;
}
class Contact {
  id!: number;
  fullName!: string;
}

class Customer {
  id!: number | null;
  name!: string;
  @Type(() => Contact)
  contacts!: Contact[];
}

class Factoring {
  id!: string;
  displayName!: string;
}

class Terminal {
  id!: string;
  name!: string;
  companyName?: string;
}

class Template {
  id!: string;
  templateName!: string;
}

export class Commodity {
  commodity!: { id: string; itemName: string };
  id!: string;
  commodityTypeId!: string;
  commodityType!: string;
  commodityTypeDisplayValue!: string;
  description!: string;
  floorLoad!: boolean;
  height!: string;
  length!: string;
  width!: string;
  packageType!: string;
  qty!: number;
  stackable!: boolean;
  volume!: string;
  weight!: string;
}

export class AssignedDriver {
  driverGroupId: string;
  driverGroupName: string;
  assignToDriver: boolean;
}

export class LoadDetailsSummary {
  id: string | null;
  @Type(() => EquipmentType)
  equipmentTypes: EquipmentType[] | null;
  sealNumber: string;
  containerNumber: string;
  chassisNumber: string;
  bookingNumber?: string;
  reeferMode?: string;
  appointmentNumber?: string;
  loadDirection?: string;
  lowerLimitTemperature: string;
  upperLimitTemperature: string;
  totalWeight: number | null;
  // AXL2-1506 @TODO: ask Davit to make changes  in InvoiceDetails
  // since InvocieDetails component is reused from Invoice page invoice data is not kept in Invoice model to avoid fied names changes (nameStarts, validationschema)
  @Type(() => LineItem)
  lineItems: LineItem[];
  @Type(() => ReceivePayment)
  receivedPayments: ReceivePayment[];
  factoringChargePercent: number | null;
  factoringNotes: string;
  billOfLadingMandatory: boolean;
  proofOfDeliveryMandatory: boolean;
  invoiceTerm: string;
  invoiceTermValue: number | null;
  invoiceNotes: string;
  invoiceNoteDetails?: NoteDetails | null;
  revenue?: number | null;

  notes: string;
  noteDetails?: NoteDetails | null;
  loadType: string;
  @Type(() => Customer)
  customer: Customer | null;
  @Type(() => Contact)
  contact: Contact | null;
  referenceId: string;
  @Type(() => Factoring)
  factoring: Factoring | null;
  @Type(() => Terminal)
  terminal: Terminal | null;
  @Type(() => Stop)
  stops: Stop[] | RelayStopFormData[];
  loadCategory: string;
  loadStatus: string;
  @Type(() => Commodity)
  commodityData: Commodity[] | [];
  @Type(() => Template)
  template: Template | null;
  saveAsTemplate: boolean | null;
  newTemplateName: string;
  @Type(() => AssignedDriver)
  assignedDriver?: AssignedDriver | null;
  constructor({
    id = null,
    equipmentTypes = [],
    sealNumber = '',
    containerNumber = '',
    chassisNumber = '',
    bookingNumber = '',
    appointmentNumber = '',
    loadDirection = null,
    lowerLimitTemperature = '',
    upperLimitTemperature = '',
    totalWeight = null,
    notes = '',
    noteDetails = null,
    invoiceTerm = '',
    invoiceTermValue = null,
    invoiceNotes = '',
    invoiceNoteDetails = null,
    lineItems = [],
    revenue = null,
    receivedPayments = [],
    factoringChargePercent = null,
    factoringNotes = '',
    billOfLadingMandatory = true,
    proofOfDeliveryMandatory = true,
    loadType = 'FTL',
    customer = null,
    contact = null,
    referenceId = '',
    terminal = null,
    factoring = null,
    stops = [],
    loadCategory = 'LOAD', // @TODO:AXL2-5205: Nvard - check category in case of template in axele1
    loadStatus = 'AVAILABLE',
    commodityData = [],
    template = null,
    saveAsTemplate = false,
    newTemplateName = '',
    driverName = '',
    assignedDriver = null,
  }: any) {
    this.id = id;
    this.equipmentTypes = equipmentTypes;
    this.sealNumber = sealNumber;
    this.chassisNumber = chassisNumber;
    this.bookingNumber = bookingNumber;
    this.appointmentNumber = appointmentNumber;
    this.loadDirection = loadDirection;
    this.containerNumber = containerNumber;
    this.lowerLimitTemperature = lowerLimitTemperature;
    this.upperLimitTemperature = upperLimitTemperature;
    this.totalWeight = totalWeight;
    this.notes = notes;
    if (noteDetails) {
      this.noteDetails = noteDetails;
    }
    this.invoiceTerm = invoiceTerm;
    this.invoiceTermValue = invoiceTermValue;
    this.invoiceNotes = invoiceNotes;
    if (invoiceNoteDetails) {
      this.invoiceNoteDetails = invoiceNoteDetails;
    }
    this.factoringNotes = factoringNotes;
    this.lineItems = lineItems;
    this.revenue = revenue;
    this.receivedPayments = receivedPayments;
    this.factoringChargePercent = factoringChargePercent;
    this.billOfLadingMandatory = billOfLadingMandatory;
    this.proofOfDeliveryMandatory = proofOfDeliveryMandatory;
    this.loadType = loadType || 'FTL';
    this.customer = customer;
    this.contact = contact;
    this.referenceId = referenceId;
    this.factoring = factoring;
    this.terminal = terminal;
    this.stops = stops;
    this.loadCategory = loadCategory;
    this.loadStatus = loadStatus;
    this.commodityData = commodityData;
    this.template = template;
    this.saveAsTemplate = saveAsTemplate;
    this.newTemplateName = newTemplateName;
    this.driverName = driverName;
    this.assignedDriver = assignedDriver;
  }
}
