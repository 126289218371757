/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import ThreeDotMenuIcon from '../../../../ui-kit/components/ThreeDotMenuIcon';
import { ContactSummaryDTO } from '../../../../models/DTOs';
import Accordion from '../../../../common/Accordion';
import TextField from '../../../../common/Ui/TextField/TextField';
import Switch from '../../../../common/Ui/Switcher';
import Typography from '@mui/material/Typography';
import FormPhoneField from '../../../../common/Ui/FormPhoneField/index';
import {
  formatPhoneNumber,
  getAccountTypeWithRoleCode,
  isHasPermission,
} from '../../../../utils/index';
import { contactDeleteAction } from '../../../shared/constants';
import { AXELE_PERMISSION_TYPE } from '../../../../common/Permission';

type propsType = {
  id: number;
};

function ContactsForm({ id }: propsType) {
  const [expanded, setExpanded] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { control, watch, setValue } = useFormContext();
  const [fullName, email, phoneData, isPrimary, contacts] = watch([
    `contacts.${id}.fullName`,
    `contacts.${id}.email`,
    `contacts.${id}.phoneData`,
    `contacts.${id}.isPrimary`,
    `contacts`,
  ]);
  const handleSwitchChange = (): void => {
    contacts.forEach((contact: ContactSummaryDTO, index: number) => {
      setValue(`contacts.${index}.isPrimary`, id === index);
    });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    action: { icon: string; label: string },
    accountData: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (action?.label !== 'Delete') return;
    handleDeleteContact();
    setAnchorEl(null);
  };

  const handleDeleteContact = () => {
    const updatedContacts: ContactSummaryDTO[] = [];
    contacts.forEach((contact: ContactSummaryDTO, index: number) => {
      if (index !== id) {
        updatedContacts.push(contact);
      }
    });
    setValue('contacts', updatedContacts, { shouldDirty: false });
  };
  const noPermissionForEditLocation = !isHasPermission([
    AXELE_PERMISSION_TYPE.LOCATION_EDIT,
  ]);

  const titleRenderer = () => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: (theme) => {
            return expanded
              ? `${theme.palette.primary.contrast}`
              : `${theme.palette.grey['100']}`;
            // @ISSUE: Vasil
            // Since on 'uncategorized' it throws error grey is used instead
            // `${theme.palette.uncategorized.accordion}`;
          },
        }}
      >
        <div>
          <Typography
            // @ISSUE: Vasil
            variant="h7"
            sx={{
              color: expanded ? 'primary.main' : 'text.primary',
            }}
          >
            {fullName}
          </Typography>
          {!expanded && (
            <div>
              <Typography
                // @ISSUE: Vasil
                variant="body3"
                sx={{ color: 'text.secondary' }}
              >
                {`${formatPhoneNumber(phoneData)} ${
                  phoneData?.phone && email && '|'
                } ${email}`}
              </Typography>
            </div>
          )}
        </div>
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '-16px',
          }}
        >
          <Switch
            label="Primary"
            name={`contacts.${id}.isPrimary`}
            disabled={isPrimary || noPermissionForEditLocation}
            control={control}
            onChange={handleSwitchChange}
            labelColor="primary.main"
            style={{ marginBottom: 0, marginRight: '-15px' }}
          />
          {!isPrimary && !noPermissionForEditLocation && (
            <ThreeDotMenuIcon
              menuOptions={[{ id, ...contactDeleteAction }]}
              open={Boolean(anchorEl)}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              anchorEl={anchorEl}
              handleMenuItemClick={handleMenuItemClick}
            />
          )}
        </div>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        border: (theme) => {
          return expanded ? `1px solid ${theme.palette.primary.main}` : '';
        },
        borderRadius: '8px',
        padding: '5px 5px',
        marginTop: '20px',
        backgroundColor: (theme) => {
          return expanded
            ? `${theme.palette.primary.contrast}`
            : `${theme.palette.grey['100']}`;
          // Since on 'uncategorized' it throws error grey is used instead
          // `${theme.palette.uncategorized.accordion}`;
        },
        width: '100%',
      }}
    >
      <Accordion
        name={fullName}
        summaryRenderer={titleRenderer}
        updateParentState={setExpanded}
      >
        <TextField
          disabled={noPermissionForEditLocation}
          control={control}
          name={`contacts.${id}.fullName`}
          label="Name"
          sizes={{ xs: 6 }}
          required
        />
        <TextField
          disabled={noPermissionForEditLocation}
          control={control}
          name={`contacts.${id}.email`}
          label="Email"
          sizes={{ xs: 6 }}
        />
        <FormPhoneField
          disabled={noPermissionForEditLocation}
          control={control}
          name={`contacts.${id}.phoneData`}
          label="Phone #"
          sizes={{ xs: 12 }}
        />
        <TextField
          disabled={noPermissionForEditLocation}
          control={control}
          name={`contacts.${id}.description`}
          label="Description"
          sizes={{ xs: 12 }}
        />
      </Accordion>
    </Box>
  );
}

export default ContactsForm;
