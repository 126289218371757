import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { FunctionComponent } from 'react';
import { renderLabel } from '../Utils';

export const SimpleTextField: FunctionComponent<Props> = ({
  label,
  type,
  isPassword,
  helperText,
  size,
  variant,
  name,
  onChangeHandler,
  value,
}) => {
  const [values, setValues] = React.useState({ showPassword: false });
  const handleClickShowPassword = () => {
    setValues({
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (e: any) => {
    e.preventDefault();
  };
  const opts: any = {};
  switch (type) {
    case 'basic':
      opts['basic'] = 'basic';
      break;
    case 'focused':
      opts['focused'] = 'focused';
      break;
    case 'error':
      opts['error'] = 'error';
      break;
    case 'disabled':
      opts['disabled'] = 'disabled';
      break;
    case 'multiline':
      opts['multiline'] = 'multiline';
      break;
    default:
      opts['basic'] = 'basic';
      break;
  }
  const TextfieldStyles = {
    width: '100%',
    //TODO refactoring moved to src/ui-kit/theme/components/MuiFormLabel.ts
    // '.MuiTextField-root': {
    //   marginTop: '10px',
    //   '&:first-of-type': {
    //     mt: '0',
    //   },
    // },
    // '.Mui-error': {
    //   color: 'var(--error__main)',
    // },
    // '.Mui-focused': {
    //   color: 'var(--primary__main)',
    // },
  };
  return (
    <>
      {isPassword ? (
        <TextField
          id="standard-basic"
          label={renderLabel(label)}
          variant={variant}
          helperText={helperText}
          name={name}
          type={values.showPassword ? 'text' : 'password'}
          size={size}
          {...opts}
          onChange={onChangeHandler}
          sx={TextfieldStyles}
          defaultValue={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  style={{ cursor: 'pointer' }}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </div>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          id="standard-basic"
          label={renderLabel(label)}
          variant={variant}
          helperText={helperText}
          size={size}
          name={name}
          {...opts}
          onChange={onChangeHandler}
          sx={TextfieldStyles}
          defaultValue={value}
        />
      )}
    </>
  );
};

export interface Props {
  label: string;
  type: string;
  isPassword: boolean;
  helperText: string;
  size: string;
  variant: string;
  name: string;
  onChangeHandler: Function;
  value?: any;
}
