import { Stack } from '@mui/material';
import { observer } from 'mobx-react';

import { CustomizeFilters, CustomizeFiltersProps } from './CustomizeFilters';

export type LoadboardDataGridFiltersProps = CustomizeFiltersProps;

export const LoadboardDataGridFilters = observer(
  ({
    filterColumns,
    defaultFilterValue,
    onPageFilterChange,
    onAllFilterSubmit,
    onAllFilterReset,
    tableConfigurationProps,
    onSearchHandler,
    onRefreshHandler,
  }: LoadboardDataGridFiltersProps) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        pb={2}
        width={'100%'}
      >
        <CustomizeFilters
          filterColumns={filterColumns}
          defaultFilterValue={defaultFilterValue}
          onPageFilterChange={onPageFilterChange}
          onAllFilterSubmit={onAllFilterSubmit}
          onAllFilterReset={onAllFilterReset}
          tableConfigurationProps={tableConfigurationProps}
          onSearchHandler={onSearchHandler}
          onRefreshHandler={onRefreshHandler}
        />
      </Stack>
    );
  }
);
