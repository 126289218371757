import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export function Checkboxes({
  state,
  appearance,
  size,
  label,
  isIndeterminate,
  buttonGroup,
  groupLabel,
  helperText,
  checked,
  onChange,
}: Props) {
  let currentState: any = {},
    currentAppearance = '',
    disabled = '',
    checkboxColor: any = {};
  if (state === 'checked') {
    currentState['checked'] = 'checked';
    switch (appearance) {
      case 'disabled':
        currentAppearance = 'primary';
        currentState['disabled'] = 'disabled';
        disabled = 'disabled';
        break;
      case 'primary':
        currentAppearance = 'primary';
        checkboxColor['primary'] = 'var(--primary__main)';
        break;
      case 'secondary':
        currentAppearance = 'secondary';
        checkboxColor['secondary'] = 'var(--secondary__main)';
        break;
      case 'error':
        currentAppearance = 'error';
        checkboxColor['error'] = 'var(--error__main)';
        break;

      case 'success':
        currentAppearance = 'success';
        checkboxColor['success'] = 'var(--success__main)';
        break;

      case 'warning':
        currentAppearance = 'warning';
        checkboxColor['warning'] = 'var(--warning__main)';
        break;

      case 'info':
        currentAppearance = 'info';
        currentState['checked'] = 'checked';
        checkboxColor['info'] = 'var(--info__main)';
        break;
    }
  }
  return (
    <>
      {!buttonGroup ? (
        <React.Fragment>
          {state === 'inactive' ? (
            <>
              {appearance === 'disabled' ? (
                <FormControlLabel
                  label={label || 'sample'}
                  disabled
                  control={<Checkbox name={label} size="medium" />}
                />
              ) : (
                <FormControlLabel
                  label={label || 'sample'}
                  control={<Checkbox name={label} size="medium" />}
                />
              )}
            </>
          ) : (
            <>
              <FormControlLabel
                label={label || 'sample'}
                control={
                  <Checkbox
                    {...currentState}
                    name={label}
                    checked={checked}
                    onChange={onChange}
                    color={currentAppearance}
                    {...disabled}
                    size={size}
                    indeterminate={isIndeterminate}
                    sx={{
                      '&.Mui-checked': {
                        color: checkboxColor[currentAppearance],
                      },
                    }}
                  />
                }
              />
            </>
          )}
        </React.Fragment>
      ) : (
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">{groupLabel}</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name={label} size="small" color="primary" />}
              label={label || 'sample'}
            />
            <FormControlLabel
              control={
                <Checkbox name={label} checked size="small" color="primary" />
              }
              label={label || 'sample'}
            />

            <FormControlLabel
              control={<Checkbox checked size="small" color="primary" />}
              label={label || 'sample'}
            />
          </FormGroup>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </>
  );
}

export interface Props {
  state: string;
  appearance: string;
  size?: string;
  label?: string;
  isIndeterminate?: boolean;
  buttonGroup?: boolean;
  groupLabel?: string;
  helperText?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
}

Checkboxes.defaultProps = {
  label: 'Label',
};
