export const Label = {
  GanttChartLoading: 'Loading...',
  GanttChartNorecordstodisplay: 'No records to display',

  PreferenceButtonOptionsStartNewOptymization: 'Start New Optymization',
  PreferenceButtonOptionsConfigureParameters: 'Configure Parameters',

  PreferenceButtonDrivers: 'Drivers',
  PreferenceButtonTrips: 'Trips',

  getSortOptionsName: 'Name',
  getSortOptionsDriverAvailability: "Driver's Availability",

  // NavigatorResetButton: 'Reset',
  GanttColumnHeaderDriver: 'Driver',
  GanttColumnHeaderLocation: 'Location',
  GanttColumnHeaderEquipment: 'Equipment',
  GanttColumnHeaderRevenue: 'Revenue',
  GanttColumnHeaderDriverPay: 'Driver Pay',
  GanttColumnHeaderMiles: 'Miles',
  GanttTotalDrivers: 'Total Drivers',
  GanttWeekOf: 'Week of',

  // Trips
  TripsAddLoad: 'Add Load',
  TitleAddNewLoad: 'Add New Load',
  ButtonAddNewLoad: 'Add New Load',

  ganttLegendCompleted: 'Completed',
  ganttLegendPlanned: 'Planned',
  ganttLegendAssigned: 'Assigned',
  ganttLegendDispatched: 'Dispatched',
  ganttLegendInTransit: 'In Transit',
  ganttLegendDeadhead: 'Deadhead',
  ganttLegendNeedLoad: 'Need Load',

  DispatchTitleDriver: 'Drivers',
  GanttFilterManageFilters: 'Manage Filters', // isMobile option
  GanttFilterAllFilters: 'All Filters',
  GanttFilterFilters: 'Filters', // isMobile option
  GanttFiltersSort: 'Sort',
  GanttFilterPrimaryDispatcher: 'Primary Dispatcher',
  GanttFilterOperationType: 'OperationType',
  GanttFilterOperationTypeLocal: 'Local',
  GanttFilterOperationTypeRegional: 'Regional',
  GanttFilterOperationTypeOTR: 'OTR',
  GanttFilterOperationTypeCasual: 'Casual',
  GanttFilterStartDate: 'Start Date',
  GanttFilterAllDrivers: 'All Drivers',
  GanttFilterAllTrips: 'Trips',
  GanttFilterOriginRadius: 'Origin Radius',
  GanttFilterDestinationRadius: 'Destination Radius',
  GanttFilterOrigin: 'Origin',
  GanttFilterDestination: 'Destination',
  GanttFilterHasViolation: 'Has Violation',
  GanttFilterOperationMode: 'Operation Mode',
  GanttFilterOperationModeTeam: 'Team',
  GanttFilterOperationModeSolo: 'Solo',
  GanttFilterEmploymentType: 'Employment Type',
  GanttFilterEmploymentTypeOwnerOperator: 'Owner Operator',
  GanttFilterEmploymentTypeEmployee: 'Employee',
  GanttFilterDriverDetails: 'Driver Details',
  GanttFilterAvailableWithin48h: 'Available Within 48h',
  GanttFilterCurrentTimeOff: 'Current Time Off',
  GanttFilterLoadDelay: 'Load Delay',
  GanttFilterSearchDrivers: 'Search Drivers',
  GanttFilterApply: 'Apply',
  GanttFilterReset: 'Reset',

  DispatchDetailsPanelActionsSectionRequesttimeoff: 'Request time off',
  EDispatchPanelTabTIMELINETimeline: 'Timeline',
  EDispatchPanelTabPREFERENCESPreferences: 'Preferences',

  GanttTimelineRegenerateTimeline: 'Regenerate Timeline',
  GanttTimelineCurrent: 'Current',
  GanttCardListEmptyDescription:
    'This driver is not currently taking any loads',
  GanttTimelineReorder: 'Reorder',
  GanttCardListEmptyDescription1: 'This driver does not have any future loads',
  GanttTimelineFuture: 'Future',
  GanttTimelineReorderFutureTrips: 'Reorder Future Trips',
  GanttCardListCancel: 'Cancel',
  GanttCardListSave: 'Save',

  DriverGroupAssignmentTractor: 'Tractor',
  DriverGroupAssignmentTrailer: 'Trailer',
  DriverGroupAssignmentAssignTractor: 'Assign Tractor',
  DriverGroupAssignmentAssignTrailer: 'Assign Trailer',
  DriverGroupAssignmentbuttonUnassign: 'Disassociate',

  DriverGroupAssignmentDeletepopUp: 'Are you sure you want to remove this?', //PopUPtext

  GanttCardGapDriveTime: 'Drive Time:',
  GanttCardGapTimeAvailable: 'Time Available:',
  GanttCardGapFindLoad: 'Find Load',
  GanttCardGapDutyTime: 'Duty Time:',

  GanttCardTripTripID: 'Trip ID',
  GanttCardTripDriveTime: 'Drive Time',
  GanttCardTripTripMiles: 'Trip Miles',
  GanttCardTripEmptyMiles: 'Empty Miles',
  GanttCardTripAssociatedLoads: 'Associated Loads',
  GanttCardTripDelay: 'Delay',
  GanttCardTripStop: 'stop(s)',

  LoadStatusesConstantsDispatched: 'Dispatched',

  LoadStatusesConstantsPlanned: 'Planned',
  LoadStatusesConstantsAssigned: 'Assigned',
  LoadStatusesConstantsInTransit: 'In Transit',
  LoadStatusesConstantsCompleted: 'Completed',
  LoadStatusesConstantsCancelled: 'Cancelled',
  LoadStatusesConstantsInvoiced: 'Invoiced',
  LoadStatusesConstantsPaid: 'Paid',
  LoadStatusesConstantsNeedLoad: 'Need Load',
  LoadStatusesConstantsPartialPaid: 'Partially Paid',
  LoadStatusesConstantsReadytoInvoice: 'Ready to Invoice',

  ActionSectionDeleteTrip: 'Are you sure you want to permanently delete this?', //Delete Trip popup
  ActionSectionDeleteTripCancel: 'Cancel',
  ActionSectionDeleteTripDelete: 'Delete',
  ActionSectionDeleteTripDuplicate: 'Duplicate',
  ActionSectionDeleteTripDeleteLoad: 'Delete',
  ActionSectionDeleteTripReverttoAvailable: 'Revert to Available',
  ActionSectionDeleteTripReverttoPlan: 'Revert to Plan',
  ActionSectionDeleteTripUnDispatch: 'Reverse Dispatch Action',
  ActionSectionDeleteTripMarkasComplete: 'Mark as Complete',
  ActionSectionDeleteTripReverttoCompleted: 'Revert to Completed',
  ActionSectionDeleteTripMarkasCanceled: 'Mark as Cancelled',
  ActionSectionDeleteTripReverttoCanceled: 'Revert to Cancelled',
  ActionSectionDeleteTripMarkasInvoiced: 'Mark as Invoiced',
  ActionSectionDeleteTripReverttoInvoiced: 'Revert to Invoiced',
  ActionSectionDeleteTripMarkasPaid: 'Mark as Paid',
  ActionSectionDeleteTripUpdateProgress: 'Update Progress',
  MissingApptDatesTitle: 'Missing Appointment Dates',
  MissingApptDatesBody:
    'The action could not be completed due to missing appointment dates.',
  MissingApptDatesSubtitle:
    'Please update the appointment dates to complete the action.',
};
