import { useState } from 'react';
import { IWithSecondaryDetailsPanelData } from '.';
export const useSecondaryDetailsPanel = () => {
  const [secondaryDetailsPanelData, setSecondaryDetailsPanelData] =
    useState<IWithSecondaryDetailsPanelData | null>(null);

  return {
    secondaryDetailsPanelData,
    setSecondaryDetailsPanelData,
  };
};
