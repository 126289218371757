import { GridSortItem, GridSortModel } from '@mui/x-data-grid-pro';

export const getSortOption = (
  model: GridSortModel,
  defaultSortName: string,
  fieldName?: any
) => {
  let sortOptions = defaultSortName;
  if (model.length) {
    const { field, sort }: GridSortItem = model[0];
    if (fieldName && field in fieldName) {
      sortOptions = `${sort === 'asc' ? '+' : '-'}${fieldName[field]}`;
    } else {
      sortOptions = `${sort === 'asc' ? '+' : '-'}${field}`;
    }
  }
  return sortOptions;
};
