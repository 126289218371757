export const stopFormRequiredFields = {
  addressDTO: true,
  appointmentStartDate: true,
  appointmentEndDate: true,
};

export const stopDetailsFormRequiredFields = {
  avgHandlingTime: true,
};

export const equipmentFormRequiredFields = {
  equipmentTypes: true,
  loadType: true,
};

export const commodityFormRequiredFields = {
  qty: true,
  commodity: true,
};

const defaultStopContactData = {
  fullName: '',
  email: '',
  phoneData: null,
};

export const defaultStopData = {
  id: 0,
  activityType: null,
  refNumber: null,
  contact: { ...defaultStopContactData },
  appointmentStartDate: null,
  appointmentEndDate: null,
  fixedAppointment: true,
  avgHandlingTime: 120,
  isDriverAssistRequired: false,
  location: null,
  addressDTO: {},
  stopStatus: 'NONE',
  notes: '',
  noteDetails: null,
};

export const stopActivityTypeOptions = [
  { value: 'Pickup', key: 'PICKUP' },
  { value: 'Drop Off', key: 'DROPOFF' },
  { value: 'Pickup and Drop Off', key: 'PICKUP_AND_DROPOFF' },
];

export const defaultCommodityData = {
  commodity: null,
  commodityTypeDisplayValue: '',
  commodityType: '',
  commodityTypeId: '',
  description: '',
  floorLoad: false,
  height: null,
  length: null,
  packageType: null,
  qty: 1,
  stackable: false,
  volume: null,
  weight: null,
  width: null,
};

export type Point = {
  lat: number;
  lng: number;
};
