import { MaintenanceVendor } from '../../../models/DTOs/maintenance/maintenanceVendor/Model';
import { PaginatedMaintenanceVendorListRequest } from '../../../models/DTOs/maintenance/maintenanceVendor/Requests';
import { PaginatedResult } from '../../../types';
import { ServiceError } from '../helperTypes';
import { Service } from '../Service';

export abstract class IMaintenanceVendorService extends Service {
  abstract getPaginatedList(
    requestData: PaginatedMaintenanceVendorListRequest
  ): Promise<PaginatedResult<MaintenanceVendor> | ServiceError>;

  abstract getByOrganizationId(): Promise<MaintenanceVendor[] | ServiceError>;
}
