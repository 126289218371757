export const warningContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  pb: 1,
};

export const warningTextStyle = {
  ml: 2,
  fontFamily: 'Poppins',
  fontSize: '14',
  fontWeight: 500,
  color: 'rgba(0, 17, 34, 0.75)',
};

export const MapsToolTipContainerStyle = {
  width: '343px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 2,
  background:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #2196F3',
};

export const MapsToolTipHeaderStyle = {
  p: '16px 0 16px 0',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
};

export const headerImageStyle = {
  width: '20px',
  hieght: '59px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const HeaderInfoTitleStyle = {
  fontFamily: 'Poppins',
  fontSize: 16,
  fontWeight: 600,
};

export const HeaderInfoSubTitleStyle = {
  color: '#64B6F7',
  fontFamily: 'Poppins',
  fontSize: 14,
  fontWeight: 500,
};

export const MapsToolTipsContentStyle = {
  p: 2,
  width: '100%',
  background: '#FFFFFF',
};

export const MapsToolTipsContentSubStyle = {
  pb: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const ToolTipContentKeyStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: 16,
  fontWeight: 500,
  color: 'black',
  pr: 2,
};

export const ToolTipContentValueStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  width: '50%',
};

export const ToolTipContentValueTextStyle = {
  fontFamily: 'Poppins',
  fontSize: 16,
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'flex-end',
};

export const ToolTipContentSubValueTextStyle = {
  fontFamily: 'Poppins',
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(0, 17, 34, 0.75)',
  display: 'flex',
  justifyContent: 'flex-end',
};

export const warningPaneStyle = {
  width: '343px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0 0 8px 8px',
  background:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 17.07%), #FFE9E0;',
  p: '16px 16px 0 16px',
};
