import { Divider, Grid, Typography, useTheme } from '@mui/material';
import { NumberToCurrency } from '../../../../../constants/numberToCurrency';
import { LoadValuesDisplay } from '../../LoadValuesDisplay';
import { IProfitablityValue } from '../types';

interface Props {
  profitablityValues: IProfitablityValue[];
  profitabilityBreakdownData: {
    variableCost: number;
    fixedCost: number;
    offerPrice: string;
    profit: string;
    isEstimatedOfferValue: boolean;
  };
}
export const ProfitabilityBreakDown = ({
  profitablityValues,
  profitabilityBreakdownData,
}: Props) => {
  const theme: any = useTheme();

  const costToCurrency = (cost: number): string => {
    if (cost > 0) {
      return `$${cost}`;
    } else {
      return `-$${Math.abs(cost)}`;
    }
  };

  return (
    <Grid item xs={12}>
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
          pb: '17px',
        }}
      >
        <Grid item>
          <Typography
            variant={'h6'}
            sx={{
              color: 'text.primary',
            }}
          >
            {profitabilityBreakdownData.isEstimatedOfferValue
              ? 'Estimated Bid Amount'
              : 'Offer'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant={'h6'}
            sx={{
              color: 'text.primary',
            }}
          >
            {NumberToCurrency(Number(profitabilityBreakdownData.offerPrice))}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          pb: '17px',
        }}
      >
        {profitablityValues.map((data, index) => {
          return (
            <LoadValuesDisplay
              title={data.title}
              subtitle={data.subtitle}
              cost={data.cost}
              key={index}
            />
          );
        })}
      </Grid>

      <Divider />
      <Grid container sx={{ justifyContent: 'space-between', p: '15.5px 0' }}>
        <Grid item>
          <Typography
            sx={{
              color: 'primary.main',
              ...theme.typography.kpiValue,
            }}
          >
            Profit :
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              color: `${
                Number(profitabilityBreakdownData.profit) < 0
                  ? 'error.main'
                  : 'primary.main'
              }`,
              ...theme.typography.kpiValue,
            }}
          >
            {NumberToCurrency(Number(profitabilityBreakdownData.profit))}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
