import { PageviewOutlined, PictureAsPdfOutlined } from '@mui/icons-material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { invoiceService, loadService } from '../../../../../api';
import { ServiceError } from '../../../../../api/interfaces';
import { getAccountTypeWithRoleCode } from '../../../../../utils';
import { downloadFile, previewFile } from '../../../../../utils/Doc';
import { dispatchSheetModule } from '../../../../PendoClassIDs/constants';
import { IconBtnWrapper } from '../../../../Ui/IconBtnWrapper/IconBtnWrapper';
import { SendEmailDispatchSheetRequest } from '../Models';
import { SendDispatchSheetEmailDialog } from './SendEmailForm';
import { t } from 'i18next';

const styles: { [key: string]: SxProps } = {
  Accordion: {
    backgroundColor: 'uncategorized.oldHeaderSecondary',
    borderRadius: '8px !important',
    mb: 1,
    boxShadow: 'none',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    '&.MuiAccordion-root': {
      '&:before': {
        height: 0,
      },
      '&.Mui-expanded': {
        mb: 1,
      },
    },
  },
  AccordionSummary: {
    pl: 1,
    pr: 1,
    minHeight: '34px',
    height: '34px',
  },
  AccordionDetails: {
    pt: 0,
    mt: -1,
  },
  IconBtnWrapper: {
    ml: 1,
    width: '100%',
    height: 30,
    border: 'none',
    display: 'flex',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  iconSvg: {
    width: '18px',
    mr: 1,
  },
};

export default function DispatchSheet({
  label = t('loadsRoutesDispatchSheet'),
  loadId,
  carrierId,
  terminal,
  fileName = 'Dispatch_Sheet',
  seqNumber,
  downloadURL,
  isDisabledAllAction = false,
}: {
  label?: string;
  loadId: string;
  carrierId?: string;
  terminal: string;
  fileName?: string;
  seqNumber: string;
  downloadURL?: string;
  isDisabledAllAction?: boolean;
}) {
  const [openEmailDialog, setOpenEmailDialog] = useState<boolean>(false);
  const toggleEmailModal = () => {
    setOpenEmailDialog(!openEmailDialog);
  };
  const handleSendEmailClick = async (data) => {
    const requestData = new SendEmailDispatchSheetRequest();
    requestData.combinePdfDocuments = false;
    requestData.html = true;
    requestData.emailSendingRequestDTO = [
      {
        ...(carrierId
          ? {
              carrierRCRequestDTO: {
                tripId: loadId,
                organizationId: requestData.organizationId,
              },
            }
          : {
              dispatchSheetRequest: {
                confirmationSheet: fileName !== 'Dispatch_Sheet',
                loadId,
                organizationId: requestData.organizationId,
              },
            }),
        cc: data.cc,
        message: data.message,
        subject: data.subject,
        to: data.to,
      },
    ];

    await invoiceService.sendEmail(requestData);
    toggleEmailModal();
  };

  const handleDownloadClick = async () => {
    let result = null;
    if (carrierId) {
      result = await loadService.downloadCarrierRateConfirmationSheet(
        loadId,
        carrierId
      );
    } else {
      result = await loadService.downloadDispatchSheet(loadId, downloadURL);
    }
    if (result instanceof ServiceError) {
      return;
    }
    downloadFile(result);
  };

  const handlePreviewClick = async () => {
    let result = null;
    if (carrierId) {
      result = await loadService.downloadCarrierRateConfirmationSheet(
        loadId,
        carrierId
      );
    } else {
      result = await loadService.downloadDispatchSheet(loadId, downloadURL);
    }
    if (result instanceof ServiceError) {
      return;
    }
    previewFile(result);
  };

  const noPermissionToSendEmail = getAccountTypeWithRoleCode([
    'AC',
    'DR',
    'SP',
  ]);

  return (
    <Accordion sx={styles.Accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={styles.AccordionSummary}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <PictureAsPdfOutlined
            htmlColor="rgba(0, 17, 34, 0.6)"
            sx={{ width: '20px' }}
          />
          <Typography
            variant="subtitle2"
            sx={{ pl: 1, display: 'flex', alignItems: 'center' }}
          >
            {label}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={styles.AccordionDetails}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <IconBtnWrapper
            id={dispatchSheetModule + 'EmailButton'}
            onClick={toggleEmailModal}
            sx={styles.IconBtnWrapper}
            disabled={noPermissionToSendEmail || isDisabledAllAction}
          >
            <EmailOutlinedIcon
              sx={styles.iconSvg}
              color={noPermissionToSendEmail ? 'disabled' : 'primary'}
            />
            <Typography color={'primary'}>{t('DispatchSheetEmail')}</Typography>
          </IconBtnWrapper>
          <IconBtnWrapper
            id={dispatchSheetModule + 'DownloadButton'}
            onClick={handleDownloadClick}
            sx={styles.IconBtnWrapper}
            disabled={isDisabledAllAction}
          >
            <DownloadOutlinedIcon sx={styles.iconSvg} color={'primary'} />
            <Typography color={'primary'}>
              {t('DispatchSheetDownload')}
            </Typography>
          </IconBtnWrapper>
          <IconBtnWrapper
            id={dispatchSheetModule + 'ViewButton'}
            onClick={handlePreviewClick}
            sx={styles.IconBtnWrapper}
            disabled={isDisabledAllAction}
          >
            <PageviewOutlined sx={styles.iconSvg} color={'primary'} />
            <Typography color={'primary'}>
              {t('DispatchSheetPreview')}
            </Typography>
          </IconBtnWrapper>
        </Stack>
      </AccordionDetails>
      {openEmailDialog && (
        <SendDispatchSheetEmailDialog
          subject={label}
          fileName={fileName}
          seqNumber={seqNumber}
          terminal={terminal}
          onAction={handleSendEmailClick}
          onClose={toggleEmailModal}
        />
      )}
    </Accordion>
  );
}
