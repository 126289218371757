import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import Switch from '../../../../common/Ui/Switcher';
import LoadboardGridConfig from '../../LoadboardDataGrid/grid.config';

export function LoadboardColumnsForm() {
  const { control } = useFormContext();
  const gridConfig = new LoadboardGridConfig({});
  const disabledFields: string[] = [];

  const columns = gridConfig.config.columns.map((col) => {
    return {
      name: col.field,
      label: col.headerName,
      show: true,
      disabled: col.permanent,
      defaultChecked: true,
    };
  });

  return (
    <Box sx={{ pt: '20px' }}>
      {columns.map((column) => (
        <Switch
          key={column.name}
          label={column.label}
          name={`columnFilters[${column.name}]`}
          control={control}
          disabled={column.disabled}
          defaultChecked={column.defaultChecked}
        />
      ))}
    </Box>
  );
}
