import { Box, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';
import {
  EDITenderDTO,
  LoadMappingResponse,
} from '../../../models/DTOs/EDI/Requests';
import { numberWithThousandSeparator } from '../../../utils';
import { EEDITenderSource } from '../constants/types';

const RowItem = ({ title, value }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      width={'100%'}
    >
      <Typography variant="body2" color="text.primary">
        {title}
      </Typography>
      <Typography variant="body2Bold" color="text.primary">
        {value}
      </Typography>
    </Stack>
  );
};

export interface EDITenderDetailsProps {
  tenderDTO: EDITenderDTO;
  tripPlanData: LoadMappingResponse | null;
}

export const EDITenderDetails: FC<EDITenderDetailsProps> = observer(
  ({ tenderDTO, tripPlanData }) => {
    const allDetails = [
      {
        heading: 'Customer Info',
        details: [
          { title: 'Name', value: tenderDTO?.customer?.name || '-' },
          {
            title: 'Phone',
            value: tenderDTO?.customer?.phone || '-',
          },
          {
            title: 'Email',
            value: tenderDTO?.customer?.email || '-',
          },
        ],
      },
      {
        heading: 'Other',
        details: [
          {
            title: 'Offer',
            value:
              tenderDTO.source === (EEDITenderSource.turvo as string)
                ? tenderDTO.quotes &&
                  Array.isArray(tenderDTO?.quotes) &&
                  tenderDTO?.quotes?.length > 0 &&
                  tenderDTO?.quotes[0]?.amount
                  ? `$${numberWithThousandSeparator(
                      tenderDTO?.quotes[0]?.amount as number
                    )}`
                  : '-'
                : tenderDTO?.rate !== null
                ? `$${numberWithThousandSeparator(tenderDTO?.rate)}`
                : '-',
          },
          {
            title: 'Weight',
            value: numberWithThousandSeparator(tenderDTO?.weight) || '-',
          },
          {
            title: 'Length',
            value: tenderDTO?.length || '-',
          },
          { title: 'Note', value: tenderDTO?.notes || '-' },
        ],
      },
    ];
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          p: 2,
        }}
      >
        {allDetails.map((section) => {
          return (
            <Stack
              key={section.heading}
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              marginTop={2}
              width={'100%'}
            >
              <Typography variant="h6" color="text.primary" width={'100%'}>
                {section.heading}
              </Typography>
              {section.details.map((node) => {
                return <RowItem key={node.title} {...node} />;
              })}
            </Stack>
          );
        })}
      </Box>
    );
  }
);
