import { DateRange } from '@mui/x-date-pickers-pro-v5';
import moment, { Moment } from 'moment';
import {
  ERelativeDateRangeKey,
  ERelativeDateRangeUnit,
  IRelativeDateRangeOption,
  RelativeDateRangeProps,
} from '../../../ui-kit/components/RelativeDateRange';
import { getGridCompanyTimezoneSettings } from '../../../utils/Timezone.utils';
interface IRelativeDateRangeSettings extends Partial<RelativeDateRangeProps> {
  type:
    | 'MultipleAutocomplete'
    | 'SingleAutocomplete'
    | 'DateRangePicker'
    | 'DatePicker'
    | 'RelativeDateRange';
  options: IRelativeDateRangeOption[];
}

export class RelativeDateRangeUtils {
  static getCurrentPeriodByWorkWeekStartDay = (
    timezone?: string
  ): {
    currentStartWeek: Moment;
    currentEndWeek: Moment;
  } => {
    const { workWeekStartDay } = getGridCompanyTimezoneSettings();
    const zonedMomentDate = timezone ? moment().tz(timezone) : moment();
    let start = zonedMomentDate.day(workWeekStartDay).startOf('day');
    const day = start.day();

    if (day > zonedMomentDate.day()) {
      start = zonedMomentDate.day(-7 + day);
    }
    const end = start.clone().add(7, 'days');
    return { currentStartWeek: start, currentEndWeek: end };
  };

  static getLastWeekDateRanges = (timezone?: string): DateRange<Date> => {
    const { currentStartWeek } =
      RelativeDateRangeUtils.getCurrentPeriodByWorkWeekStartDay(timezone);
    return [
      currentStartWeek.clone().subtract(7, 'days').toDate(),
      currentStartWeek.clone().subtract(1, 'days').endOf('day').toDate(),
    ];
  };

  static getThisWeekDateRanges = (timezone?: string): DateRange<Date> => {
    const { currentStartWeek, currentEndWeek } =
      RelativeDateRangeUtils.getCurrentPeriodByWorkWeekStartDay(timezone);

    return [
      currentStartWeek.startOf('day').toDate(),
      currentEndWeek.endOf('day').toDate(),
    ];
  };

  static getPeriodTimeByRelativeKey = (
    relativeKey: ERelativeDateRangeKey | string,
    timezone?: string
  ): IRelativeDateRangeOption => {
    const zonedMomentDate = timezone ? moment().tz(timezone) : moment();
    switch (relativeKey) {
      case ERelativeDateRangeKey.PeriodTimeLastYear:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeLastYear,
          name: 'Last year',
          dateRange: [
            zonedMomentDate
              ?.clone()
              ?.subtract(1, 'year')
              ?.startOf('year')
              ?.toDate(),
            zonedMomentDate
              ?.clone()
              ?.subtract(1, 'year')
              ?.endOf('year')
              ?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeLastQuarter:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeLastQuarter,
          name: 'Last Quarter',
          dateRange: [
            zonedMomentDate
              ?.clone()
              ?.startOf('quarter')
              ?.subtract(1, 'quarter')
              ?.startOf('quarter')
              ?.toDate(),
            zonedMomentDate
              ?.clone()
              ?.startOf('quarter')
              ?.subtract(1, 'day')
              ?.endOf('day')
              ?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeLast6Months:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeLast6Months,
          name: 'Last 6 Months',
          dateRange: [
            zonedMomentDate
              ?.clone()
              ?.startOf('month')
              ?.subtract(6, 'month')
              ?.toDate(),
            zonedMomentDate
              ?.clone()
              ?.startOf('month')
              ?.subtract(1, 'month')
              ?.endOf('month')
              ?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeLast3Months:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeLast3Months,
          name: 'Last 3 Months',
          dateRange: [
            zonedMomentDate
              ?.clone()
              ?.startOf('month')
              ?.subtract(3, 'month')
              ?.toDate(),
            zonedMomentDate
              ?.clone()
              ?.startOf('month')
              ?.subtract(1, 'month')
              ?.endOf('month')
              ?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeLast3MonthsOnward:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeLast3MonthsOnward,
          name: 'Last 3 Months Onward',
          dateRange: [
            zonedMomentDate
              ?.clone()
              ?.startOf('day')
              ?.subtract(3, 'month')
              ?.toDate(),
            null,
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeLastMonth:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeLastMonth,
          name: 'Last Month',
          dateRange: [
            zonedMomentDate
              ?.clone()
              ?.startOf('month')
              ?.subtract(1, 'month')
              ?.toDate(),
            zonedMomentDate
              ?.clone()
              ?.startOf('month')
              ?.subtract(1, 'day')
              ?.endOf('day')
              ?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeLastWeek:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeLastWeek,
          name: 'Last Week',
          dateRange: RelativeDateRangeUtils.getLastWeekDateRanges(timezone),
        };
      case ERelativeDateRangeKey.PeriodTimeYesterday:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeYesterday,
          name: 'Yesterday',
          dateRange: [
            zonedMomentDate
              ?.clone()
              ?.startOf('day')
              ?.subtract(1, 'day')
              ?.toDate(),
            zonedMomentDate
              ?.clone()
              ?.startOf('day')
              ?.subtract(1, 'day')
              ?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeNext7Days:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeNext7Days,
          name: 'Next 7 days',
          dateRange: [
            zonedMomentDate?.clone()?.startOf('day')?.toDate(),
            zonedMomentDate?.clone()?.add(6, 'day')?.endOf('day')?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeNext30Days:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeNext30Days,
          name: 'Next 30 days',
          dateRange: [
            zonedMomentDate?.clone()?.startOf('day')?.toDate(),
            zonedMomentDate?.clone()?.add(29, 'day')?.endOf('day')?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeThisYear:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeThisYear,
          name: 'This year',
          dateRange: [
            zonedMomentDate?.clone()?.startOf('year')?.toDate(),
            zonedMomentDate?.clone()?.endOf('year')?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeThisQuarter:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeThisQuarter,
          name: 'This Quarter',
          dateRange: [
            zonedMomentDate?.clone()?.startOf('quarter')?.toDate(),
            zonedMomentDate?.clone()?.endOf('quarter')?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeToday:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeToday,
          name: 'Today',
          dateRange: [
            zonedMomentDate?.clone()?.startOf('day')?.toDate(),
            zonedMomentDate?.clone()?.endOf('day')?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeThisWeek:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeThisWeek,
          name: 'This Week',
          dateRange: RelativeDateRangeUtils.getThisWeekDateRanges(timezone),
        };
      case ERelativeDateRangeKey.PeriodTimeTomorrow:
        return {
          type: ERelativeDateRangeUnit.UNIT,
          key: ERelativeDateRangeKey.PeriodTimeTomorrow,
          name: 'Tomorrow',
          dateRange: [
            zonedMomentDate?.clone()?.startOf('day')?.add(1, 'day')?.toDate(),
            zonedMomentDate
              ?.clone()
              ?.startOf('day')
              ?.add(1, 'day')
              ?.endOf('day')
              ?.toDate(),
          ],
        };
      case ERelativeDateRangeKey.PeriodTimeCustom:
        return {
          type: ERelativeDateRangeUnit.CUSTOMIZE,
          key: ERelativeDateRangeKey.PeriodTimeCustom,
          name: 'Custom',
          dateRange: [null, null],
        };

      default:
        throw Error('relativeKey is invalid');
    }
  };

  static getPeriodTimeByRelativeKeys = (
    relativeKeys: Array<ERelativeDateRangeKey | string>,
    timezone?: string
  ): IRelativeDateRangeOption[] => {
    return relativeKeys.map((relativeKey) => {
      return RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
        relativeKey,
        timezone
      );
    });
  };

  static getSettingsDispatch = (): IRelativeDateRangeSettings => {
    const timezone = getGridCompanyTimezoneSettings().timezone;
    return {
      type: 'RelativeDateRange',
      width: '200px',
      isSingleDate: true,
      endDateShow: false,
      defaultOption: RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
        ERelativeDateRangeKey.PeriodTimeYesterday,
        timezone
      ),
      options: RelativeDateRangeUtils.getPeriodTimeByRelativeKeys(
        [
          ERelativeDateRangeKey.PeriodTimeToday,
          ERelativeDateRangeKey.PeriodTimeYesterday,
        ],
        timezone
      ),
      ...getGridCompanyTimezoneSettings(),
    };
  };

  static getGenericDateRangeFilter = (): IRelativeDateRangeSettings => {
    const timezone = getGridCompanyTimezoneSettings().timezone;
    return {
      type: 'RelativeDateRange',
      width: '200px',
      options: RelativeDateRangeUtils.getPeriodTimeByRelativeKeys(
        [
          ERelativeDateRangeKey.PeriodTimeToday,
          ERelativeDateRangeKey.PeriodTimeYesterday,
          ERelativeDateRangeKey.PeriodTimeTomorrow,
          ERelativeDateRangeKey.PeriodTimeThisWeek,
          ERelativeDateRangeKey.PeriodTimeLastWeek,
          ERelativeDateRangeKey.PeriodTimeLastMonth,
          ERelativeDateRangeKey.PeriodTimeThisQuarter,
          ERelativeDateRangeKey.PeriodTimeLast3Months,
          ERelativeDateRangeKey.PeriodTimeLast3MonthsOnward,
          ERelativeDateRangeKey.PeriodTimeLastQuarter,
          ERelativeDateRangeKey.PeriodTimeLast6Months,
          ERelativeDateRangeKey.PeriodTimeThisYear,
          ERelativeDateRangeKey.PeriodTimeLastYear,
          ERelativeDateRangeKey.PeriodTimeNext7Days,
          ERelativeDateRangeKey.PeriodTimeNext30Days,
        ],
        timezone
      ),
      ...getGridCompanyTimezoneSettings(),
    };
  };

  static getSettingsMaintenance = (): IRelativeDateRangeSettings => {
    return {
      type: 'RelativeDateRange',
      width: '200px',
      options: [],
      ...getGridCompanyTimezoneSettings(),
      timezone: undefined,
    };
  };

  static getSettingsAlerts = (): IRelativeDateRangeSettings => {
    return {
      type: 'RelativeDateRange',
      width: '200px',
      options: [],
      ...getGridCompanyTimezoneSettings(),
      timezone: undefined,
    };
  };

  static getSettingsAccoutSummary = (): IRelativeDateRangeSettings => {
    const timezone = undefined;
    return {
      type: 'RelativeDateRange',
      isSingleDate: false,
      startDateRequire: true,
      endDateRequire: true,
      options: RelativeDateRangeUtils.getPeriodTimeByRelativeKeys(
        [
          ERelativeDateRangeKey.PeriodTimeToday,
          ERelativeDateRangeKey.PeriodTimeYesterday,
          ERelativeDateRangeKey.PeriodTimeTomorrow,
          ERelativeDateRangeKey.PeriodTimeThisWeek,
          ERelativeDateRangeKey.PeriodTimeLastWeek,
          ERelativeDateRangeKey.PeriodTimeLastMonth,
          ERelativeDateRangeKey.PeriodTimeThisQuarter,
          ERelativeDateRangeKey.PeriodTimeLast3Months,
          ERelativeDateRangeKey.PeriodTimeLastQuarter,
          ERelativeDateRangeKey.PeriodTimeLast6Months,
          ERelativeDateRangeKey.PeriodTimeThisYear,
          ERelativeDateRangeKey.PeriodTimeLastYear,
        ],
        timezone
      ),
      defaultOption: RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
        ERelativeDateRangeKey.PeriodTimeThisYear,
        timezone
      ),
      ...getGridCompanyTimezoneSettings(),
      timezone,
    };
  };

  static getSettingsBasicReport = (): IRelativeDateRangeSettings => {
    const timezone = getGridCompanyTimezoneSettings().timezone;
    return {
      type: 'RelativeDateRange',
      width: '270px',
      isSingleDate: false,
      options: RelativeDateRangeUtils.getPeriodTimeByRelativeKeys(
        [
          ERelativeDateRangeKey.PeriodTimeToday,
          ERelativeDateRangeKey.PeriodTimeTomorrow,
          ERelativeDateRangeKey.PeriodTimeLastWeek,
          ERelativeDateRangeKey.PeriodTimeThisWeek,
          ERelativeDateRangeKey.PeriodTimeLastMonth,
          ERelativeDateRangeKey.PeriodTimeThisQuarter,
          ERelativeDateRangeKey.PeriodTimeLast3Months,
          ERelativeDateRangeKey.PeriodTimeLastQuarter,
          ERelativeDateRangeKey.PeriodTimeLast6Months,
          ERelativeDateRangeKey.PeriodTimeLastYear,
          ERelativeDateRangeKey.PeriodTimeThisYear,
        ],
        timezone
      ),
      defaultOption: RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
        ERelativeDateRangeKey.PeriodTimeThisWeek,
        timezone
      ),
      ...getGridCompanyTimezoneSettings(),
    };
  };

  static getSettingsLoadboards = (): IRelativeDateRangeSettings => {
    const timezone = undefined;
    return {
      type: 'RelativeDateRange',
      isSingleDate: false,
      options: RelativeDateRangeUtils.getPeriodTimeByRelativeKeys(
        [
          ERelativeDateRangeKey.PeriodTimeToday,
          ERelativeDateRangeKey.PeriodTimeTomorrow,
        ],
        timezone
      ),
      defaultOption: RelativeDateRangeUtils.getPeriodTimeByRelativeKey(
        ERelativeDateRangeKey.PeriodTimeToday
      ),
      ...getGridCompanyTimezoneSettings(),
      timezone: undefined,
      clearable: true,
    };
  };
}
