import React from 'react';
import Drawer from '@mui/material/Drawer';

import { SideDrawerProps } from './interface';

const SideDrawer = ({
  isOpen = true,
  anchor = 'right',
  handleClose,
  backdropColor,
  drawerContent,
  drawerRadius,
  paperPadding,
  top,
  bottom,
  height,
}: SideDrawerProps) => {
  return (
    <Drawer
      open={isOpen}
      anchor={anchor}
      onClose={handleClose}
      ModalProps={{
        BackdropProps: {
          sx: { backgroundColor: backdropColor, borderRadius: drawerRadius },
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: drawerRadius,
          padding: paperPadding,
          top: top,
          bottom: bottom,
          height: height,
        },
      }}
    >
      {drawerContent}
    </Drawer>
  );
};

export default SideDrawer;
