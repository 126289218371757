export const FailureNotificationConstants = {
  homeService: 'Application Failed Please retry',
  generalFailureMsg: 'Operation failed please try again',
  unauthorized: 'Unauthorized Request Please login',
  tryAgain: 'Sorry, Please try again',
  updateQBAuth: 'Authentication Failed. Please Try again',
  connectToQBO: 'Session should be connected',
  suppressInvoices: 'Failed to suppress invoice(s)',
  releaseInvoices: 'Failed to release invoice(s)',
  postCompanyLogo: 'Sorry Please upload logo again',
  providersList: 'Failed to load the providers',
  datCreation: 'Failed to send email request for credentials',
  activationLogin: 'Sorry, failed to integrate, Please try again',
  editIntegration: 'Sorry, failed to edit integration, Please try again',
  deactivate: 'Sorry, failed to deactivate, Please try again',
  prefilledData: 'Sorry, failed to get prefilled data',
  getUserDetails: 'Sorry, Failed to get user details',
  failedData: 'Failed to fetch data',
  getSubscription: 'Failed to fetch subscription data',
  download: 'Failed to download data',
  templateEdit: 'Failed to update template',
  templateDelete: 'Failed to delete the load template',
  addMaintenanceHistory: 'Add Record Failed, Please try again',
  updateMaintenanceHistory: 'Update Record Failed, Please try again',
  deleteMaintenanceHistory: 'Delete Record(s) Failed, Please try again',
  duplicateCompany:
    'Similar company is already registered. Please check data and try again, or contact LoadOps Support․',
  registration: 'Failed to register company',
  login:
    'Login credentials provided are not valid. Please check the credentials and try again.',
  forgotPassword:
    'There is no active user found with provided credentials. Please check data and try again.',
  reset: 'Your password reset link has expired.',
  cancelSubscription:
    'Failed to cancel subscription. Please contact support team to raise a query.',
  token: 'Something went wrong',
  deletePayment: 'Delete payment method failed',
  settingAlertDelete:
    'We cannot delete an Alert from the Alert page until all the specific alerts set for an entity are removed.',
  deleteTerminal:
    'Terminal cannot be deleted. It has associations with assets, loads, load templates, expenses, and/or users.',
  templateCreation: 'Error creating template, please try again',
  loadCreation: 'Error creating load, please try again',
  rateConDocumentUpload: 'Error uploading ratecon document',
  tractorLink: 'Could not link tractor(s)',
  driverLink: 'Could not link driver(s)',
  axeleTractors: 'Failed to load LoadOps tractors',
  axeleDrivers: 'Failed to load LoadOps drivers',
  eldTractors: 'Failed to load tractors',
  eldDrivers: 'Failed to load drivers',
  tractorUnLink: 'Failed to unlink tractors(s)',
  driverUnLink: 'Failed to unlink driver(s)',
  tractorCreation: 'Failed to create tractor',
  tractorUpdate: 'Failed to update tractor',
  tractorDelete: 'Failed to delete tractor',
  tractorAlertCreate: 'Failed to create tractor alert',
  tractorAlertUpdate: 'Failed to update tractor alert',
  tractorAlertDelete: 'Failed to delete tractor alert',
  tractorDriverAssign: 'Failed to assign tractor to driver',
  tractorDriverUnAssign: 'Failed to unassign tractor to driver',
  tractorStatusChange: 'Failed to change tractor status',
  trailerCreation: 'Failed to create trailer',
  trailerUpdate: 'Failed to update trailer',
  trailerDelete: 'Failed to delete trailer',
  trailerAlertCreate: 'Failed to create trailer alert',
  trailerAlertUpdate: 'Failed to update trailer alert',
  trailerAlertDelete: 'Failed to delete trailer alert',
  trailerDriverAssign: 'Failed to assign trailer to driver',
  trailerDriverUnAssign: 'Failed to unassign trailer to driver',
  trailerStatusChange: 'Failed to change trailer status',
  multipleTractorCreation: 'Failed to create tractors',
  driverCreation: 'Failed to create driver',
  multipleDriverCreation: 'Failed to create drivers',
  activateProvider: 'Failed to activate',
  accountDelete: 'Failed to delete the account',
  dataRefresh: 'Failed to refresh the data',
  update: 'Failed to update',
  validation: 'Failed to validate',
  deleteNotesData: 'Failed to Delete Note, Please try again',
  createNotesData: 'Failed to Create Note, Please try again',
  editNotesData: 'Failed to Update Note, Please try again',
  createTrackerData: 'Failed to Create Record, Please try again',
  deleteTrackerData: 'Failed to Delete Record, Please try again',
  updateTrackerData: 'Failed to Update Record, Please try again',
  deleteTerminalLogo: 'Failed to Delete logo, Please try again',
  editLoadAlert: 'Failed to Update Alert, Please try again',
  createLoadAlert: 'Failed to Create Alert, Please try again',
  customerListRefresh: 'Failed to refresh customer refresh',
  updateMapCustomer: 'Failed to update list',
  invalidQBIntegration:
    'The integration with QuickBooks is not valid. Please contact your company admin.',
  inactiveQBIntegration:
    'The integration with QuickBooks is not active. Please contact your company admin.',
  unmappedQBIntegration:
    'Item categories are not mapped properly. Please map the item categories first before viewing the invoice tab.',
  driverTractorAssign: 'Failed to make tractor assignment, Please try again',
  driverTrailerAssign: 'Failed to make trailer assignment, Please try again',
  driverTractorUnAssign:
    'Failed to make tractor unassignment, Please try again',
  driverTrailerUnAssign:
    'Failed to make trailer unassignment, Please try again',
  archived: 'Sorry, Failed to archive, Please try again',
  unArchived: 'Sorry, Failed to restore, Please try again',
  updatePreferenceDriver: 'Failed to update preference, Please try again',
  duplicateTerminal: 'The Terminal Name is already used',
  failedGetTimelineTripDispatch:
    'Failed to get timeline trip data, Please try again',
  failedGetTimelineMapDispatch:
    'Failed to get timeline map data, Please try again',
  SavedFactoring: 'Sorry, Failed to save data',
  updateManualHosLocation: 'Failed to update Location/HoS, Please try again',
  customersNonSynced: 'Some of the customers were not synced',
  diabledTerminalValidation:
    'You cannot disable the terminal model until you delete all Secondary Terminals',
  linkDriver: 'Sorry, Failed to link, please try later',
  createContact: 'Failed to create contact',
  customerCreate: 'Failed to create customer',
  customerUpdate: 'Failed to update customer',
  customerDelete: 'Failed to delete customer',
  vendorCreate: 'Failed to create vendor',
  vendorUpdate: 'Failed to update vendor',
  vendorDelete: 'Failed to delete vendor',
  locationCreate: 'Failed to create location',
  locationUpdate: 'Failed to update location',
  locationDelete: 'Failed to delete location',
  userCreate: 'Failed to create user',
  userUpdate: 'Failed to update user',
  userDelete: 'Failed to delete user',
  userAlertCreate: 'Failed to create user alert',
  userAlertUpdate: 'Failed to update user alert',
  userAlertDelete: 'Failed to delete user alert',
  userStatusChange: 'Failed to change user status',
  userInvitationStatus: 'Failed to send user invitation',
  payStatementNonTripCreate: 'Failed to create payment',
  payStatementNonTripUpdate: 'Failed to update payment',
  payStatementNonTripDelete: 'Failed to delete payment',
  payStatementInReviewCreate: 'Failed to create settlement',
  payStatementInReviewClose: 'Failed to close settlement',
  payStatementReopen: 'Failed to reopen settlement.',
  payStatementInReviewDelete: 'Failed to delete settlement',
  payStatementEmailSend: 'Failed to send email',
  expenseCreate: 'Failed to create expense',
  expenseUpdate: 'Failed to update expense',
  expenseDelete: 'Failed to delete expense',
  documentCreate: 'Failed to create document',
  documentDelete: 'Failed to delete document',
  documentShare: 'Failed to share document',
  importFile: 'Failed to import file',
  invoiceUpdate: 'Failed to update invoice',
  invoiceEmailSend: 'Failed to send email',
  invoiceExport: 'Failed to export data',
  invoiceStatusChange: 'Failed to change invoice status',
  invoiceDownload: 'Failed to download invoice',
  tripAssignDriver: 'Failed to assign driver',
  tripUnAssignDriver: 'Failed to unassign driver',
  tripAssignTractor: 'Failed to assign tractor',
  tripUnAssignTractor: 'Failed to unassign tractor',
  tripAssignTrailer: 'Failed to assign trailer',
  tripUnAssignTrailer: 'Failed to unassign trailer',
  tripAssignDispatcher: 'Failed to assign dispatcher',
  tripUnAssignDispatcher: 'Failed to unassign dispatcher',
  tripAssignTerminal: 'Failed to assign new terminal',
  importIssue: 'The file cannot be uploaded at the moment. Please try again',
  regenerateTimeline: 'Failed to regenerate timeline',
  tripResequience: 'Failed to re-sequence the stop',
  updateLoadReferenceDetails: 'Error updating the load',
  updateTripDispatchRevenue: 'Error updating the trip',
  tractorVINMissing:
    'Tractor VIN is missing. Please create the tractor individually.',
  invoiceFactoringAction: 'Something went wrong. Please try later',
};
