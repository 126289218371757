import { cloneDeep } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { EventHoldLoadActionData } from '../../EventEmitter/Events/EventHoldLoadAction';
import { loadService } from '../../api';
import { ServiceError } from '../../api/interfaces';
import { GetUpdatedManifestDataRequest } from '../../models';
import LoadController from '../../subPages/loadsList/LoadController';
import { LoadSummary } from '../../subPages/loadsList/models/LoadSummary';
import * as types from '../../types';
import { E3DotMenuType } from '../../views/myLoads/components/LoadDetailDialog/types';
import { loadView } from '../../views/myLoads/components/ViewSection/constants';
import { TSetData, setData } from '../utils';

const component = 'MyLoads';
const dictionary = 'load';

class MyLoadStore {
  @observable component: string = component;
  @observable dictionary: string = dictionary;
  @observable currentView: types.View = loadView;
  @observable addNewClicked = false;
  @observable loadCount: number | undefined = undefined;
  @observable updatedLoadId: string | undefined = undefined;
  @observable filters: types.ViewMetaData = {};
  @observable pageIsLoading = true;
  @observable isLoading = false;
  @observable tableData: LoadSummary[] = [];
  @observable createLoadDialogOpen = false;
  @observable createLoadRateConFile: File | null = null;
  @observable showRateConPdf = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setAddNewClicked = (newValue: TSetData<boolean>) => {
    this.addNewClicked = setData(newValue, this.addNewClicked);
  };

  @action
  setCreateLoadDialogOpen = (newValue: TSetData<boolean>) => {
    this.createLoadDialogOpen = setData(newValue, this.createLoadDialogOpen);
  };

  @action setShowRateConPdf = (val: boolean) => {
    this.showRateConPdf = val;
  };

  @action setCreateLoadRateConFile = (newValue: TSetData<File | null>) => {
    this.createLoadRateConFile = setData(newValue, this.createLoadRateConFile);
    if (newValue) this.setShowRateConPdf(true);
  };

  @action clearCreateLoadGlobalStates = () => {
    this.createLoadRateConFile = null;
    this.setShowRateConPdf(false);
  };

  @action
  setCurrentView = (newValue: TSetData<types.View>) => {
    this.currentView = setData(newValue, this.currentView);
  };

  @action
  getLoadsCount = async (): Promise<void> => {
    const loadCount = await loadService.getLoadsCount();
    this.loadCount = loadCount;
  };

  @action setLoadCount = (newValue: TSetData<number | undefined>): void => {
    this.loadCount = setData(newValue, this.loadCount);
  };

  @action setUpdatedLoadId = (newValue: TSetData<string | undefined>): void => {
    this.updatedLoadId = setData(newValue, this.updatedLoadId);
  };

  @action
  setFilters = (newValue: TSetData<types.ViewMetaData>) => {
    this.filters = setData(newValue, this.filters);
  };

  @action
  setPageIsLoading = (pageIsLoading: boolean) => {
    this.pageIsLoading = pageIsLoading;
  };

  @action
  getLoadList = (
    filters: any,
    stripId: string,
    isFirstPage = true,
    callback?: (data: LoadSummary[]) => void
  ) => {
    if (isFirstPage) {
      this.tableData = [];
    }
    this.isLoading = true;
    LoadController.instance().getLoadList(
      filters,
      stripId,
      (...params) => {
        this.isLoading = false;
        this.tableData = params[0];
        this.pageIsLoading = false;
        callback?.(...params);
      },
      isFirstPage,
      () => {
        this.pageIsLoading = false;
        this.isLoading = false;
      }
    );
  };

  @action
  getUpdatedList = async (
    shouldUpdateItemId: string,
    callback?: (data: LoadSummary[]) => void
  ) => {
    const loadsTmp = cloneDeep(this.tableData);
    let indexNumber = -1;
    loadsTmp.filter((item: LoadSummary, index: number) => {
      if (shouldUpdateItemId === item.id) {
        indexNumber = index;
      }
    });
    if (indexNumber < 0) return;
    const loadSummary = await LoadController.instance().getLoadSummaryById(
      shouldUpdateItemId
    );
    loadsTmp[indexNumber] = loadSummary;
    this.tableData = loadsTmp;
    callback?.(loadsTmp);
  };

  @action
  getManifestUpdatedList = async (
    dataUpdated: LoadSummary[],
    callback?: (data: LoadSummary[]) => void
  ) => {
    this.tableData = dataUpdated;
    callback?.(this.tableData);
  };

  @action
  getLoadSummaryById = async (id: string): Promise<LoadSummary> => {
    const load = await LoadController.instance().getLoadLightSummaryById(id);
    await LoadController.instance().setupLoadWarnings([load]);
    return load;
  };

  @action
  deleteLoadId = (loadId: string): LoadSummary[] => {
    this.tableData = this.tableData.filter(({ id }) => id !== loadId);
    return this.tableData;
  };

  @action
  updateLoadsListByNewLoad = async (load: LoadSummary): Promise<void> => {
    const newLoads = [load, ...this.tableData];
    this.tableData = newLoads;
  };

  @action
  updateMainData = async (
    {
      deletedIds,
      updatedIds,
    }: {
      deletedIds?: string[];
      updatedIds?: string[];
    },
    callback?: (loads: LoadSummary[]) => void
  ) => {
    let newTableList: any = this.tableData.concat();
    const newDeletedIds: string[] = deletedIds || [];
    if (updatedIds) {
      let loads: LoadSummary[] | ServiceError | null =
        await loadService.getUpdatedManifestLoad(
          new GetUpdatedManifestDataRequest({
            loadIds: updatedIds,
          })
        );
      if (!(loads instanceof ServiceError) && loads) {
        loads = loads?.map((item: LoadSummary) => {
          return new LoadSummary(item);
        });
        await LoadController.instance().setupLoadWarnings(loads);
        loads.forEach((item) => {
          const index = newTableList.findIndex(
            ({ id }: LoadSummary) => id === item.id
          );
          if (index < 0) {
            newTableList.unshift(item);
          } else {
            newTableList.splice(index, 1, item);
          }
        });
      }
    }
    newTableList = newTableList.filter(
      ({ id }: any) => !newDeletedIds.includes(id)
    );
    this.tableData = newTableList;

    callback?.(newTableList);
  };

  @action handleHoldOrReleaseLoadLocally = async ({
    loadIds,
    type,
  }: EventHoldLoadActionData) => {
    const newTableList: any = this.tableData.concat();
    newTableList.forEach((item: LoadSummary) => {
      if (loadIds.includes(item.id)) {
        item.onHold = type === E3DotMenuType.MARK_AS_HOLD;
      }
    });
    this.tableData = newTableList;
  };
}

export function createMyLoadStore() {
  return new MyLoadStore();
}
