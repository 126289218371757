import EncryptedStorageManager from './EncryptedStorageManager';

export default class StorageManager {
  static getItem(key: string): any {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  static setItem(key: string, value: any) {
    localStorage.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value
    );
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static removeItems(keys: string[]) {
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  static getUser() {
    return EncryptedStorageManager.getUser();
  }

  static setItemWithExpiry(key: string, value: any, ttl: number) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  static getItemWithExpiry(key: string) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
}
