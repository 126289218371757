import { Grid, SxProps } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import AxeleLoadingButton from '../../../ui-kit/AxeleLoadingButton/AxeleLoadingButton';
import { docType, previewFile } from '../../../utils/Doc';
import uuid from '../../../utils/uuid';
import { toBase64, viewFileType } from '../../../views/reports/documents/DnD';
import DocumentActions from '../../../views/reports/documents/DocumentActions';
import { FileApproved } from './UI/FileApproved';
import { FileUnApproved } from './UI/FileUnapproved';
import { FileUnregistered } from './UI/FileUnregistered';

const getIsImage = <T, K extends keyof T>(file: T, type: K): boolean => {
  const contentType = file[type] as string;
  return !!contentType.match(/(image)*\/(?:jpg|gif|png|jpeg)/);
};

interface FileResolution {
  width: number;
  height: number;
}

const LoadingButton = ({ isIMGFormat, isLoading }) => {
  return (
    <Grid
      container
      alignItems="center"
      padding={isIMGFormat ? '10px 0' : '12px 0'}
      sx={() => ({
        borderRadius: '5px',
        width: '100%',
        backgroundColor: `primary.main`,
      })}
      wrap="nowrap"
    >
      <AxeleLoadingButton
        variant="text"
        size="large"
        disabled={isLoading}
        loading={isLoading}
        sx={{
          width: '100%',
          '.MuiLoadingButton-loadingIndicator': {
            color: 'common.white',
          },
        }}
      ></AxeleLoadingButton>
    </Grid>
  );
};

export default function UploadUpdated({
  disabled = false,
  accept = {
    '.jpg, .jpeg, .png, .gif, .pdf, .docx, .xls, .xlsx, .pdf': [],
  },
  supportedFileSize = 20,
  supportedImgMaxResolution,
  maxFiles = 1,
  fileId,
  onChange,
  onRemoveCB,
  value,
  defaultFile,
  removable = true,
  skipLoader,
  uploadBtnText,
  uploadBtnStyle,
}: {
  disabled?: boolean;
  accept?: Record<string, string[]>;
  supportedFileSize?: number;
  maxFiles?: number;
  onChange: (file: File[] | null) => void;
  onRemoveCB?: () => Promise<void>;
  fileFromComponent?: File[];
  fileId?: string;
  value?: File[];
  defaultFile?: docType | null;
  removable?: boolean;
  supportedImgMaxResolution?: number;
  skipLoader?: boolean;
  uploadBtnText?: string;
  uploadBtnStyle?: SxProps;
}) {
  const [file, setFile] = useState<docType | null>(null);
  const [errors, setErrors] = useState<FileRejection[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (defaultFile) {
      setFile(defaultFile);
    }
  }, [defaultFile]);

  useEffect(() => {
    if (fileId) {
      setIsLoading(true);
      const getFileById = async () => {
        try {
          const result = await DocumentActions.downloadDocuments(
            [fileId],
            skipLoader
          );
          if (result) {
            setFile(result[0]);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      };
      getFileById();
    }
  }, [fileId]);

  useEffect(() => {
    if (!value) {
      setFile(null);
    }
  }, [value]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const base64Files = acceptedFiles.map(async (file: File) => {
      return await toBase64(file);
    });
    if (base64Files.length) {
      Promise.all(base64Files)
        .then((results) => {
          const base64File = results.map(({ src, fileName, size }) => ({
            id: uuid(),
            src,
            fileName,
            size,
          })) as viewFileType[];
          const [baseType, fileContent] = base64File[0].src.split(',');
          const contentType = baseType.split(/[:;]/)[1];
          const fileName = base64File[0].fileName;
          const size = base64File[0].size;
          const file = {
            contentType,
            fileContent,
            fileName,
            size,
          };
          setFile(file);
          onChange(acceptedFiles);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);

  const imageResolutionValidator = (file: Partial<FileResolution> & File) => {
    if (
      supportedImgMaxResolution &&
      file.width &&
      file.width > supportedImgMaxResolution
    ) {
      return {
        code: 'small-width',
        message: `Image width must be max ${supportedImgMaxResolution}px`,
      };
    }
    return null;
  };

  const customFileGetter = async (event: DropEvent) => {
    const accepted = event as unknown as [FileSystemFileHandle];
    const file: Partial<FileResolution> & File = await accepted?.[0].getFile();

    if (!getIsImage(file, 'type')) {
      return [file];
    }

    const promises = [];
    const promise: Promise<File> = new Promise((resolve) => {
      const image = new Image();
      image.onload = function () {
        file.width = image.width;
        file.height = image.height;
        resolve(file);
      };
      const url = URL.createObjectURL(file);
      image.src = url;
    });

    promises.push(promise);

    return await Promise.all(promises);
  };

  const { getRootProps, fileRejections, acceptedFiles } = useDropzone({
    onDrop,
    accept,
    maxSize: supportedFileSize * 1048576,
    maxFiles,
    validator: imageResolutionValidator,
    getFilesFromEvent: customFileGetter,
  });

  const onPreview = () => {
    if (!file) {
      return;
    }
    previewFile(file);
  };

  const onRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setFile(null);
    onChange(null);
    setErrors([]);
    onRemoveCB && onRemoveCB();
  };

  useEffect(() => {
    setErrors(fileRejections);
  }, [fileRejections]);

  const isIMGFormat = file && getIsImage(file, 'contentType');

  const isFileUnregistered = !disabled && !file && !errors.length;
  const isFileApproved = !disabled && file && !errors.length;
  const isFileUnapproved =
    !disabled && !acceptedFiles.length && !!errors.length;
  return (
    <Grid>
      {isLoading ? (
        <LoadingButton isIMGFormat={isIMGFormat} isLoading={isLoading} />
      ) : (
        <>
          {isFileUnregistered && (
            <FileUnregistered
              rootProps={{ ...getRootProps() }}
              uploadBtnStyle={uploadBtnStyle}
              uploadBtnText={uploadBtnText}
            />
          )}
          {isFileApproved && (
            <FileApproved
              rootProps={{ ...getRootProps() }}
              file={file}
              isIMGFormat={isIMGFormat}
              onRemove={onRemove}
              onPreview={onPreview}
              removable={removable}
            />
          )}
          {isFileUnapproved && (
            <FileUnApproved
              rootProps={{ ...getRootProps() }}
              onRemove={onRemove}
              errors={errors}
              removable={removable}
            />
          )}
        </>
      )}
    </Grid>
  );
}
