import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloseIcon } from '../../ui-kit/components/Assets';
import { t } from 'i18next';

const StyledDialog = styled(Dialog)(({ backgroundColor }: any) => {
  return {
    '& .MuiDialog-paper': {
      borderRadius: '19.3689px',
      backgroundColor: backgroundColor || '#D32F2F',
      textAlign: 'center',
      width: 282,
    },
  };
});

export default function DeletePopup({
  open,
  onAction,
  onClose,
  title,
  body,
  defaultText,
  isMultiple,
  entity,
  okText = t('delete'),
  cancelText = t('cancel'),
  cancelTextStyles,
  okTextStyles,
  hideDelete = false,
  backgroundColor,
}: {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
  title?: string;
  body?: string;
  defaultText?: boolean;
  isMultiple?: boolean;
  entity?: string;
  okText?: string;
  hideDelete?: boolean | false;
  cancelText?: string;
  cancelTextStyles?: object;
  okTextStyles?: object;
  backgroundColor?: string;
}) {
  const getText = () => {
    const entityName = isMultiple ? `${entity}s` : entity;
    const pronoun = isMultiple ? 'these' : 'this';
    return `${t('deleteMsg')} ${pronoun} ${entityName}?`;
  };

  const getTitle = () => {
    const entityName = isMultiple ? `${entity}s` : entity;
    return `${t('delete')} ${entityName}?`;
  };

  return (
    <StyledDialog open={open} backgroundColor={backgroundColor}>
      <DialogTitle
        sx={{
          margin: 0,
          fontSize: 16,
          color: '#ffffff',
          fontFamily: 'Poppins',
        }}
      >
        {defaultText ? getTitle() : title}
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon width={14} height={14} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: 'white',
          textAlign: 'center',
          padding: 16,
          borderRadius: '19.3689px',
          color: 'error.dark',
          fontFamily: 'Poppins',
        }}
      >
        {(body || defaultText) && <div>{defaultText ? getText() : body}</div>}
        <div style={{ marginTop: 20 }}>
          <Button
            variant={'outlined'}
            onClick={onClose}
            style={{
              borderRadius: 10,
              border: '1px solid primary.main',
              color: 'primary.main',
              fontSize: 13,
              fontWeight: 500,
              fontFamily: 'Poppins',
              lineHeight: '22px',
              width: 121,
              ...cancelTextStyles,
            }}
          >
            {cancelText}
          </Button>
          {Boolean(!hideDelete) && (
            <Button
              color={'error'}
              onClick={onAction}
              variant={'contained'}
              style={{
                borderRadius: 10,
                fontSize: 13,
                fontWeight: 500,
                fontFamily: 'Poppins',
                lineHeight: '22px',
                marginLeft: 8,
                width: 121,
                ...okTextStyles,
              }}
              sx={(theme: Theme) => ({
                color: theme?.palette?.primary?.contrast,
              })}
            >
              {okText}
            </Button>
          )}
        </div>
      </DialogContent>
    </StyledDialog>
  );
}
