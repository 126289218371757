import React, { useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import Box from '@mui/material/Box';
import {
  GridRenderCellParams,
  GridColDef,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import fetchAddress from '../../../../../utils/fetchAddress';

export default function Asynchronous({
  onChangeCb,
}: {
  onChangeCb: (e: React.SyntheticEvent, val: any) => void;
}) {
  const textFieldRef = useRef() as React.RefObject<HTMLInputElement>;
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<{ address: string }[]>([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setOptions([]);
    setInputValue('');
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (textFieldRef) {
      textFieldRef?.current?.focus();
    }
  }, []);

  const requestAddress = async (name: string) => {
    const locations = await fetchAddress(name);
    const addresses = locations.map((location: any) => ({
      fullAddress: '',
      address: location.ShortString,
      city: location.Address.City,
      state: location.Address.State,
      streetAddress: location.Address.StreetAddress,
      streetAddress2: '',
      center: {
        lat: location.StreetCoords.Lat,
        lng: location.StreetCoords.Lon,
      },
      zipcode: location.Address.Zip,
      timezone: location.TimeZone,
    }));
    setOptions(addresses);
    setLoading(false);
  };

  const handleMakeRequestOnInputChange = async (name: string) => {
    setLoading(true);
    await requestAddress(name);
  };

  useEffect(() => {
    if (open && inputValue) {
      handleMakeRequestOnInputChange(inputValue);
    }
  }, [inputValue, open]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.address === value.address}
      getOptionLabel={(option) => option.address}
      filterOptions={(options) => options}
      options={options}
      onChange={(e, val) => onChangeCb(e, val)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={textFieldRef}
          variant={'standard'}
          id={'address-text-field'}
          onChange={debouncedChangeHandler}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'disabled',
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label="Address"
        />
      )}
    />
  );
}

export function AddressEditInputCell(props: GridRenderCellParams<number>) {
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: { address: string }
  ) => {
    if (newValue) {
      // triggers changing event with new values
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      // triggers edit commit event and calls handleCellEditCommit method from Step3
      apiRef.current.commitCellChange({ id, field });
      // get back table in view mode
      apiRef.current.setCellMode(id, field, 'view');
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Asynchronous onChangeCb={handleChange} />
    </Box>
  );
}

export const renderAddressEditInputCell: GridColDef['renderCell'] = (
  params
) => {
  return <AddressEditInputCell {...params} />;
};
