import { IMaintenanceAssetTractor } from '../../../models/DTOs/maintenance/maintenanceAssetTractor/Model';
import { GetMaintenanceAssetTractorOneParams } from '../../../models/DTOs/maintenance/maintenanceAssetTractor/Requests';
import { ServiceError } from '../helperTypes';
import { Service } from '../Service';

export abstract class IMaintenanceAssetTractorService extends Service {
  abstract getMaintenanceAssetTractorOne(
    requestData: GetMaintenanceAssetTractorOneParams
  ): Promise<IMaintenanceAssetTractor | ServiceError>;
}
