import moment from 'moment';
import StorageManager from '../../../StorageManager/StorageManager';
import { loadsearchService } from '../../../api';
import { NumberToCurrency } from '../../../constants/numberToCurrency';
import { AssignLSLoadToDriverRequest } from '../../../models/DTOs/Loadsearch/Request';
import { CreateLoadRequestDTO } from '../../../subPages/loadsList/CreateLoad/dto/create-load-request.dto';
import { createLoadService } from '../../../subPages/loadsList/CreateLoad/services/createLoad.service';
import {
  AssignedDriver,
  LoadDetailsSummary,
} from '../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { assignDocumentToLoad } from '../../../subPages/loadsList/LoadDetailsPanel/utils';
import { formatPhoneNumber } from '../../../utils';
import * as loadTypes from '../loadTypes';
import {
  IDriverAssignmentData,
  ILoad,
  ILoadInfo,
  ILoadsearchTableItem,
  ISortingOrder,
} from '../loadTypes';

export const createTableData = (
  loadRegistry: Map<string, ILoad>
): ILoadsearchTableItem[] => {
  let index = 0;
  const tableData: loadTypes.ILoadsearchTableItem[] = [];
  for (const key of loadRegistry.keys()) {
    const item = loadRegistry.get(key);
    if (item && tableData.every(({ id }) => id !== item.id)) {
      const tableItem: loadTypes.ILoadsearchTableItem = {
        keyId: item['id'],
        id: item['id'],
        loadId: item?.loadId,
        loadSeqNum: item?.loadSeqNum,
        KpiData: {
          destinationDeadhead: item.destinationDeadhead,
          dns: item.dns,
          estimatedProfit: item.estimatedProfit,
          fixedCost: item.fixedCost,
          loadDuration: item.loadDuration,
          loadMiles: item.loadMiles,
          originDeadhead: item.originDeadhead,
          perDayRevenue: item.perDayRevenue,
          perMileRevenue: item.perMileRevenue,
          revenue: item.revenue,
          variableCost: item.variableCost,
        },
        phoneNumber: formatPhoneNumber({ phone: item['phoneNumber'] }),
        Age: {
          age: `${item['age']}`,
          logo: item['loadBoard'],
        },
        Dropoff: {
          city: item['destinationCity'],
          country: item['destinationState'],
          date: item.stops[item.stops.length - 1]?.appointmentEndDate,
          time: item['destinationDeadhead']?.toFixed(2) + 'mi',
          deadheadMiles: item['destinationDeadhead'],
        },
        LoadedMiles: {
          stops: item['stops'].length,
          value: `${item['loadMiles']?.toFixed(2)}`,
          totalMiles: `Total : ${(
            Number(item['loadMiles']) +
            (Number(item['originDeadhead']) +
              Number(item['destinationDeadhead']))
          ).toFixed(2)} mi`,
          actualTotalMiles: `${(
            Number(item['loadMiles']) +
            (Number(item['originDeadhead']) +
              Number(item['destinationDeadhead']))
          ).toFixed(2)}`,
        },
        MarketRate: {
          subvalue:
            NumberToCurrency(
              item['pessimisticRate'] ? item['pessimisticRate'] : '0'
            ) +
            '/mi - ' +
            NumberToCurrency(
              item['optimisticRate'] ? item['optimisticRate'] : '0'
            ) +
            '/mi',
          value: `${item['expectedRate']}`,
          pessimisticRate: item['pessimisticRate']?.toFixed(2),
          optimisticRate: item['optimisticRate']?.toFixed(2),
        },
        Equipment: {
          subvalue: item['weight'],
          value: item['equipmentType'],
          length: item['length'],
        },
        Broker: {
          subvalue: item['mcNumber'] ? 'MC' + item['mcNumber'] : '',
          value: item['company'],
          MC: item['mcNumber'],
          email: item['email'],
          phone: item['phoneNumber'],
          creditRating: item['currentScore'],
        },
        ReloadScore: {
          subValue: (item &&
            item['dns'] &&
            item['dns'].length > 0 &&
            (item['dns'][0]['value'] / 5) * 100) as number,
          value: (item &&
            item['dns'] &&
            item['dns'].length > 0 &&
            item['dns'][0]['value']) as number,
        },
        Mileage: {
          subvalue: item['perMileRevenue']?.toFixed(2),
          value: `${
            item['revenue'] !== 0 && item['loadMiles'] !== 0
              ? item['revenue'] / item['loadMiles']
              : 0
          }`,
        },
        Offer: {
          profits: `Profit : ${NumberToCurrency(item['estimatedProfit'])}`,
          perdayProfitInNumber: item['perDayEstimatedProfit'],
          profitPerDay: `(${NumberToCurrency(
            item['perDayEstimatedProfit']
          )}/day)`,
          perDayRevenue: item['perDayRevenue']?.toFixed(2),
          value: item['revenue']?.toFixed(2),
          fixedCost: item['fixedCost']?.toFixed(2),
          variableCost: item['variableCost']?.toFixed(2),
          estimatedProfit: item['estimatedProfit']?.toFixed(2),
          isEstimatedOfferValue: item['revenueSourceType'] === 'EST_RATE',
        },
        Pickup: {
          city: item['originCity'],
          country: item['originState'],
          date: item.stops[0]?.appointmentStartDate,
          time: item['originDeadhead']?.toFixed(2) + 'mi',
          deadheadMiles: item['originDeadhead'],
        },
        actions: {
          actionValue:
            index === 1 || index === 2 || index === 6
              ? 2
              : index === 3 || index === 5 || index === 7
              ? 1
              : 3,
          book:
            index === 1 || index === 2 || index === 6
              ? true
              : !(index === 3 || index === 5 || index === 7),
          bookmark: !!(item && item['bookMarkId']),
          savedLoadsData: item,
        },
      };
      tableData.push(tableItem);
      index++;
    }
  }
  return tableData;
};

export const sortingOrder = ({
  tableData,
  columnName,
  sortBy = 'desc',
  sortingKey,
  type,
}: ISortingOrder): ILoadsearchTableItem[] => {
  try {
    switch (type) {
      case 'number':
        if (sortBy === 'desc') {
          const sortedTableData = tableData
            .slice()
            .sort(
              (a, b) =>
                Number(b[columnName][sortingKey]) -
                Number(a[columnName][sortingKey])
            );
          return sortedTableData;
        }
        if (sortBy === 'asc') {
          const sortedTableData = tableData
            .slice()
            .sort(
              (a, b) =>
                Number(a[columnName][sortingKey]) -
                Number(b[columnName][sortingKey])
            );
          return sortedTableData;
        }
        if (sortBy === 'none') {
          return tableData;
        }
        return tableData;
      case 'string':
        if (sortBy === 'desc') {
          const sortedTableData = tableData
            .slice()
            .sort((a, b) =>
              b[columnName][sortingKey].localeCompare(a[columnName][sortingKey])
            );
          return sortedTableData;
        }
        if (sortBy === 'asc') {
          const sortedTableData = tableData
            .slice()
            .sort((a, b) =>
              a[columnName][sortingKey].localeCompare(b[columnName][sortingKey])
            );
          return sortedTableData;
        }
        if (sortBy === 'none') {
          return tableData;
        }
        return tableData;

      case 'date':
        if (sortBy === 'desc') {
          const sortedTableData = tableData
            .slice()
            .sort(
              (a, b) =>
                new Date(b[columnName][sortingKey]) -
                new Date(a[columnName][sortingKey])
            );
          return sortedTableData;
        }
        if (sortBy === 'asc') {
          const sortedTableData = tableData
            .slice()
            .sort(
              (a, b) =>
                new Date(a[columnName][sortingKey]) -
                new Date(b[columnName][sortingKey])
            );
          return sortedTableData;
        }
        if (sortBy === 'none') {
          return tableData;
        }
        return tableData;

      default:
        return tableData;
    }
  } catch (error) {
    console.error(error);
    return tableData;
  }
};

export const updateProfit = (
  data: any,
  [selectedRowData, row]: [
    selectedRowData: ILoadsearchTableItem,
    row: ILoadsearchTableItem
  ]
): [selectedRowData: ILoadsearchTableItem, row: ILoadsearchTableItem] => {
  row.Offer['estimatedProfit'] = (
    Number(data?.revenue) -
    Number(Number(row.Offer['fixedCost']) + Number(row.Offer['variableCost']))
  ).toFixed(2);
  selectedRowData.actions.savedLoadsData['estimatedProfit'] =
    Number(data?.revenue) -
    Number(Number(row.Offer['fixedCost']) + Number(row.Offer['variableCost']));
  row.Offer['perDayRevenue'] = (data?.perDayRevenue).toFixed(2);
  selectedRowData.actions.savedLoadsData['perDayRevenue'] =
    (data?.perDayRevenue).toFixed(2);
  row.Offer['value'] = data?.revenue;
  selectedRowData.actions.savedLoadsData['revenue'] =
    (data?.revenue).toFixed(2);
  row.Offer['profits'] = `Profit : ${NumberToCurrency(
    data['estimatedProfit']
  )}`;
  row.Offer['perdayProfitInNumber'] = data['perDayEstimatedProfit'];
  row.Offer['profitPerDay'] = `(${NumberToCurrency(
    data['perDayEstimatedProfit']
  )}/day)`;
  selectedRowData.Offer['estimatedProfit'] = (
    Number(data?.revenue) -
    Number(Number(row.Offer['fixedCost']) + Number(row.Offer['variableCost']))
  ).toFixed(2);
  selectedRowData.Offer['perDayRevenue'] = NumberToCurrency(
    data?.perDayRevenue
  );
  selectedRowData.Offer['value'] = data?.revenue;
  selectedRowData.Mileage['value'] =
    data['revenue'] !== 0 && data['loadMiles'] !== 0
      ? data['revenue'] / data['loadMiles']
      : 0;
  selectedRowData.Mileage['subvalue'] = data?.perMileRevenue?.toFixed(2);
  return [selectedRowData, row];
};

export const updateLoad = (
  load: LoadDetailsSummary,
  driver?: any
): LoadDetailsSummary => {
  const { driverGroupId, driverName } = driver || {};
  if (driverGroupId) {
    load.assignedDriver = {
      driverGroupId,
      driverGroupName: driverName,
      assignToDriver: true,
    };
  }
  return load;
};

const titleCase = (str: string) => {
  const splitStr = str?.toLowerCase?.()?.split?.(' ');
  for (let i = 0; i < splitStr?.length; i++) {
    // Assign it back to the array
    splitStr[i] =
      splitStr?.[i]?.charAt?.(0)?.toUpperCase?.() +
      splitStr?.[i]?.substring?.(1);
  }
  // Directly return the joined string
  return splitStr?.join?.(' ');
};

export const toAssignmentData = (
  selectedLoadData: ILoad,
  driver?: any
): IDriverAssignmentData | null => {
  const { driverGroupId, driverName } = driver || {};
  let assignmentData = null;
  if (driverGroupId) {
    const { dispatcherId, nextLoadId, previousLoadId } = selectedLoadData;
    assignmentData = {
      driverGroupId,
      driverGroupName: titleCase(driverName),
      action: 'ASSIGNMENT_PLANNED',
      dispatcherId,
      previousLoadId,
      nextLoadId,
    };
  }
  return assignmentData;
};

export const bookLoad = async (
  loadSummary: CreateLoadRequestDTO,
  assignedDocument: File,
  assignmentData: IDriverAssignmentData,
  assignedDriver?: AssignedDriver | null
): Promise<any> => {
  const load = loadSummary;
  let savedLoad: any;
  const assignToDriver = assignedDriver?.assignToDriver;

  if (assignToDriver) {
    const driverGroupName = assignedDriver?.driverGroupName;
    const request = new AssignLSLoadToDriverRequest({
      ...assignmentData,
      driverGroupName,
      load,
    });
    savedLoad = await loadsearchService.assignLSLoadToDriver(request, {
      driverGroupName,
    });
    if (assignedDocument && savedLoad?.id) {
      await assignDocumentToLoad(
        {
          id: savedLoad.id,
          seqNumber: savedLoad.seqNumber,
          terminalId: savedLoad.terminalId,
        },
        assignedDocument
      );
    }
    // AXL2-4728 here also we shoudl take care of document assign
  } else {
    if (assignedDocument) {
      savedLoad = await createLoadService.createLoad(load);
      await createLoadService.saveRateconFile({
        file: assignedDocument,
        load: {
          ...savedLoad?.data?.loads?.[0],
          terminalId:
            savedLoad?.data?.loads?.[0]?.terminalId ??
            savedLoad?.data?.loads?.[0]?.terminal?.id,
        },
      });
    } else {
      savedLoad = await createLoadService.createLoad(load);
    }
    savedLoad = savedLoad?.data?.loads?.[0];
  }
  return savedLoad;
};

export const toLoadInfo = (
  loadSummary: LoadDetailsSummary,
  savedLoad: any,
  assignmentData: IDriverAssignmentData,
  assignedDriver?: AssignedDriver | null
): ILoadInfo => {
  const { assignToDriver, driverGroupId } = assignedDriver || {};
  const userStorage = StorageManager.getUser() || {};
  const { customer } = loadSummary;
  const loadInfo: ILoadInfo = {
    id: savedLoad.id,
    loadStatus: loadSummary.loadStatus,
    seqNumber: savedLoad.seqNumber,
    driverGroupId,
    customerId: customer?.id || null,
    organizationId: userStorage.organizationId || null,
  };
  if (assignToDriver && driverGroupId) {
    loadInfo.id = savedLoad.loadId;
    loadInfo.loadStatus = assignmentData?.action || '';
    loadInfo.seqNumber = savedLoad.loadSeqId;
  }
  return loadInfo;
};

const timeFormate = (date: string) => {
  const data = new Date(date);
  let hrs: number | string = data.getHours();
  let mins: number | string = data.getMinutes();
  if (hrs <= 9) hrs = '0' + hrs;
  if (mins < 10) mins = '0' + mins;
  const postTime = hrs + ':' + mins;
  return postTime;
};

export const dateTimeFormate = (date: string) => {
  let dateFormate = '';
  const dateConvert = new Date(date);
  const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][
    dateConvert.getDay()
  ];
  const momentDate = moment(dateConvert).format('MM/DD');
  const momentTime = timeFormate(date);
  dateFormate = `${weekday} ${momentDate} ${momentTime}`;
  return dateFormate;
};
