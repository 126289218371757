import { terminalService } from '../../../api';
import { TerminalShort } from '../../../models/common/modelsShort';

let instance: TerminalController | null = null;
class TerminalController {
  static instance(): TerminalController {
    if (instance === null) {
      instance = new TerminalController();
    }
    return instance;
  }

  static destroy() {
    instance = null;
  }

  terminals: TerminalShort[] | null;
  isFetching!: boolean;

  private constructor() {
    this.terminals = null;
  }

  async isTerminalSupport(): Promise<boolean> {
    await this.getTerminals();
    return !!this.terminals?.length;
  }

  async getTerminals(): Promise<TerminalShort[] | null> {
    if (!this.terminals && !this.isFetching) {
      this.isFetching = true;
      const terminals = await terminalService.getTerminals();
      if (terminals?.content) {
        this.terminals = terminals.content.map((t) => new TerminalShort(t));
      }
      this.isFetching = false;
    }
    return this.terminals;
  }

  terminalById(id) {
    if (this.terminals?.length && id) {
      return this.terminals.find((t) => t.id === id);
    }
    return null;
  }

  async getPrimaryTerminal(): Promise<TerminalShort | null | undefined> {
    await this.isTerminalSupport();
    return this.terminals?.find((t) => t.isPrimary);
  }
}

export default TerminalController;
