import React from 'react';
import {
  Box,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormControlLabelProps,
  Typography,
} from '@mui/material';

interface CheckBoxProps {
  checked?: boolean;
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  style?: {};
  subLabel?: string;
  subLabelStyles?: {};
  onChange: (event: any, checked: boolean) => void;
  disabled?: FormControlLabelProps['disabled'];
  defaultChecked?: boolean;
}

export const CheckBox = ({
  checked,
  onChange,
  label,
  labelPlacement = 'end',
  style = {},
  disabled,
  defaultChecked,
  subLabel,
  subLabelStyles,
}: CheckBoxProps) => {
  return (
    <>
      <FormControlLabel
        control={<MuiCheckbox />}
        onChange={onChange}
        checked={checked}
        label={label}
        labelPlacement={labelPlacement}
        style={{ marginLeft: 0, ...style }}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      {subLabel && (
        <Box
          sx={{
            paddingLeft: '40px',
            marginTop: '-12px',
            ...subLabelStyles,
          }}
        >
          <Typography variant="body3" sx={{ color: 'text.secondary' }}>
            {subLabel}
          </Typography>
        </Box>
      )}
    </>
  );
};
