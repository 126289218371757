export const LoadViewTranslations = {
  loadViewTitle: 'Create a Load View',
  loadViewLoadId: 'Load ID',
  loadViewTripId: 'Trip ID',
  loadViewReference: 'Reference',
  loadViewOrigin: 'Origin',
  loadViewOriginState: 'Origin State',
  loadViewDestination: 'Destination ',
  loadViewDestinationDState: 'Destination State',
  loadViewEquipmentType: 'Equipment Type',
  loadViewCommodityType: 'Commodity Type',
  loadViewLoadType: 'Load Type',
  loadViewRequiredEquipmentType: 'Required Equipment Type',
  loadViewDriverOperationMode: 'Driver Operation Mode',
  loadViewDispatchNotes: 'Dispatch Notes',
  loadViewManifestID: 'Manifest ID',
  loadViewCarrier: 'Carrier',
  loadViewCustomer: 'Customer',
  loadViewRevenue: 'Revenue',
  loadViewLoadStatus: 'Load Status',
  loadViewTrip: 'Trip',
  loadViewDriver: 'Driver',
  loadViewDispatcher: 'Dispatcher',
  loadViewTractor: 'Tractor',
  loadViewTrailer: 'Trailer',
  loadViewSeal: 'Seal',
  loadViewChassis: 'Chassis',
  loadViewContainer: 'Container',
  loadViewWeight: 'Weight',
  loadViewPickUp: 'Pickup',
  loadViewDropOff: 'Drop Off',
  loadViewStops: 'Stops',
  loadViewMissingDocs: 'Missing Documents',
  loadViewInvoicingTerminal: 'Invoicing Terminal',
  loadViewDispatchTerminal: 'Dispatch Terminal',

  totalDriveTime: 'Total Drive Time',
  handlingTime: 'Handling Time',
  detention: 'Detention',
  timeatLocation: 'Time at Location',
  delay: 'Delay',
  loadDirection: 'Import/Export',
  bookingNumber: 'Booking',
  appointmentNumber: 'Appointment',
  loadTags: 'Tags',
};
