import Grid from '@mui/material/Grid';
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { docType } from '../../../utils/Doc';
import UploadUpdated from './UploadUpdated';
import { SxProps } from '@mui/material';

const FileUploadUpdated: React.FC<{
  disabled?: boolean;
  control?: any;
  fileId?: string;
  name: string;
  sizes?: {
    xs: number;
  };
  style?: {};
  shouldUnregister?: ControllerProps['shouldUnregister'];
  label?: string;
  accept?: { [key: string]: string[] };
  removeFiles?: (files: FileList | null) => void;
  onChangeCB?: (files: File[] | null) => void;
  defaultFile?: docType | null;
  supportedFileSize?: number;
  removable?: boolean;
  supportedImgMaxResolution?: number;
  skipLoader?: boolean;
  uploadBtnText?: string | any;
  uploadBtnStyle?: SxProps;
  onRemoveCB?: () => Promise<void>;
}> = ({
  disabled = false,
  control,
  fileId,
  name,
  sizes = { xs: 12 },
  style = { mb: 2 },
  shouldUnregister,
  accept,
  onChangeCB,
  defaultFile,
  supportedFileSize,
  removable = true,
  supportedImgMaxResolution,
  skipLoader = false,
  uploadBtnText,
  uploadBtnStyle,
  onRemoveCB,
}) => {
  return (
    <Grid xs={sizes.xs} item sx={style}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <UploadUpdated
            defaultFile={defaultFile}
            accept={accept}
            value={value}
            onChange={(file) => {
              onChange(file);
              onChangeCB && onChangeCB(file);
            }}
            fileId={fileId}
            disabled={disabled}
            supportedFileSize={supportedFileSize}
            removable={removable}
            supportedImgMaxResolution={supportedImgMaxResolution}
            skipLoader={skipLoader}
            uploadBtnStyle={uploadBtnStyle}
            uploadBtnText={uploadBtnText}
            onRemoveCB={onRemoveCB}
          />
        )}
        name={name}
        control={control}
        shouldUnregister={shouldUnregister}
      />
    </Grid>
  );
};

export default React.memo(FileUploadUpdated);
