import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import 'animate.css';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import AxeleLoadingButton from '../../../ui-kit/AxeleLoadingButton/AxeleLoadingButton';
import ButtonImproved from '../../../ui-kit/components/ButtonImproved';
import IconsComponent from '../../../ui-kit/components/IconsComponent';
import { AXELE_PERMISSION_TYPE } from '../../Permission';
import PermissionWrapper from '../../Permission/components/PermissionWrapper';
import {
  CloseOrCancelButton,
  ECloseOrCancelButton,
} from './CloseOrCancelButton';

interface FooterProps {
  onSave: () => void;
  onCancel: () => void;
  // entity: string;
  shouldShake: boolean;
  actionButtonLabel?: string;
  isSaveIconVisible?: boolean;
  showSaveButton: boolean;
  showCancelButton?: boolean;
  secondaryActionButtonLabel?: string;
  secondaryActionButtonIconName?: string;
  onSecondaryAction?: () => void;
  secondaryActionButtonIsDisabled?: boolean;
  permissions?: { [key: string]: AXELE_PERMISSION_TYPE[] } | undefined;
  isSubmitting?: boolean;
  secondaryActions?: {
    secondaryActionButtonLabel?: string;
    secondaryActionButtonIconName?: string;
    onSecondaryAction?: () => void;
    secondaryActionButtonIsDisabled?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
  }[];
}

const Footer: React.FC<FooterProps> = ({
  onSave,
  onCancel,
  shouldShake,
  actionButtonLabel,
  isSaveIconVisible,
  showSaveButton,
  showCancelButton = false,
  secondaryActionButtonLabel,
  secondaryActionButtonIconName,
  onSecondaryAction,
  secondaryActionButtonIsDisabled = true,
  permissions,
  isSubmitting,
  secondaryActions = [],
}: FooterProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const animationClassName = useMemo(() => {
    setTimeout(() => {
      setTooltipOpen(false);
    }, 3000);

    //https://animate.style/
    return clsx({
      animate__animated: tooltipOpen,
      animate__pulse: tooltipOpen,
      animate__infinite: tooltipOpen,
    });
  }, [tooltipOpen]);

  useEffect(() => {
    if (shouldShake) setTooltipOpen(true);
  }, [shouldShake]);

  const getButtonIcon = (iconName?: string) => {
    return !!iconName ? (
      <IconsComponent
        iconName={iconName}
        source="MUIcons"
        styleProps={{ width: '20px' }}
      />
    ) : null;
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        direction: 'row',
        height: 'auto',
        padding:
          showCancelButton || showSaveButton || secondaryActionButtonLabel
            ? '0.5rem'
            : '0rem',
        backgroundColor: 'primary.contrast',
        color: 'primary.contrast',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        paddingBottom: 3,
      }}
    >
      {showCancelButton && (
        <CloseOrCancelButton
          onClose={onCancel}
          isDirty={false} //just reset form data only
          type={ECloseOrCancelButton.CANCEL_BUTTON}
        />
      )}

      {showSaveButton && (
        <div className={animationClassName}>
          <Tooltip
            open={tooltipOpen}
            title="Save First before leaving"
            placement="top"
            arrow
          >
            <PermissionWrapper permissions={permissions?.save}>
              <AxeleLoadingButton
                id={actionButtonLabel}
                startIcon={isSaveIconVisible && getButtonIcon('SaveOutlined')}
                variant="contained"
                onClick={onSave}
                size="small"
                sx={{
                  marginRight: '10px',
                  width: '150px',
                }}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {actionButtonLabel}
              </AxeleLoadingButton>
            </PermissionWrapper>
          </Tooltip>
        </div>
      )}
      {!showSaveButton &&
        // secondaryActionButtonLabel &&
        secondaryActions?.map?.((e) => (
          <ButtonImproved
            style={{ marginBottom: '10px', marginLeft: '5px' }}
            key={e?.secondaryActionButtonLabel}
            id={e?.secondaryActionButtonLabel}
            startIcon={getButtonIcon(e?.secondaryActionButtonIconName)}
            variant={e?.variant ?? 'contained'}
            onClick={e?.onSecondaryAction}
            label={e?.secondaryActionButtonLabel}
            disabled={e?.secondaryActionButtonIsDisabled}
          />
        ))}
    </Box>
  );
};

export default Footer;
