import * as yup from 'yup';
import {
  emailArrayValidationYup,
  nullableNumberYup,
  nullableStringMaxValidation,
  phoneNumberValidation,
} from '../utils';

export const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const defaultContactValidationSchema = {
  description: nullableStringMaxValidation(50),
  email: nullableStringMaxValidation(50)
    .email('Email is not valid.')
    .matches(emailRegExp, 'Email is not valid.'),
  phoneData: yup
    .object()
    .shape(
      {
        phone: phoneNumberValidation,
        extension: nullableNumberYup().max(
          99999,
          'Extension should have 5 or less characters.'
        ),
      },
      [['phone', 'phone']]
    )
    .nullable(true),
};

export const contactValidationSchema = yup.object().shape({
  fullName: nullableStringMaxValidation(100).required('Name is requerd.'),
  ...defaultContactValidationSchema,
});

export const loadContactValidationSchema = yup.object().shape({
  fullName: nullableStringMaxValidation(100),
  ...defaultContactValidationSchema,
});

export const EmailFormValidationSchema = yup.object().shape({
  to: emailArrayValidationYup('to').min(1, 'Email is required'),
  cc: emailArrayValidationYup('cc'),
});
