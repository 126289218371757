import { Link, Typography } from '@mui/material';
import { hyperLinkProps, secondaryPanelType } from '../type';
import { secondaryPanelData } from '../config';
import { ESecondaryDetailsPanelType } from '../../../../../views/dispatch/constants/types';
import { hyperLinkStyle } from '../styles';

export const LogLink = (props: {
  data?: hyperLinkProps;
  handlePanelOpen: (panelProp: secondaryPanelType) => void;
}) => {
  const { data, handlePanelOpen } = props;
  const type: string | any =
    data?.type == 'manifest' ? ESecondaryDetailsPanelType.LOAD : data?.type;
  return data?.displayName ? (
    data?.type != ESecondaryDetailsPanelType.LOAD && !data?.isNothyperLink ? (
      <Link
        href="#"
        underline="none"
        sx={{ ...hyperLinkStyle }}
        onClick={(event) => {
          event.stopPropagation();
          handlePanelOpen({
            id: data?.id,
            type:
              type in secondaryPanelData
                ? secondaryPanelData[type]
                : (type as any),
          });
        }}
      >
        {data?.displayName}
      </Link>
    ) : (
      <span style={{ ...hyperLinkStyle }}>{data?.displayName}</span>
    )
  ) : (
    <>{' - '}</>
  );
};
