import { DEFAULT_HANDLING_TIME } from '../../../../../../subPages/loadsList/CreateLoad/data.config';
import { StopTypeEnum } from '../../../../../../subPages/loadsList/CreateLoad/schema/stops';

export const initialEmptyTripData = ({
  terminalId,
}: {
  terminalId?: string;
}) => ({
  isEmptyTrip: true,
  terminalId,
  stops: [
    {
      type: StopTypeEnum.TRIP_STOP,
      estimatedActivityDuration: DEFAULT_HANDLING_TIME,
      relayDropOffEstimatedActivityDuration: DEFAULT_HANDLING_TIME,
      referenceNumber: '',
      notes: '',
      fixedAppointment: false,
      isEndTimeVisible: false,
      isRelayDropOffEndTimeVisible: false,
      appointmentStartDate: '',
      appointmentEndDate: '',
      appointmentStartTime: '',
      appointmentEndTime: '',
      relayDropOffAppointmentStartDate: '',
      relayDropOffAppointmentStartTime: '',
      relayDropOffAppointmentEndDate: '',
      relayDropOffAppointmentEndTime: '',
      isDriverAssistRequired: false,
      isRelayDropOffDriverAssistRequired: false,
      relayPickupRevenueShare: null,
      relayDropOffRevenueShare: null,
      appointmentConfirmation: 'NO',
      dropOffAppointmentConfirmation: 'NO',
      location: {
        locationId: null,
        locationName: '',
        businessName: '',
        center: { lat: null, lng: null },
        city: '',
        state: '',
        zipcode: '',
        country: '',
        streetAddress: '',
        address: '',
        address2: '',
        geoCoordinates: [],
      },
      contact: {
        id: '',
        contactName: '',
        countryCode: '',
        phone: '',
        extension: '',
        email: '',
        firstname: '',
        lastname: '',
      },
      isHandlingTimeUpdatedManually: false,
      isDropOffHandlingTimeUpdatedManually: false,
      isNotesUpdatedManually: false,
      isRefNumberUpdatedManually: false,
      isContactDetailsUpdatedManually: false,
    },
    {
      type: StopTypeEnum.TRIP_STOP,
      estimatedActivityDuration: DEFAULT_HANDLING_TIME,
      relayDropOffEstimatedActivityDuration: DEFAULT_HANDLING_TIME,
      referenceNumber: '',
      notes: '',
      fixedAppointment: false,
      appointmentStartDate: '',
      appointmentEndDate: '',
      appointmentStartTime: '',
      appointmentEndTime: '',
      isEndTimeVisible: false,
      isRelayDropOffEndTimeVisible: false,
      relayDropOffAppointmentStartDate: '',
      relayDropOffAppointmentStartTime: '',
      relayDropOffAppointmentEndDate: '',
      relayDropOffAppointmentEndTime: '',
      isDriverAssistRequired: false,
      isRelayDropOffDriverAssistRequired: false,
      relayPickupRevenueShare: null,
      relayDropOffRevenueShare: null,
      appointmentConfirmation: 'NO',
      dropOffAppointmentConfirmation: 'NO',
      location: {
        locationId: null,
        locationName: '',
        businessName: '',
        center: { lat: null, lng: null },
        city: '',
        state: '',
        zipcode: '',
        country: '',
        streetAddress: '',
        address: '',
        address2: '',
        geoCoordinates: [],
      },
      contact: {
        contactName: '',
        countryCode: '',
        phone: '',
        extension: '',
        email: '',
        firstname: '',
        lastname: '',
      },
      isHandlingTimeUpdatedManually: false,
      isDropOffHandlingTimeUpdatedManually: false,
      isNotesUpdatedManually: false,
      isRefNumberUpdatedManually: false,
      isContactDetailsUpdatedManually: false,
    },
  ],
});
