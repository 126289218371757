import { DashboardLoader } from '../../../../home/DhaboardLoader';
import { StyledCardTripRoot } from '../styles';

export const GanttCardLoader = () => {
  return (
    <StyledCardTripRoot
      sx={{
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DashboardLoader />
    </StyledCardTripRoot>
  );
};
