import { Grid, Stack, ThemeProvider, useTheme } from '@mui/material';
import { forwardRef, useMemo } from 'react';
import useFiltersAutoResize, {
  mergeHiddenColumns,
} from '../../../../../common/hooks/useFiltersAutoResize';
import { useThemeResponsive } from '../../../../../common/hooks/useThemeResponsive';
import { useStores } from '../../../../../store/root.context';
import AllFilters from '../../../../../ui-kit/components/DataGridPro/filter/AllFilters';
import Filters from '../../../../../ui-kit/components/DataGridPro/filter/Filters';
import { IFiltersFormConfig } from '../../../../trips/constants/types';
import StyledTableSettings from '../../Generic/StyledTableSettings';
import {
  getStyledCustomFilter,
  getThemeDispatchFilters,
} from '../../gantt/Filters/styles';

export interface CustomizeFiltersProps {
  filterColumns: IFiltersFormConfig[];
  groupOrder?: string[];

  [key: string]: any;
}

const CustomAllFilters = forwardRef<HTMLDivElement, CustomizeFiltersProps>(
  (
    {
      filterColumns = [],
      displayFilters = true,
      onAllFilterSubmit,
      onAllFilterReset,
      filterPerCol,
      defaultFilterValue,
      groupOrder,
    },
    ref
  ) => {
    const currTheme = useTheme();
    const overrideTheme = getThemeDispatchFilters({
      currTheme,
      inputWithSearchIcon: true,
    });
    const styles = getStyledCustomFilter({ theme: currTheme });

    return (
      displayFilters && (
        <ThemeProvider theme={overrideTheme}>
          <AllFilters
            column={filterColumns.map((item) => {
              return {
                ...item,
                customStyles: null,
              };
            })}
            handleOnSubimt={onAllFilterSubmit}
            handleOnReset={onAllFilterReset}
            filterPerCol={filterPerCol}
            groupOrder={groupOrder}
            defaultFilterValue={defaultFilterValue}
            stylesProps={styles.AllFilters}
            ref={ref}
          />
        </ThemeProvider>
      )
    );
  }
);

export const CustomizeFilters = ({
  filterColumns = [],
  displayFilters = true,
  onAllFilterSubmit,
  onAllFilterReset,
  filterPerCol,
  defaultFilterValue,
  onPageFilterChange,
  tableConfigurationProps,
  containerStyles,
  groupOrder,
}: CustomizeFiltersProps) => {
  const { isMobile } = useThemeResponsive();
  const currTheme = useTheme();
  const overrideTheme = getThemeDispatchFilters({ currTheme });
  const styles = getStyledCustomFilter({
    theme: currTheme,
  });

  const {
    dispatch2TripsStore: { secondaryPanelData },
  } = useStores();

  const {
    tableSettingsRef,
    hiddenColumns,
    filterRefs,
    allFilterRef,
    containerRef,
  } = useFiltersAutoResize({
    panelState: !!secondaryPanelData,
  });

  const displayFilterColumns = useMemo(
    () => mergeHiddenColumns(filterColumns, hiddenColumns),
    [filterColumns, hiddenColumns]
  );
  if (!defaultFilterValue) return <></>; //TODO: Added this code is important to handle error here

  return (
    <ThemeProvider theme={overrideTheme}>
      <Grid
        container
        sx={{ ...styles.LeftCustomizeFilters, ...containerStyles }}
      >
        <Grid
          id="FilterGridRow"
          container
          sx={styles.FilterGridRow}
          ref={containerRef}
        >
          {!isMobile && filterColumns?.length > 0 ? (
            <Stack sx={styles.FilterGridRowContent}>
              <Filters
                columns={displayFilterColumns}
                onChangeCb={onPageFilterChange}
                defaultFilterValue={defaultFilterValue}
                setRef={(index: number, ref: HTMLDivElement, column: any) => {
                  filterRefs.current[index] = { name: column.name, el: ref };
                }}
              />
              <CustomAllFilters
                filterColumns={displayFilterColumns}
                groupOrder={groupOrder}
                displayFilters={displayFilters}
                onAllFilterSubmit={onAllFilterSubmit}
                onAllFilterReset={onAllFilterReset}
                filterPerCol={filterPerCol}
                defaultFilterValue={defaultFilterValue}
                ref={allFilterRef}
              />
              {!isMobile && (
                <StyledTableSettings
                  ref={tableSettingsRef}
                  {...tableConfigurationProps.TableSettings}
                />
              )}
            </Stack>
          ) : (
            <>
              <CustomAllFilters
                filterColumns={filterColumns}
                displayFilters={displayFilters}
                onAllFilterSubmit={onAllFilterSubmit}
                onAllFilterReset={onAllFilterReset}
                filterPerCol={filterPerCol}
                defaultFilterValue={defaultFilterValue}
              />
              {!isMobile && (
                <StyledTableSettings
                  {...tableConfigurationProps.TableSettings}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
