import { Skeleton } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ILoadSearchTripDetails } from '../../../../models/DTOs/Loadsearch/Model';
import {
  LoadSearchGetSolutionRequest,
  LoadSearchInvokeRequest,
} from '../../../../models/DTOs/Loadsearch/Request';
import { useDetailsPanelStore } from '../../../../store/DetailsPanel';
import {
  ExpandableTimelineV3PanelView,
  StopSolutionV3Prop,
  TripV3,
  TripV3Props,
  TripViewV3Mode,
} from '../../../../ui-kit/components/TripPlan';
import { ESecondaryDetailsPanelType } from '../../../dispatch2/constants/types';
import {
  DEBOUNCE_TIME,
  GET_SOLUTION_RESPONSE_STATUS,
} from '../../constants/tripPlan.constant';
import { InvocationReqIdMetaDataProps } from '../DetailPanel';
import { getLSGetSolution, getLSInvoke } from '../DetailPanel/utils/api.utils';
import { convertTripDetailToStopV3PropList } from '../DetailPanel/utils/trip.utils';
import { PopupActions } from '../Popup/PopupActions';
import { StyledPopupContent, StyledPopupRoot } from '../Popup/styles';

export interface TripPlanProps {
  selectedLoad: any;
  onBookHandler: () => void;
  actionBidBook: any;
  invocationReqIdMetaData: InvocationReqIdMetaDataProps;
  setInvocationReqIdMetaData: React.Dispatch<
    React.SetStateAction<InvocationReqIdMetaDataProps>
  >;
}

export const TripPlan = ({
  selectedLoad,
  onBookHandler,
  invocationReqIdMetaData,
  setInvocationReqIdMetaData,
  actionBidBook,
}: TripPlanProps): JSX.Element => {
  const { trackPromise } = useDetailsPanelStore;
  const [lsTripDetails, setLsTripDetails] =
    useState<ILoadSearchTripDetails | null>(null);
  const [solutionLoader, setSolutionLoader] = useState<boolean>(false);

  const getLSGetSolutionRequestHandler = useCallback(
    async (
      invokeRequest: LoadSearchInvokeRequest,
      invocationId: string
    ): Promise<void> => {
      debouncedGetSolutionHandler?.cancel();
      const getSolutionRequest: LoadSearchGetSolutionRequest =
        new LoadSearchGetSolutionRequest({
          dispatcherId: invokeRequest.dispatcherId,
          invocationRequestId: invocationId,
          nextLoadId: invokeRequest.nextLoadId,
          previousLoadId: invokeRequest.previousLoadId,
          driverGroupId: selectedLoad?.driverGroupId || null,
          driverId: selectedLoad?.driverId,
        });
      setSolutionLoader(true);
      const lsGetSolutionResponse = await trackPromise(
        getLSGetSolution(getSolutionRequest),
        ESecondaryDetailsPanelType.LOAD_BOARD
      );
      setSolutionLoader(false);
      if (lsGetSolutionResponse) {
        if (lsGetSolutionResponse.status === GET_SOLUTION_RESPONSE_STATUS.OK) {
          setLsTripDetails(lsGetSolutionResponse.lsTripDetails);
        }
        if (
          lsGetSolutionResponse.status ===
          GET_SOLUTION_RESPONSE_STATUS.NO_OUTPUT
        ) {
          //server data is not available now, try sending request again
          debouncedGetSolutionHandler(invokeRequest, invocationId);
        }
      } else {
        //error
      }
    },
    [selectedLoad]
  );

  const debouncedGetSolutionHandler = useMemo(() => {
    return debounce(getLSGetSolutionRequestHandler, DEBOUNCE_TIME);
  }, [getLSGetSolutionRequestHandler]);

  const invokeRequestHandler = async () => {
    const invokeRequest = new LoadSearchInvokeRequest({
      dispatcherId: selectedLoad?.dispatcherId,
      driverGroupId: selectedLoad?.driverGroupId || null,
      nextLoadId: selectedLoad?.nextLoadId || null,
      previousLoadId: selectedLoad?.previousLoadId || null,
      selectedLoad: selectedLoad,
    });

    const invocationRequestId = invocationReqIdMetaData[selectedLoad.id];
    if (invocationRequestId === null || invocationRequestId === undefined) {
      setSolutionLoader(true);
      const lsInvokeResponse = await trackPromise(
        getLSInvoke(invokeRequest),
        ESecondaryDetailsPanelType.LOAD_BOARD,
        DEBOUNCE_TIME * 1.5
      );
      if (lsInvokeResponse) {
        setInvocationReqIdMetaData((preState) => ({
          ...preState,
          [selectedLoad.id]: lsInvokeResponse?.invocationRequestId,
        }));

        debouncedGetSolutionHandler(
          invokeRequest,
          lsInvokeResponse?.invocationRequestId
        );
      } else {
        //error
        setSolutionLoader(false);
      }
    } else {
      getLSGetSolutionRequestHandler(invokeRequest, invocationRequestId);
    }
  };

  useEffect(() => {
    if (selectedLoad && selectedLoad?.id) {
      invokeRequestHandler();
    }
  }, [selectedLoad?.id]);

  useEffect(() => {
    return () => {
      debouncedGetSolutionHandler.cancel();
    };
  }, []);

  const tripProps: TripV3Props = useMemo((): TripV3Props => {
    return {
      panelView: ExpandableTimelineV3PanelView.Loadsearch,
      isMultipleExpanded: true,
      onClickEditIconHandler: (item: StopSolutionV3Prop): void => {},
      viewMode: TripViewV3Mode.DefaultView,
      enablePreAndNext: true,
      data: {
        id: '1',
        expanded: true,
        isCompleted: !!lsTripDetails?.tripCompleted,
        headerData: {
          title: 'Loadsearch details',
          renderHeaderContents: (): JSX.Element => <></>,
        },
        stopList: lsTripDetails?.stops
          ? convertTripDetailToStopV3PropList(lsTripDetails.stops)
          : [],
        prevLoadId: lsTripDetails?.prevLoadId,
        nextLoadId: lsTripDetails?.nextLoadId,
      },
    };
  }, [lsTripDetails]);

  return (
    <StyledPopupRoot isTripTab={true}>
      {solutionLoader ? (
        <>
          <Skeleton height={'2rem'} width={'100%'} variant="rectangular" />
          <Skeleton
            height={'3rem'}
            width={'100%'}
            variant="rectangular"
            sx={{ marginTop: '10px', marginBottom: '15px' }}
          />
          <Skeleton
            height={'2rem'}
            width={'100%'}
            variant="rectangular"
            sx={{ marginTop: '10px', marginBottom: '15px' }}
          />
          <Skeleton
            height={'3rem'}
            width={'100%'}
            variant="rectangular"
            sx={{ marginTop: '10px', marginBottom: '15px' }}
          />
        </>
      ) : (
        <>
          <StyledPopupContent>
            {lsTripDetails && <TripV3 {...tripProps} />}
          </StyledPopupContent>
          <PopupActions
            onBidHandler={() => {
              //
            }}
            actionBidBook={actionBidBook}
            onBookHandler={onBookHandler}
          />
        </>
      )}
    </StyledPopupRoot>
  );
};
