import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { GridRowId } from '@mui/x-data-grid';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RadioGroup from '../../../../common/Ui/RadioGroup';
import TextField from '../../../../common/Ui/TextField/TextField';
import FormDialog from '../../../../common/Ui/FormDialog';
import { CloseIcon } from '../../../../ui-kit/components/Assets';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import { SingleAutocompleteForm } from '../../../../common/Ui/SingleAutocomplete';
import {
  addAmountToDriverMapping,
  getDriverIdAmountMap,
  getFundingAccounts,
  getInstructions,
  getMappingIdAmountObject,
} from '../../utils';
import { InvoiceModel } from '../../models/InvoiceModel';
import { fundingItemsSchema } from '../../constants';
import { numberWithThousandSeparator } from '../../../../utils';
import uuid from '../../../../utils/uuid';
import InvoiceActions from '../../InvoiceActions';
import { useTheme } from '@mui/material';

export function SubmitForFundingForm({
  invoices,
}: {
  invoices: InvoiceModel[];
}) {
  const [currentInvoices, setCurrentInvoices] = useState(invoices);
  const existingFundingIds = useRef<Record<string, string>>({});
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fundings',
  });
  const watchFieldArray = watch('fundings');

  useEffect(() => {
    const getDriverMappingDetails = async () => {
      const driverIdAmountMap = getDriverIdAmountMap(invoices);
      const result = await InvoiceActions.getDriverMappingDetails(
        invoices[0].factoringId!,
        Object.keys(driverIdAmountMap)
      );
      if (result) {
        const instructions = getInstructions(Object.values(result));
        setValue('instructions', instructions);
        const resultWithAmount = addAmountToDriverMapping(
          result,
          driverIdAmountMap
        );
        const mappingObj = getMappingIdAmountObject(resultWithAmount);
        Object.keys(mappingObj).forEach((mappingId, index) => {
          const currentMappingObj = mappingObj[mappingId];
          append({
            fundingIdObj: {
              mappingId,
              mappingDescription: `${currentMappingObj.mappingCode} - ${currentMappingObj.mappingDescription}`,
            },
            amount: currentMappingObj.amount,
            id: uuid(),
          });
          existingFundingIds.current[index] = mappingId;
        });
      }
    };
    getDriverMappingDetails();
  }, []);

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const deleteInvoice = (invoiceId: GridRowId) => {
    setCurrentInvoices((invoices) =>
      invoices.filter((i) => i.id !== invoiceId)
    );
  };

  const totalAmountDue: number = useMemo(() => {
    return currentInvoices.reduce(
      (accumulator: number, object: { amountDue: number }) => {
        return accumulator + +object.amountDue;
      },
      0
    );
  }, [currentInvoices]);

  useEffect(() => {
    setValue('totalAmountDue', totalAmountDue);
  }, [totalAmountDue]);

  const handleRemoveFunding = (
    value: { fundingIdObj: string },
    index: number
  ) => {
    remove(index);
    if (!value.fundingIdObj) {
      return;
    }
    if (existingFundingIds.current[index]) {
      delete existingFundingIds.current[index];
    }
  };

  const handleFundingMethodChange = (data: any, index: number) => {
    if (!data) {
      delete existingFundingIds.current[index];
      return;
    }
    if (existingFundingIds.current[index]) {
      delete existingFundingIds.current[index];
    }
    existingFundingIds.current[index] = data.mappingId;
  };

  const currentTheme = useTheme();

  const theme = createTheme(currentTheme, {
    components: {
      // Name of the component
      MuiDataGrid: {
        styleOverrides: {
          // remove borders from root
          root: {
            border: 'none',
            height: 150,
            '.MuiDataGrid-columnHeaderTitleContainerContent ': {
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            },
          },
          row: {
            boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.12)',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          columnSeparator: {
            '&:hover': {
              color: '#c5c8ca',
            },
          },
          cell: {
            border: 'none',
            fontSize: '14px',
            color: '#c5c8ca',
            weight: 400,
            '&:focus': {
              border: 'none',
              outline: 'none',
            },
          },
          columnHeader: {
            '&:focus': {
              outline: 'none',
            },
          },
          columnHeaders: {
            color: '#c5c8ca',
            borderBottom: 'none',
            fontSize: '12px',
            fontWeight: 500,
          },
        },
      },
    },
  });

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <ThemeProvider theme={theme}>
          <DataGridPro
            columns={[
              {
                flex: 1,
                field: 'seqNumber',
                headerName: 'Invoice #',
                sortable: false,
              },
              {
                flex: 1,
                field: 'loadReferenceId',
                headerName: 'Ref #',
                align: 'center',
                sortable: false,
              },
              {
                flex: 1,
                field: 'customer',
                headerName: 'Customer',
                align: 'center',
                sortable: false,
                renderCell: (params) => params.row?.customer.name || '',
              },
              {
                flex: 1,
                field: 'amountDue',
                headerName: 'Due Amount',
                align: 'left',
                sortable: false,
              },
              {
                field: 'actions',
                type: 'actions',
                width: 80,
                getActions: (params) => {
                  if (currentInvoices.length > 1) {
                    return [
                      <GridActionsCellItem
                        key={0}
                        icon={<DeleteIcon sx={{ color: '#c5c8ca' }} />}
                        label="Delete"
                        onClick={() => deleteInvoice(params.id)}
                      />,
                    ];
                  }
                  return [];
                },
              },
            ]}
            rows={currentInvoices}
            showColumnRightBorder={false}
            showCellRightBorder={false}
            disableColumnMenu
            disableColumnReorder
            hideFooter
          />
        </ThemeProvider>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        <Box>
          <Typography variant={'subtitle2'}>Select Funding Time</Typography>
        </Box>
        <Box>
          <Typography variant={'subtitle2'} fontStyle={'italic'}>
            Next day cut off time is 12:00
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <RadioGroup
          row={true}
          control={control}
          name={'sameDay'}
          defaultValue={'nextDay'}
          data={[
            { value: 'sameDay', label: 'Same Day' },
            { value: 'nextDay', label: 'Next Day' },
          ]}
        />
      </Box>
      {controlledFields.map((value, index) => {
        return (
          <Box key={value.id} sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ flex: 3, mr: 4 }}>
              <SingleAutocompleteForm
                disableClear
                control={control}
                name={`fundings.${index}.fundingIdObj`}
                label={'Funding Method'}
                fieldName={'mappingDescription'}
                getOptions={(text, pageNumber) =>
                  getFundingAccounts(
                    text,
                    pageNumber,
                    invoices[0].factoringId!,
                    Object.values(existingFundingIds.current)
                  )
                }
                onChangeCb={(data) => handleFundingMethodChange(data, index)}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                control={control}
                name={`fundings.${index}.amount`}
                label={'Amount'}
                disabled={!value.fundingIdObj}
                onlyNumbers
              />
            </Box>
            <Box
              sx={{
                flex: 0.5,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => handleRemoveFunding(value, index)}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Box>
        );
      })}
      {errors?.fundings?.message && (
        <Box>
          <Typography
            variant={'body2'}
            sx={(theme) => ({
              color: `${theme.palette.error.main} !important`,
            })}
          >
            {errors.fundings.message}
          </Typography>
        </Box>
      )}
      {errors?.amountTotal?.message && (
        <Box>
          <Typography
            variant={'body2'}
            sx={(theme) => ({
              color: `${theme.palette.error.main} !important`,
            })}
          >
            {errors.amountTotal.message}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2,
        }}
      >
        <ButtonImproved
          onClick={() =>
            append({
              fundingIdObj: null,
              amount: '',
              id: uuid(),
            })
          }
          label="+ Add Funding Method"
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant={'h7'} sx={{ mr: 1 }}>
          Total:
        </Typography>
        <Typography variant={'body2'}>{`$${numberWithThousandSeparator(
          totalAmountDue
        )} (${currentInvoices.length} invoices)`}</Typography>
      </Box>
      <Box>
        <TextField
          control={control}
          name={'instructions'}
          label={'Additional Instructions'}
        />
      </Box>
    </>
  );
}

export function SubmitForFundingDialog({
  open,
  onAction,
  onClose,
  invoices,
}: {
  open: boolean;
  onAction: (data: {
    [key: string]: string | number | null | string[];
  }) => void;
  onClose: () => void;
  invoices: InvoiceModel[];
}) {
  return (
    <FormDialog
      data={{
        fundings: [],
        instructions: '',
      }}
      validationSchema={fundingItemsSchema}
      titleText="Submit for Funding"
      actionLabel="Submit"
      open={open}
      onAction={onAction}
      handleClose={onClose}
      contentRenderer={() => <SubmitForFundingForm invoices={invoices} />}
    />
  );
}
