import { ArrowBackOutlined } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  ThemeProvider,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router-dom';
import StorageManager from '../../StorageManager/StorageManager';
import { useThemeResponsive } from '../../common/hooks/useThemeResponsive';
import { regitrationPageStyle } from './RegistrationPageUtils';
import { AuthIntroduction } from './components/AuthIntroduction';
import { EPartner, headerCss } from './constant';
import {
  StyledLoginAuthFromReactivateWrapper,
  SxPropAuthButton,
  getRegistrationStyles,
} from './styles';
// eslint-disable-next-line import/no-unresolved
import { AuthReJoinIcon } from '@/ui-kit/components/Assets';
// eslint-disable-next-line import/no-unresolved
import { letter15 } from '@/components/Subscription/constant';
// eslint-disable-next-line import/no-unresolved
import ButtonImproved from '@/ui-kit/components/ButtonImproved';
import { subscriptionService } from '@/api';
import { getCustomerIp } from './utils';
import { useEffect, useState } from 'react';

const ReactivationFlowPage = () => {
  const { themeLogin, isMobile } = useThemeResponsive();
  const [searchParams] = useSearchParams();
  const user = StorageManager.getUser() || {};
  const [ipAddress, setIpAddress] = useState<string>('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!ipAddress) {
      const ip = await getCustomerIp();
      setIpAddress(ip);
    }
  }, [ipAddress]);

  const navigate = useNavigate();
  const isExistPartnerUser: boolean = Object.values(EPartner).includes(
    searchParams.get('partner')
  );
  const styles = getRegistrationStyles({
    theme: themeLogin,
    isExistPartnerUser: isExistPartnerUser,
  });
  const handleSubscriptionPlan = async () => {
    const payload = {
      organizationId: user.organizationId,
      ipAddress: ipAddress,
    };
    const response = await subscriptionService.ReactivationFlow(payload);
    if (response) {
      navigate('/login');
    }
  };
  return (
    <ThemeProvider theme={themeLogin}>
      <>
        <Box className="Auth-root" sx={styles.authRoot}>
          <Box className="Auth-backgroundImage" sx={styles.authBackgroundImage}>
            <Stack className="Auth-content" spacing={0} sx={styles.authContent}>
              <AuthIntroduction />

              <Box className="formWrapper" sx={styles.formWrapper}>
                <Box
                  sx={{
                    width: '100%',
                    height: 'max-content',
                    overflowX: 'auto',
                  }}
                >
                  <Box
                    className="formWrapperContent"
                    sx={styles.formWrapperContent}
                  >
                    <StyledLoginAuthFromReactivateWrapper>
                      <Box sx={regitrationPageStyle.verificationStyle}>
                        <Box
                          sx={{
                            height: '100%',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <AuthReJoinIcon />
                            <Box sx={headerCss}>Welcome Back!</Box>
                            <Box sx={headerCss}>{user?.organizationName}</Box>
                            <Box sx={headerCss}>
                              Reactivate Now and Save Big!
                            </Box>
                            <Box
                              sx={(theme) => ({
                                ...theme.typography.body1,
                                ...letter15,
                                fontSize: '20px',
                                textAlign: 'center',
                                pt: 4,
                                pl: 3,
                                pr: 3,
                                display: 'grid',
                              })}
                            >
                              <span>
                                Renew your annual agreement for only{' '}
                                <b>$55 per driver per month</b> , down from $75.
                                Get an extra 12-month discount to{' '}
                                <b>$35 per driver per month</b> . Plus, enjoy a{' '}
                                <b>1-month Risk-free Trial</b> with no credit
                                card required!
                              </span>
                              <span>
                                Reactivate your LoadOps subscription now before
                                this offer ends!
                              </span>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-evenly',
                              mt: '50px',
                            }}
                          >
                            <ButtonImproved
                              onClick={() => {
                                handleSubscriptionPlan();
                              }}
                              label={'Rejoin'}
                              size="large"
                              styleProps={{
                                ...SxPropAuthButton,
                                ...themeLogin.typography.h6,
                              }}
                              variant="contained"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </StyledLoginAuthFromReactivateWrapper>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </>
    </ThemeProvider>
  );
};

export default ReactivationFlowPage;
