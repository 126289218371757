import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Grid,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { cloneDeep, debounce } from 'lodash';
import * as React from 'react';
import { financeLoadService } from '../../../../api';
import FormDialog from '../../../../common/Ui/FormDialog';
import { FormDialogMode } from '../../../../common/Ui/FormDialog/constants';
import { ByLoadIdRequest } from '../../../../models/DTOs';
import LoadController from '../../../../subPages/loadsList/LoadController';
import { amountFormat } from '../../../../utils';
import { loadIdStyle } from '../Overview/components/LoadDetails';
import FormContext from './FinanceOverviewFormContext';
import {
  FinanceRevenueShareWarning,
  IFinanceRevenueSharePayLoadType,
  IFinanceRevenueShareRespType,
  IFinanceRevenueShareType,
  IFinanceRevenueShareUIType,
  ILoadRevenueShareDetails,
  ILoadRevenueShareKey,
  ITripDetails,
  RevenueShareModelThemeStyle,
  loadIconStyle,
  shareTextStyle,
  tripDetailsStyle,
} from './FinanceOverviewModel';
import { t } from 'i18next';
import { useTheme } from '@mui/material/styles';
import FinanceRevenueTootTip from './FinanceRevenueTootTip';

export default function FinanceRevenueShare({
  shareData,
  loadType,
  loadId,
}: IFinanceRevenueShareType) {
  const [open, setOpen] = React.useState(false);
  const [revenuePayLoad, setRevenuePayLoad] =
    React.useState<IFinanceRevenueShareRespType>();
  const [isWarningFlag, setWarningFlag] = React.useState(open);
  const { selectEntityType, resetFinanceOverviewData } =
    React.useContext(FormContext);
  const updateFinanceRevenueShareAPI = async (
    payLoad: IFinanceRevenueSharePayLoadType
  ) => {
    const data = await financeLoadService.updateFinanceRevenueShare(payLoad);
    setOpen(false);
    if (data) {
      resetFinanceOverviewData(selectEntityType, false);
    }
  };

  React.useEffect(() => {
    setWarningFlag(false);
  }, [loadId, open]);

  const currentTheme = useTheme();

  return (
    <>
      {open ? (
        <ThemeProvider theme={RevenueShareModelThemeStyle(currentTheme)}>
          <FormDialog
            data={null}
            titleText={t('loadsFinanceChangeRevenueShare')}
            actionLabel="Save"
            mode={FormDialogMode.LIGHT}
            open={open}
            width={580}
            onAction={() => {
              let isFlag = false;
              if (!revenuePayLoad) return;
              revenuePayLoad?.loadRevenueShareDetails.map(
                (tripItem: ILoadRevenueShareDetails) => {
                  if (tripItem.calAmount && tripItem.calAmount != 0) {
                    isFlag = true;
                  }
                }
              );
              if (isFlag) {
                setWarningFlag(true);
                return null;
              }
              const payLoad: IFinanceRevenueSharePayLoadType = {
                organizationId: revenuePayLoad.organizationId,
                loadIdToTripRevenueShare: {},
              };
              revenuePayLoad?.loadRevenueShareDetails?.map(
                (tripItem: { tripDetails: ITripDetails[]; loadId: string }) => {
                  const collectItem: ILoadRevenueShareKey = {};
                  tripItem.tripDetails?.map(
                    (item: {
                      tripId: string | number;
                      revenueSharePercentage: number;
                    }) => {
                      collectItem[item.tripId] = item.revenueSharePercentage;
                    }
                  );
                  payLoad.loadIdToTripRevenueShare[tripItem.loadId] =
                    collectItem;
                }
              );
              updateFinanceRevenueShareAPI(payLoad);
            }}
            handleClose={() => {
              setOpen(false);
            }}
            validationSchema={null}
            contentRenderer={() => (
              <FinanceRevenueShareUI
                loadType={loadType}
                loadId={loadId}
                financeRevenueShareupdated={setRevenuePayLoad}
                isSubmit={isWarningFlag}
              />
            )}
          ></FormDialog>
        </ThemeProvider>
      ) : (
        <FinanceRevenueTootTip
          onModelOpen={() => {
            setOpen(true);
          }}
          shareData={shareData}
        />
      )}
    </>
  );
}

export function FinanceRevenueShareUI({
  loadType,
  loadId,
  isSubmit = false,
  financeRevenueShareupdated,
}: IFinanceRevenueShareUIType) {
  const [revenuedata, setRevenuedata] = React.useState<
    IFinanceRevenueShareRespType | undefined
  >();

  React.useEffect(() => {
    const handleLoadTripAction = () => {
      const requestData = new ByLoadIdRequest();
      requestData[loadType != 'MANIFEST' ? 'loadId' : 'manifestId'] = loadId;
      LoadController.instance().getFinanceRevenueShareData(
        requestData,
        async (result: IFinanceRevenueShareRespType) => {
          if (result) {
            setRevenuedata(result);
            financeRevenueShareupdated(result);
          }
        }
      );
    };
    handleLoadTripAction();
  }, [loadId]);

  const getMarginValue = (total: number, sumValue: number) => {
    const calValue = total - sumValue;
    if (calValue != 0 && calValue < -2) {
      return (calValue + 1).toFixed(0);
    } else if (calValue != 0 && calValue > 5) {
      return calValue.toFixed(0);
    } else {
      return 0;
    }
  };

  const onChange = (
    value: number,
    trip: ITripDetails,
    load: ILoadRevenueShareDetails,
    isPercent: boolean
  ) => {
    const changeValue = Number(value);
    const filterData = load.tripDetails.map((item: ITripDetails) => {
      if (trip.tripId === item.tripId) {
        if (isPercent) {
          item.revenueSharePercentage = changeValue;
          item.revenueShare = (load.amount * changeValue) / 100;
        } else {
          item.revenueSharePercentage = (changeValue * 100) / load.amount;
          item.revenueShare = changeValue;
        }
        if (
          item.revenueShare > load.amount ||
          item.revenueSharePercentage > 100
        ) {
          item.isMore = true;
        } else {
          item.isMore = false;
        }
        return item;
      }
      return item;
    });
    const originData = revenuedata;
    const updatedList = originData?.loadRevenueShareDetails.map(
      (tripItem: ILoadRevenueShareDetails) => {
        if (tripItem.loadId === load.loadId) {
          tripItem.tripDetails = filterData;
        }
        const sumAmout = tripItem?.tripDetails.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.revenueShare,
          0
        );
        tripItem.calAmount = getMarginValue(tripItem.amount, sumAmout);
        return tripItem;
      }
    );
    originData.loadRevenueShareDetails = updatedList;
    setRevenuedata(cloneDeep(originData));
    financeRevenueShareupdated(cloneDeep(originData));
  };
  const debouncedOnChange = debounce(onChange, 100);
  return (
    <>
      {revenuedata?.loadRevenueShareDetails?.map(
        (item: ILoadRevenueShareDetails) => {
          return (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                style={{
                  padding: '15px 0px 0px 5px',
                }}
              >
                <Typography sx={loadIdStyle}>
                  Load Id: {item.seqNumber}
                </Typography>
                <Typography sx={shareTextStyle}>
                  {amountFormat(item.amount || 0)}
                </Typography>
              </Stack>

              {item?.tripDetails?.map((record: ITripDetails) => {
                return (
                  <Grid
                    container
                    spacing={2}
                    key={record.seqNumber}
                    style={tripDetailsStyle}
                  >
                    <Grid item xs={5}>
                      <Box sx={{ display: 'inline-grid' }}>
                        <Box sx={{ display: 'inline-flex' }}>
                          <Typography sx={loadIdStyle}>
                            {t('tripId')}:
                            <Typography sx={shareTextStyle}>
                              {record.seqNumber}
                            </Typography>
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'inline-flex' }}>
                          <Typography sx={loadIconStyle}>
                            {record.origin}
                            <ArrowForwardIcon sx={loadIconStyle} />{' '}
                            {record.destination}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            id="standard-basic"
                            label={
                              <>
                                Percentage (%)
                                <Typography
                                  component="span"
                                  sx={(theme) => ({
                                    color: `${theme.palette.error.main} !important`,
                                  })}
                                >
                                  *
                                </Typography>
                              </>
                            }
                            value={
                              record.revenueSharePercentage.toFixed(0) || 0
                            }
                            type="number"
                            variant="standard"
                            disabled={item?.tripDetails?.length === 1}
                            helperText={
                              record.isMore &&
                              'Percentage should not be more than 100%.'
                            }
                            error={record.isMore || false}
                            onChange={(e) => {
                              debouncedOnChange(
                                e.target.value,
                                record,
                                item,
                                true
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="standard-basic"
                            helperText={
                              record.isMore &&
                              `Amount should not be more than ${amountFormat(
                                item.amount || 0
                              )}.`
                            }
                            error={record.isMore || false}
                            label={
                              <>
                                Revenue ($)
                                <Typography
                                  component="span"
                                  sx={(theme) => ({
                                    color: `${theme.palette.error.main} !important`,
                                  })}
                                >
                                  *
                                </Typography>
                              </>
                            }
                            variant="standard"
                            disabled={item?.tripDetails?.length === 1}
                            type="number"
                            value={
                              (Number.isInteger(Number(record.revenueShare))
                                ? record.revenueShare
                                : record.revenueShare.toFixed(2)) || 0
                            }
                            onChange={(e) => {
                              debouncedOnChange(
                                e.target.value,
                                record,
                                item,
                                false
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              {item.calAmount != 0 && (
                <Grid>
                  {isSubmit && item.calAmount && item.calAmount > 0 && (
                    <Typography sx={{ color: '#F44336', fontSize: '12px' }}>
                      {FinanceRevenueShareWarning.remainingAmount}
                      {amountFormat(Math.abs(item.calAmount || 0))}.
                    </Typography>
                  )}
                  {isSubmit && item.calAmount && item.calAmount < 0 && (
                    <Typography sx={{ color: '#F44336', fontSize: '12px' }}>
                      {FinanceRevenueShareWarning.additionalAmount}
                      {amountFormat(Math.abs(item.calAmount || 0))}.
                    </Typography>
                  )}
                </Grid>
              )}
            </>
          );
        }
      )}
    </>
  );
}
