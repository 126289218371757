import React, { FC } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { cargoChiefLogo as CargoChiefLogo } from '../../../../../ui-kit/components/Assets';
import IconsComponent from '../../../../../ui-kit/components/IconsComponent';
import { MarketRateResponse } from '../../../../../types/LoadboardTypes';
import { currencyDisplay } from '../../../../../utils/grid.utils';

const secondaryColor = 'rgba(0, 17, 34, 0.6)';
const primaryColor = 'rgba(4, 0, 34, 0.75)';

interface MarketRateInfoProps {
  onClose: () => void;
  data: MarketRateResponse;
}

const MarketRateInfo: FC<MarketRateInfoProps> = ({ onClose, data }) => {
  return (
    <Box maxWidth={430} p={3}>
      <Stack spacing={3}>
        <Stack spacing={2} direction="row">
          <Stack spacing={1} flexGrow={1}>
            <Typography fontSize={20} fontWeight={500}>
              Lane Rate
            </Typography>
            <Stack direction="row" spacing={1} alignItems="flex-end">
              <Typography fontSize={8} fontWeight={400} color={secondaryColor}>
                Powered by
              </Typography>
              <Box fontSize={8} fontWeight={400} color="#001122">
                <CargoChiefLogo />
              </Box>
            </Stack>
          </Stack>
          <OverallKPI
            name="Fuel/Gal"
            value={`${currencyDisplay(data.fuelCostPerGallon)}`}
          />
          <OverallKPI
            name="Trip Miles"
            value={data.laneMiles?.toString() || ''}
          />
          <Box>
            <IconButton onClick={onClose} size="small">
              <IconsComponent source="MUIcons" iconName="Close" />
            </IconButton>
          </Box>
        </Stack>
        <Stack spacing={2}>
          <KPI
            name="All In"
            title={`${currencyDisplay(data.allInCost)}`}
            subTitle={`${currencyDisplay(
              data.allInCostLow
            )} - ${currencyDisplay(data.allInCostHigh)}`}
          />
          <KPI
            name="Per Mile"
            title={`${currencyDisplay(data.allInCostPerMile)}`}
            subTitle={`${currencyDisplay(
              data.allInCostLowPerMile
            )} - ${currencyDisplay(data.allInCostHighPerMile)}`}
          />
        </Stack>
      </Stack>
      <Typography
        mt={2}
        textAlign="justify"
        variant="subtitle2"
        fontSize={10}
        lineHeight={1.2}
      >
        Cargo Chief C4 Pricing helps carriers determine which lanes to run, and
        the fair price for their full truckload capacity with real-time accurate
        full truckload rates generated by advanced algorithms leveraging the
        aggregation of millions of loads from our digital freight matching, and
        from our payment processor and factoring partners.
      </Typography>
    </Box>
  );
};

const KPI: FC<{ name: string; title: string; subTitle: string }> = ({
  name,
  title,
  subTitle,
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography color={primaryColor} fontSize={20} fontWeight={600}>
        {name}
      </Typography>
      <Box
        textAlign="right"
        bgcolor="rgba(102, 158, 255, 0.12)"
        borderRadius={1}
        px={2}
        py={1}
      >
        <Typography color="#2B64CB" fontSize={20} fontWeight={600}>
          {title}
        </Typography>
        <Typography color={secondaryColor} fontSize={14} fontWeight={500}>
          {subTitle}
        </Typography>
      </Box>
    </Stack>
  );
};

const OverallKPI: FC<{ name: string; value: string }> = ({ name, value }) => {
  return (
    <Stack textAlign="right">
      <Typography color={secondaryColor} fontSize={12} fontWeight={400}>
        {name}
      </Typography>
      <Typography color={primaryColor} fontSize={14} fontWeight={600}>
        {value}
      </Typography>
    </Stack>
  );
};

export default MarketRateInfo;
