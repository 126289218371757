import moment, { Moment } from 'moment';
import { ReactNode } from 'react';
import {
  getOrganizationId,
  getUserId,
} from '../../../common/TimeoffDialog/utils';
import {
  CustomerSummaryDTO,
  LocationAddressListDTO,
  Terminal,
} from '../../../models';
import { Model } from '../../../models/common/Model';
import { getCurrentDateTime } from '../../../utils';
import { EInvoiceType } from '../constants';
import { revertUserDefinedItemsTypes } from '../utils';

export class LineItem {
  id!: string;
  amount!: number;
  description!: string;
  itemId!: string;
  itemCode!: string;
  itemName!: string;
  itemType!: string;
  quantity!: number;
  rate!: number;
  type!: string;
  deduction!: boolean;
  defaultItemCode?: string;
}

export class ReceivePayment {
  id!: string;
  amount!: number;
  paymentDate!: string;
  referenceNumber!: string;
  sequence!: string;
}

export class DocumentType {
  documentId!: string;
  documentType!: string;
  fileName!: string;
  fileSize!: number;
  contentType!: string;
  loadId!: string;
}
export class FactoringDetailsType {
  factoringId!: string;
  factoringStatus!: string;
  useFactoring!: boolean;
  factoringInvoiceId!: string;
  factoringDocumentStatus!: string;
  factoringInvoiceUpdateDate!: string;
  notes!: string;
  factoringNotes!: string;
  factoringScheduleId!: string;
  factoringChargePercent!: string;
  constructor(dto: any) {
    this.factoringId = dto.factoringId || null;
    this.factoringStatus = dto.factoringStatus || null;
    this.useFactoring = dto.useFactoring || null;
    this.factoringInvoiceId = dto.factoringInvoiceId || null;
    this.factoringDocumentStatus = dto.factoringDocumentStatus || null;
    this.factoringInvoiceUpdateDate = dto.factoringInvoiceUpdateDate || null;
    this.notes = dto.notes || null;
    this.factoringNotes = dto.factoringNotes || null;
    this.factoringScheduleId = dto.factoringScheduleId || null;
    this.factoringChargePercent = dto.factoringChargePercent || null;
  }
}

// @TODO davits remove ? from customer
export class InvoiceModel extends Model {
  // list props
  id: string;
  invoiceId: string;
  seqNumber: string;
  customer: CustomerSummaryDTO;
  customerId: string;
  terminalId: string;
  driverName: string;
  factoringCompany: string | null;
  total: number;
  factoringAmount: number;
  totalFactoringCharge: number;
  amountDue: number;
  factoringStatus: string | null;
  stops: LocationAddressListDTO[];
  missingDocuments: string[];
  loadReferenceId: string;
  terminal: Terminal;
  loadStatus: string;
  completeDate: string;
  emailSendDate: string;
  loadSeqNumber: string;
  updateDate: string;
  masterInvoiceId: string;
  invoicedOn: string;
  paidOn: string;
  notes: string;
  groupDetails: DriverGroupDetailType[] | null;
  assignedDrivers: string[] | null;
  // details props
  lineItems: LineItem[];
  receivedPayments: ReceivePayment[];
  customerContactId: string;
  invoiceTerm: string;
  invoiceTermValue: number;
  loadedMiles: number;
  emptyMiles: number;
  factoringCharge: number | null;
  factoringChargePercent: number | null;
  factoringId: string | null;
  useFactoring: boolean;
  factoringInvoiceId: string | null;
  factoringCompanyEmail: string | null;
  factoringDocumentStatus: string | null;
  factoringNotes: string | null;
  factoringInvoiceUpdateDate: string | null;
  factoringScheduleId: string | null;
  loadId: string;
  isFactoringInvalid: boolean;
  hasPayment: boolean;
  customerMapped: boolean;
  synchStatus: string | null;
  assignedDocuments: DocumentType[];
  warnings: { name: string; time: number }[];
  noteDetails: { userId: string; updateTime: Moment };
  factoringProviderId: number | null;
  sourceFactoringNotes: string | null;
  pinned: boolean;
  nonInvoiceable: boolean;
  // document props
  billOfLadingMandatory: boolean;
  proofOfDeliveryMandatory: boolean;
  childInvoice: boolean;
  status: string;
  destinationState: string;
  destinationCity: string;
  originCity: string;
  originState: string;
  factoringDetails: FactoringDetailsType;
  childInvoices: any;
  invoiceReferenceId: string;
  masterInvoiceSeqNumber: string;
  invoiceType: EInvoiceType;
  messageOnInvoice: string;
  masterInvoiceReferenceId: string;
  referenceId: string;
  onHold: boolean;
  onHoldNote?: string;
  source?: string;
  tags?: Array<any>;
  milesManuallyUpdated?: boolean | null;
  mileageUpdatedDate?: string | Date;
  mileageUpdatedBy?: string;
  isMilesManuallyUpdated?: boolean | null;
  constructor(dto: any) {
    // list props
    super(dto);
    this.id = dto.id || dto.invoiceId;
    this.seqNumber = dto.seqNumber;
    this.customer = dto.customer;
    this.customerId = dto.customer?.id;
    this.driverName = dto.driverName;
    this.factoringCompany = dto.factoringDetails?.factoringCompany || null;
    this.total = dto.total;
    this.amountDue = dto.amountDue;
    this.factoringStatus = dto.factoringDetails?.factoringStatus || null;
    this.stops = dto.stops || [];
    this.missingDocuments = dto.missingDocuments || [];
    this.loadReferenceId = dto.loadReferenceId;
    this.terminal = dto.terminal;
    this.terminalId = dto.terminalId;
    this.loadStatus = dto.loadStatus;
    this.completeDate = dto.completeDate;
    this.loadSeqNumber = dto.loadSeqNumber;
    this.updateDate = dto.updateDate;
    this.invoicedOn = dto.invoicedOn;
    this.masterInvoiceId = dto.masterInvoiceId;
    this.masterInvoiceReferenceId = dto.masterInvoiceReferenceId;
    this.childInvoice = dto.childInvoice;
    this.nonInvoiceable = dto.nonInvoiceable;
    this.masterInvoiceSeqNumber = dto.masterInvoiceSeqNumber;
    this.paidOn = dto.paidOn;
    this.notes = dto.notes;
    this.emailSendDate = dto.emailSendDate;
    this.groupDetails = dto.groupDetails || [];
    this.assignedDrivers = dto.assignedDrivers || [];
    // details props
    this.lineItems = dto.lineItems;
    this.receivedPayments = dto.receivedPayments || [];
    this.customerContactId =
      dto.customerContactId ||
      dto.customer?.contacts?.find((c: { isPrimary: boolean }) => c.isPrimary)
        ?.id ||
      null;
    this.invoiceTerm = dto.invoiceTerm;
    this.invoiceTermValue = dto.invoiceTermValue;
    this.loadedMiles = dto.loadedMiles;
    this.emptyMiles = dto.emptyMiles;
    this.factoringCharge = dto.factoringDetails?.factoringCharge ?? null;
    this.factoringChargePercent =
      dto.factoringDetails?.factoringChargePercent ?? null;
    this.factoringId = dto.factoringDetails?.factoringId || null;
    this.useFactoring = dto.factoringDetails?.useFactoring;
    this.factoringInvoiceId = dto.factoringDetails?.factoringInvoiceId || null;
    this.factoringCompanyEmail =
      dto.factoringDetails?.factoringCompanyEmail || null;
    this.factoringDocumentStatus =
      dto.factoringDetails?.factoringDocumentStatus || null;
    this.factoringNotes = dto.factoringDetails?.factoringNotes || null;
    this.factoringInvoiceUpdateDate =
      dto.factoringDetails?.factoringInvoiceUpdateDate || null;
    this.factoringScheduleId =
      dto.factoringDetails?.factoringScheduleId || null;
    this.loadId = dto.loadId || '';
    this.isFactoringInvalid = dto.factoringDetails?.isFactoringInvalid;
    this.customerMapped = dto.factoringDetails?.customerMapped;
    this.synchStatus = dto.synchStatus;
    this.assignedDocuments = dto.assignedDocuments || [];
    this.warnings = dto.warnings || [];
    this.noteDetails = dto.noteDetails;
    this.factoringProviderId =
      dto.factoringDetails?.factoringProviderId || null;
    this.sourceFactoringNotes =
      dto.factoringDetails?.sourceFactoringNotes || null;
    this.pinned = dto.pinned;
    // document props
    this.billOfLadingMandatory = dto.billOfLadingMandatory;
    this.proofOfDeliveryMandatory = dto.proofOfDeliveryMandatory;
    this.status = dto.status;
    this.factoringDetails = dto.factoringDetails?.factoringDetails || null;
    this.factoringAmount = dto.factoringAmount || 0;
    this.destinationState = dto.destinationState || '';
    this.destinationCity = dto.destinationCity || '';
    this.originCity = dto.originCity || '';
    this.originState = dto.originState || '';
    this.invoiceId = dto.invoiceId || '';
    this.messageOnInvoice = dto.messageOnInvoice || '';
    this.invoiceReferenceId = dto.invoiceReferenceId || '';
    this.invoiceType = dto.invoiceType;
    this.childInvoices = dto.childInvoices || [];
    this.hasPayment = dto.hasPayment;
    this.referenceId = dto.referenceId;
    this.totalFactoringCharge = dto.totalFactoringCharge;
    this.onHold = !!dto.onHold;
    this.onHoldNote = dto.onHoldNote || '';
    this.tags = dto.tags || [];
    this.source = dto.source;
    this.milesManuallyUpdated = dto?.milesManuallyUpdated;
    this.mileageUpdatedDate = dto?.mileageUpdatedDate;
    this.mileageUpdatedBy = dto?.mileageUpdatedBy;
    this.isMilesManuallyUpdated = null;
  }
}

export type SendEmailRequestType = {
  emailSendingRequestDTO: {
    cc: string[];
    to: string[];
    invoiceList: { id: string }[];
    subject: string;
    message: string;
    type: string;
    documentList: { id: string }[];
  }[];
  html: boolean;
  combinePdfDocuments: boolean;
};

export type EmailFormType = {
  to: string[];
  cc: string[];
  subject: string;
  message: string;
  sendInvoice: boolean;
  rateConfirmation: boolean;
  billOfLading: boolean;
  proofOfDelivery: boolean;
  other: boolean;
  markAsInvoiced: boolean;
  documentIds: string[];
  invoiceId: string;
  note: string;
};

export type BulkActionConfigType = {
  title: string;
  action: string;
  subTitle?: string;
  contentRenderer?: () => ReactNode;
};

export type FactoringType = {
  address: string;
  archive: boolean;
  countryCode: string;
  displayName: string;
  email: string;
  extension: string;
  factoringCharge: string;
  factoringCompany: string;
  fax: string;
  faxCountryCode: string;
  faxExtension: string;
  id: string;
  invoiceFactoringNote: string;
  isFactoringInvalid: boolean;
  nameOnDocuments: string;
  organizationId: number;
  phoneNumber: string;
  primary: boolean;
  providerId: number;
};

export type FactoringDataDTOType = {
  invoiceId?: string;
  factoringInvoiceId: string;
  factoringStatus: string;
  factoringDocumentStatus: string;
  loadStatus: string[];
  factoringNotes: string;
  factoringScheduleId: string;
  factoringInvoiceUpdateDate: string;
  invoicedOn: string;
  paidOn: string;
};

export type FactoringErrorDTOType = {
  invoiceId: string;
  loadId: string;
  invoiceNumber: string;
  message: string;
};

export type FactoringActionReturnType = {
  factoringDataDTO: FactoringDataDTOType[];
  factoringErrorDTO: FactoringErrorDTOType[];
};

export type InvoiceTotalsType = {
  totalAmount: number;
  totalDueAmount: number;
  totalFactoringCharge: number;
};
export type InvoiceLineItemFormRequiredFields = {
  type?: boolean;
  quantity?: boolean;
  rate?: boolean;
};

export type ReceivePaymentFormRequiredFields = {
  amount?: boolean;
};
export class GetInvoiceNotesResponse {
  message!: string;
}
export type CustomerContactType = {
  id: number;
  fullName: string;
  isPrimary: boolean;
};

export type DriverGroupDetailType = {
  drivers: { name: string; id: string }[];
  displayName: string;
};
export class GetInvoiceGroupDetailResponse {
  respones!: DriverGroupDetailType[];
}

export class InvoiceCreatModel {
  // list props
  organizationId: string;
  invoiceReferenceId: string;
  lineItems: LineItem[];
  receivedPayments: ReceivePayment[];
  invoiceType: string;
  invoiceTerm: string;
  invoiceTermValue: number;
  notes: string;
  noteDetails: { userId: string; updateTime: string | Moment };
  terminalId: string;
  messageOnInvoice: string;
  customerId: string;
  customerContactId: string;
  childInvoices: childInvoices[];
  invoicedOn: string | Moment;

  constructor(dto: any) {
    this.organizationId = getOrganizationId();
    this.invoiceReferenceId = dto.invoiceReferenceId;
    this.lineItems = revertUserDefinedItemsTypes(dto.lineItems ?? []);
    this.receivedPayments = dto.receivedPayments || [];
    this.invoiceType = dto.childInvoices?.length > 0 ? 'MASTER' : 'CUSTOM';
    this.invoiceTerm = dto.invoiceTerm || '';
    this.invoiceTermValue = dto.invoiceTermValue;
    this.notes = dto.notes || '';
    this.terminalId = dto.terminal?.id || null;
    this.messageOnInvoice = dto.messageOnInvoice || '';
    this.customerId = dto.customerId || '';
    this.customerContactId = dto.customerContactId || '';
    this.invoicedOn = getCurrentDateTime();
    this.noteDetails = {
      userId: getUserId(),
      updateTime: getCurrentDateTime(),
    };
    this.childInvoices =
      dto.childInvoices?.map(
        (data: ChildInvoices) => new ChildInvoices(data)
      ) || [];
  }
}

export class ChildInvoices {
  // list props
  invoiceId: string;
  proofOfDeliveryMandatory: boolean;
  billOfLadingMandatory: boolean;
  loadedMiles: number;
  emptyMiles: number;
  lineItems: LineItem[];
  constructor(dto: any) {
    this.proofOfDeliveryMandatory = dto.proofOfDeliveryMandatory;
    this.billOfLadingMandatory = dto.billOfLadingMandatory;
    this.loadedMiles = dto.loadedMiles;
    this.emptyMiles = dto.emptyMiles;
    this.lineItems = revertUserDefinedItemsTypes(dto.lineItems ?? []);
    this.invoiceId = dto.invoiceId || '';
  }
}

export class InvoiceUpdateModel {
  // list props
  organizationId: string;
  invoiceReferenceId: string;
  lineItems: LineItem[];
  receivedPayments: ReceivePayment[];
  // invoiceType: string;
  invoiceId: string;
  invoiceTerm: string;
  invoiceTermValue: number;
  notes: string;
  noteDetails: { userId: string; updateTime: string | Moment };
  terminalId: string;
  messageOnInvoice: string;
  customerId: string;
  customerContactId: string;
  childInvoices: childInvoices[];
  invoicedOn: string | Moment;
  shouldRemoveFromMaster: boolean;
  proofOfDeliveryMandatory: boolean;
  childInvoice: boolean;
  billOfLadingMandatory: boolean;
  paidOn: string;
  factoringDetails: FactoringDetailsType | null;
  emptyMiles: number;
  status: string;
  loadedMiles: number;
  milesManuallyUpdated?: boolean;

  constructor(dto: any) {
    this.organizationId = getOrganizationId();
    this.invoiceReferenceId = dto.invoiceReferenceId;
    this.lineItems = revertUserDefinedItemsTypes(dto.lineItems ?? []);
    this.receivedPayments = dto.receivedPayments || [];
    this.invoiceTerm = dto.invoiceTerm || '';
    this.invoiceTermValue = dto.invoiceTermValue;
    this.notes = dto.notes || '';
    this.terminalId = dto.terminal?.id || null;
    this.messageOnInvoice = dto.messageOnInvoice || '';
    this.customerId = dto.customerId || '';
    this.customerContactId = dto.customerContactId || '';
    this.invoicedOn = moment(dto.invoicedOn);
    this.noteDetails = dto.noteDetails;
    this.invoiceId = dto.invoiceId;
    this.proofOfDeliveryMandatory = dto.proofOfDeliveryMandatory;
    this.paidOn = dto.paidOn;
    this.shouldRemoveFromMaster = false;
    this.billOfLadingMandatory = dto.billOfLadingMandatory;
    this.factoringDetails =
      dto.invoiceType === 'LOAD' ? new FactoringDetailsType(dto) : null;
    this.emptyMiles = dto.emptyMiles || 0;
    this.childInvoice = dto.childInvoice;
    this.status = dto.status;
    this.loadedMiles = dto.loadedMiles || 0;
    this.childInvoices =
      dto.childInvoices?.map(
        (data: ChildInvoices) => new ChildInvoices(data)
      ) || [];
    this.milesManuallyUpdated = dto?.isMilesManuallyUpdated;
  }
}
