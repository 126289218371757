import Grid from '@mui/material/Grid';
import ButtonImproved from '../../../../ui-kit/components/ButtonImproved';
import MultilineCell from '../../../../ui-kit/components/MultilineCell';
import IconsComponent from '../../../../ui-kit/components/IconsComponent';
import { emptyStateText } from '../constants/constants';
import { emptyStyles } from '../styles/styles';
import { terminalService } from '../../../../api';
import { useRootStore } from '../../../../store/root-store/rootStateContext';
import { observer } from 'mobx-react';
import { GetTerminalListDTO } from '../../../../models';
import {
  AXELE_PERMISSION_TYPE,
  Permission,
} from '../../../../common/Permission';
import { terminalPage } from '../../../../common/PendoClassIDs/constants';
import StorageManager from '../../../../StorageManager/StorageManager';
import { UpdateTerminalModeRequest } from '../../../../models/DTOs/Terminal/Requests';

const EmptyState = (props: any) => {
  const { setTerminalEnabled } = useRootStore();
  const userStorage = StorageManager.getUser();

  const updateTerminalMode = async () => {
    if (!userStorage) return;

    const payload = new UpdateTerminalModeRequest(true);
    const terminalModeResponse = await terminalService.updateTerminalMode(
      payload
    );
    if (!terminalModeResponse) return;
    const {
      name,
      email,
      addressData: {
        address,
        city,
        state,
        streetAddress,
        streetAddress2,
        zipcode,
      },
    } = terminalModeResponse;

    setTerminalEnabled(true);
    localStorage.setItem('terminalEnabled', JSON.stringify(true));

    const request = new GetTerminalListDTO();
    request.addUserIdToParams();
    const terminalListResponse = await terminalService.getTerminalList(request);
    if (terminalListResponse?.content.length == 0) {
      const createPayload = {
        addressData: {
          fullAddress: '',
          address,
          city,
          state,
          streetAddress,
          streetAddress2,
          center: {
            lat: '',
            lng: '',
          },
          zipcode,
        },
        companyName: name,
        contactPerson: {
          countryCode: userStorage.countryCode
            ? userStorage.countryCode
            : 'USA',
          email: email,
          extension: userStorage.extension,
          fax: userStorage.fax ? userStorage.fax : null,
          faxCountryCode: userStorage.countryCode
            ? userStorage.countryCode
            : 'USA',
          faxExtension: userStorage.faxExtension
            ? userStorage.faxExtension
            : null,
          firstName: userStorage.firstname + ' ' + userStorage.lastname,
          phone: userStorage.mobileno,
        },
        dot: userStorage.dot,
        isPrimary: true,
        mc: userStorage.mc ? userStorage.mc : null,
        organizationId: userStorage.organizationId,
      };
      await terminalService.createTerminal(createPayload);
    }
    window.location.reload();
  };

  const enableTerminalPermissions: {
    [key: string]: AXELE_PERMISSION_TYPE[];
  } = {
    AC: [AXELE_PERMISSION_TYPE.TERMINALS_ADD],
    DR: [AXELE_PERMISSION_TYPE.TERMINALS_ADD],
    SP: [AXELE_PERMISSION_TYPE.TERMINALS_ADD],
    PD: [AXELE_PERMISSION_TYPE.TERMINALS_ADD],
    DI: [AXELE_PERMISSION_TYPE.TERMINALS_ADD],
    NFD: [AXELE_PERMISSION_TYPE.TERMINALS_ADD],
  };

  return (
    <Grid style={emptyStyles.mainContainer}>
      <Grid container>
        <Grid item xs={6}>
          <MultilineCell
            title={emptyStateText.title}
            subtitle={emptyStateText.subTitle}
            titleStyle={emptyStyles.emptyTitle}
            subtitleStyle={emptyStyles.emptySub}
            alignTitle="left"
            alignSubtitle="left"
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Permission includes={enableTerminalPermissions[userStorage?.roleCode]}>
        <ButtonImproved
          id={terminalPage + 'updateTerminalsMode'}
          size="large"
          variant="contained"
          label={emptyStateText.enableButton}
          startIcon={
            <IconsComponent iconName="PowerSettingsNew" source="MUIcons" />
          }
          styleProps={emptyStyles.button}
          onClick={() => updateTerminalMode()}
        />
      </Permission>
    </Grid>
  );
};

export default observer(EmptyState);
