import {
  documentService,
  maintenanceAssetTractorService,
  maintenanceEquipmentService,
  maintenanceHistoryService,
  maintenanceItemService,
  maintenanceVendorService,
  vendorService,
} from '../../../api';
import { ServiceError } from '../../../api/interfaces';
import {
  AssignDocumentRequest,
  CreateDocumentOnlyRequest,
  CreateVendorRequest,
  DeleteDocumentRequest,
} from '../../../models';
import { IMaintenanceAssetTractor } from '../../../models/DTOs/maintenance/maintenanceAssetTractor/Model';
import { GetMaintenanceAssetTractorOneParams } from '../../../models/DTOs/maintenance/maintenanceAssetTractor/Requests';
import { MaintenanceEquipment } from '../../../models/DTOs/maintenance/maintenanceEquipment/Model';
import { GetMaintenanceEquipmentByOrganizationIdRequest } from '../../../models/DTOs/maintenance/maintenanceEquipment/Requests';
import {
  IAttachment,
  MaintenanceHistory,
} from '../../../models/DTOs/maintenance/maintenanceHistory/Model';
import {
  CreateMaintenanceHistoryRequest,
  DeleteBulkMaintenanceHistoryRequest,
  ExportMaintenanceHistoryFilterPayload,
  ExportMaintenanceHistoryFilterQueryParams,
  GetMaintenanceHistoryListCalendarQueryParams,
  PaginatedMaintenanceHistoryListRequest,
  UpdateMaintenanceHistoryRequest,
} from '../../../models/DTOs/maintenance/maintenanceHistory/Requests';
import { MaintenanceItem } from '../../../models/DTOs/maintenance/maintenanceItem/Model';
import { MaintenanceVendor } from '../../../models/DTOs/maintenance/maintenanceVendor/Model';
import { PaginatedMaintenanceVendorListRequest } from '../../../models/DTOs/maintenance/maintenanceVendor/Requests';
import { PaginatedResult, ViewMetaData } from '../../../types';
import { downloadFile } from '../../../utils/Doc';
import { IMaintenanceRecordFilters } from '../constants/types';

const preGetArrayParams = (viewMetaData: ViewMetaData, fieldName: string) => {
  if (viewMetaData[fieldName]?.length > 0) {
    return viewMetaData[fieldName];
  }
  return null;
};
const getFilteredRequest = (
  viewMetaData: ViewMetaData,
  request: PaginatedMaintenanceHistoryListRequest
): PaginatedMaintenanceHistoryListRequest => {
  const { startDate, endDate, sort } = viewMetaData;

  Object.assign(request, {
    maintenanceItems: preGetArrayParams(viewMetaData, 'maintenanceItems'),
    assetId: preGetArrayParams(viewMetaData, 'assetId'),
    equipmentTypes: preGetArrayParams(viewMetaData, 'equipmentTypes'),
    vendor: preGetArrayParams(viewMetaData, 'vendor'),
    expenseIds: preGetArrayParams(viewMetaData, 'expenseIds'),
    startDate,
    endDate,
    sort,
  });
  return request;
};

export const fetchPaginatedMaintenanceHistoryList = async (
  queryParams: ViewMetaData
): Promise<PaginatedResult<MaintenanceHistory> | null> => {
  const request = new PaginatedMaintenanceHistoryListRequest(queryParams);
  const response = await maintenanceHistoryService.getPaginatedList(
    getFilteredRequest(queryParams, request)
  );
  return response instanceof ServiceError ? null : response;
};

const getFilteredCalendarRequest = (
  viewMetaData: ViewMetaData,
  request: GetMaintenanceHistoryListCalendarQueryParams
): GetMaintenanceHistoryListCalendarQueryParams => {
  const { startDate, endDate } = viewMetaData;
  Object.assign(request, {
    maintenanceItems: preGetArrayParams(viewMetaData, 'maintenanceItems'),
    assetId: preGetArrayParams(viewMetaData, 'assetId'),
    equipmentTypes: preGetArrayParams(viewMetaData, 'equipmentTypes'),
    vendor: preGetArrayParams(viewMetaData, 'vendor'),
    expenseIds: preGetArrayParams(viewMetaData, 'expenseIds'),
    startDate,
    endDate,
  });
  return request;
};
export const fetchMaintenanceHistoryListCalendar = async (
  queryParams: ViewMetaData
): Promise<MaintenanceHistory[] | null> => {
  const request = new GetMaintenanceHistoryListCalendarQueryParams(queryParams);
  const response =
    await maintenanceHistoryService.getMaintenanceHistoryListCalendar(
      getFilteredCalendarRequest(queryParams, request)
    );
  return response instanceof ServiceError ? null : response;
};

export const fetchMaintenanceItemList = async (): Promise<
  MaintenanceItem[] | null
> => {
  const response = await maintenanceItemService.getByOrganizationId();
  return response instanceof ServiceError ? null : response;
};

export const fetchMaintenanceEquipmentList = async (): Promise<
  MaintenanceEquipment[] | null
> => {
  const response = await maintenanceEquipmentService.getByOrganizationId(
    new GetMaintenanceEquipmentByOrganizationIdRequest({})
  );
  return response instanceof ServiceError ? null : response.content;
};

export const fetchPaginatedMaintenanceVendorList = async (
  queryParams: PaginatedMaintenanceVendorListRequest
): Promise<PaginatedResult<MaintenanceVendor> | null> => {
  const response = await maintenanceVendorService.getPaginatedList(queryParams);
  return response instanceof ServiceError ? null : response;
};

export const fetchMaintenanceAssetTractorOne = async (
  queryParams: GetMaintenanceAssetTractorOneParams
): Promise<IMaintenanceAssetTractor | null> => {
  const response =
    await maintenanceAssetTractorService.getMaintenanceAssetTractorOne(
      queryParams
    );
  return response instanceof ServiceError ? null : response;
};

export const createMaintenanceHistory = async (
  requestBody: CreateMaintenanceHistoryRequest
) => {
  const response = await maintenanceHistoryService.create(requestBody);
  return response instanceof ServiceError ? null : response;
};

export const updateMaintenanceItems = async (
  requestBody: UpdateMaintenanceHistoryRequest
) => {
  const response = await maintenanceHistoryService.update(requestBody);

  return response instanceof ServiceError ? null : response;
};

export const bulkDeleteMaintenanceItems = async (
  requestBody: DeleteBulkMaintenanceHistoryRequest
) => {
  const response = await maintenanceHistoryService.buckDelete(requestBody);

  return response;
};

export const uploadDocument = async (
  requestBody: CreateDocumentOnlyRequest
) => {
  const response = await documentService.createDocumentOnly(requestBody);

  return response instanceof ServiceError ? null : response;
};

export const assignDocument = async (requestBody: AssignDocumentRequest) => {
  const response = documentService.assignDocument(requestBody);

  return response instanceof ServiceError ? null : response;
};

export const createVendor = async (requestBody: CreateVendorRequest) => {
  const response = await vendorService.createVendor(requestBody);

  return response instanceof ServiceError ? null : response;
};

export const deleleMaintenanceHistory = async (
  deleteItems: MaintenanceHistory[],
  organizationId: number
) => {
  const documents: string[] = [];
  const maintenanceHistoryIds: string[] = [];

  deleteItems.forEach((item) => {
    documents.push(
      ...(item.attachments
        ?.filter((doc: IAttachment) => !!doc?.documentId)
        .map((doc) => doc.documentId) || [])
    );
    maintenanceHistoryIds.push(item.id);
  });

  if (documents.length) {
    await documentService.deleteDocument(
      new DeleteDocumentRequest({
        documentIds: documents,
      })
    );
  }

  return await bulkDeleteMaintenanceItems(
    new DeleteBulkMaintenanceHistoryRequest({
      organizationId,
      maintenanceHistoryIds: maintenanceHistoryIds.join(','),
    })
  );
};

export const exportToExcel = async ({
  filters,
  gridColumnMetadataList,
}: {
  filters: IMaintenanceRecordFilters;
  gridColumnMetadataList: string[];
}) => {
  const payload: ExportMaintenanceHistoryFilterPayload =
    new ExportMaintenanceHistoryFilterPayload({
      equipmentTypesFilter: (
        preGetArrayParams(filters, 'equipmentTypes') || []
      ).map(({ value }) => value),
      maintenanceItemsFilter: (
        preGetArrayParams(filters, 'maintenanceItems') || []
      ).map(({ value }) => value),
      assetIdsFilter: (preGetArrayParams(filters, 'assetId') || []).map(
        ({ value }) => value
      ),
      vendorFilter: (preGetArrayParams(filters, 'vendor') || []).map(
        ({ name }) => name
      ),
    });
  const queryParams = new ExportMaintenanceHistoryFilterQueryParams({
    gridColumnMetadataList,
  });
  const response = await maintenanceHistoryService.exportToExcel(
    queryParams,
    payload
  );
  if (!(response instanceof ServiceError)) {
    downloadFile(response);
  }
};
