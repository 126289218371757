import { useImport } from '../../../../../contexts/ImportContext';
import CompletedItemInfo from './CompletedItemInfo';
import {
  ErrorIcon,
  IgnoredIcon,
  RowsIcon,
  WarningIcon,
} from '../../../../../ui-kit/components/Assets';

export default function KpiSection() {
  const {
    kpiInfo: {
      totalRowsCount,
      extractedRowsCount,
      warningsCount,
      errorsCount,
      ignoredColumnsCount,
    },
    currentEntity: { displayName: entityDisplayName },
  } = useImport();

  return (
    <>
      <CompletedItemInfo
        icon={
          <RowsIcon color={'rgba(0, 17, 34, 0.6)'} width={24} height={24} />
        }
        text={`${extractedRowsCount}/${totalRowsCount}`}
        subText={`Total Extracted ${entityDisplayName}`}
      />
      <CompletedItemInfo
        icon={
          <WarningIcon color={'rgba(0, 17, 34, 0.6)'} width={29} height={25} />
        }
        text={`${warningsCount}`}
        subText={'Total Warning'}
      />
      <CompletedItemInfo
        icon={
          <ErrorIcon
            color={'rgba(0, 17, 34, 0.6)'}
            width={24}
            height={24}
            opacity={'0.6'}
          />
        }
        text={`${errorsCount}`}
        subText={'Total Errors'}
      />
      <CompletedItemInfo
        icon={
          <IgnoredIcon color={'rgba(0, 17, 34, 0.6)'} width={24} height={24} />
        }
        text={`${ignoredColumnsCount}`}
        subText={'Total Ignored Columns'}
      />
    </>
  );
}
