import { Theme } from '@mui/material';

export const ConfirmationPopupContainerStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  '.MuiDialog-container': {
    margin: 'auto',
  },
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '0px -11px 25px rgba(78, 101, 120, 0.25)',
    backdropFilter: 'blur(6px)',
    borderRadius: '16px',
    maxWidth: '430px',
    padding: '16px',
  },
});

export const titleStyles = {
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '160%',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
  padding: 0,
};
export const bodyTextStyles = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: 'rgba(4, 0, 34, 0.75)',
  marginTop: '16px',
};

export const btnStyles = {
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px -2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: '10px',
  fontSize: '14px',
  textTransform: 'capitalize',
};
