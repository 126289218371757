import { Box, Button, Menu, Typography, useTheme } from '@mui/material';
import { remove } from 'lodash';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { useCallback, useEffect, useState } from 'react';
import NoData from '../../LoadTabPanel/component/NoData';
import { LoadBoardAccordion } from './LoadBoardAccordion';
import { LoadboardPickerUtils } from './LoadboardPicker.utils';
import { getLoadboardPickerStyles } from './styles';
import { LoadBoardAccount, LoadboardPickerProps } from './types';

export const LoadboardPicker = ({
  name,
  options,
  width = '220px',
  fullWidth,
  isFormControl,
  variant = 'outlined',
  defaultOptions,
  onChange,
}: LoadboardPickerProps) => {
  const theme = useTheme();
  const styles = getLoadboardPickerStyles({ theme, width, isFormControl });
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  });
  const [selectedAccounts, setSelectedAccounts] = useState<LoadBoardAccount[]>(
    []
  );

  const onSelectAccountHandler = (account: LoadBoardAccount) => {
    const newSelectedAccounts = [...selectedAccounts];
    const selectedAccount = newSelectedAccounts.find(
      (option) => option.id === account.id
    );
    remove(
      newSelectedAccounts,
      (selectedAccount) => selectedAccount.groupName === account.groupName
    );
    if (!selectedAccount) {
      newSelectedAccounts.push(account);
    }
    setSelectedAccounts(newSelectedAccounts);
    onChange?.(newSelectedAccounts, name);
  };

  const isSelectedAccount = (account: LoadBoardAccount) => {
    return !!selectedAccounts.find((option) => option.id === account.id);
  };

  const getDisplayName = (): string => {
    return LoadboardPickerUtils.getDisplayName(selectedAccounts);
  };

  const init = useCallback(() => {
    if (defaultOptions) {
      setSelectedAccounts(defaultOptions);
    }
  }, [defaultOptions]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        fullWidth={fullWidth}
        sx={styles.Button}
        variant={variant}
        {...bindTrigger(popupState)}
      >
        <Typography sx={styles.DisplayName}>{getDisplayName()}</Typography>
      </Button>

      <Menu
        sx={styles.Menu}
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {!!options.length &&
          options.map((accounts: LoadBoardAccount[]) => (
            <LoadBoardAccordion
              key={`${accounts[0].id}`}
              accounts={accounts}
              onSelectAccountHandler={onSelectAccountHandler}
              isSelectedAccount={isSelectedAccount}
            />
          ))}
        {!options.length && <NoData />}
      </Menu>
    </Box>
  );
};
