import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-v5';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import InputLabel from '../../InputLabel';
import {
  isTimezoneValid,
  makeAsteriskRed,
  toTimezoneMoment,
  useTimezoneAdapterMoment,
} from '../Utils';

const Datepicker: FunctionComponent<Props> = ({
  label,
  variant = 'standard',
  onDateChange,
  required,
  disableFuture,
  error = false,
  disabled = false,
  inputFormat = 'MM/DD/YYYY',
  initialDate = null,
  styleProps,
  helperText = `Please enter correct date in ${inputFormat} format`,
  id,
  customInputProps,
  customInputLabelProps,
  size,
  minDate = null,
  maxDate = null,
  icon,
  timezone,
  allowEmptyDate,
  disabledManualInput = false,
  fixedLabel = false,
  placeholder,
  fullWidth = false,
  autoFocus = false,
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [datePickerError, setdatePickerError] = useState<boolean>(error);

  useEffect(() => {
    if (!initialDate) {
      setSelectedDate(null);
    } else {
      setSelectedDate(toTimezoneMoment(moment(initialDate), timezone));
    }
  }, [initialDate, timezone]);

  const dateChange = (newDate: Moment | null) => {
    const formatValidation = moment(newDate, inputFormat).isValid();
    const requiredCheck = required && newDate === null;
    const futureDisableCheck =
      disableFuture && newDate && newDate.isAfter(moment(new Date()));
    const emptyCase = !allowEmptyDate || newDate !== null;
    const dateValidationCheck =
      (requiredCheck || futureDisableCheck || !formatValidation) && emptyCase;
    dateValidationCheck ? setdatePickerError(true) : setdatePickerError(false);
    setSelectedDate(newDate);
    onDateChange?.(newDate, dateValidationCheck, id);
  };

  const adapter = useTimezoneAdapterMoment(timezone);
  const handleKeypress = (e: Event) => {
    if (!disabledManualInput) return;
    e.preventDefault();
    return false;
  };
  return (
    <LocalizationProvider dateLibInstance={moment} dateAdapter={adapter}>
      <DatePicker
        label={fixedLabel ? <InputLabel>{label}</InputLabel> : label}
        value={selectedDate}
        disabled={disabled}
        disableFuture={disableFuture}
        onChange={dateChange}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat={inputFormat}
        components={{
          OpenPickerIcon: icon,
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            disabled={disabled}
            id={id}
            onKeyPress={handleKeypress}
            variant={variant}
            error={datePickerError || error}
            required={required}
            sx={{
              ...makeAsteriskRed,
              ...styleProps,
            }}
            size={size}
            helperText={
              <>
                {isTimezoneValid(timezone) && (
                  <Typography
                    fontSize="xx-small"
                    color="text.secondary"
                    variant="subtitle1"
                  >
                    {timezone}
                  </Typography>
                )}
                {(datePickerError || error) && helperText}
              </>
            }
            InputLabelProps={{
              style: customInputLabelProps,
              fixedLabel,
            }}
            InputProps={{
              ...params.InputProps,
              style: customInputProps,
            }}
            placeholder={placeholder}
            fullWidth={fullWidth}
            autoFocus={autoFocus}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export interface Props {
  id?: string;
  label?: string;
  variant?: any;
  onDateChange?: (
    newDate: Moment | null,
    error: boolean,
    id: string | undefined
  ) => void;
  error?: boolean;
  required?: boolean;
  disableFuture?: boolean;
  initialDate?: Date | null;
  styleProps?: any;
  helperText?: any;
  caption?: string;
  disabled?: boolean;
  inputFormat?: string;
  customInputProps?: CSSProperties;
  customInputLabelProps?: CSSProperties;
  size?: 'small' | 'medium';
  minDate?: any;
  maxDate?: any;
  icon?: React.ElementType<JSX.Element>;
  timezone?: string;
  allowEmptyDate?: boolean;
  disabledManualInput?: boolean;
  fixedLabel?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  autoFocus?: boolean;
}

export default Datepicker;
