import {
  CreatePaymentDTO,
  ExportPaymentPayload,
  GetPaginatedPaymentListParams,
  GetPaginatedPaymentListPayload,
  GetPaginatedPaymentListResponse,
  GetPaymentCountTotalResponse,
  GetPaymentOneRequest,
  GetPaymentOpenCreditRequest,
  GetPaymentOpenCreditResponse,
  GetUnpaidPaymentsQueryParams,
  InvoicePaymentDetails,
  PaginatedPaymentInvoiceList,
  PaginatedPaymentInvoiceListParams,
  PaginatedPaymentReferenceList,
  PaginatedPaymentReferenceListParams,
  PaginatedPaymentSeqNumberList,
  PaginatedPaymentSeqNumberListParams,
  PaymentDetailsDTO,
  PaymentMessageResponse,
  UpdatePaymentDTO,
  updatePaymentToInvoiceMappingtParams,
} from '../../models/DTOs/Payment/DTOs';
import { docType } from '../../utils/Doc';
import { Service } from './Service';
import { ServiceError } from './helperTypes';

export abstract class IPaymentService extends Service {
  abstract getUnpaidInvoices(
    queryParams: GetUnpaidPaymentsQueryParams
  ): Promise<Array<InvoicePaymentDetails> | ServiceError>;

  abstract createPayment(
    payload: CreatePaymentDTO
  ): Promise<PaymentDetailsDTO | ServiceError>;

  abstract updatePayment(
    payload: UpdatePaymentDTO
  ): Promise<PaymentDetailsDTO | ServiceError>;

  abstract getPaginatedPaymentList(
    queryParams: GetPaginatedPaymentListParams,
    payload: GetPaginatedPaymentListPayload
  ): Promise<GetPaginatedPaymentListResponse | ServiceError>;

  abstract getCountTotal(
    queryParams: GetPaginatedPaymentListParams,
    payload: GetPaginatedPaymentListPayload
  ): Promise<GetPaymentCountTotalResponse | ServiceError>;

  abstract getPaymentOne(
    queryParams: GetPaymentOneRequest
  ): Promise<PaymentDetailsDTO | null | ServiceError>;

  abstract exportToExcel(
    payload: ExportPaymentPayload
  ): Promise<docType | ServiceError>;

  abstract deletePayment(
    queryParams: GetPaymentOneRequest
  ): Promise<PaymentMessageResponse | ServiceError>;

  abstract getPaymentOpenCredit(
    queryParams: GetPaymentOpenCreditRequest
  ): Promise<GetPaymentOpenCreditResponse | ServiceError>;

  //Grid Filters API begin
  abstract getPaginatedPaymentSeqNumberList(
    queryParams: PaginatedPaymentSeqNumberListParams
  ): Promise<PaginatedPaymentSeqNumberList | ServiceError>;

  abstract getPaginatedPaymentReferenceList(
    queryParams: PaginatedPaymentReferenceListParams
  ): Promise<PaginatedPaymentReferenceList | ServiceError>;

  abstract getPaginatedPaymentInvoiceList(
    queryParams: PaginatedPaymentInvoiceListParams
  ): Promise<PaginatedPaymentInvoiceList | ServiceError>;
  abstract updatePaymentToInvoiceMapping(
    queryParams: updatePaymentToInvoiceMappingtParams
  ): Promise<string | ServiceError>;
  //Grid Filters API end
}
