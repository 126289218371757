import { NumberLocale } from 'yup/lib/locale';
import {
  IDriverSceduleRequestPayload,
  ScheduleDriverType,
} from '../../../types/DispatchTypes';
import { QueryParams } from '../commonMixed/QueryParams';

export class DriverGroupSearchListParams extends QueryParams {
  pageNumber?: number;
  pageSize: number;
  nameFilter: string | undefined;
  terminalIds?: string[];
  sort: string;
  activeForOperation?: boolean;
  typeFilter?: string;
  constructor(nameFilter: string | undefined) {
    super();
    this.pageSize = 25;
    this.nameFilter = nameFilter;
    this.sort = '+groupName';
  }
}

export class DriverMapMarkersRequestParams
  implements IDriverSceduleRequestPayload
{
  hasTimeOff?: string | number | boolean | null;
  excludeTimeline?: boolean;
  driverNameList?: Array<string> | NumberLocale;
  driverGroupIds?: Array<string> | NumberLocale;
}

export class DriverMapMarkersRequestHeaders {
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
  organizationId?: number;
}

export class GanttDriverMapMarkersResponse {
  hosConstants?: {
    dutyTimeConst: number;
    timeToBreakConst: number;
    driveTimeConst: number;
  };
  drivers?: Array<ScheduleDriverType>;
  currentWeek?: Array<string>;
  numberOfDrivers?: number;
}
