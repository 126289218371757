import {
  AddressDTO,
  CreateLocationRequest,
  GetAllStopsResponse,
  GetRelayStopResponse,
  LocationSummaryDTO,
  SplitPayload,
  TripLocation,
  ValidateLocationsUniqueNamesRequest,
  ValidateLocationsUniqueNamesResponse,
} from '../../../../models';
import { DistanceInfo } from '../../../../models/DTOs/GeoBus/Requests';
import { EDistanceId } from '../../../../models/DTOs/GeoBus/Types';
import {
  Business,
  RelayStopFormData,
  TripStopFormData,
} from './components/RelayStopPopup/RelayStopForm';

import moment, { Moment } from 'moment';
import { locationService } from '../../../../api';
import { UpdateRelayStopPayload } from '../../../../models/DTOs/Trip/Requests';
import { Stop } from '../../../../subPages/loadsList/LoadDetailsPanel/models/LoadDetails';
import { TripStopStatusEnum } from '../../../../ui-kit/components/TripPlan';
import { ELoadStatus } from '../../constants/constants';
import { Trip, TripStop } from './Models';
import { FormAsset } from './components/AssetAssignmentPopup';
import { UpdateActivityFormData } from './components/UpdateStopDialog/components/Form/UpdateStopForm';

export const getRoundedRevenueShare = (revenue: number): number =>
  Math.round(revenue === 0 ? 0 : revenue * 100) / 100;

export function calculateStopsNumber(tripsDTO) {
  let stopsNumber = 0;
  tripsDTO.forEach((trip) => {
    stopsNumber += trip.stops.filter((stop) => stop.type === 'LOAD').length;
  });
  return stopsNumber;
}
const splitFullName = (fullname?: string): [string, string, string] => {
  if (!fullname) return ['', '', ''];
  fullname = fullname.trim();
  const parts = fullname.split(/\s/);
  const firstName = parts.shift() || '';
  const lastName = parts.pop() || '';
  const middleName = parts.join(' ');
  return [firstName, middleName, lastName];
};
export const relayStopToSplitPayload = (
  formData: RelayStopFormData
): SplitPayload => {
  const address = formData.address || {};

  return {
    connectionPoint: {
      address: address.address,
      avgHandlingTime: formData.businessName?.avgHandlingTime,
      // axeleId: string, // TODO Anh
      center: {
        lat: address.center?.lat,
        lng: address.center?.lng,
      },
      city: address.city,
      contacts:
        formData.businessName?.contacts?.map((contact) => {
          const names = splitFullName(contact.fullName);
          return {
            contactTitle: contact.contactTitle,
            countryCode: contact.phoneData.countryCode,
            email: contact.email,
            extension: contact.phoneData.extension,
            firstname: names[0],
            isAfterHours: contact.isAfterHours,
            isPrimary: contact.isPrimary,
            lastname: names[2],
            middlename: names[1],
            phone: contact.phoneData.phone,
          };
        }) || [],
      fullAddress: address.fullAddress,
      id: formData.businessName?.id,
      lat: address.center?.lat,
      lng: address.center?.lng,
      locationName: formData.businessName?.locationName,
      notes: formData.businessName?.notes,
      state: address.state,
      streetAddress: address.streetAddress,
      streetAddress2: address.streetAddress2,
      timezone: address.timezone,
      zipcode: address.zipcode,
      geoCoordinates: address.geoCoordinates,
    },
    legStartStopId: formData.legStopId,
    loadId: formData.loadId,
    // Pickup data
    pickupConnectionDetails: {
      appointmentConfirmation: formData?.pickup?.appointmentConfirmation,
      appointmentEndDate: formData.pickup.endDate,
      appointmentStartDate: formData.pickup.startDate,
      driverAssistRequired: formData.pickup.driverAssist,
      estimatedActivityDuration: formData.pickup?.handlingTime
        ? +formData.pickup?.handlingTime
        : undefined,
      fixedAppointment: formData.pickup?.fixedAppointment,
      revenueShare: formData.pickup?.revenueShare
        ? +formData.pickup?.revenueShare
        : undefined,
      terminalId: formData.terminalId,
    },
    // Dropoff data
    dropOffConnectionDetails: {
      appointmentEndDate: formData.dropOff.endDate,
      appointmentStartDate: formData.dropOff.startDate,
      appointmentConfirmation: formData?.dropOff?.appointmentConfirmation,
      driverAssistRequired: formData.dropOff.driverAssist,

      estimatedActivityDuration: formData.dropOff?.handlingTime
        ? +formData.dropOff?.handlingTime
        : undefined,
      fixedAppointment: formData.dropOff?.fixedAppointment,
      revenueShare: formData.dropOff?.revenueShare
        ? +formData.dropOff?.revenueShare
        : undefined,
      driverGroupId: formData.driverGroupId,
      // NOTE: Tran Anh - check driverId usage, seems it is deprecated
      driverId: formData.driverId,
      terminalId: formData.terminalId,
      driverGroupId: formData.driverGroupId,
      // NOTE: Tran Anh - check driverId usage, seems it is deprecated
      driverId: formData.driverId,
    },
  };
};

export const relayStop2UpdatePayload = (
  formData: RelayStopFormData
): UpdateRelayStopPayload => ({
  tripId: formData.loadId,
  stopDTO: {
    // activityType:formData.s TODO: Anh
    originalRelayStopDetails: {
      appointmentEndDate: formData[formData.type].endDate
        ? (formData[formData.type].endDate as Moment).toISOString()
        : null,
      appointmentStartDate: formData[formData.type].startDate
        ? (formData[formData.type].startDate as Moment).toISOString()
        : null,
      estimatedActivityDuration: formData[formData.type].handlingTime,
      fixedAppointment: !!formData[formData.type].fixedAppointment,
      isDriverAssistRequired: !!formData[formData.type].driverAssist,
      appointmentConfirmation: formData[formData.type]?.appointmentConfirmation,
    },
    contact: formData.contact,
    id: formData.id as number,
    location: {
      address: formData.address.address,
      geoCoordinates: formData.address?.geoCoordinates,
      avgHandlingTime: formData.businessName?.avgHandlingTime,
      // axeleId: string;
      center: {
        lat: formData.address.center.lat,
        lng: formData.address.center.lng,
      },
      city: formData.address.city,
      contacts: formData.businessName?.contacts?.map((contact) => ({
        // contactTitle: contact.contactTitle;
        countryCode: contact.phoneData.countryCode,
        email: contact.email,
        extension: contact.phoneData.extension,
        firstname: contact.fullName.split(' ')[0] || '',
        // isAfterHours: contact.is;
        isPrimary: contact.isPrimary,
        lastname: contact.fullName.split(' ').pop(),
        middlename: contact.fullName.split(' ').slice(1, -1).join(' '),
        phone: contact.phoneData.phone,
        // prefix: contact.pre;
        // suffix: contact.
      })),
      // country: formData.address.;
      fullAddress: formData.address.fullAddress,
      id: formData.businessName?.id,
      lat: formData.address.center.lat,
      lng: formData.address.center.lng,
      locationName: formData.businessName?.locationName,
      notes: formData.businessName?.notes,
      // organizationId: number;
      // recordDate: formData.businessName.;
      state: formData.address.state,
      streetAddress: formData.address.streetAddress,
      streetAddress2: formData.address.streetAddress2,
      timezone: formData.address?.timezone,
      zipcode: formData.address.zipcode,
    },
    noteDetails: formData.noteDetails,
    notes: formData.businessName?.notes,
    connectedRelayStopDetails: {
      appointmentEndDate: formData[
        formData.type === 'pickup' ? 'dropOff' : 'pickup'
      ].endDate
        ? (
            formData[formData.type === 'pickup' ? 'dropOff' : 'pickup']
              .endDate as Moment
          ).toISOString()
        : null,
      appointmentStartDate: formData[
        formData.type === 'pickup' ? 'dropOff' : 'pickup'
      ].startDate
        ? (
            formData[formData.type === 'pickup' ? 'dropOff' : 'pickup']
              .startDate as Moment
          ).toISOString()
        : null,
      estimatedActivityDuration:
        formData[formData.type === 'pickup' ? 'dropOff' : 'pickup']
          .handlingTime,
      fixedAppointment:
        !!formData[formData.type === 'pickup' ? 'dropOff' : 'pickup']
          .fixedAppointment,
      isDriverAssistRequired:
        !!formData[formData.type === 'pickup' ? 'dropOff' : 'pickup']
          .driverAssist,
      appointmentConfirmation:
        formData[formData.type === 'pickup' ? 'dropOff' : 'pickup']
          ?.appointmentConfirmation,
    },
    refNumber: formData.refNumber,
    // sequenceNumber: formData.seqNumber,
    stopCategory: formData.stopCategory,
    stopStatus: formData.stopStatus,
    timezone: formData.address?.timezone,
  },
});

export const routeInfo2DistancePayload = (
  stops: TripStopFormData[],
  legStopId: RelayStopFormData['legStopId'],
  address: AddressDTO
): DistanceInfo[] => {
  const relayIndex = stops.findIndex(({ id }) => +id === +legStopId);
  const firstStops = stops.slice(0, relayIndex + 1);
  const secondStops = stops.slice(relayIndex + 1);
  return [
    {
      id: EDistanceId.pickup,
      points: firstStops
        .map((stop) => ({
          lat: +stop.location.lat,
          lon: +stop.location.lng,
        }))
        .concat([
          {
            lat: +address.center.lat,
            lon: +address.center.lng,
          },
        ]),
    },
    {
      id: EDistanceId.dropoff,
      points: [
        {
          lat: +address.center.lat,
          lon: +address.center.lng,
        },
      ].concat(
        secondStops.map((stop) => ({
          lat: +stop.location.lat,
          lon: +stop.location.lng,
        }))
      ),
    },
  ];
};

export const validateLocationBusinessName = async (location: {
  id: number;
  locationName: string;
}) => {
  const { locationName } = location;
  const requestData = new ValidateLocationsUniqueNamesRequest({
    locations: [
      {
        id: 1,
        locationName,
      },
    ],
  });
  const response = await locationService.validateLocationsUniqueNames(
    requestData
  );

  if (response instanceof ValidateLocationsUniqueNamesResponse) {
    return !(response.locations && response.locations[1]);
  }
  return false;
};

export const validateAndSaveStopLocation = async (stop: Stop) => {
  const { location } = stop;
  const locationName = location?.locationName?.trim();
  if (location?.id || !locationName) {
    return { isValid: true, savedLocationId: null };
  }

  const isLocationValid = await validateLocationBusinessName(location);
  if (isLocationValid) {
    const stopLocation = new CreateLocationRequest();
    stopLocation.getLocationOfLoadStop(stop);
    const savedLocation = await locationService.createLocation(stopLocation);
    if (savedLocation instanceof LocationSummaryDTO) {
      return { isValid: true, savedLocationId: savedLocation.id };
    } else {
      return { isValid: false, savedLocationId: null };
    }
  }
  return { isValid: false, savedLocationId: null };
};

export const location2BusinessAndAddress = (
  location?: TripLocation
): {
  business: Business | null;
  address: AddressDTO | null;
} => {
  if (!location) {
    return {
      business: null,
      address: null,
    };
  }
  return {
    business: location.id
      ? {
          contacts: location.contacts?.map((contact) => ({
            id: contact.id,
            description: contact.description || '',
            fullName: [contact.firstname, contact.middlename, contact.lastname]
              .filter(Boolean)
              .join(' '),
            phoneData: {
              countryCode: contact.countryCode,
              extension: +contact.extension,
              phone: +contact.phone,
            },
            email: contact.email,
            isPrimary: contact.isPrimary,
          })),
          addressDTO: {
            fullAddress: location.fullAddress,
            address: location.address,
            city: location.city,
            state: location.state,
            streetAddress: location.streetAddress,
            streetAddress2: location.streetAddress2,
            zipcode: location.zipcode,
            center: {
              lat: location.center.lat,
              lng: location.center.lng,
            },
          },
          id: location.id,
          locationName: location.locationName,
          notes: location.notes,
          avgHandlingTime: location.avgHandlingTime,
          timezone: location.timezone,
          locationPreference: location.locationPreference,
          locationDisplayName: location.locationName,
        }
      : null,
    address: {
      fullAddress: location.fullAddress,
      address: location.address,
      city: location.city,
      state: location.state,
      streetAddress: location.streetAddress,
      streetAddress2: location.streetAddress2,
      zipcode: location.zipcode,
      center: {
        lat: location.center.lat,
        lng: location.center.lng,
      },
      timezone: location.timezone,
      geoCoordinates: location.geoCoordinates,
    },
  };
};

export const businessAndAddress2Location = ({
  business,
  address,
}: {
  business: Business | null;
  address: AddressDTO;
}): TripLocation => {
  return {
    address: address.address,
    avgHandlingTime: business?.avgHandlingTime,
    // axeleId: string;
    center: {
      lat: address.center.lat,
      lng: address.center.lng,
    },
    city: address.city,
    contacts:
      business?.contacts.map((contact) => ({
        // contactTitle: contact.contactTitle;
        countryCode: contact.phoneData.countryCode,
        email: contact.email,
        extension: contact.phoneData.extension,
        firstname: contact.fullName.split(' ')[0] || '',
        // isAfterHours: contact.is;
        isPrimary: contact.isPrimary,
        lastname: contact.fullName.split(' ').pop(),
        middlename: contact.fullName.split(' ').slice(1, -1).join(' '),
        phone: contact.phoneData.phone,
        // prefix: contact.pre;
        // suffix: contact.
      })) || [],
    // country: address.;
    fullAddress: address.fullAddress,
    id: business?.id,
    lat: address.center.lat,
    lng: address.center.lng,
    locationName: business?.locationName || '',
    notes: business?.notes || '',
    // organizationId: number;
    // recordDate: business.;
    state: address.state,
    streetAddress: address.streetAddress,
    streetAddress2: address.streetAddress2,
    timezone: business?.timezone || '',
    zipcode: address.zipcode,
  };
};

export const initUpdateRelayStopFormData = (
  response: GetRelayStopResponse,
  stop: TripStop,
  trip: Trip,
  type: 'pickup' | 'dropOff'
): Partial<RelayStopFormData> => {
  const { address, business } = location2BusinessAndAddress(
    response.originalRelayStop.location
  );
  return {
    type,
    id: +stop.id,
    businessName: business,
    address,
    [type]: {
      id: response.originalRelayStop.id,
      appointmentConfirmation:
        response?.originalRelayStop?.appointmentConfirmation ?? 'NO',
      fixedAppointment: response.originalRelayStop.fixedAppointment,
      startDate: response.originalRelayStop.appointmentStartDate
        ? moment(response.originalRelayStop.appointmentStartDate)
        : null,
      endDate: response.originalRelayStop.appointmentEndDate
        ? moment(response.originalRelayStop.appointmentEndDate)
        : null,
      handlingTime:
        response.originalRelayStop.estimatedActivityDuration?.toString() || '',
      revenueShare: response.originalRelayStop.revenueShare?.toString(),
      driverAssist: response.originalRelayStop.isDriverAssistRequired,
      stopStatus: response.originalRelayStop.stopStatus,
      tripId: response.originalRelayStop.tripId,
    },
    [type === 'pickup' ? 'dropOff' : 'pickup']: {
      id: response.connectedRelayStop.id,
      appointmentConfirmation:
        response?.connectedRelayStop?.appointmentConfirmation ?? 'NO',
      fixedAppointment: response.connectedRelayStop.fixedAppointment,
      startDate: response.connectedRelayStop.appointmentStartDate
        ? moment(response.connectedRelayStop.appointmentStartDate)
        : null,
      endDate: response.connectedRelayStop.appointmentEndDate
        ? moment(response.connectedRelayStop.appointmentEndDate)
        : null,
      handlingTime:
        response.connectedRelayStop.estimatedActivityDuration?.toString() || '',
      revenueShare: response.connectedRelayStop.revenueShare?.toString(),
      driverAssist: response.connectedRelayStop.isDriverAssistRequired,
      stopStatus: response.connectedRelayStop.stopStatus,
      tripId: response.connectedRelayStop.tripId,
    },
    legStopId: response.originalRelayStop.id,
    // driverGroupId: response.connectedRelayStop.; TODO: Anh
    // terminalId: TODO: Anh
    loadId: trip.id,
    // stops: TripStopFormData[];
    // driverId? : string;
    contact: stop.contact,
    refNumber: stop.refNumber,
    noteDetails: stop.noteDetails,
    seqNumber: trip.seqNumber,
    tripStatus: trip.tripStatus,
    stopStatus: stop.status,
    stopCategory: stop.type,
  };
};

export const initCreateRelayStopFormData = (
  stop: TripStop,
  trip: Trip
): Partial<RelayStopFormData> => {
  const stopIndex = trip.stops.findIndex(({ id }) => id === stop.id);
  const nextStop = trip.stops[stopIndex + 1];
  let dropOffStartDate = stop.appointmentStartDate,
    dropOffEndDate = stop.appointmentEndDate;
  if (stop.stopType === 'RELAY') {
    dropOffStartDate = stop.pickup?.startDate;
    dropOffEndDate = stop.pickup?.endDate;
  }
  let pickupStartDate = nextStop?.appointmentStartDate,
    pickupEndDate = nextStop?.appointmentEndDate;
  if (nextStop?.stopType === 'RELAY') {
    pickupStartDate = nextStop.dropOff?.startDate;
    pickupEndDate = nextStop.dropOff?.endDate;
  }
  // NOTE! Tran Anh - why dates of dropOff are wrapped by moment but pickup's dates are not
  return {
    dropOff: {
      fixedAppointment: true,
      driverAssist: false,
      startDate: dropOffStartDate ? moment(dropOffStartDate) : null,
      endDate: dropOffEndDate ? moment(dropOffEndDate) : null,
      handlingTime: '120',
      revenueShare: '50',
    },
    pickup: {
      fixedAppointment: true,
      driverAssist: false,
      startDate: pickupStartDate,
      endDate: pickupEndDate,
      handlingTime: '120',
      revenueShare: '50',
    },
    loadId: trip.id,
    terminalId: trip.terminal?.id,
    legStopId: +stop.id,
    driverGroupId: trip.driverGroup?.id,
    tripStatus: trip.tripStatus,
    stops: trip.stops.map((stop) => ({
      id: stop.id,
      activityType: stop.activityType,
      location: stop.location,
      appointmentStartDate: stop.appointmentStartDate,
      appointmentEndDate: stop.appointmentEndDate,
    })),
  };
};

export const stringToTimezoneMoment = (
  dateString?: string | null,
  timeZone?: string | null
): Moment | null => {
  if (!dateString) return null;
  return timeZone ? moment(dateString).tz(timeZone) : moment(dateString);
};

export const convertTripStatus = (tripStatus: ELoadStatus): ELoadStatus => {
  if (
    [
      ELoadStatus.LOAD_COMPLETED,
      ELoadStatus.INVOICED,
      ELoadStatus.PAID,
    ].includes(tripStatus)
  )
    return ELoadStatus.LOAD_COMPLETED;
  return tripStatus;
};

export const initUpdateActivitiesFormData = (
  response: GetAllStopsResponse,
  tripId: string,
  origTripStatus: ELoadStatus,
  location: TripLocation,
  assetType: 'TRACTOR' | 'TRAILER',
  orgPrevTripStatus?: ELoadStatus,
  trailer?: FormAsset,
  tractor?: FormAsset,
  loadId?: string
): UpdateActivityFormData => {
  const tripStatus = convertTripStatus(origTripStatus);
  const prevTripStatus = orgPrevTripStatus
    ? convertTripStatus(orgPrevTripStatus)
    : orgPrevTripStatus;
  return {
    stops: response.stopsActivityDataByTripId[tripId].map((activity) => ({
      location: activity.location,
      status: activity.stopStatus,
      id: activity.id,
      type: activity.stopType,
      activityType: activity.activityType,
      appointmentStartDate: activity.appointmentStartDate,

      actualTimeOfArrival:
        !!activity.actualTimeOfArrival ||
        tripStatus === ELoadStatus.LOAD_COMPLETED ||
        [
          TripStopStatusEnum.ARRIVED,
          TripStopStatusEnum.ACTIVITY_STARTED,
          TripStopStatusEnum.ACTIVITY_ENDED,
          TripStopStatusEnum.COMPLETED,
        ].includes(activity.stopStatus),
      loadingStart:
        !!activity.loadingStartDate ||
        tripStatus === ELoadStatus.LOAD_COMPLETED ||
        [
          TripStopStatusEnum.ACTIVITY_STARTED,
          TripStopStatusEnum.ACTIVITY_ENDED,
          TripStopStatusEnum.COMPLETED,
        ].includes(activity.stopStatus),
      loadingEnd:
        !!activity.loadingEndDate ||
        tripStatus === ELoadStatus.LOAD_COMPLETED ||
        [
          TripStopStatusEnum.ACTIVITY_ENDED,
          TripStopStatusEnum.COMPLETED,
        ].includes(activity.stopStatus),
      actualTimeOfCompletion:
        !!activity.actualTimeOfCompletion ||
        tripStatus === ELoadStatus.LOAD_COMPLETED ||
        [TripStopStatusEnum.COMPLETED].includes(activity.stopStatus),

      actualTimeOfArrivalDate: activity.actualTimeOfArrival,
      loadingStartDate: activity.loadingStartDate,
      loadingEndDate: activity.loadingEndDate,
      actualTimeOfCompletionDate: activity.actualTimeOfCompletion,
      actualTimeOfArrivalDisabled: !!activity.actualTimeOfArrival,
      loadingStartDisabled: !!activity.loadingStartDate,
      loadingEndDisabled: !!activity.loadingEndDate,
      actualTimeOfCompletionDisabled: !!activity.actualTimeOfCompletion,
    })),
    tripStatus,
    prevTripStatus,
    tripId,
    assetAssignmentFormData: {
      ...location2BusinessAndAddress(location),
      userChoice: 'DRIVER',
      assetType: assetType,
      trailer,
      tractor,
      tripId,
    },
    loadId,
  };
};
