import {
  GetGlobalTerminalDTO,
  UpdateReceivedDTO,
  GetNotificationCountDTO,
  GetNotificationListDTO,
  GetGlobalSerarchDTO,
} from '../../../../../models';
import { layoutService } from '../../../../../api';
import StorageManager from '../../../../../StorageManager/StorageManager';

//////////////////////// ATTENTION ///////////////////////////////
//////  FEW DATA IS HARD-CODED SINCE API UNAVAILABILITY  /////////
/////////// IT WILL BE FIXED IN THE NEXT RELEASE  ////////////////
/////////////////////////////////////////////////////////////////

export const getGlobalSearchPanelData = async (keys: string) => {
  const userStorage = StorageManager.getUser();
  const request = new GetGlobalSerarchDTO();
  request.organizationId = userStorage.organizationId;
  request.keys = keys;
  const getRes = await layoutService.getGlobalSearchResponseData(request);
  return getRes ? getRes : [];
};

export const getTerminals = async () => {
  const userStorage = StorageManager.getUser();
  const request = new GetGlobalTerminalDTO();
  request.organizationId = userStorage.organizationId;
  request.userId = userStorage.id;
  const getRes = await layoutService.getGlobalTerminals(request);
  return getRes;
};

export const updateReceived = async (id: string) => {
  const userStorage = StorageManager.getUser();
  const payload = new UpdateReceivedDTO();
  payload.id = id;
  payload.organizationId = userStorage.organizationId;
  payload.receiverId = userStorage.id;
  payload.status = true;
  await layoutService.updateReceivedData(payload);
  return;
};

export const getNotificationCount = async () => {
  const userStorage = StorageManager.getUser();
  const request = new GetNotificationCountDTO();
  request.organizationId = userStorage.organizationId;
  request.receiverId = userStorage.id;
  request.notificationType = 'UPDATE';
  request.trayReady = true;
  request.readStatus = false;
  return await layoutService.getNotificationCountData(request);
};

export const updateSingleReadData = async (id = '', markAll = true) => {
  const userStorage = StorageManager.getUser();
  const payload = new UpdateReceivedDTO();
  if (!markAll) payload.id = id;
  payload.organizationId = userStorage.organizationId;
  payload.receiverId = userStorage.id;
  payload.status = true;
  await layoutService.updateSingleRead(payload);
  return;
};

export const getNotificationList = async () => {
  const userStorage = StorageManager.getUser();
  const request = new GetNotificationListDTO();
  request.organizationId = userStorage.organizationId;
  request.receiverId = userStorage.id;
  request.notificationType = 'UPDATE';
  request.trayReady = true;
  request.pageNumber = 0;
  request.pageSize = 25;
  request.sort = 'DESC';

  return await layoutService.getNotificationListData(request);
};
