import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Link, Tab, Tabs, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect } from 'react';
import TabContents from './TabContent';

export default function SideDrawerDAT({
  currentCustomerIntegrationFormField,
  isEditTab,
  cardData,
  allTerminals,
  currentCustomerData,
  refreshOnCard,
  toggleDrawer,
}: selectedCustomerTypes) {
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    if (isEditTab) {
      setValue(1);
    }
  }, [isEditTab]);

  const DATFormText = {
    lable: 'Enable DAT Connexion',
    typography1:
      'To link your DAT load board, DAT must provide specific credentials to integrate with LoadOps.',
    bold: 'The new credentials will be different than your current DAT credentials.',
    typography2:
      'Please call at 800-547-5417 or fill the form below to have LoadOps request the credentials on your behalf. Integration is free.',
    userGuide: 'https://help.axele.com/loadops-dat',
  };
  const needConnexion =
    currentCustomerIntegrationFormField &&
    currentCustomerIntegrationFormField.length > 0 &&
    currentCustomerIntegrationFormField[0]['fields'] &&
    currentCustomerIntegrationFormField[0]['fields'].length > 0
      ? currentCustomerIntegrationFormField[0]['fields']
      : [];

  const haveConnexion =
    currentCustomerIntegrationFormField &&
    currentCustomerIntegrationFormField.length > 0 &&
    currentCustomerIntegrationFormField[1] &&
    currentCustomerIntegrationFormField[1]
      ? currentCustomerIntegrationFormField[1]
      : [];

  useEffect(() => {
    if (
      haveConnexion &&
      Object.keys(haveConnexion) &&
      Object.keys(haveConnexion).length > 0
    ) {
      const sidePanelForm: {
        desc: string;
        fields: Array<object>;
        label: string;
        userGuide: string;
      } = {
        desc: '',
        fields: [],
        label: '',
        userGuide: '',
      };
      sidePanelForm['desc'] = haveConnexion['description'];
      sidePanelForm['fields'] = haveConnexion['fields'];
      sidePanelForm['label'] = haveConnexion['displayStepName'];
      sidePanelForm['userGuide'] = haveConnexion['readmeURL'];
    }
  }, []);

  const handleChange = (
    event:
      | React.SyntheticEvent
      | React.ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<any>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, index, ...other } = props;

    return (
      <Box
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, paddingTop: value === 1 ? '0px' : 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Box>
            <Typography fontSize="h5.fontSize" color={'primary.main'}>
              {DATFormText['lable']}
            </Typography>
          </Box>
          <Box sx={{ mt: 1, alignSelf: 'stretch' }}>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: 'body1.fontSize',
                letterSpacing: 'body1.lineHeight',
              }}
            >
              {DATFormText['typography1']}
              <Typography sx={{ fontWeight: 'bold' }}>
                {DATFormText['bold']}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ mt: 1, alignSelf: 'stretch' }}>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: 'body1.fontSize',
                letterSpacing: 'body1.lineHeight',
              }}
            >
              {DATFormText['typography2']}
            </Typography>
          </Box>
          {DATFormText.userGuide && (
            <Link
              href={DATFormText.userGuide}
              underline="always"
              target="_blank"
              rel="noopener"
              sx={{ pt: '12px', display: 'block' }}
            >
              Read User Guide
              <ArticleOutlinedIcon
                sx={{ ml: '11px', width: '18px', height: '18px' }}
              />
            </Link>
          )}
        </Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="I need Connexion" {...a11yProps(0)} />
            <Tab label="I have Connexion" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box
          sx={{
            display: 'flexbox',
            flexDirection: 'columnflexbox',
            alignItems: 'flex-startflexbox',
          }}
        >
          <TabPanel value={value} index={value}>
            {
              <TabContents
                tabIndex={value}
                needConnexion={needConnexion}
                haveConnexion={haveConnexion}
                refreshOnCard={refreshOnCard}
                isEditTab={isEditTab}
                cardData={cardData}
                toggleDrawer={toggleDrawer}
                allTerminals={allTerminals}
                currentCustomerData={currentCustomerData}
              />
            }
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface IcurrentCustomerIntegrationFormField {
  displayStepName?: string;
  description?: string;
  hasReadmeGuide?: boolean;
  readmeURL?: string;
  hasFields?: boolean;
  fields?: [];
  activeStep?: boolean;
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface selectedCustomerTypes {
  currentCustomerIntegrationFormField: Array<IcurrentCustomerIntegrationFormField>;
  currentCustomerData: any;
  allTerminals: any;
  isEditTab: boolean;
  cardData: any;
  sideDrawerDisplayData: any;
  refreshOnCard: () => void;
  toggleDrawer: (newOpen: boolean) => void;
  isDAT: boolean;
}
