import { List, ListItemButton, ListItemText, SxProps } from '@mui/material';

export const AdvanceReportSideBar = ({
  data,
  listStyle,
  listItemStyle,
  selectedReport,
  selectedReportStyle,
  handleReportOnClick,
}: {
  data: Array<{ id: string; title: string; description: string | null }>;
  listStyle: SxProps;
  listItemStyle?: SxProps;
  selectedReport?: { id: string };
  selectedReportStyle: SxProps;
  handleReportOnClick: (data: {
    id: string;
    title: string;
    description: string | null;
  }) => void;
}) => {
  return (
    <List
      sx={{
        bgcolor: 'background.paper',
        borderRadius: '16px 16px 0px 0px',
        ...listStyle,
      }}
    >
      {data?.map((item) => {
        return (
          <ListItemButton
            id={item.title}
            sx={
              selectedReport?.id == item?.id
                ? { ...listItemStyle, ...selectedReportStyle }
                : { ...listItemStyle }
            }
            onClick={() => handleReportOnClick(item)}
          >
            <ListItemText primary={item.title} secondary={item.description} />
          </ListItemButton>
        );
      })}
    </List>
  );
};
