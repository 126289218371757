import { memo, PropsWithChildren } from 'react';
import { PermissionOutputProps } from '../types/types';

export const PermissionOutput = memo(
  ({
    children,
    denied,
    deniedSubscription,
    hasPermission,
    useDefault,
    render,
    prevent,
  }: PropsWithChildren<PermissionOutputProps>): JSX.Element => {
    if (prevent) return <>{denied?.() || null}</>;

    if (typeof render === 'function') return <>{render(hasPermission)}</>;

    if (hasPermission) return <>{children || null}</>;

    if (useDefault) return <></>;

    if (deniedSubscription) return deniedSubscription(hasPermission);

    return denied ? denied(hasPermission) : <></>;
  }
);
PermissionOutput.displayName = 'PermissionOutput';
