import { GridValueFormatterParams } from '@mui/x-data-grid';
import moment from 'moment';
import {
  API_MAINTENANCE_HISTORY_COMPLETED_DATE_FORMAT,
  UI_GRID_DATE_FORMAT,
} from '../constants/date.constants';
import { GridColDefSelf } from '../types';

export const valueFormatterNumeric = (
  params: GridValueFormatterParams
): string => {
  if (params.value) {
    return numberFormat(params.value);
  }
  return '';
};
export const valueFormatterDecimal = (
  params: GridValueFormatterParams
): string => {
  if (params.value) {
    return numberFormat(parseFloat(params.value).toFixed(2));
  }
  return '';
};

const numberFormat = (value: string | number): string => {
  return new Intl.NumberFormat().format(value);
};

export const valueFormatterCurrency = (
  params: GridValueFormatterParams | any
): string => {
  if (params.value) {
    return `$${numberFormat(parseFloat(params.value).toFixed(2))}`;
  }
  return '$0';
};
export const valueFormatterDate = (params: any | string): string => {
  if (params.value) {
    return moment(new Date(params.value)).format(UI_GRID_DATE_FORMAT);
  }
  return '';
};

export const formatApiMaintenanceHistoryCompletedDate = (
  value: any
): string => {
  if (value) {
    return moment(new Date(value)).format(
      API_MAINTENANCE_HISTORY_COMPLETED_DATE_FORMAT
    );
  }
  return '';
};

export const currencyDisplay = (
  value: string | number,
  defaultValue = '',
  minimumFractionDigits = 0
): string => {
  if ((!value && typeof value !== 'number') || isNaN(Number(value))) {
    return defaultValue;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: minimumFractionDigits,
  }).format(Number(value));
};

export const integerFormat = (
  value?: string | number,
  defaultValue = ''
): string => {
  if ((!value && typeof value !== 'number') || isNaN(Number(value))) {
    return defaultValue;
  }
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format(Number(value));
};

export const getGridColumns = (
  columns: GridColDefSelf[] = [],
  isTerminalEnabled: boolean,
  hasFinancialInvoicesView = false,
  isClassMappingActive: boolean
): GridColDefSelf[] | undefined => {
  if (!columns) {
    return;
  }
  return columns.filter((item) => {
    const { field } = item;
    if (['terminals', 'terminal', 'dispatchTerminals'].includes(field))
      return isTerminalEnabled;
    if (field === 'revenue') return hasFinancialInvoicesView;
    if (field === 'mappingEntityName') return isClassMappingActive;
    return true;
  });
};

export const getGridColumnsLoad = (
  columns: GridColDefSelf[] = [],
  isTerminalEnabled: boolean,
  hasFinancialInvoicesView = false,
  hasCarrierViewPermission: boolean
): GridColDefSelf[] | undefined => {
  if (!columns) {
    return;
  }
  return columns.filter((item) => {
    const { field } = item;
    if (['terminals', 'terminal', 'dispatchTerminals'].includes(field))
      return isTerminalEnabled;
    if (field === 'revenue') return hasFinancialInvoicesView;
    if (field === 'carrierDetails') return hasCarrierViewPermission;
    if (field === 'canBeBrokered') return hasCarrierViewPermission;
    if (field === 'brokerageTrip') return hasCarrierViewPermission;
    return true;
  });
};
