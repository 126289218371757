import styled from '@emotion/styled';
import {
  MenuProps,
  Menu,
  alpha,
  Grid,
  Tooltip,
  IconButton,
  MenuItem,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import { MenuActionsIcon } from '../../../../common/MenuActions/constants';
import { useTeamsDriver } from '../../../../contexts/TeamDriverContext';
import { getAccountTypeWithRoleCode } from '../../../../utils';
import { enumMenuList } from '../utils/integrationTeamDriver';

export const CustomizedMenus = (data: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { setSelectTeamDriver, setActionWorkFlow } = useTeamsDriver();
  const handleClose = (List: string) => {
    if (List) {
      setSelectTeamDriver && setSelectTeamDriver(data.menuList);
      setActionWorkFlow(List);
    }
    setAnchorEl(null);
  };

  const noPermissionForAssignAndChangeStatus = getAccountTypeWithRoleCode([
    'AC',
    'DR',
    'SP',
  ]);

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <Grid>
      <Tooltip title="Actions">
        <IconButton
          onClick={handleClick}
          disabled={noPermissionForAssignAndChangeStatus}
        >
          <MenuActionsIcon />
        </IconButton>
      </Tooltip>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {data.menuList?.actions?.map((List: string) => {
          return (
            <MenuItem
              onClick={() => handleClose(List)}
              disableRipple
              key={List}
              value={List}
            >
              {enumMenuList[List]}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </Grid>
  );
};
