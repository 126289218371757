import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BrokeredTripOption } from '../../../../../models';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { useStores } from '../../../../../store/root.context';
import { ComponentType } from '../../../../../types';
import { ESecondaryDetailsPanelType } from '../../../../../views/dispatch2/constants/types';
import { DirtyDetailsPanelManager } from '../../../../DetailsPanel/utils';
import SecondaryDetailsPanel from '../../../../SecondaryDetailsPanel/SecondaryDetailsPanel';
import { SingleFieldInfoCard } from '../../../../SingleFieldInfoCard';
import HyperLink from '../../../../Ui/HyperLink/HyperLink';
import { t } from 'i18next';

interface IProp {
  id: number;
  loadId: string;
  assignments: {
    dispatcher: any;
    driverGroup: any;
    tractorDetails: any;
    trailerDetails: any;
    terminal: any;
    seqNumber: string;
  } & BrokeredTripOption;
}

export const TripCard = observer(({ id, loadId, assignments }: IProp) => {
  const { setValue } = useFormContext();
  const [secondaryPanelData, setSecondaryPanelData] = useState<{
    type: keyof ComponentType;
    subType?: string;
    id: string | number;
  } | null>(null);
  const {
    driverGroup,
    dispatcher,
    tractorDetails,
    trailerDetails,
    terminal,
    seqNumber,
    brokerageTrip,
    carrierDetails,
  } = assignments;
  const { id: tractorId, name: tractorName } = tractorDetails || {};
  const { id: trailerId, name: trailerName } = trailerDetails || {};
  const { id: driverGroupId, displayName: driverGroupName } = driverGroup || {};
  const { id: dispatcherId, name: dispatcherName } = dispatcher || {};
  const { companyName: dispatchTerminalName } = terminal || {};

  const {
    myLoadStore: { setUpdatedLoadId },
  } = useStores();
  const { getIsTerminalEnabled } = useRootStore();
  const gridItemStyle = { mt: 1 };

  const closeSecondaryDetailsPanel = () => {
    if (secondaryPanelData?.type === 'DISPATCH') {
      setUpdatedLoadId && setUpdatedLoadId(loadId);
    }
    setSecondaryPanelData(null);
  };

  const handleSecondaryDetailsPanelSave = (data) => {
    const { type, subType } = secondaryPanelData;
    switch (type) {
      case 'TRACTOR':
        setValue(`trips.${id}.tractorDetails.name`, data.tractorName);
        break;
      case 'TRAILER':
        setValue(`trips.${id}.trailerDetails.name`, data.trailerName);
        break;
      case 'USER':
        const { firstName, lastName } = data.currentUser || {};

        if (subType === 'DRIVER') {
          setValue(
            `trips.${id}.driverGroup.displayName`,
            `${firstName} ${lastName}`
          );
        } else if (subType === 'DISPATCHER') {
          setValue(`trips.${id}.dispatcher.name`, `${firstName} ${lastName}`);
        }

        break;
      case ESecondaryDetailsPanelType.CARRIER:
        setValue(`trips.${id}.carrierDetails.name`, data?.name);
        break;
      default:
        return;
    }
    setUpdatedLoadId && setUpdatedLoadId(loadId);
    closeSecondaryDetailsPanel();
  };

  return (
    <>
      <Box>
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="h7"
            sx={{
              color: 'primary.main',
            }}
          >
            {`${t('tripId')}: ${seqNumber}`}
          </Typography>
        </Box>

        <Grid container>
          {getIsTerminalEnabled && (
            <Grid item xs={6} sx={gridItemStyle}>
              <SingleFieldInfoCard
                label={t('dispatchTerminal')}
                value={dispatchTerminalName || '--'}
              />
            </Grid>
          )}
          <Grid item xs={6} sx={gridItemStyle}>
            <SingleFieldInfoCard
              label={t('dispatcher')}
              {...(!dispatcherId && { value: '--' })}
              {...(dispatcherId && {
                contentRenderer: () => (
                  <HyperLink
                    value={dispatcherName || '--'}
                    onClick={() => {
                      if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
                      setSecondaryPanelData({
                        type: 'USER',
                        id: dispatcherId,
                        subType: 'DISPATCHER',
                      });
                    }}
                  />
                ),
              })}
            />
          </Grid>
          {brokerageTrip ? (
            <Grid item xs={6} sx={gridItemStyle}>
              <SingleFieldInfoCard
                label={t('carrier')}
                {...(!carrierDetails?.id && { value: '--' })}
                {...(carrierDetails?.id && {
                  contentRenderer: () => (
                    <HyperLink
                      value={carrierDetails?.name || '--'}
                      onClick={() => {
                        if (DirtyDetailsPanelManager.isShouldPanelShake())
                          return;
                        setSecondaryPanelData({
                          type: ESecondaryDetailsPanelType.CARRIER,
                          id: carrierDetails?.id,
                        });
                      }}
                    />
                  ),
                })}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={6} sx={gridItemStyle}>
                <SingleFieldInfoCard
                  label={t('driver')}
                  {...(!driverGroupId && { value: '--' })}
                  {...(driverGroupId && {
                    contentRenderer: () => (
                      <HyperLink
                        value={driverGroupName || '--'}
                        onClick={() => {
                          if (DirtyDetailsPanelManager.isShouldPanelShake())
                            return;
                          setSecondaryPanelData({
                            type: 'DISPATCH',
                            id: driverGroupId,
                          });
                        }}
                      />
                    ),
                  })}
                />
              </Grid>
              <Grid item xs={6} sx={gridItemStyle}>
                <SingleFieldInfoCard
                  label={t('tractor')}
                  value={tractorName || '--'}
                  {...(!tractorId && { value: '--' })}
                  {...(tractorId && {
                    contentRenderer: () => (
                      <HyperLink
                        value={tractorName || '--'}
                        onClick={() => {
                          if (DirtyDetailsPanelManager.isShouldPanelShake())
                            return;
                          setSecondaryPanelData({
                            type: 'TRACTOR',
                            id: tractorId,
                          });
                        }}
                      />
                    ),
                  })}
                />
              </Grid>
              <Grid item xs={6} sx={gridItemStyle}>
                <SingleFieldInfoCard
                  label={t('trailer')}
                  value={trailerName || '--'}
                  {...(!trailerId && { value: '--' })}
                  {...(trailerId && {
                    contentRenderer: () => (
                      <HyperLink
                        value={trailerName || '--'}
                        onClick={() => {
                          if (DirtyDetailsPanelManager.isShouldPanelShake())
                            return;
                          setSecondaryPanelData({
                            type: 'TRAILER',
                            id: trailerId,
                          });
                        }}
                      />
                    ),
                  })}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      {secondaryPanelData && (
        <SecondaryDetailsPanel
          isGlobal={true}
          type={secondaryPanelData.type}
          id={secondaryPanelData.id}
          onClose={closeSecondaryDetailsPanel}
          onUpdated={(data) => handleSecondaryDetailsPanelSave(data)}
        />
      )}
    </>
  );
});
