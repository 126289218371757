/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComposedError, httpClient } from '../../axios/axiosInstance';
import {
  getFailureMsg,
  getSuccessMsg,
} from '../../constants/globalNotificationMessages';
import {
  AssignLSLoadToDriverResponse,
  IBookmarkedLoadsResponse,
  IDriverDataResponse,
  ILoadSearchGetSolutionResponse,
  ILoadSearchInvokeResponse,
} from '../../models/DTOs/Loadsearch/Model';
import {
  AssignLSLoadToDriverRequest,
  AvailableLoadboards,
  BookmarkLoadRequest,
  DeleteSavedLanesRequest,
  EditSavedLanesRequest,
  GetBookmarkListRequest,
  GetDriverListRequest,
  GetEquipmentRequest,
  GetSavedLanesRequest,
  LoadInstantBookingRequest,
  LoadSearchGetSolutionRequest,
  LoadSearchInvokeRequest,
  LoadsearchMapDataRequest,
  LoadsearchRequest,
  SaveKpiRequest,
  SaveLaneRequest,
  UnBookmarkLoadRequest,
} from '../../models/DTOs/Loadsearch/Request';
import { RootStoreInstence } from '../../store/root-store/rootStateContext';
import { ServiceError, annotateNameAsync } from '../interfaces';
import { ILoadsearchService } from '../interfaces/ILoadsearchServices';

import { MarkersData } from '../../common/Ui/Maps/types';
import { QueryParams } from '../../models';

const getLoadSearch = 'web/loadselect/api/v2/loadselect/loads/search';
const assignLSLoadToDriver = '/web/loadselect/api/v2/loadselect/assign';
const saveLaneURL = 'web/loadselect/api/v2/savedLanes';
const equipmentsURL = 'web/loadselect/api/v2/loadselect/static';
const availableLoadboardURL =
  'web/omnibus/api/v2/integrations/available/loadboard';
const bookemarkURL = 'web/loadselect/api/v2/bookmarked';
const bookmarkedListURL = 'web/loadselect/api/v2/bookmarked';
const driversListURL = 'web/loadselect/api/v2/driver/groups/gaps';
const kpiURL = 'web/loadselect/api/v2/kpi/driver/weekly';
const BidOnLoad = 'web/loadselect/api/v2/bookmarked/bid';
const updateBidLoad = 'web/loadselect/api/v2/bookmarked/updateBidStatus';
const loadboardMap = '/web/loadselect/api/v2/mapmarkers/map';
const instantBookingURL = 'web/loadselect/api/v2/loadselect/book-bid-api';

const LOAD_SELECT_RESOURCE_URL = {
  invoke: 'web/loadselect/api/v2/loadselect/invoke',
  getSolution: 'web/loadselect/api/v2/loadselect/get/solution',
};
export class LoadsearchServices extends ILoadsearchService {
  requestData: QueryParams;
  constructor() {
    super();
    this.requestData = new QueryParams();
  }

  @annotateNameAsync
  async getLoadsearchList(payload: LoadsearchRequest): Promise<any> {
    try {
      return await httpClient.post<any>(
        getLoadSearch,
        undefined,
        payload,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async assignLSLoadToDriver(
    payload: AssignLSLoadToDriverRequest,
    additionalDetails: { [key: string]: any }
  ) {
    const serviceName = 'assignLSLoadToDriver';
    try {
      const response = await httpClient.put<AssignLSLoadToDriverResponse>(
        assignLSLoadToDriver,
        undefined,
        payload,
        AssignLSLoadToDriverResponse,
        false
      );

      RootStoreInstence.setNotificationType({
        type: 'SUCCESS',
        serviceName,
        message: getSuccessMsg[serviceName](
          response.seqNumber,
          additionalDetails.driverGroupName
        ),
      });
      return response;
    } catch (error) {
      RootStoreInstence.setNotificationType({
        type: 'FAILURE',
        serviceName,
        message: getFailureMsg[serviceName](additionalDetails.driverGroupName),
      });
      if (error instanceof ComposedError) {
        const composedError = error as ComposedError;
        return this.getServiceError(composedError);
      }
      return Promise.resolve(null);
    }
  }

  @annotateNameAsync
  async getEquipmentsList(payload: GetEquipmentRequest) {
    try {
      return await httpClient.get<any>(
        equipmentsURL,
        payload,
        null,
        undefined,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async saveLane(payload: SaveLaneRequest) {
    payload.addDispatcherIdToParams();
    try {
      return await httpClient.post<any>(saveLaneURL, undefined, payload, false);
    } catch (error) {
      throw error;
    }
  }

  async getSavedLanes(payload: GetSavedLanesRequest) {
    payload.addDispatcherIdToParams();
    try {
      return await httpClient.get<any>(
        saveLaneURL + '/dispatcher',
        payload,
        null,
        undefined,
        true
      );
    } catch (error) {
      throw error;
    }
  }

  async editSavedLanes(payload: EditSavedLanesRequest) {
    try {
      return await httpClient.put<any>(saveLaneURL, undefined, payload, false);
    } catch (error) {
      throw error;
    }
  }

  async deleteSavedLane(payload: DeleteSavedLanesRequest) {
    try {
      return await httpClient.delete<any>(
        saveLaneURL + `?laneId=${payload.laneId}`,
        undefined,
        payload,
        false
      );
    } catch (error) {
      throw error;
    }
  }

  @annotateNameAsync
  async getAvailableLoadboards(payload: AvailableLoadboards): Promise<any> {
    try {
      return await httpClient.get<any>(
        availableLoadboardURL,
        payload,
        null,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async bookmarkedLoad(
    payload: BookmarkLoadRequest
  ): Promise<IBookmarkedLoadsResponse> {
    try {
      return await httpClient.post<IBookmarkedLoadsResponse>(
        bookemarkURL,
        undefined,
        payload,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async unBookmarkedLoad(payload: UnBookmarkLoadRequest): Promise<any> {
    try {
      return await httpClient.delete<any>(
        `${bookemarkURL}?bookMarkId=${payload.bookMarkId}`,
        undefined,
        payload,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async loadInstantBooking(payload: LoadInstantBookingRequest): Promise<any> {
    try {
      return await httpClient.post<any>(
        instantBookingURL,
        undefined,
        payload.selectedLoad,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getBookmarkedList(payload: GetBookmarkListRequest): Promise<any> {
    try {
      return await httpClient.get<any>(
        bookmarkedListURL,
        payload,
        null,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getDriversList(
    payload: GetDriverListRequest
  ): Promise<IDriverDataResponse> {
    try {
      return await httpClient.post<IDriverDataResponse>(
        driversListURL,
        undefined,
        payload,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async saveCalcultorKpi(payload: SaveKpiRequest) {
    try {
      return await httpClient.post<any>(
        kpiURL +
          `?newRevenue=${payload.newRevenue}&organizationId=${payload.organizationId}`,
        undefined,
        payload,
        false
      );
    } catch (error) {
      throw error;
    }
  }

  //Trip Plan tab in the Loadsearch Detail Panel  begin
  @annotateNameAsync
  async getLSInvoke(
    payload: LoadSearchInvokeRequest
  ): Promise<ILoadSearchInvokeResponse | ServiceError | null> {
    try {
      return await httpClient.post<any>(
        LOAD_SELECT_RESOURCE_URL.invoke,
        undefined,
        payload,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }
  @annotateNameAsync
  async getLSGetSolution(
    payload: LoadSearchGetSolutionRequest
  ): Promise<ILoadSearchGetSolutionResponse | ServiceError | null> {
    try {
      return await httpClient.get<any>(
        LOAD_SELECT_RESOURCE_URL.getSolution,
        payload,
        null,
        undefined,
        true
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }
  //Trip Plan tab in the Loadsearch Detail Panel end

  @annotateNameAsync
  async saveBidOnLoad(payload: any): Promise<any | ServiceError | null> {
    try {
      return await httpClient.post<any>(
        `${BidOnLoad}?bidPrice=${payload.bidPrice}&dispatcherId=${payload.dispatcherId}&recipientEmailId=${payload.recipientEmailId}`,
        undefined,
        payload,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async updateBidLoad(payload: any): Promise<any | ServiceError | null> {
    try {
      return await httpClient.put<any>(
        `${updateBidLoad}?bidStatusAction=${payload.bidStatusAction}`,
        undefined,
        payload,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }

  @annotateNameAsync
  async getloadboardMapData(
    payload: LoadsearchMapDataRequest
  ): Promise<MarkersData | ServiceError | null> {
    try {
      return await httpClient.post<any>(
        loadboardMap,
        undefined,
        payload,
        false
      );
    } catch (error) {
      const composedError = error as ComposedError;
      throw this.getServiceError(composedError);
    }
  }
}
