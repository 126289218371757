import { Fragment } from 'react';
import { DriverTeamWithTrips } from '../../../../models';
import {
  EDispatchPanelTab,
  IDispatchPreferencesData,
  SecondaryDetailsOpenProps,
} from '../../constants/types';
import { GanttPreferences } from '../Preferences';
import { GanttTimeline } from '../Timeline';

export interface DispatchDetailsFormProps
  extends Partial<SecondaryDetailsOpenProps> {
  selectedTab: EDispatchPanelTab;
  detailsPanelData: {
    preferencesData?: IDispatchPreferencesData;
    driverTeam: DriverTeamWithTrips;
  };
  onUpdate?: () => void;
}
export default function DispatchDetailsForm({
  selectedTab,
  detailsPanelData,
  handleSecondaryDetailsOpen,
  onUpdate,
}: DispatchDetailsFormProps) {
  return (
    <Fragment>
      {selectedTab === EDispatchPanelTab.TIMELINE && (
        <Fragment>
          <GanttTimeline
            onUpdate={onUpdate}
            driverTeam={detailsPanelData.driverTeam}
            handleSecondaryDetailsOpen={handleSecondaryDetailsOpen}
          />
        </Fragment>
      )}

      {selectedTab === EDispatchPanelTab.PREFERENCES && <GanttPreferences />}
    </Fragment>
  );
}
