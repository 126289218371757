import React, { ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import StyledIconsComponent from './StyledIconsComponent';
import StyledThreeDotMenuIcon from './StyledThreeDotMenuIcon';
import StyledTab from './StyledTab';
import { ITabV2 } from './TabsV2';
import { TabProps } from '@mui/material/Tab';
import { Tooltip, Typography } from '@mui/material';

export interface TabV2Props<
  KeyName extends string = 'key',
  TTab extends ITabV2<KeyName> = ITabV2<KeyName>
> extends TabProps {
  tab: TTab;
  value: TTab[KeyName];
  isDarkMode?: boolean;
  isOverflow?: boolean;
  labelCentered?: boolean;
}

const TabV2 = <
  KeyName extends string = 'key',
  TTab extends ITabV2<KeyName> = ITabV2<KeyName>
>({
  isDarkMode,
  isOverflow,
  labelCentered,
  tab: { label, iconName, icon, actions, source },
  value,
  ...props
}: TabV2Props<KeyName, TTab>): ReactElement | null => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  return (
    <StyledTab
      value={value}
      key={value}
      isDarkMode={isDarkMode}
      label={
        <Tooltip title={isOverflow ? label : ''}>
          <Box
            overflow="hidden"
            display="flex"
            alignItems="center"
            justifyContent={labelCentered ? 'space-between' : 'center'}
            gap="4px"
            width={1}
          >
            <Box
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent={labelCentered ? 'center' : undefined}
              gap="4px"
              overflow="hidden"
            >
              <Box display="flex" alignItems="center" className="tab-icon">
                {icon ? (
                  icon
                ) : (
                  <StyledIconsComponent iconName={iconName} source={source} />
                )}
              </Box>
              <Typography
                overflow="hidden"
                maxWidth="100%"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                fontSize={12}
                fontWeight={600}
                fontFamily={'poppins'}
              >
                {label}
              </Typography>
            </Box>

            {actions?.length && (
              <StyledThreeDotMenuIcon
                menuOptions={actions}
                open={!!anchorEl}
                handleMenuClick={(e: any) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }}
                handleMenuClose={(e: any) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                }}
                anchorEl={anchorEl}
                handleMenuItemClick={(
                  opt: {
                    label: string;
                    iconName: string;
                    source: string;
                    key: string;
                    onClick: (key: string) => void;
                  },
                  accountData: any,
                  e: any
                ) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                  opt.onClick(value as string);
                }}
              />
            )}
          </Box>
        </Tooltip>
      }
      {...props}
    />
  );
};

export default TabV2;
