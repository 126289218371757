import Grid from '@mui/material/Grid';
import { GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import DeletePopup from '../../../../../ui-kit/components/DeletePopup';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { QBInvoice } from '../../../../../models';
import { useRootStore } from '../../../../../store/root-store/rootStateContext';
import { ViewMetaData } from '../../../../../types';
import { CommonDataGrid } from '../../../../../ui-kit/components/DataGridPro';
import { gridPageSize } from '../../../../../utils';
import { getGridColumns } from '../../../../../utils/grid.utils';
import QuickbookInvoiceActions from '../QuickbookInvoiceActions';
import {
  quickbookInvoiceColumns,
  quickbookInvoiceDefaultFilters,
  quickbookInvoiceFilterColumnConfig,
  warningsMap,
} from './constants';

export default observer(function QuickbookInvoices({
  qbAccountId,
  invoices,
  last,
  terminalIds,
  totalDataInTable = 0,
  isClassMappingActive,
}: {
  qbAccountId: string;
  invoices: QBInvoice[];
  last: boolean;
  terminalIds: string[];
  totalDataInTable: number | undefined;
  isClassMappingActive: boolean;
}) {
  const { getGlobalTerminalsIds = [], getIsTerminalEnabled } = useRootStore();
  const isLastPage = useRef(last);
  const pageNumber = useRef(1);
  const [filters, setFilters] = useState<ViewMetaData>(
    quickbookInvoiceDefaultFilters
  );
  const [tableData, setTableData] = useState<QBInvoice[]>(invoices || []);
  const [selectedInvoices, setSelectedInvoices] = useState<QBInvoice[]>([]);
  const [warning, setWarning] = useState<{ [key: string]: any } | null>(null);
  const [disableRequest, setDisableRequest] = useState(true);
  const tableTotalDataCount = useRef(totalDataInTable);

  useEffect(() => {
    if (invoices) {
      setTableData(invoices);
      setDisableRequest(false);
    }
  }, [invoices]);

  const getQuickbookInvoices = async (
    filters: ViewMetaData,
    scrolling = false
  ) => {
    if (disableRequest) {
      return;
    }
    let currentTerminalIds = terminalIds;
    if (getGlobalTerminalsIds?.length) {
      currentTerminalIds = terminalIds.filter(function (n) {
        return getGlobalTerminalsIds.indexOf(n) !== -1;
      });
    }
    if (
      !currentTerminalIds.length &&
      getIsTerminalEnabled &&
      terminalIds?.length
    ) {
      setTableData([]);
      return;
    }
    const result = await QuickbookInvoiceActions.getQuickbookInvoices(
      filters,
      qbAccountId,
      currentTerminalIds
    );
    if (!result) {
      return;
    }
    if (!result.allTypesMapped) {
      setTableData([]);
      return;
    }
    isLastPage.current = result.last;
    if (scrolling) {
      setTableData((oldData) => [...oldData!, ...result.content]);
    } else {
      tableTotalDataCount.current = result.totalElements;
      setTableData(result.content);
    }
  };

  useEffect(() => {
    if (filters) {
      pageNumber.current = 1;
      getQuickbookInvoices({
        ...filters,
        pageNumber: pageNumber.current,
        pageSize: gridPageSize,
      });
    }
  }, [filters]);

  const handleFilterChangeCb = (data: string[], name: string) => {
    setFilters((oldFilters) => ({ ...oldFilters, [name]: data }));
  };

  const handleScroll = async () => {
    if (!isLastPage.current) {
      pageNumber.current++;
      await getQuickbookInvoices(
        {
          ...filters,
          pageNumber: pageNumber.current,
          pageSize: gridPageSize,
        },
        true
      );
    }
  };

  const handleSortChanged = async (model: GridSortModel) => {
    if (!model.length) return;
    const { field, sort } = model[0];
    const updatedRequestData = {
      ...filters,
      pageNumber: pageNumber.current,
      pageSize: gridPageSize,
      sort: `${sort === 'asc' ? '' : '-'}${field}`,
    };
    await getQuickbookInvoices(updatedRequestData);
  };

  const changeSelectionModel = (newModel: GridSelectionModel) => {
    const currentInvoices = tableData.filter((invoice) =>
      newModel.includes(invoice.id)
    );
    setSelectedInvoices(currentInvoices);
  };

  const suppressIsValid = useMemo(() => {
    return (
      selectedInvoices.filter(({ synchStatus }) => synchStatus === 'NON_SYNCED')
        .length === selectedInvoices.length
    );
  }, [selectedInvoices.length, tableData]);

  const releaseIsValid = useMemo(() => {
    return (
      selectedInvoices.filter(({ synchStatus }) => synchStatus === 'SUPPRESSED')
        .length === selectedInvoices.length
    );
  }, [selectedInvoices.length, tableData]);

  const syncQBPaymentStatusIsValid = useMemo(() => {
    return (
      selectedInvoices.filter(
        ({ loadStatus, synchStatus }) =>
          synchStatus === 'SYNCED' && loadStatus === 'INVOICED'
      ).length === selectedInvoices.length
    );
  }, [selectedInvoices.length, tableData]);

  const sentToQBIsValid = useMemo(() => {
    return (
      selectedInvoices.filter(
        ({ synchStatus, customer: { synch } }) =>
          (synchStatus === 'NON_SYNCED' || synchStatus === 'MODIFIED') &&
          !!synch
      ).length === selectedInvoices.length
    );
  }, [selectedInvoices.length, tableData]);

  const handleSuppress = async () => {
    const selectedInvoicesIds =
      QuickbookInvoiceActions.getSelectedInvoicesIds(selectedInvoices);
    const result = await QuickbookInvoiceActions.suppressInvoices(
      selectedInvoicesIds,
      qbAccountId
    );
    if (result) {
      const { updatedTableData } =
        QuickbookInvoiceActions.generateUpdatedTableData(tableData, result);
      setTableData(updatedTableData);
    }
  };

  const handleRelease = async () => {
    const selectedInvoicesIds =
      QuickbookInvoiceActions.getSelectedInvoicesIds(selectedInvoices);
    const result = await QuickbookInvoiceActions.releaseInvoices(
      selectedInvoicesIds,
      qbAccountId
    );
    if (result) {
      const { updatedTableData } =
        QuickbookInvoiceActions.generateUpdatedTableData(tableData, result);
      setTableData(updatedTableData);
    }
  };

  const handleSyncBackQBInvoices = async () => {
    const selectedInvoicesIds =
      QuickbookInvoiceActions.getSelectedInvoicesIds(selectedInvoices);
    const result = await QuickbookInvoiceActions.syncBackQBInvoices(
      selectedInvoicesIds,
      qbAccountId
    );
    if (result) {
      setWarning({
        title: 'QB statuses are synced successfully.',
        onAction: 'closeModal',
        buttonText: 'OK',
      });
      const { updatedTableData } =
        QuickbookInvoiceActions.generateUpdatedTableData(tableData, result);
      setTableData(updatedTableData);
    }
  };

  const handleSyncQBInvoices = async () => {
    const selectedInvoicesIds =
      QuickbookInvoiceActions.getSelectedInvoicesIds(selectedInvoices);
    const result = await QuickbookInvoiceActions.syncQBInvoices(
      selectedInvoicesIds,
      qbAccountId
    );
    if (result) {
      const { updatedTableData, unMappedSeqNumbers, bodyMessage } =
        QuickbookInvoiceActions.generateUpdatedTableData(tableData, result);
      if (unMappedSeqNumbers.length) {
        setWarning({
          title: `Some of the selected invoices (${unMappedSeqNumbers.join(
            ','
          )}) are not synced. Please check data and try again`,
          subTitle: bodyMessage,
          onAction: 'closeModal',
          buttonText: 'OK',
        });
      }
      setTableData(updatedTableData);
    }
  };

  const actionsMap: { [key: string]: () => void } = {
    suppress: handleSuppress,
    release: handleRelease,
    syncBack: handleSyncBackQBInvoices,
    closeModal: () => setWarning(null),
  };

  return (
    <>
      <Grid sx={{ m: 3 }} container id="CustomerMapping-container">
        <Grid container id="Grid-Container">
          <Grid item id="CustomerGrid" xs={12}>
            <CommonDataGrid
              tableData={tableData}
              tableColumnsData={getGridColumns(
                quickbookInvoiceColumns,
                getIsTerminalEnabled,
                undefined,
                isClassMappingActive
              )}
              totalNumberOfRows={tableTotalDataCount.current}
              checkboxSelection={true}
              filterColumns={quickbookInvoiceFilterColumnConfig}
              defaultFilterValue={filters}
              onPageFilterChange={handleFilterChangeCb}
              onSortModelChange={handleSortChanged}
              displayFilters={false}
              settingsPanel={false}
              handleScroll={handleScroll}
              selectRow={changeSelectionModel}
              cardBackgroundColor={'#fff'}
              bulkAction={[
                {
                  iconName: 'QuickBookIcon',
                  iconSource: 'AxeleIcon',
                  label: 'Sync To QuickBooks',
                  disabled: !sentToQBIsValid,
                  onClick: async () => {
                    if (sentToQBIsValid) {
                      await handleSyncQBInvoices();
                    }
                  },
                },
                {
                  iconName: 'SyncQBIcon',
                  iconSource: 'AxeleIcon',
                  label: 'Sync QB Status',
                  disabled: !syncQBPaymentStatusIsValid,
                  onClick: async () => {
                    if (syncQBPaymentStatusIsValid) {
                      setWarning(warningsMap['sendBack']);
                    }
                  },
                },
                {
                  iconName: 'ReleaseQBIcon',
                  iconSource: 'AxeleIcon',
                  label: 'Release',
                  disabled: !releaseIsValid,
                  onClick: async () => {
                    if (releaseIsValid) {
                      setWarning(warningsMap['release']);
                    }
                  },
                },
                {
                  iconName: 'SuppressQBIcon',
                  iconSource: 'AxeleIcon',
                  label: 'Suppress',
                  disabled: !suppressIsValid,
                  onClick: async () => {
                    if (suppressIsValid) {
                      setWarning(warningsMap['suppress']);
                    }
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      {!!warning && (
        <DeletePopup
          title={warning.title}
          subtitle={warning.subTitle}
          buttonText={warning.buttonText}
          open={!!warning}
          onClose={() => setWarning(null)}
          onAction={async () => {
            setWarning(null);
            await actionsMap[warning.actionName]();
          }}
          width="20%"
        />
      )}
    </>
  );
});
