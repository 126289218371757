import useFilterStorage from '../../services/storage';
import { entityTypes } from '../entityTypesData';
import {
  driverFilterData,
  invoiceFilterData,
  navigationLinks,
  tractorFilterData,
  trailerFilterData,
  tripsFilterData,
  dispatchFilterData,
  invoiceDelayPaymentFilterData,
  invoiceDelayMissingFilterData,
} from './constants/constants';
import { NavigationTypes } from './constants/interface';

export const performNaivgation = (id: string, navigate: any) => {
  let data: NavigationTypes = {
    navigate: navigate,
    entity: '',
    page: '',
    filterValues: {},
    stripId: '',
  };

  switch (id) {
    case 'tractorUnavailable':
    case 'tractorAvailable':
    case 'tractorWarning':
    case 'tractorCritical':
    case 'totalTractor':
      data = {
        ...data,
        entity: entityTypes.TRACTOR,
        page: 'Equipment',
        filterValues: tractorFilterData[id],
        stripId: 'tractor',
      };
      navigateWithFilters(data);
      break;

    case 'trailerWarning':
    case 'trailerCritical':
      data = {
        ...data,
        entity: entityTypes.TRAILER,
        page: 'Equipment',
        filterValues: trailerFilterData[id],
        stripId: 'trailer',
      };
      navigateWithFilters(data);
      break;

    case 'driverWarning':
    case 'driverCritical':
      data = {
        ...data,
        entity: entityTypes.DRIVER,
        page: 'Users',
        filterValues: driverFilterData[id],
        stripId: 'driver',
      };
      navigateWithFilters(data);
      break;

    case 'invoiceDelay':
      data = {
        ...data,
        entity: entityTypes.INVOICE,
        page: 'Invoices',
        filterValues: invoiceDelayPaymentFilterData[id],
        stripId: 'invoiced',
      };
      navigateWithFilters(data);
      break;
    case 'invoicePending':
      data = {
        ...data,
        entity: entityTypes.INVOICE,
        page: 'Invoices',
        filterValues: invoiceFilterData[id],
        stripId: 'invoiced',
      };
      navigateWithFilters(data);
      break;

    case 'invoiceMissing':
      data = {
        ...data,
        entity: entityTypes.INVOICE,
        page: 'Invoices',
        filterValues: invoiceDelayMissingFilterData[id],
        stripId: 'completed',
      };
      navigateWithFilters(data);
      break;
    case 'invoiceReady':
      data = {
        ...data,
        entity: entityTypes.INVOICE,
        page: 'Invoices',
        filterValues: invoiceFilterData[id],
        stripId: 'completed',
      };
      navigateWithFilters(data);
      break;

    case 'openTripsTotal':
      data = {
        ...data,
        entity: entityTypes.TRIPS,
        page: 'Trips',
        filterValues: tripsFilterData[id],
        stripId: 'trips',
      };
      navigateWithFilters(data);
      break;

    case 'tripsNeedDriver':
      data = {
        ...data,
        entity: entityTypes.TRIPS,
        page: 'Trips',
        filterValues: tripsFilterData[id],
        stripId: 'allTrips',
      };
      navigateWithFilters(data, id);
      break;

    case 'assignTripsTotal':
    case 'assignedPotentialDelay':
      data = {
        ...data,
        entity: entityTypes.TRIPS,
        page: 'Trips',
        filterValues: tripsFilterData[id],
        stripId: 'allTrips',
      };
      navigateWithFilters(data);
      break;

    case 'driverNeedsLoads':
    case 'driverOnVacation':
      data = {
        ...data,
        entity: entityTypes.DISPATCH,
        page: 'Dispatch',
        filterValues: dispatchFilterData[id],
        stripId: 'grantt',
      };
      navigateWithFilters(data);
      break;

    case 'loadsearch':
      data = {
        ...data,
        entity: entityTypes.LOAD,
        page: 'Loadsearch',
        filterValues: {},
        stripId: 'loadsearch',
      };
      navigateWithFilters(data);
      break;
    default:
      break;
  }
};

export const navigateWithFilters = async (data: NavigationTypes, id = '') => {
  const { navigate, entity, page, filterValues, stripId } = data;
  const LStorageHook = useFilterStorage({ page: page });
  const storageData = LStorageHook.getStorage() || {};

  if (entity != entityTypes.DISPATCH)
    LStorageHook.updateStorage('stripId', stripId);

  LStorageHook.updateStorage(stripId, {
    ...storageData[stripId],
    filters: filterValues,
  });

  navigate(navigationLinks[entity]);
};
