import React from 'react';
import Stack from '@mui/material/Stack';
import IconsComponent from '../../../../ui-kit/components/IconsComponent';
import Typography from '@mui/material/Typography';

const Cell = (props: any) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <IconsComponent
        iconName={props.iconName}
        source={props.source}
        styleProps={{
          color: props.iconColor ? props.iconColor : 'text.secondary',
        }}
      />
      <>
        <Stack
          direction="column"
          spacing={0}
          // justifyContent="center"
          // alignItems="center"
        >
          <Typography
            variant={props.variant}
            color={props.textColor}
            sx={{ fontWeight: '600' }}
            align="left"
          >
            {props.text}
          </Typography>
          {props.multilineText && props.multilineText !== '' && (
            <Typography
              variant={props.variant}
              color={props.textColor}
              sx={{ fontWeight: '600' }}
              align="left"
            >
              {props.multilineText}
            </Typography>
          )}
        </Stack>
      </>
    </Stack>
  );
};

export default Cell;
