import { Theme } from '@mui/material';
import { RoundedBtnStyles } from './';

export const RecommendTripsContainerStyles = {
  height: '100%',
  minHeight: '12em',
};

export const RecommendTripFilterContainerStyles = {
  gap: '10px',
  // height: '40px',
  background: '#FFFFFF',
  marginBottom: '10px',
  alignItems: 'center',
};

export const ColumnSingleTextStyles = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  color: '#000000',
};

export const RecommendGanttWithResources = {
  height: '50%',
};

export const RecommendGanttContainerStyles = {
  // height: 'calc(100% - 64px)',
  height: '100%',
};

export const disabledLayoutStyles = {
  opacity: '0.6',
  pointerEvents: 'none',
  filter: 'blur(1px)',
};
export const enabledLayoutStyles = {
  opacity: '1',
  pointerEvents: 'all',
  filter: 'none',
};

export const SplitterContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 64px)',
};

export const PreferenceIconBtnStyles = (theme: Theme) => ({
  boxShadow: theme?.shadows?.[2],
  borderRadius: '10px',
  width: '52px',
  height: '32px',
  background: theme.palette.primary.main,
  ':hover': {
    background: theme.palette.primary.main,
  },
});

export const tripColumnTextStyles = {
  color: 'rgba(0, 17, 34, 0.75)',
  textOverflow: 'ellipsis',
  gap: '5px',
  fontWeight: 400,
  fontSize: '14px',
  letterSpacing: '0.15px',
};

export const GlobalEvalButtonStyles = (theme: Theme) => {
  return {
    ...RoundedBtnStyles,
    borderRadius: '10px',
    flexDirection: 'row',
    height: '32px!important',
    background: theme?.palette?.primary?.main,
    ':hover': {
      background: theme?.palette?.primary?.main,
    },
  };
};

export const BrokeredTripDriverColumnStyles = {
  color: 'rgba(0, 17, 34, 0.60)',
  fontSize: 14,
  fontStyle: 'italic',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
};
