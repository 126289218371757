import React, { useMemo } from 'react';
import { CommonConstants } from '../../../locales/en/common/index';
import { ITabV2 } from '../../../ui-kit/components/TabsV2';
import TabsV2 from '../../../ui-kit/components/TabsV2/TabsV2';
import { DirtyDetailsPanelManager } from '../../DetailsPanel/utils';
import { useLoadPermission } from '../../hooks/useLoadPermission';
import { EMyLoadDetailsPanelTabs } from '../constants/constants';
export interface MyLoadDetailsPanelTab extends ITabV2 {
  key: EMyLoadDetailsPanelTabs;
}

const getTabItems = (): MyLoadDetailsPanelTab[] => {
  return [
    {
      key: EMyLoadDetailsPanelTabs.OVERVIEW,
      label: CommonConstants.overview,
      iconName: 'InfoOutlined',
      source: 'MUIcons',
    },
    {
      key: EMyLoadDetailsPanelTabs.ROUTES,
      label: CommonConstants.routes,
      iconName: 'MapOutlined',
      source: 'MUIcons',
    },
    {
      key: EMyLoadDetailsPanelTabs.FINANCE,
      label: CommonConstants.finance,
      iconName: 'AttachMoney',
      source: 'MUIcons',
    },
    {
      key: EMyLoadDetailsPanelTabs.INVOICE,
      label: CommonConstants.invoice,
      iconName: 'Receipt',
      source: 'MUIcons',
    },
    {
      key: EMyLoadDetailsPanelTabs.TRACKING,
      label: CommonConstants.tracking,
      iconName: 'TrackChangesOutlined',
      source: 'MUIcons',
    },
    {
      key: EMyLoadDetailsPanelTabs.NOTES,
      label: CommonConstants.notes,
      iconName: 'SpeakerNotesOutlined',
      source: 'MUIcons',
    },
    {
      key: EMyLoadDetailsPanelTabs.DOCUMENTS,
      label: CommonConstants.documents,
      iconName: 'SnippetFolderOutlined',
      source: 'MUIcons',
    },
    {
      key: EMyLoadDetailsPanelTabs.ADDITIONAL_DETAILS,
      label: CommonConstants.additionalDetails,
      iconName: 'MoreOutlined',
      source: 'MUIcons',
    },
    {
      key: EMyLoadDetailsPanelTabs.ACTIVITY,
      label: CommonConstants.activity,
      iconName: 'ListAlt',
      source: 'MUIcons',
    },
  ];
};

const DetailsTabStrips: React.FC<{
  onChange: (selected: EMyLoadDetailsPanelTabs) => void;
  keys: EMyLoadDetailsPanelTabs[];
  tab: EMyLoadDetailsPanelTabs;
}> = ({ onChange, keys, tab }) => {
  const { hasFinancialPaymentView, hasFinancialInvoicesViewByRoleCode } =
    useLoadPermission();

  const tabItems = useMemo(() => {
    return getTabItems().filter((item: MyLoadDetailsPanelTab) => {
      return (
        keys.includes(item.key) &&
        (item.key !== EMyLoadDetailsPanelTabs.INVOICE ||
          hasFinancialInvoicesViewByRoleCode) &&
        (item.key !== EMyLoadDetailsPanelTabs.FINANCE ||
          hasFinancialPaymentView)
      );
    });
  }, [keys, hasFinancialPaymentView, hasFinancialInvoicesViewByRoleCode]);

  const getTab = (tabKey?: EMyLoadDetailsPanelTabs) =>
    tabItems.find(({ key }) => key === tabKey) || tabItems[0];

  const handleStripChange = (selected: MyLoadDetailsPanelTab) => {
    if (DirtyDetailsPanelManager.isShouldPanelShake()) return;
    onChange(selected.key);
  };

  return (
    <TabsV2<'key', MyLoadDetailsPanelTab>
      keyName="key"
      tabs={tabItems}
      setSelectedTab={(tab) => {
        handleStripChange(tab);
      }}
      selectedTab={getTab(tab).key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

const getTabByKey = (
  key: EMyLoadDetailsPanelTabs
): MyLoadDetailsPanelTab | undefined => {
  return getTabItems().find((tab) => tab.key === key);
};

export const tabStripRendererByKeyFS = (key: EMyLoadDetailsPanelTabs) => {
  return (
    <TabsV2<'key', MyLoadDetailsPanelTab>
      keyName="key"
      tabs={[getTabByKey(key)].filter(Boolean) as MyLoadDetailsPanelTab[]}
      selectedTab={getTabByKey(key)?.key}
      isDarkMode
      autoFill
      labelCentered
      autoCollapse={false}
      keepSelectedTabOnFirstRow={false}
    />
  );
};

export default DetailsTabStrips;
