import { QueryParams } from '../commonMixed/QueryParams';
export class LoadboardRequest extends QueryParams {
  organizationId!: number;
  terminalIds!: Array<string>;
}

export class GetTerminalList extends QueryParams {
  organizationId!: number;
  terminalIds!: Array<string>;
}

export class CreateRequestCredentials extends QueryParams {
  data!: Array<object>;
  integrationType!: string;
  isValid!: boolean;
  lastUsedDate!: Date;
  lastValidationDate!: Date;
  providerId!: number;
  organizationId!: number;
  terminalIds!: Array<string>;
  id?: string;

  constructor(data: any) {
    super();
    this.data = data.data;
    this.integrationType = data.integrationType;
    this.isValid = data.isValid;
    this.lastUsedDate = data.lastUsedDate;
    this.lastValidationDate = data.lastValidationDate;
    this.providerId = data.providerId;
    this.terminalIds = data.terminalIds;
    this.id = data.id;
  }
}
export class LoginAccountIntegration extends QueryParams {
  data: Array<object>;
  integrationType: string;
  isValid: boolean;
  lastUsedDate!: null;
  lastValidationDate!: null;
  organizationId!: number;
  providerId: number;
  terminalIds: Array<string>;
  id?: string;

  constructor(data: any) {
    super();
    this.data = data.data;
    this.integrationType = data.integrationType;
    this.isValid = data.isValid;
    this.lastUsedDate = data.lastUsedDate;
    this.lastValidationDate = data.lastValidationDate;
    this.providerId = data.providerId;
    this.terminalIds = data.terminalIds;
    this.id = data.id;
  }
}

export class DeactivateAccountCard extends QueryParams {
  id: string;
  integrationType: string;
  organizationId: number;
  providerId: number;

  constructor(data: any) {
    super();
    this.id = data.id;
    this.integrationType = data.integrationType;
    this.organizationId = data.organizationId;
    this.providerId = data.providerId;
  }
}

export class PrefilledDataRequest extends QueryParams {
  organizationId!: number;
  terminalIds!: Array<string>;
}

export class UserDetailsRequest extends QueryParams {
  userId!: number;
  constructor(data: any) {
    super();
    this.userId = data.userId;
  }
}

interface LaneRateQueryParams {
  destinationCity: string;
  destinationState: string;
  equipmentType: string;
  originCity: string;
  originState: string;
  pickupDate: string;
}
export class MarketRateRequest
  extends QueryParams
  implements LaneRateQueryParams
{
  destinationCity!: string;
  destinationState!: string;
  equipmentType!: string;
  originCity!: string;
  originState!: string;
  pickupDate!: string;
  constructor(dto: LaneRateQueryParams) {
    super();
    Object.assign(this, dto);
  }
}
