import {
  DVIRPreferenceOptions,
  PrefferedTruckStopsOptions,
  WorkWeekTypeOptions,
} from '../../settings/preferences/constants';
import { IDispatchOption, IDispatchPreferencesFormData } from './types';

export const getWorkWeekType = (): IDispatchOption[] => {
  return WorkWeekTypeOptions.map((option) => ({
    value: option.label,
    key: option.key,
  }));
};

export const weeklyResetDayData = [
  { key: 'ANY', value: 'any' },
  { key: 'MONDAY', value: 'mon' },
  { key: 'TUESDAY', value: 'tue' },
  { key: 'WEDNESDAY', value: 'wed' },
  { key: 'THURSDAY', value: 'thu' },
  { key: 'FRIDAY', value: 'fri' },
  { key: 'SATURDAY', value: 'sat' },
  { key: 'SUNDAY', value: 'sun' },
];

export const drivingBehaviourPreferences = [
  { key: 'ANY', value: 'Any' },
  { key: 'DAY', value: 'Day Time' },
  { key: 'NIGHT', value: 'Night Time' },
];

export const getDvirPreferences = (): IDispatchOption[] => {
  return DVIRPreferenceOptions.map((option) => ({
    value: option.label,
    key: option.key,
  }));
};

export const getPreferredTruckstopsType = (): IDispatchOption[] => {
  return PrefferedTruckStopsOptions.map((option) => ({
    value: option.label,
    key: option.key,
  }));
};
